export default {
  methods: {
    async onSuccessSetupPayments({
      publicToken = null,
      metadata = null,
      externalAccount = null,
      data = {},
      skip = false
    }) {
      return this.$store.dispatch('ajax', {
        path: '/gateway/setupPayments',
        data: {
          ...(publicToken ? { public_token: publicToken } : {}),
          ...(metadata ? { account: metadata.account } : {}),
          ...(externalAccount ? { external_account: externalAccount } : {}),
          ...data,
          skip
        }
      })
    },

    /**
     * create a plaid link token
     * @returns {Promise<*>}
     */
    createPlaidLinkToken() {
      return this.$store.dispatch('ajax', {
        path: 'payfac/createPlaidLinkToken'
      })
    }
  }
}
