<template>
  <div
    v-if="dimensionType"
    class="quote-dimensions--dimension-container"
    :class="{ 'quote-dimensions--dimension-inherit': dim.inherit && (inAssembly || hasParent) }"
  >
    <div class="quote-dimensions--dimension-label">
      {{ dimensionType.dimension_type_name }}
      <span class="quote-dimensions--dimension-sublabel" v-if="dim.inherit && inheritsFrom">
        <font-awesome-icon icon="drafting-compass" /> from
        <strong>{{ $f.truncate(inheritsFrom, 22) }}</strong>
      </span>
    </div>

    <div class="quote-dimensions--dimension-contents">
      <btn-group style="max-width: 100%">
        <btn
          v-if="hasParent && dim.inherit"
          style="flex: 0 0 20%; min-width: unset"
          class="info"
          @click="unlink"
          v-tooltip="inheritMessage"
        >
          <font-awesome-icon icon="drafting-compass" />
        </btn>
        <btn
          v-else-if="hasParent"
          style="flex: 0 0 20%; min-width: unset"
          class=""
          @click="link"
          v-tooltip="
            `Click to link the dimension to ${inheritsFrom} ${dimensionType.dimension_type_name}`
          "
        >
          <font-awesome-icon icon="link" />
        </btn>

        <field
          type="calculator"
          ref="calculator"
          style="flex: 1 60%; min-width: unset"
          class="text-center number"
          :class="{
            danger: !dim.value && !rootAssembly,
            muted: !dim.inherit && dim.value,
            info: dim.inherit && (dim.value || rootAssembly)
          }"
          :dimensions="dimensions"
          :measure="measureLocal"
          :placeholder="rootAssembly ? 'inherits' : '0'"
          :equation="dim.equation"
          :value="dim.value"
          :format="/m/.test(dim.measure) || /\d/.test(dim.measure) ? 'number' : 'imperial'"
          @input="(value, equation) => setDimension(value, equation)"
        />

        <choose
          style="flex: 0 0 20%; min-width: unset"
          :staticSet="measuresPreset"
          v-model="measureLocal"
          :return-array="false"
          :default="defaultMeasure"
        />
      </btn-group>
    </div>
  </div>
</template>

<script>
import UserMetaMixin from '../mixins/UserMeta'

/**
 * This component needs to be specifically used in QuoteDimensions component only
 */
export default {
  name: 'Dimension',

  mixins: [UserMetaMixin],
  emits: ['dimensions'],

  computed: {
    rootAssembly() {
      return (
        (!this.$parent.parentRefId ||
          !this.$store.state[this.$parent.storeName].normalized[this.$parent.parentRefId]) &&
        this.$parent.storeName === 'Assembly'
      )
    },
    defaultMeasure: {
      get() {
        return this.getMetaItem(this.defaultMeasureSettingKey) || this.dim.measures[0]
      },
      set(measure) {
        this.setMetaItem(this.defaultMeasureSettingKey, measure)
      }
    },

    measureLocal: {
      get() {
        return this.dim.measure || this.defaultMeasure
      },
      set(measure) {
        this.setMeasure(measure)
      }
    },

    dimTypes() {
      const all = this.$store.state.DimensionType.all
      return Object.keys(all).map((n) => all[n][Object.keys(all[n])[0]])
    },
    dimensionType() {
      const abbr = this.dim.abbr
      return this.dimTypes.find((t) => t.dimension_type_abbr === abbr)
    },
    measuresPreset() {
      return this.dim.measures.map((m) => ({ text: m, value: m }))
    },
    hasParent() {
      return Object.keys(this.parentDimensions).length || this.inAssembly
    },
    inheritMessage() {
      return this.inheritsFrom
        ? `Currently inherits from <strong>"${this.dimensionType.dimension_type_name}"</strong> of <strong>${this.inheritsFrom}</strong>.`
        : 'Set this dimension if any of items require it.'
    },
    inheritsFrom() {
      if (this.rootAssembly) {
        return 'the context it is added to'
      } else if (
        !this.$parent.parentRefId ||
        !this.$store.state[this.$parent.storeName].normalized[this.$parent.parentRefId]
      ) {
        return null
      }

      const parent = this.$store.state[this.$parent.storeName].normalized[this.$parent.parentRefId]
      return parent.assembly_name || parent.quote_name
    }
  },
  data() {
    return {
      forceViewValue: 0,
      defaultMeasureSettingKey: /2/.test(this.dim.measures[0])
        ? 'defaultAreaMeasure'
        : 'defaultLengthMeasure'
    }
  },
  watch: {
    forceViewValue(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.calculator.selectAll()
        })
      }
    }
  },
  methods: {
    link() {
      const pd = Object.keys(this.parentDimensions).length
        ? this.parentDimensions[this.dim.abbr]
        : false
      if (pd) {
        this.$emit('dimensions', {
          ...this.dimensions,
          [this.dim.abbr]: {
            ...pd,
            value: this.dim.value,
            measure: this.dim.measure,
            inherit: 1,
            explicitlySet: 0
          }
        })
      }
      this.forceViewValue = 0
    },
    unlink() {
      this.$emit('dimensions', {
        ...this.dimensions,
        [this.dim.abbr]: {
          ...this.dim,
          inherit: 0,
          explicitlySet: 1
        }
      })
    },
    eq(val, measure, val2, measure2, precision = 4) {
      return c.eqMeasure(val, measure, val2, measure2, precision)
    },
    setMeasure(value) {
      this.defaultMeasure = value

      this.$emit('dimensions', {
        ...this.dimensions,
        [this.dim.abbr]: {
          ...this.dimensions[this.dim.abbr],
          measure: value
        }
      })
    },
    setDimension(value, equation = null) {
      const d = this.dimensions[this.dim.abbr]
      const pd = Object.keys(this.parentDimensions).length
        ? this.parentDimensions[this.dim.abbr]
        : false

      if (!this.eq(d.value, d.measure, value, this.dim.measure) || d.equation !== equation) {
        if (pd && this.eq(pd.value, pd.measure, value, this.dim.measure, 4)) {
          this.$emit(
            'dimensions',
            _.imm({
              ...this.dimensions,
              [this.dim.abbr]: {
                ...pd,
                value,
                measure: this.dim.measure,
                inherit: 1,
                equation
              }
            })
          )
        } else {
          this.$emit(
            'dimensions',
            _.imm({
              ...this.dimensions,
              [this.dim.abbr]: {
                ...this.dimensions[this.dim.abbr],
                value,
                explicitlySet: 1,
                equation,
                inherit: 0
              }
            })
          )
        }
      }
    }
  },
  components: {},
  props: {
    dimensionsUsed: {
      type: Array,
      default: () => []
    },
    dim: {
      required: true
    },
    dimensions: {
      required: true
    },
    parentDimensions: {
      required: true,
      default: () => ({})
    },
    inAssembly: {
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
