import phoneWeb from './phoneWeb'

const currentPhone = phoneWeb

export default {
  ...currentPhone,

  actions: {
    async verify({ dispatch, state, commit, rootState }, payload) {
      const { object: phone } = await dispatch('Phone/resolveObject', payload, { root: true })
      const id = phone.phone_id

      let verified
      try {
        const { object } = await dispatch(
          'ajax',
          {
            path: `phone/verify/${id}`
          },
          { root: true }
        )

        verified = object
      } catch (e) {
        if (/already/.test(e.message) || /already/.test(e.userMessage)) {
          commit({
            type: 'PHONE_OPEN',
            phone: state.lastPhoneOpen || phone,
            force: true
          })

          return {
            ...payload
          }
        }

        throw e
      }

      if (
        await dispatch(
          'modal/asyncConfirm',
          {
            message: `We are calling your number now, please
                  answer the phone and enter the following code:
                  ${verified.phone_verification_code}`,
            yes: 'I verified',
            no: 'Cancel'
          },
          { root: true }
        )
      ) {
        if (payload.refId) {
          commit({
            type: 'FIELD',
            refId: payload.refId,
            changes: verified
          })
        }

        dispatch('show', { id: verified.phone_id })

        return {
          ...payload,
          reload: true
        }
      }

      return {
        ...payload,
        reload: false
      }
    },

    /**
     *
     * @param dispatch
     * @param payload
     *  @param bool verified      set to true or false to get verified or unverified phones,
     *                              otherwise all are returned.
     */
    async getPhones({ dispatch, rootState }, payload) {
      const { verified = null } = payload

      const { set } = await dispatch(
        'ajax',
        {
          path: 'phone/search',
          data: {
            filters: {
              phone_owner: `user-${rootState.session.user.user_id}`,
              ...(verified !== null
                ? {
                    phone_is_verified: verified ? 1 : 0
                  }
                : {})
            }
          }
        },
        { root: true }
      )

      return set
    },

    ...currentPhone.actions
  }
}
