<script setup>
import { defineProps, defineModel, ref, defineExpose } from 'vue'
import CostItem from '@/components/bodies/CostItem.vue'
CostItem.compatConfig = { MODE: 3 }

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'cost_type' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true }
})
const object = defineModel('object')
const refCostItem = ref(null)
defineExpose({
  refCostItem
})
</script>

<template>
  <CostItem v-bind="props" v-model:object="object" ref="refCostItem" />
</template>
