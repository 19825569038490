import * as types from '../../mutation-types'

const state = {
  searchBoxOpen: false,
  zIndex: false,
  searchPhrase: ''
}

const getters = {}

const actions = {
  /**
   * Open if not open, and set search phrase;
   * @param dispatch
   * @param payload
   *    @param searchPhrase string    search phrase to search for
   */
  async search({ dispatch, state, commit }, payload) {
    const {
      searchPhrase,
      types: searchTypes = ['client', 'quote', 'user', 'file', 'task'],
      offset = 0,
      limit = 10,
      filters = {},
      minScore = 0,
      open = false,
      multiple = false
    } = payload

    if (open) {
      commit({ type: types.SET_SEARCH_PHRASE, searchPhrase })
      commit({ type: types.SEARCH_OPEN })
      return {}
    }

    const processed = searchPhrase.replace(/[^A-Za-z0-9,-_'".@#&* ]/, '')
    let path = `${multiple ? 'msearch' : 'search'}/${searchTypes.join(',')}/${limit}/${offset}`
    if (searchPhrase && searchPhrase.length > 0) {
      path = `${multiple ? 'msearch' : 'search'}/${searchTypes.join(',')}/${encodeURIComponent(processed)}/${limit}/${offset}`
    }
    const { object } = await dispatch(
      'ajax',
      {
        path,
        data: {
          filters,
          minScore
        }
      },
      { root: true }
    )

    const set = object.hits.map((hit) => hit._source)
    const total = object.total.value
    const score = searchPhrase ? object.max_score : 10

    return {
      object,
      set,
      stats: object,
      score,
      total
    }
  },

  async multiSearch({ dispatch, state, commit }, payload) {
    const {
      searchPhrase,
      types: searchTypes = ['client', 'quote', 'user', 'file', 'task'],
      date,
      offset = 0,
      limit = 10,
      filters = [],
      minScore = 0
    } = payload

    const processed = searchPhrase.replace(/[^A-Za-z0-9,-_'".@#&* ]/, '')
    let path = `multiSearch/${searchTypes.join(',')}/${limit}/${offset}`
    if (searchPhrase && searchPhrase.length > 0) {
      path = `multiSearch/${searchTypes.join(',')}/${encodeURIComponent(processed)}/${limit}/${offset}`
    }
    const { object } = await dispatch(
      'ajax',
      {
        path,
        data: {
          filters,
          date,
          minScore
        }
      },
      { root: true }
    )

    const set = object.hits.map((hit) => hit._source)
    const total = object.total.value
    const score = searchPhrase ? object.max_score : 10

    return {
      object,
      set,
      stats: object,
      score,
      total
    }
  },

  /**
   * Open search modal
   * @param commit
   * @param state
   * @param rootState
   * @returns {Promise<any>}
   */
  async open({ commit, state, rootState, getters }) {
    if (getters.isGuestUser) return

    commit({
      type: types.SEARCH_SET_Z,
      zIndex: rootState.modal.topZIndex + 10
    })
    commit({
      type: types.SEARCH_OPEN
    })
  },

  /**
   * Close search modal
   * @param commit
   * @param state
   * @returns {Promise<any>}
   */
  close({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit({
        type: types.SEARCH_CLOSE
      })
      resolve()
    })
  }
}

const mutations = {
  [types.SEARCH_OPEN](state) {
    state.searchBoxOpen = true
  },
  [types.SEARCH_CLOSE](state) {
    state.searchBoxOpen = false
  },
  [types.SEARCH_SET_Z](state, { zIndex }) {
    state.zIndex = zIndex
  },
  [types.SET_SEARCH_PHRASE](state, { searchPhrase }) {
    state.searchPhrase = searchPhrase
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
