<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :scrollable="true"
    :closeable="true"
    :showCloseButton="false"
    :clickAway="true"
  >
    <template #header> Create a new client </template>
    <template #body>
      <client-body ref="body" :object="object" />
    </template>
  </Modal>
</template>

<script>
import ClientBody from '../bodies/ClientNew.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalClientNew',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { ClientBody },
  props: {
    type: {
      default: 'client'
    },
    go: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
