<template>
  <container v-if="!bodyLoading">
    <card-section>
      <card class="sm">
        <template #icon>
          <font-awesome-icon icon="briefcase" />
        </template>

        <template #title>Set permissions for this role</template>

        <p>
          Set the permissions for this role. Permissions are split between what the user is allowed
          to do with objects they own (self) and what users are allowed to do with objects other
          users own (others).
        </p>
        <p>
          A manager may want to have access to their teams objects to be able to supervise them, in
          that case you would allow them to read, create, modify and delete for self
          <strong>and</strong> for others.
        </p>
        <p>
          A sales person may only need to see their clients and proposals for example, so you might
          have their permissions for quotes set to read, create, modify for self only, and if they
          need to delete one they can ask their manager.
        </p>
      </card>
    </card-section>

    <card-section>
      <card-list>
        <card-list-field>
          <span>Role name</span>

          <field schema="role:role_name" type="input" v-model="role_name" />
        </card-list-field>
        <card-list-field>
          <span>Role description</span>

          <field schema="role:role_desc" type="input" v-model="role_desc" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <permission-list
        v-if="loaded"
        type="role"
        store="Role"
        permissionType="Role"
        @changed="(permission) => change(permission)"
        :forcePermissions="permissions"
      />
    </card-section>
  </container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import PermissionList from '../Permissions/PermissionList.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Role',
  mixins: [ObjectManipulator('role', true, 'Role'), BodyMixin],
  data() {
    return {
      permissions: [],
      loaded: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    change(permission) {
      let perms = _.imm(this.aoRolePermissions)
      const old = perms.find(
        (obj) => String(obj.permission_id) === String(permission.permission_id)
      )

      if (!old) {
        perms = [...perms, permission]
      } else {
        const index = perms.indexOf(old)
        perms.splice(index, 1, permission)
      }
      this.aoRolePermissions = perms
    }
  },
  components: {
    PermissionList
  },
  props: {},
  mounted() {
    eventBus.$on(`${this.uid}-selected`, () => {
      if (this.aoRolePermissions && this.aoRolePermissions.length) {
        this.permissions = this.aoRolePermissions
      } else {
        this.$store.dispatch('ajax', { path: 'permission/filter' }).then(({ set }) => {
          this.permissions = set.map((permission) =>
            c.buildDefaultObject('role_permission', permission)
          )
        })
      }
      this.loaded = true
    })
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
// $tableColor: darken(dodgerblue, 40%);

.content {
  border: 1px solid lightgray;
  // color: $tableColor;
  border-radius: 10px;
}
.line {
  background-color: lightgray;
  width: 100%;
  height: 1px;
}
</style>
