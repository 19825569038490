<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :scrollable="true"
    :full="true"
    :clickAway="false"
    :expandable="true"
    :includeGutter="true"
    :hasSidePanel="true"
  >
    <template #header>
      <font-awesome-icon icon="text-resize" />
      New Template
    </template>
    <template #body>
      <template-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import TemplateBody from '../bodies/Template.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'TemplateNewModal',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { TemplateBody },
  props: {
    type: {
      default: 'template'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
