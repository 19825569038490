<template>
  <div class="assembly-child--container-outer loader">
    <div class="card-list card assembly-child--container">
      <ul class="list-group list-group-flush">
        <li
          class="card-list-item list-group-item collapse--container-outer white assembly-child--collapse collapse--closed"
        >
          <div class="collapse--container">
            <div class="collapse--header"></div>
          </div>
        </li>
      </ul>
    </div>

    <div class="assembly-child--folds">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="scss" rel="stylesheet/scss">
@keyframes phAnimation {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(30%);
  }
}

.assembly-child--container-outer.loader {
  .assembly-child--collapse {
    padding: 0 !important;
    > .collapse--container {
      height: 100%;
      width: 100%;
    }
  }

  .assembly-child--container {
    box-shadow: none !important;
  }

  .collapse--closed > .collapse--container > .collapse--header {
    min-height: 4.5em;
    width: 100%;
    background-color: $cool-gray-400 !important;
    display: flex;

    &:hover {
      background-color: $cool-gray-400 !important;
    }
  }

  .collapse--header {
    min-height: 4.5em;
    width: 100%;
    background-color: $cool-gray-400 !important;
    display: flex;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      width: 500%;
      margin-left: -250%;
      animation: phAnimation 0.8s linear infinite;
      background: linear-gradient(
          to right,
          rgba($flame-white, 0) 46%,
          rgba($flame-white, 0.35) 50%,
          rgba($flame-white, 0) 54%
        )
        50% 50% !important;
    }

    &:hover {
      background-color: $cool-gray-400 !important;
    }
  }

  .assembly-child--folds {
    > div {
      background-color: $cool-gray-400 !important;
      box-shadow: none !important;
    }
  }
}
</style>
