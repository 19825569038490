<template>
  <InputText
    v-if="componentIs === 'input'"
    :type="componentType"
    ref="input"
    :data-uid="uid"
    :placeholder="placeholder"
    v-tooltip="inputTooltip"
    v-model="fieldValue"
    :disabled="!!disabled"
    :autocomplete="componentType !== 'password' ? 'off' : 'on'"
    :pt="pt"
    :ptOptions="{ mergeProps: true }"
    :class="[...inputClass, ...componentClass]"
    @change="changeHandler"
    @click.stop="clickHandler"
    @focusin="focusinHandler"
    @focusout="focusoutHandler"
    @keyup="keyupHandler"
    @keypress="keypressHandler"
  />
  <InputNumber
    v-else-if="componentIs === 'number'"
    ref="input"
    :data-uid="uid"
    :placeholder="placeholder"
    v-tooltip="inputTooltip"
    :disabled="!!disabled"
    v-model="fieldValue"
    @change="changeHandler"
    :pt="pt"
    :ptOptions="{ mergeProps: true }"
    :class="[...inputClass, ...componentClass]"
    @click.stop="clickHandler"
    @focusin="focusinHandler"
    @focusout="focusoutHandler"
    @keyup="keyupHandler"
    @keypress="keypressHandler"
  />
  <calculator
    v-else-if="type === 'calculator-inline'"
    v-bind="$props"
    :class="componentClass"
    ref="input"
    v-model="fieldValue"
    :equation="equationValue"
    :value="typedValue"
    :dimensions="dimensions"
    @focusin="
      (...args) => {
        openHandler(...args)
        focusinHandler(...args)
      }
    "
    @click="
      (...args) => {
        openHandler(...args)
        focusinHandler(...args)
      }
    "
    @focusout="
      (...args) => {
        closeHandler(...args)
        focusoutHandler(...args)
      }
    "
    @equation="calcEquationHandler"
  />
  <div
    v-else-if="componentIs === 'calculator'"
    @mouseover.capture="mouseoverHandler"
    @mouseout="mouseoutHandler"
    class="flex items-center relative"
  >
    <!-- field -->
    <InputText
      type="text"
      ref="input"
      class="w-full !border-l-[20px] focus:border-blue-print border-surface-400 text-right"
      :data-uid="uid"
      :disabled="!!disabled"
      :placeholder="placeholder"
      :pt="pt"
      :ptOptions="{ mergeProps: true }"
      v-tooltip="inputTooltip"
      v-model="fieldValue"
      autocomplete="off"
      spellcheck="false"
      autocorrect="off"
      @change="changeHandler"
      @click.stop="clickHandler"
      @focusin="focusinHandler"
      @focusout="focusoutHandler"
      @keyup="keyupHandler"
      @keypress="keypressHandler"
    />

    <!-- calc button -->
    <btn
      size="sm"
      unstyled
      class="bg-transparent !text-white hover:text-white !absolute left-0 top-0 w-[20px] h-[100%] z-20"
      :action="openCalculator"
      v-show="showButton"
    >
      <font-awesome-icon icon="calculator" size="sm" />
    </btn>

    <drop
      v-if="requested"
      ref="drop"
      classes="calculator--drop"
      :fixTo="`[data-uid=&quot;${uid}&quot;]`"
      position="bottom right"
      targetAttachment="bottom right"
      offset="0px 0px"
      @open="openHandler"
      @close="closeHandler"
      @click="() => $refs.contentEditable.focus()"
    >
      <calculator
        v-bind="$props"
        ref="calculator"
        v-model="fieldValue"
        :equation="equationValue"
        :value="typedValue"
        :getDimOnOpen="true"
        @equation="calcEquationHandler"
      />
    </drop>
  </div>
  <textarea
    v-else-if="componentIs === 'textarea'"
    :type="componentType"
    ref="input"
    :class="[
      ...inputClass,
      ...componentClass,
      'textarea',
      'border border-surface-200 focus:border-pitch-black focus:border-2 bg-transparent focus:bg-white text-sm',
      'outline-none rounded-sm p-2 border border-surface-400 focus:border-2 focus:border-blue-print'
    ]"
    :style="inputStyle"
    :data-uid="uid"
    :disabled="!!disabled"
    :placeholder="placeholder"
    v-tooltip="inputTooltip"
    v-model="fieldValue"
    @change="changeHandler"
    @focusin="focusinHandler"
    @focusout="focusoutHandler"
    @keyup="keyupHandler"
    @keypress="keypressHandler"
  />
  <ContentEditable
    v-else-if="componentIs === 'div'"
    tabindex
    ref="input"
    :class="[...inputClass, ...componentClass]"
    :style="inputStyle"
    :data-uid="uid"
    :disabled="!!disabled"
    :placeholder="placeholder"
    v-tooltip="inputTooltip"
    v-model="fieldValue"
    @change="changeHandler"
    @focusin="focusinHandler"
    @focusout="focusoutHandler"
    @keyup="keyupHandler"
    @keypress="keypressHandler"
  />
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import FieldMixin from '../mixins/Field'
import ContentEditable from './ContentEditable.vue'
/**
 * See /mixins/SchemaField for advanced validation logic etc
 */
export default {
  name: 'Field',

  props: {
    /**
     * Input type
     *  -textarea
     *  -text
     *  -password
     *  -contenteditable
     */
    type: {
      type: String,
      default: 'text'
    },

    showButton: {
      default: true
    },

    disabled: {
      default: false
    },

    focusOnMount: {
      type: Boolean,
      default: false
    },

    pt: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['enter', 'submit', 'tab', 'next'],

  watch: {
    focused(f) {
      if (!f) {
        setTimeout(() => {
          if (!this.equationValue) this.hideButton = true
        }, 1000)
      } else {
        this.hideButton = false
      }
    },
    rawValue() {
      if (this.componentIs === 'textarea') {
        this.resizeTextarea()
      }
    }
  },

  mixins: [FieldMixin],

  methods: {
    mouseoverHandler() {
      this.hovering = 1
    },
    mouseoutHandler() {
      setTimeout(() => {
        this.hovering = 0
      }, 2000)
    },
    async calcEquationHandler(e) {
      this.setTypedValue(e, false)

      this.emitEquation()
    },

    async closeHandler() {
      const calValue = this.$refs.calculator
        ? this.$refs.calculator.getEquation()
        : this.$refs.input.getEquation()

      await c.throttle(() => {}, { key: `${this.uid}waitforval`, delay: 100 })
      this.startedTypingSinceFocus = this.focused

      this.setTypedValue(calValue, false)
      this.emitEquation()
      this.emitValue()

      this.calculatorOpen = false
    },

    async openHandler() {
      this.startedTypingSinceFocus = true
      this.calculatorOpen = true
    },

    async openCalculator() {
      this.focusinHandler()
      this.startedTypingSinceFocus = true
      await this.$refs.calculator.getPossibleDimensions()
      await this.$refs.drop.open()
      this.$refs.calculator.setTypedValue(this.typedValue, false)
      this.$refs.calculator.selectAll()
      // this.focusoutHandler();
    },

    enterHandler() {
      if (!this.enableGoToNextField) {
        return
      }

      this.$emit('enter')
      this.$emit('submit')
    },

    tabHandler() {
      if (!this.enableGoToNextField) {
        return
      }

      this.$emit('tab')
      this.$emit('next')
    },

    resizeTextarea() {
      if (this.componentIs !== 'textarea') {
        return
      }

      const ta = this.$el
      const returns = (String(ta.value).match(/\n|\r\n|\r/gi) || []).length
      const lineHeight = c.toNum(window.getComputedStyle(ta, null).getPropertyValue('line-height'))
      const fontSize = c.toNum(window.getComputedStyle(ta, null).getPropertyValue('font-size'))
      const totalCharWidth = (ta.value.length - returns) * fontSize
      const width = c.toNum(window.getComputedStyle(ta, null).getPropertyValue('width'))
      const charPerLine = width / (fontSize * 1.02)
      const lines = Math.ceil(((totalCharWidth - charPerLine * returns) / width) * 0.5 + returns)
      const height = lines * lineHeight * 1.1 + 50
      this.height = height
    }
  },

  mounted() {
    if (this.autoSelect) {
      this.$refs.input.focus()
    }

    if (this.focusOnMount) {
      setTimeout(() => this.focus(), 500)
    }
  },

  data() {
    return {
      height: 0,
      requested: true,
      calculatorOpen: false,
      hideButton: true,
      hovering: 0
    }
  },

  computed: {
    /**
     *
     */
    inputStyle() {
      if (this.componentIs !== 'textarea') return ''
      return `height: ${this.height}px !important; min-height: 100px !important;`
    },

    /**
     *
     */
    enableGoToNextField() {
      return this.isComponent !== 'textarea'
    },

    /**
     * @return string
     */
    componentIs() {
      switch (this.type) {
        case 'textarea':
        case 'desc':
          return 'textarea'
        case 'number':
          return 'number'
        case 'calculator':
          return 'calculator'
        case 'calculator-inline':
          return 'calculator'
        case 'div':
        case 'contenteditable':
        case 'contente-ditable':
          return 'div'
        default:
          return 'input'
      }
    },

    /**
     * @return string
     */
    componentType() {
      switch (this.type) {
        case 'password':
        case 'pass':
          return 'password'
        default:
          return 'text'
      }
    },

    fieldId() {
      return this.schema || this.format || this.type
    }
  },

  components: {
    ContentEditable,
    InputText,
    InputNumber
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
textarea.field-component::placeholder {
  color: $surface-300;
}
.field-component.field-component--calculator {
  position: relative;
  overflow: visible;

  display: inline-flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  padding: 0;

  .calculator-dongle {
    position: absolute !important;
    z-index: $z-layout;
    opacity: 0.7;
    font-size: 0.8em;
    left: 0.1em;
    top: 0.1em;
  }
}
</style>
