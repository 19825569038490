<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { updatePassword as updatePasswordAmplify } from 'aws-amplify/auth'
import { usePasswords } from '@/composables/passwords'
import CardList from '@/components/ui/Cards/CardList.vue'
import CardListField from '@/components/ui/Cards/CardListField.vue'
import PasswordField from '@/components/ui/fields/PasswordField.vue'
import UsernameField from '@/components/ui/fields/UsernameField.vue'

const emit = defineEmits(['password-reset'])

const store = useStore()

const {
  password: newPassword,
  confirmPassword,
  passwordsMatch,
  showPasswordMatch,
  passwordStrength,
  updatePasswordStrength,
  resetPasswordStrength,
  setPasswordValid
} = usePasswords()

const currentPassword = ref(null)

const prModal = ref(null)

const canResetPassword = computed(() => {
  if (!currentPassword.value) {
    return false
  }
  if (!passwordStrength.value.valid) {
    return false
  }
  if (!passwordsMatch.value) {
    return false
  }
  // Password can therefore be reset
  return true
})

function open() {
  prModal.value.open()
}
function close() {
  prModal.value.close()
}
function cancel() {
  currentPassword.value = null
  newPassword.value = null
  confirmPassword.value = null
  resetPasswordStrength()
  close()
}
async function updatePassword() {
  try {
    await updatePasswordAmplify({
      oldPassword: currentPassword.value,
      newPassword: newPassword.value
    })
  } catch (e) {
    await store.dispatch('alert', {
      error: true,
      message: e.message || 'Could not update password.  Please try again.'
    })
  }

  await store.dispatch('alert', {
    message: 'Password successfully updated.'
  })

  emit('password-reset')
  close()
}
function doPasswordReset() {
  if (passwordsMatch.value) {
    updatePassword()
  }
}

defineExpose({
  open,
  close
})
</script>

<template>
  <Modal ref="prModal" :click-away="false" :show-top-close="false">
    <template #body>
      <div class="mb-3">
        <span>Reset your password</span>
      </div>
      <form>
        <UsernameField context="reset" :value="$store.state.session.user.user_email" />
        <CardList>
          <CardListField :mobile-stack="true">
            <label>Current Password</label>
            <PasswordField context="reset" intent="current" v-model="currentPassword" />
          </CardListField>
          <CardListField :mobile-stack="true">
            <label>New Password</label>
            <PasswordField
              context="reset"
              intent="new"
              v-model="newPassword"
              @score="updatePasswordStrength"
              @valid="setPasswordValid"
            />
            <template #below>
              <warning v-if="passwordStrength.weak">
                <div v-if="passwordStrength.warning">{{ passwordStrength.warning }}</div>
                <div v-if="passwordStrength.warning && passwordStrength.suggestions">
                  <br />
                </div>
                <div v-if="passwordStrength.suggestions">
                  <strong>Hints:</strong>
                  <div
                    v-for="suggestion in passwordStrength.suggestions"
                    class="danger"
                    :key="suggestion"
                  >
                    {{ suggestion }}
                  </div>
                </div>
              </warning>
            </template>
          </CardListField>
          <CardListField :mobile-stack="true">
            <label>Confirm Password</label>
            <PasswordField context="reset" intent="confirm" v-model="confirmPassword" />
            <template #below v-if="showPasswordMatch">
              <danger>Passwords do not match</danger>
            </template>
          </CardListField>
        </CardList>
      </form>
      <info class="mt-5">
        <p>Set yourself a <strong>secure</strong> but <strong>memorable</strong> password!</p>
        <br />
        <p>Passwords must meet the following criteria:</p>
        <br />
        <ul>
          <li>Minimum length: 8</li>
          <li>Contain at least ONE uppercase character</li>
          <li>Contain at least ONE lowercase character</li>
          <li>Contain at least ONE symbol</li>
          <li>Contain at least ONE numeric character</li>
        </ul>
        <br />
        <p>Passphrases and password managers are encouraged</p>
      </info>
    </template>
    <template #footer>
      <div class="flex justify-end gap-x-2">
        <Btn size="lg" @click="doPasswordReset()" :disabled="!canResetPassword">Reset password</Btn>
        <Btn size="lg" @click="cancel()">Cancel</Btn>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss"></style>
