<template>
  <div
    class="quote-pres-items--list-container"
    :class="{ 'quote-pres-items--padded': parentRefId }"
  >
    <Container style="margin: 0 !important">
      <quote-presentation-item
        v-for="(ref, index) in itemsToShow"
        :reference="ref"
        :refId="ref"
        :key="ref"
        :depth="depth + 1"
        :class="{
          'last-of-type':
            itemsToShow.length - 1 === index ||
            norm[itemsToShow[index + 1]].type !== norm[itemsToShow[index]].type,
          [itemsToShow[index]]: true
        }"
        :editable="editable"
        :presentationSettings="presentationSettings"
        :parentRefId="parentRefId"
        :artificialMultiplier="artificialMultiplier"
        :store="store"
      />
      <div v-if="merged.length && !showAll" class="presentation-cost-item-container">
        <div
          class="cost-item-inner flex"
          style="justify-content: center !important; align-items: center !important"
        >
          <btn-group class="sm">
            <Btn class="default" @click="showAll = 1">
              {{ merged.length }} {{ l('more general items') }}...
            </Btn>
          </btn-group>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import QuotePresentationItem from './QuotePresentationItem.vue'
import TranslationMixin from './languages/TranslationMixin'

export default {
  name: 'QuotePresentationItems',
  mixins: [TranslationMixin],
  data() {
    return {
      showDeEmphasized: 0,
      merged: [],
      visible: [],
      showAll: 0
    }
  },
  watch: {},
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },
    itemsToShow() {
      if (this.showAll) return this.items
      return this.visibleItems
    },
    visibleItems() {
      const merged = []
      const visible = []
      this.items.forEach((ref) => {
        const item = this.norm[ref]
        if (
          (item.type === 'cost_item' && item.cost_type_emphasis < 0) ||
          (item.type === 'assembly' && item.assembly_emphasis <= -3) ||
          item.cost_type_is_task
        ) {
          merged.push(ref)
        } else {
          visible.push(ref)
        }
      })
      this.merged = merged
      this.visible = visible
      return this.visible
    },
    mergedPrice() {
      const norm = _.imm(this.norm)
      return this.merged.reduce(
        (acc, ref) =>
          acc +
          (norm[ref].type === 'assembly'
            ? norm[ref].quote_subtotal_net
            : norm[ref].cost_item_price_net_base),
        0
      )
    },
    mergedCost() {
      const norm = _.imm(this.norm)
      return this.merged.reduce(
        (acc, ref) =>
          acc +
          (norm[ref].type === 'assembly'
            ? norm[ref].quote_total_cost_net_base
            : norm[ref].cost_item_total_cost_net_base),
        0
      )
    }
  },
  methods: {},
  components: {
    QuotePresentationItem
    /* ChangeAudit, */
  },
  props: {
    // Array of refIds
    items: {
      type: Array
    },
    depth: {
      default: 1
    },
    deselectOnDestroy: {
      default: false
    },
    store: {
      type: String,
      required: true
    },
    parentRefId: {
      type: String,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    /**
     * See QuotePresenetationAssembly
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    showGeneralPrice: {
      default: true
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-pres-items--container {
  margin: 0;
  .quote-pres-items--list-container {
    border: none;
    box-shadow: none;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .quote-pres-items--list-more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
