export default {
  name: 'DisplayLanguage',
  computed: {
    userLanguage() {
      let language = navigator.language
      const user = this.$store.state.session.user
      if (user && user.localization_language_code) {
        language = user.localization_language_code
        if (user.localization_country_code) {
          language = `${language}-${user.localization_country_code}`
        }
      }
      return language
    }
  },
  watch: {
    userLanguage(newLanguage) {
      if (newLanguage != null && newLanguage !== this.$root.$i18n.locale) {
        this.$root.$i18n.locale = newLanguage
      }
    }
  }
}
