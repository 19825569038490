<template>
  <figure
    v-if="showMarkupRelatedVariables"
    :style="`max-width: ${size}px; max-height: ${size / 2}px;`"
  >
    <div
      class="figure-content !bg-transparent"
      :style="`max-width: ${size / 2}px; max-height: ${size / 2}px;`"
    >
      <svg
        width="100%"
        height="100%"
        style=""
        viewBox="0 0 42 42"
        class="donut"
        aria-labelledby="chart-title chart-desc"
        role="img"
      >
        <circle
          class="donut-hole"
          cx="21"
          cy="21"
          r="15.91549430918954"
          role="presentation"
        ></circle>
        <circle
          class="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="2.85"
          role="presentation"
        ></circle>

        <circle
          v-if="showMarkupRelatedVariables"
          :stroke-dasharray="`${laborPercent} ${100 - laborPercent}`"
          class="donut-segment labor"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="3"
          stroke-dashoffset="0"
          aria-labelledby="donut-segment-1-title donut-segment-1-desc"
        >
          <title id="donut-segment-1-title">Labor</title>
        </circle>

        <circle
          :stroke-dasharray="`${materialsPercent} ${100 - materialsPercent}`"
          :stroke-dashoffset="materialsPercent"
          class="donut-segment materials"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="3"
        >
          <title id="donut-segment-2-title">Materials</title>
        </circle>

        <circle
          :stroke-dasharray="`${discountPercent} ${100 - discountPercent}`"
          :stroke-dashoffset="materialsPercent + discountPercent"
          class="donut-segment discount"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="3"
        >
          <title id="donut-segment-2-title">Discount</title>
        </circle>

        <g class="chart-text">
          <text x="50%" y="50%" class="chart-number" :fill="textColor">
            {{ profitDisplay }}
          </text>
          <text x="50%" y="60%" class="chart-label" :fill="textColor">Profit</text>
        </g>
      </svg>
    </div>
    <figcaption
      class="figure-key"
      v-if="showLabels"
      :style="`max-width: ${size / 2}px; max-height: ${size / 2}px;`"
    >
      <ul
        class="figure-key-list"
        aria-hidden="true"
        role="presentation"
        :style="{ color: textColor }"
      >
        <li v-if="showMarkupRelatedVariables">
          <span class="shape-circle labor"></span> Labor {{ laborPercent }}%
        </li>
        <li><span class="shape-circle materials"></span> Materials {{ materialsPercent }}%</li>
        <li v-if="discount">
          <span class="shape-circle discount"></span> Discount {{ discountPercent }}%
        </li>
        <li><span class="shape-circle profit"></span> Profit {{ profitPercent }}%</li>
      </ul>
    </figcaption>
  </figure>
</template>

<script>
import CheckaUserPermsMixin from '../mixins/CheckaUserPermsMixin'

export default {
  name: 'CostChart',
  mixins: [CheckaUserPermsMixin],
  computed: {
    materialsPercent() {
      return Math.round((this.materials / this.rate) * 100)
    },
    laborPercent() {
      return Math.round((this.labor / this.rate) * 100)
    },
    discountPercent() {
      return Math.round((this.discount / this.rate) * 100)
    },
    profit() {
      return (
        Math.round((this.rate - this.labor - this.materials - this.discount) * this.qty * 100) / 100
      )
    },
    profitPercent() {
      return 100 - this.materialsPercent - this.laborPercent - this.discountPercent
    },
    profitDisplay() {
      return this.profit > 100
        ? this.formatCurrency(this.profit, 0)
        : this.formatCurrency(this.profit, 2)
    }
  },
  props: {
    size: {
      type: Number,
      default: 400
    },
    materials: {
      required: true,
      default: 15
    },
    labor: {
      required: true,
      default: 30
    },
    rate: {
      required: true,
      default: 100
    },
    discount: {
      required: false,
      default: 0
    },
    qty: {
      required: true,
      default: 1
    },
    textColor: {
      required: false,
      default: 'white'
    },
    showLabels: {
      default: true
    }
  },
  methods: {
    formatCurrency(val, dec = 2) {
      if (!this.$store || !this.$store.state.session.user || !this.$store.state.session.company) {
        return c.format(val, 'currency')
      }

      return c.toNum(val).toLocaleString(undefined, {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.chart-text {
  fill: $flame-white;
  display: flex;
  align-items: center;
  justify-content: center;
  .chart-number {
    font-size: 0.35em;
    line-height: 1;
    text-anchor: middle;
  }
  .chart-label {
    font-size: 0.25em;
    text-anchor: middle;
    font-weight: normal;
  }
}
figure {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  margin: 0;

  > .figure-content {
    flex: 1 1 auto;
  }
  > figcaption {
    flex: 1 1 auto;
  }

  .donut-hole {
    fill: transparent;
  }
  .donut-ring {
    // profit
    stroke: $cement-800;
  }
  .donut-segment.materials {
    stroke: $cool-gray-300;
  }
  .donut-segment.labor {
    stroke: $deep-red-400;
  }
  .donut-segment.discount {
    stroke: $matcha-500;
  }
}
@media (min-width: 768px) {
  figure {
    flex-direction: row;
  }
}
.figure-content,
.figure-key {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  align-self: center;
}
.figure-content {
  min-width: 50%;
  max-width: 80%;
  float: left;
  padding: 0;
  border-radius: 50%;
  margin: 1em;
  background: rgba(255, 255, 255, 0.5);

  svg {
    height: auto;
  }
}
.figure-key {
  min-width: calc(8 / 12);
  float: right;
  [class*='shape-'] {
    margin-right: 6px;
  }
  .figure-key-list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    li {
      font-size: 0.8em;
      margin: 0 0 8px;
      padding: 0;
      .shape-circle {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        font-weight: bold;

        &.labor {
          background-color: $deep-red-400;
        }
        &.materials {
          background-color: $cool-gray-300;
        }
        &.profit {
          background-color: $cement-800;
        }
        &.discount {
          background-color: $matcha-500;
        }
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
