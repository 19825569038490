<template>
  <div class="activity-reply-item max-h-24 overflow-y-auto rounded border-l-4 border-l-primary">
    <div class="activity-reply-item--activity p-2 pt-0 pr-8">
      <p v-if="title" class="pt-2 sticky top-0 font-medium bg-surface-100">
        {{ title }}
      </p>
      <p class="mb-1 pt-2 sticky top-0 text-sm font-500 bg-surface-100">
        {{ activityCreatorName }}
      </p>
      <small class="break-words font-normal text-xs" v-html="activityMessage"></small>
    </div>
  </div>
</template>

<script setup>
import useActivityItem from '@/components/composables/ActivityItem'

// ======== Props ======== //
const props = defineProps({
  activity: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    required: false
  }
})
// ====================== //

const { activityCreatorName, activityMessage } = useActivityItem(props)
</script>

<style lang="less" scoped></style>
