export default {
  icon: 'circle-user',
  title: 'Assign items and track progress',
  desc: 'Learn about assigning items to your team, and how to set and track progress.',
  time: '1 min',
  id: 'Progress',
  setupSheet: async (sheet) => {
    sheet.addRow()
    sheet.resetColWidths()
    ;[0, 1, 2, 3, 4, 5, 6, 7].map((i) => sheet.uncollapseSuperHeader(0, i))
    ;[2, 4, 6].map((i) => sheet.collapseSuperHeader(0, i))
    sheet.triggerRedraw()
  },
  steps: [
    {
      icon: 'circle-user',
      tooltip:
        'Assign this item to a team member by clicking in this cell and selecting a name from the dropdown. You can also assign it to a subcontractor.',
      cell: ['assignee', 0],
      position: 'right'
    },
    {
      icon: 'bars-progress',
      tooltip: `This column tracks the progress of each item. There are 4 steps. When a step is blue
        that means it has been completed. Let me walk you through each step...`,
      cell: ['progress', 0],
      position: 'bottom'
    },
    {
      icon: 'house-circle-check',
      tooltip: `1. This is the first step. It will tell you whether the scope of this item in its present form has 
        been approved by your client or not.`,
      cell: ['progress', 0],
      offset: [0],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'house-circle-check',
      tooltip: `... that means they have signed off
        on a proposal (or change order) that includes this item in its current state. 
        When approved, this will become blue automatically.`,
      cell: ['progress', 0],
      offset: [0],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'house-circle-check',
      tooltip: `... all you need to get it approved is to send the proposal (or to re-send any changes thereafter). 
        Your client gets notified to approve the quote or change order, and every item
        included will become approved too. Easy!`,
      cell: ['progress', 0],
      offset: [0],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'clipboard-check',
      tooltip: `2. This is the step that is checked off when the person assigned to this item deems it complete. 
        The sub or team member can mark it complete, or the PM or general contractor can too.`,
      cell: ['progress', 0],
      offset: [40],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'clipboard-check',
      tooltip: `... materials items should be deemed complete when ordered or delivered. And labor items should be deemed
        complete when the task is done.`,
      cell: ['progress', 0],
      offset: [40],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'badge-check',
      tooltip: `3. This is the quality control step. This is the step designed for the project manager to
        approve the work done by the team member. This is the last step before getting client sign-off.`,
      cell: ['progress', 0],
      offset: [80],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'badge-check',
      tooltip: `... once this item is marked complete, this item becomes billable. Depending
        on the payment terms of this project your customer will see this item in their dashboard and it will become payable.`,
      cell: ['progress', 0],
      offset: [80],
      focus: true,
      position: 'bottom'
    },
    {
      icon: 'money-bill-wave',
      tooltip: `4. Once this item is paid and signed-off by your client, this step will become blue automatically and you
         will know that this item is complete!`,
      cell: ['progress', 0],
      offset: [120],
      focus: true,
      position: 'bottom'
    }
  ]
}
