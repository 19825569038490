<template>
  <div class="flex flex-col mt-0 md:mt-8 gap-y-4">
    <!-- Legal business name -->
    <div class="flex flex-col w-full">
      <span class="mx-1"> Legal Business Name </span>
      <div class="flex flex-row">
        <field
          :validate="{ required: true }"
          :errors="errors"
          name="company.name"
          v-model="localCounterparty.company.name"
          class="w-full !bg-flame-white border-surface-200"
        />
        <Help class="ml-2 text-xs">
          <template #title> Legal Business Name </template>
          <template v-if="country === 'us'">
            The legal business name must exactly match the one listed on the company's IRS documents
            (e.g., Letter 147C or SS-4 Confirmation letter), including capitalization and
            punctuation.
          </template>
          <template v-else-if="country === 'ca'">
            The legal business name must exactly match either the provincial or federally registered
            legal name, including capitalization and punctuation.
          </template>
          <template v-else>
            The legal business name must exactly match the registered legal name, including
            capitalization and punctuation.
          </template>
        </Help>
      </div>
      <p class="text-xs mt-0.5 mx-1 text-surface-600/75 w-full">
        The company's full legal business name. Must exactly match the one listed on the company's
        tax documents.
      </p>
    </div>

    <!-- Business number -->
    <BusinessNumber
      :errors="errors"
      name="company.tax_id"
      :validate="{ required: true }"
      :country="country"
      v-model="localCounterparty.company.tax_id"
    />

    <!-- Website -->
    <div class="flex flex-col w-full">
      <span class="mx-1"> Website </span>
      <field
        placeholder="https://example.com"
        :errors="errors"
        name="business_profile.url"
        :validate="{
          required: true
        }"
        v-model="localCounterparty.business_profile.url"
        class="w-full !bg-flame-white border-surface-200"
      />
      <p class="text-xs mt-0.5 mx-1 text-surface-600/75">Example: https://www.website.com.</p>
    </div>
  </div>
</template>
<script>
import BusinessNumber from '../fields/BusinessNumberField.vue'

export default {
  name: 'CounterpartyOnboardingCompany',
  data() {
    return {
      phoneVerification: null,
      localCounterparty: { ...this.counterparty }
    }
  },
  props: {
    counterparty: {
      type: Object
    },
    errors: {
      type: Array
    }
  },
  computed: {
    country() {
      return this.$store.state.session.company.company_country
    }
  },
  watch: {
    localCounterparty: {
      handler(newValue) {
        this.$emit('update:counterparty', newValue)
      },
      deep: true
    }
  },
  components: {
    BusinessNumber
  }
}
</script>
