<script setup>
import { ref, onMounted, defineEmits, defineProps, computed } from 'vue'
import OverlayPanel from 'primevue/overlaypanel'
import ChangeAudit from '@/components/ChangeAudit/ChangeAudit.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import Restore from '@/components/composables/Restore'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Saving from '@/components/headers/Saving'
import Utilities from '../../../../imports/api/Changes/Utilities'

const $router = useRouter()
const $store = useStore()
const forceDirty = ref(false)
const back = ref('')

defineEmits(['save'])

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  defaultClosePath: {
    type: String,
    default: '/pipeline'
  },
  // Save function to save changes made in focus page
  save: {
    type: Function,
    required: false
  },
  forceSave: {
    type: Boolean,
    default: false
  },
  hideSaveButtons: {
    type: Boolean,
    default: false
  }
})

const refChanges = ref(null)
const refCloseButton = ref(null)
const { refId, changes, isDirty, type, storeName, isInPresentation } =
  RouteEntityContext.useRouteEntityContext({
    trackChanges: true
  })

const { save, resetChanges } = Saving.useSaving({
  refId,
  changes,
  isDirty,
  type,
  storeName,
  save: props.save,
  refChanges,
  forceSave: props.forceSave
})

const { flushBackup, getBackup, restoreBackup, setRefId } = Restore.useRestore({
  type: type.value,
  refId: refId.value,
  store: storeName.value,
  trackChanges: false
})

onMounted(() => {
  // save the route you came from so you can go back to it when closing
  back.value = $router.options.history.state.back
})

const inCompanyScope = computed(() => $store.state.session.scope.company)
const saveHandler = async () => {
  setRefId(refId.value)
  const backup = getBackup()
  // no changes return
  if (!inCompanyScope.value || (!isDirty.value && !backup)) {
    if (refCloseButton.value) {
      refCloseButton.value.loadingLocal = 0
      refCloseButton.value.progressLocal = 0
    }
    return true
  }

  // confirm to save changes or discard
  const response = await $store.dispatch('modal/asyncConfirm', {
    message: 'Do you want to save your changes?',
    yes: 'Save changes',
    no: 'Discard changes',
    close: 'Cancel'
  })
  // cancel closing
  if (response && response === 'close') return false

  // confirm closing and saving
  if (response) {
    // restore backup with changes
    if (backup) await restoreBackup(backup)
    save()
    $store.dispatch('alert', {
      message: 'Saving...'
    })
    return true
  }

  // discard changes and close
  await resetChanges(false)
  // discard any cached changes
  flushBackup()

  return true
}

const closeHandler = async () => {
  const shouldRedirect = await saveHandler()

  if (!shouldRedirect) return

  if (back.value && $router.currentRoute.value.path !== back.value && !isBackInfiniteLoop()) {
    await $store.dispatch('to', back.value)
  } else {
    await $store.dispatch('to', props.defaultClosePath)
  }
}

const isBackInfiniteLoop = () => {
  const from = $router.currentRoute.value.path
  const to = back.value

  const toList = ['estimate', 'create/lead_rotation', 'super/user/']
  const fromList = ['client', 'bolster_showcase', 'super/company/', 'invoice']

  // Check if `to` includes any string from `toList` and `from` includes any string from `fromList`
  const toMatches = toList.some((item) => to.includes(item))
  const fromMatches = fromList.some((item) => from.includes(item))

  if (toMatches && fromMatches) return true

  return false
}

const denormalizedChanges = ref({})
const changeType = ref('Explicit')

const showExplicitChanges = () => {
  changeType.value = 'Explicit'
  denormalizedChanges.value = Utilities.denormalizeDiffSet(changes.value.explicit)
}

const showAllChanges = () => {
  changeType.value = 'All'
  denormalizedChanges.value = Utilities.denormalizeDiffSet(changes.value.all)
}
const toggleChanges = (e) => {
  showExplicitChanges()
  if (isDirty) refChanges.value.toggle(e)
}
</script>

<template>
  <div
    class="h-[74px] w-full grid grid-cols-3 md:grid-cols-5 lg:grid-cols-8 px-safe-0 gap-x-2 items-center justify-between border-b-2 border-surface-200 z-10 sticky top-0 bg-flame-white"
    :class="{ '!grid-cols-8 min-h-14': isInPresentation }"
  >
    <div :class="{ '!col-span-2': isInPresentation }">
      <slot name="left">
        <Btn
          ref="refCloseButton"
          link
          size="lg"
          class="font-medium whitespace-nowrap shrink-0"
          :action="closeHandler"
        >
          <font-awesome-icon icon="arrow-left" />
          <span :class="{ 'hidden md:block': isInPresentation }">Close</span>
        </Btn>
      </slot>
    </div>

    <div
      class="col-start-2 lg:col-start-3 md:col-span-3 lg:col-span-4"
      :class="{ '!col-span-4 text-center': isInPresentation }"
    >
      <slot name="middle">
        <span class="text-md font-medium">{{ title }}</span>
      </slot>
    </div>

    <div
      class="lg:col-span-2 flex flex-row justify-end gap-2"
      :class="{ '!col-span-2': isInPresentation }"
    >
      <slot name="before-changes"></slot>
      <slot name="changes" v-if="isDirty">
        <Btn
          link
          :disabled="!isDirty"
          class="shrink-0"
          @click.capture.native="(e) => toggleChanges(e)"
          v-if="isDirty"
          size="lg"
        >
          <font-awesome-icon icon="clock-rotate-left" class="mr-1" />
          <span class="hidden xl:inline-block">Changes</span>
        </Btn>
        <OverlayPanel ref="refChanges">
          <div class="px-4 py-2">
            <span class="font-medium"> {{ changeType }} changes </span>
            <ChangeAudit
              class="dark"
              v-if="isDirty"
              :type="type"
              :startOpen="true"
              :changes="denormalizedChanges"
            />
            <div v-else>No changes to show</div>

            <div class="flex gap-2" v-if="isDirty">
              <Btn
                :action="resetChanges"
                unstyled
                class="border border-surface-300 hover:border-surface-100 rounded-sm py-0.5 px-1"
                size="xs"
              >
                Clear changes
              </Btn>
              <Btn
                :action="showAllChanges"
                unstyled
                class="border border-surface-300 hover:border-surface-100 rounded-sm py-0.5 px-1"
                size="xs"
                v-if="changeType === 'Explicit'"
              >
                See all derived changes...
              </Btn>
            </div>
          </div>
        </OverlayPanel>
      </slot>
      <slot name="saveStatus" v-if="!hideSaveButtons">
        <Btn
          v-if="isDirty || forceDirty"
          :action="save"
          severity="bolster"
          hotkey="cmd-s"
          size="lg"
        >
          Save changes
        </Btn>
        <Btn v-else link disabled size="lg">
          <font-awesome-icon icon="check" />
          Fully saved
        </Btn>
      </slot>

      <slot name="right"> </slot>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
