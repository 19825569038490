<template>
  <ActivityItemLayout :activity="activity" @onEdit="onEdit" @onDelete="onDelete" @onReply="onReply">
    <Avatar
      shape="circle"
      :label="`${activityCreatorName[0] || '?'}`"
      :pt="{
        root: 'h-12 w-12 inline-flex items-center justify-center rounded-full bg-surface-0 border'
      }"
    />
    <div class="activity-channel-item--message overflow-hidden flex-1">
      <div class="activity-channel-item--message--header flex items-center px-2">
        <p
          class="activity-channel-item--message--header--title font-medium text-sm font-500 h-max mr-2"
          :class="{ 'flex-1': !activity.temp }"
        >
          {{ activityCreatorName }}
        </p>
        <div v-if="activity.temp" class="mr-2 flex-1">
          <ProgressSpinner :pt="{ root: 'h-4 w-4', spinner: 'w-full h-full' }" />
        </div>
        <p
          class="activity-channel-item--message--header--time text-xs font-normal text-surface-300 h-max"
        >
          {{ formattedTimeCreatedHour }}
        </p>
      </div>
      <div class="activity-channel-item--message--text w-full p-2">
        <ActivityReplyItem
          v-if="parentActivityId"
          :activity="parentActivity"
          class="mb-2 bg-surface-100"
        />
        <p
          class="activity-channel-item--message--text--body block break-all text-xs font-normal mb-2"
          v-html="activityMessage"
        ></p>
        <div v-if="hasFiles" class="activity-channel-footer--attachments--files">
          <ActivityFiles :files="activityFiles.map((url) => ({ src: url }))" />
        </div>
      </div>
      <div class="activity-channel-item--message--files"></div>
    </div>
  </ActivityItemLayout>
</template>

<script setup>
import useActivityItem from '@/components/composables/ActivityItem'
import ActivityItemLayout from '@/components/Activities/ActivityItemType/ActivityItemLayout.vue'
import ActivityReplyItem from '@/components/Activities/ActivityItemType/ActivityReplyItem.vue'
import ActivityFiles from '../Files/ActivityFiles.vue'
import ProgressSpinner from 'primevue/progressspinner'
import Avatar from 'primevue/avatar'

// ======== Props ======== //
const props = defineProps({
  activity: {
    type: Object,
    required: true
  },
  prevActivity: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['onReply', 'onEdit', 'onDelete'])
// ====================== //

// ======== Methods ======== /
const onReply = () => {
  emit('onReply')
}
const onEdit = () => {
  emit('onEdit')
}
const onDelete = () => {
  emit('onDelete')
}
// ====================== //

// ======== Composables ======== //
const {
  activityCreatorName,
  activityMessage,
  formattedTimeCreatedHour,
  parentActivity,
  parentActivityId,
  hasFiles,
  activityFiles
} = useActivityItem(props)
// ====================== //
</script>

<style lang="scss">
.activity-channel-item--message--text--body {
  > span {
    color: $secondary;
    background: $secondary-0;
    font-weight: 500;
    border-radius: 0.25rem;
    padding: 0.1rem;
    cursor: pointer;
  }
}
</style>
