// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'user_permission',
  
  skipAudit: true,

  fields: {
    user_permission_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    user_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    permission_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'permission',
    },
    user_permission_self: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    user_permission_others: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
