<template>
  <div class="h-full">
    <Loader :loading="totalProgress > 0 && totalProgress < 100 ? 1 : 0" :progress="totalProgress" />
    <Sheets
      ref="sheet"
      :border-radius="5"
      :default-cell-height="40"
      :freeze="1"
      :sheets="budgetSheets"
      @cellTyping="onSearch"
    >
      <template #sheetOption>
        <choose
          size="sm"
          class="flex items-center h-full"
          :multiple="false"
          :staticSet="sheetFilters"
          v-model="selectedSheetFilterIndex"
        />
      </template>
    </Sheets>
  </div>
</template>

<script setup>
import Sheets from '../../Sheets/Sheets.vue'
import { ref, computed, watch, nextTick, onMounted, onBeforeUnmount } from 'vue'
import EntitySheet from '@/components/Sheets/quote/EntitySheet'
import FieldSetters from '@/components/composables/EntityFields/FieldSetters'
import EquationSetters from '@/components/Sheets/EquationSetters.js'
import Loader from '@/components/ui/Loader.vue'
import { useStore } from 'vuex'
import { isNotItem } from '@/../imports/api/Item'

// ===== Props ===== //
const props = defineProps({
  refId: {
    type: String,
    required: true
  }
})
const store = ref('Quote')
// ================= //

// ===== Refs ===== //
const sheet = ref(null)
// ================ //

// ===== Composables ===== //
const $store = useStore()
const { norm, auditProgress, addingProgress, storeLoading } = EntitySheet.useEntitySheet({
  store: store.value,
  refId: props.refId,
  refSheet: sheet,
  fieldSetters: FieldSetters,
  equationSetters: EquationSetters,
  fieldMapping: {
    cost_item: {
      stage_id: 'stage_id',
      vendor_id: 'vendor_id',
      cost_type_name: 'cost_type_name',
      cost_item_total_cost_net: 'cost_item_total_cost_net',
      cost_item_actual_total_cost_net: 'cost_item_actual_total_cost_net'
    },
    assembly: {},
    quote: {}
  }
})
// ======================= //

// ===== Data ===== //
const searchPhrase = ref('')
const selectedSheetFilterIndex = ref(0)
const sheetFilters = ref([
  {
    text: 'Construction stage',
    field: 'stage_id',
    schema: 'stage',
    value: 0
  },
  {
    text: 'Vendor',
    field: 'vendor_id',
    schema: 'vendor',
    value: 1
  },
  {
    text: 'Trade group',
    field: 'trade_type_id',
    schema: 'trade_type',
    value: 2
  },
  {
    text: 'Budget code',
    field: 'cost_type_budget_code',
    schema: null,
    value: 3
  }
])
// ================ //

// ===== Computed ===== //
const sheetRowOptions = computed(() => sheet.value?.refRowOptions)
const totalProgress = computed(() => {
  const agg = [auditProgress?.value, addingProgress?.value, storeLoading?.value]

  const loading = agg.filter((l) => l > 0 && l < 100)

  return c.divide(
    loading.reduce((acc, l) => acc + l, 0),
    loading.length
  )
})
const superHeaders = computed(() => [
  {
    title: 'Classification',
    span: [1, 4]
    // formatting: {
    //   background: '#75ac3080'
    // }
  },
  {
    title: 'Location',
    icon: 'sign',
    span: [5, 5],
    expanded: true
    // formatting: {
    //   background: '#9FC1FF80'
    // }
  },
  {
    title: 'Unit pricing',
    icon: 'tag',
    span: [6, 11]
    // formatting: {
    //   background: '#C59FFF80'
    // }
  },
  {
    title: 'Budgeting',
    icon: 'receipt',
    span: [12, 16],
    expanded: true
    // formatting: {
    //   background: '#FF9FBC7F'
    // }
  }
])
const budgetColumns = computed(() => {
  return [
    {
      title: 'Name',
      field: 'cost_type_name',
      formatting: {
        width: 250,
        align: 'left',
        bold: true
      },
      computedValue: (rowData) => {
        if (rowData?.childrenIds?.length > 0) {
          return parentNames.value[rowData.cost_type_name]
        }
        return rowData.cost_type_name
      },
      readOnly: isParent
    },
    {
      title: 'Vendor',
      field: 'vendor_id',
      choose: {
        schema: 'cost_type:vendor_id'
      },
      formatting: {
        width: 125,
        align: 'left'
      },
      disabled: isParent
    },
    {
      title: 'Construction stage',
      field: 'stage_id',
      choose: {
        schema: 'cost_type:stage_id'
      },
      formatting: {
        width: 125,
        align: 'left'
      },
      disabled: isParent
    },
    {
      title: 'Budget code',
      field: 'cost_type_budget_code',
      formatting: {
        width: 125,
        align: 'left'
      },
      disabled: isParent
    },
    {
      title: 'Trade type',
      field: 'trade_type_id',
      choose: {
        schema: 'cost_type:trade_type_id'
      },
      formatting: {
        width: 125,
        align: 'left'
      },
      disabled: isParent
    },
    {
      title: 'Location',
      field: 'cost_item_location',
      formatting: {
        width: 200,
        align: 'left',
        color: '#888'
      },
      disabled: isParent
    },
    {
      title: 'Quantity',
      titleColSpan: 2,
      field: 'cost_item_qty_net',
      formatting: {
        format: 'number',
        width: 50,
        align: 'right',
        bold: true,
        color: '#666',
        borders: {
          right: {
            thickness: 0
          }
        }
      },
      disabled: isParent
    },
    {
      field: 'unit_of_measure_id',
      choose: {
        schema: 'unit_of_measure:unit_of_measure_id'
      },
      formatting: {
        width: 60,
        align: 'left',
        color: '#888',
        borders: {
          left: {
            thickness: 0
          }
        }
      },
      disabled: isParent
    },
    {
      title: 'Labor cost',
      field: 'cost_item_labor_cost_net',
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      disabled: isParent
    },
    {
      title: 'Material cost',
      field: 'cost_item_materials_cost_net',
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      disabled: isParent
    },
    {
      title: 'Material purchase',
      field: 'purchase_cost_item_qty_net',
      titleColSpan: 2,
      formatting: {
        format: 'number',
        width: 50,
        align: 'right',
        bold: true,
        color: '#666',
        borders: {
          right: {
            thickness: 0
          }
        }
      },
      disabled: isParent
    },
    {
      field: 'purchase_unit_of_measure_id',
      choose: {
        schema: 'unit_of_measure:unit_of_measure_id'
      },
      formatting: {
        width: 60,
        align: 'left',
        color: '#888',
        borders: {
          left: {
            thickness: 0
          }
        }
      },
      disabled: isParent
    },
    {
      title: 'Budgeted cost',
      field: 'cost_item_total_cost_net',
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      readOnly: isParent
    },
    {
      title: 'Actual cost',
      field: 'cost_item_actual_total_cost_net',
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      conditionalFormatting: (value, cell, row) => {
        if (row.cost_item_actual_total_cost_net > row.cost_item_total_cost_net) {
          return {
            preset: 'danger'
          }
        }
        if (row.cost_item_actual_total_cost_net < row.cost_item_total_cost_net) {
          return {
            preset: 'success'
          }
        }
      },
      readOnly: isParent
    },
    {
      title: 'Cost variance',
      field: 'cost_item_budget_cost_variance',
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      readOnly: true,
      conditionalFormatting: (value) => {
        if (value < 0) {
          return {
            preset: 'danger'
          }
        }
        if (value > 0) {
          return {
            preset: 'success'
          }
        }
      },
      computedValue: (rowData) =>
        c.toNum(rowData.cost_item_total_cost_net - rowData.cost_item_actual_total_cost_net, 2)
    },
    {
      title: 'Markup',
      field: 'cost_item_markup_net_adjusted',
      formatting: {
        format: 'currency',
        width: 75,
        align: 'right',
        bold: true
      },
      totals: {
        method: 'null'
      },
      conditionalFormatting: (value) => {
        const minimumMargin = $store.state.session.company.company_minimum_quote_margin
        const defaultMarkup = $store.getters.defaultMarkup
        const margin = _.markupToMargin(value)

        if (value < 1) {
          return {
            preset: 'danger'
          }
        } else if (margin < minimumMargin) {
          return {
            preset: 'warning'
          }
        } else if (value > defaultMarkup) {
          return {
            preset: 'success'
          }
        }
      },
      disabled: isParent
    },
    {
      title: 'Price',
      field: 'cost_item_price_net',
      readOnly: isParent,
      formatting: {
        format: 'currency',
        width: 125,
        align: 'right',
        bold: true
      },
      conditionalFormatting: (value, cell, row) => {
        const minimumMargin = $store.state.session.company.company_minimum_quote_margin
        const defaultMarkup = $store.getters.defaultMarkup
        const margin = _.markupToMargin(row.cost_item_markup_net_adjusted)

        if (row.cost_item_markup_net_adjusted < 1) {
          return {
            preset: 'danger'
          }
        } else if (margin < minimumMargin && value > 0) {
          return {
            preset: 'warning'
          }
        } else if (row.cost_item_markup_net_adjusted > defaultMarkup && value > 0) {
          return {
            preset: 'success'
          }
        }
      }
    }
  ]
})
const groupByField = computed(() => sheetFilters.value[selectedSheetFilterIndex.value].field)
const groupBySchema = computed(() => sheetFilters.value[selectedSheetFilterIndex.value].schema)
const groupByIndex = computed(() =>
  budgetColumns.value.findIndex((col) => col.field === groupByField.value)
)
const items = computed(() => {
  return Object.values(norm.value).reduce((acc, item) => {
    if (isNotItem(item)) return acc

    acc.push({
      id: item.refId,
      stage_id: item.stage_id || undefined,
      vendor_id: item.vendor_id || undefined,
      cost_type_budget_code: item.cost_type_budget_code || undefined,
      trade_type_id: item.trade_type_id || undefined,
      cost_type_name: item.cost_type_name || undefined,
      cost_item_location: item?.aoLocation?.[item?.aoLocation?.length - 1]?.name || '',
      cost_item_qty_net: c.toNum(item.cost_item_qty_net),
      unit_of_measure_id: item.unit_of_measure_id || undefined,
      cost_item_labor_cost_net: c.toNum(item.cost_item_labor_cost_net),
      cost_item_materials_cost_net: c.toNum(item.cost_item_materials_cost_net),
      purchase_cost_item_qty_net: c.toNum(item.purchase_cost_item_qty_net),
      purchase_unit_of_measure_id: item.purchase_unit_of_measure_id || undefined,
      cost_item_markup_net_adjusted: c.toNum(item.cost_item_markup_net_adjusted),
      cost_item_price_net: c.toNum(item.cost_item_price_net),
      cost_item_total_cost_net: c.toNum(item.cost_item_total_cost_net, 2),
      cost_item_actual_total_cost_net: c.toNum(item.cost_item_actual_total_cost_net, 2)
    })
    return acc
  }, [])
})
const parentNames = computed(() => {
  return sheet.value?.fetchedNames?.[groupBySchema.value]?.names || []
})
const budgetSheets = computed(() => [
  {
    title: 'Budget',
    sorting: [],
    grouping: [],
    rows: items.value,
    columns: budgetColumns.value,
    superHeaders: superHeaders.value,
    group: {
      group: [groupByIndex.value],
      showHeadings: true,
      showTotals: true,
      spacing: 50
    }
  }
])
// ================ //

// ===== Methods ===== //
const onRefreshSheet = async () => {
  await nextTick()
  sheet.value?.silentReload(budgetSheets.value)
}
const onSearch = (val) => {
  searchPhrase.value = val
}
const onStateChange = async (changeManager, refIds, changes) => {
  let refresh = false
  for (const ref in changes) {
    if (groupByField.value in changes[ref]) {
      refresh = true
      break
    }
  }
  if (refresh) await _.throttle(onRefreshSheet)
}
const onMountedHook = async () => {
  const changeManager = await $store.dispatch(`${store.value}/verifyChangeManager`, {
    refId: props.refId
  })
  $store.commit({
    type: `${store.value}/ADD_CHANGE_WATCHER`,
    changeManager: changeManager,
    watcher: onStateChange
  })
}
const onUnmountedHook = async () => {
  const changeManager = await $store.dispatch(`${store.value}/verifyChangeManager`, {
    refId: props.refId
  })
  $store.commit({
    type: `${store.value}/REMOVE_CHANGE_WATCHER`,
    changeManager: changeManager,
    watcher: onStateChange
  })
}
const isParent = ({ rowData }) => {
  return rowData?.childrenIds?.length > 0
}
// =================== //

// ===== Watch ===== //
watch(selectedSheetFilterIndex, onRefreshSheet)
watch(
  sheetRowOptions,
  (val) => {
    if (val && val instanceof HTMLElement) {
      val.innerHTML = ''
    }
  },
  { immediate: true }
)
// =================== //

// ===== Life cycle ===== //
onMounted(() => onMountedHook())
onBeforeUnmount(() => onUnmountedHook())
// =================== //
</script>

<style lang="scss" rel="stylesheet/scss"></style>
