import Button from '../../mixins/Button'
import eventBus from '../../../eventBus'

export default {
  mixins: [Button],

  props: {
    parentDimensions: {
      required: true
    },
    value: {
      required: true
    },
    measureType: {
      default: 'count'
    },
    object: {
      required: true
    },
    parentName: {
      default: null
    },
    limit: {
      default: 5
    },
    showWork: {
      default: false
    },
    modifierWeight: {
      default: 15
    },
    dimensionTypeWeight: {
      default: 10
    }
  },
  emits: [
    'createdDimension',
    'unitOfMeasure',
    'equation',
    'value',
    'measureType',
    'full',
    'close',
    'dimension',
    'input'
  ],

  data() {
    return {
      searchSet: {},
      searchPhrase: '',
      measureTypeLocal: this.measureType,
      measureTypeOptions: [null, 'count', 'length', 'area', 'volume', 'weight'],
      suggestedDimensions: {},
      seeFull: false,
      dimTypes: {}
    }
  },

  watch: {
    parentDimensions() {
      this.getSuggestedDimensionsThrottle()
    },
    measureType() {
      console.log('measureType watch')
      if (this.searchPhrase) this.throttleSearch()
      else this.getSuggestedDimensionsThrottle()
    },
    object: {
      deep: true,
      handler() {
        this.getSuggestedDimensionsThrottle()
      }
    },
    parentName() {
      console.log('parentName watch')
      this.getSuggestedDimensionsThrottle()
    },
    measureTypeLocal(mt) {
      if (mt !== this.measureType || mt === null) this.throttleSearch()
      else {
        this.throttleSearch()
        this.getSuggestedDimensions()
      }
    },
    searchPhrase(ph, old) {
      if (ph !== old) this.throttleSearch()
    }
  },

  created() {
    this.getSuggestedDimensions()
    this.getPossibleDimensions()
    eventBus.$on('saved-dimension', this.getPossibleDimensions)
  },

  beforeUnmount() {
    eventBus.$off('saved-dimension', this.getPossibleDimensions)
  },

  computed: {
    metric() {
      return this.$store.state.session.user.country_id > 2
    },
    dimensionList() {
      if (Object.keys(this.dimensions).length && Object.keys(this.searchSet).length) {
        // sort all by suggested dimensions
        const sorted = Object.keys(this.searchSet).sort((a, b) => {
          const ain = a in this.dimensions
          const bin = b in this.dimensions
          if (ain && !bin) return -1
          if (bin && !ain) return 1
          return 0
        })
        return sorted.reduce(
          (acc, abbr) => ({
            ...acc,
            [abbr]: this.searchSet[abbr]
          }),
          {}
        )
      } else if (Object.keys(this.searchSet).length) {
        return this.searchSet
      } else if (Object.keys(this.dimensions).length) {
        return this.dimensions
      }

      return {}
    },
    dimensions() {
      const sorted = this.suggestedDimensions
      const available = this.parentDimensions

      const merged = {
        ...sorted,
        ...available
      }

      return Object.values(merged)
        .filter(
          (dim) =>
            this.measureType === null ||
            this.measureType === 'count' ||
            dim.measureType === this.measureType
        )
        .reduce(
          (acc, dim) => ({
            ...acc,
            [dim.abbr]: {
              ...(acc[dim.abbr] || {}),
              ...dim,
              ...(available[dim.abbr] || {})
            }
          }),
          {}
        )
    }
  },

  methods: {
    async getSuggestedDimensionsThrottle() {
      return c.throttle(() => this.getSuggestedDimensions(), { delay: 300 })
    },
    async getPossibleDimensions() {
      this.dimTypes = await this.$store.dispatch('Dimension/getPossibleDimensions', { full: true })
      return this.dimTypes
    },
    async createDimension() {
      const object = await this.$store.dispatch('create', { type: 'dimension', go: false })
      eventBus.$emit('closeAllDrops')
      if (!object) return
      const mapped = await this.$store.dispatch('Dimension/mapDimensions', [object])
      await this.getPossibleDimensions()
      this.suggestedDimensions = {
        ...mapped,
        ...this.suggestedDimensions
      }
      this.searchSet = {
        ...mapped,
        ...this.searchSet
      }
      const mappedSingle = Object.values(mapped)[0]
      this.$emit('createdDimension', mappedSingle)
      eventBus.$emit('createdDimension', mappedSingle)
    },
    setUnitOfMeasure(uom) {
      // If it is the same measure type, but non-default, leave it anyway
      if (
        c.getMeasureTypeForUnitOfMeasure(uom) ===
        c.getMeasureTypeForUnitOfMeasure(this.object.unit_of_measure_id)
      ) {
        return
      }

      // If the measure type is incompatible, change it
      this.$emit('unitOfMeasure', uom)
    },
    setEquation(eq) {
      this.$emit('equation', eq)
    },
    setValue(val) {
      this.$emit('value', val)
    },
    async chooseDimension(dim) {
      const dimType = this.dimTypes[dim]

      let uom = 'count'
      let eq = null

      if (dim === 'each') {
        uom = 'count'
        this.setUnitOfMeasure(uom)
        eq = null
        this.setEquation(eq)
        this.measureTypeLocal = 'count'
        this.choseEach = true
      } else {
        uom = this.setSuggestedUnitOfMeasure(dimType)
        eq = dim
        this.setEquation(eq)
        this.measureTypeLocal = dimType.measureType
        this.choseEach = false
      }
      this.$emit('measureType', this.measureTypeLocal)
      await this.$nextTick()
      this.$emit('full', {
        dimensionAbbr: dim,
        dimensionType: dimType,
        measureType: this.measureTypeLocal,
        unitOfMeasure: uom,
        equation: eq
      })
      this.$emit('close')
    },
    setSuggestedUnitOfMeasure(dim) {
      const uom = this.getSuggestedUnitOfMeasure(dim)
      this.setUnitOfMeasure(uom)
      this.$emit('dimension', dim)

      return uom
    },
    getSuggestedUnitOfMeasure(dim) {
      const metric = this.metric
      const mt = dim.dimension_measure_type || dim.measureType

      if (mt === 'length') {
        return metric ? 'm' : 'ft'
      } else if (mt === 'volume') {
        return metric ? 'm3' : 'yd3'
      } else if (mt === 'area') {
        return metric ? 'm2' : 'ft2'
      } else if (mt === 'weight') {
        return metric ? 'kg' : 'lbs'
      }

      return 'count'
    },
    async throttleSearch() {
      return c.throttle(this.search, {
        delay: 300,
        debounce: true
      })
    },
    resetSearch() {
      this.searchSet = {}
      this.measureTypeLocal = this.measureType
      this.searchPhrase = ''
    },
    async search() {
      this.addLoading()
      const filters = {}
      if (this.measureTypeLocal) {
        filters.dimension_measure_type = this.measureTypeLocal
      }

      if (this.$store.state.session.scope.company) {
        filters.company_id = `${this.$store.state.session.scope.company}||NULL`
      } else {
        filters.company_id = 'NULL'
      }

      const searchPhrase = this.searchPhrase

      const { set } = await this.$store.dispatch('Dimension/search', {
        filters,
        searchPhrase
      })

      this.searchSet = {}
      this.searchSet = await this.$store.dispatch('Dimension/mapDimensions', set)

      this.removeLoading()
      return set
    },
    selectDimension(abbr) {
      this.$emit('measureType', this.measureTypeLocal)
      this.$emit('input', abbr)
      this.$emit('dimension', this.dimTypes[abbr])
      this.$emit('close')
      this.setEquation(abbr)
    },
    async getSuggestedDimensions() {
      // const mt = this.measureType;
      this.addLoading()

      const measureType =
        this.measureType || c.getMeasureTypeForUnitOfMeasure(this.object.unit_of_measure_id)
      const data = {
        object: this.object,
        itemName: this.object.cost_type_name,
        itemDesc: this.object.cost_type_desc,
        parentName: this.parentName || '',
        onlySuggested: true,
        full: true,
        dimensionTypeWeight: this.dimensionTypeWeight,
        modifierWeight: this.modifierWeight,
        preferMeasureType: measureType,
        modifiedWeights: Object.keys(this.parentDimensions).reduce(
          (acc, abbr) => ({
            ...acc,
            [abbr]: this.parentDimensions[abbr].measureType === measureType ? 0.75 : 0.2
          }),
          {}
        )
      }
      // if (mt) data.preferMeasureType = mt;

      data.limit = this.limit
      data.modifierWeight = this.modifierWeight
      data.measureTypeWeight = this.measureTypeWeight

      const { dimensions: list, rank } = await this.$store.dispatch(
        'Dimension/getSuggestedDimensions',
        data
      )
      if (this.showWork) {
        console.log('rank', _.imm(rank))
      }
      this.suggestedDimensions = list
      this.endLoading()
    }
  }
}
