<template>
  <div
    class="item-summary--cost-item-container"
    :class="{
      searching: context.searchPhrase,
      searchMatch: isSearchMatch
    }"
  >
    <a class="item-summary--label item-summary--label-cost-item" @click="edit">
      <div
        class="item-summary--title item-summary--title-cost-item"
        @click.stop.prevent="openActions"
        :id="btnBarHandle"
      >
        <span
          class="badge badge-warning item-summary--badge"
          v-if="isUpgraded"
          v-tooltip="'This item is an upgraded item'"
        ></span>
        <span
          class="badge badge-client item-summary--badge"
          v-if="object.cost_type_is_highlighted_for_estimating"
          v-tooltip="'Highlighted for estimating'"
        ></span>
        <span
          class="badge badge-project item-summary--badge"
          v-if="object.cost_type_is_highlighted_for_production"
          v-tooltip="'Highlighted for production'"
        ></span>

        {{ name || 'New item' }}

        <btn-bar
          v-if="actionRequested"
          :fixTo="`#${btnBarHandle}`"
          :showButton="false"
          ref="contextMenu"
          :collapse="true"
          :actions="lineItemActions"
          position="bottom left"
          targetAttachment="bottom left"
        />
      </div>

      <spinner :loading="1" size="1em" v-if="loading" class="info mx-1" />

      <div class="item-summary--right">
        <div class="item-summary--qty">
          <btn-group style="font-size: 0.7em">
            <template v-if="linkable">
              <btn
                @click="edit"
                class="linked"
                :stop="false"
                :prevent="false"
                v-tooltip="'Unlink this quantity from parent dimensions..'"
                v-if="linkedQty"
              >
                <font-awesome-icon icon="drafting-compass" />
              </btn>
              <btn
                @click="edit"
                class="unlinked muted"
                :stop="false"
                :prevent="false"
                v-tooltip="'Lock this quantity to parent dimensions..'"
                v-else
              >
                <font-awesome-icon icon="link" />
              </btn>
            </template>

            <btn @click="edit" :class="linkedQty ? 'info' : 'muted'" :stop="false" :prevent="false">
              {{ $f.number(qty) }}
            </btn>

            <btn @click="edit" :stop="false" :prevent="false">
              {{ (unitAbbr || '').toLowerCase() === 'each' ? 'each' : unitAbbr }}
            </btn>
          </btn-group>
        </div>

        <div
          class="item-summary--sum item-summary--cost item-summary--sum-cost-item text-muted small"
          v-if="context.showForCost.includes('cost')"
        >
          {{ $f.currency(cost) }}
        </div>

        <div
          class="item-summary--sum item-summary--price item-summary--sum-cost-item"
          v-if="context.showForCost.includes('price')"
        >
          {{ $f.currency(price) }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import BtnMixin from '../../mixins/Button'

export default {
  name: 'ItemSummaryCostItem',

  mixins: [BtnMixin],
  emits: ['actions', 'searchMatch', 'swapItem', 'editItem'],

  data() {
    return {
      actionRequested: 0
    }
  },

  computed: {
    btnBarHandle() {
      const handle = `btnbar-handle-${this.refId}`.replace(/[^a-zA-Z0-9]/g, '')
      return handle
    },

    lineItemActions() {
      const a = {
        edit: {
          title: 'Edit details + costs..',
          icon: 'pencil',
          action: this.edit,
          visible: () => !this.editing
        },
        swap: {
          title: 'Replace with..',
          icon: ['fas', 'right-from-bracket'],
          action: this.swap
        },
        duplicate: {
          title: 'Duplicate',
          icon: 'clone',
          action: this.duplicate
        },
        remove: {
          title: 'Remove',
          icon: 'circle-xmark',
          action: this.remove
        },
        save: {
          title: 'Update in your catalog',
          icon: 'floppy-disk',
          action: () => this.save(false),
          visible: () => this.object.cost_type_id
        },
        saveAs: {
          title: 'Save to your catalog',
          icon: 'floppy-disk',
          action: () => this.save(true)
        }
      }
      this.$emit('actions', a)
      return a
    },

    searchContent() {
      const fields = [
        'cost_type_name',
        'cost_type_desc',
        'aoProperties',
        'vendor_name',
        'trade_type_name',
        'stage_name'
      ]

      const string = JSON.stringify(
        fields.reduce((acc, field) => [acc, this.object[field] || ''], [])
      )

      return string
    },

    isSearchMatch() {
      const searchPhrase = this.context.searchPhrase.toLowerCase()
      if (!searchPhrase) {
        return true
      }

      const parts = searchPhrase.split(' ')
      const content = this.searchContent.toLowerCase()
      const matches = parts.every((part) => content.includes(part))

      c.throttle(
        () => {
          this.$emit('searchMatch', matches)
        },
        { key: this.refId, delay: 200, debounce: true }
      )

      return matches
    },

    isUpgraded() {
      return this.object.item_is_upgraded
    },

    cost() {
      return this.object.cost_item_total_cost_net_base
    },

    linkable() {
      return c.isUnitOfMeasureLinkable(this.object.unit_of_measure_id)
    },

    linkedQty() {
      return this.linkable && this.object.cost_item_link_qty
    },

    qty() {
      return this.object.cost_item_qty_net_base
    },

    unitAbbr() {
      return this.object.unit_of_measure_abbr
    },

    price() {
      return this.object.cost_item_price_net_base
    },

    object() {
      return this.context.norm[this.refId]
    },

    name() {
      return this.object.cost_type_name
    }
  },

  props: {
    refId: {
      type: String,
      required: true
    },

    context: {
      type: Object,
      required: true
    }
  },

  methods: {
    async openActions() {
      this.actionRequested = 1

      await this.$nextTick()

      this.$refs.contextMenu.open()
    },

    async save(asNew = true) {
      this.addLoading()

      try {
        await this.$store.dispatch(`${this.context.store}/save`, {
          go: false,
          alert: true,
          asNew,
          selected: [
            {
              ...this.object,
              type: 'cost_type'
            }
          ]
        })
      } catch (e) {
        // nothing
      }

      this.endLoading()

      return this
    },

    swap() {
      this.$emit('swapItem', this.refId, this)

      return this
    },

    async duplicate() {
      this.addLoading()
      await this.$store.dispatch(`${this.context.store}/duplicateChild`, { refId: this.refId })
      this.endLoading()

      return this
    },

    async remove() {
      this.addLoading()
      await this.$store.dispatch(`${this.context.store}/removeChild`, { refId: this.refId })
      _.bench('summary remove')
      return true
    },

    async edit() {
      this.$emit('editItem', this.refId, this)

      return this
    }
  }
}
</script>
