<template>
  <div class="activity-channel-footer relative w-full max-w-2xl p-4">
    <div class="activity-channel-footer--attachments relative">
      <TransitionExpand>
        <div
          v-if="hasEditActivity"
          class="activity-channel-footer--attachments--edit z-10 relative mb-2"
        >
          <ActivityReplyItem class="bg-surface-100" title="Editing:" :activity="editActivity" />
          <div class="absolute top-0 right-0 grid content-center h-full pr-3 pl-3">
            <font-awesome-icon
              @click.native="clearInput"
              class="cursor-pointer p-1.5"
              icon="xmark"
            />
          </div>
        </div>
      </TransitionExpand>
      <TransitionExpand>
        <div
          v-if="hasFiles"
          class="activity-channel-footer--attachments--files z-10 flex overflow-x-auto mb-2"
        >
          <ActivityFiles
            :files="
              preUploadFiles.map((file) => ({
                src: file.objectURL,
                type: file.type,
                name: file.name
              }))
            "
            @removeFile="onRemoveFile"
            showRemove
          />
        </div>
      </TransitionExpand>
      <TransitionExpand>
        <div
          v-if="hasParentActivity"
          class="activity-channel-footer--attachments--parent z-10 relative mb-2"
        >
          <ActivityReplyItem
            class="bg-surface-100"
            title="Replying to:"
            :activity="parentActivity"
          />
          <div class="absolute top-0 right-0 grid content-center h-full pr-3 pl-3">
            <font-awesome-icon
              @click.native="clearParent"
              class="cursor-pointer p-1.5"
              icon="xmark"
            />
          </div>
        </div>
      </TransitionExpand>
      <TransitionExpand>
        <div
          v-if="
            mentionQueryIndex >= 0 &&
            (mentionQueryMembers.length || mentionQueryItems.length || projectLoading)
          "
          class="activity-channel-footer--attachments--members z-10 w-full max-h-64 overflow-y-auto absolute bottom-2 bg-surface-0 rounded shadow"
        >
          <div
            v-for="member in mentionQueryMembers"
            :key="member.member_id"
            @click="() => onSelectMentionMember(member)"
            class="option flex items-center p-2 w-full hover:bg-surface-100 cursor-pointer"
          >
            <PersonAvatar
              class="activity-channel-item--header--user mr-2"
              type="user"
              :id="member.user_id"
              :name="`${member.user_fname} ${member.user_lname}`"
              size="no"
            />
            <p class="text-sm">{{ `${member.user_fname} ${member.user_lname}` }}</p>
          </div>
          <div
            v-if="projectLoading"
            class="option flex items-center p-2 w-full hover:bg-surface-100 cursor-pointer"
          >
            <ProgressSpinner
              style="width: 25px; height: 25px"
              strokeWidth="4"
              animationDuration="1s"
            />
          </div>
          <div v-else-if="mentionQueryItems.length > 0">
            <div
              v-for="item in mentionQueryItems"
              :key="item.refId"
              @click="() => onSelectMentionItem(item)"
              class="option flex items-center p-2 w-full hover:bg-surface-100 cursor-pointer"
            >
              <font-awesome-icon
                v-if="item.type === 'assembly'"
                icon="cubes"
                size="lg"
                class="mr-2 w-8 h-8"
              />
              <font-awesome-icon v-else icon="cube" size="lg" class="mr-2 w-8 h-8" />
              <p class="text-sm">
                {{ item[`${item.type === 'assembly' ? 'assembly' : 'cost_type'}_name`] }}
              </p>
            </div>
          </div>
        </div>
      </TransitionExpand>
      <div class="activity-channel-footer--attachments--parent relative">
        <div class="absolute bottom-0 left-0 w-full mb-2 flex justify-center">
          <p
            v-if="channel?.channel_type === 'CHAT' && channel?.channel_members?.items?.length <= 2"
            class="text-xs bg-surface-100 p-1.5 font-medium rounded-sm"
          >
            Chat to {{ channelName }}
          </p>
          <p v-else class="text-xs bg-surface-100 p-1.5 font-medium rounded-sm">
            Chat in the {{ channelName }} channel
          </p>
        </div>
      </div>
    </div>
    <div
      :style="`height: ${inputHeight}`"
      class="activity-channel-footer--input transition-all duration-300 max-h-32 flex items-center rounded-md border overflow-hidden pr-4 has-[:focus]:border-primary"
    >
      <textarea
        ref="chatTextInput"
        v-model="inputMessage"
        placeholder="Type a message..."
        @keydown.enter="onEnterDown"
        @beforeinput="onBeforeInput"
        class="activity-channel-footer--input--text w-full h-full pl-3.5 py-3.5 resize-none touch-none outline-0"
      />
      <ActivityFilesSelect @added="onFilesSelected" />
      <Btn
        @click="postActivity"
        id="activity-channel-footer--send"
        class="activity-channel-footer--send !w-8 !h-8 !text-level-yellow ml-3 transition-colors"
        :class="{ '!text-surface-400 !bg-transparent': activityEmpty || !channel }"
      >
        <font-awesome-icon v-if="activityEmpty" icon="paper-plane-top" />
        <font-awesome-icon v-else :icon="['far', 'paper-plane-top']" />
      </Btn>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'
import ActivityReplyItem from '@/components/Activities/ActivityItemType/ActivityReplyItem.vue'
import TransitionExpand from '@/components/transitions/TransitionExpand.vue'
import useActivityChannel from '@/components/composables/ActivityChannel'
import ProgressSpinner from 'primevue/progressspinner'
import ActivityFiles from '@/components/Activities/Files/ActivityFiles.vue'
import ActivityFilesSelect from '@/components/Activities/Files/ActivityFilesSelect.vue'

// ======== Props ======== //
const props = defineProps({
  channelId: {
    type: String,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const store = useStore()
const {
  channel,
  channelMembers,
  projectLoading,
  normalizedProjectObject,
  fetchProjectDetails,
  channelName
} = useActivityChannel(props)
// ====================== //

// ======== Refs ======== //
const chatTextInput = ref(null)
// ====================== //

// ======== Data ======== //
const inputMessage = ref('')
const inputMentions = ref([])
const parentActivityId = ref('')
const parentActivity = ref(null)
const editActivityId = ref('')
const editActivity = ref(null)
const preUploadFiles = ref([])
// ====================== //

// ======== Computed ======== //
const activityEmpty = computed(() => {
  return inputMessage.value.length <= 0 && preUploadFiles.value.length <= 0
})
const hasFiles = computed(() => {
  return preUploadFiles.value.length >= 1
})
const hasEditActivity = computed(() => {
  return editActivityId.value.length > 1 && editActivity.value !== null
})
const hasParentActivity = computed(() => {
  return parentActivityId.value.length > 1 && parentActivity.value !== null
})
const inputHeight = computed(() => {
  return `${(inputMessage.value?.match(/\n/g) || []).length + 3.5}rem`
})
const queryMessageSlice = computed(() => {
  const chatInput = chatTextInput.value
  if (!chatInput) return null
  return inputMessage.value.slice(0, chatInput.selectionStart)
})
const mentionQueryIndex = computed(() => {
  if (!queryMessageSlice.value) return -1
  const index = queryMessageSlice.value.lastIndexOf('@')
  if (
    index > 0 &&
    queryMessageSlice.value[index - 1] !== ' ' &&
    queryMessageSlice.value[index - 1] !== '\n'
  )
    return -1
  return index
})
const mentionQuery = computed(() => {
  if (mentionQueryIndex.value < 0) return ''
  return queryMessageSlice.value.slice(mentionQueryIndex.value + 1, queryMessageSlice.value.length)
})
const mentionQueryMembers = computed(() =>
  channelMembers.value.filter((member) =>
    `${member.user_fname} ${member.user_lname}`
      .toLowerCase()
      .includes(mentionQuery.value.toLowerCase())
  )
)
const mentionQueryItems = computed(() =>
  Object.values(normalizedProjectObject.value).filter((item) => {
    if (item.type === 'quote') return false
    const itemName = item[`${item.type === 'assembly' ? 'assembly' : 'cost_type'}_name`]
    return itemName && itemName.toLowerCase().includes(mentionQuery.value.toLowerCase())
  })
)
// ====================== //

// ======== Methods ======== //
const fetchProjectItems = async () => {
  const channelType = props.channelId.split('-')[0]
  normalizedProjectObject.value = {}
  if (channelType === 'QUOTE' || channelType === 'QUOTE_CLIENT' || channelType === 'INVOICE') {
    await fetchProjectDetails()
  }
}
const clearEdit = () => {
  editActivityId.value = ''
  editActivity.value = null
}
const clearParent = () => {
  parentActivityId.value = ''
  parentActivity.value = null
}
const clearMessage = () => {
  inputMessage.value = ''
  inputMentions.value = []
  preUploadFiles.value = []
}
const clearInput = () => {
  clearMessage()
  clearParent()
  clearEdit()
}
const getAdjustedMentions = (mentions, insertLength, selectionStart, filterIndex = null) => {
  return mentions.reduce((acc, mention) => {
    let mentionStart = mention.mention_start_index
    if (mentionStart >= selectionStart) mentionStart += insertLength

    if (filterIndex === null || filterIndex !== mention.mention_start_index) {
      acc.push({
        ...mention,
        mention_start_index: mentionStart <= 0 ? 0 : mentionStart
      })
    }

    return acc
  }, [])
}
const getFilteredMentions = (mentions, cursorSetStart, cursorSetEnd) => {
  return mentions.filter((mention) => {
    const mentionStart = mention.mention_start_index
    const mentionEnd = mention.mention_start_index + mention.mention_text.length

    // filter if collision //
    return !(
      (mentionStart >= cursorSetStart && mentionStart < cursorSetEnd) ||
      (mentionEnd >= cursorSetStart && mentionEnd < cursorSetEnd) ||
      (cursorSetStart >= mentionStart && cursorSetStart <= mentionEnd) ||
      (cursorSetEnd > mentionStart && cursorSetEnd < mentionEnd)
    )
  })
}
const onSelectMentionMember = (member) => {
  if (mentionQueryIndex.value >= 0) {
    const memberText = `${member.user_fname} ${member.user_lname} `
    let message = inputMessage.value.slice(0, mentionQueryIndex.value + 1)
    message += memberText
    message += inputMessage.value.slice(
      chatTextInput.value.selectionStart,
      inputMessage.value.length
    )

    const mentions = getAdjustedMentions(
      inputMentions.value,
      memberText.length + mentionQueryIndex.value + 1 - chatTextInput.value.selectionStart,
      mentionQueryIndex.value + 1,
      mentionQueryIndex.value
    )

    mentions.push({
      mention_type: 'MEMBER',
      mention_type_id: member.member_id,
      mention_text: `${member.user_fname} ${member.user_lname}`,
      mention_start_index: mentionQueryIndex.value
    })

    inputMentions.value = mentions
    inputMessage.value = message
  }
}
const onSelectMentionItem = (item) => {
  const itemName = item[`${item.type === 'assembly' ? 'assembly' : 'cost_type'}_name`]
  const itemText = `${itemName} `
  let message = inputMessage.value.slice(0, mentionQueryIndex.value + 1)
  message += itemText
  message += inputMessage.value.slice(chatTextInput.value.selectionStart, inputMessage.value.length)

  const mentions = getAdjustedMentions(
    inputMentions.value,
    itemText.length + mentionQueryIndex.value + 1 - chatTextInput.value.selectionStart,
    mentionQueryIndex.value + 1,
    mentionQueryIndex.value
  )

  mentions.push({
    mention_type: 'ITEM',
    mention_type_id: item.refId,
    mention_text: itemName,
    mention_start_index: mentionQueryIndex.value
  })

  inputMentions.value = mentions
  inputMessage.value = message
}
const onEnterDown = async (e) => {
  if (e.keyCode === 13 && !e.shiftKey) {
    e.preventDefault()
    await postActivity()
  }
}
const onBeforeInput = (e) => {
  const selectionStart = chatTextInput.value.selectionStart
  const selectionEnd = chatTextInput.value.selectionEnd
  const selectionRange = selectionEnd - selectionStart
  const inputData = e.data

  if (inputData && selectionRange <= 0) {
    let addEnd = selectionEnd + inputData.length
    inputMentions.value = getFilteredMentions(
      getAdjustedMentions(inputMentions.value, inputData.length, selectionStart),
      selectionStart,
      addEnd
    )
  } else {
    let removeStart = selectionStart
    let removeEnd = selectionEnd

    if (selectionRange <= 0) {
      if (e.inputType === 'deleteContentBackward') {
        removeStart -= 1
      } else if (e.inputType === 'deleteContentForward') {
        removeEnd += 1
      } else if (e.inputType === 'deleteSoftLineBackward') {
        removeStart = 0
      } else if (e.inputType === 'insertLineBreak') {
        // do nothing //
      } else {
        e.preventDefault()
        return
      }
    }

    inputMentions.value = getAdjustedMentions(
      getFilteredMentions(inputMentions.value, removeStart, removeEnd),
      removeStart - removeEnd + (inputData || '').length,
      selectionStart
    )
  }
}
const postActivity = async () => {
  if (activityEmpty.value || !channel.value) return
  try {
    const editId = hasEditActivity.value ? editActivityId.value : undefined
    const message = inputMessage.value
    const parentId = parentActivityId.value
    const parentObject = parentActivity.value
    const mentions = inputMentions.value
    const files = preUploadFiles.value

    clearInput()

    if (editId) {
      await store.dispatch('activityChat/editActivity', {
        editActivityId: editId,
        channelId: props.channelId,
        activityMessage: message,
        parentActivityId: parentId,
        parentActivity: parentObject,
        activityMentions: mentions,
        files
      })
    } else {
      await store.dispatch('activityChat/postActivity', {
        channelId: props.channelId,
        activityMessage: message,
        parentActivityId: parentId,
        parentActivity: parentObject,
        activityMentions: mentions,
        files
      })
    }
  } catch (err) {
    console.error(err)
    await store.dispatch('alert', {
      message: 'Failed to save activity message, please try again',
      error: true
    })
  }
}
const onRemoveFile = (index) => {
  URL.revokeObjectURL(preUploadFiles.value[index].objectURL)
  preUploadFiles.value.splice(index, 1)
}
const onFilesSelected = async (files) => {
  files.forEach((file) => {
    if (!file.objectURL) file.objectURL = URL.createObjectURL(file)
  })
  preUploadFiles.value.push(...files)
}
const replyToMessage = (activity) => {
  parentActivityId.value = activity.activity_id
  parentActivity.value = activity
}
const editMessage = (activity) => {
  editActivity.value = activity
  editActivityId.value = activity.activity_id
  inputMessage.value = activity?.oTarget?.message || activity?.activity_notification_heading
  parentActivityId.value = activity.parent_activity_id || ''
  parentActivity.value = activity.parent_activity || ''
  inputMentions.value = activity.activity_mentions || []
  preUploadFiles.value = []
}
const focusInput = () => {
  chatTextInput.value?.focus()
}
// ====================== //

// ======== Watchers ======== //
watch(
  () => props.channelId,
  () => {
    focusInput()
    fetchProjectItems()
  }
)
// ====================== //

// ======== Lifecycle ======== //
onMounted(() => {
  focusInput()
  fetchProjectItems()
})
// ====================== //

// ======== Expose ======== //
defineExpose({
  replyToMessage,
  editMessage
})
// ====================== //
</script>

<style lang="scss" scoped></style>
