import BtnMixin from '../mixins/Button'
import Milestones from '../mixins/Milestones'
import Normalize from '../../../imports/api/Normalize'
import ItemType from '../mixins/ItemType'
import eventBus from '../../eventBus'

const { map } = Normalize

/**
 * Emits:
 *  -loading
 *  -addingItem
 */
export default {
  mixins: [BtnMixin, Milestones, ItemType],

  data() {
    return {
      type: 'assembly',
      note: '',
      expanded: false,
      velocities: [],
      editingNotes: false,
      editingFiles: false,
      editingQty: false,
      showProperties: false,
      childrenExpanded: false,
      swapping: false,
      startDimensionsOpen: 0,
      margin: 0,
      markup: 0,
      modalRequested: 0,
      isOptionsShown: false
    }
  },
  emits: ['expanded', 'isExpanded', 'expanded', 'openOptionsModal'],
  watch: {
    expanded(b) {
      this.$emit('expanded', this.reference, b)
    }
  },
  computed: {
    isGlobalAssembly() {
      return this.assembly_status === '@' || this.assembly_status === 'y'
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.user.company_id
      )
    },
    parentRefId() {
      return this.object.parentRefId
    },
    warningLosing() {
      return this.object.quote_margin_net < 0
    },
    warningMinimum() {
      return (
        this.object.quote_margin_net <
          this.$store.state.session.company.company_minimum_quote_margin &&
        Math.abs(
          this.object.quote_margin_net -
            this.$store.state.session.company.company_minimum_quote_margin
        ) > 0.01 &&
        this.object.quote_total_cost_net >= 0.01
      )
    },
    isAdjusted() {
      return !c.eq(this.object.assembly_markup_percentage_adjustment, 0, 2)
    },
    defaultMarkup() {
      return this.$store.getters.defaultMarkup
    },
    minMarkup() {
      return c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin || 0.2)
    },
    bottomMarkup() {
      return 1
    },
    topMarkup() {
      return 3
    },
    adjustedPrice: {
      get() {
        return this.object.quote_subtotal_net
      },
      set(p) {
        const price = c.n(p)
        const cost = c.n(this.object.quote_total_cost_net_base)
        this.targetMarkup = price / (cost || price * this.defaultMarkup) // default to 1
      }
    },
    adjustedProfit: {
      get() {
        return this.adjustedPrice - c.n(this.object.quote_total_cost_net_base)
      },
      set(profit) {
        const newPrice = c.n(this.object.quote_total_cost_net_base) + c.n(profit)
        this.adjustedPrice = newPrice
      }
    },
    lineItemActions() {
      const a = {
        editSeparately: {
          title: 'Edit assembly contents',
          icon: 'rectangle-history-circle-plus',
          action: () =>
            this.$store.dispatch('edit', {
              type: 'assembly',
              id: this.object.assembly_id,
              saved: () => this.reload()
            }),
          visible: () => !this.assemblyEditable && !this.isOptionsShown
        },
        openOptions: {
          title: 'Edit pics, notes, upgrades and more..',
          icon: 'sliders',
          action: () => this.openOptionsModal(),
          visible: () => this.assemblyEditable && !this.isOptionsShown
        },
        laborRates: {
          title: 'Bulk change labor rates..',
          icon: 'user-helmet-safety',
          action: () => this.changeAssemblyLaborRates(),
          visible: () => this.object.quote_labor_cost_net_base > 0
        },
        confirmDimensions: {
          title: 'Confirm dimensions..',
          icon: 'drafting-compass',
          action: () => this.confirmDimensions()
        },
        edit: {
          title: 'Expand',
          icon: 'chevron-down',
          action: this.edit,
          visible: () => !this.editing && !this.expanded
        },
        swap: {
          title: 'Replace with..',
          icon: ['fas', 'right-from-bracket'],
          action: this.swap
        },
        duplicate: {
          title: 'Duplicate',
          icon: 'clone',
          action: this.duplicate
        },
        remove: {
          title: 'Remove',
          icon: 'circle-xmark',
          action: this.remove
        },
        save: {
          title: 'Update in your catalog',
          icon: 'database',
          action: () => this.save(false),
          visible: () =>
            this.object.assembly_id &&
            this.assemblyEditable &&
            this.object.assembly_status !== '@' &&
            !this.isDeleted
        },
        saveAs: {
          title: 'Save to your catalog',
          icon: ['fas', 'layer-plus'],
          action: () => this.save(true),
          visible: () => this.assemblyEditable
        },

        saveSuper: {
          title: '(GLOBAL) Update in free catalog',
          icon: 'layer-group',
          action: () => this.save(false, true),
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id &&
            this.object.assembly_id &&
            this.assemblyEditable
        },
        saveAsSuper: {
          title: '(GLOBAL) Save to free catalog',
          icon: 'layer-group',
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id &&
            this.assemblyEditable,
          action: () => this.save(true, true)
        }
      }
      return a
    },
    created() {
      this.$on('selected', () => {
        this.$nextTick(() => {
          this.startDimensionsOpen = !this.object.assembly_id || this.activeDimensions < 1

          this.margin = c.toNum(this.object.quote_margin_net * 100, 2)
          this.markup = (() => {
            const qm = c.toNum(this.object.quote_markup_net)
            return c.n(this.object.quote_markup_percentage_adjustment) * (qm - 1) + qm
          })()
        })
      })
    },
    assemblyMargin() {
      return this.object.quote_margin_net
    },
    targetMargin: {
      get() {
        return c.markupToMargin(this.targetMarkup) * 100
      },
      set(margin) {
        this.targetMarkup = c.marginToMarkup(margin / 100)
      }
    },
    targetMarkup: {
      get() {
        const qm = c.toNum(this.object.quote_markup_net)
        const markup =
          c.notNaN(this.object.quote_markup_percentage_adjustment) *
            (c.eq(qm - 1, 0, 5) ? 1 : qm - 1) +
          qm
        c.throttle(
          () => {
            this.markup = markup
          },
          { delay: 2000 }
        )
        return markup
      },
      set(val) {
        if (val !== null && val !== '') {
          /**
           * The equation given by
           * p - q
           * ----- = a
           * 1 + q
           *
           * as reversed from p,
           *
           * where:
           *  p: full/combined percentage adjustment
           *      p = a + q + qa
           *
           *      or
           *           (t - 1) - (m - 1)
           *      p = ------------------
           *                (m - 1)
           *
           *  t: target markup
           *  m: regular / existing markup
           *  q: quote/parent percentage adjustment
           *  a: local/assembly percentage adjustment
           */
          const t = c.n(val)
          const m = this.object.quote_markup_net
          const p = (t - 1 - (m - 1)) / (c.eq(m - 1, 0, 5) ? 1 : m - 1)

          this.markup = t
          let margin = c.markupToMargin(t)

          margin = margin < -0.99 ? -0.99 : margin
          margin = margin > 0.99 ? 0.99 : margin
          this.margin = margin * 100

          c.throttle(() => {
            this.$store
              .dispatch(`${this.store}/field`, {
                refId: this.reference,
                changes: {
                  quote_markup_percentage_adjustment: p
                },
                explicit: true
              })
              .then(() => {
                setTimeout(() => {
                  c.endLoadingAll(this.$refs.markupIndicator)
                }, 250)
              })
          })
        }
      }
    },
    auditVal() {
      return {
        quote_price_net: this.object.quote_price_net,
        quote_total_cost_net: this.object.quote_total_cost_net,
        quote_markup_percentage_adjustment: this.object.quote_markup_percentage_adjustment,
        quote_markup_net_adjustment: this.object.quote_markup_net_adjustment,
        quote_price_net_base_adjustment: this.object.quote_price_net_base_adjustment
      }
    },
    // Is this a assembly that is synced or not
    synced() {
      return this.object.assembly_id
    },
    isDeleted() {
      return this.object.assembly_status === 'h' || this.object.assembly_status === 'y'
    },
    syncedTopLevel() {
      const norm = this.$store.state[this.store].normalized
      return (
        this.inAssembly &&
        (!this.parentRefId ||
          !norm[this.parentRefId] ||
          ((!norm[this.parentRefId].assembly_id || !norm[this.parentRefId].parentRefId) &&
            (!norm[this.parentRefId].quote_id || !norm[this.parentRefId].parentRefId)))
      )
    },
    assemblyEditable() {
      return !this.inAssembly || (!this.synced && this.editable)
    },
    childrenForList: {
      get() {
        return this.object.aoChildren
      },
      set(childRefIds) {
        this.$store.dispatch(`${this.store}/transferChildren`, {
          childRefIds,
          parentRefId: this.reference
        })
      },
      immediate: true
    },
    showMask() {
      return this.expanded && !this.childrenExpanded
    },
    area: {
      get() {
        return this.object.quote_area_gross
      },
      set(val) {
        if (this.object.quote_link_area && c.toNum(val) !== c.toNum(this.object.quote_area_gross)) {
          this.unlinkQty()
        }
        this.object.quote_area_gross = val
      }
    },
    classes() {
      return this.expanded ? 'expanded' : ''
    },
    editing() {
      return this.expanded
    }
  },
  methods: {
    async newCategory() {
      const object = await this.$store.dispatch('CostType/newCategory')
      if (!object) return null

      this.parent_cost_type_id = object.cost_type_id

      return object
    },
    unlinkFromSavedVersion() {
      return this.$store.dispatch(`${this.store}/field`, {
        changes: {
          assembly_id: null
        },
        refId: this.reference,
        explicit: {
          assembly_id: null
        },
        skipAudit: true,
        skipLocalAudit: false
      })
    },
    confirmDimensions() {
      return this.$store.dispatch('Quote/confirmDimensions', {
        refId: this.refId || this.reference,
        store: this.storeName || this.store
      })
    },
    changeAssemblyLaborRates() {
      return this.$store.dispatch(`${this.storeName || this.store}/addChildPreferences`, {
        refId: this.refId || this.reference,
        store: this.storeName || this.store
      })
    },
    async save(asNew = false, asSuper = false) {
      const isSuper = asSuper === true && this.$store.state.session.user.user_is_super_user
      const { key: queueKey } = await this.$store.dispatch(`${this.store}/queueRequest`, {
        key: 'assembly'
      })

      try {
        const denormalized = c.denormalize(this.$store.state[this.store].normalized, this.reference)

        const superOverrides = await this.$store.dispatch('Assembly/globalSaveCheck', isSuper)
        const savingAs = asNew ? null : denormalized.assembly_id

        const { object } = await this.$store.dispatch('Assembly/save', {
          go: false,
          selected: [
            {
              ...denormalized,
              assembly_id: savingAs,
              quote_default_qty: denormalized.quote_qty_net_base,
              ...superOverrides.overrides
            }
          ],
          scope: superOverrides.scope,
          changes: false,
          queue: false, // we already waited above ^^
          alert: false,
          except: [
            // 'parent_cost_type_id', // cannot be edited from inside a quote
            'assembly_status',
            'quote_show_itemized_prices'
          ]
        })
        await this.$store.dispatch(`${this.store}/field`, {
          refId: this.reference,
          changes: {
            assembly_id: object.assembly_id,
            assembly_status: object.assembly_status,
            company_id: object.company_id,
            parent_cost_type_id: object.parent_cost_type_id
          },
          explicit: true,
          immediate: true,
          queue: false,
          skipAudit: true,
          skipLocalAudit: true
        })

        this.$store.dispatch('alert', {
          message:
            'You successfully saved this assembly.  Now it can be used later in another quote.'
        })

        c.clearCacheOfType('cost_type')
        c.clearCacheOfType('assembly')
        eventBus.$emit('saved-assembly')
      } catch (err) {
        eventBus.$emit('error-assembly')
        const { userMessage: message = 'Could not save assembly. Try again.' } = err
        this.$store.dispatch('alert', {
          message,
          error: true,
          timeout: 6000
        })
        throw err
      } finally {
        this.$store.dispatch(`${this.store}/queueNext`, { key: queueKey })
      }
    },

    /**
     * Searches the children of the assembly
     * to see if any children SHOULD be linked to
     * a common dimension.
     *
     * @returns {Promise<unknown[]>}
     */
    async findConnectedChildQuantities() {
      const norm = this.$store.state[this.store].normalized
      const set = map(this.object.aoChildren, norm)

      const found = {}

      const assemblyRefId = this.object.refId
      const assemblyName = norm[assemblyRefId].assembly_name

      Object.values(set).forEach((child) => {
        if (
          !child.asDimensionsUsed.length &&
          child.type === 'cost_item' &&
          /[A-Za-z]/.test(child.unit_of_measure_id)
        ) {
          const qty = Math.round(child.cost_item_qty_net_base * 100)
          const key = `${child.unit_of_measure_id}:${qty}`
          const cur = found[key] || {}

          found[key] = {
            ...cur,
            cost_item_qty_net_base: child.cost_item_qty_net_base,
            unit_of_measure_id: child.unit_of_measure_id,
            suggestedDimensions: [],
            refIds: [...(cur.refIds || []), child.refId]
          }
        }
      })

      // Mix in suggested measures
      await Promise.all(
        Object.keys(found).map(async (foundKey) => {
          const { dimensions: suggestedDimensions } = await this.$store.dispatch(
            'Dimension/getSuggestedDimensions',
            {
              object: {},
              itemName: `${found[foundKey].refIds
                .map((r) => norm[r].cost_type_name || norm[r].assembly_name)
                .join(' ')}`,
              itemDesc: found[foundKey].refIds
                .map((r) => norm[r].cost_type_desc || norm[r].assembly_name)
                .join(' '),
              parentName: assemblyName,
              preferMeasureType: c.getMeasureTypeForUnitOfMeasure(
                found[foundKey].unit_of_measure_id
              )
            }
          )

          found[foundKey].suggestedDimensions = Object.values(suggestedDimensions)
        })
      )

      return Object.values(found).filter(
        (f) =>
          f.refIds.length > 1 &&
          f.cost_item_qty_net_base > 1 &&
          !c.getCacheItem(`${assemblyRefId}ISNOT${f.cost_item_qty_net_base}${f.unit_of_measure_id}`)
      )
    },

    async editSeparately() {
      await this.$store.dispatch('edit', {
        type: 'assembly',
        id: this.object.assembly_id
      })

      return this.reload()
    },
    isExpandedHandler(b) {
      this.childrenExpanded = b

      this.$emit('isExpanded', this.childrenExpanded || 0)
    },
    toggleExpanded(r, b) {
      this.$emit('expanded', r, b)
    },
    setDenormalizedField(field, item, index = null) {
      const constructor = c.getConstructor(this.type)
      const typeArray =
        constructor && field in constructor.fields && constructor.fields[field].type === 'array'
      const receivedArray = Array.isArray(item)

      if (typeArray && !receivedArray && !index) {
        throw new Error(`When setting an array field with setDenormalizeField(), you must either:
              1) include an index to know where to put the item, or,
              2) pass an array as the item which will OVERWRITE the whole array.`)
      }

      let value = _.imm(this.object[field])
      if (typeArray && index) {
        value.splice(index, item)
      } else {
        value = item
      }

      this.$store.dispatch(`${this.store}/field`, {
        refId: this.reference,
        changes: {
          [field]: value
        },
        explicit: true
      })
    },

    /**
     * Set equation
     * @returns {void}
     */
    setEquation(field, equation) {
      this.$store.commit({
        type: `${this.store}/FIELD_EQUATION`,
        field,
        equation,
        refId: this.reference
      })
    },

    async setField(field, value) {
      if (_.jsonEquals(value, this.object[field])) {
        return
      }

      await this.$store.dispatch(`${this.store}/field`, {
        refId: this.reference,
        changes: {
          [field]: value
        },
        explicit: true
      })

      await this.$store.dispatch('Quote/recalcAddons', { refIds: [this.reference], loading: false })
    },

    /**
     * Memory saving open method
     */
    async openOptionsModal() {
      this.$emit('openOptionsModal')
      this.modalRequested = 1

      await this.$nextTick()

      if (this.$refs.options) {
        this.$refs.options.open()
      }
    },

    setMarkupToMinimum() {
      const markup = c.marginToMarkup(
        this.$store.state.session.company.company_minimum_quote_margin
      )
      this.targetMarkup = markup
    },

    setMarkupToDefault() {
      const markup = this.$store.getters.defaultMarkup
      this.targetMarkup = markup
    },

    isTaskDue(t) {
      const norm = this.$store.state[this.store].normalized
      const s = norm[this.rootRefId].quote_status
      return (
        t.task_status === 'u' &&
        (t.task_id ||
          t.task_stage_object_status === 'p' ||
          (s === 'k' && /k|p/.test(t.task_stage_object_status)) ||
          (s === 'f' && /f|k|p/.test(t.task_stage_object_status)))
      )
    },

    async addTask() {
      const norm = this.$store.state[this.store].normalized
      const status = norm[this.rootRefId].quote_status
      const { object } = await this.$store.dispatch('Task/buildDefaultObject', {
        type: 'task',
        embue: {
          task_is_stage: 1,
          task_is_required: 1,
          task_stage_object_type: 'quote',
          task_stage_object_status: status
        }
      })

      this.setNormalizedField('aoStageTasks', [...this.getNormalizedField('aoStageTasks'), object])
    },

    unsyncAssembly() {
      // Can't sync anymore without these values
      const changes = {
        [this.reference]: {
          assembly_quote_id: null,
          quote_id: null,
          assembly_id: null,
          original_assembly_id:
            this.object.assembly_id || this.object.quote_id || this.object.assembly_quote_id
        }
      }
      this.$store.commit({
        type: `${this.store}/FIELDS`,
        changes
      })
      this.store.dispatch(`${this.store}/addChanges`, {
        explicitChanges: changes
      })
    },

    /**
     * Set fields like aoStageTasks or aoChildren which
     * are normalized, with raw objects instead of dealing
     * with the refIds
     *
     * @param f
     * @param children
     * @returns {self}
     */
    setNormalizedField(f, children) {
      const childrenArray = _.makeArray(children)
      const constructor = c.getConstructor(this.type)
      if (c.shouldMapField(f, this.type)) {
        this.$store.dispatch(`${this.store}/replaceChildren`, {
          refId: this.reference,
          children: childrenArray,
          field: f
        })
      } else {
        this.setField(f, constructor.fields[f].type === 'object' ? childrenArray[0] : childrenArray)
      }

      return this
    },

    /**
     * Get the denormalized version of a normalized field
     * like aoStageTasks or aoFiles or aoChildren which
     * are normalized and mapped using refIds
     *
     * @param f
     * @returns object|array
     */
    getNormalizedField(field) {
      const norm = this.$store.state[this.store].normalized
      const casted = c.denormalize(norm, this.reference, true, true)
      const constructor = c.getConstructor(this.type)
      const fieldType = (constructor.fields[field] && constructor.fields[field].type) || 'object'

      if (field in casted && casted[field]) {
        return casted[field]
      }

      return fieldType === 'array' ? [] : {}
    },

    async unsyncIfNotExists() {
      const exists = await this.confirmExists()

      if (!exists) {
        this.unsyncAssembly()
      }

      return exists
    },

    async confirmExists() {
      let id
      try {
        id = await this.$store.dispatch('Assembly/fetchField', {
          field: 'assembly_id',
          id: this.object.assembly_id
        })
      } catch (e) {
        return false
      }

      if (!id) {
        return false
      }

      return true
    },

    removeAllSubAdjustments() {
      this.targetMarkup = 1
    },

    /**
     * CRUD methods
     */
    async addItem() {
      return this.$store.dispatch(`${this.store}/addChild`, {
        refId: this.reference,
        child: { type: 'line_item' }
      })
    },

    edit() {
      return this.expand()
    },

    /**
     * Viewmodel methods
     */
    titleClick() {
      this.expand()
    },

    qtyClick(ref) {
      this.editingQty = true
      if (ref && this.$refs[ref] && typeof this.$refs[ref].focus === 'function') {
        setTimeout(() => {
          this.$refs[ref].focus()
        }, 200)
      }
    },

    toggleExpand() {
      return this.expanded ? this.hide() : this.expand()
    },

    expand() {
      this.expanded = true
      setTimeout(() => {
        if (this.$refs.title) this.$refs.title.focus()
      }, 200)
    },

    hide() {
      this.expanded = false
    }
  },

  props: {
    reference: {
      type: String,
      required: true
    },

    rootRefId: {
      type: String,
      required: true
    },

    object: {
      type: Object,
      required: true
    },

    store: {
      type: String,
      required: true,
      default: 'Quote'
    },

    showForCost: {
      type: Array,
      default: () => ['cost', 'price']
    },

    editable: {
      type: Boolean,
      default: true
    },

    inAssembly: {
      default: false
    },

    removing: {
      default: false
    }
  }
}
