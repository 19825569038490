const services = ({ store }) => {
  const topDropDown = {
    title: store.state.session.company?.company_name,
    links: [
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      }
    ]
  }

  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Edit profile',
        command: () => store.dispatch('to', 'profile')
      },
      {
        separator: true
      },
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      },
      {
        label: 'Logout',
        command: () => store.dispatch('logout')
      }
    ]
  }

  const features = [
    {
      title: 'Franchise',
      icon: 'gauge',
      links: [
        {
          title: 'Insights',
          icon: 'chart-simple',
          to: 'franchisor/metrics',
          desc: 'Key performance indicators and charts to help visualize your business.'
        },
        {
          title: 'Franchise',
          icon: 'buildings',
          to: 'franchisor/franchise',
          desc: 'franchise desc.'
        },
        // @TODO - Implement a proper shared catalog
        {
          title: 'Shared catalog',
          icon: 'box-open-full',
          to: 'franchisor/items',
          desc: 'desc.'
        },
        {
          title: 'Users',
          to: 'franchisor/users',
          icon: 'users-gear',
          desc: 'Manage users, reset passwords, set roles & permissions.'
        }
      ]
    }
  ]

  return {
    features,
    topDropDown,
    bottomDropDown
  }
}

export { services }
