<template>
  <spin-container :loading="bodyLoading">
    <side-panel>
      <template #above>
        <SidePanelDetails paddingVertical="1.22">
          <template #open>
            <div style="margin-left: 0.7em">
              <font-awesome-icon icon="list" style="margin-right: 0.9em" />
              <span v-if="template_name" style="font-size: 1.2em">{{
                $f.truncate(template_name, 20)
              }}</span>
            </div>
          </template>
          <template #closed>
            <font-awesome-icon icon="list" style="font-size: 1.5em" />
          </template>
        </SidePanelDetails>
      </template>

      <template #below v-if="templateType && template_name && availableTokens.length">
        <card-section class="mt-4">
          <template #label>Insert tokens</template>
          <card-list>
            <card-list-header class="primary">
              <search-field v-model="searchPhrase" />
            </card-list-header>
            <template v-for="(token, i) in filteredTokens" :key="i">
              <card-list-header
                class="sticky primary"
                v-if="!filteredTokens[i - 1] || filteredTokens[i - 1][2] !== token[2]"
              >
                {{ $f.capitalize(token[2]) }}
              </card-list-header>
              <card-list-select
                @click="() => $refs.ckeditor.insertToken(token[1])"
                style="font-size: 0.9em"
              >
                <span>{{ token[0] }}</span>
              </card-list-select>
            </template>
          </card-list>
        </card-section>
      </template>

      <!-- main panel -->
      <panel title="Template" icon="text-resize">
        <container style="max-width: 800px !important; justify-self: center; margin: 0 auto">
          <card-section>
            <card-list>
              <card-list-field>
                <span>
                  Template type
                  <br />
                  <small>Choose the type of template you would like this to be.</small>
                </span>
                <choose
                  :return-array="false"
                  :validate="{ required: true }"
                  schema="template:template_type_id"
                  v-model="template_type_id"
                  :allowDeselect="true"
                />
              </card-list-field>

              <card-list-field>
                <span
                  >Template name
                  <br />
                  <small>Give template a name.</small>
                </span>
                <field
                  :validate="{ required: true }"
                  v-model="template_name"
                  schema="template:template_name"
                />
              </card-list-field>
            </card-list>
          </card-section>
        </container>

        <template v-if="templateType && template_name">
          <container
            v-if="templateType === 100"
            style="max-width: 800px !important; justify-self: center; margin: 0 auto"
          >
            <card-section>
              <info icon="brush" title="Want to create or change a theme?">
                <span>
                  Theme files are in CSS and changing them requires a knowledge of how that works.
                  Editing theme files is considered an advanced process. If you are unsure how to
                  proceed make sure to contact support and we can help change it for you.
                </span>
              </info>
            </card-section>
          </container>

          <document-editor
            ref="ckeditor"
            v-if="templateType !== 100 && templateType !== 4 && templateType !== 101 && showEditor"
            :styleTemplateId="style_template_id"
            :templateType="templateType"
            :value="html"
            @ready="ready"
            @input="changed"
            @tokens="(a) => addTokens(a)"
            class="pt-4"
          />
          <container
            v-else-if="templateType === 101 || templateType === 4"
            style="max-width: 800px !important; justify-self: center; margin: 0 auto"
          >
            <PresentationSettings :showPresentationSettings="false" v-model="presentationMeta" />
          </container>
          <container
            v-else-if="showEditor && templateType !== 101"
            style="max-width: 800px !important; justify-self: center; margin: 0 auto"
          >
            <card>
              <codemirror ref="codemirror" class="full-width" v-model="html" @change="changed" />
            </card>
          </container>
        </template>
      </panel>

      <!-- settings panel -->
      <panel title="Settings" icon="cog">
        <card-section
          v-if="
            templateType &&
            templateType !== 100 &&
            templateType !== 101 &&
            template_type_base_type !== 'e' &&
            templateType !== 9 &&
            templateType !== 10
          "
        >
          <card-list>
            <card-list-field>
              <label> Theme </label>
              <choose
                :return-array="false"
                :selectFirst="true"
                v-model="style_template_id"
                schema="template:style_template_id"
                :popup="true"
                :filters="{
                  template_type_id: 100
                }"
              />
            </card-list-field>
            <card-list-field>
              <label> Header </label>
              <choose
                :return-array="false"
                :selectFirst="true"
                v-model="heading_template_id"
                schema="template:heading_template_id"
                :popup="true"
                :filters="{
                  template_type_id: 9
                }"
              />
            </card-list-field>
            <card-list-field>
              <label> Footer </label>
              <choose
                :return-array="false"
                :selectFirst="true"
                v-model="footing_template_id"
                schema="template:footing_template_id"
                :popup="true"
                :filters="{
                  template_type_id: 10
                }"
              />
            </card-list-field>
          </card-list>
        </card-section>
      </panel>

      <panel v-show="template_id" title="History" icon="history">
        <div class="flex justify-center">
          <activities
            @version-checkout="checkoutPreviousVersion"
            v-if="template_id"
            :showBackupsFor="{
              template: template_id
            }"
            :showBlankUpdates="true"
            :filters="{
              target_type: 'template',
              target_id: template_id
            }"
          />
          <card v-else>
            <template #icon>
              <font-awesome-icon icon="newspaper" />
            </template>
            <template #title>Activities</template>
            <p>Save this template first to see related activities.</p>
            <p>
              <btn class="btn btn-primary" ref="forceSaveBtn" :loading="loading" @click="save"
                >Save now?</btn
              >
            </p>
          </card>
        </div>
      </panel>
    </side-panel>
  </spin-container>
</template>

<script>
import { Codemirror } from 'vue-codemirror'
// import 'codemirror/addon/selection/active-line';
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import DocumentEditor from '@/components/ui/DocumentEditor/DocumentEditor.vue'
import SearchField from '@/components/ui/SearchField.vue'
import Activities from '@/components/ui/activities/Activities.vue'
import PresentationSettings from '../quote/presentation/PresentationSettings.vue'
import SidePanelDetails from '../ui/SidePanelDetails.vue'

export default {
  name: 'TemplateBody',
  mixins: [
    ObjectManipulator(
      'template' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */,
      'Template' /* StoreName, object type in title case ie: LaborType, Quote, Client, CostType etc */
    ),
    BodyMixin /* , AutoSave */
  ],
  data() {
    return {
      html: '',
      tokenToolbar: 1,
      availableTokens: [],
      tokenClasses: [],
      toggleVisibleTokenClass: null,
      searchPhrase: '',
      showEditor: false
    }
  },
  watch: {
    html(newVal) {
      c.throttle(
        () => {
          this.template_html = newVal
        },
        { delay: 2000, debounce: true }
      )
    }
  },
  computed: {
    presentationMeta: {
      get() {
        // if (this.templateType === 4 || this.templateType === 101) {
        //   return Quote.presentationSettingsDefaulter(this.oMeta);
        // }
        return this.oMeta
      },
      set(meta) {
        this.oMeta = meta
      }
    },
    templateType() {
      return +this.template_type_id
    },
    termsAndConditions: {
      get() {
        return this.oMeta && this.oMeta.termsAndConditions
      },
      set(v) {
        this.oMeta = {
          ...this.oMeta,
          termsAndConditions: v
        }
      }
    },
    filteredTokens() {
      const r = new RegExp(
        `(.*)?${String(this.searchPhrase)
          .replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
          .split(' ')
          .join('(.*)?')}(.*)?`,
        'i'
      )
      return this.availableTokens.filter((t) => !this.searchPhrase || r.test(t.join(',')))
    }
  },
  methods: {
    tokenClassClick(s) {
      let search
      switch (s) {
        case 'special':
          search = '('
          break
        default:
          search = `@${s}`
          break
      }

      if (this.searchPhrase === search) {
        this.searchPhrase = null
        this.toggleVisibleTokenClass = null
      } else {
        this.searchPhrase = search
        this.toggleVisibleTokenClass = s
      }
    },
    addTokens(a) {
      this.tokenClasses = []
      this.availableTokens = a.map((t) => [...t, this.getTokenClass(t)])
    },
    getTokenClass(token) {
      const tclass = /@(\w+)\.\w+/.test(token[1])
        ? token[1].replace(/@(\w+)\.\w+/, '$1')
        : 'special'
      if (!this.tokenClasses.includes(tclass)) this.tokenClasses.push(tclass)
      return tclass
    },
    ready() {},
    getValue() {
      if (
        (this.$refs.codemirror && this.$refs.codemirror.content) ||
        (this.$refs.ckeditor && this.$refs.ckeditor.getValue)
      ) {
        return this.templateType === 100
          ? this.$refs.codemirror.content
          : this.$refs.ckeditor.getValue()
      }
      return this.template_html
    },
    changed() {
      this.isDirty = true
      c.throttle(
        () => {
          this.template_html = this.getValue()
          this.checkIfDirty()
        },
        {
          delay: 2000
        }
      )
    },
    beforeSave() {
      this.template_html = this.getValue()
    },
    afterSave() {
      this.isDirty = false
    },
    afterSelect() {
      this.html = this.template_html
      this.$nextTick(() => {
        this.showEditor = true
      })
    }
  },
  components: {
    SidePanelDetails,
    PresentationSettings,
    DocumentEditor,
    Codemirror,
    SearchField,
    Activities
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
// @import '../../../node_modules/codemirror/theme/paraiso-light.css';

.CodeMirror {
  height: 100% !important;
  width: calc(100% + 40px);
  margin-left: -20px;
}

.template-panel {
  .cc-container {
    max-width: 100% !important;
    flex-basis: auto !important;
    .ckeditor-container.full-width {
      margin: 0 !important;
      max-width: 100% !important;
      .cke_top {
        max-width: 100% !important;
      }
    }
  }
}
</style>

<style lang="scss" rel="stylesheet/scss" scoped></style>
