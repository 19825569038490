<template>
  <div
    v-if="selected"
    class="quote-details--container"
    :class="{ 'sidebar-closed': !$parent.$parent.sidebarOpen }"
  >
    <drop ref="drop" position="bottom left" targetAttachment="bottom left" offset="0 7px">
      <template #button>
        <div
          v-if="$parent.$parent.sidebarOpen"
          class="quote-name-link"
          v-tooltip="{
            content: 'Click for quote details',
            placement: 'bottom',
            delay: { show: 700, hide: 300 }
          }"
        >
          <span :class="iconClass" class="mr-3"></span>
          <span v-if="quote_name" class="quote-details-name">{{
            $f.truncate(quote_name, 20)
          }}</span>
          <span v-else class="quote-details-name">Quote Details</span>
        </div>

        <div
          v-else
          class="quote-name-link closed"
          v-tooltip="{
            content: 'Click for quote details',
            classes: ['sidepanel-quote-details-tooltip'],
            placement: 'left',
            delay: { show: 800, hide: 300 }
          }"
        >
          <span :class="iconClass" style="font-size: 1.5em"></span>
        </div>
      </template>
      <div style="margin-bottom: 0.5em">
        <span style="font-weight: 500">Quote Details</span>
      </div>

      <btn-group class="quote-button-group">
        <btn
          rounded
          class="more px-0 p-2 info"
          :action="() => $store.dispatch('Quote/call', { object: { type: 'quote', id: quote_id } })"
        >
          <font-awesome-icon icon="earphone" />
        </btn>
        <btn
          rounded
          class="more px-0 p-2 info ml-2"
          :action="() => $store.dispatch('Quote/map', { object: { type: 'quote', id: quote_id } })"
        >
          <font-awesome-icon icon="map" />
        </btn>
        <btn-bar
          class="ml-2"
          style="padding-top: 7px"
          btnClass="more info"
          offset="-275px 0px"
          :editing="editing"
          :contextual="true"
          :go="false"
          @reload="reload"
          :selected="[casted]"
        />
        <btn rounded v-if="!contextual" class="more px-0 p-2 info ml-2" @click="edit">
          <font-awesome-icon icon="rectangle-history-circle-plus" />
        </btn>
      </btn-group>
      <div class="quote-details-spacer"></div>

      <div
        class="quote-data-group"
        @click="
          () =>
            $store.dispatch(
              'copyToClipboard',
              `${quote_address || ''}, ${quote_city || ''}, ${quote_prov || ''}`
            )
        "
      >
        <p class="quote-info-title">Address</p>
        <p v-if="quote_suite" class="quote-info-data">{{ $f.truncate(quote_suite, 24) }}</p>
        <p v-if="quote_address" class="quote-info-data">{{ $f.truncate(quote_address, 24) }}</p>
        <p v-if="quote_city || quote_prov" class="quote-info-data">
          {{ quote_city }} {{ quote_prov }}
        </p>
        <p v-if="quote_postal" class="quote-info-data">{{ quote_postal }}</p>
      </div>
    </drop>
  </div>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'

export default {
  name: 'QuoteDetails',
  mixins: [ObjectManipulator('quote', false)],
  mounted() {},

  methods: {
    edit() {
      return this.$store.dispatch('edit', { type: 'quote', id: this.quote_id })
    }
  },

  computed: {
    iconClass() {
      switch (this.quote_status) {
        case 'd':
          return 'ban'
        case 'k':
        case 'f':
          return 'house-building'
        case 'g':
          return 'check'
        case 'p':
        default:
          return 'file'
      }
    }
  },

  props: {
    contextual: {
      type: Boolean,
      default: true
    },

    deselectOnDestroy: {
      default: false
    },

    editing: {
      default: false
    },

    /**
     * Basic version is fine
     */
    forceFull: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-quote-details-tooltip {
  position: fixed !important;
  left: 169px !important;
}

.quote-details--container {
  width: auto;
  height: auto;
  color: $cool-gray-700;
  background-color: #fff;
  text-align: center;
  padding: 1.22em 1.1em;
  border-bottom: 1px solid $cool-gray-300;

  &.sidebar-closed {
    padding: 0.65em 1.1em;
  }

  .quote-name-link {
    width: 213.8px;
    cursor: pointer;
    text-align: start;

    &:hover {
      color: #1f92fc !important; //bootstrap info
    }

    &.closed {
      margin-left: 5px;
      color: $cool-gray-600;
    }
  }
}

.quote-details-name {
  font-size: 1.2em;
}

.quote-button-group {
  margin-left: 12px;
}

.quote-details-spacer {
  width: 193px;
  margin-bottom: 0.5em;
  border-bottom: 2px solid $cool-gray-300;
}

.quote-data-group {
  &:hover {
    color: #1f92fc !important; //bootstrap info
    cursor: pointer;
  }
}

.quote-info-title {
  font-size: 0.7em;
  margin-top: 0.5em;
  color: $cool-gray-600;
}

.quote-info-data {
  &:hover {
    color: #1f92fc !important; //bootstrap info
    cursor: pointer;
  }
}
</style>
