import c from './Helpers'

export const isImage = (fileType) => {
  return /(^image).*?(png|jpg|jpeg|gif|tif|bmp|ico|webp|svg)/.test(fileType)
}

export const isPdf = (fileType) => {
  return /(^application).*?(pdf)/.test(fileType)
}

export const isCsv = (fileType) => {
  return /(^text).*?(csv)/.test(fileType)
}

export const isExcel = (fileType) => {
  return /ms-excel|xls|xlsx|spreadsheetml/i.test(fileType)
}

export const isDoc = (fileType) => {
  return /msword|ms-word|docx|wordprocessingml/i.test(fileType)
}

export const isPowerPoint = (fileType) => {
  return /ms-powerpoint|ppt|ppx|pptx|presentation/i.test(fileType)
}

export const isMsDoc = (fileType) => {
  return isDoc(fileType) || isPowerPoint(fileType) || isExcel(fileType)
}

export const viewLink = (id, type) => {
  if (isPowerPoint(type)) return c.link(`file/view/ppt/${id}`, {}, true)
  if (isCsv(type)) return c.link(`file/view/csv/${id}`, {}, true)
  if (isExcel(type)) return c.link(`file/view/excel/${id}`, {}, true)
  if (isDoc(type)) return c.link(`file/view/doc/${id}`, {}, true)
  if (isPdf(type)) return c.link(`file/view/pdf/${id}`, {}, true)
  return c.link(`file/view/${id}`, {}, true, c.getStorage('scope'))
}

export const picUrl = (id, thumbnail = false) => {
  return c.link(`file/pic/${thumbnail ? 'thumb/' : ''}${id}`)
}

export default {
  isImage,
  isPdf,
  isCsv,
  isExcel,
  isDoc,
  isPowerPoint,
  isMsDoc,
  viewLink,
  picUrl
}
