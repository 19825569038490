// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'province',
  
  skipAudit: true,

  fields: {
    province_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'country',
    },
    province_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    province_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    province_burden: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    tax_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'tax',
    },
    timezone_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'timezone',
    },
    localization_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'localization',
    },
    province_minimum_wage: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },

  generateVueActions() {
    return {
      async getProvinceFromName({ dispatch }, { name }) {
        const { set } = await dispatch('search', {
          searchPhrase: name,
        });

        if (!set.length) return null;

        return set[0].province_id;
      },
    };
  },
};
