/**
 * Common methods and logic for the approval system
 * Can be used from appsync or from FE
 */

import _ from './Helpers'

export const statuses = {
  APPROVED: 'APPROVED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  PENDING: 'PENDING'
}

export const approvalStatuses = {
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED'
}

export const sortStepsByOrder = (steps) => steps.sort((a, b) => a.order - b.order)

export const getCurrentIndex = (steps, id) => steps.findIndex((step) => step.id === id)

export const getCurrentStep = (steps, id) => steps.find((step) => step.id === id)

export const getPreviousStep = (steps, index) => steps[index - 1]

export const getNextStep = (steps, index) => steps[index + 1]

export const getLastStep = (steps) => steps[steps.length - 1]

export const isAwaitingOrApproved = (status) =>
  status === statuses.APPROVED || status === statuses.AWAITING_APPROVAL

export const isApproved = (status) => status === statuses.APPROVED

export const isAwaitingApproval = (status) => status === statuses.AWAITING_APPROVAL

export const doesOrderExist = (steps, order) => steps.find((step) => step.order === order)

/**
 * Insert a new set into an approval
 * @param {Object} newStep
 * @param {Array} steps
 * @returns
 */
export const insertNewStep = (newStep, steps) => {
  // add new step
  const newSteps = [newStep, ...steps]

  // sort steps
  let sortedSteps = sortStepsByOrder(newSteps)

  // if necessary re order steps
  const orderExists = doesOrderExist(steps, newStep.order)
  if (orderExists) {
    sortedSteps = sortedSteps.reduce((acc, step, index) => {
      const isNewStep = newStep.id === step.id
      const hasTheSameOrder = step.order === newStep.order
      const isGreaterThanNewOrder = step.order > newStep.order
      const nextStep = getPreviousStep(sortedSteps, index)
      const prevStep = getPreviousStep(sortedSteps, index)
      const isAwaitingOrApprovedStep = isAwaitingOrApproved(step.status)
      // if the steps after the one added are approved they will be set back to pending and
      // the new stage will be set to awaiting approval
      const nextStepIsApproved = isNewStep && nextStep && nextStep.status === statuses.APPROVED
      const prevStepIsApproved = isNewStep && prevStep && prevStep.status === statuses.APPROVED
      if ((!isNewStep && hasTheSameOrder) || isGreaterThanNewOrder) {
        acc = [
          ...acc,
          {
            ...step,
            order: step.order + 1,
            ...(isAwaitingOrApprovedStep ? { status: statuses.PENDING } : {})
          }
        ]
      } else {
        acc = [
          ...acc,
          {
            ...step,
            ...(nextStepIsApproved || prevStepIsApproved
              ? { status: statuses.AWAITING_APPROVAL }
              : {})
          }
        ]
      }
      return acc
    }, [])
  }

  return sortedSteps
}

/**
 * Insert a new approver into approvers
 * @param {Array} steps
 * @param {String} approvalStepId
 * @param {Object} newApprover
 * @returns
 */
export const insertApproverIntoApprovers = (steps, approvalStepId, newApprover) => {
  const stepBeingAddedTo = steps.find((step) => step.id === approvalStepId)
  return steps.reduce((acc, step) => {
    const isApproved = step.status === statuses.APPROVED
    const awaitingOrApproved = isAwaitingOrApproved(step.status)
    // update the step the approver is being added to
    if (step.id === approvalStepId) {
      acc = [
        ...acc,
        {
          ...step,
          timeUpdated: _.generateCurrentUnix(),
          approvers: [...step.approvers, newApprover],
          ...(isApproved ? { status: statuses.AWAITING_APPROVAL } : {})
        }
      ]
      return acc
    }
    // update the steps after the step being added to
    if (step.order > stepBeingAddedTo.order) {
      acc = [
        ...acc,
        {
          ...step,
          ...(awaitingOrApproved ? { status: statuses.PENDING } : {})
        }
      ]
      return acc
    }
    // add step to result
    acc = [...acc, step]
    return acc
  }, [])
}

export const removeApproverFromApprovalStep = (steps, approvalStepId, approverId) => {
  const stepBeingRemovedFrom = steps.find((step) => step.id === approvalStepId)
  let nextStep = null
  return steps.reduce((acc, step, index) => {
    const awaitingOrApproved = isAwaitingOrApproved(step.status)
    const isApproved = step.status === statuses.APPROVED
    const isPending = step.status === statuses.PENDING
    // the step the approver is being removed from
    if (step.id === approvalStepId) {
      // filter out the approver being removed
      const approvers = step.approvers.filter((approver) => {
        return parseInt(approver.userId, 10) !== parseInt(approverId, 10)
      })
      // if there are no approvers remove the step
      if (!approvers || approvers.length === 0) {
        // if the step being approved is awaiting approval set the next step to awaiting approval
        nextStep = getNextStep(steps, index)
        return acc
      }
      // update the approvers in the step
      acc = [
        ...acc,
        {
          ...step,
          timeUpdated: _.generateCurrentUnix(),
          approvers,
          ...(isApproved ? { status: statuses.AWAITING_APPROVAL } : {})
        }
      ]
    }
    // update the steps after the step being removed from
    if (step.order > stepBeingRemovedFrom.order && (!nextStep || nextStep.id !== step.id)) {
      acc = [
        ...acc,
        {
          ...step,
          ...(awaitingOrApproved ? { status: statuses.PENDING } : {})
        }
      ]
      return acc
    }

    // if the step was removed set the next step to awaiting
    if (step.order > stepBeingRemovedFrom.order && nextStep && nextStep.id === step.id) {
      acc = [
        ...acc,
        {
          ...step,
          ...(isPending ? { status: statuses.AWAITING_APPROVAL } : {})
        }
      ]
      return acc
    }

    return acc
  }, [])
}

/**
 * Update the status of an approval step
 * @param {Array} steps
 * @param {String} approvalStepId
 * @param {String} status
 * @returns
 */
export const updateApprovalStatus = (steps, approvalStepId, status) => {
  const currentIndex = getCurrentIndex(steps, approvalStepId)
  const stepBeingAddedTo = steps.find((step) => step.id === approvalStepId)
  // status update was declined so previous step must be set back to awaiting
  if (status === approvalStatuses.DECLINED) {
    const prevStep = getPreviousStep(steps, currentIndex)
    return steps.reduce((acc, step) => {
      // update the next step to awaiting approval
      const shouldUpdatePrevStep = prevStep && step.id === prevStep.id
      if (shouldUpdatePrevStep) {
        acc = [
          ...acc,
          {
            ...step,
            status: statuses.AWAITING_APPROVAL,
            timeUpdated: _.generateCurrentUnix()
          }
        ]
        return acc
      }
      // update the current step if it is the first step then switch it to awaiting approval again
      if (step.id === approvalStepId) {
        acc = [
          ...acc,
          {
            ...step,
            status: prevStep ? statuses.PENDING : statuses.AWAITING_APPROVAL,
            timeUpdated: _.generateCurrentUnix()
          }
        ]
        return acc
      }
      if (step.order > stepBeingAddedTo.order) {
        acc = [
          ...acc,
          {
            ...step,
            status: statuses.PENDING,
            timeUpdated: _.generateCurrentUnix()
          }
        ]
        return acc
      }
      // otherwise leave step as is
      acc = [...acc, step]
      return acc
    }, [])
  }
  // status was approved so next step needs to updated
  const nextStep = getNextStep(steps, currentIndex)
  return steps.reduce((acc, step) => {
    // update the next step to awaiting approval
    const shouldUpdateNextStep =
      nextStep && step.id === nextStep.id && step.status === statuses.PENDING
    if (shouldUpdateNextStep) {
      acc = [
        ...acc,
        {
          ...step,
          status: statuses.AWAITING_APPROVAL,
          timeUpdated: _.generateCurrentUnix()
        }
      ]
      return acc
    }
    // update the current step to approved
    if (step.id === approvalStepId) {
      acc = [
        ...acc,
        {
          ...step,
          status: statuses.APPROVED,
          timeUpdated: _.generateCurrentUnix(),
          timeApproved: _.generateCurrentUnix()
        }
      ]
      return acc
    }
    // otherwise leave step as is
    acc = [...acc, step]
    return acc
  }, [])
}

/**
 * Map approvals to items
 * @param {Array} approvals
 * @param {Array} items
 * @returns
 */
export const mapApprovalsToItems = (approvals = [], items) => {
  return items.map((item) => {
    const approval = approvals.find(({ itemId }) => itemId === item.item_id)
    return {
      ...item,
      ...(approval ? { approval } : {})
    }
  })
}

/**
 * Get the approver from a step
 * @param {Object} step
 * @param {String} approverUserId
 * @returns
 */
export const getApproverFromStep = (step, approverUserId) => {
  return step.approvers.find((approver) => approver.userId.toString() === approverUserId.toString())
}

/**
 * Check to see if the current approver session has the ability to approve
 * @param {Object} approvalStep
 * @param {String} approverUserId
 * @returns
 */
export const canApprove = (approvalStep, approverUserId) => {
  const isAwaitingApproval = approvalStep.status === statuses.AWAITING_APPROVAL
  const approver = isApprover(approvalStep, approverUserId)
  if (!approver) return false
  const approverAuthority = approver.authority || 1
  return (isAwaitingApproval && approver) || approverAuthority > 1
}

/**
 * Check to see if the session user is an approver
 * @param {Object} approvalStep
 * @param {String} approverUserId
 * @returns
 */
export const isApprover = (approvalStep, approverUserId) => {
  return getApproverFromStep(approvalStep, approverUserId)
}

/**
 * Check to see if the step has an approver of passed role type
 * @param {Object} approval
 * @param {String} role
 * @returns
 */
export const hasStepForRole = (approval, role) => {
  let hasRole = false
  const steps = approval.approvalSteps
  steps.forEach((step) => {
    const { approvers } = step
    const found = approvers.filter((approver) => approver.role === role)
    if (found && found.length > 0) hasRole = true
  })
  return hasRole
}

/**
 * Replace the approver in the array of approvers
 * @param {Array} steps
 * @param {String} approvalStepId
 * @param {Object} newApprover
 * @returns
 */
export const replaceApproverInApprovers = (steps, approvalStepId, newApprover) => {
  const stepBeingAddedTo = steps.find((step) => step.id === approvalStepId)
  return steps.reduce((acc, step) => {
    const isApproved = step.status === statuses.APPROVED
    const awaitingOrApproved = isAwaitingOrApproved(step.status)
    // update the step the approver is being added to
    if (step.id === approvalStepId) {
      acc = [
        ...acc,
        {
          ...step,
          timeUpdated: _.generateCurrentUnix(),
          approvers: [newApprover],
          ...(isApproved ? { status: statuses.AWAITING_APPROVAL } : {})
        }
      ]
      return acc
    }
    // update the steps after the step being added to
    if (step.order > stepBeingAddedTo.order) {
      acc = [
        ...acc,
        {
          ...step,
          ...(awaitingOrApproved ? { status: statuses.PENDING } : {})
        }
      ]
      return acc
    }
    // add step to result
    acc = [...acc, step]
    return acc
  }, [])
}

/**
 * Generate an array of all the approver user ids for approval
 * @param {Object} approval
 * @returns
 */
export const getApproverUserIdsFromStep = (approval) => {
  return approval.approvalSteps.reduce((acc, step) => {
    const ids = step.approvers.reduce((ac, approver) => {
      if (approver.userId) ac = [...ac, approver.userId]
      return ac
    }, [])
    acc = [...acc, ...(ids || [])]
    return acc
  }, [])
}

/**
 * Remove an approver from an approval step
 * @param {Object} approval
 * @param {String} approvalStepId
 * @returns
 */
export const removeApprovalStep = (approval, approvalStepId) => {
  const steps = approval.approvalSteps
  const currentIndex = getCurrentIndex(steps, approvalStepId)
  const nextStep = getNextStep(steps, currentIndex)
  const stepToRemove = getCurrentStep(steps, approvalStepId)
  const isStepToRemoveAwaitingApproval = stepToRemove.status === statuses.AWAITING_APPROVAL
  return steps.reduce((acc, step) => {
    const isStepToRemove = step.id === approvalStepId
    if (isStepToRemove) return acc
    const isNextStep = nextStep.id === step.id
    // if the current status is awaiting approval switch the next step to awaiting approval
    if (isNextStep && isStepToRemoveAwaitingApproval) {
      return [
        ...acc,
        {
          ...step,
          status: statuses.AWAITING_APPROVAL
        }
      ]
    }
    acc = [...acc, step]
    return acc
  }, [])
}

/**
 * Check to see if approval is approved based on approver user id
 * @param {Object} approval
 * @param {String} approverUserId
 * @returns
 */
export const isApprovedByApprover = (approval, approverUserId) => {
  if (!approval || !approverUserId) return false
  const steps = approval.approvalSteps
  let hasBeenApproved = false
  steps.forEach((step) => {
    const approver = getApproverFromStep(step, approverUserId)
    // is an approver and step is approved
    if (approver && isApproved(step.status)) {
      hasBeenApproved = true
    }
  })
  return hasBeenApproved
}

/**
 * Get the steps that need approval by approver user id
 * @param {Object} approval
 * @param {String} approverUserId
 * @returns
 */
export const getStepsToApprove = (approval, approverUserId) => {
  if (!approval || !approverUserId) return []
  const steps = approval.approvalSteps
  const stepToApprove = []
  steps.forEach((step) => {
    const approver = getApproverFromStep(step, approverUserId)
    // is an approver and step is approved
    if (approver && canApprove(step, approverUserId)) {
      stepToApprove.push(step)
    }
  })
  return stepToApprove
}

/**
 * Check to see if its the first approval step
 * @param {Object} approval
 * @param {String} approverUserId
 * @returns
 */
export const isFirstStepOfApproval = (approval, approverUserId) => {
  if (!approval || !approverUserId) return false
  const stepsToApprove = getStepsToApprove(approval, approverUserId)
  if (!stepsToApprove || stepsToApprove.length === 0) false
  const step = stepsToApprove[0]
  const steps = approval.approvalSteps
  const index = getCurrentStep(steps, step.id)
  const prevStep = getPreviousStep(steps, index)
  return prevStep ? false : true
}

export default {
  statuses,
  approvalStatuses,
  sortStepsByOrder,
  getCurrentIndex,
  getPreviousStep,
  getNextStep,
  getLastStep,
  isAwaitingOrApproved,
  isApprover,
  doesOrderExist,
  insertNewStep,
  insertApproverIntoApprovers,
  replaceApproverInApprovers,
  getApproverUserIdsFromStep,
  removeApproverFromApprovalStep,
  updateApprovalStatus,
  mapApprovalsToItems,
  canApprove,
  isApproved,
  isAwaitingApproval,
  getApproverFromStep,
  hasStepForRole,
  removeApprovalStep,
  isApprovedByApprover,
  getStepsToApprove,
  isFirstStepOfApproval
}
