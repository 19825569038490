<template>
  <div
    class="quote-summary--assembly"
    :style="
      presentationSettings.assemblyHeadingColor
        ? {
            borderColor: `${presentationSettings.assemblyHeadingColor} !important`
          }
        : {}
    "
  >
    <div
      class="assembly-top"
      :style="
        presentationSettings.assemblyHeadingColor
          ? {
              backgroundColor: `${presentationSettings.assemblyHeadingColor} !important`
            }
          : {}
      "
    >
      <ImageGallery
        class="assembly-images"
        v-if="itemPictures.length && showImages && presentationSettings.showDocumentPictures"
        :thumbs="thumbUrls"
        :show-as-background="false"
        :urls="fileUrls"
      />

      <div class="assembly-title">
        {{ object.assembly_name || 'Grouped items' }}
      </div>

      <div class="assembly-desc" v-if="desc">
        {{ desc }}
      </div>

      <div class="prod-notes" v-if="prodDesc && presentationSettings.showProductionNotes">
        <strong class="text-danger">Production notes</strong>
        <p>
          {{ String(prodDesc || '').trim() }}
        </p>
      </div>

      <div
        class="assembly-attributes"
        v-if="
          showProperties &&
          object.aoProperties &&
          object.aoProperties.length &&
          object.aoProperties[0] &&
          object.aoProperties[0][0] &&
          object.aoProperties[0][0].length
        "
      >
        <ul>
          <li v-for="(attribute, index) in object.aoProperties" :key="index">
            <template
              v-if="Array.isArray(attribute) && typeof attribute[0] === 'string' && attribute[0]"
            >
              <strong>
                {{ attribute[0] }}
              </strong>
              {{
                attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1]
                  ? `: ${attribute[1]}`
                  : ''
              }}
            </template>
          </li>
        </ul>
      </div>
    </div>
    <div class="assembly-items">
      <QuoteSummaryItems
        v-bind="$props"
        :refId="object.refId"
        :rootRefId="rootRefId"
        :store="store"
      />
    </div>
    <div
      class="assembly-bottom"
      :style="
        presentationSettings.assemblyHeadingColor
          ? {
              backgroundColor: `${presentationSettings.assemblyHeadingColor} !important`
            }
          : {}
      "
      v-if="(showQty && (qty > 1.0 || qty == 0)) || showCost || showPrice"
    >
      <div class="assembly-subtotal" :style="footingStyle">
        <strong> {{ objectLocal.assembly_name }} subtotal </strong>

        <span v-if="showCost" style="margin-left: auto">(Cost: {{ $f.currency(cost) }})</span>
        <div class="assembly-totals" :class="!qty ? 'excluded' : ''">
          <template v-if="!qty || qty <= 0"> not included </template>
          <template v-else>
            <template v-if="showQty && qty > 1">
              {{ $f.number(qty) }} each
              <span v-if="showPrice" class="mx-2">•</span>
            </template>

            <template v-if="showPrice">
              {{ $f.currency(price) }}
            </template>
            <template v-else-if="!showPrice"> included </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PresentationAssemblyMixin from '../presentation/PresentationAssemblyMixin'
import ImageGallery from '../../items/ImageGallery.vue'

export default {
  name: 'QuoteSummaryAssembly',
  components: { ImageGallery },
  mixins: [PresentationAssemblyMixin],

  computed: {
    objectLocal() {
      return this.object
    },
    norm() {
      return this.normalized || this.$store.state[this.store].normalized
    },
    children() {
      return this.object.aoChildren.map((childRef) => this.norm[childRef])
    }
  },

  props: {
    normalized: {
      default: null
    },
    object: {
      required: true
    },
    presentationSettings: {
      required: true
    },
    store: {
      default: 'Quote'
    },
    rootRefId: {
      required: true
    },
    showImages: {
      default: true
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: $cool-gray-200;
.quote-summary--assembly {
  border: 1px solid $bg;
  overflow: hidden;
  border-radius: 1em;
  box-sizing: content-box;
  border-collapse: collapse;
  border-top: 0;

  .assembly-images {
    margin: 0.15em 0;
  }

  .assembly-title {
    margin: 0.15em 0;
    font-weight: bold;
    font-size: 1.2em;
    mix-blend-mode: multiply;
  }

  .assembly-desc {
    color: $cool-gray-700;
    font-weight: 500;
  }

  .assembly-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0.5em 0;
    padding-left: 0.75em;

    // border-bottom: 1px dotted $cool-gray-700;
    // border-top: 2px solid $cool-gray-700;
    background: $bg;

    // border-top-left-radius: 0.25em;
    // border-bottom-left-radius: 0.25em;
    /*border-radius: 0.25em;*/
    /*border-bottom-left-radius:0.25em;*/
    /*border-bottom-right-radius:0.25em;*/

    min-height: 3.25em;
  }

  .assembly-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .assembly-items {
    padding-left: 1em;
    > .quote-summary--item-list {
    }
    > .quote-summary--item-list > .quote-summary--assembly:first-child {
      .assembly-top {
        /*border-top: none !important;*/
      }
    }
    .quote-summary--assembly {
      border-right: none !important;
    }
  }

  .assembly-bottom {
    padding: 0.5em 0;
    padding-right: 0.5em;

    // border-bottom: 1px dotted $cool-gray-700;
    // border-top: 2px solid $cool-gray-700;
    background: $bg;
    // border: 0.5em solid $bg;

    // border-top-left-radius: 0.25em;
    // border-bottom-left-radius: 0.25em;
    /*border-radius: 2em;*/
    /*margin-bottom: 1em;*/
    /*border-top-left-radius:0.25em;*/
    /*border-top-right-radius:0.25em;*/

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    min-height: 3.25em;
  }

  .assembly-attributes {
    font-weight: normal;
    font-size: 0.9em;

    > ul {
      margin: 0;
      padding-left: 1.5em;
    }
  }

  .assembly-subtotal {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    width: 100%;
    // mix-blend-mode: $assembly-subtotal-mix-blend-mode !important;

    background: transparent !important;
    // background: $assembly-heading-background-color !important;

    color: $cool-gray-400 !important;
    font-weight: 500;
    padding: 0.15em 0;
    //margin: $assembly-subtotal-margin !important;
    //border-top: $assembly-subtotal-border-top !important;
    // border-bottom: 0.25em solid $assembly-heading-background-color !important;

    // border-radius: $assembly-subtotal-border-radius !important;

    // margin-bottom: -3rem !important;

    .assembly-totals {
      border-radius: 1.5em;
      padding: 0.5em 1em;
      background: $cool-gray-500;
      color: $flame-white !important;
      font-weight: bold;
      font-size: 0.9em;
      margin-left: 2em;
    }

    .assembly-options {
      margin-top: 1em;
    }

    strong {
      color: $cool-gray-600;
      font-size: 0.9em;
    }
  }
}

.quote-summary--assembly + .quote-summary--assembly {
  margin-top: 0.3em;
}

.quote-summary--assembly:first-child {
  margin-top: 0.3em;
}
.quote-summary--assembly:last-child {
  margin-bottom: 0.3em;
}
</style>
