<template>
  <div class="flex flex-col gap-2 mx-4">
    <div class="text-md font-medium mt-2">AutoCost suppliers</div>
    <div v-for="(vendor, index) in Object.keys(value)" :key="index">
      <div class="flex flex-row gap-3">
        <!-- eslint-disable -->
        <Checkbox v-model="value[vendor].search" @input="setUnapplied(true)" />
        <div>{{ value[vendor].vendorName }}</div>
      </div>
    </div>
    <div class="text-md font-medium mt-4">Distance</div>
    <div class="flex flex-col gap-2 p-1 w-fit bg-surface-100 rounded">
      <a
        v-for="(dist, index) in distances"
        :key="index"
        class="flex py-1 px-3 rounded transition"
        :class="dist.selected ? 'bg-flame-white shadow-md' : 'hover:bg-surface-200 cursor-pointer'"
        @click="onDistanceInput(dist.value)"
      >
        <p>{{ dist.text }}</p>
      </a>
    </div>

    <Btn
      v-show="unapplied"
      severity="bolster"
      size="md"
      class="mt-4 animate-fadeIn"
      @click="applyFilters"
    >
      Apply filters
    </Btn>
  </div>
</template>

<script>
import AutoCost from '../../../../imports/api/AutoCost'

export default {
  name: 'TraverseAutoCostFilters',
  emits: ['distanceInput'],
  props: {
    value: {
      type: Object,
      required: true
    },
    defaultDistance: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      distance: this.defaultDistance,
      unapplied: false
    }
  },
  computed: {
    distances() {
      const countries = AutoCost.getAutoCostCountries(this.$store.state.session.company)
      let unit = 'km'
      if (countries.includes(2)) unit = 'mi'
      return [
        {
          value: `25${unit}`,
          text: `25 ${unit}`,
          selected: this.distance === `25${unit}`
        },
        {
          value: `50${unit}`,
          text: `50 ${unit}`,
          selected: this.distance === `50${unit}`
        },
        {
          value: `100${unit}`,
          text: `100 ${unit}`,
          selected: this.distance === `100${unit}`
        },
        {
          value: `200${unit}`,
          text: `200 ${unit}`,
          selected: this.distance === `200${unit}`
        },
        {
          value: `500${unit}`,
          text: `500 ${unit}`,
          selected: this.distance === `500${unit}`
        }
      ]
    }
  },
  methods: {
    setUnapplied(value) {
      this.unapplied = value
    },
    onDistanceInput(value) {
      this.$emit('distanceInput', value)
      this.distance = value
      this.setUnapplied(true)
    },
    applyFilters() {
      this.setUnapplied(false)
      this.$emit('applyFilters')
    }
  }
}
</script>
