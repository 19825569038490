<template>
  <Container>
    <CardSection>
      <CardList>
        <template v-if="!chosen">
          <CardListField>
            <span>
              Search for vendor
              <small class="text-info">
                Search by email (recommended), phone, name, trade type or contact person.
              </small>
            </span>

            <div class="flex w-full">
              <div class="relative w-full">
                <IconField iconPosition="left" class="w-full">
                  <InputIcon class="z-[999]">
                    <icon icon="search" class="text-surface-800 z-[999]" />
                  </InputIcon>
                  <InputText
                    placeholder="Search vendors..."
                    v-model="query"
                    class="!font-normal"
                    type="text"
                    size="large"
                  />
                </IconField>
                <Loader :loading="loading" />
              </div>
              <Btn
                @click="toggleSettings"
                v-tooltip="'Additional search settings'"
                severity="tertiary"
                class="ml-1 relative"
                size="lg"
              >
                <icon icon="gears" />
                <Badge
                  class="absolute top-0.5 right-0.5"
                  v-if="badgeCount > 0"
                  severity="info"
                  size="small"
                  :value="badgeCount"
                ></Badge>
              </Btn>
            </div>
          </CardListField>
        </template>
        <!-- No results from search -->
        <CardListField v-if="noResults">
          <div
            class="flex !justify-start !items-center !flex-row w-full flex-nowrap text-sm text-cool-gray-800"
          >
            <span
              >No results
              <span
                @click="() => emit('switchView', 'create')"
                class="underline hover:text-pitch-black cursor-pointer mx-0.5"
                >create a new vendor</span
              >?</span
            >
          </div>
        </CardListField>
        <!-- results from search -->
        <CardListField v-for="company in filteredSet" :key="company.company_id">
          <template>
            <span>
              <span>
                {{ company.company_name }}
                <small class="text-muted ml-1">
                  {{ company.company_city }}
                </small>
                <small class="text-muted ml-1">
                  {{ company.province_abbr }}
                </small>
              </span>

              <small class="text-muted" v-if="company.asTradeTypeNames">
                {{ company.asTradeTypeNames.join(', ') }}
              </small>
            </span>

            <div>
              <Checkbox
                class="radio"
                :value="chosen && chosen.company_id === company.company_id ? 1 : 0"
                @input="(v) => chooseInputHandler(v, company)"
              />
            </div>
          </template>
        </CardListField>
      </CardList>
    </CardSection>

    <!-- additional filter settings -->
    <MiniModal ref="searchSettings">
      <template #header> Additional search settings </template>
      <CardList>
        <CardListField>
          <div class="inline-flex !justify-start !items-center !flex-row">
            <Checkbox class="bg-white border border-dark" v-model="searchByProvince" />
            <span class="ml-2 !text-cool-gray-800 !font-bold">Search by state/prov</span>
          </div>

          <Choose
            :style="{
              opacity: searchByProvince ? 1 : 0.3
            }"
            :disabled="!searchByProvince"
            schema="company:province_id"
            :return-array="false"
            v-model="searchProvince"
          />
        </CardListField>

        <CardListField>
          <div class="inline-flex !justify-start !items-center !flex-row">
            <Checkbox class="bg-white border border-dark" v-model="searchByCity" />
            <span class="ml-2 !text-cool-gray-800 !font-bold"> Search by city </span>
          </div>
          <Field
            :style="{
              opacity: searchByCity ? 1 : 0.3
            }"
            :disabled="!searchByCity"
            v-model="searchCity"
            :emit-delay="50"
          />
        </CardListField>
      </CardList>
      <div class="flex justify-end">
        <Btn
          class="mt-2 max-w-48"
          :disabled="searchByCity === 0 && searchByProvince === 0"
          @click="onClearFilters"
          >Clear filters <span v-if="badgeCount > 0">({{ badgeCount }})</span></Btn
        >
      </div>
    </MiniModal>
  </Container>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, defineEmits, watch } from 'vue'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import Badge from 'primevue/badge'

const emit = defineEmits(['select', 'createNew', 'switchView'])

const $store = useStore()

const query = ref('')
const loading = ref(false)
const chosen = ref()
const result = ref([])
const searchSettings = ref()
const searchByCity = ref(0)
const searchByProvince = ref(0)

const searchProvince = ref(
  $store.state.session.user.province_id > 0 ? $store.state.session.user.province_id : null
)
const searchCity = ref($store.state.session.company.company_city)

/**
 * There are no results
 */
const noResults = computed(
  () => query.value && !chosen.value && !result.value.length && !loading.value
)

/**
 * Total filter count
 */
const badgeCount = computed(() => {
  let count = 0
  if (searchByCity.value) count++
  if (searchByProvince.value) count++
  return count
})

/**
 * Make sure the chosen is filtered out of the set
 */
const filteredSet = computed(() => {
  return result.value.filter(
    (company) =>
      (query.value && !chosen.value) ||
      (chosen.value && chosen.value.company_id === company.company_id)
  )
})

/**
 * When the user opens the additional settings modal
 */
const toggleSettings = () => {
  searchSettings.value.open()
}

/**
 * When a company clears all the additional filters
 */
const onClearFilters = () => {
  searchByCity.value = 0
  searchByProvince.value = 0
}

/**
 * When a user selects a vendor from the list
 * @param {Number} value
 * @param {Object} company
 */
const chooseInputHandler = (value, company) => {
  if (value) {
    chosen.value = company
    emit('select', company)
    return
  }
  chosen.value = null
  emit('select', null)
}

/**
 * When the user types a query to search for vendors
 */
const search = async () => {
  if (query.value.length < 3) return
  loading.value = true

  const filters = {}
  if (searchByCity.value && searchCity.value) filters.company_city = `LIKE${searchCity.value}`
  if (searchByProvince.value && searchProvince.value) filters.province_id = searchProvince.value

  const { set } = await $store.dispatch('Company/search', {
    searchPhrase: query.value,
    limit: 10,
    filters,
    minScore: 240
  })
  result.value = set
  loading.value = false
}

/**
 * Throttle the search
 */
const throttleSearch = () => {
  return c.throttle(
    () => {
      search()
    },
    {
      delay: 250
    }
  )
}

/**
 * Watch the filters and query for changes
 */
watch([query, searchByCity, searchCity, searchByProvince, searchProvince], () => {
  if (query.value) throttleSearch()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
