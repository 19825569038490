export const useFile = () => {
  // ======== Methods ======== //
  const fetchFile = async (fileUrl, fileType = null) => {
    if (fileUrl.startsWith(window.location.origin)) {
      return {
        blobMimeType: fileType,
        blobUrl: fileUrl
      }
    }

    const res = await fetch(fileUrl, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`
      }
    })
    const blob = await res.blob()

    return {
      blobMimeType: fileType || blob.type,
      blobUrl: URL.createObjectURL(blob)
    }
  }
  const revokeFile = (blobUrl) => {
    URL.revokeObjectURL(blobUrl)
  }
  /**
   * @param files {({ src: string, type: string? })[]}
   * @return {Promise<{ blobUrl: string, blobMimeType: string }>[]}
   */
  const fetchFiles = (files) => {
    return Promise.all(files.map((file) => fetchFile(file.src, file.type)))
  }
  const revokeFiles = (blobUrls) => {
    blobUrls.forEach((blobUrl) => revokeFile(blobUrl))
  }
  // ===================== //

  return {
    fetchFile,
    fetchFiles,
    revokeFile,
    revokeFiles
  }
}
