<template>
  <img :src="src" class="h-5" :data-icon="svg" />
</template>

<script>
// import SVGs to use as icon
import autocost from '@/assets/SVG/autocost.svg'
import autocostWhite from '@/assets/SVG/autocost-white.svg'
import autocostDark from '@/assets/SVG/autocost-dark.svg'
import catalogSave from '@/assets/SVG/catalog-save.svg'
import catalogSaved from '@/assets/SVG/catalog-saved.svg'
import catalogAdd from '@/assets/SVG/catalog-add.svg'
import catalogAddLight from '@/assets/SVG/catalog-add-light.svg'
import catalogSaveLight from '@/assets/SVG/catalog-save-light.svg'
import hourglassHalf from '@/assets/SVG/hourglass-half.svg'
import refresh from '@/assets/SVG/refresh.svg'
import flag from '@/assets/SVG/flag.svg'
import material from '@/assets/SVG/material.svg'
import bolsterIconBlack from '@/assets/SVG/Bolster_Icon_Black.svg'
import bolsterIconGrey from '@/assets/SVG/Bolster_Icon_Grey.svg'

export default {
  name: 'SvgIcon',

  data() {
    return {
      svgs: {
        autocost,
        autocostWhite,
        autocostDark,
        catalogSave,
        catalogAdd,
        catalogSaved,
        catalogAddLight,
        catalogSaveLight,
        hourglassHalf,
        refresh,
        flag,
        material,
        bolsterIconBlack,
        bolsterIconGrey
      }
    }
  },

  computed: {
    src() {
      return this.svgs[this.svg]
    }
  },

  props: {
    svg: {
      type: String,
      required: true
    }
  }
}
</script>
