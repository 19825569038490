<template>
  <div class="document-container">
    <iframe class="min-h-[85vh] w-full h-full" :src="viewerSrc"> </iframe>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue'

const props = defineProps({
  src: {
    required: true
  }
})

const load = ref(new Date().valueOf())

const viewerSrc = computed(() => {
  return `https://view.officeapps.live.com/op/embed.aspx?load=${load.value}&src=${encodeURIComponent(absoluteViewUrl.value)}&wdAllowInteractivity=True&wdHideGridlines=False&wdHideHeaders=True&wdDownloadButton=False&wdInConfigurator=True&wdScale=0.7&wdHideSheetTabs=True`
})

const absoluteViewUrl = computed(() => {
  if (/^http/.test(props.src)) return props.src
  return c.link(props.src, { load: load.value })
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
document-container > iframe {
  width: 100%;
  min-width: 800px;
  min-height: 600px;
  border: 1px solid $cool-gray-500;
  border-radius: 5px;
}

@media (max-width: 768px) {
  document-container > iframe {
    min-width: 400px;
  }
}
</style>
