export default {
  root: ({ props, parent }) => ({
    class: [
      // Font
      {
        'text-xl': props.size == 'large',
        'text-2xl': props.size == 'xlarge'
      },

      // Alignments
      'inline-flex items-center justify-center',
      'relative',

      // Sizes
      {
        'h-8 w-8 max-h-8 max-h-8 min-w-8 max-w-8': props.size == null || props.size == 'normal',
        'w-12 h-12 max-w-12 max-h-12 min-w-12 min-h-12': props.size == 'large',
        'w-16 h-16 max-w-16 min-w-16 max-h-16 min-h-16': props.size == 'xlarge',
        'w-7 h-7 max-w-7 min-w-7 max-h-7 min-h-7': props.size == '7',
        'w-6 h-6 max-w-6 min-w-6 max-h-6 min-h-6': props.size == 'small',
        'w-4 h-4 max-w-4 min-w-4 max-h-4 min-w-4': props.size == 'xsmall'
      },
      { '-ml-4': parent.instance.$style?.name == 'avatargroup' },

      // Shapes
      {
        'rounded-lg': props.shape == 'square',
        'rounded-full': props.shape == 'circle'
      },
      { 'border-2': parent.instance.$style?.name == 'avatargroup' },

      // Colors
      'bg-surface-300 dark:bg-surface-700',
      { 'dark:border-surface-800': parent.instance.$style?.name == 'avatargroup' }
    ]
  }),
  image: ({ props }) => ({
    class: [
      'h-full w-full',
      // Shapes
      {
        'rounded-lg': props.shape == 'square',
        'rounded-full': props.shape == 'circle'
      }
    ]
  })
}
