import { openDB, deleteDB } from 'idb'

// ==== notification store ==== //
const deleteDb = async () => {
  const db = await getDb()

  // clear object stores //
  await db.clear('notification')
  await db.clear('member')

  await db.close()
  return deleteDB('bolsterbuilt')
}
const getDb = async () => {
  return openDB('bolsterbuilt', 1, {
    upgrade(database) {
      // ==== notification store ==== //
      const notificationStore = database.createObjectStore('notification', {
        keyPath: 'notification_id'
      })
      notificationStore.createIndex('activity_created_at', 'activity_created_at')
      // ========================== //

      // ==== channel store ==== //
      const memberStore = database.createObjectStore('member', {
        keyPath: 'member_id'
      })
      memberStore.createIndex('created_at', 'created_at')
      // ========================== //
    }
  })
}
const create = async (store, item) => {
  const database = await getDb()
  return database.add(store, item)
}
const put = async (store, item) => {
  const database = await getDb()
  return database.put(store, item)
}
const get = async (store, key) => {
  const database = await getDb()
  return database.get(store, key)
}
const getAll = async (store) => {
  const database = await getDb()
  return database.getAll(store)
}
const getFromIndex = async (store, index, key) => {
  const database = await getDb()
  return database.getFromIndex(store, index, key)
}
const getAllFromIndex = async (store, index) => {
  const database = await getDb()
  return database.getAllFromIndex(store, index)
}
const remove = async (store, key) => {
  const database = await getDb()
  return database.delete(store, key)
}
const getTransaction = async (store) => {
  const database = await getDb()
  return database.transaction(store, 'readwrite')
}

export default {
  create,
  put,
  get,
  getFromIndex,
  remove,
  getAll,
  getAllFromIndex,
  getTransaction,
  deleteDb,
  getDb
}
