<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <avatar :contact="object"> </avatar>
    </template>
    <template #primary>
      <div class="flex items-center justify-start">
        <SuperIcon v-if="object.user_is_super_user" />
        {{ object.user_name }}

        <template v-if="$store.state.session.user.user_is_super_user">
          (ID: {{ object.user_id }})
        </template>

        <Tag class="sm ml-2" severity="primary" rounded v-if="superUser && clients.length">
          Client {{ clients.length > 1 ? `&times;${clients.length}` : '' }}
        </Tag>

        <Tag class="sm ml-2" severity="info" rounded v-if="superUser && companyUsers.length">
          Companies {{ companyUsers.length > 1 ? `&times;${companyUsers.length}` : '' }}
        </Tag>

        <Tag
          class="sm ml-2"
          severity="warning"
          rounded
          v-if="(superUser && adminCompanies.length) || adminThisCompany"
        >
          Admin
          {{ superUser && adminCompanies.length > 1 ? `&times;${adminCompanies.length}` : '' }}
        </Tag>
      </div>
    </template>
    <template #secondary>
      <div v-if="superUser">
        <span class="text-info"> {{ $f.phone(object.user_phone) }} · {{ object.user_email }} </span>
      </div>
    </template>
    <template #right>
      <div class="text-muted"></div>
    </template>
  </preview>
</template>

<script>
import Tag from 'primevue/tag'
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'
import SuperIcon from '../Icon/SuperIcon.vue'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewUser',
  emits: ['click'],
  data() {
    return {}
  },
  watch: {},
  computed: {
    superUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    adminThisCompany() {
      return (this.object.aoCompanies || []).filter(
        (co) =>
          String(co.company_id) === String(this.$store.state.session.company.company_id) &&
          co.user_is_admin
      ).length
    },
    adminCompanies() {
      return (this.object.aoCompanies || []).filter((co) => co.user_is_admin)
    },
    clients() {
      return this.object.aoUserCompanyClients || []
    },
    companyUsers() {
      return this.object.aoCompanies || []
    }
  },
  methods: {},
  components: {
    SuperIcon,
    Preview,
    Tag
  },
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  mounted() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
