<template>
  <preview
    v-bind="$props"
    v-if="object"
    class="quote-preview--container"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <span
        ><font-awesome-icon icon="compress" /> {{ object.webhook_name }}
        <small class="text-muted">
          {{ object.webhook_event }}
          ({{ eventsLength }} triggers)
        </small>
      </span>
    </template>
    <template #secondary>
      <span class="text-muted" style="padding-left: 1.5em">
        <font-awesome-icon icon="exclamation-circle" class="danger" v-if="lastError" />
        <font-awesome-icon icon="check" class="success" v-else />
        {{ object.webhook_endpoint }}
      </span>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewWebhook',
  emits: ['click'],
  data() {
    return {}
  },
  computed: {
    eventsLength() {
      return c.makeArray(this.object.webhook_event).length
    },
    logLength() {
      return c.makeArray(this.object.aoLog).length
    },
    lastError() {
      const log = c.makeArray(this.object.aoLog)
      if (log.length && log[0].error) {
        return true
      }
      return false
    }
  },
  props: {
    right: {},
    middle: {},
    object: {
      type: Object,
      required: true
    }
  },
  components: { Preview }
}
</script>

<style rel="stylesheet/scss" lang="scss"></style>
