<template>
  <div class="item-summary--container">
    <search-field style="margin-left: auto" class="right mb-1" v-model="searchPhraseLocal" />

    <item-summary-assembly
      :context="context"
      :refId="rootRefId"
      @swap-item="(itemRefId, component) => swapItem(itemRefId, component)"
      @edit-item="(itemRefId, component) => editItem(itemRefId, component)"
      @add-item="(parentRefId, component) => selectItems(false, parentRefId)"
    />

    <mini-modal size="sm" :width="500" v-if="showItemEditor" ref="itemEditor">
      <component
        :is="editingComponentType"
        :store="store"
        :object="norm[editingComponentRefId]"
        :rootRefId="rootRefId"
        :reference="editingComponentRefId"
        :key="editingComponentRefId"
      />

      <btn class="block xl square btn" @click="() => $refs.itemEditor.close()"> Close </btn>
    </mini-modal>
  </div>
</template>

<script>
import ButtonMixin from '../../mixins/Button'
import CostItem from '../../bodies/CostItem.vue'
import Assembly from '../../quote/AssemblyOptions.vue'
import ItemListMixin from '../ItemListMixin'

export default {
  name: 'ItemSummary',

  components: {
    CostItem,
    Assembly
  },

  mixins: [ButtonMixin, ItemListMixin],

  data() {
    return {
      traverseOpenedFor: null,
      traverseOpenedForComponent: this,
      loadingLocal: 0,
      searchPhraseLocal: this.searchPhrase,
      showItemEditor: 0,
      editingComponentType: c.titleCase('cost_item'),
      editingComponentRefId: null
    }
  },

  props: {
    refId: {
      type: String,
      required: true
    },

    store: {
      type: String,
      default: 'Quote'
    },

    startOpen: {
      default: false
    },

    searchPhrase: {
      type: String,
      default: ''
    },

    showForCost: {
      default: () => ['price']
    },

    inAssembly: {
      type: [Boolean, Number],
      default: 0
    },
    dragging: {
      default: false
    },
    parent: {
      default: null
    }
  },

  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },

    context() {
      return {
        norm: this.norm,
        store: this.store,
        rootRefId: this.rootRefId,
        startOpen: this.startOpen,
        showForCost: this.showForCost,
        inAssembly: this.inAssembly,
        editable: this.editable,
        searchPhrase: this.searchPhraseLocal
      }
    },

    itemSelection: {
      get() {
        return this.itemSelection
      },
      set(val) {
        this.$refs.traverse.close()
        this.addItem(val, 0, this.traverseOpenedFor)
      }
    }
  },

  methods: {
    async editItem(itemRefId) {
      this.editingComponentType = c.titleCase(this.norm[itemRefId].type)
      this.editingComponentRefId = itemRefId
      this.showItemEditor = 1
      await this.$nextTick()

      this.$refs.itemEditor.open()
    },

    async swapItem(itemRefId, component = this) {
      component.addLoading()

      const val = await this.openTraverse(itemRefId, component)

      if (typeof val !== 'string' || !val) {
        component.endLoading()
        return this
      }

      const object = _.imm(component.object)

      const [objectType, id] = val.split(':')
      // Because traverse searches cost_types and assemblies, we need to
      // embue the child below with 'cost_item' if it comes out as a 'cost_type'
      // which is the type of child added to a quote that is derived from a cost_type.
      const newChildType = objectType === 'assembly' ? objectType : 'cost_item'

      await this.$store.dispatch(`${this.store}/replaceChild`, {
        parent: object.parentRefId,
        child: itemRefId,
        type: objectType, // new child type
        id,
        embue: {
          ...(newChildType === 'assembly'
            ? {
                quote_qty_net_base: object.unit_of_measure_id === 2 ? +object.cost_item_qty_net : 1,
                quote_qty_net:
                  +object.unit_of_measure_id === 2
                    ? +object.cost_item_qty_net * (+object.quantity_multiplier || 1)
                    : +object.quantity_multiplier
              }
            : {
                cost_item_qty_net_base: object.cost_item_qty_net_base,
                cost_item_qty_net: object.cost_item_qty_net,
                cost_item_link_qty: object.cost_item_link_qty || 1
              }),
          type: newChildType
        }
      })

      component.endLoading()

      return this
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$labelHeight: 2em;
$indent: 1em;
$itemColor: $cool-gray-400;
$itemForeground: $cool-gray-700;
$roomColor: $purple-600;
$assemblyColor: $blue-print-700;

.item-summary--container {
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // border-bottom: 1px solid $cool-gray-400;
  // border-right: 1px solid $cool-gray-400;
  // border-radius: 3px;

  .item-summary--assembly-path {
    position: absolute;
    // top: ($labelHeight/2);
    left: 0;
    height: 100%;
    pointer-events: none;

    .assembly-line {
      stroke: $assemblyColor;
      fill: $assemblyColor;
      stroke-width: 2px;
      stroke-linecap: round;
    }

    &.item-summary--room-path {
      .assembly-line {
        stroke: $roomColor;
        fill: $roomColor;
      }
    }
  }

  .item-summary--child-path {
    position: absolute;
    left: 0px;

    .child-curve {
      stroke: $assemblyColor;
      fill: transparent;
      stroke-width: 2px;
      stroke-linecap: round;
      // height: $labelHeight / 2;
      width: $indent;
    }

    &.item-summary--room-child-path {
      .child-curve {
        stroke: $roomColor;
      }
    }
  }

  .item-summary--assembly-items {
    margin-left: $indent;
  }

  .item-summary--assembly-item {
  }

  .item-summary--cost-item-container {
    &.searching:not(.searchMatch) {
      opacity: 0.2;
    }
  }

  .item-summary--assembly-container {
    border: 0;
    position: relative;
    // border-top: 1px solid $cool-gray-400;
    // border-left: 1px solid $cool-gray-400;
    // border-top-left-radius: 0.5em;
    // background: $flame-white;

    &.searching:not(.searchMatch) {
      opacity: 0.2;
    }
  }

  .item-summary--label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: $labelHeight;
    flex: 0 0 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &.item-summary--label-cost-item {
      border-radius: 1em;

      &:hover {
        background: rgba($blue-print-700, 0.1);
      }
    }
  }

  .item-summary--title {
    font-weight: bold;
    flex: 0 1 auto;
    max-width: 60%;
    color: $flame-white;
    border-radius: 0.25em;
    padding: 0.1em 0.5em;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $flame-white;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      font-size: 0.6em;
      margin-right: 0.5em;
    }

    .chevron {
      top: 1px;
      margin-right: 0.25em;

      &.closed {
        transform: rotate(-90deg);
      }
    }

    &.item-summary--title-assembly {
      background: rgba($assemblyColor, 1);
    }

    &.item-summary--title-room {
      background: rgba($roomColor, 1);
    }

    &.item-summary--title-cost-item {
      padding: 0.1em 1em;
      background: rgba($itemColor, 1);
      color: $itemForeground;
      border-radius: 1em;

      transition: transform 0.05s ease;
      &:hover {
        // transform: translateX(0.1em);
      }
    }
  }

  .item-summary--right {
    margin-left: auto;
    flex: 1 0 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .item-summary--qty {
    text-align: right;
    font-weight: bold;
    flex: 0 0 10em;
    color: $cool-gray-700;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    line-height: 1;
    font-size: 0.8em;

    .item-summary--unit {
      font-weight: bold;
      font-size: 0.8em;
      color: $cool-gray-600;
      text-transform: lowercase;
    }

    .linkable {
      font-size: 0.8em;
      border-radius: 50%;
      padding: 0.1em;
    }

    &.linked {
      color: $roomColor;

      .linkable {
        border: 1px solid $roomColor;
      }
      .item-summary--unit {
        color: rgba($roomColor, 0.8);
      }
    }
  }

  .item-summary--sum {
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 0 0 8em;
    flex-direction: column;
    font-size: 0.8em;
    font-weight: bold;

    margin: 0 0.5em;

    &.item-summary--sum-assembly {
      opacity: 0.35;
    }
  }

  .item-summary--cost {
    color: $deep-red-800;
    text-align: right;
    font-size: 0.65em;
    line-height: 1.1;
    display: none;
  }

  .item-summary--price {
    text-align: right;
    line-height: 1.1;
  }

  .item-summary--assembly-container > .item-summary--label-assembly {
    > .item-summary--cost {
      display: none;
    }

    > .item-summary--price {
      display: none;
    }
  }

  .item-summary--badge {
    display: block !important;
    max-width: 1em !important;
    max-height: 1em !important;
    min-width: 1em !important;
    min-height: 1em !important;
    width: 1em !important;
    height: 1em !important;
    padding: 0 !important;
    margin-right: 0.25em;
  }
}

#app.small-format {
  .item-summary--container {
    font-size: 0.7em;
  }
}
</style>
