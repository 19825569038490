<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="sm"
    :mask="true"
    :isDirty="bodyIsDirty"
    :clickAway="true"
    :closeable="true"
    :expandable="false"
    :scrollable="true"
  >
    <template #body>
      <BudgetCode :key="modal.uid" :object="object" ref="body" :go="false" />
      <CardSection class="mb-0">
        <btn-group class="block">
          <Btn class="default" :action="() => close()"> Cancel </Btn>
          <Btn class="info" :action="() => saveAndClose()"> Save </Btn>
        </btn-group>
      </CardSection>
    </template>
  </Modal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
import BudgetCode from '../bodies/BudgetCode.vue'

export default {
  name: 'BudgetCodeModal',
  components: { BudgetCode },
  mixins: [ObjectModalMixin],
  props: {
    go: {
      default: false
    }
  }
}
</script>

<style scoped></style>
