<template>
  <div v-if="canShowClientRatings">
    <grid
      ref="grid"
      type="clientRating"
      :camelCase="true"
      title="Ratings"
      :isMain="false"
      :filters="{ clientId: clientId }"
      :createNew="false"
      :showToolbar="false"
      :order="[['timeCreated', 'desc']]"
      :visible="['creatorId', 'overallRating', 'description']"
    >
      <template #filterPresetBefore>
        <CreateRatingSelection type="clientRating" :ratingData="clientRatingData" />
        <RatingSummary :creatorId="clientId" :ratingData="clientRatingData" />
      </template>
    </grid>
  </div>
  <div v-else-if="!hasPermissionsToSeeClientRatings">
    <Section>
      <Container>
        <CardSection>
          <Warning>
            <template #title>You do not have permissions to view ratings.</template>
            <p>Request "client viewing" permissions from your company's administrator.</p>
          </Warning>
        </CardSection>
      </Container>
    </Section>
  </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import Grid from '@/components/ui/Grid/Grid.vue'
import RatingSummary from '@/components/ratings/RatingSummary.vue'
import CreateRatingSelection from '@/components/ratings/CreateRatingSelection.vue'
import useRating from '@/components/composables/Rating'
import eventBus from '@/eventBus'

const props = defineProps({
  clientId: {
    default: null
  }
})

const { initializeForClient } = useRating()

const store = useStore()
const clientRatingData = ref(null)

onBeforeMount(() => {
  initializeForClient({ clientId: props.clientId })
  const eventKey = `${props.clientId}-initialized-for-client`
  eventBus.$on(eventKey, () => {
    clientRatingData.value = store.getters['rating/getRatingDataByClientId'](props.clientId)
  })

  eventBus.$on('completed-fetching-data', () => {
    clientRatingData.value = store.getters['rating/getRatingDataByClientId'](props.clientId)
  })
})

onBeforeUnmount(() => {
  eventBus.$off(`${props.clientId}-initialized-for-client`)
})

const hasPermissionsToSeeClientRatings = computed(() => {
  if (!store.state.session || !store.state.session.user || !store.state.session.user.aUserPerms)
    return false
  const perms = store.state.session.user.aUserPerms
  return perms.client.read > 0
})

const canShowClientRatings = computed(() => {
  return store.state.session.scope.company && hasPermissionsToSeeClientRatings.value
})
</script>
