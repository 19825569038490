<template>
  <div class="item-wizard--container" v-if="selected">
    <wizard @step="(s) => $emit('step', s)" @percent-complete="(s) => $emit('percentComplete', s)">
      <wizard-step></wizard-step>
    </wizard>
  </div>
</template>

<script>
export default {
  name: 'NewQuoteWizard',
  emits: ['step', 'percentComplete'],

  data() {
    return {}
  },

  watch: {},

  methods: {},

  computed: {},

  components: {},

  mixins: []
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
