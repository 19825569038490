<template>
  <div class="activity-header">
    <div class="header-description">
      <strong v-text="activity.activity_notification_heading"></strong>
      <template
        v-if="
          activity.activity_desc &&
          activity.activity_desc !== activity.activity_type &&
          activity.activity_base_type === 'ACTION'
        "
      >
        • <span v-text="activity.activity_desc"></span>
      </template>
      <p class="small text-muted">
        {{ $f.datetime(activity.activity_time_created) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityHeader',
  data() {
    return {}
  },
  computed: {
    self() {
      return (
        String(this.activity.activity_creator) === String(this.$store.state.session.user.user_id) ||
        String(this.activity.activity_creator) === `user-${this.$store.state.session.user.user_id}`
      )
    }
  },
  props: {
    activity: {
      required: true
    }
  },
  components: {}
}
</script>

<style rel="stylesheet/scss" lang="scss">
.activity-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  a {
    font-weight: bold;
  }
  p.small {
    margin: 0px;
    margin-top: 0.3em;
    font-size: 0.6em;
    font-weight: bold;
  }

  > div {
    margin-right: 1em;
  }

  .header-creator {
    padding-left: 0.5em;
  }
  .header-description {
    line-height: 0.9;
  }
}
</style>
