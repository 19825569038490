<template>
  <Container v-if="selected && $store.getters.quoteCountWarnings">
    <Danger>
      <template #title
        >Verify {{ $store.getters.quoteCountWarnings }} warnings before sending!</template
      >
      <h6 class="mb-8" style="font-weight: normal !important">
        These warnings serve as a double-check before sending. Review them carefully then continue
        sending if the values are as you expect them to be. If not, make the required changes and
        try again.
      </h6>

      <div class="flex flex-col w-full justify-start items-stretch gap-6">
        <div
          v-if="zeroItemsAll.length"
          class="flex flex-col w-full justify-start items-stretch gap-2"
        >
          <div class="text-xl">{{ zeroItemsAll.length }} items have a very low price!</div>
          <h6 style="font-weight: normal !important">
            These items will be shown as 'included' to your customer, despite having a price near or
            at 0 (free). This is usually a mistake unless you are including no-price items in your
            proposal.
          </h6>

          <ul class="list-disc ml-5">
            <li v-for="(ref, index) in zeroItems" :key="index">
              {{ norm[ref].cost_type_name }} = {{ $f.currency(norm[ref].cost_item_price_net) }} ({{
                $f.currency(norm[ref].cost_item_total_cost_net)
              }}
              cost)
            </li>
          </ul>
          <Btn
            class="round danger xs"
            @click="showMax = 0"
            v-if="zeroItemsAll.length !== zeroItems.length"
            >More...</Btn
          >
        </div>

        <div
          v-if="Object.keys(zeroDimensions).length"
          class="flex flex-col w-full justify-start items-stretch gap-2"
        >
          <div class="text-xl">
            {{ Object.keys(zeroDimensions).length }} assemblies have required dimensions with a very
            low value!
          </div>

          <h6 style="font-weight: normal !important">
            These are dimensions that certain items rely on. Sometimes this may not be a mistake,
            and those items will show as 'not-included' if the quantity derived from a dimension is
            0.
          </h6>

          <ul class="list-disc ml-5">
            <li v-for="(list, ref) in zeroDimensions" :key="ref">
              {{ norm[ref].assembly_name || norm[ref].quote_name }}
              <ul class="m-0">
                <li v-for="(abbr, index) in list" :key="index">
                  {{ norm[ref].oDimensions[abbr].dimension_name }} =
                  {{ $f.number(norm[ref].oDimensions[abbr].value) }}
                  {{ norm[ref].oDimensions[abbr].measure }}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div
          v-if="belowMinimumMarkupAll.length"
          class="flex flex-col w-full justify-start items-stretch gap-2"
        >
          <div class="text-xl">
            {{ belowMinimumMarkupAll.length }} items are below the minimum profit!
          </div>

          <h6 style="font-weight: normal !important">
            These items fall under your company's minimum profit amount of
            {{ $f.percentage($store.state.session.company.company_minimum_quote_margin) }}.
          </h6>

          <ul class="list-disc ml-5">
            <li v-for="ref in belowMinimumMarkup" :key="ref">
              {{ norm[ref].cost_type_name }} = &times;{{
                $f.number(norm[ref].cost_item_markup_net_adjusted)
              }}
              ({{ $f.percentage(c.markupToMargin(norm[ref].cost_item_markup_net_adjusted)) }})
            </li>
          </ul>
          <Btn
            class="round danger xs"
            @click="showMax = 0"
            v-if="belowMinimumMarkupAll.length !== belowMinimumMarkup.length"
            >More...</Btn
          >
        </div>

        <div
          v-if="zeroQuantityItemsAll.length"
          class="flex flex-col w-full justify-start items-stretch gap-2"
        >
          <div class="text-xl">
            {{ zeroQuantityItemsAll.length }} items have a very low quantity!
          </div>
          <h6 style="font-weight: normal !important">
            These items have a very low quantity. Sometimes this may not be a mistake, and these
            items will be shown as 'not-included' to your customer if quantity is 0.
          </h6>

          <ul class="list-disc ml-5">
            <li v-for="ref in zeroQuantityItems" :key="ref">
              {{ norm[ref].cost_type_name }} = {{ $f.number(norm[ref].cost_item_qty_net) }}
              {{ norm[ref].unit_of_measure_abbr }}
            </li>
          </ul>

          <Btn
            class="round danger xs"
            @click="showMax = 0"
            v-if="zeroQuantityItemsAll.length !== zeroQuantityItems.length"
            >More...</Btn
          >
        </div>
      </div>

      <div class="mt-8">
        <div class="text-xl">If these values are correct, continue sending.</div>
      </div>
    </Danger>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import Milestones from '../mixins/Milestones'

export default {
  name: 'VerifyQuoteItems',
  mixins: [
    ObjectManipulator(
      'quote' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      false /* track changes? */
    ),
    BodyMixin /* AutoSave */,
    Milestones
  ],
  data() {
    return {
      showMax: 5
    }
  },
  computed: {
    belowDefaultMarkupAll() {
      return this.$store.getters.qwBelowDefaultMarkup
    },
    belowMinimumMarkupAll() {
      return this.$store.getters.qwBelowMinimumMarkup
    },
    zeroItemsAll() {
      return this.$store.getters.qwZeroItems
    },
    zeroQuantityItemsAll() {
      return this.$store.getters.qwZeroQuantityItems
    },
    belowDefaultMarkup() {
      return this.max(this.belowDefaultMarkupAll)
    },
    belowMinimumMarkup() {
      return this.max(this.belowMinimumMarkupAll)
    },
    zeroItems() {
      return this.max(this.zeroItemsAll)
    },
    zeroQuantityItems() {
      return this.max(this.zeroQuantityItemsAll)
    },
    zeroDimensions() {
      return this.$store.getters.qwZeroDimensions
    }
  },
  methods: {
    max(list) {
      if (this.showMax) return list.slice(0, this.showMax)

      return list
    }
  },
  props: {
    deselectOnDestroy: {
      default: false
    },
    forceFull: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
