<template>
  <Modal
    ref="modal"
    :full="true"
    @closed="markClosed"
    :zIndex="$store.state.modal.topZIndex + 10"
    :destroyInterval="0"
    :isFocus="true"
    :includeGutter="true"
  >
    <template #body>
      <Services />
    </template>
  </Modal>
</template>

<script>
import Services from '../pages/Services.vue'
import ModalMixin from './ModalMixin'

export default {
  name: 'Search',
  mixins: [ModalMixin],
  methods: {
    markClosed() {
      this.$store.dispatch('search/close')
    }
  },
  components: {
    Services
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
