<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    size="lg"
    :width="800"
    :isDirty="bodyIsDirty"
    :clickAway="true"
    :expandable="false"
  >
    <template #header> Transactions </template>
    <template #body>
      <transactions-body
        ref="body"
        :filters="modal.filters"
        :quote="modal.quote || null"
        :forceFull="true"
        :forceFetch="true"
        :id="id"
      />
    </template>
    <template #footer>
      <btn-group>
        <btn class="btn default" @click="cancelClick"> Done </btn>
      </btn-group>
    </template>
  </modal>
</template>

<script>
import TransactionsBody from '../bodies/Transactions.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalTransactions',
  mixins: [ObjectModalMixin],
  components: { TransactionsBody },
  props: {
    type: {
      default: 'transaction'
    }
  }
}
</script>
