<template>
  <div
    v-tooltip="toolTipMsg"
    :class="[
      'checkbox--container input-container',
      disabled ? 'checkbox-disabled' : '',
      checkValue ? 'checked' : '',
      size
    ]"
    @click.stop.capture.prevent="clicky"
  >
    <input
      ref="input"
      type="checkbox"
      :disabled="disabled"
      :checked="checkValue ? true : null"
      :indeterminate="indeterminate"
    />
    <div class="checkbox--checkmark"></div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['input', 'change'],
  data() {
    return {
      fieldValue: this.boolean ? !!this.value : this.value
    }
  },
  watch: {
    value(newVal) {
      this.fieldValue = newVal
    },
    indeterminate(i) {
      this.$refs.input.indeterminate = !!i
    }
  },
  methods: {
    focus() {
      this.clicky()
    },
    clicky() {
      if (this.disabled) return

      if (this.selectedValue === null && this.boolean) {
        this.checkValue = !!this.checkValue
      } else if (this.selectedValue === null) {
        this.checkValue = this.checkValue ? 0 : 1
      } else {
        this.checkValue = this.checkValue ? this.deselectedValue : this.selectedValue
      }
    },
    setValue(val) {
      this.checkValue = val
    },
    isValueSelected(value) {
      if (this.selectedValue === null) return !!value

      if (String(this.selectedValue) === String(value)) return true

      return false
    }
  },
  computed: {
    indeterminate() {
      return !!(this.value === '-' || this.value === null)
    },
    toolTipMsg() {
      return this.disabled ? this.disabledMsg : this.toolTip ? this.toolTip : ''
    },
    checkValue: {
      get() {
        return !!(!this.indeterminate && this.isValueSelected(this.fieldValue))
      },
      set(v) {
        if (this.boolean) {
          this.fieldValue = !!v
        } else {
          this.fieldValue = v
        }

        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$emit('input', this.fieldValue)
            this.$emit('change', this.fieldValue)
          })
        })
      }
    }
  },
  props: {
    boolean: {
      default: false
    },
    value: {
      required: true,
      default: 0
    },
    selectedValue: {
      default: null // null == anything truthy
    },
    deselectedValue: {
      default: null // null == anything truthy
    },
    disabled: {
      default: false
    },
    disabledMsg: {
      type: String,
      default: 'Checkbox is disabled'
    },
    size: {
      type: String
    },
    toolTip: {
      type: String
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$height: 1.4em;
$width: 1.4em;
/* Customize the label (the container) */
.checkbox--container {
  display: block;
  position: relative;
  cursor: pointer;
  height: $height;
  width: $width;
  max-height: $height;
  max-width: $width;
  flex: 0 0 auto;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  transition: none;
  box-sizing: content-box;
  transition:
    border-color 0.2s ease-out,
    box-shadow 0.2s ease-out;
  border-radius: 0.15rem;
  border: 1px solid $cool-gray-600;

  &.checked {
    border: 1px solid $pitch-black;
  }
  &.rounded {
    border-radius: 100em;
  }

  &.list-item {
    min-width: 26px;
    min-height: 26px;
    border: solid 2px $pitch-black;
    background: $flame-white;
    border-radius: 4px;

    &:hover {
      box-shadow: 0 0 0 2px $primary;
    }

    input {
      outline: none;
      &:checked {
        & ~ .checkbox--checkmark {
          height: 100%;
          width: 100%;
          background-color: $pitch-black;

          &:after {
            display: block;
          }
        }
      }
      &:indeterminate {
        & ~ .checkbox--checkmark {
          background-color: $pitch-black;
          &:after {
            display: none;
          }
        }
      }
    }

    .checkbox--checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $height;
      width: $width;
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        display: none;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: -0.2em;
        width: 0.45em;
        height: 0.9em;
        border: solid $primary;
        border-width: 0 0.18em 0.18em 0;
        transform: rotate(45deg) !important;
      }
    }
  }

  &.checkbox-disabled {
    border: solid 1px $cool-gray-500;
  }

  &:hover {
    border-color: $cool-gray-600;
    box-shadow: 0 0 0 2px $cool-gray-400;
  }

  input {
    position: absolute;
    opacity: 0 !important;
    cursor: pointer;
    transition: none;

    &:checked {
      border: 1px solid $pitch-black !important;
      & ~ .checkbox--checkmark {
        background-color: $pitch-black;
        &:after {
          display: block;
        }
      }
    }
    &:indeterminate {
      & ~ .checkbox--checkmark {
        background-color: $pitch-black;
        &:after {
          display: none;
        }
      }
    }
  }

  .checkbox--checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $height;
    width: $width;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      display: none;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: -0.2em;
      width: 0.45em;
      height: 0.9em;
      border: solid $flame-white;
      border-width: 0 0.18em 0.18em 0;
      transform: rotate(45deg) !important;
    }
  }

  &.active {
    input {
      & ~ .checkbox--checkmark {
        background-color: $blue-print-500;
        &:after {
          display: block;
        }
      }
    }
  }

  &.checkbox-disabled {
    input {
      & ~ .checkbox--checkmark {
        background-color: $cool-gray-400;
        &:after {
          margin-top: 0.15em;
        }
      }
      &:checked {
        & ~ .checkbox--checkmark {
          display: block;
        }
      }
    }
  }

  &.inline,
  &.info {
    border: 2px solid $blue-print-500;
    border-radius: 3px;
    overflow: hidden;
    display: inline-block;

    input {
      &:checked {
        & ~ .checkbox--checkmark {
          background-color: $blue-print-500;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  &.radio {
    border: 1px solid $cool-gray-400;
    border-radius: 50%;
    max-width: $width !important;
    max-height: $height !important;
    min-width: $width !important;
    min-height: $height !important;
    width: $width !important;
    height: $height !important;
    flex: 0 auto !important;
    align-self: unset !important;
    overflow: hidden;
    display: inline-block;
    padding: 0 !important;
    > .checkbox--checkmark {
      &:after {
        background-color: #fff;
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        transform: scale(0);
        transition: transform 0.3s ease-out;
        width: 6px;
        margin: auto;
        transform-origin: 3px 3px;
      }
    }

    &.active {
      input {
        & ~ .checkbox--checkmark {
          box-shadow: 0 0 0 2px rgba($blue-print-500, 0.4);
          background-color: $blue-print-500;
          &:after {
            transform: scale(1) !important;
          }
        }
      }
    }
  }

  &.block {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    display: block;

    .checkbox--checkmark {
      background: transparent;
    }
  }

  &.small {
    font-size: 0.75em;
  }

  &.sm {
    font-size: 0.7em;
  }

  &.lg {
    font-size: 1.1em;
  }

  &.xl {
    font-size: 1.2em;
  }
  &.xxl {
    font-size: 1.5em;
  }
}
</style>
