import crypto from 'crypto'

export function md5(message) {
  return crypto.createHash('md5').update(message).digest('hex')
}

export function sha1(message) {
  return crypto.createHash('sha1').update(message).digest('hex')
}

export function sha256(message) {
  return crypto.createHash('sha256').update(message).digest('hex')
}
