<template>
  <preview
    class="company-preview-container preview"
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      {{ object.company_name }}

      <div v-if="object.stripe_customer_id" class="flex justify-start items-center mx-2 gap-1">
        <Tag v-if="object.stripe_customer_id" v-tooltip.top="'This is a registered customer'">
          <font-awesome-icon icon="address-card" class="circled mx-1" />
        </Tag>
        <Tag
          v-if="!object.stripe_payment_method"
          v-tooltip.top="'This customer does not have a payment method registered'"
          severity="warning"
        >
          <font-awesome-layers>
            <font-awesome-icon icon="credit-card" fixed-width />
            <font-awesome-icon :icon="['fas', 'slash-back']" fixed-width />
          </font-awesome-layers>
        </Tag>

        <Tag
          v-if="object.stripe_payment_method"
          v-tooltip.top="'This customer has a payment method registered!'"
          severity="success"
        >
          <font-awesome-icon icon="credit-card" />
        </Tag>

        <Tag
          v-if="object.company_is_in_trial"
          severity="danger"
          v-tooltip.top="'This customer has not passed their 14 day trial yet.'"
        >
          <font-awesome-icon icon="hourglass" />
        </Tag>
        <Tag v-else-if="object.stripe_access_payment_ref" v-tooltip.top="'This customer has paid.'">
          <font-awesome-icon :icon="['fas', 'user-check']" />
        </Tag>

        <Tag
          v-if="!object.company_is_in_trial && !object.stripe_access_payment_ref"
          severity="danger"
          v-tooltip.top="'Charge this customer now, and save payment reference'"
        >
          <font-awesome-icon icon="money-bills" />
        </Tag>
      </div>

      <template v-if="$store.state.session.user.user_is_super_user">
        (ID: {{ object.company_id }})
      </template>

      <!-- <btn-group style="margin-left: auto">
        <btn rounded v-if="object.company_phone" class="more px-0 p-2 info ml-2 sm" @click="() => $store.dispatch('phone/call', { number: object.company_phone })">
          <font-awesome-icon :icon="['fas', 'phone']" />
        </btn>
        <btn rounded v-if="object.company_email" class="more px-0 p-2 info ml-2 sm" @click="() => $store.dispatch('openLink', { url: `mailto:${object.company_email}` })">
          <font-awesome-icon :icon="['fas', 'comment']" />
        </btn>
      </btn-group> -->
    </template>

    <template #secondary>
      {{ $f.datetime(object.company_time_created) }}
    </template>

    <template #right>
      <next-steps
        :object="object"
        :grid="grid"
        :inline="false"
        v-if="steps"
        style="margin-right: -0.5em"
      />
    </template>
  </preview>
</template>

<script>
import Tag from 'primevue/tag'
import Preview from './Base.vue'
import NextSteps from './NextSteps.vue'
import PreviewMixin from './PreviewMixin'
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

export default {
  name: 'PreviewCompany',
  mixins: [PreviewMixin],
  emits: ['click'],
  data() {
    return {}
  },
  computed: {
    classStatus() {
      return c.statusColors[this.object.client_status]
    },
    status() {
      return c.format(this.object.company_status, 'status')
    }
  },
  components: {
    Tag,
    FontAwesomeLayers,
    Preview,
    NextSteps
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.client-preview-container {
  .text-muted {
    font-size: 0.9em;
  }
  .preview {
    align-content: left;
  }
  .activity-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    color: gray;
  }
  .object-activity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .glyphicon,
    .fa {
      font-size: 1.5em;
      margin-right: 0.25em;
      color: $cool-gray-600;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 1;
      > small {
        color: $cool-gray-600;
        font-size: 0.75em;
        line-height: 1;
      }
      > span {
        font-weight: bold;
      }
    }
    + .object-activity {
      margin-left: 0.5em;
    }
  }
}
</style>
