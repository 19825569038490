// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers';
import Invoice from './Invoice';

export default {
  type: 'invoice',

  possibleStatuses: ['e', 'h'],
  /**
   * Items that can be intermixed with this object type,
   * that could be cached.  Each of these object types
   * will be cleared from cache, when clearning assemblies from cache.
   * @type {[string,string,string]}
   */
  cachedTypes: [
    'client',
    'quote',
  ],

  fields: {
    ...Invoice.fields,
  },

  actions: {
    send: {
      text: 'Send receipt..',
      icon: 'paper-plane',
      action: 'Receipt/send',
      class: 'success',
      visible: (receipts) => receipts.every(invoice => invoice.invoice_status === 'e')
        && invoices.some(invoice => !invoice.invoice_receipt_time_sent),
      selectionRequired: true,
      multiple: true,
    },
    sendReceiptAgain: {
      text: 'Send receipt again..',
      icon: 'paper-plane',
      action: 'Receipt/send',
      visible: (invoices) => invoices.every(invoice => invoice.invoice_status === 'e' && invoice.invoice_receipt_time_sent),
      selectionRequired: true,
      multiple: true,
    },
  },

  generateVueActions() {
    return {
      async send({ dispatch, state, rootState }, payload) {
        const {
          grid = null,
        } = payload;

        const { object } = await dispatch('resolveObject', payload);

        // try {
          await dispatch('ajax', {
            path: `/receipt/send/${object.invoice_id}`,
          });

          dispatch('alert', {
            message: 'Successfully sent!',
          }, { root: true });

          if (grid) grid.reload(true);
        // } catch (e) {
        //   if (alert) {
        //     dispatch('alert', {
        //       message: e.userMessage || 'Could not send... try again.',
        //       error: true,
        //     }, { root: true });
        //   }
        //
        //   return Promise.reject(e);
        // }

        return payload;
      },
    };
  },

  getComputedDependants() {
    return Invoice.getComputedDependants();
  },

  getFieldDependencies() {
    return Invoice.getFieldDependencies();
  },
};
