export default {
  methods: {
    /**
     * Fetch the subscription based on a slug and type
     * @param string slug
     * @param string type
     * @param string currency
     * @returns {*}
     */
    async getSubscription(slug, type = 'regular', currency = 'usd') {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/selected',
        data: {
          slug,
          type,
          currency
        }
      })
      return payload
    },
    /**
     * Get the invoices for the users subscription
     * @param {*} data
     * @returns {*}
     */
    async getSubscriptionPayments(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/payments',
        data
      })
      return payload
    },
    /**
     * Get the current users subscription
     * @param {*} data
     * @returns
     */
    async getCurrentSubscription(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/current',
        data
      })
      return payload
    },
    /**
     * Retrieve all the users payment methods
     * @param {*} data
     * @returns {*}
     */
    async getPaymentMethods(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/getPaymentMethods',
        data
      })
      return payload
    },
    /**
     * Retrieve the subscriptions usage
     * at this point for users and projects
     * @param {*} data
     * @returns {*}
     */
    async getSubscriptionUsage(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/usage',
        data
      })
      return payload
    },
    /**
     * We need to create a payment intent when adding payment method
     * @param {*} data
     * @returns {*}
     */
    async createPaymentIntent(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/intent',
        data
      })
      return payload
    },
    /**
     * Either update or create a subscription
     * @param {*} data
     * @returns {*}
     */
    async changeSubscription(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/change',
        data
      })
      return payload
    },
    /**
     * Delete a payment method
     * @param string id
     * @returns {*}
     */
    async removePaymentMethod(id) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: `/subscription/${id}/method/remove`,
        data: {}
      })
      return payload
    },
    /**
     * Set a payment method as default
     * @param string id
     * @returns {*}
     */
    async setPaymentMethodAsDefault(id) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: `/subscription/${id}/method/default`,
        data: {}
      })
      return payload
    },
    /**
     * Retrieve the customers full set of information
     * @returns {*}
     */
    async getCustomer() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/customer',
        data: {}
      })
      return payload
    },
    /**
     * When displaying the subscription selector retrieve suggested subscriptions
     * default removes downgrades, sets suggested and current
     * @returns
     */
    async getSuggestedSubscriptions() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/suggested',
        data: {}
      })
      return payload
    },
    /**
     * Verify if the user has hit a limit manually
     * @param string limit
     */
    verifyLimit(limit = '') {
      this.$store.dispatch('ajax', {
        path: '/subscription/verify',
        data: {
          limit
        }
      })
    },
    /**
     * Check to see if a promo code is valid
     * @param string code
     * @returns {*}
     */
    async validatePromoCode(code, currency = 'usd') {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/promocode/validate',
        data: {
          code,
          currency
        }
      })
      return payload
    },
    /**
     * Checkout of the session
     * Creates the customer and subscription which returns a customer secret that we
     * use to pass to the payments elements
     * @param {*} data
     * @returns {*}
     */
    async checkout(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/checkout',
        data
      })
      return payload
    },
    /**
     * Reload a session stored in local storage
     * If a user has submitted first step but does not enter payment method
     * we can reuse the session
     * @param {*} data
     * @returns {*}
     */
    async reloadSession(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/session',
        data
      })
      return payload
    },
    /**
     * After checkout sign user onto the app
     * by creating user, company and authorizing
     * @param {*} data
     * @returns {Promise<{*}>}
     */
    signup(data) {
      return this.$store.dispatch('ajax', {
        path: '/subscription/signup',
        data
      })
    },
    /**
     * Retrieve the next upcoming invoice mostly for the price and date
     * @param {*} data
     * @returns {*}
     */
    async getUpcomingInvoice(data) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/subscription/upcoming',
        data
      })
      return payload
    },
    /**
     * return the limits from a subscription or product
     * @param {*} subscription
     * @returns {}
     */
    getSubscriptionLimits(subscription) {
      if (subscription.object === 'product') {
        const limits = {
          users: subscription.metadata.users || 0,
          projects: subscription.metadata.projects || 0
        }
        return {
          limits
        }
      }
      // if limits set on back end
      if (subscription.limits) {
        return {
          limits: subscription.limits
        }
      }
      const limits = {
        users: subscription.plan.product.metadata.users || 0,
        projects: subscription.plan.product.metadata.projects || 0
      }
      return {
        limits
      }
    }
  }
}
