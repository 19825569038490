<template>
  <MiniModal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :mask="true"
    :clickAway="true"
    :showTopClose="true"
    :showCloseButton="false"
    :closeable="true"
    :expandable="false"
  >
    <template #body>
      <AssemblyAdded ref="body" :store="modal.store" :reference="modal.refId" />
    </template>
  </MiniModal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
import AssemblyAdded from '../bodies/AssemblyAdded.vue'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'AddedAssemblyPreferences',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  computed: {},
  components: { AssemblyAdded },
  props: {
    go: {
      default: false
    },
    type: {
      default: 'assembly'
    }
  }
}
</script>
