<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    size="md"
    :isDirty="bodyIsDirty"
    :full="false"
    :clickAway="false"
    :expandable="true"
  >
    <template #body>
      <Location ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import Location from '../bodies/Location.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalLocation',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { Location },
  props: {
    type: {
      default: 'location'
    },
    go: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
