<template>
  <div class="scroll-container--container container-element">
    <div
      class="scroll-container--inner container-element"
      :style="containerInnerStyle"
      :class="containerClass"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollContainer',
  props: {
    containerInnerStyle: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    showScrollBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollDisplay: this.showScrollBar ? 'block' : 'none'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
%scrollbar {
  $scrollBarWidth: 5px !default;

  &::-webkit-scrollbar {
    width: $scrollBarWidth;
    height: $scrollBarWidth;
    display: v-bind(scrollDisplay);
  }
  &::-webkit-scrollbar-corner {
    display: block;
    background: rgba($cool-gray-500, 0.6);
    border-radius: 50%;
    padding: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    width: $scrollBarWidth;
    height: $scrollBarWidth;
    background: $cool-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background: $cool-gray-700;
    box-shadow: none;
    margin: 0 1px;
    width: 4px !important;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba($cool-gray-400, 0.25);
  }
}

.scroll-container--container {
  flex: 1 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;

  overflow: hidden;
  display: block;
  position: relative;

  @extend %scrollbar;

  .scroll-container--inner {
    height: auto;
  }

  &.full {
    .scroll-container--inner {
      min-height: 100%;
    }
  }

  &.page {
    padding: 1em 0.5em 0 0.5em;

    @media (max-width: 576px) {
      padding: 0.5em 0.25em 0 0.25em;
    }
  }

  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &.scroll-container--disable-scrolling {
    overflow-y: hidden !important;
  }

  &.scrollx:not(.scrolly) {
    overflow-x: scroll;
  }

  &.scrollx.scrolly {
    overflow-x: scroll;
    overflow-y: scroll;
  }

  &.scrolly:not(.scrollx),
  &:not(.scrolly):not(.scrollx) {
    overflow-y: scroll;
  }

  &:not(.scrollx) {
    .scroll-container--inner {
      margin-bottom: 10vh;
    }
  }

  &.blue,
  &.info {
    // @extend %scrollbarBlue;
  }

  .spacer {
    height: 0;
    width: 0;
  }

  &.m-0 {
    .scroll-container--inner {
      margin-bottom: 0 !important;
    }
  }
}

.app--container.small-format.disable-scrolling .scroll-container--container {
  overflow-y: hidden;
}

.app--container.small-format.disable-scrolling .modal .scroll-container--container {
  overflow-y: scroll;
}

#app.small-format {
  .scroll-container--container {
    // @extend %scrollbarSm;
    .scroll-container--inner {
      margin-bottom: 50vh;
    }

    /*>:last-child {*/
    /*  padding-bottom: 75vh;*/
    /*}*/

    .spacer {
      height: 5em;
      min-height: 5em;
    }
  }
}
</style>
