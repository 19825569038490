<template>
  <div class="flex w-full">
    <span
      class="w-full"
      v-if="!connecting"
      @click="handleOnClick"
      :class="[
        asLink
          ? 'text-blue-print-700 hover:text-blue-print-300'
          : 'bg-pitch-black text-level-yellow hover:bg-surface-800',
        'cursor-pointer transition text-lg rounded'
      ]"
    >
      <slot></slot>
    </span>
    <div
      v-if="connecting"
      class="p-4 my-4 border border-cool-gray border-dashed flex items-center justify-center w-full"
    >
      <spinner class="mr-4" :loading="1" size="2em" />
      <p class="mt-1">Connecting..</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaidLink',
  emits: ['onEvent', 'onLoad', 'onSuccess', 'onClick'],
  data() {
    return {
      handler: null,
      connecting: false,
      events: []
    }
  },
  watch: {
    tigger(val, oldVal) {
      if (val && !oldVal) this.handleOnClick()
    }
  },

  async mounted() {
    // load the plaid js
    await c.externalScript(this.plaidUrl)
    // config to be passed to plaid
    const props = {
      token: this.token,
      onSuccess: this.onSuccess,
      onLoad: this.onLoad,
      onExit: (err, metadata) => this.onExit(this, err, metadata),
      onEvent: this.onEvent,
      receivedRedirectUri: null,
      selectAccount: true
    }
    // create the plaid handler
    this.handler = window.Plaid.create(props)
  },

  methods: {
    /**
     * User exited plaid modal
     * @param {object} e
     * @param {string} err
     * @param {object} metadata
     */
    onExit(e, err, metadata) {
      e.$emit('onExit', {
        err,
        metadata
      })
      this.connecting = false
    },
    /**
     * Event occurred while interacting with plaid modal
     * @param {string} eventName
     * @param {object} metadata
     */
    onEvent(eventName, metadata) {
      this.events.push({
        name: eventName,
        data: metadata
      })
      this.$emit(`on${c.capitalize(eventName)}`, {
        eventName,
        metadata
      })
      this.$emit('onEvent', {
        eventName,
        metadata
      })
    },
    /**
     * Plaid modal loaded
     */
    onLoad() {
      this.$emit('onLoad')
    },
    /**
     * User has successfully completed all plaid steps
     * @param {string} publicToken
     * @param {object} metadata
     */
    onSuccess(publicToken, metadata) {
      this.$emit('onSuccess', {
        publicToken,
        metadata
      })
      this.connecting = false
    },
    /**
     * Open plaid modal
     */
    handleOnClick() {
      const institution = this.institution || null
      if (this.action) this.action()
      if (this.handler) {
        this.$emit('onClick')
        this.connecting = true
        this.handler.open(institution)
      }
    }
  },

  props: {
    plaidUrl: {
      type: String,
      default: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
    },
    token: {
      type: String,
      required: true
    },
    asLink: {
      type: Boolean,
      default: false
    },
    btnClass: {
      type: String
    },
    action: {
      type: Function
    },
    tigger: {
      type: Boolean,
      default: false
    }
  }
}
</script>
