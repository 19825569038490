<template>
  <div class="slider--container my-8 mx-8">
    <Slider
      v-model="localValue"
      :step="step"
      :range="range"
      :min="min"
      :max="max"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Slider from 'primevue/slider'

Slider.compatConfig = { MODE: 3 }

export default {
  name: 'SliderComponent',
  emits: ['input'],
  data() {
    return {
      slider: null,
      localValue: this.value
    }
  },
  watch: {
    value(val) {
      this.localValue = val
    },
    localValue(val) {
      this.$emit('input', val)
    }
  },
  components: {
    Slider
  },
  props: {
    disabled: {
      default: false
    },
    value: {
      required: true
    },
    range: {
      default: false
    },
    min: {
      type: Number,
      default: 0,
      required: false
    },
    max: {
      type: Number,
      default: 100,
      required: false
    },
    step: {
      type: Number,
      default: 1,
      required: false
    },
    ticks: {
      type: Array,
      required: false
    },
    ticks_positions: {
      type: Array,
      required: false
    },
    ticks_labels: {
      type: Array,
      required: false
    },
    ticks_snap_bounds: {
      type: Number,
      required: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
