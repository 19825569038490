import { computed, ref, watch } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useStore } from 'vuex'

export const usePreferences = defineStore('preferences', () => {
  const store = useStore()

  const prefConstants = {
    UserFirstLoginIgnore: 'UserFirstLoginIgnore',
    IgnoreHelpStart: 'IgnoreHelpStart',
    DoneTutorial: 'DoneTutorial',
    DontShowAddItemWizard: 'DontShowAddItemWizard',
    DontShowItemWizard: 'DontShowItemWizard',
    DoneWelcomeWizard: 'DoneWelcomeWizard',
    defaultLengthMeasure: 'defaultLengthMeasure',
    defaultAreaMeasure: 'defaultAreaMeasure',
    defaultVolumeMeasure: 'defaultVolumeMeasure',
    defaultWeightMeasure: 'defaultWeightMeasure',
    DefaultTimeRangeIndex: 'DefaultTimeRangeIndex',
    TraverseDefaultSettings: 'TraverseDefaultSettings'
  }

  const processPrefs = (prefs) => {
    const processed = {}
    for (let key in prefs) {
      if (/^-json-/.test(key)) {
        processed[key.replace('-json-', '')] = JSON.parse(prefs[key])
      } else {
        processed[key] = prefs[key]
      }
    }
    return processed
  }

  const hasFetched = ref(false)
  const fetchedPrefs = ref({})
  const rawPrefs = ref({})
  const unsavedPrefs = ref({})
  const sessionSavedPrefs = ref({})
  const userPrefs = computed(() => ({
    ...fetchedPrefs.value,
    ...unsavedPrefs.value,
    ...sessionSavedPrefs.value
  }))

  let resolver
  const waiter = ref(
    new Promise((rr) => {
      resolver = rr
    })
  )

  watch(unsavedPrefs, () => c.throttle(() => savePrefs(), { delay: 2000 }))

  const formatKey = (key) => key.replace(/\s/g, '_')
  const fetchPrefs = async () => {
    if (!store.state.session?.user?.user_id) return

    const {
      set: [{ oMeta: prefs }]
    } = await store.dispatch('ajax', {
      path: 'meta/filter',
      data: {
        filters: {
          user_id: store.state.session.user.user_id
        }
      }
    })

    rawPrefs.value = _.imm(prefs)
    const processed = processPrefs(prefs)

    hasFetched.value = true
    fetchedPrefs.value = processed
    resolver()
  }

  const savePrefs = async () => {
    const prefs = { ...unsavedPrefs.value }
    sessionSavedPrefs.value = _.imm({ ...sessionSavedPrefs.value, ...prefs })
    // flush
    unsavedPrefs.value = {}

    const processed = {}
    // process for sending and receiving
    for (let key in prefs) {
      const value = prefs[key]

      const scalarValue = false // forward basis always encode
      const saveValue = JSON.stringify(value)

      const scalarKey = c.isScalar(key)
      const saveKey = `${scalarValue ? '' : '-json-'}${scalarKey ? key : JSON.stringify(key)}`

      if (saveKey in rawPrefs.value && saveValue === rawPrefs.value[saveKey]) continue

      // Now check that it isn't already the same
      processed[saveKey] = saveValue
    }

    // only save if there are material changes
    if (!Object.keys(processed).length) return

    await store.dispatch('ajax', {
      path: 'meta/set',
      // Make sure object is string key/value pairs
      data: processed
    })

    fetchPrefs()
  }
  const setPrefs = (object) => {
    unsavedPrefs.value = {
      ...unsavedPrefs.value,
      ...object
    }
  }
  const setPref = (itemKey, itemValue) =>
    setPrefs({
      [formatKey(itemKey)]: itemValue
    })
  const getPref = (itemKey) => userPrefs.value[formatKey(itemKey)] ?? null

  if (!hasFetched.value) fetchPrefs()

  return {
    hasFetched,
    unsavedPrefs,
    fetchedPrefs,
    userPrefs,
    prefConstants,

    setPrefs,
    setPref,
    getPref,

    fetchPrefs,
    savePrefs,
    waiter
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePreferences, import.meta.hot))
}
