<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    size="md"
    :isDirty="bodyIsDirty"
    :full="false"
    :expandable="false"
    :clickAway="false"
  >
    <template #header> Create new labor rate </template>
    <template #body>
      <labor-type-body ref="body" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import LaborTypeBody from '@/components/bodies/LaborType.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'

export default {
  name: 'LaborTypeNew',
  mixins: [ObjectModalMixin],
  components: { LaborTypeBody },
  props: {
    type: {
      default: 'labor_type'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
