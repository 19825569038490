export default {
  panel: {
    class: 'w-full rounded-sm'
  },
  header: {
    class: [
      'rounded-md w-full',
      'outline-none',
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50'
    ]
  },
  headercontent: () => ({
    class: [
      // Shape
      'rounded-sm',

      // Color
      'border-0',
      'text-surface-900 dark:text-surface-0/80',
      'font-medium',

      // States
      'hover:bg-cement-200 dark:hover:bg-surface-700/80',
      'hover:text-surface-900',

      // Transition
      'transition'
    ]
  }),
  headeraction: {
    class: [
      'relative',

      // Font
      'font-bold',
      'leading-none',

      // Flex & Alignments
      'flex items-center',

      // Misc
      'select-none cursor-pointer no-underline'
    ]
  },
  headerlabel: {
    class: 'leading-none'
  },
  headerIcon: {
    class: 'mr-2'
  },
  submenuicon: {
    class: 'mr-2'
  },
  menucontent: {
    class: [
      // Spacing
      'py-1',

      // Shape
      'border-0',
      'rounded-t-none rounded-br-sm rounded-bl-sm',

      // Color
      'text-surface-700 dark:text-white/80'
      // 'bg-surface-0 dark:bg-surface-800',
    ]
  },
  menu: {
    class: ['outline-none', 'm-0 p-0 list-none']
  },
  content: {
    class: [
      // Shape
      'border-none rounded-md',

      // Color
      'text-surface-700 dark:text-white/80',

      // Transition
      'transition',
      'hover:bg-cement-200 dark:hover:bg-surface-700/80',
      'hover:text-surface-900'
    ]
  },
  action: ({ context }) => ({
    class: [
      'relative',

      // Font
      'leading-none',

      // Flex & Alignments
      'flex items-center w-full',

      // Spacing
      'py-1',
      'h-8',

      // Color
      'text-surface-700 dark:text-white/80',

      // States
      'hover:bg-surface-100 dark:hover:bg-surface-700/80',
      'hover:text-surface-900',
      {
        'bg-surface-200 text-surface-700 dark:text-white/80 dark:bg-surface-600/90': context.focused
      },

      // Misc
      'cursor-pointer no-underline',
      'select-none overflow-hidden'
    ]
  }),
  icon: {
    class: 'mr-2'
  },
  submenu: {
    class: 'pl-3 m-0 list-none'
  },
  separator: {
    class: 'h-1 border-b border-cement-700 hover:bg-transparent'
  },
  transition: {
    enterFromClass: 'max-h-0',
    enterActiveClass:
      'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
    enterToClass: 'max-h-[1000px]',
    leaveFromClass: 'max-h-[1000px]',
    leaveActiveClass:
      'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
    leaveToClass: 'max-h-0'
  }
}
