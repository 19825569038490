const chars = 'bcdfghjklmnpqrstvwxyz'; // cant have numbers, for dimensions prefixes
// const fmod = (a, b) => Number((a - (Math.floor(a / b) * b)).toPrecision(8));

const getShort = (id, base = null) => {
  const baseChars = base || chars;
  const length = baseChars.length;

  let out = '';
  let int = id;
  let curr;
  while (int > (length - 1)) {
    curr = Math.floor(int % length);
    if (curr > length) console.log('curr > length', curr, int);
    out = `${baseChars[curr]}${out}`;
    int = Math.floor(int / length);
  }

  return `${baseChars[int]}${out}`;
};

const getId = (short) => {
  const base = chars;

  let string = short;

  const length = base.length;
  const size = string.length - 1;

  string = string.split('');

  let out = base.indexOf(string.pop());

  string.forEach((char, i) => {
    out += base.indexOf(char) * (length ** (size - i));
  });

  return out;
};

export default {
  getShortFromId: getShort,
  getIdFromShort: getId,
};
