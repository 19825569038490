import * as types from '../../mutation-types'

/**
 * Emits:
 *  -phoneReady (root)
 *  -connectionIncoming (root)
 *  -connectionDisconnected (root)
 *  -connectionConnected (root)
 */
// const $scriptjs = require('scriptjs');
//
// const globalDevice = null;
// const globalConnection = null;
let globalNumber = null

// initial state
const initialState = {
  capabilityToken: null,
  error: null,
  activePhone: null,
  callStatus: null,
  phones: [],
  phonesOpen: [],
  tags: {},
  lastPhoneOpen: null
}

// getters
const initialGetters = {}

// actions
const actions = {
  /**
   *
   * @param commit
   * @param state
   * @param rootState
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  async call({ commit, state, rootState, dispatch }, payload) {
    const {
      number
      // tags = {},
    } = payload

    globalNumber = String(number)

    dispatch(
      'openLink',
      {
        url: `tel:${globalNumber}`
      },
      { root: true }
    )

    return payload
  }
}

// mutations
const mutations = {
  [types.CHANGE_TAGS](state, { tags }) {
    state.tags = tags
  },
  [types.ADD_DEVICE](state, { id }) {
    state.activePhone = id
  },
  [types.PHONE_READY](state, { phone }) {
    state.phones = [phone]
  },
  [types.PHONE_OPEN](state, { phone, force = false }) {
    if (phone.phone_is_verified !== false || force === true) {
      state.activePhone = phone.phone_id
      state.phonesOpen = [phone]
    }
    state.lastPhoneOpen = phone
  },
  [types.PHONE_CLOSE](state) {
    state.phonesOpen = []
  },
  [types.PHONE_SAVE](state, { phone }) {
    state.lastPhoneOpen = phone
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: initialGetters,
  actions,
  mutations
}
