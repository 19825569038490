export default {
  methods: {
    async msDone(key) {
      return this.$store.dispatch('milestone/hasTriggeredOnce', key)
    },
    async msTrigger(key, additionalData = {}, session = null) {
      return this.$store.dispatch('milestone/trigger', {
        milestone: key,
        embue: additionalData,
        session: session || _.imm(this.$store.state.session)
      })
    }
  }
}
