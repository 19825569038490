<template>
  <div id="cont" class="progress-container" :data-pct="progress">
    <!-- contents -->
    <slot></slot>

    <svg
      :style="{
        zoom: zoom,
        position: 'absolute',
        top: `CALC(50% - 150px)`,
        left: `CALC(50% - 150px)`,
        opacity: progress >= 100 ? 0 : 1
      }"
      id="svg"
      width="300"
      height="300"
      viewPort="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="f2" x="-20%" y="-20%" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <circle
        r="90"
        cx="150"
        cy="150"
        fill="transparent"
        stroke-dasharray="565.48"
        stroke-dashoffset="0"
      ></circle>
      <circle
        id="bar"
        r="90"
        cx="150"
        cy="150"
        fill="transparent"
        stroke-dasharray="565.48"
        stroke-dashoffset="0"
        :style="{
          strokeDashoffset: strokeDashoffset
        }"
      ></circle>
    </svg>

    <checkmark
      :style="{
        'z-index': 0.8,
        position: 'absolute',
        top: '0px',
        left: '0px'
      }"
      :size="100 * zoom"
      v-if="progress === 100"
    />
  </div>
</template>

<script>
import Checkmark from './Checkmark.vue'

export default {
  name: 'ProgressContainer',
  data() {
    return {
      done: false
    }
  },
  props: {
    /**
     * In pixels, square
     */
    size: {
      default: 50
    },
    /**
     * Integer percentage ie: 35
     */
    progress: {
      default: 0,
      required: true
    }
  },
  watch: {
    progress(p) {
      this.done = p >= 1
    }
  },
  method: {
    setProgress(amt) {
      this.progress = +amt
    }
  },
  computed: {
    zoom() {
      return this.size / 300
    },
    strokeDashoffset() {
      let p = c.deformat(this.progress, 'percentage')
      const circ = Math.PI * (90 * 2)

      if (p < 0) p = 0
      if (p > 1) p = 1

      return (1 - p) * circ
    }
  },
  components: { Checkmark }
}
</script>

<style rel="stylesheet/scss" lang="scss">
#cont {
  display: inline-block !important;
  position: relative;
  z-index: 2;
  opacity: 1;
  #svg {
    transition: opacity 4s linear;
    opacity: 1;
    circle {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
      stroke: transparent;
      stroke-width: 1em;
      &#bar {
        stroke: $blue-print-700;
        box-shadow: 0px 0px 1em 1em rgba($blue-print-700, 0.5);
        filter: url(#f2);
        &.done {
          box-shadow: 0px 0px 1em 1em rgba($blue-print-700, 1);
        }
      }
    }
  }
}
</style>
