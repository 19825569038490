import { onUnmounted } from 'vue'
import { useStore } from 'vuex'
import CostItem from '../../../imports/api/schemas/CostItem'
import * as types from '@/store/mutation-types.js'
import eventBus from '@/eventBus.js'
// import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js';
export default (payload = {}) => {
  const {
    store = 'Quote',
    refId,
    quoteId,
    loadAssigneesOnMount = true,
    saveAssigneesOnChange = false
  } = payload

  const $store = useStore()

  const saveAssignments = async (assignments) => {
    if (!assignments.length) return
    // update assignments
    return $store.dispatch('Assignee/reassign', {
      assignments,
      quoteId
    })
  }

  // Not reliable enought with changes list to know when to send updates for assignees
  // const changeHandler = ({ refIds, changes }) => {
  //   if (!listen) return
  //   const norm = $store.state[store].normalized
  //
  //   let assignments = []
  //   Object.keys(changes).forEach(refId => {
  //     if (!('assignee_ids' in changes[refId])) return
  //
  //     const obj =  norm?.[refId] ?? {}
  //     const itemId = obj.item_id
  //
  //     if (!itemId) return
  //
  //     const itemAssignees = CostItem.getItemAssigneeFormatFromAssignees(obj.assignee_ids)
  //
  //     assignments = [
  //       ...assignments,
  //       ...itemAssignees.map((itemAssignee, i) => ({
  //         ...itemAssignee,
  //         item_id: itemId,
  //         item_assignee_type: i === 0 ? 'primary' : '',
  //       }))
  //     ]
  //   })
  //
  //   saveAssignments(assignments)
  // }

  const loadAssignees = async () => {
    const { set } = await $store.dispatch('ItemAssignee/search', {
      filters: {
        quote_id: $store.state[store].normalized[refId].quote_id,
        project_id: $store.state[store].normalized[refId].quote_id
      },
      offset: 0,
      limit: 1000
    })

    const changes = CostItem.getAssigneeIdsFromItemAssignees(set, $store.state[store].normalized)

    $store.commit(`${store}/${types.FIELDS}`, {
      changes
    })
    $store.dispatch(`${store}/addChanges`, {
      changes,
      rootRefId: refId
    })
    eventBus.$emit('sheet-assignees-loaded', { changes })
  }

  if (saveAssigneesOnChange) {
    // Not reliable enought with changes list to know when to send updates for assignees
    // ChangeWatcher.useChangeWatcher({
    //   store,
    //   refId,
    //   changeHandler,
    // })
  }
  if (loadAssigneesOnMount) {
    eventBus.$once('sheet-initialized', () => {
      if (Object.keys($store.state[store].normalized).length > 1) {
        // Load assignments
        loadAssignees()
      }
    })
  }

  /**
   * Maps selected assignee objects to a simplified format.
   *
   * This function takes in a selected assignee object, where each value is an object
   * containing `value` and `text` properties. It then transforms this object into an array
   * of objects with `type`, `id`, and `name` properties.
   *
   * @param {Object} selected - The selected assignee object. Each key-value pair in this object
   *                            should have a structure where the value is an object containing
   *                            `value` and `text` properties.
   * @returns {Array} - Returns an array of objects. Each object contains the following properties:
   *                    - `type`: A string representing the type of the assignee, extracted from the `value`.
   *                    - `id`: A string representing the ID of the assignee, extracted from the `value`.
   *                    - `name`: A string representing the name of the assignee, taken from the `text` property.
   */
  const mapSelectedAssigneeToAssignees = (selected) => {
    return Object.values(selected).map((assigneeObj) => {
      const id = assigneeObj.value
      const parts = id.split(':')
      return {
        type: parts[0],
        id: parts[1],
        name: assigneeObj.text
      }
    })
  }

  /**
   * Formats an array of assignee objects for the choose list.
   *
   * This function takes an array of assignee objects and maps each object
   * to a string in the format "type:id", where "type" and "id" are extracted
   * from the object's properties.
   *
   * @param {Array} assignees - An array of assignee objects. Each object should have the properties
   *                            `item_assignee_object_type` and `item_assignee_object_id`.
   * @returns {Array} - Returns an array of strings, each in the format "type:id".
   */
  const formatAssigneesForChoose = (assignees) => {
    return assignees.map(
      (a) => `${a.item_assignee_object_type || a.type}:${a.item_assignee_object_id || a.id}`
    )
  }

  onUnmounted(() => {
    eventBus.$off('sheet-initialized')
  })

  return {
    saveAssignments,
    loadAssignees,
    mapSelectedAssigneeToAssignees,
    formatAssigneesForChoose
  }
}
