import Normalize from '../../../imports/api/Normalize.js'
import Formatters from '@/components/ui/Choose/Formatters.js'
import AutoCost from '../../../imports/api/AutoCost.js'
import { computed, ref } from 'vue'

export default {
  useItemLaborCosts(payload) {
    const {
      refId: rrefId,
      store: rstore,
      type: rtype,

      $store,
      cost_type_hours_per_unit,
      laborTypeId,
      labor_type_rate_net,
      oMeta,
      norm
    } = payload

    const props = {
      store: rstore,
      refId: rrefId,
      type: rtype
    }

    const divisor = ref(1)

    const laborRate = computed({
      get: () => c.format(labor_type_rate_net.value, '$'),
      set: (v) => {
        labor_type_rate_net.value = v
      }
    })
    const crewSize = computed({
      get: () => oMeta.value?.crew?.size ?? 1,
      set: (v) => {
        oMeta.value = {
          ...oMeta.value,
          crew: {
            ...(oMeta.value?.crew ?? {}),
            size: _.n(v)
          }
        }
      }
    })

    const setHours = (h, m, s) => {
      const total = s / 3600 + m / 60 + h
      cost_type_hours_per_unit.value = _.divide(total, divisor.value)
    }
    const totalSeconds = computed({
      get: () => divisor.value * cost_type_hours_per_unit.value * 3600
    })
    const hours = computed({
      get: () => Math.floor(totalSeconds.value / 3600),
      set: (v) => setHours(v, minutes.value, seconds.value)
    })
    const minutes = computed({
      get: () => Math.floor((totalSeconds.value - hours.value * 3600) / 60),
      set: (v) => setHours(hours.value, v, seconds.value)
    })
    const seconds = computed({
      get: () => Math.floor(totalSeconds.value - hours.value * 3600 - minutes.value * 60),
      set: (v) => setHours(hours.value, minutes.value, v)
    })

    const rootRefId = computed(() => Normalize.getNormalizedRootRefId(norm.value, props.refId))
    const mod = computed(() => {
      const rootMod = norm.value[rootRefId.value].oMod || null
      if (rootMod && rootMod.mod_id) return rootMod
      return $store.getters.defaultMod
    })

    const laborFormatter = (lt) => Formatters.labor_type(lt, $store, mod.value)
    const isLtAutoCost = computed(() => AutoCost.isAutoCostLaborTypeId(laborTypeId.value))

    return {
      laborRate,
      crewSize,
      hours,
      minutes,
      seconds,

      isLtAutoCost,
      laborFormatter,
      rootRefId,
      mod
    }
  }
}
