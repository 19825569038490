<template>
  <section class="section--container container-element my-6 first:mt-2 last:mb-4 w-full">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'Section'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.section--container {
  @each $color, $value in $additional-colors {
    &.#{$color} {
      color: $value;
      background-color: $value;
    }
  }

  &.center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
</style>
