import { BANNER_ADD, BANNER_UPDATE, BANNER_CLOSE, BANNER_TOGGLE } from '../../mutation-types'

const initialBanner = {
  message: '',
  open: false
}

// initial state
const state = {
  settings: {
    ...initialBanner
  }
}

// getters
const getters = {}

// actions
const actions = {
  async addBanner({ commit }, banner) {
    commit({
      type: BANNER_ADD,
      banner
    })
  },
  async updateBanner({ commit }, message) {
    commit({
      type: BANNER_UPDATE,
      message
    })
  },
  async toggleBanner({ commit }) {
    commit({
      type: BANNER_TOGGLE
    })
  },
  async closeBanner({ commit }) {
    commit({
      type: BANNER_CLOSE
    })
  }
}

// mutations
const mutations = {
  [BANNER_ADD](state, { banner }) {
    state.settings = { ...banner }
  },
  [BANNER_TOGGLE](state) {
    state.settings.open = !state.settings.open
  },
  [BANNER_UPDATE](state, { message }) {
    state.settings.message = message
  },
  [BANNER_CLOSE](state) {
    state.settings.open = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
