<template>
  <section>
    <div class="above-gantt flex justify-between py-4 px-3 relative">
      <div
        class="multi-actions absolute left-0 right-0 z-[10] top-0 w-full"
        v-if="selected && selected.length > 0"
      >
        <div class="min-h-10 bg-white rounded my-4 mx-3 py-2 px-3">
          <slot name="multi-actions" :selected="selected" />
        </div>
      </div>
      <div class="gantt-actions flex items-center gap-4">
        <slot name="gantt-actions-top"> </slot>
        <!-- appears above gantt beside filters -->
        <SelectionToggle
          v-model="interval"
          :options="intervals"
          optionLabel="name"
          @input="onIntervalChange"
          aria-labelledby="Gantt interval"
        />
        <slot name="gantt-actions"></slot>

        <!-- multi select actions -->
      </div>
      <!-- filter section -->
      <div class="gantt-actions--right flex items-center">
        <div class="flex items-center relative">
          <span class="flex items-center" v-tooltip="'Search schedule'">
            <font-awesome-icon
              class="cursor-pointer mr-2 hover:bg-cool-gray-200 px-3 py-1.5 rounded transition-transform"
              @click.native="toggleSearch"
              :icon="['far', showSearch ? 'arrow-right' : 'magnifying-glass']"
            />
          </span>
          <InputText
            v-model="query"
            :pt="{
              root: `!transition-all duration-400 ease-in-out ${showSearch ? '!w-48 opacity-100' : '!w-0 !min-w-0 opacity-0 !p-0 !m-0'}`
            }"
            :ptOptions="{
              mergeProps: true
            }"
            placeholder="Search..."
          />
        </div>
        <Btn
          severity="tertiary"
          v-tooltip="'Undo'"
          :link="true"
          @click="() => gantt.ext.undo.undo()"
        >
          <font-awesome-icon :icon="'rotate-left'" />
        </Btn>
        <Btn
          severity="tertiary"
          :link="true"
          class="ml-2"
          v-tooltip="'Redo'"
          @click="() => gantt.ext.undo.redo()"
        >
          <font-awesome-icon :icon="'rotate-right'" />
        </Btn>
        <template name="right-actions"> </template>
        <Btn
          severity="tertiary"
          v-if="!hideGroupBy"
          @click="() => (showFilters = !showFilters)"
          aria-controls="overlay_menu"
          :link="true"
          aria-haspopup="true"
        >
          <font-awesome-icon :icon="['far', 'filter-list']" class="mr-1" />
        </Btn>
        <Sidebar
          v-if="!hideGroupBy"
          position="right"
          :pt="{
            header: ['border-b border-cool-gray-200'],
            content: ['!pt-5']
          }"
          :ptOptions="{ mergeProps: true }"
          v-model:visible="showFilters"
        >
          <template #header>
            <span>
              <font-awesome-icon :icon="['far', 'filter-list']" class="mr-1" />
              Filters
            </span>
          </template>
          <div class="flex flex-col h-full">
            <div>
              <slot name="filters" />
              <!-- <h4 class="mt-5 mb-3 pt-3 border-t border-cool-gray-100">Group by</h4> -->
              <!-- <div
                v-for="group in groups"
                :key="`group-${group.value}`"
                class="flex align-items-center pb-2"
              >
                <RadioButton
                  v-model="groupBy"
                  @input="(val) => onGroupBy(val)"
                  :inputId="group.value"
                  name="dynamic"
                  :value="group.text"
                />
                <label :for="`group-${group.value}`" class="ml-2">{{ group.text }}</label>
              </div> -->
            </div>
            <div class="mt-auto">
              <Btn class="w-full" v-if="filterCount" @click="clearFilters" severity="primary-black">
                Clear {{ filterCount }} filter{{ filterCount > 1 ? 's' : '' }}
              </Btn>
            </div>
          </div>
        </Sidebar>
        <!-- Collapse controls for mobile/tablet  -->
        <btn-bar :actions="mobileNavItems" :collapse="true">
          <template #button>
            <Btn
              :pt="{ root: '!border-none' }"
              severity="tertiary"
              ref="dropBtn"
              class="ml-2"
              v-tooltip="'More'"
            >
              <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
            </Btn>
          </template>
        </btn-bar>
      </div>
    </div>
    <div class="cc-gantt" :class="[hideTaskActions ? 'no-actions' : '']" :id="refId"></div>
  </section>
</template>

<script setup>
import { onMounted, watch, ref, toRef, onUnmounted, defineProps, computed, defineEmits } from 'vue'
import moment from 'moment'
import Sidebar from 'primevue/sidebar'
import RadioButton from 'primevue/radiobutton'
import useGantt from '@/components/ui/gantt/Gantt'
import $scriptjs from 'scriptjs'
import eventBus from '@/eventBus'
import SelectionToggle from '@/components/ui/SelectionToggle.vue'

RadioButton.compatConfig = { MODE: 3 }

$scriptjs('/static/gantt/dhtmlxgantt.js', 'dhtmlxgantt')

// emitters
const emit = defineEmits([
  'on-ready',
  'item-click',
  'link-added',
  'task-dragged',
  'link-deleted',
  'link-updated',
  'row-dragged',
  'auto-schedule-update',
  'clear-filters',
  'reload-data',
  'reload',
  'double-click',
  'task-created',
  'click-and-drag',
  'delete-stage',
  'on-before-row-drag-move'
])

// props
const props = defineProps({
  refId: {
    type: String,
    default: `gantt-${Math.floor(Math.random() * 1000)}`
  },
  onBarRender: {
    type: Function
  },
  onTaskLayerRender: {
    type: Function
  },
  onTooltipRender: {
    type: Function
  },
  selectable: {
    type: Boolean,
    default: false
  },
  hideGroupBy: {
    type: Boolean
  },
  hideExpandAll: {
    type: Boolean,
    default: false
  },
  hideTaskActions: {
    type: Boolean,
    default: false
  },
  lazyLoad: {
    type: Boolean,
    default: false
  },
  goToToday: {
    type: Boolean,
    default: false
  },
  correctWorkTime: {
    type: Boolean,
    default: true
  },
  filters: {
    type: Object,
    default: () => {}
  },
  startDate: {
    type: Date
  },
  endDate: {
    type: Date
  }
})

// reactive local
const hideGroupBy = toRef(props, 'hideGroupBy')
const hideExpandAll = toRef(props, 'hideExpandAll')
const lazyLoad = toRef(props, 'lazyLoad')
const correctWorkTime = toRef(props, 'correctWorkTime')
const filters = toRef(props, 'filters')

const showFilters = ref(false)

const {
  onDownload,
  clearFilters,
  onHideGrid,
  reload,
  onIntervalChange,
  expandAll,
  zoomIn,
  zoomOut,
  onRefreshItems,
  onClickAndDrag,
  initTooltips,
  handleWorkdays,
  addOrderLists,
  addMarker,
  setEvents,
  setTemplates,
  clearSelected,
  setProps,
  setEmit,
  searchTasks,
  filterTasks,
  hasFilters,
  renderFilteredTasks,
  resetGanttView,
  gantt,
  columns,
  filterCount,
  displayGrid,
  interval,
  intervals,
  expanded,
  downloadFormats,
  downloadFormat,
  intervalSpan,
  levels,
  data,
  minDate,
  maxDate,
  lazyLoad: shouldLazyLoad,
  todayMarker,
  settings,
  showSearch,
  query,
  selected,
  restoreOrCreateInstance,
  setGanttInstance
} = useGantt()

const mobileNavItems = computed(() => [
  {
    title: displayGrid.value ? 'Display grid columns' : 'Hide grid columns',
    icon: displayGrid ? 'chart-gantt' : 'sidebar',
    action: onHideGrid
  },
  {
    title: 'View as',
    icon: 'calendar-days',
    options: intervals.value.map((i) => ({
      title: i.text,
      action: () => {
        interval.value = i.value
        onIntervalChange()
      }
    }))
  },
  {
    action: zoomIn,
    title: 'Zoom in',
    icon: 'plus'
  },
  {
    action: zoomOut,
    title: 'Zoom out',
    icon: 'minus'
  },
  {
    action: () => gantt.value.ext.undo.redo(),
    title: `Redo`,
    icon: 'rotate-right'
  },
  {
    action: () => gantt.value.ext.undo.undo(),
    title: `Undo`,
    icon: 'rotate-left'
  },
  ...(!hideExpandAll.value
    ? [
        {
          action: expandAll,
          title: expanded.value ? 'Close all items' : 'Expand all items',
          icon: expanded.value ? 'chevrons-up' : 'chevrons-down'
        }
      ]
    : []),
  {
    title: 'Reload schedule',
    icon: 'arrows-rotate',
    action: reload
  },
  {
    title: 'Download as',
    icon: 'download',
    options: downloadFormats.value.map((f) => ({
      title: f.text,
      action: () => {
        downloadFormat.value = f.value
        onDownload()
      }
    }))
  }
])

/**
 * Watch and update filters
 */
watch(
  filters,
  () => {
    if (hasFilters()) {
      const filteredTasks = filterTasks()
      renderFilteredTasks(filteredTasks)
    } else {
      resetGanttView(data.value) // originalTasks holds the full dataset
    }
  },
  { deep: true }
)

watch(query, (newQuery) => {
  clearSelected()
  if (newQuery) {
    const filteredTasks = searchTasks(newQuery)
    renderFilteredTasks(filteredTasks)
  } else {
    resetGanttView(data.value) // originalTasks holds the full dataset
  }
})

/**
 * Watch and update settings
 */
watch(
  settings,
  () => {
    if (settings.value.asWorkdays && settings.value.asWorkdays.length > 0) {
      handleWorkdays()
    }
  },
  { deep: true }
)

watch(gantt, () => {
  if (!gantt.value) return
  // init
  init()
})

/**
 * Configure the gantt chart based on props and default settings
 */
const config = () => {
  // configure plugins
  gantt.value.plugins({
    drag_timeline: true,
    click_drag: false,
    tooltip: true,
    marker: true,
    auto_scheduling: true,
    export_api: true,
    grouping: true,
    multiselect: false,
    undo: true
  })

  // configure columns
  gantt.value.config.columns = columns.value
  // basic configurations
  gantt.value.config.row_height = 40
  gantt.value.config.bar_height = 26
  gantt.value.config.scale_height = 40
  gantt.value.config.font_width_ratio = 7
  gantt.value.config.types.subtask = 'subtask'
  gantt.value.config.types.quote = 'quote'
  gantt.value.config.types.list = 'list'
  gantt.value.config.fit_tasks = true
  gantt.value.config.duration_unit = 'day'
  gantt.value.config.sort = true
  gantt.value.config.order_branch = 'marker'
  gantt.value.config.order_branch_free = true

  gantt.value.config.auto_scheduling = true
  gantt.value.config.auto_scheduling_descendant_links = true
  gantt.value.config.auto_scheduling_compatibility = true
  gantt.value.config.auto_scheduling_initial = true

  // gantt.value.config.multiselect_one_level = true
  gantt.value.config.keep_grid_width = true
  gantt.value.config.autofit = true
  gantt.value.config.work_time = correctWorkTime.value
  gantt.value.config.round_dnd_dates = false
  gantt.value.config.time_step = 60 * 23.8

  gantt.value.config.correct_work_time = correctWorkTime.value
  gantt.value.config.show_errors = false
  gantt.value.config.drag_project = true
  gantt.value.config.smart_scales = true
  gantt.value.config.static_background = true
  gantt.value.config.show_progress = false
  gantt.value.config.min_duration = 24 * 60 * 60 * 1000 // 1 day

  gantt.value.config.smart_rendering = true

  shouldLazyLoad.value = lazyLoad.value
  if (lazyLoad.value) {
    const startOfYear = moment().subtract(10, 'years').format('YYYY-MM-DD hh:mm')
    const endOfYear = moment().add(10, 'years').format('YYYY-MM-DD hh:mm')
    gantt.value.config.start_date = startOfYear
    gantt.value.config.end_date = endOfYear

    minDate.value = moment().subtract(intervalSpan.value, 'months').format('YYYY-MM-DD hh:mm')
    maxDate.value = moment().add(intervalSpan.value, 'months').format('YYYY-MM-DD hh:mm')
  } else if (props.startDate && props.endDate) {
    const startOfProjectTimeline = moment(props.startDate)
      .subtract(2, 'years')
      .format('YYYY-MM-DD hh:mm')
    const endOfProjectTimeline = moment(props.endDate).add(2, 'years').format('YYYY-MM-DD hh:mm')
    gantt.value.config.start_date = startOfProjectTimeline
    gantt.value.config.end_date = endOfProjectTimeline
  }

  // configure scaling
  const zoomConfig = {
    levels: levels.value,
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: () => gantt.value.$root.querySelector('.gantt_task')
  }
  gantt.value.ext.zoom.init(zoomConfig)
  gantt.value.ext.zoom.setLevel(interval.value)

  gantt.value.config.click_drag = {
    callback: onClickAndDrag,
    singleRow: true
  }
  if (props.onTaskLayerRender) props.onTaskLayerRender()
}

/**
 * Initialize the gantt
 */
const init = () => {
  config()
  // initiate the chart with custom reference id
  gantt.value.init(props.refId.toString())
  // load the data
  gantt.value.parse(data.value)
  // update the templates
  setTemplates()
  // add the events
  setEvents()
  // order lists are used for grouping
  addOrderLists()
  // load the companies workday settings
  handleWorkdays()
  // customize the tooltips
  initTooltips()
  // on first mount auto schedule the gantt
  gantt.value.autoSchedule()
  // add todays marker
  todayMarker.value = addMarker(new Date())

  if (props.goToToday) {
    gantt.value.showDate(new Date())
  }
}

/**
 * Hard reload of data
 */
const onHardReload = () => {
  setGanttInstance()
  init()
}

const onRender = () => gantt.value.render

const toggleSearch = () => (showSearch.value = !showSearch.value)

onMounted(() => {
  // set the props and emitters for composable
  setProps(props)
  setEmit(emit)
  c.externalScript('https://kit.fontawesome.com/7e317314ee.js')
  // script is loaded and ready to go
  $scriptjs.ready('dhtmlxgantt', () => {
    // check to make sure the gantt element has been mounted
    const el = document.getElementById(props.refId)
    if (!el) return
    // check to see if a version exists or instantiate new gantt
    restoreOrCreateInstance()
    // handle deselecting multi select
    eventBus.$on('clear-gantt-selected', () => clearSelected())
    // refresh the entire gantt
    eventBus.$on('refresh-gantt', onRender)
    eventBus.$on('init-gantt', onHardReload)
    // a root listener to refresh items of the gantt chart
    eventBus.$on('refresh-gantt-items', onRefreshItems)
    eventBus.$on('refresh-order-lists', (orderLists) => addOrderLists(orderLists))
  })
})

onUnmounted(() => {
  eventBus.$off('refresh-gantt')
  eventBus.$off('refresh-gantt-items')
  eventBus.$off('refresh-order-lists')
  eventBus.$off('init-gantt')
  eventBus.$off('clear-gantt-selected')
})
</script>

<style lang="scss" rel="stylesheet/scss">
@import '../../../../public/static/gantt/dhtmlxgantt.css';

$task-color: $blue-200;
$subtask-color: $green-200;
$task-item-color: $matcha-200;
$project-color: $green-500;
$list-color: $orange-500;
$user-color: $purple-500;
$vendor-color: $purple-300;

$selected-color: $flame-white;
$hover-color: $cement-100;
$indicator-size: 1.5em;

$border: $surface-200;

// gantt container css
.cc-gantt {
  height: 100%;
  min-height: calc(100vh - 170px);

  // resizer css
  .gantt_resizer_stick {
    @apply bg-blue-print w-1;
  }

  .gantt_resizer_x {
    &::after {
      content: '';
      @apply mx-auto absolute left-0 right-0 h-full w-1;
    }
    &:hover {
      &::after {
        @apply bg-blue-print;
      }
    }
  }

  .gantt_resizer_y {
    &::after {
      content: '';
      @apply my-auto absolute top-0 bottom-0 w-full h-1;
    }
    &:hover {
      &::after {
        @apply bg-blue-print;
      }
    }
  }

  // common layout css
  .gantt_layout_cell {
    @apply border-none;
    &.gantt_layout_root {
      // timeline css
      .gantt_layout_cell.timeline_cell {
        border-top: 1px solid $border !important;
        // top timeline scale css
        .gantt_task_scale {
          .gantt_scale_cell {
            @apply font-sans text-pitch-black;
            text-transform: none !important;
          }
          // top section of the scale
          div.gantt_scale_line:first-of-type {
            @apply text-pitch-black uppercase;
          }
        }
        // timeline bars css
        .gantt_data_area {
          // general bar css
          .gantt_task_line {
            @apply rounded-sm border-none;
          }
          .gantt_task_content {
            @apply text-left px-2.5 flex items-center text-pitch-black;
          }
          .gantt_task_cell.week_end {
            background-color: $cool-gray-100 !important;
          }
          .gantt_task_row {
            &.gantt_selected {
              background-color: $selected-color;
            }
          }
          // task/stage specific bar css
          .gantt_bar_task {
            background: $task-color;
            &:hover {
              background: lighten($task-color, 5%);
            }
          }
          // project/quote specific css
          .gantt_bar_quote {
            background: $project-color;
            .gantt_task_content {
              @apply text-white;
            }
            &:hover {
              background: lighten($project-color, 5%);
            }
          }
          // task/cost item bar css
          .gantt_bar_subtask {
            background: $subtask-color;
            .gantt_task_content {
              @apply text-pitch-black;
            }
            &:hover {
              background: lighten($subtask-color, 5%);
            }

            &.task-item {
              border: 2px solid $task-item-color;
              background: $task-item-color;
              .gantt_task_content {
                @apply text-pitch-black;
              }
              &:hover {
                background: lighten($task-item-color, 5%);
                border: 2px solid lighten($task-item-color, 5%);
              }
            }
          }
          .subtask .gantt_task_progress {
            background: darken($subtask-color, 15%);
          }
        }
      }
      // left side data grid css
      .gantt_layout_cell.grid_cell {
        .gantt_layout_content {
          .gantt_grid {
            // data grid header
            .gantt_grid_scale {
              // data grid header cell
              border-bottom: 1px solid transparent !important;
              > .gantt_grid_head_cell {
                @apply text-pitch-black font-medium font-sans;
              }

              > .gantt_grid_column_resize_wrap {
                &:hover {
                  .gantt_grid_column_resize {
                    @apply bg-blue-print w-1;
                  }
                }
              }
            }
            // data grid data rows and cells
            .gantt_grid_data {
              .gantt_row {
                border: 0px solid transparent !important;
                &.gantt_selected {
                  background-color: $selected-color;
                }
                &.gantt_row_task {
                  @apply bg-cement-200;
                  &:hover {
                    @apply bg-cement-400;
                  }
                  .gantt-expand {
                    .task-title {
                      @apply font-bold;
                    }
                  }
                }
                &:hover {
                  background-color: $hover-color;
                }
                > .gantt_cell {
                  border-top: 1px solid $border;
                  border-right: 1px solid $border;
                  @apply border-collapse;
                }
                .gantt_cell {
                  @apply px-0;
                }
              }
            }
          }
        }
      }
    }
  }

  // gantt with no actions on left (company wide schedule)
  &.no-actions {
    @apply pl-5;
    .gantt_layout_content {
      .gantt_grid {
        .gantt_grid_scale {
          > .gantt_grid_head_cell {
            border-top: 1px solid $border !important;
            border-right: 1px solid $border !important;
            &:first-child {
              border-left: 1px solid $border !important;
              border-top: 1px solid $border !important;
              border-top-left-radius: 0.5em !important;
            }
            &:nth-child(2) {
              border-top-left-radius: 0 !important;
            }
            &:nth-last-child(3) {
              border-right: 1px solid $border !important;
            }
          }
        }
        .gantt_grid_data {
          .gantt_row {
            > .gantt_cell {
              &:last-of-type {
                border-right: 1px solid $border !important;
              }
              border-left: 1px solid $border !important;
            }
            &:nth-last-child(2) {
              .gantt_cell {
                border-bottom: 1px solid $border !important;
                &:first-of-type {
                  @apply rounded-bl-md;
                }
              }
            }
          }
        }
      }
    }
  }
  // gantt with actions on left (project schedule)
  &:not(.no-actions) {
    .gantt_layout_content {
      .gantt_grid {
        .gantt_grid_scale {
          > .gantt_grid_head_cell {
            border-top: 1px solid $border !important;
            border-right: 1px solid $border !important;
            &:first-child {
              border-left: 1px solid transparent !important;
              border-top: 1px solid transparent !important;
              border-bottom: 1px solid transparent !important;
              border-right: 1px solid transparent !important;
            }
            &:nth-child(2) {
              border-left: 1px solid $border !important;
              @apply rounded-tl-md;
            }
          }
        }
        .gantt_grid_data {
          .gantt_row {
            > .gantt_cell {
              @apply text-ellipsis;
              @apply font-sans;
              &:nth-child(2) {
                @apply font-medium;
              }
              &.active {
                z-index: 99;
              }
              &:last-of-type {
                border-right: 1px solid $border !important;
              }
              // &:first-of-type {
              //   // @apply min-w-[62px];
              // }
              &:nth-child(1) {
                border-top: 0px solid transparent !important;
                border-left: 0px solid transparent !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                border-right: 1px solid $border !important;
                @apply bg-white;
              }
            }
            &:nth-last-child(2) {
              .gantt_cell {
                border-bottom: 1px solid $border !important;
                &:first-of-type {
                  border-bottom: 1px solid transparent !important;
                }
                &:nth-child(2) {
                  @apply rounded-bl-md;
                }
              }
            }
            // action bar
            .gantt-line--action {
              @apply opacity-0;
              &.isSelected {
                @apply opacity-100;
              }
            }
            &:hover {
              .gantt-line--action {
                @apply opacity-100;
              }
            }
            .gantt-line--actions-content.force-display {
              .gantt-line--action {
                @apply opacity-100;
              }
            }
          }
        }
      }
    }
  }
}

// tool tip css
.gantt_tooltip {
  @apply rounded-md px-6 py-8 z-10;
}

// gantt default popups css
.gantt_popup_controls {
  @apply flex;
}
.gantt_popup_button {
  @apply rounded-sm flex justify-center items-center h-10 font-medium text-base;
  &.gantt_ok_button {
    background-color: $blue-print-700;
  }
  &.gantt_cancel_button {
    background-color: $flame-white;
  }
}
.gantt_modal_box.gantt-alert {
  @apply px-4 py-8;
  .gantt_popup_text {
    @apply text-base text-cool-gray-800 mb-6;
  }
}

// dependency links css

$link-finish-to-start: $task-color;
$link-start-to-start: $subtask-color;
$link-finish-to-finish: #fdca40;
$link-start-to-finish: #fb3640;

.gantt_task_link.finish_to_start {
  .gantt_line_wrapper > div {
    background-color: $link-finish-to-start;
  }
  .gantt_link_arrow {
    border-color: $link-finish-to-start;
  }
}

.gantt_task_link.start_to_start {
  .gantt_line_wrapper > div {
    background-color: $link-start-to-start;
  }
  .gantt_link_arrow {
    border-color: $link-start-to-start;
  }
}

.gantt_task_link.finish_to_finish {
  .gantt_line_wrapper > div {
    background-color: $link-finish-to-finish;
  }
  .gantt_link_arrow {
    border-color: $link-finish-to-finish;
  }
}

.gantt_task_link.start_to_finish {
  .gantt_line_wrapper > div {
    background-color: $link-start-to-finish;
  }
  .gantt_link_arrow {
    border-color: $link-start-to-finish;
  }
}

// drag and drop css
.gantt-cursor-grabbing {
  cursor: grabbing !important;
}
.gantt_grid_dnd_marker_folder {
  box-shadow: inset 0 0 0 2px $blue-print;
}
.gantt_grid_dnd_marker_line:before {
  @apply border border-blue-print;
}
.gantt_grid_dnd_marker_line {
  @apply bg-blue-print;
}
</style>
