<template>
  <modal
    data-component="confirm"
    v-bind="$props"
    class="modal-confirm mini-modal"
    size="sm"
    :clickAway="false"
    ref="modal"
    :scrollable="modal.choices?.length > 3"
    :zIndex="zIndexComputed"
    :closeable="false"
  >
    <template #header v-if="!modal.choices?.length">
      <div v-if="modal.title">{{ modal.title }}</div>
      <div v-else>Confirm</div>
    </template>

    <template #footer v-if="!modal.choices?.length">
      <btn-group v-if="modal.actions" class="block eq">
        <Btn
          v-for="(action, key) in visibleActions"
          :ref="`btn-${key}`"
          :hotkey="action.hotkey"
          :key="key"
          :severity="action.class"
          size="lg"
          @click="doAction(key)"
        >
          <template v-if="action.icon" #icon>
            <font-awesome-icon :icon="action.icon" />
          </template>
          <div v-text="action.title"></div>
        </Btn>
      </btn-group>
    </template>

    <template #body v-if="!modal.choices?.length">
      <Container>
        <Fade>
          <div v-if="done" class="flex flex-row justify-center items-center">
            <Checkmark class="success my-2 mx-auto" :size="100" />
          </div>
          <div v-else>
            <h4 class="message-body" v-html="modal.message"></h4>
            <h5
              v-if="modal.subMessage && modal.subMessage !== ''"
              class="message-body text-cool sub-message mt-4"
              v-html="modal.subMessage"
            ></h5>
            <div v-if="modal.body && modal.body !== ''" v-html="modal.body"></div>
          </div>
        </Fade>
      </Container>
    </template>
    <template #body v-else>
      <div class="text-xl font-medium text-center w-full mb-12" v-if="modal.message">
        {{ modal.message || '' }}
      </div>

      <div class="flex flex-row w-full flex-wrap gap-4 items-stretch justify-center mb-4 mt-6">
        <template v-for="(choice, index) in modal.choices" :key="choice.value">
          <div
            @click.capture.stop.prevent="doChoice(choice, index)"
            :class="[
              'w-[250px] min-h-[200px]',
              'border-2 border-surface-700',
              'flex flex-col relative leading-tight gap-4',
              'bg-white rounded-lg p-4 text-pitch-black/80 hover:text-level-yellow hover:bg-pitch-black cursor-pointer',
              choice.containerClass ?? ''
            ]"
          >
            <font-awesome-icon v-if="choice.icon" :icon="choice.icon" size="2xl" class="my-2" />

            <template v-if="choice.html">
              <div v-html="choice.html" class="w-full h-full"></div>
            </template>
            <template v-else>
              <span v-if="choice.title" class="font-medium text-lg leading-tight text-center">
                {{ choice.title }}
              </span>

              <span
                v-if="choice.desc"
                class="text-sm text-surface-500 leading-tight whitespace-pre-line"
              >
                {{ choice.desc }}
              </span>
            </template>
          </div>
        </template>
      </div>

      <div class="mb-8 mt-6 flex justify-center items-center">
        <Btn severity="tertiary-borderless" size="2xl" :action="choiceSkip"> Cancel </Btn>
      </div>

      <Loader :loading="loading" />
    </template>
  </modal>
</template>

<script>
import ModalMixin from './ModalMixin'
import Checkmark from '../ui/Checkmark.vue'
import eventBus from '../../eventBus'
import Loader from '@/components/ui/Loader.vue'

/**
 * Provide a Modal object with an actions object,
 * that has an action function that returns a promise.
 */
export default {
  name: 'Confirm',
  data() {
    return {
      done: false,
      loadingChoice: null,
      loading: 0
    }
  },
  mixins: [ModalMixin],
  computed: {
    zIndexComputed() {
      return this.$store.state.modal.topZIndex + 5
    },
    component() {
      return c.titleCase(this.type)
    },
    visibleActions() {
      const filteredActions = () => {
        return Object.entries(this.modal.actions).reduce((accumulator, [key, action]) => {
          if (action.visible()) {
            accumulator[key] = action
          }
          return accumulator
        }, {})
      }

      return this.modal && this.modal.actions ? filteredActions() : {}
    }
  },
  methods: {
    async choiceSkip() {
      await this.modal.choiceSkip(this)
    },
    async doChoice(choice, index) {
      this.loadingChoice = index
      this.loading = 1
      await choice.action(this)
      this.loadingChoice = null
      setTimeout(() => (this.loading = 0), 500)
    },
    doAction(key) {
      this.$refs[`btn-${key}`][0].addLoading()
      const action = this.modal.actions[key]
      const promise = action.action.call(this)
      if (promise && typeof promise.then === 'function') {
        promise.then(() => {
          if (action.setDone) {
            this.done = true
          }
          if (`btn-${key}` in this.$refs) {
            const buttons = c.makeArray(this.$refs[`btn-${key}`])
            if (buttons.length && buttons[0] && buttons[0].endLoading) {
              buttons[0].endLoading()
            }
          }
        })
      } else if (`btn-${key}` in this.$refs) {
        c.makeArray(this.$refs[`btn-${key}`])[0].endLoading()
      }
    },
    confirm() {
      if ('confirm' in this.modal.actions) {
        this.doAction('confirm')
      }
    }
  },
  created() {
    if (!this.modal.choices) eventBus.$once('enter', this.confirm)
  },
  beforeUnmount() {
    if (!this.modal.choices) eventBus.$off('enter', this.confirm)
  },
  props: {
    closeable: {
      default: false
    },
    expandable: {
      default: false
    },
    mask: {
      default: true
    }
  },
  components: {
    Checkmark,
    Loader
  }
}
</script>

<style lang="scss" rel="text/scss">
.modal-confirm {
  .message-body {
    text-align: center;
    white-space: pre-line;
    font-weight: 500;
  }
  .sub-message {
    white-space: pre-line;
  }
}
</style>
