<template>
  <div class="quote-summary--item-list">
    <template v-for="item in items">
      <QuoteSummaryAssembly
        v-if="
          item.type === 'assembly' &&
          (item.assembly_emphasis >= 0 ||
            item.assembly_emphasis === null ||
            presentationSettings.showProductionNotes)
        "
        v-bind="$props"
        :key="item.refId"
        :refId="item.refId"
        :store="store"
        :rootRefId="rootRefId"
        :object="item"
      />
      <QuoteSummaryCostItem
        v-else
        v-bind="$props"
        :key="item.refId"
        :refId="item.refId"
        :store="store"
        :rootRefId="rootRefId"
        :object="item"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'QuoteSummaryItems',
  computed: {
    items() {
      const norm = this.normalized || this.$store.state[this.store].normalized
      return norm[this.refId].aoChildren.map((childRef) => norm[childRef])
    }
  },

  props: {
    normalized: {
      default: null
    },
    refId: {
      required: true
    },
    rootRefId: {
      required: true
    },
    store: {
      default: 'Quote'
    },
    presentationSettings: {
      required: true
    },
    showImages: {
      default: true
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
