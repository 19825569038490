<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    size="md"
    :isDirty="bodyIsDirty"
    :full="false"
    :clickAway="false"
    :expandable="true"
  >
    <template #body>
      <tax-body ref="body" :key="object.tax_id" :object="object" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import TaxBody from '../bodies/Tax.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalTaxNew',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { TaxBody },
  props: {
    type: {
      default: 'tax'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
