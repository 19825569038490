<template>
  <grid
    title="Invoices"
    type="invoice"
    :multiple="true"
    :filters="{
      quote_id: quoteId,
      invoice_status: '!x&&!i&&!d&&!h'
    }"
    :isMain="false"
    :showDefaultFilterPresets="false"
    :order="[['invoice_time_due', 'ASC']]"
    :filterPresets="gridPresets"
  />
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'

export default {
  components: { Grid },
  props: {
    quoteId: {
      type: Number,
      required: true
    }
  },
  computed: {
    gridPresets() {
      return [
        {
          title: 'Outstanding',
          description: 'Invoices that are outstanding.',
          filters: {
            invoice_status: 'o',
            quote_id: this.quoteId
          }
        },
        {
          title: 'Pending',
          description: 'Invoices where the payment is processing.',
          filters: {
            invoice_status: 'p||pr',
            quote_id: this.quoteId
          }
        },
        {
          title: 'Paid',
          description: 'Invoices that have been paid.',
          filters: {
            invoice_status: 'e',
            quote_id: this.quoteId
          }
        }
      ]
    }
  }
}
</script>
