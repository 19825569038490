<template>
  <div class="mt-0 md:mt-8" v-if="companyType === 'soleProprietorship'">
    <p class="mb-4">
      Provide <b>all controlling persons and owners</b> holding 25% or more of your company.
    </p>
    <template v-if="owners.length > 0">
      <div class="individual py-3" v-for="(owner, k) in owners" :key="owner.id">
        <div class="flex justify-between py-2">
          <div class="flex">
            <p class="flex items-center">
              <b>{{ owner.first_name }} {{ owner.last_name }}</b>
            </p>
            <span>
              <Chip
                class="ml-3 !bg-matcha-500 !text-flame-white"
                label="owner"
                v-if="owner.relationship && owner.relationship.owner"
              />
              <Chip
                class="ml-1 !bg-blue-print-400 !text-flame-white"
                label="controller"
                v-if="owner.relationship && owner.relationship.executive"
              />
            </span>
          </div>
          <span>
            <a @click="() => editOwner(k)" class="text-info mr-2">
              <u>Edit</u>
            </a>
            <a
              v-if="owners && owners.length > 1 && !owner.primary"
              @click="() => removeOwner(k)"
              class="text-info"
            >
              <u>Remove</u>
            </a>
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="owners.length === 0"
      class="border text-center rounded border-cool-gray-300 p-4 my-4"
    >
      <p class="text-muted">Company must have at least one controlling person.</p>
    </div>
    <div
      v-if="
        (!isPayfacEnabled && owners.length < 1) ||
        (!isOrganization && owners.length < 1) ||
        isOrganization
      "
      class="flex justify-end mt-4 mb-5"
    >
      <Btn v-tooltip="'Add new decision maker'" class="dark round sm" @click="addIndividual">
        <font-awesome-icon icon="plus" />
        Add decision-maker
      </Btn>
    </div>
  </div>
</template>
<script>
import PayfacMixin from '../../PayfacMixin'
import Chip from 'primevue/chip'

export default {
  name: 'CounterpartyOwners',
  props: {
    companyType: {
      type: String,
      default: 'soleProprietorship'
    },
    owners: {
      type: Array
    },
    isOrganization: {
      type: Boolean,
      default: true
    }
  },
  mixins: [PayfacMixin],
  emits: ['showOwnerOnboarding', 'removed'],
  data() {
    return {
      removed: []
    }
  },
  methods: {
    /**
     * Add an individual show owner onboarding
     */
    addIndividual() {
      let data = {
        owner: {}
      }
      if (this.owners.length === 0) {
        const session = this.$store.state.session
        data = {
          owner: {
            email: session.user.user_email,
            first_name: session.user.user_fname,
            last_name: session.user.user_lname,
            id_number: session.user.id_number || '',
            dob: {
              day: null,
              month: null,
              year: null
            },
            relationship: {
              percent_ownership: 100
            },
            address: {
              line1: session.user.user_address,
              city: session.user.user_city,
              state: session.user.province_abbr,
              country: session.user.user_country,
              postal_code: session.user.user_postal
            }
          }
        }
      }
      this.$emit('showOwnerOnboarding', data)
    },
    /**
     * Remove an owner from the organization
     * @param {number} k
     */
    removeOwner(k) {
      this.removed.push(this.owners[k])
      //this.owners.splice(k, 1);
      this.$emit('removed', {
        index: k,
        removed: this.removed
      })
    },
    /**
     * Edit a owner
     * @param {number} k
     */
    editOwner(k) {
      this.$emit('showOwnerOnboarding', {
        owner: this.owners[k],
        index: k
      })
    },
    onDocumentVerified() {}
  },
  components: {
    Chip
  }
}
</script>

<style lang="scss" scoped>
.individual {
}
.individuals {
  > div.individual {
    border-top: solid 1px $cool-gray-300;
  }
}
</style>
