<template>
  <section class="mt-0 md:mt-8" v-if="localTerms">
    <Card :flat="true">
      <div class="flex">
        <Checkbox v-model="localTerms.acceptCCTerms" @change="updateTerms" />
        <span class="ml-4">
          I have read and agree to the Bolster Built Inc.
          <a
            target="_blank"
            class="text-info text-blue-500 hover:underline"
            href="https://costcertified.cc/pub/legal/msa"
          >
            Master Service Agreement</a
          >
        </span>
      </div>
    </Card>
    <Card class="mt-4" :flat="true">
      <div class="flex">
        <Checkbox v-model="localTerms.acceptPayFacTerms" @change="updateTerms" />
        <span class="ml-4">
          I understand I am entering into a direct contractual relationship with Adyen N.V. for the
          delivery of the payment services through Bolster and confirm to have taken notice of and
          to accept the following terms and restrictions:
          <ul>
            <li>
              AfP Terms and Conditions (click
              <a
                target="_blank"
                class="text-info text-blue-500 hover:underline"
                href="https://www.adyen.com/legal/terms-and-conditions-marketpay"
                >here</a
              >
              to review)
            </li>
            <li>
              Adyen Prohibited and Restricted Products and Services list (click
              <a
                target="_blank"
                class="text-info text-blue-500 hover:underline"
                href="https://www.adyen.com/legal/list-restricted-prohibited"
                >here</a
              >
              to review)
            </li>
          </ul>
        </span>
      </div>
    </Card>
  </section>
</template>

<script>
export default {
  name: 'CounterpartyTerms',
  data() {
    return {
      localTerms: { ...this.terms }
    }
  },
  props: {
    terms: {
      type: Object
    }
  },
  methods: {
    updateTerms() {
      this.$emit('update:terms', this.localTerms)
    }
  }
}
</script>

<style lang="scss" scoped></style>
