<template>
  <Section class="!mb-0">
    <CardSection>
      <template #title>General settings</template>
      <CardList>
        <CardListField :mobileStack="true">
          <span
            >Allow your users to see public items from the item selector in the quote view?
          </span>
          <div class="pr-3">
            <MetaToggle
              :onValue="0"
              :offValue="1"
              :process="(val) => val || 0"
              name="HidePublicItems"
            >
              Allowed to see public items
            </MetaToggle>
          </div>
        </CardListField>
        <CardListField :mobileStack="true">
          <span>
            <Help>
              <template #before>
                Show the "Request revision" button on quote presentations.
              </template>

              <p>
                Rather than lose a job because something is missing or something is not as your
                customer would like it, allow your customers to request a revision for you.
              </p>

              <p>
                The Request Revision button is enabled by default. It is recommended to prevent
                losing jobs you could otherwise win.
              </p>

              <p>
                When your customer requests a revision, the proposal (or booked project) will go
                into the "Needs your approval" category in the pipeline, and you will get a
                notification email with the changes requested by your customer.
              </p>

              <p>
                You can also see the change request message by opening up the proposal, and going to
                the "Changes" tab and looking at the latest change order/version to see the message
                from your customer about what they would like to change.
              </p>
            </Help>
          </span>

          <div class="pr-3">
            <MetaToggle
              :onValue="0"
              :offValue="1"
              :process="(val) => val || 0"
              name="HideRequestRevisionButton"
            >
              Allowed to request revisions
            </MetaToggle>
          </div>
        </CardListField>
        <CardListField :mobileStack="true">
          <span>
            <Help>
              <template #before> Show Costcertified global library items. </template>

              <p>
                The CostCertified library offers a comprehensive, pre-curated catalog of generic
                assemblies and items, readily available for use in your projects.
              </p>
            </Help>
          </span>

          <div class="pr-3">
            <MetaToggle
              :onValue="1"
              :offValue="0"
              :process="(val) => val || 0"
              name="EnableCostcertifiedLibrary"
            >
              Enable Costcertified library
            </MetaToggle>
          </div>
        </CardListField>
        <CardListField :mobileStack="true">
          <span>
            Default AutoCost location
            <small> New estimates will default to this location for AutoCost live pricing. </small>
          </span>
          <div class="pr-3">
            <choose
              v-model="default_live_price_zipcode"
              :allowCreate="false"
              :allowDeselect="false"
              :forceNotEmpty="true"
              :staticSet="[
                {
                  text: 'Project location',
                  value: 'project'
                },
                {
                  text: 'Company location',
                  value: 'company'
                }
              ]"
            />
          </div>
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection class="!mt-20">
      <template #title>
        Default profit
        <Help class="text-xs ml-2">
          <template #title> Understanding default and minimum profit </template>
          <CompanyMarkupHelp />
        </Help>
      </template>
      <CompanyMarkupAdjuster :object="this.$store.state.session.company" />
    </CardSection>

    <CardSection class="!mt-20">
      <template #title>Presentation settings</template>
      <card-list>
        <card-list-item>
          <span class="flex flex-col gap-y-2 mb-4">
            Company-wide terms & conditions
            <small class="font-light text-surface-500">
              Set terms and conditions here that apply to every proposal. Your client will need to
              accept these terms in order to accept your quote/estimate/proposal.
            </small>
            <small class="text-info font-light"
              >You can also add terms and conditions to your proposal if some terms apply to some
              proposals and not others. Separate each term with a new line. Add sections/headings
              with square brackets:
            </small>
            <small>
              <pre>[My heading]<br/> My term one<br/> My term two<br/>[My other heading]<br/> Other terms.</pre>
            </small>
          </span>
          <field
            v-model="company_terms_and_conditions"
            class="m-0 !w-full !h-full !p-2"
            type="textarea"
          />
        </card-list-item>
      </card-list>
    </CardSection>
  </Section>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import CompanyMarkupAdjuster from '@/components/company/CompanyMarkupAdjuster.vue'
import CompanyMarkupHelp from '../company/CompanyMarkupHelp.vue'
import eventBus from '../../eventBus'

import MetaToggle from '../ui/fields/MetaToggle.vue'
MetaToggle.compatConfig = { MODE: 3 }

export default {
  name: 'CompanyEstimatingSettings',

  mixins: [ObjectManipulator('company', false /* track changes? */), BodyMixin /* AutoSave */],

  components: {
    MetaToggle,
    CompanyMarkupHelp,
    CompanyMarkupAdjuster
  },

  props: {
    deselectOnDestroy: {
      default: false
    }
  },

  watch: {
    modules(a, b) {
      c.throttle(
        () => {
          if (a && Object.keys(a).length && b && Object.keys(b).length) {
            if (a.presentation !== b.presentation && !a.presentation) {
              this.$store.dispatch('Company/turnModuleOff', { moduleName: 'presentation' })
            } else if (a.presentation !== b.presentation && a.presentation) {
              this.$store.dispatch('Company/turnModuleOn', { moduleName: 'presentation' })
            }
          }
        },
        { delay: 3000 }
      )
    }
  },

  computed: {
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    currentCompany() {
      if (this.$store.state.session.scope.company && this.$store.state.session.company) {
        return this.$store.state.session.company
      }

      return {
        company_minimum_quote_margin: this.$store.state.session.suggestedMinimumMargin,
        company_default_markup: this.$store.state.session.suggestedDefaultMarkup
      }
    },
    minMargin: {
      get() {
        return this.company_minimum_quote_margin * 100
      },
      set(v) {
        this.company_minimum_quote_margin = v / 100
      }
    },
    togglePresentations: {
      get() {
        return this.modules.presentation
      },
      set(b) {
        if (
          this.modules &&
          ((!this.modules.presentation && b) || (this.modules.presentation && !b))
        ) {
          this.modules = {
            ...this.modules,
            presentation: b ? 1 : 0
          }
        }
      }
    },
    changeMinimumMarkup: {
      get() {
        return 1 / (1 - Number(this.company_minimum_quote_margin))
      },
      set(val) {
        this.company_minimum_quote_margin = 1 - 1 / Number(val)
      }
    },
    minMin() {
      return Math.round(Math.min(1, this.currentMinimumMarkup - 0.2) * 100) / 100
    },
    minMax() {
      return Math.round(Math.max(this.currentMinimumMarkup * 1.2, 2) * 100) / 100
    },
    currentMinimumMarkup() {
      return 1 / (1 - Number(this.currentCompany.company_minimum_quote_margin, 20))
    }
  },

  methods: {
    getPercentOfRange(f, min, max) {
      return ((f - min) / (max - min)) * 100
    }
  },

  created() {
    eventBus.$on(`${this.uid}-selected`, () => {
      this.togglePresentations = 1
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
