<template>
  <Container>
    <CardSection>
      <div class="flex flex-row items-center gap-x-3 !mb-0">
        <font-awesome-icon icon="users" class="text-lg" />
        <p class="text-xl">Invite your team</p>
      </div>

      <template v-if="!loading">
        <div
          v-if="users.length"
          class="flex flex-col gap-3 p-8 rounded shadow bg-flame-white dark:bg-surface-900 text-surface-700 dark:text-surface-0 h-full transition border border-surface-200 shadow-surface-200/30"
        >
          <div
            v-for="(user, index) in users"
            :key="index"
            class="flex flex-row items-center justify-between"
          >
            <div class="flex flex-row items-center gap-x-2">
              <font-awesome-icon
                icon="trash"
                class="text-xl hover:cursor-pointer"
                @click.native="removeInvite(index)"
              />
              <Field
                format="email"
                schema="user:user_email"
                v-model="user.email"
                placeholder="Email address"
              />
            </div>

            <Choose
              :customOptions="[
                {
                  text: 'Administrator (full permissions)',
                  html: '<font-awesome-icon icon=\'key\' />&nbsp;&nbsp; Administrator (full permissions)',
                  value: 'admin'
                }
              ]"
              :multiple="true"
              :allowDeselect="false"
              v-model="user.roles"
              :validate="{ required: true }"
              placeholder="Choose roles"
              :highlightIfEmpty="true"
              :return-array="true"
              schema="role:role_id"
            />
          </div>
        </div>
      </template>

      <Info v-else>
        <template #icon>
          <LoadingIndicator />
        </template>
        <template #title> Inviting your team.. </template>
        <p>This may take a few minutes..</p>
      </Info>

      <div class="flex justify-between">
        <div
          class="flex flex-row items-center gap-x-2 py-3 rounded cursor-pointer text-cement-900 hover:text-cement-800 transition"
          @click="addUser"
        >
          <font-awesome-icon icon="plus" />
          <p class="select-none">Add another user</p>
        </div>
        <btn
          severity="tertiary"
          size="lg"
          @click="inviteUsers"
          v-if="showSubmit"
          :disabled="!validUsers.length"
        >
          Send {{ validUsers.length > 1 ? validUsers.length : '' }} invitation{{
            validUsers.length > 1 ? 's' : ''
          }}...
        </btn>
      </div>
    </CardSection>
  </Container>
</template>

<script>
import Button from '../mixins/Button'
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import eventBus from '../../eventBus'

export default {
  name: 'InviteUsers',
  components: { LoadingIndicator },
  mixins: [Button],

  props: {
    showSubmit: {
      default: true
    }
  },
  emits: ['save', 'save-user', 'closeModal', 'close', 'saved', 'saved-user'],
  data() {
    return {
      users: [
        {
          email: '',
          roles: []
        }
      ]
    }
  },

  computed: {
    validUsers() {
      return this.users.filter((u) => u.email && u.roles.length)
    }
  },

  methods: {
    removeInvite(index) {
      const users = _.imm(this.users)
      users.splice(index, 1)
      this.users = users
    },
    async submit() {
      return this.inviteUsers()
    },
    async inviteUsers() {
      this.addLoading()
      try {
        this.$emit('save')
        this.$emit('save-user')
        eventBus.$emit('save')
        eventBus.$emit('save-user')
        await this.$store.dispatch('Company/inviteUsers', {
          id: this.$store.state.session.company.company_id,
          users: this.validUsers
        })
        console.log('emitting closeModal')
        this.$emit('saved')
        this.$emit('saved-user')
        eventBus.$emit('saved')
        eventBus.$emit('saved-user')
        this.$emit('closeModal')
        this.$emit('close')
      } finally {
        this.removeLoading()
        this.users = []
        this.addUser()
      }
    },
    addUser() {
      this.users = [
        ...this.users,
        {
          email: '',
          roles: []
        }
      ]
    }
  }
}
</script>
