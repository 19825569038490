<template>
  <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
/* eslint-disable */
export default {
  name: 'TransitionExpand',
  setup() {
    // ===== Methods ===== //
    const enter = (element) => {
      element.style.width = getComputedStyle(element).width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      // Force repaint.
      getComputedStyle(element).height

      // Trigger the animation.
      requestAnimationFrame(() => {
        element.style.height = height
      })
    }
    const afterEnter = (element) => {
      element.style.height = 'auto'
    }
    const leave = (element) => {
      // eslint-disable-next-line
      element.style.height = getComputedStyle(element).height

      // Force repaint.
      // eslint-disable-next-line
      getComputedStyle(element).height

      requestAnimationFrame(() => {
        // eslint-disable-next-line
        element.style.height = 0
      })
    }

    return {
      enter,
      afterEnter,
      leave
    }
  }
}
/* eslint-enable */
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
* {
  will-change: height;
  backface-visibility: hidden;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 0.25s ease-in-out;
  transition-property: opacity, height;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
