<template>
  <div class="addon-item--container" style="cursor: pointer" @click.stop.prevent="openSettings()">
    <template v-if="(name || addon.bulk || loading) && !disabled">
      <div class="addon-title">
        <a class="addon-name" :key="_uid" :id="`${_uid}-name`" v-tooltip="'Upgrade settings..'">
          {{ $f.truncate(name || 'Original', 30) }}

          <spinner :loading="1" v-if="loading" size="1em" />
        </a>

        <MiniModal ref="settings" class="help-card">
          <container size="2xl">
            <card-list class="mb-2" v-if="dimensionsRequired.length">
              <card-list-item
                :class="
                  missingDimension.length && (store || storeName) !== 'CostType' ? 'danger' : ''
                "
                class="left text-left"
                style="align-items: flex-start; font-weight: normal"
              >
                <p>
                  This option requires <strong>{{ (parentName || '').toLowerCase() }}</strong> to
                  have the following dimensions set and available:
                </p>
                <ul>
                  <li
                    v-for="(dim, index) in dimensionsRequired"
                    :key="index"
                    class="p-1"
                    style="border-radius: 0.25em"
                    :class="
                      (store || storeName) !== 'CostType' &&
                      (!dimensionsUsed[dim.abbr] || !dimensionsUsed[dim.abbr].value)
                        ? 'bg-danger'
                        : ''
                    "
                  >
                    <DimensionBadge class="my-1" :dim="dim" />
                    <small
                      class="text-light ml-2"
                      v-if="
                        (store || storeName) !== 'CostType' &&
                        (!dimensionsUsed[dim.abbr] || !dimensionsUsed[dim.abbr].value)
                      "
                    >
                      <strong>No value yet!</strong>
                    </small>
                    <small class="ml-2" v-else-if="dimensionsUsed[dim.abbr]">
                      <font-awesome-icon icon="check" class="text-success" />
                      <strong v-if="dimensionsUsed[dim.abbr].measure === 'ft'">{{
                        $f.imperial(dimensionsUsed[dim.abbr].value)
                      }}</strong>
                      <strong v-else
                        >{{ $f.currency(dimensionsUsed[dim.abbr].value) }}
                        {{ dimensionsUsed[dim.abbr].measure }}</strong
                      >
                    </small>
                  </li>
                </ul>
              </card-list-item>
            </card-list>

            <card-list>
              <card-list-item>
                <small class="text-info">
                  Overriding values here will only affect this item when and if it is chosen as an
                  alternative to
                  <strong>{{ target.cost_type_name || target.assembly_name || '' }}</strong
                  >.
                </small>
              </card-list-item>
              <card-list-field>
                <span>
                  <help>
                    <template #before> Quantity equation</template>
                    <template #title> Quantity equation</template>

                    <p v-if="compatibleMeasures">
                      By default, the quantity used for this upgrade is determined by the quantity
                      of
                      <span class="text-info">{{
                        target.cost_type_name || target.assembly_name
                      }}</span>
                      <span class="text-info">({{ targetQuantity }} {{ uomUsed }})</span>, since it
                      and the upgrade both use
                      <span class="text-info">{{ targetMeasure }} measures</span>.
                    </p>
                    <p>You can override the quantity below by:</p>
                    <ul>
                      <li>
                        setting a static quantity by entering a number to represent the quantity
                        used by the upgrade item (not recommended)
                      </li>
                      <li>
                        add an equation that draws from a dimension
                        <span class="text-info">(recommended)</span>
                      </li>
                    </ul>
                    <p>
                      To add a dimension, double click on the "Override quantity equation" field, or
                      click on the field, then click on the calculator button that appears beside
                      the field to select a dimension.
                    </p>
                  </help>

                  <btn
                    class="xs mt-2 danger round"
                    v-if="
                      'assembly_qty_equation' in addon.embue ||
                      'cost_type_qty_equation' in addon.embue ||
                      'quote_qty_net_base' in addon.embue ||
                      'cost_item_qty_net_base' in addon.embue
                    "
                    @click="resetQty"
                  >
                    Reset
                  </btn>
                </span>

                <field
                  type="calculator"
                  schema="cost_item:cost_item_qty_net_base"
                  :value="qtyUsed"
                  :dimensions="addon.type === 'assembly' ? {} : parentDimensions"
                  format="number"
                  :equation="overrideEquation"
                  @input="
                    (v, eq) => {
                      if (v && !eq) {
                        overrideEquation = null
                        overrideQty = v
                      }
                    }
                  "
                  @equation="(eq) => (overrideEquation = eq)"
                  :measure="uomIdUsed"
                  ref="qtyField"
                />
              </card-list-field>

              <card-list-item
                v-if="
                  overrideEquation !==
                  (object.assembly_qty_equation || object.cost_type_qty_equation)
                "
              >
                <btn
                  class="sm default round"
                  @click="
                    overrideEquation = object.assembly_qty_equation || object.cost_type_qty_equation
                  "
                >
                  Set qty to
                  {{
                    $f.truncate(
                      (object.cost_type_name || object.assembly_name || '').toLowerCase(),
                      25
                    )
                  }}
                  saved equation
                  <span class="code bg-dark text-light p-1 ml-1 text-monospace">{{
                    object.assembly_qty_equation ||
                    object.cost_type_qty_equation ||
                    (object.asDimensionsLinked || []).join('')
                  }}</span>
                </btn>
              </card-list-item>

              <card-list-field>
                <span>
                  Override markup
                  <btn
                    class="xs mt-2 danger round"
                    v-if="hasOverridenMarkup"
                    @click="setOverrideMarkup(null)"
                  >
                    Reset
                  </btn>
                </span>

                <field
                  type="calculator"
                  schema="cost_matrix:cost_matrix_markup_net"
                  v-model="overrideMarkup"
                  format="number"
                  ref="qtyField"
                />
              </card-list-field>

              <card-list-field>
                <span>
                  Override upgrade name

                  <btn
                    class="xs mt-2 danger round"
                    v-if="'cost_type_name' in addon.embue || 'assembly_name' in addon.embue"
                    @click="overrideName = null"
                  >
                    Reset
                  </btn>
                </span>

                <field schema="cost_type:cost_type_name" v-model="overrideName" />
              </card-list-field>

              <card-list-field>
                <span>
                  Override upgrade description
                  <btn
                    class="xs mt-2 danger round"
                    v-if="'cost_type_desc' in addon.embue || 'quote_notes' in addon.embue"
                    @click="overrideDesc = null"
                  >
                    Reset
                  </btn>
                </span>

                <field type="textarea" schema="cost_type:cost_type_desc" v-model="overrideDesc" />
              </card-list-field>

              <card-list-field>
                <span> Rating recommendation </span>

                <Choose
                  v-model="rating"
                  :return-array="false"
                  :static-set="[
                    {
                      text: 'None',
                      value: null
                    },
                    {
                      text: 'Good',
                      value: 1
                    },
                    {
                      text: 'Better',
                      value: 2
                    },
                    {
                      text: 'Best',
                      value: 3
                    },
                    {
                      text: 'Recommended',
                      value: 4
                    }
                  ]"
                />
              </card-list-field>
            </card-list>

            <btn class="round block info my-4" :action="edit" :loading="loading">
              Edit saved version of {{ $f.truncate(addon.name || '', 25) }}..
            </btn>
          </container>
        </MiniModal>

        <div class="addon-desc" v-if="desc" v-text="desc"></div>

        <div class="addon-dimensions">
          <template v-if="dimensionsRequired.length">
            <Btn static class="xs static mb-1">
              Priced based on dimensions from assembly/project
            </Btn>
            <DimensionBadge
              :dim="dim"
              class="mb-1 mr-1 mt-1"
              style="font-size: 0.7em"
              :key="dim.abbr"
              v-for="dim in dimensionsRequired"
            />
          </template>

          <Btn
            static
            class="xs static mb-1"
            v-if="
              !dimensionsRequired.length &&
              !('cost_item_qty_net_base' in addon.embue || 'quote_qty_net' in addon.embue)
            "
          >
            Priced based on original item qty ({{ $f.number(qtyUsed) }} {{ uomUsed }})
          </Btn>
          <Btn
            static
            class="xs static mb-1"
            v-if="'cost_item_qty_net_base' in addon.embue || 'quote_qty_net' in addon.embue"
          >
            Priced based on fixed overridden qty ({{ $f.number(qtyUsed) }} {{ uomUsed }})
          </Btn>
        </div>
      </div>

      <div class="addon-pic">
        <file-list
          :allowCreate="false"
          :allowAdd="false"
          :allow-dragging="false"
          :allowUpload="false"
          :allowRemove="false"
          :allowEdit="fileIds.length > 0"
          :value="picFile"
        />
      </div>

      <div class="addon-price">
        <btn
          rounded
          class="more px-0 p-2 danger xl"
          @click="openSettings()"
          v-if="missingDimension.length && (store || storeName) !== 'CostType'"
        >
          <font-awesome-icon icon="warning" />
        </btn>
        <Btn rounded class="more px-0 p-2 xl" @click="openSettings()" v-else>
          <font-awesome-icon icon="ellipsis" />
        </Btn>

        <Btn static class="round static sm mt-2" v-if="(store || storeName) !== 'CostType'">
          <font-awesome-icon icon="arrow-up" v-if="priceDifference > 0" />
          <font-awesome-icon icon="arrow-down" v-else-if="priceDifference < 0" />
          &nbsp;
          {{ $f.currency(priceDifference) }}
        </Btn>
        <Btn static class="round static sm mt-2" v-else>
          <font-awesome-icon icon="arrow-up" v-if="priceDifferenceUnit > 0" />
          <font-awesome-icon icon="arrow-down" v-else-if="priceDifferenceUnit < 0" />
          &nbsp;
          {{ $f.currency(priceDifferenceUnit) }}/{{ uomUsed }}
        </Btn>
      </div>
    </template>

    <spinner v-else-if="!disabled" :loading="1" />

    <span v-else>Item no longer exists, option will not be available to client.</span>
  </div>
</template>

<script>
import AddonItemMixin from './AddonItemMixin'
import DimensionBadge from '../../ui/DimensionBadge.vue'

export default {
  name: 'AddonItem',
  mixins: [AddonItemMixin],
  emits: ['replace'],
  watch: {},
  computed: {},
  data() {
    return {
      loading: 0
    }
  },
  methods: {
    async edit() {
      this.loading = 1

      const type = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      const { set } = await this.$store.dispatch('edit', {
        type,
        id: this.addon.id,
        go: false,
        modal: {
          oMod: await this.$store.dispatch('Quote/getQuoteMod', {
            refId: this.refId,
            store: this.store
          })
        }
      })

      if (set && set.length > 0) {
        const addOn = set[0]
        this.addon.id = type === 'assembly' ? addOn.assembly_id : addOn.cost_type_id
      }
      const { object } = await this.$store.dispatch(`${_.titleCase(type)}/fetch`, {
        id: this.addon.id
      })

      this.$emit('replace', object)

      await this.reload()

      this.loading = 0

      return this
    }
  },
  components: { DimensionBadge },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.addon-item--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 2em);
  width: calc(100% - 2em);

  .addon-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 50%;
    flex: 0 0 50%;
    overflow: hidden;
  }
  .addon-name {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $blue-print-700 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .addon-desc {
    overflow: hidden;
    max-width: 100%;
    font-size: 0.7em;
    color: $cool-gray-600;
    white-space: pre-wrap;
  }

  .addon-type {
    text-align: center;
    font-size: 0.4em;
    color: $cool-gray-600;
    margin-left: auto;
    .icon {
      display: block;
      font-size: 2em;
    }
  }
  .addon-price {
    min-width: 6em;
    margin-left: 1em;
    font-size: 0.7em;
    font-weight: bold;
    color: $matcha-500;
    text-align: right;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .addon-dimensions {
    margin-top: 0.5em;
  }
}
</style>
