<template>
  <div
    class="field-unique input-container no-format flex !flex-col !items-start"
    :class="validateClass"
  >
    <span class="relative w-full">
      <Loader v-if="found || checking" :loading="found || checking" />
      <Field
        v-bind="$props"
        ref="field"
        class="px-4"
        @change="(args) => $emit('change', args)"
        @dblclick.stop="(args) => $emit('dblclick', args)"
        @touchstart.stop="(args) => $emit('touchstart', args)"
        @click="(args) => $emit('click', args)"
        @focusin="(args) => $emit('focus', args) && $emit('focusin', args)"
        @focusout="(args) => $emit('blur', args) && $emit('focusout', args)"
        @keyup="(args) => $emit('keyup', args)"
        @keypress="(args) => $emit('keypress', args)"
        @input="(args) => $emit('input', args)"
        @validation="validationHandler"
        :emit-delay="200"
      />
    </span>
    <div class="suggestions text-sm pt-2">
      <slot :found="found" v-if="found">
        This {{ fieldTitle }} is already taken, try another one.
      </slot>
    </div>
  </div>
</template>

<script>
import FieldMixin from '../mixins/Field'
import Field from './Field.vue'

export default {
  name: 'UniqueField',
  emits: [
    'change',
    'dblclick',
    'touchstart',
    'click',
    'focus',
    'focusin',
    'focusout',
    'blur',
    'keyup',
    'keypress',
    'input',
    'validation'
  ],

  created() {},

  props: {
    ...FieldMixin.props,
    ...Field.props,

    /**
     * {
     *   type: 'user,
     *   field: 'user_email',
     *   except: [234],
     *   exact: true
     *   process: val => val, // alter the value to check
     * }
     */
    unique: {
      required: true,
      type: Object
    }
  },

  watch: {
    validationObject(obj) {
      this.$emit('validation', obj)
    },
    value(v) {
      this.$emit('blur')
      this.validationHandler({})
      this.checkUnique(v)
    },
    unique: {
      handler() {
        this.checkUnique(this.value, true)
      },
      deep: true
    }
  },

  methods: {
    focus() {
      this.$refs.field.focus()
    },
    validationHandler(value) {
      if (!Object.keys(value).length) return
      this.lastValidation = value

      if (!Object.keys(value).length || (value.valid && !value.empty)) {
        c.throttle(
          () => {
            this.checkUnique(value.value)
          },
          { delay: 750 }
        )
      }
    },

    async checkUnique(value, force = false) {
      this.search(value, force)
    },

    async search(rawValue, force = false) {
      const trimmed = String(rawValue || '').trim()
      const processor = this.unique.process || ((val) => val)
      const value = processor(trimmed)
      if (!rawValue || (this.lastSearched === value && !force)) return

      this.latestValue = value
      this.clear = true
      this.found = null

      this.checking = 1

      const exact =
        typeof this.unique.exact !== 'undefined' && this.unique.exact !== null
          ? !!this.unique.exact
          : true

      const type = this.unique.type
      const titleType = _.titleCase(type)

      const filters = {}

      const except = this.unique.except && c.makeArray(this.unique.except)
      const exceptField = `${type}_id`
      if (except && except.length) {
        filters[`${type}_id`] = `!${except.join('&&!')}`
      }

      filters[this.unique.field] = exact ? value : `LIKE${value}`
      this.lastChecked = value

      // hotwire scope variables
      filters[`${type}_status`] = '!NULL'
      if (exceptField !== 'company_id' || !(except && except.length)) {
        filters.company_id = '!NULL'
      }
      if (exceptField !== 'user_id' || !(except && except.length)) {
        filters.user_id = '!NULL'
      }

      this.clear = true
      this.found = null

      this.lastSearched = value
      const { set } = await this.$store.dispatch(`${titleType}/search`, {
        filters,
        scope: /company|user/.test(type) ? { public: 1 } : {},
        limit: 1
      })
      this.checking = 0

      if (this.latestValue === value) {
        this.clear = !set.length
        this.found = this.clear ? null : set[0]
      }
    }
  },

  data() {
    return {
      latestValue: null,
      clear: true,
      found: null,
      lastValidation: {},
      checking: 0,
      lastSearched: null
    }
  },

  computed: {
    fieldSchema() {
      return this.schema
    },
    fieldTitle() {
      return c.getFieldTitle(this.unique.field).toLowerCase()
    },
    typeTitle() {
      return this.unique.type.replace('_', ' ')
    },
    validateClass: FieldMixin.computed.validateClass,
    validationObject() {
      const obj = {
        ...this.lastValidation
      }

      if (!this.clear && this.found) {
        const type = this.typeTitle
        const fieldName = this.fieldTitle
        obj.message = `A ${type} already exists with that ${fieldName}.`
        obj.valid = false
        obj.empty = false
      }

      return obj
    },
    required() {
      return this.validationObject.required
    },
    notEmpty() {
      return typeof this.validationObject.empty !== 'undefined' && !this.validationObject.empty
    },
    valid() {
      return typeof this.validationObject.valid === 'undefined' || this.validationObject.valid
    }
  },

  components: {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.field-unique {
  padding: 0 !important;

  .field-component {
    width: 100% !important;
  }

  .suggestions {
    color: $deep-red-800;
  }
}
</style>
