<template>
  <ListItem classes="!mb-0" :condensed="true" :table="true">
    <div class="w-full grid grid-cols-1 gap-2 md:gap-2 md:grid-cols-8 p-4 md:p-0">
      <div
        v-tooltip="item.item_name"
        class="w-full flex flex-col md:flex-row md:items-center col-span-1 md:col-span-4"
      >
        <label class="block md:hidden mb-2">Name:</label>
        <Field
          v-tooltip="item.item_name"
          :disabled="true"
          :pt="{ root: '!w-full md:border-0' }"
          v-model="item.item_name"
        />
      </div>
      <div class="col-span-1 flex flex-col md:flex-row justify-center md:items-center col-span-2">
        <label class="block md:hidden mb-2">Status:</label>
        <Choose
          class="w-full"
          placeholder="Not ordered"
          btnClass="md:border-0 justify-between w-full md:!rounded-none hover:md:!bg-cement-300"
          v-model="item.cost_item_materials_status"
          @input="(status) => setStatus(status)"
          :staticSet="
            materialStatuses.map((s) => ({
              text: s.label,
              value: s.status,
              icon: s.icon
            }))
          "
        />
      </div>
      <div class="flex flex-col md:flex-row md:items-center md:justify-center col-span-2">
        <label class="block md:hidden mb-2">Payment status:</label>
        <span class="flex items-center">
          <label class="mr-2 !font-normal" for="purchased">{{
            item.cost_item_is_materials_purchased ? 'Paid' : 'Unpaid'
          }}</label>
          <InputSwitch
            inputId="purchased"
            v-model="item.cost_item_is_materials_purchased"
            @update:modelValue="(v) => setIsPurchased(v ? 1 : 0)"
            v-tooltip="`Mark as ${item.cost_item_is_materials_purchased ? 'paid' : 'unpaid'}`"
          />
        </span>
      </div>
    </div>
  </ListItem>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'
import InputSwitch from 'primevue/inputswitch'
import ListItem from './ListItem.vue'
import useTask from '@/components/schedule/Task'
import { materialStatuses } from '@/components/schedule/Task'

InputSwitch.compatConfig = { MODE: 3 }

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { item } = toRefs(props)

const { forceSaveItem } = useTask()

const setIsPurchased = (val) => {
  const changes = {
    cost_item_is_materials_purchased: val
  }
  return forceSaveItem({
    id: item.value.id || item.value.item_id,
    changes
  })
}

const setStatus = (status) => {
  const changes = {
    cost_item_materials_status: status || 'o'
  }
  return forceSaveItem({
    id: item.value.id || item.value.item_id,
    changes
  })
}
</script>
