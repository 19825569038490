import {
  SET_CLIENT_RATING_DATA,
  SET_CONTRACTOR_RATING_DATA,
  UPDATE_CLIENT_OWN_RATINGS,
  UPDATE_CONTRACTOR_OWN_RATINGS,
  UPDATE_CLIENT_RATINGS_BY_OTHERS,
  UPDATE_CONTRACTOR_RATINGS_BY_OTHERS,
  UPDATE_OUTSTANDING_CLIENT_PROJECTS,
  UPDATE_OUTSTANDING_CONTRACTOR_PROJECTS
} from '@/store/mutation-types'

const state = () => ({
  clientData: {},
  contractorData: {}
})

const mutations = {
  SET_CLIENT_RATING_DATA(state, { clientId, data }) {
    state.clientData[clientId] = data
  },
  SET_CONTRACTOR_RATING_DATA(state, { contractorId, data }) {
    state.contractorData[contractorId] = data
  },
  UPDATE_CLIENT_OWN_RATINGS(state, { clientId, ownRating }) {
    if (state.clientData[clientId]) {
      state.clientData[clientId].own = ownRating
    }
  },
  UPDATE_CONTRACTOR_OWN_RATINGS(state, { clientId, ownRating }) {
    if (state.contractorData[clientId]) {
      state.contractorData[clientId].own = ownRating
    }
  },
  UPDATE_CLIENT_RATINGS_BY_OTHERS(state, { clientId, byOthersRating }) {
    if (state.clientData[clientId]) {
      state.clientData[clientId].byOthers = byOthersRating
    }
  },
  UPDATE_CONTRACTOR_RATINGS_BY_OTHERS(state, { clientId, byOthersRating }) {
    if (state.contractorData[clientId]) {
      state.contractorData[clientId].byOthers = byOthersRating
    }
  },
  UPDATE_OUTSTANDING_CLIENT_PROJECTS(state, { clientId, projects }) {
    if (state.clientData[clientId]) {
      state.clientData[clientId].outstandingProjects = projects
    }
  },
  UPDATE_OUTSTANDING_CONTRACTOR_PROJECTS(state, { clientId, projects }) {
    if (state.contractorData[clientId]) {
      state.contractorData[clientId].outstandingProjects = projects
    }
  }
}

const actions = {
  setClientRatingData({ commit }, payload) {
    commit(SET_CLIENT_RATING_DATA, payload)
  },
  setContractorRatingData({ commit }, payload) {
    commit(SET_CONTRACTOR_RATING_DATA, payload)
  },
  updateClientOwnRatings({ commit }, payload) {
    commit(UPDATE_CLIENT_OWN_RATINGS, payload)
  },
  updateContractorOwnRatings({ commit }, payload) {
    commit(UPDATE_CONTRACTOR_OWN_RATINGS, payload)
  },
  updateClientRatingsByOthers({ commit }, payload) {
    commit(UPDATE_CLIENT_RATINGS_BY_OTHERS, payload)
  },
  updateContractorRatingsByOthers({ commit }, payload) {
    commit(UPDATE_CONTRACTOR_RATINGS_BY_OTHERS, payload)
  },
  updateOutstandingClientProjects({ commit }, payload) {
    commit(UPDATE_OUTSTANDING_CLIENT_PROJECTS, payload)
  },
  updateOutstandingContractorProjects({ commit }, payload) {
    commit(UPDATE_OUTSTANDING_CONTRACTOR_PROJECTS, payload)
  }
}

const getters = {
  getRatingDataByClientId: (state) => (clientId) => {
    return state.clientData[clientId] || null
  },
  getRatingDataByContractorId: (state) => (clientId) => {
    return state.contractorData[clientId] || null
  },
  getOwnRatingsByClientId: (state) => (clientId) => {
    return state.clientData[clientId]?.own || []
  },
  getOwnRatingsByContractorId: (state) => (clientId) => {
    return state.contractorData[clientId]?.own || []
  },
  getClientRatingsByOthers: (state) => (clientId) => {
    return state.clientData[clientId]?.byOthers || []
  },
  getContractorRatingsByOthers: (state) => (clientId) => {
    return state.contractorData[clientId]?.byOthers || []
  },
  getOutstandingClientProjects: (state) => (clientId) => {
    return state.clientData[clientId]?.outstandingProjects || []
  },
  getOutstandingContractorProjects: (state) => (clientId) => {
    return state.contractorData[clientId]?.outstandingProjects || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
