<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <h3>{{ object.cost_type_name }}</h3>
      <p>Select which companies to share this category with:</p>
    </div>
    <div>
      <div
        v-for="franchisee in franchisees"
        :key="franchisee.franchisee_id"
        class="flex flex-row justify-between items-center p-2 gap-x-3 first:border-t border-b border-surface-200"
      >
        <div class="flex flex-row items-center gap-3">
          <icon icon="buildings" class="text-lg" />
          <h4>{{ franchisee.franchisee_name }}</h4>
        </div>
        <checkbox
          @change="onShareToggle($event, franchisee)"
          :value="isSelected(franchisee)"
          :boolean="true"
          :selected-value="true"
          :deselected-value="false"
        />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row gap-4 mt-4">
      <Btn size="lg" severity="secondary" class="w-full" :action="cancel"> Cancel </Btn>
      <Btn size="lg" severity="primary-black" class="w-full" :action="save"> Save </Btn>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

const store = useStore()
const props = defineProps({
  object: {
    default: null
  },
  modal: {
    default: null
  }
})

const category = ref(props.object)
const selected = ref(_.imm(category.value.asSharedCompanies))

const franchisees = computed(() => {
  return store.state.session.company.aoFranchisees
})

function isSelected(franchisee) {
  return selected.value.includes(franchisee.franchisee_id.toString())
}

function onShareToggle(value, franchisee) {
  const franchiseeId = franchisee.franchisee_id.toString()
  // Add franchisee id to list of selected
  if (value) {
    selected.value.push(franchiseeId)
  }
  // Remove franchisee id from list of selected
  else if (selected.value.includes(franchiseeId)) {
    selected.value.splice(selected.value.indexOf(franchiseeId), 1)
  }
}

async function save() {
  await store.dispatch('CostType/partialUpdate', {
    selected: [
      {
        type: 'cost_type',
        cost_type_id: category.value.cost_type_id,
        asSharedCompanies: selected.value.toString()
      }
    ],
    alert: true
  })
  // Update category so that changes are immediately visible
  category.value.asSharedCompanies = selected.value
  props.modal.close()
}

function cancel() {
  props.modal.close()
}
</script>
