// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

import _ from '../Helpers'
import TradeType from './TradeType'

export default {
  type: 'stage',

  skipAudit: true,

  fields: {
    ...TradeType.fields,
    stage_id: {
      type: 'int',
      title: 'Construction stage',
      name: 'Construction stage',
      filter: false,
      format: false,
      mapTo: false,
      visible: true
    },
    stage_name: {
      type: 'string',
      title: 'Construction stage Name',
      name: 'Construction stage Name',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    stage_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    stage_keywords: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    after_stage_name: {
      type: 'string',
      title: 'Occurs after',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    after_stage_id: {
      type: 'int',
      title: 'Occurs after',
      filter: false,
      format: false,
      mapTo: 'stage',
      visible: true
    },
    stage_order: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company'
    },
    stage_time_created: {
      type: 'int',
      filter: true,
      format: false
    },
    stage_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    trade_type_id: {
      type: 'int',
      mapTo: 'trade_type',
      filter: true,
      save: true
    },
    trade_type_name: {
      type: 'string',
      filter: true,
      save: true
    },
    stage_is_lockup: {
      type: 'int'
    },
    stage_is_heated: {
      type: 'int'
    },
    stage_is_liveable: {
      type: 'int'
    },
    stage_is_inspect_before: {
      type: 'int'
    },
    labor_type_id: {
      type: 'string',
      mapTo: 'labor_type',
      filter: true,
      save: false
    },
    labor_type_name: {
      type: 'string'
    },
    labor_type_rate_net: {
      type: 'float',
      filter: true,
      save: false
    },
    labor_type_is_indexed: {
      type: 'int',
      filter: true,
      save: false,
      default: 0
    },
    stage_duration: {
      type: 'int',
      filter: false,
      format: false,
      save: true
    }
  },

  generateVueActions() {
    return {
      async getSuggestedStage({ dispatch }, payload) {
        const { set: stages } = await dispatch('search')
        const { itemName, itemDesc, parentName } = payload

        const regxs = stages.reduce((acc, stage) => {
          let regs = []

          regs = [
            ..._.tokenize(stage.stage_name, 4),
            ..._.tokenize(stage.stage_desc, 1),
            ..._.tokenize(stage.stage_keywords, 4)
          ]

          return {
            ...acc,
            [stage.stage_id]: regs
          }
        }, {})

        const nameTokens = _.tokenize(itemName, 2)
        const parentNameTokens = _.tokenize(parentName, 2)
        const descTokens = _.tokenize(itemDesc, 1)
        const haystackParts = [...nameTokens, ...parentNameTokens, ...descTokens]
        const negativeKeywords = [..._.tokenize('repair', 2), ..._.tokenize('temporary', 2)]

        const rank = {}
        const addRank = (abbr, matches, matchList = []) => {
          if (!(abbr in rank)) rank[abbr] = {}

          rank[abbr].matchList = _.uniq([
            ...(rank[abbr].matchList || []),
            ...(matchList ? matchList : [])
          ])
          rank[abbr].matches = rank[abbr].matches || 0
          rank[abbr].matches = matches > rank[abbr].matches ? matches : rank[abbr].matches
          rank[abbr].total = rank[abbr].matches
        }

        stages.forEach((stage) => {
          const all = nameTokens.every((p) => regxs[stage.stage_id].includes(p)) ? 10 : 0
          const some = nameTokens.some((p) => regxs[stage.stage_id].includes(p)) ? 5 : 0
          const allParent = parentNameTokens.every((p) => regxs[stage.stage_id].includes(p)) ? 5 : 0
          const someParent = parentNameTokens.some((p) => regxs[stage.stage_id].includes(p)) ? 2 : 0
          const directla = _.termFrequency(haystackParts, regxs[stage.stage_id]) * 5

          const reversel = _.termFrequency(regxs[stage.stage_id], haystackParts)

          const neg = _.termFrequency(regxs[stage.stage_id], negativeKeywords) * 2
          const garbage = /^[0-9]+/.test(stage.stage_name) ? -10 : 5

          const totala = directla + reversel + all + some + allParent + someParent + garbage - neg

          if (totala > 0) addRank(stage.stage_id, totala, [])
        })

        const ranked = Object.keys(rank).sort((ra, rb) => rank[rb].total - rank[ra].total)

        const list = ranked.map((rid) => stages.find((st) => String(st.stage_id) === String(rid)))

        return {
          suggested: ranked,
          sorted: list,
          rank
        }
      }
    }
  }
}
