export default {
  type: 'schedule_link',

  skipAudit: true,

  fields: {
    schedule_link_id: {
      type: 'int',
      filter: true,
      format: false
    },
    target_item_id: {
      type: 'string',
      filter: false,
      format: false
    },
    source_item_id: {
      type: 'string',
      filter: false,
      format: false
    },
    type: {
      type: 'string',
      filter: true,
      format: false
    },
    schedule_link_status: {
      type: 'string',
      filter: true,
      format: false
    }
  }
}
