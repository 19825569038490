<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="md"
    :width="800"
    ref="modal"
    scrollable
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #header>
      <div class="flex flex-row justify-between w-full mx-3">
        Send proposal
        <btn class="ml-2" severity="tertiary" :action="() => $refs.body.inPersonApproval()">
          <font-awesome-icon icon="person-chalkboard" />
          In-person presentation
        </btn>
      </div>
    </template>
    <template #body>
      <EntitySelect :id="id" type="quote">
        <template v-slot="{ refId }">
          <QuoteSend ref="body" @reload="reload" :refId="refId" />
        </template>
      </EntitySelect>
    </template>
  </modal>
</template>

<script>
import QuoteSend from '../bodies/QuoteSend.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'

export default {
  name: 'ModalQuoteSend',
  mixins: [ObjectModalMixin],
  components: { QuoteSend },
  props: {
    type: {
      default: 'quote'
    }
  },
  methods: {
    reload() {
      setTimeout(() => this.close(), 0)
    }
  }
}
</script>
