<template>
  <div class="w-full m-8">
    <div class="flex flex-col justify-center items-center">
      <div>
        <div class="text-xs mb-2">Enter secure code</div>
        <TwoFactorAuthField @codeChanged="updateCode" ref="authField" :resendCode="resendCode" />
        <div v-if="tokenMessage" class="mt-4 w-full text-xs">
          <span :class="valid ? 'text-green-600' : 'text-red-600'">{{ tokenMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFactorAuthField from '@/components/ui/fields/TwoFactorAuthField.vue'

export default {
  components: {
    TwoFactorAuthField
  },
  props: {
    valid: {
      type: Boolean,
      default: null
    },
    tokenMessage: {
      type: String,
      default: ''
    },
    resendCode: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const updateCode = (newCode) => {
      emit('codeChanged', newCode)
    }

    return {
      updateCode
    }
  }
}
</script>
