<template>
  <Toast :position="smallFormat ? 'top-center' : 'bottom-right'" group="notification">
    <template #container="{ message, closeCallback }">
      <div v-if="message" class="p-2 flex items-center overflow-hidden">
        <!-- Channel notification -->
        <NotificationBody
          v-if="message.detail.__typename === 'Channel'"
          class="flex-1"
          @click="onNotificationClick(message.detail, closeCallback)"
          :avatar="getFormattedChannelName(message.detail || null)[0]"
          :title="getFormattedChannelName(message.detail || null)"
          :message="message.summary"
        />
        <!-- Activity notification -->
        <NotificationBody
          v-else-if="message.detail.__typename === 'Notification'"
          class="flex-1"
          @click="onNotificationClick(message.detail.channel, closeCallback)"
          :avatar="message.detail.activity.oCreator.user_fname[0]"
          :title="getFormattedChannelName(message.detail.channel || null)"
          :message="message.summary"
        />
        <!-- Default -->
        <NotificationBody v-else class="flex-1" :message="message.summary" />
        <div
          @click="closeCallback"
          class="rounded-full h-6 w-6 flex items-center justify-center cursor-pointer hover:bg-surface-100 ml-2"
        >
          <font-awesome-icon icon="xmark"></font-awesome-icon>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Toast from 'primevue/toast'
import NotificationBody from '@/components/notifications/NotificationBody.vue'
import { useActivityChannels } from '@/components/composables/ActivityChannels'
import { useMediaQuery } from '@/composables/mediaQuery'

// ======== Composables ======== //
const router = useRouter()
const { getFormattedChannelName } = useActivityChannels()
const { smallFormat } = useMediaQuery()
// ====================== //

// ======== Methods ======== //
const onNotificationClick = async (channel, closeCallBack) => {
  const scopeRoute = router.currentRoute.value.params.scopeRoute
  await router.push(`/${scopeRoute}/messages/${channel.channel_type}/${channel.channel_type_id}`)
  closeCallBack()
}
// ====================== //
</script>

<style lang="scss" scoped></style>
