// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
/* eslint-disable */
import CostItem from './CostItem'
import Currency from './Currency'
import Bid from './Bid'
import _ from '../Helpers'
import * as types from '../../../src/store/mutation-types'
import TranslationMixin from '../../../src/mixins/TranslationMixin'
import CostType from './CostType'
import QuoteAddons from './QuoteAddons'
import UserError from '../UserError'
import AutoCost from '../AutoCost.js'

const { l, getLang } = TranslationMixin
const currentTime = new Date().getTime()

const presentationSettingsDefaulter = (embue = {}) => {
  const mainDefault = {
    requireSignature: 0,
    showSlidePreviews: 0,
    styleVariables: '',
    termsAndConditions: '',
    badges: [],
    props: [],
    bookingMessage:
      'Thank you for booking with us! We will get in touch as soon as possible to confirm details.',
    defaultChangeOrderMessage: '',
    coverLetter: '',
    requirePlans: 0,
    showLargeProfilePic: 0,
    showItemizedPrices: 1,
    showQuantities: 1,
    showCostItemPrices: 1,
    showAssemblyPrices: 1,
    showCosts: 0,
    alwaysIncludedFileIds: '',
    showProductionNotes: 0,
    showTermsAndConditions: 1,
    showApprovals: 0,
    showProjectTotals: 1,
    forceTemplateSettings: 0,
    showAssemblyDimensions: 0,
    showDocumentPictures: 0,
    showDocumentDescriptions: 1,
    documentHeadingBg: null,
    assemblyHeadingColor: null,
    assemblyTextColor: null,
    // If !== null, items will show their cost as the price,
    // and all the profit will appear bundled at the end
    // of the presentation and marked as 'management fee'
    showBundledProfit: null,
    showConfigurator: 0,
    showItemSpecificTax: 1
  }
  const screenDefault = {
    screenType: 'content',
    content: [],
    showHeading: 1,
    showPresenter: 1,
    showContact: 1,
    showLogo: 0,
    showBook: 1,
    showDecline: 1,
    showProjectDetails: 1,
    headingFileId: null, // unset = default bluprint image
    logoFileId: null,
    ref: null,
    styleVariables: ''
  }

  const existing = _.imm(embue)
  if (
    existing &&
    typeof existing === 'object' &&
    'screens' in existing &&
    Array.isArray(existing.screens) &&
    existing.screens.length
  ) {
    let newDefault = {
      ...mainDefault,
      ...existing
    }
    newDefault.screens = existing.screens.map((screen, index) => ({
      ...screenDefault,
      ...screen,
      ref: screen.ref || `${new Date().valueOf()}${_.uniqueId()}`
    }))

    // Make sure the set includes a 'main' screenType
    if (!newDefault.screens.find((screen) => screen.screenType === 'main')) {
      newDefault.screens = [
        ...newDefault.screens,
        { ...screenDefault, screenType: 'main', ref: `${new Date().valueOf()}${_.uniqueId()}` }
      ]
    }

    return newDefault
  }

  // returning basic default;
  return {
    ...mainDefault,
    ...existing,
    screens: [
      {
        // styleVariables,
        // fileId, => for screenType: 'pdf', or 'signature', or 'img'
        ...screenDefault,
        screenType: 'main',
        ref: 'main'
      }
    ]
  }
}

export default {
  type: 'quote',
  presentationSettingsDefaulter,

  possibleStatuses: ['p', 'd', 'k', 'f', 'g'],

  possibleStates: ['lead', 'quote', 'change-order'],
  /**
   * Items that can be intermixed with this object type,
   * that could be cached.  Each of these object types
   * will be cleared from cache, when clearning assemblies from cache.
   * @type {[string,string,string]}
   */
  cachedTypes: ['client', 'change_order'],
  relatedTypes: ['client', 'change_order'],

  listPresets: [
    {
      title: 'All proposals',
      description: 'Estimates that have not yet been accepted by your client.',
      filters: {
        quote_status: 'p',
        quote_state: '!lead'
      },
      filterText: {
        quote_status: 'Pending'
      }
    },
    {
      title: 'Unsent',
      description: 'Estimates that have not yet been sent to your client.',
      filters: {
        quote_time_sent: 'NULL||0',
        quote_status: 'p',
        quote_state: '!lead'
      },
      filterText: {
        quote_time_sent: 'Never',
        quote_status: 'Pending'
      }
    },
    {
      title: 'Opportunities',
      description: 'Proposals that clients have requested a quote for.',
      filters: {
        quote_state: 'lead'
      },
      filterText: {
        quote_status: 'Leads'
      }
    },
    {
      title: 'Change orders',
      description: 'Estimates with pending change orders.',
      filters: {
        quote_status: 'k,f',
        quote_has_change_orders_unnapproved_by_client: '>0',
        quote_state: '!lead'
      },
      filterText: {
        quote_status: 'Project',
        quote_has_change_orders_unnapproved_by_client: 'Has pending changes'
      }
    },
    {
      title: 'Booked',
      description: 'Active projects that have been booked and accepted by your client.',
      filters: {
        quote_status: 'k,f',
        quote_state: '!lead'
      },
      filterText: {
        quote_status: 'Booked or In-progress'
      }
    },
    {
      title: 'Completed',
      description: 'Projects that have been finished and closed.',
      filters: {
        quote_status: 'g',
        quote_state: '!lead'
      },
      filterText: {
        quote_status: 'Closed'
      }
    },
    {
      title: 'Declined',
      description: 'Estimates that have been declined by your client.',
      filters: {
        quote_status: 'd',
        quote_state: '!lead'
      },
      filterText: {
        quote_status: 'Declined'
      }
    },
    {
      title: 'Expired',
      description: 'Estimates that have expired.',
      filters: {
        quote_time_expired: `>0&&<${currentTime}`,
        quote_status: 'p',
        quote_state: '!lead'
      },
      filterText: {
        quote_time_expired: 'Expired'
      }
    }
  ],

  fields: {
    ...Bid.fields,
    aoAddons: CostItem.fields.aoAddons,
    currency_id: Currency.fields.currency_id,
    currency_iso: Currency.fields.currency_iso,
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewQuote'
    },
    plan_file_ids: {
      type: 'array',
      mapTo: 'file'
    },
    before_file_ids: {
      type: 'array',
      mapTo: 'file'
    },
    after_file_ids: {
      type: 'array',
      mapTo: 'file'
    },
    quote_is_rfq: {
      title: 'Quote is RFQ',
      type: 'int',
      filter: true,
      defaultSetting: true,
      default: 0
    },
    quote_is_upgrading_allowed: {
      title: 'Is upgrading allowed?',
      type: 'int',
      filter: true,
      defaultSetting: true,
      default: 1
    },
    quote_rfq_time_accepted: {
      type: 'int',
      filter: true,
      default: null
    },
    quote_rfq_vendor_ids: {
      type: 'array',
      mapTo: 'vendor'
    },
    quote_rfq_is_public: {
      type: 'int',
      default: 0
    },
    quote_is_for_self: {
      type: 'int',
      default: 0,
      defaultSetting: true
    },
    item_id: {
      save: true,
      type: 'string',
      reload: true,
      trackChanges: false,
      filter: false
    },
    file_ids: {
      type: 'array',
      save: true,
      trackChanges: true,
      normalize: false
    },
    production_file_ids: {
      type: 'array',
      save: true,
      trackChanges: true,
      normalize: false,
      mapTo: 'file'
    },
    quote_name: {
      type: 'string',
      filter: true,
      format: 'text',
      mapTo: false,
      validate: {
        required: true
      }
    },
    quote_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      ommitFromDuplicate: true,
      default: 'p',
      suggestedFilter: true
    },
    client_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'client',
      visible: true
    },
    client_user_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
      visible: true
    },
    quote_client_ref: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      ommitFromDuplicate: true,
      title: "Client's reference #"
    },
    quote_price_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Price',
      visible: true
    },
    quote_invoiced_net: {
      type: 'float',
      save: false,
      trackChanges: false,
      reload: true
    },
    quote_time_sent: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true,
      suggestedFilter: true,
      save: false
    },
    change_order_time_sent: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true
    },
    change_order_approved_terms: {
      type: 'string',
      filter: false,
      save: false,
      trackChanges: false
    },
    change_order_name: {
      type: 'string',
      filter: true,
      mapTo: false,
      trackChanges: false,
      reload: true
    },
    change_order_message: {
      type: 'string',
      save: true,
      trackChanges: true,
      reload: true
    },
    quote_time_booked: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true
    },
    quote_time_project_start: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
      trackChanges: false
    },
    quote_time_project_end: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    quote_time_declined: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true
    },
    quote_time_seen: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true,
      suggestedFilter: true
    },
    quote_time_expired: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      trackChanges: false,
      reload: true,
      visible: true
    },

    quote_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      reload: true,
      suggestedFilter: true
    },
    files_count: {
      type: 'int',
      save: false,
      format: false
    },
    aoFiles: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'file',
      ommitFromDuplicate: true,
      reload: true,
      normalize: false,
      deep: false
    },
    oClient: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'client',
      save: false,
      trackChanges: false,
      suggestedFilter: true,
      normalize: false
    },
    aoReviewers: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'reviewer',
      save: false,
      trackChanges: false,
      suggestedFilter: false,
      normalize: false
    },
    oUpdates: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    quote_paid_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Amount collected (pre-tax)'
    },
    quote_paid_tax: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Sales tax collected'
    },
    quote_paid_gross: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Amount collected (incl tax)'
    },
    quote_unpaid_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Amount uncollected (pre-tax)'
    },
    quote_unpaid_tax: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Sales tax uncollected'
    },
    quote_unpaid_gross: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Amount uncollected (incl tax)'
    },
    quote_uninvoiced_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Amount uninvoiced (pre-tax)',
      save: false
    },
    quote_uninvoiced_tax: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      save: false
    },
    quote_uninvoiced_gross: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Amount uninvoiced (incl tax)',
      save: false
    },
    quote_invoiced_percentage: {
      type: 'float',
      filter: true,
      format: 'percentWhole',
      mapTo: false,
      trackChanges: false,
      reload: true,
      title: 'Amount invoiced (%)'
    },
    quote_paid_percentage: {
      type: 'float',
      filter: false,
      format: 'percentWhole',
      mapTo: false,
      title: 'Amount paid (%)',
      trackChanges: false,
      reload: true
    },
    last_activity_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      save: false
    },
    last_activity_time: {
      type: 'float',
      filter: false,
      format: 'datetime',
      mapTo: false,
      save: false,
      suggestedFilter: true
    },
    last_activity_type: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      save: false
    },
    last_activity_desc: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      save: false
    },
    owner_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      suggestedFilter: true
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
      trackChanges: false
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      save: false,
      trackChanges: false,
      normalize: false
    },
    creator_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      save: false,
      trackChanges: false,
      normalize: false
    },
    tax_percentage: {
      type: 'float',
      filter: false,
      format: 'percentWhole',
      mapTo: false,
      defaultSetting: true
    },
    tax_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      defaultSetting: true
    },

    template_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'template',
      autoPartialSave: true,
      defaultSetting: true
    },
    quote_data: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    tax_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'tax',
      defaultSetting: true
    },
    quote_price_net_per_unit: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Price (per unit, pre-tax, after discount)'
    },
    quote_cost_net_per_unit: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Cost (per unit, pre-tax, after discount)'
    },
    quote_price_net_base: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      audit: false
    },
    quote_price_tax: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Price sales tax (after discount)'
    },
    quote_price_gross: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Price (incl tax, after discount)'
    },
    quote_exclusions: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_notes: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_private_notes: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_existing_conditions: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_address: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_suite: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_city: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    province_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'province'
    },
    quote_postal: {
      type: 'string',
      filter: false,
      mapTo: false
    },
    quote_show_cost_item_prices: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    quote_show_assembly_prices: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    quote_show_quantities: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    quote_show_expanded_assemblies: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    quote_show_costs: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 0,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    quote_show_dimensions: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1,
      autoPartialSave: true,
      defaultSetting: true,
      reload: true
    },
    export_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    parent_quote_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    quote_area_gross: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    oDimensions: {
      type: 'object',
      filter: false,
      save: true,
      trackChanges: true,
      deep: true,
      title: 'Dimensions',
      default: () => ({})
    },
    auth_file_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      reload: true,
      trackChanges: false
    },
    auth_file_ids: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      reload: true,
      normalize: false
    },
    quote_discount_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Discount',
      default: 0
    },
    quote_discount_net_base: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Discount',
      default: 0
    },
    quote_discount_percentage: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    aoChildren: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: (child) => child.type || 'cost_item',
      title: 'Line items',
      possibleChildTypes: ['cost_item', 'assembly'],
      deep: false
    },
    aoChangeOrders: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'change_order',
      title: 'Change Orders List',
      reload: true,
      trackChanges: false,
      deep: true,
      normalize: false
    },
    change_order_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'change_order',
      save: false,
      reload: true
    },
    quote_count_bids: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    quote_count_bids_pending: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    quote_count_bids_queued: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    quote_count_bids_booked: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    change_order_time_booked: {
      type: 'float',
      save: false,
      reload: true,
      trackChanges: false,
      format: 'datetime'
    },
    change_order_time_last_modified: {
      type: 'float',
      save: false,
      reload: true,
      trackChanges: false,
      format: 'datetime'
    },
    aoActivities: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'activity',
      ommitFromDuplicate: true,
      save: false,
      normalize: false
    },
    quote_materials_cost_net_base: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Materials cost',
      default: 0
    },
    quote_materials_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Materials cost',
      default: 0
    },
    quote_labor_cost_net_base: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Labor cost',
      default: 0
    },
    quote_labor_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Labor cost',
      default: 0
    },
    quote_total_cost_net_base: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Total cost',
      default: 0
    },
    quote_total_cost_net: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Total cost',
      default: 0,
      suggestedFilter: true
    },
    quote_margin_net: {
      type: 'float',
      filter: true,
      format: 'percentage',
      mapTo: false,
      title: 'Average profit',
      default: 0
    },
    quote_markup_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Average markup',
      default: 1,
      trackChanges: false
    },
    quote_profit_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Profit $',
      default: 1,
      suggestedFilter: true,
      reload: true,
      trackChanges: false
    },
    quote_production_notes: {
      type: 'string'
    },
    quote_profit_percentage: {
      type: 'float',
      filter: true,
      format: 'percentageWhole',
      mapTo: false,
      title: 'Profit %',
      default: 1,
      suggestedFilter: true,
      reload: true,
      trackChanges: false
    },
    assembly_markup_percentage_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: null
    },
    quote_markup_percentage_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0,
      title: 'Price adjustment'
    },
    quote_disbursements_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    quote_price_net_base_before_disbursements: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    quote_prov: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    quote_total_hours_base: {
      type: 'float',
      filter: false,
      format: 'hours',
      mapTo: false
    },
    aoRatings: {
      type: 'array',
      mapTo: false,
      reload: false,
      filter: false,
      trackChanges: false
    },
    quote_total_hours: {
      type: 'float',
      filter: true,
      format: 'hours',
      mapTo: false,
      default: 0,
      suggestedFilter: true
    },
    quote_subtotal_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: 0,
      audit: false,
      suggestedFilter: true
    },
    quote_price_net_base_undiscounted: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      title: 'Price (pre-tax, before discount)'
    },
    quote_price_net_undiscounted: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      audit: false
    },
    aoMeta: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    country_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'country'
    },
    quote_qty_net: {
      type: 'float',
      filter: false,
      format: 'number',
      mapTo: false,
      default: 1,
      title: 'Sub Qty'
    },
    quote_qty_net_base: {
      type: 'float',
      filter: false,
      format: 'number',
      mapTo: false,
      default: 1,
      title: 'Quantity'
    },
    quote_qty_net_base_original: {
      type: 'float',
      filter: false,
      format: 'number',
      mapTo: false,
      default: 1,
      title: 'Quantity'
    },
    quote_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      trackChanges: false,
      reload: true,
      visible: true,
      suggestedFilter: true
    },
    quote_time_last_modified: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      save: false,
      reload: true
    },
    quote_owner: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
      visible: true,
      trackChanges: false,
      suggestedFilter: true
    },
    quote_included_assembly_ids: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false,
      normalize: false
    },
    quote_included_cost_type_ids: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false,
      normalize: false
    },
    quote_included_cost_matrix_ids: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false,
      normalize: false
    },
    quote_assemblies_is_changed: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    quote_cost_matrices_is_changed: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    quote_cost_types_is_changed: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    quantity_multiplier: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1
    },
    aoProperties: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      default: []
    },
    parent_cost_type_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: 'cost_type'
    },
    parent_cost_type_name: {
      type: 'string',
      filter: false,
      format: false
    },
    quote_total_cost_net_changed: {
      title: 'Accumulated change in cost since last save',
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true
    },
    oEquations: {
      type: 'object',
      filter: false,
      format: false,
      save: true,
      mapTo: false,
      deep: true,
      trackChanges: false,
      formatForJS: false
    },
    change_order_status: {
      type: 'string',
      default: 'p',
      reload: true,
      trackChanges: false
    },
    change_order_client_has_approved: {
      type: 'int',
      default: 0,
      reload: true,
      save: false,
      filter: false
    },
    change_order_client_approved_time: {
      format: 'datetime',
      type: 'float',
      default: null,
      save: false,
      reload: true
    },
    change_order_client_approved_by: {
      type: 'string',
      default: null,
      save: false,
      reload: true
    },

    change_order_company_has_approved: {
      type: 'int',
      default: 0,
      reload: true,
      save: false,
      filter: false
    },
    change_order_company_approved_time: {
      format: 'datetime',
      type: 'float',
      default: null,
      save: false,
      reload: true
    },
    change_order_company_approved_by: {
      type: 'string',
      default: null,
      save: false,
      reload: true
    },
    aoChildStageTasks: {
      type: 'array',
      mapTo: 'task',
      title: 'Staged tasks',
      deep: false,
      trackChanges: false,
      save: false,
      /**
       * Even when keepWhole: false on denormalize,
       * this will override that and get rid of this field
       * so it doesn't clutter up fetched.  This is an object array field, and
       * it is also auto generated in dependnencies so it interferes with stuff
       * when it is normalized again and selected
       */
      keepInFetched: false
    },
    aoTasks: {
      type: 'array',
      mapTo: 'task',
      reload: true
    },
    quote_pm_user_id: {
      title: 'Project manager',
      mapTo: 'user',
      filter: true,
      defaultSetting: true
      // suggestedFilter: true,
    },
    quote_designer_user_id: {
      title: 'Designer',
      mapTo: 'user',
      filter: true,
      defaultSetting: true
      // suggestedFilter: true,
    },
    quote_price_net_base_adjustment: {
      type: 'float',
      filter: false
    },
    quote_price_net_adjustment: {
      type: 'float',
      filter: false
    },
    assembly_fixed_price_net_each: {
      type: 'float',
      filter: false,
      default: null
    },
    assembly_minimum_qty_net: {
      type: 'float',
      default: null
    },
    assembly_is_using_minimum_qty: {
      type: 'int',
      default: 0
    },
    assembly_minimum_area_net: {
      type: 'float',
      default: null
    },
    assembly_is_using_minimum_area: {
      type: 'int',
      default: 0
    },
    // Pseudo field flags whether
    // a full change order should be created and
    // client approval sought, or not
    changesRequireApproval: {
      type: 'int',
      default: 1,
      trackChanges: false
    },
    quote_count_tasks_due: {
      type: 'int',
      default: 0,
      filter: true,
      trackChanges: false,
      save: false
    },
    quote_cost_tax: {
      type: 'float',
      default: 0,
      save: true,
      trackChanges: true,
      title: 'Sales tax on costs'
    },
    quote_discount_tax: {
      type: 'float',
      default: 0,
      save: true,
      trackChanges: true,
      title: 'Less sales tax on discount'
    },
    quote_profit_tax: {
      type: 'float',
      default: 0,
      save: true,
      trackChanges: true,
      title: 'Sales tax on profit'
    },
    quote_tax: {
      type: 'float',
      default: 0,
      save: true,
      trackChanges: true,
      title: 'Sales tax total'
    },
    quote_cost_net: {
      type: 'float',
      default: 0,
      save: true,
      trackChanges: true
    },
    quote_is_test: {
      type: 'int',
      default: 0,
      reload: false,
      save: true,
      filter: false
    },
    /**
     * Default presentation settings as loaded
     * from a template.  This allows you to cahnge
     * the 'theme' of a quote depending on the type
     * of quote it is or depending what kind of
     * customer you have.
     */
    oTemplate: {
      type: 'object',
      save: false,
      reload: true,
      deep: false,
      trackChanges: false,
      mapTo: 'template',
      normalize: false,
      title: 'Presentation settings'
    },
    presentation_template_id: {
      save: true,
      reload: false,
      trackChanges: true,
      type: 'int',
      mapTo: 'template',
      default: 3039991009181, // Design Studio
      defaultSetting: true
    },
    presentation_template_name: {
      save: true,
      reload: false,
      trackChanges: false,
      type: 'string',
      mapTo: false,
      default: 'Default',
      defaultSetting: true
    },
    // aoDiscounts: {
    //   type: 'array',
    //   default: (embue = []) => {
    //     const defaultDiscount = {
    //       type: 'discount',
    //       discount_type: 'n',
    //       discount_name: 'Discount',
    //       discount_amount: 0,
    //       discount_percentage: 0,
    //       discount_adjustment_percentage: 0,
    //     };
    //     return _.makeArray(embue)
    //       .map((u) => ({ ...defaultUpgrade, ...u, }));
    //   },
    //   save: true,
    //   deep: true,
    //   reload: false,
    //   trackChanges: true,
    //   title: 'Discounts',
    // },

    depth: {
      type: 'int',
      default: 0,
      save: false,
      trackChanges: false,
      reload: false,
      filter: false
    },

    /**
     * Set local settings for a particular quote only,
     * that will override oTemplate ^ settings set
     * from the main template.  This allows fine-grained
     * settings on a per-quote basis, based off a template.
     *
     * This specifically is overridden by the oTemplate.oMeta
     * field.
     */
    oPresentationSettings: {
      type: 'object',
      filter: false,
      save: false,
      trackChanges: true,
      deep: true,
      title: 'Presentation settings',
      default: presentationSettingsDefaulter
    },
    quote_count_addons_available: {
      type: 'int',
      default: 0,
      trackChanges: false,
      reload: true,
      save: true,
      title: 'Has add-ons available'
    },
    quote_has_live_pricing: {
      type: 'int',
      default: 0,
      trackChanges: false,
      reload: true,
      save: true,
      title: 'Has AutoCost items'
    },
    location_ids: {
      type: 'array',
      mapTo: 'location',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    },
    oSowColumns: {
      type: 'object',
      filter: false,
      save: true,
      trackChanges: true,
      deep: false,
      title: 'Scope of work columns visible',
      autoPartialSave: true,
      defaultSetting: true,
      default: () => ({
        cost_type_name: 1,
        cost_matrix_materials_cost_net: 1,
        cost_type_hours_per_unit: 1,
        labor_type_rate_net: 1,
        cost_matrix_labor_cost_net: 1,
        cost_matrix_markup_net: 1,
        cost_matrix_rate_net: 1,
        cost_item_qty_net: 1,
        unit_of_measure_name: 1,
        cost_item_materials_cost_net: 1,
        cost_item_total_hours: 1,
        cost_item_labor_cost_net: 1,
        cost_item_total_cost_net: 1,
        cost_item_regular_price_net: 1,
        cost_item_price_net_adjustment: 0,
        cost_item_price_net: 1,
        aoProperties: 1,
        cost_type_desc: 1
      })
    },
    child_task_ids: {
      type: 'array',
      reload: true,
      save: false,
      trackChanges: false,
      normalize: false
    },
    quote_show_workbook_in_sow: {
      type: 'int',
      default: 1,
      autoPartialSave: true,
      defaultSetting: true
    },
    quote_show_tasks_in_sow: {
      type: 'int',
      default: 1,
      autoPartialSave: true,
      defaultSetting: true
    },
    quote_show_profit_summary_in_sow: {
      type: 'int',
      default: 1,
      autoPartialSave: true,
      defaultSetting: true
    },
    asAssemblyPath: {
      type: 'Array',
      deep: false,
      mapTo: false,
      filter: false,
      save: true,
      trackChanges: false
    },
    quote_is_change_order: {
      type: 'int',
      default: 0,
      save: false,
      reload: true
    },
    oFollowupActivity: {
      type: 'object',
      save: false,
      trackChanges: false,
      reload: true,
      mapTo: 'activity',
      normalize: false
    },
    quote_last_followup_time: {
      type: 'float',
      save: false,
      reload: true,
      trackChanges: false,
      default: null
    },
    followup_activity_id: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false,
      mapTo: 'activity'
    },
    aoVendorPayments: {
      type: 'array',
      save: false,
      reload: true,
      trackChanges: false,
      mapTo: false,
      normalize: false,
      deep: false
    },
    quote_actual_total_cost_net: {
      type: 'float',
      format: 'currency',
      save: true,
      trackChanges: true,
      default: null
    },
    quote_actual_materials_cost_net: {
      type: 'float',
      format: 'currency',
      save: true,
      trackChanges: true,
      default: null
    },
    quote_actual_labor_cost_net: {
      type: 'float',
      format: 'currency',
      save: true,
      trackChanges: true,
      default: null
    },
    quote_completion_percentage: {
      type: 'float',
      format: 'percentage',
      save: true,
      trackChanges: true
    },
    quote_sum_completed_items: {
      type: 'float',
      format: 'currency',
      save: false,
      trackChanges: false,
      reload: false
    },
    quote_count_completed_items: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: false
    },
    quote_count_items: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: false
    },
    quote_has_change_orders_unnapproved_by_client: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    quote_has_unsent_pending_change_orders: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    quote_has_pending_change_orders: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    quote_show_itemized_prices: {
      type: 'int',
      default: 1
    },
    asRequiredDimensions: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    oChildRequiredDimensions: {
      type: 'object',
      default: {},
      save: false,
      trackChanges: false
    },
    asDimensionsUsed: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    item_count_upgrades: {
      type: 'int',
      default: 0,
      save: false,
      trackChanges: false,
      reload: true
    },

    quote_is_bid: {
      type: 'int',
      default: 0,
      save: false,
      reload: true
    },
    oBid: {
      type: 'object',
      mapTo: 'bid',
      save: false,
      reload: true
    },

    oProjectTaxSettings: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: CostItem.taxSettingsDefaulter
    },
    quote_weight_net: {
      type: 'float',
      save: true,
      reload: true,
      trackChanges: true,
      default: 0
    },
    weight_unit_of_measure_id: {
      type: 'string',
      save: true,
      reload: true,
      trackChanges: true,
      default: 'lbs'
    },
    weight_unit_of_measure_abbr: {
      type: 'string',
      save: true,
      reload: true,
      trackChanges: true,
      default: 'lbs'
    },
    /**
     * Calculate tax sums
     * individual based on the
     * individual tax type
     */
    oTaxSums: {
      type: 'object',
      default: () => ({})
    },
    quote_terms: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    oMod: {
      type: 'object',
      save: true,
      default: () => ({}),
      trackChanges: false
    },
    quote_schedule_status: {
      type: 'string',
      default: 'y',
      reload: true,
      trackChanges: false
    },
    quote_is_aggregated_payment: {
      type: 'int',
      default: 0,
      save: true,
      reload: true,
      filter: false
    },
    live_price_zipcode: {
      type: 'string',
      default: 'company',
      format: false
    },
    quote_is_open_quote: {
      title: 'Quote is openQuote',
      type: 'int',
      filter: false,
      default: 0
    },
    quote_desc: {
      type: 'string',
      format: false
    },
    showcase_file_ids: {
      type: 'array',
      mapTo: 'file',
      filter: false
    },
    lead_rotation_id: {
      type: 'int',
      save: true,
      saveChanges: true,
      trackChanges: true,
      filter: false
    },
    quote_open_quote_status: {
      type: 'string',
      default: 'y',
      save: true,
      saveChanges: true,
      trackChanges: true,
      filter: false
    },
    quote_state: {
      type: 'string',
      default: 'quote'
    },
    quote_is_display_price_shown: {
      type: 'int',
      save: true,
      saveChanges: true,
      trackChanges: true,
      filter: false,
      default: 0
    },
    quote_display_price: {
      type: 'float',
      format: 'currency',
      save: true,
      saveChanges: true,
      trackChanges: true,
      filter: false
    }
  },

  actions: {
    sendWarn: {
      text: 'Never sent! Send now..',
      icon: 'paper-plane',
      action: 'Quote/send',
      selectionRequired: true,
      multiple: true,
      class: 'danger',
      visible: (quotes) =>
        quotes.every((quote) => quote.quote_status === 'p' && !quote.quote_time_sent),
      collapse: false
    },
    send: {
      text: 'Send..',
      icon: 'paper-plane',
      action: 'Quote/send',
      selectionRequired: true,
      multiple: true,
      visible: (quotes) =>
        quotes.every((quote) => quote.quote_status === 'p') &&
        !quotes.every((quote) => quote.quote_status === 'p' && !quote.quote_time_sent) &&
        quotes.some((quote) => !quote.quote_time_sent),
      collapse: false
    },
    followUp: {
      text: 'Send reminder..',
      icon: 'paper-plane',
      action: 'Quote/send',
      selectionRequired: true,
      multiple: true,
      visible: (quotes) =>
        quotes.every((quote) => quote.quote_status === 'p' && quote.quote_time_sent),
      collapse: false
    },
    markApproved: {
      text: 'Approve for release..',
      icon: 'check',
      class: 'success',
      selectionRequired: true,
      collapse: 'lg',
      action: 'Quote/markApprovedByCompany',
      multiple: true,
      visible: (quotes) =>
        quotes.some((quote) => quote.quote_has_change_orders_unnapproved_by_company)
    },
    sign: {
      text: 'Sign & book now..',
      icon: 'pencil',
      action: 'Quote/sign',
      selectionRequired: true,
      class: 'success',
      visible: ([quote]) => quote.quote_status === 'p'
    },
    decline: {
      text: 'Mark as declined for now',
      icon: 'ban',
      action: 'Quote/decline',
      selectionRequired: true,
      class: 'danger',
      multiple: true,
      visible: ([quote]) => quote.quote_status === 'p'
    },
    expire: {
      text: 'Mark as expired',
      icon: 'timer',
      action: 'Quote/expire',
      selectionRequired: true,
      class: 'warning',
      visible: (quotes) =>
        quotes.every(
          (quote) =>
            quote.quote_status === 'p' &&
            quote.quote_time_sent &&
            !(quote.quote_time_expired && new Date().getTime() > quote.quote_time_expired)
        )
    },
    unexpire: {
      text: 'Remove expiry',
      icon: 'timer',
      action: 'Quote/unexpire',
      selectionRequired: true,
      class: 'warning',
      visible: ([quote]) => quote.quote_status === 'p' && quote.quote_time_expired
    },
    invoice: {
      text: 'Invoice this project..',
      icon: 'file-invoice',
      action: 'Quote/invoice',
      selectionRequired: true,
      collapse: ([quote]) => !_.eq(quote.quote_unpaid_net, 0),
      visible: ([quote]) => quote.quote_status === 'k' || quote.quote_status === 'f'
    },
    rating: {
      text: 'Write a review',
      icon: 'star',
      action: 'Quote/rate',
      selectionRequired: true,
      class: 'success'
    },
    download: {
      multiple: true,
      text: 'Download',
      icon: 'cloud-arrow-down',
      selectionRequired: true,
      collapse: 'lg',
      options: [
        {
          text: 'Download proposal (PDF)',
          icon: 'file-pdf',
          async action(store, payload) {
            const quote = payload.selected[0]

            if (
              quote &&
              Object.prototype.hasOwnProperty.call(quote, 'isDirty') &&
              quote.isDirty &&
              quote.autoSave
            ) {
              const size = store.state.session.deviceSize
              const res = await store.dispatch('modal/asyncConfirm', {
                message:
                  'You have unsaved changes. Would you like to save your changes before leaving?',
                yes: size === 'xs' ? 'Save' : 'Save changes',
                no: size === 'xs' ? 'Discard' : 'Discard changes',
                close: 'Cancel'
              })
              if (res === 'close') {
                this.emitReload = false
                return
              }

              if (res) {
                await store.dispatch('addLoading')
                await quote.autoSave()
                store.dispatch('endLoading')
              }
            }

            return store.dispatch('Quote/download', {
              ...payload,
              documentType: 'quote',
              format: 'pdf'
            })
          },
          selectionRequired: true
        },
        {
          text: 'Download scope of work (Excel)',
          icon: 'file-excel',
          async action(store, payload) {
            const quote = payload.selected[0]

            if (
              quote &&
              Object.prototype.hasOwnProperty.call(quote, 'isDirty') &&
              quote.isDirty &&
              quote.autoSave
            ) {
              const size = store.state.session.deviceSize
              const res = await store.dispatch('modal/asyncConfirm', {
                message:
                  'You have unsaved changes. Would you like to save your changes before leaving?',
                yes: size === 'xs' ? 'Save' : 'Save changes',
                no: size === 'xs' ? 'Discard' : 'Discard changes',
                close: 'Cancel'
              })
              if (res === 'close') {
                this.emitReload = false
                return
              }

              if (res) {
                await store.dispatch('addLoading')
                await quote.autoSave()
                store.dispatch('endLoading')
              }
            }

            return store.dispatch('Quote/download', {
              ...payload,
              documentType: 'sow',
              format: 'excel'
            })
          },
          selectionRequired: true
        },
        {
          text: 'Download latest signed contract (PDF)',
          icon: 'pencil',
          action(store, payload) {
            return store.dispatch('Quote/download', {
              ...payload,
              documentType: 'contract',
              format: 'pdf'
            })
          },
          selectionRequired: true,
          visible: (quotes) =>
            quotes.filter((q) => q.quote_status && /k|f|g/.test(q.quote_status) && q.auth_file_ids)
              .length === quotes.length
        }
      ]
    }
  },

  generateVueActions() {
    const getTotalPriceField = (objType) => {
      return objType === 'assembly' ? 'quote_price_net' : 'cost_item_price_net'
    }
    const getQtyField = (objType) => {
      return objType === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'
    }

    const getNormalizedRootRefId = (set, refId = Object.keys(set)[0]) => {
      let cursorRef = refId
      const allCursors = [] // Prevent recursion
      if (
        typeof set === 'object' &&
        refId &&
        cursorRef in set /* && typeof set[cursorRef] === 'object' */
      ) {
        while (
          typeof set[cursorRef] === 'object' &&
          'parentRefId' in set[cursorRef] &&
          !_.isempty(set[cursorRef].parentRefId) &&
          set[cursorRef].parentRefId in set &&
          allCursors.indexOf(set[cursorRef].parentRefId) === -1
        ) {
          cursorRef = set[cursorRef].parentRefId
          allCursors.push(cursorRef)
        }
        return cursorRef
      }
      return false
    }

    let addons = {}
    let addonsQueue = []
    let addonsExpiry = 0
    let addonsTimeLimit = 1000 * 60 * 15 // 15 mins

    return {
      async getHashes({ dispatch, state }) {
        const norm = { ...(state.normalized || {}) }
        const itemRefs = Object.keys(norm).filter(
          (ref) => norm[ref].type === 'cost_item' && norm[ref].cost_type_id
        )
        const ids = itemRefs.reduce(
          (acc, refId) => ({
            ...acc,
            [refId]: norm[refId].cost_type_id
          }),
          {}
        )
        const { object } = await dispatch('ajax', {
          path: 'cost_type/getHashes',
          data: {
            ids: Object.values(ids)
          }
        })

        // generate change object
        const changes = itemRefs.reduce(
          (acc, refId) => ({
            ...acc,
            ...(object[norm[refId].cost_type_id]
              ? {
                  [refId]: {
                    cost_type_hash: object[norm[refId].cost_type_id]
                  }
                }
              : {})
          }),
          {}
        )

        dispatch('field', {
          changes,
          skipAudit: true,
          skipLocalAudit: true
        })
        dispatch('addChanges', {
          changes
        })
      },
      async changeOpenQuoteStatus({ dispatch }, payload) {
        if (!payload.id || !payload.status) {
          throw new Error('Must provide a quote id and status')
        }
        const { id, status } = payload
        const { object } = await dispatch('resolveObject', { id })
        return dispatch('partialUpdate', {
          selected: [
            {
              type: 'quote',
              quote_id: object.quote_id,
              quote_open_quote_status: status
            }
          ]
        })
      },
      async changeQuoteState({ dispatch }, payload) {
        if (!payload.id || !payload.state) {
          throw new Error('Must provide a quote id and state')
        }
        if (!this.possibleStates.includes(state)) {
          throw new Error('You cannot switch quote to that state')
        }
        const { id, state } = payload
        const { object } = await dispatch('resolveObject', { id })
        return dispatch('partialUpdate', {
          selected: [
            {
              type: 'quote',
              quote_id: object.quote_id,
              quote_state: state
            }
          ]
        })
      },
      async requestForEstimate({ dispatch }, payload) {
        if (!payload.id) {
          throw new Error('Must provide a quote id and state')
        }
        return dispatch('ajax', {
          path: 'quote/requestForEstimate',
          data: {
            quoteId: payload.id
          }
        })
      },
      /**
       * Import image/pdf/other file with an old quote in it, generate items,
       * and place inside of an assembly.
       * @param dispatch
       * @param state
       * @param rootState
       * @param payload
       */
      async import({ dispatch, state, rootState }, payload = {}) {
        const {
          fileId
          // refId,
          // store,
          // normalized = state.normalized,
        } = payload

        const quoteDetails = await dispatch('ajax', {
          path: 'ocr/quote',
          data: {
            file_id: fileId
          }
        })
      },

      ...QuoteAddons,
      setIncluded: CostItem.generateVueActions().setIncluded,
      async getSuggestedStages({ dispatch, state, rootState }, payload) {
        const { normalized = state.normalized, refId, embue = {} } = payload

        const object = { ..._.immutable(normalized[refId]), ...embue }
        const parent =
          object.parentRefId &&
          object.parentRefId in normalized &&
          normalized[object.parentRefId].parentRefId
            ? _.immutable(normalized[object.parentRefId])
            : null

        const { sorted } = await dispatch(
          'Stage/getSuggestedStage',
          {
            itemName: object.cost_type_name,
            itemDesc: object.cost_type_desc,
            parentName: parent ? parent.assembly_name || parent.quote_name : ''
          },
          { root: true }
        )

        return [...sorted].slice(0, 5)
      },

      async getSuggestedDimensions({ dispatch, state, rootState }, payload) {
        const { normalized = state.normalized, refId, embue = {} } = payload

        const object = { ..._.immutable(normalized[refId]), ...embue }
        const parent =
          object.parentRefId &&
          object.parentRefId in normalized &&
          normalized[object.parentRefId].parentRefId
            ? _.immutable(normalized[object.parentRefId])
            : null

        const { dimensions: list } = await dispatch(
          'Dimension/getSuggestedDimensions',
          {
            object: {},
            itemName: object.cost_type_name,
            itemDesc: object.cost_type_desc,
            parentName: parent ? parent.assembly_name || parent.quote_name : '',
            limit: 3
          },
          { root: true }
        )

        return list
      },

      async getSuggestedItemTypes({ dispatch }, payload) {
        const { set } = await dispatch('ajax', {
          path: 'quote/getSuggestedItemTypes'
        })

        return {
          set
        }
      },
      async integrateUpstreamChanges({ dispatch, state, commit, rootState }, payload) {
        const { refId } = payload

        const { object } = await dispatch('resolveObject', { refId })

        if (!object.quote_id) return false

        // Quick check
        const hasUpstreamChanges = await dispatch('hasUpstreamChanges', {
          id: object.quote_id,
          sinceTime: object.change_order_time_last_modified
        })

        if (!hasUpstreamChanges.changed) return false

        const currentOriginal = _.imm(state.all[String(object.quote_id)])
        const current = _.imm(state.normalized)

        // fetch current server version
        const { normalized: upstream } = await dispatch('fetchNormalized', {
          id: object.quote_id,
          force: true
        })

        const upstreamChanges = await dispatch('diffNormalized', {
          from: currentOriginal,
          to: upstream
        })
        const localCurrentChanges = await dispatch('diffNormalized', {
          from: currentOriginal,
          to: current
        })
        const combined = {
          ...currentOriginal,
          ...upstream,
          ...current
        }
        const integrated = await dispatch('integrateChangesToSet', {
          startingSet: currentOriginal,
          combinedSet: combined,
          changeSchemas: [upstreamChanges, localCurrentChanges]
        })

        // // rebase changes on fetched server version,
        // // first get the current changes
        // const changes = await dispatch('getChanges', {
        //   refId,
        //   normalized: true,
        // });
        //
        // const norm = _.imm(state.normalized);
        // const stateKeys = Object.keys(norm);
        // const upstreamKeys = Object.keys(upstream);
        // const changeKeys = Object.keys(changes);
        //
        // // Just to have a reference copy of every possible item:
        // const combined = _.merge(norm, upstream);
        //
        // // Get list of items that were just removed if any
        // const removedKeys = changeKeys
        //   .filter(ref => changes[ref].changed === 'removed');
        //
        // // Get upstream items not found in current set
        // const upstreamAll = _.difference(upstreamKeys, removedKeys);
        //
        // const upstreamNew = _.difference(upstreamAll, stateKeys);
        //
        // // Get items added here not found upstream
        // const currentNew = _.difference(_.difference(stateKeys, upstreamKeys),
        //   removedKeys);
        //
        // // Items that were added either here or upstream
        // // need to be added invidivudally
        // const keysToAdd = _.uniq([...upstreamNew, ...currentNew]);
        //
        // // These are items that must have been removed upstream, and
        // // are not affected by any changes here. If they have already been
        // // removed here, then do nothing.
        // let keysToRemove = _.uniq([
        //   ..._.difference(_.difference(_.difference(
        //     stateKeys, upstreamKeys), changeKeys), removedKeys),
        //   ...removedKeys,
        // ]);
        //
        // let merged = {};
        //
        // // Add upstream keys unless they have already been removed currently
        // upstreamAll.forEach((ref) => {
        //   if (keysToRemove.includes(ref)) return;
        //   merged[ref] = upstream[ref];
        // });
        //
        // // Add current items that don't exist on the upstream, unless they are marked removed
        // currentNew.forEach((ref) => {
        //   if (keysToRemove.includes(ref)) return;
        //   merged[ref] = state.normalized[ref];
        // });
        //
        // // Now merge current changes in with upstream
        // changeKeys
        //   .forEach((ref) => {
        //     const obj = ref in state.normalized && state.normalized[ref];
        //
        //     // If this item doesn't exist in upstream, it was just added
        //     // and no merging necessary
        //     if (!obj || !(ref in upstream)) return;
        //
        //     const isAssembly = obj.type === 'assembly' || obj.type === 'quote';
        //
        //     // Add field changes
        //     const fields = _.difference(Object.keys(changes[ref].fieldChanges), [
        //       // List of fields to ignore, for now
        //       'aoChildren', // Will use the strcuture of upstream, add in new items later
        //       'oDimensions', // needs to be merged if changed locally
        //       'oPresentationSettings',
        //     ]);
        //     const fieldMixin = fields.reduce((acc, field) => ({
        //       ...acc,
        //       [field]: obj[field],
        //     }), {});
        //
        //     merged[ref] = {
        //       ...merged[ref], // upstream version
        //       ...fieldMixin, // local changes
        //     };
        //
        //     // Merge oDimensions if they have been locally changed
        //     if (isAssembly && 'oDimensions' in changes[ref].fieldChanges) {
        //       merged[ref].oDimensions = {
        //         ...upstream[ref].oDimensions,
        //         ...obj.oDimensions,
        //       };
        //     }
        //   });
        //
        // // Remove children from aoChildren
        // let childrenFound = [];
        // Object.keys(merged).forEach((ref) => {
        //   const children = (merged[ref].aoChildren || []);
        //   const included = _.intersection(keysToRemove, children);
        //
        //   included.forEach((refToRemove) => {
        //     children.splice(children.indexOf(refToRemove), 1);
        //   });
        //
        //   if (included.length) merged[ref].aoChildren = children;
        //
        //   // Add all teh children that we've seen to keysToRemove so
        //   // that we ensure there are no duplicates
        //   childrenFound = [...childrenFound, ...children];
        //   keysToRemove = [...keysToRemove, ...children];
        // });
        //
        // // After they are added, make sure they appear in their parents children
        // // needs to happen after added, in case the parent(s) are added too
        // keysToAdd.forEach((ref) => {
        //   const parentRef = combined[ref].parentRefId;
        //   if (!(merged[parentRef].aoChildren || []).includes(ref)
        //     && !childrenFound.includes(ref)) {
        //     merged[parentRef].aoChildren.push(ref);
        //   }
        // });

        // Set upstream as the new original
        const cm = await dispatch('getChangeManager', { refId })
        const newOriginal = await dispatch('integrateChangesToSet', {
          startingSet: currentOriginal,
          combinedSet: {
            ...currentOriginal,
            ...upstream
          },
          changeSchemas: [upstreamChanges]
        })
        cm.setOriginal(newOriginal)

        integrated[refId].change_order_time_last_modified = Date.now() + 10

        commit('ADD_NORMALIZED', {
          object: integrated
        })

        await dispatch('auditDependencies', { refId, immediate: true })

        return true
      },

      async hasUpstreamChanges({ dispatch }, payload) {
        const { id, sinceTime } = payload
        const { object } = await dispatch('ajax', {
          path: `quote/hasChangedSince/${id}/${_.timeToSec(sinceTime)}`
        })
        return object
      },

      /**
       * Gets all addons from normalized items,
       * fetches them from the database,
       * and inserts asDimensionsUsed into the addons
       * so if any required diensions for the addon as changed
       * since the last time it was added to the quote,
       * it will be represented here. Can be used in Assembly store
       * if { store: 'Assembly' } is provided in payload.
       * @returns {Promise<void>}
       */
      async updateAddonDimensionsRequired({ dispatch, rootState }, payload = {}) {
        const { store = 'Quote' } = payload

        const norm = rootState[store].normalized

        const addonIds = {
          cost_type: [],
          assembly: []
        }
        const indexReference = {
          cost_type: {},
          assembly: {}
        }

        Object.keys(norm).forEach((refId) => {
          const obj = norm[refId]
          const addons = _.makeArray(obj.aoAddons)

          if (!addons.length) return

          addons.forEach((a) => {
            if (!a.id) return

            if (a.type === 'assembly') {
              addonIds.assembly.push(a.id)
              indexReference.assembly[a.id] = [...(indexReference.assembly[a.id] || []), refId]
            } else {
              addonIds.cost_type.push(a.id)
              indexReference.cost_type[a.id] = [...(indexReference.cost_type[a.id] || []), refId]
            }
          })
        })

        const getRequiredDimensionsByType = async (atype) => {
          const typed = {}

          if (addonIds[atype].length) {
            const { set } = await dispatch(
              `${_.titleCase(atype)}/search`,
              {
                filters: {
                  [`${atype}_id`]: addonIds[atype].join('||')
                }
              },
              { root: true }
            )

            set.forEach((obj) => {
              typed[obj[`${atype}_id`]] = _.makeArray(obj.asDimensionsUsed)
            })
          }

          return typed
        }

        const [cost_type, assembly] = await Promise.all(
          ['cost_type', 'assembly'].map(async (t) => getRequiredDimensionsByType(t))
        )

        const reqByType = { cost_type, assembly }

        const changeSet = {}
        Object.keys(norm).forEach((refId) => {
          const obj = norm[refId]
          let addons = _.makeArray(obj.aoAddons)

          if (!addons.length) return

          let changes = 0
          addons = addons.map((a) => {
            if (!a.id) return a

            const type = a.type
            const id = a.id

            const diff = _.jsonEquals(a.asDimensionsRequired, reqByType[type][a.id]) ? 0 : 1

            if (diff) {
              a.asDimensionsRequired = reqByType[type][a.id]
            }

            return a
          })

          if (changes) {
            changeSet[refId].aoAddons = addons
          }
        })

        if (Object.keys(changeSet).length) {
          dispatch(
            `${store}/fields`,
            {
              changes: changeSet,
              explicit: true,
              skipAudit: false
            },
            { root: true }
          )
        }

        return changeSet
      },

      /**
       * Mark latest version approved by company
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async markApprovedByCompany({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        await dispatch(
          'ChangeOrder/markApprovedByCompany',
          {
            object
          },
          { root: true }
        )

        dispatch(
          'alert',
          {
            message: 'Marked approved.'
          },
          { root: true }
        )

        return payload
      },

      /**
       *
       * @param dispatch
       * @param payload
       * @returns {Promise<boolean>}
       */
      async declineToBid({ dispatch }, payload) {
        const { go = true } = payload

        const { object } = await dispatch('resolveObject', payload)

        if (
          !(await dispatch(
            'modal/asyncConfirm',
            {
              message: 'Are you sure you would like to decline to bid on this project?'
            },
            { root: true }
          ))
        ) {
          return false
        }

        const reason = await dispatch(
          'modal/prompt',
          {
            message: 'Please let us know why you are declining:',
            required: false
          },
          { root: true }
        )

        await dispatch('ajax', {
          path: `/quote/declineToBid/${object.quote_id}`,
          data: {
            reason: `Bid request declined by vendor: ${reason || 'No reason provided.'}`
          }
        })

        if (go) {
          dispatch(
            'viewInList',
            {
              type: 'quote',
              id: object.quote_id
            },
            { root: true }
          )
        }

        return true
      },

      /**
       * Get multiple addons at once
       * @param dispatch
       * @param addons array [{ type: ..., id: ..., }, {...}, ...]
       * @param immediate
       * @param delay
       * @returns {Promise<void>}
       */
      async getAddons(
        { dispatch, state },
        { addons: addonsToFetch, immediate = false, delay = 1500 }
      ) {
        if (!addonsToFetch.length) throw new Error('addons empty')
        addonsQueue = _.imm([...addonsQueue, ...addonsToFetch])

        const clearQueue = async () => {
          const addonTypes = _.imm(addonsQueue)
          addonsQueue = []
          const items = addonTypes.map((i) => {
            if (i.type === 'cost_item') i.type = 'cost_type'
            return i
          })

          const rootRefId = getNormalizedRootRefId(state.normalized)
          const zipcode = state.normalized[rootRefId].quote_postal

          const { object } = await dispatch('ajax', {
            path: 'quote/getAddons',
            data: {
              addons: items,
              immediate,
              zipcode
            }
          })
          addonsExpiry = new Date().valueOf() + addonsTimeLimit

          Object.keys(object).forEach((itemType) => {
            let set = object[itemType] || []

            set.forEach((item) => {
              const t = itemType
              const idField = `${item.type}_id`
              const id = String(item[idField])

              if (!addons[t]) {
                addons[t] = {}
              }

              addons[t][id] = item
            })
          })
        }

        if (immediate) {
          await clearQueue()
        } else {
          await _.throttle(() => clearQueue(), {
            delay,
            debounce: true,
            collapseFuture: false
          })
        }

        return addons
      },

      /**
       * Get addon
       * @param id
       * @param type
       * @returns {Promise<object>}
       */
      async getAddon({ dispatch }, { id, type, force = false }) {
        const stringId = String(id)
        if (
          !addons[type] ||
          !addons[type][stringId] ||
          force ||
          addonsExpiry <= new Date().valueOf()
        ) {
          await dispatch('getAddons', {
            immediate: true,
            addons: [
              {
                type,
                id: stringId
              }
            ]
          })
        }

        if (!addons[type] || !addons[type][stringId]) {
          return {
            object: null,
            set: []
          }
        }

        return { object: addons[type][stringId], set: [addons[type][stringId]] }
      },

      /**
       *
       * @param dispatch
       * @param commit
       * @param state
       * @param payload
       * @returns {Promise<*>}
       */
      async duplicate({ dispatch, commit, state }, payload) {
        const { go = true } = payload

        const { id, object: originalQuote } = await dispatch('resolveObject', payload)

        const clientId = await dispatch(
          'modal/prompt',
          {
            message: 'Choose a client to create this quote for',
            inputType: 'choose',
            entityType: 'client',
            defaultValue: originalQuote.client_id
          },
          { root: true }
        )

        if (!clientId) return null

        dispatch('addLoading', payload, { root: true })

        const createdQuote = await dispatch(
          'Client/quote',
          { id: clientId, go: false },
          { root: true }
        )
        const { normalized: newNormalized, rootRefId } = await dispatch('fetchNormalized', {
          id: createdQuote.quote_id
        })
        const quote = newNormalized[rootRefId]

        const { normalized } = await dispatch('fetchNormalized', { id })

        let oldRootRefId = await dispatch('getRootRefId', {
          normalized
        })

        const refsToConvertTo = []
        refsToConvertTo[Object.keys(normalized).indexOf(oldRootRefId)] = rootRefId
        let { set: rereferenced } = await dispatch('rereference', {
          normalized,
          refsToConvert: Object.keys(normalized),
          refsToConvertTo
        })

        ;[
          'quote_id',
          'quote_name',
          'client_id',
          'quote_address',
          'quote_suite',
          'quote_city',
          'province_id',
          'quote_postal',
          'change_order_id',
          'quote_status',
          'change_order_status',
          'item_id',
          'refId',
          ['children_item_ids', null],
          ['quote_time_created', null],
          ['quote_time_last_modified', null],
          ['quote_time_sent', null]
        ].forEach((change) => {
          const isArray = Array.isArray(change)
          const field = isArray ? change[0] : change
          const value = isArray ? change[1] : quote[field]
          rereferenced[rootRefId][field] = value
        })
        rereferenced[rootRefId].quote_name = `${originalQuote.quote_name} (COPY)`

        const { object } = await dispatch('ajax', {
          path: `quote/saveNormalized/${quote.quote_id}`,
          data: {
            normalized: rereferenced,
            changes: [],
            explicitChanges: []
          },
          queue: false
        })

        const newQuoteId = object[rootRefId].quote_id

        if (go) {
          dispatch('to', `/quote/${newQuoteId}`, { root: true })
        }

        dispatch('endLoading', payload, { root: true })

        return object
      },
      /**
       *
       * @param dispatch
       * @param commit
       * @param changeOrder change order id
       * @param refId quote refId
       * @returns {Promise<void>}
       */
      async checkoutChangeOrder(
        { dispatch, commit, state },
        { refId, changeOrder: changeOrderId }
      ) {
        const { object } = await dispatch('ajax', {
          path: `change_order/fetch/${changeOrderId}`
        })

        dispatch(
          'alert',
          {
            message: `You have checked out a different change order/stage.
                If you would like to save this project back to this state, simply save or force-save now.`,
            timeout: 10000
          },
          { root: true }
        )

        const { type: omit, aoChildren, oNormalized, ...changes } = object

        const quote = {
          ...state.normalized[refId],
          ...changes,
          type: 'quote'
        }

        const normalized =
          oNormalized && Object.keys(oNormalized).length
            ? {
                ...oNormalized,
                [refId]: {
                  ...state.normalized[refId],
                  ...(oNormalized[refId] || {}),
                  ...changes,
                  refId,
                  type: 'quote'
                }
              }
            : normalize(
                {
                  ...state.normalized[refId],
                  ...changes,
                  aoChildren,
                  refId,
                  type: 'quote'
                },
                false,
                refId
              )

        return dispatch('loadNormalizedVersion', {
          normalized,
          refId
        })
      },

      /**
       * Get list of change orders
       * @returns {Promise<void>}
       */
      async getChangeOrders({ dispatch }, payload) {
        let { id = null } = payload

        if (!id) {
          const { id: resolved } = await dispatch('resolveObject', payload)
          id = resolved
        }

        const { set } = await dispatch('ajax', {
          path: `quote/getChangeOrders/${id}`
        })

        return { set }
      },

      /**
       * Invoice project
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async rate({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        if (!/f|g/.test(object.quote_status)) {
          throw new UserError('Project must be in-progress, or completed to review a project.')
        }

        return dispatch(
          'modal/open',
          {
            modal: 'User',
            embue: {
              user_id: object.owner_id,
              startingTab: ['Rating']
            },
            go: false
          },
          { root: true }
        )
      },

      /**
       * Invoice project
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async invoice({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        if (!/k|f/.test(object.quote_status)) {
          throw new UserError('Project must be booked, or in-progress to create an invoice.')
        }

        return dispatch(
          'modal/open',
          {
            modal: 'InvoiceNew',
            embue: { quote_id: object.quote_id },
            go: false
          },
          { root: true }
        )
      },

      /**
       * Two-Factor Authentication
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async twoFactorAuth({ dispatch }, payload) {
        const { refId } = payload

        return dispatch(
          'modal/open',
          {
            modal: {
              name: 'TwoFactorAuth',
              refId
            },
            go: false
          },
          { root: true }
        )
      },

      /**
       * Opens upgrade details modal
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async openUpgradesModal({ dispatch }, payload) {
        const {
          thumbs,
          itemName,
          description,
          object,
          parentName,
          price,
          dimensions,
          urls,
          properties,
          priceTag,
          priceAction,
          addon,
          refId
        } = payload

        return dispatch(
          'modal/open',
          {
            modal: {
              name: 'UpgradeDetails',
              thumbs,
              itemName,
              description,
              object,
              parentName,
              price,
              dimensions,
              urls,
              properties,
              priceTag,
              priceAction,
              addon,
              refId
            },
            go: false
          },
          { root: true }
        )
      },

      /**
       * Opens Optional details modal
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async openOptionalModal({ dispatch }, payload) {
        const { thumbs, itemName, description, object, urls, properties, refId } = payload

        return dispatch(
          'modal/open',
          {
            modal: {
              name: 'OptionalDetails',
              thumbs,
              itemName,
              description,
              object,
              urls,
              properties,
              refId,
              store: await dispatch('getStoreName')
            },
            go: false
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param commit
       * @param dispatch
       * @param payload
       * @param at  location to put child at -1 default
       * @returns {Promise<void>}
       */
      async addFetchItem({ state, commit, dispatch }, payload) {
        const {
          refId, // parent refid
          child = {}, // embue
          field = 'aoChildren',
          skipAudit = false,
          type,
          id,
          at = null
        } = payload

        if (!(refId in state.normalized)) return payload

        const quoteRefId = await dispatch('getNormalizedRootRefId', {
          normalized: state.normalized,
          refId
        })
        const quoteId = state.normalized[quoteRefId].quote_id

        const embue = {
          ...child,
          parentRefId: refId
        }

        let { object: savedNormalized } = await dispatch('ajax', {
          path: `quote/addFetchItem/${quoteId}`,
          data: {
            embue,
            type,
            id
          }
        })

        // Turn cost types into cost items
        const reTyped = {}
        Object.keys(savedNormalized).forEach((r) => {
          reTyped[r] = savedNormalized[r]
          if (savedNormalized[r].type === 'cost_type') {
            reTyped[r].type = 'cost_item'
          }
        })

        const { normalized: defaulted } = await dispatch('buildDefaultObjectNormalized', {
          embue: reTyped
        })

        let childRefId
        Object.keys(savedNormalized).forEach((r) => {
          if (!(savedNormalized[r].parentRefId in savedNormalized)) {
            childRefId = r
          }
        })

        const children = state.normalized[refId][field]
        children.splice(at || children.length, 0, childRefId)

        savedNormalized = {
          ...savedNormalized,
          [refId]: {
            ...state.normalized[refId],
            [field]: children
          }
        }

        commit({
          type: types.ADD_NORMALIZED,
          object: savedNormalized
        })

        const changeManager = await dispatch('getChangeManager', { refId })
        changeManager.trigger('added', [childRefId])

        // Always do a quick audit
        if (field === 'aoChildren' && !skipAudit) {
          await dispatch('auditDependencies', {
            refId: refId,
            immediate: true,
            force: true
          })
        }

        return { ...payload, object: savedNormalized, refId: childRefId }
      },

      async getSavePayload({ commit, state, dispatch, rootGetters }, payload) {
        const { refId: ref = null, requireChanges = false } = payload

        if (!ref) {
          throw new Error('Either refId or normalized must be provided for quote save.')
        }

        const refId = getNormalizedRootRefId(state.normalized, ref)

        const changes = await dispatch('getChanges', { refId, normalized: true })
        const explicitChanges = await dispatch('getExplicitChanges', { refId, normalized: true })
        const removed = await dispatch('getRemoved', { refId })

        let normalized = {}
        // Collect all required objects
        Object.keys(changes).forEach((reference) => {
          normalized[reference] = state.normalized[reference]
        })

        // make sure root object is included in normalized with latest changes
        normalized[refId] = {
          ...state.normalized[refId],
          ...(normalized[refId] || {})
        }

        return {
          normalized,
          changes: [changes],
          explicitChanges: [explicitChanges],
          removed
        }
      },

      async saveAndBook({ commit, state, dispatch, rootGetters }, payload) {
        const {
          refId: ref = null,
          requireChanges = false,
          changeOrderId,
          file: preSignedFile = false,
          getTermsApproval = true,
          termsAccepted,
          signature
        } = payload

        if (!ref) {
          throw new Error('Either refId or normalized must be provided for quote save.')
        }

        const refId = getNormalizedRootRefId(state.normalized, ref)
        const quoteId = state.normalized[refId].quote_id

        const data = await dispatch('getSavePayload', payload)
        const userMeta = await dispatch('getUserMeta', {}, { root: true })

        data.oClientMeta = { ...userMeta, signature }
        data.change_order_id = changeOrderId
        data.change_order_approved_terms = termsAccepted

        let ajaxPayload = await dispatch('ajax', {
          path: `quote/saveAndBook/${quoteId}`,
          data,
          queue: false
        })

        await dispatch('resetChanges', { refId })

        return ajaxPayload
      },

      /**
       * Overrides entityStores.save to allow normalized saving.
       * @param commit
       * @param state
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async save({ commit, state, dispatch, rootGetters }, payload) {
        const { refId: ref = null, requireChanges = false } = payload

        if (!ref) {
          throw new Error('Either refId or normalized must be provided for quote save.')
        }

        const { key: queueKey } = await dispatch('queueRequest', {
          ...payload,
          key: `save-quote`
        })

        const guestUser = rootGetters.isGuestUser

        const refId = getNormalizedRootRefId(state.normalized, ref)
        const quoteId = state.normalized[refId].quote_id

        await dispatch('auditDependencies', {
          immediate: true,
          refId,
          queue: false
        })

        const data = await dispatch('getSavePayload', payload)

        if (requireChanges && !Object.keys(data.explicitChanges).length) {
          return {
            object: {},
            normalized: {}
          }
        }

        const path = guestUser
          ? `quote/saveForUpgrades/${quoteId}`
          : `quote/saveNormalized/${quoteId}`

        let reloadedSet
        try {
          const { object } = await dispatch('ajax', {
            path,
            data,
            queue: false
          })

          if (object && object[refId]) {
            object[refId].change_order_time_last_modified = Date.now() + 100
          }

          const { set: selectiveSet } = await dispatch('selectiveReload', {
            refId,
            normalized: object,
            queue: false
          })

          reloadedSet = selectiveSet

          commit(
            `Quote/${types.ADD_FETCHED}`,
            {
              object: reloadedSet,
              audit: false,
              normalized: true,
              refId
            },
            { root: true }
          )

          await dispatch('resetChanges', { refId })
        } catch (e) {
          throw new UserError(
            {
              userMessage:
                e.userMessage || 'Could not save, try again.  Contact support if this persists.'
            },
            e
          )
        } finally {
          dispatch('queueNext', { key: queueKey })
        }

        return {
          object: reloadedSet,
          normalized: reloadedSet
        }
      },

      /**
       * Get modification object used on this quote
       * @param getters
       * @param state
       * @param refId
       * @returns {any}
       */
      async getQuoteMod({ rootGetters: getters, rootState }, payload) {
        const { refId, store = 'Quote' } = payload

        const state = rootState[store]
        const rootRefId = getNormalizedRootRefId(state.normalized, refId)

        if (
          state.normalized[rootRefId] &&
          state.normalized[rootRefId].oMod &&
          state.normalized[rootRefId].oMod.mod_id
        ) {
          return state.normalized[rootRefId].oMod
        }

        return getters.defaultMod
      },

      /**
       *
       * @param dispatch
       * @param payload
       *    @param parent parent refId
       *    @param type   child type, cost_type, cost_item, or assembly
       *    @param id     child id  or 'blank' for blank items/assemblies
       *    @param name   default name for blank items
       *    @param embue
       *    @param optional add as optional item
       *    @param at index to add at, -1 defaul at end
       * @returns {Promise<void>}
       */
      async getQuoteItem({ dispatch, rootState, state, rootGetters: getters }, payload) {
        let {
          parent: parentRefId,
          type: newType,
          id,
          name = '',
          embue = {},
          optional = 0
        } = payload

        let child = {}

        const { object: parent } = await dispatch('resolveObject', { refId: parentRefId })

        let prefix = /cost_type|cost_item|autocost/.test(newType) ? 'cost_item' : 'quote'
        let itemType = /cost_type|cost_item|autocost/.test(newType) ? 'cost_item' : 'assembly'

        const isOptional = optional ? 1 : 0
        if (id === 'blank' && (newType === 'cost_item' || newType === 'cost_type')) {
          const { object: laborType } = await dispatch(
            'LaborType/resolveObject',
            { id: 'craftsman-3' },
            { root: true }
          )
          child = {
            ...laborType,
            type: 'cost_item',
            cost_matrix_markup_net: getters.defaultMarkup,
            cost_type_name: name,
            cost_item_is_optional: isOptional
          }
        } else if (id === 'blank' && newType === 'assembly') {
          child = {
            assembly_name: name || 'New',
            quote_name: name || 'New',
            quote_link_area: 1,
            aoChildren: [],
            assembly_is_optional: isOptional,
            type: 'assembly'
          }
        }

        child[`${prefix}_qty_net_base`] = 1
        child[`${itemType}_is_optional`] = isOptional
        child[`${itemType}_link_qty`] = 1

        // We need to add parent_item_id because the
        // parent might be new, and have a temporary refId
        // but it SHOULD have a item_id
        child.parent_item_id = parent.item_id || null

        child = {
          ...child,
          ...embue
        }

        try {
          // ;({ object: child } = await dispatch('buildDefaultObject', { embue: child, type: itemType }))
          if (newType === 'autocost') {
            dispatch('alert', { message: 'Fetching AutoCost live pricing' }, { root: true })

            const rootRefId = getNormalizedRootRefId(state.normalized)
            const quote = state.normalized[rootRefId]
            const company = rootState.session.company
            const zipcode = AutoCost.getAutoCostZipcode(company, quote)

            const response = await dispatch('ajax', {
              path: 'live_price/fetchLivePriceItem',
              data: {
                live_price_reference: id,
                quote_id: quote.quote_id,
                zipcode
              }
            })

            const source = response.payload
            const unixTimestamp = Math.floor(new Date().getTime() / 1000).toString()

            const hasLabor = AutoCost.isCraftsmanLaborObject({
              autocost_id: source.live_price_reference
            })
              ? 1
              : 0
            const hasMaterials = hasLabor ? 0 : 1

            child = {
              cost_type_name: source.name,
              cost_type_desc: source.description,
              unit_of_measure_id: source.unit_of_measure_id,
              unit_of_measure_abbr: source.unit_of_measure_abbr,
              cost_type_has_materials: hasMaterials,
              cost_type_has_labor: hasLabor,
              cost_type_is_subcontracted: 0,
              cost_matrix_materials_cost_net: source.material_rate,
              cost_type_hours_per_unit: source.hours_per_unit,
              live_price_reference: source.live_price_reference,
              live_price_last_fetch: unixTimestamp,
              labor_type_id: source.labor_id,
              labor_type_name: source.labor_name,
              labor_type_rate_net: source.labor_rate,
              aoImages: source.aoImages,
              file_ids: source.aoImages,
              type: 'cost_item',
              cost_matrix_markup_net: getters.defaultMarkup,
              cost_item_is_optional: isOptional,
              csi_code_id: source.csi_code || null,
              stage_id: source.stage_id || null,
              live_price_online_stock: source.live_price_online_stock,
              live_price_store_stock: source.live_price_store_stock,
              live_price_vendor_id: source.live_price_vendor_id,
              live_price_vendor_name: source.live_price_vendor_name,
              live_price_item_url: source.live_price_item_url,
              live_price_store_id: source.live_price_store_id,
              live_price_store_name: source.live_price_store_name,
              cost_type_sku: source.sku
            }
          } else if (id !== 'blank') {
            const { object: fetchedItem } = await dispatch(
              `${_.titleCase(newType)}/fetch`,
              {
                id
              },
              { root: true }
            )

            // if this is a variation item, actually get the parent,
            // then choose this as the variation
            if (
              fetchedItem.type !== 'assembly' &&
              (fetchedItem.variation_parent_cost_type_id ||
                fetchedItem.oVariations?.selectedItem ||
                fetchedItem.cost_type_is_variation_parent)
            ) {
              // Either the selected variation is this item that was added if a variation was
              // directly added to the quote, OR if they added thep arent, grab the variation
              // from the selectedItem slot if there is one to get the latest on that item
              const variantId =
                fetchedItem.oVariations?.selectedItem?.id ||
                (fetchedItem.cost_type_is_variation_parent &&
                  fetchedItem?.aoVariationItems?.[0]?.id) ||
                id

              // Depending on whether a variant or the parent was added, find the parent
              const parentId =
                fetchedItem.oVariations?.parentId ?? fetchedItem.variation_parent_cost_type_id ?? id

              const isVariant = variantId === id

              if (
                !isVariant ||
                !(await dispatch(
                  'modal/asyncConfirm',
                  {
                    message: `${fetchedItem.cost_type_name} is a variant in an options group.
                 Would you like to add the variation options, or only load this single item?`,
                    choices: [
                      {
                        value: true,
                        title: 'Load single item only',
                        icon: 'cube',
                        desc: 'This will load only this item without the possibility of the other options for your client.'
                      },
                      {
                        value: false,
                        title: 'Load the variations',
                        icon: 'swatchbook',
                        desc: `This will enable your customer to choose from any of the variants, with ${fetchedItem.cost_type_name} being pre-selected for them.`
                      }
                    ]
                  },
                  { root: true }
                ))
              ) {
                // Now, add selection
                const { changes } = await dispatch(
                  'CostItem/embueVariant',
                  {
                    variantId,
                    parentId
                  },
                  { root: true }
                )

                child = { ...child, ...fetchedItem, ...changes } // reset to cost_type which is the required return for this function unless its an assembly
              } else {
                child = { ...child, ...fetchedItem }
              }
            } else {
              child = { ...child, ...fetchedItem }
            }
            // Set parent_cost_type_id of legacy catalog items to 'NULL' (root company catalog category)
            // Prevents unintended saving of legacy catalog items back into the legacy catalog
            if (fetchedItem.type === 'assembly' && fetchedItem.assembly_status === 'y') {
              child = { ...child, parent_cost_type_id: 'NULL' }
            }
          }
          const { object } = await dispatch('buildDefaultObject', { embue: child, type: itemType })
          return { object }
        } catch (e) {
          const { userMessage = 'Could not add that item. Please try again.' } = e

          dispatch(
            'alert',
            {
              message: userMessage
            },
            { root: true }
          )

          throw e
        }
      },
      /**
       * Add a task item to the quote
       * @returns {String}
       */
      async addTaskItem({ dispatch }, payload) {
        const { embue = {}, parent } = payload
        const { addedChildrenRefIds } = await dispatch(`addItems`, {
          items: [
            {
              cost_type_id: 'blank',
              type: 'cost_type'
            }
          ],
          parent,
          optional: 0,
          skipAudit: false,
          embue
        })
        const refId = addedChildrenRefIds[0]
        return refId
      },

      /**
       * Add an assembly/template to the quote
       * @returns {String}
       */
      async addTemplateItem({ dispatch }, payload) {
        const { embue = {}, parent } = payload
        const { addedChildrenRefIds } = await dispatch(`addItems`, {
          items: [
            {
              assembly_id: 'blank',
              type: 'assembly'
            }
          ],
          parent,
          optional: 0,
          skipAudit: false,
          embue
        })
        const refId = addedChildrenRefIds[0]
        return refId
      },

      /**
       * Quicker way to add multiple items at once
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async addItems({ dispatch }, payload) {
        const {
          items,
          parent,
          optional = 0,
          skipAudit = false,
          embue = {},
          position = -1,
          replaceItemRefId = null
        } = payload
        const payloads = await Promise.all(
          items.map((item) =>
            dispatch('getQuoteItem', {
              type: item.type,
              id: item[`${item.type}_id`],
              optional,
              parent,
              embue
            })
          )
        )

        const addedChildDetails = await dispatch('addChildren', {
          refId: parent,
          children: payloads.map((p) => p.object),
          position,
          replaceItemRefId,
          skipAudit
        })

        return addedChildDetails
      },

      /**
       *
       * @param dispatch
       * @param state
       * @param payload
       *  @param refId assemby refid
       * @returns {Promise<unknown>|boolean}
       */
      async confirmDimensions({ dispatch, state }, payload) {
        const { refId } = payload

        const rootObj = state.normalized[refId]
        let confirmFor = rootObj.type === 'assembly' ? refId : rootObj.parentRefId
        const goDownstream = rootObj.type === 'assembly'
        if (rootObj.type !== 'assembly') {
          return false
        }

        // Check if there are dimensions used
        const obj = confirmFor && state.normalized[confirmFor]
        const dims = obj.oDimensions

        // Get all downstream required
        let downstreamRefIds = [confirmFor]
        const getDown = (refId) => {
          if (!(refId in state.normalized)) return

          const children = state.normalized[refId].aoChildren || []
          downstreamRefIds = [...downstreamRefIds, ...children]

          if (!children.length) return

          children.forEach((childRef) => {
            getDown(childRef)
          })
        }
        getDown(confirmFor)

        downstreamRefIds = _.uniq(downstreamRefIds)

        const confirm = downstreamRefIds.filter(
          (ref) =>
            (state.normalized[ref].type === 'assembly' &&
              state.normalized[ref].asRequiredDimensions.length &&
              state.normalized[ref].asRequiredDimensions.length) ||
            state.normalized[ref].cost_item_is_optional ||
            state.normalized[ref].assembly_is_optional
        ).length

        if (!confirm) return false

        return _.throttle(
          async () => {
            let resolve = () => {}
            const promise = new Promise((r) => {
              resolve = r
            })

            dispatch(
              'modal/open',
              {
                modal: {
                  name: 'AssemblyConfirmDimensions',
                  refId: confirmFor,
                  store: await dispatch('getStoreName'),
                  closed() {
                    resolve(true)
                  }
                }
              },
              { root: true }
            )

            return promise
          },
          { delay: 200, key: confirmFor }
        )
      },

      async addChildPreferences({ dispatch, state }, payload = {}) {
        const { refId, force = true } = payload

        const obj = state.normalized[refId]
        const type = obj.type
        const idType = type === 'assembly' ? 'assembly' : 'cost_type'

        // Only ask preferences when it is a global saved object
        const getChildPrefs = !obj.company_id && obj[`${idType}_id`]

        if (!force && !getChildPrefs) return refId

        await dispatch('embueChildPreferences', {
          refId
        })

        // Only ask preferences when it has labor
        const hasLabor =
          obj.type === 'assembly' ? obj.quote_labor_cost_net_base > 0 : obj.cost_type_has_labor

        if (!hasLabor && !force) return refId

        let resolve = () => {}
        const promise = new Promise((r) => {
          resolve = r
        })

        const name = `Added${type === 'cost_item' ? 'Item' : 'Assembly'}Preferences`
        dispatch(
          'modal/open',
          {
            modal: {
              name,
              object: obj,
              refId,
              store: await dispatch('getStoreName'),
              closed() {
                resolve(refId)
              }
            }
          },
          { root: true }
        )

        return promise
      },

      /**
       * Embue defaults
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async embueChildPreferences({ dispatch, state }, payload) {
        const { refId: addedRefId } = payload
        const norm = state.normalized
        const obj = norm[addedRefId]

        const itemDefaults = await dispatch('CostItem/getObjectDefaults', {}, { root: true })
        const laborRatesByTradeType = await dispatch('LaborType/getByTradeType', {}, { root: true })

        const changeSet = {}

        const getLaborType = (tradeTypeId) => {
          if (tradeTypeId && String(tradeTypeId) in laborRatesByTradeType) {
            return laborRatesByTradeType[String(tradeTypeId)]
          }

          return null
        }

        const embueChild = (refId) => {
          if (
            norm[refId].type === 'assembly' &&
            norm[refId].aoChildren &&
            norm[refId].aoChildren.length
          ) {
            norm[refId].aoChildren.forEach((childRef) => embueChild(childRef))
          } else {
            changeSet[refId] = {
              ...changeSet[refId]
              // cost_type_is_subcontracted: itemDefaults.cost_type_is_subcontracted,
            }

            // add labor rate
            const laborType = getLaborType(norm[refId].trade_type_id)
            if (laborType) {
              changeSet[refId] = {
                ...changeSet[refId],
                labor_type_id: laborType.labor_type_id,
                labor_type_rate_net: laborType.labor_type_rate_net,
                labor_type_name: laborType.labor_type_name
              }
            }
          }
        }

        embueChild(addedRefId)

        return dispatch('field', {
          skipLocalAudit: true,
          skipAudit: true,
          changes: changeSet
        })
      },

      /**
       * Open a map with directions to quote/project
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async map({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', { ...payload, forceFull: true })

        return dispatch(
          'openMap',
          {
            city: object.quote_city || object.user_city,
            prov: object.quote_prov || '',
            address: object.quote_address || object.user_address
          },
          {
            root: true
          }
        )
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async call({ dispatch }, payload) {
        let { tags = {} } = payload

        const { object } = await dispatch('resolveObject', { ...payload, forceFull: true })

        return dispatch(
          'Client/call',
          {
            id: object.client_id,
            tags: {
              ...tags,
              quote_id: object.quote_id
            }
          },
          { root: true }
        )
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async message({ dispatch }, payload) {
        let { tags = {} } = payload

        dispatch(
          'alert',
          {
            message: 'Not yet available. Coming soon!'
          },
          {
            root: true
          }
        )
      },

      /**
       *
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async getClient({ dispatch }, payload) {
        const { object: quote } = await dispatch('resolveObject', payload)
        const { object: client } = await dispatch(
          'Client/resolveObject',
          { id: quote.client_id },
          { root: true }
        )

        return client
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float profit
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setMod({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let modId = String(id).toUpperCase()
        let rootRef = object.refId

        if (modId.length < 3) {
          // not valid
          return false
        }

        // If canada, do first 3 only
        if (/[a-zA-Z]/.test(modId)) {
          modId = modId.slice(0, 3)
        }

        let mod
        const { object: modO } = await dispatch(
          'Mod/fetch',
          {
            id: modId
          },
          { root: true }
        )
        mod = modO

        const oldMod = object.oMod || {}
        const norm = rootState[store].normalized
        // re-cost everything for new mod

        const changes = {}

        Object.keys(norm).forEach((r) => {
          if (norm[r].type !== 'cost_item') return
          const { changes: modChanges } = CostType.reMod(norm[r], mod, oldMod)
          changes[r] = modChanges
        })

        const rootChanges = {
          mod_id: mod.mod_id,
          mod_labor_net: mod.mod_labor_net,
          mod_materials_net: mod.mod_materials_net,
          oMod: mod
        }

        changes[rootRef] = {
          ...((rootRef in changes && changes[rootRef]) || {}),
          ...rootChanges
        }

        const explicitChanges = {
          [rootRef]: rootChanges
        }

        await dispatch('field', {
          changes,
          explicitChanges
        })

        return true
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float profit
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setProfit({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          profit: recProfit,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const profit = _.n(recProfit)

        const netPrice = profit + _.n(object.quote_total_cost_net)

        const { changes, explicitChanges } = await dispatch('setNetPrice', {
          object,
          store,
          netPrice
        })

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async toggleIncluded({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          included: incl,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        // Check if clients are able to make changes to proposal
        const norm = state.normalized
        const rootRefId = getNormalizedRootRefId(norm, refId)
        if (String(norm[rootRefId].quote_is_upgrading_allowed) === '0') {
          dispatch(
            'alert',
            {
              message: `Modifications to this project are currently disabled. Please contact your contractor directly to enable it again.`,
              error: true
            },
            { root: true }
          )

          return false
        }

        return dispatch(
          'CostItem/setIncluded',
          {
            ...payload,
            refId,
            store,
            included: +incl,
            object
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float grossPrice
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setGrossPrice({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          grossPrice: price,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const gross = _.n(price)
        const perc = _.divide(_.n(object.quote_tax), _.n(object.quote_price_net))
        let netPrice = gross

        if (!_.eq(0, perc, 4)) {
          netPrice = gross / (1 + perc)
        }

        const { changes } = await dispatch('setNetPrice', {
          object,
          store,
          netPrice
        })

        const explicitChanges = {
          quote_price_gross: gross
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float netPrice
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setNetPrice({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          netPrice: price,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const net = _.n(price)
        const targetMarkup =
          (net + _.n(object.quote_discount_net)) / _.n(object.quote_total_cost_net)

        const explicitChanges = {
          quote_price_net: net
        }

        const { changes } = await dispatch('setTargetMarkup', {
          object: {
            ...object,
            ...explicitChanges
          },
          store,
          targetMarkup
        })

        return dispatch('reportChanges', {
          ...payload,
          changes: {
            ...changes,
            ...explicitChanges
          },
          explicitChanges,
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id client
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setClient({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          client: id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const { object: client } = await dispatch('Client/resolveObject', { id }, { root: true })

        let explicitChanges = {
          client_id: null,
          client_name: '',
          oClient: null
        }

        let changes = {
          ...explicitChanges
        }

        if (client && client.client_id) {
          explicitChanges = {
            client_id: client.client_id,
            client_name: client.client_name
          }

          changes = {
            ...explicitChanges,
            quote_address: object.quote_address || client.user_address,
            quote_suite: object.quote_suite || client.user_suite,
            quote_city: object.quote_city || client.user_city,
            quote_postal: object.quote_postal || client.user_postal,
            province_id: object.province_id || client.province_id,
            oClient: client
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id tax
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTax({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          tax: id,
          object = _.imm(rootState[store].normalized[refId]),
          updateVendor = true
        } = payload

        const { object: tax } = await dispatch('Tax/resolveObject', { id }, { root: true })

        let explicitChanges = {
          tax_id: null,
          tax_name: '',
          oProjectTaxSettings: {}
        }

        if (tax && tax.tax_id) {
          explicitChanges = {
            tax_id: tax.tax_id,
            tax_name: tax.tax_name,
            oProjectTaxSettings: tax.oTaxSettings
          }
        }

        const changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float discountPercentage 0-100
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setDiscountNet({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          discountNet,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const net = _.n(discountNet)
        const perc = net / _.n(object.quote_price_net_base_undiscounted)

        const explicitChanges = {
          quote_discount_net: net
        }

        const changes = {
          ...explicitChanges,
          quote_discount_percentage: perc,
          quote_discount_net_base: net
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float discountPercentage 0-100
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setDiscountPercentage({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          discountPercentage,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const perc = _.n(discountPercentage) / 100
        const net = perc * _.n(object.quote_price_net_base_undiscounted)

        const explicitChanges = {
          quote_discount_percentage: perc
        }

        const changes = {
          ...explicitChanges,
          quote_discount_net: net,
          quote_discount_net_base: net
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float targetMargin
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTargetMargin({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          targetMargin,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const margin = _.n(targetMargin)
        const markup = _.marginToMarkup(margin)

        const { changes } = await dispatch('setTargetMarkup', {
          object,
          store,
          targetMarkup: markup
        })

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges: {
            quote_margin_net: margin
          },
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float targetMarkup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTargetMarkup({ state, dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          targetMarkup,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const markup = _.n(targetMarkup)

        const originalMarkup = _.n(object.quote_markup_net)

        const totalPerc =
          (markup - 1 - (originalMarkup - 1)) /
          (_.eq(originalMarkup - 1, 0, 5) ? 1 : originalMarkup - 1)

        let margin = _.markupToMargin(markup)

        margin = margin < -0.99 ? -0.99 : margin
        margin = margin > 0.99 ? 0.99 : margin

        const explicitChanges = {
          assembly_markup_percentage_adjustment: totalPerc
        }

        const changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          auditLocal: false
        })
      },
      async getCostItems({ state }, payload) {
        const { refId, rootRefId = getNormalizedRootRefId(state.normalized, refId) } = payload

        return Object.keys(state.normalized)
          .filter(
            (ref) =>
              getNormalizedRootRefId(state.normalized, ref) === rootRefId &&
              state.normalized[ref].type === 'cost_item'
          )
          .map((ref) => state.normalized[ref])
      },
      async getPayableItems({ state, dispatch }, payload) {
        const { refId, rootRefId = getNormalizedRootRefId(state.normalized, refId) } = payload

        const items = await dispatch('getCostItems', { refId })

        return items.filter(
          (item) => !item.cost_item_is_fully_paid && item.cost_item_is_complete_according_to_general
        )
      },
      async makeVendorPayment({ dispatch, state, commit }, payload) {
        const {
          refId, // for quote
          vendorId
        } = payload

        let items = (await dispatch('getPayableItems', { refId })).filter(
          (item) => +vendorId === +item.vendor_id
        )

        if (!items.length) {
          items = (await dispatch('getCostItems', { refId })).filter(
            (item) => +item.vendor_id === +vendorId
          )
        }

        if (!items.length) {
          dispatch(
            'alert',
            {
              error: true,
              message: 'That vendor does not have any items in this project'
            },
            { root: true }
          )
          throw new Error('That vendor does not have any items in this project.')
        }

        const { object: quote } = await dispatch('resolveObject', { refId })

        const { object: vendor } = await dispatch('resolveObject', { id: vendorId, type: 'vendor' })
        let amt = items.reduce((acc, item) => acc + +item.cost_item_unpaid_to_vendor_net, 0)
        const payment = await dispatch(
          'create',
          {
            type: 'vendor_payment',
            embue: {
              vendor_payment_net: amt,
              vendor_id: vendor.vendor_id,
              tax_percentage: vendor.tax_percentage,
              tax_name: vendor.tax_name,
              tax_id: vendor.tax_id,
              quote_id: quote.quote_id,
              aoChildren: items
            }
          },
          { root: true }
        )

        return dispatch('applyVendorPayment', { payment })
      },
      async applyVendorPayment({ dispatch, state, commit }, payload) {
        const { payment } = payload

        const children = payment.aoChildren

        return Promise.all(
          children.map((vc) => {
            // Get the local store version of the object for most up to date
            const child = state.normalized[vc.refId]
            dispatch('field', {
              refId: child.refId,
              changes: {
                vendor_payment_ids: _.makeArray(
                  _.makeArray(child.vendor_payment_ids).push(payment.vendor_payment_id)
                ),
                cost_item_actual_materials_cost_net: vc.cost_item_actual_materials_cost_net,
                cost_item_actual_labor_cost_net: vc.cost_item_actual_labor_cost_net,
                cost_item_actual_total_cost_net: vc.cost_item_actual_total_cost_net,
                cost_item_is_fully_paid: vc.cost_item_actual_total_cost_net,
                cost_item_paid_to_vendor_net: vc.cost_item_paid_to_vendor_net
              }
            })
          })
        )
      },
      async reverseVendorPayment({ dispatch, state, commit }, payload) {
        const { object } = await dispatch('resolveObject', { ...payload, type: 'vendor_payment' })

        const totalPayment = _.toNum(object.vendor_payment_net)
        const paidItems = object.aoChildren || []
        const paidItemRefs = paidItems.map((item) => item.refId)
        const totalItemValue = paidItems.reduce(
          (acc, item) =>
            acc +
            Math.max(
              _.toNum(item.cost_item_total_cost_net),
              _.toNum(item.cost_item_actual_total_cost_net)
            ),
          0.0
        )

        await Promise.all(
          paidItemRefs.map((ref) => {
            const paymentIds = _.makeArray(state.normalized[ref].vendor_payment_ids)
            if (
              ref in state.normalized &&
              _.makeArray(state.normalized[ref].vendor_payment_ids).includes(
                object.vendor_payment_id
              )
            ) {
              const item = state.normalized[ref]
              const itemValue = _.toNum(item.cost_item_actual_total_cost_net)
              const itemWeight = itemValue / totalItemValue
              const weightedReduction = itemWeight * totalPayment
              const newIds = _.makeArray(item.vendor_payment_ids).filter(
                (id) => +id !== +object.vendor_payment_id
              )
              const paidValue = item.cost_item_paid_to_vendor_net - Math.max(0, weightedReduction)
              const fullyPaid = _.eq(paidValue, itemValue, 3) || paidValue > itemValue
              return dispatch('field', {
                refId: ref,
                changes: {
                  vendor_payment_id: null,
                  cost_item_is_fully_paid: fullyPaid,
                  cost_item_paid_to_vendor_net: paidValue,
                  vendor_payment_ids: newIds
                }
              })
            }
            return Promise.resolve()
          })
        )

        return payload
      },

      fetchToLastContract({ dispatch }, { id }) {
        if (!id)
          throw new UserError({
            userMessage: 'No proposal to fetch'
          })
        return dispatch('ajax', {
          path: `/quote/fetchLastApproved/${id}`
        })
      },

      /**
       *
       * @param dispatch
       * @param state
       * @param payload
       *  -documentType: 'quote'|'sow'
       *  -format: 'pdf'|'excel'
       * @returns {Promise}
       */
      async download({ dispatch, state }, payload) {
        const { documentType = 'quote', templateId = null, format = 'pdf' } = payload

        let { changeOrderId = null } = payload

        let presentationSettings = {}

        if (documentType === 'sow' || format === 'excel') {
          const { object } = await dispatch('resolveObject', payload)
          return dispatch(
            'route',
            {
              path: `quote/${object.quote_id}`,
              query: {
                tab: 'Budget'
              }
            },
            { root: true }
          )
        }

        if (!changeOrderId) {
          const { object } = await dispatch('resolveObject', payload)
          changeOrderId = object.change_order_id
        }

        if (documentType === 'contract') {
          const { object: resolved } = await dispatch('resolveObject', payload)
          const { object } = await dispatch('fetchToLastContract', {
            id: resolved.quote_id
          })
          changeOrderId = object.change_order_id
          presentationSettings = {
            showApprovals: 1,
            showTermsAndConditions: 1
          }
        }
        return dispatch(
          'ChangeOrder/downloadContract',
          {
            id: changeOrderId,
            presentationId: templateId,
            presentationSettings
          },
          { root: true }
        )
      },

      async sendApprovalToken({ dispatch, state, rootState }, payload) {
        const { quoteId, method } = payload

        try {
          await dispatch('ajax', {
            path: `/quote/sendInPersonApprovalToken/${quoteId}`,
            data: {
              quoteId,
              method
            }
          })

          if (alert) {
            dispatch(
              'alert',
              {
                message: 'Successfully sent!'
              },
              { root: true }
            )
          }
        } catch (e) {
          if (alert) {
            dispatch(
              'alert',
              {
                message: e.userMessage || 'Could not send... try again.',
                error: true
              },
              { root: true }
            )
          }
          return Promise.reject(e)
        }
        return payload
      },

      async validateApprovalToken({ dispatch, state, rootState }, payload) {
        const { quoteId, token } = payload

        try {
          const response = await dispatch('ajax', {
            path: `/quote/validateInPersonApprovalToken/${quoteId}`,
            data: {
              quoteId,
              token
            }
          })

          return response.payload
        } catch (e) {
          return Promise.reject(e)
        }
      },

      async sendAndUpdate({ dispatch, state, rootState }, payload) {
        const { alert = true, update = {}, sms = true, email = true, forceEmail = null } = payload

        const { object } = await dispatch('resolveObject', payload)

        try {
          await dispatch('ajax', {
            path: `/quote/send/${object.quote_id}`,
            data: {
              update,
              sms,
              email,
              forceEmail
            }
          })

          if (alert) {
            dispatch(
              'alert',
              {
                message: 'Successfully sent!'
              },
              { root: true }
            )
          }
        } catch (e) {
          if (alert) {
            dispatch(
              'alert',
              {
                message: e.userMessage || 'Could not send... try again.',
                error: true
              },
              { root: true }
            )
          }
          return Promise.reject(e)
        }
        return payload
      },

      async send({ dispatch, state, rootState }, payload) {
        const {
          grid = null,
          templateType = 2,
          button = null,
          alert = true,
          sms = true,
          email = true
        } = payload

        const { set: objects = [] } = await dispatch('resolveObject', payload)

        try {
          await dispatch('ajax', {
            path: `/quote/sendMultiple`,
            data: {
              objects: objects.map((q) => ({ type: 'quote', quote_id: q.quote_id })),
              sms,
              email
            }
          })
          if (alert) {
            dispatch(
              'alert',
              {
                message: 'Successfully sent!'
              },
              { root: true }
            )
          }
          if (grid) grid.reload(true)
        } catch (e) {
          if (alert) {
            dispatch(
              'alert',
              {
                message: e.userMessage || 'Could not send... try again.',
                error: true
              },
              { root: true }
            )
          }
          return Promise.reject(e)
        }
        return payload
      },
      resend({ dispatch, state, rootState }, payload) {
        const { grid = null, templateType = 22 } = payload

        return dispatch('send', { ...payload, templateType, grid })
      },

      async decline({ dispatch, rootState }, payload) {
        const { object: resolved } = await dispatch('resolveObject', payload)

        const reason = await dispatch(
          'modal/prompt',
          {
            message: l(
              'Please provide a reason for declining the project',
              getLang(rootState.session.user)
            )
          },
          { root: true }
        )

        if (!reason) {
          return payload
        }

        return await dispatch(
          'ChangeOrder/markMultiple',
          {
            markAs: 'declined',
            selected: [
              {
                type: 'change_order',
                change_order_id: resolved.change_order_id,
                activity_desc: reason,
                change_order_declined_reason: reason
              }
            ],
            go: false
          },
          { root: true }
        )
      },

      async expire({ dispatch, rootState }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        await dispatch('ajax', {
          path: `/quote/expire/${object.quote_id}`
        })
      },

      async unexpire({ dispatch, rootState }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        await dispatch('ajax', {
          path: `/quote/removeExpiration/${object.quote_id}`
        })
      },

      sign({ dispatch, state }, payload) {
        return dispatch('resolveObject', payload).then(({ set }) =>
          dispatch('to', `/pub/presentation/${set[0].quote_id}`, { root: true })
        )
      },
      sendSow({ dispatch, state, rootState }, payload) {
        const { format = 'excel', grid = null } = payload

        return new Promise((resolve) => {
          dispatch('resolveObject', payload).then(({ set: objects }) => {
            const selectedMessages = objects.map((q) => ({
              message_to: '',
              message_cc: null,
              message_subject: `Scope of Work for #${q.quote_id}-${q.quote_name}`,
              tags: {
                quote_id: q.quote_id,
                client_id: q.client_id
              },
              aoFiles: [
                {
                  file_is_virtual: 1,
                  file_virtual_object_id: q.quote_id,
                  file_virtual_object_type: 'sow',
                  file_virtual_object_format: format,
                  file_name: `Quote-${rootState.session.company.company_name_short}-${q.quote_id}.${
                    format === 'excel' ? 'xlsx' : 'pdf'
                  }`,
                  file_type: `document/${format === 'excel' ? 'xlsx' : 'pdf'}`
                }
              ],
              title: `${q.quote_name} • Scope of Work`
            }))
            dispatch(
              'message/composeMultiple',
              {
                templateFilters: {
                  template_type_id: 'none'
                },
                selected: selectedMessages
              },
              { root: true }
            ).then(() => {
              if (grid) grid.reload(true)
              resolve(payload)
            })
          })
        })
      },

      async addOptionalItem({ dispatch, state, commit }, { refId, confirm = true }) {
        const norm = state.normalized

        const rootRefId = getNormalizedRootRefId(norm, refId)
        if (String(norm[rootRefId].quote_is_upgrading_allowed) === '0') {
          dispatch(
            'alert',
            {
              message: `Modifications to this project are currently disabled. Please contact your contractor directly to enable it again.`,
              error: true
            },
            { root: true }
          )

          return false
        }

        const object = norm[refId]

        if (object[`${itemType}_is_included`]) {
          return false
        }

        const itemType = object.type
        const orig =
          object[`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base_original`] || 1
        return dispatch('field', {
          refId,
          changes: {
            [`${itemType}_is_included`]: 1,
            [`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`]: orig,
            [`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`]: orig
          },
          explicit: true
        })
      },

      async removeOptionalItem({ dispatch, state, commit }, { refId, confirm = true }) {
        const norm = state.normalized

        const rootRefId = getNormalizedRootRefId(norm, refId)
        if (String(norm[rootRefId].quote_is_upgrading_allowed) === '0') {
          dispatch(
            'alert',
            {
              message: `Modifications to this project are currently disabled. Please contact your contractor directly to enable it again.`,
              error: true
            },
            { root: true }
          )

          return false
        }

        const object = norm[refId]
        const itemType = norm[refId].type

        if (!object[`${itemType}_is_included`]) {
          return false
        }

        return dispatch('field', {
          refId,
          changes: {
            [`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base_original`]:
              object[`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`],
            [`${itemType}_is_included`]: 0,
            [`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`]: 0,
            [`${itemType === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`]: 0
          },
          explicit: true
        })
      },

      removeItem(refId) {
        return Promise.resolve()
          .then(() => {
            if (this.editing) {
              return Promise.resolve()
            }
            return dispatch('modal/quickConfirm', {
              message: 'Are you sure you would like to remove that item?'
            })
          })
          .then(() =>
            dispatch(`${this.storeName}/removeChild`, {
              refId
            })
          )
      },

      async addAddon(param1, param2) {
        // call set target keys to
        // get original key, if one exists
        // if none exists, create new original key based on target
        // setTargetKeys
      },

      async removeAddon(param1, param2) {
        // call set target keys to
        // get original key, if one exists
        // if none exists, create new original key based on target
        // setTargetKeys
      },

      async selectAddon({ dispatch, state, getters: gets, rootState }, payload) {
        const { refId, addonId, addonType } = payload

        const norm = state.normalized
        const target = norm[refId]
        let addons = [...target.aoAddons]
        const addon = addons.find((a) =>
          addonType === 'live_price'
            ? a.livePriceRef === addonId
            : a.id === addonId && a.type === addonType && !a.livePriceRef
        )
        if (addon.type === 'cost_item') addon.type = 'cost_type'
        if (addon.type === 'live_price') addon.type = 'cost_type'
        // Full fetch
        const hasBulkData = addon.bulk && Object.keys(addon.bulk).length
        const isOriginal = addon.original || (addon.bulk && Object.keys(addon.bulk).length)
        const rootRefId = getNormalizedRootRefId(norm, refId)
        const quote = norm[rootRefId]
        const company = rootState.session.company
        const zipcode = AutoCost.getAutoCostZipcode(company, quote)

        let object
        if (addon.livePriceRef) {
          object = QuoteAddons.removeAddonTags(
            hasBulkData
              ? addon.bulk
              : await dispatch(
                  `CostType/getDefaultLivePriceItem`,
                  {
                    livePriceRef: addon.livePriceRef,
                    company,
                    zipcode
                  },
                  { root: true }
                )
          )
        } else {
          object = QuoteAddons.removeAddonTags(
            hasBulkData
              ? addon.bulk
              : (
                  await dispatch(
                    `${_.titleCase(addon.type)}/fetch`,
                    {
                      id: addon.id
                    },
                    { root: true }
                  )
                ).object
          )

          let materialCost
          let laborRate
          let hours

          // live price data
          let livePriceOnlineStock
          let livePriceStoreStock
          let livePriceVendorId
          let livePriceVendorName
          let livePriceItemUrl
          let livePriceStoreId
          let livePriceStoreName

          if (
            object.type === 'cost_type' &&
            (object.live_price_reference ||
              (object.labor_type_id && AutoCost.isAutoCostLaborTypeId(object.labor_type_id)))
          ) {
            if (object.live_price_reference) {
              const rootRefId = getNormalizedRootRefId(state.normalized)
              const quote = state.normalized[rootRefId]
              const company = rootState.session.company
              const zipcode = AutoCost.getAutoCostZipcode(company, quote)

              const material = await dispatch('ajax', {
                path: 'live_price/fetchLivePriceItem',
                data: {
                  live_price_reference: object.live_price_reference,
                  quote_id: quote.quote_id,
                  zipcode
                }
              })

              materialCost = material.payload.material_rate
              hours = material.payload.hours_per_unit

              livePriceOnlineStock = material.payload.live_price_online_stock
              livePriceStoreStock = material.payload.live_price_store_stock
              livePriceVendorId = material.payload.live_price_vendor_id
              livePriceVendorName = material.payload.live_price_vendor_name
              livePriceItemUrl = material.payload.live_price_item_url
              livePriceStoreId = material.payload.live_price_store_id
              livePriceStoreName = material.payload.live_price_store_name
            } else {
              materialCost = object.cost_matrix_materials_cost_net
              hours = object.cost_type_hours_per_unit
            }

            if (object.labor_type_id && AutoCost.isAutoCostLaborTypeId(object.labor_type_id)) {
              let ref = object.labor_type_id

              const rootRefId = getNormalizedRootRefId(state.normalized)
              const quote = state.normalized[rootRefId]
              const company = rootState.session.company
              const zipcode = AutoCost.getAutoCostZipcode(company, quote)

              const labor = await dispatch('ajax', {
                path: 'live_price/fetchLivePriceItem',
                data: {
                  live_price_reference: ref,
                  quote_id: quote.quote_id,
                  zipcode
                }
              })
              laborRate = labor.payload.labor_rate
            } else {
              laborRate = object.labor_type_rate_net
            }

            const markup = object.cost_matrix_markup_net
            const laborCost = laborRate * hours
            const combinedCost = materialCost + laborCost
            const rate = combinedCost * markup

            object.cost_matrix_labor_cost_net = laborCost
            object.cost_matrix_labor_cost_net_index = laborCost
            object.cost_matrix_materials_cost_net = materialCost
            object.cost_matrix_materials_cost_net_index = materialCost
            object.cost_matrix_aggregate_cost_net = combinedCost
            object.cost_matrix_aggregate_cost_net_index = combinedCost
            object.labor_type_rate_net = laborRate
            object.labor_type_rate_net_index = laborRate
            object.cost_type_hours_per_unit = hours
            object.cost_matrix_rate_net = rate
            object.cost_matrix_rate_net_index = rate
            object.cost_type_is_indexed = 0
            object.labor_type_is_indexed = 0

            object.live_price_online_stock = livePriceOnlineStock
            object.live_price_store_stock = livePriceStoreStock
            object.live_price_vendor_id = livePriceVendorId
            object.live_price_vendor_name = livePriceVendorName
            object.live_price_item_url = livePriceItemUrl
            object.live_price_store_id = livePriceStoreId
            object.live_price_store_name = livePriceStoreName
          }

          if (
            object.type === 'cost_item' &&
            (object.live_price_reference ||
              (object.labor_type_id && AutoCost.isAutoCostLaborTypeId(object.labor_type_id)))
          ) {
            object.cost_type_is_indexed = 0
            object.labor_type_is_indexed = 0
          }

          // Maintain optionality
          if (target[`${target.type}_is_optional`]) {
            object[`${object.type}_is_optional`] = 1
            object.quote_qty_net_base_original =
              target[`${target.type === 'assembly' ? 'quote' : 'cost_item'}_qty_net_base_original`]
          }

          ;(object.oMeta ??= {}).optionGroupName = target.oMeta?.optionGroupName ?? ''
          object.oMeta.optionGroupDesc = target.oMeta?.optionGroupDesc ?? ''
        }

        // Audit with embue (will quantize, embue and audit)
        let audited = await QuoteAddons.auditForAddon({
          dispatch,
          getters: gets,
          norm: state.normalized,
          fetched: object,
          addon,
          refId,
          target
        })

        // Rebuild addon list for new swapped item

        // Package target into addon
        // Find original tag in one of the addons
        // Always pack original as bulk, at the start
        // Note: Bulk items get reduced to type/id when saving the quote
        const packAsBulk = !addons.find((a) => a.bulk && Object.keys(a.bulk).length)

        // If target is original, based on item tag, then re-pack as bulk
        // If target is not original, based on item tag, then re-pack as regular addon

        let packed
        if (packAsBulk) {
          packed = QuoteAddons.getSelectedAsBulkAddon({ norm, refId, target: audited })
        } else {
          packed = QuoteAddons.getAddonFromObject({ auditedObject: audited, target: audited })
        }

        const isAnUpgrade = addon.price > packed.price

        // Only remove, if it is the current, with bulk
        if (hasBulkData) {
          const addonIndex = addons.indexOf(addon)
          addons.splice(addonIndex, 1)
        }

        // Only add if it isn't in there already, which is only bulk
        if (packAsBulk) {
          addons.push(packed)
        }

        // reset addons targetPrice //
        const newTargetPrice =
          audited[audited.type === 'assembly' ? 'quote_subtotal_net' : 'cost_item_price_net_base']
        for (let index = 0; index < addons.length; index += 1) {
          const addon = { ...addons[index] }
          addon.targetPrice = newTargetPrice
          addons[index] = addon
        }

        addons = QuoteAddons.setTargetKeys(addons, target)

        // Set addon tags
        audited = {
          ...audited,
          addon_is_original: isOriginal ? 1 : 0,
          addon_is_upgraded: isOriginal ? 0 : 1, // important marker for back-end
          addon_is_saved: 0,
          addon_is_a_downgrade: isAnUpgrade ? 0 : 1,
          addon_upgraded_by: isOriginal ? null : rootState.session.user.user_id,
          addon_time_upgraded: isOriginal ? null : Date.now(),
          addon_is_notified: 0,
          aoAddons: addons
        }

        if (!norm[rootRefId].quote_is_upgrading_allowed) {
          dispatch(
            'alert',
            {
              message: `Modifications to this project are currently disabled. Please contact your contractor directly to enable it again.`,
              error: true
            },
            { root: true }
          )

          throw new UserError({
            alert: false,
            userMessage: `Modifications to this project are currently disabled. Please contact your contractor directly to enable it again.`
          })
        }

        let fetchedRefIds = []
        // if selection or its children have addons
        if (audited.quote_count_addons_available) {
          await dispatch('addLoading', {}, { root: true })
          // all refIds before selection (addons are fetched already)
          fetchedRefIds = await dispatch('getQuoteRefIds', {})
        }

        await dispatch('replaceChild', {
          parent: target.parentRefId,
          child: refId,
          selected: [audited]
        })

        // if selection or its children have addons
        if (audited.quote_count_addons_available) {
          // all refIds after selection
          const quoteRefIds = await dispatch('getQuoteRefIds', {})

          // new refIds that need addons fetched
          const newRefIds = quoteRefIds.filter((id) => !fetchedRefIds.includes(id))

          // recalc addons of new refIds
          await dispatch('recalcAddons', { refIds: newRefIds, loading: false })

          await dispatch('removeLoading', {}, { root: true })
        }
      },

      getParents({ dispatch, state }, payload = {}) {
        const { refId = null } = payload
        const norm = state.normalized

        const getParents = (p = null) => {
          const parentRefId =
            p || (norm[refId] && norm[refId].parentRefId ? norm[refId].parentRefId : null)

          if (parentRefId && norm[parentRefId]) {
            return [
              norm[parentRefId],
              ...(norm[parentRefId].parentRefId ? getParents(norm[parentRefId].parentRefId) : [])
            ]
          }
          return []
        }

        return Promise.resolve(getParents(refId))
      },

      addEmphasis({ state, dispatch }, { refId }) {
        const obj = state.normalized[refId]
        const emphType = obj.type === 'assembly' ? 'assembly' : 'cost_type'
        let emphasis = _.notNaN(obj[`${emphType}_emphasis`])
        emphasis = Math.min(1, emphasis + 1)
        return dispatch('field', {
          refId,
          changes: {
            [`${emphType}_emphasis`]: emphasis
          },
          skipAudit: true,
          explicit: true
        })
      },
      removeEmphasis({ state, dispatch }, { refId }) {
        const obj = state.normalized[refId]
        const emphType = obj.type === 'assembly' ? 'assembly' : 'cost_type'
        let emphasis = _.notNaN(obj[`${emphType}_emphasis`])
        emphasis = emphType === 'assembly' ? Math.max(-3, emphasis - 1) : Math.max(-1, emphasis - 1)
        return dispatch('field', {
          refId,
          changes: {
            [`${emphType}_emphasis`]: emphasis
          },
          skipAudit: true,
          explicit: true
        })
      },

      /**
       * This function fetches and updates the price of items and addons in a quote
       * @param state
       * @param rootState
       * @param dispatch
       * @param payload
       *    quoteId - the id of the quote
       *    rootRefId - the refId of the root item (quote)
       *    refIds - the refIds of AutoCost items
       *    normalized - the nomralized set of the quote
       *    all - determines if pricing update should be done for all addons in a quote
       *          or only the addons in the given refIds
       * @returns {Promise<void>}
       */
      async fetchLivePricing({ state, rootState, dispatch }, payload) {
        let {
          zipcode = null,
          rootRefId = null,
          refIds = [],
          all = false,
          normalized = state.normalized,
          reviewChanges = true
        } = payload

        try {
          dispatch('alert', { message: 'Fetching AutoCost live pricing' }, { root: true })

          if (!refIds.length) throw new UserError({ userMessage: 'No AutoCost items found.' })

          if (!zipcode) {
            if (!rootRefId) rootRefId = getNormalizedRootRefId(state.normalized)
            const quote = normalized[rootRefId]
            const company = rootState.session.company
            zipcode = AutoCost.getAutoCostZipcode(company, quote)
          }

          const response = await dispatch('fetchCostItemsLivePricing', { zipcode, refIds })

          const time = new Date().getTime()
          const unixTimestamp = Math.floor(time / 1000).toString()

          const changes = {}
          for (const livePriceObject of Object.values(response)) {
            const source = livePriceObject.live_price
            livePriceObject.ref_ids.forEach((refId) => {
              changes[refId] = {
                refId,
                unit_of_measure_id: source.unit_of_measure_id,
                unit_of_measure_abbr: source.unit_of_measure_abbr,
                cost_matrix_materials_cost_net: source.material_rate,
                live_price_last_fetch: unixTimestamp,
                live_price_online_stock: source.live_price_online_stock,
                live_price_store_stock: source.live_price_store_stock,
                live_price_vendor_id: source.live_price_vendor_id,
                live_price_vendor_name: source.live_price_vendor_name,
                live_price_item_url: source.live_price_item_url,
                live_price_store_id: source.live_price_store_id,
                live_price_store_name: source.live_price_store_name,
                cost_type_sku: source.sku
              }
            })
          }

          if (reviewChanges) return changes

          dispatch('updateLivePrices', { changes: Object.values(changes) })
        } catch (e) {
          dispatch(
            'alert',
            {
              message: e.userMessage || e.message || 'Could not update price.',
              error: true
            },
            { root: true }
          )
        }
      },

      async fetchLaborLivePricing({ state, rootState, dispatch }, payload) {
        let { zipcode = null, rootRefId = null, refIds = [], reviewChanges = true } = payload

        try {
          dispatch('alert', { message: 'Fetching AutoCost labor rates' }, { root: true })

          if (!zipcode) {
            if (!rootRefId) rootRefId = getNormalizedRootRefId(state.normalized)
            const quote = normalized[rootRefId]
            const company = this.$store.state.session.company
            zipcode = AutoCost.getAutoCostZipcode(company, quote)
          }

          const liveLaborItems = await dispatch('fetchCostItemLaborLivePricing', {
            zipcode,
            refIds
          })

          const time = new Date().getTime()
          const unixTimestamp = Math.floor(time / 1000).toString()

          const changes = {}

          Object.values(liveLaborItems).forEach((livePriceObject) => {
            livePriceObject.ref_ids.forEach((refId) => {
              changes[refId] = {
                refId,
                labor_type_rate_net: livePriceObject.live_price.labor_rate,
                live_price_last_fetch: unixTimestamp
              }
            })
          })

          if (reviewChanges) return changes

          return dispatch('updateLivePrices', { changes: Object.values(changes) })
        } catch (e) {
          dispatch(
            'alert',
            {
              message: e.userMessage || e.message || 'Could not update AutoCost labor rates.',
              error: true
            },
            { root: true }
          )
        }
      },

      /**
       * This function will fetch and update the price of a given list
       * of AutoCost items (refIds) in a quote
       *
       * @param state
       * @param rootState
       * @param dispatch
       * @param payload
       *    quoteId - the id of the quote
       *    refIds - the refIds of AutoCost items
       *    normalized - the nomralized set of the quote
       * @returns {Promise<boolean>}
       */
      async fetchCostItemsLivePricing({ state, rootState, dispatch }, payload) {
        let { zipcode = null, refIds = [], normalized = state.normalized } = payload

        // set up live_price object to pass to backend
        // This object links live_price_reference to refIds
        const autocostRefs = {}
        const craftmansRefs = {}

        refIds.forEach((ref) => {
          const item = normalized[ref]
          const ven = item.live_price_reference.split('-')[0]
          if (ven.length === 4 && ven === 'crft') {
            if (craftmansRefs[item.live_price_reference]) {
              craftmansRefs[item.live_price_reference].ref_ids.push(item.refId)
            } else {
              craftmansRefs[item.live_price_reference] = {
                ref_ids: [item.refId],
                live_price_reference: item.live_price_reference
              }
            }
          } else if (ven.length === 4 && ven !== 'crft') {
            if (autocostRefs[item.live_price_reference]) {
              autocostRefs[item.live_price_reference].ref_ids.push(item.refId)
            } else {
              autocostRefs[item.live_price_reference] = {
                ref_ids: [item.refId],
                live_price_reference: item.live_price_reference
              }
            }
          }
        })

        // fetch AutoCosts
        const response = await dispatch('ajax', {
          path: 'live_price/fetchMultipleCostItemLivePrice',
          data: {
            zipcode,
            autocost_material: autocostRefs,
            craftsman_material: craftmansRefs
          }
        })

        if (response && !response.error && response.payload) return response.payload
        else return false
      },

      /**
       * This function will fetch and update the labor rate of a given list
       * of AutoCost labor items (refIds) in a quote
       *
       * @param state
       * @param rootState
       * @param dispatch
       * @param payload
       *    quoteId - the id of the quote
       *    refIds - the refIds of AutoCost items
       *    normalized - the nomralized set of the quote
       * @returns {Promise<boolean>}
       */
      async fetchCostItemLaborLivePricing({ state, rootState, dispatch }, payload) {
        let { zipcode = null, refIds = [], normalized = state.normalized } = payload

        // set up live_price object to pass to backend
        // This object links live_price_reference to refIds
        const autocostRefs = {}
        refIds.forEach((ref) => {
          const item = normalized[ref]
          if (item.labor_type_id.startsWith('ac-')) {
            if (autocostRefs[item.labor_type_id]) {
              autocostRefs[item.labor_type_id].ref_ids.push(item.refId)
            } else {
              autocostRefs[item.labor_type_id] = {
                ref_ids: [item.refId],
                live_price_reference: item.labor_type_id
              }
            }
          }
        })

        // fetch AutoCosts
        const response = await dispatch('ajax', {
          path: 'live_price/fetchMultipleCostItemLivePrice',
          data: {
            zipcode,
            autocost_labor: autocostRefs
          }
        })

        if (response && !response.error && response.payload) return response.payload
        else return false
      },

      /**
       * For each AutoCost item - update the corresponding item cost and price
       * @returns {Promise<void>}
       */
      async updateLivePrices({ state, rootState, dispatch }, payload) {
        const { changes = [] } = payload

        changes.forEach((change) => {
          dispatch('field', {
            refId: change.refId,
            changes: {
              ...change
            },
            explicit: true
          })
        })
      },

      /**
       * For each AutoCost item - update the corresponding item cost and margin
       * @returns {Promise<void>}
       */
      async updateLiveCosts({ state, rootState, dispatch }, payload) {
        const { changes = [], norm = state.normalized } = payload

        changes.forEach((change) => {
          change.cost_matrix_markup_net =
            norm[change.refId].cost_matrix_rate_net /
            (change.cost_matrix_materials_cost_net +
              change.cost_type_hours_per_unit * norm[change.refId].labor_type_rate_net)

          dispatch('field', {
            refId: change.refId,
            changes: {
              ...change
            },
            explicit: true
          })
        })
      },
      async submitOpenQuote({ dispatch, commit }, payload) {
        const { lead, item, company } = payload
        try {
          // generate lead and create estimate
          const { object } = await dispatch('ajax', {
            path: '/lead_rotation/openQuoteLead',
            data: {
              lead,
              company
            }
          })
          const { quoteId, leadRequestId, link, lead: leadData } = object
          // fetch quote
          const { normalized, rootRefId } = await dispatch('fetchNormalized', {
            id: quoteId,
            leadRequestId,
            force: true
          })
          commit({
            type: types.ADD_NORMALIZED,
            object: normalized
          })
          // add item to estimate
          await dispatch('addItems', {
            items: [item],
            parent: rootRefId
          })
          // save estimate
          const data = await dispatch('getSavePayload', {
            refId: rootRefId
          })
          await dispatch('ajax', {
            path: 'lead_request/saveQuote',
            data: {
              leadRequestId,
              data
            }
          })
          // return url for redirect
          return {
            link,
            received: leadData.received
          }
        } catch (e) {
          console.log(e, 'ERROR')
        }
      }
    }
  },

  /**
   * Context:
   * this => other computed properties and expected props only
   *
   * Properties:
   * state => the actual state saved properties, localstate
   * parent => the parent state
   * rootState => this.$store.state.[quote|cost_item etc].normalizedSelected;
   * calculations => the parallel state filled with calculations
   *
   * @param c
   * @returns {*}
   */
  getComputedDependants() {
    const n = _.notNaN

    const getAllJoinedDimensions = (state, parent, children, possibleDimensions) => {
      return {
        ...possibleDimensions,
        ..._.imm(state.oDimensions)
      }
    }

    const getDimensionsChildrenRequire = (state, parent, children, possibleDimensions) => {
      // 1. Get all dimensions required by children and addons
      const req = _.uniq([
        // Addon required dimensions
        ...(state.aoAddons || []).reduce(
          (acc, addon) => [...acc, ...(_.makeArray(addon.asDimensionsRequired) || [])],
          []
        ),

        // Childrens' required dimensions
        ...children.reduce((acc, child) => {
          // Get the dimensions that are linked up,
          // and therefore NOT directly set by the child
          // if the child is an assembly, if the child is
          // a cost item asRequiredDimensions will be null anyway
          const carryForward =
            _.difference(
              _.makeArray(child?.asDimensionsUsed) || [],
              _.makeArray(child?.asRequiredDimensions || [])
            ) || []
          return [...acc, ...carryForward]
        }, [])
      ])

      return req
    }

    const getDimensionsUsed = (state, parent, children, possibleDimensions) => {
      const all = getAllJoinedDimensions(state, parent, children, possibleDimensions)

      // 1. Get all dimensions required by children and addons
      const root = !state.parentRefId
      const uniq = getDimensionsChildrenRequire(state, parent, children, possibleDimensions)

      // 2. Get all dimensions, used by other dimensions
      const used = uniq.filter((key) => key in all && (root || !all[key].inherit))

      let alreadyFound = []
      const getRequiredDimesionsFromDimension = (abbr) => {
        const defaultEquation =
          all[abbr].value || all[abbr].explicitlySet ? '' : all[abbr].defaultValue

        const equation =
          all[abbr].equation && typeof all[abbr].equation === 'string'
            ? all[abbr].equation
            : defaultEquation

        const computedDimension = _.getComputedDimension(all, equation, all[abbr].measure)

        const dimUses = computedDimension.usingDimensions

        let recursedUsed = []
        alreadyFound.push(abbr)
        dimUses.forEach((usedAbbr) => {
          if (!alreadyFound.includes(usedAbbr)) {
            recursedUsed = [...recursedUsed, ...getRequiredDimesionsFromDimension(usedAbbr)]
          }
        })

        alreadyFound = [...alreadyFound, ...dimUses, ...recursedUsed]

        return [...dimUses, ...recursedUsed]
      }

      const usedComputedDimensions = used.reduce(
        (acc, abbr) => [...acc, ...getRequiredDimesionsFromDimension(abbr)],
        []
      )

      // 3. combine
      return _.uniq([...uniq, ...usedComputedDimensions])
    }

    return {
      // These fielsd are not in the quote schema (they are cost_item/cost_type/cost_matrix type)
      //  but are required for quote children dependency tracking.  They won't be added to
      //  quote level computed values, but will be used in dependency tracking.
      // ...CostItem.getComputedDependants(),
      oProjectTaxSettings(state, parent) {
        if (state.parentRefId !== null) {
          return parent.oProjectTaxSettings
        }

        let settings = state.oProjectTaxSettings

        // For backwards compatibility
        const legacyPercent = parent ? parent.tax_percentage : state.tax_percentage
        if (!settings || !Object.keys(settings).length || (legacyPercent && !settings.ct.length)) {
          // initial load, quote hasn't been defaulted yet
          // and oProjectTaxSettings hasn't been passed down yet,
          // mock it from legacy n(parent.tax_percentage) value
          settings = CostItem.taxSettingsDefaulter({
            ct: [
              {
                name: parent.tax_name || 'Sales tax',
                pcnt: n(legacyPercent)
              }
            ]
          })
        }

        return settings
      },
      depth(state, parent) {
        const pd = Object.keys(parent).length ? _.n(parent.depth) : -1

        return pd + 1
      },
      item_count_upgrades(state, parent, children = []) {
        const self = state.item_is_upgraded || state.addon_is_upgraded || 0

        const childCount = children.reduce((acc, child) => acc + child.item_count_upgrades, 0)

        return self + childCount
      },
      /**
       * These methods return just the field value
       * @param state
       * @param parent
       * @returns {*}
       */
      oDimensions(state, parent, children, possibleDimensions) {
        const used = getDimensionsUsed(state, parent, children, possibleDimensions)
        const all = getAllJoinedDimensions(state, parent, children, possibleDimensions)

        const explicitlySetDimensions = []
        for (const dim of Object.values(all)) {
          if (dim.explicitlySet && dim.value) {
            explicitlySetDimensions.push(dim.abbr)
          }
        }

        let required = _.uniq([
          // get dimensions that have a value and were set to save them
          ...explicitlySetDimensions,
          ...used,
          // Backwards compati
          ...Object.keys(state.oDimensions ?? {})
        ])

        let pd = false
        if (state.type === 'assembly' && !parent) {
          // assembly store
          pd = _.imm(selected)
        } else if (parent && Object.keys(parent).length) {
          pd = getAllJoinedDimensions(parent, {}, [], possibleDimensions)
        }

        // Does the provided dimension have a value that is derived
        // from other dimension values
        required = _.sortDimensions(all, pd, required)

        const selected = {}
        for (const abbr of required) {
          selected[abbr] = all[abbr]
        }
        let newDimensions = _.imm(selected)

        // First, get all regular inheritted dimensions that are not computed
        // based on the values of other dimensions, if there is a parent
        if (pd) {
          required.forEach((d) => {
            if ((!(d in newDimensions) || newDimensions[d].inherit) && pd[d]) {
              newDimensions[d] = {
                ..._.imm(pd[d]),
                // If it is an assembly, that is in a quote,
                // and the assembly has this dimension as inheriting from parent
                // but the parent doesn't have a dimension set
                // whilst the assembly DOES have it set, use the assembly value
                // to ensure that there is always a value if the assembly needs it
                //pd[d].value === null && newDimensions[d].value !== null && state.type === 'assembly'
                //                   ? newDimensions[d].value
                //                   : pd[d].value,
                value: _.imm(pd[d].value) || 0,
                equation: '', // since we inherit the value, there is no equation
                inherit: 1,
                explicitlySet: 0
              }
            }
          })
        }

        const regx = new RegExp('(?:^|\\b)(' + Object.keys(all).join('|') + ')(?:$|\\b)')
        const equationDerived = (dim) =>
          dim &&
          (!dim.inherit || !pd) &&
          ((dim.equation && regx.test(dim.equation)) || (dim.defaultValue && !dim.explicitlySet))

        // Now figure out the default values for dimensions with values derived from equtions
        required.forEach((d) => {
          if (equationDerived(newDimensions[d])) {
            const formula =
              newDimensions[d].equation && typeof newDimensions[d].equation === 'string'
                ? newDimensions[d].equation
                : newDimensions[d].defaultValue
            // console.log('    formula', d, formula);

            let { value, equation } = _.getComputedDimension(
              newDimensions,
              formula,
              newDimensions[d].measure
            )

            newDimensions[d] = {
              ...newDimensions[d],
              value,
              equation,
              explicitlySet: 0,
              inherit: 0
            }
          }
        })

        return newDimensions
      },
      tax_id(state, parent) {
        return parent && parent.tax_id ? parent.tax_id : state.tax_id
      },
      tax_percentage(state, parent) {
        return parent && typeof parent.tax_percentage !== 'undefined'
          ? n(parent.tax_percentage)
          : n(state.tax_percentage)
      },
      quote_count_addons_available(state, parent, children = []) {
        let addons = (state.aoAddons || []).length || 0
        return children.reduce(
          (acc, child) =>
            acc +
              child?.[
                `${child?.type === 'assembly' ? 'quote' : 'cost_item'}_count_addons_available`
              ] || 0,
          addons
        )
      },
      quote_has_live_pricing(state, parent, children) {
        let hasLivePricing = 0
        children.forEach((child) => {
          if (hasLivePricing) return
          const type = child.type === 'assembly' ? 'quote' : 'cost_item'
          if (child[`${type}_has_live_pricing`]) hasLivePricing = 1
        })
        return hasLivePricing
      },
      aoChildStageTasks(state, parent, children = []) {
        return children.reduce(
          (acc, child) => [
            ...acc,
            ...(child.aoStageTasks || []),
            ...(child.aoChildStageTasks || [])
          ],
          []
        )
      },
      // Override with this schema specific dependencies
      quote_area_gross(state, parent) {
        const r =
          !!state.quote_link_area && Object.keys(parent).length
            ? n(parent.quote_area_gross)
            : n(state.quote_area_gross)
        return state.assembly_minimum_area_net ? Math.max(r, state.assembly_minimum_area_net) : r
      },
      quote_qty_net_base(state) {
        if (state.type === 'assembly' && !state.assembly_is_included) {
          return 0
        }
        return Math.ceil(
          state.assembly_minimum_qty_net
            ? Math.max(state.quote_qty_net_base, state.assembly_minimum_qty_net)
            : state.quote_qty_net_base
        )
      },
      quantity_multiplier(state, parent) {
        return Math.ceil(Object.keys(parent).length ? n(parent.quote_qty_net) : 1)
      },

      quote_cost_net_per_unit(state) {
        return _.divide(state.quote_total_cost_net_base, state.quote_qty_net_base)
      },

      quote_price_net_per_unit(state, parent, children) {
        const added = children.reduce((acc, child) => {
          if (child.type === 'assembly') {
            return acc + child.quote_price_net
          }

          return acc + child.cost_item_price_net
        }, 0)

        // Since the children will have the quantity of this assembly baked into them
        // we have to take it out to get the price per unit
        return _.divide(added, state.quote_qty_net)
      },

      /**
       * Sum of all child prices x qty of this assembly (or 1 for quote)
       */
      quote_price_net_base_undiscounted(state) {
        // Older schemas have assemblies having quote_subtotal_net instead
        //  of the more descriptive quote_price_net_base_undiscounted, so
        //  in order for their totals to register in their parent's total, we must
        //  alternately look for that as well
        // const r = children
        //   .reduce((acc, child) => acc + n(child[`${child.type === 'assembly' ? 'quote' : child.type}_price_net_base`]
        //       || child[`${child.type === 'assembly' ? 'quote' : child.type}_price_net_base_undiscounted`], 0), 0)
        //   * n(state.quote_qty_net_base);

        const added = state.quote_price_net_per_unit

        const r = added * state.quote_qty_net_base

        return r
      },
      quote_labor_cost_net_base(state, parent, children) {
        const amt = children.reduce(
          (acc, child) =>
            acc + n(child[`${child.type === 'assembly' ? 'quote' : child.type}_labor_cost_net`]),
          0
        )

        return _.divide(amt, state.quantity_multiplier)
      },
      quote_materials_cost_net_base(state, parent, children) {
        const amt = children.reduce(
          (acc, child) =>
            acc +
            n(child[`${child.type === 'assembly' ? 'quote' : child.type}_materials_cost_net`]),
          0
        )
        return _.divide(amt, state.quantity_multiplier)
      },
      /**
       * Difference in price of fixed price subtotal and actual sum of child prices.
       *  - Should only be != 0 when assembly is fixed price
       */
      quote_labor_cost_net(state) {
        // 2 decimal points only
        return n(state.quote_labor_cost_net_base) * n(state.quantity_multiplier, 1)
      },
      quote_materials_cost_net(state) {
        // 2 decimal points only
        return n(state.quote_materials_cost_net_base) * n(state.quantity_multiplier, 1)
      },
      /**
       * Sum of all child costs
       */
      quote_total_cost_net(state) {
        // 2 decimal points only
        return n(state.quote_labor_cost_net) + n(state.quote_materials_cost_net)
      },
      quote_total_cost_net_base(state) {
        return n(state.quote_labor_cost_net_base) + n(state.quote_materials_cost_net_base)
      },
      assembly_price_adjustment_net_base(state) {
        let formerPrice =
          state.assembly_fixed_price_net_each && state.assembly_has_set_price
            ? n(state.assembly_fixed_price_net_each) * n(state.quote_qty_net_base)
            : state.quote_price_net_base_undiscounted
        return formerPrice - state.quote_price_net_base_undiscounted
      },

      /**
       * The adjustment when this assembly IS the adjusted parent.
       *
       * If this is set to 0, the assembly markup can STILL be adjusted if the parent is adjusted,
       * which will be represented in totality in quote_markup_percentage_adjustment;
       *
       * Quote_markup_percentage_adjustment does NOT save, but this one DOES.
       *
       * @param state
       * @param parent
       * @returns {assembly_markup_percentage_adjustment|(function(*, *=, *))|{type: string, save: boolean, format: string, trackChanges: boolean}|{type: string, filter: boolean, format: boolean, mapTo: boolean, default: null}|*}
       */
      assembly_markup_percentage_adjustment(state, parent, children) {
        let a = state.assembly_markup_percentage_adjustment
        // const takeFromQuote = !state.parentRefId
        //   && !_.eq(state.quote_markup_percentage_adjustment, 0)
        //   && _.eq(a, 0);
        //
        // if (takeFromQuote) {
        //   a = state.quote_markup_percentage_adjustment;
        // }

        return a
      },

      /**
       * Inherits the adjustment. If parent is adjusted, this will show parent adjustment.
       * If this assembly IS the adjusted assembly,
       * this will show the assembly_markup_percentage_adjustment.
       * @param state
       * @param parent
       * @returns {number}
       */
      quote_markup_percentage_adjustment(state, parent) {
        let m
        if (state.parentRefId) {
          m =
            n(state.assembly_markup_percentage_adjustment) +
            n(parent.quote_markup_percentage_adjustment) +
            n(state.assembly_markup_percentage_adjustment) *
              n(parent.quote_markup_percentage_adjustment)
        } else {
          m = n(
            state.assembly_markup_percentage_adjustment || state.quote_markup_percentage_adjustment
          )
        }
        return m
      },
      quote_count_items(state, parent, children = []) {
        return children.reduce(
          (acc, child) => acc + n(child.type === 'assembly' ? child.quote_count_items : 1),
          0
        )
      },
      quote_count_completed_items(state, parent, children = []) {
        return children.reduce(
          (acc, child) =>
            acc +
            n(
              child.type === 'assembly'
                ? child.quote_count_completed_items
                : child.cost_item_is_complete_according_to_general
            ),
          0
        )
      },
      quote_sum_completed_items(state, parent, children = []) {
        return children.reduce((acc, child) => {
          if (child.type === 'assembly') return acc + child.quote_sum_completed_items
          else if (child.cost_item_is_complete_according_to_general)
            return acc + child.cost_item_total_cost_net
          return acc
        }, 0)
      },

      // quote_materials_cost_net_base(state, parent, children) {
      //   return children
      //     .reduce((acc, child) =>
      //       acc + (
      //       (
      //         n(child[`${child.type === 'assembly' ? 'quote' : child.type}_materials_cost_net_base`])
      //         - (child.type !== 'assembly' ? (
      //           n(child.cost_item_minimum_materials_net_adjustment) // remove one-time costs
      //           + n(child.cost_type_static_materials_cost_net)
      //         ) : 0)
      //       )
      //       * n(state.quote_qty_net_base)), 0);
      // },

      quote_total_hours_base(state, parent, children) {
        return children.reduce(
          (acc, child) =>
            acc + n(child[`${child.type === 'assembly' ? 'quote' : child.type}_total_hours_base`]),
          0
        )
      },

      /**
       * Adjustment from top quote level markup/margin setting.
       * ONLY finds for adjustment based on THIS objects quote_markup_percentage_adjustment.
       */
      quote_price_net_adjustment(state, parent, children) {
        return children.reduce((acc, child) => {
          if (
            child.type === 'cost_item' &&
            child.cost_item_price_net_adjustment &&
            _.eq(child.cost_type_minimum_price_net, child.cost_item_price_net)
          ) {
            if (!child.cost_item_markup_percentage_adjustment) return acc + 0

            // Get only the adjustment portion, not the minimum portion
            const qm = n(child.cost_matrix_markup_net)
            const fromAdjustment = n(child.cost_item_markup_percentage_adjustment) * (qm - 1)
            return acc + fromAdjustment
          }

          return (
            acc + child[`${child.type === 'assembly' ? 'quote' : child.type}_price_net_adjustment`]
          )
        }, 0)
      },

      quote_price_net_base_adjustment(state, parent, children) {
        return n(state.quote_price_net_adjustment) / n(state.quantity_multiplier)
      },

      // /**
      //  * Adjustment from top quote level markup/margin setting.
      //  * ONLY finds for adjustment based on THIS objects quote_markup_percentage_adjustment.
      //  */
      // quote_price_net_from_minimums(state, parent, children) {
      //   // const targetMarkup = state.quote_price_net_base_undiscounted / state.quote_total_cost_net_base;
      //   // const pAdjustment = state.quote_markup_percentage_adjustment;
      //   // const originalMarkup = ((targetMarkup - 1) / (pAdjustment + 1)) + 1;
      //   // const originalPrice = state.quote_total_cost_net_base * originalMarkup;
      //   // return state.quote_price_net_undiscounted - originalPrice;
      //   return children.reduce((acc, child) => {
      //     if (child.type === 'cost_item'
      //       && child.cost_type_minimum_price_net >= 0.01
      //       && _.eq(child.cost_type_minimum_price_net, child.cost_item_price_net)) {
      //       if (!child.cost_item_markup_net_adjustment) return acc + 0;
      //
      //       // Get only the adjustment portion, not the minimum portion
      //       const qm = n(child.cost_matrix_markup_net);
      //       const fromAdjustment = (n(child.cost_item_markup_percentage_adjustment)
      //         * (qm - 1));
      //       return acc + fromAdjustment;
      //     }
      //
      //     return acc + (child[`${child.type === 'assembly' ? 'quote' : child.type}_price_net_base_adjustment`] * n(state.quote_qty_net_base));
      //   }, 0);
      // },
      /**
       * For full quantity only, no _base value
       */
      quote_actual_materials_cost_net(state, parent, children) {
        return children.reduce((acc, child) => {
          const type = child.type === 'assembly' ? 'quote' : child.type
          return (
            acc +
            (child[`${type}_actual_materials_cost_net`] === null
              ? n(child[`${type}_materials_cost_net`])
              : n(child[`${type}_actual_materials_cost_net`]))
          )
        }, 0)
      },
      quote_actual_labor_cost_net(state, parent, children) {
        return children.reduce((acc, child) => {
          const type = child.type === 'assembly' ? 'quote' : child.type
          return (
            acc +
            (child[`${type}_actual_labor_cost_net`] === null
              ? n(child[`${type}_labor_cost_net`])
              : n(child[`${type}_actual_labor_cost_net`]))
          )
        }, 0)
      },
      quote_actual_total_cost_net(state, parent, children) {
        return n(state.quote_actual_labor_cost_net) + n(state.quote_actual_materials_cost_net)
      },
      quote_completion_percentage(state) {
        return n(state.quote_sum_completed_items) / n(state.quote_total_cost_net) || 0
      },

      assembly_quote_id(state) {
        return state.quote_id || state.assembly_quote_id
      },
      quote_id(state) {
        return state.quote_id || state.assembly_quote_id
      },
      quote_qty_net(state) {
        const r = n(state.quote_qty_net_base) * n(state.quantity_multiplier)
        if (state.type === 'assembly' && !state.assembly_is_included) {
          return 0
        }
        return Math.ceil(
          state.assembly_minimum_qty_net ? Math.max(r, state.assembly_minimum_qty_net) : r
        )
      },
      assembly_is_using_minimum_qty(state) {
        return (
          state.assembly_minimum_qty_net &&
          state.assembly_minimum_qty_net >= state.quote_qty_net_base
        )
      },
      assembly_is_using_minimum_area(state) {
        return (
          state.assembly_minimum_area_net &&
          state.assembly_minimum_area_net >= state.quote_area_gross
        )
      },

      /*
      quote_minimum_cost_adjustment_net(state, parent, children) {
        return children.reduce((acc, child) => {
          const type = child.type === 'assembly' ? 'quote' : child.type;
          return acc +
            (_.isnan(child[`${type}_minimum_cost_adjustment_net`])
              ? 0
              : child[`${type}_minimum_cost_adjustment_net`]);
        }, 0);
      },
      quote_minimum_price_adjustment_net(state) {
        return children.reduce((acc, child) => {
          const type = child.type === 'assembly' ? 'quote' : child.type;
          return acc +
            (_.isnan(child[`${type}_minimum_cost_adjustment_net`])
              ? 0
              : child[`${type}_minimum_cost_adjustment_net`]);
        }, 0);
        if (_.eq(0, state.cost_type_minimum_price_net)) return 0;
        const adj = n(state.cost_type_minimum_price_net) - n(state.cost_item_price_net);
        return  adj < 0 ? 0 : adj;
      },
      */
      weight_unit_of_measure_id(state, parent, children) {
        const defaultMeasure = state.weight_unit_of_measure_id || 'lbs'

        if (!children && !children.length) return defaultMeasure

        const allMeasures = children.map((ch) => ch.weight_unit_of_measure_id)
        const cleaned = _.cleanArray(allMeasures)
        const list = _.uniq(cleaned)

        if (!list.length) return defaultMeasure

        return list[0] || defaultMeasure
      },

      weight_unit_of_measure_abbr(state) {
        return state.weight_unit_of_measure_id
      },

      quote_weight_net(state, parent, children) {
        let weight = 0
        let unit = state.weight_unit_of_measure_id

        children.forEach((child) => {
          const converted = _.convertMeasure(
            child.cost_item_weight_net || child.quote_weight_net || 0,
            child.weight_unit_of_measure_id || unit,
            unit
          )
          weight += converted || 0
        })

        return weight
      },

      quote_total_hours(state) {
        return n(state.quote_total_hours_base) * n(state.quantity_multiplier, 1)
      },
      quote_subtotal_net(state) {
        if (!state.parentRefId) {
          return _.toNum(state.quote_price_net_base_undiscounted, 2)
        }
        return state.quote_price_net_base_undiscounted
      },
      /**
       * Original markup BEFORE adjustment
       */
      quote_markup_net(state) {
        const targetMarkup =
          state.quote_price_net_base_undiscounted / state.quote_total_cost_net_base
        const pAdjustment = state.quote_markup_percentage_adjustment
        const markup = (targetMarkup - 1) / (pAdjustment + 1) + 1
        return markup
      },
      quote_price_net_undiscounted(state) {
        return n(state.quote_subtotal_net) * n(state.quantity_multiplier, 1)
      },
      quote_discount_percentage(state) {
        return _.divide(state.quote_discount_net_base, state.quote_price_net_base_undiscounted)
      },
      quote_discount_net(state) {
        // 2 decimal points only
        if (state.parentRefId) {
          const disc = n(state.quote_discount_net_base) * n(state.quantity_multiplier, 1)
          return disc
        }
        const disc = _.toNum(n(state.quote_discount_net_base) * n(state.quantity_multiplier, 1), 2)
        return disc
      },
      quote_price_net_base(state) {
        if (!state.parentRefId) {
          return _.toNum(state.quote_subtotal_net, 2) - _.toNum(state.quote_discount_net_base, 2)
        }

        return _.n(state.quote_subtotal_net) - _.n(state.quote_discount_net_base)
      },
      // After discount
      // and fixed price adjustment
      quote_price_net(state) {
        // 2 decimal point only
        if (!state.parentRefId) {
          return _.toNum(
            n(state.quote_subtotal_net) * n(state.quantity_multiplier) -
              n(state.quote_discount_net),
            2
          )
        }
        return (
          n(state.quote_subtotal_net) * n(state.quantity_multiplier) - n(state.quote_discount_net)
        )
      },
      quote_margin_net(state) {
        const price = state.quote_price_net < 0 ? -1 : 1
        return (
          price *
            ((_.toNum(state.quote_price_net) - _.toNum(state.quote_total_cost_net)) /
              _.toNum(state.quote_price_net)) || 0
        )
      },
      quote_profit_net(state) {
        return n(state.quote_price_net) - n(state.quote_total_cost_net)
      },

      oTaxSums(state, parent, children) {
        const childSums = children.flatMap((ch) => Object.values(ch.oTaxSums || {}))
        let regular = childSums.reduce((acc, sums) => {
          const alreadyFound = acc[sums.key] || {
            ...sums,
            cost: 0,
            profit: 0,
            sum: 0
          }

          return {
            ...acc,
            [sums.key]: {
              ...alreadyFound,
              cost: alreadyFound.cost + sums.cost,
              profit: alreadyFound.profit + sums.profit,
              sum: alreadyFound.sum + sums.sum
            }
          }
        }, {})

        // Tax adjustments for discounts need to be made ONLY on taxes
        // that apply to the price and profit, NOT on costs
        if (_.n(state.quote_discount_net) >= 0.01 && !state.parentRefId) {
          const mult = _.n(state.quote_discount_percentage) * -1
          const discountSet = Object.values(regular).reduce((acc, sums) => {
            const key = `${sums.key}-discount`

            if (sums.on !== 'all' && sums.on !== 'profit') {
              return {
                ...acc,
                [key]: {
                  ...sums,
                  key,
                  desc: 'This tax is is based on cost and so has no adjustment from discounts.',
                  name: `${sums.name} - discount adjustment`,
                  cost: 0,
                  profit: 0,
                  sum: 0,
                  discount: 1
                }
              }
            }

            const adj = mult * sums.sum
            return {
              ...acc,
              [key]: {
                ...sums,
                key,
                name: `${sums.name} - discount adjustment`,
                cost: 0,
                profit: adj,
                sum: adj,
                discount: 1
              }
            }
          }, {})

          regular = {
            ...regular,
            ...discountSet
          }
        }

        return regular
      },
      quote_cost_tax(state) {
        return Object.values(state.oTaxSums).reduce((acc, sum) => acc + sum.cost, 0)
      },

      quote_profit_tax(state) {
        return Object.values(state.oTaxSums).reduce((acc, sum) => acc + sum.profit, 0)
      },

      quote_discount_tax(state) {
        if (_.n(state.quote_discount_net) < 0.01) {
          return 0
        }

        return Object.values(state.oTaxSums)
          .filter((sums) => sums.discount)
          .reduce((acc, sum) => acc + sum.profit, 0)
      },

      quote_tax(state) {
        return state.quote_cost_tax + state.quote_profit_tax
      },

      quote_price_tax(state) {
        // 2 decimal point only
        return state.quote_tax
        // const t = _.toNum(n(state.tax_percentage) * _.toNum(n(state.quote_price_net), 2), 2);
        // return t < 0 ? 0 : t;
      },
      quote_price_gross(state) {
        // 2 decimal points only
        if (!state.parentRefId) {
          const p = _.toNum(n(state.quote_price_net) + n(state.quote_price_tax), 2)
          return p < 0 ? 0 : p
        }
        const p = n(state.quote_price_net) + n(state.quote_price_tax)
        return p < 0 ? 0 : p
      },
      asAssemblyPath(state, parent) {
        let path = parent.asAssemblyPath || []
        if (state && state.type === 'assembly' && state.parentRefId) {
          path = [...path, state.assembly_name]
        }
        return path
      },
      quote_show_itemized_prices(state, parent) {
        // if quote as root, default to true
        if (state.type === 'quote') {
          return state.quote_show_itemized_prices === null || state.quote_show_itemized_prices
            ? 1
            : 0
        }
        // If assembly, editing as root, default to true
        if (!Object.keys(parent).length) {
          return state.assembly_show_itemized_prices || state.assembly_show_itemized_prices === null
            ? 1
            : 0
        }

        // If assembly, editing inside quote or assembly, parent must be true to be true
        if (state.assembly_show_itemized_prices === null || state.assembly_show_itemized_prices) {
          return parent.quote_show_itemized_prices ? 1 : 0
        }

        // Otherwise false
        return 0
      },
      assembly_has_set_price() {
        return 0
      },

      /**
       * Based on options/upgrades inside and
       * for all the childrens utilized dimensions,
       * will tell us all the dimensions utilized by this
       * assembly.
       * @returns {string[]}
       */
      asDimensionsUsed(state, parent, children, possibleDimensions) {
        return getDimensionsUsed(state, parent, children, possibleDimensions)
      },

      /**
       * Get the dimensions that are required by the children of this quote or assembly.
       * This does NOT include the dimensions, that these dimensions require.
       * @param state
       * @param parent
       * @param children
       * @param possibleDimensions
       * @returns {*}
       */
      oChildRequiredDimensions(state, parent, children) {
        const required = {}

        const addAddonReq = (addon, refId) => {
          const areq = _.makeArray(addon.asDimensionsRequired) || []

          areq.forEach((abbr) => {
            if (!(abbr in required)) {
              required[abbr] = []
            }

            required[abbr].push({
              type: 'addon',
              objectType: addon.type,
              objectId: addon.id,
              name: addon.name || 'Upgrade/option',
              refId
            })
          })
        }

        // Get from addons
        ;(state.aoAddons || []).forEach((addon) => addAddonReq(addon, state.refId))

        // Get from children
        ;(children || []).forEach((child) => {
          // If it is assembly, pass along its oChildRequiredDimensions value
          if (child.type === 'assembly') {
            Object.keys(child.oChildRequiredDimensions || {})
              .filter((key) => key in child.oDimensions && child.oDimensions[key].inherit)
              .forEach((abbr) => {
                if (!(abbr in required)) {
                  required[abbr] = []
                }

                required[abbr] = [...required[abbr], ...child.oChildRequiredDimensions[abbr]]
              })
          } else {
            const linkedReqs = _.makeArray(child.asDimensionsLinked || [])
            const addonReqs =
              _.difference(_.makeArray(child.asDimensionsUsed) || [], linkedReqs) || []

            ;(child.aoAddons || []).forEach((addon) => addAddonReq(addon, child.refId))

            linkedReqs.forEach((abbr) => {
              if (!(abbr in required)) {
                required[abbr] = []
              }

              required[abbr].push({
                type: 'linked',
                objectType: 'cost_item',
                objectId: child.cost_type_id || null,
                name: child.cost_type_name,
                refId: child.refId
              })
            })
          }
        })

        return required
      },

      /**
       * Tells us which dimensions are required
       * to be set in THIS assembly.  If a dimension is
       * used but this assembly delegates that to a parent
       * assembly (linked), then it is not 'required' here.
       *
       * @param state
       * @returns {string[]}
       */
      asRequiredDimensions(state) {
        const dim = state.oDimensions
        const root = !state.parentRefId

        return _.uniq([
          // Get dimensions we KNOW that are required from
          // addons and children
          ..._.makeArray(state.asDimensionsUsed).filter(
            (key) => root || !(key in dim) || !dim[key].inherit
          )
          //
          // // Get dimensions that the user may have indicated
          // // that are required because they filled htem out
          // ...Object.keys(dim)
          //   .filter(key => dim[key].explicitlySet && dim[key].value && !dim[key].inherit),
        ])
      },

      /**
       * Counts the total number of dimensions
       * which MUST be set here to satisfy child and addon
       * requirements, but ALSO the dimeneions that are set
       * here specifically but are not known to be required.
       *
       * @param state
       * @returns {int}
       */
      assembly_count_own_dimensions(state) {
        return state.asRequiredDimensions.length
      },

      /**
       * Adjusts non-overridden addons when a price adjustment has been observed
       */
      aoAddons(state, parent, children, possibleDimensions, latestSet, changeSet) {
        const addons = _.imm(state.aoAddons)
        const updatedPrice = changeSet[state.refId] && changeSet[state.refId].quote_subtotal_net

        if (updatedPrice) {
          for (let index = 0; index < addons.length; index++) {
            const addon = { ...addons[index] }
            addon.targetPriceAdjustment =
              updatedPrice -
              (addon.targetPrice || updatedPrice) +
              (addon.targetPriceAdjustment || 0)
            addon.targetPrice = updatedPrice
            addons[index] = addon
          }
        }

        return addons
      }
    }
  },

  // Define fields dependant or calculted from other fields, calculated within the component scope
  //  Keep them in order of operations required for final calculation.
  // This only defines dependencies on the same hierarchical level, for parent fields
  //  dependent on child fields, see childDependencies
  getFieldDependencies() {
    const {
      // cost_item_discount_percentage, <= parent dependency
      // cost_item_discount_net_base,
      // cost_item_discount_net,
      // quote_area_gross, <= parent dependency
      // quantity_multiplier, <=  parent dependency
      quote_qty_net,
      quote_price_net_adjustment,
      quote_price_net_base_adjustment,
      quote_labor_cost_net_base,
      quote_labor_cost_net,
      quote_materials_cost_net_base,
      quote_materials_cost_net,
      quote_total_cost_net_base,
      quote_actual_total_cost_net,
      quote_total_cost_net,
      quote_markup_net,
      quote_qty_net_base,
      quote_area_gross,
      quote_is_using_minimum_qty,
      quote_is_using_minimum_area,
      // cost_item_price_net_adjustment,
      // quote_total_hours_net_base, <= child dependency
      quote_total_hours_base,
      quote_total_hours,
      // quote_discount_net_base,
      quote_discount_net,
      // quote_markup_percentage_adjustment,
      // quote_markup_net, <= child dependency
      // quote_discount_percentage, <= parent dependency
      quote_price_net_base_undiscounted,
      quote_price_net_undiscounted,
      quote_subtotal_net,
      quote_price_net_base,
      quote_price_net,
      quote_margin_net,
      quote_price_tax,
      quote_price_gross,
      assembly_price_adjustment_net_base,
      assembly_price_adjustment_net,
      quote_completion_percentage,
      quote_count_completed_items,
      oDimensions,
      quote_actual_labor_cost_net,
      quote_actual_materials_cost_net,
      quote_show_itemized_prices,
      quote_markup_percentage_adjustment,
      quote_tax,
      quote_profit_net,
      quote_discount_tax,
      assembly_has_set_price,
      item_count_upgrades,
      asRequiredDimensions,
      oChildRequiredDimensions,
      quote_weight_net,
      weight_unit_of_measure_abbr,
      quote_cost_tax,
      quote_profit_tax,
      quote_discount_percentage,
      oTaxSums,

      cost_item_regular_price_net_base,
      cost_item_regular_price_net,

      cost_matrix_labor_cost_net,
      cost_matrix_aggregate_cost_net,
      cost_matrix_rate_net,
      // cost_item_qty_net_base, <= parent dependency
      cost_item_qty_net,
      cost_item_labor_cost_net_base,
      cost_item_labor_cost_net,
      cost_item_materials_cost_net_base,
      cost_item_materials_cost_net,
      cost_item_total_hours_base,
      cost_item_total_hours,
      cost_item_total_cost_net_base,
      cost_item_total_cost_net,
      cost_item_price_net_base,
      cost_item_price_net,
      cost_item_markup_percentage_adjustment,
      cost_item_markup_net_adjustment,
      cost_item_markup_net_adjusted,
      cost_item_price_net_base_adjustment,
      quote_price_net_per_unit,
      quote_cost_net_per_unit
    } = this.getComputedDependants()

    return {
      item_is_upgraded: {
        item_count_upgrades
      },
      assembly_fixed_price_net_each: {
        assembly_price_adjustment_net_base
      },
      assembly_markup_percentage_adjustment: {
        quote_markup_percentage_adjustment
      },
      assembly_show_itemized_prices: {
        quote_show_itemized_prices
      },
      oDimensions: {
        oDimensions,
        oChildRequiredDimensions,
        asRequiredDimensions
      },
      asRequiredDimensions: {
        oDimensions
      },
      // Cost matrix
      quantity_multiplier: {
        quote_discount_net,
        quote_qty_net,
        quote_price_net,
        quote_total_cost_net,
        quote_labor_cost_net,
        quote_materials_cost_net
      },
      // Quote
      quote_minimum_qty_net: {
        quote_qty_net_base
      },
      quote_minimum_area_net: {
        quote_area_gross
      },
      assembly_is_included: {
        quote_qty_net_base,
        quote_qty_net
      },
      quote_qty_net_base: {
        quote_qty_net,
        quote_labor_cost_net_base,
        quote_materials_cost_net_base,
        quote_total_cost_net_base,
        // quote_discount_net_base,
        quote_price_net_base_undiscounted,
        quote_is_using_minimum_qty,
        quote_cost_net_per_unit
      },
      quote_discount_net_base: {
        quote_discount_net,
        quote_price_net_base,
        quote_discount_percentage,
        oTaxSums
      },
      quote_discount_net: {
        quote_discount_tax,
        quote_price_net_base,
        quote_price_net,
        quote_discount_percentage,
        oTaxSums
      },
      quote_discount_percentage: {
        oTaxSums
      },
      quote_area_gross: {
        quote_total_hours_base,
        quote_materials_cost_net_base,
        quote_labor_cost_net_base,
        quote_price_net_base_undiscounted,
        quote_price_net_base,
        quote_total_hours,
        quote_materials_cost_net,
        quote_labor_cost_net,
        quote_price_net_undiscounted,
        quote_price_net,
        quote_is_using_minimum_area
      },
      quote_qty_net: {
        quote_labor_cost_net,
        quote_materials_cost_net,
        quote_total_cost_net,
        quote_price_net_per_unit
      },
      quote_total_hours_base: {
        quote_total_hours
      },
      quote_labor_cost_net_base: {
        quote_labor_cost_net,
        quote_total_cost_net_base
      },
      quote_materials_cost_net_base: {
        quote_materials_cost_net,
        quote_total_cost_net_base
      },
      quote_total_cost_net_base: {
        quote_total_cost_net,
        quote_price_net_base_undiscounted,
        quote_price_net_adjustment,
        quote_markup_net,
        quote_cost_net_per_unit
      },
      quote_total_cost_net: {
        quote_price_net_undiscounted,
        quote_profit_net,
        quote_margin_net
      },

      // quote actual costs
      quote_actual_labor_cost_net: {
        quote_actual_total_cost_net
      },
      quote_actual_materials_cost_net: {
        quote_actual_total_cost_net
      },
      quote_price_net_per_unit: {
        quote_price_net_base_undiscounted
      },
      quote_price_net_base_undiscounted: {
        // quote_discount_net_base,
        // quote_discount_net,
        quote_price_net_base,
        quote_subtotal_net,
        quote_price_net_undiscounted,
        assembly_price_adjustment_net_base,
        quote_price_net_adjustment,
        quote_markup_net
      },
      quote_subtotal_net: {
        assembly_price_adjustment_net_base
      },
      quote_markup_percentage_adjustment: {
        quote_price_net_adjustment
      },
      quote_price_net_adjustment: {
        quote_price_net_base_adjustment
      },
      quote_price_net_undiscounted: {
        quote_price_net
      },
      quote_price_net_base: {
        quote_price_net,
        quote_price_tax
      },
      quote_price_net: {
        quote_margin_net,
        quote_price_tax,
        quote_price_gross,
        quote_profit_net
      },
      oTaxSums: {
        quote_cost_tax,
        quote_profit_tax,
        quote_tax,
        quote_price_tax,
        quote_discount_tax
      },
      tax_percentage: {
        quote_price_tax,
        quote_discount_tax
      },
      quote_cost_tax: {
        quote_tax
      },
      quote_profit_tax: {
        quote_tax
      },
      // quote_profit_net: {
      //   quote_discount_tax,
      // },
      quote_discount_tax: {
        quote_tax
      },
      quote_tax: {
        quote_price_tax
      },
      quote_price_tax: {
        quote_price_gross,
        assembly_has_set_price
      },
      quote_count_items: {
        quote_count_completed_items,
        quote_completion_percentage
      },
      quote_count_completed_items: {
        quote_completion_percentage
      },
      weight_unit_of_measure_id: {
        weight_unit_of_measure_abbr,
        quote_weight_net
      }
    }
  },

  getParentDependencies() {
    const {
      quote_area_gross,
      quantity_multiplier,
      // quote_discount_percentage,
      quote_markup_percentage_adjustment,
      quote_qty_net,
      asAssemblyPath,
      oDimensions,
      quote_show_itemized_prices,
      quote_tax,
      tax_id,
      tax_percentage,
      depth,
      oProjectTaxSettings
    } = this.getComputedDependants()

    return {
      // When the parent of this object sees as change in
      //  quote_discount_percentage, update this objects
      //  quote_discount_percentage as well, or cost_item_discount_percentage
      //  if this object is a cost item.
      oProjectTaxSettings: {
        oProjectTaxSettings
      },
      tax_id: {
        tax_id
      },
      depth: {
        depth
      },
      parentRefId: {
        depth
      },
      tax_percentage: {
        tax_percentage
      },
      quote_show_itemized_prices: {
        quote_show_itemized_prices
      },
      oDimensions: {
        oDimensions
      },
      quote_area_gross: {
        quote_area_gross
      },
      quote_qty_net: {
        quantity_multiplier,
        quote_qty_net
      },
      quote_markup_percentage_adjustment: {
        quote_markup_percentage_adjustment
      },
      assembly_name: {
        asAssemblyPath
      },
      quote_name: {
        asAssemblyPath
      },
      asAssemblyPath: {
        asAssemblyPath
      }
    }
  },

  getChildDependencies() {
    const {
      quote_total_hours_base,
      quote_actual_materials_cost_net,
      quote_actual_labor_cost_net,
      quote_labor_cost_net_base,
      quote_materials_cost_net_base,
      quote_total_cost_net_base,
      quote_price_net_base,
      quote_price_net,
      quote_markup_net,
      quote_discount_net_base,
      aoChildStageTasks,
      quote_count_items,
      quote_count_completed_items,
      assembly_markup_percentage_adjustment,
      asDimensionsUsed,
      oDimensions,
      asRequiredDimensions,
      item_count_upgrades,
      quote_count_addons_available,
      oChildRequiredDimensions,
      weight_unit_of_measure_id,
      weight_unit_of_measure_abbr,
      quote_weight_net,
      oTaxSums,
      quote_price_net_per_unit
    } = this.getComputedDependants()

    return {
      cost_item_total_hours_base: {
        quote_total_hours_base
      },
      quote_total_hours_base: {
        quote_total_hours_base
      },
      cost_item_labor_cost_net_base: {
        quote_labor_cost_net_base,
        quote_actual_labor_cost_net
      },
      quote_labor_cost_net_base: {
        quote_labor_cost_net_base,
        quote_actual_labor_cost_net
      },
      cost_item_materials_cost_net_base: {
        quote_materials_cost_net_base,
        quote_actual_materials_cost_net
      },
      quote_materials_cost_net_base: {
        quote_materials_cost_net_base,
        quote_actual_materials_cost_net
      },
      cost_item_actual_materials_cost_net: {
        quote_actual_materials_cost_net
      },
      quote_actual_materials_cost_net: {
        quote_actual_materials_cost_net
      },
      quote_actual_labor_cost_net: {
        quote_actual_labor_cost_net
      },
      cost_item_actual_labor_cost_net: {
        quote_actual_labor_cost_net
      },
      cost_item_total_cost_net_base: {
        quote_total_cost_net_base,
        assembly_markup_percentage_adjustment
      },
      quote_total_cost_net_base: {
        quote_total_cost_net_base
      },
      cost_item_price_net_base_undiscounted: {
        quote_price_net_per_unit
      },
      quote_price_net_base: {
        quote_price_net_base
      },
      cost_item_price_net_base: {
        quote_price_net_base
      },
      cost_item_price_net: {
        quote_price_net
      },
      quote_markup_net: {
        quote_markup_net
      },
      cost_item_markup_net: {
        quote_markup_net
      },
      quote_discount_net_base: {
        quote_discount_net_base
      },
      cost_item_discount_net_base: {
        quote_discount_net_base
      },
      aoChildStageTasks: {
        aoChildStageTasks
      },
      oDimensions: {
        oDimensions,
        asDimensionsUsed,
        asRequiredDimensions,
        oChildRequiredDimensions
      },
      asDimensionsUsed: {
        asDimensionsUsed,
        asRequiredDimensions,
        oDimensions,
        oChildRequiredDimensions
      },
      asRequiredDimensions: {
        asDimensionsUsed,
        asRequiredDimensions,
        oDimensions,
        oChildRequiredDimensions
      },
      item_count_upgrades: {
        item_count_upgrades
      },
      oChildRequiredDimensions: {
        oChildRequiredDimensions
      },
      aoAddons: {
        quote_count_addons_available,
        oChildRequiredDimensions
      },
      quote_count_addons_available: {
        quote_count_addons_available
      },
      weight_unit_of_measure_id: {
        weight_unit_of_measure_id,
        weight_unit_of_measure_abbr,
        quote_weight_net
      },
      quote_weight_net: {
        quote_weight_net
      },
      cost_item_weight_net: {
        quote_weight_net
      },
      oTaxSums: {
        oTaxSums
      }
    }
  }
}
