<template>
  <div class="flex flex-col">
    <div v-if="!loading" class="w-full max-w-xl">
      <div v-if="notSidebar">
        <div v-if="creatorId" class="text-lg mb-1 mt-4">Your rating</div>
        <div v-if="!creatorId" class="text-lg mb-1 mt-4">Company ratings</div>
        <p class="text-sm text-gray-400 mb-4">
          Rating and reviews are received from verified clients who have used these services
        </p>
      </div>
      <div class="flex justify-between items-stretch">
        <!-- Rating Summary -->
        <div
          class="flex flex-col items-center justify-center"
          :class="{
            'border-cool-gray-500 rounded-l-md border-l border-t border-b': notSidebar,
            'p-6 w-50': notSidebar,
            'pr-3': inSidebar
          }"
        >
          <div class="flex flex-col items-center">
            <div
              :class="{
                'text-4xl': inSidebar,
                'text-6xl': !inSidebar
              }"
            >
              {{ summary.averageRating }}
            </div>
            <div class="flex items-center my-2">
              <Rating v-model="value" readonly :cancel="false" :size="starSize" />
            </div>
            <p v-if="notSidebar" class="text-sm text-gray-400">
              {{ summary.totalRatings }} reviews
            </p>
            <p v-if="inSidebar" class="text-sm text-gray-400">{{ summary.totalRatings }}</p>
          </div>
        </div>

        <!-- Rating Chart -->
        <div
          class="bg-white flex-grow flex flex-col justify-center"
          :class="{
            'border-cool-gray-500 rounded-r-md border-r border-t border-b': notSidebar,
            'p-6': notSidebar
          }"
        >
          <div>
            <div class="flex items-center mb-2">
              <div class="w-4">5</div>
              <div class="w-full h-3 mx-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                <div
                  class="h-3 bg-blue-600 rounded-lg"
                  :style="{ width: `${summary.fiveStarPercent}%` }"
                ></div>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <div class="w-4">4</div>
              <div class="w-full h-3 mx-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                <div
                  class="h-3 bg-blue-600 rounded-lg"
                  :style="{ width: `${summary.fourStarPercent}%` }"
                ></div>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <div class="w-4">3</div>
              <div class="w-full h-3 mx-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                <div
                  class="h-3 bg-blue-600 rounded-lg"
                  :style="{ width: `${summary.threeStarPercent}%` }"
                ></div>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <div class="w-4">2</div>
              <div class="w-full h-3 mx-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                <div
                  class="h-3 bg-blue-600 rounded-lg"
                  :style="{ width: `${summary.twoStarPercent}%` }"
                ></div>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <div class="w-4">1</div>
              <div class="w-full h-3 mx-2 bg-gray-200 rounded-lg dark:bg-gray-700">
                <div
                  class="h-3 bg-blue-600 rounded-lg"
                  :style="{ width: `${summary.oneStarPercent}%` }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="notSidebar" class="my-4">
        <div v-if="creatorId" class="text-md">Reviews about me ({{ summary.totalRatings }})</div>
        <div v-if="!creatorId" class="text-md">Based on ({{ summary.totalRatings }}) reviews</div>
        <p class="text-sm text-gray-400">Reviews received from verified users on the Bolster app</p>
      </div>
    </div>
    <div v-else>
      <Spinner :loading="loading" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onBeforeMount, watch } from 'vue'
import { ratingSummary } from '../../../appsync/src/rating/schemas/util'

const props = defineProps({
  ratingData: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: 'clientId'
  },
  creatorId: {
    type: String,
    default: null,
    required: true
  },
  inSidebar: {
    type: Boolean,
    default: false
  },
  starSize: {
    type: String,
    default: 'md'
  }
})

const loading = ref(false)
const notSidebar = ref(!props.inSidebar)

const value = ref(0)
const summary = ref({
  totalRatings: 0,
  averageRating: 0,
  fiveStarPercent: 0,
  fourStarPercent: 0,
  threeStarPercent: 0,
  twoStarPercent: 0,
  oneStarPercent: 0
})

const fetchRatingSummary = async () => {
  loading.value = true

  const { totalRatings, averageRating, percentages } = ratingSummary({
    ratings: props.ratingData.byOthers || []
  })

  value.value = Math.ceil(averageRating)
  summary.value = {
    totalRatings,
    averageRating,
    fiveStarPercent: percentages.five,
    fourStarPercent: percentages.four,
    threeStarPercent: percentages.three,
    twoStarPercent: percentages.two,
    oneStarPercent: percentages.one
  }

  loading.value = false
}

onBeforeMount(() => {
  if (props.ratingData !== null && props.ratingData !== undefined) {
    fetchRatingSummary()
  }
})

watch(
  () => props.ratingData,
  () => {
    fetchRatingSummary()
  },
  { deep: true }
)
</script>
