import _ from '../../../imports/api/Helpers'
import ExternalChanges from './ExternalChangesMixin'
import eventBus from '../../eventBus'
import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js'

/**
 * Emits:
 *  -$root.isDirty
 *  -isDirty
 *  -change
 *
 * Default settings saving:
 *  - this must be mixed into an object manipulator,
 *  of a type that has fields that will do autoPartialSave: true or
 *  defaultSetting: true/.
 *
 *  For default setting, any changes in that field will be saved as a 'default'
 *    for that object and that field
 *
 *  For autoPartialSave, that field will save immediately after it is changed,
 *    rather than pooling with all the other changes.
 *  So beware, that it will save automatically.
 *
 *  Defaults and auto partial saves will only occur on fields based on the ROOT
 *  OBJECT schema, so for example, if it is a quote object, that has a child of
 *  type cost_item, and cost_item schema says that 'labor_type_id' is a default
 *  setting, that will NOT be set as default when changed in the context of an
 *  object manipulator object of type quote.  If the cost_item is
 *  opened up in an object manipulator component of type 'cost_item' with
 *  CHANGE TRACKING ENABLED! then the auto partial saves and defaults
 *  saving will work when values are changed on that item.
 *
 *  To suppress changes or calls to auto saving or defaults saving call:
 *  disableChangeSaving();
 *  and then
 *  enableChangeSaving();
 */
export default {
  mixins: [ExternalChanges],

  props: {
    /**
     * Autosave for default and autosave values inside of children items
     */
    childDefaultsAutoSave: {
      default: false
    },

    /**
     * When off, this will disable change tracking.
     */
    trackChanges: {
      required: false,
      default: true
    },

    autoSaveEnabled: {
      type: Boolean,
      default: false
    },

    dirtyCheckDelay: {
      type: Number,
      default: 1000
    }
  },

  emits: ['isDirty', 'dirty', 'clean', 'changes', 'change'],

  created() {
    eventBus.$once(`${this.uid}-selected`, () => {
      const changeHandler = (changePayload) => {
        const { isDirty: is, changeManager, changes } = changePayload

        this.isDirty = is
        this.fieldsChanged = [...this.fieldsChanged, ...Object.keys(changes[this.refId] ?? {})]
        this.auditedChanges = changeManager.getChangeLogs()
      }

      const resetHandler = (changePayload) => {
        const { changeManager } = changePayload

        this.isDirty = false
        this.fieldsChanged = []
        this.auditedChanges = changeManager.getChangeLogs()
      }

      const { initiate, changeManager } = ChangeWatcher.useChangeWatcher({
        store: this.storeName,
        refId: this.refId,
        changeHandler,
        resetHandler,
        $store: this.$store
      })
      this.changeManager = changeManager
      initiate()
      this.enableChangeSaving()
    })
  },

  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)

    this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
      refId: this.rootRefId,
      callback: this.throttledChangeChecker
    })
  },

  data() {
    return {
      isDirty: false,
      enableChangeWatching: this.trackChanges,

      hasChangeTrackingMixin: true,
      watchingChanges: false,
      suppressChangeSaving: true,
      auditedChanges: {},
      seeAllChanges: 0,

      fieldsChanged: [],
      oldchangemgr: null,

      uid: _.uniqueId(),

      autoSaveEnabledLocal: this.autoSaveEnabled,
      buildChangeWatcher: () => {},
      changeManager: null
    }
  },

  watch: {
    isDirty(dirty) {
      this.$emit('isDirty', !!dirty)
      this.$emit(dirty ? 'dirty' : 'clean')
      eventBus.$emit('isDirty', {
        type: this.type,
        id: `${this.getId()}`,
        store: this.storeName,
        refId: this.refId,
        isDirty: dirty
        // changes: this.getDenormalizedChanges()
      })
    },

    auditedChanges(ch) {
      this.$emit('changes', ch)
      this.$emit('change', ch)
      eventBus.$emit('change', {
        id: `${this.getId()}`,
        // changes: this.getDenormalizedChanges(),
        ...ch
      })
    }
  },

  computed: {
    /**
     * Denormalized version of change set
     * @returns {{object}}
     */
    denormalizedChanges() {
      return this.getDenormalizedChanges()
    }
  },

  methods: {
    getId() {
      return this.$store.state[this.storeName]?.normalized[this.refId]?.[`${this.type}_id`]
    },
    /**
     * Get denormalized changes from auditedChanges
     * @returns {{object}}
     */
    getDenormalizedChanges() {
      return c.denormalizeDiffSet(this.auditedChanges)
    },

    /**
     * Build change watchers & initialize change watching
     *
     * @param bool startFromScratch           whether to start watching fro mthe current state
     *                                        of the normalized set (state.normalized[refId]),
     *                                        OR otherweise start from the original ie:
     *                                        state.all[id].  If set to false you could begin
     *                                        with being marked changed/dirty
     * @returns {self}
     */
    // buildChangeWatcher(startFromScratch = true) {
    //   if (!this.enableChangeWatching) {
    //     return this
    //   }
    //
    //   this.$store.commit({
    //     type: `${this.storeName}/ENSURE_CHANGE_MANAGER`,
    //     refId: this.rootRefId
    //   })
    //
    //   this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
    //     refId: this.rootRefId,
    //     callback: this.throttledChangeChecker
    //   })
    //
    //   // only reset if this refId === rootRefId
    //   this.resetChanges(startFromScratch)
    //
    //   this.watchingChanges = true
    //   this.$store.dispatch(`${this.storeName}/setWatchers`, {
    //     refId: this.rootRefId,
    //     store: this.throttledChangeChecker
    //   })
    //
    //   // Call it for the first time
    //   this.throttledChangeChecker()
    //
    //   return this
    // },

    /**
     * Throttled checker of dirty
     *
     * @returns {self}
     */
    throttledChangeChecker() {
      // c.throttle(() => this.checkIfDirty(), {
      //   delay: this.dirtyCheckDelay,
      //   key: this.uid
      // })

      return this
    },

    /**
     * Destroy change watchers and stop watching
     * @returns {self}
     */
    destroyWatchers() {
      if (!this.changeManager) {
        return this
      }

      this.changeManager.unwatch(this.throttledChangeChecker)

      return this
    },

    /**
     * Reset change watcher, will set isDirty to false
     *
     * @param bool startFromScratch           whether to start watching fro mthe current
     *                                        state of the normalized set
     *                                        (state.normalized[refId]), OR otherweise
     *                                        start from the original ie: state.all[id].
     *                                        If set to false you could begin with being
     *                                        marked changed/dirty
     * @returns {self}
     */
    resetChanges(startFromScratch = true) {
      // only reset if this is THE root object.
      // Otherwise, this is a suborniate object manipulator that
      // is just using a root's change watcher
      if (this.rootRefId !== this.refId) {
        return this
      }

      const reset =
        startFromScratch || !(this[`${this.type}_id`] in this.$store.state[this.storeName].all)

      const startingPoint = reset
        ? this.$store.state[this.storeName].normalized
        : this.$store.state[this.storeName].all[this[`${this.type}_id`]]

      this.$store.dispatch(`${this.storeName}/setOriginal`, {
        refId: this.rootRefId,
        original: startingPoint
      })

      if (!reset) {
        // this.changeManager.reset();
        this.$store.commit({
          type: `${this.storeName}/RESET_CHANGES`,
          refId: this.rootRefId
        })
      }

      return this
    },

    /**
     * Turns on change tracking, if it was stopped or never started
     * @param startFromScratch
     * @returns {self}
     */
    startChangeTracking(startFromScratch = false) {
      this.enableChangeWatching = true
      this.buildChangeWatcher(startFromScratch)

      return this
    },

    /**
     * Stops change tracking if it has alreday started
     * @param startFromScratch
     * @returns {self}
     */
    stopChangeTracking(startFromScratch = true) {
      this.enableChangeWatching = false
      this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
        refId: this.rootRefId,
        callback: this.throttledChangeChecker
      })

      if (startFromScratch) this.isDirty = false

      return this
    },

    /**
     * Get original object state (normalized)
     * @returns {object} normalized set
     */
    getOriginalNormalized() {
      if (this.original) {
        return this.original
      }

      const root = this.norm[this.rootRefId]
      const id = root[`${root.type}_id`]
      return _.imm(this.$store.state[this.storeName].all[id])
    },

    /**
     * Get the current object state.
     * It gets rootChanges which is a selected set where only changed fields and objects
     * are added to it. They will stay there even if they are changed back and original,
     * but it will limit the iterations required
     * @param bool abbreviated
     * @returns {object} normalized set
     */
    getCurrentNormalized(abbreviated = true) {
      return (
        (abbreviated &&
          this.$store.state[this.storeName].rootChanges &&
          this.$store.state[this.storeName].rootChanges[this.refId]) ||
        this.$store.state[this.storeName].normalized
      )
    },

    /**
     * Get a full change set of changes, from actual normalized and
     * @returns {Object}
     */
    getFullChangeAudit() {
      this.getChanges(true)

      return this.auditedChanges
    },

    /**
     * Get change list
     * @param full
     * @returns {Object|*}
     */
    async getChanges(full = false) {
      this.seeAllChanges = full

      if (full) {
        this.auditedChanges = await this.$store.dispatch(`${this.storeName}/getChanges`, {
          refId: this.refId,
          normalized: true
        })
      } else {
        this.auditedChanges = await this.$store.dispatch(`${this.storeName}/getExplicitChanges`, {
          refId: this.refId,
          normalized: true
        })
      }

      return this.auditedChanges
    },

    /**
     * REturns an array of fields that have changed, ONLY for this object and not
     * for any children. If children have changed, this could still be dirty (isDirty = true)
     * if there are NO fields that have changed.
     *
     * @returns {[]}
     */
    getChangedFields() {
      return this.fieldsChanged
    },

    /**
     *
     * @returns {*|*[]}
     */
    getExplicitlyChangedFields() {
      const explicit = this.getExplicitChanges()
      return Object.keys(explicit.fieldChanges || {})
    },

    /**
     * Returns object full of changes that were marked as explicit.
     * @returns object
     */
    getExplicitChanges() {
      return this.changeManager.getExplicitChanges(false, false)
    },

    /**
     * This is the primary function to see if the model is dirty (changed)
     * @returns {boolean|*}
     */
    async checkIfDirty(full = false) {
      await this.getChanges(full)

      const changedRefs = Object.keys(this.auditedChanges)
      const isRoot = this.rootRefId === this.refId
      const anyHasChanged = changedRefs.length
      const thisHasChanged = anyHasChanged && changedRefs.includes(this.refId)
      const isNew = !this.getField(`${this.type}_id`)

      if (changedRefs.length) {
        const isChanged = this.refId in this.auditedChanges
        const fieldKeys = isChanged ? Object.keys(this.auditedChanges[this.refId].fieldChanges) : []
        this.fieldsChanged = fieldKeys

        // Check if there are 'child' changes
        if (changedRefs.length > 1 || (anyHasChanged && !thisHasChanged && isRoot)) {
          // Check to see if there are 'child' changes
          this.fieldsChanged = _.uniq([...this.fieldsChanged, 'aoChildren'])
        }
      }

      // if this is the root object, then any sub-objects
      // can be dirty and then this will be called dirty.
      // If however this is NOT the root object, this item
      // will only be called dirty if this particular item is dirty
      this.isDirty = (isRoot && (anyHasChanged || isNew)) || thisHasChanged

      return this.isDirty
    },

    enableChangeSaving() {
      this.suppressChangeSaving = false
      return this
    },

    disableChangeSaving() {
      this.suppressChangeSaving = true
      return this
    }
  }
}
