<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="md"
    class="modal-mini"
    :isDirty="bodyIsDirty"
    :full="false"
    :expandable="false"
    :clickAway="false"
  >
    <template #header>
      <font-awesome-icon icon="user-helmet-safety" />
      Labor type
    </template>
    <template #body>
      <labor-type-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        v-if="!isAutoCost"
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import LaborTypeBody from '@/components/bodies/LaborType.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import AutoCost from '../../../imports/api/AutoCost.js'

export default {
  name: 'LaborType',
  mixins: [ObjectModalMixin],
  components: { LaborTypeBody },
  props: {
    type: {
      default: 'labor_type'
    }
  },
  computed: {
    isAutoCost() {
      return this.id && AutoCost.isAutoCostLaborTypeId(this.id)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
