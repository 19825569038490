import { loadStripe } from '@stripe/stripe-js'
import declineLookup from './StripeDeclineCodes'
import paymentRequestsMixin from '../PaymentRequestsMixin'

export default {
  data() {
    return {
      stripe: null,
      elements: null,
      paymentElement: null,
      clientSecret: null,
      billingDetails: null,
      currency: 'usd',
      declineLookup,
      // styling for stripe payment elements
      appearance: {
        theme: 'flat',
        variables: {
          colorPrimary: '',
          colorBackground: '#ffffff',
          colorText: '',
          colorDanger: '',
          fontFamily:
            '"Product Sans", "Droid Sans Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          spacingUnit: '',
          borderRadius: '0.5em'
        }
      },
      // styling for card element
      style: {
        base: {
          iconColor: '#495057',
          color: '#495057',
          fontWeight: 500,
          fontFamily:
            '"Product Sans", "Droid Sans Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          fontSize: '1.3rem',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#495057'
          },
          '::placeholder': {
            color: '#868E98'
          }
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE'
        }
      }
    }
  },
  emits: ['paymentMethodAdded', 'selectMethod', 'paid'],
  mixins: [paymentRequestsMixin],
  methods: {
    /**
     * Get payers payment methods
     */
    async getStripePaymentMethods() {
      const payload = await this.fetchPaymentMethods()
      if (payload.storedPaymentMethods) {
        this.storedPaymentMethods = this.formatPaymentMethods(payload.storedPaymentMethods)
      }
      if (payload.paymentMethods) {
        this.allowedPaymentMethods = payload.paymentMethods
      }
    },

    /**
     * Get payment details for the invoice
     */
    async getStripePaymentDetails() {
      return this.fetchPaymentDetails()
    },

    async stripeInitialize() {
      await this.getStripePaymentDetails()
      await this.getStripePaymentMethods()
      await this.setupStripePaymentElements()
      await this.mapBillingDetails()
    },

    /**
     * Init the setup of payment elements
     */
    async setupStripePaymentElements() {
      await this.createElements(false, true)
      // use payment element so we can offer other methods in the future
      // await this.createPaymentElement();
      await this.createCardElement()
    },

    /**
     * Format the payment methods so they include the reference object
     * @param {object} payload
     * @returns {array}
     */
    formatPaymentMethods(payload) {
      if (!payload || !payload.length) return []
      return payload.map((m) => ({
        ...m,
        ...JSON.parse(m.oReference)
      }))
    },

    /**
     * Create the payment element
     */
    createPaymentElement() {
      this.paymentElement = this.elements.create('payment', {
        business: {
          name: ''
        },
        paymentMethodOrder: [],
        // map session data so stripe knows what fields we need in the element
        fields: {
          billingDetails: {
            name: this.billingDetails.name ? 'never' : 'auto',
            email: this.billingDetails.email ? 'never' : 'auto',
            phone: this.billingDetails.phone ? 'never' : 'auto',
            address: {
              country: this.billingDetails.address.country ? 'never' : 'auto',
              city: this.billingDetails.address.city ? 'never' : 'auto',
              postalCode: this.billingDetails.address.postal_code ? 'never' : 'auto',
              state: this.billingDetails.address.state ? 'never' : 'auto',
              line1: this.billingDetails.address.line1 ? 'never' : 'auto'
            }
          }
        }
        // don't show stripes terms shown under element
        // terms: {
        //   card: 'never',
        //   usBankAccount: 'never',
        //   sofort: 'never',
        //   sepaDebit: 'never',
        //   ideal: 'never',
        //   bancontact: 'never',
        //   auBecsDebit: 'never',
        // },
        // currently don't want wallet capabilities
        // wallets: {
        //   applePay: 'never',
        //   googlePay: 'never',
        // },
      })

      const el = document.getElementById('credit-card-payment-element')
      this.paymentElement.mount(el)
      // add all the listeners
      this.addListeners(this.paymentElement)
    },
    /**
     * Auto setup the event listeners
     * @param {object} el
     */
    addListeners(el) {
      const listeners = ['change', 'focus', 'blur', 'escape', 'ready', 'click']
      listeners.forEach((event) => {
        el.on(event, (e) => {
          this.$emit(event)
          if (this[`on${c.capitalize(event)}`]) this[`on${c.capitalize(event)}`](e)
        })
      })
    },
    /**
     * When stripe element fields are changed
     * @param {object} event
     */
    onChange(event) {
      if (!event.complete) {
        if (this.paymentData) this.paymentData = null
        if (this.isPaymentDataValid) this.isPaymentDataValid = false
        return
      }
      this.isPaymentDataValid = event.complete
      if (this.onElementsCompleted) this.onElementsCompleted()
    },
    /**
     * When the form element has been confirmed as valid
     * @returns {Promise<object>}
     */
    onComplete() {
      return this.confirmCardElement()
    },
    confirmCardElement() {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.paymentElement,
            billing_details: {
              name: this.billingDetails.name
            }
          })

          if (res.error) {
            const { error } = res
            const code = error.code || error.decline_code
            const defaultLookup = this.declineLookup.default_code
            const codeLookup = this.declineLookup[code] || defaultLookup
            const formattedCode = code.replaceAll('_', ' ')
            const message = `${formattedCode}: ${codeLookup.description}`

            throw new Error(message)
          }

          const { paymentMethod } = res
          this.paymentData = {
            source_type: 'card',
            pay_invoice: true,
            invoice_id: this.invoiceId,
            payment_method_id: paymentMethod.id
          }

          resolve(res)
        } catch (e) {
          this.$store.dispatch('alert', {
            message: e || 'Could not process payment method.',
            error: true
          })
          reject(e)
        }
      })
    },
    confirmPaymentElements(additionalDetails = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.stripe.confirmPayment({
            elements: this.elements,
            redirect: 'if_required',
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  ...this.billingDetails,
                  ...(additionalDetails || {})
                }
              }
            }
          })

          if (res.error) {
            const { error } = res
            const code = error.code || error.decline_code
            const defaultLookup = this.declineLookup.default_code
            const codeLookup = this.declineLookup[code] || defaultLookup
            const formattedCode = code.replaceAll('_', ' ')
            const message = `${formattedCode}: ${codeLookup.description}`

            throw new Error(message)
          }
          const { paymentIntent } = res

          if (this.paymentIntentCreated) this.paymentIntentCreated(paymentIntent)

          resolve(paymentIntent)
        } catch (e) {
          this.$store.dispatch('alert', {
            message: e || 'Could not process payment method.',
            error: true
          })
          reject(e)
        }
      })
    },
    /**
     * Create a payment request button
     */
    async createPaymentRequestElement(payload) {
      const { amount = 0, label = 'Bolster', currency = 'usd', country = 'US' } = payload

      const paymentRequest = this.stripe.paymentRequest({
        country: country.toUpperCase(),
        currency: currency.toLowerCase(),
        total: {
          label,
          amount: Math.round(amount * 100)
        }
      })

      const prButton = this.elements.create('paymentRequestButton', {
        paymentRequest,
        style: {
          paymentRequestButton: {
            theme: 'dark',
            height: '40px',
            borderRadius: '35px'
          }
        }
      })

      // Check the availability of the Payment Request API first.
      const check = await paymentRequest.canMakePayment()

      if (check) prButton.mount('#payment-request-button')

      this.paymentData = null
      paymentRequest.on('paymentmethod', (e) => {
        const paymentMethod = e.paymentMethod
        this.$emit('paymentMethodAdded', paymentMethod)
        if (this.onPaymentMethodAdded) this.onPaymentMethodAdded(paymentMethod)
        e.complete('success')
      })

      paymentRequest.on('cancel', () => {
        this.$emit('selectMethod', null)
      })
    },
    /**
     * Create just the card element
     */
    createCardElement() {
      this.paymentElement = this.elements.create('card', {
        style: this.style
      })

      const el = document.getElementById('stripe-credit-card-payment-element')
      this.paymentElement.mount(el)
      this.addListeners(this.paymentElement)
    },
    /**
     * Load stripe js
     * @param boolean force - force use stripe CA
     */
    async loadStripe(force = false) {
      if (this.stripe) return this.stripe
      let key = import.meta.env.VITE_STRIPE_KEY
      if (
        this.$store.state.session.company &&
        this.$store.state.session.company.company_subscription_account &&
        !force
      ) {
        key =
          this.$store.state.session.company.company_subscription_account === 'stripe_delaware'
            ? process.env.VITE_STRIPE_KEY_DELAWARE
            : import.meta.env.VITE_STRIPE_KEY
      } else if (this.currency === 'usd' && !force) {
        key = import.meta.env.VITE_STRIPE_KEY_DELAWARE
      }
      return loadStripe(key)
    },
    /**
     * Load stripe and create elements with config
     * @param boolean force - force use stripe CA
     */
    async createElements(loadSecret = false, force = false) {
      try {
        this.stripe = await this.loadStripe(force)
        this.elements = this.stripe.elements({
          ...(loadSecret ? { clientSecret: this.clientSecret } : {}),
          appearance: this.appearance
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'Could not load stripe elements.',
          error: true
        })
      }
    },
    /**
     * Utility method to map necessary billing details
     */
    mapBillingDetails() {
      this.billingDetails = {
        name:
          this.$store.state.session.user.user_fname && this.$store.state.session.user.user_lname
            ? `${this.$store.state.session.user.user_fname} ${this.$store.state.session.user.user_lname}`
            : null,
        email: this.$store.state.session.user.user_email,
        phone: this.$store.state.session.user.user_phone,
        address: {
          country: this.$store.state.session.user.country_abbr,
          line1: this.$store.state.session.user.user_address,
          city: this.$store.state.session.user.user_city,
          state: this.$store.state.session.user.province_name,
          postal_code: this.$store.state.session.user.user_postal
        }
      }
    },

    /**
     * Pay an invoice for a Stripe counterparty
     * - Called in the PaymentsMixin
     */
    async stripePayInvoice() {
      this.loading = 1
      const invoiceId = this.invoice.invoice_id
      let payment

      if (this.storedPaymentMethodValid) {
        payment = {
          type:
            this.selectedStoredPaymentMethod.type ||
            this.selectedStoredPaymentMethod.virtual_account_type,
          fund_source_id: this.selectedStoredPaymentMethod.virtual_account_id
        }
      } else {
        if (this.selectedPaymentType === 'card') await this.onComplete()
        payment = this.paymentData
      }
      const { payload } = await this.makePaymentRequest({
        invoice_id: invoiceId,
        checkout: payment
      })
      if (payload.error && payload.details) {
        this.$refs['3d-verification-modal'].open()
        const iframe = document.createElement('iframe')
        iframe.src = payload.details.url
        setTimeout(() => {
          const ctn = document.getElementById('3d-verification')
          ctn.appendChild(iframe)
        }, 600)
        return
      }
      if (payload.error) throw payload.message
      this.$emit('paid')

      if (payload.error) throw payload.message
      this.$emit('paid')
      this.loading = 0
    }
  }
}
