<script>
import Sheets from '@/components/Sheets/Sheets.vue'
import EntitySheet from '@/components/Sheets/quote/EntitySheet.js'
import { computed, getCurrentInstance, nextTick, ref, watch, onBeforeMount, onMounted } from 'vue'
import FieldSetters from '@/components/composables/EntityFields/FieldSetters.js'
import EquationSetters from '@/components/Sheets/EquationSetters.js'
import { useStore } from 'vuex'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import EmptyQuote from '@/components/pages/Quote/EmptyQuote.vue'
import _ from '../../../../../imports/api/Helpers.js'
import Loader from '@/components/ui/Loader.vue' /**/
import Guide from '@/components/Sheets/Guide.js'
import CostTypeSuggestor from '@/SuggestionEngine/CostType/index.js'
import IconText from '@/components/ui/IconText.vue'
import CostItemNameDropdown from '../../CostItemNameDropdown.js'
import Hotkey from '@/components/ui/Hotkey.vue'
import Tag from 'primevue/tag'
import CostItem from '../../../../../imports/api/schemas/CostItem.js'
import DimensionSelector from '@/components/quote/item/DimensionSelector.vue'
import Dimensions from '@/components/composables/Dimensions.js'
import eventBus from '@/eventBus.js'
import OnboardingMilestones from '@/components/composables/OnboardingMilestones.js'
import DrawUtilities from '@/components/Sheets/DrawUtilities.js'
import useAssignees from '@/components/composables/Assignees.js'
import CostItemHeader from '@/components/bodies/CostItem/Header.vue'
import CostItemEquationVariables from '@/components/composables/CostItemEquationVariables.js'
import NormalizeUtilities from '../../../../../imports/api/NormalizeUtilities.js'
import AssemblyAdded from '@/components/bodies/AssemblyAdded.vue'
import { statuses } from '@/../imports/api/Statuses'
import useApproval from '@/components/composables/Approval'
import useApprovals from '@/components/composables/Approvals'
import useTask from '@/components/schedule/Task'
import Approval from '@/../imports/api/Approval'
import AutoCost from '../../../../../imports/api/AutoCost.js'

export default {
  name: 'Estimating',

  mixins: [CurrencyFilter],

  emits: ['updateAutocost', 'fixMissingDimensions', 'resetCheckout'],

  props: {
    title: {
      default: 'Estimate'
    },
    icon: {
      default: 'Table'
    },
    store: {
      default: 'Quote'
    },
    refId: {
      required: true
    },
    alternateChangeOrderId: {
      default: false
    },
    autoCostDisabledWarning: {
      default: false
    },
    showLivePriceWarning: {
      default: false
    },
    hasLivePricingEnabled: {
      default: false
    }
  },

  components: {
    DimensionSelector,
    Loader,
    Sheets,
    EmptyQuote,
    IconText,
    Hotkey,
    Tag,
    CostItemHeader,
    AssemblyAdded
  },

  setup(props) {
    const $this = getCurrentInstance().proxy
    const editingReference = ''
    const refSheet = ref({})
    const refAssemblyAdded = ref(null)
    const { processAction, isApprovedByApprover, session } = useApproval()
    const { fetchApprovalByApprover, upsertApproval, findApprovalByItemId } = useApprovals()
    const { saveItemField } = useTask()

    onMounted(async () => {
      // approver session data
      const quoteId = $store.state[props.store].normalized[props.refId].quote_id
      const companyId = $store.state.session.company.company_id
      session.value.userId = companyId
      session.value.role = 'company'
      // fetch all approvals for quote
      if (quoteId) {
        await fetchApprovalByApprover(quoteId)
      }
      if (!inCompanyScope.value && !publicCategories.value.length) await getPublicCategories()
    })

    const costItemDefaults = computed(
      () => $store.state.session.user?.aoObjectDefaults?.cost_item ?? {}
    )

    onBeforeMount(async () => {
      // For efficiency, deselect all queots that are not THIS quote
      const selectedIds = Object.keys($store.state[props.store].all)

      if (selectedIds.length <= 1) return

      const thisId = $store.state[props.store].normalized[props.refId].quote_id

      for (let id of selectedIds) {
        if (id !== thisId) {
          const refId = NormalizeUtilities.getNormalizedRootRefId(
            $store.state[props.store].normalized,
            Object.keys($store.state[props.store].all[id])[0]
          )
          await $store.dispatch(`${props.store}/deselect`, { refId })
        }
      }
    })

    let publicCategories = ref([])

    const fieldMapping = {
      cost_item: {
        cost_type_name: 'name',
        cost_item_total_cost_net: 'cost',
        cost_item_price_net: 'price',
        cost_item_markup_net_adjusted: 'markup',
        cost_item_profit_percentage: ['margin', 'marginu'],
        cost_matrix_aggregate_cost_net: 'ucost',
        cost_matrix_rate_net: 'uprice',
        cost_item_qty_net_base: 'qty',
        cost_item_qty_net: 'total_qty',
        quantity_multiplier: 'quantity_multiplier',
        unit_of_measure_id: 'units',
        asDimensionsLinked: 'dimLinks',
        type: 'type',
        stage_id: 'stage',
        cost_item_markup_percentage_adjustment: 'parent_adj',
        cost_item_markup_net_adjustment: 'net_adj',
        cost_matrix_markup_net: 'matrix_markup',
        oMeta: 'metaType',
        cost_type_qty_equation: 'eq',
        cost_type_has_labor: 'hasLabor',
        cost_type_has_materials: 'hasMaterials',
        cost_type_hours_per_unit: 'hoursPerUnit',
        cost_type_hours_per_unit_equation: 'hoursEq',
        labor_type_id: 'laborRate',
        cost_matrix_labor_cost_net: 'laborCost',
        cost_matrix_materials_cost_net: ['materialsCost', 'subCost'],
        cost_type_is_subcontracted: 'isSubcontracted',
        item_client_has_approved: 'clientHasApproved',
        change_order_status: 'changeOrderStatus',
        item_status: 'taskStatus',
        invoice_id: 'invoiceId',
        invoice_status: 'invoiceStatus',
        item_company_has_approved: 'companyHasApproved',
        item_company_status: 'companyHasApprovedItem',
        cost_type_desc: 'desc',
        cost_type_production_notes: 'prodNotes',
        file_ids: 'files',
        assignee_ids: 'assignee',
        parent_cost_type_id: 'category',
        trade_type_id: 'crewType',
        cost_type_budget_code: 'budgetCode',
        cost_type_current_hash: 'currentHash',
        cost_type_hash: 'savedHash',
        cost_type_is_fee: 'isFee'
      },
      assembly: {
        assembly_name: 'name',
        quote_total_cost_net: 'cost',
        quote_price_net: 'price',
        asDimensionsUsed: 'dimLinks',
        quote_qty_net_base: 'qty',
        quantity_multiplier: 'quantity_multiplier',
        quote_qty_net: 'total_qty',
        quote_markup_net: 'markup',
        quote_markup_percentage_adjustment: 'parent_adj',
        assembly_markup_percentage_adjustment: 'self_adj',
        quote_markup_net_adjustment: 'net_adj',
        type: 'type',
        quote_notes: 'desc',
        file_ids: 'files',
        quote_production_notes: 'prodNotes',
        assembly_profit_percentage: 'margin',
        parent_cost_type_id: 'category'
      },
      quote: {
        quote_name: 'name',
        quote_total_cost_net: 'cost',
        quote_price_net: 'price',
        quote_qty_net: 'qty',
        quote_cost_net: 'ucost',
        quote_subtotal_net: 'uprice',
        unit_of_measure_id: 'units',
        type: 'type',
        quote_notes: 'desc',
        file_ids: 'files',
        quote_production_notes: 'prodNotes',
        asDimensionsUsed: 'dims'
      }
    }

    const $store = useStore()
    const inCompanyScope = computed(() => $store.state.session.scope.company)

    const minimumMarkup = computed(() =>
      c.marginToMarkup($store.state.session.company.company_minimum_quote_margin)
    )
    const defaultMarkup = computed(() => $store.state.session.company.company_default_markup)

    const { possibleDimensions, getDimensionsInEquation, getBlendedDimensionColor } =
      Dimensions.useDimensions()

    const assemblyHiddenFieldConditional = (value, cell, rowData, other = () => ({})) => {
      if (rowData.type === 'assembly') {
        return {
          borders: {
            left: {
              thickness: 0
            },
            right: {
              thickness: 0
            }
          }
        }
      }
      return other()
    }

    const taskColorBase = DrawUtilities.levelYellow
    const taskColor = c.hexWithOpacity(taskColorBase, 0.2)
    const taskDisabledColorBase = c.blendColors(taskColorBase, DrawUtilities.lightGray, 0.4)
    const taskDisabledColor = c.hexWithOpacity(taskDisabledColorBase, 0.25)

    const mediaColorBase = '#dcfec3'
    const mediaColor = c.hexWithOpacity(mediaColorBase, 0.3)
    const mediaDisabledColorBase = c.blendColors(mediaColorBase, DrawUtilities.lightGray, 0.4)
    const mediaDisabledColor = c.hexWithOpacity(mediaDisabledColorBase, 0.25)

    const isNonCostCostItem = (metaType) => metaType && !/costItem/i.test(metaType)
    const nonCostConditional = (rdisabled = true, value, cell, rowData) => {
      const metaType = rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? ''
      const isNonCost = isNonCostCostItem(metaType)

      if (!isNonCost) {
        const disabled = typeof rdisabled === 'function' ? rdisabled(metaType) : false
        if (disabled) {
          return {
            preset: 'disabled'
          }
        } else {
          return {}
        }
      }

      const disabled = rdisabled
      const readOnly = metaType === 'task' || metaType === 'gallery' || metaType === 'text'

      const bg = metaType === 'task' ? taskColor : mediaColor
      let disabledBg = null
      if (metaType === 'task') disabledBg = taskDisabledColor
      else if (metaType !== 'costItem') disabledBg = mediaDisabledColor

      return {
        ...(disabled
          ? {
              background: disabledBg,
              readOnly,
              color: 'transparent',
              borders: {
                left: {
                  thickness: 0
                }
              }
            }
          : {
              background: bg,
              readOnly,
              borders: {
                left: {
                  thickness: 1
                },
                right: {
                  thickness: 1
                }
              }
            })
      }
    }

    const assemblyHiddenFieldValue = (field) => (rowData) => {
      if (rowData.type === 'assembly') {
        return ''
      }
      return rowData[field]
    }
    const disabledForAssemblies = ({ rowData }) => rowData.type === 'assembly'

    const disabledForNonCosts = ({ rowData }) => {
      const metaType = rowData.oMeta?.itemType ?? rowData?.itemType?.itemType ?? ''
      return /text|gallery|task/.test(metaType)
    }

    const getColorForDimensionCellDraw = (row) => {
      const id = refSheet.value.getRowId(row)
      const dims = norm.value[id].asDimensionsLinked ?? norm.value[id].asDimensionsUsed
      return getBlendedDimensionColor(dims)
    }

    const storeNorm = computed(() => $store.state[props.store].normalized)

    const { getCalculatorVariablesByRefId } =
      CostItemEquationVariables.useCostItemEquationVariables({
        norm: storeNorm
      })

    const quote = computed(() => norm.value[props.refId] ?? {})
    const selectedCell = computed(() => refSheet.value.currentCell)
    const selectedRow = computed(() => refSheet.value.currentCell?.[1])
    const selectedId = computed(() => refSheet.value.getRowId?.(selectedRow.value))
    const selectedObj = computed(() => norm.value[selectedId.value] ?? null)
    const selectedType = computed(() => selectedObj.value?.type)
    const selectedName = computed(
      () =>
        selectedObj.value?.[
          selectedType.value.includes('cost_') ? 'cost_type_name' : `${selectedType.value}_name`
        ]
    )
    const selectedParentId = computed(() => norm.value[selectedId.value]?.parentRefId)
    const selectedUom = computed(() => norm.value[selectedId.value]?.unit_of_measure_id ?? 'count')
    const selectedParentName = computed(
      () =>
        norm.value[selectedParentId.value]?.assembly_name ||
        norm.value[selectedParentId.value]?.quote_name
    )
    const selectedParentDims = computed(() =>
      getCalculatorVariablesByRefId(selectedId.value, selectedUom.value, false)
    )
    const selectedIsCostItem = computed(
      () =>
        selectedType.value === 'cost_item' &&
        (norm.value[selectedId.value]?.oMeta?.itemType ?? 'costItem') === 'costItem'
    )

    const sidepanelTabIndex = ref(1)

    const cicd = CostItem.getComputedDependants()

    const subDisabled = ({ rowData }) =>
      rowData.type === 'assembly' ||
      !rowData.isSubcontracted ||
      isNonCostCostItem(rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? '')
    const matDisabled = ({ rowData }) =>
      rowData.type === 'assembly' ||
      rowData.isSubcontracted ||
      !rowData.hasMaterials ||
      isNonCostCostItem(rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? '')
    const labDisabled = ({ rowData }) =>
      rowData.type === 'assembly' ||
      rowData.isSubcontracted ||
      !rowData.hasLabor ||
      isNonCostCostItem(rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? '')

    const subColumn = {
      title: 'Subcontractor cost',
      field: 'subCost',
      component: {
        name: 'ItemSubcontracted',
        props: () => ({
          refId: selectedId.value,
          type: selectedType.value,
          store: props.store
        }),
        showIf: () => selectedIsCostItem.value
      },
      formatting: {
        width: 120,
        align: 'right',
        format: 'currency'
      },
      conditionalFormatting: (...args) =>
        nonCostConditional(() => subDisabled({ rowData: args[2] }), ...args),
      computedValue: (rowData) => {
        if (rowData.isSubcontracted) return rowData.materialsCost
        return ''
      },
      disabled: subDisabled
    }
    const materialsColumn = {
      title: 'Materials cost per unit',
      field: 'materialsCost',
      component: {
        name: 'ItemMaterials',
        props: () => ({
          refId: selectedId.value,
          type: selectedType.value,
          store: props.store
        }),
        showIf: () => selectedIsCostItem.value
      },
      formatting: {
        width: 120,
        align: 'right',
        format: (value, cd, rowData) => {
          if (rowData.isSubcontracted || !rowData.hasMaterials) return ''

          return c.format(value, 'currency')
        },
        deformat: 'currency'
      },
      conditionalFormatting: (...args) =>
        nonCostConditional(() => matDisabled({ rowData: args[2] }), ...args),
      disabled: matDisabled
    }

    const laborColumn = {
      title: 'Labor cost per unit',
      field: 'laborCost',
      component: {
        name: 'ItemLabor',
        props: () => ({
          refId: selectedId.value,
          type: selectedType.value,
          store: props.store
        }),
        showIf: () => selectedIsCostItem.value
      },
      formatting: {
        width: 100,
        align: 'right',
        format: (value, cd, rowData) => {
          if (rowData.isSubcontracted || !rowData.hasLabor) return ''

          return c.format(value, 'currency')
        },
        deformat: 'currency'
      },
      conditionalFormatting: (...args) =>
        nonCostConditional(() => labDisabled({ rowData: args[2] }), ...args),
      disabled: labDisabled
    }

    const inGlobalScope = computed(() => {
      return $store.state.session.user.user_is_super_user && !$store.state.session.scope.company
    })

    const columns = computed(() => [
      {
        title: 'Name',
        field: 'name',
        titleColSpan: 2,
        formatting: {
          width: 250,
          align: 'left',
          borders: {
            right: {
              thickness: 0
            }
          }
        },
        conditionalFormatting: (...args) => nonCostConditional(false, ...args),
        onChange: ({ id, text }) => costItemNameHandler(id, text)
      },
      {
        field: 'metaType',
        title: ' ',
        forceUpdate: true,
        conditionalFormatting: (...args) => nonCostConditional(false, ...args),
        action: (cellValue, rowData) => {
          editItemFromRefId(rowData.id)
        },
        actionText: '',
        formatting: {
          width: 40,
          align: 'center',
          borders: {
            right: {
              thickness: 1
            },
            left: {
              thickness: 0
            }
          },
          deformat: (value, sh, rd) => {
            return rd.oMeta || rd.metaType
          },
          preventDefaultDraw: true,
          draw: ({
            ctx,
            clipTo,
            text: metaType,
            drawIcon,
            lightGrayTrans,
            // lightGray,
            getRowData
          }) => {
            let icon = 'cube'

            let color = lightGrayTrans
            const { id: refId, isFee = 0 } = getRowData()
            const obj = { ...norm.value[refId] }

            let iconType = _.ucfirst(metaType?.itemType || metaType)
            if (obj.cost_type_is_fee || isFee) {
              iconType = 'Fee'
            } else if (
              obj.cost_type_is_variation_parent ||
              obj.oVariations?.items?.length ||
              obj.cost_type_is_addon_group ||
              obj.aoAddons?.length
            ) {
              iconType = 'Variation'
            } else if (obj.cost_item_is_optional) {
              iconType = 'Option'
            } else if (obj.cost_item_has_live_pricing) {
              iconType = 'AutoCost'
            }
            switch (iconType) {
              case 'Fee':
                icon = 'percent'
                break
              case 'Variation':
                icon = 'swatchbook'
                break
              case 'Option':
                icon = 'toggle-on'
                break
              case 'Assembly':
                icon = 'cubes'
                break
              case 'AutoCost':
                icon = 'circle-dollar'
                break
              case 'Task':
                icon = 'check-square'
                break
              case 'Cost Item':
                icon = 'cube'
                break
              case 'Gallery':
                icon = 'images'
                break
              case 'Text':
                icon = 'align-left'
                break
            }
            // }

            drawIcon(ctx, clipTo, icon, color)
            return ctx
          }
        },
        computedValue: (rowData) => {
          if (rowData.type === 'assembly') return 'Assembly'
          const oMeta = rowData.oMeta

          if (rowData.type === 'cost_item' && norm.value[rowData.id]?.cost_type_id) {
            return `${rowData.savedHash}-${rowData.currentHash}`
          }

          const metaType = oMeta?.itemType ?? null
          if (metaType) return c.ucfirst(metaType)

          const refId = rowData.id
          if (norm.value[refId]?.cost_item_has_live_pricing) return 'AutoCost'

          // Trigger the following dependants:
          // rowData.savedHash
          return rowData.currentHash
        }
      },
      {
        field: 'assignee',
        title: 'Assign',
        choose: {
          schema: 'assignee:assignee_id',
          allowCreate: true
        },
        hideInGlobalScope: true,
        conditionalFormatting: (...args) => ({
          ...assemblyHiddenFieldConditional(...args),
          ...nonCostConditional(
            !/costItem|task/.test(
              args[2]?.oMeta?.itemType ?? args[2]?.metaType?.itemType ?? 'costItem'
            ),
            ...args
          )
        }),
        disabled: (...args) => {
          const { rowData } = args[0]
          const metaType = rowData.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem'
          return !(rowData.type === 'cost_item' && (metaType === 'task' || metaType === 'costItem'))
        },
        formatting: {
          width: 40,
          align: 'left',
          preventDefaultDraw: true,
          draw: ({
            ctx,
            clipTo,
            text: assignee,
            drawIcon,
            black,
            lightGrayTrans,
            cell: [, row],
            drawText
          }) => {
            const rowData = refSheet.value?.getRowData(row)
            const itemType = rowData?.type
            const metaType = rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem'
            if (itemType !== 'cost_item' || !/task|costItem/.test(metaType)) {
              return ctx
            }

            if (!assignee || assignee === '...') {
              drawIcon(ctx, clipTo, 'circle-user', lightGrayTrans)
              return ctx
            }

            const [type, id] = assignee.split(':')
            const color = c.stringToColor(`${id}${type}`)

            ctx.beginPath()

            // Draw a circle
            ctx.arc(21, 21, 15, 0, 2 * Math.PI)

            // Set the style for the circle
            ctx.fillStyle = color // Fill color
            ctx.fill() // Fill the circle

            // End the path
            ctx.closePath()

            drawText(ctx, [1, 1, 40, 40], assignee[0], {
              bold: true,
              color: 'white',
              fontSize: 18
            })

            drawText(ctx, [40, 1, 100, 40], assignee, {
              bold: true,
              color: black,
              fontSize: 14,
              align: 'left'
            })
          }
        }
      },
      {
        title: 'Progress',
        field: 'progress',
        formatting: {
          width: 160,
          minWidth: 160
        },
        hideInGlobalScope: true,
        computedValue: (rowData) => {
          const approval = findApprovalByItemId(rowData.id)
          const isApproved =
            isApprovedByApprover(approval) || rowData.companyHasApprovedItem === statuses.Completed
          return [
            rowData.changeOrderStatus && rowData.changeOrderStatus === 'k' ? 1 : 0,
            rowData.taskStatus && rowData.taskStatus === 'c' ? 1 : 0,
            isApproved ? 1 : 0,
            rowData.invoiceId && rowData.invoiceStatus && rowData.invoiceStatus === 'e'
          ]
        },
        conditionalFormatting: (...args) =>
          nonCostConditional(
            !/costItem|task/.test(
              args[2]?.oMeta?.itemType ?? args[2]?.metaType?.itemType ?? 'costItem'
            ),
            ...args
          ),
        disabled: (...args) => {
          const { rowData } = args[0]
          const metaType = rowData.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem'
          return rowData.type !== 'cost_item' || metaType === 'text' || metaType === 'gallery'
        },
        progress: {
          showComplete: false,
          showBar: true,
          colorRange: '#0b4bff',
          steps: [
            {
              text: 'Scope approved by client',
              enableDescription: 'Client has not yet approved this scope.',
              disableDescription:
                'This scope has been approved by the client, and this work can be completed.',
              disabled: true,
              action: () => {},
              underlay: {
                icon: 'house-circle-check',
                square: 0
              }
            },
            {
              text: 'Work complete',
              enableDescription: 'Work not complete. Click to mark item as completed internally.',
              disableDescription: 'The work for this item as been completed.',
              action: ({ rowData }) => {
                costItemStatusHandler(rowData.id)
              },
              underlay: {
                icon: 'clipboard-check',
                square: 0
              }
            },
            {
              groups: ['a'],
              text: 'Quality approved by you',
              enableDescription: 'Mark this item as approved for payment from your client.',
              action: ({ rowData }) => {
                costItemApprovalHandler(rowData.id)
              },
              disableDescription:
                'This item has been approved by you and payment has been requested.',
              underlay: {
                icon: 'badge-check',
                square: 2
              }
            },
            {
              groups: ['a'],
              text: 'Work approved and paid by client',
              enableDescription:
                'This item has not been paid by your client yet. This step will be completed automatically by the system after payment is made.',
              disableDescription: 'You have received the payment for this item from your client',
              action: () => {},
              disabled: true,
              underlay: {
                icon: 'money-bill-wave',
                square: 0
              }
            }
          ]
        }
      },

      {
        title: 'Pics',
        field: 'files',
        formatting: {
          width: 40
        },
        conditionalFormatting: (...args) => nonCostConditional(false, ...args),
        attachments: {
          props: () => ({
            startingFolder: `quote-${quote.value.quote_id}`,
            idList: true,
            dropzone: 'row',
            class: 'max-h-96'
          })
        }
      },
      {
        title: 'Desc',
        field: 'desc',
        formatting: {
          wordWrap: true,
          width: 40,
          align: 'left',
          verticalAlign: 'top',
          preventDefaultDraw: true,
          draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
            if (!desc) {
              // drawIcon(ctx, clipTo, 'input-text', lightGrayTrans);
              return ctx
            }

            drawIcon(ctx, clipTo, 'input-text', blueTrans)

            drawText(ctx, [41, 0, 400, 40], desc, {
              fontSize: 14,
              align: 'left',
              verticalAlign: 'top'
            })
          }
        },
        conditionalFormatting: (...args) => nonCostConditional(false, ...args)
      },

      {
        ...((col) => ({
          formatting: {
            width: 40
          },
          ...col,
          checkbox: {
            unchecked: {
              icon: col.icon ?? 'square'
            },
            checked: {
              color: col.color ?? null,
              background: col.background ?? col.color ?? null,
              icon: col.icon ?? 'square-check'
            }
          }
        }))({
          title: 'Labor',
          field: 'hasLabor',
          tooltip: 'Toggle if labor is included in this item',
          icon: 'user-helmet-safety',
          background: c.getCssColor('labor').replace(/\)$/, ',0.2)'),
          color: c.getCssColor('labor'),
          disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
        })
      },

      {
        ...((col) => ({
          formatting: {
            width: 40
          },
          ...col,
          checkbox: {
            unchecked: {
              icon: col.icon ?? 'square'
            },
            checked: {
              color: col.color ?? null,
              background: col.background ?? col.color ?? null,
              icon: col.icon ?? 'square-check'
            }
          }
        }))({
          superHeader: 'Materials costs',
          title: 'Materials',
          field: 'hasMaterials',
          icon: 'box-taped',
          tooltip: 'Toggle if materials are included in this item',
          background: c.getCssColor('materials').replace(/\)$/, ',0.2)'),
          color: c.getCssColor('materials'),
          disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
        })
      },
      {
        ...((col) => ({
          formatting: {
            width: 40
          },
          ...col,
          checkbox: {
            unchecked: {
              icon: col.icon ?? 'square'
            },
            checked: {
              color: col.color ?? null,
              background: col.background ?? col.color ?? null,
              icon: col.icon ?? 'square-check'
            }
          }
        }))({
          title: 'Sub',
          field: 'isSubcontracted',
          tooltip: 'Toggle if this item is subcontracted',
          icon: 'truck',
          background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)'),
          color: c.getCssColor('subcontractor'),
          disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
        })
      },

      subColumn,
      materialsColumn,
      laborColumn,
      {
        title: 'Combined unit cost',
        field: 'ucost',
        formatting: {
          width: 100,
          align: 'right',
          format: 'currency'
        },
        conditionalFormatting: (...args) => ({
          ...assemblyHiddenFieldConditional(...args),
          ...nonCostConditional(true, ...args)
        }),
        computedValue: assemblyHiddenFieldValue('ucost'),
        disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args)
      },

      // {
      //   title: 'matrix markup',
      //   field: 'matrix_markup',
      //   disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args),
      // },
      {
        title: 'Profit %',
        field: 'margin',
        formatting: {
          width: 75,
          align: 'right',
          format: 'percentageWhole'
        },
        conditionalFormatting: (...args) => ({
          ...assemblyHiddenFieldConditional(args[0], args[1], args[2], (value) => {
            if (value < 1) return { preset: 'danger' }
            if (value < minimumMarkup.value) return { preset: 'danger' }
            if (value < defaultMarkup.value) return { preset: 'warning' }
            return {}
          }),
          ...nonCostConditional(true, ...args)
        }),
        computedValue: (row) => {
          if (row.type === 'cost_item') {
            return c.markupToMargin(row.markup) * 100
          }

          return c.divide(row.price - row.cost, row.price) * 100
        },
        disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args)
      },
      {
        title: 'Unit price',
        field: 'uprice',
        formatting: {
          width: 100,
          align: 'right',
          format: 'currency'
        },
        conditionalFormatting: (...args) => ({
          ...assemblyHiddenFieldConditional(...args),
          ...nonCostConditional(true, ...args)
        }),
        computedValue: assemblyHiddenFieldValue('uprice'),
        disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args)
      },
      {
        title: 'Dimension',
        field: 'dims',
        action: (cellValue, rowData) => {
          goToRoom(rowData.id)
        },
        // onDraw: ({ redrawCells, col, row }) => redrawCells([[col + 1, row], [col + 2, row]], true),
        actionText: '      ',
        formatting: {
          width: 60,
          bold: true,
          borders: {
            right: {
              thickness: 0
            }
          },
          preventDefaultDraw: true,
          draw: ({ ctx, clipTo, text: eq, drawIcon, lightGrayTrans, cell: [, row], drawText }) => {
            const newClipTo = clipTo
            newClipTo[3] = 40
            const rowData = refSheet.value?.getRowData(row)
            const isCostItem =
              rowData?.type === 'cost_item' &&
              (rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem') === 'costItem'
            const correctType = isCostItem || rowData?.type === 'assembly'

            if (!correctType) {
              return ctx
            }

            if (!eq.length) {
              drawIcon(ctx, newClipTo, 'ruler-triangle', lightGrayTrans)
              return ctx
            }

            const color = getColorForDimensionCellDraw(row)
            drawIcon(ctx, newClipTo, 'ruler-triangle', color)

            // Draw text for dim
            const id = refSheet.value.getRowId(row)
            const dims = _.makeArray(isCostItem ? norm.value[id].asDimensionsLinked : eq)
            const text = `${dims.slice(0, 2).join(',')}${dims.length > 2 ? '+' : ''}`
            drawText(ctx, [clipTo[3] / 2, 8, clipTo[2], 20], text, {
              fontSize: 10,
              align: 'left',
              verticalAlign: 'top',
              color
            })

            // only do the wrapper if cost item
            if (!isCostItem) return ctx

            const padding = 5
            const br = 3
            const [x, y, width, height] = clipTo

            const rheight = height - padding * 2 // Height adjusted for padding
            const rwidth = width - padding // Width adjusted for padding
            const xx = x + padding // X coordinate
            const yy = y + padding // Y coordinate

            // Draw the rectangle with rounded corners
            ctx.beginPath()
            ctx.moveTo(xx + br, yy) // Adjust starting point for border radius
            ctx.lineTo(xx + rwidth, yy)
            // ctx.arcTo((xx + rwidth), yy, (xx + rwidth), (yy + br), br);
            // ctx.lineTo((xx + rwidth), (yy + rheight) - br);
            // ctx.arcTo((xx + rwidth), (yy + rheight),
            //   (xx + rwidth) - br, (yy + rheight), br);
            ctx.moveTo(xx + rwidth, yy + rheight)
            ctx.lineTo(xx + br, yy + rheight)
            ctx.arcTo(xx, yy + rheight, xx, yy + rheight - br, br)
            ctx.lineTo(xx, yy + br)
            ctx.arcTo(xx, yy, xx + br, yy, br)
            ctx.lineJoin = 'round'
            ctx.lineCap = 'round'

            ctx.lineWidth = 2
            ctx.strokeStyle = color
            ctx.stroke()
          }
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        variables: ({ id, rowData }) => getCalculatorVariablesByRefId(id, rowData.units, false),
        computedValue: (rowData) => {
          const dims = [...(rowData.dimLinks || [])]
          const text = `${dims.slice(0, 2).join(',')}${dims.length > 2 ? '+' : ''}`
          return text
        }
      },
      {
        title: 'Qty',
        titleColSpan: 2,
        field: 'qty',
        onSelect: (cellData, rowData) => goToRoom(rowData.id),
        onDraw: ({ redrawCells, col, row }) =>
          redrawCells(
            [
              [col - 1, row],
              [col + 1, row]
            ],
            true
          ),
        formatting: {
          format: 'number',
          width: 80,
          align: 'right',
          bold: true,
          borders: {
            right: {
              thickness: 0
            },
            left: {
              thickness: 0
            }
          },
          deformat: (value, cd, rowData) => {
            const eq = String(value).replace(/^=/, '')
            if (rowData.type === 'assembly') {
              return c.toNum(eq, 20, true)
            }

            if (!value) return 0

            const obj = {
              ...norm.value[rowData.id],
              ...rowData,
              cost_type_qty_equation: eq
            }
            const parent = {
              ...norm.value[obj.parentRefId],
              ...rowData
            }
            return cicd.cost_item_qty_net_base(obj, parent, [], possibleDimensions.value)
          },
          preventDefaultDraw: false,
          draw: ({ ctx, clipTo, cell: [, row] }) => {
            const rowData = refSheet.value?.getRowData(row)
            const dimValue = rowData?.dims

            const correctType =
              rowData?.type === 'cost_item' &&
              (rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem') === 'costItem'
            if (!dimValue?.length || !correctType) {
              return ctx
            }

            const color = getColorForDimensionCellDraw(row)

            const padding = 5
            const [x, y, width, height] = clipTo

            const rheight = height - padding * 2 // Height adjusted for padding
            const rwidth = width // Width adjusted for padding
            const xx = x // X coordinate
            const yy = y + padding // Y coordinate

            // Draw the rectangle with rounded corners
            ctx.beginPath()
            ctx.moveTo(xx, yy) // Adjust starting point for border radius
            ctx.lineTo(xx + rwidth, yy)
            ctx.moveTo(xx + rwidth, yy + rheight)
            ctx.lineTo(xx, yy + rheight)

            ctx.lineWidth = 2
            ctx.strokeStyle = color
            ctx.stroke()
          }
        },
        desc: ({ id }) => {
          const parentRef = norm.value[id].parentRefId
          const name = norm.value[id].cost_type_name || norm.value[id].assembly_name
          const parentName =
            norm.value[parentRef].assembly_name || norm.value[parentRef].assembly_name
          return `This is the quantity of ${name} in each ${parentName}.`
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForNonCosts(...args),
        variables: ({ id, rowData }) => getCalculatorVariablesByRefId(id, rowData.units, false)
      },
      {
        field: 'units',
        choose: {
          schema: 'unit_of_measure:unit_of_measure_id',
          order: [
            ['unit_of_measure_is_linkable', 'desc'],
            [
              'unit_of_measure_is_metric',
              $store.state.session.company.country_id <= 2 ? 'asc' : 'desc'
            ]
          ]
        },
        // onDraw: ({ redrawCells, col, row }) => c.throttle(() => redrawCells([[col - 2, row], [col - 1, row]], true), { key: `${[[col - 2, row], [col - 1, row]].join(',')}`},
        formatting: {
          width: 60,
          align: 'left',
          borders: {
            left: {
              thickness: 0
            }
          },
          preventDefaultDraw: false,
          draw: ({ ctx, clipTo, cell: [, row] }) => {
            const rowData = refSheet.value?.getRowData(row)
            const dimValue = rowData?.dims

            const correctType =
              rowData?.type === 'cost_item' &&
              (rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem') === 'costItem'
            if (!dimValue?.length || !correctType) {
              return ctx
            }

            const color = getColorForDimensionCellDraw(row)
            const padding = 5
            const br = 3
            const [x, y, width, height] = clipTo

            const rheight = height - padding * 2 // Height adjusted for padding
            const rwidth = width - padding // Width adjusted for padding
            const xx = x // X coordinate
            const yy = y + padding // Y coordinate

            // Draw the rectangle with rounded corners
            ctx.beginPath()
            ctx.moveTo(xx, yy) // Adjust starting point for border radius
            ctx.lineTo(xx + rwidth - br, yy)
            ctx.arcTo(xx + rwidth, yy, xx + rwidth, yy + br, br)
            ctx.lineTo(xx + rwidth, yy + rheight - br)
            ctx.arcTo(xx + rwidth, yy + rheight, xx + rwidth - br, yy + rheight, br)
            ctx.moveTo(xx + rwidth - br, yy + rheight)
            ctx.lineTo(xx, yy + rheight)
            // ctx.arcTo(xx, (yy + rheight), xx, (yy + rheight) - br, br);
            // ctx.lineTo(xx, (yy + br));
            // ctx.arcTo(xx, yy, (xx + br), yy, br);
            ctx.lineJoin = 'round'
            ctx.lineCap = 'round'

            ctx.lineWidth = 2
            ctx.strokeStyle = color
            ctx.stroke()
          }
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args)
      },
      {
        title: 'Production quantities',
        titleColSpan: 2,
        field: 'total_qty',
        formatting: {
          format: 'number',
          width: 80,
          align: 'right',
          bold: true,
          borders: {
            right: {
              thickness: 0
            }
          }
        },
        desc: ({ id }) => {
          const parentRef = norm.value[id].parentRefId
          const name = norm.value[id].cost_type_name || norm.value[id].assembly_name
          const parentName =
            norm.value[parentRef].assembly_name || norm.value[parentRef].assembly_name
          return `This is the quantity of ${name} in each ${parentName} × the quantity of ${parentName}.`
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForNonCosts(...args)
      },

      {
        field: 'units',
        choose: {
          schema: 'unit_of_measure:unit_of_measure_id',
          order: [
            ['unit_of_measure_is_linkable', 'desc'],
            [
              'unit_of_measure_is_metric',
              $store.state.session.company.country_id <= 2 ? 'asc' : 'desc'
            ]
          ]
        },
        formatting: {
          width: 50,
          align: 'left',
          borders: {
            left: {
              thickness: 0
            }
          }
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForAssemblies(...args) || disabledForNonCosts(...args)
      },
      {
        field: 'stage',
        title: 'Stage',
        choose: {
          schema: 'cost_type:stage_id'
        },
        formatting: {
          width: 100
        },
        conditionalFormatting: (...args) =>
          nonCostConditional(
            !/costItem|task/.test(
              args[2]?.oMeta?.itemType ?? args[2]?.metaType?.itemType ?? 'costItem'
            ),
            ...args
          ),
        // onChange: ({ id }) => costItemStageHandler(id),
        disabled: (...args) => {
          const { rowData } = args[0]
          const metaType = rowData?.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem'

          if (rowData.type !== 'cost_item') return true

          if (metaType && metaType !== 'task' && metaType !== 'costItem') return true

          return false
        }
      },
      {
        title: 'Production notes',
        field: 'prodNotes',
        formatting: {
          width: 200,
          align: 'left',
          wordWrap: true,
          verticalAlign: 'top'
        },
        disabled: (...args) => {
          const { rowData } = args[0]
          const metaType = rowData.oMeta?.itemType ?? rowData?.metaType?.itemType ?? 'costItem'
          return !(rowData.type === 'cost_item' && (metaType === 'task' || metaType === 'costItem'))
        },
        conditionalFormatting: (...args) =>
          nonCostConditional(
            !/costItem|task/.test(
              args[2]?.oMeta?.itemType ?? args[2]?.metaType?.itemType ?? 'costItem'
            ),
            ...args
          )
      },
      {
        title: 'Budget code',
        field: 'budgetCode',
        formatting: {
          width: 80,
          align: 'left'
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForNonCosts(...args)
      },
      {
        title: 'Catalog category',
        field: 'category',
        choose: inCompanyScope.value
          ? {
              schema: 'cost_type:cost_type_id',
              allowCreate: false,
              order: [['cost_type_name', 'asc']],
              customOptions: [
                {
                  value: 'NULL',
                  text: 'Root directory',
                  html: 'Root directory'
                }
              ],
              filters: {
                cost_type_is_parent: 1,
                company_id: $store.state.session.company?.company_id ?? 'NULL'
              }
            }
          : {
              staticSet: publicCategories.value
            },

        formatting: {
          width: 150
        },
        conditionalFormatting: (...args) => ({
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForNonCosts(...args)
      },

      {
        title: 'Cost',
        field: 'cost',
        formatting: {
          width: 100,
          align: 'right',
          format: 'currency'
        },
        disabled: (...args) => disabledForNonCosts(...args),
        conditionalFormatting: (...args) => nonCostConditional(true, ...args)
      },
      {
        title: 'Profit %',
        field: 'margin',
        formatting: {
          width: 75,
          align: 'right',
          format: 'percentageWhole'
        },
        conditionalFormatting: (...args) => ({
          ...((...args) => {
            const value = args[0]
            if (value < 1) return { preset: 'danger' }
            if (value < minimumMarkup.value) return { preset: 'danger' }
            if (value < defaultMarkup.value) return { preset: 'warning' }
            return {}
          })(),
          ...nonCostConditional(true, ...args)
        }),
        computedValue: (row) => {
          if (row.type === 'cost_item') {
            return c.markupToMargin(row.markup) * 100
          }

          return c.divide(row.price - row.cost, row.price) * 100
        },
        disabled: (...args) => disabledForNonCosts(...args)
      },
      {
        title: 'Price',
        field: 'price',
        formatting: {
          width: 100,
          align: 'right',
          format: 'currency'
        },
        conditionalFormatting: (...args) => ({
          ...((...args) => {
            const value = args[0]
            if (value < 1) return { preset: 'danger' }
            if (value < minimumMarkup.value) return { preset: 'danger' }
            if (value < defaultMarkup.value) return { preset: 'warning' }
            return {}
          })(),
          ...nonCostConditional(true, ...args)
        }),
        disabled: (...args) => disabledForNonCosts(...args)
      }
      // {
      //   title: 'refId',
      //   field: 'id',
      //   formatting: {
      //     width: 100,
      //     align: 'right',
      //   },
      // },
      // {
      //   title: 'parentRefId',
      //   field: 'parentId',
      //   formatting: {
      //     width: 100,
      //     align: 'right',
      //   },
      // },
      // {
      //   title: 'aoChildren',
      //   field: 'childrenIds',
      //   formatting: {
      //     wordWrap: true,
      //     width: 500,
      //     align: 'left',
      //   },
      // },
    ])
    const superHeaders = computed(() => [
      ...(inGlobalScope.value
        ? [
            {
              title: 'All costing data',
              icon: 'calculator',
              span: [
                [7, 9],
                [10, 12],
                [22, 23]
              ],
              hidden: true,
              expanded: true
            },
            {
              title: 'Progress',
              icon: 'circle-user',
              span: [],
              hidden: inGlobalScope.value,
              expanded: showProductionColumns.value
            },
            {
              title: 'Desc',
              icon: 'presentation-screen',
              span: [2, 3]
            },
            {
              title: 'Unit costing',
              icon: 'box-taped',
              span: [7, 9],
              expanded: false
            },
            {
              title: 'Unit pricing',
              // icon: 'cash-register',
              icon: 'tag',
              span: [10, 12]
            },
            {
              title: 'Quantity',
              icon: 'ruler-triangle',
              span: [13, 15],
              expanded: true
            },
            {
              title: 'Production details',
              icon: 'hammer-brush',
              span: [16, 21],
              expanded: showProductionColumns.value
            },
            {
              title: 'Totals',
              icon: 'calculator',
              span: [22, 24]
            }
          ]
        : [
            {
              title: 'All costing data',
              icon: 'calculator',
              span: [
                [9, 11],
                [12, 14],
                [24, 25]
              ],
              hidden: true,
              expanded: true
            },
            {
              title: 'Progress',
              icon: 'circle-user',
              span: [2, 3],
              expanded: showProductionColumns.value
            },
            {
              title: 'Desc',
              icon: 'presentation-screen',
              span: [4, 5]
            },
            {
              title: 'Unit costing',
              icon: 'box-taped',
              span: [9, 11],
              expanded: false
            },
            {
              title: 'Unit pricing',
              // icon: 'cash-register',
              icon: 'tag',
              span: [12, 14]
            },
            {
              title: 'Quantity',
              icon: 'ruler-triangle',
              span: [15, 17],
              expanded: true
            },
            {
              title: 'Production details',
              icon: 'hammer-brush',
              span: [18, 23],
              expanded: showProductionColumns.value
            },
            {
              title: 'Totals',
              icon: 'calculator',
              span: [24, 26]
            }
          ])
    ])

    const fieldSetters = {
      ...FieldSetters
    }

    const equationSetters = {
      ...EquationSetters
    }

    const filteredColumns = computed(() => {
      if (!inGlobalScope.value) return columns.value
      return columns.value.filter((column) => !column.hideInGlobalScope)
    })

    const {
      sheetRows,
      diff,
      norm,
      setFields,
      auditProgress,
      reloadItem,
      mapChangeSet,
      setMeta,
      setAsFee,
      storeLoading,
      importAddedItems
    } = EntitySheet.useEntitySheet({
      fieldMapping,
      columns: filteredColumns.value,
      store: props.store,
      refId: props.refId,
      refSheet,
      fieldSetters,
      equationSetters
    })

    const findRooms = (refId, equation, nn = norm.value) => {
      const dims = getDimensionsInEquation(equation)

      const rooms = []
      for (let i = 0; i < dims.length; i += 1) {
        const abbr = dims[i]
        let found = false
        let parentRefId = nn[refId].parentRefId
        while (!found || !parentRefId) {
          const parentDims = nn[parentRefId].oDimensions
          if (!parentDims?.[abbr]?.inherit) {
            found = true
            rooms.push({
              refId,
              parentRefId,
              abbr,
              dimension: parentDims[abbr]
            })
          } else {
            parentRefId = nn[parentRefId].parentRefId
          }
        }
      }

      return rooms
    }

    const goToRoom = async (refId) => {
      showSidebar.value = true
      await nextTick()
      sidepanelTabIndex.value = 2
      await nextTick()

      const isAssembly = norm.value[refId]?.type === 'assembly'
      const eq =
        (!isAssembly && norm.value[refId]?.cost_type_qty_equation) ||
        norm.value[refId].asDimensionsUsed.join(' + ')

      if (!eq && !isAssembly) return

      const rooms = findRooms(refId, eq)

      eventBus.$emit('closeDimensions')
      eventBus.$emit(`openDimensions`)

      if (norm.value[refId].type === 'assembly') eventBus.$emit(`openDimensions-${refId}`)

      rooms.forEach((room) => eventBus.$emit(`openDimensions-${room.parentRefId}`, room.abbr))
    }

    const searchPhrase = ref(null)
    const cellTypingHandler = (val) => {
      searchPhrase.value = val
    }

    const inTutorial = computed(() => step.value !== null)

    const {
      showItemSelectorDropdown,
      itemSelectorDropdownStyle,
      dropOptions,
      handleItemDropOption,
      addingProgress,
      addItems
    } = CostItemNameDropdown.useCostItemNameDropdown(
      {
        store: props.store,
        refId: props.refId,
        refSheet,
        importAddedItems,
        norm,
        setMeta,
        setAsFee,
        searchPhrase,
        inTutorial
      },
      { emit: $this.$emit }
    )

    const showProductionColumns = computed(() => !!/[kgf]/.test(quote.value.quote_status))
    const sheets = computed(() => [
      {
        uid: c.uniqueId(),
        title: props.title,
        icon: props.icon,
        rows: sheetRows.value.items[0] || [],
        equations: sheetRows.value.equations[0] || [],
        columns: filteredColumns.value,

        collapseGroups: {
          sortField: 'location',
          rootId: props.refId,
          isParent: (data) => {
            return data?.type === 'assembly' || false
          },
          rootChildrenIds: [...norm.value[props.refId].aoChildren],
          parentFormatting: {
            preset: 'heading',
            bold: true
          }
        },
        superHeaders: superHeaders.value,
        sort: {
          sort: []
        },

        group: {
          group: [],
          showHeadings: true,
          showTotals: true,
          spacing: 50
        }
      }
    ])

    const objectSaved = (obj) => {
      const type = obj.type
      const prefix = type === 'cost_item' ? 'cost_type' : 'assembly'
      const saved = obj[`${prefix}_id`]
      return !!saved
    }

    const isTask = (obj = {}) => {
      return (obj?.oMeta?.itemType ?? obj?.metaType?.itemType ?? 'costItem') === 'task'
    }

    const refItemEditor = ref(null)
    const editingRefId = ref(null)
    const editingItemType = computed(() => norm.value?.[editingRefId.value]?.type)

    const editItemFromRefId = async (refId) => {
      editingRefId.value = refId
      await c.throttle(() => {}, { delay: 400 })
      refItemEditor.value.open()
    }
    const editItem = async (rows, { sheet }) => {
      editItemFromRefId(sheet.getRowId(rows[0]))
    }
    const handleCostItemClose = async () => {
      await c.throttle(() => {}, { delay: 400 })
      reloadItem(editingRefId.value)
      refItemEditor.value.close()
      editingRefId.value = null
    }

    const costItemStatusHandler = async (refId) => {
      const object = norm.value[refId]
      let status = statuses.Pending
      if (object.item_status === statuses.Pending) status = statuses.Completed
      const normed = {
        [refId]: {
          item_status: status
        }
      }
      const { values } = mapChangeSet(normed)
      refSheet.value.setFieldValues(values, {}, false, false)
      saveItemField(statuses.Completed, 'item_status', object.item_id)
    }

    const costItemApprovalHandler = async (refId) => {
      const approval = findApprovalByItemId(refId)
      const isApproved = isApprovedByApprover(approval)
      let status = Approval.approvalStatuses.APPROVED
      if (isApproved) status = Approval.approvalStatuses.DECLINED
      const updateApproval = await processAction(approval, status)
      upsertApproval(updateApproval)
      // mostly did this so the cell computedValue reacts to change
      const normed = {
        [refId]: {
          item_company_status:
            status === Approval.approvalStatuses.APPROVED ? statuses.Completed : statuses.Pending
        }
      }
      const { values } = mapChangeSet(normed)
      refSheet.value.setFieldValues(values, {}, false, false)
      setFields(normed)
    }

    const costItemNameHandler = async (refId, name) => {
      const object = _.imm(norm.value[refId])
      object.cost_type_name = name

      const parent = _.imm(norm.value[object.parentRefId])
      const guesses = await CostTypeSuggestor.suggest({
        object: object,
        parent: parent,
        fields: [
          'stage_id',
          'stage_name',
          // 'unit_of_measure_id',
          // 'unit_of_measure_name',
          // 'unit_of_measure_abbr',
          'parent_cost_type_id',
          'trade_type_id',
          'trade_type_name',
          'labor_type_id',
          'labor_type_name',
          'cost_type_qty_equation',
          'cost_type_has_labor',
          'cost_type_has_materials'
        ],
        override: true
      })
      if (!Object.keys(guesses.bestResult).length) return

      const changes = {
        ...Object.values(guesses.bestResult).reduce((acc, obj) => ({ ...acc, ...obj }), {})
      }
      const run = () => {
        const normed = {
          [refId]: changes
        }
        const { values } = mapChangeSet(normed)
        refSheet.value.setFieldValues(values, {}, false, true)
      }

      // not relevant or no results, do nothing
      if (object.type !== 'cost_item' || !changes?.stage_name) {
        return
      }

      // already set, so confirm first
      if (
        object.stage_id &&
        object.stage_id !== costItemDefaults.value?.stage_id &&
        changes.stage_id !== object.stage_id
      ) {
        $store.dispatch('alert', {
          size: 'sm',
          ai: true,
          message: `Would you like to change the construction stage of ${object.cost_type_name} to "${changes.stage_name}"?`,
          actions: [
            {
              title: 'Yes, change it',
              action: () => run()
            }
          ],
          timeout: 10000
        })
      } else {
        // not already set, so just set it
        run()
      }
    }

    const suggestion = async () => {
      costItemNameHandler()
      // const uom = await CostTypeSuggestor.suggest({ id: refSheet.value.rowsMap[0].id } ['unit_of_measure_id']);
      // ;
    }

    const saveProgress = ref(0)
    const save = async ({ rows, sheet, asSuper = false, asNew = false }) => {
      // TODO: when multiple or changes detected, open save modal with preview of changes
      // Do 20 at a time
      const chunks = c.chunk(rows, 20)

      try {
        await c.waterfall(
          chunks.map((chunkSet) => async () => {
            const promises = []

            for (let i = 0; i < chunkSet.length; i += 1) {
              const rowIndex = chunkSet[i]
              const refId = sheet.getRowId(rowIndex)
              const obj = norm.value[refId]
              const type = obj.type
              const action =
                type === 'cost_item' ? 'CostType/saveFromCostItem' : 'Assembly/saveFromAssembly'

              promises.push(
                (async () => {
                  saveProgress.value += saveProgress.value + (1 / rows.length) * 80
                  return $store.dispatch(action, {
                    refId,
                    store: props.store,
                    asSuper,
                    asNew,
                    quiet: true
                  })
                })()
              )
            }

            await Promise.all(promises)
          })
        )
      } finally {
        saveProgress.value = 0
      }

      $store.dispatch('alert', {
        type: 'success',
        message: `${rows.length} saved`
      })
    }

    const totalProgress = computed(() => {
      const agg = [
        auditProgress.value,
        saveProgress.value,
        addingProgress.value,
        storeLoading.value
      ]

      const loading = agg.filter((l) => l > 0 && l < 100)

      return c.divide(
        loading.reduce((acc, l) => acc + l, 0),
        loading.length
      )
    })

    // Get saved hashes only once at the beginning of load
    $this.$once('selected', () => $store.dispatch('Quote/getHashes'))
    const stopWatch = watch(norm, () => {
      if (Object.values(norm.value || {}).length) {
        $this.$emit('selected')
        stopWatch()
      }
    })

    const sheetProps = computed(() => ({
      sheets: sheets.value,
      freeze: 1,
      session: {
        save: true,
        key: `1${/[kfg]/.test(quote.value.quote_status) ? 'booked' : 'pending'}`,
        saveCollapseGroups: false
      },
      selectedRowOptions: [
        {
          name: 'Add to library',
          icon: 'svg:catalogAddLight',
          action: (rows, { sheet }) => save({ rows, sheet }),
          multiple: true,
          single: true,
          if: ({ id: refId = null }) =>
            norm.value?.[refId] && !objectSaved(norm.value[refId]) && !isTask(norm.value[refId])
        },
        {
          name: 'Save changes to library',
          icon: 'svg:catalogSaveLight',
          action: (rows, { sheet }) => save({ rows, sheet }),
          multiple: true,
          single: true,
          if: ({ id: refId = null }) =>
            norm.value?.[refId] && objectSaved(norm.value[refId]) && !isTask(norm.value[refId])
        },
        {
          name: 'Save as new item',
          icon: 'svg:catalogAddLight',
          action: (rows, { sheet }) => save({ rows, sheet, asNew: true }),
          multiple: true,
          single: true,
          if: ({ id: refId = null }) =>
            norm.value?.[refId] && objectSaved(norm.value[refId]) && !isTask(norm.value[refId])
        },
        {
          name: 'Delete',
          icon: 'trash',
          action: (rows, { sheet }) => {
            sheet.deleteRows(rows)
            sheet.selectedRows.value = []
            sheet.gripRow.value = null
          },
          multiple: true,
          single: true
        },
        {
          name: 'Duplicate',
          icon: 'copy',
          action: (rows, { sheet }) => {
            sheet.duplicateRows(rows)
          },
          multiple: false,
          single: true
        },
        {
          name: 'Turn into assembly',
          icon: 'cubes',
          action: async (rows, { sheet }) => {
            sheet.turnIntoAssembly(rows)
            await c.throttle(() => {}, { delay: 400 })
            sheet.moveCell(0, rows[0])
            sheet.focusCell()
          },
          multiple: false,
          single: true,
          if: ({ id: refId = null }) => !isTask(norm.value[refId])
        },
        {
          name: 'Advanced item options...',
          icon: 'pencil',
          action: editItem,
          if: ({ id: refId = null }) => !isTask(norm.value[refId])
        }
      ]
    }))

    const sheetCollapsedSuperHeadings = computed(() => refSheet.value.collapsedSuperHeaders)

    const directSet = (field, value) =>
      setFields({
        [props.refId]: { [field]: value }
      })

    const quoteLaborHours = computed(() => quote.value.quote_total_hours)
    const quoteLaborCosts = computed(() => quote.value.quote_labor_cost_net)
    const quoteMaterialCosts = computed(() => quote.value.quote_materials_cost_net)
    const quoteSubCosts = computed(() => 0)
    const quoteTotalCosts = computed(() => quote.value.quote_total_cost_net)

    const quoteProfit = computed({
      get: () => c.toNum(quote.value.quote_profit_net, 4),
      set: (val) => directSet('quote_profit_net', val)
    })
    const profitPercentage = computed({
      get: () =>
        c.toNum(_.divide(quote.value.quote_profit_net, quote.value.quote_price_net) * 100, 4),
      // c.divide(quote.value.quote_price_net - quote.value.quote_total_cost_net,
      //   quote.value.quote_price_net) * 100,
      set: (val) => directSet('quote_profit_percentage', val / 100)
    })
    const quoteMarkup = computed({
      get: () => c.toNum(c.marginToMarkup(profitPercentage.value / 100), 4),
      set: (val) => {
        profitPercentage.value = c.markupToMargin(val) * 100
      }
    })
    const quoteNet = computed({
      get: () => quote.value.quote_price_net,
      set: (val) => directSet('quote_price_net', val)
    })
    const quoteGross = computed({
      get: () => quote.value.quote_price_gross,
      set: (val) => directSet('quote_price_gross', val)
    })
    const quoteTax = computed({
      get: () => quote.value.quote_price_tax,
      set: (val) => directSet('quote_price_tax', val)
    })
    const discountPercent = computed({
      get: () => c.toNum(quote.value.quote_discount_percentage, 4) * 100,
      set: (val) => directSet('quote_discount_percentage', val)
    })
    const quoteDiscount = computed({
      get: () => quote.value.quote_discount_net,
      set: (val) => directSet('quote_discount_net', val)
    })
    const targetMarkup = computed({
      get: () => c.toNum(quote.value.quote_markup_net, 4),
      set: (val) => directSet('quote_markup_net', val)
    })
    const targetMargin = computed({
      get: () => c.toNum(c.markupToMargin(quote.value.quote_markup_net), 4),
      set: (val) => directSet('targetMargin', val)
    })
    const quotePostal = computed({
      get: () => quote.value.quote_postal,
      set: (val) => directSet('quote_postal', val)
    })
    const quoteAdjustment = computed({
      get: () => quote.value.quote_markup_percentage_adjustment,
      set: (val) => directSet('quote_markup_percentage_adjustment', val)
    })
    const assemblyAdjustment = computed({
      get: () => quote.value.assembly_markup_percentage_adjustment,
      set: (val) => directSet('assembly_markup_percentage_adjustment', val)
    })

    const noticeCheckingOutOldVersion = computed(() => props.alternateChangeOrderId)

    const noticeHasPendingChangeOrder = computed(
      () => /[kf]/.test(quote.value.quote_status) && quote.value.change_order_time_booked
    )

    const noticeAutocostDisabled = computed(() => props.autoCostDisabledWarning)

    const noticeLivePricingPostal = computed(
      () => props.hasLivePricingEnabled && quote.value.quote_postal
    )

    const noticeLivePriceWarning = computed(() => props.showLivePriceWarning)

    const dimensionalAreas = computed(() => {
      const items = norm.value

      return Object.keys(items).filter(
        (refId) =>
          items[refId] &&
          (items[refId].type === 'assembly' || items[refId].type === 'quote') &&
          items[refId].asRequiredDimensions
      )
    })

    const noticeMissingDimensions = computed(() => {
      const rooms = dimensionalAreas.value

      return rooms.reduce((count, room) => {
        const dims = norm.value[room].oDimensions
        const roomCount = norm.value[room].asRequiredDimensions.filter(
          (abbr) => !dims[abbr] || !dims[abbr].value
        )
        return count + roomCount.length
      }, 0)
    })

    const noticeRequiresClientApproval = computed(() => {
      const p = quote.value.quote_price_net
      const all = $store.state[props.store].all
      const allq = all && all[String(quote.value.quote_id)]
      const allr = allq && props.refId && allq[props.refId]
      const pn = allr && allr.quote_price_net
      return !c.eq(p, pn)
    })

    const noticeRequiresManagerApproval = computed(
      () => quote.value.change_order_company_has_approved
    )
    const canApprove = computed(
      () =>
        noticeRequiresManagerApproval.value && $store.state.session.user.aUserPerms.quote.approve
    )

    const approveChanges = async () => {
      await $store.dispatch('ChangeOrder/markApprovedByCompany', {
        id: quote.value.change_order_id
      })
      refSheet.value.reinitialize()
    }

    const noticeMarginBelowDefault = computed(
      () => profitPercentage.value - c.markupToMargin($store.getters.defaultMarkup) * 100 <= -0.01
    )

    const noticeMarginBelowMinimum = computed(
      () =>
        profitPercentage.value - $store.state.session.company.company_minimum_quote_margin * 100 <=
        -0.01
    )

    const increaseProfitToMin = () => {
      targetMargin.value = +$store.state.session.company.company_minimum_quote_margin + 0.001
    }
    const increaseProfitToDef = () => {
      profitPercentage.value = c.markupToMargin($store.getters.defaultMarkup) * 100 + 0.001
    }

    const empty = computed(() => {
      return quote.value?.aoChildren?.length === 0
    })

    const {
      startGuide,
      endGuide,
      tooltipStyle,
      elementHighlightStyle,
      tooltipText,
      step,
      currentStep,
      prevStep,
      nextStep,
      completed: guideCompleted,
      guides,
      guide
    } = Guide.useGuide({
      setMeta,
      refSheet,
      setFields,
      $store,
      props
    })

    const { completeMilestone } = OnboardingMilestones.useOnboardingMilestones()

    watch(guideCompleted, () => {
      step.value = null
      completeMilestone({
        points: 20,
        id: `guide-${guide.value.id}`,
        name: guide.value.title,
        description: guide.value.desc
      })
    })

    const setStartingPoint = (option) => {
      if (option.id) {
        addItems(
          [
            {
              assembly_id: option.id,
              type: 'assembly'
            }
          ],
          0,
          false,
          false
        )
      }

      if (option.key === 'blank') {
        // add single item to the quote
        refSheet.value.addRow()
      }

      if (option.key === 'tutorial') {
        // add single item to the quote
        startGuide('Basics', 0)
      }
    }

    const chooseHandler = (option) => {
      setStartingPoint(option)
    }

    const refVars = ref(null)

    const varsFixTo = computed(() => {
      const ccalc = refSheet.value.refCalc
      if (refSheet.value.cellFocused && ccalc) {
        return ccalc.$el
      }

      if (refSheet.value.showActionDiv && refSheet.value.refActionDiv) {
        return refSheet.value.refActionDiv
      }

      return refSheet.value.refEditableDiv
    })

    // Get suggested dimensions
    const suggestedDimensions = ref([])
    const getSuggestedDimensions = async ({ object }) => {
      ;({ sorted: suggestedDimensions.value } = await $store.dispatch(
        'Dimension/getSuggestedDimensions',
        {
          object,
          itemName: object.cost_type_name,
          itemDesc: object.cost_type_desc,
          parentName:
            norm.value[object.parentRefId].assembly_name ||
            norm.value[object.parentRefId].quote_name
        }
      ))
    }

    const showDim = computed(() => {
      const [col] = selectedCell.value ?? [null]
      const hasVars = !!filteredColumns.value[col]?.variables
      const rightType = selectedType.value === 'cost_item'

      if (hasVars && rightType && selectedObj.value)
        getSuggestedDimensions({ object: selectedObj.value })

      return (
        !!(hasVars && rightType && sheetRows.value && sheets.value.length && refSheet.value) ||
        filteredColumns.value[col]?.field === 'dims'
      )
    })
    watch([showDim, selectedCell], () => {
      showAllDims.value = false
    })

    watch(
      () => refSheet.value.cellFocused,
      async () => {
        await $this.$nextTick()
        if (showDim.value) c.throttle(() => refVars.value?.open(), { delay: 200 })
      }
    )
    watch(selectedCell, async () => {
      await $this.$nextTick()
      if (showDim.value) c.throttle(() => refVars.value?.open(), { delay: 200 })
      // else refVars.value.close();
    })

    const tempDimsInEq = ref([])
    const eqCol = computed(
      () => +filteredColumns.value.findIndex((column) => column.field === 'qty')
    )
    const eqRow = computed(() => +refSheet.value?.currentCell[1])
    const dimsInEq = computed(() => {
      return getDimensionsInEquation(refSheet.value.getCellEquation(+eqCol.value, +eqRow.value))
    })

    const addDimensionHandler = (abbr) => {
      if (refSheet.value.cellFocused) {
        tempDimsInEq.value.push(abbr)
        refSheet.value.injectValue(abbr)
      } else {
        refSheet.value.setCellText(eqCol.value, eqRow.value, abbr)
      }
    }

    const clearDimensionHandler = () => {
      if (refSheet.value.cellFocused) {
        refSheet.value.forceValue('')
      } else {
        refSheet.value.setCellText(eqCol.value, eqRow.value, '')
      }
    }

    const showAllDims = ref(false)

    const taxId = computed({
      get: () => quote.value.tax_id,
      set: (val) => directSet('tax_id', val)
    })
    const taxName = computed({
      get: () => quote.value.tax_name,
      set: (val) => directSet('tax_name', val)
    })
    const provinceId = computed({
      get: () => quote.value.province_id,
      set: (val) => directSet('province_id', val)
    })

    const emptyTaxSet = ref(null)
    const taxIsEmpty = () => {
      emptyTaxSet.value = true
    }

    const taxIsNotEmpty = () => {
      emptyTaxSet.value = false
    }
    const createSalesTax = async () => {
      const tax = await $store.dispatch('create', {
        type: 'tax',
        embue: {
          province_id: provinceId.value || $store.state.session.company.province_id
        }
      })

      this.taxId = tax.tax_id

      return this
    }

    const summaryTabPassthru = computed(() => ({
      content: ['px-4']
    }))
    const tabViewPassthru = computed(() => ({
      navContainer: {
        class: ['!mb-0']
      },
      nav: {
        class: [
          // Flexbox
          'flex flex-1',

          // Spacing
          'list-none',
          'p-0 m-0',

          // Colors
          'bg-surface-0 dark:bg-surface-800',
          'border-b-2 border-surface-200 dark:border-surface-700',
          'text-surface-900 dark:text-surface-0/80'
        ]
      },
      tabPanel: {
        headerAction: ({ parent, context }) => ({
          class: [
            'relative',

            // Font
            'font-medium',

            // Flexbox and Alignment
            'flex items-center',

            // Spacing
            'p-2',
            '-mb-[2px]',

            // Shape
            'border-b-2',
            'rounded-t-md',

            // Colors and Conditions
            'bg-surface-0 dark:bg-surface-800',
            {
              'border-surface-200 dark:border-surface-700':
                parent.state.d_activeIndex !== context.index,
              'text-surface-500 dark:text-surface-0/80':
                parent.state.d_activeIndex !== context.index,

              'border-primary-500 dark:border-primary-400':
                parent.state.d_activeIndex === context.index,
              'text-surface-800 dark:text-primary-400': parent.state.d_activeIndex === context.index
            },

            // States
            'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset',
            'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
            {
              'hover:bg-surface-0 dark:hover:bg-surface-800/80':
                parent.state.d_activeIndex !== context.index,
              'hover:border-surface-400 dark:hover:border-primary-400':
                parent.state.d_activeIndex !== context.index,
              'hover:text-surface-900 dark:hover:text-surface-0':
                parent.state.d_activeIndex !== context.index
            },

            // Transitions
            'transition-all duration-200',

            // Misc
            'cursor-pointer select-none text-decoration-none',
            'overflow-hidden',
            'user-select-none'
          ]
        })
      }
    }))

    const showSidebar = ref(true)
    watch(sidepanelTabIndex, (tv, before) => {
      if (tv === 0) {
        showSidebar.value = false
        sidepanelTabIndex.value = before
      }
    })

    const hideLivePriceWarning = ref(false)

    const toggleLivePriceWarning = () => {
      hideLivePriceWarning.value = !hideLivePriceWarning.value
    }

    const toggleAllCostData = () => {
      refSheet.value.handleClickSuperHeading({
        force: true,
        element: {
          superHeadingIndex: 0,
          sheetIndex: 0
        }
      })
    }

    useAssignees({
      store: props.store,
      refId: props.refId,
      loadAssigneesOnMount: true,
      // Will save through the fieldSetter for assignee_ids instead
      saveAssigneesOnChange: false
    })

    const getPublicCategories = async () => {
      const categories = await AutoCost.getAutoCostCategories($store, 'autocost', false)
      publicCategories.value = categories.map((category) => {
        return {
          text: category.cost_type_name,
          value: category.cost_type_id
        }
      })
    }

    const showTutorials = ref(false)

    return {
      guides,
      showSidebar,
      showTutorials,

      taxIsEmpty,
      taxIsNotEmpty,
      taxName,
      provinceId,
      emptyTaxSet,
      createSalesTax,

      sidepanelTabIndex,
      addDimensionHandler,
      suggestion,
      goToRoom,

      showItemSelectorDropdown,
      itemSelectorDropdownStyle,
      dropOptions,
      handleItemDropOption,
      toggleLivePriceWarning,
      toggleAllCostData,
      hideLivePriceWarning,

      handleCostItemClose,
      editingRefId,
      editingItemType,

      tooltipStyle,
      elementHighlightStyle,
      tooltipText,
      startGuide,
      endGuide,
      step,
      currentStep,
      prevStep,
      nextStep,
      sheetCollapsedSuperHeadings,

      totalProgress,
      chooseHandler,
      setStartingPoint,
      editingReference,
      refItemEditor,
      props,
      sheetProps,
      sheetRows,
      sheets,
      refSheet,
      diff,
      quoteNet,
      quoteTax,
      quoteGross,

      quoteLaborHours,
      quoteLaborCosts,
      quoteMaterialCosts,
      quoteSubCosts,
      quoteTotalCosts,
      quoteMarkup,
      quoteProfit,
      minimumMarkup,
      defaultMarkup,
      editItem,
      quote,
      norm,
      quoteDiscount,

      targetMarkup,
      targetMargin,
      profitPercentage,
      discountPercent,
      taxId,
      auditProgress,

      noticeCheckingOutOldVersion,
      noticeMarginBelowDefault,
      noticeMarginBelowMinimum,
      noticeHasPendingChangeOrder,
      noticeMissingDimensions,
      noticeAutocostDisabled,
      noticeLivePricingPostal,
      noticeLivePriceWarning,

      noticeRequiresClientApproval,
      noticeRequiresManagerApproval,

      quotePostal,
      canApprove,
      approveChanges,
      increaseProfitToMin,
      increaseProfitToDef,
      empty,

      assemblyAdjustment,
      quoteAdjustment,
      searchPhrase,
      cellTypingHandler,

      refVars,
      showDim,
      showAllDims,
      dimsInEq,
      varsFixTo,

      selectedRow,
      selectedId,
      selectedParentId,
      selectedParentName,
      selectedParentDims,
      selectedName,

      $store,
      tabViewPassthru,
      summaryTabPassthru,
      clearDimensionHandler,
      suggestedDimensions,
      refAssemblyAdded,

      publicCategories,
      inCompanyScope
    }
  }
}
</script>

<template>
  <div class="absolute inset-0">
    <div id="estimating-sheet-container" v-show="!empty">
      <div
        v-show="noticeLivePriceWarning && !hideLivePriceWarning"
        id="estimate-autocost-banner"
        class="flex flex-row items-center justify-between w-full p-4 bg-matcha-100 border-b border-matcha-500/30"
      >
        <div class="flex flex-row items-center gap-2">
          <icon
            icon="svg:autocostWhite"
            class="bg-matcha-500 rounded-full aspect-square h-6 p-0.5 border border-surface-0"
          />
          <p>AutoCost items may need to be updated.</p>
          <Btn severity="tertiary" class="ml-2" @click="() => $emit('updateAutocost')">
            Review changes
          </Btn>
        </div>
        <a
          class="flex items-center cursor-pointer hover:text-surface-500 transition"
          @click="() => toggleLivePriceWarning()"
        >
          <Icon icon="xmark" class="text-xl" />
        </a>
      </div>

      <Sheets
        v-if="sheetRows && sheets.length"
        ref="refSheet"
        v-bind="sheetProps"
        @cellTyping="cellTypingHandler"
      >
        <template #sheetOption>
          <btn
            size="xsmall"
            v-show="sheetCollapsedSuperHeadings?.[0]?.['0']"
            @click="toggleAllCostData"
          >
            <div class="flex flex-row items-center gap-2">
              <icon
                :icon="['fas', 'triangle-exclamation']"
                class="text-yellow-300 rounded-full aspect-square h-6 p-0.5"
              />
              <p>Cost data is hidden, click to enable.</p>
            </div>
          </btn>
        </template>
        <template #after>
          <div class="py-2 flex flex-col gap-6 items-start justify-start">
            <div class="flex justify-start items-stretch gap-2">
              <Btn
                data-id="addItemButton"
                v-if="refSheet"
                :action="() => refSheet?.handleAddClick(null)"
                unstyled
                size="sm"
                class="min-w-[290px] opacity-80 hover:opacity-100 bg-surface-200 text-surface-500 rounded-md hover:bg-surface-200 hover:text-surface-900 text-sm py-2 font-medium"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
                Add item
              </Btn>

              <Btn
                v-if="refSheet"
                :action="() => (showTutorials = !showTutorials)"
                unstyled
                class="bg-purple-100 text-purple-600 rounded-md px-3 py-2 text-sm font-medium flex gap-2 justify-start items-center"
                size="sm"
              >
                <font-awesome-icon icon="sparkles" />
                Help me

                <font-awesome-icon icon="chevron-down" v-if="!showTutorials" fixed-width />
                <font-awesome-icon icon="close" v-if="showTutorials" fixed-width />
              </Btn>
            </div>

            <div class="flex flex-col justify-start items-start gap-6 mt-4" v-if="showTutorials">
              <div class="flex flex-col gap-1">
                <div class="leading-none text-xl font-medium">
                  Run guided mini-tutorials to learn Bolster basics
                </div>
                <div class="leading-tight text-surface-600">
                  Once you get the hang of it, Bolster is the easiest part of your day. Until then,
                  these short guided tutorials will get you level and plum.
                </div>
              </div>
              <div class="flex flex-col justify-start items-start gap-1">
                <template v-for="guide in guides" :key="guide.title">
                  <Btn
                    v-if="refSheet"
                    :tooltip="guide.desc"
                    :action="
                      () => {
                        startGuide(guide.id)
                      }
                    "
                    unstyled
                    :class="[
                      {
                        'opacity-50': guide.done
                      },
                      'bg-purple-100 text-purple-600 rounded-md px-3 py-2 text-sm font-medium flex gap-2 justify-start items-center'
                    ]"
                    size="sm"
                  >
                    <font-awesome-icon icon="check" fixed-width v-if="guide.done" />
                    <font-awesome-icon :icon="guide.icon" fixed-width v-else />
                    {{ guide.title }} - {{ guide.time }}
                    <font-awesome-icon icon="play" fixed-width />
                  </Btn>
                </template>
              </div>
            </div>
          </div>
        </template>

        <template #calculator v-if="showDim">
          <Btn unstyled class="text-white" @click="refVars.toggle()">
            <font-awesome-icon icon="ruler-triangle" size="sm" />
          </Btn>
        </template>
      </Sheets>

      <Drop
        :key="refSheet.showMultiline"
        ref="refVars"
        v-if="showDim"
        :fix-to="varsFixTo"
        :auto-z-index="false"
        unstyled
        containerClass="bg-surface-100 rounded-sm shadow-md after:border-b-transparent translate-y-1 flex flex-col gap-2"
      >
        <div class="flex flex-col gap-4">
          <div
            class="text-xs gap-2 flex justify-center items-center text-surface-700 bg-surface-200/50 px-2 py-2 rounded-sm font-medium leading-none whitesspace-nowrap select-none"
            v-tooltip="
              'Choose a dimension below to link this quantity to. Whenever that dimension changes, this quantity will too -- automatically.'
            "
          >
            <font-awesome-icon icon="ruler-triangle" />
            Link your quantity to a takeoff dimension
          </div>

          <div class="flex flex-col gap-4 justify-start max-w-[500px] p-2">
            <div
              class="flex flex-col gap-2 justify-start"
              v-if="Object.keys(refSheet.calcVariables).length"
            >
              <div
                class="text-xs text-left leading-none text-surface-800 font-medium flex justify-start items-center gap-1"
              >
                <font-awesome-icon icon="ruler-triangle" /> Available dimensions
              </div>
              <div class="flex gap-2 items-stretch flex-wrap">
                <template v-for="(dim, abbr) in refSheet.calcVariables" :key="abbr">
                  <Btn
                    :action="() => addDimensionHandler(abbr)"
                    v-tooltip="refSheet.calcVariables[abbr].name"
                    :link="!dimsInEq.includes(abbr)"
                    size="xs"
                    :style="
                      dimsInEq.includes(abbr)
                        ? `background-color: #${dim.color};`
                        : `border: 1px solid #${dim.color};`
                    "
                    :class="[
                      'shrink-0',
                      {
                        'border border-b-2 !text-surface-500': !dimsInEq.includes(dim.abbr),
                        'border-0 !text-white': dimsInEq.includes(dim.abbr)
                      },
                      'min-h-8'
                    ]"
                  >
                    <span class="font-medium font-mono">{{ abbr }}</span>
                    <span class="font-medium mr-0.5"
                      >{{ dim.measure === 'ft' ? $f.imperial(dim.value) : $f.number(dim.value) }}
                      {{ dim.measure }}</span
                    >
                  </Btn>
                </template>
              </div>
            </div>

            <div class="flex flex-col gap-2 justify-start" v-if="suggestedDimensions.length">
              <div
                class="text-xs text-left leading-none text-purple-500 font-medium flex justify-start items-center gap-1"
              >
                <font-awesome-icon icon="sparkles" /> Recommended dimensions
              </div>
              <div class="flex flex-row justify-start items-stretch gap-2">
                <template v-for="dim in suggestedDimensions.slice(0, 3)" :key="dim.abbr">
                  <Btn
                    :action="() => addDimensionHandler(dim.abbr)"
                    v-tooltip="dim.name"
                    :link="!dimsInEq.includes(dim.abbr)"
                    size="xs"
                    :style="
                      dimsInEq.includes(dim.abbr)
                        ? `background-color: #${dim.color};`
                        : `border: 1px solid #${dim.color}; border-bottom: 2px solid #${dim.color};`
                    "
                    :class="[
                      'shrink-0',
                      {
                        'border border-b-2 !text-surface-500': !dimsInEq.includes(dim.abbr),
                        'border-0 !text-white': dimsInEq.includes(dim.abbr)
                      },
                      'min-h-8'
                    ]"
                  >
                    <div class="flex justify-between items-center gap-1">
                      <div
                        class="basis-60 font-medium text-xs flex justify-start items-center font-sans text-left whitespace-pre-wrap break-spaces text-ellipsis max-w-24 leading-none shrink-1"
                      >
                        {{ dim.name.replace(' ', '\r\n') }}
                      </div>
                      <span class="font-medium mr-0.5"
                        >{{ dim.measure === 'ft' ? $f.imperial(dim.value) : $f.number(dim.value) }}
                        {{ dim.measure }}</span
                      >
                    </div>
                  </Btn>
                </template>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center" v-if="dimsInEq.length">
            <Btn link :action="() => clearDimensionHandler()" size="sm"> Unlink </Btn>
            <Btn link :action="() => goToRoom(selectedId)" size="sm"> Set dimensions </Btn>
          </div>

          <Btn link :action="() => (showAllDims = !showAllDims)" size="sm">
            {{ showAllDims ? 'Hide others...' : 'More...' }}
          </Btn>

          <div class="border-t border-surface-200 pt-4" v-if="showAllDims">
            <DimensionSelector
              :object="{}"
              :parentDimensions="refSheet.calcVariables"
              :value="refSheet.currentCellValue"
              @input="(abbr) => addDimensionHandler(abbr)"
            />
          </div>
        </div>
      </Drop>

      <!-- tooltip guides -->
      <div
        :style="[elementHighlightStyle]"
        :class="['pointer-events-none ring-4 ring-purple-500/40 rounded-sm animate-ping']"
      ></div>
      <div
        ref="refTooltip"
        :style="tooltipStyle"
        :class="[
          'rounded-md text-sm font-medium bg-purple-200 text-purple-950 px-4 py-2 flex gap-4 items-center flex-row flex-nowrap z-[10000]',
          'shadow-md',
          {
            'before:absolute before:-top-4 before:border-8 before:border-transparent before:border-b-purple-200 before:w-4 before:pointer-events-none before:h-4':
              currentStep?.position === 'bottom' || !currentStep?.position,
            'before:absolute before:-left-4 before:border-8 before:border-transparent before:border-r-purple-200 before:pointer-events-none before:w-4':
              currentStep?.position === 'right',
            'before:absolute before:-bottom-4 before:border-8 before:border-transparent before:border-t-purple-200 before:w-4 before:pointer-events-none before:h-4':
              currentStep?.position === 'top',
            'before:absolute before:-right-4 before:border-8 before:border-transparent before:border-l-purple-200 before:w-4 before:pointer-events-none before:h-4':
              currentStep?.position === 'left'
          }
        ]"
      >
        <div class="max-w-fit animate-bounce">
          <font-awesome-icon :icon="currentStep?.icon ?? 'sparkles'" size="xl" />
        </div>
        <div v-if="!totalProgress">
          <IconText :text="tooltipText" />
        </div>
        <div v-else>
          <LoadingIndicator :loading="1" class="mix-blend-multiply" />
        </div>
        <div class="absolute -bottom-6 h-5 right-1 flex">
          <Btn :action="endGuide" hideText link size="xsmall" class="text-purple-950 px-2">
            <template #icon>
              <font-awesome-icon icon="times" />
            </template>
          </Btn>
          <Btn
            :action="prevStep"
            hideText
            link
            size="xsmall"
            class="text-purple-950 px-2"
            :disabled="step === 0"
          >
            <template #icon>
              <font-awesome-icon icon="arrow-left" />
            </template>
          </Btn>

          <Btn
            severity="tertiary"
            :action="nextStep"
            :class="[
              'ml-auto',
              {
                '!text-purple-900 !border-purple-400  !bg-purple-400':
                  !currentStep?.triggerNext || currentStep?.triggerNext === 'commit',
                '!text-deep-red-800 !bg-deep-red-200 !border-transparent !opacity-100':
                  currentStep?.triggerNext && currentStep?.triggerNext !== 'commit'
              }
            ]"
            size="md"
            :disabled="currentStep?.triggerNext && currentStep?.triggerNext !== 'commit'"
          >
            {{
              currentStep?.triggerNext && currentStep?.triggerNext !== 'commit'
                ? 'complete action to continue'
                : 'next'
            }}
            <font-awesome-icon
              icon="arrow-right"
              v-if="!(currentStep?.triggerNext && currentStep?.triggerNext !== 'commit')"
          /></Btn>
          <!--          <Btn :action="nextStep" link size="xsmall" class="text-purple-950 px-2">-->
          <!--            <font-awesome-icon icon="arrow-right" />-->
          <!--          </Btn>-->
        </div>
      </div>

      <!-- cost item selector -->
      <div
        class="item-name-drop !w-fit !min-w-80 h-fit rounded-md bg-white shadow-md flex flex-col justify-stretch p-1 overflow-y-auto overflow-x-hidden"
        :style="itemSelectorDropdownStyle"
        style="{ display: flex }"
      >
        <template v-for="(option, index) in dropOptions" :key="index">
          <div v-if="option.divider" class="h-[1px] border-t border-t-cool-gray-200"></div>
          <div
            v-else
            @mousedown="(e) => handleItemDropOption(option, e)"
            class="w-full h-16 flex flex-row justify-stretch items-stretch gap-2 hover:bg-cool-gray-100 rounded-md p-2 cursor-pointer relative"
          >
            <div
              class="size-10 basis-10 shrink-0 grow-0 items-center flex justify-center border border-cool-gray-200 bg-flame-white rounded-sm relative"
            >
              <Icon :icon="option.icon" size="lg" v-if="!option.image" />
              <img :src="option.image" class="inset absolute" alt="" />
            </div>
            <div class="flex flex-col grow-1 justify-center align-start">
              <div class="font-medium text-sm flex justify-start items-center content-center">
                {{ option.label }}
                <Hotkey
                  :hotkey="option.hotkey"
                  class="scale-[0.7] -mt-1 -mb-1"
                  v-if="option.hotkey && itemSelectorDropdownStyle.display !== 'none'"
                  @hotkey-pressed="handleItemDropOption(option)"
                />
                <Tag
                  class="absolute top-3 right-2 opacity-0.5"
                  :value="option.badge"
                  :severity="option.badgeSeverity ?? 'bolster'"
                  v-if="option.badge"
                />
              </div>
              <div class="text-xs"><IconText :text="option.desc" /></div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <ScrollPanel v-show="empty" class="absolute inset-0">
      <Page
        :title="props.store === 'Quote' ? 'Build a new estimate' : 'Build a new assembly'"
        :icon="props.store === 'Quote' ? 'file-signature' : 'blocks'"
      >
        <EmptyQuote :refId="props.refId" :store="props.store" @choose="chooseHandler"></EmptyQuote>
      </Page>
    </ScrollPanel>

    <Loader :loading="totalProgress > 0 && totalProgress < 100 ? 1 : 0" :progress="totalProgress" />

    <MiniModal size="sm" :width="500" scrollable ref="refItemEditor">
      <template #header>
        <CostItemHeader
          v-if="editingRefId"
          :type="editingItemType"
          :store="store"
          :refId="editingRefId"
          :key="editingRefId"
        />
      </template>
      <CostItem
        v-if="editingRefId && editingItemType !== 'assembly'"
        @close="handleCostItemClose"
        :store="store"
        :refId="editingRefId"
        :key="editingRefId"
        :show-title="false"
      />
      <AssemblyBody
        v-else-if="editingRefId && editingItemType === 'assembly'"
        :store="store"
        :refId="editingRefId"
        :key="editingRefId"
        ref="assemblyEditor"
        :show-title="false"
        :show-info="false"
        :show-contents="false"
      />
    </MiniModal>

    <AssemblyAdded
      v-if="false"
      ref="refAssemblyAdded"
      :store="store"
      refId="as_p1VV6UpK_YsBO6xkWQ4A"
      :key="editingRefId"
    />

    <div v-if="!showSidebar" class="absolute top-1 right-4">
      <Btn outline severity="tertiary" size="lg" :action="() => (showSidebar = true)">
        <font-awesome-icon icon="bars" />
      </Btn>
    </div>
  </div>
</template>

<style scoped lang="scss" rel="stylesheet/scss">
$bolsterBlue: #0b4bff;
$bolsterWarning: #fdecbc;
$bolsterRed: #ffc6cd;
$concrete: #e6e3e0;

#estimating-sheet-container {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  right: auto;
  z-index: 0;
  height: 100%;
}
//
//#estimating-sidebar {
//  $py: 20px;
//  $px: 20px;
//  position: absolute;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  left: auto;
//  z-index: $z-layout;
//  background: lighten($cement-50, 11%);
//  padding: $py $px;
//
//  .tinyprogressbar {
//    width: 100%;
//    height: 2px;
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    padding: 0;
//    margin: 0;
//    z-index: $z-layout;
//
//    .bar {
//      height: 2px;
//      background: $bolsterBlue;
//      width: 0;
//      padding: 0;
//      margin: 0;
//      transition: width 0.1s ease-in;
//    }
//  }
//
//  .notice {
//    position: relative;
//    top: -$py;
//    left: -$px;
//    right: -$px;
//    width: calc(100% + #{$px * 2});
//
//    padding: calc($py / 2) $px;
//
//    font-size: 0.9em;
//
//    font-weight: 500;
//
//    display: flex;
//    align-items: center;
//    justify-content: flex-start;
//
//    &.warning {
//      background: $bolsterWarning;
//      color: $cool-gray-700;
//    }
//
//    &.danger {
//      background: $bolsterRed;
//      color: $pitch-black;
//    }
//
//    &.info {
//      background: $bolsterBlue;
//      color: $flame-white;
//    }
//
//    &.info {
//      background: $bolsterBlue;
//      color: $flame-white;
//    }
//
//    &.active {
//      cursor: pointer;
//      &:hover {
//        opacity: 0.9;
//      }
//    }
//  }
//
//  .es-row {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    flex-wrap: nowrap;
//    white-space: nowrap;
//    height: 2.8em;
//    margin-top: 0.1em;
//    margin-bottom: 0.1em;
//
//    >span {
//      margin-left: -0.25em;
//      margin-right: -0.25em;
//      padding-left: 0.25em;
//      padding-right: 0.25em;
//      overflow-x: hidden;
//      max-width: 100%;
//    }
//
//    >:last-child {
//      font-weight: bold;
//    }
//
//    span.label {
//      flex: 1 50%;
//      font-weight: 500;
//      font-size: 1em;
//      display: block;
//      color: $cool-gray-700;
//      padding-top: 0.1em;
//      padding-bottom: 0.1em;
//      display: flex;
//      justify-content: center;
//      flex-direction: column;
//      margin-top: 0;
//      margin-bottom: 0;
//
//      a[href] {
//        font-size: 0.8em;
//        color: $bolsterBlue;
//      }
//
//      &.strong {
//        font-weight: 500;
//        font-size: 1.05em;
//      }
//    }
//    span.middle {
//      flex: 1 20%;
//      display: flex;
//      font-weight: normal;
//      font-size: 0.9em;
//      flex-wrap: nowrap;
//      white-space: nowrap;
//      align-items: center;
//      justify-content: center;
//    }
//    span.value {
//      flex: 1 30%;
//      font-weight: bold;
//      text-align: right;
//      display: flex;
//      justify-content: flex-end;
//    }
//  }
//
//  span.divider {
//    width: 100%;
//    height: 1px;
//    border-bottom: 1px solid $cool-gray-200;
//    display: block;
//    margin: 0em 0;
//  }
//
//  .heading {
//    font-weight: bold;
//    font-size: 1.2em;
//    display: block;
//    margin-bottom: 0.25em;
//  }
//}
</style>
