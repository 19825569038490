import CostType from '../../../../imports/api/schemas/CostType'

const { inputRequiredDefaulter } = CostType

export default {
  computed: {
    inputRequired: {
      get() {
        return this.getField('oInputRequired')
      },
      set(input) {
        this.setField('oInputRequired', input)
      }
    },
    ir() {
      return (this.inputRequired && this.inputRequired.inputs) || []
    },
    textInputCheckbox: {
      get() {
        if (this.indexFor('txt') > -1) return 1

        return 0
      },
      set(b) {
        if (b) {
          this.addInput('txt', 'text')
        } else {
          this.removeInput('txt')
        }
      }
    },
    colorInputCheckbox: {
      get() {
        if (this.indexFor('clr') > -1) return 1

        return 0
      },
      set(b) {
        if (b) {
          this.addInput('clr', 'color')
        } else {
          this.removeInput('clr')
        }
      }
    },
    textInput: {
      get() {
        return this.ir.find((inp) => inp.id === 'txt')
      },
      set(obj) {
        let index = this.indexFor('txt')

        if (index < -1) {
          index = this.addInput()
        }

        const inputs = _.imm(this.inputRequired.inputs)
        inputs[index] = obj
        this.inputRequired = inputRequiredDefaulter(
          _.imm({
            ...this.inputRequired,
            inputs
          })
        )
      }
    },
    textInputRequired: {
      get() {
        return this.textInput.required
      },
      set(required) {
        this.textInput = {
          ...this.textInput,
          required
        }
      }
    },
    textInputMessage: {
      get() {
        return this.textInput.message
      },
      set(message) {
        this.textInput = {
          ...this.textInput,
          message
        }
      }
    },
    textInputValue: {
      get() {
        return this.textInput.input.text
      },
      set(text) {
        this.textInput = {
          ...(this.textInput || {}),
          input: {
            ...((this.textInput && this.textInput.input) || {}),
            text,
            setBy: 'company'
          }
        }
      }
    },

    colorInputRequired: {
      get() {
        return this.colorInput.required
      },
      set(required) {
        this.colorInput = {
          ...this.colorInput,
          required
        }
      }
    },
    colorInputMessage: {
      get() {
        return this.colorInput.message
      },
      set(message) {
        this.colorInput = {
          ...this.colorInput,
          message
        }
      }
    },
    colorInput: {
      get() {
        return this.ir.find((inp) => inp.id === 'clr')
      },
      set(obj) {
        let index = this.indexFor('clr')

        if (index < 0) {
          index = this.addInput('clr', 'color')
        }

        const inputs = _.imm(this.inputRequired.inputs)
        inputs[index] = obj
        const newInput = inputRequiredDefaulter(
          _.imm({
            ...this.inputRequired,
            inputs
          })
        )
        this.inputRequired = newInput
      }
    },
    colorInputData: {
      get() {
        return this.colorInput.input.data || {}
      },
      set(data) {
        this.colorInput = {
          ...(this.colorInput || {}),
          input: {
            ...((this.colorInput && this.colorInput.input) || {}),
            data,
            setBy: 'company'
          }
        }
      }
    }
  },
  methods: {
    toggleInput(id = 'txt', type = 'text') {
      if (this.inputExists(id)) {
        return this.removeInput(id)
      }

      return this.addInput(id, type)
    },
    inputExists(id = 'txt') {
      return !!this.ir.find((inp) => inp.id === id)
    },
    indexFor(id = 'txt') {
      const ir = _.imm(this.ir)
      const found = ir.find((inp) => inp.id === id)
      return ir.indexOf(found)
    },
    removeInput(id = 'txt') {
      const ir = _.imm(this.ir)

      ir.splice(this.indexFor(id), 1)

      this.inputRequired = { inputs: ir }
    },
    addInput(id = 'txt', type = 'text') {
      const index = this.indexFor(id)

      if (index > -1) return index

      const defaulted = inputRequiredDefaulter({
        ...this.inputRequired,
        inputs: [
          ...this.ir,
          {
            type,
            id
          }
        ]
      })

      this.inputRequired = defaulted

      return this.indexFor(id)
    }
  }
}
