<template>
  <div class="performance" :class="{ 'icon-only': iconOnly }">
    <div class="icon">
      <font-awesome-icon :icon="icon" />
    </div>
    <div class="text" v-text="text" v-if="!iconOnly"></div>
  </div>
</template>

<script>
export default {
  name: 'Insight',
  data() {
    return {}
  },
  watch: {},
  computed: {
    icon() {
      let icon
      switch (this.performance) {
        case 'poor':
          icon = 'face-frown-open'
          break
        case 'good':
          icon = 'face-meh'
          break
        case 'stellar':
          icon = ['fas', 'star']
          break
        default:
          icon = 'thumbs-up'
          break
      }
      return icon
    },
    text() {
      let text
      switch (this.performance) {
        case 'poor':
          text = this.poorText
          break
        case 'good':
          text = this.goodText
          break
        case 'stellar':
          text = this.stellarText
          break
        default:
          text = this.averageText
          break
      }
      return text
    }
  },
  methods: {},
  components: {},
  props: {
    performance: {
      type: String,
      required: true
    },
    iconOnly: {
      default: false
    },
    poorText: {
      type: String,
      default: 'You have some room for improvement!'
    },
    averageText: {
      type: String,
      default: 'You are just about average!'
    },
    goodText: {
      type: String,
      default: 'Well done! Above average.'
    },
    stellarText: {
      type: String,
      default: 'So far so good! You are in the top performers.'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.performance {
  line-height: 1;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 0.8em;
  .icon {
    font-size: 2em;
  }
  :not(.icon-only) {
    .icon {
      margin-right: 0.25em;
    }
  }
  .text {
    font-size: 1em;
    line-height: 1.05;
  }
}
</style>
