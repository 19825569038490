<template>
  <Choose
    v-model="model"
    @close="onAssign"
    :multiple="true"
    :return-array="true"
    class="w-full"
    schema="assignee:assignee_id"
  >
    <AvatarGroup
      v-if="!asField || (asField && value.length > 0)"
      class="flex min-h-10 items-center h-full max-auto w-[50px]"
      :class="[center || value.length <= 1 ? 'justify-center' : 'ml-6']"
    >
      <Avatar
        :size="size"
        shape="circle"
        :pt="{
          root: `!bg-white cursor-pointer ${size === 'small' ? '!h-7 !w-7' : ''} !border hover:border-pitch-black border-cool-gray-300 !text-cool-gray-800 !mx-auto`
        }"
        :ptOptions="{ mergeProps: true }"
        v-if="!asField && value.length === 0"
      >
        <template #icon>
          <font-awesome-icon class="text-cool-gray-400" :icon="['fal', 'user']" />
        </template>
      </Avatar>
      <Avatar
        v-for="assignee in value"
        :key="assignee.id"
        :label="getInitials(assignee.user_name || assignee.vendor_name || assignee.name)"
        :size="size"
        shape="circle"
        :pt="{
          root: `!bg-white cursor-pointer ${size === 'small' ? '!h-7 !w-7' : ''} !border hover:border-pitch-black border-cool-gray-300 !text-cool-gray-800 ${value.length === 1 ? '!mx-auto' : ''}`
        }"
        :ptOptions="{ mergeProps: true }"
      />
      <Avatar
        v-if="value.length > 3"
        :label="`+${value.length - 3}`"
        shape="circle"
        :size="size"
        :pt="{ root: '!bg-pitch-black !h-7 !w-7 !text-white cursor-pointer' }"
        :ptOptions="{ mergeProps: true }"
      />
    </AvatarGroup>

    <Btn
      :pt="{ root: '!border-cool-gray-500' }"
      v-if="asField && value.length === 0"
      class="w-full lg:min-w-48 h-12"
      :class="btnClass"
      severity="tertiary"
    >
      <span class="text-base flex justify-between w-full items-center">
        {{ placeholder }}
        <font-awesome-icon class="ml-1" :icon="['far', 'chevron-down']" />
      </span>
    </Btn>
  </Choose>
</template>

<script setup>
import { defineProps, computed, toRef } from 'vue'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import useAssignees from '@/components/composables/Assignees'

const emit = defineEmits(['input', 'assign'])

const props = defineProps({
  value: {
    type: Array,
    default: () => []
  },
  asField: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'normal',
    validator(value) {
      return ['small', 'normal', 'large', 'xlarge'].includes(value)
    }
  },
  btnClass: {
    type: String
  },
  center: {
    type: Boolean,
    default: true
  },
  onAssign: {
    type: Function
  },
  placeholder: {
    type: String,
    default: 'Assign task'
  },
  format: {
    type: Boolean,
    default: true
  }
})

const { mapSelectedAssigneeToAssignees, formatAssigneesForChoose } = useAssignees()

const value = toRef(props, 'value')

const model = computed({
  get: () => (props.format ? formatAssigneesForChoose(value.value) : value.value),
  set: (value) => emit('input', value)
})

const onAssign = (assignees) => {
  // no changes have been made
  // if (assignees.length === value.value.length) return
  const assigneesToAssign = mapSelectedAssigneeToAssignees(assignees)
  emit('assign', assigneesToAssign)
  if (props.onAssign) props.onAssign(assigneesToAssign)
}

const getInitials = (name = 'No Name') => {
  const words = name.split(' ')
  const initials = words.map((word) => word.charAt(0).toUpperCase())
  return initials.join('')
}
</script>
