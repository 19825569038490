import { onMounted, ref, computed, onUnmounted } from 'vue'

const breakpoint = ref(null)

export default function useBreakpoint() {
  /* global $tt:readonly */
  const screens = ref($tt.screens || {})

  const sanitizeWidth = (s) => parseInt(s, 10)

  const breakpoints =
    computed(() =>
      Object.keys(screens.value).reduce((acc, bpoint) => {
        const screen = screens.value[bpoint]
        acc[bpoint] = sanitizeWidth(screen)
        return acc
      }, {})
    ) || {}

  // checks if the value is greater than or equal to the specified breakpoint.
  const isOverOrEqual = (value, size) => value >= breakpoints.value[size]

  // checks if the value is strictly greater than the specified breakpoint.
  const isOver = (value, size) => value > breakpoints.value[size]

  // checks if the value is less than or equal to the specified breakpoint.
  const isUnderOrEqual = (value, size) => value <= breakpoints.value[size]

  // checks if the value is strictly less than the specified breakpoint.
  const isUnder = (value, size) => value < breakpoints.value[size]

  // checks if the value falls within the range specified by the breakpoint
  const isBetween = (value, size) => {
    const bpoint = breakpoints.value[size]
    const nextSize = Object.keys(breakpoints.value).find((key) => breakpoints.value[key] > bpoint)
    const nextBreakpoint = breakpoints.value[nextSize]
    return value >= bpoint && value < nextBreakpoint
  }

  const getBreakpoint = (value) => {
    for (const size in breakpoints.value) {
      if (value < breakpoints.value[size]) return size
    }
    // return the name of the largest breakpoint
    return Object.keys(breakpoints.value).pop()
  }

  const resize = () => {
    c.throttle(
      () => {
        const width = window.innerWidth
        breakpoint.value = getBreakpoint(width)
      },
      { delay: 300 }
    )
  }

  const isMobile = computed(() => ['sm'].includes(breakpoint.value))

  const isTablet = computed(() => ['md', 'lg'].includes(breakpoint.value))

  const isDesktop = computed(() => ['xl', '2xl', 'h-3xl', 'h-4xl'].includes(breakpoint.value))

  const isWideScreen = computed(() => ['h-3xl', 'h-4xl'].includes(breakpoint.value))

  onMounted(() => {
    resize()
    window.addEventListener('resize', resize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', resize)
  })

  return {
    breakpoints,
    screens,
    breakpoint,
    isOverOrEqual,
    isOver,
    isUnderOrEqual,
    isUnder,
    isBetween,
    getBreakpoint,
    isMobile,
    isTablet,
    isDesktop,
    isWideScreen
  }
}
