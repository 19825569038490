<template>
  <card-list-item
    class="collapse--container-outer"
    :class="{
      'collapse--open': isOpen,
      'collapse--closed': !isOpen,
      condensed: condensed
    }"
  >
    <div class="collapse--container">
      <!-- content -->
      <div class="collapse--content" v-if="isOpen || !cloak || showContent">
        <div class="collapse--content-inner">
          <slot></slot>
        </div>
      </div>

      <!-- header -->
      <div class="collapse--header" @click.stop.prevent="toggle">
        <span v-if="showCaret" :class="caretClass" class="collapse--caret">
          <font-awesome-icon :icon="['far', caretClass]" />
        </span>

        <span class="collapse--title">
          <slot name="title">Expand</slot>
        </span>
      </div>
    </div>
  </card-list-item>
</template>

<script>
/**
 *
 *
 */
export default {
  name: 'CardListCollapse',
  emits: ['input', 'click'],

  watch: {
    value(v) {
      this.shown = v
    },

    shown(v) {
      this.$emit('input', v)
    }
  },

  methods: {
    /**
     * Toggle between open and closed
     * @return {Promise<this>}
     */
    toggle() {
      this.$emit('click')

      if (this.shown.includes(this.uid)) {
        return this.close()
      }

      return this.open()
    },

    /**
     * Open this
     * @return {Promise<this>}
     */
    async open() {
      this.showContent = true
      await this.$nextTick()

      setTimeout(() => {
        const index = this.shown.indexOf(this.uid)
        if (index === -1) {
          this.shown = [...this.shown, this.uid]
        }

        this.scrollTo()
      }, 200)

      return this
    },

    /**
     * Close this
     * @return {Promise<this>}
     */
    async close() {
      const index = this.shown.indexOf(this.uid)
      if (index > -1) {
        this.shown.splice(index, 1)
      }

      await this.$nextTick()

      if (this.recloak) {
        setTimeout(() => {
          this.showContent = false
        }, 500)
      }

      return this
    },

    /**
     * Scroll to this.
     * @return this
     */
    scrollTo() {
      if (this.scroll) {
        c.scrollTo(this.$el)
      }

      return this
    }
  },

  computed: {
    isOpen() {
      return this.shown.includes(this.uid)
    }
  },

  data() {
    return {
      showContent: false,
      shown: [...this.value],
      uid: this.id,
      collapseStyle: '',
      parentsPrevOverflow: {}
    }
  },

  props: {
    /**
     * Array of unique id's
     * that can be used among a number of
     * CardListCollapses to indicate which
     * among them are closed. Values in the array
     * relate to the unique id (uid) associated
     * to each CardListCollapse if it is open, and
     * nothing will be in the array for the ones closed.
     * The id value can be overwritten with the id prop.
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * Whether to show expand/collapse indicator
     */
    showCaret: {
      default: true
    },

    /**
     * Set a right-pointing caret which will be turned around
     */
    caretClass: {
      type: String,
      default: 'chevron-right'
    },

    /**
     * Specify a unique id that will check for and appear
     * in the value array for open and closed collapses
     */
    id: {
      type: String,
      default: String(_.uniqueId())
    },

    /**
     * If set to true, then the main content will ONLY
     * be loaded when it is opened. Otherwise whatever
     * components exist inside it will not be created
     * until then.
     */
    cloak: {
      default: false
    },

    /**
     * ONLY if cloak is set to TRUE, and this is set to true,
     * the contents inside the collapsible body will be destroyed
     * when it collapsed, and be created again when it opens etc.
     *
     * If this is set to know, the components inside the collapsible
     * body will not be destroyed.
     */
    recloak: {
      type: Boolean,
      default: true
    },

    /**
     * Whether to scroll to item upon opening.
     */
    scroll: {
      type: Boolean,
      default: true
    },

    condensed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$padding: 0.75rem 1.25rem;
$inverse: -0.75rem -1.25rem;

.collapse--container-outer {
  padding: 0 !important;
  overflow: hidden;

  &.condensed {
    .collapse--container {
      .collapse--header {
        min-height: 40px;
        background: $flame-white !important;
        padding: 0.65rem 0.5rem;
        &::before,
        &::after {
          box-shadow: none !important;
        }
        .collapse--title {
          font-weight: 500 !important;
          color: $cool-gray-600;
        }
        .collapse--caret {
          &::before {
            color: $cool-gray-800;
          }
        }
      }
      .collapse--content-inner {
        padding-top: 0 !important;
        color: $cool-gray-600;
      }
    }
  }

  .collapse--container {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: $z-layout - 2;

    .collapse--header {
      width: 100%;
      padding: $padding;
      flex: 1 100%;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      z-index: $z-layout - 1;
      background: $flame-white;
      position: relative;
      transition: background 0.2s ease;
      min-height: 5em;
      text-align: left;

      &:hover {
        cursor: pointer;
        background: $cool-gray-200;
      }
      &::before {
        transition: all 0.2s ease;
        content: '';
        position: absolute;

        background-color: transparent;
        bottom: 0;
        left: 0;
        height: 1em;
        width: 1em;
        border-top-left-radius: 0.5em;
        box-shadow: 0 -0.5em 0 0 transparent;
      }
      &::after {
        transition: all 0.2s ease;
        content: '';
        position: absolute;

        background-color: transparent;
        bottom: 0;
        right: 0;
        height: 1em;
        width: 1em;
        border-top-right-radius: 0.5em;
        box-shadow: 0 -0.5em 0 0 transparent;
      }
    }

    .collapse--title {
      font-weight: bold;
      line-height: 1;
      flex: 2 auto;
      overflow: visible;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      height: 100%;
      color: $cool-gray-600;
    }

    .collapse--header-contents {
      height: 100%;
    }

    .collapse--content {
      transition: height 1s ease;
      height: 0px;
      opacity: 0;
    }

    .collapse--content-inner {
      height: 100%;
      width: 100%;
      padding: $padding;
      transition: transform 0.2s ease;
      transform: translateY(-100%);
      contain: paint layout;
      font-weight: 500;

      > .flush {
        margin: $inverse !important;
        width: calc(100% + 2.5em);
        min-width: calc(100% + 2.5em);
        border-radius: 0 !important;
      }

      > p {
        font-weight: 500;
        margin: 1em;
      }
    }

    .collapse--caret {
      margin-right: 1em;
      transition: all 0.2s ease;
      height: 1.5em;
      width: 1.5em;
      max-height: 1.5em;
      max-width: 1.5em;
      min-height: 1.5em;
      min-width: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:hover {
        background: $blue-print-600;
        color: $flame-white !important;
      }
    }
  }

  &.collapse--open {
    > .collapse--container {
      > .collapse--header {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        .collapse--caret {
          transform: rotate(90deg);
        }

        &::before,
        &::after {
          bottom: -1em;
        }
      }
      > .collapse--content {
        height: auto;
        opacity: 1;
        > .collapse--content-inner {
          transform: translateY(0);
        }
      }
    }
  }

  @each $color, $value in $additional-colors {
    &.#{$color} {
      $yiq: $value;

      .collapse--header {
        background: $value;
        color: $yiq;

        &:hover {
          background: $value;
        }
      }

      .collapse--caret {
        color: $value;
      }

      .collapse--content {
        background: $flame-white;
      }

      &.collapse--open {
        > .collapse--container {
          > .collapse--header {
            background: $value;

            &:hover {
              background: $value;
              &::before,
              &::after {
                box-shadow: 0 -0.5em 0 0 $value;
              }
            }
            &::before,
            &::after {
              box-shadow:
                0 -0.5em 0 0 $value,
                10%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .collapse--container-outer:first-child {
    > .collapse--container {
      > .collapse--header {
        border-top-right-radius: $card-border-radius;
        border-top-left-radius: $card-border-radius;
      }
    }
  }

  .collapse--container-outer:last-child {
    > .collapse--container {
      > .collapse--header {
        border-bottom-right-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
      }
    }
  }
}
</style>
