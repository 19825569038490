import { ref } from 'vue'
import { useStore } from 'vuex'
import { fetchAuthSession } from 'aws-amplify/auth'

export async function useSocialIdPs() {
  const store = useStore()
  const { tokens } = await fetchAuthSession()

  const appleIdPSubject = ref(null)
  const googleIdPSubject = ref(null)

  const identities = tokens.idToken.payload.identities

  if (identities) {
    for (const identity of identities) {
      if (identity.providerName === 'Google') {
        googleIdPSubject.value = identity.userId
      }
      if (identity.providerName === 'SignInWithApple') {
        appleIdPSubject.value = identity.userId
      }
    }
  }

  function enableSignInWithApple() {
    const authUrl = `https://appleid.apple.com/auth/authorize?client_id=${import.meta.env.VITE_SIWA_CLIENT_ID}&redirect_uri=https://${import.meta.env.VITE_FE_BASE}/oauth/apple/link&response_type=code&response_mode=query`
    window.open(authUrl, '_self')
  }

  async function disableSignInWithApple() {
    try {
      await store.dispatch('ajax', {
        path: `/oauth/unlink/apple`,
        data: {
          sub: appleIdPSubject.value
        }
      })
      appleIdPSubject.value = null
      await store.dispatch('alert', { message: 'Apple account successfully disconnected' })
    } catch (e) {
      await store.dispatch('alert', {
        error: true,
        message: 'An error occurred disconnecting your account. Please try again or contact support'
      })
      console.debug(e)
    }
  }

  function enableSignInWithGoogle() {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=https://${import.meta.env.VITE_FE_BASE}/oauth/google/link&scope=email&response_type=code`
    window.open(authUrl, '_self')
  }

  async function disableSignInWithGoogle() {
    try {
      await store.dispatch('ajax', {
        path: `/oauth/unlink/google`,
        data: {
          sub: googleIdPSubject.value
        }
      })
      googleIdPSubject.value = null
      await store.dispatch('alert', { message: 'Google account successfully disconnected' })
    } catch (e) {
      await store.dispatch('alert', {
        error: true,
        message: 'An error occurred disconnecting your account. Please try again or contact support'
      })
      console.debug(e)
    }
  }

  return {
    appleIdPSubject,
    googleIdPSubject,
    enableSignInWithApple,
    disableSignInWithApple,
    enableSignInWithGoogle,
    disableSignInWithGoogle
  }
}
