import { computed, ref, onBeforeUnmount, onMounted, getCurrentInstance } from 'vue'

export default {
  useSuperHeaders(args) {
    const {
      tooltip,
      tooltipCoords,
      dataSheets,
      collapsedSuperHeaders,
      collapseSuperHeader,
      uncollapseSuperHeader,
      canvasCursorType,
      scrollToX
    } = args

    const showSuperHeaderCollapse = ref(false)
    const superHeaderCollapseCoords = ref(null)

    const superHeaderCollapseStyle = computed(() => {
      if (!showSuperHeaderCollapse.value) {
        return {
          display: 'none'
        }
      }

      return {
        position: 'absolute',
        left: `${superHeaderCollapseCoords.value[0]}px`,
        top: `${superHeaderCollapseCoords.value[1]}px`,
        width: `${superHeaderCollapseCoords.value[2]}px`,
        height: `${superHeaderCollapseCoords.value[3]}px`
      }
    })

    const getFirstNonHiddenSuperHeadingCollision = (sheetIndex, superHeaderIndex, col) => {
      const allSuperHeaders = dataSheets.value[sheetIndex].superHeaders

      let index = -1
      for (const superHeader of allSuperHeaders) {
        index += 1
        if (superHeader.hidden) continue
        let start = 0
        let end = 1
        do {
          if (col >= superHeader.span[start] && col <= superHeader.span[end]) {
            return index
          }
          start += 2
          end += 2
        } while (_.isNumber(superHeader.span[start]) && _.isNumber(superHeader.span[end]))
      }

      return superHeaderIndex
    }

    const clickCollapseSuperHeadersHandler = (
      sheetIndex = null,
      superHeaderIndex = null,
      position = null,
      force = false
    ) => {
      const csh = { ...collapsedSuperHeaders.value }

      const sh = sheetIndex ?? showSuperHeaderCollapse.value[0]
      let sshi = superHeaderIndex ?? showSuperHeaderCollapse.value[1]

      if (!force && dataSheets.value[sh].superHeaders[sshi].hidden) {
        sshi = getFirstNonHiddenSuperHeadingCollision(sh, sshi, position.col)
      }

      if (!(sh in csh)) csh[sh] = {}

      // uncollapse
      if (`${sshi}` in csh[sh]) {
        uncollapseSuperHeader(sh, sshi)
      } else {
        collapseSuperHeader(sh, sshi)
        scrollToX(0)
      }
    }

    const handleMouseoverSuperHeading = (eventData) => {
      const { sheetIndex, superHeadingIndex, position } = eventData.element
      let sh = superHeadingIndex

      if (dataSheets.value[sheetIndex].superHeaders[superHeadingIndex].hidden) {
        sh = getFirstNonHiddenSuperHeadingCollision(sheetIndex, superHeadingIndex, position.col)
      }

      const { x: left, y: top, width, height } = eventData.element.position

      showSuperHeaderCollapse.value = [sheetIndex, sh]
      superHeaderCollapseCoords.value = [left, top, width, height]

      tooltip.value = `Click to show/hide • ${dataSheets.value[sheetIndex].superHeaders[sh].title}`
      tooltipCoords.value = {
        left,
        top,
        width,
        height
      }
      canvasCursorType.value = 'pointer'
    }

    const handleMouseoutSuperHeading = () => {
      showSuperHeaderCollapse.value = false
      superHeaderCollapseCoords.value = null
      tooltip.value = ''
      canvasCursorType.value = 'cell'
    }

    const handleClickSuperHeading = (e) => {
      const { superHeadingIndex, sheetIndex, position } = e.element
      const force = e.force || false
      clickCollapseSuperHeadersHandler(sheetIndex, superHeadingIndex, position, force)
    }

    const $this = getCurrentInstance().proxy
    onMounted(() => {
      $this.$on('mouseoverSuperHeading', handleMouseoverSuperHeading)
      $this.$on('mouseoutSuperHeading', handleMouseoutSuperHeading)
      $this.$on('clickSuperHeading', handleClickSuperHeading)
    })

    onBeforeUnmount(() => {
      $this.$off('mouseoverSuperHeading', handleMouseoverSuperHeading)
      $this.$off('mouseoutSuperHeading', handleMouseoutSuperHeading)
      $this.$off('clickSuperHeading', handleClickSuperHeading)
    })

    return {
      clickCollapseSuperHeadersHandler,
      handleClickSuperHeading,
      superHeaderCollapseStyle,
      showSuperHeaderCollapse
    }
  }
}
