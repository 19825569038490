<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import SidePanel from '@/components/layout/panel/SidePanel.vue'
import { useSidePanel } from '@/stores/sidepanel'
import { useMediaQuery } from '@/composables/mediaQuery'

const route = useRoute()
const sidePanelStore = useSidePanel()

const { smallFormat } = useMediaQuery()

const startOffset = ref(0)

const menuTop = computed(() => {
  return !smallFormat.value && route.meta.header ? 'top-[74px]' : 'top-0'
})

const showMenuBackdrop = computed(() => {
  return sidePanelStore.open
})

const menuSize = computed(() => {
  return sidePanelStore.computedSize
})

const dragHandleCursor = computed(() => {
  if (sidePanelStore.collapsed) {
    return 'cell'
  }
  return menuSize.value <= sidePanelStore.minimumSize ? 'e-resize' : 'col-resize'
})
const dragHandleTooltip = computed(() => {
  return sidePanelStore.collapsed ? 'Click to expand' : 'Drag to resize or click to collapse'
})

const dragging = ref(false)
function startResize(event) {
  startOffset.value = menuSize.value - event.x
  dragging.value = true
}
function dragEnd() {
  dragging.value = false
}

function resizeMenu(event) {
  if (event.x !== 0) {
    sidePanelStore.size = event.x + startOffset.value
  }
}
function collapseMenu() {
  sidePanelStore.collapsed = !sidePanelStore.collapsed
}
</script>

<template>
  <div class="select-none">
    <div
      v-if="showMenuBackdrop"
      class="md:hidden fixed top-0 bottom-0 left-0 right-0 bg-surface-800 opacity-90 z-20"
      @click="sidePanelStore.toggle"
    ></div>
    <nav
      class="bottom-0 right-0 md:left-0 fixed flex flex-col flex-nowrap overflow-hidden bg-cement-100 z-20 md:border-r md:border-cement-400"
      :class="[menuTop]"
      :style="{ width: `calc(env(safe-area-inset-right) + ${menuSize}px)` }"
    >
      <component class="h-full" :is="$route.meta.menu" v-if="$route.meta.menu" />
      <SidePanel v-else-if="$route.meta.menuContext" />
      <div
        :draggable="!sidePanelStore.collapsed"
        @dragstart="startResize"
        @dragend="dragEnd"
        @drag="resizeMenu"
        @click="collapseMenu"
        class="hidden md:block"
        :class="[
          'absolute w-1 h-full top-0 bottom-0 right-0 z-20 hover:bg-blue-print',
          {
            'bg-transparent': !dragging,
            'bg-blue-print': dragging
          }
        ]"
        :style="{ cursor: dragHandleCursor }"
        v-tooltip="dragHandleTooltip"
      ></div>
    </nav>
  </div>
</template>

<style scoped lang="scss"></style>
