// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'timezone',
  
  skipAudit: true,

  fields: {
    timezone_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    timezone_country_code: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'country',
    },
    timezone_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    timezone_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    timezone_utc_offset: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    timezone_utc_dst_offset: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },



  generateVueActions() {
    return {
      async getTimezoneFromName({ dispatch }, { name }) {
        const searchPhrase = name.replace('/', ' ');
        const { set } = await dispatch('search', {
          searchPhrase,
        });

        if (!set.length) return null;

        return set[0].timezone_id;
      },
    };
  },
};
