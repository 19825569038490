/**
 * Methods common to assembly and cost type in the quoting engine
 */
export default {
  emits: ['removing'],
  methods: {
    getStore() {
      return this.store || this.storeName
    },
    getRefId() {
      return this.refId || this.reference
    },

    async swap() {
      this.swapping = true
      this.addLoading()

      await this.$nextTick()

      try {
        const selected = await this.$store.dispatch('openItemSelector', {
          mod: await this.$store.dispatch('Quote/getQuoteMod', {
            refId: this.refId,
            store: this.store
          })
        })

        if (!selected.length) {
          this.endLoading()
          this.swapping = false
          return this
        }

        const item = selected[0]

        const newChildType = item.type === 'assembly' ? 'assembly' : 'cost_item'

        const newChildId = item.cost_type_id || item.assembly_id

        this.showLoader = newChildType

        await this.$store.dispatch(`${this.getStore()}/swapItem`, {
          parent: this.parentRefId,
          child: this.getRefId(),
          type: newChildType,
          id: newChildId
        })
      } finally {
        this.swapping = false
        this.removeLoading()
      }

      return this
    },

    async remove() {
      this.$emit('removing', 1)
      this.$store.dispatch(`${this.getStore()}/removeChild`, { refId: this.getRefId() })
      this.$destroy()
    },

    async duplicate() {
      return this.$store.dispatch(`${this.getStore()}/duplicateItem`, { refId: this.getRefId() })
    }
  }
}
