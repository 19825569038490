import filters from '../../filters'
/**
 * For use in input-like components
 *
 * Emits
 *  -input
 *  -change
 *  -formatted
 *  -submit
 *  -keypress
 *  -focus
 *  -blur
 *
 *  -invalidRequired
 *  -invalidType
 *  -valid
 *  -noValidation
 *
 *  Uses classes: (if implemented)
 *    -field (all)
 *    -validate-valid (for valid field entries)
 *    -validate-invalid (for invalid field entries)
 */

const throttle = c.throttle

export default {
  mounted() {
    this.emitValidationEvents()
    this.$emit('field-mounted')
  },
  beforeUnmount() {
    if (this.emitOnDestroy) {
      this.emitValue()
      this.emitEquation()
    }
  },

  props: {
    value: {
      required: false
    },

    equation: {
      required: false
    },

    emitOnDestroy: {
      default: true
    },

    emitOnBlur: {
      default: true
    },

    format: {
      default: false
    },

    formatDecimals: {
      default: 2
    },

    validate: {
      default: null
    },

    schema: {
      default: null
    },

    autoSelect: {
      default: null
    },

    placeholder: {
      default: ''
    },

    dimensions: {
      default: () => ({})
    },

    variables: {
      default: () => ({})
    },

    measure: {
      default: 'ft' // m | ft | mm | count | yd | yd3 | m3  etc
    },

    emitDelay: {
      type: Number,
      default: 1500
    },

    validateImmediately: {
      default: false
    },

    validateTrigger: {
      default: false
    },

    name: {
      type: String
    },

    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'blur',
    'cancel',
    'change',
    'changed',
    'click',
    'dblclick',
    'down',
    'empty',
    'enter',
    'equation',
    'field-mounted',
    'focus',
    'focusin',
    'focusout',
    'formatted',
    'input',
    'invalid',
    'keypress',
    'keyup',
    'left',
    'next',
    'notEmpty',
    'right',
    'submit',
    'tab',
    'touchstart',
    'up',
    'valid',
    'validation'
  ],
  data() {
    return {
      /**
       * Source of truth for value, always unformatted
       */
      rawValue: this.deformatValue(this.value),

      /**
       * Source of truth for equation
       */
      equationValue: this.equation,

      /**
       * Value as typed
       * Always needs to exist if there is a value to this input
       */
      typedValue: this.equation || this.value,

      /**
       * Whether input has stopped and value changes
       * from external [value prop] can be accepted
       */
      emitted: true,

      /**
       * Whether there is focus on the input
       */
      focused: false,

      /**
       * Has ever focused
       */
      hasFocused: false,

      /**
       * Unique identifier
       */
      uid: _.uniqueId(),

      /**
       * When value is required but not found, or if it is invalid
       * this will have a message to describe what is missing
       */
      validationMessage: '',

      /**
       * As long as user hasn't started typing yet, value can update
       */
      startedTypingSinceEmit: false,

      /**
       * As long as user hasn't started typing yet at all, it will focus all
       */
      startedTypingSinceFocus: false,

      markInvalid: false
    }
  },

  computed: {
    /**
     *
     * @returns {{default: null}|{default: boolean}|boolean|boolean}
     */
    shouldAutoSelectOnFocus() {
      return (
        this.autoSelect ||
        (this.autoSelect === null &&
          this.fieldType === 'calculator' &&
          this.type !== 'calculator-inline')
      )
    },

    /**
     *
     * @returns {boolean|default.computed.isNumericField}
     */
    shouldCalculate() {
      return this.fieldType === 'calculator'
    },

    /**
     *
     * @returns {string}
     */
    fieldType() {
      if (this.type === 'calculator' || this.type === 'calculator-inline') return 'calculator'

      return 'input'
    },

    /**
     * Get list of all variables available
     * @returns {{[p: string]: *}}
     */
    variablesAvailable() {
      // first, convert provided dimensions into a common base measure,
      // based on the measure provided, ft/m/mm etc
      const dimensions = _.imm(this.dimensions)
      const measure = this.measure

      let all = {}

      if (Object.keys(dimensions).length) {
        let convertedDimensions = dimensions
        if (measure && measure !== 'count') {
          convertedDimensions = this.getConvertedDimensions(dimensions, measure)
        }

        all = {
          ...Object.keys(convertedDimensions).reduce(
            (acc, abbr) => ({
              ...acc,
              [abbr]: {
                value: convertedDimensions[abbr].value || 0,
                name: convertedDimensions[abbr].name,
                desc: convertedDimensions[abbr].desc,
                color: convertedDimensions[abbr].color,
                measure: convertedDimensions[abbr].measure,
                measureType: convertedDimensions[abbr].measureType
              }
            }),
            {}
          )
        }
      }

      if (Object.keys(this.variables).length) {
        all = {
          ...all,
          ...this.variables
        }
      }

      return all
    },

    /**
     * The value that should appear in the field
     * @returns {default.computed.formattedValue|*|default.equation}
     */
    fieldValue: {
      get() {
        const focused = this.isFocused()

        if (focused && this.equationValue) {
          return this.equationValue
        }

        if (!focused && this.fieldFormat) {
          return this.formattedValue
        }

        return this.formatTypedValue(this.typedValue)
      },
      set(value) {
        if (!this.focused) return
        this.setTypedValue(value)
      }
    },

    /**
     *
     * @returns {string|string|boolean}
     */
    inputTooltip() {
      return this.validationMessage || false
    },

    /**
     * Guesses if is numeric
     * @returns {boolean}
     */
    isNumericField() {
      if (this.fieldType === 'calculator') {
        return true
      }

      if (c.isNumericField(this.schema)) {
        return true
      }

      if (
        /currency|number|hours|percent|percentage|foot|ft|'|imperial|"|markup|%/.test(
          this.fieldFormat
        )
      ) {
        return true
      }

      return !!(this.fieldSchema && /float|int/.test(this.fieldSchema.type))
    },

    /**
     * Determines whether we need to determine whether a value
     * has changed by looking into decimal points or not.
     * @returns {boolean}
     */
    checkDecimalEquals() {
      return this.isNumericField && (!this.fieldSchema || /float/.test(this.fieldSchema.type))
    },

    /**
     * Constructor from field schema provided
     * @returns {null|object}
     */
    objectConstructor() {
      if (!this.schema) {
        return null
      }

      const type = this.schema.split(':')[0]

      return c.getConstructor(type)
    },

    /**
     * Field schema as provided
     * @returns {null|object}
     */
    fieldSchema() {
      if (!this.objectConstructor) return null

      const schemaField = this.schema.split(':')[1]

      if (!(schemaField in this.objectConstructor.fields)) return null

      return this.objectConstructor.fields[schemaField]
    },

    /**
     * Final object type from the schema provided
     * @returns {string|null}
     */
    objectType() {
      if (!this.fieldSchema) return null

      const schemaType = this.schema && this.schema.split(':')[0]

      return this.fieldSchema.mapTo || schemaType
    },

    /**
     * Get the format type for this value.
     * @returns {string|null}
     */
    fieldFormat() {
      let format = this.format || (this.fieldSchema && this.fieldSchema.format)

      if (this.fieldType === 'calculator' && !format) {
        format = 'number'
      }

      if (format && this.formatRequired && format !== this.formatRequired) {
        console.warn(
          'The format required:',
          this.formatRequired,
          'is not the same as the output format requested:',
          format,
          'for field component which will make validation impossible.',
          'Using required format instead.'
        )
        format = this.formatRequired
      }

      return format
    },

    /**
     * @returns {boolean}
     */
    required() {
      if (!this.validate && (!this.fieldSchema || !this.fieldSchema.validate)) return false

      const valReq = this.validate && this.validate.required

      const schemReq =
        this.fieldSchema && this.fieldSchema.validate && this.fieldSchema.validate.required

      if (valReq !== null && typeof valReq !== 'function') {
        return !!valReq
      }

      if (schemReq !== null && typeof schemReq !== 'function') {
        return !!schemReq
      }

      const fn = valReq || schemReq
      if (typeof fn === 'function') return fn.call(this, this)

      return false
    },

    /**
     * @returns {null|string}
     */
    formatRequired() {
      if (!this.validate && (!this.fieldSchema || !this.fieldSchema.validate)) return null

      const valType = this.validate && this.validate.type

      let schemType =
        this.fieldSchema && this.fieldSchema.validate && this.fieldSchema.validate.type

      const countryId = +this.$store.state.session.company.country_id
      const countryAbbr = countryId && countryId in _.countries && _.countries[countryId].abbr
      const countrySpecificType = countryAbbr && `${schemType}_${countryAbbr}`

      schemType = (countrySpecificType in _.validations && countrySpecificType) || schemType

      const valFormat = this.validate && this.validate.format

      const schemFormat =
        this.fieldSchema && this.fieldSchema.validate && this.fieldSchema.validate.format

      return valFormat || schemFormat || valType || schemType
    },

    /**
     * Checks if based on the validation it is correct
     */
    valid() {
      return this.validFormat && (!this.required || this.notEmpty)
    },

    /**
     * @returns {*}
     */
    validFormat() {
      const msg = this.validate && this.validate.message ? this.validate.message : null
      const { format, message } = c.validate(this.rawValue, this.required, this.formatRequired, msg)
      this.validationMessage = message
      return format
    },

    /**
     * If a value exists
     * @returns {boolean}
     */
    notEmpty() {
      return !!this.rawValue
    },

    /**
     * @returns {[]}
     */
    validateClass() {
      const classes = []

      if (this.required && !this.notEmpty) {
        classes.push('validate-required')
      }

      if (this.valid) {
        classes.push('validate-valid')
      } else if (this.hasFocused) {
        classes.push('!border-2 !border-deep-red-500')
      } else if (this.markInvalid) {
        classes.push('!border-2 !border-deep-red-500')
      }

      return classes
    },

    /**
     * @returns {*[]}
     */
    inputClass() {
      return ['field', ...this.validateClass, ...(this.isNumericField ? ['number'] : [])]
    },

    componentClass() {
      return [
        'input-container',
        ...[this.fieldFormat ? this.fieldFormat : 'no-format'],
        'field-component',
        `field-component--${this.fieldType}`,
        ...(this.isNumericField ? ['number'] : []),
        ...(this.focused ? ['focused'] : [])
      ]
    },

    /**
     * @returns {*[]}
     */
    inputStyle() {
      return []
    },

    /**
     * @returns {string}
     */
    inputId() {
      return `${this.uid}.${this.schema}`
    },

    /**
     * @returns {string|*}
     */
    deformattedValue() {
      return this.rawValue
    },

    /**
     * @returns {string|*}
     */
    formattedValue() {
      return this.formatValue(this.rawValue)
    }
  },

  watch: {
    valid() {
      this.emitValidationEvents()
    },

    errors() {
      if (this.name && this.errors.includes(this.name)) {
        this.markInvalid = true
        return
      }
      this.markInvalid = false
    },

    validRequired() {
      this.emitValidationEvents()
    },

    validFormat() {
      this.emitValidationEvents()
    },

    equation() {
      this.importValue()
    },

    value() {
      this.$emit('changed', this.value)
      this.importValue()
    },

    rawValue() {
      this.emitValidationEvents()
    }
  },

  methods: {
    log(...args) {
      console.log(`IPT[${this.uid}]: `, ...args)
    },

    // Handlers

    async inputHandler(e) {
      this.fieldValue = $(e.target).val()
    },

    async focusinHandler(...args) {
      if (!this.focused && this.shouldAutoSelectOnFocus) {
        this.selectAll()
      }

      this.focused = true
      this.hasFocused = true
      this.genericEmit('focusin', args)
      this.genericEmit('focus', args)
    },

    async focusoutHandler(...args) {
      this.focused = false

      this.startedTypingSinceFocus = false

      if (this.emitOnBlur) {
        this.emitValue()
        this.emitEquation()
      }

      this.genericEmit('focusout', args)
      this.genericEmit('blur', args)
      this.emitValidationEvents()
    },

    async dblclickHandler(...args) {
      await this.selectAll()
      this.genericEmit('dblclick', args)
    },

    async touchstartHandler(...args) {
      this.clickHandler(...args)
      this.genericEmit('touchstart', args)
    },

    async clickHandler(...args) {
      await this.focus()
      this.genericEmit('click', args)
    },

    async keypressHandler(...args) {
      this.startedTypingSinceEmit = true
      this.startedTypingSinceFocus = true
      this.genericEmit('keypress', args)
    },

    async keyupHandler(...args) {
      const key = args[0].which

      if (key === 13) {
        this.enterHandler()
      } else if (key === 27) {
        this.escapeHandler()
      } else if (key === 9) {
        this.tabHandler()
      } else if (key === 37) {
        this.leftArrowHandler()
      } else if (key === 38) {
        this.upArrowHandler()
      } else if (key === 39) {
        this.rightArrowHandler()
      } else if (key === 40) {
        this.downArrowHandler()
      }

      this.genericEmit('keyup', args)
    },

    enterHandler() {
      this.genericEmit('submit')
      this.genericEmit('enter')
    },

    escapeHandler() {
      this.genericEmit('cancel')
    },

    tabHandler() {
      this.genericEmit('tab')
      this.genericEmit('next')
    },

    leftArrowHandler() {
      this.genericEmit('left')
    },

    upArrowHandler() {
      this.genericEmit('up')
    },

    rightArrowHandler() {
      this.genericEmit('right')
    },

    downArrowHandler() {
      this.genericEmit('down')
    },

    async changeHandler(...args) {
      this.genericEmit('change', args)
    },

    async genericEmit(name, args = []) {
      if (!this.validateImmediately && !this.hasFocused) return
      this.$emit.apply(this, [name, ...args])
    },

    async emitValidationEvents() {
      this.throttle(
        () => {
          const payload = {
            type: this.formatRequired,
            format: this.formatRequired,
            required: this.required,
            message: this.validationMessage,
            valid: this.valid,
            empty: !this.notEmpty,
            validRequired: !this.required || this.notEmpty,
            validType: this.validFormat,
            value: this.rawValue
          }
          // console.log('emitting valid? ', this.valid && (!this.required || this.notEmpty));

          this.genericEmit('validation', [payload])
          if (this.valid && (!this.required || this.notEmpty)) {
            this.genericEmit('valid')
          } else if (this.notEmpty) this.genericEmit('invalid')

          if (this.notEmpty) this.genericEmit('notEmpty')
          else this.genericEmit('empty')
        },
        false,
        200
      )
    },

    /**
     * Method that forces blur on this input
     * @returns {Promise<boolean>}
     */
    async blur() {
      if (!this.isFocused()) {
        return true
      }

      return true
    },

    /**
     * Method that forces focus on this input
     * @returns {Promise<boolean>}
     */
    async focus() {
      if (this.isFocused()) {
        return true
      }

      this.importValue()

      if (this.$refs.input) {
        this.$refs.input.$el.focus()
      }

      return true
    },

    /**
     * Method that forces select all on the input
     * @returns {Promise<boolean>}
     */
    async selectAll() {
      await this.$nextTick()

      if (!this.$refs.input) {
        return false
      }

      this.isSelectAll = true

      if (typeof c.makeArray(this.$refs.input)[0].selectAll === 'function') {
        c.makeArray(this.$refs.input)[0].selectAll()
      } else if (typeof c.makeArray(this.$refs.input)[0].select === 'function') {
        c.makeArray(this.$refs.input)[0].select()
      }

      return true
    },

    /**
     * Synonym for focused
     * @returns {*}
     */
    isFocused() {
      return this.focused
    },

    /**
     * Sets the universal source of truth
     * @param value
     * @param triggerEmit
     */
    async setRawValue(value, triggerEmit = true) {
      const oldValue = this.rawValue
      let deformatted = value

      deformatted = this.deformatValue(deformatted)

      if (!this.areEqual(deformatted, oldValue)) {
        this.rawValue = deformatted
      }

      if (triggerEmit) {
        this.emitted = false

        this.throttle(
          () => {
            this.emitValue()
          },
          false,
          this.emitDelay
        )
      }
    },

    /**
     * Set the equation
     * @param typed
     * @param triggerEmit
     */
    setTypedValue(typed, triggerEmit = true) {
      this.typedValue = typed

      const deformattedTyped = this.deformatValue(this.typedValue)

      if (
        (/[a-zA-Z'"]/.test(this.typedValue) && this.isNumericField) ||
        c.isEquation(this.typedValue, this.variablesAvailable)
      ) {
        this.setEquation(this.typedValue, triggerEmit)
      } else if (this.equationValue) {
        this.setEquation('', triggerEmit)
      }

      if (this.isNumericField || this.fieldFormat) {
        this.setRawValue(deformattedTyped, triggerEmit)
      } else {
        this.setRawValue(this.typedValue, triggerEmit)
      }
    },

    /**
     * Set the equation
     * @param equation
     * @param triggerEmit
     */
    setEquation(equation, triggerEmit = true) {
      const oldEquation = this.equationValue

      if (equation !== oldEquation) {
        this.equationValue = equation
      }

      if (triggerEmit) {
        this.throttle(
          () => {
            this.emitEquation()
          },
          false,
          this.emitDelay
        )
      }
    },

    /**
     * Gets the external value
     */
    importValue(val = this.value, eq = this.equation) {
      if (this.focused && (this.startedTypingSinceEmit || this.startedTypingSinceFocus)) {
        return
      }

      this.setTypedValue(eq || val, false)
      this.setEquation(eq, false)

      if (this.focused && !this.startedTypingSinceFocus && this.shouldAutoSelectOnFocus) {
        this.selectAll()
      }
    },

    /**
     * Convert dimensions into the required dimension type, ie convert any dimensions
     * that are provided as metres as ft, which may be designated as this input's measure type
     * @returns {{}}
     */
    getConvertedDimensions(existingDimensions = this.dimensions, measure = this.measure) {
      const meas = String(_.makeArray(measure)[0] || 'ft')
      const baseMeasure = meas.replace(/^(ft|m|mm|yd)\d?$/i, '$1')
      const defaultDimensions = c.getCacheItem(
        'PossibleDimensions',
        'dimension',
        this.$store.state.session
      )

      if (!Object.keys(defaultDimensions)) {
        this.$store.dispatch('Dimension/getPossibleDimensions')
        return existingDimensions
      }

      const dimensions = {
        ...defaultDimensions,
        ...existingDimensions
      }

      let convertedDimensions = {}

      if (baseMeasure && baseMeasure in _.conversionTables) {
        convertedDimensions = Object.keys(dimensions).reduce((acc, abbr) => {
          let measureTypeNotation = ''
          if (dimensions[abbr].measureType === 'area' && baseMeasure !== 'square') {
            measureTypeNotation = '2'
          } else if (dimensions[abbr].measureType === 'volume') {
            measureTypeNotation = '3'
          }

          let convertedValue = dimensions[abbr].value
          const fromMeasure = dimensions[abbr].measure || `ft${measureTypeNotation}`
          const toMeasure = `${baseMeasure}${measureTypeNotation}`
          if (toMeasure !== fromMeasure && dimensions[abbr].measureType !== 'count') {
            convertedValue =
              c.convertMeasures(convertedValue, fromMeasure || 'ft', toMeasure) || convertedValue // if cannot convert, return the value itself
          }

          return {
            ...acc,
            [abbr]: {
              ...(defaultDimensions[abbr] || {}),
              ...(acc[abbr] || {}),
              ...dimensions[abbr],
              value: convertedValue,
              measure: toMeasure
            }
          }
        }, {})
      }

      return convertedDimensions
    },

    // Emits

    /**
     * Emit the value, without delay
     */
    emitValue() {
      this.emitted = true
      this.startedTypingSinceEmit = false

      if (
        (String(this.rawValue) === String(this.value) ||
          this.areEqual(this.rawValue, this.value) ||
          this.areEqual(this.rawValue, this.deformatValue(this.value))) &&
        this.equationValue === this.equation
      ) {
        return
      }

      if (!this.hasFocused) return

      this.$emit('input', this.rawValue, this.equationValue)
    },

    /**
     * Emit equation without delay
     */
    emitEquation() {
      if (this.equationValue === this.equation) {
        return
      }

      if (!this.hasFocused) return

      this.$emit('equation', this.equationValue)
    },

    /**
     * Emit formatted value without delay
     */
    emitFormatted() {
      if (!this.hasFocused) return

      this.$emit('formatted', this.formattedValue)
    },

    // Utilities

    /**
     * Custom throttle with this unique key
     * @param fn
     * @param debounce
     * @param delay
     * @returns {*}
     */
    throttle(fn, debounce = false, delay = 300) {
      return throttle(
        () => {
          fn()
        },
        {
          key: `${this.uid}${fn.toString()}`,
          delay,
          debounce
        }
      )
    },

    /**
     * Checks if two values are equal or substantially equal
     * @param valueA
     * @param valueB
     * @returns {boolean|*}
     */
    areEqual(valueA, valueB) {
      return String(valueA) === String(valueB) || (this.checkDecimalEquals && c.eq(valueA, valueB))
    },

    /**
     * Formats a given value
     * @param value
     * @param format
     * @returns {*}
     */
    formatValue(value, format = this.fieldFormat) {
      if (!format) return value
      if (format === 'currency') return filters.currency(value)
      if (format === 'number') return filters.number(value, '0.[00]')
      return c.format(value, format, this.formatDecimals)
    },

    /**
     * What should show in the 'unformatted' field when focused?
     *  - not necessarily the raw value, the raw value is always
     *  in computer decimal format NNNNN.NNNN, whereas the user's
     *  version of a raw format may be NN NNN,NN etc or for
     *  hours notation 5H 3M 3S etc
     * @param raw
     * @param format
     * @returns {*}
     */
    formatTypedValue(raw, format = this.fieldFormat) {
      if (format === 'hours') return c.format(raw, 'hours')

      return raw
    },

    /**
     * Deformats a given value
     * @param value
     * @param format
     * @returns {*}
     */
    deformatValue(value, format = this.fieldFormat) {
      if (this.isNumericField) {
        return c.toNum(value, 20, this.shouldCalculate, this.variablesAvailable)
      }

      if (format) return c.deformat(value, format)

      return value
    }
  }
}
