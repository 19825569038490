<script setup>
import { ref, defineExpose, computed, onMounted, onBeforeUnmount } from 'vue'
import Cheers from '@/assets/onboarding/Cheers.png'
import ThatsLit from '@/assets/onboarding/ThatsLit.png'
import ThumbsUp from '@/assets/onboarding/ThumbsUp.png'
import eventBus from '@/eventBus.js'

const celebration = ref(null)

const points = ref(1)
const name = ref('Estimating guide')

const open = () => celebration.value.open()

defineExpose({ open })

const images = [Cheers, ThatsLit, ThumbsUp]

const chosenImage = computed(() => images[Math.round(Math.random() * images.length)] ?? images[2])

const handleMilestoneCompleted = (milestone) => {
  points.value = milestone.points ?? 4
  name.value = milestone.name
  open()
}

onMounted(() => {
  eventBus.$on('milestoneComplete', handleMilestoneCompleted)
})
onBeforeUnmount(() => {
  eventBus.$off('milestoneComplete', handleMilestoneCompleted)
})
</script>

<template>
  <MiniModal
    :pt="{
      content: () => ({
        class: ['!bg-black rounded-md']
      }),
      header: () => ({
        class: ['!hidden']
      }),
      closeButton: () => ({
        class: ['!text-white']
      })
    }"
    ref="celebration"
    :scrollable="false"
  >
    <div class="flex flex-col items-center justify-center gap-4">
      <!--      <span class="text-level-yellow font-medium text-4xl">-->
      <!--        Cheers!-->
      <!--      </span>-->
      <img :src="chosenImage" class="max-w-[400px]" />
      <span class="text-level-yellow font-medium text-4xl text-center">
        {{ name }} completed!
      </span>
      <span class="text-level-yellow font-medium text-4xl flex justify-center items-center gap-2">
        <font-awesome-icon icon="coin" class="animate-bounce" />
        +{{ points }} <span class="text-sm">Bolster points</span>
      </span>
    </div>
  </MiniModal>
</template>

<style scoped lang="scss"></style>
