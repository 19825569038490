// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'location',
  
  skipAudit: true,

  fields: {
    location_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      required: true,
    },
    company_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    location_time_created: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    user_ids: {
      type: 'string',
      filter: true,
      format: false,
      save: false,
      reload: true,
      trackChanges: false,
      mapTo: 'user',
    },
    location_is_location: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0,
    },
    location_latlon: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_address: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_suite: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_city: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    province_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'province',
    },
    location_postal: {
      type: 'string',
      filter: true,
      mapTo: false,
    },
    location_website: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_phone: {
      type: 'string',
      filter: true,
      format: 'phone',
      mapTo: false,
    },
    location_email: {
      type: 'string',
      filter: true,
      mapTo: false,
      format: 'email',
    },
  },
};
