import schemas from './schemas'
import _ from './Helpers'
import Statuses from './Statuses'
import FieldDetection from './FieldDetection'

const { statuses, statusColors } = Statuses

/**
 * List of objects that can be created,
 *   key: type
 *   value: modal name for creation
 */
const creatableObjects = {
  quote: 'QuoteNew',
  invoice: 'InvoiceNew',
  client: 'ClientNew',
  tax: 'TaxNew',
  template: 'TemplateNew',
  lead_rotation: 'LeadRotationNew',
  labor_type: 'LaborTypeNew',
  vendor: 'VendorNew',
  assembly: 'AssemblyNew',
  cost_type: 'CostTypeNew',
  user: 'UserNew',
  role: 'RoleNew',
  lead_source: 'LeadSourceNew',
  lead_request: 'LeadRequestNew',
  company: 'CompanyNew',
  dimension: 'DimensionNew',
  form: 'Form',
  location: 'LocationNew',
  stage: 'Stage',
  webhook: 'Webhook',
  trade_type: 'TradeType',
  export_token: 'ExportToken',
  csi_code: 'BudgetCode',
  assignee: 'AssigneeNew'
}

/**
 * List of objects that can be edited,
 *   key: type
 *   value: modal name for creation
 */
const editableObjects = {
  quote: 'Quote',
  assembly: 'Assembly',
  cost_type: 'CostType',
  invoice: 'Invoice',
  client: 'Client',
  tax: 'Tax',
  template: 'Template',
  lead_rotation: 'LeadRotation',
  user: 'User',
  labor_type: 'LaborType',
  role: 'Role',
  vendor: 'Vendor',
  file: (file) => (file.file_is_folder ? 'Files' : 'File'),
  change_order: 'Quote',
  lead_source: 'LeadSource',
  lead_request: 'LeadRequest',
  company: 'Company',
  dimension: 'Dimension',
  form: 'Form',
  location: 'Location',
  stage: 'Stage',
  webhook: 'Webhook',
  trade_type: 'TradeType',
  rating: 'Rating',
  csi_code: 'BudgetCode'
}

const objectList = {
  ...schemas
}

/**
 * Tests if the field key given is a JSON field iersal: aoChildren aoFiles, oObject, oClient etc
 * @param field
 * @returns {boolean}
 */
const isJsonField = FieldDetection.isJsonField

/**
 * Get constructor/definition from object type
 * @param type
 * @returns {*}
 */
const getConstructor = (type) => {
  const constructorName = _.upperFirst(_.camelCase(String(type).replace('_', ' '))).replace(' ', '')
  return constructorName in objectList ? objectList[constructorName] : false
}

/**
 * Get a default value for a field:
 * @param schema ie constructor.fields[fieldName]
 * @param field ie fieldName
 * @param embue ie: { fieldName: { existingKey: existingValue}, someOtherField: 1, ... }
 * @returns {*}
 */
const getDefaultFromField = (schema, field, embue = {}) => {
  let defaultValue = null
  if (typeof schema.default === 'function') {
    defaultValue = schema.default(embue[field] || undefined, embue)
  } else if (schema.type === 'array') {
    defaultValue = []
  } else if (/object|array/.test(typeof schema.default)) {
    defaultValue = _.imm(schema.default)
  } else if (typeof schema.default !== 'undefined') {
    defaultValue = schema.default
  }
  return defaultValue
}

/**
 * Create a shell object of given type, with default values;
 * @param type
 * @returns {{type}}
 */
const buildDefaultObject = (type, embue = {}) => {
  const constructor = getConstructor(type)
  if (!constructor) return {}
  const fields = constructor.fields
  const keys = Object.keys(fields)

  let object = _.imm({
    oEquations: {},
    ..._.zipObject(
      keys,
      keys.map((f) => getDefaultFromField(fields[f], f, embue))
    ),
    type: constructor.type
  })

  if (Object.keys(embue).length) {
    object = {
      ..._.merge(object, _.imm(embue)),
      type: constructor.type
    }

    Object.keys(object).forEach((f) => {
      if (f in fields && (/^(ao|aa|o)[A-Z]\w+/.test(f) || /array|object/.test(fields[f].type))) {
        if (fields[f].mapTo && !_.isempty(object[f])) {
          if (fields[f].type === 'array') {
            // Recursively enforce types for sub objects,
            //  ie: aoActivities => activity
            //  and oClient => client
            object[f] = _.makeArray(object[f])
            if (object[f].length) {
              object[f].map((so) => buildDefaultObject(fields[f].mapTo, so))
            }
          } else if (fields[f].type === 'object') {
            object[f] = buildDefaultObject(fields[f].mapTo, object[f])
          }
        } else if (fields[f] === 'array' && !Array.isArray(object[f])) {
          // Enforce array
          object[f] = _.makeArray(object[f])
        } else if (fields[f] === 'object' && typeof object[f] !== 'object') {
          // Enforce objects
          if (Array.isArray(object[f]) && object[f].length) {
            object[f] = object[f][0]
          } else object[f] = {}
        }

        // Deep merge defaults, liek for oDimensions or oSowColumns in quote.js schema
        // so that all required columns/keys exist.
        if (
          fields[f].type === 'object' &&
          (typeof fields[f].default === 'object' || typeof fields[f].default === 'function') &&
          !fields[f].mapTo
        ) {
          const def =
            typeof fields[f].default === 'object'
              ? fields[f].default
              : fields[f].default(object[f], object)
          object[f] = _.merge(_.imm(def || {}), object[f])
        }
      } else if (f in fields && 'default' in fields[f] && (!(f in object) || object[f] === null)) {
        const def =
          typeof fields[f].default === 'function'
            ? fields[f].default(object[f], object)
            : fields[f].default

        object[f] = def
      }
    })
  }

  return object
}

/**
 * Enforces object structure recursively
 *   on set or object provided
 * @param set Array or Object
 */
const enforce = (set = []) => {
  if (Array.isArray(set)) {
    return _.makeArray(set).map((o) => (o.type ? buildDefaultObject(o.type, o) : o))
  } else if (typeof set === 'object' && 'type' in set) {
    return buildDefaultObject(set.type, set)
  }
  return set
}

const getFieldTitle = (field, type = false) => {
  let objectType = typeof type === 'string' ? type : null
  if (type) {
    const constructor =
      (typeof type === 'object' || typeof type === 'function') && type.fields
        ? type
        : getConstructor(type)
    objectType = constructor.type
    if (type && constructor.fields[field] && constructor.fields[field].title)
      return constructor.fields[field].title
  }
  return _.capitalize(
    field
      .replace(new RegExp(`^${objectType ? String(objectType).toLowerCase() : ''}`), '')
      .replace(/^(ao|aa|ai|af|as|o)([A-Z]\w+)$/g, '$2')
      .replace(/(\w+)_id$/, '$1')
      .replace(/_/g, ' ')
      .replace('fname', 'first name')
      .replace('lname', 'last name')
      .replace(/([A-Z])/g, ' $1')
      .replace(' net', '')
      .replace('area gross', 'floor area')
      .replace(' base', '')
      .replace(' gross', ' (incl tax)')
      .replace('qty', 'quantity')
      // .replace('quote', '')
      // .replace('cost matrix', '')
      // .replace('cost type', '')
      // .replace('assembly', '')
      // .replace('quote', '')
      // .replace('cost item', '')
      // .replace('explicit', '')
      .replace('undiscounted', ' (before any discount)')
      .trim()
  )
}

/**
 * See if we should be tracking
 * changes in a particular field
 * @param field
 * @param constructor
 * @param fields
 * @returns {boolean}
 */
const shouldTrackChanges = (field, constructor = null, localFields = null) => {
  const fields = localFields || (constructor ? constructor.fields : false)
  if (field === 'parentRefId') return true
  if (!fields || !(field in fields)) return false
  const fieldSchema = fields[field]

  if (fieldSchema && (fieldSchema.trackChanges === true || fieldSchema.trackChanges === 1)) {
    return true
  }

  if (
    typeof fieldSchema.save !== 'undefined' &&
    (fieldSchema.save === false || fieldSchema.save === 0)
  )
    return false
  if (
    typeof fieldSchema.trackChanges !== 'undefined' &&
    (fieldSchema.trackChanges === false || fieldSchema.trackChanges === 0)
  )
    return false

  return true
}

/**
 * Get object id
 * @param object
 * @returns {null}
 */
const getId = (object) => (object.type && object[`${object.type}_id`]) || null

/**
 * This function determines whether we should map out
 *   a field, ie: oContact or aoChildren. Particularly important
 *   for c.normalize to determine whether to flatten the object
 * @param field
 * @param type
 */
const shouldMapField = (field, type) => {
  let constructor
  if (typeof type === 'string') constructor = getConstructor(type)
  else constructor = type

  return (
    constructor &&
    constructor.fields &&
    constructor.fields[field] &&
    (constructor.fields[field].normalize === true ||
      constructor.fields[field].normalize === 1 ||
      (typeof constructor.fields[field].normalize === 'undefined' &&
        /array|object/.test(constructor.fields[field].type) &&
        constructor.fields[field].mapTo))
  )
}

/**
 * Build a set of actions for each object type, based on their definitions
 * and defaults;
 * @param type
 * @returns Object
 */
const defaultAction = {
  // icon to show on button/dropdown
  icon: '',
  // btn class
  class: 'default',
  // btn text
  text: '',
  // Modal the button will open, or false
  modal: false,
  // Action the button will perform, or false
  // Can be a function, an object (with type: key rerpesenting the action type),
  //  or an absolute path to action ie: 'Quote/delete'
  //   found in the objects acctions store
  action: false,
  // Route to a specific router route
  route: false,
  // Should we clear cache of this.type, after performing
  //  this action.  For example, if we delete an object, select lists and
  //  tables/grids could still show the item if they have cached data-sets
  //  and cause errors if user tries to select it, so for deleting, creating etc
  //  we will want to clear cache, but for most kinds of editing/updating, we wouldn't.
  //  If however we update the status of an object we should clear cache of that type,
  //  because select lists are functionally usually sorted by type.
  clearCache: false,
  // Is this a general action (like create new object), or one that requires an objecft
  // (like editing an existing object)?
  selectionRequired: false,
  // Can this action be performed on multiple items at same time?
  multiple: false,
  // Should this action be collapsed when there is no room,
  // or kept front and center default true
  //  String, function or boolean
  collapse: 'xs',
  // Confirm action before going ahead? False or text representing confirmation text
  confirm: false,
  // Function takeing the object in question as parameter, returning either
  //  true or false whether the
  //  the button should be available. ie: (quote) => quote_status === 'k';
  //  would show only when the quote
  //  is not an aseembly
  visible: () => true,
  // To make this a parent option, and give it sub-options
  // like Share -> Link, Email etc. then add
  //  action objects into the options array.  This is an array, and
  // not an object like the top level
  //  actions object.
  options: [],
  // If editing already, do show this action.
  //  ie: the edit action should have editing = false,
  //  because when you are editing the object,
  //  this should not be visible
  editing: true,
  // INT or BOOLEAN
  badge: false
}

const getActions = (type) => {
  const typeTitle = _.titleCase(type)
  const constructor = getConstructor(type)
  if (!constructor) return {}
  const actionDefaults = defaultAction
  const defaultActions = {
    // create: {
    //   icon: 'plus',
    //   class: 'info',
    //   text: 'Create',
    //   clearCache: true,
    //   action: `${typeTitle}/create`,
    //   selectionRequired: false,
    //   collapse: false,
    //   editing: false,
    // },
    edit: {
      icon: constructor.glyph || 'pencil',
      class: 'default',
      text: 'Edit/view',
      action: `${typeTitle}/edit`,
      selectionRequired: true,
      collapse: false,
      editing: false
    },
    delete: {
      icon: 'trash',
      class: 'default',
      text: 'Delete',
      clearCache: true,
      action: `${typeTitle}/delete`,
      confirm: `Are you sure you want to delete this ${typeTitle}?`,
      selectionRequired: true,
      collapse: 'sm',
      multiple: true
    },
    duplicate: {
      icon: 'clone',
      class: 'default',
      text: 'Duplicate',
      clearCache: true,
      action: `${typeTitle}/duplicate`,
      selectionRequired: true,
      collapse: 'sm'
    },
    changeStatus: {
      icon: 'share',
      class: 'default',
      text: 'Change status',
      clearCache: true,
      selectionRequired: true,
      collapse: 'sm',
      multiple: true,
      visible: () => constructor.possibleStatuses,
      options: (constructor.possibleStatuses || []).map((s) => ({
        text: statuses[s],
        selectionRequired: true,
        action: ({ dispatch }, payload) => {
          const newPayload = {
            ...payload,
            selected: payload.selected.map((o) => ({
              [`${type}_id`]: o[`${type}_id`],
              type
            })),
            markAs: statuses[s].replace(' ', '')
          }
          return dispatch(`${typeTitle}/markMultiple`, newPayload)
        }
      }))
    }
    // setDefault: {
    //   icon: 'thumbtack',
    //   class: 'default',
    //   text: 'Set this as default',
    //   clearCache: true,
    //   selectionRequired: true,
    //   collapse: true,
    //   multiple: false,
    //   visible: ([s]) => `${type}_is_default` in constructor.fields && !s[`${type}_is_default`],
    //   action: `${typeTitle}/makeDefault`,
    // },
    // unsetDefault: {
    //   icon: 'thumbtack',
    //   class: 'default',
    //   text: 'Unset this as default',
    //   clearCache: true,
    //   selectionRequired: true,
    //   collapse: true,
    //   multiple: false,
    //   visible: ([s]) => `${type}_is_default` in constructor.fields && s[`${type}_is_default`],
    //   action: `${typeTitle}/makeDefault`,
    // },
  }

  const constructorActions = constructor.actions || {}

  const actions = _.merge(constructorActions, defaultActions)

  // Make sure all actions have all options, from defaults
  Object.keys(actions).forEach((action) => {
    actions[action] = {
      ...actionDefaults,
      ...actions[action],
      options: (actions[action].options || []).map((o) => ({
        ...actionDefaults,
        ...o
      }))
    }
  })

  let sortedActions = {}
  ;(Object.keys(actions) || [])
    .map((k) => [actions[k], k])
    .sort((a, b) => a[0].selectionRequired - b[0].selectionRequired)
    .forEach((a) => {
      sortedActions = {
        ...sortedActions,
        [a[1]]: a[0]
      }
    })

  return sortedActions
}

const getFilterFields = (type) => {
  const constructor = getConstructor(type)
  return Object.keys(constructor.fields).filter(
    (field) =>
      (typeof constructor.fields[field].filter === 'undefined' &&
        !/(^(tax_id|parent_).*?)|(.*?(mod_id|file_id|qbexport_id|country_id|company_id|docusign_envelope_id|_ids|template_id)$)/.test(
          field
        ) &&
        new RegExp(
          `(${constructor.type}.*?_(time_\\w+|status|name|net|gross|owner|creator|count|sum)|.*?_id)$`
        ).test(field)) ||
      constructor.fields[field].filter
  )
}

/**
 * Prune a denormalized object
 * @param object
 * @param type
 * @param providedFieldKeys
 * @returns {{}}
 */
const getPrunedObject = (object, type, providedFieldKeys = null) => {
  const requiredFields = ['type', 'refId', 'parentRefId', 'full']

  const fields = [
    ...requiredFields,
    ...(providedFieldKeys || Object.keys(getConstructor(type).fields))
  ]

  const pruned = {}

  fields.forEach((field) => {
    if (field in object) pruned[field] = object[field]
  })

  return pruned
}

/**
 * Prune a normalized set
 * @param norm
 * @returns {{}}
 */
const prune = (norm) => {
  const fieldKeys = {}

  const pruned = {}

  const refs = Object.keys(norm)

  refs.forEach((refId) => {
    const type = norm[refId].type

    if (!fieldKeys[type]) fieldKeys[type] = Object.keys(getConstructor(type).fields)

    const keys = fieldKeys[type]

    pruned[refId] = getPrunedObject(norm[refId], type, keys)
  })

  return pruned
}

const getFieldFormatType = (type, field) => {
  const schema = getConstructor(type)?.fields?.[field] ?? null
  if (!schema) return null

  if ('format' in schema) {
    return schema.format === false ? null : schema.format
  } else if (/_time(_|$)/.test(field)) {
    return 'datetime'
  } else if (/_(percent|percentage)(_|$)/.test(field)) {
    return 'percentage'
  } else if (/_hours($|_)/.test(field)) {
    return 'hours'
  } else if (/_(net|gross|tax)(_|$)/.test(field)) {
    return 'currency'
  } else if (/_status$/.test(field)) {
    return 'status'
  } else if (/_is_|_has_|_show_|_link_/.test(field)) {
    return 'boolean'
  } else if (/_html($|_)/.test(field)) {
    return 'html'
  }
  return null
}

export default {
  getConstructor,
  constructorFromType: getConstructor,
  buildDefaultObject,

  // Utilities
  isJsonField,
  enforce,
  getFieldTitle,
  getFieldName: getFieldTitle,
  shouldMapField,
  getFilterFields,
  getDefaultFromField,
  shouldTrackChanges,
  getId,
  prune,
  pruneNormalized: prune,

  // References
  statuses,
  creatableObjects,
  editableObjects,
  statusColors,
  objectList,
  defaultAction,

  // Helpers
  getActions,
  getFieldFormatType
}
