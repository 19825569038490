<template>
  <!-- Profit input fields-->
  <card-list>
    <card-list-field>
      <div>
        <span> Default profit </span>
        <small>Default profit percentage for new items.</small>
      </div>
      <field v-model="changeDefaultMarkup" format="number" class="!ml-4 !max-w-16" />
      <span class="ml-4">%</span>
    </card-list-field>
    <card-list-field>
      <div>
        <span> Minimum profit </span>
        <small>If an item will make less than this amount of profit, a warning will appear.</small>
      </div>
      <field v-model="changeMinimumMargin" format="number" class="!ml-4 !max-w-16" />
      <span class="ml-4">%</span>
    </card-list-field>
  </card-list>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import eventBus from '@/eventBus'

export default {
  name: 'CompanyMarkupAdjuster',
  mixins: [ObjectManipulator('company', false /* track changes? */), BodyMixin],
  props: {
    object: Object,
    deselectOnDestroy: {
      default: false
    }
  },
  computed: {
    changeMinimumMargin: {
      get() {
        return Number(this.company_minimum_quote_margin) * 100
      },
      set(val) {
        console.log(val)
        this.company_minimum_quote_margin = Number(val) / 100
      }
    },
    changeDefaultMarkup: {
      get() {
        return (1 - 1 / c.toNum(this.company_default_markup)) * 100
      },
      set(val) {
        const perc = c.toNum(val) / 100
        this.company_default_markup = 1 / (1 - perc)
      }
    }
  },
  created() {
    eventBus.$on(`${this.uid}-selected`, () => {
      this.company_default_markup = this.company_default_markup || this.$store.getters.defaultMarkup
      this.company_minimum_quote_margin = this.company_minimum_quote_margin || 0.2
    })
  }
}
</script>
