import { toRefs, reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import useTranslation from './Translation'
import usePayment from './Payment'

export const paymentMethodHash = {
  check: 1,
  card: 2,
  scheme: 2,
  'card-keyed': 3,
  cash: 4,
  'debit-card': 5,
  'bank-transfer': 6,
  'bank-account': 6,
  eft: 6,
  ach: 6,
  'bank-draft': 7,
  other: 8,
  manual: 9
}

export const initialState = {
  paymentMethods: {},
  paymentMethod: null,
  creditCards: [],
  bankAccounts: [],
  specialtyCards: [],
  paymentMethodVariants: [],
  locale: 'en-US',
  brand: null,
  verification3D: false,
  validationErrorMessage: '',
  newBankAccount: null,
  addingNewBankAccount: false,
  selectedPaymentType: 'ach'
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const { l } = useTranslation()
  const store = useStore()
  const { fees, isValid, checkout } = usePayment()

  const savedPaymentMethods = computed(() => [...context.bankAccounts, ...context.creditCards])

  const defaultPaymentMethod = computed(() =>
    savedPaymentMethods.value && savedPaymentMethods.value.length > 0
      ? savedPaymentMethods.value[0]
      : null
  )

  watch(defaultPaymentMethod, (defaultMethod) => {
    if (defaultMethod) {
      if (defaultMethod.type === 'ach') {
        context.paymentMethod = {
          type: 'bank-account',
          account: {
            name: defaultMethod.name,
            mask: defaultMethod.bankAccountNumber.slice(-4)
          }
        }
        checkout.value.paymentMethod = defaultMethod
        checkout.value.isValid = true
      } else {
        context.paymentMethod = defaultMethod
        checkout.value.paymentMethod = defaultMethod
      }
    }
  })

  const hasDefaultPaymentMethod = computed(() => !!defaultPaymentMethod.value)

  const hasSelectedPaymentMethod = computed(() => !!context.paymentMethod)

  const type = computed(() =>
    checkout.value.paymentMethod ? checkout.value.paymentMethod.type : null
  )

  const alias = computed(() => {
    if (['card', 'schema'].includes(type.value)) return 'card'
    if (['account', 'bank-account', 'bank_account', 'eft', 'ach'].includes(type.value)) return 'ach'
    return 'card'
  })

  const fetchPaymentMethods = async (invoiceId, amount) => {
    try {
      const response = await store.dispatch('ajax', {
        path: '/Payment_Method/getPaymentMethods',
        data: {
          invoice_id: invoiceId,
          amount
        }
      })

      if (!response || !response.payload) throw new Error()
      return response.payload
    } catch (e) {
      store.dispatch('alert', {
        message: e || l('Could not fetch payment methods, please try again'),
        error: true
      })
      return null
    }
  }

  const allowedPaymentMethods = computed(
    () =>
      context.paymentMethods.reduce((acc, method) => {
        if (method.type === 'scheme') acc.push('card')
        if (method.type === 'ach') acc.push('ach')
        const country = store.state.session.company.country_abbr
        if (method.type === 'eft_directdebit_CA' && country === 'ca') acc.push('eft')
        return acc
      }),
    []
  )

  const getFeeBasedOnCardBrand = (brand) => {
    if (context.specialtyCards.includes(brand)) {
      return fees.value.specialtyCards
    }
    return fees.value.standardCards
  }

  const removeStoredPaymentMethod = async (paymentMethodId) => {
    try {
      const res = await store.dispatch('ajax', {
        path: '/invoice/deleteStoredPaymentMethod',
        data: {
          stored_payment_method_id: paymentMethodId
        }
      })
      return res
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || l('Could not delete payment method. Please try again'),
        error: true
      })
      return false
    }
  }

  const onSelectNewAccount = ({ publicToken, metadata }) => {
    const { account } = metadata
    context.paymentMethod = {
      type: context.selectedPaymentType,
      public_token: publicToken,
      account
    }
    checkout.value.paymentMethod = { ...context.paymentMethod }
    context.newBankAccount = {
      type: 'bank_account',
      bank_name: account.name,
      last4: account.mask
    }
    checkout.value.isValid = true
    isValid.value = true
  }

  const onSelectedBankAccount = (id) => {
    const bankAccount = context.bankAccounts.find((f) => f.id === id)
    context.paymentMethod = {
      account: {
        name: bankAccount.name,
        mask: bankAccount.bankAccountNumber.slice(-4)
      },
      type: 'bank-account'
    }
    checkout.value.paymentMethod = {
      type: bankAccount.type,
      recurringDetailReference: bankAccount.id
    }
    checkout.value.isValid = true
    isValid.value = true
    context.newBankAccount = null
  }

  const getCreditCardIcon = (brand) => {
    let icon = 'visa'
    switch (brand) {
      case 'mc':
        icon = 'mastercard'
        break
      case 'visa':
        icon = 'visa'
        break
      case 'discover':
        icon = 'discover'
        break
      case 'diners':
        icon = 'diners-club'
        break
      case 'amex':
        icon = 'amex'
        break
      default:
        icon = 'visa'
        break
    }
    return icon
  }

  return {
    ...toRefs(context),
    fetchPaymentMethods,
    getFeeBasedOnCardBrand,
    removeStoredPaymentMethod,
    onSelectNewAccount,
    onSelectedBankAccount,
    getCreditCardIcon,
    allowedPaymentMethods,
    savedPaymentMethods,
    defaultPaymentMethod,
    hasDefaultPaymentMethod,
    hasSelectedPaymentMethod,
    paymentMethodHash,
    alias,
    type
  }
}
