<template>
  <MiniModal
    v-bind="$props"
    ref="modal"
    size="md"
    :mask="true"
    :clickAway="false"
    :expandable="false"
    :show-close-button="false"
    :closeable="false"
  >
    <!-- <PaymentMethodNew
      ref="body"
      :key="modal.company_id"
      :id="modal.company_id" /> -->
  </MiniModal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
// import PaymentMethodNew from '../billing/PaymentMethodNew';

export default {
  name: 'ModalPaymentMethodNew',
  mixins: [ObjectModalMixin],
  components: {
    // PaymentMethodNew,
  }
}
</script>
