<template>
  <Container class="product-management-container" size="5xl">
    <div v-if="products.length && (isAdmin || isSuperUser)" class="product-management-card-list">
      <FeatureCard
        v-for="product in products"
        :key="product.moduleId"
        :product="product"
        :companyId="companyId"
      />
    </div>
  </Container>
</template>

<script>
import FeatureCard from '@/components/ui/Cards/FeatureCard.vue'
import AutoCostImage from '../../assets/auto-cost-background.png'

export default {
  name: 'FeatureManagement',
  data() {
    return {
      loading: 0,
      products: []
    }
  },
  computed: {
    isAmerican() {
      const session = this.$store.state.session
      return this.companyIsAmerican || (session.company && session.company.country_id === 2)
    },
    isAdmin() {
      const session = this.$store.state.session
      return session.user && session.user.user_is_admin === 1
    },
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    }
  },
  methods: {
    addAmericanProducts() {
      const americanProducts = [
        {
          name: 'AutoCost',
          desc: 'Automate pricing for 130,000+ construction materials.',
          moduleId: 30,
          image: AutoCostImage,
          monthlyCost: '99',
          yearlyCost: '899',
          freeTrialDays: 45
        }
      ]

      this.products.push(...americanProducts)
    }
  },
  props: {
    companyIsAmerican: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  mounted() {
    if (this.isAmerican) this.addAmericanProducts()
  },
  components: {
    FeatureCard
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.product-management-container {
  padding-top: 2em !important;
}

.product-management-card-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  @media (max-width: 576px) {
    justify-content: center;
  }
}

.product-management-card {
}

.product-management-card-title {
  font-size: 1.3em;
  margin-bottom: 0.3em;
}

.product-management-card-desc {
  font-size: 0.9em;
  color: $cool-gray-700;
}
</style>
