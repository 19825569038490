<template>
  <div class="loading-curtain">
    <div id="particles-background" class="vertical-centered-box"></div>
    <div id="particles-foreground" class="vertical-centered-box"></div>

    <div class="vertical-centered-box">
      <template v-if="!progress">
        <div class="content">
          <div class="loader-circle"></div>
          <div class="loader-line-mask">
            <div class="loader-line"></div>
          </div>
          <img src="@/assets/logos/Bolster_Icon_White.png" alt="Bolster" class="size-16" />
        </div>
      </template>
      <template v-else>
        <div class="global-loading-container">
          <Progress :progress="progress" :size="4" />
          <div>{{ loadingMessage }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Progress from '../ui/Progress.vue'

export default {
  name: 'LoadingCurtain',
  components: { Progress },
  computed: {
    progress() {
      return c.divide(
        this.$store.state.session.progress,
        this.$store.state.session.progress + this.$store.state.session.loading
      )
    },
    loadingMessage() {
      return this.$store.state.session.loadingMessage
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.loading-curtain {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $pitch-black;
  z-index: $z-tooltip + 1;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: 'proxima-nova-soft', sans-serif;
  overflow: hidden;

  .vertical-centered-box {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .content {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      font-size: 0;
      height: 65px;
    }
  }
}

* {
  transition: all 0.3s;
}
// END: TEMPLATE

body {
  background: mix(#3f3251, #002025, 70%);
}

#prism-logo {
  path {
    // fill: #20293b;
    // stroke: rgba(255,255,255,1);
    // stroke-width: 1px;
  }
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
  // .animation(fade 1.2s infinite ease-in-out);
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;
  .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

#particles-background,
#particles-foreground {
  left: -51%;
  top: -51%;
  width: 202%;
  height: 202%;
  transform: scale3d(0.5, 0.5, 1);
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cls-1 {
  fill: #fff;
}

.global-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto auto;
  flex-direction: column;
  color: $flame-white;
  font-weight: 500;
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
}
</style>
