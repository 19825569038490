import { ref, onBeforeUnmount, onMounted, getCurrentInstance, computed } from 'vue'

export default {
  useColumnOptions(args) {
    const { getColLabel } = args

    const showColumnButton = ref(false)
    const columnButtonLabel = ref('Column')
    const columnButtonPosition = ref({ x: 0, y: 0, width: 0 })
    const columnButtonStyle = computed(() => ({
      left: `${(columnButtonPosition.value.x ?? 0) + 2}px`,
      top: `${(columnButtonPosition.value.y ?? 0) + 4}px`,
      width: `${(columnButtonPosition.value.width ?? 0) - 6}px`
    }))
    const actionColumn = ref(null)
    const actionSheet = ref(null)

    const handleMouseoverColHeading = (eventData) => {
      const { sheetIndex } = eventData.element
      const { col, width, y, x } = eventData.element.position

      columnButtonLabel.value = getColLabel(col, sheetIndex)
      showColumnButton.value = true
      actionColumn.value = col
      actionSheet.value = sheetIndex

      $this.$nextTick(() => {
        columnButtonPosition.value = {
          x: x,
          y: y,
          width
        }
      })
    }

    const handleMouseoutColHeading = () => {
      showColumnButton.value = false
    }

    const $this = getCurrentInstance().proxy
    onMounted(() => {
      $this.$on('mouseoverColHeading', handleMouseoverColHeading)
      $this.$on('mouseoutColHeading', handleMouseoutColHeading)
    })

    onBeforeUnmount(() => {
      $this.$off('mouseoverColHeading', handleMouseoverColHeading)
      $this.$off('mouseoutColHeading', handleMouseoutColHeading)
    })

    return {
      showColumnButton,
      columnButtonLabel,
      columnButtonPosition,
      actionColumn,
      actionSheet,
      columnButtonStyle
    }
  }
}
