<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="full"
    :full="true"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
  >
    <template #body>
      <Container v-if="normalized">
        <QuoteSummary
          :normalized="normalized"
          :rootRefId="rootRefId"
          :reference="rootRefId"
          :show-document-options="true"
          :presentation-settings-overrides="presentationSettings"
        />
      </Container>
      <LoadingIndicator v-else />
    </template>
  </modal>
</template>

<script>
import ModalMixin from './ModalMixin'
import LoadingIndicator from '../ui/LoadingIndicator.vue'

export default {
  name: 'ModalQuoteDocumentViewer',
  components: { LoadingIndicator },
  mixins: [ModalMixin],

  data() {
    return {
      normalized: null,
      rootRefId: null,
      presentationSettings: {}
    }
  },

  async mounted() {
    const id = this.modal.id
    // Can be change_order as well as quote
    const type = this.modal.type || 'quote'
    const presentationId = this.modal.presentationId || null

    let normalized
    let rootRefId
    if (this.modal.normalized && this.modal.rootRefId) {
      normalized = this.modal.normalized
      rootRefId = this.modal.rootRefId
    } else {
      const { normalized: fnorm, rootRefId: frootref } = await this.$store.dispatch(
        `${c.titleCase(type)}/fetchNormalized`,
        {
          id
        }
      )
      normalized = fnorm
      rootRefId = frootref
    }

    if (presentationId) {
      const { object: template } = await this.$store.dispatch('Template/fetch', {
        id: presentationId
      })
      this.presentationSettings = template.oMeta
    }

    if (this.modal.presentationSettings) {
      this.presentationSettings = {
        ...this.presentationSettings,
        ...(this.modal.presentationSettings || {})
      }
    }

    this.rootRefId = rootRefId
    this.normalized = normalized
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
