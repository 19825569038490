<template>
  <SelectButton
    :pt="pt"
    v-model="localValue"
    :options="options"
    option-label="text"
    option-value="value"
    :multiple="multiple"
    :allowEmpty="false"
  >
    <template #option="{ option }">
      <font-awesome-icon v-if="option.icon" :icon="option.icon" class="mr-2" /><span>{{
        option.text
      }}</span>
    </template>
  </SelectButton>
</template>

<script setup>
import { defineEmits, defineProps, computed } from 'vue'
import SelectButton from 'primevue/selectbutton'

SelectButton.compatConfig = { MODE: 3, ATTR_FALSE_VALUE: false }

const emit = defineEmits(['input'])
const props = defineProps({
  pt: {
    default: null
  },
  options: {
    type: Array,
    required: true
  },
  value: {
    required: true
  },
  default: {
    required: false
  },
  multiple: {
    default: false,
    required: false
  }
})

const localValue = computed({
  get() {
    return props.value
  },
  set(newValue) {
    emit('input', newValue)
  }
})
</script>
