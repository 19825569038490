import { usePushNotifications } from '@/components/composables/PushNotifications'
import { useStore } from 'vuex'
import { watch, computed } from 'vue'

export const useActivityChatPushNotifications = () => {
  // ======== Composables ======== //
  const store = useStore()
  const { initPushNotifications } = usePushNotifications()
  // ====================== //

  // ======== Computed ======== //
  const isLoggedIn = computed(() => store.state.session.isLoggedIn)
  // ====================== //

  // ======== Methods ======== //
  const saveActivityChatPushNotificationSubscription = (subscription) => {
    const userId = store.state.session.authorizedUser?.user_id || store.state.session.user.user_id
    return store.dispatch('activityChat/postPushNotificationSubscription', {
      userId,
      subscription
    })
  }
  const initActivityChatPushNotifications = async () => {
    const subscription = await initPushNotifications()
    await saveActivityChatPushNotificationSubscription(subscription)
  }
  // ====================== //

  // ======== Watchers ======== //
  watch(isLoggedIn, (val) => {
    if (val) {
      initActivityChatPushNotifications()
    }
  })
  // ====================== //
}
