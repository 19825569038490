import { computed, ref, watchEffect } from 'vue'

import DrawUtilities from './DrawUtilities'

const { initializeCanvas } = DrawUtilities

export default {
  useCanvas(args) {
    const { canvas } = args

    watchEffect(() => {
      if (!canvas.value || !canvas.value.getContext) return
      mainCtx.value = canvas.value.getContext('2d')
      initializeMainCanvas()
    })

    const container = ref(null)
    const mainCtx = ref(null)
    const canvasWidth = ref(800)
    const canvasHeight = ref(800)
    const pixelRatio = ref(window.devicePixelRatio || 1)
    const scaledRatio = computed(() => pixelRatio.value)

    const initializeMainCanvas = () => {
      const mainCanvas = canvas.value
      container.value = mainCanvas.parentNode

      const { top, left, bottom, right } = getSizing()

      canvasHeight.value = bottom - top
      canvasWidth.value = right - left
      pixelRatio.value = window.devicePixelRatio || 1

      const { ctx: initCtx } = initializeCanvas(mainCanvas, canvasWidth.value, canvasHeight.value)

      mainCtx.value = initCtx
    }

    const getSizing = () => {
      const scrollParent = c.getSizedParent(container.value)
      const { top, left, bottom, right } = scrollParent.getBoundingClientRect()
      // throw new Error()

      return {
        top,
        left,
        bottom,
        right
      }
    }

    return {
      initializeMainCanvas,
      mainCtx,
      canvasWidth,
      canvasHeight,
      scaledRatio,
      pixelRatio
    }
  }
}
