<template>
  <Container v-if="!bodyLoading">
    <CardSection>
      <template #title>Webhook</template>
      <CardList class="mb-2">
        <CardListField>
          <Help>
            <template #before>Reference name</template>
            <p>Enter a friendly reference name of your choice for your custom webhook.</p>
          </Help>

          <Field
            schema="webhook:webhook_name"
            v-model="webhook_name"
            :validate="{ required: true }"
          />
        </CardListField>

        <CardListField>
          <Help>
            <template #before>HTTP Endpoint</template>
            <p>Enter a fully qualified URL that will receive the details of this endpoint.</p>
          </Help>

          <Field
            placeholder="https://yourdomain.com/webhook/endpoint"
            schema="webhook:webhook_endpoint"
            v-model="webhook_endpoint"
            :validate="{ required: true }"
          />
        </CardListField>

        <CardListField>
          <span>
            Webhook event triggers
            <small class="text-info"> Choose events that will trigger the webhook. </small>
          </span>

          <choose
            :return-array="false"
            schema="webhook:webhook_event"
            :allowDeselect="false"
            :multiple="true"
            v-model="webhook_event"
            :staticSet="eventOptions"
            :validate="{ required: true }"
          />
        </CardListField>

        <CardListField>
          <span>Status</span>
          <choose
            :return-array="false"
            schema="webhook:webhook_status"
            :allowDeselect="false"
            v-model="webhook_status"
            :default="'a'"
            :staticSet="statusOptions"
          />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection v-if="webhook_id">
      <template #title>Log</template>
      <CardList v-if="aoLog && aoLog.length">
        <CardListCollapse :cloak="true" v-for="(log, index) in aoLog" :key="index">
          <template #title>
            <font-awesome-icon icon="exclamation-circle" class="danger" v-if="log.payload.error" />
            <font-awesome-icon icon="check" v-else />
            <span v-if="log.payload.response.status_code"
              >&nbsp;{{ log.payload.response.status_code }}&nbsp;-&nbsp;</span
            >
            {{ $f.datetime(log.time) }}&nbsp;-&nbsp;
            <span v-if="log.payload.response.message">
              {{ $f.truncate(log.payload.response.message, 30) }}&nbsp;
            </span>
            ({{ log.payload.type }} {{ log.payload.name }})
          </template>

          <template v-if="log.payload.response">
            <div v-if="log.payload.response.status_code">
              Status code: {{ log.payload.response.status_code }}
            </div>
            <div v-if="log.payload.response.message">
              Message: {{ log.payload.response.message }}
            </div>
            <div v-if="log.payload.response.body">
              Response:
              <pre>{{ log.payload.response.body }}</pre>
            </div>
          </template>
        </CardListCollapse>
      </CardList>
      <Warning v-else>
        <template #title>No webhooks sent yet</template>
        <p>Trigger this webhook first to see a log of responses.</p>
      </Warning>
    </CardSection>

    <Btn class="lg round info block" :action="saveAndClose" :disabled="!isDirty"> Save </Btn>
  </Container>
</template>

<script>
import BodyMixin from '@/components/mixins/Body'
import ObjectManipulator from '../mixins/ObjectManipulator'

export default {
  name: 'WebhookEdit',
  mixins: [ObjectManipulator('webhook', true), BodyMixin /* AutoSave */],

  props: {
    deselectOnDestroy: {
      default: true
    }
  },

  watch: {},

  data() {
    return {
      statusOptions: [
        {
          text: 'Active',
          value: 'a'
        },
        {
          text: 'Inactive',
          value: 'i'
        }
      ],
      eventOptions: [
        {
          text: 'Change Order→Created',
          value: 'change_order.Created'
        },
        {
          text: 'Change Order→Status Changed',
          value: 'change_order.StatusChanged'
        },
        {
          text: 'Change Order→Updated',
          value: 'change_order.Updated'
        },
        {
          text: 'Change Order→Viewed',
          value: 'change_order.Viewed'
        },
        {
          text: 'Client→Assigned',
          value: 'client.Assigned'
        },
        {
          text: 'Client→Created',
          value: 'client.Created'
        },
        {
          text: 'Client→Lead Received',
          value: 'client.LeadReceived'
        },
        {
          text: 'Client→Status Changed',
          value: 'client.StatusChanged'
        },
        {
          text: 'Client→Updated',
          value: 'client.Updated'
        },
        {
          text: 'Invoice→Created',
          value: 'invoice.Created'
        },
        {
          text: 'Invoice→Status Changed',
          value: 'invoice.StatusChanged'
        },
        {
          text: 'Invoice→Updated',
          value: 'invoice.Updated'
        },
        {
          text: 'Quote→Created',
          value: 'quote.Created'
        },
        {
          text: 'Quote→Status Changed',
          value: 'quote.StatusChanged'
        },
        {
          text: 'Quote→Updated',
          value: 'quote.Updated'
        },
        {
          text: 'Quote→Upgraded',
          value: 'quote.Upgraded'
        },
        {
          text: 'Transaction→Created',
          value: 'transaction.Created'
        },
        {
          text: 'Transaction→Deleted',
          value: 'transaction.Deleted'
        },
        {
          text: 'Transaction→Status Changed',
          value: 'transaction.StatusChanged'
        }
      ]
    }
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
