<template>
  <Section class="card-section container-element" :id="id" :data-label="id">
    <div class="card-label !mt-0" v-if="$slots.label || $slots.title">
      <span class="ml-2 mb-3 text-xl font-medium" v-if="$slots.title">
        <slot name="title"></slot>
      </span>
      <span class="card--title-buttons">
        <slot name="titleButtons"></slot>
      </span>
      <span class="ml-2 mb-3 text-xl font-medium" v-if="$slots.label">
        <slot name="label"></slot>
      </span>
      <div class="card-subtitle" v-if="$slots.subtitle || $slots.subLabel">
        <slot name="subtitle"></slot>
        <slot name="subLabel"></slot>
      </div>
    </div>

    <div
      class="card-section--inner container-element"
      :class="[$slots.info ? 'flex !flex-row' : 'gap-4']"
    >
      <slot></slot>
      <slot name="info"></slot>
    </div>

    <div class="card-section-footer container-element" v-if="$slots.footer || $slots.footing">
      <slot name="footer"></slot>
      <slot name="footing"></slot>
    </div>
  </Section>
</template>

<script>
import Section from '../Section.vue'
/**
 * MODIFIER CLASSES
 * -.muted
 */
export default {
  name: 'CardSection',
  components: { Section },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    const slots = this.$slots.label
      ? this.$slots.label()
      : this.$slots.title
        ? this.$slots.title()
        : null
    if (!slots) return
    const slot = c.makeArray(slots)
    this.id = slot && slot.length && slot[0] && slot[0].elm && c.idFromText(slot[0].elm.innerText)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
section.section--container.card-section {
  align-items: flex-start;

  &.p-0,
  &.m-0 {
    margin: 0;
    padding: 0 !important;
  }

  .card-section--label,
  .card-section--title {
    color: $primary-700;
    font-weight: 500;
    font-size: 0.9em;
    > span {
      display: flex;
    }
  }

  .card-section--inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    > section.section--container.card-section {
      margin: 0;
      padding: 0 !important;
    }
  }

  .card-section-footer {
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    max-width: 100%;
    width: 100%;
    margin: 1rem 0 0 0.5em !important;

    /*row-gap: 0.5rem;*/
    > *:not(:last-child) {
      margin-bottom: 0.5rem !important;
    }
  }

  .card-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    max-width: 100%;

    .card--title-buttons {
      font-size: 1rem;
    }

    .card-subtitle {
      margin-top: 0.25em;
      clear: both;
      display: block;
      font-size: 0.6em;
      font-weight: bold;
      color: $cool-gray-600;
      line-height: 1.3;
    }
  }

  &.muted {
    .card-label {
      > * {
        font-size: 0.8em;
        color: $cool-gray-600;
      }
    }
  }

  &.sm {
    font-size: 0.8em;
    .title,
    .label {
      font-size: 0.6em;
    }
  }
  &.xs {
    font-size: 0.6em;
    .title,
    .label {
      font-size: 0.5em;
    }
  }

  &.center {
    .card-label {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .card-section-footer {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

#app.small-format {
  .cc-container {
    > .card-section .card-section--inner {
      > :not(.card):not(.see-more-container):not(.card-section):not(.container-element) {
        margin-right: 1rem;
        margin-left: 1rem;
        width: calc(100% - 2rem);
      }
    }
  }

  .card-section {
    > .card-label {
      font-size: 1.5em;
      margin-right: 1rem;
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    > :not(div):not(.card-label) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
    }
  }
}
</style>
