<template>
  <div class="summary-container">
    <div class="summary-avatar">
      <slot name="avatar"></slot>
    </div>
    <div class="summary-text">
      <div class="summary-firstname">
        <slot name="firstname"></slot>
      </div>
      <div class="summary-lastname" v-if="$slots.lastname">
        <slot name="lastname"></slot>
      </div>
      <div class="summary-company" v-if="$slots.company">
        <slot name="company"></slot>
      </div>
    </div>
    <div class="summary-button" v-if="$slots.button">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectSummary'
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.summary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  overflow: visible;
  width: 100%;

  .summary-avatar {
    margin-right: 0.25em;
    display: flex;
    align-items: center;
  }

  .summary-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .summary-firstname {
      font-size: 1.1em;
      font-weight: bold;
      color: $cool-gray-600;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .summary-lastname {
      font-size: 0.9em;
      font-weight: normal;
      color: $cool-gray-600;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .summary-company {
      margin-top: 0.25em;
      font-size: 0.5em;
      font-weight: bold;
      color: $cool-gray-400;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .summary-button {
    margin-left: 0.5em;
    font-size: 0.75em;
  }
}
</style>
