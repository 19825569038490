export default {
  type: 'csi_code',

  skipAudit: true,

  fields: {
    csi_code_id: {
      type: 'int',
    },
    csi_code_code: {
      type: 'string',
    },
    csi_code_name: {
      type: 'string',
    },
    csi_code_status: {
      type: 'string',
    },
    csi_code_time_created: {
      type: 'int',
    },
    csi_code_time_last_modified: {
      type: 'int',
    },
    csi_code_creator: {
      type: 'int',
      filter: true,
      format: false,
    },
    company_id: {
      type: 'int',
    },
  },
};