<template>
  <div>
    <div class="grid grid-cols-2 gap-2">
      <Card @click="subType = 'user'" :selected="subType === 'user'" :button="true" class="h-full">
        <FontAwesomeIcon class="text-xl" :icon="['far', 'user']" />
        <h3>New user</h3>
      </Card>
      <Card
        @click="subType = 'vendor'"
        :selected="subType === 'vendor'"
        :button="true"
        class="h-full"
      >
        <FontAwesomeIcon class="text-xl" :icon="['far', 'truck']" />
        <h3>New vendor/subcontractor</h3>
      </Card>
    </div>

    <CardSection>
      <CardList>
        <CardListField>
          <span>Email</span>

          <field
            focusOnMount
            schema="user:user_email"
            @valid="emailValidHandler"
            @invalid="emailInvalidHandler"
            @enter="$refs.submit.submit()"
            :validate="{
              type: 'email',
              required: 1
            }"
            :emit-delay="100"
            v-model="email"
          />
        </CardListField>
        <CardListField v-if="subType === 'vendor'">
          <span>Crew size</span>
          <field type="number" schema="vendor:vendor_crew_size" v-model="vendorCrewSize" />
        </CardListField>
      </CardList>
    </CardSection>

    <Btn
      class="w-full"
      size="lg"
      :action="saveAssignee"
      :disabled="!emailValid || !email"
      ref="submit"
    >
      {{ emailValid || !email ? 'Save' : 'Enter valid email' }}
    </Btn>
  </div>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import Field from '@/components/ui/Field.vue'
import eventBus from '@/eventBus.js'
import FontAwesomeIcon from '@/fontAwesome'

export default {
  name: 'AssigneeNew',
  components: { Field, FontAwesomeIcon },
  mixins: [
    ObjectManipulator(
      'assignee' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],

  methods: {
    async saveAssignee() {
      this.$emit('saving')
      eventBus.$emit(`${this.uid}-saving`)
      eventBus.$emit(`saving-${this.type}-${this.refId}`)
      eventBus.$emit(`saving-${this.type}`)

      let assigneeId
      if (this.subType === 'user') {
        // Check if user exists
        let { user } = await this.$store.dispatch('User/createFromEmail', {
          email: this.email
        })

        assigneeId = `user:${user.user_id}`
        // If not exists, create
        // Add to company
      } else {
        // Check if company exists
        let { vendor } = await this.$store.dispatch('Vendor/createFromEmail', {
          email: this.email
        })

        // update crew size of vendor
        if (this.vendorCrewSize > 1) {
          await this.$store.dispatch('Vendor/partialUpdate', {
            selected: [
              {
                type: 'vendor',
                vendor_id: vendor.vendor_id,
                vendor_crew_size: this.vendorCrewSize
              }
            ]
          })
        }

        assigneeId = `vendor:${vendor.vendor_id}`
      }

      this.$emit('saved', { reset: true, go: false, object: { assignee_id: assigneeId } })
      eventBus.$emit(`${this.uid}-saved`)
      eventBus.$emit('saved', { object: { assignee_id: assigneeId } })
      eventBus.$emit(`saved-${this.type}-${this.refId}`)
      eventBus.$emit(`saved-${this.type}`)
      this.$emit('close')
      this.$emit('closeModal')
    },
    emailValidHandler() {
      this.emailValid = true
    },
    emailInvalidHandler() {
      this.emailValid = false
    }
  },
  data() {
    return {
      subType: 'user',
      email: '',
      emailValid: false,
      vendorCrewSize: 1
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
