import { ref } from 'vue'
import eventBus from '@/eventBus.js'
import { useStore } from 'vuex'

export default {
  useSaving(payload) {
    const {
      refId: initialRefId,
      isDirty,
      type,
      storeName,
      save: providedSave = null,
      refChanges,
      forceSave = false
    } = payload

    const refId = ref(initialRefId)
    const $store = useStore()

    const defaultSave = async () => {
      eventBus.$emit(`saving-${type.value}-${refId.value}`)
      await $store.dispatch(`${storeName.value}/save`, {
        refId: refId.value,
        go: false,
        alert: true,
        changes: true,
        force: true
      })
      eventBus.$emit(`saved-${type.value}-${refId.value}`)
    }

    const save = async () => {
      if (isDirty.value || forceSave) return (providedSave || defaultSave)()
    }

    const resetChanges = async (toggle = true) => {
      await $store.dispatch(`${storeName.value}/reset`, {
        refId: refId.value,
        go: false
      })

      if (toggle) refChanges?.value?.toggle()
    }

    return {
      save,
      resetChanges,
      refId // Expose refId if needed outside
    }
  }
}
