<template>
  <div>
    <card-section class="m-0">
      <template #label> Lead request </template>
      <warning class="mb-3" icon="user-plus">
        <p>{{ activity.activity_desc || '[Nothing received]' }}</p>
        <next-steps
          :inline="false"
          v-if="activity.oClient.client_status === 'l'"
          :object="activity.oClient"
        />
        <span class="add-btn success sm" v-else>
          <font-awesome-icon icon="check" />
          Lead successfully converted to active client!
        </span>
      </warning>
    </card-section>
  </div>
</template>

<script>
import NextSteps from '../Previews/NextSteps.vue'

export default {
  name: 'ActivityLead',
  props: {
    activity: {
      required: true
    },
    contextual: {
      default: false
    }
  },
  components: {
    NextSteps
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
