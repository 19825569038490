<template>
  <Toggle
    v-if="fetched"
    :onValue="onValue"
    :offValue="offValue"
    @click.native="toggle"
    :value="metaValue"
  />
</template>

<script setup>
import { useMetaField } from '@/components/composables/MetaFieldCommon'
import Toggle from '@/components/ui/Toggle.vue'
Toggle.compatConfig = { MODE: 3 }

// ==== Props ==== //
const props = defineProps({
  onValue: {
    default: 1
  },
  offValue: {
    default: 0
  },
  /**
   * Process the value received from keyvalue
   */
  process: {
    default: () =>
      function process(val) {
        return val || 0
      }
  },
  name: {
    type: String,
    required: true
  },
  global: {
    type: Boolean,
    default: false
  }
})
// =============== //

// ==== Emits ==== //
const emit = defineEmits(['input'])
// =============== //

// ==== Composables ==== //
const { metaValue, fetched, setValue } = useMetaField(props, emit)
// =============== //

// ==== Methods ==== //
const toggle = () => {
  if (metaValue.value === props.onValue) {
    setValue(props.offValue)
  } else {
    setValue(props.onValue)
  }
}
// =============== //
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
