<template>
  <div
    class="h-full basis-full shrink-1 flex flex-col items-stretch justify-stretch"
    @dragstart="dragStart"
    @touchstart="dragStart"
    @dblclick="rowDoubleClick"
    @doubletap="rowDoubleClick"
    @click="() => deselectAll()"
  >
    <Splitter
      class="h-full basis-full grow-1 shrink-1 overflow-y-clip !bg-transparent"
      @click.prevent.stop.shift="rowShiftClick"
      @click.prevent.stop.ctrl="rowCtrlClick"
      @click.prevent.stop.meta="rowCtrlClick"
      @touchstart="rowTap"
      @click.exact="rowClick"
    >
      <!-- Side bar -->
      <SplitterPanel
        v-show="showSidebarLocal"
        size="1"
        class="w-full md:min-w-[300px] md:max-w-[400px] overflow-y-auto flex flex-col justify-between"
      >
        <div class="flex items-center border-b border-surface-200 min-h-16 shrink-0 h-16 !w-full">
          <div class="flex justify-start items-start text-xl font-medium gap-2 px-safe-4 pl-6">
            <div
              class="rounded-xs h-fit flex justify-center items-start gap-2 text-surface-800 leading-tight"
            >
              <font-awesome-icon icon="folder" />
              <span> Bolster Drive </span>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-stretch justify-start gap-4 py-2 px-safe-4">
          <div class="flex justify-stretch items-center gap-1 w-full">
            <Btn
              tooltip="Toggle sidebar"
              class="grow-0 shrink-0 !size-10"
              size="lg"
              link
              @click="showSidebarLocal = !showSidebarLocal"
            >
              <Icon icon="sidebar" size="lg" />
            </Btn>

            <IconField iconPosition="left" class="!grow">
              <InputIcon>
                <icon icon="search" class="text-surface-800" />
              </InputIcon>
              <InputText
                v-model="searchPhraseLocal"
                placeholder="Search your drive..."
                size="large"
                type="text"
              />
            </IconField>
          </div>

          <div class="flex justify-stretch items-center gap-1 w-full">
            <Btn
              class="basis-50 shrink-1 !border-transparent !w-1/2"
              severity="tertiary"
              :action="createFolder"
            >
              <font-awesome-icon icon="folder-plus" />
              New folder
            </Btn>
            <Btn
              class="basis-50 shrink-1 !border-transparent !w-1/2"
              severity="tertiary"
              :action="upload"
            >
              <font-awesome-icon icon="folder-arrow-up" />
              Upload
            </Btn>
          </div>

          <div class="flex flex-col items-stretch justify-start gap-1 py-2">
            <template v-if="$store.state.session.scope.company">
              <file
                v-for="file in specialFiles[0]"
                :object="file"
                :scope="scopeLocal"
                :allow-rename="false"
                @dblclick.stop.prevent="() => fileDblClick(file)"
                @click.stop.prevent="() => fileDblClick(file)"
                :grid="$refs.grid"
                :data-id="file.file_id"
                :key="file.file_id"
                :dblClickOpen="false"
                size="sm"
                :selected="file.selected"
                @contextmenu="() => selectSingleRow(file)"
                :class="{
                  folder: file.file_is_folder,
                  selected: file.selected,
                  dragging: draggingIds.indexOf(file.file_id) > -1,
                  hovering: file.file_is_folder && hoveringIds.indexOf(String(file.file_id)) > -1,
                  removing: removingIds.indexOf(String(file.file_id)) > -1
                }"
              />
            </template>

            <file
              v-for="file in specialFiles[1]"
              :object="file"
              :scope="scopeLocal"
              :allow-rename="false"
              @dblclick.stop.prevent="() => fileDblClick(file)"
              @click.stop.prevent="() => fileDblClick(file)"
              :grid="$refs.grid"
              :data-id="file.file_id"
              :key="file.file_id"
              :dblClickOpen="false"
              size="sm"
              :selected="file.selected"
              @contextmenu="() => selectSingleRow(file)"
              :class="{
                folder: file.file_is_folder,
                selected: file.selected,
                dragging: draggingIds.indexOf(file.file_id) > -1,
                hovering: file.file_is_folder && hoveringIds.indexOf(String(file.file_id)) > -1,
                removing: removingIds.indexOf(String(file.file_id)) > -1
              }"
            />

            <template v-if="!$store.state.session.scope.company || showOtherCompanies">
              <file
                v-for="file in specialFiles[2]"
                :object="file"
                :scope="scopeLocal"
                :allow-rename="false"
                @dblclick.stop.prevent="() => fileDblClick(file)"
                @click.stop.prevent="() => fileDblClick(file)"
                :grid="$refs.grid"
                :data-id="file.file_id"
                :key="file.file_id"
                :dblClickOpen="false"
                size="sm"
                :selected="file.selected"
                @contextmenu="() => selectSingleRow(file)"
                :class="{
                  folder: file.file_is_folder,
                  selected: file.selected,
                  dragging: draggingIds.indexOf(file.file_id) > -1,
                  hovering: file.file_is_folder && hoveringIds.indexOf(String(file.file_id)) > -1,
                  removing: removingIds.indexOf(String(file.file_id)) > -1
                }"
              />
            </template>

            <div v-if="specialFiles[2]?.length && !showOtherCompanies">
              <Btn link @click="showOtherCompanies = true">
                <font-awesome-icon icon="fas fa-ellipsis" />
                Other company drives
              </Btn>
            </div>
          </div>
        </div>
        <div class="p-0 mt-auto">
          <div class="bg-pitch-black p-6 flex flex-col justify-center items-center gap-6">
            <div class="max-w-[250px] flex flex-col justify-start items-start gap-4">
              <div class="flex justify-start items-center gap-4">
                <font-awesome-icon icon="folder" size="2xl" class="text-level-yellow" />

                <div
                  class="flex justify-center flex-col text-level-yellow font-medium tracking-wide"
                >
                  <span class="font-light text-sm leading-tight"> Unlimited cloud storage</span>
                  <span class="text-xl leading-none">Bolster Drive</span>
                </div>
              </div>

              <div class="text-sm leading-snug text-normal text-flame-white">
                Unlimited storage, with built-in security, collaboration, and file sharing -- all
                included in your membership.
              </div>
            </div>
          </div>
        </div>
      </SplitterPanel>

      <!-- Files list -->
      <SplitterPanel
        :size="100"
        v-show="!showSidebarLocal || !smallFormat"
        :class="{ dragging: dragging.length }"
        class="overflow-y-auto relative"
      >
        <Splitter :gutter-size="0">
          <SplitterPanel
            v-show="!showSidebarLocal"
            :size="5"
            class="scrollbar-hide pt-4 overflow-y-auto pb-20 min-w-[60px]"
          >
            <Btn link size="xl" @click="showSidebarLocal = true" tooltip="Show sidebar">
              <Icon icon="sidebar" size="xl" />
            </Btn>
            <Btn link size="xl" @click="showSidebarLocal = true" tooltip="Search">
              <Icon icon="folder-magnifying-glass" size="xl" />
            </Btn>
            <Btn link size="xl" :action="createFolder" tooltip="Create folder">
              <Icon icon="folder-plus" size="xl" />
            </Btn>
            <Btn link size="xl" :action="upload" tooltip="Upload files">
              <Icon icon="folder-arrow-up" size="xl" />
            </Btn>
          </SplitterPanel>

          <SplitterPanel :size="100" class="overflow-y-auto pb-20">
            <!-- Path -->
            <div
              class="flex flex-row items-center border-b border-surface-200 px-3 h-16 relative"
              ref="fileList"
            >
              <template v-if="!searchPhrase && !pathLoading">
                <template v-for="(segment, index) in path" :key="index">
                  <a
                    class="hover:text-blue-print-400 cursor-pointer select-none"
                    :class="{
                      hovering: hoveringIds.indexOf(segment.file_id) > -1,
                      folder: index !== path.length - 1 // every folder works except current working directory
                    }"
                    :data-id="segment.file_id"
                    :data-index="-1"
                    @touchstart.stop.prevent="() => goToDir(segment)"
                    @click.stop.prevent="() => goToDir(segment)"
                  >
                    <div class="text-lg">&nbsp;{{ segment.file_name }} /</div>
                  </a>
                  <div class="divider"></div>
                </template>
              </template>
              <Loader :loading="pathLoading" />
            </div>

            <!-- File list -->
            <div
              v-if="set && set.length"
              class="flex flex-col justify-start items-stretch pb-40 w-full relative divide-y px-4 gap-1"
            >
              <file
                v-for="(file, index) in visibleSet"
                :object="file"
                :data-index="index"
                :data-id="file.file_id"
                :grid="this"
                :scope="scopeLocal"
                :key="file.file_id"
                :dblClickOpen="false"
                size="lg"
                :dragging="draggingIds.indexOf(file.file_id) > -1"
                :hovering="file.file_is_folder && hoveringIds.indexOf(String(file.file_id)) > -1"
                :selected="selectedIds.indexOf(file.file_id) > -1"
                @contextmenu="() => (file.file_is_folder ? {} : selectAdditionalRow(file))"
                @click.stop="() => (file.file_is_folder ? {} : selectAdditionalRow(file))"
                @dblclick="fileDblClick(file)"
                :class="{
                  folder: file.file_is_folder,
                  selected: selectedIds.indexOf(file.file_id) > -1,
                  highlighted: highlightedIds.indexOf(file.file_id) > -1,
                  dragging: draggingIds.indexOf(file.file_id) > -1,
                  hovering: file.file_is_folder && hoveringIds.indexOf(String(file.file_id)) > -1,
                  removing: removingIds.indexOf(String(file.file_id)) > -1
                }"
              />

              <div
                style="width: 100%"
                class="flex mt-2 mb-5 items-center justify-center relative"
                v-if="set.length && !endOfSet"
              >
                <btn class="round default sm" :action="fetchMore" v-if="!loading">
                  Load more..
                </btn>
                <Loader :loading="loading" />
              </div>
            </div>

            <!-- empty files -->
            <div class="empty-files-set" v-else-if="!loading && !set.length">
              <h4>
                {{ searchPhraseLocal ? 'Nothing found.' : 'No files in this folder.' }}
                <br />
                <p>
                  <font-awesome-icon icon="cloud-arrow-down" class="mt-5" />
                  Drop files..
                </p>
              </h4>
              <span class="flex flex-row gap-3 mt-5">
                <btn
                  severity="tertiary"
                  class="text-surface-400 border-surface-300"
                  :action="createFolder"
                >
                  New folder
                </btn>
                <btn
                  severity="tertiary"
                  class="text-surface-400 border-surface-300"
                  :action="upload"
                >
                  Upload
                </btn>
              </span>
            </div>

            <!-- loading -->
            <Loader :loading="loading" />
          </SplitterPanel>
        </Splitter>
      </SplitterPanel>
    </Splitter>

    <!-- upload -->
    <file-upload
      ref="fileUpload"
      :grid="this"
      :tags="uploadTags"
      :showButton="false"
      :scope="scopeLocal"
      :folder="dir"
    />

    <!-- clone -->
    <div
      class="dragging-container animate-fadeIn"
      v-if="dragging.length"
      :style="draggingContainerStyle"
    >
      <file v-for="file in draggingFiles" :key="file.file_id" :object="file" :selected="true" />
      <div class="message animate-fadeIn" v-if="hoveringIds.length">
        Move {{ selectedIds.length > 1 ? selectedIds.length : '' }} selected to this folder...
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '../ui/FileUpload.vue'
import File from './File.vue'
import GridMixin from '../ui/Grid/GridMixin'
import eventBus from '../../eventBus'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import { useMediaQuery } from '@/composables/mediaQuery'

export default {
  name: 'Files',
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  mixins: [GridMixin],
  components: {
    File,
    FileUpload,
    InputIcon,
    IconField,
    InputText
  },
  props: {
    showSidebar: {
      default: true
    },
    ignoreFiltersOnKeywordSearch: {
      default: true
    },
    filters: {
      required: false,
      default: () => ({})
    },
    permanentFilters: {
      required: false,
      default: () => ({})
    },
    showFilters: {
      required: false,
      default: true
    },
    showToolbar: {
      required: false,
      default: true
    },
    showPath: {
      required: false,
      default: true
    },
    showByPath: {
      required: false,
      default: true
    },
    openFoldersInNewWindow: {
      default: false
    },
    doubleClickToOpen: {
      type: Boolean,
      default: false
    },
    limit: {
      default: 50
    },
    order: {
      default: () => [
        ['file_is_folder', 'desc'],
        ['file_time_created', 'desc']
      ]
    },
    type: {
      default: 'file'
    },
    isMain: {
      default: false
    }
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.drag)
    window.removeEventListener('mouseup', this.dragEnd)
    window.removeEventListener('dragend', this.dragEnd)
    window.removeEventListener('touchend', this.dragEnd)
    window.removeEventListener('touchmove', this.drag)
    eventBus.$off('dblclick', this.fileDblClick)
    eventBus.$off('initialized', this.setFiltersToDefault)
  },
  mounted() {
    this.setFiltersToDefault()
    eventBus.$on('dblclick', this.fileDblClick)
    eventBus.$once('initialized', this.setFiltersToDefault)
  },
  data() {
    return {
      showOtherCompanies: false,
      showSidebarLocal: this.showSidebar,
      dir: null,
      path: [],
      dragging: [],
      draggingIds: [],
      draggingFiles: [],
      removingIds: [],
      hovering: [],
      hoveringIds: [],
      startDragEvent: null,
      dragPosition: {
        x: 0,
        y: 0
      },
      pathLoading: 0,
      fileFiltersLocal: this.filters
    }
  },

  watch: {
    dir() {
      this.updateFilePath()
    },
    fileFilters(fil) {
      if (!c.jsonEquals(fil, this.filtersLocal)) {
        this.filtersLocal = fil
      }
    },
    filtersLocal(fil) {
      if (!c.jsonEquals(fil, this.fileFilters)) {
        this.fileFilters = fil
      }
    }
  },

  computed: {
    specialFiles() {
      let companies
      let otherCompanies
      let users

      if (this.$store.state.session.scope.company) {
        const co = this.$store.state.session.company
        companies = [
          {
            current: true,
            file_id: `company-${co.company_id}`,
            file_name: co.company_name_short || co.company_name,
            file_is_folder: 1,
            parent_file_id: null,
            scope: { company: co.company_id, user: this.$store.state.session.scope.user },
            selected:
              `company-${co.company_id}` === this.dir ||
              c.jsonEquals(
                {
                  company: co.company_id,
                  user: this.$store.state.session.scope.user
                },
                this.scopeLocal
              )
          }
        ]
      }

      const curco = this.$store.state.session.scope.company
      otherCompanies = this.$store.state.session.user.aoCompanies
        .filter((co) => !curco || co.company_id !== curco)
        .map((co) => ({
          file_id: `company-${co.company_id}`,
          file_name: co.company_name_short || co.company_name,
          file_is_folder: 1,
          parent_file_id: null,
          scope: { company: co.company_id, user: this.$store.state.session.scope.user },
          selected:
            `company-${co.company_id}` === this.dir ||
            c.jsonEquals(
              {
                company: co.company_id,
                user: this.$store.state.session.scope.user
              },
              this.scopeLocal
            )
        }))

      const u = this.$store.state.session.user
      users = [
        {
          file_id: `user-${u.user_id}`,
          file_name: `${u.user_fname} ${u.user_lname} • personal`,
          file_is_folder: 1,
          parent_file_id: null,
          scope: { user: u.user_id },
          selected:
            `user-${u.user_id}` === this.dir || c.jsonEquals({ user: u.user_id }, this.scopeLocal)
        }
      ]

      return [companies, users, otherCompanies]
    },
    userDirectory() {
      return `user-${this.$store.state.session.user.user_id}`
    },
    companyDirectory() {
      if (this.$store.state.session.company.company_id) {
        return `company-${this.$store.state.session.company.company_id}`
      }
      return null
    },
    uploadTags() {
      return {
        ...this.permanentFilters,
        parent_file_id: this.dir,
        company_id: 'company' in this.scopeLocal ? this.scopeLocal.company : null,
        user_id: !('company' in this.scopeLocal)
          ? this.scopeLocal.user || this.$store.state.session.scope.user
          : null
      }
    },
    fileFilters: {
      get() {
        return {
          ...this.fileFiltersLocal,
          ...{
            parent_file_id: this.dir
          },
          ...this.permanentFilters
        }
      },
      set(filters) {
        this.fileFiltersLocal = filters
        this.dir = 'parent_file_id' in filters ? filters.parent_file_id : this.dir
      }
    },
    draggingContainerStyle() {
      return `top: ${this.dragPosition.y + 2}px; left: ${this.dragPosition.x + 2}px`
    },
    visibleSet() {
      return this.set.filter((file) => file && !file.file_is_invisible)
    }
  },

  methods: {
    async setFiltersToDefault() {
      const folder = this.filtersLocal.parent_file_id
      if (!folder || /^NULL$/i.test(folder)) {
        const scope = this.$store.state.session.scope
        const dir =
          this.filters.parent_file_id ||
          (scope.company && `company-${scope.company}`) ||
          (scope.user && `user-${scope.user}`)
        this.scopeLocal = this.getScopeByDir(dir)

        this.filtersLocal = {
          parent_file_id: dir
        }
      }

      await this.$nextTick()

      this.dir = this.filtersLocal.parent_file_id
      this.scopeLocal = this.getScopeByDir(this.dir)

      this.updateFilePath()
    },
    /**
     * Create a folder
     */
    async createFolder() {
      this.addLoading()
      await this.$store.dispatch('File/createFolder', {
        tags: {
          ...this.permanentFilters,
          ...this.fileFilters,
          file_is_folder: 1
        },
        scope: this.scopeLocal
      })
      await c.throttle(() => {})
      await this.reload()
      this.removeLoading()
    },

    /**
     * Trigger upload
     */
    upload() {
      this.$refs.fileUpload.upload()
    },

    /**
     * Go to directory
     * @param dir file object
     * @param scope = null
     */
    async goToDir(dir) {
      if (String(dir.file_id) !== String(this.dir)) {
        this.addLoading()
        await this.$nextTick()
        this.set = []
      }

      const scope = this.getScopeByDir(dir.file_id)
      this.scopeLocal = scope

      // this.scopeLocal = scope || this.getFileScope(dir);
      if (dir.file_id && dir.file_id !== 'NULL') {
        this.dir = dir.file_id
      } else if (this.$store.state.session.scope.company) {
        this.dir = `company-${this.$store.state.session.scope.company}`
      } else if (this.$store.state.session.scope.user) {
        this.dir = `user-${this.$store.state.session.scope.user}`
      }
      this.removeLoading()
    },

    getScopeByDir(dir) {
      if (/^(company-|user-)(\d+)$/.test(dir)) {
        const matches = dir.match(/^(company|user)-(\d+)$/)
        const scopeType = matches[1]
        const scopeId = matches[2]

        const scope = {}
        scope[scopeType] = scopeId

        if (scopeType !== 'user') {
          // Add user
          scope.user = this.$store.state.session.user.user_id
        }

        return scope
      }

      return this.scopeLocal
    },

    getFileScope(file) {
      if (file.oScope) {
        return file.oScope
      }

      const scope = {
        user: this.$store.state.session.scope.user
      }

      if (file.user_id) {
        scope.user = file.user_id
      } else if (/user-\d+/.test(file.file_id)) {
        scope.user = file.file_id.replace('user-', '')
      }

      if (file.company_id) {
        scope.company = file.company_id
      } else if (/^(\d+-projects|company-\d+)$/.test(file.file_id)) {
        scope.company = file.file_id.replace('company-', '').replace('-projects')
      } else if (/quote-\d+/.test(file.file_id)) {
        scope.company = file.company_id || this.$store.state.session.scope.company
      }

      return scope
    },

    async fileDblClick(target) {
      if (!target) return
      if (target.file_is_folder && !this.openFoldersInNewWindow) {
        if (this.searchPhraseLocal) {
          this.searchPhraseLocal = ''
        }
        return this.goToDir(target)
      }

      return this.$store.dispatch('modal/open', {
        modal: 'File',
        objects: [target]
        // scope: this.scopeLocal,
      })
    },

    async updateFilePath() {
      this.pathLoading = 1
      await c.throttle(
        async () => {
          const dirGoingWith = this.dir
          const { object = [] } = await this.$store.dispatch('ajax', {
            path: `file/getPath/${dirGoingWith}`
          })

          if (this.dir === dirGoingWith) {
            this.path = object
            this.pathLoading = 0
          }
        },
        { delay: 100 }
      )
    },

    dragStart(e) {
      // Make sure item under drag start is selected, if not select that.
      const hover = $(document.elementFromPoint(e.clientX, e.clientY))
      const hoverEl = hover.is('[data-index]') ? hover : hover.closest('[data-index]')
      const index =
        hoverEl.length && !hoverEl.is('.selected') /* prevent dropping folder into itself */
          ? hoverEl.attr('data-index')
          : false
      if (index) {
        this.selectSingleRow(this.set[index])
      }

      this.startDragEvent = e
      this.dragging = [...this.selected]
      this.draggingFiles = this.dragging.map((i) => ({ ...this.set[i] }))
      this.draggingIds = this.draggingFiles.map((f) => f.file_id)
      this.mouseMoveListener = window.addEventListener('mouseup', this.dragEnd)
      this.mouseMoveListener = window.addEventListener('dragend', this.dragEnd)
      this.mouseMoveListener = window.addEventListener('touchend', this.dragEnd)
      this.mouseMoveListener = window.addEventListener('mousemove', this.drag)
      this.mouseMoveListener = window.addEventListener('touchmove', this.drag)
      return e.preventDefault()
    },

    drag(e) {
      if (e.clientX) {
        this.setDragPosition(e.clientX, e.clientY, this)
      } else {
        this.setDragPosition(e.targetTouches[0].clientX, e.targetTouches[0].clientY, this)
      }
      e.stopPropagation()
      return e.preventDefault()
    },
    setDragPosition: c.debounce((x, y, context) => {
      context.dragPosition = {
        x,
        y
      }
      context.setHoverElement(x, y)
    }),

    setHoverElement(x, y) {
      const $hover = $(document.elementFromPoint(x, y))
      const $hoverEl = $hover.is('.folder[data-id]') ? $hover : $hover.closest('.folder[data-id]')
      const id =
        $hoverEl.length && !$hoverEl.is('.selected') /* prevent dropping folder into itself */
          ? $hoverEl.attr('data-id')
          : false
      const index =
        $hoverEl.length && !$hoverEl.is('.selected') /* prevent dropping folder into itself */
          ? $hoverEl.attr('data-index')
          : false

      if (index) {
        this.hovering = [index || false]
      } else {
        this.hovering = []
      }

      if (id) {
        this.hoveringIds = [id]
      } else {
        this.hoveringIds = []
      }
    },

    dragEnd(e) {
      if (this.hoveringIds.length) {
        this.removingIds = [...this.draggingIds]
        // move file within within company, or within individual scope
        const data = this.draggingFiles.map((f) => ({
          file_id: f.file_id,
          parent_file_id: this.hoveringIds[0]
        }))
        this.$store.dispatch('ajax', { path: '/file/updateMultiple', data, scope: this.scopeLocal })
        // Remove from set
        this.selected = []
        const newSet = this.set.filter((f) => this.removingIds.indexOf(f.file_id) === -1)
        setTimeout(() => {
          this.set = newSet
          this.hovering = []
          this.hoveringIds = []
          this.removing = []
          this.removingIds = []
        }, 600)
      } else {
        this.hovering = []
        this.hoveringIds = []
        this.removing = []
        this.removingIds = []
      }

      this.dragging = []
      this.draggingIds = []
      this.draggingFiles = []
      this.startDragEvent = null
      this.mouseMoveListener = window.removeEventListener('mousemove', this.drag)
      this.mouseMoveListener = window.removeEventListener('mouseup', this.dragEnd)
      this.mouseMoveListener = window.removeEventListener('dragend', this.dragEnd)
      this.mouseMoveListener = window.removeEventListener('touchend', this.dragEnd)
      this.mouseMoveListener = window.removeEventListener('touchmove', this.drag)
      e.stopPropagation()
      return e.preventDefault()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.files--container {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  max-height: 100%;
  height: 100%;

  .files--toolbar {
    flex: 0 0 3em;

    display: flex;
    justify-content: stretch;
    align-items: center;

    padding: 0 0.25em;

    .grid--toolbar {
      width: 100%;
      flex: 0 100%;
    }
  }

  .files--path {
    flex: 0 0 3em;
    min-height: 2em;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background: $blue-print-300;
    position: sticky;
    top: 0;
    z-index: $z-layout - 1;
    border-radius: 1em;
    overflow-y: auto;
    // margin: 0 0em 0 0.25em;

    .divider {
      display: block;
      background: $cool-gray-300;
      transform: skew(-15deg);
      width: 0.5em;
    }

    .segment {
      flex: 0 1 auto;
      max-width: fit-content;
      background: $blue-print-600;

      color: $blue-print-300;
      display: inline-flex;
      align-items: center;
      padding: 0 1em;
      margin: 0;

      font-weight: bold;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      justify-content: flex-start;
      transition: all 0.1s ease-in;
      will-change: width;

      > div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1;
      }

      &:hover {
        background: $blue-print-700;
        color: $flame-white;
        max-width: fit-content;
        min-width: fit-content !important;
        width: fit-content !important;
      }

      &:first-child {
        border-radius: 1em 0 0 1em;
      }
      &:not(:first-child) {
        margin-left: -0.25em;
      }
      margin-right: -0.25em;
    }
  }

  .files--body {
    flex: 1 auto;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: row;
  }

  .files--sidebar {
    flex: 0 0 auto;
    border-radius: 0 1em 0 0;
    background: $cool-gray-600;
    max-height: 100%;
    overflow-y: scroll;
    // @extend %scrollbar;
    padding-bottom: 50vh;
  }

  .files--list {
    flex: 1 auto;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    // @extend %scrollbar;
  }

  .files--list-scroll {
    height: auto;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 3em 3em;
    padding-bottom: 25vh !important;

    .file {
      transition: all 0.25s;
      &.dragging {
        opacity: 0;
        .file-image {
          background: $orange-500;
        }
      }
      &.removing {
        opacity: 0.2;
        .file-image {
          background: $deep-red-300;
        }
      }
      &.highlighted {
        .file-image {
          background: $level-yellow;
        }
      }
    }
  }

  &.dragging {
    .folder:not(.dragging) {
      &.hovering {
        .file-image {
          background: rgba($blue-print-700, 0.6);
        }
      }
    }
  }
}

.dragging-container {
  position: fixed;
  bottom: auto;
  right: auto;
  z-index: 100000000000;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: grabbing;
  .file {
    margin-left: -50px;
    opacity: 1;
    &:first-child {
      margin-left: 0px;
    }
  }
  .message {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px 10px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 0.7em;
    border-top-left-radius: 1px;
    white-space: nowrap;
    font-weight: bold;
  }
}
.empty-files-set {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;

  color: $cool-gray-500;
  h4,
  h5 {
    color: $cool-gray-500;
    text-align: center;
  }
}

.folder.hovering {
  cursor: copy !important;
  &:hover,
  a,
  a:hover {
    cursor: copy !important;
  }
}
.dragging {
  cursor: grabbing !important;

  .file {
    &:hover,
    .file-label:hover {
      cursor: grabbing !important;
    }
  }
  .file.folder:not(.dragging) {
    &:hover,
    .file-label:hover {
      cursor: copy !important;
    }
  }
}
.folder.hovering {
  cursor: copy !important;
  &:hover,
  a,
  a:hover {
    cursor: copy !important;
  }
}
</style>
