<template>
  <component :is="tag" @click="go">
    <slot></slot>
  </component>
</template>

<script>
/**
 * ToRoute determines the company
 * route and routes inside the current scope
 */
export default {
  name: '',
  emits: ['before', 'after'],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    async go() {
      this.$emit('before')
      // In case we have a Promise containing a route/URL/Vorticon, await it
      const dest = await this.to
      if (dest.includes('https://')) {
        window.location.href = dest
      } else {
        await this.$store.dispatch('to', dest)
      }
      this.$emit('after')
    }
  },
  components: {},
  props: {
    tag: {
      default: 'a'
    },
    to: {
      default: ''
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
