<template>
  <spinner :loading="loading" :size="size" v-show="showLoading" class="scroll-trigger-container" />
</template>

<script>
import BtnMixin from '@/components/mixins/Button'

/**
 * Props:
 *  -disableOnTrigger
 *      - whether to stop emitting @on once it is trigger.
 *          if you want to reset it, then call .reset() on this
 *          component and it with re-enable it.
 *
 *  Emits:
 *    @in - when the element enters the viewport
 *    @out - when the element leaves viewport
 *
 *  Slots:
 *    default - has a spinner object that is triggered
 *
 *  Methods:
 *    addLoading()
 *    removeLoading()
 *    endLoading()
 *    reset()
 */
export default {
  name: 'ScrollTrigger',
  mixins: [BtnMixin],
  emits: ['out', 'in'],
  data() {
    return {
      scrollParent: null,
      uid: _.uniqueId(),
      tracking: true,
      firstOut: false,
      state: 'out'
    }
  },
  props: {
    size: {
      default: '3em'
    },
    disableOnTrigger: {
      default: true
    },
    disabled: {
      default: false
    },
    showLoading: {
      default: true
    },
    checkOnMount: {
      default: false
    },
    delay: {
      default: 800
    }
  },
  methods: {
    reset() {
      this.tracking = true
      setTimeout(() => {
        this.firstOut = true
        this.scroll()
      }, 1000)
    },
    out() {
      if (this.state !== 'out') {
        if (!this.firstOut) {
          this.firstOut = true
        } else {
          this.$emit('out')
        }
        this.state = 'out'
      }

      // If it is actually in after 200ms then do in again
      setTimeout(500, () => {
        if (this.isIn()) {
          this.scroll()
        }
      })
    },
    async in() {
      if (this.firstOut && !this.disabled) {
        if (this.state !== 'in') {
          this.addLoading()
          this.$emit('in')
          if (this.disableOnTrigger) this.tracking = false
          this.state = 'in'
        }
      }
    },
    isIn() {
      const rect = this.$el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        parseInt(rect.bottom.toFixed(2), 10) <=
          parseInt(window.innerHeight || document.documentElement.clientHeight, 10) &&
        parseInt(rect.right.toFixed(2), 10) <=
          parseInt(window.innerWidth || document.documentElement.clientWidth, 10)
      )
    },
    async scroll() {
      if (this.tracking) {
        const prehit = this.isIn()

        if ((!prehit && this.state !== 'in') || (prehit && this.state === 'in')) {
          // already there do nothing
          return
        }

        await c.throttle(
          async () => {
            const hit = this.isIn() // still the same?
            if (prehit !== hit) return

            if (hit && this.state !== 'in') {
              this.in()
            } else if (!hit && this.state === 'in') {
              this.out()
            }

            await c.throttle(
              () => {
                if (hit) {
                  this.in()
                } else {
                  this.out()
                }
              },
              { debounce: true, delay: 100, key: `${this.uid}-${this.isIn()}` }
            )

            setTimeout(() => this.out(), 1000 + this.delay)
          },
          { delay: this.delay }
        )
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollParent = c.getScrollParent(this.$el)
        this.scrollParent.addEventListener('scroll', this.scroll)
        if (this.checkOnMount) {
          if (this.isIn()) {
            this.firstOut = true
            this.in()
          } else {
            this.firstOut = true
            this.out()
          }
        } else {
          setTimeout(() => {
            this.firstOut = true
            this.scroll()
          }, 1000)
        }
      })
    }, 1000)
  },
  beforeUnmount() {
    if (this.scrollParent && this.scrollParent.removeEventListener) {
      this.scrollParent.removeEventListener('scroll', this.scroll)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.scroll-trigger-container {
  position: relative;
}
</style>
