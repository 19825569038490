<template>
  <div class="dot-container">
    <div class="dot-flashing" key="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator'
}
</script>

<style lang="scss" rel="stylesheet/scss">
$scale: 10px;

@keyframes doflash {
  0%,
  50% {
    background-color: $blue-print-500;
  }
  100% {
    background-color: $blue-print-700;
  }
}

.dot-container {
  width: $scale * 5 !important;
  height: $scale * 2;
  padding: calc($scale / 2);
  display: inline-flex;
  .dot-flashing {
    margin: 0 auto;
    position: relative;
    width: $scale;
    height: $scale;
    max-width: $scale;
    max-height: $scale;
    min-width: $scale;
    min-height: $scale;
    border-radius: 50%;
    background-color: $blue-print-700;
    color: $blue-print-700;
    animation: doflash 0.5s infinite linear alternate;
    animation-delay: 0.25s;
    margin: 0 auto;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -1.5 * $scale !important;
    width: $scale;
    height: $scale;
    max-width: $scale;
    max-height: $scale;
    min-width: $scale;
    min-height: $scale;
    border-radius: calc($scale / 2);
    background-color: $blue-print-700;
    color: $blue-print-700;
    animation: doflash 0.5s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 1.5 * $scale !important;
    width: $scale;
    height: $scale;
    max-width: $scale;
    max-height: $scale;
    min-width: $scale;
    min-height: $scale;
    border-radius: calc($scale / 2);
    background-color: $blue-print-700;
    color: $blue-print-700;
    animation: doflash 0.5s infinite alternate;
    animation-delay: 0.5s;
  }
}
</style>
