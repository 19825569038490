<template>
  <div
    class="item-summary--assembly-container"
    :class="{
      searching: context.searchPhrase,
      searchMatch: isSearchMatch
    }"
  >
    <svg
      class="item-summary--assembly-path"
      v-if="open"
      :class="isRoom ? 'item-summary--room-path' : ''"
    >
      <line x1="5" y1="2" x2="5" :y2="y2" class="assembly-line" />
    </svg>

    <a class="item-summary--label item-summary--label-assembly" @click.stop.prevent="edit">
      <div
        class="item-summary--title item-summary--title-assembly"
        @click.stop.prevent="openActions"
        @touchstart.stop.prevent="openActions"
        :id="btnBarHandle"
        :class="isRoom ? 'item-summary--title-room' : ''"
      >
        <a @click.capture.stop.prevent="open = !open">
          <font-awesome-icon icon="chevron-circle-down" :class="open ? 'open' : 'closed'" />
        </a>

        <span
          class="badge badge-warning item-summary--badge"
          v-if="isUpgraded"
          v-tooltip="'This item is an upgraded item'"
        >
        </span>

        <!--<span class="cubes icon"></span>-->

        {{ name }}

        <btn-bar
          v-if="actionRequested"
          :fixTo="`#${btnBarHandle}`"
          :showButton="false"
          ref="contextMenu"
          :collapse="true"
          :actions="lineItemActions"
          position="bottom left"
          targetAttachment="bottom left"
        />
      </div>

      <btn rounded class="more px-0 p-2 xs info ml-2" @click="addItem">
        <font-awesome-icon icon="plus" />
      </btn>

      <font-awesome-icon v-if="isRoom" icon="drafting-compass" class="ml-1 circled text-info" />

      <spinner :loading="1" v-if="loading" size="1em" class="info mx-1" />

      <div class="item-summary--qty" style="margin-left: auto">
        <small class="text-muted" v-if="qty !== 1"> &times;{{ qty }} </small>
      </div>

      <div
        class="item-summary--sum item-summary--cost item-summary--sum-cost-item text-muted small"
        style="opacity: 0.4"
        v-if="context.showForCost.includes('cost')"
      >
        {{ $f.currency(cost) }}
      </div>

      <div
        class="item-summary--sum item-summary--price item-summary--sum-cost-item"
        style="opacity: 0.3"
        v-if="context.showForCost.includes('price')"
      >
        {{ $f.currency(price) }}
      </div>
    </a>

    <div class="item-summary--assembly-items" v-if="open">
      <div class="item-summary--assembly-item" v-for="(childRefId, index) in children" :key="index">
        <svg
          class="item-summary--child-path"
          :class="isRoom ? 'item-summary--room-child-path' : ''"
          viewBox="0 0 20 20"
          height="20"
          width="20"
        >
          <path d="M5,7 C5,14 7,15 13,15" class="child-curve" />
        </svg>

        <component
          :ref="childRefId"
          :is="
            context.norm[childRefId].type === 'assembly'
              ? 'item-summary-assembly'
              : 'item-summary-cost-item'
          "
          :refId="childRefId"
          :context="context"
          :key="childRefId"
          @search-match="(is) => searchMatchChild(childRefId, is)"
          @open="childOpenedOrClosed"
          @edit-item="(itemRefId, component) => $emit('editItem', itemRefId, component)"
          @add-item="(parentRefId, component) => $emit('addItem', parentRefId, component)"
          @swap-item="(itemRefId, component) => $emit('swapItem', itemRefId, component)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import QuoteDimensions from '../QuoteDimensions.vue'
import ButtonMixin from '../../mixins/Button'

export default {
  name: 'ItemSummaryAssembly',

  mixins: [ButtonMixin],

  components: {
    QuoteDimensions
  },
  emits: ['editItem', 'addItem', 'swapItem', 'open', 'actions', 'searchMatch'],

  watch: {
    children() {
      this.$nextTick(() => this.setY2())
    },

    open(opened) {
      this.$emit('open', opened)
      this.$nextTick(() => this.setY2())
    }
  },

  data() {
    return {
      open: this.context.startOpen,
      y2: '0',
      childSearchMatches: {},
      actionRequested: 0
    }
  },

  mounted() {
    this.setY2()
  },

  methods: {
    async openActions() {
      this.actionRequested = 1

      await this.$nextTick()

      this.$refs.contextMenu.open()
    },

    addItem() {
      this.$emit('addItem', this.refId, this)
    },

    searchMatchChild(childRefId, is) {
      this.childSearchMatches = {
        ...this.childSearchMatches,
        [childRefId]: is
      }
    },

    childOpenedOrClosed() {
      this.$emit('open', open)
      this.$nextTick(() => this.setY2())
    },

    setY2() {
      if (!this.children && !this.children.length) {
        return
      }

      const lastRef = this.children[this.children.length - 1]
      const lastLabel = this.$refs[lastRef] && this.$refs[lastRef][0]

      if (!lastLabel || !lastLabel.$el) {
        return
      }

      this.y2 = lastLabel.$el.offsetTop - 10
    },

    async save(asNew = true) {
      this.addLoading()

      try {
        await this.$store.dispatch(`${this.context.store}/save`, {
          go: false,
          alert: true,
          asNew,
          selected: [
            {
              ...this.object,
              type: 'assembly'
            }
          ]
        })
      } catch (e) {
        // nothing
      }

      this.endLoading()

      return this
    },

    swap() {
      this.$emit('swapItem', this.refId, this)

      return this
    },

    async duplicate() {
      this.addLoading()
      await this.$store.dispatch(`${this.context.store}/duplicateChild`, { refId: this.refId })
      this.endLoading()

      return this
    },

    async remove() {
      this.addLoading()
      await this.$store.dispatch(`${this.context.store}/removeChild`, { refId: this.refId })
    },

    async edit() {
      this.$emit('editItem', this.refId, this)

      return this
    }
  },

  computed: {
    btnBarHandle() {
      const handle = `btnbar-handle-${this.refId}`.replace(/[^a-zA-Z0-9]/g, '')
      return handle
    },

    lineItemActions() {
      const a = {
        edit: {
          title: 'Edit details + costs..',
          icon: 'pencil',
          action: this.edit,
          visible: () => !this.editing
        },
        swap: {
          title: 'Replace with..',
          icon: ['fas', 'right-from-bracket'],
          action: this.swap
        },
        duplicate: {
          title: 'Duplicate',
          icon: 'clone',
          action: this.duplicate
        },
        remove: {
          title: 'Remove',
          icon: 'circle-xmark',
          action: this.remove
        },
        save: {
          title: 'Update in your catalog',
          icon: 'floppy-disk',
          action: () => this.save(false),
          visible: () => this.object.cost_type_id
        },
        saveAs: {
          title: 'Save to your catalog',
          icon: 'floppy-disk',
          action: () => this.save(true)
        }
      }
      this.$emit('actions', a)
      return a
    },

    searchContent() {
      const fields = ['assembly_name', 'quote_name', 'aoProperties', 'quote_desc']

      const string = JSON.stringify(
        fields.reduce((acc, field) => [acc, this.object[field] || ''], [])
      )

      return string
    },

    isSearchMatchLocal() {
      const searchPhrase = String(this.context.searchPhrase || '').toLowerCase()
      const parts = searchPhrase.split(' ')
      const content = this.searchContent.toLowerCase()

      return parts.every((part) => content.includes(part))
    },

    isSearchMatch() {
      const match =
        this.isSearchMatchLocal || Object.values(this.childSearchMatches).some((val) => !!val)

      c.throttle(
        () => {
          this.$emit('searchMatch', match)
        },
        { key: this.refId, delay: 200, debounce: true }
      )

      return match
    },

    isRoom() {
      return !this.object.parentRefId || this.object.assembly_count_own_dimensions
    },

    isUpgraded() {
      return this.object.item_is_upgraded
    },

    cost() {
      return this.object.quote_total_cost_net_base
    },

    qty() {
      return this.object.quote_qty_net_base
    },

    price() {
      return this.object.quote_price_net_base
    },

    name() {
      return this.object.assembly_name || this.object.quote_name
    },

    children() {
      return this.object.aoChildren
    },

    object() {
      return this.context.norm[this.refId]
    }
  },

  props: {
    refId: {
      type: String,
      required: true
    },

    context: {
      type: Object,
      required: true
    }
  }
}
</script>
