<script setup>
import { useStore } from 'vuex'
import { defineProps, defineOptions, computed, ref, watch } from 'vue'

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  name: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'user'
  },
  id: {
    default: '0'
  },
  channelType: {
    type: String,
    required: false,
    default: undefined
  },
  channelTypeId: {
    type: String,
    required: false,
    default: undefined
  },
  size: {
    default: 'normal'
  },
  full: {
    default: false
  },
  shape: {
    default: null
  },
  alreadyEditing: {
    type: Boolean,
    default: false
  },
  onClick: {
    type: Function,
    default: null
  },
  picture: {
    type: String,
    default: null
  }
})
const $store = useStore()

const color = computed(() => c.stringToColor(`${props.id}${props.type}`))
const prettyType = computed(() => _.ucfirst(props.type.replace('_', ' ')))
const person = computed(() => /client|user|creator|owner|vendor/.test(props.type))
const name = ref(props.name || '')

const edit = async () => {
  $store.commit({
    type: 'SET_PREVIEW',
    preview: {
      type: props.type,
      id: props.id,
      channel_type: props.channelType,
      channel_type_id: props.channelTypeId
    }
  })
}

const handleClick = () => {
  if (props.onClick) {
    props.onClick({ type: props.type, id: props.id })
  } else {
    edit()
  }
}

watch(
  () => props.name,
  async (val) => {
    if (!props.id) return
    name.value =
      val ??
      (await $store.dispatch(`${c.titleCase(props.type)}/getName`, {
        id: props.id
      }))
  }
)
</script>

<template>
  <a @click="handleClick" class="cursor-pointer user-select-none select-none shrink-0 grow-0">
    <Avatar
      :size="props.size"
      :style="`background: ${color} !important`"
      class="cursor-pointer uppercase shrink-0 grow-0"
      :class="[
        {
          'bg-blue-700 text-flame-white': person,
          'bg-cement-400 text-flame-white': !person,
          'w-full h-full': full
        }
      ]"
      :shape="props.shape ? props.shape : person ? 'circle' : 'square'"
      :label="(name && name[0]) || ''"
      v-tooltip="`${prettyType}: ${name}`"
    >
      <img
        v-if="picture && !picture.includes('null')"
        :src="picture"
        class="w-full aspect-square rounded-full object-cover"
      />
    </Avatar>
  </a>
</template>
