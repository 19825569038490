<template>
  <PreviewCostType v-bind="$props" @click="() => $emit('click')" />
</template>

<script>
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewAssembly',
  emits: ['click'],
  data() {
    return {}
  },
  props: {
    object: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
