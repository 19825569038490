<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :clickAway="false"
    :expandable="true"
  >
    <template #header>
      <div>
        <font-awesome-icon icon="arrows-rotate" />
        Synchronize with quickbooks
      </div>
    </template>
    <template #body>
      <sync-body ref="body" />
    </template>
    <template #footer>
      <div class="btn btn-primary" @click="done">Save</div>
    </template>
  </modal>
</template>

<script>
import SyncBody from '../bodies/AccountingSync.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalAccounting',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    done() {
      this.$refs.body.submit()
      this.$refs.modal.close()
    }
  },
  components: { SyncBody },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
