// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'template',

  possibleStatuses: ['a', 'i', 'h'],

  skipAudit: true,

  actions: {
    /* create: {
      icon: 'plus',
      class: 'btn-sm btn btn-primary',
      text: 'Create',
      clearCache: true,
      route: '/template/new',
      selectionRequired: false,
      collapse: false,
      editing: false,
    },
    edit: {
      icon: 'pencil',
      text: 'Edit/View',
      modal: false,
      action: false,
      route: ([selected]) => `/template/${selected.template_id}`,
      selectionRequired: true,
      collapse: true,
      editing: false,
    }, */
  },

  tableColumns: [
    {
      column: 'template_id',
      visible: false,
    },
    {
      column: 'template_name',
      visible: true,
      format: state => `${state.template_name}
        &nbsp;${state.template_is_default ? '<span class="badge badge-default">default</span>' : ''}`,
    },
    {
      column: 'template_type_name',
      visible: true,
    },
  ],

  fields: {
    template_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_base_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_primary_object_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
    },
    creator_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      save: false,
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false,
      save: false,
    },
    footing_template_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    heading_template_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    style_template_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    template_html: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_is_default: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    template_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      validate: {
        required: true,
      },
    },
    template_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    template_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'template_type',
      validate: {
        required: true,
      },
    },
    template_is_custom: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    template_is_override: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    template_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
      trackChanges: false,
      save: false,
    },
    oMeta: {
      type: 'object',
      save: true,
      trackChanges: true,
      deep: true,
    },
  },
};
