<template>
  <div class="activity-channel flex flex-col h-full w-full">
    <div class="activity-channel-header">
      <ActivityChannelHeader
        ref="activityHeaderRef"
        :channelType="channelType"
        :channelTypeId="channelTypeId"
      />
    </div>
    <div
      ref="activityMessagesContainerRef"
      @scroll="onMessagesScroll"
      class="activity-channel--body relative overflow-y-auto flex-1"
    >
      <div
        v-if="!hasMoreActivities && hasMoreLegacyActivities && showFetchLegacy"
        class="activity-channel--show-legacy flex flex-col items-center"
      >
        <a
          class="text-center text-sm w-full block text-secondary my-5 cursor-pointer"
          @click="onLoadLegacyActivities"
        >
          Load previous...
        </a>
      </div>
      <div class="activity-channel--items flex flex-col items-center">
        <div v-if="loading || !channel" class="activity-channel--loader absolute top-4 h-0 z-10">
          <ProgressSpinner
            style="width: 36px; height: 36px"
            strokeWidth="5"
            animationDuration="1s"
          />
        </div>
        <div v-if="!activities?.length" class="activity-channel--loader">
          <p>No activities</p>
        </div>
        <ActivityChannelItem
          v-for="(activity, index) in activities"
          :activity="activity"
          :prevActivity="activities[index - 1]"
          :parentIntersectionObserver="null"
          :channelId="channelId"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ProgressSpinner from 'primevue/progressspinner'
import useActivityChannel from '@/components/composables/ActivityChannel'
import ActivityChannelItem from '@/components/Activities/ActivityChannelItem.vue'
import { ADD_CHANNEL, REMOVE_CHANNEL } from '@/store/mutation-types'
import ActivityChannelHeader from '@/components/Activities/ActivityChannelHeader.vue'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: true
  },
  channelTypeId: {
    type: String,
    required: true
  },
  company: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const store = useStore()
const {
  loading,
  channelId,
  channelType,
  channelTypeId,
  channel,
  fetchChannelActivities,
  initChannelActivities,
  destroyChannelActivities
} = useActivityChannel(props)
// ====================== //

// ======== Refs ======== //
const activityMessagesContainerRef = ref(null)
// ====================== //

// ======== Data ======== //
const showFetchLegacy = ref(true)
// ====================== //

// ======== Computed ======== //
const hasMoreActivities = computed(() => {
  return store.getters['activityChat/getChannelHasMoreActivities'](channelId.value)
})
const hasMoreLegacyActivities = computed(() => {
  return store.getters['activityChat/getChannelHasMoreLegacyActivities'](channelId.value)
})
const activities = computed(() => {
  return store.getters['activityChat/getChannelActivities'](channelId.value)
})
// ====================== //

// ======== Methods ======== //
const onLoadLegacyActivities = () => {
  return store.dispatch('activityChat/getChannelLegacyActivities', {
    channelType: channelType.value,
    channelTypeId: channelTypeId.value,
    limit: 15
  })
}
const onMessagesScroll = async (event) => {
  const scrollHeight = event.target.scrollHeight
  const scrollTop = event.target.scrollTop

  if (scrollTop <= 0 && !loading.value) {
    if (hasMoreActivities.value) {
      await fetchChannelActivities()

      // maintain scroll positions for consistency //
      const newScrollTop = event.target.scrollTop
      const newScrollHeight = event.target.scrollHeight

      event.target.scrollTo({
        top: newScrollTop + newScrollHeight - scrollHeight
      })
    }
  }
}
const mountHandler = async () => {
  // get channel if not exists //
  if (!store.state.activityChat.channels[channelId.value]) {
    let channel = await store.dispatch('activityChat/getChannel', {
      channelId: channelId.value
    })

    if (!channel) {
      channel = {
        channel_id: channelId.value,
        channel_type: channelType.value,
        channel_type_id: channelTypeId.value,
        channel_name: props.company.company_name,
        created_at: Date.now().toString(),
        channel_members: {
          items: []
        }
      }
    }

    await store.commit(`activityChat/${ADD_CHANNEL}`, channel)
  }

  // init channel //
  await initChannelActivities()

  // scroll to bottom //
  const activityMessageElm = activityMessagesContainerRef.value
  activityMessageElm.scrollTop = activityMessageElm.scrollHeight - activityMessageElm.clientHeight
}
const unmountHandler = async () => {
  await destroyChannelActivities()
  await store.commit(`activityChat/${REMOVE_CHANNEL}`, {
    channel_id: channelId.value
  })
}
// ====================== //

// ======== Watchers ======== //
watch(channelId, async () => {
  loading.value = true
  await unmountHandler()
  await mountHandler()
})
// ====================== //

// ======== Lifecycle ======== //
onMounted(mountHandler)
onBeforeUnmount(unmountHandler)
// ====================== //
</script>

<style lang="less" scoped></style>
