<template>
  <div
    class="flex flex-row items-center !cursor-pointer !select-none"
    :class="[
      ...[
        size === 'lg'
          ? 'h-24 w-full p-3 gap-4 rounded transition'
          : ' py-4 px-4 gap-3 text-pitch-black transition rounded-sm'
      ],
      ...[
        selected
          ? size === 'lg'
            ? 'bg-cement-400 hover:bg-cement-300'
            : 'bg-cement-400 hover:bg-cement-300'
          : size === 'lg'
            ? 'bg-flame-white hover:bg-surface-100'
            : 'hover:bg-surface-100'
      ],
      ...[hovering ? 'border-dashed border-surface-300' : ''],
      ...[dragging ? 'opacity-50' : 'opacity-100']
    ]"
    :id="uid"
    @click="(e) => $emit('click', e)"
    @dblclick.self="(...args) => onDoubleClick(args)"
    @doubletap.self="(...args) => onDoubleClick(args)"
    @contextmenu.stop.prevent="contextMenu"
  >
    <div v-if="object.file_is_alias">
      <icon icon="building" v-if="String(object.file_id).includes('company')" />
      <icon icon="user" v-else-if="String(object.file_id).includes('user')" />
      <icon icon="wrench" v-else-if="String(object.file_id).includes('projects')" />
      <icon
        icon="check"
        v-else-if="
          String(object.file_id).includes('quote-') &&
          object.oMeta &&
          /k|f/.test(object.oMeta.quote_status)
        "
      />
      <icon
        icon="archive"
        v-else-if="
          String(object.file_id).includes('quote-') &&
          object.oMeta &&
          /g|h|d/.test(object.oMeta.quote_status)
        "
      />
    </div>
    <div
      :class="
        size === 'lg' ? 'flex h-full aspect-square items-center justify-center rounded-sm' : ''
      "
      @dblclick="(...args) => onDoubleClick(args)"
      @doubletap="(...args) => onDoubleClick(args)"
    >
      <img v-if="!!previewSrc" :src="previewSrc" @error="imgError = true" class="rounded-sm" />
      <icon
        v-else
        :icon="folderSrc"
        class="text-cement-800"
        :class="size === 'lg' ? 'text-5xl' : 'text-lg'"
      />
    </div>
    <div class="flex flex-col">
      <StringField
        @blur="doneRenaming()"
        :disabled="!allowRename"
        class="flex justify-start items-center leading-none"
        v-model="fileName"
      />
      <small v-if="showLabel" class="text-surface-400">
        {{ $f.datetime(object.file_time_created) }}
      </small>
    </div>

    <btn-bar
      :fixTo="`#${uid}`"
      v-show="false"
      :selected="[object]"
      :contextual="true"
      :editing="false"
      :showButton="false"
      ref="drop"
      :grid="grid"
      type="file"
    />
  </div>
</template>

<script>
import GenericFile from '../../assets/genericFile.png'
import StringField from '@/components/ui/Calculator/StringField.vue'

export default {
  name: 'File',
  emits: ['click', 'dblclick', 'contextmenu'],
  components: {
    StringField
  },
  data() {
    return {
      everCalled: false,
      rename: false,
      actions: Object.values(c.getActions('file')),
      imageFileSrc: '',
      uid: _.uniqueId(),
      imgError: false,
      imgStyle: 'min-width: 40px; min-height: 40px;',
      fileName: this.object.file_name
    }
  },
  computed: {
    isImage() {
      return /(^image).*?(png|jpg|jpeg|gif|tif|bmp|ico)/.test(this.object.file_type)
    },
    previewSrc() {
      if (this.object.file_is_folder) return false
      if (!this.isImage) return GenericFile
      if (this.imageFile && !this.imgError) return this.imageFileSrc
      return c.link(`file/pic/thumb/${this.object.file_id}`)
    },
    folderSrc() {
      const id = this.object.file_id
      if (/company-\d+/.test(id)) {
        return 'folder'
      }
      if (/\d+-projects|quote-\d+/.test(id)) {
        return 'folder-bookmark'
      }
      if (/user-\d+/.test(id)) {
        return 'folder-user'
      }
      return 'folder'
    }
  },
  methods: {
    edit() {
      this.$store.dispatch('edit', {
        type: 'file',
        id: this.object.file_id
      })
    },
    contextMenu() {
      if (!this.everCalled) {
        this.everCalled = true
        setTimeout(() => {
          this.$refs.drop.open()
          this.$emit('contextmenu')
        }, 250)
      } else {
        this.$refs.drop.open()
        this.$emit('contextmenu')
      }
    },
    doneRenaming() {
      this.rename = false
      if (this.object.file_name !== this.fileName) {
        // eslint-disable-next-line vue/no-mutating-props
        this.object.file_name = this.fileName
        this.$store.dispatch('ajax', {
          path: `file/update/${this.object.file_id}`,
          data: {
            object: {
              file_name: this.fileName
            }
          },
          scope: this.scope
        })
      }
    },
    onDoubleClick(args) {
      this.dblClickOpen ? this.edit.apply(this, args) : this.$emit('dblclick', args[0])
    }
  },
  props: {
    object: {
      required: true
    },
    grid: {
      required: false,
      default: false
    },
    imageFile: {
      required: false,
      default: false
    },
    allowRename: {
      type: Boolean,
      default: true
    },
    showLabel: {
      required: false,
      default: true
    },
    dblClickOpen: {
      required: false,
      default: true
    },
    scope: {
      required: false,
      default: null
    },
    size: {
      required: false,
      default: 'lg'
    },
    dragging: {
      required: false,
      default: false
    },
    hovering: {
      required: false,
      default: false
    },
    selected: {
      required: false,
      default: false
    }
  },
  mounted() {
    if (this.imageFile) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageFileSrc = e.target.result
      }
      reader.readAsDataURL(this.imageFile)
    }
  },
  beforeUnmount() {
    this.imageFileSrc = null
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.file {
  display: flex;
  flex-direction: column;
  max-width: 100px;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  margin: 10px;
  padding: 2px;
  border-radius: 10px;
  position: relative;

  &:hover {
    cursor: grab;
  }

  .file-overlay {
    position: absolute;
    top: 1.65em;
    color: $blue-print-400;
    mix-blend-mode: overlay;
  }

  .file-image {
    padding: 5px;
    max-width: 90px;
    max-height: 60px;
    border-radius: 3px;

    img {
      image-orientation: from-image;
      max-width: 80px;
      max-height: 50px;
      width: auto;
      height: auto;
    }
  }

  .file-label {
    cursor: text;
    margin-top: 0.2em;
    font-size: 0.8em;
    text-align: center;
    text-overflow: ellipsis;
    max-width: 100%;
    word-wrap: break-word;
    line-height: 1;
    padding: 2px 4px;
    border: 1px solid transparent;
    white-space: pre-wrap;
    font-weight: bold;
    color: $cool-gray-700;
  }

  &:not(.folder) {
    .file-image {
      img {
        border: 1px solid $cool-gray-600;
        border-radius: 3px;
      }
    }
  }

  &.selected {
    .file-image {
      background: $cool-gray-500;
      border-radius: 10px;
    }

    .file-label {
      background: $blue-print-700;
      border-radius: 3px;
      color: $flame-white;

      &[contenteditable='true'] {
        border: 1px solid $blue-print-700;
        background: $flame-white;
        color: $cool-gray-600;
        outline: none;
        margin-top: 0.2em;
        font-size: 0.8em;
        text-align: center;
        text-overflow: ellipsis;
        max-width: 100%;
        word-wrap: break-word;
        line-height: 1;
        padding: 2px 4px;
      }
    }
  }
}
</style>
