<template>
  <div class="activity-container" :class="{ self: self, 'comments-open': commentsOpen }">
    <div class="activity-separator">
      <div class="activity-connector"></div>
    </div>

    <div class="activity-inner">
      <div class="dongle">
        <span :class="dongle"></span>
      </div>
      <div class="activity card" ref="activityBox">
        <activity-header :activity="act" />
        <!-- description -->
        <div
          class="activity-content-description"
          v-if="act.activity_desc && act.activity_desc !== act.activity_type"
        >
          <font-awesome-icon icon="comments" />
          {{ act.activity_desc }}
        </div>

        <div class="activity-content" v-if="showChanges || showOldChanges">
          <!-- OLD CHANGE AUDIT -->
          <card-section class="m-0" v-if="showOldChanges">
            <template #label> Changes </template>
            <card>
              <change-audit
                :startOpen="true"
                :changes="object.oObjectChanges"
                :type="act.activity_object_type"
              />
            </card>
          </card-section>

          <!-- NEW CHANGE AUDIT -->
          <card-section class="m-0" v-if="showChanges">
            <template #label> Changes </template>

            <card>
              <change-audit
                :startOpen="true"
                :changes="denormalizedChanges"
                :type="act.activity_object_type"
              />
            </card>

            <btn class="xs info" @click="showExplicitChanges = !showExplicitChanges">
              {{
                showExplicitChanges
                  ? 'Viewing explicit changes. See all resulting changes..'
                  : 'See explicit changes only..'
              }}
            </btn>
          </card-section>
        </div>

        <div
          class="activity-content"
          v-if="!hideContent && component && act.activity_base_type !== 'ACTION'"
        >
          <component :is="component" :activity="act" />
        </div>

        <div
          class="activity-content"
          v-if="
            act.aoFiles &&
            act.aoFiles.length &&
            act.activity_base_type !== 'POST' &&
            act.activity_base_type !== 'NOTE'
          "
        >
          <card-section class="m-0">
            <file-list
              class="left"
              v-model="act.aoFiles"
              :allowCreate="false"
              :allowUpload="false"
              :allowRemove="false"
            />
          </card-section>
        </div>

        <div
          class="activity-content"
          v-if="
            act.file_ids &&
            act.file_ids.length &&
            act.activity_base_type !== 'POST' &&
            act.activity_base_type !== 'NOTE'
          "
        >
          <card-section class="m-0">
            <file-list
              class="left"
              :id-list="true"
              v-model="act.file_ids"
              :allowCreate="false"
              :allowUpload="false"
              :allowRemove="false"
            />
          </card-section>
        </div>

        <div class="activity-content" v-if="!contextual && previewComponent && act.oTarget">
          <!-- preview/activity object -->
          <component
            :contextual="contextual"
            :is="previewComponent"
            :activity="act"
            :object="act.oTarget"
            :darker="true"
            :steps="false"
          />
        </div>
        <activity-footer :activity="act" @like-click="likeClick" @comment-click="commentClick" />
      </div>
    </div>
  </div>
</template>

<script>
import ActivityHeader from './ActivityHeader.vue'
import ActivityFooter from './ActivityFooter.vue'
import Comment from './Comment.vue'
import ActivityPost from './ActivityPost.vue'
import ActivityLead from './ActivityLead.vue'

import AssemblyPreview from '../Previews/Assembly.vue'
import ClientPreview from '../Previews/Client.vue'
import CostTypePreview from '../Previews/CostType.vue'
import FilePreview from '../Previews/File.vue'
import InvoicePreview from '../Previews/Invoice.vue'
import QuotePreview from '../Previews/Quote.vue'
import UserPreview from '../Previews/User.vue'
import FileList from '../FileList.vue'

export default {
  name: 'Activity',
  emits: ['versionCheckout', 'likeClick'],
  data() {
    return {
      act: this.activity,
      commentsOpen: false,
      commentsWidth: 0,
      commentText: '',
      showExplicitChanges: true
    }
  },
  mounted() {},
  computed: {
    object() {
      return this.activity.oTarget
    },
    denormalizedChanges() {
      let diff
      if (
        this.showExplicitChanges &&
        Array.isArray(this.object.aoExplicitChanges) &&
        this.object.aoExplicitChanges.length
      ) {
        diff = this.object.aoExplicitChanges[0]
      } else if (Array.isArray(this.object.aoFullChanges) && this.object.aoFullChanges.length) {
        diff = this.object.aoFullChanges[0]
      }

      return diff ? c.denormalizeDiffSet(diff) : {}
    },

    showChanges() {
      return (
        (this.object.aoExplicitChanges &&
          this.object.aoExplicitChanges.length &&
          !Array.isArray(this.object.aoExplicitChanges[0])) ||
        (this.object.aoFullChanges &&
          this.object.aoFullChanges.length &&
          !Array.isArray(this.object.aoFullChanges[0]))
      )
    },

    showOldChanges() {
      return (
        this.object.oObjectChanges &&
        Object.keys(this.object.oObjectChanges).length &&
        this.object.activity_base_type === 'ACTION'
      )
    },

    contextual() {
      return (
        (this.filters.activity_pin_object_type === this.act.target_type &&
          this.filters.activity_pin_object_id === this.act.target_id) ||
        (this.filters.target_type === this.act.target_type &&
          this.filters.target_id === this.act.target_id)
      )
    },
    hideContent() {
      // Hide content if it is a note, where the note appears in header for a
      //  more condensed look. Also hide the content if it is an action, and
      //  this activity appears in a list of activities specifically for the actual
      //  object in question (making it redundant and maybe confusing).
      const obj = this.filters ? this.filters.target_type || false : false
      const id = this.filters ? this.filters.target_id || false : false

      return (
        ((this.activity.activity_base_type === 'NOTE' &&
          !(this.activity.file_ids && this.activity.file_ids.length) &&
          !(this.activity.aoFiles && this.activity.aoFiles.length)) ||
          (this.activity.activity_base_type === 'ACTION' &&
            this.activity.activity_object_type === obj &&
            this.activity.activity_object_id === id) ||
          ((this.act.activity_base_type !== 'ACTION' || !this.contextual) &&
            this.previewComponent === this.component)) &&
        !this.showChanges
      )
    },
    self() {
      return (
        String(this.activity.activity_creator) === String(this.$store.state.session.user.user_id) ||
        String(this.activity.activity_creator) === `user-${this.$store.state.session.user.user_id}`
      )
    },
    previewComponent() {
      switch (this.activity.target_type) {
        case 'client':
          return 'ClientPreview'
        case 'assembly':
          return 'AssemblyPreview'
        case 'cost_type':
          return 'CostTypePreview'
        case 'invoice':
          return 'InvoicePreview'
        case 'file':
          return 'FilePreview'
        case 'quote':
          return 'QuotePreview'
        case 'user':
          return 'UserPreview'
        default:
          return ''
      }
    },
    component() {
      switch (this.activity.activity_base_type) {
        case 'LEAD':
          return 'ActivityLead'
        case 'ACTION':
          switch (this.activity.target_type) {
            case 'client':
              return 'ClientPreview'
            case 'assembly':
              return 'AssemblyPreview'
            case 'cost_type':
              return 'CostTypePreview'
            case 'invoice':
              return 'InvoicePreview'
            case 'file':
              return 'FilePreview'
            case 'quote':
              return 'QuotePreview'
            case 'user':
              return 'UserPreview'
            default:
              return ''
          }
        default:
          return 'ActivityPost'
      }
    },
    dongle() {
      switch (this.activity.activity_base_type) {
        case 'EMAIL_IN':
          return 'message-arrow-down'
        case 'EMAIL_OUT':
          return 'message-arrow-up'
        case 'MESSAGE_IN':
        case 'MESSAGE_OUT':
        case 'SMS_IN':
        case 'SMS_OUT':
          return ['fas', 'comment']
        case 'CALL_IN':
          return 'phone-volume'
        case 'CALL_OUT':
          return 'phone-arrow-up-right'
        case 'LEAD':
          return 'user-plus'
        case 'ACTION':
          return 'upgrade'
        default:
          return (this.activity.aoFiles && this.activity.aoFiles.length) ||
            (this.activity.file_ids && this.activity.file_ids.length)
            ? 'paperclip'
            : 'note'
      }
    }
  },
  props: {
    firstActivity: {
      type: Boolean,
      default: false
    },
    activity: {
      required: true
    },
    filters: {
      required: false,
      default: () => {}
    }
  },
  methods: {
    versionCheckout(isLatest = false) {
      this.$emit('versionCheckout', {
        activity_id: this.activity.activity_id,
        button: this.$refs.versionBtn,
        isLatest
      })
    },
    async postComment() {
      const button = this.$refs.commentPostBtn
      button.addLoading()
      const { object } = await this.$store.dispatch('Activity/comment', {
        comment: this.commentText,
        selected: [this.activity]
      })

      this.commentText = ''

      this.act.aoComments = [object, ...this.act.aoComments]

      button.removeLoading()

      return { object }
    },
    likeClick() {
      const unlike = !!this.act.activity_liked
      this.$store.dispatch('Activity/thumb', { selected: [this.activity] })
      this.act.activity_liked = unlike ? 0 : 1
      this.act.activity_count_likes += unlike ? -1 : 1
      this.$emit('likeClick')
    },
    commentClick() {
      this.toggleComments()
    },
    toggleComments() {
      if (this.commentsOpen) this.closeComments()
      else this.openComments()
      setTimeout(() => {
        this.$refs.newCommentInput.focus()
      })
    },
    openComments() {
      this.commentsOpen = true
    },
    closeComments() {
      this.commentsOpen = false
    }
  },
  components: {
    ActivityHeader,
    ActivityFooter,
    Comment,

    ActivityPost,
    ActivityLead,

    AssemblyPreview,
    ClientPreview,
    CostTypePreview,
    FilePreview,
    InvoicePreview,
    QuotePreview,
    UserPreview,

    FileList
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$commentBg: $cool-gray-500;

.activity-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .activity-connector {
    width: 2px;
    height: 100%;
    background: linear-gradient(135deg, rgba($cool-gray-300, 0) 2%, $cool-gray-300 50%);
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 0;

    &:before {
      content: ' ';
      display: block;
      overflow: hidden;
      position: absolute;
      bottom: 0.5em;
      left: calc(-0.3em + 1px);
      width: 1px;
      height: 1px;
      background: transparent;
    }

    &:after {
      content: ' ';
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0.2em;
      left: calc(-0.3em + 1px);
      width: 1px;
      height: 1px;
      background: transparent;
    }
  }

  .activity-separator {
    height: 3em;
    position: relative;
  }

  .activity-version {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8em;
    position: relative;
    background-clip: box;
    .activity-connector {
      background: $blue-print-700;
      &:after,
      &:before {
        border-bottom-color: $blue-print-700;
      }
    }
    .btnbar {
      z-index: 2;
    }
    .activity-version-dongle {
      z-index: 2;
      border-radius: 50%;
      border: 1px solid $blue-print-700;
      width: 6em;
      height: 6em;
      font-size: 0.9em;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      .content > .text {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
      }
      line-height: 1;
      text-align: center;
      color: $blue-print-700;
      margin: 0 auto;
      background: $blue-print-200;
      overflow: hidden;
      transition: all 0.25s;
      &:hover {
        border: 1px solid $blue-print-700;
        background: $blue-print-700;
        color: $flame-white;
        cursor: pointer;
      }
    }
  }

  .activity-inner {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    z-index: 1;

    .dongle {
      display: flex;
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      z-index: 2;
      background: white;
      justify-content: space-around;
      align-items: center;
      color: $cool-gray-400;
      border: 1px solid $cool-gray-400;
      display: none;
    }

    .activity {
      /// @extend .card;
      width: 100%;
      padding: 0 !important;
      border-radius: 10px;
      .activity-content-description {
        font-weight: bold;
        background: $blue-print-700;
        color: $flame-white;
        font-size: 1.3em;
        padding: 1em;
        min-height: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        [data-icon] {
          position: absolute;
          top: 0.5em;
          left: 0.5em;
        }
      }

      .activity-content {
        margin: 0 0;
        padding: 1em;
      }
      .activity-header {
        padding: 1.5em;
      }
      .activity-footer {
        padding: 1.5em;
        padding-top: 0.75em;
        margin-top: 0;
      }
    }
  }

  &.has-comments {
    .activity-inner {
      .activity {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  &.self {
    .activity-inner {
      .activity {
        border-color: $cool-gray-200;
      }
    }
    .comments-container,
    .comments-preview {
      align-self: flex-end;
    }
  }

  .comments-container,
  .comments-preview {
    display: none;
    overflow: hidden;
    background: $cool-gray-200;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 1em;
    width: 100%;
    .comment-new {
      width: 100%;
      text-align: right;
      input {
        border: 1px solid $primary;
        border-bottom-width: 2px;
        border-bottom-right-radius: 0px;
        margin-bottom: 0px;
        box-shadow: none;
      }
      .btn {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        margin-top: 0px;
      }
    }

    .comment {
      margin-top: 1em;
    }

    &.comments-preview {
      display: block;
      .comment {
        margin-top: 0.25em;
        .comment-main {
          margin: 0;
        }
      }
      a.small {
        margin-top: 0.5em;
        color: $primary;
        font-weight: bold;
        font-size: 1.1em;
      }
    }
  }
  &.comments-open {
    .activity-inner {
      .activity {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid $commentBg;
      }
    }
    .comments-container {
      display: block;
    }
  }
}
</style>
