<template>
  <div
    contenteditable="true"
    ref="editableDiv"
    v-html="computedVal"
    tabindex
    class="content-editable-processed--element input-container"
    :class="{ empty: !computedVal }"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    @input.stop.prevent="input"
    @touchend.passive="selectAll"
    @click.stop.prevent="divClick"
    @tap.stop.prevent="divClick"
    @keydown.stop="keydownHandler"
    @keydown.up="arrow"
    @keydown.down="arrow"
    @keydown.left="arrow"
    @keydown.right="arrow"
    @keydown.delete.stop.prevent="backSpace"
    @keydown.enter.stop.prevent="enter"
    @keydown.tab.stop.prevent="tab"
    @focusout="focusoutHandler"
    @focusin="focusinHandler"
  />
</template>

<script>
export default {
  name: 'ContentEditableProcessed',

  props: {
    /**
     * Value of the EQUATION
     */
    value: {
      required: true
    },

    /**
     * If tab is pressed, blur the calculator
     */
    blurOnTab: {
      default: true
    },

    /**
     *  Variables so they can be displayed properly in the calculator
     */
    variables: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['input', 'focusin', 'focus', 'focusout', 'blur', 'keydown', 'tab'],

  data() {
    return {
      html: this.value,
      cursor: 'end',
      endLock: 1,
      valueLocal: c.removeHtml(this.value),
      focused: false,
      startedTypingSinceFocus: false,
      startedTypingSinceEmit: false,
      uid: c.uniqueId()
    }
  },

  beforeUnmount() {
    this.emit()
  },

  watch: {
    value() {
      this.importValue()
    },

    html() {
      this.valueLocal = this.getValueFromHtml()

      c.throttle(
        () => {
          this.emit()
        },
        { delay: 500 }
      )
    }
  },

  computed: {
    variableClassesStyle() {
      return Object.keys(this.variables).reduce(
        (acc, abbr) => `${acc} .${abbr}{${this.variables[abbr].style || ''};}`,
        ''
      )
    },

    variableRegEx() {
      return new RegExp(`\\b(${Object.keys(this.variables || {}).join('|')})\\b`, 'g')
    },

    computedVal() {
      let val = String(this.valueLocal)
      val = val.replace(/(\d),(\d)/g, '$1$2')
      val = val.replace(/&(nbsp|amp|quot|lt|gt);/g, ' ')

      const orfuncs = Object.keys(c.calculatorFunctions).join('|')
      const funcregx = new RegExp(`((?:${orfuncs})?[()])(?![^<]*>|[^<>]*<\\/)`, 'gi')

      let string = val

      // Next, convert newline with a [\*\+\-\/xX] cahracter to newline, with invisible
      string = string.replace(
        /(?:\r\n|\r|\n)(?:\s+)?([*+-/])(?![^<]*>|[^<>]*<\/)/g,
        '\n<newline>$1</newline>'
      )
      // Next, convert all paranthesis and functions to other color
      // .replace(/([()])(?![^<]*>|[^<>]*<\/)/g, '<parenthesis>$1</parenthesis>')
      string = string.replace(funcregx, '<parenthesis>$1</parenthesis>')
      // // First convert imperial notation
      string = string.replace(
        /((?:(\d+)\s?(?:ft|'|feet|foot))\s?(?:(\d+)\s(\d{1,2}\/\d{1,2})|(\d{1,2}\/\d{1,2})|(\d+))\s?(?:in|inch|inches|")|(?:(?:(\d+)(?:ft|'|feet|foot))(?:(\d+)))|(?:(?:(\d+)\s?(?:ft|'|feet|foot))|(?:(?:(\d+)\s)?(\d{1,2}\/\d{1,2})\s?(?:in|inch|inches|"))|(?:(\d+)\s?(?:in|inch|inches|"))))/gi,
        '<imperial>$1</imperial>'
      )
      //   // Then convert hours notation
      string = string.replace(
        /(((?:(\d+)(?:(?:\s?h(?:rs?)?(?:ours?)?\s?)|:))|(?:(\d+)(?:(?:\s?m(?:ins?)?(?:inutes?)?\s?)|:))|(?:(\d+)\s?s(?:ecs?)?(?:econds?)?)){1,3})/gi,
        '<time>$1</time>'
      )
      // Next, convert all modifiers to coloured spans
      string = string.replace(/([*+-/])(?![^<]*>|[^<>]*<\/)/g, '<modifier>$1</modifier>')
      // Next, convert all x or X characters to modifier
      string = string.replace(
        /(?:([^a-zA-Z])(?:X|x)([^a-zA-Z]))(?![^<]*>|[^<>]*<\/)/g,
        '$1<modifier>x</modifier>$2'
      )
      // Next, convert all numbers to other color

      string = string.replace(/(?!<[^<>]+>)(\d+)(?!<\/[^<>]+>)/g, '<number>$1</number>')
      // string = string.replace(/(\d+)/g, '<number>$1</number>');
      // Next, convert all notes to comments
      string = string.replace(
        /(?!<\/?)([^*+-/0-9\s></]+)(?!>)(?![^<]*>|[^<>]*<\/)/g,
        '<comments>$1</comments>'
      )

      if (this.variables && Object.keys(this.variables).length) {
        // Next convert variables
        string = string.replace(this.variableRegEx, (...matches) => {
          const abbr = matches[1]
          const variable = this.variables[abbr]
          const style = variable.style
          return `<variable style="${style}">${abbr}</variable>`
        })
      }

      return string
    }
  },

  methods: {
    hasSelection() {
      const sel = window.getSelection()
      return !!sel.toString()
    },

    log(...args) {
      console.log(`CE[${this.uid}]: `, ...args)
    },

    getValueFromHtml(html = this.html) {
      return c.removeHtml(html)
    },

    getEquation() {
      return c.removeHtml($(this.$refs.editableDiv).html())
    },

    importValue() {
      if (this.focused && (this.startedTypingSinceFocus || this.startedTypingSinceEmit)) return

      this.valueLocal = c.removeHtml(this.value)
    },

    emit() {
      const value = this.getValueFromHtml($(this.$refs.editableDiv).html())
      if (this.value === value) return

      this.startedTypingSinceEmit = false
      this.$emit('input', this.valueLocal)
    },

    focusinHandler() {
      this.focused = true
      this.$emit('focusin')
      this.$emit('focus')
    },

    focusoutHandler() {
      this.focused = false
      this.startedTypingSinceFocus = false
      this.$emit('focusout')
      this.$emit('blur')
    },

    keydownHandler(e) {
      this.startedTypingSinceFocus = true
      this.startedTypingSinceEmit = true
      this.changed(e)
      this.$emit('keydown', e)
    },

    getText() {
      return c.removeHtml(
        String($(this.$refs.editableDiv).html() || '').replace(/<\/?br\/?>/g, '\r\n')
      )
    },

    getTextLength() {
      return this.getText().length
    },

    insert(newInsert, position = this.cursor, moveCursor = true, replace = false) {
      const text = this.getText()
      const htmlArray = text
        .replace(/(^\r\n|\r\n$|^<\/?\s?br\s?\/?>|<\/?\s?br\s?\/?>$)/gi, '')
        .split('')
      htmlArray.splice(position === 'end' ? htmlArray.length : position, replace ? 1 : 0, newInsert)
      this.html = htmlArray.join('')

      if (moveCursor && position !== 'end') {
        const replaceLength = c.removeHtml(newInsert.replace(/\r\n/g, '\r')).length
        const newCursor = replace ? position + replaceLength : position + replaceLength

        this.cursor = newCursor <= 0 ? 'end' : newCursor
      }
      setTimeout(this.setCursor, 0)
    },

    arrow() {
      // do nothing, just get new cursor location
      setTimeout(() => {
        this.cursor = this.getCursorPosition()
      }, 0)
    },

    backSpace() {
      if (!this.overrideSelection()) this.replaceLastInsert('')
    },

    insertAtCursor(newInsert) {
      return this.insert(newInsert, this.cursor)
    },

    replaceLastInsert(newInsert) {
      return this.insert(
        newInsert,
        this.cursor === 'end' ? this.getTextLength() - 1 : this.cursor - 1,
        true,
        true
      )
    },

    changed(event) {
      if (event.key.length === 1 && !event.metaKey && !event.ctrlKey) {
        this.overrideSelection()
        setTimeout(() => {
          this.insertAtCursor(event.key)
        }, 0)

        event.preventDefault()
        event.stopPropagation()
        return false
      }
      return true
    },

    overrideSelection() {
      let deleted = 0
      if (
        (window.getSelection && window.getSelection()) ||
        (document.selection && document.selection.createRange && document.selection.createRange())
      ) {
        let sel
        let range
        if (window.getSelection) {
          sel = window.getSelection()
          if (sel.rangeCount) {
            deleted = sel.anchorOffset - sel.focusOffset
            range = sel.getRangeAt(0)
            range.deleteContents()
          }
        } else if (document.selection && document.selection.createRange) {
          range = document.selection.createRange()
          deleted = range.text.length
          range.text = ''
        }
      }
      // Force reset of html
      this.html = $(this.$refs.editableDiv).html()
      return deleted
    },

    input(event) {
      this.html = $(this.$refs.editableDiv).html()
      event.preventDefault()
      event.stopPropagation()
      return false
    },

    tab() {
      if (this.blurOnTab) this.blur()
      return this.$emit('tab')
    },

    enter() {
      this.insert('\r\n+ ')
    },

    focus() {
      this.setCursorAtEnd()
    },

    blur() {
      const $el = $(this.$el)
      const tabbableSelector = ':input, [contenteditable]'
      const list = $(tabbableSelector)
      const tabIndex = list.index($el)
      this.$refs.editableDiv.blur()
      if (list.length > tabIndex && tabIndex && list[tabIndex + 1] && list[tabIndex + 1].focus) {
        list[tabIndex + 1].focus()
      }
    },

    divClick() {
      this.endLock = 0
      setTimeout(() => {
        this.cursor = this.getCursorPosition()
      }, 0)
    },

    selectAll() {
      document.activeElement.blur()
      this.setCursorAt(0)
      document.execCommand('selectAll', false, null)
      // setImmediate(() => {
      //   document.execCommand('selectAll', false, null);
      // });
    },

    setCursor() {
      const $parent = $(this.$refs.editableDiv)
      const cur = this.cursor
      const textLength = $parent.text().length
      const atEnd = cur === textLength

      if (atEnd || cur === 'end') {
        this.setCursorAtEnd()
      } else {
        this.setCursorAt(Math.min(cur, textLength))
      }
    },

    setCursorAt(pos) {
      const parent = this.$refs.editableDiv
      if (pos >= 0) {
        const selection = window.getSelection()
        const range = this.createRange(parent, { count: pos })

        if (range) {
          range.collapse(false)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    },

    setCursorAtEnd() {
      this.$nextTick(() => {
        const parent = this.$refs.editableDiv
        parent.focus()
        try {
          if (
            typeof window.getSelection !== 'undefined' &&
            typeof document.createRange !== 'undefined'
          ) {
            const range = document.createRange()
            range.selectNodeContents(parent)
            range.collapse(false)
            const sel = window.getSelection()
            sel.removeAllRanges()
            sel.addRange(range)
          } else if (typeof document.body.createTextRange !== 'undefined') {
            const textRange = document.body.createTextRange()
            textRange.moveToElementText(parent)
            textRange.collapse(false)
            textRange.select()
          }
        } catch (e) {
          // do nothing
        }

        // Then scroll to bottom
        parent.scrollTop = parent.scrollHeight
        setTimeout(() => {
          parent.scrollTop = parent.scrollHeight
        }, 0)
      })
    },

    createRange(node, pos, startingRange = false) {
      const chars = pos
      let range

      if (startingRange === false) {
        range = document.createRange()
        range.selectNode(node)
        range.setStart(node, 0)
      } else {
        range = startingRange
      }

      if (chars.count === 0) {
        range.setEnd(node, chars.count)
      } else if (node && chars.count > 0) {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent.length < chars.count) {
            chars.count -= node.textContent.length
          } else {
            range.setEnd(node, chars.count)
            chars.count = 0
          }
        } else {
          for (let lp = 0; lp < node.childNodes.length; lp += 1) {
            range = this.createRange(node.childNodes[lp], chars, range)

            if (chars.count === 0) {
              break
            }
          }
        }
      }

      return range
    },

    getCursorPosition() {
      if (this.endLock) return 'end'
      const selection = window.getSelection()
      const parent = this.$refs.editableDiv
      let charCount = -1
      let node

      if (selection.focusNode) {
        if (this.nodeIsChildOf(selection.focusNode, parent)) {
          node = selection.focusNode

          if (node === parent) return parent.textContent.length
          charCount = selection.focusOffset

          // If text content is on first index of new line just a return, add one
          if (node.previousSibling && /(?:\r\n|\r|\n)$/.test(node.previousSibling.textContent))
            charCount += 1

          while (node) {
            if (node === parent) {
              break
            }

            if (node.previousSibling) {
              node = node.previousSibling
              charCount += node.textContent.length
            } else {
              node = node.parentNode
              if (node === null) {
                break
              }
            }
          }
        }
      }
      const curPos =
        parent.textContent.length === charCount
          ? 'end'
          : Math.min(charCount, parent.textContent.length)

      if (curPos === 'end') this.endLock = 1
      return curPos
    },

    nodeIsChildOf(node, parent) {
      return $(node).closest(parent).length
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
div[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
.content-editable-processed--element {
  display: inline-block;
  padding: 0.25em;
  padding-bottom: calc(0.25em - 2px);
  transition: border-color 1s;
  border-bottom: 2px solid transparent;
  outline: none;
  min-width: 1em;
  line-height: 1.75;
  &:hover {
    cursor: text;
  }
  &:focus,
  &.empty {
    border-color: $primary;
    outline: none;
  }
}
</style>
