import store from '../../store'

export const formatNumber = (val, dec = null) => {
  if (!store || !store.state.session.user) {
    return c.format(val, 'number')
  }

  const sigdec = c.getSignificantDecimals(val, dec)
  const minimumFractionDigits = sigdec
  const maximumFractionDigits = sigdec
  return c.toNum(val).toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits
  })
}

export const formatCurrency = (val, dec = 2) => {
  if (!store || !store.state.session.user || !store.state.session.company) {
    return c.format(val, 'currency')
  }

  return c.toNum(val).toLocaleString(undefined, {
    minimumFractionDigits: dec,
    maximumFractionDigits: dec
  })
}

export const formatCurrencySymbol = (val, dec = 2, code = null) => {
  if (!store || !store.state.session.user || !store.state.session.company) {
    return c.format(val, 'currency')
  }

  const currency =
    code ||
    (store.state.session.company && store.state.session.company.currency_iso) ||
    (store.state.session.user && store.state.session.user.currency_iso) ||
    'USD'
  return c.toNum(val).toLocaleString(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: dec,
    maximumFractionDigits: dec,
    currencyDisplay: 'symbol'
  })
}

export const deformatNumber = (val) => {
  if (!store || !store.state.session.user) {
    return c.format(val, 'number')
  }

  const thou = store.state.session.user.localization_thousands_separator
  const decs = store.state.session.user.localization_decimal_separator

  const noalpha = String(val).replace(/[^0-9,.]/, '')
  const computerdec = noalpha.replace(thou, '').replace(decs, '.')

  return +computerdec
}

export const deformatCurrency = (val) => deformatNumber(val)

export default {
  filters: {
    currency(val, dec = 2) {
      return formatCurrency(val, dec)
    },
    currencySymbol(val, dec = 2, code = null) {
      return formatCurrencySymbol(val, dec, code)
    },
    $(val, dec = 2, code = null) {
      return formatCurrencySymbol(val, dec, code)
    },
    number(val, dec = null) {
      return formatNumber(val, dec)
    }
  },

  methods: {
    formatNumber,
    formatCurrency,
    deformatNumber,
    deformatCurrency,
    formatCurrencySymbol,
    $$: formatCurrencySymbol
  }
}
