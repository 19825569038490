<template>
  <div class="flex flex-row justify-between items-center min-h-20 px-4 py-2">
    <div class="max-w-[50%]">
      <span class="font-medium">{{ permission.permission_name }}</span>
      <small class="hidden md:block text-muted">
        {{ permission.permission_desc }}
      </small>
    </div>

    <div class="flex justify-start *:mr-16 *:w-36">
      <div>
        <template
          v-if="
            String(rolePermission.permission_self) === '1' ||
            String(rolePermission.permission_others) === '1'
          "
        >
          <strong v-if="String(rolePermission.permission_self) === '1'">&nbsp;Self</strong>
          <strong v-if="String(rolePermission.permission_others) === '1'"
            >&nbsp;+&nbsp;Others</strong
          >
        </template>
        <template v-else>
          <strong class="text-muted">-</strong>
        </template>
      </div>

      <div>
        <div v-if="user.user_is_admin">Admin Privileges</div>
        <choose
          v-else
          :allowDeselect="true"
          placeholder="Choose..."
          :return-array="false"
          :staticSet="staticSet"
          v-model="permissionValue"
        />
      </div>

      <div class="!mr-0">
        <template
          v-if="
            String(permission.permission_self) === '1' &&
            !user.user_is_admin &&
            String(permission.permission_others) !== '1'
          "
        >
          Self Only
        </template>
        <template v-else-if="String(permission.permission_others) === '1' || user.user_is_admin">
          Self + Others
        </template>
        <template v-else> Not Allowed </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  emits: ['saving', 'saved'],
  data() {
    return {}
  },
  watch: {},
  computed: {
    userPermission() {
      // return this.user.aoUserPermissions[this.index];
      return this.user.aoUserPermissions.filter(
        (permission) => permission.permission_id === this.permission.permission_id
      )[0]
    },
    rolePermission() {
      // return this.user.aoRolePermissions[this.index];
      return this.user.aoRolePermissions.filter(
        (permission) => permission.permission_id === this.permission.permission_id
      )[0]
    },
    staticSet() {
      return [
        {
          value: 'defer',
          text: 'Defer to role'
        },
        {
          value: 'self',
          text: 'Self only'
        },
        {
          value: 'others',
          text: 'Self + Others'
        },
        ...(this.rolePermission.permission_self
          ? [
              {
                value: 'retract',
                text: 'Retract'
              }
            ]
          : [])
      ]
    },
    permissionValue: {
      set(value) {
        let userPermission = {
          user_id: this.user.user_id,
          permission_id: this.permission.permission_id,
          user_permission_id: this.userPermission.user_permission_id || null
        }
        switch (value) {
          case 'self':
            userPermission = {
              ...userPermission,
              user_permission_self: 1,
              user_permission_others: 0
            }
            this.save(userPermission)
            break
          case 'others':
            userPermission = {
              ...userPermission,
              user_permission_self: 1,
              user_permission_others: 1
            }
            this.save(userPermission)
            break
          case 'retract':
            userPermission = {
              ...userPermission,
              user_permission_self: 0,
              user_permission_others: 0
            }
            this.save(userPermission)
            break
          default:
            userPermission = {
              ...userPermission
            }
            this.delete(userPermission)
        }
      },
      get() {
        if (String(this.permission.permission_others) === '1') return 'others'
        else if (String(this.permission.permission_self) === '1') return 'self'
        else if (this.permission.permission_granted_by_type === 'user_permission') return 'retract'
        return 'defer'
      }
    }
  },
  methods: {
    async save(userPermission) {
      this.$emit('saving')

      try {
        await this.$store.dispatch('ajax', {
          path: 'user_permission/save',
          data: { object: userPermission }
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.userMessage || e.message,
          error: true
        })
        throw e
      }

      this.$emit('saved')

      return this
    },
    async delete(userPermission) {
      this.$emit('saving')

      if (!userPermission.user_permission_id) {
        return this
      }

      await this.$store.dispatch('ajax', {
        path: `user_permission/delete/${userPermission.user_permission_id}`
      })

      this.$emit('saved')

      return this
    }
  },
  components: {},
  props: {
    index: {
      required: true
    },
    user: {
      required: true
    },
    permission: {
      required: true
    }
  },
  created() {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.btn-success {
  background-color: $matcha-500;
}
.btn-danger {
  background-color: $deep-red-800;
}
</style>
