export function getChangeOrderSteps(object, store) {
  let steps = []

  const canApprove = store.state.session.user.aUserPerms.quote.approve

  if (!object.change_order_company_has_approved && canApprove) {
    steps = [
      ...steps,
      {
        name: 'These changes need to be approved by a manager.',
        short: 'Approve as manager',
        action: (obj, button, grid) =>
          store.dispatch('ChangeOrder/markApprovedByCompany', {
            id: obj.change_order_id,
            button,
            grid
          }),
        glyph: 'check',
        color: 'green-500',
        go: false
      }
    ]
  } else if (!object.change_order_company_has_approved && !canApprove) {
    steps = [
      ...steps,
      {
        name: 'Waiting for changes to be approved by a manager.',
        short: 'Waiting for approval by manager',
        action: () => {},
        glyph: 'clock',
        color: 'surface-600',
        go: false
      }
    ]
  } else if (
    object.change_order_status !== 'k' &&
    !object.change_order_client_has_approved &&
    !object.change_order_time_sent
  ) {
    steps = [
      ...steps,
      {
        name: 'This change order has not been sent.',
        short: 'Send',
        action: (obj, button, grid) =>
          store.dispatch('send', {
            id: _.makeArray(obj)[0].quote_id,
            type: 'quote',
            button,
            grid
          }),
        glyph: 'paper-plane',
        color: 'green-500',
        go: false
      }
    ]
  } else if (
    object.change_order_status !== 'k' &&
    !object.change_order_client_has_approved &&
    object.change_order_time_sent
  ) {
    steps = [
      ...steps,
      {
        name: `This change order was sent to the client on ${_.format(object.change_order_time_sent, 'date')}.`,
        short: 'Resend',
        action: (obj, button, grid) =>
          store.dispatch('send', {
            id: _.makeArray(obj)[0].quote_id,
            type: 'quote',
            button,
            grid
          }),
        glyph: 'paper-plane',
        color: 'green-500',
        go: false
      }
    ]
  } else if (
    object.change_order_status === 'k' &&
    (!store.state.session.company.aoModules.presentation ||
      store.state.session.company.company_project_signature_is_required) &&
    !object.auth_file_id
  ) {
    steps = [
      ...steps,
      {
        name: 'This change order is approved but no contract file is attached.',
        short: 'Add contract file',
        action: (obj, button, grid) =>
          store.dispatch('ChangeOrder/addContract', {
            id: obj.change_order_id,
            button,
            grid
          }),
        glyph: 'plus',
        color: 'green-500',
        go: false
      }
    ]
  }

  return steps
}
