<template>
  <div class="flex flex-row items-center justify-start gap-x-2 flex-nowrap">
    <div
      v-if="showCheck"
      @click="grid.onRowUnselect"
      class="flex justify-center items-center min-w-5 w-5 min-h-5 h-5 ml-2 border rounded hover:bg-surface-200 transition"
      :class="selected ? 'border-surface-900' : 'border-surface-300'"
    >
      <icon v-if="selected" icon="check" />
    </div>
    <Button v-if="showEdit" ref="editButton" text label="Edit" @click="go" />
    <div v-if="avatar && $slots.image"><slot name="image"></slot></div>
    <div class="flex flex-col items-start justify-center max-w-full w-full">
      <div
        class="basis-1/2 flex flex-row items-center justify-start font-medium gap-2 text-ellipsis overflow-hidden text-nowrap max-w-full w-full"
      >
        <slot name="primary"></slot>
      </div>
      <div
        class="basis-1/2 flex flex-row items-start justify-start gap-4 text-ellipsis overflow-hidden text-nowrap max-w-full w-full"
      >
        <slot name="secondary"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Slots
 * -primary (title/top-left text area)
 * -secondary (sub-title/bottom-left text area)
 * -image (icon or image or avatar)
 * -middle
 * -right
 */
export default {
  name: 'PreviewBase',
  methods: {
    go() {
      return this.$store.dispatch('edit', {
        type: this.object.type,
        id: this.object[`${this.object.type}_id`],
        button: this.$refs.editButton
      })
    },
    edit() {
      return this.go()
    }
  },
  props: {
    avatar: {
      required: false,
      default: true
    },
    steps: {
      required: false,
      default: true
    },
    right: {
      required: false,
      default: true
    },
    middle: {
      required: false,
      default: true
    },
    canOpen: {
      required: false,
      default: true
    },
    object: {
      required: false
    },
    showEdit: {
      default: false
    },
    grid: {
      Type: Object,
      required: false
    },
    selected: {
      required: false,
      default: 0
    },
    showCheck: {
      required: false,
      default: false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.clickable {
  cursor: pointer;
}

.preview.preview-component-container {
  .hover-btn {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
  display: flex;
  justify-content: flex-start !important;
  align-content: center;
  align-items: center;
  height: 4em;
  max-width: 100%;
  overflow: hidden;

  .image {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-right: 10px;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    flex-shrink: 10;
    flex-grow: 10;
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
    overflow: hidden;

    .primary {
      font-size: 1.1em;
      font-weight: normal;
      color: $cool-gray-700;
      white-space: nowrap;
      line-height: 1.2;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: visible;
    }
    .secondary {
      font-size: 0.9em;
      font-color: $cool-gray-600;
      white-space: nowrap;
      line-height: 1.2;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: first baseline;
        > * {
          margin-right: 0.25em;
          white-space: nowrap;
        }
        > strong {
          pointer-events: none;
        }
      }
    }
  }
  .middle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    flex: 3 0 15%;
    margin-right: auto;
    margin-left: 3em;
    text-align: right;
    margin-left: auto;
    margin-right: 0;
    justify-content: flex-end;
  }
  .right {
    margin-left: 5px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    height: 100%;
    flex: 0 1 40%;
    max-width: 400px;
    text-align: right;
  }
  &.dark {
    .info {
      .primary {
        color: $cool-gray-500;
      }
      .secondary {
        color: $cool-gray-500;
      }
    }
  }
  &:hover {
    .hover-btn {
      opacity: 1;
    }
  }
}

.dark {
  .preview {
    .info {
      .primary {
        color: $cool-gray-500;
      }
      .secondary {
        color: $cool-gray-500;
      }
    }
  }
}
</style>
