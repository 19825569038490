import _ from '../../../../imports/api/Helpers'

export const ratingSummary = ({ ratings }) => {
  const starCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  let totalRatings = ratings.length
  let sumOfRatings = _.sumBy(ratings, (rating) => rating.overallRating)

  ratings.forEach((rating) => {
    const stars = rating.overallRating
    starCount[stars]++
  })

  if (totalRatings === 0) {
    return {
      totalRatings,
      averageRating: 0,
      percentages: {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0
      }
    }
  }

  const averageRating = (sumOfRatings / totalRatings).toFixed(1)

  const ratingPercentages = {}
  let totalPercentage = 0
  let maxPercentage = { star: 0, value: 0 }
  for (let i = 1; i <= 5; i++) {
    const percentage = Math.round((starCount[i] / totalRatings) * 100)
    ratingPercentages[i] = percentage
    totalPercentage += percentage
    if (percentage > maxPercentage.value) {
      maxPercentage = { star: i, value: percentage }
    }
  }

  if (totalPercentage !== 100) {
    ratingPercentages[maxPercentage.star] += 100 - totalPercentage
  }

  return {
    totalRatings,
    averageRating: parseFloat(averageRating),
    percentages: {
      one: ratingPercentages[1],
      two: ratingPercentages[2],
      three: ratingPercentages[3],
      four: ratingPercentages[4],
      five: ratingPercentages[5]
    }
  }
}
