import {
  UPDATE_EVENT_NOTIFICATIONS,
  ADD_EVENT_NOTIFICATIONS,
  CLEAR_EVENT_NOTIFICATIONS
} from '../../mutation-types'

const initialState = {
  notifications: {}
}

// initial state
const state = {
  ...initialState
}

// getters
const getters = {}

// actions
const actions = {
  async updateEventNotifications({ commit }, { notifications, type }) {
    commit({
      type: UPDATE_EVENT_NOTIFICATIONS,
      payload: { notifications, type }
    })
  },
  async addEventNotifications({ commit }, { notifications, type }) {
    commit({
      type: ADD_EVENT_NOTIFICATIONS,
      payload: {
        notifications,
        type
      }
    })
  },
  async clearEventNotifications({ commit }, { type }) {
    commit({
      type: CLEAR_EVENT_NOTIFICATIONS,
      payload: {
        type
      }
    })
  }
}

// mutations
const mutations = {
  [UPDATE_EVENT_NOTIFICATIONS](state, { payload }) {
    const { type, notifications = [] } = payload
    // Ensure we're not mutating state directly, creating a new object for notifications
    state.notifications = {
      ...state.notifications,
      [type]: [...(state.notifications[type] || []), ...(notifications || [])]
    }
  },
  [CLEAR_EVENT_NOTIFICATIONS](state, { payload }) {
    const { type } = payload
    // Create a new object and set the specified type to an empty array
    state.notifications = {
      ...state.notifications,
      [type]: []
    }
  },
  [ADD_EVENT_NOTIFICATIONS](state, { payload }) {
    const { type, notifications = [] } = payload
    // Create a new object for notifications
    state.notifications = {
      ...state.notifications,
      [type]: notifications
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
