<template>
  <div class="activity-footer">
    <activity-actions
      :activity="activity"
      @like-click="() => $emit('likeClick')"
      @comment-click="() => $emit('commentClick')"
    />
  </div>
</template>

<script>
import ActivityActions from './ActivityActions.vue'

export default {
  name: 'ActivityFooter',
  emits: ['likeClick', 'commentClick'],
  data() {
    return {}
  },
  props: {
    activity: {
      required: true
    }
  },
  components: { ActivityActions }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.activity-footer {
  border-top: 1px solid $cool-gray-600;
  margin-bottom: -7px;
}
</style>
