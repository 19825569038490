// Import FontAwesome Core
import { config, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import regular icons
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
// Import solid icons
import { fas } from '@fortawesome/pro-solid-svg-icons'
// Import brand icons
import {
  faStackOverflow,
  faCcVisa,
  faCcMastercard,
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons'
// Import Duotone Icons
import {
  faMoneyBillTransfer as fadMoneyBillTransfer,
  faWallet as fadWallet,
  faMoneyFromBracket as fadMoneyFromBracket,
  faMapLocationDot as fadMapLocationDot,
  faComments as fadComments,
  faPhone as fadPhone,
  faUserSecret as fadUserSecret
} from '@fortawesome/pro-duotone-svg-icons'

// Add our used icons here
library.add(
  far,
  fas,
  faStackOverflow,
  fadMoneyBillTransfer,
  fadWallet,
  fadMoneyFromBracket,
  fadMapLocationDot,
  fadComments,
  fadPhone,
  faCcVisa,
  faCcMastercard,
  faFacebook,
  faInstagram,
  faXTwitter,
  faLinkedin,
  fal,
  fadUserSecret
)

// Set our default style to FaRegular so we can use shorter syntax
config.styleDefault = 'light'

export default FontAwesomeIcon
