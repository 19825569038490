// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'template_type',
  
  skipAudit: true,

  fields: {
    template_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_base_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    template_type_primary_object_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
