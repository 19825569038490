<template>
  <component
    :is="tag"
    class="input-container"
    :contenteditable="contenteditable && !disabled"
    :placeholder="value ? '' : placeholder"
    @input="update"
    @focusin="fwdEv"
    @focusout="fwdEv"
    @focus="update"
    @blur="update"
    @paste="onPaste"
    @keypress="onKeypress"
    @keydown="fwdEv"
    @keyup="fwdEv"
    @mouseenter="fwdEv"
    @mouseover="fwdEv"
    @mousemove="fwdEv"
    @mousedown="fwdEv"
    @mouseup="fwdEv"
    @auxclick="fwdEv"
    @click="fwdEv"
    @dblclick="fwdEv"
    @contextmenu="fwdEv"
    @wheel="fwdEv"
    @mouseleave="fwdEv"
    @mouseout="fwdEv"
    @select="fwdEv"
    @pointerlockchange="fwdEv"
    @pointerlockerror="fwdEv"
    @dragstart="fwdEv"
    @drag="fwdEv"
    @dragend="fwdEv"
    @dragover="fwdEv"
    @dragleave="fwdEv"
    @drop="fwdEv"
    @transitionstart="fwdEv"
    @transitioncancel="fwdEv"
    @transitionend="fwdEv"
    @transitionrun="fwdEv"
    @compositionstart="fwdEv"
    @compositionupdate="fwdEv"
    @compositionend="fwdEv"
    @cut="fwdEv"
    @copy="fwdEv"
    ref="element"
  >
  </component>
</template>
<script>
const replaceAll = (str, search, replacement) => str.split(search).join(replacement)

export default {
  name: 'ContentEditable',
  props: {
    value: {
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    contenteditable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noHTML: {
      type: Boolean,
      default: true
    },
    noNL: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['input', 'returned'],
  mounted() {
    this.update_content(this.value)
  },
  computed: {},
  data() {
    return {}
  },
  methods: {
    current_content() {
      return this.noHTML ? this.$refs.element.innerText : this.$refs.element.innerHTML
    },
    update_content(newcontent) {
      if (this.noHTML) {
        this.$refs.element.innerText = newcontent
      } else {
        this.$refs.element.innerHTML = newcontent
      }
    },
    update() {
      this.$emit('input', this.current_content())
    },
    onPaste(event) {
      event.preventDefault()
      let text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (this.noNL) {
        text = replaceAll(text, '\r\n', ' ')
        text = replaceAll(text, '\n', ' ')
        text = replaceAll(text, '\r', ' ')
      }
      window.document.execCommand('insertText', false, text)
      this.fwdEv(event)
    },
    onKeypress(event) {
      if (event.key === 'Enter' && this.noNL) {
        event.preventDefault()
        this.$emit('returned', this.current_content)
      }
      this.fwdEv(event)
    },
    fwdEv(event) {
      this.$emit(event.type, event)
    }
  },
  watch: {
    value(newval) {
      if (newval !== this.current_content()) {
        this.update_content(newval)
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
div[contenteditable] {
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  min-width: 1em;
  white-space: pre-wrap;
  position: relative;
  box-sizing: border-box;
  background: $cool-gray-300;
  &:hover {
    cursor: text;
    background: rgba($blue-print-700, 0.1);
    color: $blue-print-600;
  }
  &:focus,
  &.empty {
    outline: none;
    background: rgba($blue-print-700, 0.2);
    color: $blue-print-600;
  }

  // Placeholder
  &:before {
    color: $cool-gray-300;
    content: attr(placeholder);
    width: 100%;
  }
}
</style>

<style lang="scss" rel="stylesheet/scss">
div[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
div[contenteditable][notracks]::-webkit-scrollbar {
  display: none;
}
div[contenteditable][notracks]::-webkit-scrollbar-thumb {
  background: transparent;
}
div[contenteditable][notracks]::-webkit-scrollbar-track {
  background: transparent;
}
</style>
