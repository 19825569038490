// Drawing helpers
import DrawUtilities from './DrawUtilities'

const {
  drawCircleWithCaret,
  icons,
  presetFormats,
  disabledColor,
  disabledBackground,
  stroke,
  concrete,
  gray,
  lightGray,
  lightGrayTrans,
  black,
  blue,
  blueTrans,
  warningTrans,
  drawCheckmark,
  interpolateColor,
  initializeCanvas,
  createCanvas,
  drawIcon
} = DrawUtilities

export default {
  useDraw(args) {
    const { scaleFactor, defaultFormatting } = args

    const getDrawingFont = (format) => {
      const {
        fontFamily = defaultFormatting.fontFamily,
        fontSize = defaultFormatting.fontSize,
        bold = defaultFormatting.bold,
        italic = defaultFormatting.italic
      } = format
      const scaledSize = fontSize * scaleFactor.value
      return `${bold ? '500 ' : ''}${italic ? 'italic ' : ''}${scaledSize}px ${fontFamily}`
    }

    const measureCanvas = createCanvas(1000, 1000)
    const measureText = (text, format = defaultFormatting) => {
      const { ctx } = measureCanvas
      ctx.font = getDrawingFont(format)
      return ctx.measureText(text)
    }

    const drawText = (ctx, clipTo, text, format = {}) => {
      text = String(text).trim()
      const {
        fontSize = defaultFormatting.fontSize,
        align = defaultFormatting.align,
        verticalAlign = defaultFormatting.verticalAlign,
        color = defaultFormatting.color,
        wordWrap = defaultFormatting.wordWrap,
        padding = [0, 0, 0, 0]
      } = format

      ctx.font = getDrawingFont(format)

      const [x, y, width, height] = clipTo

      const originalLines = text.split(/\r?\n/) // Split by newline
      const lines = []

      originalLines.forEach((lineContent) => {
        if (wordWrap) {
          const words = lineContent.split(' ')
          let line = ''

          for (let n = 0; n < words.length; n += 1) {
            const testLine = `${line}${words[n]} `
            const metrics = ctx.measureText(testLine)
            const testWidth = metrics.width
            if (testWidth > width && n > 0) {
              lines.push(line.trim())
              line = `${words[n]} `
            } else {
              line = testLine
            }
          }
          lines.push(line.trim())
        } else {
          lines.push(lineContent)
        }
      })

      for (let l = 0; l < lines.length; l += 1) {
        const lineText = lines[l]
        const textWidth = ctx.measureText(lineText).width

        // Text alignment
        let xPos = x + 5
        if (align === 'center') {
          xPos = x + (width / 2 - textWidth / 2)
        } else if (align === 'right') {
          xPos = x + (width - textWidth - 5)
        }

        const fullHeight = lines.length * fontSize
        const textMiddle = fullHeight / 2
        const cellLines = height / 2 - textMiddle
        const tweak = fontSize / 6

        let yPos = y + cellLines + (l + 1) * fontSize - tweak

        if (verticalAlign === 'top') {
          yPos = y + fontSize * (l + 1) + tweak + padding[0]
        } else if (verticalAlign === 'bottom') {
          yPos = y + (height - fullHeight) + fontSize * (l + 1) - tweak * 2 - padding[2]
        }

        ctx.save()
        ctx.beginPath()
        ctx.rect(...clipTo)
        ctx.clip()
        ctx.fillStyle = color
        ctx.fillText(lineText, xPos + padding[3], yPos)
        ctx.restore()
      }
    }

    // const drawIcon = drawIcon;
    // const drawIcon = (ctx, clipTo, icon, format = {}) => drawText(
    //   ctx,
    //   clipTo,
    //   icons[icon].ch,
    //   {
    //     ...format,
    //     fontFamily: icons[icon].ff,
    //   });

    return {
      ...DrawUtilities,
      drawText,
      drawIcon,
      measureText,

      drawCircleWithCaret,
      icons,
      presetFormats,
      disabledColor,
      disabledBackground,
      stroke,
      concrete,
      gray,
      lightGray,
      lightGrayTrans,
      black,
      blue,
      blueTrans,
      warningTrans,
      drawCheckmark,
      interpolateColor,
      initializeCanvas,
      createCanvas
    }
  }
}
