<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <file :object="object" :showLabel="false" />
    </template>
    <template #primary>
      <div>
        {{ object.file_name }}
      </div>
    </template>
    <template #secondary>
      <div>
        <div v-if="object.file_is_folder">
          Folder&nbsp;·&nbsp;{{ $f.datetime(object.file_time_created) }}
        </div>
        <div v-else>
          {{ Math.round((object.file_size / 1048576) * 100) / 100 }} MB&nbsp;·&nbsp;{{
            $f.datetime(object.file_time_created)
          }}
        </div>
      </div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import File from '../../Files/File.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewFile',
  emits: ['click'],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    Preview,
    File
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    showEdit: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
