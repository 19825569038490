<template>
  <div class="activity-message-item w-full flex overflow-hidden my-3 px-4">
    <slot class="w-full" />
    <div class="activity-message-item--btns flex justify-center items-center overflow-hidden w-0">
      <BtnBar
        severity="tertiary"
        :link="true"
        :collapse="true"
        btnClass="!border-none flex items-center justify-center"
        :actions="dropActions"
      >
        <template #button>
          <Btn rounded link severity="tertiary" :stop="false" :prevent="false" ref="button">
            <font-awesome-icon :icon="['fas', 'ellipsis']" size="lg"></font-awesome-icon>
          </Btn>
        </template>
      </BtnBar>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// ======== Props ======== //
import useActivityItem from '@/components/composables/ActivityItem'

const props = defineProps({
  activity: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const { isUserActivity, activityType } = useActivityItem(props)
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['onReply', 'onEdit', 'onDelete'])
// ====================== //

// ======== Computed ======== //
const dropActions = computed(() => [
  {
    title: 'Reply',
    action: emitReply
  },
  ...(isUserActivity.value && activityType.value === 'Message'
    ? [
        {
          title: 'Edit',
          action: emitEdit
        },
        {
          title: 'Delete',
          action: emitDelete
        }
      ]
    : [])
])
// ====================== //

// ======== Methods ======== //
const emitReply = () => {
  emit('onReply')
}
const emitEdit = () => {
  emit('onEdit')
}
const emitDelete = () => {
  emit('onDelete')
}
// ====================== //
</script>

<style lang="scss" scoped>
.activity-message-item {
  transition: width 0.25s ease-in-out;

  &:hover {
    .activity-message-item--btns {
      width: 2rem;
      margin-left: 0.25rem;
      opacity: 1;
    }
  }

  .activity-message-item--btns {
    opacity: 0;
    transition:
      width 0.25s ease-in-out,
      opacity 0.25s ease-in-out;
  }
}
</style>
