import * as types from '../../mutation-types'
import UserError from '../../../../imports/api/UserError'

// initial state
const state = {
  alerts: []
}

const alertDefault = {
  type: 'alert',
  warnings: [],
  timeout: 4000, // 0 = forever, 1 = 1ms
  error: false,
  warning: false,
  /**
   * Text or message must be given
   */
  text: '',
  message: '',
  uid: '',
  loading: 0,
  muted: 0,
  success: null,
  actions: []
}

// getters
const getters = {}

// actions
const actions = {
  /**
   * Will run the function provided, try/catch
   * and alert if there is an error and do nothing if there
   * is no error.  It will check for '
   * @param dispatch
   * @param payload
   *    @param fn Function            function to call and try
   *    @param success String|Null    default success message, if not null
   *                                  then there will always be an alert on success.
   *                                  If there is a userMessage or message in the payload
   *                                  returned from the promise returned from the fn function,
   *                                  that will be the success message, otherwise the provided
   *                                  default will be used.  The default success message MUST be provided
   *                                  for there to be an alert on success.
   *    @param error String           The default error message. There will ALWAYS be an alert
   *                                  when an error occurs, regardless whether a default error message
   *                                  is provided or not.  The default message will be used when
   *                                  a userMessage is not found in the error provided.  To avoid
   *                                  script errors displaying to the user, the message value from the
   *                                  erorr will never be used.
   *   @returns Promise<{Object { error: Bool, userMessage: String }}>
   *
   *   @example
   *      await this.$store.dispatch('alertOnError', () => this.$store.dispatch('User/updatePassword', {
            password: this.newPassword,
            again: this.newPasswordConfirm,
            alert: true,
          }));

   */
  async alertOnError({ dispatch }, payload) {
    // If they provided only the function as the main
    // argument, build out a payload object from it
    const pyld = typeof payload === 'function' ? { fn: payload } : payload

    const { fn, success = null, error = 'There was an error. Please try again.' } = pyld

    let returnPayload
    try {
      returnPayload = await fn()
    } catch (e) {
      const errorMessage = (e && e.userMessage) || error
      if (e && !e.alreadyAlerted) {
        dispatch('alert', {
          error: true,
          message: errorMessage
        })
      }

      return {
        ...(e || {}),
        error: true,
        userMessage: errorMessage,
        message: errorMessage,
        alreadyAlerted: true
      }
    }

    const successMessage = returnPayload.userMessage || returnPayload.message || success
    if (success && !returnPayload.alreadyAlerted) {
      dispatch('alert', {
        message: successMessage
      })
    }

    return {
      ...returnPayload,
      error: false,
      userMessage: successMessage,
      message: successMessage,
      alreadyAlerted: true
    }
  },

  /**
   * Show an alert to the user.
   * @param commit
   * @param alert
   *    @param error Boolean    default false
   *    @param message String   message to alert
   *    @param warnings Array   array of strings of warnings to add under the message
   *    @param timeout Int      time alert shoudl be visible for
   * @returns {Promise<any>}
   */
  async alert({ commit, state }, alertPayload) {
    const alert = { ...alertDefault, ...alertPayload, uid: c.uniqueId() }

    if (
      state.alerts.find(
        (al) =>
          al.message === alert.message && al.error === alert.error && al.warning === alert.warning
      )
    ) {
      // Already got the message!
      return
    }

    commit({
      type: types.ALERT_ADD,
      alert
    })

    return alert
  },

  async error({ dispatch }, error) {
    const userMessage =
      error instanceof UserError || error.userMessage
        ? error.userMessage
        : 'Something went wrong. Please try again.'

    return dispatch('alert', {
      error: true,
      message: userMessage
    })
  },

  async removeAlert({ commit }, { alert }) {
    commit({
      type: types.ALERT_REMOVE,
      alert
    })
  }
}

// mutations
const mutations = {
  [types.ALERT_ADD](state, { alert }) {
    state.alerts = [..._.imm(state.alerts), alert]
  },
  [types.ALERT_REMOVE](state, { alert }) {
    const filtered = _.imm(state.alerts).filter((a) => String(a.uid) !== String(alert.uid))
    state.alerts = filtered
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
