<template>
  <section class="mt-0 md:mt-8">
    <p class="text-lg">Please select which type of legal entity your company is registered as:</p>
    <div class="flex w-full mt-2 gap-4 flex-col md:flex-row">
      <Card
        @click="() => onSelectEntityType('organization')"
        :selected="value === 'organization'"
        :button="true"
        class="text-center text-surface-800"
      >
        <template #title> Organization</template>
        <span class="mt-2">
          An organization often involves multiple individuals or stakeholders. They require formal
          documentation, such as articles of incorporation or partnership agreements. Examples
          include corporations, partnerships, and LLCs.
        </span>
      </Card>
      <Card
        @click="() => onSelectEntityType('soleProprietorship')"
        :button="true"
        :selected="value === 'soleProprietorship'"
        class="text-center text-surface-800"
      >
        <template #title> Sole proprietorship </template>
        <span class="text-surface-600 mt-2">
          A sole proprietorship is a business where a single individual runs and manages the
          business. In this structure, there's no legal distinction between the owner and the
          business entity.
        </span>
      </Card>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CounterpartyOnboardingLegalEntity',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onSelectEntityType(value) {
      this.$emit('input', value)
    }
  },
  mounted() {
    if (!this.value) {
      this.$emit('input', 'soleProprietorship')
    }
  }
}
</script>
