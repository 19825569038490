<template>
  <!-- Files component (List) -->
  <Files type="file" :isMain="isMain" :filters="filters" ref="grid" class="w-full h-full" />
</template>

<script>
import Files from '../Files/List.vue'
import BodyMixin from '../mixins/Body'
import SelectorBodyMixin from '../mixins/SelectorBody'

export default {
  mixins: [BodyMixin, SelectorBodyMixin],
  components: {
    Files
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.loaded = true
  }
}
</script>
