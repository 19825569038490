import { ref } from 'vue'

export default {
  useDebug() {
    const debug = ref(false)

    const benches = ref({})

    const startBench = (name) => (benches.value[name] = Date.now())

    const endBench = (name) => delete benches.value[name]

    const bench = (name, phase = '0', ...rest) => {
      if (!(name in benches.value)) startBench(name)

      if (debug.value && import.meta.env.NODE_ENV === 'dev')
        console.log(`..bench.. ${name} -> ${phase}`, Date.now() - benches.value[name], _.imm(rest))
    }

    return {
      debug,
      bench,
      startBench,
      endBench
    }
  }
}
