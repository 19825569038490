<template>
  <Accordion unstyled v-model:activeIndex="activeIndex">
    <AccordionTab
      :pt="{
        header: {
          class: ['py-0 px-0']
        },
        headerIcon: {
          class: ['shrink-0']
        },
        headerAction: {
          class: [
            'whitespace-nowrap',
            'text-left',
            {
              'bg-surface-200': object.asRequiredDimensions?.length && !missingDimensions.length,
              '!text-surface-400 font-normal': !object.asRequiredDimensions?.length,
              'bg-deep-red-500 text-flame-white':
                object.asRequiredDimensions?.length && missingDimensions.length
            },
            'h-10 w-full'
          ]
        }
      }"
      :headerClass="[
        {
          'bg-transparent': !missingDimensions.length
        },
        'text-sm',
        '!rounded-none !mb-0 w-full'
      ]"
      :contentClass="['p-0']"
      :class="[missingDimensions.length ? 'p-error' : '', '!rounded-none !mb-0']"
    >
      <template #header>
        <div class="w-full">
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center">
              <div class="flex flex-col text-left">
                <span
                  :class="[
                    'whitespace-nowrap',
                    {
                      '!text-surface-400 font-normal': object.asRequiredDimensions?.length < 1
                    }
                  ]"
                >
                  {{
                    $route.name === 'Super Quote' && !object.parentRefId
                      ? 'Top-Level Dimensions'
                      : names[names.length - 1]
                  }}

                  <span v-if="!object.parentRefId" class="text-xs">(Project root)</span>

                  <font-awesome-icon
                    v-if="missingDimensions.length"
                    icon="exclamation-circle"
                    class="ml-2"
                    v-tooltip="'Missing dimensions'"
                  />
                </span>
              </div>
            </div>

            <span
              v-if="object.asRequiredDimensions?.length"
              v-tooltip="
                `${object.asRequiredDimensions?.length} required dimensions in this assembly`
              "
            >
              {{ object.asRequiredDimensions?.length }}
            </span>
          </div>
        </div>
      </template>
      <area-dimensions
        v-if="activeIndex === 0"
        v-model="dimensions"
        :showDerivedDimensions="showDerivedDimensions"
        :object="object"
        :store="store"
        :reference="reference"
        :key="reference"
      />
    </AccordionTab>
  </Accordion>
</template>

<script>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import AreaDimensions from './AreaDimensions.vue'
import AssemblyDimensionsSummaryMixin from './AssemblyDimensionsSummaryMixin'
import eventBus from '@/eventBus.js'

export default {
  name: 'AreaDimensionCollapse',
  mixins: [AssemblyDimensionsSummaryMixin],
  components: {
    Accordion,
    AccordionTab,
    AreaDimensions
  },
  data() {
    return {
      activeIndex: null,
      isHighlighted: false
    }
  },
  mounted() {
    eventBus.$on(`closeDimensions`, this.handleClose)
    eventBus.$on(`openDimensions-${this.reference}`, this.handleOpen)
  },
  beforeUnmount() {
    eventBus.$off(`closeDimensions`, this.handleClose)
    eventBus.$off(`openDimensions-${this.reference}`, this.handleOpen)
  },
  methods: {
    handleOpen() {
      c.scrollTo(this.$el)
      this.activeIndex = 0
    },
    handleClose() {
      this.activeIndex = null
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
