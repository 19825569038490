import { toRefs, computed, reactive } from 'vue'
import Normalize from '@/../imports/api/Normalize'
import { useStore } from 'vuex'

const { getNormalizedRootRefId, normalizeSet } = Normalize

export const initialState = {
  items: [],
  discount: 0,
  unInvoiced: 0,
  invoiceDiscount: 0
}

let context = reactive({
  ...initialState
})

export default () => {
  const store = useStore()

  const addToItems = (item) => context.items.push(item)

  const removeFromItems = (item) => {
    const key = Object.keys(context.items).find((k) => context.items[k].item_id === item.item_id)
    context.items.splice(key, 1)
  }

  const toggleFromItems = ({ item, selected }) => {
    if (selected) {
      const find = context.items.find((i) => i.item_id === item.item_id)
      if (!find) addToItems(item)
      return
    }
    removeFromItems(item)
  }

  const recalculate = (items = null) => {
    const costItems = items || context.items
    return costItems.reduce(
      (acc, item) => {
        const net = item.item_net || item.cost_item_net || item.cost_item_price_net || 0
        const gross = item.item_gross || item.cost_item_gross || item.cost_item_price_gross || 0
        acc.net += net
        acc.gross += gross
        return acc
      },
      { net: 0, gross: 0 }
    )
  }

  const applyDiscount = (net, gross) => {
    const discountAmount = net * context.discount
    const discountedGross = gross - discountAmount
    return discountedGross
  }

  const itemizedTotal = computed(() => {
    if (context.items.length === 0) return 0
    const calculations = recalculate(context.items)
    return applyDiscount(calculations.net, calculations.gross)
  })

  const normalized = computed(() => {
    const [norm] = normalizeSet(context.items.map((i) => i))
    return norm
  })

  const toggleAllItems = (selected) => {
    const keys = Object.keys(normalized.value)
    keys.forEach((key) => {
      const item = normalized.value[key]
      toggleFromItems({ item, selected })
    })
  }

  const toggleItems = (selected, items) => {
    items.forEach((item) => {
      toggleFromItems({ item, selected })
    })
  }

  const itemizedEnabled = computed(
    () =>
      store.state.session.company.aoModules &&
      store.state.session.company.aoModules.itemized_payments &&
      parseInt(store.state.session.company.aoModules.itemized_payments, 10) === 1
  )

  const rootRefId = computed(() => getNormalizedRootRefId(context.items.map((i) => i)))

  const toggleBundle = (selected = false, items = []) => {
    items.forEach((item) => {
      toggleFromItems({ item, selected })
    })
  }

  const getPercentageOfProject = (projectTotal, items) => {
    const calculations = recalculate(items)
    const amount = applyDiscount(calculations.net, calculations.gross)
    return Math.ceil((amount / projectTotal) * 100)
  }

  const resetItemizedPayments = () =>
    (context = reactive({
      ...initialState
    }))

  return {
    ...toRefs(context),
    addToItems,
    removeFromItems,
    toggleFromItems,
    recalculate,
    applyDiscount,
    toggleAllItems,
    toggleItems,
    resetItemizedPayments,
    toggleBundle,
    getPercentageOfProject,
    itemizedTotal,
    normalized,
    rootRefId,
    itemizedEnabled
  }
}
