<template>
  <div class="activity-channel-members">
    <Sidebar v-model:visible="show" position="right">
      <template #header>
        <p class="text-xl font-medium">Group chat</p>
      </template>
      <p class="font-medium">{{ channelMembers?.length }} total members</p>
      <div
        v-for="(member, index) in channelMembers"
        class="activity-channel--member flex mt-4"
        :key="index"
      >
        <PersonAvatar
          shape="circle"
          :name="`${member.user_fname || '?'}`"
          :id="member?.user_id"
          type="user"
          size="lg"
          :full="true"
          class="h-12 w-12 inline-flex items-center justify-center rounded-full bg-surface-0 border"
        />
        <div class="activity-channels--channel--details mx-4 flex-1 overflow-hidden">
          <p class="font-medium text-ellipsis overflow-hidden">
            {{ member.user_fname }} {{ member.user_lname }}
          </p>
          <p class="text-xs font-normal text-surface-300 text-ellipsis overflow-hidden">
            {{ member.user_is_admin ? 'Admin' : 'Member' }}
          </p>
        </div>
        <div>
          <BtnBar
            class="cursor-pointer"
            :actions="[
              {
                title: 'View profile',
                visible: false,
                action: () => onViewProfile(member)
              },
              {
                title: 'Direct chat',
                action: () => onDirectChat(member)
              }
            ]"
            :collapse="true"
          >
            <template #button>
              <font-awesome-icon :icon="['fas', 'ellipsis']" />
            </template>
          </BtnBar>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import Sidebar from 'primevue/sidebar'
import { ref } from 'vue'
import useActivityChannel from '@/components/composables/ActivityChannel'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ADD_CHANNEL } from '@/store/mutation-types'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: true
  },
  channelTypeId: {
    type: String,
    required: true
  }
})
// ====================== //

// ======== Data ======== //
const show = ref(false)
// ====================== //

// ======== Composables ======== //
const { channelMembers } = useActivityChannel(props)
const store = useStore()
const router = useRouter()
// ====================== //

// ======== Methods ======== //
const toggleShowSidebar = () => {
  show.value = !show.value
}
const onViewProfile = (member) => {
  console.log(member)
}
const onDirectChat = async (member) => {
  const scopeRoute = router.currentRoute.value.params.scopeRoute
  const memberUserIds = [
    member.user_id,
    store.state.session.authorizedUser?.user_id || store.state.session.user.user_id
  ].sort()
  const channel = {
    channel_id: `CHAT-${memberUserIds[0]}-${memberUserIds[1]}`,
    channel_type: `CHAT`,
    channel_type_id: `${memberUserIds[0]}-${memberUserIds[1]}`,
    channel_name: '',
    created_at: Date.now().toString(),
    channel_members: {
      items: [
        {
          user_id: member.user_id,
          user_fname: member.user_fname || '',
          user_lname: member.user_lname || '',
          channel_type: 'CHAT',
          user_is_admin: true,
          channel_id: `CHAT-${memberUserIds[0]}-${memberUserIds[1]}`
        },
        {
          user_id: store.state.session.authorizedUser?.user_id || store.state.session.user.user_id,
          user_fname:
            store.state.session.authorizedUser?.user_fname ||
            store.state.session.user.user_fname ||
            '',
          user_lname:
            store.state.session.authorizedUser?.user_lname ||
            store.state.session.user.user_lname ||
            '',
          channel_type: 'CHAT',
          user_is_admin: true,
          channel_id: `CHAT-${memberUserIds[0]}-${memberUserIds[1]}`
        }
      ]
    }
  }

  store.commit(`activityChat/${ADD_CHANNEL}`, channel)
  return router.push(`/${scopeRoute}/messages/${channel.channel_type}/${channel.channel_type_id}`)
}
// ====================== //

// ======== Expose ======== //
defineExpose({
  toggleShowSidebar
})
// ====================== //
</script>
