<template>
  <div class="tab-pane p-1" v-show="shown">
    <slot v-if="everShown"></slot>
  </div>
</template>

<script>
export default {
  name: 'TabPane',
  data() {
    return {
      everShown: this.renderImmediately
    }
  },
  computed: {
    shown() {
      const shown = this.$parent.tab === this.name
      this.everShown = this.everShown || shown
      return shown
    }
  },
  props: {
    name: {
      required: true
    },
    label: {
      required: false
    },
    glyph: {
      required: false,
      default: false
    },
    renderImmediately: {
      required: false,
      default: false
    },
    dot: {
      required: false,
      default: false
    },
    badge: {
      required: false,
      default: false
    }
  },
  mounted() {
    this.$parent.addPane(this)
  },
  beforeUnmount() {
    this.$parent.removePane(this)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
