<template>
  <div class="lg:grid" :class="[!hidePreview ? 'lg:grid-cols-2 h-full' : '']">
    <SelectButton
      :pt="{
        root: 'w-full',
        label: '!font-medium',
        button: 'w-6/12 flex justify-center !py-3'
      }"
      :ptOptions="{ mergeProps: true }"
      v-if="isMobileView"
      v-model="view"
      :options="viewOptions"
    >
      <template #option="{ option }">
        <font-awesome-icon class="mr-3" :icon="['far', option.icon]" />
        {{ option.value }}
      </template>
    </SelectButton>
    <div v-if="isMobileEditor" class="bg-white h-full relative">
      <div
        class="px-2 bg-white"
        :class="[
          !hidePreview ? 'lg:px-5 lg:py-5 left-0 shadow w-full fixed lg:w-[50%] z-10' : 'max-w-full'
        ]"
      >
        <h2 v-if="!hidePreview" class="w-full text-center !font-medium hidden lg:block">
          Custom form editor
        </h2>
        <div
          :class="!hidePreview ? 'py-4' : 'py-2'"
          class="flex gap-3 items-center justify-center overflow-x-auto"
        >
          <Btn
            :key="field.label"
            outlined
            @click="() => selectFieldType(field)"
            size="lg"
            :pt="{ root: '!border-pitch-black !rounded-3xl !px-3 min-w-36' }"
            severity="contrast"
            v-for="field in fieldTypesAndFormatting[0].items.slice(0, filterCount)"
          >
            <template #icon>
              <font-awesome-icon :icon="['far', field.icon]" />
            </template>
            {{ field.label }}
          </Btn>
          <Btn
            v-if="fieldTypesAndFormatting[0].items.length > filterCount"
            @click.native="toggleFilterMenu"
            outlined
            :pt="{ root: '!border-pitch-black !rounded-3xl !px-3 min-w-24' }"
            size="lg"
            severity="contrast"
          >
            More...
          </Btn>
          <Menu
            ref="filterMenu"
            :model="fieldTypesAndFormatting[0].items.slice(filterCount)"
            :popup="true"
          >
            <template #item="{ item }">
              <a
                v-if="item"
                v-ripple
                class="flex items-center cursor-pointer py-1 px-2 my-1"
                @click="() => selectFieldType(item)"
              >
                <span class="mr-2" v-if="item.icon">
                  <font-awesome-icon :icon="['far', item.icon]" />
                </span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </template>
            <template #submenuheader="{ item }">
              <p class="text-sm font-medium text-cool-gray-500">{{ item.label }}</p>
            </template>
          </Menu>
        </div>
      </div>
      <div :class="[!hidePreview ? 'p-6 pt-28 lg:pt-36' : 'p-0']">
        <h3 class="text-center w-full !font-medium block md:hidden">Form editor</h3>
        <CardSection v-if="!formName && !hideName">
          <CardList>
            <CardListField>
              <span>Form name</span>
              <Field type="text" v-model="form_name" schema="form:form_template_name" />
            </CardListField>
          </CardList>
        </CardSection>

        <CardSection>
          <Draggable
            v-model="fields"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            item-key="uid"
          >
            <template #item="{ element: field, index }">
              <FieldItem class="mb-2" :item="field" :index="index" />
            </template>
          </Draggable>
        </CardSection>
      </div>
    </div>
    <div
      v-if="!hidePreview"
      v-show="(isMobileView && view.value === 'Preview') || !isMobileView"
      class="p-6 h-full bg-cool-gray-100"
    >
      <h3 class="!mb-2 text-center w-full !font-medium">Form preview</h3>
      <div class="px-12 pt-4 bg-white rounded h-full">
        <h1 class="text-center my-8">{{ form_name }}</h1>
        <Form
          class="h-full min-h-80"
          v-if="aoFields && aoFields.length > 0"
          :aoFields="aoFields"
          :isPreview="true"
          :aoFieldOptions="aoFieldOptions"
        />
        <p
          class="text-xl text-cool-gray-700 text-center flex h-full w-full items-center justify-center"
          v-else
        >
          Please use the form builder from the left to create your custom lead form. You can preview
          the form here.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineProps, defineEmits, onMounted } from 'vue'
import SelectButton from 'primevue/selectbutton'
import Menu from 'primevue/menu'
import Form from '@/components/forms/Form.vue'
import useForm from '@/components/forms/Form'
import useCustomField from '@/components/forms/CustomField'
import { useDeviceStore } from '@/stores/device'
import useBreakpoint from '@/components/composables/Breakpoint'
import Draggable from 'vuedraggable'
import FieldItem from '@/components/ui/listItem/FieldItem.vue'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'

Draggable.compatConfig = { MODE: 3 }
SelectButton.compatConfig = { MODE: 3 }

const type = 'form'
const storeName = 'Form'

const emit = defineEmits(['changes'])

const props = defineProps({
  hidePreview: {
    type: Boolean,
    default: false
  },
  formName: {
    type: String,
    default: ''
  },
  hideName: {
    type: Boolean,
    default: false
  },
  refId: {
    type: String
  }
})

const deviceStore = useDeviceStore()
const { aoFields, aoFieldOptions, form_name } = EntityComputedFields.useEntityComputedFields({
  refId: props.refId,
  type,
  store: storeName
})
const {
  refId: formRefId,
  fields,
  fieldTypesAndFormatting,
  customFieldOptionsToArray,
  resetForm,
  selectFieldType,
  mapFieldsForBuilder,
  mapFieldOptionsForBuilder
} = useForm()
const { options, resetCustomField } = useCustomField()
const { isTablet, isMobile, isDesktop, isWideScreen } = useBreakpoint()

const draggingDelay = deviceStore.isNative ? 400 : 100
const dragOptions = ref({
  animation: 200,
  delay: draggingDelay,
  ghostClass: 'ghost',
  filter:
    '.loader, input, [btn-component], .button--container, textarea, .nodrag, .calculator-container, .field-component, [field-component], [toggle-component], .toggle-component, .el-switch',
  preventOnFilter: false,
  fallbackOnBody: true
})

const views = [
  { icon: 'pencil', value: 'Editor' },
  { icon: 'eye', value: 'Preview' }
]

const drag = ref(false)
const filterMenu = ref()
const view = ref(views[0])
const viewOptions = ref(views)

const isMobileView = computed(() => isMobile.value)

const filterCount = computed(() => {
  if (isMobile.value) return 2
  if (isTablet.value) return 3
  if (isDesktop.value) return 3
  if (isWideScreen.value) return 4
  return 4
})

const isMobileEditor = computed(
  () => (isMobileView.value && view.value.value === 'Editor') || !isMobileView.value
)

// watch for field changes to trigger isDirty
watch(
  fields,
  (newFields) => {
    aoFields.value = [...newFields]
    emit('changes', { aoFields: newFields })
  },
  { deep: true }
)
// watch for option changes to trigger isDirty
watch(
  options,
  (newOptions) => {
    const customFieldOptions = customFieldOptionsToArray(newOptions)
    aoFieldOptions.value = [...customFieldOptions]
    emit('changes', { aoFieldOptions: newOptions })
  },
  { deep: true }
)

watch(
  () => props.formName,
  () => {
    if (props.formName) form_name.value = props.formName
  }
)

const toggleFilterMenu = (event) => filterMenu.value.toggle(event)

onMounted(() => {
  resetForm()
  resetCustomField()
  formRefId.value = props.refId
  fields.value = mapFieldsForBuilder(aoFields.value || [])
  options.value = mapFieldOptionsForBuilder(aoFieldOptions.value)
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
