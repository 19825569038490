// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


import _ from '../Helpers';

export default {
  type: 'trade_type',
  
  skipAudit: true,

  fields: {
    trade_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    trade_type_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    trade_type_desc: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    labor_type_id: {
      type: 'string',
      mapTo: 'labor_type',
      save: true,
    },
    labor_type_name: {
      type: 'string',
    },
    labor_type_rate_net: {
      type: 'float',
      filter: true,
      save: false,
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company',
    },
  },

  generateVueActions() {
    return {
      async getDefaultLaborType({ dispatch }, { id }) {
        const { object } = await dispatch('LaborType/filter', {
          filters: {
            trade_type_ids: `INSET${id}`,
          },
          limit: 1,
        }, { root: true });

        return { object: object || null };
      },
    };
  },
};
