export default {
    'Review all': 'Revisar tudo',
    'Review all items': 'Revisar todos os itens',
    'Selections': 'Seleções',
    'Click to toggle expand':  'Clique para expandir',
    'Please make a selection above before continuing': 'Faça uma seleção acima antes de continuar',
    'This is a required selection': 'Esta é uma seleção obrigatória',
    'Category': 'Categoria',
    'Product': 'Produto',
    'Quantity': 'Quantidade',
    'Price': 'Preço',
    'Management fee': 'Taxa de administração',
    'not included': 'não incluído',
    'each': 'cada',
    'No item name': 'Sem nome de item',
    'Review the details of this quote': 'Revisar os detalhes desta cotação',
    'tax on {taxOn} derived from {taxType}': 'imposto sobre {taxOn} derivado de {taxType}',
    'loan': 'empréstimo',
    'From': 'De',
    'Cancel loan': 'Cancelar empréstimo',
    'month': 'mês',
    'Loan payment': 'Pagamento do empréstimo',
    'powered by': 'distribuído por',
    'See projects': 'Ver projetos',
    'Rate your experience': 'Avalie sua experiência',
    'How would you rate your recent experience with': 'Como você avaliaria sua experiência recente com',
    'Submit review': 'Enviar avaliação',
    'Review submitted': 'Avaliação enviada',
    'Anonymous': 'Anônimo',
    'You are a read-only reviewer': 'Você é um revisor de somente leitura',
    'This proposal has expired and is no longer valid': 'Esta proposta expirou e não é mais válida',
    'Request a new proposal': 'Solicitar uma nova proposta',
    'No selection required by customer': 'Nenhuma seleção exigida pelo cliente',
    "Thank you for your submission! We will get back to you as soon as possible": "Obrigado pelo seu envio! Entraremos em contato com você o mais breve possível.",
    "Submission Successful": "Envio Bem-Sucedido",
    "Could not find form": "Não foi possível encontrar o formulário",
    "This form does not exist. Please double check the url": "Este formulário não existe. Por favor, verifique o URL.",
    "Saving form submission": "Salvando envio do formulário",
    "Submit": "Enviar",
    "To get started creating an estimate, please select the options provided below": "Para começar a criar uma cotação, selecione as opções fornecidas abaixo",
    "About us": "Sobre nós",
    "Get a quote": "Obter uma cotação",
    "Company rating": "Classificação da empresa",
    "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "Um de nossos agentes dedicados revisará seus requisitos para fornecer uma resposta personalizada. Verifique sua caixa de entrada para confirmação e detalhes. Estamos entusiasmados em ajudar a tornar sua casa dos sonhos uma realidade.",
    "You will receive a copy of the estimate by email for your records": "Você receberá uma cópia da estimativa por e-mail para seus registros.",
    "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Obrigado pelo seu envio! Recebemos seus dados e já estamos trabalhando na criação de uma estimativa para o seu projeto. Entraremos em contato com você em breve para discutir a estimativa e os próximos passos.",
    "First Name": "Primeiro Nome",
    "Last Name": "Último Nome",
    "Email": "E-mail",
    "Phone": "Telefone",
    "Suite": "Suíte",
    "Address": "Endereço",
    "City": "Cidade",
    "Zip Code / Postal Code": "Código Postal / CEP",
    "Project Details": "Detalhes do Projeto",
    "Project Type": "Tipo de Projeto",
    "Project Budget": "Orçamento do Projeto",
    "Project Timeline": "Cronograma do Projeto",
    "Tell us about your project.": "Conte-nos sobre o seu projeto.",
    "Tell us the type of project you need.": "Diga-nos o tipo de projeto que você precisa.",
    "Tell us your budget for the project.": "Informe-nos o seu orçamento para o projeto.",
    "Tell us when you want the project to start and any other important dates.": "Diga-nos quando você deseja iniciar o projeto e qualquer outra data importante.",
    "Add to estimate": "Adicionar à estimativa",
    "Remove from estimate": "Remover da estimativa",
    "Add": "Adicionar",
    "Save": "Economizar",
    "Included": "Incluído",
    "Not included": "Não incluído",
    "Same price": "Mesmo preço",
    "Learn more": "Saiba mais",
    "Select": "Selecionar",
    "Optional item details": "Detalhes do item opcional",
    "No description available": "Nenhuma descrição disponível",
    "Description": "Descrição",
    "Features": "Características",
    "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.": "A disponibilidade de melhorias pode variar de acordo com as circunstâncias específicas do projeto. As imagens são exemplos e podem não representar exatamente como aparecem no seu projeto.",
    "Cancel": "Cancelar",
    "Upgrade details": "Detalhes da melhoria",
    "Select this": "Selecione isto",
    "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Por favor, preencha o formulário abaixo e geraremos instantaneamente um orçamento personalizável. Você poderá fazer upgrades, refinar suas seleções e enviar uma solicitação de proposta.",
    "View options": "Ver opções",
    "Welcome to your interactive estimate": "Bem-vindo à sua estimativa interativa",
    "In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.": "Nesta proposta, seu empreiteiro ou construtor especificou alguns itens que exigem uma escolha sua antes que seu projeto possa começar.",
    "There are also items marked 'Optional', which you can choose to add to your project if you wish.": "Também há itens marcados como 'Opcional', que você pode escolher adicionar ao seu projeto, se desejar.",
    "Thanks, and enjoy your experience using Bolster's Interactive Estimates!": "Obrigado e aproveite sua experiência usando as Estimativas Interativas da Bolster!",
    "Dismiss": "Dispensar",
    "Download relevant files to enhance your project or documentation such as contracts, invoices, receipts, images, or any other supporting documents.": "Baixe arquivos relevantes para aprimorar seu projeto ou documentação, como contratos, faturas, recibos, imagens ou outros documentos de apoio.",
    "The achievements and recognitions demonstrate our expertise and commitment, helping you feel confident in your decision.": "As conquistas e reconhecimentos demonstram nossa expertise e compromisso, ajudando você a se sentir confiante em sua decisão.",
    "Awards and badges": "Prêmios e distintivos",
    "Attached files": "Arquivos anexados"
};
