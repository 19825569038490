import { computed, ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { ScreenOrientation } from '@capacitor/screen-orientation'

export const useDeviceStore = defineStore('device', () => {
  const platform = computed(() => Capacitor.getPlatform())
  const isNative = computed(() => Capacitor.isNativePlatform())
  const isTouch = computed(() => matchMedia('(hover: none)').matches)

  /** `deviceName` may be `undefined`, particularly when not on a mobile device */
  const deviceName = ref()
  const deviceModel = ref()
  const operatingSystem = ref()
  const osVersion = ref()
  const isMobile = computed(() => {
    return operatingSystem.value === 'ios' || operatingSystem.value === 'android'
  })
  const isMobileBrowser = computed(() => platform.value === 'web' && isMobile.value)
  const isVirtual = ref()
  /** `memUsedBytes` may be `undefined`, particularly when not on a mobile device */
  const memUsedBytes = ref(0)
  /** `memUsed` will be `NaN` when `memUsedBytes` is `undefined` */
  const memUsed = computed(() => memUsedBytes.value / 1048576)
  const deviceId = ref()

  Device.getInfo().then((deviceInfo) => {
    deviceName.value = deviceInfo.name
    deviceModel.value = deviceInfo.model
    operatingSystem.value = deviceInfo.operatingSystem
    osVersion.value = deviceInfo.osVersion
    isVirtual.value = deviceInfo.isVirtual
    memUsedBytes.value = deviceInfo.memUsed
  })
  Device.getId().then((deviceIdResult) => {
    deviceId.value = deviceIdResult.identifier
  })

  const orientation = ref()
  const isPortrait = computed(() => {
    return orientation.value === 'portrait-primary' || orientation.value === 'portrait-secondary'
  })
  const isLandscape = computed(() => {
    return orientation.value === 'landscape-primary' || orientation.value === 'landscape-secondary'
  })
  ScreenOrientation.orientation().then((orientationResult) => {
    orientation.value = orientationResult.type
  })
  ScreenOrientation.addListener('screenOrientationChange', (orientationResult) => {
    orientation.value = orientationResult.type
  })

  const languageCode = ref('en')
  Device.getLanguageCode().then((languageCodeResult) => {
    languageCode.value = languageCodeResult.value
  })

  const hasCamera = ref(false)
  if (window.isSecureContext) {
    // This is not available when running in unsecure context (such as dev mode for mobile)
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      hasCamera.value = devices.some((device) => device.kind === 'videoinput')
    })
  }

  return {
    platform,
    isNative,
    isTouch,
    isMobileBrowser,
    deviceName,
    deviceModel,
    operatingSystem,
    osVersion,
    isMobile,
    isVirtual,
    memUsedBytes,
    memUsed,
    deviceId,
    orientation,
    isPortrait,
    isLandscape,
    languageCode,
    hasCamera
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
