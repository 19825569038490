<template>
  <CardComponent
    class="h-full transition"
    @click.native="() => $emit('click')"
    v-bind="$props"
    :pt="pt"
    :ptOptions="{ mergeProps: true }"
    :class="[
      noBorder ? 'border-none' : '',
      border ? 'border border-surface-200' : '',
      rounded ? 'rounded' : '',
      button ? 'hover:bg-surface-100/50 cursor-pointer' : '',
      selected ? '!border-cement-800' : '',
      flat ? 'shadow-none' : 'shadow shadow-surface-200/30',
      highlight ? `!border-l-8 !border-${highlight}` : ''
    ]"
  >
    <template #title v-if="$slots.title || $slots.icon">
      <span class="icon mr-2" v-if="$slots.icon">
        <slot name="icon"></slot>
      </span>
      <slot name="title" v-if="$slots.title"></slot>
    </template>
    <template #subtitle v-if="$slots.subtitle">
      <slot name="subtitle"></slot>
    </template>
    <template #content v-if="$slots.default || $slots.text">
      <slot name="text"></slot>
      <div class="relative">
        <slot></slot>
        <Loader v-if="loading" :loading="loading" />
      </div>
    </template>
    <template #footer v-if="$slots.footer || $slots.actions || $slots.footing || $slots.loader">
      <slot name="footer"></slot>
      <slot name="actions"></slot>
      <slot name="footing"></slot>
    </template>
  </CardComponent>
</template>

<script>
import CardComponent from 'primevue/card'

export default {
  name: 'Card',
  components: {
    CardComponent
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    iconTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Number,
      default: 0
    },
    pt: {
      type: Object,
      default: () => {}
    },
    highlight: {
      type: String
    }
  },
  emits: ['click']
}
</script>
