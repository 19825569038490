// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'transaction',

  possibleStatuses: ['q', 'r', 'c', 'x'],

  skipAudit: false,

  fields: {
    transaction_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    source_account_id: {
      type: 'string',
      mapTo: 'account'
    },
    company_id: {
      type: 'int',
      mapTo: 'company'
    },
    vendor_id: {
      type: 'int',
      mapTo: 'vendor'
    },
    vendor_company_id: {
      type: 'int',
      mapTo: 'company'
    },
    aoSplits: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewSplit'
    },
    transaction_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    transaction_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    transaction_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    transaction_time_last_modified: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    transaction_time_completed: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    transaction_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    tax_percentage: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    transaction_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      visible: true
    },
    transaction_gross: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      visible: true
    },
    transaction_tax: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    transaction_total: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    transaction_fee: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      visible: true
    },
    root_quote_id: {
      type: 'float',
      filter: true,
      mapTo: false,
      visible: true
    },
    root_company_id: {
      type: 'float',
      filter: true,
      mapTo: false
    },
    transaction_is_record_only: {
      type: 'int',
      filter: true,
      mapTo: false,
      default: 1
    },
    transaction_reference: {
      type: 'string',
      filter: true,
      mapTo: false,
      default: ''
    },
    oMeta: {
      type: 'object',
      filter: false
    },
    transaction_processing_time_delay_interval: {
      type: 'int',
      filter: false,
      format: 'hours'
    },
    transaction_time_cancelled: {
      type: 'int',
      filter: false,
      format: 'datetime'
    },
    transaction_time_approved: {
      type: 'int',
      filter: false,
      format: 'datetime'
    },
    transaction_cancelled_by: {
      type: 'string',
      filter: false,
      mapTo: 'user'
    },
    transaction_approved_by: {
      type: 'string',
      filter: false,
      mapTo: 'user'
    },
    currency_id: {
      type: 'int',
      filter: false,
      visible: true
    },
    invoice_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'invoice'
    }
  },

  generateVueActions() {
    return {
      async projectToVendor({ dispatch }, payload) {
        const { itemAmounts, quote_id, recordOnly = true, transaction: transData = {} } = payload

        const transaction = await dispatch('ajax', {
          path: `transaction/projectToVendor`,
          data: {
            itemAmounts,
            recordOnly,
            quote_id,
            transaction: transData
          }
        })

        return { ...payload, transaction, object: transaction }
      }
    }
  }
}
