<template>
  <div class="assembly-dimensions--container contain">
    <Danger v-if="circMessage" class="sm">
      <p style="white-space: pre-line !important">{{ circMessage }}</p>
    </Danger>
    <transition-group name="fade">
      <dimension
        v-for="dimension in filteredDimensions"
        :key="dimension.abbr"
        :value="dimension"
        :store="store"
        :showDerivedDimensions="showDerivedDimensions"
        :object="object"
        :reference="reference"
        @input="(dim) => setDimension(dimension.abbr, dim)"
        required
        :inAssembly="store === 'Assembly'"
        :class="{
          inherit: dimension.inherit && object.parentRefId,
          hasValue: dimension.value,
          'contain-item': true
        }"
      />
      <div
        class="assembly-dimensions--dimension-container contain-item flex items-center justify-center"
        key="loader"
        v-if="loading"
        style="background: transparent !important"
      >
        <LoadingIndicator />
      </div>
    </transition-group>
  </div>
</template>

<script>
import AssemblyDimensionsMixin from './AssemblyDimensionsMixin'
import Dimension from './AssemblyDimension.vue'
import LoadingIndicator from '../../ui/LoadingIndicator.vue'

export default {
  name: 'AssemblyDimensions',
  mixins: [AssemblyDimensionsMixin],
  components: {
    LoadingIndicator,
    Dimension
  },
  computed: {
    filteredDimensions() {
      return this.mappedRequiredDimensions.filter(
        (dimension) => !dimension.demote || this.showDerivedDimensionsLocal
      )
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.assembly-dimensions--container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.75em;
  border-radius: 0.25em;
  max-width: 100%;
  margin: 0;
}

.contain {
  width: 100%;
  position: relative;
  > span {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.contain-item {
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0px);
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 1s;
}

.fade-leave {
}

// NEED TO ADD POSITION: ABSOLUTE TO THE ELEMENT FOR REMOVAL
.fade-leave-active {
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(0);
  position: absolute;
}

.fade-move {
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      transition: transform 0.4s #{$i * 0.1}s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
}
</style>
