<template>
  <div
    data-component="choose"
    :class="[
      { 'absolute pointer-events-none hidden': headless },
      full ? 'w-full' : 'w-fit min-w-10'
    ]"
  >
    <!-- button -->
    <div class="w-full" v-if="!headless" ref="triggerContainer" @click.capture.stop.prevent="open">
      <slot :text="singleTextDisplay" :textValues="formattedValue" :value="rawValue">
        <!-- potential alternative button style -->
        <Btn @click="open" :size="size" :class="btnClasses" severity="tertiary">
          {{ $f.truncate(singleTextDisplay, 30) }}
          <font-awesome-icon icon="chevron-down" class="ml-2" />
        </Btn>
      </slot>
    </div>

    <!-- drop components -->
    <div v-if="isOpen" ref="outer" class="choose-drop absolute" :style="{ zIndex: zIndex }">
      <!-- mask -->
      <div
        @click.stop.prevent="close()"
        class="fixed top-0 bottom-0 left-0 right-0 bg-surface-900 opacity-40"
      />

      <!-- drop -->
      <div
        class="fixed flex flex-col bottom-0 right-0 h-[80vh] w-full sm:w-[40em] bg-flame-white rounded-tl-lg"
      >
        <div>
          <!-- header -->
          <div class="flex flex-col p-4 pb-4 gap-6">
            <div class="flex flex-row gap-x-2 items-center">
              <!-- close button -->
              <div>
                <btn link rounded :action="close" v-if="!multiple">
                  <font-awesome-icon icon="remove" size="lg" />
                </btn>
                <btn link class="rounded" size="lg" :action="close" v-else> Done </btn>
              </div>

              <!-- search input -->
              <input
                ref="input"
                v-model="searchPhraseLocal"
                class="rounded border border-surface-300 px-2 w-full min-h-10 h-10"
              />

              <!-- refresh button -->
              <btn rounded link :action="refresh" :loading="loading">
                <font-awesome-icon icon="arrows-rotate" size="lg" />
              </btn>
              <!-- create button -->
              <btn rounded :action="create" v-if="canCreate" severity="bolster">
                <font-awesome-icon icon="fas fa-plus" size="lg" />
              </btn>
            </div>

            <!-- selected values -->
            <div class="flex flex-wrap gap-1 mb-3" v-if="!forceNotEmpty">
              <div
                class="flex items-center gap-x-2 text-flame-white rounded transition cursor-pointer px-2 py-1 bg-cement-800 hover:bg-cement-700"
                v-for="(text, index) in formattedValue"
                :key="index"
                @click.capture.stop.prevent="removeIndex(index)"
              >
                <span>{{ text }}</span>
                <font-awesome-icon :icon="['far', 'times']" />
              </div>
            </div>
          </div>

          <!-- divider -->
          <div class="bg-surface-300 h-px" />
        </div>

        <!-- items -->
        <div class="max-h-full">
          <div class="max-h-full h-full overflow-y-scroll">
            <div class="pb-64" ref="all" @click="clickHandler">
              <div
                v-for="(item, index) in mappedSet"
                :key="`${item.value}-${index}`"
                :data-id="item.value"
                :data-index="index"
                :class="[
                  'flex gap-2 justify-start items-center px-8 py-2 m-1 min-h-12 transition rounded cursor-pointer',
                  `${rawValue}`.includes(String(item.value))
                    ? `bg-cement-400 ${!item.disabled ? 'hover:bg-cement-300' : ''}`
                    : `even:bg-surface-0 odd:bg-surface-100/50 ${!item.disabled ? 'hover:bg-surface-900/10' : ''}`
                ]"
              >
                <div
                  v-if="item.html"
                  v-html="item.html"
                  class="flex justify-between items-center w-full"
                />
                <div v-else class="flex justify-between items-center w-full">{{ item.text }}</div>
                <!-- injection safe -->
                <div
                  v-if="item.icon"
                  class="flex items-center justify-center p-1 h-6 aspect-square bg-surface-200 rounded-full"
                >
                  <Icon :icon="item.icon" />
                </div>
              </div>

              <div v-if="canCreate" class="flex px-4 my-4 justify-center items-center">
                <Btn severity="bolster" :action="create" size="lg">
                  <font-awesome-icon icon="fas fa-plus" />
                  Create new
                </Btn>
              </div>
            </div>

            <div class="w-full text-center" v-if="!loading && !mappedSet.length">
              Nothing found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue3-click-away'
import Field from '../../mixins/Field.js'
import GridMixin from '../Grid/GridMixin.js'
import eventBus from '../../../eventBus.js'
import { useDeviceStore } from '@/stores/device.js'
import Formatters from '@/components/ui/Choose/Formatters.js'

// remove type prop

// eslint-disable-next-line no-unused-vars
const { type: omit, ...rest } = GridMixin.props
GridMixin.props = rest

/**
 * Events emitted:
 *  input - -emits values
 *  change - emits values
 *  formatted - textual representation of values
 *  open - dropdown going to open
 *  opened - dropdown opened
 *  close - dropdown going to close
 *  closed - dropdown closed
 *  empty - empty set of options fetched
 *  notEmpty - non-empty set of options fetched
 *  cache - options are being fetched from cache
 *  fetch - options are being fetched from server
 */
export default {
  name: 'Choose',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [clickaway, Field, GridMixin],

  props: {
    headless: {
      type: Boolean,
      default: false
    },
    emitDelay: {
      type: Number,
      default: 500
    },

    formatter: {
      default: null
    },

    saveStateEnabled: {
      default: false
    },

    default: {
      default: () => null
    },

    ignoreFiltersOnKeywordSearch: {
      default: false
    },

    /**
     *
     */
    btnClass: {
      default: ''
    },
    btnClassSelected: {
      default: ''
    },
    size: {
      default: 'lg'
    },
    full: {
      type: Boolean,
      default: false
    },

    /**
     * By default choose will return / emit an
     * array, of values, even if there is only one
     * value selected. Set this to false to return
     * only the value, not wrapped as an array.
     *
     * null // -> will check for the fieldSchema and
     *    see if it is array type, return array if true, not if not
     * true // [234]
     * false // 234
     */
    returnArray: {
      default: null
    },
    disabled: {
      default: false
    },
    /**
     * Provide a static set and no ajax calls will be made,
     * only the static set will be used to create options:
     * In format:
     *  [
     *    {
     *      text: 'first option',
     *      value: 1,
     *    },
     *    {
     *      text: 'first option',
     *      value: 1,
     *    },
     *    ...
     *  ]
     */
    staticSet: {
      type: [Array, Boolean],
      required: false,
      default: false,
      validate(set) {
        // Every item in the set needs to have a text, and value key try again
        return (
          !set ||
          !set.reduce(
            (acc, item) =>
              acc + (item && typeof item === 'object' && 'text' in item && 'value' in item ? 0 : 1),
            0
          )
        )
      }
    },

    /**
     * Add on to the fetched set
     */
    customOptions: {
      required: false,
      default: false,
      validate(set) {
        // Every item in the set needs to have a text, and value key
        return (
          !set ||
          !set.reduce(
            (acc, item) =>
              acc + (item && typeof item === 'object' && 'text' in item && 'value' in item ? 0 : 1),
            0
          )
        )
      }
    },
    /**
     * If an existing value exists, that value will be carried over,
     *   even if the value does not exist in the set of options provided.
     *   Set this to true to prevent carry-over when filters change or the
     *   set of options change.  If the value exists in the set, the value
     *   will stay selected.
     */
    noCarryOver: {
      required: false,
      default: false
    },
    /**
     * Default functionality is for the drop and fetching to occur first
     *   only after this has been opened once.  This is not true if filters
     *   are provided or loadImmediate is explicitly set to true;
     */
    loadImmediate: {
      required: false,
      default: false
    },
    /**
     * If allowDeselect is true and there is
     * no default prop provided, by default
     * no value is selected on mount. If you want
     * the first value to be selected on mount,
     * set selectFirst: true
     */
    selectFirst: {
      default: false
    },
    placeholder: {
      required: false,
      default() {
        return 'Choose..'
      }
    },
    multiple: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    /**
     * Signals that a selection is required
     * and colors the choose button red if no
     * selection is made. Doesn't force a
     * selection to be made at all times.
     */
    allowDeselect: {
      required: false,
      default: true
    },
    /**
     * Removes the ability to deselect a
     * chosen item. Works with multiple.
     * This hard-enforces no deselect,
     * whereas allowDeselect soft-enforces.
     * Works best with a default value or
     * selectFirst set to true.
     */
    forceNotEmpty: {
      required: false,
      default() {
        return false
      }
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    createFunction: {
      type: [Function, Boolean],
      required: false,
      default() {
        return false
      }
    },
    allowRefresh: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    allowSearch: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    /**
     * 'button' or 'input'
     */
    showAs: {
      type: String,
      default: 'button'
    },
    popup: {
      default: true
    },
    embue: {
      type: [Boolean, Object],
      default: false
    },
    isMain: {
      default: false
    },
    limit: {
      default: 1000
    },
    highlightIfEmpty: {
      type: Boolean,
      default: false
    },
    highlightIfNotEmpty: {
      type: Boolean,
      default: false
    },
    force: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'input', 'empty', 'notEmpty'],

  async created() {
    if (this.staticSet) {
      this.everFetched = true
    }

    // this.$on('selectedId', this.selectedIdHandler);
    // this.$on('deselectedId', this.deselectedIdHandler);
    // this.$on('rowClick', this.rowClickHandler);

    if (this.default && (this.value === null || !c.makeArray(this.value).length)) {
      this.setRawValue(this.default)
    }
    // console.log('order', (!this.order || !this.order.length)
    //   && this.objectType
    //   && !this.staticSet);
    // if ((!this.order || !this.order.length)
    //   && this.objectType
    //   && !this.staticSet) {
    //   this.orderLocal = [[
    //     `${this.objectType}_time_created`, 'desc',
    //   ]];
    // }

    if (!this.staticSet) {
      this.fetchCache(true, true)

      c.throttle(() => this.getMissingNames(), { delay: 300, key: this.uid })
    }
  },

  beforeUnmount() {
    if (this.$refs.outer) this.$refs.outer.parentNode.removeChild(this.$refs.outer)
  },

  data() {
    return {
      tether: null,
      openPromise: null,
      openResolve: null,
      hidden: false,
      cachedSearchSet: [],
      cachedFilterSet: [],
      missingSet: [],
      whiteButtonStyle:
        'border-surface-300 bg-flame-white hover:bg-surface-100 hover:border-surface-300 text-pitch-black',
      blueButtonStyle:
        'border-blue-print-100 bg-blue-100 hover:bg-blue-print-100 hover:border-blue-print-200',
      mutedButtonStyle: 'border border-surface-300 hover:bg-surface-200',
      mutedNoBorderButtonStyle: 'hover:bg-surface-200',
      zIndex: 0
    }
  },

  watch: {
    staticSet(set) {
      if (set && set.length && !this.schema) this.reload()
    }
  },

  computed: {
    btnClasses() {
      let validationClasses = []
      if (!this.allowDeselect && (!this.value || (this.returnArray && !this.value.length))) {
        validationClasses = ['bg-red-500']
      }

      let propClasses = []

      const classToApply = this.btnClass
      if (
        (this.highlightIfEmpty && !this.rawValue.length) ||
        (this.highlightIfNotEmpty && this.rawValue.length)
      ) {
        propClasses = ['!border-cement-800 !text-cement-900 hover:!bg-cement-300']
      }
      if (classToApply) {
        if (typeof classToApply === 'object') {
          propClasses = [
            ...propClasses,
            ...Object.keys(classToApply).filter((key) => !!classToApply[key])
          ]
        } else if (Array.isArray(classToApply)) {
          propClasses = [...propClasses, ...classToApply]
        } else if (typeof classToApply === 'string') {
          propClasses = [...propClasses, ...classToApply.split(' ')]
        }
      }

      let sizeClasses = []
      const size = this.size
      if (!size || size === 'md') sizeClasses = ['h-10 px-4 py-1.5']
      if (size === 'xs') sizeClasses = ['text-xs px-1.5 py-0.5']
      if (size === 'sm') sizeClasses = ['text-sm py-1.5 px-3.5']
      if (size === 'lg') sizeClasses = ['py-3 px-4 h-10']

      if (this.full) sizeClasses = [...sizeClasses, '!w-full']

      // console.log([...propClasses, ...validationClasses, ...sizeClasses]);
      return [...propClasses, ...validationClasses, ...sizeClasses]
    },
    /**
     *
     */
    formattedValue() {
      const mappedSet = [
        ...this.cachedSearchSet,
        ...(this.cachedFilterSet || []),
        ...(this.staticSet || []),
        ...(this.missingSet || [])
      ]
      const rv = c.makeArray(this.rawValue)
      return rv.map((v) => (mappedSet.find((o) => String(o.value) === v) || { text: v }).text)
    },

    formattedData() {
      const mappedSet = [
        ...this.cachedSearchSet,
        ...(this.cachedFilterSet || []),
        ...(this.staticSet || []),
        ...(this.missingSet || [])
      ]
      const rv = c.makeArray(this.rawValue)
      return rv.map((v) => mappedSet.find((o) => String(o.value) === v))
    },

    /**
     * For the Grid mixin requirements
     */
    type() {
      return this.objectType
    },

    /**
     *
     */
    isOpen() {
      return !!this.openPromise
    },

    cachedSet() {
      if (this.staticSet && this.staticSet.length) return this.mapOptions(this.staticSet)
      if (this.searchPhraseLocal) {
        return [
          ...this.quickFilterSet,
          ...this.cachedSearchSet.filter(
            (cache) => !this.quickFilterSet.find((qf) => qf.uid === cache.uid)
          )
        ]
      }
      // Sort company labor types before autocost labor types
      // this should be done using the :filter="" prop
      // if (this.objectType === 'labor_type') {
      //   let cachedSortedSet = _.imm(this.cachedFilterSet)
      //   return cachedSortedSet.sort((a, b) => b.company - a.company)
      // }
      return this.cachedFilterSet
    },

    quickFilterSet() {
      const set = _.immutable(this.cachedFilterSet)

      const sp = String(this.searchPhraseLocal || '')
        .trim()
        .toLowerCase()
      if (!sp) return []

      const parts = sp.split(' ')
      const regx = new RegExp(`${String(parts.join('|'))}(.*?)`, 'ig')
      const filtered = set.filter((o) => regx.test(String(o.text).toLowerCase()))

      return filtered
    },

    /**
     *
     */
    mappedSet() {
      return this.cachedSet.filter((item) => !(this.type === 'stage' && item.status === 'hd'))
    },

    /**
     *
     */
    singleTextDisplay() {
      return this.formattedValue.join(', ') || this.placeholder
    },

    /**
     * Whether it should fetch set immediately...
     * @returns boolean
     */
    fetchOnBuild() {
      return false
    },

    /**
     * Whether to show the create option
     */
    canCreate() {
      return (
        !this.staticSet &&
        this.allowCreate &&
        this.objectType &&
        this.objectType in c.creatableObjects
      )
    }
  },

  methods: {
    async getMissingNames() {
      const mappedSet = [
        ...this.cachedSearchSet,
        ...(this.cachedFilterSet || []),
        ...(this.staticSet || [])
      ]
      const rv = c.makeArray(this.rawValue)
      let missingIds = rv.filter((id) => !mappedSet.find((m) => String(m.value) === id))

      if (!missingIds.length) return

      missingIds = missingIds.join('||')
      const { set } = await c.throttle(
        () =>
          this.$store.dispatch(`${c.titleCase(this.type)}/filter`, {
            filters: {
              [`${this.type}_id`]: missingIds,
              [`${this.type}_status`]: '!NULL'
            }
          }),
        { delay: 300, key: `${missingIds}${this.type}` }
      )

      this.missingSet = this.mapOptions(set)
    },
    hide() {
      this.hidden = true
    },
    show() {
      this.hidden = false
    },
    selectAll() {},
    /**
     * Method that forces focus on this input
     * @returns {Promise<boolean>}
     */
    async focus() {
      if (this.isFocused()) {
        return true
      }

      this.importValue()

      if (this.$refs.input) {
        this.$refs.input.focus()
      }

      return true
    },

    isFocused() {
      return this.isOpen
    },

    clickHandler(event) {
      const $target = $(event.target)
      const row = $target.is('[data-index]') ? $target[0] : $target.closest('[data-index]')[0]
      if (row) {
        const index = +row.getAttribute('data-index')
        this.toggleIndex(index)
      }
    },

    touchstartHandler(e) {
      if (!this.deviceStore.isTouch) return

      this.clickHandler(e)
    },

    async dblclickHandler(...args) {
      this.genericEmit('dblclick', args)
    },

    selectedIdHandler(id) {
      this.toggleVal(id)
    },

    selectedIndexHandler(index) {
      this.toggleIndex(index)
    },

    async open() {
      this.zIndex = this.$store.state.modal.topZIndex

      this.hasFocused = true

      this.show()

      if (this.disabled) return null

      if (this.openPromise) return this.openPromise

      await this.$nextTick()

      this.openPromise = new Promise((resolve) => {
        this.openResolve = resolve
      })

      this.fetch(this.force, true)
      eventBus.$once('fetched', () => this.scrollToSelected())

      await this.$nextTick()

      if (this.$refs.outer) document.body.appendChild(this.$refs.outer)

      if (!this.deviceStore.isTouch && this.$refs.input) {
        this.$refs.input.focus()
      }

      if (this.hasFetched) this.scrollToSelected()

      return this.openPromise
    },

    scrollToSelected() {
      const sel = $(this.$refs.all).find('.selected')
      if (sel.length) c.scrollTo(sel[0])
    },

    close() {
      if (this.openResolve) {
        this.openResolve(this.rawValue)
      }
      // this.$emit('enter');
      // this.$emit('submit');

      this.$emit('close', this.formattedData)
      this.openResolve = null
      this.openPromise = null
    },

    async create() {
      this.hide()
      const payload = {
        type: this.objectType,
        embue: {
          ...(this.embue ? this.embue : this.filters),
          ...(this.searchPhrase ? { [`${this.objectType}_name`]: this.searchPhrase } : {})
        },
        go: false,
        view: false
      }
      const object = this.createFunction
        ? await this.createFunction(payload)
        : await this.$store.dispatch('create', payload)
      this.show()

      const id = object && `${this.objectType}_id` in object && object[`${this.objectType}_id`]
      if (id) {
        this.searchPhraseLocal = ''
        await this.reload()
        this.addVal(id)
      }

      return object
    },

    positionDrop() {},

    clickawayHandler() {
      this.close()
    },

    toggleIndex(index) {
      const item = this.mappedSet[index]
      if (item.disabled) return
      return this.toggleVal(String(item.value))
    },

    toggleVal(id) {
      const included = this.rawValue.includes(String(id))

      // Remove value if:
      // 1) there is at least one included value
      // 2) multiple-select is enabled
      // 3) it isn't the case that forceNotEmpty is true and this is the last included item
      // (otherwise there could be no included values, and forceNotEmpty wouldn't be enforced!)
      if (included && this.multiple && !(this.forceNotEmpty && this.rawValue.length <= 1)) {
        return this.removeVal(id)
      }

      if (!included) {
        return this.addVal(id)
      }

      // Already selected, close
      if (!this.multiple) {
        return this.close()
      }

      // Otherwise do nothing
      return this
    },

    removeIndex(index) {
      this.removeVal(this.rawValue[index])
    },

    removeVal(id) {
      const val = [...this.rawValue]
      val.splice(val.indexOf(String(id)), 1)
      this.setRawValue(val)
    },

    addVal(id) {
      this.searchPhraseLocal = ''
      let val = [...this.rawValue]
      if (!this.multiple) val = []

      if (!val.includes(String(id))) val = [...val, String(id)]

      this.setRawValue(val)

      if (!this.multiple) {
        this.close()
      }
    },

    mapOptions(options) {
      if (this.staticSet) {
        return options.map((o) => ({
          uid: `${o.value}.${o.text}.${o.uid || _.uniqueId()}`,
          value: String(o.value),
          text: String(o.text || o.value),
          html: o.html ? String(o.html) : c.removeHtml(String(o.text || o.value))
        }))
      }
      const formatter = this.formatter || Formatters[this.objectType] || Formatters.default

      const mappedFetched = options.map((o) =>
        formatter(o, this.$store, this.$store.getters.defaultMod)
      )

      return [...(this.customOptions || []), ...mappedFetched]
    },

    async refresh() {
      this.clearCacheSet()
      return this.reload()
    },

    fetchCache(start = true, onlySelected = false) {
      const data = this.getFetchData(start)
      const cache = this.getCacheSet(data) || null
      let cacheArr = cache || []

      if (onlySelected) {
        const vals = c.makeArray(this.rawValue)
        cacheArr = cacheArr.filter((o) => vals.includes(o.value))
      }

      if (data.searchPhrase) {
        this.cachedSearchSet = cacheArr
      } else {
        this.cachedFilterSet = cacheArr
        this.cachedSearchSet = []
      }

      return cache
    },

    async fetch(force = false, start = false) {
      // if (!this.openPromise) return this; // has to ben open to fetch
      if (this.staticSet) {
        this.hasFetched = true
        return this
      }

      const data = this.getFetchData(start)
      const cache = this.fetchCache(start)
      const goFetch = !cache || force

      if (goFetch || this.customOptions.length) {
        if (this.type) await this.$store.dispatch(`${c.titleCase(this.type)}/clearCache`)
        await GridMixin.methods.fetch.call(this, force, start)

        await this.$nextTick()

        if (!this.formatter) {
          this.setCacheSet(this.mapOptions(this.set), data)
        }
      }

      this.hasFetched = true

      if (data.searchPhrase) {
        this.cachedSearchSet = cache || this.getCacheSet(data) || this.mapOptions(this.set)
      } else {
        this.cachedFilterSet = cache || this.getCacheSet(data) || this.mapOptions(this.set)
        this.cachedSearchSet = []
      }

      this.scrollToSelected()

      if (!this.mappedSet.length) {
        eventBus.$emit('empty')
        this.$emit('empty')
      } else {
        eventBus.$emit('notEmpty')
        this.$emit('notEmpty')
      }

      return this
    },

    getCacheTag(data = null) {
      const json = JSON.stringify(data || this.getFetchData())
      return `choose.${this.objectType}.${json}`
    },

    getCacheSet(data = null) {
      return (
        c.getCacheItem(this.getCacheTag(data), this.objectType, this.$store.state.session) || null
      )
    },

    clearCacheSet() {
      return c.removeCacheItem(this.getCacheTag(), this.objectType, this.$store.state.session)
    },

    setCacheSet(set = null, data = null) {
      return c.setCacheItem(
        this.getCacheTag(data),
        set || this.mappedSet,
        this.objectType,
        this.$store.state.session
      )
    },

    emitValue() {
      this.emitted = true
      this.startedTypingSinceEmit = false

      if (
        String(this.rawValue) === String(this.value) ||
        this.areEqual(this.rawValue, this.value) ||
        this.areEqual(c.makeArray(this.rawValue), c.makeArray(this.value)) ||
        this.areEqual(this.rawValue, this.deformatValue(this.value))
      ) {
        return
      }

      const value = this.returnArray ? this.rawValue : this.rawValue.join(',') || null

      const formatted = this.formattedValue.join(', ')

      this.$emit('selectedValue', {
        formatted,
        value
      })
      this.$emit('formatted', formatted)
      this.$emit('input', value)
    },

    areEqual(valueA, valueB) {
      return c.jsonEquals(valueA, valueB)
    },

    async formatValue(value) {
      return Promise.all(
        c.makeArray(value).map(async (v) => {
          const found = this.mappedSet.find((item) => String(item.value) === String(v))

          if (found && found.text) return found.text

          if (this.objectType && !this.staticSet && v) {
            return this.$store.dispatch(`${c.titleCase(this.objectType)}/getName`, {
              id: v,
              getIndividual: true
            })
          }

          return v
        })
      )
    },

    deformatValue(value) {
      return c.makeArray(value).map((v) => String(v))
    }
  }
}
</script>
