<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :clickAway="false"
    :scrollable="true"
    :expandable="false"
    :includeGutter="true"
    :hasSidePanel="true"
    :has-header-border="true"
  >
    <template #header>
      <font-awesome-icon icon="person" />
      {{ object.user_name }}
    </template>
    <template #body>
      <user-body ref="body" :forceFull="true" :forceFetch="true" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import UserBody from '../bodies/User.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalUser',
  mixins: [ObjectModalMixin],
  components: { UserBody },
  props: {
    type: {
      default: 'user'
    }
  }
}
</script>
