const ownerSchema = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  id_number: '',
  ssn_last_4: '',
  address: {
    line1: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  },
  dob: {
    day: '',
    month: '',
    year: ''
  },
  relationship: {
    owner: null,
    director: null,
    executive: null,
    representative: null,
    percent_ownership: '',
    title: ''
  }
}

export default {
  data() {
    return {
      owners: [],
      index: null,
      // initial owner schema
      ownerSchema,
      owner: ownerSchema,
      updatesHaveBeenMade: false,
      updating: 0,
      removed: []
    }
  },
  methods: {
    /**
     * On success event
     */
    onSuccess() {
      this.$refs.modal.close()
    },
    /**
     * On modal close clicked
     */
    onClose() {
      this.$refs.modal.close()
    },
    /**
     * Save and update owners after submitting owner onboarding modal
     * @param {object} owner
     */
    onOwnerOnboardingSuccess(owner) {
      if (owner.id || this.index !== null) {
        const owners = [...this.owners]
        owners[this.index || 0] = owner
        this.owners = owners
      } else {
        this.owners.push(owner)
      }
      this.$refs.ownerModal.close()
      this.updatesHaveBeenMade = true
    },
    /**
     * Display the owner onboarding modal
     * @param {number|null} index
     * @param {object|null} owner
     */
    onShowOwnerOnboarding({ index = null, owner = null }) {
      this.owner = {
        ...ownerSchema,
        ...owner
      }
      this.index = index
      this.$refs.ownerModal.open()
    },
    /**
     * Called when an owner has been removed
     */
    onRemoved({ removed }) {
      this.updatesHaveBeenMade = true
      this.removed = removed
    },
    /**
     * Update the owners of the organization
     * @returns {Ptomise<*>}
     */
    async updateOwners() {
      try {
        this.updating = 1
        await this.$store.dispatch('ajax', {
          path: 'counterparty/updateOwners',
          data: {
            owners: this.owners,
            removed: this.removed
          }
        })
        this.updating = 0
        this.updatesHaveBeenMade = false
      } catch (e) {
        this.updating = 0
        this.$store.dispatch('alert', {
          message: e.message || 'Could not update owners',
          error: true
        })
      }
    }
  }
}
