<template>
  <div class="activities-container gap-2" @dblclick="hideBlankUpdates = false">
    <activity-new
      :filters="filters"
      :contact="contact"
      :startingFolder="startingFolder"
      v-if="allowCreate"
      @sent="loadLatest"
      @created="addCreated"
    />

    <div class="activities--search">
      <div class="flex justify-center items-center">
        <div class="activities--search-suggestions my-2">
          <btn class="sm round" :link="!hasTypeFilter('')" @click="() => toggleTypeFilter('')">
            All
          </btn>
          <btn
            class="sm round"
            :link="!hasTypeFilter('NOTE||LEAD||POST')"
            @click="() => toggleTypeFilter('NOTE||LEAD||POST')"
          >
            Notes/posts
          </btn>
          <btn
            class="sm round"
            :link="!hasTypeFilter('ACTION||LEAD')"
            @click="() => toggleTypeFilter('ACTION||LEAD')"
          >
            Actions
          </btn>
        </div>
      </div>
      <span class="badge info mt-2" v-if="set.length && searchPhrase">
        In order of relevance..
      </span>
    </div>
    <template v-for="(activity, index) in activityList">
      <activity
        v-if="showActivity(activity)"
        :firstActivity="index === 0"
        :activity="activity"
        :key="activity.activity_id"
        :filters="filters"
        @version-checkout="(data) => $emit('versionCheckout', data)"
      />
      <ActivityVersion
        v-else-if="
          activity.activity_base_type === 'VERSION' &&
          activity.target_type in showBackupsFor &&
          (showBackupsFor[activity.target_type] ||
            String(showBackupsFor[activity.target_type]) === String(activity.target_id))
        "
        :filters="filters"
        :firstActivity="index === 0"
        :key="activity.activity_id"
        :activity="activity"
        @version-checkout="(data) => $emit('versionCheckout', data)"
      />
    </template>

    <div class="flex justify-center" v-if="!endOfSet">
      <scroll-trigger v-if="firstLoad" @in="onInfinite" ref="infiniteLoading" />
      <spinner style="border-bottom: none" size="50" class="inline" :loading="true" v-else />
    </div>
    <div v-else-if="searchPhrase && !set.length && !loading" class="flex justify-center my-5">
      <span class="badge warning lg" style="font-size: 1.2em"> Nothing found! </span>
    </div>
    <div class="flex justify-center" v-else-if="loading">
      <spinner style="border-bottom: none" size="50" class="inline" :loading="1" />
    </div>
    <hr v-else />
  </div>
</template>

<script>
import Activity from './Activity.vue'
import ActivityVersion from './ActivityVersion.vue'
import ActivityNew from './ActivityNew.vue'
import ScrollTrigger from '../ScrollTrigger.vue'
import eventBus from '../../../eventBus'

/**
 * Emits
 *  created(Activity)
 */
export default {
  name: 'Activities',
  emits: ['versionCheckout', 'created', 'endOfSet', 'empty', 'notEmpty'],
  data() {
    return {
      limit: 5,
      nextToken: null,
      focused: 0,
      offset: 0,
      searchPhrase: '',
      set: this.activities,
      loading: 0,
      endOfSet: false,
      firstLoad: false,
      mountFetch: null,
      hideBlankUpdates: !this.showBlankUpdates,
      localFilters: {
        activity_base_type: ''
      }
    }
  },
  computed: {
    activityList() {
      return this.set
    }
  },
  watch: {
    searchPhrase(ph) {
      if (ph) this.toggleTypeFilter('')
      this.debounceSearch()
    },
    filters(old, newf) {
      if (!_.jsonEquals(old, newf)) this.debounceSearch()
    },
    localFilters(old, newf) {
      if (!_.jsonEquals(old, newf)) this.debounceSearch()
    }
  },
  methods: {
    hasTypeFilter(type) {
      const bt = this.localFilters.activity_base_type
      if (!type || type === '') return !bt || bt === ''
      return bt && bt === type
    },

    toggleTypeFilter(type) {
      if (type && this.localFilters.activity_base_type !== type) {
        this.localFilters = {
          ...this.localFilters,
          activity_base_type: type
        }
      } else {
        const { activity_base_type: omit, ...rest } = this.localFilters
        this.localFilters = rest
      }
    },
    debounceSearch() {
      this.nextToken = null
      c.throttle(() => this.search(), {
        debounce: true,
        delay: 500
      })
    },

    async search() {
      this.loading += 1

      await this.$nextTick()

      this.set = []

      await this.fetch()

      this.loading -= 1

      await this.$nextTick()

      return true
    },

    showActivity(activity) {
      if (activity.activity_base_type === 'VERSION') {
        return false
      }

      return !(
        this.hideBlankUpdates &&
        activity.activity_base_type === 'ACTION' &&
        /updated/i.test(activity.activity_type) &&
        (!activity.oTarget.aoFullChanges.length ||
          Array.isArray(activity.oTarget.aoFullChanges[0])) &&
        (!activity.oTarget.aoExplicitChanges.length ||
          Array.isArray(activity.oTarget.aoExplicitChanges[0]))
      )
    },
    async loadLatest() {
      if (this.set.length) {
        const set = await this.$store.dispatch('activity/search', {
          filters: {
            activity_id: this.set[0].activity_id
          }
        })

        this.set = [...set, ...this.set]
      }
    },
    addCreated(activity) {
      this.set = [{ ...activity, activityNoCollapse: 1 }, ...this.set]
      this.$emit('created', activity)
    },
    onInfinite() {
      this.throttleFetch()
    },
    /* Fetch/Search etc */
    getFetchHeaders() {
      return {
        filters: c.getFilters({
          ...this.filters,
          ...this.localFilters
        }),
        nextToken: this.nextToken,
        limit: this.limit
      }
    },
    fetch() {
      const oldHeaders = this.getFetchHeaders()
      this.loading += 1
      this.$store
        .dispatch('Activity/search', {
          ...oldHeaders,
          limit: this.limit,
          nextToken: this.nextToken
        })
        .then(({ set, nextToken }) => {
          const newHeaders = this.getFetchHeaders()
          this.endOfSet = !nextToken
          this.nextToken = nextToken

          if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.endLoading()
          if (this.endOfSet) {
            this.$emit('endOfSet')
          } else if (this.$refs.infiniteLoading) {
            this.$refs.infiniteLoading.reset()
          }

          // only update if request headers haven't changed
          if (_.isEqual(oldHeaders, newHeaders)) {
            if (!set.length) this.$emit('empty', {})
            else this.$emit('notEmpty', set)

            this.set = [...this.set, ...set]
          }

          this.loading -= 1
          this.firstLoad = true
        })
        .catch(() => {
          if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.reset()
        })
    },
    throttleFetch() {
      c.throttle(this.fetch, {
        key: `${this.limit}/${this.set.length}/${JSON.stringify(this.getFetchHeaders())}`
      })
    }
  },
  mounted() {
    this.mountFetch = setTimeout(() => {
      if (!this.activities.length) {
        this.throttleFetch()
      }
      eventBus.$on('connectionDisconnected', this.fetch)
    }, 500)
  },
  beforeUnmount() {
    /**
     * There is no guarantee about when slot content is
     * first loaded/mounted.  It could be loaded or mounted
     * before the container v-if=true.  To prevent the expensive
     * operation of fetching activities on mount, before it is
     * actually requested like when inside a SidePanel, we prevent
     * fetching when it is created then immediately destroyed.
     * https://github.com/vuejs/vue/issues/5190
     */
    eventBus.$off('connectionDisconnected', this.fetch)
    window.clearTimeout(this.mountFetch)
  },
  props: {
    showBlankUpdates: {
      default: false
    },
    startingFolder: {
      default: null
    },
    allowCreate: {
      default: true
    },
    filters: {
      required: true
    },
    activities: {
      required: false,
      default: () => []
    },
    contact: {
      required: false
    },

    /**
     * An object representing the types of backups that can be shown.
     * You do not want a related objects backups showing in all contexts
     * because it would not possible to back up to it. For example an invoice
     * backup showing up in a quote activity list even though the invoice is for that project.
     *
     * key is the objec ttype,
     * id is the object id, or true for all
     * {
     *  'change_order': true,
     *  'quote': 23434332,
     * }
     */
    showBackupsFor: {
      type: Object,
      default: () => ({})
    }
  },
  components: { ActivityVersion, Activity, ActivityNew, ScrollTrigger }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.activities-container {
  text-align: left;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  .activities--search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .activities-container {
    .activity-version--card {
      border-radius: 0 !important;
    }

    .card.card--container.activity-new {
      border-radius: 0 !important;
    }

    .activity.card {
      border-radius: 0 !important;
    }
  }
}
</style>
