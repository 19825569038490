import { onMounted, ref, computed, onBeforeUnmount } from 'vue'
import _ from '../../../imports/api/Helpers.js'
import { useStore } from 'vuex'
import eventBus from '@/eventBus'

// TODO eliminate these duplicate functions in Helpers
export default {
  useDimensions() {
    const $store = useStore()
    const possibleDimensions = ref({})

    const dimRegexp = computed(() => {
      const joined = Object.keys(possibleDimensions.value || {}).join('|')
      const regexp = new RegExp(`(?:\\b|^)(${joined})(?:\\b|$)`, 'g')
      return regexp
    })

    const getDimensionsInEquation = (equation) => String(equation).match(dimRegexp.value) || []

    const getBlendedDimensionColor = (dims = []) => {
      const colors = _.makeArray(
        dims.map((abbr) => c.rgbToHex(possibleDimensions.value?.[abbr]?.color))
      )
      return colors.reduce((blended, color) => c.blendColors(blended, color), colors[0])
    }

    const getConvertedDimensions = (existingDimensions = {}, measure, all = true) => {
      const meas = String(_.makeArray(measure)[0] || 'ft')
      const baseMeasure = meas.replace(/^(ft|m|mm|yd)\d?$/i, '$1')
      const defaultDimensions = all ? possibleDimensions.value : {}

      const dimensions = {
        ...defaultDimensions,
        ...existingDimensions
      }

      let convertedDimensions = {}

      if (baseMeasure && baseMeasure in _.conversionTables) {
        convertedDimensions = Object.keys(dimensions).reduce((acc, abbr) => {
          let measureTypeNotation = ''
          if (dimensions[abbr].measureType === 'area' && baseMeasure !== 'square') {
            measureTypeNotation = '2'
          } else if (dimensions[abbr].measureType === 'volume') {
            measureTypeNotation = '3'
          }

          let convertedValue = dimensions[abbr].value
          const fromMeasure = dimensions[abbr].measure || `ft${measureTypeNotation}`
          let toMeasure = `${baseMeasure}${measureTypeNotation}`
          if (toMeasure !== fromMeasure && dimensions[abbr].measureType !== 'count') {
            convertedValue =
              c.convertMeasures(convertedValue, fromMeasure || 'ft', toMeasure) || convertedValue // if cannot convert, return the value itself
          } else {
            toMeasure = fromMeasure
          }

          return {
            ...acc,
            [abbr]: {
              ...(defaultDimensions[abbr] || {}),
              ...(acc[abbr] || {}),
              ...dimensions[abbr],
              value: convertedValue,
              measure: toMeasure
            }
          }
        }, {})
      }

      return convertedDimensions
    }

    const possibleMeasureTypes = ['count', 'length', 'area', 'volume', 'weight']

    const possibleMeasures = Object.keys(c.conversionTables)

    const countryUsesMetric = computed(() => $store.state.session.user.country_id > 2)

    const getSuggestedUnitOfMeasure = (measureType, isMetric = countryUsesMetric.value) => {
      const metric = isMetric
      const mt = measureType

      if (mt === 'length') {
        return metric ? 'm' : 'ft'
      } else if (mt === 'volume') {
        return metric ? 'm3' : 'yd3'
      } else if (mt === 'area') {
        return metric ? 'm2' : 'ft2'
      } else if (mt === 'weight') {
        return metric ? 'kg' : 'lbs'
      }

      return 'each'
    }

    /**
     * Gets the basic measure for a unit of measure
     * for exmple, m2, or ft (linear foot), or mm2 etc,
     * so that it can be used to find a conversion table.
     * @param id    unit of measure id. Now this is mostly
     * unnecessary because the new unit_of_measure ids are strings that
     * denote their own measure, like 'm2' or 'ft' or 'count', whereas for count
     * it used to be an id of 2.  This is here for backwards compatibility.
     * @returns {string | undefined}
     */
    const getMeasureForUnitOfMeasure = (id) => {
      if (id == 2 || id == 'count' || id == 'each') return 'count'

      if (id in c.conversionTables) return id

      // Old
      return Object.keys(c.conversionTables).find(
        (m) =>
          c.conversionTables[m].unit_of_measure_ids.includes(String(id)) ||
          c.conversionTables[m].unit_of_measure_ids.includes(+id)
      )
    }

    /**
     * Get the basic measure type, ie 'ft' === 'length' and 'm2' === 'area'
     * @param id
     * @returns {*|string}
     */
    const getMeasureTypeForUnitOfMeasure = (id) => {
      const m = getMeasureForUnitOfMeasure(id)
      if (m in c.conversionTables) {
        return c.conversionTables[m].measureType
      }

      return 'count'
    }

    const replaceDimensionsInEquation = (formula, dimensions, convertToMeasure = null) => {
      const variablesRegex = dimRegexp.value

      if (!variablesRegex.test(formula)) {
        return [formula, []]
      }

      const measurea = _.makeArray(convertToMeasure)
      let meas =
        (measurea.length && measurea[0] && typeof measurea[0] === 'string' && measurea[0]) || 'ft'
      const mt = getMeasureTypeForUnitOfMeasure(meas)
      meas = mt === 'count' ? 'count' : meas
      const base = meas.replace(/2|3/, '') || 'ft'
      const dimensionsUsed = []

      const replaced = String(formula).replace(variablesRegex, (abbr) => {
        if (!(abbr in dimensions)) {
          return abbr
        }

        dimensionsUsed.push(abbr)

        const num = c.notNaN(dimensions[abbr].value)
        // If we don't have a dimension to convert from or to, just return the raw number
        // regardless of what dimension it derives from
        if (!convertToMeasure || !base || !dimensions[abbr].value || base === 'count') {
          return num
        }

        // get the measure to convert TO
        let measureTo = base
        if (dimensions[abbr].measureType === 'area' && measureTo !== 'square') {
          measureTo = `${base}2`
        } else if (dimensions[abbr].measureType === 'volume') {
          measureTo = `${base}3`
        }

        if (convertToMeasure) {
          const converted = c.convertMeasures(num, dimensions[abbr].measure, measureTo)

          return converted === false ? num : converted
        }

        // If no measure provided, we cannot convert
        return num
      })

      return [replaced, dimensionsUsed]
    }

    const getDimensionValue = (equation, dimensions, coerceToMeasure = null) => {
      const [formula] = replaceDimensionsInEquation(equation.trim(), dimensions, coerceToMeasure)
      return c.toNum(formula, 20, true)
    }

    const getFormatTypeForMeasure = (measure) =>
      /m/.test(measure) || /\d/.test(measure) ? 'number' : 'imperial'

    const measureTypeIcons = {
      count: 'tally-5',
      area: 'layer-group',
      volume: 'glass-water',
      weight: 'weight-scale',
      length: 'ruler-horizontal'
    }

    const getPossibleDimensions = async () => {
      possibleDimensions.value = await $store.dispatch(
        'Dimension/getPossibleDimensions',
        {},
        { root: true }
      )
    }

    onMounted(async () => {
      await getPossibleDimensions()
      eventBus.$on('saved-dimension', getPossibleDimensions)
    })

    onBeforeUnmount(() => {
      eventBus.$off('saved-dimension', getPossibleDimensions)
    })

    return {
      possibleDimensions,
      possibleMeasures,
      possibleMeasureTypes,
      countryUsesMetric,
      measureTypeIcons,

      getDimensionsInEquation,
      getBlendedDimensionColor,
      getConvertedDimensions,
      getSuggestedUnitOfMeasure,
      getDimensionValue,
      getFormatTypeForMeasure,
      getMeasureForUnitOfMeasure,
      getMeasureTypeForUnitOfMeasure
    }
  }
}
