<script setup>
import { watch, watchEffect, defineProps, onMounted, toRefs, ref, computed } from 'vue'
import { useSidePanel as useSidePanelStore } from '@/stores/sidepanel'
import Tag from 'primevue/tag'
import PanelMenu from 'primevue/panelmenu'
import useSidePanel from './SidePanel'
import Badge from 'primevue/badge'

const {
  expandedKeys,
  items,
  query: spQuery,
  pathQuery,
  toggleAction,
  toggleKey,
  getOpenKeys,
  mapWithClass,
  getItems,
  searchResults,
  onStar,
  viewAll
} = useSidePanel()

const sidePanelStore = useSidePanelStore()

const props = defineProps({
  model: {
    type: Array
  },
  query: {
    type: String
  },
  allowStarred: {
    type: Boolean,
    default: true
  }
})

const { model, query: searchQuery } = toRefs(props)

const handleChange = () => {
  searchResults.value = []
  const menuItems = model.value.map((it) => mapWithClass(it))
  items.value = getItems(menuItems)
}

function itemTooltip(item) {
  if (sidePanelStore.collapsed) {
    return item.label
  }
  return false
}
function showItemIcon(item, hasSubmenu) {
  if (item.icon) {
    if (hasSubmenu && sidePanelStore.collapsed) {
      return true
    }
    if (hasSubmenu && !sidePanelStore.collapsed) {
      return false
    }
    if (item.label === 'Show all') {
      return !!sidePanelStore.collapsed
    }
    return true
  }
  return false
}

watchEffect(() => {
  // declare variables outside of async so they trigger this watchEffect
  const view = viewAll.value
  viewAll.value = view
  const path = pathQuery.value
  pathQuery.value = path
  c.throttle(handleChange, { debounce: true, delay: 300 })
}, {})

watch(items, (menuItems) => {
  const opened = getOpenKeys(menuItems)
  expandedKeys.value = {
    ...expandedKeys.value,
    ...opened,
    ...(menuItems[0]?.key ? { [menuItems[0]?.key]: true } : {})
  }
})

watch(model, () => c.throttle(handleChange, { debounce: true, delay: 300 }))

watch(searchQuery, (q) => {
  spQuery.value = q
})

onMounted(() => {
  items.value = model.value
})

const itemsWithCollapse = computed(() => {
  if (!sidePanelStore.collapsed) return items.value

  return [
    {
      label: 'Expand sidebar',
      highlight: false,
      icon: 'sidebar',
      tab: null,
      class: 'text-cement-800',
      command: () => {
        sidePanelStore.collapsed = !sidePanelStore.collapsed
      }
    },
    ...items.value
  ]
})

const searchPhrase = ref('')
</script>

<template>
  <PanelMenu
    :model="itemsWithCollapse"
    class="w-full py-2 pl-4 pr-safe-4"
    v-model:expandedKeys="expandedKeys"
  >
    <template #item="{ item, hasSubmenu }">
      <div
        v-if="item && !item.divider"
        v-tooltip="itemTooltip(item)"
        :class="[
          'flex items-center relative w-full cursor-pointer',
          {
            'bg-cement-400': item.active || false,
            rounded: item.active || false,
            'text-surface-300': hasSubmenu && sidePanelStore.collapsed,
            'py-2 justify-center': sidePanelStore.collapsed
          }
        ]"
      >
        <a
          @click.stop.prevent="toggleKey(item.key)"
          v-if="!item.hideChevron && hasSubmenu && !(sidePanelStore.collapsed && item.icon)"
        >
          <font-awesome-icon
            icon="chevron-right"
            class="mx-2 text-xs transition-all"
            :size="sidePanelStore.collapsed ? 'xl' : undefined"
            :class="{
              'rotate-90': expandedKeys[item.key]
            }"
          />
        </a>

        <a
          v-if="
            !sidePanelStore.collapsed ||
            item.hideChevron ||
            !hasSubmenu ||
            (sidePanelStore.collapsed && item.icon)
          "
          @click.stop.prevent="toggleAction(item)"
          :data-found="item.searchHit || false"
          :key="item.key"
          class="flex items-center justify-start py-2 rounded-md w-full"
          :class="{
            'opacity-50': searchPhrase && !item.searchHit,
            'justify-center': sidePanelStore.collapsed,
            '!px-2': !hasSubmenu && !sidePanelStore.collapsed,
            ' overflow-hidden': !sidePanelStore.collapsed
          }"
        >
          <div
            class="flex items-center justify-start h-full max-w-full"
            :class="{
              'w-full pl-2 overflow-hidden ': !sidePanelStore.collapsed,
              'ml-4': !sidePanelStore.collapsed && !hasSubmenu,
              '-ml-2': !sidePanelStore.collapsed && hasSubmenu,
              [item.containerClass]: item.containerClass
            }"
          >
            <span
              v-if="showItemIcon(item, hasSubmenu)"
              class="w-4 h-4 flex items-center justify-center"
              :class="{ 'mr-2': !sidePanelStore.collapsed }"
            >
              <font-awesome-icon
                :icon="item.active && item.filters ? 'fas fa-filter' : item.icon"
                :size="sidePanelStore.collapsed ? 'xl' : undefined"
                :class="{
                  [item.iconClass]: item.iconClass,
                  [item.class]: item.class,
                  [item.textClass]: item.textClass
                }"
                fixed-width
              />
            </span>

            <span v-if="item.user">
              <Avatar
                shape="circle"
                :label="item.user"
                class="mr-2"
                :pt="{
                  root: `
                   inline-flex items-center justify-center relative h-6 w-6
                   rounded-full dark:bg-surface-700
                   ${item.user.includes('+') ? 'bg-primary text-white' : 'border-surface-300 border'}
                 `,
                  label: 'font-normal text-sm'
                }"
              />
            </span>

            <span v-if="item.avatar">
              <PersonAvatar
                class="mr-1"
                shape="circle"
                :name="item.avatar"
                :id="item.members[0].user_id"
                type="user"
                size="small"
              />
            </span>

            <span v-if="item.image">
              <img :src="item.image" alt="image" class="block mr-2 w-4 h-4" />
            </span>

            <span
              v-if="item.label && !sidePanelStore.collapsed"
              class="text-ellipsis pr-1 overflow-hidden whitespace-nowrap w-full text-sm leading-tight grow-0 shrink basis-4/5"
              :class="{
                [item.class]: item.class,
                [item.textClass]: item.textClass,
                'font-medium': item.active && item.filters,
                '!text-blue-print': item.searchHit,
                '!font-medium': item.searchHit
              }"
            >
              {{ item.label }}
              <Tag
                v-if="item.tag"
                :value="item.tag"
                class="ml-1 !border-blue-print !text-blue-print"
              />
            </span>

            <span class="h-full inline-flex items-center shrink-0 ml-auto" v-if="item.badge">
              <Badge
                :value="item.badge > 99 ? '99+' : item.badge"
                :pt="{
                  root: '!size-6 rounded-full text-white/80 dark:text-surface-900 bg-secondary flex justify-center items-center text-[8pt] !font-light'
                }"
              />
            </span>
          </div>
        </a>
        <div
          v-show="allowStarred && !sidePanelStore.collapsed"
          @click.stop.prevent="() => onStar(item)"
          class="right-4 absolute"
          v-tooltip.top="item && item.isStarred ? 'Unpin this page' : 'Pin this page'"
        >
          <font-awesome-icon
            :size="sidePanelStore.collapsed ? 'xl' : undefined"
            v-if="item ? !item.noStar : true"
            class="nav-star text-cement-700 hover:text-cement-900 cursor-pointer"
            :icon="[item && item.isStarred ? 'fas' : 'fal', 'thumbtack']"
          />
        </div>
      </div>
      <div class="border-b border-cement-600 my-2" v-else-if="item && item.divider"></div>
    </template>
  </PanelMenu>
</template>

<style scoped lang="scss">
[data-pc-section='menuitem'],
[data-pc-section='header'] {
  > [data-pc-section='content'] {
    &:hover {
      .nav-star {
        display: block !important;
      }
    }
  }
  .nav-star {
    display: none;
  }
}
</style>
