export default {
  methods: {
    async go() {
      this.$emit('editing')
      await this.$store.dispatch('edit', {
        type: this.localType || this.object.type,
        id: this.object[`${this.object.type}_id`],
        button: this.$refs.editButton
      })
      this.$emit('afterEdit')
    },
    edit() {
      return this.go()
    }
  },
  data() {
    return {
      localType: null
    }
  },
  props: {
    avatar: {
      default: true,
      required: false
    },
    grid: {
      default: null,
      required: false
    },
    object: {
      required: true
    },
    right: {
      required: false,
      default: true
    },
    middle: {
      required: false,
      default: true
    },
    steps: {
      required: false,
      default: true
    }
  },
  emits: ['editing', 'afterEdit']
}
