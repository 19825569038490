<template>
  <!-- ----PIPELINE---- -->
  <div v-if="activeTab.includes('pipeline')">
    <pipeline
      v-if="client_id && client_status !== 'l'"
      :isMain="false"
      :filters="{ client_id: client_id }"
      :showToolbar="false"
      :startColumn="2"
    />
    <div v-else>
      <card-section>
        <card>
          <template #icon>
            <font-awesome-icon :icon="['fa', 'columns-3']" />
          </template>
          <template #title> Convert to active client first </template>
          <template #text>
            <p>
              This is currently still a lead, convert it to an active client so you can create
              quotes, projects and invoices for them.
            </p>
          </template>
          <btn-group>
            <btn :action="convert" severity="success" class="success round">
              Convert to active client
            </btn>
          </btn-group>
        </card>
      </card-section>
    </div>
  </div>

  <!-- ----ACTIVITIES---- -->
  <div v-if="activeTab.includes('activities')" class="flex flex-row justify-center">
    <div class="w-full max-w-6xl">
      <template v-if="client_status !== 'a'">
        <card-section>
          <warning>
            <template #title>This is not an active client</template>
            <p>You are not able to build a quote for this prospect until they are marked active.</p>

            <btn-group class="mt-2">
              <btn class="round dark" :action="makeActive"> Convert to active client </btn>
            </btn-group>
          </warning>
        </card-section>
      </template>
      <activities
        :contact="getContact()"
        :showBackupsFor="{ client: client_id }"
        :filters="{ client_id }"
      />
    </div>
  </div>

  <!-- ----DETAILS---- -->
  <div v-if="activeTab.includes('details')" class="flex flex-row justify-center">
    <div class="max-w-6xl">
      <card-section v-if="client_status !== 'a'">
        <warning>
          <template #title>This is not an active client</template>
          <p>You are not able to build a quote for this prospect until they are marked active.</p>

          <btn class="round dark mt-2" :action="makeActive"> Convert to active client </btn>
        </warning>
      </card-section>
      <card-section>
        <template #label>
          <span>Owner</span>
        </template>
        <card-list>
          <card-list-field :mobileStack="true">
            <span>
              Client owner
              <br />
              <small
                >Changing the client owner will also change the owner for all the quotes and
                invoices associated with the client.</small
              >
            </span>
            <choose
              :return-array="false"
              schema="client:client_owner"
              v-model="clientOwner"
              :filters="{ company_ids: `INSET${$store.state.session.company.company_id}` }"
            />
          </card-list-field>
        </card-list>
      </card-section>

      <card-section>
        <template #label>
          <span>Lead source</span>
        </template>
        <card-list>
          <card-list-field>
            <span>
              Lead source
              <small class="text-info">Where did this client/lead come from? (optional)</small>
            </span>
            <choose
              :validate="{ required: false }"
              :return-array="false"
              :validation="{ required: false }"
              schema="client:lead_source_id"
              v-model="lead_source_id"
            />
          </card-list-field>
        </card-list>
      </card-section>

      <card-section>
        <template #label>
          <span>Change status</span>
        </template>
        <card-list>
          <card-list-field :mobileStack="true" v-if="client_status === 'a'">
            <span>
              Make client inactive
              <br />
              <small>
                If the client has no pending quotes or outstanding invoices, and you don't expect
                them to generate any activity, it makes sense to make them inactive to clean up your
                lists and remove them from view. Making the client inactive will hide it from most
                lists. You can always see your inactive clients in the clients page to re-activate
                it later.
              </small>
            </span>
            <div>
              <btn class="btn btn-warning" :action="makeInactive" size="lg">
                Make client inactive
              </btn>
            </div>
          </card-list-field>

          <template v-else-if="client_status === 'l'">
            <card-list-field>
              <span>
                Convert to client
                <br />
                <small>
                  A lead must first be converted to a client before you can build them a quote. If
                  you have all the required details to turn this lead into a fully active client,
                  convert it now.
                </small>
              </span>
              <div>
                <btn class="btn btn-success" :action="convert" size="lg">
                  Convert to active client
                </btn>
              </div>
            </card-list-field>
            <card-list-field>
              <span>
                Abandon lead
                <br />
                <small>
                  If you don't think this lead will result in any business, abandon it so that it
                  disappears from your lead list. You can always re-activate it later.
                </small>
              </span>
              <div>
                <btn class="btn btn-warning" :action="makeInactive" size="lg">
                  Abandon lead for now
                </btn>
              </div>
            </card-list-field>
          </template>
          <card-list-field v-else>
            <span>
              Make client active
              <br />
              <small
                >This client is currently inactive. Re-activate this client so that you can build
                them a quote or project.</small
              >
            </span>
            <div>
              <btn class="btn btn-success pr-4" :action="makeActive" size="lg">
                Make client active
              </btn>
            </div>
          </card-list-field>
        </card-list>
      </card-section>

      <template v-if="user_is_using_temp_pass">
        <card-section>
          <template #label>
            <span>Client type</span>
          </template>
          <selection-toggle
            class="mb-3"
            :options="[
              { text: 'Residential', value: false },
              { text: 'Commercial', value: true }
            ]"
            :value="isCommercial || showCompany"
            @input="(b) => (showCompany = b)"
          />
          <fade>
            <card-list v-if="showCompany || isCommercial">
              <card-list-field>
                <span>Company name</span>
                <field schema="company:company_name" v-model="companyName" />
              </card-list-field>
              <card-list-field>
                <span>Company type</span>
                <choose :multiple="true" schema="client:trade_type_ids" v-model="trade_type_ids" />
              </card-list-field>
            </card-list>
          </fade>
        </card-section>

        <card-section>
          <template #label>
            <span>Contact details</span>
          </template>
          <card-list>
            <card-list-field>
              <span>First name</span>
              <field
                v-model="user_fname"
                schema="client:user_fname"
                :validate="{ required: false }"
              ></field>
            </card-list-field>
            <card-list-field>
              <span>Last name</span>
              <field
                v-model="user_lname"
                schema="client:user_lname"
                :validate="{ required: false }"
              ></field>
            </card-list-field>
            <card-list-field>
              <span>Phone number</span>
              <div>
                <btn
                  outline
                  severity="tertiary"
                  size="xs"
                  class="mr-2"
                  @click="primaryExtension = true"
                  v-if="primaryExtension !== true && !user_phone_ext"
                >
                  <template #icon>
                    <font-awesome-icon icon="plus" />
                  </template>
                  Extension
                </btn>
              </div>
              <field v-model="user_phone" schema="client:user_phone" :format="true"></field>
            </card-list-field>
            <card-list-field v-if="primaryExtension === true || user_phone_ext">
              <span>Extension</span>
              <field v-model="user_phone_ext" schema="client:user_phone_ext"></field>
            </card-list-field>
            <card-list-field>
              <span>Email address</span>
              <div>
                <UniqueField
                  v-model="user_email"
                  schema="user:user_email"
                  :validate="{ type: 'email' }"
                  :unique="{
                    type: 'user',
                    field: 'user_email',
                    except: client_user_id ? [client_user_id] : [],
                    exact: true
                  }"
                />
              </div>
            </card-list-field>
            <card-list-field>
              <span>Localization</span>
              <choose
                :return-array="false"
                schema="user:localization_id"
                v-model="localization_id"
                :validate="{ required: true }"
              />
            </card-list-field>
          </card-list>
        </card-section>

        <card-section>
          <template #label>
            <span>Client address</span>
          </template>
          <card-list>
            <card-list-field>
              <span>Address</span>
              <field v-model="user_address" />
            </card-list-field>
            <card-list-field>
              <span></span>
              <field v-model="user_suite" />
            </card-list-field>
            <card-list-field>
              <span>City</span>
              <field v-model="user_city" />
            </card-list-field>
            <card-list-field>
              <span>State/Province</span>
              <choose
                :return-array="false"
                :allowDeselect="false"
                schema="client:province_id"
                v-model="province_id"
              />
            </card-list-field>
            <card-list-field>
              <span>Postal/Zip Code</span>
              <field v-model="user_postal" />
            </card-list-field>
          </card-list>
        </card-section>
      </template>

      <card-section v-if="!user_is_using_temp_pass">
        <template #title>
          <span>Client details</span>
        </template>
        <card>
          <template #icon>
            <font-awesome-icon icon="user" />
          </template>
          <template #title>
            <span>This user manages their own account</span>
          </template>
          <p>
            This user has taken ownership of their own account, including managing details like
            their email, phone number, address and other personal details.
          </p>
        </card>
      </card-section>
    </div>
  </div>

  <!-- ----REQUESTS---- -->
  <div v-if="activeTab.includes('requests')" class="flex flex-row justify-center">
    <div
      v-if="client_status !== 'a' || oMeta || client_lead_message"
      class="w-full max-w-6xl py-12"
    >
      <card-section v-if="Object.keys(oMeta).length">
        <template #title> Requests received </template>
        <card-list v-for="(meta, time) in oMeta" :key="time">
          <card-list-header class="font-medium py-4">
            Request received at {{ $f.datetime(time) }}
          </card-list-header>

          <CardListField>
            <div>
              <div class="!flex !flex-row !items-center !justify-center gap-2">
                <font-awesome-icon icon="flag-swallowtail" />
                Marketing campaign / lead source
              </div>
            </div>

            <div>
              <Choose v-model="lead_source_id" schema="lead_source:lead_source_id" />
            </div>
          </CardListField>

          <card-list-field v-for="(value, key) in meta" :key="key">
            <div>
              <div v-if="leadFormData[key]">
                <div>{{ leadFormData[key].label }}</div>
                <small>{{ leadFormData[key].desc }}</small>
              </div>
              <div v-else>{{ String(key || '').replace(/_/g, ' ') }}</div>
            </div>
            <div
              v-if="typeof value === 'string' || typeof value === 'number'"
              class="p-4"
              style="
                line-height: 1.5;
                white-space: pre-line;
                word-wrap: break-word;
                overflow: hidden;
                max-width: 60%;
              "
            >
              {{ value }}
            </div>
            <span v-else>{{ $f.pretty(value) }}</span>
          </card-list-field>
        </card-list>
      </card-section>
      <card-section v-else class="w-full">
        <template #label> Requests received </template>
        <Info class="w-full" :closable="false" icon="house-flag">
          <div class="flex flex-col gap-4">
            <span>No requests to show</span>

            <p class="font-normal">
              When clients submit a request for a quote, there will be details about that request
              here. This client was created by a user in your company, or has not submitted a
              request for a quote yet.
            </p>
          </div>
        </Info>
      </card-section>
    </div>
  </div>

  <!-- ----RATINGS---- -->
  <div v-if="activeTab.includes('rating')" class="flex flex-row justify-center">
    <div class="w-full max-w-6xl">
      <client-ratings
        v-if="client_id && client_status !== 'l'"
        :isMain="false"
        :clientId="client_id"
        :showToolbar="false"
        :startColumn="2"
      />
    </div>
  </div>
  <!--    <floating-toolbar v-if="isDirty" class="modified bottom">-->
  <!--      <save-or-cancel-->
  <!--          :isDirty="isDirty"-->
  <!--          :loading="loading"-->
  <!--          @save="save"-->
  <!--          @cancel="reset"-->
  <!--          omitStyle-->
  <!--      />-->
  <!--    </floating-toolbar>-->
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import Activities from '../ui/activities/Activities.vue'
import Pipeline from './Pipeline.vue'
import ClientRatings from '@/components/bodies/ClientRatings.vue'
import UniqueField from '../ui/UniqueField.vue'

export default {
  mixins: [ObjectManipulator('client', true), BodyMixin],
  data() {
    return {
      apiKey: 'AIzaSyCxJHcDQP6cMI0XoGhtfWF_xyCbaWZWwPg',
      currentPage: 0, // 1 - Info, 2 - quotes, 3 - Invoices
      editing: false,
      startingTab: ['requests'],
      taskListId: null,
      completedTasksShown: false,
      primaryExtension: false,
      secondaryExtension: false,
      showCompany: false,
      cacheCompanyId: null,
      leadFormData: {}
    }
  },
  computed: {
    activeTab() {
      const rtq = this.$route.query && this.$route.query.tab
      if (rtq) return c.makeArray(rtq)
      return ['requests']
    },
    isCommercial() {
      return !c.empty(this.company_name) && this.company_name !== '' && this.company_name !== null
    },
    clientOwner: {
      get() {
        return this.client_owner
      },
      set(ownerId) {
        this.client_owner = ownerId
        c.throttle(() => {
          this.$store.dispatch('ajax', { path: `/contact/fetch/${ownerId}` }).then(({ object }) => {
            this.owner = object
          })
        })
      }
    },
    companyName: {
      get() {
        return this.company_name
      },
      set(cName) {
        if (!cName) {
          this.cacheCompanyId = this.client_company_id || this.cacheCompanyId
          this.client_company_id = null
        } else {
          this.client_company_id = this.cacheCompanyId
        }

        this.company_name = cName
      }
    },
    clientObject() {
      return {
        client_id: this.client_id,
        type: this.type,
        user_fname: this.user_fname,
        user_lname: this.user_lname,
        user_phone: this.user_phone,
        user_email: this.user_email,
        user_suite: this.user_suite,
        user_address: this.user_address,
        user_city: this.user_city,
        user_prov: this.user_prov,
        user_postal: this.user_postal,
        country_abbr: this.country_abbr
      }
    }
  },
  methods: {
    async makeActive() {
      if (this.getField('client_status') === 'l') return this.convert()
      await this.mark('a')
      await this.reload()
    },
    async makeInactive() {
      await this.mark('i')
      await this.reload()
    },
    async convert() {
      await this.$store.dispatch('Client/convert', { refId: this.refId, go: false })
      await this.reload()
    },
    afterSelect() {
      this.startingTab = this.client_status === 'l' ? ['Requests'] : ['Pipeline']
    },
    beforeSave() {
      this.editing = false
    },
    fetchCompletedTasks() {
      this.$store
        .dispatch('Task/search', {
          filters: {
            client_id: this.client_id,
            task_status: 'c'
          }
        })
        .then(({ set }) => {
          this.tasks = [...this.tasks, ...set]
          this.completedTasksShown = true
        })
    },
    addTask() {
      new Promise((resTl) => {
        if (this.taskListId) resTl(this.taskListId)
        else {
          this.$store
            .dispatch('TaskList/search', {
              filters: {
                client_id: this.client_id
              }
            })
            .then(({ set }) => {
              if (set.length) {
                resTl(set[0].task_list_id)
              } else {
                this.$store
                  .dispatch('ajax', {
                    path: 'task_list/create',
                    data: {
                      object: {
                        task_list_name: `Client: #${this.client_id} • ${this.client_name}`
                      }
                    }
                  })
                  .then(({ object }) => {
                    resTl(object.task_list_id)
                  })
              }
            })
        }
      }).then((taskListId) => {
        this.taskListId = taskListId
        this.tasks = [
          ...this.tasks,
          c.buildDefaultObject('task', {
            task_list_id: this.taskListId,
            client_id: this.client_id
          })
        ]
      })
    },
    call() {
      this.$store.dispatch('phone/call', {
        number: this.user_phone
      })
    }
  },
  components: {
    UniqueField,
    Activities,
    Pipeline,
    ClientRatings
  },
  props: {
    forceFull: {
      default: true
    }
  },
  async mounted() {
    this.leadFormData = await this.$store.dispatch('LeadRotation/getFormFieldsData')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.client-heading-container {
  height: 4em;
  width: 100%;
  background-color: $flame-white;
  border-bottom: 1px solid $cool-gray-300;

  .content-left {
    display: inline-block;
    margin-left: 1.4em;
    height: 100%;

    &.mobile {
      margin-left: 2.8em;
    }
  }

  .content-right {
    position: absolute;
    display: inline-block;
    right: 1.4em;
    padding-top: 0.78em;

    .client-action-buttons {
      display: inline;
    }

    .client-next-steps {
      display: inline;
    }
  }
}

.item-list-item {
  transition: all 1s;
  display: block;
}
.item-list-enter, .item-list-leave-to
    /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.item-list-leave-active {
  position: absolute;
}

div[contenteditable='true'] {
  border-bottom: 1px dashed $pitch-black;
  color: $pitch-black;
}

.info-block-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .info-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5em 0;
    :first-child {
      margin-right: 1em;
    }
  }
}
.navigation {
  border-bottom: 1px solid lightgray;
}
.nav-tab {
  border: 1px solid lightgray;
  background-color: lightgray;
  border-radius: 5px 5px 0 0;
  height: 2em;

  &.empty {
    border: none;
    border-bottom: 1px solid lightgray;
    background-color: whitesmoke;
  }

  &.active {
    background-color: inherit;
    border-bottom: 1px solid whitesmoke;
  }
}

.client-avatar {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  .client-name {
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    h2,
    h4 {
      margin: 0;
      text-transform: capitalize;
    }
  }
}
</style>
