<script setup>
import AreaDimensions from '@/components/quote/ProjectDimensions/AreaDimensions.vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { computed, defineProps, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useMediaQuery } from '@/composables/mediaQuery.js'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'assembly' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true },
  showInfo: { type: Boolean, default: true },
  showContents: { type: Boolean, default: true },
  oMod: { type: Object, required: false }
})

const $store = useStore()

const { smallFormat } = useMediaQuery()

const refId = ref(props.refId || props.reference || 'root')
const type = ref(props.type || 'quote')
const store = ref(props.store || props.storeName)

const obj = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store
})

const { norm: fullNorm, selected } = obj

const norm = ref({})
const assembliesWithRequiredDimensions = computed(() =>
  Object.values(norm.value).filter(
    (item) => item.type !== 'cost_item' && item.asDimensionsUsed?.length
  )
)

const localDimensions = ref({})

let ignore = false
watch(fullNorm, () => c.throttle(loadFull))

const isDirty = ref(false)
watch(
  localDimensions,
  () => {
    isDirty.value = true
    if (ignore) return false
    else c.throttle(() => commitDimensions(), { delay: 750 })
  },
  {
    deep: true
  }
)

const loadFull = async () => {
  ignore = true
  // Get subset of norm for all descendants only
  norm.value = NormalizeUtilities.extractDescendants(fullNorm.value, [refId.value], true)
  localDimensions.value = [...assembliesWithRequiredDimensions.value].reduce(
    (acc, assembly) => ({
      ...acc,
      [assembly.refId]: assembly.oDimensions
    }),
    {}
  )

  setTimeout(() => {
    ignore = false
  })
}

onBeforeMount(async () => {
  await selected
  loadFull()
})

const recalcAddonsFor = ref([])
watch(recalcAddonsFor, async (refIds) => {
  if (!refIds?.length) return
  // Recalc linked dimension addons
  c.throttle(async () => {
    const urefids = _.cleanArray(refIds)
    recalcAddonsFor.value = [] // flush
    await $store.dispatch('Quote/recalcAddons', { refIds: urefids, loading: false })
  })
})

const commitDimensions = async () => {
  if (!isDirty.value) return
  if (ignore) return
  isDirty.value = false

  const refs = Object.keys(localDimensions.value ?? {})

  if (!refs.length) return

  // PUt in correct format
  const structured = Object.keys(localDimensions.value).reduce(
    (acc, ref) => ({
      ...acc,
      [ref]: {
        oDimensions: localDimensions.value[ref]
      }
    }),
    {}
  )

  await $store.dispatch(`${store.value}/field`, {
    changes: structured,
    explicit: true,
    immediate: true
  })
}
onBeforeUnmount(async () => commitDimensions())
</script>

<template>
  <div class="flex flex-col gap-12">
    <template v-if="assembliesWithRequiredDimensions.length">
      <div
        class="flex flex-col gap-2"
        v-for="assembly in assembliesWithRequiredDimensions"
        :key="assembly.refId"
      >
        <div
          class="relative text-2xl font-medium px-2 py-0.5 rounded-sm bg-pitch-black text-white select-none cursor-pointer"
        >
          {{ assembly.assembly_name || 'Project root' }}
          <div
            class="opacity-0 hover:opacity-100 absolute inset-0 text-sm flex justify-start items-center font-normal px-2 py-0.5 rounded-sm bg-pitch-black text-flame-white select-none cursor-pointer gap-2"
          >
            Project root
            <font-awesome-icon icon="arrow-right" /> {{ assembly.asAssemblyPath[0] }}
            <div
              v-for="segment in assembly.asAssemblyPath.slice(1)"
              :key="segment"
              class="flex justify-start items-center gap-2"
            >
              <font-awesome-icon icon="arrow-right" />
              <span>{{ segment }}</span>
            </div>
          </div>
        </div>
        <AreaDimensions
          :full="!smallFormat"
          v-model="localDimensions[assembly.refId]"
          :showDerivedDimensions="true"
          :object="assembly"
          :store="store"
          :reference="assembly.refId"
          inputSize="text-xl"
        />
      </div>
    </template>
    <template v-else>
      <div class="w-full h-full flex justify-center items-center">
        <div class="bg-surface-100 rounded-md p-4">
          <div class="text-xl">No required dimensions yet.</div>
          <div class="text-surface-400">
            Link an item's qty to a dimension first and you will see the takeoff requirement appear
            here.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
