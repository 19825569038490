<template>
  <div class="field-item flex px-1 gap-1 hover:bg-cement-300 py-2">
    <div class="items-center justify-center hidden md:flex">
      <Btn
        v-tooltip="'Add field'"
        :pt="{ root: '!px-2 !text-pitch-black' }"
        size="lg"
        class="field-item--action transition"
        @click.native="toggleMenu"
        :link="true"
      >
        <template #icon>
          <font-awesome-icon :icon="['far', 'plus']" />
        </template>
      </Btn>
    </div>
    <Menu ref="menu" :model="fieldTypesAndFormatting" :popup="true">
      <template #item="{ item }">
        <a
          v-if="item"
          v-ripple
          class="flex items-center cursor-pointer py-1 px-2 my-1"
          @click="() => selectFieldType(item, index)"
        >
          <span class="mr-2" v-if="item.icon">
            <font-awesome-icon :icon="['far', item.icon]" />
          </span>
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </template>
      <template #submenuheader="{ item }">
        <p class="text-sm font-medium text-cool-gray-500">{{ item.label }}</p>
      </template>
    </Menu>
    <div class="bg-white p-4 border border-cool-gray-400 rounded-md w-full">
      <div class="flex flex-col w-full">
        <div class="flex justify-between w-full items-center mb-4" v-if="isOptionField">
          <span class="mr-3 text-pitch-black font-medium"> {{ validationOption.label }} </span>
          <div class="flex flex-col md:flex-row md:items-center md:justify-end ml-4">
            <label class="block md:hidden mb-2">Required field:</label>
            <span class="flex items-center">
              <label class="mr-2 !font-normal text-pitch-black text-xs" for="required">{{
                item.custom_field_is_required ? 'Required' : 'Not required'
              }}</label>
              <InputSwitch
                inputId="required"
                v-model="item.custom_field_is_required"
                v-tooltip="`Mark as ${item.custom_field_is_required ? 'required' : 'not required'}`"
              />
            </span>
          </div>
        </div>
        <div class="flex items-center text-nowrap text-cool-gray-700 font-normal w-full">
          <span class="mr-3 text-pitch-black font-medium" v-if="isTextField && !validationOption"
            >Text field</span
          >
          <span class="mr-3 text-pitch-black font-medium" v-if="isTextField && validationOption">{{
            validationOption.label
          }}</span>
          <Field
            type="text"
            placeholder="Name of field"
            :pt="{ root: '!w-full' }"
            class="w-full"
            :validate="{ required: true }"
            v-model="item.custom_field_name"
          />
          <div
            v-if="!isOptionField"
            class="flex flex-col md:flex-row md:items-center md:justify-end ml-4"
          >
            <label class="block md:hidden mb-2">Required field:</label>
            <span class="flex items-center">
              <label class="mr-2 !font-normal text-pitch-black text-xs" for="required">{{
                item.custom_field_is_required ? 'Required' : 'Not required'
              }}</label>
              <InputSwitch
                inputId="required"
                v-model="item.custom_field_is_required"
                v-tooltip="`Mark as ${item.custom_field_is_required ? 'required' : 'not required'}`"
              />
            </span>
          </div>
        </div>
      </div>
      <CustomFieldOptions class="mt-4" v-if="isOptionField" :field="item" />
    </div>
    <div class="flex items-center justify-center">
      <Btn
        class="field-item--action"
        :pt="{ root: '!px-2 !text-pitch-black' }"
        size="lg"
        v-tooltip="'Remove field'"
        :link="true"
        @click="() => removeField(item.uid)"
      >
        <template #icon>
          <font-awesome-icon :icon="['far', 'times']" />
        </template>
      </Btn>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed, ref } from 'vue'
import CustomFieldOptions from '@/components/forms/CustomFieldOptions.vue'
import InputSwitch from 'primevue/inputswitch'
import useForm from '@/components/forms/Form'
import Menu from 'primevue/menu'

const { fieldTypesAndFormatting, removeField, selectFieldType } = useForm()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number
  }
})

const menu = ref()

const isOptionField = computed(() =>
  ['select', 'radio', 'checkbox'].includes(item.value.custom_field_type)
)

const isTextField = computed(() => ['textfield'].includes(item.value.custom_field_type))

const validationOption = computed(() =>
  fieldTypesAndFormatting.value[0].items.find(
    (i) =>
      i.formatting === props.item.custom_field_validation ||
      i.value === props.item.custom_field_type
  )
)

const toggleMenu = (event) => menu.value.toggle(event)

const { item } = toRefs(props)
</script>

<style lang="scss" scoped>
.field-item {
  .field-item--action {
    opacity: 0;
  }
  &:hover {
    .field-item--action {
      opacity: 1;
    }
  }
}
</style>
