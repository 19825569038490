<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import {
  fetchMFAPreference,
  updateMFAPreference,
  setUpTOTP,
  verifyTOTPSetup
} from 'aws-amplify/auth'
import QRCode from 'qrcode'
import InputOtp from 'primevue/inputotp'
import Btn from '@/components/ui/Btn.vue'

InputOtp.compatConfig = { MODE: 3 }

const store = useStore()

const qrCode = ref()
const setupSecret = ref()
const confirmationCode = ref()
const loading = ref(true)
const enabled = ref()
const preferred = ref()

async function enableMfa() {
  const result = await setUpTOTP()
  setupSecret.value = result.sharedSecret
  const setupUri = result.getSetupUri('Bolster')
  qrCode.value = await QRCode.toDataURL(setupUri.toString(), {
    type: 'image/webp'
  })
}

async function confirmMfa() {
  try {
    await verifyTOTPSetup({
      code: confirmationCode.value
    })
    await updateMFAPreference({ totp: 'PREFERRED' })
    await checkMFAPreference()
  } catch (e) {
    await store.dispatch('alert', {
      error: true,
      message:
        e.message === 'Code mismatch'
          ? 'Incorrect confirmation code. Please try again'
          : 'An error occurred. Please try again.'
    })
  }
}

async function checkMFAPreference() {
  const result = await fetchMFAPreference()
  enabled.value = result.enabled
  preferred.value = result.preferred
  loading.value = false
}

async function disableMFA() {
  await updateMFAPreference({ totp: 'DISABLED' })
  await checkMFAPreference()
  qrCode.value = null
  confirmationCode.value = null
}

checkMFAPreference()
</script>

<template>
  <div v-if="loading">
    <font-awesome-icon icon="spinner" spin size="2xl" />
    Checking...
  </div>
  <div v-else class="flex flex-col gap-y-4">
    <template v-if="enabled">
      Multi-Factor Authentication is enabled for your account.
      <div class="flex justify-center">
        <Btn
          class="h-field !font-medium"
          size="lg"
          severity="danger"
          ref="btn"
          :action="disableMFA"
        >
          Disable MFA
        </Btn>
      </div>
    </template>
    <template v-else>
      <div v-if="!qrCode" class="flex flex-col items-center gap-y-4">
        <font-awesome-icon icon="lock" size="2xl" />
        Set up multi-factor authentication to add an additional layer of protection to your account
        <Btn
          class="h-field !font-medium"
          size="lg"
          severity="primary-yellow"
          ref="btn"
          :action="enableMfa"
        >
          Set Up
        </Btn>
      </div>
      <template v-if="qrCode">
        <p>
          Scan the QR code below to set up your Authenticator app (such as Google Authenticator or
          Authy)
        </p>
        <div class="flex flex-col items-center gap-y-4">
          <img :src="qrCode" alt="QR Code for MFA" />
          <div class="flex flex-col items-center text-xs">
            <p>Can't scan the QR code? Use this setup code instead:</p>
            <span>{{ setupSecret }}</span>
          </div>
          <p>Once your app is set up, paste a code here to confirm</p>
          <InputOtp v-model="confirmationCode" :length="6" integer-only />
          <Btn
            class="h-field !font-medium"
            size="lg"
            severity="primary-yellow"
            ref="btn"
            :action="confirmMfa"
          >
            Confirm
          </Btn>
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
