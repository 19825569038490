<template>
  <div :class="tabClasses">
    <div class="el-tabs__header">
      <div class="el-tabs__nav-wrap">
        <div class="el-tabs__nav-scroll">
          <div class="el-tabs__nav">
            <div class="el-tabs__active-bar" :style="barStyle"></div>
            <div
              v-for="(pane, index) in panes"
              :key="index"
              class="el-tabs__item"
              :style="`min-width: ${minTabWidth}px;`"
              :ref="pane.name"
              :class="{ 'is-active': tab === pane.name }"
              @click.stop.prevent="tab = pane.name"
            >
              <span>
                <span v-if="pane.glyph" :class="pane.glyph"></span>
                <badge
                  :value="pane.badge"
                  :isDot="pane.dot"
                  :hidden="!pane.dot && !pane.badge"
                  class="item"
                >
                  <span v-text="pane.label"></span>
                </badge>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="el-tabs__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Badge from './Badge.vue'

export default {
  name: 'Tabs',
  components: { Badge },
  emits: ['input', 'TabClick'],
  data() {
    return {
      tab: this.value || '',
      panes: [],
      tabSlots: {},
      barStyle: '',
      minTabWidth: 0
    }
  },
  watch: {
    value(val) {
      this.tab = val
    },
    tab(val) {
      this.$emit('input', val)
      this.barStyle = this.getBarStyle()
    }
  },
  computed: {
    tabClasses() {
      return `el-tabs
          ${this.type === 'card' ? 'el-tabs--card' : ''}
          ${this.type === 'border-card' ? 'el-tabs--border-card' : ''}`
    },
    activeTab: {
      get() {
        return this.tab
      },
      set(val) {
        this.tab = val
        this.$emit('input', val)
      }
    },
    filteredSlots() {
      return this.$slots.default().filter((f) => f.tag)
    }
  },
  methods: {
    getBarStyle() {
      if (this.type) return 'display: none;'

      const activeTab = this.tab
      let offset = 0
      let tabWidth = 0

      Object.keys(this.$refs).forEach((r) => {
        if (activeTab !== r && !tabWidth) {
          offset += this.$refs[r][0].clientWidth
        } else {
          tabWidth = this.$refs[r][0].clientWidth
        }
      })

      return `transform: translateX(${offset}px); width: ${tabWidth}px;`
    },
    addPane(item) {
      const index = this.filteredSlots.indexOf(item.$vnode)
      this.panes.splice(index, 0, item)
    },
    removePane(item) {
      const index = this.panes.indexOf(item)
      this.panes.splice(index, 1)
    },
    tabClick(tab, event) {
      this.$emit('TabClick', tab, event)
    }
  },
  props: {
    value: {
      required: false
    },
    type: {
      required: false,
      default: false
    }
  },
  mounted() {
    if (c.isempty(this.tab)) {
      this.tab = this.filteredSlots[0].child.name
    }
    this.$nextTick(() => {
      this.barStyle = this.getBarStyle()

      // Then determine the biggest width for the panes tabs
      const min = this.panes.reduce((acc, pane) => {
        const width = c.makeArray(this.$refs[pane.name])[0].getBoundingClientRect().width
        return acc > width ? acc : width
      }, 0)
      this.minTabWidth = min * 0.75
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.el-badge__content.is-fixed.is-dot {
  right: 0.4em !important;
  top: auto !important;
  bottom: 1.5em !important;
}

.el-tabs__nav-scroll {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}

.el-tabs__header {
  border-bottom: 1px solid $blue-print-700 !important;
  pointer-events: none;
  margin: 0px;
  .el-tabs__nav {
    pointer-events: all;
    width: 100%;
    white-space: pre-wrap;
    // @media (max-width: 576px) {
    //   overflow: hidden !important;
    // }
  }
}

.el-tabs__content {
  overflow: visible !important;
}

.el-tabs__item {
  color: $cool-gray-400 !important;
  position: relative;
  text-align: center !important;
  &.is-active {
    color: $blue-print-700 !important;
    // @media (max-width: 576px) {
    //   background-color: lightgray;
    // }
  }
}
.el-tabs__active-bar {
  background-color: $blue-print-700 !important;

  // @media (max-width: 576px) {
  //   visibility: hidden;
  // }
}

.el-tab-pane {
  padding: 10px;
}

.el-tabs--border-card {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid $cool-gray-500;
  background: $flame-white !important;
  > .el-tabs__header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $blue-print-700 !important;
    pointer-events: none;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        // @media (max-width: 576px) {
        //   overflow-x: hidden !important;
        // }

        .el-tabs__nav {
          max-width: 100%;
          pointer-events: all;
          float: right;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          .el-tabs__item {
            font-size: 0.8em;
            &:last-child {
              border-top-right-radius: 5px;
            }
            &.is-active {
              background: $flame-white !important;
            }
          }
        }
      }
    }
  }
}

.el-tabs__item {
  padding: 0 8px !important;
  // @media (max-width: 576px) {
  //   width: 49%;
  // }
  .badged {
    display: inline;
  }
}
</style>
