import { useRoute, useRouter } from 'vue-router'

/**
 * The page mixin works alongside the objectmanipulator mixin.
 * Put the page mixin on the actual page component that is linked to
 * by the vue-router.  This component will auto-discover the
 * component/child in the page that uses the object manipulator mixin
 * and will confirm whether to leave if there are changes detected.
 *
 * In order for the page mixin to confirm to leave, there must be
 * the confirmLeave meta value set on the particular route that points to
 * this page.
 *
 * ie:
 *
 * new Router({
 *  routes: [
 *    {
 *      name: 'my page',
 *      route: '/page',
 *      meta: {
 *        confirmLeave: true,
 *      },
 *    },
 *  ],
 * })
 */
export default {
  data() {
    return {
      hasPageMixin: true,
      pageObjectManipulatorComponent: null
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      this &&
      this.pageObjectManipulatorComponent &&
      this.pageObjectManipulatorComponent.isDirty
    ) {
      const fn = (b) => {
        if (b === true) {
          this.pageObjectManipulatorComponent.isDirty = false
          this.$router.push(_.imm(to)).catch(() => {})
        }
      }
      this.confirmLeave(fn)
    } else {
      next(true)
    }
  },
  methods: {
    mountedProcedure() {
      if (this.doConfirmLeave) {
        let deepLimit = 10
        const findObjectManipulator = (children) => {
          deepLimit -= 1
          const foundChild = children.find((child) => {
            if (child.hasChangeTrackingMixin) {
              return child
            } else if (child.$children && child.$children.length && deepLimit > -1) {
              return findObjectManipulator(child.$children)
            }
            return false
          })
          return foundChild
        }
        this.pageObjectManipulatorComponent = this.hasChangeTrackingMixin
          ? this
          : findObjectManipulator(this.$children)
      }
    },
    confirmLeave(next) {
      if (
        this.doConfirmLeave &&
        this.pageObjectManipulatorComponent &&
        this.pageObjectManipulatorComponent.isDirty
      ) {
        this.$store.dispatch('modal/confirm', {
          message: 'Are you sure you want to leave?  Your changes will be lost.',
          actions: {
            cancel: {
              title: `Cancel`,
              action: () =>
                new Promise((resolve) => {
                  next(false)
                  resolve()
                })
            },
            confirm: {
              class: 'btn btn-sm btn-outline-primary',
              title: 'Yes, abandon changes..',
              action: () =>
                new Promise((resolve) => {
                  this.pageObjectManipulatorComponent
                    .reset()
                    .then(() => {
                      next(true)
                      resolve()
                    })
                    .catch(() => {
                      next(true)
                      resolve()
                    })
                })
            },
            ...(this.pageObjectManipulatorComponent.save
              ? {
                  save: {
                    class: 'btn btn-sm btn-primary',
                    title: 'Save changes first..',
                    action: () =>
                      new Promise((resolve, reject) => {
                        this.pageObjectManipulatorComponent
                          .save()
                          .then(() => {
                            next(true)
                            resolve()
                          })
                          .catch(() => {
                            next(false)
                            reject()
                          })
                      })
                  }
                }
              : {})
          }
        })
      } else {
        next(true)
      }
    }
  },
  activated() {
    this.pageRoute = this.$route
    this.mountedProcedure()
  },
  deactivated() {
    if (this.pageObjectManipulatorComponent || !this.pageRoute.meta.keepAlive) {
      this.$children.forEach((ch) => ch.$destroy(true))
      this.$destroy(true)
    }
  },
  props: {
    doConfirmLeave: {
      default() {
        const route = useRoute()
        return route?.meta?.confirmLeave
      }
    }
  }
}
