// TODO Refactor
import * as faIcons from '@fortawesome/pro-thin-svg-icons'
// import * as fabIcons from '@fortawesome/pro-regular-svg-icons'
// import * as fabIcons from '@fortawesome/pro-solid-svg-icons'

const {
  faBank,
  faBoxCheck,
  faCartShopping,
  faCoin,
  faCoins,
  faHandHoldingDollar,
  faLock,
  faLockOpen,
  faSparkles,
  faTable,
  faTruckLadder,
  faUserHelmetSafety,
  faBoxTaped,
  faTruckPickup,
  faPersonCirclePlus,
  faTruck,
  faFileSignature,
  faClipboardCheck,
  faBadgeCheck,
  faHouseCircleCheck,
  faMoneyBillWave,
  faCheckSquare,
  faImages
} = faIcons

import CatalogAdd from '@/assets/SVG/catalog-add-singleton.svg?raw'
import CatalogSaveChanges from '@/assets/SVG/catalog-save-singleton.svg?raw'
import CatalogSaved from '@/assets/SVG/catalog-saved-singleton.svg?raw'

const extractPathDataFromString = (svgString) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(svgString, 'image/svg+xml')
  const svgElement = doc.getElementsByTagName('svg')[0]
  const pathElements = svgElement.getElementsByTagName('path')
  const pathData = []
  for (let i = 0; i < pathElements.length; i++) {
    const d = pathElements[i].getAttribute('d')
    pathData.push(d)
  }
  return pathData.join(' ')
}

//
// const {
//   faClipboardCheck,
//   faBadgeCheck,
//   faHouseCircleCheck,
//   faMoneyBillWave,
// } = fabIcons

const concrete = '#e6e3e0'
const stroke = 'rgba(0, 0, 0, 0.1)'
const gray = '#585858'
const lightGray = '#7a7a7a'
const lightGrayTrans = '#7a7a7a66'
const black = '#1c1a1b'
const blue = '#0b4bff'
const blueTrans = '#0b4bff8a'
const warningTrans = c.hexWithOpacity('#fce4a2', 0.5)
const disabledBackground = c.hexWithOpacity(lightGray, 0.1)
const disabledColor = c.hexWithOpacity(black, 0.6)
const levelYellow = '#FBFF00'

const presetFormats = {
  superHeader: {
    background: c.getCssColor('cement-50'),
    color: black
  },
  readonly: {
    background: '#e5e3e040',
    color: lightGray
  },
  muted: {
    background: '#e5e3e040',
    color: lightGray
  },
  success: {
    background: '#bad6bd',
    color: '#004639'
  },
  danger: {
    background: '#ffc6cd',
    color: '#620914'
  },
  warning: {
    background: '#fce4a2',
    color: '#533f08'
  },
  info: {
    background: '#c7e4ff',
    color: '#034480'
  },
  footing: {
    color: gray,
    background: concrete
  },
  heading: {
    color: black,
    background: c.blendColors(
      c.rgbToHex(c.getCssColor('surface-100')),
      c.rgbToHex(c.getCssColor('cement-300')),
      0.4
    )
  },
  disabled: {
    background: '#e5e3e040',
    color: 'transparent',
    borders: {
      left: {
        thickness: 0
      },
      right: {
        thickness: 0
      }
    }
  },
  disabledTransparent: {
    color: 'transparent',
    background: disabledBackground
  },
  disabledBackground: {
    background: disabledBackground
  },
  highlight: {
    background: c.getCssColor('purple-100')
    // borders: {
    //   top: {
    //     color: warningTrans,
    //     thickness: 1,
    //   },
    //   right: {
    //     color: warningTrans,
    //     thickness: 1,
    //   },
    //   bottom: {
    //     color: warningTrans,
    //     thickness: 1,
    //   },
    //   left: {
    //     color: warningTrans,
    //     thickness: 1,
    //   },
    // },
  },
  checkmarkChecked: {}
}

const fa = 'FAS'
const fathin = 'FATHIN'
const icons = {
  paid: {
    ch: '',
    ff: fa,
    icon: faHandHoldingDollar.icon
  },
  boxCheck: {
    ch: '',
    ff: fa,
    icon: faBoxCheck.icon
  },
  cart: {
    ff: fa,
    ch: '',
    icon: faCartShopping.icon
  },
  'file-signature': {
    ff: fa,
    ch: '',
    icon: faFileSignature.icon
  },

  'check-square': {
    ff: fa,
    ch: 'c',
    icon: faCheckSquare.icon
  },
  images: {
    ff: fa,
    ch: 'c',
    icon: faImages.icon
  },
  'box-taped': {
    ff: fa,
    ch: '',
    icon: faBoxTaped.icon
  },
  'truck-ladder': {
    ff: fa,
    ch: '',
    icon: faTruckLadder.icon
  },
  truck: {
    ff: fa,
    ch: '',
    icon: faTruck.icon
  },
  'truck-pickup': {
    ff: fa,
    ch: '',
    icon: faTruckPickup.icon
  },
  'person-circle-plus': {
    ff: fa,
    ch: '',
    icon: faPersonCirclePlus.icon
  },
  'user-helmet-safety': {
    ff: fa,
    ch: '',
    icon: faUserHelmetSafety.icon
  },
  coins: {
    ff: fa,
    ch: '',
    icon: faCoins.icon
  },
  coin: {
    ff: fa,
    ch: '',
    icon: faCoin.icon
  },
  pay: {
    ff: fathin,
    ch: '',
    icon: faCoin.icon
  },
  sparkles: {
    ff: fa,
    ch: '',
    icon: faSparkles.icon
  },
  bank: {
    ff: fa,
    ch: '',
    icon: faBank.icon
  },
  sheet: {
    ff: fa,
    ch: '',
    icon: faTable.icon
  },
  handDollar: {
    ff: fa,
    ch: '',
    icon: faHandHoldingDollar.icon
  },
  locked: {
    ff: fa,
    ch: '',
    icon: faLock.icon
  },
  unlocked: {
    ff: fa,
    ch: '',
    icon: faLockOpen.icon
  },

  'catalog-saved': {
    icon: [650, 538, undefined, undefined, extractPathDataFromString(CatalogSaved), false]
  },
  'catalog-save-changes': {
    icon: [650, 649, undefined, undefined, extractPathDataFromString(CatalogSaveChanges), false]
  },
  'catalog-add': {
    icon: [650, 535, undefined, undefined, extractPathDataFromString(CatalogAdd), false]
  },
  'clipboard-check': {
    ff: fa,
    ch: '',
    icon: faClipboardCheck.icon
  },
  'badge-check': {
    ff: fa,
    ch: '',
    icon: faBadgeCheck.icon
  },
  'house-circle-check': {
    ff: fa,
    ch: '',
    icon: faHouseCircleCheck.icon
  },
  'money-bill-wave': {
    ff: fa,
    ch: '',
    icon: faMoneyBillWave.icon
  }
}
const drawCircleWithCaret = (ctx, x, y, radius) => {
  ctx.beginPath()

  // Draw the arc
  ctx.arc(x, y, radius, 0.25 * Math.PI, 0.75 * Math.PI, true)

  // Move to the bottom right of the arc
  ctx.moveTo(x + Math.cos(0.75 * Math.PI) * radius, y + Math.sin(0.75 * Math.PI) * radius)

  ctx.lineTo(x, y + radius + radius * 0.3)

  // Draw a line to the bottom left of the arc
  ctx.lineTo(x + Math.cos(0.25 * Math.PI) * radius, y + Math.sin(0.25 * Math.PI) * radius)

  // Optional: close the path if you want to fill the shape
  ctx.closePath()

  // Fill the semi-circle with the flat bottom
  ctx.fill()
}

const interpolateColor = (lightColor, darkColor, ifactor) => {
  // Convert hex color to RGB
  const colorToRGB = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return [r, g, b]
  }

  // Interpolate between two colors
  const interpolate = (start, end, subfactor) => start + (end - start) * subfactor

  const rgbLight = colorToRGB(lightColor)
  const rgbDark = colorToRGB(darkColor)

  const r = interpolate(rgbLight[0], rgbDark[0], ifactor)
  const g = interpolate(rgbLight[1], rgbDark[1], ifactor)
  const b = interpolate(rgbLight[2], rgbDark[2], ifactor)

  // Convert back to hex color
  return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`
}

const drawCheckmark = (ctx, x, y, size, color = '#FFFFFF') => {
  // Calculate line width based on size (proportional scaling)
  const lineWidth = size / 7.5 // Adjust this factor to change line thickness

  // Set the properties for the checkmark
  ctx.strokeStyle = color // Checkmark color
  ctx.lineWidth = lineWidth
  ctx.lineJoin = 'round' // Rounded corners
  ctx.lineCap = 'round' // Rounded ends of the lines

  // Start drawing the checkmark
  ctx.beginPath()

  // Adjust the checkmark points based on size and position
  ctx.moveTo(x + size * 0.17, y + size * 0.5)
  ctx.lineTo(x + size * 0.4, y + size * 0.73)
  ctx.lineTo(x + size * 0.83, y + size * 0.27)

  // Finish the path and stroke it
  ctx.stroke()
}

const getMaxCoordinates = (svgPathData) => {
  // Split the path data into commands and coordinates
  const commands = svgPathData.match(/[a-zA-Z]+|[0-9.-]+/g)

  let maxX = 0
  let maxY = 0

  let currentX = 0
  let currentY = 0

  commands.forEach((command, index) => {
    if (isNaN(command)) {
      // It's a command letter, not a coordinate
      return
    }

    const value = parseFloat(command)

    // Check if the index is even or odd to determine if it's an X or Y coordinate
    if (index % 2 === 0) {
      currentX = value
      if (currentX > maxX) {
        maxX = currentX
      }
    } else {
      currentY = value
      if (currentY > maxY) {
        maxY = currentY
      }
    }
  })

  return { maxX, maxY }
}

const drawIcon = (ctx, clipTo, icon, color, padding = 10, increase = 20) => {
  if (!icons[icon]) {
    const faFormat = `fa${icon
      .split('-')
      .map((iconName) => c.ucfirst(iconName))
      .join('')}`
    if (faIcons[faFormat])
      icons[icon] = {
        ff: fa,
        ch: '-',
        icon: faIcons[faFormat].icon
      }

    if (!icons[icon]) {
      console.warn(`Icon ${icon} does not exist`)
      return
    }
  }

  const [xx, yy, ss, ssy = ss] = clipTo
  const x = xx + padding
  const y = yy + padding
  const size = Math.min(ss, ssy) - padding * 2

  const [maxX, maxY, , , svgPathData, allowBold = true] = icons[icon].icon

  ctx.fillStyle = color
  const path = new Path2D(svgPathData)

  const scale = size / Math.max(maxX, maxY)
  const nudgeX = (maxX < maxY ? (maxY - maxX) / 2 : 0) * scale
  const nudgeY = (maxX > maxY ? (maxX - maxY) / 2 : 0) * scale

  // Scale the path to fit the size
  ctx.save()

  if (scale < 0) {
    ctx.translate(0, -(ssy + size - padding * 2))
    ctx.scale(1, -1)
    ctx.translate(x + nudgeX, y + nudgeY)
    ctx.scale(scale, scale)
  } else {
    ctx.translate(x + nudgeX, y + nudgeY)
    ctx.scale(scale, scale)
  }

  ctx.fill(path)
  if (increase > 0 && allowBold) {
    ctx.strokeStyle = color
    ctx.lineWidth = increase // Ensure stroke width increase
    ctx.stroke(path)
  }
  ctx.restore()
}

const initializeCanvas = (
  canvasProvided,
  width,
  height,
  canvasPixelRatio = window.devicePixelRatio
) => {
  const canvas = canvasProvided

  const canvasWidth = width * canvasPixelRatio
  const canvasHeight = height * canvasPixelRatio

  canvas.width = canvasWidth
  canvas.height = canvasHeight

  const ctx = canvas.getContext('2d')
  ctx.scale(canvasPixelRatio, canvasPixelRatio)

  if (canvas.style) {
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
  }

  return {
    canvas,
    ctx,
    pixelRatio: canvasPixelRatio
  }
}

const createCanvas = (width, height, pixelRatio = window.devicePixelRatio) => {
  width = Math.min(10000, Math.max(40, width))
  height = Math.min(10000, Math.max(10, height))
  const canv = new window.OffscreenCanvas(width, height)
  const { ctx } = initializeCanvas(canv, width, height, pixelRatio)

  return {
    canvas: canv,
    ctx,
    height,
    width,
    pixelRatio
  }
}

const roundRect = (args) => {
  const {
    ctx,
    clipTo: [x, y, width, height],
    borderRadius: br = 3,
    padding = 0
  } = args
  // Define the rectangle dimensions and position
  const rheight = height - padding * 2 // Height adjusted for padding
  const rwidth = width - padding * 2 // Width adjusted for padding
  const xx = x + padding // X coordinate
  const yy = y + padding // Y coordinate

  // Draw the rectangle with rounded corners
  ctx.beginPath()
  ctx.moveTo(xx + br, yy) // Adjust starting point for border radius
  ctx.lineTo(xx + rwidth - br, yy)
  ctx.arcTo(xx + rwidth, yy, xx + rwidth, yy + br, br)
  ctx.lineTo(xx + rwidth, yy + rheight - br)
  ctx.arcTo(xx + rwidth, yy + rheight, xx + rwidth - br, yy + rheight, br)
  ctx.lineTo(xx + br, yy + rheight)
  ctx.arcTo(xx, yy + rheight, xx, yy + rheight - br, br)
  ctx.lineTo(xx, yy + br)
  ctx.arcTo(xx, yy, xx + br, yy, br)
  ctx.lineJoin = 'round'
  ctx.lineCap = 'round'

  return ctx
}

export default {
  drawCircleWithCaret,
  icons,
  presetFormats,
  disabledColor,
  disabledBackground,
  stroke,
  concrete,
  gray,
  lightGray,
  lightGrayTrans,
  black,
  blue,
  blueTrans,
  warningTrans,
  drawCheckmark,
  interpolateColor,
  initializeCanvas,
  createCanvas,
  drawIcon,
  roundRect,
  getMaxCoordinates,
  levelYellow
}
