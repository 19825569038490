export default {
  type: 'form_field',

  skipAudit: true,

  fields: {
    form_field_id: {
      type: 'int'
    },
    form_field_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    form_field_time_created: {
      type: 'int'
    },
    form_field_time_last_modified: {
      type: 'int'
    },
    form_field_status: {
      type: 'int'
    },
    form_id: {
      type: 'int',
      required: true
    },
    custom_field_id: {
      type: 'int',
      required: true
    }
  }
}
