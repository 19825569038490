<template>
  <Container size="5xl" class="!w-full !mt-0 !mb-0">
    <CardSection>
      <template #title>Schedule settings</template>
      <div class="flex flex-col w-full border border-surface-300 rounded p-4">
        <DaysOfWeek v-model="settings.asWorkdays" @input="saveWorkdays" />
        <WorkingHours
          class="mt-4 pt-4"
          v-model="settings.oWorkingHours"
          @input="saveWorkingHours"
        />
      </div>
    </CardSection>
  </Container>
</template>

<script>
import DaysOfWeek from '../schedule/fields/DaysOfWeek.vue'
import WorkingHours from '../schedule/fields/WorkingHours.vue'
import { workdays } from '../schedule/fields/defaultWorkdays'

export default {
  name: 'CompanyProjectSettings',
  components: {
    DaysOfWeek,
    WorkingHours
  },
  props: {
    current: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['updated'],
  data() {
    return {
      settings: {
        asWorkdays: workdays,
        oWorkingHours: {
          start: 8,
          end: 17
        },
        ...this.current
      }
    }
  },
  methods: {
    saveWorkdays() {
      this.$emit('updated', {
        asWorkdays: this.settings.asWorkdays
      })
    },
    saveWorkingHours() {
      this.$emit('updated', {
        oWorkingHours: this.settings.oWorkingHours
      })
    }
  }
}
</script>
