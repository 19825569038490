export default {
  data() {
    return {
      inModal: false,
      isMain: this.main
    }
  },
  created() {
    const closestModal = (parent) => {
      if (parent && /modal/i.test(parent.$options.name)) {
        return true
      } else if (parent) {
        return closestModal(parent.$parent)
      }
      return false
    }
    const inModal = !!closestModal(this.$parent)
    this.isMain = (this.main === null && !inModal) || this.main
    this.inModal = inModal
  },
  props: {
    main: {
      default: null
    },
    filters: {
      required: false,
      default: () => ({})
    }
  }
}
