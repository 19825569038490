<template>
  <section class="w-full">
    <div
      v-if="items"
      class="progress-payments flex justify-between border-t border-cool-gray-200 w-full pt-4 pb-1 mt-8"
    >
      <div class="progress-payments--left">
        <div class="flex items-center">
          <small>Total {{ !isBundled ? `for ${items.length} items` : '' }}:</small>
          <h2 class="mb-0 ml-2">{{ $f.currency(invoiceGross) }}</h2>
        </div>
        <a
          @click="viewBreakDown"
          class="mt-2 underline text-sm"
          :class="
            items.length > 0
              ? 'text-blue-print cursor-pointer hover:text-blue-print-800 transition-all'
              : 'text-cool-gray-400'
          "
        >
          View Breakdown
        </a>
      </div>
      <div class="progress-payments--right flex items-center">
        <div v-if="hasSelectedPaymentMethod" class="flex flex-col items-end mr-4">
          <ProgressPaymentMethod />
          <a @click="changeMethod" class="text-blue-print underline text-sm cursor-pointer">
            change method
          </a>
        </div>
        <div v-if="!hasSelectedPaymentMethod" class="flex flex-col items-end mr-4">
          <span>No payment method added</span>
          <a
            @click="emit('openPaymentMethod')"
            class="underline text-sm cursor-pointer hover:text-blue-print-800 transition-all"
          >
            add payment method
          </a>
        </div>
        <Btn
          severity="primary-black"
          size="lg"
          :disabled="items.length === 0 || !hasSelectedPaymentMethod"
          @click="confirmAndPay"
          >Approve & Pay</Btn
        >
      </div>
    </div>
    <Modal :pt="{ content: '!bg-white', header: '!bg-white' }" size="md" ref="reviewAllModal">
      <template #header>
        <h5>Payment breakdown</h5>
      </template>
      <template #body>
        <ProgressPaymentReview :hideBreakdown="isBundled" />
      </template>
    </Modal>
    <Modal :pt="{ content: '!bg-white', header: '!bg-white' }" size="md" ref="paymentMethodModal">
      <template #header>
        <h5>Payment methods</h5>
      </template>
      <template #body>
        <ProgressPaymentMethods />
        <div class="progress-payment--actions text-right mt-5">
          <Btn
            @click="() => paymentMethodModal.close()"
            :disabled="!isValid"
            severity="primary-black"
            size="lg"
          >
            Confirm payment method
          </Btn>
        </div>
      </template>
    </Modal>
    <Modal :pt="{ content: '!bg-white', header: '!bg-white' }" size="md" ref="confirmPayment">
      <template #header>
        <h5>Confirm payment</h5>
      </template>
      <template #body>
        <ProgressPaymentCheckout :hideBreakdown="isBundled" />
      </template>
    </Modal>
    <div id="3d-verification-newTab"></div>
  </section>
</template>

<script setup>
import {
  inject,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineExpose,
  defineEmits
} from 'vue'
import useItemizedPayments from '../composables/ItemizedPayments'
import useInvoice from '../composables/Invoice'
import usePayFac from '../composables/PayFac'
import usePayment from '../composables/Payment'
import useAdyen from '../composables/Adyen'
import usePaymentMethod from '../composables/PaymentMethod'
import ProgressPaymentReview from './ProgressPaymentReview.vue'
import ProgressPaymentMethods from './ProgressPaymentMethods.vue'
import ProgressPaymentMethod from './ProgressPaymentMethod.vue'
import ProgressPaymentCheckout from './ProgressPaymentCheckout.vue'

defineProps({
  isBundled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['openPaymentMethod'])

// provide/inject
const project = inject('project')

// composables
const { itemizedTotal, items, discount, unInvoiced, resetItemizedPayments } = useItemizedPayments()

const {
  invoiceGross,
  quotePriceGross,
  unInvoicedGross,
  quoteTaxSums,
  invoiceQuoteId,
  oSettings,
  resetInvoice
} = useInvoice()

const { configureAdyenStoredCardsComponent } = useAdyen()

const { isValid } = usePayment()

const { initializePayments, initializePaymentMethods } = usePayFac()

const { hasSelectedPaymentMethod } = usePaymentMethod()

// reactive data
const reviewAllModal = ref(null)
const paymentMethodModal = ref(null)
const confirmPayment = ref(null)

// watch
watch(itemizedTotal, (newGross) => {
  invoiceGross.value = newGross
})

// methods
const viewBreakDown = () => {
  if (items.value.length === 0) return
  reviewAllModal.value.open()
}

const changeMethod = async () => {
  await paymentMethodModal.value.open()
  initializePaymentMethods()
}

const confirmAndPay = async () => {
  if (!items.value || items.value.length === 0) throw new Error()
  await confirmPayment.value.open()
  configureAdyenStoredCardsComponent()
}

discount.value = project.value.quote_discount_percentage
unInvoiced.value = project.value.quote_uninvoiced_gross
quotePriceGross.value = project.value.quote_price_gross
unInvoicedGross.value = project.value.quote_uninvoiced_gross
quoteTaxSums.value = project.value.oTaxSums
invoiceQuoteId.value = project.value.quote_id
oSettings.value = {
  enableItemizedInvoicing: 1
}

onMounted(() => {
  initializePayments()
})

onBeforeUnmount(() => {
  // reset selected items
  resetItemizedPayments()
  resetInvoice()
})

defineExpose({
  changeMethod
})
</script>

<style style="scss">
.payment-method--icon {
  font-size: 2em;
  margin-top: -0.25em;
}
</style>
