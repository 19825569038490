import { inject } from 'vue'
import { PostHogSymbol } from '@/plugins/vue-posthog/symbol'

/**
 * @return {import('posthog-js').PostHog}
 */
export function usePostHog() {
  const posthogInstance = inject(PostHogSymbol)
  if (!posthogInstance) {
    throw new Error('PostHog has not been provided')
  }
  return posthogInstance
}
