import BankTransferIcon from '../../../assets/secured_payments.svg'
import CardIcon from '../../../assets/credit_card.svg'
import ManualIcon from '../../../assets/external_payment.svg'

export const paymentMethods = [
  {
    value: 'ach',
    text: 'Secure Bank Transfer',
    enabled: false,
    icon: BankTransferIcon
  },
  {
    value: 'eft',
    text: 'Secure Bank Transfer',
    enabled: false,
    icon: BankTransferIcon
  },
  {
    value: 'card',
    text: 'Credit Card',
    enabled: false,
    icon: CardIcon
  },
  {
    value: 'manual',
    text: 'Request alternative payment method',
    enabled: false,
    icon: ManualIcon,
    message: 'Upon request'
  }
]

export default {
  paymentMethods
}
