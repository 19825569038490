// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'bid',
  
  skipAudit: true,

  fields: {
    bid_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_group_id: {
      type: 'string',
      filter: false,
      trackChanges: true,
      save: true,
    },
    quote_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'quote',
    },
    vendor_quote_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'quote',
    },
    vendor_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'vendor',
    },
    oVendor: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: 'vendor',
      trackChanges: false,
      save: false,
      normalize: false,
    },
    item_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'item',
    },
    bid_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_created: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_due_diff: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: true,
      default: 0, // 1000 * 60 * 60 * 24 * 4, // 4 days
    },
    bid_time_due: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: null, // (f, bid) => new Date().valueOf() + bid.bid_time_due_diff,
    },
    bid_time_sent: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_seen: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_queued: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_booked: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_time_declined: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_booked_by: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_queued_by: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    bid_declined_by: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    file_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'file',
    },
    bid_file_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'file',
    },
    bid_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    quote_notes: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    change_order_message: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    oChangeOrder: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: 'change_order',
    },
    oCompany: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    bid_total_suggested_price_net: {
      type: 'float',
      default: null,
      save: true,
      reload: true,
    },
    bid_suggestion_discount_percentage: {
      type: 'float',
      default: 0.05,
      save: true,
      reload: true,
      defaultSetting: true,
    },
    bid_is_suggest_price: {
      type: 'int',
      default: 1,
      save: true,
      defaultSetting: true,
    },
    bid_is_for_ongoing_pricing: {
      type: 'int',
      default: 1,
      save: true,
      defaultSetting: true,
    },
    bid_is_dictated: {
      type: 'int',
      default: 0,
      save: true,
      defaultSetting: true,
    },
    bid_declined_reason: {
      type: 'string',
      default: null,
    },
  },


  generateVueActions() {
    return {
      async getQuote({ dispatch }, payload) {
        const { id: bidId } = await dispatch('resolveObject', payload);
        const returned = await dispatch('ajax', {
          path: `bid/getQuote/${bidId}`,
        });

        return returned.payload;
      },
    };
  },
};
