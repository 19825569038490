<template>
  <btn-group class="steps--container">
    <btn
      v-for="(step, index) in steps"
      :key="step"
      @click="() => $emit('input', [step])"
      :class="value.includes(step) ? 'active info sm' : 'sm'"
    >
      <template #icon>
        <span class="fa steps--number-icon">{{ index + 1 }}</span>
      </template>
      {{ step }}
    </btn>
  </btn-group>
</template>

<script>
export default {
  name: 'Steps',

  props: {
    value: {
      required: true
    },
    steps: {
      type: Array,
      default: () => ['Estimate', 'Dimensions', 'Preview', 'Send']
    }
  },
  emits: ['input']
}
</script>

<style lang="scss" rel="stylesheet/scss">
.steps--container {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  .steps--number-icon.fa {
  }

  @media (max-width: 576px) {
    max-width: 100%;
    overflow-y: auto;
  }
}
</style>
