import { usePreferences } from '@/stores/preferences.js'
import { storeToRefs } from 'pinia'

export default function useUserMeta() {
  const prefs = usePreferences()
  const { userPrefs, hasFetched, waiter } = storeToRefs(prefs)

  const metaConstants = prefs.prefConstants
  const userMeta = userPrefs
  const setMeta = prefs.setPrefs
  const setUserMeta = prefs.setPrefs
  const setMetaItem = prefs.setPref
  const getMetaItem = prefs.getPref

  return {
    store: prefs,
    ...prefs,
    userMeta,
    metaConstants,
    setMeta,
    setMetaItem,
    setUserMeta,
    getMetaItem,
    hasFetched,
    waiter
  }
}
