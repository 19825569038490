// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'
import CostItem from './CostItem'

export default {
  type: 'invoice',

  possibleStatuses: ['o', 'e', 'h', 'p', '$', 'x'],
  /**
   * Items that can be intermixed with this object type,
   * that could be cached.  Each of these object types
   * will be cleared from cache, when clearning assemblies from cache.
   * @type {[string,string,string]}
   */
  cachedTypes: ['client', 'quote'],

  listPresets: [
    {
      title: 'Outstanding',
      description: 'Invoices that have not been paid by the client, or marked paid.',
      filters: {
        invoice_status: 'o||$'
      },
      filterText: {
        invoice_status: 'Outstanding'
      }
    },
    {
      title: 'Processing',
      description: 'Invoices where the payment is processing.',
      filters: {
        invoice_status: 'p||pr'
      }
    },
    {
      title: 'Paid',
      description: 'Invoices that have been paid.',
      filters: {
        invoice_status: 'e'
      }
    },
    {
      title: 'Past-due',
      description: 'Invoices that are unpaid and past-due.',
      filters: {
        invoice_status: 'o||$',
        invoice_is_pastdue: 1
      },
      filterText: {
        invoice_status: 'Outstanding',
        invoice_is_pastdue: 'True'
      }
    }
  ],

  fields: {
    files_count: {
      type: 'int',
      save: false,
      format: false
    },
    invoice_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewInvoice'
    },
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote',
      validate: {
        required: true
      }
    },
    quote_name: {
      title: 'Project',
      type: 'int',
      filter: false,
      format: false
    },
    client_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'client'
    },
    aoPhoneNumbers: {
      type: 'array',
      filter: false,
      format: false,
      normalize: false
    },
    aoEmails: {
      type: 'array',
      filter: false,
      format: false,
      normalize: false
    },
    aoEmailAddresses: {
      type: 'array',
      filter: false,
      format: false,
      normalize: false
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      reload: true,
      normalize: false
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      reload: true,
      normalize: false
    },
    oQuote: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'quote',
      normalize: false
    },
    oClient: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'client',
      normalize: false
    },
    invoice_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    invoice_time: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    oProjectTaxSettings: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: CostItem.taxSettingsDefaulter
    },
    oQuoteTaxSums: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: () => ({})
    },
    oTaxSums: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: () => ({})
    },
    invoice_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    invoice_time_sent: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    invoice_time_due: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
      validate: {
        required: true
      }
    },
    invoice_time_paid: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    invoice_receipt_time_sent: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    invoice_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    template_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'template'
    },
    receipt_template_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'template'
    },
    invoice_highlight: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    tax_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'tax'
    },
    invoice_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      validate: {
        required: true
      }
    },
    invoice_tax: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    invoice_gross: {
      type: 'float',
      save: true,
      filter: true,
      format: 'currency',
      mapTo: false
    },
    invoice_service_fee_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapsTo: false,
      default: 0.0
    },
    invoice_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    invoice_private_notes: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    export_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    aoChildren: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: (child) => child.type,
      normalize: false
    },
    invoice_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    invoice_percentage: {
      type: 'float',
      filter: true,
      format: 'percentage',
      mapTo: false
    },
    invoice_project_percentage: {
      type: 'float',
      filter: true,
      format: 'percentage',
      mapTo: false
    },
    aoItems: {
      type: 'Array',
      filter: false,
      mapTo: false,
      trackChanges: false
    },
    tax_percentage: {
      type: 'float',
      filter: true,
      format: 'percent',
      mapTo: false
    },
    tax_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    payment_method_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'payment_method'
    },
    location_ids: {
      type: 'array',
      mapTo: 'location',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    },
    oSettings: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: () => ({})
    }
  },

  actions: {
    send: {
      text: 'Not sent! Send..',
      icon: 'paper-plane',
      action: 'Invoice/send',
      class: 'danger',
      visible: (invoices) =>
        invoices.every((invoice) => invoice.invoice_status === 'o' && !invoice.invoice_time_sent),
      selectionRequired: true,
      multiple: true
    },
    sendWarn: {
      text: 'Send now..',
      icon: 'paper-plane',
      action: 'Invoice/send',
      class: 'warning',
      visible: (invoices) =>
        !invoices.every(
          (invoice) => invoice.invoice_status === 'o' && !invoice.invoice_time_sent
        ) && invoices.some((invoice) => !invoice.invoice_time_sent),
      selectionRequired: true,
      multiple: true
    },
    sendAgain: {
      text: 'Send reminder..',
      icon: 'paper-plane',
      action: 'Invoice/resend',
      class: 'warning',
      visible: (invoices) =>
        invoices.every((invoice) => invoice.invoice_status === 'o' && invoice.invoice_time_sent),
      selectionRequired: true,
      multiple: true
    },
    sendReceipt: {
      text: 'Send receipt..',
      icon: 'paper-plane',
      action: 'Invoice/sendReceipt',
      class: 'success',
      visible: (invoices) =>
        invoices.every((invoice) => invoice.invoice_status === 'e') &&
        invoices.some((invoice) => !invoice.invoice_receipt_time_sent),
      selectionRequired: true,
      multiple: true
    },
    sendReceiptAgain: {
      text: 'Send receipt again..',
      icon: 'paper-plane',
      action: 'Invoice/sendReceipt',
      visible: (invoices) =>
        invoices.every(
          (invoice) => invoice.invoice_status === 'e' && invoice.invoice_receipt_time_sent
        ),
      selectionRequired: true,
      multiple: true
    },
    markPaid: {
      text: 'Mark as paid..',
      icon: 'check',
      action(store, payload) {
        return store.dispatch('Invoice/markMultiple', { ...payload, markAs: 'paid', go: false })
      },
      class: 'success',
      visible: (invoices) => invoices.every((invoice) => invoice.invoice_status === 'o'),
      selectionRequired: true,
      multiple: true
    }
  },

  generateVueActions() {
    return {
      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id client
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setQuote({ dispatch }, payload) {
        const { quote: id } = payload

        const { object: quote } = await dispatch(
          'Quote/resolveObject',
          { id, quick: true },
          { root: true }
        )

        let explicitChanges = {
          quote_id: null,
          quote_name: '',
          oQuote: null
        }

        let changes = {
          ...explicitChanges
        }

        if (quote && quote.quote_id) {
          const taxPercentage =
            _.divide(quote.quote_price_tax, quote.quote_price_net) || quote.tax_percentage
          explicitChanges = {
            quote_id: quote.quote_id,
            quote_name: quote.quote_name,
            tax_percentage: taxPercentage,
            tax_name: quote.tax_name,
            invoice_gross: quote.quote_uninvoiced_gross
          }

          changes = {
            ...explicitChanges,
            oQuote: quote
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          skipAudit: false,
          auditFull: true
        })
      },

      async sendReceipt({ dispatch }, payload) {
        const { set } = await dispatch('resolveObject', payload)

        if (!set.every((receipt) => receipt.invoice_status === 'e')) {
          dispatch(
            'alert',
            {
              message: 'Could not send receipts because some invoices are not marked paid yet.',
              error: true
            },
            { root: true }
          )

          return payload
        }

        return dispatch('Receipt/send', { selected: set }, { root: true })
      },
      async send({ dispatch }, payload) {
        const { grid = null, sms = true, email = true } = payload

        const { set: objects } = await dispatch('resolveObject', payload)

        //try {
        await dispatch('ajax', {
          path: `/invoice/sendMultiple`,
          data: {
            objects: objects.map((q) => ({ type: 'invoice', invoice_id: q.invoice_id })),
            sms,
            email
          }
        })

        dispatch(
          'alert',
          {
            message: 'Successfully sent! '
          },
          { root: true }
        )

        if (grid) grid.reload(true)
        // } catch (e) {
        //   if (alert) {
        //     dispatch('alert', {
        //       message: e.userMessage || 'Could not send... try again.',
        //       error: true,
        //     }, { root: true });
        //   }
        //
        //   return Promise.reject(e);
        // }

        return payload
      },
      resend({ dispatch }, payload) {
        return dispatch('send', payload)
      }
    }
  },

  getComputedDependants() {
    const n = _.notNaN
    return {
      invoice_gross(state) {
        return _.toNum(state.invoice_gross, 2)
      },
      invoice_percentage(state) {
        return (
          (_.toNum(n(state.invoice_gross), 2) /
            _.toNum(n(state.oQuote ? state.oQuote.quote_uninvoiced_gross : 0), 2)) *
          100
        )
      },
      invoice_project_percentage(state) {
        // once the invoice is created this can't be changed
        if (state.invoice_id) return state.invoice_project_percentage
        return _.divide(n(state.invoice_gross), n(state.oQuote && state.oQuote.quote_price_gross))
      },
      oTaxSums(state) {
        // once the invoice is created this can't be changed
        const sumsExist = state.oTaxSums ? Object.keys(state.oTaxSums).length : {}
        if (state.invoice_id && sumsExist) {
          return state.oTaxSums
        }

        // legacy invoice
        if (state.invoice_id && !sumsExist) {
          let taxes = _.toNum(state.invoice_tax)
          let taxPercent = _.divide(taxes, state.invoice_net)

          return {
            [state.tax_name]: {
              name: state.tax_name,
              key: state.tax_name,
              profit: taxes * 0.5,
              cost: taxes * 0.5,
              sum: taxes,
              on: 'all',
              pcnt: taxPercent
            }
          }
        }

        if (!state.quote_id || !state.oQuote) {
          return {}
        }

        // Legacy quote
        const quoteSums = state.oQuote.oTaxSums || {}
        const quoteSumsExist = Object.keys(quoteSums).length
        if (!quoteSumsExist) {
          let percentage = _.toNum(state.invoice_project_percentage)
          let quoteTaxes = _.toNum(state.oQuote.quote_price_tax)
          let taxPercent = _.divide(quoteTaxes, state.oQuote.quote_price_net)

          const totalTaxes = quoteTaxes * percentage

          return {
            [state.tax_name]: {
              name: state.tax_name,
              key: state.tax_name,
              profit: totalTaxes * 0.5,
              cost: totalTaxes * 0.5,
              sum: totalTaxes,
              on: 'all',
              pcnt: taxPercent
            }
          }
        }

        let projectPercentage = _.toNum(state.invoice_project_percentage)

        // get the percentage for each type and provide the invoice amount
        // according to the perentage
        return Object.keys(quoteSums).reduce((acc, key) => {
          const sum = projectPercentage * quoteSums[key].sum
          const profit = projectPercentage * quoteSums[key].profit
          const cost = projectPercentage * quoteSums[key].cost
          return {
            ...acc,
            [key]: {
              ...quoteSums[key],
              sum,
              profit,
              cost
            }
          }
        }, {})
      },
      invoice_tax(state) {
        const sums = state.oTaxSums
        const total = Object.keys(sums).reduce((acc, key) => acc + sums[key].sum, 0)
        return total
      },
      invoice_net(state) {
        return state.invoice_gross - state.invoice_tax
      }
    }
  },

  getFieldDependencies() {
    const { invoice_net, invoice_tax, invoice_project_percentage, oTaxSums } =
      this.getComputedDependants()

    return {
      quote_id: {
        invoice_project_percentage,
        oTaxSums
      },
      oQuote: {
        invoice_project_percentage,
        oTaxSums
      },
      invoice_gross: {
        invoice_project_percentage,
        invoice_tax,
        invoice_net,
        oTaxSums
      },
      invoice_percentage: {
        oTaxSums
      },
      oQuoteTaxSums: {
        oTaxSums
      },
      oTaxSums: {
        invoice_tax
      },
      invoice_tax: {
        invoice_net
      }
    }
  }
}
