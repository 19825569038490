<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="sm"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :mask="true"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
  >
    <template #body>
      <EntitySelect
        :object="{ ...object, ...(modal.embue ?? {}) }"
        :id="id"
        type="cost_type"
        @selected="selectedHandler"
      >
        <template #default="{ refId }">
          <cost-type
            :key="refId"
            ref="body"
            :oMod="modal.oMod || $store.getters.defaultMod"
            :reference="refId"
          />
        </template>
      </EntitySelect>
    </template>

    <template #footer>
      <div class="flex justify-end items-center">
        <Btn link size="xl" :action="close">Cancel</Btn>
        <Btn
          severity="bolster"
          hotkey="enter"
          size="xl"
          :action="() => saveAndClose3({ refId, store })"
          >Save</Btn
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import CostType from '../bodies/CostType.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'
import EntitySelect from '@/components/ui/EntitySelect.vue'

export default {
  name: 'CostTypeModal',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { EntitySelect, CostType },
  props: {
    go: {
      default: false
    },
    type: {
      default: 'cost_type'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
