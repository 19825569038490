<template>
  <div class="flex flex-row">
    <div class="flex flex-col w-full">
      <span>
        <span class="mx-1">
          <template v-if="country === 'us'"> Employer Identification Number (EIN) </template>
          <template v-else-if="country === 'ca'"> Business Number </template>
          <template v-else> Company Number </template>
        </span>
      </span>
      <div class="flex flex-row">
        <field
          :validate="validationObj"
          :format="format"
          @blur="onBlur"
          :errors="errors"
          :name="name"
          :placeholder="country === 'ca' ? '123456789-RP-0002' : '12-3456789'"
          v-model="formatted"
          class="w-full !bg-flame-white border-surface-200"
        />
        <Help class="ml-2 text-xs">
          <template #title>
            <template v-if="country === 'us'"> Employer Identification Number (EIN) </template>
            <template v-if="country === 'ca'"> Business Number </template>
          </template>
          <template v-if="country === 'us'">
            <p>
              A nine-digit number (for example, 12-3456789) assigned to sole proprietors,
              corporations, partnerships, estates, trusts, and other entities for tax filing and
              reporting purposes. An EIN is for use only in connection with a taxpayer's business
              activities.
            </p>
          </template>
          <template v-if="country === 'ca'">
            <p>
              A unique, 9-digit number and the standard identifier for businesses which is unique to
              a business or legal entity.
            </p>
            <img src="../../../../assets/business-number-reference.jpg" />
            <h6>Business Number</h6>
            <p>the 9-digit business number to identify the business</p>
            <h6>Program Identifier</h6>
            <p>a 2-letter program identifier code to identify the program account</p>
            <h6>Reference Number</h6>
            <p>
              A 4-digit reference number to identify an individual program account (since businesses
              can have more than one of the same kind)
            </p>
          </template>
        </Help>
      </div>
      <p v-if="country === 'us'" class="text-xs mt-0.5 mx-1 text-surface-600/75">
        A nine-digit number (Ex. 12-3456789) assigned to sole proprietors, corporations, etc. for
        tax purposes.
      </p>
      <p v-if="country === 'ca'" class="text-xs mt-0.5 mx-1 text-surface-600/75">
        A unique, 9-digit number and the standard identifier for businesses. Unique to a business or
        legal entity.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialSecurityField',
  props: {
    value: {
      type: String,
      required: true
    },
    country: {
      type: String,
      default: 'ca'
    },
    errors: {
      type: Array,
      default: () => []
    },
    name: {
      type: String
    }
  },
  emits: ['input', 'change'],
  computed: {
    formatted: {
      get() {
        return this.localValue
      },
      set(newValue) {
        this.localValue = this.format(newValue)

        const match = this.validate(this.localValue)

        if (match && match.length > 0) {
          this.error = null
          this.$emit('input', this.localValue)
          this.$emit('change', this.localValue)
        }
      }
    },
    /**
     * Based on country validate using regex
     */
    validationFormat() {
      return this.country === 'ca' ? /^(\d{9}[R][TPCM][0][0][0][12345])$/ : /^\d{2}\d{7}$/
    },
    /**
     * Based on country validate using regex
     */
    validationObj() {
      return {
        format: this.country === 'ca' ? /^(\d{9}-[R][TPCM]-[0][0][0][12345])$/ : /^\d{2}-\d{7}$/,
        required: true
      }
    }
  },
  data() {
    return {
      localValue: null,
      error: null
    }
  },
  methods: {
    /**
     * Format the value
     * @param {String} value
     */
    format(value) {
      let val = value.replace(/-/g, '')

      if (this.country === 'ca') {
        val = val.replace(/^(\d{9})/, '$1-')
        val = val.replace(/-([A-Za-z]{2})/, '-$1-')
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')
        return val
      }

      val = val.replace(/^(\d{2})/, '$1-')
      val = val.replace(/-(\d{7})/, '-$1')
      return val
    },
    /**
     * Make sure value matches the right format and is valid
     */
    onBlur() {
      if (!this.localValue || this.localValue === '') {
        return
      }
      const match = this.validate(this.localValue)
      if (match && match.length > 0) {
        return
      }
      // display custom error messages
      let customMessage = '12-1234567'
      if (this.country === 'ca')
        customMessage = '9 digits followed by two letters (RT, RP, RC, RM) and 4 digits'
      this.error = `The format of your response is not what we expected to see. The format should be ${customMessage}`

      this.$store.dispatch('alert', {
        message: this.error,
        error: true
      })
    },
    /**
     * Make sure value matches the right format
     * @param {String} value
     */
    validate(value) {
      const val = value.replace(/-/g, '')
      return this.validationFormat.exec(val)
    }
  },
  watch: {
    value() {
      if (this.localValue !== this.value) {
        this.localValue = this.value
      }
    }
  },
  created() {
    this.localValue = this.value
  },
  components: {}
}
</script>
