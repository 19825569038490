<script>
export default {
  name: 'UsernameField',
  props: {
    context: {
      default: 'login',
      type: String,
      validator(value) {
        return ['login', 'reset', 'forgot'].includes(value)
      }
    },
    value: {}
  },
  emits: ['input'],
  computed: {
    hideField() {
      return ['reset', 'forgot'].includes(this.context)
    }
  }
}
</script>

<template>
  <input
    :class="{ 'username-hidden': hideField }"
    :aria-hidden="hideField"
    type="email"
    name="username"
    id="username"
    autocomplete="username"
    :value="value"
    @input="() => $emit('input', $event.target.value)"
  />
</template>

<style scoped lang="scss">
.username-hidden {
  display: none;
}
</style>
