const SchedulePossibleColumns = [
  {
    id: 'vendor',
    type: 'choose',
    title: 'Vendor/sub',
    info: 'The vendor/subtrade/subcontractor assigned to complete the work.',
    schema: 'cost_item:vendor_id',
    objectType: 'vendor',
    valueField: 'vendorId',
    fieldName: 'vendor_id',
    textField: 'vendor_name',
    align: 'center',
    width: '18em',
    summable: false,
    embue: (item) => ({ trade_type_ids: String(item.trade_type_id) }),
    sumValue: 'vendor',
    quoteValue: 'vendor',
    functionalValue: (obj) => obj.vendor_name
  },
  {
    id: 'stage',
    type: 'choose',
    title: 'Construction stage',
    info: 'The stage of this item in the order of operations.',
    schema: 'cost_item:stage_id',
    valueField: 'stageId',
    objectType: 'stage',
    fieldName: 'stage_id',
    textField: 'stage_name',
    align: 'center',
    width: '24em',
    format: 'none',
    summable: false,
    sumValue: 'stage',
    quoteValue: 'stage',
    order: [['stage_order', 'asc']],
    functionalValue: (obj) => obj.stage_name
    // sumValue: async (items = []) => {
    //   const type = 'stage';
    //   const field = `${type}_id`;
    //   if (items.length && items[0] && items[0][field]) {
    //     const every = items.every(item => String(item[field]) === String(items[0][field]));
    //     if (every) return this.getName(type, items[0][field]);
    //   }
    //   return '-';
    // },
  },
  {
    id: 'tradetype',
    type: 'choose',
    title: 'Stage',
    info: 'The kind of vendor/subtrade that provides the materials or labor for the item.',
    schema: 'cost_item:trade_type_id',
    valueField: 'tradTypeId',
    objectType: 'trade_type',
    fieldName: 'trade_type_id',
    textField: 'trade_type_name',
    align: 'center',
    width: '24em',
    format: 'none',
    summable: false,
    sumValue: 'tradeType',
    quoteValue: 'cost_item:trade_type_id',
    functionalValue: (obj) => obj.trade_type_name
    // sumValue: async (items = []) => {
    //   const type = 'trade_type';
    //   const field = `${type}_id`;
    //   if (items.length && items[0] && items[0][field]) {
    //     const every = items.every(item => String(item[field]) === String(items[0][field]));
    //     if (every) return this.getName(type, items[0][field]);
    //   }
    //   return '-';
    // },
  },
  {
    id: 'scheduledDate',
    type: 'date',
    title: 'Date scheduled',
    quoteFunction: 'tradType',
    schema: 'cost_item:cost_item_schedule_end_date',
    info: 'The date this item is scheduled to be completed',
    objectType: 'cost_item',
    align: 'center',
    valueField: 'costItemSchedule',
    fieldName: 'cost_item_schedule_end_date',
    textField: 'cost_item_schedule_end_date',
    width: '24em',
    format: false,
    quoteValue: 'none',
    summable: false,
    sumValue: 'none',
    functionalValue: (obj, whichone) => (obj[whichone] ? parseInt(obj[whichone], 10) : null)
  }
]
export default SchedulePossibleColumns
