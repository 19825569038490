export default {
  root: ({ props }) => ({
    class: [
      // Flexbox and Position
      'inline-flex',
      'relative',

      // Shape
      { 'shadow-lg': props.raised }
    ]
  }),
  button: {
    root: ({ props, context, parent }) => ({
      class: [
        'relative',

        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',

        // Sizes & Spacing
        'leading-none',

        ...(props.unstyled
          ? []
          : [
              // Sizes & Spacing
              !props.rounded
                ? {
                    'px-1.5 py-0.5 text-xs': props.size === 'xs' || props.size === 'xsmall',
                    'px-3.5 py-1.5 text-xs': props.size === 'sm' || props.size === 'small',
                    'py-1.5 px-4 text-sm':
                      props.size === 'md' || props.size === 'medium' || props.size === 'none',
                    'py-2 px-4 text-base h-10': props.size === 'lg' || props.size === 'large',
                    'py-2 px-4 text-xl': props.size === 'xl' || props.size === 'xlarge'
                  }
                : {},
              // rounded sizing
              props.rounded
                ? {
                    'p-0.5 text-xs size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                      props.size === 'xs' || props.size === 'xsmall',
                    'p-1 text-xs size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                      props.size === 'sm' || props.size === 'small',
                    'p-1.5 text-sm size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                      props.size === 'md' || props.size === 'medium' || props.size === 'none',
                    'p-2 text-base w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                      props.size === 'lg' || props.size === 'large',
                    'p-4 text-xl w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                      props.size === 'xl' || props.size === 'xlarge'
                  }
                : {},

              // Link Button
              {
                'text-pitch-black bg-transparent border-transparent hover:bg-surface-200':
                  props.link,
                'text-surface-700 bg-transparent border-transparent hover:bg-transparent':
                  props.link & context.disabled
              },

              {
                'opacity-75': context.disabled
              },

              // {
              //   'text-xs p-0.5 px-1.5 h-fit': props.size === 'xs' || props.size === 'xsmall',
              //   'text-xs py-1.5 px-3.5 h-fit': props.size === 'sm' || props.size === 'small',
              //   'px-4 py-1.5 h-fit': (props.size === 'md' || props.size === 'medium' || props.size === null),
              //   'text-xl py-3 px-4 h-fit': props.size === 'lg' || props.size === 'large',
              // },
              // Color
              {
                'text-surface-100 bg-surface-900 hover:bg-cool-gray-900 active:bg-pitch-black':
                  props.severity === 'primary' && !props.link && !context.disabled,
                'text-surface-100 bg-blue-print-500 hover:bg-blue-print-500/80 active:bg-pitch-black':
                  props.severity === 'blue' && !context.disabled,
                'text-level-yellow bg-pitch-black hover:bg-cool-gray-900 active:bg-pitch-black':
                  (props.severity === 'primary-black' || props.severity === 'bolster') &&
                  !context.disabled,
                'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-pitch-black active:bg-cool-gray-900':
                  props.severity === 'primary-yellow' && !context.disabled,
                'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-cool-gray-800 active:bg-cool-gray-900':
                  props.severity === 'primary-yellow-login' && !context.disabled,
                'text-pitch-black bg-cool-gray-300 hover:text-pitch-black hover:bg-cool-gray-400 active:bg-cool-gray-500':
                  props.severity === 'secondary' && !context.disabled,
                'text-pitch-black bg-transparent border border-pitch-black hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                  props.severity === 'tertiary' && !context.disabled,
                'text-pitch-black bg-transparent hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                  props.severity === 'tertiary-borderless' && !context.disabled
              },
              {
                'w-12 p-0 py-3': props.label == null && props.icon !== null
              },

              // Shapes
              { 'shadow-lg': props.raised },
              {
                'rounded-sm':
                  !(
                    parent.instance?.$name === 'InputGroup' ||
                    parent.instance?.$name === 'ButtonGroup'
                  ) && !props.rounded,
                'rounded-full':
                  !(
                    parent.instance?.$name === 'InputGroup' ||
                    parent.instance?.$name === 'ButtonGroup'
                  ) && props.rounded
              },
              {
                'rounded-none first:rounded-l-sm last:rounded-r-sm':
                  parent.instance?.$name === 'InputGroup' ||
                  parent.instance?.$name === 'ButtonGroup'
              },

              {
                'text-xs p-0.5 h-fit size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                  props.size === 'xs' && props.rounded,
                'text-xs p-1.5 h-fit size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                  props.size === 'small' && props.rounded,
                'p-2 h-fit size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                  (props.size === 'md' || props.size === null || !props.size) &&
                  !props.unstyled &&
                  props.rounded,
                'text-xl p-3 h-fit w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                  props.size === 'large' && props.rounded
              },

              // Link Button
              { 'text-primary-600 bg-transparent border-transparent': props.link },

              // Plain Button
              {
                'text-white bg-gray-500 border border-gray-500':
                  props.plain && !props.outlined && !props.text
              },
              // Plain Text Button
              {
                'text-surface-700 p-1 hover:bg-surface-400 hover:text-surface-900 focus:bg-surface-400 focus:text-surface-900 ':
                  props.plain && props.text
              },
              // Plain Outlined Button
              { 'text-surface-700 border border-gray-700': props.plain && props.outlined },

              // Text Button
              { 'bg-transparent border-transparent': props.text && !props.plain },

              // Outlined Button
              { 'bg-transparent border': props.outlined && !props.plain },

              // --- Severity Buttons ---

              // Primary Button
              {
                'dark:text-white text-level-yellow':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled,
                'bg-pitch-black dark:bg-primary-400':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled,
                'border border-pitch-black dark:border-primary-400':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled
              },
              // Primary Text Button
              {
                'text-level-yellow dark:text-primary-400':
                  props.text && props.severity === 'bolster' && !props.plain
              },
              // Primary Outlined Button
              {
                'text-pitch-black border border-pitch-black hover:bg-pitch-black hover:text-level-yellow':
                  props.outlined && props.severity === 'bolster' && !props.plain && !props.unstyled
              },

              // Primary Button
              // {
              //     'dark:text-white text-primary-50': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'bg-primary-900 dark:bg-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'border border-primary-900 dark:border-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'hover:bg-primary-700': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled
              // },
              // Primary Text Button
              {
                'text-primary-500 dark:text-primary-400':
                  props.text && props.severity === null && !props.plain
              },
              // Primary Outlined Button
              {
                'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
                  props.outlined && props.severity === null && !props.plain && !props.unstyled
              },

              // Secondary Button
              // {
              //     'text-surface-100 dark:text-surface-900 hover:bg-secondary/90 hover:border-secondary/90 ': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
              //     'bg-secondary dark:bg-surface-400': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
              //     'border border-secondary dark:bg-secondary': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain
              // },
              // Secondary Text Button
              // { 'text-surface-500 dark:text-surface-300': props.text && props.severity === 'secondary' && !props.plain },
              // Secondary Outlined Button
              // { 'text-secondary dark:text-secondary border border-secondary hover:bg-secondary/20': props.outlined && props.severity === 'secondary' && !props.plain },

              // Success Button
              {
                'text-white dark:text-green-900':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'bg-green-500 dark:bg-green-400':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'border border-green-500 dark:border-green-400':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain
              },
              // Success Text Button
              {
                'text-green-500 dark:text-green-400':
                  props.text && props.severity === 'success' && !props.plain
              },
              // Success Outlined Button
              {
                'text-green-500 border border-green-500 hover:bg-green-300/20':
                  props.outlined && props.severity === 'success' && !props.plain
              },

              // Info Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'bg-blue-500 dark:bg-blue-400':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'border border-blue-500 dark:border-blue-400':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain
              },
              // Info Text Button
              {
                'text-blue-500 dark:text-blue-400':
                  props.text && props.severity === 'info' && !props.plain
              },
              // Info Outlined Button
              {
                'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
                  props.outlined && props.severity === 'info' && !props.plain
              },

              // Warning Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'bg-orange-500 dark:bg-orange-400':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'border border-orange-500 dark:border-orange-400':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain
              },
              // Warning Text Button
              {
                'text-orange-500 dark:text-orange-400':
                  props.text && props.severity === 'warning' && !props.plain
              },
              // Warning Outlined Button
              {
                'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
                  props.outlined && props.severity === 'warning' && !props.plain
              },

              // Help Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'bg-purple-500 dark:bg-purple-400':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'border border-purple-500 dark:border-purple-400':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain
              },
              // Help Text Button
              {
                'text-purple-500 dark:text-purple-400':
                  props.text && props.severity === 'help' && !props.plain
              },
              // Help Outlined Button
              {
                'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
                  props.outlined && props.severity === 'help' && !props.plain
              },

              // Danger Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'bg-red-500 dark:bg-red-400':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'border border-red-500 dark:border-red-400':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain
              },
              // Danger Text Button
              {
                'text-red-500 dark:text-red-400':
                  props.text && props.severity === 'danger' && !props.plain
              },
              // Danger Outlined Button
              {
                'text-red-500 border border-red-500 hover:bg-red-300/20':
                  props.outlined && props.severity === 'danger' && !props.plain
              },

              // --- Severity Button States ---
              'focus:outline-none focus:outline-offset-0',

              // Link
              // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.link },

              // Plain
              {
                'hover:bg-gray-600 hover:border-gray-600':
                  props.plain && !props.outlined && !props.text
              },
              // Text & Outlined Button
              { 'hover:bg-surface-300/20': props.plain && (props.text || props.outlined) },

              // Primary
              // { 'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300 hover:text-surface-100': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain },
              // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.severity === null },
              // Text & Outlined Button
              // { 'hover:bg-primary-300/20': (props.text || props.outlined) && props.severity === null && !props.plain },

              // Secondary
              // { 'hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain },
              // { 'focus:ring-surface-400/50 dark:focus:ring-surface-300/50': props.severity === 'secondary' },
              // Text & Outlined Button
              // { 'hover:bg-surface-300/20': (props.text || props.outlined) && props.severity === 'secondary' && !props.plain },

              // Success
              {
                'hover:bg-green-600 dark:hover:bg-green-300 hover:border-green-600 dark:hover:border-green-300':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-green-400/50 dark:focus:ring-green-300/50': props.severity === 'success' },
              // Text & Outlined Button
              {
                'hover:bg-green-300/20':
                  (props.text || props.outlined) && props.severity === 'success' && !props.plain
              },

              // Info
              {
                'hover:bg-blue-600 dark:hover:bg-blue-300 hover:border-blue-600 dark:hover:border-blue-300':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-blue-400/50 dark:focus:ring-blue-300/50': props.severity === 'info' },
              // Text & Outlined Button
              {
                'hover:bg-blue-300/20':
                  (props.text || props.outlined) && props.severity === 'info' && !props.plain
              },

              // Warning
              {
                'hover:bg-orange-600 dark:hover:bg-orange-300 hover:border-orange-600 dark:hover:border-orange-300':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-orange-400/50 dark:focus:ring-orange-300/50': props.severity === 'warning' },
              // Text & Outlined Button
              {
                'hover:bg-orange-300/20':
                  (props.text || props.outlined) && props.severity === 'warning' && !props.plain
              },

              // Help
              {
                'hover:bg-purple-600 dark:hover:bg-purple-300 hover:border-purple-600 dark:hover:border-purple-300':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-purple-400/50 dark:focus:ring-purple-300/50': props.severity === 'help' },
              // Text & Outlined Button
              {
                'hover:bg-purple-300/20':
                  (props.text || props.outlined) && props.severity === 'help' && !props.plain
              },

              // Danger
              {
                'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-red-400/50 dark:focus:ring-red-300/50': props.severity === 'danger' },
              // Text & Outlined Button
              {
                'hover:bg-red-300/20':
                  (props.text || props.outlined) && props.severity === 'danger' && !props.plain
              },

              // Disabled
              {
                'bg-cool-gray-300 border-surface-200 text-cool-gray-600 pointer-events-none cursor-default':
                  context.disabled && !props.link,
                'border border-pitch-black': context.disabled && props.severity === 'tertiary'
              },

              // Transitions
              'transition duration-200 ease-in-out',

              // Misc
              'cursor-pointer overflow-hidden select-none',

              'rounded-r-none',
              'border-r-0'
            ])
      ]
    }),
    label: ({ props }) => ({
      class: [
        'duration-200',
        'font-medium',
        {
          'hover:underline': props.link
        },
        { 'flex-1': props.label !== null, 'invisible w-0': props.label == null }
      ]
    }),
    icon: ({ props }) => ({
      class: [
        'mx-0',
        {
          [props.iconPos]: props.iconPos,
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null
        }
      ]
    }),
    loading: ({ props }) => ({
      class: [
        'w-full h-full flex items-center justify-center',
        'mx-0',
        'absolute',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null
        },
        'animate-spin'
      ]
    }),
    badge: ({ props }) => ({
      class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }]
    })
  },
  menubutton: {
    root: ({ props, context, parent }) => ({
      class: [
        'relative',

        // Alignments
        'items-center inline-flex text-center align-bottom justify-center',

        // Sizes & Spacing
        'leading-none',

        ...(props.unstyled
          ? []
          : [
              // Sizes & Spacing
              !props.rounded
                ? {
                    'px-1.5 py-0.5 text-xs': props.size === 'xs' || props.size === 'xsmall',
                    'px-3.5 py-1.5 text-xs': props.size === 'sm' || props.size === 'small',
                    'py-1.5 px-4 text-sm':
                      props.size === 'md' || props.size === 'medium' || props.size === 'none',
                    'py-2 px-4 text-base h-10': props.size === 'lg' || props.size === 'large',
                    'py-2 px-4 text-xl': props.size === 'xl' || props.size === 'xlarge'
                  }
                : {},
              // rounded sizing
              props.rounded
                ? {
                    'p-0.5 text-xs size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                      props.size === 'xs' || props.size === 'xsmall',
                    'p-1 text-xs size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                      props.size === 'sm' || props.size === 'small',
                    'p-1.5 text-sm size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                      props.size === 'md' || props.size === 'medium' || props.size === 'none',
                    'p-2 text-base w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                      props.size === 'lg' || props.size === 'large',
                    'p-4 text-xl w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                      props.size === 'xl' || props.size === 'xlarge'
                  }
                : {},

              // Link Button
              {
                'text-pitch-black bg-transparent border-transparent hover:bg-surface-200':
                  props.link,
                'text-surface-700 bg-transparent border-transparent hover:bg-transparent':
                  props.link & context.disabled
              },

              {
                'opacity-75': context.disabled
              },

              // {
              //   'text-xs p-0.5 px-1.5 h-fit': props.size === 'xs' || props.size === 'xsmall',
              //   'text-xs py-1.5 px-3.5 h-fit': props.size === 'sm' || props.size === 'small',
              //   'px-4 py-1.5 h-fit': (props.size === 'md' || props.size === 'medium' || props.size === null),
              //   'text-xl py-3 px-4 h-fit': props.size === 'lg' || props.size === 'large',
              // },
              // Color
              {
                'text-surface-100 bg-surface-900 hover:bg-cool-gray-900 active:bg-pitch-black':
                  props.severity === 'primary' && !props.link && !context.disabled,
                'text-surface-100 bg-blue-print-500 hover:bg-blue-print-500/80 active:bg-pitch-black':
                  props.severity === 'blue' && !context.disabled,
                'text-level-yellow bg-pitch-black hover:bg-cool-gray-900 active:bg-pitch-black':
                  (props.severity === 'primary-black' || props.severity === 'bolster') &&
                  !context.disabled,
                'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-pitch-black active:bg-cool-gray-900':
                  props.severity === 'primary-yellow' && !context.disabled,
                'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-cool-gray-800 active:bg-cool-gray-900':
                  props.severity === 'primary-yellow-login' && !context.disabled,
                'text-pitch-black bg-cool-gray-300 hover:text-pitch-black hover:bg-cool-gray-400 active:bg-cool-gray-500':
                  props.severity === 'secondary' && !context.disabled,
                'text-pitch-black bg-transparent border border-pitch-black hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                  props.severity === 'tertiary' && !context.disabled,
                'text-pitch-black bg-transparent hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                  props.severity === 'tertiary-borderless' && !context.disabled
              },
              {
                'w-12 p-0 py-3': props.label == null && props.icon !== null
              },

              // Shapes
              { 'shadow-lg': props.raised },
              {
                'rounded-sm':
                  !(
                    parent.instance?.$name === 'InputGroup' ||
                    parent.instance?.$name === 'ButtonGroup'
                  ) && !props.rounded,
                'rounded-full':
                  !(
                    parent.instance?.$name === 'InputGroup' ||
                    parent.instance?.$name === 'ButtonGroup'
                  ) && props.rounded
              },
              {
                'rounded-none first:rounded-l-sm last:rounded-r-sm':
                  parent.instance?.$name === 'InputGroup' ||
                  parent.instance?.$name === 'ButtonGroup'
              },

              {
                'text-xs p-0.5 h-fit size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                  props.size === 'xs' && props.rounded,
                'text-xs p-1.5 h-fit size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                  props.size === 'small' && props.rounded,
                'p-2 h-fit size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                  (props.size === 'md' || props.size === null) && !props.unstyled && props.rounded,
                'text-xl p-3 h-fit w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                  props.size === 'large' && props.rounded
              },

              // Link Button
              { 'text-primary-600 bg-transparent border-transparent': props.link },

              // Plain Button
              {
                'text-white bg-gray-500 border border-gray-500':
                  props.plain && !props.outlined && !props.text
              },
              // Plain Text Button
              {
                'text-surface-700 p-1 hover:bg-surface-400 hover:text-surface-900 focus:bg-surface-400 focus:text-surface-900 ':
                  props.plain && props.text
              },
              // Plain Outlined Button
              { 'text-surface-700 border border-gray-700': props.plain && props.outlined },

              // Text Button
              { 'bg-transparent border-transparent': props.text && !props.plain },

              // Outlined Button
              { 'bg-transparent border': props.outlined && !props.plain },

              // --- Severity Buttons ---

              // Primary Button
              {
                'dark:text-white text-level-yellow':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled,
                'bg-pitch-black dark:bg-primary-400':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled,
                'border border-pitch-black dark:border-primary-400':
                  !props.link &&
                  props.severity === 'bolster' &&
                  !props.text &&
                  !props.outlined &&
                  !props.plain &&
                  !props.unstyled
              },
              // Primary Text Button
              {
                'text-level-yellow dark:text-primary-400':
                  props.text && props.severity === 'bolster' && !props.plain
              },
              // Primary Outlined Button
              {
                'text-pitch-black border border-pitch-black hover:bg-pitch-black hover:text-level-yellow':
                  props.outlined && props.severity === 'bolster' && !props.plain && !props.unstyled
              },

              // Primary Button
              // {
              //     'dark:text-white text-primary-50': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'bg-primary-900 dark:bg-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'border border-primary-900 dark:border-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
              //     'hover:bg-primary-700': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled
              // },
              // Primary Text Button
              {
                'text-primary-500 dark:text-primary-400':
                  props.text && props.severity === null && !props.plain
              },
              // Primary Outlined Button
              {
                'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
                  props.outlined && props.severity === null && !props.plain && !props.unstyled
              },

              // Secondary Button
              // {
              //     'text-surface-100 dark:text-surface-900 hover:bg-secondary/90 hover:border-secondary/90 ': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
              //     'bg-secondary dark:bg-surface-400': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
              //     'border border-secondary dark:bg-secondary': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain
              // },
              // Secondary Text Button
              // { 'text-surface-500 dark:text-surface-300': props.text && props.severity === 'secondary' && !props.plain },
              // Secondary Outlined Button
              // { 'text-secondary dark:text-secondary border border-secondary hover:bg-secondary/20': props.outlined && props.severity === 'secondary' && !props.plain },

              // Success Button
              {
                'text-white dark:text-green-900':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'bg-green-500 dark:bg-green-400':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                'border border-green-500 dark:border-green-400':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain
              },
              // Success Text Button
              {
                'text-green-500 dark:text-green-400':
                  props.text && props.severity === 'success' && !props.plain
              },
              // Success Outlined Button
              {
                'text-green-500 border border-green-500 hover:bg-green-300/20':
                  props.outlined && props.severity === 'success' && !props.plain
              },

              // Info Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'bg-blue-500 dark:bg-blue-400':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                'border border-blue-500 dark:border-blue-400':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain
              },
              // Info Text Button
              {
                'text-blue-500 dark:text-blue-400':
                  props.text && props.severity === 'info' && !props.plain
              },
              // Info Outlined Button
              {
                'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
                  props.outlined && props.severity === 'info' && !props.plain
              },

              // Warning Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'bg-orange-500 dark:bg-orange-400':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                'border border-orange-500 dark:border-orange-400':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain
              },
              // Warning Text Button
              {
                'text-orange-500 dark:text-orange-400':
                  props.text && props.severity === 'warning' && !props.plain
              },
              // Warning Outlined Button
              {
                'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
                  props.outlined && props.severity === 'warning' && !props.plain
              },

              // Help Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'bg-purple-500 dark:bg-purple-400':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                'border border-purple-500 dark:border-purple-400':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain
              },
              // Help Text Button
              {
                'text-purple-500 dark:text-purple-400':
                  props.text && props.severity === 'help' && !props.plain
              },
              // Help Outlined Button
              {
                'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
                  props.outlined && props.severity === 'help' && !props.plain
              },

              // Danger Button
              {
                'text-white dark:text-surface-900':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'bg-red-500 dark:bg-red-400':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
                'border border-red-500 dark:border-red-400':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain
              },
              // Danger Text Button
              {
                'text-red-500 dark:text-red-400':
                  props.text && props.severity === 'danger' && !props.plain
              },
              // Danger Outlined Button
              {
                'text-red-500 border border-red-500 hover:bg-red-300/20':
                  props.outlined && props.severity === 'danger' && !props.plain
              },

              // --- Severity Button States ---
              'focus:outline-none focus:outline-offset-0',

              // Link
              // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.link },

              // Plain
              {
                'hover:bg-gray-600 hover:border-gray-600':
                  props.plain && !props.outlined && !props.text
              },
              // Text & Outlined Button
              { 'hover:bg-surface-300/20': props.plain && (props.text || props.outlined) },

              // Primary
              // { 'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300 hover:text-surface-100': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain },
              // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.severity === null },
              // Text & Outlined Button
              // { 'hover:bg-primary-300/20': (props.text || props.outlined) && props.severity === null && !props.plain },

              // Secondary
              // { 'hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain },
              // { 'focus:ring-surface-400/50 dark:focus:ring-surface-300/50': props.severity === 'secondary' },
              // Text & Outlined Button
              // { 'hover:bg-surface-300/20': (props.text || props.outlined) && props.severity === 'secondary' && !props.plain },

              // Success
              {
                'hover:bg-green-600 dark:hover:bg-green-300 hover:border-green-600 dark:hover:border-green-300':
                  props.severity === 'success' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-green-400/50 dark:focus:ring-green-300/50': props.severity === 'success' },
              // Text & Outlined Button
              {
                'hover:bg-green-300/20':
                  (props.text || props.outlined) && props.severity === 'success' && !props.plain
              },

              // Info
              {
                'hover:bg-blue-600 dark:hover:bg-blue-300 hover:border-blue-600 dark:hover:border-blue-300':
                  props.severity === 'info' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-blue-400/50 dark:focus:ring-blue-300/50': props.severity === 'info' },
              // Text & Outlined Button
              {
                'hover:bg-blue-300/20':
                  (props.text || props.outlined) && props.severity === 'info' && !props.plain
              },

              // Warning
              {
                'hover:bg-orange-600 dark:hover:bg-orange-300 hover:border-orange-600 dark:hover:border-orange-300':
                  props.severity === 'warning' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-orange-400/50 dark:focus:ring-orange-300/50': props.severity === 'warning' },
              // Text & Outlined Button
              {
                'hover:bg-orange-300/20':
                  (props.text || props.outlined) && props.severity === 'warning' && !props.plain
              },

              // Help
              {
                'hover:bg-purple-600 dark:hover:bg-purple-300 hover:border-purple-600 dark:hover:border-purple-300':
                  props.severity === 'help' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-purple-400/50 dark:focus:ring-purple-300/50': props.severity === 'help' },
              // Text & Outlined Button
              {
                'hover:bg-purple-300/20':
                  (props.text || props.outlined) && props.severity === 'help' && !props.plain
              },

              // Danger
              {
                'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300':
                  props.severity === 'danger' && !props.text && !props.outlined && !props.plain
              },
              // { 'focus:ring-red-400/50 dark:focus:ring-red-300/50': props.severity === 'danger' },
              // Text & Outlined Button
              {
                'hover:bg-red-300/20':
                  (props.text || props.outlined) && props.severity === 'danger' && !props.plain
              },

              // Disabled
              {
                'bg-cool-gray-300 border-surface-200 text-cool-gray-600 pointer-events-none cursor-default':
                  context.disabled && !props.link,
                'border border-pitch-black': context.disabled && props.severity === 'tertiary'
              },

              // Transitions
              'transition duration-200 ease-in-out',

              // Misc
              'cursor-pointer overflow-hidden select-none',

              'rounded-l-none',
              'border-l-0'
            ])
      ]
    }),
    label: ({ props }) => ({
      class: [
        'duration-200',
        'font-medium',
        {
          'hover:underline': props.link
        },
        { 'flex-1': props.label !== null, 'invisible w-0': props.label == null }
      ]
    }),
    icon: ({ props }) => ({
      class: [
        'mx-0',
        {
          [props.iconPos]: props.iconPos,
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null
        }
      ]
    }),
    loading: ({ props }) => ({
      class: [
        'w-full h-full flex items-center justify-center',
        'mx-0',
        'absolute',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null
        },
        'animate-spin'
      ]
    }),
    badge: ({ props }) => ({
      class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }]
    })
  },
  menu: {
    root: {
      class: [
        // Shape
        'rounded-md',

        // Size
        'min-w-[12rem]',
        'py-1',

        // Colors
        'bg-surface-0 dark:bg-surface-700',
        'border border-surface-200 dark:border-surface-700'
      ]
    },
    menu: {
      class: [
        // Spacings and Shape
        'list-none',
        'm-0',
        'p-0',
        'outline-none'
      ]
    },
    menuitem: {
      class: [
        // Position
        'relative'
      ]
    },
    content: ({ context }) => ({
      class: [
        //Shape
        'rounded-none',

        //  Colors
        {
          'text-surface-500 dark:text-white/70': !context.focused && !context.active,
          'text-surface-500 dark:text-white/70 bg-surface-200 dark:bg-surface-600/90':
            context.focused && !context.active,
          'text-primary-700 dark:text-surface-0/80 bg-primary-50 dark:bg-primary-400/30':
            context.focused && context.active,
          'text-primary-700 dark:text-surface-0/80 bg-primary-50 dark:bg-primary-400/30':
            !context.focused && context.active
        },

        // Hover States
        {
          'hover:bg-surface-100 dark:hover:bg-surface-600/80': !context.active,
          'hover:bg-primary-500/50 dark:hover:bg-primary-300/30 text-primary-700 dark:text-surface-0/80':
            context.active
        },

        // Transitions
        'transition-shadow',
        'duration-200'
      ]
    }),
    action: {
      class: [
        'relative',
        // Flexbox

        'flex',
        'items-center',

        // Spacing
        'py-3',
        'px-5',

        // Color
        'text-surface-700 dark:text-white/80',

        // Misc
        'no-underline',
        'overflow-hidden',
        'cursor-pointer',
        'select-none'
      ]
    },
    icon: {
      class: [
        // Spacing
        'mr-2',

        // Color
        'text-surface-600 dark:text-white/70'
      ]
    },
    label: {
      class: ['leading-none']
    },
    submenuicon: {
      class: [
        // Position
        'ml-auto'
      ]
    },
    submenu: {
      class: [
        // Size
        'w-full sm:w-48',

        // Spacing
        'py-1',
        'm-0',
        'list-none',

        // Shape
        'shadow-none sm:shadow-md',
        'border-0',

        // Position
        'static sm:absolute',
        'z-10',

        // Color
        'bg-surface-0 dark:bg-surface-700'
      ]
    },
    separator: {
      class: 'border-t border-surface-200 dark:border-surface-600 my-1'
    }
  }
}
