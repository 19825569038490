<template>
  <div v-if="displaying" class="divide-y">
    <AreaDimension
      v-for="dimension in displayedMappedRequiredDimensions"
      :key="dimension.abbr"
      :value="dimension"
      :store="store"
      :object="object"
      :reference="reference"
      @input="(dim) => setDimension(dimension.abbr, dim)"
      required
    >
      <template #before>
        <slot name="before"></slot>
      </template>
      <template #after>
        <slot name="after"></slot>
      </template>
    </AreaDimension>
  </div>
</template>

<script>
import AssemblyDimensionsMixin from '../quote/ProjectDimensions/AssemblyDimensionsMixin'
import AreaDimension from '../quote/ProjectDimensions/AreaDimension.vue'

export default {
  name: 'AssemblyDimensionsConfirm',
  components: { AreaDimension },
  mixins: [AssemblyDimensionsMixin],
  props: {
    excludeForRefIds: {
      default: () => []
    }
  },
  emits: ['displaying'],
  watch: {
    displaying: {
      handler(count) {
        this.$emit('displaying', count)
      },
      immediate: true
    }
  },
  computed: {
    displaying() {
      return this.mappedRequiredDimensions.length - this.hideRemovingDimensions.length
    },
    hideRemovingDimensions() {
      const excludedRefs = this.excludeForRefIds
      if (!excludedRefs || !excludedRefs.length) return []

      const required = this.object.oChildRequiredDimensions
      const flattened = Object.keys(required).reduce(
        (acc, abbr) => ({
          ...acc,
          [abbr]: _.uniq(required[abbr].map((o) => o.refId))
        }),
        {}
      )

      return Object.values(this.mappedRequiredDimensions)
        .map((dim) => dim.abbr || dim.dimension_abbr)
        .filter((abbr) => {
          if (!(abbr in flattened)) return true // in case old format without refids

          const refIds = flattened[abbr]

          // After excluded ones removed, is there any left, any reason to show?
          return !_.difference(refIds, excludedRefs).length
        })
    },
    name() {
      return this.object.assembly_name || this.object.quote_name
    },
    displayedMappedRequiredDimensions() {
      return this.mappedRequiredDimensions.filter(
        (i) =>
          !this.hideRemovingDimensions.includes(i.abbr) &&
          (!i.demote || this.showDerivedDimensionsLocal)
      )
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
