export default {
  root: {
    class: []
  },
  navContainer: ({ props }) => ({
    class: [
      // Position
      'relative',
      'mb-10',

      // Misc
      { 'overflow-hidden': props.scrollable }
    ]
  }),
  navContent: {
    class: [
      // Overflow and Scrolling
      'overflow-y-hidden overscroll-contain',
      'overscroll-auto',
      'scroll-smooth',
      '[&::-webkit-scrollbar]:hidden'
    ]
  },
  previousButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 left-0',
      'z-20',

      // Size and Shape
      'h-full w-12',
      'rounded-none',

      // Colors
      'bg-flame-white',
      'text-surface-500',
      'hover:text-pitch-black'
    ]
  },
  nextButton: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-center',

      // Position
      '!absolute',
      'top-0 right-0',
      'z-20',

      // Size and Shape
      'h-full w-12',
      'rounded-none',

      // Colors
      'bg-flame-white',
      'text-surface-500',
      'hover:text-pitch-black'
    ]
  },
  nav: {
    class: [
      // Flexbox
      'flex flex-1',

      // Spacing
      'list-none',
      'gap-x-12',

      // Colors
      'dark:border-surface-700',
      'text-surface-900 dark:text-surface-0/80'
    ]
  },
  tabpanel: {
    header: ({ props }) => ({
      class: [
        // Spacing
        'mr-0',

        // Misc
        {
          'opacity-60 cursor-default user-select-none select-none pointer-events-none':
            props?.disabled
        }
      ]
    }),
    headerAction: ({ parent, context }) => ({
      class: [
        'relative',

        // Font
        'font-medium',
        'text-xl',
        'text-nowrap',

        // Flexbox and Alignment
        'flex items-center',

        // Spacing
        'py-1',

        // Shape
        'border-b-2',

        // Colors and Conditions
        {
          'border-transparent': parent.state.d_activeIndex !== context.index,
          'text-surface-500': parent.state.d_activeIndex !== context.index,

          'border-pitch-black': parent.state.d_activeIndex === context.index,
          'text-pitch-black': parent.state.d_activeIndex === context.index
        },

        // States
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset',
        'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
        {
          'hover:border-surface-500 dark:hover:border-primary-400':
            parent.state.d_activeIndex !== context.index,
          'hover:text-surface-500 dark:hover:text-surface-0':
            parent.state.d_activeIndex !== context.index
        },

        // Transitions
        'transition-all duration-200',

        // Misc
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none'
      ]
    }),
    headerTitle: {
      class: [
        // Text
        'leading-none',
        'whitespace-nowrap'
      ]
    },
    content: {}
  }
}
