<template>
  <div>
    <ItemizedList v-if="!hideBreakdown" :normalized="normalized" :readonly="true" />
    <div v-if="hideBreakdown">
      <BundledItem
        :hideSelect="true"
        :item="{
          title: `${percentage}% of project`,
          items,
          totalItems: items.length
        }"
      />
    </div>

    <div class="mt-5 flex justify-end">
      <div class="itemized-totals flex flex-col items-between">
        <div class="d-flex justify-between mt-2" v-if="showBundled">
          <span>
            {{
              l(
                typeof presentationSettings.showBundledProfit === 'string'
                  ? presentationSettings.showBundledProfit
                  : l('Management fee')
              )
            }}:
          </span>
          <span>{{
            $f.currency(invoiceNet - project.quote_total_cost_net + (invoiceDiscount || 0))
          }}</span>
        </div>

        <div class="flex justify-between mt-2 mb-1" v-if="invoiceDiscount > 0">
          <p class="mr-4 font-semibold">{{ l('Discount') }}:</p>
          <p class="discount text-success font-semibold">
            {{ $f.currency(invoiceDiscount) }}
          </p>
        </div>

        <div v-if="!loading" class="flex justify-between mt-2 mb-1">
          <p class="mr-4">{{ l('Surcharge') }}:</p>
          <p>
            {{ $f.currency(serviceFeeNet) }}
          </p>
        </div>

        <div class="flex justify-between mt-2 mb-1" v-if="invoiceTax > 0 && !loading">
          <p class="mr-4 font-semibold">{{ l('Subtotal') }}:</p>
          <p>{{ $f.currency(invoiceNet) }}</p>
        </div>

        <div
          class="border-t border-cool-gray-300 mt-2"
          v-if="taxSums && taxSums.length > 0 && !loading"
        >
          <div v-for="tax in taxSums" :key="tax.key" class="flex justify-between mt-2">
            <span class="flex flex-col">
              <span class="tax-name mr-4 mb-1"> {{ tax.name }}: </span>
              <small class="text-cool-gray-600" v-if="languageSelected.includes('en_')">
                tax on {{ friendlyNameTaxOn(tax.on) }} derived from
                {{ friendlyNameTaxType(tax.type) }}
              </small>
            </span>
            <p>
              {{ $f.currency(tax.sum) }}
            </p>
          </div>
        </div>

        <p class="text-cool-gray-200" v-if="loading">
          {{ l('Calculating totals...') }}
        </p>

        <div
          v-if="!hideTotal && !loading"
          class="flex justify-between mt-4 border-t border-cool-gray-600 pt-3"
        >
          <p class="mr-4 font-semibold">Total:</p>
          <h2>
            {{ $f.currency(invoiceTotal) }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, inject, defineProps, onMounted, watch, ref } from 'vue'
import useTranslation from '@/components/composables/Translation'
import useItemizedPayments from '@/components/composables/ItemizedPayments'
import useInvoice from '@/components/composables/Invoice'
import usePayments from '@/components/composables/Payment'
import usePaymentMethod from '@/components/composables/PaymentMethod'
import ItemizedList from '../invoice/itemized/ItemizedList.vue'
import BundledItem from '@/components/ui/listItem/BundledItem.vue'

const props = defineProps({
  hideTotal: {
    type: Boolean,
    default: false
  },
  hideBreakdown: {
    type: Boolean,
    default: false
  }
})

const { hideTotal } = props
const loading = ref(0)
const percentage = ref(0)
// provide/inject
const project = inject('project')

const { l, languageSelected } = useTranslation()

const { fetchPaymentDetails, fees } = usePayments()

const { alias, paymentMethodVariants } = usePaymentMethod()

const { invoiceDiscount, normalized, getPercentageOfProject, items } = useItemizedPayments()

const {
  invoiceTotal,
  invoiceNet,
  invoiceTax,
  oTaxSums,
  friendlyNameTaxOn,
  friendlyNameTaxType,
  invoiceGross,
  serviceFeeNet
} = useInvoice()

const { oPresentationSettings } = project.value
const presentationSettings = reactive(oPresentationSettings)

const taxSums = computed(() =>
  Object.values(oTaxSums.value).filter(
    (tax) => Math.abs(tax.pcnt) >= 0.01 && Math.abs(tax.sum) >= 0.01
  )
)

const serviceFee = computed(() => fees.value[alias.value])

const showBundled = computed(() => invoiceNet >= 0.01 && presentationSettings.showBundledProfit)

watch(
  serviceFee,
  () => {
    serviceFeeNet.value = serviceFee.value || 0
  },
  {
    immediate: true
  }
)

onMounted(async () => {
  loading.value = 1
  const projectTotal = c.toNum(project.value.quote_price_gross, 2)
  percentage.value = getPercentageOfProject(projectTotal, items.value)
  await fetchPaymentDetails(invoiceGross.value, null, paymentMethodVariants.value)
  loading.value = 0
})
</script>
