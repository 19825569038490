<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  title: {
    type: String
  },
  icon: {},
  full: {
    type: Boolean,
    default: false
  },
  center: {
    type: Boolean
  },
  bodyClass: {},
  create: {
    type: Boolean
  },
  grid: {
    type: Boolean
  }
})

const title = ref(props.title)
</script>

<template>
  <div
    class="md:h-20 shrink-0 flex justify-between items-center w-full py-4"
    :class="grid || full ? '' : 'max-w-7xl'"
  >
    <div class="h-full basis-1/2 shrink-1 flex justify-start gap-4 items-center">
      <font-awesome-icon v-if="icon" :icon="icon" class="text-xl" />
      <h1 class="font-medium" v-if="title">{{ title }}</h1>
      <slot name="left"></slot>
    </div>
    <slot name="middle"></slot>
    <div class="h-full basis-1/2 shrink-1 flex justify-end gap-4 items-center">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
