import { toRefs, reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import Status from '@/../imports/api/Statuses'
import useGantt from '@/components/ui/gantt/Gantt'
import useGantts from '@/components/ui/gantt/Gantts'
import { priorities } from '@/components/schedule/Task'
import eventBus from '@/eventBus'

export const initialState = {
  rootRefId: null,
  projectId: null,
  selected: null,
  project: {},
  hoursPerDay: 8,
  reportedChanges: {},
  status: '',
  scheduleStatus: '',
  scheduleStatuses: [
    {
      text: 'Draft',
      value: Status.statuses.Draft
    },
    {
      text: 'Published',
      value: Status.statuses.Approved
    }
  ],
  filters: {
    priority: [],
    item_status: [],
    assignee_ids: []
  }
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  const { gantt, setOrderLists, resetGantt, settings } = useGantt()
  const { updateDataInGantt } = useGantts()
  const store = useStore()

  const loadingSchedule = ref(1)

  const hoursPerDayTotal = computed(() =>
    settings.value.oWorkingHours
      ? settings.value.oWorkingHours.end - settings.value.oWorkingHours.start
      : 8
  )

  /**
   * Utility method to get a time stamp
   * @param {String} date
   * @returns {Number}
   */
  const getTimeStamp = (date) => {
    const end = new Date(date)
    return end.getTime()
  }

  /**
   * Utility method to format the date
   * @param {String} date
   * @returns {Date}
   */
  const formatDate = (date) => {
    return typeof date === 'string' || typeof date === 'object' ? date : new Date(date)
  }

  /**
   * Adjust the start and end date range to start and end of day
   * @param {String} startDate
   * @param {String} endDate
   * @returns {Object}
   */
  const adjustDates = (startDate, endDate) => {
    return {
      startDate: moment(startDate).add(30, 'minutes').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    }
  }

  /**
   * Format the date range for readability
   * @param {Object} params
   * @returns {String}
   */
  const formatDateRange = ({ startDate, endDate }) => {
    const days = moment(endDate).diff(startDate, 'days')
    if (days <= 7) return `${days}d`
    const weeks = moment(endDate).diff(startDate, 'weeks')
    if (weeks <= 5) return `${weeks}w`
    const months = moment(endDate).diff(startDate, 'months')
    if (months < 12) return `${months}m`
    const years = moment(endDate).diff(startDate, 'years')
    return `${years}y`
  }

  /**
   * Format the duration for readability
   * @param {Number} days
   * @returns {String}
   */
  const formatDuration = (days) => {
    if (days <= 7) return `${days}d`
    const duration = moment.duration(days, 'days')
    const weeks = duration.weeks()
    const months = duration.months()
    if (weeks >= 1 && months < 1) return `${weeks}w`
    const years = duration.years()
    if (months >= 1 && years < 1) return `${months}m`
    return `${years}y`
  }

  /**
   * Convert days to hours
   * @param {Number} hours
   * @returns {Number}
   */
  const convertDaysToHours = (days) => Math.ceil(days * context.hoursPerDay)

  /**
   * Convert hours to days
   * @param {Number} hours
   * @returns {Number}
   */
  const convertHoursToDays = (hours) => Math.ceil(hours / context.hoursPerDay)

  /**
   * Get the non working days based off working days
   * @returns {Array}
   */
  const getNonWorkingDays = () => {
    return c.difference([0, 1, 2, 3, 4, 5, 6], Object.values(settings.value.asWorkdays))
  }

  /**
   * Calculate the end date based on start date and duration
   * @param {Date} startDate
   * @param {Number} workdays
   * @returns {Date}
   */
  const calculateEndDate = (startDate, workdays) => {
    return gantt.value.calculateEndDate({
      start_date: startDate,
      duration: workdays * context.hoursPerDay,
      unit: 'hour'
    })
  }

  /**
   * Save the project start and end dates
   * @returns
   */
  const saveProjectDates = () => {
    if (!context.projectId) return
    const dates = gantt.value.getSubtaskDates()
    // detect if on 0h 0m 0 s and if so subtract a day
    let end = dates.end_date
    if (dates.end_date && moment(dates.end_date).format('HH:mm:ss') === '00:00:00') {
      end = moment(dates.end_date).subtract(1, 'd').toDate()
    }
    const { startDate: startDate, endDate: endDate } = adjustDates(dates.start_date, end)
    c.throttle(
      () => {
        store.dispatch('ajax', {
          path: 'schedule/saveProjectStartOrEnd',
          data: {
            id: context.projectId,
            endDate: endDate,
            startDate: startDate
          }
        })
      },
      { delay: 800 }
    )
    // if cached company wide schedule update item with new dates
    const companyId = store.state.session.company.company_id
    updateDataInGantt(`${companyId}-schedule`, context.projectId.toString(), {
      end_date: new Date(endDate),
      start_date: new Date(startDate)
    })
  }

  /**
   * Get the disabled calendar days
   * @returns {Array}
   */
  const disabledDate = () => getNonWorkingDays()

  /**
   * Report the schedule changes so we have before and after
   * @param {Object} changes
   */
  const reportChanges = (changes) => {
    context.reportedChanges = {
      before: [...(context.reportedChanges.before || []), ...(changes.before || [])],
      after: [...(context.reportedChanges.after || []), ...(changes.after || [])]
    }
    // throttle changes so they accumulate and and notify at once
    c.throttle(async () => {
      if (context.status === Status.statuses.Approved) {
        await notifyOfChanges(context.reportedChanges)
      }
      context.reportedChanges = {}
    }, 800)
  }

  /**
   * When auto schedule updates occur
   * @param {Object} changes
   */
  const onAutoScheduleUpdate = (changes) => {
    reportChanges(changes)
    saveProjectDates()
  }

  /**
   * When clearing the search filters
   * @returns
   */
  const onClearFilters = () => eventBus.$emit('clear-select-search-filters')

  /**
   * When has been loaded and the schedule is ready
   */
  const onReady = () => {
    // generate order lists for order by
    const orderLists = generateOrderLists()
    setOrderLists(orderLists)
    saveProjectDates()
    loadingSchedule.value = 0
  }

  /**
   * When filtering by multiple assignees
   * @param {Array} assignees
   */
  const onFilterByAssignees = (assignees) => {
    const ids = assignees.map(
      (i) =>
        parseInt(i.id, 10) ||
        parseInt(i.user_id, 10) ||
        parseInt(i.vendor_user_id, 10) ||
        parseInt(i.vendor_id, 10)
    )
    context.filters.assignee_ids = ids && ids.length > 0 ? ids : null
  }

  /**
   * When order lists need to be refreshed regenerate and set
   */
  const onRefreshOrderList = () => {
    const orderLists = generateOrderLists()
    setOrderLists(orderLists)
  }

  /**
   * Generate the order lists for grouping
   * @returns {Object}
   */
  const generateOrderLists = () => {
    // Extract all the assignees that a user can order by
    const data = gantt.value.getTaskByTime()
    const assignees = data.reduce((acc, item) => {
      if (item.assignees && item.assignees.length > 0) {
        item.assignees.forEach((assignee) => {
          const exits = acc.find((i) => i.key === assignee.item_assignee_object_id)
          if (!exits) {
            // add the assignee in the expected format
            acc.push({
              key: assignee.item_assignee_object_id || assignee.id,
              label: assignee.vendor_name || assignee.user_name
            })
          }
        })
      }
      return acc
    }, [])
    // this is for items that have not been assigned
    assignees.push({
      key: null,
      label: 'Unassigned'
    })
    // return the assignee order list formatted and priorities
    const response = {
      assignee_ids: assignees,
      priority: priorities
    }
    return response
  }
  /**
   * Load the schedule from BE
   * @param {Object} params
   * @returns
   */
  const loadSchedule = async (params = {}) => {
    if (context.projectId) {
      // quote schedule
      return store.dispatch('ajax', {
        path: 'quote/schedule',
        data: {
          id: context.projectId,
          ...params
        }
      })
    }
    // company schedule
    return store.dispatch('ajax', {
      path: 'schedule/gantt',
      data: params
    })
  }

  /**
   * Reload the schedule data
   * @param {Object} params
   */
  const reloadData = async (params = {}) => {
    c.throttle(
      async () => {
        const { payload } = await loadSchedule(params)
        const { data } = payload
        data.forEach((item) => {
          gantt.value.addTask(item)
        })
      },
      { key: JSON.stringify(params), delay: 400 }
    )
  }

  /**
   * Reload the schedule and reinitialize gantt
   */
  const reload = async () => {
    await loadSchedule()
    eventBus.$emit('init-gantt')
  }

  /**
   * Send schedule changes to BE
   * @param {Object} changes
   * @returns
   */
  const notifyOfChanges = (changes = {}) => {
    return store.dispatch('item_assignee/notifyOfChanges', {
      changes,
      quoteId: context.projectId
    })
  }

  /**
   * Get the latest date from an array of task items
   * @param {Array} items
   * @returns
   */
  const getTheLatestDate = (items) => {
    // map all the end dates to an array in timestamp format
    const endDates = items.map((i) => new Date(i.end_date).getTime())
    // find the max date
    const max = Math.max(...endDates)
    // get the latest date
    return new Date(max)
  }

  /**
   * Search stages on the BE
   * @param {String} search
   * @returns
   */
  const searchStages = (search) => {
    return store.dispatch('Stage/search', {
      searchPhrase: search,
      limit: 15
    })
  }

  /**
   * Search assignees on the BE
   * @param {String} search
   * @returns
   */
  const searchAssignees = async (search) => {
    const { payload } = await store.dispatch('ajax', {
      path: 'schedule/searchAssignees',
      data: {
        phrase: search
      }
    })
    const { hits } = payload
    const set = hits.map((hit) => hit._source)
    return {
      set,
      ...payload
    }
  }

  /**
   * Calculates the total crew size based on an array of assignee objects.
   *
   * This function iterates over an array of assignee objects and sums up the crew sizes.
   * If an assignee does not have a specified crew size, a default value of 1 is used.
   *
   * @param {Array} [assignees=[]] - An optional array of assignee objects. Each assignee object may have a property `item_assignee_crew_size`.
   * @returns {number} - Returns the total calculated crew size.
   */
  const calculateCrewSize = (assignees = []) => {
    return assignees.reduce((acc, assignee) => {
      acc += assignee.item_assignee_crew_size || 1
      return acc
    }, 0)
  }

  /**
   * Use the crew size to calculate the duration of an item
   * @param {String} itemId
   * @param {Number} assigneeCount
   * @returns
   */
  const calculateDurationBasedOnCrewSize = (itemId, assignees = []) => {
    const item = gantt.value.getTask(itemId)
    const totalCrewSize = calculateCrewSize(assignees)
    if (totalCrewSize > 1) {
      const duration = item.duration / totalCrewSize
      const endDate = gantt.value.calculateEndDate({ start_date: item.start_date, duration })
      return {
        start_date: item.start_date,
        end_date: endDate,
        duration
      }
    }
    return {
      start_date: item.start_date,
      end_date: item.end_date,
      duration: item.duration
    }
  }

  /**
   * Set Project id
   * @param {String} id
   * @returns
   */
  const setProjectId = (id) => (context.projectId = id)

  /**
   * Set root reference id of the quote
   * @param {String} id
   * @returns
   */
  const setRootRefId = (id) => (context.rootRefId = id)

  /**
   * Set the project
   * @param {Object} project
   * @returns
   */
  const setProject = (project) => (context.project = project)

  const resetSchedule = () => {
    context = reactive({
      ...initialState,
      project: {},
      reportedChanges: {},
      filters: {
        priority: [],
        item_status: [],
        assignee_ids: []
      }
    })
  }

  const resetGanttAndSchedule = () => {
    resetSchedule()
    resetGantt()
  }

  return {
    ...toRefs(context),
    hoursPerDayTotal,
    loadingSchedule,
    // utility
    getTimeStamp,
    formatDate,
    adjustDates,
    formatDateRange,
    formatDuration,
    convertDaysToHours,
    convertHoursToDays,
    calculateEndDate,
    calculateDurationBasedOnCrewSize,
    calculateCrewSize,
    // handlers
    onRefreshOrderList,
    disabledDate,
    getTheLatestDate,
    onAutoScheduleUpdate,
    onClearFilters,
    onFilterByAssignees,
    onReady,
    // searches
    searchStages,
    searchAssignees,
    // other
    generateOrderLists,
    reload,
    reloadData,
    loadSchedule,
    saveProjectDates,
    reportChanges,
    resetSchedule,
    resetGanttAndSchedule,
    // setters
    setProjectId,
    setRootRefId,
    setProject
  }
}
