import { useStore } from 'vuex'
import { ref, reactive, toRefs } from 'vue'
import languages from '../quote/presentation/languages/index'

export const initialState = {
  localizationLanguageCode: null,
  localizationCountryCode: null
}

// global state
const context = reactive({
  ...initialState
})

const useTranslation = () => {
  // composables //
  const store = useStore()

  // language defaults //
  const getUserLang = (user = null) => (user && user.localization_language_code) || 'en'
  const getUserCountry = (user = null) => (user && user.localization_country_code) || 'US'
  const getUserLocalLanguage = (user = null) => {
    if (context.localizationLanguageCode && context.localizationCountryCode) {
      return `${context.localizationLanguageCode}_${context.localizationCountryCode}`
    }
    const userLang = getUserLang(user)
    const userCountry = getUserCountry(user)
    return `${userLang}_${userCountry}`
  }

  // language encodings //
  // data //
  const languageSelected = ref(getUserLocalLanguage(store.state.session.user))
  // methods //
  const convertPhrase = (phrase, language = 'en_US') => {
    if (language in languages && phrase in languages[language]) {
      return languages[language][phrase]
    }

    return phrase
  }
  const l = (phrase) => {
    return convertPhrase(phrase, languageSelected.value)
  }

  const setLocalization = (localizationLanguageCode = 'en', localizationCountryCode = 'US') => {
    context.localizationLanguageCode = localizationLanguageCode
    context.localizationCountryCode = localizationCountryCode
    languageSelected.value = store ? getUserLocalLanguage(store.state.session.user) : null
  }

  // language options //
  // data //
  const languageOptions = ref(
    Object.keys(languages).reduce(
      (acc, lang) => [
        ...acc,
        {
          name: languages[lang].languageName,
          value: lang
        }
      ],
      []
    )
  )

  return {
    ...toRefs(context),
    languageSelected,
    languageOptions,
    l,
    convertPhrase,
    getUserLocalLanguage,
    getLang: getUserLocalLanguage,
    getUserCountry,
    getUserLang,
    setLocalization
  }
}

export default useTranslation
