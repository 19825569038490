export default {
  root: ({ props }) => ({
    class: [
      'inline-block relative',
      'w-12 h-7 max-h-7',
      'rounded-[2px]',
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled
      }
    ]
  }),
  slider: ({ props, attrs }) => {
    return {
      class: [
        // Position
        'absolute top-0 left-0 right-0 bottom-0',
        { 'before:transform before:translate-x-5': props.modelValue == props.trueValue },

        // Shape
        'rounded-[2px]',

        // Before:
        'before:absolute before:top-1/2 before:left-0.5',
        'before:-mt-2.5',
        'before:h-5 before:w-5',
        'before:rounded-[1px]',
        'before:duration-200',
        'before:bg-surface-0 before:dark:bg-surface-900',

        // Colors
        'border',
        {
          'bg-surface-300 border-2 border-surface-500 dark:bg-surface-700': !(
            props.modelValue == props.trueValue
          ),
          'bg-pitch-black dark:bg-primary-400':
            props.modelValue == props.trueValue && !attrs.severity,
          'bg-blue-print dark:bg-blue-print-400':
            props.modelValue == props.trueValue && attrs.severity === 'info',
          'bg-green-500 dark:bg-green-400':
            props.modelValue == props.trueValue && attrs.severity === 'success',
          'bg-orange-500 dark:bg-orange-400':
            props.modelValue == props.trueValue && attrs.severity === 'warning',
          'bg-red-500 dark:bg-red-400':
            props.modelValue == props.trueValue && attrs.severity === 'danger'
        },

        { 'border-transparent': !props.invalid },

        // Invalid State
        { 'border-red-500 dark:border-red-400': props.invalid },

        // States
        {
          'peer-hover:bg-surface-300 dark:peer-hover:bg-surface-600 ':
            !(props.modelValue == props.trueValue) && !props.disabled
        },
        {
          'peer-hover:bg-primary-600 dark:peer-hover:bg-surface-300 ':
            props.modelValue == props.trueValue && !props.disabled
        },
        'peer-focus-visible:ring peer-focus-visible:ring-primary-400/50 dark:peer-focus-visible:ring-primary-300/50',

        // Transition
        'transition-colors duration-200',

        // Misc
        'cursor-pointer'
      ]
    }
  },
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded-[2px]',
      'outline-none',

      // Misc
      'appearance-none',
      'cursor-pointer'
    ]
  }
}
