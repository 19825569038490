<template>
  <card class="split-item--container" :class="selected ? 'itemSelected' : 'itemUnselected'">
    <div class="split-item">
      <div class="split-item--checkbox">
        <checkbox v-model="isSelected" class="info"></checkbox>
      </div>
      <div class="split-item--content">
        <div class="split-item--content--top">
          <strong>
            {{ $f.truncate(item.item_name, 30) }}
            <strong style="font-size: 0.8em">({{ $f.truncate(vendorName, 20) }})</strong>
          </strong>
        </div>

        <div class="split-item--content--bottom justify-between">
          <div class="flex flex-col" style="flex: 0 0 auto">
            <div class="flex flex-col justify-center items-center">
              <strong style="font-size: 0.6em">Budgeted cost</strong>
              <small>
                {{ $f.currency(item.item_recorded_cost_gross) }}
              </small>
            </div>
            <div class="flex flex-col justify-center items-center">
              <strong style="font-size: 0.6em">Actual cost</strong>
              <small>
                {{
                  $f.currency(
                    item.item_actual_total_cost_gross === null
                      ? item.item_recorded_cost_gross
                      : item.item_actual_total_cost_gross
                  )
                }}
              </small>
            </div>
            <div class="flex flex-col justify-center items-center mt-1">
              <strong style="font-size: 0.6em">Already paid</strong>
              <small class="text-danger">
                {{ $f.currency(item.item_vendor_paid_gross) }}
              </small>
            </div>
          </div>

          <div class="split-item--content--bottom flex-col" v-if="isSelected">
            <div class="split-item--content--bottom" style="width: 100%">
              <help>
                <template #before> Payment </template>

                Unlike in the budget spreadsheet, this amount should include all sales taxes.
              </help>
              <field
                type="calculator"
                v-model="gross"
                style="font-size: 0.5"
                class="ml-2"
                format="currency"
              />
            </div>
            <div class="split-item--content--bottom" style="width: 100%">
              <help>
                <template #before> Final payment? </template>

                Check this box if this is the last and final payment for this item. If you expect to
                make another payment later, leave it unchecked.
              </help>
              <checkbox v-model="fullyPaid" class="ml-2 info" />
            </div>
          </div>

          <div class="split-item--content--bottom flex-col split-item--show-hover" v-else>
            <btn class="btn info" @click="isSelected = 1">
              <template #icon>
                <font-awesome-icon icon="plus" />
              </template>
              Add item to payment
            </btn>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import NameMixin from '../mixins/Name'
import eventBus from '@/eventBus'

/**
 * Emits:
 * -itemChanges // changes that must be made to item based on updates made here
 * -click // click event
 */
export default {
  name: 'SplitItem',
  mixins: [NameMixin],
  emits: ['selected', 'input', 'fullyPaid'],
  data() {
    return {
      itemChanges: {},
      amount: 0,
      vendorName: '',
      explicitlySetFullyPaid: null,
      isFullyPaid: this.item.item_vendor_is_fully_paid
    }
  },
  watch: {
    fullyPaid() {
      this.emitFullyPaid()
    },
    gross(v) {
      if (
        !this.explicitlySetFullyPaid &&
        (v >= this.item.item_vendor_unpaid_gross || c.eq(v, this.item.item_vendor_unpaid_gross, 2))
      ) {
        this.fullyPaid = 1
      } else {
        this.fullyPaid = 0
      }
    }
  },
  computed: {
    // taxId: {
    //
    // },
    isSelected: {
      get() {
        return this.selected
      },
      set(b) {
        this.$emit('selected', b ? 1 : 0)
        eventBus.$emit(`${this.uid}-selected`, b ? 1 : 0)
      }
    },
    gross: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', c.toRound(v))
      }
    },
    changedItem() {
      return {
        ...this.item,
        ...this.itemChanges
      }
    },
    fullyPaid: {
      get() {
        if (!this.selected) return 0
        return this.explicitlySetFullyPaid ? this.isFullyPaid : this.item.item_vendor_is_fully_paid
      },
      set(b) {
        this.isFullyPaid = b ? 1 : 0
        this.explicitlySetFullyPaid = true
      }
    }
  },
  methods: {
    emitFullyPaid() {
      this.$emit('fullyPaid', this.fullyPaid)
    }
  },
  async mounted() {
    if (this.item && this.item.vendor_id) {
      this.vendorName = String(await this.getName('vendor', this.item.vendor_id)).trim()
    }

    if (this.selected) {
      this.emitFullyPaid()
    }
  },
  props: {
    selected: {
      default: 0
    },
    /**
     * Split object
     */
    value: {
      type: Number
    },
    /**
     * Item the split is based off of
     */
    item: {
      required: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.split-item--container {
  overflow: hidden;
  .card-body {
    padding: 0;
  }
  .split-item {
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 8em;
    .split-item--checkbox {
      width: 4em;
      min-width: 4em;
      max-width: 4em;
      flex: 0 0 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      .checkbox--container {
        width: 6em;
        height: 10em;
        border: none;
      }
    }
    .split-item--content {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      padding: 1.25rem;
      flex: 1 1 100%;
    }
    .split-item--content--top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 0.5em;
    }
    .split-item--content--bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    [calculator-component] {
      input[type='text'] {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        font-size: 0.9em;
        width: 8em;
      }
    }
  }

  &.itemUnselected {
    opacity: 0.5;
    .split-item--show-hover {
      opacity: 0;
      transition: all 0.1s ease;
    }
    &:hover {
      opacity: 1;
      cursor: pointer;

      .split-item--show-hover {
        opacity: 1;
      }
    }
  }
}
</style>
