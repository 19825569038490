<template>
  <div class="range--container input-container">
    <choose
      v-bind="$props"
      ref="choose"
      :emit-delay="0"
      :return-array="false"
      :static-set="options"
      placeholder="All time"
      v-model="chooseValue"
    />

    <div v-if="chooseValueRaw === 'custom'" class="mt-2 flex justify-between items-center">
      <strong class="mx-1">From</strong>
      <DatePicker v-model="from" placeholder="all time" style="font-size: 0.7em" />
      <strong class="mx-1">to</strong>
      <DatePicker v-model="to" placeholder="now" style="font-size: 0.7em" />
    </div>

    <small class="text-muted mt-2" v-if="chooseValueRaw !== 'custom'">
      {{ dateDescription }}
    </small>
  </div>
</template>

<script>
import Field from '../mixins/Field'
import UserMeta from '../mixins/UserMeta'
import DatePicker from './DatePicker.vue'
import moment from 'moment'

export default {
  name: 'TimeRangeSelector',

  mixins: [Field, UserMeta],

  components: {
    DatePicker
  },

  props: {
    btnClass: {
      default: 'btn round sm'
    },
    emitDelay: {
      type: Number,
      default: 100
    }
  },

  mounted() {
    // Choose last 30 days
    this.hasFocused = true
    this.$refs.choose.toggleIndex(2)
  },

  data() {
    return {
      isCustom: false,
      chooseValueRaw: null
    }
  },

  methods: {
    setCustom(from = null, to = null) {
      let val = ''

      if (from) val = `>=${from}`

      if (to && from) val = `${val}&&`

      if (to) val = `${val}<${to}`

      this.setRawValue(val)
    }
  },

  computed: {
    from: {
      get() {
        const val = String(this.value).replace(/(?:^>=?([0-9,.]+))?.*?$/, '$1')
        if (val === '') return null
        return val
      },
      set(val) {
        this.setCustom(val, this.to)
      }
    },
    to: {
      get() {
        const val = String(this.value).replace(/^.*?(?:<=?([0-9,.]+))?$/, '$1')
        if (val === '') return null
        return val
      },
      set(val) {
        this.setCustom(this.from, val)
      }
    },

    dateDescription() {
      let value = this.rawValue

      if (!value) return 'All time'

      if (/&&/.test(value)) {
        value = value.replace(/>|<|=/g, '')
        const [from, to] = value.split('&&')
        return `Between ${c.format(from, 'date')} and ${c.format(moment(+to).subtract(1, 'days').valueOf(), 'date')}`
      }

      if (/^>?<?=?\d+/.test(value)) {
        value = value.replace(/[<>]=?/g, '')
        return `Between ${c.format(value, 'date')} and now`
      }

      return 'All time'
    },
    chooseValue: {
      get() {
        return this.chooseValueRaw
      },
      set(ch) {
        this.chooseValueRaw = ch

        if (ch === 'custom') {
          this.isCustom = true
          return
        }

        if (ch === 'null') {
          this.setRawValue('')
          return
        }

        this.isCustom = false
        this.setRawValue(ch)
      }
    },
    options() {
      return [
        {
          text: 'Custom',
          value: 'custom'
        },
        {
          text: 'Last 12 months',
          value: `>${moment().subtract(365, 'days').startOf('day').valueOf()}`
        },
        {
          text: 'Last 30 days',
          value: `>${moment().subtract(30, 'days').startOf('day').valueOf()}`
        },
        {
          text: 'Last 14 days',
          value: `>${moment().subtract(14, 'days').startOf('day').valueOf()}`
        },
        {
          text: 'Last 7 days',
          value: `>${moment().subtract(7, 'days').startOf('day').valueOf()}`
        },
        {
          text: 'Last year',
          value: `>=${moment().subtract(1, 'year').startOf('year').valueOf()}&&<${moment().startOf('year').valueOf()}`
        },
        {
          text: 'Last month',
          value: `>=${moment().subtract(1, 'month').startOf('month').valueOf()}&&<${moment().startOf('month').valueOf()}`
        },
        {
          text: 'Last week',
          value: `>=${moment().subtract(1, 'week').startOf('week').valueOf()}&&<${moment().startOf('week').valueOf()}`
        },
        {
          text: 'All time',
          value: null
        }
      ]
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.range--container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .choose--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
