/**
 * Company-scoped key-value pairs.
 */
export default {
  type: 'keyvalue',

  skipAudit: true,

  fields: {
    keyvalue_id: {
      type: 'int',
    },
    keyvalue_key: {
      type: 'string',
    },
    keyvalue_value: {
      type: 'string',
    },
    company_id: {
      type: 'string',
      filter: true,
      mapTo: 'company',
    },
  },

  generateVueActions() {
    return {
      async get({ dispatch }, payload) {
        let key = payload;
        let global = false;
        if (typeof payload === 'object') {
          key = payload.key || payload.name;
          global = payload.global || false;
        }

        const { object } = await dispatch('ajax', {
          path: `keyvalue/get/${key}/${global}`,
        });

        return object.value || object.message || null;
      },

      async set({ dispatch }, { key, value, global = false }) {
        let escapedValue = value;
        if ((typeof value === 'object') || Array.isArray(value)) {
          escapedValue = JSON.stringify(value);
        }

        const encoded = encodeURIComponent(btoa(value));

        return dispatch('ajax', {
          path: `keyvalue/set/${key}/${encoded}/${global}`,
        });
      },
    };
  },
};
