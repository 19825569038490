<template>
  <container class="p-4">
    <card-section>
      <h2 class="h3">Bolster Privacy Policy</h2>
      <ol start="1">
        <li class="mb-4">
          <h3 class="h5"><strong>1. INTRODUCTION</strong></h3>
          <p>
            Bolster Built Inc., doing business as Bolster (“<strong>Bolster</strong>”,
            “<strong>we</strong>”, “<strong>us</strong>”) has created this Privacy Policy (“<strong
              >Privacy Policy</strong
            >”) in order to set out how we collect, use, and disclose personal information through
            our software-as-a-service construction sales and job management platform (the
            “<strong>Platform</strong>”) including through our provision and your use of the
            Services (as defined in the Bolster Terms & Conditions and the Portal Terms of Use).
          </p>
          <p>
            Our Platform enables registered users, such as homeowners, developers and builders, to
            manage payments and contract with contractors and subcontractors (collectively,
            “<strong>Users</strong>”). The privacy of our Users is of great importance to us. By
            visiting any of our websites located at https://bolsterbuilt.com, including subpages,
            (collectively, the “<strong>Website</strong>”) or using the Platform or Services in any
            manner, you acknowledge that you accept the practices and policies outlined in this
            Privacy Policy and you hereby consent to the collection, use and disclosure of your
            Personal Information in accordance with this Privacy Policy.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>2. WHAT DOES THIS PRIVACY POLICY COVER?</strong></h3>
          <p class="mb-4">
            This Privacy Policy covers our collection, use and disclosure of information about
            identifiable individuals and information which can be used to identify an individual
            (“<strong>Personal Information</strong>”). Personal Information may be collected about
            our Users and visitors to the Website, as well as any individuals whose Personal
            Information is uploaded by a User.
          </p>
          <p>
            This Privacy Policy does not apply to the practices of companies that we do not own or
            control. Our Users are responsible for maintaining their own privacy policies governing
            the collection, use and disclosure of Personal Information. You are responsible for
            ensuring that you have obtained the necessary authorizations and consents for any
            Personal Information you make available to us for use in accordance with this Privacy
            Policy.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>3. COLLECTION AND USE OF PERSONAL INFORMATION</strong></h3>
          <p class="mb-4">
            <strong>3.1 Account Information</strong>. In order to use the Services, Users may be
            required to have a valid Bolster account to log in to the Platform (“Account”). When you
            register for the Services or create an Account, Bolster collects your name, email
            address, phone number and address (“Account Information”).
          </p>
          <p class="mb-2">Bolster uses the Account Information to:</p>
          <ul class="ml-4 mt-4 mb-4">
            <li class="mb-4">
              - authenticate access to the Account and provide access to the Services;
            </li>
            <li class="mb-4">- provide, operate, maintain and improve the Services;</li>
            <li class="mb-4">
              - Send technical notices, updates, security alerts and support and administrative
              messages
            </li>
            <li class="mb-4">
              - Provide and deliver the Services and features you request, process and complete
              transactions, and send you related information, including confirmations and invoices;
            </li>
            <li class="mb-4">
              - Respond to comments, questions, and requests and provide customer service and
              support;
            </li>
            <li class="mb-4">
              - Communicate with you about services, features, surveys, newsletters, offers,
              promotions, contests and events, and provide other news or information about us and
              our select partners;
            </li>
            <li class="mb-4">
              - Investigate and prevent fraudulent transactions, unauthorized access to the
              Services, and other illegal activities
            </li>
            <li class="mb-4">
              - Personalize and improve the Services, and provide content, features, and/or
              advertisements that match your interests and preferences or otherwise customize your
              experience on the Platform;
            </li>
            <li class="mb-4">
              - Monitor and analyze trends, usage, and activities in connection with the Platform
              and for marketing or advertising purposes;
            </li>
            <li class="mb-4">
              - Link or combine with other information we receive from third parties to help
              understand your needs and provide you with better service; and
            </li>
            <li class="mb-4">
              - Enable you to communicate, collaborate, and share files with users you designate;
              and For other purposes about which we will notify you about and seek your consent.
            </li>
          </ul>
          <p>
            <strong>3.2 Banking Information and AML</strong>. If you conduct any transactions on the
            Platform. Bolster will collect your banking information in order to process transactions
            and withdraw transactions fees owed. Users may be required to provide photo
            identification or other Personal Information (and supporting documentation) to verify
            their identity in order to process a transaction using the Services, which Bolster will
            use in order to comply with applicable laws and regulations regarding Anti-Money
            Laundering.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5">
            <strong>4. STORAGE LOCATION AND TRANSFER OF PERSONAL INFORMATION</strong>
          </h3>
          <p>
            Bolster processes and stores its data, including Personal Information, on servers
            located in Canada and the United States. Bolster also transfers data to the third-party
            service providers described in Section 5.1. By submitting Personal Information or
            otherwise using the Services, you agree to this transfer, storing or processing of your
            Personal Information in Canada and the United States. You acknowledge and agree that
            your Personal Information may be accessible to law enforcement and governmental agencies
            in Canada and the U.S. under lawful access regimes or court order.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5">
            <strong>5. DISCLOSURE OF PERSONAL INFORMATION WITH THIRD PARTIES</strong>
          </h3>
          <p class="mb-4">
            <strong>5.1 Service Providers and Business Partners</strong>. We may from time to time
            employ third parties to perform tasks on our behalf and we may need to share Account
            Information and other Personal Information with them to provide certain services. Unless
            we tell you differently, such third parties do not have any right to use the Personal
            Information we share with them beyond what is necessary for them to provide the tasks
            and services on our behalf. The third parties we currently engage includes third party
            companies and individuals employed by us to facilitate our services, including the
            provision of database management, payment processing and customer relationship
            management tools. In particular, Bolster uses the following third-party service
            providers that process Personal Information on behalf of Bolster: Amazon Web Services,
            Paysave, Sendgrid and Twilio.
          </p>
          <p class="mb-4">
            <strong>5.2 Business Transfers</strong>. If our business (or substantially all of our
            assets) are acquired by a third party, or if we go out of business, enter bankruptcy, or
            go through some other change of control, Personal Information may be made available or
            otherwise transferred to the new controlling entity, where permitted under applicable
            law.
          </p>
          <p class="mb-4">
            <strong>5.3 With Your Consent</strong>. If we need to use or disclose any Personal
            Information in a way not identified in this Privacy Policy, we will notify you and/or
            obtain consent as required under applicable privacy laws.
          </p>
          <p class="mb-4">
            <strong>5.4 As required by law</strong>. We may disclose your Personal Information to
            third parties without your consent if we have reason to believe that disclosing this
            information is necessary to identify, contact or bring legal action against someone who
            may be causing injury to or interference with (either intentionally or unintentionally)
            our rights or property, other End Users, or anyone else (including the rights or
            property of anyone else) that could be harmed by such activities. Further, we may
            disclose Personal Information when we believe in good faith that such disclosure is
            required by and in accordance with the law.
          </p>
          <p>
            We also reserve the right to access, read, preserve, and disclose any information as we
            reasonably believe is necessary to:
          </p>
          <ul class="ml-4 mt-4 mb-4">
            <li class="mb-4">
              - satisfy any applicable law, regulation, legal process or governmental request;
              enforce our contracts or user agreement, including investigation of potential
              violations hereof;
            </li>
            <li class="mb-4">
              - and detect, prevent, or otherwise address fraud, security or technical issues.
            </li>
          </ul>
          <p>
            The above may include exchanging information with other companies and organizations for
            fraud protection and spam/malware prevention. Notwithstanding the general terms of this
            policy, the collection, use, and disclosure of Personal Information may be made outside
            of the terms herein to the extent provided for in any applicable privacy or other
            legislation in effect from time to time, or pursuant to court orders.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>6. RETENTION</strong></h3>
          <p>
            We will keep your Personal Information for as long as it remains necessary for the
            identified purpose or as required by law, which may extend beyond the termination of our
            relationship with you. We may retain certain data as necessary to prevent fraud or
            future abuse, or for legitimate business purposes, such as analysis of aggregated,
            non-personally-identifiable data, account recovery, or if required by law. All retained
            Personal Information will remain subject to the terms of this Privacy Policy.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>7. ACCESS, CORRECTION AND ACCURACY</strong></h3>
          <p class="mb-4">
            You have the right to access the Personal Information we hold about you in order to
            verify the Personal Information we have collected in respect to you and to have a
            general account of our uses of that information. Upon receipt of your written request,
            we will provide you with a copy of your Personal Information, although in certain
            limited circumstances, and as permitted under law, we may not be able to make all
            relevant information available to you, such as where that information also pertains to
            another user. In such circumstances we will provide reasons for the denial to you upon
            request. We will endeavor to deal with all requests for access and modifications in a
            timely manner.
          </p>
          <p>
            We will make every reasonable effort to keep your Personal Information accurate and up
            to date, and we will provide you with mechanisms to update, correct, delete or add to
            your Personal Information as appropriate. As appropriate, this amended Personal
            Information will be transmitted to those parties to which we are permitted to disclose
            your information. Having accurate Personal Information about you enables us to give you
            the best possible service.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>8. CHANGES TO THIS PRIVACY POLICY</strong></h3>
          <p>
            We may amend this Privacy Policy from time to time. Use of Personal Information we
            collect is subject to the Privacy Policy in effect at the time such information is
            collected, used or disclosed. If we make material changes or changes in the way we use
            Personal Information, we will notify you by posting an announcement on our Website or
            Platform or sending you an email prior to the change becoming effective. You are bound
            by any changes to the Privacy Policy when you use the Website, Platform or Services
            after such changes have been first posted.
          </p>
        </li>
        <li class="mb-4">
          <h3 class="h5"><strong>9. ADDITIONAL INFORMATION</strong></h3>
          <p class="mb-4">
            Questions regarding this Privacy Policy or Bolster’s privacy practices should be
            directed to our Privacy Officer:
          </p>
          <p class="ml-4">Privacy Officer</p>
          <p class="ml-4">685 Center Street South</p>
          <p class="ml-4">Suite 1900</p>
          <p class="ml-4">Calgary, Alberta T2G2C7</p>
          <p class="ml-4">Privacy@BolsterBuilt.com</p>
        </li>
      </ol>
      <p>Last Updated: January 29, 2024</p>
    </card-section>
  </container>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
