<template>
  <div
    class="presentation-cost-item-container"
    v-observe-visibility="{
      callback: visibilityHandler,
      once: true
    }"
    :class="{
      'cost-item-emphasis': showEmphasis,
      'cost-item-upgrade': countAddons && addons.length,
      'cost-item-expandable': expandableLocal
    }"
    @click="expand"
    :id="object.refId"
    :target="`${uid}-edit-toggle`"
  >
    <div
      class="cost-item-floating cost-item-full-width"
      ref="floater"
      :class="isPinned ? 'pinned' : ''"
      :style="headingStyle"
    >
      <div
        class="cost-item-name"
        v-if="!showMiddleContent"
        :class="{
          condensed: !showMiddleContent
        }"
        @click="expand"
      >
        <btn rounded class="more px-0 p-2 default md" v-if="expandableLocal" @click="expand">
          <font-awesome-icon icon="chevron-right" fixed-width />
        </btn>

        {{ name || 'Misc item' }}

        <div class="hidden md:flex">
          <SuggestionButton
            @click="expand"
            class="info has-upgrades xs"
            v-if="
              !presentationSettings.showConfigurator && isIncluded && countAddons > addons.length
            "
          >
            <font-awesome-icon icon="chevrons-up" class="mr-2" fixed-width />
            {{ l('Upgrades available') }}
          </SuggestionButton>

          <SuggestionButton class="danger xs mx-1" v-if="isAnUpgrade">{{
            l('Upgraded item')
          }}</SuggestionButton>
        </div>

        <btn
          rounded
          class="more px-0 p-2 info lg"
          ref="editorBtn"
          v-if="editable"
          @click="openEditor"
        >
          <font-awesome-icon icon="pencil" />
        </btn>

        <QuotePresentationInputs
          class="mr-1"
          v-if="!presentationSettings.showConfigurator && showInputs"
          v-bind="$props"
        />

        <QuotePresentationDimensions
          class="mr-1"
          v-if="showDimensions && !showMiddleContent"
          v-bind="$props"
        />

        <SuggestionButton
          class="danger ml-2 sm"
          style="margin-left: auto !important; margin-right: 0 !important"
          v-if="
            !presentationSettings.showConfigurator &&
            optional &&
            !showMiddleContent &&
            (object.cost_item_is_included || object.assembly_is_included)
          "
          @click="removeSelf()"
        >
          <font-awesome-icon icon="remove" class="mr-2" />
          {{ l('Remove') }}
        </SuggestionButton>

        <SuggestionButton
          style="margin-left: auto"
          class="info mr-2 sm"
          v-else-if="
            !presentationSettings.showConfigurator &&
            optional &&
            !object.cost_item_is_included &&
            !object.assembly_is_included
          "
          @click="addSelf()"
        >
          {{ l('Include') }}?
        </SuggestionButton>

        <LoadingIndicator
          v-if="
            optional &&
            (object.cost_item_is_included || object.assembly_is_included) &&
            (!qty || !price || (qty === 1 && (price < 0.01 || !price)))
          "
        />
        <div
          class="cost-item-totals"
          v-else-if="!showMiddleContent && (showQty || showPrice || !qty || !expandableLocal)"
          :style="optional ? '' : 'margin-left: auto !important'"
          :class="!qty ? 'excluded' : ''"
        >
          <template v-if="!qty">
            {{ l('not included') }}
          </template>
          <template v-else>
            <template v-if="showQty">
              <template v-if="object.cost_type_is_fee"> 1 {{ l('each') }} </template>
              <template v-else> {{ $f.number(qty) }} {{ units }} </template>
            </template>
            <span v-if="showQty" class="mx-2">•</span>
            <template v-if="showPrice">
              {{ $f.currency(price) }}
            </template>
            <template v-else-if="!showPrice">
              {{ l('included') }}
            </template>
          </template>
        </div>
      </div>
      <div
        class="cost-item-floating--inner cost-item-full-padding"
        :style="
          !isVariation
            ? { background: 'transparent' }
            : {
                maxHeight: isVariation && !viewFull ? '8em' : 'unset'
              }
        "
      >
        <div class="top">
          <template v-if="showMiddleContent">
            <div class="cost-item-properties">
              <div class="cost-item-name flex flex-nowrap" @click="expand">
                <btn
                  rounded
                  class="more px-0 p-2 default md"
                  v-if="expandableLocal"
                  @click="expand"
                >
                  <font-awesome-icon icon="chevron-right" fixed-width />
                </btn>

                {{ name || 'Misc item' }}

                <div class="hidden md:flex">
                  <SuggestionButton
                    @click="expand"
                    class="info has-upgrades xs"
                    v-if="
                      !presentationSettings.showConfigurator &&
                      isIncluded &&
                      countAddons > addons.length
                    "
                  >
                    <font-awesome-icon icon="chevrons-up" class="mr-2" fixed-width />
                    {{ l('Upgrades available') }}
                  </SuggestionButton>

                  <SuggestionButton class="danger xs mx-1" v-if="isAnUpgrade">{{
                    l('Upgraded item')
                  }}</SuggestionButton>
                </div>

                <btn
                  rounded
                  class="more px-0 p-2 info lg"
                  ref="editorBtn"
                  v-if="editable"
                  @click="openEditor"
                >
                  <font-awesome-icon icon="pencil" />
                </btn>

                <QuotePresentationDimensions
                  class="mr-1"
                  v-if="showDimensions && !showMiddleContent"
                  v-bind="$props"
                />

                <SuggestionButton
                  class="danger ml-2 sm"
                  style="margin-left: auto !important; margin-right: 0 !important"
                  v-if="
                    !presentationSettings.showConfigurator &&
                    optional &&
                    !showMiddleContent &&
                    (object.cost_item_is_included || object.assembly_is_included)
                  "
                  @click="removeSelf()"
                >
                  <font-awesome-icon icon="remove" class="mr-2" />
                  {{ l('Remove') }}
                </SuggestionButton>

                <SuggestionButton
                  style="margin-left: auto"
                  class="info mr-2 sm"
                  v-else-if="
                    !presentationSettings.showConfigurator &&
                    optional &&
                    !object.cost_item_is_included &&
                    !object.assembly_is_included
                  "
                  @click="addSelf()"
                >
                  {{ l('Include') }}?
                </SuggestionButton>

                <LoadingIndicator
                  v-if="
                    optional &&
                    (object.cost_item_is_included || object.assembly_is_included) &&
                    (!qty || !price || (qty === 1 && (price < 0.01 || !price)))
                  "
                />

                <div
                  class="cost-item-totals"
                  v-else-if="
                    !showMiddleContent && (showQty || showPrice || !qty || !expandableLocal)
                  "
                  :style="optional ? '' : 'margin-left: auto !important'"
                  :class="!qty ? 'excluded' : ''"
                >
                  <template v-if="!qty">
                    {{ l('not included') }}
                  </template>
                  <template v-else>
                    <template v-if="showQty">
                      <template v-if="object.cost_type_is_fee"> 1 {{ l('each') }} </template>
                      <template v-else> {{ $f.number(qty) }} {{ units }} </template>
                    </template>
                    <span v-if="showQty" class="mx-2">•</span>
                    <template v-if="showPrice">
                      {{ $f.currency(price) }}
                    </template>
                    <template v-else-if="!showPrice">
                      {{ l('included') }}
                    </template>
                  </template>
                </div>
              </div>

              <div class="cost-item-inputs">
                <QuotePresentationInputs
                  class="cost-item-inputs-color"
                  v-if="showInputs"
                  v-bind="$props"
                  :deselectMessage="l('Clear color')"
                />
              </div>

              <div
                class="flex md:hidden"
                v-if="countAddons > addons.length || optional || isAnUpgrade"
              >
                <SuggestionButton
                  @click="expand"
                  class="info has-upgrades sm"
                  v-if="!presentationSettings.showConfigurator && countAddons > addons.length"
                >
                  <font-awesome-icon icon="chevrons-up" class="mr-2" fixed-width />
                  {{ l('Upgrades available') }}
                </SuggestionButton>

                <SuggestionButton class="danger sm mx-1" v-if="isAnUpgrade">{{
                  l('Upgraded item')
                }}</SuggestionButton>
              </div>

              <div
                class="cost-item-desc"
                :style="
                  viewFull || !isVariation
                    ? {}
                    : {
                        whiteSpace: isVariation && !viewFull ? 'no-wrap' : 'pre-line'
                      }
                "
                v-if="desc || isAnUpgrade"
              >
                <Linkify
                  :content="$f.truncate(desc || '', isVariation && !viewFull ? truncateAmt : 10000)"
                />
              </div>

              <fade>
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 0 !important;
                    padding: 0;
                  "
                  v-if="isPinned && isVariation"
                >
                  <LoadingIndicator
                    v-if="
                      loading ||
                      (optional &&
                        (object.cost_item_is_included || object.assembly_is_included) &&
                        (!qty || !price || (qty === 1 && (price < 0.01 || !price))))
                    "
                  />
                  <div class="cost-item-cost" v-if="showCost && !loading">
                    <strong class="text-muted mr-1">Cost</strong>
                    <span class="cost-item-cost">
                      {{ $f.currency(cost) }}
                    </span>
                  </div>
                  <div
                    class="cost-item-totals"
                    style="margin: 0 !important; margin-top: 0.5em !important"
                    v-else-if="!loading && (showQty || showPrice || !qty || !expandableLocal)"
                    :class="!qty ? 'excluded' : ''"
                  >
                    <template v-if="!qty">
                      {{ l('not included') }}
                    </template>
                    <template v-else>
                      <template v-if="showQty">
                        <template v-if="object.cost_type_is_fee"> 1 {{ l('each') }} </template>
                        <template v-else> {{ $f.number(qty) }} {{ units }} </template>
                      </template>
                      <span v-if="showQty" class="mx-2">•</span>
                      <template v-if="showPrice">
                        {{ $f.currency(price) }}
                      </template>
                      <template v-else-if="!showPrice && !expandableLocal">
                        {{ l('included') }}
                      </template>
                    </template>
                  </div>

                  <div class="flex justify-start items-center ml-2">
                    <a
                      class="text-info"
                      v-if="isVariation && (desc || '').length > truncateAmt"
                      @click="viewFull = !viewFull"
                    >
                      <small>
                        <strong> Show {{ viewFull ? 'less' : 'more' }}... </strong>
                      </small>
                    </a>
                  </div>
                </div>

                <div
                  class="flex justify-start items-center"
                  v-if="isVariation && (desc || '').length > truncateAmt"
                >
                  <a class="text-info" @click="viewFull = !viewFull">
                    <small>
                      <strong> Show {{ viewFull ? 'less' : 'more' }}... </strong>
                    </small>
                  </a>
                </div>
              </fade>

              <div
                @click.capture.stop.prevent="expand"
                class="cost-item-attributes"
                v-if="!isVariation && (hasProperties || $slots.default)"
              >
                <slot v-if="!hasProperties"></slot>

                <PresentationProperties :object="object" />

                <SuggestionButton class="info" v-if="expandableLocal" @click="expand">
                  <font-awesome-icon icon="plus" fixed-width />
                  {{ l('Expand') }}
                  {{
                    countAddons > addons.length && !presentationSettings.showConfigurator
                      ? `${l('to see options')}...`
                      : ''
                  }}
                </SuggestionButton>
              </div>
            </div>

            <img
              @click="() => $refs.gallery.preview(fileUrls[0])"
              v-if="(itemPictures.length || images.length || fileUrls.length) && isVisible"
              :src="fileUrls[0]"
              class="cost-item-img"
            />
          </template>
        </div>

        <div class="bottom">
          <div class="cost-item-footer" v-if="showMiddleContent && showFooter">
            <QuotePresentationDimensions class="mr-1" v-if="showDimensions" v-bind="$props" />

            <SuggestionButton
              style="margin-left: auto"
              class="danger mr-2 sm"
              v-if="
                !presentationSettings.showConfigurator &&
                optional &&
                (object.cost_item_is_included || object.assembly_is_included)
              "
              @click="removeSelf()"
            >
              <font-awesome-icon icon="remove" class="mr-2" />
              {{ l('Remove') }}
            </SuggestionButton>
            <SuggestionButton
              style="margin-left: auto"
              class="info mr-2 sm"
              v-else-if="
                !presentationSettings.showConfigurator &&
                optional &&
                !object.cost_item_is_included &&
                !object.assembly_is_included
              "
              @click="addSelf()"
            >
              {{ l('Include') }}?
            </SuggestionButton>
          </div>
        </div>
      </div>
    </div>

    <ImageGallery
      ref="gallery"
      v-if="(itemPictures.length || images.length) && isVisible"
      v-show="(itemPictures.length || images.length) > 1 && isVisible"
      :thumbs="thumbUrls"
      :urls="fileUrls"
      :show-as-background="false"
      style="margin: 1em"
    />

    <div v-if="isAnUpgrade">
      <SuggestionButton class="danger sm">
        {{ l('Upgrades subject to final approval and availability') }}.
      </SuggestionButton>
    </div>

    <div
      @click.capture.stop.prevent="expand"
      class="cost-item-attributes"
      v-if="isVariation && (hasProperties || $slots.default)"
    >
      <slot v-if="!hasProperties"></slot>
      <ul style="padding: 0" v-if="hasProperties">
        <li v-for="(attribute, index) in visibleProperties" :key="index">
          <strong>
            <Linkify :content="(attribute && attribute[0]) || ''" />
          </strong>
          <Linkify
            :content="
              attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1]
                ? `: ${attribute[1]}`
                : ''
            "
          />
        </li>
      </ul>

      <SuggestionButton class="info" v-if="expandableLocal" @click="expand">
        <font-awesome-icon icon="plus" fixed-width />
        {{ l('Expand') }}
        {{
          countAddons > addons.length && !presentationSettings.showConfigurator
            ? `${l('to see options')}...`
            : ''
        }}
      </SuggestionButton>
    </div>

    <div
      class="cost-item-variations cost-item-full-width cost-item-full-padding"
      ref="variationsContainer"
      v-if="isVariation"
    >
      <CostItemVariationSelector
        v-if="!presentationSettings.showConfigurator && isVisible && isVariation"
        @loading="(l) => (loading = l)"
        :deselectOnDestroy="false"
        :store="store"
        :editable="editable"
        :upgrading-allowed="upgradesAllowed"
        :commitInterval="100"
        :reference="object.refId"
        :key="object.refId"
        :object="object"
      />
    </div>

    <div
      style="display: flex; justify-content: flex-end; align-items: flex-end"
      v-if="showMiddleContent"
    >
      <LoadingIndicator
        v-if="
          loading ||
          (optional &&
            (object.cost_item_is_included || object.assembly_is_included) &&
            (!qty || !price || (qty === 1 && (price < 0.01 || !price))))
        "
      />

      <div class="cost-item-cost" v-if="showCost">
        <strong class="text-muted mr-1">Cost</strong>
        <span class="cost-item-cost">
          {{ $f.currency(cost) }}
        </span>
      </div>
      <div
        class="cost-item-totals"
        style="margin: 0 !important; margin-top: 0.5em !important"
        v-else-if="showQty || showPrice || !qty || !expandableLocal"
        :class="!qty ? 'excluded' : ''"
      >
        <template v-if="!qty">
          {{ l('not included') }}
        </template>
        <template v-else>
          <template v-if="showQty">
            <template v-if="object.cost_type_is_fee"> 1 {{ l('each') }} </template>
            <template v-else> {{ $f.number(qty) }} {{ units }} </template>
          </template>
          <span v-if="showQty" class="mx-2">•</span>
          <template v-if="showPrice">
            {{ $f.currency(price) }}
          </template>
          <template v-else-if="!showPrice && !expandableLocal">
            {{ l('included') }}
          </template>
        </template>
      </div>
    </div>

    <addons-list
      class="cost-item-upgrades"
      v-if="!presentationSettings.showConfigurator && showAddons && !editable && isVisible"
      :title="(name || 'item').toLowerCase()"
      :reference="object.refId"
      :optionals="false"
      :dimensions="possDimensions"
      :showCurrent="false"
      :upgrades="true"
      v-model="addons"
    />
    <div
      class="element-replacer"
      v-else-if="!isVisible && !presentationSettings.showConfigurator && showAddons && !editable"
    ></div>

    <drop
      ref="editor"
      :fixTo="`[target='${uid}-edit-toggle']`"
      offset="5px 5px"
      v-if="editorRequested && editable"
      targetAttachment="bottom left"
    >
      <card-section class="sm" v-if="object.type === 'cost_item'">
        <btn class="btn round info" @click="editCosts">
          <template #icon>
            <font-awesome-icon icon="rectangle-history-circle-plus" />
          </template>
          Edit costs, description, attributes..
        </btn>
      </card-section>

      <card-section class="sm">
        <card-list>
          <card-list-field>
            <span> Set emphasis </span>

            <strong
              class="text-muted"
              style="
                font-size: 0.7em;
                line-height: 1.1;
                white-space: normal;
                word-wrap: break-word;
                max-width: 25%;
              "
            >
              <div class="text-info mr-1">
                {{ (object.cost_type_emphasis || object.assembly_emphasis) > 0 ? '+' : ''
                }}{{ object.cost_type_emphasis || object.assembly_emphasis }}
              </div>

              <template
                v-if="
                  (object.type === 'assembly' && object.assembly_emphasis <= -3) ||
                  object.cost_type_emphasis === -1
                "
              >
                Show in a bundle with other de-emphasized items
              </template>
              <template v-else-if="object.type === 'assembly' && object.assembly_emphasis === -2">
                Show as a regular item (cannot see items)
              </template>
              <template v-else-if="object.type === 'assembly' && object.assembly_emphasis === -1">
                Show as an emphasized item (cannot see items)
              </template>
              <template v-else-if="object.type === 'assembly' && object.assembly_emphasis === 0">
                Show as a regular expanded assembly
              </template>
              <template v-else-if="object.type === 'assembly' && object.assembly_emphasis === 1">
                Show as an emphasized expanded assembly
              </template>

              <template v-else-if="object.type !== 'assembly' && object.cost_type_emphasis === 0">
                Show as a regular item
              </template>
              <template v-else-if="object.type !== 'assembly' && object.cost_type_emphasis === 1">
                Show as an emphasized item
              </template>
            </strong>

            <div>
              <btn
                rounded
                class="more px-0 p-2 lg mr-1"
                :class="
                  (object.type === 'assembly' && object.assembly_emphasis <= -3) ||
                  object.cost_type_emphasis <= -1
                    ? 'muted'
                    : 'info'
                "
                :disabled="
                  (object.type === 'assembly' && object.assembly_emphasis <= -3) ||
                  object.cost_type_emphasis <= -1
                "
                @click="lessEmphasis"
              >
                <font-awesome-icon icon="minus" />
              </btn>
              <btn
                rounded
                class="more px-0 p-2 lg"
                :class="
                  object.assembly_emphasis >= 1 || object.cost_type_emphasis >= 1 ? 'muted' : 'info'
                "
                :disabled="object.assembly_emphasis >= 1 || object.cost_type_emphasis >= 1"
                @click="moreEmphasis"
              >
                <font-awesome-icon icon="plus" />
              </btn>
            </div>
          </card-list-field>

          <card-list-field v-if="object.type === 'assembly'">
            <span>
              Show itemized pricing?
              <br />
              <small class="text-muted">
                If you turn this off, the prices of items inside this assembly will not be shown.
              </small>
            </span>

            <toggle
              @input="(v) => setShowItemizedPrices(v)"
              :value="object.assembly_show_itemized_prices"
            />
          </card-list-field>

          <card-list-field>
            <span> Optional item? </span>

            <toggle v-model="optional" />
          </card-list-field>
        </card-list>
      </card-section>

      <card-section class="sm">
        <template #label>Pictures/documents</template>
        <file-list class="left" :highlightFirst="true" :idList="true" v-model="fileIds" />
      </card-section>
      <card-section class="sm">
        <template #label>Vendor-supplied files + images</template>
        <file-list class="left" :highlightFirst="true" v-model="images" />
      </card-section>

      <card-section class="sm">
        <template #label>Optional/suggested upgrades</template>
      </card-section>
    </drop>

    <mini-modal
      size="sm"
      :width="500"
      v-if="object.type === 'cost_item' && editable && showMiniModal"
      ref="itemEditor"
    >
      <cost-item-body
        @close="() => $refs.itemEditor.close()"
        :store="store"
        :reference="object.refId"
      />

      <btn class="block xl square btn" @click="() => $refs.itemEditor.close()"> Close </btn>
    </mini-modal>
  </div>
</template>

<script>
import AddonsList from './QuotePresentationAddonList-classic.vue'
import CostItemBody from '../../bodies/CostItem.vue'
import CostItemVariationSelector from '../item/CostItemVariationSelector.vue'
import ImageGallery from '../../items/ImageGallery.vue'
import SuggestionButton from '../../ui/SuggestionButton.vue'
import PresentationCostItemMixin from './PresentationCostItemMixin'
import TranslationMixin from './languages/TranslationMixin'
import QuotePresentationDimensions from './QuotePresentationDimensions.vue'
import LoadingIndicator from '../../ui/LoadingIndicator.vue'
import Linkify from '../../ui/Linkify.vue'
import PresentationItemMixin from './PresentationItemMixin'
import QuotePresentationInputs from './languages/QuotePresentationInputs.vue'
import PresentationProperties from './PresentationProperties.vue'

/**
 * This item acts as an assembly that is mocking an item,
 * as well as a cost_item
 */
export default {
  name: 'QuotePresentationItemCostItem',
  mixins: [PresentationCostItemMixin, TranslationMixin, PresentationItemMixin],
  emits: ['expand'],
  data() {
    return {
      viewed: null,
      showUpgrades: false,
      showAlternates: true,
      uid: c.uniqueId(),
      showMiniModal: 0,
      editorRequested: 0,
      truncate: true,
      isVisible: 0,
      isPinned: false,
      hideExtras: false,
      viewFull: false,
      loading: 0
    }
  },
  watch: {
    editable(b) {
      if (!b) this.editorRequested = 0
      // destroy drop each tiem
    },
    isPinned(a, b) {
      if (a && !b) {
        this.hideExtras = true
      }

      if (!a && b) {
        this.hideExtras = false
      }
    }
  },
  computed: {
    isVariation() {
      return this.object.cost_type_is_variation_parent
    },
    depthMargin() {
      return `${this.depth * 3.15}em`
    },
    headingStyle() {
      if (!this.isVariation) {
        return {
          position: 'static'
        }
      }
      const style = {
        top: '-1px', // `calc(${this.depthMargin} + 1em)`,
        paddingTop: `calc(${this.depthMargin} + ${this.depth - 1}px)`,
        marginTop: `calc(-${this.depthMargin} - ${this.depth + 1}px)`,
        zIndex: 1
      }

      // if (this.showProperties) {
      //   style.boxShadow = 'none !important';
      // }

      return style
    },
    truncateAmt() {
      return /sm|xs/.test(this.$store.state.session.deviceSize) ? 40 : 70
    },
    visibleProperties() {
      return this.properties.filter((attribute) => {
        return (
          attribute && Array.isArray(attribute) && attribute[0] && typeof attribute[0] === 'string'
        )
      })
    }
  },
  asyncComputed: {
    possDimensions: {
      async get() {
        return this.$store.dispatch('Dimension/getPossibleDimensions')
      },
      default: () => ({})
    }
  },
  methods: {
    expand() {
      if (!this.expandableLocal) return

      this.$emit('expand')
    },
    visibilityHandler(isVisible) {
      this.isVisible = isVisible
    },
    openEditor() {
      this.editorRequested = 1
      this.$nextTick(() => this.$refs.editor.open())
    },
    editCosts() {
      this.showMiniModal = 1
      this.$refs.editor.close()
      this.$nextTick(() => {
        this.$refs.itemEditor.open()
      })
    },
    saveEmphasis() {
      const saveType = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      const idField = `${saveType}_id`
      const emph = `${saveType}_emphasis`
      return this.object[idField]
        ? this.$store.dispatch(`${c.titleCase(saveType)}/partialUpdate`, {
            go: false,
            alert: false,
            selected: [
              {
                type: saveType,
                [idField]: this.object[idField],
                [emph]: this.$store.state[this.store].normalized[this.object.refId][emph]
              }
            ]
          })
        : Promise.resolve()
    },
    moreEmphasis() {
      return this.$store
        .dispatch(`${this.store}/addEmphasis`, {
          refId: this.object.refId
        })
        .then(() => this.saveEmphasis())
    },
    lessEmphasis() {
      return this.$store
        .dispatch(`${this.store}/removeEmphasis`, {
          refId: this.object.refId
        })
        .then(() => this.saveEmphasis())
        .then(() =>
          (this.object.type === 'assembly' && this.object.assembly_emphasis <= -2) ||
          (this.object.type !== 'assembly' && this.object.cost_type_emphasis <= 0)
            ? this.$store.dispatch('alert', {
                message: 'Bundled item, check the bottom of the list for bundled items.'
              })
            : Promise.resolve()
        )
    },
    async removeSelf() {
      this.$store.dispatch('addLoading')

      await this.$store.dispatch('Quote/toggleIncluded', {
        included: 0,
        store: 'Quote',
        explicit: true,
        refId: this.object.refId
      })

      this.$store.dispatch('removeLoading')
    },
    async addSelf() {
      this.$store.dispatch('addLoading')

      await this.$store.dispatch('Quote/toggleIncluded', {
        included: 1,
        store: 'Quote',
        explicit: true,
        refId: this.object.refId
      })

      this.$store.dispatch('removeLoading')
    }
  },
  components: {
    PresentationProperties,
    QuotePresentationInputs,
    LoadingIndicator,
    QuotePresentationDimensions,
    SuggestionButton,
    ImageGallery,
    CostItemVariationSelector,
    AddonsList,
    CostItemBody,
    Linkify
  },
  props: {
    depth: {
      default: 1
    },
    object: {
      type: Object,
      required: true
    },
    deselectOnDestroy: {
      default: false
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    store: {
      required: true
    },
    expandable: {
      default: false
    },
    /**
     * If this cost item is in an assembly
     * marked to be shown as 'exploded', which means
     * that all the contents of the assembly are taken
     * out of the assembly container
     * and appear as though they are no longer in the
     * assembly, the item prices, costs and quantities need
     * to be multiplied by the quantity of of the container assembly
     *
     * for example, say you have a bathrom assembly with quantity: 2,
     * with the container there we can see that we have 2x everything
     * that is inside the containre.  Maybe there are 4 potlights in
     * the bathroom assembly, since we have 2 bathrooms, we know we have a total
     * of 2x4 potlights or 8 potlights.
     *
     * If we take the container away, the item would normally still show
     * 4x potlights.  We would never know that the actaul count and price is 2x that
     * So, to get around that, we need to pass an artificialMultiplier that multiplies
     * all the figures by the quantity of the (now removed) assembly container.
     *
     * This is all just for presentation purposes, the actual quote still has the container,
     * it just appears to be gone here.
     *
     * We also must pass this down indefinitely to all subsequent child items and assemblies.
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    }
  },
  beforeMount() {},
  mounted() {
    if (!this.isVariation) return
    const el = this.$refs.floater // this.$refs.variationsContainer;
    const fromTop = this.depth * 56 + this.$refs.floater.offsetHeight + 100
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          this.isPinned = true
        } else {
          this.isPinned = false
        }
      },
      {
        rootMargin: `${fromTop}px`,
        // root: this.$refs.floater,
        // document.querySelector('[reference="as_hcfp5xkq_bSmC3A5ELkGm"]'),
        threshold: [0, 1]
      }
    )
    observer.observe(el)
  },
  beforeUnmount() {}
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.presentation-cost-item-container {
  //background: $item-background;
  // border: 2px 0px 0px 1px !important;
  // overflow: hidden;
  //border-top-left-radius: $item-border-radius !important;
  //border-bottom-left-radius: $item-border-radius !important;
  // perspective: 30em;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &:last-child {
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
  }

  padding: 1rem;
  padding-top: 2em;
  //row-gap: 1rem;
  > :not(:first-child) {
    margin-top: 1rem !important;
  }

  .cost-item-properties {
    position: relative;
    padding-left: 1em;
    //row-gap: 1rem;
    //row-gap: 1rem;
    > :not(:first-child):not(.cost-item-desc) {
      margin-top: 1rem !important;
    }
    display: flex;
    flex-direction: column;
    flex: 1 100%;

    .cost-item-name {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }

  &.cost-item-upgrade {
    padding-bottom: 0 !important;
  }

  .cost-item-name,
  .cost-item-desc,
  .cost-item-attributes,
  .cost-item-pictures,
  .cost-item-footer,
  .cost-item-variations {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    //row-gap: 1rem;
    margin-top: 1rem;
    > :not(:first-child) {
      margin-top: 1rem !important;
    }
  }

  .cost-item-name {
    margin-top: 0 !important;
  }

  > :not(:first-child).cost-item-desc {
    margin-top: 0.5em !important;
  }

  .cost-item-floating {
    pointer-events: none;
    > * {
      pointer-events: all;
    }

    .cost-item-floating--inner {
      background: $cool-gray-600;
      padding: 0.5em 0;

      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
      .bottom {
      }
    }
  }

  > .cost-item-name.condensed {
    padding-left: 0 !important;
    padding-right: 1rem !important;
    > .cost-item-totals {
      margin-right: 0 !important;
    }
  }

  .cost-item-variations {
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 3rem);
    max-width: calc(100% + 3rem);

    padding: 0 1rem;

    .variation-selector--type {
      padding-left: 1rem;
    }

    .addon-tag {
      background: $deep-red-800;
      color: $flame-white;
      padding: 0.15em 1em 0.15em 0.5em;
      border-radius: 1em;
      width: fit-content;
      margin-left: auto;
      margin-bottom: 0.2em;
      margin-top: -0.5em;
      margin-right: -2em;
    }
  }

  .cost-item-selections {
    padding-left: 2em;
  }

  .cost-item-attributes {
    flex-direction: column;
    font-weight: 500;
    margin-top: 0 !important;
  }

  .cost-item-footer {
    justify-content: flex-end;
    align-items: center;
  }

  .cost-item-totals {
    border-radius: 1.5em;
    padding: 0.5em 1em;
    background: $cool-gray-300;
    color: $pitch-black;
    font-weight: bold;
    font-size: 0.9em;
  }

  .cost-item-name {
    font-size: 1em;
    line-height: 1;
    align-items: center;
    height: 2em;
    font-weight: bold;
    cursor: pointer;
    padding-left: 2rem;
    margin-left: 0;
    //column-gap: 0.5rem;
    > * {
      margin-right: 0.5rem !important;
    }
    > :not(:first-child) {
      margin-left: 0.5rem !important;
    }
    //row-gap: 1rem;
    > :not(:first-child) {
      margin-top: 0 !important;
    }
  }

  &.cost-item-expandable {
    .cost-item-name {
      padding-left: 0;
    }
  }

  .cost-item-desc {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.5;
    color: $cool-gray-700 !important;
    white-space: pre-wrap;
    font-weight: 500;
  }

  .cost-item-pictures {
  }

  .cost-item-attributes {
    font-size: 0.8em;
    padding-left: 1.5rem;
    margin-top: 0 !important;
    ul:not(.assembly-teaser-list) {
      padding-left: 1rem;
      margin: 0;
      li {
        font-weight: normal;
        strong {
          font-weight: 500;
        }
      }
    }
  }

  .cost-item-qty {
  }
  .cost-item-cost {
    margin-right: 2em;
    color: $cool-gray-700;
    font-size: 0.9em;
    .cost-item-cost {
      color: $cool-gray-700;
    }
  }
  .cost-item-price {
  }
  .cost-item-full-width {
    width: calc(100% + 2rem) !important;
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .cost-item-full-padding {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  @media (max-width: 700px) {
    .cost-item-qty {
    }
    .cost-item-cost {
    }
    .cost-item-price {
    }
  }

  .cost-item-options {
    margin-top: 0.25em;
  }

  //&:hover {
  //  background: $item-highlight-background-color !important;
  //}

  & + .presentation-cost-item-container {
    .cost-item-inner {
      //border-top-width: $item-divider-width;
      //border-top-color: $item-divider-color;
      //border-top-style: $item-divider-style;
    }
  }

  &.cost-item-emphasis {
    $yell: $level-yellow;
    background: linear-gradient(
      0deg,
      rgba($yell, 0) 0%,
      rgba($yell, 0.7) 20%,
      rgba($yell, 0.7) 80%,
      rgba($yell, 0) 100%
    ) !important;
  }

  &.cost-item-upgrade {
    // background: $item-upgrade-background-color;
    .cost-item-upgrades {
      /* margin-top: -3em;
        background: transparent !important; */
    }
    &:hover {
      // background: darken($item-upgrade-background-color, 20%) !important;
    }
  }

  &.cost-item-expandable {
    .cost-item-properties {
      position: relative;
      display: flex;
      flex-direction: column;
      .cost-item-name {
      }
      //row-gap: 1rem;
      > :not(:first-child):not(.cost-item-desc) {
        margin-top: 1rem !important;
      }
    }
  }

  .cost-item-desc {
    margin-top: 0 !important;
  }
}

.presentation-cost-item-container {
  .cost-item-img {
    max-height: 6em;
    margin-left: 0.3rem;
    max-width: 12em;
    border-radius: 0.15em;
    cursor: pointer;
  }

  @media (max-width: 992px) {
    .cost-item-name {
      height: auto;
    }
    .cost-item-desc {
      margin-top: 1rem !important;
    }
  }

  @media (max-width: 576px) {
    .cost-item-img {
      max-width: 7em;
    }
    .cost-item-desc {
      margin-top: 0.3rem !important;
    }
  }

  .cost-item-totals {
    white-space: nowrap;
  }
}
</style>
