import Modal from './Modal.vue'

export default {
  data() {
    return {
      hasModalMixin: true,
      modalChild: null
    }
  },
  methods: {
    toggle() {
      this.modalChild.toggle()
    },
    open() {
      this.modalChild.open()
    },
    close() {
      this.modalChild.close()
    }
  },
  mounted() {
    // Get modal child,
    this.modalChild = this.$refs.modal || false

    if (this.modalChild === false) {
      throw new Error(
        `Every component given the ModalMixin requires 
        a direct child component to be a Modal or for there to be a a $refs.modal 
        pointing to a Modal component. The following component is missing that: `,
        this
      )
    }
  },
  props: {
    ...Modal.props,
    modal: {
      required: false,
      type: Object,
      validator() {
        // if (!obj.objects.length)
        // throw new Error('No objects provided for quote modal. ie: [{ quote_id: 123 }]');
        // if (!obj.objects[0].quote_id)
        // throw new Error('Object provided for quote modal does not have a quote id.
        // ie: [{ quote_id: 123 }]');
        return true
      }
    }
  },
  components: {
    Modal
  }
}
