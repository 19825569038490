import { toRefs, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export const initialState = {
  options: {}
}

// global state
let context = reactive({
  ...initialState
})

export default (customField) => {
  const store = useStore()
  const field = ref(customField)

  const addOption = async () => {
    const { object } = await store.dispatch('CustomFieldOption/buildDefaultObject')
    if (!context.options[field.value.uid]) context.options[field.value.uid] = []
    context.options[field.value.uid].push(object)
  }

  const removeOption = (index) => {
    context.options[field.value.uid].splice(index, 1)
  }

  const resetCustomField = () => {
    context.options = {}
  }

  return {
    ...toRefs(context),
    resetCustomField,
    addOption,
    removeOption
  }
}
