<template>
  <preview
    v-bind="$props"
    v-if="object"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <div>
        <div class="font-normal" v-if="serviceFee">
          {{ $f.currency(serviceFee) }}
        </div>
      </div>
    </template>
    <template #secondary>
      <div></div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewSplit',
  emits: ['click'],
  data() {
    return {}
  },
  props: {
    right: {},
    middle: {},
    object: {
      type: Object,
      required: true
    }
  },
  components: { Preview },
  computed: {
    serviceFee() {
      const foundSplits =
        this.object.aoSplits?.filter(
          (split) => split.split_type_id !== undefined && split.split_type_id === 'VPMT'
        ) || []

      if (foundSplits.length > 0) {
        const split = foundSplits[0]
        const vendorSplitAmount = split.split_amount
        return this.object.transaction_gross - vendorSplitAmount
      }

      return null
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.has-unapproved {
  background: rgba($orange-500, 0.3);
}
</style>
