export default {
  cost_item: {
    // DO nothing for these because these will be set on the fieldSetter for both of these
    // as the equation is passed through those.
    // Every other field should have the equation set in the oEquations object
    // which is the default behavior, so it doesn't need to be specified here
    // cost_item_qty_net: () => {},
    cost_item_qty_net_base: () => ({ changes: {} })
  }
}
