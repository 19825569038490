// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'permission',
  
  skipAudit: true,

  fields: {
    permission_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    permission_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    permission_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    permission_object: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    permission_action: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
