<template>
  <div v-if="!loading && totalOutstandingProjects > 0">
    <div>
      <div class="text-lg mb-1">Waiting for your review ({{ totalOutstandingProjects }})</div>
      <p class="text-sm text-gray-400 mb-4">Rate your previous experiences</p>
    </div>
    <div class="max-w-full gap-2">
      <Carousel
        :value="outstandingProjects"
        :numVisible="3"
        :numScroll="2"
        :showIndicators="false"
        :showNavigators="totalOutstandingProjects > 3"
      >
        <template #item="slotProps">
          <div>
            <div
              class="border-cool-gray-500 rounded-md border p-4"
              :class="requiresNavigation ? '' : 'mx-1'"
            >
              <div>
                <span>
                  {{ $f.truncate(slotProps.data.quoteName || 'Unnamed', 27) }}
                </span>
                <span>
                  {{ $f.currency(slotProps.data.quotePriceNet) }}
                </span>
              </div>
              <div class="flex items-center my-2">
                <Rating v-model="noRating" readonly :cancel="false" size="sm" />
              </div>
              <btn
                size="sm"
                severity="primary-black"
                ref="btn"
                @click="createNewRating({ project: slotProps.data })"
              >
                Give a rating
              </btn>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
  <div v-else>
    <Spinner :loading="loading" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, defineProps, onBeforeMount, watch } from 'vue'
import Carousel from 'primevue/carousel'

const props = defineProps({
  type: {
    type: String,
    default: 'clientRating'
  },
  ratingData: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()
const loading = ref(false)

const totalOutstandingProjects = ref(0)
const outstandingProjects = ref([])
const noRating = ref(0)

const requiresNavigation = computed(() => totalOutstandingProjects.value > 3)

const fetchOutstandingRatings = () => {
  loading.value = true

  const projects = props.ratingData.outstandingProjects.reduce((acc, project) => {
    const clientName = project.clientName
    const contractorName = project.contractorName

    if (clientName === false || contractorName === false) {
      return acc
    }

    acc.push({
      id: project.quote_id,
      companyId: project.company_id,
      clientId: project.client_id,
      clientName,
      ownerId: project.owner_id,
      contractorName,
      quoteName: project.quote_name,
      quotePriceNet: project.quote_price_net
    })

    return acc
  }, [])

  totalOutstandingProjects.value = props.ratingData.outstandingProjects.length
  outstandingProjects.value = projects

  loading.value = false
}

onBeforeMount(() => {
  if (props.ratingData !== null && props.ratingData !== undefined) {
    fetchOutstandingRatings()
  }
})

watch(
  () => props.ratingData,
  () => {
    fetchOutstandingRatings()
  },
  { deep: true }
)

const createNewClientRating = ({ project }) => {
  return store.dispatch('modal/open', {
    objects: [
      {
        companyId: project.companyId,
        creatorId: project.ownerId,
        contractorName: project.contractorName,
        clientId: project.clientId,
        clientName: project.clientName,
        quoteId: project.id,
        quoteName: project.quoteName,
        quotePriceNet: project.quotePriceNet
      }
    ],
    modal: {
      name: 'ClientRatingNew'
    }
  })
}

const createNewContractorRating = async ({ project }) => {
  return store.dispatch('modal/open', {
    objects: [
      {
        companyId: project.companyId,
        creatorId: project.clientId,
        clientName: project.clientName,
        contractorId: project.ownerId,
        contractorName: project.contractorName,
        quoteId: project.id,
        quoteName: project.quoteName,
        quotePriceNet: project.quotePriceNet
      }
    ],
    modal: {
      name: 'ContractorRatingNew'
    }
  })
}

const createNewRating = ({ project }) => {
  if (props.type === 'clientRating') {
    return createNewContractorRating({ project })
  }

  return createNewClientRating({ project })
}
</script>
