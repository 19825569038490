<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="sm"
    :mask="true"
    :isDirty="bodyIsDirty"
    :clickAway="true"
    :closeable="true"
    :expandable="false"
    :scrollable="true"
  >
    <template #body>
      <Stage :key="modal.uid" :object="object" ref="body" :go="false" />
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </Modal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
import Stage from '../bodies/Stage.vue'

export default {
  name: 'StageModal',
  components: { Stage },
  mixins: [ObjectModalMixin],
  props: {
    go: {
      default: false
    }
  }
}
</script>

<style scoped></style>
