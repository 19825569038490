<template>
  <div
    v-tooltip="'Create a task'"
    @click="createTask"
    class="flex items-center w-full h-full justify-center cursor-pointer"
  >
    <font-awesome-icon
      class="text-base hover:bg-cool-gray-100 px-0.5 py-1 rounded"
      :icon="['far', 'plus']"
    />
  </div>
</template>

<script setup>
import FontAwesomeIcon from '../../../fontAwesome'
import useGantt from '@/components/ui/gantt/Gantt'

const { gantt } = useGantt()

const props = defineProps({
  item: {
    type: Object
  },
  onCreateTask: {
    type: Function
  }
})

const createTask = () => {
  if (props.onCreateTask) {
    const parent =
      props.item.reference_type === 'stage' ? props.item : gantt.value.getTask(props.item.parent)
    // now create the task
    props.onCreateTask(parent)
  }
}
</script>
