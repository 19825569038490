<template>
  <div class="w-full min-h-[8hvh]">
    <DataTable
      v-if="!loading"
      :value="csvData"
      :responsiveLayout="'scroll'"
      class="w-full max-w-full"
    >
      <Column
        v-for="col in columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
      ></Column>
    </DataTable>
    <div class="flex flex-col justify-stretch items-stretch h-10 w-full gap-4 p-8" v-else>
      <Skeleton class="min-h-8" />
      <Skeleton class="min-h-8" />
      <Skeleton class="min-h-8" />
      <Skeleton class="min-h-8" />
      <Skeleton class="min-h-8" />
      <Skeleton class="min-h-8" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Skeleton from 'primevue/skeleton'

const props = defineProps({
  src: {
    type: String,
    required: true
  }
})

const csvData = ref([])
const columns = ref([])
const loading = ref(false)

const parseCSV = (csvText) => {
  const rows = csvText.trim().split('\n') // Split by newline
  const headers = rows[0].split(',') // Extract headers from the first row
  const data = rows.slice(1).map((row) => {
    const values = row.split(',')
    return headers.reduce((acc, header, index) => {
      acc[header] = values[index]
      return acc
    }, {})
  })

  return { headers, data }
}

onMounted(() => {
  // Fetch the CSV
  const csvUrl = props.src
  loading.value = true
  fetch(csvUrl)
    .then((response) => response.text())
    .then((csvText) => {
      const { headers, data } = parseCSV(csvText)
      csvData.value = data

      // Set column headers dynamically
      columns.value = headers.map((header) => ({
        field: header,
        header
      }))
      loading.value = false
    })
})
</script>
