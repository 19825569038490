<template>
  <div>
    <div class="mt-2 mb-2 flex items-center justify-between">
      <span class="font-medium text-sm text-pitch-black">Options</span>
      <a
        class="flex cursor-pointer hover:bg-cool-gray-100 font-medium text-pitch-black items-center text-sm p-2"
        @click="addOption"
      >
        <font-awesome-icon :icon="['far', 'plus']" class="mr-4" />
        Additional option
      </a>
    </div>

    <FieldOptionItem
      v-for="(option, index) in options[field.uid]"
      :index="index"
      :item="option"
      :field="field"
      :key="`option-${index}`"
    />
  </div>
</template>

<script setup>
import { defineProps, onMounted } from 'vue'
import useCustomField from '@/components/forms/CustomField'
import FieldOptionItem from '@/components/ui/listItem/FieldOptionItem.vue'

const props = defineProps({
  field: {
    type: Object,
    required: true
  }
})
const { options, addOption } = useCustomField(props.field)

onMounted(() => {
  // add one option by default
  if (!options.value[props.field.uid] || options.value[props.field.uid].length === 0) addOption()
})
</script>
