<template>
  <TabView @tab-change="changePaymentMethodTab">
    <TabPanel header="Credit Card">
      <div class="w-full" id="adyen-credit-card-payment-element"></div>
    </TabPanel>
    <TabPanel header="Bank Accounts">
      <div class="w-full mt-0" id="adyen-ach-payment-element">
        <div class="payment-method--action mb-3">
          <Card
            :flat="true"
            class="mb-2"
            @click="() => onSelectedBankAccount(account.id)"
            :pt="{
              body: '!p-4',
              root: '!rounded-md !border hover:border-blue-print'
            }"
            v-for="account in bankAccounts"
            :key="account.id"
          >
            <!-- selectedBankAccountId === account.id ? 'active' : '' -->
            <div class="flex items-center">
              <Checkbox
                class="radio"
                @change="() => onSelectedBankAccount(account.id)"
                :selected-value="true"
                :deselected-value="false"
              />
              <font-awesome-icon :icon="['far', 'university']" class="text-xl ml-3" />
              <span class="ml-2 flex flex-col">
                <span>XXXX {{ account.bankAccountNumber.slice(-4) }}</span>
                <small>{{ account.name }}</small>
              </span>
            </div>
          </Card>
        </div>

        <PlaidLink
          ref="plaid"
          :token="token"
          :tigger="addingNewBankAccount === true"
          :asLink="true"
          @onSuccess="onSelectNewAccount"
          @onOpen="() => (addingNewBankAccount = true)"
          @onExit="() => (addingNewBankAccount = false)"
        >
          <Card
            :class="['checkout_payment-method', addingNewBankAccount ? 'active' : '']"
            :flat="true"
            :pt="{
              body: '!p-4',
              root: '!rounded-md !border hover:border-blue-print'
            }"
          >
            <div class="flex items-center">
              <Checkbox
                class="radio mr-3"
                v-model="addingNewBankAccount"
                :selected-value="true"
                :deselected-value="false"
              />
              <font-awesome-icon class="ml-3 text-pitch-black" :icon="['far', 'university']" />
              <span class="ml-2 text-pitch-black !no-underline !text-base">
                {{ l('Add new bank account') }}
              </span>
            </div>
          </Card>
        </PlaidLink>
        <Card
          v-if="newBankAccount"
          :flat="true"
          :pt="{
            body: '!p-4',
            root: '!rounded-md !border !border-pitch-black'
          }"
        >
          <BankAccountPaymentMethod
            :hideRadio="true"
            :hideDelete="true"
            :hideDefault="true"
            :hideIcon="false"
            :method="newBankAccount"
          />
        </Card>
      </div>
    </TabPanel>
  </TabView>
</template>

<script setup>
import { ref } from 'vue'
import useTranslation from '@/components/composables/Translation'
import usePayment from '../composables/Payment'
import usePaymentMethod from '../composables/PaymentMethod'
import PlaidLink from '../payments/plaid/PlaidLink.vue'
import BankAccountPaymentMethod from '../payments/paymentMethods/BankAccountPaymentMethod.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

const paymentMethodTab = ref('card')

const { l } = useTranslation()

const { token } = usePayment()

const {
  addingNewBankAccount,
  onSelectNewAccount,
  newBankAccount,
  selectedPaymentType,
  bankAccounts,
  onSelectedBankAccount
} = usePaymentMethod()

const changePaymentMethodTab = ({ index }) => {
  let method = 'card'
  if (index === 1) method = 'bank-account'
  paymentMethodTab.value = method
  selectedPaymentType.value = method
}

selectedPaymentType.value = paymentMethodTab.value
</script>

<style lang="scss">
#adyen-ach-payment-element {
  .plaid-link {
    @apply w-full;
  }
}
#adyen-credit-card-payment-element {
  .adyen-checkout-form-instruction {
    display: none !important;
  }

  .adyen-checkout__payment-method--selected {
    @apply bg-surface-100 rounded-md;
  }

  .adyen-checkout__payment-method__name_wrapper {
    @apply text-base text-pitch-black;
  }
}
</style>
