<template>
  <div
    :class="[
      loading
        ? 'text-surface-500 bg-surface-200 hover:text-surface-500 hover:bg-surface-200 !cursor-default'
        : '',
      muted && !loading ? 'text-pitch-black bg-surface-200 hover:bg-surface-300' : '',
      !muted && !loading ? 'text-level-yellow bg-pitch-black hover:bg-cool-gray-900' : '',
      'flex justify-center px-10 py-3 w-full rounded transition cursor-pointer',
      'text-sm sm:text-xl'
    ]"
    @click="onClick"
  >
    <div class="flex items-center select-none">
      <font-awesome-icon v-if="leftArrow" icon="chevron-left" class="text-lg mr-2" />
      {{ this.text }}
      <font-awesome-icon v-if="rightArrow" icon="chevron-right" class="text-lg ml-2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntakeButton',
  data() {
    return {
      loading: false
    }
  },
  props: {
    text: {
      type: String,
      default: 'Continue'
    },
    leftArrow: {
      type: Boolean,
      default: false
    },
    rightArrow: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.loading) return
      if (this.showLoading) this.loading = true
      this.$emit('click')
    },
    doneLoading() {
      this.loading = false
    }
  }
}
</script>
