// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'user_role',
  
  skipAudit: true,

  fields: {
    user_role_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    user_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    role_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'role',
    },
    role_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    role_is_override: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_is_custom: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_is_generic: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
