<template>
  <div>
    <div
      class="flex flex-col mx-4 mb-6"
      v-for="filter in visibleAggregationsSchema"
      :key="filter.key"
    >
      <div class="text-md font-medium mb-1">
        {{ filter.schema.title }}
      </div>

      <div v-for="(bucket, index) in filteredBuckets(filter)" :key="`${filter.key}-${index}`">
        <div
          v-if="
            filter.key in value &&
            bucket.doc_count > 0 &&
            (bucket.key !== 'NULL' || filter.schema.includeUnknown) &&
            (showMore.includes(filter.key) || index < 3)
          "
          class="flex flex-row items-center justify-between"
        >
          <div class="flex flex-row gap-3 mb-2">
            <Checkbox
              :value="bucket.filter || false"
              @input="(b) => checkBucket(filter.key, index)"
            />
            <template v-if="'key' in bucket && (!filter.schema || !filter.schema.key)">
              {{
                !bucket || !bucket.key || bucket.key === 'NULL'
                  ? filter.schema.includeUnknown
                  : bucket.key
              }}
            </template>
            <template v-if="'key' in bucket && filter.schema && filter.schema.key">
              {{ filter.schema.key(bucket, index) }}
            </template>
            <template v-else-if="bucket.to || bucket.from">
              <template v-if="bucket.from">
                {{ $f.number(bucket.from) }}
              </template>
              <template v-if="bucket.to && bucket.from"> to </template>
              <template v-if="!bucket.from"> 0 to </template>
              <template v-if="bucket.to">
                {{ $f.number(bucket.to) }}
              </template>
              <template v-if="!bucket.to"> + </template>
            </template>
          </div>
          <span class="badge sm default ml-1">{{ bucket.doc_count }}</span>
        </div>
      </div>
      <a
        class="text-cement-800 hover:text-cement-700 transition cursor-pointer"
        @click="toggleShowMore(filter.key)"
        v-if="shouldShowMore(filter)"
      >
        <p class="text-sm">Show {{ remainingBucketCount(filter) }} more..</p>
      </a>
    </div>
  </div>
</template>

<script>
const getBucketsFromAgg = (agg) => {
  const isArray = Array.isArray(agg.buckets)
  return [isArray ? agg.buckets : Object.values(agg.buckets), Object.keys(agg.buckets), isArray]
}

export default {
  name: 'TraverseRefinementFilters',
  emits: ['input'],

  data() {
    return {
      showMore: []
    }
  },
  computed: {
    visibleAggregationsSchema() {
      return Object.entries(this.aggregationsSchema)
        .filter(([key, schema]) => {
          return (
            key &&
            schema &&
            (schema.alwaysShow ||
              (key in this.value &&
                (getBucketsFromAgg(this.value[key])[0].length > 1 ||
                  getBucketsFromAgg(this.value[key])[0].some((b) => b.filter))))
          )
        })
        .map(([key, schema]) => {
          const filterValue = this.value[key]
          return { key, schema, filterValue }
        })
    },
    filteredBuckets() {
      return (filter) => {
        const { buckets } = filter.filterValue
        const isShowMore = this.showMore.includes(filter.key)
        if (isShowMore) {
          return Array.isArray(buckets) ? buckets : Object.values(buckets)
        } else {
          const slicedBuckets = Array.isArray(buckets)
            ? buckets.slice(0, 3)
            : Object.values(buckets).slice(0, 3)
          return slicedBuckets.length ? slicedBuckets : []
        }
      }
    }
  },
  methods: {
    getBucketsFromAgg,
    checkBucket(aggKey, bucketKey) {
      const aggs = _.imm(this.value)

      const bucket = aggs[aggKey].buckets[bucketKey]
      const current = 'filter' in bucket && bucket.filter

      bucket.filter = !current

      aggs[aggKey].buckets[bucketKey] = bucket

      this.commitValue(aggs)
    },

    commitValue(aggs) {
      this.$emit('input', aggs)
    },
    shouldShowMore(filter) {
      const buckets = filter.filterValue.buckets
      const bucketCount = Array.isArray(buckets) ? buckets.length : Object.keys(buckets).length
      return !this.showMore.includes(filter.key) && bucketCount > 3
    },
    remainingBucketCount(filter) {
      const buckets = filter.filterValue.buckets
      const bucketCount = Array.isArray(buckets) ? buckets.length : Object.keys(buckets).length
      return Math.max(bucketCount - 3, 0)
    },
    toggleShowMore(key) {
      const index = this.showMore.indexOf(key)
      if (index !== -1) {
        this.showMore.splice(index, 1)
      } else {
        this.showMore.push(key)
      }
    }
  },

  props: {
    aggregationsSchema: {
      type: Object,
      required: true
    },

    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.trf--container {
  margin-top: 1em;

  .trf--category {
    margin-top: 2em;
  }
  .trf--title {
    font-weight: bold;
    font-size: 0.9em;
    margin-bottom: 1em;
    margin-top: 2.5em;
  }
  .trf--filter-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em;
  }
  a.trf--showmore {
    font-weight: bold;
    font-size: 0.95em;
    color: $blue-print-700 !important;
    margin-left: 3em;
  }
}
</style>
