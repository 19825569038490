<script setup>
import { defineProps, toRefs, computed } from 'vue'
import GlobalSearchItem from './GlobalSearchItem.vue'
import useSearch from '@/components/composables/Search'

const props = defineProps({
  group: {
    type: Object
  },
  title: {
    type: String
  }
})

const { searchTypes } = useSearch()

const { group, title } = toRefs(props)

const sections = computed(() =>
  Object.keys(group.value).filter((type) => group.value[type].length > 0)
)
const hasResults = computed(() => sections.value && sections.value.length > 0)
</script>
<template>
  <section>
    <p class="my-2 text-xl font-semibold" v-if="title && hasResults">
      {{ title }}
    </p>
    <div class="pb-2 mb-2" v-for="type in sections" :key="type">
      <template v-if="group[type]">
        <p class="font-semibold text-cool-gray-800 mb-2 block">
          {{ $f.capitalize(type) }}{{ group[type].length > 1 ? 's' : '' }}
        </p>
        <template v-for="result in group[type]" :key="result.id">
          <GlobalSearchItem :result="result" :type="type" :searchTypes="searchTypes" />
        </template>
      </template>
    </div>
  </section>
</template>
