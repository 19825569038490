import Helpers from '../../../imports/api/Helpers'
import PDFGenerator from '../mixins/PDFGenerator'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'
import PayfacMixin from '../payments/PayfacMixin'

export default {
  data() {
    return {
      itemMarginTop: 0,
      headerHeight: null,
      messageBlockHeight: null,
      marginTop: 0,
      marginX: 24,
      marginY: 24,
      maxWidth: 0.3,
      quoteNormalized: {},
      lineItems: []
    }
  },

  mixins: [PDFGenerator, TranslationMixin, PayfacMixin],

  computed: {
    headerArrayofText() {
      const marginTop = this.itemMarginTop + 10
      const headerArrayofText = [
        {
          t: 'h2',
          text: this.$t('Invoice breakdown'),
          x: this.pageMarginX,
          y: this.itemMarginTop,
          align: 'left'
        },
        { t: 'h5', text: this.$t('Name'), x: this.pageMarginX, y: marginTop, align: 'left' },
        { t: 'h5', text: this.$t('Price'), x: this.alignRight, y: marginTop, align: 'right' }
      ]
      return headerArrayofText
    },
    shouldShowInvoiceItems() {
      return this.aoItems && this.aoItems.length && this.oSettings.showItems
    },
    isInvoiceOwner() {
      return Number(this.$store.state.session.user.user_id) === Number(this.invoice_creator)
    },
    company() {
      return this.$store.state.session.company
    },
    isServiceFee() {
      return this.invoice_service_fee_net && this.invoice_service_fee_net
    }
  },

  watch: {
    invoice() {
      if (this.invoice && this.invoice.invoice_id) {
        this.renderDoc()
      }
    }
  },

  methods: {
    getUnitofMeasure(item) {
      return Helpers.getMeasureForUnitOfMeasure(item.unit_of_measure_id)
        ? Helpers.getMeasureForUnitOfMeasure(item.unit_of_measure_id)
        : ''
    },
    async renderDoc() {
      this.initDoc()
      this.page = 1
      await this.createHeader()
      if (this.invoice.oClient) this.createContactInfo()
      this.createTotalsInfo()
      await this.createItemizedBreakdown()
      this.createMessageBlock()
    },
    createNewPage() {
      this.doc.addPage()
      this.itemMarginTop = this.pageMarginY
      this.marginTop = this.pageMarginY
    },
    createItemizedBreakdownHeader(arrayOfTextObjects) {
      arrayOfTextObjects.forEach((value) => {
        this.txt(value.t, value.text, value.x, value.y, value.align)
      })
    },
    createLineItemPath(assemblyPath) {
      this.itemMarginTop = this.itemMarginTop + 10
      const path = assemblyPath.join(' \u2192 ')
      this.txt('small', path, this.pageMarginX, this.itemMarginTop, 'left', [128, 128, 128])
    },
    createLineItem(item, itemMarginTop) {
      this.txt('b', item.cost_type_name, this.pageMarginX, itemMarginTop, 'left', [0, 0, 0])
      if (item.item_type !== 'assembly')
        this.txt(
          'p',
          `$${this.currencyFormat(item.cost_item_price_net)}`,
          this.alignRight,
          itemMarginTop,
          'right'
        )
      this.itemMarginTop = itemMarginTop
    },
    async createItemizedBreakdown() {
      if (!this.shouldShowInvoiceItems) return
      // fetch the quote
      this.maxWidth = this.pageWidth - 20
      const { payload } = await this.$store.dispatch('ajax', {
        path: `Quote/fetchNormalized/${this.quote_id}`
      })
      this.quoteNormalized = payload.normalized
      this.lineItems = []
      Object.keys(this.quoteNormalized).forEach((ref) => {
        if (this.aoItems.filter((i) => i.item_id === this.quoteNormalized[ref].item_id).length) {
          this.lineItems.push(this.quoteNormalized[ref])
        }
      })
      this.createNewPage()
      this.createItemizedBreakdownHeader(this.headerArrayofText)
      this.itemMarginTop = this.itemMarginTop + 10
      this.lineItems.map(async (item) => {
        const assemblyPath = item.asAssemblyPath ? item.asAssemblyPath.slice(0, -1) : []
        if (assemblyPath.length > 0) {
          this.createLineItemPath(assemblyPath)
          this.itemMarginTop = this.itemMarginTop + 6
        } else {
          this.itemMarginTop = this.itemMarginTop + 10
        }
        this.createLineItem(item, this.itemMarginTop)
        if (this.itemMarginTop + this.marginTop > this.pageHeight - 5) this.createNewPage()
      })
    },
    async createHeader() {
      this.headerHeight = this.height / 5

      let logo = c.link(
        `file/view/${this.$store.state.session.company.company_logo_file_id}`,
        {},
        true,
        _.getStorage('scope')
      )
      let logoDim = {}
      try {
        logoDim = await this.getImageDimWithMax(logo, this.pageWidth * 0.75, 30)
      } catch (e) {
        logo = null
      }

      if (logo) {
        const image = await this.convertImage(logo).catch((err) => console.error(err))
        this.doc.addImage(image, 'JPEG', this.pageMarginX, this.pageMarginY, logoDim.w, logoDim.h)
      }
      const paidDate = this.invoice.invoice_time_paid
      this.txt(
        'h1',
        this.$t(paidDate ? 'Receipt' : 'Invoice'),
        this.alignRight,
        this.headerHeight / 2,
        'right'
      )
    },

    friendlyNameTaxOn(taxOn) {
      if (taxOn === 'all') return this.l('prices')
      if (taxOn === 'cost') return this.l('costs')
      if (taxOn === 'profit') return this.l('profits')

      return taxOn
    },
    friendlyNameTaxType(taxType) {
      if (taxType === 'ihlt') return this.$t('labor')
      if (taxType === 'mt') return this.$t('materials')
      if (taxType === 'slt') return this.$t('subcontracting')

      return this.l('general')
    },

    createContactInfo() {
      // client info
      const {
        company_name: companyName,
        user_suite: clientSuite,
        user_address: clientAddress,
        user_city: clientCity,
        user_prov: clientProv,
        user_postal: clientPostal,
        user_fname: userFname,
        user_lname: userLname
      } = this.invoice.oClient

      // Company
      const companyText =
        `${this.company.company_suite || ''} ${this.company.company_address || ''} ${this.company.company_suite || this.company.company_address ? '\n' : ''}` +
        `${this.company.company_city || ''} ${this.company.company_postal || ''} ${this.company.company_city || this.company.company_postal ? '\n' : ''}` +
        `${this.company.company_phone || ''} ${this.company.company_phone ? '\n' : ''}` +
        `${this.company.company_email || ''} ${this.company.company_email ? '\n' : ''}` +
        `${this.company.company_website || ''} ${this.company.company_website ? '\n' : ''}` +
        `${this.company.company_business_number ? '#' : ''}${this.company.company_business_number || ''}`

      this.callout(
        this.company.company_name,
        companyText,
        this.pageMarginX,
        this.headerHeight,
        undefined,
        undefined,
        undefined,
        'h3',
        (this.width - this.pageMarginX) / 2
      )
      this.setName(`#${this.invoice.invoice_id} - ${this.invoice.quote_name}`)

      // Client
      const clientCompanyText = companyName ? `${companyName} \n` : ''
      const userName =
        userFname || userLname ? `${this.l('Attn')}: ${userFname || ''} ${userLname || ''}` : ''
      const clientAddressText =
        clientSuite || clientAddress ? `${clientSuite || ''} ${clientAddress || ''} \n` : ''
      const client = `${clientCompanyText} ${userName} \n ${clientAddressText} ${clientCity || ''} ${clientProv || ''} ${clientPostal || ''}`

      this.callout(this.l('Client'), client, this.alignRight, this.headerHeight, 'right')
      const clientHeight =
        this.getTextHeight(this.l('Client'), 'h3') + this.getTextHeight(client, 'p')

      // Project
      this.callout(
        this.l('Project'),
        `#${this.invoice.quote_id} \n${this.invoice.quote_name}`,
        this.alignRight,
        this.headerHeight + clientHeight * 2,
        'right'
      )
    },

    createMessageBlock() {
      const startYMessage = this.height - 40

      if (!this.invoice_desc) return

      this.txt(
        'p',
        this.invoice_desc,
        this.pageMarginX,
        startYMessage,
        'left',
        [0, 0, 0],
        this.pageWidth
      )
    },

    percentage(value) {
      return this.$f.percentage(value)
    },

    number(value) {
      return this.$f.number(value)
    },
    currencyFormat(value) {
      return this.$f.currency(value)
    },

    date(value) {
      return this.$f.date(value)
    },

    capitalize(value) {
      return this.$f.capitalize(value)
    },

    createTotalsInfo() {
      if (this.setDefaults) this.setDefaults()
      const isServiceFee = this.isServiceFee
      const startY = this.messageBlockHeight ? this.messageBlockHeight : this.height / 2 - 20
      const textStartY = startY + 7
      const lines = {
        one: textStartY + this.lineHeight,
        two: textStartY + this.lineHeight * 2,
        three: textStartY + this.lineHeight * 3,
        four: textStartY + this.lineHeight * 4,
        five: textStartY + this.lineHeight * 5,
        six: textStartY + this.lineHeight * (isServiceFee ? 6 : 8),
        seven: textStartY + this.lineHeight * (isServiceFee ? 8 : 9),
        eight: textStartY + this.lineHeight * (isServiceFee ? 9 : 10)
      }

      const invoiceGross = this.invoice.invoice_gross
      const invoiceTotal = isServiceFee ? invoiceGross + this.invoice_service_fee_net : invoiceGross
      const paidDate = this.invoice.invoice_time_paid
      const leftCol = this.pageMarginX
      const type = paidDate ? this.$t('Receipt') : this.$t('Invoice')
      const generateLine = (val) => textStartY + this.lineHeight * val

      this.txt('h2', this.$t('Project summary'), leftCol, startY, 'left')

      const titleSpacing = 35
      const valueLX = leftCol + titleSpacing + 10

      let line = generateLine(1)
      this.txt('b', `${this.$t('Total project value')}:`, leftCol, line, 'left')
      this.txt(
        'p',
        this.$f.currency(this.invoice.oQuote.quote_price_gross || ''),
        valueLX,
        line,
        'left'
      )

      line = generateLine(2)
      this.txt('b', `${this.$t('Payments received')}:`, leftCol, line, 'left')
      this.txt('p', this.$f.currency(this.invoice.oQuote.quote_paid_gross), valueLX, line, 'left')

      line = generateLine(3)
      this.txt('b', `${this.$t('Invoices payable')}:`, leftCol, lines.three, 'left')
      this.txt(
        'p',
        this.$f.currency(this.invoice.oQuote.quote_invoiced_gross),
        valueLX,
        line,
        'left'
      )

      line = generateLine(4)
      this.txt('b', `${this.$t('Not yet due')}:`, leftCol, lines.four, 'left')
      this.txt(
        'p',
        this.$f.currency(this.invoice.oQuote.quote_uninvoiced_gross),
        valueLX,
        line,
        'left'
      )

      line = generateLine(1)
      const titleRX = this.alignRight - titleSpacing
      this.txt('b', `${this.capitalize(this.l(type))}:`, titleRX, line, 'right')
      this.txt('p', `#${this.invoice.invoice_id}`, this.alignRight, line, 'right')

      line = generateLine(2)
      this.txt('b', `${this.capitalize(this.l(`${type} date`))}:`, titleRX, line, 'right')
      this.txt('p', this.date(this.invoice.invoice_time) || '', this.alignRight, line, 'right')

      line = generateLine(3)
      this.txt('b', `${this.$t('Due date')}: `, titleRX, line, 'right')
      this.txt('p', this.date(this.invoice_time_due) || '', this.alignRight, line, 'right')

      line = generateLine(4)
      this.txt(
        'b',
        `${this.capitalize(this.l(`${type} ${this.$t('subtotal')}`))}:`,
        titleRX,
        line,
        'right'
      )
      this.txt(
        'p',
        this.$f.currency(this.invoice.invoice_net) || '',
        this.alignRight,
        line,
        'right'
      )

      const taxes = this.invoice.oTaxSums
      let lineRef = 7
      if (taxes && Object.values(taxes).length > 1) {
        Object.values(taxes).forEach((tax) => {
          line = generateLine(lineRef)
          const taxText = `${this.percentage(tax.pcnt)} ${this.$t('tax')} ${this.$t('on')} ${this.friendlyNameTaxOn(tax.on)} ${this.$t('derived from')} ${this.friendlyNameTaxType(tax.type)}`
          this.txt('b', `${tax.name}:`, titleRX, line, 'right')
          this.txt(
            'p',
            this.$f.currency(tax.sum),
            this.alignRight,
            line,
            'right',
            [0, 0, 0],
            this.pageWidth * 0.6
          )
          lineRef += 1
          line = generateLine(lineRef)
          this.txt('small', taxText, titleRX, line - 1.4, 'right')
          lineRef += 1
        })
      }

      line = generateLine(lineRef)
      this.txt('b', `${this.$t('Taxes Total')}:`, titleRX, line, 'right')
      this.txt(
        'p',
        `${this.$f.currency(this.invoice.invoice_tax) || ''}`,
        this.alignRight,
        line,
        'right'
      )

      if (this.invoice.tax_name) {
        line = generateLine(lineRef + 1)
        this.txt('small', `${this.invoice.tax_name}`, titleRX, line - 1.4, 'right')
      }

      line = generateLine(lineRef + 3)
      let paidLine = generateLine(lineRef + 4)
      if (isServiceFee) {
        this.txt('b', `${this.l('Surcharge')}:`, titleRX, line, 'right')
        this.txt(
          'p',
          `${this.$f.currency(this.invoice_service_fee_net)}`,
          this.alignRight,
          line,
          'right'
        )
        line = generateLine(lineRef + 4)
        paidLine = generateLine(lineRef + 5)
      }

      const stringTotal = paidDate ? `${this.l('Total paid')}:` : `${this.$t('Total due now')}:`
      this.txt('h3', stringTotal, titleRX, line, 'right')
      this.txt('h4', `${this.$f.currency(invoiceTotal)}`, this.alignRight, line, 'right')

      if (paidDate) {
        this.txt('p', `${this.l('Paid on')}:`, titleRX, paidLine, 'right')
        this.txt('p', `${this.date(paidDate)}`, this.alignRight, paidLine, 'right')
      }
    }
  }
}
