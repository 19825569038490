<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :pt="{ content: '!bg-white', footer: '!bg-white', header: '!bg-white' }"
    :full="true"
    :isDirty="bodyIsDirty"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
    :includeGutter="true"
    :hasSidePanel="true"
    :has-header-border="true"
  >
    <template #header>
      <font-awesome-icon icon="file-invoice-dollar" />&nbsp; New Invoice
    </template>
    <template #body>
      <div class="mb-12">
        <invoice-body :isNewInvoice="true" ref="body" />
      </div>
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        :omitStyle="true"
        class="w-full text-right bg-white h-[5rem] px-4 py-4 fixed bottom-0 left-0 right-0"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import InvoiceBody from '../bodies/Invoice.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalInvoice',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { InvoiceBody },
  props: {
    type: {
      default: 'invoice'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
