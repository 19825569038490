import _ from '../Helpers'
import { getRatingById, getRatingsByClientId, searchRatings } from '@/apollo-client/services/rating'

// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
export default {
  type: 'clientRating',

  skipAudit: true,

  fields: {
    ratingId: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    quoteId: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote'
    },
    creatorId: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewRatingCreator'
    },
    clientId: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    overallRating: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewOverallRating'
    },
    description: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewRatingDescription'
    },
    timeCreated: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    timeUpdated: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    timeDeleted: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    }
  },
  generateVueActions() {
    return {
      convertAppSyncRatingFields({ dispatch }, { rating }) {
        return {
          ...rating
        }
      },
      async search({ dispatch }, { filters: preClonedFilters, searchPhrase, limit, nextToken }) {
        const filters = _.imm(preClonedFilters)
        if (filters.ClientRatingId) {
          const { ClientRatingId } = filters

          const ratingIds = ClientRatingId.split('!')
            .filter((id) => !!id && id !== '')
            .map((id) => id.replace('&&'))

          const input = {
            ratingIds,
            searchTerm: searchPhrase
          }

          const searchResult = await searchRatings({ input })
          const ratings = searchResult.nodes.map((r) => {
            return {
              ...r
            }
          })

          return {
            set: ratings,
            nextToken: null
          }
        } else if (filters.clientId) {
          const { clientId, ...rest } = filters

          return await dispatch('getRatingsByClientId', {
            clientId,
            limit,
            nextToken,
            filters: rest
          })
        } else {
          throw new Error('Invalid search parameters')
        }
      },
      async getRating({ dispatch }, { id }) {
        try {
          const rating = getRatingById({ ratingId: id })

          return {
            set: [await dispatch('convertAppSyncRatingFields', { rating })],
            nextToken: null
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch rating, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: []
          }
        }
      },
      async getRatingsByClientId(
        { dispatch },
        { clientId, limit = null, nextToken = null, filters = null }
      ) {
        try {
          const input = {
            id: clientId,
            limit,
            nextToken,
            filters: _.generateAppsyncFilterPattern(filters)
          }

          const ratings = await getRatingsByClientId({ input })

          return {
            set: await Promise.all(
              ratings.items.map(async (rating) => {
                try {
                  return await dispatch('convertAppSyncRatingFields', { rating })
                } catch (error) {
                  console.error(`Failed to convert rating fields for rating ${rating.id}: ${error}`)
                  return null // or handle the error as appropriate
                }
              })
            ),
            nextToken: ratings.nextToken,
            score: null
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch rating, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: []
          }
        }
      }
    }
  }
}
