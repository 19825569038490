import pt_PT from '../../../quote/presentation/languages/pt_PT';

export default {
  ...pt_PT,
  languageName: 'Portuguese (PT)',

  // Presentation
  'Estimate': 'Orçamento',
  'Project': 'Projeto/Obra',
  'Client': 'Cliente',
  'Call us': 'Contacte-nos',
  'Presented by': 'Apresentado por',
  'Expand': 'Expandir',
  'to see options': 'para ver as opções',
  'other customers recently upgraded their': 'outros clientes atualizaram recentemente o item',
  'other options for': 'outras opções para',
  'included': 'incluído',
  'not included': 'não incluído',
  'Other options for': 'Outras opções para',
  'Preview': 'Pré-visualização',
  'selected': 'selecionado',
  'other customers selected this recently': 'outros clientes selecionaram este item recentemente',
  'Make this selection': 'Selecione esta opção',
  'Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project': 'A disponibilidade do upgrade pode variar de acordo com as circunstâncias individuais de cada projeto/obra. As imagens são meramente indicativas podendo não corresponder integralmente ao produto',

  'Download': 'Download',
  'Subtotal': 'Subtotal',
  'Discount': 'Desconto',
  'Please review the proposal and choose accept to proceed': 'Por favor, reveja a proposta de orçamento e escolha aceitar para continuar',
  'See': 'Ver',
  'More': 'Mais',
  'Remove': 'Remover',
  'Project files': 'Ficheiros do projeto',
  'Notes': 'Notas',
  'Upgraded item': 'Item atualizado',
  'Clients address': 'Morada do cliente',
  'more general items': 'mais items gerais',
  'Upgrades subject to final approval and availability': 'Upgrades sujeitos a aprovação final e disponibilidade',
  'Cost': 'Valor',
  'Taxes': 'IVA',
  'Project total': 'Valor total',
  'Review terms and approve this': 'Rever os termos e condições e aprovar esta',
  'Review terms and approve': 'Rever os termos e condições e aprovar',
  'Request revision': 'Solicitar alteração',
  'Decline': 'Rejeitar',
  'Options available - look for the upgrades symbol': 'Opções disponíveis - procure o símbolo de upgrades',
  'Upgrades available': 'Upgrades disponíveis',
  'You agree to accept and proceed with the project as detailed': 'Aceito e pretendo prosseguir com o projeto/obra conforme aqui detalhado',
  'This version was approved on': 'Esta versão foi aprovada a',
  'This version was declined': 'esta versão foi rejeitada',
  'Terms and conditions': 'Termos e Condições',
  'In order to continue, review and accept to the terms below': 'Para continuar, reveja e aceite os termos abaixo',
  'I accept the terms and conditions': 'Aceito os termos e condições',
  'I do not accept the terms and conditions': 'Não aceito os termos e condições',
  'Thank you for booking': 'Obrigado por reservar',
  'Please wait while we upgrade your records': 'Por favor, aguarde enquanto atualizamos os seus registos',
  'Thank you': 'Obrigado',
  'Project fully booked': 'Projeto reservado',
  'Thank you for booking with us': 'Obrigado por reservar conosco',
  'We will get in touch as soon as possible to confirm details': 'Entraremos em contato o mais breve possível para confirmar os detalhes',
  'Your project is fully booked': 'O seu projeto está reservado',
  'Come back to Bolster to follow updates or to request or approve change-orders': 'Volte ao Bolster para acompanhar as atualizações ou para solicitar ou aprovar alterações',
  'See projects': 'Veja os projetos',
  'There was an error booking this project. Please try again.': 'Ocorreu um erro ao reservar este projeto/obra. Por favor, tente novamente.',

  'Electronic signature agreement': 'Acordo de assinatura eletrônica',
  'By clicking the "I accept the terms and conditions" button below, I am accepting to reserve the work as detailed and priced in this proposal by': 'Ao clicar no botão "Aceito os termos e condições" abaixo, aceito reservar a obra conforme detalhado e precificado nesta proposta por',
  '(the Contractor) and acknowledge and agree that clicking to sign this Agreement will have the same legal affect as a handwritten signature.': '(o Contratado) e reconhece e concorda que clicar para assinar este Contrato terá o mesmo efeito jurídico que uma assinatura manuscrita.',
  'The Agreements may be executed in one or more counterparts, each of which will be deemed to be an original copy and all of which, when taken together, will be deemed to constitute one and the same agreement, and will be effective when acceptance of the counterparts have been recorded electronically by both the Contractor and myself.': 'Os Contratos podem ser assinados em uma ou mais vias, cada uma das quais será considerada uma cópia original e todas as quais, quando tomadas em conjunto, serão consideradas como constituindo um e o mesmo contrato, e entrarão em vigor no momento da aceitação do as contrapartes foram registradas eletronicamente pelo Contratado e por mim.',
  'I accept that modifications that I make to the project through Bolster are subject to approval by the Contractor in order to take into consideration any existing or special conditions particular to my property, and may not be possible in all cases.': 'Aceito que as modificações que faço no projeto por meio do Bolster estão sujeitas à aprovação da Contratada, a fim de levar em consideração quaisquer condições existentes ou especiais específicas à minha propriedade, e podem não ser possíveis em todos os casos.',
  'I acknowledge that this is an agreement only between the Contractor and myself.': 'Eu reconheço que este é um acordo apenas entre o Contratado e eu.',
  'I consent to the Bolster terms and conditions and privacy policy (found at https://costcertified.cc/pub/legal) while using Bolster.': 'Eu concordo com os termos e condições e política de privacidade do Bolster (encontrados em https://costcertified.cc/pub/legal) ao usar o Bolster.',

  'Each': 'Un.',
  'each': 'Un.',
  'Sales tax': 'IVA composto',
  'Items': 'Itens',
  // Email
  'Thank you for the opportunity to provide you this': 'Obrigado pela oportunidade de lhe apresentarmos esta',
  'change-order': 'alteração à proposta de orçamento',
  'proposal': 'proposta',
  'for project': 'para projeto/obra',
  'at': 'no(a)',
  'After you review the': 'Depois de rever a',
  'and make desired selections (if applicable), please choose to accept, request an alteration or decline': 'e escolher as opções que deseja (se aplicável), por favor escolha “Rever os termos e condições e aprovar esta”, “Solicitar alteração” ou “Rejeitar”',
  'Review your': 'Reveja a sua',
  'You\'re receiving this email because you requested information form a company subscribed to': 'Recebeu este e-mail porque solicitou um orçamento a uma empresa que subscreve o',
  'You have a': 'Você tem um',
  'Your request': 'Seu pedido de',
  'to review': 'para revisar',
  'to review + approve': 'para revisar + aprovar',
  'has sent you a': 'enviou-lhe um',
  'PLEASE READ THE TERMS AND CONDITIONS AND INDICATE YOUR ACCEPTANCE BY ENTERING YOUR NAME ABOVE AND CLICKING THE "I ACCEPT THE TERMS AND CONDITIONS" BUTTON AT THE BOTTOM OF THIS CHECKOUT PAGE. ACCEPTANCE OF THE AGREEMENT MEANS THAT YOU ARE BOUND BY ITS TERMS. PLEASE RETAIN IT FOR YOUR RECORDS.': 'POR FAVOR, LEIA OS TERMOS E CONDIÇÕES E INDIQUE SUA ACEITAÇÃO INSERINDO SEU NOME ACIMA E CLICANDO NO BOTÃO "EU ACEITO OS TERMOS E CONDIÇÕES" NA PARTE INFERIOR DESTA PÁGINA DE CHECKOUT. A ACEITAÇÃO DO ACORDO SIGNIFICA QUE VOCÊ ESTÁ VINCULADO POR SEUS TERMOS. POR FAVOR, MANTENHA-O PARA SEUS REGISTROS.'
};
