<template>
  <div class="activity-version--container">
    <div class="activity-separator">
      <div class="activity-connector"></div>
    </div>

    <div class="activity-version--card">
      <font-awesome-icon icon="hdd-o" class="mr-2" />

      Backup version

      <btn-bar
        class="ml-2"
        :actions="[
          {
            title: 'Checkout this version',
            action: checkout,
            icon: 'hdd-o'
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityPost',

  props: {
    firstActivity: {
      type: Boolean,
      default: false
    },
    activity: {
      required: true
    },
    filters: {
      required: false,
      default: () => {}
    }
  },
  emits: ['versionCheckout'],

  methods: {
    checkout() {
      this.$emit('versionCheckout', {
        activity_id: this.activity.activity_id
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.activity-version--container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .activity-connector {
    width: 2px;
    height: 100%;
    background: $cool-gray-500;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 0;

    &:before {
      content: ' ';
      display: block;
      overflow: hidden;
      position: absolute;
      bottom: 0.5em;
      left: calc(-0.3em + 1px);
      width: 1px;
      height: 1px;
      background: transparent;
    }

    &:after {
      content: ' ';
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0.2em;
      left: calc(-0.3em + 1px);
      width: 1px;
      height: 1px;
      background: transparent;
    }
  }

  .activity-separator {
    height: 3em;
    position: relative;
  }

  .activity-version--card {
    background: $blue-print-700;
    color: $flame-white;
    padding: 1em;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }
}
</style>
