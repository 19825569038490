<template>
  <component
    :is="tag"
    @click="() => $emit('click')"
    class="sm mr-1"
    v-show="count !== null"
    :class="{
      info: count > 0,
      muted: count <= 0
    }"
    style="white-space: nowrap; flex-direction: row"
    v-tooltip="'This is how many assemblies this item is found inside of.'"
  >
    <font-awesome-icon :icon="['fas', 'cubes']" class="mr-1" style="top: -1px" v-if="showIcon" />
    <span>
      {{ startMessage }}
      <Count
        @count="countHandler"
        v-if="type === 'assembly'"
        :hide-on-zero="true"
        type="assembly"
        :filters="{
          quote_included_assembly_ids: id
        }"
      />
      <Count
        @count="countHandler"
        v-else
        :hide-on-zero="true"
        type="assembly"
        :filters="{
          quote_included_cost_type_ids: id
        }"
      />
      {{ type === 'assembly' ? 'other' : '' }}
      assembl{{ count > 1 || count === 0 ? 'ies' : 'y' }}
    </span>
  </component>
</template>

<script>
import SuggestionButton from '../../ui/SuggestionButton.vue'

export default {
  name: 'FoundInAssemblies',
  components: { SuggestionButton },
  emits: ['click'],
  data() {
    return {
      count: null
    }
  },
  computed: {
    startMessage() {
      return this.count > 0
        ? 'Used in'
        : `This${this.type === 'assembly' ? '' : ' item'} is not used in any`
    }
  },
  methods: {
    countHandler(num) {
      this.count = +num
    }
  },
  props: {
    type: {
      required: true
    },
    id: {
      required: true
    },
    tag: {
      default: 'SuggestionButton'
    },
    showIcon: {
      default: true
    }
  }
}
</script>

<style scoped></style>
