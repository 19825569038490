import {
  SET_COUNTERPARTY_DATA,
  SET_LIGHT_KYC_DATA,
  SET_DECISION_MAKERS_DATA
} from '@/store/mutation-types'

const state = () => ({
  counterpartyData: {},
  lightKycData: {},
  decisionMakers: {}
})

const mutations = {
  SET_COUNTERPARTY_DATA(state, { companyId, data }) {
    state.counterpartyData[companyId] = data
  },
  SET_LIGHT_KYC_DATA(state, { companyId, data }) {
    state.lightKycData[companyId] = data
  },
  SET_DECISION_MAKERS_DATA(state, { companyId, email, data }) {
    if (!state.decisionMakers[companyId]) {
      state.decisionMakers[companyId] = {}
    }
    state.decisionMakers[companyId][email] = data
  }
}

const actions = {
  setCounterpartyData({ commit }, payload) {
    commit(SET_COUNTERPARTY_DATA, payload)
  },
  setLightKycData({ commit }, payload) {
    commit(SET_LIGHT_KYC_DATA, payload)
  },
  setDecisionMakerData({ commit }, payload) {
    commit(SET_DECISION_MAKERS_DATA, payload)
  }
}

const getters = {
  getCounterpartyDataByCompanyId: (state) => (companyId) => {
    return state.counterpartyData[companyId] || null
  },
  getLightKycDataByCompanyId: (state) => (companyId) => {
    return state.lightKycData[companyId] || null
  },
  getDecisionMakerDataByEmail: (state) => (companyId, email) => {
    if (!state.decisionMakers[companyId]) {
      return null
    }
    return state.decisionMakers[companyId][email] || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
