<template>
  <Container v-if="!bodyLoading">
    <CardSection>
      <template #title> {{ location_id ? '' : 'New ' }}{{ typeName }} </template>

      <CardList>
        <CardListField>
          <span>{{ typeName }} Name</span>

          <Field
            :validate="{ required: true }"
            v-model="location_name"
            schema="location:location_name"
          />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection>
      <template #title>Users in group</template>
      <template v-if="location_id">
        <Info class="sm">
          <p>
            When adding/removing users in a group, it can take up to a few minutes for group filters
            to update for pipeline, clients, proposals etc.
          </p>
        </Info>
        <CardList>
          <CardListItem v-for="userId in userIds" :key="userId">
            <div class="flex justify-start items-center" style="width: 100%; flex-basis: 100%">
              <Btn rounded class="danger more px-0 p-2 mr-1" @click="removeUser(userId)">
                <font-awesome-icon icon="minus" />
              </Btn>

              <Name type="user" :id="userId" />
            </div>
          </CardListItem>
        </CardList>

        <div class="mt-2 flex justify-start items-center">
          <Choose
            :disabled="loading"
            :key="user_ids"
            placeholder="Add user"
            @input="addUser"
            :value="null"
            :return-array="true"
            schema="user:user_id"
          />
          <Spinner class="info ml-2" size="2em" :loading="loading" />
        </div>
      </template>
      <Btn class="info block round" :action="save" v-else> Save first to add users.. </Btn>
    </CardSection>

    <CardSection v-if="location_is_location">
      <template #title>Location contact</template>

      <CardList>
        <card-list-field>
          <span> Location default email </span>
          <field
            schema="location:location_email"
            :validate="{ format: 'email' }"
            v-model="company_email"
          />
        </card-list-field>
        <card-list-field>
          <span> Location default phone </span>
          <field schema="location:location_phone" format="phone" v-model="location_phone" />
        </card-list-field>
      </CardList>
    </CardSection>

    <CardSection v-if="location_is_location">
      <template #title>Location address</template>

      <CardList>
        <card-list-field>
          <span>Address</span>
          <field :validate="{ required: false }" v-model="location_address" />
        </card-list-field>

        <card-list-field>
          <span></span>
          <field v-model="location_suite" />
        </card-list-field>

        <card-list-field>
          <span>City</span>
          <field :validate="{ required: false }" v-model="location_city" />
        </card-list-field>

        <card-list-field>
          <span>State/Province</span>
          <choose
            :return-array="false"
            :allowDeselect="false"
            schema="vendor:province_id"
            :filters="{ country_id: $store.state.session.company.country_id }"
            v-model="province_id"
          />
        </card-list-field>

        <card-list-field>
          <span>Postal/Zip Code</span>
          <field v-model="location_postal" />
        </card-list-field>
      </CardList>
    </CardSection>
  </Container>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'

export default {
  name: 'BodyLocation',
  mixins: [ObjectManipulator('location', true), BodyMixin],
  methods: {
    async reloadUsers() {
      if (!this.location_id) return
      const { object: location } = await this.$store.dispatch('Location/fetch', {
        force: true,
        id: this.location_id
      })
      this.user_ids = c.makeArray(location.user_ids)
    },
    async addUser(idArray) {
      this.loading = 1
      const ids = c.makeArray(idArray)
      const usersUpdate = []
      await c.waterfall(
        ids.map((id) => async () => {
          let lids = await this.getUserLocationIds(id)

          if (lids.includes(String(this.location_id))) return

          lids.push(String(this.location_id))

          lids = c.uniq(lids)

          usersUpdate.push({
            type: 'user',
            user_id: id,
            location_ids: lids
          })
        })
      )

      await this.$store.dispatch('User/partialUpdate', {
        selected: usersUpdate
      })

      await this.reloadUsers()

      this.loading = 0
    },
    async getUserLocationIds(id) {
      const { object: user } = await this.$store.dispatch('User/resolveObject', {
        id
      })
      return _.makeArray(user.location_ids).map((lid) => String(lid))
    },
    async removeUser(id) {
      this.loading = 1
      const lids = await this.getUserLocationIds(id)
      const index = lids.indexOf(String(this.location_id))

      if (index === -1) return

      lids.splice(index, 1)

      await this.$store.dispatch('User/partialUpdate', {
        selected: [
          {
            type: 'user',
            user_id: id,
            location_ids: lids
          }
        ]
      })

      await this.reloadUsers()
      this.loading = 0
    }
  },
  props: {},
  data() {
    return {
      advanced: false,
      loading: 0
    }
  },
  computed: {
    userIds() {
      return c.makeArray(this.user_ids)
    },
    typeName() {
      return this.location_is_location ? 'Location' : 'Group'
    }
  }
}
</script>
