<script setup>
import { defineProps, ref, defineEmits, watch } from 'vue'
import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  getDenormalized: { type: Boolean, default: false }
})

const refId = ref(props.refId || props.reference)
const store = ref(props.store || props.storeName)

const isDirty = ref(null)
const changes = ref(null)
const denormalized = ref(null)

const changeHandler = (changePayload) => {
  const { isDirty: is, changeManager } = changePayload
  isDirty.value = is
  changes.value = changeManager.getChangeLogs()
  denormalized.value = props.getDenormalized
    ? {
        explicit: changeManager.getExplicitChanges(null, false),
        changes: changeManager.getChanges(false, false, true)
      }
    : {}
}

const resetHandler = (changePayload) => {
  const { changeManager } = changePayload
  isDirty.value = false
  changes.value = changeManager.getChangeLogs()
  denormalized.value = props.getDenormalized
    ? {
        explicit: changeManager.getExplicitChanges(null, false),
        changes: changeManager.getChanges(false, false, true)
      }
    : {}
}

const emit = defineEmits(['changes', 'isDirty'])

watch(changes, () => emit('changes', changes.value))
watch(isDirty, () => emit('isDirty', isDirty.value))

const { initiate } = ChangeWatcher.useChangeWatcher({
  store: store.value,
  refId: refId.value,
  changeHandler,
  resetHandler
})

initiate()
</script>

<template>
  <slot :changes="changes" :isDirty="isDirty" :denormalized="denormalized"></slot>
</template>
