import { toRefs, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import useAdyen from './Adyen'

export const initialState = {}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const store = useStore()
  const adyen = useAdyen()
  const payFacs = { ...adyen }

  const isPayfacEnabled = computed(
    () =>
      store &&
      store.state.session.company &&
      store.state.session.company.aoModules &&
      parseInt(store.state.session.company.aoModules.payments_v2, 10) === 1
  )

  const payFacService = computed(() => (isPayfacEnabled.value ? 'adyen' : 'adyen'))

  const isPaymentsOnboarded = computed(
    () =>
      store &&
      store.state.session.company &&
      store.state.session.company.counterparty_status &&
      (store.state.session.company.counterparty_status === 'a' ||
        store.state.session.company.counterparty_status === '#')
  )

  const counterpartyStatus = computed(() =>
    store ? store.state.session.company.counterparty_status : 'u'
  )

  const counterpartyCountry = computed(() =>
    store ? store.state.session.company.country_abbr : 'us'
  )

  const initializePayments = () => payFacs[`${payFacService.value}Initialize`]()

  const initializePaymentMethods = () => payFacs[`${payFacService.value}InitializePaymentMethods`]()

  const makeItemizedPayment = () => payFacs[`${payFacService.value}MakeItemizedPayment`]()

  const makeItemizedInvoice = () => payFacs[`${payFacService.value}MakeItemizedInvoice`]()

  return {
    ...toRefs(context),
    isPayfacEnabled,
    payFacService,
    isPaymentsOnboarded,
    counterpartyStatus,
    counterpartyCountry,
    initializePayments,
    initializePaymentMethods,
    makeItemizedPayment,
    makeItemizedInvoice
  }
}
