export default {
  props: {
    /**
     * Name of vuex store ie: "Quote"
     */
    store: {
      type: String,
      required: true,
      default: 'Quote'
    },

    object: {
      type: Object,
      required: true
    },

    reference: {
      type: String,
      required: true
    },

    showDerivedDimensions: {
      default: false
    }
  },

  data() {
    return {
      dimTypes: {}
    }
  },

  computed: {
    /**
     * Dimensions objec tfor this item
     */
    dimensions: {
      get() {
        return _.imm(this.object.oDimensions)
      },

      async set(dimensions) {
        if (dimensions && !_.jsonEquals(dimensions, this.dimensions)) {
          const keys = Object.keys(dimensions)
          const changedDimensions = keys.filter(
            (key) => !_.jsonEquals(dimensions[key], this.dimensions[key])
          )

          await this.$store.dispatch(`${this.store}/field`, {
            changes: {
              oDimensions: dimensions
            },
            explicit: true,
            refId: this.reference
          })

          const refIds = await this.$store.dispatch('Quote/getLinkedDimensionRefIds', {
            assemblyRefId: this.reference,
            changedDimensions
          })
          await this.$store.dispatch('Quote/recalcAddons', { refIds, loading: false })
        }
      }
    },

    /**
     * If any dimensions that are required, have no values
     * @returns {string}
     */
    missingDimensions() {
      const dims = this.object.oDimensions
      return this.object.asRequiredDimensions.filter((abbr) => !dims[abbr].value)
    },

    /**
     * Summarize dimensions
     * @returns {string}
     */
    summary() {
      const obj = this.object
      const possibleDimensions = this.dimTypes

      if (!Object.keys(possibleDimensions).length) return ''

      return _.intersection(obj.asRequiredDimensions, Object.keys(obj.oChildRequiredDimensions))
        .filter((abbr) => abbr in obj.oDimensions)
        .map(
          (abbr) =>
            `<span style="color: #444; border-bottom: 3px solid #${obj.oDimensions[abbr].value ? (possibleDimensions[abbr] && possibleDimensions[abbr].color) || (obj.oDimensions[abbr] && obj.oDimensions[abbr].color) : 'ef3c5'}; padding: 0.2em 0.5em; border-radius: 0; line-height:1.5 !important; display: inline-block;"><strong>${c.format(obj.oDimensions[abbr].value || 0, obj.oDimensions[abbr].measure === 'ft' ? 'imperial' : 'number')}</strong>${obj.oDimensions[abbr].measure === 'ft' ? '' : obj.oDimensions[abbr].measure}</span>`
        )
        .join('')
    },

    /**
     * Assembly path/name
     * @returns {string[]}
     */
    names() {
      return this.object.asAssemblyPath?.length
        ? this.object.asAssemblyPath
        : [this.object.quote_name]
    }
  },

  watch: {
    object() {
      this.setDefaults()
    }
  },

  methods: {
    /**
     * Make sure each required dimension has a
     * default set so it isn't null
     */
    setDefaults() {
      const defaultAreaMeasure = this.$store.getters.userMeta.defaultAreaMeasure || null
      const defaultLengthMeasure = this.$store.getters.userMeta.defaultLengthMeasure || null
      const defaultVolumeMeasure = this.$store.getters.userMeta.defaultVolumeMeasure || null
      const defaultWeightMeasure = this.$store.getters.userMeta.defaultWeightMeasure || null
      const dims = this.dimensions

      const defaulted = this.object.asRequiredDimensions.reduce((acc, abbr) => {
        let defaultMeasure = 'count'
        if (!dims[abbr]) return { ...acc }
        if (dims[abbr].measureType === 'length') {
          defaultMeasure = defaultLengthMeasure || 'ft'
        } else if (dims[abbr].measureType === 'area') {
          defaultMeasure = defaultAreaMeasure || 'ft2'
        } else if (dims[abbr].measureType === 'volume') {
          defaultMeasure = defaultVolumeMeasure || 'ft3'
        } else if (dims[abbr].measureType === 'weight') {
          defaultMeasure = defaultWeightMeasure || 'lbs'
        }

        return {
          ...acc,
          [abbr]: {
            ...dims[abbr],
            measure: (dims[abbr] && dims[abbr].measure) || defaultMeasure,
            value: dims[abbr].value || 0
          }
        }
      }, {})

      this.dimensions = {
        ...this.dimensions,
        ...defaulted
      }
    },

    getDefaultKey(abbr) {
      const possibleDimensions = this.dimTypes

      if (!Object.keys(possibleDimensions).length) return null

      const dim = possibleDimensions[abbr]

      if (dim.measureType === 'length') return 'defaultLengthMeasure'
      if (dim.measureType === 'area') return 'defaultAreaMeasure'
      if (dim.measureType === 'volume') return 'defaultVolumeMeasure'
      if (dim.measureType === 'weight') return 'defaultWeightMeasure'

      return null // count
    }
  },

  mounted() {
    this.setDefaults()
  },

  async created() {
    this.dimTypes = await this.$store.dispatch('Dimension/getPossibleDimensions')
  }
}
