<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :clickAway="false"
    :expandable="false"
    :includeGutter="true"
    :hasSidePanel="true"
  >
    <template #header>
      <font-awesome-icon icon="shop" class="mr-2" />
      Vendor
    </template>
    <template #body>
      <vendor ref="body" :object="object" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import Vendor from '../bodies/Vendor.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalVendor',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { Vendor },
  props: {
    type: {
      default: 'vendor'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
