<template>
  <field-section :noLabel="true" class="select-field" :class="size">
    <select class="input-container select-field" v-model="localValue">
      <option
        v-if="placeholder"
        :disabled="true"
        :selected="value === null ? 'selected' : ''"
        :value="null"
      >
        {{ placeholder }}
      </option>
      <option
        :value="option.value"
        :selected="localValue === option.value ? 'selected' : ''"
        :key="option.value"
        v-for="option in options"
      >
        {{ option.text }}
      </option>
    </select>
  </field-section>
</template>

<script>
import FieldSection from './FieldSection.vue'

export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: 'Please Select'
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  components: {
    FieldSection
  },
  emits: ['input', 'change'],
  data() {
    return {
      localValue: ''
    }
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
      if (this.localValue !== this.value) {
        this.$emit('change', this.localValue)
      }
    },
    value() {
      if (this.localValue !== this.value) {
        this.localValue = this.value
      }
    }
  },
  created() {
    this.localValue = this.value
  },
  mounted() {
    this.localValue = this.value
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.input-container.select-field {
  cursor: pointer;
}
.field-section-item.select-field.sm {
  .field-section,
  select {
    min-height: 2em !important;
  }
}
</style>
