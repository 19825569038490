export default {
  root: {
    class: [
      // Shape
      'rounded',
      'shadow-md',

      // Color
      'bg-flame-white dark:bg-surface-900',
      'text-surface-700 dark:text-surface-0'
    ]
  },
  body: ({ props }) => ({
    class: {
      'p-8': !props.unstyled
    }
  }),
  title: {
    class: 'text-xl font-header font-bold mb-2'
  },
  subtitle: {
    class: [
      // Font
      'font-normal',

      // Spacing
      'mb-2',

      // Color
      'text-surface-600 dark:text-surface-0/60'
    ]
  },
  content: {
    // class: 'py-3' // Vertical padding.
  },
  footer: {
    class: 'pt-5' // Top padding.
  }
}
