export const getItemType = (item) => item?.oMeta?.itemType ?? 'costItem'

export const isItemType = (item, type) => getItemType(item) === type

export const isNotItemType = (item, type) => getItemType(item) !== type

export const getType = (item) => item?.type ?? 'cost_item'

export const isNotItem = (item) => getItemType(item) !== 'costItem' || getType(item) !== 'cost_item'

export const isNotProgressItem = (item) =>
  (getItemType(item) !== 'costItem' && getItemType(item) !== 'task') ||
  getType(item) !== 'cost_item'

export const getAudience = (item) => item.oMeta?.selectionAudience || 'client'

export default {
  getType,
  isItemType,
  isNotItemType,
  getItemType,
  isNotItem,
  isNotProgressItem,
  getAudience
}
