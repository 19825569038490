<template>
  <div class="quote-presentation--dimensions">
    <font-awesome-icon icon="drafting-compass" class="mr-1" />
    <SuggestionButton
      class="quote-presentation--dimension"
      v-for="dim in visibleDimensions"
      @click="toggleVisibility(dim.abbr)"
      :style="{
        backgroundColor: `#${dim.color}`,
        mixBlendMode: 'multiply'
      }"
      :key="dim.name"
    >
      <font-awesome-icon
        v-if="editable"
        :class="{
          circled: true,
          info: dim.showCustomer,
          danger: !dim.showCustomer,
          'mr-1': true
        }"
        :icon="dim.showCustomer ? 'check' : 'minus-circle'"
        fixed-width
      />
      {{ dim.name }}&nbsp;•&nbsp;<strong>{{ $f.number(dim.value) }}</strong
      >&nbsp;{{ dim.measure }}
    </SuggestionButton>
  </div>
</template>

<script>
import SuggestionButton from '../../ui/SuggestionButton.vue'
import PresentationAssemblyMixin from './PresentationAssemblyMixin'

export default {
  name: 'QuotePresentationDimensions',
  mixins: [PresentationAssemblyMixin],
  data() {
    return {
      uid: c.uniqueId()
    }
  },
  methods: {
    toggleVisibility(abbr) {
      if (!this.editable) return

      const dims = _.immutable(this.object.oDimensions)
      const dim = dims[abbr]
      dim.showCustomer = dim.showCustomer ? 0 : 1
      dims[abbr] = dim

      this.$store.dispatch(`${this.store}/field`, {
        refId: this.object.refId,
        changes: {
          oDimensions: dims
        },
        explicit: true
      })
    }
  },
  computed: {
    visibleDimensions() {
      const all = Object.values(this.object.oDimensions)

      if (this.editable) return all

      return all.filter((dim) => dim.showCustomer)
    }
  },
  components: {
    SuggestionButton
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    deselectOnDestroy: {
      default: false
    },
    store: {
      type: String
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-presentation--dimensions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.8em;
}
a.suggestion-button.quote-presentation--dimension {
  margin: 0.15em;
  font-weight: 500 !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
