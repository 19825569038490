<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="lg"
    :width="800"
    ref="modal"
    :scrollable="true"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #header>{{ $t('Optional item details') }}</template>
    <template #body>
      <!--   Cost item name   -->
      <div class="text-base text-surface-500">{{ parentName }}</div>
      <div class="text-2xl font-medium">{{ itemName }}</div>
      <div class="font-medium text-xl">{{ dimensions }}</div>
      <div class="relative grid grid-cols-1 md:grid-cols-4 gap-6 mt-6">
        <!--   Left     -->
        <div class="flex flex-col justify-start items-start col-span-2 max-w-full">
          <!--   Image gallery     -->
          <div v-if="thumbList.length" class="overflow-hidden w-full">
            <Galleria
              :images="thumbList"
              numVisible="6"
              thumbnailsPosition="bottom"
              hideArrows="true"
              :pt="{
                thumbnailContainer: 'w-fit pt-3 ',
                thumbnailItem: 'overflow-hidden pr-2 w-fit',
                thumbnailItemContent: 'w-fit'
              }"
            />
          </div>
          <div v-else class="flex justify-center items-center w-full h-[293px] bg-surface-200">
            <font-awesome-icon :icon="['fas', 'cube']" class="text-7xl text-surface-300" />
          </div>
        </div>
        <!--    Right    -->
        <div class="col-span-2">
          <div class="text-lg font-medium mb-3">{{ $t('Description') }}</div>
          <p v-if="description" class="font-light">{{ description }}</p>
          <p v-else class="font-light">{{ $t('No description available') }}.</p>
          <div
            class="mt-4"
            v-if="properties && properties.length && properties[0] && properties[0][0]"
          >
            <span class="font-medium">{{ $t('Features') }}</span>
            <PresentationProperties :object="object" />
          </div>
          <p class="text-xs py-4 text-pitch-black-500 font-light">
            {{
              $t(
                'Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.'
              )
            }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <!--     CTA     -->
      <div class="flex gap-6 flex-col lg:flex-row justify-end mt-[28px]">
        <Btn
          severity="tertiary-borderless"
          :size="'item-price'"
          :disabled="savingChanges"
          @click="closeModal"
        >
          <span>{{ $t('Cancel') }}</span>
        </Btn>
        <Btn
          severity="primary"
          v-if="!isIncluded"
          :disabled="savingChanges"
          @click="toggleIncluded(true)"
        >
          {{ $t('Add to estimate') }} -- {{ `${$t('add')} ${$f.currency(itemPrice)}` }}
        </Btn>

        <Btn
          severity="primary"
          v-if="isIncluded"
          :disabled="savingChanges"
          @click="toggleIncluded(false)"
        >
          {{ $t('Remove from estimate') }} -- {{ `(${$t('save')} ${$f.currency(itemPrice)})` }}
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { ref, computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import SelectionItemMixin from '@/components/quote/presentation/SelectionItemMixin'
import PresentationProperties from '@/components/quote/presentation/PresentationProperties.vue'
import eventBus from '@/eventBus'
import Galleria from '@/components/ui/Gallery/Galleria.vue'

export default {
  name: 'OptionalDetails',
  mixins: [ObjectModalMixin, SelectionItemMixin],
  emits: [],
  components: {
    PresentationProperties,
    Galleria
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const { modal } = toRefs(props)

    const savingChanges = ref(false)

    // Computed properties
    const refId = computed(() => modal.value.refId)
    const object = computed(() => modal.value.object)
    const storeName = computed(() => modal.value.store)
    const itemType = computed(() => object.value.type)
    const isIncluded = computed(() => _.toNumber(object.value[`${itemType.value}_is_included`]))
    const itemPrice = computed(() => object.value[`${itemType.value}_orig_price_net`])

    const parentName = computed(
      () => modal.value.object.asAssemblyPath[modal.value.object.asAssemblyPath.length - 2]
    )
    const itemName = computed(() => modal.value.itemName)
    const description = computed(() => modal.value.description)
    const thumbList = computed(() => modal.value.thumbs)
    const properties = computed(() => modal.value.properties)

    const closeModal = async () => {
      await store.dispatch('modal/closeAll')
    }

    const toggleIncluded = async (included) => {
      savingChanges.value = true
      await store.dispatch('Quote/toggleIncluded', {
        included,
        store: storeName.value,
        explicit: true,
        refId: modal.value.refId
      })
      savingChanges.value = false
      await closeModal()
    }

    const handleSelection = async () => {
      if (!isIncluded.value) {
        await toggleIncluded(1)
        eventBus.$emit('remove-from-disabled-list', refId.value)
      }
    }

    const handleCancel = async () => {
      if (isIncluded.value) {
        await toggleIncluded(0)
        eventBus.$emit('add-to-disabled-list', refId.value)
      }
    }

    return {
      handleCancel,
      handleSelection,
      closeModal,
      parentName,
      itemName,
      description,
      thumbList,
      object,
      properties,
      isIncluded,
      savingChanges,
      itemPrice
    }
  }
}
</script>
