// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers';

export default {
  type: 'labor_type',

  possibleStatuses: ['a', 'h'],

  tableColumns: [
    {
      column: 'labor_type_id',
      name: 'Labor type code',
      visible: false,
      class: 'text-left justify-start',
    },
    {
      column: 'labor_type_name',
      name: 'Labor type name',
      visible: true,
      class: 'text-left justify-start',
    },
    {
      column: 'labor_type_rate_net',
      visible: true,
      class: 'number justify-end',
    },
    {
      name: '# of items that use this labor type',
      column: 'labor_type_count_cost_types',
      visible: true,
      class: 'number text-success lead',
    },
    {
      column: 'collection_name',
      name: 'Collection name',
      visible: true,
      class: 'text-left justify-start',
    }
  ],

  fields: {
    mod_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: 'mod',
    },
    mod_labor_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
    },
    labor_type_is_override: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false,
    },
    labor_type_is_custom: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false,
    },
    labor_type_is_indexed: {
      type: 'int',
      default: 0,
    },
    labor_type_is_generic: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      trackChanges: false,
    },
    labor_type_is_default: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    labor_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    labor_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      validate: {
        required: true,
      },
    },
    labor_type_rate_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      validate: {
        required: true,
      },
    },
    labor_type_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    labor_type_count_cost_types: {
      type: 'int',
      filter: true,
      format: 'number',
      save: false,
      trackChanges: false,
    },
    trade_type_ids: {
      type: 'array',
      filter: true,
      mapTo: 'trade_type',
      save: true,
      trackChanges: true,
    },
    collection_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      validate: {
        required: false,
      },
    },
  },

  generateVueActions() {
    return {
      async getDefaultId({ dispatch, rootState }) {
        return 'craftsman-3';
      },
      async getByTradeType({ dispatch, rootState }) {
        let byTradeType;

        byTradeType = await dispatch('getCacheItem', { key: 'laborTypeByTradeType' });

        if (!byTradeType || !Object.keys(byTradeType).length) {
          const { set } = await dispatch('filter', {
            order: [['company_id', 'asc'], ['labor_type_id', 'asc']],
            filters: {
              company_id: `${rootState.session.scope.company}||NULL`,
            },
          });

          byTradeType = {};

          set.forEach((lt) => {
            const tts = _.makeArray(lt.trade_type_ids);

            tts.forEach((tt) => {
              byTradeType[String(tt)] = lt;
            });
          });

          await dispatch('setCacheItem', { key: 'laborTypeByTradeType', value: byTradeType });
        }

        return byTradeType;
      },
    };
  },
};
