const Metrics = () => import('@/components/pages/Metrics.vue')
const Franchise = () => import('@/components/pages/Franchise.vue')
const Items = () => import('@/components/pages/Items.vue')
const Users = () => import('@/components/pages/Users.vue')

const meta = {
  scopesAllowed: ['user', 'company', 'franchisor'],
  menuContext: 'franchisor'
}

export default [
  {
    path: '/:scopeRoute/franchisor',
    alias: ['/:scopeRoute/franchisor'],
    children: [
      {
        path: 'metrics',
        name: 'Franchise Metrics',
        component: Metrics,
        meta
      },
      {
        path: 'franchise',
        name: 'Franchise',
        component: Franchise,
        meta: {
          ...meta
        }
      },
      {
        path: 'items',
        name: 'Franchise Items List',
        component: Items,
        meta: {
          ...meta
        }
      },
      {
        path: 'users',
        name: 'Franchise Users',
        component: Users,
        meta: {
          ...meta
        }
      }
    ],
    meta
  }
]
