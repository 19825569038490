<template>
  <container v-if="visible" :size="width" :full="full">
    <slot></slot>
  </container>
</template>

<script>
export default {
  name: 'Step',
  emits: ['active', 'inactive'],
  data() {
    return {
      isStep: 0,
      inputs: []
    }
  },
  watch: {
    async visible(v) {
      if (v) {
        this.listenForEnter()
        const first = await this.getFirstInput()
        if (first) first.focus()
        this.$emit('active')
      } else {
        this.unlistenForEnter()
        this.$emit('inactive')
      }
    }
  },
  onMounted() {
    this.unlistenForEnter()
  },
  props: {
    nextButtonText: {
      default: 'Next'
    },
    nextButtonIcon: {
      default: 'arrow-right'
    },
    action: {
      type: Object
    },
    beforeNext: {
      type: Function,
      default: () => {}
    },
    canContinue: {
      default: true
    },
    skipIf: {
      default: false
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '800px'
    },
    full: {
      type: Boolean,
      default: false
    },
    onSkip: {
      type: Function
    }
  },
  computed: {
    visible() {
      return this.isStep && this.$parent.step === this.isStep
    }
  },
  methods: {
    async enterHandler(e) {
      if (e.which !== 13) return

      const last = await this.getLastInput()

      if (e.target === last[0] || !$(e.target).is(':input')) {
        this.$parent.next()
      }
    },
    async getLastInput() {
      const inputs = await this.getInputs()
      return inputs.last()
    },
    async getFirstInput() {
      const inputs = await this.getInputs()
      return inputs.first()
    },
    async getInputs() {
      await c.throttle(() => {}, { key: this.isStep, delay: 100 })
      return $('body .steps-wizard--content').find(':input, .choose--container')
    },
    listenForEnter() {
      window.addEventListener('keypress', this.enterHandler)
    },
    unlistenForEnter() {
      try {
        window.removeEventListener('keypress', this.enterHandler)
      } catch (e) {
        //
      }
    },
    setStep(step) {
      this.isStep = step
    }
  }
}
</script>

<style scoped></style>
