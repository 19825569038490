<template>
  <Container class="search--container">
    <spinner :loading="1" v-if="loading" size="3em" class="my-3 mt-4" />
    <h4 class="text-muted mt-4" v-else-if="!total && phrase">Nothing found...</h4>

    <div class="row results" v-show="phrase">
      <template v-for="(schema, type) in searchTypes">
        <div
          v-if="sortedSet[type] && sortedSet[type].length > 0"
          class="col search-result-container"
          :key="type"
          :id="type"
        >
          <section>
            <span class="section-title">{{ searchTypes[type].title }}</span>
            <div
              class="search-result-wrapper"
              @click.capture.stop.prevent="edit(result)"
              v-for="(result, index) in sortedSet[type]"
              :key="index"
            >
              <component
                :is="getComponentForType(type)"
                :object="result"
                :key="`${result}-${index}`"
                :middle="false"
                class="my-3"
              />
            </div>
          </section>
        </div>
      </template>
    </div>
  </Container>
</template>

<script>
import SearchResult from '../ui/SearchResult.vue'
import SpinContainer from '../ui/SpinContainer.vue'
import PreviewQuote from '../ui/Previews/Quote.vue'
import PreviewInvoice from '../ui/Previews/Invoice.vue'
import PreviewClient from '../ui/Previews/Client.vue'
import PreviewUser from '../ui/Previews/User.vue'
import PreviewAssembly from '../ui/Previews/Assembly.vue'
import PreviewCostType from '../ui/Previews/CostType.vue'
import PreviewFile from '../ui/Previews/File.vue'

export default {
  props: {
    value: {
      required: true
    }
  },

  name: 'Search',
  emits: ['input', 'close'],

  data() {
    return {
      set: [],
      total: 0,
      loading: false,
      numberOfLoadedResults: 0,
      searchTypes: {
        quote: {
          title: 'Proposals + projects'
        },
        client: {
          title: 'Clients + prospects'
        },
        // user: {
        //   title: 'Users',
        // },
        file: {
          title: 'Files'
        },
        assembly: {
          title: 'Assemblies'
        }
        // item: {
        //   title: 'Items',
        // },
      },
      hasItems: 0
    }
  },
  computed: {
    sortedSet() {
      return this.set.reduce(
        (acc, item) => ({
          ...acc,
          [item.type]: [...(acc[item.type] || []), item]
        }),
        {}
      )
    },
    phrase: {
      get() {
        return this.value
      },

      set(searchPhrase) {
        this.$emit('input', searchPhrase)
      }
    }
  },
  watch: {
    phrase() {
      this.numberOfLoadedResults = 0
      this.loading = true
      this.throttleFetch()
    }
  },
  methods: {
    /**
     * Edit/view result of search
     * @param result
     * @returns {Promise<any>}
     */
    async edit(result) {
      const type = result.type
      const id = result[`${type}_id`]

      this.$emit('close')

      return this.$store.dispatch('edit', { type, id })
    },

    getComponentForType(type) {
      return `Preview${c.titleCase(type)}`
    },

    async throttleFetch() {
      const searchPhrase = this.phrase

      if (!searchPhrase) return

      this.loading = 1
      await c.throttle(
        async () => {
          const { set, total } = await this.$store.dispatch('search/search', {
            searchPhrase,
            types: Object.keys(this.searchTypes)
          })
          this.set = set
          this.total = total
        },
        {
          delay: 500
        }
      )
      this.loading = 0
    }
  },
  components: {
    SearchResult,
    SpinContainer,
    PreviewQuote,
    PreviewInvoice,
    PreviewClient,
    PreviewUser,
    PreviewAssembly,
    PreviewCostType,
    PreviewFile
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
div.search--container {
  .results {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .search-result-container {
    max-width: 45em;
    flex: 1 45em;
  }
}
</style>
