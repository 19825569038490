<template>
  <spin-container :loading="bodyLoading">
    <template v-if="tax_id">
      <btn-bar class="pull-right" :collapse="true" :actions="actions" :selected="[this]">
        <template #button>
          <btn :stop="false" :capture="false" :prevent="false" class="round default sm">
            <template #icon>
              <font-awesome-icon icon="ellipsis" />
            </template>
            Actions
          </btn>
        </template>
      </btn-bar>
      <h4>{{ tax_name }}</h4>
    </template>
    <template v-else>
      <h4>Create sales tax</h4>
    </template>

    <card-section>
      <card-list>
        <card-list-field>
          <span>
            State/province
            <small class="text-info">
              The state or province that this sales tax applies to. If you also have a city or
              county or other regional sales tax, combine them and choose the state or province it
              is inside of.
            </small>
          </span>

          <choose
            :return-array="false"
            :filters="{
              country_id: $store.state.session.company.country_id
            }"
            :default="$store.state.session.user.province_id"
            v-model="province_id"
            schema="tax:province_id"
          />
        </card-list-field>

        <card-list-field>
          <span> Tax{{ advanced ? '/combination' : '' }} name </span>

          <field
            :validate="{
              required: true
            }"
            v-model="tax_name"
            schema="tax:tax_name"
          />
        </card-list-field>

        <card-list-field v-if="!advanced">
          <label> Tax percentage </label>
          <field
            type="calculator"
            v-model="percentWhole"
            :format="false"
            schema="tax:tax_percentage"
          />
          <small class="text-muted" style="flex: 0 0 auto"> % </small>
        </card-list-field>

        <template v-if="advanced">
          <CardListField class="under under-first">
            <span>
              <Help>
                <template #before> Basic sales taxes </template>

                <p>This tax is collected on the entire price of an item.</p>
                <p>
                  You can add multiple taxes, for example if you have city, county, state/prov,
                  national sales or value added taxes, you can add them one by one.
                </p>
              </Help>
            </span>

            <span>
              <Btn rounded class="more px-0 p-2 info" @click="addTaxClause('ct')">
                <font-awesome-icon icon="plus" />
              </Btn>
            </span>
          </CardListField>

          <CardListField
            class="under-under"
            v-for="(tax, index) in oTaxSettings.ct"
            :class="{
              'under-under': true,
              'under-first': index === 0,
              'under-last': index === oTaxSettings.ct.length - 1
            }"
            :key="`ct${index}`"
          >
            <span class="flex flex-row items-center">
              <Btn
                rounded
                class="more px-0 p-2 danger"
                style="max-width: 8em"
                @click="removeTaxClause('ct', index)"
              >
                <font-awesome-icon icon="trash" />
              </Btn>
              <Field
                class="inline"
                :validate="{
                  required: true
                }"
                placeholder="Name this tax segment"
                :value="tax.name"
                @input="(val) => setTaxName('ct', index, val)"
              />
            </span>

            <Choose
              style="min-width: 15em"
              :staticSet="onChoices"
              :value="tax.on"
              :return-array="false"
              @input="(val) => setTaxPortion('ct', index, val)"
            />

            <Field
              type="calculator"
              :value="(tax.pcnt || 0) * 100"
              @input="(val) => setTaxPercentage('ct', index, val)"
            />

            <small class="text-muted suffix"> % </small>

            <template #below>
              <small class="text-info">
                Taxes charged on {{ tax.on !== 'all' ? tax.on : 'price' }} of the item.
              </small>
            </template>
          </CardListField>

          <CardListField class="under">
            <span>
              <Help>
                <template #before> Sales tax on labor </template>

                <p>
                  This tax is collected on the share of your items final price that is from your
                  in-house labor (work that your employees/team workers do). This tax will not be
                  levied if your item is subcontracted.
                </p>
                <p>
                  If the tax on this labor is the same as the tax on subcontracted work, then add
                  one for the same percentage under the subcontractor category.
                </p>
                <p>
                  You can add multiple taxes, for example if you have city, county, state/prov,
                  national sales or value added taxes, you can add them one by one.
                </p>
              </Help>
            </span>

            <span>
              <Btn rounded class="more px-0 p-2 info" @click="addTaxClause('ihlt')">
                <font-awesome-icon icon="plus" />
              </Btn>
            </span>
          </CardListField>

          <CardListField
            class="under"
            v-for="(tax, index) in oTaxSettings.ihlt"
            :class="{
              'under-under': true,
              'under-first': index === 0,
              'under-last': index === oTaxSettings.ihlt.length - 1
            }"
            :key="`ihlt${index}`"
          >
            <span class="flex flex-row items-center">
              <Btn rounded class="more px-0 p-2 danger" @click="removeTaxClause('ihlt', index)">
                <font-awesome-icon icon="trash" />
              </Btn>
              <Field
                class="inline"
                :validate="{
                  required: true
                }"
                placeholder="Name this tax segment"
                :value="tax.name"
                @input="(val) => setTaxName('ihlt', index, val)"
              />
            </span>

            <Choose
              style="min-width: 15em"
              :staticSet="onChoices"
              :value="tax.on"
              :return-array="false"
              @input="(val) => setTaxPortion('ihlt', index, val)"
            />

            <Field
              type="calculator"
              :value="(tax.pcnt || 0) * 100"
              @input="(val) => setTaxPercentage('ihlt', index, val)"
            />

            <small class="text-muted suffix"> % </small>
            <template #below>
              <small class="text-info">
                Taxes charged on the portion of the
                {{ tax.on !== 'all' ? tax.on : 'price' }} generated from in-house labor.
              </small>
            </template>
          </CardListField>

          <CardListField class="under">
            <span>
              <Help>
                <template #before> Sales tax on subcontracted services </template>

                <p>
                  This tax is collected on the share of your items final price that is from your
                  subcontractor expenses. This tax will not be levied if your item is not
                  subcontracted.
                </p>
                <p>
                  You can add multiple taxes, for example if you have city, county, state/prov,
                  national sales or value added taxes, you can add them one by one.
                </p>
              </Help>
            </span>

            <span>
              <Btn rounded class="more px-0 p-2 info" @click="addTaxClause('slt')">
                <font-awesome-icon icon="plus" />
              </Btn>
            </span>
          </CardListField>

          <CardListField
            class="under"
            v-for="(tax, index) in oTaxSettings.slt"
            :class="{
              'under-under': true,
              'under-first': index === 0,
              'under-last': index === oTaxSettings.slt.length - 1
            }"
            :key="`slt${index}`"
          >
            <span class="flex flex-row items-center">
              <Btn rounded class="more px-0 p-2 danger" @click="removeTaxClause('slt', index)">
                <font-awesome-icon icon="trash" />
              </Btn>
              <Field
                class="inline"
                :validate="{
                  required: true
                }"
                placeholder="Name this tax segment"
                :value="tax.name"
                @input="(val) => setTaxName('slt', index, val)"
              />
            </span>

            <Choose
              style="min-width: 15em"
              :staticSet="onChoices"
              :value="tax.on"
              :return-array="false"
              @input="(val) => setTaxPortion('slt', index, val)"
            />

            <Field
              type="calculator"
              :value="(tax.pcnt || 0) * 100"
              @input="(val) => setTaxPercentage('slt', index, val)"
            />

            <small class="text-muted suffix"> % </small>
            <template #below>
              <small class="text-info">
                Taxes charged on the portion of the
                {{ tax.on !== 'all' ? tax.on : 'price' }} generated from subcontractor costs.
              </small>
            </template>
          </CardListField>

          <CardListField class="under">
            <span>
              <Help>
                <template #before> Sales tax on materials </template>

                <p>
                  This tax is collected on the share of your items final price that is from your
                  materials expenses. This tax will not be levied if your item is subcontracted.
                </p>
                <p>
                  You can add multiple taxes, for example if you have city, county, state/prov,
                  national sales or value added taxes, you can add them one by one.
                </p>
              </Help>
            </span>

            <span>
              <Btn rounded class="more px-0 p-2 info" @click="addTaxClause('mt')">
                <font-awesome-icon icon="plus" />
              </Btn>
            </span>
          </CardListField>

          <CardListField
            class="under"
            v-for="(tax, index) in oTaxSettings.mt"
            :class="{
              'under-under': true,
              'under-first': index === 0,
              'under-last': index === oTaxSettings.mt.length - 1
            }"
            :key="`mt${index}`"
          >
            <span class="flex flex-row items-center">
              <Btn rounded class="more px-0 p-2 danger" @click="removeTaxClause('mt', index)">
                <font-awesome-icon icon="trash" />
              </Btn>
              <Field
                class="inline"
                :validate="{
                  required: true
                }"
                placeholder="Name this tax segment"
                :value="tax.name"
                @input="(val) => setTaxName('mt', index, val)"
              />
            </span>

            <Choose
              style="min-width: 15em"
              :staticSet="onChoices"
              :value="tax.on"
              :return-array="false"
              @input="(val) => setTaxPortion('mt', index, val)"
            />

            <Field
              type="calculator"
              :value="(tax.pcnt || 0) * 100"
              @input="(val) => setTaxPercentage('mt', index, val)"
            />

            <small class="text-muted suffix"> % </small>
            <template #below>
              <small class="text-info">
                Taxes charged on the portion of the
                {{ tax.on !== 'all' ? tax.on : 'price' }} generated from materials costs.
              </small>
            </template>
          </CardListField>
        </template>
      </card-list>

      <SuggestionButton class="info mt-4" @click="advanced = !advanced" v-if="!advanced">
        Advanced/multiple tax options..
      </SuggestionButton>
    </card-section>
  </spin-container>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import SuggestionButton from '../ui/SuggestionButton.vue'

export default {
  name: 'BodyTax',
  components: { SuggestionButton },
  mixins: [ObjectManipulator('tax', true), BodyMixin],
  methods: {
    addTaxClause(category) {
      const sett = _.immutable(this.oTaxSettings)
      sett[category].unshift({ pcnt: 0, name: 'Sales tax' })
      this.oTaxSettings = sett
    },
    removeTaxClause(category, index) {
      const sett = _.immutable(this.oTaxSettings)
      sett[category].splice(index, 1)
      this.oTaxSettings = sett
    },
    setTaxPercentage(category, index, percentage) {
      const sett = _.immutable(this.oTaxSettings)
      sett[category][index].pcnt = percentage > 1 ? c.divide(percentage, 100) : percentage
      this.oTaxSettings = sett
    },
    setTaxName(category, index, name) {
      const sett = _.immutable(this.oTaxSettings)
      sett[category][index].name = name
      this.oTaxSettings = sett
    },
    setTaxPortion(category, index, portion) {
      const sett = _.immutable(this.oTaxSettings)
      sett[category][index].on = portion
      this.oTaxSettings = sett
    },
    afterSelect() {
      const taxClauses = Object.keys(this.oTaxSettings).reduce(
        (acc, ttype) => acc + this.oTaxSettings[ttype].length,
        0
      )
      console.log('tax clas', taxClauses)
      this.advanced = taxClauses > 1 || (!this.oTaxSettings.ct.length && taxClauses === 1)
      console.log('adv', this.advanced)
    }
  },
  props: {},
  data() {
    return {
      advanced: false,
      onChoices: [
        {
          text: 'Price',
          value: 'all'
        },
        {
          text: 'Cost only',
          value: 'cost'
        },
        {
          text: 'Profit only',
          value: 'profit'
        }
      ]
    }
  },
  computed: {
    percentWhole: {
      get() {
        return (
          ((this.oTaxSettings &&
            this.oTaxSettings.ct &&
            this.oTaxSettings.ct.length &&
            this.oTaxSettings.ct[0].pcnt) ||
            0) * 100
        )
      },
      set(v) {
        const sett = _.immutable(this.oTaxSettings)
        if (!sett.ct[0]) sett.ct[0] = {}
        sett.ct[0].pcnt = v / 100
        this.oTaxSettings = sett
        this.tax_percentage = v
      }
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
