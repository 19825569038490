export default {
  methods: {
    /**
     * create a bank account
     * @param {*} data
     * @returns {Promise<*>}
     */
    createAccount(data) {
      return this.$store.dispatch('ajax', {
        path: 'virtual_account/createVirtualAccount',
        data
      })
    },
    /**
     * create a bank account
     * @param {*} data
     * @returns {Promise<*>}
     */
    addAccountToTransferInstrument(data) {
      return this.$store.dispatch('ajax', {
        path: 'virtual_account/addVirtualAccountToTransferInstrument',
        data
      })
    },
    /**
     * Delete a bank account
     * @param {String} id
     * @returns {Promise<*>}
     */
    deleteAccount(id) {
      return this.$store.dispatch('ajax', {
        path: 'virtual_account/deleteVirtualAccount',
        data: {
          id
        }
      })
    },
    /**
     * Set the bank account as default
     * @param {String} id
     * @returns {Promise<*>}
     */
    setDefault(id) {
      return this.$store.dispatch('ajax', {
        path: 'virtual_account/setDefaultVirtualAccount',
        data: {
          id
        }
      })
    },
    /**
     * Fetch counterparties virtual accounts
     * @returns {Promise<*>}
     */
    fetchVirtualAccounts(counterpartyType) {
      return this.$store.dispatch('ajax', {
        path: 'virtual_account/fetchVirtualAccounts',
        data: {
          counterpartyType
        }
      })
    }
  }
}
