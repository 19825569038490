export function getEmailSteps(object, store) {
  let steps = []

  if (!object.email_is_verified) {
    steps = [
      ...steps,
      {
        name: 'This email address has not been verified.',
        short: 'Verify email address',
        action: (obj, button, grid) =>
          store.dispatch('email/verify', {
            id: obj.email_id,
            grid,
            button
          }),
        glyph: 'at',
        color: 'green-500'
      }
    ]
  }

  return steps
}
