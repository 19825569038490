import { reactive, toRefs } from 'vue'

export const initialState = {
  gantts: {}
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  const storeGantt = (key, gantt) => {
    context.gantts[key] = gantt
  }

  const clearGantt = (key) => {
    if (!(key in context.gantts)) return
    delete context.gantts[key]
  }

  const getGantt = (key) => {
    return context.gantts[key]
  }

  const updateDataInGantt = (key, id, changes) => {
    const instance = getGantt(key)
    if (!instance) return
    const { data } = instance
    const index = data.data.findIndex((i) => i.id === id)
    context.gantts[key].data.data[index] = {
      ...context.gantts[key].data.data[index],
      ...changes
    }
  }

  return {
    ...toRefs(context),
    storeGantt,
    clearGantt,
    getGantt,
    updateDataInGantt
  }
}
