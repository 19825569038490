<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="false"
    :clickAway="false"
    :expandable="true"
  >
    <template #header>
      <font-awesome-icon icon="coins" />
      Tax
    </template>
    <template #body>
      <tax-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import TaxBody from '../bodies/Tax.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalTaxNew',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { TaxBody },
  props: {
    type: {
      default: 'tax'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
