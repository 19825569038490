<template>
  <span
    class="badged"
    :class="{
      'is-dot': !hidden && isDot
    }"
  >
    <span class="content">
      <slot></slot>
      <span
        v-if="!hidden && (!isDot || isDot === undefined) && valueNum > 0"
        class="badge badge-danger"
        :style="{ 'font-size': fontSize }"
      >
        {{ max && valueNum > max ? `${max}+` : value }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'Badge',
  computed: {
    valueNum() {
      return c.toNum(this.value)
    }
  },
  props: {
    isDot: {
      required: false,
      default: undefined
    },
    max: {
      required: false,
      default: false
    },
    value: {
      required: false,
      default: 0
    },
    hidden: {
      default: false,
      required: false
    },
    fontSize: {
      required: false,
      default: '0.8em'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.badged {
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;

  span.content {
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    position: static;
    top: 0px;
    left: 0px;
    .badge {
      z-index: 100;
      top: -0.3em;
      right: -0.8em;
      left: auto;
      position: absolute;

      background: $deep-red-800;
      color: $flame-white;
      font-size: 0.8em;
      height: 1.5em;
      width: 1.5em;
      padding: 0.5em 0.5em;
      line-height: 1;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.is-dot,
  &.is-dot.right {
    span.content {
      &::after {
        z-index: 100;
        display: block;
        content: '';
        width: 0.5em;
        height: 0.5em;
        border-radius: 50px;
        background: $deep-red-800;
        position: absolute;
        top: -0.07em;
        right: -0.3em;
        left: auto;
        opacity: 0.8;
        box-shadow: 0px 0px 0.5em 0.2em rgba($deep-red-800, 0.2);
      }
    }
  }
  &.left {
    &.is-dot {
      span.content {
        &::after {
          left: -0.3em;
          right: auto;
        }
      }
    }
  }

  &:hover {
    span.content {
      .badge {
        opacity: 1;
      }
    }
    &.is-dot {
      span.content {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
</style>
