<template>
  <Modal
    ref="modal"
    size="lg"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
    :closeable="false"
  >
    <template #body>
      <!-- Page 1 -->
      <div v-show="tutorialPage === 0" class="flex flex-col gap-y-8 animate-fadeIn">
        <!-- Title and subtitle -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-x-3 items-center">
            <icon icon="clipboard-check" class="text-xl" />
            <p class="text-2xl font-medium">Thank you for booking with us!</p>
          </div>
          <p class="text-lg">
            This portal contains everything you need to stay on top of your project:
          </p>
        </div>
        <!-- Information cards -->
        <div
          class="grid grid-cols-2 xl:grid-cols-4 justify-center items-center gap-3 *:flex *:flex-col *:items-center *:rounded *:px-6 *:py-10 *:gap-y-6 *:h-full *:bg-white"
        >
          <div>
            <icon
              :icon="['fal', 'list']"
              class="text-4xl text-white bg-orange-600 p-6 rounded-full w-fit aspect-square"
            />
            <p>Monitor progress and make payments from the <b>Progress</b> dashboard.</p>
          </div>
          <div>
            <icon
              :icon="['fal', 'bag-shopping']"
              class="text-4xl text-white bg-green-400 p-6 rounded-full w-fit aspect-square"
            />
            <p>Review your selections on the <b>Preview</b> page.</p>
          </div>
          <div>
            <icon
              :icon="['fal', 'clock-rotate-left']"
              class="text-4xl text-white bg-purple-300 p-6 rounded-full w-fit aspect-square"
            />
            <p>Inspect documents and the project's change history on the <b>History</b> page.</p>
          </div>
          <div>
            <icon
              :icon="['fal', 'message']"
              class="text-4xl text-white bg-matcha-500 p-6 rounded-full w-fit aspect-square"
            />
            <p>Communicate directly with your contractor under the <b>Messages</b> tab.</p>
          </div>
        </div>
        <!-- Bottom buttons -->
        <div class="flex flex-col items-end w-full">
          <Btn
            v-if="!disablePayment"
            :action="nextTutorialPage"
            severity="primary-black"
            size="lg"
            class="max-w-32"
          >
            Continue
            <icon :icon="['fas', 'chevron-right']" />
          </Btn>
          <Btn
            v-if="disablePayment"
            :action="closeTutorial"
            severity="primary-black"
            size="lg"
            class="max-w-32"
          >
            Close
          </Btn>
        </div>
      </div>

      <!-- Page 2 -->
      <div v-show="tutorialPage === 1" class="flex flex-col gap-y-8 animate-fadeIn">
        <!-- Title and subtitle -->
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-x-3 items-center">
            <icon icon="credit-card" class="text-xl" />
            <p class="text-2xl font-medium">Add a payment method</p>
          </div>
          <p class="text-lg">
            Add a method to pay for your project as items are completed with seamless, efficient,
            and secure transactions:
          </p>
        </div>
        <!-- Description cards -->
        <div
          class="grid grid-cols-1 xl:grid-cols-2 justify-center items-center gap-3 *:flex *:flex-col *:items-center *:rounded *:px-6 *:py-10 *:gap-y-6 *:h-full *:bg-white"
        >
          <div>
            <icon
              :icon="['fal', 'credit-card']"
              class="text-4xl text-white bg-green-400 p-6 rounded-full w-fit aspect-square"
            />
            <div class="flex flex-col w-full">
              <p class="text-lg font-medium">Credit cards</p>
              <p>
                We accept Visa, Mastercard, American Express, and Discover through
                <a
                  href="https://www.adyen.com/"
                  target="_blank"
                  class="text-blue-print hover:text-blue-print-300 underline transition"
                  >Adyen</a
                >
                secure payments. Some fees may apply.
              </p>
            </div>
          </div>
          <div>
            <icon
              :icon="['fal', 'building-columns']"
              class="text-4xl text-white bg-purple-300 p-6 rounded-full w-fit aspect-square"
            />
            <div class="flex flex-col w-full">
              <p class="text-lg font-medium">Bank transfers</p>
              <p>
                We accept ACH transfers through
                <a
                  href="https://plaid.com/"
                  target="_blank"
                  class="text-blue-print hover:text-blue-print-300 underline transition"
                  >Plaid</a
                >
                with a valid bank account. Some fees may apply.
              </p>
            </div>
          </div>
        </div>
        <!-- Social proof cards @TODO - Get real testimonials -->
        <!-- <div
          class="grid grid-cols-2 xl:grid-cols-3 justify-center items-center gap-3 *:flex *:flex-col *:rounded *:p-6 *:gap-y-3 *:h-full *:bg-white"
        >
          <div>
            <p class="text-sm">
              "Testimonial body goes here. Bolster payments are great because..."
            </p>
            <div class="flex flex-row items-center gap-x-3">
              <icon :icon="['fal', 'comment-check']" class="text-xl" />
              <div>
                <p class="text-sm font-medium">John Doe</p>
                <p class="text-xs text-surface-600 font-light">Client</p>
              </div>
            </div>
          </div>
          <div>
            <p class="text-sm">
              "Testimonial body goes here. Bolster payments are great because..."
            </p>
            <div class="flex flex-row items-center gap-x-3">
              <icon :icon="['fal', 'comment-check']" class="text-xl" />
              <div>
                <p class="text-sm font-medium">Jane Doe</p>
                <p class="text-xs text-surface-600 font-light">Contracting Company</p>
              </div>
            </div>
          </div>
          <div>
            <p class="text-sm">
              "Testimonial body goes here. Bolster payments are great because..."
            </p>
            <div class="flex flex-row items-center gap-x-3">
              <icon :icon="['fal', 'comment-check']" class="text-xl" />
              <div>
                <p class="text-sm font-medium">John Smith</p>
                <p class="text-xs text-surface-600 font-light">Contracting Company</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="flex flex-col gap-3">
          <a
            @click="toggleTransactionFees"
            class="flex flex-row items-center gap-2 hover:text-surface-400 w-fit cursor-pointer"
          >
            <icon :icon="showTransactionFees ? 'chevron-down' : 'chevron-right'" />
            <p>Transaction fees</p>
          </a>
          <TransactionFeeTable
            v-show="showTransactionFees"
            :showSettlements="false"
            class="animate-fadeIn"
          />
        </div>
        <!-- Bottom buttons -->
        <div
          class="flex flex-row items-center"
          :class="disableBack ? 'justify-end' : 'justify-between'"
        >
          <Btn v-if="!disableBack" :action="lastTutorialPage" size="lg" severity="secondary">
            <icon :icon="['fas', 'chevron-left']" />
            Back
          </Btn>
          <div class="flex flex-row items-center gap-4">
            <a
              @click="closeTutorial"
              class="text-sm text-surface-600 hover:text-surface-400 transition cursor-pointer"
            >
              Do later
            </a>
            <Btn @click="emit('addMethod')" size="lg" severity="primary-black">
              Add payment method
            </Btn>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, defineExpose, defineEmits, defineProps } from 'vue'
import TransactionFeeTable from '@/components/payments/counterparty/TransactionFeeTable.vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  disablePayment: {
    default: false
  },
  disableBack: {
    default: false
  },
  startingPage: {
    default: 0
  }
})

const modal = ref()
const tutorialPage = ref(props.startingPage)
const showTransactionFees = ref(false)

const emit = defineEmits(['addMethod'])

const nextTutorialPage = () => {
  if (tutorialPage.value === 0 && props.disablePayment) {
    closeTutorial()
  } else {
    tutorialPage.value += 1
  }
}

const lastTutorialPage = () => {
  tutorialPage.value -= 1
}

const toggleTransactionFees = () => {
  showTransactionFees.value = !showTransactionFees.value
}

const getTutorialViewed = () => {
  let viewed = c.getStorage('clientTutorialViewed')
  const quoteId = store.state.session.scope.quote
  try {
    return !!viewed[quoteId]
  } catch {
    return false
  }
}

const setTutorialViewed = () => {
  let viewed = c.getStorage('clientTutorialViewed')
  const quoteId = store.state.session.scope.quote
  if (!quoteId) return
  viewed ? (viewed = { ...viewed, [quoteId]: true }) : (viewed = { [quoteId]: true })
  c.setStorage('clientTutorialViewed', viewed)
}

const closeTutorial = async () => {
  setTutorialViewed()
  await modal.value.close()
}

const openTutorial = async (force) => {
  if (getTutorialViewed() && !force) return
  await modal.value.open()
}

defineExpose({
  closeTutorial,
  openTutorial
})
</script>
