<template>
  <div
    class="sidepanel-container"
    :class="{
      'sidepanel-open': sidebarShown,
      'sidepanel-closed': !sidebarShown,
      'sidepanel-mobile': smallFormat
    }"
  >
    <div
      class="sidepanel-tabs scrollbar-hide"
      :class="{
        'sidepanel-open': sidebarShown,
        'sidepanel-closed': !sidebarShown,
        'sidepanel-mobile': smallFormat
      }"
      :style="sidebarShown ? `width: ${openWidth}px` : `width: ${closedWidth}px`"
    >
      <div class="tab-fog" @click.stop.prevent="close"></div>

      <ScrollContainer class="tabs-scroll">
        <div
          v-if="sidebarOpen"
          class="sidepanel-toggle sidepanel-open hidden md:flex justify-center content-center items-center"
          @click.stop.prevent="close"
          v-tooltip="{
            value: 'Click to hide side bar',
            showDelay: 500,
            hideDelay: 100
          }"
        >
          <font-awesome-icon icon="chevron-left" class="chevron-icon" />
        </div>
        <div
          v-else-if="!sidebarOpen"
          class="sidepanel-toggle sidepanel-closed hidden md:flex justify-center content-center items-center"
          @click.stop.prevent="open"
          v-tooltip="{
            value: 'Click to show side bar',
            showDelay: 500,
            hideDelay: 100
          }"
        >
          <font-awesome-icon icon="chevron-right" class="chevron-icon" />
        </div>
        <div
          v-else-if="sidebarShown"
          class="sidepanel-toggle-mobile open"
          @click.stop.prevent="close"
        >
          <font-awesome-icon icon="xmark" />
        </div>

        <div class="tab-container">
          <div v-if="!sidebarOpen" class="sidepanel-closed-spacer"></div>

          <slot name="above"> </slot>
          <template v-if="tabs && tabs.length > 1">
            <div v-for="(tab, index) in tabs" :key="index">
              <div v-if="tab.spacer" class="sidepanel-spacer"></div>
              <a
                v-else
                class="sidepanel--tab"
                @click.stop.prevent.meta="() => addTab(tab.title)"
                @click.stop.prevent.ctrl="() => addTab(tab.title)"
                @click.stop.prevent.shift="() => addTab(tab.title)"
                @dblclick.stop.prevent="() => togglePin(tab.title)"
                @click.exact="() => toggleTab(tab.title)"
                :class="{ active: tabLocal.indexOf(tab.title) > -1, closed: !sidebarOpen }"
              >
                <div v-if="sidebarOpen" class="sidepanel-tab-container cursor-pointer">
                  <div class="flex justify-start items-center">
                    <font-awesome-icon
                      v-if="tab.icon"
                      :icon="tab.icon"
                      v-badge.danger="tab.badge"
                      fixed-width
                    />
                    <span class="ml-3">{{ tab.title }}</span>
                  </div>
                </div>
                <div
                  v-else
                  class="sidepanel-tab-container flex justify-center cursor-pointer"
                  v-tooltip="{
                    value: tab.title,
                    showDelay: 100,
                    hideDelay: 100
                  }"
                >
                  <font-awesome-icon
                    v-if="tab.icon"
                    :icon="tab.icon"
                    size="lg"
                    v-badge.danger="tab.badge"
                    fixed-width
                  />
                </div>
              </a>
            </div>
          </template>

          <slot name="below"> </slot>
        </div>
      </ScrollContainer>
    </div>
    <div class="all-content" :class="{ 'sidepanel-shown': sidebarShown }">
      <slot name="heading" :panelShortcut="panelShortcut"></slot>
      <div
        v-if="!sidebarOpen"
        class="sidepanel-toggle-mobile closed md:hidden"
        :class="{
          'side-panel-modal': modal,
          'has-header': hasHeader,
          'no-header': !hasHeader
        }"
        @click.stop.prevent="open"
      >
        <font-awesome-icon icon="bars" />
      </div>
      <div
        class="sidepanel-content flex flex-col"
        :class="{
          'no-header-with-side-shown': smallFormat && !hasHeader && sidebarShown,
          'sidepanel-open': sidebarShown,
          'sidepanel-closed': !sidebarShown,
          'sidepanel-mobile': smallFormat
        }"
      >
        <slot name="above-content"> </slot>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  toRef,
  defineEmits,
  nextTick,
  useSlots
} from 'vue'
import { useStore } from 'vuex'
import eventBus from '../../../eventBus'
import { useMediaQuery } from '@/composables/mediaQuery'
import useSidePanel from '@/components/composables/SidePanel'

/**
 * Takes Panel compoennts for general slots.
 *
 * Also accepts:
 *  -"below" slot (below tab buttons)
 *  -"above" slot (above tab buttons)
 *
 *  Emits:
 *    -input (tab value)
 */

// props

const props = defineProps({
  value: {
    type: Array,
    default: () => []
  },
  maxPanels: {
    default: 2
  },
  forceHide: {
    default: false
  },
  modal: {
    default: true
  },
  hasHeader: {
    default: true
  }
})

// emitters

const emit = defineEmits(['input'])

// composables
const { smallFormat } = useMediaQuery()
const $store = useStore()
const slots = useSlots()
const { sidebarOpen, openWidth, closedWidth } = useSidePanel()

sidebarOpen.value = props.forceHide

// refs

const tabLocal = ref(Array.isArray(props.value) ? props.value : [props.value])
const pinnedTabsLocal = ref([])
const tabs = ref([])
const uid = ref(_.uniqueId())
const preventNextAction = ref(false)
const value = toRef(props, 'value')

// computed

const maxPanelsForSize = computed(() => {
  return largeFormat.value ? 2 : 1
})

const actualMaxPanels = computed(() => {
  return Math.min(props.maxPanels, maxPanelsForSize.value)
})

const largeFormat = computed(() => {
  return $store.state.session.deviceWidth >= 1500
})

const sidebarShown = computed(() => sidebarOpen.value)

const filteredSlots = computed(() => {
  return slots.default ? slots.default().filter((f) => f.type) : []
})

const panelShortcut = computed(() => {
  if (tabLocal.value.length < 2 && tabLocal.value[0] === 'Estimate') {
    return 'Preview'
  }
  return 'Estimate'
})

// Methods

const resize = () => {
  if (actualMaxPanels.value < tabLocal.value) {
    tabLocal.value.pop()
  }
}

const close = () => {
  sidebarOpen.value = false
}

const open = () => {
  sidebarOpen.value = true
}

const addTab = (tabName) => {
  preventNextAction.value = true
  if (tabLocal.value.indexOf(tabName) > -1) {
    tabLocal.value.splice(tabLocal.value.indexOf(tabName), 1)
  } else if (actualMaxPanels.value <= tabLocal.value.length) {
    preventNextAction.value = false
    toggleTab(tabName)
  } else {
    tabLocal.value = [...tabLocal.value, tabName]
  }
}

const togglePin = (tabName) => {
  if ($store.state.session.deviceSize === 'xl') {
    const index = pinnedTabsLocal.value.indexOf(tabName)
    if (index > -1) {
      pinnedTabsLocal.value.splice(index, 1)
    } else {
      pinnedTabsLocal.value = [...pinnedTabsLocal.value, tabName]
    }
  } else {
    pinnedTabsLocal.value = []
  }
}

const toggleTab = (tabName) => {
  if (preventNextAction.value) {
    preventNextAction.value = false
  } else if (tabLocal.value.indexOf(tabName) > -1 && tabLocal.value.length > 1) {
    if (pinnedTabsLocal.value.indexOf(tabName) > -1) {
      pinnedTabsLocal.value.splice(pinnedTabsLocal.value.indexOf(tabName), 1)
    }
    tabLocal.value.splice(tabLocal.value.indexOf(tabName), 1)
  } else if (tabLocal.value.indexOf(tabName) === -1) {
    tabLocal.value = [...pinnedTabsLocal.value, tabName]
  }

  if (smallFormat.value) close()
}

const setSlots = () => {
  c.throttle(
    () => {
      let newTabs = []
      filteredSlots.value.forEach((s) => {
        if (!s.componentOptions) return
        const slot = s.componentOptions.propsData
        newTabs = [
          ...newTabs,
          {
            title: slot.title,
            icon: slot.icon,
            badge: slot.badge,
            dot: slot.dot,
            iconPadding: slot.iconPadding,
            iconFontFamily: slot.iconFontFamily,
            spacer: slot.spacer
          }
        ]
      })
      tabs.value = newTabs

      if (!value.value.length && !tabLocal.value.length && filteredSlots.value.length) {
        toggleTab(filteredSlots.value[0].componentOptions.propsData.title)
      }
    },
    { delay: 100, key: uid.value }
  )
}

// expose
defineExpose({
  tabLocal,
  setSlots
})

// watchers

watch(filteredSlots, () => {
  setSlots()
})

watch(value, (a) => {
  tabLocal.value = Array.isArray(a) ? a : [a]
})

watch(tabLocal, (a) => {
  emit('input', a)
  nextTick(() => {
    eventBus.$emit('resize')
  })
  eventBus.$emit('tab', a)
})

onMounted(async () => {
  await nextTick()
  setSlots()
  await nextTick()
  resize()
})

onUnmounted(() => {
  eventBus.$off('resize', resize)
})
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-tooltip {
  position: fixed !important;
  left: 60px !important;
}

.sidepanel-container {
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;

  .sidepanel-tag {
    position: relative;
    display: inline-flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
    height: 1.5em;
    width: 1.3em;
    background: rgba($blue-print-700, 0.8);
    color: $blue-print-200;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 3em;
    left: 0px;
    border-left: 0;
    color: $blue-print-700 !important;
    text-align: center;
    font-size: 1.5em;
    transition: none;
    transition: all 0.1s ease;

    .glyphicon {
      color: $blue-print-200 !important;
      transition: all 0.1s ease;
    }

    &:hover {
      cursor: pointer;
      background: $blue-print-700;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
      .glyphicon {
        color: $blue-print-200 !important;
        transform: translateX(0.1em);
      }
    }
  }

  .sidepanel-tabs {
    background: $flame-white;
    border-right: 1px solid $cool-gray-300;

    &.sidepanel-mobile {
      border-right: unset;
    }

    position: sticky;
    top: 0;
    flex: 0 0 auto;
    transform: translatex(-100vw);
    transition: transform 0.1ms;
    overflow: hidden;
    height: 100vh;
    max-width: 250px;

    font-weight: 500;

    .tab-fog {
      cursor: pointer;
      -webkit-backdrop-filter: saturate(180%) blur(1px);
      backdrop-filter: saturate(180%) blur(1px);
      background-color: rgba($blue-print-700, 0.45);
      height: 5em;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateY(-3em);
      display: none;
    }

    .sidepanel-toggle {
      position: absolute;
      height: 2em;
      background-color: white;
      width: 2em;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $cool-gray-500;

      transition:
        background-color 0.1s ease,
        border-color 0.1s ease;

      .chevron-icon {
        color: $cool-gray-500;
        font-size: 25px;
        margin-top: 1px;
      }

      &.sidepanel-open {
        top: 1em;
        right: 0.5em;
        .chevron-icon {
          margin-right: 1.8px;
        }

        &.mobile {
          position: fixed;
          top: 0em;
          left: 0em;
        }
      }

      &.sidepanel-closed {
        top: 1em;
        right: 1.15em;
        .chevron-icon {
          margin-left: 1.8px;
        }

        &.mobile {
          position: fixed;
          top: 2em;
          left: 2em;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $pitch-black; //bootstrap info
        border-color: $pitch-black; //bootstrap info
        .chevron-icon {
          color: $level-yellow;
        }
      }
    }

    &.sidepanel-open {
      transform: translatex(0);
      .tabs-scroll {
        height: calc(100% - 4.5em);
      }
    }

    &.sidepanel-closed {
      transform: translatex(0);

      &.sidepanel-mobile {
        width: 0;
        padding-left: 20px;
      }

      .tabs-scroll {
        height: calc(100% - 4.5em);
      }
    }

    .tab-container {
      margin-bottom: 20vh;

      .sidepanel-closed-spacer {
        height: 4em;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid $cool-gray-300;
      }
    }
  }

  .all-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    .side-panel-heading {
      width: auto;
      height: 4em;
    }
  }

  .sidepanel-toggle-mobile {
    height: 2em;
    background-color: $cool-gray-100;
    width: 2em;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $cool-gray-500;

    &.closed {
      position: fixed;
      font-weight: 500;

      &.has-header {
        top: 3em;
        left: 0.5em;
      }

      &.no-header {
        top: 0.5em;
        left: 0.5em;
      }

      .fa-bars-icon {
        color: $cool-gray-500;
        font-size: 24px;
        margin-top: 1.5px;
      }
    }

    &.open {
      position: absolute;
      top: 1.2em;
      right: 0.5em;

      .glyphicon-remove-icon {
        color: $cool-gray-500;
        font-size: 20px;
        margin-top: 4.5px;
      }
    }
  }

  .sidepanel-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1 1 100%;
    height: 100%;
    overflow: hidden;
    border-left-color: $surface-200;
    border-left-width: 1px;

    &.sidepanel-closed.sidepanel-mobile {
      border-left-width: 0;
    }

    &.no-header-with-side-shown {
      display: none;
    }

    > .panel-container + .panel-container {
      border-left: 2px solid $cool-gray-300;
    }
  }
}

.sidepanel-spacer {
  width: 85%;
  height: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-bottom: solid 1px $cool-gray-300;
}

a.sidepanel--tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  color: $cool-gray-700 !important;
  font-size: 1.1em;
  user-select: none;
  transition: color 0.1s ease;

  font-weight: 500;

  .sidepanel-tab-container {
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
  }

  .icon {
    font-size: 0.8em;
    width: 1.8em;
    max-width: 1.8em;
    min-width: 1.8em;
    padding-right: 1em;
  }
  &.closed {
    color: $cool-gray-600 !important;
  }
  &:hover {
    color: #1f92fc !important; //bootstrap info
  }
  &.active {
    background-color: #edf8ff;
    color: #1f92fc !important; //bootstrap info
  }
}

$transition: transform 0.2s ease-in;

.sidepanel-container.sidepanel-mobile {
  max-width: unset;
  max-height: unset;

  .sidepanel-tabs {
    max-width: unset;
  }

  &.sidepanel-open {
    .sidepanel-tabs {
      transform: translateY(3rem);
      border-radius: 1em;
      box-shadow: 0 -10px 10px rgba($pitch-black, 0.3);
      overflow: visible;
      width: 100vw;

      .tab-fog {
        display: block;
      }

      .tabs-scroll {
        padding: 0 !important;
        border-radius: 1em;
      }
      section.section--container.card-section {
        padding: 0 !important;
      }
    }
    .sidepanel-content {
      transform: translateY(-1rem);
    }
  }
}
</style>
