<template>
  <div class="spin-container" key="spinner" :style="containerStyles">
    <template v-if="loadingLocal">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SpinContainer',
  data() {
    return {
      progress: 0,
      loadingLocal: this.loading,
      boundingSize: 100,
      hideInitial: this.cloak === null ? !!this.loading : this.cloak,
      rect: null
    }
  },
  computed: {
    containerStyles() {
      const pixel = /^[0-9]$/.test(String(this.size || '20'))
      const size = `${this.size}${pixel ? 'px' : ''}`
      return `
          ${
            this.$slots.default && this.$slots.default().length
              ? `min-height: 100%;
                min-width: 100%;`
              : `height: ${size};
                width: ${size};
                max-height: ${size};
                max-width: ${size};
                min-height: ${size};
                min-width: ${size};`
          }
          ${this.styles}`
    }
  },
  watch: {
    loading(val) {
      this.loadingLocal = val
    },
    loadingLocal(val) {
      if (val === 1) this.getSize()
      if (+val === 0) this.hideInitial = false
    }
  },
  methods: {
    getSize() {
      this.rect =
        this.$refs.boundingClient && this.$refs.boundingClient.getBoundingClientRect
          ? { ...this.$refs.boundingClient.getBoundingClientRect() }
          : {}

      if (this.size) {
        this.boundingSize = this.size
      } else if (Object.keys(this.rect).length && Math.max(this.rect.width, this.rect.height)) {
        this.boundingSize = Math.min(this.rect.width, this.rect.height)
      } else {
        this.boundingSize = 100
      }
    },
    removeLoading() {
      this.loadingLocal -= 1
      if (this.loadingLocal === 0) this.hideInitial = false
    },
    endLoading() {
      this.loadingLocal = 0
      if (this.loadingLocal === 0) this.hideInitial = false
    },
    addLoading() {
      this.loadingLocal += 1
    },
    setProgress(progress) {
      this.progress = progress
    }
  },
  mounted() {
    this.getSize()
  },
  props: {
    size: {
      default: '1em'
    },
    loading: {
      required: false,
      default: 0
    },
    styles: {
      default: ''
    },
    cloak: {
      default: null
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.spin-container {
  max-height: 3em;
  max-width: 3em;
  height: 3em;
  width: 3em;
  display: inline-block;
  position: relative;
  overflow: visible;

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    padding: 0.1em;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $cool-gray-800;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @each $color, $value in $additional-colors {
    &.#{$color} {
      .double-bounce1,
      .double-bounce2 {
        background-color: $value;
      }
    }
  }
}
</style>
