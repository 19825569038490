<template>
  <div v-if="!adyenComponentOpen" class="mt-16 text-center">
    <p class="mb-4">
      Getting paid using Bolster is easier than ever, <br />
      Add a bank account to have funds deposited directly.
    </p>
    <div class="text-center">
      <Btn @click="openAdyenBanking" class="mb-4"> Add Bank Account </Btn>
    </div>
  </div>
  <div v-if="!adyenAccountAdded" id="adyen-bank-kyc" class="mt-6"></div>
</template>
<script>
import AdyenComponents from '@adyen/kyc-components'
import BankAccountMixin from '@/components/payments/bank/BankAccountMixin'

export default {
  name: 'CounterpartyOnboardingBankAccount',
  mixins: [BankAccountMixin],
  data() {
    return {
      adyenComponentOpen: false,
      adyenAccountAdded: false
    }
  },
  emits: ['success'],
  methods: {
    async openAdyenBanking() {
      this.adyenAccountAdded = false
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'counterparty/fetchSessionPayee'
      })
      const adyenKycHandler = new AdyenComponents({
        locale: 'en-US',
        country: payload.country.toUpperCase(),
        openBankingPartnerConfigId: 'Bolster_CostCertified',
        environment: import.meta.env.VITE_ADYEN_COMPONENT_ENV,
        sdkToken: payload.token,
        getSdkToken: () => {
          return this.openAdyenBanking()
        }
      })
      // Create instance
      adyenKycHandler
        .create('createTransferInstrumentComponent', {
          legalEntityId: payload.externalPayeeId,
          settings: {
            allowIntraRegionCrossBorderPayout: true, // Optional
            allowBankAccountFormatSelection: true // Optional
          },
          onSubmitSuccess: async (submittedData) => {
            const { id } = submittedData
            await this.addAccountToTransferInstrument({
              id
            })
            this.$emit('add-transfer-instrument-success')
            await this.approveCounterparty()
          }
        })
        .mount('#adyen-bank-kyc')
      this.adyenComponentOpen = true
    },
    async approveCounterparty() {
      await this.$store.dispatch('ajax', {
        path: '/counterparty/approveCounterparty',
        data: {
          company_id: this.$store.state.session.company.company_id,
          counterparty_type: 'adyen'
        }
      })
      this.$emit('success')
      this.adyenComponentOpen = false
      this.adyenAccountAdded = true
    }
  }
}
</script>

<style>
@import '@adyen/kyc-components/styles.css';
.adyen-kyc-dropin__content {
  background-color: rgb(244, 243, 244);
}
.adyen-action-bar {
  background-color: rgb(244, 243, 244);
}
</style>
