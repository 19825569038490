<template>
  <div v-show="activeTab.includes('details')" class="flex flex-row justify-center">
    <div class="w-full max-w-6xl pt-4 mx-4">
      <!-- Project selector -->
      <card-section v-if="!invoice_id">
        <template #label>
          <span>Project</span>
        </template>
        <card-list v-if="!noProjects">
          <card-list-field>
            <span>
              <small class="text-info">
                {{ $t('To create an invoice,')
                }}<strong> {{ $t('you must have a booked project first!') }}</strong> <br />
                <br />
                {{
                  $t(
                    "Select a project to create an invoice for.  Project must be marked 'booked' or 'in progress' before it will appear in this list."
                  )
                }}
              </small>
            </span>
            <choose
              :return-array="false"
              :disabled="invoice_id"
              schema="invoice:quote_id"
              :allowDeselect="true"
              :filters="{ quote_status: '!p&&!d&&!g&&!h' }"
              @empty="noProjects = true"
              @not-empty="noProjects = false"
              v-model="quoteId"
            />
          </card-list-field>
        </card-list>
        <warning v-else>
          You do not have any projects to provide an invoice for. Mark a quote as "Booked" first to
          turn it into a project, so that you can create an invoice for it here.
        </warning>
      </card-section>
      <!-- End Project selector -->

      <div v-if="invoice_id" class="flex flex-row justify-between flex-wrap gap-5">
        <card class="flex-1 min-w-[22rem] min-h-[280px]">
          <template #title>
            <font-awesome-icon class="stroke-bold" icon="file-invoice-dollar" />
            <div>Invoice</div>
          </template>
          <template v-if="invoice_id" #subtitle>
            <div>#{{ invoice_id }}</div>
          </template>
          <div v-if="invoice_gross" class="mb-5">
            <div class="text-sm">Amount</div>
            <div class="text-xl font-bold">{{ $f.currency(invoice_gross) }}</div>
          </div>

          <div class="flex flex-row">
            <div v-if="invoice_time">
              <div class="text-sm">Date created</div>
              <div class="text-base">{{ $f.date(invoice_time) }}</div>
            </div>

            <div class="ml-6">
              <div class="text-sm">Date sent</div>
              <div class="text-base">
                {{ invoice_time_sent ? $f.date(invoice_time_sent) : 'Not sent' }}
              </div>
            </div>
          </div>
        </card>
        <card class="flex-1 min-w-[22rem] min-h-[280px]">
          <template #title>
            <div class="flex flex-row justify-between">
              <div>
                <font-awesome-icon icon="user" class="mr-2" fixed-width />
                <div>Client info</div>
              </div>
              <div>
                <div
                  class="text-sm font-light text-blue-print-600 cursor-pointer underline"
                  @click="edit('client', oClient.client_id)"
                >
                  View Details
                </div>
              </div>
            </div>
          </template>
          <div v-if="oClient.user_fname || oClient.user_lname" class="mb-5">
            <div class="text-sm">Name</div>
            <div class="text-md font-bold">{{ `${oClient.user_fname} ${oClient.user_lname}` }}</div>
          </div>
          <div class="flex flex-row flex-wrap">
            <div v-if="oClient.user_email" :class="{ 'mr-6 mb-5': oClient.user_phone }">
              <div class="text-sm">Email</div>
              <div class="text-base">{{ oClient.user_email }}</div>
            </div>

            <div v-if="oClient.user_phone">
              <div class="text-sm">Phone</div>
              <div class="text-base">{{ oClient.user_phone }}</div>
            </div>
          </div>
        </card>

        <card class="flex-1 min-w-[22rem] min-h-[280px]">
          <template #title>
            <div class="flex flex-row justify-between">
              <div>
                <font-awesome-icon icon="paint-roller" class="mr-2" fixed-width />
                <div>Project info</div>
              </div>
              <div>
                <div
                  class="text-sm font-light text-blue-print-600 cursor-pointer underline"
                  @click="edit('quote', quote_id)"
                >
                  View Details
                </div>
              </div>
            </div>
          </template>
          <div v-if="oQuote.quote_name" class="mb-5">
            <div class="text-sm">Project name</div>
            <div class="text-base">{{ oQuote.quote_name }}</div>
          </div>
          <div v-if="oQuote.quote_address" class="mb-5">
            <div class="text-sm">Address</div>
            <div class="text-base">{{ oQuote.quote_address }}</div>
          </div>
        </card>
      </div>

      <!-- Manual pay -->
      <card-section v-if="invoice_status === $">
        <template #label>
          <span>Manual Payment Pending</span>
        </template>
        <card-list>
          <card-list-item>
            <span class="w-100 my-2">
              <div class="mb-1">
                <span> Manual payment requested </span>
                <help class="ml-2" style="position: absolute; top: 1em">
                  <template #title>
                    <span>Manual Payment Pending</span>
                  </template>
                  <span>
                    Mark this invoice as paid if you have received manual payment equal to the
                    amount of the invoice.
                  </span>
                  <span>
                    Request online payment for an instant and secure payment through Bolster. This
                    will notify your client and mark the invoice as unpaid.
                  </span>
                </help>
              </div>
              <small class="text-muted">
                Your client has requested to pay this invoice manually and invoice is currently
                pending.
              </small>
            </span>
            <div class="justify-between md:justify-around w-100 flex flex-row mt-4 mb-2">
              <btn
                class="info"
                style="width: 35%; min-width: 160px"
                @click="markOutstanding"
                :loading="markOutstandingLoading"
                :disabled="markOutstandingLoading === 1"
              >
                Request online payment
              </btn>
              <btn
                class="success"
                style="width: 35%; min-width: 160px"
                @click="markPaid"
                :loading="markPaidLoading"
                :disabled="markPaidLoading === 1"
              >
                <span class="text-white md:hidden">Mark paid</span>
                <span class="text-white hidden md:inline-block">Mark invoice as paid</span>
              </btn>
            </div>
          </card-list-item>
        </card-list>
      </card-section>
      <!-- End manual pay -->

      <spinner v-if="loading" size="5em" :loading="1" />

      <!-- If the quote has been loaded and invoice has not been paid -->
      <template v-if="quote_id && quote && (invoice_id || quote.quote_uninvoiced_gross > 0)">
        <!-- Invoice amounts including percentage of project -->
        <InvoicePresentationAmounts
          v-bind="$props"
          :reference="refId"
          :store="storeName"
          :invoiceSettings="invoiceSettings"
          :type="type"
          v-model="subComponentInterface"
        />
        <!-- End invoice amounts including percentage of project -->

        <!-- Itemized Invoicing -->
        <div>
          <card v-if="itemizedEnabled">
            <span class="flex justify-between items-center w-100">
              <span class="flex flex-col">
                <strong class="mb-1">
                  {{ $t('Itemized invoicing') }}
                </strong>
                <small class="text-muted">
                  {{ $t('If enabled, invoice per item.') }}
                </small>
              </span>
              <span>
                <toggle
                  :disabled="!!invoice_id"
                  @input="(v) => onEnableItemizedInvoicing(v)"
                  v-model="invoiceSettings.enableItemizedInvoicing"
                />
              </span>
            </span>
            <div v-show="invoiceSettings.enableItemizedInvoicing" class="w-100">
              <div
                :class="[
                  'flex justify-between w-100 border-top border-gray py-3',
                  invoice_id ? '' : 'border-bottom'
                ]"
              >
                <div class="flex flex-col">
                  <strong class="mb-1">
                    {{ $t('Display itemized pricing') }}
                  </strong>
                  <small class="text-muted">
                    {{ $t('Allow the client to view the itemized pricing') }}
                  </small>
                </div>
                <toggle v-model="invoiceSettings.showItems" />
              </div>
              <div v-if="!invoice_id" class="mt-4">
                <strong> {{ $t('Select items:') }}</strong>
                <ItemizedList
                  ref="itemizedList"
                  @on-change="recalculateInvoice"
                  :normalized="normalizedQuote"
                  :quoteId="quote_id"
                  v-if="normalizedQuote"
                />
              </div>
            </div>
          </card>
        </div>
        <!-- End Itemized Invoicing -->

        <!-- Invoice due date and date -->
        <card-section>
          <template #label>
            <span> Dates </span>
          </template>
          <card-list>
            <card-list-field>
              <span
                >{{ $t('Invoice date') }}
                <br />
                <small>{{
                  $t('Date when invoice created or when project completed.  Defaults to today.')
                }}</small>
              </span>

              <Calendar v-model="invoiceTimeAsDate" showIcon iconDisplay="input" />
            </card-list-field>

            <card-list-field>
              <span>
                {{ $t('Due') }}

                <small class="text-info">
                  {{ $t('Due on') }} <strong>{{ $f.date(invoice_time_due) }}</strong>
                </small>
              </span>

              <choose
                :return-array="false"
                v-model="dueTime"
                :staticSet="invoiceTimeOutOptions"
                schema="quote:quote_name"
                :default="30"
              />
            </card-list-field>

            <card-list-field v-if="dueTime === 'custom'">
              <span>
                {{ $t('Due date') }}
                <br />
                <small>Date that this invoice is payable by the client.</small>
              </span>

              <Calendar v-model="invoiceDueAsDate" showIcon iconDisplay="input" />
            </card-list-field>
          </card-list>
        </card-section>
        <!-- End Invoice due date and date -->

        <!-- Invoice payment settings -->

        <PaymentSettings
          v-if="isPaymentsOnboarded"
          :hideSettlementTime="true"
          :hideAggregatedPayments="true"
          :hideDisplayItemizedPricing="true"
          @change="(changes) => (invoiceSettings = changes)"
          :settings="invoiceSettings"
        />

        <!-- End invoice payment settings -->

        <!-- Invoice description -->
        <card-section>
          <card-list>
            <card-list-field>
              <span>Message/description</span>
              <field type="textarea" v-model="invoice_desc" />
            </card-list-field>
          </card-list>
        </card-section>
        <!-- End invoice description -->

        <!-- Invoice totals -->
        <InvoicePresentationTotals
          v-bind="$props"
          :reference="refId"
          :store="storeName"
          :quoteId="quoteId"
          :type="type"
          v-model="subComponentInterface"
          :selectedItems="invoiceItems || selectedItems"
        />
        <!-- End Invoice totals -->
      </template>
      <!-- End if the quote has been loaded and invoice has not been paid -->

      <!-- Invoice has already been paid -->
      <template
        v-else-if="
          quote && quote.quote_uninvoiced_gross < 0.01 && quote.quote_uninvoiced_gross !== null
        "
      >
        <warning>
          <span> That project is fully invoiced already.. </span>
        </warning>
      </template>
      <!-- End invoice has already been paid -->
    </div>
  </div>
  <div v-show="activeTab.includes('pdf')" class="flex flex-row justify-center">
    <div class="w-full max-w-6xl">
      <card v-if="!invoice_id">
        <template #icon>
          <span class="file-pdf"></span>
        </template>
        <template #title> This invoice must be saved before you can preview it. </template>
        <btn class="btn btn-primary" ref="forceSaveBtn" :loading="loading" @click="save">
          Save now?
        </btn>
      </card>
      <template v-else>
        <card-section>
          <div class="flex justify-end">
            <BtnBar size="lg" severity="tertiary" :actions="menuActions" :collapse="false" />
          </div>
          <PdfViewer :supportForPDF="supportsPDFs" :download="savePDF" />
        </card-section>
      </template>
    </div>
  </div>
  <div
    v-if="activeTab.includes('invoiceChat')"
    class="h-full w-full flex items-center justify-center"
  >
    <div v-if="invoice_id" class="h-full w-full">
      <ProgressSpinner v-if="initInvoiceChannelLoading" />
      <ActivityChannelBody
        channelType="INVOICE"
        :channelTypeId="invoice_id"
        :userId="$store.state.session.user.user_id"
        v-else
      />
    </div>
    <div v-else class="w-full max-w-6xl">
      <card class="m-10">
        <template #icon>
          <font-awesome-icon icon="newspaper" />
        </template>
        <template #title>Activities</template>
        <p>Save this invoice first to see related activities.</p>
        <p>
          <btn class="btn btn-primary" ref="forceSaveBtn" :loading="loading" @click="save"
            >Save now?</btn
          >
        </p>
      </card>
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import ButtonMixin from '@/components/mixins/Button'
import UserMeta from '@/components/mixins/UserMeta'
import PdfViewer from '../PdfViewer/PdfViewer.vue'
import InvoicePDFMixin from '../invoice/InvoicePDFMixin.js'
import PayfacMixin from '../payments/PayfacMixin'
import PaymentSettings from '../payments/settings/PaymentSettings.vue'

import InvoicePresentationTotals from '../invoice/presentation/InvoicePresentationTotals.vue'
import InvoicePresentationAmounts from '../invoice/presentation/InvoicePresentationAmounts.vue'

import ItemizedMixin from '../invoice/itemized/ItemizedMixin'
import ItemizedList from '../invoice/itemized/ItemizedList.vue'

import defaultSettings from '../payments/settings/defaultSettings'
import eventBus from '../../eventBus'
import { useDeviceStore } from '@/stores/device'
import ActivityChannels from '@/components/mixins/ActivityChannels'
import ActivityChannelBody from '@/components/Activities/ActivityChannelBody.vue'

Calendar.compatConfig = { MODE: 3 }

export default {
  name: 'Invoice',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [
    ObjectManipulator('invoice', true, 'Invoice'),
    BodyMixin,
    UserMeta,
    ButtonMixin,
    InvoicePDFMixin,
    ItemizedMixin,
    PayfacMixin,
    ActivityChannels
  ],

  data() {
    return {
      activeNames: [],
      noProjects: false,
      docType: 'invoice',
      invoice: null,
      invoiceTimeOutOptions: [
        {
          value: 'custom',
          text: 'Custom'
        },
        {
          value: '30',
          text: '30 days'
        },
        {
          value: '0',
          text: 'Immediately'
        },
        {
          value: '15',
          text: '15 days'
        },
        {
          value: '7',
          text: '7 days'
        },
        {
          value: '5',
          text: '5 days'
        },
        {
          value: '60',
          text: '60 days'
        },
        {
          value: '90',
          text: '90 days'
        }
      ],
      dueTimeLocal: null,
      hasAccessToExporter: false,
      markOutstandingLoading: 0,
      markPaidLoading: 0,
      invoiceSettings: {
        enableItemizedInvoicing: 0,
        showItems: 0
      },
      normalizedQuote: {},
      selectedItems: null,
      tabOptions: ['details', 'pdf', 'invoiceChat']
    }
  },

  computed: {
    activeTab() {
      const rtq = this.$route.query && this.$route.query.tab
      if (rtq && this.tabOptions.includes(rtq)) {
        const arr = c.makeArray(rtq)
        if (arr.includes('pdf')) {
          this.renderDoc()
          c.throttle(
            () => {
              this.setBlobUrl()
            },
            { delay: 800 }
          )
        }
        return arr
      }
      return ['details']
    },
    menuActions() {
      return [
        {
          icon: 'rotate-right',
          class: 'btn muted',
          action: () => this.reloadInvoice(),
          text: 'Reload'
        },
        {
          icon: 'cloud-arrow-down',
          class: 'btn muted',
          action: () => this.downloadURL(this.docDownloadUrl, `${this.invoice_name}-invoice.pdf`),
          text: 'Download'
        }
      ]
    },
    $() {
      return this.$store.getters.$
    },
    invoiceTimeAsDate: {
      get() {
        return new Date(this.invoice_time)
      },
      set(val) {
        this.invoice_time = val.getTime()
        this.setDueTime(this.dueTime)
      }
    },
    invoiceDueAsDate: {
      get() {
        return new Date(this.invoice_time_due)
      },
      set(val) {
        this.invoice_time_due = val.getTime()
      }
    },
    dueTime: {
      get() {
        return (
          this.dueTimeLocal ||
          String(this.userMeta.invoiceTimeOut === null ? '30' : this.userMeta.invoiceTimeOut)
        )
      },
      set(val) {
        this.dueTimeLocal = val
        this.setDueTime(val)
        this.setMetaItem('invoiceTimeOut', val)
      }
    },
    docDownloadUrl() {
      return c.link(`invoice/download/${this.docType}/${this.invoice_id}`)
    },
    docViewUrl() {
      return c.link(`invoice/view/${this.docType}/${this.invoice_id}`)
    },
    classStatus() {
      return c.statusColors[this.invoice_status]
    },
    classStatusQuote() {
      return c.statusColors[this.quote.quote_status]
    },
    status() {
      if (this.invoice_time_due < new Date().valueOf() && this.invoice_status === 'o') {
        return 'PAST DUE'
      }
      return c.format(this.invoice_status, 'status')
    },
    statusQuote() {
      return c.format(this.quote.quote_status, 'status')
    },
    quoteId: {
      set(v) {
        if (c.eq(v, this.quote_id, 0)) {
          return
        }
        this.setQuote(v)
      },
      get() {
        return this.quote_id
      }
    },
    invoiceActions() {
      const actions = [
        {
          icon: 'cloud-arrow-down',
          class: 'btn muted',
          action: () => this.downloadURL(this.docDownloadUrl, `${this.invoice_name}-invoice.pdf`),
          text: 'Download'
        },
        {
          icon: 'paper-plane',
          class: 'btn info',
          action: this.sendInvoice,
          text: 'Send'
        }
      ]

      if (this.hasAccessToExporter) {
        actions.splice(1, 0, {
          icon: 'sync',
          class: 'btn text-primary',
          action: this.syncInvoice,
          text: 'Sync with QuickBooks'
        })
      }

      return actions
    },
    invoiceStatus() {
      if (!this.invoice_status) return ['Uninvoiced', 'warning']
      switch (this.invoice_status) {
        case 'o':
          return ['Unpaid', 'danger']
        case '$':
        case 'p':
          return ['Pending', 'secondary']
        case 'e':
          return ['Paid', 'success']
        default:
          return ['', 'muted']
      }
    }
  },

  watch: {
    refId() {
      this.invoice = this.norm[this.refId]
    },
    invoiceSettings: {
      handler() {
        this.oSettings = { ...this.oSettings, ...this.invoiceSettings }
      },
      deep: true
    }
  },
  methods: {
    async edit(type, id) {
      if (!type || !id) {
        await this.$store.dispatch(
          'alert',
          {
            message: 'Could not find what you are looking for.',
            error: true
          },
          { root: true }
        )
      }
      return this.$store.dispatch('edit', { type, id })
    },
    onEnableItemizedInvoicing(isOn) {
      if (isOn) {
        this.invoice_gross = 0
        this.recalculateInvoice(this.$refs.itemizedList.selected)
      }
    },
    recalculateInvoice(refIds) {
      const calculations = this.recalculate(this.normalizedQuote, refIds)
      const discount = c.toNum(this.quote.quote_discount_percentage)
      const discountAmount = calculations.net * discount
      const discountedGross = calculations.gross - discountAmount
      this.invoice_gross = Math.min(discountedGross, this.quote.quote_uninvoiced_gross)
      this.selectedItems = this.normalizeSubSet(refIds, this.normalizedQuote)
    },
    async setupSettings() {
      if (!Object.keys(this.oSettings).length && this.isPaymentsOnboarded) {
        try {
          const { payload } = await this.$store.dispatch('ajax', {
            path: '/counterparty/fetchWithCompanyId'
          })
          if (payload && payload.oSettings) {
            this.oSettings =
              typeof payload.oSettings === 'string'
                ? JSON.parse(payload.oSettings)
                : payload.oSettings
          }
        } catch (e) {
          this.$store.dispatch(
            'alert',
            {
              message:
                'Could not fetch your default payment settings. Please review this section in the invoice.'
            },
            { root: true }
          )
        }
      }
      // set default settings
      this.invoiceSettings = {
        ...defaultSettings,
        ...this.invoiceSettings,
        ...(this.oSettings || {})
      }
    },
    setDoesPayeeCoverCreditCardFee(doesPayeeCoverCreditCardFee) {
      this.doesPayeeCoverCreditCardFee = doesPayeeCoverCreditCardFee
      this.oSettings = {
        payCostCertifiedFee: this.doesPayeeCoverBolsterFee,
        payCreditCardFee: this.doesPayeeCoverCreditCardFee
      }
    },
    setDoesPayeeCoverCostCertifiedFee(doesPayeeCoverBolsterFee) {
      this.doesPayeeCoverBolsterFee = doesPayeeCoverBolsterFee
      this.oSettings = {
        payCostCertifiedFee: this.doesPayeeCoverBolsterFee,
        payCreditCardFee: this.doesPayeeCoverCreditCardFee
      }
    },
    async setQuote(v) {
      this.quote_id = v
      this.addLoading()

      await this.$store.dispatch('Invoice/setQuote', {
        store: this.storeName,
        refId: this.refId,
        quote: v,
        audit: false,
        auditLocal: false
      })

      this.setDefaults()

      await this.$nextTick()
      this.addDefaults()

      this.removeLoading()

      this.setItemizedItems()

      this.isDirty = true
    },
    setDueTime(val) {
      if (val !== 'custom' && /[0-9]/.test(String(val))) {
        const timeLater = _.n(val) * (1000 * 60 * 60 * 24)
        this.invoice_time_due = (this.invoice_time || new Date().valueOf()) + timeLater
      }
    },
    downloadURL() {
      this.savePDF()
    },
    sendInvoice() {
      return this.$store.dispatch('Invoice/send', { refId: this.refId })
    },
    async reloadInvoice() {
      this.addLoading()
      await this.renderDoc()
      this.removeLoading()
    },
    syncInvoice() {
      return this.$store.dispatch('ExportToken/sync', {
        id: this.invoice_id,
        type: 'invoice',
        exporter: 'quickbooks'
      })
    },
    printInvoice() {
      if (this.deviceStore.isMobileBrowser) {
        this.downloadURL(this.invoiceDownloadUrl, `${this.invoice_name}-invoice.pdf`)
      }
      return this.$refs.doc.print()
    },
    beforeSave() {
      this.aoItems = this.selectedItems
      this.$store.dispatch(
        'clearCache',
        {
          type: ['quote', 'client', 'invoice']
        },
        { root: true }
      )
    },
    async afterSave() {
      const id = this.invoice_id
      if (
        !this.invoice_time_sent &&
        (await this.$store.dispatch('modal/asyncConfirm', {
          message: 'This invoice has never been sent, would you like to send it?'
        }))
      ) {
        this.$store.dispatch('Invoice/send', {
          id
        })
      }
    },
    setDefaults() {
      this.invoice_time = this.invoice_time || new Date().valueOf()
      if (!this.invoice_time_due) {
        this.setDueTime(this.userMeta.invoiceTimeOut || 0)
      }
      setTimeout(() => this.removeBodyLoading(), 0)
    },

    gotToPayments() {
      eventBus.$emit('closeModal')
      this.$router.push(`/${this.$route.params.scopeRoute}/payments`)
    },
    async markOutstanding() {
      if (this.markOutstandingLoading === 1) return
      this.markOutstandingLoading = 1
      try {
        await this.$store.dispatch('ajax', {
          path: '/gateway/reversePayManually',
          data: {
            invoice_id: this.invoice_id,
            is_contractor: true
          }
        })

        // fetch new amounts for invoice chart
        this.reload()
        this.markOutstandingLoading = 0
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not mark invoice as outstanding. Please try again.',
          error: true
        })
        this.markOutstandingLoading = 0
      }
    },
    async markPaid() {
      if (this.markPaidLoading === 1) return
      this.markPaidLoading = 1
      try {
        await this.$store.dispatch('Invoice/markMultiple', {
          markAs: 'Paid',
          selected: [
            {
              type: 'invoice',
              invoice_id: this.invoice_id
            }
          ],
          alert: true,
          go: false
        })

        // fetch new amounts for invoice chart
        this.reload()
        this.markPaidLoading = 0
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not mark invoice as paid. Please try again.',
          error: true
        })
        this.markPaidLoading = 0
      }
    }
  },

  components: {
    ActivityChannelBody,
    PdfViewer,
    InvoicePresentationTotals,
    InvoicePresentationAmounts,
    PaymentSettings,
    ItemizedList,
    Calendar
  },

  props: {
    isNewInvoice: {
      type: Boolean,
      default: false
    }
  },

  async mounted() {
    eventBus.$on(`${this.uid}-selected`, () => {
      // setup the settings
      this.setDefaults()
      this.setupSettings()
      this.setItemizedItems()
      this.initInvoiceChannel(
        this.$store.state.session.user.user_id,
        this.$store.state.session.user.user_fname,
        this.$store.state.session.user.user_lname,
        this.invoice_id,
        this.invoice_name
      )
    })
  },

  async created() {
    this.forceHideTransactionFee = this.invoice_status === 'o'
    eventBus.$on(`${this.uid}-embued`, () => {
      this.addBodyLoading()
      this.quoteId = this.quote_id
      this.setItemizedItems()
      this.setDefaults()
      setTimeout(() => this.removeBodyLoading(), 0)
    })
    const { payload } = await this.$store.dispatch('ExportToken/hasAccess', {
      type: 'quickbooks'
    })
    const { value: verification } = payload
    this.hasAccessToExporter = verification
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
    eventBus.$off(`${this.uid}-embued`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.multibar {
  margin: 4em 1em 4em 1em;
  > .progress {
    width: 100%;
  }
}
.line {
  width: 100%;
  height: 2px;
  background-color: darkgray;
}

.tab-container {
  padding-bottom: 9rem;
}

.amount-row {
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  align-content: center;
  > div {
    &.or {
      flex-grow: 0;
      margin: 0 2em;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    &:not(.or) {
      flex-basis: 50%;
      flex-grow: 10;
    }
  }
}

.invoice-pdf {
  height: 100vh;
  min-height: 800px;
  width: 100%;
}
h4 {
  font-weight: normal;
}
</style>
