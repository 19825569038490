<template>
  <preview
    v-bind="$props"
    v-if="object"
    class="quote-preview--container"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <div style="flex: 1 100%; overflow: hidden; text-overflow: ellipsis; text-align: left">
        <DimensionBadge :dim="object" size="lg" :showSuggestedMeasure="false">
          <template #before>
            <font-awesome-icon
              v-if="!object.company_id"
              icon="globe"
              style="margin-left: -0.15em"
              v-tooltip="'This is a public/global item.'"
              class="circled mr-1"
            />
          </template>
        </DimensionBadge>
      </div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'
import DimensionBadge from '../DimensionBadge.vue'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewDimension',
  components: {
    DimensionBadge,
    Preview
  },
  emits: ['click']
}
</script>

<style rel="stylesheet/scss" lang="scss"></style>
