<template>
  <img :src="link" @click="() => $emit('click')" />
</template>

<script>
export default {
  name: 'Thumb',
  emits: ['click'],

  data() {
    return {
      link: ''
    }
  },

  computed: {
    truncated() {
      if (this.truncate && this.name.length > this.truncate) {
        return `${this.name.slice(0, this.truncate)}...`
      }
      return this.name
    },

    store() {
      return c.titleCase(this.type)
    }
  },

  async mounted() {
    const key = `thumbs.${this.type}.${this.id}.${this.url}`
    if (!c.getStorage(key)) {
      const link = this.url || c.link(`${this.type}/thumb/${this.id}`)
      c.setStorage(key, link)
    }

    this.link = c.getStorage(key)
  },

  props: {
    type: {
      type: String,
      default: 'file'
    },

    url: {
      type: String,
      required: false
      // if not provided, provide id
    },

    id: {
      required: false
      // if not provided, must provide url
    }
  }
}
</script>

<style scoped></style>
