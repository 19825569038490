 // This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'export_settings',
  fields: {
    export_settings_export_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    export_settings_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    export_settings_sync_type: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    export_settings_sync_date: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    export_settings_last_time_exported: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    export_settings_quickbooks_realm_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    export_settings_freshbooks_account_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
  },
};
