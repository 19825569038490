/**
 * Created this object to lookup stripe error codes to display an error that makes sense
 */

export default {
  expired_card: {
    description: 'The card has expired.',
    actions: 'Please use another card.'
  },
  authentication_required: {
    description: 'The card was declined as the transaction requires authentication.',
    actions:
      'Please try to reauthenticate your card. If the card issuer declines your card again, contact your card issuer for more information.'
  },
  approve_with_id: {
    description: 'The payment can’t be authorized.',
    actions:
      'Attempt the payment again. If the payment method is sill not processed, you will need to contact your card issuer.'
  },
  call_issuer: {
    description: 'The card was declined for an unknown reason.',
    actions: 'You will need to contact your card issuer for more information.'
  },
  card_not_supported: {
    description: 'The card does not support this type of purchase.',
    actions:
      'Please contact your card issuer and make sure your card can be used to make this type of purchase.'
  },
  card_velocity_exceeded: {
    description: 'You have exceeded the balance or credit limit available on their card.',
    actions: 'Please contact your card issuer for more information.'
  },
  currency_not_supported: {
    description: 'The card does not support the specified currency.',
    actions:
      'Please check with the issuer whether the card can be used for the type of currency specified.'
  },
  do_not_honor: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  do_not_try_again: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  duplicate_transaction: {
    description:
      'A transaction with identical amount and credit card information was submitted very recently.',
    actions: 'Please contact support to check and see if a recent payment already exists.'
  },
  fraudulent: {
    description: 'The payment was declined because it was suspected as fraudulent.',
    actions: 'Please contact your card issuer for more information.'
  },
  generic_decline: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  incorrect_number: {
    description: 'The card number is incorrect.',
    actions: 'Please try again using the correct card number.'
  },
  incorrect_cvc: {
    description: 'The CVC number is incorrect.',
    actions: 'Please try again using the correct CVC.'
  },
  incorrect_pin: {
    description: 'The PIN entered is incorrect.',
    actions: 'Please try again using the correct PIN.'
  },
  incorrect_zip: {
    description: 'The postal code is incorrect.',
    actions: 'Please try again using the correct billing postal code.'
  },
  insufficient_funds: {
    description: 'The card has insufficient funds to complete the purchase.',
    actions: 'Please use an alternative payment method.'
  },
  invalid_account: {
    description: 'The card, or account the card is connected to, is invalid.',
    actions: 'Please contact your card issuer to check that the card is working correctly.'
  },
  invalid_amount: {
    description: 'The payment amount is invalid, or exceeds the amount that’s allowed.',
    actions:
      'If the amount appears to be correct, please check with your card issuer that you can make purchases of that amount.'
  },
  invalid_cvc: {
    description: 'The CVC number is incorrect.',
    actions: 'Please try again using the correct CVC.'
  },
  invalid_expiry_month: {
    description: 'The expiration month is invalid.',
    actions: 'Please try again using the correct expiration month.'
  },
  invalid_expiry_year: {
    description: 'The expiration year is invalid',
    actions: 'Please try again using the correct expiration year.'
  },
  invalid_number: {
    description: 'The card number is incorrect.',
    actions: 'Please try again using the correct card number.'
  },
  invalid_pin: {
    description: 'The PIN entered is incorrect.',
    actions: 'Please try again using the correct PIN.'
  },
  issuer_not_available: {
    description: 'The card issuer couldn’t be reached, so the payment couldn’t be authorized.',
    actions:
      'Attempt the payment again. If you still can’t process it, please contact your card issuer.'
  },
  lost_card: {
    description: 'The payment was declined because the card is reported lost',
    actions: 'You will need to contact your card issuer for more information.'
  },
  merchant_blacklist: {
    description: 'The payment was declined because it has been black listed.',
    actions: 'Please contact your card issuer for more information.'
  },
  new_account_information_available: {
    description: 'The card, or account the card is connected to, is invalid.',
    actions: 'Please contact your card issuer for more information.'
  },
  no_action_taken: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  not_permitted: {
    description: 'The payment isn’t permitted.',
    actions: 'Please contact your card issuer for more information.'
  },
  offline_pin_required: {
    description: 'The card was declined because it requires a PIN.',
    actions: 'Please try again by inserting their card and entering a PIN.'
  },
  online_or_offline_pin_required: {
    description: 'The card was declined as it requires a PIN.',
    actions:
      'If the card reader supports Online PIN, prompt the customer for a PIN without creating a new transaction. If the card reader doesn’t support Online PIN, the customer needs to try again by inserting their card and entering a PIN.'
  },
  pickup_card: {
    description:
      'You can’t use this card to make this payment (it’s possible it was reported lost or stolen).',
    actions: 'Please contact your card issuer for more information.'
  },
  pin_try_exceeded: {
    description: 'The allowable number of PIN tries was exceeded.',
    actions: 'Please use another card or method of payment.'
  },
  processing_error: {
    description: 'An error occurred while processing the card.',
    actions:
      'The payment needs to be attempted again. If it still can’t be processed, try again later.'
  },
  reenter_transaction: {
    description: 'The payment couldn’t be processed by the issuer for an unknown reason.',
    actions:
      'The payment needs to be attempted again. If it still can’t be processed, the customer needs to contact their card issuer.'
  },
  restricted_card: {
    description:
      'You cannot use this card to make this payment (it’s possible it was reported lost or stolen).',
    actions: 'Please contact your card issuer for more information.'
  },
  revocation_of_all_authorizations: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  revocation_of_authorization: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  security_violation: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  service_not_allowed: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  stolen_card: {
    description: 'The payment was declined because the card is reported stolen.',
    actions: 'Please contact your card issuer for more information.'
  },
  stop_payment_order: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  testmode_decline: {
    description: 'A Stripe test card number was used.',
    actions: 'A genuine card must be used to make a payment.'
  },
  transaction_not_allowed: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  },
  try_again_later: {
    description: 'The card was declined for an unknown reason.',
    actions:
      'Please attempt the payment again. If subsequent payments are declined, please contact your card issuer for more information.'
  },
  withdrawal_count_limit_exceeded: {
    description: 'You have exceeded the balance or credit limit available on their card.',
    actions: 'Please try to use an alternative payment method.'
  },
  default_code: {
    description: 'The card was declined for an unknown reason.',
    actions: 'Please contact your card issuer for more information.'
  }
}
