<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    :isDirty="bodyIsDirty"
    :scrollable="false"
    :clickAway="true"
    :expandable="false"
    :hasHeaderBorder="true"
  >
    <template #header> Create a new estimate </template>
    <template #body>
      <quote-body
        ref="body"
        @closeModal="$refs.modal.close()"
        :object="object"
        :clientId="modal.embue.client_id"
        @embued="() => ($refs.body.quote_status = 'p')"
      />
    </template>
  </modal>
</template>

<script>
import QuoteBody from '../bodies/QuoteNew.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'

export default {
  name: 'ModalQuoteNew',
  mixins: [ObjectModalMixin],
  components: { QuoteBody },
  props: {
    type: {
      default: 'quote'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
