<template>
  <Container class="addon-list--container" size="5xl">
    <card-list v-if="addonList.length">
      <template v-for="(addon, index) in addonList" :key="index">
        <card-list-item>
          <div class="addon-list--item-container">
            <btn rounded class="more px-0 p-2 danger mr-2" @click="removeAddon(index)">
              <font-awesome-icon icon="minus" />
            </btn>
            <addon-item
              style="flex-grow: 10"
              :key="addon.key"
              :targetRefId="reference"
              :currentPrice="currentPrice"
              :editable="true"
              :store="store"
              :addon="addon"
              :dimensions="dimensions"
              @replace="(object) => replace(object)"
              @disabled="disableAddon(addon)"
              @addon="(modified) => modifyAddon(addon, modified)"
              @get="(object) => get(object, addon)"
            />
          </div>
        </card-list-item>
      </template>

      <card-list-item v-if="loading">
        <spinner size="3em" class="info" :loading="1" />
      </card-list-item>
    </card-list>

    <template v-else>
      <card>
        <template #icon>
          <font-awesome-icon icon="arrow-trend-up" />
        </template>
        <template #title> Add upgrades or options </template>

        <p class="lead">Supercharge your proposal with upgrades!</p>
        <p>
          On average 83% of customers upgrade their projects when upgrades are available for every
          selection item, which could increase your profit by 7% on average.
        </p>

        <btn v-if="!disabled" class="info block round" :action="() => addUpgradeItem()">
          Add upgrade/option now..
        </btn>

        <btn v-else class="info block round disabled">
          Save item to your catalog to add upgrades
        </btn>
      </card>
    </template>

    <div class="mt-2 ml-3" v-if="addonList.length">
      <btn :loading="loading" :action="() => addUpgradeItem()" class="btn more info xl">
        <font-awesome-icon icon="plus" />
      </btn>
      <btn
        rounded
        :loading="loading"
        v-if="optionals && (target.type === 'assembly' || target.type === 'quote')"
        @click="addOptionalItem"
        class="btn info more px-0 p-2 xl"
      >
        <font-awesome-icon icon="plus" />
      </btn>
    </div>

    <card-section class="addon-list--suggested" v-if="suggestUpgrades && suggestedUpgrades.length">
      <h6 class="text-info">We found some similar items that might make good upgrades/options:</h6>

      <card
        v-for="suggestion in suggestedUpgrades"
        :key="`${suggestion.type}:${suggestion[`${suggestion.type}_id`]}`"
      >
        <div style="width: 100%" class="flex justify-start items-center flex-row">
          <div class="flex justify-center items-center mr-3">
            <btn
              rounded
              class="info more px-0 p-2 lg"
              :action="() => addUpgradeItem(suggestion.type, suggestion[`${suggestion.type}_id`])"
            >
              <font-awesome-icon icon="plus" />
            </btn>
          </div>

          <div class="flex flex-col justify-center items-start" style="flex: 1 100%">
            <strong>
              {{ suggestion.cost_type_name || suggestion.assembly_name }}
            </strong>

            <div class="flex justify-between items-center" style="width: 100%; flex: 1 100%">
              <small class="text-muted">
                {{
                  $f.truncate(
                    suggestion.cost_type_desc ||
                      suggestion.assembly_desc ||
                      suggestion.quote_notes ||
                      '',
                    50
                  )
                }}
              </small>

              <small>
                <strong class="text-monospace">{{
                  $f.currency(suggestion.cost_matrix_rate_net || suggestion.quote_price_net)
                }}</strong>

                <small class="text-muted"> / {{ suggestion.unit_of_measure_abbr }} </small>
              </small>
            </div>
          </div>
        </div>
      </card>

      <btn :loading="loading" :action="() => addUpgradeItem()" class="block info lg round">
        <template #icon>
          <font-awesome-icon icon="plus" />
        </template>

        See more options..
      </btn>
    </card-section>
  </Container>
</template>

<script>
import AddonListMixin from './AddonListMixin'
import AddonItem from './AddonItem.vue'

export default {
  name: 'AddonList',
  mixins: [AddonListMixin],
  watch: {},
  methods: {
    async replace(object) {
      const type = object.type
      const id = object[`${type}_id`]

      const existing = this.value.filter(
        (addon) => String(addon.id) === String(id) && addon.type === type
      ).length

      if (!existing) {
        this.$store.dispatch('alert', {
          message:
            'That item was saved as a new item. It has been added as an upgrade item separately.',
          timeout: 15000
        })
        this.emit([
          ...this.valueLocal,
          await this.getAddonFromObject(type, id, 'replace', 'sibling')
        ])
      }
    }
  },
  components: {
    AddonItem
  },
  props: {
    suggestUpgrades: {
      default: true
    },
    disabled: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.addon-list--container {
  .addon-list--item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
  }
  .addon-list--suggested {
    margin-top: 1em;
  }
}
</style>
