import { createRouter, createWebHistory } from 'vue-router'
import { beforeEachGuard } from '@/router/guards/beforeEach'
import publicRoutes from '@/router/routes/public'
import staticRoutes from '@/router/routes/static'
import companyRoutes from '@/router/routes/company'
import superRoutes from '@/router/routes/super'
import clientRoutes from '@/router/routes/client'
import vendorRoutes from '@/router/routes/vendor'
import developmentRoutes from '@/router/routes/development'
import franchisorRoutes from '@/router/routes/franchisor'
import authenticationRoutes from '@/router/routes/authentication'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...authenticationRoutes,
    ...publicRoutes,
    ...staticRoutes,
    ...companyRoutes,
    ...superRoutes,
    ...clientRoutes,
    ...vendorRoutes,
    ...franchisorRoutes,
    ...(import.meta.env.DEV ? developmentRoutes : [])
  ]
})

/**
 * This function can be used to register any global
 */
export function registerGlobalGuards() {
  router.beforeEach(beforeEachGuard)
}

export default router
