import { ref, computed } from 'vue'
import { Geolocation } from '@capacitor/geolocation'

export default {
  useGeolocation() {
    const location = ref(null)
    const error = ref(null)

    const instructions = computed(() => {
      const userAgent = navigator.userAgent

      if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
        return `
          <span class="font-medium text-lg">Enable Location in Chrome:</span>
          <ul class="list-disc ml-5">
            <li>Click on the padlock icon in the address bar.</li>
            <li>Go to "Site settings".</li>
            <li>Under "Permissions", find "Location" and set it to "Allow".</li>
          </ul>
        `
      } else if (/Firefox/.test(userAgent)) {
        return `
          <span class="font-medium text-lg">Enable Location in Firefox:</span>
          <ul class="list-disc ml-5">
            <li>Click on the padlock icon in the address bar.</li>
            <li>Click on the right arrow next to "Permissions".</li>
            <li>Under "Location", click "Clear Block" or set it to "Allow".</li>
          </ul>
        `
      } else if (/Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor)) {
        return `
          <span class="font-medium text-lg">Enable Location in Safari:</span>
          <ul class="list-disc ml-5">
            <li>Go to "Preferences" > "Websites".</li>
            <li>Find "Location" in the sidebar.</li>
            <li>Set the permission for the site to "Allow".</li>
          </ul>
        `
      } else if (/Edg/.test(userAgent)) {
        return `
          <span class="font-medium text-lg">Enable Location in Edge:</span>
          <ul class="list-disc ml-5">
            <li>Click on the padlock icon in the address bar.</li>
            <li>Go to "Permissions for this site".</li>
            <li>Under "Location", set it to "Allow".</li>
          </ul>
        `
      } else if (/Android/.test(userAgent)) {
        return `
          <span class="font-medium text-lg">Enable Location on Android:</span>
          <ul class="list-disc ml-5">
            <li>Go to Settings > Apps & notifications > App permissions.</li>
            <li>Select "Location" and set your app to "Allow".</li>
          </ul>
        `
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return `
          <span class="font-medium text-lg">Enable Location on iOS:</span>
          <ul class="list-disc ml-5">
            <li>Go to Settings > Privacy > Location Services.</li>
            <li>Make sure "Location Services" is turned on.</li>
            <li>Scroll down and select your app.</li>
            <li>Set the permission to "While Using the App" or "Always".</li>
          </ul>
        `
      } else {
        return `
          <span class="font-medium text-lg">Enable Location:</span>
          <p>Check your browser or device settings to allow location access.</p>
        `
      }
    })

    const captureLocation = async () => {
      error.value = ''
      try {
        const position = await Geolocation.getCurrentPosition()
        location.value = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
        error.value = null
        return location.value
      } catch (err) {
        if (err.code === 1) {
          // Permission denied
          try {
            const permissions = await Geolocation.requestPermissions()
            if (permissions.location === 'denied') {
              error.value = 'Location permission denied. Please enable it in the settings.'
            } else {
              // Try to get the location again after requesting permissions
              try {
                const position = await Geolocation.getCurrentPosition()
                location.value = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
                error.value = null
                return location.value
              } catch (retryErr) {
                error.value = retryErr.message
              }
            }
          } catch (permErr) {
            error.value = permErr.message
          }
        } else {
          error.value = err.message
        }
      }

      return null
    }

    return { location, error, instructions, captureLocation }
  }
}
