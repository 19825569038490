<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :clickAway="false"
    :show-top-close="true"
    :closeable="true"
    :show-close-button="true"
    :expandable="false"
  >
    <template #header>
      <font-awesome-icon icon="drafting-compass" />
      Confirm Dimensions
    </template>
    <template v-if="modal.refId in $store.state[modal.store].normalized" #body>
      <AssemblyConfirmDimensions
        ref="body"
        :key="modal.refId"
        :store="modal.store"
        :object="$store.state[modal.store].normalized[modal.refId]"
        :reference="modal.refId"
      />
    </template>
    <Spinner
      v-if="!(modal.refId in $store.state[modal.store].normalized)"
      size="4em"
      :loading="1"
    />
    <template #footer>
      <div class="flex px-4" style="width: 100%">
        <Btn class="block xl info round" :action="close"> Done </Btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import ObjectModalMixin from '../modals/ObjectModalMixin'
import AssemblyConfirmDimensions from '../bodies/AssemblyConfirmDimensions.vue'

export default {
  name: 'ModalAssemblyConfirmDimensions',
  mixins: [ObjectModalMixin],
  components: { AssemblyConfirmDimensions },
  props: {
    type: {
      default: 'assembly'
    }
  }
}
</script>
