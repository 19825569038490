<template>
  <section class="item-list">
    <div class="grid-cols-5 gap-4 item-list--header hidden md:grid mb-2">
      <div>Name</div>
      <div>Assignees</div>
      <div>Date</div>
      <div>Status</div>
      <div>Priority</div>
    </div>
    <div v-if="list">
      <LaborItem v-for="item in list" :key="item.id" :item="item" />
    </div>
    <div v-if="list && list.length === 0">
      <span
        class="text-cool-gray-500 p-4 text-center block border border-cool-gray-200 rounded-md mt-4"
        >No action items.</span
      >
    </div>
  </section>
</template>

<script setup>
import { defineProps, toRef } from 'vue'
import LaborItem from '@/components/ui/listItem/LaborItem.vue'

const props = defineProps({
  update: {
    type: Function
  },
  list: {
    type: Array
  }
})

const list = toRef(props, 'list')
</script>
<style lang="scss" rel="stylesheet/scss">
.item-list--header {
  > div {
    @apply first:text-left text-sm text-cool-gray-500 text-center;
  }
}
</style>
