<template>
  <Tag
    class="border-0 shadow-sm !text-white cursor-pointer"
    :style="nameStyle"
    @click.native="clickHandler"
  >
    <div @click.stop.prevent="clickHandler" class="flex flex-row items-center">
      <slot name="before"></slot>

      <code>{{ abbr }}</code>
      <template v-if="size !== 'sm'">&nbsp;•&nbsp;{{ dim.dimension_name }}</template>
      <template v-if="showSuggestedMeasure">&nbsp;•&nbsp;{{ suggestedUnitOfMeasure }}</template>

      <slot name="after"></slot>
    </div>

    <help headless ref="help" @click="() => $emit('click')">
      <template #title>{{ dim.dimension_name }}</template>

      <slot name="help-before"></slot>

      <strong>Dimension measure type</strong>
      <p>
        {{ dim.dimension_measure_type }}
      </p>

      <strong>Description</strong>
      <p v-if="dim.dimension_desc">
        {{ dim.dimension_desc }}
      </p>

      <slot>
        <p v-if="actions && Object.keys(actions).length">
          <Btn
            class="round info block"
            v-for="action in actions"
            :key="action.title"
            :action="action.action"
          >
            {{ action.title }}
          </Btn>
        </p>
      </slot>

      <strong>Code</strong>
      <br />
      <a
        style="display: inline-flex; align-items: center; cursor: pointer; border-radius: 0.2em"
        class="py-2 px-4 text-light bg-dark"
        @click="copyToClipboard(abbr)"
      >
        <code class="p-2" style="text-weight: bold">{{ abbr }}</code>
        <font-awesome-icon icon="clipboard-line" />
      </a>
      <div>
        <small class="text-muted">
          This is the code you can use in calculators and dimension fields to link to the related
          dimension.
        </small>
      </div>

      <slot name="help-after"></slot>
    </help>
  </Tag>
</template>

<script>
export default {
  name: 'DimensionBadge',
  emits: ['click'],
  computed: {
    nameStyle() {
      return `background-color: #${this.color};`
    },
    metric() {
      return this.$store.state.session.user.country_id > 2
    },
    abbr() {
      return this.dim.dimension_id || this.dim.dimension_abbr || this.dim.abbr
    },
    color() {
      return c.ensureContrastWithWhite(this.dim.dimension_color || this.dim.color)
    },
    measureType() {
      return this.dim.dimension_measure_type || this.dim.measureType
    },
    suggestedUnitOfMeasure() {
      const metric = this.metric
      const mt = this.measureType

      if (mt === 'length') {
        return metric ? 'm' : 'ft'
      } else if (mt === 'volume') {
        return metric ? 'm3' : 'yd3'
      } else if (mt === 'area') {
        return metric ? 'm2' : 'ft2'
      } else if (mt === 'weight') {
        return metric ? 'kg' : 'lbs'
      }

      return 'each'
    }
  },
  methods: {
    clickHandler() {
      if (!this.openable) {
        this.$emit('click')
        return
      }

      this.$refs.help.open()
    },
    close() {
      this.$refs.help.close()
    },
    open() {
      this.$refs.help.close()
    },
    copyToClipboard(text) {
      this.$store.dispatch('copyToClipboard', text)
    }
  },
  props: {
    dim: {
      type: Object
    },
    size: {
      type: String,
      default: 'md'
    },
    actions: {
      type: Object
    },
    showSuggestedMeasure: {
      type: Boolean,
      default: true
    },
    openable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.dimension-label {
  border-radius: 0.25em;
  padding: 0.3em 0.7em 0.3em 0.7em;
  color: rgba($pitch-black, 0.8);
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.1;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  code {
    font-weight: bold;
    color: $pitch-black;
  }
}
</style>
