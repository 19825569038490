<template>
  <div>
    <div
      v-if="selected && quote.quote_name"
      class="invoice-details--container"
      :class="{ 'sidebar-closed': !$parent.$parent.sidebarOpen }"
    >
      <drop ref="drop" position="bottom left" targetAttachment="bottom left" offset="0 7px">
        <template #button>
          <div
            v-if="$parent.$parent.sidebarOpen"
            class="invoice-name-link"
            v-tooltip="{
              value: 'Click for invoice details',
              showDelay: 700,
              hideDelay: 300
            }"
          >
            <div v-if="!contextual" class="default" :action="edit">
              <span :class="iconClass" class="mr-2"></span>
              {{ invoice_id ? `#${invoice_id}` : 'New invoice' }} •
              {{ $f.truncate(quote.quote_name, 20) }}
              <font-awesome-icon icon="rectangle-history-circle-plus" class="ml-2" />
            </div>

            <div class="invoice-name-link" v-else>
              <span>
                <span :class="iconClass" style="margin-right: 14px"></span>
                <span class="invoice-details-name">{{
                  invoice_id ? `#${invoice_id}` : 'New invoice'
                }}</span>
              </span>
            </div>
          </div>

          <div
            v-else
            class="invoice-name-link closed"
            v-tooltip="{
              content: 'Click for invoice details',
              classes: ['sidepanel-invoice-details-tooltip'],
              placement: 'left',
              delay: { show: 800, hide: 300 }
            }"
          >
            <div class="invoice-details-name closed" @click="() => $parent.$parent.open">
              <span :class="iconClass" style="font-size: 1.4em"></span>
            </div>
          </div>
        </template>

        <div style="margin-bottom: 0.5em">
          <span style="font-weight: 500">Invoice Details</span>
        </div>

        <btn-group class="invoice-button-group">
          <btn
            rounded
            class="more px-0 p-2 info"
            :action="() => $store.dispatch('Quote/call', { object: quote })"
          >
            <font-awesome-icon icon="earphone" />
          </btn>

          <btn
            rounded
            class="more px-0 p-2 info ml-2"
            :action="() => $store.dispatch('Quote/map', { object: quote })"
          >
            <font-awesome-icon icon="map" />
          </btn>

          <btn-bar
            class="ml-2"
            style="padding-top: 8px"
            btnClass="more info"
            offset="-125px 0px"
            :contextual="true"
            :editing="editing"
            :selected="[casted]"
          />
        </btn-group>

        <div class="invoice-details-spacer"></div>

        <div v-if="invoice_time">
          <p class="invoice-info-title">Issued Date</p>
          <p class="invoice-info-data">{{ $f.date(invoice_time) }}</p>
        </div>

        <div v-if="invoice_time_due">
          <p class="invoice-info-title">Due Date</p>
          <p class="invoice-info-data">{{ $f.date(invoice_time_due) }}</p>
        </div>
      </drop>
    </div>

    <div
      class="invoice-details-actions"
      v-if="$parent.$parent.sidebarOpen && invoice_status && invoice_status !== 'e'"
    >
      <next-steps
        v-if="contextual"
        :object="casted"
        :inline="false"
        @done="reload"
        :contextual="true"
        class="vertical"
      />
    </div>
  </div>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'

export default {
  name: 'InvoiceDetails',

  mixins: [ObjectManipulator('invoice', false)],

  methods: {
    edit() {
      return this.$store.dispatch('edit', { type: 'invoice', id: this.quote_id })
    }
  },

  computed: {
    iconClass() {
      switch (this.invoice_status) {
        case 'e':
          return 'check'
        default:
          return 'file-invoice'
      }
    }
  },

  props: {
    contextual: {
      type: Boolean,
      default: true
    },

    deselectOnDestroy: {
      default: false
    },

    editing: {
      default: false
    },

    /**
     * Basic version is fine
     */
    forceFull: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-invoice-details-tooltip {
  position: fixed !important;
  left: 177px !important;
}

.invoice-details--container {
  width: auto;
  height: auto;
  color: $cool-gray-700;
  background-color: #fff;
  text-align: center;
  padding: 1.25em 1.1em;
  border-bottom: 1px solid $cool-gray-300;

  .invoice-name-link {
    width: 213.8px;
    cursor: pointer;
    text-align: start;

    &:hover {
      color: #1f92fc !important; //bootstrap info
    }

    &.closed {
      margin-left: 5px;
      color: $cool-gray-600;
    }
  }

  &.sidebar-closed {
    padding: 0.65em 1.1em;
  }
}

.invoice-details-actions {
  width: auto;
  height: auto;
  text-align: center;
  padding: 0.5em 0.5em;
  background-color: $flame-white;
  border-bottom: 1px solid $cool-gray-300;
}

.invoice-details-name {
  font-size: 1.2em;

  &.closed {
    cursor: pointer;
    color: $cool-gray-600;
    &:hover {
      color: #1f92fc !important; //bootstrap info
    }
  }
}

.invoice-details-spacer {
  width: 193px;
  margin-bottom: 0.5em;
  border-bottom: 2px solid $cool-gray-300;
}

.invoice-info-title {
  font-size: 0.7em;
  margin-top: 0.5em;
  color: $cool-gray-600;
}

.invoice-info-data {
  &:hover {
    color: #1f92fc !important; //bootstrap info
    cursor: pointer;
  }
}

.invoice-button-group {
  margin-left: 32px;
}
</style>
