<template>
  <preview
    v-bind="$props"
    v-if="object"
    class="quote-preview--container"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <div style="flex: 1 100%; overflow: hidden; text-overflow: ellipsis; text-align: left">
        {{ $f.truncate(object.company_name || 'Unnamed', 30) }}
      </div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewVendor',
  components: {
    Preview
  },
  emits: ['click']
}
</script>

<style rel="stylesheet/scss" lang="scss">
.quote-preview--container {
  .secondary {
    width: 100%;
    .number {
      margin-left: auto;
    }
  }

  .has-unapproved {
    background: rgba($orange-500, 0.3);
  }

  .secondary--client-name {
    max-width: 50%;
    flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
