<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :scrollable="false"
    :clickAway="false"
    :expandable="false"
    @close="() => $emit('close', $event)"
    @closed="() => $emit('closed', $event)"
    @open="() => $emit('open', $event)"
    @opened="() => $emit('opened', $event)"
  >
    <template #header>
      <font-awesome-icon icon="file-cloud" />
      Files
    </template>
    <template #body>
      <files-body :filters="filesFilters" />
    </template>
    <template #footer>
      <slot name="footer" v-if="$slots.footer"></slot>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'

export default {
  name: 'ModalFiles',
  selectorFor: 'file',
  emits: ['close', 'closed', 'open', 'opened'],
  computed: {
    filesFilters() {
      return this.modal &&
        this.modal.objects &&
        this.modal.objects.length &&
        this.modal.objects[0].file_is_folder &&
        this.modal.objects[0].file_id
        ? {
            parent_file_id: this.modal.objects[0].file_id
          }
        : {}
    }
  },
  methods: {
    open() {
      this.$refs.modal.open()
    },
    close() {
      this.$refs.modal.open()
    }
  },
  props: {
    ...Modal.props,
    modal: {
      required: false,
      type: Object,
      validator() {
        return true
      }
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
