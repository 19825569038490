<script setup>
import { computed } from 'vue'
import { useSocialIdPs } from '@/composables/socialIdP'
import GoogleSignIn from '@/components/identityProviders/buttons/GoogleSignIn.vue'
import AppleSignIn from '@/components/identityProviders/buttons/AppleSignIn.vue'

const {
  appleIdPSubject,
  googleIdPSubject,
  enableSignInWithApple,
  disableSignInWithApple,
  enableSignInWithGoogle,
  disableSignInWithGoogle
} = await useSocialIdPs()

const googleButtonText = computed(() => {
  return googleIdPSubject.value ? 'Disconnect your Google account' : 'Connect your Google account'
})
const appleButtonText = computed(() => {
  return appleIdPSubject.value ? 'Disconnect your Apple account' : 'Connect your Apple account'
})

async function toggleProvider(providerName) {
  switch (providerName) {
    case 'Apple':
      appleIdPSubject.value ? await disableSignInWithApple() : enableSignInWithApple()
      break
    case 'Google':
      googleIdPSubject.value ? await disableSignInWithGoogle() : enableSignInWithGoogle()
      break
  }
}
</script>

<template>
  <div class="flex flex-col md:flex-row justify-center gap-4">
    <GoogleSignIn :button-text="googleButtonText" @click.native="toggleProvider('Google')" />
    <AppleSignIn :button-text="appleButtonText" @click.native="toggleProvider('Apple')" />
  </div>
</template>

<style scoped lang="scss"></style>
