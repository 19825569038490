/**
 * Throttle functions, like _.unbounce, except more versatile.  Can be called
 * like an anonymous function : Base.throttle(function(){ alert(); }, { delay: 1000 });
 * and then somewhere else could call the same function or in a different context, and both
 * functions would be merged and throttled together.
 *
 * If you need to differentiate between contexts, use the 'key' option.  For example, if you
 * have multiple components that are all calling throttle on the exact same function, you will
 * want to group them based on their actual instnace:
 *
 * Base.throttle(function(){
   *    Base.ajax('/quote/selectOptions/', {filters: { quote_status: 'a' } })
   *      .then(function(opts){ fillOptions(opts); });
   * }, { delay: 750, key: instanceId });
 *   - So now, if you have 3 select boxes, even though they are running the same function,
 *   they will eachbe filled with options.  If the function is called on each instance
 *   multiple times, the throttler will call the function, for each isntance,
 *   only once changes have stopped for at least 750 ms;
 *
 * c.throttle(function, options)
 * -options:
 *  -delay // (default 750) in milliseconds
 *  -debounce // Boolean:
 *    -true // goes off at least x milliseconds set in delay (timing set by delay)
 *    -false // goes off only x milliseconds after changes have stopped (timing set by delay)
 *  -key // throttle decides to collapse functions based on
 *       // the string value of the function.
 *       // if the string function value is the same, for
 *       // example for two instances of the same component
 *       // and you want the function provided to run once for
 *       // each component, if they are not gruoped then
 *       // only the last received function will be run.
 *       // Use the key to separate the two into two different groups
 *       // so they are throttled separately.
 *
 *  @returns Promise() that resolves all throttled functions when function is fully run
 */
let throttlers = {};
const disabled = [];

const getThrottlers = () => throttlers;
const setThrottlers = (thr) => { throttlers = thr; };

const removeThrottler = ({ refId }) => {
  const { [refId]: omit, ...rest } = getThrottlers();
  setThrottlers(rest);
};

const clearThrottleTimeout = (refId) => {
  const thr = getThrottlers();
  return (refId in thr && clearTimeout(thr[refId].timer));
};

const flushWaitersSuccess = ({ refId, waitersPayload = {}, list = [] }) => {
  // Resolve all promises
  const thr = getThrottlers();
  let waiters = refId in thr ? thr[refId].waiters : [];
  waiters = waiters.length > list.length ? waiters : list;
  try {
    clearThrottleTimeout(refId);
    waiters
      .forEach(w => w[0](waitersPayload));
  } catch (err) {
    waiters
      .forEach(w => w[1](err));
    console.warn('Waiters not found for throttler: ', refId);
  } finally {
    removeThrottler({ refId });
  }
};

const flushWaitersFailure = ({ refId, waitersPayload = {}, list = [] }) => {
  // Resolve all promises
  const thr = getThrottlers();
  let waiters = refId in thr ? thr[refId].waiters : [];
  waiters = waiters.length > list.length ? waiters : list;
  clearThrottleTimeout(refId);
  if (!(refId in thr)) return;

  try {
    waiters
      .forEach(w => w[1](waitersPayload));
  } finally {
    removeThrottler({ refId });
  }
};

const resetTimer = (payload) => {
  const thr = getThrottlers();
  const { refId, delay = 750, debounce = false } = payload;
  if (refId in thr) {
    clearThrottleTimeout(refId);
    return debounce
      ? (thr[refId].lastInvoked + delay) - new Date().valueOf()
      : 0;
  }
  return 0;
};

const getTimer = (payload) => {
  const {
    context,
    refId,
    throttlerDelay,
    func,
  } = payload;

  const thr = getThrottlers();
  return setTimeout(
    async () => {
      // disabled.push(refId);
      let ret = {};
      try {
        // First call the last submitted callback,
        ret = await func.call(context) || {};
        const list = refId in thr
          ? thr[refId].waiters
          : [];
        // disabled.splice(disabled.indexOf(refId), 1);
        flushWaitersSuccess({ refId, waitersPayload: ret, list });
      } catch (err) {
        const list = refId in thr
          ? thr[refId].waiters
          : [];
        flushWaitersFailure({ refId, waitersPayload: err, list });
      }
    }, throttlerDelay);
};

const getThrottler = (payload) => {
  const { invocation = new Date().valueOf() } = payload;
  const timerGot = getTimer(payload);
  return {
    lastInvoked: invocation,
    timer: timerGot,
  };
};

const addThrottler = (payload) => {
  const { resolve, reject, refId, delay = 750, debounce = false } = payload;
  let timeRemaining = delay;
  const thr = getThrottlers();
  const found = refId in thr;
  if (found) timeRemaining = resetTimer(payload);
  const throttlerDelay = debounce ? timeRemaining : delay;
  thr[refId] = {
    ...getThrottler({
      ...payload,
      throttlerDelay,
      invocation: found && debounce
        ? thr[refId].lastInvoked
        : new Date().valueOf(),
    }),
    waiters: [
      ...(refId in thr ? thr[refId].waiters : []),
      [resolve, reject],
    ],
  };
  setThrottlers(thr);
};

const throttlerExists = (refId) => {
  const thr = getThrottlers();
  return refId in thr;
};

/**
 * If the function for the desired key
 * has already been called and may be in process
 * over a long period of time, we need to create a new thread
 * to collapse requests in that only complete after the NEW ones are done.
 *
 * Without doing this, if throttle is called after one with the same key
 * has already called the function, it will return when that original function has
 * finished (collapse future threads), but that may be missing some data that was mutated for the
 * the latest call of throttle, which is why it needs its own new thread.
 *
 * @param func
 * @param key
 * @returns {string}
 */
const getReferenceKey = (func, key, collapseFuture) => {
  const regularKey = `${func.toString()}-${key}`
    .replace(/\r|\n|\s/g, '');
  const thr = getThrottlers();

  if (collapseFuture) return regularKey;

  const keys = Object.keys(thr);

  const divider = '::::';

  const relatedKeyRegx = new RegExp(`(${regularKey
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})(${divider}(.*))?`);

  const found = keys.find(k => relatedKeyRegx.test(k) && !disabled.includes(k));

  // if (found) console.log('>>> REUSING KEY', found);
  // else console.log('<<<>>> CREATING NEW KEY', regularKey);

  return found || `${regularKey}${divider}${Math.random() * 10000}`;
};

export default {
  getThrottlers,
  /**
   *
   * @param func
   * @param payload
   *  -delay = 750
   *  -key
   *  -debounce
   *  -collapseFuture = false     // if all future calls should return when
   *                              // previous call has completed
   * @returns {Promise}
   */
  throttle(func = null, payload = {}) {
    let { key = '' } = payload;
    const {
      collapseFuture = true,
    } = payload;
    let funcToUse = func;

    if (typeof func === 'string') {
      key = func;
      funcToUse = () => Promise.resolve();
    }

    const refId = getReferenceKey(func, key, collapseFuture);

    key = null;

    let resolve;
    let reject;

    const throwError = (err) => {
      throw err;
    };

    const prom = new Promise((res, rej) => {
      resolve = succ => setTimeout(() => res(succ), 50);
      reject = err => setTimeout(() => rej(err), 50);
    }).catch(err => throwError(err));

    addThrottler({
      ...payload,
      resolve,
      reject,
      func: funcToUse,
      refId,
    });

    return prom;
  },
};
