import { ref, computed } from 'vue'

export default function usePagination(params) {
  const { canFetchMore, fetchMore, page = 1, perPage = 15 } = params

  // Use refs for reactive data
  const items = ref()
  const currentPage = ref(page)
  const itemsPerPage = ref(perPage)
  const paginatedItems = ref([])

  // Compute total pages based on items length
  const totalPages = computed(() => Math.ceil(items.value.length / itemsPerPage.value))

  // Check if more pages can be loaded
  const canLoadMore = computed(() => totalPages.value > currentPage.value)

  const setPaginatedItems = () => {
    const endIndex = currentPage.value * itemsPerPage.value
    paginatedItems.value = items.value.slice(0, endIndex)
  }

  // Function to load more items
  const loadMore = async () => {
    if (!canLoadMore.value) return
    const itemLen = items.value.length
    const paginationLen = currentPage.value * itemsPerPage.value
    if (itemLen <= paginationLen && canFetchMore) await fetchMore()
    currentPage.value++
    setPaginatedItems()
  }

  // Function to update items and reset pagination
  const setItems = (newItems = []) => {
    items.value = newItems.value
    currentPage.value = 1
    setPaginatedItems()
  }

  return {
    currentPage,
    totalPages,
    paginatedItems,
    itemsPerPage,
    canLoadMore,
    items,
    loadMore,
    setItems
  }
}
