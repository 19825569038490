<template>
  <file-list
    v-if="activity.aoFiles && Array.isArray(activity.aoFiles) && activity.aoFiles.length"
    style="font-size: 1.5em"
    class="left"
    v-model="activity.aoFiles"
    :allowCreate="false"
    :allowDelete="false"
    :allowRemove="false"
  />
  <file-list
    v-else-if="activity.file_ids && Array.isArray(activity.file_ids) && activity.file_ids.length"
    style="font-size: 1.5em"
    class="left"
    v-model="activity.file_ids"
    :allowCreate="false"
    :allowDelete="false"
    :allowRemove="false"
  />
</template>

<script>
import FileList from '../FileList.vue'

export default {
  name: 'ActivityPost',
  props: {
    activity: {
      required: true
    }
  },
  mounted() {},
  components: { FileList }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
