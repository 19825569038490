<template>
  <div class="progress-container" :style="containerStyles">
    <svg class="progress-ring" :width="`${size}em`" :height="`${size}em`">
      <circle
        ref="circle"
        :style="circleStyle"
        class="progress-ring__circle"
        stroke="white"
        :stroke-width="`${strokeWidth}em`"
        fill="transparent"
        :r="`${radius}em`"
        :cx="`${size / 2}em`"
        :cy="`${size / 2}em`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  computed: {
    strokeWidth() {
      return 0.5
    },
    radius() {
      return this.size / 2 - this.strokeWidth
    },
    circleStyle() {
      const radius = this.radius
      const circumference = radius * 2 * Math.PI
      const strokeDashoffset = `${circumference - this.progress * circumference}em`

      return {
        strokeDasharray: `${circumference}em ${circumference}em`,
        strokeDashoffset
      }
    },
    containerStyles() {
      return {}
    }
  },
  methods: {
    getSize() {
      this.rect =
        this.$refs.boundingClient && this.$refs.boundingClient.getBoundingClientRect
          ? { ...this.$refs.boundingClient.getBoundingClientRect() }
          : {}

      if (this.size) {
        this.boundingSize = this.size
      } else if (Object.keys(this.rect).length && Math.max(this.rect.width, this.rect.height)) {
        this.boundingSize = Math.min(this.rect.width, this.rect.height)
      } else {
        this.boundingSize = 100
      }
    }
  },
  mounted() {
    this.getSize()
  },
  props: {
    progress: {
      default: 0.5
    },
    size: {
      default: 3
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .progress-ring {
  }

  .progress-ring__circle {
    transition: 0.35s stroke-dashoffset;
    // axis compensation
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  @each $color, $value in $additional-colors {
    &.#{$color} {
      .progress-ring__circle {
        stroke: $value;
      }
    }
  }
}
</style>
