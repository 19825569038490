<template>
  <Modal
    v-bind="$props"
    :isDirty="bodyIsDirty"
    ref="modal"
    size="md"
    :clickAway="true"
    :closeable="false"
    :scrollable="false"
    :expandable="false"
  >
    <template #header>
      <font-awesome-icon :icon="['fas', 'star']" />
      Rate
    </template>
    <template #body>
      <rating-request
        :IsPublic="false"
        :clientObjectProp="object.oClient"
        :quoteIdProp="id"
        :rating-o-prop="object"
        ref="body"
      >
      </rating-request>
    </template>
  </Modal>
</template>
<script>
import RatingRequest from '@/components/pages/RatingRequest.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalRating',
  mixins: [ObjectModalMixin],
  props: {
    type: {
      default: 'rating'
    }
  },
  components: { RatingRequest },
  mounted() {},
  beforeUnmount() {}
}
</script>
