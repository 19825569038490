<template><div></div></template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'
import eventBus from '@/eventBus'

const store = useStore()

const createModal = ref(null)

const props = defineProps({
  go: {
    default: false
  },
  alwaysVisible: {
    default: false
  }
})

const open = async (embue = {}) => {
  const choice = await store.dispatch('modal/asyncConfirm', {
    message: 'What would you like to create?',
    choices: [
      {
        value: 'client',
        title: 'New client / lead',
        icon: 'house-user',
        desc: 'Create a new client that you can create an estimate for later!'
      },
      {
        value: 'quote',
        title: 'New estimate',
        icon: 'file-signature',
        desc: 'Build a new estimate for a new or existing customer. This is the starting point for every new project.'
      },
      {
        value: 'cost_type',
        title: 'Item or assembly',
        icon: 'box-open-full',
        desc: 'Manage your item library -- create, update or delete items and assemblies.'
      },
      {
        value: 'lead_rotation',
        title: 'Lead form',
        icon: 'house-flag',
        desc: 'Create a lead rotation to pipe leads from websites, landing pages, even QR codes right into your Bolster pipeline'
      },
      {
        value: 'lead_source',
        title: 'Marketing campaign',
        icon: 'flag-swallowtail',
        desc: 'Create a marketing campaign, and track your ROI.'
      }
    ]
  })

  create(choice, embue)
}

function create(type, embue) {
  if (!type) return

  switch (type) {
    case 'cost_type':
      store.dispatch('to', 'items')
      break
    case 'lead_rotation':
      store.dispatch('to', 'lead_rotations')
      break
    case 'client':
      createItem('client', { client_status: 'a', ...embue })
      break
    case 'lead_source':
      createItem('lead_source', { lead_source_status: 'a', ...embue })
      break
    case 'quote':
      createItem('quote', { quote_status: 'p', ...embue })
      break
    case 'project':
      createItem('quote', { quote_status: 'k', ...embue })
      break
    case 'invoice':
      createItem('invoice', { invoice_status: 'o', ...embue })
      break
  }

  createModal.value?.close()
}

function createItem(type, embue) {
  return store.dispatch('create', {
    type: type,
    embue: embue,
    go: props.go
  })
}

onBeforeMount(() => {
  eventBus.$on('quickCreate', open)
})
onBeforeUnmount(() => {
  eventBus.$off('quickCreate', open)
})

defineExpose({
  open
})
</script>

<style rel="stylesheet/scss" lang="scss"></style>
