export default {
  root: {
    class: [
      // Shape
      'rounded-sm',

      // Size
      'min-w-[12rem]',
      'py-1',

      // Colors
      'bg-pitch-black dark:bg-surface-300',
      'border border-surface-200 dark:border-surface-700',
      'text-surface-100 dark:text-surface-300'
    ]
  },
  menu: {
    class: [
      // Spacings and Shape
      'list-none',
      'm-0',
      'p-0',
      'outline-none'
    ]
  },
  menuitem: {
    class: [
      // Position
      'relative'
    ]
  },
  content: ({ context }) => ({
    class: [
      //Shape
      'rounded-none',

      //  Colors
      {
        // 'text-surface-500 dark:text-white/70': !context.focused && !context.active,
        // 'text-surface-500 dark:text-white/70 bg-surface-200 dark:bg-surface-600/90':
        //   context.focused && !context.active,
        // 'text-primary-700 dark:text-surface-0/80 bg-primary-50 dark:bg-primary-400/30':
        //   context.focused && context.active,
      },

      // Hover States
      {
        'hover:bg-surface-700 dark:hover:bg-surface-600/80': !context.active,
        'hover:bg-primary-700/50 dark:hover:bg-primary-300/30 dark:text-surface-0/80':
          context.active
      },

      // Transitions
      'transition-shadow',
      'duration-200'
    ]
  }),
  action: () => ({
    class: [
      'relative',
      // Flexbox

      'flex',
      'items-center',

      // Spacing
      'py-3',
      'px-5',

      // Color
      // {
      //   'text-surface-400 dark:text-surface-400': props.disabled,
      // },

      // Misc
      'no-underline',
      'overflow-hidden',
      'cursor-pointer',
      'select-none'
    ]
  }),
  icon: {
    class: [
      // Spacing
      'mr-2',

      // Color
      'text-surface-600 dark:text-white/70'
    ]
  },
  label: {
    class: ['leading-none']
  },
  submenuicon: {
    class: [
      // Position
      'ml-auto'
    ]
  },
  submenu: {
    class: [
      // Size
      'w-full sm:w-48',

      // Spacing
      'py-1',
      'm-0',
      'list-none',

      // Shape
      'shadow-none sm:shadow-md',
      'border-0',

      // Position
      'static sm:absolute',
      'z-10',

      // Color
      'bg-surface-0 dark:bg-surface-700'
    ]
  },
  separator: {
    class: 'border-t border-surface-200 dark:border-surface-600 my-1'
  }
}
