<template>
  <div
    class="absolute bg-surface-600 flex-col top-1 rounded -right-10 gap-0.5 overflow-hidden cursor-pointer w-8 h-8 flex items-center justify-center"
    v-if="isInQuoteEditor"
    v-tooltip="'Assembly settings'"
  >
    <!-- Emphasis Control Button -->
    <div class="text-base font-light text-surface-700">
      <div class="text-flame-white" @click="openEmphasisOptions" ref="settingsBtn">
        <font-awesome-icon :icon="['fas', 'cog']" fixed-width />
      </div>
      <!-- Emphasis Settings Modal -->
      <MiniModal ref="emphasisOptions">
        <CardSection>
          <template #title> Assembly Display Settings </template>
          <CardList class="sm">
            <CardListField>
              <span>
                Display settings
                <br />
                <small class="text-muted">
                  Change the way this assembly appears in your presentation.
                </small>
              </span>
              <Choose
                :value="assemblyEmphasis"
                @input="(v) => setField('assembly_emphasis', v)"
                :default="0"
                schema="assembly:assembly_emphasis"
                :allowCreate="false"
                :return-array="false"
                :staticSet="[
                  { value: 1, text: 'Emphasize the assembly on proposal' },
                  { value: 0, text: 'Show the assembly normally' },
                  {
                    value: -1,
                    text: '[Outdated] Display as emphasized single item (CostCertified classic preview only)'
                  },
                  {
                    value: -2,
                    text: '[Outdated] Display as normal single item (CostCertified classic preview only)'
                  },
                  { value: -3, text: 'Hide assembly from the proposal' }
                ]"
              />
            </CardListField>
          </CardList>
        </CardSection>
      </MiniModal>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import eventBus from '@/eventBus'

// Props
const props = defineProps({
  isInQuoteEditor: { type: Boolean, default: false },
  object: { type: Object }
})

const assemblyEmphasis = computed(() => props.object.assembly_emphasis)
const emphasisOptions = ref(null)

const openEmphasisOptions = () => {
  if (emphasisOptions.value && emphasisOptions.value.open) {
    emphasisOptions.value.open()
  }
}

const setField = (fieldName, value) => {
  eventBus.$emit('PresentationModifyItem', {
    refId: props.object.refId,
    changes: {
      [fieldName]: value
    },
    skipAudit: true,
    explicit: true
  })
}
</script>
