<template>
  <div class="flex flex-col gap-y-8">
    <Card>
      <template #title>
        <font-awesome-icon icon="drafting-compass" />
      </template>
      <template #content>
        <span>
          Toggle optional items, set dimensions and make selections required for {{ name }}.
        </span>
      </template>
      <template #footer>
        <Btn class="round lg" @click="() => $emit('closeModal')">
          <template #icon>
            <font-awesome-icon icon="arrow-right" />
          </template>
          Skip confirming dimensions..
        </Btn>
      </template>
    </Card>
    <Card v-for="subRef in containers" :key="subRef">
      <template #title>
        <ItemLocation
          :start-full="true"
          :rooms-only="false"
          :root-name="object.assembly_name"
          :object="norm[subRef]"
        />
      </template>
      <template #content>
        <div
          v-if="
            [...subRefsOptional, ...subRefsOptional.map((sro) => norm[sro].parentRefId)].includes(
              subRef
            )
          "
          class="divide-y"
        >
          <div
            v-for="optionalRef in displayedOptionalSubRefs(subRef)"
            :key="`opt-${optionalRef}`"
            class="py-2"
          >
            <div class="flex flex-row justify-between items-center">
              <span>
                {{ norm[optionalRef].cost_type_name || norm[optionalRef].assembly_name }}
              </span>

              <SelectionToggle
                :value="removing.includes(optionalRef) ? 0 : 1"
                @input="() => keepOrRemove(optionalRef)"
                :active-class="removing.includes(optionalRef) ? 'active danger' : 'active info'"
                :options="[
                  {
                    text: 'Exclude',
                    value: 0
                  },
                  {
                    text: 'Include',
                    value: 1
                  }
                ]"
              />
            </div>

            <div class="flex justify-end my-1">
              <SuggestionButton
                class="sm info"
                @click="optionalOrNot(optionalRef)"
                v-if="!notOptional.includes(optionalRef)"
              >
                <font-awesome-icon icon="user" />
                Your client can still add/remove this
                {{ norm[optionalRef].type === 'assembly' ? 'assembly' : 'item' }} • click to
                change...
              </SuggestionButton>

              <SuggestionButton class="sm danger" @click="optionalOrNot(optionalRef)" v-else>
                <font-awesome-icon icon="ban" />
                Your client cannot add/remove this
                {{ norm[optionalRef].type === 'assembly' ? 'assembly' : 'item' }}
                • click to change...
              </SuggestionButton>
            </div>
          </div>
        </div>

        <AssemblyDimensionsConfirm
          v-if="!fullRemoval.includes(subRef)"
          class="flush"
          :excludeForRefIds="fullRemoval"
          :value="norm[subRef].oDimensions"
          @input="(dims) => setDims(subRef, dims)"
          :showDerivedDimensions="false"
          :object="norm[subRef]"
          :store="store"
          :reference="subRef"
          :key="`dim-${subRef}`"
        >
          <template #before>
            <font-awesome-icon icon="drafting-compass" class="mr-1 circled" />
          </template>
        </AssemblyDimensionsConfirm>

        <template v-if="!fullRemoval.includes(subRef)">
          <CardListCollapse
            :cloak="true"
            class="sm"
            v-for="ref in displayedVariationItems(subRef)"
            :key="`var-${ref}`"
          >
            <template #title>
              <div class="flex justify-start flex-col items-start">
                <strong class="flex justify-start items-center flex-row">
                  <font-awesome-icon icon="circle-dot" class="circled mr-1" />
                  {{ norm[ref].cost_type_name }}
                </strong>
              </div>
            </template>
            <CostItemVariationSelector
              :object="norm[ref]"
              :store="store"
              :show-highlights="false"
              :reference="ref"
              :editable="true"
              :scroll="false"
              :focusOnMount="false"
              :key="`var-var-${ref}`"
            />
          </CardListCollapse>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card'
import BodyMixin from '../mixins/Body'
import AssemblyDimensionsConfirm from '../items/AssemblyDimensionsConfirm.vue'
import AssemblyDimensionsSummaryMixin from '../quote/ProjectDimensions/AssemblyDimensionsSummaryMixin'
import CostItemVariationSelector from '../quote/item/CostItemVariationSelector.vue'
import ItemLocation from '../items/ItemLocation.vue'
import SuggestionButton from '../ui/SuggestionButton.vue'
import AssemblyDimensionsMixin from '../quote/ProjectDimensions/AssemblyDimensionsMixin'

export default {
  components: {
    SuggestionButton,
    ItemLocation,
    CostItemVariationSelector,
    AssemblyDimensionsConfirm,
    Card
  },
  mixins: [BodyMixin, AssemblyDimensionsSummaryMixin, AssemblyDimensionsMixin],
  name: 'AssemblyConfirmDimensions',
  emits: ['closeModal'],

  data() {
    return {
      removing: [],
      notOptional: [],
      c,
      fetchedVariationItemRefs: [],
      variationItems: {}
    }
  },

  beforeUnmount() {
    const removing = _.imm(this.removing || [])
    const fullRemoval = _.imm(this.fullRemoval || [])
    const notOptional = _.imm(this.notOptional || [])
    const subRefs = _.imm(this.subRefsOptional)
    const norm = _.imm(this.norm)

    const changes = subRefs.reduce((acc, refId) => {
      const rem = removing.includes(refId)
      return {
        ...acc,
        [refId]: {
          [`${norm[refId].type}_is_optional`]: notOptional.includes(refId) ? 0 : 1,
          [`${norm[refId].type}_is_included`]: rem ? 0 : 1,
          [`${norm[refId].type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`]: rem ? 0 : 1,
          [`${norm[refId].type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`]: rem ? 0 : 1,
          [`${norm[refId].type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base_original`]:
            norm[refId][`${norm[refId].type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net_base`]
        }
      }
    }, {})

    this.$store.dispatch(`${this.store}/field`, {
      changes,
      explicit: true
    })
    this.$store.dispatch(`${this.store}/removeChildren`, {
      refIds: fullRemoval
    })
  },

  methods: {
    containerSort(a, b) {
      const norm = this.norm

      const namea = norm[a].cost_type_name || norm[a].assembly_name
      const nameb = norm[b].cost_type_name || norm[b].assembly_name

      const patha = norm[a].asAssemblyPath.length
      const pathb = norm[b].asAssemblyPath.length

      if (patha === pathb && !patha) {
        if (namea > nameb) return 1
        if (nameb > namea) return -1
        return 0
      }

      if (patha === pathb) {
        const lasta = norm[a].asAssemblyPath[patha - 1]
        const lastb = norm[b].asAssemblyPath[pathb - 1]

        return lasta - lastb
      }

      return patha - pathb
    },

    optionalOrNot(subRef) {
      if (this.notOptional.includes(subRef)) {
        this.markOptional(subRef)
      } else {
        this.markNotOptional(subRef)
      }
    },
    markNotOptional(subRef) {
      const extract = c.getDescendants(this.norm, [subRef], true)
      this.notOptional = [...(this.notOptional || []), ...extract]
    },
    markOptional(subRef) {
      const extract = c.getDescendants(this.norm, [subRef], true)
      this.notOptional = _.imm(_.difference(this.notOptional || [], extract))
    },

    keepOrRemove(subRef) {
      if (this.removing.includes(subRef)) {
        this.markRetained(subRef)
      } else {
        this.markRemoved(subRef)
      }
    },
    markRemoved(subRef) {
      const extract = c.getDescendants(this.norm, [subRef], true)
      this.removing = [...(this.removing || []), ...extract]
    },
    markRetained(subRef) {
      const extract = c.getDescendants(this.norm, [subRef], true)
      this.removing = _.imm(_.difference(this.removing || [], extract))
    },
    displayedOptionalSubRefs(subRef) {
      return this.subRefsOptional.filter((optionalRef) => {
        return (
          (optionalRef === subRef || this.norm[optionalRef].parentRefId === subRef) &&
          (!this.norm[optionalRef].parentRefId ||
            !this.removing.includes(this.norm[optionalRef].parentRefId))
        )
      })
    },
    displayedVariationItems(subRef) {
      return this.variationItemIds.refs.filter((ref) => {
        return this.norm[ref].parentRefId === subRef && !this.fullRemoval.includes(ref)
      })
    },
    async setDims(subRef, dimensions) {
      if (dimensions && !_.jsonEquals(dimensions, this.norm[subRef].oDimensions)) {
        // get the changed dimensions
        const keys = Object.keys(dimensions)
        const changedDimensions = keys.filter(
          (key) => !_.jsonEquals(dimensions[key], this.norm[subRef].oDimensions[key])
        )

        // make the change
        await this.$store.dispatch(`${this.store}/field`, {
          changes: {
            oDimensions: dimensions
          },
          explicit: true,
          refId: subRef
        })

        // get the RefIds effected by the change and re-calc their Addons
        const refIds = await this.$store.dispatch('Quote/getLinkedDimensionRefIds', {
          assemblyRefId: this.reference,
          changedDimensions
        })
        await this.$store.dispatch('Quote/recalcAddons', { refIds, loading: false })
      }
    }
  },

  computed: {
    fullRemoval() {
      return _.intersection(this.removing, this.notOptional)
    },
    name() {
      return this.object.assembly_name || this.object.quote_name
    },
    norm() {
      return _.immutable(this.$store.state[this.store].normalized)
    },
    subRefs() {
      const norm = this.norm
      const refs = c.getDescendants(norm, [this.reference], true)

      refs.sort(this.containerSort)
      // Make this root assembly as if it is the root so it detects the correct rootRefId
      // extracted[this.reference].parentRefId = null;
      // const shallowestToDeepest = c.getNormalizedDepthOrder(extracted, this.reference, {})
      //   .reverse();

      return refs
    },
    subRefsOptional() {
      const norm = this.norm

      const subrefs = this.subRefs.filter(
        (ref) => norm[ref].cost_item_is_optional || norm[ref].assembly_is_optional
      )

      this.removing = this.initiated
        ? this.removing
        : subrefs.filter((refId) => !norm[refId][`${norm[refId].type}_is_included`])
      this.initiated = 1

      return subrefs
    },
    subRefsVariations() {
      const norm = this.norm

      const refs = this.subRefs.filter(
        (ref) => norm[ref].variation_parent_cost_type_id || norm[ref].cost_type_is_variation_parent
      )

      return refs
    },
    variationItemIds() {
      const norm = this.norm

      const grouped = this.subRefsVariations.reduce(
        (acc, ref) => ({
          ...acc,
          [norm[ref].variation_parent_cost_type_id || norm[ref].cost_type_id]: ref
        }),
        {}
      )

      grouped.refs = this.subRefsVariations

      return grouped
    },
    containers() {
      const containers = Object.keys(this.allDimensionZones)

      // Add all optional that are visible
      ;[...this.subRefsOptional].forEach((subRef) => {
        // add only if the parent is visible
        const item = this.norm[subRef]
        if (this.fullRemoval.includes(item.parentRefId)) return

        if (item.type === 'assembly') {
          containers.push(subRef)
          return
        }

        containers.push(item.parentRefId)
      })

      // Add all variations that are visible
      ;[...this.subRefsVariations].forEach((subRef) => {
        const item = this.norm[subRef]
        if (this.fullRemoval.includes(subRef)) return

        containers.push(item.parentRefId)
      })

      const preferredOrder = [this.object.refId]
      const goDown = (children) => {
        children.forEach((childRef) => {
          preferredOrder.push(childRef)
          if (this.norm[childRef].aoChildren) goDown(this.norm[childRef].aoChildren)
        })
      }
      goDown(this.object.aoChildren)

      return c
        .uniq(containers)
        .sort((a, b) => preferredOrder.indexOf(a) - preferredOrder.indexOf(b))
    },
    allDimensionZones() {
      const norm = this.norm
      const req = this.object.asRequiredDimensions.length
        ? { [this.object.refId]: this.object.asRequiredDimensions }
        : {}

      this.subRefs
        .filter((subRef) => norm[subRef].type === 'assembly')
        .forEach((subRef) => {
          if (!norm[subRef].asRequiredDimensions.length) return

          if (this.fullRemoval.includes(subRef)) return

          const { dependencies } = _.getDimensionDependencies(
            norm[subRef].oDimensions,
            norm[subRef].asRequiredDimensions
          )

          const nonDerived = norm[subRef].asRequiredDimensions.filter(
            (abbr) => !dependencies[abbr].length
          )

          if (!nonDerived.length) return

          req[subRef] = [...nonDerived]
        })

      return req
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
