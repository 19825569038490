export default {
  root: ({ context }) => ({
    class: [
      // Colors
      'bg-flame-white',
      'dark:bg-surface-900',
      'text-surface-700',
      'dark:text-surface-0/80',

      // Shape
      // 'rounded-lg',

      // Borders (Conditional)
      // { 'border border-solid border-surface-50 dark:border-surface-700': !context.nested },

      // Nested
      { 'flex grow border-0': context.nested }
    ]
  }),

  gutter: ({ props }) => ({
    class: [
      // Flexbox
      'flex',
      'items-center',
      'justify-center',
      'shrink-0',

      // Colors
      'bg-surface-200',
      'hover:bg-blue-print',
      'dark:bg-surface-800',

      // Transitions
      'transition-all',
      'duration-200',

      // Misc
      {
        'min-w-[2px] min-h-[2px]': props.gutterSize > 0,
        'min-w-0 min-h-0': props.gutterSize < 1
      },

      // Misc
      {
        'cursor-col-resize': props.layout == 'horizontal',
        'cursor-row-resize': props.layout !== 'horizontal'
      }
    ]
  }),
  gutterhandler: ({ props }) => ({
    class: [
      // Colors
      'bg-surface-300/60',
      'dark:bg-surface-600',
      'hidden', // TEMPORARY - hide gutter since it appears even when the splitter is hidden

      // Transitions
      'transition-all',
      'duration-200',

      // Sizing (Conditional)
      {
        'h-6': props.layout == 'horizontal',
        'w-6 h-2': props.layout !== 'horizontal'
      }
    ]
  })
}
