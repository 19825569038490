<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="false"
    :clickAway="false"
    :expandable="true"
  >
    <template #body>
      <webhook ref="body" :forceFull="true" :id="id" />
    </template>
  </modal>
</template>

<script>
import Webhook from '../bodies/Webhook.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalWebhook',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { Webhook },
  props: {
    type: {
      default: 'webhook'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
