// import Helpers from '../../../imports/api/Helpers';

export default {
  name: 'ItemizedMixin',
  data() {
    return {
      invoiceItems: null
    }
  },
  computed: {
    /**
     * Check the company module to see if itemized invoicing module is turned on
     * @returns {Boolean}
     */
    itemizedEnabled() {
      return (
        this.$store.state.session.company.aoModules &&
        this.$store.state.session.company.aoModules.itemized_invoicing &&
        parseInt(this.$store.state.session.company.aoModules.itemized_invoicing, 10) === 1
      )
    },
    /**
     * Check to see if enableItemizedInvoicing is toggled on in the invoice settings
     * @returns {Boolean}
     */
    enableItemizedInvoicing() {
      return this.oSettings && this.oSettings.enableItemizedInvoicing
    },
    isClient() {
      return this.$store.state.session.authorizedUser.user_is_client
    },
    isCompany() {
      return this.$store.state.session.authorizedUser.user_is_company_user
    },
    showItemizedPricing() {
      return (this.isClient && this.oSettings && this.oSettings.showItems) || this.isCompany
    }
  },
  methods: {
    /**
     * Recalculate the invoice based on selected items
     * @param {Object} set
     * @param {Array} refIds
     * @returns {Object}
     */
    recalculate(set = {}, refIds = []) {
      const calculations = {
        net: 0,
        gross: 0
      }

      Object.keys(set).forEach((ref) => {
        const item = set[ref]
        if (
          refIds.includes(set[ref].item_id) &&
          (item.type === 'cost_type' || item.type === 'cost_item')
        ) {
          calculations.net += item.item_net || item.cost_item_net || 0
          calculations.gross += item.item_gross || item.cost_item_gross || 0
        }
      })
      return calculations
    },
    /**
     * Based on a sub set of ids change to our normalized format
     * referencing the full normalized set
     * @param {Array} refIds
     * @param {Object} normalized
     * @returns {Object}
     */

    normalizeSubSet(refIds = [], normalized = {}) {
      return Object.keys(normalized).reduce((acc, ref) => {
        const item = normalized[ref]
        if (refIds.includes(item.item_id)) acc[ref] = item
        return acc
      }, {})
    },
    /**
     * From aoItems returned from quote reformat to normalized
     * subset of the full normalized set
     * @param {Array} aoItems
     * @param {Object} normalized
     * @returns {Object}
     */
    getNormalizedItems(aoItems = [], normalized = {}) {
      if (!aoItems || aoItems.length === 0) return null
      const normalizedItems = aoItems.reduce((acc, item) => {
        const itemId = item.item_id
        const object = normalized[itemId]
        const parentId = object.parent_item_id
        acc[itemId] = object
        acc[parentId] = normalized[parentId]
        return acc
      }, {})

      // We need to include the grandparent because that becomes the root
      // object that the itemized list component uses to loop through
      const parentRefId = c.getNormalizedRootRefId(normalizedItems)
      const parent = normalized[parentRefId]
      const grandParentId = parent.parentRefId
      normalizedItems[grandParentId] = normalized[grandParentId]
      return normalizedItems
    },
    // If need be fetch the quote and store
    async fetchQuote() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: `Quote/fetchNormalized/${this.quoteId}`
      })
      const { normalized } = payload
      return normalized
    },
    /**
     * Retrieve the full normalized quote for itemized invoicing
     * @returns {Object}
     */
    async getQuote() {
      if (!this.quoteId) return {}
      // if the user logged in is not a client the should be stored in the state
      if (
        this.$store.state.Quote.normalized &&
        Object.keys(this.$store.state.Quote.normalized).length > 0
      ) {
        const rootRefId = await this.$store.dispatch('Quote/getRootRefId', {})
        const { normalized } = this.$store.state.Quote
        const quote = normalized[rootRefId]
        // if the stored quote does not match the set quote id fetch the quote
        if (quote.quote_id !== this.quoteId) return this.fetchQuote()
        return normalized
      }
      // if the quote is not stored in the state we need to fetch it
      return this.fetchQuote()
    },
    /**
     * Setup method to retrieve quote and set normalized invoice items
     */
    async setItemizedItems() {
      // get or fetch the normalized quote
      this.normalizedQuote = await this.getQuote()
      // get the normalized invoice items
      this.invoiceItems = this.getNormalizedItems(this.aoItems, this.normalizedQuote)
    }
  }
}
