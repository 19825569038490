import Router from '../../../router'
import UserError from '../../../../imports/api/UserError'

/**
 * Enter URLS that the milestone module will report
 * to when a milestone is successfully triggered. Can
 * enter multiple. Make sure all URLS here are
 * added to CORS
 * @type {*[]}
 */
const reportTo = ['https://hook.integromat.com/2r9x99mtoqiy4lm0mafubmm8trgqlwpn']

/**
 * Structure and milestone default values
 */
const defaultMilestone = {
  /**
   * Description
   */
  desc: 'N/A',

  /**
   * Data to include in milestone
   */
  include: {
    /**
     * Company
     */
    company: null,

    /**
     * The user that is part of the company, the employee user
     */
    companyUser: null,

    /**
     * The user for the client of the company
     */
    clientUser: null
  },

  /**
   * If true, the milestone will be reported to the API
   * urls in reportTo as the body in a post
   */
  report: true,

  /**
   * If set to true, whenever the milestone is triggered
   * after the first time, it will be ignored.
   */
  oneTimeOnly: true,

  /**
   * Allow listener callbacks to file
   */
  silent: false,

  /**
   * Send GET PARAMS
   */
  sendParams: true
}

/**
 * List of built-in milestones.
 */
const milestones = {
  INCOMPLETE_SIGNUP: {
    desc: 'User signed up but did not complete form'
  },
  COMPLETE_SIGNUP: {
    desc: 'User signed up completely to the end of the form'
  },
  FIRST_LOGIN: {
    desc: 'User logged in for the first time.'
  },
  CREDIT_CARD_RECEIVED: {
    desc: 'User submitted their credit card.'
  },
  PAYMENT_RECEIVED: {
    desc: 'User submitted their credit card.'
  },
  FIRST_ESTIMATING_PAGE_VIEW: {
    desc: 'User has been to the estimating page at least once.'
  },
  FIRST_QUOTE_SENT: {
    desc: 'User has sent at least one quote.'
  },
  FIRST_ITEM_OR_ASSEMBLY_SAVED: {
    desc: 'User has saved at least one item or assembly to their own catalog.'
  },
  FIRST_QUOTE_BOOKED: {
    desc: 'User has had their first job booked.'
  },
  FIRST_LEAD_FORM_GENERATED: {
    desc: 'User has generated a lead form for their website.'
  }
}

/**
 * Get only matching fields to save on size of object
 * @param obj
 * @returns {{}}
 */
const filterObject = (obj) =>
  Object.keys(obj).reduce(
    (acc, field) => ({
      ...acc,
      ...(/_id|_name|_names|_lname|_fname|_email|_website|asTradeTypeNames|_phone|_city|_unit|_address|_postal|country_name|province_name|timezone_name/.test(
        field
      )
        ? { [field]: obj[field] }
        : {})
    }),
    {}
  )

/**
 * Return GET query veriables
 * @returns {Dictionary<string | (string | null)[]>}
 */
const getRequestParams = () => Router.currentRoute.value.query

/**
 *
 * @param milestone
 */
const getDefaultMilestone = (milestone, session) => {
  const defaulted = {
    ...defaultMilestone,
    ...milestone,
    include: {
      ...(defaultMilestone.include || {}),
      ...milestone.include
    }
  }

  const guestUser =
    !session.scope || !session.scope.company || session.scope.quote || session.scope.file

  const include = Object.keys(defaulted.include).reduce((acc, key) => {
    let obj = {}

    if (key in session) {
      obj = filterObject(session[key] || {})
    } else if (key === 'clientUser' && guestUser) {
      obj = filterObject(session.user || {})
    } else if (key === 'companyUser' && guestUser && session.quote) {
      obj = filterObject(session.quote.owner || {})
    } else if (key === 'companyUser') {
      obj = filterObject(session.user || {})
    }

    return {
      ...acc,
      [key]: obj
    }
  }, {})

  return _.imm({
    ...defaulted,
    include
  })
}

const getCacheKey = (milestoneKey) => `MS_KEY-${milestoneKey}`

/**
 * Get the fully defaulted and prepared milestone with the data
 * ready to send
 * @param key
 * @param session
 * @returns {*}
 */
const getMilestone = (key, session) => {
  if (!(key in milestones)) {
    throw new UserError('That milestone does not exist.')
  }
  return _.imm({
    event: key,
    timeFired: new Date().toISOString(),
    eventData: getDefaultMilestone(milestones[key], session),
    requestParameters: getRequestParams()
  })
}

const actions = {
  // /**
  //  * Register listener callback
  //  * @param milestone string
  //  * @param callback function
  //  */
  // on({ rootState }, { milestone: key, callback }) {
  //   const milestone = getMilestone(key, rootState.session);
  // },
  //
  // /**
  //  * Add a new milestone
  //  * @param milestone string
  //  * @param body milestone options conforming to defaultMilestone
  //  */
  // add({}, { milestone, body }) {},

  /**
   * Trigger a milestone
   * @param payload
   */
  async trigger({ rootState, dispatch }, payload) {
    const key = typeof payload === 'string' ? payload : payload.milestone
    const session = payload.session || rootState.session

    const milestone = getMilestone(key, session)
    try {
      if (milestone.eventData.oneTimeOnly && (await dispatch('hasTriggeredOnce', key))) {
        return
      }
    } catch (e) {
      console.log('Exception on milestone -> trigger', e)
    }

    milestone.additionalData = _.imm(payload.embue || payload.additionalData || {})

    milestone.testing = import.meta.env.MODE !== 'production'

    // Send
    await Promise.all(
      reportTo.map((url) =>
        dispatch(
          'ajax',
          {
            host: url,
            data: milestone
          },
          { root: true }
        )
      )
    )

    // Mark as triggered
    await dispatch(
      'Keyvalue/set',
      {
        key: getCacheKey(milestone),
        value: 1
      },
      { root: true }
    )
  },

  /**
   *
   * @param dispatch
   * @param milestone
   * @returns {Promise<void>}
   */
  async hasTriggeredOnce({ dispatch }, milestone) {
    const done = await dispatch('Keyvalue/get', getCacheKey(milestone), { root: true })
    return !!done
  }
}

export default {
  namespaced: true,
  state: {
    types: Object.keys(milestones).reduce(
      (acc, ms) => ({
        [ms]: ms
      }),
      {}
    )
  },
  actions
}
