import ld from 'lodash'

const tags = {
  argsTag: '[object Arguments]',
  arrayTag: '[object Array]',
  asyncTag: '[object AsyncFunction]',
  boolTag: '[object Boolean]',
  dateTag: '[object Date]',
  domExcTag: '[object DOMException]',
  errorTag: '[object Error]',
  funcTag: '[object Function]',
  genTag: '[object GeneratorFunction]',
  mapTag: '[object Map]',
  numberTag: '[object Number]',
  nullTag: '[object Null]',
  objectTag: '[object Object]',
  promiseTag: '[object Promise]',
  proxyTag: '[object Proxy]',
  regexpTag: '[object RegExp]',
  setTag: '[object Set]',
  stringTag: '[object String]',
  symbolTag: '[object Symbol]',
  undefinedTag: '[object Undefined]',
  weakMapTag: '[object WeakMap]',
  weakSetTag: '[object WeakSet]',
  arrayBufferTag: '[object ArrayBuffer]',
  dataViewTag: '[object DataView]',
  float32Tag: '[object Float32Array]',
  float64Tag: '[object Float64Array]',
  int8Tag: '[object Int8Array]',
  int16Tag: '[object Int16Array]',
  int32Tag: '[object Int32Array]',
  uint8Tag: '[object Uint8Array]',
  uint8ClampedTag: '[object Uint8ClampedArray]',
  uint16Tag: '[object Uint16Array]',
  uint32Tag: '[object Uint32Array]'
}

// clonable type tags
const cloneableTags = {
  [tags.argsTag]: true,
  [tags.arrayTag]: true,
  [tags.arrayBufferTag]: true,
  [tags.dataViewTag]: true,
  [tags.boolTag]: true,
  [tags.dateTag]: true,
  [tags.float32Tag]: true,
  [tags.float64Tag]: true,
  [tags.int8Tag]: true,
  [tags.int16Tag]: true,
  [tags.int32Tag]: true,
  [tags.mapTag]: true,
  [tags.numberTag]: true,
  [tags.objectTag]: true,
  [tags.regexpTag]: true,
  [tags.setTag]: true,
  [tags.stringTag]: true,
  [tags.symbolTag]: true,
  [tags.uint8Tag]: true,
  [tags.uint8ClampedTag]: true,
  [tags.uint16Tag]: true,
  [tags.uint32Tag]: true,
  [tags.weakMapTag]: false,
  [tags.funcTag]: false,
  [tags.errorTag]: false
}

const symToStringTag = Symbol ? Symbol.toStringTag : undefined

const getRawTag = (value) => {
  if (typeof value === 'function') return '[object Function]'
  const isOwn = Object.hasOwnProperty.call(value, symToStringTag)
  const tag = value[symToStringTag]
  let unmasked = false

  value[symToStringTag] = undefined
  unmasked = true

  const result = Object.prototype.toString.call(value)
  if (unmasked) {
    if (isOwn) {
      value[symToStringTag] = tag
    } else {
      delete value[symToStringTag]
    }
  }
  return result
}

const isPrototype = (value) => {
  const Ctor = value && value.constructor
  const proto = (typeof Ctor === 'function' && Ctor.prototype) || Object.prototype

  return value === proto
}

const initCloneObject = (object) => {
  if (typeof object.constructor === 'function' && !isPrototype(object)) {
    const obj = Object.getPrototypeOf(Object(object))
    if (!ld.isObject(obj)) {
      return {}
    }
    return Object.create(obj)
  }
  return {}
}

const cloneArrayBuffer = (arrayBuffer) => {
  const result = new arrayBuffer.constructor(arrayBuffer.byteLength)
  new Uint8Array(result).set(new Uint8Array(arrayBuffer))
  return result
}

const cloneDataView = (dataView) => {
  const buffer = cloneArrayBuffer(dataView.buffer)
  return new dataView.constructor(buffer, dataView.byteOffset, dataView.byteLength)
}

const cloneTypedArray = (typedArray) => {
  const buffer = cloneArrayBuffer(typedArray.buffer)
  return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length)
}

const cloneRegExp = (regexp) => {
  const result = new regexp.constructor(regexp.source, /\w*$/.exec(regexp))
  result.lastIndex = regexp.lastIndex
  return result
}

const cloneSymbol = (symbol) => {
  const symbolPrototype = Symbol ? Symbol.prototype : undefined
  const symbolValueOf = symbolPrototype ? symbolPrototype.valueOf : undefined
  return symbolValueOf ? Object(symbolValueOf.call(symbol)) : {}
}

const baseAssignValue = (object, key, value) => {
  if (key === '__proto__' && Object.defineProperty) {
    Object.defineProperty(object, key, {
      configurable: true,
      enumerable: true,
      value,
      writable: true
    })
  } else {
    object[key] = value
  }
}

const assignValue = (object, key, value) => {
  const objValue = object[key]
  if (
    !(Object.hasOwnProperty.call(object, key) && ld.eq(objValue, value)) ||
    (value === undefined && !(key in object))
  ) {
    baseAssignValue(object, key, value)
  }
}

const initCloneByTag = (object, tag) => {
  const Ctor = object.constructor
  switch (tag) {
    case tags.arrayBufferTag:
      return cloneArrayBuffer(object)

    case tags.boolTag:
    case tags.dateTag:
      return new Ctor(+object)

    case tags.dataViewTag:
      return cloneDataView(object)

    case tags.float32Tag:
    case tags.float64Tag:
    case tags.int8Tag:
    case tags.int16Tag:
    case tags.int32Tag:
    case tags.uint8Tag:
    case tags.uint8ClampedTag:
    case tags.uint16Tag:
    case tags.uint32Tag:
      return cloneTypedArray(object)

    case tags.mapTag:
      return new Ctor()

    case tags.numberTag:
    case tags.stringTag:
      return new Ctor(object)

    case tags.regexpTag:
      return cloneRegExp(object)

    case tags.setTag:
      return new Ctor()

    case tags.symbolTag:
      return cloneSymbol(object)
  }
}

const initCloneArray = (array) => {
  const length = array.length
  const result = new array.constructor(length)

  // Add properties assigned by `RegExp#exec`.
  if (length && typeof array[0] === 'string' && Object.hasOwnProperty.call(array, 'index')) {
    result.index = array.index
    result.input = array.input
  }
  return result
}

const baseGetTag = (value) => {
  if (value == null) {
    return value === undefined ? tags.undefinedTag : tags.nullTag
  }

  return symToStringTag && symToStringTag in Object(value)
    ? getRawTag(value)
    : Object.prototype.toString.call(value)
}

/**
 * A clone of the lodash cloneDeepWith function. However, mimics the behaviour
 * of JSON.stringify(JSON.parse()) in that it will not clone undefined object values
 * and set array undefined values to null.
 * @param value
 * @param customizer
 * @returns {any[]|*|{}}
 */
const cloneDeep = (value, customizer, key = null, object = null) => {
  let result

  if (customizer) {
    result = object ? customizer(value, key, object) : customizer(value)
  }
  if (result !== undefined) {
    return result
  }
  if (!ld.isObject(value)) {
    return value
  }
  if (ld.isArray(value)) {
    result = initCloneArray(value)
  } else {
    const tag = baseGetTag(value)
    const isFunc = tag === '[object Function]' || tag === '[object GeneratorFunction]'

    if (ld.isBuffer(value)) {
      return value.slice()
    }

    if (tag === '[object Object]' || tag === '[object Arguments]' || (isFunc && !object)) {
      result = isFunc ? {} : initCloneObject(value)
    } else {
      if (!cloneableTags[tag]) {
        return object ? value : {}
      }
      result = initCloneByTag(value, tag)
    }
  }

  if (ld.isSet(value)) {
    value.forEach((subValue) => result.add(cloneDeep(subValue, customizer)))
  } else if (ld.isMap(value)) {
    value.forEach((subValue, key1) => result.set(key1, cloneDeep(subValue, customizer)))
  }

  const props = ld.isArray(value) ? undefined : ld.keys(value)
  ;(props || value).forEach((subValue, key1) => {
    if (props) {
      key1 = subValue
      subValue = value[key1]
    }

    // Recursively populate clone (susceptible to call stack limits).
    const res = cloneDeep(subValue, customizer, key1, value)
    if (typeof res !== 'undefined') {
      assignValue(result, key1, res)
    } else if (!props) {
      assignValue(result, key1, null)
    }
  })

  return result
}

export default cloneDeep
