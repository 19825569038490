// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'role',

  skipAudit: true,

  possibleStatuses: ['a', 'h'],
  tableColumns: [
    {
      column: 'role_name',
      visible: true,
      format: o => `${o.role_name}`,
      class: 'text-left justify-start align-self-center',
    },
  ],
  fields: {
    role_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    role_is_override: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    role_is_custom: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    role_is_generic: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    aoRolePermissions: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      save: true,
    },
  },
};
