import { useStore } from 'vuex'
import { computed, ref, toRef, watch } from 'vue'
import {
  ADD_CHANNEL,
  SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN,
  SET_CHANNEL_ACTIVITIES_NEXT_TOKEN,
  SET_CHANNEL_HAS_MORE_ACTIVITIES,
  SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES
} from '@/store/mutation-types'
import { useActivityChannels } from '@/components/composables/ActivityChannels'

export const useActivityChannel = (props) => {
  const store = useStore()

  // ======== Composables ======== //
  const { getFormattedChannelName, getStatusCodeName } = useActivityChannels()
  // ====================== //

  // ======== Refs ======== //
  const loading = ref(true)
  const projectLoading = ref(false)
  const projectObject = ref({})
  const normalizedProjectObject = ref({})
  const channelUserIsOnlineTimer = ref(null)
  // ====================== //

  // ======== Data ======== //
  const channelType = toRef(props.channelType)
  const channelTypeId = toRef(props.channelTypeId)
  // ====================== //

  // ======== Computed ======== //
  const userId = computed(
    () => store.state.session.authorizedUser?.user_id || store.state.session.user.user_id
  )
  const propsChannelId = computed(
    () => props.channelId || `${props.channelType}-${props.channelTypeId}`
  )
  const channelId = computed(() => props.channelId || `${channelType.value}-${channelTypeId.value}`)
  const channel = computed(() => store.state.activityChat.channels[channelId.value])
  const channelMembers = computed(() => channel.value?.channel_members.items)
  const channelName = computed(() => getFormattedChannelName(channel.value || null))
  const channelMuted = computed(
    () => channelMembers.value?.find((member) => member.user_id === userId.value)?.user_channel_mute
  )
  const channelArchived = computed(() => channel.value?.archived)
  const channelConnected = computed(() => store.state.activityChat.activitiesObserverConnected)
  const activityChannelTabs = computed(() =>
    [
      channelId.value,
      ...Object.values(store.state.activityChat.channels || {})
        .filter(
          (chan) =>
            chan.parent_channel_id === channelId.value ||
            channel?.value?.parent_channel_id === chan.channel_id
        )
        .map((channel) => channel.channel_id)
    ].sort()
  )
  const channelStatus = computed(() => channel.value?.channel_status)
  const channelClientName = computed(() =>
    channel.value?.client_fname || channel.value?.client_lname
      ? `${channel.value?.client_fname} ${channel.value?.client_lname}`
      : ''
  )
  // ====================== //

  // ======== Methods ======== //
  const toggleMuteChannel = () => {
    try {
      return store.dispatch('activityChat/updateChannelMember', {
        channelId: channelId.value,
        userId: userId.value,
        userChannelMute: !channelMuted.value,
        userChannelArchived: channelArchived.value
      })
    } catch (err) {
      this.$store.dispatch('alert', {
        error: true,
        message: 'An error occurred while toggling mute, please retry.'
      })
      console.error(err)
    }
  }
  const toggleArchiveChannel = () => {
    try {
      return store.dispatch('activityChat/updateChannelMember', {
        channelId: channelId.value,
        userId: userId.value,
        userChannelMute: channelMuted.value,
        userChannelArchived: !channelArchived.value
      })
    } catch (err) {
      this.$store.dispatch('alert', {
        error: true,
        message: 'An error occurred while toggling mute, please retry.'
      })
      console.error(err)
    }
  }
  const subscribeToActivityChannel = () => {
    return store.dispatch('activityChat/subscribeToChannelActivities', {
      channelId: channelId.value,
      userId: userId.value
    })
  }
  const unSubscribeFromActivityChannel = () => {
    return store.dispatch('activityChat/unsubscribeFromChannelActivities')
  }
  const setChannelUserIsOnline = (val) => {
    if (val) {
      return store.dispatch('activityChat/postChannelUserOnline', {
        channelId: channelId.value,
        userId: userId.value
      })
    }

    return store.dispatch('activityChat/removeChannelUserOnline', {
      channelId: channelId.value,
      userId: userId.value
    })
  }
  const fetchChannelActivities = async (nextToken = null) => {
    loading.value = true

    try {
      await store.dispatch('activityChat/getChannelActivities', {
        channelId: channelId.value,
        userId: userId.value,
        limit: 15,
        nextToken
      })
    } catch (err) {
      console.error(err)
    }

    loading.value = false
  }
  const clearChannelActivities = async () => {
    try {
      await store.dispatch('activityChat/clearChannelActivities', {
        channelId: channelId.value
      })
    } catch (err) {
      console.error(err)
    }
  }
  const postSeenActivity = async (activityId) => {
    try {
      await store.dispatch('activityChat/postMemberActivity', {
        userId: userId.value,
        activityId
      })

      await store.dispatch('activityChat/removeNotification', {
        userId: userId.value,
        channelId: channelId.value,
        activityId
      })
    } catch (err) {
      console.error(err)
    }
  }
  const initChannelActivities = async () => {
    // get channel if not exists //
    if (!store.state.activityChat.channels[channelId.value]) {
      const channel = await store.dispatch('activityChat/getChannel', {
        channelId: channelId.value
      })

      if (!channel) {
        await store.dispatch('alert', {
          error: true,
          message: 'No channel found.'
        })
        throw new Error('No channel found.')
      }

      await store.commit(`activityChat/${ADD_CHANNEL}`, channel)
    }

    store.commit(`activityChat/${SET_CHANNEL_ACTIVITIES_NEXT_TOKEN}`, {
      channelId: channelId.value,
      nextToken: null
    })
    store.commit(`activityChat/${SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN}`, {
      channelId: channelId.value,
      nextToken: null
    })
    store.commit(`activityChat/${SET_CHANNEL_HAS_MORE_ACTIVITIES}`, {
      channelId: channelId.value,
      hasNextToken: true
    })
    store.commit(`activityChat/${SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES}`, {
      channelId: channelId.value,
      hasNextToken: true
    })

    // get channel activities //
    await fetchChannelActivities()
    await subscribeToActivityChannel()
  }
  const destroyChannelActivities = async () => {
    await unSubscribeFromActivityChannel()
    clearInterval(channelUserIsOnlineTimer.value)
    await setChannelUserIsOnline(false)
  }
  const fetchProjectDetails = async () => {
    projectLoading.value = true
    projectObject.value = {}
    normalizedProjectObject.value = {}

    let chanType = channelType.value || props.channelId.split('-')[0]
    chanType = chanType === 'QUOTE_CLIENT' ? 'QUOTE' : chanType
    const chanTypeId = channelTypeId.value || props.channelId.split('-')[1]
    const type = chanType.charAt(0) + chanType.slice(1).toLowerCase()

    try {
      let quoteObject = null
      let normalizedQuoteObject = null
      const { normalized, rootRefId } = await store.dispatch(`${type}/fetchNormalized`, {
        id: chanTypeId
      })
      quoteObject = normalized[rootRefId]
      normalizedQuoteObject = normalized

      if (channelType.value === 'INVOICE') {
        const { normalized: norm, rootRefId: rrid } = await store.dispatch(
          `Quote/fetchNormalized`,
          {
            id: quoteObject.quote_id
          }
        )
        quoteObject = norm[rrid]
        normalizedQuoteObject = norm
      }

      if (quoteObject && normalizedQuoteObject) {
        projectObject.value = quoteObject
        normalizedProjectObject.value = normalizedQuoteObject
      }
    } catch (err) {
      console.log(err)
      await store.dispatch('alert', {
        type: 'error',
        message: 'Failed to fetch project details'
      })
    }

    projectLoading.value = false
  }
  // ====================== //

  // ====== watchers ====== //
  watch(propsChannelId, () => {
    channelType.value = props.channelType
    channelTypeId.value = props.channelTypeId
  })
  // ====================== //

  return {
    loading,
    projectLoading,
    projectObject,
    normalizedProjectObject,
    channelUserIsOnlineTimer,
    channelId,
    channel,
    channelName,
    channelMembers,
    channelMuted,
    channelArchived,
    channelConnected,
    activityChannelTabs,
    channelType,
    channelTypeId,
    channelStatus,
    channelClientName,
    getStatusCodeName,
    toggleMuteChannel,
    toggleArchiveChannel,
    subscribeToActivityChannel,
    unSubscribeFromActivityChannel,
    setChannelUserIsOnline,
    fetchChannelActivities,
    initChannelActivities,
    destroyChannelActivities,
    postSeenActivity,
    fetchProjectDetails,
    clearChannelActivities
  }
}

export default useActivityChannel
