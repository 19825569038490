<template>
  <div
    class="sticky w-full flex flex-row justify-end items-center h-field px-4 py-3 floating-toolbar container-element"
    v-if="!hideNav"
    :class="{
      'floating-toolbar--mobile-browser': $store.state.session.mobileBrowser,
      'floating-toolbar--full-width': fullWidth
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
/**
 * Classes:
 * -bottom
 * -mist
 */
export default {
  name: 'FloatingToolbar',
  computed: {
    hideNav() {
      return !!this.$store.state.session.hideNav
    }
  },
  props: {
    height: {
      required: false,
      default: 0
    },
    fullWidth: {
      default: false
    }
  }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.floating-toolbar {
  %scrollbar {
    $scrollBarWidth: 15px !default;

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      height: $scrollBarWidth;
      display: block;
    }
    &::-webkit-scrollbar-corner {
      display: block;
      background: rgba($cool-gray-100, 0.6);
      border-radius: 50%;
      padding: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      width: $scrollBarWidth;
      height: $scrollBarWidth;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba($cool-gray-100, 0.6);
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba($cool-gray-100, 0.25);
    }
  }

  %scrollbarSm {
    $scrollBarWidthSm: 5px !default;
    @extend %scrollbar;
    &::-webkit-scrollbar {
      width: $scrollBarWidthSm !important;
      height: $scrollBarWidthSm !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      width: $scrollBarWidthSm !important;
      height: $scrollBarWidthSm !important;
    }
  }

  z-index: 999;
  box-shadow: 0 2px 6px 0 rgba(16, 24, 40, 0.06);
  background: $flame-white;

  overflow: hidden;
  overflow-x: scroll;
  @extend %scrollbarSm;

  &:not(.fixed) {
    top: 0 !important;
  }

  &.auto {
    max-height: unset;
  }

  &.bottom {
    top: auto !important;
    left: 0;
    right: 0;
    bottom: -1px;
    position: fixed;
    margin-bottom: 0;
    box-shadow: 0px -20px 20px -20px rgba(115, 120, 123, 0.4);

    @media (max-width: 992px) {
      left: 0px;
    }
  }

  &.mist {
    box-shadow: 0 20px 30px 10px $cool-gray-600;
    &.bottom {
      box-shadow: 0 -20px 30px -10px $cool-gray-600;
    }
  }

  &.fixed {
    position: fixed;
    top: 50px;
    &.bottom {
      top: auto !important;
      max-height: unset;
      right: 0;
      left: auto;
      margin: 0 !important;
      position: fixed;
      @media (min-width: 576px) {
        width: calc(100vw - 60px) !important;
      }
      @media (max-width: 576px) {
        width: 100vw !important;
      }
    }
  }

  &.modified {
    $modified: $cool-gray-200;
    height: 70px;

    .btn-primary {
      background: $cool-gray-300 !important;
      border-color: $cool-gray-300 !important;
      &:hover {
        background: $cool-gray-300 !important;
        border-color: $cool-gray-300 !important;
      }
    }
    .btn-outline-primary {
      color: $cool-gray-300 !important;
      border-color: $cool-gray-600 !important;
      &:hover {
        color: $flame-white !important;
        background: $cool-gray-300 !important;
        border-color: $cool-gray-300 !important;
      }
    }
  }

  &.floating-toolbar--mobile-browser.bottom {
    top: auto !important;
    left: 0;
    right: 0;
    bottom: -1px;
    position: fixed;
    margin-bottom: 0;
    max-height: unset;
    height: 7rem;
    padding-bottom: 3.5rem;
    box-shadow: 0px -20px 20px -20px rgba(115, 120, 123, 0.4);

    @media (max-width: 992px) {
      left: 0px;
    }
  }
}

#app.small-format {
  .floating-toolbar {
    font-size: 0.8em;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    height: 11em;
    padding: 0.5em 1em !important;
    position: fixed;
    bottom: 0;
    max-height: unset;
    padding-bottom: 3em;
    .filter-section {
      padding: 0 1em;
    }
  }
}
</style>
