<script setup>
import { ref, defineEmits, onBeforeMount, defineProps, computed } from 'vue'
import Tutorial from '@/assets/template_graphics/tutorial.png'
import Scratch from '@/assets/template_graphics/scratch.png'
import { useStore } from 'vuex'
import OnboardingMilestones from '@/components/composables/OnboardingMilestones.js'
// import Fencing from '@/assets/template_graphics/fencing.png'
// import Garage from '@/assets/template_graphics/garage3.png'
// import House from '@/assets/template_graphics/house.png'
// import Basement from '@/assets/template_graphics/basement.png'
// import Siding from '@/assets/template_graphics/siding2.png'
// import BathroomImg from '@/assets/template_graphics/bathroom.png'
// import KitchenImg from '@/assets/template_graphics/kitchen.png'
// import RooflineImg from '@/assets/template_graphics/roofline.png'
// import Divider from 'primevue/divider'

const props = defineProps({
  store: {
    type: String,
    required: true
  }
})

const { isMilestoneComplete } = OnboardingMilestones.useOnboardingMilestones()

const tutorialDone = computed(() => isMilestoneComplete('guide-Basics'))

const presets = computed(() => [
  // {
  //   title: 'Kitchen',
  //   subtitle: 'Estimate kitchen projects',
  //   description: 'Create estimates for kitchen renovations, including all necessary details.',
  //   image: KitchenImg,
  //   key: 'kitchen'
  // },
  // {
  //   title: 'Bathroom',
  //   subtitle: 'Estimate bathroom projects',
  //   description: 'Create estimates for bathroom renovations, including all necessary details.',
  //   image: BathroomImg,
  //   key: 'bathroom'
  // },
  // {
  //   title: 'Roof',
  //   subtitle: 'Estimate roofing projects',
  //   description: 'Create estimates for roof installations or repairs.',
  //   image: RooflineImg,
  //   key: 'roof'
  // },
  // {
  //   title: 'Fence',
  //   subtitle: 'Estimate fencing projects',
  //   description: 'Create estimates for fence installations.',
  //   image: Fencing,
  //   key: 'fence'
  // },
  // {
  //   title: 'Garage',
  //   subtitle: 'Estimate garage projects',
  //   description: 'Create estimates for building or renovating garages.',
  //   image: Garage,
  //   key: 'garage'
  // },
  // {
  //   title: 'New house',
  //   subtitle: 'Estimate new house projects',
  //   description: 'Create comprehensive estimates for building new houses.',
  //   image: House,
  //   key: 'new_house'
  // },
  // {
  //   title: 'Basement',
  //   subtitle: 'Estimate basement projects',
  //   description: 'Create estimates for basement finishing or remodeling.',
  //   image: Basement,
  //   key: 'basement'
  // },
  // {
  //   title: 'Exterior siding',
  //   subtitle: 'Estimate siding projects',
  //   description: 'Create estimates for exterior siding installations or repairs.',
  //   image: Siding,
  //   key: 'exterior_siding'
  // },
  // {
  //   title: 'Interior or exterior painting',
  //   subtitle: 'Estimate painting projects',
  //   description: 'Create estimates for interior or exterior painting jobs.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'painting'
  // },
  // {
  //   title: 'Addition',
  //   subtitle: 'Estimate home addition projects',
  //   description: 'Create estimates for home additions.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'addition'
  // },
  // {
  //   title: 'Additional dwelling unit',
  //   subtitle: 'Estimate ADU projects',
  //   description: 'Create estimates for building additional dwelling units (ADUs).',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'adu'
  // },
  // {
  //   title: 'Deck',
  //   subtitle: 'Estimate deck projects',
  //   description: 'Create estimates for deck building or repairs.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'deck'
  // },
  // {
  //   title: 'Landscaping',
  //   subtitle: 'Estimate landscaping projects',
  //   description: 'Create estimates for landscaping work.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'landscaping'
  // },
  // {
  //   title: 'Pool',
  //   subtitle: 'Estimate pool projects',
  //   description: 'Create estimates for pool installations or repairs.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'pool'
  // },
  // {
  //   title: 'Foundation',
  //   subtitle: 'Estimate foundation projects',
  //   description: 'Create estimates for foundation work.',
  //   image: 'https://primefaces.org/cdn/primevue/images/usercard.png',
  //   key: 'foundation'
  // }
])

const ownAssemblies = ref([])

const templates = computed(() => [
  ...(!tutorialDone.value
    ? [
        {
          title: 'Tutorial',
          subtitle: 'Get the basics down',
          description:
            '5 minute tutorial to quickly learn how to add items, drag and drop and set pricing.',
          image: Tutorial,
          key: 'tutorial',
          highlight: true
        }
      ]
    : []),
  {
    title: 'Start from scratch',
    subtitle: 'Blank slate',
    description: 'Build a detailed estimate from scratch. Best for experienced users.',
    image: Scratch,
    key: 'blank'
  },
  ...(tutorialDone.value
    ? [
        {
          title: 'Tutorial',
          subtitle: 'Get the basics down',
          tag: 'Completed',
          tagIcon: 'check',
          muted: true,
          description:
            '5 minute tutorial to quickly learn how to add items, drag and drop and set pricing.',
          image: Tutorial,
          key: 'tutorial'
        }
      ]
    : []),
  ...ownAssemblies.value,
  ...presets.value
])

const $store = useStore()

onBeforeMount(() => {
  addOwnAssemblies()
})

const addOwnAssemblies = async () => {
  const { set } = await $store.dispatch('CostType/search', {
    // assemblies are searched via CostType
    filters: {
      type: 'assembly',
      assembly_count_usage: '>0',
      company_id: $store.state.session.company.company_id
    },
    limit: 10,
    order: [['assembly_count_usage', 'desc']]
  })

  ownAssemblies.value = set.map((assembly) => ({
    title: assembly.assembly_name,
    subtitle: 'Your assembly',
    description: assembly.quote_notes,
    image:
      assembly.file_ids?.[0] &&
      c.link(`file/view/${assembly.file_ids?.[0]}`, { max: 300 }, true, $store.state.session.scope),
    key: assembly.assembly_id,
    id: assembly.assembly_id
  }))
}

const emit = defineEmits(['choose'])
const choose = (option) => emit('choose', option)
</script>

<template>
  <div class="flex flex-col gap-5 user-select-none">
    <template v-if="props.store === 'Quote'">
      <p class="text-xl mb-8">
        We've built thousands of estimates, so leverage our experience and start with a Bolster
        template to maximize your sales, and minimize your pain! If you insist, you can definitely
        build from scratch. And either way, just like everything in Bolster, your estimate will
        transition seamlessly into project management -- automatically
        <font-awesome-icon icon="sparkles" />
      </p>
      <div class="border-b border-surface-300 py-2 font-medium text-large">
        Start from a template
      </div>
      <div class="py-2 gap-4 flex flex-row flex-wrap justify-stretch items-stretch">
        <div
          v-for="(option, index) in templates"
          :key="index"
          :class="[
            'w-[25rem] overflow-hidden rounded-sm hover:bg-surface-100/35 border border-surface-200 bg-white select-none user-select-none flex flex-col justify-stretch items-stretch',
            {
              'opacity-60': option.muted,
              'ring-4 ring-blue-print/80': option.highlight
            }
          ]"
        >
          <div class="w-full h-40 shrink-0 overflow-hidden relative">
            <img
              v-if="option.image"
              alt="user header"
              :src="option.image"
              class="object-cover object-center w-full h-60"
            />
          </div>

          <div class="flex flex-col gap-8 p-4 basis-full justify-between items-stretch">
            <div class="flex flex-col gap-2">
              <div
                class="font-medium text-xl tracking-wide leading-none flex justify-between items-center gap-2"
              >
                {{ option.title }}

                <div v-if="option.tag" class="text-sm font-medium text-blue-print">
                  <font-awesome-icon :icon="option.tagIcon" v-if="option.tagIcon" />
                  {{ option.tag }}
                </div>
              </div>

              <div class="font-medium text-sm text-surface-400 leading-none">
                {{ option.subtitle }}
              </div>

              <p class="leading-tight">
                {{ option.description }}
              </p>
            </div>

            <Btn severity="tertiary" class="w-full" :action="() => choose(option)">Choose this</Btn>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-center items-center">
        <Automation :closable="false" class="max-w-[600px] flex flex-col gap-2">
          <div class="text-xl font-medium">No items in your assembly yet!</div>
          Assemblies are like templates that you can re-use over and over. You can even add
          assemblies to other assemblies, for maximum re-usability.

          <div>Create a new item to get started!</div>
          <Btn :action="() => choose({ key: 'blank' })" severity="primary" size="xl">
            <font-awesome-icon icon="fa-solid fa-plus" />
            Add your first item
          </Btn>
        </Automation>
      </div>
    </template>
  </div>
</template>
