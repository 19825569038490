<template>
  <Section class="p-0">
    <Container size="screen-lg">
      <div>
        <div class="flex flex-row items-center text-2xl gap-4 mt-6 mb-3">
          <font-awesome-icon icon="user" />
          <h1>Edit Profile</h1>
        </div>
        <div class="bg-surface-200 w-full h-px" />
      </div>
      <div>
        <h3>Profile picture</h3>
        <ImageSelector v-model="profile_file_id" :rounded="true" size="md" />
      </div>
      <template v-if="selfEditing">
        <div>
          <h3>Connected Accounts</h3>
          <Suspense>
            <ConnectedAccounts />
          </Suspense>
        </div>
        <div>
          <h3>Multi-Factor Authentication</h3>
          <Suspense>
            <UserMFASettings />
          </Suspense>
        </div>
        <div>
          <h3 class="mb-3">Password</h3>
          <Btn severity="tertiary" size="lg" @click="() => $refs.passwordReset.open()">
            <template #icon>
              <font-awesome-icon icon="key" />
            </template>
            Change password
          </Btn>
        </div>
      </template>

      <div>
        <h3 class="mb-3">Personal details</h3>
        <card-list>
          <card-list-field>
            <span>First name</span>

            <field v-model="user_fname" :validate="{ required: true }" />
          </card-list-field>
          <card-list-field>
            <span>Last name</span>

            <field v-model="user_lname" :validate="{ required: true }" />
          </card-list-field>
          <card-list-field>
            <span>Phone</span>

            <field v-model="user_phone" schema="user:user_phone" format="phone" />
          </card-list-field>
          <card-list-field>
            <span>Email</span>

            <UniqueField
              v-model="user_email"
              schema="user:user_email"
              :validate="{
                type: 'email',
                required: true
              }"
              :unique="{
                type: 'user',
                field: 'user_email',
                except: user_id ? [user_id] : [],
                exact: true
              }"
            />
          </card-list-field>
        </card-list>
      </div>

      <div>
        <h3 class="mb-3">Address</h3>
        <card-list>
          <card-list-field>
            <span>Street address</span>

            <field v-model="user_address" />
          </card-list-field>
          <card-list-field>
            <span>Unit/Suite</span>

            <field v-model="user_suite" />
          </card-list-field>
          <card-list-field>
            <span>City</span>

            <field v-model="user_city" />
          </card-list-field>
          <card-list-field v-if="!company">
            <span>Country</span>

            <choose
              :return-array="false"
              schema="user:country_id"
              v-model="country_id"
              :validate="{ required: true }"
            />
          </card-list-field>
          <card-list-field>
            <span>Province/state</span>

            <choose
              :return-array="false"
              schema="user:province_id"
              v-model="province_id"
              :filters="{ country_id }"
              :validate="{ required: true }"
            />
          </card-list-field>

          <card-list-field>
            <span>Postal</span>

            <field
              v-model="user_postal"
              schema="user:user_postal"
              :validate="{ format: country_id === 1 ? 'postal_ca' : 'postal_us' }"
            />
          </card-list-field>
        </card-list>
      </div>

      <div v-if="!company">
        <h3 class="mb-3">Currency</h3>
        <card-list>
          <template>
            <card-list-field>
              <choose
                :return-array="false"
                schema="user:currency_id"
                v-model="currency_id"
                :validate="{ required: true }"
              />
            </card-list-field>

            <card-list-field>
              <span>Localization</span>

              <choose
                :return-array="false"
                schema="user:localization_id"
                v-model="localization_id"
                :validate="{ required: true }"
              />
            </card-list-field>
          </template>
        </card-list>
      </div>

      <div v-if="company && $store.state.session.user.user_is_admin">
        <h3 class="mb-3">Administrator</h3>
        <card-list>
          <card-list-field>
            <span>Grant admin privileges?</span>

            <toggle v-model="user_is_admin" :onValue="1" :offValue="0" onText="Yes" offText="No" />
          </card-list-field>
        </card-list>
      </div>
      <div v-if="!selfEditing && $store.state.session.authorizedUser.user_is_super_user">
        <h3 class="mb-3">
          Super Ultimate Bolster Power
          <SuperIcon />
        </h3>
        <card-list>
          <card-list-field>
            <span>Toggle Super Saiyan God Mode</span>
            <InputSwitch v-model="user_is_super_user" :true-value="1" :false-value="0" />
          </card-list-field>
        </card-list>
        <warning class="mt-3" :closable="false">
          With great power, comes great responsibility! Be absolutely certain before you toggle
          this, Spider-Man.
        </warning>
      </div>
    </Container>
    <PasswordReset ref="passwordReset" />
  </Section>
</template>

<script>
import PasswordReset from '@/components/modals/auth/PasswordReset.vue'
import ObjectManipulator from '..//mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body.js'
import UniqueField from '../ui/UniqueField.vue'
import Section from '../ui/Section.vue'
import eventBus from '../../eventBus'
import ConnectedAccounts from '@/components/identityProviders/ConnectedAccounts.vue'
import SuperIcon from '@/components/ui/Icon/SuperIcon.vue'
import UserMFASettings from '@/components/user/UserMFASettings.vue'

export default {
  name: 'UserEdit',
  mixins: [ObjectManipulator('user', false), BodyMixin],
  computed: {
    company() {
      return this.$store.state.session.scope.company && this.$store.state.session.company
    },
    selfEditing() {
      return String(this.$store.state.session.authorizedUser.user_id) === String(this.user_id)
    }
  },
  components: {
    SuperIcon,
    UserMFASettings,
    ConnectedAccounts,
    PasswordReset,
    Section,
    UniqueField
  },
  props: {
    deselectOnDestroy: {
      default: false
    }
  },
  mounted() {
    eventBus.$on(`${this.uid}-selected`, () => {
      if (this.company && this.company.country_id) {
        this.country_id = this.company.country_id
        this.province_id = this.company.province_id
        this.localization_id = this.company.localization_id
        this.user_city = this.company.company_city
      }
    })
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
