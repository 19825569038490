<template>
  <div class="image-gallery--container">
    <template v-if="showAsBackground">
      <div
        class="image-gallery--thumb"
        v-for="(url, index) in thumblist"
        @click="() => preview(urls[index])"
        :key="`th${url}`"
        :style="`background: url(${url});
          background-repeat: no-repeat;
          background-size: cover;`"
      >
        &nbsp;
      </div>
    </template>
    <template v-else>
      <img
        class="image-gallery--thumb"
        v-for="(url, index) in thumblist"
        :src="url"
        :key="`th${url}`"
        @click="() => preview(urls[index])"
      />
    </template>

    <MiniModal
      classes="image-preview"
      v-if="previewUrl"
      size="full"
      :full="true"
      ref="expandedModal"
    >
      <div class="flex justify-center items-center pb-0" style="padding-bottom: 0 !important">
        <Btn rounded class="more px-0 p-2 light mr-1" @click="() => prev()" v-if="urls.length > 1">
          <font-awesome-icon icon="arrow-left" />
        </Btn>
        <img
          :key="previewUrl"
          @click="() => next()"
          style="margin: auto; display: block; cursor: pointer"
          :src="previewUrl"
          class="image-gallery--full-image"
        />
        <Btn rounded class="more px-0 p-2 light mr-2" @click="() => next()" v-if="urls.length > 1">
          <font-awesome-icon icon="arrow-right" />
        </Btn>
      </div>
    </MiniModal>
  </div>
</template>

<script>
export default {
  name: 'ImageGallery',
  emits: ['viewing'],
  data() {
    return {
      previewUrl: this.urls.length ? this.urls[0] : null
    }
  },
  mounted() {
    this.$emit('viewing', this.previewUrl)
  },
  watch: {
    previewUrl(url) {
      this.$emit('viewing', url)
    }
  },
  computed: {
    thumblist() {
      return this.thumbs.length ? this.thumbs : this.urls
    },
    curIndex() {
      if (!this.previewUrl) return -1
      return this.urls.indexOf(this.previewUrl)
    },
    canNext() {
      const cur = this.curIndex
      return this.urls.length > cur + 1
    },
    canPrev() {
      const cur = this.curIndex
      return cur > 0
    }
  },
  methods: {
    async preview(url = null) {
      if (url) this.previewUrl = url
      await this.$nextTick()
      this.$refs.expandedModal.open()
    },
    next() {
      this.previewUrl = this.canNext ? this.urls[this.curIndex + 1] : this.urls[0]
    },
    prev() {
      this.previewUrl = this.canPrev
        ? this.urls[this.curIndex - 1]
        : this.urls[this.urls.length - 1]
    }
  },
  props: {
    showAsBackground: {
      default: true
    },
    urls: {
      type: Array,
      required: true
    },
    thumbs: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.image-gallery--container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  /*row-gap: 0.15em;*/
  /*column-gap: 0.15em;*/
  > *:not(:last-child) {
    margin-right: 0.15em !important;
    margin-bottom: 0.15em;
  }
  margin-bottom: -0.15em;
}
.image-gallery--full-image {
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 0.5em;
}
.image-gallery--thumb {
  height: 5em;
  width: 5em;
  border-radius: 0.5em;
  cursor: pointer;
  transition:
    transform 0.07s,
    box-shadow 0.2s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadow-more;
  }
}
img.image-gallery--thumb {
  height: 105px;
  width: unset;
}

.modal.image-preview {
  .modal-content {
    background: rgba($pitch-black, 0.9) !important;
  }
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
