<template>
  <div
    class="line-item-list--item-container-outer"
    :style="{
      'z-index': expanded ? 9999 : 0
    }"
  >
    <component
      :showForCost="showForCost"
      @expanded="toggleExpanded"
      @actions="getActions"
      @openContextMenu="openContextMenu"
      ref="child"
      :store="store"
      :inAssembly="inAssembly"
      :is="component"
      :reference="reference"
      :key="reference"
      :childIndex="childIndex"
      :rootRefId="rootRefId"
      :editable="editable"
    >
      <btn-bar ref="contextMenu" :collapse="true" :actions="actions">
        <template #button>
          <Button rounded size="small" @click.native="openContextMenu">
            <template #icon>
              <font-awesome-icon icon="ellipsis" fixed-width />
            </template>
          </Button>
        </template>
      </btn-bar>
    </component>
  </div>
</template>

<script>
import Button from 'primevue/button'
import Assembly from './Assembly.vue'
import CostItem from './CostItem.vue'

CostItem.compatConfig = { MODE: 3 }
Assembly.compatConfig = { MODE: 3 }

export default {
  name: 'LineItem',
  emits: ['expanded'],
  data() {
    return {
      text: '',
      contextRequested: 0,
      expanded: false,
      actions: {}
    }
  },
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },
    titleType() {
      return this.type === 'cost_item' ? 'item' : 'assembly'
    },
    type() {
      return this.norm[this.reference].type
    },
    component() {
      if (this.type === 'quote' || this.type === 'assembly') return 'Assembly'
      return 'CostItem'
    }
  },
  methods: {
    getActions(a) {
      this.actions = a
    },
    toggleExpanded(b) {
      this.expanded = b
      this.$emit('expanded', this.reference, b)
    },
    remove() {
      if (this.editable) {
        this.$refs.child.remove(this.$refs.collapseBtn)
        this.closeContextMenu()
      } else {
        this.$store.dispatch('alert', {
          message: 'You cannot edit this project.  Create a change order instead.'
        })
      }
    },
    edit() {
      this.$refs.child.edit(this.$refs.collapseBtn)
      this.closeContextMenu()
    },
    save(asNew = false) {
      this.$refs.child.save(asNew, this.$refs.collapseBtn)
      this.closeContextMenu()
    },
    duplicate() {
      if (this.editable) {
        this.$refs.child.duplicate(this.$refs.collapseBtn)
        this.closeContextMenu()
      } else {
        this.$store.dispatch('alert', {
          message: 'You cannot edit this project.  Create a change order instead.'
        })
      }
    },
    openContextMenu() {
      if (!this.contextRequested) {
        this.contextRequested = 1
        setTimeout(() => this.$refs.contextMenu.open(), 100)
      } else {
        this.$refs.contextMenu.open()
      }
    },
    closeContextMenu() {
      setTimeout(() => {
        try {
          this.$refs.contextMenu.close()
        } catch (err) {
          // already removed
        }
      }, 200)
    }
  },
  props: {
    showForCost: {
      default: () => ['cost', 'price']
    },
    // if root object is an assembly
    inAssembly: {
      default: false
    },
    editable: {
      default: true
    },
    reference: {
      required: true
    },
    childIndex: {
      required: true
    },
    store: {
      default: 'Quote'
    },
    rootRefId: {
      required: true
    }
  },
  components: {
    CostItem,
    Assembly,
    Button
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.line-item-list--item-container-outer {
  position: relative;
  overflow-x: visible;
  padding-bottom: 0.5em;
  transition: z-index 1ms ease;
  will-change: z-index;

  .move-handle {
    right: 1em;
    top: 1em;
  }

  &:hover {
    cursor: pointer;
    z-index: $z-layout - 1 !important;
  }
  & > div > div > ul.list-group > .card-list-collapse-closed {
    height: 100%;
  }
}
</style>
