<template>
  <modal
    :pt="{
      closeButton: { class: '!w-0 !p-0 !m-0 !h-0' },
      body: {
        class: '!p-0'
      }
    }"
    ref="modal"
    :scrollable="false"
    v-bind="$props"
    :clickAway="false"
    size="full"
    :full="true"
    :fixed="true"
    :showTopClose="false"
  >
    <template #header>
      <div class="flex justify-between w-full">
        <div class="flex gap-2 items-center">
          <font-awesome-icon :icon="['fas', 'cubes']" />
          Build Assembly
        </div>
        <div>
          <EntitySelect
            :object="{ ...object, ...(modal.embue ?? {}) }"
            type="assembly"
            :preclear="true"
          >
            <template #default="{ refId, store }">
              <Btn link size="xl" :action="close">Cancel</Btn>
              <Btn severity="bolster" size="xl" :action="() => saveAndClose3({ refId, store })"
                >Save</Btn
              >
            </template>
          </EntitySelect>
        </div>
      </div>
    </template>
    <template #body>
      <EntitySelect :object="{ ...object, ...(modal.embue ?? {}) }" type="assembly">
        <template #default="{ refId }">
          <assembly-body ref="body" :refId="refId" />
        </template>
      </EntitySelect>
    </template>
  </modal>
</template>

<script>
import AssemblyBody from '../bodies/Assembly.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import EntitySelect from '@/components/ui/EntitySelect.vue'

export default {
  name: 'AssemblyNew',
  mixins: [ObjectModalMixin],
  components: { EntitySelect, AssemblyBody },
  props: {
    type: {
      default: 'assembly'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
