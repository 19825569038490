<template>
  <!-- Main container -->
  <div
    class="relative flex flex-col border p-4 gap-4 min-h-[525px] rounded transition select-none"
    :class="[
      selected ? 'border-cement-500 bg-cement-300' : 'bg-flame-white',
      canSelect ? `cursor-pointer ${selected ? 'hover:bg-cement-200' : 'hover:bg-cement-100'}` : '',
      !hasAutoCostSubscription && isLivePrice && !isParent && !inGlobalScope ? 'opacity-50' : ''
    ]"
    @click="onClick"
    :data-label="itemLabel"
  >
    <!-- Image -->
    <div>
      <!-- Custom image -->
      <div
        v-if="ownImage"
        class="w-full aspect-square max-h-[30vh] rounded bg-center bg-no-repeat bg-cover"
        :style="{ backgroundImage: `url(${ownImage})` }"
      ></div>
      <!-- Craftsman image -->
      <div
        v-else-if="isCraftsman"
        class="flex flex-col justify-center items-center rounded w-full aspect-square *:text-surface-300 bg-surface-100"
      >
        <div class="mb-3">
          <icon v-if="isCraftsmanMaterial" :icon="'box-taped'" class="text-4xl" />
          <icon v-else-if="isCraftsmanLabor" :icon="'user-helmet-safety'" class="text-4xl" />
        </div>
        <p class="text-xl">AutoCost</p>
        <p class="text-lg" v-if="isCraftsmanMaterial">Material</p>
        <p class="text-lg font-light" v-else-if="isCraftsmanLabor">Labor</p>
      </div>
      <!-- manufacturer image -->
      <div
        v-else-if="manufacturersImage"
        class="w-full aspect-square rounded bg-center bg-no-repeat bg-cover"
        :style="{ backgroundImage: `url(${manufacturersImage})` }"
      />
      <!-- Placeholder image -->
      <div
        v-else
        class="flex justify-center items-center w-full aspect-square rounded"
        :class="isParent ? '' : 'bg-surface-100'"
      >
        <icon v-if="isParent" icon="folder-open" class="text-7xl text-surface-200" />
        <icon v-else :icon="['fas', 'cube']" class="text-7xl text-surface-200" />
      </div>
    </div>

    <!-- Top right icons -->
    <div class="absolute right-1 px-6 py-2 cursor-pointer">
      <!-- Autocost item -->
      <div
        v-if="isLivePrice && !isParent"
        v-tooltip="
          object.type === 'assembly' ? 'Premium AutoCost assembly' : 'Premium AutoCost item'
        "
      >
        <icon
          icon="svg:autocostWhite"
          class="bg-matcha-500 rounded-full aspect-square h-5 p-0.5 border border-surface-0"
        />
      </div>
      <!-- Shared item / assembly -->
      <div
        v-else-if="
          object.company_id && object.company_id !== $store.state.session.scope.company?.toString()
        "
        v-tooltip="object.type === 'assembly' ? 'Shared assembly' : 'Shared item'"
      >
        <icon
          v-if="!company"
          :icon="['fas', 'share-nodes']"
          class="bg-flame-white p-1 rounded-full"
        />
      </div>
      <!-- Global item / assembly -->
      <div
        v-else-if="!object.company_id && !isParent"
        v-tooltip="object.type === 'assembly' ? 'Bolster assembly' : 'Bolster item'"
      >
        <icon icon="svg:bolsterIconGrey" class="aspect-square h-4 p-0.5" />
      </div>
    </div>

    <!-- Top left checkbox -->
    <div
      v-if="canSelect"
      class="absolute flex justify-center items-center border w-8 h-8 m-2 rounded-md transition"
      :class="
        selected
          ? 'bg-pitch-black border-pitch-black text-level-yellow'
          : 'bg-flame-white border-surface-400 hover:bg-surface-100'
      "
    >
      <icon v-if="selected" :icon="['fas', 'check']" />
    </div>

    <!-- Information -->
    <div class="flex flex-col h-full">
      <!-- Name -->
      <div class="text-lg font-medium line-clamp-2 cursor-pointer leading-tight" v-tooltip="name">
        {{ name }}
      </div>
      <!-- Attribute tags-->
      <div class="flex flex-col gap-2 mt-2 mb-4">
        <div v-if="!isParent" class="flex flex-row gap-1 flex-wrap">
          <!-- Item or assembly -->
          <div
            class="!h-6 px-2 rounded-sm w-fit text-xs bg-surface-100 text-surface-600 font-light gap-1 flex justify-center items-center"
          >
            <font-awesome-icon icon="folder-open" v-if="!isAssembly && isParent" size="xs" />
            <font-awesome-icon icon="cube" v-if="!isAssembly" size="xs" />
            <font-awesome-icon icon="cubes" v-else-if="isAssembly" size="xs" />
            {{ isAssembly ? 'Assembly' : isParent ? 'Category' : 'Item' }}
          </div>
          <div
            class="flex flex-row gap-1 flex-wrap *:text-sm *:flex *:justify-center *:items-center *:bg-surface-100 *:rounded-full *:!size-6 *:aspect-square cursor-pointer"
          >
            <!-- Costs are averages -->
            <div
              v-if="
                object.cost_type_is_indexed ||
                (object.labor_type_is_indexed && object.cost_type_has_labor)
              "
              v-tooltip="
                'The costs in this item are averages, adjusted based on your postal/zip code (US/CA Only)'
              "
            >
              <font-awesome-icon icon="map-location-dot" class="tli--type-badge" size="xs" />
            </div>
            <!-- Includes labor -->
            <div
              class="!bg-labor/20"
              v-if="object.cost_type_has_labor && !object.cost_type_is_subcontracted"
              v-tooltip="'Includes labor'"
            >
              <font-awesome-icon icon="user-helmet-safety" class="tli--type-badge" size="xs" />
            </div>
            <!-- Includes materials -->
            <div
              class="!bg-materials/20"
              v-if="object.cost_type_has_materials && !object.cost_type_is_subcontracted"
              v-tooltip="'Includes materials'"
            >
              <font-awesome-icon icon="box-taped" class="tli--type-badge" size="xs" />
            </div>
            <!-- Subcontracted -->
            <div
              class="!bg-subcontractor/20"
              v-if="object.cost_type_is_subcontracted"
              v-tooltip="'Subcontracted'"
            >
              <font-awesome-icon icon="truck" class="tli--type-badge" size="xs" />
            </div>
            <!-- Includes upgrades -->
            <div
              class="!bg-pitch-black text-level-yellow"
              v-if="object?.aoAddons?.length || object.quote_count_addons_available"
              v-tooltip="'Includes upgrades'"
            >
              <font-awesome-icon icon="swatchbook" size="xs" />
            </div>
            <!-- Part of a variation group -->
            <div
              class="!bg-pitch-black text-level-yellow"
              v-if="object.variation_parent_cost_type_id || object.cost_type_is_variation_parent"
              v-tooltip="'This is part of a variations group'"
            >
              <font-awesome-icon icon="swatchbook" size="xs" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row mt-auto pt-4 w-full justify-between items-center">
        <!-- Price -->
        <div
          v-if="!isParent"
          :class="!hasAutoCostSubscription && isLivePrice ? 'cursor-pointer' : ''"
          v-tooltip="
            !hasAutoCostSubscription && isLivePrice
              ? 'Upgrade to AutoCost for automatic, local pricing on 130,000+ items.'
              : ''
          "
        >
          <div
            v-if="isLivePrice && isAssembly && object.quote_notes"
            class="mb-4 -mt-4"
            v-tooltip="object.quote_notes"
          >
            <span class="text-sm text-surface-400 font-light leading-normal cursor-pointer">
              {{ $f.truncate(object.quote_notes, 100) }}
            </span>
          </div>

          <div class="text-xs text-surface-400">
            {{ isAssembly ? 'Approx' : isVariationParent ? 'From' : '' }}
          </div>
          <div class="flex items-center">
            <span class="text-xl text-pitch-black">
              ${{
                hasAutoCostSubscription || !isLivePrice || inGlobalScope
                  ? $f.currency(price)
                  : obscurePrice($f.currency(price))
              }}
            </span>
            <small class="text-sm text-surface-400" v-if="!isAssembly">
              &nbsp;/{{ ` ${unit.toLowerCase()}` }}
            </small>
          </div>
          <div v-if="isLivePrice && !isCraftsman && !isAssembly" class="flex items-center">
            <span class="text-md text-surface-400 font-light">
              ${{
                hasAutoCostSubscription || !isLivePrice || inGlobalScope
                  ? $f.currency(autoCostOriginalPrice)
                  : obscurePrice($f.currency(autoCostOriginalPrice))
              }}
            </span>
            <small class="text-sm text-surface-400 font-light"> &nbsp;from supplier </small>
          </div>
        </div>
        <div v-else />
        <!-- Editing options -->
        <div
          v-if="
            ((object.company_id &&
              object.company_id === $store.state.session.scope.company?.toString()) ||
              isSuper) &&
            !isParent &&
            (!(isLivePrice || isGlobalCustom) ||
              ((isLivePriceCustom || isGlobalCustom) && inGlobalScope)) &&
            !modal
          "
          class="mt-auto"
        >
          <btn-bar
            size="sm"
            btnClass="!border-none !text-xl !text-surface-500"
            :selected="[object]"
            :actions="actions"
            :collapse="true"
          />
        </div>
        <!-- Go to category -->
        <div v-else-if="isParent">
          <div
            @click="this.$emit('click')"
            class="flex hover:bg-surface-200 p-2 rounded-full transition justify-center items-center cursor-pointer"
          >
            <icon :icon="['fas', 'arrow-right-long-to-line']" class="text-surface-400" />
          </div>
        </div>
      </div>
      <!-- Autocost supplier stock quantity -->
      <div v-if="isLivePrice && !isLivePriceCustom && !isCraftsman && !isParent">
        <div class="flex flex-col text-surface-500 mt-4">
          <small>{{ livePriceStoreStock }}</small>
          <small v-if="object.live_price_online_stock">Available online</small>
        </div>
        <!-- Autocost supplier name -->
        <div
          v-if="object.live_price_vendor_name && object.live_price_item_url"
          class="flex flex-row items-center gap-2 text-surface-500 underline underline-offset-2 transition select-none"
          :class="
            hasAutoCostSubscription || inGlobalScope ? 'hover:text-surface-900 cursor-pointer' : ''
          "
          @click="openLivePriceLink"
        >
          <small>View on {{ object.live_price_vendor_name }}</small>
          <icon icon="arrow-up-right-from-square" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import AutoCost from '../../../../imports/api/AutoCost'

export default {
  name: 'TraverseListItem',
  components: {},
  emits: ['click'],
  computed: {
    isSuper() {
      const session = this.$store.state.session
      return session.authorizedUser && session.authorizedUser.user_is_super_user
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.user.company_id
      )
    },
    itemLabel() {
      return String(this.name)
        .toLowerCase()
        .replace(/[^a-z]/g, '_')
    },
    isVariationParent() {
      return !!this.object.cost_type_is_variation_parent
    },
    isLivePrice() {
      return AutoCost.isAutoCostObject(this.object)
    },
    isLivePriceCustom() {
      return !this.object.live_price_reference && AutoCost.isAutoCostObject(this.object)
    },
    isGlobalCustom() {
      return !this.object.live_price_reference && AutoCost.isGlobalObject(this.object)
    },
    canSelect() {
      if (!this.canSelectOverride) return false
      const companyOwnsItem =
        this.object.company_id &&
        this.object.company_id === this.$store.state.session.scope.company?.toString()
      const autoCostAccess = !this.isLivePrice || this.hasAutoCostSubscription || this.inGlobalScope

      return (companyOwnsItem || this.modal) && !this.isParent && autoCostAccess
    },
    hasAutoCostSubscription() {
      return AutoCost.hasAutoCostSubscription(this.$store.state.session.company)
    },
    isCraftsman() {
      return AutoCost.isCraftsmanObject(this.object)
    },
    isCraftsmanMaterial() {
      return AutoCost.isCraftsmanMaterialObject(this.object)
    },
    isCraftsmanLabor() {
      return AutoCost.isCraftsmanLaborObject(this.object)
    },
    livePriceStoreStock() {
      const stock = this.object.live_price_store_stock
      if (!stock || stock === '0' || stock === 'Unknown') return 'None in stock'
      return `${stock} in stock`
    },
    name() {
      return (
        this.object.variation_parent_cost_type_name ||
        this.object.cost_type_name ||
        this.object.assembly_name
      )
    },
    description() {
      return this.object.cost_type_desc || this.object.quote_notes || ''
    },
    $() {
      return this.$store.getters.$
    },
    $a() {
      return this.$store.getters.$a
    },
    $b() {
      return this.$store.getters.$b
    },
    dollars() {
      return this.object.cost_type_name || this.object.assembly_name
    },
    cents() {
      return this.object.cost_type_name || this.object.assembly_name
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isParent() {
      return this.object.cost_type_is_parent
    },
    unit() {
      if (!this.object.unit_of_measure_abbr || this.object.unit_of_measure_abbr === 'each') {
        return 'ea'
      }
      return this.object.unit_of_measure_abbr
    },
    mod() {
      if (
        this.$store.state.general.itemSelectorMod &&
        this.$store.state.general.itemSelectorMod.mod_labor_net
      ) {
        return this.$store.state.general.itemSelectorMod
      }
      return this.$store.getters.defaultMod
    },
    defaultMarkup() {
      return this.$store.getters.defaultMarkup
    },
    autoCostOriginalPrice() {
      return this.object?.cost_matrix_materials_cost_net_index || 0.0
    },
    price() {
      const o = this.object
      if ((o.type === 'cost_type' || o.type === 'autocost') && !this.isVariationParent) {
        const markup = c.n(
          typeof o.cost_matrix_markup_net === 'undefined' ||
            o.cost_matrix_markup_net === null ||
            o.cost_matrix_use_company_markup ||
            !o.company_id
            ? this.defaultMarkup
            : o.cost_matrix_markup_net
        )
        const modMat = o.cost_type_is_indexed ? this.mod.mod_materials_net : 1
        const modLab = o.labor_type_is_indexed ? this.mod.mod_labor_net : 1
        const lab =
          o.cost_type_has_labor && !o.cost_type_is_subcontracted
            ? o.cost_type_hours_per_unit * o.labor_type_rate_net_index * modLab
            : 0
        const mat =
          o.cost_type_has_materials || o.cost_type_is_subcontracted
            ? o.cost_matrix_materials_cost_net_index * modMat
            : 0
        const net = lab + mat
        return markup * net
      }
      if (o.type === 'cost_type' && this.isVariationParent) {
        if (!this.object.aoVariationItems || !this.object.aoVariationItems.length) return 0
        const tempObject = this.object
        const sorted = tempObject.aoVariationItems.sort((via, vib) => via.rate - vib.rate)
        return sorted[0].rate
      }
      return o.quote_price_net
    },
    manufacturersImage() {
      if (this.object?.aoImages?.length) {
        return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${this.object.aoImages[0]}`
      }
      return null
    },
    ownImage() {
      const img = c.makeArray(this.object.file_ids || [])
      if (img.length) {
        return c.link(`file/view/${img[0]}`, { size: 500 }, true, _.getStorage('scope'))
      }
      return null
    },
    actions() {
      return [
        {
          action: this.duplicate,
          title: 'Duplicate',
          icon: ['fas', 'copy']
        },
        {
          action: this.delete,
          title: 'Delete',
          icon: 'trash'
        },
        {
          action: this.publish,
          title: 'Publish to showcase',
          icon: 'upload'
        }
      ]
    }
  },

  methods: {
    /**
     * onClick
     * Executes when the list item is clicked
     */
    onClick() {
      if (this.canSelect) {
        this.$emit('click')
      }
    },
    /**
     * Edit item / assembly
     */
    async edit() {
      await this.$store.dispatch('edit', {
        type: this.object.type,
        id: this.object[`${this.object.type}_id`]
      })
      eventBus.$emit('traverse-reload')
      return this
    },
    /**
     * Duplicate item / assembly
     */
    async duplicate() {
      await this.$store.dispatch(`${c.titleCase(this.object.type)}/duplicate`, {
        id: this.object[`${this.object.type}_id`]
      })
      eventBus.$emit('traverse-reload')
      return this
    },
    /**
     * Delete item / assembly
     */
    async delete() {
      await this.$store.dispatch(`${c.titleCase(this.object.type)}/delete`, {
        id: this.object[`${this.object.type}_id`]
      })
      eventBus.$emit('traverse-reload')
      return this
    },
    async publish() {
      await this.$store.dispatch(`${c.titleCase(this.object.type)}/publish`, {
        id: this.object[`${this.object.type}_id`]
      })
      eventBus.$emit('traverse-reload')
      return this
    },
    openLivePriceLink() {
      if (!this.hasAutoCostSubscription && !this.inGlobalScope) return
      const url = `${this.object.live_price_item_url}`
      if (!url) return
      c.openLink(url)
    },
    /**
     * Given a number, changes all digits to x's.
     * Used to obscure the price of AutoCost items without a subscription.
     */
    obscurePrice(price) {
      return price.toString().replace(/[0-9]/g, 'X')
    }
  },

  data() {
    return {
      viewMore: false
    }
  },

  props: {
    modal: {
      required: false,
      default: null
    },
    object: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false
    },
    canSelectOverride: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
