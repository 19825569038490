<template>
  <Progress
    :items="items"
    :loading="loading"
    :displayApproved="true"
    :approverId="companyId"
    :displayApprovalBar="true"
    approverRole="company"
  />
</template>

<script setup>
import { provide, computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import Progress from '@/components/progress/Progress.vue'
import Approval from '@/../imports/api/Approval'
import { getApprovalsByApprover } from '@/apollo-client/requests/approval'
import eventBus from '@/eventBus'

const items = ref([])
const loading = ref(0)

const store = useStore()
const companyId = computed(() => store.state.session.company.company_id)
const project = computed(
  () => Object.values(store.state.Quote.normalized).find((i) => i.type === 'quote') || {}
)
provide('project', project)

const loadProject = async () => {
  loading.value = 1
  const { normalized } = store.state.Quote
  const approvals = await getApprovalsByApprover({
    userId: companyId.value,
    quoteId: project.value?.quote_id
  })

  if (approvals) {
    const costItems = Object.values(normalized).filter((item) => item.type === 'cost_item')
    items.value = Approval.mapApprovalsToItems(approvals, costItems)
  }
  loading.value = 0
}

watch(
  () => project.value,
  (quote, prevQuote) => {
    if (!quote || Object.keys(quote).length === 0) return
    if (prevQuote && quote.quote_id === prevQuote.quote_id) return
    loadProject()
  },
  {
    immediate: true
  }
)

onMounted(() => {
  eventBus.$on('approval-action-processed', () => {
    loadProject()
  })
})

onBeforeUnmount(() => {
  eventBus.$off('approval-action-processed')
})
</script>
