<template>
  <Container>
    <CardSection>
      <template #title> Custom budget code </template>

      <CardList>
        <CardListField>
          <span>Budget code</span>

          <Field
            schema="budget_code:csi_code_code"
            v-model="csi_code_code"
            :validate="{ required: true }"
          />
        </CardListField>
        <CardListField>
          <span>Budget code name</span>
          <Field v-model="csi_code_name" schema="budget_code:csi_code_name" />
        </CardListField>
      </CardList>
    </CardSection>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'

export default {
  name: 'BudgetCode',
  mixins: [ObjectManipulator('csi_code', true), BodyMixin],
  emits: ['saving', 'saved']
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
