<template>
  <div class="ckeditor-container" :class="[email ? 'email' : '', height ? 'hasHeight' : '']">
    <spin-container :loading="loading">
      <textarea style="visibility: hidden" ref="textarea" :name="uid" :id="uid"></textarea>
    </spin-container>
  </div>
</template>

<script>
import BtnMixin from '../../mixins/Button'

import $scriptjs from 'scriptjs'
import { useDeviceStore } from '@/stores/device'

/**
 * Emits:
 *  -ready
 *  -tokens
 */
export default {
  name: 'DocumentEditor',
  setup() {
    const deviceStore = useDeviceStore()

    if (!deviceStore.isMobileBrowser) {
      $scriptjs('../../static/ckeditor/ckeditor.js', () => {
        window.dispatchEvent(new Event('ckeditorAdded'))
        window.CKEDITOR.disableAutoInline = true
      })
    } else {
      window.dispatchEvent(new Event('ckeditorAdded'))
    }
  },
  mixins: [BtnMixin],
  emits: ['input', 'update:value', 'tokens', 'ready', 'blur', 'focus'],
  data() {
    let tokens
    let specialTokens
    let tokensByTemplateType
    if (this.templateType) {
      tokens = {
        client: this.getObjectTokens('client'),
        quote: this.getObjectTokens('quote'),
        invoice: this.getObjectTokens('invoice'),
        company: this.getObjectTokens('company'),
        user: this.getObjectTokens('user'),
        owner: this.getObjectTokens('user', 'owner'),
        creator: this.getObjectTokens('user', 'creator')
      }

      specialTokens = {
        pdf: [
          ['CostCertified Badge', '@badge()'],

          ['PDF - Page Break', '@page_break()'],
          ['PDF - Current Page Number', '@current_page()'],
          ['PDF - Total Number of Pages', '@number_of_pages()']

          // ['✎ Signature Field (Force required)', '@field_signature(true)'],
          // ['✎ Signature Field (Optional only)', '@field_signature(false)'],
          // ['✎ Initial Field (Force required)', '@field_initial(true)'],
          // ['✎ Initial Field (Optional only)', '@field_initial(false)'],
          // ['✎ Name Field (Force required)', '@field_name(true)'],
          // ['✎ Name Field (Optional only)', '@field_name(false)'],
          // ['✎ Date Field (Force required)', '@field_date(true)'],
          // ['✎ Date Field (Optional only)', '@field_date(false)'],
          // ['✎ General Text Field (Force required)', '@field_text(true)'],
          // ['✎ General Text Field (Optional only)', '@field_text(false)'],
          // ['✎ Checkbox Field', '@field_checkbox()'],
        ],
        quote: [
          ['Quote  ▶  Signer audit meta-data (proof of acceptance)', '@signer_meta()'],
          ['Quote  ▶  Accepted terms and conditions', '@terms_and_conditions()'],
          ['Quote  ▶  Line Items List', '@line_items()'],
          ['Quote  ▶  List All Attached Images', '@files()']
        ],
        email: []
      }

      tokensByTemplateType = {
        // Quote PDF
        4: [
          ...specialTokens.pdf,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Quote email
        2: [
          ...specialTokens.email,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Invoice PDF
        5: [
          ...specialTokens.pdf,
          ...tokens.invoice,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Invoice send email
        8: [
          ...specialTokens.email,
          ...tokens.invoice,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // REceipt PDF
        12: [
          ...specialTokens.pdf,
          ...tokens.invoice,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Receipt Email
        11: [
          ...specialTokens.email,
          ...tokens.invoice,
          ...tokens.quote,
          ...specialTokens.quote,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Client / Marketing email
        13: [
          ...specialTokens.email,
          ...tokens.client,
          ...tokens.owner,
          ...tokens.creator,
          ...tokens.company,
          ...tokens.user
        ],

        // Pdf heading
        9: [...specialTokens.pdf, ...tokens.company, ...tokens.user],

        // Pdf footing
        10: [...specialTokens.pdf, ...tokens.company, ...tokens.user]
      }
    }

    const tokenFormatOptions = [
      ['None', ''],
      ['Currency', '@format_currency'],
      ['Number', '@format_number'],
      ['Date', '@format_date'],
      ['Phone Number', '@format_phone'],
      ['Postal (CA)', '@format_postal']
    ]

    return {
      uid: `ckeditor-${_.uniqueId()}`,
      tokenFormatOptions,
      tokensByTemplateType,
      ready: this.$store.state.session.ckeditorAdded,
      fnMount: () => {}
    }
  },
  computed: {
    toolbarGroups() {
      if (this.email) {
        return [
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          { name: 'paragraph', groups: ['list', 'indent', 'align', 'paragraph'] },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] }
        ]
      }
      if (this.groups) return this.groups
      return [
        { name: 'clipboard', groups: ['undo'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'align', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'codemirror', groups: ['mode'] },
        { name: 'editing', groups: ['find'] }
      ]
    },
    removeButtons() {
      if (this.email) {
        return 'JustifyLeft,JustifyRight,JustifyBlock,JustifyCenter,Styles,Format,Token,Table,HorizontalRule,SpecialChar,Strikethrough,Save,NewPage,Preview,Print,RemoveFormat,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,SelectAll,Scayt,Form,Radio,Checkbox,TextField,Textarea,Select,Button,HiddenField,ImageButton,Subscript,Superscript,RemoveFormat,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Flash,Smiley,PageBreak,Iframe,Maximize,About'
      }
      if (this.buttonsToRemove) return this.buttonsToRemove
      return 'Strikethrough,Save,NewPage,Preview,Print,RemoveFormat,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,SelectAll,Scayt,Form,Radio,Checkbox,TextField,Textarea,Select,Button,HiddenField,ImageButton,Subscript,Superscript,RemoveFormat,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Flash,Smiley,PageBreak,Iframe,Maximize,About'
    },
    configLocal() {
      const tokenFormatOptions = this.tokenFormatOptions
      const contentsCss = this.contentsCss
      const config = this.config
      let height = this.height || null
      if (this.email) height = 300
      return _.defaults(config, {
        autoGrow_onStartup: !this.email || !this.height,
        autoGrow_bottomSpace: 40,
        customConfig: '',
        startupFocus: true,
        extraPlugins: 'image2,token,codemirror,stylesheetparser,autogrow',
        removePlugins: this.email || this.height ? 'entities,autogrow' : 'entities',
        toolbarGroups: this.toolbarGroups,
        removeButtons: this.removeButtons,
        entities: false,
        toolbarLocation: this.email ? 'bottom' : 'top',
        foceSimpleAmpersand: true,
        htmlEncodeOutput: false,
        // enterMode: window.CKEDITOR.ENTER_BR,
        baseFloatZIndex: 99999,
        tokenFormatOptions,
        availableTokens: this.availableTokens,
        provided$: $,
        contentsCss,
        height,
        instance: null,
        hasFocus: false,
        hasFocused: false,
        queueValue: this.value
        // $store: this.$store,
      })
    },
    availableTokens() {
      return this.templateType &&
        this.templateType &&
        this.templateType in this.tokensByTemplateType
        ? this.tokensByTemplateType[this.templateType]
        : []
    },
    instance: {
      get() {
        return window.CKEDITOR.instances[this.uid]
      },
      set(value) {
        window.CKEDITOR.instances[this.uid] = value
      }
    },
    contentsCss() {
      return [
        this.email ? '/static/ckeditor/emailContents.css' : '/static/ckeditor/contents.css',
        ...(this.styleTemplateId ? [c.link(`template/raw/${this.styleTemplateId}`)] : [])
      ]
    }
  },
  watch: {
    value(val) {
      this.queueValue = val
      if (!this.hasFocus) {
        this.setValue(this.queueValue)
      }
    }
  },
  methods: {
    focus() {
      if (this.instance) {
        this.instance.focusManager.focus(true)
      }
    },
    insertToken(val) {
      if (this.instance) {
        this.instance.insertHtml(`{{${val}}}`)
      }
    },
    getObjectTokens(type, prefix) {
      const constructor = c.getConstructor(type)
      const fields = constructor.fields
      return Object.keys(fields)
        .filter(
          (f) =>
            !/(^(ao|aa|o))|(_ids?$)|password|company_id|database|_db_|parent_file_id/.test(f) ||
            f === `${type}_id`
        )
        .map((f) => this.mapToken(f, constructor, prefix))
    },
    mapToken(fieldName, constructor, prefix = constructor.type) {
      return [
        `${_.capitalize(prefix)}  ▶  ${c.getFieldTitle(fieldName, constructor)}`,
        `@${prefix ? `${prefix}.` : ''}${fieldName}`,
        prefix // category
      ]
    },
    getValue() {
      return this.instance.getData()
    },
    setValue(val) {
      this.$nextTick(() => {
        if (this.instance && val !== this.instance.getData().trim()) {
          this.queueValue = val
          this.instance.setData(val)
        }
      })
    },
    setCkeditorAdded() {
      this.$store.dispatch('ckeditorAdded')
    },
    startup() {
      this.$nextTick(() => {
        window.CKEDITOR.disableAutoInline = true
        window.CKEDITOR.replace(this.uid, this.configLocal)
        this.instance = window.CKEDITOR.instances[this.uid]

        this.$nextTick(() => {
          this.queueValue = this.value
          this.setValue(this.value || '')
        })

        this.$nextTick(() => {
          this.instance.on('change', () => {
            const html = this.instance.getData()
            if (html !== this.value && this.hasFocused) {
              this.$emit('input', html)
              this.$emit('update:value', html)
              this.queueValue = html
            }
          })

          this.instance.on('instanceReady', () => {
            this.ready = true
            this.endLoading()
            this.$emit('tokens', this.availableTokens)
            this.$nextTick(() => {
              this.setValue(this.value)
              this.$emit('ready')
            })
          })

          this.instance.on('blur', () => {
            this.hasFocus = false
            this.$emit('blur', this.instance)
            setTimeout(() => {
              if (
                c.format(this.getValue(), 'text').trim() !==
                c.format(this.queueValue, 'text').trim()
              ) {
                this.setValue(this.queueValue)
              }
            }, 500)
          })

          this.instance.on('focus', () => {
            this.hasFocus = true
            this.hasFocused = true
            this.$emit('focus', this.instance)
          })
        })
      })
    }
  },
  created() {
    if (!this.$store.state.session.ckeditorAdded) {
      window.addEventListener('ckeditorAdded', this.setCkeditorAdded)
    }
  },
  mounted() {
    this.addLoading()

    if (this.$store.state.session.ckeditorAdded || window.CKEDITOR) {
      this.startup()
    } else {
      window.addEventListener('ckeditorAdded', this.startup)
    }
  },
  beforeUnmount() {
    if (this.instance) {
      this.instance.focusManager.blur(true)
      this.instance.removeAllListeners()
      this.instance.destroy()
    }
    window.removeEventListener('ckeditorAdded', this.fnMount)
    window.removeEventListener('ckeditorAdded', this.setCkeditorAdded)
  },
  props: {
    email: {
      type: Boolean,
      default: false
    },
    buttonsToRemove: {
      type: String
    },
    groups: {
      type: Array
    },
    height: {
      type: Number
    },
    value: {
      required: true
    },
    // CKEDITOR options, to override defaults
    config: {
      type: Object,
      default: () => ({})
    },
    // Inline or classic, should generally be classic
    //  See CKEDITOR type inline vs classic
    types: {
      type: String,
      defualt: 'classic'
    },
    // The template_type_id of the template being edited.
    //  This will help determine which tokens are available.
    templateType: {
      type: Number,
      required: false,
      default: null
    },
    // The id of the template style,
    //  this helps parse the styling
    styleTemplateId: {
      type: Number,
      required: false,
      default: null
    },
    // If this is editing a template that is already formatted,
    // or will not be formatted in future, that will affect
    // the way images are inserted and
    // whether we want to add tokens or not.
    preFormatted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.ckeditor-container {
  height: 100%;
  position: static;
  @apply border border-cool-gray-300 rounded-md;
  .cke {
    height: 100% !important;
    background: transparent;
    border-radius: 0;
    border: 0px;
    padding: 0px;
    overflow: visible;
    position: static;
    .cke_inner {
      display: flex !important;
      justify-content: stretch;
      flex-direction: column;
      height: 100% !important;
      overflow: visible;
      position: static;
      .cke_top {
        position: sticky;
        top: -2em;
        flex-grow: 0;
        flex-shrink: 0;
        z-index: 2;
        padding: 0.5em 0.5em 0 0.5em;
        background: $flame-white;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        .cke_toolbar {
          height: 100%;
          min-height: 35px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          .cke_toolgroup {
            border: 0;
            background: transparent;
            border-radius: 0;
            border-right: 1px solid $cool-gray-500;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            &:last-child {
              border-right: 0px solid transparent;
            }
            .cke_button {
              border: 0;
              background: transparent;
              border-radius: 2px;
              height: 35px;
              min-width: 35px;
              padding: 0 0.1em;
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              &:hover,
              &.cke_button_on {
                background: rgba($primary, 0.2);
                text-decoration: none;
                cursor: pointer;
              }
              &:last-child {
                margin-right: 5px;
              }
            }
          }
          &:last-child {
            .cke_toolgroup {
              border-right: 1px solid transparent;
            }
          }
          .cke_combo {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            .cke_combo_button {
              height: 100%;
              background: transparent;
              margin-bottom: 0;
              &:hover {
                background: rgba($primary, 0.2);
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }
      }
      .cke_contents {
        flex-grow: 100;
        border: 0px;
        border-radius: 0px;
        background: transparent;
        display: flex;
        justify-content: center;
        z-index: 0;
        overflow: visible;
        iframe {
          max-width: 100%;
          border-radius: 8px;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.03);
        }
      }
    }
  }

  &.email {
    .cke {
      border-radius: 4px;
      margin: 1em 0;
      height: 100% !important;
      width: 100%;
      position: relative;
      background: transparent;
      border-radius: 0;
      border: 0px;
      padding: 0px;
      overflow: visible;
      .cke_inner {
        display: flex !important;
        justify-content: stretch;
        flex-direction: column;
        height: 100% !important;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.025);
        border: 1px solid $cool-gray-500;
        margin-right: 1px;
        .cke_bottom {
          position: sticky;
          bottom: 0px;
          top: auto;
          width: 100%;
          padding-top: 0.25em;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          .cke_toolbar {
            height: 100%;
            min-height: 35px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            .cke_toolgroup {
              border: 0;
              background: transparent;
              border-radius: 0;
              border-right: 1px solid $cool-gray-500;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              align-content: center;
              &:last-child {
                border-right: 0px solid transparent;
              }
              .cke_button {
                border: 0;
                background: transparent;
                border-radius: 2px;
                height: 35px;
                min-width: 35px;
                padding: 0 0.1em;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                &:hover,
                &.cke_button_on {
                  background: rgba($primary, 0.2);
                  text-decoration: none;
                  cursor: pointer;
                }
                &:last-child {
                  margin-right: 5px;
                }
              }
            }
            &:last-child {
              .cke_toolgroup {
                border-right: 1px solid transparent;
              }
            }
            .cke_combo {
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-content: center;
              align-items: center;
              .cke_combo_button {
                height: 100%;
                background: transparent;
                margin-bottom: 0;
                &:hover {
                  background: rgba($primary, 0.2);
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .cke_contents {
          border-radius: 4px;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
          min-height: 100px;
          border-radius: 4px;
          margin: 0px;
          margin-bottom: -5px;
          overflow-y: auto !important;
          webkit-overflow-scrolling: touch !important;
          iframe {
            max-width: 100%;
            margin: 0px;
            overflow: visible;
          }
        }
      }
    }
  }

  &:not(.hasHeight) {
    .cke_contents {
      min-height: 100vh;
    }
  }
}

.modal {
  .ckeditor-container {
    .cke {
      .cke_inner {
        .cke_top {
          width: 100%;
          padding: 0.5em 2em;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
