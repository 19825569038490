<template>
  <card-section>
    <card-list>
      <!-- This is for toggling of the user sees the itemized pricing and viewing the items -->
      <card-list-collapse
        v-if="
          ((itemizedEnabled && enableItemizedInvoicing && showItemizedPricing) ||
            forceShowItemizedInvoicing) &&
          !isBundled
        "
        id="expand-itemized-list"
        :value="expanded ? ['expand-itemized-list'] : []"
        class="invoice-details"
      >
        <template #title>
          <span class="flex flex-col w-100">
            <span class="mb-1">
              {{ $t('This invoice includes the following items') }}
            </span>
            <small>
              {{ $t('Expand to view items.') }}
            </small>
          </span>
        </template>
        <ItemizedList
          :normalized="selectedItems"
          :readonly="true"
          :expanded="expanded"
          v-if="selectedItems"
        />
      </card-list-collapse>

      <card-list-field class="under">
        <span>
          {{ $t('Subtotal') }}
        </span>

        <Field :disabled="1" format="currency" v-model="InvoiceNet" />
      </card-list-field>

      <template v-if="oTaxSums && Object.values(oTaxSums).length > 1">
        <CardListField
          class="under under-under"
          v-for="(tax, index) in Object.values(oTaxSums)"
          :key="tax.key"
          :class="{
            'under-first': index === 0,
            'under-last': index === Object.values(oTaxSums).length - 1
          }"
        >
          <strong>
            {{ tax.name }}

            <small>
              {{ $f.percentage(tax.pcnt) }} tax on {{ friendlyNameTaxOn(tax.on) }} derived from
              {{ friendlyNameTaxType(tax.type) }}
            </small>
          </strong>

          <strong style="font-weight: bold; white-space: nowrap" class="number input-container">{{
            $f.currency(tax.sum)
          }}</strong>
        </CardListField>
      </template>
      <card-list-field v-if="invoice_service_fee_net" class="under">
        <span> Surcharge </span>

        <Field :disabled="1" format="currency" v-model="invoice_service_fee_net" />
      </card-list-field>

      <card-list-field class="under under-last">
        <span>
          {{ $t('Tax') }}
        </span>

        <span style="white-space: nowrap"> {{ tax_name }} • {{ $f.percentage(taxPerc) }} </span>

        <Field :disabled="1" format="currency" v-model="invoice_tax" />
      </card-list-field>

      <card-list-field>
        <span> Total </span>
        <Field :disabled="1" format="currency" v-model="total" />
      </card-list-field>
    </card-list>
  </card-section>
</template>

<script>
import ItemizedList from '@/components/invoice/itemized/ItemizedList.vue'
import ObjectDistinct from '../../mixins/ObjectDistinct'
import TranslationMixin from './languages/TranslationMixin'
import ItemizedMixin from '../itemized/ItemizedMixin'

export default {
  name: 'InvoicePresentationTotals',
  components: {
    ItemizedList
  },
  mixins: [ObjectDistinct('invoice', true), TranslationMixin, ItemizedMixin],
  data() {
    return {
      showDetails: false
    }
  },
  props: {
    selectedItems: {
      type: Object
    },
    forceShowItemizedInvoicing: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    isBundled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    total() {
      return this.invoice_gross + this.invoice_service_fee_net
    },
    showBreakdownToggle() {
      return this.$store.getters.isCompanyUser
    },
    taxPerc() {
      return c.divide(this.invoice_tax, this.invoice_net)
    },
    serviceFee() {
      return this.invoice_service_fee_net
    },
    InvoiceNet: {
      get() {
        return this.invoice_net
      },
      set(v) {
        this.invoice_net = v
      }
    }
  },
  methods: {
    friendlyNameTaxOn(taxOn) {
      if (taxOn === 'all') return 'prices'
      if (taxOn === 'cost') return 'costs'
      if (taxOn === 'profit') return 'profits'

      return taxOn
    },
    friendlyNameTaxType(taxType) {
      if (taxType === 'ihlt') return 'labor'
      if (taxType === 'mt') return 'materials'
      if (taxType === 'slt') return 'subcontracting'

      return 'general'
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss"></style>
