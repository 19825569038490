<template>
  <spin-container :loading="loadingRatingComponent">
    <template v-if="ratingId">
      <container>
        <card-section>
          <card-list>
            <card class="rating_card">
              <template #title>
                <h2>{{ $t('Rate your experience') }}</h2>
              </template>
              <span class="rating_cta"
                >{{ $t('How would you rate your recent experience with') }}
                <strong>{{ ratingTitle }}</strong
                >?</span
              >

              <img
                v-if="companyObject && companyObject.company_logo_file_id"
                class="ratingImage"
                :src="getCompanyLogo"
              />
              <img
                v-if="companyObject && !companyObject.company_logo_file_id"
                class="ratingImage-Place-Holder"
                :src="getCompanyLogo"
              />
              <img
                v-if="clientObject && clientObject.company_logo_file_id"
                :src="getClientLogo"
                class="ratingImage"
              />
              <img
                v-if="clientObject && !clientObject.company_logo_file_id"
                :src="getClientLogo"
                class="ratingImage-Place-Holder"
              />

              <Rating
                ref="ElRate"
                class="ratingStars"
                style=""
                :disabled="!showRatingBox"
                v-model="ratingStarsComputed"
                :textColor="'inherit'"
                :max="5"
                disabledVoidIconClass="el-icon-star-off"
              />

              <span class="rating_description">{{
                ratingTexts[Math.ceil(ratingStarsComputed) - 1]
              }}</span>
            </card>
            <card-list-field>
              <span>{{ $t('Comments') }}</span>

              <field
                :disabled="!showRatingBox"
                type="textarea"
                class="ratingTextArea"
                v-model.lazy="ratingCommentComputed"
              />
            </card-list-field>
          </card-list>
        </card-section>

        <btn
          :disabled="!showRatingBox"
          class="info review_submit"
          @click="
            () => {
              saveRating()
            }
          "
        >
          <p v-if="showRatingBox">{{ $t('Submit review') }}</p>
          <p v-if="!showRatingBox">{{ $t('Review submitted') }}</p>
        </btn>
      </container>
    </template>
  </spin-container>
</template>

<script>
import Rating from 'primevue/rating'
import RatingMixin from '@/components/mixins/RatingMixin'
import PageMixin from '@/components/mixins/Page'

export default {
  components: {
    Rating
  },
  mixins: [PageMixin, RatingMixin],
  name: 'RatingRequest'
}
</script>
<style lang="scss" rel="stylesheet/scss">
.rating_card {
  border-radius: unset !important;
}
.completeButton {
  background-color: #ffac00;
}
.el-rate__icon {
  font-size: 3vw;
}
.ratingStars {
  align-self: center;
  text-align: center;
  padding-bottom: 5vw;
}

@media (max-width: 576px) {
  .el-rate__icon {
    font-size: 8vw;
  }
  .ratingStars {
    align-self: center;
    text-align: center;
    padding-bottom: 5vw;
  }
}
.el-rate__text {
  color: #000000;
  font-size: 24px !important;
}
.el-icon-star-on {
  color: #ffac00 !important;
}
.ratingTextArea:enabled {
  background-color: #ffffff;
}
.ratingImage {
  align-self: center;
  image-orientation: from-image;
  max-width: 25em;
  max-height: 25em;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.ratingImage-Place-Holder {
  align-self: center;
  image-orientation: from-image;
  max-width: 10rem;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.ratingTextArea:disabled,
.ratingTextArea[disabled] {
  background: #dee2e6;
}
.rating_description {
  align-self: center;
}
.review_submit {
  margin-top: -1em;
}
</style>
