<template>
  <Selector
    :placeholder="placeholder"
    attribute="Label"
    :search="searchAddress"
    :required="required"
    :error="error"
    :disabled="disabled"
    @select="onSelect"
    v-model="localValue"
  >
  </Selector>
</template>

<script>
import Selector from './Selector.vue'

export default {
  name: 'AddressField',
  emits: ['input', 'select'],
  computed: {
    required() {
      return this.validate && this.validate.required
    },
    valid() {
      if (!this.required && !this.notEmpty) return true
      return false
    }
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
    }
  },
  data() {
    return {
      localValue: ''
    }
  },
  props: {
    placeholder: {
      default: 'Start typing an address...'
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validate: {
      default: null
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.localValue = this.value
  },
  methods: {
    onSelect(selected) {
      this.$emit('select', selected)
      if (!selected.AddressNumber && !selected.Street) return
      this.localValue = `${selected.AddressNumber ? `${selected.AddressNumber} ` : ''}${selected.Street}`
    },
    searchAddress(search) {
      return this.$store.dispatch('ajax', {
        path: 'search/address',
        data: {
          search
        }
      })
    }
  },
  components: {
    Selector
  }
}
</script>
