import { computed, getCurrentInstance, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

export default {
  useDataTransform(args) {
    const { propSheets } = args

    const $store = useStore()
    const $this = getCurrentInstance().proxy

    const dataSheets = ref([])

    const cellData = ref({})

    const cellDefault = {
      eq: '',
      raw: '',
      fmt: ''
    }

    const rowsMap = ref([])

    const columnDependencies = ref({})

    const getColField = (col, sheet) => getColDef(col, sheet).field || null

    const groupBoundaries = ref({})
    const groupMap = ref({})

    const rowCount = computed(() => rowsMap.value.length)
    const colCount = computed(
      () =>
        dataSheets.value?.reduce((acc, shh) => Math.max(acc, shh?.columns?.length ?? 1), 0) ?? 50
    )

    const fetchedNames = ref({})

    const cellChangedHandler = (payload) => {
      const [col, row] = payload?.currentCell ?? {}
      const colDef = getColDef(col, getRowSheet(row))
      if (!colDef.onChange) return
      colDef.onChange(payload)
    }
    onMounted(() => {
      $this.$on('cellChanged', cellChangedHandler)
    })
    onBeforeUnmount(() => {
      $this.$off('cellChanged', cellChangedHandler)
    })

    const refIdToColRow = computed(() => {
      const refIds = {}

      for (let row = 0; row < rowsMap.value.length; row += 1) {
        for (let col = 0; col < rowsMap.value[row].columns.length; col += 1) {
          refIds[rowsMap.value[row].columns[col]] = [col, row]
        }
      }

      return refIds
    })

    const getCellData = (col, row, cd = cellData.value, rm = rowsMap.value) => {
      const refId = getRefIdFromIndexes(col, row, rm)
      return getCellDataFromRefId(refId, cd)
    }

    const sheetColFields = computed(() =>
      dataSheets.value.map((sh) =>
        (sh.columns ?? []).reduce(
          (acc, colDef, index) => ({
            ...acc,
            [colDef.field || index]: index
          }),
          {}
        )
      )
    )

    const getRowFields = (
      row,
      fields,
      cd = cellData.value,
      rm = rowsMap.value,
      ds = dataSheets.value
    ) => {
      const sheetIndex = getRowSheet(row, rm)

      const data = {}

      const colFields = sheetColFields.value[sheetIndex]
      const rmRow = rm[row]
      const origRow = ds[sheetIndex].rows[rm[row].pointer]

      for (const field of fields) {
        if (field && field in colFields) data[field] = cd[rmRow.columns[colFields[field]]].raw
        else if (field && field in origRow) data[field] = origRow[field]
        else if (field && field in (origRow?.data ?? {})) data[field] = origRow.data[field]
        else data[field] = null
      }

      return data
    }

    const getRowData = (row, data = cellData.value, rm = rowsMap.value, ds = dataSheets.value) => {
      const rmRow = rm[row]
      if (!rmRow) return {}

      if (isArrayRows.value) {
        // For array rows, return an array of raw data
        const columns = rmRow.columns
        const result = new Array(columns.length)
        for (let i = 0; i < columns.length; i++) {
          result[i] = data[columns[i]]?.raw || null
        }
        return result
      }

      // Handle object-based rows
      const sheet = getRowSheet(row)
      const rmRowOriginalRow = rmRow.pointer
      const dsSheet = ds[sheet]
      const dsSheetRows = dsSheet?.rows || {}
      const dsRowData = dsSheetRows[rmRowOriginalRow] || {}

      // Merge basics and rowData
      const basics = {
        ...dsRowData,
        ...rmRow,
        childrenIds: getChildren(rmRow.id, sheet)
      }

      const colDefs = dsSheet?.columns || []
      const columns = rmRow.columns
      const rowData = {}

      const len = Math.min(columns.length, colDefs.length)
      for (let index = 0; index < len; index++) {
        const refId = columns[index]
        const colDef = colDefs[index]

        if (!colDef) continue

        const field = colDef.field ?? index
        rowData[field] = data[refId]?.raw || null
      }

      return { ...basics, ...rowData }
    }

    const addNewRow = (
      {
        dataSheets: ds = dataSheets.value,
        rowsMap: rm = rowsMap.value,
        cellData: cd = cellData.value
      },
      {
        rowIndex,
        sheetIndex,
        pointer,
        id,
        rowData = {},
        rowEquations = {},
        parentId = null,
        childPosition = null,
        groupKey = null,
        formatting = {},
        calculateCells = false,
        rebuildCollapseGroups = false,
        addToParent: addRowToParent = true
      }
    ) => {
      const rowDetails = {
        sheet: sheetIndex,
        sheetIndex,

        pointer,

        columns: [],

        pseudoRow: pointer === false,

        formatting,
        group: groupKey,
        id: id || getRowId(pointer, sheetIndex, rm),
        parentId
      }

      // Simulate original row if it doesn't exist
      if (!ds[sheetIndex].rows[pointer]) {
        ;({ dataSheets: ds } = addSourceRows(
          { dataSheets: ds },
          {
            pointer,
            data: rowData,
            equations: rowEquations,
            sheetIndex,
            id
          }
        ))
      }

      rm.splice(rowIndex, 0, rowDetails)

      // Make sure each row has column data, even if it's empty values
      ;({
        dataSheets: ds,
        rowsMap: rm,
        cellData: cd
      } = addRowData(
        {
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        },
        {
          pointer,
          rowIndex,
          sheetIndex,
          data: rowData,
          equations: rowEquations
        }
      ))

      if (ds[sheetIndex].collapseGroups?.groups && addRowToParent) {
        ;({ dataSheets: ds, rowsMap: rm } = addToParent(
          { dataSheets: ds, rowsMap: rm },
          {
            rows: [rm[rowIndex]],
            parentId: parentId ?? ds[sheetIndex].collapseGroups.rootId,
            position: childPosition,
            rebuild: rebuildCollapseGroups
          }
        ))
      }

      // After data added, now calculate computed row values
      if (calculateCells) {
        ;({
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        } = initiateComputedRowValues({ dataSheets: ds, rowsMap: rm, cellData: cd }, { rowIndex }))
      }

      return {
        dataSheets: ds,
        rowsMap: rm,
        cellData: cd
      }
    }

    const addNewCell = (
      col,
      row,
      refId = null,
      value = '',
      equation = value,
      data = cellData.value,
      rm = rowsMap.value
    ) => {
      if (!rm[row]) throw Error('Row must exist first')

      const sheet = getRowSheet(row, rm)
      refId = refId || getRefId(col, rm[row].pointer, sheet)

      const forceUpdate = getForceUpdate(col, sheet)
      const formatting = getColFormatting(col, sheet)
      const format = formatting.format
      const deformat = formatting.deformat || format

      let eq = `${equation === null ? '' : equation}`.replace(/^=/, '')

      const recdNull = (equation === null && value === null) || (equation === '' && value === '')
      const deformatted =
        recdNull && !deformat ? '' : deformatCellValue(col, row, eq || value, deformat, data, rm)
      const formatted =
        recdNull && !format
          ? ''
          : formatCellValue(refId, col, row, deformatted || value, format, null, data, rm)

      eq = eq || `${value}`

      let diff = true
      const isNumeric = getCellType(col, row) === 'number'
      if (
        !forceUpdate &&
        data[refId] &&
        ((isNumeric && c.eq(deformatted, data[refId].raw)) ||
          (!isNumeric && deformatted === data[refId].raw)) &&
        eq === data[refId].eq
      ) {
        diff = false
      }

      data[refId] = {
        ...cellDefault,
        raw: deformatted,
        setValue: value,
        eq,
        fmt: formatted,
        searchIndex:
          `${deformatted || ''} ${value === null ? '' : value} ${formatted}`.toLowerCase(),
        refId,
        // original positioning:
        row,
        col,
        sheet
      }

      // map rows to original row order
      rm[row].columns[col] = refId

      return {
        refId,
        data,
        rowsMap: rm,
        diff
      }
    }

    const getRowSheet = (row, rm = rowsMap.value) => (rm[row] && rm[row].sheet) || 0

    const getCellType = (col, row = null, sheet = null, rm = rowsMap.value) => {
      if (!(row in rm)) return 'text'
      const useSheet = sheet === null ? rm[row].sheet : sheet
      const colDef = getColDef(col, useSheet)
      const rowDef = row !== null && rm[row]

      const pseudoRow = rowDef && rowDef.pseudoRow && rowDef.group in groupMap.value

      if (pseudoRow && groupMap.value[rowDef.group].totalsRow === row) return 'group_total'

      if (pseudoRow && groupMap.value[rowDef.group].headerRow === row) return 'group_header'

      if (!colDef) return 'text'

      if (colDef.action) return 'action'

      if (colDef.progress) return 'progress'

      if (colDef.attachments) return 'attachments'

      if (colDef.checkbox) return 'checkbox'

      if (colDef.enum && Array.isArray(colDef.enum)) return 'enum'

      if (colDef.choose && Object.keys(colDef.choose).length) return 'choose'

      if (
        (colDef.field && c.isNumericField(colDef.field)) ||
        (colDef.formatting &&
          /imperial|number|int|float|currency|\$/.test(colDef.formatting.format || ''))
      )
        return 'number'

      return 'text'
    }

    const getOriginalRow = (row) => {
      const orig = rowsMap.value && rowsMap.value[row] && rowsMap.value[row].rowIndex
      return orig === null ? null : orig
    }

    const isArrayRows = computed(() => dataSheets.value[0]?.rows?.[0]?.length ?? false)

    const triggerCellValueChanges = (events) => {
      if (!events.length) return
      const mapped = events.map((event) => {
        const { current, previous } = event

        const { col, row } = event
        const isNumeric = getCellType(col, row) === 'number'

        return {
          currentCell: [col, row],
          id: getRowId(row),
          field: getColField(col, getRowSheet(row)),
          reference: event.refId,
          currentRow: row,
          currentColumn: col,
          row: getOriginalRow(row),
          column: col,
          deformatted: current.raw,
          formatted: current.fmt,
          text: current.eq,
          equation:
            isNumeric && (c.isEquation(current.eq) || /[a-zA-Z]/.test(current.eq))
              ? current.eq
              : null,
          current,
          previous,
          explicit: event.explicit
        }
      })

      if (mapped.length) {
        $this.$emit('changes', mapped)
        mapped.forEach((change) => $this.$emit('cellChanged', change))
      }
    }

    const triggerComputeDependants = (col, row) => {
      const field = getColField(col, getRowSheet(row))
      return triggerComputeDependantsByField(field, row)
    }

    const triggerComputeDependantsByField = (f, r) => {
      const sheetIndex = getRowSheet(r)
      const list = Array.isArray(f) ? f : [[f, r]]

      // Use direct references to avoid spreading objects unnecessarily
      let cd = cellData.value
      let rm = rowsMap.value
      let ds = dataSheets.value

      const changeList = []

      for (let j = 0; j < list.length; j++) {
        const [field, row] = list[j]
        const deps = columnDependencies.value[sheetIndex]?.[field] || []

        for (let i = 0; i < deps.length; i++) {
          const depCol = deps[i]
          const result = calculateComputedCell(depCol, row, cd, rm, ds)

          // Update references directly
          rm = result.rowsMap
          cd = result.cellData
          ds = result.dataSheets

          if (result.changes) {
            changeList.push(...result.changes)
          }
        }
      }

      // Update reactive values only once
      cellData.value = cd
      rowsMap.value = rm

      // Flatten the change list and emit redraw event
      const listToRedraw = changeList.map((change) => [change.col, change.row])
      $this.$emit('RedrawCells', listToRedraw)
    }

    const setCellValues = async (
      values,
      doEmit = true,
      triggerComputed = true,
      triggerRedraw = true
    ) => {
      if (!values.length) return { changes: [] }

      let data = { ...cellData.value }
      let rm = [...rowsMap.value]

      const cols = []
      let changes = []
      let sheetIndex = null
      let rowIds = []
      const computeds = []

      for (let i = 0; i < values.length; i += 1) {
        const [col, row, text, equation = text] = values[i]
        const previous = getCellData(col, row, data, rm)
        cols.push(col)
        const rowSheet = getRowSheet(row, rm)

        // only allow setting one sheet at a time
        if (sheetIndex !== rowSheet) {
          if (sheetIndex !== null) {
            continue
          }
          sheetIndex = rowSheet
        }

        let refId = getRefId(col, rm[row].pointer, rowSheet, dataSheets.value)
        const {
          refId: assignedRefId,
          data: newData,
          rowsMap: newRowsMap,
          diff
        } = addNewCell(col, row, refId, text, equation, data, rm)

        if (!diff) continue

        data = newData
        rm = newRowsMap
        refId = assignedRefId

        rowIds.push(rm[row] && rm[row].id)

        computeds.push([col, row])

        changes = [
          ...changes,
          {
            refId,
            col,
            row,
            previous,
            current: data[refId],
            explicit: true
          }
        ]
      }

      if (changes.length) {
        cellData.value = data
        rowsMap.value = rm

        if (triggerRedraw)
          Object.values(changes).forEach((ch) => $this.$emit('RedrawCell', [ch.col, ch.row]))

        if (triggerComputed)
          setTimeout(() => computeds.forEach((cc) => triggerComputeDependants(...cc)))

        const sheet = getSheet(sheetIndex)

        const sortingColumnTouched = _.intersection(
          sheet.sorting.map((s) => s[0]),
          cols
        )
        const groupingColumnTouched = _.intersection(sheet.grouping, cols)
        const fullSort = sortingColumnTouched || groupingColumnTouched

        if (
          triggerComputed &&
          ((sheet.sorting.length && fullSort) || (sheet.grouping && sheet.grouping.length))
        ) {
          await c.throttle(
            () => {
              if (fullSort) {
                // Is a grouping column
                parseCellData()
              } else {
                calculateTotals(null, null, sheetIndex)
              }
            },
            { delay: 50 }
          )
        }

        if (doEmit) setTimeout(() => triggerCellValueChanges(changes))
      }

      return {
        changes
      }
    }

    const initiateComputedRowValues = (
      {
        dataSheets: ds = dataSheets.value,
        rowsMap: rm = rowsMap.value,
        cellData: cd = cellData.value
      },
      { rowIndex }
    ) => {
      const sheetIndex = getRowSheet(rowIndex, rm)
      const computedCols = sheetColsWithComputed.value[sheetIndex]?.computedColumns ?? []

      for (let j = 0; j < computedCols.length; j += 1) {
        ;({ cellData: cd, rowsMap: rm } = calculateComputedCell(
          computedCols[j],
          rowIndex,
          cd,
          rm,
          ds
        ))
      }

      return {
        dataSheets: ds,
        rowsMap: rm,
        cellData: cd
      }
    }

    const initiateComputedCellValues = (from = 0, toProvided = null) => {
      let to = toProvided ?? rowsMap.value.length

      let rm = [...rowsMap.value]
      let cd = { ...cellData.value }
      let ds = [...dataSheets.value]

      for (let rowIndex = from; rowIndex < to; rowIndex += 1) {
        ;({ cellData: cd, rowsMap: rm } = initiateComputedRowValues(
          { cellData: cd, rowsMap: rm, dataSheets: ds },
          { rowIndex }
        ))
      }

      cellData.value = cd
      rowsMap.value = rm
      // dataSheets.value = ds // no changes
    }

    const calculateComputedCell = (
      col,
      row,
      data = cellData.value,
      rm = rowsMap.value,
      ds = dataSheets.value
    ) => {
      const sheetIndex = getRowSheet(row)
      const colDef = getColDef(col, sheetIndex)
      const hasComputedFormat = typeof colDef.formatting?.format === 'function'

      if (!colDef.computedValue && !hasComputedFormat) {
        return {
          cellData: data,
          rowsMap: rm,
          changes: []
        }
      }

      const affectedRefId = rm[row].columns[col]
      const colField = getColField(col, sheetIndex)
      const deps = columnDependencies.value[sheetIndex]?.[colField] || null
      // // if we have deps, get them speficially to save time, otherwise do regular getRowData
      //
      // //
      let rowData = (deps && getRowFields(row, deps, data, rm, ds)) || getRowData(row, data, rm, ds)
      const newComputedValue = colDef.computedValue?.(rowData) ?? data[affectedRefId].raw
      const oldValue = data[affectedRefId].raw

      const oldFormatted = data[affectedRefId].fmt
      const newFormatted =
        hasComputedFormat && colDef.formatting.format(newComputedValue, data, rowData)

      if (
        oldValue === newComputedValue &&
        c.eq(oldValue, newComputedValue) &&
        (!hasComputedFormat || oldFormatted === newFormatted)
      ) {
        return {
          cellData: data,
          rowsMap: rm,
          changes: []
        }
      }

      ;({ data, rowsMap: rm } = addNewCell(
        col,
        row,
        affectedRefId,
        newComputedValue,
        newComputedValue,
        data,
        rm
      ))

      let changes = [
        {
          field: colField,
          col,
          row,
          previous: oldValue,
          current: newComputedValue,
          explicit: false
        }
      ]

      return {
        cellData: data,
        rowsMap: rm,
        dataSheets: ds,
        changes
      }
    }

    const affectComputedChangesByField = (field, row, recdData, recdRowsMap) => {
      let data = { ...recdData }
      let rm = [...recdRowsMap]
      const sheetIndex = getRowSheet(row)
      const computedCols = sheetColsWithComputed.value[sheetIndex]?.computedColumns ?? []
      if (!computedCols.length) {
        return { data, rowsMap: rm, changes: [] }
      }

      let changes = []

      const deps = columnDependencies.value[sheetIndex][field] || []

      for (let i = 0; i < deps.length; i += 1) {
        const depCol = deps[i]
        const {
          rowsMap: crm,
          cellData: cdata,
          changes: cchanges
        } = calculateComputedCell(depCol, row, data, rm)
        rm = crm
        data = cdata
        changes = [...changes, ...cchanges]
      }

      return {
        data,
        rowsMap: rm,
        changes
      }
    }

    const affectComputedChanges = (col, row, recdData, recdRowsMap) => {
      const sheetIndex = getRowSheet(row)
      const field = typeof col === 'string' ? col : getColDef(col, sheetIndex).field
      return affectComputedChangesByField(field, row, recdData, recdRowsMap)
    }

    const getColFormatting = (col, sheet) => getColDef(col, sheet).formatting || {}

    const getRowFormatting = (row) => (rowsMap.value[row] && rowsMap.value[row].formatting) || {}

    const getForceUpdate = (col, sheet) => getColDef(col, sheet).forceUpdate || false

    const setCellValue = async (col, row, text, doEmit = true) => {
      const cell = getCellData(col, row)
      let data = { ...cellData.value }
      let rm = rowsMap.value

      let refId = cell.refId
      const sheetIndex = getRowSheet(row)

      const previous = data[refId] || {}

      // Add cell or set value (does both)
      const {
        refId: assignedRefId,
        data: newData,
        rowsMap: newRowsMap,
        diff
      } = addNewCell(col, row, refId, text, text, data, rm)
      data = newData
      rm = newRowsMap
      refId = assignedRefId

      // for no change:
      if (!diff) return []

      const {
        data: computedData,
        rowsMap: computedRowsMap,
        changes: computedChanges
      } = affectComputedChanges(col, row, data, rm)
      data = computedData
      rm = computedRowsMap

      cellData.value = data
      rowsMap.value = rm

      // If this is a sorting cell value, or a grouped value, it must be resorted
      await c.throttle(
        () => {
          const sheet = getSheet(sheetIndex)

          if (sheet.grouping.includes(col)) {
            parseCellData()
          } else if (sheet.sorting.map((s) => s[0]).includes(col)) {
            sortInsideGroup(col, row, data[refId], previous, true)
          } else {
            calculateTotals(rm[row].group, col, sheetIndex)
          }
        },
        { delay: 50 }
      )

      const changes = [
        {
          col,
          row,
          previous,
          current: data[refId],
          refId,
          explicit: true,
          text
        },
        ...computedChanges
      ]

      Object.values(changes).forEach((ch) => $this.$emit('RedrawCell', [ch.col, ch.row]))

      if (doEmit) {
        triggerCellValueChanges(changes)
      }

      return changes
    }

    const getRefIdFromIndexes = (col, row, rm = rowsMap.value) =>
      (rm[row] && rm[row].columns && rm[row].columns[col]) || null

    const getCellDataFromRefId = (id, cd = cellData.value) => (id && cd[id]) || { ...cellDefault }

    const sortAz = (column, sheet) => {
      dataSheets.value[sheet].sorting = [[column, 'asc']]
      parseCellData()
    }

    const sortZa = (column, sheet) => {
      dataSheets.value[sheet].sorting = [[column, 'desc']]
      parseCellData()
    }

    const getColDef = (col, sheet) => {
      return (
        (dataSheets.value[sheet] &&
          dataSheets.value[sheet].columns &&
          dataSheets.value[sheet].columns[col]) ||
        {}
      )
    }

    const getRefId = (originalCol, pointer, sheetIndex, osheets = propSheets.value) =>
      `${pointer}:${sheetIndex}-${getColPointer(originalCol, sheetIndex, osheets)}:${originalCol}`

    const getColPointer = (originalCol, sheetIndex, osheets = propSheets.value) => {
      if (typeof originalCol === 'string') {
        return originalCol
      }

      return osheets[sheetIndex]?.columns?.[originalCol]?.field ?? originalCol
    }

    const getRowId = (row, sheetIndex = getRowSheet(row), rm = rowsMap.value) => {
      if (row === null) return null

      // If the row hasn't been added yet then this is the first time, and the row is original
      const pointer = row in rm ? rm[row].pointer : row

      if (pointer === null) throw new Error('Cannot have null original row')

      const id = rowsMap.value?.[row]?.id || propSheets.value[sheetIndex].rows[pointer]?.id

      return id || pointer
    }

    const fetchNames = async (dataModel = {}) => {
      const { dataSheets: sh = dataSheets.value } = dataModel

      const typeIds = {}
      for (let s = 0; s < sh.length; s += 1) {
        const sheet = sh[s]
        const sheetColumns = sheet.columns || []
        const rows = sheet.rows
        for (let i = 0; i < sheetColumns.length; i += 1) {
          const col = sheetColumns[i]
          const schema = col.schema || (col.choose && col.choose.schema)
          const staticSet = col.choose && col.choose.staticSet

          const type = schema && `${schema}`.includes(':') && getSchemaObjectType(schema)

          if (type) {
            // add blanks to prime the data structure
            await addFetchedNames(type, {})
            // add actuals if rows are set
            const field = getColPointer(i, s)
            typeIds[type] = c.cleanArray([
              ...(typeIds[type] || []),
              ...Object.values(rows).map((row) => row[field] ?? row.data?.[field])
            ])
          }

          if (staticSet) {
            const reduced = staticSet.reduce(
              (acc, option) => ({
                ...acc,
                [option.value]: option.text,
                ...(!option.value
                  ? {
                      null: option.text,
                      '': option.text
                    }
                  : {})
              }),
              {}
            )

            await addFetchedNames(`${s}:${i}`, reduced)
          }
        }
      }

      await Promise.all(
        Object.keys(typeIds).map(async (type) => {
          if (!typeIds[type].length) return
          return fetchTypeNames(type, typeIds[type])
        })
      )
    }

    const fetchTypeNames = async (type, ids) => {
      namesTried = [...namesTried, ...ids.map((id) => `${type}:${id}`)]
      const retrieved = await $store.dispatch(`${c.titleCase(type)}/getNames`, {
        ids
      })

      await addFetchedNames(type, retrieved)
    }

    /**
     * Offload the name fetching so it doesn't slow things down
     * but if it isn't found, we can still go and fetch it
     * @type {{}}
     */
    let fetchQueue = {}
    let rerunQueue = {}
    const queueNameFetch = (type, ids, refId, value) => {
      if (!fetchQueue[type]) fetchQueue[type] = []

      fetchQueue[type] = _.cleanArray([...fetchQueue[type], ...ids])
      rerunQueue[refId] = value

      c.throttle(
        async () => {
          // flush queue
          const fq = { ...fetchQueue }
          fetchQueue = {}
          const rr = { ...rerunQueue }
          rerunQueue = {}

          await Promise.all(Object.keys(fq).map((tt) => fetchTypeNames(tt, fq[tt])))

          let cd = { ...cellData.value }
          let rm = [...rowsMap.value]
          // Rerun sett]ing value
          Object.keys(rr).forEach((refId) => {
            const value = rr[refId]
            if (!refId) return

            // If value has changed again by now, then ignore
            // we are only updating the formatted value, and so it needs
            // to match the value we already recorded
            const stringVal = String(value)
            if (String(cd[refId].setValue) !== stringVal) return

            if (!Array.isArray(refIdToColRow.value[refId])) return
            const [col, row] = refIdToColRow.value[refId]
            ;({ rowsMap: rm, data: cd } = addNewCell(col, row, refId, stringVal, stringVal, cd, rm))
          })
          cellData.value = cd
          rowsMap.value = rm
          $this.$emit(
            'RedrawCells',
            Object.keys(rr).map((refId) => refIdToColRow.value[refId] ?? [])
          )
        },
        { delay: 1000 }
      )
    }

    const addFetchedNames = (type, idNames = {}) => {
      const names = fetchedNames.value

      // Ensure the 'type' exists in 'names'
      if (!names[type]) {
        names[type] = { ids: [], names: {} }
      }

      // Early return if 'idNames' is empty
      const idKeys = Object.keys(idNames)
      if (idKeys.length === 0) return

      const typeData = names[type]

      // Update 'ids' array in place
      typeData.ids.push(...idKeys)

      // Update 'names' object in place
      for (let i = 0; i < idKeys.length; i++) {
        const id = idKeys[i]
        typeData.names[id] = idNames[id] || id
      }

      fetchedNames.value = names
    }

    const addFetchedName = (type, idString, formatted) =>
      addFetchedNames(type, { [idString]: formatted })

    const getCellObjectType = (col, sheet) => {
      const colDef = getColDef(col, sheet)
      if (!colDef.choose) return null

      if (colDef.choose.staticSet) return `${sheet}:${col}`

      return getSchemaObjectType(colDef.choose.schema)
    }

    const getSchemaObjectType = (schema) => {
      const [objectType, fieldName] = schema.split(':')
      let fieldSchema = null
      if (objectType && fieldName) {
        const constructor = c.getConstructor(objectType)
        if (constructor && constructor.fields && constructor.fields[fieldName]) {
          fieldSchema = constructor.fields[fieldName]
        }
      }

      return fieldSchema && fieldSchema.mapTo ? fieldSchema.mapTo : objectType
    }

    const deformatCellValue = (
      col,
      row,
      value,
      recdFormat = null,
      dataRcd = cellData.value,
      rowsMapRcd = rowsMap.value
    ) => {
      const rm = rowsMapRcd // Object.assign([], rowsMap.value, rowsMapRcd)
      const data = dataRcd /* {
        ...cellData.value,
        ...dataRcd
      } */

      const sheet = rm[row].sheet
      const cellType = getCellType(col, row, sheet, rm)
      const colDef = getColDef(col, sheet)
      const refId = rm[row].columns[col]
      const cd = data[refId]

      const defFormat = recdFormat || (colDef.formatting && colDef.formatting.deformat)
      if (typeof defFormat === 'function') {
        const rowData = getRowData(row, data, rm)
        return defFormat(value, cd, rowData)
      }

      if (cellType === 'progress') {
        return `${value}`.split(',').map((i) => +i)
      }

      if (cellType === 'choose') {
        // determine type of value (int/string)
        let string = true
        const choose = colDef && colDef.choose && colDef.choose
        const staticSet = choose.staticSet
        if (staticSet && staticSet.length && staticSet[staticSet.length - 1]) {
          string = typeof staticSet[0].value === 'string'
        } else if (/^[0-9]+$/.test(`${value}`)) {
          string = false
        }

        return string ? `${value}` : +value
      }

      if (colDef.checkbox) {
        const trueValue = colDef.checkbox.checked?.value ?? 1
        const falseValue = colDef.checkbox.unchecked?.value ?? 0

        let cbval
        const parsed = JSON.parse(`${value || falseValue || 0}`)
        if (`${trueValue}` === `${value}` || parsed) cbval = trueValue
        if (`${falseValue}` === `${value}` || !parsed) cbval = falseValue

        return cbval
      }

      const format = recdFormat || getColFormatting(col, sheet).format
      if (!format || typeof format !== 'string' || value === '') return value

      if (/imperial|number|int|float|currency|\$/.test(format) && c.isEquation(value)) {
        return c.toNum(String(value).replace(/^=/, ''), 20, true, getCellVariables(col, row))
      }

      return c.deformat(String(value).replace(/^=/, ''), format)
    }

    // List of names we tried to fetch
    let namesTried = []

    const formatCellValue = (
      refId,
      col,
      row,
      value,
      recdFormat = null,
      recdType = null,
      dataRcd = cellData.value,
      rowsMapRcd = rowsMap.value
    ) => {
      const rm = rowsMapRcd // Object.assign([], rowsMap.value, rowsMapRcd)
      const data = dataRcd /* {
        ...cellData.value,
        ...dataRcd
      } */

      const sheetIndex = rm[row].sheet
      const objectType = recdType || getCellObjectType(col, getRowSheet(row))
      const cellType = getCellType(col, row, sheetIndex, rm)
      const colDef = getColDef(col, sheetIndex)
      const cd = data[refId]

      if (value === '-') return '-'

      const format =
        recdFormat ?? colDef.formatting?.format ?? getColFormatting(col, sheetIndex).format

      if (cellType === 'progress') {
        return value
      }

      if (format && !objectType && typeof format === 'string' && value !== '') {
        // Normal format
        return c.format(value, format)
      }
      let rowData = null

      const names = fetchedNames.value
      if (objectType && value !== '' && names && names[objectType]) {
        // Name
        const existingName = names[objectType].names[`${value}`]
        // leave blank until at least it was tried once
        const name = existingName || '...'

        if (!existingName) {
          // only queue if not already tried to avoid infinite loop
          queueNameFetch(objectType, [value], refId, value, rm, cd)
        }

        if (format && typeof format === 'function') {
          rowData = rowData ?? getRowData(row, data, rm)
          return format(value, cd, rowData, name)
        }

        return name
      }

      if (format && typeof format === 'function') {
        rowData = rowData ?? getRowData(row, data, rm)
        return format(value, cd, rowData, null)
      }

      return value
    }

    const getRowGroup = (row) => rowsMap.value[row] && rowsMap.value[row].group

    const sortInsideGroup = (col, row, newCellData, oldCellData, doTotalsCalculation = false) => {
      const sheetIndex = getRowSheet(row)
      const sheet = dataSheets.value[sheetIndex]
      const sortingIndex = sheet.sorting.findIndex((s) => s[0] === col)
      if (!sheet.sorting.length || sortingIndex === -1) return

      const sortingDirection = sheet.sorting[sortingIndex][1]

      const groupKey = getRowGroup(row)

      let rm = [...rowsMap.value]
      let spliceStart = 0
      let spliceEnd = rm.length - 1

      if (groupKey !== null) {
        const rows = groupMap.value[groupKey].rows
        const headerRow = groupMap.value[groupKey].headerRow
        const totalsRow = groupMap.value[groupKey].totalsRow
        spliceStart = headerRow !== null ? headerRow + 1 : rows[0]
        spliceEnd = totalsRow !== null ? totalsRow - 1 : rows[rows.length - 1]
      }

      const asc = sortingDirection === 'asc'
      const colDef = getColDef(col, sheet)
      const sortValType = colDef.sortVal || 'raw'
      const isString = typeof oldCellData[sortValType] === 'string'

      const val = (rowVal, valCol = col, valType = sortValType) => {
        if (!(rowVal in rm)) return null

        const rowMap = rm[rowVal]
        const cols = rowMap.columns

        if (!cols[valCol] || !(cols[valCol] in cellData.value)) return ''

        let v = cellData.value[cols[valCol]][valType]

        if (isString) {
          v = v.toLowerCase()
        }

        return v === null ? '' : v
      }

      const oldValue = isString
        ? String(oldCellData[sortValType]).toLowerCase()
        : oldCellData[sortValType]
      const newValue = isString
        ? String(newCellData[sortValType]).toLowerCase()
        : newCellData[sortValType]

      // Which direction should we move in?
      const gtMove = (asc && newValue > oldValue) || (!asc && newValue < oldValue) ? 1 : -1

      // Much faster single row sorting algorithm
      let foundIndex = null
      let newRow = row
      while (foundIndex === null) {
        const rowFound = rm[newRow]

        const nextVal = val(newRow + gtMove)
        const nextRow = rm[newRow + gtMove]

        // End of the line
        if (!nextRow || rowFound.sheet !== nextRow.sheet) {
          foundIndex = newRow
          break
        }

        const nextAsc = gtMove > 0 && asc
        const prevAsc = gtMove < 0 && asc

        const nextDesc = gtMove > 0 && !asc
        const prevDesc = gtMove < 0 && !asc

        const nextGreater = nextVal >= newValue
        const nextLesser = !nextGreater

        // Termination
        if (
          (nextAsc && nextGreater) ||
          (nextDesc && nextLesser) ||
          (prevAsc && nextLesser) ||
          (prevDesc && nextGreater) ||
          ((prevAsc || prevDesc) && nextRow < spliceStart) ||
          ((nextAsc || nextDesc) && nextRow > spliceEnd)
        ) {
          foundIndex = newRow
          break
        } else {
          newRow += gtMove
        }
      }

      const rowDef = rm.splice(row, 1)[0]
      rm.splice(foundIndex, 0, rowDef)

      if (doTotalsCalculation && groupKey) {
        const {
          rowsMap: gtRowsMap,
          groupMap: gm,
          data
        } = calculateGroupTotals(groupKey, col, cellData.value, rm, groupMap.value)

        groupMap.value = gm
        cellData.value = data
        rm = gtRowsMap
        // calculateTotals();
      }

      rowsMap.value = rm
    }

    const getCellVariables = () => ({})

    const getSheet = (sheet) => dataSheets.value[sheet] || {}

    const parseCellData = (shr = dataSheets.value, rmr = rowsMap.value, cdr = cellData.value) => {
      // Sort rows based on the selected column
      let rm = c.imm(rmr)
      let sh = c.imm(shr)
      let data = _.imm(cdr)

      // will sort by sheet
      ;({ dataSheets: sh, rowsMap: rm } = orderByParentChild(
        {
          dataSheets: sh,
          rowsMap: rm
        },
        {
          sheetIndex: null, // all
          rebuild: false // do later
        }
      ))

      const val = (rowMap, col, valType = 'raw') => {
        const cols = rowMap.columns

        if (!cols[col] || !(cols[col] in data)) return ''

        let v = data[cols[col]][valType]

        if (typeof v === 'string') {
          v = v.toLowerCase()
        }

        return v === null ? '' : v
      }

      rm.sort((a, b) => {
        let groupVal = 0

        // Keep sheets separate
        if (a.sheet !== b.sheet) {
          return a.sheet > b.sheet ? 1 : -1
        }

        const sheet = sh[a.sheet]
        if (sheet.grouping.length) {
          sheet.grouping.some((col) => {
            let asc = true
            const colDef = getColDef(col, sheet)
            const sortValType = colDef.sortVal || 'raw'

            const va = val(a, col, sortValType)
            const vb = val(b, col, sortValType)

            const sameIndex = sheet.sorting.findIndex((sortcol) => sortcol[0] === col)
            if (sameIndex > -1) {
              asc = sheet.sorting[sameIndex][1] === 'asc'
            }

            const valueA = asc ? va : vb
            const valueB = asc ? vb : va

            if (valueA < valueB) groupVal = -1
            if (valueA > valueB) groupVal = 1

            return groupVal !== 0
          })
        }

        if (groupVal !== 0) return groupVal

        let sortVal = 0
        if (sheet.sorting.length) {
          sheet.sorting.some(([col, dir]) => {
            const colDef = getColDef(col, sheet)
            const sortValType = colDef.sortVal || 'raw'

            const va = val(a, col, sortValType)
            const vb = val(b, col, sortValType)
            const asc = dir === 'asc'

            const valueA = asc ? va : vb
            const valueB = asc ? vb : va

            if (valueA < valueB) sortVal = -1
            if (valueA > valueB) sortVal = 1

            return sortVal !== 0
          })
        }

        return sortVal
      })

      const gb = {}
      let groupVal = 'nogroup'
      const gm = {}

      const getGroupVal = (grouping, row, sheetIndex) =>
        `${sheetIndex}${grouping.map((grp) => val(rm[row], grp, 'raw')).join('|')}`
      const getGroupName = (grouping, row) =>
        grouping.map((grp) => val(rm[row], grp, 'fmt')).join(' × ')

      let sheetIndex = null
      let sheet = null

      for (let row = 0; row < rm.length; row += 1) {
        const colsLength = rm[row].columns.length

        if (rm[row].sheet !== sheetIndex) {
          sheetIndex = rm[row].sheet
          sheet = sh[sheetIndex]
          sheet.topRow = row
          sheet.rowCount = 0
        }

        // Add group boundaries if is grouped
        const isGrouping = sheet.grouping.length
        let rowGroupVal = isGrouping && getGroupVal(sheet.grouping, row, sheetIndex)
        rowGroupVal = rowGroupVal === null ? '' : rowGroupVal
        const sameGroup = groupVal === rowGroupVal

        if (isGrouping && !sameGroup) {
          const fmt = getGroupName(sheet.grouping, row)
          gm[rowGroupVal] = {
            fmt,
            title: fmt,
            group: rowGroupVal,
            rows: [],
            headerRow: null,
            totalsRow: null,
            sheet: sheetIndex,
            // cache totals when available
            totals: (groupMap.value[rowGroupVal] && groupMap.value[rowGroupVal].totals) || []
          }

          // Set boundary to header row or first row if no headers
          gb[row] = rowGroupVal
          groupVal = rowGroupVal
        }

        if (isGrouping) {
          gm[groupVal].rows.push(row)
          rm[row].group = rowGroupVal
        }
        sheet.rowCount += 1

        for (let col = 0; col < colsLength; col += 1) {
          let refId = rm[row].columns[col] || null

          // If refId it is missing the cell data, and we must add it
          // which could be because it is added from a row above
          if (!refId) {
            ;({
              refId,
              cellData: data,
              rowsMap: rm
            } = addNewCell(col, row, refId, '', '', data, rm))
          }
        }
        sh[sheetIndex] = sheet
      }
      // update cell key references TODO

      // Update the rows to reflect the sorted order
      // reset selected cell
      for (let i = 0; i < sh.length; i += 1) {
        ;({ dataSheets: sh, rowsMap: rm } = rebuildCollapseGroupsFromParentChild(
          {
            dataSheets: sh,
            rowsMap: rm
          },
          { sheetIndex: i }
        ))
      }

      rowsMap.value = rm
      dataSheets.value = sh
      cellData.value = data
      groupBoundaries.value = gb
      groupMap.value = gm
    }

    const getColIndexByField = (field, sheetIndex) =>
      (getSheet(sheetIndex).columns || []).findIndex((col) => col.field === field)

    const getColIndexesByField = (field, sheetIndex) =>
      (getSheet(sheetIndex).columns || [])
        .map((colDef, index) => (colDef.field === field ? index : null))
        .filter((v) => v !== null)

    const STRIP_COMMENTS = /((\/\/.*$)|(\/\*.*\*\/))/gm
    const STRIP_KEYWORDS = /(\s*async\s*|\s*function\s*)+/
    const ARGUMENT_NAMES = /\(([^)]+)\)\s*=>|([a-zA-Z_$]+)\s*=>|[a-zA-Z_$]+\(([^)]+)\)|\(([^)]+)\)/
    const ARGUMENT_SPLIT = /[ ,\n\r\t]+/
    function getParamNames(func) {
      const fnStr = func.toString().replace(STRIP_COMMENTS, '').replace(STRIP_KEYWORDS, '').trim()
      const matches = ARGUMENT_NAMES.exec(fnStr)
      var match
      if (matches) {
        for (var i = 1; i < matches.length; i++) {
          if (matches[i]) {
            match = matches[i]
            break
          }
        }
      }
      if (match === undefined) {
        return []
      }
      return match.split(ARGUMENT_SPLIT).filter((part) => part !== '')
    }

    const collectDependencies = () => {
      if (isArrayRows.value) {
        columnDependencies.value = {}
        return
      }

      const extractParameterInfo = (functionString, param = 1) => {
        // Extract the first parameter name
        const paramMatch = getParamNames(functionString)[param - 1]
        const paramName = paramMatch ? paramMatch : null

        // Extract the properties accessed on the first parameter
        const propRegex = new RegExp(`\\b${paramName}\\.(\\w+)`, 'g')
        let propMatch
        const properties = new Set()

        while ((propMatch = propRegex.exec(functionString)) !== null) {
          properties.add(propMatch[1])
        }

        return {
          paramName,
          properties: Array.from(properties)
        }
      }

      const dependencies = {}

      for (let sheetIndex = 0; sheetIndex < sheetColsWithComputed.value.length; sheetIndex += 1) {
        const sheetDeps = {}

        // Get direct deps
        sheetColsWithComputed.value[sheetIndex].computedColumns.forEach((col) => {
          const cv = dataSheets.value[sheetIndex].columns[col].computedValue ?? (() => {})
          const fm = dataSheets.value[sheetIndex].columns[col].formatting?.format ?? (() => {})
          const { properties = [] } = extractParameterInfo(cv.toString())
          const { properties: fmtProperties = [] } = extractParameterInfo(fm.toString(), 3)

          _.uniq([...properties, ...fmtProperties]).forEach((field) => {
            if (!(field in sheetDeps)) sheetDeps[field] = []

            // When field changes, we need to recacl this column
            // field to column, because a specific field may not be a column, might be hidden
            sheetDeps[field] = _.uniq([...sheetDeps[field], col])
          })
        })

        const getDownstreamDeps = (deps, field, depths = []) => {
          if (!(field in deps)) return []
          if (depths.includes(field)) {
            console.warn(`Circular dependency detected: ${field}`)
            return []
          }

          depths.push(field)

          return _.uniq(
            deps[field].reduce(
              (acc, subcol) => [
                ...acc,
                ...deps[field],
                ...getDownstreamDeps(deps, getColField(subcol, sheetIndex), depths)
              ],
              []
            )
          )
        }

        const newSheetDeps = { ...sheetDeps }
        Object.keys(sheetDeps).forEach((field) => {
          const colDeps = sheetDeps[field]

          colDeps.forEach(() => {
            const ds = colDeps.map((subcol) =>
              getDownstreamDeps(sheetDeps, getColField(subcol, sheetIndex), [field])
            )

            newSheetDeps[field] = _.uniq([...newSheetDeps[field], ...ds.flat()])
          })
        })

        dependencies[sheetIndex] = newSheetDeps
      }

      columnDependencies.value = dependencies
    }

    const orderByParentChild = (
      { dataSheets: sh, rowsMap: rm },
      { sheetIndex = null, rebuild = true }
    ) => {
      let reorderedRows = []

      let rowsById

      for (let i = 0; i < sh.length; i += 1) {
        if ((sheetIndex !== null && sheetIndex !== i) || !hasCollapseGroups(i, sh)) {
          reorderedRows.push(...rm.filter((row) => row.sheetIndex === i))
          continue
        }

        // do this once only
        rowsById ??= rm.reduce(
          (acc, row) => ({
            ...acc,
            [row.id]: row
          }),
          {}
        )

        // Now add back the rootChildrenIds to the root
        const rootId = sh[i].collapseGroups.rootId
        const cg = sh[i].collapseGroups.groups
        sh[i].collapseGroups.rootChildrenIds = cg[rootId].childrenIds

        // traverse through to sort naturally starting from the root
        const addParentRows = (parentId) => {
          for (let childRef of cg[parentId].childrenIds ?? []) {
            if (rowsById[childRef]) reorderedRows.push(rowsById[childRef])
            if (childRef in cg) addParentRows(childRef)
          }
        }
        addParentRows(rootId)
      }

      if (rebuild) {
        for (let i = 0; i < sh.length; i += 1) {
          ;({ dataSheets: sh, rowsMap: rm } = rebuildCollapseGroupsFromParentChild(
            {
              dataSheets: sh,
              rowsMap: rm
            },
            { sheetIndex: i }
          ))
        }
      }

      return { dataSheets: sh, rowsMap: reorderedRows }
    }

    const importSession = (session, sh = propSheets.value) => {
      const settingSheets = session?.sheets ?? []

      // Set row sizes in cellPositions
      // rowHeights.value = session.rowHeights ?? {};

      for (let i = 0; i < settingSheets.length; i++) {
        if (!dataSheets.value[i]) continue

        // Set sorting
        dataSheets.value[i].sorting = settingSheets[i].sorting ?? sh.sort?.sort ?? []
        // Set grouping
        dataSheets.value[i].grouping = settingSheets[i].grouping ?? sh.group?.group ?? []
        // Set col widths in cellPositions
        dataSheets.value[i].colWidths = settingSheets[i].colWidths ?? {}
        // Set super header visibility
        dataSheets.value[i].superHeaders = (dataSheets.value[i].superHeaders ?? []).map(
          (superHeader, shi) => {
            return {
              ...superHeader,
              expanded: settingSheets[i].superHeaders?.[shi]?.expanded ?? superHeader.expanded
            }
          }
        )
      }
    }

    const loaded = ref(0)
    const loadData = async (providedSheets = propSheets.value, session = {}) => {
      let rows = []

      dataSheets.value = _.imm(providedSheets) // needs to be immunatble here which is the most efficient
      importSession(session)
      let sh = dataSheets.value

      for (let i = 0; i < sh.length; i += 1) {
        const sheet = sh[i]

        // Get sorting data
        sh[i].sorting = sheet.sort?.sort ?? []

        sh[i].grouping = sheet.group?.group ?? []

        const rootId =
          'rootId' in (sheet.collapseGroups ?? {}) ? sheet.collapseGroups.rootId : 'root'
        rows = [
          ...rows,
          ...sheet.rows.map((r, rowIndex) => {
            const roPointer = _.uniqueId('ro')
            return {
              data: r,
              sheetIndex: i,
              pointer: roPointer,
              id: r.id || rowIndex,
              parentId: r.parentId || rootId,
              childrenIds: r.childrenIds || null,
              equations: sheet.equations?.[rowIndex] ?? {}
            }
          })
        ]

        // Setup collapse groups
        if (hasCollapseGroups(i, sh)) {
          sheet.collapseGroups = {
            parentFormatting: {
              preset: 'heading',
              bold: true
            },
            sortField: null,
            rootChildrenIds: [], // let the addToParent figure this out, then add back later
            rootId,
            ...sheet.collapseGroups,
            groups: {
              [rootId]: {
                id: rootId,
                parentId: null,
                row: { data: {} },
                childrenIds: [], // let the addToParent figure this out, then add back later
                index: -1,
                data: {},
                root: true,
                expanded: true,
                level: 0,
                hidden: true
              },
              ...(sheet.collapseGroups?.groups ?? {})
            }
          }
        }

        // Collapse superheaers
        ;(sheet.superHeaders || []).forEach((superHeader, shi) => {
          if (!(superHeader.expanded ?? true)) collapseSuperHeader(i, shi, sh)
        })

        sheet.rows = {}
      }

      // turn attachments cols into component cols as well
      sh = sh.reduce((acc, sht) => {
        sht.columns = sht.columns.map((column) =>
          !column.attachments
            ? column
            : {
                ...column,
                component: {
                  name: 'Gallery',
                  props: column.attachments?.props
                }
              }
        )

        return [...acc, sht]
      }, [])

      // Collect dependencies from columns
      collectDependencies()

      // fetch names based on original data
      await fetchNames({ dataSheets: propSheets.value })

      // data to collect
      let maxCols = 0
      let data = {}
      let rm = []

      // iterate through original rows
      for (let row = 0; row < rows.length; row += 1) {
        const currentSheet = rows[row].sheetIndex
        const pointer = rows[row].pointer
        const currentRow = rows[row].data
        const cols = sh[currentSheet].columns
        const colsLength = isArrayRows.value ? currentRow.length : cols.length
        const rootId = sh[currentSheet].collapseGroups?.rootId
        maxCols = Math.max(colsLength, maxCols)
        ;({
          dataSheets: sh,
          rowsMap: rm,
          cellData: data
        } = addNewRow(
          {
            dataSheets: sh,
            rowsMap: rm,
            cellData: data
          },
          {
            rowIndex: row,
            sheetIndex: currentSheet,
            pointer,
            id: currentRow.id,
            rowData: currentRow,
            rowEquations: rows[row].equations,
            parentId: rows[row].parentId ?? rootId,
            formatting: currentRow.formatting ?? {},
            calculateCells: true,
            addToParent: false, // do this later once all parents are accounted for
            rebuildCollapseGroups: false // too expensive one by one, do all at once after
          }
        ))

        // if is parent, create parent from row
        const isRoot = currentRow.id === rootId
        if (
          isRoot ||
          currentRow.childrenIds?.length ||
          sh[currentSheet].collapseGroups?.isParent?.(currentRow)
        ) {
          ;({ dataSheets: sh, rowsMap: rm } = createParentFromRow(
            { dataSheets: sh, rowsMap: rm },
            {
              rowIndex: row
            }
          ))
        }
      }

      // now once all parents are created, you can add the chidlren to the parents
      for (let row = 0; row < rm.length; row += 1) {
        const currentSheet = rows[row].sheetIndex
        const rootId = sh[currentSheet].collapseGroups?.rootId
        const hasCollapseGroups =
          rootId || Object.keys(sh[currentSheet].collapseGroups ?? {}).length
        if (!hasCollapseGroups)
          continue

          // if is child, add to parent
        ;({ dataSheets: sh, rowsMap: rm } = addToParent(
          { dataSheets: sh, rowsMap: rm },
          {
            rows: [rm[row]],
            parentId: rm[row].parentId,
            position: null, // end - it will be placed in the order of the rows as received
            rebuild: false
          }
        ))
      }

      parseCellData(sh, rm, data)

      if (sh.some((sheet) => sheet.grouping.length)) {
        calculateTotals()
      }

      sh.map((shi) =>
        Object.keys(shi.collapseGroups?.groups ?? {}).map((key) => {
          if (shi.collapseGroups?.groups[key].expanded) $this.$emit('expandGroup', key)
          else $this.$emit('collapseGroup', key)
        })
      )
    }

    const ingressData = async (providedSheets = propSheets.value, session = {}) => {
      loaded.value = 0
      await loadData(providedSheets, session)
      loaded.value = 1
    }

    const getColTotalsMethod = (col, sheet) => {
      const colDef = getColDef(col, sheet)

      if (colDef.progress) return 'progress'

      return (
        (colDef.totals && colDef.totals.method) ||
        (getCellType(col, sheet) === 'number' && 'sum') ||
        'selection'
      )
    }

    const calculateColumnTotals = (col, rowStart, rowEnd, data, rm) => {
      const val = (rowMap, valCol) => {
        const cols = rowMap.columns

        if (!cols[valCol] || !(cols[valCol] in data)) return ''

        return data[cols[valCol]].raw
      }
      const method = getColTotalsMethod(col, getRowSheet(rowStart))

      const all = []
      for (let row = rowStart; row <= rowEnd; row += 1) {
        all.push(val(rm[row], col))
      }

      let total
      if (method === 'progress') {
        let count = 0
        let sum = 0

        all.forEach((p) => {
          count += p.length
          sum += p.reduce((acc, v) => acc + v, 0)
        })

        total = `${c.toNum((sum / count) * 100, 0)}%`
      } else if (method === 'sum') {
        total = all.reduce((acc, allVal) => acc + c.toNum(allVal || 0), 0)
      } else if (method === 'average' || method === 'avg') {
        let totalNotEmpty = 0
        total =
          all.reduce((acc, allVal) => {
            totalNotEmpty += allVal !== '' ? 1 : 0
            return acc + c.toNum(allVal || 0)
          }, 0) / totalNotEmpty
      } else if (method === 'median') {
        const vals = all.reduce((acc, allVal) => [...acc, allVal], [])
        const sortedArr = vals.slice().sort((a, b) => a - b)
        const mid = Math.floor(sortedArr.length / 2)

        if (sortedArr.length % 2 === 0) {
          // even length
          total = (c.toNum(sortedArr[mid - 1]) + c.toNum(sortedArr[mid])) / 2
        }

        total = c.toNum(sortedArr[mid])
      } else {
        // Selection
        const vals = all.reduce((acc, allVal) => [...acc, `${allVal}`], [])
        total = c.cleanArray(vals).length === 1 ? vals[0] : '-'
      }

      return total
    }

    const calculateGroupTotals = (groupKey, singleCol = null, data, rm, gm) => {
      if (!(groupKey in gm)) {
        return {
          data,
          rowsMap: rm,
          groupMap: gm
        }
      }
      const sheet = gm[groupKey].sheet || 0

      const rowStart = 0
      const rowEnd = gm[groupKey].rows.length - 1

      const columns =
        singleCol === null ? dataSheets.value[sheet].columns : [getColDef(singleCol, sheet)]

      for (let col = 0; col < columns.length; col += 1) {
        const colIndex = singleCol || col
        gm[groupKey].totals[colIndex] = calculateColumnTotals(
          colIndex,
          gm[groupKey].rows[rowStart],
          gm[groupKey].rows[rowEnd],
          data,
          rm
        )
      }

      return {
        data,
        rowsMap: rm,
        groupMap: gm
      }
    }

    const calculateTotals = (singleGroup = null, singleCol = null, sheet = null) => {
      if (sheet !== null) {
        calculateSheetTotals(sheet, singleGroup, singleCol)
        return
      }

      for (let i = 0; i < dataSheets.value.length; i += 1) {
        calculateSheetTotals(i, singleGroup, singleCol)
      }
    }

    const calculateSheetTotals = (sheetIndex, singleGroup = null, singleCol = null) => {
      const groupSettings = dataSheets.value[sheetIndex].group
      const groupings = dataSheets.value[sheetIndex].grouping
      if (!groupings.length || !groupSettings.showTotals) return

      let data = cellData.value
      let rm = rowsMap.value

      // Calculate group totals
      let gm = groupMap.value
      const groups = singleGroup !== null ? [singleGroup] : Object.keys(gm)

      for (let group = 0; group < groups.length; group += 1) {
        const groupKey = groups[group]
        const {
          data: sData,
          rowsMap: sRowsMap,
          groupMap: sGroupMap
        } = calculateGroupTotals(groupKey, singleCol, data, rm, gm)
        data = sData
        rm = sRowsMap
        gm = sGroupMap
      }

      cellData.value = data
      rowsMap.value = rm
      groupMap.value = gm
    }

    const isRowFirstOfSheet = (row) =>
      dataSheets.value.findIndex((sheet) => sheet.topRow === row) > -1

    const isRowLastOfSheet = (row) =>
      dataSheets.value.findIndex((sheet) => sheet.topRow + sheet.rowCount - 1 === row) > -1

    const isRowFirstOfGroup = (row) => row in groupBoundaries.value

    const isRowLastOfGroup = (row) => {
      const rowGroup = getRowGroup(row)
      const rows = (groupMap.value[rowGroup] && groupMap.value[rowGroup].rows) || []
      return rows[rows.length - 1] === row
    }

    const sheetColsWithComputed = computed(() =>
      dataSheets.value.map((sheet) => ({
        computedColumns: sheet.columns.reduce(
          (acc, col, i) => [
            ...acc,
            ...(col.computedValue || typeof col.formatting?.format === 'function' ? [i] : [])
          ],
          []
        )
      }))
    )

    const hasSuperHeaders = computed(() =>
      dataSheets.value.some((sheetDef) => sheetDef.superHeaders && sheetDef.superHeaders.length)
    )

    const getSuperHeaders = (sheet) =>
      (dataSheets.value[sheet] && dataSheets.value[sheet].superHeaders) || []
    const collapsedSuperHeaders = ref({})
    const superHeadersHidden = computed(() => {
      const res = {}

      Object.values(collapsedSuperHeaders.value || {}).forEach(
        (sheetCollapsedSuperHeaders, sheetIndex) => {
          const superHeaderIndexes = Object.keys(sheetCollapsedSuperHeaders || {})
          for (const superHeaderIndex of superHeaderIndexes) {
            const superHeaders = getSuperHeaders(sheetIndex) || []
            superHeaders.forEach((superHeader, subSuperHeaderIndex) => {
              if (subSuperHeaderIndex.toString() === superHeaderIndex) return
              const subSuperHeaderSpan = superHeaders[subSuperHeaderIndex]?.span || []
              const intersection = _.intersection(
                subSuperHeaderSpan,
                sheetCollapsedSuperHeaders[superHeaderIndex]
              )
              if (subSuperHeaderSpan.length === intersection.length) res[subSuperHeaderIndex] = true
            })
          }
        }
      )
      return res
    })

    const collapseSuperHeader = (sheetIndex, superHeaderIndex, shs = dataSheets.value) => {
      const csh = { ...collapsedSuperHeaders.value }
      if (!(sheetIndex in csh)) csh[sheetIndex] = {}

      const sh = shs[sheetIndex].superHeaders[superHeaderIndex]

      csh[sheetIndex][`${superHeaderIndex}`] = Array.isArray(sh.span[0])
        ? sh.span.flatMap(([start, end]) =>
            Array.from({ length: end - start + 1 }, (_, i) => start + i)
          )
        : Array.from({ length: sh.span[1] - sh.span[0] + 1 }, (_, i) => sh.span[0] + i)

      collapsedSuperHeaders.value = csh

      dataSheets.value[sheetIndex].superHeaders[superHeaderIndex].expanded = false

      $this.$emit('superHeaderCollapsed', { sheetIndex, superHeaderIndex })
    }

    const uncollapseSuperHeader = (sheetIndex, superHeaderIndex) => {
      const csh = { ...collapsedSuperHeaders.value }
      if (!(sheetIndex in csh)) csh[sheetIndex] = {}

      // eslint-disable-next-line no-unused-vars
      const { [`${superHeaderIndex}`]: omit, ...rest } =
        collapsedSuperHeaders.value[sheetIndex] ?? {}

      csh[sheetIndex] = rest
      collapsedSuperHeaders.value = csh

      dataSheets.value[sheetIndex].superHeaders[superHeaderIndex].expanded = true

      $this.$emit('superHeaderExpanded', { sheetIndex, superHeaderIndex })
    }

    const resetOrder = () => {
      const shts = [...dataSheets.value]

      for (let i = 0; i < shts.length; i += 1) {
        shts[i].sorting = []
        shts[i].grouping = []
      }

      dataSheets.value = shts
      ingressData()
    }

    const isCollapseGroupParent = (row) => {
      const groups = (dataSheets.value[getRowSheet(row)].collapseGroups || {}).groups || {}
      const keys = Object.keys(groups)
      const id = getRowId(row)
      return keys.includes(id)
    }

    const getRowCollapseGroupKeys = (row, dataModel = {}) => {
      const { dataSheets: ds = dataSheets.value, rowsMap: rm = rowsMap.value } = dataModel

      const sheetIndex = getRowSheet(row, rm)
      const collapseGroups = ds[sheetIndex]?.collapseGroups?.groups

      if (!collapseGroups) return []

      const result = []
      for (const [key, group] of Object.entries(collapseGroups)) {
        if (group.rows.includes(row)) {
          result.push(key)
        }
      }

      return result
    }

    const rowIdMap = computed(() =>
      rowsMap.value.reduce(
        (acc, row, index) => ({
          ...acc,
          [row.id]: index
        }),
        {}
      )
    )

    const getRowFromId = (id, rm = rowsMap.value) => {
      if (id in rowIdMap.value && rm === rowsMap.value) {
        return rowIdMap.value[id] // Fast lookup in the map
      }

      // Fallback to linear search if a custom rowsMap is provided
      for (let i = 0; i < rm.length; i++) {
        if (rm[i].id === id) {
          return i
        }
      }

      return -1
    }

    const hasCollapseGroups = (sheetIndex, dsheets = dataSheets.value) =>
      Object.keys(dsheets[sheetIndex]?.collapseGroups ?? {}).length

    const getRowParent = (row, rm = rowsMap.value, ds = dataSheets.value) => {
      const sheetIndex = getRowSheet(row)

      return (rm[row] && rm[row].parentId) || ds[sheetIndex]?.collapseGroups?.rootId
    }

    const isRowParent = (row, dsheets = dataSheets.value) => {
      const sheetIndex = getRowSheet(row)
      if (!hasCollapseGroups(sheetIndex)) return false

      const rowId = rowsMap.value[row].id

      return !(!rowId || !(rowId in dsheets[sheetIndex].collapseGroups.groups))
    }

    const addRowData = (
      {
        dataSheets: ds = dataSheets.value,
        cellData: cd = cellData.value,
        rowsMap: rm = rowsMap.value
      },
      { pointer, rowIndex, sheetIndex = 0, data = {}, equations = {} }
    ) => {
      const cols = ds[sheetIndex].columns ?? []

      for (const colDef of cols) {
        const col = cols.indexOf(colDef)
        const arr = data.length || !colDef

        const cellValue = (arr ? data[col] : data[colDef.field]) ?? ''
        const cellEquation = (arr ? equations[col] : equations[colDef.field]) ?? cellValue

        const refId = getRefId(col, pointer, sheetIndex, ds)

        ;({ data: cd, rowsMap: rm } = addNewCell(
          col,
          rowIndex,
          refId,
          cellValue,
          cellEquation,
          cd,
          rm
        ))
      }

      return {
        dataSheets: ds,
        cellData: cd,
        rowsMap: rm
      }
    }

    const addRow = (prevRow = null, emit = true) => {
      let sheetIndex = 0
      let row = rowsMap.value.length
      let addedFromParent = false
      let parent = dataSheets.value[sheetIndex]?.collapseGroups?.rootId
      let prevId = null
      let groupKey = null

      if (prevRow !== null) {
        sheetIndex = getRowSheet(prevRow)
        row = prevRow + 1
        addedFromParent = isRowParent(prevRow)
        parent = addedFromParent ? getRowId(prevRow) : getRowParent(prevRow)
        prevId = getRowId(prevRow)
        groupKey = rowsMap.value[prevRow].group
      }

      let dsheets = [...dataSheets.value]
      let rm = [...rowsMap.value]
      let data = { ...cellData.value }

      const id = _.uniqueId(`${prevId}-new-`)
      const fakeOriginalRow = _.uniqueId(`ro`)

      const childIds = getChildren(parent, sheetIndex, dsheets)
      prevId = prevId ?? childIds[childIds.length - 1]
      const position = addedFromParent ? 0 : childIds.indexOf(prevId) + 1

      ;({
        dataSheets: dsheets,
        rowsMap: rm,
        cellData: data
      } = addNewRow(
        {
          dataSheets: dsheets,
          rowsMap: rm,
          cellData: data
        },
        {
          rowIndex: row,
          sheetIndex,
          pointer: fakeOriginalRow,
          id,
          rowData: {},
          rowEquations: {},
          parentId: parent,
          childPosition: position,
          groupKey,
          calculateCells: true
        }
      ))

      const { dataSheets: ds, rowsMap: rm2 } = rebuildCollapseGroupsFromParentChild(
        {
          dataSheets: dsheets,
          rowsMap: rm
        },
        { sheetIndex }
      )

      cellData.value = data
      rowsMap.value = rm2
      rowCount.value += 1
      dataSheets.value = ds

      parseCellData()

      const payload = {
        rows: [rm[row]],
        parentId: parent,
        position,
        rowIds: [row], // @todo is this correct?
        rowIndexes: [row],
        addedFrom: prevRow
      }

      if (emit) $this.$emit('addedRows', payload)

      return payload
    }

    const mergeChanges = (changes, toMerge) => NormalizeUtilities.mergeChanges(changes, toMerge)

    const rebuildCollapseGroupsFromParentChild = (
      { dataSheets: ds = dataSheets.value, rowsMap: rm = rowsMap.value },
      { sheetIndex = 0 } = {}
    ) => {
      // Skip if this sheet doesn't have collapse groups
      if (!hasCollapseGroups(sheetIndex, ds)) {
        return { dataSheets: ds, rowsMap: rm }
      }

      const cg = { ...ds[sheetIndex].collapseGroups }
      const gps = cg.groups

      // Initialize tracking structures
      const paths = Object.create(null)
      const levels = Object.create(null)

      const rowIdMap = rm.reduce((acc, row, index) => {
        acc[row.id] = index
        return acc
      }, {})

      const getChildPaths = (id) => {
        if (!gps[id]) return

        const parentId = gps[id]?.parentId
        const parentLevel = levels[parentId] ?? -1
        levels[id] = parentLevel + 1

        // Build path
        paths[id] = paths[parentId] ? [...paths[parentId], id] : [id]

        // Reset group properties
        const group = gps[id]
        group.level = levels[id]
        group.ids = [id]
        group.rows = [rowIdMap[id]]

        // Process children
        for (const childId of group.childrenIds) {
          getChildPaths(childId)
        }
      }

      getChildPaths(cg.rootId)

      // Add rows to ancestors
      for (let rowIndex = 0; rowIndex < rm.length; rowIndex++) {
        const parentId = rm[rowIndex].parentId
        if (!paths[parentId]) continue

        for (const ancestor of paths[parentId]) {
          const group = gps[ancestor]
          group.ids.push(rm[rowIndex].id)
          group.rows.push(rowIndex)
        }
      }

      return { dataSheets: ds, rowsMap: rm }
    }

    const createParentFromRow = (
      { dataSheets: ds = dataSheets.value, rowsMap: rm = rowsMap.value },
      { rowIndex }
    ) => {
      const sheetIndex = getRowSheet(rowIndex, rm)
      const id = rm[rowIndex].id
      const level =
        (ds[sheetIndex].collapseGroups?.groups?.[rm[rowIndex].parentId]?.level ?? -1) + 1

      const expanded = ds[sheetIndex].collapseGroups.isExpanded?.(getRowData(rowIndex)) ?? true
      ds[sheetIndex].collapseGroups.groups[id] = {
        id,
        expanded,
        rows: [rowIndex], // will be sorted out later
        ids: [id],
        parentId: rm[rowIndex].parentId,
        level,
        childrenIds: []
      }

      return {
        id,
        expanded,
        dataSheets: ds,
        rowsMap: rm
      }
    }

    const addToParent = (
      {
        dataSheets: ds = dataSheets.value,
        rowsMap: rm = rowsMap.value,
        cellData: cd = cellData.value
      },
      {
        rows,
        parentId,
        position = null, // end,
        rebuild = true
      }
    ) => {
      const rowIndexes = rows.map((r) => getRowFromId(r.id, rm))
      const rowIds = rows.map((r) => r.id)
      const sheetIndex = getRowSheet(rowIndexes[0], rm)

      // Skip if this sheet doesn't have cgs
      if (!hasCollapseGroups(sheetIndex, ds)) {
        return {
          dataSheets: ds,
          rowsMap: rm
        }
      }

      const changes = {}

      let rowPosition =
        position ?? ds[sheetIndex].collapseGroups?.groups[parentId]?.childrenIds?.length ?? 0

      for (let rowIndex of rowIndexes) {
        // remove from old parent(s)
        const oldParent = rm[rowIndex].parentId
        const oldSiblings = ds[sheetIndex].collapseGroups.groups?.[oldParent]?.childrenIds ?? []

        const id = rm[rowIndex].id
        const index = oldSiblings.indexOf(id)
        if (index > -1) oldSiblings.splice(index, 1)
        changes[oldParent] = {
          childrenIds: oldSiblings
        }

        // set parentId
        rm[rowIndex].parentId = parentId
        if (ds[sheetIndex].collapseGroups.groups[id]) {
          ds[sheetIndex].collapseGroups.groups[id].parentId = parentId
          changes[id] = {
            parentId: parentId
          }
        }
      }

      // Add to new parent
      const chids = ds[sheetIndex].collapseGroups.groups[parentId].childrenIds.filter(
        (id) => !rowIds.includes(id)
      )
      chids.splice(rowPosition, 0, ...rowIds)
      ds[sheetIndex].collapseGroups.groups[parentId].childrenIds = chids
      changes[parentId] = {
        childrenIds: chids
      }

      if (rebuild) {
        ;({ dataSheets: ds } = rebuildCollapseGroupsFromParentChild(
          {
            dataSheets: ds,
            rowsMap: rm,
            cellData: cd
          },
          { sheetIndex }
        ))
      }

      return {
        dataSheets: ds,
        rowsMap: rm,
        changes
      }
    }

    const removeFromParent = (rows, dsheets = [...dataSheets.value], rm = [...rowsMap.value]) => {
      const rowIds = rows.map((r) => r.id)
      const rowIndexes = rowIds.map((rid) => getRowFromId(rid, rm))

      const sheetIndex = getRowSheet(rowIndexes[0])
      const changes = {}

      for (let i = 0; i < rowIndexes.length; i += 1) {
        const parentId = rm[rowIndexes[i]].parentId
        const id = rm[rowIndexes[i]].id

        rm[rowIndexes[i]].parentId = null
        changes[rowIds[i]] = { parentId: null }

        dsheets[sheetIndex].collapseGroups.groups[parentId].childrenIds = dsheets[
          sheetIndex
        ].collapseGroups.groups[parentId].childrenIds.filter((cid) => !rowIds.includes(cid))
        changes[parentId] = {
          childrenIds: dsheets[sheetIndex].collapseGroups.groups[parentId].childrenIds
        }

        const idIndex = dsheets[sheetIndex].collapseGroups.groups[parentId].ids.indexOf(id)
        if (idIndex > -1) {
          dsheets[sheetIndex].collapseGroups.groups[parentId].ids.splice(idIndex, 1)
        }
      }

      return {
        dataSheets: dsheets,
        rowsMap: rm,
        changes
      }
    }

    // const oldParentRow = getRowFromId(oldParentId, rm);
    // const oldParentIndex = rm[oldParentRow].childrenIds.indexOf(idToMove);
    // if (oldParentIndex > -1) {
    //   rm[oldParentRow].childrenIds.splice(oldParentIndex, 1);
    //   changes[oldParentId] = {
    //     childrenIds: rm[oldParentRow].childrenIds,
    //   };
    // }

    // const getMoveParent = (to, forceParent, sheetIndex) => {
    //   const prevRow = to - 1
    //   const prevId = getRowId(prevRow)
    //   let dsheets = [...dataSheets.value]
    //   const rootId = dsheets[sheetIndex].collapseGroups.rootId
    //   const prevParent = getRowParent(prevRow) || rootId
    //   const prevParentRow = getRowFromId(prevParent)
    //   const prevParentChildIds = getChildren(prevParent, sheetIndex, dsheets)
    //   const isPrevParent = isCollapseGroupParent(prevRow)
    //
    //   let parentRow
    //   let parentId
    //   if (isPrevParent) {
    //     parentRow = to - 1
    //     parentId = getRowId(parentRow)
    //   } else if (
    //     prevRow > -1 &&
    //     prevParentChildIds.indexOf(prevId) === prevParentChildIds.length - 1
    //   ) {
    //     // go to the grandparent, in the position after the parent
    //     parentId = getRowParent(prevParentRow)
    //     parentRow = getRowFromId(parentId)
    //   } else {
    //     parentId = prevParent
    //     parentRow = prevParentRow
    //   }
    //   parentRow = forceParent || parentRow
    //   parentId = (forceParent && getRowId(parentRow)) || parentId
    //
    //   if (parentRow < 0 || parentId === null) {
    //     parentId = rootId
    //     parentRow = -1
    //   }
    //
    //   return {
    //     parentRow,
    //     parentId
    //   }
    // }

    const getAncestorsFrom = (id, sheetIndex) => {
      const rootId = dataSheets.value[sheetIndex].collapseGroups.rootId
      const ancestors = []
      let ar = id
      while (ar !== null) {
        ancestors.push(ar)
        ar = ar === rootId ? null : getRowParent(getRowFromId(ar))
      }
      return ancestors
    }

    // const getPrevSibling = (parentId, prevRow, sheetIndex, rm, dhseets) => {
    //   const prevId = getRowId(prevRow, sheetIndex, rm)
    //   const prevParent = getRowParent(prevRow, rm, dhseets)
    //   if (prevParent === parentId) return prevRow
    //   if (prevId === parentId) return null
    //   return getPrevSibling(parentId, prevRow - 1, sheetIndex, rm, dhseets)
    // }

    const getChildPosition = (parentId, prevRow, prevId, rows, sheetIndex, rm, dsheets) => {
      // if prevRow  IS parent, return 0 and put first
      let children = dsheets[sheetIndex].collapseGroups.groups?.[parentId]?.childrenIds ?? []
      if (prevId === parentId || !children.length) return 0

      // make sure we check the child position NOT including the children that are already moved
      const idsMoving = rows.map((r) => r.id)
      children = children.slice().filter((chref) => !idsMoving.includes(chref))

      // if not, rewind row by row until you find a CHILD of the parent, get it's position, add 1 and thats the one
      let pos = -1
      let cur = prevRow
      while (pos < 0 && cur > -1) {
        const id = rm[cur].id
        const index = children.indexOf(id)
        if (index > -1) {
          pos = index
        }
        cur--
      }

      // go up rows from prevRo
      return pos + 1
    }

    const moveRowsTo = (rowIds, to, parentId, forceParentPosition = null, emit = true) => {
      if (!rowIds?.length || to < 0 || to === null) {
        return { rowsCount: 0 }
      }

      const sheetIndex = getRowSheet(to)
      const ancestors = getAncestorsFrom(parentId, sheetIndex)

      // Open all ancestors
      ancestors.forEach((id) => $this.$emit('expandGroup', id))

      let dsheets = _.imm(dataSheets.value)
      let rm = _.imm(rowsMap.value)

      let prevId = null
      let cur = to - 1

      // Get a prevId that is NOT included in the rowIds that are getting moved
      while (prevId === null && cur > -1) {
        const id = rm[cur].id
        if (!rowIds.includes(id)) prevId = id
        else cur--
      }
      // If not founds, resort to position 0
      if (!prevId) {
        forceParentPosition = 0
      }

      let rows = []
      const ids = new Set(rowIds) // Use Set for faster lookups

      for (const id of rowIds) {
        const from = getRowFromId(id, rm)
        if (from === -1) continue // was added as child of previous parent

        // Can't place within itself
        if (ancestors.includes(id)) {
          return { rowsCount: 0 }
        }

        const isParent = isCollapseGroupParent(from)
        const rowsCount = isParent
          ? dsheets[sheetIndex].collapseGroups.groups[id]?.rows.length || 1
          : 1

        // Collect rows and remove from `rm`
        rows = rows.concat(rm.splice(from, rowsCount))
      }

      const newTo = getRowFromId(prevId, rm) + 1
      const childPosition =
        forceParentPosition ??
        getChildPosition(parentId, newTo - 1, prevId, rows, sheetIndex, rm, dsheets)

      // Insert rows at the new position
      rm.splice(newTo, 0, ...rows)

      const filteredRows = rows.filter((row) =>
        getAncestorsFrom(row.id, sheetIndex)
          .slice(1)
          .every((ancestorId) => !ids.has(ancestorId))
      ) // Only keep rows without parent IDs in `ids`

      let changes = {}
      ;({
        dataSheets: dsheets,
        rowsMap: rm,
        changes
      } = addToParent(
        { dataSheets: dsheets, rowsMap: rm },
        {
          rows: filteredRows,
          parentId,
          position: childPosition,
          rebuild: true
        }
      ))

      // Update reactive values
      rowsMap.value = rm
      dataSheets.value = dsheets

      parseCellData()

      if (emit) {
        $this.$emit('movedRows', {
          rowIds,
          previousRow: rowIds,
          newRow: to,
          parentId,
          childPosition,
          changes
        })
      }

      return {
        rowIds,
        previousRow: rowIds,
        newRow: to,
        parentId,
        childPosition,
        changes
      }
    }

    const deleteRows = (rowIndexes) => {
      let rm = rowsMap.value.slice()
      let dsheets = dataSheets.value.slice()

      let allRows = new Set()
      let allIds = new Set()

      let sheetIndex = 0
      for (let i = 0; i < rowIndexes.length; i++) {
        const r = rowIndexes[i]
        sheetIndex = getRowSheet(r)
        const row = rm[r]
        const id = row.id

        allRows.add(r)
        allIds.add(id)

        const collapseGroups = dsheets[sheetIndex]?.collapseGroups?.groups
        if (collapseGroups?.[id]) {
          for (const rowId of collapseGroups[id].rows) allRows.add(rowId)
          for (const rowId of collapseGroups[id].ids) allIds.add(rowId)
        }
      }

      allRows = _.cleanArray(Array.from(allRows))
      allIds = _.cleanArray(Array.from(allIds))

      const fullRows = allRows.map((r) => rm[r])
      const ids = allIds

      let cd = { ...cellData.value }
      let changes = {}

      for (let i = 0; i < allRows.length; i++) {
        const row = allRows[i]
        const parentId = rm[row]?.parentId

        if (parentId && !allIds.includes(parentId)) {
          const {
            rowsMap: updatedRm,
            changes: updatedChanges,
            dataSheets: updatedDsheets
          } = removeFromParent([rm[row]], dsheets, rm)
          rm = updatedRm
          dsheets = updatedDsheets
          changes = mergeChanges(changes, updatedChanges)
        }
        // Remove from collapse groups
        const id = ids[i]
        sheetIndex = getRowSheet(row)

        // Clean up cell data
        const columns = rm[row].columns
        for (let j = 0; j < columns.length; j++) {
          delete cd[columns[j]]
        }

        // Remove collapse group entries
        const collapseGroups = dsheets[sheetIndex]?.collapseGroups?.groups
        if (collapseGroups?.[id]) {
          delete collapseGroups[id]
        }
      }

      // Remove rows from rowsMap and dataSheets in descending order
      for (const row of fullRows) {
        const sheetIndex = getRowSheet(row)
        delete dsheets[sheetIndex].rows[row.pointer]
        rm.splice(rm.indexOf(row), 1)
      }

      // Rebuild collapse groups and update reactive states
      ;({ dataSheets: dsheets, rowsMap: rm } = rebuildCollapseGroupsFromParentChild(
        { dataSheets: dsheets, rowsMap: rm },
        { sheetIndex }
      ))

      cellData.value = cd
      dataSheets.value = dsheets
      rowsMap.value = rm

      parseCellData()

      $this.$emit('removedRows', {
        rows: fullRows,
        ids,
        changes
      })
    }

    const getChildren = (parentId, sheetIndex, dsheets = dataSheets.value) => {
      const cg = dsheets[sheetIndex].collapseGroups && dsheets[sheetIndex].collapseGroups
      const groups = cg && cg.groups

      if (!cg || !groups || !(parentId in groups)) return []

      return groups[parentId].childrenIds
    }

    const getDescendants = (parentId, sheetIndex, dsheets = dataSheets.value) => {
      const descendants = [] // To store the final list of descendant IDs
      const queue = [parentId] // Initialize queue with the parent ID
      const visited = new Set() // Use Set to track visited nodes (better performance)

      while (queue.length > 0) {
        const id = queue.shift() // Get and remove the first item from the queue

        // If this ID has already been visited, skip to the next iteration
        if (visited.has(id)) {
          continue
        }
        visited.add(id) // Mark this ID as visited

        const children = getChildren(id, sheetIndex, dsheets) || [] // Retrieve children of the current ID

        descendants.push(...children) // Add all children to the descendants list
        queue.push(...children) // Add children to the queue for further traversal
      }

      return descendants
    }

    const getRowIndexes = (rowIds) => {
      const rowIndexes = []
      for (let id of rowIds) {
        rowIndexes.push(getRowFromId(id))
      }

      return rowIndexes.toSorted((a, b) => a - b)
    }
    const getRowsFromIds = getRowIndexes

    const duplicateRows = ([rowIndex]) => {
      // Only one at a time
      const rowId = getRowId(rowIndex)
      const sheetIndex = getRowSheet(rowIndex)

      let rm = [...rowsMap.value]
      let cd = { ...cellData.value }
      let ds = [...dataSheets.value]

      const dupRow = rm[rowIndex]
      const rootParentId = rm[rowIndex].parentId
      const rowIds = [dupRow.id, ...getDescendants(dupRow.id, sheetIndex)]

      const rowIndexes = _.uniq(getRowIndexes(rowIds))

      let rowPosition = rowIndexes[rowIndexes.length - 1] + 1

      // reref list
      const idMap = rowIds.reduce(
        (acc, rr) => ({
          ...acc,
          [rr]: _.uniqueId(`${rr}-copy`)
        }),
        {}
      )
      const indexMap = new Map()

      for (const rowIndex of rowIndexes) {
        let fakeOriginalRow = _.uniqueId('ro')
        // eslint-disable-next-line no-unused-vars
        const { id, parentId, childrenIds, ...rowData } = ds[sheetIndex].rows[rm[rowIndex].pointer]
        ;({
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        } = addNewRow(
          { dataSheets: ds, rowsMap: rm, cellData: cd },
          {
            rowIndex: rowPosition,
            pointer: fakeOriginalRow,
            sheetIndex,
            id: idMap[rm[rowIndex].id],
            // get rerefed parent
            parentId: idMap[rm[rowIndex].parentId] ?? rm[rowIndex].parentId,
            rebuildCollapseGroups: false,
            rowData
          }
        ))

        // If it was a parent/assembly before, make it a parent for the duplicate
        if (rm[rowIndex].id in (ds[sheetIndex].collapseGroups?.groups ?? {})) {
          ;({ dataSheets: ds, rowsMap: rm } = createParentFromRow(
            { dataSheets: ds, rowsMap: rm },
            {
              rowIndex: rowPosition
            }
          ))
        }

        // oldrow, newrow
        indexMap.set(rowIndex, rowPosition)

        rowPosition++
      }

      // Rebuild collapse groups when all done
      ;({ dataSheets: ds } = rebuildCollapseGroupsFromParentChild(
        {
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        },
        { sheetIndex }
      ))

      dataSheets.value = ds
      cellData.value = cd
      rowsMap.value = rm

      parseCellData()

      const payload = {
        id: rowId,
        newId: idMap[rowId],
        idMap,
        rows: Array.from(indexMap).map(([, newRow]) => rm[newRow]),
        copiedRow: rowIndex,
        newRow: indexMap.get(rowIndex),
        parentId: rootParentId,
        parentRow: rowsMap.value[getRowFromId(rootParentId)],
        rowData: getRowData(indexMap.get(rowIndex))
      }

      $this.$emit('duplicatedRows', payload)

      return payload
    }

    const turnIntoAssembly = (rows) => {
      const [rowIndex] = rows
      // get row ids before rows are shifted around
      let rm = [...rowsMap.value]
      let cd = { ...cellData.value }
      let dsheets = [...dataSheets.value]
      const itemRow = { ...rm[rowIndex] }
      const itemData = getRowData(rowIndex)

      const sheetIndex = rm[rowIndex].sheetIndex
      const fakeOriginalRow = _.uniqueId('ro')
      const originalRow = rm[rowIndex]
      const originalId = originalRow.id
      const parentId = originalRow.parentId
      const assemblyId = `${originalId}-${c.uniqueId()}-assembly`

      // create new assembly in rowIndex position
      ;({
        dataSheets: dsheets,
        rowsMap: rm,
        cellData: cd
      } = addNewRow(
        { dataSheets: dsheets, rowsMap: rm, cellData: cd },
        {
          sheetIndex,
          rowIndex,
          pointer: fakeOriginalRow,
          id: assemblyId,
          rowData: {
            type: 'assembly'
          },
          parentId,
          calculateCells: false, // not enough data to bother yet
          rebuildCollapseGroups: false // no point yet
        }
      ))
      // Turn new row into a parent / assembly with no children
      ;({ dataSheets: dsheets, rowsMap: rm } = createParentFromRow(
        { dataSheets: dsheets, rowsMap: rm },
        {
          rowIndex
        }
      ))
      // Now add original row to the new parent / assembly
      ;({ dataSheets: dsheets, rowsMap: rm } = addToParent(
        { dataSheets: dsheets, rowsMap: rm },
        {
          rows: [rm[rowIndex + 1]],
          parentId: assemblyId,
          position: 0, // start
          rebuild: true
        }
      ))

      dataSheets.value = dsheets
      cellData.value = cd
      rowsMap.value = rm

      parseCellData()

      $this.$emit('turnItemIntoAssembly', {
        rows: [itemRow],
        itemRow,
        itemRowData: itemData,
        parentRow: rowIndex,
        assemblyRow: rm[rowIndex]
      })
    }

    const addSourceRows = (
      { dataSheets: ds = dataSheets.value },
      { id, sheetIndex, pointer = _.uniqueId('ro'), data = {} }
    ) => {
      ds[sheetIndex].rows[pointer] = {
        ...(ds[sheetIndex].rows[pointer] ?? {}),
        ...data,
        id
      }

      return {
        dataSheets: ds
      }
    }

    const rereference = (refMap) => {
      const rm = [...rowsMap.value]
      let dsheets = [...dataSheets.value]
      let cd = { ...cellData.value }

      const rerefedRows = []
      for (let i = 0; i < rm.length; i += 1) {
        const origRow = rm[i].pointer
        const oldId = rm[i].id
        const oldParentId = rm[i].parentId

        if (oldId in refMap) {
          const newId = refMap[oldId]
          rm[i].id = newId
          rerefedRows.push(rm[i])

          // reset originals
          dsheets[rm[i].sheetIndex].rows[origRow].id = newId

          // Reref column pointers
          rm[i].columns = rm[i].columns.map((colRef, col) => {
            const newRef = getRefId(col, origRow, rm[i].sheetIndex, dsheets)
            let cellData
            ;({ [colRef]: cellData, ...cd } = cd)
            cd[newRef] = cellData
            return newRef
          })
        }

        if (oldParentId && oldParentId in refMap) {
          rm[i].parentId = refMap[oldParentId]

          // reset originals
          dsheets[rm[i].sheetIndex].rows[origRow].parentId = refMap[oldParentId]
        }
      }

      for (let i = 0; i < dsheets.length; i += 1) {
        const cgs = (dsheets[i].collapseGroups && dsheets[i].collapseGroups.groups) || {}
        const cgsks = Object.keys(cgs)

        if (!cgsks.length) continue

        for (let j = 0; j < cgsks.length; j += 1) {
          const cgsk = cgsks[j]

          // the group ids:
          dsheets[i].collapseGroups.groups[cgsk].ids = dsheets[i].collapseGroups.groups[
            cgsk
          ].ids.map((id) => (id in refMap ? refMap[id] : id))

          // parentid
          dsheets[i].collapseGroups.groups[cgsk].parentId =
            refMap[dsheets[i].collapseGroups.groups[cgsk].parentId] ??
            dsheets[i].collapseGroups.groups[cgsk].parentId

          const childrenIds = dsheets[i].collapseGroups.groups[cgsk].childrenIds
          dsheets[i].collapseGroups.groups[cgsk].childrenIds =
            childrenIds === null
              ? childrenIds
              : childrenIds.map((id) => (id in refMap ? refMap[id] : id))

          // the group key itself
          if (cgsk in refMap) {
            const { [cgsk]: group, ...rest } = dsheets[i].collapseGroups.groups
            dsheets[i].collapseGroups.groups = {
              [refMap[cgsk]]: {
                ...group,
                id: refMap[cgsk],
                parentId: group.parentId in refMap ? refMap[group.parentId] : group.parentId,
                childrenIds: dsheets[i].collapseGroups.groups[cgsk].childrenIds
              },
              ...rest
            }
          }
        }
      }

      for (let sheetIndex = 0; sheetIndex < dsheets.length; sheetIndex++) {
        ;({ dataSheets: dsheets } = rebuildCollapseGroupsFromParentChild(
          { dataSheets: dsheets, rowsMap: rm },
          { sheetIndex }
        ))
      }
      rowsMap.value = rm
      dataSheets.value = dsheets
      cellData.value = cd

      parseCellData()
      $this.$emit('rereferencedRows', { rows: rerefedRows })
    }

    const getCellValue = (col, row) => getCellData(col, row).raw

    // const turnIntoItem = () => {};

    const determineSetting = (setting, args = {}) => {
      if (typeof setting === 'function') return setting(args)

      return !!setting
    }

    const isCellReadOnly = (col, row) => {
      const refId = rowsMap.value[row]?.columns?.[col]
      if (!refId) return false
      const cell = cellData.value[refId]
      const sheet = cell.sheet
      const colDef = getColDef(col, sheet)
      return determineSetting(colDef.readOnly, {
        value: getCellValue(col, row),
        cellData: cell,
        rowData: getRowData(row),
        cell: [col, row]
      })
    }

    const isCellDisabled = (col, row) => {
      const refId = rowsMap.value[row]?.columns?.[col]
      if (!refId) return false
      const cell = cellData.value[refId]
      const sheet = cell.sheet
      const colDef = getColDef(col, sheet)
      return determineSetting(colDef.disabled, {
        value: getCellValue(col, row),
        cellData: cell,
        rowData: getRowData(row),
        cell: [col, row]
      })
    }

    return {
      isCellDisabled,
      isCellReadOnly,
      loadData,
      ingressData,

      dataSheets,
      resetOrder,

      cellData,
      cellDefault,
      refIdToColRow,
      getCellData,
      getRefId,
      getCellType,
      getCellObjectType,
      setCellValues,
      setCellValue,
      parseCellData,
      calculateTotals,

      getColFormatting,
      getRowFormatting,
      formatCellValue,
      getForceUpdate,

      rowCount,
      colCount,

      addFetchedName,

      rowsMap,
      getRowData,
      getRowId,
      isRowLastOfGroup,
      isRowFirstOfGroup,
      isRowLastOfSheet,
      isRowFirstOfSheet,
      getColDef,
      getColIndexByField,
      getColIndexesByField,

      groupMap,
      getRowGroup,

      getSheet,
      getRowSheet,

      sortZa,
      sortAz,

      getSuperHeaders,
      hasSuperHeaders,
      collapsedSuperHeaders,
      superHeadersHidden,
      addNewRow,
      moveRowsTo,
      addRow,
      getRowFromId,
      getRowsFromIds,
      getRowIndexes,
      isCollapseGroupParent,
      addToParent,
      deleteRows,
      duplicateRows,
      turnIntoAssembly,
      rereference,
      isRowParent,
      getRowParent,
      initiateComputedCellValues,
      getChildren,
      fetchedNames,
      columnDependencies,
      sheetColsWithComputed,
      affectComputedChanges,

      collapseSuperHeader,
      uncollapseSuperHeader,
      getCellValue,
      loaded,
      addNewCell,
      getRowCollapseGroupKeys,
      fetchNames,

      triggerComputeDependants,
      triggerComputeDependantsByField,
      affectComputedChangesByField,
      addSourceRows,
      addRowData,
      createParentFromRow,
      rebuildCollapseGroupsFromParentChild
    }
  }
}
