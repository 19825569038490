<template>
  <div class="card-list-item list-group-item card-list-item-heading">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardListItem'
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.card-list-item-heading {
  background: $cool-gray-200;
  font-weight: 500;
  font-size: 1.05em;
  line-height: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    flex: 1 100%;
  }

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &.secondary {
    background: var(--secondary) !important;
    color: $flame-white !important;
  }
  &.primary {
    background: $primary !important;
    color: $flame-white !important;
  }
  &.warning {
    background: $level-yellow !important;
    color: $pitch-black !important;
  }
  &.info {
    background: $blue-print-700 !important;
    color: $flame-white !important;
  }
  &.dark {
    background: $pitch-black !important;
    color: $flame-white !important;
  }
  &.light {
    background: $flame-white !important;
    color: $cool-gray-400 !important;
  }
  &.danger {
    background: $deep-red-800 !important;
    color: $flame-white !important;
  }
}
</style>
