import { ref, computed } from 'vue'

export default function useFilters(sections) {
  const selectedFilter = ref('All')

  const filteredItemSections = computed(() => {
    if (selectedFilter.value === 'All') return sections.value
    return sections.value.filter((section) => section.title === selectedFilter.value)
  })

  function handleSelectedFilter(filter) {
    selectedFilter.value = filter
  }

  return {
    selectedFilter,
    filteredItemSections,
    handleSelectedFilter
  }
}
