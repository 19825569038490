<template>
  <div>
    <MiniModal
      ref="modal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <h3 class="text-center mb-3">Account Profile</h3>
      <CounterpartyOnboarding
        @close="onClose"
        @success="onSuccess"
        @owner-onboarding="onShowOwnerOnboarding"
        :owners="owners"
        service="wisetack"
      />
    </MiniModal>
    <MiniModal
      ref="ownerModal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <OwnerOnboarding
        @close="() => $refs.ownerModal.close()"
        @success="onOwnerOnboardingSuccess"
        :owner="owner"
      />
    </MiniModal>
  </div>
</template>
<script>
import CounterpartyOnboarding from '../payments/counterparty/onboarding/CounterpartyOnboarding.vue'
import OwnerOnboarding from '../payments/counterparty/onboarding/OwnerOnboarding.vue'
import OwnerMixin from '../payments/counterparty/OwnerMixin'
import eventBus from '../../eventBus'

export default {
  name: 'ConsumerFinancingOnboarding',
  methods: {
    onToggleConsumerFinanceOnboarding() {
      this.$refs.modal.open()
    }
  },
  mounted() {
    eventBus.$on('toggleConsumerFinanceOnboarding', this.onToggleConsumerFinanceOnboarding)
  },
  beforeUnmount() {
    eventBus.$off('toggleConsumerFinanceOnboarding', this.onToggleConsumerFinanceOnboarding)
  },
  components: {
    CounterpartyOnboarding,
    OwnerOnboarding
  },
  mixins: [OwnerMixin]
}
</script>
