import Registry from '../Registry.js'
import UnitOfMeasure from './UnitOfMeasure.js'
import Stage from '@/SuggestionEngine/CostType/Stage.js'
import TradeType from '@/SuggestionEngine/CostType/TradeType.js'

Registry.register(UnitOfMeasure)
Registry.register(Stage)
Registry.register(TradeType)

export default {
  ...Registry.output(Registry)
}
