<template>
  <transition name="fade" :class="classes" :tag="tag">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'transition-fade',
  props: {
    classes: {
      required: false
    },
    tag: {
      type: String,
      required: false,
      default() {
        return 'div'
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
