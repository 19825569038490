const services = ({ store }) => {
  const features = [
    {
      title: 'Home',
      icon: 'home',
      to: 'home'
    },
    {
      title: 'Edit Profile',
      icon: 'pencil',
      to: 'profile'
    }
  ]
  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Logout',
        command: () => store.dispatch('to', 'logout')
      }
    ]
  }

  return {
    features,
    bottomDropDown
  }
}

export { services }
