<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    size="sm"
    :width="800"
    ref="modal"
    :scrollable="false"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #body>
      <div class="flex flex-col justify-center items-center min-h-96 text-center px-20 gap-8">
        <h2 class="font-sans text-4xl !font-medium">
          {{ $t('Welcome to your interactive estimate') }}
        </h2>
        <CostItemFlag type="Selections" />
        <p>
          {{
            $t(
              'In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.'
            )
          }}
        </p>
        <CostItemFlag type="Optional" />
        <p>
          {{
            $t(
              "There are also items marked 'Optional', which you can choose to add to your project if you wish."
            )
          }}
        </p>
        <p>{{ $t("Thanks, and enjoy your experience using Bolster's Interactive Estimates!") }}</p>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center items-center">
        <Btn class="w-52" severity="tertiary" @click="closeOnboarding">
          <span class="p-2">{{ $t('Dismiss') }}</span>
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { useStore } from 'vuex'
import CostItemFlag from '@/components/quote/presentation/CostItemFlag.vue'
import Btn from '@/components/ui/Btn.vue'
import TranslationMixin from '@/mixins/TranslationMixin'

export default {
  name: 'PresentationOnboarding',
  mixins: [TranslationMixin],
  components: { Btn, CostItemFlag },
  props: {
    type: {
      default: 'quote'
    },
    modal: {
      type: Object,
      default: null
    }
  },
  setup() {
    const store = useStore()

    const closeOnboarding = async () => {
      await store.dispatch('modal/closeAll')
    }

    return {
      closeOnboarding
    }
  }
}
</script>
