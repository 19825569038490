import { toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import useTranslation from './Translation'

export const initialState = {
  token: '',
  fees: {},
  settings: {},
  checkout: {
    paymentMethod: null,
    browserInfo: null,
    invoice: null,
    isValid: false,
    paymentMethodVariations: null
  },
  loading: 0,
  isValid: false,
  doesPayeeCoverBolsterFee: true,
  doesPayeeCoverCreditCardFee: false,
  paymentResponse: null
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  const { l } = useTranslation()
  const store = useStore()

  const fetchPaymentDetails = async (amount, invoiceId = null, variants = null) => {
    try {
      const response = await store.dispatch('ajax', {
        path: '/Payfac/getPaymentDetails',
        data: {
          invoice_id: invoiceId,
          amount,
          variants
        }
      })

      if (!response || response.error || !response.payload) throw new Error()
      const { payload } = response
      const {
        counterpartySettings,
        creditCardFeeOwed,
        specialtyCreditCardFeeOwed,
        otherFeeOwed,
        specialtyCards,
        linkToken,
        variantFees
      } = payload
      context.fees = {
        card: creditCardFeeOwed,
        standardCards: creditCardFeeOwed,
        specialtyCards: specialtyCreditCardFeeOwed,
        ach: otherFeeOwed,
        variantFees
      }
      const settings = Object.keys(counterpartySettings).reduce((acc, k) => {
        acc[k] = parseInt(counterpartySettings[k], 10)
        return acc
      }, {})
      context.settings = settings
      context.specialtyCards = specialtyCards
      context.token = linkToken
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || l('Could not fetch payment details. Please refresh.'),
        error: true
      })
    }
  }

  const makePaymentRequest = (data = {}) =>
    store.dispatch('ajax', {
      path: '/invoice/payInvoice',
      data
    })

  const makeItemizedPaymentRequest = (data = {}) =>
    store.dispatch('ajax', {
      path: '/invoice/itemizedPayment',
      data
    })

  const makeItemizedInvoiceRequest = (data = {}) =>
    store.dispatch('ajax', {
      path: '/invoice/createItemizedInvoice',
      data
    })

  const makePayment = async (invoiceId, callback = null) => {
    context.loading = 1
    if (!invoiceId || !context.checkout) throw new Error()
    const response = await makePaymentRequest({
      invoice_id: invoiceId,
      checkout: context.checkout.value
    })
    const { payload } = response
    context.paymentResponse = response
    if (callback) callback(payload)
    if (response.error) throw new Error(response.message)
    context.loading = 0
  }

  const transformPaymentMethod = ({ paymentMethod }) => {
    if (paymentMethod.type === 'ach') {
      const { bankAccountNumber, bankLocationId, ownerName, type } = paymentMethod
      return {
        bankAccountNumber,
        bankLocationId,
        ownerName,
        type
      }
    }

    return { ...paymentMethod }
  }

  const makeItemizedPayment = async (callback = null) => {
    context.loading = 1
    if (!context.checkout) throw new Error()

    const { paymentMethod, ...rest } = context.checkout

    const transformedPaymentMethod = transformPaymentMethod({ paymentMethod })

    const checkout = {
      paymentMethod: transformedPaymentMethod,
      ...rest
    }

    const response = await makeItemizedPaymentRequest({
      checkout
    })
    const { payload } = response
    context.paymentResponse = response
    if (callback) callback(payload)
    if (response.error) throw new Error(response.message)
    context.loading = 0
    return payload
  }

  const makeItemizedInvoice = async () => {
    if (!context.checkout) throw new Error()
    context.loading = 1
    const checkout = context.checkout
    const response = await makeItemizedInvoiceRequest({
      checkout
    })
    context.loading = 0
    if (response.error) throw new Error(response.message)
    const { payload } = response
    return payload
  }

  const fetchPaymentSettings = async (invoiceId) => {
    try {
      const response = await store.dispatch('ajax', {
        path: '/invoice/getPaymentSettings',
        data: {
          invoice_id: invoiceId
        }
      })
      if (!response || response.error || !response.payload || response.payload.error) {
        throw new Error()
      }
      const { payload } = response
      const { doesPayeeCoverBolsterFee: coverBolsterFee, doesPayeeCoverCreditCardFee: coverCCFee } =
        payload

      context.doesPayeeCoverBolsterFee = Boolean(coverBolsterFee)
      context.doesPayeeCoverCreditCardFee = Boolean(coverCCFee)
    } catch (e) {
      store.dispatch('alert', {
        message: e.message || l('Could not fetch payment settings. Please refresh.'),
        error: true
      })
    }
  }

  const resetPayment = () =>
    (context = reactive({
      ...initialState
    }))

  return {
    ...toRefs(context),
    fetchPaymentDetails,
    fetchPaymentSettings,
    makePayment,
    makeItemizedPayment,
    makeItemizedInvoice,
    makeItemizedInvoiceRequest,
    makePaymentRequest,
    makeItemizedPaymentRequest,
    resetPayment
  }
}
