<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="sm"
    :mask="true"
    :isDirty="bodyIsDirty"
    :clickAway="true"
    :closeable="true"
    :expandable="false"
    :scrollable="true"
  >
    <template #body>
      <Dimension :key="modal.uid" :object="object" ref="body" />
    </template>
    <template #footer>
      <btn-group class="block">
        <Btn class="default" :action="close"> Cancel </Btn>
        <Btn class="info" :action="saveAndClose"> Save </Btn>
      </btn-group>
    </template>
  </Modal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
import Dimension from '../bodies/Dimension.vue'

export default {
  name: 'DimensionModal',
  components: { Dimension },
  mixins: [ObjectModalMixin],
  props: {
    go: {
      default: false
    }
  }
}
</script>

<style scoped></style>
