import languages from './index';

export default {
  data() {
    const userLang = (this.$store.state.session.user
      && this.$store.state.session.user.localization_language_code) || 'en';
    const userCountry = (this.$store.state.session.user
      && this.$store.state.session.user.localization_country_code) || 'US';

    return {
      languageSelected: `${userLang}_${userCountry}`,
      languageOptions: Object.keys(languages)
        .reduce((acc, lang) => ([
          ...acc,
          {
            name: languages[lang].languageName,
            value: lang,
          },
        ]), []),
    };
  },

  methods: {
    l(phrase) {
      if (this.languageSelected in languages
        && phrase in languages[this.languageSelected]) {
        return languages[this.languageSelected][phrase];
      }

      return phrase;
    },
  },
};
