<template>
  <div
    class="section-reveal--container"
    :class="{ shown, partial }"
    @mouseover="partial = !shown ? 1 : 0"
    @mouseout="partial = 0"
  >
    <div class="section-reveal--inner">
      <div class="section-reveal--content">
        <slot></slot>
      </div>

      <div class="section-reveal--shadow"></div>

      <div class="section-reveal--click" @click.stop.prevent.capture="show()"></div>
    </div>

    <btn
      class="sm round"
      v-if="!shown"
      style="position: absolute; bottom: -1.5em; z-index: 100; width: 10em; left: calc(50% - 5em)"
      :action="show"
    >
      see all
    </btn>
  </div>
</template>

<script>
/**
 * MODIFIER CLASSES
 * -.muted
 */
export default {
  name: 'Reveal',

  props: {
    startShown: {
      default: false
    }
  },

  watch: {
    startShown(s) {
      if (!this.explicit) {
        this.shown = s
      }
    }
  },

  data() {
    return {
      shown: this.startShown,
      partial: 0,
      explicit: 0
    }
  },

  methods: {
    show() {
      this.explicit = 1
      this.shown = 1
    },

    hide() {
      this.shown = 0
    },

    toggle() {
      if (this.shown) return this.hide()
      return this.show()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.section-reveal--container {
  position: relative;
  width: 100%;
  transition: all 0.1s ease-out;

  .section-reveal--inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0 0 70% 70%;
    // border: 1px solid red;
    max-height: 10em;
    will-change: height;
    transition: all 0.1s ease-out;
    overflow: hidden !important;
    z-index: 1;
    position: relative;
    width: 100%;
  }

  .section-reveal--shadow {
    position: absolute;
    height: 5em;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px -3em 1em -2em rgba($pitch-black, 0.2);
    border-radius: 0 0 70% 70%;
    pointer-events: none;
    touch-action: none;
    transition: all 0.1s ease-out;
  }

  .section-reveal--content {
    z-index: 0;
    width: calc(100% - 4em);
    font-size: 0.8em;
    transition: all 0.1s ease-out;
  }

  .section-reveal--click {
    background: transparent;
    position: absolute;
    height: 3em;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 70% 70%;
    cursor: pointer;
  }

  &.partial {
    .section-reveal--inner {
      height: 15em;
      max-height: 15em;
    }
    .section-reveal--content {
      // width: calc(100% - 2em);
      // font-size: 0.85em;
    }
    .section-reveal--shadow {
      box-shadow: inset 0px -3em 1em -2em rgba($pitch-black, 0.1);
    }
  }

  &.shown {
    .section-reveal--inner {
      max-height: unset;
      overflow: visible !important;
    }
    .section-reveal--content {
      width: 100%;
      font-size: 1em;
    }
    .section-reveal--shadow {
      opacity: 0;
    }
    .section-reveal--click {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
    }
  }
}
</style>
