<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
  >
    <template #body>
      <cost-item ref="body" store="Quote" :refId="object.refId" type="cost_item" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </Modal>
</template>

<script>
import CostItem from '../bodies/CostItem.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'CostItemModal',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { CostItem },
  props: {
    type: {
      default: 'cost_item'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
