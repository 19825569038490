import { computed, ref } from 'vue'
import DrawUtilities from './DrawUtilities'

const { createCanvas } = DrawUtilities

export default {
  useDrawCache(args) {
    const { pixelRatio, canvasWidth, canvasHeight } = args

    const buffer = ref(1)
    const scaledBuffer = computed(() => buffer.value * pixelRatio.value)
    const offscreenCache = ref({})

    const getOffscreen = (id = 'default', width = null, height = null) => {
      const created = id in offscreenCache.value

      if (
        !created ||
        (width && offscreenCache.value[id].width !== width) ||
        (height && offscreenCache.value[id].height !== height)
      ) {
        width = (width || canvasWidth.value) * (pixelRatio.value > 1 ? pixelRatio.value : 1)
        height = (height || canvasHeight.value) * (pixelRatio.value > 1 ? pixelRatio.value : 1)

        const { ctx, canvas } = createCanvas(width, height, pixelRatio.value)
        offscreenCache.value[id] = { canvas, ctx, height, width }

        return {
          ...offscreenCache.value[id],
          cached: false
        }
      }

      return {
        ...offscreenCache.value[id],
        cached: true
      }
    }

    const applyOrDraw = (
      name,
      ctx,
      [sx = 0, sy = 0, swidthUnscaled = null, sheightUnscaled = null] = [],
      drawMethod,
      drawParams = [],
      [dxProvided = 0, dyProvided = 0] = []
    ) => {
      const { canvas, width, height } = getFragmentOrDraw(
        name,
        swidthUnscaled,
        sheightUnscaled,
        drawMethod,
        drawParams
      )

      applyOffscreen(
        canvas,
        ctx,
        [
          sx * pixelRatio.value,
          sy * pixelRatio.value,
          width / pixelRatio.value - sx,
          height / pixelRatio.value - sy
        ],
        [
          dxProvided - scaledBuffer.value,
          dyProvided - scaledBuffer.value,
          width / pixelRatio.value - sx,
          height / pixelRatio.value - sy
        ]
      )

      return {
        height,
        width,
        canvas
      }
    }

    const clearFragments = (regex = null) => {
      Object.keys(offscreenCache.value).forEach((name) => {
        const cache = offscreenCache.value[name]

        if (!regex || new RegExp(regex).test(name)) {
          cache.ctx.clearRect(0, 0, cache.width, cache.height)
          cache.canvas.width = 0
          cache.canvas.height = 0
          delete offscreenCache.value[name]
        }
      })
    }

    const getFragmentOrDraw = (name, width, height, drawMethod, drawParams = []) => {
      if (isFragmentCached(name)) return getOffscreen(name)

      // drawParams can be a function so that expensive calls can be made only when a draw actually happens
      return drawFragmentIntoCache(name, width, height, drawMethod, drawParams)
    }

    const isFragmentCached = (name) => name in offscreenCache.value

    const downloadFragment = (name) => {
      const { canvas } = getOffscreen(name)

      canvas.convertToBlob().then((blob) => {
        // Create a new URL for the blob
        const url = URL.createObjectURL(blob)

        // Create an anchor element
        const a = document.createElement('a')

        // Set the download URL and the filename
        a.href = url
        a.download = `${name}.png`

        // Append the anchor to the document, trigger click, and remove it afterwards
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        // Optional: Release the blob URL to free up resources
        URL.revokeObjectURL(url)
      })
    }
    window.downloadFragment = downloadFragment

    const drawFragmentIntoCache = (name, width, height, drawMethod, rdp = []) => {
      const bufferSpace = buffer.value
      const cw = width + bufferSpace * 2
      const ch = height + bufferSpace * 2
      const { ctx } = getOffscreen(name, cw, ch)

      ctx.clearRect(0, 0, cw, ch)

      const drawParams = (typeof rdp === 'function' ? rdp() : rdp) ?? []
      drawMethod(ctx, [bufferSpace, bufferSpace, width, height], ...drawParams)

      return getOffscreen(name)
    }

    const applyOffscreen = (
      os,
      domContext,
      // Source canvas
      [
        sx = 0,
        sy = 0,
        swidthUnscaled = canvasWidth.value,
        sheightUnscaled = canvasHeight.value
      ] = [],
      // Destination canvas
      [dxProvided = 0, dyProvided = 0, dwidthProvided = null, dheightProvided = null] = []
    ) => {
      const swidth = swidthUnscaled * pixelRatio.value
      const sheight = sheightUnscaled * pixelRatio.value
      const dwidth = dwidthProvided || swidthUnscaled
      const dheight = dheightProvided || sheightUnscaled
      const dx = dxProvided
      const dy = dyProvided

      domContext.drawImage(os, sx, sy, swidth, sheight, dx, dy, dwidth, dheight)
    }

    return {
      applyOffscreen,
      applyOrDraw,
      clearFragments,
      buffer,
      scaledBuffer,
      downloadFragment
    }
  }
}
