<template>
  <Message severity="error" :icon="icon" :closable="closable" :life="life">
    <slot></slot>
  </Message>
</template>

<script>
import Message from '@/components/ui/message/Message.vue'

export default {
  name: 'warning',
  props: {
    icon: {
      type: [String, Boolean],
      default: 'exclamation-circle'
    },
    life: {
      type: Number,
      default: null
    },
    highlight: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Message
  },
  mounted() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
