<template>
  <CardSection>
    <CardList>
      <MetaListItem
        v-for="(meta, index) in valueLocal"
        :key="`${uid}${index}`"
        :value="meta"
        :class="classes"
        :fieldClasses="fieldClasses"
        :show-second="showSecond"
        :firstPlaceholder="firstPlaceholder"
        @remove="() => remove(index)"
        @input="(meta) => changeMeta(index, meta)"
      />
    </CardList>
    <Btn rounded class="ml-2 -mt-2" @click="add" v-tooltip="'Add value'">
      <font-awesome-icon icon="plus" />
    </Btn>
  </CardSection>
</template>

<script>
import MetaListItem from './MetaListItem.vue'

export default {
  name: 'MetaList',
  emits: ['input'],
  data() {
    return {
      // must be _.imm() because it is an array,
      // if we didn't immute it, it would retain the reference
      // to the exact same object instead of a copy and this.value === this.valueLocal
      // would always be true as well as
      // JSON.stringify(this.valueLocal) === JSON.stringify(this.value), thus
      // disrupting emissions
      valueLocal: _.imm(this.value),
      uid: c.uniqueId(),
      fullyEmitted: true
    }
  },
  watch: {
    /**
     * When  valueLocal changes, emit the change on a throttle
     * @param v
     */
    valueLocal(v) {
      this.fullyEmitted = false
      c.throttle(
        () => {
          this.fullyEmitted = true
          const nonEmpty = v[0]?.[0] ?? v[0]?.[1] ?? null
          if (nonEmpty && !c.jsonEquals(v, this.value)) {
            this.$emit('input', _.imm(v))
          }
        },
        { key: this.uid, delay: 200 }
      )
    },

    /**
     * When the provided value changes, integrate the change, on a throttle
     * with a delay greater than valueLocal so that it does not disrupt active
     * typing or editing
     * @param v
     */
    value(v) {
      c.throttle(
        () => {
          if (!c.jsonEquals(v, this.valueLocal) && this.fullyEmitted) {
            this.valueLocal = _.imm(v)
          }
        },
        { key: this.uid, delay: 800 }
      )
    }
  },
  computed: {
    order: {
      get() {
        return this.valueLocal
      },
      set(a) {
        this.valueLocal = a
      }
    }
  },
  methods: {
    /**
     * Make a change to a key or value for a property
     * @param index
     * @param meta
     */
    changeMeta(index, meta) {
      this.valueLocal.splice(index, 1, meta)
    },

    /**
     * Remove an item
     * @param index
     */
    remove(index) {
      this.valueLocal.splice(index, 1)
    },

    /**
     * Add a blank meta to the end
     */
    add() {
      this.valueLocal = [...this.valueLocal, ['', '']]
    }
  },
  props: {
    /**
     * array of arrays: [['key', 'value], ['key2', 'value2'], ...]
     */
    value: {
      default: () => [],
      required: true
    },
    showSecond: {
      default: true
    },
    firstPlaceholder: {
      default: 'Property name'
    },
    classes: {
      type: String,
      default: ''
    },
    fieldClasses: {
      type: String,
      default: ''
    }
  },
  mounted() {
    // If there isn't one, add one so there is one blank one
    if (!this.value.length) this.add()
  },
  components: {
    MetaListItem
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
div.add-entry {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: rgba($matcha-500, 0.4);
  border: 1px solid $matcha-500;
  border-top: 0px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 5em;
  font-weight: bold;
  margin-left: 2em;
  padding: 0px;
  a {
    color: $matcha-500;
    font-size: 1.2em;
    display: block;
    height: 100%;
    width: 100%;
    padding: 5px;
    text-align: center;
  }
  &:hover {
    background: rgba($matcha-500, 0.9);
    color: $flame-white;
  }
}
.meta-list-container {
  overflow: hidden;
  border: 1px solid $cool-gray-500;
  border-radius: 5px;
  background: $flame-white;
}
</style>
