<template>
  <card v-if="!disabled" class="button addon-container-classic up" @click="preview">
    <spinner :loading="1" v-if="loading" />
    <div class="addon-outer-classic" v-else>
      <div class="addon-name-classic">
        {{ $f.truncate(name, 80) }}
      </div>
      <div class="addon-inner-classic">
        <div class="addon-info-classic">
          <div class="addon-desc-classic" v-if="desc">{{ $f.truncate(desc, 80) }}</div>
          <div class="addon-price-classic mt-2">
            <template v-if="isCurrent">
              {{ l('selected') }}
            </template>
            <template v-else>
              {{ priceDifference > 0 ? 'add ' : 'save ' }}{{ $f.currency(priceDifference) }}
            </template>
          </div>
        </div>
        <div class="addon-pic-classic" v-if="picFile.length || editable">
          <file-list
            v-if="editable && !loading"
            :allowCreate="editable && !picFile.length"
            :allowAdd="editable && !picFile.length"
            :allowUpload="editable && !picFile.length"
            :allowRemove="editable"
            @input="(all) => fileAdded(all)"
            :value="picFile"
          />
          <img :src="pic" v-else />
        </div>

        <MiniModal
          classes="item-preview"
          size="md"
          :width="600"
          maxWidth="600px"
          v-if="previewRequested"
          ref="interstitial"
        >
          <Container class="images-container" v-if="urls.length">
            <div
              :style="{
                backgroundImage: `url(${picViewing})`
              }"
              class="image-preview"
              @click="() => $refs.gallery.preview(picViewing)"
            ></div>
          </Container>

          <Container
            class="content-row"
            :style="{
              marginTop: urls.length ? '10em !important' : '0'
            }"
          >
            <Container class="content-container">
              <Card style="border-radius: 1em !important">
                <span class="item-name">{{ name }}</span>
                <span class="item-desc" v-text="desc" v-if="desc"></span>

                <template v-if="properties.length && properties[0] && properties[0][0]">
                  <span class="item-heading">Features</span>
                  <ul class="item-props">
                    <li v-for="(prop, index) in visibleProperties" :key="index">
                      <strong>
                        {{ typeof prop[0] === 'string' ? prop[0] : '' }}
                      </strong>
                      {{
                        prop.length > 1 && prop[1] && typeof prop[1] === 'string'
                          ? `: ${prop[1]}`
                          : ''
                      }}
                    </li>
                  </ul>
                </template>

                <Container class="center">
                  <small>
                    <Container class="center tight">
                      <div v-if="numberChose > 0" class="chosen-count text-dark">
                        <Btn rounded class="more px-0 p-2 default mr-1">{{ numberChose }}</Btn>
                        {{ l('other customers selected this recently') }}.
                      </div>

                      <btn-group>
                        <Btn
                          class="lg default"
                          @click="
                            () => {
                              $refs.interstitial.close()
                              get()
                            }
                          "
                        >
                          {{ priceDifference >= 0 ? 'add' : 'save' }}
                          {{ $f.currency(Math.abs(priceDifference)) }}
                        </Btn>
                        <Btn
                          class="info"
                          @click="
                            () => {
                              $refs.interstitial.close()
                              get()
                            }
                          "
                        >
                          {{ l('Make this selection') }}!
                        </Btn>
                      </btn-group>
                    </Container>
                  </small>
                </Container>

                <div class="item-disclaimers">
                  <small
                    >{{
                      l(
                        'Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project'
                      )
                    }}.</small
                  >
                </div>
              </Card>
            </Container>

            <Container class="gallery-container" v-if="urls.length">
              <ImageGallery
                ref="gallery"
                @viewing="(url) => (picViewing = url)"
                :thumbs="thumbs"
                :urls="urls"
              />
            </Container>
          </Container>

          <Container class="action-row">
            <div v-if="numberChose > 0" class="chosen-count">
              <Btn rounded class="more px-0 p-2 light mr-1">{{ numberChose }}</Btn>
              {{ l('other customers selected this recently') }}.
            </div>

            <btn-group>
              <Btn
                class="lg light"
                @click="
                  () => {
                    $refs.interstitial.close()
                    get()
                  }
                "
              >
                {{ priceDifference >= 0 ? 'add' : 'save' }}
                {{ $f.currency(Math.abs(priceDifference)) }}
              </Btn>
              <Btn
                class="info"
                @click="
                  () => {
                    $refs.interstitial.close()
                    get()
                  }
                "
              >
                {{ l('Make this selection') }}!
              </Btn>
            </btn-group>
          </Container>
        </MiniModal>
      </div>
    </div>
  </card>
</template>

<script>
import AddonItemMixin from '../addons/AddonItemMixin-classic'
import ImageGallery from '../../items/ImageGallery.vue'
import TranslationMixin from './languages/TranslationMixin'

/**
 * Emits:
 * -addon (newAddon object)
 */
export default {
  name: 'QuotePresentationAddonItem-classic',
  components: { ImageGallery },
  mixins: [AddonItemMixin, TranslationMixin],
  emits: ['numberChose'],
  data() {
    return {
      previewRequested: 0,
      picViewing: null,
      numberChose: 1
    }
  },
  watch: {
    filesList(list) {
      if (list.length) {
        this.picViewing = list[0]
      }
    },
    numberChose(num) {
      this.$emit('numberChose', num)
    },
    auditedObject() {
      c.throttle(
        () => {
          this.getCount()
        },
        { key: this.auditedObject.cost_type_id || this.auditedObject.assembly_id }
      )
    }
  },
  computed: {
    $() {
      return this.$store.getters.$
    },
    $a() {
      return this.$store.getters.$a
    },
    $b() {
      return this.$store.getters.$b
    },
    urls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.url))
    },
    thumbs() {
      return c.uniq(this.filesListWithUrl.map((f) => f.thumb))
    },
    filesListWithUrl() {
      return this.filesList.map((id) => ({
        id,
        url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
        thumb: c.link(
          `file/pic/thumb/${id}`,
          { size: 200, square: true },
          true,
          _.getStorage('scope')
        )
      }))
    },
    filesList() {
      return _.uniq(this.object.file_ids)
    },
    picFileUrl() {
      if (this.picViewing)
        return c.link(`file/view/${this.picViewing}`, { max: 500 }, true, _.getStorage('scope'))
      return false
    },
    properties() {
      return this.object.aoProperties || []
    },
    visibleProperties() {
      return this.properties.filter((prop) => {
        return (
          prop.length &&
          prop[0] &&
          (typeof prop[0] === 'string' ||
            (prop.length > 1 && prop[1] && typeof prop[1] === 'string'))
        )
      })
    }
  },
  methods: {
    async getCount() {
      this.numberChose = 0
    },
    preview() {
      this.previewRequested = 1
      this.$nextTick(() => {
        this.$refs.interstitial.open()
        this.getCount()
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.addon-container-classic {
  position: relative;
  line-height: 1.1;
  flex: 1 1 auto;
  margin: 0 0.15em;
  //-webkit-backface-visibility: hidden;
  //-moz-backface-visibility: hidden;
  //-ms-backface-visibility: hidden;
  //backface-visibility: hidden;
  //
  //-webkit-transform-style: preserve-3d;
  //-moz-transform-style: preserve-3d;
  //-o-transform-style: preserve-3d;
  //-ms-transform-style: preserve-3d;
  //transform-style: preserve-3d;
  //
  //transform-origin: center;
  //$perspective: 30em;
  //perspective: $perspective;
  //-webkit-perspective: $perspective;
  //-moz-perspective: $perspective;
  //-o-perspective: $perspective;
  //-ms-perspective: $perspective;

  .addon-inner-classic {
    max-height: 100%;
    flex: 1 100%;
    background: rgba($flame-white, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: hidden;
  }
  .addon-info-classic {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .addon-name-classic {
    margin-top: 0.5em;
    font-size: 1.2em;
    color: $pitch-black;
    font-weight: bold;
    overflow: hidden;
    line-height: 1.1;
    margin-bottom: 1em;
    flex: 0 auto;
    overflow: visible;
  }
  .addon-type-classic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: auto;
    margin-top: 0.5em;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-size: 0.9em;
      font-weight: normal;
      opacity: 0.8;
      background: $cool-gray-500;
      color: $cool-gray-800;

      border-radius: 5px;
      line-height: 1;
      height: 1.75em;
      padding: 0 1em;
      .fa,
      .glyphicon {
        margin-right: 0.25em;
        top: 0;
      }
    }
  }
  .addon-desc-classic {
    font-size: 1em;
    color: $cool-gray-600;
    overflow: hidden;
    white-space: pre-wrap;
    line-height: 1.2;
    font-weight: normal;
  }
  .addon-price-classic {
    border-radius: 2em;
    padding: 0.25em 1em;
    background: $cool-gray-400;
    color: $pitch-black;
    font-size: 1em;
    font-weight: bold;
  }
  .addon-pic-classic {
    margin-left: auto;
    padding-left: 1em;
    img {
      max-height: 6em;
      max-width: 6em;
      border-radius: 5px;
    }
    mix-blend-mode: multiply;
  }

  & + .addon-container-classic {
    margin-left: 1em !important;
  }
}
.quote-pres--sandbox {
  &:not(.touch) .addon-container-classic:hover {
    // transform: translateY(-3px);
    -moz-transform: none;
    -ms-transform: none;
    box-shadow: 0 0 0.1em 0 rgba(61, 119, 180, 0.1);
  }
}

img.interstitial-image {
  border-radius: 5px;
  max-width: 250px;
  width: 250px;
}
.interstitial {
  .image-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;
    .image-selector {
      height: 4em;
      width: 4em;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover {
        transform: translateY(-5px);
        box-shadow: $shadow-more;
      }
    }
    .image-selector + .image-selector {
      margin-left: 0.25em;
    }
  }
  .interstitial-column {
    min-width: 200px;
    width: 50%;
  }
  .interstitial-column + .interstitial-column {
    margin-left: 1em;
  }
}
</style>
<style lang="scss" rel="stylesheet/scss">
.modal.item-preview {
  .modal-content {
    background: rgba($pitch-black, 1) !important;
  }
  .modal-body {
    padding: 0 0 0 0;
    padding-left: 0;
    position: relative;

    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    align-items: flex-start;

    > .scroll-container--container {
      padding: 0;
      > .scroll-container--inner {
        padding: 0;
      }
    }

    .image-preview {
      margin: 0 !important;
      width: 100% !important;
      height: 50em !important;
      cursor: pointer;
      opacity: 0.5;
      max-height: 25em;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      filter: saturate(0);
    }

    .images-container {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 100%;
      min-width: 100%;
      flex-basis: 100%;
      position: absolute;
      top: 0;
      margin-left: -3em !important;
    }

    .content-row {
      display: flex;
      flex-direction: row-reverse;
    }

    .content-container {
      width: unset !important;
      flex: 1 60% !important;
      margin: 0;
      margin-left: auto;
      padding: 1em !important;
      > .card {
        margin: 0;
        font-size: 0.9em;
      }

      .item-name {
        font-size: 1.3em;
        color: $blue-print-700;
        display: block;
        font-weight: bold;
      }
      .item-desc {
        font-size: 1.1em;
        color: $cool-gray-600;
        display: block;
        white-space: pre-wrap;
        font-weight: 500;
      }
      .item-heading {
        font-size: 1em;
        color: $cool-gray-600;
        display: block;
      }
      .item-props {
        font-size: 1em;
        display: block;
        padding-left: 1.5em;
        li {
          font-weight: normal;
          color: $cool-gray-800;
        }
      }
      .item-disclaimers {
        margin-top: 1em;
        font-size: 1em;
        color: $cool-gray-600;
      }
    }
    .gallery-container {
      padding: 1em !important;
      margin-top: 15em !important;
      max-width: 40% !important;
      z-index: 10;
    }

    .action-row {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      margin-top: 0 !important;
      padding-top: 3em !important;
      /*row-gap: 0.5rem !important;*/
      > :not(:first-child) {
        margin-top: 0.5rem !important;
      }
    }

    .chosen-count {
      color: $flame-white;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
    }
  }
}

#app.small-format {
  .modal.item-preview {
    .modal-body {
      .gallery-container {
        flex: 1 5em !important;
        padding: 1em !important;
        margin-top: 0em !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
        z-index: 10;
        justify-content: flex-start;
      }

      .content-container {
        flex: 1 20em !important;
        > .card {
          border-radius: 1em !important;
        }
      }

      .content-row {
        flex-basis: auto !important;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      .action-row {
        background: $pitch-black;
        padding: 0 !important;
        margin: 0 !important;
        flex-wrap: wrap;
        max-height: 12em;
        height: 12em;
        bottom: 0em;
        left: 0px;
        right: 0px;
        padding-top: 1em !important;
        padding-bottom: 3.5em !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: fixed !important;
        z-index: $z-modal + 10;
      }

      .chosen-count {
        justify-content: center;
        align-items: center;
        text-align: left;
        line-height: 1;
      }
    }
  }
}
#app.small-format {
  .modal.item-preview {
    .images-container {
      margin-left: 0 !important;
    }
  }
}

/*#app.mobile-browser {*/
/*  .modal.item-preview {*/
/*    .modal-body {*/
/*      .action-row {*/
/*        max-width: 800px;*/
/*        min-width: 230px;*/
/*        flex-basis: 100%;*/
/*        max-height: 22em;*/
/*        height: 22em;*/
/*        bottom: 0;*/
/*        padding-bottom: 17em !important;*/
/*        position: fixed;*/
/*        bottom: 0;*/
/*      }*/
/*    }*/
/*  }*/
/*}*/
</style>
