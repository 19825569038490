<template>
  <div class="btn-group--container" :class="{ 'gap-2': gap }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BtnGroup',
  data() {
    return {
      zIndexes: {},
      zIndex: 1
    }
  },
  watch: {},
  methods: {},
  components: {},
  props: {
    gap: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.btn-group--container {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  align-content: center;
  //gap: 0.5em;

  perspective: 10000px;
  perspective-origin: center;

  border-radius: 5px;
  flex-wrap: nowrap;

  > .button--container {
    flex: 0 0 auto;
  }

  &:not(.vertical):not(.gap-2) {
    .button--container:not(.round):not(.circle):not(.rounded):not(.more):not(.add-btn) {
      margin-right: -1px;

      &:not(:hover):not(.active):not(:active) {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &.vertical {
    height: auto;
    flex-direction: column;
    height: auto;
    min-height: 2em;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;

    button {
      justify-content: flex-start !important;
      text-align: left !important;
    }
    .button--container:not(.round):not(.circle):not(.rounded):not(.more):not(.add-btn) {
      margin-top: -1px;
      border: none !important;

      &:not(:hover):not(.active):not(:active) {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        &:not(:last-child) {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  &.block {
    width: 100%;
    > [btn-component] {
      flex: 1 100%;
    }
  }
}
</style>
