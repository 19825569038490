// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'transfer',

  possibleStatuses: ['w', 'q', 're', 'f'],

  skipAudit: true,

  fields: {
    transfer_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    // source_account_id: {
    //   type: 'string',
    //   mapTo: 'account',
    // },
    transfer_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    transfer_amount: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      visible: true
    },
    transfer_source: {
      type: 'string',
      filter: true,
      mapTo: false,
      default: ''
    },
    transfer_destination: {
      type: 'string',
      filter: true,
      mapTo: false,
      default: ''
    },
    transfer_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    transfer_time_updated: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    }
  },

  generateVueActions() {
    return {
      async fetchTransactions({ dispatch }, payload) {
        return await dispatch('ajax', {
          path: 'counterparty/fetchTransactions',
          payload
        })
      },
      async handleFetchTransactions({ dispatch }) {
        return await dispatch('fetchTransactions', {
          type: 'payout',
          status: 'paid',
          order: 'transfer_time_created'
        })
      },
      async search({ dispatch }) {
        const response = await dispatch('handleFetchTransactions')

        return {
          set: response.set[0].data,
          nextToken: null
        }
      }
    }
  }
}
