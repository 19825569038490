<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :scrollable="true"
    :clickAway="true"
    :expandable="true"
  >
    <template #header> Receipt # {{ modal.objects[0].invoice_id }} </template>
    <template #body>
      <receipt-body ref="body" slot="body" :id="id" />
    </template>
  </modal>
</template>

<script>
import ReceiptBody from '../bodies/Receipt.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalInvoice',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { ReceiptBody },
  props: {
    type: {
      default: 'invoice'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
