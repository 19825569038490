<template>
  <div class="w-150 flex flex-col gap-2 max-w-[400px]">
    <div class="w-full flex">
      <div class="basis-4/5 justify-start flex items-center gap-1">
        <Btn rounded link :action="createDimension" severity="tertiary">
          <font-awesome-icon icon="plus" />
        </Btn>

        <search-field
          placeholder="Search for a dimension"
          class="left !max-w-50 !w-50"
          v-model="searchPhrase"
        />

        <Loader :loading="loading" />

        <div class="hidden md:flex items-center" v-if="Object.keys(searchSet).length && !loading">
          <template v-if="Object.keys(searchSet).length && searchPhrase">
            <btn link :action="resetSearch">
              <font-awesome-icon icon="remove" />
              {{ Object.keys(searchSet).length }}
            </btn>
          </template>
        </div>
      </div>

      <slot name="header"> </slot>
    </div>
    <div class="flex items-center gap-2">
      <btn
        size="xsmall"
        v-for="mt in measureTypeOptions"
        :key="mt"
        @click="measureTypeLocal = measureTypeLocal === mt ? null : mt"
        :link="measureTypeLocal !== mt"
        :severity="measureTypeLocal === mt ? 'blue' : 'tertiary'"
      >
        <font-awesome-icon :icon="dimTypeIcon[mt]" />
        {{ mt || 'any' }}
      </btn>
    </div>
    <div class="max-h-[350px] scroll-pb-2 overflow-y-scroll overflow-x-hidden">
      <div class="grid grid-cols-3 gap-2 p-2">
        <div
          v-for="dim in dimensionList"
          :key="dim.abbr"
          @click="selectDimension(dim.abbr)"
          :class="[
            {
              'border-2 border-blue-print': new RegExp(`(\\b|^)${dim.abbr}(\\b|$)`).test(value),
              'border border-surface-200 ': !new RegExp(`(\\b|^)${dim.abbr}(\\b|$)`).test(value)
            },
            'flex flex-col gap-1 items-start justify-start p-1 rounded-sm',
            'min-h-[60px] overflow-hidden',
            'cursor-pointer  relative'
          ]"
        >
          <Tag :style="`background: #${dim.color};`" class="border-0 shadow-sm !text-white">
            {{ dim.abbr }}
          </Tag>
          <template v-if="dim.value > 0">
            {{ $f.number(dim.value) }}
            {{ dim.measure }}
          </template>
          <span class="text-xs text-surface-500 leading-3">{{ dim.name }}</span>

          <div v-tooltip="dim.measureType" class="absolute top-0 right-1">
            <font-awesome-icon class="text-surface-200" :icon="dimTypeIcon[dim.measureType]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DimensionSelectorMixin from './DimensionSelectorMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'DimensionSelector',
  mixins: [DimensionSelectorMixin],
  methods: {
    async onCreatedDim(dim) {
      this.selectDimension(dim.abbr)
    }
  },
  data() {
    return {
      measureTypeLocal: null,
      dimTypeIcon: {
        count: 'tally-5',
        area: 'layer-group',
        volume: 'glass-water',
        weight: 'weight-scale',
        length: 'ruler-horizontal'
      }
    }
  },
  mounted() {
    this.search()
    eventBus.$on('createdDimension', this.onCreatedDim)
  },
  unmounted() {
    eventBus.$off('createdDimension', this.onCreatedDim)
  }
}
</script>
