import Normalize from '../Normalize'
import Objects from '../Objects'
import _ from '../Helpers'

const { getNormalizedRootRefId } = Normalize

const { getConstructor, getFieldTitle, shouldTrackChanges } = Objects

const objectContainer = {
  /**
   * The refId of the object
   */
  refId: null,

  /**
   * The parentRefId of the object
   */
  parentRefId: null,

  /**
   * Friendly reference name (rather than refid), cost_type_name etc
   */
  referenceName: null,

  /**
   * Entity type, assembly or cost_item
   */
  objectType: null,

  /**
   * Changes in fields of this item with
   * field name as key, and a changedField object
   * (described below) as a value for each changed
   * field.
   */
  fieldChanges: {},

  /**
   * Changes in items that are children of this item
   * with refIds as keys, and more objectContainers
   * as the value for each changed child.
   */
  subChanges: {},

  /**
   * If this item itself was added or removed,
   * the changed value should be either 'added' or 'removed'
   */
  changed: null
}

const changedField = {
  /**
   * Describes the change
   */
  desc: '',

  /**
   * The previous value
   */
  from: null,

  /**
   * The new value
   */
  to: null
}

/**
 *
 * @param num1
 * @param num2
 * @returns {boolean}
 */
const eqNum = (num1, num2) =>
  typeof num1 === 'number' && typeof num2 === 'number' && _.eq(num1, num2, 8)

/**
 *
 * @param value1
 * @param value2
 * @returns {boolean}
 */
const eqString = (value1, value2) =>
  typeof value1 === 'string' && typeof value2 === 'string' && value1.trim() === value2.trim()

/**
 *
 * @param value
 * @returns {boolean}
 */
const eqIsEmpty = (value) =>
  value !== 0 &&
  (value === '' ||
    value === null ||
    value === 'undefined' ||
    (typeof value === 'string' && !value.trim()) ||
    (typeof value === 'object' && !Object.keys(value).length) ||
    (Array.isArray(value) && !value.length))

/**
 *
 * @param value1
 * @param value2
 * @returns {boolean}
 */
const eqEmpty = (value1, value2) => eqIsEmpty(value1) && eqIsEmpty(value2)

/**
 *
 * @param value1
 * @param value2
 * @param format
 * @returns {boolean}
 */
const eqFormat = (value1, value2, format) => _.format(value1, format) === _.format(value2, format)

/**
 *
 * @param object oldChanges     starts INSIDE the rootRefId, should be a normalized set of changes
 *                                  with keys being refIds
 * @param object newChanges     ibid
 * @returns {{}}
 */
const mergeChanges = (...changeSchemas) => {
  let combined = _.imm(changeSchemas.shift() || {})

  changeSchemas.forEach((newChanges) => {
    Object.keys(newChanges).forEach((refId) => {
      const defaulted = _.imm({
        ..._.imm(objectContainer),
        ...newChanges[refId]
      })

      // If this entry doesn't exist yet,
      // add it then skip to next
      if (!combined[refId]) {
        combined[refId] = defaulted
        return
      }

      // If there are no changes reported, skip without adding
      if (
        !defaulted.changed &&
        !Object.keys(defaulted.fieldChanges).length &&
        !Object.keys(defaulted.subChanges)
      ) {
        return
      }

      // If it was added then subsequently removed,
      // remove this entry entirely
      if (
        defaulted &&
        combined[refId] &&
        defaulted.changed === 'removed' &&
        combined[refId].changed === 'added'
      ) {
        // eslint-disable-next-line no-unused-vars
        const { [refId]: omit, ...rest } = combined
        combined = rest
        return
      }

      // Coalesce field changes, overriding with new changes
      // but retaining the 'from' data point from the previous
      // change
      Object.keys(defaulted.fieldChanges).forEach((field) => {
        if (!(field in combined[refId].fieldChanges)) {
          combined[refId].fieldChanges[field] = defaulted.fieldChanges[field]
        } else {
          // if the field is an object,
          // merge them
          const oldTo = combined[refId].fieldChanges[field].to
          const newTo = defaulted.fieldChanges[field].to
          let coalescedTo = newTo
          if (/^o[A-Z]/.test(field)) {
            coalescedTo = {
              ...oldTo,
              ...newTo
            }
          }
          combined[refId].fieldChanges[field] = {
            ...combined[refId].fieldChanges[field],
            ...defaulted.fieldChanges[field],
            from: combined[refId].fieldChanges[field].from,
            to: coalescedTo
          }
        }
      })

      // Coalesce subChanges if present
      Object.keys(defaulted.subChanges).forEach((childRefId) => {
        combined[refId].subChanges[childRefId] = {
          ...combined[refId].subChanges[childRefId],
          ...defaulted.subChanges[childRefId]
        }
      })

      // Coalesce changed data point
      combined[refId].changed = defaulted.changed || combined[refId].changed
    })
  })

  // Make sure each one has a change recorded before returning
  // return Object.keys( v)
  //   .filter(refId => combined[refId].changed || Object.keys(combined[refId].fieldChanges).length)
  //   .reduce((acc, refId) => ({
  //     [refId]: combined[refId],
  //   }), {});

  return combined
}

/**
 * Get the friendly referencename
 * @param set
 * @param refId
 * @returns {String}
 */
const getReferenceName = (set, refId) => {
  const type = set[refId].type
  return (
    set[refId][`${type}_name`] ||
    set[refId].cost_type_name ||
    set[refId].assembly_name ||
    set[refId].quote_name ||
    _.capitalize(String(set[refId].type).replace('_', ' '))
  )
}

/**
 * Build a log entry
 * @param desc
 * @param from
 * @param to
 * @returns {{desc: *, from: *, to: *}}
 */
const logEntry = (desc, from, to) => ({
  desc,
  from,
  to
})

/**
 *
 * Returns true if values are the same
 * @param fromold value
 * @param mixed to                  new value
 * @param string field              name of field
 * @param object localFields        constructor.fields
 * @param bool strict
 * @param bool checkChangeTracking
 * @returns {*} Returns true if values are the same
 */
const fieldComparison = (
  from,
  to,
  field,
  localFields = null,
  strict = false,
  checkChangeTracking = true
) => {
  const fieldExists = field && localFields && field in localFields

  // Check field settings
  if (checkChangeTracking && !shouldTrackChanges(field, null, localFields)) {
    return true
  }

  if (field === 'parentRefId' && from !== to) {
    return false
  }

  const schema = fieldExists && localFields[field]
  const isJson = _.isJsonField(field)
  const checkDeep = isJson && schema && schema.deep

  if (isJson && eqEmpty(from, to)) return true

  // For deep checked json, see if it equals regardless
  // of order or typing
  if (isJson && checkDeep) {
    return _.deepEquals(from, to)
  }

  // Non deep json comparison
  if (isJson && !checkDeep) {
    return _.jsonEquals(from, to, strict)
  }

  //
  // console.log('  check field',
  //   from, to,
  //   (_.isNumericField(field) && eqNum(from, to)),
  //   eqString(from, to),
  //   _.jsonEquals(from, to, strict),
  //   eqEmpty(from, to),
  //   (localFields && (fieldExists,
  //   localFields[field].format,
  //   eqFormat(from, to, localFields[field].format))),
  //   (checkChangeTracking && localFields && field && !(field in localFields)),
  //   (!strict && from == to),
  //   (strict && from === to));
  if (fieldExists && typeof localFields[field].isChanged === 'function') {
    return !localFields[field].isChanged(from, to)
  }

  return (
    (_.isNumericField(field) && eqNum(from, to)) ||
    eqString(from, to) ||
    eqEmpty(from, to) ||
    (localFields &&
      fieldExists &&
      localFields[field].format &&
      eqFormat(from, to, localFields[field].format)) ||
    (checkChangeTracking && localFields && field && !(field in localFields)) ||
    (!strict && from == to) ||
    (strict && from === to)
  )
}

/**
 * Include parent shells so that a full denormalized set can be created
 * @param combined
 * @param diffNormalized
 * @returns {*}
 */
const getParents = (combined, diffNormalized) => {
  const logs = diffNormalized

  // Now make sure there is a shell for each parent
  // of each change required
  const addParent = (childRefId) => {
    if (!(childRefId in combined)) {
      return childRefId
    }

    const parentRefId = combined[childRefId].parentRefId

    if (!parentRefId) {
      return childRefId
    }

    logs[parentRefId] = logs[parentRefId] || {
      ..._.imm(objectContainer),
      parentRefId: combined[parentRefId].parentRefId || null,
      refId: parentRefId,
      objectType: combined[parentRefId].type,
      referenceName: getReferenceName(combined, parentRefId)
    }

    return addParent(parentRefId)
  }

  Object.keys(diffNormalized).forEach((refId) => addParent(refId))

  return logs
}

/**
 * Get changes based on added and removed items
 * @param original object
 * @param current object
 */
const diffAddedRemoved = (original, current, rootRefId, addParents = true) => {
  const originalRefIds = Object.keys(original)
  const currentRefIds = Object.keys(current)
  const combined = {
    ...original,
    ...current
  }

  let changes = {}

  const allRefIds = _.uniq([...currentRefIds, ...originalRefIds])
  allRefIds.forEach((refId) => {
    if (getNormalizedRootRefId(combined, refId) !== rootRefId) {
      return
    }

    if (!originalRefIds.includes(refId)) {
      changes[refId] = {
        ..._.imm(objectContainer),
        parentRefId: combined[refId].parentRefId || null,
        refId,
        changed: 'added',
        referenceName: getReferenceName(combined, refId),
        objectType: combined[refId].type
      }
    }

    if (!currentRefIds.includes(refId)) {
      changes[refId] = {
        ..._.imm(objectContainer),
        parentRefId: combined[refId].parentRefId || null,
        refId,
        changed: 'removed',
        referenceName: getReferenceName(combined, refId),
        objectType: combined[refId].type
      }
    }
  })

  if (addParents) {
    changes = getParents(combined, changes)
  }

  return changes
}

/**
 * Get the differences between two normalized sets
 * @param object rootFrom            normalized set, inside [this.rootRefId]
 * @param object rootTo              normalized set, inside [this.rootRefId]
 * @param string rootRefId           rootRefId
 * @param bool strict                comparing values in strict mode
 * @param array|null refs            restrict the search for differences to only the refIds
 *                                      provided in this array
 *
 */
const diffNormalized = (rootFrom, rootTo, rref = null, strict = false, refs = null) => {
  const rootRefId = rref || getNormalizedRootRefId(rootTo)
  const constructor = getConstructor((rootFrom[rootRefId] || rootTo[rootRefId]).type)
  const fields = constructor.fields
  const excludeFields = ['type', 'changesRequireApproval', 'changedFields']
  let logs = {}

  const toKeys = Object.keys(rootTo)
  const trackingRefIds = refs || toKeys
  const combined = {
    ...rootFrom,
    ...rootTo
  }

  trackingRefIds.forEach((refId) => {
    // Check that this is inside the aoChildren field
    // and not some other normalized object in other fields
    // like aoActivities where changes are not tracked ever
    const parentRefId = rootTo && rootTo[refId] && rootTo[refId].parentRefId
    if (
      !rootTo[refId] ||
      (parentRefId &&
        (!rootTo[parentRefId] ||
          !rootTo[parentRefId].aoChildren ||
          !_.makeArray(rootTo[parentRefId].aoChildren).includes(refId)))
    ) {
      return
    }

    // Use a different function to get added and removed items
    if (!(refId in rootFrom && refId in rootTo)) {
      return
    }

    const objectType = combined[refId].type
    const log = {
      ..._.imm(objectContainer),
      parentRefId,
      refId,
      referenceName: getReferenceName(combined, refId),
      objectType
    }

    const to = rootTo[refId]
    const from = rootFrom[refId]
    const localConstructor = (objectType && getConstructor(objectType)) || constructor
    const localFields =
      (objectType && localConstructor && localConstructor.fields) || fields || false

    // needs fields
    if (!localFields) {
      return
    }

    const toFields = Object.keys(rootTo[refId])
    toFields.forEach((field) => {
      if (excludeFields.includes(field)) return // excluded

      if (!shouldTrackChanges(field, localConstructor, localFields)) return // not tracking

      if (fieldComparison(from[field], to[field], field, localFields, strict)) return // same

      const shouldBeArray = _.isArrayField(field)

      // Anything but arrays
      if (!shouldBeArray) {
        log.fieldChanges[field] = logEntry('Changed', from[field], to[field], field, from || to)

        return
      }

      // Arrays

      // Wasnt array before, ie was initialized, or changed
      const isArray = Array.isArray(to[field])
      const wasArray = Array.isArray(from[field])
      if (typeof from[field] !== typeof to[field] || wasArray !== isArray) {
        log.fieldChanges[field] = logEntry(
          `This was of previously type "${typeof from[field]}" and is now of type "${typeof to[field]}".`,
          from[field],
          to[field],
          field,
          from || to
        )
      }

      // Items added
      if (!from[field] || _.clearEmptyArrayValues(to[field]).length > from[field].length) {
        log.fieldChanges[field] = logEntry(
          `${to[field].length - (from[field] ? from[field].length : 0)} item(s) were added.`,
          from[field],
          to[field],
          field,
          from || to
        )

        return
      }

      // Items removed
      if (_.clearEmptyArrayValues(from[field]).length > _.clearEmptyArrayValues(to[field]).length) {
        log.fieldChanges[field] = logEntry(
          `${String(from[field].length - to[field].length)} item(s) removed.`,
          from[field],
          to[field],
          field,
          from || to
        )
        return
      }

      // Children were re-arranged
      if (field === 'aoChildren') {
        log.fieldChanges[field] = logEntry(
          'Re-arranged items',
          from[field],
          to[field],
          field,
          from || to
        )
        return
      }

      log.fieldChanges[field] = logEntry('Changed', from[field], to[field], field, from || to)
    })

    // If there is indeed a change, add it
    if (Object.keys(log.fieldChanges).length) {
      logs = { ...logs, [refId]: log }
    }
  })

  const addedRemoved = diffAddedRemoved(rootFrom, rootTo, rootRefId, false)
  logs = {
    ...logs,
    ...addedRemoved
  }

  logs = getParents(combined, logs)

  return logs
}

const diffNormalizedRaw = (rootFrom, rootTo, rref = null, strict = false, refs = null) => {
  const diffNorm = diffNormalized(rootFrom, rootTo, rref, strict, refs)

  const rawChanges = {}

  Object.keys(diffNorm).forEach((refId) => {
    const fc = diffNorm[refId].fieldChanges
    if (fc && Object.keys(fc).length) {
      rawChanges[refId] = Object.keys(fc).reduce(
        (acc, field) => ({
          ...acc,
          [field]: fc[field].to
        }),
        {}
      )
    }
  })

  return rawChanges
}

/**
 *
 * @param normalizedChanges
 * @param combined
 * @param rref
 * @returns {{object}}        denormalized chagnes
 */
const denormalizeDiffSet = (normalized, rref = null) => {
  const normalizedChanges = { ...normalized }
  const changedRefs = Object.keys(normalizedChanges)
  const rootRefId = rref || getNormalizedRootRefId(normalized, changedRefs[0])

  const refIds = Object.keys(normalizedChanges)

  const nestle = (parentRefId = rootRefId) => {
    const childRefIds = refIds.filter((refId) => normalized[refId].parentRefId === parentRefId)
    const subChanges = childRefIds.reduce(
      (acc, refId) => ({
        ...acc,
        [refId]: nestle(refId)
      }),
      {}
    )
    return {
      ...normalizedChanges[parentRefId],
      subChanges
    }
  }

  // Nestle and return
  return nestle()
}

/**
 * Get the differences between two normalized sets
 * @param object rootFrom            normalized set, inside [this.rootRefId]
 * @param object rootTo              normalized set, inside [this.rootRefId]
 * @param string rootRefId           rootRefId
 * @param bool strict                comparing values in strict mode
 * @param array|null refs            restrict the search for differences to only the refIds
 *                                      provided in this array
 *
 */
const diffDenormalized = (rootFrom, rootTo, rref = null, strict = false, refs = null) => {
  const normalizedChanges = diffNormalized(rootFrom, rootTo, rref, strict, refs)
  const changedRefs = Object.keys(normalizedChanges)

  if (!changedRefs.length) {
    return {}
  }

  return denormalizeDiffSet(normalizedChanges, rref)
}

/**
 * Checks a field entry, to see if it actually has changed
 * in relation to the original value.
 *
 * @param fieldObject
 * @param fieldName
 * @param refId
 * @param fields
 * @param currentSet
 * @param originalSet
 * @returns {boolean}
 */
const fieldActuallyChanged = (fieldObject, fieldName, refId, fields, currentSet, originalSet) => {
  const to = fieldObject.to

  return (
    refId in originalSet &&
    refId in currentSet &&
    !fieldComparison(originalSet[refId][fieldName], to, fieldName, fields, false, true) &&
    !fieldComparison(
      currentSet[refId][fieldName],
      originalSet[refId][fieldName],
      fieldName,
      fields,
      false,
      true
    )
  )
}

/**
 * Returns the object if it turns out the value is ACTUALLY different from
 * the original.  Returns FALSE if not.
 *
 * @param itemObject
 * @param currentSet
 * @param originalSet
 * @returns {object|false}
 */
const itemActuallyChanged = (itemObject, currentSet, originalSet) => {
  const refId = itemObject.refId
  const newItemObject = _.imm(itemObject)
  const fieldChangeKeys = Object.keys(newItemObject.fieldChanges)

  if (fieldChangeKeys.length) {
    const constructor = getConstructor(newItemObject.objectType)
    const newFieldChanges = {}

    fieldChangeKeys.forEach((field) => {
      if (
        fieldActuallyChanged(
          newItemObject.fieldChanges[field],
          field,
          refId,
          constructor.fields,
          currentSet,
          originalSet
        )
      ) {
        newFieldChanges[field] = newItemObject.fieldChanges[field]
      }
    })

    newItemObject.fieldChanges = newFieldChanges
  }

  if (
    (!(refId in originalSet) && refId in currentSet && newItemObject.changed === 'added') ||
    (refId in originalSet && !(refId in currentSet) && newItemObject.changed === 'removed') ||
    Object.keys(newItemObject.fieldChanges).length
  ) {
    return newItemObject
  }

  return false
}

/**
 * Filter a change set, leaving ONLY the entries where the actual values were changed in some way
 * from the original. This is important when changes build up, to check that it wasn't changed back
 * at some point to the original.
 *
 * @param changeSet
 * @param currentSet
 * @param originalSet
 */
const filterActuallyChanged = (changeSet, currentSet, originalSet) => {
  const refIds = Object.keys(changeSet)

  const newChangeSet = {}
  refIds.forEach((refId) => {
    const changed = itemActuallyChanged(changeSet[refId], currentSet, originalSet)

    if (changed) {
      newChangeSet[refId] = changed
    }
  })

  return newChangeSet
}

/**
 *
 * @param object rawChanges             refIds as keys
 * @param object|bool changeLog
 * @param string|array refList
 * @param string|null changeType
 * @param string|null referenceName
 * @returns {{}}    returns in normalized set format { [refId]: objectContainer, [refId]: ... }
 */
const getChangesFromRaw = (
  rawChanges = {},
  changeType = 'default',
  currentNormalized = {},
  originalNormalized = {}
) => {
  const refIds = _.makeArray(Object.keys(rawChanges))

  const newChangeSet = {}

  refIds.forEach((refId) => {
    const objectChanges = {
      ..._.imm(objectContainer),
      parentRefId: currentNormalized[refId].parentRefId || null,
      refId,
      referenceName: getReferenceName(currentNormalized, refId),
      objectType: currentNormalized[refId].type
    }

    if (changeType === 'added') {
      objectChanges.changed = 'added'
    } else if (changeType === 'removed') {
      objectChanges.changed = 'removed'
    } else {
      const objectRawChanges = rawChanges[refId] || {}
      const norm = currentNormalized[refId]
      const changedConstructor = getConstructor(norm.type)
      const objectOriginal = originalNormalized[refId] || {}

      Object.keys(objectRawChanges).forEach((field) => {
        // If we shouldn't track changes in this field, go
        // to next.
        if (!shouldTrackChanges(field, changedConstructor)) {
          return
        }

        const from = objectOriginal[field] || norm[field]
        const to = objectRawChanges[field]

        // If they are the same, continue to next
        if (fieldComparison(from, to, field, changedConstructor.fields, false, true)) {
          return
        }

        objectChanges.fieldChanges[field] = {
          to,
          from,
          desc: `${getFieldTitle(field, changedConstructor)} changed`
        }
      })
    }

    // If it turns out the changes weren't actually changes,
    // do not add these changes to the overall changes, skip to next
    if (!Object.keys(objectChanges.fieldChanges).length && !objectChanges.changed) {
      return
    }

    // Add changes into newChangeSet
    // separated by refIds
    newChangeSet[refId] = objectChanges
  })

  return newChangeSet
}

/**
 * @param startingSet the normalized set to add changes too
 * @param combinedSet a whole normalized set of all possible items to reference from if necessary
 * @param changeSchemas [...] any number of change schemas to integrate,
 *                      from left to right MUST BE NORMALIZED CHANGE SET
 */
const integrateChangesToSet = (startingSet, combinedSet, ...changeSchemas) => {
  const norm = _.immutable(startingSet)

  const changes = mergeChanges(...changeSchemas)

  Object.keys(changes).forEach((refId) => {
    if (changes[refId].changed === 'added') {
      norm[refId] = combinedSet[refId]

      const parent = combinedSet[refId].parentRefId
      if (!(parent in norm)) {
        norm[parent] = combinedSet[parent]
      }

      let siblings = norm[parent].aoChildren
      if (!siblings.includes(refId)) {
        siblings = [...siblings, refId]
      }

      norm[parent].aoChildren = siblings
    }
    if (changes[refId].changed === 'removed' && refId in startingSet) {
      delete norm[refId]
    }

    const fieldsChanged = Object.keys(changes[refId].fieldChanges)
    if (fieldsChanged.length) {
      norm[refId] = {
        ...startingSet[refId],
        ...Object.keys(changes[refId].fieldChanges).reduce(
          (acc, fc) => ({
            ...acc,
            [fc]: changes[refId].fieldChanges[fc].to
          }),
          {}
        )
      }
    }
  })

  return norm
}

export default {
  diffDenormalized,
  diffNormalized,
  diffNormalizedRaw,
  getParents,
  eqIsEmpty,
  eqEmpty,
  diffAddedRemoved,
  denormalizeDiffSet,
  getChangesFromRaw,
  filterActuallyChanged,
  fieldComparison,
  getReferenceName,
  logEntry,
  mergeChanges,
  integrateChangesToSet,
  objectContainer,
  changedField
}
