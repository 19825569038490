<template>
  <div class="change-audit--container">
    <change-audit-object :changes="changes" :startOpen="startOpen" />
  </div>
</template>

<script>
export default {
  name: 'ChangeAudit',

  props: {
    /**
     * Denormalized changeset derived from
     * api/Changes/Changes.getChanges();
     */
    changes: {
      required: true,
      type: Object,
      default: () => ({})
    },

    /**
     * The entity type that this change audit
     * is looking at.
     */
    type: {
      required: false
    },

    /**
     * Whether to start completely expanded, or
     * remain collapsed to start;
     */
    startOpen: {
      default: true
    }
  },

  computed: {
    fieldsLocal() {
      return this.fields ? this.fields : c.getConstructor(this.type).fields
    },
    deduplicatedChanges() {
      /* let newObj = {};
        const fields = Object.keys(this.changes);
        if (!this.children) {
          fields.forEach((f) => {
            if ((fields.indexOf(`${f}_base`) === -1
                && fields.indexOf(`${f}_index`) === -1)
              && (/^\d+$/.test(f)
                || (this.fieldsLocal
                  && this.fieldsLocal[f]
                  && !/_time_last_modified/.test(f)
                  && (typeof this.fieldsLocal[f].trackChanges === 'undefined'
                    || this.fieldsLocal[f].trackChanges
                    || this.fieldsLocal[f].reload)
                  && (typeof this.fieldsLocal[f].audit === 'undefined'
                    || this.fieldsLocal[f].audit)))) {
              newObj[f] = this.changes[f];
            }
          });
        } else {
          newObj = this.changes;
        }
        return newObj; */

      if (this.changes.fieldChanges || this.changes.subChanges) {
        const { fieldChanges, subChanges } = this.changes
        return {
          ...fieldChanges,
          ...subChanges
        }
      }

      const {
        objectType: omit,
        referenceName: omitA,
        refId: omitB,
        changeType: omitC,
        changedFields: omitD,
        ...rest
      } = this.changes
      return rest
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
div.change-audit {
  text-align: left;
  padding: 0.25em 0;
}

// object
.change-audit--container {
  .change-audit--object {
    margin-bottom: 1em;
    margin-top: 0.5em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .change-audit--label-container {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div.change-audit--object-items {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    margin-left: 1em;
  }

  span.change-audit--sublabel {
    // cost_type
    color: $purple-900;
    font-weight: 500;
    font-size: 1em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.change-audit--sublabel-assembly {
      color: $purple-900;
    }
  }

  .change-audit--badge {
    border-radius: 2px;
    font-size: 0.6em;
    padding: 0.2em 0.35em 0.4em 0.35em;
    background: $deep-red-800;
    color: $flame-white;
    margin: 0 0.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0.8;

    &.added {
      background: $matcha-500;
      color: $flame-white;
    }
  }
}

// Field
.change-audit--container {
  max-width: 100%;
  .change-audit--field {
    font-weight: normal;
  }

  span.change-audit--field-label {
    color: $blue-print-700;
    margin-right: 1em;
  }

  .change-audit--field-log {
    color: $cool-gray-600;
    font-size: 0.85em;
    overflow-wrap: break-word;
    white-space: normal;

    span.change-audit--field-number {
      color: #2727ab;
      font-weight: normal;
    }
  }
}

.change-audit--container.dark {
  span.change-audit--field-label {
    color: $blue-print-300;
  }

  .change-audit--field-log {
    color: $cool-gray-300;

    span.change-audit--field-number {
      color: $blue-print-400;
    }
  }
  span.change-audit--sublabel {
    // cost_type
    color: $purple-300;

    &.change-audit--sublabel-assembly {
      color: $purple-300;
    }
  }

  .change-audit--badge {
    background: $deep-red-200;
    color: $deep-red-800;

    &.added {
      background: $matcha-500;
      color: darken($matcha-500, 25%);
    }
  }
}
</style>
