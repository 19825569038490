import { computed, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import Router from '@/router'
import { useStore } from 'vuex'
import _ from 'lodash'
import { useSidePanel } from '@/stores/sidepanel'

export const initialState = {
  allItems: [],
  viewAll: false,
  starred: []
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const state = reactive({
    query: null,
    items: [],
    searchResults: [],
    expandedKeys: {}
  })

  const $route = useRoute()
  const $store = useStore()
  const sidePanelState = useSidePanel()

  const pathQuery = computed(() => $route.query || {})
  const scopeRoute = computed(() => $route.params.scopeRoute)
  const hasItemsToHide = computed(() => context.allItems.length > 3)

  // all the visible items not collapsed
  const visibleItems = computed(() => {
    if (context.viewAll || state.query || !hasItemsToHide.value) return context.allItems
    return context.allItems.slice(0, 3).map((i) => ({ ...i, expanded: true }))
  })

  // handle collapsing/hiding items and adding see all
  const collapsedItems = computed(() => {
    const seeAllMenuItem = {
      label: 'More',
      icon: 'fas fa-ellipsis',
      command: () => (context.viewAll = true),
      class: 'text-surface-800 text-left !font-medium !text-[16px]',
      containerClass: '!-ml-2',
      noStar: true,
      noHide: true
    }
    const starredMenuItem = {
      key: 'Favorites',
      label: 'Favorites',
      icon: 'star',
      items: context.starred,
      expanded: true,
      noStar: true,
      section: true
    }
    const divider = {
      key: 'Divider',
      divider: true
    }
    const hasStarred = context.starred.length > 0
    return [
      ...(hasStarred ? [starredMenuItem, divider] : []),
      ...visibleItems.value,
      ...(context.viewAll || !hasItemsToHide.value ? [] : [seeAllMenuItem])
    ]
  })

  // guess the path based on the route
  const guessPath = (path) => {
    const rel = path.replace(/^\//, '')
    const route = Router.resolve(`/scoperoute/${rel}`) || false
    const scopedRoute = !!route?.params?.scopeRoute

    const sr = scopedRoute ? scopeRoute.value : false
    return sr ? `/${sr}/${rel}` : `/${rel}`
  }

  // does the item a match based on search query
  const isSearchQueryMatch = (item) => {
    const q = (state.query || '').toLowerCase()
    const searchCriteria = `${item.label || ''} ${item.desc || ''}`.toLowerCase()
    if (q && searchCriteria.includes(q)) return true
    return false
  }

  // check to see if the menu item is the current page based on route
  const isActivePage = (item) => {
    const guessedPath = guessPath(item.page)
    if ($route.path === guessedPath) return true
    return false
  }

  // does the menu item have a tab and is it the current route tab
  const isActiveTab = (item) => {
    if (Array.isArray($route.query?.tab)) {
      return $route.query?.tab[0] === item.tab
    }
    return $route.query?.tab === item.tab
  }

  // should the page filter nav item be set to active
  const isActiveFilterPage = (item) => {
    const guessedPath = guessPath(item.page)
    const pq = pathQuery.value.filters
    if ($route.path === guessedPath && _.jsonEquals(pq, item.filters)) return true
    return false
  }

  // is there a route param that should set nav item as active
  const isActiveQueryPage = (item) => {
    const pq = pathQuery.value
    const queryKeys = Object.keys(item.query)
    if (queryKeys.every((key) => pq[key] && pq[key] === item.query[key])) return true
    return false
  }

  // does the child have active parents and therefore be active
  const hasActiveAncestors = (item) =>
    item.items.some((subItem) => subItem.active || (subItem.items && hasActiveAncestors(subItem)))

  // properly map and format item data to be displayed
  const mapWithClass = (item) => {
    const isParent = item.items?.length && !item.icon
    const isPage = item.page && !item.filters && !item.query
    const isTab = item.tab
    const hasNav = item.page || item.tab || item.filters || item.query
    const isExpanded = !!state.expandedKeys[item.key]
    const hasChildren = !!item.items

    // set defaults
    // item.expanded = false;
    item.active = false
    item.searchHit = false
    item.key = item.key || _.uniqueId()

    // check for query match
    item.searchHit = isSearchQueryMatch(item)

    if (item.searchHit)
      state.searchResults.push({
        ...item,
        hideChevron: true
      })

    item.isStarred = !!context.starred.find((i) => i.key === item.key)

    // check if parent
    if (isParent) item.textClass = `${item.textClass || ''} font-medium`

    // is the this menu item the current page if so set active
    if (isPage) item.active = isActivePage(item)

    // check to see if the menu item has a tab and add it to the route query
    if (isTab) {
      item.command = () =>
        Router.replace({
          ...$route,
          query: {
            ...$route.query,
            tab: item.tab
          }
        })
      const a = isActiveTab(item)
      item.active = a
    }

    // check if menu item is active based on filters
    if (item.page && item.filters) item.active = isActiveFilterPage(item)

    if (item.query && !item.active) item.active = isActiveQueryPage(item)

    // make sure it is opened first before mapping children
    if (hasChildren && (!hasNav || isExpanded))
      item.items = item.items.map((subItem) => mapWithClass(subItem))

    // has active parents or the menu item itself is active
    if (hasChildren && item.active && hasActiveAncestors(item)) item.expanded = true

    return item
  }

  // toggle the item open or closed
  const toggleKey = (key) => {
    state.expandedKeys = {
      ...state.expandedKeys,
      [key]: !state.expandedKeys[key]
    }
  }

  // When clicking on a menu item do an action
  const toggleAction = (item) => {
    if (!item.command) {
      // toggle
      toggleKey(item.key)
      return
    }
    // item has custom command
    item.command()
    if (!item.noHide) {
      sidePanelState.toggle()
    }
  }

  // open parent
  const ancestorOpen = (item, openKeys = []) => {
    const ancestor = item.items?.some((subItem) => ancestorOpen(subItem, openKeys)) || false
    const shouldOpen = ancestor || item.expanded || item.searchHit
    if (shouldOpen) openKeys.push(item.key)
    return ancestor || item.active || item.searchHit
  }

  // auto open parents
  const openAncestors = (items) => {
    const openKeys = []
    items.forEach((item) => ancestorOpen(item, openKeys))
    return openKeys
  }

  // get all the opened nav item keys
  const getOpenKeys = (items) => {
    const openKeys = openAncestors(items)
    return openKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: true
      }),
      {}
    )
  }

  // get either search results or all items
  const getItems = (items) => {
    if (!state.query) return items
    return state.searchResults
  }

  const savedStarred = () => {
    const keys = context.starred.map(({ key }) => key)
    return $store.dispatch('setMeta', {
      starredNavItems: JSON.stringify(_.uniq(keys))
    })
  }

  // handle the starring a menu nav item
  const onStar = (item) => {
    if (item.isStarred) {
      context.starred = context.starred.filter((i) => i.key !== item.key)
      savedStarred()
      return
    }
    context.starred.push(item)
    savedStarred()
  }

  const findAllStarred = (items, keys, starred = []) => {
    items.forEach((item) => {
      const { key, items: children = [] } = item
      if (children && children.length > 0) {
        findAllStarred(children, keys, starred)
        return
      }
      if (keys.includes(key)) starred.push(item)
    })
    return starred
  }

  const setStarred = async () => {
    const starredNavItems = await $store.dispatch('getMeta', 'starredNavItems')
    const keys = starredNavItems ? JSON.parse(starredNavItems) : []
    let allStarred = []
    context.starred = findAllStarred(context.allItems, keys, allStarred)
  }

  return {
    ...toRefs(context),
    ...toRefs(state),
    toggleAction,
    mapWithClass,
    toggleKey,
    getOpenKeys,
    getItems,
    setStarred,
    onStar,
    pathQuery,
    collapsedItems
  }
}
