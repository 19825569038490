<template>
  <span class="help--container flex !justify-start !flex-row">
    <a v-if="$slots.before" @click="open" class="help--text-button">
      <slot name="before"></slot>
      <btn
        rounded
        class="help--button"
        :class="btnClass"
        v-if="inline"
        @click="open"
        v-tooltip="'Click for more px-0 p-2 information..'"
      >
        <font-awesome-icon icon="info" fixed-width />
      </btn>
    </a>
    <a v-if="$slots.after" @click="open" class="help--text-button">
      <slot name="after"></slot>
    </a>
    <MiniModal ref="drop" :scrollable="false" v-if="requested">
      <template #header>
        <font-awesome-icon icon="question-circle" /><slot name="title">Details</slot>
      </template>
      <Container class="text-sm">
        <div class="text-lg">
          <slot></slot>
        </div>
      </Container>
    </MiniModal>
    <a :id="`help-toggle-${uid}`" class="help--btn-link flex items-center mr-1" @click="open">
      <slot name="button">
        <div
          class="flex items-center justify-center aspect-square !text-xs rounded-full size-5 cursor-pointer transition bg-secondary hover:bg-blue-print-300"
          v-if="!headless && !inline"
          @click="open"
          v-tooltip="'Click for more information..'"
        >
          <font-awesome-icon :icon="['fas', 'question']" class="text-flame-white" />
        </div>
      </slot>
    </a>
  </span>
</template>

<script>
export default {
  name: 'Help',
  emits: ['click'],
  data() {
    return {
      requested: 0,
      uid: c.uniqueId()
    }
  },
  methods: {
    open() {
      this.$emit('click')
      if (this.buttonOnly) {
        return
      }

      this.requested = 1
      this.$nextTick(() => {
        this.$refs.drop.open()
      })
    },
    close() {
      if (this.$refs.drop) this.$refs.drop.close()
    }
  },
  props: {
    buttonOnly: {
      type: Boolean,
      default: false
    },
    headless: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    btnClass: {
      default: 'more sm'
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.help--container {
  > .help--button[btn-component].more.info.sm {
    margin: 0 0.25em !important;
  }

  .help--text-button {
    margin: 0 0.25em 0 0;
  }

  .help--btn-link {
    overflow: visible !important;
  }
}
</style>
