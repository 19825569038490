<template>
  <div
    class="line-item-list--container container-element"
    @touchstart="testLongTouchStart"
    @touchend="testLongTouchEnd"
    style="z-index: 2"
    :class="{
      'line-item-list--empty': !valueLocal.length && !showLoader,
      'line-item-list--expanded': expandedList.length
    }"
  >
    <slot name="start"></slot>
    <draggable
      :class="
        valueLocal.length || showLoader
          ? 'line-item-list--populated-set'
          : 'line-item-list--empty-set'
      "
      :component-data="{
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
      v-model="valueLocal"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="id"
      :move="canDrag"
    >
      <template #item="{ element: childRefId }">
        <div class="line-item-list--item-container-outer">
          <component
            class="line-item-list--item-component"
            :showForCost="showForCost"
            :ref="childRefId"
            v-if="childRefId in norm"
            :object="norm[childRefId]"
            :store="store"
            :removing="removing"
            :inAssembly="inAssembly"
            @removing="removingHandler"
            @expanded="toggleExpanded"
            @adding-item="(v) => $emit('addingItem', v)"
            @loading="(v) => $emit('loading', v)"
            :is="/quote|assembly/.test(norm[childRefId].type) ? 'Assembly' : 'CostItem'"
            :reference="childRefId"
            :key="childRefId"
            :childIndex="childRefId.id"
            :data-index="childRefId.id"
            :rootRefId="rootRefId"
            :editable="editable"
          />
        </div>
      </template>
      <template #footer v-if="!showLoader && !valueLocal.length && editable">
        <div key="empty" class="line-items--empty-container">
          <spinner :loading="true" v-if="loading" size="5em" class="info" />
          <template v-else>
            <h4 v-if="isRoot" class="!text-2xl !text-pitch-black mb-4">
              Add your first line-item to this {{ inAssembly ? 'assembly' : 'quote' }}
            </h4>
            <h4 v-else class="!text-2xl mb-4">Add, or drag here</h4>
            <span class="btn-group">
              <btn
                class="primary-black !p-4"
                data-label="add"
                @click="selectItems()"
                :loading="loading"
              >
                <font-awesome-icon icon="plus" fixed-width class="mr-4" />
                Add item to this {{ isRoot ? 'quote' : 'assembly' }}
              </btn>
            </span>

            <div class="mt-5" v-if="isRoot && suggestions.length">
              <strong class="text-muted">Suggestions</strong>
              <div class="p-2 flex justify-center items-center flex-wrap">
                <btn
                  class="round sm m-2"
                  v-for="suggestion in suggestions"
                  :key="suggestion.assembly_id || suggestion.cost_type_id"
                  @click="itemSelection = `assembly:${suggestion.assembly_id}`"
                >
                  <template #icon>
                    <font-awesome-icon icon="plus" />
                  </template>
                  {{ $f.truncate(suggestion.assembly_name || 'No name', 40) }}
                </btn>
              </div>
            </div>
          </template>
        </div>
      </template>
    </draggable>

    <div class="line-item-list--item-container-outer" v-if="showLoader" key="loader">
      <assembly-loader key="assemblyloader" v-if="showLoader === 'assembly'" />
      <cost-item-loader key="costitemloader" v-else />
    </div>

    <slot name="end"></slot>

    <div class="flex items-center justify-start ml-2 gap-2">
      <btn
        v-if="isRoot"
        data-label="add"
        @click="selectItems()"
        severity="primary-black"
        v-show="valueLocal.length"
        style="position: relative"
      >
        &nbsp;{{ isRoot ? 'Add' : 'Add' }}
      </btn>
      <btn
        rounded
        v-else
        data-label="add"
        @click="selectItems()"
        severity="primary-black"
        v-show="valueLocal.length"
        style="position: relative"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </btn>

      <btn
        :loading="loading"
        data-label="add_optional"
        v-if="isRoot"
        @click="selectItems(true)"
        severity="secondary"
        v-show="valueLocal.length"
        style="position: relative"
      >
        &nbsp;{{ isRoot ? 'Add optional' : 'Add optional' }}
      </btn>
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import TransitionFade from '../transitions/TransitionFade.vue'
import Assembly from './Assembly.vue'
import CostItem from './CostItem.vue'
import AssemblyLoader from './AssemblyLoader.vue'
import CostItemLoader from './CostItemLoader.vue'
import UserMeta from '../mixins/UserMeta'
import ItemListMixin from './ItemListMixin'

Draggable.compatConfig = { MODE: 3 }

/**
 * Emits:
 * -input (child refids)
 * -addingItem
 * -loading
 * -expanded (bool)
 */
export default {
  name: 'line-item-list',
  mixins: [UserMeta, ItemListMixin],
  props: {
    loading: {
      default: false
    }
  },
  components: {
    Draggable,
    TransitionFade,
    CostItem,
    Assembly,
    AssemblyLoader,
    CostItemLoader
  },
  emits: ['addingItem', 'loading']
}
</script>

<style lang="scss" rel="stylesheet/scss">
.line-item-list--container {
  .line-item-list--item-container-outer {
    position: relative;
    overflow-x: visible;
    padding-bottom: 0.5em;

    &:hover {
      cursor: pointer;
    }
  }

  .line-item-list--item-component {
    transform: translateZ(0);
    transition: all 0.3s ease !important;
    transition-delay: 0.5s;
    position: relative;
  }

  .line-item-list--empty-set {
    padding: 0;
    border: 1px dashed $cool-gray-700;
    border-radius: 8px;
    > div {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      color: $cool-gray-500;

      .line-item-list--item-container-outer {
        position: absolute;
        max-width: 100%;
      }
    }
  }

  .line-item-list--populated-set {
    min-height: 20px;
  }

  .line-items--empty-container {
    min-height: 276px;
    h4,
    h6,
    h5 {
      color: $blue-print-700;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    text-align: center;
    color: rgba($primary, 0.6);
  }
}

.sortable-drag {
  border-radius: 10px;
  background: rgba($level-yellow, 0.8);
  transform: translateZ(-1em);
  cursor: move;
}
.sortable-chosen {
  zoom: 0.9;
  border-radius: 1em;
  padding: 0.5em !important;
  margin: 0.5em !important;
  background: rgba($level-yellow, 0.6);
  transform: translateZ(0);
}

@media (max-width: 576px) {
  .line-item-list--item-container-outer {
    border-radius: 0;

    .card.assembly-child--container,
    .card.cost-item-container {
      border-radius: 0;

      .card-list-item {
        border-radius: 0 !important;
      }
    }
  }
}
</style>
