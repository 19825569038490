<template>
  <Inplace :pt="{ display: '!no-underline' }" :active="active">
    <template #display>
      <div class="min-h-8 min-w-12" @click="onDisplayClick">
        <slot name="display" :active="active" />
      </div>
    </template>
    <template #content>
      <div
        class="content-wrapper"
        ref="contentWrapper"
        @focusout="onFocusOut"
        @dblclick.prevent="selectAllText"
        @click.stop="onInputClick"
        @keyup.enter="onPressEnter"
      >
        <slot name="content" :active="active" />
      </div>
    </template>
  </Inplace>
</template>

<script setup>
import Inplace from 'primevue/inplace'
import { ref, toRef, watch, nextTick } from 'vue'

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['blur', 'close', 'open'])
const active = ref(props.isActive)
const contentWrapper = ref()

const focusInput = () => {
  nextTick(() => {
    const input = contentWrapper.value?.querySelector('input, textarea')
    if (input) {
      input.focus()
    }
  })
}

const onDisplayClick = () => {
  if (!active.value) {
    active.value = true
    focusInput()
    emit('open')
  }
}

const onFocusOut = () => {
  if (active.value) {
    active.value = false
    emit('blur')
    emit('close')
  }
}

const onPressEnter = () => {
  active.value = false
  emit('close')
}

const selectAllText = () => {
  const input = contentWrapper.value?.querySelector('input, textarea')
  if (input) {
    input.select()
    input.focus()
  }
}

const onInputClick = (event) => {
  // Prevent deactivating when clicking inside the input
  event.stopPropagation()
}

// Watch for changes to the `isActive` prop
watch(toRef(props, 'isActive'), (value) => {
  active.value = value
  if (value) {
    focusInput()
    emit('open')
  }
})
</script>
