export default {
  methods: {
    setField(field, value) {
      const changes = {
        [field]: value
      }
      this.objectLocal = {
        ...this.objectLocal,
        ...changes
      }

      this.$nextTick(() =>
        setTimeout(
          () =>
            this.$store.dispatch(`${this.store}/field`, {
              refId: this.object.refId,
              changes,
              explicit: true
            }),
          0
        )
      )
    },
    setShowItemizedPrices(v) {
      const actual = v ? 1 : 0
      this.setField('assembly_show_itemized_prices', actual)

      c.throttle(() => this.saveEmphasis(), { key: this.objectLocal.refId })
    }
  }
}
