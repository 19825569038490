import adyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import paymentRequestsMixin from '../PaymentRequestsMixin'

export default {
  data() {
    return {
      adyenPMResponse: {
        card: {},
        ach: {}
      },
      clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
      ...(import.meta.env.VITE_ADYEN_ENV === 'test' ? { environment: 'test' } : {}),
      locale: 'en-US',
      browserInfo: {},
      brand: null,
      paymentMethodVariants: null
    }
  },
  emits: ['paid'],
  mixins: [paymentRequestsMixin],
  methods: {
    /**
     * Fetches the available payment methods and the users stored payment methods from Adyen
     */
    async getAdyenPaymentMethods() {
      const { paymentMethods, storedPaymentMethods } = await this.fetchPaymentMethods()

      if (paymentMethods) {
        this.adyenPMResponse.card.paymentMethods = paymentMethods.filter(
          (method) => method.type === 'scheme'
        )
        this.adyenPMResponse.ach.paymentMethods = paymentMethods.filter(
          (method) => method.type === 'ach'
        )
      }
      if (storedPaymentMethods) {
        this.adyenPMResponse.card.storedPaymentMethods = storedPaymentMethods.filter(
          (method) => method.type === 'scheme'
        )
        this.adyenPMResponse.ach.storedPaymentMethods = storedPaymentMethods.filter(
          (method) => method.type === 'ach'
        )
      }
      const availableMethods = ['manual']
      paymentMethods.forEach((method) => {
        if (method.type === 'scheme') availableMethods.push('card')
        if (method.type === 'ach') availableMethods.push('ach')
        const country = this.$store.state.session.company.country_abbr
        if (method.type === 'eft_directdebit_CA' && country === 'ca') availableMethods.push('eft')
      })
      this.allowedPaymentMethods = availableMethods
    },

    /**
     * Get payment details for the invoice
     *
     */
    async getAdyenPaymentDetails() {
      return this.fetchPaymentDetails()
    },

    /**
     * Use adyen's bin lookup to adjust the fees
     */
    handleOnBinLookup(data) {
      const { paymentMethodVariants, detectedBrands } = data
      this.brand = detectedBrands[0] || null
      // only adjust in the US
      if (this.counterpartyCountry !== 'us') {
        this.onBrand()
        return
      }
      this.paymentMethodVariants = paymentMethodVariants
      if (!this.variantFees || this.variantFees.length === 0) return
      this.adjustFeeBasedOnVariant()
    },

    /**
     * Based on the set card variants adjust the credit card fee
     */
    adjustFeeBasedOnVariant() {
      // set back to standard
      if (!this.paymentMethodVariants) {
        this.allFees.card = this.allFees.standardCards
        return
      }
      // find based on variant and set new fee
      const variant = this.paymentMethodVariants.find((v) => this.variantFees[v])
      if (variant) {
        const newFee = this.variantFees[variant]
        this.allFees.card = newFee
      } else {
        this.onBrand()
      }
    },

    /**
     * Configure Adyen's Drop-in UI component and mount it to
     * <div id="adyen-credit-card-payment-element" />
     */
    async configureAdyenCreditCardComponent() {
      const configure = {
        paymentMethodsResponse: this.adyenPMResponse.card,
        clientKey: this.clientKey,
        locale: this.locale,
        environment: this.environment,
        onChange: this.handleOnChange,
        onAdditionalDetails: this.handleOnAdditionalDetails,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            hideCVC: false, // Change this to true to hide the CVC field for stored cards
            name: 'Add a new credit card',
            onBinLookup: this.handleOnBinLookup
          }
        }
      }
      const checkout = await adyenCheckout(configure)
      const options = {
        openFirstPaymentMethod: true,
        openFirstStorePaymentMethod: false,
        showRemovePaymentMethodButton: true,
        showPayButton: false,
        onDisableStoredPaymentMethod: this.handleOnDisableStoredPaymentMethod
      }
      checkout.create('dropin', options).mount('#adyen-credit-card-payment-element')
    },

    onError(error) {
      console.log(error, 'ERROR')
    },

    /**
     * Configure Adyen's Drop-in UI component and mount it to
     * <div id="adyen-ach-payment-element" />
     */
    async configureAdyenACHComponent() {
      const configure = {
        paymentMethodsResponse: this.adyenPMResponse.ach,
        clientKey: this.clientKey,
        locale: this.locale,
        environment: this.environment,
        onChange: this.handleOnChange,
        onError: this.onError,
        onAdditionalDetails: this.handleOnAdditionalDetails
      }

      const checkout = await adyenCheckout(configure)
      const options = {
        openFirstStorePaymentMethod: false,
        showRemovePaymentMethodButton: true,
        showPaymentMethods: false,
        showPayButton: false,
        onDisableStoredPaymentMethod: this.handleOnDisableStoredPaymentMethod
      }
      checkout.create('dropin', options).mount('#adyen-ach-payment-element')
    },

    /**
     * Initialize Adyen for payments
     * @returns {Promise<void>}
     */
    async adyenInitialize() {
      await this.getAdyenPaymentDetails()
      await this.getAdyenPaymentMethods()
      await this.configureAdyenCreditCardComponent()
    },
    onBrand() {
      if (this.specialtyCards.includes(this.brand)) {
        this.allFees.card = this.allFees.specialtyCards
      } else {
        this.allFees.card = this.allFees.standardCards
      }
    },
    handleOnChange(state) {
      const { paymentMethod } = state.data
      this.brand = paymentMethod.brand
      if (!state.isValid) {
        if (this.paymentData) this.paymentData = null
        if (this.isPaymentDataValid) this.isPaymentDataValid = false
        return
      }
      // is a stored credit card
      if (
        paymentMethod.storedPaymentMethodId &&
        paymentMethod.type === 'scheme' &&
        this.counterpartyCountry === 'us'
      ) {
        const stored = this.adyenPMResponse.card.storedPaymentMethods.find(
          (pm) => pm.id === paymentMethod.storedPaymentMethodId
        )
        if (stored) this.paymentMethodVariants = stored.subVariants
        this.adjustFeeBasedOnVariant()
      }
      this.browserInfo = state.data.browserInfo
      this.paymentData = {
        paymentMethod: state.data.paymentMethod
      }
      this.isPaymentDataValid = true
    },

    handleOnAdditionalDetails(state, component) {
      console.log('state', state)
      console.log('component', component)
    },

    async handleOnDisableStoredPaymentMethod(storedPaymentMethodId, resolve, reject) {
      if (storedPaymentMethodId) {
        const res = await this.deleteStoredPaymentMethod(storedPaymentMethodId)
        if (res && !res.error) return resolve()
      }
      return reject()
    },

    async handle3DSecure(state) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/payfac/submitDetailsForPayment',
        data: {
          details: state.data.details
        }
      })
      const { resultCode, refusalReason } = payload
      if (resultCode === 'Authorised') {
        this.verificationSuccess = true
        return
      }

      if (resultCode === 'Cancelled') {
        this.verificationError = 'The transaction authorization has been cancelled.'
        return
      }

      if (resultCode === 'Error' || resultCode === 'Refused') {
        this.verificationError = refusalReason
      }
    },

    /**
     * Pay an invoice for an Adyen counterparty
     * - Called in the PaymentsMixin
     */
    async adyenPayInvoice() {
      const invoiceId = this.invoice.invoice_id
      const payment = this.paymentData
      this.loading = 1

      if (!invoiceId || !payment) throw new Error()

      const response = await this.makePaymentRequest({
        invoice_id: invoiceId,
        checkout: {
          ...payment,
          browserInfo: this.browserInfo,
          paymentMethodVariations: this.paymentMethodVariants
        }
      })
      const { payload } = response
      if (payload.error && payload.details) {
        const checkout = await adyenCheckout({
          clientKey: this.clientKey,
          locale: this.locale,
          environment: this.environment,
          onAdditionalDetails: this.handle3DSecure
        })
        const newCheckoutComponent = document.getElementById('3d-verification-newTab')
        checkout.createFromAction(payload.details).mount(newCheckoutComponent)
        return
      }
      if (response.error) throw new Error(response.message)
      this.$emit('paid')

      this.loading = 0
    },

    async deleteStoredPaymentMethod(storedPaymentMethodId) {
      try {
        const res = await this.$store.dispatch('ajax', {
          path: '/invoice/deleteStoredPaymentMethod',
          data: {
            stored_payment_method_id: storedPaymentMethodId
          }
        })
        return res
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not delete payment method. Please try again.',
          error: true
        })
        return false
      }
    }
  }
}
