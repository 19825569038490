<template>
  <ListItem classes="!mb-0" :table="true" :condensed="true">
    <div class="grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-5 w-full p-4 md:p-0">
      <div v-tooltip="item.text" class="w-full flex flex-col md:flex-row md:items-center">
        <label class="block md:hidden mb-2">Name:</label>
        <Field
          :disabled="true"
          :pt="{ root: '!w-full !min-w-auto lg:!min-w-full lg:!w-full md:border-0' }"
          v-model="item.text"
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Assignees:</label>
        <div class="flex justify-center">
          <Assignees
            @assign="(assignees) => onAssignment(assignees, item, 'id', updateList)"
            :asField="asField"
            btnClass="!h-10"
            v-model="assignees"
          />
        </div>
      </div>

      <div class="flex w-full flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Name:</label>
        <StartDate
          class="w-full h-full"
          :displayEndDate="true"
          :asField="asField"
          :item="item"
          :input="(date, field) => adjustTaskDateRange(item, date, field)"
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Status:</label>
        <Choose
          class="w-full"
          placeholder="Select status"
          btnClass="md:border-0 justify-between w-full md:!rounded-none hover:md:!bg-cement-300"
          v-model="item.item_status"
          @input="(status) => onUpdateStatus(status, item)"
          :staticSet="
            statuses.map((s) => ({
              text: s.name,
              value: s.status
            }))
          "
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Priority:</label>
        <Choose
          class="w-full"
          placeholder="Set priority"
          btnClass="md:border-0 justify-between w-full md:!rounded-none hover:md:!bg-cement-300"
          v-model="item.priority"
          @input="(value) => saveItemField(value, 'item_priority', item.id)"
          :staticSet="
            priorities.map((s) => ({
              text: s.label,
              value: s.key
            }))
          "
        />
      </div>
    </div>
  </ListItem>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import ListItem from './ListItem.vue'
import useTask from '@/components/schedule/Task'
import { priorities, statuses } from '@/components/schedule/Task'
import useBreakpoint from '@/components/composables/Breakpoint'
import StartDate from '@/components/schedule/fields/StartDate.vue'
import Assignees from '@/components/schedule/fields/Assignees.vue'
import eventBus from '@/eventBus'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { item } = toRefs(props)

const assignees = computed(() => item.value.assignees || [])

const { saveItemField, updateStatus, onAssignment, adjustTaskDateRange } = useTask()
const { isMobile, isTablet } = useBreakpoint()

const onUpdateStatus = async (val, selectedItem) => {
  const changes = await updateStatus(val, selectedItem)
  eventBus.$emit('refresh-gantt-items', {
    items: [
      {
        ...changes,
        id: item.value.parent
      }
    ]
  })
}

const asField = computed(() => {
  return isTablet.value || isMobile.value
})
</script>
