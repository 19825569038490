// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'mod',
  
  skipAudit: true,

  fields: {
    mod_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    mod_labor_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
    },
    mod_materials_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
    },
    mod_equipment_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
    },
    country_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'country',
    },
  },
};
