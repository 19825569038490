<template>
  <div class="modals-gutter" :class="{ dip }">
    <slot></slot>
    <component
      v-for="(modal, index) in allModals"
      :key="`${modal.name}-${modal.timeAdded}`"
      :is="modal.name"
      :zIndex="2000 + (index + 1) * 10"
      :style="{
        'z-index': 2000 + (index + 1) * 10
      }"
      :startOpen="true"
      :modal="modal"
    />
  </div>
</template>

<script>
import Accounting from '@/components/modals/AccountingSync.vue'
import AddedAssemblyPreferences from '@/components/modals/AddedAssemblyPreferences.vue'
import AddedItemPreferences from '@/components/modals/AddedItemPreferences.vue'
import Assembly from '@/components/modals/Assembly.vue'
import AssemblyConfirmDimensions from '@/components/modals/AssemblyConfirmDimensions.vue'
import AssemblyNew from '@/components/modals/AssemblyNew.vue'
import AssigneeNew from '@/components/modals/AssigneeNew.vue'
import BudgetCode from '@/components/modals/BudgetCode.vue'
import Client from '@/components/modals/Client.vue'
import ClientNew from '@/components/modals/ClientNew.vue'
import Company from '@/components/modals/Company.vue'
import CompanyNew from '@/components/modals/CompanyNew.vue'
import Confirm from '@/components/modals/Confirm.vue'
import CostItem from '@/components/modals/CostItem.vue'
import CostType from '@/components/modals/CostType.vue'
import CostTypeNew from '@/components/modals/CostTypeNew.vue'
import Dimension from '@/components/modals/Dimension.vue'
import DimensionNew from '@/components/modals/DimensionNew.vue'
import File from '@/components/modals/File.vue'
import Files from '@/components/modals/Files.vue'
import Form from '@/components/modals/Form.vue'
import Invoice from '@/components/modals/Invoice.vue'
import InvoiceNew from '@/components/modals/InvoiceNew.vue'
import LaborType from '@/components/modals/LaborType.vue'
import LaborTypeNew from '@/components/modals/LaborTypeNew.vue'
import LeadRotation from '@/components/modals/LeadRotation.vue'
import LeadRotationNew from '@/components/modals/LeadRotationNew.vue'
import LeadSource from '@/components/modals/LeadSource.vue'
import LeadSourceNew from '@/components/modals/LeadSourceNew.vue'
import Location from '@/components/modals/Location.vue'
import LocationNew from '@/components/modals/LocationNew.vue'
import PaymentMethodNew from '@/components/modals/PaymentMethodNew.vue'
import PaymentNew from '@/components/modals/PaymentNew.vue'
import Prompt from '@/components/modals/Prompt.vue'
import Quote from '@/components/modals/Quote.vue'
import QuoteDocumentViewer from '@/components/modals/QuoteDocumentViewer.vue'
import QuoteNew from '@/components/modals/QuoteNew.vue'
import QuoteSend from '@/components/modals/QuoteSend.vue'
import QuoteWizard from '@/components/modals/QuoteWizard.vue'
import RatingForm from '@/components/modals/RatingForm.vue'
import Receipt from '@/components/modals/Receipt.vue'
import Role from '@/components/modals/Role.vue'
import RoleNew from '@/components/modals/RoleNew.vue'
import Search from '@/components/modals/Search.vue'
import Selector from '@/components/modals/Selector.vue'
import Stage from '@/components/modals/Stage.vue'
import Tax from '@/components/modals/Tax.vue'
import TaxNew from '@/components/modals/TaxNew.vue'
import Template from '@/components/modals/Template.vue'
import TemplateNew from '@/components/modals/TemplateNew.vue'
import TermsAndConditions from '@/components/modals/TermsAndConditions.vue'
import Transactions from '@/components/modals/Transactions.vue'
import TwoFactorAuth from '@/components/modals/TwoFactorAuth.vue'
import TradeType from '@/components/modals/TradeType.vue'
import User from '@/components/modals/User.vue'
import UserNew from '@/components/modals/UserNew.vue'
import Vendor from '@/components/modals/Vendor.vue'
import VendorNew from '@/components/modals/VendorNew.vue'
import Webhook from '@/components/modals/Webhook.vue'
import UpgradeDetails from '@/components/modals/UpgradeDetails.vue'
import OptionalDetails from '@/components/modals/OptionalDetails.vue'
import PresentationOnboarding from '@/components/modals/PresentationOnboarding.vue'
import ContractorRatingNew from '@/components/modals/ContractorRatingNew.vue'
import ClientRatingNew from '@/components/modals/ClientRatingNew.vue'

export default {
  name: 'Modals',
  components: {
    Accounting,
    AddedAssemblyPreferences,
    AddedItemPreferences,
    Assembly,
    AssemblyConfirmDimensions,
    AssemblyNew,
    AssigneeNew,
    BudgetCode,
    Client,
    ClientNew,
    Company,
    CompanyNew,
    Confirm,
    CostItem,
    CostType,
    CostTypeNew,
    Dimension,
    DimensionNew,
    File,
    Files,
    Form,
    Invoice,
    InvoiceNew,
    LaborType,
    LaborTypeNew,
    LeadRotation,
    LeadRotationNew,
    LeadSource,
    LeadSourceNew,
    Location,
    LocationNew,
    PaymentMethodNew,
    PaymentNew,
    Prompt,
    Quote,
    QuoteDocumentViewer,
    QuoteNew,
    QuoteSend,
    QuoteWizard,
    RatingForm,
    Receipt,
    Role,
    RoleNew,
    Search,
    Selector,
    Stage,
    Tax,
    TaxNew,
    TradeType,
    UpgradeDetails,
    OptionalDetails,
    PresentationOnboarding,
    // Grrrr 🤬@todo Find where we invoke Template modals and rename these
    // eslint-disable-next-line vue/no-reserved-component-names
    Template,
    TemplateNew,
    TermsAndConditions,
    Transactions,
    TwoFactorAuth,
    User,
    Vendor,
    VendorNew,
    Webhook,
    UserNew,
    ContractorRatingNew,
    ClientRatingNew
  },
  methods: {
    previewUnderlyingPage() {
      if (this.allModals.length) {
        setTimeout(() => {
          this.dipModals()
          setTimeout(() => {
            this.undipModals()
          }, 3000)
        }, 2000)
      }
    },
    dipModals() {
      this.dip = 1
    },
    undipModals() {
      this.dip = 0
    }
  },
  data() {
    return {
      dip: 0
    }
  },
  computed: {
    allModals() {
      return this.$store.state.modal.visible
    }
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      // Filter out query changes, only dip when an actual path has changed
      if (to.path !== from.path) {
        this.previewUnderlyingPage()
      }
    })
  }
}
</script>
