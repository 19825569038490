import { isDev } from './moduleUtil.js';

export default class AuthError extends Error {
  constructor(data) {
    super(data.hint || data.message || 'Authorization denied.');

    this.userMessage = data.userMessage || 'Authorization denied.';
    this.hintMessage = data.hint || null;

    if (isDev()) {
      console.log(`%c[CostCertified • AuthError]
      • ${this.userMessage || 'No UserMessage'}
      • ${this.message}
      • ${this.hintMessage || 'No hint'}`,
        'color: #ef3c52; font-weight: bold;');
    }
  }
}
