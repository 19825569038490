<template>
  <Choose
    class="w-full"
    btnClass="w-full justify-between h-12"
    placeholder="Save task in assembly"
    v-model="model"
    :staticSet="options"
  >
    <slot />
  </Choose>
</template>

<script setup>
import { defineProps, ref, defineEmits, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits([])

const props = defineProps({
  value: {
    type: [String, Object, Number, null]
  }
})

const store = useStore()

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', value)
})

const options = ref([])

const searchAssemblies = (search = null) => {
  const normalized = store.state.Quote.normalized
  const assemblies = Object.values(normalized).filter(
    (obj) =>
      obj.assembly_name &&
      ((search && obj.assembly_name.includes(search)) || !search) &&
      obj.item_type === 'assembly'
  )
  const formatted = assemblies.map((i) => ({ text: i.assembly_name, value: i.item_id }))
  options.value = formatted
}

onMounted(() => {
  searchAssemblies()
})
</script>
