<template>
  <modal
    v-bind="$props"
    ref="modal"
    :isDirty="bodyIsDirty"
    :clickAway="false"
    :includeGutter="true"
    :hasSidePanel="true"
    :has-header-border="true"
  >
    <template #header>
      <font-awesome-icon icon="house-user" />
      Client
    </template>
    <template #body>
      <client-body ref="body" :forceFull="true" :id="id" />
    </template>
  </modal>
</template>

<script>
import ClientBody from '../bodies/Client.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalClient',
  mixins: [ObjectModalMixin],
  components: { ClientBody },
  props: {
    type: {
      default: 'client'
    }
  }
}
</script>
