<template>
  <div class="sub-nav--container font-header">
    <div
      class="sub-nav--btn"
      v-for="(page, index) in processedPages"
      :key="page.title"
      :badge="page.badge"
      @click="triggerPage(page, index)"
      :data-label="getPageLabel(page)"
      :class="{
        active: active === index,
        primary: active === index,
        [page.class || 'default']: true
      }"
    >
      <div class="sub-nav--indicator"></div>
      <font-awesome-icon class="sub-nav--icon" v-if="page.icon" :icon="page.icon" />
      <span class="sub-nav--text mr-2 lg:text-xl">{{ page.title }}</span>
      <Badge v-if="page.badge && typeof page.badge !== 'boolean'" :value="page.badge" />
    </div>
  </div>
</template>

<script>
import Badge from 'primevue/badge'

/**
 * SUBNAV
 *
 * Provide a pages prop with a schema like:
 * [{
 *  to: '/clients',
 *  title: 'Clients',
 *  active: [
 *    '/clients',
 *  ],
 *  badge: 12,
 *  icon: 'user',
 * }, {
 *  to: '/pipeline',
 *  title: 'Pipeline',
 *  active: [ // either or
 *    '/dashboard',
 *    '/pipeline',
 *  ],
 *  badge: true,
 *  icon: 'stack-overflow',
 * }]
 *
 */
export default {
  name: 'SubNav',
  emits: ['input'],
  data() {
    return {}
  },
  watch: {},
  computed: {
    active() {
      if (this.value !== null) {
        return this.value
      }

      const currentPath = this.$route.path
      const found = this.pages.find((p) =>
        p.to instanceof RegExp ? p.to.test(currentPath) : currentPath.includes(p.to)
      )
      return this.pages.indexOf(found)
    },
    processedPages() {
      return this.pages
    }
  },
  methods: {
    getPageLabel(page) {
      return `nav-${c.idFromText(page.title)}`
    },
    async triggerPage(page, index) {
      if (page.to) return this.go(page.to)

      this.$emit('input', index)

      return true
    },
    go(page) {
      return this.$store.dispatch('to', page)
    }
  },
  components: {
    Badge
  },
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    value: {
      default: null
    }
  },
  mounted() {
    const $active = $(this.$el).find('.active')
    if ($active.length) $active[0].scrollIntoView()
  },
  beforeUnmount() {},
  activated() {
    const $active = $(this.$el).find('.active')
    if ($active.length) $active[0].scrollIntoView()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$inactiveColor: $cool-gray-600;
$bgcolor: $cool-gray-300;
$indicatorWidth: 4px;
$scrollBarWidth: 2px;

.sub-nav--container {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  padding: 0 0 0 0;

  transition: transform 0.5s ease-out 0.6s;
  background: transparent;
  height: 56px;
  padding: 0;
  width: 100%;
  overflow: -moz-scrollbars-none;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;

  &::-webkit-scrollbar {
    width: $scrollBarWidth;
    height: $scrollBarWidth;
    display: block;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    width: $scrollBarWidth;
    height: $scrollBarWidth;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: $cool-gray-500;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba($cool-gray-500, 0.25);
  }

  .sub-nav--btn {
    flex: 1 1 auto;
    height: 100%;
    max-width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
    padding: 0 1em;
    word-wrap: nowrap;
    white-space: nowrap;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: $z-layout;

    color: $pitch-black;
    max-width: 100%;
    min-width: 10em;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    .sub-nav--icon {
      margin-right: 0.5em;
      line-height: 1;
      transition: none;
    }

    .sub-nav--text {
      z-index: 1;
      transition: none;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      display: inline;
    }

    .sub-nav--indicator {
      height: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $cool-gray-200 !important;
      z-index: 0;
      display: block;
      will-change: height;
      transition: height 0.2s ease;
      z-index: -1;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $pitch-black !important;
      position: sticky;
      left: 0;
      right: 0;
      z-index: $z-layout + 1;

      .sub-nav--indicator {
        height: 100%;
        z-index: -1;
      }
    }
  }
}
</style>
