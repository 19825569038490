import AdyenPayFacMixin from './adyen/AdyenPayFacMixin'
import StripePayFacMixin from './stripe/StripePayFacMixin'

/*
  Abstract payfac mixin
*/
export default {
  data() {
    return {
      merchantId: null,
      linkToken: null,
      hmac: null,
      doesPayeeCoverBolsterFee: true,
      doesPayeeCoverCreditCardFee: false,
      forceHideTransactionFee: false
    }
  },
  // Add our current payfac services
  mixins: [AdyenPayFacMixin, StripePayFacMixin],
  computed: {
    isPayfacEnabled() {
      return (
        this.$store.state.session.company &&
        this.$store.state.session.company.aoModules &&
        parseInt(this.$store.state.session.company.aoModules.payments_v2, 10) === 1
      )
    },
    payFacService() {
      if (this.isPayfacEnabled) {
        return 'adyen'
      }
      return 'stripe'
    },
    isPaymentsOnboarded() {
      return (
        this.$store.state.session.company &&
        this.$store.state.session.company.counterparty_status &&
        (this.$store.state.session.company.counterparty_status === 'a' ||
          this.$store.state.session.company.counterparty_status === '#')
      )
    },
    isCompanyOnboardedWithoutBusinessAccount() {
      const company = this.$store.state.session.company
      return (
        (company.counterparty_status === 'a' ||
          company.counterparty_status === '#' ||
          company.counterparty_status === 's') &&
        company.company_has_onboarded &&
        !company.company_onboarded_on_bolster_time_completed
      )
    },
    counterpartyStatus() {
      return this.$store.state.session.company.counterparty_status
    },
    counterpartyCountry() {
      return this.$store.state.session.company.country_abbr
    },
    isCompanyLocatedInUS() {
      return this.counterpartyCountry === 'us'
    }
  },
  methods: {
    async markOnboardingAsComplete() {
      const data = {
        company_has_onboarded: 1,
        company_onboarded_on_bolster_time_completed: new Date().valueOf()
      }
      await this.$store.dispatch('Company/submitOnboardingData', {
        companyId: this.$store.state.session.scope.company,
        data
      })
    },
    /**
     * Get the merchant category codes based on payfac
     * @returns {array}
     */
    getMccCodes() {
      return this[`${this.payFacService}MccCodes`]
    },
    /**
     * Map the onboarding data to the expected format of our BE
     * @param {object} data
     * @returns {Promise<*>}
     */
    mapDataToPayfac(data) {
      return this[`${this.payFacService}MapDataToPayfac`](data)
    },
    /**
     * After the onboarding wizard is done handle the onboarding
     * @param {object} data
     * @returns {Promise<*>}
     */
    handleOnboardPayee(data) {
      return this[`${this.payFacService}OnboardPayee`](data)
    },
    initializePayments() {
      return this[`${this.payFacService}Initialize`]()
    },
    payInvoice() {
      return this[`${this.payFacService}PayInvoice`]()
    },
    handleGetBusinessAccountTermsOfService(data) {
      return this[`${this.payFacService}GetBusinessAccountTermsOfService`](data)
    },
    handleGetUploadedBusinessAccountTermsOfService(data) {
      return this[`${this.payFacService}GetUploadedBusinessAccountTermsOfService`](data)
    },
    handleOnboardBusinessAccount(data) {
      return this[`${this.payFacService}OnboardBusinessAccount`](data)
    }
  }
}
