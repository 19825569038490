<template>
  <card-section>
    <template #title> This transaction includes payment for the following items: </template>
    <template #subtitle> Amounts include any sales taxes paid. </template>

    <table class="table table-condensed table-striped mt-1">
      <tbody>
        <tr v-for="(item, index) in object.aoItems" :key="index">
          <td>{{ item.item_name }}</td>
          <td class="text-right text-monospace text-info">
            <strong>{{ $f.currency(item.split_amount) }}</strong>
          </td>
        </tr>
        <tr class="bg-info text-white">
          <td><strong>Total (includes taxes)</strong></td>
          <td class="text-right text-monospace">
            <strong>{{ $f.currency(object.transaction_gross) }}</strong>
          </td>
        </tr>
        <tr>
          <td style="font-style: italic">Sales taxes in this transaction</td>
          <td class="text-right text-monospace text-info" style="font-style: italic">
            {{ $f.currency(object.transaction_tax) }}
          </td>
        </tr>
        <tr>
          <td style="font-style: italic">Before-tax amount</td>
          <td class="text-right text-monospace text-info" style="font-style: italic">
            {{ $f.currency(object.transaction_net) }}
          </td>
        </tr>
      </tbody>
    </table>
  </card-section>
</template>

<script>
export default {
  name: 'TransactionBreakdown',
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
