<template>
  <div
    class="acceptterms-container !shadow-none"
    :class="{
      headless
    }"
    style="width: 100%"
  >
    <CardListItem v-if="showInlineTerms">
      <ScrollContainer style="max-height: 300px" class="p-0">
        <TermsAndConditions />
        <hr />
        <PrivacyPolicy />
      </ScrollContainer>
    </CardListItem>
    <span>
      <span v-if="showCheck" ref="approvalMessage">
        I have read and accept the terms and conditions and privacy policy <slot></slot>
      </span>

      <Btn
        severity="primary-black"
        size="lg"
        class="!py-2 !px-4"
        @click="() => $refs.legal.open()"
        v-if="!showInlineTerms"
      >
        <font-awesome-icon icon="arrow-up-right-from-square" class="mr-2" />
        Review and accept terms
      </Btn>
    </span>

    <div v-if="showCheck">
      <checkbox v-model="accept" ref="checkbox" class="info" />
    </div>

    <modal
      ref="legal"
      :closeable="false"
      :show-close-button="false"
      :click-away="false"
      :hasHeaderBorder="true"
    >
      <template #header>
        <font-awesome-icon icon="list" />
        Terms and conditions
      </template>
      <template #body>
        <ScrollContainer
          ref="termsContainer"
          style="max-height: 60vh; border-radius: 0.3em"
          class="mt-4"
        >
          <TermsAndConditions />
          <hr />
          <PrivacyPolicy />
        </ScrollContainer>
        <div class="flex flex-row mt-10 gap-x-4">
          <FormButton text="I don't accept the terms" @click="rejectTerms" muted showLoading />
          <FormButton text="I accept the terms" @click="acceptTerms" showLoading />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import TermsAndConditions from './TermsAndConditions.vue'
import PrivacyPolicy from './PrivacyPolicy.vue'
import eventBus from '../../eventBus'

export default {
  name: 'AcceptTerms',

  components: { PrivacyPolicy, TermsAndConditions, FormButton },

  props: {
    showInlineTerms: {
      type: Boolean,
      default: false
    },
    forceView: {
      default: true
    },
    value: {
      default: false
    },
    companyId: {
      default: null
    },
    userId: {
      default: null
    },
    token: {
      default: null
    },
    headless: {
      type: Boolean,
      default: false
    },
    forUser: {
      type: Boolean,
      default: true
    },
    forCompany: {
      type: Boolean,
      default: true
    },
    showCheck: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input', 'termsLoaded'],

  methods: {
    async saveAcceptance() {
      if (this.forCompany) {
        try {
          this.saveAcceptanceForCompany()
        } catch (e) {
          //
        }
      }
      if (this.forUser) {
        try {
          this.saveAcceptanceForUser()
        } catch (e) {
          //
        }
      }
    },

    async saveAcceptanceForCompany() {
      if (!this.getTermsText()) return false
      if (!this.companyId) return false

      const data = {
        ...this.getSaveFieldsForCompany(),
        company_id: this.companyId,
        type: 'company'
      }
      await this.$store.dispatch('Company/submitOnboardingData', {
        companyId: this.companyId,
        data
      })
    },

    async saveAcceptanceForUser() {
      if (!this.getTermsText()) return false
      if (!this.userId) return false

      return this.$store.dispatch('ajax', {
        path: 'user/updateMultiple',
        token: this.token,
        setToken: !this.token,
        data: [
          {
            ...this.getSaveFieldsForUser(),
            user_id: this.userId,
            type: 'user'
          }
        ]
      })
    },

    getSaveFieldsForCompany() {
      return {
        oTermsAcceptance: {
          acceptedBy: this.userId,
          timeAccepted: Date.now(),
          termsAccepted: this.getTermsText()
        }
      }
    },

    getSaveFieldsForUser() {
      return {
        user_terms: this.getTermsText(),
        user_terms_time_accepted: Date.now()
      }
    },

    getTermsText() {
      return `I have read and accept the terms and conditions and privacy policy: ${(this.$refs.termsContainer && this.$refs.termsContainer.$el.innerHTML) || ''}`
    },

    async acceptTerms() {
      this.saveAcceptance()
      await c.throttle(() => this.$nextTick())
      this.$emit('input', 1)
      if (this.$refs.legal) this.$refs.legal.close()
    },

    rejectTerms() {
      this.$refs.legal.close()
      this.$emit('input', 0)
    },

    open() {
      this.$refs.legal.open()

      let resolution
      const promise = new Promise((resolve) => {
        resolution = resolve
      })

      eventBus.$once('input', (val) => resolution(val))

      return promise
    }
  },

  created() {
    c.throttle(
      () => {
        this.$emit('termsLoaded', this.getTermsText())
      },
      {
        delay: 500
      }
    )
  },

  computed: {
    accept: {
      get() {
        return this.value
      },
      async set(b) {
        if (b) {
          if (this.forceView && !this.showInlineTerms) {
            this.$refs.checkbox.fieldValue = 0
            return this.$refs.legal.open()
          }
          return this.acceptTerms()
        }

        return this.rejectTerms()
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.acceptterms-container {
  &.headless {
    height: 0 !important;
    width: 0 !important;
    min-width: unset !important;
    min-height: unset !important;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
  }
}
</style>
