/**
 * This mixin allows you to treat the object it is added to
 *   as a button, with addLoading(), removeLoading(), and
 *   endLoading() functionality. It tracks loading state
 *   in the 'loading' state data point
 */
export default {
  data() {
    return {
      loading: 0
    }
  },
  emits: ['loading', 'done', 'click', 'tap'],
  watch: {
    loading(l) {
      this.$emit('loading', l)
      if (l === 0) {
        this.$emit('done')
      }
    }
  },
  computed: {
    disableButton() {
      return this.disabled || this.loadingLocal > 0
    }
  },
  methods: {
    click(e) {
      if (!this.disabled) {
        this.addLoading()
        // Must remain setTimeout,
        //  insteead of setImmediate or $nextTick, because of a problem
        //  forcing clicks on file inputs
        setTimeout(() => {
          this.$emit('click', { ...e, button: this })
          this.$emit('tap', { ...e, button: this })
          this.removeLoading()
        }, 0)
      }
    },
    addLoading() {
      this.loading += 1
    },
    removeLoading() {
      if (this.loading > 0) this.loading -= 1
      else this.loading = 0
    },
    endLoading() {
      this.loading = 0
    }
  },
  props: {
    disabled: {
      default: false
    },
    text: {
      type: String,
      default() {
        return 'Button'
      },
      required: false
    },
    glyph: {
      type: String,
      default() {
        return ''
      },
      required: false
    },
    loadingColor: {
      type: String,
      default: '#1abc9c'
    },
    badge: {
      default: false
    }
  }
}
