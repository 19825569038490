import {isProd} from "../moduleUtil.js";

const benchTmp = {};
const startEndBenches = {};
const benches = {
  general: [],
};
const keyColors = {
  general: '#1f92fc',
};
const isBrowser = () => navigator && navigator.userAgent;
const getTimeStamp = () => {
  if (isBrowser()) {
    return 'miliseconds';
  } else {
    return 'microseconds';
  }
};
const getTime = () => {
  if (isBrowser()) {
    return Date.now();
  } else {
    const timestamp = process.hrtime();
    return timestamp[0] * 1000000 + timestamp[1] / 1000;
  }
};

const getKeyColor = (key = 'general') => {
  if (!(key in keyColors)) {
    keyColors[key] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  return keyColors[key];
};

const getLast = (key = 'general') => key in benches
  && (benches[key].length ? benches[key][benches[key].length - 1] : null);

const getLastStartEnd = (key = 'general') => startEndBenches[key];

const getLastTime = (key = 'general') => {
  const last = getLast(key);

  if (!last) return getTime();

  return last.time;
};

const getStart = (key = 'general') => key in benches
  && (benches[key].length ? benches[key][0] : null);

const getStartTime = (key = 'general') => {
  const first = getStart(key);

  if (!first) return getTime();

  return first.time;
};

const getBenchName = (name = null) => {
  if (name) return name;

  const stack = (new Error()).stack.split(/\r?\n/);
  return stack[3].trim();
};

const clear = (key = 'general') => {
  benches[key] = [];
};

const clearStartEnd = (key = 'general') => {
  delete startEndBenches[key];
};

const get = (key = null) => benches[key];

const start = (key = 'general') => {
  if (isProd() && isBrowser()) return;
  benchTmp[key] = getTime();
};

const end = (key = 'general') => {
  if (isProd() && isBrowser()) return;
  const lastBench = getLastStartEnd(key);
  const currTime = getTime();
  if (!benchTmp[key]) {
    console.warn(`[PERF] No start for ${key}`);
    return;
  }
  const timeTaken = currTime - benchTmp[key];
  const bench = {
    name: key,
    time: timeTaken,
    total: lastBench ? timeTaken + lastBench.total : timeTaken,
    average: lastBench ? (timeTaken + lastBench.total) / (lastBench.totalBenches + 1) : timeTaken,
    slowest: lastBench ? Math.max(timeTaken, lastBench.slowest) : timeTaken,
    totalBenches: lastBench ? lastBench.totalBenches + 1 : 1,
  };
  startEndBenches[key] = bench;
  delete benchTmp[key];
  return bench;
};

const bench = (name = null, key = 'general', resetAfter = false) => {};

const log = (title = '', times = () => {}) => {
  if(isProd() && isBrowser()) return;
  console.debug(`
   ----------------------------
   ${title}
   ----------------------------
   ${times()}
    ----------------------------
  `);
};

const logReport = (keys = []) => {
  if(isProd() && isBrowser()) return;
  const timeStamp = getTimeStamp();
  const sortedAverages = [];
  const sortedTotals = [];
  const sortedSlowest = [];
  keys.forEach(key => {
    const bench = startEndBenches[key];
    if(!bench) return;
    sortedAverages.push([key, bench.average, bench.totalBenches]);
    sortedTotals.push([key, bench.total]);
    sortedSlowest.push([key, bench.slowest]);
    clearStartEnd(key);
  });
  sortedAverages.sort((a, b) => b[1] - a[1]);
  sortedTotals.sort((a, b) => b[1] - a[1]);
  sortedSlowest.sort((a, b) => b[1] - a[1]);
  log('TOTAL EQUATION TIME', () => {
    return sortedTotals.map(bench => `\t${bench[0]}: ${bench[1]} ${timeStamp}`).join('\n');
  });
  log('SLOWEST EQUATION TIME', () => {
    return sortedSlowest.map(bench => `\t${bench[0]}: ${bench[1]} ${timeStamp}`).join('\n');
  });
  log('AVERAGE EQUATION TIME', () => {
    return sortedAverages.map(bench =>
      `\t${bench[0]}: ${bench[1]} ${timeStamp}, was called ${bench[2]} times`)
      .join('\n');
  });
};

export default {
  bench,
  clear,
  clearStartEnd,
  get,
  start,
  end,
  logReport,
  startEndBenches,
  log,
  getTimeStamp,
};
