import NormalizeUtilities from './NormalizeUtilities';
import Objects from './Objects';

const { buildDefaultObject } = Objects;

const normalize = (obj,
  overrideRefIds = false,
  forceRootRefId = null,
  currentRefIds = [],
  formatter = buildDefaultObject) => NormalizeUtilities.normalize(obj,
    overrideRefIds,
    forceRootRefId,
    currentRefIds,
    formatter);

export default {
  ...NormalizeUtilities,
  normalize,
};
