<template>
  <div
    v-if="matchesSearchQuery && object && showItem"
    class="relative ml-0.5 pt-1 flex items-center cursor-pointer pb-1 text-base"
    :class="[
      disabled.includes(refId) ? 'text-cool-gray-400' : 'text-cool-gray-600',
      activeItem === refId ? 'font-medium text-pitch-black' : '',
      `max-w-[calc(100%-${16 * object.asAssemblyPath.length})]`
    ]"
  >
    <!-- Tree arm -->
    <div
      class="absolute flex-shrink-0 w-[1px] h-full border-l border-surface-200"
      :class="{
        'mt-4': index === 0 && object.aoChildren.length > 1
      }"
    />
    <div
      class="flex-shrink-0 h-[1px] border-t"
      :class="[object.aoChildren.length > 0 ? 'w-3 border-transparent' : 'w-3 border-surface-200']"
    />
    <!--  Assembly item  -->
    <div v-if="object.type === 'assembly'" class="w-full">
      <div
        :id="`title-${refId}`"
        :data-parent="parentRefId"
        class="w-full flex text-base font-normal text-pitch-black"
        @click="toggleSection"
      >
        <span
          class="cursor-pointer w-3 h-full flex justify-center items-center pr-2 mt-0.5"
          v-if="hasChildren"
        >
          <font-awesome-icon
            :icon="isSectionOpen() ? 'chevron-down' : 'chevron-right'"
            class="chevron-icon text-surface-500 text-base mt-0.5"
            fixed-width
          />
        </span>
        <div class="grid grid-cols-12 w-full items-center">
          <div class="w-full flex items-center col-span-9">
            <span
              @click="scrollTo"
              class="max-w-[85%] w-fit truncate"
              v-tooltip="itemName"
              v-html="highlightMatch(itemName)"
            ></span>
            <span
              class="flex-grow mr-1 ml-2 border-dotted border-t border-surface-200"
              v-if="isNotInteractiveItem"
            />
          </div>
          <span
            v-if="showPrice && childPrice > 0"
            class="whitespace-nowrap text-thin text-sm w-full text-right col-span-3"
            >{{ $f.currency(itemPrice) }}</span
          >
          <span
            v-else-if="optional && !included"
            class="whitespace-nowrap text-thin text-sm text-right col-span-3"
            >Optional</span
          >
          <span
            v-else-if="
              ((showPrice && childPrice <= 0 && isNotInteractiveItem) || !showPrice) && qty
            "
            class="whitespace-nowrap text-thin text-sm w-full text-right col-span-3"
            >Included</span
          >
        </div>
      </div>
      <!-- If the assembly itself is an interactive item that has selections or optionals -->
      <div
        v-if="itemType(refId) && isSectionOpen()"
        class="relative ml-0.5 pt-1 flex items-center cursor-pointer pb-1"
      >
        <!-- Tree arm -->
        <div
          class="absolute flex-shrink-0 w-[1px] h-full border-l border-surface-200"
          :class="{
            'mt-4': index === 0 && object.aoChildren.length > 1
          }"
        />
        <div class="flex-shrink-0 h-[1px] border-t w-3 border-surface-200 mr-1" />
        <font-awesome-icon
          icon="lock"
          v-if="disabled.includes(refId)"
          class="chevron-lock mt-1"
          fixed-width
        />
        <div
          v-if="getItemIconData(refId).icon"
          class="bg-pitch-black rounded mr-2 flex items-center justify-center"
        >
          <div
            class="bg-pitch-black text-level-yellow w-6 h-6 flex justify-center items-center rounded-sm"
          >
            <font-awesome-icon
              class="text-level-yellow"
              v-if="!disabled.includes(refId)"
              :icon="getItemIconData(refId).icon"
              fixed-width
            />
          </div>
        </div>
        <div
          class="grid grid-cols-12 w-full items-center"
          :class="{ 'opacity-30': disabled.includes(refId) }"
        >
          <div class="w-full flex items-center col-span-9">
            <div class="max-w-[85%] w-fit truncate" @click="scrollTo" v-tooltip="itemName">
              <span class="hover:text-pitch-black" v-html="highlightMatch(itemName)"></span>
            </div>
            <span
              class="flex-grow mx-1 border-dotted border-t border-surface-200"
              v-if="isNotInteractiveItem"
            />
          </div>

          <span
            v-if="showPrice && childPrice > 0"
            class="whitespace-nowrap text-thin text-sm text-right col-span-3"
            >{{ $f.currency(childPrice) }}</span
          >
          <span
            v-else-if="optional && !included"
            class="whitespace-nowrap text-thin text-sm text-right col-span-3"
            >Optional</span
          >
          <span
            v-else-if="
              ((showPrice && childPrice <= 0 && isNotInteractiveItem) || !showPrice) && qty
            "
            class="whitespace-nowrap text-thin text-sm text-right col-span-3"
            >Included</span
          >
        </div>
      </div>
      <!-- Render assembly children using recursive component -->
      <div v-if="isSectionOpen()">
        <RecursiveTOCItem
          v-for="(childRef, childIndex) in groups"
          :key="childRef"
          :ref-id="childRef"
          :parent-ref-id="refId"
          :normalized="normalized"
          :disabled="disabled"
          :active-item="activeItem"
          :open-sections="openSections"
          :search-query="searchQuery"
          :index="childIndex"
          :itemIcons="itemIcons"
          :presentationSettings="presentationSettings"
          @item-click="$emit('item-click', $event)"
          @update:openSections="updateOpenSections"
        />
      </div>
    </div>
    <!-- Standard child item -->
    <div v-else class="quote-presentation-toc--title flex text-base w-full ml-1">
      <font-awesome-icon
        icon="lock"
        v-if="disabled.includes(refId)"
        class="chevron-lock mt-1"
        fixed-width
      />
      <div
        v-if="getItemIconData(refId).icon"
        class="bg-pitch-black rounded flex items-center justify-center mr-2"
      >
        <div
          class="bg-pitch-black text-level-yellow w-6 h-6 flex justify-center items-center rounded-sm"
        >
          <font-awesome-icon
            class="text-level-yellow"
            v-if="!disabled.includes(refId)"
            :icon="getItemIconData(refId).icon"
            fixed-width
          />
        </div>
      </div>
      <div
        class="grid grid-cols-12 w-full items-center"
        :class="{ 'opacity-30': disabled.includes(refId) }"
      >
        <div class="w-full flex items-center col-span-9">
          <div class="max-w-[85%] w-fit truncate" @click="scrollTo" v-tooltip="itemName">
            <span class="hover:text-pitch-black" v-html="highlightMatch(itemName)"></span>
          </div>
          <span
            class="flex-grow mx-1 border-dotted border-t border-surface-200"
            v-if="isNotInteractiveItem"
          />
        </div>

        <span
          v-if="showPrice && childPrice > 0"
          class="whitespace-nowrap text-thin text-sm text-right col-span-3"
          >{{ $f.currency(childPrice) }}</span
        >
        <span
          v-else-if="optional && !included"
          class="whitespace-nowrap text-thin text-sm text-right col-span-3"
          >Optional</span
        >
        <span
          v-else-if="((showPrice && childPrice <= 0 && isNotInteractiveItem) || !showPrice) && qty"
          class="whitespace-nowrap text-thin text-sm text-right col-span-3"
          >Included</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import useSelections from '@/components/composables/UseSelections'
import SelectionItemMixin from '@/components/quote/presentation/SelectionItemMixin'

export default {
  name: 'RecursiveTOCItem',
  mixins: [SelectionItemMixin],
  props: {
    refId: {
      type: String,
      required: true
    },
    parentRefId: {
      type: String,
      required: true
    },
    normalized: {
      type: Object,
      required: true
    },
    disabled: {
      type: Array,
      required: true
    },
    activeItem: {
      type: String,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    openSections: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      required: true
    },
    itemIcons: {
      type: Object,
      required: true
    },
    presentationSettings: {
      type: Object
    }
  },
  computed: {
    object() {
      return this.normalized[this.refId]
    },
    itemName() {
      return (
        this.object.oMeta?.optionGroupName ||
        this.object.item_name ||
        this.object.assembly_name ||
        this.object.cost_type_name ||
        'Selection item'
      )
    },
    selections() {
      return useSelections(this.normalized)
    },
    itemPrice() {
      return (this.object.cost_item_price_net_base || this.object.quote_subtotal_net || 0).toFixed(
        2
      )
    },
    hasChildren() {
      return this.object?.aoChildren && this.object.aoChildren.length > 0
    },
    groups() {
      const groups = this.object?.aoChildren || []
      return groups.filter((ref) => this.selections.shouldShowItem(ref))
    },
    matchesSearchQuery() {
      const query = this.searchQuery.toLowerCase()

      if (!query) return true

      const itemName = this.itemName.toLowerCase()

      if (itemName.includes(query)) return true

      if (this.hasChildren) {
        return this.object.aoChildren.some((childRef) =>
          this.checkChildMatchesSearchQuery(childRef, query)
        )
      }

      return false
    },
    childPrice() {
      const item = this.normalized[this.refId]
      return ((item.cost_item_price_net_base || item.quote_subtotal_net || 0) * 1).toFixed(2)
    },
    inheritedPriceVisibility() {
      const inheritedVisibility =
        this.object.cost_item_show_itemized_prices || this.object.quote_show_itemized_prices

      const isAssembly = (this.object.item_type || this.object.type) === 'assembly'
      if (isAssembly) {
        const inheritedAssemblyVisibility =
          this.presentationSettings?.showAssemblyPrices &&
          this.presentationSettings?.showItemizedPrices
        return inheritedVisibility && inheritedAssemblyVisibility
      }

      const inheritedItemVisibility =
        this.presentationSettings?.showCostItemPrices &&
        this.presentationSettings?.showItemizedPrices
      return inheritedVisibility && inheritedItemVisibility
    },
    visibility() {
      const inheritedSettings = {
        price: this.inheritedPriceVisibility,
        qty: this.presentationSettings?.showQuantities,
        isVisible: 1
      }
      if (this.presentationSettings?.forceTemplateSettings) {
        return inheritedSettings
      }
      return (this.object.oViewOptions && this.object.oViewOptions.pres) || inheritedSettings
    },
    showPrice() {
      return this.visibility.price
    },
    showItem() {
      const isAssembly = this.object.type === 'assembly'
      return isAssembly ? this.object.assembly_emphasis >= 0 : this.visibility.isVisible
    },
    isNotInteractiveItem() {
      const itemType = this.object.oMeta?.itemType
      const metaTypes = ['gallery', 'text']
      return !metaTypes.includes(itemType)
    }
  },
  methods: {
    checkChildMatchesSearchQuery(ref, query) {
      const itemName = this.normalized[ref]?.item_name?.toLowerCase() || ''

      if (itemName.includes(query)) return true

      const hasChildren = this.normalized[ref]?.aoChildren?.length > 0

      if (hasChildren) {
        return this.normalized[ref].aoChildren.some((childRef) =>
          this.checkChildMatchesSearchQuery(childRef, query)
        )
      }

      return false
    },
    toggleSection() {
      if (this.isSectionOpen()) {
        this.$emit(
          'update:openSections',
          this.openSections.filter((section) => section !== this.refId)
        )
      } else if (this.object.type === 'assembly') {
        this.$emit('update:openSections', [...this.openSections, this.refId])
      }
    },
    isSectionOpen() {
      return this.openSections.includes(this.refId)
    },
    updateOpenSections(newOpenSections) {
      this.$emit('update:openSections', newOpenSections)
    },
    scrollTo() {
      this.$emit('item-click', this.refId)
    },
    getItemIconData(ref) {
      const itemType = this.itemType(ref)
      return this.itemIcons[itemType] || { icon: '', color: '' }
    },
    highlightMatch(text) {
      if (!this.searchQuery) return text
      const regex = new RegExp(`(${this.searchQuery})`, 'gi')
      return text.replace(regex, '<span class="text-pitch-black font-medium">$1</span>')
    },
    itemType(ref) {
      if (this.normalized[ref].cost_item_is_optional || this.normalized[ref].assembly_is_optional)
        return 'Optional'
      if (
        (this.normalized[ref].aoAddons && this.normalized[ref].aoAddons.length) ||
        this.normalized[ref].cost_type_is_variation_parent
      )
        return 'Selections'
      return null
    }
  }
}
</script>
