import { computed, ref } from 'vue'
import { getAudience } from '@/../imports/api/Item'

export default function useSelections(normalized) {
  const norm = ref(normalized)
  const rootRefId = computed(() => c.getNormalizedRootRefId(norm.value))

  const preferredOrder = computed(() => {
    const preferredOrder = []
    const goDown = (children) => {
      children.forEach((childRef) => {
        preferredOrder.push(childRef)
        if (norm.value[childRef]?.aoChildren) goDown(norm.value[childRef]?.aoChildren)
      })
    }
    const firstChildren = norm.value[rootRefId.value].aoChildren
    goDown(firstChildren)
    return preferredOrder
  })

  const shouldShowItem = (ref) => {
    const item = norm.value[ref]
    return !(!item || item.oMeta?.itemType === 'task' || getAudience(item) === 'company')
  }

  const quoteItemRefs = computed(() => {
    return Object.keys(norm.value)
  })

  const metaRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) =>
        norm.value[ref].oMeta?.itemType === 'gallery' || norm.value[ref].oMeta?.itemType === 'text'
    )
  })

  const variationRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) =>
        norm.value[ref].variation_parent_cost_type_id ||
        norm.value[ref].cost_type_is_variation_parent
    )
  })

  const optionalRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) => norm.value[ref].cost_item_is_optional || norm.value[ref].assembly_is_optional
    )
  })

  const excludedRefs = computed(() => {
    return quoteItemRefs.value.filter((ref) => norm.value[ref].cost_item_is_included === 0)
  })

  const inputRefs = computed(() => {
    return Object.keys(norm).filter((ref) => norm.value[ref]?.oInputRequired?.inputs?.length)
  })

  const addonRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) => norm.value[ref].aoAddons && norm.value[ref].aoAddons.length
    )
  })

  const emphasizedRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) =>
        (norm.value[ref].assembly_emphasis && norm.value[ref].assembly_emphasis > 0) ||
        (norm.value[ref].cost_type_emphasis && norm.value[ref].cost_type_emphasis > 0)
    )
  })

  const interactiveRefs = computed(() => {
    const refs = [
      ...optionalRefs.value,
      ...variationRefs.value,
      ...inputRefs.value,
      ...addonRefs.value
    ]
    return _.uniq(refs)
      .filter((ref) => shouldShowItem(ref) && !excludedRefs.value.includes(ref))
      .sort((a, b) => preferredOrder.value.indexOf(a) - preferredOrder.value.indexOf(b))
  })

  const standardRefs = computed(() => {
    return quoteItemRefs.value.filter((ref) => !interactiveRefs.value.includes(ref))
  })

  const allRefs = computed(() => {
    return _.uniq([...interactiveRefs.value, ...standardRefs.value])
      .filter((ref) => shouldShowItem(ref))
      .sort((a, b) => preferredOrder.value.indexOf(a) - preferredOrder.value.indexOf(b))
  })

  const hiddenAssemblyRefs = computed(() => {
    return allRefs.value.filter((ref) => {
      const item = norm.value[ref]
      const isAssembly = norm.value[ref]?.type === 'assembly'
      return isAssembly && item.assembly_emphasis < 0
    })
  })

  const hiddenAssemblyChildren = computed(() => {
    const visited = new Set()
    const childrenRefs = new Set()

    const collectHiddenChildren = (assemblyRefs) => {
      assemblyRefs.forEach((ref) => {
        if (visited.has(ref)) return
        visited.add(ref)

        const item = norm.value[ref]
        if (item?.aoChildren?.length) {
          item.aoChildren.forEach((childRef) => {
            childrenRefs.add(childRef)
            const isChildAssembly = norm.value[childRef]?.type === 'assembly'
            if (isChildAssembly) {
              collectHiddenChildren([childRef])
            }
          })
        }
      })
    }

    collectHiddenChildren(hiddenAssemblyRefs.value)
    return Array.from(childrenRefs)
  })

  const hiddenRefs = computed(() => {
    const hiddenAssembliesSet = hiddenAssemblyRefs.value
    const hiddenChildrenSet = hiddenAssemblyChildren.value

    const combinedHiddenSet = new Set([...hiddenAssembliesSet, ...hiddenChildrenSet])

    return Array.from(combinedHiddenSet)
  })

  const allVisibleRefs = computed(() => {
    const hiddenAssembliesSet = new Set(hiddenAssemblyRefs.value)
    const hiddenChildrenSet = new Set(hiddenAssemblyChildren.value)

    return allRefs.value.filter((ref) => {
      if (hiddenAssembliesSet.has(ref) || hiddenChildrenSet.has(ref)) return false
      return ref
    })
  })

  return {
    quoteItemRefs,
    variationRefs,
    optionalRefs,
    excludedRefs,
    inputRefs,
    addonRefs,
    emphasizedRefs,
    interactiveRefs,
    standardRefs,
    allRefs,
    hiddenAssemblyRefs,
    hiddenAssemblyChildren,
    hiddenRefs,
    allVisibleRefs,
    metaRefs,
    shouldShowItem,
    preferredOrder,
    getAudience
  }
}
