<template>
  <div class="text-pitch-black rounded-lg">
    <div class="mb-6">
      <p class="mb-2">You worked with</p>
      <div class="border border-cool-gray-500 rounded-md p-2">
        <div class="flex justify-center items-center mb-2">
          <PersonAvatar
            class="mx-2"
            :id="object.contractorId"
            type="user"
            :name="object.contractorName"
            :onClick="openSidebar"
          />
          <div>
            <div class="text-sm"></div>
          </div>
        </div>
        <div class="">
          <p class="font-bold mb-1 flex justify-center items-center">
            <span>
              {{ $f.truncate(object.quoteName || 'Unnamed', 30) }}
            </span>
            <span>
              {{ $f.currency(object.quotePriceNet) }}
            </span>
          </p>
          <p class="flex justify-center items-center">
            You worked with {{ $f.truncate(object.contractorName || 'No name provided', 30) }}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-6">
      <p class="mb-2">Give your ratings</p>
      <div class="flex align-left">
        <Rating v-model="overallRating" size="xl" :cancel="false" />
      </div>
    </div>
    <div class="mb-6">
      <p class="mb-2">Describe your experience</p>
      <div class="flex flex-wrap gap-2 mb-4">
        <Chip
          v-for="tag in tags"
          class="cursor-pointer hover:bg-cool-gray-300"
          :class="{
            '!bg-cool-gray-300': selectedTags.includes(tag)
          }"
          :key="tag"
          :label="tag"
          @click.native="() => toggleTag(tag)"
        />
      </div>
      <div class="flex mb-2">
        <field
          class="w-full"
          schema="clientRating:description"
          type="textarea"
          placeholder="Enter your text"
          v-model="description"
          :validate="{ required: true }"
        />
      </div>
    </div>
    <div class="text-right mt-4">
      <btn-group>
        <Btn size="sm" severity="secondary" ref="btn" @click="close"> Skip for now </Btn>
        <Btn
          size="sm"
          severity="primary-black"
          ref="btn"
          :disabled="!isFormValid"
          @click="createRating"
        >
          Save
        </Btn>
      </btn-group>
    </div>
  </div>
  <!-- TODO: clean up the type passed to be more intuitive, indicate the type of user rather than the rating type -->
  <RatingCreatorDetails
    type="ClientRating"
    :creatorId="object.contractorId"
    ref="ratingCreatorDetailsRef"
    :ratingData="ratingData"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, onBeforeMount } from 'vue'
import Field from '@/components/ui/Field.vue'
import Btn from '@/components/ui/Btn.vue'
import Chip from 'primevue/chip'
import Rating from 'primevue/rating'
import { createContractorRating } from '@/apollo-client/services/rating'
import RatingCreatorDetails from '@/components/ratings/RatingCreatorDetails.vue'
import eventBus from '@/eventBus'

const props = defineProps({
  object: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['closeModal'])

const store = useStore()

const overallRating = ref(null)
const tags = ['Professional', 'On-time', 'Fair negotiation', 'Honest', 'Polite', 'Great value']
const selectedTags = ref([])
const description = ref('')
const ratingData = ref(null)
const ratingCreatorDetailsRef = ref(null)

const finalDescription = computed(() => {
  let localDescription = ''
  if (selectedTags.value) {
    localDescription = selectedTags.value.join(' • ')

    if (selectedTags.value.length > 0 && description.value) {
      localDescription += `\n\n`
    }
  }

  return localDescription + description.value
})

const isFormValid = computed(() => {
  return (
    overallRating.value !== null &&
    (description.value.trim() !== '' || (selectedTags.value && selectedTags.value.length > 0))
  )
})

const openSidebar = () => {
  if (ratingCreatorDetailsRef.value) {
    ratingCreatorDetailsRef.value.toggleShowSidebar()
  } else {
    console.error('ratingCreatorDetailsRef is not available')
  }
}

const createRating = async () => {
  const rating = {
    type: 'ContractorRating',
    companyId: props.object.companyId,
    creatorId: props.object.creatorId,
    quoteId: props.object.quoteId,
    contractorId: props.object.contractorId,
    overallRating: overallRating.value,
    description: finalDescription.value
  }

  await createContractorRating({ rating })
  eventBus.$emit('completed-fetching-data')
  emit('closeModal')
}

const close = () => emit('closeModal')

const toggleTag = (tag) => {
  if (selectedTags.value.includes(tag)) {
    selectedTags.value = selectedTags.value.filter((t) => t !== tag)
  } else {
    selectedTags.value.push(tag)
  }
}

onBeforeMount(() => {
  ratingData.value = store.getters['rating/getRatingDataByContractorId'](props.object.contractorId)
})
</script>
