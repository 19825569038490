 // This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'import_export_provider_type',
  fields: {
    import_export_provider_type_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    import_export_provider_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
