<template>
  <div class="relative" :class="{ hidden: isAssembly && !showAssembly && !isInQuoteEditor }">
    <Accordion
      v-if="isAssembly"
      :multiple="true"
      class="md:border-l-2 md:border-t-2 border-surface-200 my-1 md:mt-0 rounded-tl-lg"
      :active-index="getActiveIndex"
    >
      <AccordionTab
        :selections="true"
        :disabled="disabledList.includes(refId) || !showAssembly"
        :pt="{
          header: 'p-4 mb-1 bg-surface-100 !font-medium !text-lg rounded-tl-md',
          headerTitle: '!font-medium !text-2xl'
        }"
      >
        <template #header>
          <div class="flex flex-col items-stretch justify-start w-full font-normal">
            <div
              v-if="isInteractiveAssembly || hasAssemblyDescription || isOptionalAssembly"
              :id="refId"
            >
              <SelectionsItem
                :ref-id="refId"
                :parent-ref-id="parentRefId"
                :dimensions="dimensions"
                :presentation-settings="presentationSettings"
                :editable="editable"
                :store="store"
                :disabled="disabled || disabledList.includes(refId)"
                :isInQuoteEditor="isInQuoteEditor"
              />
            </div>
            <div v-else class="text-2xl gap-2">
              {{ object.assembly_name }}
            </div>
          </div>
        </template>
        <div v-if="showAssembly">
          <div v-for="childRef of groups" :key="childRef" class="md:pl-2">
            <RecursiveSelectionsItem
              :ref-id="childRef"
              :parent-ref-id="norm[childRef].parentRefId"
              :dimensions="dimensions"
              :presentation-settings="presentationSettings"
              :editable="editable"
              :store="store"
              :interactiveRefs="interactiveRefs"
              :isInQuoteEditor="isInQuoteEditor"
              :disabledList="disabledList"
            />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
    <div v-else class="md:border-l-2 md:border-t-2 border-surface-200 rounded-sm" :id="refId">
      <SelectionsItem
        :ref-id="refId"
        :parent-ref-id="parentRefId"
        :dimensions="dimensions"
        :presentation-settings="presentationSettings"
        :editable="editable"
        :store="store"
        :disabled="disabled || disabledList.includes(refId)"
        :isInQuoteEditor="isInQuoteEditor"
      />
    </div>
    <ItemEmphasisControl :isInQuoteEditor="isInQuoteEditor" :object="object" v-if="isAssembly" />
    <!--  Hidden item banner  -->
    <div
      v-if="isAssembly"
      class="absolute text-pitch-black inset-0 flex items-center justify-center"
      :class="{
        'block !opacity-100': isInQuoteEditor && !showAssembly,
        hidden: (!isInQuoteEditor && !showAssembly) || showAssembly
      }"
    >
      <p class="bg-surface-400 text-flame-white px-4 py-2 shadow rounded">
        <font-awesome-icon :icon="['fas', 'eye-slash']" class="mr-1" />
        Assembly hidden from the client
      </p>
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import SelectionsItem from './SelectionsItem.vue'
import eventBus from '@/eventBus'
import ItemEmphasisControl from '@/components/quote/presentation/ItemEmphasisControl.vue'
import useSelections from '@/components/composables/UseSelections'

export default {
  name: 'RecursiveSelectionsItem',
  components: {
    ItemEmphasisControl,
    SelectionsItem,
    Accordion,
    AccordionTab
  },
  emits: ['add-to-disabled-list', 'remove-from-disabled-list'],
  data() {
    return {
      activeIndices: []
    }
  },
  props: {
    refId: {
      type: String,
      required: true
    },
    dimensions: {
      type: Object
    },
    presentationSettings: {
      type: Object,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    store: {
      type: String,
      required: true
    },
    interactiveRefs: {
      type: Array,
      required: true
    },
    isInQuoteEditor: {
      type: Boolean,
      required: true
    },
    disabledList: {
      type: Array,
      required: true
    }
  },
  computed: {
    showAssembly() {
      return this.object.assembly_emphasis >= 0
    },
    norm() {
      return this.$store.state[this.store].normalized
    },
    object() {
      return this.norm[this.refId]
    },
    parentRefId() {
      return this.object.parentRefId
    },
    selections() {
      return useSelections(this.norm)
    },
    groups() {
      const order = this.selections.preferredOrder.value
      return _.uniq(this.object.aoChildren)
        .filter((ref) => this.selections.shouldShowItem(ref))
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    },
    getActiveIndex() {
      return Array.from({ length: this.groups.length }, (_, i) => i)
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isInteractiveAssembly() {
      return Array.isArray(this.interactiveRefs) && this.interactiveRefs.includes(this.refId)
    },
    isOptionalAssembly() {
      return this.isAssembly && this.object.assembly_is_optional
    },
    hasAssemblyDescription() {
      return this.isAssembly && (this.object.cost_type_desc || this.object.quote_notes)
    },
    disabled() {
      const hasMissingInputs = (this.object.oInputRequired?.inputs || []).some(
        (input) =>
          (input.required && !input.input.text) ||
          (!input.input.data && !Object.keys(input.input.data).length)
      )

      if (this.isAssembly && !this.object.assembly_is_included) {
        eventBus.$emit('add-to-disabled-list', this.refId)
      }
      return !!hasMissingInputs
    }
  }
}
</script>
