<template>
  <Container>
    <CardSection>
      <template #title>
        <span> Add custom dimension </span>
      </template>

      <CardList>
        <CardListField>
          <span>Full dimension name</span>

          <Field
            schema="dimension:dimension_name"
            v-model="dimension_name"
            :validate="{ required: true }"
          />
        </CardListField>

        <CardListField>
          <Help>
            <template #before>
              Custom dimension code

              <small class="text-info mt-2 block" v-if="originalDimensionId">
                The code cannot be changed after the item has been created. Create a new dimension
                instead.
              </small>
            </template>

            <p>Choose a code that will be used in formulas to reference your dimension.</p>
            <p>
              Your custom dimension <strong>will be prefixed</strong> with a unique prefix
              representing only your company, which will avoid overriding other codes, since all
              codes must be unique.
            </p>
            <p>Examples:</p>
            <ul>
              <li>
                <pre>fa</pre>
                for floor area
              </li>
              <li class="mt-3">
                <pre>iwh</pre>
                for interior wall height
              </li>
            </ul>
          </Help>

          <UniqueField
            v-if="!dimension_time_created"
            :disabled="dimension_time_created"
            :unique="{
              type: 'dimension',
              field: 'dimension_id',
              exact: true,
              process: (val) => `${prefix}${val}`,
              except: originalDimensionId ? [originalDimensionId] : []
            }"
            :validate="{ required: true }"
            v-model="dimensionId"
          />
          <div v-else>
            {{ originalDimensionId }}
          </div>

          <template #below>
            <div>
              <div class="flex flex-col items-center justify-center">
                <span class="text-medium"> Full code: </span>

                <Tag
                  :style="`background: ${dimension_color};`"
                  class="border-0 shadow-sm !text-white"
                  @click="
                    () =>
                      $store.dispatch(
                        'copyToClipboard',
                        `${originalDimensionId ? '' : prefix}${originalDimensionId || dimensionId}`
                      )
                  "
                >
                  {{ originalDimensionId ? '' : prefix }}{{ originalDimensionId || dimensionId }}
                </Tag>
              </div>
            </div>
          </template>
        </CardListField>
      </CardList>
      <CardList class="mt-4">
        <CardListField>
          <span>Dimension measure type</span>

          <Choose
            v-model="dimension_measure_type"
            :allow-deselect="false"
            :static-set="[
              {
                text: 'Count/each',
                value: 'count'
              },
              {
                text: 'Linear length (linear feet, linear meters etc)',
                value: 'length'
              },
              {
                text: 'Area (square feet, square meters etc)',
                value: 'area'
              },
              {
                text: 'Volume (cubic yards, cubic meters etc)',
                value: 'volume'
              },
              {
                text: 'Weight (lbs, kg etc)',
                value: 'weight'
              }
            ]"
            :validate="{
              required: true
            }"
          />
        </CardListField>
        <CardListField>
          <span>Instructions / description</span>

          <Field
            type="textarea"
            v-model="dimension_desc"
            schema="dimension:dimension_desc"
            placeholder="Describe how to measure this dimension"
          />
        </CardListField>
      </CardList>

      <CardList class="mt-4">
        <CardListField>
          <span
            >Keywords
            <small class="text-info">
              Comma-separated keywords for automated searching and suggestions
            </small></span
          >

          <Field v-model="dimension_keywords" schema="dimension:dimension_keywords" />
        </CardListField>
        <CardListField>
          <span>Color code</span>
          <div>
            <ColorPicker class="mr-1" style="font-size: 3em" v-model="dimension_color" />
          </div>
        </CardListField>

        <CardListItem class="column">
          <span class="mb-2">Choose an icon</span>

          <div>
            <Btn
              rounded
              class="more px-0 p-2 mx-1 xl"
              @click="dimension_icon = icon"
              :class="{
                info: icon === dimension_icon
              }"
              v-for="icon in iconOptions"
              :key="icon"
            >
              <font-awesome-icon :icon="icon" />
            </Btn>
          </div>
        </CardListItem>
      </CardList>
    </CardSection>

    <CardSection v-if="dimension_id && dimension_time_created">
      <template #title>
        <span>
          <font-awesome-icon icon="arrows-rotate" class="mr-1" />
          Where this dimension is used
        </span>
      </template>

      <List
        type="cost_type"
        :showOptions="false"
        :permanent-filters="{
          type: 'cost_type',
          asDimensionsUsed: dimension_id
        }"
      >
        <template #header>Items</template>
      </List>
      <List
        type="assembly"
        :showOptions="false"
        :permanent-filters="{
          type: 'assembly',
          asDimensionsUsed: dimension_id
        }"
      >
        <template #header>Assemblies</template>
      </List>
    </CardSection>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import UniqueField from '../ui/UniqueField.vue'
import ColorPicker from '@/components/ui/ColorPicker.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Dimension',
  components: { UniqueField, ColorPicker },
  mixins: [
    ObjectManipulator(
      'dimension' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],
  emits: ['saving', 'saved'],
  data() {
    return {
      originalDimensionId: null,
      iconOptions: [
        'hashtag',
        'block-brick',
        'up-down',
        'left-right',
        'square',
        'cube',
        'ruler-combined',
        'map-location-dot',
        'scale-balanced'
      ]
    }
  },
  methods: {
    afterSelect() {
      this.originalDimensionId = this.dimension_id
    },
    afterSave() {
      this.originalDimensionId = this.originalDimensionId || this.dimension_id
    },
    async save() {
      try {
        await this.commit()
        this.beforeSave()

        if (!(await this.validate())) {
          return false
        }

        this.addLoading()
        this.$emit('saving')
        eventBus.$emit('saving', { changes: this.changes, object: this.cast() })
        eventBus.$emit(`saving-${this.type}-${this.refId}`)

        this.addLoading()
        const method = this.dimension_time_created ? 'save' : 'createWithId'
        const savePayload = await this.$store.dispatch(`${this.storeName}/save`, {
          refId: this.refId,
          go: false,
          changes: true,
          method
        })
        await this.$store.dispatch('Dimension/getPossibleDimensions', { force: true })

        this.endLoading()

        this.afterSave()
        this.isCheckingOutOldVersion = 0
        this.$emit('saved', { ...savePayload })
        eventBus.$emit('saved', savePayload)
        eventBus.$emit(`saved-${this.type}-${this.refId}`)
        eventBus.$emit(`saved-${this.type}`)
        return { ...savePayload }
      } catch (error) {
        if (alert) {
          this.$store.dispatch('alert', {
            error: true,
            message: error.userMessage || 'An error occurred. Changes may not have been saved.'
          })
        }

        throw new Error(error.message)
      } finally {
        this.endLoading()
      }
    }
  },
  computed: {
    prefix() {
      if (!this.$store.state.session.scope.company) return ''

      return `${c.getShortFromId(+this.$store.state.session.scope.company)}_`
    },
    dimensionId: {
      set(id) {
        let dim = `${this.prefix}${id}`.toLowerCase()
        dim = dim.replace(/\s+/gi, '_')
        dim = dim.replace(/[^a-z_]/gi, '_')
        this.dimension_id = dim
        this.dimension_color = c.stringToHexColor(dim)
      },
      get() {
        if (!this.dimension_id) return ''

        return String(this.dimension_id).replace(this.prefix, '')
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
