<template>
  <div
    v-if="changeOrders && changeOrders.length"
    class="flex flex-col justify-start items-stretch gap-4"
  >
    <change-order
      v-for="(co, index) in changeOrders"
      @deleted="() => remove(co.change_order_id)"
      @checkout="() => checkout(co.change_order_id, index)"
      @send="send"
      @reload="reload"
      :isCheckedOut="co.change_order_id === changeOrderId"
      :object="co"
      :editable="editable"
      :latest="index === 0"
      :key="index"
    />
  </div>
  <spinner :loading="1" size="3em" class="info" v-else-if="loading" />

  <Card class="sm center text-center" v-else>
    <h5 class="m-0">No other change orders/versions found..</h5>
  </Card>
</template>

<script>
import ChangeOrder from './ChangeOrder.vue'
import Button from '../mixins/Button'

/**
 * Emits:
 * -reload
 * -checkout (change order id)
 */
export default {
  name: 'ChangeOrders',

  mixins: [Button],
  emits: ['reload', 'checkout'],

  data() {
    return {
      changeOrders: []
    }
  },

  methods: {
    async reload() {
      this.fetch()

      this.$emit('reload')

      return this
    },

    /**
     * Send quote, only sends most recent.
     * @returns {Promise<any>}
     */
    async send() {
      await this.$store.dispatch('Quote/send', {
        id: this.quoteId
      })

      return this.reload()
    },

    checkout(changeOrderId, index) {
      return this.$emit('checkout', changeOrderId, index === 0)
    },

    checkoutLatest() {
      return this.checkout(this.changeOrders[0].change_order_id)
    },

    async remove() {
      this.$store.dispatch('addLoading')
      await this.reload()
      this.$store.dispatch('removeLoading')

      return this
    },

    async fetch() {
      this.addLoading()

      const { set } = await this.$store.dispatch('ChangeOrder/search', {
        filters: {
          ...this.filters,
          quote_id: this.quoteId
        },
        order: [['change_order_id', 'desc']]
      })

      this.endLoading()

      this.changeOrders = set
    }
  },

  created() {
    this.fetch()
  },

  props: {
    /**
     * QuoteId for project
     */
    quoteId: {
      required: true
    },

    editable: {
      type: Boolean,
      default: true
    },

    /**
     * Change order id currently being viewed
     */
    changeOrderId: {
      required: true
    },

    filters: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    ChangeOrder
  }
}
</script>

<style scoped></style>
