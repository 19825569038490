<template>
  <modal v-bind="$props" ref="modal" size="lg" :full="true" :clickAway="false" :expandable="false">
    <template #header> Custom form </template>
    <template #body>
      <FormBuilder v-if="object" ref="body" :object="object" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="handleSave"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import { ref } from 'vue'
import ObjectModalMixin from './ObjectModalMixin'
import FormBuilder from '@/components/forms/FormBuilder.vue'
import useForm from '@/components/forms/Form'

export default {
  name: 'FormModal',
  mixins: [ObjectModalMixin],
  components: { FormBuilder },
  props: {
    type: {
      default: 'form'
    }
  },
  setup() {
    const { saveForm, refId } = useForm()
    const body = ref(null)
    const handleSave = async () => {
      refId.value = body.value.refId
      saveForm()
    }
    return {
      saveForm,
      handleSave,
      body
    }
  }
}
</script>
