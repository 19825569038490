<template>
  <PersonAvatar :type="type" :name="name" :id="id" />
</template>

<script>
import SuggestionButton from '../SuggestionButton.vue'

export default {
  name: 'GridSubItemTag',
  computed: {
    subObject() {
      const subObjKey = `o${c.titleCase(this.pseudoType)}`
      if (
        subObjKey in this.object &&
        this.object[subObjKey] &&
        Object.keys(this.object[subObjKey]).length
      ) {
        return this.object[subObjKey]
      }
      return this.object
    },
    nameKey() {
      return `${this.type}_name`
    },
    idKey() {
      let idKey = `${this.type}_id`
      if (/creator|owner/.test(this.pseudoType)) {
        idKey = `${this.object.type}_${this.pseudoType}`
      }

      return idKey
    },
    name() {
      const fname = `${this.type}_fname`
      const lname = `${this.type}_lname`
      if (this.nameKey in this.subObject && this.subObject[this.nameKey]) {
        return this.subObject[this.nameKey]
      } else if (this.subObject && fname in this.subObject && lname in this.subObject) {
        return `${this.subObject[fname] || ''} ${this.subObject[lname] || ''}`
      }
      return null
    },
    id() {
      if (this.idKey in this.subObject && this.subObject[this.idKey]) {
        return this.subObject[this.idKey]
      }

      if (this.idKey in this.object && this.object[this.idKey]) {
        return this.object[this.idKey]
      }

      return null
    }
  },
  methods: {
    async edit() {
      let id = this.id

      if (this.type === 'user') {
        id = String(id).replace(/[^0-9]/g, '')
      }

      return this.$store.dispatch('edit', { type: this.type, id })
    }
  },
  components: { SuggestionButton },
  props: {
    /**
     * Type used to find object, ie:
     * 'creator' would point to oCreator
     * instead of actual type of user, which would
     * point to 'oUser' which doesn't exist
     */
    pseudoType: {
      type: String,
      required: true
    },
    /**
     * Actual pure object type of pointer
     */
    type: {
      type: String,
      required: true
    },
    /**
     * Primary data provided to the grid, must have the
     * sub object of type (above), ie:
     * quote: {
     *    oClient: {},
     * }
     * if type set to client, will build a client tag
     * with info from object.oClient
     */
    object: {
      type: Object,
      required: true
    },

    grid: {
      required: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.grid-sub-item-tag--container {
  margin: auto auto;
}
</style>
