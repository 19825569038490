import { useStore } from 'vuex'
import { onBeforeUnmount, toValue } from 'vue'
import eventBus from '@/eventBus.js'
import Utilities from '../../../imports/api/Changes/Utilities.js'

export default {
  useRestore(args = {}) {
    const {
      refId: recdRef = null,
      store: recdStore = null,
      type: recdType,
      trackChanges = true
    } = args

    let refId = toValue(recdRef)
    let store = toValue(recdStore)
    let checked = false
    const type = toValue(recdType)

    const $store = useStore()

    let changeManager

    const getNorm = () => $store.state[store].normalized

    const getBkpKey = () => `bck-${store}-${refId}`

    const saveBackup = () => {
      const hasChanges = changeManager?.isDirty()

      if (!hasChanges) return

      const bundle = {
        norm: getNorm(),
        time: Date.now()
      }
      eventBus.$emit(`saving-backup-${refId}`)
      c.setCacheItem(
        getBkpKey(),
        bundle,
        'bkup',
        $store.state.session,
        Date.now() + 60 * 1000 * 60 * 24 * 7
      )
      eventBus.$emit(`saved-backup-${refId}`)
    }

    const flushBackup = () => {
      eventBus.$emit(`flushing-backup-${refId}`)
      c.removeCacheItem(getBkpKey(), 'bkup', $store.state.session)
      eventBus.$emit(`flushed-backup-${refId}`)
    }

    const setRefId = (recdRefId) => (refId = recdRefId)

    const watchHandler = (cw, refIds) => {
      if (checked && refIds.length) {
        c.throttle(() => saveBackup())
      }
    }

    const restoreBackup = async (bkp) => {
      eventBus.$emit(`restoring-${refId}`)
      checked = false

      const diff = Utilities.diffNormalizedRaw($store.state[store].normalized, bkp.norm, refId)

      await $store.commit({
        type: `${store}/SET_NORMALIZED`,
        object: bkp.norm,
        skipDefaulting: true,
        prune: false
      })

      await $store.commit({
        type: `${store}/ADD_EXPLICIT_CHANGES`,
        changes: diff
      })

      setTimeout(() => {
        checked = true
      }, 500)
      eventBus.$emit(`restored-${refId}`)
      // clear cached
      flushBackup()
    }

    const checkBackups = async () => {
      const bkp = c.getCacheItem(getBkpKey(), 'bkup', $store.state.session)
      eventBus.$emit(`checking-backup-${refId}`)

      if (bkp) {
        const result = await $store.dispatch('modal/asyncConfirm', {
          message: `A backup found for this ${type.replace('_', ' ')} from ${c.format(bkp.time, 'datetime')}. Restore backup?`,
          yes: 'Load backup',
          no: 'Discard backup'
        })

        if (result === false) flushBackup()

        if (result === true) restoreBackup(bkp)
      }

      checked = true
      eventBus.$emit(`checked-backup-${refId}`)
    }

    const getBackup = () => c.getCacheItem(getBkpKey(), 'bkup', $store.state.session)

    const initialize = async ({ refId: recdRefId = refId, store: recdStore = store } = {}) => {
      refId = recdRefId
      store = recdStore

      if (!store || !refId) return

      changeManager = await $store.dispatch(`${store}/getChangeManager`, {
        refId
      })
      $store.commit({
        type: `${store}/ADD_CHANGE_WATCHER`,
        watcher: watchHandler,
        changeManager
      })

      await checkBackups()

      eventBus.$on(`saved-${type}-${refId}`, flushBackup)
    }

    onBeforeUnmount(() => {
      if (!trackChanges) return
      $store.commit({
        type: `${store}/REMOVE_CHANGE_WATCHER`,
        watcher: watchHandler,
        changeManager
      })
    })

    return {
      initialize,
      saveBackup,
      checkBackups,
      restoreBackup,
      getBackup,
      setRefId,
      flushBackup
    }
  }
}
