<template>
  <container>
    <card-section>
      <template #title>
        <span> How you would like to sync with Quickbooks? </span>
      </template>
      <card>
        <div class="options">
          <div class="option">
            <input type="radio" v-model="syncOption" value="0" />&nbsp;Sync currently booked
            projects, in-progress projects, & outstanding invoices
          </div>
          <div class="option">
            <input type="radio" v-model="syncOption" value="1" />&nbsp;Sync all projects/invoices
            since date
            <date-picker v-if="syncOption == 1" v-model="date" />
          </div>
          <div class="option">
            <input type="radio" v-model="syncOption" value="2" />&nbsp;Sync future projects/invoices
          </div>
        </div>
      </card>
    </card-section>
  </container>
</template>

<script>
import DatePicker from '../ui/DatePicker.vue'

export default {
  name: 'AccountingSync',
  data() {
    return {
      syncOption: 2,
      date: null
    }
  },
  watch: {},
  computed: {},
  methods: {
    submit() {
      this.$store.dispatch('ajax', {
        path: 'Export_Settings/setSyncOptions',
        data: {
          export_settings_sync_type: this.syncOption,
          export_settings_sync_date: Date.parse(this.date) / 1000
        }
      })
      // close the banner because they have reconnected
      this.$store.dispatch('closeBanner')
    }
  },
  components: {
    DatePicker
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.options {
  display: flex;
  flex-direction: column;

  .option {
    margin-top: 1em;
  }
}
</style>
