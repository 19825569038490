<template>
  <div class="inset-0 absolute overflow-hidden" v-if="selected">
    <Page title="Project dashboard" v-if="activeTabs.includes('Dashboard')">
      <EstimateDashboard />
    </Page>

    <div v-else-if="activeTabs.includes('Estimate')" class="absolute inset-0 bg-surface-50">
      <Estimating
        :key="`${refId}-${restored}-`"
        v-if="refId && !bodyLoading && refId in $store.state.Quote.normalized"
        :checkedOutChangeOrderId="checkedOutChangeOrderId"
        :autoCostDisabledWarning="autoCostDisabledWarning"
        :showLivePriceWarning="showLivePriceWarning"
        :hasLivePricingEnabled="hasLivePricingEnabled"
        @fixMissingDimensions="activeTabs = ['Dimensions']"
        @updateAutocost="() => fetchQuoteLivePrices()"
        @resetCheckout="() => resetCheckout()"
        :refId="refId"
      />
      <Skeleton v-else shape="rectangle" width="100" height="100" />
    </div>

    <Page v-else-if="activeTabs.includes('Takeoff')" class="p-8" scrollable>
      <QuoteTakeoff store="Quote" :refId="refId" />
    </Page>

    <div v-else-if="activeTabs.includes('Preview')" class="absolute inset-0">
      <ScrollPanel
        class="absolute inset-0"
        v-if="quote_name && aoChildren.length"
        :pt="{
          content: () => ({
            class: [
              'h-full w-full p-0 !overflow-y-scroll !overflow-x-hidden scrollbar-none box-border relative scrollbar-hide'
            ]
          })
        }"
      >
        <quote-presentation
          :isInQuoteEditor="true"
          :reference="refId"
          :key="refId"
          :store="storeName"
        >
        </quote-presentation>
      </ScrollPanel>
      <Page v-else icon="eye" title="Preview">
        <Automation :closable="false">
          <div class="flex flex-col gap-4">
            <p>
              <font-awesome-icon icon="eye" class="mr-2" /><span class="font-medium"
                >Nothing to preview yet!
              </span>
              Add some items to the estimate first, then come back to see what your customer will
              see when you send them this estimate.
            </p>

            <p>
              <font-awesome-icon icon="person-chalkboard" class="mr-2" />
              You can also come back here to choose to present it to them in person, from a tablet,
              laptop, TV or other device. They can approve your estimate
              <span class="font-medium">on the spot, in front of you!</span>
            </p>
          </div>
        </Automation>
      </Page>
    </div>

    <div
      v-else-if="activeTabs.includes('Activities')"
      class="h-full w-full flex items-center justify-center"
    >
      <ProgressSpinner v-if="initQuoteChannelLoading" />
      <ActivityChannelBody
        channelType="QUOTE"
        :channelTypeId="quote_id"
        :userId="$store.state.session.user.user_id"
        v-else
      />
    </div>

    <div v-else-if="activeTabs.includes('Schedule')" class="absolute inset-0">
      <Page class="!px-0" full>
        <Schedule
          v-if="quote_id"
          class="project-scheduler"
          :reference="quote_id"
          :goToToday="true"
          :startDate="quote_time_project_start"
          :endDate="quote_time_project_end"
          :projectId="quote_id"
          :hasChanges="isDirty"
        />
      </Page>
    </div>

    <div v-else-if="activeTabs.includes('Invoices')" class="absolute inset-0">
      <Page>
        <QuoteInvoices :quoteId="quote_id" />
      </Page>
    </div>

    <div v-else-if="activeTabs.includes('Files')" class="absolute inset-0">
      <Page title="Project files" icon="folder-open" scrollable>
        <card-section class="sm tight">
          <template #label>
            <help>
              <template #before>Signed contracts</template>
              Signed contracts are generated when a client approves your proposal or a change-order.
              These are the versions/change-orders for this project. All versions appear here, they
              will indicate if they have a client authorization attached.
            </help>
          </template>

          <card-list v-if="visibleChangeOrders?.length">
            <card-list-item v-for="co in visibleChangeOrders" :key="co.change_order_id">
              <div class="flex items-center w-100">
                <template
                  v-if="
                    co.auth_file_id ||
                    (co.oClientMeta &&
                      typeof co.oClientMeta === 'object' &&
                      Object.keys(co.oClientMeta).length)
                  "
                >
                  <font-awesome-icon icon="file" style="font-size: 2em; top: 1px" fixed-width />

                  <div class="flex flex-col items-start justify-center ml-3">
                    <strong>{{ co.change_order_name }}</strong>
                    <small class="text-muted">
                      Approved {{ $f.datetime(co.change_order_client_approved_time) }}
                    </small>
                  </div>
                  <div style="margin-left: auto">
                    <btn
                      class="btn round info"
                      @click="
                        () =>
                          $store.dispatch('ChangeOrder/downloadContract', {
                            id: co.change_order_id
                          })
                      "
                    >
                      <template #icon>
                        <font-awesome-icon icon="cloud-arrow-down" fixed-width />
                      </template>
                      Download
                    </btn>
                  </div>
                </template>
                <template v-else>
                  <font-awesome-icon
                    icon="file"
                    class="text-muted"
                    style="font-size: 2em; top: 1px"
                    fixed-width
                  />

                  <div class="flex flex-col items-start justify-center ml-3">
                    <strong>{{ co.change_order_name }}</strong>
                    <small class="text-muted" v-if="co.change_order_status !== 'p'"
                      >There is no client authorization recorded for this version.</small
                    >
                    <small class="text-muted" v-else
                      >Client has not approved this change-order yet.</small
                    >
                  </div>
                </template>
              </div>
            </card-list-item>
          </card-list>
          <Automation v-else :closable="false">
            Once your customer approves this project, the approved versions (including all approved
            changes) will automatically show here and you can download the contracts.
          </Automation>
        </card-section>

        <CardSection class="sm" style="position: relative">
          <template #label>Floor plans</template>
          <FileList
            class="left ml-2"
            :starting-folder="`quote-${quote_id}`"
            :id-list="true"
            v-model="plan_file_ids"
          />
        </CardSection>

        <CardSection class="sm" style="position: relative">
          <template #label>Before pictures</template>
          <FileList
            class="left ml-2"
            :starting-folder="`quote-${quote_id}`"
            :id-list="true"
            v-model="before_file_ids"
          />
        </CardSection>

        <CardSection class="sm" style="position: relative">
          <template #label>After pictures</template>

          <FileList
            class="left ml-2"
            :starting-folder="`quote-${quote_id}`"
            :id-list="true"
            v-model="after_file_ids"
          />
        </CardSection>

        <card-section class="sm" style="position: relative">
          <template #label>
            <help>
              <template #before>Presentation files</template>
              These are the files attached to the proposal for presentation purposes. Drawings,
              specifications, pictures, before & after photos etc.
              <strong>These are visible to the client.</strong>
            </help>
          </template>

          <file-list
            class="left ml-2"
            :highlightFirst="true"
            :idList="true"
            :starting-folder="`quote-${quote_id}`"
            v-model="file_ids"
          />
        </card-section>

        <Btn link @click="showAllFiles = !showAllFiles">
          <font-awesome-icon icon="folder-open" />
          {{ showAllFiles ? 'Hide' : 'Show' }} full Bolster Drive folder for this project...
        </Btn>

        <card-section class="sm" v-if="showAllFiles">
          <Files
            :filters="{
              parent_file_id: `quote-${quote_id}`
            }"
            :showSidebar="false"
            :main="false"
            :showFilters="false"
            :showPath="true"
            :showByPath="true"
          />
        </card-section>
      </Page>
    </div>

    <div v-else-if="activeTabs.includes('Budget')" class="absolute inset-0">
      <BudgetList v-if="!bodyLoading" :refId="refId" :key="refId" />
      <Skeleton v-else shape="rectangle" width="100" height="100" />
    </div>

    <div v-else-if="activeTabs.includes('Materials')" class="absolute inset-0">
      <MaterialList v-if="!bodyLoading" :refId="refId" :key="refId" />
      <Skeleton v-else shape="rectangle" width="100" height="100" />
    </div>

    <Page v-else-if="activeTabs.includes('Selections')" class="p-8" scrollable>
      <TabView>
        <TabPanel>
          <template #header> Client selections </template>
          <div
            class="overflow-y-auto max-h-full basis-100 shrink grow-0 w-full gap-10 flex flex-col"
          >
            <Selections audience="client" :store="store" :refId="refId" key="client" />
          </div>
        </TabPanel>
        <TabPanel>
          <template #header> Internal selections </template>
          <div
            class="overflow-y-auto max-h-full basis-100 shrink grow-0 w-full gap-10 flex flex-col"
          >
            <Selections audience="company" :store="store" :refId="refId" key="company" />
          </div>
        </TabPanel>
      </TabView>
    </Page>

    <Page
      title="Revisions and change orders"
      icon="timeline-arrow"
      scrollable
      v-else-if="activeTabs.includes('Changes')"
    >
      <div class="flex flex-col gap-8 font-normal leading-2 *:text-lg">
        <p>
          <font-awesome-icon icon="timeline-arrow" class="mr-2" />
          <span class="font-medium">Change orders are automatically generated</span> when you make a
          change to the scope or price of this job. You don't need to worry about doing anything
          manually. Whenever you save your changes, Bolster detects if a material change has been
          made and generates a new change order automatically. You'll be notified to re-send the
          proposal to get a new sign-off from your customer -- whenever you're ready!
        </p>

        <p>
          <font-awesome-icon icon="eye" class="mr-2" />Multiple revisions can also be generated even
          before the project is approved if a previous version has been tagged as having been seen
          by your customer already. This allows you to track
          <span class="font-medium"
            >which is the latest version your customer has seen, and when they viewed it.</span
          >
        </p>

        <p>
          <font-awesome-icon icon="file-signature" class="mr-2" />
          Each change order is tracked as a new version of the project.
          <span class="font-medium"
            >Once approved by your customer, a contract is associated with each one.</span
          >
          The contracts are legally binding and give you peace of mind to keep the work moving!
        </p>

        <p>
          <font-awesome-icon icon="history" class="mr-2" />
          <span class="font-medium">You can always roll back</span> to a previous version if you've
          made a mistake, or if your customer changes their mind.
        </p>
      </div>

      <card-section>
        <template #label> Unsaved changes </template>

        <ChangeTracker get-denormalized :ref-id="refId" :store="storeName" type="quote">
          <template #default="{ denormalized, isDirty }">
            <template v-if="isDirty">
              <template v-if="/k|f/.test(quote_status)">
                <warning class="mb-3 sm" v-if="requiresClientApproval">
                  <template #title>
                    Because this project has been booked, these changes will be saved as a new
                    pending change order.
                  </template>
                </warning>
                <warning
                  class="mb-3 sm"
                  v-else-if="aoChangeOrders.length && aoChangeOrders[0].change_order_status === 'p'"
                >
                  Because this project has been booked, these changes will be saved as part of the
                  latest change order that is still pending.
                </warning>
              </template>

              <card :icon="false" style="justify-content: flex-start; text-align: left">
                <template v-if="Object.keys(denormalized?.explicit ?? {}).length">
                  <change-audit
                    type="quote"
                    :changes="seeAllChanges ? denormalized.changes : denormalized.explicit"
                    field="quote"
                    :key="seeAllChanges"
                  />
                  <btn class="xs mt-4 info" v-if="!seeAllChanges" @click="seeAllChanges = true">
                    <template #icon>
                      <font-awesome-icon icon="code-fork" fixed-width />
                    </template>
                    See all connected changes..
                  </btn>
                </template>
                <template v-else>
                  <div class="text-xl font-medium">No unsaved changes</div>
                </template>
              </card>
            </template>
            <card v-else>
              <template #icon>
                <font-awesome-icon icon="code-fork" fixed-width />
              </template>
              <template #title>No unsaved changes</template>
              <span> Make changes to this quote to see an audit of changes made here. </span>
            </card>
          </template>
        </ChangeTracker>
      </card-section>

      <card-section v-if="isCheckingOutOldVersion || checkedOutChangeOrderId">
        <info class="my-3">
          <template #title>
            You are currently checking out a previous version/change order of this project.
          </template>
          <p>
            Auto-saving is disabled. To revert to this version, click 'Save this as new latest
            version' below or do a 'Force save' from the save icon.
          </p>

          <pill-group class="mt-2">
            <btn @click="startingTab = ['Changes']" class="dark">
              Go to the changes tab to see all change orders..
            </btn>
            <btn @click="resetCheckout"> Back to latest version </btn>
            <btn @click="forceSave"> Save this as new latest version </btn>
          </pill-group>
        </info>
      </card-section>

      <warning class="sm" v-if="/[kf]/.test(quote_status) && !change_order_time_booked">
        <template #title>
          Some changes in this project have not been approved by the client.
        </template>
      </warning>

      <card-section>
        <template #label>
          Versions + approvals
          <help class="ml-2">
            <p>
              As you work, a new version/change-order may be automatically generated. You do not
              specifically need to create a change-order or re-estimate. It will automatically be
              created if the latest version has already been approved, sent or viewed.
            </p>

            <p>Any newly generated version/change-order will show as pending and unsent.</p>

            <p>
              Whether a new change-order is automatically generated or not, the client always sees
              the latest changes after it has been sent. New change-orders/versions do not need to
              be sent to be seen by the client, however it is recommended to notify them to look
              again. When sending a proposal, it always sends the
              <strong>latest change-order/version</strong>. If you reload this list after sending,
              you should see the change-order marked as sent.
            </p>

            <p>Change orders are generated automatically if:</p>
            <ul>
              <li>
                The latest version/change-order has been approved by the client. Any new changes
                will be rolled into a new version to not affect the previous approval.
              </li>

              <li>
                The latest version/change-order has been sent to the client. To keep track of what
                versions were sent, sent versions remain static. Any new changes will be rolled into
                a new version/change-order.
              </li>
            </ul>
          </help>
        </template>

        <change-orders
          @checkout="checkoutChangeOrder"
          @reload="reload"
          :quoteId="quote_id"
          :changeOrderId="change_order_id"
        />
      </card-section>
    </Page>

    <Page title="History" icon="history" v-else-if="activeTabs.includes('History')">
      <div class="flex justify-stretch">
        <activities
          :startingFolder="`quote-${quote_id}`"
          @version-checkout="checkoutPreviousVersion"
          v-if="quote_id"
          :showBackupsFor="{
            change_order: true,
            quote: quote_id
          }"
          :contact="getContact()"
          :filters="{
            quote_id
          }"
        />
        <card v-else>
          <template #icon>
            <font-awesome-icon icon="newspaper" />
          </template>
          <template #title>Activities</template>
          <p>Save this quote first to see related activities.</p>
          <p>
            <btn class="btn btn-primary" ref="forceSaveBtn" :loading="loading" @click="save"
              >Save now?</btn
            >
          </p>
        </card>
      </div>
    </Page>

    <Page title="Settings" icon="cog" v-else-if="activeTabs.includes('Settings')" scrollable>
      <ScrollPanel>
        <CardSection>
          <template #title>Roles</template>
          <CardList>
            <CardListField>
              <div>
                <div><font-awesome-icon icon="clipboard" /> Project manager</div>
              </div>
              <choose
                schema="quote:quote_pm_user_id"
                :allowDeselect="false"
                :loadImmediate="true"
                :filters="{ company_ids: `INSET${company.company_id}` }"
                v-model="quote_pm_user_id"
              />
            </CardListField>

            <CardListField>
              <div class="flex gap-2">
                <div><font-awesome-icon icon="swatchbook" /> Designer</div>
              </div>
              <choose
                schema="quote:quote_designer_user_id"
                :allowDeselect="false"
                :loadImmediate="true"
                :filters="{ company_ids: `INSET${company.company_id}` }"
                v-model="quote_designer_user_id"
              />
            </CardListField>
          </CardList>
        </CardSection>

        <CardSection>
          <template #title>Project details</template>
          <CardList>
            <CardListField v-if="isSuperAdmin">
              <span>Mark as a test?</span>
              <div>
                <InputSwitch
                  v-model="quote_is_test"
                  class="border-muted border"
                  :false-value="0"
                  :true-value="1"
                />
              </div>
            </CardListField>

            <CardListField>
              <span>Project name</span>
              <field v-model="quote_name" type="text" ref="nameField" schema="quote:quote_name" />
            </CardListField>

            <CardListField>
              <span>Client</span>
              <choose :allowDeselect="false" schema="quote:client_id" v-model="clientId" />
            </CardListField>

            <CardListField>
              <span>Project address</span>
              <AddressForm
                v-model="quoteAddress"
                :in-quote="true"
                class="lg:min-w-[450px] lg:w-[450px]"
              />
            </CardListField>

            <CardListField>
              <span>Client reference number (RFQ/PO/WO)</span>
              <field type="text" v-model="quote_client_ref" />
            </CardListField>

            <CardListField>
              <span>Project notes</span>
              <field
                v-model="quote_notes"
                type="textarea"
                ref="projectNotes"
                schema="quote:quote_notes"
              />
            </CardListField>

            <CardListField>
              <span>Project status</span>
              <choose
                :allowDeselect="false"
                schema="quote:quote_status"
                v-model="quote_status"
                :staticSet="statusSet"
              />
            </CardListField>
          </CardList>
        </CardSection>

        <CardSection>
          <template #title>AutoCost</template>
          <CardList>
            <CardListField>
              <div>
                <div>Live pricing location</div>
              </div>
              <choose
                v-model="live_price_zipcode"
                :allowCreate="false"
                :allowDeselect="false"
                :forceNotEmpty="true"
                :staticSet="[
                  {
                    text: 'Project location',
                    value: 'project'
                  },
                  {
                    text: 'Company location',
                    value: 'company'
                  }
                ]"
              />
            </CardListField>
          </CardList>
        </CardSection>

        <CardSection>
          <template #title>Legal</template>
          <CardList>
            <CardListField>
              <span>Project-specific terms and conditions</span>
              <field
                type="textarea"
                ref="projectTerms"
                schema="quote:quote_terms"
                v-model="quote_terms"
                class="p-2"
              />
            </CardListField>
          </CardList>
        </CardSection>

        <CardSection>
          <template #title>Payment settings</template>
          <CardList>
            <CardListField>
              <span>Percentage payments</span>
              <toggle
                @input="(v) => setField('quote_is_aggregated_payment', v)"
                :value="quote_is_aggregated_payment"
              />
            </CardListField>
          </CardList>
        </CardSection>
      </ScrollPanel>
    </Page>

    <Page title="" v-else-if="activeTabs.includes('Progress')" scrollable>
      <div class="py-4">
        <ProjectProgress />
      </div>
    </Page>

    <Modal ref="livePriceChanges" size="lg" :click-away="false">
      <template #header>
        <Icon icon="svg:autocostWhite" class="bg-matcha-500 rounded-full aspect-square h-7 p-0.5" />
        Review AutoCost changes
      </template>
      <template #body>
        <div>
          <div class="mb-1">How do you want to adjust your items?</div>
          <selection-toggle
            class="mb-5"
            :value="livePriceUpdate"
            v-model="livePriceUpdate"
            :options="[
              {
                text: 'Unit cost',
                value: 'price'
              },
              {
                text: 'Profit margin',
                value: 'markup'
              }
            ]"
          />
        </div>
        <div class="mb-2">Item change (per unit):</div>
        <table class="live-price-change-table">
          <thead>
            <tr>
              <th class="live-price-changes-header">Item</th>
              <th v-if="deviceWidth > 500" class="live-price-changes-header">
                <div>Labor time</div>
                <div>(hr/unit)</div>
              </th>
              <th v-if="deviceWidth > 850" class="live-price-changes-header">
                <div>Labor rate</div>
                <div>($/hr)</div>
              </th>
              <th class="live-price-changes-header">
                <div>Labor</div>
                <div>cost</div>
              </th>
              <th class="live-price-changes-header">
                <div>Material</div>
                <div>cost</div>
              </th>
              <th v-if="deviceWidth > 925" class="live-price-changes-header">
                <div>Combined</div>
                <div>cost</div>
              </th>
              <th class="live-price-changes-header">Profit</th>
              <th class="live-price-changes-header">Price</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(change, index) in Object.values(livePriceChanges)" :key="index">
              <LivePriceChange
                :change="change"
                :norm="norm[change.refId]"
                :livePriceUpdate="livePriceUpdate"
              />
            </template>
          </tbody>
        </table>
        <div class="flex flex-row justify-end mt-3 gap-2">
          <btn severity="secondary" size="lg" @click="() => $refs.livePriceChanges.close()">
            <Icon icon="xmark" />
            Cancel
          </btn>
          <btn severity="bolster" size="lg" @click="approveLivePriceChanges">
            <Icon icon="check" />
            Apply Changes
          </btn>
        </div>
      </template>
    </Modal>
    <AssemblyAdded
      :refId="addedAssemblyPreferenceRefId"
      store="Quote"
      ref="assemblyAdded"
      :key="addedAssemblyPreferenceRefId"
    />
  </div>
  <div v-else class="w-full h-full flex flex-col justify-start items-stretch gap-4 p-8">
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" class="grow-0 h-40 w-40 min-h-40 max-w-40" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" class="grow-0 h-40 w-40 min-h-40 max-w-40" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" class="grow-0 h-40 w-40 min-h-40 max-w-40" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
    <div class="flex items-stretch justify-stretch gap-2">
      <Skeleton shape="circle" class="grow-0 h-40 w-40 min-h-40 max-w-40" />
      <Skeleton shape="rectangle" height="100" class="grow-1" />
    </div>
  </div>
</template>

<script>
import LivePriceChange from '../quote/LivePriceChange.vue'
import AddressForm from '../ui/forms/AddressForm.vue'
import Field from '../ui/Field.vue'
import ChangeOrders from '../quote/ChangeOrders.vue'
import Activities from '../ui/activities/Activities.vue'
import FileList from '../ui/FileList.vue'
import Files from '../Files/List.vue'
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import ExternalChanges from '../mixins/ExternalChangesMixin'
import QuotePresentation from '../quote/presentation/QuotePresentation.vue'
import Normalize from '../../../imports/api/Normalize'
import CheckaUserPermsMixin from '../mixins/CheckaUserPermsMixin'
import SchedulePossibleColumns from '../quote/Spreadsheet/possibleColumns/SchedulePossibleColumns'
import Schedule from '../schedule/Schedule.vue'
import Status from '../../../imports/api/Statuses'
import Estimating from '../Sheets/quote/estimating/Estimating.vue'
import eventBus from '../../eventBus'
import EstimateDashboard from '@/components/pages/Quote/EstimateDashboard.vue'
import ProjectProgress from '@/components/quote/progress/ProjectProgress.vue'
import AutoCost from '../../../imports/api/AutoCost.js'
import ActivityChannelBody from '@/components/Activities/ActivityChannelBody.vue'
import ActivityChannels from '@/components/mixins/ActivityChannels'
import ProgressSpinner from 'primevue/progressspinner'
import Restore from '@/components/composables/Restore.js'
import { getCurrentInstance, onMounted, onBeforeUnmount, ref } from 'vue'
import QuoteInvoices from '@/components/quote/QuoteInvoices.vue'
import useSchedule from '@/components/schedule/Schedule'
import useGantt from '@/components/ui/gantt/Gantt'
import useGantts from '@/components/ui/gantt/Gantts'
import ChangeTracker from '@/components/bodies/UtilityComponents/ChangeTracker.vue'
import Selections from '@/components/bodies/CostItem/Selections.vue'
import BudgetList from '@/components/quote/BudgetList/BudgetList.vue'
import MaterialList from '@/components/quote/MaterialsList/MaterialsList.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import AssemblyAdded from '@/components/bodies/AssemblyAdded.vue'
import QuoteTakeoff from '@/components/quote/QuoteTakeoff.vue'

const { statuses } = Status

const { rereference, getNormalizedRootRefId } = Normalize

export default {
  name: 'QuoteBody',

  setup() {
    const $this = getCurrentInstance().proxy
    const { initialize } = Restore.useRestore({ type: 'quote' })
    const { resetGanttAndSchedule } = useSchedule()
    const { clearGanttInstance, gantt } = useGantt()
    const { updateDataInGantt } = useGantts()

    const { smallFormat } = useMediaQuery()

    const addedAssemblyPreferenceRefId = ref(null)
    const handleAddedAssemblyPreferences = async (assemblyRefIds) => {
      const open = () =>
        c.throttle(() => {
          _.setCacheItem(`skip-assemblyAdded`, 0, 'stepexplainermodal', $this.$store.state.session)
          addedAssemblyPreferenceRefId.value = assemblyRefIds[0]
          $this.$nextTick(() => $this.$refs.assemblyAdded.open())
        })

      if (_.getCacheItem(`skip-assemblyAdded`, 'stepexplainermodal', $this.$store.state.session)) {
        $this.$store.dispatch('alert', {
          size: 'sm',
          message: 'Skipping assembly wizard. Click "Open wizard" to open.',
          timeout: 10000,
          actions: [
            {
              title: 'Open wizard',
              action: open
            }
          ]
        })
        return
      }

      open()
    }

    onMounted(() => {
      eventBus.$once(`${$this.uid}-selected`, () => {
        initialize({ refId: $this.refId, store: $this.storeName })

        eventBus.$once(`restored-${$this.refId}`, () => {
          $this.restored = true
        })

        eventBus.$on(`saved-quote-${$this.refId}`, () => {
          // clear stored instance which will result in a reload
          clearGanttInstance($this.quote_id)
          // clear all tasks and links if gantt instance exists, meaning they have visited the schedule tab
          if (gantt.value) gantt.value.clearAll()
          // if on the schedule tab reload the schedule
          if ($this.activeTabs.includes('Schedule'))
            eventBus.$emit(`fetch-schedule-${$this.quote_id}`)
          const companyId = $this.$store.state.session.company.company_id
          // need to update name, status if there is a company wide schedule
          updateDataInGantt(`${companyId}-schedule`, $this.quote_id.toString(), {
            text: $this.quote_name
          })
        })
        // Testing added assembly panel
        // console.log(_.imm(Object.keys($this.norm).filter(ref => $this.norm[ref].type !== 'cost_item').reduce((acc, ref) => ({
        //   ...acc,
        //   [ref]: {
        //     name: $this.norm[ref].quote_name || $this.norm[ref].assembly_name,
        //     oDimensions: $this.norm[ref].oDimensions,
        //     asRequiredDimensions: $this.norm[ref].asRequiredDimensions,
        //     asDimensionsUsed: $this.norm[ref].asDimensionsUsed,
        //     aoChildren: $this.norm[ref].aoChildren,
        //     parentRefId: $this.norm[ref].parentRefId,
        //   },
        // }), {})))
        // handleAddedAssemblyPreferences(['as_ipWJi0Fp62GA8w_baXbvzUImAwW'])
      })
      eventBus.$on('openAddedAssemblyPreferences', handleAddedAssemblyPreferences)
    })

    onBeforeUnmount(() => {
      resetGanttAndSchedule()
      eventBus.$off(`saved-quote-${$this.refId}`)
      eventBus.$off('openAddedAssemblyPreferences', handleAddedAssemblyPreferences)
    })

    return {
      smallFormat,
      addedAssemblyPreferenceRefId
    }
  },

  mixins: [
    ObjectManipulator('quote', false),
    BodyMixin,
    ExternalChanges,
    CheckaUserPermsMixin,
    ActivityChannels
  ],

  data() {
    return {
      /**
       * Shows or hides the cost breakdown
       * in the totals section at the bottom of the items
       */
      costBreakdown: false,
      SchedulePossibleColumns,
      windowWidth: window.innerWidth,
      forceShowExtras: 0,
      restored: false,

      edit: true,

      emptyTaxSet: false,

      headChangeOrderId: null,

      checkedOutChangeOrderId: null,

      startDimensionsOpen: 0,

      addingItem: false,

      priceViewOptions: [
        {
          value: 'cost',
          text: 'Cost'
        },
        {
          value: 'price',
          text: 'Price'
        },
        {
          value: 'profit',
          text: 'Profit'
        },
        {
          value: 'margin',
          text: '%'
        }
      ],
      showForCost: ['cost', 'price', 'profit', 'margin'],

      itemViewOptions: [
        {
          value: 'cards',
          text: 'Item cards'
        },
        {
          value: 'summary',
          text: 'Tree view'
        }
      ],
      itemView: ['cards'],

      viewAssembly: null,
      viewAssemblyRefId: null,

      viewDimensions: 0,

      dimensionsVisible: 0,

      itemsVisible: 0,

      bidList: [],

      checkingOutBidId: null,

      normalizedBidQuote: null,

      confirmDimensionsShowed: false,

      loadingLivePrice: false,

      showLivePriceWarning: false,

      autoCostDisabledWarning: false,

      livePriceChanges: [],

      livePriceUpdate: 'price',

      statuses,

      isProjectSettingOpen: false,

      showAllFiles: false
    }
  },

  watch: {
    routeTab(t) {
      c.throttle(() => {
        const arrayed = c.makeArray(t)
        if (arrayed[0] !== this.activeTabs[0]) {
          this.activeTabs = arrayed
        }
      })
    },
    activeTabs(tab) {
      this.$router
        .push({
          query: {
            tab
          }
        })
        .catch(() => {})
    },

    async itemView(newView) {
      const itemViewType = c.makeArray(newView)[0]
      if (
        c.makeArray(this.itemView)[0] !== (await this.$store.dispatch('getMeta', 'itemViewType'))
      ) {
        await this.$store.dispatch('setMeta', {
          itemViewType
        })
      }
    },

    async showForCost(show) {
      const showForCost = c.makeArray(show).join(',')
      if (showForCost !== (await this.$store.dispatch('getMeta', 'showForCost'))) {
        await this.$store.dispatch('setMeta', {
          showForCost
        })
      }
    },

    quote_margin_net() {
      const margin = c.toNum(c.toNum(this.quote_margin_net) * 100, 2)
      c.throttle(
        () => {
          this.margin = margin
        },
        { delay: 2000 }
      )
    },

    refId() {
      if (
        !this.$route.query.confirmDimensions ||
        this.confirmDimensionsShowed ||
        !this.norm ||
        !this.norm[this.refId] ||
        !this.norm[this.refId].aoChildren[0]
      ) {
        return
      }

      c.throttle(
        () => {
          this.confirmDimensionsShowed = true
          this.$store.dispatch('Quote/confirmDimensions', {
            refId: this.norm[this.refId].aoChildren[0]
          })
        },
        {
          delay: 500
        }
      )
    }
  },

  computed: {
    activeTabs: {
      get() {
        const rtq = this.$route.query?.tab ?? ['Estimate']
        return c.makeArray(rtq)
      },
      set(tabs) {
        this.$router.push({
          query: {
            tab: tabs
          }
        })
      }
    },
    quoteDraftStatus() {
      if (this.quote_status === 'h') return 'Cancelled project'
      if (this.quote_status === 'x') return 'Cancelled project'
      if (this.quote_status === 'c') return 'Closed project'
      if (this.quote_status === 'g') return 'Closed project'
      const pending = this.quote_status === 'p'
      const inprogress = this.quote_status === 'f'
      const booked = this.quote_status === 'k'

      const unsentChanges = this.isDirty || !this.change_order_time_sent

      if (pending && unsentChanges) return 'Draft proposal'
      if (pending && this.change_order_time_sent) return 'Awaiting client approval'

      if ((inprogress || booked) && unsentChanges) return 'Draft changes'
      if (booked && unsentChanges) return 'Draft changes'

      const unapprovedChangesC = !this.change_order_company_has_approved
      if ((booked || inprogress) && unapprovedChangesC) return 'Manager approval required'

      const unapprovedChanges = !this.change_order_client_has_approved
      if ((booked || inprogress) && unapprovedChanges) return 'Awaiting client approval'

      if (booked) return 'Booked project'
      if (inprogress) return 'In-progress project'

      return 'Draft'
    },
    isSuperAdmin() {
      return this.$store.state.session.authorizedUser.user_is_super_user
    },
    hasLivePricingEnabled() {
      return AutoCost.hasAutoCostSubscription(this.$store.state.session.company)
    },
    isExpired() {
      const currentTime = new Date().getTime()
      return (
        this.quote_time_expired &&
        currentTime > this.quote_time_expired &&
        this.quote_status === 'p'
      )
    },
    deviceWidth() {
      return this.$store.state.session.deviceWidth
    },
    showRatingPanelComputed() {
      return (
        this.getClientRatingComputed[0] &&
        this.getClientRatingComputed[0].rating_status &&
        this.getClientRatingComputed[0].rating_status !== 'g'
      )
    },
    getClientRatingComputed() {
      const returnMe =
        this.aoRatings[0] && this.aoRatings[0].rating_id
          ? this.aoRatings.filter((rating) => {
              if (rating.rating_object_type === 'client') {
                return rating
              }
              return null
            })
          : null
      return returnMe
    },
    livePriceItems() {
      return Object.values(this.norm).filter(
        (item) => item.type === 'cost_item' && item.live_price_reference
      )
    },
    livePriceLabor() {
      return Object.values(this.norm).filter(
        (item) =>
          item.type === 'cost_item' &&
          item.labor_type_id &&
          AutoCost.isAutoCostLaborTypeId(item.labor_type_id)
      )
    },
    calculateSaveBottomOffset() {
      let offset = 20
      if (this.activeTabs.includes('Preview')) {
        offset += this.windowWidth <= 764 ? 65 : 80
      }
      if (this.activeTabs.includes('Spreadsheet')) {
        offset += this.windowWidth <= 764 ? 50 : 45
      }
      if (this.windowWidth <= 764) {
        offset += 35
      }

      return `${offset}px`
    },
    calculateSaveRightOffset() {
      let offset = 0
      if (
        this.activeTabs.includes('Preview') ||
        this.activeTabs.includes('Estimate') ||
        this.activeTabs.includes('Spreadsheet')
      ) {
        offset += 15
      }
      if (this.windowWidth > 764) {
        offset += 5
      }
      return `${offset}px`
    },
    chatButtonBottom() {
      let offset = 20
      if (this.activeTabs.includes('Preview')) {
        offset += this.windowWidth <= 764 ? 56 : 70
      }
      if (this.activeTabs.includes('Spreadsheet')) {
        offset += this.windowWidth <= 764 ? 41 : 35
      }
      if (this.windowWidth <= 764) {
        offset += 27
      }

      return `${offset}px`
    },
    chatButtonRight() {
      let offset = 15
      if (this.windowWidth > 764) {
        offset += 5
      }
      return `${offset}px`
    },
    company() {
      if (!this.global) return this.$store.state.session.company

      return {
        company_minimum_quote_margin: 0.2,
        company_default_markup: 1.43
      }
    },
    displaySchedule() {
      return (
        this.$store.state.session.company.aoModules &&
        this.$store.state.session.company.aoModules.simple_scheduler === '1' &&
        this.quote_name &&
        this.aoChildren &&
        this.aoChildren.length
      )
    },
    displayInvoices() {
      return this.quote_status !== statuses.Pending
    },
    quickEditPermission() {
      return (
        this.$store.state.session.company.aoModules &&
        this.$store.state.session.company.aoModules.quick_edit === '1'
      )
    },
    client() {
      if (!this.global) return this.oClient

      return {
        client_name: `${this.$store.state.session.user.user_fname} ${this.$store.state.session.user.user_lname}`,
        client_fname: `${this.$store.state.session.user.user_fname}`,
        client_lname: `${this.$store.state.session.user.user_lname}`,
        client_address: '123 Abc St SW'
      }
    },
    routeTab() {
      return this.$route.query.tab
    },
    minPriceItems() {
      return Object.keys(this.norm)
        .filter(
          (r) =>
            this.norm[r].cost_type_minimum_price_net && this.norm[r].cost_type_minimum_price_net > 0
        )
        .map((r) => this.norm[r].cost_type_name)
    },
    renderedTaxPercentage() {
      return c.divide(this.quote_price_tax, this.quoteNet)
    },
    dangerTabs() {
      return [
        ...(this.missingDimensions ? ['Dimensions'] : []),
        ...(!this.quote_time_sent ? ['Send'] : [])
      ]
    },
    missingDimensions() {
      const rooms = this.dimensionalAreas

      return rooms.reduce((count, room) => {
        const dims = this.norm[room].oDimensions
        const roomCount = this.norm[room].asRequiredDimensions.filter(
          (abbr) => !dims[abbr] || !dims[abbr].value
        )
        return count + roomCount.length
      }, 0)
    },
    quoteAddress: {
      get() {
        return {
          line1: this.quote_address || '',
          line2: this.quote_suite || '',
          city: this.quote_city,
          country: this.country_id,
          state: this.province_id,
          postal_code: this.quote_postal
        }
      },
      set(v) {
        this.quote_address = v.line1
        this.quote_suite = v.line2
        this.quote_city = v.city
        this.country_id = v.country
        this.province_id = v.state
        this.quote_postal = v.postal_code
      }
    },
    isPublicRfq: {
      get() {
        return this.quote_rfq_is_public
      },
      set(v) {
        this.quote_rfq_is_public = v
      }
    },
    showItems() {
      const rfq = this.quote_is_rfq
      const acc = this.quote_rfq_time_accepted
      return !rfq || acc
    },
    countItems() {
      return Object.keys(this.$store.state[this.storeName].normalized).length
    },
    hideDistractions() {
      return !this.aoChildren.length
    },
    dimensionalAreas() {
      const items = this.norm

      return Object.keys(items).filter(
        (refId) =>
          items[refId] &&
          (items[refId].type === 'assembly' || items[refId].type === 'quote') &&
          items[refId].asRequiredDimensions
      )
    },

    hasPendingChangeOrder() {
      return /k|f/.test(this.quote_status) && !this.change_order_time_booked
    },
    viewAssemblyObject() {
      const ref = this.viewAssemblyRefId
      if (!ref || !(ref in this.norm)) {
        return null
      }

      return this.norm[ref]
    },
    /* cashflow */
    // current
    clientPayments() {
      return this.quote_paid_gross
    },
    vendorPayments() {
      return -1 * this.paidToVendor
    },
    salesTaxPayable() {
      return (
        -1 *
        (this.quote_paid_gross - this.quote_paid_net - (this.paidToVendor - this.paidToVendorNet))
      )
    },
    cashFlow() {
      return this.quote_paid_net - this.paidToVendorNet
    },

    // future
    futureClientPayments() {
      return this.quote_unpaid_gross + this.quote_uninvoiced_gross
    },
    futureVendorPayments() {
      return -1 * this.unpaidToVendor
    },
    futureSalesTaxPayable() {
      return (
        -1 *
        (this.quote_unpaid_gross +
          this.quote_uninvoiced_gross -
          (this.quote_unpaid_net + this.quote_uninvoiced_net) -
          (this.unpaidToVendor - this.unpaidToVendorNet))
      )
    },
    futureCashFlow() {
      return this.quote_unpaid_net + this.quote_uninvoiced_net - this.unpaidToVendorNet
    },

    items() {
      const set = this.norm
      let itemKeys = []
      Object.keys(set).forEach((refId) => {
        if (set[refId] && set[refId].type === 'cost_item') {
          itemKeys = [...itemKeys, refId]
        }
      })
      return itemKeys
    },

    itemsWhole() {
      return this.items.map((r) => this.norm[r])
    },

    paidToVendor() {
      return this.itemsWhole.reduce((acc, item) => acc + c.n(item.item_vendor_paid_gross), 0) || 0.0
    },

    paidToVendorNet() {
      return this.itemsWhole.reduce((acc, item) => acc + c.n(item.item_vendor_paid_net), 0) || 0.0
    },

    unpaidToVendor() {
      return (
        this.itemsWhole.reduce((acc, item) => acc + c.n(item.item_vendor_unpaid_gross), 0) || 0.0
      )
    },

    unpaidToVendorNet() {
      return this.itemsWhole.reduce((acc, item) => acc + c.n(item.item_vendor_unpaid_net), 0) || 0.0
    },

    warningLosing() {
      return this.quote_margin_net < 0
    },

    warningMinimum() {
      return this.quote_margin_net < this.company.company_minimum_quote_margin
    },

    showExtras() {
      return this.quote_notes || this.aoFiles.length || this.forceShowExtras
    },

    quoteSteps: {
      get() {
        return this.activeTabs
      },
      set(step) {
        this.activeTabs = c.makeArray(step)
      }
    },
    requiresClientApproval() {
      const p = this.getField('quote_price_net')
      const all = this.$store.state[this.storeName].all
      const allq = all && all[String(this.getField('quote_id'))]
      const allr = allq && this.refId && allq[this.refId]
      const pn = allr && allr.quote_price_net
      return !c.eq(p, pn)
    },

    adjustedProfit: {
      get() {
        return this.getField('quote_price_net') - c.n(this.getField('quote_total_cost_net'))
      },

      async set(v) {
        if (c.eq(v, this.adjustedProfit, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setProfit', {
          store: this.storeName,
          object: this.cast(),
          profit: c.n(v)
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    /**
     * quote_price_net
     */
    quoteNet: {
      get() {
        return this.quote_price_net
      },

      async set(v) {
        if (c.eq(v, this.quoteNet, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setNetPrice', {
          store: this.storeName,
          object: this.cast(),
          netPrice: c.n(v)
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    quoteGross: {
      get() {
        return this.quote_price_gross
      },

      async set(v) {
        if (c.eq(v, this.quoteGross, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setGrossPrice', {
          store: this.storeName,
          object: this.cast(),
          grossPrice: c.n(v)
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    classStatus() {
      return c.statusColors[this.quote_status]
    },

    status() {
      return c.format(this.quote_status, 'status')
    },

    childrenForList: {
      get() {
        return this.aoChildren
      },
      set(childRefIds) {
        this.$store.dispatch(`${this.storeName}/transferChildren`, {
          childRefIds,
          parentRefId: this.refId
        })
      }
    },

    /**
     * Price that the quote should be, if increased costs are accounted for
     */
    commensuratePrice() {
      return this.commensurateMarkup * c.toNum(this.quote_total_cost_net)
    },

    commensurateMarkup() {
      return (
        c.toNum(this.quote_price_net) /
        (c.toNum(this.quote_total_cost_net) - c.toNum(this.quote_total_cost_net_changed))
      )
    },

    costsChangedWarning() {
      return this.quote_total_cost_net_changed > 0
    },

    marginWarning() {
      return (
        !this.costsChangedWarning &&
        this.profitPercentage - this.company.company_minimum_quote_margin < -0.009
      )
    },

    objectTitle() {
      return /k|f/.test(this.quote_status) ? 'project' : 'quote'
    },

    clientId: {
      get() {
        return this.client_id
      },

      async set(v) {
        if (c.eq(v, this.clientId, 0)) {
          return
        }

        this.client_id = v

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setClient', {
          store: this.storeName,
          object: this.cast(),
          client: v,
          audit: false,
          auditLocal: false
        })

        this.fieldDispatch(changes, explicitChanges)

        this.$nextTick(() => this.addDefaults())
      }
    },

    quoteFiles: {
      get() {
        return this.aoFiles.map((r) => this.$store.state.Quote.normalized[r])
      },
      set(files) {
        this.$store.dispatch('Quote/replaceChildren', {
          refId: this.refId,
          children: files,
          field: 'aoFiles'
        })
      }
    },

    stepIndex() {
      if (this.quote_status === 'p') {
        if (this.quote_time_sent > 0) {
          return 2
        } else if (!c.empty(this.quote_id)) {
          return 1
        }
        return 0
      }
      return 4
    },

    quoteDownloadUrl() {
      return c.link(`quote/download/${this.quote_id}`)
    },

    sowDownloadUrl() {
      return c.link(`quote/download/sow/${this.sowType}/${this.quote_id}`)
    },

    isDiscounted() {
      return !c.eq(this.quote_discount_net, 0, 2)
    },

    targetMargin: {
      get() {
        const margin = c.toNum(c.toNum(this.quote_margin_net) * 100, 2)
        c.throttle(
          () => {
            this.margin = margin
          },
          { delay: 2000 }
        )
        return margin
      },

      async set(v) {
        if (c.eq(v, this.targetMargin, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setTargetMargin', {
          store: this.storeName,
          object: this.cast(),
          targetMargin: c.n(v)
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    /**
     * Set target markup
     */
    targetMarkup: {
      get() {
        const qm = c.toNum(this.quote_markup_net)
        const markup =
          c.notNaN(this.object.quote_markup_percentage_adjustment) *
            (c.eq(qm - 1, 0, 5) ? 1 : qm - 1) +
          qm
        return markup
      },

      async set(v) {
        if (c.eq(v, this.targetMarkup, 6)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setTargetMarkup', {
          store: this.storeName,
          object: this.cast(),
          targetMarkup: c.n(v)
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    /**
     * Set discount percentage
     */
    discountPercent: {
      get() {
        return c.toNum(this.quote_discount_percentage, 4) * 100
      },
      async set(v) {
        if (c.eq(v, this.discountPercent, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch(
          'Quote/setDiscountPercentage',
          {
            store: this.storeName,
            object: this.cast(),
            discountPercentage: c.n(v)
          }
        )

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    /**
     * Set discount net
     */
    discountNet: {
      get() {
        return this.quote_discount_net_base
      },
      async set(v) {
        let net = v

        if (/[0-9,.]%\s*/.test(String(net))) {
          const perc = Math.min(c.n(net) / 100, 1)
          net = perc * +this.getField('quote_subtotal_net')
        }

        if (c.eq(net, this.discountNet, 2)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setDiscountNet', {
          store: this.storeName,
          object: this.cast(),
          discountNet: net
        })

        this.fieldDispatch(changes, explicitChanges)
      }
    },

    /**
     * Set tax
     */
    taxId: {
      get() {
        return this.tax_id
      },

      async set(v) {
        if (c.eq(v, this.taxId, 0)) {
          return
        }

        const { changes, explicitChanges } = await this.$store.dispatch('Quote/setTax', {
          store: this.storeName,
          object: this.cast(),
          tax: v
        })

        this.fieldDispatch(changes, explicitChanges)
        this.addDefaults()
      }
    },

    editable: {
      get() {
        return this.edit
      },
      set(b) {
        this.edit = b
      }
    },

    averageHourlyRate() {
      return c.toNum(this.quote_labor_cost_net) / c.toNum(this.quote_total_hours)
    },

    quoteProfit() {
      return c.toNum(this.quote_price_net, 2) - c.toNum(this.quote_total_cost_net, 2)
    },

    profitPercentage() {
      return c.toNum(this.quoteProfit) / c.toNum(this.quote_price_net)
    },
    typeName() {
      const status = this.isExpired ? 'z' : this.quote_status

      switch (status) {
        case 'd':
          return ['Declined proposal', 'danger']
        case 'k':
          return ['Booked project', 'success']
        case 'f':
          return ['In-progress project', 'success']
        case 'g':
          return ['Completed project', 'success']
        case 'z':
          return ['Expired proposal', 'danger']
        case 'p':
        default:
          return ['Proposal', 'warning']
      }
    },
    showChatButton() {
      return (
        !this.global &&
        (this.activeTabs.includes('Preview') ||
          this.activeTabs.includes('Estimate') ||
          this.activeTabs.includes('Spreadsheet')) &&
        this.$store.state.session.user.user_id === this.quote_pm_user_id &&
        this.$store.state.session.company &&
        this.$store.state.session.company.aoModules.chat === '1'
      )
    }
  },

  methods: {
    onCreateLeadRotation() {
      return this.$store.dispatch('LeadRotation/goToCreatePage', {})
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    onDownload(e) {
      this.downloadQuote()
      return e.preventDefault()
    },
    onFocusHandler() {
      this.$store.dispatch('Dimension/getPossibleDimensions')
    },
    handleOpenProjectSettings() {
      this.isProjectSettingOpen = true
      this.$refs.projectInfo.open()
    },
    async ctrlh(e) {
      this.downloadQuote()
      return e.preventDefault()
    },
    async setMod(id) {
      this.addLoading()
      try {
        await this.$store.dispatch('Quote/setMod', {
          id,
          refId: this.refId
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage.includes('found')
            ? 'That postal/zip code was not found or invalid (only US/CA postal/zip codes). Try a different one.'
            : e.userMessage || e.message
        })
      } finally {
        this.removeLoading()
      }
    },
    async uploadFileHandler(files) {
      if (!files.length) return

      this.addLoading()
      const file = files[0]

      try {
        const reader = new FileReader()

        reader.onload = (e) =>
          this.loadQuoteRaw(JSON.parse(decodeURIComponent(atob(e.target.result))).payload)

        reader.readAsText(file, 'UTF-8')
      } catch (e) {
        this.endLoading()
        this.errorParsing = e.userMessage || e.message
        throw e
      }
    },
    downloadQuote() {
      const content = JSON.stringify({ payload: this.$store.state.Quote.normalized })
      const filename = `Quote-${Date.now()}.costcertified`

      // const blob = new Blob([content], {
      //   type: 'text/plain;charset=utf-8',
      // }, filename);
      // saveAs(blob, filename);

      const element = document.createElement('a')
      element.setAttribute('href', `data:base64,${btoa(encodeURIComponent(content))}`)
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
      // const link = window.URL.createObjectURL(blob);
      // window.location = link;
    },
    async loadQuoteRaw(normalized) {
      const rereferenced = rereference(
        normalized,
        [getNormalizedRootRefId(normalized)],
        [this.refId]
      ).set
      this.$store.commit({
        type: 'Quote/SET_NORMALIZED',
        object: rereferenced,
        refId: this.refId
      })
      await this.$nextTick()
      this.loading = 0
    },
    async audit() {
      await this.$store.dispatch('Quote/auditDependencies', {
        refId: this.refId
      })
    },
    // async toggleEnableModifications() {
    //   const val = this.quote_is_upgrading_allowed ? 1 : 0;
    //   this.quote_is_upgrading_allowed = val;
    //   this.saveFields(['quote_is_upgrading_allowed']);
    // },
    async checkOutBid(id) {
      if (id && id === this.checkingOutBidId) {
        this.checkingOutBidId = null
        return
      }

      this.checkingOutBidId = id

      const { normalized } = await this.$store.dispatch('Bid/getQuote', {
        id,
        normalized: true
      })

      this.normalizedBidQuote = normalized
    },

    async fetchBids() {
      const { set } = await this.$store.dispatch('Bid/search', {
        filters: { quote_id: this.quote_id }
      })
      this.bidList = set
    },
    async beforeSave() {
      if (this.quote_is_rfq && !this.getRfqItem()) {
        await this.createRfqItem()
      }
    },

    async afterSave() {
      // If there are no bids created yet but this is a rfq,
      // then create them, as long as there are vendors
      if (this.quote_is_rfq && this.quote_rfq_vendor_ids.length) {
        await this.createRfqBids()
      }

      // keepChanges = true because between the time it
      // takes to save and come back, then reset changes
      // we would want to make sure that any intermittent
      // changes are kept so that work isn't lost
      this.resetCheckout(true)
    },

    // async createRfqItem() {
    //   const { refId } = await this.$store.dispatch(`${this.storeName}/addItem`, {
    //     type: 'assembly',
    //     id: 'blank',
    //     parent: this.refId,
    //     optional: false
    //   })
    //
    //   await this.$store.dispatch(`${this.storeName}/field`, {
    //     refId,
    //     changes: {
    //       assembly_is_rfq_root: 1
    //     },
    //     immediate: true,
    //     explicit: false,
    //     skipAudit: true,
    //     skipLocalAudit: true
    //   })
    // },

    getRfqItem() {
      if (!this.aoChildren || !this.aoChildren.length || !this.aoChildren[0]) return null
      return this.$store.state[this.storeName].normalized[this.aoChildren[0]]
    },

    async createRfqBids() {
      if (!this.quote_id) return

      if (!this.getRfqItem()) return

      const vids = this.quote_rfq_vendor_ids

      if (!vids.length) return

      // create a bid for each vendor that doesn't have one yet
      const { set: bids } = await this.$store.dispatch('Bid/filter', {
        filters: {
          quote_id: this.quote_id
        },
        offset: 0,
        limit: 10000
      })

      const missingvids = vids.filter(
        (vid) => !bids.find((b) => String(b.vendor_id) === String(vid))
      )

      await Promise.all(
        missingvids.map((vid) =>
          this.$store.dispatch('Bid/save', {
            object: {
              vendor_id: vid,
              quote_id: this.quote_id,
              item_ids: [this.getRfqItem().refId],
              bid_desc: this.quote_production_notes,
              bid_file_ids: this.production_file_ids
            },
            go: false
          })
        )
      )
    },
    beforeSelect() {
      // Make sure every quote is deselected first so that it doesn't cause issues
      // solves multiple bugs where remnants remain in the store
      this.$store.commit({
        type: 'Quote/UNSET_NORMALIZED'
      })
    },
    async beforeSend() {
      await c.throttle(() => {}, { delay: 555 })
      if (
        this.isDirty &&
        !(await this.$store.dispatch('modal/asyncConfirm', {
          message: 'There are unsaved changes to this project. Would you like to continue?'
        }))
      ) {
        throw new Error('Do not continue')
      }

      return true
    },
    dimensionsVisibilityHandler(isVisible) {
      this.dimensionsVisible = isVisible
    },
    itemsVisibilityHandler(isVisible) {
      this.itemsVisible = isVisible
    },
    /**
     *
     */
    async declineToBid() {
      return this.$store.dispatch('Quote/declineToBid', {
        refId: this.refId
      })
    },

    expandedHandler(refId, isExpanded) {
      if (isExpanded) {
        this.viewAssemblyRefId = this.smallFormat && refId
      }

      this.viewAssembly = this.smallFormat && !!isExpanded
    },

    clearAdjustment() {
      this.$store.dispatch('Assembly/removeAllSubAdjustments', {
        store: 'Quote',
        refId: this.refId || this.reference
      })
    },

    addingItemHandler(adding) {
      this.addingItem = !!adding

      c.throttle(
        () => {
          this.addingItem = false
        },
        { delay: 10000 }
      )
    },

    async forceSave() {
      try {
        await this.save()

        this.reload()
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message:
            "Saving somehow failed and we aren't sure why. We've built a recovery file for you.  Please contact support and provide the downloaded file to restore your changes."
        })
        this.buildRecoveryFile()
        throw e
      }
    },

    async invoiceProject() {
      await this.$store.dispatch('Quote/invoice', {
        refId: this.refId,
        go: false
      })

      if (this.$refs.outstandingInvoices) this.$refs.outstandingInvoices.reload()
    },

    /**
     * Set markup to minimum required by company
     * @returns self
     */
    setMarkupToMinimum() {
      const markup = c.marginToMarkup(this.company.company_minimum_quote_margin)
      const priceBeforeDiscount = markup * this.getField('quote_total_cost_net')
      this.quoteNet = priceBeforeDiscount + 0.01

      return this
    },

    /**
     * set Markup to default / target markup set by company
     * @returns self
     */
    setMarkupToDefault() {
      const markup = this.$store.getters.defaultMarkup
      const priceBeforeDiscount = markup * this.getField('quote_total_cost_net')
      this.quoteNet = priceBeforeDiscount + 0.01

      return this
    },

    /**
     * Event handler for when line items are loading
     * @returns self
     */
    lineItemChangeLoading(v = 0) {
      this.loading = v

      return this
    },

    /**
     * Returns whether a task is due or not
     * @returns bool
     */
    isTaskDue(t) {
      const s = this.quote_status
      return (
        t.task_status === 'u' &&
        (t.task_id ||
          t.task_stage_object_status === 'p' ||
          (s === 'k' && /k|p/.test(t.task_stage_object_status)) ||
          (s === 'f' && /f|k|p/.test(t.task_stage_object_status)))
      )
    },

    /**
     * Set quote values to the state of the most recent change order
     *  @returns {Promise<default.methods>}
     */
    checkoutLatestChangeOrder() {
      return this.reload()
    },

    /**
     * Remove change order (does not delete, only remove from view)
     * to be called AFTER it is actually deleted.
     * @param changeOrderId
     * @returns {Promise<default.methods>}
     */
    async removeChangeOrder(changeOrderId) {
      const co = this.aoChangeOrders.find(
        (ch) => String(changeOrderId) === String(ch.change_order_id)
      )
      const index = this.aoChangeOrders.indexOf(co)

      if (index > -1) {
        this.aoChangeOrders.splice(this.aoChangeOrders.indexOf(co), 1)
      }
      await this.checkoutLatestChangeOrder()
      await this.reload()
      await this.reset()

      return this
    },

    /**
     * Checkout a change order given by change order id
     * @param changeOrderId
     * @returns {Promise<any>}
     */
    async checkoutChangeOrder(changeOrderId, latest) {
      if (latest) {
        this.resetCheckout()

        return this
      }

      this.addLoading()

      this.headChangeOrderId = changeOrderId

      let normalized = {}
      try {
        const { normalized: norm } = await this.$store.dispatch('Quote/checkoutChangeOrder', {
          refId: this.refId,
          changeOrder: changeOrderId
        })
        normalized = norm
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.userMessage || 'Could not checkout that change order, please try again.',
          error: true
        })
        throw e
      }

      this.isCheckingOutOldVersion = true
      this.checkedOutChangeOrderId = normalized[this.refId].change_order_id

      this.endLoading()

      return this
    },

    /**
     *
     * @returns {Promise<void>}
     */
    async resetCheckout(keepChanges = false) {
      this.reload(keepChanges)
      this.isCheckingOutOldVersion = false
      this.checkedOutChangeOrderId = null
    },

    /**
     * Create a new sales tax
     * @returns {Promise<default.methods>}
     */
    async createSalesTax() {
      const tax = await this.$store.dispatch('create', {
        type: 'tax',
        embue: {
          province_id: this.province_id || this.company.province_id
        }
      })

      this.taxId = tax.tax_id

      return this
    },

    /**
     * Send this quote
     * @returns {Promise<default.methods>}
     */
    sendQuote() {
      return this.$store.dispatch('Quote/send', { refId: this.refId })
    },

    /**
     * Event handler for when there are no
     * tax options available. Prompt to create one etc.
     */
    taxIsEmpty() {
      this.emptyTaxSet = true
      this.tax_id = null
      this.tax_name = ''
    },

    /**
     * Event handler for when there ARE tax options,
     * no special prompts
     */
    taxIsNotEmpty() {
      this.emptyTaxSet = false
    },

    /**
     *
     * Overrides default after select
     * @returns {default.methods}
     */
    afterSelect() {
      if (!this.$route.query || !this.$route.query.tab) {
        this.activeTabs = ['Estimate']
      }

      this.$nextTick(() => {
        if (this.$refs.nameField && this.$refs.nameField.focus) {
          this.$refs.nameField.focus()
        }
      })

      return this
    },
    async setShowLivePriceWarning() {
      if (
        !this.hasLivePricingEnabled ||
        ((!this.livePriceItems || !this.livePriceItems.length) &&
          (!this.livePriceLabor || !this.livePriceLabor.length))
      ) {
        this.showLivePriceWarning = false
        return
      }

      const time = new Date().getTime()
      const twoWeeksAgo = time / 1000 - 1209600

      this.livePriceItems.forEach((item) => {
        if (this.showLivePriceWarning) return
        if (
          !item.live_price_last_fetch ||
          (parseInt(item.live_price_last_fetch, 10) < twoWeeksAgo && item.item_status === 'p')
        ) {
          this.showLivePriceWarning = true
          return
        }
      })

      this.livePriceLabor.forEach((item) => {
        if (this.showLivePriceWarning) return
        if (!item.live_price_last_fetch || parseInt(item.live_price_last_fetch, 10) < twoWeeksAgo) {
          this.showLivePriceWarning = true
        }
      })
    },
    async setAutoCostDisabledWarning() {
      if (
        ((this.livePriceItems && this.livePriceItems.length) ||
          (this.livePriceLabor && this.livePriceLabor.length)) &&
        !this.hasLivePricingEnabled
      ) {
        this.autoCostDisabledWarning = true
      }
    },
    async fetchQuoteLivePrices() {
      this.loadingLivePrice = true
      try {
        const livePriceRefIds = this.livePriceItems.map((item) => item.refId)
        const livePriceLaborRefIds = this.livePriceLabor.map((item) => item.refId)

        const quote = this.$store.state.Quote?.normalized[this.rootRefId]
        const company = this.$store.state.session.company
        const zipcode = AutoCost.getAutoCostZipcode(company, quote)

        const response = await Promise.all([
          livePriceRefIds && livePriceRefIds.length
            ? this.$store.dispatch('Quote/fetchLivePricing', {
                zipcode,
                refIds: livePriceRefIds,
                rootRefId: this.rootRefId,
                all: true
              })
            : null,
          livePriceLaborRefIds && livePriceLaborRefIds.length
            ? this.$store.dispatch('Quote/fetchLaborLivePricing', {
                zipcode,
                refIds: livePriceLaborRefIds,
                rootRefId: this.rootRefId
              })
            : null
        ])

        if (response[0] && Object.keys(response[0]).length) {
          this.livePriceChanges = {
            ...response[0]
          }
        }

        if (response[1] && Object.keys(response[1]).length) {
          Object.keys(response[1]).forEach((ref) => {
            this.livePriceChanges[ref] = {
              ...this.livePriceChanges[ref],
              ...response[1][ref]
            }
          })
        }

        this.livePriceUpdate =
          this.quote_status === 'k' || this.quote_status === 'f' || this.quote_status === 'g'
            ? 'markup'
            : 'price'

        this.$refs.livePriceChanges.open()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not update price.',
          error: true
        })
      }

      this.loadingLivePrice = false
    },

    async approveLivePriceChanges() {
      const type =
        this.livePriceUpdate === 'price' ? 'Quote/updateLivePrices' : 'Quote/updateLiveCosts'
      try {
        this.$store.dispatch(type, {
          changes: Object.values(this.livePriceChanges)
        })
        this.$refs.livePriceChanges.close()
        this.showLivePriceWarning = false
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not update price.',
          error: true
        })
      }
    },
    onCreatedSelected() {
      this.$nextTick(() => {
        this.quote_name =
          this.quote_name || `${this.client.user_fname} • ${this.client.user_address}`
        this.startDimensionsOpen = !this.quote_id || this.activeDimensions < 1
      })

      if (!this.oMod || !this.oMod.mod_id) {
        // this.oMod = this.$store.getters.defaultMod
        // @TODO do this somewhere else so it doesn't create a glut
        // this.oMod = this.$store.getters.defaultMod
        // Do this passively with no audit to speed up
        // this.$store.commit({
        //   type: 'Quote/FIELD',
        //   refId: this.refId,
        //   changes: {
        //     oMod: { ...this.$store.getters.defaultMod }
        //   }
        // })
      }

      if (this.quote_is_rfq && !this.quote_rfq_time_accepted) {
        this.fetchBids()
      }

      if (!this.activeTabs || !this.activeTabs.length) {
        if (!this.aoChildren.length) this.activeTabs = ['Estimate']
        else this.activeTabs = ['progress']
      }
    },
    onBeforeCreateEmbued() {
      if (this.global) return

      this.addBodyLoading()
      this.$nextTick(() => {
        if (this.client_id && !this.quote_id) {
          this.clientId = this.client_id
          this.removeBodyLoading()
        } else {
          this.removeBodyLoading()
        }

        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.nameField.focus()
          }, 500)
        })
      })
    },
    onMountedSelected() {
      this.initQuoteChannel(
        this.$store.state.session.user.user_id,
        this.$store.state.session.user.user_fname,
        this.$store.state.session.user.user_lname,
        this.quote_id,
        this.quote_name,
        this.oClient.client_user_id,
        this.client.user_fname,
        this.client.user_lname,
        this.quote_status
      )
      // @todo Do this when needed only (when viewed or when)
      // this.$store.dispatch('Quote/recalcAddons')
    },
    onMountedSaved() {
      if (this.$refs.doc) this.$refs.doc.reload()
    }
  },

  created() {
    // Only have ONE quote in normalized at a time
    this.$store.commit({
      type: 'Quote/CLEAR'
    })

    eventBus.$on(`${this.uid}-selected`, this.onCreatedSelected)
  },

  beforeCreate() {
    eventBus.$on(`${this.uid}-embued`, this.onBeforeCreateEmbued)
  },

  async beforeUnmount() {
    eventBus.$off('resize', this.onResize)
    eventBus.$off('ctrl-h', this.ctrlh)
    eventBus.$off('ctrl-s', this.onDownload)
    eventBus.$off('focus', this.onFocusHandler)
    eventBus.$off(`${this.uid}-selected`, this.onCreatedSelected)
    eventBus.$off(`${this.uid}-embued`, this.onBeforeCreateEmbued)
    eventBus.$off(`${this.uid}-selected`, this.onMountedSelected)
    eventBus.$off(`${this.uid}-saved`, this.onMountedSaved)
  },
  async mounted() {
    eventBus.$on('resize', this.onResize)
    this.$store.dispatch('Dimension/getPossibleDimensions', { force: true })
    this.$store.dispatch('triggerEvent', 'EstimatingPageViewed')

    if (this.global) {
      eventBus.$on('ctrl-s', this.onDownload)
    }
    eventBus.$on('ctrl-h', this.ctrlh)

    eventBus.$once(`${this.uid}-selected`, this.onMountedSelected)

    eventBus.$once(`${this.uid}-embued`, this.onMountedSaved)

    eventBus.$on(`${this.uid}-saved`, this.onMountedSaved)

    eventBus.$on('focus', this.onFocusHandler)

    this.itemView = (await this.$store.dispatch('getMeta', 'itemViewType')) || this.itemView

    this.showForCost = c.makeArray(
      (await this.$store.dispatch('getMeta', 'showForCost')) || this.showForCost
    )

    if (import.meta.env.PROD) {
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push(['setPath', '/'])
      _hsq.push(['trackPageView'])
      _hsq.push(['identify', { email: this.$store.state.session.user.user_email }])
    }

    eventBus.$once(`${this.uid}-selected`, async () => this.setShowLivePriceWarning())
    eventBus.$once(`${this.uid}-selected`, async () => this.setAutoCostDisabledWarning())
  },

  props: {
    autoSaveEnabled: {
      default: true
    },
    forceFetch: {
      default: true
    },
    forceFull: {
      default: true
    },
    global: {
      default: false
    },
    deselectOnDestroy: {
      default: true
    }
  },

  components: {
    QuoteTakeoff,
    AssemblyAdded,
    BudgetList,
    MaterialList,
    Selections,
    ProgressSpinner,
    ActivityChannelBody,
    AddressForm,
    LivePriceChange,
    QuotePresentation,
    Field,
    Activities,
    FileList,
    ChangeOrders,
    Files,
    // QuickEditTable,
    Schedule,
    Estimating,
    ProjectProgress,
    EstimateDashboard,
    QuoteInvoices,
    ChangeTracker,
    TabView,
    TabPanel
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.heading-btn {
  margin-left: 0.5em;
}

.quote-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  background-color: $flame-white;
  border-bottom: 1px solid $cool-gray-300;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch;

  .more.muted.xs {
    background: transparent !important;
    border: 1px solid $cool-gray-500 !important;
    .button--text {
      color: $cool-gray-500 !important;
    }
  }

  .quote-status-button {
    font-size: 0.8em;
    padding: 0.2em 0.75em;
    border-radius: 1em;
    font-weight: normal;

    &.warning {
      background: #fdecbc !important;
      color: #754909 !important;
    }
  }

  .heading-middle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% - 350px);
    max-width: calc(100% - 350px);
    flex: 5 100%;
    height: 100%;
    font-size: 1.2em;
    font-weight: 500;

    > * {
      margin: 0 0.5em;
    }
  }
  .heading-right {
    width: 350px;
    flex: 0 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    font-size: 1.2em;
    font-weight: 500;

    .send-button {
      display: inline-flex;
      padding: 0.3em 0.6em;
      border-radius: 3px;
      background: $pitch-black;
      color: $level-yellow;
      font-weight: 500;
      display: inline-block;
      font-size: 0.9em;
      transition: opacity 0.1s ease;

      &:hover {
        opacity: 0.8;
        background: $pitch-black;
        color: $level-yellow !important;
      }
    }
  }

  .content-left {
    display: flex;
    align-items: center;
    margin-left: 1.4em;
    height: 100%;

    .quote-title-input {
      margin-left: 24px;
      color: $pitch-black;

      p {
        font-weight: bold;
      }

      &:hover {
        .quote-title-text {
          color: #1f92fc; //bootstrap info
          text-decoration: underline;
        }
      }
    }
    &.mobile {
      margin-left: 2.8em;
    }
  }

  .content-right {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1.4em;
    padding-top: 0.78em;

    .quote-action-buttons {
      display: inline;
    }

    .quote-next-steps {
      display: inline;
    }

    .quote-refresh-live-pricing {
      display: inline;
      margin-left: 0.5em;
    }
  }
}

.quote--estimating-dongles {
  position: fixed;
  bottom: 2em;
  text-align: right;
  right: 8em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: $z-layout + 1;
}

.assembly-item-mask {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  transition: opacity 1s;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $z-mask;
  opacity: 0;
}

.assembly-item-mask.active {
  opacity: 1;
  pointer-events: all;
}

input.simple {
  border: none;
  border-bottom: 1px solid $cool-gray-500;
  height: 30px;
  font-size: 18px;
  background: transparent;
  color: $cool-gray-400;
  box-shadow: none;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: all 0.5s;
  &:hover {
    background: rgba($flame-white, 0.3);
    border-bottom: 1px solid rgba(#1abc9c, 0.5);
  }
  &:focus,
  &:focus:hover,
  &:active,
  &:active:hover {
    background: rgba($flame-white, 0.8);
    border-bottom: 2px solid #1abc9c;
  }
}

.create-options {
  input.simple {
    border: none;
    border-bottom: 1px solid $cool-gray-500;
    height: 30px;
    font-size: 18px;
    background: transparent;
    color: $cool-gray-400;
    box-shadow: none;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: all 0.5s;
    &:hover {
      background: rgba($flame-white, 0.3);
      border-bottom: 1px solid rgba(#1abc9c, 0.5);
    }
    &:focus,
    &:focus:hover,
    &:active,
    &:active:hover {
      background: rgba($flame-white, 0.8);
      border-bottom: 2px solid #1abc9c;
    }
  }
}

.items-header {
  display: flex;
  justify-content: stretch;

  padding: 0;
  color: $cool-gray-400;
  margin: 0;
  font-weight: bold;
  flex-wrap: nowrap;
  align-content: first baseline;
  align-items: first baseline;
  > div {
    font-weight: bold;
    color: $cool-gray-600;
    font-size: 0.8em;
    font-weight: bold;
  }
  .title {
    flex: 10 10 46%;
    font-size: 1.2em;
    color: $cool-gray-800;
  }
  .area {
    flex: 0 0 16.666666%;
    color: $cool-gray-600;
  }
  .qty {
    flex: 0 0 18.666666%;
    color: $cool-gray-600;
  }
  .price {
    flex: 0 0 18.666666%;
    color: $cool-gray-600;
  }
}

.auto-save-timer-container {
  display: flex !important;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  margin: 0 !important;
  .auto-save-timer-text {
    font-weight: bold;
    align-text: center;
    font-size: 2em;
  }
}

.quote-items {
  max-width: 800px;
  width: 100%;
  overflow: visible;
  justify-self: center;
  display: inline-block;
  flex-direction: column;
  max-height: unset;
}
.quote-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  .create-options {
    min-width: 100%;
    max-width: 100%;
  }

  .quote-options {
    text-align: left;
    margin-bottom: 20px;
    min-width: 280px;
    max-width: 100%;
    width: 100%;
    .steps {
      margin-bottom: 2em;
      text-align: center;
    }
  }
}

.bold {
  font-weight: bold;
}
.line {
  width: 100%;
  background-color: grey;
  height: 1px;
}
.space {
  height: 1em;
}

.card-inline {
  margin-right: 0px;
  width: 100%;
}

.quote-children {
  min-height: 50px;
}

.change-order-list {
}

.margin-lock-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;

  padding: 0.5em 2em;

  ul {
    text-align: left;
    margin-top: 1em;
    font-size: 0.8em;
  }

  [btn-component] {
    margin-top: 1em;
  }
}

.live-price-changes-modal-title {
  font-size: 1.5em;
  margin-bottom: 1.2em;
}

.live-price-changes-modal-toggle-text {
  margin-bottom: 1em;
}

.live-price-change-table {
  background-color: white;
  width: 100%;
  overflow-x: scroll;
}

.live-price-changes-header {
  &:first-child {
    width: 30%;
  }
  width: 10%;
  padding: 0.5em;
  text-align: center;
}

.live-price-changes-question {
  @media (max-width: 576px) {
    margin-left: 1em;
  }
}

.live-price-changes-buttons {
  @media (max-width: 576px) {
    margin-right: 1em;
  }
}

#app.small-format {
  .spreadsheet--container {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
}

.project-scheduler {
  min-height: 100vh;
}
</style>
