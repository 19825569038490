<template>
  <div>
    <div class="flex justify-start items-center gap-1 rounded-sm pb-2 bg-white/50">
      <client-details
        class="inline-block"
        :contextual="false"
        :sidebar="false"
        :client="client"
        :truncate="smallFormat ? 16 : 40"
      />
      <div
        v-for="(reviewer, idx) in reviewers"
        class="reviewer flex items-center not-client"
        :key="idx"
        @mouseover="reviewers[idx].hover = true"
        @mouseleave="reviewers[idx].hover = false"
      >
        <client-details
          :contextual="false"
          :sidebar="false"
          :client="reviewer"
          :truncate="smallFormat ? 16 : 40"
        />
        <Btn rounded link class="last:mr-1" @click="removeReviewer(idx)">
          <font-awesome-icon :icon="['far', 'times']" v-tooltip="'Remove'" />
        </Btn>
      </div>
      <Btn rounded link :action="openNewClientModel">
        <font-awesome-icon :icon="['far', 'plus']" />
      </Btn>
    </div>
    <!-- Reviewer Modal -->
    <Modal
      v-bind="$props"
      ref="reviewerModal"
      size="md"
      :scrollable="true"
      :closeable="true"
      :showCloseButton="false"
      :clickAway="true"
    >
      <template #header>
        <div>
          <font-awesome-icon icon="user" />
          Add a read-only reviewer
        </div>
      </template>
      <template #body>
        <client-body ref="body" :getReviewer="true" @add-reviewer="addReviewer" />
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, toRefs, defineEmits } from 'vue'
import { useStore } from 'vuex'
import ClientBody from '../../bodies/ClientNew.vue'
import ClientDetails from '../../ui/ClientDetails.vue'
import { useMediaQuery } from '@/composables/mediaQuery'

const emit = defineEmits(['change'])

const { smallFormat } = useMediaQuery()
const $store = useStore()

const reviewers = ref([])
const reviewerModal = ref()

const props = defineProps({
  quoteId: {
    type: Number,
    required: true
  },
  permissions: {
    type: Array,
    default() {
      return ['read']
    }
  },
  aoReviewers: {
    type: Array,
    default: () => []
  },
  client: {
    type: Object,
    default() {
      return {}
    }
  }
})

const { quoteId, permissions, aoReviewers, client } = toRefs(props)

const openNewClientModel = () => reviewerModal.value.open()

const addReviewer = async (reviewerObject = null, clientPermission = null) => {
  if (!reviewerObject || !reviewerObject.user_email) {
    $store.dispatch('alert', {
      text: 'Could not fetch user.',
      error: true
    })
    return
  }

  // check if user is already reviewing
  let alreadyReviewing = false
  if (reviewerObject.user_email === client.value.user_email) {
    alreadyReviewing = true
  }
  reviewers.value.forEach((rev) => {
    if (alreadyReviewing) return
    if (rev.user_email === reviewerObject.user_email) {
      alreadyReviewing = true
    }
  })
  if (alreadyReviewing) {
    $store.dispatch('alert', {
      text: 'Client is already reviewing this proposal.'
    })
    reviewerModal.value.close()
    return
  }

  const newReviewer = {
    client_id: reviewerObject.client_id,
    type: 'client',
    user_fname: reviewerObject.user_fname,
    user_lname: reviewerObject.user_lname,
    user_phone: reviewerObject.user_phone,
    user_email: reviewerObject.user_email,
    user_suite: reviewerObject.user_suite,
    user_address: reviewerObject.user_address,
    user_city: reviewerObject.user_city,
    user_prov: reviewerObject.user_prov,
    user_postal: reviewerObject.user_postal,
    country_abbr: reviewerObject.country_abbr,
    asPermissions: clientPermission || permissions.value,
    email: reviewerObject.user_email,
    hover: false
  }
  try {
    await reviewerModal.value.close()
    const { payload: reviewer } = await $store.dispatch('ajax', {
      path: `quote/addReviewer/${quoteId.value}`,
      data: {
        reviewer: {
          user_email: newReviewer.user_email,
          asPermissions: newReviewer.asPermissions
        }
      }
    })
    emit('change', reviewers.value)
    if (reviewer && reviewer.reviewer_id) newReviewer.reviewer_id = reviewer.reviewer_id
  } catch (err) {
    await $store.dispatch('alert', {
      text: err.userMessage,
      error: true
    })
  }

  reviewers.value.push(newReviewer)
}

const removeReviewer = async (index) => {
  if (!index && index !== 0) return

  const reviewer = reviewers.value[index]
  if (!reviewer || !reviewer.reviewer_id) return

  reviewers.value.splice(index, 1)

  try {
    await $store.dispatch('ajax', {
      path: `quote/deleteReviewer/${quoteId.value}`,
      data: {
        reviewer_id: String(reviewer.reviewer_id)
      }
    })
    emit('change', reviewers.value)
  } catch (err) {
    await $store.dispatch('alert', {
      text: err.userMessage,
      error: true
    })
  }
}

onMounted(() => {
  aoReviewers.value.forEach((reviewer) => {
    if (reviewer.reviewer_id) {
      reviewers.value.push({
        ...reviewer,
        type: 'client',
        hover: false
      })
    }
  })
})
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-reviewers-container {
  height: auto;
  padding: 0.5em;
  text-align: left;
  margin-bottom: 0 !important;

  .reviewers-list {
    .reviewer {
      --reviewer-width: auto;
      display: inline-block;
      cursor: pointer;
      height: auto;
      margin: 0.2em 0.2em;
      border-radius: 10px;
      background-color: white;

      &.add {
        padding: 0.5em 0.6em;
        &:hover {
          background-color: #1f92fc;
          color: white;
        }
      }

      .delete-x {
        display: inline-block;
        height: 1.3em;
        width: 1.3em;
        background-color: #4a5056;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        padding-top: 0px;
        position: relative;
        top: -1px;
        margin-right: 0.5em;
        &:hover {
          background-color: $deep-red-800;
        }
      }
    }
  }
}
</style>
