export default {
  data() {
    return {
      rate: 10.99,
      term: 15,
      interval: 12
    }
  },
  computed: {
    monthlyPayment() {
      const c = this.interval
      const i = this.rate / 100
      const p = this.amount
      const t = this.term
      return ((i / c) * p) / (1 - (1 + i / c) ** (t * -c))
    }
  },
  methods: {
    async applyForLoan() {
      this.loading = true
      const response = await this.$store.dispatch('ajax', {
        path: `/renofi/financing/${this.$store.state.session.user.user_id || ''}/${this.$store.state.session.quote.quote_id || ''}`
      })

      if (response && response.payload && response.payload.url) {
        window.open(response.payload.url, '_blank')
      } else {
        this.$store.dispatch('alert', { message: 'Something went wrong. Please try again.' })
      }
      this.loading = false
    }
  }
}
