<template>
  <modal
    v-bind="$props"
    ref="modal"
    class="modal-mini"
    :isDirty="bodyIsDirty"
    :full="false"
    :scrollable="true"
    :clickAway="false"
    :expandable="true"
  >
    <template #body>
      <template v-if="$store.state.session.user.user_is_super_user && !$store.getters.inCompany">
        <user-body ref="body" :id="id" @users-found="toggleSaveBar" />
      </template>
      <InviteUsers v-else ref="body" />
    </template>
    <template
      v-if="
        showSaveBar && $store.state.session.user.user_is_super_user && !$store.getters.inCompany
      "
      #footer
    >
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import ObjectModalMixin from '@/components/modals/ObjectModalMixin'
import BillingMixin from '@/components/billing/BillingMixin'
import UserBody from '@/components/bodies/UserNew.vue'
import InviteUsers from '@/components/users/InviteUsers.vue'

export default {
  name: 'ModalUserNew',
  mixins: [ObjectModalMixin, BillingMixin],
  data() {
    return {
      showSaveBar: true
    }
  },
  methods: {
    toggleSaveBar(val) {
      this.showSaveBar = !val
    }
  },
  components: { InviteUsers, UserBody },
  props: {
    type: {
      default: 'user'
    }
  }
}
</script>
