<template>
  <div class="quote-summary--cost-item">
    <div class="item-left">
      <ImageGallery
        class="cost-item-gallery"
        v-if="itemPictures.length && showImages && presentationSettings.showDocumentPictures"
        :thumbs="thumbUrls"
        :show-as-background="false"
        :urls="fileUrls"
      />

      <div class="item-title">
        {{ name || 'Misc item' }}
      </div>
      <div class="item-desc" v-if="desc">
        {{ String(desc || '').trim() }}
      </div>
      <div class="prod-notes" v-if="prodDesc && presentationSettings.showProductionNotes">
        <strong class="text-danger">Production notes</strong>
        <p>
          {{ String(prodDesc || '').trim() }}
        </p>
      </div>
      <div
        class="costItem-attributes"
        v-if="
          showProperties &&
          object.aoProperties &&
          object.aoProperties.length &&
          object.aoProperties[0] &&
          object.aoProperties[0][0] &&
          object.aoProperties[0][0].length
        "
      >
        <ul>
          <li :key="index" v-for="(attribute, index) in object.aoProperties">
            <template
              v-if="Array.isArray(attribute) && typeof attribute[0] === 'string' && attribute[0]"
            >
              <strong>
                {{ attribute[0] }}
              </strong>
              {{
                attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1]
                  ? `: ${attribute[1]}`
                  : ''
              }}
            </template>
          </li>
        </ul>
      </div>
    </div>

    <div class="item-right">
      <div class="cost-item-cost mr-1" v-if="showCost">
        <strong class="text-muted mr-1">Cost</strong>
        <span class="cost-item-cost">
          {{ $f.currency(cost) }}
        </span>
      </div>

      <div class="cost-item-totals">
        <template v-if="!qty || qty <= 0"> not included </template>
        <template v-else>
          <template v-if="showQty">
            <template v-if="object.cost_type_is_fee"> 1 each </template>
            <template v-else> {{ $f.number(qty) }} {{ units }} </template>
          </template>
          <span v-if="showQty" class="mx-2">•</span>
          <template v-if="showPrice">
            {{ $f.currency(price) }}
          </template>
          <template v-else-if="!showPrice"> included </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PresentationCostItemMixin from '../presentation/PresentationCostItemMixin'
import ImageGallery from '../../items/ImageGallery.vue'

export default {
  name: 'QuoteSummaryCostItem',
  components: { ImageGallery },
  mixins: [PresentationCostItemMixin],

  props: {
    object: {
      required: true
    },
    presentationSettings: {
      required: true
    },
    store: {
      default: 'Quote'
    },
    rootRefId: {
      required: true
    },
    showImages: {
      default: true
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    forceTemplateSettings: {
      default: false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: $cool-gray-700;
.quote-summary--cost-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.15em 0;
  margin: 0;

  &:not(:first-child) {
    border-top: 1px solid $bg !important;
  }

  .cost-item-gallery {
    margin: 0.15em 0;
  }

  .item-left {
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .item-title {
    font-size: 1.05em;
    font-weight: 500;
    margin: 0.15em 0;
  }
  .item-desc {
    font-size: 1em;
    font-weight: normal;
    color: $cool-gray-700;
    white-space: pre-line;
  }

  .cost-item-totals {
    border-radius: 1.5em;
    padding: 0.5em 1em;
    background: $cool-gray-200;
    color: $pitch-black;
    font-weight: bold;
    font-size: 0.9em;
    flex: 0 auto;
    white-space: nowrap;
  }

  .item-right {
    margin-left: auto;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0.3em 0.5em 0.3em 0;
  }

  .cost-item-cost {
    margin-right: 2em;
    color: $cool-gray-700;
    font-size: 0.9em;
    .cost-item-cost {
      color: $cool-gray-700;
    }
  }
}

.quote-summary--assembly + .quote-summary--cost-item {
  border-top: none !important;
}
</style>
