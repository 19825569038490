<template>
  <div class="flex flex-col w-full">
    <span class="ml-1">
      {{ country === 'us' ? 'Social Security Number' : 'Social Insurance Number' }}
    </span>
    <field
      :validate="validationObj"
      :format="format"
      :errors="errors"
      :name="name"
      @blur="onBlur"
      :placeholder="country === 'ca' ? '123-456-789' : '123-45-6789'"
      v-model="formatted"
      class="w-full !bg-flame-white border-surface-200"
    />
    <p class="text-xs mt-0.5 ml-1 text-surface-600/75">
      Required for personal verification - we never store sensitive information.
    </p>
  </div>
</template>

<script>
export default {
  name: 'SocialSecurityField',
  props: {
    value: {
      type: String,
      required: true
    },
    country: {
      type: String,
      default: 'ca'
    },
    errors: {
      type: Array,
      default: () => []
    },
    name: {
      type: String
    }
  },
  emits: ['input', 'change'],
  computed: {
    formatted: {
      get() {
        return this.localValue
      },
      set(newValue) {
        this.localValue = this.format(newValue)

        const match = this.validate(this.localValue)

        if (match && match.length > 0) {
          this.error = null
          this.$emit('input', this.localValue)
          this.$emit('change', this.localValue)
        }
      }
    },
    validationFormat() {
      return this.country === 'ca'
        ? /^(?!666|000|9\\d{3})\d{3}-(?!00)\d{3}-(?!0{3})\d{3}$/
        : /^(?!666|000|9\\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
    },
    validationObj() {
      return {
        format: this.validationFormat,
        required: true
      }
    }
  },
  data() {
    return {
      localValue: null,
      error: null
    }
  },
  methods: {
    /**
     * Format the value
     * @param {String} value
     */
    format(value) {
      let val = value.replace(/\D/g, '')

      if (this.country === 'ca') {
        val = val.replace(/^(\d{3})/, '$1-')
        val = val.replace(/-(\d{3})/, '-$1-')
        return val
      }

      val = val.replace(/^(\d{3})/, '$1-')
      val = val.replace(/-(\d{2})/, '-$1-')
      val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')
      return val
    },
    /**
     * On blur handle some validation
     */
    onBlur() {
      const match = this.validate(this.localValue)
      if (match && match.length > 0) {
        return
      }
      // display custom error messages
      if (this.localValue.length < 11) {
        this.error = 'We expected a response at least 9 characters long.'
      } else {
        this.error = 'The format of your response is not what we expected to see.'
      }
      this.$store.dispatch('alert', {
        message: this.error,
        error: true
      })
    },
    /**
     * Make sure value matches the right format
     * @param {String} value
     */
    validate(value) {
      return this.validationFormat.exec(value)
    }
  },
  created() {
    this.localValue = this.value
  },
  components: {}
}
</script>
