/**
 * This file should contain all functions that bridge our API
 * with Vue.js/Vuex.  These are functions or utilities that
 * can be used everywhere/anywhere within the app that create
 * efficiencies for Vuejs.
 */
// import * as types from '../../client/store/mutation-types';
import Pako from 'pako'
import { find as linkifyFind } from 'linkifyjs'
import linkifyHtml from 'linkify-html'
import _ from '../imports/api'
import rpc from '../imports/api/RPCWrapper'
import Cache from '../imports/api/Cache'
import Objects from '../imports/api/Objects'
import Normalize from '../imports/api/Normalize'
import FieldDetection from '../imports/api/FieldDetection'
import { Filesystem } from '@capacitor/filesystem'
import { useDeviceStore } from '@/stores/device'
import DOMPurify from 'dompurify'
// import { getLinkPreview } from 'link-preview-js';

const { getConstructor, getFieldTitle, shouldMapField } = Objects

const addLoadingAll = (buttons = []) => {
  _.makeArray(buttons).forEach((b) =>
    setTimeout(() => typeof b.addLoading !== 'function' || b.addLoading(), 0)
  )
  return this
}
/**
 * Truncate string using js
 * @param str
 * @param num
 * @returns {*|string}
 */
const truncateString = (str, num) => {
  if (str && str.length > num) {
    return `${str.slice(0, num)}...`
  }
  return str
}

const removeLoadingAll = (buttons = []) => {
  _.makeArray(buttons).forEach((b) =>
    setTimeout(() => typeof b.removeLoading !== 'function' || b.removeLoading(), 0)
  )
  return this
}

const endAllLoading = (buttons = []) => {
  _.makeArray(buttons).forEach((b) =>
    setTimeout(() => typeof b.endLoading !== 'function' || b.endLoading(), 0)
  )
  return this
}

const endLoadingAll = (buttons = []) => {
  _.makeArray(buttons).forEach((b) =>
    setTimeout(() => typeof b.endLoading !== 'function' || b.endLoading(), 0)
  )
  return this
}

const setProgressAll = (buttons = [], amount = 1) => {
  _.makeArray(buttons).forEach((b) => typeof b.setProgress !== 'function' || b.setProgress(amount))
  return this
}

const encodeGridQuery = (path, filters = {}, filterText = {}, searchPhrase = '', order = []) => {
  const t = _.mangle(path)
  const o = _.mangle(JSON.stringify(order))
  const f = _.mangle(JSON.stringify(filters))
  const d = _.mangle(JSON.stringify(filterText))
  const s = _.mangle(searchPhrase)
  return { t, o, f, d, s }
}

const decodeGridQuery = (queryState) => {
  const {
    t = null, // path
    o = false, // order
    f = false, // filters
    d = false, // display text for filters
    s = false // search phrase
  } = queryState

  const queryObject = {}
  if (t) queryObject.path = _.unmangle(t)
  if (o) queryObject.order = [...JSON.parse(_.unmangle(o))]
  if (s) queryObject.searchPhrase = _.unmangle(s)
  if (d) queryObject.filterText = { ...JSON.parse(_.unmangle(d)) }
  if (f) queryObject.filters = { ...JSON.parse(_.unmangle(f)) }

  return queryObject
}

/**
 *
 * @param schema
 * @param embue
 * @returns {*}
 */
const getDefault = (schema, embue) => {
  if (
    schema.type === 'object' &&
    !schema.mapTo &&
    (typeof schema.default === 'object' || typeof schema.default === 'function')
  ) {
    const def = typeof schema.default === 'function' ? schema.default(_.imm(embue)) : schema.default
    return _.merge(_.imm(def), _.imm(embue))
  }
  return embue
}

const getDefaultSelectListFilters = (type) => ({
  [`${type}_status`]: '!i&&!d&&!c&&!l&&!e&&!h&&!y'
})

/**
 * Populate all the items in a column that a Grid component will need
 * @param obj
 */
const buildDefaultColumn = (obj) => ({
  ...obj,
  column: obj.column || obj.sortBy,
  sortBy: obj.sortBy,
  name: obj.title || obj.name || obj.sortBy,
  class: obj.class || '',
  cellClass: obj.cellClass || '',
  style: obj.style || '',
  // Will show more prominently as a filter option
  // suggestedFilter: fields[field].suggestedFilter || false,
  // Start out visible
  visible: obj.visible,
  format: obj.format || ((passthru) => passthru),
  // Component should have :object prop, to receive the data
  // from the grid, like any objectManipulator
  component: obj.component || false,
  componentProps: obj.componentProps || {},
  componentStyle: obj.componentStyle || {},
  formatType: obj.formatType || null,
  selectOptionFormat: (object) => ({
    text: object[`${object.type}_name`],
    value: String(object[`${object.type}_id`])
  })
})

/**
 * Build individual column definition for a table
 * @param field
 * @param fields
 * @returns {
 *  {
 *    class: string,
 *    style: string,
 *    column: *,
 *    sortBy: *,
 *    name,
 *    suggestedFilter: boolean,
 *    visible: boolean,
 *    format: *,
 *    component: boolean,
 *    formatType: null,
 *    selectOptionFormat: (function(*): {text: *, value: string})
 *   }
 * }
 */
const getTableColumn = (field, typeOrConstructor, permFilters = {}) => {
  const constructor =
    typeof typeOrConstructor === 'object' ? typeOrConstructor : getConstructor(typeOrConstructor)

  const type = constructor.type

  if (!constructor) return []

  const fields = constructor.fields

  let fieldFactory
  if (field === `${type}_name`) {
    fieldFactory = (obj) => `<div style="margin: 0.5em 0;">${_.removeHtml(obj[field])}</div>`
  } else if (fields[field] && fields[field].format) {
    fieldFactory = (obj) => {
      const s = _.format(obj[field], fields[field].format)
      return s && s !== 'null' ? s : ''
    }
  } else if (/_phone|_net|_gross|_tax|_hours|_time/.test(field)) {
    const format =
      (/_phone/.test(field) && 'phone') ||
      (/_net|_gross|_tax/.test(field) && 'number') ||
      (/_hours/.test(field) && 'hours') ||
      (/_time/.test(field) && 'timerelative')
    fieldFactory = (obj) => {
      const s = _.format(obj[field], format)
      return s && s !== 'null' ? s : '<strong class="text-muted">-</strong>'
    }
  } else if (fields[field] && fields[field].gridDisplay) {
    fieldFactory = (obj) => fields[field].gridDisplay(obj[field])
  } else {
    fieldFactory = (obj) =>
      obj[field] && obj[field] !== 'null' ? obj[field] : '<strong class="text-muted">-</strong>'
  }

  let vis = false
  if (fields[field] && fields[field].visible) {
    if (typeof fields[field].visible === 'function') {
      vis = fields[field].visible(permFilters)
    } else {
      vis = fields[field].visible
    }
  }

  let colClass = ''
  if (field.includes('time')) colClass = 'text-sm text-nowrap'
  if (field.includes('desc')) colClass = 'text-sm line-clamp-3 text-balance'
  if (field.includes('name')) colClass = 'text-sm truncate'

  return buildDefaultColumn({
    ...fields[field],
    class: `${fields[field] && fields[field].class ? fields[field].class : ''} ${colClass}`,
    style: fields[field] && fields[field].style ? fields[field].style : '',
    column: field,
    sortBy: field,
    width:
      (fields[field] && fields[field].width) || (/_name|_desc|_time/.test(field) ? '10em' : '8em'),
    name: getFieldTitle(field, type),
    // Will show more prominently as a filter option
    // suggestedFilter: fields[field].suggestedFilter || false,
    // Start out visible
    visible: vis,
    format: fieldFactory,
    component: field in fields && 'component' in fields[field] ? fields[field].component : false,
    formatType:
      field in fields && 'format' in fields[field] && fields[field].format
        ? fields[field].format
        : null
  })
}

/**
 * Ads component info for _id and _creator and _owner tags,
 * so they appear as nice clickable tags in the grid
 * @param columns
 */
const convertIdColumnsToTags = (columns, fields) =>
  columns.map((col) => {
    if (col.column in fields && fields[col.column].component === false) return col
    if (/(_id|_creator|_owner)$/.test(col.column)) {
      const pseudoType = /(_creator|_owner)$/.test(col.column)
        ? col.column.replace(/(.*?)(creator|owner)$/, '$2')
        : col.column.replace('_id', '')
      const type = /creator|owner|contact/.test(pseudoType) ? 'user' : pseudoType
      return {
        ...col,
        component: 'GridSubItemTag',
        componentProps: {
          type,
          pseudoType
        }
      }
    }
    return col
  })

/**
 * Get a list of columns, not associated with a particular object type
 * @param type
 * @returns {[null]}
 */
const getAdhocGridColumns = (typeOrConstructor, columns = []) => {
  const constructor =
    typeof typeOrConstructor === 'object' ? typeOrConstructor : getConstructor(typeOrConstructor)
  const type = constructor.type
  if (!constructor) return columns

  const previewTemplate = columns.find((o) => o.sortBy === `${type}_id` && o.component) || {}

  let newColumns = convertIdColumnsToTags(columns, constructor.fields)

  if (previewTemplate.component) {
    newColumns = [
      // Preview column
      buildDefaultColumn({
        ...previewTemplate,
        ...getTableColumn(`${type}_id`, constructor),
        column: `${type}_preview`,
        width: '40em',
        sortBy: `${type}_name` in constructor.fields ? `${type}_name` : `${type}_id`,
        name: _.capitalize(type.replace('_', ' ')),
        component: previewTemplate.component,
        componentProps: {
          middle: true,
          right: false,
          steps: false
        },
        visible: !!previewTemplate.component
      }),

      ...newColumns,

      // NextSteps column
      buildDefaultColumn({
        column: 'nextSteps',
        width: '20em',
        sortBy: `${type}_status`,
        name: 'Next Step',
        visible: true,
        component: 'NextSteps',
        cellClass: 'text-right px-4 right',
        class: 'text-right',
        componentStyle: {
          maxWidth: '800px',
          fontSize: '0.8em',
          marginLeft: 'auto'
        },
        componentProps: {
          inline: false
        }
      })
    ]
  }

  return newColumns
}

const shouldIncludeFieldAsColumn = (fieldName, fieldSchema) => {
  // if set always go with what is explicit
  if ('filter' in fieldSchema) return !!fieldSchema.filter

  if (
    /(^(tax_id|export_|parent_).*?)|(.*?(mod_id|file_id|qbexport_id|country_id|company_id|docusign_envelope_id|_ids|template_id)$)/.test(
      fieldName
    )
  )
    return false

  // All id fields by default
  if (/_id$/.test(fieldName)) return true

  // All description fields by default
  if (/_desc$/.test(fieldName)) return true

  // Anything to do with a number
  if (/_(net|gross)$/.test(fieldName)) return true

  if (/_(count|sum|percent)/.test(fieldName)) return true

  if (/_(owner|creator)/.test(fieldName)) return true

  // time fields
  if (/_time/.test(fieldName)) return true

  // status fields
  if (/_status/.test(fieldName)) return true

  // bool fields
  if (/_is_|_has_/.test(fieldName)) return true

  // Everything else false
  return false
}

/**
 * Build a set of table columns from the fields definitions.
 * @param type
 * @returns [] Array
 */
const getTableColumns = (type, permFilters = {}) => {
  const constructor = getConstructor(type)
  if (!constructor) return []

  let columns = (constructor.tableColumns || []).map((col) => ({
    ...getTableColumn(col.column, constructor, permFilters),
    ...col
  }))

  if (!columns.length) {
    const columnsToInclude = Object.keys(constructor.fields).filter((field) =>
      shouldIncludeFieldAsColumn(field, constructor.fields[field], type)
    )

    columns = columnsToInclude.map((field) => getTableColumn(field, constructor))
  }

  // get preview and nextsteps columns from adhoc:
  return getAdhocGridColumns(constructor, columns)
}

/**
 * Format all data in JS in a format required in PHP.  Primarily required for
 * time fields in converting from milliseconds to seconds.
 * @param Object oObj
 * @returns Object oObj
 */
const shouldRecurse = (val) =>
  val &&
  ((typeof val === 'object' && Object.keys(val).length) || (Array.isArray(val) && val.length))

const shouldSaveField = (field, constructor) =>
  !constructor ||
  !constructor.fields ||
  !constructor.fields[field] ||
  typeof constructor.fields[field].save === 'undefined' ||
  constructor.fields[field].save === null ||
  !!constructor.fields[field].save

function formatNumbersAsStrings(obj) {
  if (Array.isArray(obj)) {
    return obj.map(formatNumbersAsStrings)
  } else if (typeof obj === 'object' && obj !== null) {
    const formattedObj = {}
    for (const [key, value] of Object.entries(obj)) {
      formattedObj[key] = formatNumbersAsStrings(value)
    }
    return formattedObj
  } else if (typeof obj === 'number') {
    return obj.toFixed(2)
  } else {
    return obj
  }
}

const formatForPHP = (data = {}, full = true, level = 0) => {
  const cloned = _.cloneDeep(data)

  if (Array.isArray(cloned)) {
    return cloned.map((val) => (shouldRecurse(val) ? formatForPHP(val) : val))
  }

  // if (!cloned.type) {
  //   return _.zipObject(Object.keys(cloned), formatForPHP(Object.values(cloned)));
  // }

  let constructor = null
  if (cloned.type) {
    constructor = getConstructor(cloned.type)
  }
  const newData = cloned || {}

  if (newData && typeof newData === 'object' && Object.keys(newData).length) {
    Object.keys(newData).forEach((key) => {
      let val = data[key]
      // Recurse
      if (
        shouldRecurse(val) &&
        (!constructor || shouldSaveField(key, constructor)) &&
        val !== data
      ) {
        val = formatForPHP(val, full, level + 1)
      } else if (key.match(/_time/)) {
        // Convert Millisecond times to Second times
        val = _.timeToSec(val)
      }
      newData[key] = val
    })
  }

  return newData
}

/**
 * Format all the data for an object into data requried for JS based on expected
 * values received from PHP.
 * @param Object oObj object to be converted
 * @returns Object oObj converted object
 */
const formatForJS = (data = {}) => {
  if (_.isScalar(data)) {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((o) => formatForJS(o))
  }

  const newData = data || {}

  if (typeof data === 'object' && data) {
    const type = 'type' in data ? data.type : false
    const constructor = type ? getConstructor(type) : false
    const fields = constructor ? constructor.fields : {}

    Object.keys(newData).forEach((key) => {
      let val = data[key]

      // Fields are set to "[]" or "{}" when sending empty arrays or object
      // to server because otherwise browsers will omit empty arrays and objects
      // entirely and nothing will reach the server.  So, sometimes those values get
      // inputted in a LongText JSON field in the server as a string "[]" instead of []
      // so we need to check for that and reverse it back into an array here.
      if (
        typeof fields[key] !== 'undefined' &&
        typeof fields[key].type !== 'undefined' &&
        /(array|object)/.test(fields[key].type) &&
        (/^((\["\[\]"\])|(\{"\{\}"\})|(\[\])|(\{\}))$/.test(JSON.stringify(val)) ||
          /^(\[\]|\{\})$/.test(String(val)))
      ) {
        if (fields[key].type === 'object') val = {}
        else val = []
      }

      // Recurse
      if (
        val &&
        (Array.isArray(val) || typeof val === 'object') &&
        !/oEquations/.test(key) &&
        val !== data &&
        (!constructor ||
          !constructor.fields ||
          !constructor.fields[key] ||
          typeof constructor.fields[key].formatForJS === 'undefined' ||
          constructor.fields[key].formatForJS === null ||
          !!constructor.fields[key].formatForJS)
      ) {
        val = Array.isArray(val) ? val.map((item) => formatForJS(item)) : formatForJS(val)
      } else if (key.match(/_time(_|$)/)) {
        // Convert Second times to Millisecond times
        val = _.timeToMs(val)
      } else if (
        val !== null &&
        (_.isNumericField(key) ||
          (typeof val === 'string' && /^-?\d+(,\d{3})*(\.\d+)$/.test(val))) &&
        typeof val !== 'number' &&
        (typeof fields[key] === 'undefined' ||
          typeof fields[key].format === 'undefined' ||
          fields[key].format !== false) &&
        (typeof fields[key] === 'undefined' ||
          typeof fields[key].type === 'undefined' ||
          fields[key].type === 'int' ||
          fields[key].type === 'float' ||
          fields[key].type === 'number')
      ) {
        val = _.toNum(val, 4)
      } else if (key.match(/_desc|_html|_note|_text/) && typeof val === 'string') {
        // Remove 2 more more backslashes, which is a php error
        //   that ocurrs, and replace with only one \\
        val = val.replace(/\\{2,}/g, '\\')
      }

      newData[key] = val
    })
  }

  return newData
}

/**
 * Basic ajax calling wrapper that returns a promise.
 *
 * Example:
 * Base.ajax('/path/', { postData: true }, button)
 *  .then(function({ message, object, warnings, button, code, sessionId }){
 *    alert('done')!
 *  });
 *
 * @param {String} path
 * @param {Object} data
 * @param {Array|Object} button
 * @param {Array} authorization
 *    format: [[superUserEmail], userEmail, password]
 *    superUserEmail optional
 * @returns {Promise}
 */
let queue = []
let callsOut = 0
const maxCalls = 3

const ajax = (
  path,
  data,
  authorization = [],
  host = import.meta.env.VITE_BASE_API_URL,
  processData = true
) => {
  const url = `${host.replace(/\/$/, '')}/${path.replace(/^\//, '')}`
  const processedData = formatForPHP(data)

  let headers = {}
  const authToken = _.getStorage('token') || null
  // username password authentication
  if (authorization.length === 2) {
    headers = {
      Authorization: `Basic ${window.btoa(authorization.join(':'))}`
    }
  } else if (authorization.length === 3) {
    headers = {
      Authorization: `Super ${window.btoa(authorization.join(':'))}`
    }
  } else if (authToken) {
    headers = {
      Authorization: `Bearer ${authToken}`
    }
  }

  const shouldCompress = import.meta.env.VITE_ENCODE === 'true'
  const uncompressed = JSON.stringify(processedData)

  const compressed = shouldCompress ? Pako.deflate(uncompressed, { to: 'string' }) : uncompressed
  const contentType = shouldCompress ? 'text/plain; UTF-8;' : 'application/json; UTF-8;'
  if (shouldCompress) {
    headers['CC-String-Encoding'] = 'CC Compression'
  }

  return new Promise((resolve, reject) => {
    const fn = () => {
      callsOut += 1
      $.ajax({
        url,
        headers,
        data: compressed,
        contentType,
        type: 'POST',
        cache: false,
        xhr: () => {
          const xhr = new window.XMLHttpRequest()
          return xhr
        }
      })
        .always(() => {
          callsOut -= 1
          if (queue.length) queue.shift()()
        })
        .done((...args) => {
          // Decode and decompress
          const json = _.getResponseFromAjaxArgs(args)

          const { code, error, payload, scope, token, warnings } = json

          if (isNaN(+code)) {
            reject({
              message:
                'An error has occured, please try again.  If that fails, clear your cookies, restart and try again.',
              userMessage:
                'An error has occured, please try again.  If that fails, clear your cookies, restart and try again.',
              object: {},
              set: [],
              scope,
              payload,
              warnings: [],
              code: 0,
              token
            })
          } else if (error) {
            reject({
              message:
                error.message ||
                'An error occurred. Please try again. If the problem persists please restart and try again.',
              userMessage:
                error.userMessage ||
                'An error occurred. Please try again. If the problem persists please restart and try again.',
              object: {},
              set: [],
              scope,
              payload,
              warnings,
              code,
              token
            })

            if (code < 0) {
              // If not logged in, immediately hide screen from view
              window.dispatch('authFailed')
            }
          } else {
            const formattedObject = processData ? formatForJS(payload) : payload
            resolve({
              message: typeof payload === 'string' ? payload : payload.message,
              userMessage: typeof payload === 'string' ? payload : payload.userMessage,
              object: formattedObject,
              set: _.makeArray(formattedObject),
              scope,
              payload: formattedObject,
              warnings,
              code,
              token
            })
            if (!parseInt(_.getStorage('remainLoggedOut'), 10)) {
              // If scope or token is differnet, fetch new base values
              if (
                !_.jsonEquals(token, _.getStorage('token')) ||
                !_.jsonEquals(scope, _.getStorage('scope'))
              ) {
                // _.throttle(() => {
                // window.dispatch('getBaseValues');
                // }, 5000);
              }

              if (processData) _.setStorage('token', token)
            }
          }
        })
        .fail((...args) => {
          // Decode and decompress
          const json = _.getResponseFromAjaxArgs(args)
          // error handling
          // if (import.meta.env.DEV) {
          // }

          if (json.error) {
            const { code = 0, error } = json

            reject({
              message:
                error.message ||
                'An error occurred. Please try again. If the problem persists please restart and try again.',
              userMessage:
                error.userMessage ||
                'An error occurred. Please try again. If the problem persists please restart and try again.',
              object: {},
              set: [],
              payload: {},
              warnings: [],
              code,
              token: ''
            })

            if (code < 0) {
              // If not logged in, immediately hide screen from view
              window.dispatch('authFailed')
            }
          } else {
            reject({
              message: 'There was a server error.. Could not connect.',
              object: {},
              warnings: [],
              code: 0
            })
          }
        })
    }

    if (callsOut > maxCalls) {
      // Add call to queue
      queue = [...queue, fn]
    } else {
      // Call now
      fn()
    }
  })
}

/**
 * Download URL using Capacitor plugin
 * @param url
 * @param fileName
 */
const downloadFromURL = (url, fileName) => {
  const deviceStore = useDeviceStore()

  return new Promise((resolve) => {
    if (deviceStore.isNative) {
      Filesystem.downloadFile({
        url,
        path: fileName,
        directory: 'DATA'
      })
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.target = '_blank'
      a.download = fileName
      a.href = url
      a.click()
    }
    resolve()
  })
}

const hasLink = (value) => linkifyFind(value).length > 0
const linkify = (value) =>
  hasLink(value)
    ? linkifyHtml(value, {
        target: {
          url: '_blank'
        },
        format: (val) => `${val.length > 30 ? `${val.slice(0, 30)}...` : val} ↗`
      })
    : value.trim()
const embedYouTubeLink = (url) => {
  const regExp = /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=)?(\S*)?/

  return url.replace(regExp, (match, p1, p2, p3, p4, p5) => {
    let id = ''
    if (p3.indexOf('youtu.be') > -1) {
      id = match.split('/')[match.split('/').length - 1]
    } else {
      id = p5
    }
    return `
      <div class="video-container">
        <iframe src="https://www.youtube-nocookie.com/embed/${id}"
                class="video-embed" 
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
      </div>
    `
  })
}

const getVideoEmbedUrl = (url) => {
  const youtubeRegex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/
  const vimeoRegex = /vimeo\.com\/(\d+)/

  if (youtubeRegex.test(url)) {
    const videoId = url.match(youtubeRegex)[1]
    return `https://www.youtube.com/embed/${videoId}`
  }

  if (vimeoRegex.test(url)) {
    const videoId = url.match(vimeoRegex)[1]
    return `https://player.vimeo.com/video/${videoId}`
  }
  return url
}

const processOembedTags = (html) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // Find all <oembed> tags
  const oembedElements = tempDiv.querySelectorAll('oembed')

  oembedElements.forEach((oembed) => {
    const url = oembed.getAttribute('url')
    const embedUrl = getVideoEmbedUrl(url)
    // Convert to an iframe
    const iframe = document.createElement('iframe')
    iframe.setAttribute('width', '560')
    iframe.setAttribute('height', '315')
    iframe.setAttribute('src', embedUrl)
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    )
    iframe.setAttribute('allowfullscreen', '')
    iframe.style.margin = 'auto'

    // Replace <oembed> with <iframe>
    oembed.replaceWith(iframe)
  })

  // Return the processed HTML as a string
  return tempDiv.innerHTML
}

const escapeHtml = (unsafe) =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')

const sanitize = (html = '', tags = [], attrs = []) => {
  return DOMPurify.sanitize(html, {
    ADD_TAGS: tags,
    ADD_ATTR: attrs
  })
}

const getCssColor = (name) => {
  const rootElement = document.documentElement
  const computedStyle = getComputedStyle(rootElement)
  const primaryColor = computedStyle.getPropertyValue(`--${name}`).trim()
  const [r, g, b] = primaryColor.split(' ')
  return `rgb(${parseInt(r)}, ${parseInt(g)}, ${parseInt(b)})`
}
export default {
  // References
  ...Normalize,
  ..._,
  ...Cache,
  ...FieldDetection,
  rpc,

  // Correct overlaps
  mapNormalized: Normalize.map,

  // Api <-> Vuejs utilities
  getDefaultSelectListFilters,

  // Component utilities/helpers
  addLoadingAll,
  removeLoadingAll,
  endAllLoading,
  endLoadingAll,
  setProgressAll,
  encodeGridQuery,
  decodeGridQuery,
  buildDefaultColumn,
  getTableColumn,
  getTableColumns,
  shouldSaveField,
  shouldMapField,
  truncateString,

  // Networking
  ajax,
  downloadFromURL,

  // Other
  formatForPHP,
  formatForJS,

  // link parsing
  linkify,
  // getLinkPreviewData,
  // getLinkPreview,
  hasLink,
  embedYouTubeLink,
  processOembedTags,
  getVideoEmbedUrl,
  escapeHtml,
  sanitize,
  getDefault,
  formatNumbersAsStrings,
  getCssColor
}
