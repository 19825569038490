import CounterpartyProfileMixin from '../CounterpartyProfileMixin'

/**
 * Handles FE counterparty onboarding validation and mapping session data
 */
export default {
  emits: ['addOwner'],
  data() {
    return {
      warning: null,
      errors: [],
      profile: {},
      individuals: [],
      saveBeforeNext: true
    }
  },
  mixins: [CounterpartyProfileMixin],
  methods: {
    /**
     * Get the meta data value from an oMeta
     * @param {string} field
     * @param {string} subField
     * @returns {*}
     */
    getMetaValue(field, subField) {
      if (!field || !this.meta || Object.keys(this.meta).length === 0) return null
      if (this.meta[field] && subField) return this.meta[field][subField]
      return this.meta[field]
    },
    /**
     * Grab the stored session data for the user and company.
     * Map the data to the counterparty object. We do this so we can more easily add more fields.
     * As well we use the mapping for validation and displaying errors.
     * @return {void}
     */
    mapSessionDataToCounterparty() {
      Object.keys(this.fieldMapping).forEach((sf) => {
        // get the session field
        const ccf = this.fieldMapping[sf]
        const ccfParts = ccf.split('.')
        // either user or company
        const entity = ccfParts[0]
        // bolster fields
        const field = ccfParts[1]
        const subField = ccfParts[2]
        // get the session value
        let val = null
        if (subField) {
          val =
            this.$store.state.session[entity][field] &&
            this.$store.state.session[entity][field][subField]
        } else {
          val = this.$store.state.session[entity][field] || ''
        }

        // get the stripe fields
        const sfParts = sf.split('.')
        // stripe entity owner or company
        const gatewayEntity = sfParts[0]
        // additional stripe fields
        const gatewayField = sfParts[1]
        const gatewaySubField = sfParts[2]
        // set the stripe fields (counterparty) with the session data
        const metaVal = this.getMetaValue(field, subField)
        if (gatewaySubField) {
          const value =
            val !== undefined && val !== null
              ? val
              : this.counterparty[gatewayEntity][gatewayField][gatewaySubField]
          this.counterparty[gatewayEntity][gatewayField][gatewaySubField] = metaVal || value
        } else {
          const value =
            val !== undefined && val !== null ? val : this.counterparty[gatewayEntity][gatewayField]
          this.counterparty[gatewayEntity][gatewayField] = metaVal || value
        }
      })
    },
    /**
     * Preform the validation check
     * @param {string} k
     * @returns {boolean}
     */
    validate(k) {
      const keys = k.split('.')
      const depth = keys.length
      let val
      if (depth === 4) {
        val = this[keys[0]][keys[1]][keys[2]][keys[3]]
      } else if (depth === 3) {
        val = this[keys[0]][keys[1]][keys[2]]
      } else if (depth === 2) {
        val = this[keys[0]][keys[1]]
      } else {
        val = this[keys[0]]
      }
      if (val === '' || val === undefined || val === null || val === false) return false
      if (Array.isArray(val) && val.length === 0) return false
      return true
    },
    async saveOwner() {
      await this.beforeNext()
      this.$emit('addOwner', this.counterparty.owner)
    },

    /**
     * Validate each step based on validation schema
     * @returns {boolean}
     */
    async beforeNext(step) {
      const schema = this.valdationSchemas[step]
      if (!schema) return true
      this.errors = []
      schema.forEach((k) => {
        const or = k.split('||')
        if (or.length > 1) {
          const valid = this.validate(or[0])
          const valid2 = this.validate(or[1])
          if (!valid && !valid2) {
            this.errors.push(or[0])
          }
        } else {
          const valid = this.validate(k)
          if (!valid) this.errors.push(k)
        }
      })

      const validated = this.errors.length === 0
      if (!validated) {
        const allErrors = []
        this.errors.forEach((message) => {
          const e = message.replaceAll('.', ' ').replaceAll('_', ' ')
          allErrors.push(e)
        })
        this.$store.dispatch('alert', {
          message: `Please fill out the following fields: ${allErrors.toString().replaceAll(',', ', ')}`,
          error: true
        })

        return false
      }
      // save changes after each step
      if (this.saveBeforeNext) await this.saveAllChanges(this.profile, this.counterparty)
      return validated
    },
    /**
     * Set warning
     * @param {string|null} warning
     */
    setWarning(warning = null) {
      this.warning = warning
    }
  }
}
