<template>
  <div class="activity-actions">
    <a
      class="like"
      @click.capture.stop.prevent="likeClick"
      :class="{ 'liked-by-self': activity.activity_liked, liked: activity.activity_count_likes }"
    >
      <font-awesome-icon icon="thumbs-up" />
      Like

      <span class="badge info ml-1" v-if="activity.activity_count_likes">{{
        activity.activity_count_likes
      }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: '',
  emits: ['likeClick', 'commentClick'],
  data() {
    return {}
  },
  methods: {
    likeClick() {
      this.$emit('likeClick')
    },
    commentClick() {
      this.$emit('commentClick')
    }
  },
  props: {
    activity: {
      required: true
    }
  },
  components: {}
}
</script>

<style rel="stylesheet/scss" lang="scss">
.activity-actions {
  overflow: visible !important;
  font-size: 0.9em;
  margin-top: 0.5em;

  .badge {
    line-height: 0.9 !important;
  }
  .like {
    font-weight: bold;
    display: inline-flex;
    height: 2em;
    justify-content: flex-start;
    align-items: center;
    .fa {
      top: 0;
      margin-right: 0.25em;
    }
    overflow: visible;
    a {
      color: $cool-gray-600 !important;
      font-weight: bold;
    }
    &.liked-by-self {
      a {
        color: #1abc9c !important;
      }
    }
    &.liked {
    }
  }
  .comment {
    overflow: visible;
    a {
      color: $cool-gray-600 !important;
      font-weight: bold;
    }
  }
}
</style>
