<template>
  <div>
    <CardSection v-if="!assemblyEditable">
      <Warning>
        <template #icon>
          <font-awesome-icon icon="arrows-rotate" />
        </template>
        <template #title>Synchronized assembly</template>

        <p>
          This assembly stays synchronized to the saved version. That means that a fresh saved
          version of {{ object.assembly_name }} is loaded into
          {{ $store.state[store].normalized[rootRefId].assembly_name }} everytime it is added to an
          estimate.
        </p>

        <div class="flex">
          <SuggestionButton class="info m-1" @click="editSeparately()">
            <font-awesome-icon icon="rectangle-history-circle-plus" />
            Edit {{ object.assembly_name }} externally and keep it sync'ed
          </SuggestionButton>
          <SuggestionButton class="info m-1" @click="unsyncAssembly()">
            <font-awesome-icon icon="rotate-exclamation" />
            Edit here as-is but unsync from {{ object.assembly_name }}
          </SuggestionButton>
        </div>
      </Warning>
    </CardSection>

    <CardSection v-if="suggestLinking.length">
      <Card class="sm">
        <template #icon>
          <font-awesome-icon icon="drafting-compass" />
        </template>
        <template #title>
          <h5>
            Is the
            <span class="text-info">{{
              String(suggestLinking[0].suggestedDimensions[0].dimension_name || '').toLowerCase()
            }}</span>
            of {{ object.assembly_name }}
            <strong class="text-info"
              >{{ suggestLinking[0].cost_item_qty_net_base }}
              {{ suggestLinking[0].unit_of_measure_id }}</strong
            >?
          </h5>
        </template>
        <div class="mt-2">
          <Btn class="xl info mr-2 round" :action="() => acceptSuggestion(suggestLinking[0])"
            >Yes</Btn
          >
          <Btn class="xl default round" :action="() => declineSuggestion(suggestLinking[0])"
            >No</Btn
          >
        </div>
      </Card>
    </CardSection>

    <CardSection v-if="assemblyEditable">
      <template #title>
        <font-awesome-icon icon="cog" class="mr-1" />
        Bulk changes</template
      >
      <Container class="flex-col flex-md-row pb-0">
        <Btn :action="confirmDimensions" class="sm round mr-1 mb-1">Confirm dimensions</Btn>

        <Btn @click="changeAssemblyLaborRates" class="sm round mr-1 mb-1"
          >Change assembly labor rates</Btn
        >

        <Btn @click="openOptionsModal" class="sm round mr-1 mb-1">Change assembly price</Btn>

        <Btn @click="openOptionsModal" class="sm round mr-1 mb-1">Change options and upgrades</Btn>
      </Container>
    </CardSection>

    <card-section v-if="object.asDimensionsUsed.length">
      <template #title>
        <font-awesome-icon icon="drafting-compass" class="mr-1" />
        {{ $f.truncate(object.assembly_name || '', 30) }} dimensions
      </template>
      <assembly-dimensions
        :showDerivedDimensions="true"
        v-model="dimensions"
        :object="object"
        :reference="reference"
        :store="store"
      />
    </card-section>

    <card-section v-if="assemblyEditable">
      <template #title>
        <div class="mb-2">
          <font-awesome-icon icon="bars" class="mr-1" />
          {{ $f.truncate(object.assembly_name || '', 30) }} items
        </div>
      </template>
      <line-item-list
        :style="{ opacity: !assemblyEditable ? 0.7 : 1 }"
        :inAssembly="inAssembly"
        :loading="loading"
        :editable="assemblyEditable"
        @expanded="toggleExpanded"
        @is-expanded="isExpandedHandler"
        @adding-item="(v) => $emit('addingItem', v)"
        @loading="(v) => $emit('loading', v)"
        @add-item="addItem"
        :refId="reference"
        :showForCost="showForCost && showForCost.length ? showForCost : ['price']"
        v-model="childrenForList"
        :store="store"
        :rootRefId="rootRefId"
      >
      </line-item-list>
    </card-section>
  </div>
</template>

<script>
import AssemblyDimensions from '../quote/ProjectDimensions/AssemblyDimensions.vue'
import AssemblyMixin from './AssemblyMixin'
import AssemblyDimensionsSummaryMixin from './ProjectDimensions/AssemblyDimensionsSummaryMixin'
import SuggestionButton from '../ui/SuggestionButton.vue'

/**
 * Emits:
 *  -loading
 *  -addingItem
 */
export default {
  name: 'AssemblyInner',

  mixins: [AssemblyDimensionsSummaryMixin, AssemblyMixin],
  emits: ['addingItem', 'loading'],

  data() {
    return {
      suggestLinking: []
    }
  },

  watch: {
    object() {
      c.throttle(() => this.checkSuggestLinking(), { key: this.reference })
    },
    unitsAndQties() {
      c.throttle(() => this.checkSuggestLinking(), { key: this.reference })
    }
  },

  computed: {
    unitsAndQties() {
      const norm = this.$store.state[this.store].normalized
      return this.object.aoChildren
        .filter((ch) => norm[ch].type === 'cost_item')
        .map((ch) => `${norm[ch].unit_of_measure_id}${norm[ch].cost_item_qty_net_base}`)
        .join('-')
    }
  },

  mounted() {
    // if (this.object.assembly_id && this.object.assembly_id !== 'null') {
    //   setTimeout(() => this.unsyncIfNotExists());
    // }

    c.throttle(() => this.checkSuggestLinking(), { delay: 500 })
  },

  methods: {
    async acceptSuggestion(sugg) {
      const changes = {}
      const dims = await this.$store.dispatch('Dimension/getPossibleDimensions')
      const abbr = sugg.suggestedDimensions[0].abbr

      changes[this.reference] = {
        oDimensions: {
          ...this.object.oDimensions,
          [abbr]: {
            ...dims[abbr],
            value: sugg.cost_item_qty_net_base,
            equation: '',
            explicitlySet: 1,
            inherit: 0,
            measure: sugg.unit_of_measure_id
          }
        }
      }

      sugg.refIds.forEach((refId) => {
        changes[refId] = {
          cost_type_qty_equation: abbr
        }
      })

      await this.$store.dispatch(`${this.store}/field`, {
        changes,
        explicit: true
      })
    },
    declineSuggestion() {
      const curr = _.imm(this.suggestLinking)
      const latest = curr.shift()
      c.setCacheItem(
        `${this.reference}ISNOT${latest.cost_item_qty_net_base}${latest.unit_of_measure_id}`,
        1
      )
      this.suggestLinking = curr
    },
    async checkSuggestLinking() {
      this.suggestLinking = await this.findConnectedChildQuantities()
    }
  },

  components: {
    SuggestionButton,
    AssemblyDimensions
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$childrenContainerBg: $cool-gray-400;
$borderColor: $cool-gray-500;
$border: 1px solid $borderColor;
$boxShadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
$container-padding: 10px;

.assembly-child--container-outer {
  .assembly-child--container {
    box-shadow: $shadow !important;
    z-index: 3;
    border-width: 0px;

    > ul {
      > .assembly-child--collapse {
        > .collapse--container {
          > .collapse--content {
            > .collapse--content-inner {
              font-size: 1em;
              background-color: var(--slate-100);
              transition: all 0.2s ease;
              height: unset;
              // &:hover {
              //   background-color: var(--slate-200);
              // }
            }
          }
        }
      }
    }
  }

  .collapse--title {
    min-height: 3em;
    height: auto;
  }

  .assembly--header-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: auto;
    flex: 1 100%;
    max-width: 100%;
    height: 100%;

    .assembly--total-label {
      display: none;
    }

    > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      min-width: 200px;
      width: auto;
      flex: auto;
      padding: 0.25em 0;

      &:first-child {
        justify-content: flex-start;
        min-width: 10em;
      }

      &:last-child {
        justify-content: flex-end;
        min-width: unset;
      }
    }
  }

  .assembly--title {
    align-items: center;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    color: $cool-gray-600;
    width: 100%;
    height: 100%;

    position: relative;

    a {
      max-width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      font-size: 0.9em;
      flex-basis: 15em;
      min-height: 3em;
      height: 100%;
      background: $flame-white;
      color: $blue-print-700;
      border: none;
      &::placeholder {
        color: rgba($blue-print-700, 0.7);
      }
    }
  }

  .assembly--qty {
    margin-left: 1em;
    flex: 1 0 20em;
    margin-left: 1em;
    font-size: 0.7em;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;

    [calculator-component] {
      width: 7em;
      flex: 0 0 7em;
      margin-right: 0.1em;
    }
  }

  .assembly--price {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    flex-wrap: none;
    flex: 0 0 6em;
    text-align: right;
    margin-left: 1em;
    text-overflow: ellipsis;
    font-weight: normal;

    > .cost-value {
      color: $deep-red-800;
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      line-height: 1;
      font-size: 0.7em;
    }
    > .price-value {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      color: $cool-gray-700;
      line-height: 1;
      font-size: 1em;
    }
    > .profit-value {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      color: $matcha-500;
      line-height: 1;
      font-size: 0.7em;
    }

    .calculator-container {
      height: 100%;
    }
    input {
      font-size: 0.8em;
      min-width: 7em;
      min-height: 3em;
      height: 100%;
      background: $flame-white;
      color: $blue-print-700;
      border: none;
      &::placeholder {
        color: rgba($blue-print-700, 0.7);
      }
    }
  }

  .assembly-child--folds {
    z-index: -1;
    margin-top: -1px;
    cursor: pointer;
    transition: opacity 2s;
    height: 15px;
    opacity: 1;
    div {
      background: $flame-white;
      box-shadow: $shadow;
      border: none;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      padding: 0px;
      margin: auto auto;
      transition:
        box-shadow 0.5s ease,
        transform 0.2s ease-out !important;
      border-bottom-right-radius: 2em;
      border-bottom-left-radius: 2em;
      position: relative;
    }
    div:first-child {
      height: 7px;
      margin-top: -1px;
      width: 96%;
      z-index: 2;
      transition:
        box-shadow 0.5s ease,
        transform 0.2s ease-out !important;
    }
    div:last-child {
      height: 7px;
      margin-top: -1px;
      width: 90%;
      transition:
        box-shadow 0.5s ease,
        transform 0.25s ease-out !important;
    }
  }

  .collapse--closed {
    > .collapse--container {
      flex: 1 0 100%;
      > .collapse--header {
        &:hover {
          background-color: $flame-white !important;
        }
      }
    }
  }

  &.assembly-child--active {
    .collapse--open {
      > .collapse--container {
        > .collapse--header {
          flex: 0 auto;
          color: $flame-white !important;
          .text-success,
          .text-info,
          .assembly--title {
            color: $flame-white !important;
          }

          > .collapse--title {
            > .assembly--header-contents {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  &:hover {
    > .assembly-child--folds {
      > div:first-child {
        box-shadow: $shadow-more !important;
        transform: translateY(0.1em);
      }
      > div:last-child {
        box-shadow: $shadow-more !important;
        transform: translateY(0.2em);
      }
    }
  }

  .assembly-child--actions {
    margin: 0 0.25em;
  }
}

// Small format
.app--container.small-format .assembly-child--container-outer.assembly-child--active {
  z-index: 1000000000000;
  transition: all 0.3s ease;
  position: static;
  div:not(.assembly-child--container-outer.assembly-child--active) {
    position: static;
  }

  > .assembly-child--container {
    border-radius: 0;
    height: calc(100vh - 3em) !important;
    max-height: calc(100vh - 3em) !important;
    position: static;

    > .list-group {
      position: static;
      > .assembly-child--collapse.collapse--open {
        height: 100%;
        border-radius: 0 !important;

        position: fixed !important;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        max-height: 100%;
        width: 100%;
        z-index: 1000000000000;

        > .collapse--container {
          height: 100%;
          max-height: 100vh;
          min-width: 100vw;
          max-width: 100vw;
          position: static;

          //position: absolute;

          > .collapse--content {
            min-height: unset;
            flex: 0 100%;
            max-height: 100%;
            height: 100%;
            position: static;
            // height: unset;
            // height: 100%;
            // @extend %scrollbarBlue;
            //position: absolute;
            // overflow-x: hidden;
            // overflow-y: scroll;

            > .collapse--content-inner {
              min-height: 100%;
              height: auto;
              padding: 5em 0 25vh 0;
              position: static;
              //position: unset;
              > div {
                position: static;
                > .card-section {
                  position: static;
                  > div {
                    position: static;
                    > div {
                      position: static;
                      > div {
                        position: static;
                        > div {
                          position: static;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          > .collapse--header {
            flex: 0 auto;
            //position: absolute;
            top: 0;
            border-radius: 0 !important;

            > .collapse--caret {
              font-size: 1.5em;
              margin-right: 0.5em;
            }

            .assembly--total-label {
              display: inline;
            }

            > .collapse--title {
              background: inherit;
              > .assembly--header-contents {
                background: inherit;
                > .assembly--upper-header {
                  flex: auto;
                  > .assembly--title {
                    flex: auto;

                    > .assembly-child--actions {
                      margin-left: auto;
                    }

                    input {
                      border: none;
                      flex: 2 100%;
                      min-height: 3em;
                      margin-right: 0.5em;
                    }
                  }
                }

                > .assembly--upper-header,
                > .assembly--lower-header {
                  height: 100%;
                }

                .assembly--lower-header {
                  height: 4em;
                  width: 100vw;
                  z-index: $z-layout;
                  background: inherit;
                  padding: 1em 1em;

                  .assembly--total-label {
                    flex: 1 30%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.9em;
                  }

                  .assembly--price {
                    flex: 2 30%;
                  }

                  .assembly--qty {
                    flex: 2 30%;
                    width: unset;
                    max-width: 30%;
                    > div {
                      max-width: 100%;
                      > .assembly--qty-pill-group {
                        max-width: 100%;
                      }
                    }
                  }

                  input {
                    max-width: unset;
                    max-width: 100%;
                  }

                  .field-component {
                    max-width: 6em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.assembly-child--children-expanded {
    > .assembly-child--container {
      > .list-group {
        > .assembly-child--collapse.collapse--open {
          border-radius: 0 !important;
          > .collapse--container {
            > .collapse--content {
              overflow: hidden;
            }

            > .collapse--header {
              border-radius: 0 !important;
              display: none;
              > .collapse--caret {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// Large format
.app--container:not(.small-format) .assembly-child--container-outer {
  > .assembly-child--container {
    > .list-group {
      > .assembly-child--collapse {
        > .collapse--container {
          > .collapse--content {
            > .collapse--content-inner {
              font-size: 0.95em;
            }
          }
        }
      }
    }
  }
}
</style>
