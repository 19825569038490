<script setup>
import ButtonSkeleton from '@/components/identityProviders/buttons/ButtonSkeleton.vue'

const props = defineProps({
  buttonText: {
    default: 'Sign in with Apple'
  }
})
</script>

<template>
  <ButtonSkeleton :button-text="props.buttonText">
    <template #icon>
      <svg
        width="19.529562"
        height="20"
        viewBox="0 0 19.529562 24"
        version="1.1"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <g
          id="Left-Black-Logo-Large"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          transform="translate(-9.765,-7.6)"
        >
          <path
            d="m 19.819673,13.138462 c 1.08328,0 2.441195,-0.732359 3.249841,-1.708837 0.732358,-0.884933 1.26637,-2.1207884 1.26637,-3.3566434 0,-0.1678322 -0.01526,-0.3356644 -0.04577,-0.4729816 -1.20534,0.045772 -2.6548,0.8086459 -3.524475,1.8308964 -0.686586,0.7781306 -1.312143,1.9987286 -1.312143,3.2498406 0,0.18309 0.03051,0.36618 0.04577,0.42721 0.07629,0.01526 0.198347,0.03052 0.320407,0.03052 z M 16.005305,31.6 c 1.479975,0 2.136046,-0.991736 3.9822,-0.991736 1.876669,0 2.28862,0.961221 3.936427,0.961221 1.617292,0 2.700572,-1.495232 3.722823,-2.959949 1.14431,-1.678322 1.617291,-3.326129 1.647806,-3.402416 -0.106802,-0.03052 -3.204068,-1.296885 -3.204068,-4.851875 0,-3.082009 2.441195,-4.470439 2.578512,-4.577241 -1.617292,-2.319136 -4.073744,-2.380165 -4.745073,-2.380165 -1.815639,0 -3.295614,1.098537 -4.226319,1.098537 -1.006993,0 -2.334393,-1.037508 -3.905912,-1.037508 -2.990464,0 -6.026701,2.47171 -6.026701,7.140496 0,2.898919 1.129053,5.965671 2.517483,7.949142 C 13.472565,30.226828 14.510073,31.6 16.005305,31.6 Z"
            id=""
            fill="#000000"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    </template>
  </ButtonSkeleton>
</template>
