import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userMeta'])
  },

  data() {
    return {
      metaConstants: {
        UserFirstLoginIgnore: 'UserFirstLoginIgnore',
        IgnoreHelpStart: 'IgnoreHelpStart',
        DoneTutorial: 'DoneTutorial',

        DontShowAddItemWizard: 'DontShowAddItemWizard',
        DontShowItemWizard: 'DontShowItemWizard',

        DoneWelcomeWizard: 'DoneWelcomeWizard',

        defaultLengthMeasure: 'defaultLengthMeasure',
        defaultAreaMeasure: 'defaultAreaMeasure',
        defaultVolumeMeasure: 'defaultVolumeMeasure',
        defaultWeightMeasure: 'defaultWeightMeasure',

        DefaultTimeRangeIndex: 'DefaultTimeRangeIndex',

        TraverseDefaultSettings: 'TraverseDefaultSettings'
      }
    }
  },

  methods: {
    /**
     * Set met values from an object with multiple key value pairs
     * @param object
     * @returns {Promise<default.methods>}
     */
    setMeta(object) {
      this.$store.dispatch('setMeta', object)

      return this
    },

    /**
     * Set single meta item by key and value
     * @param itemKey
     * @param itemValue
     * @returns {Promise<void>}
     */
    setMetaItem(itemKey, itemValue) {
      this.setMeta({
        [itemKey]: itemValue
      })

      return this
    },

    /**
     * Alternate to setMeta
     * @param object
     * @returns {Promise<*|Promise<default.methods>>}
     */
    setUserMeta(object) {
      return this.setMeta(object)
    },

    /**
     * Get meta by item key
     * @param key
     * @returns {Promise<*|null>}
     */
    getMetaItem(itemKey) {
      return this.userMeta[itemKey] || null
    }
  }
}
