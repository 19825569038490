<template>
  <li class="card-list-select list-group-item list-group-item-action">
    <a
      @touchstart.passive="() => $emit('touchstart', $event)"
      @click.stop.prevent="() => $emit('click', $event)"
    >
      <slot></slot>
    </a>
  </li>
</template>

<script>
export default {
  name: 'CardListSelect',
  emits: ['touchstart', 'click']
}
</script>

<style lang="scss" rel="stylesheet/scss">
.card-list-select {
  padding: 0 !important;
  a {
    display: block;
    // padding: $list-group-item-padding-y $list-group-item-padding-x !important;
    font-weight: bold;
    &:hover {
      background: rgba($blue-print-700, 0.2);
      color: $blue-print-600 !important;
    }
  }
}
</style>
