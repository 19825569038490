<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col justify-start items-center gap-2 overflow-hidden">
      <div class="flex flex-row justify-center items-center rounded-lg overflow-hidden relative">
        <div
          class="flex justify-center items-center w-[640px] h-[480px] bg-gray-200 border-4 border-primary rounded-lg"
        >
          <video
            ref="cameraStream"
            width="640"
            height="480"
            autoplay
            class="block rounded-lg"
          ></video>
          <Btn
            :action="takePhoto"
            v-if="isCameraStarted"
            severity="bolster"
            class="!absolute right-0 bottom-0"
            size="xl"
          >
            <font-awesome-icon icon="camera" />
            Take photo
          </Btn>
        </div>
      </div>

      <div
        class="flex gap-2 justify-center items-center flex-wrap max-w-full overflow-hidden max-w-[640px]"
      >
        <div
          v-for="(photo, index) in photoDataURLs"
          :key="index"
          class="w-[152px] h-[114px] m-0 p-0 relative"
        >
          <img :src="photo" alt="Captured Photo" class="rounded-lg" />
          <Btn
            rounded
            class="!absolute top-1 right-1"
            severity="bolster"
            :action="() => remove(index)"
          >
            <font-awesome-icon icon="trash" />
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted, defineEmits, defineExpose, defineProps } from 'vue'
import { Camera, CameraResultType } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import { useStore } from 'vuex'
import Geolocation from '@/components/composables/Geolocation'

const isWeb = Capacitor.getPlatform() === 'web'

const props = defineProps({
  geoMandatory: {
    type: Boolean,
    default: false
  }
})

const { captureLocation, instructions, error } = Geolocation.useGeolocation()

const isCameraStarted = ref(false)
const cameraStream = ref(null)
const photoDataURLs = ref([])

const emit = defineEmits(['filesCaptured'])

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

let stream
const startCamera = async () => {
  try {
    stream = await navigator.mediaDevices.getUserMedia({ video: true })
    cameraStream.value.srcObject = stream
    isCameraStarted.value = true
  } catch (error) {
    console.error('Error accessing the camera:', error)
  }
}

const stopCamera = () => {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop())
    cameraStream.value.srcObject = null
    isCameraStarted.value = false
  }
}

const takePhoto = async () => {
  try {
    if (!isWeb) {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        quality: 90,
        allowEditing: false,
        source: 'CAMERA',
        webUseInput: false
      })

      if (photo.dataUrl) {
        photoDataURLs.value.push(photo.dataUrl)
      }
    } else if (stream) {
      const canvas = document.createElement('canvas')
      canvas.width = 640
      canvas.height = 480
      const context = canvas.getContext('2d')
      context.drawImage(cameraStream.value, 0, 0, canvas.width, canvas.height)
      const photoDataURL = canvas.toDataURL('image/png')
      photoDataURLs.value.push(photoDataURL)
    }
  } catch (error) {
    console.error('Error taking photo:', error)
  }
}

const remove = (index) => {
  const urls = [...photoDataURLs.value]
  urls.splice(index, 1)
  photoDataURLs.value = urls
}

const $store = useStore()

const geoTag = async () => {
  let err = false

  let geo = captureLocation()
  if (!geo) {
    err = true
    geo = {}
  }

  if (err) {
    console.log('NO GEO', instructions.value)
    await $store.dispatch('modal/asyncConfirm', {
      message: `
        <div class="flex flex-col gap-2">
          <span class="font-bold text-xl my-2">Enable location settings!</span>
          ${props.geoMandatory ? '<div class="border-4 border-deep-red-500 text-deep-red-500 p-2 rounded-md my-2">Cannot continue without geolocation, as it is mandatory.</div>' : ''}
          ${instructions.value}
        </div>
      `,
      yes: 'Ok',
      no: null
    })

    if (props.geoMandatory) throw new Error('Geo location is mandatory for these pictures.')
  }

  return geo
}

const getFiles = async () => {
  const geo = await geoTag()
  const files = photoDataURLs.value.map((dataURL, index) => ({
    geolocation: geo,
    File: dataURLtoFile(dataURL, `photo_${index}.png`)
  }))
  return files
}

defineExpose({
  getFiles
})

onMounted(() => {
  startCamera()
})

onBeforeUnmount(() => {
  stopCamera()
})
</script>

<style>
/* Add your styles here */
</style>
