<template>
  <grid
    type="quote"
    ref="grid"
    :multiple="multiple"
    :isMain="isMain"
    :permanentFilters="filters"
    :order="[['quote_time_last_modified', 'DESC']]"
    :filters="{
      quote_status: 'p'
    }"
    :filterText="{
      quote_status: 'Pending'
    }"
  />
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'
import SelectorBodyMixin from '../mixins/SelectorBody'

export default {
  mixins: [BodyMixin, SelectorBodyMixin],
  components: { Grid }
}
</script>

<style></style>
