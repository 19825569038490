<script setup>
import PageHeader from '@/components/layout/page/PageHeader.vue'

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  icon: {
    type: String,
    required: false
  },
  full: {
    type: Boolean,
    default: false
  },

  // Fixed layout, no padding etc
  fixed: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'xl' // 2xl, xl, lg, md, sm
  },
  scrollable: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <div
    data-component="page"
    :class="[
      'flex flex-col w-full min-h-full',
      {
        'items-center': !props.full,
        'h-full w-full': props.full,
        'overflow-y-auto max-h-full': props.scrollable,
        'px-safe-4 lg:px-8': !props.fixed
      }
    ]"
  >
    <div
      :class="[
        {
          [`w-full h-full max-w-screen-${size} flex flex-col justify-start gap-8`]: !props.full,
          'w-full h-full flex flex-col items-center justify-start': props.full,
          'pb-10': props.scrollable
        }
      ]"
    >
      <div
        v-if="$slots.header || title"
        :class="[
          {
            [`pt-4 w-full max-w-screen-${size} px-4 md:px-8`]: props.full
          }
        ]"
      >
        <slot name="header">
          <PageHeader :title="title" :icon="icon" />
        </slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
