export function getPhoneSteps(object, store) {
  let steps = []

  if (!object.phone_is_verified) {
    steps = [
      ...steps,
      {
        name: 'This phone number has not been verified.',
        short: 'Verify phone number',
        action: (obj, button, grid) =>
          store.dispatch('phone/verify', {
            id: obj.phone_id,
            grid,
            button
          }),
        glyph: 'phone',
        color: 'green-500'
      }
    ]
  }
  return steps
}
