export default {
  type: 'custom_field',

  skipAudit: true,

  fields: {
    custom_field_id: {
      type: 'int'
    },
    custom_field_creator: {
      type: 'int'
    },
    custom_field_time_created: {
      type: 'int'
    },
    custom_field_time_last_modified: {
      type: 'int'
    },
    custom_field_status: {
      type: 'int'
    },
    custom_field_type: {
      type: 'string',
      required: true
    },
    custom_field_validation: {
      type: 'string'
    },
    custom_field_name: {
      type: 'string',
      required: true
    },
    custom_field_is_required: {
      type: 'int',
      default: 0
    }
  }
}
