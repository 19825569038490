<template>
  <div class="gantt-line--actions w-full h-full">
    <div
      :class="[actionsOpened ? 'force-display' : '', dragging ? '!hidden' : '']"
      class="flex items-center justify-end h-full max-w-[62px] gantt-line--actions-content"
    >
      <div class="gantt-line--action">
        <CreateTask :item="item" :onCreateTask="addTask" />
      </div>
      <Drop
        :hidePointer="true"
        classes="max-w-5"
        @closed="() => (actionsOpened = false)"
        @open="() => (actionsOpened = true)"
      >
        <template #button>
          <div
            class="gantt-line--action gantt-dragg w-5"
            :class="[
              `flex justify-center items-center ${item.reference_type === 'item' ? '' : ''}`,
              dragging ? 'cursor-grabbing' : 'cursor-pointer'
            ]"
            v-tooltip.bottom="{
              value: '<b>Click</b> for options <br /> <b>Click and hold</b> to drag',
              escape: false
            }"
          >
            <font-awesome-icon
              class="text-base hover:bg-cool-gray-100 p-1 rounded gantt-dragg"
              :icon="['fas', 'grip-dots-vertical']"
            />
          </div>
        </template>
        <div class="flex flex-col">
          <Btn
            @click="menuItem.action"
            :pt="{ root: 'text-white hover:text-pitch-black mb-2' }"
            :link="true"
            :key="menuItem.label"
            v-for="menuItem in menuItems"
          >
            <font-awesome-icon :icon="['far', menuItem.icon]" />
            {{ menuItem.label }}
          </Btn>
        </div>
      </Drop>
      <div
        :class="isSelected ? 'isSelected' : ''"
        class="gantt-line--action mr-0.5"
        v-if="item.reference_type === 'item'"
      >
        <Checkbox
          v-tooltip="'Select task'"
          v-model="isSelected"
          @input="(state) => updateSelected(state, item.id)"
          class="border !border-cool-gray-900"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, toRef, computed } from 'vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import CreateTask from '@/components/schedule/fields/CreateTask.vue'
import useGantt from '@/components/ui/gantt/Gantt'
import useTask from '@/components/schedule/Task'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  edit: {
    type: Function
  }
})

const item = toRef(props, 'item')
const actionsOpened = ref(false)

const { updateSelected, isSelectedItem, dragging } = useGantt()
const { onCreateTask, confirmAddingTask, deleteTask } = useTask()

const isSelected = computed(() => isSelectedItem(item.value.id))

const editTask = () => {
  if (props.edit) props.edit(item.value)
}

const menuItems = ref([
  {
    label: `Edit ${item.value.reference_type === 'stage' ? 'stage' : 'task'}`,
    action: editTask,
    icon: 'pencil'
  },
  ...(item.value.reference_type === 'item'
    ? [{ label: `Delete task`, icon: 'trash', action: () => deleteTask(item.value.id) }]
    : [])
])

const addTask = (parent) => {
  onCreateTask(parent)
  confirmAddingTask()
}
</script>
