<template>
  <Container>
    <h3>{{ cost_type_name }}</h3>
    <CardSection class="m-0" v-if="!full">
      <template #title>
        <span>Customize labor costs</span>
      </template>

      <CardList>
        <CardListHeader class="info"> Construction stage: {{ trade_type_name }} </CardListHeader>

        <card-list-field>
          <help class="wide-label">
            <template #before>
              <font-awesome-icon icon="user-helmet-safety" class="mr-1" />
              Labor/installation included?</template
            >
            <template #title>Item includes labor/installation?</template>

            <p>
              Does this item include any kind of labor or installation, or does it only include
              materials?
            </p>
            <p>
              If this includes any kind of labor or installation and it should be supplied by
              {{ isSubcontracted ? 'the subcontractor' : 'you' }}, check this box.
            </p>
          </help>

          <div>
            <checkbox v-model="includesLabor" class="info mr-2" />
          </div>
        </card-list-field>

        <template v-if="includesLabor">
          <card-list-field>
            <help class="wide-label">
              <template #before>
                <font-awesome-icon icon="shop" class="mr-1" />
                Subcontracted item?</template
              >
              <template #title>Subcontracted item?</template>

              <p>
                Is the labor for this item usually completed by a subcontractor, or by you or an
                employee/coworker?
              </p>
              <p>If it is done by a subcontractor, check this box.</p>
            </help>

            <div>
              <checkbox v-model="isSubcontracted" class="info mr-2" />
            </div>
          </card-list-field>

          <card-list-field v-if="includesLabor && !isSubcontracted" class="under under-first">
            <span>
              <help>
                <template #before>
                  <span> Labor type </span>
                </template>
                <template #title>
                  <span> Labor type </span>
                </template>

                <p>
                  Assign a labor rate to this item so that if you ever need to change the labor
                  rate, all the items associated with it will automatically reflect the new labor
                  costs.
                </p>

                <p>Choose a saved labor rate, or create your own.</p>

                <btn class="sm info round block" :action="editLaborType"> Edit labor type </btn>
              </help>
            </span>

            <choose
              schema="labor_type:labor_type_id"
              v-model="laborType"
              :return-array="false"
              :disabled="!editable"
            />
          </card-list-field>

          <card-list-field v-if="includesLabor && !isSubcontracted" class="under">
            <span>
              <help>
                <template #before>
                  <span> Labor rate </span>
                </template>
                <template #title>
                  <span> Labor rate </span>
                </template>
                <p>
                  The labor rate can be an actual rate that you pay a particular person or group of
                  people, or it can be an average for example the average rate of a crew.
                </p>

                <p>
                  This rate should include all your costs associated with labor. We recommend that
                  you include workers compensation insurance costs (often calculated by the hour),
                  payroll taxes (including employment insurance, pension amounts) as well as holiday
                  pay. Try to account for every factor of labor cost.
                </p>
              </help>
            </span>

            <field type="calculator" v-model="laborRate" />
            <small class="text-muted" style="flex-shrink: 0; flex-basis: auto !important">
              / hr
            </small>
          </card-list-field>
        </template>
      </CardList>

      <Btn class="sm round mt-2" :action="editFull">Edit more..</Btn>
    </CardSection>
    <CostItem v-else :store="store" :reference="refId" />

    <Btn class="block info round mt-5" :action="saveAndClose">
      <template #icon>
        <font-awesome-icon :icon="['fas', 'star']" />
      </template>
      Save in your catalog..
    </Btn>
    <Btn class="block round mt-2" :action="close"> Close </Btn>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectDistinct'
import BodyMixin from '@/components/mixins/Body'
import CostItemMixin from '@/components/quote/CostItemMixin'
import UserMetaMixin from '../mixins/UserMeta'
import CostItem from '../bodies/CostItem.vue'
import eventBus from '../../eventBus'

export default {
  name: 'CostItemAdded',
  mixins: [ObjectManipulator('cost_item'), BodyMixin /* AutoSave */, CostItemMixin, UserMetaMixin],
  emits: ['closeModal'],
  data() {
    return {
      wizardComplete: 0,
      enableFullAudit: false,
      enableLocalAudit: true,
      editing: true,
      wizarding: null,
      full: 0
    }
  },
  watch: {},
  computed: {
    casted() {
      return this.cast()
    },
    showWizard() {
      return this.wizarding
      // if (this.wizarding === true) return true;
      // if (this.wizarding === false) return false;
      //
      // // default on if no cost type id and not skipped
      // return !this.userMeta[this.metaConstants.DontShowItemWizard]
      //   && !this.cost_type_id;
    }
  },
  methods: {
    editFull() {
      this.full = 1
    },
    async saveAndClose() {
      await this.save()
      return this.close()
    },
    close() {
      this.$emit('closeModal')
    }
  },
  components: {
    CostItem
  },
  props: {
    store: {
      default: 'Quote'
    }
  },
  mounted() {
    eventBus.$once(`${this.uid}-selected`, () => {
      c.throttle(() => {}).then(() => c.focusInput(this.$refs.titleInput))
    })
  },
  beforeUnmount() {
    this.audit()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.cost-item--container {
  .card-list-item-field .card-list-field {
    & > :first-child {
      &.wide-label {
        flex: 0 100% !important;
        white-space: normal;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
</style>
