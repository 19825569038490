<template>
  <div class="px-2">
    <div class="progress-steps flex gap-0.5 justify-center items-center my-0.5">
      <div
        v-tooltip="clientScopeApprovalMsg"
        class="progress-step"
        :class="{
          active: isClientScopeApproved,
          disabled: true
        }"
      >
        <font-awesome-icon :icon="['far', 'house-circle-check']" />
      </div>
      <div
        @click="markWorkAsComplete"
        v-tooltip="workApprovedMsg"
        class="progress-step"
        :class="{
          active: isWorkCompleted,
          disabled: !isClientScopeApproved
        }"
      >
        <font-awesome-icon :icon="['far', 'clipboard-check']" />
      </div>
      <div
        @click="!isApprovalDisabled ? markAsApproved() : () => {}"
        v-tooltip="approvalMsg"
        class="progress-step"
        :class="{
          active: isApprovedByCompany,
          disabled: isApprovalDisabled || !isWorkCompleted
        }"
      >
        <font-awesome-icon :icon="['far', 'badge-check']" />
      </div>
      <div
        v-tooltip="paymentMsg"
        class="progress-step"
        :class="{
          active: isPaid,
          disabled: true
        }"
      >
        <font-awesome-icon :icon="['far', 'money-bill-wave']" />
      </div>
    </div>
    <div class="progress-bar relative h-1 w-full bg-cool-gray-200 rounded mb-0.5">
      <span :style="`width: ${progress}%`" class="progress-indicator"></span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed, toRefs, ref, onMounted } from 'vue'
import { statuses } from '@/../imports/api/Statuses'
import Approval from '@/../imports/api/Approval'
import useApprovals from '@/components/composables/Approvals'
import useTask from '@/components/schedule/Task'
import useApproval from '@/components/composables/Approval'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})
const { item } = toRefs(props)
const approval = ref()
const itemStatus = ref(item.value.item_status)
const itemApproved = ref(approval.value ? isApprovedByApprover(approval.value) : false)

const { saveItemField } = useTask()
const { isApprovedByApprover, processAction } = useApproval()
const { getApproval, upsertApproval } = useApprovals()

const isApprovedByCompany = computed(
  () => itemApproved.value || isApprovedByApprover(approval.value)
)
const isWorkCompleted = computed(() => itemStatus.value === statuses.Completed)
const isClientScopeApproved = computed(() => item.value.change_order_status === statuses.Booked)
const isPaid = computed(
  () =>
    item.value.invoice_id &&
    item.value.invoice_status &&
    (item.value.invoice_status === statuses.Paid || item.value.invoice_status === statuses.Active)
)

const clientScopeApprovalMsg = computed(() => {
  if (isClientScopeApproved.value) return 'Client has approved scope'
  return 'Client has not yet approved of scope'
})

const workApprovedMsg = computed(() => {
  if (isWorkCompleted.value) return 'Mark as work complete'
  if (!isWorkCompleted.value && !isClientScopeApproved.value)
    return 'Scope must be approved by client before marked as completed'
  return 'Work has been marked as completed'
})

const approvalMsg = computed(() => {
  if (isApprovedByCompany.value) return 'Has been marked as quality approved'
  if (!isApprovalDisabled.value) return 'Mark as quality approved'
  if (!isApprovalDisabled.value && !isWorkCompleted.value)
    return 'Work must be marked as completed before approval'
  return 'You do not have permission to approve quality'
})

const paymentMsg = computed(() => {
  if (isPaid.value) return 'Item has been paid'
  return 'Item has not been paid'
})

const progress = computed(() => {
  let progress = 0
  if (isClientScopeApproved.value) progress = 25
  if (isWorkCompleted.value) progress = 50
  if (isApprovedByCompany.value) progress = 75
  if (isPaid.value) progress = 100
  return progress
})

const isApprovalDisabled = computed(() => !approval.value)

const markWorkAsComplete = () => {
  // optimistically update
  try {
    itemStatus.value = statuses.Completed
    saveItemField(statuses.Completed, 'item_status', item.value.item_id)
  } catch (e) {
    itemStatus.value = statuses.InProgress
  }
}

const markAsApproved = async () => {
  try {
    // optimistically update
    itemApproved.value = true
    const updateApproval = await processAction(approval.value, Approval.statuses.APPROVED)
    const isApproved = isApprovedByApprover(updateApproval)
    if (!isApproved) itemApproved.value = false
    upsertApproval(updateApproval)
  } catch (e) {
    itemApproved.value = false
  }
}

onMounted(async () => {
  c.throttle(
    async () => {
      if (item.value.item_id) approval.value = await getApproval(item.value.item_id)
    },
    { key: item.value.item_id, delay: 600 }
  )
})
</script>

<style lang="scss" scoped>
.progress-steps {
  @apply first:rounded-l last:rounded-r;
}
.progress-step {
  @apply px-1 w-7 h-7 flex justify-center items-center text-cool-gray-200 cursor-pointer hover:bg-blue-print-100 hover:text-blue-print transition-all first:rounded-l last:rounded-r;
  &.disabled {
    @apply cursor-pointer hover:bg-cool-gray-100 hover:text-cool-gray-200;
  }
  &.active {
    @apply text-blue-print bg-blue-print-100 hover:bg-blue-print-100 hover:text-blue-print;
  }
}
.progress-bar {
  .progress-indicator {
    @apply absolute block left-0 h-full bg-blue-print transition w-6/12 rounded;
  }
}
</style>
