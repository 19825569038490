import { getCurrentUser, signOut } from 'aws-amplify/auth'
import { useAuthentication } from '@/composables/authentication'
import { useStore } from 'vuex'

/**
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param {import('vue-router').RouteLocationNormalized} from
 * @return {import('vue-router').NavigationGuardReturn}
 */
export async function beforeEachGuard(to, from) {
  const store = useStore()
  const { tokenExchange } = useAuthentication()
  if (to.meta.public) {
    return true
  }

  // Check user is authenticated
  try {
    await getCurrentUser()
  } catch (e) {
    return {
      name: 'Login',
      query: {
        redirect: to.path,
        ...to.query
      }
    }
  }

  // Check if we are trying to use a magic link
  if (to.query.magic) {
    // Logout magic link users first
    await store.dispatch('logout', { go: false })
    await signOut()
    // Return them to the login page to do magic fun times
    return {
      name: 'Login',
      query: {
        redirect: to.path,
        ...to.query
      }
    }
  }

  // In some instances, like when we reload, Vuex state gets obliterated
  // So, in that case...
  if (Object.keys(store.state.session?.authorizedUser).length === 0) {
    console.debug('No available state! Refreshing!')
    try {
      await tokenExchange()
    } catch (e) {
      // Assume we don't have any tokens or something went horrendously wrong and fail nicely
      console.debug('Always pull out when you feel the protection break')
      await signOut()
      return {
        name: 'Login',
        query: {
          redirect: to.path,
          ...to.query
        }
      }
    }
  }

  if (shouldSetScope(to, from)) {
    await store.dispatch('setScopeByScopeRoute', to)
  }

  const redirect = shouldRedirect(to, store)
  // Check whether we need to do some sort of redirect
  if (redirect) {
    return redirect
  }

  // otherwise go to the requested route
  return true
}

/**
 * Check if the user is an admin of the company
 * @param companyId
 * @param store
 * @returns {*|boolean}
 */
function includedAsCompanyAdmin(companyId, store) {
  if (companyId === null || companyId === undefined) {
    return false
  }

  const companyIdStr = String(companyId)
  const adminCompanyIdsStr = store.state.session.user?.user_admin_company_ids?.map(String)
  return adminCompanyIdsStr?.includes(companyIdStr)
}

/**
 * Redirect to the company intake form if the following all hold:
 *  - The company hasn't onboarded yet
 *  - The current user isn't a superuser
 *  - The route we're going to is scoped to a company
 *  - The route we're going to is not public
 *  - The route we're going to isn't .../intake
 *
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param store
 */
function shouldRedirectToIntake(to, store) {
  const hasOnboarded = store.state.session.company.company_has_onboarded
  const isSuperUser = store.state.session.user.user_is_super_user
  const isCompanyAdmin = includedAsCompanyAdmin(store.state.session.company?.company_id, store)

  return !!(
    !hasOnboarded &&
    !isSuperUser &&
    isCompanyAdmin &&
    to.params.scopeRoute &&
    !to.meta.public &&
    to.name !== 'Intake'
  )
}

/**
 * A not-so-fool-proof way to determine if we should call setScopeFromScopeRoute. It naively
 * compares a few metrics to check for differences. It should catch almost all cases but may
 * fail in instances such as two routes both defining the same allowed scopes, but the scope
 * type actually differs between them.
 *
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param {import('vue-router').RouteLocationNormalized} from
 * @return {boolean}
 */
function shouldSetScope(to, from) {
  if (to.params.scopeRoute === undefined) {
    console.debug('No available scope! Not calling setScope')
    return false
  }

  console.debug(`Scope detected! Analyzing ${to.params.scopeRoute}...`)
  if (to.params.scopeRoute !== from.params.scopeRoute) {
    console.debug('Scope change detected! Scope should be set!')
    return true
  }

  console.debug('Scopes are identical but ambiguous, verifying permitted scopes just in case!')
  const toScopes = to.meta.scopesAllowed
  const fromScopes = from.meta.scopesAllowed
  if (toScopes.length !== fromScopes?.length) {
    console.debug('Permitted scopes differ by quantity, set to be safe')
    return true
  }

  console.debug('Permitted scope quantity matches, validate based on scope definitions')
  if (toScopes.sort().every((value, index) => value === fromScopes.sort()[index])) {
    console.debug("Permitted scopes match fully, don't set scope again")
    return false
  }

  console.debug('Permitted scopes do not fully match, call setScope')
  return true
}

/**
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param {Store<any>} store
 */
function companyIsLocked(to, store) {
  const ignoredRouteNames = ['Billing', 'Home', 'LockedCompany', 'Intake']

  return (
    !store.state.session.authorizedUser.user_is_super_user &&
    to.meta.scopesAllowed?.includes('company') &&
    store.getters.inCompany &&
    !ignoredRouteNames.includes(to.name) &&
    store.state.session.company.company_is_locked
  )
}

/**
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param {import('vuex').useStore} store
 */
function shouldRedirect(to, store) {
  if (companyIsLocked(to, store)) {
    return { name: 'LockedCompany', params: { scopeRoute: to.params.scopeRoute } }
  }
  // redirect to intake if conditions are satisfied
  // We need to pass the store because we can't use 'inject()' in that function
  if (shouldRedirectToIntake(to, store)) {
    return { name: 'Intake', params: { scopeRoute: to.params.scopeRoute } }
  }

  return false
}
