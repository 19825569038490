<template>
  <div
    :id="uid"
    :class="[
      'w-full rounded-sm border-2 p-4',
      {
        'border-blue-print': isCheckedOut,
        'border-surface-300': !isCheckedOut
      }
    ]"
  >
    <div class="flex justify-start items-center w-full">
      <div class="size-10 flex justify-center items-center shrink-0">
        <font-awesome-icon icon="file" size="2xl" />
      </div>
      <div class="flex flex-col justify-center items-start basis-2/5 shrink-0">
        <div class="flex justify-start items-end text-xl font-medium leading-none">
          <template v-if="isCheckedOut">
            {{
              object.change_order_client_approved_time
                ? 'Current approved version'
                : 'Current revision'
            }}
          </template>
          <template v-else>
            {{ object.change_order_client_approved_time ? object.change_order_name : 'Revision' }}
          </template>
        </div>
        <div class="flex justify-start items-start text-sm leading-none">
          {{ $$(object.quote_price_net) }} • {{ $f.date(object.change_order_time_created) }}
        </div>
      </div>

      <div class="flex justify-start items-center gap-2 basis-1/5 shrink-0">
        <div
          v-if="object.change_order_status === 'd'"
          v-tooltip="
            `This revision was declined by your customer ${object.change_order_declined_reason}`
          "
        >
          <font-awesome-icon
            :class="[dashIconClass, '!border-deep-red-500 !text-deep-red-500']"
            icon="user-xmark"
            size="lg"
          />
        </div>
        <div
          v-tooltip="
            object.change_order_time_sent
              ? `This revision was sent on ${$f.date(object.change_order_time_sent)}`
              : 'This version has not yet been sent'
          "
        >
          <font-awesome-icon
            :class="[
              dashIconClass,
              {
                '!border-blue-print !text-blue-print': object.change_order_time_sent
              }
            ]"
            icon="paper-plane"
            size="lg"
          />
        </div>
        <div
          v-tooltip="
            object.change_order_time_seen
              ? `This revision was seen on ${$f.date(object.change_order_time_seen)}`
              : 'This version has not yet been seen'
          "
        >
          <font-awesome-icon
            :class="[
              dashIconClass,
              {
                '!border-blue-print !text-blue-print': object.change_order_time_seen
              }
            ]"
            icon="eye"
            size="lg"
          />
        </div>
        <div
          v-tooltip="
            object.change_order_client_approved_time
              ? `This version was approved on ${$f.date(object.change_order_client_approved_time)}`
              : 'This version is not yet approved'
          "
        >
          <font-awesome-icon
            :class="[
              dashIconClass,
              {
                '!border-blue-print !text-blue-print': object.change_order_client_approved_time
              }
            ]"
            icon="house-circle-check"
            size="lg"
          />
        </div>
      </div>

      <div
        class="justify-self-end flex justify-end items-center text-right basis-full shrink-1 gap-2"
      >
        <PersonAvatar :id="object.change_order_creator" />

        <btn rounded link :action="reviewChanges" v-tooltip="'Review changes..'">
          <font-awesome-icon icon="code-fork" />
        </btn>

        <btn-bar
          v-if="editable"
          ref="changeOrderActions"
          :collapse="true"
          :position="actionMenuPosition"
          :showButton="true"
          :selected="[
            {
              type: 'change_order',
              change_order_id: object.change_order_id
            }
          ]"
          :actions="[
            {
              action: reviewChanges,
              title: 'Review changes made on this change order',
              icon: 'code-fork'
            },
            {
              action: download,
              title: 'Download authorization file',
              icon: 'cloud-arrow-down'
            },
            {
              action: checkout,
              title: 'Checkout this revision',
              icon: 'arrow-left',
              visible: () => !isCheckedOut
            },
            {
              title: 'Approval',
              icon: 'pencil',
              visible: () => object.change_order_status !== 'k',
              options: [
                {
                  action: send,
                  title: 'Send for client approval',
                  icon: 'paper-plane',
                  visible: () => latest
                },
                {
                  action: addAuthFile,
                  title: 'Add signed contract',
                  icon: 'folder-open'
                },
                {
                  action: markBooked,
                  title: 'Mark as approved (without contract)',
                  icon: 'check'
                }
              ]
            },
            {
              action: addAuthFile,
              title: 'Change signed contract',
              icon: 'pencil',
              visible: () => object.auth_file_id
            },
            {
              action: addAuthFile,
              title: 'Add signed contract',
              icon: 'pencil',
              visible: () => !object.auth_file_id && object.change_order_status === 'k'
            },
            {
              action: rename,
              title: 'Rename change order',
              icon: 'pencil'
            },
            {
              action: removeChangeOrder,
              title: 'Delete this change order..',
              icon: 'trash'
            }
          ]"
        />
      </div>
    </div>

    <!--    <div class="flex flex-column flex-md-row justify-start items-center" style="width: 100%">-->
    <!--      <div class="flex justify-center items-center mr-3 mb-4 mb-md-0">-->
    <!--        <font-awesome-icon-->
    <!--          icon="file"-->
    <!--          class="text-muted change-order&#45;&#45;icon"-->
    <!--          style="font-size: 2em"-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div class="flex flex-column justify-center items-start" style="flex-basis: 60%">-->
    <!--        <strong class="change-order&#45;&#45;name">-->
    <!--          {{ object.change_order_name }} •-->
    <!--          <span class="mono">{{ $f.$(object.quote_price_net) }}</span>-->
    <!--        </strong>-->

    <!--        <div class="change-order&#45;&#45;status mb-1">-->
    <!--          <div-->
    <!--            class="change-order&#45;&#45;message"-->
    <!--            :class="{-->
    <!--              active: object.change_order_message || object.change_order_status === 'd'-->
    <!--            }"-->
    <!--          >-->
    <!--            <User-->
    <!--              v-if="object.change_order_status !== 'd'"-->
    <!--              :id="object.change_order_creator"-->
    <!--              class="xxs"-->
    <!--            />-->
    <!--            <Client v-else :id="object.client_id" class="xxs" />-->
    <!--            <p class="message-text" v-if="object.change_order_status === 'd'">-->
    <!--              {{ object.change_order_declined_reason || 'No reason provided' }}-->
    <!--            </p>-->
    <!--            <p class="message-text" v-else-if="object.change_order_message">-->
    <!--              {{ object.change_order_message || '' }}-->
    <!--            </p>-->
    <!--          </div>-->

    <!--          <btn-group style="width: 100%" class="sm eq" v-if="object.change_order_status !== 'd'">-->
    <!--            <Btn static v-if="object.change_order_time_sent" class="info">-->
    <!--              <div class="flex flex-column items-center justify-center">-->
    <!--                Sent<small class="mt-1">{{ $f.date(object.change_order_time_sent) }}</small>-->
    <!--              </div>-->
    <!--            </Btn>-->
    <!--            <Btn static v-else> Not sent </Btn>-->

    <!--            <Btn static class="info" v-if="object.change_order_time_seen">-->
    <!--              <div class="flex flex-column items-center justify-center">-->
    <!--                Seen<small class="mt-1">{{ $f.date(object.change_order_time_seen) }}</small>-->
    <!--              </div>-->
    <!--            </Btn>-->
    <!--            <Btn static v-else> Not seen </Btn>-->

    <!--            <Btn static class="info" v-if="object.change_order_status === 'k'">-->
    <!--              <div class="flex flex-column items-center justify-center">-->
    <!--                Booked<small class="mt-1">{{ $f.date(object.change_order_time_booked) }}</small>-->
    <!--              </div>-->
    <!--            </Btn>-->
    <!--            <Btn static v-else> Not booked </Btn>-->

    <!--            <Btn-->
    <!--              static-->
    <!--              class="info"-->
    <!--              v-if="object.auth_file_id || object.change_order_client_has_approved"-->
    <!--            >-->
    <!--              <div class="flex flex-column items-center justify-center no-wrap">-->
    <!--                Client auth<small class="mt-1">{{-->
    <!--                  $f.date(object.change_order_client_approved_time)-->
    <!--                }}</small>-->
    <!--              </div>-->
    <!--            </Btn>-->
    <!--            <Btn static v-else> No client authorization </Btn>-->
    <!--            <Btn @click="retrieveLink" v-if="displayLink"> Retrieve sent link </Btn>-->
    <!--          </btn-group>-->

    <!--          <btn-group style="width: 100%" class="sm eq" v-else>-->
    <!--            <Btn static class="danger">-->
    <!--              <div class="flex flex-column items-center justify-center no-wrap">-->
    <!--                Declined<small class="mt-1">{{ $f.date(object.change_order_time_declined) }}</small>-->
    <!--              </div>-->
    <!--            </Btn>-->
    <!--          </btn-group>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div style="margin-left: auto" class="flex justify-end items-center mt-2 mt-md-0">-->
    <!--        <div class="mr-2" style="max-width: 10em" v-if="editable">-->
    <!--          <next-steps-->
    <!--            :object="object"-->
    <!--            @done="() => $emit('reload')"-->
    <!--            :go="false"-->
    <!--            v-if="latest && object.change_order_status !== 'd'"-->
    <!--            :inline="false"-->
    <!--          />-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->
    <mini-modal v-if="reviewing" ref="changeReview">
      <spinner :loading="1" v-if="loading" />
      <template v-else-if="Object.keys(mergedExplicitChanges).length">
        <change-audit
          :startOpen="true"
          :changes="viewingExplicit ? mergedExplicitChanges : mergedChanges"
        />

        <btn class="xs info" :action="swapChanges">
          {{ viewingExplicit ? 'See all resulting changes..' : 'See only user changes..' }}
        </btn>
      </template>
      <div v-else>
        <h5>
          This change order has no recorded changes. It may because it is older. Older change orders
          did not record changes.
        </h5>
      </div>
    </mini-modal>
    <file-list
      ref="fileList"
      :startingFolder="`quote-${object.quote_id}`"
      :hoverOn="`#${uid}`"
      :showList="false"
      :allowCreate="true"
      placeholder="Drag signed contract file to change"
      v-model="selectedFile"
    />
    <Field class="field-link" readonly v-show="link" :value="link"></Field>
  </div>
</template>

<script>
import ChangeAudit from '@/components/ChangeAudit/ChangeAudit.vue'
import FileList from '@/components/ui/FileList.vue'
import Utilities from '../../../imports/api/Changes/Utilities'
import Button from '../mixins/Button'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import { useMediaQuery } from '@/composables/mediaQuery'

const { mergeChanges, denormalizeDiffSet } = Utilities

export default {
  name: 'ChangeOrder',

  mixins: [Button, CurrencyFilter],
  emits: ['reload', 'send', 'checkout', 'deleted'],
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  data() {
    return {
      dashIconClass:
        'rounded-sm p-2 size-6 border border-surface-300 text-surface-300 cursor-pointer',
      authFile: this.object.oAuthFile,
      renaming: false,
      uid: _.uniqueId(),
      reviewing: false,
      mergedExplicitChanges: {},
      mergedChanges: {},
      viewingExplicit: true,
      showReason: 0,
      link: null
    }
  },

  computed: {
    isSuperAdmin() {
      return this.$store.state.session.user.user_is_super_user
    },
    displayLink() {
      return !!this.isSuperAdmin && this.object.change_order_time_sent
    },
    actionMenuPosition() {
      return this.smallFormat ? 'top right' : 'bottom right'
    },
    statusWord() {
      if (this.object.change_order_status === 'd') return 'Declined by client'
      if (this.object.change_order_status === 'k') return 'Approved by client'
      return 'Waiting for approval'
    },

    selectedFile: {
      get() {
        return this.authFile ? [this.authFile] : []
      },

      async set(files) {
        // Get last file in list
        const file = files.length ? files[files.length - 1] : false

        if (!file) {
          return
        }

        this.authFile = file

        await this.$store.dispatch('ChangeOrder/partialUpdate', {
          selected: [
            {
              type: 'change_order',
              change_order_id: this.object.change_order_id,
              auth_file_id: file.file_id,
              change_order_client_has_approved: 1,
              change_order_client_approved_time: new Date().valueOf(),
              change_order_client_approved_by: this.$store.state.session.user.user_id
            }
          ]
        })

        if (this.object.change_order_status !== 'k' && file.file_id) {
          this.markBooked()
        }

        this.$emit('reload')
      }
    }
  },

  methods: {
    async retrieveLink() {
      // UrlShare
      try {
        const { set } = await this.$store.dispatch('UrlShare/search', {
          filters: {
            company_id: this.$store.state.session.company.company_id,
            url_share_object_type: 'quote',
            url_share_object_id: this.object.quote_id
          }
        })

        if (!set || !set[0]) throw Error('no results')
        this.link = set[0].url_share_url
        this.$store.dispatch('copyToClipboard', this.link)
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage || e.message
        })
      }
    },
    swapChanges() {
      this.viewingExplicit = !this.viewingExplicit
    },
    async getChanges() {
      return this.$store.dispatch('ChangeOrder/getChanges', {
        id: this.object.change_order_id
      })
    },
    async reviewChanges() {
      this.addLoading()
      this.reviewing = true
      await this.$nextTick()
      this.$refs.changeReview.open()

      const [explicit, full] = await this.getChanges()

      if (!explicit.length) {
        this.mergedExplicitChanges = {}
        this.mergedChanges = {}
      } else {
        const mergedFull = mergeChanges(...full)
        const mergedExplicit = mergeChanges(...explicit)

        this.mergedExplicitChanges = denormalizeDiffSet(mergedExplicit)
        this.mergedChanges = denormalizeDiffSet(mergedFull)
      }
      this.endLoading()

      return this
    },

    async rename() {
      const name = await this.$store.dispatch('modal/prompt', {
        message: 'Enter a new name for this change order'
      })

      await this.$store.dispatch('ChangeOrder/partialUpdate', {
        selected: [
          {
            type: 'change_order',
            change_order_id: this.object.change_order_id,
            change_order_name: name
          }
        ]
      })

      this.$emit('reload')
    },

    download() {
      if (
        this.object.auth_file_id ||
        (this.object.oClientMeta &&
          typeof this.object.oClientMeta === 'object' &&
          Object.keys(this.object.oClientMeta).length)
      ) {
        return this.$store.dispatch('ChangeOrder/downloadContract', {
          id: this.object.change_order_id
        })
      }
      return this.$store.dispatch('alert', {
        message: 'There is no authorization for that change-order.',
        error: 1
      })
    },

    async markBooked() {
      await this.$store.dispatch('ChangeOrder/markMultiple', {
        selected: [this.object],
        markAs: 'booked',
        go: false
      })

      this.$emit('reload')
    },

    async send() {
      this.$store.dispatch('alert', {
        message: 'Sending..',
        timeout: 10000
      })

      this.$emit('send')

      await c.throttle(() => {}, {
        key: 'sendingCO',
        delay: 5000
      })

      this.$emit('reload')
    },

    checkout() {
      this.$emit('checkout', this.object.change_order_id)
    },

    addAuthFile() {
      this.$refs.fileList.triggerSelector()
    },

    removeChangeOrder() {
      this.$store.dispatch('modal/confirm', {
        message: 'Are you sure you want to delete this change order?',
        subMessage: `You cannot undo this, and you will lose the changes made.
             This project will revert to the next previous change order state.`,
        actions: {
          confirm: {
            title: 'Yes, delete',
            action: async () => {
              await this.$store.dispatch('ajax', {
                path: `change_order/delete/${this.object.change_order_id}`
              })

              this.$emit('deleted', this.object.change_order_id)
            }
          }
        }
      })
    }
  },

  components: {
    // Client,
    // User,
    FileList,
    // NextSteps,
    ChangeAudit
  },

  props: {
    object: {
      type: Object,
      required: true
    },

    isCheckedOut: {
      default: false
    },

    latest: {
      default: false
    },

    editable: {
      type: Boolean,
      default: true
    }
  }
}
</script>
