export function useColorManipulator() {
  function rgbToHex(r, g, b) {
    return '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')
  }

  function hexToRgb(hex) {
    return hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16))
  }

  return {
    rgbToHex,
    hexToRgb
  }
}
