<script setup>
import { ref, computed, defineProps, watch, onBeforeMount, defineModel } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import MeterGroup from 'primevue/metergroup'
import StringField from '@/components/ui/Calculator/StringField.vue'
import CostItemHeader from './CostItem/Header.vue'
CostItemHeader.compatConfig = { MODE: 3 }
import ItemLaborCosts from '@/components/composables/ItemLaborCosts.js'
import ItemMaterialCosts from '@/components/composables/ItemMaterialCosts.js'
import CostItemEquationVariables from '@/components/composables/CostItemEquationVariables.js'
import CalculatorField from '@/components/ui/Calculator/Field.vue'
import ItemAddons from '@/components/composables/ItemAddons.js'
import ItemVariations from '@/components/composables/ItemVariations.js'
import AddonsEditor from '@/components/bodies/CostItem/AddonsEditor.vue'
AddonsEditor.compatConfig = { MODE: 3 }
import VariationsEditor from '@/components/bodies/CostItem/VariationsEditor.vue'
VariationsEditor.compatConfig = { MODE: 3 }
import CostType from '../../../imports/api/schemas/CostType.js'
import SelectionColor from '@/components/ui/SelectionColor/SelectionColor.vue'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'cost_item' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true },
  oMod: { type: Object, required: false }
})

const $store = useStore()

const refId = ref(props.refId || props.reference || 'root')
const type = ref(props.type || 'cost_item')
const store = ref(props.store || props.storeName)
const mobject = defineModel('object')

const obj = EntityComputedFields.useEntityComputedFields({
  object: mobject,
  refId,
  type,
  store
})

const {
  norm,
  object,

  cost_type_has_labor,
  cost_type_has_materials,
  cost_type_is_subcontracted,
  cost_matrix_materials_cost_net,
  cost_matrix_labor_cost_net,
  cost_matrix_aggregate_cost_net,
  cost_matrix_rate_net,
  cost_matrix_markup_net,
  unitOfMeasureId,
  cost_type_desc,
  aoProperties,
  file_ids,
  aoImages,
  vendorId,
  purchaseUnitOfMeasureId,
  unit_of_measure_name,
  purchase_unit_of_measure_name,
  cost_type_materials_purchase_qty_per_unit,
  cost_type_material_waste_factor_net,
  cost_matrix_rate_net_equation,
  cost_matrix_materials_cost_net_equation,
  cost_type_materials_purchase_qty_per_unit_equation,
  cost_type_material_waste_factor_net_equation,
  cost_matrix_labor_cost_net_equation,
  cost_type_qty_equation,
  asDimensionsUsed,
  weightUnitOfMeasureId,
  cost_type_static_materials_cost_net,
  cost_type_static_materials_cost_net_equation,
  cost_type_static_labor_cost_net,
  cost_type_static_labor_cost_net_equation,
  cost_type_minimum_labor_cost_net,
  cost_type_minimum_labor_cost_net_equation,
  cost_type_minimum_materials_cost_net,
  cost_type_minimum_materials_cost_net_equation,
  cost_type_weight_per_purchase_unit_net,
  cost_type_weight_per_purchase_unit_net_equation,
  cost_type_minimum_qty_net,
  cost_type_minimum_qty_net_equation,
  cost_type_minimum_price_net,
  cost_type_minimum_price_net_equation,
  taxId,
  cost_type_sku,
  cost_type_production_notes,
  cost_type_budget_code,
  parent_cost_type_id,
  laborTypeId,
  stageId,
  tradeTypeId,
  item_client_has_approved,
  cost_type_name,
  cost_type_is_parent,

  addons,
  oVariations,
  oMeta,
  cost_type_is_fee,

  // If it is a cost_type these values will not exist
  // and need a reactive default fallback
  cost_item_price_net = ref(0),
  cost_item_is_optional = ref(0),
  cost_item_is_included = ref(1),
  cost_item_qty_net_base = ref(1),
  assignee_ids = ref([]),
  oInputRequired = ref({})
} = obj

const isCostItem = computed(() => !oMeta.value?.itemType || oMeta.value?.itemType === 'costItem')
const isGalleryItem = computed(() => oMeta.value?.itemType && oMeta.value?.itemType === 'gallery')
const isTextItem = computed(() => oMeta.value?.itemType && oMeta.value?.itemType === 'text')
const isMediaItem = computed(() => isGalleryItem.value || isTextItem.value)

onBeforeMount(async () => {
  // When editing a cost_type out of a quote context, you will want to edit
  // the cost_type based on the 'mod' of that quote so taht the numbers are the same
  // between the cost item and cost type. If none is provided make sure the item is
  // re-moded to the mod of the company
  if (
    type.value === 'cost_type' &&
    (object.value.cost_type_is_indexed || object.value.labor_type_is_indexed)
  ) {
    const modChanges = CostType.reMod(
      object.value,
      props.oMod || $store.getters.defaultMod,
      object.value.oMod ?? {}
    ).changes

    if ($store.state?.[store.value]?.normalized?.[refId.value]) {
      $store.commit({
        type: `${store.value}/ADD_NORMALIZED`,
        object: {
          [refId.value]: {
            ...$store.state[store.value].normalized[refId.value],
            ...modChanges
          }
        }
      })

      // reMod shouldn't need to be audited again
      // await dispatch('auditDependencies', { refId: refId.value, immediate: true, force: true })

      const changeManager = await $store.dispatch(`${store.value}/verifyChangeManager`, {
        refId: refId.value
      })
      $store.commit({
        type: `${store.value}/SET_ORIGINAL_CHANGE_MANAGER`,
        changeManager,
        normalized: { ...norm.value }
      })
    } else {
      mobject.value = {
        ...mobject.value,
        ...modChanges
      }
    }
  }
})

const {
  laborRate,
  crewSize,
  hours,
  minutes,
  seconds,

  isLtAutoCost,
  laborFormatter
} = ItemLaborCosts.useItemLaborCosts({
  refId: refId.value,
  store: store.value,
  type: type.value,

  $store,
  ...obj
})

const {
  isEachD: isEach,
  isEachD,
  isEachP,
  wastage,
  wastageTerm,
  useImperial,
  pricePerPurchaseUnit,
  unitsSame
} = ItemMaterialCosts.useItemMaterialCosts({
  cost_type_materials_purchase_qty_per_unit,
  unit_of_measure_name,
  unitOfMeasureId,
  cost_type_material_waste_factor_net,
  cost_matrix_materials_cost_net,
  purchase_unit_of_measure_name,
  purchaseUnitOfMeasureId
})

const { getCalculatorVariablesByRefId, possibleDimensions } =
  CostItemEquationVariables.useCostItemEquationVariables({
    norm: obj.norm
  })
const variables = computed(() =>
  type.value === 'cost_item'
    ? getCalculatorVariablesByRefId(refId.value, unitOfMeasureId.value, false)
    : possibleDimensions.value
)
const hasQtyEquation = computed(() => c.isEquation(cost_type_qty_equation.value, variables.value))

const profit = computed({
  get() {
    return cost_matrix_rate_net.value - cost_matrix_aggregate_cost_net.value
  },
  set(pr) {
    cost_matrix_rate_net.value = cost_matrix_aggregate_cost_net.value + pr
  }
})

const margin = computed({
  get() {
    return c.markupToMargin(cost_matrix_markup_net.value) * 100
  },
  set(mr) {
    cost_matrix_markup_net.value = c.marginToMarkup(mr / 100)
  }
})

const attrs = computed({
  get() {
    return [...(aoProperties.value || []), ['', '']]
  },
  set(props) {
    aoProperties.value = props.filter(([a, b]) => a || b)
  }
})
const handleAttr = (attrIndex, valIndex, val) => {
  const arr = [...attrs.value]
  if (!arr[attrIndex]) arr[attrIndex] = ['', '']
  arr[attrIndex] = [...arr[attrIndex]]
  arr[attrIndex][valIndex] = val

  attrs.value = arr
}

const assigneeType = computed(() => `${assignee_ids.value?.[0] ?? ''}`.split(':')?.[0] ?? 'user')
const assigneeId = computed(() => `${assignee_ids.value?.[0] ?? ''}`.split(':')?.[1] ?? '')
const assigneeName = ref('')
watch(
  [assigneeId, assigneeType],
  async () => {
    if (!assigneeType.value || !assigneeId.value) return

    const name = await $store.dispatch(`${c.titleCase(assigneeType.value)}/getName`, {
      id: assigneeId.value
    })

    assigneeName.value = name || c.titleCase(assigneeType.value)
  },
  { immediate: true }
)

const addonTypeChoice = ref(null)
const { isAddonItem } = ItemAddons.useItemAddons({
  object: mobject,
  type,
  refId,
  store,
  autoload: false
})

const { isVariationItem } = ItemVariations.useItemVariations({
  object: mobject,
  type,
  refId,
  store,
  autoload: false
})

if (isAddonItem.value) addonTypeChoice.value = 'addon'
if (isVariationItem.value) addonTypeChoice.value = 'variation'

const forceShowAddonOptions = ref(false)
const hasVariationsOrAddons = computed({
  get() {
    return isAddonItem.value || isVariationItem.value || forceShowAddonOptions.value
  },
  set(b) {
    forceShowAddonOptions.value = b
  }
})

const isOptionsInitialized = computed(() => {
  if (addons.value?.length || oVariations.value?.items?.length) return true
  return false
})

// const costType = computed({
//   get() {},
//   set() {}
// })
// const itemType = computed({
//   get() {},
//   set() {}
// })

const inCompanyScope = computed(() => $store.state.session.scope.company)

const costMeter = computed(() => [
  {
    label: 'Materials',
    color: c.getCssColor('materials'),
    value: cost_type_is_subcontracted.value ? 0 : cost_matrix_materials_cost_net.value
  },
  {
    label: 'Labor',
    color: c.getCssColor('labor'),
    value: cost_type_is_subcontracted.value ? 0 : cost_matrix_labor_cost_net.value
  },
  {
    label: 'Subcontractor',
    color: c.getCssColor('subcontractor'),
    value: cost_type_is_subcontracted.value ? cost_matrix_materials_cost_net.value : 0
  },
  {
    label: 'Profit',
    color: c.getCssColor('profit'),
    value: cost_matrix_rate_net.value - cost_matrix_aggregate_cost_net.value
  }
])

const accordionIndex = ref([])

const accordionPt = computed(() => ({
  root: () => ({}),
  tab: () => ({}),
  accordionTab: () => ({
    root: ({ context }) => ({
      class: [
        'border rounded-sm my-4',
        {
          'bg-surface-200/50 border-transparent shadow': context.active,
          'border-transparent': !context.active
        }
      ]
    }),
    header: () => ({
      class: ['hover:bg-surface-300/30 transition border-b-0 py-0 px-2']
    }),
    headerIcon: () => ({
      class: [
        {
          hidden: true
        }
      ]
    }),
    headerAction: () => ({
      class: ['px-4 py-4 min-h-20 text-lg text-surface-900']
    }),
    content: () => ({
      class: ['!px-4']
    })
  }),
  hooks: () => ({})
}))

const livePriceImage = computed(() =>
  aoImages.value.length
    ? `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${aoImages.value[0]}`
    : null
)

const showPlus = ref(0)
const forceImageOpen = ref(file_ids.value?.length)
const refFileList = ref(null)
const refLaborChoose = ref(null)

watch(
  file_ids,
  (ids) => {
    if (ids?.length) forceImageOpen.value = 1
  },
  { immediate: true }
)

const labelClass =
  'text-surface-800 flex justify-start items-center gap-4 font-medium basis-3/5 grow-0 shrink-1 text-lg leading-tight pr-2'
const numberClass = 'text-surface-600 text-lg tabular-nums text-right'
const rowClass = 'flex justify-between items-center hover:bg-surface-200/20 h-[70px]'
const chooseClass =
  'flex flex-nowrap border-2 border-transparent hover:border-blue-print rounded-sm p-1 cursor-pointer'
const valueClass = 'flex flex-nowrap justify-end items-center !text-surface-600 text-lg'
const adjunctButtonClass =
  'border border-transparent rounded-sm bg-transparent hover:bg-surface-200 hover:border-pitch-black hover:text-pitch-black text-pitch-black/60 text-xs px-1 py-0.5'
const dashIconClass = 'rounded-sm p-2 size-6 border-2'
const dashIconDisabledClass = 'text-surface-300 border-surface-300 !border'
const fieldGroupClass = 'divide-y divide-surface-300'
const showMaterialsOptions = ref(0)
const showLaborOptions = ref(0)

const requestColorIndex = computed(
  () => oInputRequired.value?.inputs?.findIndex((inp) => inp.id === 'clr') ?? -1
)
const requestColorInput = computed({
  get() {
    return oInputRequired.value?.inputs?.find((inp) => inp.id === 'clr') ? 1 : 0
  },
  set(requestColor) {
    const ir = oInputRequired.value ?? {}

    if (requestColor && !requestColorInput.value) {
      ;(ir.inputs ??= []).push({
        type: 'color',
        id: 'clr',
        required: 0,
        message: '',
        input: {
          text: '',
          data: {},
          setBy: null // null|company|client
        }
      })
    } else if (!requestColor && requestColorInput.value) {
      ir.inputs = ir.inputs.filter((inp) => inp.id !== 'clr')
    }

    oInputRequired.value = ir
  }
})

const requestTextIndex = computed(
  () => oInputRequired.value?.inputs?.findIndex((inp) => inp.id === 'txt') ?? -1
)
const requestTextInput = computed({
  get() {
    return oInputRequired.value?.inputs?.find((inp) => inp.id === 'txt') ? 1 : 0
  },
  set(requestText) {
    const ir = oInputRequired.value ?? {}

    if (requestText && !requestTextInput.value) {
      ;(ir.inputs ??= []).push({
        type: 'text',
        id: 'txt',
        required: 0,
        message: '',
        input: {
          text: '',
          data: {},
          setBy: null // null|company|client
        }
      })
    } else if (!requestText && requestTextInput.value) {
      ir.inputs = ir.inputs.filter((inp) => inp.id !== 'txt')
    }

    oInputRequired.value = ir
  }
})

const showAdvanced = ref({
  matBase: !!cost_type_static_materials_cost_net.value,
  matMin: !!cost_type_minimum_materials_cost_net.value,
  matWeight: !!cost_type_weight_per_purchase_unit_net.value,

  labBase: !!cost_type_static_labor_cost_net.value,
  labMin: !!cost_type_minimum_labor_cost_net.value,

  minSpend: !!cost_type_minimum_price_net.value,
  minQty: !!cost_type_minimum_qty_net.value,
  itemTax: !!taxId.value,
  itemSku: !!cost_type_sku.value
})

const hasMinOrBaseLaborSet = computed(() => {
  if (!cost_type_has_labor.value) return 0
  return !c.eq(cost_type_minimum_labor_cost_net.value + cost_type_static_labor_cost_net.value, 0, 2)
})
const hasMinOrBaseMaterialsSet = computed(() => {
  if (!cost_type_has_materials.value) return 0
  return !c.eq(
    cost_type_minimum_materials_cost_net.value + cost_type_static_materials_cost_net.value,
    0,
    2
  )
})
// const hasMinQtyOrSpendSet = computed(() => {
//   return !c.eq(cost_type_minimum_qty_net.value + cost_type_minimum_price_net.value, 0, 2)
// })
// const hasMinOrBaseSet = computed(() => {
//   return hasMinOrBaseLaborSet.value || hasMinOrBaseMaterialsSet.value || hasMinQtyOrSpendSet.value
// })
const hasMinOrBaseCostSpend = computed(() => {
  return (
    hasMinOrBaseLaborSet.value ||
    hasMinOrBaseMaterialsSet.value ||
    !c.eq(cost_type_minimum_price_net.value, 0, 2)
  )
})

const refUpgradesModal = ref(null)
const handleOptionsManager = () => {
  refUpgradesModal.value.open()
}
const handleCloseRequest = () => {
  refUpgradesModal.value.close()
}

const refOptionsEditor = ref(null)
const handleOptionsSave = () => refOptionsEditor.value.commitAndClose()
const handleOptionsCancel = () => {
  refOptionsEditor.value.abort()
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <template v-if="cost_type_is_parent">
      <div class="w-full h-full flex flex-col gap-2 justify-center items-center py-20">
        <span class="text-lg font-medium">
          <font-awesome-icon icon="box-open-full" /> Name your category</span
        >
        <div class="flex gap-4 items-center justify-center">
          <font-awesome-icon icon="folder-open" size="xl" />
          <StringField
            v-model="cost_type_name"
            class="text-xl font-medium"
            placeholder="Enter your category name here..."
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <div v-if="showTitle">
          <CostItemHeader v-bind="props" v-model:object="mobject" />
        </div>
        <div class="flex flex-col lg:flex-row justify-between items-stretch gap-4">
          <div class="flex flex-col items-start justify-start gap-4">
            <StringField
              :class="[
                'text-md h-fit !min-h-[3.4rem] w-full !whitespace-pre-line',
                {
                  'bg-surface-200/50 text-surface-400 !border-transparent': !cost_type_desc
                }
              ]"
              multiline
              placeholder="Add a description"
              :validate="{ required: true }"
              v-model="cost_type_desc"
            />

            <div class="flex flex-col items-start justify-start gap-1">
              <div
                :class="['flex justify-start items-center gap-6 w-[400px]']"
                v-for="(attr, index) in attrs"
                :key="index"
              >
                <font-awesome-icon
                  icon="fas fa-circle"
                  :class="[
                    '',
                    {
                      'text-surface-300/30 w-[10px]': !attr[0] && !attr[1],
                      'text-surface-700 w-[10px]': attr[0] || attr[1]
                    }
                  ]"
                />

                <div class="flex justify-start gap-0">
                  <StringField
                    :class="[
                      'text-sm min-h-8 ml-4 whitespace-break-spaces leading-relaxed',
                      {
                        'bg-surface-200/50 text-surface-400 w-[170px] !border-transparent':
                          !attr[0],
                        'w-fit font-medium': attr[0]
                      }
                    ]"
                    placeholder="Add attributes/traits"
                    :validate="{ required: true }"
                    :value="attr[0]"
                    @input="(val) => handleAttr(index, 0, val)"
                  />
                  <span v-if="attr[0] && attr[1]">:</span>
                </div>

                <StringField
                  :class="[
                    'text-sm min-h-8 ml-4 whitespace-break-spaces leading-relaxed',
                    {
                      'bg-surface-200/50 text-surface-400 w-[50px] !border-transparent': !attr[1],
                      'w-fit ': attr[1]
                    }
                  ]"
                  placeholder=""
                  :validate="{ required: true }"
                  :value="attr[1]"
                  @input="(val) => handleAttr(index, 1, val)"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-y-2 w-full max-w-64">
            <div
              v-if="livePriceImage && file_ids.length <= 1"
              class="w-full aspect-square rounded bg-center bg-no-repeat bg-cover"
              :style="{ backgroundImage: `url(${livePriceImage})` }"
            />
            <div class="grow-1 h-full">
              <div
                @mouseover="showPlus = 1"
                @mouseout="showPlus = 0"
                @click="
                  () => {
                    if (!forceImageOpen) {
                      forceImageOpen = true
                      $nextTick(() => {
                        refFileList.showAddOptions = true
                      })
                    }
                  }
                "
                :class="[
                  'h-full min-h-20 lg:min-w-[200px] rounded-md flex gap-2 cursor-pointer relative transition',
                  {
                    'bg-surface-200/50 hover:bg-surface-300/50 !border-transparent':
                      !forceImageOpen && !livePriceImage
                  }
                ]"
              >
                <font-awesome-icon
                  icon="camera"
                  size="xl"
                  class="text-surface-400 self-center justify-self-center w-full"
                  v-if="!showPlus && !forceImageOpen && !livePriceImage"
                />
                <font-awesome-icon
                  icon="fas fa-plus"
                  size="xl"
                  class="text-surface-400 self-center justify-self-center w-full"
                  v-else-if="showPlus && !forceImageOpen && !livePriceImage"
                />
                <FileList
                  v-model="file_ids"
                  heroFirst
                  idList
                  ref="refFileList"
                  v-else
                  class="max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        :pt="accordionPt"
        v-model:activeIndex="accordionIndex"
        expandIcon="pi pi-plus"
        collapseIcon="pi pi-minus"
        multiple
      >
        <AccordionTab v-if="cost_type_is_fee">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(0)
                    }
                  ]"
                />
                Fee pricing
              </div>

              <div class="flex flex-row-reverse md:flex-row justify-end md:justify-start gap-2">
                <div class="flex flex-col items-end justify-center w-fit">
                  <div
                    class="flex flex-row items-center justify-end flex-nowrap white-space-nowrap"
                  >
                    <CalculatorFormatted
                      v-if="type === 'cost_item'"
                      format="currency"
                      v-model="cost_item_price_net"
                      disabled
                      read-only
                      class="text-right !ml-2"
                      :autoMultiline="false"
                    />
                  </div>

                  <MeterGroup :value="costMeter" :max="cost_matrix_rate_net" class="w-full">
                    <template #label><span class="hidden"></span></template>
                  </MeterGroup>
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-col justify-start items-stretch gap-1 py-2">
            <!-- Material costs -->
            <!-- Material costs field -->
            <div :class="[rowClass, '!h-fit py-2']">
              <div :class="[labelClass, '!basis-2/5']">Fee costs</div>

              <!--            <Choose schema="cost_type:vendor_id" v-model="vendorId" placeholder="Choose supplier" v-if="!cost_type_is_subcontracted">-->
              <!--              <template #default="{ text }">-->
              <!--                <div-->
              <!--                  class="text-xs whitespace-nowrap flex flex-nowrap border-2 border-transparent hover:border-blue-print rounded-sm p-1 cursor-pointer"-->
              <!--                > {{ text }}-->
              <!--                </div>-->
              <!--              </template>-->
              <!--            </Choose>-->
              <div :class="valueClass">
                <CalculatorField
                  force-inline
                  show-pocket
                  numeric
                  auto-multiline
                  :width="350"
                  :height="50"
                  :variables="variables"
                  v-model="cost_type_qty_equation"
                />
              </div>
            </div>

            <!--            &lt;!&ndash; Profit field &ndash;&gt;-->
            <!--            <div :class="[rowClass, 'border-t border-pitch-black']">-->
            <!--              <div :class="labelClass">Profit</div>-->

            <!--              <div class="flex flex-row items-center justify-center flex-grow">-->
            <!--                <CalculatorFormatted-->
            <!--                  :class="numberClass"-->
            <!--                  style="max-width: 100px"-->
            <!--                  format="number"-->
            <!--                  v-model="margin"-->
            <!--                  v-model:equation="margin_equation"-->
            <!--                />-->
            <!--                %-->
            <!--              </div>-->

            <!--              <div class="text-success value flex flex-nowrap justify-end items-center">-->
            <!--                <CalculatorFormatted-->
            <!--                  format="$"-->
            <!--                  v-model="profit"-->
            <!--                  v-model:equation="profit_equation"-->
            <!--                  :class="numberClass"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            &lt;!&ndash; Price per unit &ndash;&gt;-->
            <!--            <div :class="[rowClass, 'border-t border-pitch-black']">-->
            <!--              <div :class="labelClass">Fee price</div>-->

            <!--              <div :class="valueClass">-->
            <!--                <CalculatorFormatted-->
            <!--                  format="$"-->
            <!--                  id="CATCHING"-->
            <!--                  v-model="cost_matrix_rate_net"-->
            <!--                  v-model:equation="cost_matrix_rate_net_equation"-->
            <!--                  :class="numberClass"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->

            <div :class="[rowClass]" v-if="showAdvanced.minSpend">
              <div :class="[labelClass, '!text-deep-red-500']">
                What is the minimum fee for your customer on this item?
              </div>

              <div :class="valueClass">
                <CalculatorFormatted
                  :class="numberClass"
                  format="$"
                  v-model="cost_type_minimum_price_net"
                  v-model:equation="cost_type_minimum_price_net_equation"
                />
                total
              </div>
            </div>

            <div :class="[rowClass]" v-if="showAdvanced.itemTax">
              <div :class="[labelClass]">
                Define a tax for this item that will override default project tax settings
              </div>

              <div :class="valueClass">
                <Choose
                  schema="cost_type:tax_id"
                  v-model="taxId"
                  placeholder="Use project tax setting"
                >
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">
                      {{ text || 'Use project tax setting' }}
                    </div>
                  </template>
                </Choose>
              </div>
            </div>

            <div
              :class="[
                rowClass,
                '!justify-start !items-start gap-1',
                'border-t border-pitch-black'
              ]"
              v-if="!showAdvanced.minSpend || !showAdvanced.itemTax"
            >
              <div
                v-tooltip="
                  'When the total price for this item doesn\'t hit the minimum threshold set by you, the price will be bumped up automatically'
                "
              >
                <Btn
                  unstyled
                  :class="adjunctButtonClass"
                  size="sm"
                  @click="() => (showAdvanced.minSpend = true)"
                  v-if="!showAdvanced.minSpend"
                >
                  <font-awesome-icon icon="square-plus" size="sm" />
                  Add minimum spend
                </Btn>
              </div>
              <div
                v-tooltip="
                  'By default every item adopts the project-level tax settings. You can add item-specific sales tax settings to override the project-level settings with this option.'
                "
              >
                <Btn
                  unstyled
                  :class="adjunctButtonClass"
                  size="sm"
                  @click="() => (showAdvanced.itemTax = true)"
                  v-if="!showAdvanced.itemTax"
                >
                  <font-awesome-icon icon="square-plus" size="sm" />
                  Add item-specific sales tax
                </Btn>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab v-if="isCostItem && !cost_type_is_fee">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(0)
                    }
                  ]"
                />
                Unit pricing
              </div>

              <div class="flex flex-row-reverse md:flex-row justify-end md:justify-start gap-2">
                <div class="flex flex-col items-end justify-center w-fit">
                  <div
                    class="flex flex-row items-center justify-end flex-nowrap white-space-nowrap"
                  >
                    <CalculatorFormatted
                      format="$"
                      v-model="cost_matrix_rate_net"
                      v-model:equation="cost_matrix_rate_net_equation"
                      class="text-right"
                    />
                    <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                      <template #default="{ text }">
                        <div :class="chooseClass">/ {{ text }}</div>
                      </template>
                    </Choose>
                  </div>

                  <MeterGroup :value="costMeter" :max="cost_matrix_rate_net" class="w-full">
                    <template #label><span class="hidden"></span></template>
                  </MeterGroup>
                </div>

                <div class="flex gap-2">
                  <div
                    v-if="hasMinOrBaseCostSpend"
                    v-tooltip="
                      'This item either has a minimum/base cost, minimum spend, and/or minimum quantity set on it, that will affect the calculation of the item, and may make it seem like things don\'t add up - but they do!'
                    "
                  >
                    <font-awesome-icon
                      :class="[
                        dashIconClass,
                        {
                          '!border-deep-red-500 !text-deep-red-500': hasMinOrBaseCostSpend
                        }
                      ]"
                      icon="lightbulb-dollar"
                      size="lg"
                    />
                  </div>

                  <div
                    v-tooltip="
                      cost_type_has_materials
                        ? 'This item includes materials costs'
                        : 'This item does not include materials costs'
                    "
                  >
                    <font-awesome-icon
                      :class="[
                        dashIconClass,
                        {
                          'border-materials text-materials': cost_type_has_materials,
                          'border-surface-300 text-surface-300': !cost_type_has_materials
                        }
                      ]"
                      icon="box-taped"
                      size="lg"
                    />
                  </div>
                  <div
                    v-tooltip="
                      cost_type_has_labor
                        ? 'This item includes labor costs'
                        : 'This item does not include labor costs'
                    "
                  >
                    <font-awesome-icon
                      icon="user-helmet-safety"
                      :class="[
                        dashIconClass,
                        {
                          'border-labor text-labor': cost_type_has_labor,
                          [dashIconDisabledClass]: !cost_type_has_labor
                        }
                      ]"
                      size="lg"
                    />
                  </div>
                  <div
                    v-if="cost_type_is_subcontracted"
                    v-tooltip="
                      cost_type_is_subcontracted
                        ? 'This items labor costs are subcontracted'
                        : 'This item is not subcontracted'
                    "
                  >
                    <font-awesome-icon
                      icon="truck"
                      :class="[
                        dashIconClass,
                        {
                          'text-subcontractor border-subcontractor': cost_type_is_subcontracted,
                          [dashIconDisabledClass]: !cost_type_is_subcontracted
                        }
                      ]"
                      size="lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-col justify-start items-stretch gap-1 py-2">
            <!-- Material costs -->
            <!-- Material costs field -->
            <div :class="rowClass">
              <div :class="labelClass">
                <Checkbox
                  v-model="cost_type_has_materials"
                  name="costType"
                  value="Materials costs"
                  inputId="mat"
                  :trueValue="1"
                  :falseValue="0"
                />

                <font-awesome-icon
                  icon="box-taped"
                  :class="[
                    {
                      'text-materials': cost_type_has_materials
                    }
                  ]"
                />
                Materials costs

                <div v-tooltip="'Show detailed materials options...'">
                  <Btn
                    unstyled
                    class="ml-3"
                    @click="showMaterialsOptions = !showMaterialsOptions"
                    v-if="!cost_type_is_subcontracted"
                  >
                    <font-awesome-icon
                      :class="{
                        'text-deep-red-500': hasMinOrBaseMaterialsSet
                      }"
                      :icon="showMaterialsOptions ? 'square-minus' : 'square-plus'"
                      size="lg"
                    />
                  </Btn>
                </div>
              </div>

              <!--            <Choose schema="cost_type:vendor_id" v-model="vendorId" placeholder="Choose supplier" v-if="!cost_type_is_subcontracted">-->
              <!--              <template #default="{ text }">-->
              <!--                <div-->
              <!--                  class="text-xs whitespace-nowrap flex flex-nowrap border-2 border-transparent hover:border-blue-print rounded-sm p-1 cursor-pointer"-->
              <!--                > {{ text }}-->
              <!--                </div>-->
              <!--              </template>-->
              <!--            </Choose>-->
              <div
                :class="valueClass"
                v-if="!cost_type_is_subcontracted && cost_type_has_materials"
              >
                <CalculatorFormatted
                  format="$"
                  v-model="cost_matrix_materials_cost_net"
                  v-model:equation="cost_matrix_materials_cost_net_equation"
                  :class="numberClass"
                />

                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>
            <!-- Material costs additional details -->
            <div
              v-if="showMaterialsOptions && !cost_type_is_subcontracted"
              :class="fieldGroupClass"
            >
              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">Default supplier / vendor</div>

                <div :class="valueClass">
                  <Choose
                    schema="cost_type:vendor_id"
                    v-model="vendorId"
                    placeholder="Choose supplier"
                    v-if="inCompanyScope && !cost_type_is_subcontracted"
                  >
                    <template #default="{ text }">
                      <div :class="chooseClass">{{ text }}</div>
                    </template>
                  </Choose>
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">How do you purchase this product?</div>

                <div :class="valueClass">
                  <Choose
                    :order="[['unit_of_measure_name', 'asc']]"
                    ref="punitChoose"
                    v-model="purchaseUnitOfMeasureId"
                    schema="cost_type:purchase_unit_of_measure_id"
                  >
                    <template #default>
                      <div :class="chooseClass">
                        By the
                        {{ isEachP ? 'individual unit (each)' : purchase_unit_of_measure_name }}
                      </div>
                    </template>
                  </Choose>
                </div>
              </div>

              <div v-if="!unitsSame" :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">
                  How many
                  {{ isEachD ? 'individual units (each)' : unit_of_measure_name }}
                  are in one {{ purchase_unit_of_measure_name }}?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    :format="useImperial ? 'imperial' : 'number'"
                    v-model="cost_type_materials_purchase_qty_per_unit"
                    v-model:equation="cost_type_materials_purchase_qty_per_unit_equation"
                  />
                </div>
              </div>

              <div v-if="!unitsSame" :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">
                  How much does every {{ purchase_unit_of_measure_name }} cost you?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="$"
                    v-model="pricePerPurchaseUnit"
                  />
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">
                  How much do you lose to wastage, like {{ wastageTerm }}?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="number"
                    v-model="wastage"
                    v-model:equation="cost_type_material_waste_factor_net_equation"
                  />%
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']" v-if="showAdvanced.matBase">
                <div :class="[labelClass, '!text-deep-red-500']">
                  What is the base cost for this materials item?
                  <span class="text-xs text-surface-500"
                    >(total material cost = base cost + (material cost x qty))</span
                  >
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="$"
                    v-model="cost_type_static_materials_cost_net"
                    v-model:equation="cost_type_static_materials_cost_net_equation"
                  />
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']" v-if="showAdvanced.matMin">
                <div :class="[labelClass, '!text-deep-red-500']">
                  What is the minimum materials cost for this item?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="$"
                    v-model="cost_type_minimum_materials_cost_net"
                    v-model:equation="cost_type_minimum_materials_cost_net_equation"
                  />
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']" v-if="showAdvanced.matWeight">
                <div :class="[labelClass]">
                  What is the weight of this item per
                  {{ purchase_unit_of_measure_name || unit_of_measure_name }}?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="number"
                    v-model="cost_type_weight_per_purchase_unit_net"
                    v-model:equation="cost_type_weight_per_purchase_unit_net_equation"
                  />

                  <Choose
                    :order="[['unit_of_measure_name', 'asc']]"
                    ref="refWeightUnit"
                    :filters="{
                      dimension_measure_type: 'weight'
                    }"
                    :default="$store.getters.isMetric ? 'kg' : 'lbs'"
                    v-model="weightUnitOfMeasureId"
                    schema="cost_type:weight_unit_of_measure_id"
                  >
                    <template #default="{ text }">
                      <div :class="chooseClass">
                        {{ text }}
                      </div>
                    </template>
                  </Choose>

                  per {{ purchase_unit_of_measure_name || unit_of_measure_name }}
                </div>
              </div>

              <div :class="[rowClass, 'ml-10 !justify-start !items-start gap-1']">
                <div
                  v-tooltip="
                    'This is for setup costs, or base costs that are NOT multiplied by the quantity. This acts as a one-time charge per item.'
                  "
                >
                  <Btn
                    unstyled
                    :class="adjunctButtonClass"
                    size="sm"
                    @click="() => (showAdvanced.matBase = true)"
                    v-if="!showAdvanced.matBase"
                  >
                    <font-awesome-icon icon="square-plus" size="sm" />
                    Add base materials cost
                  </Btn>
                </div>
                <div
                  v-tooltip="
                    'When the total materials costs for this item doesn\'t hit the minimum threshold set by you, it will be bumped up automatically'
                  "
                >
                  <Btn
                    unstyled
                    :class="adjunctButtonClass"
                    size="sm"
                    @click="() => (showAdvanced.matMin = true)"
                    v-if="!showAdvanced.matMin"
                  >
                    <font-awesome-icon icon="square-plus" size="sm" />
                    Add minimum materials cost
                  </Btn>
                </div>
                <div
                  v-tooltip="
                    'This value is useful to understand shipping costs, and can be used in generating a fee item elsewhere in the estimate'
                  "
                >
                  <Btn
                    unstyled
                    :class="adjunctButtonClass"
                    size="sm"
                    @click="() => (showAdvanced.matWeight = true)"
                    v-if="!showAdvanced.matWeight"
                  >
                    <font-awesome-icon icon="square-plus" size="sm" />
                    Add item weight
                  </Btn>
                </div>
              </div>
            </div>

            <!-- Labor costs -->
            <!-- Labor costs field -->
            <div :class="rowClass">
              <div :class="labelClass">
                <Checkbox
                  v-model="cost_type_has_labor"
                  value="Labor costs"
                  name="costType"
                  inputId="lab"
                  :trueValue="1"
                  :falseValue="0"
                />

                <font-awesome-icon
                  icon="user-helmet-safety"
                  :class="[
                    {
                      'text-labor': cost_type_has_labor
                    }
                  ]"
                />
                Labor costs

                <div v-tooltip="'Show detailed labor options...'">
                  <Btn
                    :class="{
                      '!text-deep-red-500': hasMinOrBaseLaborSet
                    }"
                    unstyled
                    class="ml-3"
                    @click="showLaborOptions = !showLaborOptions"
                    v-if="!cost_type_is_subcontracted"
                  >
                    <font-awesome-icon
                      :icon="showLaborOptions ? 'square-minus' : 'square-plus'"
                      size="lg"
                    />
                  </Btn>
                </div>
              </div>

              <div :class="valueClass" v-if="!cost_type_is_subcontracted && cost_type_has_labor">
                <CalculatorFormatted
                  format="$"
                  v-model="cost_matrix_labor_cost_net"
                  v-model:equation="cost_matrix_labor_cost_net_equation"
                  :class="numberClass"
                />

                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>
            <!-- Labor costs additional details -->
            <div v-if="showLaborOptions && !cost_type_is_subcontracted" :class="fieldGroupClass">
              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">
                  How much time does it take to complete one
                  {{ isEach ? 'individual unit (each)' : unit_of_measure_name }}
                  of this item?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted :class="numberClass" format="number" v-model="hours" />h
                  <CalculatorFormatted :class="numberClass" format="number" v-model="minutes" />m
                  <CalculatorFormatted :class="numberClass" format="number" v-model="seconds" />s
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">What kind of trade or laborer completes this work?</div>

                <a
                  class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
                  @click="refLaborChoose.open()"
                  >{{ labor_type_name
                  }}<Icon
                    icon="svg:autocostWhite"
                    class="inline ml-1 bg-matcha-500 rounded-full aspect-square h-6 p-0.5"
                    v-if="isLtAutoCost"
                    v-tooltip="
                      'This is an AutoCost rate managed by Bolster. It is costed based on your location and updated regularly.'
                    "
                /></a>
                <div :class="valueClass">
                  <Choose
                    ref="refLaborChoose"
                    placeholder="Choose a labor type"
                    v-model="laborTypeId"
                    :formatter="laborFormatter"
                    schema="cost_type:labor_type_id"
                  >
                    <template #default="{ text }">
                      <div :class="chooseClass">{{ text }}</div>
                    </template>
                  </Choose>
                </div>
              </div>
              <div :class="[rowClass, 'ml-10']" v-if="laborTypeId">
                <div :class="[labelClass]">
                  How much does a {{ labor_type_name || 'laborer for this item' }} cost you per
                  hour?
                </div>

                <div :class="[valueClass, 'inline']">
                  <CalculatorFormatted
                    :class="[numberClass, '!mr-1']"
                    format="$"
                    v-model="laborRate"
                  />
                  per person, per hour
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']">
                <div :class="[labelClass]">
                  How big is the crew that usually completes this work?
                </div>

                <div :class="[valueClass, 'inline']">
                  <CalculatorFormatted
                    :class="[numberClass, '!mr-1']"
                    format="number"
                    v-model="crewSize"
                  />
                  {{ crewSize === 1 ? 'person' : 'people' }}
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']" v-if="showAdvanced.labBase">
                <div :class="[labelClass, '!text-deep-red-500']">
                  What is the base cost for this labor item?
                  <span class="text-xs text-surface-500"
                    >(total labor cost = base cost + (labor cost x qty))</span
                  >
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="$"
                    v-model="cost_type_static_labor_cost_net"
                    v-model:equation="cost_type_static_labor_cost_net_equation"
                  />
                </div>
              </div>

              <div :class="[rowClass, 'ml-10']" v-if="showAdvanced.labMin">
                <div :class="[labelClass, '!text-deep-red-500']">
                  What is the minimum labor cost for this item?
                </div>

                <div :class="valueClass">
                  <CalculatorFormatted
                    :class="numberClass"
                    format="$"
                    v-model="cost_type_minimum_labor_cost_net"
                    v-model:equation="cost_type_minimum_labor_cost_net_equation"
                  />
                </div>
              </div>

              <div :class="[rowClass, 'ml-10 !justify-start !items-start gap-1']">
                <div
                  v-tooltip="
                    'This is for setup costs, or base costs that are NOT multiplied by the quantity. This acts as a one-time charge per item.'
                  "
                >
                  <Btn
                    unstyled
                    :class="adjunctButtonClass"
                    size="sm"
                    @click="() => (showAdvanced.labBase = true)"
                    v-if="!showAdvanced.labBase"
                  >
                    <font-awesome-icon icon="square-plus" size="sm" />
                    Add base labor cost
                  </Btn>
                </div>
                <div
                  v-tooltip="
                    'When the total labor costs for this item doesn\'t hit the minimum threshold set by you, it will be bumped up automatically'
                  "
                >
                  <Btn
                    unstyled
                    :class="adjunctButtonClass"
                    size="sm"
                    @click="() => (showAdvanced.labMin = true)"
                    v-if="!showAdvanced.labMin"
                  >
                    <font-awesome-icon icon="square-plus" size="sm" />
                    Add minimum labor cost
                  </Btn>
                </div>
              </div>
            </div>

            <!-- Subcontracting field -->
            <div :class="rowClass">
              <div :class="labelClass">
                <Checkbox
                  v-model="cost_type_is_subcontracted"
                  name="costType"
                  value="Subcontractor costs"
                  inputId="sub"
                  :trueValue="1"
                  :falseValue="0"
                />
                <font-awesome-icon
                  icon="truck"
                  :class="[
                    {
                      'text-subcontractor': cost_type_is_subcontracted
                    }
                  ]"
                />
                Subcontracted item
              </div>

              <div :class="valueClass" v-if="cost_type_is_subcontracted">
                <CalculatorFormatted
                  format="$"
                  v-model="cost_matrix_materials_cost_net"
                  v-model:equation="cost_matrix_materials_cost_net_equation"
                  :class="numberClass"
                />

                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>

            <!-- Combined unit cost field -->
            <div :class="rowClass">
              <div :class="labelClass">Combined unit cost</div>
              <!-- cost_matrix_aggregate_cost_net -->
              <div class="text-success value flex flex-nowrap justify-end items-center">
                <CalculatorFormatted
                  format="$"
                  v-model="cost_matrix_aggregate_cost_net"
                  :class="numberClass"
                />
                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>

            <!-- Profit field -->
            <div :class="[rowClass, 'border-t border-pitch-black']">
              <div :class="labelClass">Profit</div>

              <div class="flex flex-row items-center justify-center flex-grow">
                <CalculatorFormatted
                  :class="numberClass"
                  style="max-width: 100px"
                  format="number"
                  v-model="margin"
                  v-model:equation="margin_equation"
                />
                %
              </div>

              <div class="text-success value flex flex-nowrap justify-end items-center">
                <CalculatorFormatted
                  format="$"
                  v-model="profit"
                  v-model:equation="profit_equation"
                  :class="numberClass"
                />

                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>

            <!-- Price per unit -->
            <div :class="[rowClass, 'border-t border-pitch-black']">
              <div :class="labelClass">Price per unit</div>

              <div :class="valueClass">
                <CalculatorFormatted
                  format="$"
                  id="CATCHING"
                  v-model="cost_matrix_rate_net"
                  v-model:equation="cost_matrix_rate_net_equation"
                  :class="numberClass"
                />

                <Choose schema="cost_type:unit_of_measure_id" v-model="unitOfMeasureId">
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">/ {{ text }}</div>
                  </template>
                </Choose>
              </div>
            </div>

            <div :class="[rowClass]" v-if="showAdvanced.minSpend">
              <div :class="[labelClass, '!text-deep-red-500']">
                What is the minimum spend for your customer on this item?
              </div>

              <div :class="valueClass">
                <CalculatorFormatted
                  :class="numberClass"
                  format="$"
                  v-model="cost_type_minimum_price_net"
                  v-model:equation="cost_type_minimum_price_net_equation"
                />
                total
              </div>
            </div>

            <div :class="[rowClass]" v-if="showAdvanced.itemTax">
              <div :class="[labelClass]">
                Define a tax for this item that will override default project tax settings
              </div>

              <div :class="valueClass">
                <Choose
                  schema="cost_type:tax_id"
                  v-model="taxId"
                  placeholder="Use project tax setting"
                >
                  <template #default="{ text }">
                    <div :class="[numberClass, chooseClass]">
                      {{ text || 'Use project tax setting' }}
                    </div>
                  </template>
                </Choose>
              </div>
            </div>

            <div
              :class="[
                rowClass,
                '!justify-start !items-start gap-1',
                'border-t border-pitch-black'
              ]"
              v-if="!showAdvanced.minSpend || !showAdvanced.itemTax"
            >
              <div
                v-tooltip="
                  'When the total price for this item doesn\'t hit the minimum threshold set by you, the price will be bumped up automatically'
                "
              >
                <Btn
                  unstyled
                  :class="adjunctButtonClass"
                  size="sm"
                  @click="() => (showAdvanced.minSpend = true)"
                  v-if="!showAdvanced.minSpend"
                >
                  <font-awesome-icon icon="square-plus" size="sm" />
                  Add minimum spend
                </Btn>
              </div>
              <div
                v-tooltip="
                  'By default every item adopts the project-level tax settings. You can add item-specific sales tax settings to override the project-level settings with this option.'
                "
              >
                <Btn
                  unstyled
                  :class="adjunctButtonClass"
                  size="sm"
                  @click="() => (showAdvanced.itemTax = true)"
                  v-if="!showAdvanced.itemTax"
                >
                  <font-awesome-icon icon="square-plus" size="sm" />
                  Add item-specific sales tax
                </Btn>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab v-if="isCostItem && !cost_type_is_fee">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(1)
                    }
                  ]"
                />
                Quantity
              </div>

              <div
                class="flex flex-row-reverse md:flex-row items-center justify-end md:justify-end flex-nowrap white-space-nowrap gap-2"
              >
                <div class="flex flex-row items-center justify-end flex-nowrap white-space-nowrap">
                  <CalculatorFormatted
                    v-if="type === 'cost_item'"
                    format="currency"
                    v-model="cost_item_qty_net_base"
                    v-model:equation="cost_type_qty_equation"
                    :variables="variables"
                    class="text-right !ml-2"
                    :autoMultiline="false"
                  />
                  <span v-else>Unit</span>

                  <Choose
                    schema="cost_type:unit_of_measure_id"
                    v-model="unitOfMeasureId"
                    class="!w-fit"
                  >
                    <template #default="{ text }">
                      <div :class="chooseClass">
                        {{ text }}
                      </div>
                    </template>
                  </Choose>
                </div>

                <div class="flex gap-2">
                  <div
                    v-if="cost_type_minimum_qty_net >= 0.01"
                    v-tooltip="
                      'This item has a minimum quantity set, so if you see the quantity being bumped, that\'s why!'
                    "
                  >
                    <font-awesome-icon
                      :class="[
                        dashIconClass,
                        {
                          '!border-deep-red-500 !text-deep-red-500': cost_type_minimum_qty_net
                        }
                      ]"
                      icon="lightbulb-exclamation"
                      size="lg"
                    />
                  </div>
                  <div
                    v-tooltip="
                      `This quantity equation uses assembly dimensions: ${asDimensionsUsed.map((abbr) => `${variables[abbr]?.name} (${variables[abbr]?.value} ${variables[abbr]?.measure})`).join(', ')}`
                    "
                  >
                    <font-awesome-icon
                      icon="ruler-triangle"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: !asDimensionsUsed?.length,
                          'text-blue-print border-blue-print': asDimensionsUsed?.length
                        }
                      ]"
                      size="lg"
                    />
                  </div>

                  <div
                    v-tooltip="`Equation used for quantity: &quot;=${cost_type_qty_equation}&quot;`"
                  >
                    <font-awesome-icon
                      icon="calculator"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: !hasQtyEquation,
                          'text-blue-print border-blue-print': hasQtyEquation
                        }
                      ]"
                      size="lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass, '!h-fit py-2']">
              <div :class="[labelClass, '!basis-2/5']">Quantity formula</div>

              <div :class="valueClass">
                <CalculatorField
                  force-inline
                  show-pocket
                  numeric
                  auto-multiline
                  :width="350"
                  :height="50"
                  :variables="variables"
                  v-model="cost_type_qty_equation"
                />
              </div>
            </div>

            <div :class="[rowClass]" v-if="showAdvanced.minQty">
              <div :class="[labelClass, '!text-deep-red-500']">
                What is the minimum quantity for this item?
              </div>

              <div :class="valueClass">
                <CalculatorFormatted
                  :class="numberClass"
                  format="number"
                  v-model="cost_type_minimum_qty_net"
                  v-model:equation="cost_type_minimum_qty_net_equation"
                />
              </div>
            </div>

            <div
              :class="[rowClass, '!justify-start !items-start gap-1']"
              v-if="!showAdvanced.minQty"
            >
              <div
                v-tooltip="
                  'When the total quantity for this item doesn\'t hit the minimum threshold set by you, the quantity will be bumped up automatically'
                "
              >
                <Btn
                  unstyled
                  :class="adjunctButtonClass"
                  size="sm"
                  @click="() => (showAdvanced.minQty = true)"
                  v-if="!showAdvanced.minQty"
                >
                  <font-awesome-icon icon="square-plus" size="sm" />
                  Add minimum quantity
                </Btn>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab v-if="isCostItem && !cost_type_is_fee">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(2)
                    }
                  ]"
                />
                Sales
              </div>

              <div class="flex flex-row items-center md:justify-end flex-nowrap white-space-nowrap">
                <div class="flex gap-2">
                  <font-awesome-icon
                    icon="swatchbook"
                    :class="[
                      dashIconClass,
                      {
                        [dashIconDisabledClass]: !hasVariationsOrAddons,
                        'border-blue-print text-blue-print': hasVariationsOrAddons
                      }
                    ]"
                    size="lg"
                  />

                  <font-awesome-icon
                    v-if="type === 'cost_item'"
                    icon="toggle-on"
                    :class="[
                      dashIconClass,
                      {
                        [dashIconDisabledClass]: !cost_item_is_optional,
                        'border-blue-print text-blue-print': cost_item_is_optional
                      }
                    ]"
                    size="lg"
                  />
                </div>
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass]" v-if="type === 'cost_item'">
              <div :class="[labelClass]">Make this item optional for your customer?</div>

              <div :class="[valueClass, 'gap-2']">
                <Choose
                  v-if="cost_item_is_optional"
                  schema="cost_item:cost_item_is_included"
                  v-model="cost_item_is_included"
                  :return-array="false"
                  placeholder="Included"
                  :default="1"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Included by default',
                      value: 1
                    },
                    {
                      text: 'Excluded by default',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                />

                <Checkbox
                  v-model="cost_item_is_optional"
                  name="optional"
                  value="Optional"
                  inputId="opt"
                  :trueValue="1"
                  :falseValue="0"
                />
              </div>
            </div>

            <div :class="[rowClass, 'border-b-none']">
              <div :class="[labelClass]">Include alternative selections for this item?</div>

              <div :class="[valueClass, 'gap-2']">
                <Checkbox v-model="hasVariationsOrAddons" />
              </div>
            </div>

            <template v-if="hasVariationsOrAddons">
              <div :class="[rowClass, '!h-fit !border-none !justify-center']">
                <div class="flex flex-col lg:flex-row gap-6 !h-fit">
                  <div
                    v-if="!isVariationItem"
                    :class="[
                      {
                        'border-pitch-black bg-surface-100':
                          isAddonItem || addonTypeChoice === 'addon',
                        'border-surface-400 bg-surface-100/60':
                          !isAddonItem && addonTypeChoice !== 'addon',
                        'w-[17rem] h-[17rem]': true // !isAddonItem && !isVariationItem,
                      },
                      'flex flex-col justify-between gap-2 rounded-sm border-2 p-4 hover:opacity-100 cursor-pointer opacity-90 hover:border-pitch-black'
                    ]"
                    @click.capture.stop.prevent="
                      () => {
                        addonTypeChoice = 'addon'
                        handleOptionsManager()
                      }
                    "
                  >
                    <div class="flex flex-col leading-tight gap-0.5">
                      <span class="font-medium text-pitch-black leading-tight">Simple list</span>
                      <span class="text-sm leading-tight"
                        >Recommended for 1-10 options, or where the items are very different and may
                        require more details</span
                      >
                    </div>
                    <div class="flex gap-2 justify-center items-center">
                      <div
                        class="w-12 h-[8rem] bg-surface-300 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        1
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/90 flex items-center justify-center rounded-sm border border-pitch-black/50"
                      >
                        2
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/80 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        3
                      </div>
                      <div
                        class="w-12 h-[8rem] bg-surface-300/60 flex items-center justify-center rounded-sm border border-transparent"
                      >
                        ...
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="!isAddonItem"
                    :class="[
                      {
                        'border-pitch-black bg-surface-100':
                          isVariationItem || addonTypeChoice === 'variation',
                        'border-surface-400 bg-surface-100/60':
                          !isVariationItem && addonTypeChoice !== 'variation',
                        'w-[17rem] h-[17rem]': true //!isAddonItem && !isVariationItem,
                      },
                      'flex flex-col justify-between gap-2 rounded-sm border-2 p-4 hover:opacity-100 cursor-pointer opacity-90 hover:border-pitch-black'
                    ]"
                    @click.capture.stop.prevent="
                      () => {
                        addonTypeChoice = 'variation'
                        handleOptionsManager()
                      }
                    "
                  >
                    <div class="flex flex-col leading-tight gap-0.5">
                      <span class="font-medium text-pitch-black leading-tight"
                        >Variation options</span
                      >
                      <span class="text-sm leading-tight"
                        >Recommended for similar options, where each has a combination of 2-5
                        traits, for example material, style, finish, price-level, manufacturer
                        etc</span
                      >
                    </div>

                    <template>
                      <div class="flex flex-col">
                        <span class="text-xs">Size</span>
                        <div class="flex gap-2 justify-start">
                          <div
                            class="w-10 h-10 bg-surface-300 flex items-center justify-center rounded-sm text-xs border border-pitch-black/50"
                          >
                            sm
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/90 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            md
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/80 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            lg
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/70 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            xl
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/60 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            ...
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <span class="text-xs">Finish</span>
                        <div class="flex gap-2 justify-start">
                          <div
                            class="w-10 h-10 bg-surface-300 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            White
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/90 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            Black
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/80 flex items-center justify-center rounded-sm text-xs border border-pitch-black/50"
                          >
                            S/S
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/70 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            Oak
                          </div>
                          <div
                            class="w-10 h-10 bg-surface-300/60 flex items-center justify-center rounded-sm text-xs border border-transparent"
                          >
                            ...
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div
                class="flex justify-center items-center !border-none p-4"
                v-if="isVariationItem || isAddonItem || addonTypeChoice"
              >
                <Btn
                  severity="bolster"
                  size="xlarge"
                  :action="handleOptionsManager"
                  :class="[
                    {
                      'animate-bounce': !isOptionsInitialized
                    }
                  ]"
                >
                  {{
                    isOptionsInitialized
                      ? `Manage ${addons?.length || oVariations?.items?.length} options...`
                      : 'Get started!'
                  }}
                </Btn>
              </div>
            </template>

            <div :class="[rowClass, ' h-auto py-4']" v-if="requestColorInput">
              <div :class="[labelClass, '!shrink !grow-0']">Color selection</div>

              <div :class="[valueClass, 'gap-2 !grow !w-fit']">
                <Checkbox
                  v-model="requestColorInput"
                  name="optional"
                  value="Optional"
                  inputId="opt"
                  :trueValue="1"
                  :falseValue="0"
                />
                <Choose
                  v-model="oInputRequired.inputs[requestColorIndex].required"
                  :return-array="false"
                  placeholder="Choose required or not"
                  :default="0"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Require selection',
                      value: 1
                    },
                    {
                      text: 'Selection optional',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                />
                <SelectionColor
                  v-model="oInputRequired.inputs[requestColorIndex].input.data"
                  placeholder="No default color selected..."
                  class="shrink-0 w-[200px]"
                />
              </div>
            </div>

            <div :class="[rowClass, 'h-auto py-4']" v-if="requestTextInput">
              <div :class="[labelClass, '!shrink !grow-0']">Write-in input or selection</div>

              <div :class="[valueClass, 'gap-2 !grow !w-fit']">
                <Checkbox
                  v-model="requestTextInput"
                  name="optional"
                  value="Optional"
                  inputId="opt"
                  :trueValue="1"
                  :falseValue="0"
                />
                <Choose
                  v-model="oInputRequired.inputs[requestTextIndex].required"
                  :return-array="false"
                  placeholder="Choose required or not"
                  :default="0"
                  size="sm"
                  :static-set="[
                    {
                      text: 'Require selection',
                      value: 1
                    },
                    {
                      text: 'Selection optional',
                      value: 0
                    }
                  ]"
                  :allow-deselect="false"
                />

                <div class="flex flex-col gap-2 ml-3 shrink-0 w-[190px]">
                  <StringField
                    multiline
                    :class="['text-md h-fit !min-h-[3.4rem] w-full !whitespace-pre-line']"
                    placeholder="What would you like to ask your client for?"
                    :validate="{ required: true }"
                    v-model="oInputRequired.inputs[requestTextIndex].message"
                  />

                  <StringField
                    multiline
                    :class="['text-sm w-full !whitespace-pre-line']"
                    placeholder="Default value? Or leave blank..."
                    :validate="{ required: false }"
                    v-model="oInputRequired.inputs[requestTextIndex].input.text"
                  />
                </div>
              </div>
            </div>

            <div
              :class="[
                rowClass,
                '!justify-start !items-start gap-2 py-2',
                'border-t border-pitch-black'
              ]"
              v-if="!requestColorInput || !requestTextInput"
            >
              <Btn
                tooltip="Allow your customers to choose a color for this item."
                unstyled
                :class="adjunctButtonClass"
                size="sm"
                @click="
                  () => {
                    requestColorInput = 1
                  }
                "
                v-if="!requestColorInput"
              >
                <font-awesome-icon icon="square-plus" size="sm" />
                Request a custom color from your customer
              </Btn>
              <Btn
                tooltip="Allow your customers to write-in their own selection or other answer to a question, in a text input."
                unstyled
                :class="adjunctButtonClass"
                size="sm"
                @click="
                  () => {
                    requestTextInput = 1
                  }
                "
                v-if="!requestTextInput"
              >
                <font-awesome-icon icon="square-plus" size="sm" />
                Request custom input or selection from your customer
              </Btn>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab v-if="!isMediaItem">
          <template #header>
            <div
              class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="chevron-right"
                  :class="[
                    'min-w-4 transition-all ease-in-out',
                    {
                      'rotate-90': accordionIndex.includes(3)
                    }
                  ]"
                />
                Production
              </div>

              <div class="flex flex-row items-center md:justify-end flex-nowrap white-space-nowrap">
                <div class="flex gap-2">
                  <div
                    v-if="type === 'cost_item'"
                    v-tooltip="
                      assignee_ids?.length && assigneeName
                        ? `Assigned to: ${assigneeName}`
                        : 'Item not yet assigned'
                    "
                  >
                    <div
                      :class="[
                        'rounded-sm border-2 p-1 size-10',
                        'text-pitch-black border-transparent'
                      ]"
                      v-if="assignee_ids?.length"
                    >
                      <PersonAvatar :type="assigneeType" :name="assigneeName" :id="assigneeId" />
                    </div>
                    <font-awesome-icon
                      v-else
                      icon="circle-user"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: true
                        }
                      ]"
                      size="lg"
                    />
                  </div>

                  <div
                    v-tooltip="
                      cost_type_production_notes
                        ? `Production notes: ${cost_type_production_notes.slice(0, 50)}...`
                        : 'No production notes'
                    "
                  >
                    <font-awesome-icon
                      icon="comment-exclamation"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: !cost_type_production_notes,
                          'text-blue-print border-blue-print': cost_type_production_notes
                        }
                      ]"
                      size="lg"
                    />
                  </div>

                  <template v-if="type === 'cost_item'">
                    <div
                      v-tooltip="
                        item_client_has_approved
                          ? 'The client has approved this scope'
                          : 'The client has not yet approved this scope'
                      "
                    >
                      <font-awesome-icon
                        icon="house-circle-check"
                        :class="[
                          dashIconClass,
                          {
                            [dashIconDisabledClass]: !item_client_has_approved,
                            'text-blue-print border-blue-print': item_client_has_approved
                          }
                        ]"
                        size="lg"
                      />
                    </div>

                    <font-awesome-icon
                      icon="clipboard-check"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: true
                        }
                      ]"
                      size="lg"
                    />
                    <font-awesome-icon
                      icon="badge-check"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: true
                        }
                      ]"
                      size="lg"
                    />
                    <font-awesome-icon
                      icon="money-bill-wave"
                      :class="[
                        dashIconClass,
                        {
                          [dashIconDisabledClass]: true
                        }
                      ]"
                      size="lg"
                    />
                  </template>
                </div>
              </div>
            </div>
          </template>

          <div :class="fieldGroupClass">
            <div :class="[rowClass]" v-if="type === 'cost_item' && inCompanyScope">
              <div :class="[labelClass]">Who do you want to assign this item to?</div>

              <div :class="valueClass">
                <Choose
                  schema="assignee:assignee_id"
                  v-model="assignee_ids"
                  :return-array="true"
                  placeholder="Unassigned"
                  :allow-deselect="true"
                />
              </div>
            </div>

            <div :class="[rowClass, '!h-fit py-2']">
              <div :class="[labelClass, '!basis-2/5']">Production notes</div>

              <div :class="valueClass">
                <Field
                  class="min-w-[300px]"
                  type="textarea"
                  v-model="cost_type_production_notes"
                  placeholder="Add internal-only production notes (not visible to your client)"
                />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">
                What stage in the order of operations does this item get completed?
              </div>

              <div :class="valueClass">
                <Choose schema="cost_type:stage_id" v-model="stageId" placeholder="General" />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">What type of worker would do this work?</div>

              <div :class="valueClass">
                <Choose
                  schema="cost_type:trade_type_id"
                  v-model="tradeTypeId"
                  placeholder="General"
                />
              </div>
            </div>

            <div :class="[rowClass]">
              <div :class="[labelClass]">Budget or CSI code</div>

              <div :class="valueClass">
                <field v-model="cost_type_budget_code" />
              </div>
            </div>

            <div v-if="inCompanyScope" :class="[rowClass]">
              <div :class="[labelClass]">Where do you want this to be saved in your catalog?</div>

              <div :class="valueClass">
                <Choose
                  v-if="inCompanyScope"
                  v-model="parent_cost_type_id"
                  schema="cost_type:cost_type_id"
                  placeholder="Root category"
                  :custom-options="[
                    {
                      value: 'NULL',
                      text: 'Root directory',
                      html: 'Root directory'
                    }
                  ]"
                  :allow-create="true"
                  :order="[['cost_type_name', 'asc']]"
                  :filters="{
                    cost_type_is_parent: 1,
                    company_id: $store.state.session.company?.company_id || 'NULL'
                  }"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </template>

    <MiniModal
      full
      :fixed="true"
      :closeable="false"
      :showCloseButton="false"
      :includeGutter="true"
      :hasFooter="false"
      ref="refUpgradesModal"
    >
      <template #header>
        <div
          class="flex flex-col md:flex-row justify-start md:justify-between md:items-center w-full gap-y-2"
        >
          <div><font-awesome-icon icon="swatchbook" /> Selection options editor</div>

          <div class="flex gap-2">
            <Btn size="large" severity="tertiary" :action="handleOptionsCancel"> Cancel </Btn>
            <Btn size="large" severity="bolster" :action="handleOptionsSave"> Save and close </Btn>
          </div>
        </div>
      </template>

      <AddonsEditor
        ref="refOptionsEditor"
        @close="handleCloseRequest"
        v-model:object="mobject"
        v-if="addonTypeChoice === 'addon' || (addonTypeChoice === null && addons?.length)"
        v-bind="props"
      />
      <VariationsEditor
        ref="refOptionsEditor"
        v-else
        v-bind="props"
        v-model:object="mobject"
        @close="handleCloseRequest"
      />
    </MiniModal>

    <!--    <CardSection>-->
    <!--      <CardList>-->
    <!--        <CardListField>-->
    <!--          <span>Item name</span>-->

    <!--          <Field-->
    <!--            ref="titleInput"-->
    <!--            schema="cost_type:variation_parent_cost_type_name"-->
    <!--            :value="cost_type_name"-->
    <!--            @input="(name) => (variationParentName = name)"-->
    <!--            :emitDelay="400"-->
    <!--            :validate="{ required: true }"-->
    <!--          />-->
    <!--        </CardListField>-->
    <!--      </CardList>-->
    <!--    </CardSection>-->
  </div>
</template>
