<template>
  <div>
    <modal
      v-bind="$props"
      class="modal-mini"
      ref="modal"
      size="lg"
      :isDirty="bodyIsDirty"
      :full="false"
      :showClose="true"
      :clickAway="true"
      :expandable="true"
    >
      <template #header>
        <font-awesome-icon icon="user" />
        Edit Role
      </template>
      <template #body>
        <role-body ref="body" :forceFull="true" :id="id" />
      </template>
      <template #footer>
        <save-or-cancel
          :isDirty="bodyIsDirty"
          :loading="loading"
          @save="saveClick"
          @cancel="cancelClick"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import RoleBody from '../bodies/Role.vue'
import ObjectModalMixin from './ObjectModalMixin'
import Confirm from './Confirm.vue'

export default {
  name: 'ModalRole',
  mixins: [ObjectModalMixin],
  components: { RoleBody },
  props: {
    type: {
      default: 'role'
    }
  }
}
</script>
