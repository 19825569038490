<template>
  <div class="presentation-alter--container" :class="btnbarOpen ? 'active' : ''">
    <btn :id="uid" class="btn xs info" @click="click">
      <template #icon v-if="icon">
        <font-awesome-icon :icon="icon"></font-awesome-icon>
      </template>
      {{ text || 'Change' }}
    </btn>
    <btn-bar
      @open="btnbarOpen = 1"
      @close="btnbarOpen = 0"
      :fixTo="`#${uid}`"
      v-if="actions"
      ref="btnbar"
      :actions="actions"
      :showButton="false"
      :collapse="true"
    />
  </div>
</template>

<script>
/**
 * Modifier classes:
 * -top-right (button, actions go to top)
 * -top-left
 * -bottom-right (default)
 * -bottom-left
 */
export default {
  name: 'PresentationAlter',
  emits: ['click'],
  data() {
    return {
      uid: c.uniqueId(),
      btnbarOpen: 0
    }
  },
  watch: {},
  computed: {},
  methods: {
    click() {
      if (this.actions) {
        c.makeArray(this.$refs.btnbar)[0].toggle()
      } else {
        this.$emit('click')
      }
    }
  },
  components: {},
  props: {
    icon: {},
    text: {},
    actions: {
      type: [Array, Object, Boolean],
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.presentation-alter--container {
  border-radius: 5em;
  height: calc(100% - 0.3em);
  border: 0.25em solid transparent;
  margin: 0.15em;
  width: calc(100% - 0.3em);
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-out;

  .btn {
    position: absolute;
    bottom: -0.5em;
    right: -0.5em;
    left: auto;
    top: auto;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &.active,
  &:hover {
    border-color: $blue-print-700;
    min-height: 2em;
    .btn {
      opacity: 1;
    }
  }

  &.top-right {
    .btn {
      bottom: auto;
      right: -0.5em;
      left: auto;
      top: -0.5em;
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 5px !important;
    }
  }
  &.top-left {
    .btn {
      bottom: auto;
      right: auto;
      left: -0.5em;
      top: -0.5em;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 0 !important;
    }
  }
  &.bottom-left {
    .btn {
      bottom: -0.5em;
      right: auto;
      left: -0.5em;
      top: auto;
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 5px !important;
    }
  }
}
</style>
