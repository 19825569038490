import fr_CA from '../../../quote/presentation/languages/fr_CA';

export default {
  ...fr_CA,
  languageName: 'French (CA)',

  // Presentation
  'Estimate': 'Soumission',
  'Project': 'Projet',
  'Invoice': 'Facture',
  'Client': 'Client(s)',
  'Call us': 'Nous joindre',
  'Presented by': 'Présenté par',
  'Expand': 'Voir',
  'to see options': 'le détail',
  'other customers recently upgraded their': 'récemment, d\'autres client ont opté pour un surclassement de',
  'other options for': 'leur option pour',
  'included': 'inclus',
  'not included':    'exclu',
  'Other options for': 'D\'autres options pour',
  'Preview': 'Devis de construction',
  'selected': 'sélectionné',
  'other customers selected this recently': 'd\'autres clients ont récemment sélectionné ceci',
  'Make this selection': 'Choisir cette option',
  'Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project': 'Les options offertes en surclassement peuvent varier selon les spécifications du projet de même que de leur disponibilité. Les images sont à titre indicatif et peuvent ne pas être représentative de ce qui sera appliqué à votre projet. Voir avec le conseiller aux ventes.',

  'Download': 'Télécharger',
  'Subtotal': 'Sous-total',
  'Discount': 'Rabais',
  'Please review the proposal and choose accept to proceed': 'Merci de bien vouloir réviser la soumission et sélectionner «Accepter» pour poursuivre',
  'See': 'Voir',
  'More': 'Plus',
  'Remove': 'Retirer',
  'Project files': 'Documents du projet',
  'Notes': 'Notes',
  'Upgraded item': 'Items surclassés',
  'Clients address': 'Adresse des clients',
  'more general items': 'plus d\'items généraux',
  'Upgrades subject to final approval and availability': 'Surclassements sujet à la disponibilité et à l\'approbation de l\'Entrepreneur',
  'Cost': 'Coût de construction',
  'Taxes': 'Taxes',
  'Project total': 'Total du projet',
  'Review terms and approve this': 'Réviser les termes et conditions et approuver',
  'Review terms and approve': 'Réviser les termes et conditions et approuver',
  'Request revision': 'Demande de révision',
  'Decline': 'Refuser',
  'Options available - look for the upgrades symbol': 'Options disponibles - voir les symboles de surclassement',
  'Upgrades available': 'Surclassements disponibles',
  'You agree to accept and proceed with the project as detailed': 'Vous acceptez le projet tel que détaillé et passer à l\'étape suivante',
  'This version was approved on': 'Cette version a été approuvée le',
  'This version was declined': 'Cette version est refusée',
  'Terms and conditions': 'Termes et conditions',
  'In order to continue, review and accept to the terms below': 'Afin de poursuivre, veuillez réviser et accepter les termes et conditions ci-après',
  'I accept the terms and conditions': 'J\'accepte les termes et conditions',
  'I do not accept the terms and conditions': 'Je refuse les termes et conditions',
  'Thank you for booking': 'Merci d\'avoir confirmé ce projet',
  'Please wait while we upgrade your records': 'Merci de patienter alors que nous mettons à jour vos sélections',
  'Thank you': 'Merci',
  'Project fully booked': 'Projet confirmé',
  'Thank you for booking with us': 'Merci d\'avoir choisi de collaborer avec nous',
  'We will get in touch as soon as possible to confirm details': 'Nous vous contacterons dès que possible afin de vous confirmer les détails',
  'Your project is fully booked': 'Votre projet est confirmé',
  'Come back to Bolster to follow updates or to request or approve change-orders': 'Revenez vers la plateforme Bolster afin de suivre les mises à jour ou pour approuver ou faire de nouvelles demandes',
  'See projects': 'Voir les projets',
  'There was an error booking this project. Please try again.': 'Une erreur est survenue lors de la confirmation de ce projet. Merci d\'essayer à nouveau.',
  'Secure payment methods': 'Méthodes de paiement sécurisées',
  'PLEASE READ THE TERMS AND CONDITIONS AND INDICATE YOUR ACCEPTANCE BY ENTERING YOUR NAME ABOVE AND CLICKING THE "I ACCEPT THE TERMS AND CONDITIONS" BUTTON AT THE BOTTOM OF THIS CHECKOUT PAGE. ACCEPTANCE OF THE AGREEMENT MEANS THAT YOU ARE BOUND BY ITS TERMS. PLEASE RETAIN IT FOR YOUR RECORDS.': 'VEUILLEZ LIRE LES TERMES ET CONDITIONS ET INDIQUER VOTRE ACCEPTATION EN SAISISSANT VOTRE NOM CI-DESSUS ET EN CLIQUANT SUR LE BOUTON "J\'ACCEPTE LES TERMES ET CONDITIONS" EN BAS DE CETTE PAGE DE PAIEMENT. L\'ACCEPTATION DE L\'ACCORD SIGNIFIE QUE VOUS ÊTES LIÉ PAR SES TERMES. VEUILLEZ LE CONSERVER POUR VOS DOSSIERS.'
};
