export default {
  root: ({ props, instance }) => ({
    class: [
      'relative',

      // Flex & Alignment
      'flex items-center',
      {
        'gap-1': instance.$attrs.size === 'sm',
        'gap-2': !instance.$attrs.size || instance.$attrs.size !== 'sm'
      },

      // Misc
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled
      }
    ]
  }),
  cancelitem: ({ context }) => ({
    class: [
      // Flex & Alignment
      'inline-flex items-center',

      // Misc
      'cursor-pointer'
    ]
  }),
  cancelicon: ({ instance }) => ({
    class: [
      // Size
      {
        'w-3 h-3': instance.$attrs.size === 'sm',
        'w-5 h-5': !instance.$attrs.size || instance.$attrs.size === 'md',
        'w-7 h-7': !instance.$attrs.size || instance.$attrs.size === 'lg',
        'w-9 h-9': !instance.$attrs.size || instance.$attrs.size === 'xl'
      },

      // Color
      'text-red-500 dark:text-red-400',

      // State
      'hover:text-red-600 dark:hover:text-red-300',

      // Transition
      'transition duration-200 ease-in'
    ]
  }),
  item: ({ props, context }) => ({
    class: [
      // Flex & Alignment
      'inline-flex items-center',

      // Misc
      {
        'cursor-pointer': !props.readonly,
        'cursor-default': props.readonly
      }
    ]
  }),
  officon: ({ props, instance }) => ({
    class: [
      // Size
      {
        'w-3 h-3': instance.$attrs.size === 'sm',
        'w-5 h-5': !instance.$attrs.size || instance.$attrs.size === 'md',
        'w-7 h-7': !instance.$attrs.size || instance.$attrs.size === 'lg',
        'w-9 h-9': !instance.$attrs.size || instance.$attrs.size === 'xl'
      },

      // Color
      'text-yellow-700 dark:text-surface-0/80',

      // State
      { 'hover:text-primary-500 dark:hover:text-primary-400': !props.readonly },

      // Transition
      'transition duration-200 ease-in'
    ]
  }),
  onicon: ({ props, instance }) => ({
    class: [
      // Size
      {
        'w-3 h-3': instance.$attrs.size === 'sm',
        'w-5 h-5': !instance.$attrs.size || instance.$attrs.size === 'md',
        'w-7 h-7': !instance.$attrs.size || instance.$attrs.size === 'lg',
        'w-9 h-9': !instance.$attrs.size || instance.$attrs.size === 'xl'
      },

      // Color
      'text-yellow-700 dark:text-primary-400',

      // State
      { 'hover:text-primary-600 dark:hover:text-primary-300': !props.readonly },

      // Transition
      'transition duration-200 ease-in'
    ]
  })
}
