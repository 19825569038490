import CounterpartyMixin from './CounterpartyMixin'
import PayfacMixin from '../PayfacMixin'

export default {
  mixins: [CounterpartyMixin, PayfacMixin],
  data() {
    return {
      // initial counterparty schema
      counterparty: {
        company: {
          name: '',
          phone: '',
          tax_id: '',
          owners_provided: true,
          address: {
            line1: '',
            city: '',
            state: '',
            country: '',
            postal_code: ''
          }
        },
        business_profile: {
          mcc: '23',
          name: '',
          url: '',
          source_of_funds: '',
          product_description: ''
        }
      },
      // fields to save under meta
      fieldsToSaveToMeta: {
        company: [
          'company_address',
          'company_city',
          'company_prov_2',
          'company_postal',
          'company_country'
        ],
        user: ['user_address', 'user_city', 'province_abbr', 'user_country', 'user_postal']
      },
      // use this to map data to stripe from session and find changes
      fieldMapping: {
        'company.name': 'company.company_name',
        'company.address.line1': 'company.company_address',
        'company.address.city': 'company.company_city',
        'company.address.state': 'company.company_prov_2',
        'company.address.country': 'company.company_country',
        'company.address.postal_code': 'company.company_postal',
        'company.tax_id': 'company.oMeta.tax_id',
        'business_profile.name': 'company.company_name_short',
        'business_profile.url': 'company.company_website',
        'business_profile.product_description': 'company.oMeta.product_description'
      }
    }
  },
  methods: {
    /**
     * Use the field mapping to see what changes we need to save to use or company
     * @param {*} diff
     * @returns object
     */
    getChanges(diff) {
      const changes = {}

      Object.keys(diff).forEach((key) => {
        const difference = diff[key]
        if (key === '.owner.id_number') return
        const cf = key.substring(1)
        const ccf = this.fieldMapping[cf]
        if (!ccf) return
        const parts = ccf.split('.')
        const entity = parts[0]
        const field = parts[1]
        const subField = parts[2]
        if (!(entity in changes)) changes[entity] = {}

        // check to see if we want to save the date under the meta field;
        const metaChanges = changes.company && changes.company.oMeta ? changes.company.oMeta : {}
        if (field && this.fieldsToSaveToMeta[entity].includes(field)) {
          if (!('company' in changes)) changes.company = {}
          changes.company.oMeta = { ...(metaChanges || {}), [field]: difference }
          return
        } else if (subField && this.fieldsToSaveToMeta[entity].includes(subField)) {
          if (!('company' in changes)) changes.company = {}
          changes.company.oMeta = { ...(metaChanges || {}), [subField]: difference }
          return
        }

        if (subField) {
          if (!(field in changes[entity])) changes[entity][field] = {}
          changes[entity][field][subField] = difference
        } else {
          changes[entity][field] = difference
        }
      })

      return changes
    },
    // save the changes for the passed entity
    saveChanges(entity, id, data) {
      return this.$store.dispatch('ajax', {
        path: `${entity}/saveChanges`,
        data: [
          {
            [`${entity}_id`]: id,
            ...data
          }
        ]
      })
    },
    // get the difference between the initial profile and profile with updates
    // keep object hierarchy
    getDiff(a, b, diff, parent = null) {
      const keys = Object.keys(a)
      const diffRef = diff
      keys.forEach((key) => {
        const aVal = a[key]
        const bVal = b[key]
        if (typeof aVal === 'object') {
          this.getDiff(aVal, bVal, diffRef, key)
        } else if (aVal !== bVal) {
          if (parent) {
            if (typeof diffRef[parent] !== 'object') diffRef[parent] = {}
            diffRef[parent][key] = bVal
          } else {
            diffRef[key] = bVal
          }
        }
      })
    },
    /**
     * Make updates to the counterparty on the gateway side
     * @param {Object} data
     * @returns {Promise<*>}
     */
    updateCounterparty(data) {
      const diff = {}
      this.getDiff(data, this.counterparty, diff)
      if (Object.keys(diff).length === 0) return {}
      return this.$store.dispatch('ajax', {
        path: '/gateway/updateCounterparty',
        data: diff
      })
    },
    /**
     * Save the profile data to the company
     * @param {Object} changes
     * @returns {Promise<*>}
     */
    saveCompanyData(changes) {
      return Promise.all(
        Object.keys(changes).map((entity) => {
          const data = changes[entity]
          const id = this.$store.state.session[entity][`${entity}_id`]
          return this.saveChanges(entity, id, data)
        })
      )
    },
    /**
     * Get changes and save company and user data
     * @param {Object} profile
     * @param {Object|null} counterparty
     */
    saveAllChanges(profile, counterparty = null) {
      if (this.warning) {
        this.$store.dispatch('alert', {
          message: this.warning,
          error: true
        })
        return null
      }
      return new Promise((resolve, reject) => {
        const diff = {}
        const path = ''
        const data = counterparty || this.counterparty
        this.deepDiff({ ...data }, profile, diff, path)
        const changes = this.getChanges(diff)
        if (!changes.company) changes.company = {}
        // make sure to update the oMEta and not save over it
        changes.company.oMeta = {
          ...(this.$store.state.session.company.oMeta || {}),
          ...{ mcc: profile.business_profile.mcc },
          ...(changes.company.oMeta || {})
        }
        try {
          const res = this.saveCompanyData(changes)
          resolve(res)
        } catch (e) {
          reject(e)
        }
      })
    },
    /**
     * When saving the profile to update our entities we need to use the field mapping
     * @param {*} a
     * @param {*} b
     * @param {*} diff
     * @param {*} path
     * @param {*} parent
     */
    deepDiff(a, b, diff, path, parent = null) {
      const diffRef = diff
      let pathRef = path
      if (!a || !b) return
      const keys = Object.keys(a)
      keys.forEach((key) => {
        const aVal = a[key]
        const bVal = b[key]
        if (aVal && typeof aVal === 'object') {
          if (key in this.counterparty) {
            pathRef = ''
          }

          const parts = path.split('.')
          const i = parts.indexOf(parent)
          if (i >= 1) {
            parts.length = i + 1
            pathRef = parts.join('.')
          }

          pathRef += `.${key}`
          this.deepDiff(aVal, bVal, diffRef, pathRef, key)
        } else if (aVal !== bVal) {
          diffRef[`${pathRef}.${key}`] = aVal
        }
      })
    },
    /**
     * Save the payee profile
     * @param {*} profile
     */
    async saveDetails(profile) {
      try {
        this.loading = true
        const payfacData = await this.mapDataToPayfac({
          counterparty: profile
        })
        // remove owner because we update owner from owner list now
        delete payfacData.owner
        await this.saveAllChanges(payfacData)
        await this.updatePayee(payfacData)
        this.$store.dispatch('alert', {
          message: 'Profile details updated successfully',
          error: false
        })
        this.loading = false
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not save account profile.',
          error: true
        })
      }
    },
    /**
     * Save the payee profile
     * @param {*} profile
     */
    async verifyStakeholderIdentityPresent({ email }) {
      try {
        let error = null
        this.loading = true
        const { payload } = await this.$store.dispatch('ajax', {
          path: '/counterparty/verifyStakeholderIdentityPresent',
          data: {
            email
          }
        })

        if (!payload?.valid) {
          error = 'Identity verification information must be provided before you continue'
        }
        this.loading = false
        return error
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not verify identity information.',
          error: true
        })
      }
    }
  }
}
