import { computed } from 'vue'
import _ from '../../../imports/api/Helpers.js'

export default {
  useItemMaterialCosts(payload) {
    const {
      cost_type_materials_purchase_qty_per_unit,
      unit_of_measure_name,
      unitOfMeasureId,
      cost_type_material_waste_factor_net,
      cost_matrix_materials_cost_net,
      purchase_unit_of_measure_name,
      purchaseUnitOfMeasureId
    } = payload

    const measureType = computed(() => c.getMeasureTypeForUnitOfMeasure(unitOfMeasureId.value))
    const wastageTerm = computed(() => {
      if (/length|area/.test(measureType.value || '')) return 'fitting, or off cuts'
      if (/volume|weight/.test(measureType.value || '')) return 'spilling, or unusable quantities'
      return 'cutting, spilling or unusable quantities'
    })
    const isEachD = computed(
      () => String(unit_of_measure_name.value || 'each').toLowerCase() === 'each'
    )
    const isEachP = computed(
      () => String(purchase_unit_of_measure_name.value || 'each').toLowerCase() === 'each'
    )
    const unitsSame = computed(() => purchaseUnitOfMeasureId.value === unitOfMeasureId.value)
    const unitName = computed(() => {
      const punit = String(purchase_unit_of_measure_name?.value ?? 'each')
      if (isEachP.value) return 'the individual unit (each)'
      return `the ${punit}`
    })
    const perUnitName = computed(() => {
      const punit = String(purchase_unit_of_measure_name?.value ?? 'each')
      if (isEachP.value) return 'per individual unit'
      return `in each ${punit}`
    })
    const dunitName = computed(() => {
      if (isEachD.value) return 'item(s)'
      return String(unit_of_measure_name.value || 'each').toLowerCase()
    })
    const wastage = computed({
      get: () => cost_type_material_waste_factor_net.value * 100,
      set: (val) => (cost_type_material_waste_factor_net.value = c.divide(val, 100))
    })
    const useImperial = computed(() => unitOfMeasureId.value === 'ft')
    const pricePerPurchaseUnit = computed({
      get() {
        return (
          cost_type_materials_purchase_qty_per_unit.value * cost_matrix_materials_cost_net.value
        )
      },

      set(v) {
        const ppu = _.n(v)
        const matrix = c.divide(ppu, cost_type_materials_purchase_qty_per_unit.value)

        cost_matrix_materials_cost_net.value = matrix
      }
    })

    return {
      wastage,
      wastageTerm,
      unitName,
      useImperial,
      pricePerPurchaseUnit,
      isEachD,
      isEachP,
      unitsSame,
      perUnitName,
      dunitName
    }
  }
}
