<template>
  <div class="flex space-x-2">
    <input
      v-for="(digit, index) in code"
      :key="index"
      type="text"
      maxlength="1"
      class="w-14 h-14 md:w-16 md:h-16 text-4xl text-center border border-cool-gray-200 rounded focus:border-2 focus:border-blue-500"
      @input="onInput($event, index)"
      @keydown.backspace="onBackspace($event, index)"
      @keypress="onKeyPress($event)"
      v-model="code[index]"
      ref="inputs"
    />
  </div>
</template>
I

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  props: {
    resendCode: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const code = ref(Array(6).fill(''))
    const inputs = ref([])

    const onInput = (event, index) => {
      const value = event.target.value
      if (value.match(/^\d$/)) {
        code.value[index] = value
        focusNext(index)
        emit('codeChanged', code.value.join(''))
      } else {
        event.target.value = ''
      }
    }

    const onBackspace = (event, index) => {
      if (event.key === 'Backspace' && !code.value[index] && index > 0) {
        focusPrevious(index)
        emit('codeChanged', code.value.join(''))
      }
    }

    const onKeyPress = (event) => {
      const keyCode = event.keyCode || event.which
      const keyValue = String.fromCharCode(keyCode)
      if (!keyValue.match(/^\d$/)) {
        event.preventDefault()
      }
    }

    const focusNext = (index) => {
      if (index < inputs.value.length - 1) {
        inputs.value[index + 1].focus()
      }
    }

    const focusPrevious = (index) => {
      if (index > 0) {
        inputs.value[index - 1].focus()
      }
    }

    const clearInputs = () => {
      code.value = Array(6).fill('')
      inputs.value[0].focus()
    }

    watch(
      () => props.resendCode,
      (newVal) => {
        if (newVal) {
          clearInputs()
        }
      }
    )

    onMounted(() => {
      if (inputs.value.length > 0) {
        inputs.value[0].focus()
      }
    })

    return {
      code,
      inputs,
      onInput,
      onBackspace,
      onKeyPress
    }
  }
}
</script>
