<template>
  <section>
    <ul>
      <li>
        <strong>Profit</strong> is the percentage you make on each item:
        <pre class="mt-1 mb-4 bg-transparent w-fit p-2">(price - cost) / price = profit</pre>
      </li>
    </ul>

    <div>
      <p class="mb-2">
        Let's find the price of an item costing $100 that we want to make a
        <span class="text-info">30%</span> profit on:
      </p>
      <pre
        class="!mt-1 bg-transparent w-fit"
      >(x - 100) / x = 0.30<br/>x - 100 = 0.30x<br/>0.70x = 100<br/>x = 100 / 0.70<br/>x = 143
      </pre>
      <p class="mb-2">
        Therefore, the price of an item costing us
        <span class="text-info">$100</span>
        will be
        <span class="text-info">$143</span>
        if we wish to make a
        <span class="text-info">30%</span>
        profit on it.
      </p>
    </div>

    <div class="mt-5">
      <SuggestionButton
        class="info mt-2"
        @click="() => $store.dispatch('helpTopic', 'company_wide_margin')"
      >
        Documentation
      </SuggestionButton>
    </div>
  </section>
</template>

<script>
import SuggestionButton from '../ui/SuggestionButton.vue'

export default {
  name: 'CompanyMarkupHelp',
  components: {
    SuggestionButton
  }
}
</script>
