<template>
  <div class="flex flex-col items-end gap-y-4 min-w-[400px] w-[400px]">
    <div class="overflow-visible flex flex-col gap-4 w-full p-1">
      <div class="flex flex-col w-full">
        <div class="flex">
          <AddressField
            class="flex-1 !h-12"
            @select="onSelect"
            label="Address"
            placeholder="Start typing address..."
            :error="required && errors.includes(`${namespace}.address.line1`) && !address.line1"
            v-model="address.line1"
          />
          <div v-if="allowManualEntry" class="ml-2 flex items-start">
            <Checkbox v-model="manualEntry" :selected-value="true" :deselected-value="false" />
            <span class="text-xs ml-2 font-medium">Detailed</span>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full">
        <field
          placeholder="Unit/suite"
          :value="address.suite"
          v-model="address.suite"
          class="!w-full max-w-full !h-12"
        />
      </div>

      <div v-if="showAllFields" class="flex flex-col w-full">
        <field
          placeholder="Address line 2"
          :value="address.line1"
          v-model="address.line1"
          class="!w-full max-w-full !h-12"
        />
      </div>

      <div v-if="showAllFields" class="flex flex-col w-full">
        <field
          placeholder="Address line 3"
          :value="address.line2"
          v-model="address.line2"
          class="!w-full max-w-full"
        />
      </div>

      <div class="flex flex-col w-full">
        <field
          placeholder="City"
          :value="address.city"
          :validate="{ required }"
          v-model="address.city"
          class="!w-full max-w-full"
        />
      </div>

      <div class="flex flex-row gap-4 justify-stretch">
        <div class="flex flex-col w-1/2 basis-1/2 justify-end">
          <choose
            :placeholder="address?.country === 'ca' ? 'Province' : 'State'"
            :return-array="false"
            :allowDeselect="false"
            :formatter="formatters.state"
            :error="required && errors.includes(`${namespace}.address.state`)"
            :schema="inQuote ? 'province:province_id' : 'company:province_id'"
            :filters="{
              ...(address.country
                ? {
                    country_id: lookupCountry(address.country)
                  }
                : {})
            }"
            v-model="address.state"
          />
        </div>
        <div class="flex basis-auto justify-end grow-1 w-1/2">
          <field
            :placeholder="address.country === 1 ? 'Postal Code' : 'ZIP Code'"
            unstyled
            :validate="{ required }"
            :value="address.postal_code"
            :error="required && errors.includes(`${namespace}.address.postal_code`)"
            v-model="address.postal_code"
            class="!w-full !max-w-8"
          />
        </div>
      </div>

      <div v-if="showAllFields" class="flex flex-col w-[50%]">
        <p class="ml-1 mb-0.5">Country</p>
        <choose
          v-model="address.country"
          size="md"
          :allow-create="false"
          :allow-deselect="false"
          placeholder="Choose country"
          :return-array="false"
          btnClass="w-full rounded transition !justify-start border border-surface-200 bg-flame-white hover:bg-surface-100 hover:border-surface-300"
          :formatter="formatters.country"
          :error="required && errors.includes(`${namespace}.address.country`)"
          :schema="inQuote ? 'country:country_name' : 'country:country_abbr'"
          :validate="{ required }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddressField from '../fields/AddressField.vue'

export default {
  name: 'AddressForm',
  emits: ['input'],
  computed: {
    addressPreview() {
      return `${this.address.line1 || ''}${this.address.line2 ? '' : ` ${this.address.line2}`}, ${this.address.city || ''}, ${this.getStateLookup(this.address.state) || ''}, ${this.address.postal_code || ''}`
    },
    manualEntry: {
      get() {
        return this.manualEntryValue
      },
      set(value) {
        this.manualEntryValue = value
      }
    },
    showAllFields() {
      return this.allowManualEntry && this.manualEntryValue
    }
  },
  data() {
    const formatters = {
      country: (obj) => ({
        uid: obj.uid,
        text: this.inQuote ? obj.country_name : (obj.country_abbr || '').toUpperCase(),
        html: `${obj.country_name}`,
        value: this.inQuote ? obj.country_id : (obj.country_abbr || '').toUpperCase()
      }),
      state: (obj) => ({
        uid: obj.uid,
        text: this.inQuote ? obj.province_name : (obj.province_abbr || '').toUpperCase(),
        html: `${obj.province_name}`,
        value: this.inQuote ? obj.province_id : (obj.province_abbr || '').toUpperCase()
      })
    }
    return {
      address: {
        full: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
        state: '',
        postal_code: ''
      },
      formatters,
      manualEntryValue: false
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        full: '',
        line1: '',
        line2: '',
        city: '',
        country: '',
        state: '',
        postal_code: ''
      })
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    namespace: {
      type: String
    },
    inQuote: {
      type: Boolean,
      default: false
    },
    allowManualEntry: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    address: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  methods: {
    lookupCountry(val) {
      // if the val is already an id return it
      if (typeof val !== 'string') return val
      const lookup = {
        CA: 1,
        US: 2,
        GB: 3,
        AU: 4,
        NZ: 5,
        ZA: 6,
        IE: 7,
        PT: 8,
        ES: 9,
        FR: 10,
        DE: 11,
        IN: 12
      }
      return lookup[val.toUpperCase()]
    },
    async onSelect(selectedAddress) {
      // map fields to address
      const { Country, Label, Region, PostalCode, Municipality } = selectedAddress
      const lookup = this.getCountryLookup()
      const country = lookup[Country] || Country
      let postal = PostalCode
      // if the country is us it seems to add a second sequence of digits to the zip code that
      // invalidate the address
      if (country === 'US') {
        const parts = PostalCode.split(' ')
        postal = parts[0]
      }
      this.address = {
        line1: Label,
        country,
        state: this.inQuote ? this.getStateLookup(Region) : Region,
        postal_code: postal,
        city: Municipality
      }
    },
    getCountryLookup() {
      if (this.inQuote) {
        return {
          CAN: 1,
          USA: 2,
          GBR: 3,
          AUS: 4,
          NZL: 5,
          ZAF: 6,
          IRL: 7,
          PRT: 8,
          ESP: 9,
          FRA: 10,
          DEU: 11,
          IND: 12
        }
      }
      return {
        CAN: 'CA',
        USA: 'US',
        GBR: 'GB',
        AUS: 'AU',
        NZL: 'NZ',
        ZAF: 'ZA',
        IRL: 'IE',
        PRT: 'PT',
        ESP: 'ES',
        FRA: 'FR',
        DEU: 'DE',
        IND: 'IN'
      }
    },
    getStateLookup(val) {
      const lookup = {
        Alberta: 1,
        'British Columbia': 2,
        Saskatchewan: 3,
        Manitoba: 4,
        Quebec: 5,
        Ontario: 6,
        'New Brunswick': 7,
        'Nova Scotia': 8,
        'Prince Edward Island': 9,
        'Newfoundland and Labrador': 10,
        Nunavut: 11,
        'Northwest Territories': 12,
        Yukon: 13,
        Alabama: 14,
        Alaska: 15,
        Arizona: 16,
        Arkansas: 17,
        California: 18,
        Colorado: 19,
        Connecticut: 20,
        Delaware: 21,
        Florida: 22,
        Georgia: 23,
        Hawaii: 24,
        Idaho: 25,
        Illinois: 26,
        Indiana: 27,
        Iowa: 28,
        Kansas: 29,
        Kentucky: 30,
        Louisiana: 31,
        Maine: 32,
        Maryland: 33,
        Massachusetts: 34,
        Michigan: 35,
        Minnesota: 36,
        Mississippi: 37,
        Missouri: 38,
        Montana: 39,
        Nebraska: 40,
        Nevada: 41,
        'New Hampshire': 42,
        'New Jersey': 43,
        'New Mexico': 44,
        'New York': 45,
        'North Carolina': 46,
        'North Dakota': 47,
        Ohio: 48,
        Oklahoma: 49,
        Oregon: 50,
        Pennsylvania: 51,
        'Rhode Island': 52,
        'South Carolina': 53,
        'South Dakota': 54,
        Tennessee: 55,
        Texas: 56,
        Utah: 57,
        Vermont: 58,
        Virginia: 59,
        Washington: 60,
        'West Virginia': 61,
        Wisconsin: 62,
        Wyoming: 63
      }

      return lookup[val] ?? ''
    }
  },
  created() {
    this.address = this.value
  },
  components: {
    AddressField
  }
}
</script>
