import BtnMixin from '../mixins/Button'
import UserMeta from '../mixins/UserMeta'
import Milestones from '../mixins/Milestones'
import ItemType from '../mixins/ItemType'
import _ from '../../../imports/api/Helpers'
import Formatters from '@/components/ui/Choose/Formatters.js'
import AutoCost from '../../../imports/api/AutoCost.js'

export default {
  mixins: [BtnMixin, UserMeta, Milestones, ItemType],
  data() {
    return {
      laborFormatter: Formatters.labor_type,
      destroying: 0,
      setMinQty: false,
      setMinMaterials: false,
      setMinLabor: false,
      setBaseMaterials: false,
      setBaseLabor: false,
      subcontractSet: false,
      open: false,
      draggable: this.editable,
      editing: false,
      editingNotes: false,
      editingFiles: false,
      hoursEntered: null,
      showProperties: false,
      test: [],
      swapping: false,
      estimatedHours: 0,
      showAll: 0,
      summaryType: 'perUnit', // or 'total'
      existsConfirmed: null,
      dimTypesCache: null,
      linkingValueInput: false
    }
  },

  asyncComputed: {
    dimTypes: {
      async get() {
        if (!this.dimTypesCache) {
          const all = await this.$store.dispatch('Dimension/getPossibleDimensions', { full: true })

          this.dimTypesCache = all
        }

        return this.dimTypesCache
      },
      default: () => ({})
    },

    measureType: {
      async get() {
        return this.getDefaultMeasureType()
        // if (this.dimension_measure_type) return this.dimension_measure_type;
        //
        // if (!this.measureTypeLocal && this.unitOfMeasure) {
        //   const { object } = await this.$store.dispatch('UnitOfMeasure/resolveObject', {
        //     id: this.unitOfMeasure,
        //   });
        //   this.setField('dimension_measure_type', object.dimension_measure_type);
        //   this.measureTypeLocal = object.dimension_measure_type;
        // }
        //
        // return this.measureTypeLocal;
      },
      set(mt) {
        this.measureTypeLocal = mt
      },
      default() {
        return null
      }
    }
  },

  computed: {
    isTask() {
      return this.cost_type_is_task // this.item_type === 'task';
    },
    isGlobalItem() {
      return this.cost_type_status === '@' || this.cost_type_status === 'y'
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.user.company_id
      )
    },
    mod() {
      const rootRefId = this.rootRefId

      const rootMod = this.norm[rootRefId].oMod || null

      if (rootMod && rootMod.mod_id) return rootMod

      return this.$store.getters.defaultMod
    },
    laborFormatterFilled() {
      return (lt) => this.laborFormatter(lt, this.$store, this.mod)
    },
    global() {
      return !this.$store.state.session.scope.company
    },
    isVariation() {
      return !!this.getField('variation_parent_cost_type_id')
    },
    costTypeId() {
      return this.getField('cost_type_id')
    },
    showSuggestedDimensions() {
      if (this.getField('asDimensionsLinked') && this.getField('asDimensionsLinked').length)
        return false

      return true
    },
    defaultMeasureType() {
      return this.getDefaultMeasureType()
    },
    startingSwapParent() {
      return this.parent_cost_type_id
    },
    /**
     * Color coding for linked dimensions
     * @returns {{}|{backgroundColor: string, color: string}}
     */
    linkStyle() {
      const linked = c.makeArray(this.asDimensionsLinked)
      if (!linked.length) return {}

      const dimensions = this.dimTypes

      const abbr = linked[0]
      if (!(abbr in dimensions)) return {}

      return { backgroundColor: `#${dimensions[abbr].color} !important`, color: '#444 !important' }
    },

    /**
     * Checks if this is a synced item being edited in an assembly store context
     * in which case it should be edited separately
     * @returns {boolean|*}
     */
    assemblyEditable() {
      return !this.inAssembly || (!this.getField('cost_type_id') && this.editable)
    },

    baseMeasure() {
      const id = this.getField('unit_of_measure_id')
      return (
        id &&
        this.$store.getters.uomById &&
        this.$store.getters.uomById[String(id)] &&
        this.$store.getters.uomById[String(id)].unit_of_measure_base_measure
      )
    },
    /**
     *
     */
    qtyEquation: {
      get() {
        return this.qtyEquationNoCommit
      },
      set(equation) {
        this.qtyEquationNoCommit = equation
        this.commitAudit()
      }
    },
    /**
     *
     */
    qtyEquationNoCommit: {
      get() {
        return this.getField('cost_type_qty_equation')
      },
      set(equation) {
        this.setField('cost_type_qty_equation', equation)
      }
    },

    /**
     * Parents dimensions
     */
    parentName() {
      const parentRef = this.parentRefId || this.object.parentRefId
      const store = this.store || this.storeName
      const state = store && this.$store.state[store]
      const norm = state && state.normalized
      const parent = norm && norm[parentRef]
      return parent && (parent.assembly_name || parent.quote_name)
    },
    parentDimensions() {
      const parentRef = this.parentRefId || this.object.parentRefId
      const store = this.store || this.storeName
      const state = store && this.$store.state[store]
      const norm = state && state.normalized
      const parent = norm && norm[parentRef]
      const dims = parent && parent.oDimensions

      return dims || {}
    },

    minMarkup() {
      return (
        c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin) - 0.0000001
      )
    },
    warningMinimum() {
      const adj = c.n(this.getField('cost_item_markup_net_adjusted'))
      const min = c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin)
      return adj < min && Math.abs(adj - min) > 0.01
    },
    warningLosing() {
      const adj = c.n(this.getField('cost_item_markup_net_adjusted'))
      return !c.eq(adj, 1, 4) && adj < 1
    },
    margin() {
      return c.markupToMargin(this.getField('cost_item_markup_net_adjusted'))
    },
    variationParentName: {
      get() {
        return this.getField('variation_parent_cost_type_name')
      },
      set(n) {
        this.setField('cost_type_name', n)
        this.setField('variation_parent_cost_type_name', n)
      }
    },
    isStandardCostItem: {
      get() {
        return this.getField('cost_type_is_fee') ||
          this.getField('cost_type_is_addon_group') ||
          this.getField('cost_type_is_variation_parent')
          ? 0
          : 1
      },
      async set(v) {
        this.setField('cost_type_is_fee', 0)
        this.setField('cost_type_is_addon_group', 0)
        this.setField('cost_type_is_variation_parent', v ? 0 : 1)
      }
    },
    isFeeItem: {
      get() {
        return this.getField('cost_type_is_fee') ? 1 : 0
      },
      async set(v) {
        this.setField('cost_type_is_fee', v)
        this.setField('cost_type_is_variation_parent', 0)
        this.setField('cost_type_is_addon_group', 0)
        if (v) {
          this.isSubcontracted = 0
          this.includesMaterials = 1
          this.includesLabor = 0
        }
      }
    },
    isOptionGroup: {
      get() {
        return this.getField('cost_type_is_addon_group')
      },
      async set(v) {
        this.setField('cost_type_is_variation_parent', 0)
        this.setField('cost_type_is_fee', 0)
        this.setField('cost_type_is_addon_group', v)

        if (v) {
          this.includesLabor = 0
          this.isSubcontracted = 0
          this.setField('cost_matrix_materials_cost_net', 0)
        }
      }
    },
    aoAddons: {
      get() {
        return this.getField('aoAddons')
      },
      set(v) {
        if (_.jsonEquals(v, this.getField('aoAddons'))) {
          return
        }

        this.$store.dispatch(`${this.store}/field`, {
          refId: this.reference,
          changes: {
            aoAddons: v
          },
          explicit: true
        })
      }
    },
    isVariationParent: {
      get() {
        return this.getField('cost_type_is_variation_parent')
      },
      async set(v) {
        if (v && !this.getField('variation_parent_cost_type_name')) {
          this.setField('variation_parent_cost_type_name', this.getField('cost_type_name'))
        }
        this.setField('cost_type_is_fee', 0)
        this.setField('cost_type_is_addon_group', 0)
        this.setField('cost_type_is_variation_parent', v)
      }
    },
    targetMarkup: {
      get() {
        return this.getField('cost_item_markup_net_adjusted')
      },
      async set(v) {
        if (!c.eq(v, this.targetMarkup, 6)) {
          const { changes } = await this.$store.dispatch('CostItem/setTargetMarkup', {
            store: this.storeName,
            object: this.cast(),
            targetMarkup: v
          })
          this.fieldDispatch(changes, { cost_item_markup_net_adjusted: v })
        }
      }
    },
    showSummary() {
      return !this.showAll
    },
    showEstimatedHours() {
      return this.estimatedHours && c.eq(this.getField('cost_type_hours_per_unit'), 0)
    },
    includesLabor: {
      get() {
        return this.getField('cost_type_has_labor')
      },
      async set(v) {
        this.subcontractSet = true
        if (!c.eq(v, this.includesLabor, 2)) {
          const { changes } = await this.$store.dispatch('CostType/setHasLabor', {
            store: this.storeName,
            object: this.cast(),
            hasLabor: v
          })
          this.fieldDispatch(changes, { cost_type_has_labor: v })
          this.addDefaults()
        }
      }
    },

    weightUnit: {
      get() {
        return c.n(this.getField('weight_unit_of_measure_id'))
      },

      set(v) {
        const pricePerPurchaseUnit = _.n(v)
        const matrix =
          pricePerPurchaseUnit / c.n(this.getField('cost_type_materials_purchase_qty_per_unit'))

        if (!c.eq(matrix, this.cost_matrix_materials_cost_net, 2)) {
          this.fieldDispatch(
            {
              cost_matrix_materials_cost_net: matrix
            },
            { cost_matrix_materials_cost_net: matrix }
          )
          this.addDefaults()
        }
      }
    },

    pricePerPurchaseUnit: {
      get() {
        return (
          c.n(this.getField('cost_type_materials_purchase_qty_per_unit')) *
          c.n(this.getField('cost_matrix_materials_cost_net'))
        )
      },

      set(v) {
        const pricePerPurchaseUnit = _.n(v)
        const matrix =
          pricePerPurchaseUnit / c.n(this.getField('cost_type_materials_purchase_qty_per_unit'))

        if (!c.eq(matrix, this.cost_matrix_materials_cost_net, 2)) {
          this.fieldDispatch(
            {
              cost_matrix_materials_cost_net: matrix
            },
            { cost_matrix_materials_cost_net: matrix }
          )
          this.addDefaults()
        }
      }
    },

    wastage: {
      get() {
        return c.n(this.getField('cost_type_material_waste_factor_net')) * 100
      },
      async set(v) {
        const waste = _.n(v) / 100
        if (!c.eq(waste, this.wastage, 2)) {
          this.fieldDispatch(
            {
              cost_type_material_waste_factor_net: waste
            },
            { cost_type_material_waste_factor_net: waste }
          )
          this.addDefaults()
        }
      }
    },
    includesMaterials: {
      get() {
        return this.getField('cost_type_has_materials')
      },
      async set(v) {
        this.subcontractSet = true
        if (!c.eq(v, this.includesMaterials, 2)) {
          const { changes } = await this.$store.dispatch('CostType/setHasMaterials', {
            store: this.storeName,
            object: this.cast(),
            hasMaterials: v
          })
          this.fieldDispatch(changes, { cost_type_has_materials: v })
          this.addDefaults()
        }
      }
    },
    // Is this a assembly that is synced or not
    synced() {
      return this.getField('cost_type_id')
    },
    lineItemActions() {
      const a = {
        edit: {
          title: 'Edit',
          icon: 'pencil',
          action: this.edit,
          visible: () => !this.editing
        },
        swap: {
          title: 'Swap with..',
          icon: ['fas', 'right-from-bracket'],
          action: this.swap
        },
        duplicate: {
          title: 'Duplicate',
          icon: 'clone',
          action: this.duplicate
        },
        remove: {
          title: 'Remove',
          icon: 'circle-xmark',
          action: this.remove
        },
        save: {
          title: 'Update in your catalog',
          icon: 'floppy-disk',
          action: () => this.save(false),
          visible: () => this.getField('cost_type_id')
        },
        saveAs: {
          title: 'Save to your catalog',
          icon: 'floppy-disk',
          action: () => this.save(true)
        },
        saveSuper: {
          title: '(GLOBAL) Update in free catalog',
          icon: 'layer-group',
          action: () => this.save(false, true),
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id &&
            this.getField('cost_type_id')
        },
        saveAsSuper: {
          title: '(GLOBAL) Save to free catalog',
          icon: 'layer-group',
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id,
          action: () => this.save(true, true)
        }
      }
      this.$emit('actions', a)
      return a
    },

    auditVal() {
      return {
        cost_item_price_net_base: this.getField('cost_item_price_net_base'),
        cost_item_total_cost_net: this.getField('cost_item_total_cost_net'),
        cost_item_markup_percentage_adjustment: this.getField(
          'cost_item_markup_percentage_adjustment'
        ),
        cost_item_markup_net_adjustment: this.getField('cost_item_markup_net_adjustment'),
        cost_item_price_net_base_adjustment: this.getField('cost_item_price_net_base_adjustment')
      }
    },

    totalMinimumCost() {
      return (
        +this.getField('cost_type_minimum_labor_cost_net') +
        +this.getField('cost_type_minimum_materials_cost_net')
      )
    },

    totalMinimumPrice() {
      return this.totalMinimumCost * +this.getField('cost_item_markup_net_adjusted')
    },

    totalMinimumCostAdjustment() {
      return (
        +this.getField('cost_item_minimum_labor_net_adjustment') +
        +this.getField('cost_item_minimum_materials_net_adjustment')
      )
    },

    totalMinimumPriceAdjustment() {
      return this.totalMinimumCostAdjustment * +this.getField('cost_item_markup_net_adjusted')
    },

    qtyNet: {
      get() {
        return this.getField('cost_item_qty_net')
      },
      async set(qty) {
        if (!c.eq(qty, this.qtyNet, 4)) {
          const { changes } = await this.$store.dispatch('CostItem/setQty', {
            store: this.storeName,
            object: this.cast(),
            qty
          })
          this.fieldDispatch(changes, { cost_item_qty_net: qty })
        }
      }
    },

    qtyBase: {
      get() {
        return this.getField('cost_item_qty_net_base')
      },
      async set(qty) {
        if (!c.eq(qty, this.qtyBase, 4)) {
          const multiplied = qty * c.n(this.getField('quantity_multiplier'))
          const { changes } = await this.$store.dispatch('CostItem/setQty', {
            store: this.storeName,
            object: this.cast(),
            qty: multiplied
          })
          this.fieldDispatch(changes, { cost_item_qty_net_base: qty })
        }
      }
    },

    linkable() {
      return this.isUnitOfMeasureLinkable(c.extractSingle(this.getField('unit_of_measure_id')))
    },

    hoursPerUnit() {
      return c.format(this.getField('cost_type_hours_per_unit'), 'hours')
    },
    viewOpen() {
      return (
        (!this.draggable ||
          this.editing ||
          c.isempty(this.getField('cost_type_name')) ||
          (!this.getField('cost_type_id') &&
            c.eq(c.toNum(this.getField('cost_item_price_net_base')), 0, 3))) &&
        this.editable
      )
    },
    isSubcontracted: {
      get() {
        return this.getField('cost_type_is_subcontracted')
      },
      async set(v) {
        if (!c.eq(v, this.isSubcontracted, 2)) {
          const { changes } = await this.$store.dispatch('CostType/setIsSubcontracted', {
            store: this.storeName,
            object: this.cast(),
            isSubcontracted: v
          })
          this.fieldDispatch(changes, { cost_type_is_subcontracted: v ? 1 : 0 })
          this.addDefaults()
        }
      }
    },
    stageId: {
      get() {
        return this.getField('stage_id')
      },
      async set(v) {
        if (String(v) !== String(this.stageId)) {
          const { changes } = await this.$store.dispatch('CostType/setStage', {
            store: this.storeName,
            object: this.cast(),
            stage: v,
            confirm: false
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
    },
    csiCodeId: {
      get() {
        return this.getField('csi_code_id')
      },
      async set(v) {
        return this.setField('csi_code_id', v)
      }
    },
    taxId: {
      get() {
        return this.getField('tax_id')
      },
      async set(v) {
        if (String(this.taxId) !== String(v)) {
          this.setField('tax_id', v)
          const { changes } = await this.$store.dispatch('CostType/setTax', {
            store: this.storeName,
            object: this.cast(),
            tax: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
    },
    vendorId: {
      get() {
        return this.getField('vendor_id')
      },
      async set(v) {
        if (String(this.vendorId) !== String(v)) {
          this.setField('vendor_id', v)
          const { changes } = await this.$store.dispatch('CostType/setVendor', {
            store: this.storeName,
            object: this.cast(),
            vendor: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
    },
    tradeTypeId: {
      get() {
        return this.getField('trade_type_id')
      },
      async set(v) {
        if (String(v) !== String(this.tradeTypeId)) {
          const { changes } = await this.$store.dispatch('CostType/setTradeType', {
            store: this.storeName,
            object: this.cast(),
            tradeType: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()

          this.setSuggestedVendor()
        }
      }
    },
    unitOfMeasureId: {
      get() {
        return this.purchaseUnitOfMeasure
      },
      set(v) {
        this.purchaseUnitOfMeasure = v
      }
    },
    purchaseUnitOfMeasureId: {
      get() {
        return this.purchaseUnitOfMeasure
      },
      set(v) {
        this.purchaseUnitOfMeasure = v
      }
    },
    weightUnitOfMeasure: {
      get() {
        return this.getField('weight_unit_of_measure_id')
      },
      async set(v) {
        if (!_.isScalar(v)) return
        if (String(v) !== String(this.weightUnitOfMeasure)) {
          this.fieldDispatch({ weight_unit_of_measure_id: v })
          const { changes } = await this.$store.dispatch('CostType/setWeightUnitOfMeasure', {
            store: this.storeName,
            object: this.cast(),
            weightUnitOfMeasure: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
    },
    purchaseUnitOfMeasure: {
      get() {
        return this.getField('purchase_unit_of_measure_id')
      },
      async set(v) {
        if (!_.isScalar(v)) return
        if (String(v) !== String(this.purchaseUnitOfMeasure)) {
          this.fieldDispatch({ purchase_unit_of_measure_id: v })
          const { changes } = await this.$store.dispatch('CostType/setPurchaseUnitOfMeasure', {
            store: this.storeName,
            object: this.cast(),
            purchaseUnitOfMeasure: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
        // make sure to not use cost_type_materials_purchase_qty_per_unit CS-250
        if (this.unitOfMeasure === this.purchaseUnitOfMeasure) {
          this.cost_type_materials_purchase_qty_per_unit = null
        }
      }
    },
    unitOfMeasure: {
      get() {
        return this.unit_of_measure_id
      },
      async set(v) {
        if (!_.isScalar(v)) return
        if (String(v) !== String(this.unitOfMeasure)) {
          this.fieldDispatch({ unit_of_measure_id: v })
          const { changes } = await this.$store.dispatch('CostType/setUnitOfMeasure', {
            store: this.storeName,
            object: this.cast(),
            unitOfMeasure: v
          })
          this.purchaseUnitOfMeasure = v
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
    },
    laborRate: {
      get() {
        return this.labor_type_rate_net
      },
      set(rate) {
        if (!c.eq(rate, this.labor_type_rate_net, 2)) {
          this.labor_type_rate_net = rate
          if (this.labor_type_id && AutoCost.isAutoCostLaborTypeId(this.labor_type_id)) return
          c.throttle(
            () => {
              this.updateLaborType(rate)
            },
            { delay: 1500 }
          )
        }
      }
    },
    laborType: {
      get() {
        return this.getField('labor_type_id')
      },
      async set(v) {
        if (!_.isScalar(v)) return
        if (String(v) !== String(this.laborType)) {
          const { changes } = await this.$store.dispatch('CostType/setLaborType', {
            store: this.storeName,
            object: this.cast(),
            laborType: v
          })
          this.fieldDispatch(changes)
          this.addDefaults()
        }
      }
      // set(val) {
      //   this.setField('labor_type_id', val);
      //   this.setLaborType(val);
      // },
    },
    totalHoursBase: {
      get() {
        return this.getField('cost_item_total_hours_base')
      },
      async set(v) {
        if (!c.eq(v, this.totalHours, 2)) {
          const multiplied = _.n(v) * _.n(this.getField('quantity_multiplier'))
          const { changes } = await this.$store.dispatch('CostItem/setTotalHours', {
            store: this.storeName,
            object: this.cast(),
            totalHours: multiplied
          })
          this.fieldDispatch(changes, { cost_item_total_hours_base: v })
        }
      }
    },
    totalHours: {
      get() {
        return this.getField('cost_item_total_hours')
      },
      async set(v) {
        if (!c.eq(v, this.totalHours, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setTotalHours', {
            store: this.storeName,
            object: this.cast(),
            totalHours: v,
            holdPrice: true
          })
          this.fieldDispatch(changes, { cost_item_total_hours: v })
        }
      }
    },
    laborCost: {
      get() {
        return this.getField('cost_matrix_labor_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.laborCost, 2)) {
          const { changes } = await this.$store.dispatch('CostType/setLaborCost', {
            store: this.storeName,
            object: this.cast(),
            laborCost: v
          })
          this.fieldDispatch(changes, { cost_matrix_labor_cost_net: v })
        }
      }
    },
    totalProfit: {
      get() {
        return (
          this.getField('cost_item_price_net_base') - this.getField('cost_item_total_cost_net_base')
        )
      },
      async set(v) {
        if (!c.eq(v, this.totalProfit, 2)) {
          const targetMarkup =
            (_.n(v) + _.n(this.getField('cost_item_price_net_base'))) /
            (_.n(this.getField('cost_item_total_cost_net_base')) || 1)
          const { changes } = await this.$store.dispatch('CostItem/setTargetMarkup', {
            store: this.storeName,
            object: this.cast(),
            targetMarkup
          })
          this.fieldDispatch(changes, { cost_matrix_markup_net: changes.cost_matrix_markup_net })
        }
      }
    },
    totalCost: {
      get() {
        return this.getField('cost_item_total_cost_net_base')
      },
      async set(v) {
        if (!c.eq(v, this.totalCost, 2)) {
          const totalCost = _.n(v) * (_.n(this.getField('quantity_multiplier')) || 1)
          const { changes } = await this.$store.dispatch('CostItem/setTotalCost', {
            store: this.storeName,
            object: this.cast(),
            totalCost
          })
          this.fieldDispatch(changes, { cost_item_total_cost_net_base: v })
        }
      }
    },
    unpaidToVendor: {
      get() {
        return this.getField('cost_item_unpaid_to_vendor_net')
      }
    },
    paidToVendor: {
      get() {
        return this.getField('cost_item_unpaid_to_vendor_net')
      }
    },
    actualCost: {
      get() {
        return this.getField('cost_item_actual_total_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.actualCost, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setTotalActualCost', {
            store: this.storeName,
            object: this.cast(),
            totalActualCost: v
          })
          this.fieldDispatch(changes, { cost_item_actual_total_cost_net: v })
        }
      }
    },
    totalMaterialsCost: {
      get() {
        return this.getField('cost_item_materials_cost_net')
      },
      async set(v) {
        if (this.totalMaterialsCost !== v) {
          const { changes } = await this.$store.dispatch('CostItem/setMaterialsCost', {
            store: this.storeName,
            object: this.cast(),
            materialsCost: v,
            holdPrice: false
          })
          this.fieldDispatch(changes, { cost_item_materials_cost_net: v })
        }
      }
    },

    materialPurchaseDisplay: {
      get() {
        if (this.getField('cost_type_is_subcontracted')) {
          return 'Provided by sub'
        }

        if (!this.getField('cost_type_has_materials')) {
          return 'No materials'
        }

        return `${this.getField('purchase_cost_item_qty_net')} ${this.getField('purchase_unit_of_measure_abbr') || this.getField('unit_of_measure_abbr')}`
      },
      set() {}
    },

    materialsCostMultiplied: {
      get() {
        return this.getField('cost_item_materials_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.materialsCostMultiplied, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setMaterialsCost', {
            store: this.storeName,
            object: this.cast(),
            materialsCost: v,
            holdPrice: true
          })
          this.fieldDispatch(changes, { cost_item_materials_cost_net: v })
        }
      }
    },
    laborCostMultiplied: {
      get() {
        return this.getField('cost_item_labor_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.laborCostMultiplied, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setLaborCost', {
            store: this.storeName,
            object: this.cast(),
            laborCost: v,
            holdPrice: true
          })
          this.fieldDispatch(changes, { cost_item_labor_cost_net: v })
        }
      }
    },
    totalCostMultiplied: {
      get() {
        return this.getField('cost_item_total_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.totalCostMultiplied, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setTotalCost', {
            store: this.storeName,
            object: this.cast(),
            totalCost: v,
            holdPrice: true
          })
          this.fieldDispatch(changes, { cost_item_total_cost_net: v })
        }
      }
    },
    aggregateCost: {
      get() {
        return this.getField('cost_matrix_aggregate_cost_net')
      },
      async set(v) {
        if (!c.eq(v, this.aggregateCost, 2)) {
          const { changes } = await this.$store.dispatch('CostType/setAggregateCost', {
            store: this.storeName,
            object: this.cast(),
            aggregateCost: v
          })
          this.fieldDispatch(changes, { cost_matrix_aggregate_cost_net: v })
        }
      }
    },
    /**
     * Does not include static, or minimum cost adjustments
     */
    unitPrice: {
      get() {
        // if (this.type === 'cost_item' && this.getField('cost_item_markup_net_adjustment')) {
        //   return _.divide(_.n(this.getField('cost_item_price_net_base')),
        //     _.n(this.getField('cost_item_qty_net_base')));
        // }

        if (this.type === 'cost_item') {
          return (
            this.getField('cost_matrix_aggregate_cost_net') *
            this.getField('cost_item_markup_net_adjusted')
          )
        }

        return this.getField('cost_matrix_rate_net')
        // return this.getField('cost_matrix_rate_net');
      },
      async set(v) {
        if (!c.eq(v, this.unitPrice, 2)) {
          const price = v

          const { changes } = await this.$store.dispatch('CostType/setPrice', {
            store: this.storeName,
            object: this.cast(),
            price
          })

          this.fieldDispatch(changes, { cost_matrix_rate_net: v })
        }
      }
    },
    markupAdjusted: {
      get() {
        return this.getField('cost_item_markup_net_adjusted')
      },
      async set(v) {
        if (!c.eq(v, this.markupAdjusted, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setTargetMarkup', {
            store: this.storeName,
            object: this.cast(),
            targetMarkup: v
          })
          this.fieldDispatch(changes, { cost_item_markup_net_adjusted: v })
        }
      }
    },
    totalPriceMultiplied: {
      get() {
        return this.getField('cost_item_price_net')
      },
      async set(v) {
        if (!c.eq(v, this.totalPriceMultiplied, 2)) {
          const { changes } = await this.$store.dispatch('CostItem/setTotalPrice', {
            store: this.storeName,
            object: this.cast(),
            totalPrice: v
          })
          this.fieldDispatch(changes, { cost_item_price_net: v })
        }
      }
    },
    totalPrice: {
      get() {
        return this.getField('cost_item_price_net_base')
      },
      async set(v) {
        if (!c.eq(v, this.totalPrice, 2)) {
          const multiplied = _.n(v) * (+this.getField('quantity_multiplier') || 1)
          const { changes } = await this.$store.dispatch('CostItem/setTotalPrice', {
            store: this.storeName,
            object: this.cast(),
            totalPrice: multiplied
          })
          this.fieldDispatch(changes, { cost_item_price_net_base: v })
        }
      }
    },
    profit: {
      get() {
        return this.totalPrice - this.totalCost
      },
      async set(v) {
        if (!c.eq(v, this.profit, 2)) {
          const price = c.n(v) + this.totalCost
          this.totalPrice = price
        }
      }
    }
  },
  methods: {
    async editVariationParent() {
      const parent = this.getField('variation_parent_cost_type_id')
      if (!parent) return false

      return this.$store.dispatch('edit', {
        type: 'cost_type',
        id: parent,
        modal: {
          oMod: await this.$store.dispatch('Quote/getQuoteMod', {
            refId: this.refId || this.reference || this.object.refId,
            store: this.store || this.storeName
          })
        }
      })
    },
    async dimensionBadgeHandler(dim) {
      if (this.$refs[dim.abbr] && this.$refs[dim.abbr].length) {
        this.$refs[dim.abbr].forEach((h) => h.close())
      }
      const equation = dim.abbr
      const qtyBase = 0

      const isCostItem = this.type === 'cost_item'

      let diffQty = false
      let qty = 0
      if (isCostItem) {
        const mult = this.getField('quantity_multiplier') || 1
        qty = qtyBase * (mult || 1)
        const orig = this.qtyBase * (mult || 1)
        diffQty = !c.eq(qty, orig)
      }

      if (diffQty || equation !== this.qtyEquation) {
        if (isCostItem) this.setQty(qty, equation)
        else this.qtyEquation = equation

        const uom = await this.$store.dispatch(
          'UnitOfMeasure/getSuggestedUnitOfMeasureFromDimension',
          dim
        )

        if (
          c.getMeasureTypeForUnitOfMeasure(uom) !==
          c.getMeasureTypeForUnitOfMeasure(this.unitOfMeasure)
        ) {
          this.unitOfMeasure = uom
        }
      }

      if (this.$refs.dimDrop) this.$refs.dimDrop.close()
    },
    measureTypeHandler(mt) {
      const measureType = c.getMeasureTypeForUnitOfMeasure(mt)
      if (measureType === 'count') {
        this.unitOfMeasure = 'count'
      }

      let backupDefault = 'ft'
      if (mt === 'volume') backupDefault = 'yd3'
      if (mt === 'area') backupDefault = 'ft2'
      if (mt === 'weight') backupDefault = 'lbs'

      const metaConstant = `default${c.titleCase(mt)}Measure`

      const uom = this.getMetaItem(this.metaConstants[metaConstant]) || backupDefault

      this.unitOfMeasure = uom
    },
    async editLaborType() {
      const { object } = await this.$store.dispatch('edit', {
        type: 'labor_type',
        id: this.laborType
      })
      this.laborType = null
      await c.throttle(() => {}, { delay: 1000 })
      this.laborType = object.labor_type_id
    },
    async updateLaborType(rate) {
      const id = this.laborType

      const { object } = await this.$store.dispatch('LaborType/resolveObject', {
        id
      })

      if (c.eq(object.labor_type_rate_net, rate, 2)) {
        return
      }

      const coid =
        (this.$store.state.session.company && this.$store.state.session.company.company_id) || null
      if (
        !object.company_id ||
        String(object.company_id) !== String(coid) ||
        !(await this.$store.dispatch('modal/asyncConfirm', {
          message: `Would you like to update ${object.labor_type_name} (will affect
            all items with this labor type), or do you want to create a new labor type?`,
          yes: 'Update this labor type',
          no: 'Create new labor type'
        }))
      ) {
        object.labor_type_id = null
        object.company_id = coid
        object.labor_type_name = `${object.labor_type_name} (${this.$store.state.session.company.company_name})`
      }

      object.labor_type_rate_net = rate

      if (object.company_id) {
        object.labor_type_is_indexed = 0
      }

      const { object: saved } = await this.$store.dispatch('LaborType/save', {
        object,
        go: false
      })

      this.laborType = saved.labor_type_id
    },
    qtyBlurHandler() {
      this.linkingValueInput = false
    },
    getDefaultMeasureType() {
      const uom = this.unit_of_measure_id ? String(this.unit_of_measure_id) : null
      const abbr = this.unit_of_measure_abbr

      if (uom === 'count' || uom === 'each' || String(uom) === '2') return 'count'

      if (/kg|lbs|oz|ton/.test(uom)) {
        return 'weight'
      } else if (/\w+2/.test(uom) || /\w+2/.test(abbr)) {
        return 'area'
      } else if (/\w+3/.test(uom) || /\w+3/.test(abbr)) {
        return 'volume'
      } else if (/^[a-zA-Z]+$/.test(uom) || /^(ft|mm|m|yd)$/.test(abbr)) {
        return 'length'
      }

      return 'count'
    },
    async setOriginDimension(abbr, value, measure, equation = null, parentRefId = null) {
      const originRef = parentRefId || this.getDimensionOriginRefId(abbr)
      const dims = _.imm(this.norm[originRef].oDimensions)
      const dimMeasureType = dims[abbr].measureType
      const measureMeasureType = c.getMeasureTypeForUnitOfMeasure(measure)
      const dimDefaultMeasure =
        dimMeasureType === 'count'
          ? 'count'
          : this.getMetaItem(`default${c.titleCase(dimMeasureType)}Measure`)

      dims[abbr] = {
        ...this.dimTypes[abbr],
        ...(dims[abbr] || {}),
        inherit: 0,
        value,
        equation,
        explicitlySet: 1
      }

      if (dimDefaultMeasure || (measureMeasureType === dimMeasureType && measure)) {
        dims[abbr].measure = measureMeasureType === dimMeasureType ? measure : dimDefaultMeasure
      }

      return this.$store.dispatch(`${this.storeName}/field`, {
        changes: {
          oDimensions: dims
        },
        refId: originRef,
        explicit: true
      })
    },
    getDimensionOriginRefId(abbr) {
      const norm = this.norm
      let parentRefId = norm[this.refId].parentRefId
      let originRefId = parentRefId

      while (parentRefId) {
        originRefId = parentRefId
        if (
          norm[parentRefId].oDimensions &&
          (!(abbr in norm[parentRefId].oDimensions) || !norm[parentRefId].oDimensions[abbr].inherit)
        ) {
          break // go no further
        }

        parentRefId = norm[parentRefId].parentRefId
      }

      return originRefId
    },
    async setQty(qtyBase, equation) {
      this.addLoading()

      const qty = qtyBase * (this.quantity_multiplier || 1)

      const orig = this.qtyBase * (this.quantity_multiplier || 1)

      if (!c.eq(qty, orig) || equation !== this.qtyEquation) {
        // If this is linked, the dimension is zero, and now
        // user is setting the value, set the dimension instead
        if (
          this.cost_item_link_qty &&
          this.asDimensionsLinked.length === 1 &&
          qtyBase > 0 &&
          (c.eq(this.qtyBase, 0) || this.linkingValueInput)
        ) {
          this.linkingValueInput = true
          // If the user includes local dimensions in the equation, the
          // furthest up this value can be pushed is the closest parent
          // to make sure the values are correct
          const parentRefId =
            equation &&
            new RegExp(`(\b|^)(${Object.keys(this.dimTypes).join('|')})(\b|$)`).test(equation)
              ? this.parentRefId
              : null
          const measure = c.getMeasureForUnitOfMeasure(this.unit_of_measure_id)
          this.setOriginDimension(
            this.asDimensionsLinked[0],
            qtyBase,
            measure,
            equation,
            parentRefId
          )
          this.qtyEquation = this.asDimensionsLinked[0]
          this.removeLoading()
          return
        }

        const { changes } = await this.$store.dispatch('CostItem/setQty', {
          store: this.storeName,
          object: this.cast(),
          qty,
          equation
        })
        this.fieldDispatch(changes)
        this.commitAudit()
      }
      this.removeLoading()
    },

    async unsyncIfNotExists() {
      const exists = await this.confirmTypeExists()

      if (!exists) {
        this.setField('cost_type_id', null)
        this.setField('cost_matrix_id', null)
      }

      return exists
    },

    async confirmTypeExists() {
      if (this.existsConfirmed) {
        return true
      }

      let id
      try {
        id = await this.$store.dispatch('CostType/fetchField', {
          field: 'cost_type_id',
          id: this.object.cost_type_id
        })
      } catch (e) {
        return false
      }

      if (!id) {
        return false
      }

      this.existsConfirmed = true
      return true
    },

    async ensureItemId() {
      if (!this.getField('item_id')) {
        this.ensureItemExists()
      }

      return true
    },

    async ensureItemExists() {
      const itemId = this.getField('item_id')
      try {
        if (!itemId) {
          throw new Error('no item id')
        }

        await this.$store.dispatch('Item/fetch', {
          quick: true,
          force: true,
          id: itemId
        })
        return true
      } catch (e) {
        await this.saveSingleItem()
        return true
      }
    },

    async saveSingleItem() {
      const cast = this.cast()
      cast.quote_id = this.norm[this.rootRefId].quote_id
      cast.change_order_id = this.norm[this.rootRefId].change_order_id

      const { object } = await this.$store.dispatch('ajax', {
        path: 'item/saveSingleQuoteItem',
        data: {
          object: cast
        }
      })

      await this.setField('item_id', object.item_id)

      return { object }
    },

    async markPending() {
      this.setField('item_company_status', 'p')
      return c.throttle(
        async () => {
          // If after the throttle delay they havent switched the
          // status again, THEN dispatch
          if (this.getField('item_company_status') === 'p') {
            const { object } = await this.$store.dispatch('CostItem/markPending', {
              selected: [
                {
                  item_id: this.getField('item_id'),
                  type: 'cost_item'
                }
              ]
            })
            return this.importItemFields(object)
          }
          return false
        },
        { delay: 1000, key: this.uid }
      )
    },
    async markInProgress() {
      this.setField('item_company_status', 'f')
      return c.throttle(
        async () => {
          // If after the throttle delay they havent switched the
          // status again, THEN dispatch
          if (this.getField('item_company_status') === 'f') {
            const { object } = await this.$store.dispatch('CostItem/markInProgress', {
              selected: [
                {
                  item_id: this.getField('item_id'),
                  type: 'cost_item'
                }
              ]
            })
            return this.importItemFields(object)
          }
          return false
        },
        { delay: 1000, key: this.uid }
      )
    },
    async markCompleted() {
      this.setField('item_company_status', 'c')
      return c.throttle(
        async () => {
          // If after the throttle delay they havent switched the
          // status again, THEN dispatch
          if (this.getField('item_company_status') === 'c') {
            const { object } = await this.$store.dispatch('CostItem/markCompleted', {
              selected: [
                {
                  item_id: this.getField('item_id'),
                  type: 'cost_item'
                }
              ]
            })
            return this.importItemFields(object)
          }
          return false
        },
        { delay: 1000, key: this.uid }
      )
    },
    importItemFields(item) {
      Object.keys(item).forEach((field) => {
        if (/^item_/.test(field)) {
          this.setField(field, item[field])
        }
      })
      return this
    },
    initiatePayment() {},
    cancelPayment() {},
    setVendor(vendor = null) {
      if (vendor !== null && vendor) {
        this.setField('vendor_id', vendor.vendor_id)
        this.setField('vendor_name', vendor.vendor_name)
        // this.setField('item_is_direct_pay', 1);
      } else {
        this.setField('vendor_id', null)
        this.setField('vendor_name', '')
        // this.setField('item_is_direct_pay', 0);
      }
    },
    setMarkupToDefault() {
      this.targetMarkup = this.$store.getters.defaultMarkup + 0.01
    },
    setMarkupToMinimum() {
      this.targetMarkup =
        c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin) + 0.01
    },
    afterSelect() {
      this.$nextTick().then(() => {
        const existingMarkup = +this.getField('cost_matrix_markup_net')

        c.throttle(
          () => {
            if (
              this.getField('type') !== 'quote' &&
              !this.getField('cost_type_id') &&
              !this.getField('cost_type_name') &&
              (!this.unitPrice || c.eq(this.unitPrice, 0)) &&
              !existingMarkup
            ) {
              this.setField('cost_matrix_markup_net', this.$store.getters.defaultMarkup)
            }

            // Load up the labor rate fresh
            if (this.getField('labor_type_id')) {
              // Should ONLY do this on NEW items
              // this.setLaborType(this.labor_type_id);
            }
          },
          { key: this.uid }
        )
      })
    },
    getSuggestedVendors() {
      return this.$store
        .dispatch('Vendor/search', {
          filters: {
            trade_type_ids: `INSET${this.getField('trade_type_id')}`,
            vendor_status: 'a',
            ...(this.getField('cost_type_has_materials') ? { vendor_has_materials: 1 } : {}),
            ...(this.getField('cost_type_has_labor') && this.getField('cost_type_is_subcontracted')
              ? { vendor_has_labor: 1 }
              : {})
          }
        })
        .then(({ set }) => {
          this.suggestedVendors = set
          return Promise.resolve(this.suggestedVendors)
        })
    },
    setSuggestedVendor() {
      return this.getSuggestedVendors().then((vendors) => {
        if (vendors.length) {
          this.setVendor(vendors[0])
        }
        return Promise.resolve(this.getField('vendor_id'))
      })
    },
    isUnitOfMeasureLinkable(id) {
      return c.isUnitOfMeasureLinkable(id)
    },

    async save(asNew = false, asSuper = false) {
      this.addLoading()
      this.$emit('save')
      await this.commit()
      this.beforeSave()

      let savePayload = {}
      try {
        savePayload = await this.$store.dispatch('CostType/saveFromCostItem', {
          refId: this.refId,
          store: this.storeName,
          asSuper,
          asNew
        })

        const { object } = savePayload

        await this.setField('cost_type_id', object.cost_type_id)
        await this.setField('company_id', object.company_id)
        await this.setField('cost_type_status', object.cost_type_status)
        await this.setField('parent_cost_type_id', object.parent_cost_type_id)

        this.afterSave()

        this.$emit('saved', { ...savePayload })

        return savePayload
      } catch (err) {
        console.error(err)
      } finally {
        this.endLoading()
      }
    },
    isTaskDue(t) {
      const s =
        this.rootRefId && this.norm[this.rootRefId] && this.norm[this.rootRefId].quote_status
          ? this.norm[this.rootRefId].quote_status
          : false
      if (!s) return false
      return (
        t.task_status === 'u' &&
        (t.task_id ||
          t.task_stage_object_status === 'p' ||
          (s === 'k' && /k|p/.test(t.task_stage_object_status)) ||
          (s === 'f' && /f|k|p/.test(t.task_stage_object_status)))
      )
    },
    async addTask() {
      const status =
        this.rootRefId && this.norm[this.rootRefId] && this.norm[this.rootRefId].quote_status
          ? this.norm[this.rootRefId].quote_status
          : 'p'
      const { object } = await this.$store.dispatch('Task/buildDefaultObject', {
        type: 'task',
        embue: {
          task_is_stage: 1,
          task_is_required: 1,
          task_stage_object_type: 'quote',
          task_stage_object_status: status
        }
      })

      this.setNormalizedField('aoStageTasks', [...this.getNormalizedField('aoStageTasks'), object])
    },
    clickAway(e) {
      if (!$(e.target).closest('.drop').length) {
        this.$nextTick(() => {
          this.editing = false
        })
      }
    },
    doneEditing() {
      this.editing = false
      this.draggable = true
    },
    // async remove() {
    //   c.bench(true);
    //   this.$emit('close');
    //   await this.$nextTick();
    //   // this.$destroy();
    //   await this.$store.dispatch(`${this.store}/removeChild`, { refId: this.refId });
    //   c.bench('cost item mixin return');
    //   return this;
    // },
    edit() {
      this.editing = true
    },
    async linkQty() {
      if (this.type === 'cost_item') {
        const { changes } = await this.$store.dispatch('CostItem/linkQty', {
          store: this.storeName,
          object: this.cast()
        })
        this.fieldDispatch(changes)
      }
    },
    async unlinkQty() {
      if (this.type === 'cost_item') {
        const { changes } = await this.$store.dispatch('CostItem/unlinkQty', {
          store: this.storeName,
          object: this.cast()
        })
        this.fieldDispatch(changes)
      }
    },
    titleClick(ref) {
      this.draggable = false
      setTimeout(() => {
        try {
          if (this.$refs[ref] && typeof this.$refs[ref].focus === 'function') {
            this.$refs[ref].focus()
          }
        } catch (e) {
          // do nothing
        }
      }, 200)
    },
    makeDraggable() {
      setTimeout(() => {
        this.draggable = true
      }, 300)
    }
  },
  watch: {
    cost_matrix_aggregate_cost_net(a, b) {
      if (a !== null && b !== null && this.getField('cost_type_is_subcontracted')) {
        c.throttle(
          () => {
            // estimate hours
            this.$store
              .dispatch('LaborType/fetch', {
                force: false,
                id: this.getField('labor_rate_id') || 3 // general
              })
              .then(({ object }) => {
                const multiplier = this.getField('cost_type_has_materials') ? 0.4 : 0.5
                this.estimatedHours =
                  (this.getField('cost_matrix_aggregate_cost_net') * multiplier) /
                  object.labor_type_rate_net

                if (!this.getField('cost_type_hours_per_unit')) {
                  this.setField('cost_type_hours_per_unit', this.estimatedHours)
                }
              })
          },
          { key: this.uid }
        )
      }
    },
    lineItemActions(a) {
      this.$emit('actions', a)
    },
    editable(val) {
      this.draggable = !!val
    },
    editing(val) {
      this.$emit('expanded', !!val)
    },
    parent(val) {
      this.parentRefId = val
    }
  },
  mounted() {
    this.hoursEntered = this.getField('cost_type_hours_per_unit')
    if (
      c.isempty(this.getField('cost_type_name')) ||
      c.eq(c.toNum(this.getField('cost_matrix_rate_net')), 0, 4)
    ) {
      this.draggable = false
      this.$nextTick(() => {
        if (this.$refs.title && typeof this.$refs.title.focus === 'function')
          this.$refs.title.focus()
      })
    }
  },
  props: {
    showForCost: {
      default: () => ['cost', 'price']
    },
    // If root object is an assembly
    inAssembly: {
      default: false
    },
    editable: {
      required: false,
      default() {
        return true
      }
    },
    store: {
      default: 'Quote'
    }
  },
  emits: ['actions', 'save', 'saved', 'expanded']
}
