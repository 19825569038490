<template>
  <card-section class="itemized-invoicing">
    <!-- Itemized wrapper need this to fix auto added margin -->
    <div class="itemized-wrapper">
      <!-- Itemized list header -->
      <div class="itemized-header flex justify-between items-center">
        <div class="flex pr-3 py-3">
          <span class="flex justify-end">
            <checkbox v-if="!readonly" @input="selectAll" v-model="isSelected"></checkbox>
          </span>
          <strong :class="['flex items-center itemized-name', readonly ? '' : 'ml-2']">
            {{ readonly ? $t('Name') : $t('Select all') }}
          </strong>
        </div>
        <div class="flex justify-end pl-3 py-3">
          <strong>{{ $t('Price') }}</strong>
        </div>
      </div>

      <!-- Itemized list body -->
      <div class="itemized-body" v-if="normalized && normalized[parentRefId] && !update">
        <template>
          <ItemizedListItem
            v-for="ref in normalizedSet"
            :key="ref"
            :item="normalized[ref] || {}"
            :parentRefId="parentRefId"
            :normalized="normalized"
            :expanded="expanded"
            :selected="selected"
            :readonly="readonly"
            :invoiced="invoiced"
            :refId="ref"
          />
        </template>
      </div>
    </div>
  </card-section>
</template>

<script>
import ItemizedListItem from './ItemizedListItem.vue'
import eventBus from '../../../eventBus'

export default {
  name: 'ItemizedList',
  emits: ['onChange'],
  data() {
    return {
      selected: [],
      hasSelectedAll: 0,
      invoiced: [],
      update: false,
      isSelected: false
    }
  },
  async mounted() {
    // if readonly we want to listen to edits
    if (this.readonly) {
      eventBus.$on('itemized-item-select', this.forceUpdate)
      eventBus.$on('itemized-item-select-group', this.forceUpdate)
      return
    }

    // events to send updates to selected
    eventBus.$on('itemized-item-select', this.select)
    eventBus.$on('itemized-item-select-group', this.selectGroup)

    if (!this.quoteId) return

    // fetch the currently invoiced
    const { payload } = await this.$store.dispatch('ajax', {
      path: 'line_item/fetchLineItems',
      data: {
        quoteId: this.quoteId
      }
    })
    this.invoiced = payload || []
  },
  beforeUnmount() {
    if (this.readonly) {
      eventBus.$off('itemized-item-select', this.forceUpdate)
      eventBus.$off('itemized-item-select-group', this.forceUpdate)
      return
    }
    eventBus.$off('itemized-item-select', this.select)
    eventBus.$off('itemized-item-select-group', this.selectGroup)
  },
  computed: {
    // Get the root parent ref id
    parentRefId() {
      return c.getNormalizedRootRefId(this.normalized)
    },
    normalizedSet() {
      const parent = this.normalized[this.parentRefId]
      const children = parent.aoChildren
      return children.length && children.length > 0 ? children : Object.keys(this.normalized)
    }
  },
  methods: {
    /**
     * Handle selecting one item
     * @param {Object} item
     */
    select(item) {
      const isSelected = this.selected.includes(item.item_id)
      if (!isSelected) {
        const parents = c.getAncestors(this.normalized, [item.item_id])
        this.selected = [...this.selected, ...parents]
        this.selected.push(item.item_id)
        this.$emit('onChange', this.selected)
        return
      }
      const index = this.selected.indexOf(item.item_id)
      this.selected.splice(index, 1)
      this.$emit('onChange', this.selected)
    },
    /**
     * Handle select all the items
     */
    selectAll() {
      this.hasSelectedAll = !this.hasSelectedAll
      if (!this.hasSelectedAll) {
        this.selected = []
        this.$emit('onChange', this.selected)
        return
      }
      Object.keys(this.normalized).forEach((ref) => {
        this.selected.push(this.normalized[ref].item_id)
      })
      this.$emit('onChange', this.selected)
    },
    /**
     * Handle when selecting a group of items / assembly
     */
    selectGroup(item) {
      // get the children of group
      const groupIds = []
      c.getChildren(this.normalized, [item.refId]).forEach((ref) => {
        groupIds.push(this.normalized[ref].item_id)
      })
      // include the selected item id
      const allItemsIds = [...groupIds, item.item_id, item.parentRefId]
      // check to see if its already selected
      const isSelected = this.selected.includes(item.item_id)
      if (!isSelected) {
        // add items to selected
        this.selected = [...this.selected, ...allItemsIds]
        this.$emit('onChange', this.selected)
        return
      }
      // remove items from selected
      const filtered = this.selected.filter((id) => !allItemsIds.includes(id))
      this.selected = filtered
      this.$emit('onChange', this.selected)
    },
    /**
     * Force a rerender of the itemized list
     */
    async forceUpdate() {
      this.update = true
      await this.$nextTick()
      this.update = false
    }
  },
  components: {
    ItemizedListItem
  },
  props: {
    normalized: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    quoteId: {
      type: Number
    },
    expanded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
