<template>
  <span class="flex flex-row gap-1 justify-start items-stretch !m-0" v-if="inline && !heading">
    <spin-container :loading="loading" :size="20" style="width: 100%">
      <span class="steps-inner">
        <a
          @click.stop.prevent.capture="dispatch(step)"
          :class="`step-badge badge badge-pill badge-${step.color}`"
          v-for="(step, index) in steps"
          :key="index"
        >
          <font-awesome-icon v-if="step.glyph" :icon="step.glyph" fixed-width />&nbsp;
          <span v-html="showShort ? step.short : step.name"></span>
        </a>
      </span>
    </spin-container>
  </span>

  <span class="steps-container steps-container-inline !m-0" v-else-if="inline && heading">
    <span v-if="!minimizeBtn" class="steps-inner">
      <span v-for="(step, index) in steps" :key="index">
        <btn @click="dispatch(step)" class="borderless !min-w-fit">
          <font-awesome-icon v-if="step.glyph" :icon="step.glyph" fixed-width class="mr-2" />&nbsp;
          <span>{{ step.short }}</span>
        </btn>
      </span>
    </span>

    <span v-else class="steps-inner">
      <drop
        ref="next-steps-drop"
        position="bottom right"
        targetAttachment="bottom right"
        offset="0px -7px"
      >
        <template #button>
          <div>
            <btn class="borderless !min-w-fit">
              <font-awesome-icon icon="share" />
              <badge :value="steps.length" color="danger" class="ml-4" />
            </btn>
          </div>
        </template>
        <div class="steps-container steps-container-block vertical next-steps-drop">
          <div class="steps-inner">
            <a
              :style="{ 'z-index': step.name in zIndexes ? zIndexes[step.name] : 1 }"
              @mouseover="upZIndex(step.name)"
              @click.stop.prevent.capture="dispatch(step)"
              :class="`step-${step.color}`"
              :key="step.name"
              v-for="step in steps"
            >
              <font-awesome-icon
                v-if="step.glyph && !loading"
                :icon="step.glyph"
                fixed-width
              />&nbsp;
              <spinner v-else class="icon light" size="3em" :loading="1" />
              <span class="text"><span v-html="step.short"></span></span>
            </a>
          </div>
        </div>
      </drop>
    </span>
  </span>

  <div class="flex flex-row gap-1 justify-start items-stretch !m-0" v-else-if="steps.length === 1">
    <Button
      v-for="step in steps"
      :key="step.name"
      v-tooltip="step.name"
      severity="tertiary"
      :loading="!!loading"
      unstyled
      @click.native="dispatch(step)"
      :class="`py-2 px-3 text-nowrap text-ellipsis rounded border border-${step.color} text-${step.color} hover:bg-surface-200 transition`"
    >
      <icon :icon="step.glyph" class="mr-2" />
      <p>{{ step.short }}</p>
      <template #loadingicon>
        <spinner class="icon light" size="3em" :loading="1" />
      </template>
    </Button>
  </div>

  <div class="flex flex-row gap-1 justify-start items-stretch !m-0" v-else-if="steps.length > 1">
    <drop position="bottom right" targetAttachment="bottom right" offset="0px -12px">
      <template #button>
        <div>
          <Button class="py-2 px-3 !border-surface-600 !text-surface-600" severity="tertiary">
            <p>More actions</p>
            <icon icon="chevron-down" class="ml-2" />
          </Button>
        </div>
      </template>
      <div class="flex flex-col gap-2">
        <a
          v-for="step in steps"
          :key="step.name"
          v-tooltip="step.name"
          @click.stop.prevent.capture="dispatch(step)"
          class="flex flex-row items-center gap-2 p-1 rounded transition *:text-sm hover:bg-surface-300 hover:text-pitch-black cursor-pointer select-none"
        >
          <icon class="!text-xs" :icon="step.glyph" />
          <p class="font-normal">{{ step.short }}</p>
        </a>
      </div>
    </drop>
  </div>
</template>

<script>
import Button from 'primevue/button'
import BtnMixin from '../../mixins/Button'
import { generateNextSteps } from './steps'
import eventBus from '@/eventBus'
import { useMediaQuery } from '@/composables/mediaQuery'

/**
 * Emits:
 * -done (after action taken)
 * -error (action results in error)
 *
 * Modifier classes:
 * -vertical (does vertical list isntead of horizontal)
 */
export default {
  name: 'NextSteps',
  mixins: [BtnMixin],
  emits: ['error', 'done'],
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  data() {
    return {
      steps: [],
      zIndexes: {},
      zIndex: 1
    }
  },
  watch: {
    object() {
      this.$nextTick(() => {
        this.generateSteps()
      })
    }
  },
  computed: {
    showShort() {
      return (
        this.$store.state.session.deviceSize === 'xs' ||
        this.$store.state.session.deviceSize === 'sm' ||
        this.steps.length > 2
      )
    },
    minimizeBtn() {
      return this.$store.state.session.deviceWidth < 1550 && !this.smallFormat
    }
  },
  methods: {
    upZIndex(ref) {
      this.zIndex = this.zIndex + 1
      this.zIndexes = {
        ...this.zIndexes,
        [ref]: this.zIndex
      }
    },
    async dispatch(step) {
      this.addLoading()

      if (this.heading && this.minimizeBtn) {
        this.$refs['next-steps-drop'].close()
      }
      if (
        this.object &&
        typeof this.object === 'object' &&
        '$el' in this.object &&
        this.object.dataManipulator &&
        typeof this.object.cast === 'function' &&
        this.object.selected &&
        this.object.isDirty
      ) {
        await this.$store.dispatch('alert', { message: 'Saving first...' })
        await this.object.save()
      }

      try {
        await step.action(this.object.cast ? this.object.cast() : this.object, this, this.grid)
      } catch (e) {
        this.$emit('error')
      }

      this.endLoading()

      if ((this.go === null && !this.contextual) || this.go) {
        await this.$store.dispatch('go', {
          object: this.object
        })
      } else if (this.grid) {
        this.grid.reload()
      }

      this.$emit('done')
    },
    generateSteps() {
      if (this.object) {
        eventBus.$on(`${this.object.uid}-reloaded`, this.generateSteps)
        eventBus.$on(`${this.object.uid}-selected`, this.generateSteps)
        eventBus.$on(`${this.object.uid}-saved`, this.generateSteps)
      }
      this.steps = generateNextSteps(
        this.object.cast ? this.object.cast() : this.object,
        this.$store,
        this.contextual
      )
    }
  },
  mounted() {
    this.generateSteps()
  },
  beforeUnmount() {
    if (this.object) {
      eventBus.$off(`${this.object.uid}-reloaded`, this.generateSteps)
      eventBus.$off(`${this.object.uid}-selected`, this.generateSteps)
      eventBus.$off(`${this.object.uid}-saved`, this.generateSteps)
    }
  },
  props: {
    inline: {
      default: true
    },
    object: {
      required: true
    },
    grid: {
      default: null,
      required: false
    },
    /**
     * Is this in the edit modal/bodie of the object? OR outside?
     */
    contextual: {
      default: false
    },

    /**
     * View the item in a list after step is complete?
     * Default: true when NOT contextual
     */
    go: {
      default: null
    },
    heading: {
      default: false
    }
  },
  components: {
    Button
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.steps-container {
  &.steps-container-inline {
    display: inline-flex;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    .steps-inner {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      gap: 8px;
      position: relative;

      .step-badge {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 0.5em;
        padding: 0 0.5em 0 0;
        min-height: 2em;
        font-weight: normal;
        .icon {
          top: unset;
          position: static;
          font-size: 1.1em;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 1.7em;
          height: 1.7em;
          border-radius: 100%;
          color: $flame-white;
        }

        background: $cool-gray-500;
        color: $cool-gray-400;
        opacity: 1;
        transition: opacity 100ms ease-out;

        &.badge-default {
          .icon {
            background: $cool-gray-600;
            color: $flame-white;
          }
        }

        &.badge-warning {
          .icon {
            background: $level-yellow;
          }
        }

        &.badge-danger {
          .icon {
            background: $deep-red-800;
          }
        }

        &.badge-success {
          .icon {
            background: $green-600;
          }
        }
      }
    }
  }
  &.steps-container-block {
    display: flex;
    justify-content: stretch;
    align-content: center;
    height: 100%;
    width: 100%;
    overflow: visible;
    backface-visibility: hidden;
    min-height: 3em;
    perspective: 1000px;
    perspective-origin: center;

    .steps-inner {
      display: flex;
      justify-content: stretch;
      align-items: center;
      align-content: center;
      backface-visibility: hidden;
      z-index: $z-layout;
      height: 3em;
      flex-basis: 100%;

      > :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      > :first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      > a {
        backface-visibility: hidden;
        perspective: 1000;
        position: relative;
        display: flex;
        height: 100%;
        max-height: 4em;
        flex-basis: 100%;
        justify-content: center;
        align-items: stretch;
        transition: all 0.3s ease;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: perspective(1000px);
        font-weight: normal;
        white-space: normal;
        font-size: 0.7em;
        overflow: hidden;
        text-overflow: ellipsis;

        > span {
          height: 100%;
          border: 0 !important;
          display: flex;
          justify-content: center;
          text-align: left;
          align-items: center;

          &.icon {
            border: none !important;
            top: 0 !important;
            line-height: 1;
            width: 3.5em;
            font-size: 1.5em;
            background: rgba(255, 255, 255, 0.3);
            transition: background 0.3s ease;
            -moz-transition: none;
            -ms-transition: none;
          }
          &.text {
            background: rgba(255, 255, 255, 0.1);
            line-height: 1.1;
            padding: 0.5em;
            transition: background 0.3s ease;
            -moz-transition: none;
            -ms-transition: none;
            width: 100%;
          }
        }

        &:hover,
        &:active {
          cursor: pointer;
          transform: perspective(1000px) translate3d(0, 0, 3em);
          border-radius: 5px !important;
          box-shadow:
            0 10px 20px rgba(0, 0, 0, 0.15),
            0 6px 6px rgba(0, 0, 0, 0.05) !important;
          z-index: $z-layout + 1 !important;
          > .icon,
          > .text {
            background: transparent;
          }
        }

        &.step-danger {
          background: $deep-red-800;
          color: $flame-white;
        }
        &.step-warning {
          background: $level-yellow;
          color: $level-yellow;
        }
        &.step-success {
          background: $matcha-500;
          color: $flame-white;
        }
        &.step-default {
          background: $cool-gray-600;
          color: $cool-gray-200;
        }
      }
    }

    &.vertical {
      height: auto;
      .steps-inner {
        flex-direction: column;
        height: auto;
        min-height: 3em;
        justify-content: stretch;
        align-items: stretch;
        align-content: stretch;
        > * {
          min-height: 3em;
          > * {
            height: auto;
          }
        }
        > :first-child {
          border-radius: 5px 5px 0 0;
        }
        > :last-child {
          border-radius: 0 0 5px 5px;
        }
        :last-child:first-child {
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
