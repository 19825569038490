<template>
  <div class="notification-container" @click="openObject">
    <div class="notification-content">
      <div class="notification-details">
        <div class="notification-text">You {{ object.activity_type }}</div>
        <div class="notification-date">
          {{ $f.datetime(object.activity_time_created) }}
        </div>
      </div>

      <div class="notification-pointer">
        <font-awesome-icon icon="arrow-right" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'ActivityNotification',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    openCreator() {
      this.$store.dispatch('edit', {
        type: this.object.oCreator.contact_object_type,
        id: this.object.oCreator.contact_object_id,
        button: this
      })
    },
    openObject() {
      this.$store.dispatch('edit', {
        type: this.object.target_type,
        id: this.object.target_id,
        button: this
      })
    },
    go() {
      this.$router.push(`Activity/${this.activity_id}`).catch(() => {})
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.notification-container {
  padding: 1em;
  cursor: pointer;

  .notification-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .notification-avatar {
      flex: 0 0 auto;
      margin: 0.5em;
    }
    .notification-details {
      flex: 10 10 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      .notification-text {
        font-size: 1.1em;
        line-height: 1;
        a {
          font-weight: bold;
        }
      }
      .notification-date {
        color: $cool-gray-600;
        font-size: 0.9em;
        margin-top: 0.25em;
      }
    }
  }

  .notification-pointer {
    display: none;
    margin-left: auto;
    $size: 2em;
    height: $size;
    width: $size;
    max-height: $size;
    max-width: $size;
    min-height: $size;
    min-width: $size;
    border-radius: 50%;
    background: $blue-print-200;
    color: $blue-print-700;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background: $blue-print-400;
    .notification-pointer {
      display: flex;
    }
  }
}
</style>
