<template>
  <choose
    v-bind="$props"
    v-if="ownableType"
    v-model="localValue"
    :disabled="!canChange"
    :schema="schema"
    size="lg"
    multiple
    highlightIfNotEmpty
    placeholder="Everyone"
    :filters="{
      company_ids: `INSET${$store.state.session.company.company_id}`
    }"
  />
</template>

<script>
import SchemaFieldMixin from '../../mixins/SchemaField'
/**
 * Emits:
 *  -input
 */
export default {
  name: 'FilterOwner',
  mixins: [SchemaFieldMixin],
  emits: ['input'],
  data() {
    return {}
  },
  watch: {},
  props: {
    btnClass: {
      default: undefined
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    filters() {
      return {
        contact_object_type: 'user',
        ...(this.canChange
          ? {}
          : { contact_object_id: `user-${this.$store.state.session.user.user_id}` })
      }
    },
    /**
     * Can this user see any other user detais for this object other than themselves?
     * @returns {boolean}
     */
    canChange() {
      const perms = this.$store.state.session.user.aUserPerms
      return (
        this.objectType &&
        perms &&
        (!(this.objectType in perms) ||
          !('read' in perms[this.objectType]) ||
          perms[this.objectType].read > 1)
      )
    },
    ownerField() {
      return `${this.objectType}_owner`
    },
    ownableType() {
      if (!this.objectType) return false
      const constructor = c.getConstructor(this.objectType)
      const fields = constructor.fields
      return this.ownerField && this.ownerField in fields
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
