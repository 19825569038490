// src/components/composables/useUnapprovedItems.js
import { computed } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

export default {
  useUnapprovedChanges({ refId, store }) {
    const { norm } = EntityComputedFields.useEntityComputedFields({
      refId,
      type: 'quote',
      store
    })

    const unapprovedItems = computed(() => {
      const items = Object.values(norm.value)

      return items
        .filter(
          (item) => (!item.item_client_has_approved || !item.item_id) && item.type === 'cost_item'
        )
        .map((item) => item.cost_type_name || item.assembly_name || item.quote_name)
    })

    return {
      unapprovedItems
    }
  }
}
