<!-- eslint-disable vue/no-mutating-props -->
<template>
  <span class="flex flex-col gap-y-4">
    <div class="flex flex-col w-full mt-0 md:mt-8">
      <span class="mx-1"> Legal First Name </span>
      <field
        :validate="{ required: true }"
        :errors="errors"
        name="owner.first_name"
        :value="owner.first_name"
        v-model="owner.first_name"
        class="w-full !bg-flame-white border-surface-200"
      />
      <p class="text-xs text-surface-600/75 mt-0.5 ml-1">
        The legal first name as it appears on identification and / or tax documents.
      </p>
    </div>

    <div class="flex flex-col w-full">
      <span class="mx-1"> Legal Last Name </span>
      <field
        :validate="{ required: true }"
        :errors="errors"
        name="owner.last_name"
        v-model="owner.last_name"
        class="w-full !bg-flame-white border-surface-200"
      />
      <p class="text-xs text-surface-600/75 mt-0.5 ml-1">
        The legal last name as it appears on identification and / or tax documents.
      </p>
    </div>

    <div class="flex flex-col w-full" v-if="!individual">
      <span class="mx-1"> Email </span>
      <field
        :validate="{ required: true, format: 'email' }"
        :errors="errors"
        name="owner.email"
        v-model="owner.email"
        class="w-full !bg-flame-white border-surface-200"
      />
    </div>
    <div class="flex flex-col w-full" v-if="!individual">
      <span class="mx-1"> Phone </span>
      <PhoneField
        class="field validate-valid input-container no-format field-component field-component--input !bg-flame-white border-surface-200 font-sans leading-none lg:min-w-[200px] lg:w-[300px] h-10 m-0 px-4 rounded-sm text-pitch-black dark:text-surface-200 placeholder:text-cool-gray-700 dark:placeholder:text-surface-500 bg-transparent dark:bg-surface-900 focus:border-pitch-black focus:ring-1 focus:ring-pitch-black focus:bg-flame-white transition hover:bg-surface-100 border border-cool-gray-500 dark:border-surface-600 hover:border-cool-gray-700 hover:bg-flame-white dark:hover:border-surface-300 focus:outline-none focus:outline-offset-0 focus:z-10 appearance-none transition-colors duration-200"
        v-bind="$props"
        :validate="{ required: true }"
        @validation="handlePhoneValidation"
        v-model="owner.phone"
        name="owner.phone"
        :current="owner.phone"
        :country-code="country"
      />
    </div>

    <SocialSecurity
      :validate="{ required: true }"
      @change="onSinChange"
      :errors="errors"
      name="owner.id_number"
      :country="country"
      v-model="owner.id_number"
    />

    <field-section class="!p-0">
      <span class="mx-1"> Date of birth </span>
      <date-picker
        v-model="dob"
        :headless="true"
        :keepFormat="true"
        name="owner.dob.month"
        value-format="YYYY-MM-DD"
        format="YYYY-MM-DD"
        :required="true"
        :picker-options="{
          shortcuts: false
        }"
        :errors="errors"
        placeholder="Pick a date"
      />
    </field-section>

    <div class="flex flex-col my-2 gap-4">
      <div>
        <span class="flex flex-row gap-x-2">
          <Checkbox
            name="owner.relationship.owner"
            v-model="isOwner"
            class="!bg-flame-white border-surface-200"
          />
          Is owner
        </span>
        <p class="text-xs text-surface-600/75 mt-0.5">
          An owner is someone who owns 25% or more of the company, through voting rights, equity, or
          similar.
        </p>
      </div>
      <div>
        <span class="flex flex-row gap-x-2">
          <Checkbox
            name="owner.relationship.executive"
            v-model="isExecutive"
            class="!bg-flame-white border-surface-200"
          />
          Controlling person
        </span>
        <p class="text-xs text-surface-600/75 mt-0.5">
          A controlling person is someone authorized to make major business decisions. They may or
          may not be an owner.
        </p>
      </div>
    </div>

    <div class="flex flex-col w-full">
      <span class="mx-1"> Title </span>
      <field
        :validate="{ required: true }"
        :errors="errors"
        name="owner.relationship.title"
        v-model="owner.relationship.title"
        class="w-full !bg-flame-white border-surface-200"
      />
    </div>

    <div class="flex flex-col w-full" v-if="!individual">
      <span class="mx-1"> Percent Ownership </span>
      <field
        type="number"
        :validate="{ required: true, format: /^(0*(?:[1-9][0-9]?|100))$/ }"
        :errors="errors"
        name="owner.relationship.percent_ownership"
        @invalid="invalidOwnership"
        v-model="percentOwnership"
        class="w-full !bg-flame-white border-surface-200"
      />
    </div>
  </span>
</template>
<script>
/* eslint-disable vue/no-mutating-props */
import moment from 'moment'
import PhoneField from '../../../ui/fields/PhoneField.vue'
import DatePicker from '../../../ui/DatePicker.vue'
import SocialSecurity from '../fields/SocialSecurityField.vue'
import FieldSection from '../../../ui/fields/FieldSection.vue'
import PayfacMixin from '../../PayfacMixin'

export default {
  name: 'CounterpartyOnboardingOwner',
  props: {
    owner: {
      type: Object
    },
    errors: {
      type: Array
    },
    individual: {
      type: Boolean,
      default: false
    }
  },
  emits: ['warning'],
  data() {
    return {
      phoneVerification: null
    }
  },
  computed: {
    country() {
      return this.$store.state.session.company.company_country
    },
    percentOwnership: {
      get() {
        return this.owner.relationship.percent_ownership
      },
      set(v) {
        // to be considered an owner by stripe you need to have >= 25% ownership
        if (v < 25 && !this.isExecutive) {
          this.$emit(
            'warning',
            'You must have more than 25% ownership of company to setup payments.'
          )
        } else {
          this.$emit('warning')
        }
        // if percent is larger than 0 and owner is turned off turn it on
        if (v > 0 && !this.isOwner) {
          this.owner.relationship.owner = true
        }
        // if you have 0 percent ownership set ownership to false
        if (v === 0) this.owner.relationship.owner = true
        this.owner.relationship.percent_ownership = v
      }
    },
    isOwner: {
      get() {
        return !!this.owner.relationship.owner
      },
      set(v) {
        // if you are not a owner you have no ownership percentage
        if (!v) this.owner.relationship.percent_ownership = 0
        // if you toggle owner on and percent ownership is below 25
        // set the percent ownership to stripes default 25
        if (v && this.percentOwnership < 25) {
          this.owner.relationship.percent_ownership = 25
        }
        // to create an account you must either be an executive or owner
        if (!v && !this.isExecutive) {
          this.$emit(
            'warning',
            'You must either be more than 25% owner or executive of company to active payments.'
          )
        } else {
          this.$emit('warning')
        }
        this.owner.relationship.owner = v === 1
      }
    },
    isDirector: {
      get() {
        return !!this.owner.relationship.director
      },
      set(v) {
        this.owner.relationship.director = v === 1
      }
    },
    isExecutive: {
      get() {
        return !!this.owner.relationship.executive
      },
      set(v) {
        // to create an account you must either be an executive or owner
        if (!v && !this.isOwner) {
          this.$emit(
            'warning',
            'You must either be more than 25% owner or executive of company to active payments.'
          )
        } else {
          this.$emit('warning')
        }
        this.owner.relationship.executive = v === 1
      }
    },
    isRepresentative: {
      get() {
        return !!this.owner.relationship.representative
      },
      set(v) {
        this.owner.relationship.representative = v === 1
      }
    },
    dob: {
      get() {
        if (!this.owner.dob.year || !this.owner.dob.month || !this.owner.dob.day) {
          return null
        }
        const date = moment()
        date.set('year', this.owner.dob.year)
        date.set('month', this.owner.dob.month)
        date.set('date', this.owner.dob.day)
        return date.toDate()
      },
      set(dob) {
        if (!dob) return
        const date = moment(dob)
        this.owner.dob.day = date.date()
        this.owner.dob.month = date.month()
        this.owner.dob.year = date.year()
      }
    }
  },
  methods: {
    /**
     * Use the full sin number to calculate last 4
     */
    onSinChange(value) {
      // only add last_4 if company is in US
      if (this.country === 'us') {
        this.owner.ssn_last_4 = value.slice(value.length - 4)
      }
    },
    /**
     * Validate the inputted ownership percentage
     */
    invalidOwnership() {
      this.$store.dispatch('alert', {
        message: 'Ownership cannot be larger than 100%',
        error: true
      })
    },
    handlePhoneValidation(validationResult) {
      // Update phone verification status
      this.phoneVerification =
        validationResult.valid && !validationResult.empty && validationResult.value !== ''

      if (!this.phoneVerification) {
        // Validation failed, add 'owner.phone' to errors if not already present
        if (!this.errors.includes('owner.phone')) {
          this.errors.push('owner.phone')
        }
      } else {
        // Validation passed, remove 'owner.phone' from errors if present
        const index = this.errors.indexOf('owner.phone')
        if (index !== -1) {
          this.errors.splice(index, 1)
        }
      }
    }
  },
  components: {
    PhoneField,
    DatePicker,
    SocialSecurity,
    FieldSection
  },
  mixins: [PayfacMixin]
}
</script>
