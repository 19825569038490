<template>
  <div
    class="flex justify-end w-full delay-300"
    :class="approvedIds.length <= 0 ? '-bottom-24' : 'bottom-0 '"
  >
    <Btn
      class="mr-4"
      @click="cancelApproval"
      v-if="!hideClear"
      :link="true"
      :disabled="approvedIds.length === 0"
      severity="tertiary"
    >
      Clear
    </Btn>
    <Btn @click="onConfirm" :disabled="approvedIds.length === 0" severity="primary-black" size="xl">
      <slot>
        Approve {{ approvedIds.length > 0 ? `${approvedIds.length}` : '' }} item{{
          approvedIds.length > 1 ? 's' : ''
        }}
      </slot>
    </Btn>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue'
import useApproval from '@/components/composables/Approval'
import useItemizedPayments from '../composables/ItemizedPayments'
import useInvoice from '../composables/Invoice'

const props = defineProps({
  hideClear: {
    type: Boolean,
    default: true
  },
  confirmed: {
    type: Function
  }
})

// provide/inject
const project = inject('project')

const { quotePriceGross, unInvoicedGross, quoteTaxSums, invoiceQuoteId } = useInvoice()

const { approvedIds, approvalAllSelected } = useApproval()
const { discount, unInvoiced } = useItemizedPayments()

discount.value = project.value.quote_discount_percentage
unInvoiced.value = project.value.quote_uninvoiced_gross
quotePriceGross.value = project.value.quote_price_gross
unInvoicedGross.value = project.value.quote_uninvoiced_gross
quoteTaxSums.value = project.value.oTaxSums
invoiceQuoteId.value = project.value.quote_id

const onConfirm = () => {
  approvalAllSelected()
  if (props.confirmed) props.confirmed()
}
</script>
