<template>
  <preview
    class="transaction-preview--container"
    v-bind="$props"
    v-if="object"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <font-awesome-icon
        icon="arrow-right-from-bracket"
        class="icon"
        style="border: 1px solid #888; border-radius: 50%; padding: 0.25em"
      />
    </template>
    <template #primary>
      <div @click.stop.capture="() => $refs.details.open()">
        <name
          type="account"
          :id="object.source_account_id"
          truncate="10"
          :default="object.source_account_id"
        />
        <font-awesome-icon icon="arrow-right" class="text-info" style="font-size: 0.75em" />
        <name type="vendor" :id="object.vendor_id" truncate="15" />
        <small class="text-muted" v-if="object.transaction_reference">
          {{ object.transaction_memo }}
        </small>
      </div>
    </template>
    <template #middle>
      <div>
        <btn rounded class="more px-0 p-2 danger lg" :action="deleteTransaction">
          <font-awesome-icon icon="trash" />
        </btn>
      </div>
    </template>
    <template #secondary>
      <div class="text-info">
        {{ $f.date(object.transaction_time_created) }} •
        <help class="mx-1" buttonOnly @click="() => $refs.details.open()">
          <template #before>
            <span class="badge badge-info xs" style="font-size: 0.7em">
              <font-awesome-icon
                :icon="['fas', 'cube']"
                class="text-white mr-1"
                style="font-size: 0.6em"
              />
              for {{ object.aoItems.length }} items
            </span>
          </template>
        </help>

        <mini-modal ref="details">
          <card-section>
            <template #title> This transaction includes payment for the following items: </template>
            <template #subtitle> Amounts include any sales taxes paid. </template>

            <table class="table table-condensed table-striped mt-1">
              <tbody>
                <tr v-for="(item, index) in object.aoItems" :key="index">
                  <td>{{ item.item_name }}</td>
                  <td class="text-right text-monospace text-info">
                    <strong>{{ $f.currency(item.split_amount) }}</strong>
                  </td>
                </tr>
                <tr class="bg-info text-white">
                  <td><strong>Total (includes taxes)</strong></td>
                  <td class="text-right text-monospace">
                    <strong>{{ $f.currency(object.transaction_gross) }}</strong>
                  </td>
                </tr>
                <tr>
                  <td style="font-style: italic">Sales taxes in this transaction</td>
                  <td class="text-right text-monospace text-info" style="font-style: italic">
                    {{ $f.currency(object.transaction_tax) }}
                  </td>
                </tr>
                <tr>
                  <td style="font-style: italic">Before-tax amount</td>
                  <td class="text-right text-monospace text-info" style="font-style: italic">
                    {{ $f.currency(object.transaction_net) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </card-section>
        </mini-modal>
      </div>
    </template>
    <template #right>
      <div>
        <help buttonOnly @click="() => $refs.details.open()">
          <template #after>
            {{ $f.currency(object.transaction_gross) }}
          </template>
        </help>
      </div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewTransaction',
  props: {
    right: {},
    middle: {},
    object: {
      type: Object,
      required: true
    }
  },
  emits: ['click'],
  methods: {
    async deleteTransaction() {
      if (!this.object.transaction_is_record_only) {
        this.$store.dispatch('alert', {
          message: 'Transactions cannot be deleted. You can reverse the transaction instead.',
          error: 1
        })
        throw Error('Transactions cannot be deleted. You can reverse the transaction instead.')
      }
      await this.$store.dispatch('Transaction/delete', {
        id: this.object.transaction_id
      })
      return this.grid.reload()
    }
  },
  components: { Preview }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
