const Dashboard = () => import('@/components/layout/Dashboard.vue')
const CustomerDashboardProgress = () =>
  import('@/components/bodies/customer/CustomerDashboardProgress.vue')
const CustomerDashboardChat = () => import('@/components/bodies/customer/CustomerDashboardChat.vue')
const CustomerDashboardPreview = () =>
  import('@/components/bodies/customer/CustomerDashboardPreview.vue')
const CustomerDashboardHistory = () =>
  import('@/components/bodies/customer/CustomerDashboardHistory.vue')
const Booked = () => import('@/components/pages/Booked.vue')
const Messages = () => import('@/components/pages/Messages.vue')
const ActivityChannelBody = () => import('@/components/Activities/ActivityChannelBody.vue')
const DefaultActivityChannelBody = () =>
  import('@/components/Activities/DefaultActivityChannelBody.vue')

const meta = {
  scopesAllowed: ['user', 'company', 'quote'],
  menuContext: 'client'
}

/**
 * Routes named customer as company already defines client
 */
export default [
  {
    path: '/:scopeRoute/customer',
    alias: ['/:scopeRoute/project', '/:scopeRoute/customer/project'],
    component: Dashboard,
    children: [
      {
        name: 'Project',
        path: 'project/progress',
        component: CustomerDashboardProgress,
        meta
      },
      {
        name: 'Project messages',
        path: 'project/chat',
        component: CustomerDashboardChat,
        meta
      },
      {
        name: 'Project preview',
        path: 'project/preview',
        component: CustomerDashboardPreview,
        meta: {
          scopesAllowed: ['user', 'company', 'quote'],
          menuContext: 'client',
          type: 'quote'
        }
      },
      {
        name: 'Estimates History',
        path: 'project/history',
        component: CustomerDashboardHistory,
        meta
      }
    ],
    meta
  },
  {
    path: '/:scopeRoute/booked',
    component: Booked,
    meta: {
      confirmLeave: false,
      ...meta
    }
  },
  {
    path: '/:scopeRoute/client-messages',
    name: 'ClientMessages',
    component: Messages,
    children: [
      {
        path: '',
        name: 'ClientDefaultChannel',
        props: false,
        component: DefaultActivityChannelBody
      },
      {
        path: ':channelType/:channelTypeId',
        name: 'ClientChannel',
        props: true,
        component: ActivityChannelBody
      }
    ],
    meta
  }
]
