import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      userPoolEndpoint: import.meta.env.VITE_COGNITO_ENDPOINT,
      loginWith: {
        email: true,
        phone: true,
        oauth: {
          domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
          redirectSignOut: [`https://${import.meta.env.VITE_FE_BASE}/logout`],
          redirectSignIn: [`https://${import.meta.env.VITE_FE_BASE}/auth/callback`],
          providers: ['Apple', 'Google'],
          responseType: 'code',
          scopes: ['email', 'phone', 'openid', 'profile', 'aws.cognito.signin.user.admin']
        }
      }
    }
  }
})

export const config = Amplify.getConfig()
