import { client } from '@/apollo-client/apollo-config'
import {
  onCreateChatSubscription,
  onCreateMessagesSubscription
} from '@/apollo-client/api-calls/quoteChat'

export default {
  data() {
    return {
      chatObserver: null,
      messaageObserver: null
    }
  },
  methods: {
    async subscribeToNewMessages() {
      if (!this.$store.state.chat.chatSummary) return

      const refIdList = Object.keys(this.$store.state.chat.chatSummary)
      if (refIdList && refIdList.length) {
        try {
          const observer = client().subscribe({
            query: onCreateMessagesSubscription,
            variables: {
              filter: JSON.stringify({
                refId: {
                  in: refIdList
                }
              })
            }
          })

          const store = this.$store
          const messageObserver = observer.subscribe({
            next(data) {
              const newMessage = data.data.onCreateMessage
              if (store.state.session.user.user_id !== newMessage.userId) {
                store.dispatch('addMessage', { message: newMessage })
                store.dispatch('alert', { message: `New message regarding ${newMessage.refId}` })
              }
            },
            complete(com) {
              console.log('com', com)
            },
            error(err) {
              console.log('err', err)
            }
          })

          this.messaageObserver = messageObserver
        } catch (err) {
          console.log(err)
        }
      }
    },

    async unsubscribeToNewMessages() {
      if (this.messaageObserver) this.messaageObserver.unsubscribe()
    },

    async subscribeToNewChats() {
      try {
        const observer = client().subscribe({
          query: onCreateChatSubscription,
          variables: {
            contractorId: String(this.$store.state.session.user.user_id)
          }
        })

        const that = this
        const chatObserver = observer.subscribe({
          next(data) {
            const newChat = data.data.onCreateChat
            if (newChat) {
              that.$store.dispatch('addNewChat', { chat: newChat })

              that.unsubscribeToNewMessages()
              that.subscribeToNewMessages()

              if (
                newChat.messages &&
                newChat.messages.messageList &&
                newChat.messages.messageList.length
              ) {
                that.$store.dispatch('alert', {
                  message: `New chat created regarding ${newChat.refId}. Go to your chat dashboard to view!`
                })
              }
            }
          },
          complete(com) {
            console.log('com', com)
          },
          error(err) {
            console.log('err', err)
          }
        })

        this.chatObserver = chatObserver
      } catch (err) {
        console.log(err)
      }
    },

    async unsubscribeToNewChats() {
      if (this.chatObserver) this.chatObserver.unsubscribe()
    }
  }
}
