<template>
  <Btn
    severity="secondary"
    v-tooltip="'Edit presentation'"
    @click="$emit('toggle-edit-toolbar')"
    v-if="!$store.state.session.isGuestUser && !('quote' in $store.state.session.scope)"
    data-label="edit_presentation"
  >
    <font-awesome-icon icon="pen-to-square" class="font-medium text-sm" />
    Presentation editor
  </Btn>
  <mini-modal size="full" :full="true" :scrollable="true" :closeable="true" ref="showHide">
    <template #header>Presentation Settings</template>
    <PresentationSettings
      v-bind="$props"
      :reference="refId"
      :store="storeName"
      :type="type"
      :editing="editingLocal"
      @editing="(b) => (editingLocal = b)"
      v-model="subComponentInterface"
    />
  </mini-modal>
</template>

<script>
import ObjectDistinct from '@/components/mixins/ObjectDistinct'
import PresentationMixin from './PresentationMixin'
import PresentationSettings from '@/components/quote/presentation/PresentationSettings.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'PresentationToolbar',
  mixins: [ObjectDistinct('quote'), PresentationMixin],
  emits: ['toggle-edit-toolbar'],
  components: {
    FontAwesomeIcon,
    PresentationSettings
  },
  data() {
    return {
      unsavedChanges: 0
    }
  },
  watch: {
    oPresentationSettings(a) {
      if (!this.templateFull || !this.templateFull.oMeta) return

      const original = {
        ...this.templateFull
      }
      const current = {
        ...this.templateFull,
        oMeta: a
      }

      const eq = c.jsonEquals(original.oMeta, current.oMeta, true)
      if (!eq) {
        this.unsavedChanges = 1
      }
    }
  },
  methods: {
    toggleSettings() {
      this.$refs.showHide.toggle()
    },
    async handleResetChanges() {
      await this.loadTemplate(this.presentationTemplateId)
    },
    handleSaveTemplate() {
      this.templateFull.company_id ? this.saveTemplate() : this.savePresentationTemplateNew()
    },
    async handleLoadTemplate() {
      await this.loadTemplate(this.presentation_template_id)
    }
  },
  mounted() {
    this.handleLoadTemplate()
  }
}
</script>
