<template>
  <div
    :style="
      collapsed === 'popup' ? 'display: flex; align-items: center;justify-content: center;' : ''
    "
  >
    <card-list class="inline" v-if="collapsed === 'collapse'">
      <card-list-collapse class="inline" :value="collapseLocal">
        <template #title>
          {{ $f.capitalize(dimensionsName) }} dimensions
          <dimensions-badge
            class="ml-2"
            :hasParent="false"
            :partiallyLinked="partiallyLinked"
            :fullyLinked="fullyLinked"
            :activeDimensions="activeDimensions.length"
          />
        </template>
        <div class="quote-dimensions--container">
          <div class="quote-dimensions--row" v-for="(row, index) in visibleRows" :key="index">
            <dimension
              v-for="abbr in visibleDimension(row)"
              :key="abbr"
              @dimensions="setDimensions"
              :parentDimensions="parentDimensions"
              :dimensions="dimensionsLocal"
              :dim="dimensionsLocal[abbr]"
              :inAssembly="inAssembly"
              :class="{
                inherit: dimensionsLocal[abbr].inherit && parentRefId,
                hasValue: dimensionsLocal[abbr].value
              }"
            />
          </div>
        </div>
      </card-list-collapse>
    </card-list>
    <template v-else-if="collapsed === 'popup'">
      <slot name="button">
        <btn rounded class="default more px-0 p-2" @click="() => $refs.dimensionsModal.open()">
          <svg
            aria-hidden="true"
            data-prefix="fas"
            data-icon="ruler-combined"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="svg-inline--fa fa-ruler-combined fa-w-16"
          >
            <path
              fill="currentColor"
              d="M160 288h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56v-64h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56V96h-56c-4.42 0-8-3.58-8-8V72c0-4.42 3.58-8 8-8h56V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 2.77.91 5.24 1.57 7.8L160 329.38V288zm320 64h-32v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-41.37L24.2 510.43c2.56.66 5.04 1.57 7.8 1.57h448c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"
              class=""
            ></path>
          </svg>
        </btn>
      </slot>
    </template>
    <div v-else>
      <div class="quote-dimensions--container">
        <div class="quote-dimensions--row" v-for="(row, index) in visibleRows" :key="index">
          <dimension
            v-for="abbr in visibleDimension(row)"
            :key="abbr"
            :dimensionsUsed="dimensionsUsed"
            @dimensions="setDimensions"
            :parentDimensions="parentDimensions"
            :dimensions="dimensionsLocal"
            :inAssembly="inAssembly"
            :dim="dimensionsLocal[abbr]"
            :class="{
              inherit: dimensionsLocal[abbr].inherit && parentRefId,
              hasValue: dimensionsLocal[abbr].value
            }"
          />
        </div>
      </div>
      <btn class="round xs" v-if="showAllButton" @click="() => $refs.dimensionsModal.open()">
        <template #icon>
          <font-awesome-icon icon="ellipsis" />
        </template>
        All dimensions
      </btn>
    </div>
    <mini-modal ref="dimensionsModal" :width="640">
      <div class="quote-dimensions--container">
        <div class="quote-dimensions--row" v-for="(row, index) in layout" :key="index">
          <dimension
            v-for="abbr in row"
            :key="`window-${abbr}`"
            @dimensions="setDimensions"
            :parentDimensions="parentDimensions"
            :dimensions="dimensionsLocal"
            :inAssembly="inAssembly"
            :dim="dimensionsLocal[abbr]"
            :class="{
              inherit: dimensionsLocal[abbr].inherit && parentRefId,
              hasValue: dimensionsLocal[abbr].value
            }"
          />
        </div>
      </div>
    </mini-modal>
  </div>
</template>

<script>
import BodyMixin from '@/components/mixins/Body'
import Dimension from './Dimension.vue'
import DimensionsBadge from './DimensionsBadge.vue'

export default {
  name: 'QuoteDimensions',
  mixins: [BodyMixin /* AutoSave */],
  props: {
    /**
     * Whether to show a button to allow user to open
     * a popup of all dimensions or not.
     */
    showAllButton: {
      type: Boolean,
      default: true
    },
    /**
     * List of abbreviations for dimensions that are
     * used by the quote/assembly.
     *
     * ['fa','iwp',..]
     */
    dimensionsUsed: {
      type: Array,
      default: () => []
    },
    dimensions: {
      type: Object,
      required: true
    },
    showAll: {
      default: false
    },

    /**
     * 'popup' or 'inline'
     */
    viewAs: {
      type: String,
      default: null
    },
    collapse: {
      type: Array,
      default: () => []
    },
    deselectOnDestroy: {
      default: false
    },
    type: {
      type: String,
      default: 'quote'
    },
    store: {
      type: String,
      required: true
    },
    /**
     * Quote or assembly name
     */
    parentName: {
      type: String,
      required: true
    },
    parentRefId: {
      default: null
    },
    /**
     * RefId for this object
     */
    reference: {
      required: true
    }
  },
  components: {
    Dimension,
    DimensionsBadge
  },
  emits: ['dimensions'],
  watch: {
    dimensions(dim) {
      if (!this.waitingOnEmit && !_.jsonEquals(this.dimensionsLocal, dim)) {
        this.dimensionsLocal = _.imm(dim)
      }
    }
  },
  methods: {
    emit() {
      if (!c.jsonEquals(this.dimensionsLocal, this.dimensions)) {
        this.$emit('dimensions', _.imm(this.dimensionsLocal))
      }
      this.waitingOnEmit = 0
    },
    showRow(row) {
      if (this.dimensionsUsed) {
        return !this.showOnlyActiveRows || row.some((abbr) => this.dimensionsUsed.includes(abbr))
      }

      return (
        !this.showOnlyActiveRows || row.some((abbr) => !c.eq(this.dimensionsLocal[abbr].value, 0))
      )
    },
    setDimensions(dimensions) {
      this.waitingOnEmit = 1
      this.dimensionsLocal = dimensions
      c.throttle(() => this.emit())
      // this.dimensions = dimensions;
    },
    visibleDimension(row) {
      return row.filter(
        (abbr) =>
          (!this.showOnlyActiveRows ||
            !this.dimensionsUsed ||
            this.dimensionsUsed.includes(abbr)) &&
          (!this.dimensionsLocal[abbr].isAdvanced || this.showAdvancedDimensions)
      )
    }
  },
  computed: {
    showOnlyActiveRows() {
      return (
        !this.showAll && (this.type === 'assembly' || this.type === 'quote') && this.parentRefId
      )
    },
    inAssembly() {
      return this.storeName === 'Assembly'
    },
    dev() {
      return this.$store.state.session.deviceWidth
    },
    collapsed() {
      if (this.viewAs === null && this.$store.state.session.deviceWidth < 800) {
        return 'popup'
      }

      return this.viewAs
    },
    dimensionsName() {
      return this.parentName
    },
    activeDimensions() {
      const dim = this.dimensionsLocal
      const flatLayout = _.flatten(this.layout)
      return Object.keys(dim).filter((abbr) => dim[abbr].value && flatLayout.includes(abbr))
    },
    fullyLinked() {
      return this.activeDimensions.every((abbr) => this.dimensionsLocal[abbr].inherit)
    },
    partiallyLinked() {
      return (
        !this.fullyLinked &&
        this.activeDimensions.some((abbr) => this.dimensionsLocal[abbr].inherit)
      )
    },
    parentDimensions() {
      // if (this.type === 'assembly') {
      //   // Actually just 'this' dimensions
      //   return this.dimensionsLocal;
      // }
      return this.parentRefId in this.$store.state[this.storeName].normalized
        ? _.imm(this.$store.state[this.storeName].normalized[this.parentRefId].oDimensions)
        : {}
    },
    showAdvancedDimensions() {
      return this.forceShowAdvancedDimensions
    },
    hasParent() {
      return !!this.parentRefId
    },
    visibleRows() {
      return this.layout.filter((row) => this.showRow(row))
    }
  },
  beforeUnmount() {
    if (this.waitingOnEmit) {
      this.emit()
    }
  },
  data() {
    return {
      dimensionsLocal: this.dimensions,
      storeName: this.store,
      forceShowAdvancedDimensions: true,
      forceViewValue: [],
      waitingOnEmit: 0,
      layout: [['fa', 'ca'], ['iwp', 'iwh', 'iwa'], ['ewp', 'ewh', 'ewa'], ['fp'], ['rl', 'ra']],
      collapseLocal: this.collapse
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-dimensions--container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
  font-size: 0.75em;
  border-radius: 0.25em;
  max-width: 100%;
  margin: 0;

  .quote-dimensions--row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0.25em;
    max-width: 100%;
    overflow-x: auto !important;
    width: 100%;

    &:hover {
      overflow: visible;
    }
    .quote-dimensions--dimension-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 7em;
      flex: 0 0 16em;
      background: $cool-gray-300;
      padding: 1em;
      margin: 0;
      border: none;
      border-radius: 0;
      position: relative;
      overflow: hidden;
      transition: all 0.35s ease-out;

      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;

      /*-webkit-perspective: 1000;*/
      /*-moz-perspective: 1000;*/
      /*-ms-perspective: 1000;*/
      /*perspective: 1000;*/
      transform-origin: center;
      transform-style: preserve-3d;
      /*transform:perspective(30em);*/
      -webkit-backface-visibility: hidden;

      &.quote-dimensions--dimension-inherit {
        background: $blue-print-300;
        .quote-dimensions--dimension-label {
          color: $blue-print-600;
        }
      }

      .quote-dimensions--dimension-contents {
        display: flex;
        justify-content: stretch;
        align-items: center;
        font-weight: normal;
        line-height: 1.1;
        max-width: 100%;
      }

      .quote-dimensions--dimension-label {
        font-weight: bold;
        font-size: 1.2em;
        text-align: center;
        line-height: 1;
      }
      .quote-dimensions--dimension-sublabel {
        font-size: 0.7em;
        font-weight: normal;
        display: block;
        margin: 0.1em 0 0.4em 0;
        text-align: center;

        .fa {
          font-size: 0.5em;
          top: 0.001em;
        }
      }

      &.hasValue {
        &.calculated {
          background: $blue-print-200;
          .quote-dimensions--dimension-label {
            color: $blue-print-700;
          }
        }
      }

      .link-button {
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &.more.danger {
          background: $deep-red-800 !important;
          color: $flame-white !important;
          border-top-left-radius: 0 !important;
        }
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .link-button {
          border-top-left-radius: 5px !important;
        }
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:hover {
        box-shadow: $shadow;
        // background: lighten($brand-info, 10%) !important;
        cursor: pointer;
        // transform: perspective(30em);
        // box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.15),
        // 0 0.25em 0.25em rgba(0, 0, 0, 0.05),
        // 0 -0.25em 0.25em rgba(0, 0, 0, 0.05) !important;
        // border-radius: 5px !important;
        &:first-child,
        &:last-child {
          //   border-radius: 5px !important;
        }

        // .quote-dimensions--dimension-label {
        //   color: $flame-white !important;
        // }
      }
    }
  }
}
</style>
