<template>
  <div>
    <Steps :active-step="2" :model="steps" class="custom-steps" :readonly="true">
      <template #item="{ item }">
        <div v-if="item?.icon" class="z-20 flex flex-col items-center justify-center">
          <span
            :class="[
              'inline-flex items-center justify-center rounded-full border-primary-200 border h-12 w-12 z-20 cursor-pointer',
              {
                'bg-primary text-surface-100': item.done && !item.active,
                'bg-surface-100 text-surface-400': !item.done && !item.active,
                'bg-secondary text-surface-100 !border-secondary': item.active
              }
            ]"
          >
            <font-awesome-icon :icon="item.icon" size="lg" />
          </span>
          <span
            class="text-sm whitespace-nowrap"
            :class="{
              'text-surface-500': item.done && !item.active,
              'text-surface-500': !item.done && !item.active,
              'text-secondary font-medium': item.active
            }"
            >{{ item.label }}</span
          >
        </div>
      </template>
    </Steps>

    <Card class="mt-6" selected flat>
      <div class="grid grid-cols-2 gap-4 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-2">
        <div class="">
          <h3 class="!text-secondary !font-medium">Build estimate</h3>
        </div>
        <div class="">
          <span class="!font-medium"> Start from a template </span>
        </div>
        <div class="">
          <span class="!font-medium"> Start from scratch </span>
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Steps from 'primevue/steps'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'

const { object } = RouteEntityContext.useRouteEntityContext({ trackChanges: false })

const hasPendingChangeOrder = computed(() => {
  return /k|f|p/.test(object.value.quote_status) && !object.value.change_order_time_booked
})
const unsentChanges = computed(() => {
  return /k|f|p/.test(object.value.quote_status) && !object.value.change_order_time_sent
})
const allChangesApproved = computed(() => {
  return /k|f|p/.test(object.value.quote_status) && !object.value.change_order_client_has_approved
})

const steps = computed(() =>
  object.value
    ? [
        {
          icon: 'table',
          active:
            (object.value.quote_status === 'p' && !unsentChanges.value) ||
            !object.value.aoChildren.length,
          done: object.value.aoChildren.length > 0,
          label: 'Build estimate'
        },
        // {
        //   icon: 'eye',
        //   done: 1,
        // },
        {
          icon: 'paper-plane',
          active: unsentChanges.value && object.value.aoChildren.length > 0,
          done: object.value.aoChildren.length > 0 && !unsentChanges.value,
          label: 'Send estimate'
        },
        {
          icon: 'file-signature',
          active: !unsentChanges.value && hasPendingChangeOrder.value,
          done:
            object.value.aoChildren.length > 0 &&
            !unsentChanges.value &&
            !hasPendingChangeOrder.value &&
            allChangesApproved.value,
          label: 'Get client approval'
        },
        {
          icon: 'money-bill-wave',
          active: object.value.quote_status === 'f',
          done:
            object.value.aoChildren.length > 0 &&
            !unsentChanges.value &&
            !hasPendingChangeOrder.value &&
            allChangesApproved.value &&
            c.eq(1, object.value.quote_invoiced_percentage),
          label: 'Complete items and collect'
        }
      ]
    : []
)
</script>
