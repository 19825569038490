<template>
  <quote-presentation-assembly
    :object="object"
    :store="store"
    :refId="refId"
    :editable="editable"
    :presentationSettings="presentationSettings"
    :parentRefId="parentRefId"
    :artificialMultiplier="artificialMultiplier"
    v-if="objectType === 'assembly'"
  />
  <quote-presentation-cost-item
    :object="object"
    :store="store"
    :depth="depth"
    :refId="refId"
    :editable="editable"
    :presentationSettings="presentationSettings"
    :parentRefId="parentRefId"
    :artificialMultiplier="artificialMultiplier"
    v-else
  />
</template>

<script>
import QuotePresentationAssembly from './QuotePresentationAssembly.vue'
import QuotePresentationCostItem from './QuotePresentationCostItem.vue'

export default {
  name: 'QuotePresentationItems',
  data() {
    return {}
  },
  watch: {},
  computed: {
    object() {
      return this.$store.state[this.store].normalized[this.reference]
    },
    objectType() {
      return this.$store.state[this.store].normalized[this.reference].type
    }
  },
  methods: {},
  components: {
    QuotePresentationAssembly,
    QuotePresentationCostItem
    /* ChangeAudit, */
  },
  props: {
    depth: {
      default: 1
    },
    deselectOnDestroy: {
      default: false
    },
    reference: {
      required: true
    },
    store: {
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    /**
     * See QuotePresentationAssembly
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
