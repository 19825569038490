<template>
  <div
    class="assembly-child--container-outer"
    :id="`${object.assembly_name} - ${object.refId}`"
    :class="{
      'assembly-child--active': expanded,
      'assembly-child--children-expanded': childrenExpanded
    }"
  >
    <!-- swapper traverse to swap this item -->
    <traverse
      v-if="swapping"
      ref="swapTraverse"
      headless
      :value="`assembly:${object.assembly_id}`"
      :startingParent="object.parent_cost_type_id"
    />

    <mini-modal v-if="modalRequested" ref="options" size="lg">
      <assembly-options v-bind="$props" />
    </mini-modal>

    <card-list
      v-if="!swapping && !loading"
      class="assembly-child--container"
      style="will-change: box-shadow"
      :style="`${expanded ? 'z-index: 999; border-color: transparent; border: none !important; /* box-shadow:0 0 1000px 1000px rgba(0,0,0,0.5) !important; */' : ''}`"
      @contextmenu.stop.prevent="() => $emit('openContextMenu')"
    >
      <card-list-collapse
        class="white assembly-child--collapse"
        headerClass="handle"
        ref="collapse"
        :cloak="true"
        :recloak="true"
        id="contents"
        :showCaret="!smallFormat"
        :caretClass="smallFormat && expanded ? 'arrow-down' : undefined"
        :value="expanded ? ['contents'] : []"
        @click="() => $emit('click')"
        @input="
          (expand) => {
            expanded = expand.includes('contents')
          }
        "
      >
        <template #title>
          <div class="assembly--header-content flex grow justify-between">
            <!-- Left Hand Side -->
            <div class="flex flex-row content-center">
              <Btn rounded class="more px-0 p-2 danger mr-1" v-if="removing" :action="remove">
                <font-awesome-icon icon="trash" />
              </Btn>

              <small class="flex flex-row items-center">
                <SvgIcon
                  v-if="object.quote_has_live_pricing"
                  svg="autocost"
                  class="mr-1"
                  v-tooltip="'This assembly includes AutoCost items'"
                />
                <help
                  class="mr-1"
                  v-if="
                    object.assembly_is_recursed || (inAssembly && synced) || (!inAssembly && synced)
                  "
                  ref="syncHelp"
                >
                  <template #button>
                    <Badge
                      v-if="object.assembly_is_recursed"
                      severity="danger"
                      value=""
                      v-tooltip="
                        'This assembly could not be synced because it is located inside of itself, causing an infinite loop.  It will no longer be updated.'
                      "
                    >
                      <font-awesome-icon icon="rotate-exclamation" />
                    </Badge>
                    <Badge
                      v-if="inAssembly && synced && !isDeleted"
                      severity="info"
                      value=""
                      v-tooltip="
                        'This is a dynamically synced assembly. When it is changed externally, those changes will automatically appear here.  You cannot directly edit the contents of this assembly here.  Open the assembly to see more options.'
                      "
                    >
                      <font-awesome-icon icon="arrows-rotate" />
                    </Badge>
                    <Badge
                      v-else-if="!inAssembly && synced && !isDeleted"
                      class="circled"
                      severity="info"
                      value=""
                      v-tooltip="
                        'This assembly is already saved to your catalog. Tap for more information...'
                      "
                    >
                      <font-awesome-icon icon="database" />
                    </Badge>
                    <Badge
                      v-else-if="isDeleted"
                      class="circled danger"
                      severity="info"
                      value=""
                      v-tooltip="
                        'You are using an assembly that was removed from the catalog. Replace with a working copy.'
                      "
                    >
                      <font-awesome-icon icon="database" />
                    </Badge>
                  </template>

                  <template #title> Saved items and assemblies </template>

                  <template v-if="!inAssembly">
                    <p>
                      This assembly is already saved to your catalog. Any changes you make here
                      within the assembly will not affect the saved version, it will only change it
                      here.
                    </p>

                    <p>
                      If you want to save the changes you make here for future use, from the actions
                      button, choose 'Update in your catalog'. That will overwrite your saved
                      version with your new version.
                    </p>

                    <p>
                      If you want save the changes you made into an entirely
                      <strong>new assembly</strong> then choose 'Save to your catalog', and it will
                      not affect any previous saved version.
                    </p>

                    <p>
                      You do not need to save or update the assembly for your changes to be saved in
                      the proposal/project. You only need to save the proposal/project, and all the
                      saved versions stay the same.
                    </p>
                  </template>

                  <template v-else>
                    <p>
                      This is a saved/sync'ed assembly. You are able to change the name of the
                      assembly in this context, as well as the description. Any other changes you
                      make to the assembly, including the items, tasks, addons/uprades and
                      costing/pricing will be reloaded from fresh each time this assembly is added
                      to a project or proposal.
                    </p>

                    <p>
                      To change items, tasks, addons/uprades and costing/pricing, open and edit this
                      assembly, then save it. Those new changes will be the ones loaded when it is
                      added to a project or proposal.
                    </p>
                  </template>
                </help>

                <Badge
                  v-if="object.item_count_upgrades"
                  value=""
                  v-tooltip="`This assembly, or items inside this assembly have been upgraded.`"
                  class="mr-1"
                  severity="info"
                >
                  <font-awesome-icon icon="arrow-up-from-bracket" />
                </Badge>
                <Badge
                  v-else-if="object.quote_count_addons_available"
                  value=""
                  v-tooltip="`Upgrades/options available on this assembly or items inside.`"
                  class="mr-1"
                  severity="info"
                >
                  <font-awesome-icon icon="arrow-up-from-bracket" />
                </Badge>
              </small>

              <!-- title -->
              <field
                v-if="expanded"
                class="grow"
                :disabled="!editable"
                :clickToSelect="true"
                type="text"
                ref="title"
                placeholder="Name this assembly"
                @input="(v) => setField('assembly_name', v)"
                :value="object.assembly_name"
              />
              <a
                v-else
                class="flex grow items-center gap-x-2"
                @click.stop="
                  () => {
                    $refs.collapse.toggle()
                    $nextTick(() => {
                      if ($refs.title) $refs.title.focus()
                    })
                  }
                "
              >
                <span class="flex items-center gap-x-2">
                  <span class="text-info">
                    {{ object.assembly_name }}
                  </span>
                  <Badge :value="object.aoChildren?.length"></Badge>
                </span>
                <span class="flex items-center gap-x-2">
                  <Badge
                    v-if="object.assembly_is_optional"
                    value="Optional"
                    severity="warning"
                    v-tooltip="'This assembly is optional on the clients proposal.'"
                  >
                  </Badge>
                  <Badge
                    v-if="object.assembly_emphasis > 0"
                    value="Highlighted"
                    severity="success"
                    v-tooltip="'This assembly is highlighted on the clients proposal.'"
                  >
                  </Badge>
                  <Badge
                    v-if="object.assembly_emphasis < -2"
                    value="Hidden"
                    :v-tooltip="`This assembly has been hidden from the clients proposal`"
                  >
                  </Badge>
                </span>
              </a>
              <btn-bar
                ref="contextMenu"
                :position="actionMenuPosition"
                class=""
                :collapse="true"
                :alert="false"
                @click="() => unsyncIfNotExists()"
                :actions="lineItemActions"
              >
                <template #button>
                  <btn :stop="false" :prevent="false" ref="button" class="more more-default">
                    <font-awesome-icon icon="ellipsis" />
                  </btn>
                </template>
              </btn-bar>

              <btn-bar
                :position="actionMenuPosition"
                v-if="warningMinimum || warningLosing"
                class="ml-2"
                :actions="[
                  {
                    title: 'Set to minimum profit',
                    icon: 'arrow-up',
                    action: setMarkupToMinimum
                  },
                  {
                    title: 'Set to default profit',
                    icon: 'arrow-up',
                    action: setMarkupToDefault
                  }
                ]"
                :collapse="true"
              >
                <template #title>
                  <font-awesome-icon icon="exclamation-circle" class="text-client" />
                  <span class="text-danger" v-if="warningLosing"
                    >This item will LOSE MONEY! Adjust pricing markup now!</span
                  >
                  <span class="text-client" v-else-if="warningMinimum"
                    >The markup on this item is below minimum set by company, adjust markup.</span
                  >
                </template>
                <template #button>
                  <btn rounded class="more px-0 p-2 danger lg" v-if="warningLosing">
                    <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
                  </btn>
                  <btn
                    rounded
                    class="more px-0 p-2 warning lg"
                    v-tooltip="'This assembly is below the minimum'"
                    v-else-if="warningMinimum"
                  >
                    <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
                  </btn>
                </template>
              </btn-bar>

              <!-- minimum qty used -->
              <a
                class="more warning ml-2"
                style="font-size: 0.6em !important; top: 1px; position: relative"
                v-tooltip="'This item uses a minimum quantity or minimum area..'"
                v-if="object.assembly_is_using_minimum_qty || object.assembly_is_using_minimum_area"
              >
                <font-awesome-icon icon="arrow-up" style="font-size: 1.4em" />
              </a>
            </div>

            <!-- Right Hand Side -->
            <div class="flex flex-row items-center content-center justify-end" v-if="isIncluded">
              <strong class="assembly--total-label"> Assembly total </strong>

              <div class="assembly--qty">
                <div @click.stop="() => {}">
                  <btn-group class="assembly--qty-pill-group">
                    <!-- qty -->
                    <field
                      type="calculator"
                      :clickToSelect="true"
                      :disabled="!assemblyEditable && !syncedTopLevel"
                      schema="quote:quote_qty_net_base"
                      :value="object.quote_qty_net_base"
                      @input="(v) => setField('quote_qty_net_base', v)"
                      :showButton="true"
                      format="number"
                      :dimensions="parentDimensions"
                      class="text-center"
                      :class="{
                        muted: object.quote_qty_net_base >= 1,
                        danger: object.quote_qty_net_base < 1
                      }"
                      :equation="
                        (object.oEquations && object.oEquations.quote_qty_net_base) || null
                      "
                      @equation="(args) => setEquation('quote_qty_net_base', args)"
                      style="font-weight: bold; max-width: 8em"
                      ref="qtyField"
                    />

                    <btn>each <span class="caret caret-down" style="opacity: 0"></span></btn>
                  </btn-group>
                </div>
              </div>

              <div class="assembly--price gap-y-1" @click.stop="() => {}">
                <template v-if="showMarkupRelatedVariables && !expanded">
                  <a
                    class="number cost-value"
                    v-tooltip="'Cost you pay'"
                    v-if="showForCost.includes('cost')"
                    @click.stop.prevent="() => $refs.collapse.toggle()"
                  >
                    {{ $f.currency(object.quote_total_cost_net_base) }}
                  </a>
                  <a
                    class="number price-value"
                    v-tooltip="'Price your client pays'"
                    v-if="showForCost.includes('price')"
                    @click.stop.prevent="() => $refs.collapse.toggle()"
                  >
                    {{ $f.currency(object.quote_subtotal_net) }}
                  </a>

                  <a
                    class="number profit-value gap-x-1.5"
                    v-tooltip="'Your gross margin on this assembly'"
                    v-if="showForCost.includes('profit') || showForCost.includes('margin')"
                    @click.stop.prevent="() => $refs.collapse.toggle()"
                  >
                    <span v-if="showForCost.includes('margin')"
                      >{{ assemblyMarginPercentage }}%</span
                    >
                    <span v-if="showForCost.includes('profit')">{{ assemblyProfit }}</span>
                  </a>
                </template>
                <field
                  type="calculator"
                  v-else
                  :clickToSelect="true"
                  schema="assembly:quote_subtotal_net"
                  v-model="adjustedPrice"
                  :showButton="true"
                  :equation="(object.oEquations && object.oEquations.quote_subtotal_net) || null"
                  @equation="(args) => setEquation('quote_subtotal_net', args)"
                  classes="number"
                />
              </div>
            </div>
            <div class="assembly--lower-header" v-else>
              <SelectionToggle
                v-model="isIncluded"
                class="sm"
                :active-class="!isIncluded ? 'active danger' : 'active info'"
                :options="[
                  {
                    text: 'Not included',
                    value: 0
                  },
                  {
                    text: 'Included',
                    value: 1
                  }
                ]"
              />
            </div>
          </div>
        </template>

        <!-- content -->
        <assembly-inner v-bind="$props" @open-options-modal="openOptionsModal" />
      </card-list-collapse>
    </card-list>

    <card v-else class="muted flex justify-center items-center">
      <spinner :loading="1" class="light" />
    </card>

    <div
      v-if="!expanded && !loading"
      :data-index="0"
      class="assembly-child--folds"
      key="folds"
      @click="expanded = true"
    >
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import Badge from 'primevue/badge'
import AssemblyInner from './AssemblyInner.vue'
import AssemblyMixin from './AssemblyMixin'
import AssemblyOptions from './AssemblyOptions.vue'
import CheckaUserPermsMixin from '../mixins/CheckaUserPermsMixin'
import { formatCurrency, formatNumber } from '../mixins/CurrencyFilter'
import { useMediaQuery } from '@/composables/mediaQuery'

/**
 * Emits:
 *  -loading
 *  -addingItem
 */
export default {
  name: 'QuoteAssembly',
  mixins: [AssemblyMixin, CheckaUserPermsMixin],
  emits: ['click', 'openContextMenu'],
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  methods: {
    divide: c.divide
  },
  computed: {
    actionMenuPosition() {
      return this.smallFormat ? 'bottom left' : 'bottom right'
    },
    isIncluded: {
      get() {
        return this.object.assembly_is_included
      },
      set(b) {
        this.$store.dispatch(`${this.store}/field`, {
          changes: {
            assembly_is_included: b ? 1 : 0,
            quote_qty_net_base: b ? 1 : 0
          },
          explicit: true,
          store: this.store,
          refId: this.reference
        })
      }
    },
    hasMarkupAdjustment() {
      return !_.eq(this.targetMarkup, this.object.quote_markup_net, 2)
    },
    assemblyMarginPercentage() {
      const number =
        c.divide(
          this.object.quote_subtotal_net - this.object.quote_total_cost_net_base,
          this.object.quote_subtotal_net
        ) * 100

      return formatNumber(number, 0)
    },
    assemblyProfit() {
      return formatCurrency(this.object.quote_subtotal_net - this.object.quote_total_cost_net_base)
    },
    /**
     * Parents dimensions
     */
    parentDimensions() {
      return (
        (this.store &&
          this.object.parentRefId &&
          this.$store.state[this.store] &&
          this.$store.state[this.store].normalized[this.object.parentRefId] &&
          this.$store.state[this.store].normalized[this.object.parentRefId].oDimensions) ||
        {}
      )
    }
  },

  components: {
    AssemblyInner,
    AssemblyOptions,
    Badge
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$childrenContainerBg: $pitch-black;
$borderColor: $pitch-black;
$border: 1px solid $borderColor;
$boxShadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
$container-padding: 10px;

.assembly-badge {
  font-size: 0.6em;
  font-weight: normal;
  &.highlighted {
    background-color: #cce7c9;
    border: 1px solid #8bca84;
    color: black;
  }
}

.assembly-child--container-outer {
  .assembly-child--container {
    box-shadow: $shadow !important;
    z-index: 3;
    border-width: 0px;

    > ul {
      > .assembly-child--collapse {
        > .collapse--container {
          > .collapse--content {
            > .collapse--content-inner {
              font-size: 1em;
              background-color: var(--slate-100);
              transition: all 0.2s ease;
              height: unset;
              // &:hover {
              //   background-color: var(--slate-200);
              // }
            }
          }
        }
      }
    }
  }

  .collapse--title {
    min-height: 3em;
    height: auto;
  }

  .assembly--header-content {
    .assembly--total-label {
      display: none;
    }
  }

  .assembly--title {
    align-items: center;
    text-overflow: ellipsis;
    font-weight: bold;
    color: $cool-gray-600;
    position: relative;
    white-space: normal;
    word-break: break-word;

    a {
      max-width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      font-size: 0.9em;
      flex-basis: 15em;
      min-height: 3em;
      height: 100%;
      background: $flame-white;
      color: $blue-print-700;
      border: none;
      &::placeholder {
        color: rgba($blue-print-700, 0.7);
      }
    }
  }

  .assembly--qty {
    margin-left: 1em;
    flex: 1 0 20em;
    font-size: 0.7em;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;

    [calculator-component] {
      width: 7em;
      flex: 0 0 7em;
      margin-right: 0.1em;
    }
  }

  .assembly--price {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    display: flex;
    flex: 0 0 6em;
    text-align: right;
    margin-left: 1em;
    text-overflow: ellipsis;
    font-weight: normal;

    > .cost-value {
      color: $deep-red-800;
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      line-height: 1;
      font-size: 0.7em;
    }
    > .price-value {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      color: $cool-gray-700;
      line-height: 1;
      font-size: 1em;
    }
    > .profit-value {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      color: $matcha-500;
      line-height: 1;
      font-size: 0.7em;
      white-space: nowrap;
    }

    .calculator-container {
      height: 100%;
    }
    input {
      font-size: 0.8em;
      min-width: 7em;
      min-height: 3em;
      height: 100%;
      background: $flame-white;
      color: $blue-print-700;
      border: none;
      &::placeholder {
        color: rgba($blue-print-700, 0.7);
      }
    }
  }

  .assembly-child--folds {
    z-index: -1;
    margin-top: -1px;
    cursor: pointer;
    transition: opacity 2s;
    height: 15px;
    opacity: 1;
    div {
      background: $flame-white;
      box-shadow: $shadow;
      border: none;
      padding: 0px;
      margin: auto auto;
      transition:
        box-shadow 0.5s ease,
        transform 0.2s ease-out !important;
      border-radius: 0px 0px 2em 2em;
      position: relative;
    }
    div:first-child {
      height: 7px;
      margin-top: -1px;
      width: 96%;
      z-index: 2;
      transition:
        box-shadow 0.5s ease,
        transform 0.2s ease-out !important;
    }
    div:last-child {
      height: 7px;
      margin-top: -1px;
      width: 90%;
      z-index: 1;
      transition:
        box-shadow 0.5s ease,
        transform 0.25s ease-out !important;
    }
  }

  .collapse--closed {
    > .collapse--container {
      > .collapse--header {
        &:hover {
          background-color: $flame-white !important;
        }
      }
    }
  }

  &.assembly-child--active {
    .collapse--open {
      > .collapse--container {
        > .collapse--header {
          color: $flame-white !important;
          .text-success,
          .text-info,
          .assembly--title {
            color: $flame-white !important;
          }

          > .collapse--title {
            > .assembly--header-contents {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  .assembly-child--actions {
    margin: 0 0.25em;
  }
}

// Small format

// Large format
.app--container:not(.small-format) .assembly-child--container-outer {
  > .assembly-child--container {
    > .list-group {
      > .assembly-child--collapse {
        > .collapse--container {
          > .collapse--content {
            > .collapse--content-inner {
              font-size: 0.95em;
            }
          }
        }
      }
    }
  }
}
</style>
