<script setup>
import { defineProps, ref } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import ItemMaterialCosts from '@/components/composables/ItemMaterialCosts.js'

const props = defineProps(['refId', 'store', 'type'])

const {
  cost_type_name,
  purchaseUnitOfMeasureId,
  cost_type_materials_purchase_qty_per_unit,
  unit_of_measure_name,
  unitOfMeasureId,
  cost_type_material_waste_factor_net,
  cost_matrix_materials_cost_net,
  cost_type_has_materials,
  cost_type_is_subcontracted,
  vendorId,
  vendor_name,
  purchase_unit_of_measure_name
} = EntityComputedFields.useEntityComputedFields({
  store: props.store,
  refId: props.refId,
  type: props.type
})

const {
  wastage,
  wastageTerm,
  unitName,
  useImperial,
  pricePerPurchaseUnit,
  isEachD,
  unitsSame,
  perUnitName,
  dunitName
} = ItemMaterialCosts.useItemMaterialCosts({
  cost_type_materials_purchase_qty_per_unit,
  unit_of_measure_name,
  unitOfMeasureId,
  cost_type_material_waste_factor_net,
  cost_matrix_materials_cost_net,
  purchase_unit_of_measure_name,
  purchaseUnitOfMeasureId
})

const refVendor = ref(null)
const punitChoose = ref(null)
</script>

<template>
  <div class="text-sm leading-snug flex flex-col gap-4">
    <Choose
      :order="[['unit_of_measure_name', 'asc']]"
      ref="punitChoose"
      headless
      v-model="purchaseUnitOfMeasureId"
      schema="cost_type:purchase_unit_of_measure_id"
    />

    <Choose
      :order="[['vendor_name', 'asc']]"
      ref="refVendor"
      headless
      v-model="vendorId"
      schema="cost_type:vendor_id"
    />

    <template v-if="cost_type_is_subcontracted">
      <span class="font-medium text-sm">
        <font-awesome-icon icon="truck" class="mr-2" />
        Subcontracted item
      </span>

      <p>
        This item is subcontracted. To modify materials options directly, make this item not
        subcontracted.
      </p>

      <label class="flex items-center content-center gap-2 font-medium">
        Subcontracted
        <InputSwitch v-model="cost_type_is_subcontracted" :trueValue="1" :falseValue="0" />
      </label>
    </template>
    <template v-else-if="!cost_type_has_materials">
      <span class="font-medium text-sm">
        <font-awesome-icon icon="box-taped" class="mr-2" />
        No materials set
      </span>

      <p>There are no materials associated with this item currently.</p>

      <label class="flex items-center content-center gap-2 font-medium">
        Include materials
        <InputSwitch v-model="cost_type_has_materials" :trueValue="1" :falseValue="0" />
      </label>
    </template>
    <template v-else>
      <span class="font-medium text-sm">
        <font-awesome-icon icon="box-taped" class="mr-2" />
        Purchase quantities
      </span>
      <p>
        You purchase <span class="font-medium">{{ cost_type_name }}</span> from your vendor by
        <a
          class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
          @click="punitChoose.open()"
          >{{ unitName }}</a
        >
        <template v-if="!unitsSame"
          >, with
          <CalculatorFormatted
            class="inline font-medium underline underline-offset-4"
            :format="useImperial ? 'imperial' : 'number'"
            v-model="cost_type_materials_purchase_qty_per_unit"
          />
          {{ dunitName }} {{ isEachD ? '' : 'covered ' }}{{ perUnitName }} </template
        >.

        <template v-if="!unitsSame">
          Your vendor charges you
          <CalculatorFormatted
            class="inline font-medium underline underline-offset-4"
            format="$"
            v-model="pricePerPurchaseUnit"
          />
          for each {{ unitName.replace('the', '').trim().toLowerCase() }}.
        </template>
      </p>

      <span class="font-medium text-sm">
        <font-awesome-icon icon="trowel-bricks" class="mr-2" />
        Material wastage
      </span>
      <p>
        You will lose around
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="number"
          v-model="wastage"
        />% of {{ cost_type_name }} to wastage, like {{ wastageTerm }}.
      </p>

      <p>
        <label class="flex items-center content-center gap-2 font-medium">
          Default vendor
          <a
            class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
            @click="refVendor.open()"
            >{{ vendor_name || 'None' }}</a
          >
        </label>
      </p>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
