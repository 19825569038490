<template>
  <preview
    class="client-preview-container preview"
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <PersonAvatar :id="object.client_id" type="client" :name="name" class="mx-2" />
    </template>

    <template #primary>
      {{ $f.truncate(name || 'No name provided', 30) }}
    </template>

    <template #right>
      <next-steps
        :object="object"
        :grid="grid"
        :inline="false"
        v-if="steps"
        style="margin-right: -0.5em"
      />
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import NextSteps from './NextSteps.vue'
import PreviewMixin from './PreviewMixin'

export default {
  name: 'PreviewClient',
  mixins: [PreviewMixin],
  emits: ['click'],
  data() {
    return {}
  },
  computed: {
    desc() {
      return c.removeHtml(this.object.oLeadActivity.activity_desc)
    },
    classStatus() {
      return c.statusColors[this.object.quote_status]
    },
    status() {
      return c.format(this.object.quote_status, 'status')
    },
    name() {
      const object = this.object
      return object.company_name
        ? `${object.company_name} ${object.user_fname ? `(${object.user_fname})` : ''}`
        : object.user_name || object.user_fname || object.user_lname
    }
  },
  components: {
    Preview,
    NextSteps
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.client-preview-container {
  .text-muted {
    font-size: 0.9em;
  }
  .preview {
    align-content: left;
  }
  .activity-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    color: gray;
  }
  .object-activity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .glyphicon,
    .fa {
      font-size: 1.5em;
      margin-right: 0.25em;
      color: $cool-gray-600;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 1;
      > small {
        color: $cool-gray-600;
        font-size: 0.75em;
        line-height: 1;
      }
      > span {
        font-weight: bold;
      }
    }
    + .object-activity {
      margin-left: 0.5em;
    }
  }
}
</style>
