<script>
import Tag from 'primevue/tag'
import SuperIcon from '../Icon/SuperIcon.vue'

export default {
  name: 'ServiceItem',
  props: {
    highlight: {
      type: Boolean,
      default: true
    },
    link: {}
  },
  components: {
    SuperIcon,
    Tag
  },
  methods: {
    close() {
      return this.$store.dispatch('search/close')
    }
  }
}
</script>

<template>
  <li class="flex my-2 hover:bg-cool-gray-100 transition-all duration-300" :service-link="link.to">
    <to-route class="px-4 py-2 flex cursor-pointer" :to="link.to" @before="close">
      <div class="mr-4 text-lg">
        <SvgIcon v-if="link.svg" :svg="link.svg" class="w-[32px] h-[32px]" />
        <SuperIcon v-else-if="link.icon === 'super'" />
        <font-awesome-icon v-else :icon="link.icon" fixed-width />
      </div>

      <span class="text">
        <span class="title font-header text-black">
          {{ link.title }}
          <Tag v-if="highlight && link.highlight" class="ml-3">New</Tag>
        </span>
        <br />
        <small v-if="link.desc" class="desc">
          {{ link.desc }}
        </small>
      </span>
    </to-route>
  </li>
</template>

<style scoped lang="scss"></style>
