<template>
  <div>
    <slot :suggestedDimensions="suggestedDimensions"> </slot>
  </div>
</template>

<script>
import DimensionSelectorMixin from '../quote/item/DimensionSelectorMixin'

export default {
  name: 'CostItemDimSuggestor',
  mixins: [DimensionSelectorMixin],
  props: {
    showWork: {
      default: false
    }
  }
}
</script>
