<template>
  <div
    :style="{
      zoom: zoom
    }"
  >
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Checkmark',
  data() {
    return {}
  },
  computed: {
    zoom() {
      return this.size / 56
    }
  },
  props: {
    size: {
      default: 50
    }
  },
  components: {}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
// @import '~bourbon/core/bourbon';

$color--green: $blue-print;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color--green;
  fill: none;
  animation: stroke 0.6s $curve forwards;
}

.checkmark {
  margin: 0px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $color--green;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s $curve 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $color--green;
  }
}
</style>
