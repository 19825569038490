export default {
  methods: {
    async getName(objType = this.type, id) {
      await c.throttle(() => {}, { key: `getName${objType}${id}` })
      const titleType = c.titleCase(objType)
      const nameField = `${objType}_name`
      const stringId = String(id)
      let found = false
      if (stringId in this.$store.state[titleType].all) {
        found = Object.values(this.$store.state[titleType].all[stringId]).find(
          (o) => !o.parentRefId
        )
      }
      if (found && nameField in found) return found[nameField]
      const { set } = await this.$store.dispatch(`${titleType}/search`, {
        filters: {
          [`${objType}_id`]: id
        }
      })
      if (set.length && set[0] && nameField in set[0]) return set[0][nameField]
      return `${c.nameCase(objType)} - ${id}`
    }
  }
}
