<template>
  <div class="flex flex-col justify-center items-stretch gap-2">
    <div class="flex flex-col justify-center items-stretch gap-12 py-6">
      <div class="flex flex-col justify-stretch gap-2">
        <div class="text-xl font-medium">Who is this estimate for?</div>
        <SelectionToggle
          class="w-full"
          :pt="stpt"
          v-model="estimateFor"
          :options="[
            {
              value: 'existing',
              text: 'Existing client',
              icon: 'house-user'
            },
            {
              value: 'new',
              text: 'New client',
              icon: 'house-flag'
            }
          ]"
          optionLabel="name"
          aria-labelledby="Gantt interval"
        />
        <div
          class="flex justify-between items-center gap-2 bg-surface-200/60 rounded-md p-2 px-4"
          v-if="estimateFor === 'existing'"
        >
          <font-awesome-icon icon="house-user" size="2xl" />
          <choose
            class="!w-full"
            :return-array="false"
            schema="quote:client_id"
            placeholder="Choose a client"
            size="xl"
            :order="[
              ['client_count_quotes', 'asc'],
              ['client_time_last_modified', 'desc']
            ]"
            :validate="{
              required: true
            }"
            v-model="id"
          >
            <template #default="{ text, value }">
              <div class="w-full flex justify-between items-center pr-4">
                <Btn
                  :link="value.length > 0"
                  :severity="!value.length ? 'tertiary' : ''"
                  size="xl"
                  :class="['border-2 ', {}]"
                >
                  <span>{{ text }}</span>
                  <font-awesome-icon icon="chevron-down" size="xl" v-if="!value.length" />
                </Btn>

                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="value.length"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </template>
          </choose>
        </div>

        <div class="flex flex-col bg-surface-200/60 rounded-md" v-else>
          <div class="flex justify-between items-center gap-2 p-2 px-4">
            <div class="flex justify-start items-center gap-6 basis-4/5">
              <font-awesome-icon icon="house-flag" size="2xl" />

              <StringField
                ref="emailOrPhone"
                placeholder="Enter a mobile phone or email"
                :multiline="false"
                @keydown="(e) => focusHandler('firstNameField', e)"
                v-model="emailOrPhone"
                :class="[
                  'h-13 !py-2 px-4 !w-full !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                  {
                    '!border-blue-print !border-solid': !emailOrPhone
                  }
                ]"
              />
            </div>
            <div class="flex justify-end items-end gap-2">
              <font-awesome-icon icon="sms" size="xl" v-if="isEmail === null || !isEmail" />
              <span v-if="isEmail === null">or</span>
              <font-awesome-icon icon="envelope" size="xl" v-if="isEmail === null || isEmail" />
              <font-awesome-icon
                icon="fas fa-check"
                size="xl"
                v-if="isEmail !== null"
                class="text-blue-print animate-fadeIn"
              />
            </div>
          </div>

          <div class="flex justify-between items-center gap-2 p-2 px-4" v-if="isEmail !== null">
            <div class="flex justify-start items-center gap-6 basis-4/5">
              <font-awesome-icon icon="circle-user" size="2xl" class="pr-2" />

              <StringField
                placeholder="First name"
                :multiline="false"
                @keydown="(e) => focusHandler('lastNameField', e)"
                v-model="firstName"
                ref="firstNameField"
                :class="[
                  'h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                  {
                    '!border-blue-print !border-solid !w-1/2': !firstName
                  }
                ]"
              />
              <StringField
                placeholder="Last name"
                :multiline="false"
                ref="lastNameField"
                v-model="lastName"
                @keydown="
                  (e) => {
                    focusHandler('mobilePhone', e)
                    focusHandler('email', e)
                  }
                "
                :class="[
                  'h-13 !py-2 px-4  !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                  {
                    ' !w-1/2': !lastName
                  }
                ]"
              />
            </div>
            <div class="flex justify-end items-end gap-2">
              <font-awesome-icon
                icon="fas fa-check"
                size="xl"
                v-if="firstName"
                class="text-blue-print animate-fadeIn"
              />
            </div>
          </div>

          <template v-if="emailOrPhone && isEmail === true">
            <div class="flex justify-between items-center gap-2 p-2 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <font-awesome-icon icon="sms" size="2xl" class="pr-4" />
                <StringField
                  placeholder="Mobile phone"
                  :multiline="false"
                  @keydown="(e) => focusHandler('quoteNameField', e)"
                  v-model="phone"
                  ref="mobilePhone"
                  :class="[
                    'w-full h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap'
                  ]"
                />
              </div>

              <div class="flex justify-end items-end gap-2">
                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="phone"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </div>
          </template>
          <template v-if="emailOrPhone && isEmail === false">
            <div class="flex justify-between items-center gap-2 p-2 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <font-awesome-icon icon="envelope" size="2xl" class="pr-4" />
                <StringField
                  placeholder="Email"
                  :multiline="false"
                  @keydown="(e) => focusHandler('quoteNameField', e)"
                  v-model="email"
                  ref="email"
                  :class="[
                    'w-full h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap'
                  ]"
                />
              </div>

              <div class="flex justify-end items-end gap-2">
                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="email"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="flex flex-col justify-stretch gap-2" v-if="clientChosen">
        <div class="text-xl font-medium">Name this estimate</div>
        <field
          schema="quote:quote_name"
          ref="quoteNameField"
          :validate="{ required: false }"
          v-model="name"
          placeholder="Use the address or client name if you're not sure..."
          class="!w-full h-14"
        />
      </div>
    </div>

    <div class="flex justify-end mt-5">
      <btn-group style="margin-left: auto">
        <btn size="lg" :action="() => $emit('closeModal')" severity="tertiary-borderless">
          Cancel
        </btn>
        <btn
          size="lg"
          :action="saveAndGo"
          :disabled="!id && isEmail === null"
          severity="primary"
          hotkey="cmd-s"
        >
          Start building
        </btn>
      </btn-group>
    </div>
  </div>
</template>

<script>
import BodyMixin from '../mixins/Body'
import eventBus from '../../eventBus'
import SelectionToggle from '@/components/ui/SelectionToggle.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'

export default {
  components: { SelectionToggle, StringField },
  mixins: [BodyMixin],

  data() {
    return {
      estimateFor: 'existing',
      name: '',
      id: null,
      loading: 0,
      rfq: 0,
      emailOrPhone: null,
      email: '',
      phone: '',
      isEmail: null,
      firstName: '',
      lastName: '',
      stpt: {
        button: ({ context, props }) => ({
          class: [
            'relative',
            // Font
            'leading-none',

            // Flex Alignment
            'inline-flex items-center align-bottom text-center',

            // Spacing
            'px-6 py-4 text-xl !font-medium w-1/2 text-center justify-center',

            // Shape
            'rounded-sm grow',

            // Color
            {
              'bg-transparent': !context.active,
              'text-cool-gray-700 dark:text-white/80': !context.active,
              'border-transparent': !context.active,
              '!bg-pitch-black text-white shadow-md': context.active
            },
            // Invalid State
            { 'border-red-500 dark:border-red-400': props.invalid },

            // States
            {
              'hover:bg-white hover:text-pitch-black hover:shadow-md dark:hover:bg-surface-800/80 ':
                !context.active
            },
            { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none overflow-hidden'
          ]
        })
      }
    }
  },

  computed: {
    clientChosen() {
      return (this.emailOrPhone && this.firstName) || this.id
    }
  },

  watch: {
    estimateFor(efor) {
      if (efor === 'new') {
        this.$nextTick(() => {
          this.$refs.emailOrPhone?.focus()
        })
      }
    },
    async id(id) {
      c.throttle(
        () => {
          this.$refs.quoteNameField?.focus()
        },
        { delay: 50 }
      )
      const { object } = await this.$store.dispatch('Client/fetch', { id })

      this.client = object
      this.name = `${object.user_address || object.client_address || ''}`
    },
    async emailOrPhone(val, old) {
      if (val === old) return
      if (!val) {
        this.isEmail = null
        return
      }
      if (val.includes('@')) {
        this.isEmail = true

        const [found = null] = await this.findUsingEmail()
        if (!found) return

        this.$store.dispatch('alert', {
          success: true,
          message: 'A client with that email already exists!'
        })
        this.id = found.client_id
        this.estimateFor = 'existing'
      } else {
        this.isEmail = false
        this.emailOrPhone = c.format(val, 'phone')

        const [found = null] = await this.findUsingPhone()
        if (!found) return

        this.$store.dispatch('alert', {
          success: true,
          message: 'A client with that phone number already exists!'
        })
        this.id = found.client_id
        this.estimateFor = 'existing'
      }
      c.throttle(() => this.$refs.firstNameField?.focus?.())
      return
    }
  },

  methods: {
    async findUsingPhone(entityType = 'client') {
      if (!this.emailOrPhone) return []

      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          user_phone: c.deformat(this.emailOrPhone, 'phone')
        }
      })
      return set
    },
    async findUsingEmail(entityType = 'client') {
      if (!this.emailOrPhone) return []

      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          user_email: this.emailOrPhone
        }
      })
      return set
    },
    focusHandler(field, e) {
      if (e.key !== 'Tab' && e.key !== 'Enter') return

      setTimeout(() => this.$refs[field]?.focus(), 50)
    },
    async setRfq(rfq) {
      this.rfq = rfq

      await this.$store.dispatch('Quote/addDefaults', {
        object: { quote_is_rfq: rfq },
        defaults: ['quote_is_rfq']
      })
    },
    async saveAndGo() {
      this.loading = 1
      let id = this.id

      Object.values(this.$refs).forEach((ref) => typeof ref?.blur === 'function' && ref?.blur?.()) // blur and commit all fields
      await c.throttle(() => {}, { delay: 500 }) // if enter is pressed from a field it hasn't committed yet

      if (this.estimateFor === 'new') {
        const { object } = await this.$store.dispatch('Client/save', {
          object: {
            client_owner: this.$store.state.session.user.user_id,
            user_email: this.isEmail ? this.emailOrPhone : this.email,
            user_phone: !this.isEmail ? this.emailOrPhone : this.phone,
            user_fname: this.firstName,
            user_lname: this.lastName,
            client_status: 'a'
          },
          go: false,
          alert: false,
          asNew: true
        })
        id = object.client_id
      }

      return this.$store.dispatch('Client/quote', {
        id,
        embue: {
          client_id: id,
          quote_name: this.name,
          quote_is_rfq: this.rfq,
          live_price_zipcode: this.$store.state.session.company.default_live_price_zipcode
        }
      })
    }
  },

  async beforeCreate() {
    const { object } = await this.$store.dispatch('Quote/buildDefaultObject')
    this.rfq = object.quote_is_rfq
    eventBus.$on(`${this.uid}-embued`, () => {
      this.$nextTick(() => {
        if (this.client_id) {
          this.id = this.client_id
        }
      })
    })
  },

  async mounted() {
    this.id = this.clientId
  },

  beforeUnmount() {
    eventBus.$off(`${this.uid}-embued`)
  },

  props: {
    deselectOnDestroy: {
      default: true
    },
    forceFetch: {
      default: false
    },
    forceFull: {
      default: true
    },
    go: {
      default: false
    },
    autoSaveEnabled: {
      default: false
    },
    clientId: {
      default: null
    }
  }
}
</script>
