<template>
  <card class="relative">
    <div class="absolute inset-0 w-full h-full" @click="() => $refs.postField.focus()"></div>
    <div>
      <field
        class="!border-0"
        style="min-height: 100px !important; line-height: 1.1"
        ref="postField"
        placeholder="Post some files, notes or comments.."
        type="textarea"
        schema="activity:activity_desc"
        v-model="activity_desc"
      />

      <div class="mt-3 flex items-end justify-between gap-4">
        <file-list :startingFolder="startingFolder" v-model="aoFiles" class="left" />
        <btn :action="submit" class="btn btn-sm btn-info">Post</btn>
      </div>
    </div>
  </card>
</template>

<script>
import FileList from '../../ui/FileList.vue'

/**
 * Emits:
 *  created(Activity)
 */
export default {
  name: 'ActivityNew',
  emits: ['created'],
  data() {
    return {
      activity_desc: '',
      activity_base_type: 'NOTE',
      aoFiles: [],
      addFiles: false
    }
  },
  methods: {
    async submit() {
      const { object } = await this.$store.dispatch('ajax', {
        path: 'activity/create',
        data: {
          tags: this.tags,
          object: {
            ...this.tags,
            activity_desc: this.activity_desc,
            activity_base_type: this.activity_base_type,
            aoFiles: this.aoFiles
          }
        }
      })

      const mapped = await this.$store.dispatch('Activity/mapEntityFieldsToAppsyncSchema', {
        entityFields: object
      })

      this.$emit('created', mapped)
      this.activity_desc = ''
      this.aoFiles = []

      return mapped
    }
  },
  computed: {
    tags() {
      if (this.filters.activity_pin_object_type && this.filters.activity_pin_object_id) {
        return {
          [`${this.filters.activity_pin_object_type}_id`]: this.filters.activity_pin_object_id,
          activity_object_type: this.filters.activity_pin_object_type,
          activity_object_id: this.filters.activity_pin_object_id
        }
      } else if (
        (!('activity_object_type' in this.filters) || !('activity_object_id' in this.filters)) &&
        Object.keys(this.filters).find((f) => /_id$/.test(f))
      ) {
        const idField = Object.keys(this.filters).find((f) => /^([a-zA-Z_]+)_id$/.test(f))
        const type = idField.replace('_id', '')
        return {
          activity_object_type: type,
          activity_object_id: this.filters[idField],
          ...this.filters
        }
      }
      return this.filters
    },
    message() {
      if ($(window).width() < 300) {
        return 'Msg'
      }
      return 'Message'
    }
  },
  props: {
    startingFolder: {
      default: null
    },
    filters: {
      type: Object,
      required: true
    },
    contact: {
      required: false
    }
  },
  components: { FileList }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.activity-new {
  textarea {
    background: transparent !important;
    border: none !important;
    border-right: none !important;
  }
}
</style>
