import {
  getActivity,
  getActivitiesByTarget,
  getActivitiesByQuote,
  getActivitiesByCreator
} from '../../../src/apollo-client/api-calls/activity'
import { client } from '../../../src/apollo-client/apollo-config'
import _ from '../Helpers'

// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
export default {
  type: 'activity',
  possibleStatuses: ['u', 'c'],
  skipAudit: true,
  notificationObserver: null,
  channelActivitiesObservers: {},
  channelActivities: {},
  channelNotifications: {},

  fields: {
    activity_count_pins: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    client_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'client'
    },
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote'
    },
    invoice_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'invoice'
    },
    task_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'task'
    },
    file_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'file'
    },
    user_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user'
    },
    phone_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'phone'
    },
    phone_call_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'phone_call'
    },
    oClient: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'client',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oQuote: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'quote',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oInvoice: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'invoice',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oTask: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'task',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oFile: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'file',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oMessage: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'message',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oUser: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oPhone: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'phone',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oPhoneCall: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'phone',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    oObject: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: false,
      normalize: false,
      reload: true,
      trackChanges: false
    },
    activity_count_comments: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_commented: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    aoComments: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'activity',
      trackChanges: false
    },
    activity_count_likes: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_liked: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_count_shares: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_shared: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_count_files: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    aoFiles: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'file'
    },
    activity_owner: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    owner_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user'
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company'
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    activity_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    creator_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user'
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      normalize: false,
      reload: true,
      trackChanges: false
    },
    activity_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_base_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    activity_escalation_owner: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_details: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    parent_activity_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    activity_notification_heading: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_object_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_object_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    activity_is_version: {
      title: 'Includes Backup Version',
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    oObjectChanges: {
      type: 'object',
      trackChanges: false,
      save: false
    },
    aoFullChanges: {
      type: 'array',
      trackChanges: false,
      save: false
    },
    aoExplicitChanges: {
      type: 'array',
      trackChanges: false,
      save: false
    }
  },
  generateVueActions() {
    return {
      convertAppsyncActivityFields({ dispatch }, { activity }) {
        return {
          ...activity,
          oOwner: activity.oOwner ? JSON.parse(activity.oOwner) : null,
          oCreator: activity.oCreator ? JSON.parse(activity.oCreator) : null,
          oTarget: activity.oTarget ? JSON.parse(activity.oTarget) : null,
          parent_activity: activity.parent_activity
            ? {
                ...activity.parent_activity,
                oCreator: JSON.parse(activity.parent_activity.oCreator)
              }
            : null
        }
      },
      async comment({ dispatch }, payload) {
        const { comment } = payload
        const { object: activity } = await dispatch('resolveObject', payload)
        const { object } = await dispatch(
          'ajax',
          {
            path: `activity/comment/${activity.activity_id}`,
            data: {
              activity_desc: comment
            }
          },
          { root: true }
        )

        const { object: defaulted } = await dispatch('buildDefaultObject', { embue: object })
        return { ...payload, object: defaulted }
      },
      async thumb({ dispatch }, payload) {
        const { object: activity } = await dispatch('resolveObject', payload)
        const { object } = await dispatch(
          'ajax',
          {
            path: `activity/thumb/${activity.activity_id}`
          },
          { root: true }
        )

        const { object: defaulted } = await dispatch('buildDefaultObject', { embue: object })

        return { ...payload, object: defaulted }
      },
      async mapEntityFieldsToAppsyncSchema({ dispatch }, { entityFields }) {
        const activityTargetType = entityFields.activity_object_type
        const activityTargetObjectKey = `o${_.capitalize(activityTargetType)}`

        if (entityFields.aoExplicitChanges) {
          entityFields[activityTargetObjectKey].aoExplicitChanges = entityFields.aoExplicitChanges
        }
        if (entityFields.aoFullChanges) {
          entityFields[activityTargetObjectKey].aoFullChanges = entityFields.aoFullChanges
        }
        if (entityFields.oObjectChanges) {
          entityFields[activityTargetObjectKey].oObjectChanges = entityFields.oObjectChanges
        }

        return {
          ...entityFields,
          target_type: activityTargetType,
          target_id: entityFields.activity_object_id,
          oTarget: entityFields[activityTargetObjectKey]
        }
      },
      async search({ dispatch }, { filters: preClonedFilters, limit, nextToken }) {
        const filters = _.imm(preClonedFilters)
        if (filters.activity_status) filters.activity_status = 'u||c'
        if (filters.activity_id) {
          return await dispatch('getActivity', {
            id: activity_id,
            limit,
            nextToken
          })
        } else if (filters.quote_id) {
          const { quote_id, ...rest } = filters

          return await dispatch('getActivitiesByQuote', {
            quoteId: quote_id,
            limit,
            nextToken,
            filters: rest
          })
        } else if (filters.activity_creator) {
          const { activity_creator, ...rest } = filters

          return await dispatch('getActivitiesByCreator', {
            creatorId: filters.activity_creator.split('user-')[1] || filters.activity_creator,
            limit,
            nextToken,
            filters: rest
          })
        } else if (filters.target_type && filters.target_id) {
          const { target_id, ...rest } = filters

          return await dispatch('getActivitiesByTarget', {
            targetId: filters.target_id,
            limit,
            nextToken,
            filters: rest
          })
        } else {
          throw new Error('Invalid search parameters')
        }
      },
      async getActivity({ dispatch }, { id }) {
        try {
          const { data } = await client().query({
            query: getActivity,
            variables: {
              activityId: id
            },
            fetchPolicy: 'no-cache'
          })

          return {
            set: await Promise.all(
              data.getActivityById.items.map(async (activity) =>
                dispatch('convertAppsyncActivityFields', { activity: activity })
              )
            ),
            nextToken: data.getActivityById.nextToken
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch activity, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: []
          }
        }
      },
      async getActivitiesByCreator(
        { dispatch },
        { creatorId, limit = null, nextToken = null, filters = null }
      ) {
        const input = {
          id: creatorId,
          limit,
          nextToken,
          filters: _.generateAppsyncFilterPattern(filters)
        }

        try {
          const { data } = await client().query({
            query: getActivitiesByCreator,
            variables: {
              input
            },
            fetchPolicy: 'no-cache'
          })

          return {
            set: await Promise.all(
              data.getActivitiesByCreator.items.map(async (activity) =>
                dispatch('convertAppsyncActivityFields', { activity: activity })
              )
            ),
            nextToken: data.getActivitiesByCreator.nextToken
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch activities, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: [],
            nextToken: null
          }
        }
      },
      async getActivitiesByTarget(
        { dispatch },
        { targetId, targetType, limit = null, nextToken = null, filters = null }
      ) {
        const input = {
          id: targetId,
          limit,
          nextToken,
          filters: _.generateAppsyncFilterPattern(filters)
        }

        try {
          const { data } = await client().query({
            query: getActivitiesByTarget,
            variables: {
              input
            },
            fetchPolicy: 'no-cache'
          })

          return {
            set: await Promise.all(
              data.getActivitiesByTarget.items.map(async (activity) =>
                dispatch('convertAppsyncActivityFields', { activity: activity })
              )
            ),
            nextToken: data.getActivitiesByTarget.nextToken
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch activities, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: [],
            nextToken: null
          }
        }
      },
      async getActivitiesByQuote(
        { dispatch },
        { quoteId, limit = null, nextToken = null, filters = null }
      ) {
        const input = {
          id: quoteId,
          limit,
          nextToken,
          filters: _.generateAppsyncFilterPattern(filters)
        }

        try {
          const { data } = await client().query({
            query: getActivitiesByQuote,
            variables: {
              input
            },
            fetchPolicy: 'no-cache'
          })

          return {
            set: await Promise.all(
              data.getActivitiesByQuote.items.map(async (activity) =>
                dispatch('convertAppsyncActivityFields', { activity: activity })
              )
            ),
            nextToken: data.getActivitiesByQuote.nextToken
          }
        } catch (e) {
          console.error(e)
          await dispatch(
            'alert',
            {
              message: 'Failed to fetch activities, please try again.',
              error: true
            },
            { root: true }
          )

          return {
            set: [],
            nextToken: null
          }
        }
      }
    }
  }
}
