// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'item_assignee',

  skipAudit: true,

  fields: {
    item_assignees_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'item',
    },
    user_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    item_assignee_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_assignees_time_created: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_assignees_time_last_modified: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_assignees_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_assignees_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    item_assignees_owner: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
