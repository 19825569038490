<template>
  <a
    @click.capture.stop.prevent="userMenu.toggle"
    @contextmenu.prevent="userMenu.toggle"
    class="place-self-end w-full z-50 p-4 pr-safe-4 pb-safe-4 flex-none flex bg-cement-200 justify-center hover:bg-cement-400 items-center flex-row text-nowrap overflow-hidden gap-2 border-t border-cement-600 cursor-pointer"
  >
    <div class="h-full flex justify-start items-center">
      <PersonAvatar
        :size="null"
        v-if="effectiveUser"
        type="user"
        :id="effectiveUser.user_id"
        :name="effectiveUserName"
        :picture="picture"
      />
    </div>
    <div
      v-show="!sidePanelStore.collapsed"
      class="h-full w-full flex justify-start items-center flex-col"
    >
      <div
        class="w-full h-1/2 flex text-sm justify-start items-end text-nowrap overflow-hidden font-medium"
      >
        {{ effectiveUserName }}
      </div>
      <div class="w-full h-1/2 text-xs flex justify-start items-start text-nowrap overflow-hidden">
        {{ effectiveUserRole }}
      </div>
    </div>
    <div v-if="showContextBadge">
      <font-awesome-icon
        v-if="userIsImpersonating"
        icon="masks-theater"
        size="lg"
        v-tooltip="'Impersonating another user'"
      />
      <SuperIcon v-else-if="effectiveUser.user_is_super_user" size="lg" />
    </div>
  </a>
  <ContextMenu ref="userMenu" append-to="body" :model="contextMenuItems" />
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useSidePanel } from '@/stores/sidepanel'
import ContextMenu from 'primevue/contextmenu'
import SuperIcon from '@/components/ui/Icon/SuperIcon.vue'

const store = useStore()
const sidePanelStore = useSidePanel()

defineProps({
  contextMenuItems: {
    type: Array
  }
})

const userMenu = ref()

// The user that is explicitly logged in
const authorizedUser = computed(() => store.state.session.authorizedUser)
// The user that is currently
const effectiveUser = computed(() => store.state.session.user)

const effectiveUserName = computed(() => {
  if (!effectiveUser.value.user_fname && !effectiveUser.value.user_lname) {
    const email = effectiveUser.value.user_email
    if (email) return email.split('@')[0]
    return email
  }
  return `${effectiveUser.value.user_fname ?? ''} ${effectiveUser.value.user_lname ?? ''}`
})
const effectiveUserRole = computed(() => effectiveUser.value.user_primary_role)

const userIsImpersonating = computed(() => {
  return (
    authorizedUser.value.user_is_super_user &&
    authorizedUser.value.user_id !== effectiveUser.value.user_id
  )
})

const picture = ref(null)

const showContextBadge = computed(() => {
  return effectiveUser.value.user_is_super_user || userIsImpersonating.value
})

onMounted(() => {
  picture.value = c.link(
    `file/pic/${store.state.session.user.profile_file_id}`,
    {},
    true,
    _.getStorage('scope')
  )
})
</script>

<style>
.safe-padding-bottom {
  padding-bottom: max(env(safe-area-inset-bottom, 0), 1rem);
}
</style>
