export default {
  icon: 'table',
  title: 'Visibility settings, export and search',
  desc: 'Learn how to show/hide columns, export, and search.',
  time: '1 min',
  id: 'Settings',
  setupSheet: async (sheet) => {
    sheet.resetColWidths()
    ;[0, 1, 2, 3, 4, 5, 6, 7].map((i) => sheet.uncollapseSuperHeader(0, i))
    ;[1, 2, 3, 6].map((i) => sheet.collapseSuperHeader(0, i))
    sheet.triggerRedraw()
  },
  steps: [
    {
      icon: 'eye-slash',
      tooltip:
        'You can hide/show sections of columns to declutter your view. This represents a group of hidden columns. Click it to show these columns...',
      position: 'bottom',
      element: ['superHeader', 'Desc'],
      triggerNext: 'superHeaderExpanded'
    },
    {
      icon: 'eye',
      tooltip:
        'Now you are able to see the picture and description column. These allow you to add pictures or a sales description for your customer.',
      position: 'right',
      cell: ['desc', 0],
      focus: false
    },
    {
      icon: 'gear',
      tooltip:
        'You can click on this button to see the visibility settings for all sections of this estimate.',
      element: ['[data-id=visibilitySettingsButton]'],
      focus: false,
      position: 'bottom'
    },
    {
      icon: 'export',
      tooltip:
        'You can export the contents of this sheet into an Excel spreadsheet. Click the export button to download this sheet..',
      element: ['[data-id=exportButton]'],
      focus: false,
      position: 'bottom'
    },
    {
      icon: 'magnifying-glass',
      tooltip:
        'Click this magnifying glass button to search the cells of your sheet. Then click the "x" to close.',
      element: ['[data-id=searchButton]'],
      focus: false,
      position: 'bottom'
    }
  ]
}
