<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="sm"
    :mask="true"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :clickAway="false"
    :expandable="false"
    :scrollable="true"
  >
    <template #body>
      <EntitySelect :object="{ ...object, ...(modal.embue ?? {}) }" type="cost_type">
        <template #default="{ refId, store, type }">
          <cost-type :refId="refId" ref="body" />

          <div class="flex justify-end items-center">
            <Btn link size="xl" :action="close">Cancel</Btn>
            <Btn
              severity="bolster"
              hotkey="enter"
              size="xl"
              :action="() => saveAndClose3({ refId, store, type })"
              >Save</Btn
            >
          </div>
        </template>
      </EntitySelect>
    </template>
  </Modal>
</template>

<script>
import CostType from '../bodies/CostType.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'
import EntitySelect from '@/components/ui/EntitySelect.vue'

export default {
  name: 'CostTypeNewModal',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { CostType, EntitySelect },
  props: {
    go: {
      default: false
    },
    type: {
      default: 'cost_type'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
