// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'

export default {
  type: 'unit_of_measure',

  skipAudit: true,

  fields: {
    unit_of_measure_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_is_default: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_is_metric: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    dimension_measure_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_keywords: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_is_common_purchase_qty: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    }
  },

  generateVueActions() {
    return {
      async getSuggestedUnitOfMeasureFromDimension({ rootState }, dim) {
        const metric = rootState.session.user.user_country > 2
        const mt = dim.dimension_measure_type || dim.measureType

        if (mt === 'length') {
          return metric ? 'm' : 'ft'
        } else if (mt === 'volume') {
          return metric || rootState.session.user.user_country === 1 ? 'm3' : 'yd3'
        } else if (mt === 'area') {
          return metric ? 'm2' : 'ft2'
        } else if (mt === 'weight') {
          return metric ? 'kg' : 'lbs'
        }

        return 'count'
      },
      async isLinkable({ dispatch }, payload = {}) {
        const { object } = await dispatch('resolveObject', payload)
        return !!Object.keys(_.conversionTables).find((abbr) =>
          _.conversionTables[abbr].unit_of_measure_ids.includes(object.unit_of_measure_id)
        )
      }
    }
  }
}
