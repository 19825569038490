<template>
  <div class="ck-editor--ctn" :class="editorClass">
    <div ref="ckEditorElement">
      <Ckeditor
        v-if="isLayoutReady"
        :model-value="text"
        @update:modelValue="updateValue"
        :editor="ClassicEditor"
        :config="config"
        tag-name="textarea"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { Ckeditor } from '@ckeditor/ckeditor5-vue'
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SpecialCharacters,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'
import { basicToolbarItems, fullToolbarItems } from '@/components/ui/DocumentEditor/toolbarItems'

const props = defineProps({
  value: {
    required: true
  },
  basic: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Type or paste your content here!'
  },
  size: {
    type: String,
    required: true,
    default: 'sm'
  },
  dark: {
    type: Boolean,
    default: false
  },
  removeToolbarItems: {
    type: Array,
    required: false,
    default: () => []
  }
})

const emit = defineEmits(['input'])

const isLayoutReady = ref(false)
const config = ref({}) // CKEditor needs the DOM tree before calculating the configuration.

const text = ref(props.value || '') // CKEditor needs this to not be null

const editorClass = computed(() => {
  return {
    [props.size]: true,
    dark: props.dark
  }
})

const updateValue = (value) => {
  emit('input', value)
}

const getToolbarItems = (items, toRemove) => {
  return items.filter((item) => !toRemove.includes(item))
}

onMounted(() => {
  const items = getToolbarItems(
    props.basic ? basicToolbarItems : fullToolbarItems,
    props.removeToolbarItems
  )
  config.value = {
    toolbar: {
      items,
      shouldNotGroupWhenFull: false
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      Autosave,
      Base64UploadAdapter,
      BlockQuote,
      Bold,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      SpecialCharacters,
      Strikethrough,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo
    ],
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 28, 36, 48, 72],
      supportAllValues: true
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage'
      ]
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true
      }
    },
    placeholder: props.placeholder,
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    }
  }

  isLayoutReady.value = true
})
</script>

<style lang="scss">
:root {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 0.2rem; // rounded-sm
}
.ck.ck-balloon-panel.ck-powered-by-balloon {
  display: none;
}

.ck-editor--ctn {
  &.md {
    .ck-editor__editable_inline {
      min-height: 200px;
    }
  }
  &.lg {
    .ck-editor__editable_inline {
      min-height: 400px;
    }
  }
}

.ck-editor--ctn {
  &.dark {
    .ck-content {
      background-color: $pitch-black;
      color: $flame-white;
    }
    .ck-dropdown,
    .ck-toolbar,
    .ck-toolbar__items {
      background-color: $pitch-black;
      color: $flame-white;
    }

    .ck-icon,
    .ck-button {
      color: $flame-white;
    }

    .ck-button,
    .ck-disabled {
      &:hover {
        color: $pitch-black;
        .ck-icon {
          color: $pitch-black;
        }
      }
    }

    .ck-disabled {
      &:hover {
        color: $flame-white;
        .ck-icon {
          color: $flame-white;
        }
      }
    }
  }
}
</style>
