<template>
  <section class="item-modal">
    <div class="text-sm text-cool-gray-400 mb-4 w-full">
      {{
        $f.truncate(
          selected.asAssemblyPath
            ? selected.asAssemblyPath
                .slice(Math.max(selected.asAssemblyPath.length - 2, 0))
                .join(' / ')
            : '',
          80
        )
      }}
    </div>
    <field-container label="Task name">
      <IconField iconPosition="left">
        <InputIcon>
          <font-awesome-icon :icon="['far', 'square-check']" />
        </InputIcon>
        <Field :pt="{ root: '!w-full' }" type="text" @input="updateName" v-model="selected.text" />
      </IconField>
    </field-container>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
      <field-container label="Task assembly">
        <TaskTemplate v-model="template" />
      </field-container>

      <field-container label="Assigned to">
        <Assignees
          size="large"
          @assign="(s) => onAssignment(s, selected)"
          :asField="true"
          :center="false"
          v-model="assignees"
        />
      </field-container>
    </div>

    <div class="grid md:grid-cols-2 gap-3">
      <field-container label="Priority">
        <Choose
          placeholder="Set priority"
          class="w-full"
          btnClass="bg-white border border-cool-gray-500 flex justify-between h-12 rounded-sm w-full"
          v-model="selected.priority"
          @input="(val) => saveItemField(val, 'item_priority', selected.id)"
          :staticSet="
            priorities.map((s) => ({
              text: s.label,
              value: s.key
            }))
          "
        />
      </field-container>
      <field-container label="Task status">
        <Choose
          placeholder="Select task status"
          class="w-full"
          btnClass="bg-white border border-cool-gray-500 flex justify-between h-12 rounded-sm w-full"
          v-model="selected.item_status"
          @input="(val) => onUpdateStatus(val, selected)"
          :staticSet="
            statuses.map((s) => ({
              text: s.name,
              value: s.status
            }))
          "
        />
      </field-container>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
      <field-container label="Set start date">
        <date-picker
          :picker-options="{
            shortcuts: false
          }"
          @date-select="(date) => onChangeDate(selected, date, 'start_date')"
          v-model="selected.start_date"
          ref="datepicker"
        />
        <template #details>
          <p>
            <span class="flex items-center" v-if="selected.item_total_hours">
              <p>
                EST. Workdays:
                <span class="text-sm text-cool-gray-600">
                  {{ workdays }} workday{{ workdays > 1 ? 's' : '' }} ({{
                    selected.item_total_hours
                  }}
                  / {{ hoursPerDayTotal }} = {{ workdays }} workday{{ workdays > 1 ? 's' : '' }})
                </span>
              </p>
            </span>
          </p>
        </template>
      </field-container>
      <field-container label="Set end date">
        <date-picker
          :picker-options="{
            shortcuts: false
          }"
          @date-select="(date) => onChangeDate(selected, date, 'end_date')"
          v-model="selected.end_date"
          ref="datepicker"
        />
      </field-container>
    </div>
    <field-container label="Task notes">
      <Field
        type="textarea"
        @blur="updateNotes"
        placeholder="Add a scope of work or any note here"
        v-model="selected.internal_notes"
        :validate="{ required: false }"
        classes="!bg-white !min-h-36"
      />
    </field-container>
  </section>
</template>
<script setup>
import { computed } from 'vue'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import useTask from '@/components/schedule/Task'
import useSchedule from '@/components/schedule/Schedule'

import DatePicker from '@/components/ui/DatePicker.vue'
import FieldContainer from '@/components/ui/fields/FieldContainer.vue'
import TaskTemplate from '@/components/schedule/fields/TaskTemplate.vue'
import Assignees from '@/components/schedule/fields/Assignees.vue'
import eventBus from '@/eventBus'

const {
  saveCostItemField,
  onChangeDate,
  onUpdateTaskName,
  saveItemField,
  onAssignment,
  priorities,
  updateStatus,
  statuses,
  template
} = useTask()

const { selected, rootRefId, hoursPerDayTotal } = useSchedule()

const workdays = computed(() => Math.ceil(selected.value.item_total_hours / hoursPerDayTotal.value))

const assignees = computed(() => selected.value.assignees || [])

const onUpdateStatus = async (val, selectedItem) => {
  const changes = await updateStatus(val, selectedItem)
  eventBus.$emit('refresh-gantt-items', {
    items: [
      {
        ...changes,
        id: selected.value.parent
      }
    ]
  })
}

const updateName = (val) => {
  c.throttle(
    () => {
      onUpdateTaskName(selected.value.id, val)
    },
    { delay: 400 }
  )
}

const updateNotes = (e) => {
  c.throttle(
    () => {
      const val = e.target.value
      if (val)
        saveCostItemField(
          val,
          'cost_type_production_notes',
          selected.value.item_id,
          rootRefId.value
        )
    },
    { delay: 400 }
  )
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
