<template>
  <div class="flex items-center justify-stretch w-full gap-12">
    <div class="flex items-center justify-start basis-full shrink grow">
      <slot name="leftSide"> </slot>
    </div>
    <div class="flex items-center justify-end basis-[450px] shrink-0">
      <slot name="rightSide"> </slot>
    </div>
  </div>
</template>

<script setup></script>
