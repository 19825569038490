<template>
  <div class="signature-field">
    <label for="signature" class="block text-gray-700 mb-3">Your full name</label>
    <InputText
      v-model="signature"
      type="text"
      aria-label="Signature"
      placeholder="Enter your name"
      class="!w-full !p-2"
    />
    <div class="mt-3 !min-h-20">
      <span class="block text-cool-gray-500 mb-1"
        >Below is how your signature will appear on documents.</span
      >
      <div v-if="signature" class="text-4xl font-signature font-bold">{{ signature }}</div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import InputText from 'primevue/inputtext'
InputText.compatConfig = { MODE: 3 }

export default {
  name: 'SignatureField',
  components: {
    InputText
  },
  emits: ['signature'],
  setup(props, { emit }) {
    const signature = ref('')

    watch(signature, (newVal) => {
      emit('signature', newVal)
    })

    return { signature }
  }
}
</script>
