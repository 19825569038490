<template>
  <modal
    v-bind="$props"
    classes="selector-modal"
    class="modal-mini selector-modal"
    size="lg"
    :scrollable="false"
    :clickAway="false"
    ref="modal"
  >
    <template #header> Select a {{ type.replace('_', ' ') }} </template>
    <template #body>
      <component
        class="bod"
        ref="body"
        :is="component"
        :multiple="modal.multiple"
        :filters="filters"
        @selected="(set) => (selected = set)"
      />
    </template>
    <template #footer>
      <div class="flex justify-end items-center gap-3" style="width: 100%">
        <btn severity="tertiary" size="lg" @click="() => $refs.body.selectAll()">
          <template #icon>
            <font-awesome-icon icon="square-check" />
          </template>
          Select all
        </btn>
        <btn
          severity="tertiary"
          size="lg"
          style="margin-right: auto"
          @click="() => $refs.body.deselectAll()"
        >
          <template #icon>
            <font-awesome-icon icon="square" />
          </template>
          Deselect all
        </btn>
        <btn-group>
          <btn severity="secondary" size="lg" @click="cancel"> Cancel </btn>
          <btn severity="primary-black" size="lg" v-if="selected.length" @click="choose">
            <template #icon>
              <font-awesome-icon icon="check" />
            </template>
            Select {{ selected.length > 1 ? selected.length : '' }}
          </btn>
        </btn-group>
      </div>
    </template>
  </modal>
</template>

<script>
// Name the components as TitleType (singular)
import File from '../Files/List.vue'
import Quote from '../bodies/Quotes.vue'
import LeadRotation from '../bodies/LeadRotation.vue'
import ModalMixin from './ModalMixin'
import eventBus from '../../eventBus'

export default {
  name: 'Selector',
  mixins: [ModalMixin],
  emits: ['selected'],
  data() {
    return {
      selected: []
    }
  },
  computed: {
    filters() {
      return this.modal.filters || {}
    },
    type() {
      return this.modal.type || 'file'
    },
    listenerId() {
      return this.modal.listenerId || false
    },
    component() {
      return c.titleCase(this.type)
    }
  },
  methods: {
    cancel() {
      this.$refs.body.deselectAll()
      this.$refs.modal.close()
      if (this.listenerId) eventBus.$emit(this.listenerId, [])
    },
    choose() {
      this.$emit('selected', this.selected)
      eventBus.$emit('selected', this.selected)
      if (this.listenerId) eventBus.$emit(this.listenerId, [...this.selected])
      this.$refs.modal.close()
    }
  },
  components: {
    File,
    Quote,
    LeadRotation
  },
  props: {
    modal: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
// @media (min-width: 576px) {
.modal.selector-modal:not(.full) {
  .modal-dialog {
    .modal-content {
      .modal-body {
        // @extend %scrollbar;
        max-height: 70vh;
        height: 70vh;
        overflow-y: hidden !important;
        -webkit-overflow-scrolling: touch;
        padding: 0;
        padding-top: 2em;
      }
    }
  }
}

.modal.selector-modal {
  .modal-body {
    padding-top: 0 !important;

    .grid--floating-bar {
      padding-top: 2em !important;
    }

    .files--outer-container .path {
      top: 5em !important;
    }
  }
}
// }
</style>
