<template>
  <div
    :class="
      omitStyle
        ? ''
        : 'w-full text-right bg-cool-gray-100 h-[5rem] px-4 py-4 fixed bottom-0 left-0 right-0 flex items-end justify-end gap-2'
    "
  >
    <slot name="before"></slot>
    <template
      v-if="
        $store.state.session.testing &&
        isDirty &&
        changes &&
        typeof changes === 'object' &&
        (Object.keys(changes).length || !!$parent.$slots.body)
      "
    >
      <btn
        severity="danger"
        size="lg"
        @click="getAndShowChanges"
        v-tooltip="'In development mode only. Check console.'"
      >
        Show changes
      </btn>
      <btn
        severity="danger"
        size="lg"
        @click="() => $refs.compare.open()"
        v-tooltip="'In development mode only. Check console.'"
      >
        Compare
      </btn>
    </template>
    <btn
      v-if="showCancel"
      ref="cancelBtn"
      size="lg"
      severity="tertiary-borderless"
      @click="cancelClick"
    >
      {{ cancelText || (isDirty ? 'Cancel' : 'Done') }}
    </btn>
    <btn
      v-if="showSave"
      ref="saveBtn"
      size="lg"
      hotkey="cmd-s"
      severity="primary-black"
      @click="saveClick"
      :disabled="loadingLocal || !isDirty"
    >
      {{ saveText || 'Save' }}
    </btn>
    <!-- add more buttons -->
    <slot></slot>
  </div>
</template>

<script>
import eventBus from '../../eventBus'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'SaveOrCancel',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      everSaved: false,
      loadingLocal: this.loading
    }
  },
  watch: {
    loading(l) {
      this.loadingLocal = l
      if (this.$refs.saveBtn) {
        if (l) {
          this.$refs.saveBtn.addLoading()
        } else {
          this.$refs.saveBtn.endLoading()
        }
      }
    }
  },
  mounted() {
    eventBus.$on('ctrl-s', this.controlKey)
  },
  beforeUnmount() {
    eventBus.$off('ctrl-s', this.controlKey)
  },
  methods: {
    getDiff(a, b) {
      const aa = JSON.stringify(a, null, 2).split('\n')
      const ab = JSON.stringify(b, null, 2).split('\n')

      return aa
        .map((line, k) => {
          if (line === ab[k]) {
            return `<pre class="m-0" style="white-space: pre-wrap;">${line}</pre>`
          }
          return `<pre class="bg-warning text-dark m-0" style="white-space: pre-wrap;">${line}</pre>`
        })
        .join('')
    },
    getAndShowChanges() {
      if (this.changes && typeof this.changes === 'object' && Object.keys(this.changes).length) {
        console.log(_.imm(this.changes))
      } else {
        const body = c.makeArray(this.$parent.$slots.body)[0].componentInstance
        // window.a = JSON.parse(JSON.stringify(body.getOriginal()));
        // window.b = JSON.parse(JSON.stringify(body.cast()));
        const { changes, original, current } = body.getChanges()
        console.log({ changes, original, current })
      }
    },
    controlKey(e) {
      let closestModal = (parent) => {
        if (parent && parent.$options._componentTag === 'modal') {
          return parent
        } else if (parent) {
          return closestModal(parent.$parent)
        }
        return false
      }
      let el = $(this.$el)
      let visible = el.is(':visible')
      let modalParent = closestModal(this)
      if (
        visible &&
        this.isDirty &&
        (!modalParent || modalParent.zIndex >= this.$store.state.modal.topZIndex)
      ) {
        this.saveClick()
      }
      el = null
      closestModal = null
      modalParent = null
      visible = null
      e.stopPropagation()
      return e.preventDefault()
    },
    saveClick() {
      if (!this.loadingLocal && this.isDirty) {
        this.$refs.saveBtn.addLoading()
        setTimeout(() => {
          this.$emit('save', this.$refs.saveBtn)
          this.$refs.saveBtn.removeLoading()
        })
        this.everSaved = true
      } else {
        this.$refs.saveBtn.endLoading()
        this.cancelClick()
      }
    },
    cancelClick() {
      this.$refs.cancelBtn.addLoading()
      setTimeout(() => {
        this.$emit('cancel', this.$refs.cancelBtn)
        this.$refs.cancelBtn.removeLoading()
      })
    }
  },
  props: {
    cancelText: {
      default: null
    },
    saveText: {
      default: null
    },
    saveIsPrimary: {
      default: true
    },
    isDirty: {
      required: false,
      default: false
    },
    loading: {
      required: false,
      default: 0
    },
    showCancel: {
      required: false,
      default: true
    },
    showSave: {
      required: false,
      default: true
    },
    changes: {
      type: Object,
      required: false,
      default: () => ({})
    },
    omitStyle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
