// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'lead_request',

  skipAudit: true,

  fields: {
    lead_request_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    lead_request_time_created: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    oReference: {
      type: 'object',
      trackChanges: false,
      save: false,
    },
    lead_request_quote_created: {
      type: 'int',
      default: 0,
    },
  },
};
