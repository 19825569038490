<template>
  <div :class="containerClass" class="btn-bar--container">
    <template v-if="actionsFull.length">
      <div class="flex flex-row !divide-x !divide-x-surface-800">
        <slot name="before"></slot>
        <Button
          :size="size"
          severity="primary"
          v-for="(action, index) in actionsFull"
          :class="[
            {
              '!rounded-none':
                (index !== 0 && index !== actionsFull.length - 1) || (index === 0 && $slots.before),
              '!rounded-r-none': index === 0,
              '!rounded-l-none': index === actionsFull.length - 1
            },
            'gap-2 whitespace-nowrap'
          ]"
          v-bind="$attrs"
          :iconPos="action.options?.length ? 'right' : 'left'"
          :key="JSON.stringify(action)"
          :ref="`btn-${actionsVisible.indexOf(action)}`"
          :id="`btn-${actionsVisible.indexOf(action)}`"
          :badge="action.badge"
          :fileInput="action.fileInput"
          @click="() => triggerClick(action, action)"
        >
          <font-awesome-icon
            icon="chevron-down"
            v-if="
              action.options?.length &&
              showDropSubActions.indexOf(actionsVisible.indexOf(action)) === -1
            "
          />
          <font-awesome-icon
            icon="chevron-up"
            v-else-if="
              action.options?.length &&
              showDropSubActions.indexOf(actionsVisible.indexOf(action)) > -1
            "
          />
          <font-awesome-icon
            v-else-if="action.icon && action.icon !== 'super'"
            :icon="action.icon"
          />
          <SuperIcon v-else-if="action.icon && action.icon === 'super'" />
          <template v-if="action.svg && !action.icon"
            ><img class="btn-bar-svg" :src="action.svg"
          /></template>

          <span>{{ action.title || action.text || '' }}</span>
          <drop
            :ref="`drop-${actionsVisible.indexOf(action)}`"
            v-if="action.options && action.options.length"
            :is-open="showDropSubActions.indexOf(actionsVisible.indexOf(action)) > -1"
            @open="() => $emit('open')"
            @close="
              () => {
                $emit('close')
                showDropSubActions = []
              }
            "
            :fixTo="`#btn-${actionsVisible.indexOf(action)}`"
          >
            <div class="flex flex-col">
              <btn-group class="vertical">
                <template v-for="(subAction, index) in visibleActions(action)" :key="index">
                  <btn
                    :class="subAction.class"
                    :fileInput="subAction.fileInput"
                    class="btn-bar--dropdown-btn sm"
                    @click="triggerClick(subAction, subAction, $refs.drop)"
                    :badge="subAction.badge"
                  >
                    <template #icon>
                      <font-awesome-icon
                        v-if="subAction.icon && subAction.icon !== 'super'"
                        :icon="subAction.icon"
                      />
                      <SuperIcon v-else-if="subAction.icon && subAction.icon === 'super'" />
                      <span v-if="subAction.svg && !subAction.icon"
                        ><img class="btn-bar-svg" :src="subAction.svg"
                      /></span>
                    </template>
                    <span v-text="subAction.title || subAction.text"></span>

                    <span class="ml-2">
                      <font-awesome-icon
                        icon="chevron-down"
                        v-if="
                          subAction.options?.length &&
                          showDropSubActions.indexOf(actionsVisible.indexOf(action)) === -1
                        "
                      />
                      <font-awesome-icon
                        icon="chevron-up"
                        v-else-if="
                          subAction.options?.length &&
                          showDropSubActions.indexOf(actionsVisible.indexOf(action)) > -1
                        "
                      />
                    </span>
                  </btn>
                  <template
                    v-if="showDropSubActions.indexOf(actionsVisible.indexOf(subAction)) > -1"
                  >
                    <btn
                      class="btn sm btn-bar--dropdown-btn btn-bar--drop-sub-btn"
                      :class="subAction.class"
                      :indexOne="index"
                      :index2="subAction.options.length"
                      v-for="(subSubAction, index) in filterActionOptions(subAction.options)"
                      :key="subSubAction.title || subSubAction.text"
                      @click="triggerClick(subSubAction, subAction, $refs.drop)"
                    >
                      <template #icon>
                        <font-awesome-icon
                          v-if="subSubAction.icon !== 'super'"
                          :icon="subSubAction.icon"
                        />
                        <SuperIcon v-else />
                      </template>
                      <span v-text="subSubAction.title || subSubAction.text"></span>
                    </btn>
                  </template>
                </template>
              </btn-group>
            </div>
          </drop>
        </Button>
      </div>
      <!--</transition-group>-->
    </template>
    <template v-if="actionsCollapsed.length">
      <div
        class="w-full"
        :class="actionsFull.length ? 'ml-3' : ''"
        :id="uid"
        @click.capture.stop.prevent="openDrop"
      >
        <slot name="button" :loading="loading">
          <btn
            link
            :size="size"
            v-if="showButton"
            :class="btnClass"
            severity="tertiary"
            :stop="false"
            :prevent="false"
            :loading="loading"
            ref="button"
          >
            <p :class="size === 'sm' ? 'text-sm font-light' : ''">
              {{ actionsFull.length ? 'More' : 'Actions' }}
            </p>
            <font-awesome-icon icon="chevron-down" :class="size === 'sm' ? 'text-sm' : ''" />
          </btn>
        </slot>
      </div>
      <drop
        @open="() => $emit('open')"
        @close="() => $emit('close')"
        v-if="requestedDrop"
        ref="drop"
        class="btn-bar--drop"
        :offset="offset"
        :position="position"
        :dropClasses="dropClasses"
        :targetAttachment="targetAttachment"
        :fixTo="fixTo ? fixTo : `#${uid}`"
      >
        <div class="flex flex-col">
          <slot name="before">
            <div class="btn-bar--drop-title" v-if="$slots.title || $slots.description">
              <div class="description-container">
                <slot name="title"></slot>
                <slot name="description"></slot>
              </div>
            </div>
          </slot>
          <div v-if="contextual && selected && selected.length" class="mb-2">
            <small>
              <span class="badge info">{{ selected.length }}</span>
              <strong> selected </strong>
            </small>
          </div>
          <btn-group class="vertical">
            <template v-for="(action, index) in actionsCollapsed" :key="index">
              <btn
                :class="action.class"
                :fileInput="action.fileInput"
                class="text-surface-200 hover:text-surface-900 bg-transparent hover:bg-surface-300"
                @click="triggerClick(action, action, $refs.drop)"
                :badge="action.badge"
              >
                <template #icon>
                  <font-awesome-icon
                    v-if="action.icon && action.icon !== 'super'"
                    :icon="action.icon"
                  />
                  <SuperIcon v-else-if="action.icon && action.icon === 'super'" />
                  <span v-if="action.svg && !action.icon"
                    ><img class="btn-bar-svg" :src="action.svg"
                  /></span>
                </template>
                <span v-text="action.title || action.text"></span>

                <span class="ml-2">
                  <font-awesome-icon
                    icon="chevron-down"
                    v-if="
                      action.options?.length &&
                      showDropSubActions.indexOf(actionsVisible.indexOf(action)) === -1
                    "
                  />
                  <font-awesome-icon
                    icon="chevron-up"
                    v-else-if="
                      action.options?.length &&
                      showDropSubActions.indexOf(actionsVisible.indexOf(action)) > -1
                    "
                  />
                </span>
              </btn>
              <template v-if="showDropSubActions.indexOf(actionsVisible.indexOf(action)) > -1">
                <btn
                  severity="tertiary"
                  :class="[action.class, 'text-surface-100 hover:text-surface-900']"
                  :indexOne="index"
                  :index2="action.options.length"
                  v-for="(subAction, index) in filterActionOptions(action.options)"
                  :key="subAction.title || subAction.text"
                  @click="triggerClick(subAction, action, $refs.drop)"
                >
                  <template #icon>
                    <font-awesome-icon v-if="subAction.icon !== 'super'" :icon="subAction.icon" />
                    <SuperIcon v-else />
                  </template>
                  <span v-text="subAction.title || subAction.text"></span>
                </btn>
              </template>
            </template>
          </btn-group>
        </div>
      </drop>
    </template>
    <btn-group v-if="$slots.default">
      <slot></slot>
    </btn-group>
  </div>
</template>

<script>
import BtnMixin from '@/components/mixins/Button'
import SuperIcon from '@/components/ui/Icon/SuperIcon.vue'
import eventBus from '@/eventBus'

/**
 * Modifier classes:
 *
 *  -vertical
 *  -flex (makes block width, flex display)
 *  -center (added onto flex, to make items center justified instead of flex-start)
 */
export default {
  name: 'BtnBar',
  components: { SuperIcon },
  mixins: [BtnMixin],
  emits: ['open', 'close', 'loading', 'click', 'reload'],
  data() {
    return {
      everClicked: false,
      actionsLocal: this.processActions(this.actions || c.getActions(this.getType()) || {}),
      uid: _.uniqueId(),
      requestedDrop: false,
      showDropSubActions: [],
      forceCollapse: false,
      emitReload: true
    }
  },
  watch: {
    loading(l) {
      this.$emit('loading', l)
    },
    actions(a) {
      this.actionsLocal = this.processActions(a)
    }
  },
  computed: {
    deviceSize() {
      return this.$store.state.session.deviceSize
    },
    embueFilters() {
      let newObj = {}
      if (this.grid?.permanentFilters && Object.keys(this.grid.permanentFilters).length) {
        Object.keys(this.grid.permanentFilters).forEach((k) => {
          if (!/[&|<>%]|LIKE/.test(this.grid.permanentFilters[k])) {
            newObj = {
              [k]: this.grid.permanentFilters[k]
            }
          }
        })
      }
      return newObj
    },
    actionsFull() {
      return _.difference(this.actionsVisible, this.actionsCollapsed)
    },
    actionsCollapsed() {
      const collapsed = this.actionsVisible.filter((a) => this.shouldCollapse(a.collapse))

      const full = _.difference(this.actionsVisible, collapsed).length

      if (full > 4) {
        return this.actionsVisible.slice(4)
      }

      return collapsed
    },
    actionsVisible() {
      if (!c.isempty(this.actionsLocal)) {
        const actionsArray = c.makeArray(
          typeof this.actionsLocal === 'object' && Object.keys(this.actionsLocal).length
            ? Object.values(this.actionsLocal) || []
            : this.actionsLocal || [] || []
        )
        return actionsArray.filter(
          (a) =>
            ((!a.selectionRequired &&
              (!this.contextual || !this.selected || this.selected.length < 1)) ||
              (this.selected?.length && a.selectionRequired)) &&
            !(typeof a.visible === 'function' && !a.visible(this.selected || [], this.$store)) &&
            (!this.editing || a.editing)
        )
      }
      return []
    }
  },
  methods: {
    getType() {
      return this.type || (this.selected?.length && this.selected[0].type)
    },
    processActions(object) {
      const a = Array.isArray(object) ? object : Object.values(object)
      return a.map((action) => ({
        ...c.defaultAction,
        ...action,
        options: !action.options
          ? false
          : action.options.map((op) => ({
              ...c.defaultAction,
              ...op
            }))
      }))
    },
    checkCollapsed(collapseValue = this.collapse) {
      const deviceSize = this.deviceSize
      if (collapseValue === true || this.forceCollapse === true) return true
      else if (collapseValue === 'xl') return true
      else if (collapseValue === 'lg' && deviceSize !== 'xl') return true
      else if (collapseValue === 'md' && !/xl|lg/.test(deviceSize)) return true
      else if (collapseValue === 'sm' && !/md|xl|lg/.test(deviceSize)) return true
      else if (collapseValue === 'xs' && !/sm|md|xl|lg/.test(deviceSize)) return true
      return false
    },
    shouldCollapse(actionCollapse) {
      if (this.collapse === true) return true
      else if (actionCollapse === true) return true
      else if (this.checkCollapsed() && actionCollapse !== false) return true
      else if (typeof actionCollapse === 'string') return this.checkCollapsed(actionCollapse)
      else if (typeof actionCollapse === 'function') {
        return this.checkCollapsed(actionCollapse(this.selected, this.$store, c))
      }
      return false
    },
    /* eslint-disable */
    async triggerClick(action, parentAction = null, drop = null, event = null) {
      const index = this.actionsVisible.indexOf(action)
      const buttonIndex = this.actionsVisible.indexOf(parentAction || action)
      const suggestedButton = event?.button
        ? event.button
        : c.makeArray(this.$refs[`btn-${buttonIndex}`])[0]
      const button =
        this.$store.state.session.deviceSize === 'xs' ||
        this.actionsCollapsed.indexOf(action) > -1 ||
        parentAction
          ? this.$refs.button
          : suggestedButton

      this.$emit(
        'click',
        {
          button,
          action
        },
        event
      )

      if (action.options?.length) {
        if (Object.keys(this.actionsLocal) && Object.keys(this.actionsLocal).length) {
          if (this.showDropSubActions.indexOf(index) > -1) this.showDropSubActions = []
          else this.showDropSubActions = [index]
        }

        return this
      }

      const done = (payload = null) => {
        if (
          this.grid &&
          payload &&
          payload.reload &&
          this.grid.reload &&
          (typeof action.reload === 'undefined' || action.reload)
        ) {
          this.grid.reload(true, true, true).then(() => (button ? c.endLoadingAll(button) : null))
        } else if (button) c.endLoadingAll(button)
      }

      const selected = action.selectionRequired
        ? // If this is a component given, cast it at the moment required only
          this.selected.map((o) =>
            '$el' in o && this.selected[0].dataManipulator && typeof o.cast === 'function'
              ? o.cast()
              : o
          )
        : []
      let payload = {
        objects: selected,
        selected,
        button,
        action,
        grid: this.grid,
        scope: this.scope || this.$store.state.session.scope
      }

      if (this.$refs.drop?.close) {
        this.$refs.drop.close()
      }

      c.addLoadingAll(button)

      await this.$nextTick()

      try {
        this.addLoading()

        await this.before(this.selected)

        if (action.modal) {
          payload = {
            ...payload,
            modal: action.modal,
            embue: this.embueFilters,
            go: this.go
          }

          await this.$store.dispatch('modal/open', payload)
        } else if (typeof action.action === 'string') {
          await this.$store.dispatch(action.action, payload)
        } else if (typeof action.action === 'function') {
          await action.action.call(this, this.$store, payload)
        } else if (action.route) {
          const route =
            typeof action.route === 'function' ? action.route(this.selected) : action.route
          await this.$router.push(route, done).catch(() => {})
        }

        await this.after(this.selected)

        await this.$nextTick()

        if (this.grid?.reload && (typeof action.reload === 'undefined' || action.reload)) {
          this.grid.reload(true, true, true)
        }

        if (this.emitReload) {
          this.$emit('reload')
        } else {
          this.emitReload = true
        }
      } catch (e) {
        if (e && this.alert) {
          this.$store.dispatch('alert', {
            error: true,
            message: e.userMessage || 'An error occurred, please try again.'
          })
        }

        throw e
      } finally {
        this.endLoading()
        c.endLoadingAll(button)
      }

      return this
    },

    openDrop(event) {
      this.everClicked = true
      if (this.requestedDrop !== true) {
        this.requestedDrop = true
        this.$nextTick(() => {
          this.$refs.drop.open(event)
        })
      } else {
        this.$refs.drop.open(event)
      }
      this.$emit('click')
    },

    open(event) {
      return this.openDrop(event)
    },

    toggle(event) {
      return this.open(event)
    },

    checkResize() {
      const elRect = this.$el.getBoundingClientRect()
      const parentRect = $(this.$el).parent()[0].getBoundingClientRect()
      if (c.toNum(elRect.width) > c.toNum(parentRect.width)) {
        this.forceCollapse = true
      }
    },

    visibleActions(action) {
      return action.options.filter((subAction) => subAction.visible(this.selected, this.$store))
    },
    filterActionOptions(options) {
      return options.filter((subAction) => subAction.visible(this.selected, this.$store))
    }
  },
  beforeMount() {
    c.assert(
      this.getType() || this.actions,
      `Either the 'type' or 'actions' prop
        is required for the btn-bar component.`
    )
    eventBus.$on('resize', this.checkResize)
  },
  beforeUnmount() {
    eventBus.$off('resize', this.checkResize)
  },
  props: {
    /**
     * Function to be performed before an action, should by async/return a promise.
     * The first argument in the function will be the array of selected items as
     * provided in the prop "selected"
     */
    before: {
      type: Function,
      default: async () => true
    },

    /**
     * Function to be performed after an action, should by async/return a promise.
     * The first argument in the function will be the array of selected items as
     * provided in the prop "selected"
     */
    after: {
      type: Function,
      default: async () => true
    },

    /**
     * If we are in an editing context, ie: a Client modal, where
     * we are currently activevly editing that client.  This will
     * affect the kind of actions displayed, ie, the action for "go to client modal or page"
     * would not be visible.
     */
    editing: {
      default: false
    },
    /**
     * If this BtnBar is associtated with one particular object, it is contextual.
     *
     * In that case, actions not requiring a selection won't appear, only the ones that
     * require a selection, inclduing editing will appear.
     */
    contextual: {
      default: false
    },
    /**
     * List of selected items, if it is just one item
     * then send :selected="[{ ...myobject }]".
     */
    selected: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * [{
     *  title: '',
     *  class: 'primary',
     *  visible() {},
     *  icon: 'glyphicon glyphicon-...',
     *  action() {},
     * },{}]
     */
    actions: {
      type: [Array, Object],
      required: false
    },
    /**
     * If not sending a list of actions,
     * send the type and c.getActions(type) will
     * get called to summon the actions
     */
    type: {
      type: String,
      required: false
    },
    /**
     * Whether to collapse these items into a
     * pop up button
     * xs, sm, md, lg, xl or true|false
     */
    collapse: {
      type: [Boolean, String],
      default: true
    },
    /**
     * If an action promps a grid reload, this will do it
     * automatically.
     */
    grid: {
      required: false
    },
    btnClass: {
      default: 'rounded'
    },
    showButton: {
      default: true
    },

    /**
     * JQuery selector
     * String
     */
    fixTo: {
      default: null
    },
    btnGroupClass: {
      default: 'mr-2 sm'
    },
    btnIcon: {
      default: 'ellipsis'
    },
    containerClass: {
      default: 'flex justify-start items-center align-content-center'
    },
    position: {
      default: 'bottom right'
    },
    targetAttachment: {
      default: 'bottom center'
    },
    dropClasses: {},
    alert: {
      type: Boolean,
      default: true
    },
    go: {
      default: null
    },
    scope: {
      default: null
    },
    offset: {
      default: '0 -25px'
    },
    size: {
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped></style>
>
