import { ref, onMounted, onBeforeUnmount, getCurrentInstance, computed } from 'vue'

export default {
  useCollapseGroups(args) {
    const {
      getRowSheet,
      dataSheets,
      emit,
      collapseGroupGutterPositionsVisible,
      getRowId,
      getRowFromId,
      colPositionsVisible,
      collapsedGroups,
      canvasCursorType,
      topRow,
      scrollToY
    } = args

    const collapseGroupHighlightCoords = ref({})
    const showCollapseGroupHighlight = ref(false)

    const collapseGroupHighlightStyle = computed(() => {
      const { height, width, top, left } = collapseGroupHighlightCoords.value

      return {
        position: 'absolute',
        height: `${height}px`,
        width: `${width + 10}px`,
        top: `${top - 1}px`,
        left: `${left - 5}px`,
        display: showCollapseGroupHighlight.value ? 'block' : 'none',
        pointerEvents: 'none'
      }
    })

    const highlightCollapseGroup = (position) => {
      let { row, height = null, x = null, y = null } = position

      const parentId = getRowId(row)
      if (x === null || y === null || height === null) {
        const cgp = collapseGroupGutterPositionsVisible.value.find((cg) => cg.id === parentId)
        if (!cgp) {
          showCollapseGroupHighlight.value = false
          return
        }
        ;({ x, y, height } = cgp)
      }

      const sheetIndex = getRowSheet(row)
      const visibleRow = dataSheets.value[sheetIndex].collapseGroups.groups[parentId]?.rows.find(
        (row) => row in colPositionsVisible.value
      )
      const rowCols = colPositionsVisible.value?.[visibleRow] ?? {}
      const colsVis = Object.keys(rowCols || {})
      const lastColIndex = colsVis.pop()
      if (lastColIndex < 0 || !(lastColIndex in rowCols)) {
        showCollapseGroupHighlight.value = false
        return
      }
      const lastColPos = rowCols[lastColIndex]

      const right = lastColPos.x + lastColPos.width
      const width = right - x
      collapseGroupHighlightCoords.value = {
        height,
        width,
        right,
        left: x,
        top: y
      }
      showCollapseGroupHighlight.value = true
    }
    const handleMouseoverHint = (eventData) => {
      const { row } = eventData.element.position

      highlightCollapseGroup({
        ...eventData.element.contextPosition,
        row
      })
    }

    const hideCollapseGroupHighlight = () => {
      collapseGroupHighlightCoords.value = {}
      showCollapseGroupHighlight.value = false
    }

    const handleMouseoutHint = () => {
      hideCollapseGroupHighlight()
    }

    const handleMouseoverCollapseGroupButton = () => {
      canvasCursorType.value = 'pointer'
    }

    const handleMouseoutCollapseGroupButton = () => {
      canvasCursorType.value = 'cell'
    }

    const isGroupCollapsed = (groupId) => collapsedGroups.value.includes(groupId)

    const collapseGroup = (groupId) => {
      collapsedGroups.value = [...collapsedGroups.value, groupId]
      scrollToY(Math.max(0, topRow.value)) // will go to the previous available row

      emit('collapseCollapseGroup', {
        collapseGroupId: groupId
      })
    }

    const expandGroup = (groupId) => {
      const grps = [...collapsedGroups.value].filter((grpid) => grpid !== groupId)
      collapsedGroups.value = grps

      emit('expandCollapseGroup', {
        collapseGroupId: groupId,
        rowIndex: getRowFromId(groupId)
      })
    }

    const handleClickCollapseGroupButton = (eventData) => {
      const { collapseGroupId } = eventData.element

      if (isGroupCollapsed(collapseGroupId)) expandGroup(collapseGroupId)
      else collapseGroup(collapseGroupId)
    }

    const $this = getCurrentInstance().proxy
    onMounted(() => {
      $this.$on('mouseoverCollapseGroupButton', handleMouseoverHint)
      $this.$on('mouseoverCollapseGroupLine', handleMouseoverHint)
      $this.$on('mouseoverCollapseGroup', handleMouseoverHint)
      $this.$on('mouseoutCollapseGroupButton', handleMouseoutHint)
      $this.$on('mouseoutCollapseGroup', handleMouseoutHint)
      $this.$on('mouseoutCollapseGroupLine', handleMouseoutHint)

      $this.$on('clickCollapseGroupButton', handleClickCollapseGroupButton)
      $this.$on('mouseoverCollapseGroupButton', handleMouseoverCollapseGroupButton)
      $this.$on('mouseoutCollapseGroupButton', handleMouseoutCollapseGroupButton)
    })
    onBeforeUnmount(() => {
      $this.$off('mouseoverCollapseGroupButton', handleMouseoverHint)
      $this.$off('mouseoverCollapseGroupLine', handleMouseoverHint)
      $this.$off('mouseoverCollapseGroup', handleMouseoverHint)
      $this.$off('mouseoutCollapseGroupButton', handleMouseoutHint)
      $this.$off('mouseoutCollapseGroup', handleMouseoutHint)
      $this.$off('mouseoutCollapseGroupLine', handleMouseoutHint)

      $this.$off('clickCollapseGroupButton', handleClickCollapseGroupButton)
      $this.$off('mouseoverCollapseGroupButton', handleMouseoverCollapseGroupButton)
      $this.$off('mouseoutCollapseGroupButton', handleMouseoutCollapseGroupButton)
    })

    return {
      collapseGroup,
      expandGroup,
      collapseGroupHighlightCoords,
      showCollapseGroupHighlight,
      collapseGroupHighlightStyle,
      collapsedGroups,
      highlightCollapseGroup,
      hideCollapseGroupHighlight
    }
  }
}
