<template>
  <div class="pdf-viewer">
    <div
      :class="{
        display: supportForPDF && !loading,
        screenSize: screenSize
      }"
      id="pdf-viewer"
    ></div>
    <h4 v-if="!supportForPDF">
      This browser does not support inline PDFs. Please download the PDF to view it:
      <span class="link text-info" @click="onDownload">Download PDF</span>
    </h4>
    <spin-container :loading="loading" style="display: inline-block !important" />
  </div>
</template>
<script>
import SpinContainer from '../ui/SpinContainer.vue'

export default {
  name: 'PdfViewer',
  data() {
    return {
      pdfData: null,
      currentPage: 1,
      numberOfPages: 0
    }
  },
  components: {
    SpinContainer
  },
  methods: {
    onDownload() {
      return this.download()
    }
  },
  props: {
    download: {
      type: Function
    },
    supportForPDF: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    screenSize: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
#pdf-viewer {
  width: 100%;
  display: none;
  embed,
  iframe,
  object {
    width: 100%;
    height: 100vh;
    min-height: 800px;
  }
  &.screenSize {
    embed,
    iframe,
    object {
      min-height: 86vh;
    }
  }
  &.display {
    display: block;
  }
}

.pdf-viewer {
  width: 100%;
  .link {
    cursor: pointer;
  }
}
</style>
