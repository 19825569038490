<template>
  <ul class="cc-list-group">
    <slot></slot>
  </ul>
</template>

<script>
/**
 * Modifier classes:
 *
 *  -brand colors ie:
 *    -primary
 *    -secondary
 *    -info
 *    -warning
 *    -danger
 *    -default
 *    -light
 *    -dark
 *    -success
 *
 *  -sizes ie:
 *    -sm
 *    -md
 *    -lg
 *    -xl
 *
 *  -icon positions ie:
 *    -icon-top
 *    (defaults to icon middle)
 *
 *  -item spacing ie:
 *    -contiguous
 *      (defaults to margin between each item)
 */
export default {
  name: 'ListGroup',
  props: {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cc-list-group {
  margin-bottom: 0px;
}
</style>
