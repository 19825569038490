import UserMetaMixin from '../../mixins/UserMeta'
import Dimension from '../../../../imports/api/schemas/Dimension.js'

export default {
  mixins: [UserMetaMixin],

  watch: {
    value(d) {
      if (d.measure) {
        this.measureLocal = d.measure
      }
    }
  },

  data() {
    let defaultMeasureSettingKey = 'count'

    if (this.value.measureType === 'length') {
      defaultMeasureSettingKey = 'defaultLengthMeasure'
    } else if (this.value.measureType === 'area') {
      defaultMeasureSettingKey = 'defaultAreaMeasure'
    } else if (this.value.measureType === 'volume') {
      defaultMeasureSettingKey = 'defaultVolumeMeasure'
    } else if (this.value.measureType === 'weight') {
      defaultMeasureSettingKey = 'defaultWeightMeasure'
    }

    return {
      dimTypes: {},
      measureLocal: this.value.measure || null,
      defaultMeasureSettingKey
    }
  },

  methods: {
    /**
     * Handler for measure chooser
     */
    setMeasure(measure) {
      this.$emit('input', {
        ...this.value,
        measure
      })

      this.defaultMeasure = measure
    },

    /**
     * Handler for calculator
     */
    setDimension(value, equation = null) {
      const dimension = this.value
      const parentDimension = this.parentDimension

      // If this has not changed in relation to itself,
      // do nothing.
      if (
        c.eqMeasure(dimension.value, dimension.measure, value, dimension.measure) &&
        dimension.equation === equation
      ) {
        return
      }

      // This has changed, but if it is the same as its parent
      // we should just link it instead.
      if (
        parentDimension &&
        c.eqMeasure(parentDimension.value, parentDimension.measure, value, dimension.measure, 4) &&
        parentDimension.value > 2
      ) {
        this.$store.dispatch('alert', {
          message: `${c.format(value, 'number')} ${dimension.measure} is the same as the parent dimension.
           
           Linking ${dimension.name} to parent dimension!`
        })
        this.$emit('input', {
          ...parentDimension,
          value,
          measure: dimension.measure,
          inherit: 1,
          equation
        })

        return
      }

      this.$emit('input', {
        ...dimension,
        value,
        explicitlySet: 1,
        equation,
        inherit: 0
      })
    }
  },

  computed: {
    inheritMessage() {
      return this.inheritsFrom
        ? `Currently inherits from <strong>"${this.dimensionType.name}"</strong> of <strong>${this.inheritsFrom}</strong>.`
        : 'Set this dimension if any of items require it.'
    },
    inheritsFrom() {
      if (this.store === 'Assembly' && !this.object.parentRefId) {
        return 'the context it is added to'
      } else if (!this.object.parentRefId) {
        return null
      }

      const parent = this.$store.state[this.store].normalized[this.object.parentRefId]

      return parent.assembly_name || parent.quote_name
    },
    numRequiredBy() {
      return _.uniq(
        (this.object.oChildRequiredDimensions?.[this.abbr] ?? []).map(
          (o) => `${o.name}${o.type === 'addon' ? ' (upgrade)' : ''}`
        )
      ).length
    },
    requiredBy() {
      if (this.abbr in this.object.oChildRequiredDimensions) {
        return _.uniq(
          this.object.oChildRequiredDimensions[this.abbr].map(
            (o) => `${o.name}${o.type === 'addon' ? ' (upgrade)' : ''}`
          )
        ).join(',  ')
      }

      return 'other dimensions'
    },
    nameStyle() {
      return `background-color: #${this.dimensionType.color};`
    },
    borderStyle() {
      return `border-color: #${this.dimensionType.color};`
    },

    usingDimensions() {
      const eq = this.value.equation
      if (!eq) {
        return []
      }

      const { usingDimensions } = _.getComputedDimension(this.dimensions, eq, this.value.measure)

      return usingDimensions
    },
    isCalculated() {
      return !!this.usingDimensions.length
    },

    format() {
      return /count|each|square/.test(this.value.measure) ||
        /kg|lbs/.test(this.value.measure) ||
        /m/.test(this.value.measure) ||
        /\d/.test(this.value.measure)
        ? 'number'
        : 'imperial'
    },

    formattedValue() {
      return c.format(this.value.value, this.format)
    },

    parentName() {
      return (
        (this.dimParent.refId !== this.reference &&
          (this.dimParent.assembly_name || 'project root')) ||
        'room/project'
      )
    },

    defaultMeasure: {
      get() {
        return this.getMetaItem(this.defaultMeasureSettingKey) || this.measuresPossible[0]
      },
      set(measure) {
        this.setMetaItem(this.defaultMeasureSettingKey, measure)
      }
    },

    measure: {
      get() {
        return this.measureLocal || this.defaultMeasure
      },
      set(measure) {
        this.measureLocal = measure
        this.setMeasure(measure)
      }
    },

    measuresPossible() {
      if (this.value.measureType === 'count') return ['count']

      const ct = _.imm(c.conversionTables)
      const measures = Object.keys(c.conversionTables).filter(
        (mt) => ct[mt].measureType === this.value.measureType
      )

      return measures
    },

    /**
     * A list for the choose for measures
     */
    measuresPreset() {
      return this.measuresPossible.map((m) => ({ text: m === 'ft' ? 'ft/in' : m, value: m }))
    },

    /**
     * If the value is missing return true
     * @returns {bool}
     */
    missing() {
      return !this.value.value && !this.value.equation && this.required
    },

    /**
     * Checks if it is linked
     * @returns {bool}
     */
    isLinked: {
      get() {
        return (!this.isRoot || this.isAssemblyRoot) && this.value.inherit
      },
      set(linked) {
        if (linked) {
          const startingPoint = this.isAssemblyRoot
            ? this.value
            : this.parentDimension || this.value

          this.$emit('input', {
            ...startingPoint,
            value: this.value.value,
            measure: this.value.measure,
            inherit: 1,
            equation: this.value.equation
          })
        } else {
          this.$emit('input', {
            ...this.value,
            value: this.value.value,
            explicitlySet: 1,
            equation: '',
            inherit: 0
          })
        }
      }
    },

    isAssemblyRoot() {
      return this.isRoot && this.store === 'Assembly' && this.object.type === 'assembly'
    },

    /**
     * Whether this is the root object or not
     * @returns {bool}
     */
    isRoot() {
      return !this.object.parentRefId
    },

    /**
     * Parent or null
     * @returns {object | null}
     */
    dimParent() {
      return this.norm[this.dimParentRef] ?? null
    },

    /**
     * Closest parent that has this dimension, or root
     * @returns {string}    referenceId
     */
    dimParentRef() {
      return Dimension.getDimParent(this.norm, this.reference, this.dimensionType.dimension_id)
    },

    /**
     * Active normalized set
     */
    norm() {
      return this.$store.state[this.store].normalized
    },

    /**
     * Dimensions object for this assembly/quote
     * @returns {object}
     */
    dimensions() {
      return this.object.oDimensions
    },

    /**
     * Get dimensions of the parent
     * @returns {object | null}
     */
    parentDimensions() {
      const pref = this.object.parentRefId
      return (pref && pref in this.norm && this.norm[pref].oDimensions) || null
    },

    /**
     * Get the parent dimension related to this dimension
     * @returns {object}
     */
    parentDimension() {
      return this.parentDimensions ? this.parentDimensions[this.value.abbr] : false
    },

    /**
     * This dimension type
     * @returns {object}
     */
    dimensionType() {
      const abbr = this.value.abbr

      if (!(abbr in this.dimTypes)) return this.value

      return this.dimTypes[abbr]
    },

    /**
     * Full name
     * @returns {string}
     */
    name() {
      return this.dimensionType.name
    },

    /**
     * Full name
     * @returns {string}
     */
    abbr() {
      return this.value.abbr
    }
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    required: {
      type: Boolean,
      default: true
    },

    store: {
      required: true,
      default: 'Quote'
    },

    reference: {
      required: true
    },

    object: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],

  async created() {
    this.dimTypes = await this.$store.dispatch('Dimension/getPossibleDimensions')
  }
}
