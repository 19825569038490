import {
  getFileKey as getFileKeyFn,
  getFileKeys as getFileKeysFn,
  getPreSignedPost as getPreSignedPostFn,
  deleteFile as deleteFileFn
} from '@/apollo-client/services/bolsterApprovals'

import { ref } from 'vue'

export function useFileService() {
  const error = ref(null)

  const executeFn = async (fn, params) => {
    try {
      return await fn(params)
    } catch (err) {
      error.value = err
      console.error(`Error in ${fn.name}:`, err)
      throw err
    }
  }

  const getFileKeys = (prefix) => executeFn(getFileKeysFn, { prefix })
  const getFileKey = (prefix) => executeFn(getFileKeyFn, { prefix })
  const getPreSignedPost = (key) => executeFn(getPreSignedPostFn, { key })
  const deleteFile = (key) => executeFn(deleteFileFn, { key })

  return {
    getFileKeys,
    getFileKey,
    getPreSignedPost,
    deleteFile,
    error
  }
}
