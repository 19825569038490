import languages from '../languages'

const getUserLang = (user = null) => (user && user.localization_language_code) || 'en'

const getUserCountry = (user = null) => (user && user.localization_country_code) || 'US'

const languageOptions = Object.keys(languages).reduce(
  (acc, lang) => [
    ...acc,
    {
      name: languages[lang].languageName,
      value: lang
    }
  ],
  []
)

const getUserLocalLanguage = (user = null) => {
  const userLang = getUserLang(user)
  const userCountry = getUserCountry(user)
  return `${userLang}_${userCountry}`
}

const convertPhrase = (phrase, language = 'en_US') => {
  if (language in languages && phrase in languages[language]) {
    return languages[language][phrase]
  }

  return phrase
}

export default {
  data() {
    return {
      languageSelected: getUserLocalLanguage(this.$store.state.session.user),
      languageOptions
    }
  },

  methods: {
    l(phrase) {
      return convertPhrase(phrase, this.languageSelected)
    }
  },

  l: convertPhrase,
  convertPhrase,
  getUserLocalLanguage,
  getLang: getUserLocalLanguage,
  getUserCountry,
  getUserLang
}
