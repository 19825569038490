<template>
  <RecursiveSelectionsItem
    v-for="ref of groups"
    :key="ref"
    :ref-id="ref"
    :presentation-settings="presentationSettings"
    :editable="editable"
    :store="store"
    :interactiveRefs="interactiveRefs"
    :isInQuoteEditor="isInQuoteEditor"
    :disabledList="disabledList"
  />
</template>

<script>
import { computed } from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import eventBus from '../../../eventBus'
import RecursiveSelectionsItem from '@/components/quote/presentation/RecursiveSelectionsItem.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
import useSelections from '@/components/composables/UseSelections'
import { useStore } from 'vuex'

export default {
  name: 'Selections',
  mixins: [TranslationMixin],
  emits: [
    'disabled-list',
    'groups',
    'setEmphasizedItems',
    'update-interactive-items',
    'highlight-item'
  ],
  setup(props, { emit }) {
    const { smallFormat } = useMediaQuery()
    const $store = useStore()
    const normalized = computed(() => $store.state.Quote.normalized)
    const selections = useSelections(normalized)

    const subRefsEmphasized = computed(() => selections.emphasizedRefs)

    const interactiveRefs = computed(() => {
      const uniqueInteractiveRefs = selections.interactiveRefs
      emit('update-interactive-items', uniqueInteractiveRefs)
      return uniqueInteractiveRefs
    })

    const standardRefs = computed(() => selections.standardRefs)

    const groups = computed(() => {
      const order = selections.preferredOrder.value
      const groups = Object.values(normalized.value[props.refId].aoChildren || [])
      const orderedGroups = _.uniq(groups)
        .filter((ref) => selections.shouldShowItem(ref))
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      emit('groups', orderedGroups)
      return orderedGroups
    })

    return {
      smallFormat,
      subRefsEmphasized,
      interactiveRefs,
      standardRefs,
      groups
    }
  },
  data() {
    return {
      fullRemoval: [],
      notOptional: [],
      titleStyle: {},
      excludeList: [],
      callback: null,
      currentInteractiveIndex: 0,
      disabledList: []
    }
  },
  created() {
    eventBus.$on('add-to-disabled-list', (refId) => {
      if (!this.disabledList.includes(refId)) {
        this.disableChildren(refId)
      }
    })

    eventBus.$on('remove-from-disabled-list', (refId) => {
      this.enableChildren(refId)
    })
  },
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    }
  },
  watch: {
    subRefsEmphasized: {
      immediate: true,
      handler(value) {
        this.$emit('setEmphasizedItems', value.length)
      }
    }
  },
  asyncComputed: {
    possDimensions: {
      async get() {
        return this.$store.dispatch('Dimension/getPossibleDimensions')
      },
      default: () => ({})
    }
  },
  methods: {
    async setOffsets() {
      const firstSectionTitle = this.$refs.sectionTitle && this.$refs.sectionTitle[0]
      if (!firstSectionTitle) return
      const parent = c.getScrollParent(firstSectionTitle)
      const { top, left: parentLeft } = parent.getBoundingClientRect()

      this.callback = () => {
        this.$refs.sectionTitle.forEach((head) => {
          const headTop = head.getBoundingClientRect().top
          const diff = headTop - top
          if (Math.abs(diff) < 5) {
            head.classList.add('current')
          } else if (Math.abs(diff) > 15) {
            head.classList.remove('current')
          }
        })
      }

      await this.$nextTick()
      parent.addEventListener('scroll', this.callback, { passive: true })

      const lgFormatExtra = this.smallFormat ? 0 : 50
      const smallFormatExtra = this.smallFormat ? 25 : 0

      const { left } = firstSectionTitle.getBoundingClientRect()
      const padDiff = left - parentLeft + lgFormatExtra

      this.titleStyle = {
        marginLeft: `${-padDiff}px`,
        paddingLeft: `${padDiff + smallFormatExtra}px`
      }
    },
    async clearOffsets() {
      window.removeEventListener('scroll', this.callback)
      const firstSectionTitle = this.$refs.sectionTitle && this.$refs.sectionTitle[0]
      if (firstSectionTitle) {
        const parent = c.getScrollParent(firstSectionTitle)
        parent.removeEventListener('scroll', this.callback, { passive: true })
      }

      this.titleStyle = {}
      await c.throttle(() => {}, { delay: 500 })
      await this.$nextTick()
    },
    async onResize() {
      await this.clearOffsets(this)
      return this.setOffsets(this)
    },
    disableChildren(refId) {
      const object = this.norm[refId]
      if (object.aoChildren && object.aoChildren.length > 0) {
        object.aoChildren.forEach((childRefId) => {
          // Add child to disabledList
          if (!this.disabledList.includes(childRefId)) {
            this.disabledList.push(childRefId)
            // Recursively disable further children
            this.disableChildren(childRefId)
          }
        })
      }
      this.$emit('disabled-list', this.disabledList)
    },

    enableChildren(refId) {
      const object = this.norm[refId]
      if (object.aoChildren && object.aoChildren.length > 0) {
        // Filter out children from disabledList
        this.disabledList = this.disabledList.filter((id) => !object.aoChildren.includes(id))
        // Recursively enable further children
        object.aoChildren.forEach((childRefId) => {
          this.enableChildren(childRefId)
        })
      }
      this.$emit('disabled-list', this.disabledList)
    }
  },
  components: {
    RecursiveSelectionsItem
  },
  props: {
    refId: {
      required: true
    },
    deselectOnDestroy: {
      default: false
    },
    store: {
      type: String,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    presentationSettings: {
      type: Object
    },
    interactiveItems: {
      type: Array
    },
    /**
     * See QuotePresenetationAssembly
     */
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    showGeneralPrice: {
      default: true
    },
    isInQuoteEditor: {
      type: Boolean
    },
    setDisableList: {
      type: Function
    }
  },
  mounted() {
    if (this.$refs.sectionTitle && this.$refs.sectionTitle.length) {
      this.setOffsets(this)
    }

    eventBus.$on('resize', this.onResize)
  },
  beforeUnmount() {
    this.clearOffsets(this)
    eventBus.$off('resize', this.onResize)
    eventBus.$off('add-to-disabled-list')
    eventBus.$off('remove-from-disabled-list')
  }
}
</script>
