<template>
  <div class="flex flex-col items-center">
    <span v-if="title" class="text-[12px] mb-2">{{ title }}</span>
    <PrimevueColorPicker
      v-model="parsedColor"
      v-tooltip.left="value"
      :format="formatType"
      :inline="inline"
      :baseZIndex="topZIndex + 1"
    />
  </div>
</template>

<script>
import PrimevueColorPicker from 'primevue/colorpicker'
import { useColorManipulator } from '@/components/composables/colorManipulator'

PrimevueColorPicker.compatConfig = { MODE: 3 }

export default {
  name: 'ColorPicker',
  emits: ['input', 'change'],
  setup() {
    const { rgbToHex, hexToRgb } = useColorManipulator()

    return {
      rgbToHex,
      hexToRgb
    }
  },
  computed: {
    formatType() {
      if (/^#?[a-fA-F0-9]{3,6}/.test(this.value)) return 'hex'
      else if (/^rgba?\(.*\)$/.test(this.value)) return 'rgb'
      return undefined
    },
    topZIndex() {
      return this.$store.state.modal.topZIndex
    },
    parsedColor: {
      get() {
        if (this.formatType === 'hex') {
          // Allow the hex to have an optional octothorpe
          const matches = this.value.match(/#?([a-fA-F0-9]{3,6})/)

          return matches ? matches[1] : this.value
        } else if (this.formatType === 'rgb') {
          // Can't do much with an alpha value at the moment
          const rgb = this.value.match(/rgba?\((?<r>\n+),\s*(?<g>\n+),\s*(?<b>\n+)(?:,\s*\n+)?\)/)
          // In some weird cases with templates, we may have a mixin and a hex value
          const hasHex = this.value.match(/#?(?<hex>[a-fA-F0-9]{3,6})/)

          if (!rgb && hasHex) {
            // Build the RGB value out of the hex
            const fromHex = this.hexToRgb(hasHex.groups.hex)
            return {
              r: fromHex[0],
              g: fromHex[1],
              b: fromHex[2]
            }
          }

          return {
            r: rgb.groups.r,
            g: rgb.groups.g,
            b: rgb.groups.b
          }
        }
        return this.value
      },
      set(v) {
        if (this.formatType === 'hex') {
          this.emitNow('#' + v)
        }
        if (this.formatType === 'rgb') {
          this.emitNow(`rgb(${v.r}, ${v.g}, ${v.b})`)
        }
      }
    }
  },
  methods: {
    emitNow(v) {
      this.$emit('input', v)
      this.$emit('change', v)
    }
  },
  components: {
    PrimevueColorPicker
  },
  props: {
    value: {
      required: true
    },
    title: {
      default: null
    },
    inline: {
      default: false
    },
    tooltipPosition: {
      default: 'left'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
