<template>
  <div class="assembly--options">
    <card-section>
      <btn-bar
        btnClass="more info"
        :contextual="true"
        :editing="true"
        :actions="lineItemActions"
        :before="async () => commit()"
        :after="async () => $emit('close')"
      />
    </card-section>

    <card-section>
      <template #label> {{ object.assembly_name }} dimensions </template>
      <assembly-dimensions
        :showDerivedDimensions="true"
        v-model="dimensions"
        :object="object"
        :reference="reference"
        :store="store"
      />
    </card-section>

    <card-section style="min-width: 100%">
      <card-list>
        <card-list-field>
          <span>Assembly name</span>
          <field
            schema="assembly:assembly_name"
            :value="object.assembly_name"
            @input="(v) => setField('assembly_name', v)"
          />
        </card-list-field>
        <CardListField v-if="isGlobalAssembly && !inGlobalScope">
          <span>
            <div>
              <span style="color: #606a73; font-weight: bold">Category</span>
              <font-awesome-icon
                icon="exclamation-circle"
                style="color: #facf58; font-size: 1.5em"
              />
            </div>
          </span>

          <span style="padding-top: 0.5em">
            <small>Can not set the category of a global assembly</small>
          </span>
        </CardListField>
        <CardListField v-else>
          <span>
            <Help>
              <template #before> Category </template>

              <p>
                Keep your items organized in categories so they are easier to manage in the items
                list. This is a quick easy way to add this item to an existing category.
              </p>
            </Help>

            <SuggestionButton class="info sm" @click="newCategory()">
              <font-awesome-icon icon="plus" /> New...
            </SuggestionButton>

            <span class="text-muted">
              <!--              {{aoPath}}-->
            </span>
          </span>

          <Choose
            v-model="parent_cost_type_id"
            schema="cost_type:cost_type_id"
            :custom-options="[
              {
                value: 'NULL',
                text: 'Root directory',
                html: 'Root directory'
              }
            ]"
            :allow-create="false"
            :filters="{
              cost_type_is_parent: 1,
              company_id:
                ($store.state.session.company && $store.state.session.company.company_id) || 'NULL'
            }"
          />
        </CardListField>
        <card-list-field>
          <span>Notes</span>
          <field
            schema="quote:quote_notes"
            type="textarea"
            placeholder="Notes about this assembly.."
            :value="object.quote_notes"
            @input="(v) => setField('quote_notes', v)"
          />
        </card-list-field>

        <card-list-field>
          <span>Quantity</span>
          <field
            type="calculator"
            :equation="quote_qty_net_base_equation"
            @equation="(e) => setEquation('quote_qty_net_base', e)"
            schema="assembly:quote_qty_net_base"
            :value="object.quote_qty_net_base"
            @input="(v) => setField('quote_qty_net_base', v)"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section class="sm">
      <template #label> Attributes </template>
      <meta-list :value="object.aoProperties" @input="(v) => setField('aoProperties', v)" />
    </card-section>

    <card-section>
      <template #label> Files </template>
      <file-list
        :value="object.file_ids"
        :idList="true"
        @input="(ids) => setField('file_ids', ids)"
        :highlightFirst="true"
        class="left"
      />
    </card-section>

    <card-section class="sm">
      <template #label> Presentation/display settings </template>
      <card-list>
        <card-list-field>
          <span>
            Display settings
            <br />
            <small class="text-muted">
              Change the way this assembly appears in your presentation.
            </small>
          </span>
          <choose
            :value="assembly_emphasis"
            @input="(v) => setField('assembly_emphasis', v)"
            :default="0"
            schema="assembly:assembly_emphasis"
            :allowCreate="false"
            :return-array="false"
            :staticSet="[
              {
                value: 1,
                text: 'Emphasize the assembly on proposal'
              },
              {
                value: 0,
                text: 'Show the assembly normally'
              },
              {
                value: -1,
                text: 'Display as emphasized single item (classic preview only)'
              },
              {
                value: -2,
                text: 'Display as normal single item (classic preview only)'
              },
              {
                value: -3,
                text: 'Hide assembly from the proposal'
              }
            ]"
          >
          </choose>
        </card-list-field>

        <card-list-field>
          <span>
            Show itemized pricing?
            <br />
            <small class="text-muted">
              If you turn this off, the prices of items inside this assembly will not be shown.
            </small>
          </span>

          <toggle
            @input="(v) => setField('assembly_show_itemized_prices', v)"
            :value="assembly_show_itemized_prices"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section class="sm">
      <card-list>
        <card-list-field>
          <span>
            Minimum quantity
            <br />
            <small class="text-muted">
              Set a minimum qty for this assembly to prevent the area from going below a threshold
              you set.
            </small>
          </span>

          <field
            @input="(v) => setField('assembly_minimum_qty_net', v)"
            :value="object.assembly_minimum_qty_net"
            schema="assembly:assembly_minimum_qty_net"
            placeholder="no minimum"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #label> Interactive upgrades + optional items </template>
      <card-list class="my-2">
        <card-list-field>
          <help class="wide-label">
            <template #before>
              <font-awesome-icon icon="user-minus" class="mr-1" />
              Make this assembly optional?
            </template>
            <template #title>Make this assembly optional</template>

            <p>
              Making this item optional allows your customer to remove it (and its contents) from
              the project before they book it.
            </p>

            <p>
              Allowing your customers to remove items can make the project more affordable for them
              and can increase your booking percentage.
            </p>
          </help>

          <div>
            <checkbox v-model="assembly_is_optional" class="info mr-2" />
          </div>
        </card-list-field>
      </card-list>

      <addon-list
        class="assembly-upgrades"
        :editable="true"
        :reference="reference"
        :key="reference"
        :optionals="false"
        :upgrades="true"
        :dimensions="possDimensions"
        :original="objectLocal"
        :suggestUpgrades="false"
        :store="storeName"
        :value="object.aoAddons"
        @input="(addons) => setField('aoAddons', addons)"
      />
    </card-section>
    <card-section class="sm">
      <template #title> Pricing </template>
      <card-list>
        <card-list-field>
          <span
            >Price
            <small class="text-info"
              >Price your client pays you for all the items inside this assembly.</small
            >
          </span>

          <field v-model="adjustedPrice" format="currency" />
        </card-list-field>
        <card-list-field v-if="showMarkupRelatedVariables">
          <span
            >Cost
            <small class="text-info"
              >Costs you pay for labor/materials to finish all items in this assembly.</small
            >
          </span>

          <field :value="object.quote_total_cost_net_base" disabled format="currency" />
        </card-list-field>
        <card-list-field v-if="showMarkupRelatedVariables">
          <span
            >Profit
            <small class="text-info">Profit for all the items in this assembly</small>
          </span>

          <field v-model="adjustedProfit" format="currency" />
        </card-list-field>
        <card-list-item
          v-if="showMarkupRelatedVariables"
          class="flex justify-center"
          style="position: relative"
        >
          <slider
            style="width: 100%; display: flex; justify-content: center; justify-self: center"
            :disabled="!editable"
            v-model="targetMarkup"
            ref="markupSlider"
            :min="1"
            :max="3"
            :step="0.01"
            :ticks="[bottomMarkup, minMarkup, defaultMarkup, topMarkup]"
            :ticks_positions="[
              0,
              ((minMarkup - bottomMarkup) / (topMarkup - bottomMarkup)) * 100,
              /* (quoteDefault - bottomMarkup) / (topMarkup - bottomMarkup), */
              ((defaultMarkup - bottomMarkup) / (topMarkup - bottomMarkup)) * 100,
              100
            ]"
            :ticks_labels="[
              '',
              'Minimum',
              /* 'Quote default', */
              'Company default',
              ''
            ]"
            :ticks_snap_bounds="0.05"
          />
        </card-list-item>

        <card-list-field v-if="showMarkupRelatedVariables">
          <span>Target profit margin</span>

          <field
            type="text"
            :disabled="!editable"
            class="number"
            format="percentageWhole"
            schema="quote:quote_margin_net"
            @input="(v) => (targetMargin = v)"
            :value="targetMargin"
          />
        </card-list-field>
        <card-list-field v-if="showMarkupRelatedVariables">
          <span>Target markup</span>

          <field
            :disabled="!editable"
            type="text"
            class="number"
            schema="quote:quote_markup_net"
            @input="(v) => (targetMarkup = v)"
            :value="targetMarkup"
          />
        </card-list-field>

        <card-list-item class="danger" v-if="isAdjusted">
          <div class="flex justify-between items-center">
            <span> The markup on this assembly has been adjusted. </span>

            <btn-group>
              <btn
                class="btn square success"
                @click="setField('quote_markup_percentage_adjustment', 0)"
                >Reset to normal markup</btn
              >
            </btn-group>
          </div>
        </card-list-item>
      </card-list>
    </card-section>

    <card-section>
      <cost-chart
        style="max-height: 300px"
        :materials="object.quote_materials_cost_net_base"
        :labor="object.quote_labor_cost_net_base"
        :rate="object.quote_subtotal_net"
        textColor="#222222"
        :qty="object.quote_qty_net_base"
      />
    </card-section>
  </div>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import AssemblyMixin from '@/components/quote/AssemblyMixin'
import MetaList from '../ui/MetaList.vue'
import AddonList from '../quote/addons/AddonList.vue'
import Slider from '../ui/Slider/Slider.vue'
import CostChart from '../quote/CostChart.vue'
import AssemblyDimensions from '../quote/ProjectDimensions/AssemblyDimensions.vue'
import AssemblyDimensionsSummaryMixin from './ProjectDimensions/AssemblyDimensionsSummaryMixin'
import CheckaUserPermsMixin from '../mixins/CheckaUserPermsMixin'

export default {
  name: 'AssemblyOptions',
  mixins: [
    ObjectManipulator('assembly', false),
    BodyMixin,
    AssemblyMixin,
    AssemblyDimensionsSummaryMixin,
    CheckaUserPermsMixin
  ],
  emits: ['close'],
  data() {
    return {
      isOptionsShown: true,
      expanded: true
    }
  },
  watch: {},
  computed: {},
  asyncComputed: {
    possDimensions: {
      async get() {
        return this.$store.dispatch('Dimension/getPossibleDimensions')
      },
      default: () => ({})
    }
  },
  methods: {},
  components: {
    AssemblyDimensions,
    AddonList,
    Slider,
    CostChart,
    MetaList
  },
  props: {
    object: {
      required: true
    },
    deselectOnDestroy: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
