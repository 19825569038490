<template>
  <div class="search-field--container" :class="classes">
    <Field
      :style="noBorder ? 'border: none' : ''"
      v-model="phrase"
      @focusin="focusIn"
      @focusout="focusOut"
      :placeholder="placeholder || ''"
      @enter="enterKeyPress"
      @keyup.esc="escapeKeyPress"
      :emit-delay="emitDelay"
      ref="search"
    />
    <div
      class="search-field--icon absolute"
      :class="large ? `left-2 top-[1em]` : `left-[0.5em] top-[0.35em]`"
    >
      <font-awesome-icon
        icon="magnifying-glass"
        @click="click"
        :class="focused ? '!text-pitch-black' : '!text-cool-gray-700'"
      />
    </div>

    <btn
      rounded
      class="more px-0 p-2 dark search-field--cancel"
      :class="{
        large: large
      }"
      @click="phrase = ''"
      v-if="showCancel && phrase"
    >
      <font-awesome-icon icon="remove" />
    </btn>
  </div>
</template>

<script>
export default {
  name: 'SearchField',
  emits: ['change', 'input', 'focus', 'blur', 'icon', 'escapeKey', 'enterKey'],
  data() {
    return {
      focused: 0
    }
  },
  computed: {
    phrase: {
      get() {
        return this.value
      },
      set(val) {
        console.log('emit change', val)
        this.$emit('change', val)
        this.$emit('input', val)
      }
    },
    classes() {
      return `${c.isempty(this.value) ? '' : 'full notempty'}`
    }
  },
  methods: {
    focusIn() {
      this.$emit('focus')
      this.focused = 1
    },
    focusOut() {
      this.$emit('blur')
      this.focused = 0
    },
    click() {
      this.$emit('icon', event)
      this.$refs.search.blur()
    },
    escapeKeyPress() {
      this.$emit('escapeKey', event)
    },
    enterKeyPress() {
      this.$nextTick(() => this.$emit('enterKey'))
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.search.focus()
      })
    },
    setFocus() {
      return this.focus()
    }
  },

  props: {
    value: {},
    startFocused: {},
    placeholder: {},
    noBorder: {},
    showCancel: {},
    large: {
      type: Boolean,
      default: false
    },
    emitDelay: {
      default: 400
    }
  },
  mounted() {
    if (this.startFocused) {
      this.focus()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
%field {
  height: 1.68em;
  border-radius: 2em;
  padding: 0.25em 1.3em;
  font-size: 1.2em;
  outline: none;
  transition: all 250ms ease-out;
  color: transparent;
  float: right;
  max-width: 25em;
  width: 100%;
  border: 1px solid $cool-gray-500;
  background: transparent;
  box-shadow: none;
  -webkit-appearance: none;

  font-weight: normal;

  &:focus {
    width: 100%;
    color: $pitch-black;
    border-color: $cool-gray-800;
    background: $flame-white;
  }
}

input[type='text'].search-input,
div[contenteditable].search-input {
  @extend %field;
}

.search-field--container {
  text-align: right;
  position: relative;

  input[type='text'] {
    @extend %field;
    &::placeholder {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $cool-gray-300;
      font-size: 0.8em;
      line-height: 1;
    }
  }

  .search-field--icon {
    color: $cool-gray-300;
  }

  .search-field--cancel {
    position: absolute;
    top: 0.3em;
    right: 0.5em;
    .glyphicon {
      color: $flame-white !important;
    }
    font-size: 0.75em;

    &.large {
      top: 1.2em;
      left: 10em;
    }
  }

  &.full {
    input[type='text'] {
      width: 100%;
      color: $cool-gray-800;
      max-width: unset !important;
    }
  }

  &.dark-grey {
    input[type='text'] {
      border: 1px solid $cool-gray-600;
      &:focus {
        color: $cool-gray-700;
      }
    }
    .glyphicon {
      color: $cool-gray-600;
    }
  }

  &.left {
    text-align: left;

    input[type='text'] {
      float: left;
      padding: 0.25em 0.5em 0.25em 1.5em;
    }

    .glyphicon {
      width: 2em;
      left: 0px;
    }

    .search-field--cancel {
      position: absolute;
      right: 0.2em;
      left: unset;

      &.large {
        right: 1em;
      }
    }
  }

  &.center {
    text-align: center;

    input[type='text'] {
      float: none;
      padding: 0.25em 0.5em 0.25em 1.5em;
    }

    .glyphicon {
      width: 2em;
      left: 0px;
    }

    .search-field--cancel {
      position: absolute;
      top: 0.3em;
      right: 0.2em;
      left: unset;
    }
  }

  &.notempty {
    input[type='text'] {
      border: 1px solid $cement-700;
      color: $pitch-black;
      background: $cement-400;
      &:focus {
        color: $cement-900;
      }
    }
    .glyphicon {
      color: $cement-900;
    }
  }
}
</style>
