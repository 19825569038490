<template>
  <div class="wizard">
    <Steps v-model:activeStep="active" :model="steps" class="hidden md:block" />
    <slot></slot>
    <div class="flex flex-row-reverse justify-between mt-8">
      <div
        v-if="currentStep.skip"
        @click="currentStep.skip"
        class="flex justify-center items-center transition cursor-pointer text-blue-print-700 hover:text-blue-print-300"
      >
        <p>Skip</p>
      </div>
      <FormButton
        v-if="!currentStep.skip"
        ref="nextButton"
        @click="goToNext"
        class="max-w-48"
        rightArrow
        showLoading
        :text="isLastStep ? 'Finish' : 'Next'"
      >
      </FormButton>
      <FormButton
        v-if="hasPrevious && !currentStep.hidePrevious"
        @click="goToPrev"
        class="max-w-48"
        leftArrow
        muted
        text="Previous"
      >
      </FormButton>
    </div>
  </div>
</template>

<script setup>
import FormButton from '@/components/ui/FormButton.vue'
import Steps from 'primevue/steps'
import { ref, provide, computed } from 'vue'

const props = defineProps(['steps', 'skip', 'startingStep'])
const { steps, startingStep } = props
const startingStepDefault = startingStep || 0
const active = ref(startingStepDefault)
const counter = ref(0)
const nextButton = ref()

provide('active', active)
provide('counter', counter)

const currentStep = computed(() => {
  return steps[active.value]
})

const isLastStep = computed(() => {
  return active.value === counter.value - 1
})

const hasPrevious = computed(() => {
  return active.value > 0
})

const goToPrev = () => {
  if (active.value === 0) return
  active.value--
}

const goToNext = async () => {
  let passed = true
  if (currentStep.value.action) passed = await currentStep.value.action(active.value)
  nextButton.value.loading = false
  if (!passed) return
  active.value++
}
</script>
