<template>
  <preview
    class="cost-type-preview"
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <font-awesome-icon
        :icon="['fas', 'cube']"
        class="circled"
        v-if="!picture && !object.cost_type_is_parent && object.type === 'cost_type'"
      />
      <font-awesome-icon
        :icon="['fas', 'cubes']"
        class="circled"
        v-else-if="!picture && !object.cost_type_is_parent"
      />
      <img v-if="!object.cost_type_is_parent && picture" :src="picture" class="item--picture" />
    </template>
    <template #primary>
      <SuggestionButton v-tooltip="title || 'Unnamed'" class="tight" @click="edit()">
        {{ $f.truncate(title || 'Unnamed', 30) }}
      </SuggestionButton>
      <SuggestionButton
        @click="() => $store.dispatch('copyToClipboard', object.cost_type_id || object.assembly_id)"
        class="sm tight muted"
      >
        {{ object.cost_type_id || object.assembly_id }}
      </SuggestionButton>
    </template>
    <template #secondary>
      <div>
        <div class="flex justify-start items-center">
          <font-awesome-icon
            v-if="!object.company_id"
            icon="globe"
            v-tooltip="'This is a public/global item.'"
            class="circled danger mr-1"
          />

          <font-awesome-icon
            v-if="object.cost_type_is_indexed"
            icon="nearby"
            v-tooltip="
              'This item is indexed to local cost factors based on postal/zip code (north america only).'
            "
            class="circled mr-1"
          />

          <font-awesome-icon
            v-if="object.cost_type_has_labor || object.quote_labor_cost_net > 0.01"
            icon="user-helmet-safety"
            v-tooltip="laborMessage"
            class="circled mr-1"
          />

          <font-awesome-icon
            v-if="object.cost_type_has_labor || object.quote_materials_cost_net > 0.01"
            icon="box-taped"
            v-tooltip="materialsMessage"
            class="circled mr-1"
          />

          <font-awesome-icon
            v-if="object.variation_parent_cost_type_id || object.cost_type_is_variation_parent"
            v-tooltip="'This is a variation/selection item, part of a group of variations.'"
            icon="check"
            class="circled mr-1"
          />

          <font-awesome-icon
            v-if="object.cost_type_is_fee"
            v-tooltip="feeMessage"
            icon="percentage"
            class="circled mr-1"
          />

          <SvgIcon v-if="object.live_price_reference" v-tooltip="'AutoCost item'" svg="autocost" />

          <Choose
            v-if="
              $store.state.session.user &&
              $store.state.session.user.user_is_super_user &&
              !$store.state.session.scope.company
            "
            :return-array="false"
            :static-set="[
              {
                text: 'Draft',
                value: 'y'
              },
              {
                text: 'Published',
                value: '@'
              }
            ]"
            v-model="draft"
          >
            <template #default="{ text }">
              <SuggestionButton
                :stop="false"
                :prevent="false"
                :class="{
                  'mr-1': true,
                  sm: true,
                  danger: text !== 'Published',
                  info: text === 'Published'
                }"
              >
                {{ text.trim() || 'Draft' }}
              </SuggestionButton>
            </template>
          </Choose>

          <FoundInAssemblies
            :type="object.type"
            :id="object.cost_type_id || object.assembly_id"
            @click="edit()"
          />

          <SuggestionButton
            class="sm info"
            style="white-space: nowrap; flex-direction: row"
            v-if="
              object.company_id &&
              String(object.company_id) !==
                String($store.state.session.company && $store.state.session.company.company_id)
            "
          >
            <font-awesome-icon icon="shop" />
            {{ $f.truncate(object.company_name || `Company id: ${object.company_id}`, 20) }}
          </SuggestionButton>
        </div>
      </div>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import SuggestionButton from '../SuggestionButton.vue'
import FoundInAssemblies from '../../quote/item/FoundInAssemblies.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewCostType',
  emits: ['click', 'reload'],
  data() {
    return {
      draftLast: null
    }
  },
  watch: {},
  computed: {
    laborMessage() {
      const hours = this.object.cost_type_hours_per_unit || this.object.quote_total_hours
      const cost = this.object.cost_matrix_labor_cost_net || this.object.quote_labor_cost_net
      const formattedHours = c.format(hours, 'hours')
      const formattedCost = c.format(cost, 'currency')
      const unit = this.object.unit_of_measure_abbr || 'each'
      return `This has a labor cost of ${formattedCost} / ${unit} ${
        hours > 0.000001 ? `and requires ${formattedHours} to complete 1 ${unit}.` : ''
      }`
    },
    materialsMessage() {
      return `This has a materials cost of ${c.format(
        this.object.cost_matrix_materials_cost_net || this.object.quote_materials_cost_net,
        'currency'
      )} / ${this.object.unit_of_measure_abbr || 'each'}.`
    },
    feeMessage() {
      return `This is a fee item calcualted by
        ${this.object.cost_type_qty_equation}.`
    },
    draft: {
      get() {
        return this.draftLast || this.object.cost_type_status || this.object.assembly_status || 'y'
      },
      set(status) {
        if (status === this.draftLast) return
        this.draftLast = status

        const type = this.object.type
        const store = c.titleCase(type)
        const idField = `${type}_id`
        const statusField = `${type}_status`

        this.$store.dispatch(`${store}/partialUpdate`, {
          selected: [
            {
              type,
              [idField]: this.object[idField],
              [statusField]: status
            }
          ],
          go: false,
          alert: false
        })
        this.$emit('reload')
      }
    },
    title() {
      return this.object.cost_type_name || this.object.assembly_name
    },
    ownPicture() {
      const img = c.makeArray(this.object.file_ids || [])

      if (img.length) {
        return c.link(`/file/thumb/${img[0]}`)
      }

      return null
    },

    manufacturersPicture() {
      const manufacturerImages = c.makeArray(this.object.aoImages || [])
      if (manufacturerImages.length) {
        return manufacturerImages[0].file_url
      }

      return null
    },

    picture() {
      return this.ownPicture || this.manufacturersPicture || null
    }
  },
  methods: {},
  components: {
    FoundInAssemblies,
    SuggestionButton,
    Preview
  },
  props: {
    object: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.cost-type-preview {
  .info {
    overflow: visible !important;
  }
  .item--picture {
    max-width: 4em;
    max-height: 4em;
    border-radius: 0.15em;
  }
}
</style>
