<template>
  <tr>
    <td class="live-price-changes-col">
      <span>{{ costItemName }}</span>
    </td>
    <td v-if="deviceWidth > 500" class="live-price-changes-col">
      {{ newHoursPerUnit.toFixed(3) }}
      <div v-if="diffHoursPerUnit > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        {{ diffHoursPerUnit.toFixed(3) }}
      </div>
      <div v-if="diffHoursPerUnit < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        {{ diffHoursPerUnit.toFixed(3) }}
      </div>
    </td>
    <td v-if="deviceWidth > 850" class="live-price-changes-col">
      ${{ $f.currency(newLaborRate) }}
      <div v-if="diffLaborRate > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        ${{ $f.currency(diffLaborRate) }}
      </div>
      <div v-if="diffLaborRate < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        ${{ $f.currency(diffLaborRate) }}
      </div>
    </td>
    <td class="live-price-changes-col">
      ${{ $f.currency(newLaborCost) }}
      <div v-if="diffLaborCost > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        ${{ $f.currency(diffLaborCost) }}
      </div>
      <div v-if="diffLaborCost < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        ${{ $f.currency(diffLaborCost) }}
      </div>
    </td>
    <td class="live-price-changes-col">
      <div>${{ $f.currency(newMaterialCost) }}</div>
      <div v-if="diffMaterialCost > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        ${{ $f.currency(diffMaterialCost) }}
      </div>
      <div v-if="diffMaterialCost < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        ${{ $f.currency(diffMaterialCost) }}
      </div>
    </td>
    <td v-if="deviceWidth > 925" class="live-price-changes-col">
      ${{ $f.currency(newCombinedCost) }}
      <div v-if="diffCombinedCost > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        ${{ $f.currency(diffCombinedCost) }}
      </div>
      <div v-if="diffCombinedCost < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        ${{ $f.currency(diffCombinedCost) }}
      </div>
    </td>
    <td class="live-price-changes-col">
      {{ ((1 - 1 / newMarkup) * 100).toFixed(2) }}%
      <div v-if="diffMarkup > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        {{ ((1 - 1 / diffMarkup) * 100).toFixed(2) }}%
      </div>
      <div v-if="diffMarkup < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        {{ ((1 - 1 / diffMarkup) * 100).toFixed(2) }}%
      </div>
    </td>
    <td class="live-price-changes-col">
      ${{ $f.currency(newPrice) }}
      <div v-if="diffPrice > 0.0049" class="positive-change">
        <font-awesome-icon icon="chevron-up" />
        ${{ $f.currency(diffPrice) }}
      </div>
      <div v-if="diffPrice < -0.0049" class="negative-change">
        <font-awesome-icon icon="chevron-down" />
        ${{ $f.currency(diffPrice) }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'LivePriceChange',
  mounted() {},
  data() {
    return {}
  },
  computed: {
    deviceWidth() {
      return this.$store.state.session.deviceWidth
    },

    costItemName() {
      return this.norm.cost_type_name
    },
    // Labor rate
    oldLaborRate() {
      return this.norm.labor_type_rate_net
    },
    newLaborRate() {
      if (this.change.labor_type_rate_net) return Number(this.change.labor_type_rate_net)
      return this.norm.labor_type_rate_net
    },
    diffLaborRate() {
      return this.newLaborRate - this.oldLaborRate
    },

    // Material cost
    oldMaterialCost() {
      return this.norm.cost_matrix_materials_cost_net
    },
    newMaterialCost() {
      if (this.change.cost_matrix_materials_cost_net) {
        return Number(this.change.cost_matrix_materials_cost_net)
      }
      return this.norm.cost_matrix_materials_cost_net
    },
    diffMaterialCost() {
      return this.newMaterialCost - this.oldMaterialCost
    },

    // hours per unit
    oldHoursPerUnit() {
      return this.norm.cost_type_hours_per_unit
    },
    newHoursPerUnit() {
      if (this.change.cost_type_hours_per_unit) return Number(this.change.cost_type_hours_per_unit)
      return this.norm.cost_type_hours_per_unit
    },
    diffHoursPerUnit() {
      return this.newHoursPerUnit - this.oldHoursPerUnit
    },

    // labor details
    hasLabor() {
      return this.norm.cost_type_has_labor && this.norm.labor_type_rate_net
    },

    // labor cost
    oldLaborCost() {
      return this.norm.cost_matrix_labor_cost_net
    },
    newLaborCost() {
      if (this.hasLabor) {
        return this.newHoursPerUnit * this.newLaborRate
      }
      return 0
    },
    diffLaborCost() {
      return this.newLaborCost - this.oldLaborCost
    },

    // combined cost
    oldCombinedCost() {
      return this.oldLaborCost + this.oldMaterialCost
    },
    newCombinedCost() {
      return this.newLaborCost + this.newMaterialCost
    },
    diffCombinedCost() {
      return this.newCombinedCost - this.oldCombinedCost
    },

    // markup
    oldMarkup() {
      return this.norm.cost_matrix_markup_net
    },
    newMarkup() {
      if (this.livePriceUpdate === 'markup') {
        return this.oldPrice / (this.newMaterialCost + this.newLaborCost)
      }
      return this.oldMarkup
    },
    diffMarkup() {
      return this.newMarkup - this.oldMarkup
    },

    // price
    oldPrice() {
      return this.norm.cost_matrix_rate_net
    },
    newPrice() {
      if (this.livePriceUpdate === 'price') {
        return (this.newMaterialCost + this.newLaborCost) * this.oldMarkup
      }

      return this.oldPrice
    },
    diffPrice() {
      return this.newPrice - this.oldPrice
    }
  },
  methods: {},
  props: {
    change: {
      type: Object
    },
    norm: {
      type: Object
    },
    livePriceUpdate: {
      type: String,
      default: 'price'
    }
  },
  components: {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.live-price-changes-col {
  overflow: hidden;
  position: relative;
  &:first-child {
    max-width: 15em;
    text-align: start;
  }
  padding: 0.5em 1em;
  height: 5em;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 0.9em;
    padding: 0.5em 0.5em;
  }

  .positive-change {
    position: absolute;
    top: 8px;
    right: 20%;
    color: green;
    font-size: 0.8em;
  }

  .negative-change {
    position: absolute;
    top: 8px;
    right: 20%;
    color: red;
    font-size: 0.8em;
  }
}
</style>
