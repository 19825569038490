// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'form',

  skipAudit: true,

  fields: {
    form_id: { type: 'int' },
    form_name: { type: 'string', required: true, save: true, filter: true },
    form_status: { type: 'string' },
    form_time_created: { type: 'int', format: 'datetime', filter: true },
    form_time_last_modified: { type: 'int', format: 'datetime' },
    form_creator: { type: 'string', mapTo: 'user' },
    form_template_id: { type: 'string', mapTo: 'user' },
    company_id: { type: 'string', mapTo: 'company' },
    form_object_type: { type: 'string' },
    form_object_id: { type: 'string' },
    oData: { type: 'object', mapTo: false },
    aoFieldOptions: { type: 'array', save: true, mapTo: 'custom_field_option' },
    aoFields: { type: 'array', save: true, mapTo: 'custom_field' },
    aoFormFields: { type: 'array' }
  },

  generateVueActions() {
    return {
      goToCreatePage({ dispatch }) {
        dispatch('to', 'create/form', { root: true })
        dispatch('modal/closeAll', { confirm: true }, { root: true })
      }
    }
  }
}
