import _ from '../../../../imports/api/Helpers'
import CostType from '../../../../imports/api/schemas/CostType'
import eventBus from '../../../eventBus'

const costTypeDeps = CostType.getComputedDependants()

export default {
  props: {
    editable: {
      default: false
    },
    /**
     * Addon object
     * {
     *  // object type, assembly or cost_type
     *  type: 'assembly',
     *
     *  // item id
     *  id: '1234',
     *
     *  // replace or option
     *  // replace replaces a different item
     *  // option adds to parent
     *  // for cost_items/_types, it is always
     *  // a replace!
     *  addonType: 'replace',
     *
     *  // sibling or anywhere
     *  // for sibling, this option will show
     *  // if this item does not exist in
     *  // the immediate siblings, or for an assembly
     *  // also within its children
     *  // For a 'anywhere' option, it cannot exist anywhere
     *  // in the active quote tree.
     *  preventIf: 'sibling',
     * }
     */
    addon: {
      type: Object,
      default: {}
    },
    /**
     * Only required when it is an option/replace,
     * we need to know the existing price to offer
     * a difference
     */
    currentPrice: {
      type: Number,
      default: 0
    },
    /**
     * Item to get replaced, if addonType === 'replace'
     * or to be added INTO if addonType === 'option'
     */
    targetRefId: {
      required: true
    },
    dimensions: {
      type: Object,
      required: true
    },
    store: {
      default: 'Quote'
    }
  },

  created() {
    this.fetch()
  },

  data() {
    return {
      loading: 1,
      object: {},
      auditedObject: false,
      disabled: false
    }
  },

  watch: {
    auditedObject(obj) {
      let used = []
      if (obj.type !== 'assembly') {
        used =
          obj.asDimensionsLinked && obj.asDimensionsLinked.length
            ? c.makeArray(obj.asDimensionsLinked || [])
            : []
      } else {
        // asDimensionsUsed is all dimensions, asRequiredDimensions are dimensions that are
        // set speficially inside the assembly, so used - required = dimensions
        // that we are expecting to come from
        used = _.uniq([...obj.asDimensionsUsed, ...obj.asRequiredDimensions])
      }

      this.$emit('addon', {
        ...this.addon,
        file_ids: obj.file_ids || [],
        file_id: (obj.file_ids && obj.file_ids.length && obj.file_ids[0]) || null,
        asDimensionsRequired: used
      })
    },
    addon(a, b) {
      // if (JSON.stringify(a) !== JSON.stringify(b)) {
      //   console.log('a');
      //   console.log(JSON.stringify(a));
      //   console.log('b ');
      //   console.log(JSON.stringify(b));
      //   this.$emit('addon', a);
      // }
      if (a.file_id !== b.file_id) {
        this.object.file_id = a.file_id
        // this.savePic();
      }

      if (!_.jsonEquals(a, b)) {
        this.setObject(this.object)
      }
    }
  },

  computed: {
    target() {
      return this.$store.state[this.store].normalized[this.targetRefId]
    },

    targetUnitPrice() {
      if (this.target.type === 'assembly') return this.target.quote_subtotal_net
      return this.target.cost_matrix_rate_net
    },

    /**
     * Localized
     * @returns {*}
     */
    targetQuantity() {
      const type = this.target.type
      let field

      if (type === 'assembly') {
        field = 'quote_qty_net_base'
      } else {
        field = 'cost_item_qty_net_base'
      }

      const qty = this.target[field]
      const from = this.target.unit_of_measure_id
      const conv = c.convertMeasures(qty, from, this.uomIdUsed)

      return conv
    },
    targetMeasure() {
      const target = this.target
      const prevUom = String(target.unit_of_measure_id || 'count')
      const targetMt = c.getMeasureTypeForUnitOfMeasure(prevUom)
      return targetMt
    },
    objectMeasure() {
      const object = this.auditedObject
      const currUom = String(object.unit_of_measure_id || 'count')
      const objectMt = c.getMeasureTypeForUnitOfMeasure(currUom)
      return objectMt
    },
    compatibleMeasures() {
      return this.objectMeasure === this.targetMeasure
    },
    overrideQty: {
      get() {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'

        return this.auditedObject[field]
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'
        this.setEmbueValue(field, v)
      }
    },
    overrideEquation: {
      get() {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_qty_equation' : 'cost_type_qty_equation'

        return this.auditedObject[field]
      },
      async set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_qty_equation' : 'cost_type_qty_equation'
        if (v) this.overrideQty = null
        await c.throttle(() => {})
        this.setEmbueValue(field, v)
      }
    },
    overrideName: {
      get() {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_name' : 'cost_type_name'

        return this.auditedObject[field]
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'assembly_name' : 'cost_type_name'
        this.setEmbueValue(field, v)
      }
    },
    overrideDesc: {
      get() {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_notes' : 'cost_type_desc'

        return this.auditedObject[field]
      },
      set(v) {
        const type = this.object.type
        const field = type === 'assembly' ? 'quote_notes' : 'cost_type_desc'
        this.setEmbueValue(field, v)
      }
    },
    overrideMarkup: {
      get() {
        const type = this.object.type
        if (type === 'assembly') {
          return c.divide(
            this.auditedObject.quote_subtotal_net,
            this.auditedObject.quote_total_cost_net_base
          )
        }
        return this.auditedObject.cost_matrix_markup_net
      },
      set(v) {
        this.setOverrideMarkup(v)
      }
    },
    hasOverridenMarkup() {
      const type = this.object.type

      if (type === 'assembly') {
        return 'assembly_markup_percentage_adjustment' in this.addon.embue
      }

      return 'cost_matrix_markup_net' in this.addon.embue
    },
    dimensionsAvailable() {
      const assemblyToAssembly = this.addon.type === 'assembly' && this.target.type === 'assembly'

      return {
        ...this.parentDimensions,
        ...(assemblyToAssembly
          ? {
              ...this.target.oDimensions
            }
          : {})
      }
    },
    missingDimension() {
      if (!this.dimensionsRequired.length) return false

      return !!this.dimensionsRequired.filter(
        (dim) => !this.dimensionsAvailable[dim.abbr] || !this.dimensionsAvailable[dim.abbr].value
      ).length
    },
    parent() {
      const store = this.store || this.storeName
      const state = store && this.$store.state[store]
      const norm = state && state.normalized
      const obj = norm && norm[this.targetRefId]
      const parentRef = obj.parentRefId
      const parent = norm && norm[parentRef]

      return parent
    },
    parentDimensions() {
      const dims = this.parent && this.parent.oDimensions

      return dims || {}
    },
    parentName() {
      return (
        (this.parent && (this.parent.assembly_name || this.parent.quote_name)) ||
        'the parent/container assembly'
      )
    },
    dimensionsRequired() {
      const abbrs = _.makeArray(this.addon.asDimensionsRequired)
      return abbrs.filter((abbr) => abbr in this.dimensions).map((abbr) => this.dimensions[abbr])
    },
    qtyUsed() {
      const obj = this.auditedObject
      if (obj.type === 'assembly') return obj.quote_qty_net_base
      return obj.cost_item_qty_net_base
    },
    uomUsed() {
      const obj = this.auditedObject
      if (obj.type === 'assembly') return 'each'
      return obj.unit_of_measure_abbr
    },
    uomIdUsed() {
      const obj = this.auditedObject
      if (obj.type === 'assembly') return 'count'
      return obj.unit_of_measure_id
    },
    currentIndex() {
      const current = this.target
      const type = current.type === 'assembly' ? 'assembly' : 'cost_type'
      const currentIndex = `${type}:${current[`${type}_id`]}`
      return currentIndex
    },
    addonIndex() {
      let id
      let type

      if (this.addon.bulk) {
        type = this.addon.bulk.type === 'assembly' ? 'assembly' : 'cost_type'
        id = this.addon.bulk[`${type}_id`]
      } else {
        type = this.addon.type
        id = this.addon.id
      }
      const addonIndex = `${type}:${id}`
      return addonIndex
    },
    isCurrent() {
      const addonIndex = this.addonIndex
      const currentIndex = this.currentIndex

      return addonIndex === currentIndex
    },
    fileIds() {
      const ids = c.makeArray(this.object.file_ids)
      return ids.length
        ? ids
        : [
            ...(this.addon.file_id ? [this.addon.file_id] : []),
            ...(this.object.file_id ? [this.object.file_id] : [])
          ]
    },
    picFile() {
      return this.fileIds.length ? [c.buildDefaultObject('file', { file_id: this.fileIds[0] })] : []
    },
    pic() {
      if (this.picFile.length && this.picFile[0].file_id) {
        const url = `file/view/${this.picFile[0].file_id}`
        return c.link(url)
      }
      const tagType = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      const url = `${tagType}/pic/${this.object[`${tagType}_id`]}`
      return c.link(url)
    },
    uploadTags() {
      const tagType = this.object.type === 'assembly' ? 'assembly' : 'cost_type'
      return {
        [`${tagType}_id`]: this.object[`${tagType}_id`]
      }
    },
    desc() {
      return c.removeHtml(this.auditedObject.quote_notes || this.auditedObject.cost_type_desc)
    },
    name() {
      return (
        this.auditedObject.assembly_name || this.auditedObject.cost_type_name || this.addon.name
      )
    },
    priceDifference() {
      const priceField = /quote|assembly/.test(this.addon.type)
        ? 'quote_subtotal_net'
        : 'cost_item_price_net_base'

      const auditPrice = this.auditedObject ? this.auditedObject[priceField] : 0

      if (this.addon.addonType === 'replace') {
        return auditPrice - this.currentPrice
      }

      return auditPrice
    },
    priceDifferenceUnit() {
      const priceField = /quote|assembly/.test(this.addon.type)
        ? 'quote_subtotal_net'
        : 'cost_matrix_rate_net'

      const auditPrice = this.auditedObject ? this.auditedObject[priceField] : 0

      if (this.addon.addonType === 'replace') {
        return auditPrice - this.targetUnitPrice
      }

      return auditPrice
    }
  },

  methods: {
    async resetQty() {
      this.overrideEquation = null
      await c.throttle(() => {})
      this.overrideQty = null
    },
    async setObject(object) {
      const objType = object.type === 'assembly' ? 'assembly' : 'cost_item'

      const { object: quantizedObject } = await this.$store.dispatch(
        `${c.titleCase(objType)}/buildDefaultObject`,
        {
          type: objType,
          embue: object
        }
      )

      // Fix qty equation for cost types created before the dimension update
      if (objType === 'cost_item') {
        quantizedObject.cost_type_qty_equation =
          costTypeDeps.cost_type_qty_equation(quantizedObject)
      }

      this.object = quantizedObject

      await this.audit()

      return this.auditedObject
    },

    /**
     * Get the quantity for the object provided,
     * using the target (parent or replaced item) as a guide
     * @param object
     * @param target
     * @returns { qty: *, linkable: 0|1 };
     */
    getQuantity(object, target) {
      if (!target) {
        return {
          qty: 1,
          linkable: 0
        }
      }
      const currentType = object.type
      const prevType = target.type

      const currUom = String(object.unit_of_measure_id || 'count')
      const prevUom = String(target.unit_of_measure_id || 'count')

      const prevQtyPrefix = prevType === 'assembly' ? 'quote' : 'cost_item'
      const targetQtyField = `${prevQtyPrefix}_qty_net_base`
      const prevQty = target[targetQtyField]

      const currLinkable = c.isUnitOfMeasureLinkable(currUom)
      const prevLinkable = c.isUnitOfMeasureLinkable(prevUom)
      const prevLinked = target.cost_item_link_qty ? 1 : 0

      const isReplacement = this.addon.addonType !== 'option'

      let qty = 1
      let linkable = 0

      // const prevEach = prevUom === '2';
      // const currEach = currUom === '2';
      const isCostItem = currentType === 'cost_item' || currentType === 'cost_type'
      const sameUnit = currUom === prevUom

      qty = sameUnit && isReplacement ? prevQty : 1

      linkable = isCostItem && currLinkable && (prevLinked || !prevLinkable) ? 1 : 0

      const equationField =
        prevType === 'cost_item' || prevType === 'cost_type'
          ? 'cost_type_qty_equation'
          : 'assembly_qty_equation'

      const targetMt = c.getMeasureTypeForUnitOfMeasure(prevUom)
      const objectMt = c.getMeasureTypeForUnitOfMeasure(currUom)
      const compatibleMeasures = objectMt === targetMt

      let equation = null
      // get overridden equation

      const field = !isCostItem ? 'assembly_qty_equation' : 'cost_type_qty_equation'
      const overrideEq = this.addon.embue[field]
      if (overrideEq) {
        equation = overrideEq
      } else if (!compatibleMeasures && object[equationField]) {
        equation = object[equationField]
      } else if (compatibleMeasures && isCostItem && target[equationField]) {
        equation = target[equationField]
      }

      // No equation found, convert the qty directly
      if (!equation && compatibleMeasures) {
        // requires conversion
        const amt = target[targetQtyField]
        const from = prevUom
        const to = currUom
        const conv = c.convertMeasure(amt, from, to)
        qty = conv === false ? amt : conv
      }

      return {
        qty,
        linkable,
        equation
      }
    },

    savePic() {
      if (!this.addon.id) return Promise.resolve()
      return this.$store.dispatch(`${c.titleCase(this.addon.type)}/partialUpdate`, {
        alert: false,
        go: false,
        selected: [
          {
            type: this.addon.type,
            [`${this.addon.type}_id`]: this.addon.id,
            file_id: this.addon.file_id
          }
        ]
      })
    },
    fileAdded(files = []) {
      if (Array.isArray(files) && files.length && files[0] && files[0].file_id) {
        this.$emit('addon', { ...this.addon, file_ids: files[0].file_id })
      } else {
        this.$emit('addon', { ...this.addon, file_ids: null })
      }
    },
    setEmbueValue(key, value) {
      if (value === null) {
        const { [key]: omit, ...rest } = this.addon.embue
        this.$emit('addon', { ...this.addon, embue: rest })
        return
      }

      this.$emit('addon', {
        ...this.addon,
        embue: {
          ...this.addon.embue,
          [key]: value
        }
      })
    },
    async setOverrideMarkup(overrideMarkup) {
      const type = this.object.type
      const unsetting = overrideMarkup === false || overrideMarkup === null

      if (type === 'assembly' && !unsetting) {
        const { changes } = await this.$store.dispatch('Quote/setTargetMarkup', {
          object: this.auditedObject,
          targetMarkup: overrideMarkup
        })

        return this.setEmbue({
          ...this.addon.embue,
          ...changes
        })
      } else if (type === 'assembly' && unsetting) {
        const { assembly_markup_percentage_adjustment: omit, ...rest } = this.addon.embue
        return this.setEmbue(rest)
      } else if (unsetting) {
        const { cost_matrix_markup_net: omit, ...rest } = this.addon.embue
        return this.setEmbue(rest)
      }

      return this.setEmbue({
        ...this.addon.embue,
        cost_matrix_markup_net: overrideMarkup
      })
    },
    setEmbue(embue = {}) {
      this.$emit('addon', { ...this.addon, embue })
    },
    addFiles(files) {
      this.$store.dispatch('CostType/addChildren', {
        refId: this.refId,
        children: files,
        field: 'aoFiles'
      })
      eventBus.$emit('changing', {
        refId: this.refId
      })
    },
    get() {
      this.loading = 1
      this.$nextTick(() => {
        this.$emit('get', this.auditedObject, this.addon)
      })
      setTimeout(() => {
        this.loading = 0
      }, 4000)
    },

    async audit() {
      const objType = this.object.type === 'assembly' ? 'assembly' : 'cost_item'
      const currentObj = this.target
      const addonQtyField = objType === 'assembly' ? 'quote_qty_net_base' : 'cost_item_qty_net_base'
      const addonEquationField =
        objType === 'assembly' ? 'quote_qty_equation' : 'cost_type_qty_equation'

      const obj = _.imm(this.object)

      const { qty, equation } = this.getQuantity(obj, currentObj, this.addon)

      obj[addonQtyField] = qty
      obj[addonEquationField] = equation

      const quantizedObject = {
        ...obj,
        ..._.imm(this.addon.embue)
      }

      // If this addon is an assembly, and it replaces an assembly,
      // inherit all of that assembly's dimensions
      let dimensions = {}
      if (
        /quote|assembly/.test(currentObj.type) &&
        objType === 'assembly' &&
        this.addon.addonType === 'replace'
      ) {
        dimensions = { oDimensions: currentObj.oDimensions }
      }

      const object = {
        ...quantizedObject,
        ...dimensions
      }

      // maintain markup adjustments for assemblies //
      const changes = {}
      if (objType === 'assembly') {
        const targetMarkup = this.addon.markup || obj.quote_markup_net
        const targetQuoteMarkupAdjustment = 0
        object.quote_markup_percentage_adjustment = targetQuoteMarkupAdjustment
        object.quote_markup_net = targetMarkup
        changes[this.targetRefId] = {
          quote_markup_percentage_adjustment: targetQuoteMarkupAdjustment
        }
      } else {
        // adjustments, if necessary //
        object.cost_item_markup_net_adjusted =
          object.cost_item_markup_net_adjusted || object.cost_matrix_markup_net
      }

      // Audit first to capture parent data
      const { object: initial } = await this.$store.dispatch(`${this.store}/auditInPlace`, {
        object,
        targetRefId: this.targetRefId,
        insertType: this.addon.addonType,
        changes
      })

      this.auditedObject = initial
    },

    async reload() {
      return this.fetch(true)
    },

    async fetch(force = false) {
      this.loading = 1

      try {
        let object = null

        if (this.addon.bulk) {
          // prefer bulk
          object = this.addon.bulk
        } else {
          const { object: fetched } = await this.$store.dispatch('Quote/getAddon', {
            type: this.addon.type === 'cost_item' ? 'cost_type' : this.addon.type,
            id: this.addon.id,
            force
          })
          object = fetched
        }

        if (object) {
          await this.setObject(object)
          return object
        }

        this.disabled = true
        return false
      } catch (e) {
        this.disabled = true
        this.$emit('disabled')

        throw e
      } finally {
        this.loading = 0
      }
    }
  }
}
