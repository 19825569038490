<template>
  <div :class="{ invalid: markInvalid }" class="date-picker--container">
    <Calendar
      v-bind="$props"
      @date-select="(val) => $emit('date-select', val)"
      @blur="() => $emit('blur')"
      @hide="() => $emit('hide')"
      :showIcon="showIcon"
      :disabledDays="disabledDays"
      :manualInput="manualInput"
      :dateFormat="dateFormat"
      :inputId="inputId"
      :pt="pt"
      :ptOptions="{ mergeOptions: true }"
      iconDisplay="input"
      v-model="datetime"
      :placeholder="placeholder"
      ref="picker"
    >
    </Calendar>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar'

Calendar.compatConfig = { MODE: 3 }

export default {
  name: 'DatePicker',
  emits: ['input', 'formatted', 'date-select', 'blur', 'hide'],
  data() {
    return {
      markInvalid: false,
      inputId: Math.floor(Math.random() * 1000)
    }
  },
  computed: {
    datetime: {
      get() {
        return this.value ? new Date(this.value) : null
      },
      set(datetime) {
        this.$emit('input', datetime.getTime())
        this.$emit('formatted', c.format(datetime.getTime() || null, this.type))
        this.markInvalid = false
      }
    }
  },
  watch: {
    errors() {
      if (this.name && this.errors.includes(this.name)) {
        this.markInvalid = true
        return
      }
      this.markInvalid = false
    }
  },
  methods: {
    clickHandler() {
      $(this.$el).find('.el-input__inner')[0].focus()
    },
    show() {
      this.open()
    },
    hide() {
      this.close()
    },
    open() {
      this.$refs.picker.showPicker()
    },
    close() {
      this.$refs.picker.hidePicker()
    }
  },
  props: {
    name: {
      type: String
    },
    errors: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    editable: {
      type: Boolean,
      default: () => true
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    size: {
      type: String,
      default: () => 'lg'
    },
    manualInput: {
      type: Boolean,
      default: () => true
    },
    dateFormat: {
      type: String,
      default: () => 'mm/dd/yy'
    },
    placeholder: {
      type: String,
      default: () => 'Choose a time'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    pt: {
      type: Object,
      default: () => {}
    },
    /**
     * year/month/date/datetime/week/datetimerange/daterange
     */
    type: {
      type: String,
      default: () => 'date'
    },
    /**
     * year yyyy month MM day dd, hour HH, minute mm, second ss
     */
    format: {
      type: String,
      default: () => 'dd MMM yyyy'
    },
    /**
     * show time or just date
     */
    showTime: {
      default: false
    },
    disabledDays: {
      type: Array
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    /**
     * DisabledDate:
     * a function determining if a date is disabled with that date as its parameter.
     * Should return a Boolean
     *
     * example:
     *  datePickerOptions: {
     *  disabledDate(date) {
     *    return date >= new Date();
     *  },
     *},
     */
    pickerOptions: {
      type: Object,
      default: () => {}
    },
    /**
     * left/center/right
     */
    align: {
      type: String,
      default: () => 'left'
    },
    'default-value': {
      default: () => Date.now()
    },
    /**
     * Class for dropdown
     */
    'popper-class': {
      type: String,
      default: () => 'date-time-picker-dropdown'
    },
    /**
     *
     *  shortcuts
     *    a { text, onClick } object array to set shortcut options
     *  disabledDate
     *    a function determining if a date is
     *    disabled with that date as its parameter.
     *    Should return a Boolean function
     */
    'picker-options': {
      type: Object,
      default: () => ({
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'A week ago',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ],
        disabledDate: () => false
      })
    },
    'range-separator': {}
  },
  components: { Calendar },
  mounted() {
    if (this.autoFocus) {
      setTimeout(() => {
        document.getElementById(this.inputId).focus()
      }, 200)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.date-picker--container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
.el-date-editor.headless {
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
}
.el-input {
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
  opacity: 0;
  top: 2em;
  &.el-input--large {
    .el-input__icon {
      width: 100%;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
      + .el-input__inner {
        border-radius: 5em !important;
        background: $cool-gray-400;
        color: $cool-gray-800;
        border: none;
        text-align: center;
        font-weight: bold;
        padding: 0 1em !important;
        line-height: 1 !important;
        height: 3em;
      }
    }

    &:hover {
      cursor: pointer;
      .el-input__icon {
        opacity: 1;
        pointer-events: all;
        color: $cool-gray-800;
        + .el-input__inner {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}
.el-picker-panel.el-date-picker.date-time-picker-dropdown {
  border-radius: 10px !important;
  overflow: hidden;
}
.date-time-picker-dropdown {
  z-index: 10000 !important;
  border-radius: 10px !important;
}
</style>
