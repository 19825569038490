<template>
  <div class="counterparty-onboarding pt-8">
    <Wizard
      v-show="!loading && !merchantId"
      :steps="steps"
      :startingStep="startingStep"
      :key="wizardKey"
    >
      <WizardStep v-if="isPayfacEnabled && !performLightKyc">
        <CounterpartyOnboardingLegalEntity v-model="legalEntityType" :errors="errors" />
      </WizardStep>
      <WizardStep v-if="!performLightKyc">
        <CounterpartyOnboardingCompany
          :errors="errors"
          @change="(obj) => (counterparty = obj)"
          :counterparty="counterparty"
        />
      </WizardStep>
      <WizardStep v-if="!performLightKyc">
        <CounterpartyOnboardingAddress
          ref="companyAddress"
          reference="company"
          :errors="errors"
          @change="(obj) => (counterparty = obj)"
          :counterparty="counterparty"
        />
      </WizardStep>
      <WizardStep>
        <CounterpartyOwners
          :onSkip="onSkip"
          :errors="errors"
          :legalEntityType="legalEntityType"
          @show-owner-onboarding="handleShowOwnerOnboarding"
          :counterparty="counterparty"
          :owners="owners"
          :isOrganization="isOrganization"
          :userIsOwner="userIsOwner"
        />
      </WizardStep>
      <WizardStep v-if="isPayfacEnabled && !performLightKyc">
        <CounterpartyTerms v-model:terms="terms" />
      </WizardStep>
      <WizardStep v-if="isPayfacEnabled && isCompanyLocatedInUS">
        <BusinessAccountTerms
          v-model:businessAccountTerms="businessAccountTerms"
          :handleGetUploadedBusinessAccountTermsOfService="
            handleGetUploadedBusinessAccountTermsOfService
          "
        />
      </WizardStep>
      <WizardStep v-if="!isCompanyLocatedInUS">
        <CounterpartyOnboardingBankAccount @success="onSuccess" />
      </WizardStep>
    </Wizard>
    <danger class="py-3 mx-3" v-if="merchantId && !documentVerificationNeeded">
      <template #title><div class="text-center text-md-left">Activate this account</div></template>
      <span class="w-100 mb-2 text-center text-md-left">
        Your business profile requires additional verification.
      </span>
      <btn class="btn mt-4 danger mx-auto mx-md-0" @click="goToPayments">
        Update business profile
      </btn>
    </danger>

    <warning class="mt-5 sm" v-if="warning">
      {{ warning }}
    </warning>

    <danger class="mt-5 sm" v-if="errors && errors.length > 0">
      {{
        `Please fill out the following fields: ${errors
          .toString()
          .replaceAll('.', ' ')
          .replaceAll('_', ' ')
          .replaceAll(',', ', ')}`
      }}
    </danger>

    <div class="mt-4 pt-4" v-show="loading">
      <div class="loader flex items-center justify-center">
        <spinner class="info mr-4" :loading="1" size="2em" />
        <p v-if="loading === 1">Loading settings..</p>
        <p v-if="loading === 2">Setting up payments..</p>
        <p v-if="loading === 3">Setting up business account..</p>
      </div>
    </div>

    <danger class="mt-5" v-if="error">
      {{ error }}
    </danger>
  </div>
</template>

<script>
import PlaidMixin from '../../plaid/PlaidMixin'
import OnboardingMixin from './OnboardingMixin'
import PayfacMixin from '../../PayfacMixin'
import CounterpartyMixin from '../../counterparty/CounterpartyMixin'
import CounterpartyProfileMixin from '../../counterparty/CounterpartyProfileMixin'

import CounterpartyOnboardingAddress from './CounterpartyOnboardingAddress.vue'
import CounterpartyOnboardingBankAccount from './CounterpartyOnboardingBankAccount.vue'
import CounterpartyOnboardingCompany from './CounterpartyOnboardingCompany.vue'
import CounterpartyOwners from './CounterpartyOwners.vue'
import CounterpartyTerms from './CounterpartyTerms.vue'
import BusinessAccountTerms from './BusinessAccountTerms.vue'
import CounterpartyOnboardingLegalEntity from './CounterpartyOnboardingLegalEntity.vue'

import Wizard from '../../../ui/wizard/Wizard.vue'
import WizardStep from '../../../ui/wizard/WizardStep.vue'
import eventBus from '@/eventBus'

export default {
  name: 'CounterpartyFullOnboarding',
  mixins: [PlaidMixin, OnboardingMixin, PayfacMixin, CounterpartyMixin, CounterpartyProfileMixin],
  props: {
    performLightKyc: {
      type: Boolean,
      default: false
    },
    parentLoading: {
      type: Boolean,
      default: false
    },
    owners: {
      type: Array
    }
  },
  emits: ['ownerOnboarding', 'close'],
  data() {
    return {
      loading: 1,
      accountData: {},
      requirements: null,
      currentlyDue: null,
      showOwnerOnboarding: false,
      error: null,
      userIsOwner: true,
      legalEntityType: null,
      terms: {
        acceptCCTerms: false,
        acceptPayFacTerms: false
      },
      businessAccountTerms: {
        acceptBusinessAccountThroughBolster: false,
        acceptBusinessAccountTerms: false
      }
    }
  },
  methods: {
    handleShowOwnerOnboarding(params) {
      this.error = null
      this.$emit('ownerOnboarding', params)
    },
    checkBusiness() {
      if (
        !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
          this.counterparty.business_profile.url
        )
      ) {
        this.error =
          'Invalid website format. Make sure your website matches the format https://www.website.com'
        return false
      }
      this.error = null
      return this.beforeNext(1)
    },
    checkAddress() {
      this.error = this.$refs.companyAddress.validateAddress(this.counterparty.company.address)
      if (this.error === null) {
        return this.beforeNext(2)
      }
      return false
    },
    async checkOwnership() {
      // is not an organization
      if (!this.isOrganization) {
        this.error = null
        return this.beforeNext(3)
      }
      // check to make sure at least one owner has been marked as a controller
      const controllers = this.owners.find((owner) => owner.relationship.executive)
      if (!controllers) {
        this.error =
          'To onboard as an organization, at least one decision maker must be marked as a controlling person. Please edit an owner and check the box for "is controlling person" to proceed.'
        return false
      }
      this.error = null
      return this.beforeNext()
    },
    async checkTerms() {
      const checks = Object.values(this.terms)
      const allChecked = checks.every((value) => Number(value) === 1)
      if (!allChecked) {
        this.error =
          'You must accept both terms of service before continuing. If you have any questions please reach out to support.'
        return false
      }

      this.error = null

      return await this.onboardPayeeAccount(false)
    },
    async checkBusinessAccountTerms() {
      const checks = Object.values(this.businessAccountTerms)
      const allChecked = checks.every((value) => Number(value) === 1)
      if (!allChecked) {
        this.error =
          'You must accept both terms of service before continuing. If you have any questions please reach out to support.'
        return false
      }

      this.error = null
      const result = await this.onboardBusinessAccount()

      this.onSkip()

      return result
    },
    goToPayments() {
      const location = this.isCompanyLocatedInUS ? 'businessAccounts' : 'payments'
      this.$store.dispatch('to', location)
      this.$emit('close')
    },
    /** When user clicks skip */
    onSkip() {
      this.$emit('close')
    },
    /**
     * User has successfully went through plaid modal
     */
    onSuccess() {
      this.$router.go()
    },
    /**
     * Set the company up for payments creating account and adding external bank account
     */
    async onboardPayeeAccount(skipBank = false) {
      const status = this.$store.state.session.company.counterparty_status
      if (status === 'a' || status === 'i') return
      try {
        // use the returned public token and metadata
        // meta data includes selected account
        const { publicToken = null, metadata = null, externalAccount = null } = this.accountData
        this.loading = 2
        this.error = null
        const { payload } = await this.handleOnboardPayee({
          counterparty: this.counterparty,
          externalAccount: skipBank ? null : externalAccount,
          owners: this.owners,
          publicToken,
          metadata,
          legalEntityType: this.legalEntityType
        })
        if (payload.error) throw payload.error

        let businessAccountTermsOfService = null
        const country = this.$store.state.session.company.country_abbr
        if (country === 'us') {
          businessAccountTermsOfService = await this.getBusinessAccountTermsOfService(
            payload.decisionMakerIds
          )
        }

        this.$store.dispatch(`payfac/setCounterpartyData`, {
          companyId: this.$store.state.session.company.company_id,
          data: {
            primaryId: payload.primaryId,
            accountHolder: payload.accountHolder,
            decisionMakerIds: payload.decisionMakerIds,
            ...(country === 'us' ? businessAccountTermsOfService : null)
          }
        })

        if (country !== 'us') {
          eventBus.$emit('kycCompleted')
        }

        this.loading = 0
        // this.$router.go()
        return true
      } catch (e) {
        this.error =
          'An error occurred while setting up your profile. Contact support if this issue persists.'
        this.loading = 0
        return false
      }
    },
    /**
     * Set the company up for payments creating account and adding external bank account
     */
    async onboardBusinessAccount() {
      try {
        // use the returned public token and metadata
        // meta data includes selected account
        const { publicToken = null, metadata = null } = this.accountData
        this.loading = 3
        this.error = null

        const { primaryId, accountHolder } = this.counterpartyData

        const { payload } = await this.handleOnboardBusinessAccount({
          counterparty: this.counterparty,
          primaryId,
          accountHolder,
          publicToken,
          metadata,
          legalEntityType: this.legalEntityType
        })
        if (payload.error) throw payload.error

        eventBus.$emit('kycCompleted')

        this.loading = 0
        // this.$router.go()
        return true
      } catch (e) {
        this.error =
          'An error occurred while setting up your profile. Contact support if this issue persists.'
        this.loading = 0
        return false
      }
    },
    /**
     * Get the current onboarding information
     * map session data to fields
     */
    async init() {
      this.meta = this.$store.state.session.company.oMeta || {}
      this.mapSessionDataToCounterparty()
      this.profile = c.cloneDeep(this.counterparty)
      this.loading = 0
    }
  },
  computed: {
    isOrganization() {
      return this.legalEntityType === 'organization'
    },
    /** does the counterparty need to verify account via document */
    documentVerificationNeeded() {
      if (!this.currentlyDue) return false
      let needed = false
      this.currentlyDue.forEach((r) => {
        if (/\bverification.document\b/g.test(r)) needed = true
      })
      return needed
    },
    /**
     * The steps for wizard, can change based on selection
     */
    steps() {
      return [
        ...(this.isPayfacEnabled && !this.performLightKyc
          ? [
              {
                label: 'Type',
                action: this.beforeNext
              }
            ]
          : []),
        ...(!this.performLightKyc
          ? [
              {
                label: 'Business',
                action: this.checkBusiness
              }
            ]
          : []),
        ...(!this.performLightKyc
          ? [
              {
                label: 'Address',
                action: this.checkAddress
              }
            ]
          : []),
        {
          label: 'Decision-makers',
          action: this.checkOwnership
        },
        ...(this.isPayfacEnabled && !this.performLightKyc
          ? [
              {
                label: 'Terms',
                action: this.checkTerms
              }
            ]
          : []),
        ...(this.isPayfacEnabled && this.isCompanyLocatedInUS
          ? [
              {
                label: 'Business Account Terms',
                action: this.checkBusinessAccountTerms
              }
            ]
          : []),
        ...(!this.isCompanyLocatedInUS
          ? [
              {
                label: 'Banking',
                action: this.beforeNext,
                skip: this.onSkip,
                hidePrevious: true
              }
            ]
          : [])
      ]
    },
    startingStep() {
      let startingStep = 0
      const incompleteStatus = this.$store.state.session.company.counterparty_status === 'u'
      if (!incompleteStatus) {
        startingStep = this.performLightKyc ? 0 : 5
      }
      return startingStep
    },
    wizardKey() {
      return `wizard-${this.startingStep}-${this.steps.length}`
    },
    counterpartyData() {
      return this.$store.getters['payfac/getCounterpartyDataByCompanyId'](
        this.$store.state.session.company.company_id
      )
    },
    /**
     * Schema validation array
     * Can change based on payfac
     */
    valdationSchemas() {
      return [
        ...(this.isPayfacEnabled ? [['legalEntityType']] : []),
        [
          'counterparty.company.name',
          'counterparty.company.tax_id',
          'counterparty.business_profile.url',
          'counterparty.business_profile.mcc'
        ],
        [
          'counterparty.company.address.line1',
          'counterparty.company.address.city',
          'counterparty.company.address.state',
          'counterparty.company.address.country',
          'counterparty.company.address.postal_code'
        ],
        ['owners']
      ]
    }
  },
  watch: {
    parentLoading(newVal) {
      if (!newVal) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.parentLoading) {
      await this.init()
    }
  },
  components: {
    CounterpartyOnboardingAddress,
    CounterpartyOnboardingBankAccount,
    CounterpartyOnboardingCompany,
    CounterpartyOwners,
    CounterpartyTerms,
    BusinessAccountTerms,
    CounterpartyOnboardingLegalEntity,
    Wizard,
    WizardStep
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
.enter-bank {
  // color: $blue!important;
}
.counterparty-onboarding {
  position: relative;
}

.counterparty-onboarding-is-owner {
  @media (max-width: 576px) {
    margin-left: 1em;
  }
}
</style>
