// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import * as types from '../../../src/store/mutation-types'
import _ from '../Helpers'
import Quote from './Quote'
import QuoteAddons from './QuoteAddons'
import CostType from './CostType'
import CostItem from './CostItem.js'
export default {
  type: 'assembly',

  possibleStatuses: ['a', 'i', 'y', 'h', '@'],

  /**
   * Items that can be intermixed with this object type,
   * that could be cached.  Each of these object types
   * will be cleared from cache, when clearning assemblies from cache.
   * @type {[string,string,string]}
   */
  cachedTypes: ['assembly', 'cost_type', 'cost_item'],

  fields: {
    oViewOptions: CostType.fields.oViewOptions,

    assembly_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true,
      component: 'PreviewAssembly'
    },
    assembly_is_rfq_root: {
      type: 'int',
      default: 0
    },
    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true
    },
    assembly_country: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true
    },
    item_id: {
      save: true,
      type: 'string',
      reload: true,
      trackChanges: false
    },
    vendor_id: {
      type: 'string',
      filter: true,
      save: true,
      trackChanges: true,
      mapTo: 'vendor'
    },
    assembly_markup_percentage_adjustment: {
      type: 'float',
      save: true,
      format: 'currency',
      trackChanges: true
    },
    file_ids: {
      type: 'array',
      save: true,
      trackChanges: true,
      normalize: false
    },
    assembly_name: {
      type: 'string',
      filter: true,
      format: 'text',
      mapTo: false,
      validate: {
        required: true
      }
    },
    /**
     * Saved settings for this item. DO NOT put state flags here. For example do not
     * put itemIsPaid or itemAssignedTo or something because those will not be reset
     * when the item is used or re-used across other quotes. This is not reset in resetFlags
     * calls. Use a different object for that if necessary and make sure it is reset
     * in resetFlags
     */
    oMeta: {
      type: 'object',
      save: true,
      trackChanges: true,
      deep: true,
      defaultSetting: false,
      default: () => ({})
    },
    assembly_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      ommitFromDuplicate: true,
      default: 'a'
    },
    assembly_time_created: {
      type: 'float',
      filter: true,
      format: false,
      reload: true,
      save: false
    },
    assembly_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
      trackChanges: false
    },
    original_assembly_id: {
      title: 'Former assembly ID',
      type: 'string',
      filter: false,
      format: false,
      mapTo: 'user'
    },
    assembly_is_recursed: {
      type: 'int',
      default: 0,
      filter: false,
      save: false,
      mapTo: false
    },

    /**
     * Changes appearance in the presentation
     * -3 === show in a bundle with other de-emphasizded items or as an item if it is the only de-emphasized item in s aset
     * -2 === show as an item
     * -1 === show as an emphasized item
     * 0 === normal, show as expanded assembly
     * +1 === show as expanded assembly, but with emphasis
     */
    assembly_emphasis: {
      type: 'int',
      default: 0,
      filter: false,
      reload: false,
      save: true,
      trackChanges: true,
      autoPartialSave: true,
      defaultSetting: false
    },
    tax_id: {
      type: 'int',
      default: null,
      save: true,
      trackChanges: true
    },

    ...Quote.fields,

    quote_id: {
      type: 'string',
      trackChanges: false,
      save: false,
      default: null
    },
    // For tracking if this item is an upgrade
    addon_is_upgraded: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true
    },
    addon_is_saved: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true,
      reload: true
    },
    addon_upgraded_by: {
      type: 'string'
    },
    addon_time_upgraded: {
      type: 'float',
      default: null
    },
    quote_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      audit: false
    },
    quote_price_gross: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      audit: false
    },
    quote_price_tax: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      audit: false
    },
    quote_link_area: {
      type: 'int',
      filter: false,
      default: 1
    },
    aoStageTasks: {
      type: 'array',
      mapTo: 'task',
      trackChanges: true,
      reload: true,
      title: 'Item tasks'
    },
    assembly_is_optional: {
      type: 'int',
      default: 0
    },
    assembly_show_expanded: {
      type: 'int',
      default: null,
      trackChanges: true
    },
    assembly_price_adjustment_net: {
      type: 'float',
      default: 0
    },
    assembly_minimum_qty_net: {
      type: 'float',
      default: null
    },
    assembly_is_using_minimum_qty: {
      type: 'int',
      default: 0
    },
    assembly_minimum_area_net: {
      type: 'float',
      default: null
    },
    assembly_is_using_minimum_area: {
      type: 'int',
      default: 0
    },
    asAssemblyPath: {
      type: 'array',
      default: [],
      save: true,
      trackChanges: false
    },
    aoAssembliesUsingItem: {
      type: 'array',
      mapTo: 'assembly',
      filter: false,
      save: false,
      trackChanges: false,
      reload: true,
      normalize: false
    },
    assembly_is_used_in_assembly: {
      type: 'int',
      filter: true,
      save: false,
      reload: true,
      trackChanges: true
    },
    /**
     * If this item is ina  set of upgrades.
     * keep track of which item was the original
     * to see if an upgrade occurred
     * In format: `${type}:${id}`
     */
    upgradesOriginalKey: {
      type: 'string',
      default: 0,
      trackChanges: false,
      save: true
    },
    assembly_show_itemized_prices: {
      type: 'int',
      default: 1,
      trackChanges: true,
      save: true
    },
    item_is_upgraded: {
      type: 'int',
      default: 1,
      trackChanges: false
    },
    assembly_price_adjustment_net_base: {
      type: 'float',
      default: 0
    },
    assembly_count_own_dimensions: {
      type: 'int',
      default: 0,
      trackChanges: false
    },
    dimension_measure_type: {
      default: 'count',
      type: 'string'
    },
    asRequiredDimensions: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    asDimensionsUsed: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    assembly_is_included: {
      type: 'int',
      default: 1,
      save: true,
      trackChanges: true
    },
    assembly_orig_qty_net: {
      type: 'float',
      default: 1,
      save: true
    },
    assembly_orig_price_net: {
      type: 'float',
      default: 0,
      save: true
    }
  },

  generateVueActions() {
    const quoteActions = Quote.generateVueActions()

    return {
      ...QuoteAddons,
      confirmDimensions: quoteActions.confirmDimensions,
      addChildPreferences: quoteActions.addChildPreferences,
      getQuoteItem: quoteActions.getQuoteItem,
      addItems: quoteActions.addItems,
      setIncluded: CostItem.generateVueActions().setIncluded,

      generateTaskItemDurations(store, normalized = {}) {
        return Object.keys(normalized).reduce((acc, refId) => {
          const item = normalized[refId]
          // is not a cost item
          if (item.item_type != 'cost_item' || item.type != 'cost_item') {
            acc[refId] = item
            return acc
          }
          // does not have a duration to generate
          if (!item.start_date || !item.end_date) {
            acc[refId] = item
            return acc
          }
          // calculate duration
          const duration = _.calculateDuration(item.start_date, item.end_date) || 1
          // set the duration
          item.cost_item_duration = duration
          acc[refId] = item
          return acc
        }, {})
      },

      async saveFromAssembly({ dispatch, rootState }, args) {
        const {
          refId,
          store = 'Quote',
          normalized = rootState[store].normalized,
          asSuper = false,
          asNew = false,
          quiet = false
        } = args

        const isSuper = asSuper && asSuper === true && rootState.session.user.user_is_super_user

        const { key: queueKey } = await dispatch(`${store}/queueRequest`, {}, { root: true })

        const set = await dispatch('generateTaskItemDurations', normalized)

        let savePayload = {}

        const denormalized = await dispatch('denormalize', { rootRef: refId, set })
        const type = denormalized.type

        try {
          let { object: reset } = await dispatch('resetFlags', {
            object: denormalized,
            resetAddons: false
          })
          reset = {
            ...reset,

            assembly_id: asNew ? null : denormalized.assembly_id,
            quote_default_qty: denormalized.quote_qty_net_base,

            type: 'assembly'
          }

          const { overrides, scope } = await dispatch('globalSaveCheck', isSuper)

          reset = {
            ...reset,
            ...overrides
          }

          // Release placeholder so we can go to our save
          savePayload = await dispatch('save', {
            scope,
            go: false,
            force: true,
            alert: false,
            queue: false, // we already waited above ^^
            selected: [reset],
            except: [
              // cannot be edited from here, so don't use potentially bad values to save
              // 'parent_cost_type_id',
              'variation_parent_cost_type_id',
              'variation_parent_cost_type_name',
              'oVariations',
              'cost_type_is_variation_parent',
              'cost_type_is_variation_none_allowed',
              'cost_type_status'
            ]
          })

          const { object } = savePayload

          // update fields with changed values after save, like ID
          await dispatch(
            `${store}/field`,
            {
              refId,
              changes: {
                assembly_id: object.assembly_id,
                assembly_status: object.assembly_status,
                company_id: object.company_id,
                parent_cost_type_id: object.parent_cost_type_id
              },
              explicit: true,
              immediate: true,
              queue: false,
              skipAudit: true,
              skipLocalAudit: true
            },
            { root: true }
          )

          if (!quiet) {
            dispatch(
              'alert',
              {
                message:
                  'You successfully saved line item.  Now it can be used later in another quote.'
              },
              { root: true }
            )
          }

          dispatch('clearCache', { type: 'cost_type' })
          dispatch('clearCache', { type: 'assembly' })
          dispatch(`${store}/getHashes`, {}, { root: true })
        } catch (e) {
          console.log('emitting ', `error-${type}`)

          const { userMessage = 'Could not save. Please try again.' } = e
          dispatch(
            'alert',
            {
              message: userMessage,
              error: true,
              timeout: 6000
            },
            { root: true }
          )

          throw e
        } finally {
          dispatch(`${store}/queueNext`, { key: queueKey }, { root: true })
        }

        return savePayload
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float totalPrice
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTotalPrice({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          totalPrice,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const price = _.n(totalPrice)
        const cost = _.n(object.quote_total_cost_net_base)
        const targetMarkup = price / (cost || price * this.defaultMarkup) // default to 1

        const { changes } = await dispatch('setTargetMarkup', {
          object,
          store,
          targetMarkup
        })

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges: {
            quote_subtotal_net: totalPrice
          },
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float targetMarkup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTargetMarkup({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          targetMarkup,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let changes = {}
        let explicitChanges = {}

        if (targetMarkup === null || targetMarkup === '') {
          return dispatch('reportChanges', {
            ...payload,
            changes,
            explicitChanges,
            auditLocal: false,
            auditFull: false
          })
        }

        /**
         * The equation given by
         * p - q
         * ----- = a
         * 1 + q
         *
         * as reversed from p,
         *
         * where:
         *  p: full/combined percentage adjustment
         *      p = a + q + qa
         *
         *      or
         *           (t - 1) - (m - 1)
         *      p = ------------------
         *                (m - 1)
         *
         *  t: target markup
         *  m: regular / existing markup
         *  q: quote/parent percentage adjustment
         *  a: local/assembly percentage adjustment
         */
        const t = _.n(targetMarkup)
        const m = _.n(object.quote_markup_net)
        const p = (t - 1 - (m - 1)) / (_.eq(m - 1, 0, 5) ? 1 : m - 1)
        const norm = rootState[store].normalized
        const q =
          object.parentRefId &&
          norm[object.parentRefId] &&
          _.n(norm[object.parentRefId].quote_markup_percentage_adjustment)
        const a = (p - q) / (1 + q)

        let margin = _.markupToMargin(t) // (markup - 1) / markup;

        margin = margin < -0.99 ? -0.99 : margin
        margin = margin > 0.99 ? 0.99 : margin
        margin = margin * 100

        changes = {
          quote_margin_net: margin,
          assembly_markup_percentage_adjustment: a
        }
        explicitChanges = {
          ...changes
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          auditLocal: false,
          auditFull: false
        })
      },

      /**
       * See CostItem.resetFlags
       * @param dispatch
       * @param payload
       *  @param object object must be fully denormalized
       * @returns {Promise<*>}
       */
      async resetFlags({ dispatch }, payload = {}) {
        const { resetAddons = true } = payload

        let { object } = await dispatch('resolveObject', payload)

        const changes = {
          aoStageTasks: (object.aoStageTasks || []).map((t) => ({
            ...t,
            task_id: null, // make sure task id always null
            task_stage_object_id: null,
            quote_id: null,
            client_id: null,
            invoice_id: null,
            // keep task_stage_object_status and _id
            // Also anything that hints at being completed
            task_completed_by: null,
            completer_id: null,
            creator_id: null,
            company_id: null,
            task_status: 'u',
            task_time_completed: null,
            task_completer: null,
            oOwner: null,
            task_owner: null,
            owner_id: null,
            aoComments: [],
            oClient: null,
            oQuote: null,
            oInvoice: null,
            task_list_id: null
          })),

          addon_is_upgraded: 0,
          addon_upgraded_by: null,
          addon_time_upgraded: null,
          upgradesOriginalKey: null,
          addon_is_saved: 0,

          change_order_id: null,
          item_id: null,

          // Remove original tags from addons
          // remove bulk items
          aoAddons: resetAddons
            ? (object.aoAddons || [])
                .map((a) => ({
                  ...a,
                  ...(a.bulk &&
                  Object.keys(a.bulk).length &&
                  (a.id || (a.bulk && (a.bulk.cost_type_id || a.bulk.assembly_id)))
                    ? {
                        id: a.id || (a.bulk && (a.bulk.cost_type_id || a.bulk.assembly_id)) || null,
                        type: a.type || (a.bulk && (a.bulk.type || a.bulk.type)) || null,
                        bulk: null,
                        original: a.bulk && Object.keys(a.bulk).length ? 1 : 0
                      }
                    : {})
                }))
                .filter((a) => a.id)
            : object.aoAddons
        }

        const newObject = {
          ...object,
          ...changes
        }

        Object.keys(newObject).forEach((field) => {
          let val = newObject[field]

          // remove parent or client or item data
          if (/^(client_|parent_)?item_/.test(field)) {
            changes[field] = null
            val = null
          }

          newObject[field] = val
        })

        if (newObject.aoChildren && newObject.aoChildren.length) {
          newObject.aoChildren = await Promise.all(
            newObject.aoChildren.map(async (obj) => {
              const { object: child } = await dispatch(
                `${_.titleCase(obj.type)}/resetFlags`,
                {
                  object: obj,
                  resetAddons: false
                },
                { root: true }
              )

              return child
            })
          )
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges: changes,
          object: newObject,
          auditLocal: payload.auditLocal || false,
          auditFull: payload.auditFull || false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async removeAllSubAdjustments({ dispatch, commit, rootState }, payload) {
        const { refId, store = 'Assembly', norm = _.imm(rootState[store].normalized) } = payload

        const changes = {
          [refId]: {
            assembly_markup_percentage_adjustment: 0
          }
        }
        const newSet = {
          ...norm,
          [refId]: {
            ...norm[refId],
            assembly_markup_percentage_adjustment: 0
          }
        }

        const setChildren = (r) => {
          if (!r || !norm[r].aoChildren) {
            return
          }

          norm[r].aoChildren.forEach((childRef) => {
            newSet[childRef] = {
              ...newSet[childRef],
              assembly_markup_percentage_adjustment: 0
            }

            if (norm[childRef].assembly_markup_percentage_adjustment) {
              changes[childRef] = {
                assembly_markup_percentage_adjustment: 0
              }
            }

            setChildren(childRef)
          })
        }

        setChildren(refId)

        commit(
          `${store}/${types.ADD_NORMALIZED}`,
          {
            object: newSet
          },
          {
            root: true
          }
        )

        await dispatch(
          `${store}/auditDependencies`,
          {
            changes,
            refId,
            immediate: true,
            force: true,
            delay: 0,
            queue: false
          },
          {
            root: true
          }
        )

        const changeManager = await dispatch(`${store}/getChangeManager`, { refId }, { root: true })
        changeManager.addExplicitChanges(changes)

        return {
          set: newSet,
          norm: newSet,
          changes
        }
      },

      async publish({ dispatch }, payload) {
        const { id, store = 'Assembly', isPublic = 1 } = payload
        const { object } = await dispatch(`${store}/resolveObject`, { id }, { root: true })
        const type = store.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
        const field = `${type}_is_public`
        const fieldId = `${type}_id`
        return dispatch('partialUpdate', {
          selected: [
            {
              type,
              [fieldId]: object[fieldId],
              [field]: isPublic
            }
          ]
        })
      }
    }
  },

  getComputedDependants() {
    return {
      ...Quote.getComputedDependants(),
      item_is_upgraded(state, parent) {
        return state.addon_is_upgraded || parent.item_is_upgraded ? 1 : 0
      },
      item_count_upgrades(state, parent, children = []) {
        const self = state.item_is_upgraded || state.addon_is_upgraded || 0

        const childCount = children.reduce(
          (acc, child) => acc + (child?.item_count_upgrades || 0),
          0
        )

        return self + childCount
      },
      quote_name(state) {
        return state.assembly_name && state.assembly_name !== ''
          ? state.assembly_name
          : state.quote_name
      },
      assembly_name(state) {
        return state.assembly_name && state.assembly_name !== ''
          ? state.assembly_name
          : state.quote_name
      }
    }
  },

  getFieldDependencies() {
    const {
      quote_name,
      assembly_name,
      item_is_upgraded,
      item_count_upgrades,
      asRequiredDimensions,
      assembly_count_own_dimensions
    } = this.getComputedDependants()
    return {
      ...Quote.getFieldDependencies(),
      addon_is_upgraded: {
        item_is_upgraded
      },
      item_is_upgraded: {
        item_count_upgrades
      },
      quote_name: {
        assembly_name
      },
      assembly_name: {
        quote_name
      },
      oDimensions: {
        asRequiredDimensions
      },
      asRequiredDimensions: {
        assembly_count_own_dimensions
      }
    }
  },

  getParentDependencies() {
    const { item_is_upgraded } = this.getComputedDependants()

    return {
      ...Quote.getParentDependencies(),
      item_is_upgraded: {
        item_is_upgraded
      }
    }
  },

  getChildDependencies() {
    return Quote.getChildDependencies()
  }
}
