import { ref, onMounted, getCurrentInstance, onBeforeUnmount } from 'vue'

export default {
  useSelectCells(args) {
    const {
      shiftKeyPressed,
      ctrlKeyPressed,
      rowsMap,
      getRowSheet,
      getSheet,
      getCellBoundingRect,
      getSelectionRange,
      deselect,
      scrollableCols,
      tooltip,
      tooltipCoords,
      dataSheets,
      isCellDisabled,
      isCellReadOnly,
      getCellType
    } = args

    const $this = getCurrentInstance().proxy

    const rightClick = ref(false)
    const mouseDown = ref(false)
    const currentCell = ref([null, null])
    const hoverCell = ref([null, null, [null, null, null, null], null])
    const multiSelect = ref(null)
    const cellFocused = ref(false)
    const originalBoundingRect = ref({})

    const handleMouseoverCell = (eventData) => {
      const { col, row, x: left, y: top, width, height } = eventData.element.position
      const cellType =
        col !== null && row !== null && rowsMap.value?.length && getCellType(col, row)

      hoverCell.value = [col, row, [left, top, width, height], cellType]

      const colDef = dataSheets.value[eventData?.element?.sheetIndex]?.columns?.[col] ?? {}
      tooltip.value = null

      if (colDef.tooltip || colDef.checkbox || colDef.disabled || colDef.readOnly) {
        c.throttle(
          () => {
            if (hoverCell.value[0] !== col || hoverCell.value[1] !== row) {
              tooltip.value = null
              return
            }

            const dis = isCellDisabled(col, row)
            const ro = isCellReadOnly(col, row)

            const msg =
              colDef.tooltip ||
              (dis && 'This cell is currently disabled') ||
              (ro && 'This cell is read-only') ||
              (colDef.checkbox && 'Click to toggle this option') ||
              null

            tooltip.value = msg

            tooltipCoords.value = {
              left,
              top,
              width,
              height
            }
          },
          { delay: 200 }
        )
      }
    }
    const handleDeselect = () => {
      deselect()
    }

    onMounted(() => {
      // multi select
      // window.addEventListener('mousemove', handleMouseMoveSelect);
      // window.addEventListener('mouseup', handleMouseUpSelect);

      $this.$on('mouseoverCell', handleMouseoverCell)
      $this.$on('mousedownCell', handleMouseDownSelect)
      $this.$on('mousemoveCell', handleMouseMoveSelect)
      window.addEventListener('mouseup', handleMouseUpSelect)
      window.addEventListener('click', handleMouseUpSelect)
      window.addEventListener('dblclick', handleMouseUpSelect)
    })
    $this.$on('mousedownOutOfBoundsRight', handleDeselect)

    onBeforeUnmount(() => {
      $this.$off('mouseoverCell', handleMouseoverCell)
      $this.$off('mousedownCell', handleMouseDownSelect)
      $this.$off('mousemoveCell', handleMouseMoveSelect)
      window.removeEventListener('mouseup', handleMouseUpSelect)
      window.removeEventListener('click', handleMouseUpSelect)
      window.removeEventListener('dblclick', handleMouseUpSelect)
      $this.$off('mousedownOutOfBoundsRight', handleDeselect)
      // multi select
      // window.removeEventListener('mousemove', handleMouseMoveSelect);
      // window.removeEventListener('mouseup', handleMouseUpSelect);
    })

    const moveCell = (col, row, commit = cellFocused.value) => {
      cellFocused.value = false
      multiSelect.value = null

      // Only move if it is different
      const [curCol, curRow] = currentCell.value
      if (curCol === col && row === curRow) return

      const sheetIndex = getRowSheet(row)
      const sheet = getSheet(sheetIndex)

      if (!sheet) return

      // commit before changing
      if (commit) {
        $this.$emit('commit')
      }

      const colMax = Math.min(Math.max(0, col), (sheet.columns || []).length - 1)
      const rowMax = Math.min(Math.max(0, row), rowsMap.value.length - 1)
      originalBoundingRect.value = getCellBoundingRect(colMax, rowMax)
      currentCell.value = [colMax, rowMax]
    }

    const selectDown = () => moveCell(currentCell.value[0], currentCell.value[1] + 1)

    const selectUp = () => moveCell(currentCell.value[0], currentCell.value[1] - 1)

    const selectRight = () => {
      const [col, row] = currentCell.value
      const newIndex = scrollableCols.value.indexOf(col) + 1
      moveCell(scrollableCols.value[newIndex], row)
    }

    const selectLeft = () => {
      const [col, row] = currentCell.value
      const newIndex = scrollableCols.value.indexOf(col) - 1
      moveCell(scrollableCols.value[newIndex], row)
    }

    const handleMouseDownSelect = async (eventData) => {
      const { col, row } = eventData.element.position

      mouseDown.value = true

      if ((shiftKeyPressed.value || ctrlKeyPressed.value) && currentCell.value) {
        selectMultipleCells(currentCell.value, [col, row])
      } else {
        multiSelect.value = null
        moveCell(col, row)
      }
      return true
    }

    const handleMouseMoveSelect = (eventData) => {
      if (!mouseDown.value) return

      const { col, row } = eventData.element.position

      const sheet = getSheet(getRowSheet(row))

      const [currentCol, currentRow] = currentCell.value

      if (col === currentCol && row === currentRow) return

      const confinedCol = Math.max(0, Math.min(col, (sheet.columns || []).length - 1))
      const confinedRow = Math.max(0, Math.min(row, rowsMap.value.length - 1))

      selectMultipleCells(currentCell.value, [confinedCol, confinedRow])
    }

    const selectMultipleCells = (startCell, endCell) => {
      if ([...startCell, ...endCell].every((coord) => coord !== null && coord > -1)) {
        multiSelect.value = getSelectionRange(startCell, endCell)
      }
    }

    const handleMouseUpSelect = () => {
      mouseDown.value = false
    }

    return {
      mouseDown,
      currentCell,
      hoverCell,
      multiSelect,
      selectDown,
      selectUp,
      selectMultipleCells,
      rightClick,
      selectRight,
      selectLeft,
      originalBoundingRect,
      moveCell,
      cellFocused
    }
  }
}
