<script setup>
import { computed, ref, defineExpose, defineProps, useSlots, defineEmits } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['close'])
const props = defineProps({
  name: {
    required: true
  },
  /**
   * [
   *   {
   *     title: '',
   *     subtitle: '',
   *     icon: '',
   *     desc: '',
   *     showSummary: true|false // on step 0 only
   *   },
   *   ...
   * ]
   */
  steps: { type: Array, required: true },
  /**
   * if provided, it will show an automated step-list summary of the next steps
   * [
   *   {
   *     title: '',
   *     subtitle: '',
   *   },
   *   ...
   * ]
   */
  summary: { type: Array, default: null }
})

const refModal = ref(null)
const close = () => refModal.value.close()

const $store = useStore()
const skipForever = async () => {
  _.setCacheItem(`skip-${props.name}`, 1, 'stepexplainermodal', $store.state.session, null)
  close()
}
const open = () => refModal.value.open()
const next = () => currentStep.value++
const back = () => currentStep.value--

const slots = useSlots()
// Compute the content of the default slot, fallback to empty array if no content is passed
const slotContent = computed(() => slots.default?.() ?? [])

const steps = computed(() => props.steps)
const stepsCount = ref(4)
const currentStep = ref(props.summary ? -1 : 0)
const currentVnode = computed(() => slotContent.value[currentStep.value])
const title = computed(
  () =>
    steps.value[currentStep.value]?.title ??
    props.summary?.title ??
    `${props.steps.length} easy steps`
)
const subtitle = computed(
  () => steps.value[currentStep.value]?.subtitle ?? props.summary?.subtitle ?? ''
)

const stepTitleClass = 'pl-2 text-3xl font-medium w-full'
const stepSubTitleClass = 'pl-2 text-xl font-medium text-surface-500 w-full'
const numberClass =
  'text-xl rounded-full bg-pitch-black size-8 text-surface-100 font-bold flex justify-center items-center shrink-0'
const stepSummaryText = 'font-medium text-xl leading-tight'
const stepSummaryItem = 'flex gap-4 justify-start items-center'
const stepSummaryDesc = 'text-lg font-normal w-full leading-tight text-surface-500'

defineExpose({
  refModal,
  close,
  open,
  next,
  back
})
</script>

<template>
  <MiniModal
    size="sm"
    :maxWidth="650"
    scrollable
    ref="refModal"
    :show-close-button="false"
    @close="emit('close')"
  >
    <template #header>
      <div class="flex flex-col items-center justify-between w-full h-full basis-full grow-0">
        <!-- title -->
        <div :class="stepTitleClass">{{ title }}</div>
        <div :class="stepSubTitleClass">{{ subtitle }}</div>
      </div>
    </template>
    <div class="flex flex-col items-center justify-between w-full h-full basis-full grow-0">
      <!-- content -->
      <div class="flex flex-col items-center w-full h-full basis-full shrink grow-0 gap-12 pt-8">
        <!-- steps -->
        <component :is="currentVnode" v-if="currentStep > -1" />
        <template v-else>
          <div class="flex flex-col gap-10 items-start max-w-[500px] pt-4">
            <div
              :class="[stepSummaryItem, 'flex justify-start w-full gap-6']"
              v-for="(step, index) in steps"
              :key="index"
            >
              <div :class="numberClass">{{ index + 1 }}</div>
              <div :class="[stepSummaryText, 'basis-4/5']">
                {{ step.title }}
                <div :class="stepSummaryDesc" v-if="step.desc">
                  {{ step.desc }}
                </div>
              </div>
              <div v-if="step.icon">
                <font-awesome-icon :icon="step.icon" class="text-3xl" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- steps footer -->
    <template #footer>
      <div class="flex flex-col justify-end items-stretch w-full" v-if="currentStep > -1">
        <!-- steps indicator -->
        <div class="flex justify-stretch items-stretch h-2 w-full gap-2">
          <div
            v-for="(step, index) in steps"
            :key="index"
            :class="[
              'shrink grow h-full',
              {
                'bg-surface-200': index > currentStep,
                'bg-blue-print': index <= currentStep
              }
            ]"
            :style="{
              basis: `${(1 / stepsCount) * 100}%`
            }"
          ></div>
        </div>

        <!-- actions -->
        <div class="flex justify-between pt-6">
          <Btn link size="2xl" class="!text-pitch-black" v-if="currentStep < 0" @click="close"
            >Skip</Btn
          >
          <Btn link size="xl" class="!text-pitch-black" v-else @click="currentStep--">Back</Btn>

          <Btn
            severity="primary"
            size="xl"
            @click="currentStep++"
            v-if="currentStep < props.steps.length - 1"
            >Next</Btn
          >
          <Btn severity="primary" size="xl" @click="close()" v-else>Finish</Btn>
        </div>
      </div>

      <div v-else class="flex flex-col justify-center items-center pb-6 gap-6">
        <Btn size="2xl" severity="bolster" class="w-full max-w-[500px]" @click="next">
          Get started
        </Btn>
        <div class="flex flex-row justify-center items-center gap-4">
          <Btn link size="xl" @click="close">Skip this time</Btn>
          <Btn link size="xl" @click="skipForever">Never show me this</Btn>
        </div>
      </div>
    </template>
  </MiniModal>
</template>
