<template>
  <section>
    <div class="mb-3">
      Working days
      <Help>
        <template #title> Working days </template>
        <p>
          Set your working days to be used for the schedule. If you turn off days they will not be
          included when calculating the duration of stages or cost items.
        </p>
      </Help>
    </div>
    <div class="flex">
      <div v-for="day in daysOfWeek" :key="day.value" class="flex mr-3">
        <Checkbox
          @change="(val) => setWorkingDays(day, val)"
          :value="value && value.includes(day.value - 1) ? day.value : null"
          :selected-value="day.value"
        />
        <span class="ml-2"> {{ day.label }} </span>
      </div>
    </div>
  </section>
</template>

<script>
import { workdays } from './defaultWorkdays'

export default {
  name: 'DaysOfWeek',
  components: {},
  props: {
    value: {
      type: Array
    }
  },
  emits: ['input'],
  watch: {
    localValue() {
      this.$emit('input', this.localValue)
    },
    value() {
      if (this.localValue !== this.value) {
        this.localValue = this.value
      }
    }
  },
  data() {
    return {
      localValue: workdays,
      daysOfWeek: [
        {
          label: 'Sun',
          value: 1
        },
        {
          label: 'Mon',
          value: 2
        },
        {
          label: 'Tues',
          value: 3
        },
        {
          label: 'Wed',
          value: 4
        },
        {
          label: 'Thurs',
          value: 5
        },
        {
          label: 'Fri',
          value: 6
        },
        {
          label: 'Sat',
          value: 7
        }
      ],
      settings: {
        workingDays: workdays
      }
    }
  },
  mounted() {
    this.localValue = this.value
  },
  methods: {
    setWorkingDays(day, val) {
      const { value } = day
      if (val) {
        this.localValue.push(val - 1)
        return
      }
      const index = this.localValue.indexOf(value - 1)
      if (index > -1) {
        this.localValue.splice(index, 1)
      }
    }
  }
}
</script>
