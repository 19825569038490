import { ref, nextTick } from 'vue'

export default {
  useLoading() {
    const loadingMessages = ref([])

    const addLoading = (msg) => {
      loadingMessages.value = [...loadingMessages.value, msg]
      return msg
    }
    const removeLoading = async (msg) => {
      await nextTick()
      c.throttle(
        () => {
          const msgs = [...loadingMessages.value]
          const index = msgs.indexOf(msg)
          if (index > -1) msgs.splice(index, 1)
          loadingMessages.value = msgs
        },
        {
          delay: 200,
          key: msg
        }
      )
    }

    return {
      addLoading,
      removeLoading,
      loadingMessages
    }
  }
}
