// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'pipeline',
  
  skipAudit: true,

  fields: {
    pipeline_object_pseudo_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    pipeline_object_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    pipeline_object_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    pipeline_owner: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    manager_user_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    pipeline_time: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    location_ids: {
      type: 'array',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true,
      mapTo: 'location',
    },
    oObject: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: o => o.type,
    },
  },
};
