export function getClientSteps(object, store) {
  let steps = []

  if (object.client_status === 'l') {
    // Stale lead, abandon
    if (object.client_time_created < new Date().valueOf() - 1000 * 60 * 60 * 24 * 7) {
      steps = [
        ...steps,
        {
          name: `This lead is over
                ${_.toNum((new Date().valueOf() - _.toNum(object.client_time_created)) / (1000 * 60 * 60 * 24), 0)}
                days old.`,
          short: 'Abandon lead',
          action: (obj, button, grid) =>
            store.dispatch('Client/markMultiple', {
              markAs: _.format('i', 'status'),
              selected: _.makeArray(obj),
              grid,
              button,
              go: false
            }),
          glyph: 'broom-wide',
          color: 'deep-red-700'
        }
      ]
    } else {
      steps = [
        ...steps,
        {
          name: 'Build an estimate for this lead.',
          short: 'Build estimate',
          action: (obj) =>
            store.dispatch('create', {
              type: 'quote',
              embue: {
                client_id: obj.client_id
              },
              go: true
            }),
          glyph: 'plus',
          color: 'green-500'
        }
      ]
    }
  } else if (object.client_status === 'a') {
    if (!object.client_count_quotes) {
      steps = [
        ...steps,
        {
          name: 'Build your first estimate for this client.',
          short: 'Build estimate',
          action: (obj) =>
            store.dispatch('create', {
              type: 'quote',
              embue: {
                client_id: obj.client_id
              },
              go: true
            }),
          glyph: 'plus',
          color: 'green-500'
        }
      ]
    } else if (
      object.client_count_quotes === object.client_count_quotes_closed ||
      object.client_count_quotes === object.client_count_quotes_declined
    ) {
      steps = [
        ...steps,
        {
          name: 'Deactivate this client since their estimate(s) have been declined or closed.',
          short: 'Set inactive',
          action: (obj, button, grid) =>
            store.dispatch('Client/markMultiple', {
              markAs: _.format('i', 'status'),
              selected: _.makeArray(obj),
              grid,
              button,
              go: false
            }),
          glyph: 'broom-wide',
          color: 'deep-red-700'
        }
      ]
    }

    if (object.client_count_quotes_booked || object.client_count_quotes_in_progress) {
      steps = [
        ...steps,
        {
          name: `View this client's ${object.client_count_quotes_booked + object.client_count_quotes_in_progress} active projects.`,
          short: 'Projects',
          action: (obj, button, grid) =>
            store.dispatch('view', {
              type: 'project',
              filters: {
                client_id: obj.client_id
              },
              filterText: {
                client_id: `${obj.client_name} Projects`
              },
              grid,
              button
            }),
          glyph: 'house-building',
          color: 'surface-600'
        }
      ]
    }

    if (object.client_count_quotes_pending) {
      steps = [
        ...steps,
        {
          name: `View this client's ${object.client_count_quotes_pending} pending estimates.`,
          short: 'Estimates',
          action: (obj, button, grid) =>
            store.dispatch('view', {
              type: 'quote',
              filters: {
                client_id: obj.client_id,
                quote_status: 'p'
              },
              filterText: {
                client_id: `${obj.client_name}`,
                quote_status: 'Pending'
              },
              grid,
              button
            }),
          glyph: 'file-signature',
          color: 'surface-600'
        }
      ]
    }

    if (object.client_count_invoices_outstanding) {
      const pd = object.client_count_invoices_pastdue
      steps = [
        ...steps,
        {
          name: pd
            ? `View this client's ${object.client_count_invoices_pastdue} past-due invoices.`
            : `View this client's ${object.client_count_invoices_outstanding} outstanding invoices.`,
          short: 'Invoices',
          action: (obj, button, grid) =>
            store.dispatch('view', {
              type: 'invoice',
              filters: {
                client_id: obj.client_id
              },
              filterText: {
                client_id: `${obj.client_name}`
              },
              grid,
              button
            }),
          glyph: 'file-invoice-dollar',
          color: 'surface-600'
        }
      ]
    }
  }

  return steps
}
