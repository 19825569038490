import * as types from '../../mutation-types'

const state = {}

const getters = {}

const actions = {
  composeMultiple({ store, dispatch }, payload) {
    const {
      emailId = null,
      templateId = null,
      templateFilters = {},
      button = null,
      type = 'EMAIL',
      canned = true,
      disableGlobalTemplate = false,
      /**
       * Each individual message object passed can have:
       * message_to,
       * message_cc,
       * message_bcc,
       * message_subject,
       * aoFiles,
       * email_id,
       * phone_id,
       * title
       * templateFilters - overrides templateFilters above
       * tags - on each message, not for all
       */
      selected = [],
      tags = {},
      selectTemplateByDefault = true
    } = payload

    return new Promise((resolve, reject) => {
      dispatch(
        'modal/open',
        {
          modal: {
            name: 'MessageNewMultiple',
            tags,
            templateFilters,
            emailId,
            templateData: tags,
            selectTemplateByDefault,
            saved: (messages) => resolve({ ...payload, messages })
            // closed: () => resolve(payload),
          },
          objects: selected
        },
        { root: true }
      )
    })
  },
  compose({ store, dispatch }, payload) {
    const {
      to = null,
      type = 'EMAIL',
      cc = null,
      bcc = null,
      body = null,
      subject = null,
      emailId = null,
      templateId = null,
      attachments = [],
      canned = false,
      tags = {},
      go = true
    } = payload

    return dispatch(
      'modal/open',
      {
        modal: {
          name: 'MessageNew',
          tags,
          templateFilters: {
            template_type_id: 13
          },
          templateData: tags,
          canned
        },
        go,
        objects: [
          {
            message_type: String(type).toUpperCase(),
            message_to: to,
            message_cc: cc,
            message_bcc: bcc,
            message_subject: subject,
            message_body: body,
            email_id: emailId,
            template_id: templateId,
            aoFiles: attachments
          }
        ]
      },
      { root: true }
    )
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
