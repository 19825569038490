import ObjectSimple from './ObjectSimple'
import _ from '../../../imports/api/Helpers'
import Changes from '../../../imports/api/Changes/Changes'
import eventBus from '../../eventBus'

/**
 * This is used with objects that utilize
 * the vuex global state, ie: quote;
 * It allows for change tracking, auditing etc.
 *
 * This is one that will only commit to vuex state
 * manually by calling this.commit();
 * Otherwise changes ONLY accumulate in this.object.
 * This speeds up use when changing many fields, by making
 * changes on the whole normalized model only once, for example
 * when the component is destroyed. That also means that only
 * local auditing is required (cascadeDependencies, see AuditAsync.js).
 *
 * For this object manipulator mixin to work the object must already be selected
 * and normalized and in vuex.
 *
 * Requirements:
 *    -'refId': prop must be passed
 *    -'store': prop must be passed
 *
 *  -Lifecycle functions:
 *    (can be overriden in the component htis mixin is added to, put these
 *      functions in methods: {})
 *    -beforeSave() - called before saves
 *    -afterSave() - called after saves
 *    -beforeSelect() - called before selecting
 *    -afterSelect() - called after selection is complete
 *
 *  Emits:
 *    -reload
 *    -reloaded
 *    -saving
 *    -saved
 *    -select
 *    -selected
 *    -fetched
 */
export default function generate(type, trackChanges = false, storeName = c.titleCase(type)) {
  const objSimple = ObjectSimple(type, trackChanges, storeName)
  const constructor = c.getConstructor(type)
  const allFields = Object.keys(constructor.fields)

  const equationFields = allFields.filter((f) => constructor.fields[f].type === 'float')

  const mappedFields = allFields.filter(
    (f) =>
      c.shouldMapField(f, constructor) ||
      (c.isJsonField(f) && /array|object/.test(constructor.fields[f].type))
  )

  const idFields = allFields.filter(
    (f) => /_id$/.test(f) && constructor.fields[f].mapTo && f !== `${type}_id`
  )

  return {
    name: `ObjectDistinct-${type}`,

    mixins: [objSimple],

    props: {
      /**
       * If set, this becomes a subcomponent and it will NOT commit before destruction.
       * The root/parent item MUST commit the changes which will be passed
       * up via subComponentInterface
       */
      value: {
        default: null
      },

      /**
       * 0 is the default, which means the default behaviour
       * is to not commit unless destroyed. Passes up changes
       * to the subcomponent api process regardless.
       */
      commitInterval: {
        default: 0
      }
    },
    emits: ['normChanged', 'input', 'selecting', 'selected'],

    /**
     * Commit before destroying
     * @returns {boolean}
     */
    async beforeUnmount() {
      // const immediate = this.explicitFields
      //   .reduce((acc, field) => ({
      //     ...acc,
      //     [field]: this.getField(field),
      //   }), {});
      if (this.value) return true // if this is a subcomponent, do not commit

      this.releaseChanges()

      return true
    },

    watch: {
      // Override this from objectSimple, becuase
      // in objectSimple it calls a commit. We should
      // never commit except when called explicitly
      // or on destroy
      objectLocal() {},
      value(values) {
        this.integrateSubComponentChanges(values)
      },
      norm() {
        // Only integrate chagnes if this isn't a
        // sub component interface connected component
        // because it will automatically have changes integrated
        if (!this.value) {
          this.integrateChanges()
          this.$emit('normChanged')
          eventBus.$emit(`${this.uid}-normChanged`)
        }
      },
      uncommittedFields(f) {
        if (this.commitInterval < 1) return
        if (f.length) c.throttle(() => this.releaseChanges(), this.commitInterval)
      }
    },

    data() {
      return {
        distinctChangeManager: null
      }
    },

    computed: {
      subComponentInterface: {
        get() {
          return [
            this.objectLocal,
            this.explicitFields,
            this.unauditedFields,
            this.uncommittedFields
          ]
        },
        set(changeArray) {
          this.integrateSubComponentChanges(changeArray)

          this.emitSubComponentInterface()
        }
      },
      // Add a mechanism to change the parent, especially for deeply embeedded hierarchies,
      //  like quote->assemblies->cost item relationships.
      parentRefId: {
        get() {
          return this.getField('parentRefId')
        },
        set(val) {
          this.setField('parentRefId', val)
        }
      },

      // Map fields so there is a computed property for each field
      ...allFields.reduce(
        (acc, fieldName) => ({
          ...acc,
          [fieldName]: {
            get() {
              return this.objectLocal[fieldName]
            },
            set(v) {
              this.setField(fieldName, v)
            }
          }
        }),
        {}
      ),

      ...mappedFields.reduce(
        (acc, fieldName) => ({
          ...acc,
          [_.hungarianToCamelCase(fieldName)]: {
            get() {
              return _.imm(this.objectLocal[fieldName])
            },
            set(v) {
              this.setField(fieldName, v)
            }
          }
        }),
        {}
      ),

      ...equationFields.reduce(
        (acc, fieldName) => ({
          ...acc,
          [`${fieldName}_equation`]: {
            get() {
              return (this.objectLocal.oEquations && this.objectLocal.oEquations[fieldName]) || null
            },
            set(v) {
              if (v === null) this.unsetEquation(fieldName)
              else this.setEquation(fieldName, v)
            }
          }
        }),
        {}
      ),

      ...idFields.reduce(
        (acc, fieldName) => ({
          ...acc,
          [c.camelCase(fieldName)]: {
            get() {
              return this.objectLocal[fieldName]
            },
            set(v) {
              this.setIdField(fieldName, v)
            }
          }
        }),
        {}
      ),

      /**
       * Override from ChangetTracking.js so that it is local
       * @returns {Changes}
       */
      changeManager() {
        return this.distinctChangeManager
      }
    },

    methods: {
      emitSubComponentInterface() {
        if (this.value && this.value.length) {
          this.committing = 1
          this.emitChanging()

          this.$emit('input', [
            this.objectLocal,
            this.explicitFields,
            this.unauditedFields,
            this.uncommittedFields,
            this.getUncommittedChanges(),
            this.getLocalExplicitChanges()
          ])

          this.uncommittedFields = []
          this.explicitFields = []
          this.unauditedFields = []

          this.emitChanged()
          this.committing = 0
        }
      },
      releaseChanges() {
        const uncommittedChanges = this.getUncommittedChanges()
        const localExplicitChanges = this.getLocalExplicitChanges()

        this.commit(uncommittedChanges, localExplicitChanges, true, true)
      },
      integrateSubComponentChanges(api) {
        if (!Array.isArray(api) || api.length < 4) return
        const [objectLocal, explicitFields, unauditedFields, uncommittedFields] = api

        this.objectLocal = {
          ...this.objectLocal,
          ...objectLocal
        }

        this.explicitFields = _.uniq([...this.explicitFields, ...explicitFields])

        this.unauditedFields = _.uniq([...this.unauditedFields, ...unauditedFields])

        this.uncommittedFields = _.uniq([...this.uncommittedFields, ...uncommittedFields])
      },

      /**
       * Overrides objectSimple select
       * @param embue
       * @returns {Promise<methods>}
       */
      async select(embue = {}) {
        this.beforeSelect()
        this.addLoading()

        this.$emit('selecting')
        eventBus.$emit(`${this.uid}-selecting`)
        eventBus.$emit(`selecting-${this.type}-${this.refId}`)
        if (this.value) {
          this.integrateSubComponentChanges(this.value)
        } else {
          this.objectLocal = { ...this.norm[this.refId], ...embue }
        }
        // this.audit();
        this.selected = 1

        this.endLoading()
        this.endBodyLoading()

        this.$nextTick(() => {
          this.$emit('selected')
          eventBus.$emit(`${this.uid}-selected`)
          eventBus.$emit(`selected-${this.type}-${this.refId}`)
          this.afterSelect()
          if (this.trackChanges) this.startChangeTracking()
        })

        return this
      },

      /**
       * Overriding from ChangeTracking.js,  so change manager is all localized
       * @param startFromScratch
       * @returns {methods}
       */
      buildChangeWatcher(startFromScratch = true) {
        if (!this.enableChangeWatching) {
          return this
        }

        this.distinctChangeManager = new Changes(
          { [this.refId]: this.objectLocal },
          { [this.refId]: this.objectLocal },
          this.refId
        )

        this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
          refId: this.rootRefId,
          callback: this.throttledChangeChecker
        })

        // only reset if this refId === rootRefId
        this.resetChanges(startFromScratch)

        this.watchingChanges = true

        this.changeManager.watch(this.throttledChangeChecker)

        // Call it for the first time
        this.throttledChangeChecker()

        return this
      },

      /**
       * Cannot selet blank
       */
      selectBlank() {
        console.error('You cannot select blank for an ObjectDistinct mixin component')
        return this
      },

      /**
       * Must override orignal to reflect a fluid local object
       * @returns {{[p: string]: *}}
       */
      cast() {
        return this.objectLocal
      },

      /**
       * The primary difference between this fieldDispatch, and the parent,
       * ObjectSimple.dispatch, is that this one does not commit automatically after
       * fieldDispatch. Commit() needs to be called explicitly or wait until destruction.
       *
       * @param allChanges
       * @param explicitChanges
       * @param audit
       * @returns {methods}
       */
      fieldDispatch(allChanges, explicitChanges = null, audit = true) {
        const fieldChanges = Object.keys(allChanges)

        const combined = _.imm({
          ...this.objectLocal,
          ...allChanges
        })
        this.objectLocal = combined

        this.explicitFields = [
          ...this.explicitFields,
          ...Object.keys(explicitChanges || allChanges || {})
        ]

        this.addExplicitChanges(explicitChanges)
        this.addChanges(allChanges)

        this.unauditedFields = _.uniq([...this.unauditedFields, ...fieldChanges])

        this.uncommittedFields = _.uniq([...this.uncommittedFields, ...fieldChanges])

        if (this.selected) {
          this.emitSubComponentInterface()
        }

        if (
          audit &&
          Object.keys(allChanges).some(
            (field) =>
              field in constructor.fields &&
              (constructor.fields[field].trackChanges ||
                typeof constructor.fields[field].trackChanges === 'undefined')
          )
        ) {
          this.triggerAudit(allChanges)
        }
        return this
      },

      addChanges(changes) {
        if (!this.changeManager || changes === null) {
          return
        }

        this.changeManager.addChanges({
          [this.refId]: changes
        })
      },

      addExplicitChanges(changes) {
        if (!this.changeManager || changes === null) {
          return
        }

        this.changeManager.addExplicitChanges({
          [this.refId]: changes
        })
      }
    }
  }
}
