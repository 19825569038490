<template>
  <section>
    <div class="mb-1">
      Working hours
      <Help>
        <template #title> Working hours </template>
        <p>
          Set your working hours to be used for the schedule. These hours will represent the amount
          of hours that can be completed in a day.
        </p>
      </Help>
    </div>
    <span class="flex items-center">
      from
      <Select v-model="localValue.start" :options="hours"> </Select>
      to
      <Select v-model="localValue.end" :options="hours"> </Select>
    </span>
  </section>
</template>
<script>
import Select from '@/components/ui/fields/Select.vue'

export default {
  name: 'WorkingHours',
  components: {
    Select
  },
  props: {
    value: {
      type: Object
    }
  },
  emits: ['input'],
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit('input', this.localValue)
      }
    },
    value() {
      if (this.localValue !== this.value) {
        this.localValue = this.value
      }
    }
  },
  data() {
    return {
      localValue: {
        start: 8,
        end: 17
      },
      hours: [
        {
          text: '1:00 AM',
          value: 1
        },
        {
          text: '2:00 AM',
          value: 2
        },
        {
          text: '3:00 AM',
          value: 3
        },
        {
          text: '4:00 AM',
          value: 4
        },
        {
          text: '5:00 AM',
          value: 5
        },
        {
          text: '6:00 AM',
          value: 6
        },
        {
          text: '7:00 AM',
          value: 7
        },
        {
          text: '8:00 AM',
          value: 8
        },
        {
          text: '9:00 AM',
          value: 9
        },
        {
          text: '10:00 AM',
          value: 10
        },
        {
          text: '11:00 AM',
          value: 11
        },
        {
          text: '12:00 PM',
          value: 12
        },
        {
          text: '1:00 PM',
          value: 13
        },
        {
          text: '2:00 PM',
          value: 14
        },
        {
          text: '3:00 PM',
          value: 15
        },
        {
          text: '4:00 PM',
          value: 16
        },
        {
          text: '5:00 PM',
          value: 17
        },
        {
          text: '6:00 PM',
          value: 18
        },
        {
          text: '7:00 PM',
          value: 19
        },
        {
          text: '8:00 PM',
          value: 20
        },
        {
          text: '9:00 PM',
          value: 21
        },
        {
          text: '10:00 PM',
          value: 22
        },
        {
          text: '11:00 PM',
          value: 23
        },
        {
          text: '12:00 AM',
          value: 24
        }
      ]
    }
  },
  created() {
    this.localValue = this.value
  }
}
</script>
