<template>
  <btn-group :class="componentClass" class="">
    <Choose
      style="max-width: 6em"
      v-if="!countryCodeLocal || showCountrySelector"
      schema="country:country_calling_code"
      :formatter="formatter"
      :allow-deselect="false"
      :return-array="false"
      btn-class="nowrap"
      :order="[['country_name', 'ASC']]"
      v-model="countryCodeLocal"
    />
    <Choose
      style="max-width: 6em"
      v-else-if="showCountryButton"
      schema="country:country_calling_code"
      :formatter="formatter"
      :allow-deselect="false"
      :return-array="false"
      btn-class="nowrap rounded"
      class="flex justify-center items-center cursor-pointer rounded transition bg-surface-200 hover:bg-blue-print-100"
      :order="[['country_name', 'ASC']]"
      v-model="countryCodeLocal"
    >
      <div>
        <Flag v-if="countryCodeLocal" size="s" :code="flagCountryCode" class="mr-1" />
        +{{ countryDialingCode }}
      </div>
    </Choose>

    <input
      type="text"
      class="phone-input"
      :class="[...inputClass]"
      :placeholder="placeholder"
      v-tooltip="inputTooltip"
      :disabled="!countryCodeLocal || !!disabled"
      @change="changeHandler"
      @dblclick.stop="dblclickHandler"
      @touchstart.stop="touchstartHandler"
      @click.stop="clickHandler"
      @keyup="checkDeleteKeyupHandler"
      @keypress="keypressHandler"
      @focusin="focusinHandler"
      @focusout="focusoutHandler"
      v-model="formattedNational"
    />
  </btn-group>
</template>

<script>
import { Flag } from 'vue-flagpack'
import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
  parsePhoneNumberFromString,
  // isValidPhoneNumber,
  getCountryCallingCode,
  getExampleNumber
} from 'libphonenumber-js/max'
import examples from 'libphonenumber-js/examples.mobile.json'
import Field from '../../mixins/Field'

export default {
  name: 'PhoneField',
  components: {
    Flag
  },
  mixins: [Field],

  data() {
    const formatter = (obj) => ({
      uid: obj.uid || `${obj.text}.${obj.value}.${_.uniqueId()}`,
      text: `${obj.country_name} +${obj.country_calling_code}`,
      html: `${obj.country_name} +${obj.country_calling_code}`,
      value: (obj.country_abbr || '').toUpperCase()
    })

    return {
      formatter,
      countryCodeLocal: this.getCountryCode(
        String(this.countryCode || '').toUpperCase() ||
          (this.$store.state.session.user &&
            (this.$store.state.session.user.country_abbr || '').toUpperCase())
      ),
      nationalNumber: '',
      latestResults: {}
    }
  },

  watch: {
    countryCode(val) {
      this.countryCodeLocal = val.toUpperCase()
    },
    validationMessagePhone(msg) {
      this.validationMessage = msg
    }
  },

  computed: {
    flagCountryCode() {
      const upper = (this.getCountryCode() || '').toUpperCase()
      if (upper === 'GB') {
        return 'GB-UKM'
      }

      return upper
    },
    countryDialingCode() {
      const code = this.getCountryCode()
      return getCountryCallingCode(code)
    },
    isNumericField() {
      return false
    },
    validationMessagePhone() {
      if (this.valid) return ''

      if (!this.countryCodeLocal) return 'Choose a country'

      return `Valid phone number required (${getExampleNumber(this.getCountryCode(), examples).formatNational()})`
    },
    valid() {
      if (!this.countryCodeLocal) return false

      if (!this.required && !this.notEmpty) return true

      if (this.required && !this.notEmpty) return false

      return this.latestResults.isValid
    },
    formatRequired() {
      return 'e.164'
    },
    fieldFormat() {
      return 'e.164'
    },
    validFormat() {
      return this.latestResults.isValid
    },
    required() {
      return this.validate && this.validate.required
    },

    formattedNational: {
      get() {
        return formatIncompletePhoneNumber(this.nationalNumber || '', this.getCountryCode())
      },
      set(val) {
        this.nationalNumber = parseIncompletePhoneNumber(String(val))
        const result = this.getParsePhoneNumberFromString({
          phoneNumber: String(val),
          countryCode: this.getCountryCode()
        })
        this.emitResults(result)
      }
    }
  },

  methods: {
    getCountryCode(preset = null) {
      const orig = preset || this.countryCodeLocal
      const code = orig === 'UK' ? 'GB' : orig

      return code
    },
    checkDeleteKeyupHandler(...args) {
      this.keyupHandler(...args)

      const key = args[0].which
      const pattern = /\(([^)]+)\)/
      const match = this.formattedNational.match(pattern)
      if (key === 8 && match && match.input === match[0]) {
        this.formattedNational = ''
      }
    },
    importValue(val = this.value) {
      if (this.focused && (this.startedTypingSinceEmit || this.startedTypingSinceFocus)) {
        return
      }

      const resultIncoming = this.getParsePhoneNumberFromString({
        phoneNumber: String(val),
        countryCode: this.getCountryCode()
      })
      const resultExisting = this.getParsePhoneNumberFromString({
        phoneNumber: String(this.nationalNumber),
        countryCode: this.getCountryCode()
      })

      if (resultExisting.nationalNumber === resultIncoming.nationalNumber) {
        return
      }

      this.nationalNumber = resultIncoming.nationalNumber

      if (this.focused && !this.startedTypingSinceFocus && this.shouldAutoSelectOnFocus) {
        this.selectAll()
      }
    },

    emitResults(result) {
      this.latestResults = result
      this.setRawValue(result.e164)
    },

    getAsYouTypeFormat(payload) {
      const { countryCode, phoneNumber } = payload
      return phoneNumber ? formatIncompletePhoneNumber(phoneNumber, countryCode) : null
    },

    getParsePhoneNumberFromString({ phoneNumber, countryCode }) {
      const parsing =
        phoneNumber && countryCode ? parsePhoneNumberFromString(phoneNumber, countryCode) : null

      return {
        countryCode,
        isValid: false,
        ...(phoneNumber && phoneNumber !== '' ? { phoneNumber } : null),
        ...(parsing
          ? {
              countryCallingCode: parsing.countryCallingCode,
              formattedNumber: parsing.number,
              nationalNumber: parsing.nationalNumber,
              isValid: parsing.isValid(),
              type: parsing.getType(),
              formatInternational: parsing.formatInternational(),
              formatNational: parsing.formatNational(),
              uri: parsing.getURI(),
              e164: parsing.format('E.164')
            }
          : null)
      }
    }
  },

  props: {
    placeholder: {
      default: 'Phone Number'
    },

    value: {
      required: true,
      default: ''
    },

    current: {
      type: String,
      default: ''
    },

    countryCode: {
      default: null
    },

    showCountryButton: {
      default: true
    },

    showCountrySelector: {
      default: false
    },

    validate: {
      default: null
    },

    disabled: {
      default: false
    }
  },
  created() {
    if (this.current) this.importValue(this.current)
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.choose--trigger-container {
  width: auto !important;
  height: 100% !important;
}

.pill-group--container > *.choose--container [btn-component].button--container.choose--btn {
  height: 100% !important;
  max-height: 100% !important;
}

.phone-field--container {
  min-width: 50%;
  padding-left: 1em !important;
  overflow: visible !important;

  .choose--container {
    flex: 1 15em;
    .button--container {
      .button--text {
        font-size: 0.8em !important;
        white-space: nowrap !important;
      }
    }
  }

  .phone-input {
    background: $cool-gray-200;
    color: $cool-gray-800;
    text-align: center;
  }
}
</style>
