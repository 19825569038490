import eventBus from '@/eventBus'
import { mapState } from 'pinia'
import { useDeviceStore } from '@/stores/device'

/**
 * For use in input-like components
 *
 * Emits
 *  -input
 *  -change
 *  -formatted
 *  -submit
 *  -keypress
 *  -focus
 *  -blur
 *
 *  -invalidRequired
 *  -invalidType
 *  -valid
 *  -noValidation
 *
 *  Uses classes: (if implemented)
 *    -field (all)
 *    -validate-valid (for valid field entries)
 *    -validate-invalid (for invalid field entries)
 */
export default {
  data() {
    const objectType = this.schema ? this.schema.split(':')[0] : null
    const fieldName = this.schema ? this.schema.split(':')[1] : null
    let fieldSchema = null
    if (objectType && fieldName) {
      const constructor = c.getConstructor(objectType)
      if (constructor && constructor.fields && constructor.fields[fieldName]) {
        fieldSchema = constructor.fields[fieldName]
      }
    }

    // Get format
    let formatValue = this.format
    if (typeof this.format !== 'string' && this.format) {
      if (fieldSchema && fieldSchema.format) {
        formatValue = fieldSchema.format
      } else {
        formatValue = false
      }
    }

    const validationSchema = {
      required: false,
      type: null,
      ...(fieldSchema ? fieldSchema.validate || {} : {}),
      ...(this.validation || this.validate || {})
    }
    const rootObjectType = fieldSchema && fieldSchema.mapTo ? fieldSchema.mapTo : objectType
    const howToOutputValue =
      this.outputOn ||
      ((!formatValue || formatValue === 'number' || formatValue === 'currency') &&
      !this.throttleOutput
        ? 'input'
        : 'change')

    let parentLevels = 0
    const getValidationParent = (component) => {
      parentLevels += 1
      if (
        parentLevels <= 3 ||
        (component &&
          component._isVue &&
          'validate' in component &&
          'attemptedValidation' in component)
      ) {
        return component
      } else if (parentLevels < 3 && component.$parent && component.$parent._isVue) {
        return getValidationParent(component.$parent)
      }
      return false
    }
    const validationParent = getValidationParent(this)
    return {
      oops: 1,
      focusIsIn: 0,
      hasFocused: 0,
      validationMessage: '',
      objectType,
      fieldName,
      fieldSchema,
      formatValue,
      validationSchema,
      validationParent,
      rootObjectType,
      howToOutputValue,
      uid: `${_.uniqueId()}-${new Date().valueOf()}`,
      label: null,
      rawValue: this.value,
      clickToSelectLocal:
        typeof this.clickToSelect === 'undefined'
          ? matchMedia('(hover: none)').matches
          : this.clickToSelect,
      hasTypedSinceLastFocus: 0,
      lastEmitted: null,
      emitting: false,
      destroying: false,
      isSelectAll: false
    }
  },
  watch: {
    validationSchema() {
      c.throttle(this.checkValidity, { key: this.uid })
    },
    validation(v) {
      this.validationSchema = {
        required: false,
        type: null,
        ...(this.fieldSchema ? this.fieldSchema.validate || {} : {}),
        ...((this.validation === true ? { required: true } : this.validation) ||
          (this.validate === true ? { required: true } : v) ||
          {})
      }
      c.throttle(this.checkValidity, { key: this.uid })
    },
    validate(v) {
      this.validationSchema = {
        required: false,
        type: null,
        ...(this.fieldSchema ? this.fieldSchema.validate || {} : {}),
        ...((this.validation === true ? { required: true } : this.validation) ||
          (this.validate === true ? { required: true } : v) ||
          {})
      }
      c.throttle(this.checkValidity, { key: this.uid })
    },
    value(val) {
      if (!this.destroying) {
        this.valueChanged(val)
      }
    }
  },
  computed: {
    isPercentage() {
      return this.formatValue && /percent|%|percentage/.test(this.formatValue)
    },
    isValid() {
      return this.checkValidity()
    },
    attemptedSubmit() {
      return false /* this.validationParent
        ? this.validationParent.attemptedValidation
        : false; */
    },
    classes() {
      const valid = this.isValid
      const primaryClass = 'field'
      const requiredClass =
        this.validationSchema.required && c.isempty(this.value) ? 'validate-required' : ''
      let validClass
      const submitted = this.attemptedSubmit
      const focused = this.hasFocused
      const opinionated = submitted || focused
      if (this.validationSchema.type || this.validationSchema.required) {
        if (valid && opinionated) {
          validClass = 'validate-valid'
        } else if (opinionated) {
          validClass = 'validate-invalid'
        }
      }
      return [primaryClass, requiredClass, validClass]
    },
    formattedValue() {
      /* let valueToUse;
      if (this.checkEquals && c.eq(this.rawValue, this.value, 8)) {
        valueToUse = this.rawValue;
      } else {
        valueToUse =
      } */
      if (this.formatValue) {
        return `${c.format(this.rawValue, this.isPercentage ? 'number' : this.formatValue) || ''}${this.isPercentage ? '%' : ''}`
      }
      return this.rawValue
    },
    checkEquals() {
      return /currency|percentage|percent|%|number|int|float|hour|hours/.test(
        `${this.formatValue},${this.fieldSchema && this.fieldSchema.type ? this.fieldSchema.type : ''}`
      )
    },
    fieldValue: {
      get() {
        // _.log(' getting: rawValue: ', this.rawValue);
        if (this.isSelectAll) {
          setTimeout(() => this.selectAll())
        }
        return this.focusIsIn ? this.rawValue : this.formattedValue
        /*
         let val;
         if (!this.focusIsIn && this.checkEquals) {
         val = c.eq(this.rawValue, this.value) ? this.rawValue : this.value;
         }
         if (!this.focusIsIn && this.formatValue) {
         return c.format(val, this.formatValue);
         }
         return val; */
        /* if ((this.checkEquals && c.eq(this.rawValue, this.value, 8))
          || (!this.checkEquals && String(this.value) === String(this.rawValue))) {
          return this.focusIsIn ? this.rawValue : this.formattedValue;
        }
        c.throttle(() => {
          if ((this.checkEquals && c.eq(this.rawValue, this.value, 8))
            || (!this.checkEquals && String(this.value) === String(this.rawValue))) {
            this.rawValue = this.value;
          }
        });
        return this.focusIsIn ? this.rawValue || this.value : this.formattedValue; */
      },
      set(val) {
        this.isSelectAll = false
        this.hasTypedSinceLastFocus = 1
        this.rawValue = val
        this.$emit('raw', val)
        this.emit()
      },
      ...mapState(useDeviceStore, ['isMobileBrowser'])
    }
  },
  methods: {
    async selectAll() {
      await this.$nextTick()

      if (!this.$refs.input) {
        return false
      }

      this.isSelectAll = true

      if (typeof c.makeArray(this.$refs.input)[0].selectAll === 'function') {
        c.makeArray(this.$refs.input)[0].selectAll()
      } else if (typeof c.makeArray(this.$refs.input)[0].select === 'function') {
        c.makeArray(this.$refs.input)[0].select()
      }

      return true
    },
    valueChanged(val) {
      if (!this.focusIsIn || !this.hasTypedSinceLastFocus) {
        this.rawValue = val
        if ((this.checkEquals && !c.eq(val, this.rawValue, 10)) || !this.checkEquals) {
          this.rawValue = val
        }
      } else {
        this.queueCheckOnBlur()
      }
    },

    /**
     * Emit, but on a throttle
     * @param value
     * @param formattedValue
     * @param equationValue
     */
    emit(
      value = this.getEmitValue(this.rawValue),
      formattedValue = this.getFormatValue(this.rawValue),
      equationValue = null
    ) {
      this.emitting = value

      c.throttle(
        () => {
          // If we are still in the same paradigm,
          // then emit
          if (this.emitting === value) {
            // if still the same
            this.emitImmediately(false, value, formattedValue, equationValue)
          }
        },
        { delay: this.emitDelay, key: this.uid, debounce: true }
      )
    },

    /**
     * Emit immediately without throttle
     * @param destroying
     * @param value
     * @param formattedValue
     * @param equationValue
     */
    emitImmediately(
      destroying = false,
      inputValue = this.getEmitValue(this.rawValue),
      formattedValue = this.getFormatValue(this.rawValue),
      equationValue = null
    ) {
      const emitEquation = equationValue !== this.equation
      const equationToEmit = emitEquation ? equationValue : this.equation

      if (
        inputValue !== this.value &&
        inputValue !== this.lastEmitted &&
        (!this.checkEquals ||
          (!c.eq(+inputValue, +this.value) && !c.eq(inputValue, this.lastEmitted)))
      ) {
        this.lastEmitted = inputValue

        if (!destroying) {
          c.throttle(
            () => {
              this.lastEmitted = null
            },
            { key: this.uid, delay: 1500 }
          )
        }

        this.$emit('input', inputValue, equationToEmit)
        this.$emit('formatted', formattedValue)
      }

      if (emitEquation) {
        this.$emit('equation', equationToEmit)
      }

      this.emitting = false
    },
    getEmitValue(val) {
      if (this.formatValue) {
        return c.deformat(val, this.formatValue)
      }
      return val
    },
    getFormatValue(val) {
      if (this.formatValue) {
        return c.format(val, this.formatValue)
      }
      return val
    },
    click() {
      if (this.isMobileBrowser) return

      if (this.type === 'textarea') {
        return
      }

      if (this.clickToSelectLocal && !this.focusIsIn) {
        this.selectAll()
      } else if (!this.focusIsIn) {
        // If for some reason is not focusing, force focus
        this.focus()
        this.selectAll()
      } else {
        this.isSelectAll = false
      }
    },
    dblclick() {
      setTimeout(() => {
        this.selectAll()
      }, 500)
    },
    checkValidity() {
      const val = this.value
      if (
        !this.validationSchema ||
        ((!this.validationSchema.type || this.validationSchema.type === null) &&
          !this.validationSchema.required)
      ) {
        this.$emit('noValidation', {
          ...(this.validationSchema || {}),
          value: val,
          hasFocused: this.hasFocused
        })
        return true
      }

      const valType = this.validationSchema.type
      const countryId = parseInt(this.$store.state.session.company.country_id, 10)
      const countryAbbr = countryId && _.countries[countryId]
      const valRegex =
        (valType && countryAbbr && c.validations[`${valType}_${countryAbbr}`]) ||
        (valType && c.validations[valType]) ||
        false

      if (this.validationSchema.required && c.isempty(val)) {
        this.$emit('invalidRequired', {
          ...this.validationSchema,
          value: val,
          hasFocused: this.hasFocused
        })
        this.$emit('invalid', { ...this.validationSchema, value: val, hasFocused: this.hasFocused })
        return false
      } else if (
        val &&
        val !== null &&
        val !== '' &&
        valRegex &&
        valRegex.test &&
        !valRegex.test.test(val) &&
        !valRegex.test.test(c.deformat(val, this.formatValue))
      ) {
        this.$emit('invalidType', {
          ...this.validationSchema,
          value: val,
          hasFocused: this.hasFocused
        })
        this.$emit('invalid', { ...this.validationSchema, value: val, hasFocused: this.hasFocused })
        return false
      }

      if (val && val !== null && val !== '') {
        this.$emit('valid', { ...this.validationSchema, value: val, hasFocused: this.hasFocused })
        return true
      }

      this.$emit('noValidation', {
        ...this.validationSchema,
        value: val,
        hasFocused: this.hasFocused
      })
      return true
    },
    queueCheckOnBlur() {
      eventBus.$once('blur', this.blurCheck)
    },
    blurCheck() {
      if (!this.checkEquals || (this.checkEquals && !c.eq(this.value, this.rawValue, 10))) {
        this.rawValue = this.value
      }
    },
    log(...message) {
      if (this.debug) _.log(...message)
    },
    change(...args) {
      this.isSelectAll = false
      this.$emit.apply(this, ['change', ...args])
    },
    hasFocus(...args) {
      if (this.clickToSelectLocal && !this.focusIsIn) {
        // if (this.$refs.input && !c.isMobile()) this.$nextTick(() => this.$refs.input.select());
      }
      setTimeout(() => {
        this.focusIsIn = 1
        this.hasFocused = 1
        if (this.type === 'textarea') this.resizeTextarea()
        this.$emit.apply(this, ['focus', ...args])
        this.$emit.apply(this, ['focusin', ...args])
      }, 100)
    },
    focus() {
      this.$nextTick(() => {
        if (this.$refs.input && !this.focusIsin && c.makeArray(this.$refs.input).length) {
          c.makeArray(this.$refs.input)[0].focus()
        }
      })
    },
    blur(...args) {
      this.isSelectAll = false
      this.hasTypedSinceLastFocus = 0
      if (this.focusIsIn && !this.emitting) {
        this.emitImmediately()
      }
      this.focusIsIn = 0
      this.$emit.apply(this, ['blur', ...args])
      this.$emit.apply(this, ['focusout', ...args])
    },
    keyup(...args) {
      this.isSelectAll = false
      if (args[0].which === 13) {
        this.$emit.apply(this, ['submit', ...args])
        this.$emit.apply(this, ['enter', ...args])
        this.blur(...args)
      } else if (args[0].which === 27) {
        this.$emit.apply(this, ['cancel', ...args])
      }
      this.$emit.apply(this, ['keyup', ...args])
    },
    keypress(...args) {
      this.isSelectAll = false
      this.$emit.apply(this, ['keypress', ...args])
      return true
    }
  },
  mounted() {
    if (
      this.default &&
      (!this.value || this.value === '0' || String(this.value).toLowerCase() === 'null')
    ) {
      this.fieldValue = this.default
    }
    this.$nextTick(() => {
      this.checkValidity()
    })
  },
  async beforeUnmount() {
    this.destroying = true
    eventBus.$off('blur', this.blurCheck)
    this.emitImmediately(true)

    await this.$nextTick()

    return this
  },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    emitDelay: {
      type: Number,
      default: 1000
    },
    debug: {
      default: false
    },
    /**
     * Set a default value.
     *   If the value provided is empty,
     *   the value will be set to this default;
     */
    default: {
      required: false
    },
    /**
     * Display a formatted value
     * ie: 4039999883 => (403) 999-9883
     */
    format: {
      type: [Boolean, String],
      default: true
    },
    /**
     * A path to a field schema
     * ie: user:user_email or cost_type:cost_type_name
     */
    schema: {
      type: String,
      required: false
    },
    /**
     * Set if a unique value is required
     * like when creating a new client, the user_email
     * must always be unique.
     */
    unique: {
      type: Boolean,
      default: false
    },
    /**
     * Override the validation object
     * ie:
     * {
     *    type: 'email',
     *    required: false,
     * }
     */
    validation: {
      type: [Object, Boolean],
      required: false,
      default: false
    },
    validate: {
      type: [Object, Boolean],
      required: false,
      default: false
    },
    /**
     * input or change
     */
    outputOn: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    throttleOutput: {
      default: false
    },
    clickToSelect: {
      type: Boolean,
      default: undefined
    }
  },
  emits: [
    'raw',
    'input',
    'formatted',
    'equation',
    'noValidation',
    'invalidRequired',
    'invalid',
    'invalidType',
    'valid'
  ]
}
