<template>
  <div class="hidden md:block">
    <Carousel
      :value="images"
      :numVisible="3"
      :numScroll="1"
      :responsive-options="responsiveOptions"
      circular
    >
      <template #item="slotProps">
        <div class="flex items-center justify-center rounded my-1 mx-2 md:h-72 w-52 md:w-full h-52">
          <Image
            preview
            :src="slotProps.data"
            image-class="object-cover cursor-pointer w-full h-52 md:min-h-72 lg:h-full"
          />
        </div>
      </template>
    </Carousel>
  </div>
  <div class="md:hidden">
    <Galleria :images="images" :numVisible="4" thumbnailsPosition="bottom" />
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Carousel from 'primevue/carousel'
import Image from 'primevue/image'
import Galleria from '@/components/ui/Gallery/Galleria.vue'

const props = defineProps({
  images: {
    type: Array,
    required: true
  }
})

const images = ref(props.images)

const responsiveOptions = ref([
  {
    breakpoint: '1400px',
    numVisible: 3,
    numScroll: 1
  },
  {
    breakpoint: '1199px',
    numVisible: 3,
    numScroll: 1
  },
  {
    breakpoint: '767px',
    numVisible: 2,
    numScroll: 1
  },
  {
    breakpoint: '575px',
    numVisible: 1,
    numScroll: 1
  }
])
</script>
