import Pako from 'pako'
import ButtonMixin from './Button'
import UserMetaMixin from './UserMeta'
import ChangeTracking from './ChangeTracking'
import BodyMixin from './Body'
import NameMixin from './Name'
import _ from '../../../imports/api/Helpers'
import * as types from '../../store/mutation-types'
import Parallel from '../../../imports/api/Parallel'
import eventBus from '../../eventBus'

/**
 * This is used with objects that utilize
 * the vuex global state, ie: quote;
 * It allows for change tracking, auditing etc.
 *
 * It has NO computed fields...
 *
 * Requirements:
 *
 *    -'id': either the id prop must be passed, or the object prop.
 *      the id is the id of the object, which will be fetched
 *      on mount.
 *    -'object': alternatively the object prop can be provided which
 *      represents the prefetched object, which negates our requirement
 *      to fetch the object again.
 *    -'refId'
 *
 *  -Lifecycle functions:
 *    (can be overriden in the component htis mixin is added to, put these
 *      functions in methods: {})
 *    -beforeSave() - called before saves
 *    -afterSave() - called after saves
 *    -beforeSelect() - called before selecting
 *    -afterSelect() - called after selection is complete
 *
 *  Emits:
 *    -reload
 *    -reloaded
 *    -saving
 *    -saved
 *    -select
 *    -selected
 *    -fetched
 */
export default function generate(type, trackChanges = true, storeName = c.titleCase(type)) {
  const defaultObject = c.buildDefaultObject(type)

  return {
    name: `ObjectManipulator-${type}`,

    mixins: [ButtonMixin, ChangeTracking, BodyMixin, UserMetaMixin, NameMixin],

    // beforeRouteLeave(to, from, next) {
    //   this.confirmLeave(next);
    // },

    beforeMount() {
      this.beforeMountProcedure()
    },

    async mounted() {
      this.mountedProcedure()
      // To latch onto for e2e testing
      $(this.$el).attr('obj-simple-component', this.type)

      if (this.focusOnMount) {
        eventBus.$once(`${this.uid}-selected`, this.focusProcedure)
        eventBus.$once(`${this.uid}-embued`, this.focusProcedure)
        if (this.selected) this.focusProcedure()
      }
    },

    beforeUnmount() {
      eventBus.$off(`${this.uid}-selected`, this.focusProcedure)
      eventBus.$off(`${this.uid}-embued`, this.focusProcedure)
      eventBus.$off(`${this.uid}-saved`, () => {
        this.isCheckingOutOldVersion = 0
      })
      this.beforeDestroyProcedure()
    },

    computed: {
      $() {
        return this.$store.getters.$
      },
      $a() {
        return this.$store.getters.$a
      },
      $b() {
        return this.$store.getters.$b
      },
      /**
       * Guesses at a name
       * @returns string    guessed name
       */
      name() {
        return this.getField(`${this.type}_name`)
      },

      /**
       * Whether we are in testing mode or not
       * @returns {computed.testing|(function())}
       */
      testing() {
        return this.$store.state.session.testing
      },

      /**
       * Full normalized set this object resides in
       * @returns {object}
       */
      norm() {
        if (this.normalized) return this.normalized

        if (this.storeName in this.$store.state) {
          return this.$store.state[this.storeName].normalized
        }

        return {}
      },

      /**
       * The normalized explicit changes / committed
       * @returns {object}
       */
      normExplicitChanges() {
        if (this.storeName in this.$store.state) {
          return this.$store.state[this.storeName].explicitChanges
        }

        return {}
      },

      /**
       * Add a mechanism to change the parent, especially for deeply embedded hierarchies,
       * like quote->assemblies->cost item relationships.
       *
       * @returns string|null
       */
      parentRefId: {
        get() {
          if (this.norm && this.norm[this.refId]) {
            return this.norm[this.refId].parentRefId
          }

          return null
        },
        set(val) {
          this.$store.dispatch(`${this.storeName}/field`, {
            changes: {
              parentRefId: val
            },
            refId: this.refId
          })
        }
      },

      /**
       * Type as title type, for example CostType
       * @returns {string}
       */
      typeTitle() {
        return c.titleCase(this.type)
      },

      /**
       * Fields that are part of default settings
       * @returns {string[]}
       */
      defaultSettingFields() {
        return Object.keys(this.fields).filter((f) => this.fields[f].defaultSetting)
      },

      /**
       * Get the root normalized ref id
       * @returns {*}
       */
      rootRefId() {
        return c.getNormalizedRootRefId(this.norm, this.refId)
      },

      /**
       * Provide optional validation schema that checks
       * for correct formatting and that all required
       * values have been provided. If null it will sue
       * the (src/api/schema/{type}.js).fields validation
       * values to check against.
       */
      validationSchema() {
        return null
      }
    },

    methods: {
      async focusProcedure() {
        await this.$nextTick()
        await c.throttle(() => {}, {
          delay: 100,
          key: this.uid
        })
        const fields = $(this.$el).closest('.modal').find('.field-component')
        if (fields.length) {
          c.throttle(
            () => fields[0].__vue__ && fields[0].__vue__.focus && fields[0].__vue__.focus(),
            {
              key: `${this.uid}-${$(fields[0]).attr('data-uid')}`,
              delay: 100
            }
          )
        }
      },

      buildRecoveryFile() {
        const casted = this.cast(true)
        let content = JSON.stringify(casted)
        content = Pako.deflate(content, { to: 'string' })
        this.$store.dispatch('downloadFile', {
          fileType: 'text/cc',
          fileName: `${this.type}-${this.getField(`${this.type}_id`)}-${new Date().valueOf()}.cc`,
          content
        })
      },

      /**
       * Get the original value of the root
       * @returns {*}
       */
      getOriginalNormalized() {
        const root = this.norm[this.rootRefId]
        const id = root[`${root.type}_id`]

        if (!(id in this.$store.state[this.storeName].all)) {
          return c.normalize(c.buildDefaultObject(this.type), false, this.rootRefId)
        }

        return _.imm(this.$store.state[this.storeName].all[id])
      },

      /**
       * Get original of this object
       * @returns {*|{}}
       */
      getOriginal() {
        const norm = this.getOriginalNormalized()
        return norm[this.refId] || {}
      },

      /**
       * The initialization sequence to bring values into the local component
       * from the VUEX store for example.
       * @param forceBlank
       * @param embue
       * @returns {object<refId>}
       */
      // eslint-disable-next-line no-unused-vars
      async select(forceBlank = false, embue = {}) {
        this.beforeSelect()
        this.$emit('selecting')
        eventBus.$emit(`${this.uid}-selecting`)
        eventBus.$emit(`selecting-${this.type}-${this.refId}`)
        this.objectLocal = { ...defaultObject, ...this.object }
        this.fieldDispatch(embue, {}, false)
        this.selected = 1
        this.$emit('selected')
        eventBus.$emit(`${this.uid}-selected`)
        eventBus.$emit(`selected-${this.type}-${this.refId}`)
        this.afterSelect()
        if (this.trackChanges) {
          this.startChangeTracking()
        }

        this.endLoading()
        this.endBodyLoading()

        return { refId: this.refId }
      },

      /**
       * Re-do selection
       * @returns {*|self}
       */
      reselect() {
        this.selected = false
        return this.select()
      },

      /**
       * Reset to previously fetched state
       * @param button
       * @returns {Promise}
       */
      reset(button = []) {
        this.addBodyLoading()
        return new Promise((resolve, reject) => {
          this.addLoading()
          this.$emit('resetting')
          eventBus.$emit(`resetting-${this.type}-${this.refId}`)
          this.$store
            .dispatch(`${this.storeName}/reset`, {
              refId: this.refId,
              button: [...button, this],
              go: false
            })
            .then(() => {
              this.$emit('reset')
              eventBus.$emit(`reset-${this.type}-${this.refId}`)
              this.removeLoading()
              this.removeBodyLoading()
              resolve()
            })
            .catch(() => {
              this.endLoading()
              this.removeBodyLoading()
              reject()
            })
        })
      },

      /**
       * Clear, data and select new blank object
       * @returns {Promise}
       */
      clear() {
        return new Promise((resolve) => {
          this.selected = false
          this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
            refId: this.rootRefId,
            callback: this.throttledChangeChecker
          })
          this.selectBlank().then((payload) => resolve(payload))
        })
      },

      /**
       * Select a blank object, for creating
       * @param embue
       * @returns {*|void}
       */
      selectBlank(embue = {}) {
        this.selected = false
        return this.select(true, embue)
      },

      /**
       * Occurs before destroy
       * Can be overriden by extension mixins
       * @returns {self}
       */
      beforeDestroyProcedure() {
        if (this.deselectOnDestroy) {
          this.$store.dispatch(`${this.storeName}/deselect`, {
            refId: this.refId
          })
        }

        return this
      },

      /**
       * Occurs before mount
       * Can be overridden by extension mixins
       * @returns {self}
       */
      beforeMountProcedure() {
        this.select()
        return this
      },

      /**
       * Occurs after mounting done
       * Can be overridden by extension mixins
       * @returns {self}
       */
      mountedProcedure() {
        return this
      },

      /**
       * Occurs before saving
       * Can be overridden by extension mixins
       * @returns {self}
       */
      beforeSave() {
        return this
      },

      /**
       * Occurs after saving
       * Can be overridden by extension mixins
       * @returns {self}
       */
      afterSave() {
        return this
      },

      /**
       * Occurs before selecting/initializing
       * Can be overridden by extension mixins
       * @returns {self}
       */
      beforeSelect() {
        return this
      },

      /**
       * Occurs after selecting/initializing
       * Can be overridden by extension mixins
       * @returns {self}
       */
      afterSelect() {
        return this
      },

      addNew() {},

      /**
       * Set loading up 1
       * @returns {self}
       */
      addBodyLoading() {
        this.bodyLoading += 1

        return this
      },

      /**
       * Set loading down 1
       * @returns {self}
       */
      removeBodyLoading() {
        this.bodyLoading = this.bodyLoading <= 0 ? 0 : this.bodyLoading - 1

        return this
      },

      /**
       * Stops loading
       * @returns {self}
       */
      endBodyLoading() {
        this.bodyLoading = 0

        return this
      },

      /**
       * Checkout previous version, this ONLY works through the normalized store
       * it will not work locally on its own.  All saved changes will be lost.
       * @param activityId
       * @param button
       * @param isLatest
       * @returns {Promise<{activityId: *, activity_id: *, button: *, object}>}
       */
      async checkoutPreviousVersion({ activity_id: activityId, button = null, isLatest }) {
        this.autoSaveEnabledLocal = false
        this.bodyLoading = 1

        const { object } = await this.$store.dispatch(`${this.storeName}/checkoutPreviousVersion`, {
          activityId,
          refId: this.refId
        })

        this.isCheckingOutOldVersion = isLatest ? 0 : 1

        this.$store.dispatch('alert', {
          message:
            "You've checked out an alternate version.\r\nSo far you are only viewing this version however if you want to revert to this version, just save or force-save the changes.\r\nYou will not lose your previous versions.",
          timeout: 10000
        })
        this.bodyLoading = 0

        return { activityId, activity_id: activityId, button, object }
      },

      /**
       *
       * @returns {Promise<void>}
       */
      async resetCheckout() {
        this.isCheckingOutOldVersion = false
        this.reload()
        this.autoSaveEnabledLocal = true
      },

      /**
       * Get a denormalized/regular JS hierarchical object from this refId
       *  unless keepWhole = true
       * @returns {object}
       */
      cast() {
        return (this.refId && this.norm[this.refId]) || this.objectLocal || {}
      },

      /**
       * Checks that all required fields to save/create exist
       * @returns {boolean}
       */
      async validate() {
        this.attemptedValidation = true

        return this.$store.dispatch(`${this.storeName}/validate`, {
          object: this.cast(),
          validationSchema: this.validationSchema
        })
      },

      /**
       * Set equation by field
       * @param field
       * @param text
       * @returns {self}
       */
      setEquation(field, text) {
        let equations = { ...(this.objectLocal.oEquations || {}) }

        if ((!text || text === '') && field in equations) {
          // remove equation
          // eslint-disable-next-line no-unused-vars
          const { [field]: omit, ...rest } = equations
          equations = rest
        } else if (text && text !== '') {
          // set equation
          equations[field] = text
        }

        this.fieldDispatch({
          oEquations: equations
        })

        return this
      },

      /**
       * Unset multiple equations
       * @param fields
       * @returns {self}
       */
      unsetEquations(fields) {
        fields.forEach((field) => this.setEquation(field, null))

        return this
      },

      /**
       * Throttled audit caller
       * @returns {Promise}
       */
      triggerAudit(changes = {}) {
        return c.throttle(() => this.audit(changes), { delay: 50, key: `${this.uid}-objectLocal` })
      },

      /**
       *
       * @param immediate
       * @returns {Promise<Promise|*|void|(function(...[*]=))>}
       */
      async commitAudit(immediate = true) {
        return c.throttle(
          () => {
            c.waterfall([this.commit, this.audit])
          },
          {
            key: this.refId,
            delay: immediate === true ? 0 : 300
          }
        )
      },

      /**
       * Local audit only
       * @returns {self}
       */
      async audit(changes = {}) {
        await c.throttle(
          async () => {
            let updatedSet = _.imm({
              ...this.norm,
              [this.refId]: this.objectLocal
            })

            let changeSet = _.imm({
              [this.refId]: this.unauditedFields.reduce(
                (acc, f) => ({
                  ...acc,
                  [f]: updatedSet[f]
                }),
                {}
              )
            })

            /*
            I couldn't find a better way to propagate markup adjustments
            to children because of async auditing. This is a hacky way to set
            explicit markup adjustments, sorry.
           */
            if (changes.quote_markup_percentage_adjustment) {
              changeSet[this.refId].quote_markup_percentage_adjustment =
                changes.quote_markup_percentage_adjustment
            }

            const possibleDimensions = await this.$store.dispatch('Dimension/getPossibleDimensions')

            let newChangeSet = (
              await Parallel.work('Audit:cascadeDependencies', [
                updatedSet,
                this.refId,
                changeSet,
                possibleDimensions
              ])
            )[1]

            let auditChanges = newChangeSet[this.refId] || {}
            this.fieldDispatch(auditChanges, {}, false)
            this.unauditedFields = []

            newChangeSet = null
            changeSet = null
            updatedSet = null
            auditChanges = null
          },
          {
            key: this.refId
          }
        )

        return this
      },

      /**
       * Get local explicit changes
       * @returns {Object}
       */
      getLocalExplicitChanges() {
        return this.explicitFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: this.objectLocal[field]
          }),
          {}
        )
      },

      /**
       * Make changes locally and in the VUEX store.
       * Set explicit changes to track changes to models.  If not set
       * all changes become marked as explicit changes.
       *
       * @param {object}      allChanges        all changes, should include explicitChanges too
       * @param {object|null} explicitChanges   changes from allChanges that are meant as explicit
       * @param {boolean}     audit             whether to trigger audit or not
       * @returns {self}
       */
      fieldDispatch(allChanges, explicitChanges = null, audit = true) {
        const fieldChanges = Object.keys(allChanges)

        this.objectLocal = _.imm({
          ...this.objectLocal,
          ...allChanges
        })

        this.explicitFields = [
          ...this.explicitFields,
          ...Object.keys(explicitChanges || allChanges || {})
        ]

        this.unauditedFields = _.uniq([...this.unauditedFields, ...fieldChanges])

        this.uncommittedFields = _.uniq([...this.uncommittedFields, ...fieldChanges])

        this.commit(this.getUncommittedChanges(), this.getLocalExplicitChanges(), true, audit)

        return this
      },

      /**
       * Fill fields into dispatch,
       * filling should be absolutely unobtrusive, NOT add any explicit changes
       * @param object
       * @param {boolean} audit
       * @returns {self}
       */
      fill(object = {}, audit = true) {
        return this.fieldDispatch(object, {}, audit)
      },

      /**
       * This is the function that remits all the changes that have occurred
       * here locally in to the VUEX normalized model.  This is normally called
       * before destruction of the component. You can also call it earlier if you
       * want the changes to be added
       * @param {object} uncommittedChanges
       * @param {boolean} immediate                if true, will not throttle audit, if
       *                                        false will throttle audit
       * @param {boolean} audit                    if true, will audit
       * @param {object|bool} explicitChanges   true = all changes explicit, false = none, or object
       * @returns {Promise<self>}
       */
      async commit(
        uncommittedChanges = this.getUncommittedChanges(),
        explicitChanges = this.getLocalExplicitChanges(),
        immediate = false,
        audit = true
      ) {
        if (!this.uncommittedFields.length) {
          return this
        }

        this.committing = 1
        this.emitChanging()

        this.uncommittedFields = []
        this.explicitFields = []
        this.unauditedFields = []

        // Commit changes to normalized object
        const normChanges = { [this.refId]: uncommittedChanges }
        const normExpChanges = { [this.refId]: explicitChanges }
        this.$store.commit({
          type: `${this.storeName}/${types.FIELDS}`,
          changes: normChanges
        })
        this.$store.dispatch(`${this.storeName}/addChanges`, {
          changes: normChanges,
          explicitChanges: normExpChanges
        })

        // Audit if true
        const constructor = audit && c.getConstructor(this.type)
        if (
          audit &&
          constructor &&
          Object.keys(uncommittedChanges).some(
            (field) =>
              field in constructor.fields &&
              (constructor.fields[field].trackChanges ||
                typeof constructor.fields[field].trackChanges === 'undefined')
          )
        ) {
          // Audit this
          this.auditStore(immediate, uncommittedChanges)
        }

        this.emitChanged()
        this.emitCommitted()
        this.committing = 0

        return this
      },

      /**
       * Audit outside store
       * @param immediate
       * @param uncommittedChanges
       * @returns {Promise<void>}
       */
      async auditStore(immediate = false, uncommittedChanges = null) {
        return c.throttle(
          () => {
            const fn = async () => {
              let { changeSet: localChangeSet } = await this.$store.dispatch(
                `${this.storeName}/auditLocalDependencies`,
                {
                  store: this.storeName,
                  refId: this.refId,
                  immediate: true,
                  changes: uncommittedChanges
                }
              )

              // Audit parent
              const parentRefId = this.parentRefId
              if (parentRefId) {
                const { changeSet: parentChangeSet } = await this.$store.dispatch(
                  `${this.storeName}/auditLocalDependencies`,
                  {
                    store: this.storeName,
                    refId: parentRefId,
                    immediate: true,
                    full: true
                  }
                )
                localChangeSet = {
                  ...localChangeSet,
                  ...parentChangeSet
                }
              }

              // Audit full
              this.$store.dispatch(`${this.storeName}/auditDependencies`, {
                store: this.storeName,
                refId: this.refId,
                immediate,
                // Only audit if necessary
                changes: {
                  [this.refId]: uncommittedChanges,
                  ...localChangeSet
                }
              })
            }

            fn()
          },
          {
            delay: 500,
            key: this.refId
          }
        )
      },

      /**
       * Emit that we are about to make a change in this object
       * @returns {self}
       */
      emitChanging() {
        eventBus.$emit('changing', {
          refId: this.refId,
          store: this.storeName
        })
        eventBus.$emit(`changing-${this.type}-${this.refId}`)

        return this
      },

      /**
       * Emit that we have made a change in this object
       * @returns {self}
       */
      emitChanged() {
        eventBus.$emit('changed', {
          refId: this.refId,
          store: this.storeName
        })
        eventBus.$emit(`changed-${this.type}-${this.refId}`)

        const cast = this.cast()
        this.$emit('object', cast)
        this.$emit('input', cast)

        return this
      },

      emitCommitted() {
        eventBus.$emit('committed', {
          refId: this.refId,
          store: this.storeName
        })
        eventBus.$emit(`committed-${this.type}-${this.refId}`)
        return this
      },

      reintegrate() {
        let resolution
        const prom = new Promise((resolve) => {
          resolution = resolve
        })

        eventBus.$once(`${this.uid}-integratedChanges`, () => {
          resolution()
        })
        this.commitAudit()

        return prom
      },

      /**
       *
       */
      integrateChanges() {
        const changes = this.getUncommittedChanges()
        this.objectLocal = {
          ...this.norm[this.refId],
          ...changes
        }
        this.$emit('integratedChanges')
        eventBus.$emit(`${this.uid}-integratedChanges`)
      },

      /**
       * Get changes that have not yet been committed with the commit() methods
       * @returns object
       */
      getUncommittedChanges() {
        return _.imm(
          this.uncommittedFields.reduce(
            (acc, field) => ({
              ...acc,
              [field]: this.objectLocal[field]
            }),
            {}
          )
        )
      },

      /**
       * Set a field that represents an id like client_id
       * It will fetch the client, make sure other client related
       * fields are also filled in.
       * @param field
       * @param id
       */
      async setIdField(field, id) {
        const fields = this.constructor.fields
        const mapType = fields[field].mapTo
        const titleMapTo = _.titleCase(mapType)
        const camelMapTo = _.camelCase(mapType)
        const current = this.getField(field)
        const normField = `o${titleMapTo}`

        this.setField(field, id)

        if (id === current) {
          return this
        }

        let object = {}
        if (id) {
          const { object: fetchedObject } = await this.$store.dispatch(`${titleMapTo}/fetch`, {
            id
          })
          object = fetchedObject
        }

        const nameField = field.replace('_id', '_name')
        const abbrField = field.replace('_id', '_abbr')
        this.fieldDispatch(
          {
            [camelMapTo]: { ...object },
            [normField]: { ...object },
            [nameField]: object[nameField] || null,
            [abbrField]: object[abbrField] || null
          },
          {},
          false
        )

        this.addDefaults()

        return this
      },

      /**
       * Get the field for an id, equivalent to getting any field
       * @param {string} field
       * @returns {any|null}
       */
      getIdField(field) {
        return this.getField(field) || null
      },

      /**
       * Get a defaulted, and deformatted value for
       * suitable for injecting into the vuex store,
       * for example, converts a string into an int, for fields
       * that are meant to be int. Makes sures Arrays are expressed
       * as tru arrays and not as comma-separated strings.
       *
       * @param f
       * @param val
       * @returns {*}
       */
      getDeformattedFieldValue(f, val) {
        // const constructor = this.constructor;
        const fields = this.constructor && this.constructor.fields
        let deformattedVal = val

        if (fields && fields[f].type === 'array') {
          deformattedVal = c.makeArray(val)
        } else if (Array.isArray(val)) {
          deformattedVal = c.makeArray(val).join(',')
        }

        if (!fields) {
          return val
        }

        if (
          (fields[f].format === 'percent' ||
            fields[f].format === 'percentage' ||
            fields[f].format === 'currency' ||
            fields[f].format === 'number' ||
            /* && !/(?:\.0*$)|(?:\.\d*0+$)/.test(val) */ (fields[f].type === 'int' &&
              fields[f].format === false)) &&
          val !== null
        )
          deformattedVal = c.deformat(val, 'number')

        // If defaulted object, make sure it is defaulted
        const getDefault = (schema, embue) => {
          if (
            schema.type === 'object' &&
            !schema.mapTo &&
            (typeof schema.default === 'object' || typeof schema.default === 'function')
          ) {
            const def =
              typeof schema.default === 'function'
                ? schema.default(_.imm(embue), {})
                : schema.default

            return _.merge(_.imm(def), _.imm(embue))
          }

          return embue
        }

        return getDefault(fields[f], deformattedVal)
      },
      /**
       * Provide a field name, and if that field
       * is one that can be defaulted, it will save the
       * current value of that field as the default value
       * @returns {Promise<self>}
       */
      async addDefaults() {
        await c.throttle(
          () => {
            try {
              this.$store.dispatch(`${this.storeName}/addDefaults`, {
                type: this.type,
                refId: this.refId,
                object: this.cast()
              })
            } catch (e) {
              // try and leave it alone
              if (import.meta.env.DEV) {
                throw e
              }
            }
          },
          { key: this.type, delay: 5000 }
        )

        return this
      },

      /**
       * Set fields like aoStageTasks or aoChildren which
       * are normalized, with raw objects instead of dealing
       * with the refIds
       *
       * @param f
       * @param children
       * @returns {self}
       */
      setNormalizedField(f, children) {
        const childrenArray = _.makeArray(children)
        if (c.shouldMapField(f, type)) {
          this.$store.dispatch(`${this.storeName}/replaceChildren`, {
            refId: this.refId,
            children: childrenArray,
            field: f
          })
        } else {
          this[f] = this.constructor.fields[f].type === 'object' ? childrenArray[0] : childrenArray
        }

        this.integrateUpstreamChanges()

        return this
      },

      /**
       * Get the denormalized version of a normalized field
       * like aoStageTasks or aoFiles or aoChildren which
       * are normalized and mapped using refIds
       *
       * @returns object|array
       * @param field
       */
      getNormalizedField(field) {
        const casted = this.cast(true)
        const fieldType =
          (this.constructor.fields[field] && this.constructor.fields[field].type) || 'object'

        if (field in casted && casted[field]) {
          return casted[field]
        }

        return fieldType === 'array' ? [] : {}
      },

      /**
       * Get regular field
       * @param field
       * @returns {any}
       */
      getField(field) {
        return this.objectLocal[field]
      },

      /**
       * Gets the original value of the field, unchanged
       * @param field
       */
      getFieldOriginal(field) {
        const original = this.getOriginal()
        if (field in original) {
          return original[field]
        }

        return null
      },

      /**
       * Set regular fields
       * @param field
       * @param val
       */
      setField(field, val) {
        const deformattedVal = this.getDeformattedFieldValue(field, val)

        this.fieldDispatch(
          {
            [field]: deformattedVal
          },
          null,
          true
        )

        this.addDefaults(field)

        return this
      },

      /**
       * RESTful /mark call
       * @param status
       * @param alert
       * @returns {Promise<self>}
       */
      async mark(status, alert = true) {
        await this.$nextTick()

        const markAs = c.format(status, 'status')

        this.$emit('marking', status)
        eventBus.$emit(`${this.uid}-marking`, status)

        await this.$store.dispatch(`${this.storeName}/markMultiple`, {
          markAs,
          refId: this.refId,
          selected: [
            {
              [`${this.type}_id`]: this.getField(`${this.type}_id`),
              type: this.type,
              refId: this.refId
            }
          ],
          alert,
          go: false
        })

        this.reload()

        return this
      },

      /**
       * Will ONLY change local changes, no children or related objects.
       *
       * RESTful /saveChanges call
       * @param button
       * @param reset   whether to reset changes after
       * @param alert
       * @returns {Promise<self>}
       */
      // eslint-disable-next-line no-unused-vars
      async saveChanges(button, reset = true, alert = true) {
        if (!this.hasChangeTrackingMixin || !this.enableChangeWatching) {
          throw new Error(
            'Changes are not being tracked on that object. Changes must be tracked to  saveChanges.'
          )
        }

        const changedFields = this.getChangedFields()
        if (!changedFields.length) {
          if (alert) {
            this.$store.dispatch('alert', {
              error: true,
              message: 'No changes to save!'
            })
          }

          return this
        }

        this.addLoading()
        await this.commit()

        this.beforeSave()

        const original = this.cast()
        const changes = changedFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: this.getField(field)
          }),
          {
            type: this.type
          }
        )

        if ((await this.validate()) && Object.keys(changes).length) {
          this.$emit('saving')
          eventBus.$emit('saving', { changes, object: original })

          const { object: saved } = await this.$store.dispatch(`${this.storeName}/saveChanges`, {
            changes: {
              [this.refId]: changes
            },
            alert: true
          })

          this.$store.dispatch('alert', {
            message: 'Saved!'
          })

          const object = c.buildDefaultObject(original.type, { ...original, ...saved })

          this.$emit('saved', object)
          eventBus.$emit(`${this.uid}-saved`)
          eventBus.$emit('saved', { object })

          if (this.reset) {
            this.resetChanges()
          }

          this.afterSave()
        }

        this.endLoading()

        return this
      },

      /**
       * Save individual fields, given by fields array
       * @param fields
       * @returns {Promise<self>}
       */
      async saveFields(fields = []) {
        const changeSet = fields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: this.getField(field)
          }),
          {}
        )

        this.addLoading()
        await this.commit()
        await this.$nextTick()

        const idField = `${this.type}_id`

        await this.$store.dispatch('ajax', {
          path: `${this.type}/updateMultiple`,
          data: [
            {
              ...changeSet,
              [idField]: this.getField(idField),
              type: this.type
            }
          ]
        })

        this.removeLoading()

        return this
      },

      /**
       * Delete
       * @param button
       * @param alert
       * @param go
       * @param force
       * @returns {Promise<self>}
       */
      async delete(button, alert = true, go = true, force = false) {
        const payload = {
          button,
          alert,
          go,
          force,
          refId: this.refId,
          type: this.type
        }

        if (!this.refId) {
          payload.id = this.getField(`${this.type}_id`)
        }

        this.addLoading()
        await this.$nextTick()

        this.$emit('deleting', payload)
        eventBus.$emit(`${this.uid}-deleting`, payload)
        eventBus.$emit('deleting', payload)

        await this.$store.dispatch(`${this.storeName}/delete`, payload)

        this.removeLoading()

        this.$emit('deleted', payload)
        eventBus.$emit(`${this.uid}-deleted`, payload)
        eventBus.$emit('deleted', payload)

        return this
      },

      /**
       * Update only the changes in this object sent specifically in
       * the changes parameter.
       * @param changes
       * @param button
       * @param alert
       * @param go
       * @returns {Promise}
       */
      async partialUpdate(changes = {}, button = null, alert = false, go = false) {
        this.addLoading()

        await this.$nextTick()
        await this.commit()

        await this.$store.dispatch(`${this.storeName}/partialUpdate`, {
          selected: [
            {
              ...changes,
              type: this.type,
              [`${this.type}_id`]: this.getField(`${this.type}_id`)
            }
          ],
          button,
          go,
          alert
        })

        this.removeLoading()

        return this
      },

      async saveAndClose() {
        await this.save()
        this.$emit('close')
        this.$emit('closeModal')
      },

      async close() {
        this.$emit('closeModal')
      },

      cancelClick() {
        this.$emit('closeModal')
      },

      /**
       *
       * @param button
       * @param reset
       * @param alert
       * @param go
       * @param force
       * @returns {Promise<*>}
       */
      async save(
        button,
        reset = !trackChanges || Object.keys(this.original).length,
        alert = true,
        go = this.go,
        force = false
      ) {
        try {
          await this.commit()
          this.beforeSave()

          if (!force && !(await this.validate())) {
            return false
          }

          this.addLoading()
          this.$emit('saving')
          eventBus.$emit(`${this.uid}-saving`)
          eventBus.$emit('saving', { changes: this.changes, object: this.cast() })
          eventBus.$emit(`saving-${this.type}-${this.refId}`)
          eventBus.$emit(`saving-${this.type}`)

          this.addLoading()
          const savePayload = await this.$store.dispatch(`${this.storeName}/save`, {
            refId: this.refId,
            go,
            reset,
            alert,
            changes: true,
            force: !trackChanges || force
          })

          this.endLoading()

          await this.afterSave()
          this.isCheckingOutOldVersion = 0
          this.$emit('saved', { reset, alert, go, ...savePayload })
          eventBus.$emit(`${this.uid}-saved`)
          eventBus.$emit('saved', savePayload)
          eventBus.$emit(`saved-${this.type}-${this.refId}`)
          eventBus.$emit(`saved-${this.type}`)
          return { reset, alert, go, ...savePayload }
        } catch (error) {
          eventBus.$emit(`error-${this.type}`, error)
          if (alert) {
            this.$store.dispatch('alert', {
              error: true,
              message: error.userMessage || 'An error occurred. Changes may not have been saved.'
            })
          }

          throw new Error(error.message)
        } finally {
          this.endLoading()
        }
      },

      /**
       * Reload fetched state, and select it
       * @returns {Promise}
       */
      async reload(keepChanges = false) {
        this.addBodyLoading()
        this.addLoading()

        this.$emit('reloading')
        this.$emit('reload')
        this.$emit(`${this.uid}-reload`)
        this.$emit(`${this.uid}-reloading`)
        eventBus.$emit(`reloading-${this.type}-${this.refId}`)

        await this.$store.dispatch(`${this.storeName}/reload`, {
          refId: this.refId,
          parentRefId: this.parentRefId, // send contextuals so it can be found in normalized
          button: this,
          go: false,
          keepChanges
        })

        this.$emit('reloaded')
        eventBus.$emit(`${this.uid}-reloaded`)
        eventBus.$emit(`reloaded-${this.type}-${this.refId}`)

        this.removeLoading()
        this.removeBodyLoading()

        return this
      },

      /**
       * Integrate changes from upstream into this component
       * @param object object    {field: value}
       * @returns {methods}
       */
      integrateUpstreamChanges(object = null) {
        const upstream = _.imm(object || this.norm[this.refId])
        // If we have committed all possible fields,
        // then bring the changes in from above.
        // otherwise, wait for current changes to be committed;
        if (!this.uncommittedFields.length) {
          this.objectLocal = upstream
        } else {
          // Otherwise, do a selective loading of values that are
          // different and fully committed only.
          const changedFields = this.getFieldChanges(upstream)
          const integrate = changedFields
            .filter((field) => !this.uncommittedFields.includes(field))
            .reduce(
              (acc, field) => ({
                ...acc,
                [field]: upstream[field]
              }),
              {}
            )

          this.fill(integrate)
        }

        return this
      },

      /**
       * Before leaving, we should determine if we should warn that
       * changes will be lost.
       * @param next
       */
      async confirmLeave(next) {
        if (!this.isDirty) {
          next(true)
          return
        }

        const shouldLeave = await this.$store.dispatch('modal/asyncConfirm', {
          message: 'Are you sure you want to leave?  Your changes will be lost.',
          yes: 'Yes, abandon changes..',
          no: 'No, stay for now..'
        })

        if (shouldLeave) {
          next(true)
        }
      },

      /**
       * Get contact for this object
       * @returns {Object}
       */
      getContact() {
        let contactObject
        const casted = this.cast(false)

        if (
          this.type === 'client' ||
          this.type === 'vendor' ||
          this.type === 'user' ||
          this.type === 'contact'
        ) {
          contactObject = casted
        } else if (this.type === 'quote' || this.type === 'invoice') {
          contactObject = casted.oClient
        } else if (this.type === 'wo' || this.type === 'bill') {
          contactObject = casted.oVendor
        }

        if (!contactObject || !contactObject.type) {
          return {}
        }

        const objectType = contactObject.type
        return {
          contact_id: `${contactObject.objectType}-${contactObject[`${contactObject.objectType}_id`]}`,
          contact_object_objectType: objectType,
          contact_fname: contactObject[`${objectType}_fname`],
          contact_lname: contactObject[`${objectType}_lname`],
          contact_name: contactObject[`${objectType}_name`],
          contact_phone: contactObject[`${objectType}_phone`],
          contact_phone_alt: contactObject[`${objectType}_phone_alt`],
          contact_phone_ext: contactObject[`${objectType}_phone_ext`],
          contact_phone_ext_alt: contactObject[`${objectType}_phone_ext_alt`],
          contact_email: contactObject[`${objectType}_email`],
          contact_email_alt: contactObject[`${objectType}_email_alt`],
          contact_company: contactObject[`${objectType}_company`],
          contact_suite: contactObject[`${objectType}_suite`],
          contact_address: contactObject[`${objectType}_address`],
          contact_city: contactObject[`${objectType}_city`],
          province_id: contactObject.province_id,
          contact_status: contactObject[`${objectType}_status`],
          company_id: contactObject.company_id,
          contact_file_id: contactObject[`${objectType}_file_id`],
          contact_time_created: contactObject[`${objectType}_time_created`],
          aoEmailAddresses: [
            ...(contactObject[`${objectType}_email`]
              ? [
                  {
                    email_address: contactObject[`${objectType}_email`]
                  }
                ]
              : []),
            ...(contactObject[`${objectType}_email_alt`]
              ? [
                  {
                    email_address: contactObject[`${objectType}_email_alt`]
                  }
                ]
              : [])
          ],
          aoPhoneNumbers: [
            ...(contactObject[`${objectType}_phone`]
              ? [
                  {
                    phone: contactObject[`${objectType}_phone`]
                  }
                ]
              : []),
            ...(contactObject[`${objectType}_phone_alt`]
              ? [
                  {
                    phone_alt: contactObject[`${objectType}_phone_alt`]
                  }
                ]
              : [])
          ]
        }
      }
    },

    watch: {
      selected(selected) {
        this.$emit('selected', selected)
        eventBus.$emit(`${this.uid}-selected`)
        this.afterSelect()
      },
      objecLocal() {
        this.commit()
      },

      object(object) {
        c.throttle(
          () => {
            this.integrateUpstreamChanges(object)
          },
          {
            key: this.uid
          }
        )
      },

      isCheckingOutOldVersion(b) {
        this.$emit('checkingOutOldVersion', b)
        eventBus.$emit(`${this.uid}-checkingOutOldVersion`, b)
        if (b) {
          // Reset itself after next save
          eventBus.$once(`${this.uid}-saved`, () => {
            this.isCheckingOutOldVersion = 0
          })
        }
      }
    },

    data() {
      const allActions = c.getActions(this.type || type)
      const actionKeys = Object.keys(allActions)
      const actions = c.omit(
        allActions,
        actionKeys.filter((k) => !allActions[k].editing)
      )
      const constructor = c.getConstructor(this.type || type)

      const intercepted = Object.keys(constructor.fields).reduce(
        (acc, field) => ({
          ...acc,
          [`intercepted_${field}`]: c.getDefaultFromField(constructor.fields[field], field)
        }),
        {}
      )
      return {
        isCheckingOutOldVersion: 0,
        attemptedValidation: false,
        selected: !c.isempty(this.reference),
        idLocal: this.id,
        dataManipulator: true,
        refId: this.reference || this.object.refId,
        bodyLoading: 1,
        fields: constructor.fields,
        constructor,
        statusSet: (constructor.possibleStatuses || []).map((s) => ({
          text: c.format(s, 'status'),
          value: s
        })),
        savedCount: 0,
        storeName: c.titleCase(this.store || storeName || this.type || type),
        original: Object.keys(this.object).length ? c.buildDefaultObject(type, this.object) : false,
        actions,
        c,
        uid: _.uniqueId(),
        componentRegistered: 0,
        ...intercepted,
        intercepted_oEquations: {},
        objectLocal: this.object,
        explicitChangesManual: {},
        uncommittedFields: [],
        unauditedFields: [],
        explicitFields: [],
        committing: 0
      }
    },

    props: {
      /**
       * This is the prop that will set the storeName variable
       */
      store: {
        default: null
      },

      /**
       * Provide a distinct normalized set
       * If provided, it will not use the store
       */
      normalized: {
        default: null
      },

      /**
       * Whether to track changes on this object or not. Change tracking
       * is an expensive activity and should be limited to one component
       * per normalized set.
       */
      trackChanges: {
        required: false,
        default: trackChanges
      },

      /**
       * This is the prop that will set the refId variable
       *
       * ObjectManipulator components don't usually
       *   take a refId, it is sorted out after the
       *   fetching is done etc.  If that is done
       *   externally however, you can pass a reference
       *   which is the refId
       */
      reference: {
        required: false,
        default: null
      },

      /**
       * Provide the entity id for the object to manipulate here, or provide
       * the whole object in object below
       */
      id: {
        required: false,
        default: null
      },

      /**
       * Provide the object to manipulate here, or provide the id above
       */
      object: {
        type: Object,
        required: false,
        default: () => defaultObject
      },

      /**
       * Whether this should select automatically
       */
      selectOnMount: {
        required: false,
        default: true
      },

      /**
       * When this entity is destroyed, should the object be de-selected and
       * removed from the set to save memory?
       * If other components are staying alive and use the same data set
       * then SET THIS TO FALSE
       */
      deselectOnDestroy: {
        required: false,
        default: false
      },

      /**
       * Use the id provided in the route:id variable from VueRouter
       */
      useRouteId: {
        required: false,
        default: false
      },

      /**
       * Force this component to select a fresh object each load
       */
      forceFetch: {
        required: false,
        type: Boolean,
        default: false
      },

      /**
       * Force this component to have a full object(not a cursory one, for example
       * as a result of a search somewhere else).  Fetch a fresh one if the existing
       * one is not full.
       */
      forceFull: {
        required: false,
        type: Boolean,
        default: false
      },

      /**
       * Set some variables to tag on this item when it is saved.
       */
      tags: {
        default: () => ({})
      },

      /**
       * Object/entity type
       * Defaults to the generated type
       */
      type: {
        default: type
      },

      /**
       * Whether to focus in first input on mount
       */
      focusOnMount: {
        default: true
      },

      /**
       * go to list after saving
       */
      go: {
        default: false
      }
    },
    emits: [
      'selecting',
      'selected',
      'resetting',
      'reset',
      'object',
      'input',
      'integratedChanges',
      'marking',
      'saving',
      'saved',
      'deleting',
      'deleted',
      'close',
      'closeModal',
      'reloading',
      'reload',
      'reloaded',
      'checkingOutOldVersion'
    ]
  }
}
