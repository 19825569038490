import * as types from '../../mutation-types'

const state = {
  videosOpen: [],
  topics: [],
  hideHelp: true
}

const getters = {}

const actions = {
  update({ commit, state, rootState }, payload) {
    const { video = null } = payload
    return new Promise((resolve, reject) => {
      const cache = c.getCacheItem('helpVideos', 'videoInfo', rootState.session)
      let timeStamp = new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      if (c.isempty(cache) || timeStamp > cache.created) {
        const apiKey = 'AIzaSyBt5Bzh4kxLNDsRY1gWiqIAUzwi0FYA3Uk'
        const playlistId = 'PLPge8j28jp7nGs5X-eWpHbld_HpQpXAe2'
        const apiLink1 = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet
        &playlistId=${playlistId}&maxResults=50&key=${apiKey}`
        let videos = []
        window.jQuery.getJSON(apiLink1).then((data) => {
          data.items.forEach((item) => {
            videos.push(item.snippet.resourceId.videoId)
          })
          const apiLink2 = `https://www.googleapis.com/youtube/v3/videos?key=${apiKey}&part=contentDetails,snippet&id=${videos.join()}`
          window.jQuery.getJSON(apiLink2).then((data) => {
            const result = data
            result.items.forEach((data) => {
              // let obj = state.topics.find(x => x.video === data.id);
              commit({
                type: types.VIDEO_CREATE,
                data
              })
              // obj.length = data.contentDetails.duration;
            })
            const cacheObject = { topics: state.topics, created: new Date() }
            c.setCacheItem('helpVideos', cacheObject, 'videoInfo', rootState.session)
          })
        })
      } else {
        commit({
          type: types.VIDEO_UPDATE,
          cache: cache
        })
      }
      resolve({ ...payload, video })
    })
  },
  open({ commit, state }, payload) {
    const { video = null } = payload
    return new Promise((resolve, reject) => {
      if (!state.videosOpen.includes(video)) {
        commit({
          type: types.VIDEO_OPEN,
          video
        })
      }
      resolve({ ...payload, video })
    })
  },
  close({ commit, state }, payload) {
    const { video = null } = payload
    return new Promise((resolve, reject) => {
      commit({
        type: types.VIDEO_CLOSE,
        video
      })
      resolve({ ...payload, video })
    })
  }
}

const mutations = {
  [types.VIDEO_OPEN](state, { video }) {
    state.videosOpen.push(video)
  },
  [types.VIDEO_CLOSE](state, { video }) {
    state.videosOpen.splice(state.videosOpen.indexOf(state.videosOpen.find((o) => o == video)), 1)
  },
  [types.VIDEO_UPDATE](state, { cache }) {
    state.topics = cache.topics
  },
  [types.VIDEO_CREATE](state, { data }) {
    let obj = state.topics.find((x) => x.video === data.id)
    if (!obj) {
      state.topics.push({
        video: data.id,
        title: data.snippet.title,
        description: data.snippet.description,
        tags: data.snippet.tags,
        length: data.contentDetails.duration
      })
    } else {
      obj.length = data.contentDetails.duration
      obj.title = data.snippet.title
      obj.description = data.snippet.description
      obj.tags = data.snippet.tags
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
