// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'webhook',

  possibleStatuses: ['a','i'],

  skipAudit: true,

  fields: {
    webhook_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewWebhook',
      visible: true,
    },
    webhook_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
      required: true,
    },
    webhook_event: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
      required: true,
    },
    webhook_endpoint: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
      required: true,
    },
    webhook_time_created: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    webhook_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    webhook_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    webhook_time_last_modified: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
      ommitFromDuplicate: true,
      save: false,
      reload: true,
    },
    aoLog: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      reload: true,
    },
  },

  generateVueActions() {
    return {
    };
  },
};
