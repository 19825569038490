export default {
  root: ({ props }) => ({
    class: [
      'p-1 rounded-sm w-fit gap-1 bg-surface-200/40 whitespace-nowrap flex-nowrap',
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled
      }
    ]
  }),
  button: ({ context, props }) => ({
    class: [
      'relative',
      // Font
      'leading-none font-medium',

      // Flex Alignment
      'inline-flex items-center align-bottom text-center',

      // Spacing
      'px-4 py-2',

      // Shape
      'rounded-sm',

      // Color
      {
        'bg-transparent': !context.active,
        'text-cool-gray-700 dark:text-white/80': !context.active,
        'border-transparent': !context.active,
        'bg-white font-bold dark:bg-primary-400 text-pitch-black shadow-md': context.active
      },
      // Invalid State
      { 'border-red-500 dark:border-red-400': props.invalid },

      // States
      {
        'hover:bg-white hover:text-pitch-black hover:shadow-md dark:hover:bg-surface-800/80 ':
          !context.active
      },
      { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
      // Transition
      'transition duration-200',

      // Misc
      'cursor-pointer select-none overflow-hidden'
    ]
  }),
  label: {
    class: 'font-bold'
  }
}
