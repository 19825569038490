export default {
  props: {
    store: {
      default: 'Quote'
    }
  },
  computed: {
    showMarkupRelatedVariables() {
      return this.checkAUserPermsUsingAction('company_markup', 'modify')
    }
  },
  methods: {
    checkAUserPermsUsingAction(nameOfPerm, actionString) {
      const currentPermission = this.$store.state.session.user.aUserPerms[nameOfPerm][actionString]
      const userIsAdmin = this.$store.state.session.user.user_is_super_user
      if (userIsAdmin) {
        return true
      }
      if (currentPermission === 0) {
        return false
      }
      if (currentPermission === 3) {
        return true
      }

      const normalizedQuote = this.$store.state.Quote.normalized
      const quoteIds = Object.keys(normalizedQuote)
      const currentUserId = this.$store.state.session.user.user_id

      let quoteCreator = null
      let quoteProjectManager = null

      quoteIds.forEach((item) => {
        if (normalizedQuote[item].quote_creator) {
          quoteCreator = normalizedQuote[item].quote_creator
        }
        if (normalizedQuote[item].quote_pm_user_id) {
          quoteProjectManager = normalizedQuote[item].quote_pm_user_id
        }
        return null
      })
      if (
        currentPermission === 1 &&
        (currentUserId === quoteCreator || currentUserId === quoteProjectManager)
      ) {
        return true
      }
      return false
    }
  }
}
