<template>
  <grid
    class="list--outer-container"
    v-bind="$props"
    :showToolbar="false"
    :showFilters="false"
    ref="grid"
    :searchPhrase="searchPhraseLocal"
    :limit="limit"
    :order="order"
    :offset="offset"
    @loaded="(set) => $emit('loaded', set)"
    @reloaded="(set) => $emit('reloaded')"
  >
    <template #customView="grid">
      <slot :set="grid.set">
        <div class="list--inner-container">
          <Loader :loading="grid.loading" />
          <card-list class="list--card-list">
            <card-list-item v-if="showHeader" class="list--header muted">
              <slot name="header"></slot>
              <div class="flex justify-end items-center" style="width: 100%">
                <search-field v-if="showHeaderSearch" v-model="searchPhraseLocal" />
                <btn
                  rounded
                  class="more px-0 p-2 muted ml-2"
                  @click="reload"
                  :loading="grid.loading"
                  ><font-awesome-icon icon="arrows-rotate"
                /></btn>
              </div>
            </card-list-item>
            <template v-if="grid.set.length">
              <card-list-item
                class="item-row"
                v-for="object in grid.set"
                :key="object[`${object.type}_id`]"
              >
                <div class="list--item-options" v-if="showOptions">
                  <btn rounded class="more px-0 p-2" @click="editObject(object)">
                    <font-awesome-icon icon="rectangle-history-circle-plus" />
                  </btn>

                  <btn-bar :contextual="true" :collapse="true" :selected="[object]" />
                </div>
                <component
                  style="flex: 1 100%"
                  :showEdit="showEdit"
                  :grid="grid"
                  :is="previewComponent"
                  :object="object"
                />
              </card-list-item>
              <card-list-button
                v-if="!grid.endOfSet && showMore"
                class="muted"
                @click="() => $refs.grid.fetchMore()"
              >
                Show more..
              </card-list-button>
            </template>
            <template v-else-if="!grid.set.length">
              <card-list-item class="items-center justify-center flex !p-10">
                <slot name="empty"> No items! </slot>
              </card-list-item>
            </template>
            <template v-else>
              <card-list-item class="center list--loading">
                <spinner :loading="1" size="3em" />
              </card-list-item>
            </template>
          </card-list>
        </div>
      </slot>
    </template>
  </grid>
</template>

<script>
import Grid from '../Grid/Grid.vue'
import GridMixin from '../Grid/GridMixin'

/**
 * Emits (to $parent):
 *  loaded
 *  ready
 *  endOfSet
 *  error
 *  selected
 *  dblclick
 *  singleClick (when one row is selected, clicked)
 *
 *  as $root:
 *    // When selection made
 *    gridSelected => ({
 *      [this.type]: [object, object, ...]
 *    })
 *
 *    // On close
 *    gridChosen => ({
 *      [this.type]: [object, object, ...]
 *    })
 *
 *  Slots:
 *    -toolbar
 *    -filters
 *    -toolbarAddon
 *    -customView
 *    -emptyLoading
 *    -emptySearching
 *    -empty
 */
export default {
  name: 'List',
  emits: ['loaded', 'reloaded', 'searchPhrase'],
  data() {
    return {
      searchPhraseLocal: this.searchPhrase
    }
  },
  watch: {
    searchPhrase(sp) {
      this.searchPhraseLocal = sp
    },
    searchPhraseLocal(sp) {
      this.$emit('searchPhrase', sp)
    }
  },
  props: {
    showEdit: {
      default: true
    },
    ...GridMixin.props,
    ...Grid.props,
    showOptions: {
      default: true
    },
    isMain: {
      default: false
    },
    limit: {
      default: 10
    },
    order: {
      default: null
    },
    offset: {
      default: 0
    },
    showMore: {
      default: true
    },
    showHeader: {
      default: true
    },
    showHeaderSearch: {
      default: true
    },
    searchPhrase: {
      default: ''
    }
  },
  methods: {
    reload() {
      this.$refs.grid.reload()
    },
    editObject(object) {
      this.$store.dispatch('edit', {
        type: object.type,
        id: object[`${object.type}_id`]
      })
    }
  },
  computed: {
    previewComponent() {
      return `Preview${c.titleCase(this.type)}`
    }
  },
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.list--outer-container {
  .list--header {
    flex-direction: row !important;
  }
  .list--item-options {
    padding: 0 0.5em;
    > :first-child {
      margin-bottom: 0.25em;
    }
  }
  .grid--main-panel {
    overflow-x: visible !important;
  }
  .list--inner-container {
    padding: 0;
    .list--card-list {
    }
  }

  .list--empty,
  .list--loading {
    min-height: 6em;

    font-weight: bold;
    color: $cool-gray-600;
    padding: 2em;
  }

  &.nopadding {
    .list--card-list {
      ul {
        .item-row {
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
