import moment from 'moment'

export function getQuoteSteps(object, store, contextual) {
  let steps = []

  const currentTime = new Date().getTime()
  const isExpired =
    object.type === 'quote' &&
    object.quote_time_expired &&
    object.quote_time_expired < currentTime &&
    object.quote_status === 'p'

  const waitingForApproval = object.type === 'quote' && !object.change_order_company_has_approved

  const canApprove = store.state.session.user.aUserPerms.quote.approve

  if (isExpired) {
    steps = [
      ...steps,
      {
        name: 'This estimate has expired and needs to be sent again.',
        short: 'Resend',
        action: (obj, button, grid) =>
          store.dispatch('send', {
            id: obj.quote_id,
            type: 'quote',
            grid,
            button
          }),
        glyph: 'paper-plane',
        color: 'green-500'
      }
    ]

    return steps
  } else if (waitingForApproval && canApprove) {
    steps = [
      ...steps,
      ...(contextual
        ? []
        : [
            {
              name: 'This estimate has not been approved yet.',
              short: 'Review',
              action: (obj) =>
                store.dispatch('edit', {
                  type: 'quote',
                  id: obj.quote_id
                }),
              glyph: 'memo-circle-info',
              color: 'surface-600'
            }
          ])
    ]
  } else if (waitingForApproval && !canApprove) {
    steps = [
      ...steps,
      {
        name: 'This estimate needs manager approval before being sent.',
        short: 'Edit',
        action: (obj) =>
          store.dispatch('edit', {
            type: 'quote',
            id: obj.quote_id
          }),
        glyph: 'pencil',
        color: 'surface-600'
      }
    ]
  } else if (!waitingForApproval && object.quote_status === 'p') {
    if (!object.quote_time_sent || object.quote_time_sent <= 0) {
      steps = [
        ...steps,
        {
          name: 'This estimate has not been sent yet.',
          short: 'Send',
          modal: 'QuoteEmail',
          action: (obj, button, grid) =>
            store.dispatch('send', {
              id: obj.quote_id,
              type: 'quote',
              grid,
              button
            }),
          glyph: 'paper-plane',
          color: 'green-500'
        }
      ]
    } else if (!object.quote_time_seen || object.quote_time_seen <= 0) {
      steps = [
        ...steps,
        {
          name: 'This estimate has not been viewed by the client.',
          short: 'Follow up',
          action: (obj, button, grid) =>
            store.dispatch('phone/call', {
              number: obj.oClient.user_phone,
              tags: {
                quote_id: obj.quote_id,
                client_id: obj.client_id,
                phone_call_desc: 'Called to see if client saw quote.'
              },
              grid,
              button
            }),
          glyph: 'phone',
          color: 'surface-600'
        }
      ]
    } else {
      // Old, should we decline?
      if (object.quote_time_seen < moment().subtract(2, 'months').format('x')) {
        steps = [
          ...steps,
          {
            name: `The client last viewed this estimate ${_.format(object.quote_time_seen, 'timerelative')}.`,
            short: 'Decline',
            action: (obj, button, grid) =>
              store.dispatch('Quote/markMultiple', {
                markAs: _.format('d', 'status'),
                selected: _.makeArray(obj),
                grid,
                button,
                go: false
              }),
            glyph: 'broom-wide',
            color: 'deep-red-700'
          }
        ]
      } else {
        // and, follow up
        steps = [
          ...steps,
          {
            name: `The client last viewed this estimate ${_.format(object.quote_time_seen, 'timerelative')}.`,
            short: 'Follow up',
            action: (obj, button, grid) =>
              store.dispatch('phone/call', {
                number: obj.oClient.user_phone,
                tags: {
                  quote_id: obj.quote_id,
                  client_id: obj.client_id,
                  phone_call_desc: `Called to follow up on proposal that was sent ${_.format(obj.quote_time_sent, 'timerelative')}.`
                },
                grid,
                button
              }),
            glyph: 'phone',
            color: 'surface-600'
          }
        ]
      }
    }
  } else if (object.quote_status === 'k') {
    steps = [
      ...steps,
      {
        name: 'Mark this project as in-progress.',
        short: 'Mark in-progress',
        action: (obj, button, grid) =>
          store.dispatch('Quote/markMultiple', {
            markAs: _.format('f', 'status'),
            grid,
            button,
            selected: [
              {
                type: 'quote',
                quote_id: obj.quote_id
              }
            ]
          }),
        glyph: 'hammer',
        color: 'green-500'
      }
    ]
  } else if (
    (object.quote_status === 'k' || object.quote_status === 'f') &&
    object.quote_sum_invoiced < object.quote_price_gross &&
    store.state.session.company.aoModules.invoice
  ) {
    steps = [
      ...steps,
      {
        name: 'This project has never been invoiced.',
        short: 'Create invoice',
        action: (obj, button, grid) =>
          store.dispatch('modal/open', {
            modal: {
              name: 'InvoiceNew',
              saved: () => {
                if (grid) grid.reload()
              }
            },
            embue: {
              quote_id: obj.quote_id
            },
            grid,
            button
          }),
        glyph: 'plus',
        color: 'green-500'
      }
    ]
  } else if (
    (object.quote_status === 'k' || object.quote_status === 'f') &&
    !object.change_order_time_booked
  ) {
    steps = [
      ...steps,
      {
        name: 'This project has changes that have not been approved by the client.',
        short: 'Send change order',
        action: (obj, button, grid) =>
          store.dispatch('send', {
            id: _.makeArray(obj)[0].quote_id,
            type: 'quote',
            grid,
            button
          }),
        glyph: 'paper-plane',
        color: 'green-500'
      }
    ]
  } else if (
    (object.quote_status === 'k' || object.quote_status === 'f') &&
    object.change_order_time_booked
  ) {
    steps = [
      ...steps,
      {
        name: 'The latest change order was sent.',
        short: 'Follow up',
        action: (obj, button, grid) =>
          store.dispatch('phone/call', {
            number: obj.oClient.user_phone,
            tags: {
              quote_id: obj.quote_id,
              client_id: obj.client_id,
              phone_call_desc: 'Called to see if client saw quote.'
            },
            grid,
            button
          }),
        glyph: 'phone',
        color: 'surface-600'
      }
    ]
  } else if (object.quote_status === 'k' || object.quote_status === 'f') {
    if (
      object.quote_status === 'f' &&
      object.quote_paid_percentage < 1 &&
      object.quote_uninvoiced_net > 0 &&
      store.state.session.company.aoModules.invoice
    ) {
      steps = [
        ...steps,
        {
          name: 'This project has only been partially invoiced.',
          short: 'Create invoice',
          action: (obj, button, grid) =>
            store.dispatch('modal/open', {
              modal: {
                name: 'InvoiceNew',
                saved: () => {
                  if (grid) grid.reload()
                }
              },
              embue: {
                quote_id: obj.quote_id
              },
              grid,
              button
            }),
          glyph: 'plus',
          color: 'green-500'
        }
      ]
    } else if (object.quote_invoiced_percentage > object.quote_paid_percentage) {
      steps = [
        ...steps,
        {
          name: 'This project has unpaid invoices.',
          short: 'Invoices',
          action: (obj, button, grid) =>
            store.dispatch('view', {
              type: 'invoice',
              filters: {
                quote_id: obj.quote_id
              },
              filterText: {
                quote_id: obj.quote_name
              },
              grid,
              button
            }),
          glyph: 'arrow-right',
          color: 'surface-600'
        }
      ]
    } else if (
      object.quote_paid_percentage === 1 &&
      store.state.session.company.aoModules.invoice
    ) {
      steps = [
        ...steps,
        {
          name: 'All invoices for this job have been paid.',
          short: 'Close job',
          action: (obj, button, grid) =>
            store.dispatch('Quote/markMultiple', {
              selected: [
                {
                  quote_id: obj.quote_id
                }
              ],
              markAs: _.format('g', 'status'),
              grid,
              button
            }),
          glyph: 'check',
          color: 'green-500'
        }
      ]
    } else if (
      object.quote_status === 'f' &&
      (!store.state.session.company.aoModules.invoice ||
        object.quote_paid_percentage === 0 ||
        object.quote_invoiced_percentage === 0) &&
      object.quote_invoiced_percentage <= 0
    ) {
      steps = [
        ...steps,
        {
          name: 'This project is currently set to in-progress.',
          short: 'Close job',
          action: (obj, button, grid) =>
            store.dispatch('Quote/markMultiple', {
              selected: [
                {
                  quote_id: obj.quote_id
                }
              ],
              markAs: _.format('g', 'status'),
              grid,
              button
            }),
          glyph: 'check',
          color: 'green-500'
        }
      ]
    }
  }

  return steps
}
