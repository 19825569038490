<template>
  <Drop targetAttachment="bottom left" position="bottom left" offset="5px 5px">
    <template #button>
      <div
        class="w-full h-full"
        v-tooltip="{
          content: 'Click for client details',
          placement: 'bottom',
          delay: { show: 700, hide: 300 }
        }"
      >
        <font-awesome-icon
          class="text-cool-gray-600 text-lg cursor-pointer"
          :icon="['far', 'user']"
        />
      </div>
    </template>
    <div>
      <div class="mb-2">
        <p style="font-weight: 500">Client Details</p>
      </div>
      <BtnGroup class="client-button-group flex justify-center">
        <Btn
          class="more info"
          :action="() => $store.dispatch('Client/call', { id: client.client_id })"
        >
          <font-awesome-icon :icon="['fad', 'phone']" />
        </Btn>
        <Btn
          class="more info ml-2"
          :action="() => $store.dispatch('Client/message', { id: client.client_id })"
        >
          <font-awesome-icon :icon="['fad', 'comments']" />
        </Btn>
        <Btn
          class="more info ml-2"
          :action="() => $store.dispatch('Client/map', { id: client.client_id })"
        >
          <font-awesome-icon icon="map-location-dot" />
        </Btn>
      </BtnGroup>
      <div class="border-muted my-2 border-top w-100"></div>
      <div>
        <small class="text-muted">Name</small>
        <p>
          {{ client.client_name }}
        </p>
      </div>
      <div
        v-if="client.user_phone"
        @click="() => $store.dispatch('copyToClipboard', client.user_phone)"
      >
        <small class="text-muted">Phone</small>
        <p class="client-info-data">
          {{ $f.phone(client.user_phone, client.country_abbr) }}
        </p>
      </div>
      <div
        v-if="client.user_email"
        @click="() => $store.dispatch('copyToClipboard', client.user_email)"
      >
        <small class="text-muted">Email</small>
        <p>
          {{ client.user_email }}
        </p>
      </div>
      <div
        @click="
          () =>
            $store.dispatch(
              'copyToClipboard',
              `${client.user_address || ''}, ${client.user_city || ''}, ${client.user_prov || ''}`
            )
        "
      >
        <small class="text-muted">Address</small>
        <p v-if="client.user_suite">
          {{ $f.truncate(client.user_suite, 24) }}
        </p>
        <p v-if="client.user_address">
          {{ $f.truncate(client.user_address, 24) }}
        </p>
        <p v-if="client.user_city || client.user_prov">
          {{ client.user_city }} {{ client.user_prov }}
        </p>
        <p v-if="client.user_postal">
          {{ client.user_postal }}
        </p>
      </div>
    </div>
  </Drop>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import Drop from '@/components/ui/Drop.vue'
import BtnGroup from '@/components/ui/BtnGroup.vue'
import Btn from '@/components/ui/Btn.vue'
import FontAwesomeIcon from '@/fontAwesome'
import filters from '@/filters'

const $f = ref(filters)

defineProps({
  client: {
    type: Object,
    required: true
  }
})
</script>
