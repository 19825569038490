<template>
  <div
    :data-label="abbr"
    v-if="abbr"
    :key="abbr"
    class="assembly-dimensions--dimension-container"
    :style="combinedStyle"
    :class="{ 'assembly-dimensions--dimension-inherit': value.demote }"
  >
    <help btnClass="more xs muted">
      <template #before>
        <div class="assembly-dimensions--dimension-label">
          {{ name || abbr }}
        </div>
      </template>

      <template #title>
        {{ name }}
      </template>

      <p class="text-info">Required by:</p>
      <ul>
        <li v-for="(item, key) in requiredBy.split(',')" :key="key">
          {{ item }}
        </li>
      </ul>

      <p>
        {{ (dimTypes[abbr] && dimTypes[abbr].desc) || 'No instructions provided' }}
      </p>

      <p>
        Link to this dimension in the calculator, item quantities or equations by using the
        variable:
      </p>

      <DimensionBadge :dim="value" />
    </help>

    <span class="usedby-explanation"> Used by: {{ $f.truncate(requiredBy, 50) }} </span>

    <div class="assembly-dimensions--dimension-contents">
      <div v-if="value.demote && !forceShow" class="demote-explanation">
        <div class="demoted-value">
          <SuggestionButton @click="forceShow = true">
            = {{ $f.number(value.value) }} {{ measure }}
          </SuggestionButton>
        </div>

        <template v-if="(!isRoot || isAssemblyRoot) && isLinked && !isCalculated">
          Calculated using {{ name }} from {{ $f.truncate(parentName, 22) }}
        </template>
        <template v-else-if="isCalculated"> Calculated using other dimensions </template>
        <template v-else-if="value.equation"> Calculated using a formula </template>
      </div>
      <btn-group style="max-width: 100%" v-else>
        <btn
          v-if="isLinked"
          style="flex: 0 0 20%; min-width: unset"
          class="info"
          :style="linkStyle"
          @click="isLinked = 0"
          v-tooltip="inheritMessage"
        >
          <font-awesome-icon icon="drafting-compass" />
        </btn>
        <btn
          v-else-if="!isRoot || isAssemblyRoot"
          style="flex: 0 0 20%; min-width: unset"
          class=""
          @click="isLinked = 1"
          v-tooltip="`Click to link the dimension to ${parentName} ${name}`"
        >
          <font-awesome-icon icon="link" />
        </btn>

        <field
          type="calculator"
          ref="calculator"
          style="flex: 1 60%; min-width: unset"
          class="text-center number"
          :class="{
            danger: missing,
            muted: isLinked && !missing
          }"
          :style="linkStyle"
          :dimensions="dimensions"
          :showButton="false"
          :measure="measure"
          :placeholder="isRoot && store === 'Assembly' ? 'inherits' : '0'"
          :equation="value.equation"
          :value="value.value"
          :format="value.measure === 'ft' ? 'imperial' : 'number'"
          @input="(value, equation) => setDimension(value, equation)"
        />

        <choose
          style="flex: 0 0 20%; min-width: unset"
          :staticSet="measuresPreset"
          v-model="measure"
          :return-array="false"
          :default="defaultMeasure"
        />
      </btn-group>
    </div>
  </div>
</template>

<script>
import DimensionMixin from './DimensionMixin'
import SuggestionButton from '../../ui/SuggestionButton.vue'
import DimensionBadge from '../../ui/DimensionBadge.vue'

/**
 * This component needs to be specifically used in QuoteDimensions component only
 */
export default {
  name: 'Dimension',
  components: { DimensionBadge, SuggestionButton },
  mixins: [DimensionMixin],

  data() {
    return {
      forceShow: 0
    }
  },

  watch: {
    'value.demote': {
      handler() {
        this.forceShow = 0
      }
    }
  },

  computed: {
    combinedStyle() {
      let demotionStyle = ''
      if (this.value.demote) {
        demotionStyle = `${this.nameStyle} color: #222 !important;`
      }
      return `${this.borderStyle} ${demotionStyle}` // ${!this.value.demote ? this.nameStyle : ''}
    },

    linkStyle() {
      if (this.value.inherit)
        return { backgroundColor: `#${this.value.color} !important`, color: '#444 !important' }

      return {}
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.assembly-dimensions--dimension-var-label {
  border-radius: 0.25em;
  padding: 0.05em 0.5em 0.1em 0.5em;
  color: $pitch-black;
  font-size: 0.95em;
  line-height: 1.1;

  code {
    font-weight: bold;
    color: $pitch-black;
  }
}
.assembly-dimensions--dimension-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10em;
  flex: 0 0 18em;
  background: $cool-gray-600;
  padding: 0.5em;
  margin: 0;
  border: none;
  border-radius: 2.1em;
  position: relative;
  overflow: hidden;
  transition: all 0.35s ease-out;
  border: 2px solid transparent;
  // border-width: 1px 4px 4px 1px;
  border-width: 5px;
  margin: 1em;

  &.assembly-dimensions--dimension-inherit {
    opacity: 0.6;
    background: $cool-gray-300;
    .assembly-dimensions--dimension-label {
      color: $cool-gray-800;
    }

    &:hover {
      opacity: 1;
    }
  }
  .usedby-explanation {
    font-size: 0.9em;
    color: $cool-gray-600;
    mix-blend-mode: multiply;
  }

  .demoted-value {
    font-size: 2em;
    font-weight: bold;
    color: $cool-gray-900;
  }
  .demote-explanation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    color: $cool-gray-600;
    mix-blend-mode: multiply;
  }

  .assembly-dimensions--dimension-contents {
    display: flex;
    justify-content: stretch;
    align-items: center;
    font-weight: normal;
    line-height: 1.1;
    max-width: 100%;
  }

  .assembly-dimensions--dimension-label {
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    line-height: 1;
  }
  .assembly-dimensions--dimension-sublabel {
    font-size: 0.7em;
    font-weight: normal;
    display: block;
    margin: 0.1em 0 0.4em 0;
    text-align: center;

    .fa {
      font-size: 0.5em;
      top: 0.001em;
    }
  }

  &.hasValue {
    &.calculated {
      background: $blue-print-200;
      .assembly-dimensions--dimension-label {
        color: $blue-print-700;
      }
    }
  }

  .link-button {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &.more.danger {
      background: $deep-red-800 !important;
      color: $flame-white !important;
      border-top-left-radius: 0 !important;
    }
  }

  &:hover:not(.assembly-dimensions--dimension-container-loading) {
    box-shadow: $shadow;
    // background: lighten($brand-info, 10%) !important;
    cursor: pointer;
    // transform: perspective(30em);
    // box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.15),
    // 0 0.25em 0.25em rgba(0, 0, 0, 0.05),
    // 0 -0.25em 0.25em rgba(0, 0, 0, 0.05) !important;
    // border-radius: 5px !important;
    &:first-child,
    &:last-child {
      //   border-radius: 5px !important;
    }

    // .assembly-dimensions--dimension-label {
    //   color: $flame-white !important;
    // }
  }
}
</style>
