<template>
  <div v-if="canShowContractorRatings">
    <grid
      ref="grid"
      type="contractorRating"
      :camelCase="true"
      title="Ratings"
      :isMain="false"
      :filters="{ contractorId: contractorId }"
      :createNew="false"
      :showToolbar="false"
      :order="[['timeCreated', 'desc']]"
      :visible="['creatorId', 'overallRating', 'description']"
    >
      <template #filterPresetBefore>
        <CreateRatingSelection type="contractorRating" :ratingData="contractorRatingData" />
        <RatingSummary :creatorId="contractorId" :ratingData="contractorRatingData" />
      </template>
    </grid>
  </div>
  <div v-else-if="!hasPermissionsToSeeContractorRatings">
    <Section>
      <Container>
        <CardSection>
          <Warning>
            <template #title>You do not have permissions to view ratings.</template>
            <p>Request "client viewing" permissions from your company's administrator.</p>
          </Warning>
        </CardSection>
      </Container>
    </Section>
  </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import Grid from '@/components/ui/Grid/Grid.vue'
import RatingSummary from '@/components/ratings/RatingSummary.vue'
import CreateRatingSelection from '@/components/ratings/CreateRatingSelection.vue'
import useRating from '@/components/composables/Rating'
import eventBus from '@/eventBus'

const props = defineProps({
  contractorId: {
    default: null
  }
})

const store = useStore()

const { initializeForContractor } = useRating()
const contractorRatingData = ref(null)

const eventKey = () => `${props.contractorId}-initialized-for-contractor`

onBeforeMount(() => {
  initializeForContractor({ contractorId: props.contractorId })
  eventBus.$on(eventKey(), () => {
    contractorRatingData.value = store.getters['rating/getRatingDataByContractorId'](
      props.contractorId
    )
  })

  eventBus.$on('completed-fetching-data', () => {
    contractorRatingData.value = store.getters['rating/getRatingDataByContractorId'](
      props.contractorId
    )
  })
})

onBeforeUnmount(() => {
  eventBus.$off(eventKey())
})

const hasPermissionsToSeeContractorRatings = computed(() => {
  if (!store.state.session || !store.state.session.user || !store.state.session.user.aUserPerms)
    return false
  const perms = store.state.session.user.aUserPerms
  return perms.client.read > 0
})

const canShowContractorRatings = computed(() => {
  return store.state.session.scope.company && hasPermissionsToSeeContractorRatings.value
})
</script>
