<template>
  <container>
    <card-section>
      <warning v-if="/k|f/.test(quote.quote_status) && !quote.change_order_time_booked">
        <template #title>
          <span>Project has changes that have not been approved by the client yet!</span>
        </template>

        <p>
          Because the client hasn't approved all the changes on this project, the value for the
          items below will represent the values from the
          <strong>last approved change-order/version of this project.</strong>
        </p>

        <p>
          To synchronize the payment amounts with the latest (unapproved) version of your items you
          must manually mark the change-order/version as 'Booked'. To do that, go to the "Changes"
          tab on the project and on the top-most change-order open the action drop-down, then choose
          "Approval<span class="caret-down"></span>", and then "Mark as approved (without
          contract)".
          <strong
            >This may not represent a legal acceptance from the client unless you have some other
            contract.</strong
          >
        </p>
      </warning>
    </card-section>

    <card-section>
      <template #title>Transactions</template>
      <list
        ref="list"
        type="transaction"
        :showOptions="true"
        :filters="filters"
        :isMain="isMain"
        searchMethod="filter"
      >
        <template #list>
          <card-list>
            <card-list-collapse v-for="transaction in list.set" :key="transaction.transaction_id">
              <template #title>
                <preview-transaction
                  style="flex: 1 100%"
                  :showEdit="false"
                  :grid="$refs.list"
                  :object="transaction"
                />
              </template>
              <transaction-breakdown :object="transaction" />
            </card-list-collapse>
          </card-list>
        </template>
      </list>
    </card-section>

    <card-section>
      <template #title>Related items</template>
      <card-list v-if="!loading">
        <card-list-item v-for="item in items" :key="item.item_id">
          <div class="flex justify-between items-center" style="width: 100%">
            <div class="flex flex-col justify-center items-start">
              {{ item.item_name }}

              <small
                class="mt-2"
                :class="{
                  'text-info': item.item_vendor_unpaid_gross < 0.01,
                  'text-danger': item.item_vendor_unpaid_gross > 0.01
                }"
              >
                Paid to vendor:
                <strong
                  >{{ $f.currency(item.item_vendor_paid_gross) }} /
                  {{
                    $f.currency(item.item_vendor_paid_gross + item.item_vendor_unpaid_gross)
                  }}</strong
                >
              </small>
            </div>

            <btn
              class="btn round muted"
              v-if="item.item_vendor_is_fully_paid === 0 && item.item_vendor_unpaid_gross !== 0"
              @click="markItem(item.item_id, item.item_vendor_is_fully_paid ? 0 : 1)"
            >
              <template #icon>
                <font-awesome-icon v-if="item.item_vendor_is_fully_paid" icon="check" />
                <font-awesome-icon v-else icon="ban-circle" />
              </template>
              Mark item fully paid?
              <toggle class="ml-2" :value="item.item_vendor_is_fully_paid" />
            </btn>
          </div>
        </card-list-item>
      </card-list>
      <spinner :loading="1" v-else />
    </card-section>
  </container>
</template>

<script>
import BodyMixin from '../mixins/Body'
import BtnMixin from '../mixins/Button'
import TransactionBreakdown from '../ui/TransactionBreakdown.vue'
import CurrencyFilter from '../mixins/CurrencyFilter'

export default {
  mixins: [BodyMixin, BtnMixin, CurrencyFilter],
  props: {
    quote: {
      default: null
    }
  },
  components: { TransactionBreakdown },
  methods: {
    async markItem(itemId, paid) {
      const foundItem = this.items.find((item) => item.item_id === itemId)
      const alteredItem = {
        ...foundItem,
        item_vendor_is_fully_paid: paid ? 1 : 0
      }
      this.items.splice(this.items.indexOf(foundItem), 1, alteredItem)
      await this.$store.dispatch('Item/markVendorFullyPaid', {
        vendorFullyPaid: paid ? 1 : 0,
        id: itemId
      })
      this.getItems()
      return itemId
    },
    async getItems() {
      const { set } = await this.$store.dispatch('Item/filter', {
        filters: {
          item_id: this.filters.item_id
        }
      })
      this.items = set
      await this.$nextTick()
      this.endLoading()
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.addLoading()
    this.getItems()
  }
}
</script>
