<template>
  <div class="steps-wizard--container container-element">
    <a href="#" ref="anchor"></a>

    <div class="steps-wizard--header">
      <div class="flex flex-row justify-between" v-if="steps.length > 0">
        <span
          class="flex steps-wizard--header-step"
          v-for="(stepTitle, index) in steps"
          :key="index"
        >
          <btn
            class="more md border step-indicator"
            :class="
              index + 1 === step
                ? 'border-info bg-white active'
                : index < step
                  ? 'border-info bg-info completed'
                  : 'border-dark bg-white'
            "
            :disabled="disableStepNavigation"
            v-tooltip="stepTitle"
            @click="() => goToStep(index + 1)"
            >{{ index + 1 }}</btn
          >
          <h5 v-if="showStepTitle" ref="stepTitle">{{ stepTitle || 'Step' }}</h5>
        </span>
      </div>
      <div v-else-if="showStepTitle">
        <span class="flex mr-4">
          <btn rounded class="more px-0 p-2 default sm">{{ step }}</btn>
          <h5 ref="stepTitle">Step</h5>
        </span>
      </div>
    </div>

    <div class="progress mb-5 wizard-progress" style="height: 3px" v-if="showStepTitle">
      <div
        class="progress-bar bg-info"
        role="progressbar"
        :style="`width: ${percentComplete}%`"
      ></div>
    </div>

    <div class="steps-wizard--content px-4 pt-4">
      <h4 v-if="displayContentTitle && steps[step - 1]">{{ steps[step - 1] || '' }}</h4>
      <slot> </slot>
    </div>

    <div
      class="flex justify-between items-center steps-wizard--buttons px-2"
      v-if="showButtonsLocal"
    >
      <btn
        class="btn lg"
        :style="{ opacity: showPrev ? 1 : 0 }"
        :class="prevDisabled || disableBack ? 'muted' : 'default'"
        :disabled="prevDisabled || disableBack"
        :action="prev"
      >
        <template #icon>
          <font-awesome-icon icon="arrow-left" />
        </template>
        Back
      </btn>
      <btn
        v-if="!showSkipStep"
        class="btn lg"
        ref="nextBtn"
        :style="{ opacity: showNext ? 1 : 0 }"
        :class="nextDisabled ? 'muted' : 'info'"
        :disabled="nextDisabled"
        :action="next"
      >
        <template #icon>
          <font-awesome-icon :icon="nextButtonIcon" />
        </template>
        {{ nextButtonText }}
      </btn>

      <btn
        class="btn lg"
        v-if="currentStepComponent && currentStepComponent.props.action"
        ref="actionBtn"
        :action="
          currentStepComponent &&
          currentStepComponent.props.action &&
          currentStepComponent.props.action.handle
        "
      >
        <template #icon>
          <font-awesome-icon :icon="nextButtonIcon" />
        </template>
        {{ currentStepComponent.props.action.text }}
      </btn>

      <a
        v-if="showSkipStep"
        class="text-md skip-step text-info"
        @click="currentStepComponent.onSkip"
      >
        Skip
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardSteps',
  emits: ['input', 'step', 'percentComplete', 'onPrev'],
  data() {
    return {
      step: 1,
      stepComponents: []
    }
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true
    },
    showStepTitle: {
      default: true
    },
    steps: {
      type: Array,
      default: () => []
    },
    start: {
      type: Number,
      default: 1
    },
    disableStepNavigation: {
      type: Boolean,
      default: true
    },
    disableBack: {
      type: Boolean,
      default: false
    },
    displayContentTitle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    step(s) {
      this.$emit('input', s)
      this.$emit('step', s)
      this.$emit('percentComplete', c.divide(s, this.activeStepComponents.length))
    },
    activeStepComponents() {
      this.distributeSteps()
    },
    start() {
      this.step = this.start
    }
  },
  computed: {
    showSkipStep() {
      return this.currentStepComponent && this.currentStepComponent.props.onSkip
    },
    showButtonsLocal() {
      return (
        this.showButtons &&
        (!this.currentStepComponent || this.currentStepComponent.props.showButtons)
      )
    },
    prevDisabled() {
      return !this.showPrev
    },
    nextDisabled() {
      return !this.showNext || !this.canContinue
    },
    activeStepComponents() {
      return this.stepComponents.filter((step) => !step.props.skipIf)
    },
    currentStepComponent() {
      return this.activeStepComponents[this.step - 1]
    },
    nextButtonText() {
      return (
        (this.currentStepComponent && this.currentStepComponent.props.nextButtonText) || 'Continue'
      )
    },
    nextButtonIcon() {
      return (
        (this.currentStepComponent && this.currentStepComponent.props.nextButtonIcon) ||
        'arrow-right'
      )
    },
    canContinue() {
      return this.currentStepComponent && this.currentStepComponent.props.canContinue
    },
    totalSteps() {
      return this.activeStepComponents.length
    },
    showNext() {
      return this.step < this.totalSteps
    },
    showPrev() {
      return this.step > 1
    },
    percentComplete() {
      return Math.round((this.step / this.totalSteps) * 100)
    }
  },
  methods: {
    async next() {
      if (this.nextDisabled) {
        if (this.showNext) {
          this.$store.dispatch('alert', {
            message: 'Complete required information before continuing',
            error: true
          })
        }

        return false
      }
      this.currentStepComponent.$emit('next')
      await this.$nextTick()
      if (this.$refs.nextBtn) this.$refs.nextBtn.addLoading()
      const verifyStep = await (
        (this.currentStepComponent && this.currentStepComponent.beforeNext) ||
        (async () => {})
      )()
      if (verifyStep === false) {
        if (this.$refs.nextBtn) this.$refs.nextBtn.removeLoading()
        return null
      }
      this.step = this.step + 1
      await this.$nextTick()
      await this.scrollUp()
      if (this.$refs.nextBtn) this.$refs.nextBtn.endLoading()
      return true
    },
    prev() {
      this.step = this.step - 1
      this.$emit('onPrev', this.step)
    },
    async scrollUp() {
      await this.$nextTick()
      return c.scrollTo(this.$refs.anchor, 100, 300)
    },
    distributeSteps() {
      let step = 1
      const active = this.activeStepComponents
      this.stepComponents.forEach((slot) => {
        if (active.includes(slot)) {
          slot.methods.setStep(step)
          step += 1
        } else {
          slot.methods.setStep(0)
        }
      })
    },
    goToStep(step) {
      this.step = step
    }
  },
  mounted() {
    this.stepComponents = this.$slots
      .default()
      .filter((slot) => slot && slot.type.name === 'Step')
      .map((slot) => slot.type)
    console.log(this.stepComponents, 'this.stepComponents')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.steps-wizard--container {
  position: relative;
  width: 100%;
  h3 {
    margin-left: 1em;
  }
}
.steps-wizard--content {
  margin: 1em auto 2em auto;
}
.wizard-progress {
  background: $cool-gray-500 !important;
}

#app.small-format {
  .steps-wizard--container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    h3 {
      margin-left: 1em;
    }
  }
  .steps-wizard--content {
    padding: 0 !important;
    margin: 2em auto;
  }
  .steps-wizard--buttons {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.step-indicator {
  &.disabled {
    span.button--text {
      background-color: $flame-white !important;
    }
  }

  &.completed,
  &.completed.disabled {
    span.button--text {
      color: $flame-white !important;
      background-color: $blue-print-700 !important;
    }
  }
}

.steps-wizard--header {
  display: flex;
  justify-content: center;
  > div {
    .steps-wizard--header-step {
      flex: 1;
      position: relative;
      z-index: 2;
      &:not(:last-child) {
        min-width: 100px;
        &::after {
          content: '';
          width: calc(100% - 40px);
          margin: 0 auto;
          height: 2px;
          background-color: $cool-gray-500;
          position: absolute;
          right: 0;
          left: 30px;
          top: calc(50% - 1px);
          z-index: -1;
        }
      }
    }
  }
}

.skip-step {
  font-size: 1.2em !important;
}
</style>
