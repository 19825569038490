import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  useCellEditing(args) {
    const {
      getStepsText,
      getCellType,
      setCellText,
      getCellData,
      addFetchedName,
      getCellObjectType,
      $store,
      currentCellData,
      currentCell,
      refStep,
      hoverCell,
      hoverCellData,
      getColDef,
      topSheet,
      getRowSheet,
      refIdToColRow,
      getRefId,
      getRowData,
      cellData,
      getCurrentCellFormula,
      getCellValue,
      cellFocused,
      handleWindowKeyUp,
      moveCell,
      getRowId,
      isCellDisabled,
      isCellReadOnly
    } = args

    const $this = getCurrentInstance().proxy
    const refCalc = ref({})
    const focusCell = async (cell = currentCell.value) => {
      if (!c.jsonEquals(currentCell.value, cell)) {
        await moveCell(...cell)
        await $this.$nextTick()
      }

      if (!refEditableDiv.value || disableCellEdit.value) return

      await $this.$nextTick()

      if (/number|text/.test(getCellType(...cell))) {
        currentCellValue.value = getCurrentCellFormula()
        cellFocused.value = true

        await $this.$nextTick()

        refCalc.value?.setValue(currentCellValue.value)
        refCalc.value?.focus()
        refCalc.value?.setCursorAtEnd()
      }

      handleWindowKeyUp()
    }

    const calcVariables = computed(() => {
      const cell = currentCell.value
      const [col, row] = cell
      const sheet = getRowSheet(row)
      const rowData = getRowData(row)
      const id = getRowId(row)
      const colDef = getColDef(col, sheet)
      const varGetter = colDef.variables || (() => ({}))

      return varGetter({
        cell,
        col,
        row,
        id,
        rowData,
        sheet,
        colDef,
        cellData
      })
    })

    onMounted(() => {
      $this.$on('commit', commitValue)
      $this.$on('dblclickCell', handleDoubleClick)
      window.focusCell = focusCell
    })

    const stepMouseDown = ref(false)
    const hoveringStep = ref(null)
    const disableCellEdit = ref(true)
    const showOriginLabel = ref(false)
    const currentCellValue = ref('')
    const showEditableDiv = ref(false)
    const showActionDiv = ref(false)
    const showChooseDiv = ref(false)
    const showCalculatorDiv = ref(false)
    const chooseOptions = ref({})
    const refChooseDiv = ref({})
    const refActionDiv = ref({})
    const refEditableDiv = ref({})
    const currentCellValueFormatted = computed(() => currentCellData.value.fmt)
    const currentCellDisabled = computed(() => isCellDisabled(...currentCell.value))
    const currentCellReadOnly = computed(() => isCellReadOnly(...currentCell.value))

    const injectValue = (text) => {
      if (cellFocused.value) refCalc.value?.injectValue(text)
      setCurrentCellFormula(text)
    }
    const forceValue = (text) => {
      if (cellFocused.value) refCalc.value?.forceValue(text)
      setCurrentCellFormula(text)
    }

    const handleDoubleClick = async () => {
      if (currentCellDisabled.value) return
      if (currentCellReadOnly.value) return
      await $this.$nextTick()
      focusCell()
    }

    const setFieldEquation = (sheet, id, field, equation) => {
      const refId = getRefId(field, id, sheet)

      if (!(refId in refIdToColRow.value)) {
        throw new Error(`${refId} is not found in map`)
      }

      const [col, row] = refIdToColRow.value[refId]

      setCellText(col, row, equation, false)
    }
    const setCurrentCellFormula = (formula) => {
      setCurrentCellValue(formula) // same same
    }

    const setCurrentCellValue = (value) => {
      if (
        !currentCell.value ||
        typeof currentCell.value[0] === 'undefined' ||
        typeof currentCell.value[1] === 'undefined'
      )
        return

      setCellText(...currentCell.value, value)
    }
    const handleInput = (value) => {
      currentCellValue.value = value
    }
    const handleChooseSelected = ({ value, formatted }) => {
      const valueString = `${value}`

      const [col, row] = currentCell.value
      const type = getCellObjectType(col, getRowSheet(row))
      addFetchedName(type, valueString, formatted)
      // will find the name in fetched names to format
      setCellText(col, row, value)
    }
    const enableCellEdit = () => {
      showEditableDiv.value = true
    }
    const enableCellCalculator = () => {
      enableCellEdit() // todo
    }
    const enableCellAction = () => {
      showActionDiv.value = true
      const [col, row] = currentCell.value
      const colDef = getColDef(col, getRowSheet(row))
      const action = colDef.action
      const actionText = colDef.actionText ?? ((action && getCellValue(col, row)) || '')

      const actionDiv = refActionDiv.value
      if (actionDiv && action && actionText) {
        actionDiv.innerText = `${actionText && `${actionText}`.trim() !== 'null' ? actionText : ''} ↗`
      } else if (actionDiv && action) {
        actionDiv.innerText = `↗`
      } else {
        actionDiv.innerText = ''
      }
    }
    const enableCellChoose = () => {
      showChooseDiv.value = true
      const colDef = getColDef(currentCell.value[0], topSheet.value)
      chooseOptions.value = colDef.choose || {}
    }
    const enableCellEnum = () => {
      enableCellChoose()
      const colDef = getColDef(currentCell.value[0], topSheet.value)
      chooseOptions.value = {
        staticSet: colDef.enum.map((val) => ({
          text: val,
          value: val
        }))
      }
    }
    const handleCheckboxClick = (e, coords) => {
      const [col, row] = coords

      const cellType = getCellType(col, row)

      let handled = false
      if (cellType === 'checkbox') {
        handled = true
        setCellText(col, row, getCellValue(col, row) ? 0 : 1)
      }

      return { handled }
    }

    const showProgressDiv = ref(true)
    const hoverStepsText = computed(() => {
      const index = hoveringStep.value

      const [col, row] = hoverCell.value
      if (index === null || getCellType(col, row) !== 'progress') return ''

      const value = [...hoverCellData.value.raw]
      value[index] = value[index] ? 0 : 1

      return getStepsText(progressSteps.value, value)
    })
    const progressSteps = computed(() => {
      const [col, row] = hoverCell.value
      if (getCellType(col, row) === 'progress') {
        const colDef = getColDef(hoverCell.value[0], getRowSheet(hoverCell.value[1]))
        return (
          colDef.progress &&
          colDef.progress.steps.map((step) => ({
            ...step,
            backgroundColor: colDef.colorRange || colDef.colorRange || '#0b4bff'
          }))
        )
      }

      return []
    })
    const stepOverlay = (index) => {
      if (index === null) return ''

      const step = progressSteps.value[index]
      const steps = progressSteps.value.length
      const padding = 5

      const [col, row, [, , fullWidth, rowHeight]] = hoverCell.value
      if (col === null || row === null) return ''

      if (hoveringStep.value !== index) return ''

      if (hoverCellData.value.raw[index] && !step.disabled) {
        const stepWidth = fullWidth / steps
        const width = stepWidth - padding * 2
        const height = rowHeight - padding * 2

        const x1 = padding
        const y1 = padding

        const x2 = width + (padding - 1)
        const y2 = height + (padding - 1)

        const thickness = 2

        return `
          <svg width="${stepWidth - 1}px" height="${rowHeight - 1}px" xmlns="http://www.w3.org/2000/svg"
               style="pointer-events: none; position: absolute; top: 0; left: 0; border-radius: 3px;">
              <!-- Draw the square -->
            <rect
            x="${padding}" y="${padding}"
             width="${width - 1}" height="${height - 1}" fill="white" stroke="#eb1631"
             stroke-width="${thickness}" rx="3" ry="3" />

              <!-- Draw the slash -->
            <line
                x1="${x1}" y1="${y1}"
                x2="${x2}" y2="${y2}" stroke="#eb1631" stroke-width="2" />
          </svg>`
      }

      return ''
    }
    const stepMouseoverHandler = (index) => {
      const [col, row] = hoverCell.value
      if (isCellDisabled(col, row)) return
      hoveringStep.value = index
      const step = progressSteps.value[index]
      // light up previous steps in the same group,
      // if no group, assume all same group

      const groups = step.groups
      for (let i = index; i >= 0; i -= 1) {
        if (_.intersection(groups, progressSteps.value[i].groups).length) {
          refStep.value[i].style.opacity = 0.2
        }
      }

      if (stepMouseDown.value !== false) {
        toggleStep(index)
      }
    }
    const stepMouseupHandler = () => {
      stepMouseDown.value = false
    }
    const stepMouseDownHandler = (index) => {
      const [col, row] = hoverCell.value

      if (isCellDisabled(col, row)) return

      if (index === null || col === null || row === null) {
        stepMouseDown.value = false
        return
      }
      const colDef = getColDef(hoverCell.value[0], getRowSheet(hoverCell.value[1]))
      const { progress } = colDef
      const { steps } = progress
      const step = steps[index] || {}
      const rowData = getRowData(row)
      const id = getRowId(row)
      if (step.action) {
        step.action({
          id,
          rowData,
          index,
          step,
          steps,
          col,
          row
        })
        return
      }

      stepMouseDown.value = getCellData(col, row).raw[index] ? 0 : 1

      // do the first one that won't be triggered by mouseover
      toggleStep(index, true)
    }
    const toggleStep = (index) => {
      const [col, row] = hoverCell.value
      if (isCellDisabled(col, row)) return

      if (index === null || col === null || row === null) return

      const mutated = [...getCellData(col, row).raw]
      const step = progressSteps.value[index]

      if (step.disabled) {
        $store.dispatch('alert', {
          type: 'error',
          message: 'That step is set automatically by the system.'
        })
        return
      }

      mutated[index] = stepMouseDown.value

      setCellText(col, row, mutated, true, true)
    }
    const stepMouseoutHandler = () => {
      hoveringStep.value = null
      progressSteps.value.forEach((step, index) => {
        if (!refStep.value[index]) return
        refStep.value[index].style.opacity = 0
      })
    }
    const enableCellProgress = () => {
      const [col, row] = hoverCell.value
      if (isCellDisabled(col, row)) return
      showProgressDiv.value = true
    }
    const commitValue = () => {
      const [col, row] = currentCell.value
      const value = String(currentCellValue.value).replace(/^=/, '')

      // For UX responsiveness
      setCellText(col, row, value)

      $this.$emit('committed')
    }

    return {
      setFieldEquation,
      showEditableDiv,
      showOriginLabel,
      hoverStepsText,
      stepOverlay,
      stepMouseupHandler,
      stepMouseoutHandler,
      stepMouseDownHandler,
      stepMouseoverHandler,
      progressSteps,
      currentCellValueFormatted,
      handleChooseSelected,
      chooseOptions,
      showChooseDiv,
      refChooseDiv,
      refActionDiv,
      handleInput,
      refCalc,
      refEditableDiv,
      currentCellValue,
      cellFocused,
      handleCheckboxClick,
      getCurrentCellFormula,
      disableCellEdit,
      commitValue,
      showCalculatorDiv,
      showActionDiv,
      enableCellEdit,
      enableCellChoose,
      enableCellEnum,
      enableCellCalculator,
      enableCellAction,
      hoveringStep,
      stepMouseDown,
      enableCellProgress,
      setCurrentCellValue,
      focusCell,
      currentCellDisabled,
      calcVariables,
      injectValue,
      forceValue
    }
  }
}
