// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers';
import User from './User';

const getCurrencyFromCode = (code) => {
  switch (code.toLowerCase()) {
    case 'cad':
      return 1;
    case 'usd':
      return 2;
    case 'gbp':
      return 3;
    case 'zar':
      return 4;
    case 'eur':
      return 5;
    case 'nzd':
      return 6;
    case 'aud':
      return 7;
    case 'inr':
      return 12;
  }

  return null;
};

export default {
  getCurrencyFromCode,

  type: 'currency',
  
  skipAudit: true,

  fields: {
    currency_id: {
      type: 'string',
      filter: false,
    },
    currency_name: {
      type: 'string',
      filter: true,
    },
    currency_symbol: {
      type: 'string',
      filter: true,
    },
    country_id: {
      type: 'string',
      filter: true,
    },
    country_iso: {
      type: 'string',
      filter: true,
    },
    currency_iso: {
      type: 'string',
      filter: true,
    },
  },
  
  
  generateVueActions() {
    return {
      getCurrencyFromCode(store, { code }) {
        return getCurrencyFromCode(code);
      },
      async addUser({ dispatch }, payload) {
        const {
          user,
        } = payload;

        const { id } = await dispatch('resolveObject', payload);

        await dispatch('ajax', {
          path: `/company/addUser/${id}/${user}`,
        });

        return true;
      },
      async removeUser({ dispatch }, payload) {
        const {
          user,
        } = payload;

        const { id } = await dispatch('resolveObject', payload);

        await dispatch('ajax', {
          path: `/company/removeUser/${id}/${user}`,
        });

        return true;
      },
      async toggleModule({ dispatch, rootState }, { moduleName, onOrOff = null }) {
        const toggle = (typeof onOrOff === 'string' && _.ucfirst(onOrOff))
          || (rootState.session.company.aoModules[moduleName]
            ? 'Off'
            : 'On');
        await dispatch('ajax', {
          path: `/company/turnModule${toggle}/${moduleName}`,
        });
        
        return dispatch('getBaseValues', {}, { root: true });
      },
      async turnModuleOn({ dispatch }, { moduleName }) {
        return await dispatch('toggleModule', {
          moduleName,
          onOrOff: 'On',
        });
      },
      async turnModuleOff({ dispatch }, { moduleName }) {
        return await dispatch('toggleModule', {
          moduleName,
          onOrOff: 'Off',
        });
      },
    };
  },
};
