<template class="flex flex-row items-center justify-start gap-x-2 flex-nowrap">
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <PersonAvatar
        class="mx-2"
        :id="object.creatorId"
        :type="object.type === 'ClientRating' ? 'user' : 'client'"
        :name="name"
        :onClick="openSidebar"
      />
    </template>

    <template #primary>
      <div>
        <div class="text-sm">{{ $f.truncate(name || 'No name provided', 25) }}</div>
        <p class="text-xs text-gray-400">{{ timeCreatedFormatted }}</p>
      </div>
    </template>
  </preview>
  <RatingCreatorDetails
    v-if="!object.clientDetails"
    :type="object.type"
    :creatorId="object.creatorId"
    ref="ratingCreatorDetailsRef"
    :ratingData="ratingData"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import moment from 'moment'
import { getCurrentInstance, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import eventBus from '@/eventBus'
import RatingCreatorDetails from '@/components/ratings/RatingCreatorDetails.vue'
import useRating from '@/components/composables/Rating'

const { fetchUserName } = useRating()

const { proxy } = getCurrentInstance()
const object = proxy.object

const store = useStore()
const name = ref('')
const timeCreatedFormatted = ref('')
const ratingCreatorDetailsRef = ref(null)

const ratingData = ref(null)

const fetchUserData = async (creatorId) => {
  timeCreatedFormatted.value = moment(object.timeCreated).format('MMM DD, YYYY')
  if (object.clientDetails) {
    name.value = object.clientDetails.name
  } else {
    name.value = await fetchUserName({ creatorId, type: object.type })
  }

  eventBus.$emit('completed-fetching-data')
}

const openSidebar = () => {
  if (ratingCreatorDetailsRef.value) {
    ratingCreatorDetailsRef.value.toggleShowSidebar()
  } else {
    console.error('ratingCreatorDetailsRef is not available')
  }
}

const eventKey = () => {
  if (object.type === 'ClientRating') {
    return `${object.creatorId}-initialized-for-contractor`
  }
  return `${object.creatorId}-initialized-for-client`
}

const getRatingData = () => {
  if (object.type === 'ClientRating') {
    return store.getters['rating/getRatingDataByContractorId'](object.creatorId)
  }

  return store.getters['rating/getRatingDataByClientId'](object.creatorId)
}

onBeforeMount(() => {
  eventBus.$on(eventKey(), () => {
    ratingData.value = getRatingData()
  })
  fetchUserData(object.creatorId)
})

onBeforeUnmount(() => {
  eventBus.$off(eventKey())
})

watch(
  () => object.creatorId,
  (newCreatorId) => {
    fetchUserData(newCreatorId)
  }
)
</script>

<script>
import { defineComponent } from 'vue'
import PreviewMixin from './PreviewMixin'
import Preview from './Base.vue'

export default defineComponent({
  name: 'PreviewRatingCreator',
  mixins: [PreviewMixin],
  emits: ['click'],
  components: { Preview }
})
</script>
