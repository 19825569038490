<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :clickAway="false"
    :expandable="true"
  >
    <template #body>
      <company-body ref="body" :key="object.company_id" :object="object" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import CompanyBody from '../bodies/CompanyNew.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalCompanyNew',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { CompanyBody },
  props: {
    type: {
      default: 'company'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
