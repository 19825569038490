<template>
  <Btn
    severity="bolster"
    :disabled="approvalBtnLoading || loadingItemReviewal"
    class="flex items-center justify-between gap-2 w-full md:w-52 lg:w-64"
    @click="handleReviewButtonClick"
  >
    <div class="flex flex-row md:flex-col items-center w-56 min-h-18 !h-18">
      <span class="text-base">{{ reviewButtonText }}</span>
      <span class="text-xs font-light ml-2 md:ml-0" v-if="reviewStarted && !hasScrolledToLastItem">
        {{ reviewProgress }}
      </span>
    </div>
    <font-awesome-icon icon="chevron-right" fixed-width />
  </Btn>
</template>

<script setup>
import { defineProps, computed, onMounted, toRef, defineEmits, ref } from 'vue'
import { useStore } from 'vuex'
import useItemReviewal from '@/components/composables/ItemReviewal'

// emits
const emit = defineEmits(['bookAndSave', 'scrollToItem'])

// props
const props = defineProps({
  reference: {
    type: String,
    required: true
  },
  interactiveItems: {
    type: Array,
    default: () => []
  },
  hasScrolledToLastItem: {
    type: Boolean,
    default: false
  }
})

// refs
const reference = toRef(props, 'reference')
const interactiveItems = toRef(props, 'interactiveItems')
const hasScrolledToLastItem = toRef(props, 'hasScrolledToLastItem')
const approvalBtnLoading = ref(0)

// composables
const $store = useStore()
const {
  getReviewalItems,
  setSession,
  setItemsForReviewal,
  upsertReviewal,
  getNextItemIdForReviewal,
  isReviewed,
  reviewProgress,
  reviewStarted
} = useItemReviewal()

// computed variables
const normalized = computed(() => $store.state.Quote.normalized)
const quote = computed(() => normalized.value[reference.value])
const isReadyToApprove = computed(
  () => (hasScrolledToLastItem.value && reviewStarted.value) || isReviewed.value
)
const reviewButtonText = computed(() => {
  if (isReadyToApprove.value) {
    return 'Approve'
  } else if (reviewStarted.value) {
    return 'View next options'
  } else {
    return 'Start review'
  }
})

const scrollToItem = () => {
  const lastReviewedItemId = getNextItemIdForReviewal()
  if (lastReviewedItemId && !isReviewed.value) {
    reviewStarted.value = true
    emit('scrollToItem', lastReviewedItemId)
  }
}

// methods
const handleReviewButtonClick = async () => {
  // if action taking place make it so user cannot init action
  if (approvalBtnLoading.value) return

  // is ready to approve so handle approve
  if (isReadyToApprove.value) {
    emit('bookAndSave')
    return
  }

  // reviewal is done
  if (isReviewed.value) return

  // start reviewal process
  if (!reviewStarted.value) {
    reviewStarted.value = true
    scrollToItem()
    return
  }

  approvalBtnLoading.value = 1
  const currentItemIdForReview = getNextItemIdForReviewal()
  // mark the items as reviewed
  await upsertReviewal({
    itemId: currentItemIdForReview,
    isReviewed: true
  })

  // scroll to the next item
  scrollToItem()
  approvalBtnLoading.value = 0
}

onMounted(async () => {
  // set the session data for item reviewal
  setSession({
    changeOrderId: quote.value.change_order_id,
    quoteId: quote.value.quote_id
  })

  // set the items that need to be reviewed
  setItemsForReviewal(interactiveItems)

  // fetch all the current items that have been reviewed
  await getReviewalItems()
})
</script>
