<template>
  <li>
    <a
      @click="() => $emit('click', { button: this })"
      @dblclick="() => $emit('dblclick', { button: this })"
      @touchstart.passive="() => $emit('touchstart', { button: this })"
      @mouseover="() => $emit('mouseover', { button: this })"
      ><slot></slot
    ></a>
  </li>
</template>

<script>
import ButtonMixin from '../../mixins/Button'

export default {
  name: 'ListGroupItem',
  mixins: [ButtonMixin],
  emits: ['click', 'dblclick', 'touchstart', 'mouseover']
}
</script>
