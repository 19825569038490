<template>
  <spin-container :loading="bodyLoading">
    <template v-if="!file_is_folder">
      <div class="flex flex-col justify-center items-center text-center gap-y-8">
        <div class="flex flex-col justify-center items-center w-full gap-y-2">
          <div
            v-tooltip="'Rename file..'"
            class="focus:bg-blue-print-100 hover:text-blue-print focus:text-blue-print py-1 px-2 text-lg"
            contenteditable="true"
            @keydown.enter.stop.prevent="doneRenaming"
            @focusout="doneRenaming"
          >
            {{ file_name }}
          </div>

          <btn-bar
            size="lg"
            class="mx-2"
            v-if="!file_is_virtual"
            :collapse="false"
            :actions="fileActions"
            :selected="[this]"
          />
        </div>

        <div class="w-full h-full">
          <!-- image -->
          <template v-if="isImage">
            <img :src="viewLink" class="w-full rounded border border-surface-300" />
          </template>

          <!-- signed/regular pdf -->

          <template v-else-if="isPdf">
            <Pdf :src="viewLink" />
          </template>

          <!-- msdoc, excel, word etc -->
          <template v-else-if="isMsDoc">
            <OfficeDocViewer :src="viewLink" />
          </template>

          <!-- csv -->
          <template v-else-if="isCsv">
            <CsvViewer :src="viewLink" />
          </template>
        </div>
      </div>
    </template>

    <!-- show directory -->
    <template v-else>
      <files-body
        :filters="{
          parent_file_id: file_id
        }"
      />
    </template>
  </spin-container>
</template>

<script>
import Pdf from '../PdfViewer/Pdf.vue'
import OfficeDocViewer from '../ui/OfficeDocViewer.vue'
import CsvViewer from '@/components/ui/CsvViewer.vue'
import ObjectManipulator from '../mixins/ObjectManipulator'
import FilesBody from './Files.vue'
import FileHelper from '@/../imports/api/File'

export default {
  mixins: [ObjectManipulator('file', true)],
  data() {
    return {
      showActivities: false,
      allowCreate: false
    }
  },
  methods: {
    refetch() {},
    async download() {
      return this.$store.dispatch('File/download', {
        refId: this.refId
      })
    },
    updateSigning(signingObject) {
      this.aoSigning = null
      this.aoSigning = signingObject
    },
    doneRenaming(e) {
      this.file_name = e.target.innerText
      this.rename = false
      this.$store.dispatch('ajax', {
        path: `file/update/${this.file_id}`,
        data: {
          object: {
            file_name: this.file_name
          }
        }
      })
    }
  },
  computed: {
    fileActions() {
      return {
        ...this.actions
      }
    },
    viewLink() {
      // Need the new Date() as a means of ensuring the document is not
      //  cached particularly when it comes to ms office doc viewer
      //  so the document is always up to date
      return FileHelper.viewLink(this.file_id, this.file_type)
    },
    downloadLink() {
      return c.link(`file/download/${this.file_id}/${this.file_name}`, {}, true)
    },
    isImage() {
      return FileHelper.isImage(this.file_type)
    },
    isCsv() {
      return FileHelper.isCsv(this.file_type)
    },
    isPdf() {
      return this.file_virtual_object_format === 'pdf' || FileHelper.isPdf(this.file_type)
    },
    isMsDoc() {
      return (
        /word|excel/.test(this.file_virtual_object_format) || FileHelper.isMsDoc(this.file_type)
      )
    },
    activities() {
      return this.$store.state.File.normalized[this.refId].aoActivities.map(
        (a) => this.$store.state.File.normalized[a]
      )
    }
  },
  props: {},
  components: { Pdf, OfficeDocViewer, FilesBody, CsvViewer }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.file-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & + div {
      margin-top: 2em;
    }
  }

  .rename-field {
    transition: background 0.25s ease;
    font-weight: bold;
    padding: 0.1em 1em;
    border-radius: 0.5em;
    outline: none;
    &:hover,
    &:active,
    &:focus {
      background: rgba($level-yellow, 0.6);
    }
  }

  .button-bar {
    width: 2em;
    height: 50px;
    position: absolute;
    right: 3.5em;
    top: -50px;
    z-index: 12;
    display: flex !important;
    align-self: center;
  }

  .main {
    img {
      max-width: 100%;
      border: 10px solid $flame-white;
      box-shadow: 0px 0px 0px 1px $cool-gray-400;
      border-radius: 3px;
    }

    > .label {
      margin-top: 0.5em;
      padding: 0.2em;
      border: 1px solid transparent;
      white-space: pre-line;
      overflow: hidden;
      &:focus,
      &:active {
        border: 1px solid $cool-gray-500;
        border-radius: 3px;
        background: $flame-white;
      }
      &:hover {
        border: 1px solid $cool-gray-500;
        border-radius: 3px;
      }
    }
  }
  .pdf-view-container {
    width: 80%;
    margin: auto;
  }
  .activities-container {
    margin-top: 2em;
    text-align: left;
    .options {
      font-size: 0.8em;
      font-weight: bold;
    }
  }
}
</style>
