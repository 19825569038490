<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :scrollable="false"
    :closeable="true"
    :showCloseButton="false"
    :clickAway="true"
  >
    <template #header> Rate your experience </template>
    <template #body>
      <client-rating-body ref="body" :object="object" />
    </template>
  </Modal>
</template>

<script>
import ClientRatingBody from '../bodies/ClientRatingNew.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalClientRatingNew',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { ClientRatingBody },
  props: {
    type: {
      default: 'rating'
    },
    go: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
