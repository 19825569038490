<template>
  <div
    class="assembly-details--container"
    :class="{ 'sidebar-closed': !$parent.$parent.sidebarOpen }"
    :style="{
      'padding-top': paddingVertical + 'em',
      'padding-bottom': paddingVertical + 'em'
    }"
  >
    <div v-if="$parent.$parent.sidebarOpen" class="assembly-name">
      <slot name="open"></slot>
    </div>

    <div v-else class="assembly-name closed">
      <slot name="closed"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidePanelDetails',
  props: {
    paddingVertical: {
      default: 0
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-client-details-tooltip {
  position: fixed !important;
  left: 167px !important;
}

.assembly-details--container {
  width: auto;
  height: auto;
  color: $cool-gray-700;
  background-color: #fff;
  text-align: start;
  border-bottom: 1px solid $cool-gray-300;
  padding-right: 2.1em;
  padding-left: 0.5em;

  &.sidebar-closed {
    padding: 0.5em 0.5em;
  }

  .assembly-name {
    &.closed {
      text-align: center;
      color: $cool-gray-600;
    }
  }
}
</style>
