<template>
  <spin-container :loading="bodyLoading">
    <card-section>
      <card-list>
        <card-list-field>
          <span>Crew name</span>

          <field
            schema="trade_type:trade_type_name"
            v-model="trade_type_name"
            placeholder="Crew type name"
          />
        </card-list-field>

        <card-list-field>
          <span>Crew description</span>

          <field
            schema="trade_type:trade_type_desc"
            type="textarea"
            v-model="trade_type_desc"
            placeholder="Crew type description"
          />
        </card-list-field>

        <card-list-field>
          <span>Crew labor type </span>

          <Choose
            :validate="{ required: true }"
            schema="trade_type:labor_type_id"
            v-model="labor_type_id"
          />
        </card-list-field>
      </card-list>
    </card-section>
  </spin-container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'

export default {
  mixins: [ObjectManipulator('trade_type', true, 'TradeType'), BodyMixin /* , AutoSave */],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    /* ChangeAudit, */
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
