<template>
  <Container class="px-4">
    <CardSection>
      <h2 class="">Terms of Service</h2>
      <p>Last Updated August 12, 2024</p>
      <p>
        Welcome to BolsterBuilt.com, a website of Bolster Built Inc. and its subsidiary, Bolster
        Built Inc. (USA), doing business as Bolster (“<strong>Bolster</strong>,”
        “<strong>we</strong>,” or “<strong>us</strong>”).
      </p>
      <p>
        This page explains the terms that govern your access to and use of our website, our
        construction industry Software as a Service platform and other services we make available to
        you on our website, our mobile application (“<strong>App</strong>”), or through other means
        (collectively the “<strong>Services</strong>”).
      </p>
      <p>
        By using this website or by purchasing a subscription and/or by opening an account with us
        (the “<strong>Account</strong>”) , you signify that you have read, understood, and agree to:
      </p>
      <ul>
        <li class="mb-4">
          <span class="ml-4 mr-2">•</span> these Bolster Terms of Service (the
          “<strong>Terms</strong>”); and
        </li>
        <li>
          <span class="ml-4 mr-2">•</span> the collection and use of your information as set forth
          in the Bolster Privacy Policy.
        </li>
      </ul>
      <p>
        Certain Bolster Services may also require that you agree to supplemental terms and/or
        policies applicable to the specific service, including a master services agreement (“<strong
          >Supplemental Terms</strong
        >”). Any such Supplemental Terms will be presented to you for your review and consent when
        you sign up to use the specific Bolster Services to which the Supplemental Terms apply. The
        Supplemental Terms shall control over these Terms in the event of a conflict between the
        two. You agree that your use of a Service governed by Supplemental Terms constitutes your
        agreement to be bound by those Supplemental Terms, and that these Terms and all applicable
        Supplemental Terms constitute the Terms applicable to you.
      </p>
      <p>
        Bolster reserves the right to make unilateral modifications to these Terms and our policies
        and will provide notice of these changes by posting an updated version to our website. If
        you do not accept all applicable terms and policies and the procedures for modifying those
        terms and policies, do not open an Account and do not use any Services offered by Bolster.
        Use of any such Services will be deemed to be acceptance of all applicable terms and
        policies.
      </p>
      <p>
        PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE TERMS
        CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION
        THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
        JURY TRIALS OR CLASS ACTIONS.
      </p>
      <ol start="1">
        <li>
          <h3 class="h5"><strong>1. Use of the Services</strong></h3>
          <p>
            This is a contract between you, the applying business entity and Bolster. You must read
            and agree to these Terms before using the Services. If you do not agree, you may not
            open an Account or use the Services.
          </p>
          <ol start="A" class="mt-4">
            <li class="mb-4">
              <h4 class="h6"><strong>A. Eligibility</strong></h4>
              <p>
                Only lawfully formed business entities or individuals wishing to hire them are
                eligible to use Bolster Services. Individuals, consumers, or any non-commercial
                entities are not eligible to use Bolster Services other than for informational
                purposes. You may only apply for an Account or use the Services if you are a legal
                entity formed and registered in or legal resident of the United States or Canada or
                and can form a binding contract with Bolster, and only in compliance with these
                Terms and all applicable local, state, national, and international laws, rules and
                regulations. Any use of or access to the Services for consumer or non-commercial
                purposes other than for informational purposes, by anyone under 18, or by any
                individual who is not your employee, contractor, agent, or other individual
                permitted to use your Account on your behalf (“User”) is strictly prohibited and in
                violation of these Terms.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>B. Bolster’s Services</strong></h4>
              <p>
                Bolster offers a construction industry Software as a Service platform and a broad
                range of financial services including, but not limited to, a services marketplace,
                escrow services and payment facilitation. Such Services may be subject to
                Supplemental Terms as set forth above. Bolster will make some or all of the Services
                available to Users pursuant to these Terms and the applicable Order Forms. Services
                that are subject to Fees may be subscribed for by Users through the submission of an
                Order to Bolster, which Order shall become binding on acceptance by Bolster (each a
                "Subscription"). For Subscriptions, Bolster will (a) use commercially reasonable
                efforts to make such Services available 24 hours a day, 7 days a week, except for:
                (i) planned downtime (of which Bolster shall give advance electronic notice), and
                (ii) any unavailability caused by circumstances beyond Bolster ’s reasonable
                control, including, for example, an act of God, act of government, flood, fire,
                earthquake, civil unrest, act of terror, strike or other labor problem (other than
                one involving Bolster employees), Internet service provider failure or delay, Third
                Party Application, or denial of service attack, and (b) provide the Services in
                accordance with laws and government regulations applicable to Bolster’s provision of
                its Services to its customers generally (i.e., without regard for Your particular
                use of the Services), and subject to Your use of the Services in accordance with
                these Terms, the Documentation and the applicable Order Form.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>C. Opening an Account</strong></h4>
              <p class="mb-4">
                Your Account gives you access to the Services and any other functionality that we
                may establish and maintain from time to time and in our sole discretion. When
                opening an Account you may register as a Contractor, Subcontractor, Supplier or
                Purchaser. You will need to provide information on the business entity opening the
                Account and certain personal information (collectively, "<strong
                  >Applicant Information</strong
                >"), when you apply to open an Account. For certain Services, Applicant Information
                may include the registered business name and state of incorporation, the business
                address, ownership details, the nature of the business, and other business
                information we may request from time to time; the name, contact information, and
                date of birth of administrators, Users or beneficial owners, and other personal
                information; and a corporate registration certificate, proof of address, personal
                identification, and any other documentary information used to verify business and
                personal information. This information will be collected, used and disclosed in
                accordance with our Privacy Policy. Certain personal information that you choose to
                make available to other users will be published on your Platform profile page. If
                you provide personal information of a third party, you agree that you have received
                consent from that party to provide the personal information to Bolster.
              </p>
              <p>
                We provide Applicant Information to our third-party service providers to determine
                your eligibility for the Services. We rely on the accuracy of the Applicant
                Information you provide us when opening and maintaining your Account. For those
                Services requiring certain Applicant Information, we may deny your application,
                suspend provision of the Services to you, or close your Account if Applicant
                Information is out of date, incomplete, or inaccurate.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>D. Account Management and Security</strong></h4>
              <p>
                You must specify at least one administrator (“<strong>Administrator</strong>”) to
                manage your Account when submitting your Application. Administrators can add,
                remove, or manage additional Administrators and Users; view transactions and run
                reports; provide or update company information; connect third-party services and
                other accounts to your Account; and perform other tasks to manage your Account. You
                are responsible for any actions or failure to act on the part of Administrators or
                Users, or those using their credentials to access your Account. You are solely
                responsible for the activity that occurs on your Account, and you must keep your
                Account password secure. We encourage you to use “strong” passwords with your
                Account. You must notify Bolster immediately of any breach of security or
                unauthorized use of your Account. Bolster will not be liable for any losses caused
                by any unauthorized use of your Account. We may suspend access to your Account if we
                believe that your Account has been compromised.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>E. Profile Page and Rating and Review Feature </strong></h4>
              <p>
                Bolster collects feedback and ratings from Users (including developers, builders,
                contractors and subcontractors) about their experience working with other Users.
                Bolster uses this feedback to provide other Users with information on other Users.
                Bolster publishes feedback and comments about Users on the Services so that Users
                can review and evaluate the experiences of other Users. If you have any concerns
                about the feedback that is published about you, please contact us at
                <a href="mailto:support@bolsterbuilt.com">support@bolsterbuilt.com</a>.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>F. Third-Party Services</strong></h4>
              <p class="mb-4">
                Bolster, as part of the Services it provides, may recommend or provide website links
                to third-party materials including offers of services or products that are not owned
                or controlled by Bolster. Should you choose to purchase such third-party services or
                products, the third-party provider may require you to enter into separate agreements
                regarding those services or products.
              </p>
              <p>
                Bolster does not endorse or assume any responsibility for any such third-party
                services, information, materials, or products. If you access a third-party website,
                application or service from the Services, you do so at your own risk, and you
                understand that these Terms and Bolster’s Privacy Policy do not apply to your use of
                such third-party services. You expressly relieve Bolster from any and all liability
                arising from your use of any third-party websites, applications, services, or
                content.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>G. Third-Party Software Providers</strong></h4>
              <p class="mb-4">
                Bolster Services may enable you to select and connect or interact with a third-party
                software product. You will need to have, and actually be signed-in to, an active
                user account for such third-party software products. You are solely responsible for
                all aspects of the relationship with the third-party including creating and
                maintaining the account and paying any fees for such use. Bolster is not affiliated
                with, has no control over, and assumes no responsibility for, the policies or
                practices of any third-party software product or third-party software product
                provider. You acknowledge and agree to review and be bound by the terms of use and
                privacy policy of any third-party software product that you use and connect with,
                and you agree to indemnify and hold us harmless, and expressly release us, from any
                and all liability arising from your use of, and/or connection to, a third-party
                software product.
              </p>
              <p>
                To the extent you choose to connect or interact with a third-party software product,
                you grant us authorization to access your third-party software product account in
                accordance with the rules for doing so prescribed by the third-party software
                product provider for the purpose of providing the Services to you, and to collect
                and use your data held by the third party.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3 class="h5"><strong>2. Definitions</strong></h3>
          <p class="mb-4">
            "<strong>Affiliate</strong>" means any entity that directly or indirectly controls, is
            controlled by, or is under common control with the subject entity. "Control," for
            purposes of this definition, means direct or indirect ownership or control of more than
            50% of the voting interests of the subject entity.
          </p>
          <p class="mb-4">
            "<strong>Applicable Law</strong>" means all applicable state, federal, provincial and
            local laws, rules and regulations, including without limitation, the Bank Secrecy Act,
            the implementing regulations issued by the U.S. Treasury’s Office of Foreign Assets
            Control (OFAC) and the Federal Trade Commission, as well as any and all other federal
            and state anti-money laundering laws and regulations.
          </p>
          <p class="mb-4">
            "<strong>Contractor</strong>" means a construction service provider who contracts
            directly with Purchasers.
          </p>
          <p class="mb-4">
            "<strong>Documentation</strong>" means the applicable Service’s documentation and its
            usage guides and policies, as updated from time to time, accessible via BolsterBuilt.com
            or our App.
          </p>
          <p class="mb-4">
            "<strong>Fees</strong>" means the fees payable for any Services that are provided
            subject to payment of applicable fees.
          </p>
          <p class="mb-4">
            "<strong>Marketplace</strong>" or "<strong>Marketplace Service</strong>" means the
            marketplace component of the Service where Users may enter into agreements directly with
            each other, as facilitated by the Service.
          </p>
          <p class="mb-4">
            “<strong>Network Rules</strong>” are the rules, regulations, releases, interpretations
            and other requirements (whether contractual or otherwise) imposed or adopted by any
            Network.
          </p>
          <p class="mb-4">
            “<strong>Onboarding Fee</strong>” means the one-time, non-refundable, fee to open an
            account for Subscription Services (as applicable).
          </p>
          <p class="mb-4">
            "<strong>Order</strong>" means an ordering document or online order specifying the
            Services to be provided hereunder that is entered into between You and Bolster including
            any addenda and supplements thereto.
          </p>
          <p class="mb-4">
            "<strong>Purchaser</strong>" means an individual who contracts, or is seeking to
            contract, with a Contractor for receipt of construction services.
          </p>
          <p class="mb-4">
            "<strong>Services</strong>" means the products and services of Bolster which are made
            available by us on our construction industry software as a service platform (the
            “Platform”) via the BolsterBuilt.com website, that are used by a User, whether through
            simply accessing the Platform or as subscribed for through an Order.
          </p>
          <p class="mb-4">
            "<strong>Subcontractor</strong>" means a construction service provider who is, or who
            may be, subcontracted by a Contractor.
          </p>
          <p class="mb-4">
            “<strong>Subscription</strong>” means the fee-based access to the Services purchased by
            User as set forth in these Terms.
          </p>
          <p class="mb-4">
            “<strong>Subscription Fee</strong>” means the fee(s) for the User’s subscription to the
            Services as forth in the Terms.
          </p>
          <p class="mb-4">
            “<strong>Subscription Term</strong>” means the subscription period identified in these
            Terms.
          </p>
          <p class="mb-4">
            "<strong>Supplier</strong>" means a supplier of physical goods to Contractors and
            Subcontractors.
          </p>
          <p class="mb-4">“<strong>Terms</strong>” means the Bolster Terms of Service.</p>
          <p class="mb-4">
            "<strong>Third Party Application</strong>" means a Web-based, mobile, offline or other
            software application functionality that interoperates with a Service, that is provided
            by You or a third party, including without limitation Salesforce.com.
          </p>
          <p class="mb-4">
            "<strong>User</strong>" means in the case of an individual accepting these Terms on
            behalf of a company or other legal entity, or in the case of an individual who is
            authorized by You to use a Service, for whom You have purchased a Subscription (or in
            the case of any Services provided by Bolster without charge, for whom a Service has been
            provisioned), and to whom You (or, when applicable, Bolster at Your request) has
            supplied a user identification and password (for Services utilizing authentication).
            Users may include, for example, employees, consultants, contractors and agents of You,
            and third parties with which You transact business.
          </p>
          <p class="mb-4">
            "<strong>Your Data</strong>" means electronic data and information submitted by or for
            You to the Services.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>3. Subscriptions</strong></h3>
          <ol start="A" class="mt-4">
            <li class="mb-4">
              <h4 class="h6"><strong>A. Subscription Fees</strong></h4>
              <p class="mb-4">
                Access to the Bolster Platform is provided on a Software-as-a-Service basis and
                requires all Users to have a valid Subscription and account access. We may charge
                Users a Fee to access and use part of the Services that are subject to paid
                Subscriptions where subscribed for in accordance with Section 3, and account access.
                User’s Subscription Fees and other fees, as applicable, are set forth in the
                Documentation and Order provided to User by Bolster. All fees, including
                Subscription Fees, are subject to change upon notice. User shall pay all fees,
                including Subscription Fees, when due and is responsible for providing complete and
                accurate billing information to Bolster. User authorizes Bolster and its payment
                processor(s) to charge fees using any of User’s available payment methods on file
                with User’s account.
              </p>
              <p>
                Subscription Services are sold as an annual subscription (auto-renewable) beginning
                on the effective date of the Order, with two available payment options:
              </p>
              <ol start="1" class="ml-4 mt-4">
                <li class="mb-4">
                  <p>
                    <strong>1. Annual Upfront Payment</strong>: 12-month Subscription Fee is paid in
                    full on the effective date of the Order (and any renewal thereafter), and
                  </p>
                </li>
                <li>
                  <p>
                    <strong>2. Annual Commitment Monthly-Payment</strong>: A one-time Onboarding Fee
                    and the first monthly Subscription Fee are paid on the effective date of the
                    Order; Subscriptions Fees are thereafter paid on a monthly basis (and any
                    renewals thereafter).
                  </p>
                </li>
              </ol>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>B. AUTO-RENEWAL</strong></h4>
              <p>
                USER AGREES THAT ITS SUBSCRIPTION WILL AUTOMATICALLY RENEW AFTER THE INITIAL
                SUBSCRIPTION TERM ENDS (THE “RENEWAL DATE”) AND ANNUALLY THEREAFTER, AT THE
                THEN-CURRENT SUBSCRIPTION FEE AND SELECTED PAYMENT PLAN, UNLESS CANCELED BY THE USER
                PRIOR TO THE RENEWAL DATE. BOLSTER WILL NOTIFY USER IN WRITING BY EMAIL, AND/OR AS
                AVAILABLE, BY THE TELEPHONE CONTACT INFORMATION ASSOCIATED WITH THE USER’S BOLSTER
                ACCOUNT, BETWEEN 15-45 DAYS PRIOR TO THE EXPIRATION OF THE SUBSCRIPTION TERM. THE
                SUBSCRIPTION WILL AUTOMATICALLY RENEW UNDER THE USER’S THEN-CURRENT PAYMENT PLAN
                OPTION UNLESS USER UPDATES THE SELECTED PAYMENT PLAN OPTION (UPFRONT PREPAID OR
                MONTHLY PAYMENT FOR THE ANNUAL RENEWAL TERM) PRIOR TO THE RENEWAL DATE. USER
                AUTHORIZES BOLSTER AND ITS PAYMENT PROCESSOR(S) TO AUTOMATICALLY CHARGE USER FOR THE
                APPLICABLE SUBSCRIPTION FEES, ON OR AFTER THE RENEWAL DATE UNLESS THE SUBSCRIPTION
                HAS BEEN CANCELED OR TERMINATED IN ACCORDANCE WITH THESE TERMS.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>C. CANCELLATION</strong></h4>
              <p>
                USER MUST CANCEL ITS SUBSCRIPTION PRIOR TO THE RENEWAL DATE TO AVOID BEING CHARGED
                THE SUBSCRIPTION FEE UNDER THE APPLICABLE PAYMENT PLAN. IF USER ATTEMPTS TO CANCEL
                ITS SUBSCRIPTION DURING THE SUBSCRIPTION TERM, USER WILL NOT BE ISSUED A REFUND FOR
                THE MOST RECENT SUBSCRIPTION FEES OR ANY PREVIOUSLY CHARGED FEES AND THE
                SUBSCRIPTION WILL TERMINATE ON THE NEXT APPLICABLE RENEWAL DATE. TO CANCEL, USER
                MUST SELECT TO CANCEL THEIR SUBSCRIPTION VIA THE USER’S ACCOUNT PRIOR TO THE RENEWAL
                DATE. Bolster does not provide refunds for any type of cancellation.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>D. Additional one-time or Add-on Fees</strong></h4>
              <p>
                Users may purchase one-time Services or add-on additional Services either separately
                to or as part of their Subscription. Such additional fees will either be indicated
                at the time of incurrence or, in respect of Subscriptions, will be as set forth in
                the applicable Order. Without limiting the foregoing, Bolster may also automatically
                charge Users add-ons or one-time service fees as Users exceed their subscription
                limits for Subscriptions. The fees for one-time Services or add-ons Services will be
                determined at the time of purchase of such Services.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>E. Upgrades</strong></h4>
              <p>
                Any upgrade to the original Subscription will result in a new subscription fee,
                prorated based on the date the Subscription was upgraded. Bolster may automatically
                upgrade User Subscriptions based on usage of Services to prevent disruption of
                Services to the User. Bolster will provide notice at least 5 days prior to changing
                the Subscription.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>F. Downgrades</strong></h4>
              <p>
                Any downgrade will result in a new Subscription Fee charged during the next billing
                cycle. There is no prorating for downgrades during the term of the Subscription.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>G. Fees for Escrow and Payment Services</strong></h4>
              <p>
                Bolster charges transaction fees for use of certain escrow and payment services.
                Fees are set out in the ordering document at the time you register for such services
                ("<strong>Transaction Fees</strong>"). The Transaction Fees will be calculated as a
                percentage of the gross total value of the transaction, which will be quoted to User
                on the Bolster Platform at the time of the transaction. Bolster reserves the right
                to change any fees at our sole discretion by providing 30 days’ notice.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>H. Payment Method </strong></h4>
              <p>
                In order to use the Services, Bolster requires Users to provide valid banking
                information or other acceptable payment information to register (collectively known
                as "<strong>Billing Information</strong>").. Users hereby agree that all payments
                will be processed by Bolster’s third-party payment processors (each a "<strong
                  >Payment Processor</strong
                >") and that all such payments will be governed by Payment Processor’s terms and
                conditions and privacy policy available at: BolsterBuilt.com or as otherwise
                published by the Payment Processor. Users shall advise Bolster within 10 business
                days if their Billing Information changes due to loss, theft, cancellation, expiry
                or otherwise, and Users shall be liable for any failure to pay Fees caused by
                out-of-date billing information or other fees incurred by Bolster due to failure to
                pay Fees when due or wrongfully disputing any Fee payment. Bolster reserves the
                right to suspend access to the Service if any Fees are outstanding.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>I. Taxes</strong></h4>
              <p>
                The Subscription Fees may be subject to applicable sales taxes which will be
                determined by Bolster and added to the respective fees. Bolster reserves the right
                to deduct the Fees and any applicable taxes from the User's account.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>J. Contractor Taxes</strong></h4>
              <p>
                Each contractor or subcontractor, as applicable, hereby agrees and acknowledges that
                it is exclusively responsible for determining the amount of all applicable taxes to
                be charged on all job fees and is exclusively responsible for posting such fees,
                inclusive of all applicable taxes and any required tax identification numbers, on
                the invoices generated by the Bolster Platform. For greater certainty, Users hereby
                agree and acknowledge that Bolster is not responsible for calculating, reporting,
                remitting and withholding any applicable federal, state, provincial, goods,
                services, value-added, municipal or other taxes associated with User’s job fees.
                Each User hereby represents and warrants that all tax information it provides in
                connection with its use of the Bolster Platform is accurate. Notwithstanding the
                foregoing, each User remains solely responsible for ensuring all applicable taxes
                are properly calculated.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>K. Purchaser Taxes</strong></h4>
              <p>
                Bolster is not responsible for calculating, reporting, remitting and withholding any
                applicable federal, state, provincial, goods, services, value-added, municipal or
                other taxes associated with a Purchaser’s engagement of a contractor or
                sub-contractor, as applicable.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>L. Refunds</strong></h4>
              <p>Bolster does not offer refunds.</p>
            </li>
          </ol>
        </li>
        <li>
          <h3 class="h5"><strong>4. Our Proprietary Rights</strong></h3>
          <p class="mb-4">
            Subject to the terms and conditions of these Terms, you are hereby granted a
            non-exclusive, limited, non-transferable, freely revocable license to use the Services
            as permitted by the features of the Services. Bolster reserves all rights not expressly
            granted herein in the Services and the Bolster IP (as defined below). Bolster may
            terminate this license at any time for any reason or no reason. The Services and all
            materials therein or transferred thereby, including, without limitation, software,
            images, text, graphics, illustrations, logos, patents, trademarks, service marks,
            copyrights, photographs, audio, videos, music, and user content belonging to other Users
            (the “<strong>Bolster IP</strong>”), and all intellectual property rights related
            thereto, are the exclusive property of Bolster and its licensors. Except as explicitly
            provided herein, nothing in these Terms shall be deemed to create a license in or under
            any such intellectual property rights, and you agree not to sell, license, rent, modify,
            distribute, copy, reproduce, transmit, publicly display, publicly perform, publish,
            adapt, edit or create derivative works from any Bolster IP. Use of the Bolster IP for
            any purpose not expressly permitted by the Terms is strictly prohibited.
          </p>
          <p>
            You may choose to, or we may invite you to, submit comments or ideas about the Services,
            including without limitation about how to improve the Services or our products. By
            submitting any such comments or ideas, you agree that your disclosure is gratuitous,
            unsolicited and without restriction, and will not place Bolster under any fiduciary or
            other obligation, and that we are free to use the comments or ideas without any
            additional compensation to you, or to disclose the comments or ideas on a
            non-confidential basis or otherwise to anyone. You further acknowledge that, by
            acceptance of your submission, Bolster does not waive any rights to use similar or
            related ideas previously known to Bolster, or developed by its employees, or obtained
            from sources other than you.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>5. Prohibited Activities</strong></h3>
          <p>You may not use any Bolster Service for activities that:</p>
          <ol start="1" class="ml-4 mt-2">
            <li class="mb-4">
              <p>1. violate any law, statute, ordinance or regulation;</p>
            </li>
            <li class="mb-4">
              <p>
                2. relate to transactions involving (a) items that encourage, promote, facilitate or
                instruct others to engage in illegal activity, (b) stolen goods including digital
                and virtual goods, (c) the promotion of hate, violence, racial or other forms of
                intolerance that is discriminatory or the financial exploitation of a crime, (d)
                items that infringe or violate any copyright, trademark, right of publicity or
                privacy or any other proprietary right under the laws of any jurisdiction;
              </p>
            </li>
            <li class="mb-4">
              <p>
                3. relate to transactions that show the personal information of third parties in
                violation of applicable law; or
              </p>
            </li>
            <li class="mb-4">
              <p>
                4. relate to transactions involving any activity that requires pre-approval without
                having obtained said approval.
              </p>
            </li>
          </ol>
          <p>You may not:</p>
          <ol start="1" class="ml-4 mt-2">
            <li class="mb-4">
              <p>
                1. copy, modify, or create derivative works or improvements of the Bolster Services
                or Bolster IP;
              </p>
            </li>
            <li class="mb-4">
              <p>
                2. rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or
                otherwise make available any Bolster Services or Bolster IP to any person, including
                on or in connection with the internet or any time-sharing, service bureau, software
                as a service, cloud, or other technology or service;
              </p>
            </li>
            <li class="mb-4">
              <p>
                3. reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to
                derive or gain access to the source code of the Bolster Services or Bolster IP, in
                whole or in part;
              </p>
            </li>
            <li class="mb-4">
              <p>
                4. bypass or breach any security device or protection used by the Bolster Services
                or Bolster IP or access or use the Services or Bolster IP other than by a User
                through the use of his or her own then valid access credentials;
              </p>
            </li>
            <li class="mb-4">
              <p>
                5. input, upload, transmit, or otherwise provide to or through the Services any
                information or materials that are unlawful or injurious, including without
                limitation harmful content, or contain, transmit, or activate any harmful code;
              </p>
            </li>
            <li class="mb-4">
              <p>
                6. damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or
                harm in any manner the Services, Bolster’s systems, or Bolster’s provision of
                Services to any third party, in whole or in part;
              </p>
            </li>
            <li class="mb-4">
              <p>
                7. remove, delete, alter, or obscure any trademarks, warranties, or disclaimers, or
                any copyright, trademark, patent, or other intellectual property or proprietary
                rights notices from any Services or Bolster IP, including any copy thereof;
              </p>
            </li>
            <li class="mb-4">
              <p>
                8. access or use the Services or Bolster IP in any manner or for any purpose that
                infringes, misappropriates, or otherwise violates any intellectual property right or
                other right of any third party (including by any unauthorized access to,
                misappropriation, use, alteration, destruction, or disclosure of the data of any
                other Bolster User), or that violates any applicable law;
              </p>
            </li>
            <li class="mb-4">
              <p>
                9. access or use the Services or Bolster IP for purposes of competitive analysis of
                the Services or Bolster IP, the development, provision, or use of a competing
                software service or product or any other purpose that is to the Bolster’s detriment
                or commercial disadvantage;
              </p>
            </li>
            <li class="mb-4">
              <p>
                10. access or use the Services or Bolster IP in, or in association with, the design,
                construction, maintenance, or operation of any hazardous environments, systems, or
                applications, any safety response systems or other safety-critical applications, or
                any other use or application in which the use or failure of the Services could lead
                to personal injury or severe physical or property damage;
              </p>
            </li>
            <li class="mb-4">
              <p>
                11. Use Bolster Services or Bolster IP for any personal, family, household, or other
                use not related to your business;
              </p>
            </li>
            <li class="mb-4">
              <p>
                12. Use Bolster Services or Bolster IP for the benefit of an individual,
                organization, or country identified on the United States Office of Foreign Asset
                Control’s Specially designated Nationals List, or for any third parties unaffiliated
                with your business; or
              </p>
            </li>
            <li class="mb-4">
              <p>
                13. otherwise access or use the Bolster Services or Bolster IP beyond the scope of
                the authorization granted under these Terms.
              </p>
            </li>
          </ol>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>6. Services Data and Privacy</strong></h3>
          <p class="mb-4">
            You understand that by using the Services you consent to the collection, use and
            disclosure of company information and aggregate data we collect from your use of the
            Services as set forth in our Privacy Policy, and to have your company information and
            this data collected, used, transferred to and processed in the United States and Canada.
            You grant us a worldwide, irrevocable license to use, modify, distribute, copy, and
            create derivative works from your data for the purposes identified in these Terms.
          </p>
          <p>
            Bolster uses commercially reasonable physical, managerial, and technical safeguards to
            preserve the integrity and security of your company information and to implement your
            privacy settings. However, we cannot guarantee that unauthorized third parties will
            never be able to defeat our security measures or use your company information for
            improper purposes. You acknowledge that you provide your company information at your own
            risk. Upon request by You made within 30 days after the effective date of termination or
            expiration of these Terms, Bolster will make Your Data available to You for export or
            download as provided in the Documentation. After such 30-day period, Bolster will have
            no obligation to maintain or provide any of Your Data, and as provided in the
            Documentation will thereafter delete or destroy all copies of Your Data in its systems
            or otherwise in its possession or control, unless legally prohibited.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>7. Electronic Communications</strong></h3>
          <ol start="A" class="mt-2">
            <li class="mb-4">
              <p>
                <strong>A</strong>. "Communication" means any customer agreements or amendments
                thereto, disclosures, notices, responses to claims, transaction histories, privacy
                policies, payment authorizations and receipts, and all other information we
                communicate to you in connection with the Services or related products, including
                but not limited to information that we are required by law to provide you in
                writing.
              </p>
            </li>
            <li class="mb-4">
              <p>
                <strong>B</strong>. In connection with the Services, we are required by law to
                provide you with certain Communications in writing. Without your consent, we are not
                permitted to provide those Communications to you electronically. The Services are
                intended for use only by persons who are willing and able to receive Communications
                electronically, exclusively through the Website, the Bolster mobile Application
                (“App”), push notification, SMS message, or via electronic mail ("Email"). If you do
                not agree to receive the Communications described herein in electronic, and not
                paper form, you may not open an Account or receive Services. Similarly, if after
                providing your consent hereunder, you withdraw such consent, your Account will be
                closed and you will not be permitted to use any Services.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6">
                <strong>C. Scope of Communications to Be Provided in Electronic Form</strong>
              </h4>
              <p>
                When you use the Services, you agree that we may provide you with any Communications
                in electronic format, to the extent allowed by law, and that we may discontinue
                sending paper Communications to you. Your consent to receive electronic
                Communications and transactions includes, but is not limited to:
              </p>
              <ul class="ml-4 mt-4 mb-4">
                <li class="mb-4">
                  - All legal and regulatory disclosures and communications associated with your
                  Account, including e-statements, account histories, or other periodic statements;
                </li>
                <li class="mb-4">- Change-in-terms related to your Account;</li>
                <li class="mb-4">- Privacy policies and notices;</li>
                <li class="mb-4">- Responses to claims ﬁled in connection with your Account;</li>
                <li class="mb-4">- Payment authorizations, confirmations, and receipts;</li>
                <li class="mb-4">
                  - All other Communications between us and you concerning your Account and any
                  related transactions, products or services.
                </li>
              </ul>
            </li>
            <li class="mb-4">
              <h4 class="h6">
                <strong>D. Method of Providing Communications to You in Electronic Form</strong>
              </h4>
              <p>
                Communications that we provide to you in electronic form will be provided either (1)
                via Email; (2) via the App; (3) by access to a web page that is designated in an
                Email notice sent to you at the time the information is available; or (4) via SMS
                message and push notification. You will not receive a notiﬁcation when your
                statement is available to be viewed. You are responsible for retrieving the
                statements from the Website or the App.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>E. How to Withdraw Consent</strong></h4>
              <p>
                You may exercise your right to withdraw your consent to receive Communications in
                electronic form at any time by writing to us at: 685 Center Street South, Suite
                1900, Calgary, Alberta T2G2C7. Any withdrawal of your consent will be effective
                after a reasonable period of time for processing your request. If you withdraw your
                consent we may close your Account or limit your access to your Account and the
                Services. You agree to repay any amounts you owe us on your Account. If you withdraw
                your consent, the legal validity and enforceability of prior Communications
                delivered in electronic form will not be affected.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>F. How to Update Your Records</strong></h4>
              <p>
                It is your responsibility to provide us with a true, accurate and complete Email
                address, contact, and other information related to these Terms and your Account, and
                to maintain and update promptly any changes in this information. You can update
                information (such as your Email address) by Emailing support@bolsterbuilt.com. We
                are not responsible for any delay or failure in your receipt of the Communications
                that is not caused by our failure to send such Communication to the contact
                information you provided to us. We will not assume liability for any delay or
                failure in your receipt of Communications in the event that your mobile phone
                number, Email address or other information we have on file for you is invalid; your
                Email service provider, mobile carrier, or Internet service provider filters the
                notification as “spam” or “junk mail”; there is a malfunction in your computer,
                mobile device, browser, Internet service, mobile connectivity or software; or for
                other reasons beyond our reasonable control.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>G. Hardware and Software Requirements</strong></h4>
              <p>
                In order to access, view, and retain electronic Communications that we make
                available to you, you must have:
              </p>
              <ul class="ml-4 mt-2 mb-4">
                <li class="mb-4">
                  - A working mobile telephone number that can receive SMS messages;
                </li>
                <li class="mb-4">
                  - Sufficient storage space on your mobile device or personal computer to save
                  Communications we send, and/or the ability to print the Communications from the
                  device or computer on which you view them;
                </li>
                <li class="mb-4">
                  - Access to an active Email account with an Email service provider, and if you use
                  a spam filter that blocks or re-routes Emails from senders not listed in your
                  address book, you must permit messages from the BolsterBuilt.com domain in your
                  spam filter;
                </li>
                <li class="mb-4">- Access to the internet or mobile connectivity; AND</li>
                <li class="mb-4">
                  - A current version of a program that accurately reads and displays PDF files
                  (e.g., Adobe Acrobat Reader).
                </li>
              </ul>
              <p>
                We may update these requirements as necessary to preserve the ability to receive
                electronic Communications. We will notify you if our hardware or software
                requirements change and whether that change creates a material risk that you would
                not be able to access or retain your electronic Communications. Continuing to use
                the Services after receiving notice of the change is the reaffirmation of your
                consent.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>H. Requesting Paper Copies</strong></h4>
              <p>
                We may, but assume no obligation, to provide a paper (instead of electronic) copy of
                any Communication that you have authorized us to provide electronically. You can
                obtain a paper copy of an electronic Communication by printing it yourself or by
                requesting that we mail you a paper copy. To request a paper copy, contact us at
                support@bolsterbuilt.com during normal business hours. We may charge you a
                reasonable service charge for the delivery of paper copies of any Communication
                provided to you electronically pursuant to this authorization.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>I. Communications in Writing</strong></h4>
              <p>
                All Communications in either electronic or paper format from us to you will be
                considered "in writing". You should print or download for your records a copy of
                these Terms and any other Communication that is important to you.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>J. U.S. Federal Law</strong></h4>
              <p>
                You acknowledge and agree that your consent to electronic Communications is being
                provided in connection with a transaction affecting interstate commerce that is
                subject to the United States federal Electronic Signatures in Global and National
                Commerce Act (the "Act"), and that you and we both intend that the Act apply to the
                fullest extent possible to validate our ability to conduct business with you by
                electronic means.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>K. Termination/Changes</strong></h4>
              <p>
                We reserve the right, at our sole discretion, to discontinue the provision of your
                electronic Communications, or to terminate or change the terms and conditions on
                which we provide electronic Communications. We will provide you with notice of any
                such termination or change as required by law.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>L. Consent</strong></h4>
              <p>
                By opening an Account, you give your afﬁrmative consent to our provision of
                electronic Communications to you as described herein. You understand and agree that
                your electronic signature or acceptance has the same force and effect as a signature
                given in ink. You further agree that your mobile device or other device you will use
                to receive, view, store, and/or print Communications satisﬁes the hardware and
                software requirements speciﬁed above and that you have provided us with a current
                Email address and mobile telephone number at which we may send electronic
                Communications to you. If you do not provide your consent and agreement, you may not
                use the Services and must discontinue your use of the Services immediately.
              </p>
            </li>
          </ol>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>8. Indemnification</strong></h3>
          <p>
            You agree to defend, indemnify and hold harmless Bolster and its subsidiaries, agents,
            licensors, managers, and other affiliated companies, and their employees, contractors,
            agents, officers and directors, and our third-party service providers, from and against
            any and all third-party claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney’s fees) arising out of,
            related to, or resulting from: (a) your use of and access to the Services, including any
            data or content transmitted or received by you; (b) your violation of any term of these
            Terms; (c) your violation of any third-party right, including without limitation any
            right of privacy or intellectual property rights; (d) your violation of any applicable
            law, rule or regulation; (e) your intentional misconduct; or (f) any other party’s
            access and use of the Services with your unique username, password or other appropriate
            security code.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>9. No Warranty</strong></h3>
          <p>
            THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICES IS
            AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE
            PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM BOLSTER OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
            HEREIN. WITHOUT LIMITING THE FOREGOING, BOLSTER, ITS SUBSIDIARIES, ITS AFFILIATES, AND
            ITS LICENSORS DO NOT WARRANT THAT THE INFORMATION PROVIDED TO YOUR THROUGH THE SERVICES
            IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT
            THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR
            SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICES ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF THE SERVICES IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
            RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT
            RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICES. BOLSTER DOES NOT WARRANT,
            ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE OFFERED BY A
            THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED WEBSITE OR SERVICE, AND BOLSTER WILL
            NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
            PROVIDERS OF PRODUCTS OR SERVICES.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>10. Limitation of Liability</strong></h3>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL BOLSTER, ITS
            AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
            WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, THE SERVICES.
            UNDER NO CIRCUMSTANCES WILL BOLSTER BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY
            RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICES OR
            YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN. TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, BOLSTER ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICES; (III) ANY
            UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL INFORMATION
            STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
            SERVICES; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO
            OR THROUGH OUR SERVICES BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT
            OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
            EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICES; AND/OR (VII)
            USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO
            EVENT SHALL BOLSTER, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
            LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS,
            DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE GREATER OF THE TOTAL FEES PAID TO
            BOLSTER IN THE THREE CALENDAR MONTHS PRIOR TO THE DATE THE CLAIM AROSE OR US$100.00 or
            CAD$100.00 AS APPLICABLE. YOU AGREE THAT ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR
            RELATED TO THESE TERMS OR THE USE OF THE SERVICES MUST BE FILED WITHIN ONE (1) YEAR
            AFTER THE CAUSE OF ACTION AROSE OR BE FOREVER BARRED. THIS LIMITATION OF LIABILITY
            SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF BOLSTER HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
            FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>11. Term and Termination</strong></h3>
          <p>
            The Terms are effective when you begin using Bolster’s website and/or Services and
            continues until terminated by either you or us, or in accordance with any Supplemental
            Terms or as otherwise set forth in these Terms. You may terminate these Terms at any
            time, provided that your Account is in "good standing". An Account will be considered to
            be in good standing if: (a) there are no active projects; (b) there are no active
            outstanding payments owed to other Users; and (c) there are no outstanding fees owed to
            Bolster. Notwithstanding any termination by you, you will still be responsible for any
            charges, fees, fines, and other losses caused by your action or inaction prior to
            terminating these Terms. We may terminate these Terms or suspend your Account at any
            time by providing you notice. Any such termination or suspension shall be made by
            Bolster in its sole discretion, and Bolster will not be responsible to you or any third
            party for any damages that may result or arise out of such termination or suspension of
            your Account and/or access to the Services.
          </p>
        </li>
        <li class="mt-4">
          <h3 class="h5">
            <strong>12. Governing Law, Arbitration, and Class Action/Jury Trial Waiver</strong>
          </h3>
          <ol start="A" class="mt-4">
            <li class="mb-4">
              <h4 class="h6"><strong>A. Governing Law</strong></h4>
              <p><strong>For Customers based in the United States only:</strong></p>
              <p class="mb-4">
                You agree that: (i) the Services covered by these Terms shall be deemed provided by
                Bolster Built Inc. (USA), a Delaware corporation, and such Services shall be deemed
                provided solely based in the State of Texas; and (ii) the Services shall be deemed a
                passive one that does not give rise to personal jurisdiction over us, either
                specific or general, in jurisdictions other than the State of Texas. These Terms
                shall be governed by the internal substantive laws of the State of Texas , without
                respect to its conflict of laws principles. The parties acknowledge that these Terms
                evidence a transaction involving interstate commerce. Notwithstanding the preceding
                sentences with respect to the substantive law, any arbitration conducted pursuant to
                the terms of these Terms shall be governed by the Federal Arbitration Act (9 U.S.C.
                §§ 1-16). You agree to submit to the personal jurisdiction of the federal and state
                courts located in Dallas County, Texas for any actions for which we retain the right
                to seek injunctive or other equitable relief in a court of competent jurisdiction to
                prevent the actual or threatened infringement, misappropriation or violation of a
                our copyrights, trademarks, trade secrets, patents, or other intellectual property
                or proprietary rights or confidential information, as set forth in the Arbitration
                provision below, including any provisional relief required to prevent irreparable
                harm. Subject to Section 12B, you agree that the federal or state court located in
                Dallas County, Texas is the proper forum for any appeals of an arbitration award or
                for trial court proceedings in the event that the Arbitration provision below is
                found to be unenforceable.
              </p>
              <p><strong>For Customers based in Canada only:</strong></p>
              <p>
                You agree that the Services covered by these Terms shall be deemed provided by
                Bolster Built Inc., a Canadian corporation, and shall be deemed provided solely
                based in the Province of Alberta. These Terms shall be governed by the laws in
                effect in the Province of Alberta, Canada. No choice of laws or rules of any
                jurisdiction shall apply to these Terms . The courts of the Province of Alberta
                located in Calgary shall have jurisdiction over any legal action or proceeding
                arising out of or relating to these Terms, the Bolster website or the Services and
                you consent to the jurisdiction of such courts for any such action or proceeding.
                You waive all rights that you may have or that may hereafter arise to contest such
                jurisdiction of such courts. The application of the United Nations Convention on
                Contracts for the International Sale of Goods to these Terms is expressly excluded.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>B. Arbitration</strong></h4>
              <p><strong>For Customers based in the United States only:</strong></p>
              <p>
                READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR
                DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM BOLSTER. For any
                dispute with Bolster, you agree to first contact us at support@bolsterbuilt.com and
                attempt to resolve the dispute with us informally. In the unlikely event that
                Bolster has not been able to resolve a dispute it has with you after sixty (60)
                days, we each agree to resolve any claim, dispute, or controversy (excluding any
                claims arising from protection of our intellectual property rights or breach of
                confidentiality requirements, which will be resolved through litigation in
                accordance with Section 9A as applicable to customers in the United States, or for
                injunctive or other equitable relief as provided below) arising out of or in
                connection with or relating to these Terms, or the breach or alleged breach thereof
                (collectively, “Claims”), by binding arbitration by JAMS. Claims with amounts
                claimed greater than US$250,000 will apply the JAMS Comprehensive Arbitration Rules
                and Procedures; and Disputes with amounts claimed less than or equal to US$250,000
                will apply the JAMS Streamlined Arbitration Rules. The arbitration will be conducted
                in Dallas, Texas unless you and Bolster agree otherwise. If JAMS cannot administer
                the Claim, either party may petition the US District Court for the Northern District
                of Texas to appoint an arbitrator. Each party will be responsible for paying any
                JAMS filing, administrative and arbitrator fees in accordance with JAMS rules, and
                the award rendered by the arbitrator shall include costs of arbitration, reasonable
                attorneys’ fees and reasonable costs for expert and other witnesses. Any judgment on
                the award rendered by the arbitrator may be entered in any court of competent
                jurisdiction. Either party may commence arbitration by providing a written demand
                for arbitration to JAMS and the other party detailing the subject of the Claim and
                the relief requested. Each party will continue to perform its obligations under
                these Terms unless that obligation or the amount (to the extent in dispute) is
                itself the subject of the Claim. Nothing in this Section shall be deemed as
                preventing either party from seeking injunctive or other equitable relief from the
                courts as necessary to prevent the actual or threatened infringement,
                misappropriation, or violation of our data security, intellectual property rights or
                other proprietary rights or confidentiality obligations. Proceedings and information
                related to them will be maintained as confidential, including the nature and details
                of the Claim, evidence produced, testimony given, and the outcome of the Claim,
                unless such information was already in the public domain or was independently
                obtained. You and Bolster, and all witnesses, advisors, and arbitrators will only
                share such information as necessary to prepare for or conduct arbitration or other
                legal proceeding, or enforcement of the outcome, unless additional disclosure is
                required by law.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>C. Class Action/Jury Trial Waiver</strong></h4>
              <p>
                With respect to all persons and entities, regardless of whether they have obtained
                an Account or used the Services for personal, commercial or other purposes, all
                Claims must be brought in the parties’ individual capacity, and not as a plaintiff
                or class member in any purported class action, collective action, private attorney
                general action or other representative proceeding. This waiver applies to class
                arbitration, and, unless we agree otherwise, the arbitrator may not consolidate more
                than one person’s claims. You agree that, by entering into these Terms , you and
                Bolster are each waiving the right to a trial by jury or to participate in a class
                action, collective action, private attorney general action, or other representative
                proceeding of any kind.
              </p>
            </li>
          </ol>
        </li>
        <li class="mt-4">
          <h3 class="h5"><strong>13. General</strong></h3>
          <ol start="A" class="mt-4">
            <li class="mb-4">
              <h4 class="h6"><strong>A. Assignment</strong></h4>
              <p>
                These Terms and any rights and licenses granted hereunder, may not be transferred or
                assigned by you, but may be assigned by Bolster without restriction. Any attempted
                transfer or assignment in violation hereof shall be null and void.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6">
                <strong>B. Notification Procedures and Changes to the Terms</strong>
              </h4>
              <p class="mb-4">
                You consent to us providing notices to you under these Terms electronically and
                understand that this consent has the same legal effect as a physical signature. We
                will provide notices to you electronically through your Account, and via text or SMS
                to the phone numbers provided to us by Administrators and Users. If you sign up to
                receive certain Bolster notifications or information via text or SMS, you may incur
                additional charges from your wireless provider for these notices. You agree that you
                are solely responsible for any such charges.
              </p>
              <p>
                Notices affecting these Terms will be sent by email to Administrators and posted on
                our website and are considered received 24 hours after they are sent and/or posted.
                You understand that you may not use the Services unless you consent to receive
                notices from us electronically. You may only withdraw consent to receive notices
                electronically by closing your Account and ceasing to use the Services. Notices may
                include alerts about the Services and your Account and may provide Administrators
                and Users the ability to respond with information about transactions or your
                Account. Administrators and Users may disable notification preferences to limit the
                use of certain Service features. Administrators and Users are required to maintain a
                regularly updated web browser, and computer and mobile device operating systems to
                receive notices correctly. Administrators and Users will be responsible for all
                costs imposed by internet or mobile service providers for sending or receiving
                notices electronically. Contact us immediately at support@bolsterbuilt.com if you
                are having trouble receiving notices from us.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>C. Entire Agreement Terms/Severability</strong></h4>
              <p>
                These Terms together with the other agreements set out in the introduction to these
                Terms and any amendments thereto and any Supplemental Terms you may enter into with
                Bolster in connection with the Services, shall constitute the entire agreement
                between you and Bolster concerning your Account and the Services. If any provision
                of these Terms is deemed invalid by a court of competent jurisdiction, the
                invalidity of such provision shall not affect the validity of the remaining
                provisions of these Terms, which shall remain in full force and effect, except that
                in the event of unenforceability of the universal Class Action/Jury Trial Waiver,
                the entire arbitration agreement if applicable shall be unenforceable.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>D. No Waiver</strong></h4>
              <p>
                No waiver of any term of these Terms shall be deemed a further or continuing waiver
                of such term or any other term, and Bolster’s failure to assert any right or
                provision under these Terms shall not constitute a waiver of such right or
                provision.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>E. Legal Orders</strong></h4>
              <p>
                We may respond to and comply with any subpoenas, warrants, liens, or any other legal
                order we receive related to your use of the Services. We are not responsible to you
                for any losses you incur due to our response to such legal order. We may hold funds
                or provide information as required by the issuer of the legal order or take any
                other actions we believe are required of us under legal orders. Where permitted, we
                will provide you reasonable notice that we have received such an order.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>F. Continuing Obligations</strong></h4>
              <p>
                All other provisions of these Terms giving rise to continued obligations of the
                parties will continue in effect after termination of these Terms.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>G. Force Majeure</strong></h4>
              <p>
                We shall not be liable for delays, failure in performance or interruption of
                Services which result directly or indirectly from any cause or condition beyond our
                reasonable control, including but not limited to, significant market volatility, act
                of God, act of civil or military authorities, act of terrorists, civil disturbance,
                war, strike or other labor dispute, fire, interruption in telecommunications or
                Internet services or network provider services, failure of equipment and/or
                software, pandemic, other catastrophe or any other occurrence which is beyond our
                reasonable control and shall not affect the validity and enforceability of any
                remaining provisions.
              </p>
            </li>
            <li class="mb-4">
              <h4 class="h6"><strong>H. English Language</strong></h4>
              <p>
                It is the express wish of the parties that these Terms and all related documents be
                drawn up in English. C’est la volonté expresse des parties que la présente
                convention ainsi que les documents qui s’y rattachent soient rédigés en anglais.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </CardSection>
  </Container>
</template>

<script>
export default {
  name: 'TermsAndConditions'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
