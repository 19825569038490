<template>
  <card-section>
    <div class="!bg-flame-white rounded !border !border-surface-200 shadow shadow-surface-200/30">
      <card-list-field>
        <span>
          Cover the credit card transaction fee?
          <small v-if="!computedSettings.payCreditCardFee"
            >A transaction fee will be added to the client's invoice.</small
          >
          <small v-if="computedSettings.payCreditCardFee"
            >A transaction fee will be subtracted from your payout.</small
          >
          <p class="text-info pt-2">
            Within each invoice settings you can opt in or out to pay for the credit card
            transaction fee. Otherwise this global setting will be used.
          </p>
        </span>
        <span>
          <toggle
            :value="computedSettings.payCreditCardFee"
            :onValue="1"
            :offValue="0"
            @input="(val) => updateSettings('payCreditCardFee', val)"
          />
        </span>
      </card-list-field>
      <card-list-field v-if="!isPayfacEnabled">
        <span>
          Cover the Bolster transaction fee?
          <small v-if="!computedSettings.payCostCertifiedFee"
            >A transaction fee will be added to the client's invoice.</small
          >
          <small v-if="computedSettings.payCostCertifiedFee"
            >A transaction fee will be subtracted from your payout.</small
          >
          <p class="text-info pt-2">
            Within each invoice settings you can opt in or out to pay for the Bolster transaction
            fee. Otherwise this global setting will be used.
          </p>
        </span>
        <span>
          <toggle
            :value="computedSettings.payCostCertifiedFee"
            :onValue="1"
            :offValue="0"
            @input="(val) => updateSettings('payCostCertifiedFee', val)"
          />
        </span>
      </card-list-field>
      <card-list-field v-if="isPayfacEnabled">
        <span>
          Cover the bank transfer transaction fee?
          <small v-if="!computedSettings.payBankTransferFee"
            >A transaction fee will be added to the client's invoice.</small
          >
          <small v-if="computedSettings.payBankTransferFee"
            >A transaction fee will be subtracted from your payout.</small
          >
          <p class="text-info pt-2">
            Within each invoice settings you can opt in or out to pay for the Bolster transaction
            fee. Otherwise this global setting will be used.
          </p>
        </span>
        <span>
          <toggle
            :value="computedSettings.payBankTransferFee"
            :onValue="1"
            :offValue="0"
            @input="(val) => updateSettings('payBankTransferFee', val)"
          />
        </span>
      </card-list-field>
      <card-list-field
        v-if="
          isPayfacEnabled && !computedSettings.aggregatedPayments && !hideDisplayItemizedPricing
        "
      >
        <span>
          <help>
            <template #before>Display itemized pricing</template>
            When using itemized payments display the price for each item to the client
          </help>
        </span>
        <span>
          <toggle
            :value="computedSettings.displayItemizedPricing"
            :onValue="1"
            :offValue="0"
            @input="(val) => updateSettings('displayItemizedPricing', val)"
          />
        </span>
      </card-list-field>
      <card-list-field v-if="isPayfacEnabled && !hideAggregatedPayments">
        <span>
          <help>
            <template #before>Percentage Payments</template>
            When approving items we will automatically batch the payments into one line item which
            displays as the total percentage of the project
          </help>
        </span>
        <span>
          <toggle
            :value="computedSettings.aggregatedPayments"
            :onValue="1"
            :offValue="0"
            @input="(val) => updateSettings('aggregatedPayments', val)"
          />
        </span>
      </card-list-field>
      <card-list-field
        v-if="isPayfacEnabled && !hideSettlementTime"
        class="bg-transparent !shadow-none"
      >
        <span>
          Settlement time
          <small>Select the settlement time you would like to switch to.</small>
        </span>
        <span>
          <choose
            :value="payoutInterval"
            class="mt-2"
            btnClass="info flat"
            v-model="payoutInterval"
            @input="(val) => onChangeSettlementInterval(val)"
            :static-set="settlementTimes"
          />
        </span>
      </card-list-field>
    </div>
  </card-section>
</template>
<script>
import { paymentMethods } from '../paymentMethods/PaymentMethods'
import PayfacMixin from '../PayfacMixin'
import CounterpartyMixin from '../counterparty/CounterpartyMixin'

export default {
  name: 'PaymentSettings',
  props: {
    settings: {
      type: Object,
      required: true
    },
    merchant: {
      type: Object
    },
    hideSettlementTime: {
      type: Boolean,
      default: false
    },
    hideAggregatedPayments: {
      type: Boolean,
      default: false
    },
    hideDisplayItemizedPricing: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  data() {
    return {
      paymentMethods,
      payoutInterval: 'regular',
      autoPayouts: false,
      computedSettings: {
        payCreditCardFee: parseInt(this.settings.payCreditCardFee, 10),
        payCostCertifiedFee: parseInt(this.settings.payCostCertifiedFee, 10),
        payBankTransferFee: parseInt(this.settings.payBankTransferFee, 10),
        displayItemizedPricing: parseInt(this.settings.displayItemizedPricing, 10),
        aggregatedPayments: parseInt(this.settings.aggregatedPayments, 10)
      }
    }
  },
  watch: {
    settings: {
      handler(newVal) {
        this.computedSettings = {
          payCreditCardFee: parseInt(newVal.payCreditCardFee, 10),
          payCostCertifiedFee: parseInt(newVal.payCostCertifiedFee, 10),
          payBankTransferFee: parseInt(newVal.payBankTransferFee, 10),
          displayItemizedPricing: parseInt(this.settings.displayItemizedPricing, 10),
          aggregatedPayments: parseInt(this.settings.aggregatedPayments, 10)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateSettings(key, value) {
      console.log(key, 'key')
      console.log(value, 'value')
      const settings = Object.keys(this.settings).reduce((acc, k) => {
        acc[k] = parseInt(this.settings[k], 10)
        return acc
      }, {})
      this.$emit('change', { ...settings, [key]: parseInt(value, 10) })
    }
  },
  computed: {
    settlementTimes() {
      return [
        {
          text: 'Regular',
          value: 'regular'
        },
        {
          text: 'Fast',
          value: 'fast'
        },
        ...(this.counterpartyCountry === 'us'
          ? [
              {
                text: 'Faster',
                value: 'instant'
              }
            ]
          : [])
      ]
    }
  },
  mounted() {
    if (this.merchant) {
      this.payoutInterval = this.merchant.settings.payout_speed || 'regular'
      this.autoPayouts = this.merchant && this.merchant.settings.payout_interval !== 'manual'
    }
  },
  mixins: [PayfacMixin, CounterpartyMixin],
  components: {}
}
</script>
