<template>
  <transition-group name="list-complete" :class="classes" :tag="tag">
    <slot></slot>
  </transition-group>
</template>

<script>
export default {
  name: 'transition-list',
  props: {
    classes: {
      required: false
    },
    tag: {
      type: String,
      required: false,
      default() {
        return 'div'
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.list-complete > * {
  transition: all 2s;
  display: inline-block;
  position: relative;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
