<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <Rating v-model="value" readonly :cancel="false" size="sm" />
    </template>
  </preview>
</template>

<script>
import PreviewMixin from './PreviewMixin'
import Preview from '@/components/ui/Previews/Base.vue'
import Rating from 'primevue/rating'

export default {
  name: 'PreviewOverallRating',
  components: { Preview, Rating },
  mixins: [PreviewMixin],
  data() {
    return {
      value: this.object.overallRating
    }
  },
  watch: {},
  computed: {},
  methods: {}
}
</script>
<script setup lang="ts"></script>
