<template>
  <preview
    v-bind="$props"
    v-if="object"
    class="quote-preview--container"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
    :class="{
      'has-unnapproved':
        object.quote_has_change_orders_unnapproved_by_client && /k|f/.test(object.quote_status)
    }"
  >
    <template #image>
      <PersonAvatar
        type="client"
        class="mx-2"
        :name="object.oClient.client_name"
        :id="object.client_id"
      />
    </template>
    <template #primary>
      <div
        style="flex: 1 100%; overflow: hidden; text-overflow: ellipsis; text-align: left"
        class="text-pitch-black"
        v-tooltip="object.quote_address"
      >
        {{ object.quote_name || 'Unnamed' }}
        <small class="text-muted" v-if="object.quote_is_rfq"> RFQ </small>
      </div>
    </template>
    <template #secondary>
      <div class="flex flex-row justify-between items-center w-full">
        <span class="flex flex-row items-center gap-x-1 !text-surface-400 !font-light">
          <Icon icon="user" class="text-[10px]" />
          {{ `${object.oClient.user_fname} ${object.oClient.user_lname}` }}
          • ${{ $f.currency(object.quote_price_net) }}
          <div v-if="object.quote_is_rfq">
            • {{ object.quote_count_bids_queued }}/{{ object.quote_count_bids }} bids
          </div>
        </span>
      </div>
    </template>

    <template #right>
      <next-steps :object="object" :grid="grid" v-if="steps" :inline="false" />
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import NextSteps from './NextSteps.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewQuote',
  emits: ['click'],
  data() {
    return {}
  },
  computed: {
    icon() {
      if (this.object) {
        if (/k|f/.test(this.object.quote_status)) return 'house-building'
        else if (this.object.quote_status === 'p') return 'file-signature'
        return 'ban'
      }
      return 'file'
    },
    classStatus() {
      return c.statusColors[this.object.quote_status]
    },
    status() {
      return c.format(this.object.quote_status, 'status')
    }
  },
  props: {
    right: {},
    middle: {},
    object: {
      type: Object,
      required: true
    }
  },
  components: { Preview, NextSteps }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.quote-preview--container {
  .secondary {
    width: 100%;
    .number {
      margin-left: auto;
    }
  }

  .has-unapproved {
    background: rgba($orange-500, 0.3);
  }

  .secondary--client-name {
    max-width: 50%;
    flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
