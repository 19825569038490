export function getInvoiceSteps(object, store) {
  let steps = []

  if (!object.invoice_time_sent && object.invoice_status === 'o') {
    steps = [
      ...steps,
      {
        name: 'This invoice has never been sent.',
        short: 'Send invoice',
        action: (obj, button, grid) =>
          store.dispatch('Invoice/send', {
            selected: _.makeArray(obj),
            grid,
            button
          }),
        glyph: 'paper-plane',
        color: 'green-500'
      }
    ]
  } else if (object.invoice_status === 'o' && object.invoice_time_due <= new Date().valueOf()) {
    steps = [
      ...steps,
      {
        name: 'This invoice is past-due and should be followed up on.',
        short: 'Follow up on invoice',
        action: (obj, button, grid) =>
          store.dispatch('phone/call', {
            number: obj.oClient.user_phone,
            tags: {
              phone_call_desc: `Call to follow up on past-due invoice #${obj.invoice_id}
                  that was due ${_.format(obj.invoice_time_due, 'timerelative')}.`,
              invoice_id: obj.invoice_id,
              quote_id: obj.quote_id
            },
            grid,
            button
          }),
        glyph: 'phone',
        color: 'surface-600'
      }
    ]
  } else if (
    object.invoice_status === 'o' ||
    object.invoice_status === 'pr' ||
    object.invoice_status === 'p'
  ) {
    steps = [
      ...steps,
      {
        name: 'This invoice has been paid in full.',
        short: 'Mark as paid',
        action: (obj, button, grid) =>
          store.dispatch('Invoice/markMultiple', {
            markAs: 'paid',
            selected: [obj],
            grid,
            button,
            go: false
          }),
        glyph: 'check',
        color: 'green-500'
      }
    ]
  } else if (object.invoice_status === 'e' && !object.invoice_receipt_time_sent) {
    steps = [
      ...steps,
      {
        name: 'This invoice has been paid but no receipt has been sent.',
        short: 'Send receipt',
        action: (obj, button, grid) =>
          store.dispatch('Invoice/sendReceipt', {
            selected: [obj],
            grid,
            button,
            go: false
          }),
        glyph: 'paper-plane',
        color: 'green-500'
      }
    ]
  }

  return steps
}
