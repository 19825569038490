export default {
  icon: 'cube',
  title: 'Create and save an item',
  desc: 'Learn how to add a line item, with description and pictures, then connect it to a dimension, and save it for later.',
  time: '2 min',
  id: 'Item',
  setupSheet: async (sheet, $store, props) => {
    sheet.addRow()
    sheet.resetColWidths()
    ;[0, 1, 2, 3, 4, 5, 6, 7].map((i) => sheet.uncollapseSuperHeader(0, i))
    sheet.triggerRedraw()
    await c.throttle(() => {}, { delay: 200 })
    const ref = sheet.rowsMap[sheet.rowsMap.length - 1].id
    await $store.dispatch(`${props.store}/field`, {
      changes: {
        [ref]: {
          cost_type_name: 'Flooring',
          cost_type_desc: 'A cheap and cheerful floor for your home!',
          unit_of_measure_id: 'ft2',
          cost_type_qty_equation: 'fa',
          cost_matrix_materials_cost_net: 10,
          cost_matrix_labor_cost_net: 5,
          cost_type_has_materials: 1,
          cost_type_has_labor: 1,
          cost_type_is_subcontracted: 0,
          stage_id: 1648
        }
      },
      immediate: true,
      explicit: true
    })
  },
  steps: [
    {
      icon: 'pencil',
      tooltip: 'Enter a name for your new item. Press Tab or click next to continue.',
      cell: ['name', 0],
      focus: false,
      position: 'bottom'
    },
    {
      icon: 'align-left',
      tooltip:
        'Enter a description for your item in the "Description" column. This is your sales pitch for this item to your client.',
      cell: ['desc', 0],
      focus: true,
      position: 'left'
    },
    {
      icon: 'images',
      tooltip:
        'Attach a picture or gallery for this item. Click on the Pics column to add attachments.',
      cell: ['files', 0],
      focus: true,
      position: 'top'
    },
    {
      icon: 'ruler-triangle',
      tooltip: `Link your item to a dimension. This one is linked to the "floor area" with code "fa". 
      You can link multiple items to the same dimension, automating the process in the future.`,
      cell: ['dims', 0],
      focus: true,
      position: 'top'
    },
    {
      icon: 'box-open-full',
      tooltip:
        'Now save your item to your library. Hover over the side of the item, click the grip icon [fa-solid fa-grip-dots-vertical] then choose "Add to library" to save this item so that you can re-use it in the future.',
      cell: ['gutter', 0],
      focus: false,
      offset: [-25],
      position: 'top'
    },
    {
      icon: 'cube',
      tooltip: 'Then your item is saved and ready for use in your next estimate.',
      cell: ['gutter', 0],
      position: 'bottom'
    },
    {
      icon: 'plus',
      tooltip: 'To add an item to the bottom of your project you can click this button, or...',
      element: [`[data-id="addItemButton"]`],
      position: 'right'
    },
    {
      icon: 'plus',
      tooltip: `... you can hover over this area, and click the "+" button to add an item 
        exactly where you want it inside the estimate.`,
      cell: ['gutter', 0],
      position: 'bottom',
      offset: [-50]
    }
  ]
}
