
export default {
  type: 'url_share',
  
  skipAudit: true,

  fields: {
    url_share_url: {
      filter: false,
      type: 'string',
      format: false,
      mapTo: false,
    },
    url_share_object_type: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false,
    },
    url_share_object_id: {
      filter: true,
      type: 'int',
      format: false,
      mapTo: false,
    },
    url_share_time_created: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: false,
    },
    url_share_creator: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: false,
    },
    company_id: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: 'company',
    },
    user_id: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: 'user',
    },
    url_share_code: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: false,
    },
    url_share_scope_code: {
      filter: false,
      type: 'int',
      format: false,
      mapTo: false,
    },
  },
};
