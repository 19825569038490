import { toRefs, reactive, computed } from 'vue'
import { useStore } from 'vuex'

export const initialState = {
  sidebarOpen: false,
  openWidth: 300,
  closedWidth: 70
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const $store = useStore()

  context.sidebarOpen = $store.state.session.deviceWidth < 825

  const sideBarWidth = computed(() =>
    context.sidebarOpen ? context.openWidth : context.closedWidth
  )

  return {
    ...toRefs(context),
    sideBarWidth
  }
}
