import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue'

export default {
  useCalculator(args) {
    const { props, editableDiv, emit } = args

    const cursorPosition = ref(0)
    const raw = ref(`${props.value || ''}`)
    const html = ref('')
    const editable = ref(true)
    const variables = computed(() => props.variables)

    const setEditable = (isEditable) => {
      editable.value = !!isEditable
    }

    const focused = ref(false)

    const forceMultiline = ref(c.isEquation(props.value, props.variables) ? true : false)
    const multiLine = computed(() => forceMultiline.value)

    const getValue = () => raw.value

    const setCursorAtEnd = () => {
      setCursorPosition(raw.value.length)
    }

    const prefillValue = (val) => {
      raw.value = `${val}${raw.value || ''}`
      setCursorAtEnd()
      updateHtml()
    }

    const setValue = (val) => {
      raw.value = val
      setCursorAtEnd()
      updateHtml()
    }

    const handleFocusIn = () => {
      emit('focus')
      if (!focused.value) setCursorAtEnd()
      focused.value = true
    }

    const handleFocusOut = () => {
      emit('blur')
      focused.value = false
    }

    const handleClick = () => {
      emit('click')
    }

    const focus = () => {
      if (!editableDiv.value) return
      editableDiv.value.click()
      editableDiv.value.focus()
      editableDiv.value.click()
      editableDiv.value.focus()
      setTimeout(() => {
        editableDiv.value?.click()
        editableDiv.value?.focus()
      }, 400)
      editable.value = true

      // Create a range and select the node contents
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNodeContents(editableDiv.value)

      // Set the cursor to the end of the current content
      range.collapse(false) // Collapse the range to the end point. Set to true for start.
      selection.removeAllRanges()
      selection.addRange(range)
    }

    const handleDblClick = () => {
      emit('dblclick')
      editable.value = true
      focus()
    }

    const blur = () => {
      if (!editableDiv.value) return
      editableDiv.value.blur()
      editable.value = false
    }

    const isFocused = () => document.activeElement === editableDiv.value

    const variableRegEx = computed(
      () => new RegExp(`\\b(${Object.keys(variables.value || {}).join('|')})\\b`, 'g')
    )

    const wrapTags = (content) => {
      let val = String(content)
      // val = val.replace(/(\d),(\d)/g, '$1$2');
      val = val.replace(/&(nbsp|amp|quot|lt|gt);/g, ' ')

      const orfuncs = Object.keys(c.calculatorFunctions).join('|')
      const funcregx = new RegExp(`((?:${orfuncs})?[()])(?![^<]*>|[^<>]*<\\/)`, 'gi')

      let string = val

      // Next, convert newline with a [\*\+\-\/xX] cahracter to newline, with invisible
      // string = string.replace(
      //   /(?:\r\n|\r|\n)(?:\s+)?([*+-/])(?![^<]*>|[^<>]*<\/)/g,
      //   '\r\n<newline>$1</newline>'
      // )
      // Next, convert all paranthesis and functions to other color
      // .replace(/([()])(?![^<]*>|[^<>]*<\/)/g, '<parenthesis>$1</parenthesis>')
      string = string.replace(funcregx, '<parenthesis>$1</parenthesis>')
      // // First convert imperial notation
      string = string.replace(
        /((?:(\d+)\s?(?:ft|'|feet|foot))\s?(?:(\d+)\s(\d{1,2}\/\d{1,2})|(\d{1,2}\/\d{1,2})|(\d+))\s?(?:in|inch|inches|")|(?:(?:(\d+)(?:ft|'|feet|foot))(?:(\d+)))|(?:(?:(\d+)\s?(?:ft|'|feet|foot))|(?:(?:(\d+)\s)?(\d{1,2}\/\d{1,2})\s?(?:in|inch|inches|"))|(?:(\d+)\s?(?:in|inch|inches|"))))/gi,
        '<imperial>$1</imperial>'
      )
      //   // Then convert hours notation
      string = string.replace(
        /(((?:(\d+)(?:(?:\s?h(?:rs?)?(?:ours?)?\s?)|:))|(?:(\d+)(?:(?:\s?m(?:ins?)?(?:inutes?)?\s?)|:))|(?:(\d+)\s?s(?:ecs?)?(?:econds?)?)){1,3})/gi,
        '<time>$1</time>'
      )
      // Next, convert all modifiers to coloured spans
      string = string.replace(/([*+-/])(?![^<]*>|[^<>]*<\/)/g, '<modifier>$1</modifier>')
      // Next, convert all x or X characters to modifier
      string = string.replace(
        /(?:([^a-zA-Z])(?:X|x)([^a-zA-Z]))(?![^<]*>|[^<>]*<\/)/g,
        '$1<modifier>x</modifier>$2'
      )
      // Next, convert all numbers to other color

      string = string.replace(/(?!<[^<>]+>)(\d+)(?!<\/[^<>]+>)/g, '<number>$1</number>')
      // string = string.replace(/(\d+)/g, '<number>$1</number>');
      // Next, convert all notes to comments
      string = string.replace(
        /(?!<\/?)([^*+-/0-9></]+)(?!>)(?![^<]*>|[^<>]*<\/)/g,
        '<comments>$1</comments>'
      )

      string = string.replace(/(?!<\/?)( )(?!>)/g, '<space>$1</space>')
      string = string.replace(/(?!<\/?)(\n)(?!>)/g, '<newline>\n</newline>')

      if (variables.value && Object.keys(variables.value).length) {
        // Next convert variables
        string = string.replace(variableRegEx.value, (...matches) => {
          const abbr = matches[1]
          const variable = variables.value[abbr]
          const style = `color: #${c.ensureContrastWithWhite(variable.dimension_color || variable.color)}`
          return `<variable style="${style}">${abbr}</variable>`
        })
      }

      // Create a tail, so that white spaces are registered correctly, removed in getInnerText()
      string += '<end>\r</end>'

      return string
    }

    const getInnerText = () => editableDiv.value.innerText.replace(/\n$/, '')

    const updateHtml = () => {
      if (!editableDiv.value) return
      html.value = wrapTags(raw.value)
      editableDiv.value.innerHTML = html.value
      setCursorPosition(cursorPosition.value)
    }

    const forceValue = (typed) => {
      raw.value = typed
      setTimeout(() => emit('progress', typed))

      setCursorAtEnd()

      // You might need a more Vue 3 compatible throttle mechanism or a custom solution
      // For simplicity, this will just call updateHtml directly
      updateHtml()
    }
    const injectValue = (typed) => {
      let newValue = raw.value.split('')
      newValue.splice(cursorPosition.value, 0, typed)
      newValue = newValue.join('')

      raw.value = newValue
      let delta = typed ? typed.length : 0
      setCursorPosition(cursorPosition.value + delta)

      setTimeout(() => emit('progress', newValue))

      // You might need a more Vue 3 compatible throttle mechanism or a custom solution
      // For simplicity, this will just call updateHtml directly
      updateHtml()
    }

    const handleInput = async (event) => {
      if (event.inputType === 'insertParagraph') {
        // const arr = editableDiv.value.innerText.split('')
        // arr.splice(cursorPosition.value, 0, '\n')
        // raw.value = arr.join('')
        // // setCursorPosition(cursorPosition.value + 1)
        // updateHtml()
        return event.preventDefault()
      } else {
        saveCursorPosition()
        raw.value = getInnerText()
        updateHtml()
        return
      }
      //
      // console.log('handleInput', typed);
      // if (!typed) {
      //   // saveCursorPosition()
      //   return
      // }
      //
      // raw.value = editableDiv.value.innerText
      // setTimeout(() => emit('progress', editableDiv?.value?.innerText ?? raw.value))
      //
      // if (!isFocused()) return
      // let delta = typed ? typed.length : 0
      // if (event.inputType === 'deleteContentBackward') delta = -1
      // if (event.inputType === 'deleteContentForward') delta = 0
      //
      // setCursorPosition(cursorPosition.value + delta)
      //
      // // You might need a more Vue 3 compatible throttle mechanism or a custom solution
      // // For simplicity, this will just call updateHtml directly
      // updateHtml()
    }

    const saveCursorPosition = () => {
      if (!editableDiv.value) return
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(editableDiv.value)
        preCaretRange.setEnd(range.startContainer, range.startOffset)
        cursorPosition.value = preCaretRange.toString().length
      }
    }
    const mouseDown = ref(false)
    const handleMouseDown = () => {
      mouseDown.value = true
    }
    const handleMouseUp = () => {
      mouseDown.value = false
      saveCursorPosition()
    }
    onMounted(() => {
      window.addEventListener('mouseup', handleMouseUp)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', handleMouseUp)
    })

    const setCursorPosition = (position) => {
      cursorPosition.value = Math.max(0, Math.min(position, raw.value.length))
      if (!editableDiv.value) return
      const range = document.createRange()
      const selection = window.getSelection()
      const treeWalker = document.createTreeWalker(
        editableDiv.value,
        NodeFilter.SHOW_TEXT,
        null,
        false
      )
      let charCount = 0
      let currentNode = null
      while (treeWalker.nextNode()) {
        currentNode = treeWalker.currentNode
        const textLength = currentNode.textContent.length
        if (charCount + textLength >= position) {
          range.setStart(currentNode, position - charCount)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
          break
        }
        charCount += textLength
      }
    }

    const handleKeyup = () => {
      // if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      //   saveCursorPosition()
      //   return
      // }

      saveCursorPosition()
    }

    const handleKeydown = (event) => {
      // if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      //   saveCursorPosition()
      //   return
      // }
      if (event.key === 'Backspace' || event.key === 'Delete') {
        // const delta = event.key === 'Delete' ? 0 : -1
        // const text = editableDiv.value.innerText
        // const pos = cursorPosition.value
        // const arr = text.split('')
        //
        // arr.splice(pos + delta, 1)
        // raw.value = arr.join('')
        // console.log(raw.value);
        // cursorPosition.value += delta
        // updateHtml()
      } else if (event.key === 'Enter') {
        if (!multiLine.value) {
          blur()
          setTimeout(() => emit('enter'))
          return event.preventDefault()
        } else {
          saveCursorPosition()
          raw.value = getInnerText()
          const arr = raw.value.split('')
          arr.splice(cursorPosition.value, 0, `\n`)
          raw.value = arr.join('')
          setCursorPosition(cursorPosition.value + 1)
          updateHtml()
          event.preventDefault()
          event.stopPropagation()
          return event.preventDefault()
        }
        //         else {
        //           raw.value = `${raw.value}
        // `
        //           cursorPosition.value += 1
        //           saveCursorPosition()
        //           updateHtml()
        //         }
        //         event.stopPropagation()
      } else if (event.key === 'Tab' && !multiLine.value) {
        blur()
        setTimeout(() => emit('tab'))
        event.stopPropagation()
        return event.preventDefault() // Prevent default tab behavior (optional, based on requirements)
      } else if (event.keyCode === 32 || event.code === 'Space') {
        raw.value = getInnerText()
        const arr = raw.value.split('')
        arr.splice(cursorPosition.value, 0, ' ')
        raw.value = arr.join('')
        setCursorPosition(cursorPosition.value + 1)
        updateHtml()
        event.preventDefault()
      }
    }

    const propVal = computed(() => `${props.value || ''}`)

    watch(
      () => props.multiLine,
      (val) => (forceMultiline.value = val)
    )

    watch(propVal, (newVal) => {
      if (isFocused) return
      raw.value = newVal
      updateHtml()
    })

    watch(raw, (newVal) => {
      emit('progress', newVal)
      emit('input', newVal)
    })

    onMounted(() => {
      updateHtml()
    })

    return {
      cursorPosition,
      raw,
      html,
      editable,
      editableDiv,
      handleFocusIn,
      handleFocusOut,
      handleInput,
      saveCursorPosition,
      handleDblClick,
      handleClick,
      handleKeydown,
      handleKeyup,
      blur,
      focus,
      isFocused,
      getValue,
      setValue,
      prefillValue,
      setEditable,
      setCursorAtEnd,
      handleMouseDown,
      injectValue,
      forceValue,
      focused,
      forceMultiline,
      multiLine,
      updateHtml
    }
  }
}
