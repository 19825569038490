import axios from 'axios';
import $h from './Helpers';
import { workerBaseUrl } from "./moduleUtil.js";

const rpc = {};
const config = {
  responseType: 'json',
  responseEncoding: 'utf8',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  maxContentLength: 2000000,
  maxBodyLength: 2000000,
  decompress: true,
  // we can try to connect over websocket
  socketPath: null,
  maxRedirects: 5,
  url: '',
  method: 'post',
  baseURL: workerBaseUrl(),
  transformResponse: null,
  data: {},
  params: {},
};

// lookup for default actions
const actions = {
  create: 'post',
  delete: 'delete',
  update: 'put',
  fetch: 'get',
  call: 'post', // we use post like a fetch
};

// set the authorization headers and scope
rpc.setInterceptor = (authorization = {}) => {
  const { headers } = $h.getAuthorizationHeaders(authorization);
  config.headers = headers;
  config.data = {
    scope: authorization.scope,
    ...config.data,
  };
};

// axios wrapper
rpc.request = (url = '', payload = {}) => {
  const data = { ...payload.data || {}, ...config.data };
  return axios.request({
    ...config,
    ...payload,
    data,
    url,
  });
};

// create default request actions
Object.keys(actions)
  .forEach((method) => {
    rpc[method] = (url = '', payload = {}) => rpc.request(url, {
      ...payload,
      method: actions[method],
    });
  });

let endpointURL = null;

let websocket = null;

rpc.connect = async (messageListener) => {
  if (!endpointURL) {
    console.debug('Getting websocket URL');

    const res = await axios.request({
      ...config,
      payload: null,
      data: null,
      method: 'get',
      url: 'websocket/endpoint',
    });

    if (res.status !== 200) {
      return false;
    }

    const websocketResponse = JSON.parse(res.data);
    endpointURL = websocketResponse.response.endpoint;
    console.debug('got notification endpoint', endpointURL);
  }

  if (!websocket) {
    websocket = new WebSocket(endpointURL, 'echo-protocol');
    websocket.onmessage = messageListener;
  }

  return websocket;
};

rpc.subscribe = (payload) => {
  if (!websocket) {
    return false;
  }
  websocket.send(JSON.stringify({ subscribe: payload }));
  return true;
};

rpc.close = () => {
  if (websocket) {
    websocket.close();
    websocket = null;
  }
};

export default rpc;
