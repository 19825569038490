<template>
  <div class="product-card-container">
    <img class="product-card-image" :src="product.image" />
    <div v-if="!isAcceptingTerms" class="product-card-text">
      <div class="product-card-title">{{ product.name }}</div>
      <div class="product-card-desc">
        <p>{{ product.desc }}</p>
        <p v-if="!isModuleEnabled && !freeTrailStartUnix">Try it for free for the first 45 days!</p>
      </div>
      <div v-if="!loading">
        <div v-if="!isModuleEnabled">
          <selection-toggle
            v-model="paymentSchedule"
            class="flex w-100 justify-start mb-5"
            activeClass="w-50 white sm border border-info active px-3"
            inactiveClass="w-50 white sm text-dark px-3"
            :options="[
              {
                text: 'Pay monthly',
                value: 'month'
              },
              {
                text: 'Pay yearly',
                value: 'year'
              }
            ]"
          />
        </div>
        <div v-if="isSuperUser && !isModuleEnabled" class="product-card-free-trial">
          <span>Free trial end date:</span>
          <DatePicker
            v-model="freeTrialEndUnix"
            placeholder="End date"
            size="sm"
            :default-value="freeTrialEndUnix"
          />
        </div>
        <div class="product-card-actions">
          <div>
            <div v-if="isModuleEnabled">
              <span class="text-success product-card-status">Activated</span>
              <span v-if="inFreeTrial" class="product-card-trail-date ml-1"
                >(Free trial ends {{ freeTrialEndDateFormatted }})</span
              >
            </div>
            <div>
              <span v-if="paymentSchedule === 'month'">{{
                `$${product.monthlyCost} / month`
              }}</span>
              <span v-else>{{ `$${product.yearlyCost} / year` }}</span>
            </div>
          </div>

          <div v-if="isModuleEnabled">
            <div
              v-if="!activationLoading"
              @click="cancelProduct"
              class="product-card-cancel-button"
            >
              cancel
            </div>
            <Spinner v-else :loading="1" />
          </div>
          <btn
            v-if="!isModuleEnabled"
            @click="() => (isAcceptingTerms = true)"
            class="md"
            :class="freeTrailStartUnix && !inFreeTrial ? 'success' : 'info'"
          >
            <span v-if="freeTrailStartUnix && !inFreeTrial" class="text-white">Activate</span>
            <span v-else class="text-white"
              >{{ inFreeTrial ? 'Continue' : 'Start' }} free trial</span
            >
          </btn>
        </div>
      </div>
      <div v-if="loading" class="text-center">
        <Spinner :loading="loading" />
      </div>
    </div>
    <div v-if="isAcceptingTerms" class="product-card-terms">
      <div class="product-card-terms-checkbox">
        <checkbox
          v-model="acceptedTermsOfService"
          :boolean="true"
          :selected-value="true"
          :deselected-value="false"
          class="inline info xs"
        />
        <span class="ml-3">I agree and accept</span>
        <span
          class="product-card-terms-link"
          @click="openInNewTab('https://costcertified.com/legal/terms-and-conditions')"
          >Terms of Service</span
        >
      </div>
      <div class="product-card-terms-checkbox">
        <checkbox
          v-model="acceptedPrivacyPolicy"
          :boolean="true"
          :selected-value="true"
          :deselected-value="false"
          class="inline info xs"
        />
        <span class="ml-3">I agree and accept</span>
        <span
          class="product-card-terms-link"
          @click="openInNewTab('https://costcertified.com/legal/privacy-policy')"
          >Privacy Policy</span
        >
      </div>
      <div class="product-card-terms-actions">
        <div class="product-card-terms-back" @click="() => (isAcceptingTerms = false)">back</div>
        <btn
          v-if="!isModuleEnabled"
          @click="activateProduct"
          class="info md"
          ref="activateButton"
          :disabled="!acceptedTermsOfService || !acceptedPrivacyPolicy"
        >
          Accept & Continue
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker.vue'

export default {
  name: 'ProductCard',
  components: { DatePicker },
  data() {
    return {
      loading: 0,
      isModuleEnabled: false,
      freeTrailStartUnix: null,
      freeTrialEndUnix: null,
      freeTrialStarted: false,
      activationLoading: 0,
      paymentSchedule: 'month',
      isAcceptingTerms: false,
      acceptedTermsOfService: false,
      acceptedPrivacyPolicy: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  computed: {
    inFreeTrial() {
      if (!this.freeTrialStarted) return false
      const now = new Date()
      const currentUnix = now.getTime()
      return this.freeTrialEndUnix > currentUnix
    },
    freeTrialEndDateFormatted() {
      if (!this.freeTrialEndUnix) return ''
      const endDate = new Date(this.freeTrialEndUnix)
      const options = { day: '2-digit', year: 'numeric', month: 'short' }
      return `${endDate.toLocaleDateString('en-US', options)}`
    },
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    }
  },
  methods: {
    async getSubscriptionStatus() {
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/getProductSubscriptionStatus',
          data: {
            module_id: this.product.moduleId,
            company_id: this.companyId
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrailStartUnix = payload.free_trail_start_date

        const time = new Date().getTime()

        if (payload.free_trial_end_date) {
          this.freeTrialEndUnix = payload.free_trial_end_date * 1000
          this.freeTrialStarted = true
        } else {
          this.freeTrialEndUnix = time + this.product.freeTrialDays * 86400 * 1000
        }

        this.paymentSchedule = payload.payment_schedule
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
    },
    async cancelProduct() {
      if (this.activationLoading) return
      this.activationLoading = 1
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/cancelProductSubscription',
          data: {
            module_id: this.product.moduleId,
            module_name: this.product.name,
            company_id: this.companyId
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrailStartUnix = payload.free_trail_start_date
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
      this.activationLoading = 0
    },
    async activateProduct() {
      if (this.activationLoading || !this.acceptedTermsOfService || !this.acceptedPrivacyPolicy)
        return
      this.activationLoading = 1
      this.$refs.activateButton.addLoading()
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/activateProductSubscription',
          data: {
            module_id: this.product.moduleId,
            module_name: this.product.name,
            company_id: this.companyId,
            free_trial_end_date: Math.floor(this.freeTrialEndUnix / 1000),
            payment_schedule: this.paymentSchedule
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrailStartUnix = payload.free_trail_start_date
        this.freeTrialStarted = true
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
      this.activationLoading = 0
      this.$refs.activateButton.removeLoading()
      this.isAcceptingTerms = false
    },

    openInNewTab(url) {
      window.open(url)
    }
  },
  async mounted() {
    this.loading = 1
    await this.getSubscriptionStatus()
    this.loading = 0
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.product-card-container {
  width: 42%;
  min-width: 350px;
  height: auto;
  background-color: white;
  margin-bottom: 1em;
  border-radius: 20px;
  border: solid black 1px;

  @media (max-width: 576px) {
    width: 90%;
  }
}

.product-card-image {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
}

.product-card-text {
  padding: 1.5em;
  padding-top: 0.5em;
}

.product-card-terms {
  display: flex;
  flex-direction: column;
  bottom: 0;
  background-color: white;
  border-radius: 20px;
  padding: 1.5em;

  .product-card-terms-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5em;

    // @media (max-width: 576px) {
    //   font-size: 0.9em;
    // }

    .product-card-terms-link {
      color: #1f92fc;
      margin-left: 0.3em;
      cursor: pointer;
      &:hover {
        color: #11487a;
      }
    }
  }

  .product-card-terms-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .product-card-terms-back {
      cursor: pointer;
      &:hover {
        color: #d9534f;
      }
    }
  }
}

.product-card-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.3em;
}

.product-card-desc {
  margin-bottom: 1.5em;
}

.product-card-free-trial {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em;
}

.product-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  &.inactive {
    justify-content: flex-end;
  }

  .product-card-status {
    font-size: 1.3em;
    font-weight: bold;
  }

  .product-card-trail-date {
    font-size: 0.8em;
  }

  .product-card-cancel-button {
    cursor: pointer;

    &:hover {
      color: #d9534f;
    }
  }
}
</style>
