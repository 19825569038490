<template>
  <Button
    :class="[
      {
        'gap-2': size !== 'sm' && size !== 'xs',
        'gap-1': size === 'sm' || size === 'xs'
      },
      'button--container relative whitespace-nowrap shrink-0'
    ]"
    :size="size"
    :severity="severity"
    :pt="pt"
    :ptOptions="{
      mergeProps: true
    }"
    :btn-component="id"
    :loading="loadingLocal ? true : false"
    @mouseover="(e) => $emit('mouseover', e)"
    @mouseout="(e) => $emit('mouseout', e)"
    tabindex
    :badge="badge || ''"
    @click="triggerClick"
    :disabled="disabled || loadingLocal"
    :rounded="rounded"
    :type="type"
    v-tooltip="tooltip"
  >
    <div
      v-if="$slots.icon"
      :class="{
        'mr-1': !hideText
      }"
    >
      <slot name="icon"></slot>
    </div>
    <template v-if="!hideText">
      <slot>
        {{ text }}
      </slot>
    </template>
    <div
      v-if="$slots.iconRight"
      :class="{
        'ml-4': !hideText
      }"
    >
      <slot name="iconRight"></slot>
    </div>
    <div class="absolute flex items-center justify-center inset-0 pointer-events-none">
      <Loader :loading="loadingLocal" />
    </div>
    <Hotkey
      v-if="hotkey && !isMobile"
      :hotkey="hotkey"
      @hotkey-pressed="triggerClick"
      class="scale-75 -mt-1 -mb-1 -mr-3"
    />
  </Button>
</template>

<script>
import { useDeviceStore } from '@/stores/device'
import Loader from '@/components/ui/Loader.vue'
import { mapState } from 'pinia'
/**
 * EMITS
 * -loading(loadingCount)
 * -click(clickEvent)
 * -tap(tapEvent)
 *
 * MODIFIER CLASSES
 * -.square, or .btn
 * -.more
 * -.round, or .add-btn
 *
 * Severity
 * -.primary-black
 * -.primary-yellow
 * -.primary-yellow-login
 * -.secondary
 * -.tertiary
 * -.dark
 * -.light
 * -.info
 * -.success
 * -.danger
 * -.warning
 *
 * Sizes
 * -.sm
 * -.md
 * -.lg
 * -.xl
 */
export default {
  name: 'Btn',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  components: { Loader },
  emits: ['mouseover', 'mouseout', 'loading', 'triggered', 'click', 'tap'],
  data() {
    return {
      loadingLocal: this.loading,
      progressLocal: this.progress,
      height: 0,
      width: 0,
      id: ''
    }
  },
  computed: {
    touch() {
      return this.static || this.deviceStore.isTouch
    },
    disableButton() {
      return (
        this.disabled ||
        this.loadingLocal > 0 ||
        (this.progressLocal >= 0.01 && this.progressLocal < 1)
      )
    },
    ...mapState(useDeviceStore, ['isMobile'])
  },
  watch: {
    loading(val) {
      this.loadingLocal = val
    },
    loadingLocal(val) {
      this.$emit('loading', val)
    }
  },
  methods: {
    triggerClick(e) {
      if (this.disabled || this.loadingLocal) return
      this.click(e).catch((err) => {
        if (err.userMessage) {
          this.$store.dispatch('alert', {
            message: err.userMessage,
            error: true
          })
        }

        this.endLoading()
        throw err
      })
    },
    async submit() {
      return this.click()
    },
    async click(e) {
      if (this.stop && !this.static && e) e.stopPropagation()
      if (this.prevent && !this.static && e) e.preventDefault()

      if (this.disabled) {
        return false
      }
      this.$emit('triggered')

      this.addLoading()

      // Must remain setTimeout,
      //  insteead of setImmediate or $nextTick, because of a problem
      //  forcing clicks on file inputs
      const progress = (prog) => {
        this.progressLocal = prog
      }
      if (this.to) {
        this.$store.dispatch('to', this.to)
      } else if (this.action) {
        try {
          await this.action({ progress })
        } finally {
          this.removeLoading()
        }
      } else {
        setTimeout(() => {
          this.$emit('click', { ...e, button: this }, this)
          this.$emit('tap', { ...e, button: this }, this)
          setTimeout(() => this.removeLoading(), 100)
        }, 15)
      }

      if (this.prevent && e) return e.preventDefault()

      return true
    },
    removeLoading() {
      this.$nextTick(() => {
        this.loadingLocal = Math.max(this.loadingLocal - 1, 0)

        if (this.loadingLocal === 0) {
          this.endProgress()
        }
      })
    },
    addLoading() {
      this.loadingLocal += 1
    },
    endLoading() {
      this.loadingLocal = 0
      this.endProgress()
    },
    endProgress() {
      this.progressLocal = 0.79
      setTimeout(() => {
        this.progressLocal = 1
        setTimeout(() => {
          this.loadingLocal = Math.max(this.loadingLocal - 1, 0)
          this.progressLocal = 0
        }, 250)
      }, 250)
    },
    setProgress(progress) {
      this.progressLocal = progress
    }
  },
  mounted() {
    /* const d = this.$el.getBoundingClientRect();
      const styles = window.getComputedStyle(this.$el, null);
      this.width = d.width
        - parseInt(styles.getPropertyValue('padding-right'), 0)
        - parseInt(styles.getPropertyValue('padding-left'), 0)
        - parseInt(styles.getPropertyValue('border-right-width'), 0)
        - parseInt(styles.getPropertyValue('border-left-width'), 0);
      this.height = d.height
        - parseInt(styles.getPropertyValue('padding-top'), 0)
        - parseInt(styles.getPropertyValue('padding-bottom'), 0)
        - parseInt(styles.getPropertyValue('border-bottom-width'), 0)
        - parseInt(styles.getPropertyValue('border-top-width'), 0); */
    this.id = c.idFromText(String(this.$el.innerText || ''))
  },
  beforeUnmount() {
    this.loadingLocal = null
    this.progressLocal = null
    this.height = null
    this.width = null
  },
  props: {
    tooltip: {
      default: false
    },
    action: {
      default: null
    },
    stop: {
      default: true
    },
    prevent: {
      default: true
    },
    fileInput: {
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default() {
        return ''
      },
      required: false
    },
    icon: {
      type: String,
      default() {
        return ''
      },
      required: false
    },
    loadingColor: {
      type: String,
      default: '#ffffff'
    },
    badge: {
      default: false
    },
    loading: {
      default: 0
    },
    progress: {
      default: 0
    },
    to: {
      type: String
    },
    static: {
      type: Boolean,
      default: false
    },
    hideText: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    asField: {
      type: Boolean,
      default: false
    },
    design: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'none'
    },
    rounded: {
      type: Boolean
    },
    severity: {
      type: String,
      default: 'primary'
    },
    hotkey: {
      type: String,
      default: null
    },
    pt: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>
