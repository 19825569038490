<template>
  <Skeleton class="min-h-8 w-full max-w-40 rounded" v-if="name === '' && loading" />
  <span v-else>{{ truncated }}</span>
</template>

<script>
import Skeleton from 'primevue/skeleton'
export default {
  name: 'Name',

  components: {
    Skeleton
  },

  data() {
    return {
      name: '',
      loading: true
    }
  },

  computed: {
    processedId() {
      if (this.type === 'user') {
        return String(this.id || '').replace(/[^0-9]/g, '')
      }

      return this.id
    },
    truncated() {
      if (this.truncate && this.name.length > this.truncate) {
        return `${this.name.slice(0, this.truncate)}...`
      }

      if (this.name === 'Name not available') return this.default

      return this.name
    },
    store() {
      return c.titleCase(this.type)
    },

    key() {
      return `names.${this.type}.${this.processedId}`
    }
  },

  methods: {
    async getNames() {
      return this.$store.dispatch(`${this.store}/getNames`)
    },

    async getName() {
      return this.$store.dispatch(`${this.store}/getName`, {
        id: this.processedId,
        getIndividual: this.getIndividual
      })
    }
  },

  async mounted() {
    let name = this.default || c.getCacheItem(this.key, this.type, this.$store.state.session)
    // const hasFetched = c.getStorage(`${this.type}.namesFetched`);

    if (!name && this.processedId) {
      await this.getName()
      name = c.getCacheItem(this.key, this.type, this.$store.state.session)
    }

    this.loading = false

    this.name = name || 'Name not available'
  },

  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      required: true
    },
    default: {
      type: String,
      required: false,
      default: null
    },
    truncate: {
      default: false
    },
    getIndividual: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped></style>
