// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'role_permission',

  possibleStatuses: ['a', 'h'],
  
  skipAudit: true,

  fields: {
    role_permission_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'role',
    },
    permission_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'permission',
    },
    role_permission_self: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_permission_others: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    role_permission_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    role_permission_is_override: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    role_permission_is_custom: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    role_permission_is_generic: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
  },
};
