/**
 * A caching service for approvals
 */

import { reactive, toRefs } from 'vue'
import useApproval from '@/components/composables/Approval'
import {
  getApprovalsByItemAndApprover,
  getApprovalsByApprover
} from '@/apollo-client/requests/approval'

export const initialState = {
  approvals: {}
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const { session } = useApproval()

  /**
   * Fetch the approval from the BE which is appsync
   * @param {String} itemId
   * @returns
   */
  const fetchApproval = async (itemId) => {
    const userId = session.value.userId
    const approvals = await getApprovalsByItemAndApprover({
      itemId,
      userId
    })
    if (approvals && approvals.length > 0) {
      const approval = approvals[0]
      return approval
    }
    return null
  }

  const fetchApprovalByApprover = async (quoteId) => {
    const userId = session.value.userId
    const approvals = await getApprovalsByApprover({
      userId,
      quoteId: quoteId
    })
    context.approvals = approvals?.reduce((acc, approval) => {
      acc[approval.id] = approval
      return acc
    }, {})
  }

  /**
   * Search through the cached approvals based on an item id
   * @param {String} itemId
   * @returns
   */
  const findApprovalByItemId = (itemId) => {
    const id = Object.keys(context.approvals || {}).find((approvalId) => {
      const approval = context.approvals?.[approvalId]
      return approval.itemId === itemId
    })

    return context.approvals?.[id]
  }

  /**
   * Clear the approval from the cache
   * @param {String} itemId
   * @returns
   */
  const clearApproval = (itemId) => {
    const approvalId = findApprovalByItemId(itemId)
    if (approvalId) delete context.approvals?.[approvalId]
    return approvalId
  }

  /**
   * Clear the approval from the cache and refetch it
   * @param {String} itemId
   * @returns
   */
  const resetApproval = (itemId) => {
    clearApproval(itemId)
    return getApproval(itemId)
  }

  /**
   * Get the approval either from cache or BE
   * @param {String} itemId
   * @param {Boolean} fetch
   * @returns
   */
  const getApproval = async (itemId, fetch = true) => {
    // check to see if an approval already exists
    const approvalId = findApprovalByItemId(itemId)
    // no result and we don't want to fetch the approval
    if ((!approvalId || !context.approvals?.[approvalId]) && !fetch) return null
    // no result so lets try to fetch the approval
    if ((!approvalId || !context.approvals?.[approvalId]) && fetch) {
      const approval = await fetchApproval(itemId)
      return approval
    }
    // found the result so return it
    return context.approvals?.[approvalId]
  }

  const upsertApproval = (approval) => {
    if (!approval || !approval.id) return
    context.approvals = {
      ...(context.approvals || {}), // Preserve existing approvals
      [approval.id]: approval // Upsert the new or updated approval
    }
  }

  return {
    ...toRefs(context),
    fetchApproval,
    fetchApprovalByApprover,
    clearApproval,
    getApproval,
    resetApproval,
    findApprovalByItemId,
    upsertApproval
  }
}
