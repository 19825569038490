export default {
  methods: {
    async fetchPaymentMethods(showAlert = true) {
      try {
        const response = await this.$store.dispatch('ajax', {
          path: '/Payment_Method/getPaymentMethods',
          data: {
            invoice_id: this.invoice?.invoice_id ?? null
          }
        })

        if (!response || !response.payload) throw new Error()
        return response.payload
      } catch (e) {
        if (showAlert) {
          this.$store.dispatch('alert', {
            message: e || 'Could not fetch payment methods, please try again.',
            error: true
          })
        }
        return null
      }
    },

    /**
     * Get payment details for the invoice
     */
    async fetchPaymentDetails() {
      try {
        const response = await this.$store.dispatch('ajax', {
          path: '/Payfac/getPaymentDetails',
          data: {
            invoice_id: this.invoice.invoice_id
          }
        })

        if (!response || response.error || !response.payload) throw new Error()
        const { payload } = response
        const {
          creditCardFeeOwed,
          specialtyCreditCardFeeOwed,
          otherFeeOwed,
          specialtyCards,
          linkToken,
          variantFees
        } = payload
        this.allFees = {
          card: creditCardFeeOwed,
          standardCards: creditCardFeeOwed,
          specialtyCards: specialtyCreditCardFeeOwed,
          ach: otherFeeOwed
        }
        this.specialtyCards = specialtyCards
        this.token = linkToken
        this.variantFees = variantFees
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not fetch payment details. Please refresh.',
          error: true
        })
      }
    },

    async fetchPaymentSettings(invoiceId) {
      try {
        const response = await this.$store.dispatch('ajax', {
          path: '/invoice/getPaymentSettings',
          data: {
            invoice_id: invoiceId
          }
        })
        if (!response || response.error || !response.payload || response.payload.error) {
          throw new Error()
        }

        this.doesPayeeCoverBolsterFee = Boolean(response.payload.doesPayeeCoverBolsterFee)
        this.doesPayeeCoverCreditCardFee = Boolean(response.payload.doesPayeeCoverCreditCardFee)
        return true
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not fetch payment settings. Please refresh.',
          error: true
        })
        return false
      }
    },

    /**
     * Pay Invoice
     * @param data
     * @returns {Promise<any>}
     */
    makePaymentRequest(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/invoice/payInvoice',
        data
      })
    },

    // TODO: Move these to invoice controller
    payManually(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/gateway/payManually',
        data
      })
    },
    reversePayManually(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/gateway/reversePayManually',
        data
      })
    }
  }
}
