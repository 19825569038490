<template>
  <ListItem :stacked="true" :seamless="true">
    <div class="flex gap-2 w-full">
      <div class="flex items-center w-full">
        <Field
          type="text"
          placeholder="Label of option"
          :pt="{ root: '!w-full' }"
          :validate="{ required: true }"
          v-model="item.custom_field_option_label"
        />
      </div>
      <div class="flex items-center w-full">
        <Field
          type="text"
          placeholder="Value of option"
          :pt="{ root: '!w-full' }"
          class="w-full"
          :validate="{ required: true }"
          v-model="item.custom_field_option_value"
        />
      </div>
      <div class="flex items-center justify-end col-span-1">
        <Btn v-tooltip="'Remove option'" :link="true" @click="() => removeOption(index)">
          <template #icon>
            <font-awesome-icon :icon="['far', 'times']" />
          </template>
        </Btn>
      </div>
    </div>
  </ListItem>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'
import ListItem from './ListItem.vue'
import useCustomField from '@/components/forms/CustomField'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
    required: true
  },
  index: {
    type: Number
  },
  field: {
    type: Object,
    required: true
  }
})

const { removeOption } = useCustomField(props.field)

const { item } = toRefs(props)
</script>
