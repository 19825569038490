export const usePushNotifications = () => {
  // ======== Methods ======== //
  const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
  const subscribeToNotifications = async () => {
    const serviceWorkerRegister = await navigator.serviceWorker.register(
      '/assets/workers/notification.worker.js'
    )
    while (!serviceWorkerRegister.active) {
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    return serviceWorkerRegister.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY)
    })
  }
  const initPushNotifications = () => {
    return subscribeToNotifications()
  }
  // ====================== //

  return {
    initPushNotifications
  }
}
