<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :scrollable="false"
    :clickAway="false"
    :expandable="false"
    :hasSidePanel="true"
  >
    <template #header>
      <template v-if="typeName === 'Project'">
        <font-awesome-icon icon="house" />
        Project
      </template>
      <template v-else-if="typeName === 'Proposal'">
        <font-awesome-icon icon="file" />
        Proposal
      </template>
      <fade>
        <btn
          v-if="selected && original"
          @click="() => $store.dispatch('open', { type: 'client', id: original.client_id })"
          class="btn round"
        >
          <template #icon>
            <font-awesome-icon icon="user" />
          </template>
          <span>
            <span v-if="original.oClient.company_name">{{ original.oClient.company_name }} • </span
            >{{ original.oClient.client_name }}
          </span>
        </btn>
      </fade>
    </template>
    <template #body>
      <quote-body ref="body" :forceFull="true" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import QuoteBody from '../bodies/Quote.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'

export default {
  name: 'ModalQuote',
  mixins: [ObjectModalMixin],
  components: { QuoteBody },
  computed: {
    typeName() {
      if (this.original) {
        return /k|f|g/.test(this.original.quote_status || '') ? 'Project' : 'Proposal'
      }
      return ''
    }
  },
  props: {
    type: {
      default: 'quote'
    }
  }
}
</script>
