<template>
  <div :class="classes">
    <template v-if="url">
      <img
        :src="url"
        @click="change"
        class="my-4"
        :class="[
          size === 'lg' ? 'max-w-80' : size === 'md' ? 'max-w-40' : 'max-w-20',
          rounded ? 'rounded-full' : ''
        ]"
      />
      <slot name="buttons" :change="change" :remove="remove">
        <div class="flex flex-row gap-x-2">
          <btn size="lg" severity="tertiary" @click="change">
            <font-awesome-icon icon="folder-open" />
          </btn>
          <btn size="lg" severity="secondary" @click="remove">
            <font-awesome-icon icon="trash" />
          </btn>
        </div>
      </slot>
    </template>

    <div v-else class="mt-4 w-full">
      <slot name="upload" :change="change">
        <btn size="lg" severity="tertiary" @click="change">
          <font-awesome-icon :icon="['fas', 'image-polaroid-user']" />
          <p>None, add one?</p>
        </btn>
      </slot>
    </div>

    <file-list v-show="false" ref="fileList" v-model="list" />
  </div>
</template>

<script>
export default {
  name: 'ImageSelector',
  props: {
    /**
     * Must be the ID of a file
     */
    value: {},
    size: {
      default: 'lg'
    },
    rounded: {
      default: false
    },
    classes: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  data() {
    return {
      url: null
    }
  },
  created() {
    this.setUrl()
  },
  watch: {
    value() {
      this.setUrl()
    }
  },
  computed: {
    file() {
      return this.value
        ? {
            type: 'file',
            file_id: this.value,
            file_name: 'Selected file'
          }
        : null
    },
    list: {
      get() {
        return this.file ? [this.file] : []
      },
      set(fl) {
        const list = c.makeArray(fl).reverse()
        if (list.length && list[0].file_id) {
          this.$emit('input', list[0].file_id)
        } else {
          this.$emit('input', null)
        }
      }
    }
  },
  methods: {
    async setUrl() {
      if (!this.value) {
        this.url = null
        return false
      }
      const { link: url } = await this.$store.dispatch('link', {
        path: `file/view/${this.value}`
      })
      this.url = url
      return true
    },
    change() {
      this.$refs.fileList.triggerSelector()
    },
    remove() {
      this.list = []
    }
  }
}
</script>

<!-- <style rel="stylesheet/scss" lang="scss" scoped>


  .image-selector--container {
    max-width: 20em;
    max-height: 20em;
    img {
      max-width: 20em;
      max-height: 20em;
      cursor: pointer;
      padding: 1em;
      max-width: 100%;
      border-radius: 5px;
      border: 3px solid $cool-gray-200;
      box-shadow: $shadow;
      margin: 0em 0em 1em 0em;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &.sm {
      max-width: 10em;
      max-height: 5em;
      img {
        max-width: 10em;
        max-height: 5em;
        padding: 0;
        max-width: 100%;
        border-radius: 5px;
        border: 3px solid $cool-gray-200;
        box-shadow: $shadow;
        margin: 0em 0em 0.5em 0em;
      }
    }
  }

</style> -->
