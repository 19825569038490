<template>
  <div>
    <div class="w-full mt-4">
      <table
        class="table-auto mt-1 border-collapse border border-surface-200 bg-flame-white w-full"
      >
        <thead class="thead-light">
          <tr>
            <th class="border border-surface-200 bg-surface-100 px-4 py-2" scope="col">
              Standard cards (Visa, Mastercard)
            </th>
            <th class="border border-surface-200 bg-surface-100 px-4 py-2" scope="col">
              Specialty cards (Amex, Discover)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-surface-200 px-4 py-2">
              <p>2.99%</p>
              <small><i>per transaction</i></small>
            </td>
            <td class="border border-surface-200 px-4 py-2">
              <p>3.95%</p>
              <small><i>per transaction</i></small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="countryCode === 'us'" class="w-full mt-8">
      <table
        class="table-auto mt-1 border-collapse border border-surface-200 bg-flame-white w-full"
      >
        <thead class="thead-light">
          <tr>
            <th class="border border-surface-200 bg-surface-100 px-4 py-2" scope="col">
              <span class="d-flex align-items-center"> Bank Transfer </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-surface-200 px-4 py-2">
              <p>1% up to $15</p>
              <small><i>minimum $2 per transaction</i></small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showSettlements" class="w-full mt-8 mb-2">
      <table
        class="table-auto mt-1 border-collapse border border-surface-200 bg-flame-white w-full"
      >
        <thead class="thead-light">
          <tr>
            <th class="border border-surface-200 bg-surface-100 px-4 py-2" scope="col">
              <span class="d-flex align-items-center">
                <p>Regular</p>
              </span>
              <span class="d-flex align-items-center">
                <small
                  ><i>2 day settlement, {{ payoutInterval }} day payout</i></small
                >
              </span>
            </th>
            <th class="border border-surface-200 bg-surface-100" scope="col">
              <span class="d-flex align-items-center">
                <p>Fast</p>
              </span>
              <span class="d-flex align-items-center">
                <small
                  ><i>1 day settlement, {{ payoutInterval }} day payout</i></small
                >
              </span>
            </th>
            <th
              class="border border-surface-200 bg-surface-100 px-4 py-2"
              v-if="countryCode === 'us'"
              scope="col"
            >
              <span class="d-flex align-items-center">
                <p>Faster</p>
              </span>
              <span class="d-flex align-items-center">
                <small
                  ><i>same day settlement, {{ payoutInterval }} day payout</i></small
                >
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-surface-200 px-4 py-2">
              <p>No fee</p>
              <small><i>per transaction</i></small>
            </td>
            <td class="border border-surface-200 px-4 py-2">
              <p>0.5%</p>
              <small><i>per transaction</i></small>
            </td>
            <td class="border border-surface-200 px-4 py-2" v-if="countryCode === 'us'">
              <p>1%</p>
              <small><i>per transaction</i></small>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <small class="text-surface-600">
          <i>
            Settlement and payout times are in business days. Payouts are based on bank clearing
            schedules, including working days and non-holidays.
          </i>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionFeeTable',
  props: {
    countryCode: {
      type: String,
      default: 'us'
    },
    showSettlements: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    payoutInterval() {
      return this.countryCode === 'us' ? 1 : 2
    },
    daysPlural() {
      return this.payoutInterval === 1 ? 'day' : 'days'
    }
  }
}
</script>
