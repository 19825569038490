// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'reviewer',

  skipAudit: true,

  fields: {
    reviewer_id: {
      type: 'string',
      filter: true,
      format: false,
      save: false,
      mapTo: false,
    },
    client_id: {
      type: 'string',
      filter: true,
      format: false,
      save: false,
      mapTo: false,
    },
    quote_id: {
      type: 'string',
      filter: true,
      format: false,
      save: false,
      mapTo: false,
    },
    user_id: {
      type: 'string',
      filter: true,
      format: false,
      save: false,
      mapTo: false,
    },
    asPermissions: {
      type: 'array',
      trackChanges: false,
      save: false,
    },
  },
};
