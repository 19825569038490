<template>
  <div
    class="top-0 h-screen shrink-0 flex-col items-stretch justify-start min-w-[80vw] xl:min-w-0 w-fit flex fixed xl:sticky z-[1000000]"
    :class="{
      'min-w-[470px]': showSidebarLocal,
      'max-w-0 hidden': !showSidebarLocal
    }"
  >
    <div class="quote-presentation-toc--wrapper w-fit h-full static" v-show="showSidebarLocal">
      <div
        class="absolute z-[100000] xl:sticky top-0 left-0 h-full w-[80vw] md:w-[400px] bg-flame-white flex flex-col border-r-2 border-r-surface-200 box-border shadow-lg xl:shadow-none"
        :class="{
          'top-[66px] xl:top-0': $route.name === 'Project preview',
          'top-[72px] xl:top-0': $route.query?.tab === 'Preview',
          'top-0': $route.path.includes('presentation')
        }"
      >
        <!--   Header section  -->
        <div class="w-full flex-none">
          <!--    Search bar    -->
          <div class="w-full flex items-center p-4 justify-center bg-flame-white gap-4">
            <Btn
              v-if="!isQuotingEngine"
              severity="tertiary-borderless"
              tooltip="Show PDF"
              class="grow-0 shrink-0 !size-10"
              size="lg"
              @click="$emit('update-show-summary', 1)"
            >
              <font-awesome-icon icon="file-pdf" size="lg" />
            </Btn>
            <IconField iconPosition="left" class="w-full">
              <InputIcon>
                <font-awesome-icon :icon="['far', 'search']" class="text-pitch-black text-lg" />
              </InputIcon>
              <InputText
                v-model="searchQuery"
                ref="searchInput"
                :placeholder="'Search'"
                class="h-full text-base font-light"
                :ptOptions="{ mergeProps: true }"
                :pt="{
                  root: '!h-10 !text-cool-gray-700 !hover:border-pitch-black !hover:bg-pitch-black !hover:text-level-yellow !rounded-md'
                }"
              />
            </IconField>
            <Btn
              v-if="!smallFormat"
              tooltip="Hide sidebar"
              class="grow-0 shrink-0 !size-10"
              size="lg"
              severity="primary"
              @click="toggleSidebar"
            >
              <font-awesome-icon icon="arrow-left" size="lg" />
            </Btn>
          </div>
        </div>
        <!-- Content section -->
        <div
          class="h-full max-h-fit"
          :class="{
            'top-36': $route.query?.tab === 'Preview' || $route.name === 'Project preview',
            'top-20': $route.path.includes('presentation')
          }"
        >
          <div
            class="w-[80vw] md:w-[400px] py-4 px-3 md:px-6 overflow-y-auto h-full"
            :class="{
              'pb-[180px]': $route.query?.tab === 'Preview',
              'pb-[170px]': $route.name === 'Project preview',
              'pb-[110px]': $route.path.includes('presentation')
            }"
          >
            <RecursiveTOCItem
              v-for="(ref, index) in filteredGroups"
              :key="ref"
              :ref-id="ref"
              :parent-ref-id="ref"
              :normalized="normalized"
              :disabled="disabled"
              :active-item="activeItem"
              :open-sections="openSections"
              :index="index"
              :search-query="searchQuery"
              :itemIcons="itemIcons"
              :presentationSettings="presentationSettings"
              @update:openSections="updateOpenSections"
              @item-click="scrollTo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield'
import RecursiveTOCItem from '@/components/quote/presentation/RecursiveTOCItem.vue'
import GridMixin from '@/components/ui/Grid/GridMixin'
import { compute } from 'compute-scroll-into-view'
import useSelections from '@/components/composables/UseSelections'

export default {
  name: 'QuotePresentationTOC',
  mixins: [GridMixin],
  emits: ['toggle-toc'],
  props: {
    normalized: {
      type: Object
    },
    groups: {
      type: Object
    },
    disabled: {
      type: Array
    },
    isInQuoteEditor: {
      type: Boolean
    },
    isQuotingEngine: {
      default: false
    },
    showSidebar: {
      default: true
    },
    presentationSettings: {
      type: Object
    }
  },
  data() {
    return {
      tocOpen: true,
      scroller: null,
      scrollCallback: null,
      searchQuery: '',
      activeItem: null,
      openSections: [],
      itemIcons: {
        Optional: {
          icon: 'fas fa-circle-half-stroke'
        },
        Selections: {
          icon: 'swatchbook'
        }
      },
      showSidebarLocal: this.showSidebar
    }
  },
  created() {
    // Listen for the event from Selections
    eventBus.$on('toggle-section-from-selections', ({ ref, isOpen }) => {
      this.$set(this.openSections, ref, isOpen)
    })
  },
  components: { IconField, InputText, InputIcon, RecursiveTOCItem },
  computed: {
    selections() {
      return useSelections(this.normalized)
    },
    filteredGroups() {
      const query = this.searchQuery.toLowerCase()
      const groups = this.groups.filter((ref) => this.selections.shouldShowItem(ref))

      const filterRecursive = (subRef, accumulator) => {
        const subRefName = this.getNormalizedName(subRef)?.toLowerCase()

        if (subRefName?.includes(query)) {
          accumulator.push(subRef)
        }

        if (this.normalized[subRef]?.aoChildren?.length > 0) {
          this.normalized[subRef].aoChildren.forEach((childRef) => {
            filterRecursive(childRef, accumulator)
          })
        }

        return accumulator
      }

      const filtered = []

      groups.forEach((subRef) => {
        const matches = filterRecursive(subRef, [])
        if (matches.length > 0) {
          filtered.push(subRef)
        }
      })

      return query ? filtered : groups
    }
  },
  methods: {
    toggleSection(ref) {
      if (this.openSections.includes(ref)) {
        this.openSections = this.openSections.filter((section) => section !== ref)
      } else if (this.normalized[ref].type === 'assembly') {
        this.openSections.push(ref)
      }

      eventBus.$emit('toggle-section-from-toc', { ref, isOpen: this.isSectionOpen(ref) })
    },
    isSectionOpen(ref) {
      return this.openSections.includes(ref)
    },
    updateOpenSections(newOpenSections) {
      this.openSections = newOpenSections
    },
    scrollTo(ref) {
      const element = document.querySelector(`#${ref}`)
      const isAssembly = this.normalized[ref].item_type === 'assembly'
      const isInteractive = this.itemType(ref)
      const shouldScroll = !isAssembly || (isAssembly && isInteractive)

      if (shouldScroll && element) {
        this.activeItem = ref

        if (!this.smallFormat) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
          const actions = compute(element, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
            skipOverflowHiddenElements: true
          })

          actions.forEach(({ el, top, left }) => {
            el.scrollTop = top
            el.scrollLeft = left
          })
        }

        const allItems = document.querySelectorAll('.border-2')
        allItems.forEach((item) => {
          item.classList.remove('border-2')
          item.classList.remove('!border-pitch-black')
        })

        setTimeout(() => {
          element.classList.add('border-2')
          element.classList.add('!border-pitch-black')
        }, 300)
      }
    },
    getNormalizedName(ref) {
      return (
        this.normalized[ref]?.item_name ||
        this.normalized[ref]?.assembly_name ||
        this.normalized[ref]?.cost_type_name
      )
    },
    itemType(ref) {
      if (this.normalized[ref].cost_item_is_optional || this.normalized[ref].assembly_is_optional)
        return 'Optional'
      if (
        (this.normalized[ref].aoAddons && this.normalized[ref].aoAddons.length) ||
        this.normalized[ref].cost_type_is_variation_parent
      )
        return 'Selections'
      return null
    },
    toggleSidebar() {
      this.showSidebarLocal = !this.showSidebarLocal
      this.$emit('toggle-toc', this.showSidebarLocal)
    }
  },
  async mounted() {
    // Initialize only the first-level sections as open
    this.openSections = Object.keys(this.normalized).filter((ref) => {
      const item = this.normalized[ref]
      return item.type === 'assembly' && item.asAssemblyPath.length < 2
    })

    await this.$nextTick()
    this.scroller = document.querySelector('.scroll-container--container')
    const anchors = document.getElementsByClassName('toc-anchor')
    const content = document.querySelector('.quote-pres--content')
    const toc = document.querySelector('.quote-presentation--selections-wrapper')
    this.scrollCallback = () => {
      anchors.forEach((anchor) => {
        const distanceToTop = anchor.getBoundingClientRect().top
        const contentTop = content.getBoundingClientRect().top
        const el = document.getElementById(`title-${anchor.id}`)
        const { parent } = el.dataset
        const container = document.querySelector(`#container-${parent}`)
        const classes = el.classList
        // mark toc section as active
        if (distanceToTop < 1000 && distanceToTop > 0) {
          classes.add('active')
          // open if the section is triggered
          if (!container.classList.contains('open')) {
            container.classList.add('open')
          }
        } else {
          classes.remove('active')
        }

        // close if past the section
        if (
          distanceToTop < -1 * (anchor.offsetHeight + 200) &&
          container.classList.contains('open')
        ) {
          container.classList.remove('open')
        }

        if (contentTop < 0) {
          toc.classList.remove('trigger')
        }
      })
    }
    await this.$nextTick()
  },
  beforeUnmount() {
    eventBus.$off('toggle-section-from-selections')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-presentation-toc--wrapper {
  position: relative;
  .quote-presentation-toc--item {
    &:not(.open) {
      .quote-presentation-toc--item--sub {
        display: none;
      }
    }
    > .quote-presentation-toc--title {
      color: $cool-gray-700;
      font-size: 0.9em;
      font-weight: 500;
      display: flex;
    }
    .quote-presentation-toc--item--sub {
      > .quote-presentation-toc--title {
        color: $cool-gray-600;
        font-size: 0.8em;
        display: flex;
      }

      &.disabled {
        > .quote-presentation-toc--title {
          color: $cool-gray-400 !important;
        }
      }
    }
  }
}
</style>
