<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :scrollable="true"
    :clickAway="false"
    :expandable="false"
    :includeGutter="true"
    :hasSidePanel="true"
    :has-header-border="true"
  >
    <template #header>
      <font-awesome-icon icon="file-invoice-dollar" />&nbsp; Invoice #
      {{ modal.objects[0].invoice_id }}
    </template>
    <template #body>
      <invoice-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import InvoiceBody from '../bodies/Invoice.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalInvoice',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: { InvoiceBody },
  props: {
    type: {
      default: 'invoice'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
