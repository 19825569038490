<template>
  <div class="w-full">
    <!-- assembly name with path and checkbox -->
    <div class="flex border-t border-cool-gray items-center itemized-assembly--title">
      <!-- assembly checkbox -->
      <checkbox
        v-if="!readonly"
        @input="() => eventBus.$emit('itemized-item-select-group', item)"
        :disabled="isInvoiced"
        disabledMsg="You have already added this item to an invoice"
        :value="isSelected"
      ></checkbox>

      <!-- assembly name with path -->
      <div
        class="itemized-assembly--expand flex flex-col w-full py-1 ml-2"
        @click="() => (toggle = !toggle)"
      >
        <span class="flex itemized-assembly--path">
          <span :key="index" v-for="(path, index) in item.asAssemblyPath">
            <small
              v-if="item.asAssemblyPath.length > 1 && item.asAssemblyPath.length - 1 !== index"
              class="text-cool-gray-600"
            >
              {{ $f.truncate(path, 45) }}
              <font-awesome-icon
                v-show="index !== item.asAssemblyPath.length - 2"
                class="mx-1"
                icon="arrow-right"
              />
            </small>
          </span>
        </span>
        <span class="flex items-center">
          {{ $f.truncate(item.item_name || item.assembly_name, 70) }}
          <small v-if="!readonly" class="itemized-assembly--count text-muted ml-2">
            ({{ itemCount }}/{{ totalItemCount }})
          </small>
          <font-awesome-icon
            :icon="toggle ? 'chevron-down' : 'chevron-right'"
            class="chevron-icon close-section ml-3"
          />
        </span>
      </div>
    </div>

    <!-- assembly children -->
    <div
      v-show="toggle"
      v-if="item && item.aoChildren"
      :class="['itemized-children', readonly ? 'readonly' : '']"
    >
      <ItemizedListItem
        v-for="ref in item.aoChildren"
        :key="ref"
        :parentRefId="parentRefId"
        :item="normalized[ref] || {}"
        :normalized="normalized"
        :selected="selected"
        :expanded="expanded"
        :invoiced="invoiced"
        :readonly="readonly"
        :refId="ref"
      />
    </div>
  </div>
</template>

<script>
import ItemizedListMixin from './ItemizedListMixin'
import eventBus from '../../../eventBus'
import ItemizedListItem from './ItemizedListItem.vue'

export default {
  name: 'ItemizedListAssembly',
  mixins: [ItemizedListMixin],
  components: {
    ItemizedListItem
  },
  props: {},
  data() {
    return {
      objectLocal: this.item,
      toggle: !!this.expanded
    }
  },
  computed: {
    eventBus() {
      return eventBus
    },
    itemCount() {
      // Get normalized values from this.selected (which contains item_ids)
      const selectedNormalized = Object.keys(this.normalized).reduce((acc, ref) => {
        if (this.selected.includes(this.normalized[ref].item_id)) acc.push(ref)
        return acc
      }, [])
      // Get array of items that are selected and children of this assembly
      const intersection = this.item.aoChildren.filter((i) => selectedNormalized.includes(i))
      return intersection.length || 0
    },
    totalItemCount() {
      return this.item.aoChildren.length || 0
    }
  },
  itemCount() {
    // Get normalized values from this.selected (which contains item_ids)
    const selectedNormalized = Object.keys(this.normalized).reduce((acc, ref) => {
      if (this.selected.includes(this.normalized[ref].item_id)) acc.push(ref)
      return acc
    }, [])
    // Get array of items that are selected and children of this assembly
    const intersection = this.item.aoChildren.filter((i) => selectedNormalized.includes(i))
    return intersection.length || 0
  }
}
</script>

<style lang="scss" scoped>
.itemized-assembly--title {
  cursor: pointer;
  min-height: 50px;
}

.itemized-assembly--path {
  font-size: 0.85em;
}

.chevron-icon {
  margin-top: -0.05rem;
  font-size: 0.8em;
}

.itemized-assembly--count {
  font-size: 0.7em;
}

.itemized-children {
  padding-left: 0.8em;
  @media (max-width: 576px) {
    padding-left: 0.4em;
  }
}
</style>
