// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

import _ from '../Helpers';
import TradeType from './TradeType';

export default {
  type: 'schedule',
  
  skipAudit: true,

  fields: {
    ...TradeType.fields,
    stage_id: {
      type: 'int',
      title: 'Item Type',
      name: 'Item Type',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
    },
    stage_name: {
      type: 'string',
      title: 'Item Type Name',
      name: 'Item Type Name',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
    },
    stage_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
    },
    stage_keywords: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
    },
    after_stage_name: {
      type: 'string',
      title: 'Occurs after',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
    },
    after_stage_id: {
      type: 'int',
      title: 'Occurs after',
      filter: true,
      format: false,
      mapTo: 'stage',
      visible: true,
    },
    stage_order: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0,
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company',
    },
    stage_time_created: {
      type: 'int',
      filter: true,
      format: false,
    },
    stage_creator: {
      type: 'int',
      filter: true,
      format: false,
    },
    trade_type_id: {
      type: 'int',
      mapTo: 'trade_type',
      filter: true,
      save: true,
    },
    trade_type_name: {
      type: 'string',
      filter: true,
      save: true,
    },
    stage_is_lockup: {
      type: 'int',
    },
    stage_is_heated: {
      type: 'int',
    },
    stage_is_liveable: {
      type: 'int',
    },
    stage_is_inspect_before: {
      type: 'int',
    },
    labor_type_id: {
      type: 'string',
      mapTo: 'labor_type',
      filter: true,
      save: false,
    },
    labor_type_name: {
      type: 'string',
    },
    labor_type_rate_net: {
      type: 'float',
      filter: true,
      save: false,
    },
    labor_type_is_indexed: {
      type: 'int',
      filter: true,
      save: false,
      default: 0,
    },
  },
};
