<template>
  <div class="mt-4 pt-4" v-show="loading">
    <div class="loader flex items-center justify-center">
      <spinner class="info mr-4" :loading="1" size="2em" />
      <p v-if="loading === 1">Loading business account terms..</p>
    </div>
  </div>
  <section v-show="!loading" class="mt-0 md:mt-8" v-if="businessAccountTerms">
    <Card :flat="true">
      <div class="flex">
        <Checkbox v-model="localTerms.acceptBusinessAccountThroughBolster" />
        <span class="flex ml-4">
          I understand I am entering into a direct contractual relationship with Adyen N.V. for the
          usage of the Adyen business account through Bolster
        </span>
      </div>
    </Card>
    <Card class="mt-4" :flat="true">
      <div>
        <ScrollContainer ref="termsContainer" style="max-height: 60vh; border-radius: 0.3em">
          <span class="flex ml-4">
            <div ref="adyenDocumentViewer" class="document-viewer mt-2"></div>
          </span>
        </ScrollContainer>
        <div class="flex mt-4 items-start">
          <Checkbox v-model="localTerms.acceptBusinessAccountTerms" @change="updateTerms" />
          <div class="ml-4">I have read and agreed to the Adyen terms and conditions</div>
        </div>
      </div>
    </Card>
  </section>
</template>

<script>
import AdyenDocumentViewer from '@adyen/adyen-document-viewer'
import '@adyen/adyen-document-viewer/dist/adyen-document-viewer.min.css'

export default {
  name: 'BusinessAccountTerms',
  components: {},
  data() {
    return {
      loading: 1,
      localTerms: { ...this.businessAccountTerms }
    }
  },
  props: {
    businessAccountTerms: {
      type: Object
    },
    handleGetUploadedBusinessAccountTermsOfService: {
      type: Function,
      required: true
    }
  },
  methods: {
    updateTerms() {
      this.$emit('update:businessAccountTerms', this.localTerms)
    },
    /**
     * Try and retrieve any terms of services which were previously uploaded
     */
    async getUploadedBusinessAccountTermsOfService() {
      try {
        const { payload } = await this.handleGetUploadedBusinessAccountTermsOfService()

        if (payload.error) throw payload.error
        this.loading = 0
        return payload
      } catch (e) {
        this.error =
          'An error occurred while setting up your profile. Contact support if this issue persists.'
        this.loading = 0
        return false
      }
    }
  },
  async mounted() {
    const businessAccountTermsOfService = await this.getUploadedBusinessAccountTermsOfService()
    if (businessAccountTermsOfService) {
      const firstKey = Object.keys(businessAccountTermsOfService)[0]
      const firstTermsOfService = businessAccountTermsOfService[firstKey]
      const document = JSON.parse(
        decodeURIComponent(escape(window.atob(firstTermsOfService.document)))
      )
      const documentViewer = new AdyenDocumentViewer(this.$refs.adyenDocumentViewer)
      documentViewer.render(document)
    }
  }
}
</script>

<style lang="scss" scoped>
.document-viewer {
  width: 100%;
  height: 600px;
}
</style>
