<template>
  <choose
    class="whitespace-nowrap"
    v-bind="$props"
    v-if="groupableType"
    v-model="localValue"
    :disabled="!canChange"
    :schema="schema"
    highlightIfNotEmpty
    multiple
    size="lg"
    placeholder="All groups"
    :filters="{
      company_id: $store.state.session.company.company_id
    }"
  />
</template>

<script>
import SchemaFieldMixin from '../../mixins/SchemaField'
/**
 * Emits:
 *  -input
 */
export default {
  name: 'FilterGroup',
  mixins: [SchemaFieldMixin],
  emits: ['input'],
  data() {
    return {}
  },
  watch: {},
  props: {
    btnClass: {}
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    /**
     * Can this user see any other user detais for this object other than themselves?
     * @returns {boolean}
     */
    canChange() {
      const perms = this.$store.state.session.user.aUserPerms
      return (
        this.objectType &&
        perms &&
        (!(this.objectType in perms) ||
          !('read' in perms[this.objectType]) ||
          perms[this.objectType].read > 1)
      )
    },
    groupField() {
      return 'location_ids'
    },
    groupableType() {
      if (!this.objectType) return false
      const constructor = c.getConstructor(this.objectType)
      const fields = constructor.fields
      return this.groupField && this.groupField in fields
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
