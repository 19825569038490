<template>
  <card-list
    class="cost-item-container cost-item"
    @contextmenu.stop="() => $emit('openContextMenu')"
  >
    <card-list-item
      class="bg-white cost-item-collapse p-3"
      style="height: auto"
      headerClass="handle"
      :cloak="true"
      :showCaret="false"
    >
      <div class="cost-item--contents-container flex grow justify-between gap-x-2">
        <!-- Left Hand Side -->
        <div class="flex flex-row content-center items-center gap-x-2">
          <div
            class="title flex flex-row justify-start items-center gap-x-2"
            @click.stop="() => {}"
          >
            <Btn rounded class="more px-0 p-2 danger mr-1" v-if="removing" :action="remove">
              <font-awesome-icon icon="trash" />
            </Btn>

            <font-awesome-icon
              v-if="!object.stage_id"
              icon="exclamation-circle"
              class="mr-1 text-danger"
              v-tooltip="'Missing required field: Construction stage'"
            />

            <SvgIcon
              v-if="object.cost_item_has_live_pricing"
              svg="autocost"
              class="mr-1"
              v-tooltip="'This is an AutoCost item'"
              style="width: 1.2em; height: 1.2em"
            />

            <small class="flex items-center">
              <help class="mr-1" v-if="synced" ref="syncHelp">
                <template #button>
                  <font-awesome-icon
                    icon="arrows-rotate"
                    class="circled"
                    v-tooltip="
                      'This is a dynamically synced item. Except for item name, description and quantity, all other changes will be overriden when the assembly is reloaded or added to a quote. External changes to this item are loaded automatically when they occur.'
                    "
                    v-if="inAssembly && synced && !isDeleted"
                  />
                  <font-awesome-icon
                    icon="database"
                    class="circled"
                    v-tooltip="
                      'This item is saved as a catalog item already. Tap for more information...'
                    "
                    v-else-if="!inAssembly && synced && !isDeleted"
                  />
                  <font-awesome-icon
                    icon="database"
                    class="circled lead"
                    v-tooltip="
                      'You are using an item that was removed from the catalog. Replace with a working copy.'
                    "
                    v-else-if="synced && isDeleted"
                  />
                </template>

                <template #title> Saved items and assemblies </template>

                <template v-if="!inAssembly">
                  <p>
                    This item is saved as a catalog item already. Any changes you make to the item
                    here will not affect the saved version, it will only change it here.
                  </p>

                  <p>
                    If you want to save the changes you make here for future use, from the actions
                    button, choose 'Update in your catalog'. That will overwrite your saved version
                    with your new version.
                  </p>

                  <p>
                    If you want save the changes you made into an entirely
                    <strong>new item</strong> then choose 'Save to your catalog', and it will not
                    affect any previous saved version.
                  </p>

                  <p>
                    You do not need to save or update the item for your changes to be saved in the
                    proposal/project. You only need to save the proposal/project, and all the saved
                    versions stay the same.
                  </p>
                </template>

                <template v-else>
                  <p>
                    This is a saved/sync'ed item. You are able to change the name of the item in
                    this context, as well as the description. Any other changes you make to the
                    item, including the tasks, addons/uprades and costing/pricing will be reloaded
                    from fresh each time this item is added to a project or proposal.
                  </p>

                  <p>
                    To change tasks, addons/uprades and costing/pricing, open and edit this item,
                    then save it. Those new changes will be the ones loaded when it is added to a
                    project or proposal.
                  </p>
                </template>
              </help>

              <font-awesome-icon
                icon="chevrons-up"
                v-if="object.item_is_upgraded || object.addon_is_upgraded"
                v-tooltip="`This item is upgraded.`"
                class="circled coin mr-1"
              />

              <font-awesome-icon
                icon="chevrons-up"
                v-if="object.aoAddons.length"
                v-tooltip="`Upgrades/options available on this item.`"
                class="circled mr-1 text-info"
              />

              <font-awesome-icon
                icon="check"
                v-if="object.cost_type_is_variation_parent || object.variation_parent_cost_type_id"
                v-tooltip="`This is a variation item.`"
                class="circled mr-1 text-info"
              />

              <font-awesome-icon
                icon="percentage"
                v-if="object.cost_type_is_fee"
                v-tooltip="
                  `This is a fee item that is calculated based on values of its sibling items.`
                "
                class="circled mr-1 text-info"
              />
              <font-awesome-icon
                icon="landmark-flag"
                v-tooltip="'This item has a custom tax setting..'"
                v-if="Object.keys(object.oTaxSettings || {}).length"
                class="circled mr-1 danger"
              />
            </small>

            <a @click.stop="edit()" class="text-info flex flex-row items-center gap-x-2">
              <help
                v-if="!object.item_company_has_approved && !inAssembly && object.item_time_created"
              >
                <template #button>
                  <font-awesome-icon
                    icon="asterisk"
                    v-tooltip="'This item requires your approval! Click for more information..'"
                    class="text-danger"
                  />
                </template>

                <p>
                  This item, or changes to this item, have not yet been approved by someone at your
                  company that has the permissions to approve items.
                </p>

                <p>
                  When you approve the changes on this project, the changes with this item will also
                  be approved by you.
                </p>

                <p>
                  Once you've approved the changes, the updated project can be sent or re-sent to
                  your client.
                </p>
              </help>

              {{ object.cost_type_name || 'Add a name to this item..' }}
              <Badge
                v-if="object.cost_item_is_optional"
                value="Optional"
                severity="warning"
                v-tooltip="'This item is optional on the clients proposal'"
              >
              </Badge>
              <Badge
                v-if="object.cost_type_emphasis > 0"
                value="Highlighted"
                severity="success"
                v-tooltip="'This item is highlighted on the clients proposal'"
              >
              </Badge>
              <Badge
                v-if="object.cost_type_emphasis < 0"
                value="Hidden"
                :v-tooltip="`This item has been hidden from the clients proposal`"
              >
              </Badge>
            </a>

            <btn-bar
              v-if="warningMinimum || warningLosing"
              class="ml-2"
              :position="actionMenuPosition"
              :actions="[
                {
                  title: 'Set to minimum profit',
                  icon: 'arrow-up',
                  action: setMarkupToMinimum
                },
                {
                  title: 'Set to default profit',
                  icon: 'arrow-up',
                  action: setMarkupToDefault
                }
              ]"
              :collapse="true"
            >
              <template #title>
                <font-awesome-icon icon="exclamation-circle" class="text-client" />
                <span class="text-danger" v-if="warningLosing"
                  >This item will LOSE MONEY! Adjust pricing markup now!</span
                >
                <span class="text-client" v-else-if="warningMinimum"
                  >The markup on this item is below minimum set by company, adjust markup.</span
                >
              </template>
              <template #button>
                <btn class="more danger lg" v-if="warningLosing">
                  <font-awesome-icon icon="exclamation-circle" />
                </btn>
                <btn
                  class="more warning lg"
                  v-tooltip="'This item is below the minimum'"
                  v-else-if="warningMinimum"
                >
                  <font-awesome-icon icon="exclamation-circle" />
                </btn>
              </template>
            </btn-bar>

            <help v-if="hasMinPriceAdjustment > 0" class="ml-1">
              <template #title>Minimum cost adjustment</template>
              <template #before>
                <small class="danger badge">
                  <strong><font-awesome-icon icon="arrow-up" /> Cost bumped</strong>
                </small>
              </template>
              There is a minimum cost associated with this item. If the total quantity quoted for
              this item results in a cost below the minimum, it will be bumped up to the minimum
              cost.
            </help>

            <help v-if="object.cost_item_is_using_minimum_qty" class="ml-1">
              <template #title>Minimum quantity adjustment</template>
              <template #before>
                <small class="danger badge">
                  <strong><font-awesome-icon icon="arrow-up" /> Qty bumped</strong>
                </small>
              </template>
              This item has a minimum quantity setting of
              <strong>{{ object.cost_type_minimum_qty_net }}</strong
              >. The costs and pricing of this item will be based off the minimum, but your customer
              will see the quantity you entered.
            </help>
          </div>
          <div class="options">
            <!-- for btn bar -->

            <!-- for btn bar -->
            <btn-bar
              ref="contextMenu"
              class="ml-2"
              :alert="false"
              :position="actionMenuPosition"
              :collapse="true"
              :actions="lineItemActions"
            >
              <template #button>
                <btn :stop="false" :prevent="false" ref="button" class="more more-default">
                  <font-awesome-icon icon="ellipsis" />
                </btn>
              </template>
            </btn-bar>
          </div>
        </div>
        <div v-if="object.cost_item_is_optional">
          <SelectionToggle
            class="sm"
            v-model="isIncluded"
            :active-class="!isIncluded ? 'active danger' : 'active info'"
            :options="[
              {
                text: 'Not included',
                value: 0
              },
              {
                text: 'Included',
                value: 1
              }
            ]"
          />
        </div>
        <div v-if="object.cost_item_is_included && !object.cost_type_is_task">
          <div class="qty" @click="() => {}">
            <btn-group>
              <template v-if="!object.cost_type_is_fee">
                <btn
                  class="linked"
                  :style="linkStyle"
                  v-tooltip="'Unlink this quantity from required dimensions..'"
                  v-if="object.cost_item_link_qty"
                  @click="editable ? unlinkQty() : () => {}"
                >
                  <font-awesome-icon :icon="drafting - compass" />
                </btn>
                <btn
                  class="unlinked"
                  v-tooltip="'Lock this quantity to a required dimension..'"
                  v-else
                  @click="editable ? linkQty() : () => {}"
                >
                  <font-awesome-icon icon="link" />
                </btn>
              </template>

              <!-- :format="unitOfMeasure === 'ft' ? 'imperial' : 'number'" -->
              <field
                type="calculator"
                :clickToSelect="true"
                :disabled="!editable"
                schema="cost_item:cost_item_qty_net_base"
                :value="qtyBase"
                @blur="qtyBlurHandler"
                @focus="enableSuggestedDimensions()"
                @input="(value, equation) => setQty(value, equation)"
                :showButton="false"
                :dimensions="parentDimensions"
                :variables="(object.cost_type_is_fee && object.oSiblingsVariables) || {}"
                format="number"
                :equation="qtyEquation"
                :measure="measure"
                :data-drop-ref="`qty-${reference}`"
                :style="linkStyle"
                :class="{
                  muted: !object.cost_item_link_qty,
                  info: object.cost_item_link_qty,
                  danger: qtyBase < 0.01,
                  number: true,
                  'm-0': true,
                  'text-center': true
                }"
                style="font-weight: bold; min-width: 80px; max-width: 8em"
                ref="qtyField"
              />

              <choose
                schema="cost_type:unit_of_measure_id"
                v-if="!object.cost_type_is_fee"
                :disabled="!editable || object.live_price_reference"
                :allowDeselect="false"
                :return-array="false"
                v-model="unitOfMeasure"
              />
              <Btn class="muted" v-else> cost </Btn>
            </btn-group>

            <template
              v-if="(showSuggestedDimensions || hoveringSuggested) && !object.cost_type_is_fee"
            >
              <div
                class="suggested-dimensions-container"
                @mouseover.capture="hoveringSuggested = 1"
                @mouseout.capture="hoveringSuggested = 0"
              >
                <strong class="mb-2 mt-1">
                  <font-awesome-icon icon="drafting-compass" class="mr-1" />
                  Suggested dimensions
                </strong>
                <!--                @click="() => dimensionBadgeHandler(dim)"-->

                <CostItemDimSuggestor
                  :object="object"
                  @close="suggestorRequested = false"
                  @full="dimensionSelectorHandler"
                  :parentName="
                    $store.state[store].normalized[object.parentRefId].assembly_name || ''
                  "
                  :measureType="defaultMeasureType"
                  :parentDimensions="parentDimensions"
                  :limit="3"
                  :value="object.cost_type_qty_equation"
                >
                  <template #default="suggestedDimensions">
                    <div class="flex flex-wrap flex-col items-center">
                      <DimensionBadge
                        class="mb-2"
                        :dim="dim"
                        :key="dim.abbr"
                        :ref="dim.abbr"
                        :openable="false"
                        @click="dimensionBadgeHandler(dim)"
                        v-for="dim in suggestedDimensions"
                      />
                      <Btn class="sm round default mb-2" :action="linkQty"> More.. </Btn>
                    </div>
                  </template>
                </CostItemDimSuggestor>
              </div>
            </template>
            <MiniModal
              v-if="suggestorRequested && reference"
              ref="suggestorModal"
              :scrollable="true"
              @closed="suggestorRequested = false"
            >
              <DimensionSuggestor
                :object="object"
                @close="suggestorRequested = false"
                @full="dimensionSelectorHandler"
                :parentName="$store.state[store].normalized[object.parentRefId].assembly_name || ''"
                :measureType="defaultMeasureType"
                :parentDimensions="parentDimensions"
                :value="object.cost_type_qty_equation"
              />
            </MiniModal>
          </div>
          <div
            class="price gap-y-1"
            :id="`price-${uid}`"
            ref="priceEl"
            @click.stop="() => editPrice()"
          >
            <a
              class="number cost-value"
              v-tooltip="'Cost you pay'"
              v-if="showMarkupRelatedVariables && showForCost.includes('cost')"
            >
              {{ $f.currency(cost) }}
            </a>
            <a class="number price-value" v-if="showForCost.includes('price')">
              <div class="flex justify-end items-center">
                <template v-if="object.item_company_has_approved && !inAssembly">
                  <font-awesome-icon
                    v-tooltip="'The client has approved this item!'"
                    v-if="bookedProject && object.item_is_booked"
                    class="text-info"
                    icon="badge-check"
                  />
                  <font-awesome-icon
                    v-tooltip="'You marked this item as approved by the client.'"
                    v-else-if="bookedProject && object.item_client_has_approved"
                    style="opacity: 0.7"
                    class="text-info"
                    icon="badge-check"
                  />
                  <font-awesome-icon
                    v-tooltip="
                      'This item has not been approved by your client. Mark the latest change order as approved to approve this item.'
                    "
                    v-else
                    style="opacity: 0.5"
                    class="text-danger"
                    icon="asterisk"
                  />
                </template>

                <span v-tooltip="'Price your client pays'">
                  {{ $f.currency(price) }}
                </span>
              </div>
            </a>
            <a
              class="number profit-value"
              v-if="
                (showMarkupRelatedVariables && showForCost.includes('profit')) ||
                showForCost.includes('margin')
              "
              v-tooltip="'Your profit on this item'"
            >
              <div
                v-if="showMarkupRelatedVariables"
                class="flex justify-end items-center gap-x-1.5"
              >
                <span v-if="showForCost.includes('margin')">{{ formatNumber(margin, 0) }}%</span>
                <span v-if="showForCost.includes('profit')">{{ formatCurrency(profit) }}</span>
              </div>
            </a>
          </div>

          <Drop :fixTo="`#price-${uid}`" offset="0 0" targetAttachment="top right" ref="pricePopup">
            <div class="flex flex-col">
              <small><strong class="mb-2">Quick-set price</strong></small>

              <CardList class="sm">
                <CardListField>
                  <span>Cost</span>

                  <Field
                    :emit-delay="0"
                    schema="cost_item:cost_item_price_net_base"
                    v-model="quickSetCost"
                  />
                </CardListField>
                <CardListField>
                  <span>Price</span>

                  <Field
                    :emit-delay="0"
                    schema="cost_item:cost_item_price_net_base"
                    v-model="quickSetPrice"
                  />
                </CardListField>
              </CardList>
            </div>
          </Drop>
        </div>
      </div>

      <a
        v-if="object.live_price_reference && object.cost_type_sku"
        @click="openItemUrl"
        class="w-full ml-5 text-start text-[12px] underline"
        rel="noopener noreferrer"
      >
        Go to item
      </a>

      <div v-if="isVariationParent" class="cost-item--variation-parent-container">
        <CardSection>
          <CardList>
            <CardListCollapse :cloak="true">
              <template #title>
                <font-awesome-icon icon="circle-dot" class="circled info mr-2" />
                {{ object.aoVariationItems.length }} variation options
              </template>

              <CostItemVariationSelector
                :deselectOnDestroy="false"
                :store="store"
                :editable="1"
                :commitInterval="500"
                :reference="reference"
                :key="reference"
              />

              <div class="p-3">
                <Btn
                  class="info round block"
                  @click="edit()"
                  :disabled="variationIsLocked"
                  style="width:"
                >
                  {{ variationIsLocked ? 'Locked' : 'Edit...' }}
                </Btn>
              </div>
            </CardListCollapse>
          </CardList>
        </CardSection>
      </div>

      <cost-item-body
        v-show="false"
        ref="actionBody"
        v-if="actionsRequested"
        :deselectOnDestroy="false"
        :store="store"
        :reference="reference"
        :key="reference"
      />

      <!-- editor modal -->
      <mini-modal
        size="lg"
        :show-close-button="true"
        :width="500"
        :closeable="true"
        :click-away="true"
        v-if="showMiniModal"
        ref="itemEditor"
      >
        <cost-item-body
          @close="() => $refs.itemEditor.close()"
          :store="store"
          :reference="reference"
          :key="reference"
        />
      </mini-modal>
    </card-list-item>
  </card-list>
</template>

<script>
import Badge from 'primevue/badge'
import CostItemBody from '../bodies/CostItem.vue'
import UserMeta from '../mixins/UserMeta'
import DimensionSuggestor from './item/DimensionSuggestor.vue'
import DimensionBadge from '../ui/DimensionBadge.vue'
import CostItemVariationSelector from './item/CostItemVariationSelector.vue'
import CostItemDimSuggestor from '../items/CostItemDimSuggestor.vue'
import CheckaUserPermsMixin from '../mixins/CheckaUserPermsMixin'
import { formatCurrency, formatNumber } from '../mixins/CurrencyFilter'
import eventBus from '../../eventBus'
import { useMediaQuery } from '@/composables/mediaQuery'

export default {
  name: 'CostItem',
  mixins: [UserMeta, CheckaUserPermsMixin],
  emits: ['removing', 'openContextMenu'],
  setup() {
    const { smallFormat } = useMediaQuery()

    return { smallFormat }
  },
  created() {
    eventBus.$on('saved-dimension', this.dimensionCreatedHandler)
  },
  beforeUnmount() {
    eventBus.$off('saved-dimension', this.dimensionCreatedHandler)
    if (this.$refs.itemEditor) {
      this.$refs.itemEditor.close()
    }
  },
  watch: {
    object(before, after) {
      if (
        this.showSuggestedDimensions &&
        before &&
        after &&
        `${before.cost_type_name} ${before.cost_type_desc}` !==
          `${after.cost_type_name} ${after.cost_type_desc}`
      ) {
        this.enableSuggestedDimensions()
      }
    },
    async suggestorRequested(b) {
      await this.$nextTick()

      if (!this.$refs.suggestorModal) return

      if (b) this.$refs.suggestorModal.open()
      if (!b) this.$refs.suggestorModal.close()
      // if (b && this.smallFormat) this.$refs.suggestorModal.open();
      // if (b && !this.smallFormat) this.$refs.suggestorDrop.open();
      // if (!b && this.smallFormat) this.$refs.suggestorModal.close();
      // if (!b && !this.smallFormat) this.$refs.suggestorDrop.close();
    },
    quickSetPrice() {
      this.quickSet()
    },
    quickSetCost() {
      this.quickSet()
    }
  },
  data() {
    return {
      uid: _.uniqueId(),
      showMiniModal: false,

      dropRequested: false,
      suggestorRequested: false,
      dropRequestedEver: false,

      actionsRequested: false,

      loadingLivePrice: false,

      existsConfirmed: null,

      linkingValueInput: false,

      showSuggestedDimensions: false,

      hoveringSuggested: 0,
      possibleDimensions: {},

      quickSetPrice: 0,
      quickSetCost: 0,

      lineItemActions: {
        edit: {
          title: 'Edit',
          icon: 'pencil',
          action: this.edit,
          visible: () => !this.editing
        },
        swap: {
          title: 'Replace with..',
          icon: ['fas', 'right-from-bracket'],
          action: this.swap
        },
        duplicate: {
          title: 'Duplicate',
          icon: 'clone',
          action: this.duplicate
        },
        remove: {
          title: 'Remove',
          icon: 'circle-xmark',
          action: this.remove
        },
        save: {
          title: 'Update in your catalog',
          icon: 'database',
          action: () => this.save(false),
          visible: () =>
            this.object.cost_type_id &&
            this.assemblyEditable &&
            this.object.cost_type_status !== '@' &&
            !this.isDeleted
        },
        saveAs: {
          title: 'Save to your catalog',
          icon: ['fas', 'layer-plus'],
          visible: () => this.assemblyEditable,
          action: () => this.save(true)
        },
        saveSuper: {
          title: '(GLOBAL) Update in free catalog',
          icon: 'layer-group',
          action: () => this.save(false, true),
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id &&
            this.object.cost_type_id &&
            this.assemblyEditable
        },
        saveAsSuper: {
          title: '(GLOBAL) Save to free catalog',
          icon: 'layer-group',
          visible: () =>
            this.$store.state.session.user.user_is_super_user &&
            !this.$store.state.session.user.company_id &&
            this.assemblyEditable,
          action: () => this.save(true, true)
        }
      }
    }
  },
  computed: {
    variationIsLocked() {
      let locked = false

      if (this.object.oVariationTraits) {
        // If any of the options have been locked, then the whole variation is locked
        Object.values(this.object.oVariationTraits).every((option) => {
          if (option.variationTypeOptions && option.variationTypeOptions.locked) {
            locked = true
            return false
          }
          return true
        })
      }

      return locked
    },
    actionMenuPosition() {
      return this.smallFormat ? 'bottom left' : 'bottom right'
    },
    isIncluded: {
      get() {
        return this.object.cost_item_is_included
      },
      async set(incl) {
        if (this.object.cost_item_is_included === incl) return

        await this.$store.dispatch('Quote/toggleIncluded', {
          included: incl,
          store: this.store,
          explicit: true,
          refId: this.reference
        })
      }
    },
    cost() {
      return c.divide(this.object.cost_item_total_cost_net, this.object.quantity_multiplier)
    },
    price() {
      return c.divide(this.object.cost_item_price_net, this.object.quantity_multiplier)
    },
    profit() {
      return this.price - this.cost
    },
    margin() {
      return c.divide(this.profit, this.price) * 100
    },
    isVariationParent: {
      get() {
        return this.object.cost_type_is_variation_parent
      }
    },
    suggestDimensions() {
      return this.object.cost_type_name && !this.object.asDimensionsLinked.length
    },
    defaultMeasureType() {
      return this.getDefaultMeasureType()
    },
    linkStyle() {
      const linked = this.object.asDimensionsLinked
      if (!linked.length) return {}

      this.getPossibleDimensions()
      const dimensions = this.possibleDimensions

      const abbr = linked[0]
      if (!(abbr in dimensions)) return {}

      return {
        backgroundColor: `#${dimensions[abbr].color} !important`,
        color: '#444 !important'
      }
    },
    testing() {
      return this.$store.state.session.testing
    },
    showTaxError() {
      const object = this.object
      return (
        this.testing &&
        c.n(this.object.cost_item_price_net) > 0 &&
        (!c.eq(object.cost_item_profit_tax / object.cost_item_profit_net, 0.05, 2) ||
          !c.eq(object.cost_item_cost_tax / object.cost_item_total_cost_net, 0.05, 2))
      )
    },
    bookedProject() {
      const status = this.$store.state[this.store].normalized[this.rootRefId].quote_status
      return status === 'k' || status === 'f'
    },
    /**
     * Checks if this is a synced item being edited in an assembly store context
     * in which case it should be edited separately
     * @returns {boolean|*}
     */
    assemblyEditable() {
      return !this.inAssembly || (!this.object.cost_type_id && this.editable)
    },

    isDeleted() {
      return this.object.cost_type_status === 'h' || this.object.cost_type_status === 'y'
    },

    measure() {
      const uom = this.unitOfMeasure

      if (uom in _.conversionTables) {
        return uom
      }

      return 'count'
    },

    baseMeasure() {
      const uom = this.unitOfMeasure

      if (uom in _.conversionTables) {
        return _.conversionTables[uom].baseMeasure
      }

      const base =
        this.unitOfMeasure &&
        this.$store.getters.uomById &&
        this.$store.getters.uomById[String(uom)] &&
        this.$store.getters.uomById[String(uom)].unit_of_measure_base_measure

      return base || 'count'
    },
    /**
     *
     */
    qtyEquation: {
      get() {
        return this.object.cost_type_qty_equation
      },
      async set(equation) {
        await this.$store.dispatch(`${this.store}/field`, {
          changes: {
            cost_type_qty_equation: equation
          },
          explicit: true,
          store: this.store,
          refId: this.reference
        })
      }
    },
    priceEquation: {
      get() {
        return this.object.oEquations.cost_item_price_net_base || ''
      },
      async set(equation) {
        this.setEquation('cost_item_price_net_base', equation)
      }
    },

    /**
     * Parents dimensions
     */
    parentDimensions() {
      return (
        (this.store &&
          this.object.parentRefId &&
          this.$store.state[this.store] &&
          this.$store.state[this.store].normalized[this.object.parentRefId] &&
          this.$store.state[this.store].normalized[this.object.parentRefId].oDimensions) ||
        {}
      )
    },

    /**
     * Returns 1 if linkable, 0 if not possible to link
     * @returns {int}
     */
    linkable() {
      return true
    },

    /**
     * Get/set qty of this item. This sets the cost_item_qty_net_BASE
     * NOT the net qty. To set it must be multiplied by the qty multiplier.
     */
    qtyBase: {
      get() {
        return this.object.cost_item_qty_net_base
      },

      async set(qtyBase) {
        eventBus.$emit('closeAllDrops')
        const qty = qtyBase * (this.object.quantity_multiplier || 1)
        const orig = this.qtyBase * (this.object.quantity_multiplier || 1)
        if (!c.eq(qty, orig)) {
          await this.$store.dispatch('CostItem/setQty', {
            qty,
            store: this.store,
            refId: this.reference,
            auditLocal: false,
            auditFull: false
          })
        }
      }
    },

    /**
     * Set and get unit of measure id
     * @returns {int|string}
     */
    unitOfMeasure: {
      get() {
        return this.object.unit_of_measure_id
      },

      set(unitOfMeasure) {
        if (String(unitOfMeasure) !== this.unitOfMeasure) {
          this.$store.dispatch('CostItem/setUnitOfMeasure', {
            unitOfMeasure,
            store: this.store,
            refId: this.reference
          })
        }
      }
    },

    /**
     * Minimum margin set from company
     * @returns {float}
     */
    companyMinMargin() {
      return c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin)
    },

    warningMinimum() {
      const adj = c.n(this.object.cost_item_markup_net_adjusted)
      const min = c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin)
      return adj < min && Math.abs(adj - min) > 0.01
    },

    /**
     * Returns true if the profit on this item are negative
     * @returns {boolean}
     */
    warningLosing() {
      const adj = c.n(this.object.cost_item_markup_net_adjusted)
      return !c.eq(adj, 1, 4) && adj < 1
    },

    /**
     * Returns true if this item has a minimum cost
     * @returns {boolean}
     */
    hasMinPriceAdjustment() {
      return !!(
        this.object.cost_item_minimum_labor_net_adjustment ||
        this.object.cost_item_minimum_materials_net_adjustment
      )
    },

    /**
     * Returns whether the item is a saved item
     * @returns {boolean}
     */
    synced() {
      return !!this.object.cost_type_id
    }
  },
  methods: {
    formatCurrency,
    formatNumber,
    openItemUrl() {
      if (this.object.live_price_item_url)
        return window.open(this.object.live_price_item_url, '_blank')
      return this.$store.dispatch(
        'alert',
        { message: 'Could not find item url.', error: true },
        { root: true }
      )
    },
    // CURRENTLY NOT BEING USE
    // Update price of a single AutoCost item
    async fetchCostItemLivePrice() {
      this.loadingLivePrice = true

      const zipcode = this.object.quote_postal

      try {
        await this.$store.dispatch('Quote/fetchLivePricing', {
          zipcode,
          refIds: [this.object.refId],
          rootRefId: this.rootRefId,
          all: false
        })
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not update price.',
          error: true
        })
      }

      this.loadingLivePrice = false
    },
    async quickSet() {
      this.addLoading()
      await c.throttle(
        async () => {
          let combinedChanges = {}

          if (!c.eq(this.quickSetCost, this.cost)) {
            const { changes } = await this.$store.dispatch('CostItem/setTotalCost', {
              store: this.storeName,
              object: this.object,
              totalCost: this.quickSetCost
            })
            combinedChanges = {
              ...combinedChanges,
              ...changes
            }
          }

          if (!c.eq(this.quickSetPrice, this.price)) {
            const { changes } = await this.$store.dispatch('CostItem/setTotalPrice', {
              store: this.storeName,
              object: this.object,
              totalPrice: this.quickSetPrice
            })
            combinedChanges = {
              ...combinedChanges,
              ...changes
            }
          }

          return this.$store.dispatch(`${this.store}/field`, {
            changes: combinedChanges,
            refId: this.reference,
            explicit: combinedChanges,
            skipAudit: true,
            skipLocalAudit: false
          })
        },
        {
          key: `${this.reference}-quickset`
        }
      )
      this.endLoading()
    },
    unlinkFromSavedVersion() {
      return this.$store.dispatch(`${this.store}/field`, {
        changes: {
          cost_type_id: null
        },
        refId: this.reference,
        explicit: {
          cost_type_id: null
        },
        skipAudit: true,
        skipLocalAudit: false
      })
    },
    dimensionCreatedHandler() {
      this.getPossibleDimensions(true)
    },
    getPossibleDimensions(force = false) {
      if (force || !Object.keys(this.possibleDimensions).length) {
        const poss = _.getCacheItem('PossibleDimensions', 'dimension', this.$store.state.session)
        const pd = this.parentDimensions
        this.possibleDimensions = {
          ...poss,
          ...pd
        }
      }

      return this.possibleDimensions
    },
    async dimensionBadgeHandler(dim) {
      if (this.$refs[dim.abbr] && this.$refs[dim.abbr].length) {
        this.$refs[dim.abbr].forEach((h) => h.close())
      }
      const equation = dim.abbr
      const qtyBase = 0

      this.addLoading()
      this.getPossibleDimensions()
      const dimensionTypes = this.possibleDimensions

      const qty = qtyBase * (this.object.quantity_multiplier || 1)

      const orig = this.qtyBase * (this.object.quantity_multiplier || 1)

      if (!c.eq(qty, orig) || equation !== this.qtyEquation) {
        // If this is linked, the dimension is zero, and now
        // user is setting the value, set the dimension instead
        if (
          this.object.cost_item_link_qty &&
          this.object.asDimensionsLinked.length === 1 &&
          qtyBase > 0 &&
          (c.eq(this.qtyBase, 0) || this.linkingValueInput)
        ) {
          this.linkingValueInput = true
          // If the user includes local dimensions in the equation, the
          // furthest up this value can be pushed is the closest parent
          // to make sure the values are correct
          const parentRefId =
            equation &&
            new RegExp(`(\b|^)(${Object.keys(dimensionTypes).join('|')})(\b|$)`).test(equation)
              ? this.parentRefId
              : null
          const measure = c.getMeasureForUnitOfMeasure(this.object.unit_of_measure_id)
          this.setOriginDimension(
            this.object.asDimensionsLinked[0],
            qtyBase,
            measure,
            equation,
            parentRefId
          )
          this.qtyEquation = this.object.asDimensionsLinked[0]
          this.removeLoading()
          return
        }

        await this.$store.dispatch('CostItem/setQty', {
          qty,
          equation,
          store: this.store,
          refId: this.reference,
          skipAudit: true,
          skipLocalAudit: true,
          audit: false,
          auditLocal: false,
          auditFull: true
        })

        const uom = await this.$store.dispatch(
          'UnitOfMeasure/getSuggestedUnitOfMeasureFromDimension',
          dim
        )

        if (
          c.getMeasureTypeForUnitOfMeasure(uom) !==
          c.getMeasureTypeForUnitOfMeasure(this.object.unit_of_measure_id)
        ) {
          await this.$store.dispatch('CostItem/setUnitOfMeasure', {
            unitOfMeasure: uom,
            store: this.store,
            refId: this.reference,
            skipAudit: true,
            skipLocalAudit: true,
            audit: false,
            auditLocal: false,
            auditFull: true
          })
        }

        // Audit this
        await this.$store.dispatch(`${this.store}/auditLocalDependencies`, {
          store: this.store,
          refId: this.reference,
          immediate: true
        })

        // Audit parent
        const parentRefId = this.$store.state[this.store].normalized[this.reference].parentRefId
        if (parentRefId) {
          await this.$store.dispatch(`${this.store}/auditLocalDependencies`, {
            store: this.store,
            refId: parentRefId,
            immediate: true
          })
        }

        this.$store.dispatch(`${this.store}/auditDependencies`, {
          store: this.store,
          refId: parentRefId
        })
      }
      this.removeLoading()
      if (this.$refs.dimDrop) this.$refs.dimDrop.close()
    },
    async enableSuggestedDimensions() {
      if (!this.suggestDimensions) return

      this.showSuggestedDimensions = c.getMeasureForUnitOfMeasure(this.unitOfMeasure) !== 'count'

      c.throttle(
        () => {
          this.showSuggestedDimensions = false
        },
        {
          delay: 8000
        }
      )
    },
    suggestLink() {
      if (!c.isUnitOfMeasureLinkable(this.object.unit_of_measure_id)) return

      this.suggestorRequested = true
    },
    async setPrice(v, equation) {
      if (!c.eq(v, this.totalPrice, 2)) {
        const multiplied = _.n(v) * (+this.object.quantity_multiplier || 1)
        const { changes } = await this.$store.dispatch('CostItem/setTotalPrice', {
          store: this.storeName,
          object: this.object,
          totalPrice: multiplied
        })
        const eqChanges = {
          ...this.object.oEquations,
          ...(equation ? { cost_item_price_net_base: equation } : {})
        }
        changes.oEquations = eqChanges

        this.$store.dispatch(`${this.store}/field`, {
          changes,
          refId: this.reference,
          explicit: {
            cost_item_price_net_base: v,
            oEquations: eqChanges
          },
          skipAudit: true,
          skipLocalAudit: false
        })
        this.setEquation('cost_item_price_net_base', equation)

        this.$store.dispatch(`${this.store}/auditDependencies`, {
          changes,
          delay: 5000
        })
      }

      this.setEquation('cost_item_price_net_base', equation)
    },
    qtyBlurHandler() {
      this.linkingValueInput = false
    },
    dimensionSelectorHandler(full) {
      const { equation, unitOfMeasure } = full

      this.unitOfMeasure = unitOfMeasure
      this.setQty(0, equation)
    },

    getDefaultMeasureType() {
      const uom = String(this.object.unit_of_measure_id)
      const abbr = this.object.unit_of_measure_abbr

      if (/kg|lbs|oz|ton/.test(uom)) {
        return 'weight'
      } else if (/\w+2/.test(uom) || /\w+2/.test(abbr)) {
        return 'area'
      } else if (/\w+3/.test(uom) || /\w+3/.test(abbr)) {
        return 'volume'
      } else if (/^[a-zA-Z]+$/.test(uom) || /^(ft|mm|m|yd)$/.test(abbr)) {
        return 'length'
      }

      return 'count'
    },
    async setOriginDimension(abbr, value, measure, equation = null, parentRefId = null) {
      const originRef = parentRefId || this.getDimensionOriginRefId(abbr)
      const dims = _.imm(this.$store.state[this.store].normalized[originRef].oDimensions)
      const dimMeasureType = dims[abbr].measureType
      const measureMeasureType = c.getMeasureTypeForUnitOfMeasure(measure)
      const dimDefaultMeasure =
        dimMeasureType === 'count'
          ? 'count'
          : this.getMetaItem(`default${c.titleCase(dimMeasureType)}Measure`)

      this.getPossibleDimensions()
      const dimensionTypes = this.possibleDimensions
      dims[abbr] = {
        ...dimensionTypes[abbr],
        ...(dims[abbr] || {}),
        inherit: 0,
        value,
        equation,
        explicitlySet: 1
      }

      if (dimDefaultMeasure || (measureMeasureType === dimMeasureType && measure)) {
        dims[abbr].measure = measureMeasureType === dimMeasureType ? measure : dimDefaultMeasure
      }

      return this.$store.dispatch(`${this.store}/field`, {
        changes: {
          oDimensions: dims
        },
        refId: originRef,
        explicit: true
      })
    },
    getDimensionOriginRefId(abbr) {
      const norm = this.$store.state[this.store].normalized
      let parentRefId = norm[this.reference].parentRefId
      let originRefId = parentRefId

      while (parentRefId) {
        originRefId = parentRefId
        if (
          norm[parentRefId].oDimensions &&
          (!(abbr in norm[parentRefId].oDimensions) || !norm[parentRefId].oDimensions[abbr].inherit)
        ) {
          break // go no further
        }

        parentRefId = norm[parentRefId].parentRefId
      }

      return originRefId
    },
    async addLoading() {
      this.$refs.contextMenu.addLoading()
    },
    async removeLoading() {
      this.$refs.contextMenu.removeLoading()
    },
    async endLoading() {
      this.$refs.contextMenu.endLoading()
    },
    async setQty(qtyBase, equation) {
      this.addLoading()
      const qty = qtyBase * (this.object.quantity_multiplier || 1)

      const orig = this.qtyBase * (this.object.quantity_multiplier || 1)

      if (!c.eq(qty, orig) || equation !== this.qtyEquation) {
        // If this is linked, the dimension is zero, and now
        // user is setting the value, set the dimension instead
        if (
          this.object.cost_item_link_qty &&
          this.object.asDimensionsLinked.length === 1 &&
          qtyBase > 0 &&
          (c.eq(this.qtyBase, 0) || this.linkingValueInput)
        ) {
          this.linkingValueInput = true
          // If the user includes local dimensions in the equation, the
          // furthest up this value can be pushed is the closest parent
          // to make sure the values are correct
          const parentRefId =
            equation &&
            new RegExp(`(\b|^)(${Object.keys(this.possibleDimensions).join('|')})(\b|$)`).test(
              equation
            )
              ? this.parentRefId
              : null
          const measure = c.getMeasureForUnitOfMeasure(this.object.unit_of_measure_id)
          this.setOriginDimension(
            this.object.asDimensionsLinked[0],
            qtyBase,
            measure,
            equation,
            parentRefId
          )
          this.qtyEquation = this.object.asDimensionsLinked[0]
          this.removeLoading()
          return
        }

        await this.$store.dispatch('CostItem/setQty', {
          qty,
          equation,
          store: this.store,
          refId: this.reference,
          auditLocal: false,
          auditFull: false,
          skipLocalAudit: true,
          skipAudit: true
        })

        // Audit this
        let { changeSet: localChanges } = await this.$store.dispatch(
          `${this.store}/auditLocalDependencies`,
          {
            store: this.store,
            refId: this.reference,
            immediate: true,
            full: true
          }
        )
        localChanges[this.reference].cost_item_qty_net_base = qtyBase
        localChanges[this.reference].cost_item_qty_net = qty

        // Audit parent
        const parentRefId = this.$store.state[this.store].normalized[this.reference].parentRefId
        if (parentRefId && equation) {
          const { changeSet: parentChanges } = await this.$store.dispatch(
            `${this.store}/auditLocalDependencies`,
            {
              store: this.store,
              refId: parentRefId,
              immediate: true,
              full: true
            }
          )
          localChanges = {
            ...localChanges,
            ...parentChanges
          }
        }

        await this.$store.dispatch(`${this.store}/auditDependencies`, {
          store: this.store,
          refId: parentRefId,
          changes: localChanges
        })
        await this.$store.dispatch('Quote/recalcAddons', {
          refIds: [this.reference],
          loading: false
        })
      }
      this.removeLoading()
      if (this.$refs.dimDrop) this.$refs.dimDrop.close()
    },
    async unsyncIfNotExists() {
      const exists = await this.confirmTypeExists()

      if (!exists) {
        await this.$store.dispatch(`${this.store}/field`, {
          refId: this.reference,
          changes: {
            cost_type_id: null,
            cost_matrix_id: null
          },
          explicit: true,
          immediate: true,
          queue: false,
          skipAudit: true,
          skipLocalAudit: true,
          auditLocal: false,
          auditFull: true
        })
      }

      return exists
    },

    async confirmTypeExists() {
      if (this.existsConfirmed) {
        return true
      }

      let item
      try {
        const { object } = await this.$store.dispatch('CostType/fetch', {
          quick: true,
          id: this.object.cost_type_id
        })
        item = object
      } catch (e) {
        return false
      }

      if (!item || !item.cost_type_id) {
        return false
      }

      this.existsConfirmed = true
      return true
    },

    async requestAction() {
      if (!this.actionsRequested) {
        this.actionsRequested = true
        await this.$nextTick()
        await c.throttle(this.reference, {
          delay: 500
        })
      }

      return this.$refs.actionBody
    },

    /**
     * Intermediary
     */
    async remove() {
      this.$emit('removing', 1)
      const actionBody = await this.requestAction()
      this.$destroy()
      return actionBody.remove()
    },

    /**
     * Intermediary
     */
    async duplicate() {
      const actionBody = await this.requestAction()
      return actionBody.duplicate()
    },

    /**
     * Intermediary
     */
    async swap() {
      const actionBody = await this.requestAction()
      return actionBody.swap()
    },

    /**
     * Intermediary
     */
    async save(asNew = false, asSuper = false) {
      const actionBody = await this.requestAction()
      await actionBody.save(asNew, asSuper)
      actionBody.commit()
      return actionBody
    },

    /**
     * Set equation
     * @returns {void}
     */
    setEquation(field, equation) {
      this.$store.commit({
        type: `${this.store}/FIELD_EQUATION`,
        field,
        equation,
        refId: this.reference
      })
    },

    /**
     *
     * @returns {Promise}
     */
    linkQty() {
      this.suggestorRequested = true
    },

    /**
     * Unlink this qty
     * @returns {Promise}
     */
    unlinkQty() {
      return this.$store.dispatch('CostItem/unlinkQty', {
        store: this.store,
        refId: this.reference
      })
    },

    /**
     * Set markup to minimum markup, set in company estimating settings
     * @returns {Promise}
     */
    setMarkupToMinimum() {
      return this.setTargetMarkup(this.$store.getters.defaultMarkup)
    },

    /**
     * Set markup to default markup
     * @returns {Promise}
     */
    setMarkupToDefault() {
      return this.setTargetMarkup(
        c.marginToMarkup(this.$store.state.session.company.company_minimum_quote_margin)
      )
    },

    setTargetMarkup(targetMarkup) {
      return this.$store.dispatch('CostItem/setTargetMarkup', {
        store: this.store,
        targetMarkup,
        refId: this.reference
      })
    },

    async edit() {
      let openExternal = !this.assemblyEditable

      if (this.object.cost_type_id && this.inAssembly) {
        openExternal = await this.unsyncIfNotExists()
      }

      if (!openExternal || !this.inAssembly) {
        this.showMiniModal = true
        await this.$nextTick()
        this.$refs.itemEditor.open()
      } else {
        const { object } = await this.$store.dispatch('edit', {
          type: 'cost_type',
          id: this.object.cost_type_id
        })
        this.actionsRequested = true

        object.refId = this.reference
        object.parentRefId = this.object.parentRefId
        object.item_id = this.object.item_id
        object.type = 'cost_item'

        this.$store.dispatch(`${this.store}/field`, {
          refId: this.reference,
          changes: object,
          explicit: true
        })
      }

      return this
    }
  },
  components: {
    CostItemDimSuggestor,
    CostItemVariationSelector,
    DimensionBadge,
    DimensionSuggestor,
    CostItemBody,
    Badge
  },
  props: {
    reference: {
      type: String,
      required: true
    },

    object: {
      type: Object,
      required: true
    },

    store: {
      type: String,
      required: true,
      default: 'Quote'
    },

    showForCost: {
      type: Array,
      default: () => ['cost', 'price']
    },

    editable: {
      type: Boolean,
      default: true
    },

    inAssembly: {
      default: false
    },

    rootRefId: {
      required: true,
      default: false
    },

    removing: {
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.cost-item-badge {
  font-size: 0.6em;
  font-weight: normal;
  &.highlighted {
    background-color: #cce7c9;
    border: 1px solid #8bca84;
    color: black;
  }
}

.cost-item-container {
  box-shadow: $shadow !important;
  border-width: 1px;
  transition:
    outline 1s ease-out,
    transform 1s ease-out;
  transform: translateZ(0);

  .suggested-dimensions-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 0.25em;
    border-radius: 0.5em;
    margin: 0.15em 0;
    line-height: 1;
    font-size: 1.5em;
    text-align: center;
    background: $cool-gray-200;
  }

  .collapse--header {
    flex: 1 0 100%;
  }

  .hover-button {
    opacity: 0;
  }

  .cost-item-collapse {
    .cost-item--contents-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      flex: 1 0 100%;
      min-height: 3em;

      > div {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex: 0 auto;
        border-radius: 5px;
        padding: 0.25em 0;
        // @media (max-width: 992px) {
        //   border-radius: 0px;
        // }

        &:first-child {
          justify-content: flex-start;
          min-width: 10em;

          .options {
            flex-basis: 2em;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .title {
            height: 100%;
            margin-left: 0.5em;
            white-space: normal;
            word-break: break-word;
            overflow: visible;
            text-overflow: ellipsis;
            font-weight: bold;
            color: $cool-gray-600;
            display: inline-flex;
            align-items: flex-start;
            text-align: left;
          }
        }

        &:last-child {
          justify-content: flex-end;
          overflow: visible;
          width: auto;

          .qty {
            margin-left: 1em;
            flex: 1 0 20em;
            margin-left: 1em;
            font-size: 0.7em;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-wrap: nowrap;
            justify-content: center;
            white-space: nowrap;

            [calculator-component] {
              width: 7em;
              flex: 0 0 7em;
              margin-right: 0.1em;

              @media (max-width: 576px) {
                width: 5em;
                max-width: 5em;
                min-width: unset !important;
                flex: 0 0 5em;
              }
            }
          }

          .price {
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            display: flex;
            flex: 0 0 6em;
            text-align: right;
            margin-left: 1em;
            text-overflow: ellipsis;
            font-weight: normal;

            > .cost-value {
              color: $deep-red-800;
              display: flex;
              justify-content: flex-end;
              align-content: center;
              align-items: center;
              line-height: 1;
              font-size: 0.7em;
            }
            > .price-value {
              display: flex;
              justify-content: flex-end;
              align-content: center;
              align-items: center;
              color: $cool-gray-700;
              line-height: 1;
              font-size: 1em;
            }
            > .profit-value {
              display: flex;
              justify-content: flex-end;
              align-content: center;
              align-items: center;
              color: $matcha-500;
              line-height: 1;
              font-size: 0.7em;
            }
          }
        }
      }
    }
    .cost-item--collapse-contents {
      @media (max-width: 992px) {
        padding: 0px;
      }
      background-color: #3f517d !important;
      border-color: #3f517d !important;
      .card-label {
        color: $flame-white;
      }
    }

    .cost-item--variation-parent-container {
      padding-left: 2em;
      justify-content: flex-start;
      align-items: center;

      flex-wrap: wrap;
      width: 100%;
      flex: 1 0 100%;
      min-height: 3em;
    }
  }

  &.active-item {
    transform: translateZ(0);
    outline-offset: -2px;
    outline: 9999px solid rgba(0, 0, 0, 0.8);
    border: none !important;
  }
}

.item-save-bar {
  height: 4em;
  margin-top: 4em;
  background: rgba($pitch-black, 0.1);
  margin-bottom: -$list-group-item-padding-y;
  margin-right: -$list-group-item-padding-x;
  margin-left: -$list-group-item-padding-x;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 576px) {
    margin: 0;
    margin-top: 4em;
  }
}

.sortable-drag {
  .variation-selector--container {
    display: none !important;
  }
}
</style>
