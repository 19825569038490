import { App } from '@capacitor/app'

/**
 * @param {import('vue-router').Router} router
 */
export function addCapacitorListeners(router) {
  App.addListener('appUrlOpen', function (event) {
    // Natively, the URL will be `bolsterbuilt.app`
    const slug = event.url.split('.app').pop()

    if (slug) {
      router.push({
        path: slug
      })
    }
  })
}
