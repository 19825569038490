/**
 * This file is for storing all the client services/features offered in the client portal
 * topDropDown - is for the side panel top context menu options
 * bottomDropDown - is for the side panel bottom context menu options
 *
 * This content was placed here as one central location to change the user type features, services and menu items
 */

const services = ({ store, modules }) => {
  const isCompanyAdmin = () => {
    if (store.state.session.isLoggedIn) {
      // Get the current company and check the user is an admin
      const scopedCompany = store.state.session.scope?.company
      const userCompanies = store.state.session.user?.aoCompanies
      if (scopedCompany) {
        for (const company in userCompanies) {
          if (
            userCompanies[company].company_id === scopedCompany &&
            userCompanies[company].user_is_admin == 1
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  const isAdmin = () => {
    const session = store.state.session
    return session.user && session.user.user_is_admin === 1
  }

  const isSuperUser = () => {
    return store.state.session.user && store.state.session.user.user_is_super_user
  }

  const isImpersonating = () => {
    const session = store.state.session
    const scopedUser = session.scope.user
    const authorizedUser = session.authorizedUser.user_id
    return scopedUser !== authorizedUser
  }

  const topDropDown = {
    title: store.state.session.company?.company_name,
    links: [
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      },
      {
        label: 'Company settings',
        command: () => store.dispatch('to', 'company')
      }
    ]
  }

  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Edit profile',
        command: () => store.dispatch('to', 'profile')
      },
      {
        label: 'Manage users',
        command: () => store.dispatch('to', 'users')
      },
      ...(isCompanyAdmin()
        ? [
            {
              label: 'Announcements',
              command: () => store.dispatch('to', 'announcements')
            }
          ]
        : []),
      {
        separator: true
      },
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      },
      {
        label: 'Logout',
        command: () => store.dispatch('to', 'logout')
      },
      ...(isSuperUser()
        ? [
            {
              separator: true
            },
            {
              label: 'Super user dashboard',
              command: () => store.dispatch('to', 'super')
            }
          ]
        : []),
      ...(isImpersonating()
        ? [
            {
              separator: true
            },
            {
              label: 'Stop impersonating',
              command: () =>
                store.dispatch('User/loginAs', {
                  object: {
                    user_id: store.state.session.authorizedUser.user_id
                  }
                })
            }
          ]
        : [])
    ]
  }

  const features = [
    {
      title: 'Essentials',
      icon: 'gauge',
      links: [
        {
          title: store.state.session?.company?.company_name ?? 'Company home',
          icon: 'house-chimney',
          to: 'dashboard',
          desc: '',
          starred: true
        },
        {
          title: 'Pipeline',
          icon: 'columns-3',
          to: 'pipeline',
          desc: 'Overview of active leads, clients, quotes, projects and invoices.',
          starred: true
        },
        {
          title: 'Insights',
          icon: 'chart-simple',
          to: 'metrics',
          desc: 'Key performance indicators and charts to help visualize your business.'
        },
        {
          title: 'Bolster Drive',
          to: 'files',
          icon: 'folder',
          desc: 'Complete drag & drop file storage + management, in the cloud.'
        },
        {
          title: 'Bolster academy',
          to: 'academy',
          icon: 'graduation-cap',
          desc: 'Learn how to use Bolster'
        }
      ]
    },
    {
      title: 'Sales',
      icon: 'house-laptop',
      links: [
        {
          title: 'Marketing',
          to: 'marketing',
          icon: 'billboard',
          desc: `Your marketing optimization dashboard`
        },
        {
          title: 'Leads',
          to: 'leads',
          icon: 'house-flag',
          desc: `Your leads, anyone waiting for a quote`,
          type: 'lead'
        },
        {
          title: 'Estimates',
          to: 'estimates',
          icon: 'file-signature',
          desc: `Build accurately quoted proposals with
                  the ease of drag + drop. Submit to general
                  contractors, send by email or get them signed
                  on the spot. Also find completed, closed projects, declined or rejected
                  proposals here.`,
          type: 'quote'
        }
      ]
    },
    {
      title: 'Project management',
      links: [
        {
          title: 'Clients',
          to: 'clients',
          icon: 'house-user',
          desc: `All leads, current clients, and former clients`,
          type: 'client'
        },
        {
          title: 'Projects',
          icon: 'house-building',
          to: 'projects',
          desc: 'See all booked or in-progress projects + manage project tasks.',
          starred: true
        },
        {
          title: 'Schedule',
          icon: 'chart-gantt',
          to: 'schedule',
          desc: 'View the schedule for your projects.',
          highlight: true,
          starred: true
        },
        {
          title: 'Subs + suppliers',
          to: 'vendors',
          icon: 'truck',
          desc: 'Create + manage vendors, subcontractors, sub trades, suppliers and wholesalers'
        }
      ]
    },
    {
      title: 'Financial services',
      icon: 'money-bill-transfer',
      links: [
        ...(isAdmin() || isSuperUser()
          ? [
              {
                title: 'Payments',
                to: 'payments',
                icon: 'money-bill-wave',
                desc: 'Setup your account and receive payments directly from your customers. Receive your money quickly, reliably and securely..'
              },
              ...(store.state.session.company.country_abbr === 'us'
                ? [
                    {
                      title: 'Business Account',
                      to: 'businessAccounts',
                      icon: 'money-bill-wave',
                      desc: 'Setup your business account and manage your business finances efficiently.'
                    }
                  ]
                : [])
            ]
          : []),
        ...(modules.value.invoice && modules.value.invoice === '1'
          ? [
              {
                title: 'Invoices',
                to: 'invoices',
                icon: 'file-invoice-dollar',
                desc: 'Create, send + track invoices.',
                type: 'invoice'
              }
            ]
          : [])
      ]
    },
    {
      title: 'Estimating',
      icon: 'boxes-stacked',
      links: [
        {
          title: 'AutoCost',
          to: 'autocost',
          icon: 'layer-plus',
          desc: 'Automate pricing for 130,000+ construction materials.',
          tag: 'New'
        },
        {
          title: 'Item + assembly catalog',
          to: 'items',
          icon: ['fal', 'box-open-full'],
          desc: `Manage an existing catalog of
                  20,000+ construction items, import
                  your own or add them one-by-one.
                  Build re-usable assemblies to save time when quoting.`
        },
        {
          title: 'Estimation settings',
          to: 'estimating_defaults',
          icon: 'calculator',
          desc: `Set default profit, company-wide terms of service, proposal settings,
                  minimum profit thresholds and any other
                  costing/pricing settings related to estimating.`
        },
        {
          title: 'Labor rates',
          to: 'labor',
          icon: 'user-helmet-safety',
          desc: `Once you've assigned a labor rate
                  type to your items you can change their
                  average costs here. This is for when you
                  have workers and you know their aggregated
                  average labor rates. If you're not sure,
                  you can use our built-in rates.`
        },
        {
          title: 'Custom dimensions',
          to: 'dimensions',
          icon: 'drafting-compass',
          desc: `Create, update and delete custom dimension types
                  that you create to link quanitites to formulas using custom dimensions
                  particular in your industry or company.`
        },
        {
          title: 'Construction stages',
          to: 'stages',
          icon: 'clipboard-list',
          desc: `Create, update and delete custom construction stages
                  so you can categorize your items, and plan for future schedule placement.`
        },
        {
          title: 'Tax rates',
          to: 'taxes',
          icon: 'percentage',
          desc: `Manage your custom sales tax settings, add complex taxes by
                  materials, labor, state/prov etc.`
        }
      ]
    },
    // {
    //   title: 'Files',
    //   icon: 'files',
    //   links: [
    //     {
    //       title: 'Bolster Drive',
    //       to: 'files',
    //       icon: 'folder-open',
    //       desc: 'Complete drag + drop file storage + management, in the cloud.'
    //     },
    //     // {
    //     //   title: 'Templates + presentations',
    //     //   to: 'templates',
    //     //   icon: 'file-code',
    //     //   desc: `Create + manage proposal presentations,
    //     //           email templates and
    //     //           document templates for quotes, invoices, receipts + more.`
    //     // }
    //   ]
    // },
    {
      title: 'Reporting',
      icon: 'chart-pie',
      links: [
        {
          title: 'Reports',
          to: 'reports',
          icon: 'table',
          desc: 'Visualize your proposals, clients and other data to track progress and measure success.'
        }
      ]
    },
    {
      title: 'Team management',
      icon: 'screen-users',
      links: [
        {
          title: 'Users',
          to: 'users',
          icon: 'users-gear',
          desc: 'Manage users, reset passwords, set roles + permissions.'
        },
        {
          title: 'Groups',
          to: 'groups',
          icon: 'users',
          desc: 'Create groups for your users for different teams or lines of business. You can filter by groups in the pipeline, clients, proposals and reports.'
        },
        {
          title: 'Roles',
          to: 'roles',
          icon: 'user-lock',
          desc: "Create, edit and manage user roles with sets of permissions assigned according to a user's position in your company."
        },
        {
          title: 'Vendors',
          to: 'vendors',
          icon: 'shop',
          desc: 'Create + manage vendors, subcontractors, sub trades, suppliers and wholesalers'
        }
      ]
    },
    {
      title: 'Integrations',
      icon: 'link-simple',
      links: [
        {
          title: 'CompanyCam',
          to: 'CompanyCam',
          icon: 'camera',
          desc: 'Integrate Bolster with your CompanyCam account to automatically create projects from quotes, import your existing projects, and access CompanyCam images direct from your Bolster quotes.',
          tag: 'New'
        },
        {
          title: 'Accounting export settings',
          to: 'accounting',
          icon: 'money-bills',
          desc: 'Manage accounting settings.'
        },
        {
          title: 'Webhooks',
          to: 'webhooks',
          icon: 'compress',
          desc: `Build an event-based connection from Bolster to
                  a webhook aggregator like Zapier or Integromat, or directly to any
                  web-based endpoint for leads, clients, quotes, change-orders and invoices.`
        }
      ]
    },
    ...(!store.state.session.user.user_is_admin
      ? []
      : [
          {
            title: 'Admin',
            icon: 'user-tie',
            links: [
              {
                title: 'Profile',
                to: 'profile',
                icon: 'id-card',
                desc: 'Manage your profile, personal information, picture + change your password.'
              },

              {
                title: 'Company settings',
                to: 'company',
                icon: 'shop',
                desc: 'Manage company details + information.'
              },
              {
                title: 'Client import tool',
                to: 'import/clients', // This is stupid dumb. We should refer to routes by their name. - Sera
                icon: 'cloud-arrow-down',
                desc: `Upload or import your client or lead list from
                  excel with this tool.`
              },
              {
                title: 'Item import tool',
                to: 'import/items', // This is stupid dumb. We should refer to routes by their name. - Sera
                icon: 'cloud-arrow-down',
                desc: `Upload or import your items list from
                  excel with this tool.`
              },
              {
                title: 'Vendor import tool',
                to: 'import/vendors', // This is stupid dumb. We should refer to routes by their name. - Sera
                icon: 'cloud-arrow-down',
                desc: 'Upload or import your vendor list from excel with this tool.'
              },

              ...(modules.value.quickbooks_export && modules.value.quickbooks_export === '1'
                ? [
                    {
                      title: 'Accounting export settings',
                      to: 'accounting',
                      icon: 'money-bills',
                      desc: 'Manage accounting settings.'
                    }
                  ]
                : []),
              ...(modules.value.subscription_management &&
              modules.value.subscription_management === '1'
                ? [
                    {
                      title: 'Billing management',
                      to: 'subscription',
                      icon: 'credit-card',
                      desc: 'Upgrade your subscription package. Manage your payment methods and view subscription invoice payments.',
                      highlight: true
                    }
                  ]
                : []),
              ...(store.state.session.authorizedUser &&
              store.state.session.authorizedUser.user_is_super_user
                ? [
                    {
                      title: 'Super user',
                      to: 'super',
                      icon: 'user-shield',
                      desc: 'See the super user dashboard.'
                    }
                  ]
                : []),
              {
                title: 'Terms of service',
                to: '/pub/legal',
                icon: 'handshake',
                desc: 'Terms and conditions, anti-money laundering and privacy policies.'
              }
            ]
          }
        ]),
    ...(store.state.help.hideHelp
      ? []
      : [
          {
            title: 'Support',
            icon: 'life-ring',
            links: [
              {
                title: 'Help resources',
                to: 'support',
                icon: 'question-circle',
                desc: 'Video tutorials, and support tickets.'
              }
            ]
          }
        ])
  ]

  return {
    features,
    topDropDown,
    bottomDropDown
  }
}

export { services }
