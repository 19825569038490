<template>
  <div
    class="relative"
    :class="{ hidden: !showItem && !isInQuoteEditor }"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
  >
    <div
      class="relative flex flex-col mb-1"
      :class="{
        ' bg-flame-white': !isAssembly,
        ' bg-transparent': isAssembly,
        'opacity-50': isInQuoteEditor && !showItem
      }"
    >
      <!--   Item flag   -->
      <div
        v-if="flagType"
        class="absolute flex items-center gap-2"
        :class="{
          'left-0.5 md:-left-4 xl:-left-5 top-2': !isAssembly,
          'left-0.5 md:-left-14 xl:-left-15 -top-2': isAssembly,
          hidden: disabled,
          'opacity-50': !showItem
        }"
      >
        <CostItemFlag :type="flagType" />
        <div
          class="flex gap-1 justify-start items-center"
          :class="[showItem ? itemStatusClass : 'text-gray-600']"
        >
          <font-awesome-icon :icon="['fas', itemStatusIcon]" fixed-width />
          <span class="text-sm">{{ itemStatus }}</span>
        </div>
      </div>
      <div
        class="border-2 border-transparent"
        :class="{
          '!pt-5': flagType
        }"
      >
        <div
          class="h-full flex flex-col-reverse md:flex-row justify-between selection-list-item px-4 py-2 md:py-2 mx:px-4 gap-2"
          :class="{
            'opacity-30 pointer-events-none': disabled
          }"
        >
          <div class="flex flex-col md:w-5/12 lg:w-7/12 gap-2">
            <!--    Item name    -->
            <div
              class="w-full flex flex-col justify-center h-full gap-2"
              :class="{
                'md:mt-10': flagType && !isGallery && !isTextBlock,
                'mt-16': flagType && addons && addons.length
              }"
            >
              <div
                v-if="name"
                class="flex flex-col justify-center text-lg md:text-xl font-medium md:font-normal gap-0 md:mt-0"
              >
                <StringField
                  v-if="isInQuoteEditor"
                  :disabled="!isInQuoteEditor"
                  class="!-ml-2 !-mt-2 !font-medium"
                  v-model="name"
                />
                <div v-if="!isInQuoteEditor">{{ name }}</div>

                <div
                  class="flex justify-start items-center gap-2 text-surface-400 text-sm"
                  v-if="showQuantityAndPrice"
                >
                  <template v-if="!included && optional"> {{ $t('Optional item') }} • </template>
                  <template v-if="optional && !variation">
                    <span v-if="included" class="">{{ $t('Included') }}</span>
                    <span v-else class="">{{ $t('Not included') }}</span>
                  </template>
                  <template v-if="included || !optional">
                    <template v-if="optional && !variation && showQty"> &nbsp;•&nbsp; </template>
                    <template v-if="showQty">
                      {{ $f.number(qty) }} <span class="lowercase">{{ units }}</span>
                    </template>
                    <template v-if="showPrice && showQty"> &nbsp;•&nbsp; </template>
                    <template v-if="showPrice">{{ price ? $(price) : $t('Included') }}</template>
                  </template>
                </div>
              </div>
              <!--    Quantity and price    -->

              <!--     Item description     -->
              <div
                class="text-base font-normal md:font-light py-4 text-surface-700"
                v-if="desc || object.aoProperties?.length"
              >
                <StringField
                  v-if="desc && isInQuoteEditor"
                  v-model="desc"
                  :disabled="!isInQuoteEditor"
                  multiline
                >
                  {{ desc }}
                </StringField>
                <div v-if="!isInQuoteEditor">
                  <div v-if="desc">{{ desc }}</div>
                </div>
                <PresentationProperties :object="object" />
              </div>
              <!--     Selections/Variations     -->
              <template v-if="showOptionality">
                <div
                  v-if="variation && showVariationSelector"
                  class="py-2 flex flex-col justify-start items-start gap-4"
                >
                  <div
                    class="flex flex-row justify-start items-start gap-2 cursor-pointer select-none"
                    @click="openVariations()"
                  >
                    <template
                      v-if="Object.keys(object.oVariations?.selectedItem?.variations ?? {}).length"
                    >
                      <div
                        v-for="trait of selectedTraits"
                        :key="trait.name"
                        class="flex flex-col justify-center items-center text-center gap-1"
                      >
                        <div
                          :class="[
                            'w-20 h-20 border-2 border-surface-400 flex justify-center items-center relative',
                            {
                              'rounded-full': trait.imageShape === 'circle',
                              'rounded-sm': trait.imageShape !== 'circle'
                            }
                          ]"
                        >
                          <img
                            v-if="trait.img"
                            :class="[
                              'h-full w-full',
                              {
                                'object-cover':
                                  !trait.backgroundSize || trait.backgroundSize === 'cover',
                                'object-contain': trait.backgroundSize === 'contain',
                                'object-fill': trait.backgroundSize === 'fill'
                              }
                            ]"
                            :src="imgLink(trait.img)"
                          />
                          <div
                            v-else
                            class="text-xs w-full h-full leading-none flex flex-col justify-center items-center"
                          >
                            <span class="text-[8pt] font-medium text-surface-400">{{
                              trait.vtypeName
                            }}</span>
                            {{ trait.name }}
                          </div>
                        </div>

                        <div
                          class="text-xs w-full h-full leading-none flex flex-col justify-center items-center"
                          v-if="trait.img"
                        >
                          <span class="text-[8pt] font-medium text-surface-400">{{
                            trait.vtypeName
                          }}</span>
                          {{ trait.name }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="text-sm text-blue-print flex gap-1 items-center cursor-pointer">
                        <font-awesome-icon icon="fas fa-circle" />{{
                          $t('Please make a selection')
                        }}
                      </div>
                    </template>
                  </div>
                  <Btn
                    class="w-full md:w-52 py-2"
                    :severity="
                      object.oVariations?.selectedItem?.variations ? 'tertiary' : 'primary'
                    "
                    :action="openVariations"
                  >
                    {{ $t('View options') }}
                  </Btn>
                </div>
                <!--     Optional       -->
                <div
                  class="flex items-center gap-1 justify-start md:w-fit py-2"
                  v-if="optional && !variation"
                >
                  <Checkbox v-model="included" />
                  <span v-if="included" class="">{{ $t('Included') }}</span>
                  <span v-else class="">{{ $t('Not included') }}</span>
                  <Btn size="md" :class="{ active: included }" link :action="toggleOptional">
                    <template v-if="!included"
                      >{{ $t('Add to estimate') }} - {{ $t('add') }} {{ $(includePrice) }}</template
                    >
                    <template v-if="included"
                      >{{ $t('Remove from estimate') }} - {{ $t('save') }} {{ $(price) }}</template
                    >
                  </Btn>
                </div>
                <!--        Color input        -->
                <div class="optional-toggle mt-6 py-2" v-if="input && colorInput">
                  <a @click="() => $refs.selectionColor.openSelector()">
                    <span class="toggle-title"> Color </span>
                    <span class="toggle-price">
                      <SelectionColor
                        ref="selectionColor"
                        :allow-deselect="false"
                        class="compact text-sm"
                        :value="colorInputData"
                      />
                    </span>
                  </a>
                </div>
                <!--        Text input       -->
                <div
                  v-if="input && textInput"
                  class="flex flex-col justify-start items-stretch py-2"
                >
                  <div class="toggle-title">
                    {{ textInputMessage }}
                  </div>
                  <Field
                    :class="{ 'validate-invalid': !textInputValue }"
                    class="w-full h-12 rounded-md"
                    v-model="textInputValue"
                    placeholder="Type here..."
                    :validate="{ required: textInputRequired }"
                  />
                </div>
              </template>
            </div>
          </div>
          <!--    Image container    -->
          <div
            class="flex !items-center min-h-full md:mt-0"
            :class="{ 'filter grayscale': isInQuoteEditor && !showItem, 'mt-4 md:mt-0': flagType }"
            v-if="!(addons && addons.length) && !isGallery"
          >
            <div
              class="rounded-lg min-h-48 h-48 overflow-hidden"
              :class="{ 'mt-14': flagType, 'min-w-72 max-w-72': !object.live_price_reference }"
              v-if="itemPictures.length || images.length || fileUrls.length"
            >
              <Image
                v-if="object.live_price_reference"
                preview
                :src="fileUrls[0]"
                image-class="object-cover cursor-pointer rounded-lg mix-blend-multiply max-w-48 max-h-full rounded-lg"
              />
              <Image
                v-else
                preview
                :src="fileUrls[0]"
                image-class="object-cover cursor-pointer rounded-lg mix-blend-multiply min-h-48"
                width="296"
                height="196"
              />
            </div>
          </div>
        </div>
        <div v-if="isGallery" class="p-3">
          <ItemGallery
            v-if="itemPictures.length || images.length || fileUrls.length"
            :images="fileUrls"
            :pt="{ indicators: 'hidden md:flex flex-row justify-center flex-wrap' }"
          />
        </div>
        <!--   Upgrades/Addons   -->
        <div
          class="optional-toggle w-full flex-col p-2 md:p-6 pt-2 bg-flame-white"
          v-if="showOptionality && addons && addons.length && included && !disabled"
          :class="{
            disabled,
            'mt-3': optional,
            'filter grayscale ': isInQuoteEditor && !showItem
          }"
        >
          <QuotePresentationAddonList
            class=""
            :title="false"
            :reference="object.refId"
            :optionals="false"
            :dimensions="dimensions"
            :showCurrent="true"
            :upgrades="true"
            :showHeadline="false"
            :value="addons"
          />
        </div>
      </div>

      <modal
        ref="variations"
        v-if="showOptionality"
        class="modal-mini"
        :size="desc || properties?.length || variationImages?.length ? 'lg' : 'md'"
        :scrollable="true"
        :full="false"
        :clickAway="true"
        :expandable="false"
      >
        <template #header>
          {{ this.name }}
        </template>
        <template #body>
          <div class="selection-body h-full w-full flex justify-center overflow-hidden">
            <div class="selection-container flex flex-col w-full max-w-screen-xl gap-6">
              <div class="flex flex-col lg:flex-row gap-6 justify-stretch items-start">
                <!--   Left     -->
                <div
                  class="flex flex-col justify-start items-start col-span-2 max-w-full gap-4 basis-1/2"
                  v-if="variationImages?.length"
                >
                  <template v-if="desc">
                    <p v-if="desc" class="font-light">{{ desc }}</p>
                  </template>
                  <div
                    class="mt-4"
                    v-if="properties && properties.length && properties[0] && properties[0][0]"
                  >
                    <span class="font-medium">{{ $t('Features') }}</span>
                    <PresentationProperties :object="object" />
                  </div>
                  <!--    Image gallery    -->
                  <div v-if="variationImages.length" class="overflow-hidden w-full">
                    <Galleria
                      :images="variationImages"
                      numVisible="6"
                      thumbnailsPosition="bottom"
                      hideArrows="true"
                      :pt="{
                        thumbnailContainer: 'w-fit pt-2',
                        thumbnailItem: 'overflow-hidden pr-2'
                      }"
                    />
                  </div>

                  <!--    Description    -->
                </div>

                <!--    Right    -->
                <div class="basis-1/2 grow flex flex-col justify-start items-center">
                  <div
                    class="mt-4"
                    v-if="properties && properties.length && properties[0] && properties[0][0]"
                  >
                    <span class="font-medium">{{ $t('Features') }}</span>
                    <PresentationProperties :object="object" />
                  </div>
                  <CostItemVariationSelector
                    v-bind="$props"
                    :reference="refId"
                    :store="store"
                    :deselectOnDestroy="false"
                    :object="object"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <!--     CTA     -->
          <div
            class="flex flex-col lg:flex-row gap-2 justify-end md:justify-between items-center w-full h-full"
          >
            <span class="text-xl font-medium flex justify-end items-center">
              <span v-if="showQty">
                {{ $f.number(qty) }} <span class="lowercase">{{ units }}</span>
              </span>
              <span v-if="showQty && showPrice" class="text-surface-400">&nbsp;•&nbsp;</span>
              <span v-if="showPrice">{{ $(price, 2) }}</span>
            </span>
            <div class="flex gap-2">
              <Btn severity="tertiary-borderless" size="lg" @click="closeVariations()">
                Cancel
              </Btn>
              <Btn severity="primary" size="lg" @click="closeVariations()">
                Confirm selections
              </Btn>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!--  Hidden item banner  -->
    <div
      class="absolute text-pitch-black inset-0 flex items-center justify-center"
      :class="{
        'block !opacity-100': isInQuoteEditor && !showItem,
        hidden: (!isInQuoteEditor && !showItem) || showItem
      }"
    >
      <p class="bg-surface-400 text-flame-white px-4 py-2 shadow rounded">
        <font-awesome-icon :icon="['fas', 'eye-slash']" class="mr-1" />
        Item hidden from the client
      </p>
    </div>
    <!-- Visibility controls -->
    <VisibilityControls
      :class="[
        'transition-all',
        {
          'opacity-0': !hovering
        }
      ]"
      :isInQuoteEditor="isInQuoteEditor"
      :object="object"
      :visibility="visibility"
    />
  </div>
</template>

<script>
import { getCurrentInstance, computed, ref, watch } from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import CostItemVariationSelector from '../item/CostItemVariationSelector.vue'
import ItemInputsMixin from '../item/ItemInputsMixin'
import SelectionColor from '../../ui/SelectionColor/SelectionColor.vue'
import QuotePresentationAddonList from './QuotePresentationAddonList.vue'
import ItemPicturesMixin from './ItemPicturesMixin'
import PresentationProperties from './PresentationProperties.vue'
import CostItemFlag from '@/components/quote/presentation/CostItemFlag.vue'
import SelectionItemMixin from '@/components/quote/presentation/SelectionItemMixin'
import useItemReviewal from '@/components/composables/ItemReviewal'
import StringField from '@/components/ui/Calculator/StringField.vue'
import ItemGallery from '@/components/quote/presentation/ItemGallery.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import Image from 'primevue/image'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'
import ItemVariations from '@/components/composables/ItemVariations'
import VisibilityControls from '@/components/quote/presentation/VisibilityControls.vue'
import _ from '../../../../imports/api/Helpers.js'

export default {
  name: 'SelectionsItem',
  mixins: [TranslationMixin, ItemInputsMixin, SelectionItemMixin, ItemPicturesMixin],
  data() {
    return {
      hovering: false,
      fullRemoval: [],
      notOptional: [],
      showVisOptions: 0,
      visoptions: [
        {
          key: 'price',
          name: 'Price',
          desc: 'If hidden, the price will be hidden from the buyer.'
        },
        {
          key: 'qty',
          name: 'Quantity',
          desc: 'If hidden, the quantity will be hidden from the buyer.'
        }
      ],
      assemblyEmphasis: 0
    }
  },
  watch: {},
  computed: {
    included: {
      get() {
        return (!this.isAssembly && this.object.cost_item_is_included) ||
          (this.isAssembly && this.object.assembly_is_included)
          ? 1
          : 0
      },
      set() {
        this.toggleOptional()
      }
    },
    selectedTraits() {
      const chosen = this.object.oVariations?.selectedItem?.variations ?? {}

      return Object.keys(chosen).map((vtypeName) => {
        const vt = this.object.oVariations?.variationTypes.find((vtype) => vtype.name === vtypeName)
        const trait = vt?.traits.find((trait) => trait.name === chosen[vtypeName])

        return {
          ...vt,
          vtypeName,
          ...trait,
          img: trait?.imgs?.[0] || null
        }
      })
    },
    name: {
      get() {
        // If the item is a selection it could have an option group name
        const groupName = this.getOptionGroupMeta(this.object)?.optionGroupName ?? null
        const itemName = this.isAssembly ? this.object.assembly_name : this.object.cost_type_name
        // groupName should override itemName if it exists
        return groupName || itemName
      },
      set(value) {
        // If the item is a selection it could have an option group name
        const hasGroupName = this.object.oMeta?.optionGroupName
        const itemNameField = this.isAssembly ? 'assembly_name' : 'cost_type_name'
        const fieldName = hasGroupName ? 'oMeta' : itemNameField
        const nameValue = hasGroupName ? { ...this.object.oMeta, optionGroupName: value } : value
        this.setField(fieldName, nameValue)
      }
    },
    desc: {
      get() {
        // If the item is a selection it could have an option group desc
        const groupDesc = this.getOptionGroupMeta(this.object)?.optionGroupDesc ?? null
        const itemDesc = this.object.cost_type_desc || this.object.quote_notes || ''
        // groupDesc should override itemDesc if it exists
        return groupDesc || itemDesc
      },
      set(value) {
        // If the item is a selection it could have an option group desc
        const hasGroupDesc = this.object.oMeta?.optionGroupDesc
        const fieldName = hasGroupDesc ? 'oMeta' : 'cost_type_desc'
        const descValue = hasGroupDesc ? { ...this.object.oMeta, optionGroupDesc: value } : value
        this.setField(fieldName, descValue)
      }
    },
    inheritedPriceVisibility() {
      const inheritedVisibility =
        this.object.cost_item_show_itemized_prices || this.object.quote_show_itemized_prices

      const isAssembly = (this.object.item_type || this.object.type) === 'assembly'
      if (isAssembly) {
        const inheritedAssemblyVisibility =
          this.presentationSettings?.showAssemblyPrices &&
          this.presentationSettings?.showItemizedPrices
        return inheritedVisibility && inheritedAssemblyVisibility
      }

      const inheritedItemVisibility =
        this.presentationSettings?.showCostItemPrices &&
        this.presentationSettings?.showItemizedPrices
      return inheritedVisibility && inheritedItemVisibility
    },
    visibility() {
      const inheritedSettings = {
        price: this.inheritedPriceVisibility,
        qty: this.presentationSettings?.showQuantities,
        isVisible: 1
      }
      if (this.presentationSettings?.forceTemplateSettings) {
        return inheritedSettings
      }
      return (this.object.oViewOptions && this.object.oViewOptions.pres) || inheritedSettings
    },
    showPrice() {
      return this.visibility.price
    },
    showQty() {
      return this.visibility.qty
    },
    showItem() {
      return this.visibility.isVisible
    },
    // if all variations are locked or hidden, selector should not be shown
    showVariationSelector() {
      if (!this.object) return true
      const isCompanyUser = this.$store.getters.isCompanyUser
      const isInternalSelection = (this.object?.oMeta?.selectionAudience || 'client') === 'company'
      if (isInternalSelection && !isCompanyUser) return false

      // filter through variation types to see if one is not hidden or locked
      let response = false
      Object.values(this.object.oVariations?.variationTypes || []).forEach((variationType) => {
        // if response is already true or variationTypeOptions does not exist - skip
        if (response) return

        // if a variationType is not locked or hidden change response to show selector
        if (!variationType.hidden && !variationType.locked) {
          response = true
        }
      })
      return response
    },
    flagType() {
      if (this.optional) return 'Optional'
      if ((this.addons && this.addons.length) || this.variation) return 'Selections'
      return null
    },
    showQuantityAndPrice() {
      return (
        (this.qty || !this.included) &&
        (this.showQty || this.showPrice) &&
        !this.isTextBlock &&
        !this.isGallery
      )
    },
    itemProperties() {
      return this.object.aoProperties.filter((property) => {
        const [attribute, value] = property
        return attribute.trim() !== '' || value.trim() !== ''
      })
    }
  },
  methods: {
    handleMouseout() {
      this.hovering = false
    },
    handleMouseover() {
      this.hovering = true
    },
    toggleOptional() {
      return this.$store.dispatch('Quote/toggleIncluded', {
        refId: this.refId,
        store: 'Quote',
        included:
          (!this.isAssembly && this.object.cost_item_is_included) ||
          (this.isAssembly && this.object.assembly_is_included)
            ? 0
            : 1,
        object: this.object
      })
    },
    formatCurrencySymbol,
    $: formatCurrencySymbol,
    previewImage() {
      this.$refs.gallery.preview(this.fileUrls[0])
    },
    modifyAddons(addons) {
      if (!this.$store.getters.isGuestUser) {
        this.setField('aoAddons', addons)
      }
    },
    async showOptionalModal() {
      await this.$store.dispatch('Quote/openOptionalModal', {
        itemName: this.name,
        description: this.desc,
        object: this.object,
        thumbs: this.fileUrls,
        properties: this.properties,
        refId: this.object.refId,
        go: false
      })
    },
    getOptionGroupMeta(obj) {
      if (obj.oMeta && typeof obj.oMeta === 'object' && Object.keys(obj.oMeta).length) {
        return obj.oMeta
      }
      if (Array.isArray(obj.aoAddons)) {
        for (const addon of obj.aoAddons) {
          if (
            addon?.bulk?.oMeta &&
            typeof addon.bulk.oMeta === 'object' &&
            Object.keys(addon.bulk.oMeta).length
          ) {
            return addon.bulk.oMeta
          }
        }
      }

      return null
    }
  },
  components: {
    PresentationProperties,
    QuotePresentationAddonList,
    SelectionColor,
    CostItemVariationSelector,
    CostItemFlag,
    StringField,
    Galleria,
    ItemGallery,
    Image,
    VisibilityControls
  },
  props: {
    showOptionality: {
      default: true
    },
    maxHeight: {
      default: '30em'
    },
    disabled: {
      default: false
    },
    presentationSettings: {
      type: Object
    },
    isInQuoteEditor: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { reviewalItems } = useItemReviewal()
    const instance = getCurrentInstance()
    const refId = computed(() => props.refId)
    const store = computed(() => props.store)

    const { selectedVariationTraitImages, object: rObject } = ItemVariations.useItemVariations({
      refId,
      store,
      object: instance.proxy.object,
      type: 'CostItem'
    })

    const galleryActiveIndex = ref(0)
    const itemReviewal = computed(() =>
      reviewalItems.value.find((reviewal) => reviewal.itemId === instance.proxy.object.refId)
    )
    const isItemViewed = computed(() => {
      return itemReviewal.value && itemReviewal.value.isReviewed
    })
    const itemStatus = computed(() => {
      return isItemViewed.value ? 'Viewed' : 'Not yet viewed'
    })
    const itemStatusIcon = computed(() => {
      return isItemViewed.value ? 'circle-check' : 'circle'
    })
    const itemStatusClass = computed(() => {
      return isItemViewed.value ? 'text-surface-400' : 'text-blue-print'
    })
    const itemImages = computed(() => {
      let aoImages = []
      let fileIds = []

      if (rObject.value?.aoImages) {
        aoImages = rObject.value.aoImages.map((image) => {
          if (rObject.value.live_price_reference) {
            return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${image}`
          }
          return image.file_url
        })
      }

      if (rObject.value?.file_ids) {
        fileIds =
          _.makeArray(rObject.value?.file_ids).map((id) =>
            c.link(`file/view/${id}`, {}, true, _.getStorage('scope'))
          ) ?? []
      }

      return [...aoImages, ...fileIds]
    })
    const variationImages = computed(() => [
      ...(itemImages.value || []),
      ...(selectedVariationTraitImages.value || [])
    ])

    const scope = _.getStorage('scope')
    const imgLink = (id) => c.link(`file/view/${id}`, { max: 100 }, true, scope)
    watch(variationImages, () => (galleryActiveIndex.value = 0))

    return {
      galleryActiveIndex,
      itemReviewal,
      isItemViewed,
      itemStatus,
      itemStatusIcon,
      itemStatusClass,
      variationImages,
      itemImages,
      imgLink
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.selection-list-item {
  &:not(:last-child) {
    padding-bottom: 0;
  }
}
.selection-list-item + .selection-list-item {
  border-top: 1px solid $cool-gray-400;
}

.selection-graphic,
.selection-text {
  @media (max-width: 576px) {
    max-width: 100vw;
  }
  @media (max-width: 768px) {
    max-width: 100vw;
  }
}

.selection-text {
  flex: 2;
}

@media (max-width: 992px) {
  .selection-text {
    flex: 2.5;
  }
}

.selection-list-item {
  @media (max-width: 768px) {
    > div {
      flex-direction: column;
    }
  }
}

#app.small-format {
  .selection-list-item {
    .selection-graphic {
      text-align: center;
      margin: 0 auto;
      max-width: 100vw;
    }
    .selection-text {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}
</style>
