<template>
  <CardListItem
    :condensed="condensed"
    :seamless="seamless"
    class="list-item--wrapper flex flex-col items-center justify-center h-full"
    :class="[
      classes,
      table
        ? 'table border-x rounded-none border-t border-cool-gray-300 first:rounded-t last:rounded-b last:border-b shadow-none'
        : computedClasses
    ]"
  >
    <div class="w-full h-full flex justify-between">
      <span class="w-full flex list-item--content">
        <slot />
      </span>
      <span class="list-item--right flex items-center justify-end">
        <slot name="right" />
        <BtnBar
          v-if="actions"
          severity="tertiary"
          :link="true"
          :collapse="true"
          btn-icon="ellipsis"
          btnClass="!border-none"
          :actions="actions"
        />
      </span>
    </div>
    <div v-if="$slots.footer" class="list-item--footer p-3 w-full">
      <slot name="footer" />
    </div>
  </CardListItem>
</template>
<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  actions: {
    type: Array,
    default: () => []
  },
  condensed: {
    type: Boolean,
    default: false
  },
  stacked: {
    type: Boolean,
    default: false
  },
  seamless: {
    type: Boolean,
    default: false
  },
  table: {
    type: Boolean,
    default: false
  },
  classes: {
    type: String
  }
})

const computedClasses = computed(() => {
  if (props.stacked) return 'mb-0 !py-1'
  if (props.seamless) return 'mb-3 !py-3'
  if (props.condensed)
    return 'border-x rounded-none border-t border-cool-gray-300 first:rounded-t last:rounded-b last:border-b shadow-none !py-3'
  return ''
})
</script>

<style lang="scss" scoped>
.list-item--wrapper {
  @media (min-width: 768px) {
    &.table {
      @apply p-0;
      .list-item--content {
        > * {
          > *:not(:last-child) {
            @apply border-r border-cool-gray-300;
          }
        }
      }
    }
  }
}
</style>
