import { computed, ref, watch, onBeforeUnmount, onMounted } from 'vue'

import Basics from './quote/guides/1-basics.js'
import Settings from './quote/guides/3-settings.js'
import Progress from './quote/guides/4-progress.js'
import Item from './quote/guides/5-item.js'
// import Assembly from './quote/guides/6-assembly.js'
// import Catalog from './quote/guides/7-catalog.js'
// import Dimension from './quote/guides/8-dimension.js'
// import Subcontracted from './quote/guides/9-subcontracted.js'
// import Materials from './quote/guides/10-materials.js'
// import Labor from './quote/guides/11-labor.js'
// import Fee from './quote/guides/12-fee.js'
// import Task from './quote/guides/13-task.js'
// import Text from './quote/guides/14-text.js'
// import Gallery from './quote/guides/15-gallery.js'
import OnboardingMilestones from '@/components/composables/OnboardingMilestones.js'

export default {
  useGuide(args) {
    const { refSheet, setMeta, setFields, $store, props } = args

    let guides = ref({
      Basics,
      Settings,
      Progress,
      Item
      // Assembly,
      // Catalog,
      // Dimension,
      // Subcontracted,
      // Materials,
      // Labor,
      // Fee,
      // Task,
      // Text,
      // Gallery
    })
    const { isMilestoneComplete } = OnboardingMilestones.useOnboardingMilestones()
    const isGuideDone = (id) => isMilestoneComplete(`guide-${id}`)

    onMounted(() => {
      for (let key of Object.keys(guides.value)) {
        guides.value[key].done = isGuideDone(key)
      }
    })

    const setGuide = (id) => {
      guide.value = guides.value[id]
    }

    const rowOffset = ref(0)

    const guide = ref(guides.value.Basics)

    const step = ref(null)
    const currentStep = computed(() => guide.value.steps[step.value])
    const tooltip = ref(null)

    const currentRow = computed(() => {
      if (currentStep.value?.element) return null
      return rowOffset.value + currentStep.value?.cell?.[1] ?? 0
    })
    const currentCol = computed(() => {
      if (currentStep.value?.element) return null
      const stepCol = currentStep.value?.cell?.[0] ?? 0

      if (typeof stepCol === 'string') {
        const [section] = stepCol.split(':')
        switch (section) {
          case 'gutter':
            return -1
          default:
            return refSheet.value.dataSheets[0].columns.findIndex((col) => col.field === stepCol)
        }
      }

      return stepCol
    })

    const handleCommit = (changes) => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext && currentStep.value?.triggerNext !== 'commit') return
      if (changes.some((ch) => ch.column === currentCol.value)) throttleNext()
    }

    const handleMoved = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'movedRows') return

      throttleNext()
    }
    const handleAdded = ({ rowIndexes: [rowIndex] }) => {
      ;[200, 300].map((delay) =>
        c.throttle(
          async () => {
            await setMeta({
              refId: refSheet.value.rowsMap[rowIndex].id,
              row: rowIndex,
              meta: {
                tutorialItem: true
              }
            })
          },
          { delay, key: delay }
        )
      )

      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'addedRows') return

      throttleNext()
    }
    const turnHandler = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'turnItemIntoAssembly') return

      throttleNext()
    }

    const handleSuperHeaderExpanded = () => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext !== 'superHeaderExpanded') return

      throttleNext()
    }

    // Avoid overstimulating
    const throttleNext = () => c.throttle(() => nextStep(), { delay: 200 })

    const handleEnter = (e) => {
      if (step.value === null) return
      if (currentStep.value?.triggerNext && currentStep.value?.triggerNext !== 'commit') return

      // Check for Enter, Tab, or Right Arrow keys
      if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'ArrowRight') {
        throttleNext()
      }
      // Check for Left Arrow key
      else if (e.key === 'ArrowLeft') {
        prevStep()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleEnter)
      refSheet.value.$on('changes', handleCommit)
      refSheet.value.$on('addedRows', handleAdded)
      refSheet.value.$on('movedRows', handleMoved)
      refSheet.value.$on('turnItemIntoAssembly', turnHandler)
      refSheet.value.$on('superHeaderExpanded', handleSuperHeaderExpanded)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleEnter)
      refSheet.value.$off('changes', handleCommit)
      refSheet.value.$off('addedRows', handleAdded)
      refSheet.value.$off('movedRows', handleMoved)
      refSheet.value.$off('turnItemIntoAssembly', turnHandler)
      refSheet.value.$off('superHeaderExpanded', handleSuperHeaderExpanded)
    })

    const completed = ref(false)
    const nextStep = () => {
      const nextStep = step.value + 1

      if (nextStep >= guide.value.steps.length) {
        completed.value = true
        endGuide()
      }

      step.value = nextStep
    }

    const prevStep = () => {
      let nextStep = step.value - 1

      if (nextStep < 0) {
        nextStep = 0
      }

      step.value = nextStep
    }

    const goToStep = async (newStep) => {
      const step = guide.value.steps[newStep]

      if (!step) return

      if (currentCol.value !== null && currentRow.value !== null) {
        await moveCell(currentCol.value, currentRow.value, step.focus ?? true)
      }
    }
    watch(step, (newStep) => {
      if (newStep !== null) {
        goToStep(newStep)
      }
    })

    const getCellPosition = (col, row, position = 'bottom') => {
      let left = 0
      let top = 0
      let bottom = 0
      let right = 0
      let height = 0
      let width = 0
      if (col === -1) {
        // row header
        left = refSheet.value.rowHeadingBoundingRect.x
        width = refSheet.value.rowHeadingBoundingRect.width
      } else if (col === -2) {
        // gutter
        left = refSheet.value.collapseGroupGutterPositionsVisible.value[0].x
        width = 100
      } else {
        let visible = refSheet.value.cellPositionsVisible[`${row}`]?.[`${col}`] ?? false

        if (!visible) {
          refSheet.value.scrollToY(row)
          refSheet.value.scrollToX(col)
          visible = refSheet.value.cellPositionsVisible[`${row}`]?.[`${col}`] ?? false
        }

        left = visible.x
        width = visible.width
      }

      let vertVis = refSheet.value.rowPositionsVisible[`${row}`] ?? false
      if (!vertVis) {
        refSheet.value.scrollToY(row)
        vertVis = refSheet.value.rowPositionsVisible[`${row}`] ?? false
      }
      height = vertVis.height
      top = vertVis.y
      bottom = top + height
      right = left + width

      const element = {
        top,
        height,
        left,
        width,
        bottom,
        right
      }

      if (position === 'bottom') {
        top = bottom + 8
        bottom = null
      } else if (position === 'right') {
        top -= 10
        left = right - 1
      } else if (position === 'left') {
        top -= 10
        left = left - 400 - 4
      } else if (position === 'top') {
        top = top - 100
        height = 100
      }

      return {
        top,
        left,
        bottom,
        right,
        width,
        height,
        element
      }
    }

    const getSuperHeaderPosition = (title, position = 'bottom') => {
      let index = refSheet.value.dataSheets[0].superHeaders.findIndex((shi) => shi.title === title)
      index = `${index < 0 ? 0 : index}`

      // If not visible, it won't be in the list, so scroll to its range
      if (!(index in refSheet.value.columnHeadingPositions[0].superHeadings)) {
        // scroll to
        const col = refSheet.value.dataSheets[0].superHeaders[+index].span.flat()[0]
        refSheet.value.scrollToX(col)
      }

      // should be visible now if it exists
      let {
        y: top,
        height,
        x: left,
        width,
        bottom = top + height,
        right = left + width
      } = refSheet.value.columnHeadingPositions[0].superHeadings[index]

      const element = {
        top,
        height,
        left,
        width,
        bottom,
        right
      }

      if (position === 'bottom') {
        top = bottom + 12
        bottom = null
        left = left - 10
      } else if (position === 'right') {
        top -= 10
        left = right - 1
      } else if (position === 'left') {
        top -= 10
        left = left - 400 - 4
      } else if (position === 'top') {
        top = top - 100
        height = 100
      }

      return {
        top,
        left,
        bottom,
        right,
        width,
        height,
        element
      }
    }

    const getDomElementPosition = (selector, position = 'bottom') => {
      const el = document.querySelector(selector)

      // should be visible now if it exists
      let {
        top,
        height,
        left,
        width,
        bottom = top + height,
        right = left + width
      } = el.getBoundingClientRect()

      const element = {
        top,
        height,
        left,
        width,
        bottom,
        right
      }

      if (position === 'bottom') {
        top = bottom + 12
        bottom = null
        left = left - 10
      } else if (position === 'right') {
        top -= 10
        left = right - 1
      } else if (position === 'left') {
        top -= 10
        left = left - 400 - 4
      } else if (position === 'top') {
        top = top - 100
        height = 100
      }

      return {
        top,
        left,
        bottom,
        right,
        width,
        height,
        element,
        fixed: true
      }
    }

    const stepCoords = computed(() => {
      if (!currentStep.value) return {}

      if (currentStep.value.cell) {
        return getCellPosition(currentCol.value, currentRow.value, currentStep.value.position)
      }

      const [elementType, identifier] = currentStep.value.element

      if (elementType === 'superHeader') {
        return getSuperHeaderPosition(identifier)
      }

      // dom elements
      if (/\[.*?\]/.test(elementType)) {
        return getDomElementPosition(elementType, currentStep.value.position)
      }

      return {}
    })

    const tooltipText = computed(() => currentStep.value?.tooltip ?? '')
    const tooltipStyle = computed(() => {
      if (!tooltipText.value) {
        return {
          display: 'none'
        }
      }

      const { top, left, fixed = false } = stepCoords.value

      const vert = top ? 'top' : 'bottom'
      const horiz = left ? 'left' : 'right'

      const [offsetX = 0, offsetY = 0] = currentStep.value?.offset ?? [0, 0]

      return {
        display: 'flex',
        position: fixed ? 'fixed' : 'absolute',
        [vert]: `${stepCoords.value[vert] + offsetY}px`,
        [horiz]: `${stepCoords.value[horiz] + offsetX}px`,
        maxWidth: '400px',
        minWidth: `${stepCoords.value.width}px`,
        height: 'fit-content'
      }
    })
    const elementHighlightStyle = computed(() => {
      const { element = null, fixed = false } = stepCoords.value ?? {}
      if (!tooltipText.value || element === null) {
        return {
          display: 'none'
        }
      }

      return {
        display: 'flex',
        position: fixed ? 'fixed' : 'absolute',
        top: `${element.top}px`,
        left: `${element.left}px`,
        bottom: `${element.bottom}px`,
        right: `${element.right}px`,
        width: `${element.width}px`,
        height: `${element.height}px`
      }
    })

    const moveCell = async (col, row, focus = true) => {
      refSheet.value.moveCell(col, row)
      await c.throttle(() => {}, { delay: 500 })
      if (focus) refSheet.value.focusCell()
    }

    const startGuide = async (id = 'Basics', offset = null) => {
      setGuide(id)
      completed.value = false
      await guide.value?.setupSheet?.(refSheet.value, $store, props, setFields)
      rowOffset.value = offset === null ? refSheet.value.rowsMap.length - 1 : offset
      step.value = 0
    }

    const endGuide = () => {
      step.value = null
      tooltip.value = null
    }

    return {
      guide,
      guides,
      step,
      currentStep,
      startGuide,
      endGuide,
      tooltipStyle,
      tooltipText,
      prevStep,
      nextStep,
      completed,
      isGuideDone,
      elementHighlightStyle
    }
  }
}
