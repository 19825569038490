<template>
  <component
    v-if="item && Object.keys(item).length > 0"
    :is="
      item.item_type === 'assembly' || item.type === 'assembly'
        ? 'ItemizedListAssembly'
        : 'ItemizedListCostItem'
    "
    :item="item"
    :parentRefId="parentRefId"
    :normalized="normalized"
    :selected="selected"
    :readonly="readonly"
    :expanded="expanded"
    :invoiced="invoiced"
    :refId="refId"
  />
</template>

<script>
import ItemizedListMixin from './ItemizedListMixin'

export default {
  name: 'ItemizedListItem',
  data() {
    return {
      objectLocal: this.item
    }
  },
  components: {},
  props: {},
  mixins: [ItemizedListMixin]
}
</script>
