<template>
  <div class="activity-channel-tabs flex justify-center items-center">
    <SelectButton
      v-model="currentChannelId"
      :options="channelOptions"
      option-value="value"
      :multiple="false"
      :allowEmpty="false"
    >
      <template #option="{ option }">
        <div class="channel-tab flex overflow-hidden">
          <div class="w-full text-left mr-8">
            <p class="font-medium">{{ option.text }}</p>
            <p class="text-xs text-surface-400">
              {{ option.channel?.channel_members?.items?.length }} users in this channel
            </p>
          </div>
          <AvatarGroup v-if="option.channel?.channel_members?.items?.length">
            <Avatar
              v-for="(member, index) in option.channel.channel_members.items.slice(0, 3)"
              shape="circle"
              :label="`${member.user_fname[0] || '?'}`"
              :pt="{
                root: 'relative -r-2 h-8 w-8 inline-flex items-center justify-center rounded-full bg-surface-0 border -ml-5'
              }"
              :key="index"
            />
            <Avatar
              v-if="option.channel.channel_members.items.length > 3"
              shape="circle"
              :label="`+${option.channel.channel_members.items.length - 3}`"
              :pt="{
                root: 'h-8 w-8 inline-flex items-center justify-center rounded-full border bg-primary text-flame-white -ml-5 z-10'
              }"
            />
          </AvatarGroup>
        </div>
      </template>
    </SelectButton>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useActivityChannels } from '@/components/composables/ActivityChannels'
import SelectButton from 'primevue/selectbutton'
import AvatarGroup from 'primevue/avatargroup'
import Avatar from 'primevue/avatar'
SelectButton.compatConfig = { MODE: 3, ATTR_FALSE_VALUE: false }

// ======== Composables ======== //
const store = useStore()
const { getFormattedChannelName } = useActivityChannels()
// ====================== //

// ======== Props ======== //
const props = defineProps({
  channelId: {
    type: String,
    required: true
  },
  channelIds: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['channelTabSelected'])
// ====================== //

// ======== Computed ======== //
const currentChannelId = computed({
  get: () => props.channelId,
  set: (value) => onChannelTabSelected(value)
})
const channels = computed(() =>
  props.channelIds.map((channelId) => store.state.activityChat.channels[channelId])
)
const channelOptions = computed(() => {
  return channels.value.map((channel) => ({
    text:
      channel?.channel_type === 'QUOTE'
        ? 'Internal only'
        : channel?.channel_type === 'QUOTE_CLIENT'
          ? 'With client'
          : getFormattedChannelName(channel),
    value: channel?.channel_id,
    channel
  }))
})
// ====================== //

// ======== Methods ======== //
const onChannelTabSelected = (channelId) => {
  emit('channelTabSelected', channelId)
}
// ====================== //
</script>
