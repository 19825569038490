<template>
  <transition name="p-message" appear v-bind="ptm('transition')">
    <div
      v-show="visible"
      :class="cx('root')"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      v-bind="ptm('root')"
      data-pc-name="message"
    >
      <slot v-if="$slots.container" name="container" :onClose="close" :closeCallback="close"></slot>
      <div v-else :class="cx('wrapper')" v-bind="ptm('wrapper')">
        <slot name="messageicon" class="p-message-icon">
          <font-awesome-icon
            :icon="iconComponent"
            :class="[cx('icon'), icon]"
            v-bind="ptm('icon')"
          />
        </slot>
        <div class="p-message-text" :class="cx('text')" v-bind="ptm('text')">
          <slot></slot>
        </div>
        <button
          v-if="closable"
          v-ripple
          :class="cx('closeButton')"
          :aria-label="closeAriaLabel"
          type="button"
          @click="close"
          v-bind="{ ...closeButtonProps, ...ptm('button'), ...ptm('closeButton') }"
        >
          <slot name="closeicon">
            <font-awesome-icon :icon="['far', 'xmark']" />
          </slot>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import Ripple from 'primevue/ripple'
import BaseMessage from 'primevue/message/BaseMessage.vue'

export default {
  name: 'Message',
  extends: BaseMessage,
  emits: ['close', 'life-end'],
  timeout: null,
  data() {
    return {
      visible: true
    }
  },
  watch: {
    sticky(newValue) {
      if (!newValue) {
        this.closeAfterDelay()
      }
    }
  },
  mounted() {
    if (!this.sticky) {
      this.closeAfterDelay()
    }
  },
  methods: {
    close(event) {
      this.visible = false
      this.$emit('close', event)
    },
    closeAfterDelay() {
      setTimeout(() => {
        this.visible = false
        this.$emit('life-end')
      }, this.life)
    }
  },
  computed: {
    iconComponent() {
      return {
        automation: ['fa', 'sparkles'],
        info: ['fas', 'circle-info'],
        success: ['far', 'check'],
        warn: ['fas', 'triangle-exclamation'],
        error: ['fas', 'circle-exclamation']
      }[this.severity]
    },
    closeAriaLabel() {
      return this.$primevue.config.locale.aria ? this.$primevue.config.locale.aria.close : undefined
    }
  },
  directives: {
    ripple: Ripple
  }
}
</script>
