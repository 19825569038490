<script setup>
import { computed, defineProps, defineModel, defineEmits } from 'vue'
import { useStore } from 'vuex'

const choice = defineModel()

const emit = defineEmits(['choice'])

const $store = useStore()
const props = defineProps({
  /**
   * [{
   *   value: '', // required
   *   title: '', // required
   *   icon: '', // optional
   *   desc: '', // optional
   *   html: '', // optional (will override title and desc)
   * }, ...]
   */
  choices: {
    type: Array,
    required: true
  },
  icon: {
    type: String,
    required: false,
    default: null
  },
  /**
   * ie: Please make a choice
   */
  message: {
    type: String,
    default: ' '
  },
  allowDeselect: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: 'Make choice...'
  },
  headless: {
    type: Boolean,
    default: false
  }
})

const choose = async () => {
  const chosenValue = await $store.dispatch('modal/asyncConfirm', {
    choices: props.choices,
    message: props.message
  })
  if (chosenValue !== null || props.allowDeselect) {
    choice.value = chosenValue
    setTimeout(() => emit('choice', chosenValue))
  }
}

const selectedChoice = computed(() => props.choices.find((ch) => ch.value === choice.value))
const text = computed(() => selectedChoice.value?.title ?? props.placeholder)
</script>

<template>
  <slot
    :text="text"
    :value="choice"
    :choice="selectedChoice"
    :choose="choose"
    v-if="!props.headless"
  >
    <Btn :action="choose" severity="tertiary" size="lg"
      ><font-awesome-icon :icon="props.icon" v-if="props.icon" />{{ text }}
      <font-awesome-icon icon="chevron-down"
    /></Btn>
  </slot>
</template>
