import PDFGenerator from '../../mixins/PDFGenerator'
import TranslationMixin from '../presentation/languages/TranslationMixin'
import { useFileService } from '@/components/composables/UseFileService'
const { getPreSignedPost, getFileKey } = useFileService()

export default {
  data() {
    return {
      ordered: null,
      currentParent: null,
      artificialMultiplier: 1,
      sectionMargin: 10,
      page: 1,
      itemMarginTop: 0,
      maxWidth: 0.7,
      marginTop: 0,
      pdfFiles: null,
      headerHeight: 0,
      currentParentRefId: null,
      changeOrderList: [],
      imageDim: {
        w: 20,
        h: 20
      },
      overrides: {
        hideTite: false,
        hideImages: true,
        hideDescription: false,
        hideLocation: true,
        hideNotes: false,
        hideInputs: false,
        hideProperties: true,
        hidePrice: false
      }
    }
  },

  mounted() {
    const theme = this.parseStyleVariables()
    this.setTheme(theme)
    this.setupItemsForPDF()
    this.renderDoc()
  },

  mixins: [PDFGenerator, TranslationMixin],

  computed: {
    set() {
      return this.normalized || this.norm
    }
  },

  methods: {
    async renderDoc() {
      let secureUrl = ''
      const clientId = this.client_id
      const quoteId = this.quote_id
      const changeOrderId = this.change_order_id

      const prefix = `${clientId}/${quoteId}/${changeOrderId}`
      const key = await getFileKey(prefix)

      await this.createDoc()

      if (key && !this.isInQuoteEditor) {
        secureUrl = `${import.meta.env.VITE_S3_APPROVALS_BUCKET_ENDPOINT}/${key}`
      }

      this.setBlobUrl(secureUrl)
      this.loadingPdf = false
    },
    async createDoc() {
      this.initDoc()
      this.pdfFiles = await this.getAllImageData()
      await this.createPDFHeader()
      const contentY = this.createContent()
      const totalsY = this.createTotals(contentY + this.sectionMargin)
      const notesY = this.getProjectNotes(totalsY + this.sectionMargin)
      const termsY = this.getTermAndConditions(notesY + this.sectionMargin)
      await this.getChangeOrderInfo(termsY + this.sectionMargin)
    },
    showTitle(name) {
      return !this.overrides.hideTitle && name
    },
    showDescription(meta, description) {
      return !this.overrides.hideDescription && meta.showDescription && description
    },
    shouldShowImages(meta, item) {
      return !this.overrides.hideImages && meta.hasImages && this.shouldAddImages(item)
    },
    showLocation(location) {
      return !this.overrides.hideLocation && location
    },
    showNotes(meta, productionNotes) {
      return !this.overrides.hideNotes && meta.showProductionNotes && productionNotes
    },
    showInputs(inputs) {
      return !this.overrides.hideInputs && inputs
    },
    showProperties(meta, aoProperties) {
      return (
        !this.overrides.hideProperties && this.checkProperties(meta.showProperties, aoProperties)
      )
    },
    getVisibility(
      item,
      obj = {
        price: 1,
        qty: 1,
        isVisible: 1
      }
    ) {
      if (this.presentationSettings?.forceTemplateSettings) {
        return obj
      }
      return (item.oViewOptions && item.oViewOptions.pres) || obj
    },
    getEmphasis(item) {
      if (item.assembly_emphasis && item.assembly_emphasis < -2) return false
      if (item.cost_type_emphasis && item.cost_type_emphasis < 0) return false
      return true
    },
    getItemType(item) {
      return item.oMeta?.itemType || 'costItem'
    },
    getItemAudience(item) {
      return item.oMeta?.selectionAudience || 'client'
    },
    checkProperties(showProperties, properties) {
      return (
        showProperties &&
        properties &&
        properties.length &&
        properties[0] &&
        properties[0][0] &&
        properties[0][0].length
      )
    },

    friendlyNameTaxOn(taxOn) {
      if (taxOn === 'all') return this.l('prices')
      if (taxOn === 'cost') return this.l('costs')
      if (taxOn === 'profit') return this.l('profits')

      return taxOn
    },
    friendlyNameTaxType(taxType) {
      if (taxType === 'ihlt') return this.l('labor')
      if (taxType === 'mt') return this.l('materials')
      if (taxType === 'slt') return this.l('subcontracting')

      return this.l('general')
    },
    setMetaData(item, norm) {
      const showProductionNotes = this.presentationSettings.showProductionNotes
      const showQty = this.presentationSettings.showQuantities
      let qty = 0
      if (item.type === 'assembly') {
        qty = item.quote_qty_net_base * this.artificialMultiplier
      } else {
        qty =
          (item.cost_item_qty_net_base || item.quote_qty_net_base || 0) * this.artificialMultiplier
      }

      const parent = norm[item.parentRefId]

      const showItemized = !parent || parent.quote_show_itemized_prices

      const assemblyCheck = this.assemblyVisibilityCheck(item.refId)

      const showAsItem =
        (item.type === 'assembly' && !assemblyCheck) || item.assembly_is_included === 0

      const showPrice =
        showItemized &&
        (item.type !== 'assembly' ||
          showAsItem ||
          (item.type === 'assembly' &&
            !showAsItem &&
            this.presentationSettings.showAssemblyPrices &&
            this.combinedPresentationSettings?.showItemizedPrices)) &&
        (((item.type !== 'assembly' || showAsItem) &&
          this.presentationSettings?.showCostItemPrices &&
          this.combinedPresentationSettings?.showItemizedPrices) ||
          (item.type === 'assembly' &&
            !showAsItem &&
            this.presentationSettings.showAssemblyPrices &&
            this.combinedPresentationSettings?.showItemizedPrices)) &&
        !item.assembly_is_in_fixed_price_assembly

      const visibility = this.getVisibility(item, {
        price: showPrice,
        qty: showQty,
        isVisible: 1
      })

      const showSubTotal = (showQty && (qty > 1.0 || qty === 0)) || showPrice

      const itemPictures = this.getItemPictures(item)
      const hasImages = itemPictures.length && this.presentationSettings.showDocumentPictures
      const showDescription = this.presentationSettings.showDocumentDescriptions
      const showProperties =
        itemPictures.length ||
        (item.aoProperties &&
          item.aoProperties.length &&
          item.aoProperties[0] &&
          item.aoProperties[0][0] &&
          item.aoProperties[0][0].length)
      return {
        showProductionNotes,
        showQty: visibility.qty,
        qty,
        parent,
        showItemized,
        showCost: false,
        showPrice: visibility.price,
        showSubTotal,
        assemblyCheck,
        showAsItem,
        hasImages,
        showProperties,
        showDescription,
        visibility
      }
    },
    assemblyVisibilityCheck(refId) {
      const item = this.set[refId]
      return (
        item.type === 'assembly' &&
        (item.assembly_emphasis >= 0 || item.assembly_emphasis === null) &&
        item.assembly_is_included !== 0
      )
    },
    orderItems(children, ordered) {
      children.forEach((item) => {
        ordered.push(item)
        if (item.aoChildren) {
          if (item.type === 'quote' || this.assemblyVisibilityCheck(item.refId)) {
            this.orderItems(item.aoChildren, ordered)
          }
        }
      })
    },
    async createPDFHeader() {
      if (!this.currentScreen.showHeading) return

      const estimate = this.set[this.refId]

      if (estimate) {
        // client info
        const {
          company_name: companyName,
          user_suite: clientSuite,
          user_address: clientAddress,
          user_city: clientCity,
          user_prov: clientProv,
          user_postal: clientPostal
        } = estimate.oClient || {}

        // Presentation credentials
        const { props = [] } = estimate.oPresentationSettings

        const companyShortName = this.$store.state.session.company?.company_name_short
        const companyPhone = this.$store.state.session.company?.company_phone
        const companyWebsite = this.$store.state.session.company?.company_website
        const companyEmail = this.$store.state.session.company?.company_email

        // estimate info
        const {
          quote_time_last_modified: quoteTimeLastModified,
          quote_time_expired: quoteTimeExpired,
          quote_name: quoteName,
          quote_address: quoteAddress,
          quote_suite: quoteSuite,
          quote_city: quoteCity,
          quote_prov: quoteProv,
          quote_postal: quotePostal,
          user_fname: userFname,
          user_lname: userLname
        } = estimate

        // format all the info
        const changeOrderTimeSent = this.change_order_time_sent
        const timeCreated = this.date(changeOrderTimeSent || quoteTimeLastModified)
        const timeExpired = this.date(quoteTimeExpired)
        const companyText = companyName ? `${companyName} \n` : ''
        const userName =
          userFname || userLname ? `${this.l('Attn')}: ${userFname || ''} ${userLname || ''}` : ''
        const projectAddressText =
          quoteSuite || quoteAddress ? `${quoteSuite || ''} ${quoteAddress || ''} \n` : ''
        const project = `${quoteName} \n ${projectAddressText} ${quoteCity || ''} ${quoteProv || ''} ${quotePostal || ''}`
        const clientAddressText =
          clientSuite || clientAddress ? `${clientSuite || ''} ${clientAddress || ''} \n` : ''
        const client = `${companyText} ${userName} \n ${clientAddressText} ${clientCity || ''} ${clientProv || ''} ${clientPostal || ''}`
        const logoFileId = this.currentScreen.logoFileId
        let logo = c.link(`file/view/${logoFileId}`, {}, true, _.getStorage('scope'))
        let colLeftHeight = this.textXY.y
        let colRightHeight = this.textXY.y

        // create the text and content
        let logoDim = {}
        try {
          logoDim = await this.getImageDimWithMax(logo, this.pageWidth * 0.75, 30)
        } catch (e) {
          logo = null
        }

        if (logo && this.currentScreen.showLogo) {
          const image = await this.convertImage(logo).catch((err) => console.error(err))
          this.doc.addImage(image, 'JPEG', this.pageMarginX, this.pageMarginY, logoDim.w, logoDim.h)
          colLeftHeight += logoDim.h + 8
        } else {
          this.txt('h1', companyShortName, this.pageMarginX, this.textXY.y, 'left')
          colLeftHeight += this.getTextHeight(companyShortName, 'h1')
        }

        if (props && props.length) {
          props.forEach((prop) => {
            if (prop && prop[0]) {
              const credentialString =
                prop[0].trim() !== ''
                  ? `•  ${prop[0]}${prop.length > 1 && typeof prop[1] === 'string' && prop[1] ? `: ${prop[1]}` : ''}`
                  : ''
              this.txt('p', credentialString, this.pageMarginX, colLeftHeight, 'left')
              colLeftHeight += this.getTextHeight(credentialString, 'p')
            }
          })

          colLeftHeight += this.lineHeight
        }

        if (companyPhone && this.currentScreen.showContact) {
          this.txt('p', `${this.phone(companyPhone)}`, this.pageMarginX, colLeftHeight, 'left')
          colLeftHeight += this.getTextHeight(companyPhone, 'p')
        }

        if (companyWebsite && this.currentScreen.showContact) {
          this.txt('p', companyWebsite, this.pageMarginX, colLeftHeight, 'left')
          colLeftHeight += this.getTextHeight(companyWebsite, 'p')
        }

        if (companyEmail && this.currentScreen.showContact) {
          this.txt('p', companyEmail, this.pageMarginX, colLeftHeight, 'left')
          colLeftHeight += this.getTextHeight(companyEmail, 'p')
        }

        if (this.currentScreen.showProjectDetails) {
          let timesTitle = this.l('Estimate')
          let timesDetails = timeCreated
          if (timeExpired) {
            timesTitle += ` / ${this.l('Expires')}`
            timesDetails += ` / ${timeExpired}`
          }

          this.callout(
            timesTitle,
            timesDetails,
            this.alignRight,
            colRightHeight,
            'right',
            [0, 0, 0],
            [0, 0, 0],
            'h4'
          )
          colRightHeight +=
            this.getTextHeight(timesTitle, 'h4') + this.getTextHeight(timesDetails, 'p') + 8
          this.callout(
            this.l('Project'),
            project,
            this.alignRight,
            colRightHeight,
            'right',
            [0, 0, 0],
            [0, 0, 0],
            'h4',
            this.pageWidth * 0.5
          )
          colRightHeight +=
            this.getTextHeight(this.l('Project'), 'h4') + this.getTextHeight(project, 'p') + 8
          this.callout(
            this.l('Client'),
            client,
            this.alignRight,
            colRightHeight,
            'right',
            [0, 0, 0],
            [0, 0, 0],
            'h4'
          )
          colRightHeight +=
            this.getTextHeight(this.l('Client'), 'h4') + this.getTextHeight(client, 'p') + 8
        }

        this.headerHeight = Math.max(colLeftHeight, colRightHeight)
        this.pageLine(this.headerHeight, null)
      }
    },
    checkPageHeight(itemMarginTop) {
      // check to see if we need to add a new page
      let check = itemMarginTop
      if (this.page === 1) {
        check = itemMarginTop + this.marginTop
      }
      return check > this.pageHeight
    },
    async getName(id, type = 'user') {
      return this.$store.dispatch(`${c.titleCase(type)}/getName`, {
        id,
        getIndividual: this.getIndividual
      })
    },
    hasBeenApproved(co) {
      return (
        co.change_order_company_has_approved &&
        co.change_order_company_approved_by &&
        co.change_order_client_has_approved &&
        co.change_order_client_approved_by
      )
    },
    async getChangeOrderInfo(startAt) {
      if (this.aoChangeOrders.length && this.quote_id) {
        await this.getChangeOrders()
      }

      if (
        (this.combinedPresentationSettings && !this.combinedPresentationSettings?.showApprovals) ||
        this.aoChangeOrders.length === 0
      ) {
        return startAt
      }

      if (this.presentationSettings?.showApprovals) {
        await this.getChangeOrders()
      }

      if (!this.changeOrderList) {
        return startAt
      }

      await this.addApprovalHistory(startAt)

      return null
    },
    async addApprovalHistory(startingPoint) {
      const description = this.l(
        'Approvals represent a digitally-recorded legally binding acceptance of the project as detailed ' +
          'in each proposal version and according to the terms and conditions set ' +
          'by the company (contractor) and Bolster terms of service and privacy policy which can be found at https://app.bolsterbuilt.com/pub/legal. ' +
          'The versions are listed in reverse chronological order.'
      )

      const descHeight = this.getTextHeight(description, 'p')
      const endY = startingPoint + this.lineHeight * 2
      let endLineY = endY
      const indent = this.pageMarginX + 4
      const signatureSpaceNeeded = 10

      this.doc.addPage()
      endLineY = this.pageMarginY + this.lineHeight

      this.txt('h2', this.l('Approvals History'), this.pageMarginX, endLineY)
      endLineY += this.lineHeight * 2
      this.txt('p', description, this.pageMarginX, endLineY)
      endLineY += descHeight

      if (this.changeOrderList) {
        this.changeOrderList.forEach((co) => {
          if (endLineY + signatureSpaceNeeded > this.pageHeight) {
            this.doc.addPage()
            endLineY = this.pageMarginY + this.lineHeight
          }

          const order = `${co.change_order_name} • ${this.currency(co.quote_price_gross)}`
          const version =
            String(co.change_order_id) === String(this.change_order_id)
              ? ` (${this.l('Version Displayed')})`
              : ''
          endLineY += this.lineHeight * 2
          this.txt('h4', `${order}${version}`, this.pageMarginX, endLineY)

          if (this.hasBeenApproved(co)) {
            endLineY += this.lineHeight + 2
            this.txt('b', this.l('Digital signatures'), indent, endLineY)
            endLineY += this.lineHeight
            const clientName = co.oClientMeta.user_name
            const clientSignature = co.oClientMeta.signature

            let orderApproved = ''
            if (co.change_order_client_has_approved && co.change_order_client_approved_by) {
              orderApproved = `Approved at ${this.datetime(co.change_order_client_approved_time)} by ${clientName || 'customer'} (user #${co.change_order_client_approved_by})`
              if (clientSignature) {
                endLineY += this.lineHeight * 2
                this.txt('signature', clientSignature, indent, endLineY)
                endLineY += this.lineHeight * 2
              }
            } else {
              orderApproved = 'This version was never approved by the client.'
            }
            // endLineY += this.lineHeight;
            this.txt('p', this.l(orderApproved), indent, endLineY)
          }
        })
      }

      return endLineY
    },
    createContent() {
      // setup the initial margins
      const headerMargin = this.headerHeight + this.pageMarginY * 2 + this.containerMargin
      this.marginTop = this.currentScreen.showHeading ? headerMargin : this.pageMarginY
      this.itemMarginTop = this.pageMarginY
      this.page = 1
      let endAt = this.marginTop + this.lineHeight
      this.txt('h2', this.l('Items'), this.pageMarginX, this.marginTop - this.lineHeight)
      // loop through all the ordered and flattened items
      this.ordered.forEach((item) => {
        if (item.type === 'quote') return

        // check assembly visibility
        const isVisible = item.type === 'assembly' ? this.assemblyVisibilityCheck(item.refId) : true
        if (!isVisible) return
        // check item visibility
        const visibility = this.getVisibility(item)
        if (!visibility.isVisible) return
        const emphasis = this.getEmphasis(item)
        if (!emphasis) return
        // check item type
        const itemType = this.getItemType(item)
        if (itemType !== 'costItem') return
        // check item audience
        const itemAudience = this.getItemAudience(item)
        if (itemAudience !== 'client') return

        let name = item.cost_type_name || item.assembly_name
        name = name || item.item_name

        if (name === '') return
        if (['cost_type', 'cost_item'].includes(item.type) && item.cost_type_is_task) return

        // add the item to pdf content
        const { height, endY } = this.createItem(item)

        // set the new item margin from top
        this.itemMarginTop += height
        endAt = endY
      })

      return endAt
    },
    getItemHeight(props) {
      const {
        startingY,
        title = null,
        description = null,
        notes = null,
        location = null,
        properties = null,
        item,
        meta
      } = props
      let endY = 0

      if (this.showTitle(title)) {
        const h = this.getTextHeight(title, 'h2')
        endY = startingY + h
      }

      if (this.showLocation(location)) {
        const h = this.getTextHeight(location, 'small')
        endY = endY + this.lineHeight / 2 + h
      }

      if (this.shouldShowImages(meta, item)) {
        const { files } = this.pdfFiles.find((i) => i.id === item.item_id)
        const rows = Math.floor(files.length / 8)
        endY = this.imageDim.h + rows + (rows - 1) * 5 + endY + this.lineHeight
      }

      if (this.showDescription(description)) {
        const h = this.getTextHeight(description, 'p')
        endY = endY + this.lineHeight + h
      }

      if (this.showNotes(meta, notes)) {
        const h = this.getTextHeight(notes, 'p')
        endY = endY + this.lineHeight + h
      }

      if (this.showProperties(meta, properties)) {
        properties.forEach((attribute) => {
          if (!attribute || attribute.length === 0 || typeof attribute[0] !== 'string') return
          const attrString =
            attribute[0].trim() !== ''
              ? `•  ${attribute[0]}${attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1] ? `: ${attribute[1]}` : ''}`
              : ''
          const h = this.getTextHeight(attrString, 'p')
          endY = endY + this.lineHeight + h
        })
      }

      return endY + this.lineHeight - startingY + 6
    },
    createItem(item) {
      // get the meta which basically are the checks
      const meta = this.setMetaData(item, this.set)
      const dimensions = {}
      let marginX = this.pageMarginX
      let prevTab = this.pageMarginX
      const hierarchyIndicator = item.asAssemblyPath?.length || 1

      if (item.parentRefId !== this.refId) {
        const multiplier = hierarchyIndicator > 8 ? 8 : hierarchyIndicator
        marginX += multiplier * 4
        prevTab += (multiplier - 1) * 4
      }

      // where the item start point is
      let startingY = this.marginTop + this.itemMarginTop

      // setup the info from the item
      const price =
        item.type === 'assembly' ? this.getPrice(item, meta) : this.getCostItemPrice(item, meta)
      const location = item.asAssemblyPath ? item.asAssemblyPath.join(' \u2192 ') : null
      const description = c.removeHtml(item.cost_type_desc || item.quote_notes).trim()
      let name = item.cost_type_name || item.assembly_name
      name = name || item.item_name
      const productionNotes = c
        .removeHtml(item.cost_type_production_notes || item.quote_production_notes)
        .trim()
      const inputs =
        item.oInputRequired && item.oInputRequired.inputs && item.oInputRequired.inputs.length
          ? item.oInputRequired.inputs.reduce((acc, input) => {
              let accr = ''
              if (input.type === 'text') {
                accr = `${acc ? `${acc} • ` : ''}${input.message}: ${input.input.text}`
              }

              if (input.type === 'color') {
                accr = `${acc ? `${acc} • ` : ''}${input.input.data.vendor} - ${input.input.data.name}`
              }

              return accr
            }, '')
          : false

      // get the height before we add to the document
      const h = this.getItemHeight({
        startingY,
        title: name,
        description,
        notes: productionNotes,
        location,
        properties: item.aoProperties,
        item,
        meta
      })

      // check right away to see if we need to add a new page
      const addPage = this.checkPageHeight(this.itemMarginTop + h)
      if (addPage) {
        // add page
        this.doc.addPage()
        this.page += 1
        // reset the margins
        this.itemMarginTop = this.pageMarginY
        this.marginTop = this.pageMarginY
        startingY = this.marginTop + this.itemMarginTop
      }

      let endY = 0
      const { r, g, b } = this.theme.backgroundColorDarker

      // title
      if (this.showTitle(name)) {
        const titleXY = {
          x: marginX,
          y: startingY
        }
        if (hierarchyIndicator > 1) {
          this.txt('icon', '\u21b3 ', prevTab - 0.5, titleXY.y - 0.5, 'left', [r, g, b])
        }
        dimensions.title = this.txt(
          hierarchyIndicator === 1 ? 'h3' : 'h4',
          name,
          titleXY.x,
          titleXY.y
        )
        endY = titleXY.y + dimensions.title.h - 1
      }

      // location
      if (this.showLocation(location)) {
        const locationXY = {
          x: marginX,
          y: endY + this.lineHeight / 6
        }
        dimensions.location = this.txt('small', location, locationXY.x, locationXY.y, 'left', [
          r,
          g,
          b
        ])
        endY = locationXY.y + dimensions.location.h
      }

      // add images
      if (this.shouldShowImages(meta, item)) {
        const imageY = endY
        dimensions.images = this.addImages(item, imageY, marginX)
        endY = dimensions.images.h + imageY + 3
      }

      // description
      if (this.showDescription(meta, description)) {
        const descriptionXY = {
          x: marginX,
          y: endY + this.lineHeight
        }
        dimensions.description = this.txt('p', description.trim(), descriptionXY.x, descriptionXY.y)

        if (dimensions.description.pageAdded) {
          endY = dimensions.description.h + this.lineHeight * 2 + this.marginTop
        } else {
          endY = descriptionXY.y + dimensions.description.h
        }
      }

      // notes
      if (this.showNotes(productionNotes)) {
        const notesXY = {
          x: marginX,
          y: endY + this.lineHeight
        }
        const red = [156, 0, 0]
        dimensions.notesHeader = this.txt(
          'b',
          `${this.l('Production Notes')}:`,
          notesXY.x,
          notesXY.y,
          'left',
          red
        )

        if (dimensions.notesHeader.pageAdded) {
          notesXY.y = dimensions.notesHeader.h + this.lineHeight + this.marginTop
        }

        dimensions.notes = this.txt(
          'p',
          productionNotes,
          notesXY.x,
          notesXY.y + this.lineHeight,
          'left',
          red
        )

        if (dimensions.notes.pageAdded) {
          endY = dimensions.notes.h + this.lineHeight * 2 + this.marginTop
        } else if (dimensions.notesHeader.pageAdded) {
          endY = dimensions.notes.h + dimensions.notesHeader.h + this.lineHeight + this.marginTop
        } else {
          endY = notesXY.y + dimensions.notes.h + dimensions.notesHeader.h + this.lineHeight
        }
      }

      if (this.showInputs(inputs)) {
        const inputsXY = {
          x: marginX,
          y: endY + this.lineHeight
        }
        const red = [156, 0, 0]
        dimensions.inputsHeader = this.txt(
          'b',
          `${this.l('Client Input Details')}:`,
          inputsXY.x,
          inputsXY.y,
          'left',
          red
        )

        if (dimensions.inputsHeader.pageAdded) {
          inputsXY.y = dimensions.inputsHeader.h + this.lineHeight + this.marginTop
        }

        dimensions.inputs = this.txt(
          'p',
          inputs,
          inputsXY.x,
          inputsXY.y + this.lineHeight,
          'left',
          red
        )

        if (dimensions.inputs.pageAdded) {
          endY = dimensions.inputs.h + this.lineHeight * 2 + this.marginTop
        } else if (dimensions.inputsHeader.pageAdded) {
          endY =
            dimensions.inputs.h + dimensions.inputsHeader.h + this.lineHeight * 2 + this.marginTop
        } else {
          endY = inputsXY.y + dimensions.inputs.h + dimensions.inputsHeader.h + this.lineHeight
        }
      }

      // properties
      if (this.showProperties(meta, item.aoProperties)) {
        const attrXY = {
          x: marginX,
          y: endY + this.lineHeight * 1
        }
        item.aoProperties.forEach((attribute) => {
          if (!attribute || attribute.length === 0 || typeof attribute[0] !== 'string') return
          const attrString =
            attribute[0].trim() !== ''
              ? `•  ${attribute[0]}${attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1] ? `: ${attribute[1]}` : ''}`
              : ''
          const dim = this.txt('p', attrString, attrXY.x, attrXY.y)
          if (dim.pageAdded) {
            attrXY.y = dim.h + this.lineHeight * 2 + this.marginTop
            return
          }
          attrXY.y += dim.h + this.doc.internal.getLineHeightFactor()
        })

        endY = attrXY.y
      }

      // price
      if (!this.overrides.hidePrice && price) {
        const chipXY = {
          x: this.alignRight,
          y: startingY
        }
        dimensions.chip = this.txt('p', price, chipXY.x, chipXY.y, 'right')
      }

      // add divider line
      this.pageLine(endY, null, false, marginX)

      // return the calculated height of item section
      const height = endY + this.lineHeight - startingY + 3

      this.currentParentRefId = item.parentRefId

      return {
        height,
        endY: endY + this.lineHeight,
        addPage
      }
    },
    number(value) {
      return c.format(value, 'number')
    },
    percentage(value) {
      return c.format(value, 'percentage')
    },
    currency(value) {
      return c.format(value, 'currency')
    },
    phone(value) {
      return c.format(value, 'phone')
    },
    datetime(value) {
      return c.format(value, 'datetime')
    },
    date(value) {
      return c.format(value, 'date')
    },
    createTotals(startAt) {
      if (!this.combinedPresentationSettings?.showProjectTotals) {
        return startAt - this.sectionMargin
      }
      let start = startAt

      if (startAt > this.height - 60) {
        this.doc.addPage()
        start = this.pageMarginX
      }

      const rootRefId = c.getNormalizedRootRefId(this.set)
      const estimate = this.set[rootRefId]
      const discount = this.currency(estimate?.quote_discount_net) || 0
      const subTotal = this.currency(estimate?.quote_price_net) || 0
      const total = estimate?.quote_price_gross
      const currencyIso = estimate?.currency_iso
      const largeLineHeight = this.lineHeight + 2
      const managementCheck =
        estimate?.quote_profit_net >= 0.01 && this.mergedSettings?.showBundledProfit

      const generateLine = (val) => {
        const line = managementCheck
          ? start + largeLineHeight * (val + 1)
          : start + largeLineHeight * val
        return line
      }

      const lines = {
        one: start + largeLineHeight,
        two: managementCheck ? start + largeLineHeight * 2 : start + largeLineHeight,
        three: managementCheck ? start + largeLineHeight * 3 : start + largeLineHeight * 2,
        four: managementCheck ? start + largeLineHeight * 4 : start + largeLineHeight * 3,
        five: managementCheck ? start + largeLineHeight * 5 : start + largeLineHeight * 4
      }

      // titles
      if (managementCheck) {
        const managementFee =
          estimate.quote_price_net -
          estimate.quote_total_cost_net +
          (estimate.quote_discount_net || 0)
        const managementText =
          typeof this.mergedSettings?.showBundledProfit === 'string'
            ? this.mergedSettings?.showBundledProfit
            : this.l('Management fee')
        this.txt('h3', managementText, this.pageMarginX, lines.one)
        this.txt('p', this.currency(managementFee), this.alignRight, lines.one, 'right')
      }

      // this.txt('h2', 'GST', this.pageMarginX, lines.four);
      // this.txt('p', tax, this.alignRight, lines.four, 'right');

      // info
      let line = generateLine(1)
      let lineRef = 2
      if (estimate.quote_discount_net !== 0) {
        this.txt('h3', this.l('Discount'), this.pageMarginX, line)
        this.txt('p', discount, this.alignRight, line, 'right')
        line = generateLine(2)
        lineRef = 3
      }

      this.txt('h3', this.l('Subtotal'), this.pageMarginX, line)
      this.txt('p', subTotal, this.alignRight, line, 'right')

      const taxes = estimate.oTaxSums

      if (taxes && Object.values(taxes).length >= 1) {
        Object.values(taxes).forEach((tx) => {
          if (tx.sum === 0) return
          line = generateLine(lineRef)
          this.txt('h3', `${this.l(tx.name)}`, this.pageMarginX, line)
          this.txt(
            'p',
            `${this.percentage(tx.pcnt)} ${this.l('tax')} ${this.l('on')} ${this.friendlyNameTaxOn(tx.on)} ${this.l('derived from')} ${this.friendlyNameTaxType(tx.type)}    ${this.currency(tx.sum)}`,
            this.alignRight,
            line,
            'right'
          )
          lineRef += 1
        })
      }

      line = generateLine(lineRef + 1)
      this.txt('h2', this.l('Project total'), this.pageMarginX, line)
      this.txt('h2', `${this.currency(total, 2, currencyIso)}`, this.alignRight, line, 'right')

      return line
    },
    getAllImageData() {
      return Promise.all(
        this.ordered.map(async (item) => {
          const { item_id: itemId } = item
          const files = await this.getImageData(item)
          return {
            id: itemId,
            files
          }
        })
      )
    },
    getImageData(item) {
      const thumbs = this.getFilesListWithUrl(item)
      return Promise.all(
        thumbs.map(async ({ thumb, id }) => {
          try {
            const image = await this.convertImage(thumb)
            return {
              id,
              thumb,
              image
            }
          } catch (e) {
            console.error(e)
            // silent error bad, but try catch good //
            return null
          }
        })
      )
    },
    shouldAddImages(item) {
      const { files } = this.pdfFiles.find((i) => i.id === item.item_id)
      let addImages = false
      files.forEach((file) => {
        if (!file) return
        addImages = true
      })
      return addImages
    },
    addImages(item, startY, marginX) {
      const { files } = this.pdfFiles.find((i) => i.id === item.item_id)
      const imageSpacing = 5
      const imageXY = {
        x: marginX,
        y: startY
      }
      let rows = 1
      files.forEach((file, index) => {
        if (!file) return
        const { image } = file
        if (index !== 0 && index % 7 === 0) {
          imageXY.y += this.imageDim.h + imageSpacing
          imageXY.x = marginX
          rows += 1
        }
        this.doc.addImage(image, 'JPEG', imageXY.x, imageXY.y, this.imageDim.w, this.imageDim.h)
        imageXY.x += this.imageDim.w + imageSpacing
      })
      const multiplier = rows >= 4 ? 1.5 : imageSpacing
      return {
        h: (this.imageDim.h + (rows - 1) * multiplier) * rows,
        w: imageXY.x
      }
    },
    getItemPictures(item) {
      const ids = []
      let fileIds = []
      let fileIdsArray = []
      fileIdsArray = Array.isArray(this.object.file_ids)
        ? this.object.file_ids
        : [this.object.file_ids]

      if (item?.file_ids?.length) {
        fileIds = [...fileIdsArray.map((id) => c.buildDefaultObject('file', { file_id: id }))]

        return fileIds.filter((file) => {
          // De-duplicate
          const id = String(file.file_id)
          const inc = !ids.includes(id)
          ids.push(id)
          return !c.isempty(id) && inc
        })
      }

      return fileIds
    },
    getThumbUrls(item) {
      const filesListWithUrl = this.getFilesListWithUrl(item)
      return c.uniq(filesListWithUrl.map((f) => f.thumb))
    },
    getFilesListWithUrl(item) {
      const fileIds = this.getFileIds(item)
      return fileIds.map((id) => ({
        id,
        url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
        thumb: c.link(
          `file/pic/thumb/${id}`,
          { size: 200, square: true },
          true,
          _.getStorage('scope')
        )
      }))
    },
    getFileIds(item) {
      const itemPictures = this.getItemPictures(item)
      return c.uniq(itemPictures.map((f) => f.file_id))
    },
    getPrice(item, meta) {
      let price = (item.cost_item_price_net_base || item.quote_subtotal_net || 0) * 1
      if (this.combinedPresentationSettings?.showBundledProfit) {
        price = item.quote_total_cost_net_base * 1
      }

      const check =
        (meta.showQty && (meta.qty > 1.0 || meta.qty === 0)) || meta.showCost || meta.showPrice

      if (!check) return ''

      let priceFormatted = this.l('not included')
      if (meta.qty && meta.qty > 0) {
        const quantity =
          meta.showQty && meta.qty > 1
            ? `${this.number(meta.qty)} ${this.l('each').toUpperCase()} •`
            : ''
        const fullPrice = meta.showPrice ? this.currency(price) : ''
        priceFormatted = `${quantity} ${fullPrice}`
      }

      return priceFormatted
    },
    getCostItemPrice(item, meta) {
      const price = (item.cost_item_price_net_base || item.quote_subtotal_net || 0) * 1
      let cost =
        (item.cost_item_total_cost_net_base || item.quote_total_cost_net_base) *
        this.artificialMultiplier
      if (this.combinedPresentationSettings?.showBundledProfit) {
        cost = item.cost_item_total_cost_net_base * 1
      }
      const units = this.l(String(item.unit_of_measure_abbr || 'each')).toUpperCase()

      let priceFormatted = this.l('not included')
      if (meta.qty && meta.qty > 0) {
        let quantity = ''
        if (meta.showQty) {
          quantity = item.cost_type_is_fee
            ? `1 ${this.l('each')} • `
            : `${this.number(meta.qty)} ${units} • `
        }

        const fullPrice = meta.showPrice ? this.currency(price) : this.l('included')
        priceFormatted = `${quantity}${fullPrice}`
      }

      if (meta.showCost) {
        priceFormatted = `${this.l('Cost')} ${this.currency(cost)}  -  ${priceFormatted}`
      }

      return priceFormatted
    },
    setupItemsForPDF() {
      const norm = this.set || this.$store.state[this.store].normalized
      const denormalized = c.denormalize(norm)
      const ordered = []
      this.orderItems([denormalized], ordered)
      this.ordered = ordered
    },
    getTermAndConditions(startAt) {
      if (
        this.combinedPresentationSettings &&
        !this.combinedPresentationSettings?.showTermsAndConditions
      ) {
        return startAt - this.sectionMargin
      }
      // get the height to see if we need to add a new page
      const height = this.setTerms(startAt, false)

      let startingPoint = startAt
      if (startingPoint + height > this.pageHeight) {
        this.doc.addPage()
        startingPoint = this.pageMarginX
      }

      // add the title
      const titleDim = this.txt(
        'h2',
        this.l('Terms and conditions'),
        this.pageMarginX,
        startingPoint + this.lineHeight
      )

      // display the terms
      const endHeight = this.setTerms(startingPoint + titleDim.h + this.lineHeight, true)

      return startingPoint + endHeight
    },
    getProjectNotes(startAt) {
      const height = this.setProjectNotes(startAt, false)

      let startingPoint = startAt
      if (startingPoint + height > this.pageHeight) {
        this.doc.addPage()
        startingPoint = this.pageMarginX
      }

      // add the title
      const titleDim = this.txt(
        'h2',
        this.l('Project notes'),
        this.pageMarginX,
        startingPoint + this.lineHeight
      )

      const endHeight = this.setProjectNotes(startingPoint + titleDim.h + this.lineHeight, true)

      return startingPoint + endHeight
    },
    setTerms(startingPoint, display = false) {
      const terms =
        this.change_order_approved_terms ||
        ''.concat(
          `${this.$store.state.session.company.company_terms_and_conditions || ''}`,
          '\n',
          `${this.presentationSettings.termsAndConditions || ''}`,
          '\n',
          `${this.quote_terms || ''}`
        )

      const lines = c
        .removeHtml(terms)
        .replace(/(?:\r\n|\r|\n){2,}/, '\r\n') // replace multi lines to just one line
        .replace(/\t/g, '  ') // replace tab characters with two spaces
        .split(/(?:\r\n|\r|\n)/g)

      let height = display ? startingPoint : 0

      lines.forEach((l) => {
        const line = l.trim()
        if (line !== '') {
          if (height > this.pageHeight - 30 && display) {
            this.doc.addPage()
            height = this.pageMarginX
          }

          if (/^\[.*?\]/.test(line)) {
            const title = line.replace(/\[(.*?)\]/, '$1')
            const position = height + this.lineHeight
            let textHeight = 0
            if (!display) {
              textHeight = this.getTextHeight(title, 'h3')
            } else {
              const dim = this.txt('h3', title, this.pageMarginX, position)
              textHeight = dim.h
            }
            height += textHeight + this.lineHeight
          } else {
            const term = `${line.replace('•', '-')}\n`
            const lineSpace = this.lineHeight / 2
            const position = height + lineSpace
            let textHeight = 0
            if (!display) {
              textHeight = this.getTextHeight(term, 'p')
            } else {
              const dim = this.txt(
                'p',
                term,
                this.pageMarginX,
                position,
                'left',
                [0, 0, 0],
                this.pageWidth * 0.9,
                true
              )
              textHeight = dim.h
            }
            height += textHeight + lineSpace
          }
        }
      })

      return height
    },
    setProjectNotes(startingPoint, display = false) {
      const notes = this.quote_notes || ''

      const lines = c
        .removeHtml(notes)
        .replace(/(?:\r\n|\r|\n){2,}/, '\r\n') // replace multi lines to just one line
        .replace(/\t/g, '  ') // replace tab characters with two spaces
        .split(/(?:\r\n|\r|\n)/g)

      let height = display ? startingPoint : 0

      lines.forEach((l) => {
        const line = l.trim()
        if (line !== '') {
          if (height > this.pageHeight - 30 && display) {
            this.doc.addPage()
            height = this.pageMarginX
          }

          if (/^\[.*?\]/.test(line)) {
            const title = line.replace(/\[(.*?)\]/, '$1')
            const position = height + this.lineHeight
            let textHeight = 0
            if (!display) {
              textHeight = this.getTextHeight(title, 'h3')
            } else {
              const dim = this.txt('h3', title, this.pageMarginX, position)
              textHeight = dim.h
            }
            height += textHeight + this.lineHeight
          } else {
            const note = `${line.replace('•', '-')}\n`
            const lineSpace = this.lineHeight / 2
            const position = height + lineSpace
            let textHeight = 0
            if (!display) {
              textHeight = this.getTextHeight(note, 'p')
            } else {
              const dim = this.txt(
                'p',
                note,
                this.pageMarginX,
                position,
                'left',
                [0, 0, 0],
                this.pageWidth * 0.9,
                true
              )
              textHeight = dim.h
            }
            height += textHeight + lineSpace
          }
        }
      })

      return height
    },
    parseStyleVariables() {
      if (!this.currentScreen || !this.currentScreen.styleVariables) return {}
      const styleVariables = this.currentScreen.styleVariables
      const extract = styleVariables.match(/\$.+?;/g)
      const variables = {}
      extract.forEach((variable) => {
        const name = variable
          .match(/\$.+?:/g)[0]
          .replace('$', '')
          .replace(':', '')
        const value = variable.match(/:.+?;/g)[0].replace(':', '').replace(';', '')
        variables[name] = value
      })

      return variables
    },
    onCloseSettings() {
      this.renderDoc()
    },
    async uploadToS3() {
      await this.renderDoc()
      const fileName = `${this.change_order_id}`
      const key = `${this.client_id}/${this.quote_id}/${fileName}.pdf`
      const file = await this.doc.output('blob')

      // Get the pre-signed URL
      const { url, fields } = await getPreSignedPost(key)

      // Create FormData and append the fields and file
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', file)

      // Upload the file to S3
      return await fetch(url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      })
    },
    async getChangeOrders() {
      const { set } = await this.$store.dispatch('Quote/getChangeOrders', {
        id: this.quote_id
      })
      this.changeOrderList = set
    }
  }
}
