<template>
  <div
    class="addon_list-container-classic"
    :style="
      !editable && !filteredList.length
        ? {
            padding: 0,
            margin: 0,
            height: 0,
            width: 0
          }
        : {}
    "
    style="margin-bottom: 10px"
    :class="editable ? 'addon_list-editing' : ''"
  >
    <scroll-trigger
      @in="scrolledIn = true"
      :disableOnTrigger="true"
      :showLoading="false"
      :checkOnMount="true"
      ref="scrollTrigger"
      style="height: 1px"
    />

    <fade>
      <div v-if="(scrolledIn && filteredList.length) || editable">
        <div class="addon_list-title">
          <font-awesome-icon
            icon="chevron-double-up"
            class="text-info"
            style="font-size: 2em"
            fixed-width
          />
          <SuggestionButton v-if="title" class="info">
            <template v-if="totalChose > 0"
              >{{ totalChose }} {{ l('other customers recently upgraded their') }}
              {{ $f.truncate(title, 60) }}</template
            >
            <template v-else>{{ l('Other options for') }} {{ $f.truncate(title, 60) }}</template>
          </SuggestionButton>
        </div>

        <div class="addon_list-scroll">
          <template :key="`${addon.id}-${addon.type}`" v-for="(addon, index) in filteredList">
            <QuotePresentationAddonItemClassic
              :targetRefId="reference"
              :currentPrice="currentPrice"
              :editable="editable"
              :addon="addon"
              :dimensions="dimensions"
              @number-chose="(chval) => countHandler(index, chval)"
              @disabled="disableAddon(addon)"
              @addon="(modified) => (index > 0 ? modifyAddon(addon, modified) : null)"
              @get="(object) => get(addon)"
            />

            <div v-if="editable" class="addon_list-item-options">
              <btn rounded class="lg more px-0 p-2 danger" @click="removeAddon(addon)">
                &times;
              </btn>
            </div>
          </template>

          <div class="addon_list-options" v-if="editable">
            <traverse
              :embue="embueCreate"
              :externalCreate="true"
              v-if="upgrades && target.type !== 'quote'"
              ref="upgradeTraverse"
            >
              <template #button>
                <btn :loading="loading" @click="addUpgradeItem" class="btn round secondary">
                  <template #icon>
                    <font-awesome-icon icon="plus" fixed-width />
                  </template>
                  Add upgrade item
                </btn>
              </template>
            </traverse>
            <traverse
              :embue="embueCreate"
              :externalCreate="true"
              v-if="optionals && (target.type === 'assembly' || target.type === 'quote')"
              ref="optionalTraverse"
            >
              <template #button>
                <btn :loading="loading" @click="addOptionalItem" class="btn round secondary">
                  <template #icon>
                    <font-awesome-icon icon="plus" fixed-width />
                  </template>
                  Add optional item
                </btn>
              </template>
            </traverse>
          </div>
        </div>
      </div>
    </fade>
  </div>
</template>

<script>
import QuotePresentationAddonItemClassic from './QuotePresentationAddonItem-classic.vue'
import BtnMixin from '../../mixins/Button'
import AddonListMixin from '../addons/AddonListMixin-classic'
import SuggestionButton from '../../ui/SuggestionButton.vue'
import TranslationMixin from './languages/TranslationMixin'

/**
 * Emits:
 * -countItems number of items in filtered list
 * -PresentationMoreEmphasis (refId)
 */
export default {
  name: 'QuotePresentationAddonList-classic',
  mixins: [BtnMixin, AddonListMixin, TranslationMixin],
  components: {
    SuggestionButton,
    QuotePresentationAddonItemClassic
    /* ChangeAudit, */
  },
  data() {
    return {
      numberChose: []
    }
  },
  computed: {
    totalChose() {
      return Object.values(this.numberChose).reduce((acc, num) => acc + c.toNum(num || 0), 0)
    }
  },
  methods: {
    countHandler(index, count = 0) {
      const ch = _.immutable(this.numberChose)
      ch[index] = count
      this.numberChose = ch
    }
  },
  props: {
    showCurrent: {
      default: true
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.addon_list-scroll {
  .card.card--container.button.addon-container {
    &:hover {
      transform: initial;
    }
  }
}
.cost-item-upgrades.addon_list-container-classic,
.assembly-upgrades.addon_list-container-classic {
  font-size: 0.7em;
  // padding: 1em;
  position: relative;
  margin: 0 0;
  padding: 1em 0 0em 0;

  width: 100%;
  max-width: 100%;

  &.cost-item-upgrades {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
    max-width: calc(100% + 2rem);
  }

  > div {
    justify-content: flex-start;
    text-align: left;
  }

  .addon_list-scroll {
    justify-content: flex-start;
    > :first-child {
      margin-left: 2rem !important;
    }

    // @extend %scrollbarBlue;

    $scrollBarWidth: 15px;

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      height: $scrollBarWidth;
      display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      width: $scrollBarWidth;
      height: $scrollBarWidth;
    }
  }

  .addon_list-title {
    font-size: 1.1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
  }
}

.addon_list-container-classic {
  width: 100%;
  max-width: 100%;
  height: auto;
  flex: 0 1 100%;
  overflow: visible;
  margin: 3em 0 0 0;
  // perspective: 30em;
  justify-content: center;
  align-items: center;
  text-align: center;
  $light: rgba($blue-print-700, 0.2);
  background: linear-gradient(0deg, $cool-gray-200 -20%, $cool-gray-200 100%) !important;

  .addon_list-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-touch-scrolling: touch;
    width: 100%;
    max-width: 100%;
    padding: 1em 1em 2em 1em;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      width: 5px;
      height: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: rgba($cool-gray-600, 0.6);
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba($cool-gray-400, 0.25);
    }
  }
  .addon_list-scroll-item {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 10em;
  }
  .addon_list-scroll-item + .addon_list-scroll-item {
    margin-left: 0.5em !important;
  }
  .addon_list-item-options {
    display: flex;
    padding: 0.5em;
    justify-content: center;
    align-items: center;
  }
  .addon_list-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1em;
    flex: 0 0 auto !important;
    > * + * {
      margin-top: 0.5em;
    }
  }

  &.addon_list-editing {
    height: auto;
    overflow: hidden;
    .addon_list-scroll-item {
      height: 10em;
    }
  }
}
</style>
