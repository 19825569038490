<template>
  <div
    class="field-section-item list-group-item field-section-item-field"
    :class="{
      'show-invalid': hasBlurred && !validationCode && validationCode !== null,
      invalid: !validationCode && validationCode !== null,
      focused: focusIn,
      muted: muted,
      filledOut: !valueEmpty || value || changed,
      placeholder: placeholder && placeholder !== '',
      isCheckbox: isCheckbox,
      isTextField: isTextField
    }"
  >
    <div class="field-section-above" v-if="$slots.above">
      <slot name="above"></slot>
    </div>
    <div v-if="$slots.help" class="field-section--help">
      <slot name="help"></slot>
    </div>
    <div
      :data-label="label"
      :id="uid"
      class="field-section"
      ref="fieldSectionContent"
      :class="{
        noLabel: noLabel,
        isTextField: isTextField
      }"
    >
      <slot></slot>
    </div>
    <fade>
      <div
        v-if="(required || requiredFormat) && validationCode === true && hasFocused"
        class="field-section-validation-success"
      >
        <font-awesome-icon icon="check" />
      </div>
    </fade>
    <fade>
      <div
        v-if="required === true && !hasFocused && valueEmpty"
        class="field-section-validation-required"
      >
        <font-awesome-icon icon="asterisk" />
      </div>
    </fade>
    <div
      v-if="hasBlurred && validationCode === false && validationMessage && validationMessage !== ''"
      class="field-section-validation"
    >
      {{ validationMessage }}
    </div>
    <div class="field-section-below" v-if="$slots.below">
      <slot name="below"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldSection',
  props: {
    muted: Boolean,
    noLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uid: _.uniqueId(),
      validationMessage: '',
      validationCode: null, // null - no validation, false - invalid, true - valid
      required: false,
      changed: false,
      requiredFormat: false,
      focusIn: false,
      isCheckbox: false,
      isTextField: false,
      hasFocused: false,
      hasBlurred: false,
      valueEmpty: true,
      value: null,
      currentValue: null,
      placeholder: null,
      label: ''
    }
  },
  methods: {
    listenToChild(component) {
      // Set id for a hook for entering
      const $first = $(this.$refs.fieldSectionContent.firstElementChild)

      const fieldId =
        c.idFromText($first.clone().children().remove().end().text()) ||
        ($first.find('.help--text-button').length &&
          c.idFromText($first.find('.help--text-button').text())) ||
        c.idFromText($first[0].innerText)

      this.label = fieldId

      if (!component) return

      $(component.$el).attr('id', fieldId)
      // get required
      this.required = component.required || false
      this.requiredFormat = component.requiredFormat || component.format
      this.validationCode = component.valid || null
      this.validationMessage = component.required ? 'This is a required field.' : ''
      this.valueEmpty = !component.notEmpty
      this.placeholder = component.placeholder
      this.value = component.value
      const componentIs = component.componentIs
      const classes = component.$el.className.split(' ')
      this.isCheckbox = classes.includes('checkbox--container')
      this.isTextField = classes.includes('textarea')

      component.$on('validation', (payload) => {
        if (!Object.keys(payload).length) return
        this.valueEmpty = payload.empty
        this.required = payload.required
        this.requiredFormat = payload.requiredFormat || payload.format
        this.validationCode = payload.valid
        this.validationMessage = payload.message
      })

      component.$on('focusin', () => {
        this.hasFocused = true
        this.focusIn = true
      })

      component.$on('input', (val) => {
        if (val && val !== '') {
          this.valueEmpty = false
          this.hasFocused = true
          this.validationCode = true
        }
      })

      component.$on('changed', (val) => {
        if (val && val !== '') {
          this.changed = true
        } else {
          this.changed = false
        }
      })

      component.$on('focusout', () => {
        this.focusIn = false
        this.hasBlurred = true
      })

      this.valueEmpty = c.empty(component.value) && component.value !== 0

      component.$on('change', (v) => {
        if (v.target) this.currentValue = v.target.value
        this.valueEmpty = c.empty(v)
      })

      if (componentIs !== 'textarea') {
        component.$on('up', () => {
          this.prev()
        })
        component.$on('down', () => {
          this.next()
        })
        component.$on('enter', () => {
          this.next()
        })
        component.$on('tab', () => {
          // this.next();
        })
      }
    },

    next() {
      const nextFieldSection = this.getNextCardListField()
      if (nextFieldSection) nextFieldSection.focus()
    },

    prev() {
      const prevFieldSection = this.getPrevCardListField()
      if (prevFieldSection) prevFieldSection.focus()
    },

    getField() {
      const slots = this.$slots.default()

      let component = null
      slots.forEach((slot) => {
        const ci = slot.componentInstance || null

        if (ci && ('fieldSchema' in ci || typeof ci.focus === 'function')) {
          component = ci
        }
      })

      return component
    },

    getPrevCardListField() {
      let prev = $(this.$el).prev('.field-section-item-field')

      if (!prev.length) {
        prev = $(this.$el)
          .parent()
          .parent()
          .prev('.card-list.card')
          .find('.field-section-item-field')
      }

      if (prev[0]) {
        prev = prev[0].__vue__
        return prev
      }

      return null
    },

    getNextCardListField() {
      let next = $(this.$el).next('.field-section-item-field')

      if (!next.length) {
        next = $(this.$el)
          .parent()
          .parent()
          .next('.card-list.card')
          .find('.field-section-item-field')
      }

      if (next[0]) {
        next = next[0].__vue__
        return next
      }

      return null
    },
    getGenericField() {
      const inputa = $(`#${this.uid}>:last-child`).find(':input')
      const inputb = $(`#${this.uid}`).find('input,textarea,[contenteditable],:input').last()
      let input = null

      if (inputa.length) {
        input = inputa
      } else if (inputb.length) {
        input = inputb
      }

      return input
    },

    async selectField(field) {
      let el = field
      if (field && 'fieldSchema' in field) {
        field.selectAll()
        el = field.$el
      } else if (field && field.selectAll) {
        field.selectAll()
      }

      const $el = $(el)
      if ($el && $el.length) {
        $el.addClass('input-highlighted')
        await c.throttle(() => {}, { delay: 750 })
        $el.removeClass('input-highlighted')
      }
    },

    focusField(field) {
      if (field && 'fieldSchema' in field) field.focus()
      else if (field && field.focus) field.focus()
    },

    fieldIsFocused(field) {
      const rawField = field && field.$refs && field.$refs.input ? field.$refs.input : field[0]
      if (rawField) return rawField === document.activeElement
      return null
    },

    focus() {
      const field = this.getField() || this.getGenericField()
      const focused = field && this.fieldIsFocused(field)

      if (field && !focused) {
        setTimeout(() => {
          this.focusField(field)
          this.selectField(field)
        }, 100)
      }

      this.$nextTick(() => {
        const lastSlot = this.$slots.default()[this.$slots.default().length - 1].componentInstance
        if (
          lastSlot &&
          lastSlot.$options &&
          lastSlot.$options._componentTag &&
          lastSlot.$options._componentTag === 'choose'
        ) {
          setTimeout(() => {
            lastSlot.open()
          }, 100)
        }
      })

      return true
    }
  },
  mounted() {
    this.$slots.default().forEach((s) => {
      this.listenToChild(s.componentInstance)
    })
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$fieldHeight: 3.563rem;
$fieldHeight-sm: 2.5rem;

%labelContainer {
  z-index: 1;
}

.field-section-item-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: auto !important;
  position: relative;
  padding: 0.6em !important;
  border: none !important;

  .field-section {
    position: relative;
    min-height: $fieldHeight !important;
    // &:not(.noLabel) {
    //  &::after {
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    right: 0;
    //    top: 0;
    //    bottom: 0;
    //    z-index: -1;
    //    border-radius: 5px!important;
    //    border: 2px solid transparent;
    //    transition: border 0.2s ease-in-out;
    //    z-index: 2;
    //  }
    //}
    > :first-child {
      transition: all 0.35s ease-in-out;
    }

    > input {
      z-index: 9;
      background-color: transparent !important;
    }

    > .checkbox--container {
      min-width: 100%;
      padding: 0 !important;
      input,
      .checkbox--checkmark {
        left: 1em;
        top: 6px;
        border: 1px solid $cool-gray-600;
        border-radius: 0.25em;
      }
    }

    > .choose--container {
      padding: 0 !important;
      .choose--trigger-container {
        width: 100%;
        .choose--btn {
          width: 100%;
          background-color: transparent;
          transform: none;
          box-shadow: none;
          .button--text {
            display: flex;
            justify-content: space-between;
            color: $cool-gray-800 !important;
            padding: 0 0.5em !important;
            .choose-text-ctn {
              margin-top: 0.8em;
              font-weight: 400 !important;
            }
          }
        }
      }
    }

    .date-picker--container {
      width: 100%;
      min-height: $fieldHeight;
      > span {
        width: 100%;
        min-height: $fieldHeight;
        input {
          padding-top: 20px;
        }
      }
      &.invalid {
        > a {
          border: solid 1px $deep-red-800;
        }
      }
    }

    .pill-group--container {
      left: 0 !important;
      padding: 0 !important;
      top: 0 !important;
      z-index: 3;
      .phone-input {
        margin: 0 !important;
        font-size: 0.9em !important;
        text-align: left;
        padding-left: 0.8em;
      }
      .choose--container {
        margin: 0 !important;
        .choose--trigger-container a.button--container {
          color: $flame-white !important;
          border-radius: 0.2em !important;
        }
      }
    }
  }

  &.sm {
    .field-section {
      min-height: $fieldHeight-sm !important;
      height: $fieldHeight-sm;
    }
    &.placeholder,
    &.focused,
    &.filledOut {
      .field-section {
        > :first-child:not(.pill-group--container) {
          top: 3px;
        }
      }
    }
    .input-container {
      min-height: $fieldHeight-sm !important;
      height: $fieldHeight-sm;
    }
  }

  &.isCheckbox {
    .field-section {
      > :first-child {
        padding-left: 3.5em !important;
        transform: none !important;
        top: 1.3em !important;
      }
      .checkbox--checkmark {
        top: 0.85em !important;
      }
    }
  }

  &.placeholder,
  &.focused,
  &.filledOut {
    .field-section {
      > :first-child:not(.pill-group--container) {
        transform: scale3d(0.77, 0.77, 1);
        top: 8px;
        position: absolute;
      }
    }

    &.isTextField {
      .field-section {
        > :first-child {
          transform: scale(0.8);
          top: 0.35em !important;
          left: 0.7em;
        }
      }
    }
  }

  &.invalid .field-section {
    &::after {
      border: solid 2px $deep-red-800 !important;
    }
  }

  &.focused:not(.invalid) {
    .field-section {
      &::after {
        border: solid 2px $blue-print-500 !important;
      }
    }
  }

  .field-section-below {
    margin-top: 0.1em;
    padding-left: 0.3rem;
  }
  .field-section-above {
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    width: 100%;
    text-align: center;
    padding-left: 0.3rem;
  }

  .field-section-validation {
    margin-top: 0.5em;
    padding-left: 0.3rem;
    font-weight: 500;
    font-size: 0.8em;
    color: $deep-red-800;
    text-align: left;
    width: 100%;
  }
  .field-section-validation-required {
    color: rgba($deep-red-800, 0.7);
    font-size: 0.7em;
    position: absolute;
    top: 0.1em;
    right: 0.25em;
  }
  .field-section-validation-success {
    color: $blue-print-700;
    font-size: 0.8em;
    position: absolute;
    top: 0.1em;
    right: 0.25em;
  }
  .field-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    min-height: 3em;
    cursor: pointer;

    > * {
      // Label
      &:first-child {
        &:not(.input-container) {
          @extend %labelContainer;
          font-size: 0.8rem;
          font-weight: 500;
          color: $cool-gray-600;
          line-height: 1.1;
          position: absolute;
          transform-origin: top left;
          left: 0.75em;
          top: 7px;
          z-index: 9;
          pointer-events: none;
        }

        > :not(:first-child),
        > span.text-muted,
        > small,
        > small.text-muted,
        > .text-info {
          font-weight: 500 !important;
          line-height: 1.25 !important;
          display: inline-block !important;
          word-wrap: break-word;
          border-radius: 0 !important;
          background-color: transparent !important;

          ul,
          ol {
            padding-left: 1.5em;
          }
        }
        .suggestion-button {
          font-weight: 500 !important;
        }
      }
      &.input-container {
        flex-basis: 15em;
        flex-grow: 2;
        flex-shrink: 2;
        align-self: stretch;
        height: auto !important;
        min-height: 3em;
        border: 1px solid $cool-gray-400;
        border-radius: 0.2em !important;
        padding: 1.2em 0.5em 0.35em 0.4em;
        position: relative;
        margin: 0 !important;
        color: $pitch-black;

        background: transparent !important;
        transition: border 0.2s ease-in-out;
        font-size: 1.2em;

        &.field-component {
          font-weight: 400;
          cursor: text !important;
        }

        &.select-field {
          padding: 0.35em 0.5em 0.35em 0.5em;
          select {
            border-right: 0.5em solid transparent;
          }
        }

        &.validate-invalid {
          border: 1px solid $deep-red-800;

          padding-right: 0.7em !important;
          background: transparent;
          position: relative;
          box-sizing: border-box;
          min-width: 3em !important;
          border-radius: 0.2em !important;
          &::before {
            color: $deep-red-800 !important;
          }
        }
        &.textarea {
          min-width: 50% !important;
        }
      }
    }
  }

  color: $cool-gray-800;

  @each $color, $value in $additional-colors {
    &.#{$color} {
      $yiq: $value;
      background-color: $value !important;
      color: $yiq !important;
      .field-section > :first-child {
        color: $yiq !important;
      }
    }
  }

  &.muted {
    color: $cool-gray-600 !important;

    .field-section > :first-child {
      color: $cool-gray-600 !important;
    }
  }

  &.full-label {
    .field-section {
      > :first-child {
        white-space: nowrap !important;
        word-wrap: break-word;
        flex: 2 0 auto !important;
        > :not(:first-child),
        > span.text-muted,
        > small,
        > small.text-muted,
        > .text-info {
          word-wrap: break-word;
          white-space: normal !important;
        }
      }

      > .input-container {
        flex: 1 3 30% !important;
      }
    }
  }

  .field-section--help {
    position: absolute;
    right: 18px;
    top: 15px;
    z-index: 99;
  }
}
</style>
