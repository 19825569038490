/**
 * Service to handle item reviewal processes.
 * This composable manages the state of items being reviewed and their sessions,
 * handles CRUD operations related to item reviews, and keeps track of review progress.
 */

import { toRefs, reactive, computed } from 'vue'
import ItemReviewal from '@/apollo-client/requests/itemReviewal'
import { useStore } from 'vuex'
import _ from 'lodash'

// Initial global state for session and reviewal items
export const initialState = {
  session: {
    changeOrderId: null, // ID of the change order related to the review
    quoteId: null, // ID of the quote associated with the session
    reviewer: null // Reviewer information
  },
  reviewalItems: [], // List of items that have been reviewed
  itemsForReviewal: [] // List of items that need to be reviewed
}

// Global reactive state object
const context = reactive({
  ...initialState
})

export default () => {
  // Local reactive state for managing review-related flags and loading states
  const state = reactive({
    reviewStarted: false, // Indicates whether the review process has started
    loadingItemReviewal: 0 // Flag to indicate if items are being loaded for review
  })

  // Get the Vuex store instance
  const $store = useStore()

  // Computed property to get the current logged-in user from the store
  const user = computed(() => $store.state.session.user)

  // Computed property to get the user ID
  const userId = computed(() => user.value.user_id)

  /**
   * Computed property to check if all items have been reviewed.
   * Uses lodash to compare arrays and check if all items are marked as reviewed.
   * @returns {boolean} True if all items are reviewed, otherwise false.
   */
  const isReviewed = computed(() => {
    const allReviewed = context.reviewalItems.filter((i) => i.isReviewed).map((i) => i.itemId)
    return _.isEqual(
      _.differenceWith(allReviewed, context.itemsForReviewal, _.isEqual),
      _.differenceWith(context.itemsForReviewal, allReviewed, _.isEqual)
    )
  })

  /**
   * Computed property to display the progress of the review process.
   * Returns the current item index and total number of items left to review.
   */
  const reviewProgress = computed(() => {
    const index = getNextIndexForReviewal()
    return isReviewed.value ? '' : `${index + 1}/${context.itemsForReviewal.length} options left`
  })

  /**
   * Updates the session data with new values.
   * @param {Object} session - New session data to be merged with the current session.
   */
  const setSession = (session) => {
    context.session = { ...context.session, ...session }
  }

  /**
   * Updates the current change order ID in the session.
   * @param {string} changeOrderId - New change order ID.
   */
  const setChangeOrderId = (changeOrderId) => {
    context.session.changeOrderId = changeOrderId
  }

  /**
   * Updates the reviewer information in the session.
   * @param {Object} reviewer - Reviewer information.
   */
  const setReviewer = (reviewer) => {
    context.session.reviewer = reviewer
  }

  /**
   * Updates the quote ID in the session.
   * @param {string} quoteId - New quote ID.
   */
  const setQuoteId = (quoteId) => {
    context.session.quoteId = quoteId
  }

  /**
   * Sets the list of items that need to be reviewed.
   * @param {Array} items - List of items for review.
   */
  const setItemsForReviewal = (items = []) => {
    context.itemsForReviewal = items
  }

  /**
   * Upserts a reviewal record by adding or updating an item review.
   * @param {Object} payload - Reviewal details including item ID, review status, selection status, etc.
   * @returns {Promise} Promise that resolves with the upserted reviewal item.
   */
  const upsertItemReviewal = ({
    itemId = null,
    isReviewed = false,
    hasSelection = false,
    changeOrderId = null,
    quoteId = null,
    reviewedBy = userId.value
  }) => {
    return ItemReviewal.upsertItemReviewal({
      itemReviewal: {
        itemId,
        changeOrderId: changeOrderId || context.session.changeOrderId,
        quoteId: quoteId || context.session.quoteId,
        isReviewed,
        hasSelection,
        reviewedBy: reviewedBy || context.session.reviewedBy
      }
    })
  }

  /**
   * Upserts a reviewal and updates the corresponding reviewal item in the context.
   * @param {Object} payload - Reviewal data to be upserted.
   * @returns {Promise} Promise that resolves with the upserted reviewal item.
   */
  const upsertReviewal = async (payload) => {
    const reviewal = await upsertItemReviewal(payload)
    updateReviewalItem(reviewal)
    return reviewal
  }

  /**
   * Updates an existing reviewal item in the context or adds a new one if it doesn't exist.
   * @param {Object} reviewal - The reviewal item to update or add.
   */
  const updateReviewalItem = (reviewal) => {
    const existingIndex = context.reviewalItems.findIndex((item) => item.itemId === reviewal.itemId)
    if (existingIndex !== -1) {
      context.reviewalItems.splice(existingIndex, 1, reviewal) // Update existing item
    } else {
      context.reviewalItems = [...context.reviewalItems, reviewal] // Add new item
    }
  }

  /**
   * Finds a reviewal item by its item ID.
   * @param {string} id - ID of the item to find.
   * @returns {Object|undefined} The found reviewal item or undefined.
   */
  const findReviewalByItemId = (id) => context.reviewalItems.find((item) => item.itemId === id)

  /**
   * Fetches item reviewals by the change order ID.
   * @param {string|null} changeOrderId - ID of the change order to filter reviewals by.
   * @returns {Promise} Promise that resolves with the list of item reviewals.
   */
  const getItemReviewalsByChangeOrder = (changeOrderId = null) => {
    return ItemReviewal.getItemReviewalsByChangeOrder({
      changeOrderId: changeOrderId || context.session.changeOrderId
    })
  }

  /**
   * Loads reviewal items based on the change order ID.
   * @param {string|null} changeOrderId - ID of the change order to filter items by.
   */
  const getReviewalItems = async (changeOrderId = null) => {
    state.loadingItemReviewal = 1
    const items = await getItemReviewalsByChangeOrder(
      changeOrderId || context.session.changeOrderId
    )
    context.reviewalItems = items
    state.loadingItemReviewal = 0
  }

  /**
   * Fetches a specific item reviewal by item ID and change order ID.
   * @param {Object} params - Contains itemId and changeOrderId.
   * @returns {Promise} Promise that resolves with the found reviewal item.
   */
  const getItemReviewal = ({ itemId = null, changeOrderId = null }) => {
    return ItemReviewal.getItemReviewal({
      itemId,
      changeOrderId: changeOrderId || context.session.changeOrderId
    })
  }

  /**
   * Gets the ID of the last reviewed item.
   * @returns {string|undefined} ID of the last reviewed item or undefined if no item exists.
   */
  const getLastReviewedItem = () => {
    const [lastItem] = context.reviewalItems.slice(-1)
    return lastItem?.itemId
  }

  /**
   * Gets the index of the next item to be reviewed.
   * @returns {number} Index of the next item for reviewal.
   */
  const getNextIndexForReviewal = () => {
    const lastReviewedItemId = getLastReviewedItem()
    const currentIndex = context.reviewalItems.map((i) => i.itemId).indexOf(lastReviewedItemId)
    return Math.min(currentIndex + 1, context.itemsForReviewal.length - 1)
  }

  /**
   * Gets the ID of the next item to be reviewed.
   * @returns {string|undefined} ID of the next item to be reviewed.
   */
  const getNextItemIdForReviewal = () => {
    const index = getNextIndexForReviewal()
    return context.itemsForReviewal[index]
  }

  return {
    ...toRefs(context), // Export global state as refs
    ...toRefs(state), // Export local state as refs
    isReviewed,
    reviewProgress,
    setSession,
    setChangeOrderId,
    setQuoteId,
    setReviewer,
    upsertItemReviewal,
    getItemReviewalsByChangeOrder,
    getItemReviewal,
    getReviewalItems,
    setItemsForReviewal,
    upsertReviewal,
    updateReviewalItem,
    findReviewalByItemId,
    getLastReviewedItem,
    getNextItemIdForReviewal
  }
}
