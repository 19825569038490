<template>
  <span class="linkify-content">
    <span v-html="linkify"></span>
    <div class="linkify-preview flex" v-if="preview">
      <a class="pr-2 pt-2" v-for="(p, index) in preview" :key="index" :href="p.url" target="_blank">
        <span
          v-if="p.images && p.images[0]"
          class="image-gallery--thumb"
          :style="`background: url(${p.images && p.images[0]}) no-repeat center center / cover;`"
        >
        </span>
      </a>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      preview: false
    }
  },
  computed: {
    linkify() {
      const sanitized = $('<div>')
        .text(this.content || '')
        .html()
      return c.linkify(sanitized)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
