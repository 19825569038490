<template>
  <container>
    <h3>New payment</h3>

    <card-section class="sm" v-if="directPayEnabled">
      <template #title>
        <help>
          <template #before>Is this payment a record-only?</template>
          <template #title>Record-only payments</template>
          A record-only payment is one where the payment was done by cheque or cash outside of
          Bolster. By creating a record-only payment all you are doing is recording that the payment
          took place so that your records here can be up-to-date.
          <br />
          Any payments made outside of Bolster cannot be guaranteed or verified and any items paid
          fully or partially outside of Bolster cannot be insured or warranteed..</help
        >
      </template>
      <card-list>
        <card-list-field>
          <span>Record-only payment?</span>

          <toggle v-model="transaction_is_record_only" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section v-if="!projectId">
      <template #title>Project</template>
      <choose schema="quote:quote_id" v-model="projectId" :return-array="false" />
    </card-section>

    <card-section class="sm">
      <card class="lg">
        <template #icon>
          <font-awesome-icon icon="coins" />
        </template>
        <template #title>Record-only payment</template>
        <p>
          This transaction is record-only, which means that no transaction will actually be made
          here.
        </p>
        <p>
          This is only to record payments that were made offline to help you keep track of them on
          Bolster.
        </p>
      </card>
    </card-section>

    <card-section>
      <warning v-if="/k|f/.test(quote.quote_status) && !quote.change_order_time_booked">
        <template #title
          >Project has changes that have not been approved by the client yet!</template
        >

        <p>
          Because the client hasn't approved all the changes on this project, the value for the
          items below will represent the values from the
          <strong>last approved change-order/version of this project.</strong>
        </p>

        <p>
          To synchronize the payment amounts with the latest (unapproved) version of your items you
          must manually mark the change-order/version as 'Booked'. To do that, go to the "Changes"
          tab on the project and on the top-most change-order open the action drop-down, then choose
          "Approval<span class="caret-down"></span>", and then "Mark as approved (without
          contract)".
          <strong
            >This may not represent a legal acceptance from the client unless you have some other
            contract.</strong
          >
        </p>
      </warning>
    </card-section>

    <card-section v-if="!loading">
      <template #title>To: {{ vendorName }}</template>

      <div class="flex justify-center items-center">
        <btn-group>
          <btn class="btn default sm" @click="selectAll()">Select all</btn>
          <btn class="btn default sm" @click="deselectAll()">Select none</btn>
        </btn-group>
      </div>

      <split-item
        v-for="item in selectedItems"
        :key="item.item_id"
        :item="item"
        v-model="amounts[item.item_id]"
        @fully-paid="(b) => setFullyPaid(item.item_id, b)"
        :selected="1"
        @selected="(b) => (b ? null : removeSelected('item', item.item_id))"
      />

      <split-item
        v-for="item in unselectedItems"
        :key="item.item_id"
        :item="item"
        v-model="amounts[item.item_id]"
        :selected="0"
        @selected="(b) => (b ? addSelected('item', item.item_id) : null)"
      />
    </card-section>
    <spinner :loading="1" v-else />

    <card-section>
      <card-list>
        <card-list-field>
          <span>Memo/ref</span>

          <field schema="transaction:transaction_reference" v-model="transaction_reference" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <card-list>
        <card-list-field>
          <span>{{ $t('Subtotal') }}</span>
          <field type="calculator" v-model="netPayment" format="currency" />
        </card-list-field>
        <card-list-field>
          <span>Taxes</span>
          <field type="calculator" v-model="taxPayment" format="currency" />
        </card-list-field>
        <card-list-field>
          <span>Total payment</span>
          <field type="calculator" v-model="grossPayment" format="currency" />
        </card-list-field>
      </card-list>
    </card-section>

    <Btn class="block round info mt-4" :action="saveAndClose">Save</Btn>
  </container>
</template>

<script>
import BodyMixin from '../mixins/Body'
import ObjMix from '../mixins/ObjectDistinct'
import SplitItem from '../directpay/SplitItem.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Payment',

  mixins: [ObjMix('transaction', true), BodyMixin /* AutoSave */],
  emits: ['save', 'saved'],

  data() {
    return {
      allItems: {},
      selected: [],
      amounts: {},
      fullyPaid: {},
      vendorName: '',
      vendorFull: null,
      taxFull: null
    }
  },

  watch: {
    amounts(amts) {
      this.aoSplits = Object.keys(amts).reduce(
        (acc, key) => [
          ...acc,
          {
            item_id: key,
            amount: c.toRound(amts[key], 2)
          }
        ],
        []
      )
    }
  },

  computed: {
    totalSelectedUnpaid() {
      return this.selected.reduce((acc, id) => acc + this.allItems[id].item_vendor_unpaid_gross, 0)
    },
    totalSelectedBudgetCost() {
      return this.selected.reduce((acc, id) => acc + this.allItems[id].item_recorded_cost_gross, 0)
    },
    totalSelectedNetCost() {
      return this.selected.reduce((acc, id) => acc + this.allItems[id].item_recorded_cost_net, 0)
    },
    totalSelectedTaxCost() {
      return this.selected.reduce((acc, id) => acc + this.allItems[id].item_recorded_cost_tax, 0)
    },
    grossPayment: {
      get() {
        return Object.values(this.amounts).reduce((acc, num) => acc + num, 0)
      },
      set(v) {
        this.setGross(v)
      }
    },
    taxPayment: {
      get() {
        return Object.keys(this.amounts).reduce((acc, id) => {
          const p = Math.max(
            this.taxFull && this.taxFull.tax_percentage,
            this.allItems[id].cost_tax_percentage,
            this.allItems[id].keep_tax_percentage
          )
          const g = this.amounts[id]
          const tax = (g * p) / (1 + p)
          return acc + c.toRound(tax)
        }, 0)
      }
    },
    netPayment: {
      set(v) {
        const weightedTaxRate = c.toRound(this.totalSelectedTaxCost / this.totalSelectedNetCost, 5)
        const gross = v * (1 + weightedTaxRate)
        this.grossPayment = c.toRound(gross)
      },
      get() {
        return this.grossPayment - this.taxPayment
      }
    },
    selectedItems() {
      return Object.values(this.allItems).filter((i) => this.selected.includes(i.item_id))
    },
    unselectedItems() {
      return Object.values(this.allItems).filter((i) => !this.selected.includes(i.item_id))
    },
    projectId: {
      get() {
        return this.root_quote_id
      },
      async set(id) {
        this.root_quote_id = id
        this.clearSelected()
        await this.fetchQuoteItems()
        if (this.vendor && this.vendor.vendor_id) {
          this.addSelected('vendor', this.vendor.vendor_id)
        } else if (this.item && this.item.item_id) {
          this.addSelected('item', this.item.item_id)
        }
      }
    },
    directPayEnabled() {
      return this.$store.getters['directPay/directPayEnabled']
    }
  },

  methods: {
    /**
     * Override ChangeTracking.js, this should always be dirty and saveable.
     */
    checkIfDirty() {
      return true
    },

    /**
     * Override ObjectDistinct commit. NEver commit this, basically;
     */
    commit() {},

    /**
     *
     * @param itemId
     * @param fullyPaid
     */
    setFullyPaid(itemId, fullyPaid) {
      this.fullyPaid = {
        ...this.fullyPaid,
        [itemId]: fullyPaid ? 1 : 0
      }
    },

    afterSelect() {
      this.isDirty = 1
    },

    /**
     * afterSave hook
     * @returns {Promise<*>}
     */
    async afterSave() {
      const selected = Object.keys(this.fullyPaid)
        .filter((itemId) => this.fullyPaid[itemId] > 0 && this.selected.includes(itemId))
        .reduce(
          (acc, itemId) => [
            ...acc,
            this.fullyPaid[itemId]
              ? {
                  type: 'item',
                  item_vendor_is_fully_paid: 1,
                  item_id: itemId
                }
              : {}
          ],
          []
        )
      if (selected.length) {
        return this.$store.dispatch('Item/markVendorFullyPaid', {
          selected
        })
      }

      return true
    },

    /**
     * Save
     * @param button
     * @returns {Promise<void>}
     */
    async save(button = null) {
      c.addLoadingAll(button)
      await c.throttle(() => {}, { key: 'savePayment', delay: 500 })
      try {
        this.beforeSave()
        this.$emit('save')
        await this.$store.dispatch('Transaction/projectToVendor', {
          itemAmounts: Object.keys(this.amounts).reduce(
            (acc, itemId) => [
              ...acc,
              {
                item_id: itemId,
                amount: this.amounts[itemId]
              }
            ],
            []
          ),
          quote_id: this.quoteId,
          recordOnly: true,
          transaction: {
            ...this.cast(),
            ...((this.vendor && this.vendor.vendor_id && { vendor_id: this.vendor.vendor_id }) ||
              {})
          },
          button
        })
        await this.afterSave()
        this.$emit('saved')
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage
        })
        throw new Error(e.userMessage || 'Could not save.. Please try again.')
      }

      return { saved: true }
    },

    /**
     * Set the gross value
     * @param v float
     */
    setGross(v) {
      // First assign to 'fully paid' items
      // let gross = v;
      //
      // Object.keys(this.fullyPaid)
      //   .forEach((refId) => {
      //     const itemAmount = Math.min(gross, )
      //   });
      let added = 0
      const gross = c.toRound(v)
      if (this.totalSelectedUnpaid !== 0) {
        const p = gross / this.totalSelectedUnpaid
        this.selected.forEach((id) => {
          this.amounts[id] = c.toFloor(this.allItems[id].item_vendor_unpaid_gross * p)
          added += this.amounts[id]
        })
      } else {
        const p = v / this.totalSelectedBudgetCost
        this.selected.forEach((id) => {
          this.amounts[id] = c.toFloor(this.allItems[id].item_recorded_cost_gross * p)
          added += this.amounts[id]
        })
      }

      // because we are doing floor values, we add the remainder to the first one
      const diff = gross - added
      this.amounts[this.selected[0]] += diff
    },

    /**
     * Deselect all items
     */
    deselectAll() {
      this.clearSelected()
      this.amounts = {}
    },

    /**
     * Select all items
     */
    selectAll() {
      Object.values(this.allItems).forEach((item) => this.addSelected('item', item.item_id))
    },

    /**
     * Deselect all.
     */
    clearSelected() {
      this.selected = []
    },
    async fetchQuoteItems() {
      this.addLoading()

      const { set } = await this.$store.dispatch('Item/filter', {
        filters: {
          item_id: this.itemIds.join('||'),
          quote_id: this.quoteId,
          item_type: 'cost_item',
          vendor_id: this.vendor && this.vendor.vendor_id ? this.vendor.vendor_id : '!NULL'
        }
      })

      const allItems = {}
      set.forEach((item) => {
        allItems[item.item_id] = item
      })

      this.allItems = allItems

      if (this.forceNet) {
        setTimeout(() => {
          this.netPayment = this.forceNet
        }, 1100)
      }

      this.endLoading()
    },
    addSelected(type, id) {
      if (type === 'item') {
        this.amounts = {
          ...this.amounts,
          [id]: Math.max(0, c.toRound(this.allItems[id].item_vendor_unpaid_gross))
        }
        this.selected = c.uniq([...this.selected, id])
      }
      if (type === 'vendor') {
        let itemsAdded = Object.values(this.allItems).filter(
          (item) =>
            String(item.vendor_id) === String(id) &&
            !item.item_vendor_is_fully_paid &&
            item.item_vendor_unpaid_net > 0
        )
        if (itemsAdded.length < 1) itemsAdded = Object.values(this.allItems)
        this.amounts = {
          // ...this.amounts,
          ...itemsAdded.reduce(
            (acc, item) => ({
              ...acc,
              [item.item_id]: Math.max(0, c.toRound(item.item_vendor_unpaid_gross))
            }),
            {}
          )
        }
        this.selected = c.uniq([
          // ...this.selected,
          ...itemsAdded.map((item) => item.item_id)
        ])
      }
      return this
    },
    removeSelected(type, id) {
      if (type === 'item' && this.selected.includes(id)) {
        this.selected.splice(this.selected.indexOf(id), 1)
        const { [id]: omit, ...rest } = this.amounts
        this.amounts = rest
      }
      // if (type === 'vendor') {
      //   this.selected = c.uniq([
      //     ...this.selected,
      //     ...this.allItems
      //       .filter(item => String(item.vendor_id) === String(id))
      //       .map(item => item.item_id),
      //   ]);
      // }
      return this
    }
  },
  components: {
    SplitItem
  },

  props: {
    itemIds: {
      type: Array,
      required: false,
      default: () => []
    },
    forceNet: {
      default: false
    },
    items: {
      type: Array
    },
    store: {
      default: 'Transaction'
    },
    vendor: {
      default: null
    },
    quote: {
      default: null
    },
    item: {
      default: null
    }
  },

  created() {
    eventBus.$once(`${this.uid}-selected`, async () => {
      this.transaction_is_record_only = 1
      if (!this.transaction_id) {
        if (this.quote && this.quote.quote_id) {
          this.quoteId = this.quote.quote_id
          this.projectId = this.quote.quote_id
        }
      }

      if (this.vendor && this.vendor.vendor_id) {
        const { object: vendor } = await this.$store.dispatch('Vendor/resolveObject', {
          id: this.vendor.vendor_id
        })
        this.vendorFull = vendor
        this.vendorName = vendor.vendor_name
      }
    })
  },

  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.split-item {
  display: flex;
  align-items: center;
  justify-content: stretch;
  .split-item--checkbox {
    width: 3em;
    min-width: 3em;
    max-width: 3em;
    flex: 0 0 3em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .split-item--content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .split-item--content--top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .split-item--content--bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  &.unselected {
    opacity: 0.5;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>
