<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="md"
    class="modal-mini"
    :isDirty="bodyIsDirty"
    :full="false"
    :expandable="false"
    :clickAway="false"
  >
    <template #header>
      <font-awesome-icon icon="user-helmet-safety" />
      Crew type
    </template>
    <template #body>
      <trade-type-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import TradeTypeBody from '@/components/bodies/TradeType.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'TradeType',
  mixins: [ObjectModalMixin],
  components: { TradeTypeBody },
  props: {
    type: {
      default: 'trade_type'
    }
  },
  computed: {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
