<template>
  <div class="spinner-container" :style="containerStyles" :class="{ loading: loadingLocal }">
    <slot name="skeletons" v-if="hideInitial && loadingLocal">
      <div class="flex justify-stretch gap-2 flex-wrap">
        <Skeleton class="block" width="100%" height="4rem" v-if="loading" />
        <Skeleton class="block" width="100%" height="4rem" v-if="loading" />
        <Skeleton class="block" width="100%" height="4rem" v-if="loading" />
        <Skeleton class="block" width="100%" height="4rem" v-if="loading" />
      </div>
    </slot>

    <div key="content" ref="boundingClient" class="content" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'

export default {
  name: 'SpinContainer',
  data() {
    return {
      progress: 0,
      loadingLocal: this.loading,
      boundingSize: 20,
      hideInitial: this.cloak === null ? !!this.loading : this.cloak,
      rect: null
    }
  },
  computed: {
    spinnerStyles() {
      const pixel = /^[0-9]$/.test(String(this.size || '20'))
      const size = `${this.size}${pixel ? 'px' : ''}`
      return `
          height: ${size};
          width: ${size};
          max-height: ${size};
          max-width: ${size};
          min-height: ${size};
          min-width: ${size};
          opacity: ${this.loadingLocal ? 1 : 0}`
    },
    containerStyles() {
      return `min-height: 0;
                min-width: 0;
                ${this.styles}`
    }
  },
  watch: {
    loading(val) {
      this.loadingLocal = val
    },
    loadingLocal(val) {
      if (val === 1) this.getSize()
      if (+val === 0) this.hideInitial = false
    }
  },
  methods: {
    getSize() {
      this.rect =
        this.$refs.boundingClient && this.$refs.boundingClient.getBoundingClientRect
          ? { ...this.$refs.boundingClient.getBoundingClientRect() }
          : {}

      if (this.size) {
        this.boundingSize = `${this.size}${/^[0-9]$/.test(String(this.size)) ? 'px' : ''}`
      } else if (Object.keys(this.rect).length && Math.max(this.rect.width, this.rect.height)) {
        this.boundingSize = `${Math.min(this.rect.width, this.rect.height)}px`
      } else {
        this.boundingSize = '100px'
      }
    },
    removeLoading() {
      this.loadingLocal -= 1
      if (this.loadingLocal === 0) this.hideInitial = false
    },
    endLoading() {
      this.loadingLocal = 0
      if (this.loadingLocal === 0) this.hideInitial = false
    },
    addLoading() {
      this.loadingLocal += 1
    },
    setProgress(progress) {
      this.progress = progress
    }
  },
  mounted() {
    this.getSize()
  },
  props: {
    color: {
      default: '#1abc9c'
    },
    loading: {
      required: false,
      default: 0
    },
    styles: {
      default: ''
    },
    cloak: {
      default: null
    },
    size: {
      default: '4em'
    }
  },
  components: { Skeleton }
}
</script>

<style lang="scss" rel="stylesheet/scss">
a.btn {
  &:hover {
    cursor: pointer;
  }
  &:active,
  &:focus {
    outline: 0;
  }
}

.spinner-container {
  position: static;

  .content {
    position: static;
    opacity: 1;
    height: 100%;
  }

  .loading-container {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto auto;
    padding: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  &.loading {
    .content {
      opacity: 0.5;
    }
  }

  &.inline {
    display: inline;
    margin: 0 20px;
    border: none !important;
    position: relative;
    overflow: hidden;
    .content {
      display: inline-block;
    }
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $cool-gray-800;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @each $color, $value in $additional-colors {
    &.#{$color} {
      .double-bounce1,
      .double-bounce2 {
        background-color: $value;
      }
    }
  }
}
</style>
