export default {
  languageName: 'English (US)',
  // Presentation
  Estimate: 'Estimate',
  Project: 'Project',
  Client: 'Client',
  'Call us': 'Call us',
  'Presented by': 'Presented by',
  'Expand': 'Expand',
  'Expand to see options': 'Expand to see options',
  'other customers recently upgraded their': 'other customers recently upgraded their',
  'other options for': 'other options for',
  'Included': 'Included',
  'Not included': 'Not included',
  'Preview': 'Preview',
  'Download': 'Download',
  'Subtotal': 'Subtotal',
  'Cost': 'Cost',
  'Taxes': 'Taxes',
  'Project total': 'Project total',
  'Review terms and approve this proposal': 'Review terms and approve this proposal',
  'Review terms and approve': 'Review terms and approve',
  'Request revision': 'Request revision',
  'Decline': 'Decline',
  'Options available - look for the upgrades symbol': 'Options available - look for the upgrades symbol',
  'Upgrades available': 'Upgrades available',
  'This version was approved on': 'This version was approved on',
  'Terms and conditions': 'Terms and conditions',
  'In order to continue, review and accept to the terms below': 'In order to continue, review and accept to the terms below',
  'I accept the terms and conditions': 'I accept the terms and conditions',
  'I do not accept the terms and conditions': 'I do not accept the terms and conditions',
  'Thank you for booking': 'Thank you for booking',
  'Please wait while we upgrade your records': 'Please wait while we upgrade your records',
  'Thank you': 'Thank you',
  'Project fully booked': 'Project fully booked',
  'Thank you for booking with us': 'Thank you for booking with us',
  'We will get in touch as soon as possible to confirm details': 'We will get in touch as soon as possible to confirm details',
  'Your project is fully booked': 'Your project is fully booked',
  'Come back to Bolster to follow updates or to request or approve change orders': 'Come back to Bolster to follow updates or to request or approve change orders',
  'See projects': 'See projects',

  // Email
  'Thank you for the opportunity to provide you this': 'Thank you for the opportunity to provide you this',
  'change-order': 'change-order',
  'proposal': 'proposal',
  'for project': 'for project',
  'at': 'at',
  'After you review the': 'After you review the',
  'and make desired selections (if applicable), please choose to accept, request an alteration or decline': 'and make desired selections (if applicable), please choose to accept, request an alteration or decline',
  'Review your': 'Review your',
  'You\'re receiving this email because you requested information form a company subscribed to': 'You\'re receiving this email because you requested information form a company subscribed to',
};
