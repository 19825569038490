<template>
  <div class="relative">
    <div class="quote-pres--sandbox" :style="{ opacity: 1 }">
      <div v-show="!isQuoteExpired" class="quote-pres--sandbox-contents">
        <div v-if="showSummary">
          <QuoteSummary
            v-bind="$props"
            :reference="refId"
            :store="storeName"
            :editing="editingLocal"
            :type="type"
            :isInQuoteEditor="isInQuoteEditor"
            v-model="subComponentInterface"
            @update-show-summary="updateShowSummary"
          />
        </div>
        <div
          class="!bg-transparent flex"
          :class="{ 'quote-pres--content': presentationSettings.showConfigurator }"
          v-else
        >
          <QuotePresentationTOC
            ref="sidebar"
            v-if="groups"
            :normalized="norm"
            :showSidebar="false"
            :disabled="disabledList ? disabledList : []"
            :groups="groups"
            :isInQuoteEditor="isInQuoteEditor"
            :isQuotingEngine="isQuotingEngine"
            @update-show-summary="updateShowSummary"
            @toggle-toc="togglePresentationToc"
            :presentationSettings="mergedSettings"
          />

          <div class="w-full min-h-screen flex flex-col justify-between mb-20" ref="container">
            <div
              :class="{
                classic: !presentationSettings.showConfigurator,
                'md:px-6 px-0': !isInQuoteEditor,
                'px-0 md:pl-6 md:pr-12 md:pr-16 md:pl-8': isInQuoteEditor
              }"
              class="relative quote-presentation--selections-wrapper mx-auto items-center w-full max-w-[1300px]"
            >
              <div class="w-full mb-6">
                <PresentationHeading
                  v-if="!isQuotingEngine"
                  v-bind="$props"
                  :reference="refId"
                  :store="storeName"
                  :editing="editingLocal"
                  :type="type"
                  :contentWidth="contentWidth"
                />
              </div>
              <div class="w-full mb-6">
                <Selections
                  class="quote-pres--content-item"
                  @options="(rsLen) => (countOptions = rsLen)"
                  @set-emphasized-items="(value) => (emphasizedItems = value)"
                  @groups="setGroups"
                  @disabled-list="(list) => setDisableList(list)"
                  :interactive-items="interactiveItems"
                  @update-interactive-items="setInteractiveItems"
                  :store="storeName"
                  :editable="editingLocal"
                  :isInQuoteEditor="isInQuoteEditor"
                  :presentationSettings="mergedSettings"
                  :refId="refId"
                />
              </div>

              <div
                v-if="combinedFiles.length"
                class="grid grid-cols-1 md:grid-cols-5 gap-6 p-6 lg:p-8 rounded-md bg-flame-white m-5"
              >
                <div class="flex flex-col justify-center col-span-2">
                  <h2 class="mb-3">{{ l('Attached files') }}</h2>
                  <p class="max-w-[600px]">
                    {{
                      l(
                        'Download relevant files to enhance your project or documentation such as contracts, invoices, receipts, images, or any other supporting documents.'
                      )
                    }}
                  </p>
                </div>
                <div class="flex gap-4 flex-wrap col-span-3">
                  <FileFromId class="mb-2" v-for="id in combinedFiles" :key="id" :id="id" />
                </div>
              </div>

              <div
                v-if="badges.length"
                class="grid grid-cols-1 md:grid-cols-5 gap-6 p-6 lg:p-8 rounded-md bg-flame-white m-5"
              >
                <div class="flex flex-col justify-center col-span-2">
                  <h2 class="mb-3">{{ l('Awards and badges') }}</h2>
                  <p class="max-w-[600px]">
                    {{
                      l(
                        'The achievements and recognitions demonstrate our expertise and commitment, helping you feel confident in your decision.'
                      )
                    }}
                  </p>
                </div>
                <div class="flex flex-wrap gap-4 col-span-3">
                  <FileFromId
                    class="mb-2"
                    v-for="id in badges"
                    :fullImage="true"
                    :key="id"
                    :id="id"
                  />
                </div>
              </div>

              <!--------------- PROJECT SUMMARY + TOTALS --------------->
              <PriceSummary
                :changeOrderNotApproved="changeOrderNotApproved"
                :isInQuoteEditor="isInQuoteEditor"
                :items="items"
                :presentationSettings="presentationSettings"
                :mergedSettings="mergedSettings"
                :normRefId="refId"
                :quoteId="quote_id"
                :normalized="norm"
              >
                <Btn
                  link
                  @click="() => savePDF(`${quote_name.replace(/[^0-9a-zA-Z_-]/, '_')}`)"
                  size="sm"
                >
                  <template #icon>
                    <font-awesome-icon icon="cloud-arrow-down" />
                  </template>
                  Download PDF
                </Btn>
              </PriceSummary>

              <div class="w-full flex justify-center items-center mb-14"></div>

              <div v-if="notes" id="project-notes" class="w-full bg-flame-white p-6 mb-14">
                <div class="w-full text-xl text-left mb-6">{{ l('Project notes') }}</div>
                <pre
                  class="whitespace-pre-line text-left text-base font-sans"
                ><Linkify :content="notes" /></pre>
              </div>
            </div>
            <!-- ------------- PROJECT TOTAL FOOTER ------------- -->
            <div
              v-if="showFooter && !isQuoteExpired"
              class="fixed justify-center bg-flame-white overflow-hidden border-t border-surface-100 bottom-0 w-full md:pr-4"
              :style="footerWidth"
            >
              <div class="flex flex-col md:flex-row justify-between md:h-20 w-full p-2">
                <div class="items-center gap-2 hidden md:flex">
                  <Btn
                    severity="tertiary-borderless"
                    class="flex items-center gap-2"
                    :action="$refs?.sidebar?.toggleSidebar ?? (() => {})"
                  >
                    <font-awesome-icon icon="list" fixed-width />
                    <span class="text-base">{{ l('Summary') }}</span>
                  </Btn>
                  <Btn
                    severity="tertiary-borderless"
                    class="flex items-center gap-2"
                    @click="showChat"
                  >
                    <font-awesome-icon icon="message" fixed-width />
                    <span class="text-base">{{ l('Chat with us') }}</span>
                  </Btn>
                  <!--                  <Btn-->
                  <!--                    severity="tertiary-borderless"-->
                  <!--                    class="flex items-center gap-2"-->
                  <!--                    @click="openHelpModal"-->
                  <!--                  >-->
                  <!--                    <font-awesome-icon icon="life-ring" fixed-width />-->
                  <!--                    <span class="text-base">{{ l('Legend') }}</span>-->
                  <!--                  </Btn>-->

                  <Btn
                    severity="tertiary-borderless"
                    class="flex items-center gap-2"
                    v-if="
                      !isQuoteExpired &&
                      canUserApproveQuote &&
                      changeOrderNotApproved &&
                      !isInQuoteEditor &&
                      !isRequestForEstimate
                    "
                    :disabled="!isReviewed || (!isReviewed && !hasScrolledToLastItem)"
                    @click="decline"
                  >
                    <font-awesome-icon icon="xmark-large" fixed-width />
                    <span class="text-base">{{ l('Decline') }}</span>
                  </Btn>

                  <!-- Edit toolbar -->
                  <PresentationToolbar
                    v-if="$store.getters.isCompanyUser && isInQuoteEditor"
                    v-bind="$props"
                    :reference="refId"
                    :store="storeName"
                    :type="type"
                    :editing="editingLocal"
                    @editing="(b) => (editingLocal = b)"
                    @toggle-edit-toolbar="$emit('toggle-edit-toolbar')"
                    v-model="subComponentInterface"
                  />
                </div>
                <div class="flex flex-col gap-2 md:gap-4 items-center md:flex-row w-full md:w-fit">
                  <div class="w-full cursor-pointer flex flex-col items-end">
                    <span class="text-sm font-light text-left md:text-right">
                      {{ l('Estimated price') }}:
                    </span>
                    <span v-if="quote_price_gross < 10000000" class="text">
                      <span class="text-2xl font-medium text-pitch-black font-header">
                        {{ $c(quote_price_gross) }}
                      </span>
                    </span>
                    <div v-else>
                      <span class="text-2xl font-medium text-pitch-black font-header">
                        {{ $c(quote_price_gross) }}
                      </span>
                    </div>
                  </div>
                  <div class="w-full md:mt-0" v-if="changeOrderNotApproved && !isInQuoteEditor">
                    <PresentationApprovalBtn
                      v-if="!isRequestForEstimate"
                      :interactiveItems="interactiveItems"
                      :reference="reference"
                      :hasScrolledToLastItem="hasScrolledToLastItem"
                      @bookAndSave="bookAndSave"
                      @scrollToItem="scrollToItem"
                    />
                    <Btn
                      :disabled="requestForEstimateSubmission"
                      size="lg"
                      :action="handleRequestForEstimate"
                      severity="bolster"
                      v-else
                    >
                      {{
                        !requestForEstimateSubmission
                          ? l('Request an estimate')
                          : l('Request for estimate sent')
                      }}
                    </Btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isQuoteExpired" class="quote-pres--sandbox-contents quote-pres--expired">
        <container class="pt-5">
          <Warning>
            <template #title>
              <h4>
                {{ $t('This proposal has expired and is no longer valid') }}
              </h4>
            </template>
            <btn
              v-if="showRequestRevision && canUserApproveQuote"
              class="info lg block"
              :action="requestRevision"
            >
              <template #icon>
                <font-awesome-icon icon="comment-question" fixed-width />
              </template>
              {{ $t('Request a new proposal') }}
            </btn>
          </Warning>
        </container>
      </div>
    </div>

    <!-- Review All Modal -->
    <modal ref="reviewModal" size="lg" :full="false" :clickAway="true" :expandable="false">
      <template #header>
        <h3>{{ $t('Review all items') }}</h3>
      </template>
      <template #body>
        <ReviewAll
          :presentationSettings="presentationSettings"
          :normalized="norm"
          :parentRefId="refId"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, getCurrentInstance, nextTick, watch } from 'vue'
import PresentationMixin from './PresentationMixin'
import ObjectDistinct from '../../mixins/ObjectDistinct'
import FileFromId from '../../Files/FileFromId.vue'
import PresentationHeading from './PresentationHeading.vue'
import TranslationMixin from './languages/TranslationMixin'
import Linkify from '../../ui/Linkify.vue'
import Selections from './Selections.vue'
import QuotePresentationTOC from './QuotePresentationTOC.vue'
import ReviewAll from '../review/ReviewAll.vue'
import ChatSubscriptions from '../../mixins/ChatSubscriptions'
import eventBus from '../../../eventBus'
import PresentationApprovalBtn from '@/components/quote/presentation/PresentationApprovalBtn.vue'
import useItemReviewal from '@/components/composables/ItemReviewal'
import { useMediaQuery } from '@/composables/mediaQuery'
import PriceSummary from '@/components/quote/presentation/PriceSummary.vue'

import PresentationToolbar from '@/components/quote/presentation/PresentationToolbar.vue'
import QuotePDFMixin from '@/components/quote/summary/QuotePDFMixin'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter.js'

export default {
  name: 'PresentationContents',
  mixins: [
    ObjectDistinct('quote'),
    PresentationMixin,
    TranslationMixin,
    ChatSubscriptions,
    QuotePDFMixin
  ],
  components: {
    PresentationToolbar,
    Selections,
    Linkify,
    PresentationHeading,
    FileFromId,
    QuotePresentationTOC,
    ReviewAll,
    PresentationApprovalBtn,
    PriceSummary
  },
  emits: ['toggle-edit-toolbar'],
  data() {
    return {
      showSummary: 0,
      countOptions: 0,
      emphasizedItems: 0,
      showFooter: true,
      footerAnimation: 'ten',
      usingPhone: window.innerWidth < 576,
      windowHeight: window.innerHeight,
      groups: null,
      paymentTerms: 'pay',
      interactiveItems: [],
      hasScrolledToLastItem: false,
      observer: null,
      userApprovedQuote: false,
      allRefs: [],
      loading: false,
      requestForEstimateSubmission: false
    }
  },
  props: {
    isInQuoteEditor: {
      default: false
    }
  },
  created() {
    this.currenWindowWidthSize = window.innerWidth
  },
  async mounted() {
    const elementsToObserve = ['project-notes', 'price-summary']
      .map((id) => document.querySelector(`#${id}`))
      .filter((el) => el !== null)

    if (elementsToObserve.length) {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.hasScrolledToLastItem = !!entry.isIntersecting
          })
        },
        {
          root: null,
          threshold: 0.5
        }
      )
      elementsToObserve.forEach((element) => this.observer.observe(element))
    }
    eventBus.$on('updatePaymentTerms', this.onUpdatePaymentTerms)
  },
  async beforeUnmount() {
    // Disconnect IntersectionObserver
    if (this.observer) {
      const lastItem = document.querySelector(
        `#${this.interactiveItems[this.interactiveItems.length - 1]}`
      )
      if (lastItem) {
        this.observer.unobserve(lastItem)
      }
      this.observer.disconnect()
    }

    await this.unsubscribeToNewMessages()

    eventBus.$off('updatePaymentTerms', this.onUpdatePaymentTerms)
  },
  computed: {
    disabledList() {
      const nn = this.norm
      return Object.keys(nn).filter((ref) => {
        const obj = nn[ref]
        const type = obj.type

        if (
          !obj[`${type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`] &&
          !obj[`${type}_is_included`]
        )
          return false
        if (!obj[`${type === 'cost_item' ? 'cost_item' : 'quote'}_qty_net`]) return true

        return false
      })
    },
    isReviewer() {
      if (!this.aoReviewers || !this.aoReviewers.length) return false
      const reviewUserIds = this.aoReviewers.map((r) => (r.user_id && r.user_id.toString()) || null)
      const userId = this.$store.state.session.user.user_id
      return reviewUserIds.includes(userId.toString())
    },
    isClient() {
      return this.$store.state.session.user.user_id.toString() === this.client_user_id.toString()
    },
    isSuperUser() {
      return this.$store.state.session.user.user_is_super_user
    },
    readOnly() {
      if (this.isSuperUser || this.isClient) return false
      if (this.isReviewer) {
        const reviewer = this.aoReviewers.find(
          (r) => r.user_id.toString() === this.$store.state.session.user.user_id.toString()
        )
        const permissions = JSON.parse(reviewer.asPermissions)
        if (permissions && permissions.includes('read')) return true
        return false
      }

      return true
    },
    renofiEnabled() {
      return (
        (!this.$store.getters.isCompanyUser || this.$store.state.session.user.user_is_super_user) &&
        this.$store.state.session.company &&
        this.$store.state.session.company.aoModules &&
        this.$store.state.session.company.aoModules.renofi_affiliate &&
        this.$store.state.session.company.aoModules.renofi_affiliate !== '0'
      )
    },
    items() {
      const n = this.norm
      return Object.keys(n || {}).filter(
        (r) => n[r].type === 'cost_item' && n[r].cost_item_is_included && n[r].cost_item_qty_net > 0
      )
    },
    contentWidth() {
      return this.presentationSettings.showConfigurator ? '1600px' : '800px'
    },
    showExpiredElements() {
      const currentTime = new Date().getTime()
      return (
        this.$store.getters.isCompanyUser ||
        !this.quote_time_expired ||
        this.quote_status !== 'p' ||
        currentTime < this.quote_time_expired
      )
    },
    isQuoteExpired() {
      const currentTime = new Date().getTime()
      return (
        !this.$store.getters.isCompanyUser &&
        this.quote_time_expired &&
        currentTime > this.quote_time_expired &&
        this.quote_status === 'p'
      )
    },
    canUserApproveQuote() {
      return (
        this.$route.name === 'Presentation External' ||
        !this.$store.getters.isCompanyUser ||
        this.$store.state.session.user.user_is_super_user
      )
    },
    taxes() {
      return Object.values(this.oTaxSums).filter(
        (tx) => Math.abs(tx.pcnt) >= 0.01 && Math.abs(tx.sum) >= 0.01
      )
    },
    changeOrderNotApproved() {
      if (this.userApprovedQuote) {
        return false
      }
      return (
        (this.change_order_status !== 'k' || !this.change_order_client_has_approved) &&
        this.change_order_status !== 'd' &&
        !this.readOnly
      )
    },
    isRequestForEstimate() {
      return this.quote_state === 'lead'
    },
    isQuotingEngine() {
      return this.isSuperUser && this.$route.name === 'Super Quote'
    }
  },
  methods: {
    $c: formatCurrencySymbol,
    async handleRequestForEstimate() {
      try {
        await this.$store.dispatch('Quote/requestForEstimate', {
          id: this.quote_id
        })
        this.$store.dispatch('alert', {
          text: 'Your request for an estimate has been submitted successfully.'
        })
        this.requestForEstimateSubmission = true
      } catch (e) {
        this.$store.dispatch('alert', {
          type: 'error',
          message: 'Could not submit a request for estimate.'
        })
        this.requestForEstimateSubmission = false
      }
    },
    async getChangeOrders() {
      let changeOrders = []
      if (this.type === 'quote') {
        const { set } = await this.$store.dispatch('Quote/getChangeOrders', {
          id: this.quote_id
        })
        changeOrders = set
      } else {
        changeOrders = [this.norm[this.rootRefId || this.refId]]
      }
      this.changeOrderList = changeOrders
    },
    async bookAndSave() {
      await this.book()
      this.userApprovedQuote = true
      await this.uploadToS3()
    },
    onUpdatePaymentTerms(term) {
      this.paymentTerms = term
    },
    setGroups(groups) {
      this.groups = groups
    },
    setDisableList() {
      // this.disabledList = list
    },
    showChat() {
      return this.$store.commit({
        type: 'SET_PREVIEW',
        preview: {
          channel_type: 'QUOTE_CLIENT',
          channel_type_id: `${this.quote_id}-${this.client_user_id}`
        }
      })
    },
    toggleViewAll() {
      this.$refs.reviewModal.open()
    },
    async resubscribeToMessages() {
      await this.unsubscribeToNewMessages()
      await this.subscribeToNewMessages()
    },
    updateShowSummary(value) {
      this.showSummary = value
    },
    scrollToItem(item) {
      const element = document.querySelector(`#${item}`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })

        // Remove existing active borders from other elements
        const allItems = document.querySelectorAll('.border-2')
        allItems.forEach((item) => {
          item.classList.remove('border-2', '!border-pitch-black')
        })

        // Add the border class to the element after scrolling
        setTimeout(() => {
          element.classList.add('border-2', '!border-pitch-black')
        }, 300)
      }
    },
    setInteractiveItems(items) {
      this.interactiveItems = items
    },
    openHelpModal() {
      return this.$store.dispatch(
        'modal/open',
        {
          modal: {
            name: 'PresentationOnboarding',
            quoteId: this.quote_id,
            isInQuoteEditor: this.isInQuoteEditor
          },
          go: false
        },
        { root: true }
      )
    }
  },
  setup() {
    const { isReviewed } = useItemReviewal()
    const { smallFormat, xxl } = useMediaQuery()
    const estimateSideMargin = ref(15)
    const tocSidebarVisible = ref(false)
    const collapsedQuoteSidePanel = ref(true)
    const tocWidth = computed(() => (tocSidebarVisible.value ? 400 : 0))
    const sidebarWidth = computed(() => (collapsedQuoteSidePanel.value ? 70 : 300))

    const togglePresentationToc = (isVisible) => {
      tocSidebarVisible.value = isVisible
    }

    const toggleQuoteSidepanel = (isVisible) => {
      collapsedQuoteSidePanel.value = isVisible
    }

    const $this = getCurrentInstance().proxy

    const footerWidth = ref({})
    watch(
      [sidebarWidth, xxl, tocWidth, estimateSideMargin, smallFormat],
      async () => {
        await nextTick()
        const bb = $this.$refs.container?.getBoundingClientRect()
        footerWidth.value = {
          width: `${bb.width}px`
        }
      },
      { immediate: true }
    )

    onMounted(() => {
      eventBus.$on('toggle-presentation-sidebar', togglePresentationToc)
      eventBus.$on('toggle-quote-sidepanel', toggleQuoteSidepanel)
    })

    onUnmounted(() => {
      eventBus.$off('toggle-presentation-sidebar', togglePresentationToc)
      eventBus.$off('toggle-quote-sidepanel', toggleQuoteSidepanel)
    })

    return {
      isReviewed,
      smallFormat,
      footerWidth,
      togglePresentationToc,
      tocSidebarVisible
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/quote.scss';
@import '@/theme/presentations/structure/quote.scss';

$headingHeight: 50vh;
$totalsBorderRadius: 1em;
$totalsMaxWidth: 800px;

.revisionButtonStyle {
  background: #5a6570 !important;
  color: #dedede !important;
}
.quote-presentation-totals {
  max-width: $totalsMaxWidth;
  border-radius: $totalsBorderRadius;
  margin: 0 auto;
  > div {
    margin: 0 auto;
  }
}

.total-footer-enter-active {
  transition: transform 1s;
}

.total-footer-leave-active {
  transition: transform 1s;
}

.total-footer-enter,
.total-footer-leave-to {
  transform: translateY(5em);
}

.total-footer {
  box-shadow:
    0px -6px 15px -2px rgba(16, 24, 40, 0.08) inset,
    /* Left shadow (inward) */ 0px 6px 15px -2px rgba(16, 24, 40, 0.08); /* Top shadow (outward) */

  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    box-shadow: $shadow;
  }
}

.element-replacer {
  height: 5em;
  width: 100%;
  background: transparent;
}

.quote-pres--content {
  background-color: $flame-white !important;
  display: flex !important;
  flex-direction: row;
}

.quote-pres--container {
  min-height: 0;
  flex-direction: column;
  flex: 1 1 100%;
  height: calc(100vh - 3em);

  .quote-pres--fixed-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background: rgba($pitch-black, 0.8);
    padding: 1em;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .quote-pres--fixed-nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 5em;
      height: 100%;

      &.quote-pres--fixed-btn-nav-prev {
        color: $cool-gray-300 !important;
        > a {
          background: $cool-gray-300 !important;
          color: $cool-gray-700 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $pitch-black !important;
            color: $flame-white !important;
          }
        }
      }

      &.quote-pres--fixed-btn-nav-next {
        color: $deep-red-800 !important;
        > a {
          background: $deep-red-400 !important;
          color: $deep-red-800 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $deep-red-800 !important;
            color: $flame-white !important;
          }
        }
      }
    }
  }
}

.quote-presentation--selections-wrapper {
  &:not(.classic) {
    .selections-item--wrapper,
    .quote-pres--badges,
    .quote-presentation-files {
      max-width: calc(100% - 60px);
    }

    @media (max-width: 768px) {
      .quote-presentation-totals {
        max-width: 100vw;
      }
      .quote-presentation-totals {
        max-width: 82vw;
        margin-left: 1vw;
      }
      .selection-list-item .selection-pic {
        max-width: 79vw;
      }
    }

    @media (max-width: 576px) {
      .selections-item--wrapper,
      .quote-presentation-totals,
      .quote-pres--badges,
      .quote-presentation-files {
        max-width: 90vw !important;
        margin-left: -8vw !important;
      }
    }
  }
}

#app.small-format {
  .coverHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .quoteHeading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      min-width: 230px;
      padding-left: 0;
      padding-top: 1em;
    }
  }
}

.read-only-notice {
  font-size: 0.9em;
  font-style: italic;
  color: $cool-gray-700;
  margin-top: 1em;
  margin-bottom: -1em;
  text-align: center;
}
</style>
