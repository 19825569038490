<template>
  <div class="lead-form-content w-full">
    <div
      class="field-section-container bg-white w-full"
      v-for="(field, id) in formFields"
      :key="id"
    >
      <div class="field-section bg-white mb-4 w-full">
        <label :for="field" class="block text-sm font-medium mb-1">
          {{ l(field.label) }}
        </label>
        <Field
          v-model="formData[field.key]"
          :placeholder="l(field.desc)"
          :type="field.type"
          class="w-full"
          :pt="{ root: '!w-full' }"
          :ref="field.key"
          :id="field.key"
          :disabled="submitting"
        />
      </div>
    </div>
    <!-- Custom form -->
    <Form
      @submit="(customFields) => onSubmit(customFields)"
      v-if="aoFields"
      :aoFields="aoFields"
      :aoFieldOptions="aoFieldOptions"
      :isPreview="true"
    />
    <div v-else class="button-container mt-8 w-full px-4">
      <Btn
        severity="primary-black"
        size="xl"
        :text="l('Submit')"
        :disabled="submitting"
        @click="onSubmit"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue'
import Form from '@/components/forms/Form.vue'
import useTranslation from '@/components/composables/Translation'

const formData = ref({})
const emit = defineEmits(['submit'])
const { l } = useTranslation()

const props = defineProps({
  aoFields: {
    type: Array,
    default: () => []
  },
  aoFieldOptions: {
    type: Array,
    default: () => []
  },
  formFields: {
    type: Array,
    default: () => []
  },
  submitting: {
    type: Boolean,
    default: false
  }
})

const setupFormData = () => {
  props.formFields.forEach((field) => {
    formData.value[field.key] = ''
  })
}

const onSubmit = (customFields = {}) => {
  emit('submit', {
    defaultFields: formData.value,
    customFields
  })
}

onMounted(() => {
  if (props.formFields) setupFormData()
})
</script>
