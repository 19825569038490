<template>
  <Container v-if="selected">
    <!-- Searching vendors -->
    <VendorSearch
      v-show="view === 'search'"
      @createNew="onCreateNew"
      @select="onSelect"
      @switchView="onSwitchView"
    />
    <!-- Form for creating a new vendor -->
    <CardSection v-show="view === 'create'">
      <template #title> Create new vendor </template>
      <CardList>
        <CardListField>
          <span>Email</span>
          <UniqueField
            v-model="user_email"
            schema="user:user_email"
            :validate="{
              type: 'email'
            }"
            :unique="{
              type: 'company',
              field: 'company_email',
              except: vendor_user_id ? [vendor_user_id] : [],
              exact: true
            }"
          >
          </UniqueField>
        </CardListField>
        <CardListField>
          <span>Name</span>
          <Field :required="true" schema="company:company_name" v-model="company_name" />
        </CardListField>
      </CardList>
    </CardSection>
  </Container>

  <!-- Footer action bar  -->
  <div class="fixed bottom-0 left-0 right-0 px-2 py-4 bg-white w-full flex justify-between">
    <save-or-cancel
      v-if="view === 'create'"
      :isDirty="isDirty"
      :loading="loading"
      :omitStyle="true"
      :showCancel="false"
      @save="saveAndClose"
      class="flex justify-between w-full"
    >
      <template #before>
        <div class="flex justify-center">
          <SelectionToggle v-model="view" :options="formOptions"> </SelectionToggle>
        </div>
      </template>
    </save-or-cancel>
    <div v-else class="flex justify-between w-full">
      <SelectionToggle v-model="view" :options="formOptions"> </SelectionToggle>
      <Btn @click="onSelectExisting" :disabled="!selectedVendor" severity="primary-black">
        Select existing
      </Btn>
    </div>
  </div>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import UniqueField from '@/components/ui/UniqueField.vue'
import SaveOrCancel from '@/components/ui/SaveOrCancel.vue'
import VendorSearch from '../vendor/VendorSearch.vue'

export default {
  name: 'VendorNew',
  mixins: [
    ObjectManipulator(
      'vendor' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],
  components: {
    UniqueField,
    VendorSearch,
    SaveOrCancel
  },
  methods: {
    /**
     * Selecting a vendor
     */
    onSelect(vendor) {
      this.selectedVendor = vendor
    },
    /**
     * Creating a new vendor
     */
    onCreateNew() {
      this.createNew = true
      this.$emit('create')
    },
    /**
     * Switching between create and search
     */
    onSwitchView(view) {
      this.view = view
    },
    /**
     * Handle creating vendor from existing company
     */
    async onSelectExisting() {
      this.setField('vendor_company_id', this.selectedVendor.company_id)
      await this.$nextTick()
      this.saveAndClose()
    }
  },
  data() {
    return {
      formOptions: [
        {
          text: 'Search for vendor',
          value: 'search'
        },
        {
          text: 'Create new vendor',
          value: 'create'
        }
      ],
      view: 'search',
      createNew: false,
      selectedVendor: null
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
