// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'line_item',

  skipAudit: true,

  fields: {
    line_item_id: {
      type: 'string',
      filter: false,
      format: false,
      save: true,
      mapTo: false,
    },
    invoice_id: {
      type: 'number',
      filter: false,
      format: false,
      save: true,
      mapTo: false,
    },
    item_id: {
      type: 'string',
      filter: true,
      format: false,
      save: true,
      mapTo: false,
    },
    quote_id: {
      type: 'number',
      filter: true,
      format: false,
      save: true,
      mapTo: false,
    },
    status: {
      type: 'string',
      filter: true,
      format: false,
      save: true,
      mapTo: false,
    },
  },
  generateVueActions() {
    return {

    };
  },
};
