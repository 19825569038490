<template>
  <Dialog
    class="modal"
    ref="modal"
    v-model:visible="visible"
    modal
    :dismissable-mask="canClickAway"
    :class="modalClasses"
    :pt="{
      ...passThrough,
      ...pt
    }"
    :ptOptions="ptOptions"
    :hasSidePanel="!!hasSidePanel"
    :footerAccent="!!footerAccent"
    :hasHeaderBorder="!!hasHeaderBorder"
    :includeGutter="!!includeGutter"
    :isResetPassword="isResetPassword"
    :closex="!!$slots.header || title"
    :closeable="closeable"
    :full="!!full"
    :maximized="full"
    :size="size"
    :scrollable="scrollable"
    :autoZIndex="false"
    :fixed="fixed"
    :draggable="moveable"
    @after-hide="close"
    @mousemove="onDrag"
    @touchmove="onDrag"
    @mouseup="stopDrag"
    @touchend="stopDrag"
  >
    <template #header>
      <div class="w-full pt-safe-0" :style="modalHeaderStyle">
        <div v-if="showHeader" class="modal-header-content flex items-center gap-3 text-2xl">
          <slot name="header">
            {{ title }}
          </slot>
        </div>
        <slot name="header-content" />
      </div>
    </template>
    <template #closeicon>
      <slot name="closeicon">
        <font-awesome-icon
          v-if="showTopClose ?? true"
          class="text-surface-950 hover:text-surface-600 transition"
          size="xl"
          icon="xmark"
        ></font-awesome-icon>
        <div v-else></div>
      </slot>
    </template>
    <template #footer v-if="showFooter && $slots.footer">
      <div class="modal-footer" :class="isDirty ? 'modified' : ''">
        <slot name="footer"> </slot>
      </div>
    </template>
    <template #default>
      <div
        class="modal-body flex flex-col relative h-full"
        :class="[
          {
            '!basis-full !max-h-full': !scrollable,
            '!basis-full !max-h-full h-full grow shrink': (fixed || full) && !scrollable,
            'basis-full grow min-h-full': scrollable
          }
        ]"
      >
        <slot name="body"> Default body </slot>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useSlots, computed } from 'vue'
import { useStore } from 'vuex'
import eventBus from '../../eventBus'
import { useDeviceStore } from '@/stores/device'
/**
 * Class will not work on this component, instead use:
 * classes
 *
 *
 * Emits;
 *    -modalExpand (root)
 *    -modalExpanded (root)
 */
export default {
  name: 'Modal',
  setup(props) {
    const deviceStore = useDeviceStore()
    const slots = useSlots()
    const showFooter = computed(() => slots.footer && slots.footer().length > 0)
    const showHeader = computed(() => (slots.header && slots.header().length > 0) || props.title)

    return {
      deviceStore,
      showFooter,
      showHeader
    }
  },
  provide() {
    return this.providedValues
  },
  emits: ['clickAway', 'open', 'opened', 'close', 'closed'],
  data() {
    return {
      /**
       * When 0, destroys the content
       */
      isOpen: 0,

      /**
       * When is 0, hides the content
       * without destroying, allowing
       * fields and inputs that haven't yet
       * completed bubbling up to finish
       * gracefully before isOpen is set
       * to 0 and destroys it.
       */
      isHidden: 1,
      dragging: false,
      startingPosition: {},
      startingClick: {},
      offset: {},
      destroyAt: 200,
      destroying: false,
      expanded: this.full || this.size === 'full'
    }
  },
  computed: {
    visible: {
      get() {
        return !!this.isOpen
      },
      set(newValue) {
        this.isOpen = newValue
      }
    },
    canClickAway() {
      return this.clickAway && !this.$store.state.session.disableClickaway
    },
    modalLabel() {
      const parent = this.$parent.$options.name
      return parent.toLowerCase().replace(/[^a-z]/g, '_')
    },
    providedValues() {
      return {
        inModal: true,
        modal: this.modal,
        go: this.go !== false && this.go !== 0 && this.modal.go !== false && this.modal.go !== 0
      }
    },
    modalClasses() {
      return [
        {
          opened: !this.isHidden,
          closed: this.isHidden,
          'modal-full': this.expanded
        },
        this.classes
      ]
    },
    passThrough() {
      const passThrough = {
        mask: {
          style: ['backdrop-filter: blur(1px)', `z-index: ${this.zIndex}`]
        },
        root: {
          class: [
            {
              opened: !this.isHidden,
              closed: this.isHidden,
              '!w-screen min-w-screen !max-w-screen h-screen min-h-screen !max-h-screen':
                this.expanded
            },
            this.classes
          ]
        }
      }

      return passThrough
    },
    modalHeaderStyle() {
      if (this.deviceStore.isMobileBrowser) {
        return 'flex-direction: row-reverse;'
      }
      return this.deviceStore.isNative && this.expanded
        ? // This is probably not advisable, but I'm just converting existing stuff - Sera
          `width: calc(100vw - ${this.deviceStore.isLandscape ? 'env(safe-area-inset-left) - env(safe-area-inset-right)' : 0}px); height: calc(${this.$store.state.session.headerHeight} + env(safe-are-inset-top))px;`
        : ''
    },
    positionStyle() {
      if (this.dragging && this.offset && (this.offset.x || this.offset.y)) {
        return {
          position: 'relative',
          left: `${this.offset.x}px`,
          top: `${this.offset.y}px`,
          transition: 'none',
          opacity: 1,
          'z-index': this.zIndex
        }
      }
      return {
        position: 'relative',
        left: '0px',
        top: '0px',
        transition: 'all 1s',
        opacity: 1,
        'z-index': this.zIndex
      }
    },
    deviceWidth() {
      return this.$store.state.session.deviceWidth
    }
  },
  methods: {
    closeCallback() {},
    isTopModal() {
      const last = $('.modals-gutter>div:last-child')[0]
      return last === this.$parent.$el || last === this.$refs.appendToBody
    },
    expandToggle() {
      eventBus.$emit('modalExpand', this)
      this.expanded = !this.expanded
      this.$nextTick(() => {
        eventBus.$emit('modalExpanded', this)
        eventBus.$emit('resize', this)
      })
    },
    away(e) {
      // drop containers don't count
      // nor do modals count when they are opened ON TOP OF this one
      const $target = e.target && $(e.target)
      if (
        !this.isHidden &&
        this.isOpen &&
        !$target.closest('.drop').length &&
        !$target.closest('.drop--container').length &&
        !$target.closest('.drop-container').length &&
        !$target.is('.no-click-away') &&
        !$target.is('.drop') &&
        !$target.is('.drop--container') &&
        !$target.is('.drop-container') &&
        !$target.closest('.no-click-away').length &&
        this.isTopModal()
      ) {
        this.$emit('clickAway')
        if (this.canClickAway) this.close()
      }
    },
    toggle() {
      return !this.isHidden ? this.close() : this.open()
    },
    async open() {
      // modal.open and opened() callbacks called on mount
      this.offset = { x: 0, y: 0 }
      await this.appendToBody()
      if (this.modal && this.modal.open) this.modal.open.call(this)
      this.$emit('open')
      this.isOpen = 1
      this.isHidden = 0
      this.$emit('opened')
      if (this.modal && this.modal.opened) this.modal.opened.call(this)
      return true
    },
    close(params) {
      const delay = params ? params.delay : this.destroyInterval
      // modal.close() and modal.closed() callbacks
      // get called on destroy
      this.$emit('close')
      this.visible = false
      setTimeout(async () => {
        if (this.isHidden) return

        if (this.hideOnly) {
          this.isHidden = 1
          this.visible = false
          return
        }

        if (this.modal) {
          await this.$store.dispatch('modal/close', { modal: this.modal })
          this.isOpen = 0
          this.isHidden = 1
          this.$emit('closed')
        } else {
          this.$emit('closed')
          this.isOpen = 0
          this.isHidden = 1
        }
      }, delay)
    },
    confirmClose() {
      this.$refs.confirm.open()
    },
    startDrag(e) {
      const rect = this.$refs.modal.getBoundingClientRect()
      this.destroyAt = rect.width / 2
      this.startingPosition = { x: rect.left, y: rect.top }
      this.startingClick = { x: e.clientX, y: e.clientY }
      this.offset = { x: 0, y: 0 }
      this.dragging = true
    },
    onDrag(e) {
      if (this.dragging) {
        this.offset = {
          x: e.clientX - this.startingClick.x,
          y: e.clientY - this.startingClick.y
        }
      }
    },
    stopDrag() {
      if (this.dragging) {
        this.dragging = false
      }
    },
    appendToBody() {
      if (!this.modal) {
        $(this.$refs.appendToBody).appendTo('.modals-gutter')
      }
      return this.$nextTick()
    },
    onOpen() {
      eventBus.$emit('blurContents')
      this.$store.dispatch('addDisableScrolling')
    },
    onClose() {
      eventBus.$emit('unblurContents')
      this.$store.dispatch('removeDisableScrolling')
    },
    handleWindowKeydown(e) {
      if (!e || !e.key) return
      const key = e.key.toLowerCase()
      if (key === 'escape') {
        // Check if there's an action with the 'esc' hotkey, otherwise call close()
        const actions = this.modal?.actions || {}
        const escAction = Object.values(actions).find((action) => action.hotkey === 'esc')
        if (escAction?.action) {
          escAction.action()
        } else {
          this.close()
        }
      }
    }
  },
  created() {
    // We will trigger a blur
    // on website contents when a modal is open
    // that isn't connected to the $store
    if (!this.modal) {
      eventBus.$on('open', this.onOpen)
      eventBus.$on('close', this.onClose)
    }
  },
  mounted() {
    if (this.startOpen) this.open()
    window.addEventListener('keydown', this.handleWindowKeydown)
    eventBus.$on('closeModal', this.close)
  },
  beforeUnmount() {
    this.destroying = true
    this.isOpen = 0
    this.isHidden = 1

    eventBus.$off('open', this.onOpen)
    eventBus.$off('close', this.onClose)
    eventBus.$off('closeModal', this.close)
    window.removeEventListener('keydown', this.handleWindowKeydown)
    $(this.$refs.appendToBody).remove()
    try {
      if (this.modal) {
        this.modal.close(this)
        this.modal.closed(this)
      }
    } catch (e) {
      // do nothing
    }
  },
  props: {
    hideOnly: {
      default: false
    },
    // Modal object given from VUEX, only when called from global state
    modal: {
      required: false,
      default: false
    },
    showTopClose: {
      type: Boolean,
      default: null
    },
    clickAway: {
      required: false,
      default: true
    },
    /**
     * Open initially by default?
     */
    startOpen: {
      type: Boolean
    },
    /**
     * Can be sm, md, lg, full
     */
    size: {
      type: String,
      default() {
        return 'md'
      }
    },
    /**
     * Pixel size
     */
    width: {
      type: Number,
      default: null
    },
    /**
     * any kind of size
     */
    maxWidth: {
      default: false
    },
    /**
     * Start full sized?
     */
    full: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    /**
     * Can it be full-sized?
     */
    expandable: {
      required: false,
      default() {
        return true
      }
    },
    /**
     * Allow to expand/contract
     */
    resizeable: {
      type: Boolean,
      default() {
        return true
      }
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    /**
     * Allow to drag
     */
    moveable: {
      type: Boolean,
      default() {
        return true
      }
    },
    /**
     * Title in modal-header
     */
    closable: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    /**
     * Title in modal-header
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Z-index
     */
    zIndex: {
      type: Number,
      required: false,
      default() {
        const store = useStore()
        return store && store.state && store.state.modal ? store.state.modal.topZIndex : 2
      }
    },
    isDirty: {
      required: false,
      default: false
    },
    includeGutter: {
      required: false,
      default: false
    },
    hasSidePanel: {
      required: false,
      default: false
    },
    hasHeaderBorder: {
      required: false,
      default: true
    },
    classes: {
      default: ''
    },
    mask: {
      default: true
    },
    destroyInterval: {
      // default: 1500,
      default: 500
    },
    /**
     * Force fixed, for all modes, mobile and small format
     */
    fixed: {
      default: false
    },
    footerAccent: {
      default: false
    },
    isResetPassword: {
      default: false
    },
    pt: {
      type: Object,
      default: () => {}
    },
    ptOptions: {
      type: Object,
      default: () => ({
        mergeProps: true
      })
    },
    closeable: {
      type: Boolean,
      default: true
    }
  }
}
</script>
