/**
 * This file is for storing all the client services/features offered in the client portal
 * topDropDown - is for the side panel top context menu options
 * bottomDropDown - is for the side panel bottom context menu options
 *
 * This content was placed here as one central location to change the user type features, services and menu items
 */
const services = ({ store }) => {
  const topDropDown = {
    title: 'Project',
    links: [
      {
        label: 'Switch projects',
        command: () => store.dispatch('to', '/home')
      }
    ]
  }

  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Edit profile',
        command: () => store.dispatch('to', 'profile')
      },
      {
        label: 'Switch projects',
        command: () => store.dispatch('to', '/home')
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        command: () => store.dispatch('logout')
      }
    ]
  }

  const features = [
    {
      title: 'Task list',
      icon: 'list',
      to: 'vendor/task-list',
      desc: 'View, search and complete all your assigned tasks.'
    }
  ]
  return {
    features,
    topDropDown,
    bottomDropDown
  }
}

export { services }
