import { computed, ref } from 'vue'

export default {
  useUndoRedo(args = {}) {
    const { bundle = 1000 } = args
    const undos = ref([])
    const redos = ref([])
    const canRedo = computed(() => redos.value.length)
    const canUndo = computed(() => undos.value.length)

    const undo = () => {
      if (!undos.value.length) return null

      const [undo, ...rest] = undos.value
      undos.value = rest

      addRedo(undo)

      return c.imm(
        Object.keys(undo).reduce(
          (acc, refId) => ({
            ...acc,
            [refId]: undo[refId][0]
          }),
          {}
        )
      )
    }

    const redo = () => {
      if (!redos.value.length) return null

      const [redo, ...rest] = redos.value
      redos.value = rest

      addUndo(redo, false)

      return c.imm(
        Object.keys(redo).reduce(
          (acc, refId) => ({
            ...acc,
            [refId]: redo[refId][1]
          }),
          {}
        )
      )
    }

    let acc = {}
    const addUndo = (changes, clearRedos = true) => {
      acc = { ...acc, ...changes }
      c.throttle(
        () => {
          undos.value = [...undos.value, acc]
          acc = {}
          if (clearRedos) redos.value = []
        },
        { delay: bundle, debounce: true }
      )
    }

    const addRedo = (changes) => {
      redos.value = [...redos.value, changes]
    }

    return {
      addUndo,
      undo,
      redo,
      canUndo,
      canRedo
    }
  }
}
