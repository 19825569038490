
/**
 * Detects whether field is an lat lon field
 * @param string field
 * @return bool
 */
const isLatLonField = field => /^(.*?)_latlon$/.test(field);

/**
 * Detects whether field is json object
 * @param string field
 * @return bool
 */
const isJsonObjectField = field => /^o[A-Z](\w+)$/.test(field);

/**
 * Detects whether field is a json array
 * @param string field
 * @return bool
 */
const isJsonArrayField = field => /^(ao|a|as|aa)[A-Z](\w+)$/.test(field);

/**
 * Detects whether field is an array
 * @param string field
 * @return bool
 */
const isArrayField = field => /^(.*?)_ids$/.test(field)
  || isJsonArrayField(field)
  || isLatLonField(field);
/**
 * Detects whether field is a json array
 * @param string field
 * @return bool
 */
const isFlatArrayField = field => /^((as[A-Z](\w+))|(.*_ids))$/.test(field)
    || isLatLonField(field);

/**
 * Detects whether field is a list of keywrds separated by comma
 * @param string field
 * @return bool
 */
const isKeywordField = field => /_keywords$/.test(field);

/**
 * Detects whether field is a json array
 * @param string field
 * @return bool
 */
const isJsonArrayOfStringsField = field => /^(as)[A-Z](\w+)$/.test(field);

/**
 * Returns whether the field is numeric of some kind
 * @param string field
 * @return bool
 */
const isNumericField = field => /_net|_gross|_tax|_percent|_percentage|_hours|_markup|(_order$)|_is_|_use_|_has_|_time(_|$)|_count(_|$)|_show_|(_locked$)|_link_|(_emphasis$)|(_by$)/.test(field);

/**
 * Returns whether field should be an bool-like int value 1|0
 * @param string field
 * @return bool
 */
const isTinyIntField = field => /_is_|_has_|_use_|_show_|(_locked$)/.test(field);

/**
 * Returns whether field should be an int value
 * @param string field
 * @return bool
 */
const isIntField = field => /(_order$)|_is_|_use_|_has_|_time(_|$)|_count(_|$)|_show_|(_locked$)|_link_|(_emphasis$)|(_by$)/.test(field);

/**
 * Returns whether field should be a float value
 * @param string field
 * @return bool
 */
const isPhoneField = field => /_phone(_|$)/.test(field) && !isTinyIntField(field);

/**
 * Returns whether field should be a float value
 * @param string field
 * @return bool
 */
const isFloatField = field => isNumericField(field) && !isIntField(field);

/**
 * Get minimum significant decimal places depending on the field
 * @param string field    name of a field "client_id" for example
 * @return float
 */
const getFieldSigDecimals = (field) => {
  if (/(_percentage|_percent)(_.*?)?$/.test(field)) {
    return 0.000000001;
  } else if (/(_count|_id)(_.*?)?$/.test(field)) {
    return 1;
  } else if (/(_net|_gross|_tax|_sum)(_.*?)?$/.test(field)) {
    return 0.01;
  }

  return 0.01;
};

/**
 * @param string field    name of a field "client_id" for example
 * @return bool
 */
const isNumberField = field => isNumericField(field);

/**
 * Is this a sensitive field that should be omitted from
 * echo?
 * @param string field     name of a field "client_id" for example
 * @return bool
 */
const isSensitiveField = field => /company_|password/.test(field);

/**
 * Is this field meant to be an array or object field
 * display in JSON format in the database and for echo?
 * @param string field    name of a field "client_id" for example
 * @return bool
 */
const isJsonField = field => isJsonObjectField(field)
  || isJsonArrayField(field)
  || isArrayField(field);

export default {
  isJsonField,
  isSensitiveField,
  isNumberField,
  isNumericField,
  getFieldSigDecimals,
  isFloatField,
  isPhoneField,
  isIntField,
  isTinyIntField,
  isFlatArrayField,
  isJsonArrayField,
  isKeywordField,
  isArrayField,
  isJsonArrayOfStringsField,
  isJsonObjectField,
  isLatLonField,
};
