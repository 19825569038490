<template>
  <div
    class="panel-container"
    :class="{ containered: container }"
    :style="[customStyle]"
    v-if="visible"
  >
    <slot name="above-panel"> </slot>
    <ScrollContainer v-if="container">
      <Container :size="width" class="justify-stretch">
        <slot> </slot>
      </Container>
    </ScrollContainer>
    <template v-else>
      <slot> </slot>
    </template>
  </div>
</template>

<script>
/**
 * Modifier classes:
 * .column: column justified center
 * .full: full width
 * .stretch: stretch alighment in container
 * .fixed-layout: max height 100%, overflow hidden
 * .fixed-full: same as fixed-layout with no padding either
 * .scroll-vertical scroll y
 * .scroll-horizontal scroll sideways
 */
export default {
  name: 'Panel',
  data() {
    return {}
  },
  computed: {
    visible() {
      return this.$parent?.tabLocal?.indexOf(this.title) > -1
    }
  },
  watch: {
    badge() {
      this.$parent.refresh()
    },
    dot() {
      this.$parent.refresh()
    }
  },
  methods: {},
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    iconFontFamily: {
      default: null
    },
    icon: {
      type: [String, Array],
      required: false
    },
    badge: {
      type: [Boolean, Number],
      required: false,
      default: false
    },
    dot: {
      type: [Number, Boolean],
      required: false,
      default: false
    },
    container: {
      default: true
    },
    iconPadding: {
      default: '0'
    },
    spacer: {
      default: false
    },
    width: {
      type: String
    },
    customStyle: {
      type: String,
      default: ''
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.panel-container {
  flex: 1 1 100%;
  display: flex;
  overflow: hidden;
  overflow-y: hidden;
  height: 100%;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  contain: strict;
  &.containered {
    > .scroll-container--container {
      padding: 2em 2em 0 2em;
      > .scroll-container--inner {
        margin-bottom: 50vh !important;
        @media (max-width: 576px) {
          margin-bottom: 10vh !important;
        }
        height: auto !important;
        min-height: 100% !important;
      }
    }
  }
  &:not(.containered) {
    padding: 1em 2em 0 2em;
  }
  border: 0;

  %scrollbar {
    $scrollBarWidth: 15px !default;

    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      height: $scrollBarWidth;
      display: block;
    }
    &::-webkit-scrollbar-corner {
      display: block;
      background: rgba($cool-gray-500, 0.6);
      border-radius: 50%;
      padding: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      width: $scrollBarWidth;
      height: $scrollBarWidth;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba($cool-gray-400, 0.6);
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba($cool-gray-400, 0.25);
    }
  }

  &.scroll-vertical {
    > .cc-container,
    > .scroll-container--container {
      overflow-y: scroll;
      @extend %scrollbar;
      > .scroll-container--inner {
        > .cc-container {
        }
      }
    }
  }
  &.scroll-horizontal {
    > .cc-container,
    > .scroll-container--container {
      overflow-x: scroll;
      @extend %scrollbar;
      > .scroll-container--inner {
        > .cc-container {
        }
      }
    }
  }

  &.column {
    > .cc-container,
    > .scroll-container--container {
      > .scroll-container--inner {
        > .cc-container {
          overflow: visible !important;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }
      }
    }
  }

  &.full {
    > .cc-container,
    > .scroll-container--container {
      max-width: unset !important;
      width: 100% !important;
      flex-basis: 100% !important;

      > .scroll-container--inner {
        max-width: unset !important;
        width: 100% !important;
        flex-basis: 100% !important;
        > .cc-container {
          max-width: unset !important;
          width: 100% !important;
          flex-basis: 100% !important;
        }
      }
    }
  }

  &.stretch {
    > .cc-container,
    > .scroll-container--container {
      align-items: stretch !important;

      > .scroll-container--inner {
        align-items: stretch !important;
        > .cc-container {
          align-items: stretch !important;
        }
      }
    }
  }

  &.p-0 {
    > .cc-container,
    > .scroll-container--container {
      padding: 0;
      > .scroll-container--inner {
        padding: 0;
        > .cc-container {
          padding: 0;
        }
      }
    }
  }
  &.m-0 {
    > .cc-container,
    > .scroll-container--container {
      > .scroll-container--inner {
        margin-bottom: 0 !important;
        > .cc-container {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &.fixed-layout {
    max-height: 100%;
    height: 100%;
    overflow: hidden;

    &.containered {
      > .cc-container,
      > .scroll-container--container {
        max-height: 100%;
        height: 100%;
        overflow: hidden;
        > .scroll-container--inner {
          max-height: 100%;
          height: 100%;
          overflow: hidden;
          > .cc-container {
            max-height: 100%;
            height: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }

  &.fixed-full {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;

    &.containered {
      > .cc-container,
      > .scroll-container--container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        padding: 0;
        > .scroll-container--inner {
          height: 100%;
          width: 100%;
          overflow: hidden;
          padding: 0;
          > .cc-container {
            height: 100%;
            width: 100%;
            overflow: hidden;
            padding: 0;
          }
        }
      }
    }
  }

  &.panel--scrolling-disabled {
    overflow-y: hidden !important;

    > .cc-container,
    > .scroll-container--container {
      overflow-y: hidden !important;
      > .scroll-container--inner {
        overflow-y: hidden !important;
        > .cc-container {
          overflow-y: hidden !important;
        }
      }
    }
  }
}

#app.small-format {
  .panel-container {
    background: $pitch-black;
    padding-top: 0 !important;
    &.containered {
      > .scroll-container--container {
        padding: 0 0 0 0;
      }
    }
    &:not(.containered) {
      padding: 1em 0 0 0;
    }
    > :not(.button--container):not(.panel-button-space) {
      background: $cool-gray-100;
    }
    .panel-button-space {
      height: 2em;
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $cool-gray-500;
    }
  }
}
</style>
