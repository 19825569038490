import _ from '../Helpers'
import User from './User'
import Company from './Company'

export default {
  type: 'client',

  possibleStatuses: ['a', 'i', 'b', 'l'],

  skipAudit: true,

  leadListPresets: [],

  listPresets: [
    {
      title: 'Active',
      description: 'All clients and potential clients whose status is set to active.',
      filters: {
        client_status: '!i&&!d&&!x&&!h'
      },
      filterText: {
        client_status: 'Active'
      }
    },
    {
      title: 'Awaiting estimates',
      description: "Clients that don't have any estimates yet.",
      filters: {
        client_status: 'a',
        client_count_quotes: '<1'
      },
      filterText: {
        client_status: 'Active',
        client_count_quotes: 'None'
      }
    },
    {
      title: 'Unapproved estimates',
      description: "Clients with an estimate built for them that they haven't approved yet.",
      filters: {
        client_status: 'a',
        client_count_quotes_pending: '>0'
      },
      filterText: {
        client_status: 'Active',
        client_count_quotes_pending: 'At least one'
      }
    },
    {
      title: 'With booked projects',
      description: 'Clients that have an active booked or in-progress project.',
      filters: {
        client_status: 'a',
        client_count_projects_active: '>0'
      },
      filterText: {
        client_status: 'Active',
        client_count_projects_active: 'Any'
      }
    },
    {
      title: 'Previous',
      description: 'Previous clients that booked a project in the past.',
      filters: {
        client_count_projects: '>0',
        client_count_projects_active: '<1'
      },
      filterText: {
        client_count_projects: 'Any',
        client_count_projects_active: 'None'
      }
    },
    {
      title: 'Inactive',
      description:
        'Previous clients, potential clients and leads that were de-activated or abandoned.',
      filters: {
        client_status: 'i||d||x'
      },
      filterText: {
        client_status: 'Inactive'
      }
    }
  ],

  fields: {
    client_user_id: {
      type: 'string',
      mapTo: 'user'
    },
    client_company_id: {
      type: 'string',
      mapTo: 'company'
    },
    activity_desc: {
      type: 'string',
      save: false
    },
    client_lead_message: {
      type: 'string',
      save: false
    },
    oMeta: {
      type: 'object',
      save: false
    },
    client_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewClient'
    },
    client_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      suggestedFilter: true,
      visible: true
    },
    client_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
      suggestedFilter: true,
      visible: true
    },
    client_time_active: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    client_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user'
    },
    owner_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
      reload: true
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company'
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      reload: true,
      normalize: false
    },
    creator_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
      trackChanges: false,
      normalize: false
    },
    client_time_waiting: {
      type: 'int',
      filter: false,
      format: 'datetime',
      mapTo: false,
      reload: true
    },
    client_count_tasks_incomplete: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      suggestedFilter: true
    },
    client_count_tasks_complete: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    client_count_quotes: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    client_count_files: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    client_count_quotes_pending: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Number of proposals',
      suggestedFilter: true
    },
    client_count_quotes_declined: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Number of declined proposals'
    },
    client_count_quotes_booked: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Number of currently booked projects',
      suggestedFilter: true
    },
    client_count_quotes_in_progress: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Number of in-progress projects'
    },
    client_count_quotes_ever_booked: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Number of booked projects ever'
    },
    client_sum_quotes_pending_gross: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Estimates value',
      suggestedFilter: true
    },
    client_sum_quotes_booked_gross: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Projects value',
      visible: true
    },
    client_sum_quotes_total_gross: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    client_sum_invoices_outstanding: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    client_sum_invoices_pastdue: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    client_count_quotes_active: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Active proposals or projects'
    },
    client_count_projects_active: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Active projects',
      suggestedFilter: true
    },
    client_count_invoices_outstanding: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Outstanding invoices',
      suggestedFilter: true
    },
    client_count_invoices_pastdue: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Past-due invoices'
    },
    ...User.fields,
    ...Company.fields,
    lead_source_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'lead_source'
    },
    lead_rotation_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'lead_rotation'
    },
    location_ids: {
      type: 'array',
      mapTo: 'location',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    },
    client_owner: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
      suggestedFilter: true,
      visible: true,
      defaultSetting: true
    }
  },

  actions: {
    // send: {
    //   text: 'Send email..',
    //   icon: 'paper-plane',
    //   action: 'Client/email',
    //   selectionRequired: true,
    //   multiple: true,
    // },
    quoteClient: {
      text: 'New estimate..',
      icon: 'file-signature',
      action: 'Client/newEstimate',
      selectionRequired: true,
      multiple: false,
      visible: (selected) =>
        selected.filter((s) => s.client_status === 'a').length === selected.length,
      collapse: false
    },
    abandon: {
      text: 'Abandon lead',
      icon: 'user-slash',
      action: 'Client/abandon',
      selectionRequired: true,
      multiple: true,
      class: 'danger',
      visible: (selected) =>
        selected.filter((s) => s.client_status === 'l').length === selected.length
    }
  },

  generateVueActions() {
    return {
      async linkToUser({ dispatch }, payload) {
        const { user_id, client_id } = payload

        return dispatch('partialUpdate', {
          selected: [
            {
              client_user_id: user_id,
              client_id
            }
          ]
        })
      },

      /**
       *
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async getSelfClient({ dispatch, rootState }) {
        const companyId = rootState.session.company.company_id
        const { set } = await dispatch('search', {
          filters: {
            client_company_id: companyId
          }
        })

        if (set.length) return set[0]

        const { object } = await dispatch('save', {
          object: {
            company_id: companyId,
            client_company_id: companyId,
            client_status: 'a',
            client_owner: rootState.session.user.user_id
          }
        })

        return object
      },

      /**
       * Get user for specific client
       * @param dispatch
       * @param payload
       */
      async getUser({ dispatch }, payload) {
        const { object: client } = await dispatch('resolveObject', { ...payload, forceFull: true })

        const { object } = await dispatch('ajax', {
          path: `client/getUser/${client.client_id}`
        })

        return object
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async call({ dispatch }, payload) {
        let { tags = {} } = payload

        const user = await dispatch('getUser', payload)
        const { id } = await dispatch('resolveObject', { ...payload, forceFull: true })

        tags = {
          ...tags,
          client_id: id
        }

        return dispatch('User/call', { object: user, tags }, { root: true })
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async message({ dispatch }, payload) {
        const user = await dispatch('getUser', payload)

        return dispatch('User/message', { object: user }, { root: true })
      },

      /**
       * Open map to clients address
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async map({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', { ...payload, forceFull: true })

        return dispatch(
          'openMap',
          {
            city: object.user_city,
            prov: object.user_prov,
            address: object.user_address
          },
          {
            root: true
          }
        )
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      abandon({ dispatch }, payload) {
        const { grid = null, button = null, alert = true } = payload
        return new Promise((resolve, reject) => {
          dispatch('resolveObject', payload).then(({ set }) => {
            dispatch('markMultiple', {
              markAs: 'abandoned',
              selected: set,
              button,
              alert,
              grid,
              go: false
            })
              .then(resolve)
              .catch(reject)
          })
        })
      },
      convert({ dispatch }, payload) {
        const {
          button = null,
          grid = null,
          go = true
          // selected, refId, id, are all passed to resolveObject dispatch with payload
        } = payload
        return new Promise((resolve) => {
          dispatch('resolveObject', payload).then((objectPayload) => {
            const object = objectPayload.set[0]
            dispatch('markMultiple', {
              markAs: _.format('a', 'status'),
              selected: [
                {
                  type: 'client',
                  client_id: object.client_id
                }
              ],
              grid,
              alert: false,
              button,
              go
            })
              .then(() => {
                if (grid) grid.reload()
                resolve({ ...objectPayload, reload: true })
              })
              .catch(() => {
                // Even though we are opening up ClientNew the save procedure
                //  will save an existing client, all we want is the ClientNew
                //  form which is more succinct.
                dispatch(
                  'modal/open',
                  {
                    modal: {
                      name: 'ClientNew',
                      opened() {
                        dispatch(
                          'alert',
                          {
                            message: `Please complete required
                          fields then save to convert to active client.`
                          },
                          { root: true }
                        )
                      },
                      saved() {
                        if (grid) grid.reload()
                      }
                    },
                    closeOthers: true,
                    objects: [
                      {
                        ...object
                      }
                    ],
                    embue: {
                      client_status: 'a'
                    },
                    button
                  },
                  { root: true }
                ).then(() => resolve({ ...objectPayload, reload: true }))
              })
          })
        })
      },
      async newEstimate({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', payload)
        await dispatch('create', {
          type: 'quote',
          embue: {
            client_id: object.client_id
          },
          go: true
        })
      },
      async quote({ dispatch }, payload) {
        const { go = true, embue = {} } = payload
        const { object } = await dispatch('resolveObject', payload)

        // Convert to active client and create estimate
        if (object.client_status !== 'a') {
          await dispatch('markMultiple', {
            markAs: 'Active',
            selected: [
              {
                type: 'client',
                client_id: object.client_id
              }
            ],
            alert: false,
            go: false
          })
        }
        const { object: quote } = await dispatch('ajax', {
          path: `/client/createQuote/${object.client_id}`,
          data: {
            embue
          }
        })
        if (go) {
          await dispatch('modal/closeAll', {}, { root: true })
          await dispatch('to', `/quote/${quote.quote_id}`, { root: true })
        }
        return quote
      },
      email({ dispatch, state }, payload) {
        const { selected = [], refId = false, id = [], button = null, grid = null } = payload

        return new Promise((resolve) => {
          const fnOpen = (objects) => {
            const selectedMessages = objects.map((q) => ({
              message_to: q.user_email,
              message_cc: q.user_email_alt || null,
              message_subject: '',
              tags: {
                client_id: q.client_id
              },
              aoFiles: [],
              title: `${q.client_name} • ${q.user_email}
                ${q.user_email_alt ? `, ${q.user_email_alt}` : ''}`
            }))
            dispatch(
              'message/composeMultiple',
              {
                templateFilters: {
                  template_type_id: 13
                },
                selectTemplateByDefault: false,
                selected: selectedMessages
              },
              { root: true }
            ).then(() => {
              if (grid) grid.reload(true)
              resolve(payload)
            })
          }

          if (selected.length) {
            fnOpen(selected)
          } else if (refId) {
            fnOpen(_.makeArray(refId).map((r) => _.denormalize(state.normalized, r)))
          } else if (id.length) {
            const ids = _.makeArray(id).join('||')
            dispatch('ajax', {
              path: '/client/search',
              data: {
                filters: {
                  client_id: ids
                }
              },
              button
            }).then(({ set }) => {
              fnOpen(set)
            })
          }
        })
      }
    }
  }
}
