import { isDev } from './moduleUtil.js';

export default class UserError extends Error {
  constructor(data, error = null) {
    const hint = error ? `${error && error.message} - ${data.hint || data.message}` : data.hint || data.message;
    super(hint);
    this.userMessage = data.userMessage || 'An error occurred';
    this.hintMessage = hint;
    this.message = hint;
    this.request = data.request;
    this.response = data.response;
    this.name = '';
    if (isDev()) {
      console.log(`%c[CostCertified • UserError]
      • ${this.userMessage || 'No UserMessage'}
      • ${this.message}
      • ${this.hintMessage || 'No hint'}`,
        'color: #ef3c52; font-weight: bold;');
      if (error) {
        console.log('Prev error:', error);
      }
    }
  }
}
