<template>
  <div v-if="shouldShow" class="wizard-step">
    <slot></slot>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'

const active = inject('active')
const counter = inject('counter')

const currentIndex = counter.value++

const shouldShow = computed(() => {
  return currentIndex === active.value
})
</script>
