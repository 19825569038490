import eventBus from '../../../eventBus'

export default {
  computed: {
    viewOptions() {
      return (this.object.oViewOptions && this.object.oViewOptions.pres) || {}
    },
    showDimensions() {
      return (
        this.object.type === 'assembly' &&
        this.presentationSettings.showAssemblyDimensions &&
        this.object.asDimensionsUsed.length &&
        (Object.values(this.object.oDimensions).filter((dim) => dim.showCustomer).length ||
          this.editable)
      )
    },
    showProperties() {
      return (
        this.desc ||
        (this.itemPictures.length && this.isVisible) ||
        (this.objectLocal.aoProperties &&
          this.objectLocal.aoProperties.length &&
          this.objectLocal.aoProperties[0] &&
          this.objectLocal.aoProperties[0][0] &&
          this.objectLocal.aoProperties[0][0].length) ||
        (this.presentationSettings.showAssemblyDimensions &&
          this.object.asDimensionsUsed.length &&
          (Object.values(this.object.oDimensions).filter((dim) => dim.showCustomer).length ||
            this.editable))
      )
    },
    depth() {
      return this.objectLocal.depth - 1
    },
    footingStyle() {
      return {
        bottom: `${this.depth * 2}rem`,
        zIndex: `${1000 - this.depth * 100}`
      }
    },
    headingStyle() {
      const style = {
        top: `calc(${this.depth * 4}em - ${this.depth + 1}px)`,
        zIndex: `${1000 - this.depth * 100}`
      }

      // if (this.showProperties) {
      //   style.boxShadow = 'none !important';
      // }

      return style
    },
    desc() {
      return c.removeHtml(this.objectLocal.quote_notes)
    },
    prodDesc() {
      return c.removeHtml(this.object.quote_production_notes)
    },
    fileUrls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.url))
    },
    thumbUrls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.thumb))
    },
    filesListWithUrl() {
      return this.fileIds.map((id) => ({
        id,
        url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
        thumb: c.link(
          `file/pic/thumb/${id}`,
          { size: 200, square: true },
          true,
          _.getStorage('scope')
        )
      }))
    },
    fileIds: {
      get() {
        return c.uniq(this.itemPictures.map((f) => f.file_id))
      },
      set(ids) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.objectLocal.refId,
          changes: {
            file_ids: ids
          }
        })
      }
    },
    itemPictures() {
      const ids = []
      return [
        ...this.object.file_ids.map((id) => c.buildDefaultObject('file', { file_id: id }))
        // ...(this.object.file_id && this.object.file_ids.includes
        // (? [c.buildDefaultObject('file', {
        //   file_id: this.object.file_id,
        // })] : []),
      ].filter((file) => {
        // De-duplicate
        const id = String(file.file_id)
        const inc = !ids.includes(id)
        ids.push(id)
        return !c.isempty(id) && inc
      })
    },
    isAnUpgrade() {
      return this.object.addon_is_upgraded
      // return this.object.upgradesOriginalKey
      //   && this.object.upgradesOriginalKey !== this.upgradeKey;
    },
    collapseTeaser() {
      const ch = this.objectLocal.aoChildren
      const norm = this.$store.state[this.store].normalized
      return ch
        .reduce(
          (acc, r) => [
            ...acc,
            [
              norm[r].assembly_name || norm[r].cost_type_name,
              norm[r].quote_notes || norm[r].cost_type_desc
            ]
          ],
          []
        )
        .slice(0, 4)
    },
    optional: {
      get() {
        return this.objectLocal.assembly_is_optional
      },
      set(v) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.objectLocal.refId,
          changes: {
            [`${this.objectLocal.type === 'assembly' ? 'assembly' : 'cost_item'}_is_optional`]: v
          }
        })
      }
    },
    addons: {
      get() {
        const norm = this.$store.state[this.store].normalized
        const obj = norm[this.objectLocal.refId]

        return _.imm(obj.aoAddons)
      },
      set(aoAddons) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.objectLocal.refId,
          changes: {
            aoAddons
          }
        })
      }
    },
    showAsItem() {
      return this.objectLocal.assembly_emphasis < 0
    },
    showCollapsed() {
      if (this.forceShrink) return true

      if (this.forceExpand) return false

      const emph = this.objectLocal.assembly_emphasis
      if (emph === 0 || emph === null || emph < 1) return true

      return false
    },
    showExploded() {
      return this.objectLocal.assembly_show_expanded === null
        ? this.presentationSettings.quote_show_expanded_assemblies === 2
        : this.objectLocal.assembly_show_expanded === 2
    },
    showNormalExpanded() {
      return !this.showAsItem && !this.showExploded
    },
    showItemized() {
      return (
        !this.parent ||
        this.parent.quote_show_itemized_prices ||
        this.presentationSettings.forceTemplateSettings
      )
    },
    showPrice() {
      if ('price' in this.viewOptions && this.viewOptions.price !== null) {
        return this.viewOptions.price
      }

      return (
        this.showItemized &&
        this.presentationSettings.showAssemblyPrices &&
        this.presentationSettings.showItemizedPrices &&
        !this.objectLocal.assembly_is_in_fixed_price_assembly
      )
    },
    showCost() {
      if ('cost' in this.viewOptions && this.viewOptions.cost !== null) {
        return this.viewOptions.cost
      }

      return (
        this.showItemized &&
        !this.presentationSettings.showBundledProfit &&
        this.presentationSettings.showCosts &&
        !this.objectLocal.assembly_is_in_fixed_price_assembly
      )
    },
    showQty() {
      if ('qty' in this.viewOptions && this.viewOptions.qty !== null) {
        return this.viewOptions.qty
      }

      return this.presentationSettings.showQuantities
    },
    cost() {
      return this.objectLocal.quote_total_cost_net_base * this.artificialMultiplier
    },
    price() {
      if (this.presentationSettings.showBundledProfit) {
        return this.cost
      }

      return this.objectLocal.quote_subtotal_net * this.artificialMultiplier
    },
    qty() {
      return this.objectLocal.quote_qty_net_base * this.artificialMultiplier
    },
    showSubtotal() {
      return (
        (this.showPrice || this.showQty) && (!this.parent || this.parent.quote_show_itemized_prices)
      )
    },
    parent() {
      return this.$store.state[this.store].normalized[this.objectLocal.parentRefId]
    },
    /**
     * If this assembly is also exploded,
     * we add its quantity tot he multiplier for
     * any down-stream children.
     * Since it is already added into qty, we just pass
     * on qty
     */
    multiplier() {
      return this.showExploded ? this.qty : this.artificialMultiplier
    }
  }
}
