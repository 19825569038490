<template>
  <card-list-item class="comment">
    <div class="comment-main" v-if="comment.oCreator">
      <div class="picture">
        <avatar :contact="comment.oCreator" />
      </div>
      <div class="text">
        <div>
          <to-route :to="`contact/${comment.oCreator.contact_object_id}`">
            {{ comment.oCreator.contact_name }}
          </to-route>
          {{ comment.activity_desc }}
        </div>
        <div class="comment-actions">
          <a @click.stop.prevent="like">Like</a>
          &nbsp;·&nbsp;
          <a @click.stop.prevent="reply">Reply</a>
          &nbsp;·&nbsp;
          <template v-if="comment.activity_count_likes > 0">
            <font-awesome-icon icon="thumbs-up" />&nbsp;
            {{ comment.activity_count_likes }}
            &nbsp;·&nbsp;
          </template>
          {{ $f.timerelative(comment.activity_time_created) }}
        </div>
      </div>
    </div>
  </card-list-item>
</template>

<script>
export default {
  name: '',
  emits: ['likeClick'],
  data() {
    return {}
  },
  methods: {
    like() {
      const unlike = !!this.comment.activity_liked
      this.$store.dispatch('ajax', { path: `/activity/thumb/${this.comment.activity_id}` })
      this.comment.activity_liked = unlike ? 0 : 1
      this.comment.activity_count_likes += unlike ? -1 : 1
      this.$emit('likeClick')
    },
    reply() {
      this.$parent.$parent.$refs.commentField.focus()
      c.scrollTo(this.$parent.$parent.$refs.commentField.$el)
    }
  },
  props: {
    comment: {
      required: true
    }
  },
  components: {}
}
</script>

<style rel="stylesheet/scss" lang="scss">
.comment {
  .comment-main {
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    a {
      font-weight: bold !important;
      color: $primary;
    }
    > div {
      margin-right: 1em;
    }

    .text {
      font-size: 0.9em;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      .comment-actions {
        font-size: 0.8em;
        font-weight: normal;
        color: $cool-gray-600;
      }
    }
  }
}
</style>
