<script setup>
import { ref, computed, defineProps, watch, onBeforeUnmount, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import AreaDimensions from '@/components/quote/ProjectDimensions/AreaDimensions.vue'
import StepExplainerModal from '@/components/modals/StepExplainerModal.vue'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'
import Selections from '@/components/bodies/CostItem/Selections.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
import Dimension from '../../../imports/api/schemas/Dimension.js'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'assembly' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true },
  showInfo: { type: Boolean, default: true },
  showContents: { type: Boolean, default: true },
  oMod: { type: Object, required: false }
})

const $store = useStore()

const { smallFormat } = useMediaQuery()

const refId = ref(props.refId || props.reference || 'root')
const type = ref(props.type || 'assembly')
const store = ref(props.store || props.storeName)
const refModal = ref(null)

const obj = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store
})

const { assembly_name, norm: fullNorm, selected } = obj

const norm = ref({})
const assembliesWithRequiredDimensions = computed(() =>
  Object.values(norm.value).filter(
    (item) => item.type === 'assembly' && item.asRequiredDimensions?.length
  )
)

// Find root source for any required dimensions that are linked
const dimParents = computed(() =>
  Object.values(norm.value).reduce(
    (acc, item) => ({
      ...acc,
      ...(item.type === 'assembly' && item.asDimensionsUsed?.length
        ? {
            ...item.asDimensionsUsed.reduce((acc2, abbr) => {
              const dimParent = Dimension.getDimParent(fullNorm.value, item.refId, abbr)
              if (dimParent in norm.value) return acc2 // if it's a descendant, we already captured it in assembliesWithRequiredDimensions
              return {
                ...acc2,
                [dimParent]: [...(acc2[dimParent] ?? []), abbr]
              }
            }, {})
          }
        : {})
    }),
    {}
  )
)

const localDimensions = ref({})

onBeforeMount(async () => {
  await selected

  // Get subset of norm for all descendants only
  norm.value = NormalizeUtilities.extractDescendants(fullNorm.value, [refId.value], true)
  localDimensions.value = [
    ...assembliesWithRequiredDimensions.value,
    ...Object.keys(dimParents.value).map((ref) => fullNorm.value[ref])
  ].reduce(
    (acc, assembly) => ({
      ...acc,
      [assembly.refId]: assembly.oDimensions
    }),
    {}
  )
})

// const itemsWithInternalSelections = computed(() =>
//   Object.values(norm.value).filter(
//     (item) => item.type === 'assembly' && item.oMeta?.selectionAudience === 'company'
//   )
// )
// const itemsWithClientSelections = computed(() =>
//   Object.values(norm.value).filter(
//     (item) =>
//       item.type === 'assembly' &&
//       (item.oMeta?.selectionAudience === 'client' || !item.oMeta?.selectionAudience)
//   )
// )

const recalcAddonsFor = ref([])
watch(recalcAddonsFor, async (refIds) => {
  if (!refIds?.length) return
  // Recalc linked dimension addons
  c.throttle(async () => {
    const urefids = _.cleanArray(refIds)
    recalcAddonsFor.value = [] // flush
    await $store.dispatch('Quote/recalcAddons', { refIds: urefids, loading: false })
  })
})

const commitDimensions = async () => {
  const refs = Object.keys(localDimensions.value ?? {})

  if (!refs.length) return

  // PUt in correct format
  const structured = Object.keys(localDimensions.value).reduce(
    (acc, ref) => ({
      ...acc,
      [ref]: {
        oDimensions: localDimensions.value[ref]
      }
    }),
    {}
  )

  await $store.dispatch(`${store.value}/field`, {
    changes: structured,
    explicit: true
  })
}
onBeforeUnmount(async () => commitDimensions())

const steps = ref([
  {
    title: 'Confirm required dimensions',
    desc: 'Set dimensions from your takeoff, which determine item quantities automatically.',
    icon: 'ruler-triangle'
  },
  {
    title: 'Set internal construction options',
    desc: 'Confirm internal-only construction options, based on job requirements.',
    icon: 'hammer-brush'
  },
  {
    title: 'Review client selections',
    desc: 'Change the default selections for your client, or just let them decide.',
    icon: 'swatchbook'
  }
])

const summary = computed(() => ({
  title: 'Assemblies make estimating easy',
  subtitle: assembly_name.value
}))

defineExpose({
  open: () => refModal.value.open(),
  close: () => refModal.value.close(),
  back: () => refModal.value.back(),
  next: () => refModal.value.next()
})
</script>

<template>
  <StepExplainerModal
    :summary="summary"
    :steps="steps"
    ref="refModal"
    @close="commitDimensions"
    name="assemblyAdded"
  >
    <div class="overflow-y-auto max-h-full basis-100 shrink grow-0 w-full gap-10 flex flex-col">
      <div class="flex justify-center items-center gap-10">
        <font-awesome-icon icon="ruler-triangle" class="text-5xl" />
        <div class="text-xl font-normal text-surface-600 leading-tight max-w-[70%]">
          Do your takeoff for {{ assembly_name }} here so that all its items receive the correct
          quantities from the start. You can change the dimensions at any time.
        </div>
      </div>
      <div class="flex flex-col gap-12">
        <div
          class="flex flex-col gap-2"
          v-for="(abbrs, parentRefId) in dimParents"
          :key="parentRefId"
        >
          <div
            class="relative text-2xl font-medium px-2 py-0.5 rounded-sm bg-pitch-black text-white select-none cursor-pointer"
          >
            {{ fullNorm[parentRefId].assembly_name || 'Project root' }}
            <div
              class="opacity-0 hover:opacity-100 absolute inset-0 text-sm flex justify-start items-center font-normal px-2 py-0.5 rounded-sm bg-pitch-black text-flame-white select-none cursor-pointer gap-2"
            >
              Project root
              <font-awesome-icon icon="arrow-right" /> {{ fullNorm[parentRefId].asAssemblyPath[0] }}
              <div
                v-for="segment in fullNorm[parentRefId].asAssemblyPath.slice(1)"
                :key="segment"
                class="flex justify-start items-center gap-2"
              >
                <font-awesome-icon icon="arrow-right" />
                <span>{{ segment }}</span>
              </div>
            </div>
          </div>
          <AreaDimensions
            :full="!smallFormat"
            v-model="localDimensions[parentRefId]"
            :showDerivedDimensions="false"
            :object="fullNorm[parentRefId]"
            :store="store"
            :reference="parentRefId"
            inputSize="text-xl"
            :show="abbrs"
          />
        </div>

        <div
          class="flex flex-col gap-2"
          v-for="assembly in assembliesWithRequiredDimensions"
          :key="assembly.refId"
        >
          <div
            class="relative text-2xl font-medium px-2 py-0.5 rounded-sm bg-pitch-black text-white select-none cursor-pointer"
          >
            {{ assembly.assembly_name }}
            <div
              class="opacity-0 hover:opacity-100 absolute inset-0 text-sm flex justify-start items-center font-normal px-2 py-0.5 rounded-sm bg-pitch-black text-flame-white select-none cursor-pointer gap-2"
            >
              Project root
              <font-awesome-icon icon="arrow-right" /> {{ assembly.asAssemblyPath[0] }}
              <div
                v-for="segment in assembly.asAssemblyPath.slice(1)"
                :key="segment"
                class="flex justify-start items-center gap-2"
              >
                <font-awesome-icon icon="arrow-right" />
                <span>{{ segment }}</span>
              </div>
            </div>
          </div>
          <AreaDimensions
            :full="!smallFormat"
            v-model="localDimensions[assembly.refId]"
            :showDerivedDimensions="false"
            :object="assembly"
            :store="store"
            :reference="assembly.refId"
            inputSize="text-xl"
          />
        </div>
      </div>
    </div>

    <div class="overflow-y-auto max-h-full basis-100 shrink grow-0 w-full gap-10 flex flex-col">
      <div class="flex justify-center items-center gap-10">
        <font-awesome-icon icon="hammer-brush" class="text-5xl" />
        <div class="text-xl font-normal text-surface-600 leading-tight max-w-[70%]">
          Choose internal construction selections, based on the job requirements and site conditions
          for {{ assembly_name }}.
        </div>
      </div>

      <Selections audience="company" :store="store" :refId="refId" key="company" />
    </div>

    <div class="overflow-y-auto max-h-full basis-100 shrink grow-0 w-full gap-10 flex flex-col">
      <div class="flex justify-center items-center gap-10">
        <font-awesome-icon icon="swatchbook" class="text-5xl" />
        <div class="text-xl font-normal text-surface-600 leading-tight max-w-[70%]">
          Set default selections for your client, or just leave it and just let them choose
          themselves. You can always change these options from the 'Selections' tab.
        </div>
      </div>

      <Selections audience="client" :store="store" :refId="refId" key="client" />
    </div>
  </StepExplainerModal>
</template>
