<script setup>
import { computed, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useSidePanel } from '@/stores/sidepanel'
import ConsumerFinancingOnboarding from '@/components/onboarding/ConsumerFinancingOnboarding.vue'
import CreateDongle from '@/components/layout/CreateDongle.vue'
import Search from '@/components/modals/Search.vue'
import PreviewSidebar from '@/components/layout/PreviewSidebar.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
import RouteEntityContext from '@/components/composables/RouteEntityContext'

const route = useRoute()
const sidePanelStore = useSidePanel()

const { isInPresentation } = RouteEntityContext.useRouteEntityContext()

const { smallFormat } = useMediaQuery()

const createDongle = ref(null)
provide('CreateDongle', createDongle)

const panelMargin = computed(() => {
  let margin = 0
  if (sidePanelStore.shouldShowSidePanel && !smallFormat.value) {
    margin = sidePanelStore.computedSize
  }

  return margin + 'px'
})

const viewHeight = computed(() => {
  let height = 'min-h-[calc(100%-60px-env(safe-area-inset-top))] md:min-h-full md:h-full'
  if (route.meta.header) {
    height = 'min-h-[calc(100%-72px-env(safe-area-inset-top))]'
  }
  if (smallFormat.value && isInPresentation.value) {
    height = 'h-screen'
  }
  return height
})
</script>

<template>
  <div class="app-view h-full w-full absolute overflow-hidden">
    <slot name="before"></slot>
    <div
      class="relative ml-0 mr-0 panel-margin"
      :class="[viewHeight]"
      :style="{ 'margin-left': panelMargin }"
    >
      <slot name="left"></slot>
      <div class="overflow-auto absolute top-0 bottom-0 left-0 right-0">
        <RouterView />
      </div>
      <slot name="right"></slot>
    </div>
    <slot name="after"></slot>

    <Search ref="search" />
    <PreviewSidebar />
    <CreateDongle ref="createDongle" />
    <ConsumerFinancingOnboarding />
  </div>
</template>

<style scoped lang="scss">
// This is required to "unset" the panel style since that has to be
// inline to be responsive
.panel-margin {
  @media (max-width: 767px) {
    margin-left: 0 !important;
  }
}
</style>
