<template>
  <Card
    v-if="!disabled"
    :class="{
      'md:hidden': isCurrent || addon.isgrp || (!addon.id && !addon.livePriceRef),
      hidden: !addon.id && !addon.livePriceRef,
      group: addon.isgrp,
      right: addon.isgrp,
      button: !addon.isgrp,
      up: !addon.isgrp,
      '!border-2 !border-blue-print-400': isCurrent
    }"
    class="z-8 min-w-40 md:min-w-52 md:max-w-52 md:min-h-72 cursor-pointer border border-surface-300 rounded-lg hover:border-cement-500 hover:bg-cement-100 overflow-hidden"
    unstyled
    :pt="{ header: 'h-32 md:h-[180px]', root: '!shadow-none' }"
  >
    <template #header>
      <div
        class="relative"
        v-if="!addon.isgrp && (picFile.length || editable || addon.rating !== null)"
        @click="preview"
      >
        <file-list
          v-if="editable && !loading"
          :allowCreate="editable && !picFile.length"
          :allowAdd="editable && !picFile.length"
          :allowUpload="editable && !picFile.length"
          :allowRemove="editable"
          @input="(all) => fileAdded(all)"
          :value="picFile"
        />
        <div
          class="h-32 md:max-h-44 md:h-44 max-w-full w-full overflow-hidden bg-cover bg-no-repeat"
          v-else-if="picThumbnail"
          :style="{
            backgroundImage: `url(${picThumbnail})`
          }"
        >
          <div
            class="absolute top-2 left-2 bg-pitch-black text-flame-white text-xs px-1 py-0.5 rounded"
            :class="{ '!bg-blue-print-400': isCurrent }"
          >
            <span v-if="isCurrent"> {{ $t('Selected') }} </span>
            <div v-else-if="this.priceDifference !== 0">
              <span class="mr-1"> {{ priceAction }} </span>
              <span class="font-header"> {{ $f.currency(priceDifference) }} </span>
            </div>
            <span v-else> {{ $t('Same price') }} </span>
          </div>
        </div>
        <div
          v-else
          class="relative flex justify-center items-center bg-surface-100 h-32 md:max-h-44 md:h-44 max-w-full w-full"
          :class="isParent ? '' : 'bg-surface-100'"
        >
          <font-awesome-icon
            v-if="isParent"
            :icon="['fas', 'list']"
            class="text-7xl text-surface-200"
          />
          <font-awesome-icon
            v-else-if="isAssembly"
            :icon="['fas', 'cubes']"
            class="text-7xl text-surface-200"
          />
          <font-awesome-icon v-else :icon="['fas', 'cube']" class="text-7xl text-surface-200" />
          <div
            class="absolute top-2 left-2 bg-pitch-black text-flame-white text-xs px-1 py-0.5 rounded"
            :class="{ '!bg-blue-print-400': isCurrent }"
          >
            <span v-if="isCurrent"> {{ $t('Selected') }} </span>
            <div v-else-if="this.priceDifference !== 0">
              <span class="mr-1"> {{ priceAction }} </span>
              <span class="font-header"> {{ $f.currency(priceDifference) }} </span>
            </div>
            <span v-else> {{ $t('Same price') }} </span>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div @click="preview">
        <div class="p-3">
          <div
            class="mb-0 text-sm md:text-base h-10 md:min-h-12 md:h-12 max-h-12 w-full overflow-hidden !leading-tight"
          >
            {{ name }}
          </div>
        </div>
        <div class="p-3 pt-0">
          <div class="addon-inner">
            <div class="addon-info">
              <Btn severity="tertiary" class="w-full" :action="preview" size="item">
                <span v-if="isCurrent">{{ $t('View details') }}</span>
                <span v-else>{{ $t('Review and select') }}</span>
              </Btn>

              <div
                class="addon-button absolute bottom-0 right-0 flex justify-center items-center text-center opacity-0 transition-opacity duration-200 whitespace-nowrap"
              >
                <SuggestionButton class="info"> {{ $t('Learn more') }}.. </SuggestionButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card'
import AddonItemMixin from '../addons/AddonItemMixin'
import TranslationMixin from './languages/TranslationMixin'
import SuggestionButton from '../../ui/SuggestionButton.vue'

/**
 * Emits:
 * -addon (newAddon object)
 */
export default {
  name: 'QuotePresentationAddonItem',
  components: { SuggestionButton, Card },
  mixins: [AddonItemMixin, TranslationMixin],
  emits: ['numberChose', 'selected-addon'],
  data() {
    return {
      previewRequested: false,
      picViewing: null,
      numberChose: 1
    }
  },
  watch: {
    filesList(list) {
      if (list && list.length) {
        this.picViewing = list[0]
      }
    },
    numberChose(num) {
      this.$emit('numberChose', num)
    },
    auditedObject() {
      c.throttle(
        () => {
          this.getCount()
        },
        { key: this.auditedObject.cost_type_id || this.auditedObject.assembly_id }
      )
    }
  },
  computed: {
    $() {
      return this.$store.getters.$
    },
    $a() {
      return this.$store.getters.$a
    },
    $b() {
      return this.$store.getters.$b
    },
    urls() {
      return c.uniq(this.filesListWithUrl.map((f) => f.url))
    },
    thumbs() {
      return c.uniq(this.filesListWithUrl.map((f) => f.thumb))
    },
    properties() {
      return c.makeArray(this.object.aoProperties || [])
    },
    filesListWithUrl() {
      return this.filesList.map((id) => ({
        id,
        url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
        thumb: c.link(
          `file/pic/thumb/${id}`,
          { size: 200, square: true },
          true,
          _.getStorage('scope')
        )
      }))
    },
    filesList() {
      return _.uniq(this.object.file_ids)
    },
    picThumbnail() {
      if (this.addon.image_external) {
        return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${this.addon.image_external}`
      }
      return this.pic
    },
    picFileUrl() {
      if (this.picViewing)
        return c.link(`file/view/${this.picViewing}`, { max: 500 }, true, _.getStorage('scope'))
      return false
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isParent() {
      return this.object.cost_type_is_parent
    },
    priceAction() {
      return this.priceDifference > 0 ? this.$t('add') : this.$t('save')
    }
  },
  methods: {
    async getCount() {
      if (this.addon.usages) {
        this.numberChose = this.addon.usages
        return
      }

      this.numberChose = 0
    },
    async preview() {
      if (this.addon.isgrp) return
      await this.$nextTick()
      await this.getCount()
      await this.fetch(false, false)
      await this.$store.dispatch('Quote/openUpgradesModal', {
        parentName: this.parentName,
        itemName: this.name,
        description: this.desc,
        urls: [...this.urls, ...(this.picThumbnail ? [this.picThumbnail] : [])],
        object: this.object,
        properties: this.properties,
        priceTag: this.priceDifference,
        priceAction: this.priceAction,
        addon: this.addon,
        refId: this.targetRefId,
        go: false
      })
    },
    emitCurrentStatus() {
      if (this.isCurrent) {
        const addonImage = this.picFile.length ? this.pic : ''
        this.$emit('selected-addon', { addon: this.addon, addonImage, viewDetails: this.preview })
      }
    }
  },
  mounted() {
    this.emitCurrentStatus()
  },
  updated() {
    this.emitCurrentStatus()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.addon-container.card {
  z-index: 8;

  &.group.selected {
    $groupColor: $cool-gray-300;
    background: $groupColor;
    border-color: $groupColor !important;
    box-shadow: none !important;
    &:before {
      border-left-color: $groupColor !important;
    }
    &:after {
      border-color: $groupColor !important;
    }
    .addon-top-fade {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 10em;
      background: $groupColor;
      z-index: 2;
      background: linear-gradient(180deg, rgba($groupColor, 0) 55%, rgba($groupColor, 1) 80%);
    }
    //.addon-top-fade {
    //  display: none !important;
    //}
    .addon-inner {
      background: transparent !important;
    }
  }
  .addon-name {
    margin-bottom: 0 !important;
    font-size: 1.3em;
    line-height: 1.4em;
  }
  &:hover {
    .addon-button {
      opacity: 1;
    }
  }
}

.addon-pic {
  position: relative;

  .quality-range {
    font-size: 0.6em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1.5em;
    background: transparent;

    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    $quality-color: $blue-print-700;

    > div {
      width: 4.95rem;
      text-align: right;
      mix-blend-mode: difference;
      .bar {
        background: rgba($cool-gray-300, 0.3);
        height: 0.25em;
        width: 100%;
      }

      &.active {
        mix-blend-mode: normal;
        .bar {
          background: rgba($quality-color, 0.7);
        }
        .text {
          font-weight: 500;
          text-outline: 1px solid $flame-white;
          mix-blend-mode: hard-light;
        }
        color: rgba($quality-color, 0.7);
      }
    }

    > div:first-child {
    }

    > div:nth-child(2) {
    }

    > div:last-child {
    }
  }
}
.addon-button {
  opacity: 0;
  position: absolute;
  bottom: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  z-index: $z-layout + 100;
}
img.interstitial-image {
  border-radius: 5px;
  max-width: 250px;
  width: 250px;
}
.interstitial {
  .image-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;
    .image-selector {
      height: 4em;
      width: 4em;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover {
        transform: translateY(-5px);
        box-shadow: $shadow-more;
      }
    }
    .image-selector + .image-selector {
      margin-left: 0.25em;
    }
  }
  .interstitial-column {
    min-width: 200px;
    width: 50%;
  }
  .interstitial-column + .interstitial-column {
    margin-left: 1em;
  }
}
</style>
<style lang="scss" rel="stylesheet/scss">
.modal.item-preview {
  .modal-content {
    background: rgba($pitch-black, 1) !important;
  }
  .modal-body {
    padding: 0 0 0 0;
    padding-left: 0;
    position: relative;

    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    align-items: flex-start;

    > .scroll-container--container {
      padding: 0;
      > .scroll-container--inner {
        padding: 0;
      }
    }

    .image-preview {
      margin: 0 !important;
      width: 100% !important;
      height: 50em !important;
      cursor: pointer;
      opacity: 0.5;
      max-height: 25em;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      filter: saturate(0);
    }

    .images-container {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 100%;
      min-width: 100%;
      flex-basis: 100%;
      position: absolute;
      top: 0;
      margin-left: -3em !important;
    }

    .content-row {
      display: flex;
      flex-direction: row-reverse;
    }

    .content-container {
      width: unset !important;
      flex: 1 60% !important;
      margin: 0;
      margin-left: auto;
      padding: 1em !important;
      > .card {
        margin: 0;
        font-size: 0.9em;
      }

      .item-name {
        font-size: 1.3em;
        color: $blue-print-700;
        display: block;
        font-weight: bold;
      }
      .item-desc {
        font-size: 1.1em;
        color: $cool-gray-600;
        display: block;
        white-space: pre-wrap;
        font-weight: 500;
      }
      .item-heading {
        font-size: 1em;
        color: $cool-gray-600;
        display: block;
      }
      .item-props {
        font-size: 1em;
        display: block;
        padding-left: 1.5em;
        li {
          font-weight: normal;
          color: $cool-gray-800;
        }
      }
      .item-disclaimers {
        margin-top: 1em;
        font-size: 1em;
        color: $cool-gray-600;
      }
    }
    .gallery-container {
      padding: 1em !important;
      margin-top: 15em !important;
      max-width: 40% !important;
      z-index: 10;
    }

    .action-row {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      margin-top: 0 !important;
      padding-top: 3em !important;
      /*row-gap: 0.5rem !important;*/
      > :not(:first-child) {
        margin-top: 0.5rem !important;
      }
    }

    .chosen-count {
      color: $flame-white;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
    }
  }
}

#app.small-format {
  .modal.item-preview {
    .modal-body {
      .gallery-container {
        flex: 1 5em !important;
        padding: 1em !important;
        margin-top: 0em !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
        z-index: 10;
        justify-content: flex-start;
      }

      .content-container {
        flex: 1 20em !important;
        > .card {
          border-radius: 1em !important;
        }
      }

      .content-row {
        flex-basis: auto !important;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      .action-row {
        background: $pitch-black;
        padding: 0 !important;
        margin: 0 !important;
        flex-wrap: wrap;
        max-height: 12em;
        height: 12em;
        bottom: 0em;
        left: 0px;
        right: 0px;
        padding-top: 1em !important;
        padding-bottom: 3.5em !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: fixed !important;
        z-index: $z-modal + 10;
      }

      .chosen-count {
        justify-content: center;
        align-items: center;
        text-align: left;
        line-height: 1;
      }
    }
  }
}
#app.small-format {
  .modal.item-preview {
    .images-container {
      margin-left: 0 !important;
    }
  }
}
</style>
