<template>
  <div class="flex flex-col justify-start items-stretch gap-0">
    <template v-if="!dimensionalAreas.length">
      <help>
        <template #before>No dimensions required yet.</template>
        <template #title>Required dimensions</template>

        <p>
          When you add an item that requires a dimension, by choosing a unit of measure linked to
          dimensions (like square feet of floor or linear feet of wall perimeter etc), that linked
          dimension becomes required.
        </p>

        <p>
          The item will inherit the dimension from the first assembly up the chain that has that
          dimension set. If no assemblies have that dimension, or the item is not inside of an
          assembly, then the root level project dimension will be required.
        </p>
        <p>
          Dimensions that are required for all upgrades and optional items are also included, so
          that everything they need to determine the actual price for the upgrade is available when
          the consumer makes the change.
        </p>
      </help>
    </template>
    <template v-else>
      <area-dimension-collapse
        v-for="areaRef in dimensionalAreas"
        :showDerivedDimensions="showDerivedDimensions"
        :object="allItems[areaRef]"
        :reference="areaRef"
        :key="areaRef"
        :store="store"
      />
    </template>
  </div>
</template>

<script>
import AreaDimensionCollapse from './AreaDimensionCollapse.vue'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'

export default {
  name: 'ProjectDimensions',
  props: {
    reference: {
      type: String,
      required: true
    },
    store: {
      type: String,
      required: true
    },
    showDerivedDimensions: {
      default: false
    }
  },
  computed: {
    allItems() {
      const norm = this.$store.state[this.store]?.normalized
      if (!norm || !Object.keys(norm).length) return {}
      return NormalizeUtilities.sortNatural(norm).reduce(
        (acc, refId) => ({
          ...acc,
          [refId]: norm[refId]
        }),
        {}
      )
    },

    dimensionalAreas() {
      const items = this.allItems

      return Object.keys(items).filter(
        (refId) => items[refId].type === 'assembly' || items[refId].type === 'quote'
      )
    }
  },
  components: {
    AreaDimensionCollapse
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
