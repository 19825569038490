import axios from 'axios'
import Pako from 'pako'
import _ from './Helpers'
import UserError from './UserError'
import AuthError from './AuthError'

/**
 *
 * @param authorization
 * @param externalRequest
 * @returns {{headers: {}, ignoreScope: boolean}}
 */
const getAuthorizationHeaders = (authorization, externalRequest = false) => {
  let headers = {}
  let ignoreScope = false

  if (externalRequest) {
    return {
      headers: {},
      ignoreScope: true
    }
  }

  // username password authentication
  if (authorization.email && authorization.password) {
    headers = {
      Authorization: `Basic ${_.btoa(`${authorization.email}:${authorization.password}`)}`
    }
    ignoreScope = true
  } else if (authorization.token) {
    headers = {
      Authorization: `Bearer ${authorization.token}`,
      'Access-Token': `${authorization.accessToken}`,
      'Id-Token': `${authorization.idToken}`,
      'Refresh-Token': `${authorization.refreshToken}`
    }
    ignoreScope = false
  } else if (authorization.shortToken) {
    headers = {
      Authorization: `Short ${authorization.shortToken}`,
      'Access-Token': `${authorization.accessToken}`,
      'Id-Token': `${authorization.idToken}`,
      'Refresh-Token': `${authorization.refreshToken}`
    }
    ignoreScope = true
  }

  return {
    headers,
    ignoreScope
  }
}

/**
 * Basic ajax calling wrapper that returns a promise.
 *
 * Example:
 * Base.ajax('/path/', { postData: true }, button)
 *  .then(function({ message, object, warnings, button, code, sessionId }){
 *    alert('done')!
 *  });
 *
 *  This method will NOT detect any authorization automatically,
 *  it MUST be passed to execute an authorized call.
 *
 * @param {String} path
 * @param {Object} data
 * @param {Array|Object} button
 * @param {Array} authorization
 *    format: [[superUserEmail], userEmail, password]
 *    superUserEmail optional
 * @returns {Promise}
 */
const ajax = async (methodPayload) => {
  const requestPayload = typeof methodPayload === 'string' ? { path: methodPayload } : methodPayload

  const {
    host,
    path = '',
    authorization = {},
    scope = null,
    data: body,
    useAuthScope = null,
    progress = () => {},
    shouldCompress = false,
    version = null,
    ignoreScope: ignoreScopeExplicit = false
  } = requestPayload

  const url = `${host.replace(/\/$/, '')}/${path.replace(/^\//, '')}${scope ? `?c=${_.btoa(JSON.stringify(scope))}` : ''}`

  const data = {
    data: body,
    requestedScope: scope
  }

  const contentType = shouldCompress ? 'text/plain; UTF-8;' : 'application/json; UTF-8;'

  const options = {
    method: 'post',
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: 2000000,
    maxBodyLength: 2000000,
    decompress: true,
    // we can try to connect over websocket
    socketPath: null,
    maxRedirects: 5,
    transformResponse: null,
    contentType,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100
      return progress(percentCompleted)
    }
  }

  // console.log('--1', data);
  if (authorization && Object.keys(authorization).length) {
    const { headers, ignoreScope = false } = getAuthorizationHeaders(authorization)

    options.headers = headers
    data.ignoreScope = ignoreScopeExplicit || useAuthScope || ignoreScope
    // console.log('--2', ignoreScopeExplicit, useAuthScope, ignoreScope);
    data.requestedScope = data.ignoreScope ? null : data.requestedScope
  }

  if (version) {
    data.version = version
  }

  if (shouldCompress) {
    options.headers['CC-String-Encoding'] = 'CC Compression'
  }

  let response
  let compressed
  try {
    const stringifiedData = JSON.stringify(data)
    compressed = shouldCompress ? Pako.deflate(stringifiedData) : data
    response = await axios.post(url, compressed, options)
  } catch (e) {
    let message
    let userMessage

    try {
      // Attempt to pull the error message from the response
      const errorResponse = JSON.parse(e.response.data)

      message = errorResponse.payload.message
      userMessage = errorResponse.payload.userMessage
    } catch (parseException) {
      message = 'An error occurred.'
      userMessage = 'An error occurred.'

      console.error({ e })
    }

    throw new UserError({
      message,
      userMessage,
      headers: {},
      scope,
      shouldCompress,
      request: data,
      response: e.response.data,
      url,
      path: url
    })
  }

  const { data: dataString, status, latestClientVersion = 0 } = response

  const payload = JSON.parse(dataString)

  const { code = 0, error = false, payload: responsePayload, warnings } = payload

  const successMessage = (typeof payload === 'string' ? payload : payload.message) || 'Done!'

  const errorMessage =
    (error && (error.userMessage || error.message)) ||
    (error && (payload.userMessage || payload.message)) ||
    'An error occurred. Please try again. If the problem persists please restart and try again.'

  const hintMessage =
    (error &&
      (error.message ||
        payload.message ||
        'An error occurred. Please try again. If the problem persists please restart and try again.')) ||
    false

  const message = error ? errorMessage : successMessage
  const userMessage = message
  const object = responsePayload
  const set = _.makeArray(object)

  const returnPayload = {
    ...response,
    ...payload,
    payload: responsePayload,
    message,
    userMessage,
    hintMessage,
    object,
    set,
    status,
    warnings,
    latestClientVersion
  }

  if (error || isNaN(+code) || code < 0 || !(status >= 200 && status <= 299)) {
    if (code < 0 || status === 403 || status === 401) {
      // dispatch('authFailed');

      throw new AuthError({
        message: errorMessage,
        userMessage: errorMessage,
        hintMessage
      })
    }

    throw new UserError({
      ...returnPayload,
      message: errorMessage,
      userMessage: errorMessage,
      hintMessage,
      scope,
      request: data,
      url,
      path: url
    })
  }

  return {
    ...payload,
    ...returnPayload,
    message: successMessage,
    userMessage: successMessage,
    object,
    set
  }
}

export default {
  ajax,
  getAuthorizationHeaders
}
