import { toRefs, reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import useCustomField from '@/components/forms/CustomField'

export const initialState = {
  refId: null,
  fieldTypes: [
    {
      text: 'Checkbox',
      value: 'checkbox'
    },
    {
      text: 'Radio',
      value: 'radio'
    },
    {
      text: 'Dropdown',
      value: 'select'
    },
    {
      text: 'Text',
      value: 'textfield'
    },
    {
      text: 'Large text',
      value: 'textarea'
    }
  ],
  formattingOptions: [
    {
      text: 'Percentage',
      value: 'percentage'
    },
    {
      text: 'Currency/dollar (2 decimal places)',
      value: 'currency'
    },
    {
      text: 'Number (0-20 decimal places)',
      value: 'number'
    },
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: 'Phone number (north america)',
      value: 'phone'
    }
  ],
  fieldTypesAndFormatting: [
    {
      label: 'Field types',
      items: [
        {
          icon: 'pen-to-square',
          label: 'Text field',
          value: 'textfield'
        },
        {
          icon: 'list',
          label: 'Multi-select',
          value: 'checkbox'
        },
        {
          icon: 'circle-check',
          label: 'Single-select',
          value: 'radio'
        },
        {
          icon: 'list-dropdown',
          label: 'Dropdown',
          value: 'select'
        },
        {
          icon: 'align-left',
          label: 'Large text',
          value: 'textarea'
        },
        {
          icon: 'phone',
          label: 'Phone Number',
          formatting: 'phone',
          value: 'textfield'
        },
        {
          icon: 'envelope',
          label: 'Email',
          formatting: 'email',
          value: 'textfield'
        },
        {
          icon: 'hashtag',
          label: 'Number',
          formatting: 'number',
          value: 'textfield'
        },
        {
          icon: 'dollar-sign',
          label: 'Currency',
          formatting: 'currency',
          value: 'textfield'
        },
        {
          icon: 'percent',
          label: 'Percentage',
          formatting: 'percentage',
          value: 'textfield'
        }
      ]
    }
  ],
  fields: [],
  formFields: [],
  defaultFormFields: []
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  const $store = useStore()
  const { options } = useCustomField()
  const form = ref()
  const submitting = ref(false)
  const submitted = ref(false)
  const loading = ref(false)

  const addField = async (type = null, formatting = null, index = null) => {
    const { object } = await $store.dispatch('CustomField/buildDefaultObject')
    const field = {
      ...object,
      uid: `custom_field:${$store.state.session.user.user_id}:${Date.now()}`,
      ...(type ? { custom_field_type: type } : {}),
      ...(formatting ? { custom_field_validation: formatting } : {})
    }
    if (index !== null) {
      context.fields.splice(index + 1, 0, field)
      return
    }
    context.fields.push(field)
  }

  const removeField = (uid) => {
    context.fields = context.fields.filter((field) => field.uid !== uid)
  }

  const saveForm = async () => {
    try {
      // Get and validate the form
      const formObject = await getFormObject()
      await validateForm(formObject)

      // Validate custom fields and options
      await validateFieldsAndOptions()
      // Save the form and related custom fields, options, and form fields
      const form = await saveFormObject()
      const customFields = await saveCustomFields()
      await saveCustomFieldOptions(customFields)
      await saveFormFields(form, customFields)
      return form
    } catch (e) {
      $store.dispatch('alert', {
        message: e.userMessage || e.message || 'Cannot save the lead form',
        error: true
      })
    }
  }

  // Method to get and validate the form object
  const getFormObject = async () => {
    const { object: formObject } = await $store.dispatch('Form/resolveObject', {
      refId: context.refId
    })
    return formObject
  }

  // Method to validate the form
  const validateForm = async (formObject) => {
    await $store.dispatch('Form/validate', { object: formObject })
  }

  // Method to validate custom fields and options
  const validateFieldsAndOptions = async () => {
    const allValidations = [
      ...context.fields.map((field) => $store.dispatch('CustomField/validate', { object: field })),
      ...Object.values(options.value)
        .flat()
        .map((option) => $store.dispatch('CustomFieldOption/validate', { object: option }))
    ]
    await Promise.all(allValidations)
  }

  // Method to save the form object
  const saveFormObject = async () => {
    const { object: form } = await $store.dispatch('Form/save', { refId: context.refId, go: false })
    return form
  }

  // Method to save custom fields
  const saveCustomFields = async () => {
    const { payload: customFields } = await $store.dispatch('ajax', {
      path: 'custom_field/crupdateMultiple',
      data: context.fields
    })
    return customFields
  }

  // Method to save custom field options
  const saveCustomFieldOptions = async (customFields) => {
    const allOptions = Object.entries(options.value).flatMap(([key, fieldOptions]) =>
      fieldOptions.map((option) => ({
        ...option,
        custom_field_id:
          option.custom_field_id || customFields.find((field) => field.uid === key)?.custom_field_id
      }))
    )
    if (allOptions.length > 0) {
      await $store.dispatch('ajax', {
        path: 'custom_field_option/crupdateMultiple',
        data: allOptions
      })
    }
  }

  const getFormField = (customFormId, formId) => {
    return context.formFields.find(
      (formField) => formField.custom_field_id === customFormId && formField.form_id === formId
    )
  }

  // Method to save form fields
  const saveFormFields = async (form, customFields) => {
    const formFields = []
    for (const field of customFields) {
      // Check if a form field record already exists
      const formField = getFormField(field.custom_field_id, form.form_id)
      if (formField) continue

      // Create default form field
      const { object } = await $store.dispatch('FormField/buildDefaultObject')

      formFields.push({
        ...object,
        form_id: form.form_id,
        custom_field_id: field.custom_field_id
      })
    }

    await Promise.all(formFields)
    // no form fields to add
    if (formFields.length === 0) return
    // form fields to add
    await $store.dispatch('ajax', {
      path: 'form_field/crupdateMultiple',
      data: formFields
    })
  }

  const getCustomFieldValues = (customFields) => {
    return Promise.all(
      Object.keys(customFields)
        .filter((fieldId) => customFields[fieldId] !== null && customFields[fieldId] !== '')
        .map(async (fieldId) => {
          const value = customFields[fieldId]
          const { object } = await $store.dispatch('CustomFieldValue/buildDefaultObject')
          return {
            ...object,
            custom_field_value_input: value,
            custom_field_id: fieldId
          }
        })
    )
  }

  const saveCustomFieldValues = (customFieldValues = null, formId = null) => {
    if (!customFieldValues) return
    const data = customFieldValues.map((field) => ({
      ...field,
      form_id: formId
    }))
    return $store.dispatch('ajax', {
      path: 'custom_field_value/crupdateMultiple',
      data: data
    })
  }

  const getOptions = (customFieldId, options = []) => {
    return options.filter((option) => option.custom_field_id === customFieldId)
  }

  const getNewOptions = (uid) => {
    return options.value[uid]
  }

  const mapFieldsForBuilder = (aoFields) => {
    return aoFields.map((field) => ({
      ...field,
      uid: field.custom_field_id
    }))
  }

  const mapFieldOptionsForBuilder = (aoFieldOptions) => {
    return aoFieldOptions.reduce((acc, option) => {
      if (!acc[option.custom_field_id]) acc[option.custom_field_id] = []
      acc[option.custom_field_id].push(option)
      return acc
    }, {})
  }

  const customFieldOptionsToArray = (optionsObj) => {
    return Object.entries(optionsObj).flatMap(([, fieldOptions]) =>
      fieldOptions.map((option) => ({
        ...option
      }))
    )
  }

  const selectFieldType = (option, index = null) => {
    const { value, formatting = null } = option
    addField(value, formatting, index)
  }

  const resetForm = () => {
    context.fields = []
    context.formFields = []
    context.defaultFormFields = []
    context.refId = null
  }

  const customFormsEnabled = computed(
    () =>
      $store.state.session.company.aoModules &&
      $store.state.session.company.aoModules.custom_forms &&
      parseInt($store.state.session.company.aoModules.custom_forms, 10) === 1
  )

  const getFormFieldsData = () => {
    return $store.dispatch('LeadRotation/getFormFieldsData')
  }

  const mapDefaultFormFields = async () => {
    const defaultFormFields = await getFormFieldsData()
    return form.value.asFormFields.reduce((acc, key) => {
      acc.push({
        key,
        ...defaultFormFields[key]
      })
      return acc
    }, [])
  }

  const fetchLeadForm = async (leadRotationId) => {
    loading.value = true
    const { object } = await $store.dispatch('ajax', {
      path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/getLeadForm`,
      data: {
        id: leadRotationId
      }
    })
    form.value = object
    context.defaultFormFields = await mapDefaultFormFields()
    loading.value = false
  }

  const getDefaultFormFields = async () => {
    const defaultFields = await getFormFieldsData()
    context.defaultFormFields = Object.keys(defaultFields).reduce((acc, key) => {
      acc.push({
        key,
        ...defaultFields[key]
      })
      return acc
    }, [])
    return context.defaultFormFields
  }

  const onSubmitLeadForm = async (
    leadRotationId,
    signature = null,
    { defaultFields = {}, customFields = [] }
  ) => {
    if (!leadRotationId) return
    submitting.value = true
    const data = {
      ...defaultFields,
      customFields,
      lead_rotation_id: leadRotationId,
      signature: signature
    }

    try {
      const { object } = await $store.dispatch('ajax', {
        path: '/lead_rotation/finalizeLead',
        data
      })
      const received = object.received
      if (received.success_url && !received.success_url.endsWith('/pub/leadReceived')) {
        $store.dispatch('openLink', {
          url: received.success_url,
          newWindow: false
        })
      } else {
        submitted.value = true
      }
    } catch (e) {
      $store.dispatch('alert', { error: true, message: e.message }, { root: true })
    }
    submitting.value = false
  }

  return {
    ...toRefs(context),
    customFormsEnabled,
    form,
    submitting,
    submitted,
    loading,
    resetForm,
    addField,
    removeField,
    saveForm,
    saveCustomFieldValues,
    getCustomFieldValues,
    getOptions,
    getNewOptions,
    mapFieldsForBuilder,
    mapFieldOptionsForBuilder,
    customFieldOptionsToArray,
    selectFieldType,
    fetchLeadForm,
    onSubmitLeadForm,
    getDefaultFormFields
  }
}
