<template>
  <div class="filter-field">
    <!-- range -->
    <template v-if="inputType === 'range'">
      <field
        class="number"
        v-model="fromComputed"
        :schema="`${type}:${column.sortBy}`"
        format="number"
        placeholder="min"
      />
      <span v-if="formatType === 'percentage' || formatType === 'percentageWhole'">%</span>
      <span v-else-if="formatType === 'hours' || formatType === 'hour'">h</span>
      <span v-else>$</span>
      <span>to</span>
      <field
        class="number"
        v-model="toComputed"
        :schema="`${type}:${column.sortBy}`"
        format="number"
        placeholder="max"
      />
      <span v-if="formatType === 'percentage' || formatType === 'percentageWhole'">%</span>
      <span v-else-if="formatType === 'hours' || formatType === 'hour'">h</span>
      <span v-else>$</span>
    </template>

    <!-- status -->
    <template v-else-if="/_status$/.test(column.sortBy)">
      <choose
        :allowCreate="false"
        v-model="chooseComputed"
        :schema="`${type}:${column.sortBy}`"
        :staticSet="statuses"
        :multiple="true"
        @formatted="setChooseFormatted"
      />
    </template>

    <!-- select -->
    <template v-else-if="inputType === 'select'">
      <choose
        :allowCreate="false"
        :schema="`${type}:${column.sortBy}`"
        v-model="chooseComputed"
        :filters="selectFilters"
        :multiple="true"
        @formatted="setChooseFormatted"
      />
    </template>

    <!-- datetime -->
    <template v-else-if="inputType === 'datetime'">
      <date-picker placeholder="Earliest" v-model="fromComputed" />
      <span>to</span>
      <date-picker placeholder="Latest" v-model="toComputed" />
    </template>

    <!-- text -->
    <template v-else>
      <span>like</span>
      <input type="text" v-model="textComputed" />
    </template>
  </div>
</template>

<script>
import DatePicker from './DatePicker.vue'
import Choose from './Choose/Choose.vue'

export default {
  name: 'FilterField',
  emits: ['input', 'formatted'],
  data() {
    return {
      formatted: '',
      raw: this.value,
      chooseValue: [],
      chooseFormatted: [],
      from: this.getFromFromValue(),
      to: this.getToFromValue(),
      text: '',
      uid: c.uniqueId()
    }
  },
  watch: {
    to() {
      this.checkIfShouldReverse()
    },
    from() {
      this.checkIfShouldReverse()
    },
    raw(v) {
      this.$emit('input', v, { [this.column.sortBy]: v })
    },
    formatted(v) {
      this.$emit('formatted', v, { [this.column.sortBy]: v })
    },
    value() {
      // c.throttle(() => {
      this.from = this.getFromFromValue()
      this.to = this.getToFromValue()
      // }, { key: this.uid });
    }
  },
  computed: {
    formatType() {
      return this.column.formatType === 'currency' ? 'number' : this.column.formatType
    },
    chooseComputed: {
      get() {
        if (this.inputType === 'select' && this.value) {
          return c.makeArray(this.value)
        }
        return []
      },
      set(val) {
        this.chooseValue = val || ''
        this.getFull()
      }
    },
    textComputed: {
      get() {
        if (this.inputType === 'string' && this.value) {
          return String(this.value).replace(/[&]+/g, ' ').replace(/[%]/g, '')
        }
        return ''
      },
      set(val) {
        this.text = val || ''
        this.getFull()
      }
    },
    fromComputed: {
      get() {
        if ((this.inputType === 'range' || this.inputType === 'datetime') && this.value) {
          const val = String(this.value).replace(/(?:^>=?([0-9,.]+))?.*?$/, '$1')
          if (val === '') return ''
          return val
        }
        return ''
      },
      set(val) {
        this.from = val || ''
        this.getFull()
      }
    },
    toComputed: {
      get() {
        if ((this.inputType === 'range' || this.inputType === 'datetime') && this.value) {
          const val = String(this.value).replace(/^.*?(?:<=?([0-9,.]+))?$/, '$1')
          if (val === '') return ''
          return val
        }
        return ''
      },
      set(val) {
        this.to = val || ''
        this.getFull()
      }
    },
    statuses() {
      return (c.getConstructor(this.type).possibleStatuses || []).map((s) => ({
        text: c.statuses[s],
        value: s
      }))
    },
    inputType() {
      if (/_time(_|$)/.test(this.column.sortBy)) {
        return 'datetime'
      } else if (
        this.column.sortBy !== `${this.type}_id` &&
        this.column.mapTo &&
        /(_ids?|_owner|_creator)$/.test(this.column.sortBy)
      ) {
        return 'select'
      } else if (
        /number|currency|percentage/.test(this.formatType) ||
        c.isNumericField(this.column.sortBy)
      ) {
        return 'range'
      } else if (/status/.test(this.formatType) || /creator|owner/.test(this.column.sortBy)) {
        return 'select'
      }
      return 'string'
    },
    selectType() {
      return this.column.mapTo
    },
    selectFilters() {
      if (/owner|creator/.test(this.column.sortBy)) {
        return {
          contact_object_type: 'user'
        }
      }
      return {}
    }
  },
  methods: {
    checkIfShouldReverse() {
      if (this.to && this.from && this.from > this.to) {
        const from = this.from
        const to = this.to
        this.fromComputed = to
        this.toComputed = from
      }
    },
    getFromFromValue() {
      const matches = String(this.value).match(/>=?([0-9,.$%]+)/)
      return matches && matches.length ? c.toNum(matches[1]) : ''
    },
    getToFromValue() {
      const matches = String(this.value).match(/<=?([0-9,.$%]+)/)
      return matches && matches.length ? c.toNum(matches[1]) : ''
    },
    setChooseFormatted(text) {
      this.formatted = text
    },
    getFull() {
      let from = this.from
      let to = this.to
      let fromRaw
      let toRaw
      let fromFormatted
      let toFormatted
      let full = ''
      let fullFormatted = ''

      // Set >= and <= for raw values
      if (this.inputType === 'datetime' || this.inputType === 'range') {
        from = +this.from == 0 ? '' : this.from
        to = +this.to == 0 ? '' : this.to

        // Get individual raw and formatted values
        if (from !== '') {
          fromRaw = /\.0*$/.test(from) ? from : c.deformat(from, this.formatType, 'startofday')
          fromFormatted = c.format(fromRaw, this.formatType)
        }
        if (to !== '') {
          toRaw = /\.0*$/.test(to) ? to : c.deformat(to, this.formatType, 'endofday')
          toFormatted = c.format(toRaw, this.formatType)
        }

        if (!c.isempty(fromRaw)) fromRaw = `>=${fromRaw}`
        if (!c.isempty(toRaw)) toRaw = `<=${toRaw}`

        // Set individual formatted descriptors
        if (!c.isempty(fromRaw) && !c.isempty(toRaw)) {
          full = `${fromRaw}&&${toRaw}`
          fullFormatted = `between ${fromFormatted} and ${toFormatted}`
        } else if (!c.isempty(fromRaw)) {
          full = `${fromRaw}`
          fullFormatted = `${this.inputType === 'range' ? 'more than' : 'after'} ${fromFormatted}`
        } else if (!c.isempty(toRaw)) {
          full = `${toRaw}`
          fullFormatted = `${this.inputType === 'range' ? 'less than' : 'before'} ${toFormatted}`
        }
      } else if (this.inputType === 'status' || this.inputType === 'select') {
        full = c.makeArray(this.chooseValue).join(',')
        fullFormatted = this.chooseFormatted.join(' or ')
      } else if (this.inputType === 'string') {
        if (this.text !== '') {
          full = `%${this.text.split(/\s+/).join('%&&%')}%`
          fullFormatted = `contains '${this.text.split(' ').join("' and '")}'`
        }
      }

      this.formatted = fullFormatted
      this.raw = full
    }
  },
  beforeUnmount() {},
  props: {
    filters: {
      required: true
    },
    type: {
      required: true
    },
    value: {
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  components: { Choose, DatePicker }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.filter-field {
  span {
    margin: 0px 5px;
    color: $cool-gray-700;
  }
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
}
</style>
