export default {
  methods: {
    async hasExternalChanges() {
      if (this.storeName !== 'Quote') return false

      return this.$store.dispatch('Quote/hasUpstreamChanges', {
        id: this.quote_id,
        sinceTime: this.change_order_time_last_modified
      })
    },
    async integrateExternalChanges() {
      if (this.storeName !== 'Quote') return false

      const changes = await this.hasExternalChanges()

      if (!changes || !changes.changed) return false
      // if (String(changes.changedBy || '')
      //   === String(this.$store.state.session.user.user_id)) return false;

      const { object: user } = await this.$store.dispatch('User/resolveObject', {
        id: changes.changedBy
      })

      this.$store.dispatch('alert', {
        message: `Integrating changes made by ${user.user_fname} ${user.user_lname}...`
      })

      this.$store.dispatch('addLoading')

      await this.$store.dispatch('Quote/integrateUpstreamChanges', {
        refId: this.refId
      })

      this.$store.dispatch('endLoading')

      return changes
    }
  }
}
