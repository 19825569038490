<template>
  <div class="pdf-container-outer w-full h-full flex flex-col items-center">
    <div class="pdf-container w-full h-full !margin-auto max-w-[900px] max-h-[85vh]">
      <div class="pdf-error" v-if="pdfError">
        There was an error and the data could not be loaded. Try again.
      </div>
      <VuePdfEmbed
        class="viewer-container"
        v-if="absoluteUrl"
        ref="pdf"
        :source="absoluteUrl"
        :page="currentPage"
        :scale="scaleLocal"
        @loaded="loaded"
        @progress="progress"
        @page-loaded="pageLoaded"
        @numpages="numPages"
        @loading-failed="error"
      >
        <template #loading>
          <LoadingIndicator ref="loader" />
        </template>
      </VuePdfEmbed>
    </div>

    <Container class="controls-container">
      <btn-group>
        <Btn class="default" @click="upscale()">
          <font-awesome-icon icon="plus" />
        </Btn>
        <Btn class="default" @click="downscale()">
          <font-awesome-icon icon="minus" />
        </Btn>
      </btn-group>

      <btn-group>
        <Btn
          :class="{
            info: currentPage === index + 1,
            default: currentPage !== index + 1
          }"
          v-for="(page, index) in allPages"
          :key="index"
          @click="currentPage = index + 1"
          >{{ index + 1 }}</Btn
        >
      </btn-group>
    </Container>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed'
import LoadingIndicator from '../ui/LoadingIndicator.vue'

const emit = defineEmits(['progress', 'pageLoaded', 'numPages', 'error'])

const props = defineProps({
  src: {
    type: String
  },
  ...VuePdfEmbed.props
})

const $store = useStore()

const pdfError = ref()
const loadProgress = ref(0)
const numberOfPages = ref(0)
const load = ref(0)
const currentPage = ref(1)
const loadedPage = ref(0)
const absoluteUrl = ref('')
const scaleLocal = ref(1)
const allPages = ref([])
const pdf = ref()
const loader = ref()

const upscale = () => {
  scaleLocal.value += 0.1
}

const downscale = () => {
  scaleLocal.value -= 0.1
}

const getUrl = async () => {
  let src = props.src
  if (!/^https?:\/\//.test(src)) {
    src = await $store.dispatch('link', {
      path: src,
      query: { load: load.value }
    })
  }
  absoluteUrl.value = src
}

const loaded = () => {}

const progress = (n) => {
  loadProgress.value = n
  emit('progress', n)
}

const pageLoaded = (p) => {
  loadedPage.value = p
  emit('pageLoaded', p)
}

const numPages = (n) => {
  numberOfPages.value = n
  allPages.value = Array(n)
  emit('numPages', n)
}

const error = (e) => {
  pdfError.value = e || 1
  emit('error', e)
}

onMounted(() => {
  getUrl()
})
</script>

<style lang="scss" rel="stylesheet/scss">
@import 'vue-pdf-embed/dist/styles/annotationLayer.css';
@import 'vue-pdf-embed/dist/styles/textLayer.css';

.viewer-container {
  #viewerContainer {
    div.page {
      > .textLayer {
        display: none;
        position: absolute !important;
      }
    }
  }
}

.pdf-container-outer {
  .controls-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2em;

    /*column-gap: 1em;*/
    > *:not(:last-child) {
      margin-right: 1em !important;
    }
  }
  position: relative;
  overflow: hidden;
}
.pdf-container {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid $cool-gray-500;
  position: relative;
  overflow: scroll;

  .pdf-error {
    position: absolute;
    height: 100px;
    width: 200px;
    top: calc(50% - 50px);
    left: calc(50% - 100px);
    border-radius: 15px;
    line-height: 1.1;
    background: rgba(0, 0, 0, 0.5);
    color: $flame-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    padding: 2em;
  }
  .pdf-doc {
    position: relative;
    .pdf-nav {
      position: absolute;
      bottom: 10px;
      font-size: 2em;
      opacity: 0.6;
      color: $green-500;
      &.prev-page {
        left: 20px;
      }
      &.next-page {
        right: 20px;
      }
      &:hover {
        opacity: 1;
        color: $green-500;
      }
    }
  }
}
</style>
