<template>
  <div class="gallery-pop-up">
    <Galleria
      v-model:activeIndex="activeIndex"
      v-model:visible="open"
      :value="images"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="false"
      :pt="{
        closeButton: 'hidden',
        nextItemButton:
          'inline-flex justify-center items-center overflow-hidden bg-surface-900 text-white w-10 h-10 transition duration-200 ease-in-out mx-2 top-1/2 mt-[-0.5rem] right-0 !absolute hover:bg-surface-5/10 hover:text-white focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
        previousItemButton:
          'inline-flex justify-center items-center overflow-hidden bg-surface-900 text-white w-10 h-10 transition duration-200 ease-in-out mx-2 top-1/2 mt-[-0.5rem] left-0 !absolute hover:bg-surface-5/10 hover:text-white focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50'
      }"
    >
      <template #item="slotProps">
        <img
          v-if="slotProps.item?.src"
          :src="slotProps.item.src"
          alt="Message file"
          class="w-full rounded-md max-w-3xl max-h-[90vh] object-cover"
        />
      </template>
      <template #footer>
        <div class="w-full flex justify-center items-center p-2">
          <button @click="onDownload" class="text-surface-0 text-sm px-2">
            <font-awesome-icon :icon="['fal', 'download']"></font-awesome-icon>
            <span class="ml-2">Download</span>
          </button>
          <button v-if="showRemove" @click="onDelete" class="text-surface-0 text-sm px-2 mx-4">
            <font-awesome-icon :icon="['fal', 'trash-can']"></font-awesome-icon>
            <span class="ml-2">Delete</span>
          </button>
          <button @click="closeModal" class="text-surface-0 text-sm px-2">
            <font-awesome-icon :icon="['fal', 'xmark']"></font-awesome-icon>
            <span class="ml-2">Close</span>
          </button>
        </div>
      </template>
    </Galleria>
  </div>
</template>

<script setup>
import { v4 } from 'uuid'
import { ref } from 'vue'
import Galleria from 'primevue/galleria'

// ======== Props ======== //
/**
 * Guideline on props shape for your convenience,
 * and no, I didn't need to use jsdoc
 * @param images {Array<({ src: string, alt: string })>}
 */
const props = defineProps({
  images: {
    type: Array,
    required: true
  },
  showRemove: {
    type: Boolean,
    default: false
  }
})
// ===================== //

// ======== Emits ======== //
const emit = defineEmits(['removeFile'])
// ===================== //

// ======== Data ======== //
const activeIndex = ref(0)
const open = ref(false)
// ===================== //

// ======== Methods ======== //
const openModal = (url) => {
  const index = props.images.findIndex((image) => image.src === url)

  if (index >= 0) {
    activeIndex.value = index
  }

  open.value = true
}
const closeModal = () => {
  open.value = false
}
const onDownload = () => {
  const image = props.images[activeIndex.value]
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = image.src
  a.download = image.name || v4()
  document.body.appendChild(a)
  a.click()
  a.remove()
}
const onDelete = () => {
  emit('removeFile', [props.images[activeIndex.value].src])
}
// ===================== //

// ======== Expose ======== //
defineExpose({
  openModal,
  closeModal
})
// ===================== //
</script>
