export default {
  'Review all': 'Review all',
  'Review all items': 'Review all items',
  'Selections': 'Selections',
  'Click to toggle expand': 'Click to toggle expand',
  'Please make a selection above before continuing': 'Please make a selection above before continuing',
  'This is a required selection': 'This is a required selection',
  'Category': 'Category',
  'Product': 'Product',
  'Quantity': 'Quantity',
  'Price': 'Price',
  'Management fee': 'Management fee',
  'not included': 'not included',
  'each': 'each',
  'Invoice': 'Invoice',
  'Project summary': 'Project summary',
  'Total project value': 'Total project value',
  'Payments received': 'Payments received',
  'Invoices payable': 'Invoices payable',
  'Not yet due': 'Not yet due',
  'Due date': 'Due date',
  'subtotal': 'subtotal',
  'Taxes Total': 'Taxes Total',
  'Total due now': 'Total due now',
  'No item name': 'No item name',
  'Review the details of this quote': 'Review the details of this quote',
  'tax on {taxOn} derived from {taxType}': 'tax on {taxOn} derived from {taxType}',
  'loan': 'loan',
  'From': 'From',
  'Cancel loan': 'Cancel loan',
  'month': 'month',
  'Estimated loan payment': 'Estimated loan payment',
  'powered by': 'powered by',
  'No selection required by customer': 'No selection required by customer',
  "This proposal has expired and is no longer valid": "This proposal has expired and is no longer valid",
  "Request a new proposal": "Request a new proposal",
  "Thank you for your submission! We will get back to you as soon as possible": "Thank you for your submission! We will get back to you as soon as possible",
  "Submission Successful": "Submission Successful",
  "Could not find form": "Could not find form",
  "This form does not exist. Please double check the url": "This form does not exist. Please double check the url",
  "Saving form submission": "Saving form submission",
  "Submit": "Submit",
  "To get started creating an estimate, please select the options provided below": "To get started creating an estimate, please select the options provided below",
  "About us": "About us",
  "Get a quote": "Get a quote",
  "Company rating": "Company rating",
  "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality",
  "You will receive a copy of the estimate by email for your records": "You will receive a copy of the estimate by email for your records",
  "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Email": "Email",
  "Phone": "Phone",
  "Suite": "Suite",
  "Address": "Address",
  "City": "City",
  "Zip Code / Postal Code": "Zip Code / Postal Code",
  "Project Details": "Project Details",
  "Project Type": "Project Type",
  "Project Budget": "Project Budget",
  "Project Timeline": "Project Timeline",
  "Tell us about your project.": "Tell us about your project.",
  "Tell us the type of project you need.": "Tell us the type of project you need.",
  "Tell us your budget for the project.": "Tell us your budget for the project.",
  "Tell us when you want the project to start and any other important dates.": "Tell us when you want the project to start and any other important dates.",
  "Add to estimate": "Add to estimate",
  "Remove from estimate": "Remove from estimate",
  "Add": "Add",
  "Save": "Save",
  "Included": "Included",
  "Not included": "Not included",
  "Same price": "Same price",
  "Learn more": "Learn more",
  "Select": "Select",
  "Optional item details": "Optional item details",
  "No description available": "No description available",
  "Description": "Description",
  "Features": "Features",
  "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.": "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.",
  "Cancel": "Cancel",
  "Upgrade details": "Upgrade details",
  "Select this": "Select this",
  "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.",
  "View options": "View options",
  "Welcome to your interactive estimate": "Welcome to your interactive estimate",
  "In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.": "In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.",
  "There are also items marked 'Optional', which you can choose to add to your project if you wish.": "There are also items marked 'Optional', which you can choose to add to your project if you wish.",
  "Thanks, and enjoy your experience using Bolster's Interactive Estimates!": "Thanks, and enjoy your experience using Bolster's Interactive Estimates!",
  "Dismiss": "Dismiss"
};
