import Function from './function.worker?worker';
import Audit from './audit.worker?worker';

const workers = {
  Function: new Function(),
  Audit: new Audit(),
};

const pWorker = [];

workers.Audit.onmessage = (e) => {
  pWorker[e.data.pWorkerIndex].resolve(e.data.result);
  pWorker[e.data.pWorkerIndex] = null;
};

workers.Audit.onerror = (e) => {
  console.error('Worker error', e);
  // If something fails in the worker, reject all outstanding promises.
  pWorker.forEach((i) => {
    if (i) {
      i.reject(e);
    }
  });
};

const work = async (fn, args = [], scope = {}) => {
  let worker;

  let method = null;
  if (typeof fn === 'string') {
    [worker, method] = fn.split(':');

    if (!(worker in workers)) throw new Error(`Worker ${worker} does not exist`);

    worker = workers.Audit;
  } else {
    worker = new workers.Function();
  }

  let resolve;
  let reject;

  const prom = new Promise((res, rej) => {
    resolve = succ => setTimeout(() => res(succ), 50);
    reject = err => setTimeout(() => rej(err), 50);
  });

  const pWorkerIndex = pWorker.push({ resolve, reject }) - 1;

  worker.postMessage({
    scope,
    fn: fn.toString(),
    args,
    method,
    pWorkerIndex,
  });

  return prom;
};

export default {
  work,
};
