import UserError from '../../../imports/api/UserError'
/**
 * This is to be added to any body component
 * that can be used by the 'modal/selector' vuex dispatch
 *
 * It must contain a grid component with ref=grid
 */
export default {
  selectorBody: null,
  methods: {
    selectAll() {
      this.selectorBody.selectAll()
    },
    deselectAll() {
      this.selectorBody.deselectAll()
    }
  },
  async mounted() {
    await this.$nextTick()

    if (
      this.$refs.grid &&
      typeof this.$refs.grid.selectAll === 'function' &&
      typeof this.$refs.grid.deselectAll === 'function'
    ) {
      this.selectorBody = this.$refs.grid
    } else if (
      this.$refs.body &&
      typeof this.$refs.body.selectAll === 'function' &&
      typeof this.$refs.body.deselectAll === 'function'
    ) {
      this.selectorBody = this.$refs.body
    } else if (typeof this.selectAll === 'function' && typeof this.deselectAll === 'function') {
      this.selectorBody = this
    }

    if (!this.selectorBody) {
      throw new UserError({
        message:
          'You must provide a "body" or "grid" reference (or on the component itself) ' +
          'that has selectAll and deselectAll methods on it to use in the SelectorBody mixin. ' +
          'The selector body must also emit "selected" with the objects selected returned.',
        userMessage: 'An error occurred please refresh.'
      })
    }

    this.selectorBody.$on('selected', (objects) => this.$emit('selected', objects))
  },
  props: {
    filters: {
      default: () => ({})
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  emits: ['selected']
}
