import StripePaymentsMixin from './StripePaymentsMixin'

/**
 * Stripe specific payfac mixin
 */

export default {
  name: 'StripePayFac',
  mixins: [StripePaymentsMixin],
  data() {
    return {
      stripeMccCodes: [
        {
          text: 'A/C, Refrigeration Repair',
          value: '7623'
        },
        {
          text: 'Carpentry Services',
          value: '1750'
        },
        {
          text: 'Cleaning and Maintenance',
          value: '7349'
        },
        {
          text: 'Concrete Work Services',
          value: '1771'
        },
        {
          text: 'Construction Materials (Not Elsewhere Classified)',
          value: '5039'
        },
        {
          text: 'Electrical Services',
          value: '1731'
        },
        {
          text: 'Funeral Services, Crematories',
          value: '7261'
        },
        {
          text: 'Furniture Repair, Refinishing',
          value: '7641'
        },
        {
          text: 'General Services',
          value: '1520'
        },
        {
          text: 'Heating, Plumbing, A/C',
          value: '1711'
        },
        {
          text: 'Landscaping Services',
          value: '0780'
        },
        {
          text: 'Lumber, Building Materials Stores',
          value: '5211'
        },
        {
          text: 'Masonry, Stonework, and Plaster',
          value: '1740'
        },
        {
          text: 'Paints, Varnishes, and Supplies',
          value: '5198'
        },
        {
          text: 'Plumbing, Heating Equipment, and Supplies',
          value: '5074'
        },
        {
          text: 'Roofing/Siding, Sheet Metal',
          value: '1761'
        },
        {
          text: 'Special Trade Services',
          value: '1799'
        },
        {
          text: 'Welding Repair',
          value: '7692'
        }
      ]
    }
  },
  methods: {
    /**
     * Data is already mapped properly so just return it
     * @param {Object} payload
     * @returns {Object}
     */
    stripeMapDataToPayfac(payload) {
      return payload.counterparty
    },
    stripeOnboardPayee({ counterparty, externalAccount, publicToken, metadata, owners }) {
      return this.onboardPayee({
        ...(publicToken ? { publicToken } : {}),
        ...(metadata ? { metadata } : {}),
        ...counterparty,
        owners,
        bankAccount: externalAccount
      })
    }
  }
}
