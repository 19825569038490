// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'module',
  
  skipAudit: true,

  fields: {
    company_module_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    company_module_time_created: {
      type: 'int',
      filter: false,
      format: 'datetime',
      mapTo: false,
    },
    company_module_status: {
      type: 'string',
      filter: false,
      format: 'status',
      mapTo: false,
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
    },
    module_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    module_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    module_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    module_handle: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    module_on: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
  },
};
