<template>
  <div class="flex flex-col divide-y-2 divide-surface-300">
    <Danger v-if="Object.keys(circ).length" class="my-4">
      <div class="font-normal">
        <span class="font-medium">Circular dependencies</span> - please repair your circular
        dependencies with the following dimensions that reference eachother to ensure your
        quantities are always correct:
        <ul class="mt-4">
          <li v-for="(list, key) in circ" :key="key" class="font-normal">
            <span class="font-medium">{{ key }}</span> -- references: {{ list.join(', ') }}
          </li>
        </ul>
      </div>
    </Danger>
    <div class="flex flex-col divide-y-2 divide-surface-300">
      <area-dimension
        v-for="dimension in visibleMappedRequiredDimensions"
        :key="dimension.abbr"
        :value="dimension"
        :store="store"
        :object="object"
        :reference="reference"
        :full="full"
        :inputSize="inputSize"
        required
        @input="(dim) => setDimension(dimension.abbr, dim)"
      />
    </div>

    <Btn
      class="!text-left !justify-start -ml-3"
      v-if="!showDerivedDimensionsLocal && countDemoted"
      link
      size="lg"
      @click="showDerivedDimensionsLocal = 1"
    >
      See {{ countDemoted }} computed dimensions...
    </Btn>
  </div>
</template>

<script>
import AreaDimension from './AreaDimension.vue'
import AssemblyDimensionsMixin from './AssemblyDimensionsMixin'

export default {
  name: 'AreaDimensions',

  mixins: [AssemblyDimensionsMixin],

  components: {
    AreaDimension
  },

  props: {
    full: {
      type: Boolean,
      default: false
    },
    inputSize: {
      default: 'text-md'
    },
    // Only show selected dimensions by abbr
    show: {
      type: Array,
      default: null
    }
  },
  computed: {
    visibleMappedRequiredDimensions() {
      return this.mappedRequiredDimensions.filter(
        (dimension) =>
          (!this.show || this.show.includes(dimension.abbr)) &&
          (!dimension.demote || this.showDerivedDimensionsLocal)
      )
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.container {
  position: relative;
  padding: 0;
}

.item {
  width: 100%;
  height: 90px;
  box-sizing: border-box;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: 1000;
  //background-color: $surface-100 !important;
  border-radius: 0.25rem;
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>
