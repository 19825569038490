import { useStore } from 'vuex'
import { getRatingsByType } from '@/apollo-client/services/rating'
import eventBus from '@/eventBus'

const useRating = () => {
  const store = useStore()

  const getAllRatingsByType = async ({ creatorId, type }) => {
    const input = {
      id: creatorId,
      limit: 50
    }

    const firstRatings = await getRatingsByType({ input, type })
    const allRatings = [...firstRatings.items]

    let nextToken = firstRatings.nextToken
    while (nextToken) {
      const nextInput = {
        ...input,
        nextToken
      }
      const { items: actualItems, nextToken: actualNextToken } = await getRatingsByType({
        nextInput,
        type: props.type
      })
      allRatings.push(...actualItems)
      nextToken = actualNextToken
    }

    return allRatings
  }

  const idsFromRating = ({ rating }) => {
    let clientId, contractorId
    if (rating.type === 'ClientRating') {
      clientId = rating.clientId
      contractorId = rating.creatorId
    } else {
      clientId = rating.creatorId
      contractorId = rating.contractorId
    }
    return {
      clientId,
      contractorId
    }
  }

  const idsFromQuote = ({ quote }) => {
    return {
      clientId: quote.client_id,
      contractorId: quote.owner_id
    }
  }

  const idsFromRatings = ({ ratings }) => {
    const clientIds = new Set()
    const contractorIds = new Set()
    ratings.map((rating) => {
      const { clientId, contractorId } = idsFromRating({ rating })
      clientIds.add(clientId)
      contractorIds.add(contractorId)
    })

    return {
      clientIds,
      contractorIds
    }
  }

  const idsFromData = ({ ratings = [], quotes = [] }) => {
    const { clientIds, contractorIds } = idsFromRatings({ ratings })
    const { clientIds: quoteClientIds, contractorIds: quoteContractorIds } = idsFromQuotes({
      quotes
    })

    return {
      clientIds: new Set([...clientIds, ...quoteClientIds]),
      contractorIds: new Set([...contractorIds, ...quoteContractorIds])
    }
  }

  const entityDetailsMaps = async ({ ratings = [], quotes = [] }) => {
    const { clientIds, contractorIds } = idsFromData({ ratings, quotes })

    const userDetails = await fetchUserDetails({ userIds: Array.from(contractorIds) })
    const clientDetails = await fetchClientDetails({ clientIds: Array.from(clientIds) })

    const userDetailsMap = new Map(userDetails.map((user) => [user.id, user]))
    const clientDetailsMap = new Map(clientDetails.map((client) => [client.id, client]))

    return {
      userDetailsMap,
      clientDetailsMap
    }
  }

  const ratingsWithNames = ({ ratings, detailsMaps }) => {
    const { userDetailsMap, clientDetailsMap } = detailsMaps

    return ratings.reduce((acc, rating) => {
      const { clientId, contractorId } = idsFromRating({ rating })
      const { name: clientName } = clientDetailsMap.get(clientId)
      const { name: contractorName } = userDetailsMap.get(contractorId)

      acc.push({
        clientName,
        contractorName,
        ...rating
      })

      return acc
    }, [])
  }

  const quotesToIgnore = (ratings) => {
    if (!ratings || ratings.length === 0) {
      return ''
    }

    return ratings.map((quote) => `!${quote.quoteId}`).join(',')
  }

  const outstandingQuotes = async ({ id, type, ownRatings }) => {
    const quoteIds = quotesToIgnore(ownRatings)

    const filters = {
      quote_status: 'f', // TODO: change to 'g' when ready (completed)
      quote_id: quoteIds
    }

    if (type === 'clientId') {
      const quotes = await store.dispatch('Quote/search', {
        filters: {
          client_id: id,
          ...filters
        }
      })

      return quotes.set
    }

    const quotes = await store.dispatch('Quote/search', {
      filters: {
        owner_id: id,
        ...filters
      }
    })
    return quotes.set
  }

  const idsFromQuotes = ({ quotes }) => {
    const clientIds = new Set()
    const contractorIds = new Set()
    quotes.map((quote) => {
      clientIds.add(quote.client_id)
      contractorIds.add(quote.owner_id)
    })

    return {
      clientIds,
      contractorIds
    }
  }

  const ratableQuotesWithNames = ({ quotes, detailsMaps }) => {
    const { userDetailsMap, clientDetailsMap } = detailsMaps

    return quotes
      .filter((q) => q.client_status === 'a' && q.user_status === 'a')
      .reduce((acc, quote) => {
        const { clientId, contractorId } = idsFromQuote({ quote })

        const { name: clientName } = clientDetailsMap.get(clientId)
        const { name: contractorName } = userDetailsMap.get(contractorId)

        if (clientName === false || contractorName === false) {
          return acc
        }

        acc.push({
          clientName,
          contractorName,
          ...quote
        })

        return acc
      }, [])
  }

  const getUser = async ({ userId }) => {
    return await store.dispatch('User/search', {
      filters: {
        user_id: userId
      }
    })
  }

  const getClient = async ({ clientId }) => {
    return await store.dispatch('Client/search', {
      filters: {
        client_id: clientId
      }
    })
  }

  const getCompany = async ({ companyId }) => {
    return await store.dispatch('Company/search', {
      filters: {
        company_id: companyId
      }
    })
  }

  const fetchRatingData = async ({ id, type = 'clientId' }) => {
    const previouslyCreatedRatings = await getAllRatingsByType({ creatorId: id, type: 'creatorId' })
    const ratingsAboutMe = await getAllRatingsByType({ creatorId: id, type })
    const quotes = await outstandingQuotes({ id, type, ownRatings: previouslyCreatedRatings })

    const detailsMaps = await entityDetailsMaps({
      ratings: [...previouslyCreatedRatings, ...ratingsAboutMe],
      quotes
    })

    const prevCreatedRatingsWithNames = ratingsWithNames({
      ratings: previouslyCreatedRatings,
      detailsMaps
    })
    const ratingsAboutMeWithNames = ratingsWithNames({ ratings: ratingsAboutMe, detailsMaps })
    const quotesWithNames = ratableQuotesWithNames({ quotes, detailsMaps })

    return {
      [type]: id,
      data: {
        own: prevCreatedRatingsWithNames,
        byOthers: ratingsAboutMeWithNames,
        outstandingProjects: quotesWithNames
      }
    }
  }

  const fetchUserName = async ({ creatorId, type }) => {
    let details
    if (/^clientRating$/i.test(type)) {
      details = await fetchUserDetails({ userIds: [creatorId] })
    } else {
      details = await fetchClientDetails({ clientIds: [creatorId] })
    }

    if (!details || details.length !== 1) {
      return false
    }

    const { name } = details[0]

    return name
  }

  const fetchUserDetails = async ({ userIds }) => {
    const response = await store.dispatch('ajax', {
      path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/userDetails`,
      data: { ids: userIds }
    })

    return response.payload
  }

  const fetchClientDetails = async ({ clientIds }) => {
    const response = await store.dispatch('ajax', {
      path: `/api/${import.meta.env.VITE_LEAD_FORM_KEY}/external/clientDetails`,
      data: { ids: clientIds }
    })

    return response.payload
  }

  const initializeForClient = async ({ clientId }) => {
    const payload = await fetchRatingData({ id: clientId, type: 'clientId' })
    await store.dispatch('rating/setClientRatingData', payload)

    const ratingByOthers = payload.data.byOthers
    const creatorIds = new Set([
      ...payload.data.outstandingProjects
        .filter((p) => !!p.clientId)
        .map((p) => String(p.clientId)),
      ...ratingByOthers.filter((r) => !!r.creatorId).map((r) => r.creatorId)
    ])
    for (const creatorId of creatorIds) {
      const payload = await fetchRatingData({ id: creatorId, type: 'contractorId' })
      await store.dispatch('rating/setContractorRatingData', payload)
      eventBus.$emit(`${creatorId}-initialized-for-contractor`)
    }

    eventBus.$emit(`${clientId}-initialized-for-client`)
  }

  const initializeForContractor = async ({ contractorId }) => {
    const payload = await fetchRatingData({ id: contractorId, type: 'contractorId' })
    await store.dispatch('rating/setContractorRatingData', payload)

    const ratingByOthers = payload.data.byOthers
    const clientIds = new Set(ratingByOthers.map((r) => r.creatorId))
    for (const clientId of clientIds) {
      const payload = await fetchRatingData({ id: clientId, type: 'clientId' })
      await store.dispatch('rating/setClientRatingData', payload)
      eventBus.$emit(`${clientId}-initialized-for-client`)
    }
    eventBus.$emit(`${contractorId}-initialized-for-contractor`)
  }

  return {
    initializeForClient,
    initializeForContractor,
    getAllRatingsByType,
    getCompany,
    getUser,
    getClient,
    fetchUserName
  }
}

export default useRating
