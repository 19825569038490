import { toRefs, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import _ from '../../../imports/api/Helpers'
import useTranslation from './Translation'

export const initialState = {
  invoiceQuoteId: null,
  invoiceGross: 0,
  serviceFeeNet: 0,
  quotePriceGross: 0,
  unInvoicedGross: 0,
  quoteTaxSums: [],
  oSettings: {}
}

// global state
let context = reactive({
  ...initialState
})

export default () => {
  const { l } = useTranslation()
  const n = _.notNaN
  const store = useStore()

  const invoiceProjectPercentage = computed(() =>
    _.divide(n(context.invoiceGross), n(context.quotePriceGross))
  )

  const oTaxSums = computed(() => {
    const quoteSums = context.quoteTaxSums || {}
    const projectPercentage = _.toNum(invoiceProjectPercentage.value)
    return Object.keys(quoteSums).reduce((acc, key) => {
      const sum = projectPercentage * quoteSums[key].sum
      const profit = projectPercentage * quoteSums[key].profit
      const cost = projectPercentage * quoteSums[key].cost
      return {
        ...acc,
        [key]: {
          ...quoteSums[key],
          sum,
          profit,
          cost
        }
      }
    }, {})
  })

  const invoiceTax = computed(() =>
    Object.keys(oTaxSums.value).reduce((acc, key) => acc + oTaxSums.value[key].sum, 0)
  )

  const invoiceNet = computed(() => context.invoiceGross - invoiceTax.value)

  const invoicePercentage = computed(
    () => (_.toNum(n(context.invoiceGross), 2) / _.toNum(n(context.unInvoicedGross), 2)) * 100
  )

  const invoiceTotal = computed(() => context.invoiceGross + context.serviceFeeNet)

  const taxPercentage = computed(() => _.divide(invoiceTax.value, invoiceNet.value))

  const invoice = computed(() => ({
    invoice_gross: context.invoiceGross,
    invoice_net: invoiceNet.value,
    invoice_tax: invoiceTax.value,
    invoice_project_percentage: invoiceProjectPercentage.value,
    invoice_percentage: invoicePercentage.value,
    tax_percentage: taxPercentage,
    oTaxSums: oTaxSums.value,
    quote_id: context.invoiceQuoteId,
    oSettings: context.oSettings
  }))

  const friendlyNameTaxType = (taxType) => {
    if (taxType === 'ihlt') return l('labor')
    if (taxType === 'mt') return l('materials')
    if (taxType === 'slt') return l('subcontracting')
    return l('general')
  }

  const friendlyNameTaxOn = (taxOn) => {
    if (taxOn === 'all') return 'prices'
    if (taxOn === 'cost') return 'costs'
    if (taxOn === 'profit') return 'profits'
    return taxOn
  }

  const openInvoice = (id) =>
    store.dispatch('modal/open', {
      modal: 'Receipt',
      objects: [
        {
          invoice_id: id
        }
      ]
    })

  const resetInvoice = () =>
    (context = reactive({
      ...initialState
    }))

  return {
    ...toRefs(context),
    friendlyNameTaxType,
    friendlyNameTaxOn,
    openInvoice,
    resetInvoice,
    oTaxSums,
    invoiceTax,
    invoiceNet,
    invoicePercentage,
    invoiceProjectPercentage,
    invoiceTotal,
    taxPercentage,
    invoice
  }
}
