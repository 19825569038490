<template>
  <div
    v-touch:tap="(e) => $emit('click', e)"
    @click="(e) => $emit('click', e)"
    :class="`${size ? `max-w-${size}` : 'container'} ${gap ? 'gap-14' : ''} mx-auto flex flex-col`"
  >
    <slot></slot>
  </div>
</template>

<script>
/* eslint-disable */
const maxWidths = Object.keys($tt.maxWidth)
const validSizes = [...maxWidths]

export default {
  name: 'Container',
  props: {
    size: {
      type: String,
      validator: (val) => validSizes.includes(val)
    },
    gap: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click']
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
