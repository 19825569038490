<template>
  <!-- Root div -->
  <div
    class="absolute z-[2020] bg-flame-white w-screen h-screen animate-pullUpFast"
    v-show="visible"
  >
    <!-- Modal header -->
    <div class="flex flex-row justify-between items-center w-full mx-4 my-4 min-h-12">
      <div class="flex flex-row justify-start items-center text-pitch-black gap-2 *:text-2xl">
        <icon icon="box-open-full" />
        <p>Catalog</p>
      </div>

      <!-- Header actions -->
      <div class="flex flex-row items-center gap-2 mr-8">
        <!-- Clear selected -->
        <Btn v-if="$refs.body?.selected?.length" size="lg" severity="tertiary" :action="clear">
          <font-awesome-icon icon="ban" />
          Clear selected
        </Btn>
        <!-- Add to estimate -->
        <Btn
          v-if="$refs.body?.selected?.length"
          class="animate-shake mr-2"
          size="lg"
          severity="bolster"
          :action="done"
        >
          <font-awesome-icon icon="arrow-down-to-bracket" />
          Add {{ $refs.body?.selected?.length }} to estimate
        </Btn>
        <!-- Exit -->
        <div @click="close" class="flex items-center justify-center">
          <font-awesome-icon
            class="text-surface-950 hover:text-surface-600 cursor-pointer transition"
            size="xl"
            icon="xmark"
          />
        </div>
      </div>
    </div>

    <!-- Modal body -->
    <TraverseBody
      ref="body"
      :startingCompany="$store.state.session.scope.company"
      :modal="this"
      :startingParent="!!$store.state.session.scope.franchisor ? 'NULL' : 'autocost'"
    />
  </div>
</template>

<script>
import TraverseBody from './TraverseBody.vue'

export default {
  components: {
    TraverseBody
  },
  data() {
    return {
      visible: false,
      company: this.$store.state.session.scope.company
    }
  },
  methods: {
    async open() {
      this.$refs.body.open()
      // If company has switched, set the starting category to the Bolster catalog ('autocost')
      if (this.$store.state.session.scope.company !== this.company) {
        this.$refs.body.parent = 'autocost'
        this.company = this.$store.state.session.scope.company
      }
      this.visible = true
    },
    close() {
      this.visible = false
      this.$refs.body.close()
    },
    done() {
      this.visible = false
      this.$refs.body.done()
    },
    clear() {
      this.$refs.body.clearSelected()
    }
  }
}
</script>
