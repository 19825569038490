// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'payment_method',

  possibleStatuses: ['a', 'i'],
  
  skipAudit: true,

  fields: {
    payment_method_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    payment_method_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    payment_method_fixed_cost: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    payment_method_variable_cost: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    payment_method_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
  },
};
