<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :clickAway="false"
    :expandable="false"
  >
    <template #body>
      <payment-body
        ref="body"
        @loading="(v) => (loading = v)"
        @body-loading="(v) => (loading = v)"
        :itemIds="modal.itemIds || []"
        :quote="modal.quote || null"
        :vendor="modal.vendor || null"
        :item="modal.item || null"
        :forceNet="modal.forceNet || false"
        :object="object"
      />
    </template>
  </modal>
</template>

<script>
import PaymentBody from '../bodies/PaymentNew.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalPaymentNew',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    // async saveAndClose(...args) {
    //   await this.$refs.body.save(...args);
    //   this.close();
    // },
  },
  components: { PaymentBody },
  props: {
    go: {
      default: false
    },
    type: {
      default: 'transaction'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
