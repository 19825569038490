<template>
  <div>
    <span v-if="paymentMethod.type === 'bank-account'" class="flex items-center">
      <font-awesome-icon class="mr-2" icon="building-columns" />
      <span v-if="paymentMethod.account" class="flex flex-col justify-center ml-2">
        <span>{{ paymentMethod.account.name || 'Bank Account' }}</span>
        <small>XXXX {{ paymentMethod.account.mask }}</small>
      </span>
    </span>
    <span v-if="paymentMethod.type === 'card' || paymentMethod.type === 'scheme'" class="d-flex">
      <font-awesome-icon
        v-if="paymentMethod.brand"
        class="mr-2"
        :icon="['fab', `cc-${getCreditCardIcon(paymentMethod.brand)}`]"
      />
      <span v-if="paymentMethod.lastFour">XXXX-XXXX-{{ paymentMethod.lastFour }}</span>
      <span v-if="paymentMethod.firstSix">
        {{ paymentMethod.firstSix.substring(0, 4) }}-{{
          paymentMethod.firstSix.substring(4, 4)
        }}XX-XXXX
      </span>
    </span>
  </div>
</template>

<script setup>
import usePaymentMethod from '../composables/PaymentMethod'

const { getCreditCardIcon, paymentMethod } = usePaymentMethod()
</script>
