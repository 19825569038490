import { sha256 } from '../lib/hashing'
import ChangeUtilities from '../imports/api/Changes/Utilities.js'
import FieldDetection from '../imports/api/FieldDetection.js'

const fieldsCache = {}
const getVersionCheckFields = async (type) => {
  if (!(type in fieldsCache)) {
    fieldsCache[type] = (await c.ajax(`${type}/getVersionCheckFields`))?.object?.fields ?? []
  }

  return fieldsCache[type].map((field) => {
    if (!/_index$/.test(field)) return field
    return field.replace('_index', '')
  })
}

const recursiveKsort = (obj) => {
  const keys = Object.keys(obj).sort()
  const newObject = {}
  for (const key of keys) {
    const value = obj[key]
    delete obj[key]
    obj[key] = value
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      recursiveKsort(value)
    }
    newObject[key] = value
  }

  return newObject
}

const formatNumbersAsStrings = (object, fields) => {
  const newData = {}

  const data = c.formatForPHP(object)

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]

    if (
      !(field in data) ||
      (Array.isArray(data[field]) && !data[field].length) ||
      (data[field] !== null && typeof data[field] === 'object' && !Object.keys(data[field]).length)
    ) {
      newData[field] = 'null'
      continue
    }

    const dataType = typeof data[field]
    let value = data[field]

    if (Array.isArray(value) || FieldDetection.isJsonArrayField(field)) {
      newData[field] = c.makeArray(value).join(',')
    } else if (value !== null && dataType === 'object') {
      value = recursiveKsort(value)
      newData[field] = JSON.stringify(value)
    } else if (value === '') {
      newData[field] = 'null'
    } else if (dataType === 'string' && /^([0-9.])+$/.test(value)) {
      newData[field] = (+value).toFixed(2)
    } else if (value === '0' || (dataType === 'number' && +value == 0)) {
      newData[field] = 'null'
    } else if (dataType === 'number') {
      newData[field] = value.toFixed(2)
    } else if (value === null) {
      newData[field] = 'null'
    } else {
      newData[field] = value
    }
  }

  return newData
}

const formatObjectForComparison = async (unformattedObject) => {
  const type = unformattedObject.type
  const fields = await getVersionCheckFields(type)
  const newData = formatNumbersAsStrings({ ...unformattedObject }, fields)
  return newData
}

const getVersionHash = async (object) => {
  const formatted = await formatObjectForComparison(object)
  return sha256(JSON.stringify(formatted))
}

const getSavedObject = async (type, id) =>
  (await c.ajax(`${type}/getVersionCheckObject/${id}`, {}, [], undefined, false))?.object?.object ??
  {}

const compareObjects = (...toCompare) => {
  const refId = 'abc'
  let normed = toCompare.slice(0, 2).map((obj) => ({
    [refId]: {
      ...obj,
      refId,
      parentRefId: null
    }
  }))
  const logs = ChangeUtilities.diffNormalized(...normed)
  return logs
}

const compareToSaved = async (unformattedObject) => {
  const formattedLocal = await formatObjectForComparison(unformattedObject)
  const type = unformattedObject.type
  const id = unformattedObject[`${type}_id`]
  const formattedSaved = await getSavedObject(type, id)
  const logs = compareObjects(formattedSaved, formattedLocal)
  console.log(
    '{ formattedSaved, formattedLocal, logs }',
    _.imm({ formattedSaved, formattedLocal, logs })
  ) // eslint-disable-line

  return logs
}

export default {
  getVersionHash,
  formatNumbersAsStrings,
  recursiveKsort,
  getVersionCheckFields,
  compareToSaved,
  compareObjects
}
