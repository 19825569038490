import Statuses from '../Statuses'

export default {
  type: 'event_notification',

  skipAudit: true,

  fields: {
    event_notification_id: {
      type: 'string',
      filter: false
    },
    item_id: {
      type: 'string',
      filter: true
    },
    quote_id: {
      type: 'string',
      filter: true
    },
    company_id: {
      type: 'string',
      filter: true
    },
    event_notification_recipient: {
      type: 'string',
      filter: true
    },
    event_notification_action: {
      type: 'string',
      filter: true
    },
    event_notification_object_type: {
      type: 'string',
      filter: true
    },
    aMethods: {
      type: 'array',
      filter: true,
      default: () => ['sms', 'email', 'push']
    },
    oPayload: {
      type: 'object',
      trackChanges: false,
      save: false,
      default: () => {}
    },
    event_notification_creator: {
      type: 'string',
      filter: true
    },
    event_notification_time_sent: {
      type: 'string',
      filter: true
    },
    event_notification_time_created: {
      type: 'string',
      filter: true
    },
    event_notification_time_last_modified: {
      type: 'string',
      filter: true
    },
    event_notification_status: {
      type: 'string',
      filter: true,
      default: 'a'
    },
    event_notification_owner: {
      type: 'string',
      filter: true
    }
  },

  generateVueActions() {
    return {
      async checkForNotifications({ dispatch }, { type, quoteId }) {
        const { set } = await dispatch('search', {
          filters: {
            event_notification_object_type: type,
            quote_id: quoteId,
            event_notification_status: Statuses.statuses.Active
          },
          limit: 10
        })
        dispatch(
          'updateEventNotifications',
          {
            type,
            notifications: set
          },
          { root: true }
        )
        return set
      },
      async sendByType({ dispatch }, { type, quoteId }) {
        await dispatch('ajax', {
          path: 'event_notification/sendByType',
          data: {
            type,
            quoteId
          }
        })
        dispatch(
          'clearEventNotifications',
          {
            type
          },
          { root: true }
        )
      }
    }
  }
}
