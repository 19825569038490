<template>
  <div>
    <card-section class="mt-0">
      <template #label>
        <span class="mt-0 text-xl font-medium">{{ l('Review items') }}</span>
      </template>
      <template #subLabel>
        <span class="font-sans text-base text-cool-gray-400 font-normal pl-2">
          {{ l('In order to continue, review items and terms below') }}.
        </span>
      </template>

      <ReviewAll
        v-if="$attrs && $attrs.normalized"
        :presentationSettings="$attrs.presentationSettings"
        :parentRefId="$attrs.parentRefId"
        :paymentTerms="paymentTerms"
        :normalized="$attrs.normalized"
      />

      <div>
        <h6 class="mt-0 mb-2 text-xl font-medium">{{ l('Terms and conditions') }}</h6>
        <p class="mb-4 font-sans text-base text-cool-gray-400 font-normal">
          {{ l('Review and accept to the terms below') }}.
        </p>
        <Card class="terms-viewport-text shadow-none !border-1 border-cool-gray-200">
          <div>
            <p
              style="font-size: 0.9em !important; font-weight: 500 !important; line-height: 1.5"
              v-html="
                termsHtml ||
                `<ul><li>${l('You agree to accept and proceed with the project as detailed')}</li></ul>`
              "
            ></p>
          </div>
        </Card>
      </div>

      <SignatureField :signature="signature" @signature="handleSignature" />
    </card-section>

    <p class="text-xs font-normal text-cool-gray-400 mb-4">
      {{
        l(
          'PLEASE READ THE TERMS AND CONDITIONS AND INDICATE YOUR ACCEPTANCE BY ENTERING YOUR NAME ABOVE AND ' +
            'CLICKING THE "I ACCEPT THE TERMS AND CONDITIONS" BUTTON AT THE BOTTOM OF THIS CHECKOUT PAGE. ACCEPTANCE ' +
            'OF THE AGREEMENT MEANS THAT YOU ARE BOUND BY ITS TERMS. PLEASE RETAIN IT FOR YOUR RECORDS.'
        )
      }}
    </p>
    <div v-if="canUserApproveQuote" class="flex flex-col gap-4">
      <btn-group class="block mt-4 w-full">
        <btn class="block py-3 round" @click="accept" :disabled="!signature">
          {{ l('I accept the terms and conditions') }}
        </btn>
      </btn-group>

      <btn-group class="d-block">
        <btn
          class="block !bg-cool-gray-200 !text-pitch-black round w-full border-none py-3"
          @click="accept"
        >
          {{ l('I do not accept the terms and conditions') }}
        </btn>
      </btn-group>
    </div>
  </div>
</template>

<script>
import BodyMixin from '../mixins/Body'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'
import ReviewAll from '../quote/review/ReviewAll.vue'
import LoanMixin from '../mixins/LoanMixin'
import SignatureField from '@/components/ui/fields/SignatureField.vue'
import { useSession } from '@/stores/session'
import eventBus from '@/eventBus'
import PaymentRequestsMixin from '@/components/payments/PaymentRequestsMixin'

export default {
  name: 'BodyTerms',
  mixins: [BodyMixin, TranslationMixin, LoanMixin, PaymentRequestsMixin],
  components: {
    ReviewAll,
    SignatureField
  },
  emits: ['termsAccepted', 'declined'],
  methods: {
    async accept() {
      const sessionStore = useSession()
      const { usedMagicLink } = sessionStore

      if (!this.canUserApproveQuote) return

      if (this.paymentTerms === 'loan') {
        this.applyForLoan()
      }

      const inPersonApproval = this.$route.name === 'Presentation External'

      const hasPaymentsSetup = await this.getPaymentMethods()

      if ((usedMagicLink && hasPaymentsSetup) || inPersonApproval) {
        await this.authenticateUser()
      } else {
        this.approveQuote()
      }
    },
    approveQuote() {
      this.termsAccepted({ terms: this.combinedPlainText, signature: this.signature })
    },
    decline() {
      if (!this.canUserApproveQuote) return

      this.$emit('declined')
    },
    updatePaymentTerms(terms) {
      this.paymentTerms = terms
    },
    handleSignature(signatureName) {
      this.signature = signatureName
    },
    async authenticateUser() {
      await this.$store.dispatch('Quote/twoFactorAuth', {
        refId: this.$store.state.session.quote.refId,
        go: false
      })
    },
    async getPaymentMethods() {
      const showAlert = false
      return await this.fetchPaymentMethods(showAlert)
    }
  },
  data() {
    return {
      paymentTerms: 'pay',
      signature: null
    }
  },
  computed: {
    genericTerms() {
      return ''
    },
    combinedPlainText() {
      return `${this.companyTermsPlainText || this.$store.state.session.company.company_terms_and_conditions || ''}
${this.additionalTerms}
${this.genericTerms}`.trim()
    },
    termsHtml() {
      const lines = c
        .removeHtml(this.combinedPlainText)
        .trim()
        .replace(/(?:\r\n|\r|\n){2,}/, '\r\n') // replace multi lines to just one line
        .split(/(?:\r\n|\r|\n)/g)

      let html = '<ul>'
      lines.forEach((line) => {
        if (line.trim() !== '') {
          html = `${html}${
            /^\[.*?\]/.test(line)
              ? `</ul><h6>${line.replace(/\[(.*?)\]/, '$1')}</h6><ul>`
              : `<li>${line}</li>`
          }`
        }
      })
      html = `${html}</ul>`

      return html.replace('<ul></ul>', '')
    },
    companyName() {
      return this.company || this.$store.state.session.company.company_name_short
    },
    canUserApproveQuote() {
      return (
        this.$route.name === 'Presentation' ||
        !this.$store.getters.isCompanyUser ||
        this.$store.state.session.user.user_is_super_user
      )
    }
  },
  props: {
    company: {
      // => companyName
      default: null
    },
    companyTermsPlainText: {
      default: null
    },
    additionalTerms: {
      default: null
    },
    termsAccepted: {
      type: Function,
      required: true
    }
  },
  mounted() {
    eventBus.$on('updatePaymentTerms', this.updatePaymentTerms)
    eventBus.$on('termsSaved', () => {
      this.termsAccepted({ terms: this.combinedPlainText, signature: this.signature })
    })
  },
  created() {
    this.paymentTerms = this.$attrs.paymentTerms
  },
  beforeUnmount() {
    eventBus.$off('updatePaymentTerms', this.updatePaymentTerms)
    eventBus.$off('termsSaved', this.approveQuote)
  }
}
</script>
