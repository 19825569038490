<template>
  <div class="flex items-center px-3" :class="[dragging ? 'cursor-grabbing' : 'cursor-pointer']">
    <div class="mr-3" v-if="item.type === 'task' && children.length > 0">
      <font-awesome-icon :icon="['far', item.$open ? 'chevron-down' : 'chevron-right']" />
    </div>
    <Inplace
      :pt="{
        display: '!no-underline'
      }"
      :ptOptions="{ mergeOptions: true }"
      :isActive="isActive"
      @close="() => handleUpdate(name)"
    >
      <template #display>
        <span
          class="flex items-center task-title w-full min-w-40"
          :class="[
            dragging ? 'cursor-grabbing' : 'cursor-text',
            item.text ? '' : 'italic font-normal text-cool-gray-700'
          ]"
        >
          {{ item.text ? $f.truncate(item.text, 40) : `Enter ${item.reference_type} name` }}
        </span>
      </template>
      <template #content>
        <InputText
          ref="input"
          :pt="{
            root: '!w-18 !h-6 !px-1'
          }"
          :ptOptions="{ mergeOptions: true }"
          type="text"
          v-model="name"
        />
      </template>
    </Inplace>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, onUnmounted } from 'vue'
import Inplace from '@/components/ui/Inplace.vue'
import InputText from 'primevue/inputtext'
import eventBus from '@/eventBus'
import useGantt from '@/components/ui/gantt/Gantt'
import $f from '@/filters'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  update: {
    type: Function
  }
})

const { dragging, gantt } = useGantt()
const name = ref(props.item.text)
const input = ref()
const children = ref([])
const isActive = ref(false)

const handleUpdate = (name) => {
  if (name === props.item.text) return
  props.update(name)
}

onMounted(() => {
  eventBus.$on(`focus-task-name-${props.item.id}`, () => {
    isActive.value = true
    c.throttle(
      () => {
        input.value.$el.focus()
      },
      { delay: 400 }
    )
  })
  children.value = gantt.value.getChildren(props.item.id)
})

onUnmounted(() => {
  eventBus.$off(`focus-task-name-${props.item.id}`)
})
</script>
