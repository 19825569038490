<script setup>
import {
  ref,
  computed,
  defineProps,
  onBeforeMount,
  onBeforeUnmount,
  defineModel,
  defineEmits,
  defineExpose
} from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

import StringField from '@/components/ui/Calculator/StringField.vue'
// import CostItemEquationVariables from '@/components/composables/CostItemEquationVariables.js'
import CalculatorFormatted from '@/components/ui/Calculator/CalculatorFormatted.vue'
import ItemAddons from '@/components/composables/ItemAddons.js'
import _ from '../../../../imports/api/Helpers.js'
import CalculatorField from '@/components/ui/Calculator/Field.vue'
import { useStore } from 'vuex'
import Slider from 'primevue/slider'
import CostItemEquationVariables from '@/components/composables/CostItemEquationVariables.js'
import BtnBar from '@/components/ui/BtnBar.vue'
import CostType from '@/components/bodies/CostType.vue'
import Assembly from '@/components/bodies/Assembly.vue'
import FileList from '@/components/ui/FileList.vue'
import CostItemHeader from '@/components/bodies/CostItem/Header.vue'
import ChangeTracker from '@/components/bodies/UtilityComponents/ChangeTracker.vue'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false },
  store: { type: String, required: false },
  storeName: { type: String, required: false }
})

const mobject = defineModel('object')
const refId = ref(props.refId || props.reference)
const type = ref(props.type || 'cost_item')
const store = ref(props.store || props.storeName)
const $store = useStore()

const obj = EntityComputedFields.useEntityComputedFields({
  object: mobject,
  refId,
  type,
  store
})

const { object: target, unitOfMeasureId = ref('count') } = obj

const {
  addons,
  items,
  setupMockAddons,
  getEquationField,
  getQuantityField,
  getPriceField,
  getCostField,
  addAddon,
  getNameField,
  getAddonQtyType,
  addonCanAdoptTargetQuantity,
  reaudit,
  getAddonFromObject,
  convertFromCostTypeToCostItem,
  duplicateAddon,
  commit,
  triggerAudit,
  setProfit,
  optionGroupName,
  optionGroupDesc,
  mockQty
} = ItemAddons.useItemAddons({
  object: mobject,
  refId,
  type,
  store
})
const mod = ref(null)
onBeforeMount(async () => {
  if (!addons.value.length) setupMockAddons()

  mod.value = await $store.dispatch('Quote/getQuoteMod', {
    refId: refId.value,
    store: store.value
  })

  if (target.value.type === 'cost_type') {
    mockQty.value = 100
  }
})

const emit = defineEmits(['close'])

let aborting = false
onBeforeUnmount(() => {
  if (!aborting) {
    commit(false)
  }
})

const commitAndClose = () => {
  commit(true)
  aborting = true // already committed don't need to do it again
  emit('close')
}
const abort = () => {
  aborting = true
  emit('close')
}

const selectedAddon = computed(() =>
  viewingAddon.value !== null ? addons.value[viewingAddon.value] : null
)
const selectedItem = computed(() =>
  viewingAddon.value !== null ? items.value[viewingAddon.value] : null
)

const targetMeasureType = computed(() =>
  _.getMeasureTypeForUnitOfMeasure(target.value.unit_of_measure_id || 'count')
)
const itemMeasureType = computed(() =>
  _.getMeasureTypeForUnitOfMeasure(selectedItem.value.unit_of_measure_id || 'count')
)
const sameMeasures = computed(
  () =>
    (target.value.unit_of_measure_id || 'count') ===
    (selectedItem.value.unit_of_measure_id || 'count')
)
const compatibleMeasures = computed(() => targetMeasureType.value === itemMeasureType.value)

const pics = computed(() => {
  const scope = _.getStorage('scope')
  return addons.value.map((addon) => {
    if (addon.image_external && !addon.file_ids?.length) {
      return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${addon.image_external}`
    }
    const id = addon.file_ids?.[0] ?? addon.file_id
    if (!id) return null
    return c.link(`file/view/${id}`, { max: 500 }, true, scope)
  })
})

const quantityDerivedOptions = computed(() => {
  if (!selectedItem.value || !target.value) return []
  return [
    ...(addonCanAdoptTargetQuantity(selectedAddon.value, selectedItem.value, target.value)
      ? [
          {
            title: 'Adopt the quantity from the estimate (default)',
            desc: 'With this approach, the new selection will adopt the quantity (or formula) from the item that it replaces in the original estimate.',
            icon: 'link',
            value: 'default'
          }
        ]
      : []),
    {
      title: 'Manually set the quantity (or equation)',
      desc: 'With this approach, the new selection will use only the quantity (or formula) you set for it. If you use dimensions in your formula, you will be prompted to provide them in your estimate automatically!',
      icon: 'link-slash',
      value: 'manual'
    }
  ]
})

const targetPrice = computed(
  () => target.value[getPriceField(target.value)] ?? target.value.cost_matrix_rate_net
)
const targetCost = computed(
  () => target.value[getCostField(target.value)] ?? target.value.cost_matrix_aggregate_cost_net
)
const targetProfit = computed(() => targetPrice.value - targetCost.value)

const deltas = computed(() => {
  return addons.value.map((addon, index) => {
    const itemPrice = addon.price
    const itemCost = _.divide(itemPrice, addon.markup)
    const itemProfit = itemPrice - itemCost
    const itemPerc = _.divide(itemProfit, itemPrice)
    const profitDelta = itemProfit - targetProfit.value
    const priceDelta = itemPrice - targetPrice.value

    return {
      index,
      itemCost,
      itemPrice,
      itemProfit,
      itemPerc,
      profitDelta,
      priceDelta,
      profitDeltaPerc: targetProfit.value ? profitDelta / targetProfit.value : null
    }
  })
})

const addonQtyTypes = computed(() =>
  addons.value.map((addon, index) => getAddonQtyType(addon, items.value[index], target.value))
)
const picId = computed({
  get() {
    return addons.value[viewingAddon.value].file_ids?.length
      ? addons.value[viewingAddon.value].file_ids
      : addons.value[viewingAddon.value].file_id
  },
  set(id) {
    if (id === null) {
      items.value[viewingAddon.value].file_ids.splice(1, 1)
      addons.value[viewingAddon.value].file_id = null
    } else {
      if (_.isArray(id)) {
        items.value[viewingAddon.value].file_ids = [
          ...id,
          ...items.value[viewingAddon.value].file_ids
        ]
        addons.value[viewingAddon.value].file_id = id[0]
        addons.value[viewingAddon.value].file_ids = id
      } else {
        items.value[viewingAddon.value].file_ids = [id, ...items.value[viewingAddon.value].file_ids]
        addons.value[viewingAddon.value].file_id = id
      }
    }
  }
})
const selectedEquation = computed({
  get() {
    return addons.value[viewingAddon.value].equation
  },
  set(val) {
    const eqfield = getEquationField(selectedItem.value)
    addons.value[viewingAddon.value].equation = `${val}`
    addons.value[viewingAddon.value].embue = {
      ...addons.value[viewingAddon.value].embue,
      [eqfield]: `${val}`
    }

    triggerAudit(viewingAddon.value)
  }
})
const qtyType = computed({
  get() {
    return addonQtyTypes.value[viewingAddon.value]
  },
  set(value) {
    const canBeDefault = addonCanAdoptTargetQuantity(
      selectedAddon.value,
      selectedItem.value,
      target.value
    )
    const eqfield = getEquationField(selectedItem.value)
    // eslint-disable-next-line no-unused-vars
    const { [eqfield]: omit, ...rest } = selectedAddon.value.embue

    if (value === 'default' && canBeDefault) {
      // Adopt the qty/equation from the target
      addons.value[viewingAddon.value].embue = rest
      addons.value[viewingAddon.value].equation = null
    } else {
      // Manually set the qty/equation, default to the saved equation if exists
      const teqfield = getEquationField(target.value)
      const tqtyfield = getQuantityField(target.value)

      const qty = (compatibleMeasures.value && target.value[tqtyfield]) || mockQty.value
      const eq =
        (selectedItem.value.type === 'assembly' && '1') || // default assemblies to qty of 1 with no equation
        (!compatibleMeasures.value && selectedItem.value[eqfield]) || // default incompatible cost types to the SAVED equation from the cost_type, (turning 'saved' qty type into 'manual')
        (compatibleMeasures.value && target.value[teqfield]) || // default compatible cost types to the target's equation, to be managed manually from then on
        `${qty}` // fallback to the targets raw qty to be then managed directly
      addons.value[viewingAddon.value].embue[eqfield] = eq
      addons.value[viewingAddon.value].equation = eq
    }

    triggerAudit(viewingAddon.value)
  }
})
const profit = computed({
  get() {
    return c.markupToMargin(selectedAddon.value.markup) * 100
  },
  set(val) {
    setProfit(viewingAddon.value, val)
  }
})
const costNet = computed({
  get() {
    return selectedItem.value[getCostField(selectedItem.value)]
  }
})
const priceNet = computed({
  get() {
    return selectedItem.value[getPriceField(selectedItem.value)]
  },
  set(p) {
    profit.value = _.divide(p - costNet.value, p) * 100
  }
})
const profitNet = computed({
  get() {
    return priceNet.value - costNet.value
  },
  set(p) {
    priceNet.value = p + costNet.value
  }
})

const chooseNewAddon = async () => {
  const choice = await $store.dispatch('modal/asyncConfirm', {
    message: 'What kind of item do you want to add?',
    choices: [
      {
        icon: 'sparkle',
        title: 'New item',
        desc: `Choose this to build a whole new item.  To make it easier for you, we'll copy from "${target.value[getNameField(target.value)]}" to get you started.`,
        value: 'new'
      },
      {
        icon: 'box-open-full',
        title: 'Existing saved item',
        desc: `Browse your private catalog and/or Bolster items for the perfect options.`,
        value: 'catalog'
      }
    ]
  })

  if (choice === null) return
  if (choice === 'new') return addAddon(null, null, true)

  const list = await selectItems()

  await Promise.all(list.map((sel) => addAddon(...sel)))
}

const selectItems = async () => {
  const selections = await $store.dispatch('openItemSelector', {
    mod: mod.value
  })

  return selections.reduce((acc, i) => {
    if (i.cost_type_is_addon_group) {
      $store.dispatch('alert', {
        message: `${i.cost_type_name}: Option group items cannot be upgrades.`
      })
      return acc
    }

    acc.push([i.type, i[`${i.type}_id`]])

    return acc
  }, [])
}

// const { getCalculatorVariablesByRefId } = CostItemEquationVariables.useCostItemEquationVariables({
//   norm: obj.norm
// })
// const variables = computed(() => getCalculatorVariablesByRefId(refId.value, unitOfMeasureId.value))
// const hasQtyEquation = computed(() => c.isEquation(cost_type_qty_equation.value, variables.value))

// const forceShowAddonOptions = ref(false)
// const isAddonItem = computed(() => !!aoAddons.value?.length || cost_type_is_addon_group.value)
// const isVariationItem = computed(() => !!cost_type_is_variation_parent.value)
// const hasVariationsOrAddons = computed({
//   get() {
//     return isAddonItem.value || isVariationItem.value || forceShowAddonOptions.value
//   },
//   set(b) {
//     forceShowAddonOptions.value = b
//   }
// })

// const makeVariationItem = () => {
//   backupAoAddons = [...aoAddons.value]
//   aoAddons.value = []
//   cost_type_is_variation_parent.value = cost_type_is_variation_parent.value ? 0 : 1
// }
// const makeAddonGroup = () => {
//   cost_type_is_addon_group.value = cost_type_is_addon_group.value ? 1 : 0
// }
// const makeAddonItem = () => {
//   if (!aoAddons.value.length) {
//     cost_type_is_variation_parent.value = 0
//     aoAddons.value = [
//       ...aoAddons.value,
//       ...(backupAoAddons.length
//         ? backupAoAddons
//         : [
//             {
//               id: null,
//               type: 'cost_type',
//               unit: unitOfMeasureId.value,
//               markup: cost_matrix_markup_net.value
//             }
//           ])
//     ]
//   }
// }
// const measureType = computed(() =>
//   c.getMeasureTypeForUnitOfMeasure(target.value?.unit_of_measure_id || 'count')
// )
// const viewMode = ref('preview')
const editableClass = 'border-2 hover:border-blue-print transition-all cursor-pointer rounded-sm'
const viewingAddon = ref(0)

const refDrop = ref(null)
const dropType = ref(null)
const refQty = ref(null)
const refPrice = ref(null)
const refPic = ref(null)
const dropFixTo = ref(null)
const viewDrop = (type, fixTo, index) => {
  handleAddonMouseover(index)
  dropType.value = type
  dropFixTo.value = fixTo[viewingAddon.value]
  setTimeout(() => refDrop.value[0].open())
}
const dropPt = {
  root: [
    'absolute left-0 top-0',
    'z-40 transform origin-center',
    'overflow-visible',

    '!bg-surface-100 dark:bg-surface-0',
    '!text-surface-900 dark:text-surface-0/80',
    'dark:border-surface-700',
    'rounded-sm ring  ring-surface-900/30 ring-6',
    'border-0 dark:border',
    // Color

    // Before: Triangle
    'before:absolute before:-top-[9px] before:-ml-[9px] before:left-[calc(var(--overlayArrowLeft,0)+1.25rem)] z-0',
    'before:w-0 before:h-0',
    'before:border-transparent before:border-solid',
    'before:border-x-[8px] before:border-[8px]',
    'before:border-t-0 before:border-b-surface-900/30 dark:before:border-b-surface-700',
    'before:hidden',

    'after:absolute after:-top-[11px] after:-ml-[8px] after:left-[calc(var(--overlayArrowLeft,0)+1.25rem)]',
    'after:w-0 after:h-0',
    'after:border-transparent after:border-solid',
    'after:border-x-[0.5rem] after:border-[0.5rem]',
    'after:border-t-0 after:border-b-surface-900/30 dark:after:border-b-surface-900/30'
  ],
  content: ['p-4', 'overflow-auto']
}

const handleAddonMouseover = (index) => {
  if (refDrop.value[0]?.visible) return
  viewingAddon.value = index
}

const { getCalculatorVariablesByRefId } = CostItemEquationVariables.useCostItemEquationVariables({
  norm: obj.norm
})

const variables = computed(() =>
  getCalculatorVariablesByRefId(refId.value, unitOfMeasureId.value, false)
)

const getQtyFormat = (unit) => {
  if (unit === 'ft') return 'imperial'
  return 'number'
}

const cards = ref(null)
const cardContainer = ref(null)

let addonDragging = ref(null)
let draggingOver = ref(null)

const handleDragEnd = (event) => {
  event.preventDefault()
  if (addonDragging.value !== null && draggingOver.value !== null) {
    let newAddons = [...addons.value]
    moveElement(newAddons, addonDragging.value, draggingOver.value)
    const newItems = newAddons.map((a) => items.value[addons.value.indexOf(a)])
    items.value = newItems
    addons.value = newAddons
  }

  addonDragging.value = null
  draggingOver.value = null
}

let preventDrag = ref(false)
const handleMousedownCapture = (event) => {
  preventDrag.value =
    event.target.closest('[content-editable="true"]') ||
    event.target.contentEditable === true ||
    event.target.contentEditable === 'true'
}
const handleDragOver = (event, index) => {
  draggingOver.value = index
  event.dataTransfer.dropEffect = 'move'
}
const handleDragStart = (e, index) => {
  if (preventDrag.value) return
  addonDragging.value = index
  e.dataTransfer.effectAllowed = 'move'
}
const handleDrop = (e) => e.preventDefault()
function moveElement(array, fromIndex, toIndex) {
  if (fromIndex < 0) fromIndex = array.length + fromIndex
  if (toIndex < 0) toIndex = array.length + toIndex

  if (fromIndex >= 0 && fromIndex < array.length) {
    const element = array.splice(fromIndex, 1)[0]
    array.splice(Math.min(toIndex, array.length), 0, element)
  }
}

//-- Item editing
const itemEditor = ref(null)
const costTypeEditor = ref(null)
const assemblyEditor = ref(null)
const itemEditorProps = ref({ ...props })
const editItem = async (type, item) => {
  if (addons.value[viewingAddon.value].livePriceRef) {
    return $store.dispatch('alert', {
      error: true,
      message: 'Live price items cannot be modified'
    })
  }

  // Add to store
  const store = _.titleCase(type)
  const { refId } = await $store.dispatch(`${store}/selectBlank`, {
    object: item,
    type
  })
  editingItem.value = viewingAddon.value
  itemEditorProps.value = { store, type, refId }
  setTimeout(() => itemEditor.value.open())
}

//-- Addon actions
const editingItem = ref(null)
const editCurrentItem = () => {
  editingItem.value = viewingAddon.value
  const type = items.value[editingItem.value].type === 'assembly' ? 'assembly' : 'cost_type'
  editItem(type, items.value[editingItem.value])
}
const refAddonActions = ref(null)
const refGrip = ref(null)
const addonActions = computed(() => {
  return [
    {
      text: 'Edit',
      icon: 'pencil',
      action: () => editCurrentItem()
    },
    {
      text: 'Remove option',
      icon: 'trash',
      action: () => {
        const newItems = [...items.value]
        const newAddons = [...addons.value]
        newItems.splice(viewingAddon.value, 1)
        newAddons.splice(viewingAddon.value, 1)
        addons.value = newAddons
        items.value = newItems
        viewingAddon.value = viewingAddon.value - 1 < 0 ? 1 : viewingAddon.value - 1
      }
    },
    {
      text: 'Duplicate',
      icon: 'copy',
      action: () => {
        duplicateAddon(viewingAddon.value, viewingAddon.value + 1)
      }
    }
  ]
})
const actionsRequested = ref(null)
const handleGripClick = () => {
  actionsRequested.value = viewingAddon.value
  setTimeout(() => refAddonActions.value[0].open())
}
const handleEditorCancel = () => {
  itemEditor.value.close()
  const { store, refId } = itemEditorProps.value
  editingItem.value = null
  $store.dispatch(`${store}/deselect`, { refId })
}
const handleEditorSave = async (changes) => {
  // Grab item, denormalize it
  const index = editingItem.value
  const { store, type, refId } = itemEditorProps.value
  c.throttle(
    async () => {
      viewingAddon.value = index
      const normalized = _.imm($store.state[store].normalized)
      Object.keys(normalized).forEach((ref) => {
        if (ref in changes) {
          normalized[ref] = {
            ...normalized[ref],
            ...changes.allRaw[ref]
          }
        }
      })

      const object = await $store.dispatch(`${store}/denormalize`, {
        rootRef: refId,
        set: normalized
      })

      // All unsaved items get saved at the end, any saved ones get saved now
      if (object[`${object.type === 'assembly' ? 'assembly' : 'cost_type'}_id`]) {
        const idfield = `${object.type === 'assembly' ? 'assembly' : 'cost_type'}_id`
        ;(async () => {
          const { object: updated } = await $store.dispatch('CostItem/partialUpdate', {
            selected: [
              {
                ...object,
                type: object.type === 'assembly' ? 'assembly' : 'cost_type',
                ...(object.company_id ? {} : { [idfield]: null })
              }
            ]
          })
          // Always set the id just incase the save resulted in a create,
          // because it was a global item etc
          if (addons.value[index].id !== updated[idfield]) {
            items.value[index][idfield] = updated[idfield]
            items.value[index].company_id = updated.company_id
            addons.value[index].id = updated[idfield]
            addons.value[index].id = updated[idfield]

            commit()
          }
        })()
      }

      let updates = object
      if (type !== 'assembly') {
        const { object: obj } = await convertFromCostTypeToCostItem(object)
        updates = obj
      }
      items.value[index] = {
        ...updates,
        ...(addons.value[index].embue ?? {})
      }
      addons.value[index] = {
        ...getAddonFromObject({
          addon: addons.value[index],
          auditedObject: items.value[index],
          target: target.value
        }),
        uid: addons.value[index].uid
      }
      reaudit([index])

      editingItem.value = null
      $store.dispatch(`${store}/deselect`, { refId })
      viewingAddon.value = index
    },
    { delay: 400 }
  )
}

defineExpose({
  commitAndClose,
  abort
})
</script>

<template>
  <div class="w-full h-full flex flex-col gap-10">
    <MiniModal
      v-if="editingItem !== null"
      ref="itemEditor"
      :pt="{
        closeButton: { class: '!w-0 !p-0 !m-0 !h-0' }
      }"
      :closeable="false"
      :showCloseButton="false"
      scrollable
    >
      <template #header>
        <div class="flex justify-between items-start w-full">
          <CostItemHeader
            :show-save-button="false"
            v-if="itemEditorProps?.type !== 'assembly'"
            v-bind="itemEditorProps"
          />
          <ChangeTracker v-bind="itemEditorProps">
            <template #default="{ isDirty, changes }">
              <div class="flex justify-start gap-1 items-center">
                <Btn v-if="isDirty" size="large" severity="tertiary" :action="handleEditorCancel">
                  Cancel
                </Btn>
                <Btn
                  v-if="isDirty"
                  size="large"
                  severity="bolster"
                  :action="() => handleEditorSave(changes)"
                >
                  Save changes
                </Btn>
                <Btn v-else size="large" severity="tertiary" :action="handleEditorCancel">
                  Done
                </Btn>
              </div>
            </template>
          </ChangeTracker>
        </div>
      </template>

      <Assembly
        v-bind="itemEditorProps"
        v-if="itemEditorProps.type === 'assembly'"
        ref="assemblyEditor"
      />
      <CostType v-bind="itemEditorProps" v-else ref="costTypeEditor" :show-title="false" />
    </MiniModal>

    <div class="flex justify-center items-center w-full">
      <div class="max-w-[650px]">
        <Automation class="shadow-lg">
          <div class="flex flex-col gap-4">
            <p>
              <span class="font-medium">Supercharge your estimates.</span>
              Bolster contractors who give their customers options, book more of their estimates and
              make more profit on each job!
            </p>
          </div>
        </Automation>
      </div>
    </div>

    <div class="flex flex-col items-center justify-start w-full gap-2">
      <!--      <SelectionToggle-->
      <!--        v-model="viewMode"-->
      <!--        :options="[-->
      <!--          { value: 'preview', text: 'Preview', icon: 'eye' },-->
      <!--          { value: 'table', text: 'List view', icon: 'table' }-->
      <!--        ]"-->
      <!--      />-->
      <StringField
        class="text-2xl font-medium text-center"
        v-model="optionGroupName"
        placeholder="Selection name ie: 'Bathroom Faucet', 'Countertops' or 'Decking' etc"
      />
      <StringField
        multiline
        class="text-lg !min-h-20 text-surface-500 text-center"
        v-model="optionGroupDesc"
        placeholder="Provide an explanation or instructions for your customers (optional)"
      />
    </div>

    <div
      class="flex flex-wrap justify-center items-start gap-x-4 gap-y-10 pt-6"
      ref="cardContainer"
      @drop="handleDrop"
    >
      <template v-for="(addon, index) in addons" :key="index">
        <Drop v-if="viewingAddon === index" :fix-to="dropFixTo" ref="refDrop" :pt="dropPt">
          <template v-if="dropType === 'pic'">
            <FileList v-model="picId" id-list />
          </template>
          <template v-if="dropType === 'qty'">
            <div
              v-if="compatibleMeasures && !sameMeasures && qtyType === 'default'"
              class="mb-4 w-full flex justify-center items-center"
            >
              <Automation :closable="false" class="w-4/5 shadow-lg">
                The quantity is converted to "{{ selectedAddon.unit }}" automatically 😉
              </Automation>
            </div>
            <div class="divide-y divide-surface-300 w-full leading-tight">
              <div class="flex flex-col w-full leading-tight gap-4 py-4">
                <div class="text-center w-full font-medium text-surface-600 leading-tight">
                  How should the quantity be determined?
                </div>
                <div class="text-center flex justify-center items-center w-full font-medium">
                  <Choice
                    v-model="qtyType"
                    :allow-deselect="false"
                    :choices="quantityDerivedOptions"
                    message="How should the quantity be calculated?"
                  />
                </div>
              </div>

              <div
                class="flex flex-col w-full leading-tight gap-4 py-4"
                v-if="qtyType === 'saved' || qtyType === 'manual'"
              >
                <div class="text-center w-full font-medium text-surface-600 leading-tight">
                  Set the quantity or formula
                </div>
                <div class="text-right flex justify-end items-center w-full font-medium">
                  <CalculatorField
                    force-inline
                    show-pocket
                    numeric
                    multi-line
                    :width="350"
                    :height="50"
                    :variables="variables"
                    v-model="selectedEquation"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-if="dropType === 'price'">
            <div class="divide-y divide-surface-300 w-full leading-tight">
              <div class="flex flex-col w-full leading-tight gap-4 py-4">
                <div class="text-center w-full font-medium text-surface-600 leading-tight">
                  How profitable should this option be?
                </div>
                <div
                  class="text-right flex flex-col justify-center items-center w-full font-medium"
                >
                  <div class="flex justify-center items-center mb-2 gap-1">
                    <CalculatorFormatted v-model="profit" format="number" />%
                  </div>
                  <Slider v-model="profit" class="!w-full mb-4" min="0" max="90" />

                  <Btn
                    size="sm"
                    severity="tertiary"
                    @click="profit = null"
                    v-if="selectedAddon.hasOverriddenMarkup"
                  >
                    Use estimate profit (default)
                  </Btn>
                </div>
              </div>

              <div class="flex justify-between items-center w-full leading-tight gap-1 py-2">
                <div
                  class="flex justify-start items-center font-medium text-surface-600 leading-tight text-xl"
                >
                  Costs
                </div>
                <div
                  class="text-right flex justify-end items-center font-medium font-pitch-black text-xl"
                >
                  <div
                    @click="
                      () => {
                        refDrop[0].close()
                        editCurrentItem()
                      }
                    "
                    class="rounded-sm border-2 border-transparent hover:border-blue-print cursor-pointer"
                  >
                    <CalculatorFormatted v-model="costNet" format="$" class="pointer-events-none" />
                  </div>
                </div>
              </div>

              <div class="flex justify-between items-center w-full leading-tight gap-1 py-2">
                <div
                  class="flex justify-start items-center font-medium text-surface-600 leading-tight text-xl"
                >
                  Profit
                </div>
                <div
                  class="text-right flex justify-end items-center font-medium font-pitch-black text-xl"
                >
                  <CalculatorFormatted v-model="profitNet" format="$" />
                </div>
              </div>

              <div class="flex justify-between items-center w-full leading-tight gap-1 py-2">
                <div
                  class="flex justify-start items-center font-medium text-surface-600 leading-tight text-xl"
                >
                  Price
                </div>
                <div
                  class="text-right flex justify-end items-center font-medium font-pitch-black text-xl"
                >
                  <CalculatorFormatted v-model="priceNet" format="$" />
                </div>
              </div>
            </div>
          </template>
        </Drop>

        <div
          @dragover="(event) => handleDragOver(event, index)"
          v-if="
            draggingOver === index &&
            addonDragging !== index &&
            addonDragging !== null &&
            addonDragging > index
          "
          :class="[
            'relative w-[250px] h-[350px] rounded-lg border-dashed border-surface-300 border-2 m-2 flex items-center justify-center text-xl font-medium text-center'
          ]"
        >
          Move here
        </div>
        <div
          @mousedown.capture="
            (event) => {
              handleAddonMouseover(index)
              handleMousedownCapture(event, index)
            }
          "
          @dragover="(event) => handleDragOver(event, index)"
          @dragstart="(event) => handleDragStart(event, index)"
          @dragend="handleDragEnd"
          ref="cards"
          :draggable="!preventDrag"
          :class="[
            'p-2 rounded-lg bg-transparent border-0 relative',
            {
              'bg-surface-200/60': viewingAddon === index,
              'opacity-25': addonDragging === index && draggingOver !== index
            }
          ]"
        >
          <div
            :class="[
              {
                'bg-white/60': viewingAddon !== index,
                'bg-white shadow-lg': viewingAddon === index
              },
              'relative w-[250px] h-[350px]',
              'hover:bg-white rounded-sm hover:shadow-lg transition-all cursor-pointer hover:border-pitch-black border-2 border-surface-300'
            ]"
          >
            <div class="w-full h-full flex flex-col justify-between">
              <div
                v-tooltip="'Click to change add or change the picture...'"
                ref="refPic"
                @click.stop="viewDrop('pic', refPic, index)"
                class="relative basis-2/5 shrink-0 grow-0 overflow-hidden flex justify-center items-end mt-2"
              >
                <img
                  class="object-cover w-full h-full hover:opacity-50 mx-2 rounded-lg"
                  v-if="pics[index]"
                  :src="pics[index]"
                />
                <font-awesome-icon
                  icon="box-open-full"
                  v-else
                  size="4xl"
                  class="text-surface-300 hover:opacity-50"
                  style="font-size: 5em !important"
                />

                <div
                  class="absolute top-0 left-2 right-2 bottom-0 border-2 border-transparent hover:border-blue-print rounded-lg"
                >
                  <div class="absolute inset hover:opacity-100 opacity-0">
                    <font-awesome-icon icon="image" />
                  </div>
                </div>
              </div>

              <div
                class="basis-2/5 grow-1 shrink-0 py-2 px-4 flex flex-col justify-start items-start"
              >
                <div class="font-medium text-medium leading-tight max-w-full">
                  <StringField
                    placeholder="Enter a name"
                    :class="[
                      editableClass,
                      'block !line-clamp-2 focus:line-clamp-none tracking-wide'
                    ]"
                    v-model="addon.name"
                  />
                </div>
                <div class="font-sm leading-tight text-surface-400 max-w-full">
                  <StringField
                    multiline
                    placeholder="Enter a description (optional)"
                    :class="[editableClass, 'block !line-clamp-3 focus:line-clamp-none']"
                    v-model="addon.desc"
                  />
                </div>
              </div>
              <div
                class="basis-6 shrink-0 py-2 px-4 flex justify-between items-end justify-self-end"
              >
                <div
                  ref="refQty"
                  @click="viewDrop('qty', refQty, index)"
                  class="whitespace-nowrap flex justify-start items-center gap-1 rounded-sm px-2 py-1 border-2 border-transparent hover:border-blue-print"
                  v-tooltip="
                    'This is the calculated quantity based on your settings. Click to change...'
                  "
                >
                  <font-awesome-icon
                    :icon="addonQtyTypes[index] === 'manual' ? 'link-slash' : 'link'"
                  />
                  {{ $f[getQtyFormat(addon.unit)](addon.qty) }} {{ addon.unit }}
                  <!--                  <Choose-->
                  <!--                    v-model="addon.unit"-->
                  <!--                    schema="unit_of_measure:unit_of_measure_id"-->
                  <!--                    :filters="{-->
                  <!--                    dimension_measure_type: measureType-->
                  <!--                  }"-->
                  <!--                    :default="addon.unit"-->
                  <!--                  >-->
                  <!--                    <template #default="{ text }">-->
                  <!--                      <span :class="[editableClass]">{{ text }} </span>-->
                  <!--                    </template>-->
                  <!--                  </Choose>-->
                </div>
                <div
                  class="rounded-sm border-2 border-transparent hover:border-blue-print text-right leading-tight px-2 py-1"
                  ref="refPrice"
                  @click="viewDrop('price', refPrice, index)"
                  v-tooltip="
                    'This is the calculated price based on your settings. Click to change...'
                  "
                >
                  <template v-if="Math.abs(deltas[index].priceDelta) < 0.01"> same price </template>
                  <template v-else>
                    {{ deltas[index].priceDelta > 0 ? 'add ' : 'save '
                    }}{{ $f.currency(deltas[index].priceDelta) }}
                  </template>
                </div>
              </div>
            </div>

            <div class="absolute top-0 right-0 p-2">
              <Choose
                v-model="addon.rating"
                :return-array="false"
                placeholder="Add tag (optional)"
                :static-set="[
                  {
                    text: 'None',
                    value: null
                  },
                  {
                    text: 'Good',
                    value: 1
                  },
                  {
                    text: 'Better',
                    value: 2
                  },
                  {
                    text: 'Best',
                    value: 3
                  },
                  {
                    text: 'Recommended',
                    value: 4
                  }
                ]"
              >
                <template #default="{ value, text }">
                  <div
                    v-tooltip="'Add a tag to help your customers make a choice. Click to change...'"
                    :class="[
                      `z-100 mr-1 mt-1 py-0.5 px-1 text-xs font-medium`,
                      editableClass,
                      {
                        'bg-pitch-black text-flame-white': value >= 1,
                        'text-surface-800 border border-surface-400 bg-surface-100/50': value < 1,
                        'text-level-yellow': value > 3
                      }
                    ]"
                  >
                    {{ text }}
                  </div>
                </template>
              </Choose>
            </div>
          </div>

          <template v-if="Math.abs(deltas[index].profitDeltaPerc * 100) >= 1">
            <template v-if="deltas[index].profitDeltaPerc > 0">
              <div
                class="absolute left-[40px] -top-[20px] h-[30px] bg-green-500/40 text-green-800 py-1 px-4 rounded-t text-sm flex justify-center items-center text-center z-100 gap-2"
              >
                <font-awesome-icon icon="arrow-trend-up" />
                +{{ $f.number(deltas[index].profitDeltaPerc * 100, 0) }}% more profit
              </div>
            </template>
            <template v-else>
              <div
                class="absolute left-[40px] -top-[20px] h-[30px] bg-red-500/40 text-red-800 py-1 px-4 rounded-t-lg text-sm flex justify-center items-center text-center z-100 gap-2"
              >
                <font-awesome-icon icon="arrow-trend-down" />
                -{{ $f.number(deltas[index].profitDeltaPerc * 100, 0) }}% less profit
              </div>
            </template>
          </template>

          <div
            ref="refGrip"
            v-if="index === viewingAddon"
            @click="handleGripClick"
            v-tooltip="'Click for options or hold and drag to re-arrange'"
            class="cursor-pointer user-select-none absolute -top-[20px] h-[30px] bg-pitch-black/40 text-white w-[30px] rounded-t text-sm flex justify-center items-center text-center z-100 gap-2"
          >
            <font-awesome-icon icon="fas fa-grip-vertical" />
          </div>
          <BtnBar
            v-if="actionsRequested === index && index === viewingAddon"
            ref="refAddonActions"
            :fix-to="refGrip[0]"
            headless
            :show-button="false"
            :actions="addonActions"
          />
        </div>

        <div
          @dragover="(event) => handleDragOver(event, index)"
          v-if="
            draggingOver === index &&
            addonDragging !== index &&
            addonDragging !== null &&
            addonDragging < index
          "
          :class="[
            'relative w-[250px] h-[350px] rounded-lg border-dashed border-surface-300 border-2 m-2 flex items-center justify-center text-xl font-medium text-center'
          ]"
        >
          Move here
        </div>
      </template>

      <div class="flex justify-center items-center">
        <div class="p-2">
          <div
            @click="chooseNewAddon()"
            class="relative w-[250px] h-[350px] rounded-lg bg-surface-200/60 hover:bg-pitch-black hover:text-level-yellow transition-all cursor-pointer text-surface-600 font-medium text-lg flex flex-col justify-center items-center text-center leading-tight gap-1"
          >
            <font-awesome-icon icon="fas fa-plus" size="large" />
            Add another option
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
