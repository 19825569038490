// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

import { client } from '../../../src/apollo-client/apollo-config'
import {
  getThreeWayApprovalsFilePreSignedPost,
  saveApprovalItemFiles
} from '../../../src/apollo-client/api-calls/approval'

export default {
  type: 'assignee',

  skipAudit: true,

  fields: {
    assignee_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    assignee_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    assignee_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    assignee_fname: {
      type: 'string'
    },
    assignee_lname: {
      type: 'string'
    },
    assignee_object_type: {
      type: 'string'
    },
    assignee_object_id: {
      type: 'string'
    }
  },

  generateVueActions() {
    return {
      async reassign({ dispatch }, { quoteId, assignments }) {
        const response = await dispatch('ajax', {
          path: 'item_assignee/reassign',
          data: assignments
        })
        dispatch(
          'EventNotification/checkForNotifications',
          {
            type: 'schedule',
            quoteId
          },
          { root: true }
        )
        // get the event notifications
        return response
      },
      async notifyOfChanges({ dispatch }, { quoteId, changes }) {
        const response = await dispatch('ajax', {
          path: 'schedule/notifyOfChanges',
          data: changes
        })
        dispatch(
          'EventNotification/checkForNotifications',
          {
            type: 'schedule',
            quoteId
          },
          { root: true }
        )
        // get the event notifications
        return response
      },
      async saveApprovalItemFiles(options, { approvalId, files }) {
        const { data } = await client().mutate({
          mutation: saveApprovalItemFiles,
          variables: {
            input: {
              approvalId,
              files
            }
          }
        })

        return data.saveApprovalItemFiles
      },
      async getThreeWayApprovalsFilePreSignedPost({ dispatch }, { fileType }) {
        try {
          const { data } = await client().mutate({
            mutation: getThreeWayApprovalsFilePreSignedPost,
            variables: {
              input: {
                content_type: fileType
              }
            }
          })

          return JSON.parse(data.getThreeWayApprovalsFilePreSignedPost)
        } catch (err) {
          console.error(err)
          await dispatch('alert', {
            message: 'Failed to get file pre signed post.',
            error: true
          })
        }
      }
    }
  }
}
