<template>
  <div class="flex justify-between w-full py-1 border-t border-cool-gray itemized-costItem--title">
    <!-- cost item name and checkbox -->
    <div class="flex items-center">
      <checkbox
        v-if="!readonly"
        @input="() => eventBus.$emit('itemized-item-select', item)"
        :disabled="isInvoiced"
        disabledMsg="You have already added this item to an invoice"
        :value="isSelected"
      ></checkbox>

      <div class="flex flex-col ml-2">
        <span class="flex itemized-assembly--path">
          <span :key="index" v-for="(path, index) in item.asAssemblyPath">
            <small
              v-if="item.asAssemblyPath.length > 1 && item.asAssemblyPath.length - 1 !== index"
              class="text-cool-gray-600"
            >
              {{ $f.truncate(path, 45) }}
              <font-awesome-icon
                v-show="index !== item.asAssemblyPath.length - 2"
                class="mx-1"
                icon="arrow-right"
              />
            </small>
          </span>
        </span>
        <span>
          {{ $f.truncate(item.item_name || item.cost_item_name || item.cost_type_name, 70) }}
        </span>
      </div>
    </div>

    <!-- cost item price and invoice indicator -->
    <div class="flex">
      <small class="flex mr-5 items-center" v-if="isInvoiced && !readonly">
        <em>Invoiced</em>
      </small>
      <span class="flex items-center">
        {{ $f.currency(item.cost_item_price_net) }}
      </span>
    </div>
  </div>
</template>

<script>
import ItemizedListMixin from './ItemizedListMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'ItemizedListCostItem',
  computed: {
    eventBus() {
      return eventBus
    }
  },
  mixins: [ItemizedListMixin],
  data() {
    return {
      objectLocal: this.item
    }
  },
  methods: {},
  components: {},
  props: {}
}
</script>

<style lang="scss" scoped>
.itemized-assembly--path {
  font-size: 0.85em;
}

.itemized-costItem--title {
  min-height: 50px;
}

em {
  font-style: italic;
}
</style>
