<template>
  <div>
    <div class="flex flex-col" v-if="object">
      <div
        @click="preview"
        class="w-24 h-24 md:w-36 md:h-36 bg-flame-white flex items-center justify-center p-2 rounded-md shadow bg-contain cursor-pointer hover:border-pitch-black hover:border"
        :style="
          isImage(object.file_type) && !fullImage ? `background-image: url(${previewSrc});` : ''
        "
      >
        <img v-if="fullImage && isImage(object.file_type)" :src="previewSrc" />

        <font-awesome-icon
          size="2xl"
          class="pdf-icon"
          v-if="isPdf(object.file_type)"
          :icon="['far', 'file-pdf']"
        />
        <font-awesome-icon
          size="2xl"
          class="csv-icon"
          v-if="isCsv(object.file_type)"
          :icon="['far', 'file-csv']"
        />
        <font-awesome-icon
          size="2xl"
          class="excel-icon"
          v-if="isExcel(object.file_type)"
          :icon="['far', 'file-excel']"
        />
        <font-awesome-icon
          size="2xl"
          class="doc-icon"
          v-if="isDoc(object.file_type)"
          :icon="['far', 'file-word']"
        />
        <font-awesome-icon
          size="2xl"
          class="ppt-icon"
          v-if="isPowerPoint(object.file_type)"
          :icon="['far', 'file-powerpoint']"
        />
      </div>
    </div>
    <LoadingIndicator v-else />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from 'vue'
import { useStore } from 'vuex'
import LoadingIndicator from '../ui/LoadingIndicator.vue'
import {
  isPdf,
  isImage,
  isCsv,
  isExcel,
  isDoc,
  isPowerPoint,
  picUrl,
  viewLink
} from '@/../imports/api/File'

const $store = useStore()

const props = defineProps({
  id: {
    required: true
  },
  fullImage: {
    type: Boolean,
    default: false
  }
})

const object = ref({})

const previewSrc = computed(() => {
  if (object.value.file_is_folder) return false
  if (props.fullImage) return viewLink(object.value.file_id, object.value.file_type)
  return picUrl(object.value.file_id, true)
})

const preview = () => {
  return $store.dispatch('edit', {
    type: 'file',
    id: props.id
  })
}

onMounted(async () => {
  const { object: obj } = await $store.dispatch('File/fetch', {
    id: props.id
  })
  object.value = obj
})
</script>

<style scoped lang="scss">
.pdf-icon {
  color: $deep-red-500;
}
.ppt-icon {
  color: $deep-red-500;
}
.csv-icon {
  color: $matcha-500;
}
.excel-icon {
  color: $matcha-500;
}
.doc-icon {
  color: $blue-print-500;
}
</style>
