// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'notification',

  possibleStatuses: ['n', 'q'],
  
  skipAudit: true,

  fields: {
    activity_count_pins: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    client_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'client',
    },
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote',
    },
    invoice_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'invoice',
    },
    task_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'task',
    },
    file_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'file',
    },
    user_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'user',
    },
    phone_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'phone',
    },
    phone_call_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'phone_call',
    },
    oClient: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'client',
    },
    oQuote: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'quote',
    },
    oInvoice: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'invoice',
    },
    oTask: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'task',
    },
    oFile: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'file',
    },
    oMessage: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'message',
    },
    oUser: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
    },
    oPhone: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'phone',
    },
    oPhoneCall: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'phone',
    },
    notification_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
    },
    notification_owner: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
    },
    owner_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
    },
    oOwner: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
    },
    creator_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'user',
    },
    oCreator: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'user',
    },
    activity_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'activity',
    },
    activity_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    activity_base_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    activity_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    activity_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
    },
    activity_escalation_owner: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
    },
    activity_details: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    activity_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    parent_activity_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    activity_status: {
      type: 'string',
      filter: false,
      format: 'status',
      mapTo: false,
    },
    activity_notification_heading: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    activity_object_type: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    activity_object_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    notification_desc: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
    },
    notification_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    notification_is_seen: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    notification_object_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    notification_object_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    notification_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
    },
    notification_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false,
    },
    oObject: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: false,
    },
  },

  generateVueActions() {
    return {
      async get({ dispatch, rootState }) {
        const { set } = await dispatch('search', {
          filters: {
            notification_owner: `user-${rootState.session.user.user_id}`,
          },
          limit: 10,
          offset: 0,
        });
        
        return set;
      },
      async check({ dispatch, rootState, state }) {
        const searchPayload = await dispatch('search', {
          filters: {
            notification_owner: `user-${rootState.session.user.user_id}`,
            notification_id: `>${Object.values(state.all)
              .reduce((acc, n) => (acc > n.notification_id ? acc : n.notification_id), 0)}`,
          },
          limit: 10,
          offset: 0,
        });
        
        if (searchPayload.set.length) {
          dispatch('updateCounts', {}, { root: true });
        }
        
        return searchPayload;
      },
      async more({ dispatch, rootState, state }) {
        const { set } = await dispatch('search', {
          filters: {
            notification_owner: `user-${rootState.session.user.user_id}`,
          },
          limit: 10,
          offset: Object.keys(state.all).length,
        });
        
        return set;
      },
      async markAllSeen({ state, commit, dispatch }) {
        await dispatch('ajax', {
          path: 'notification/markAllSeen',
        });

        return dispatch('updateCounts', { delay: 0 }, { root: true });
      },
    };
  },
};
