<template>
  <div>
    <div
      class="client-details--container"
      :class="{ sidebar: sidebar, 'sidebar-closed': !showFull }"
    >
      <drop ref="drop" position="bottom left" targetAttachment="bottom left" offset="0px -10px">
        <template #button>
          <div
            v-if="showFull"
            class="client-name-link"
            :class="{ sidebar: sidebar }"
            v-tooltip.bottom="{
              value: 'Click for client details'
            }"
          >
            <font-awesome-icon icon="user" class="mr-2" fixed-width />
            <span :style="{ 'font-size': sidebar ? '1.1em' : '1em' }">{{ userName }}</span>
          </div>
          <div
            v-else
            class="client-name-link closed"
            :class="{ sidebar: sidebar }"
            v-tooltip="{
              value: 'Click for client details'
            }"
          >
            <font-awesome-icon icon="user" size="xl" fixed-width />
          </div>
        </template>

        <div style="margin-bottom: 0.5em">
          <span style="font-weight: 500">Client Details</span>
        </div>

        <btn-group class="client-button-group" :class="{ contextual: contextual }">
          <btn
            rounded
            class="more px-0 p-2 info"
            :action="() => $store.dispatch('Client/call', { id: client.client_id })"
          >
            <font-awesome-icon :icon="['fas', 'phone']" />
          </btn>
          <btn
            rounded
            class="more px-0 p-2 info ml-2"
            :action="() => $store.dispatch('Client/message', { id: client.client_id })"
          >
            <font-awesome-icon :icon="['fas', 'comment']" />
          </btn>
          <btn
            rounded
            class="more px-0 p-2 info ml-2"
            :action="() => $store.dispatch('Client/map', { id: client.client_id })"
          >
            <font-awesome-icon icon="map-location-dot" />
          </btn>
          <btn rounded v-if="!contextual" class="more px-0 p-2 info ml-2" @click="edit">
            <font-awesome-icon icon="rectangle-history-circle-plus" />
          </btn>
        </btn-group>
        <div class="client-details-spacer"></div>
        <div
          v-if="client.user_phone"
          @click="() => $store.dispatch('copyToClipboard', client.user_phone)"
        >
          <p class="client-info-title">Phone</p>
          <p class="client-info-data">{{ $f.phone(client.user_phone, client.country_abbr) }}</p>
        </div>
        <div
          class="client-data-group"
          v-if="client.user_email"
          @click="() => $store.dispatch('copyToClipboard', client.user_email)"
        >
          <p class="client-info-title">Email</p>
          <p class="client-info-data">{{ $f.truncate(userEmail[0], 24) }}</p>
          <p
            v-if="userEmail.length > 1"
            class="client-info-data"
            style="margin-top: -0.2em; font-size: 0.8em"
          >
            {{ $f.truncate(`@${userEmail[1]}`, 30) }}
          </p>
        </div>
        <div
          class="client-data-group"
          @click="
            () =>
              $store.dispatch(
                'copyToClipboard',
                `${client.user_address || ''}, ${client.user_city || ''}, ${client.user_prov || ''}`
              )
          "
        >
          <p class="client-info-title">Address</p>
          <p v-if="client.user_suite" class="client-info-data">
            {{ $f.truncate(client.user_suite, 24) }}
          </p>
          <p v-if="client.user_address" class="client-info-data">
            {{ $f.truncate(client.user_address, 24) }}
          </p>
          <p v-if="client.user_city || client.user_prov" class="client-info-data">
            {{ client.user_city }} {{ client.user_prov }}
          </p>
          <p v-if="client.user_postal" class="client-info-data">{{ client.user_postal }}</p>
        </div>
      </drop>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientDetails',
  computed: {
    showFull() {
      if (this.sidebar) {
        return this.$parent.$parent.sidebarOpen
      }
      return true
    },
    userName() {
      if (!this.client.user_fname && !this.client.user_lname) return 'No Name'
      if (!this.client.user_fname) return `${this.client.user_lname.substring(0, this.truncate)}...`
      if (!this.client.user_lname) return `${this.client.user_fname.substring(0, this.truncate)}...`
      if (this.client.user_fname.length + this.client.user_lname.length + 1 < this.truncate) {
        return `${this.client.user_fname} ${this.client.user_lname}`
      }
      if (this.client.user_lname.length < this.truncate - 2) {
        return `${this.client.user_fname[0]}. ${this.client.user_lname}`
      }
      return `${this.client.user_fname[0]}. ${this.client.user_lname[0]}.`
    },
    userEmail() {
      if (this.client.user_email) {
        return this.client.user_email.split('@')
      }
      return ['']
    }
  },
  methods: {
    async edit() {
      return this.$store.dispatch('edit', {
        type: this.client.type,
        id: this.client[`${this.client.type}_id`]
      })
    }
  },
  props: {
    client: {
      type: Object,
      default() {
        return {}
      }
    },
    contextual: {
      type: Boolean,
      default: true
    },
    deselectOnDestroy: {
      default: false
    },
    editing: {
      default: false
    },
    // Basic version is fine
    forceFull: {
      required: false,
      type: Boolean,
      default: false
    },
    sidebar: {
      type: Boolean,
      default: true
    },
    truncate: {
      type: Number,
      default: 18
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.client-details--container {
  width: auto;
  height: auto;
  color: $cool-gray-700;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;

  &.sidebar {
    border-bottom: 1px solid $cool-gray-300;
    border-radius: 0;
  }

  .client-name-link {
    height: 100%;
    width: 100%;
    padding: 0.5em 0.5em;
    cursor: pointer;
    text-align: start;
    &.sidebar {
      padding: 1.28em 1.1em;
      width: 213.8px;
    }

    &:hover {
      color: #1f92fc !important; //bootstrap info
    }

    &.closed {
      margin-left: 5px;
      color: $cool-gray-600;
      width: 100%;
    }
  }

  .client-details-suggestion-button {
    color: white;
    margin-top: 0.5em;
  }
}

.client-next-steps-container {
  width: auto;
  height: auto;
  text-align: center;
  padding: 0.5em 0.5em;
  background-color: $flame-white;
  border-bottom: 1px solid $cool-gray-300;
}

.client-details-name {
  font-size: 1.2em;
}

.client-button-group {
  margin-left: 12px;
  &.contextual {
    margin-left: 32px;
  }
}

.client-details-spacer {
  width: 193px;
  margin-bottom: 0.5em;
  border-bottom: 2px solid $cool-gray-300;
}

.client-data-group {
  &:hover {
    color: #1f92fc !important; //bootstrap info
    cursor: pointer;
  }
}

.client-info-title {
  font-size: 0.7em;
  margin-top: 0.5em;
  color: $cool-gray-600;
}

.client-info-data {
  &.email {
    margin-top: -0.2em;
    font-size: 0.8em;
    color: $cool-gray-700;
  }
  &:hover {
    color: #1f92fc !important; //bootstrap info
    cursor: pointer;
  }
}
</style>
