<template>
  <div>
    <!-- Loop through each segment and check if it's an icon or text -->
    <template v-for="(segment, index) in parsedText" :key="index">
      <template v-if="segment.type === 'icon'">
        <Icon :icon="segment.content" />
      </template>
      <template v-else>
        <component :is="{ template: `${segment.content}` }" v-if="html" />
        <template v-else>{{ segment.content }}</template>
      </template>
    </template>
  </div>
</template>

<script setup>
import { createApp } from 'vue/dist/vue.esm-bundler'
import { computed } from 'vue'

// Props
const props = defineProps({
  text: String,
  html: Boolean
})

// Parse the text to identify icons and text segments
const parsedText = computed(() => {
  const regex = /(\[([a-zA-Z0-9- ]+)\])|([^[]+)/g // Matches text inside brackets and outside
  const segments = []
  let match

  while ((match = regex.exec(props.text)) !== null) {
    if (match[2]) {
      // It's an icon
      segments.push({ type: 'icon', content: match[2] })
    } else if (match[3]) {
      // It's regular text
      segments.push({ type: 'text', content: match[3] })
    }
  }

  return segments
})
</script>
