import { toRefs, reactive, computed } from 'vue'
import { useStore } from 'vuex'

export const initialState = {
  loading: 0,
  searchModal: null,
  searchInput: null,
  query: '',
  lastQueryLength: 0,
  count: 0,
  limit: 80,
  offset: 0,
  filters: {},
  set: [],
  endOfSet: false,
  grouped: {},
  type: '',
  title: '',
  searchTypes: {
    client: {
      icon: 'house-user'
    },
    invoice: {
      icon: 'file-invoice'
    },
    quote: {
      icon: 'calculator'
    },
    assembly: {
      icon: 'boxes-stacked'
    },
    file: {
      icon: 'file'
    }
  },
  filterTypes: []
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const $store = useStore()

  // simple array of all the types/entities we want to search
  const types = computed(() => {
    return Object.keys(context.searchTypes)
  })

  // is there a query set
  const isQuery = computed(() => context.query && context.query.length > 0)

  // options to toggle filters on or off
  const filterOptions = computed(() =>
    types.value.map((type) => ({
      text: _.capitalize(type),
      value: type
    }))
  )

  // handle the search query changing
  const searchPhraseChange = (val) => {
    context.count = 0
    context.query = val
  }

  // close the search modal
  const close = () => context.searchModal.close()

  // fetch the search results
  const fetch = async (params) => {
    const { filters: pFilters = null, multiple = false } = params || {}
    const searchPhrase = context.query || ''
    const limit = context.limit
    const offset = context.offset
    const filters = {
      ...(pFilters || context.filters),
      company_id: $store.state.session.scope.company
    }
    const typesToSearch = context.filterTypes || types.value
    context.endOfSet = false
    context.loading = 1

    const { set, total } = await $store.dispatch('search/search', {
      searchPhrase,
      types: typesToSearch,
      limit,
      offset,
      filters,
      multiple
    })
    context.endOfSet = set.length < limit || limit === 0
    context.set = set
    context.total = total
    const grouped = typesToSearch.reduce((acc, type) => {
      const items = set.filter((item) => item.type === type)
      acc[type] = [...items]
      return acc
    }, {})
    context.grouped = grouped
    context.loading = 0
    return grouped
  }

  // open the search modal
  const open = () => {
    context.searchModal.open()
    setTimeout(() => {
      context.searchInput.$el.focus()
    }, 800)
  }

  // fetch more results
  const fetchMore = () => {
    if (context.offset && !context.endOfSet) fetch()
  }

  // clear the search
  const clearSearch = () => (context.query = '')

  return {
    ...toRefs(context),
    searchPhraseChange,
    clearSearch,
    fetchMore,
    fetch,
    open,
    close,
    types,
    isQuery,
    filterOptions
  }
}
