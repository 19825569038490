<template>
  <div
    class="calculator--container input-container"
    @click.stop.prevent="() => $emit('click')"
    v-if="Object.keys(dimTypes).length"
  >
    <div class="flex flex-col" style="width: 100%" v-show="!dropRequested">
      <content-editable
        ref="input"
        class="calculator--field"
        :variables="styledVariables"
        v-model="calculatorValue"
        @click="() => $emit('click')"
        @focusin="focusinHandlerLocal"
        @focusout="focusoutHandlerLocal"
        @keydown="keypressHandler"
      />
      <span class="calculator--variables" v-if="Object.keys(variablesAvailable).length">
        <span class="calculator--section-title">VARIABLES</span>

        <a
          v-for="(val, abbr) in visibleStyledVariables"
          class="variable info"
          @click="addVariable(abbr)"
          v-tooltip="val.name || false"
          :style="val.style"
          :key="abbr"
        >
          {{ abbr }}
          <small v-if="val.value || (val && typeof val !== 'object')"
            >{{ $f.number((+val.value || val).toFixed(1)) }}
            {{ val.measureType === 'count' ? val.measureType : val.measure }}</small
          >
          <small v-else>-</small>
        </a>

        <a
          class="variable info"
          :data-ref="`calc-dim-drop-${uid}`"
          @click="dropRequested = true"
          v-tooltip="'Select from other available dimensions..'"
        >
          Other
          <font-awesome-icon icon="window-maximize" />
        </a>
      </span>

      <span class="calculator--value">
        <div class="calculator--buttons">
          <Btn rounded class="more px-0 p-2 default" @click="add(' + ')"
            ><font-awesome-icon icon="plus"
          /></Btn>
          <Btn rounded class="more px-0 p-2 default" @click="add(' - ')"
            ><font-awesome-icon icon="minus"
          /></Btn>
          <Btn rounded class="more px-0 p-2 default" @click="add(' x ')"
            ><font-awesome-icon icon="remove"
          /></Btn>
          <Btn rounded class="more px-0 p-2 default" @click="add(' / ')"
            ><font-awesome-icon icon="divide"
          /></Btn>
        </div>
        <!--      em {{startedTypingSinceEmit}} foc {{startedTypingSinceFocus}}-->
        = {{ $f.number(rawValue) }}
      </span>
    </div>
    <div class="dimension-container" v-if="dropRequested">
      <dimension-selector
        ref="dimSelector"
        :value="null"
        :object="{}"
        :measureType="null"
        @input="dimSelectorHandler"
        :parentDimensions="dimTypes"
      >
        <template #header>
          <Btn class="dark round sm" @click="dropRequested = false"> Cancel </Btn>
        </template>
      </dimension-selector>
    </div>
  </div>
</template>

<script>
import DimensionSelector from '@/components/quote/item/DimensionSelector.vue'
import ContentEditable from './ContentEditableProcessed.vue'
import Field from '../mixins/Field'
import eventBus from '../../eventBus'

export default {
  name: 'Calculator',

  mixins: [Field],
  emits: ['click', 'focusin', 'focusout'],

  data() {
    return {
      dropRequested: false,
      focusBlocked: false,
      dimTypes: {}
    }
  },

  props: {
    getDimOnOpen: {
      default: false
    }
  },

  watch: {
    measure() {
      if (!this.getDimOnOpen) {
        this.getPossibleDimensions()
      }
    },
    dimensions() {
      if (!this.getDimOnOpen) {
        this.getPossibleDimensions()
      }
    },
    variables() {
      if (!this.getDimOnOpen) {
        this.getPossibleDimensions()
      }
    },
    async dropRequested(b) {
      if (b) {
        await this.$nextTick()
        if (this.$refs.dimensionSelector) this.$refs.dimensionSelector.open()
      }
    }
  },

  methods: {
    async dimSelectorHandler(abbr) {
      // this.dropRequested = false;
      await this.$nextTick()
      this.addVariable(abbr)
    },
    async getPossibleDimensions() {
      const vars = _.imm(this.variablesAvailable)
      const all = await this.$store.dispatch('Dimension/getPossibleDimensions', { full: true })

      ;[...Object.keys(all), ...Object.keys(vars)].forEach((abbr) => {
        all[abbr] = {
          ...(all[abbr] || {}),
          ...(vars[abbr] || {})
        }
      })

      this.dimTypes = all
      return all
    },
    focusinHandlerLocal(...args) {
      if (this.focusBlocked) return false

      return this.focusinHandler(...args)
    },
    async focusoutHandlerLocal(...args) {
      await c.throttle(() => {}, { delay: 200 })
      if (this.focusBlocked) return false

      return this.focusoutHandler(...args)
    },
    blockFocusEvents() {
      this.focusBlocked = true
    },
    unblockFocusEvents() {
      this.focusBlocked = false
    },
    async openDimSelector() {
      this.dropRequested = true
      await this.$nextTick()
      this.$refs.dimDrop.open()
    },
    getEquation() {
      return this.calculatorValue
    },

    async focusinHandler() {
      if (this.focusBlocked) return
      this.focused = true

      this.$emit('focusin')
    },

    async focusoutHandler() {
      await c.throttle(() => {}, { delay: 200 })
      if (this.focusBlocked) return
      // Important: must wait 100ms
      await c.throttle(() => {}, { key: `${this.uid}wait`, delay: 50 })

      this.focused = false
      this.startedTypingSinceFocus = false

      await this.$nextTick()

      if (this.$refs.input) {
        const contentEditableValue = (this.$refs.input && this.$refs.input.valueLocal) || ''
        this.setTypedValue(contentEditableValue, false)
        this.emitValue()
        this.emitEquation()

        await this.$nextTick()

        this.startedTypingSinceFocus = false
        this.startedTypingSinceEmit = false
        this.focused = false

        this.$emit('focusout')
      }
    },

    async add(val) {
      this.blockFocusEvents()
      if (this.$refs.input.hasSelection()) this.$refs.input.setCursorAtEnd()
      await this.$nextTick()
      this.$refs.input.insert(val)
      await c.throttle(() => {}, { delay: 200 })
      this.unblockFocusEvents()
    },

    async addVariable(abbr) {
      this.add(` ${abbr} `)
    }
  },

  computed: {
    shouldAutoSelectOnFocus() {
      return false
    },

    variablesIncluded() {
      const availableKeys = Object.keys(this.variablesAvailable || {})
      const regx = new RegExp(`\\b(${availableKeys.join('|')})\\b`, 'g')

      const variablesUsed = String(this.calculatorValue || '').match(regx) || []

      return _.uniq([
        ...variablesUsed,
        ...availableKeys.filter(
          (abbr) =>
            (this.variablesAvailable[abbr] &&
              typeof this.variablesAvailable[abbr] === 'object' &&
              this.variablesAvailable[abbr].value) ||
            abbr in this.variables
        )
      ])
    },

    styledVariables() {
      const vars = _.imm(this.dimTypes)

      const keys = [...Object.keys(vars), ...Object.keys(this.variables || {})]

      return keys.reduce(
        (acc, abbr) => ({
          ...acc,
          [abbr]: {
            ...(vars[abbr] || {}),
            value:
              (vars[abbr] && vars[abbr].value) ||
              (this.variables[abbr] && this.variables[abbr].value) ||
              this.variables[abbr],
            style: `background-color: #${(vars[abbr] && vars[abbr].color) || 'b299ff'} !important;`
          }
        }),
        {}
      )
    },
    visibleStyledVariables() {
      return this.styledVariables.filter((_, abbr) => this.variablesIncluded.includes(abbr))
    },

    isNumericField() {
      return true
    },

    fieldType() {
      return 'calculator'
    },

    calculatorValue: {
      get() {
        return this.typedValue
      },
      set(equation) {
        this.setTypedValue(equation)
      }
    }
  },

  mounted() {
    this.hasFocused = true
  },

  created() {
    if (!this.getDimOnOpen) {
      this.getPossibleDimensions()
    }
    eventBus.$on('saved-dimension', this.getPossibleDimensions)
  },
  beforeUnmount() {
    eventBus.$off('saved-dimension', this.getPossibleDimensions)
  },
  components: {
    DimensionSelector,
    ContentEditable
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.calculator--drop.drop {
  width: 20em;
  max-width: 100vw;

  .dimension-list--wrapper {
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin: 0.5em 0em;
    border-radius: 0.5em;
    background: $cool-gray-300;
    .dimension-list--container {
      min-width: 25em;
    }
  }
  .drop-content {
    background: #222 !important;
    padding: 0px !important;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.variables {
      padding: 0.5em;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 100%;
      font-size: 0.8em;
      .button--container {
        margin: 0.15em 0.15em;
      }
      .var-amount {
        font-size: 0.7em;
        margin-left: 0.1em;
      }
    }
  }

  &.drop-element-attached-top.drop-element-attached-center,
  &.drop-element-attached-top.drop-element-attached-left,
  &.drop-element-attached-top.drop-element-attached-right {
    .drop-content {
      &:before {
        border-top-color: transparent !important;
        border-bottom-color: #222 !important;
      }
    }
  }

  &.drop-element-attached-bottom.drop-element-attached-center,
  &.drop-element-attached-bottom.drop-element-attached-left,
  &.drop-element-attached-bottom.drop-element-attached-right {
    .drop-content {
      &:before {
        border-top-color: $cool-gray-600 !important;
        border-bottom-color: transparent !important;
      }
    }
  }
}

.calculator--container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 20em;
  overflow: hidden;
  height: fit-content;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  max-width: 30em;
  .dimension-container {
    background: $cool-gray-200;
    border-radius: 0.25em;
    padding: 0.25em;
  }

  .calculator--section-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.6em;
    line-height: 1;
    position: absolute;
    top: 0.5em;
    color: $cool-gray-600;
  }

  .calculator--variables {
    background: $cool-gray-800;
    display: flex;
    padding: 0.5em 1em;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    padding-top: 1em;

    .variable-icon {
      color: $blue-print-400 !important;
      margin-right: 0.25em;
    }

    a.variable {
      display: inline-flex;
      white-space: nowrap;
      height: 2.5em;
      justify-content: center !important;
      align-items: center !important;
      line-height: 1;

      font-weight: bold;
      border-radius: 5px;
      padding: 0 0.5em;
      margin: 0.15em 0.15em;

      flex-direction: column;
      small {
        font-size: 0.5em;
        font-weight: bold;
        margin-top: 0.15em;
        color: $cool-gray-600 !important;
      }

      background: $cool-gray-400 !important;
      color: $cool-gray-700 !important;
      &.info {
        background: $cool-gray-600 !important;
        color: $cool-gray-700 !important;

        small {
          color: $cool-gray-600 !important;
        }
      }

      &.default {
        opacity: 0.4;
      }
    }
  }

  .calculator--value {
    width: 100%;
    min-width: 100%;
    height: 3em;
    border-bottom-right-radius: 0.15em;
    border-bottom-left-radius: 0.15em;
    background: $cool-gray-600;
    color: $pitch-black;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1em;
    font-size: 1.2em;
    line-height: 1.1;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .calculator--buttons {
    padding: 0 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    > * {
      margin-right: 0.5em;
    }
  }

  .calculator--field {
    text-align: left;
    width: 100%;
    min-width: 100%;
    height: 15em;
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // @extend %scrollbar;
    font-weight: normal;
    box-sizing: border-box;
    font-size: 18px;
    background: $flame-white;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin: 0px;
    padding: 10px 15px !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: none !important;
    white-space: pre-wrap !important;

    &:focus {
      outline: none;
    }

    modifier {
      color: #360268;
    }
    parenthesis {
      color: #cc7833;
    }
    time {
      white-space: nowrap;

      color: darken($green-400, 5%);

      background: rgba($green-400, 0.5);
      border-radius: 5px;
      padding: 0 0.25em 0 0.15em;

      number {
        color: darken($green-400, 10%);
      }
      comments {
        color: darken($green-400, 10%);
      }
    }
    imperial {
      white-space: nowrap;

      color: darken($warning, 35%);

      background: lighten($warning, 15%);
      border-radius: 5px;
      padding: 0 0.25em 0 0.2em;

      number {
        white-space: nowrap;
        color: darken($warning, 45%);
      }
      comments {
        color: darken($warning, 35%);
      }
    }
    variable {
      white-space: nowrap;
      //background: lighten($blue-print-700, 10%);
      color: $purple-500;
      font-weight: 500;
    }
    number {
      color: #0b4bff;
    }
    comments {
      color: #797979;
    }
    newline {
      color: #a5c261;
    }
    div {
      display: inline;
    }
  }

  &.inline,
  &.field {
    overflow: hidden;
    max-width: 100%;
    min-width: unset;
    border: none !important;

    .calculator--variables {
      font-size: 0.9em;
      .calculator--section-title {
        margin-bottom: 1em;
      }
    }

    .calculator--field {
      min-height: 3em;
      height: fit-content;
      word-wrap: break-spaces;
      white-space: pre-wrap;
    }
    .calculator--value {
      font-size: 0.9em;
    }
  }
}
</style>
