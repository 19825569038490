<script setup>
import { computed, useAttrs, ref, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import eventBus from '@/eventBus.js'

const attrs = useAttrs()
const $this = getCurrentInstance().proxy

const listeners = computed(() => {
  const allListeners = {}

  Object.keys(attrs).forEach((key) => {
    if (key.startsWith('on')) {
      const eventName = key.slice(2).toLowerCase()
      allListeners[eventName] = (...args) => {
        if (attrs[key]) {
          attrs[key](...args)
        }
      }
    }
  })

  allListeners.input = (...args) => {
    $this.$emit('input', ...args)
  }

  return allListeners
})

const refFileList = ref(null)
const uploadFromEvent = (event) => refFileList.value.$refs.upload.drop(event, true)

const fileDropHandler = (e) => {
  const originalEvent = e.originalEvent
  const { col = null, row = null } = e.element?.position ?? {}

  if (!originalEvent || col === null || row === null) return

  uploadFromEvent(originalEvent)
}

onMounted(() => {
  eventBus.$on('sheetFilesDrop', fileDropHandler)
})
onBeforeUnmount(() => {
  eventBus.$off('sheetFilesDrop', fileDropHandler)
})
</script>

<template>
  <FileList v-bind="attrs" v-on="listeners" ref="refFileList" />
</template>

<style scoped lang="scss"></style>
