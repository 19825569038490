import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue'

export default {
  useCellPositions(args) {
    const {
      props,
      dataSheets,

      freezeCol,
      collapsedColumns,

      rowsMap,

      getColDef,
      getRowSheet,
      getSheet,
      isRowFirstOfGroup,
      isRowFirstOfSheet,
      isRowLastOfSheet,

      collapsedSuperHeaders,
      superHeadersHidden,
      isRowLastOfGroup,
      canvasHeight,
      canvasWidth,

      scrollContainer,

      colCount,
      rowCount,

      verticalScrollbar,
      verticalThumb,

      horizontalScrollbar,
      horizontalThumb,
      getSuperHeaders,
      getRowFromId,
      emit
    } = args

    const leftCol = ref(0)
    const topRow = ref(0)

    const scrolling = ref(false)

    const thumbTop = ref(0)
    const dragStartY = ref(0)
    const dragStartX = ref(0)
    const thumbLeft = ref(0)

    const mouseDown = ref(false)
    const isDraggingY = ref(false)
    const isDraggingX = ref(false)

    const gutterWidth = 30

    const topSheet = computed(() => getRowSheet(topRow.value || 0) || 0)

    const collapseGroups = computed(() => {
      let cg = {}
      for (let i = 0; i < dataSheets.value.length; i += 1) {
        cg = {
          ...cg,
          ...((dataSheets.value[i].collapseGroups || {}).groups || {})
        }
      }

      return cg
    })

    const collapsedGroups = ref([])
    const collapsedGroupRows = computed(() => {
      const grps = [...collapsedGroups.value]
      let rows = []
      for (let i = 0; i < grps.length; i += 1) {
        if (!(grps[i] in collapseGroups.value)) continue
        const grp = collapseGroups.value[grps[i]]
        rows = [
          ...rows,
          // all except the first one which is the assembly itself
          ...grp.ids.slice(1).map((id) => getRowFromId(id))
        ]
      }
      return rows
    })

    const maxDepth = computed(() => {
      const cgs = collapseGroups.value
      return Object.values(cgs).reduce((acc, group) => Math.max(acc, group.level + 1), 0)
    })
    const leftPadding = gutterWidth
    const rightPadding = gutterWidth
    const totalGutterWidth = computed(
      () => leftPadding + maxDepth.value * gutterWidth + rightPadding
    )

    const rowHeadingWidth = computed(() => (props.showRowHeadings ? 43 : 0))

    const superHeadingHeight = computed(() => 25)

    const sheetHeadingHeight = computed(() => 50)

    const columnHeadingHeight = computed(() => (props.showColumnHeadings ? 25 : 0))

    const fullHeadingHeight = computed(
      () => sheetHeadingHeight.value + superHeadingHeight.value + columnHeadingHeight.value
    )

    const groupSpacing = computed(() => props.defaultGroupSpacing)

    const sheetSpacing = computed(() =>
      dataSheets.value.length > 1 ? columnHeadingHeight.value * 2 : 0
    )

    const getTitleColSpan = (col, sheet = topSheet.value) => {
      const colDef = getColDef(col, sheet)

      if (colDef.titleColSpan) {
        return colDef.titleColSpan
      }

      return 1
    }

    const isRowOnTop = (row) => topRow.value === row

    const getColWidth = (col = null, sheet) => {
      if (col === null) return props.defaultCellWidth
      if (col < 0) return rowHeadingWidth.value + totalGutterWidth.value
      if (collapsedColumns.value[sheet] && collapsedColumns.value[sheet].includes(col)) {
        return 0
      }
      const colDef = getColDef(col, sheet)

      const colWidths = (dataSheets.value[sheet] && dataSheets.value[sheet].colWidths) || {}
      return Math.max(
        colDef.formatting?.minWidth ?? 40,
        colWidths[col] || // custom set
          (colDef.formatting && colDef.formatting.width) || // prop provided
          props.defaultCellWidth
      ) // default
    }

    const resetColWidths = () =>
      dataSheets.value.map((sh, i) => {
        dataSheets.value[i].colWidths = {}
      })

    const rowHeights = ref({})

    const getRowHeight = (row = null) => {
      if (row === null) return props.defaultCellHeight
      if (row < 0) return fullHeadingHeight.value
      if (collapsedGroupRows.value.includes(row)) return 0
      const id = rowsMap.value[row]?.id
      return (
        rowHeights.value[id] ?? // custom set
        props.defaultCellHeight
      ) // default
    }

    const sheetWidths = computed(() =>
      dataSheets.value.reduce(
        (acc, sheet, index) => ({
          ...acc,
          [index]: (sheet.columns || []).reduce(
            (width, col, colIndex) => width + getColWidth(colIndex, index),
            0
          )
        }),
        {}
      )
    )

    const maxSheetWidth = computed(
      () => Object.values(sheetWidths.value).reduce((max, w) => Math.max(max, w), 0) + 20
    )

    const getGroupTotalsHeight = (row) => {
      const sheet = getSheet(getRowSheet(row))
      return !sheet.group || sheet.group.showTotals ? getRowHeight() : 0
    }

    const getGroupHeadingHeight = (row) => {
      const sheet = getSheet(getRowSheet(row))
      return !sheet.group || sheet.group.showHeadings ? getRowHeight() : 0
    }

    const getRowGroupSpacing = (row) => {
      if (
        isRowFirstOfGroup(row) &&
        !isRowOnTop(row) &&
        // Not if is first row in set
        !isRowFirstOfSheet(row)
      ) {
        return groupSpacing.value
      }

      return 0
    }

    const getRowSheetSpacing = (row) => {
      // ONly if its not at the top
      if (
        !isRowOnTop(row) &&
        // Only if its the first row in a sheet other than the first sheet
        isRowFirstOfSheet(row) &&
        // Unless its the first sheet which gets no spacing
        getRowSheet(row) > 0
      ) {
        return sheetSpacing.value
      }

      return 0
    }

    const getRowSheetHeadingHeight = (row) => {
      const spacing = getRowSheetSpacing(row)
      if (!spacing) return 0

      return sheetHeadingHeight.value
    }

    const renderVerticalPositions = computed(() => {
      const rows = [...rowsMap.value]

      // row positions
      const rp = {}
      // group positions
      const gp = {}
      // sheet positions
      const sp = {
        0: {
          firstRow: 0,
          y: 0,
          x: 0,
          sheetHeading: {
            y: 0,
            x: 0,
            height: sheetHeadingHeight.value
          },
          superHeadings: {
            y: sheetHeadingHeight.value,
            x: 0,
            height: superHeadingHeight.value
          },
          colHeadings: {
            y: sheetHeadingHeight.value + superHeadingHeight.value,
            x: 0,
            height: columnHeadingHeight.value
          }
        }
      }

      let y = fullHeadingHeight.value

      for (let row = 0; row < rows.length; row += 1) {
        const firstOfSheet = isRowFirstOfSheet(row)
        const lastOfSheet = isRowLastOfSheet(row)
        const sheetIndex = getRowSheet(row)
        if (firstOfSheet && sheetIndex > 0) {
          const spacing = sheetSpacing.value
          const headingHeight = sheetHeadingHeight.value
          y += spacing
          sp[sheetIndex] = {
            firstRow: row,
            y,
            x: 0,
            sheetHeading: {
              y,
              x: 0,
              height: headingHeight
            },
            superHeadings: {
              y: y + headingHeight,
              x: 0,
              height: superHeadingHeight.value
            },
            colHeadings: {
              y: y + headingHeight + superHeadingHeight.value,
              x: 0,
              height: columnHeadingHeight.value
            }
          }
          y += headingHeight
          y += superHeadingHeight.value
          y += columnHeadingHeight.value
        }

        const sheet = getSheet(sheetIndex)
        const isGrouping = (sheet.grouping || []).length
        const firstOfGroup = isGrouping && isRowFirstOfGroup(row)
        const lastOfGroup = isGrouping && isRowLastOfGroup(row)
        if (firstOfGroup) {
          const spacing = getRowGroupSpacing(row)
          const headingHeight = getGroupHeadingHeight(row)
          y += spacing
          gp[rows[row].group] = {
            sheetIndex,
            groupId: rows[row].group,
            firstRow: row,
            lastRow: null,
            y,
            heading: {
              y,
              height: headingHeight
            },
            totals: {}
          }
          y += headingHeight
        }

        const height = getRowHeight(row)
        rp[row] = {
          y,
          height,
          cumulativeHeight: y + height
        }
        y += height
        sp[sheetIndex].lastRow = row

        if (lastOfGroup) {
          const totalsHeight = getGroupTotalsHeight(row)
          gp[rows[row].group] = {
            ...gp[rows[row].group],
            height: y + height - gp[rows[row].group].y,
            lastRow: row,
            totals: {
              y,
              height: totalsHeight
            }
          }
          y += totalsHeight
        }

        if (lastOfSheet) {
          sp[sheetIndex].height = y + height - sp[sheetIndex].y
        }
      }

      return {
        groupPositions: gp,
        sheetPositions: sp,
        rowPositions: rp
      }
    })

    const rowPositions = computed(() => renderVerticalPositions.value.rowPositions)
    const groupPositions = computed(() => renderVerticalPositions.value.groupPositions)
    const sheetPositions = computed(() => renderVerticalPositions.value.sheetPositions)

    const fullHeadingWidth = computed(() => rowHeadingWidth.value + totalGutterWidth.value)

    const getFreezedCols = (sheet) => {
      const sh = getSheet(sheet)

      const freeze = freezeCol.value === null ? -1 : freezeCol.value
      return ((sh && sh.columns) || []).slice(0, freeze + 1)
    }

    const visibleRows = computed(() => {
      let totalHeight = fullHeadingHeight.value
      const ch = canvasHeight.value
      let fromTop = 0
      const startRow = topRow.value
      const rows = {}

      for (let row = startRow; row < rowCount.value; row += 1) {
        const sheetIndex = getRowSheet(row)
        const sheet = getSheet(sheetIndex)
        const isGrouping = (sheet.grouping || []).length

        const firstOfGroup = isGrouping && isRowFirstOfGroup(row)
        const grpOffset = getRowGroupSpacing(row)
        const grpHeadingOffset = (firstOfGroup && getGroupHeadingHeight(row)) || 0

        const shtOffset = getRowSheetHeadingHeight(row)

        fromTop += grpOffset + shtOffset + grpHeadingOffset
        totalHeight += grpOffset + shtOffset + grpHeadingOffset

        const rowHeight = getRowHeight(row)
        rows[`${row}`] = {
          height: rowHeight,
          heightFromTop: fromTop,
          heightFromWindow: totalHeight
        }

        if (totalHeight >= ch) {
          break
        }

        const lastOfGroup = isGrouping && isRowLastOfGroup(row)
        const grpTotalsOffset = (lastOfGroup && getGroupTotalsHeight(row)) || 0

        fromTop += rowHeight + grpTotalsOffset
        totalHeight += rowHeight + grpTotalsOffset
      }

      return rows
    })

    const visibleRowRange = computed(() => {
      const rows = Object.keys(visibleRows.value)
      return [+rows[0], +rows[rows.length - 1]]
    })

    watch(visibleRowRange, () => {
      emit('scroll', {
        direction: 'y',
        visibleRowRange: visibleRowRange.value,
        topRow: topRow.value,
        bottomRow: visibleRowRange.value[1],
        visibleRows: visibleRows.value,
        rowCount: rowsMap.value.length,
        percentage: c.divide(visibleRowRange.value[1], rowsMap.value.length)
      })
    })

    const visibleColRange = computed(() => {
      let totalWidth = rowHeadingWidth.value
      const startCol = leftCol.value
      let endCol = leftCol.value

      for (let col = leftCol.value; col < colCount.value; col += 1) {
        const colWidth = getColWidth(col, topSheet.value)
        totalWidth += colWidth

        if (totalWidth + colWidth > canvasWidth.value) {
          endCol = col
          break
        }

        endCol = col
      }

      return [startCol, endCol]
    })

    const visibleCols = computed(() => {
      const freezedCols = getFreezedCols(topSheet.value) || []
      const sht = getSheet(topSheet.value)
      const shtCols = (sht && sht.columns) || []
      const startVis = visibleColRange.value[1] || 0

      return [
        ...freezedCols.map((fcol, index) => index),
        ...shtCols.slice(leftCol.value, startVis + 1).map((fcol, index) => leftCol.value + index)
      ]
    })

    const allRowsVisible = computed(() => {
      const count = rowsMap.value.length
      const [startRow, endRow] = visibleRowRange.value

      if (endRow === count - 1 && startRow === 0) return true

      return false
    })

    const allColsVisible = computed(() => {
      const [startCol, endCol] = visibleColRange.value

      if (endCol === colCount.value - 1 && startCol === 0) return true

      return false
    })

    const sheetColPositions = computed(() => {
      const sheets = [...dataSheets.value]
      const colBySheet = {}

      for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex += 1) {
        colBySheet[sheetIndex] = {}
        const columns = [...sheets[sheetIndex].columns]

        let x = fullHeadingWidth.value
        for (let col = 0; col < columns.length; col += 1) {
          const width = getColWidth(col, sheetIndex)
          colBySheet[sheetIndex][col] = {
            x,
            width,
            cumulativeWidth: x + width
          }

          x += width
        }
      }

      return colBySheet
    })

    const colPositions = computed(() =>
      rowsMap.value.reduce(
        (acc, row, index) => ({
          ...acc,
          [index]: sheetColPositions.value[row.sheet]
        }),
        {}
      )
    )

    const rowColPositions = colPositions

    const blendRowColPositions = (providedRowPositions, providedColPositions) => {
      const rp = { ...providedRowPositions }
      const cp = { ...providedColPositions }
      const rkeys = Object.keys(rp)

      if (!rkeys.length) return {}

      const ceP = {}
      for (let rki = 0; rki < rkeys.length; rki += 1) {
        const row = +rkeys[rki]
        ceP[row] = {}
        const ckeys = Object.keys(cp[row])
        for (let cki = 0; cki < ckeys.length; cki += 1) {
          const col = +ckeys[cki]
          if (!ckeys.includes(`${col}`)) continue
          ceP[row][col] = {
            ...rp[row],
            ...cp[row][col]
          }
        }
      }

      return ceP
    }

    const cellPositions = computed(() =>
      blendRowColPositions(rowPositions.value, colPositions.value)
    )

    const scrollOffsetY = computed(() => {
      const topPos = rowPositions.value[topRow.value || 0] || {}
      const topY = topPos.y || 0
      const firstPos = rowPositions.value[0] || {}
      const scrollControlPoint = firstPos.y || 0
      const offset = topY - scrollControlPoint

      return offset
    })

    const sheetPositionsVisible = computed(() => {
      const visible = {}

      const [firstRowVisible, lastRowVisible] = visibleRowRange.value || [null, null]
      const positions = { ...(sheetPositions.value || {}) }
      const scrollOffset = scrollOffsetY.value

      if (firstRowVisible === null || lastRowVisible === null) return []

      const visRows = Array.from(
        { length: lastRowVisible - firstRowVisible + 1 },
        (_, index) => firstRowVisible + index
      )

      const visibleSheets = Object.keys(positions).filter(
        (posKey) =>
          _.intersection(
            visRows,
            Array.from(
              { length: positions[posKey].lastRow - positions[posKey].firstRow + 1 },
              (_, index) => positions[posKey].firstRow + index
            )
          ).length
      )

      for (let sht = 0; sht < visibleSheets.length; sht += 1) {
        const sheetIndex = +visibleSheets[sht]
        const sheet = positions[sheetIndex] || {}

        const isTopSheet = sheetIndex === topSheet.value
        const sheetScrolledOffset = isTopSheet ? sheet.y || 0 : scrollOffset

        visible[sheetIndex] = {
          ...sheet,
          y: (sheet.y || 0) - sheetScrolledOffset,
          sheetHeading: {
            ...sheet.sheetHeading,
            y: (sheet.sheetHeading.y || 0) - sheetScrolledOffset
          },
          superHeadings: {
            ...sheet.superHeadings,
            y: (sheet.superHeadings.y || 0) - sheetScrolledOffset
          },
          colHeadings: {
            ...sheet.colHeadings,
            y: (sheet.colHeadings.y || 0) - sheetScrolledOffset
          }
        }
      }

      return visible
    })

    const rowPositionsVisible = computed(() => {
      const visible = {}

      const [firstRow, lastRow] = visibleRowRange.value || [null, null]
      const positions = { ...(rowPositions.value || {}) }
      const scrollOffset = scrollOffsetY.value

      if (firstRow === null || lastRow === null) return {}

      for (let row = firstRow; row <= lastRow; row += 1) {
        const rowPos = positions[row] || {}
        visible[row] = {
          ...rowPos,
          y: (rowPos.y || 0) - scrollOffset,
          cumulativeHeightScrolled: (rowPos.cumulativeHeight || 0) - scrollOffset
        }
      }

      return visible
    })

    const getFirstVisibleRowOfSheet = (sheetIndex) => {
      // sheet not visible:
      if (!sheetPositionsVisible.value[sheetIndex]) return null

      // If the top row is visible, return it:
      const firstRow = sheetPositionsVisible.value[sheetIndex].topRow
      if (rowPositionsVisible.value[firstRow]) return +firstRow

      // Otherwise if the sheet is visible, but the top row is not, then
      // the sheet must be the first visible, and the first visible row
      // would belong to it
      return +Object.keys(rowPositionsVisible.value)[0]
    }

    const freezeCols = computed(() =>
      Array.from({ length: freezeCol.value + 1 }, (_, index) => index)
    )

    const sheetColsVisible = computed(() => {
      const sheets = [...dataSheets.value]

      const sheetColsVis = []
      const [first, last] = visibleColRange.value

      for (let i = 0; i < sheets.length; i += 1) {
        const nonFreezeCols = Array.from({ length: last - first + 1 }, (_, index) => first + index)

        sheetColsVis[i] = [...freezeCols.value, ...nonFreezeCols]
      }

      return sheetColsVis
    })

    /**
     * Finds the x position of the first visible scrollable col, for each visible row
     * @type {ComputedRef<*[]>}
     */
    const scrollOffsetX = computed(() => {
      const [firstRow, lastRow] = visibleRowRange.value || [null, null]
      const offset = []
      const cp = { ...colPositions.value }
      const fz = freezeCol.value

      const lc = leftCol.value || 0

      for (let row = firstRow; row <= lastRow; row += 1) {
        // push the scroll control point rightward
        const lcr = cp[row] || {}
        const lco = lcr[lc] || {}
        const leftSheetOffset = (lcr[0] && lcr[0].x) || 0
        const normalLeftX = lco.x || 0
        const freezeWidth = fz === null ? 0 : cp[row][fz].cumulativeWidth - leftSheetOffset

        offset[row] = normalLeftX - freezeWidth - leftSheetOffset
      }

      return offset
    })

    const colPositionsVisible = computed(() => {
      const visible = {}

      const [firstCol, lastCol] = visibleColRange.value || [null, null]
      const [firstRow, lastRow] = visibleRowRange.value || [null, null]
      const positions = { ...(colPositions.value || {}) }
      const rows = [...rowsMap.value]
      const fz = freezeCol.value
      const scrollOffsets = scrollOffsetX.value
      const sheetColsVis = sheetColsVisible.value

      if (firstCol === null || lastCol === null) return {}

      for (let row = firstRow; row <= lastRow; row += 1) {
        const offSet = scrollOffsets[row]
        visible[row] = {}

        const foundRow = rows[row]

        if (!foundRow) continue

        const colsVisible = [...(sheetColsVis[foundRow.sheetIndex || 0] || [])]

        for (let colVisibleIndex = 0; colVisibleIndex < colsVisible.length; colVisibleIndex += 1) {
          const col = +colsVisible[colVisibleIndex]
          const colPos = positions[row] && positions[row][col]
          if (!colPos) continue

          if (fz !== null && col <= fz) {
            visible[row][col] = { ...colPos }
          } else {
            visible[row][col] = {
              ...colPos,
              x: colPos.x - offSet,
              cumulativeWidthScrolled: colPos.cumulativeWidth - offSet
            }
          }
        }
      }

      return visible
    })

    const groupPositionsVisible = computed(() => {
      const visible = {}

      const positions = { ...groupPositions.value }
      const [firstRow, lastRow] = [...visibleRowRange.value] || [null, null]

      if (firstRow === null || lastRow === null) return {}

      const visRows = Array.from({ length: lastRow - firstRow + 1 }, (_, index) => firstRow + index)

      const groupRowsVisible = Object.keys(positions).reduce(
        (acc, posKey) => ({
          ...acc,
          [posKey]: _.intersection(
            visRows,
            Array.from(
              { length: positions[posKey].lastRow - positions[posKey].firstRow + 1 },
              (_, index) => positions[posKey].firstRow + index
            )
          )
        }),
        {}
      )

      const visibleGroups = Object.keys(groupRowsVisible).filter(
        (posKey) => groupRowsVisible[posKey].length
      )

      for (let gp = 0; gp < visibleGroups.length; gp += 1) {
        const group = positions[visibleGroups[gp]] || {}
        const rowsVisible = groupRowsVisible[visibleGroups[gp]]
        const firstRowVisible = rowsVisible[0]
        visible[visibleGroups[gp]] = {
          ...group,
          y: (group.y || 0) - scrollOffsetY.value,
          heading: {
            ...group.heading,
            y: Math.max(fullHeadingHeight.value, (group.heading.y || 0) - scrollOffsetY.value)
          },
          totals: {
            ...group.totals,
            y: (group.totals.y || 0) - scrollOffsetY.value
          },
          rowsVisible,
          columns: { ...colPositionsVisible.value[firstRowVisible] }
        }
      }

      return visible
    })

    const columnHeadingPositions = computed(() => {
      const positions = {}

      const sheetPos = { ...(sheetPositionsVisible.value || {}) }
      const cp = { ...colPositionsVisible.value }
      const cpall = { ...colPositions.value }

      const sheetKeys = Object.keys(sheetPos)
      for (let spk = 0; spk < sheetKeys.length; spk += 1) {
        const sheetIndex = +sheetKeys[spk]

        const curSheetPos = sheetPos[sheetKeys[spk]]

        positions[sheetIndex] = {
          superHeadings: {},
          colHeadings: {}
        }

        const firstRow = curSheetPos.firstRow

        // now go through columns and place along x, with width
        const rows = Object.keys(cp)
        const row = spk === 0 ? rows[0] : firstRow
        const cpkeys = Object.keys(cp[row])
        for (let cpk = 0; cpk < cpkeys.length; cpk += 1) {
          const col = +cpkeys[cpk]
          let width = cpall[row][col].width
          const x = cp[row][col].x

          positions[sheetIndex].colHeadings[col] = {
            ...curSheetPos.colHeadings,
            ...positions[sheetIndex].colHeadings[col],
            x,
            width,
            cumulativeWidthScrolled: x + width
          }

          const tcs = getTitleColSpan(col, sheetIndex)

          if (tcs > 1) {
            const colsToMerge = Array.from(
              {
                length: tcs - 1
              },
              (_, index) => col + 1 + index
            )

            colsToMerge.forEach((mergedCol) => {
              width += cpall[row][mergedCol].width

              positions[sheetIndex].colHeadings[mergedCol] = {
                ...curSheetPos.colHeadings,
                ...positions[sheetIndex].colHeadings[mergedCol],
                x,
                width: 0,
                cumulativeWidthScrolled: x + 0,
                affectedByColSpan: true,
                affectedByColSpanIndex: col
              }
            })

            positions[sheetIndex].colHeadings[col].width = width

            cpk += colsToMerge.length
          }
        }

        // Super headers
        const superHeaders = getSuperHeaders(sheetIndex)
        const shy = sheetPos[sheetIndex].superHeadings.y
        const cpa = { ...sheetColPositions.value }
        for (let shi = 0; shi < superHeaders.length; shi += 1) {
          const sh = superHeaders[shi]
          const superHeaderCollapsed = collapsedSuperHeaders.value[sheetIndex]?.[shi] ?? false
          const colsVis = Object.keys(positions[sheetIndex].colHeadings).map((key) => +key)
          const superHeaderCollapsedSize = 26
          const colRange = _.range(sh.span[0], sh.span[1] + 1)

          const intersected = _.intersection(colRange, colsVis)
          const isVisible =
            !sh.hidden && !superHeadersHidden.value[shi.toString()] && intersected.length
          if (!isVisible) continue

          const col = intersected[0]
          const colPos = positions[sheetIndex].colHeadings[col]

          const getNeighbouringVisibleSuperHeaderIndex = (i, direction) => {
            const currentSuperHeader = superHeaders[i]
            const checkSuperHeader = superHeaders[i + direction]

            if (!checkSuperHeader) return null
            if (checkSuperHeader.hidden || superHeadersHidden.value?.[i + direction]) {
              return getNeighbouringVisibleSuperHeaderIndex(i + direction, direction)
            }

            let checkColCheck =
              direction < 0 ? checkSuperHeader.span[1] + 1 : checkSuperHeader.span[0]
            const currentColCheck =
              direction < 0 ? currentSuperHeader.span[0] : currentSuperHeader.span[1] + 1

            if (Math.abs(checkColCheck - currentColCheck) > 0) return null
            return i + direction
          }

          const getPrevNudge = (i) => {
            const checkSuperHeaderIndex = getNeighbouringVisibleSuperHeaderIndex(i, -1)
            if (checkSuperHeaderIndex === null) return 0
            if (!positions[sheetIndex]?.superHeadings?.[checkSuperHeaderIndex]?.collapsed) return 0
            return (
              positions[sheetIndex]?.superHeadings?.[checkSuperHeaderIndex]?.width +
              getPrevNudge(checkSuperHeaderIndex)
            )
          }

          const prevIndex = getNeighbouringVisibleSuperHeaderIndex(shi, -1)
          const prevColRange =
            prevIndex !== null
              ? _.range(superHeaders[prevIndex].span[0], superHeaders[prevIndex].span[1] + 1)
              : []
          const prevVisible = _.intersection(colsVis, prevColRange)?.length || false
          const prevPosition = prevVisible
            ? positions[sheetIndex]?.superHeadings?.[prevIndex]
            : null
          const prevStart = prevPosition?.x || colPos.x
          const prevEnd = prevStart + (prevPosition?.width || 0)
          const prevCollapsed = prevPosition?.collapsed || false
          const prevNudge = getPrevNudge(shi)
          const hasPrevNudge = prevNudge > 0

          const nextIndex = getNeighbouringVisibleSuperHeaderIndex(shi, 1)
          const nextCollapsed = collapsedSuperHeaders.value[sheetIndex]?.[nextIndex] || false

          const currentXStart = prevEnd

          if (superHeaderCollapsed) {
            positions[sheetIndex].superHeadings[shi] = {
              y: shy,
              x:
                currentXStart +
                (prevPosition ? superHeaderCollapsedSize / 2 : 0) -
                superHeaderCollapsedSize / 2,
              width: superHeaderCollapsedSize,
              fullWidth: superHeaderCollapsedSize,
              height: superHeaderCollapsedSize,
              collapsed: true,
              data: sh,
              superHeadingIndex: shi
            }
          } else {
            const width = intersected.reduce(
              (acc, shcol) => acc + (cpa[sheetIndex]?.[shcol]?.width || 0),
              0
            )

            const fullWidth = colRange.reduce(
              (acc, shcol) => acc + (cpa[sheetIndex]?.[shcol]?.width || 0),
              0
            )

            const adjustedWidth = Math.max(
              width -
                (prevCollapsed ? superHeaderCollapsedSize / 2 : 0) -
                (nextCollapsed ? superHeaderCollapsedSize / 2 : 0) -
                prevNudge +
                (hasPrevNudge ? superHeaderCollapsedSize : 0),
              40
            )
            const adjustedFullWidth = Math.max(
              fullWidth -
                (prevCollapsed ? superHeaderCollapsedSize / 2 : 0) -
                (nextCollapsed ? superHeaderCollapsedSize / 2 : 0) -
                prevNudge +
                (hasPrevNudge ? superHeaderCollapsedSize : 0),
              40
            )

            positions[sheetIndex].superHeadings[shi] = {
              y: shy,
              x: currentXStart,
              width: adjustedWidth,
              fullWidth: adjustedFullWidth,
              height: superHeadingHeight.value,
              paddingLeft: 0,
              paddingRight: 0,
              collapsed: false,
              data: sh,
              superHeadingIndex: shi
            }
          }
        }
      }

      return positions
    })

    const collapseGroupGutterPositions = computed(() => {
      // Determine collapse group gutter positions as if the screen was 100% visible
      // based on collapsed/expanded state and depths from collapseGroups
      const cg = [...Object.values(collapseGroups.value)]

      const gp = []

      const rp = { ...(rowPositions.value || {}) }
      if (!Object.keys(rp).length) return gp

      for (let i = 0; i < cg.length; i += 1) {
        const grp = cg[i]
        if (grp.hidden) continue

        const firstRow = grp.rows[0] ?? getRowFromId(cg[i].id)
        const lastRow = grp.rows[grp.rows.length - 1] ?? getRowFromId(cg[i].id)

        const firstPosition = rp[firstRow]
        const lastPosition = rp[lastRow]

        if (!firstPosition || !lastPosition) return gp

        const lastRowHeight = getRowHeight(lastRow)

        const top = firstPosition.y
        const left = leftPadding + grp.level * gutterWidth
        const bottom = (lastPosition?.y ?? 0) + (lastPosition?.height ?? 0)
        const right = left + gutterWidth

        const buttonWidth = 18
        const rowHeight = getRowHeight(grp.rows[0])

        const width = right - left
        const height = bottom - top
        const squareStartX = left + width / 2 - buttonWidth / 2
        const squareStartY = top + rowHeight / 2 - buttonWidth / 2

        gp.push({
          ...grp,
          x: left,
          y: top,
          collapseGroupIndex: i,
          collapseGroupId: grp.id,
          width,
          height,
          top,
          bottom,
          left,
          right,
          button: {
            x: squareStartX,
            y: squareStartY,
            width: buttonWidth,
            height: buttonWidth
          },
          line: {
            x: squareStartX + buttonWidth / 2,
            y: squareStartY + buttonWidth,
            height: height - lastRowHeight / 2 - rowHeight / 2 - buttonWidth / 2,
            width: 1
          }
        })
      }

      return gp
    })

    const cellPositionsVisible = computed(() =>
      blendRowColPositions(rowPositionsVisible.value, colPositionsVisible.value)
    )

    const collapseGroupGutterPositionsVisible = computed(() => {
      // now take into account scroll position to determine positioning
      const offset = scrollOffsetY.value
      const [firstVisible, lastVisible] = visibleRowRange.value
      return collapseGroupGutterPositions.value
        .filter((grp) => {
          const hasRows = grp.rows.length

          // Make sure at least one of the rows is in visible range
          const fallbackRow = getRowFromId(grp.id) ?? getRowFromId(grp.ids[0])
          const hasVisible =
            (fallbackRow > -1 && fallbackRow >= firstVisible && fallbackRow <= lastVisible) ||
            (hasRows &&
              grp.ids.some((r) => {
                const rr = getRowFromId(r)
                return rr >= firstVisible && rr <= lastVisible
              }))

          // Make sure the sheete is sorted in a way that makes collapse groups possible
          const sheet = fallbackRow > -1 && dataSheets.value[getRowSheet(fallbackRow)]
          const regularlySorted =
            sheet &&
            (!sheet.sorting || sheet.sorting.length === 0) &&
            (!sheet.grouping || sheet.grouping.length === 0)

          // make sure this collapse group isn't inside of a collapsed collapse group
          const parentCollapsed = collapsedGroupRows.value.includes(fallbackRow)

          return hasVisible && regularlySorted && !parentCollapsed
        })
        .map((grp) => ({
          ...grp,
          sheetIndex: (grp.rows && grp.rows[0] !== null && getRowSheet(grp.rows[0])) || 0,
          y: grp.y - offset,
          top: grp.top - offset,
          bottom: grp.bottom - offset,
          button: {
            ...grp.button,
            y: grp.button.y - offset,
            top: grp.button.top - offset
          },
          line: {
            ...grp.line,
            y: grp.line.y - offset,
            top: grp.line.top - offset
          }
        }))
    })

    const getCellBoundingRect = (col, row) => {
      const clip = cellPositionsVisible.value[row] && cellPositionsVisible.value[row][col]

      if (row === null || col === null) {
        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }
      }

      if (!clip) {
        const br = {}

        const sheetIndex = getRowSheet(row)

        const rowVis = row in cellPositionsVisible.value
        if (!rowVis) {
          const firstRowVis = +Object.keys(cellPositionsVisible.value)[0]
          br.y = firstRowVis > row ? -1000 : canvasHeight.value + 1000
          br.top = br.y
        } else {
          const rowPos = rowPositionsVisible.value[row]
          br.y = rowPos.y
          br.top = br.y
        }

        const colVis = col in sheetColsVisible.value[sheetIndex]
        if (!colVis) {
          const firstColVis = +cellPositionsVisible.value[0]
          br.x = firstColVis > col ? -1000 : canvasWidth.value + 1000
          br.left = br.x
        } else {
          const firstRow = getFirstVisibleRowOfSheet(sheetIndex)
          const colPos = firstRow && colPositionsVisible.value[firstRow]
          if (firstRow && colPos) {
            br.x = colPos.x
            br.left = br.x
          }
        }

        return {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          ...br
        }
      }

      return {
        top: clip.y,
        left: clip.x,
        bottom: clip.y + clip.height,
        right: clip.x + clip.width,
        ...clip
      }
    }

    const getSheetYOffset = (sheetIndex) => {
      const sheet = dataSheets.value[sheetIndex]
      const prevRow = sheet.topRow - 1

      if (prevRow < 0) return 0

      const { top, height } = getCellBoundingRect(0, prevRow)

      return top + height + getRowSheetSpacing(sheet.topRow)
    }

    const superHeaderPositions = computed(() => {
      const boundaries = []

      for (let i = 0; i < dataSheets.value.length; i += 1) {
        const sheetOffsetY = getSheetYOffset(i)

        if (isNaN(sheetOffsetY)) continue

        const top = sheetOffsetY + sheetHeadingHeight.value
        const bottom = top + superHeadingHeight.value

        const sheet = dataSheets.value[i]
        const superHeaders = sheet.superHeaders

        superHeaders.forEach((superHeader, index) => {
          const [col1, col2] = superHeader.span
          const { left } = getCellBoundingRect(col1, sheet.topRow)
          const { right } = getCellBoundingRect(col2, sheet.topRow)

          const nudges = [0, 0, 0, 0]
          if (
            collapsedSuperHeaders.value[i] &&
            collapsedSuperHeaders.value[i][`${index}`] &&
            collapsedSuperHeaders.value[i][`${index}`].length
          ) {
            nudges[0] = -5
            nudges[1] = 15
            nudges[2] = 5
            nudges[3] = -15
          }

          boundaries.push({
            left: left + nudges[3],
            right: right + nudges[1],
            top: top + nudges[0],
            bottom: bottom + nudges[2],
            width: right + nudges[1] - (left + nudges[3]),
            height: bottom + nudges[2] - (top + nudges[0]),
            superHeader,
            superHeaderIndex: index,
            sheetIndex: i,
            sheet
          })
        })
      }

      return boundaries
    })

    const getLeftColOffset = (sheetIndex, col) => {
      if (
        freezeCol.value === null ||
        col <= freezeCol.value ||
        !sheetColPositions.value[sheetIndex][freezeCol.value]
      ) {
        return rowHeadingWidth.value + totalGutterWidth.value
      }

      return (
        rowHeadingWidth.value +
        totalGutterWidth.value +
        sheetColPositions.value[sheetIndex][freezeCol.value].cumulativeWidth
      )
    }

    const getSelectionRangePosition = (cells) => {
      if (!cells || !cells.length || !cells[0] || !cells[0].length) {
        return {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: 0,
          height: 0,
          x: 0,
          y: 0
        }
      }

      let firstCell = cells[0]
      let lastCell = cells[1][0] === null ? cells[0] : cells[1]
      const rows = visibleRowRange.value
      const cols = visibleCols.value // must include freeze cols

      let firstCol = Math.max(+firstCell[0], +cols[0])
      let lastCol = Math.min(+lastCell[0], +cols[cols.length - 1])
      const firstRow = Math.max(+firstCell[1], +rows[0])
      const lastRow = Math.min(+lastCell[1], +rows[1])

      if (!cols.includes(firstCol)) {
        const rev = [...cols]
        const index = rev.findIndex((ccc) => ccc > +firstCell[0])

        if (index === -1) {
          firstCol = lastCol
        } else {
          firstCol = rev[index]
        }
      }

      if (!cols.includes(lastCol)) {
        const rev = [...cols].reverse()
        const index = rev.findIndex((ccc) => ccc < +lastCell[0])

        if (index === -1) {
          lastCol = firstCol
        } else {
          lastCol = rev[index]
        }
      }

      if (
        cols[0] > lastCell[0] ||
        cols[cols.length - 1] < firstCell[0] ||
        rows[0] > lastCell[1] ||
        rows[1] < firstCell[1]
      ) {
        return {
          x: 0,
          y: 0,
          top: 0,
          left: 0,
          width: 0,
          height: 0,
          bottom: 0,
          right: 0
        }
      }

      firstCell = [firstCol, firstRow]
      lastCell = [lastCol, lastRow]

      const cell1 = getCellBoundingRect(...firstCell)
      const cell2 = getCellBoundingRect(...lastCell)
      const top = cell1.top
      const left = cell1.left
      const width = cell2.right - cell1.left
      const height = cell2.bottom - cell1.top
      return { top, left, width, height, bottom: top + height, right: left + width }
    }

    const totalHeight = computed(
      () =>
        (rowPositions.value[rowPositions.value.length - 1] &&
          rowPositions.value[rowPositions.value.length - 1].cumulativeHeight) ||
        0
    )

    const rowHeadingBoundingRect = computed(() => ({
      x: totalGutterWidth.value,
      y: fullHeadingHeight.value,
      width: rowHeadingWidth.value,
      height: totalHeight.value - fullHeadingHeight.value
    }))

    const getFreezeWidth = (sheet) => {
      if (freezeCol.value === null || !sheetColPositions.value[sheet][freezeCol.value]) return 0

      return (
        sheetColPositions.value[sheet][freezeCol.value].cumulativeWidth - fullHeadingWidth.value
      )
    }

    const nonCollapsedRows = computed(() =>
      rowsMap.value.map((_, index) => index).filter((r) => !collapsedGroupRows.value.includes(r))
    )

    const scrollToY = (row, moveThumb = true) => {
      const checkRow = row ?? 0

      const movingUp = row < topRow.value
      const top = movingUp
        ? [...nonCollapsedRows.value].reverse().find((r) => r <= checkRow)
        : nonCollapsedRows.value.find((r) => r >= checkRow)

      const maxRow = nonCollapsedRows.value[Math.max(0, nonCollapsedRows.value.length - 3)]
      if (top === topRow.value) return

      topRow.value = Math.max(0, Math.min(c.toNum(top, 0), c.toNum(maxRow, 0)))

      const toRowIndex = nonCollapsedRows.value.indexOf(top)

      if (moveThumb) {
        const scrollHeight = verticalScrollbar.value.offsetHeight
        const thumbHeight = verticalThumb.value.offsetHeight

        const maxPosition = scrollHeight - thumbHeight

        const perc = toRowIndex / (nonCollapsedRows.value.length - 1)

        const position = Math.max(0, Math.min(maxPosition, Math.floor(maxPosition * perc)))
        thumbTop.value = position
      }
    }

    const scrollToX = (col, moveThumb = true) => {
      const freezeMin = freezeCol.value === null ? -1 : freezeCol.value
      leftCol.value = Math.max(
        0,
        Math.min(Math.max(freezeMin + 1, c.toNum(col, 0)), colCount.value - 1)
      )

      if (moveThumb) {
        const scrollWidth = horizontalScrollbar.value.offsetWidth
        const thumbWidth = horizontalThumb.value.offsetWidth

        const maxPosition = scrollWidth - thumbWidth

        const perc = leftCol.value / (colCount.value - 1)

        const position = Math.max(0, Math.min(maxPosition, Math.round(maxPosition * perc)))
        thumbLeft.value = position
      }
    }

    const scrollDown = (delta = 1) => {
      // if (allRowsVisible.value) return;
      scrollToY(topRow.value + delta)
    }

    const scrollUp = (delta = 1) => {
      // if (allRowsVisible.value) return;
      scrollToY(Math.max(0, topRow.value - delta))
    }

    const scrollableCols = computed(() => {
      const sheetIndex = topSheet.value

      const sheetCols = dataSheets.value[sheetIndex].columns
      const scrollCols = []

      for (let col = 0; col < sheetCols.length; col++) {
        if (getColWidth(col, sheetIndex) > 0) {
          scrollCols.push(col)
        }
      }

      return scrollCols
    })

    const scrollRight = (delta = 1) => {
      const leftColIndex = scrollableCols.value.indexOf(leftCol.value)
      const newColIndex = (leftColIndex === -1 ? leftCol.value : leftColIndex) + delta
      const newCol = scrollableCols.value[newColIndex]
      const maxRight = scrollableCols.value[scrollableCols.value.length - 2]

      scrollToX(Math.min(maxRight, newCol))
    }

    const scrollLeft = (delta = 1) => {
      const leftColIndex = scrollableCols.value.indexOf(leftCol.value)
      const newColIndex = (leftColIndex === -1 ? leftCol.value : leftColIndex) - delta
      const newCol = scrollableCols.value[newColIndex]

      scrollToX(newCol)
    }

    const handleThumbMouseDownY = (e) => {
      isDraggingY.value = true
      dragStartY.value = e.clientY
    }

    const handleThumbMouseMoveY = (e) => {
      if (!isDraggingY.value) return
      const delta = e.clientY - dragStartY.value

      const scrollHeight = verticalScrollbar.value.offsetHeight
      const thumbHeight = verticalThumb.value.offsetHeight

      const maxPosition = scrollHeight - thumbHeight

      thumbTop.value = Math.max(0, Math.min(maxPosition, thumbTop.value + delta))
      dragStartY.value = e.clientY

      const count = nonCollapsedRows.value.length

      const topIndex = Math.round((thumbTop.value / maxPosition) * count) - 1
      const top = nonCollapsedRows.value[topIndex]

      if (topRow.value !== top) {
        scrollToY(top, false)
      }
    }

    const handleThumbMouseUpY = () => {
      mouseDown.value = false
      isDraggingY.value = false
      dragStartY.value = 0
    }

    const handleThumbMouseDownX = (e) => {
      isDraggingX.value = true
      dragStartX.value = e.clientX
    }

    const handleThumbMouseMoveX = (e) => {
      if (!isDraggingX.value) return
      const delta = e.clientX - dragStartX.value

      const scrollWidth = horizontalScrollbar.value.offsetWidth
      const thumbWidth = horizontalThumb.value.offsetWidth

      const maxPosition = scrollWidth - thumbWidth

      thumbLeft.value = Math.max(0, Math.min(maxPosition, thumbLeft.value + delta))
      dragStartX.value = e.clientX

      const freezeMin = freezeCol.value === null ? -1 : freezeCol.value

      const lc =
        Math.max(freezeMin + 1, Math.round((thumbLeft.value / maxPosition) * colCount.value)) - 1

      if (leftCol.value !== lc) {
        scrollToX(lc, false)
      }
    }

    const handleThumbMouseUpX = () => {
      isDraggingX.value = false
    }

    watch(thumbLeft, () => {
      horizontalThumb.value.style.left = `${thumbLeft.value}px`
    })
    watch(thumbTop, () => {
      verticalThumb.value.style.top = `${thumbTop.value}px`
    })
    watch(isDraggingY, () => {
      if (isDraggingY.value) {
        verticalThumb.value.classList.add('dragging')
      } else {
        verticalThumb.value.classList.remove('dragging')
      }
    })
    watch(isDraggingX, () => {
      if (isDraggingX.value) {
        horizontalThumb.value.classList.add('dragging')
      } else {
        horizontalThumb.value.classList.remove('dragging')
      }
    })

    const handleWheel = (e) => {
      scrolling.value = true // avoid overstimulating + slowing UI
      const moveX = Math.abs(e.deltaX)
      const dirX = e.deltaX > 0 ? 'right' : 'left'
      const moveY = Math.abs(e.deltaY)
      const dirY = e.deltaY > 0 ? 'down' : 'up'

      let yMethod = null
      let xMethod = null
      if (moveY >= 1) {
        yMethod = scrollDown
        if (dirY === 'up') {
          yMethod = scrollUp
        }
      }

      if (moveX > 2) {
        xMethod = scrollLeft
        if (dirX === 'right') {
          xMethod = scrollRight
        }
      }

      if (xMethod) {
        const xDelay = 250 / Math.min(25, moveX)
        c.throttle(xMethod, {
          debounce: true,
          delay: xDelay,
          key: 'x'
        })
      }

      if (yMethod) {
        const yDelay = 150 / Math.min(40, moveY)
        c.throttle(yMethod, {
          debounce: true,
          delay: yDelay,
          key: 'y'
        })
      }

      c.throttle(
        () => {
          scrolling.value = false
        },
        {
          delay: 150
        }
      )
    }

    const handleClickCancel = () => {
      handleThumbMouseUpX()
      handleThumbMouseUpY()
    }

    onMounted(() => {
      scrollContainer.value?.addEventListener('wheel', handleWheel, { passive: true })

      // Scrollbar
      verticalThumb.value?.addEventListener('mousedown', handleThumbMouseDownY)
      window.addEventListener('mousemove', handleThumbMouseMoveY, { passive: true })
      window.addEventListener('mouseup', handleThumbMouseUpY)

      horizontalThumb.value?.addEventListener('mousedown', handleThumbMouseDownX)
      window.addEventListener('mousemove', handleThumbMouseMoveX, { passive: true })
      window.addEventListener('mouseup', handleThumbMouseUpX)
      window.addEventListener('click', handleClickCancel)
    })

    onBeforeUnmount(() => {
      scrollContainer.value?.removeEventListener('wheel', handleWheel)

      // Scrollbar
      window.removeEventListener('mousemove', handleThumbMouseMoveY)
      window.removeEventListener('mouseup', handleThumbMouseUpY)
      verticalThumb.value?.removeEventListener('mousedown', handleThumbMouseDownY)

      window.removeEventListener('mousemove', handleThumbMouseMoveX)
      window.removeEventListener('mouseup', handleThumbMouseUpX)
      horizontalThumb.value?.removeEventListener('mousedown', handleThumbMouseDownX)
      window.removeEventListener('click', handleClickCancel)
    })

    const getElementTypeFromCoordinates = (x, y) => {
      // Use this to narrow it down to the element type
      const elementTypes = {
        cell: {
          x: [fullHeadingWidth.value, canvasWidth.value],
          y: [fullHeadingHeight.value, sheetHeadingHeight.value]
        },
        gutter: {
          x: [0, gutterWidth.value],
          y: [fullHeadingHeight.value, canvasHeight.value]
        },
        rowHeading: {
          x: [gutterWidth.value, fullHeadingWidth.value],
          y: [fullHeadingHeight.value, canvasHeight.value]
        },
        colHeading: {
          x: [fullHeadingWidth.value, canvasWidth.value],
          y: [fullHeadingHeight.value - columnHeadingHeight.value, columnHeadingHeight.value]
        },
        superHeading: {
          x: [fullHeadingWidth.value, canvasWidth.value],
          y: [sheetHeadingHeight.value, superHeadingHeight.value]
        },
        sheetHeading: {
          x: [gutterWidth.value, canvasWidth.value],
          y: [0, sheetHeadingHeight.value]
        }
      }

      const elementTypeNames = Object.keys(elementTypes)

      let elementType = null
      for (let i = 0; i < elementTypeNames.length; i += 1) {
        const { x: xRange, y: yRange } = elementTypes[elementTypeNames[i]]

        if (x >= xRange[0] && x <= xRange[1] && y >= yRange[0] && y <= yRange[1]) {
          elementType = elementTypeNames[i]
          break
        }
      }

      return elementType
    }

    const getSheetIndexFromCoords = (y) => {
      const sp = sheetPositionsVisible.value
      const sheets = Object.keys(sheetPositionsVisible.value)
      const found = sheets.find((si) => y >= sp[si].y && y <= sp[si].y + sp[si].height)
      return found === null ? null : +found
    }

    const getSheetSectionFromCoords = (y, sheetIndex = 0) => {
      const sheetPos = sheetPositionsVisible.value[sheetIndex]
      let element = {}

      if (!sheetPos || !sheetPos.sheetHeading) return element

      if (
        y >= sheetPos.sheetHeading.y &&
        y <= sheetPos.sheetHeading.y + sheetPos.sheetHeading.height
      ) {
        element = {
          name: 'sheetHeading',
          sheetIndex,
          position: { ...sheetPos.sheetHeading }
        }
      }

      if (
        y >= sheetPos.superHeadings.y &&
        y <= sheetPos.superHeadings.y + sheetPos.superHeadings.height
      ) {
        element = {
          name: 'superHeading',
          sheetIndex,
          position: { ...sheetPos.superHeadings }
        }
      }

      if (
        y >= sheetPos.colHeadings.y &&
        y <= sheetPos.colHeadings.y + sheetPos.colHeadings.height
      ) {
        element = {
          name: 'colHeading',
          sheetIndex,
          position: { ...sheetPos.colHeadings }
        }
      }

      if (
        y >= sheetPos.colHeadings.y + sheetPos.colHeadings.height &&
        y <= sheetPos.y + sheetPos.height
      ) {
        element = {
          name: 'cell',
          sheetIndex,
          position: {}
        }
      }

      return element
    }

    const getRowFromCoords = (y) => {
      const rows = rowPositionsVisible.value

      let rowIndex = 0

      const ris = Object.keys(rows)
      for (let i = 0; i < ris.length; i += 1) {
        const ri = ris[i]
        if (y >= rows[ri].y && y <= rows[ri].y + rows[ri].height) {
          rowIndex = +ri
          break
        } else if (y >= rows[ri].y) {
          rowIndex = null
        }
      }

      return rowIndex
    }

    const getColumnFromCoords = (x, sheetIndex) => {
      const firstRow = getFirstVisibleRowOfSheet(sheetIndex)
      const colPos = colPositionsVisible.value[firstRow]

      let colIndex = null
      if (x <= rowHeadingWidth.value || !colPos) return null

      const cols = Object.keys(colPos)
      for (let i = 0; i < cols.length; i += 1) {
        const ci = cols[i]
        const testing = colPos[ci]
        if (x >= testing.x && x <= testing.x + testing.width) {
          colIndex = +ci
          break
        } else if (x >= testing.x) {
          colIndex = null
        }
      }

      return colIndex
    }

    const getGroupIdFromCoords = (y) => {
      const groupPos = groupPositionsVisible.value
      const groupIds = Object.keys(groupPositionsVisible.value)

      let groupId = null

      for (let i = 0; i < groupIds.length; i += 1) {
        const gid = groupIds[i]
        if (!groupPos[i]) continue
        if (y >= groupPos[gid].y && y <= groupPos[gid].y + groupPos[i].height) {
          groupId = gid
          break
        }
      }

      return groupId
    }

    const colspanAffectedCols = computed(() => {
      const sheetCols = []
      for (let i = 0; i < dataSheets.value.length; i += 1) {
        sheetCols[i] = []
        let span = 1
        let affectedBy = null
        for (let j = 0; j < dataSheets.value[i].columns.length; j += 1) {
          const colDef = getColDef(j, i)
          if (span > 1) {
            span -= 1
            sheetCols[i][j] = {
              ...getColDef(j, i),
              affectedByColSpan: affectedBy
            }

            if (span === 1) {
              affectedBy = null
            }

            continue
          }

          if (colDef.titleColSpan && colDef.titleColSpan > 1) {
            span = colDef.titleColSpan
            affectedBy = j
          }

          sheetCols[i][j] = getColDef(j, i)
        }
      }

      return sheetCols
    })

    const getElementFromCoords = (x, y) => {
      const sheetIndex = getSheetIndexFromCoords(y)
      const { name, position: sectionPosition } = getSheetSectionFromCoords(y, sheetIndex)
      const sheetPos = sheetPositionsVisible.value[sheetIndex]
      const sheet = dataSheets.value[+sheetIndex]

      const row = getRowFromCoords(y, sheetIndex)

      // find sheet heading
      if (name === 'sheetHeading') {
        return {
          name: 'sheetHeading',
          sheetIndex,
          position: {
            x: 0,
            y: sectionPosition.y,
            height: sectionPosition.height,
            width: sheetPos.width,
            col: null,
            row
          }
        }
      }

      if (
        x >= totalGutterWidth.value &&
        x <= fullHeadingWidth.value &&
        row !== null &&
        rowPositionsVisible.value[row]
      ) {
        const rowPos = rowPositionsVisible.value[row]

        const rowHeadingPos = {
          x: totalGutterWidth.value,
          y: rowPos.y,
          height: rowPos.height,
          width: rowHeadingWidth.value,
          col: null,
          row
        }

        // col expander for prev col
        if (y < rowPos.y + 5 && +row > 0) {
          return {
            name: 'rowExpander',
            sheetIndex,
            position: {
              ...rowHeadingPos,
              y: rowPos.y - 5,
              height: 10,
              row: row - 1
            },
            contextPosition: rowHeadingPos
          }
        }

        // col expander for current col
        if (y > rowPos.y + rowPos.height - 5) {
          return {
            name: 'rowExpander',
            sheetIndex,
            position: {
              ...rowHeadingPos,
              y: rowPos.y + rowPos.height - 5,
              height: 10,
              row
            },
            contextPosition: rowHeadingPos
          }
        }

        // find row heading
        return {
          name: 'rowHeading',
          sheetIndex,
          position: rowHeadingPos,
          contextPosition: rowHeadingPos
        }
      }

      if (x <= totalGutterWidth.value) {
        // find gutter element
        const cgps = collapseGroupGutterPositionsVisible.value

        let element = {
          name: 'collapseGroupOutOfBounds',
          collapseGroupIndex: null,
          sheetIndex,
          position: {
            x,
            y,
            height: 0,
            width: 0,
            col: null,
            row
          }
        }

        for (let i = 0; i < cgps.length; i += 1) {
          const cgp = cgps[i]
          const btn = cgps[i].button
          const ln = cgps[i].line

          if (x >= cgp.x && x <= cgp.x + cgp.width && y >= cgp.y && y <= cgp.y + cgp.height) {
            // button
            if (
              x >= btn.x - 1 &&
              x <= btn.x + btn.width + 1 &&
              y >= btn.y - 1 &&
              y <= btn.y + btn.height + 1
            ) {
              element = {
                ...cgps[i],
                collapseGroupId: cgps[i].id,
                name: 'collapseGroupButton',
                collapseGroupIndex: i,
                sheetIndex,
                position: {
                  ...btn,
                  col: null,
                  row
                },
                contextPosition: cgp
              }
            } else if (
              x >= ln.x - 2 &&
              x <= ln.x + ln.width + 2 &&
              y >= ln.y &&
              y <= ln.y + ln.height
            ) {
              // line
              element = {
                ...cgps[i],
                collapseGroupId: cgps[i].id,
                name: 'collapseGroupLine',
                collapseGroupIndex: i,
                sheetIndex,
                position: {
                  ...ln,
                  col: null,
                  row
                },
                contextPosition: cgp
              }
            } else {
              element = {
                ...cgps[i],
                collapseGroupId: cgps[i].id,
                name: 'collapseGroup',
                collapseGroupIndex: i,
                sheetIndex,
                position: {
                  ...cgp,
                  col: null,
                  row
                },
                contextPosition: cgp
              }
            }

            break
          }
        }

        return element
      }

      const col = getColumnFromCoords(x, sheetIndex)
      const sheetTopRow = sheetIndex !== null && getFirstVisibleRowOfSheet(sheetIndex)
      const colPos =
        sheetTopRow !== null &&
        col !== null &&
        colPositionsVisible.value &&
        colPositionsVisible.value[sheetTopRow][col]

      if (
        name === 'cell' &&
        col !== null &&
        row !== null &&
        cellPositionsVisible.value[row] &&
        cellPositionsVisible.value[row][col]
      ) {
        // find cell
        return {
          name: 'cell',
          sheetIndex,
          position: {
            ...cellPositionsVisible.value[row][col],
            col: +col,
            row
          }
        }
      }

      // find col heading
      if (name === 'colHeading' && colPos) {
        const colHeadingPos = {
          x: colPos.x,
          y: sectionPosition.y,
          height: sectionPosition.height,
          width: colPos.width,
          col: +col,
          row
        }

        // col expander for prev col
        if (x < colPos.x + 5 && +col > 0) {
          return {
            name: 'colExpander',
            sheetIndex,
            position: {
              x: colPos.x - 5,
              y: sectionPosition.y,
              height: sectionPosition.height,
              width: 10,
              col: +col - 1,
              row
            },
            contextPosition: colHeadingPos
          }
        }

        // col expander for current col
        if (x > colPos.x + colPos.width - 5) {
          return {
            name: 'colExpander',
            sheetIndex,
            position: {
              x: colPos.x + colPos.width - 5,
              y: sectionPosition.y,
              height: sectionPosition.height,
              width: 10,
              col: +col,
              row
            },
            contextPosition: colHeadingPos
          }
        }

        const colDef = colspanAffectedCols.value[sheetIndex][+col]
        const affected =
          (colDef.affectedByColSpan && colDef.affectedByColSpan !== null) || colDef.titleColSpan > 1
        const colToShow = affected && 'affectedByColSpan' in colDef ? colDef.affectedByColSpan : col
        const colPosToShow =
          sheetTopRow !== null &&
          col !== null &&
          colPositionsVisible.value &&
          colPositionsVisible.value[sheetTopRow][colToShow]

        const rootColDef = getColDef(colToShow, sheetIndex)
        const widthToShow = Array.from(
          { length: rootColDef.titleColSpan || 1 },
          (_, i) => i + colToShow
        ).reduce((acc, colIndex) => acc + getColWidth(colIndex, sheetIndex), 0)

        return {
          name: 'colHeading',
          sheetIndex,
          position: {
            ...colHeadingPos,
            x: colPosToShow.x,
            width: widthToShow,
            col: colToShow
          },
          contextPosition: colHeadingPos
        }
      }

      // find superheading
      if (name === 'superHeading' && sheet.superHeaders?.length) {
        const colIndex = +col
        const shp = columnHeadingPositions.value[sheetIndex].superHeadings

        const superHeadingIndex = Object.keys(shp).find(
          (shi) => x >= shp[shi].x && x <= shp[shi].width + shp[shi].x
        )
        if (superHeadingIndex > -1) {
          const shpos = shp[superHeadingIndex]

          return {
            name: 'superHeading',
            sheetIndex,
            superHeadingIndex: shpos.superHeadingIndex,
            collapsed: shpos.collapsed,
            position: {
              ...shpos,
              col: colIndex,
              row
            }
          }
        }
      }

      const groupId = getGroupIdFromCoords(y)
      if (name === 'cell' && groupId && groupPositionsVisible.value[groupId]) {
        const gp = groupPositionsVisible.value[groupId]

        // find group heading
        if (y >= gp.heading.y && y <= gp.heading.y + gp.heading.height) {
          const rowCols = colPositionsVisible.value[gp.firstRow]
          const lastCol = rowCols.length - 1
          const lastColPos = rowCols[lastCol]
          return {
            name: 'groupHeading',
            sheetIndex,
            groupId,
            group: groupId,
            position: {
              x: fullHeadingWidth.value,
              y: gp.heading.y,
              height: getRowHeight(),
              width: lastColPos.x + lastColPos.width - fullHeadingWidth.value,
              col: +col,
              row: +gp.firstRow
            }
          }
        }

        // find group total
        if (y >= gp.totals.y && y <= gp.totals.y + gp.totals.height) {
          return {
            name: 'groupTotal',
            sheetIndex,
            groupId,
            group: groupId,
            position: {
              x: colPos.x,
              y: gp.totals.y,
              height: getRowHeight(),
              width: colPos.width,
              col: +col,
              row: +gp.lastRow
            }
          }
        }
      }

      return {
        name: 'outOfBoundsRight',
        sheetIndex,
        position: {
          x,
          y,
          height: 1,
          width: 1,
          col: null,
          row
        }
      }
    }

    return {
      getElementFromCoords,
      getElementTypeFromCoordinates,
      rowPositions,
      rowPositionsVisible,

      colPositions,
      colPositionsVisible,

      cellPositions,
      cellPositionsVisible,

      sheetPositions,
      sheetPositionsVisible,

      groupPositions,
      groupPositionsVisible,

      collapseGroupGutterPositions,
      collapseGroupGutterPositionsVisible,

      totalGutterWidth,
      scrollOffsetX,
      scrollOffsetY,
      getColWidth,
      getRowHeight,
      getCellBoundingRect,
      sheetColPositions,
      superHeaderPositions,
      rowColPositions,

      getSelectionRangePosition,
      getRowSheetHeadingHeight,
      getGroupHeadingHeight,
      getGroupTotalsHeight,
      getLeftColOffset,
      maxSheetWidth,
      getRowSheetSpacing,
      getRowGroupSpacing,
      columnHeadingHeight,
      sheetHeadingHeight,
      superHeadingHeight,
      rowHeadingWidth,
      fullHeadingHeight,
      rowHeights,
      sheetWidths,

      collapseGroups,

      sheetColsVisible,
      freezeCols,
      rowHeadingBoundingRect,
      fullHeadingWidth,
      getFreezeWidth,
      isRowOnTop,
      visibleCols,
      visibleColRange,
      visibleRows,
      visibleRowRange,

      topRow,
      leftCol,
      scrollToX,
      scrollToY,
      allColsVisible,
      allRowsVisible,

      isDraggingX,

      isDraggingY,

      scrollDown,
      scrollUp,
      scrollRight,
      scrollLeft,
      topSheet,
      columnHeadingPositions,
      getFirstVisibleRowOfSheet,
      collapsedGroupRows,
      collapsedGroups,
      maxDepth,
      scrollableCols,
      resetColWidths
    }
  }
}
