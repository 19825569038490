import { zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import * as zxcvbnFrPackage from '@zxcvbn-ts/language-fr'
import { matcherPwnedFactory } from '@zxcvbn-ts/matcher-pwned'

export default {
  install() {
    // optional
    const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions)
    zxcvbnOptions.addMatcher('pwned', matcherPwned)

    const options = {
      // recommended
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
        // recommended the language of the country that the user will be in
        ...zxcvbnFrPackage.dictionary
      },
      // recommended
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      // recommended
      useLevenshteinDistance: true,
      // optional
      translations: zxcvbnEnPackage.translations
    }
    zxcvbnOptions.setOptions(options)
  }
}
