<template>
  <Button outlined size="sm" @click="clickHandler" class="relative suggestion-button">
    <!--    <template #loadingicon>-->
    <!--      <div class="absolute inset w-full h-full flex justify-center items-center backdrop-blur-lg">-->
    <!--        <div class="absolute inset bg-surface-300 opacity-60 w-full h-full backdrop-blur-lg"></div>-->
    <!--        <LoadingIndicator />-->
    <!--      </div>-->
    <!--    </template>-->

    <slot></slot>
  </Button>
</template>

<script>
export default {
  name: 'SuggestionButton',
  emits: ['click', 'triggered'],
  methods: {
    clickHandler(e) {
      if (this.stop) e.stopPropagation()

      this.$emit('click')
      this.$emit('triggered')

      if (this.prevent) return e.preventDefault()

      return true
    }
  },
  props: {
    stop: {
      default: true
    },
    prevent: {
      default: true
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
