import AvatarHref from '@/assets/Avatar2.png'

export default {
  props: {
    quoteStatusProp: {
      default: ''
    },
    IsPublic: {
      default: true
    },
    IsQuoteCall: {
      default: false
    },
    quoteIdProp: {
      default: null
    },
    clientObjectProp: {
      default: null
    },
    showratingObjectProp: {
      default: false
    },
    ratingOProp: {
      default: null
    }
  },
  data() {
    return {
      ratingStars: null,
      companyObject: null,
      loadingRating: 1,
      ratingObject: [],
      clientObject: null,
      ratingTitle: null,
      ratingTexts: ['Unacceptable', 'Poor', 'Good', 'Very good', 'Excellent'],
      ratingComment: null,
      showRatingBox: false,
      displaySubmitted: false,
      quoteStatus: ''
    }
  },
  async created() {
    await this.SetRatingAndLoad()
    this.loadingRatingComponent = 0
  },
  methods: {
    /**
     * sets all variables needed to display
     * rating info
     * @returns {Promise<void>}
     * @constructor
     */
    async SetRatingAndLoad() {
      if (this.IsPublic) {
        const Rating = await this.getRating()
        this.ratingObjectComputed = Rating.normalized[Rating.rootRefId]
        this.ratingStarsComputed = Number(this.ratingObjectComputed.rating_overall)
        this.ratingCommentComputed = this.ratingObjectComputed.rating_comment
        this.showRatingBox = this.ratingObjectComputed.rating_status !== 'g'
        this.ratingTitle = await this.getRatingTitleName()
      } else {
        this.quoteStatus = this.quoteStatusProp
        this.clientObject = this.clientObjectProp
        this.ratingObjectComputed = this.ratingOProp
        this.ratingCommentComputed = this.ratingObjectComputed.rating_comment
        this.ratingStarsComputed = Number(this.ratingObjectComputed.rating_overall)
        this.showRatingBox = this.ratingObjectComputed.rating_status !== 'g'
        this.ratingTitle = await this.getRatingTitleName()
      }
    },
    /**
     * Gets the full name of client or company short name
     * @returns {Promise<*|string|string|string>}
     */
    async getRatingTitleName() {
      if (!this.checkIfObjectIsClient || !this.IsPublic) {
        await this.setClient()
        const clientFName = this.clientObject.user_fname || ''
        const clientLName = this.clientObject.user_lname ? `${this.clientObject.user_lname}` : ''
        return `${clientFName} ${clientLName}`
      }
      const company = await this.getCompany()
      return company.company_name
    },
    /**
     * gets the comppany using
     * the companyId data variable
     * @returns {Promise<*>}
     */
    async getCompany() {
      const { normalized, rootRefId } = await this.$store.dispatch(
        'Company/fetchNormalized',
        {
          id: this.companyId
        },
        { root: true }
      )
      this.companyObject = normalized[rootRefId]
      return normalized[rootRefId]
    },
    /**
     * sets this.clientObject to client object
     * @returns {Promise<void>}
     */
    async setClient() {
      if (this.clientObject) {
        return
      }
      if (this.ratingObjectComputed.oClient) {
        this.clientObject = this.ratingObjectComputed.oClient
        return
      }
      const clientId = this.ratingObjectComputed.ratingO
        ? this.ratingObjectComputed.ratingO.rating_object_id
        : await this.getQuote().client_id
      const { normalized, rootRefId } = await this.$store.dispatch(
        'Client/fetchNormalized',
        {
          id: clientId
        },
        { root: true }
      )
      this.clientObject = normalized[rootRefId]
    },
    /**
     * gets quote info using
     * data variable quoteId;
     * @returns {Promise<any>}
     */
    async getQuote() {
      const { normalized, rootRefId } = await this.$store.dispatch(
        'Quote/fetchNormalized',
        {
          id: this.quoteId
        },
        { root: true }
      )
      return normalized[rootRefId]
    },
    formNotValid() {
      return (
        this.ratingStarsComputed === this.ratingObject.rating_overall &&
        this.ratingCommentComputed === this.ratingObject.rating_comment
      )
    },
    /**
     * save/updates rating
     * @returns {Promise<any>}
     */
    async saveRating() {
      this.loadingRatingComponent = 1
      if (this.formNotValid()) {
        if (this.ratingObject.rating_status === 'g') {
          await this.$store.dispatch('alert', {
            message: 'Must change star rating or comment in order to update',
            error: true
          })
        } else {
          await this.$store.dispatch('alert', {
            message: 'change star rating or comment in order to save',
            error: true
          })
        }
        this.loading = false
        return false
      }
      const returnMe = await this.$store.dispatch('ajax', {
        path: `Rating/partialUpdate/${!this.IsPublic ? this.ratingOProp.rating_id : this.ratingId}`,
        data: {
          object: {
            rating_overall: this.ratingStarsComputed,
            rating_comment: this.ratingCommentComputed,
            rating_status: 'g'
          }
        }
      })
      if (returnMe) {
        if (this.ratingObject.rating_object_type === 'Client') {
          await this.$store.dispatch('alert', {
            message: 'Your client rating has been saved',
            error: false
          })
        }
        if (this.ratingObject.rating_object_type === 'Company') {
          await this.$store.dispatch('alert', {
            message: 'The Company rating has been saved',
            error: false
          })
        }
      }
      if (this.IsQuoteCall) {
        await this.reload()
      }
      this.displaySubmitted = true
      this.ratingObjectComputed = returnMe.object
      this.ratingCommentComputed = this.ratingObjectComputed.rating_comment
      this.ratingStarsComputed = Number(this.ratingObjectComputed.rating_overall)
      this.showRatingBox = this.ratingObjectComputed.rating_status !== 'g'
      this.loadingRatingComponent = 0
      return returnMe
    },
    /**
     * gets rating info
     * if public just directly grab the rating
     * @returns {Promise<any>}
     */
    async getRating() {
      const returnMe = await this.$store.dispatch('Rating/fetchNormalized', {
        id: this.ratingId
      })
      return returnMe
    }
  },
  computed: {
    loadingRatingComponent: {
      get() {
        return this.loadingRating
      },
      set(v) {
        this.loadingRating = v
      }
    },
    /**
     * gets the Company logo from
     * data variable company
     * @returns {*}
     */
    checkIfObjectIsClient() {
      return this.ratingObjectComputed.rating_object_type !== 'client'
    },
    /**
     * get company logo link
     * @returns {*}
     */
    getCompanyLogo() {
      const logo = c.link(
        `file/view/${this.companyObject.company_logo_file_id}`,
        {},
        true,
        _.getStorage('scope')
      )
      return this.companyObject.company_logo_file_id ? logo : AvatarHref
    },
    /**
     * get client logo using
     * data variable client
     * @returns {*}
     */
    getClientLogo() {
      const logo = c.link(
        `file/view/${this.clientObject.profile_file_id}`,
        {},
        true,
        _.getStorage('scope')
      )
      return this.clientObject.profile_file_id ? logo : AvatarHref
    },
    /**
     * check if object is a client
     * using ratingObjectComputed
     * @returns {boolean}
     */
    ratingId() {
      return this.IsQuoteCall ? this.ratingOProp.rating_id : this.$route.params.id
    },
    quoteId() {
      return this.quoteIdProp || this.$route.params.quoteId
    },
    companyId() {
      return this.$route.params.companyId
    },
    ratingStarsComputed: {
      get() {
        return this.ratingStars
      },
      set(v) {
        this.ratingStars = v
      }
    },
    ratingObjectComputed: {
      get() {
        return this.ratingObject
      },
      set(v) {
        this.ratingObject = v
      }
    },
    ratingCommentComputed: {
      get() {
        return this.ratingComment
      },
      set(v) {
        this.ratingComment = v
      }
    }
  }
}
