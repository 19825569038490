<template>
  <ListItem :condensed="true">
    <div class="grid grid-cols-3 w-full">
      <div class="flex justify-start">
        <div v-if="!hideSelect" class="flex justify-center items-center mr-4">
          <Checkbox
            v-tooltip="'Waiting for you to approve and pay'"
            v-model="aggregateApproved"
            @input="(val) => item.action(val)"
          />
        </div>
        <div class="flex justify-center items-center text-nowrap">
          <span class="flex">{{ item.title }}</span>
        </div>
      </div>
      <div class="ml-24 flex items-center text-nowrap text-cool-gray-700 font-normal">
        <font-awesome-icon class="mr-2" :icon="['far', 'boxes-stacked']" /> Progress payment
      </div>
      <div></div>
    </div>
    <template v-if="showFooter" #footer>
      <slot name="footer" />
    </template>
  </ListItem>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import ListItem from './ListItem.vue'
import Checkbox from '../Checkbox.vue'

const aggregateApproved = ref(false)

defineProps({
  item: {
    type: Object,
    default: () => {},
    required: true
  },
  hideSelect: {
    type: Boolean,
    default: false
  }
})
</script>
