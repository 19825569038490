<template>
  <div class="activity-files flex overflow-x-auto">
    <GalleryPopUp
      ref="imageGalleryRef"
      :images="imageFiles"
      :showRemove="showRemove"
      @removeFile="onRemoveUrl"
    />
    <div v-for="(blobFile, index) in blobFiles" class="mr-2" :key="blobFile.blobUrl">
      <ActivityFile
        :fileUrl="blobFile.blobUrl || ''"
        :fileType="blobFile.blobMimeType"
        @imageClick="openGallery(blobFile.blobUrl)"
        :showRemove="showRemove"
        @removeFile="onRemove(index)"
        class="w-16 h-16"
      />
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed, watch } from 'vue'
import { useFile } from '@/components/composables/File'
import ActivityFile from '@/components/ui/File/File.vue'
import GalleryPopUp from '@/components/ui/Gallery/GalleryPopUp.vue'

// ======== Props ======== //
const props = defineProps({
  files: {
    type: Array,
    required: true
  },
  showRemove: {
    type: Boolean,
    default: false
  }
})
// ====================== //

// ======== Props ======== //
const emit = defineEmits(['removeFile'])
// ====================== //

// ======== Composables ======== //
const { fetchFiles, revokeFiles } = useFile()
// ====================== //

// ======== Refs ======== //
const imageGalleryRef = ref('imageGalleryRef')
// ====================== //

// ======== Data ======== //
const blobFiles = ref(props.files)
// ====================== //

// ======== Computed ======== //
const imageFiles = computed(() =>
  blobFiles.value
    .filter(({ blobMimeType }) => blobMimeType?.startsWith('image/'))
    .map(({ blobUrl, blobMimeType }) => ({ src: blobUrl, type: blobMimeType }))
)
// ====================== //

// ======== Methods ======== //
const onRemove = (index) => {
  emit('removeFile', [index])
}
const onRemoveUrl = (url) => {
  console.log(url)
  const index = props.files.findIndex((file) => file.src === url)
  if (index >= 0) {
    onRemove(index)
  }
}
const openGallery = (url) => {
  imageGalleryRef.value.openModal(url)
}
const initMounted = async () => {
  blobFiles.value = await fetchFiles(props.files)
}
const destroyOnBeforeUnMount = () => {
  revokeFiles(blobFiles.value.map((blob) => blob.blobUrl))
}
// ====================== //

// ======== Watchers ======== //
watch(
  () => props.files,
  () => {
    initMounted()
  }
)
// ====================== //

// ====== Lifecycle ====== //
onMounted(() => {
  initMounted()
})
onBeforeUnmount(() => {
  destroyOnBeforeUnMount()
})
// ====================== //
</script>
