import BtnMixin from '../../mixins/Button'
import _ from '../../../../imports/api/Helpers'

// const determineAddonPrice = (audited) => {
//   if (audited.type === 'assembly') {
//     return audited.quote_price_net_base;
//   }
//
//   return audited.cost_item_price_net_base;
// };
//
// const determineAddonRequiredDimensions = (audited) => {
//   if (audited.type === 'assembly') {
//     return audited.asDimensionsLinked && audited.asDimensionsLinked.length
//       ? audited.asDimensionsLinked
//       : null;
//   }
//
//   // asDimensionsUsed is all dimensions, asRequiredDimensions are dimensions that are
//   // set speficially inside the assembly, so used - required = dimensions
//   // that we are expecting to come from
//   return _.difference(audited.asDimensionsUsed, audited.asRequiredDimensions);
// };
//
// const determineAddonName = (audited) => {
//   if (audited.type === 'assembly') {
//     return audited.assembly_name;
//   }
//
//   return audited.cost_type_name;
// };

export default {
  mixins: [BtnMixin],

  methods: {
    async getAddonFromObject(type, id, addonType = 'replace', preventIf = null) {
      // const auditedObject = await this.fetchAddon(type, id, addonType);

      // const price = determineAddonPrice(auditedObject);
      // const requiredDimensions = determineAddonRequiredDimensions(auditedObject);
      // const name = determineAddonName(auditedObject);

      // IF BLANK create new, then add
      let finalId = id
      if (id === 'blank') {
        const object = await this.$store.dispatch('create', {
          type,
          go: false,
          close: false
        })
        if (object === null) return null
        finalId = object[`${type}_id`]
      }

      return {
        ...this.defaultUpgrade,
        type,
        id: finalId,
        price: 0,
        addonType,
        name: '',
        asDimensionsRequired: [], // requiredDimensions,
        preventIf: preventIf || (addonType === 'option' ? 'anywhere' : 'sibling')
      }
    },

    async getSuggestedUpgrades() {
      const filters = {}
      let sameStage = []
      let sameEquation = []

      const idf = (obj) => `${obj.type}_id`
      const id = (obj) => obj[idf(obj)]

      // get other addons, to exclude them from search
      const omitIds = {}
      this.value.forEach((addon) => {
        if (!addon.id) return
        if (!(`${addon.type}_id` in omitIds)) omitIds[`${addon.type}_id`] = []
        omitIds[`${addon.type}_id`].push(addon.id)
      })

      // Add target
      omitIds[`${this.target.type}_id`] = _.cleanArray(
        [...(omitIds[`${this.target.type}_id`] || []), this.target[`${this.target.type}_id`]],
        true
      )

      // Turn it into a filter
      Object.keys(omitIds).forEach((idField) => {
        if (omitIds[idField] && omitIds[idField].length) {
          filters[idField] = `!${omitIds[idField].join('&&!')}`
        }
      })

      // Add dimension measure type as a type of filter
      if (this.target.dimension_measure_type) {
        filters.dimension_measure_type = `${this.target.dimension_measure_type}||NULL`
      }

      if (this.target.stage_id) {
        filters.stage_id = this.target.stage_id

        const { set } = await this.$store.dispatch('CostType/search', {
          filters: _.imm(filters),
          searchTypes: ['cost_type', 'assembly'],
          limit: 10
        })

        sameStage = set
      }

      if (this.target.cost_type_qty_equation) {
        filters.cost_type_qty_equation = `${this.target.cost_type_qty_equation}||NULL`

        const { set } = await this.$store.dispatch('CostType/search', {
          filters: _.imm(filters),
          searchTypes: ['cost_type', 'assembly'],
          limit: 10
        })

        sameEquation = set
      }

      const { set: similarName } = await this.$store.dispatch('CostType/search', {
        filters: _.imm(filters),
        searchPhrase: this.target.cost_type_name || this.target.assembly_name,
        searchTypes: ['cost_type', 'assembly'],
        limit: 10
      })

      this.suggestedUpgrades = Object.values(
        [...similarName, ...sameEquation, ...sameStage].reduce(
          (acc, it) => ({
            ...acc,
            [id(it)]: it
          }),
          {}
        )
      )
    },
    getItemIndex(item) {
      const type = item.type === 'assembly' ? 'assembly' : 'cost_type'
      return `${type}:${item[`${type}_id`]}`
    },
    getAddonKey(addon) {
      const type = addon.type === 'assembly' ? 'assembly' : 'cost_type'
      return `${type}:${addon.id}`
    },
    disableAddon(addon) {
      this.disabledAddons = [...this.disabledAddons, this.getAddonKey(addon)]
    },

    modifyAddon(addon, modified) {
      const vals = _.imm(this.valueLocal)
      vals.splice(this.indexOfAddon(addon), 1, modified)
      this.emit(vals)
    },

    removeAddon(index) {
      const newVal = _.imm(this.valueLocal)
      newVal.splice(index, 1)
      // this.valueLocal = newVal;
      this.emit(newVal)
    },

    indexOfAddon(addon) {
      const vl = _.imm(this.valueLocal)
      const found = vl.find((a) => a.type === addon.type && String(a.id) === String(addon.id))
      const index = !found ? -1 : vl.indexOf(found)
      return index
    },

    emit(addons) {
      const list = this.unique(addons)
      this.$emit('input', list)
    },

    async selectItems() {
      const selections = await this.$store.dispatch('openItemSelector', {
        mod: await this.$store.dispatch('Quote/getQuoteMod', {
          refId: this.refId,
          store: this.store
        })
      })
      return selections.map((i) => `${i.type}:${i[`${i.type}_id`]}`)
    },

    async addUpgradeItem(type = null, id = null) {
      let selections = []
      this.addLoading()

      if (!type || !id) {
        console.log('adding')
        try {
          selections = await this.selectItems()
        } catch (e) {
          this.endLoading()
          throw e
        }
      } else {
        selections = [`${type}:${id}`]
      }

      // remove from suggested if found
      const suggested = _.imm(this.suggestedUpgrades)
      const inSuggested = suggested.find((addon) => addon.type === type && addon.id === id)
      if (inSuggested) {
        suggested.splice(suggested.indexOf(inSuggested), 1)
        this.suggestedUpgrades = suggested
      }

      const toEmit = []
      await Promise.all(
        selections.map(async (selection) => {
          if (!selection) return

          if (this.valueLocal.map((a) => `${a.type}:${a.id}`).includes(selection)) return

          const [itemType, itemId] = selection.split(':')

          const addonItem = await this.getAddonFromObject(itemType, itemId, 'replace', 'sibling')

          if (!addonItem) return

          toEmit.push(addonItem)
        })
      )

      this.emit([...this.valueLocal, ...toEmit])

      this.endLoading()
    },

    unique(addons) {
      const keys = []
      return addons
        .sort((addon) => (addon.bulk ? -1 : 1))
        .filter((addon) => {
          const key = this.getAddonKey(addon)
          const alreadyFound = keys.includes(key)
          keys.push(key)
          return !alreadyFound && addon.id && addon.id !== 'undefined'
        })
    },

    async addOptionalItem() {
      const selections = await this.selectItems()

      const toEmit = []
      await Promise.all(
        selections.map(async (selection) => {
          if (!selection) return

          if (this.valueLocal.map((a) => `${a.type}:${a.id}`).includes(selection)) return

          const [itemType, itemId] = selection.split(':')

          const addonItem = await this.getAddonFromObject(itemType, itemId, 'replace', 'sibling')

          if (!addonItem) return

          toEmit.push(addonItem)
        })
      )

      this.emit([...this.valueLocal, ...toEmit])
    },

    async fetchAddon(type, id, addonType = 'replace') {
      const { object } = await this.$store.dispatch(`${c.titleCase(type)}/fetch`, {
        id
      })

      const { object: auditedObject } = await this.$store.dispatch(`${this.store}/auditInPlace`, {
        object,
        targetRefId: this.reference,
        insertType: addonType
      })

      return auditedObject
    },

    /**
     * If you want to create an addon out of an un-saved item,
     * this is how you do it.
     */
    getAddonFromBulk(item, addonType = 'option', id = null, preventIf = null) {
      return {
        ...this.defaultUpgrade,
        type: item.type === 'assembly' ? 'assembly' : 'cost_type',
        id,
        bulk: _.imm(item),
        addonType,
        preventIf: preventIf || (addonType === 'option' ? 'anywhere' : 'sibling'),
        // Picture file id
        file_id: null,
        original: true,
        name: 'Option',
        asDimensionsRequired: []
      }
    },

    getCurrentAsBulkAddon(addonType = 'replace') {
      const self = c.denormalize(_.imm(this.norm), this.reference, true, true)
      const selfType = self.type === 'assembly' ? 'assembly' : 'cost_type'
      const selfId = self[`${selfType}_id`] || null
      return this.getAddonFromBulk(self, addonType, selfId)
    },

    /**
     * Approve the replace or optinal item
     * @params object object The audited full object
     *  ie: { type: 'assembly', assembly_id: 123 } etc
     * @params object addon The addon pseudo-object,
     *  ie: { addonType: 'option', type: 'assembly', id: 123 }
     */
    get(addon) {
      return this.$store.dispatch('Quote/selectAddon', {
        addonId: addon.id,
        addonType: addon.type,
        refId: this.reference
      })
    },
    async addItem(object) {
      if (this.reference) {
        return this.$store.dispatch(`${this.store}/addAddonItem`, {
          refId: this.reference,
          object
        })
      }

      return null
    },
    async swap(object) {
      if (this.reference) {
        return this.$store.dispatch(`${this.store}/swapAddonItem`, {
          refId: this.reference,
          object
        })
      }

      return null
    }
  },

  mounted() {
    if (this.suggestUpgrades) this.getSuggestedUpgrades()
  },

  data() {
    return {
      suggestedUpgrades: [],
      disabledAddons: [],
      defaultUpgrade: _.imm(_.defaultAddon),
      scrolledIn: true,
      revertList: []
    }
  },
  watch: {
    show(v) {
      this.$emit('show', v)
    }
  },
  computed: {
    /**
     * Create object that will be mixed in
     * with any new externally created object
     * @returns {{}}
     */
    embueCreate() {
      const keepFields = [
        'parent_cost_type_id',
        'cost_type_qty_equation',
        'assembly_qty_equation',
        'labor_type_id',
        'labor_type_name',
        'trade_type_id',
        'trade_type_name',
        'stage_id',
        'stage_name',
        'unit_of_measure_id',
        'unit_of_measure_name',
        'unit_of_measure_abbr',
        'dimension_measure_type',
        'cost_type_is_subcontracted',
        'cost_type_has_labor',
        'cost_type_has_materials',
        'cost_type_material_waste_factor_net',
        'cost_type_materials_purchase_qty_per_unit',
        'purchase_unit_of_measure_id',
        'purchase_unit_of_measure_name',
        'purchase_unit_of_measure_abbr'
      ]

      const ind = this.target
      return keepFields.reduce(
        (acc, f) => ({
          ...acc,
          [f]: ind[f] || null
        }),
        {}
      )
    },
    isAnUpgrade() {
      return (
        this.norm[this.reference] &&
        (this.norm[this.reference].addon_is_upgraded || this.norm[this.reference].item_is_upgraded)
      )
      // return this.object.upgradesOriginalKey
      //   && this.object.upgradesOriginalKey !== this.upgradeKey;
    },
    valueLocal() {
      return this.unique(Array.isArray(this.value) ? this.value : []).map((addon) => ({
        ...addon,
        key: this.getAddonKey(addon)
      }))
    },
    show() {
      return this.filteredList.length && this.scrolledIn
    },
    addonList() {
      return this.valueLocal
    },
    filteredList() {
      // Check to see if the item already exists as a sibling or child
      // of the target
      const addons = this.unique([...this.addonList, ...this.revertList]).filter((addon) => {
        const index = this.getAddonKey(addon)
        if (index === this.currentItemIndex) return false
        return (
          !this.disabledAddons.includes(index) &&
          ((addon.addonType === 'replace' && this.upgrades) ||
            (addon.addonType === 'option' && this.optionals))
        )
      })

      if (this.showCurrent) {
        addons.unshift(this.getCurrentAsBulkAddon('replace'))
      }

      return addons
    },
    norm() {
      return this.$store.state[this.store].normalized
    },
    target() {
      return this.original || this.norm[this.reference]
    },
    parentRefId() {
      return this.target.parentRefId
    },
    currentItemIndex() {
      return this.getItemIndex(this.target)
    },
    currentPrice() {
      return this.target[
        this.target.type === 'assembly' ? 'quote_subtotal_net' : 'cost_item_price_net_base'
      ]
    }
  },
  props: {
    /**
     * Specify the target, or leave blank
     */
    original: {
      default: null
    },
    suggestUpgrades: {
      default: false
    },
    title: {
      default: 'Other clients chose these upgrades:'
    },
    value: {
      type: Array
    },
    reference: {
      required: false
    },
    dimensions: {
      type: Object,
      required: true
    },
    store: {
      type: String,
      default: 'Quote'
    },
    editable: {
      default: false
    },
    optionals: {
      default: true
    },
    upgrades: {
      default: true
    },
    showCurrent: {
      default: false
    }
  }
}
