// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'split',
  
  skipAudit: true,

  fields: {
    split_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    transaction_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'transaction',
    },
    source_account_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'account',
      save: false,
      reload: true,
    },
    destination_account_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'account',
    },
    source_item_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'item',
    },
    item_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'item',
    },
    // Gross, including tax:
    split_amount: {
      type: 'float',
      filter: true,
      format: 'currency',
    },
    // Already included in split_amount, just for
    // purposes of explaining to vendor
    split_tax: {
      type: 'float',
      filter: true,
      format: 'currency',
    },
    split_type: {
      type: 'string',
      filter: true,
      format: false,
    },
    split_is_cleared: {
      type: 'int',
      filter: true,
      format: false,
    },
  },
};
