<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      <div class="flex space-y-2 w-200">
        <p
          :class="{
            'line-clamp-3': !isExpanded,
            'whitespace-pre-line break-words break-all': true
          }"
        >
          {{ truncatedDescription }}
        </p>
        <div class="flex-grow"></div>
        <button
          v-if="needsTruncate"
          @click="toggleExpanded"
          class="text-blue-700 focus:outline-none self-end"
        >
          {{ isExpanded ? 'Read less...' : 'Read more...' }}
        </button>
      </div>
    </template>
  </preview>
</template>

<script>
import { ref, computed } from 'vue'
import PreviewMixin from './PreviewMixin'
import Preview from '@/components/ui/Previews/Base.vue'
import Rating from 'primevue/rating'

export default {
  name: 'PreviewRatingDescription',
  components: { Preview, Rating },
  mixins: [PreviewMixin],
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isExpanded = ref(false)
    const description = props.object.description

    const normalizedParagraph = description.replace(/\r\n/g, '\n')
    const lines = normalizedParagraph.split('\n')
    const numberOfLines = lines.length
    const needsTruncate = computed(() => numberOfLines > 3)

    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value
    }

    const truncatedDescription = computed(() => {
      if (needsTruncate.value && !isExpanded.value) {
        return description.slice(0, 100) + '...'
      }
      return description
    })

    return {
      isExpanded,
      toggleExpanded,
      truncatedDescription,
      needsTruncate,
      description
    }
  },
  watch: {},
  computed: {},
  methods: {}
}
</script>
<style scoped>
.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
