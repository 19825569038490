<script setup>
import { ref, computed, watch } from 'vue'
import { zxcvbnAsync, debounce } from '@zxcvbn-ts/core'

const props = defineProps({
  context: {
    default: 'login',
    type: String,
    validator(value) {
      return ['login', 'reset', 'forgot'].includes(value)
    },
    required: true
  },
  intent: {
    type: String,
    validator(value) {
      return ['confirm', 'new', 'current'].includes(value)
    }
  },
  value: {}
})

const emit = defineEmits(['score', 'valid', 'input', 'submit'])

const minPasswordLength = 8
const maxPasswordLength = 64

const result = ref(null)

const fieldName = computed(() => `${props.intent}-password`)
const passwordRules = computed(
  () =>
    `minlength: ${minPasswordLength}; maxlength: ${maxPasswordLength}; required: ascii-printable; max-consecutive: 3;`
)

async function useZxcvbn(password) {
  result.value = await zxcvbnAsync(password)
}

function processInput(val) {
  if (val && props.intent === 'new') {
    debounce(useZxcvbn(val), 200)
  }
  emit('input', val)
}

watch(result, () => {
  emit('score', result.value)
  emit('valid', props.value.length >= minPasswordLength)
})
</script>

<template>
  <input
    v-if="context === 'login'"
    class="input-container password-field h-12"
    type="password"
    name="password"
    id="password"
    autocomplete="password"
    :value="value"
    @input="(event) => $emit('input', event.target.value)"
    @keyup.enter="() => $emit('submit')"
  />
  <input
    v-else
    class="input-container password-field h-12"
    type="password"
    :name="fieldName"
    :id="fieldName"
    :autocomplete="fieldName"
    :passwordrules="passwordRules"
    :minlength="minPasswordLength"
    :maxlength="maxPasswordLength"
    :value="value"
    @input="processInput($event.target.value)"
  />
</template>

<style scoped lang="scss">
.password-field {
  letter-spacing: 3px;
  text-align: right;
  max-width: unset !important; /* 😡 */
  padding: 0 20px;
}
.password-field::placeholder {
  letter-spacing: 0;
}
</style>
