import eventBus from '@/eventBus'
import AutoCost from '../../../imports/api/AutoCost.js'

export default {
  data() {
    return {
      valueLocal: this.value,
      draggingOnEmpty: false,
      selected: null,
      searchPhrase: '',
      expandedList: [],
      timer: null,
      previousColor: null,
      capturedElement: null,
      drag: false,
      showLoader: null,
      swapping: false,
      suggestions: [],
      removing: false,
      dragOptions: {
        // See: https://github.com/SortableJS/Sortable#options for options
        animation: 200,
        delay: 400,
        delayOnTouchOnly: true,
        ghostClass: 'ghost',
        filter:
          '.loader, input, [btn-component], .button--container, textarea, .nodrag, .calculator-container, .field-component, [field-component], [toggle-component], .toggle-component, .el-switch',
        preventOnFilter: false,
        group: {
          name: 'line-items',
          pull: true,
          put: true
        },
        fallbackOnBody: true
      }
    }
  },

  watch: {
    value(newValue, oldValue) {
      this.valueLocal = this.value

      if (newValue.length > oldValue.length) {
        this.showLoader = null
      }
    },

    expanded(val) {
      this.$emit('isExpanded', val)
      if (this.isRoot && this.$store.getters.smallFormat) {
        this.$store.dispatch('toggleNav', !val)
      }
    },

    async valueLocal(children) {
      this.swapping = true

      if (!_.jsonEquals(children, this.value)) {
        this.$emit('input', children)
      }

      await this.$nextTick()

      this.$emit('itemsChanged')

      this.swapping = false
    },

    showLoader(loader) {
      this.$emit('addingItem', !!loader)
    },

    swapping(swapping) {
      if (!swapping) {
        this.valueLocal = this.value
      }
    }
  },
  computed: {
    norm() {
      return this.$store.state[this.store].normalized
    },
    expanded() {
      return this.expandedList.length
    },
    isRoot() {
      return !this.norm[this.refId].parentRefId
    },
    inGlobalScope() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.user.company_id
      )
    }
  },
  methods: {
    async importFile(file) {
      console.log('file', file)
      await this.$store.dispatch('Quote/import', {
        refId: this.refId,
        fileId: file.file_id,
        createAssembly: true,
        store: this.store
      })
    },
    removingHandler() {
      this.removing = 1
      c.throttle(
        () => {
          this.removing = 0
        },
        {
          delay: 5000
        }
      )
    },
    async selectItems(optional = false, parentRefId = this.refId) {
      const mod = await this.$store.dispatch('Quote/getQuoteMod', {
        refId: this.rootRefId,
        store: this.store
      })
      const selected = await this.$store.dispatch('openItemSelector', {
        mod
      })

      if (selected.length) {
        await this.addItems(selected, optional, false, parentRefId)
        this.endLoading()
      }
    },

    async getSuggestions() {
      if (!this.isRoot) return

      const { set } = await this.$store.dispatch('Quote/getSuggestedItemTypes')
      this.suggestions = set
    },
    testLongTouchStart(e) {
      this.capturedElement = e.target.closest('.handle')
      this.timer = setTimeout(this.onLongTouch, 400)
    },
    testLongTouchEnd() {
      if (this.timer) clearTimeout(this.timer)
      this.capturedElement.style.background = this.previousColor
    },
    onLongTouch() {
      this.previousColor = this.capturedElement.style.background
      this.$nextTick(() => {
        this.capturedElement.style.background = 'red !important'
      })
    },
    canDrag() {
      return this.editable
    },
    toggleExpanded(r, b) {
      if (b) {
        this.expandedList.push(r)
        this.expandedList = _.uniq(this.expandedList)
      } else if (this.expandedList.indexOf(r) > -1) {
        this.expandedList.splice(this.expandedList.indexOf(r), 1)
      }

      this.$emit('expanded', r, b)
    },
    addLoading() {
      this.$emit('loading', this.loading + 1)
    },
    removeLoading() {
      this.$emit('loading', Math.max(0, this.loading - 1))
    },
    endLoading() {
      this.$emit('loading', 0)
    },
    getQuoteOrAssemblyParent() {
      let parent = this.$parent

      while (parent) {
        if (parent.type === 'quote' || parent.type === 'assembly') {
          break
        } else {
          parent = parent.$parent
        }
      }

      return parent
    },
    open() {
      return this.$refs.traverse.open()
    },

    async addItems(items, optional = 0, skipAudit = false, parentRefId = this.refId) {
      this.addLoading()

      await this.$nextTick()

      this.showLoader = items[0].type

      // get already fetched ids - for addon loading
      const fetchedRefIds = await this.$store.dispatch('Quote/getQuoteRefIds', {})

      try {
        const { addedChildrenRefIds } = await this.$store.dispatch(`${this.store}/addItems`, {
          items,
          parent: parentRefId,
          optional,
          skipAudit
        })

        if (
          items.length === 1 &&
          (items[0].id === 'blank' ||
            items[0].cost_type_id === 'blank' ||
            items[0].assembly_id === 'blank')
        ) {
          const object = items[0]
          const action = object.type === 'assembly' ? 'expand' : 'edit'

          const refId = addedChildrenRefIds[0]

          const doAction = () => {
            const comp =
              refId in this.$refs && this.$refs[refId] && c.makeArray(this.$refs[refId])[0]
            if (comp && action in comp) comp[action]()
          }

          if (!(refId in this.$refs) && !this.editAfterAdd) return this

          const delay = refId in this.$refs ? 0 : 200
          c.throttle(() => doAction(), { delay })
        }

        // get all ids after adding new ones - for addon loading
        const quoteRefIds = await this.$store.dispatch('Quote/getQuoteRefIds', {})
        const time = new Date().getTime()
        // in the last 10 minutes
        const unixTimestamp = Math.floor(time / 1000) - 600

        // get new ids to load their addons
        const newRefIds = quoteRefIds.filter((id) => !fetchedRefIds.includes(id))
        const livePriceRefIds = newRefIds.filter(
          (id) =>
            this.norm[id].live_price_reference &&
            this.norm[id].type === 'cost_item' &&
            this.norm[id].live_price_last_fetch < unixTimestamp
        )

        const livePriceLaborRefIds = newRefIds.filter(
          (id) =>
            this.norm[id].labor_type_id &&
            typeof this.norm[id].labor_type_id === 'string' &&
            AutoCost.isAutoCostLaborTypeId(this.norm[id].labor_type_id) &&
            this.norm[id].live_price_last_fetch < unixTimestamp
        )

        // load new items addons
        await this.$store.dispatch('Quote/recalcAddons', { refIds: newRefIds, loading: false })

        if (livePriceRefIds && livePriceRefIds.length > 0) {
          // fetch AutoCosts
          await this.$store.dispatch('Quote/fetchLivePricing', {
            refIds: livePriceRefIds,
            rootRefId: this.rootRefId,
            reviewChanges: false
          })
        }

        if (livePriceLaborRefIds && livePriceLaborRefIds.length > 0) {
          // fetch AutoCosts
          await this.$store.dispatch('Quote/fetchLaborLivePricing', {
            refIds: livePriceLaborRefIds,
            rootRefId: this.rootRefId,
            reviewChanges: false
          })
        }

        return addedChildrenRefIds
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.userMessage || 'Something happened, please try again.',
          error: true
        })
        this.showLoader = null
        this.endLoading()
        throw e
      }
    },

    /**
     *
     * @param val
     * @param optional
     * @returns {Promise<void>}
     */
    async addItem(val, optional = 0, skipAudit = false, parentRefId = this.refId) {
      this.addLoading()

      await this.$nextTick()

      const [type, id] = val.split(':')

      this.showLoader = type

      try {
        const { refId, object } = await this.$store.dispatch(`${this.store}/addItem`, {
          type,
          id,
          parent: parentRefId,
          optional,
          skipAudit,
          skipLocalAudit: true
        })

        await this.$nextTick()

        if (id === 'blank') {
          const action = object.type === 'assembly' ? 'expand' : 'edit'

          if (refId in this.$refs) {
            c.makeArray(this.$refs[refId])[0][action]()
          } else if (this.editAfterAdd) {
            eventBus.$once('itemsChanged', () => {
              try {
                c.throttle(() => c.makeArray(this.$refs[refId])[0][action](), { delay: 100 })
              } catch (e) {
                //
              }
            })
          }
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.userMessage || 'There was an error adding that item. Please try again.',
          error: 1
        })
        throw e
      } finally {
        this.removeLoading()
        this.showLoader = null
      }

      return this
    }
  },

  beforeUnmount() {
    this.$emit('loading', 0)
  },

  props: {
    showForCost: {
      default: () => ['cost', 'price', 'profit']
    },
    // If the root object is an assembly
    inAssembly: {
      default: false
    },
    editable: {
      default: true
    },
    value: {
      required: true
    },
    refId: {
      required: true
    },
    store: {
      default: 'Quote'
    },
    rootRefId: {
      required: true
    },
    editAfterAdd: {
      default: true
    }
  },
  emits: ['isExpanded', 'input', 'itemsChanged', 'addingItem', 'expanded', 'loading']
}
