export default {
  fieldName: 'unit_of_measure_id',
  getSuggestion: () => {
    return {
      bestObject: {},
      best: 'count',
      all: [
        {
          value: 'count',
          match: 0.98
        }
      ]
    }
  }
}
