import { onMounted, ref, nextTick, watch } from 'vue'
import { useStore } from 'vuex'

export const useMetaField = (props, emit) => {
  // ==== Composables ==== //
  const $store = useStore()
  // ================= //

  // ==== Data ==== //
  const metaValue = ref(null)
  const fetched = ref(false)
  // ================= //

  // ==== Methods ==== //
  const getValue = async () => {
    const recd = await $store.dispatch('Keyvalue/get', {
      key: props.name,
      global: props.global
    })
    metaValue.value = props.process(recd)
    await nextTick()
    fetched.value = true
  }
  const setValue = async (value) => {
    metaValue.value = value
    await $store.dispatch('Keyvalue/set', {
      key: props.name,
      global: props.global,
      value
    })
  }
  // ================= //

  // ==== Watchers ==== //
  watch(metaValue, (val, old) => {
    emit('input', val)

    if (val !== old && fetched.value) {
      setValue(val)
    }
  })
  // ================= //

  // ==== Lifecycle ==== //
  onMounted(() => {
    getValue()
  })
  // ================= //

  return {
    metaValue,
    fetched,
    getValue,
    setValue
  }
}
