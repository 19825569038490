import AdyenPaymentsMixin from './AdyenPaymentsMixin'
import moment from 'moment'

/**
 * Adyen specific payfac mixin
 */

export default {
  name: 'AdyenPayFac',
  mixins: [AdyenPaymentsMixin],
  data() {
    return {
      adyenMccCodes: [
        {
          text: 'Construction & installation',
          value: '23'
        },
        {
          text: 'Wood product manufacturing',
          value: '321'
        },
        {
          text: 'Furniture and related product manufacturing',
          value: '337'
        },
        {
          text: 'Hardware, plumbing, and heating equipment and supplies merchant wholesalers',
          value: '4237'
        },
        {
          text: 'Architectural, engineering, and related services',
          value: '5413'
        },
        {
          text: 'Landscaping services',
          value: '56173'
        },
        {
          text: 'Other services to buildings and dwellings',
          value: '56179'
        },
        {
          text: 'Personal and household goods repair and maintenance',
          value: '8114'
        },
        {
          text: 'Other personal services',
          value: '8129E'
        }
      ]
    }
  },
  methods: {
    /**
     * Helper method to search provinces/states for abbreviations
     * Adyen expects the province abbreviation
     * @param {String} state
     * @returns {String}
     */
    async lookupStateAbbr(state) {
      const { set: provs } = await this.$store.dispatch('Province/search', {
        limit: 1,
        filters: {
          province_name: state
        }
      })
      if (provs.length === 0) return state
      const provinceOrState = provs[0]
      return provinceOrState.province_abbr
    },
    /**
     * Map the indvidual legal entities
     * @param {Object} i
     * @returns {Object}
     */
    async mapLegalEntity(i) {
      const date = moment()
      date.set('year', i.dob.year)
      date.set('month', i.dob.month)
      date.set('date', i.dob.day)
      const country = i.address.country.toUpperCase()
      return {
        email: i.email || null,
        ...(i.phone
          ? {
              phone: {
                number: i.phone,
                type: 'mobile'
              }
            }
          : {}),
        name: {
          firstName: i.first_name,
          lastName: i.last_name
        },
        residentialAddress: {
          city: i.address.city,
          country,
          postalCode: i.address.postal_code.replaceAll(' ', ''),
          stateOrProvince: await this.lookupStateAbbr(i.address.state),
          street: i.address.line1,
          street2: ''
        },
        birthData: {
          dateOfBirth: date.format('YYYY-MM-DD')
        },
        ...(i.address.country.toLowerCase() === 'ca'
          ? {}
          : {
              taxInformation: [
                {
                  country: i.address.country,
                  number: i.id_number,
                  type: 'SSN'
                }
              ]
            }),
        percent_ownership: i.relationship.percent_ownership,
        owner: i.relationship.owner || false,
        executive: i.relationship.executive || false,
        title: i.relationship.title || ''
      }
    },
    /**
     * Map the primary legal entity (company)
     * @param {Object} c
     * @param {Boolean} isOrganization
     * @returns {Object}
     */
    async mapCompanyLegalEntity(c, isOrganization = false) {
      const country = c.address.country.toUpperCase()
      const registeredAddress = {
        city: c.address.city,
        country,
        postalCode: c.address.postal_code,
        street: c.address.line1,
        stateOrProvince: await this.lookupStateAbbr(c.address.state)
      }

      const registrationNumber = c.tax_id.split('-R')[0].replaceAll('-', '')
      const taxInformation = [
        {
          country: 'US',
          number: registrationNumber,
          type: 'EIN'
        }
      ]

      if (isOrganization) {
        return {
          legalName: c.name,
          registrationNumber,
          principalPlaceOfBusiness: registeredAddress,
          registeredAddress,
          ...(country === 'US' && {
            taxInformation,
            taxReportingClassification: {
              businessType: 'other',
              mainSourceOfIncome: 'businessOperation',
              type: 'nonFinancialActive'
            }
          }),
          // An agreement between two more people who run  business together
          type: 'privateCompany'
        }
      }
      return {
        name: c.name,
        countryOfGoverningLaw: c.address.country.toUpperCase(),
        registrationNumber,
        registeredAddress,
        ...(country === 'US' && {
          taxInformation
        })
      }
    },
    /**
     * Map the default counterparty data and structure to adyen
     * @param {Object} counterparty
     * @param {Object} bankDetails
     * @param {Array} individuals
     * @returns {Object}
     */
    async adyenMapDataToPayfac(data) {
      const {
        counterparty = {},
        bankDetails = {},
        individuals = [],
        publicToken = null,
        metadata = null,
        legalEntityType = null
      } = data
      const { company, business_profile: business } = counterparty
      const isOrganization = legalEntityType === 'organization'

      const businessPayload = {
        service: 'banking',
        industryCode: business.mcc,
        sourceOfFunds: {
          type: business.source_of_funds,
          adyenProcessedFunds: true
        },
        webData: [
          {
            webAddress: business.url
          }
        ]
      }

      const mapped = {
        company: await this.mapCompanyLegalEntity(company, isOrganization),
        business: {
          service: 'paymentProcessing',
          industryCode: business.mcc,
          salesChannels: ['eCommerce', 'ecomMoto'],
          webData: [
            {
              webAddress: business.url
            }
          ]
        },
        businessAccount: {
          ...businessPayload
        },
        publicToken,
        metadata,
        legalEntityType
      }

      if (bankDetails && Object.keys(bankDetails).length > 0) {
        mapped.bankAccount = {
          countryCode: company.address.country.toUpperCase(),
          currencyCode: company.currency,
          accountNumber: bankDetails.account_number,
          routingNumber: bankDetails.routing_number,
          institutionNumber: bankDetails.institution_number,
          transitNumber: bankDetails.transit_number
        }
      }

      if (individuals && individuals.length > 0) {
        mapped.individuals = await Promise.all(individuals.map((i) => this.mapLegalEntity(i)))
      }
      return mapped
    },
    /**
     * Map the default counterparty data and structure to adyen business account
     * @returns {Object}
     */
    async adyenBusinessAccountMapDataToPayfac(data) {
      const {
        counterparty = {},
        primaryId = null,
        accountHolder = null,
        publicToken = null,
        legalEntityType = null
      } = data
      const { company, business_profile: business } = counterparty
      const isOrganization = legalEntityType === 'organization'

      const businessPayload = {
        service: 'banking',
        industryCode: business.mcc,
        sourceOfFunds: {
          // TODO: hook up to intake form
          type: 'business', // business.source_of_funds,
          adyenProcessedFunds: true
        },
        webData: [
          {
            webAddress: business.url
          }
        ]
      }

      const mapped = {
        company: await this.mapCompanyLegalEntity(company, isOrganization),
        primaryId,
        accountHolder,
        businessAccount: {
          ...businessPayload
        },
        publicToken,
        legalEntityType
      }

      return mapped
    },
    async adyenOnboardPayee({
      counterparty,
      externalAccount,
      legalEntityType,
      owners,
      publicToken,
      metadata
    }) {
      const payfacData = await this.adyenMapDataToPayfac({
        counterparty,
        bankDetails: externalAccount,
        individuals: owners,
        publicToken,
        metadata,
        legalEntityType
      })
      const res = await this.onboardPayee({
        ...payfacData
      })
      return res
    },
    async adyenGetBusinessAccountTermsOfService({ decisionMakerIds }) {
      const res = await this.getCounterpartyBusinessAccountTermsOfService({
        decisionMakerIds
      })
      return res
    },
    async adyenGetUploadedBusinessAccountTermsOfService() {
      const res = await this.getCounterpartyUploadedBusinessAccountTermsOfService()
      return res
    },
    async adyenOnboardBusinessAccount({
      counterparty,
      primaryId,
      accountHolder,
      legalEntityType,
      publicToken
    }) {
      const payfacData = await this.adyenBusinessAccountMapDataToPayfac({
        counterparty,
        primaryId,
        accountHolder,
        publicToken,
        legalEntityType
      })
      const res = await this.onboardCounterpartyBusinessAccount({
        ...payfacData
      })
      return res
    }
  }
}
