export default {
  "languageName": "Portuguese (PT)",

  "Estimate": "Orçamento",
  "Expires": "Expira",
  "Project": "Projeto/Obra",
  "Client": "Cliente",
  "Call us": "Contacte-nos",
  "Presented by": "Apresentado por",
  "Expand": "Expandir",
  "to see options": "para ver as opções",
  "other customers recently upgraded their": "outros clientes atualizaram recentemente o item",
  "other options for": "outras opções para",
  "included": "incluído",
  "not included": "não incluído",
  "Other options for": "Outras opções para",
  "Preview": "Pré-visualização",
  "selected": "selecionado",
  "other customers selected this recently": "outros clientes selecionaram este item recentemente",
  "Make this selection": "Selecione esta opção",
  "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project": "A disponibilidade do upgrade pode variar de acordo com as circunstâncias individuais de cada projeto/obra. As imagens são meramente indicativas podendo não corresponder integralmente ao produto",
  "Download": "Download",
  "Subtotal": "Subtotal",
  "Discount": "Desconto",
  "Please review the proposal and choose accept to proceed": "Por favor, reveja a proposta de orçamento e escolha aceitar para continuar",
  "See": "Ver",
  "More": "Mais",
  "Remove": "Remover",
  "Project files": "Ficheiros do projeto",
  "Notes": "Notas",
  "Upgraded item": "Item atualizado",
  "Clients address": "Morada do cliente",
  "more general items": "mais items gerais",
  "Upgrades subject to final approval and availability": "Upgrades sujeitos a aprovação final e disponibilidade",
  "Cost": "Valor",
  "Taxes": "Impostos",
  "Project total": "Valor total",
  "Review terms and approve this": "Rever os termos e condições e aprovar esta",
  'Review terms and approve': 'Rever os termos e condições e aprovar',
  "Review terms": "Rever os termos e condições",
  "Request revision": "Solicitar alteração",
  "Decline": "Rejeitar",
  "Options available - look for the upgrades symbol": "Opções disponíveis - procure o símbolo de upgrades",
  "Upgrades available": "Upgrades disponíveis",
  "You agree to accept and proceed with the project as detailed": "Aceito e pretendo prosseguir com o projeto/obra conforme aqui detalhado",
  "This version was approved on": "Esta versão foi aprovada a",
  "This version was declined": "esta versão foi rejeitada",
  "Terms and conditions": "Termos e Condições",
  "In order to continue, review and accept to the terms below": "Para continuar, reveja e aceite os termos abaixo",
  "I accept the terms and conditions": "Aceito os termos e condições",
  "I do not accept the terms and conditions": "Não aceito os termos e condições",
  "Thank you for booking": "Obrigado por reservar",
  "Please wait while we upgrade your records": "Por favor, aguarde enquanto atualizamos os seus registos",
  "Thank you": "Obrigado",
  "Project fully booked": "Projeto reservado",
  "Thank you for booking with us": "Obrigado por reservar conosco",
  "We will get in touch as soon as possible to confirm details": "Entraremos em contato o mais breve possível para confirmar os detalhes",
  "Your project is fully booked": "O seu projeto está reservado",
  "Come back to Bolster to follow updates or to request or approve change-orders": "Volte ao Bolster para acompanhar as atualizações ou para solicitar ou aprovar alterações",
  "See projects": "Veja os projetos",
  "There was an error booking this project. Please try again.": "Ocorreu um erro ao reservar este projeto/obra. Por favor, tente novamente.",
  "Request a new quote": "Solicite uma nova cotação",
  "items included": 'itens incluídos',
  "items excluded": 'itens excluída',
  "options": "opções",
  "Review all": "Revisar tudo",
  "New feature": "Novo",
  "See more": "Ver mais",

  "Electronic signature agreement": "Acordo de assinatura eletrônica",
  "By clicking the \"I accept the terms and conditions\" button below, I am accepting to reserve the work as detailed and priced in this proposal by": "Ao clicar no botão \"Aceito os termos e condições\" abaixo, aceito reservar a obra conforme detalhado e precificado nesta proposta por",
  "(the Contractor) and acknowledge and agree that clicking to sign this Agreement will have the same legal affect as a handwritten signature.": "(o Contratado) e reconhece e concorda que clicar para assinar este Contrato terá o mesmo efeito jurídico que uma assinatura manuscrita.",
  "The Agreements may be executed in one or more counterparts, each of which will be deemed to be an original copy and all of which, when taken together, will be deemed to constitute one and the same agreement, and will be effective when acceptance of the counterparts have been recorded electronically by both the Contractor and myself.": "Os Contratos podem ser assinados em uma ou mais vias, cada uma das quais será considerada uma cópia original e todas as quais, quando tomadas em conjunto, serão consideradas como constituindo um e o mesmo contrato, e entrarão em vigor no momento da aceitação do as contrapartes foram registradas eletronicamente pelo Contratado e por mim.",
  "I accept that modifications that I make to the project through Bolster are subject to approval by the Contractor in order to take into consideration any existing or special conditions particular to my property, and may not be possible in all cases.": "Aceito que as modificações que faço no projeto por meio do Bolster estão sujeitas à aprovação da Contratada, a fim de levar em consideração quaisquer condições existentes ou especiais específicas à minha propriedade, e podem não ser possíveis em todos os casos.",
  "I acknowledge that this is an agreement only between the Contractor and myself.": "Eu reconheço que este é um acordo apenas entre o Contratado e eu.",
  "I consent to the Bolster terms and conditions and privacy policy (found at https://costcertified.cc/pub/legal) while using Bolster.": "Eu concordo com os termos e condições e política de privacidade do Bolster (encontrados em https://costcertified.cc/pub/legal) ao usar o Bolster.",

  "Thank you for the opportunity to provide you this": "Obrigado pela oportunidade de lhe apresentarmos esta",
  "change-order": "alteração à proposta de orçamento",
  "proposal": "proposta de orçamento",
  "for project": "para projeto/obra",
  "at": "na",
  "After you review the": "Depois de rever a",
  "and make desired selections (if applicable), please choose to accept, request an alteration or decline": "e escolher as opções que deseja (se aplicável), por favor escolha “Rever os termos e condições e aprovar esta”, “Solicitar alteração” ou “Rejeitar”",
  "Review your": "Reveja a sua",
  "You're receiving this email because you requested information form a company subscribed to": "Recebeu este e-mail porque solicitou um orçamento a uma empresa que subscreve o",
  "You have a": "Tem uma",
  "Your request from": "Proposta de orçamento da",
  "to review": "para analisar",
  "to review + approve": "para analisar + aprovar",
  "has sent you a": "enviou-lhe um",

  'Each': 'Un.',
  'each': 'Un.',
  'Sales tax': 'IVA composto',
  'Items': 'Itens',

  "tax": "imposto",
  "on": "",
  "prices": "",
  "derived from": "",
  "general": "",
  "How can we improve this proposal for you?": "Como podemos melhorar esta proposta?",
  "Cancel": "Cancelar",
  "Save": "Guardar",
  "Approval": "Aceitação",
  "This project is currently not booked. Book it now to ensure you can to proceed.": " Esta proposta não foi aceite. Clique em “Rever a Proposta” caso pretenda rever a mesma",
  "Review project": "Rever a Proposta",
  "Decline project": "Rejeitar a Proposta",
  "Please provide a reason for declining the project": "A sua opinião é muito importante para nós. Por favor, indique as principais razões porque rejeitou a proposta. Obrigado.",
  "Please wait while we process your approval": "Aguarde enquanto processamos sua aprovação",

  "Project not yet approved": "Projeto ainda não aprovado",
  "Review this project carefully then follow the steps to book the project if you wish to proceed.": "Revise este projeto cuidadosamente e siga as etapas para reservar o projeto se desejar continuar.",
  "This project is approved! Check back here soon for updates.": "Este projeto está aprovado! Volte aqui em breve para atualizações.",
  "Revisions requested.. Check your email soon for a new version": "Recebemos o seu pedido de alterações. Brevemente receberá uma revisão da proposta",

  "You declined this project": "Você recusou este projeto",
  "See project details": "Veja os detalhes do projeto",
  "Yes, go ahead..": "Sim vá em frente..",
  "No, cancel..": "Não cancele..",

  "Pending": "Pendente",
  "You have approved this project. Waiting on your changes to be approved by the company.": "Você aprovou este projeto. Aguardando suas alterações serem aprovadas pela empresa.",
  "If you've changed your mind, you can still book this project however it is subject to review by the vendor. Depending on the time since the proposal was created, prices and availability might change.": "Se você mudou de ideia, ainda pode reservar este projeto, mas ele está sujeito à revisão do fornecedor. Dependendo do tempo desde a criação da proposta, os preços e a disponibilidade podem mudar.",

  "Proposal marked as declined.": "Proposta marcada como recusada.",
  "Revisions requested. Check your email soon for a new version.": "Revisões solicitadas. Verifique seu e-mail em breve para uma nova versão.",
  "Changes to approve": "Alterações para aprovar",
  "There are some changes that need your approval!": "Há algumas alterações que precisam de sua aprovação!",
  "Review changes": "Reveja as alterações",
  "Include": "Inclua",
  "Sorry I couldn't help you further! Please help me do a better job next time by letting us know why you declined:": "Lamentamos não o termos ajudado. A sua opinião é muito importante para nós. Por favor, indique-nos em que podemos melhorar",

  "Home": "Casa",
  "Your projects": "Seus projetos",
  "Your proposals": "Suas propostas",
  "Your companies": "Suas empresas",
  "Management fee": "Taxa de administração",


  "You approved work": "Você aprovou o trabalho",
  "Client approved and paid": "Cliente aprovou e pagou",
  "Contractor approved work": "Empreiteiro aprovou o trabalho",
  "You completed work": "Você concluiu o trabalho",
  "Subcontractor completed work": "Subempreiteiro concluiu o trabalho",
  "Waiting for you to review and approve work": "Aguardando sua revisão e aprovação do trabalho",
  "Waiting for client to review and approve work": "Aguardando revisão e aprovação do trabalho pelo cliente",
  "Waiting for contractor to review work": "Aguardando o empreiteiro revisar o trabalho",
  "Waiting for subcontractor to complete work": "Aguardando o subempreiteiro concluir o trabalho",
  "Client will review and approve work once completed": "O cliente revisará e aprovará o trabalho assim que concluído",
  "You can review and approve work after contractor": "Você pode revisar e aprovar o trabalho após o empreiteiro",
  "You can review work once completed": "Você pode revisar o trabalho uma vez concluído",
  "Waiting for you to review work": "Aguardando sua revisão do trabalho",
  "Contractor will review work once complete": "O empreiteiro revisará o trabalho uma vez concluído",
  "Waiting for you to complete work": "Aguardando você concluir o trabalho",
  "Waiting for subcontractor to start working": "Aguardando o subempreiteiro começar a trabalhar",
  "You approved and paid, payment is processing": "Você aprovou e pagou, o pagamento está em processamento",
  'PLEASE READ THE TERMS AND CONDITIONS AND INDICATE YOUR ACCEPTANCE BY ENTERING YOUR NAME ABOVE AND CLICKING THE "I ACCEPT THE TERMS AND CONDITIONS" BUTTON AT THE BOTTOM OF THIS CHECKOUT PAGE. ACCEPTANCE OF THE AGREEMENT MEANS THAT YOU ARE BOUND BY ITS TERMS. PLEASE RETAIN IT FOR YOUR RECORDS.': 'POR FAVOR, LEIA OS TERMOS E CONDIÇÕES E INDIQUE SUA ACEITAÇÃO INSERINDO SEU NOME ACIMA E CLICANDO NO BOTÃO "EU ACEITO OS TERMOS E CONDIÇÕES" NA PARTE INFERIOR DESTA PÁGINA DE CHECKOUT. A ACEITAÇÃO DO ACORDO SIGNIFICA QUE VOCÊ ESTÁ VINCULADO POR SEUS TERMOS. POR FAVOR, MANTENHA-O PARA SEUS REGISTROS.',

  "Thank you for your submission! We will get back to you as soon as possible": "Obrigado pelo seu envio! Entraremos em contato com você o mais breve possível.",
  "Submission Successful": "Envio Bem-Sucedido",
  "Could not find form": "Não foi possível encontrar o formulário",
  "This form does not exist. Please double check the url": "Este formulário não existe. Por favor, verifique o URL.",
  "Saving form submission": "Salvando envio do formulário",
  "Submit": "Enviar",
  "To get started creating an estimate, please select the options provided below": "Para começar a criar uma cotação, selecione as opções fornecidas abaixo",
  "About us": "Sobre nós",
  "Get a quote": "Obter uma cotação",
  "Company rating": "Classificação da empresa",
  "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "Um de nossos agentes dedicados revisará seus requisitos para fornecer uma resposta personalizada. Verifique sua caixa de entrada para confirmação e detalhes. Estamos entusiasmados em ajudar a tornar sua casa dos sonhos uma realidade.",
  "You will receive a copy of the estimate by email for your records": "Você receberá uma cópia da estimativa por e-mail para seus registros.",
  "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Obrigado pelo seu envio! Recebemos seus dados e já estamos trabalhando na criação de uma estimativa para o seu projeto. Entraremos em contato com você em breve para discutir a estimativa e os próximos passos.",
  "First Name": "Primeiro Nome",
  "Last Name": "Último Nome",
  "Email": "E-mail",
  "Phone": "Telefone",
  "Suite": "Suíte",
  "Address": "Endereço",
  "City": "Cidade",
  "Zip Code / Postal Code": "Código Postal / CEP",
  "Project Details": "Detalhes do Projeto",
  "Project Type": "Tipo de Projeto",
  "Project Budget": "Orçamento do Projeto",
  "Project Timeline": "Cronograma do Projeto",
  "Tell us about your project.": "Conte-nos sobre o seu projeto.",
  "Tell us the type of project you need.": "Diga-nos o tipo de projeto que você precisa.",
  "Tell us your budget for the project.": "Informe-nos o seu orçamento para o projeto.",
  "Tell us when you want the project to start and any other important dates.": "Diga-nos quando você deseja iniciar o projeto e qualquer outra data importante.",
  "Simply choose one of the options below that best match your needs, and we'll instantly generate a shoppable estimate. You'll be redirected to the estimate, where you can make upgrades, refine your selections, and submit a request for proposal to the contractor with just one click.": "Basta escolher uma das opções abaixo que melhor correspondam às suas necessidades, e nós geraremos instantaneamente um orçamento personalizável. Você será redirecionado para o orçamento, onde poderá fazer melhorias, refinar suas escolhas e enviar uma solicitação de proposta ao empreiteiro com apenas um clique.",
  "View quote": "Ver o orçamento",
  "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Por favor, preencha o formulário abaixo e geraremos instantaneamente um orçamento personalizável. Você poderá fazer upgrades, refinar suas seleções e enviar uma solicitação de proposta.",
  "Download relevant files to enhance your project or documentation such as contracts, invoices, receipts, images, or any other supporting documents.": "Baixe arquivos relevantes para aprimorar seu projeto ou documentação, como contratos, faturas, recibos, imagens ou outros documentos de apoio.",
  "The achievements and recognitions demonstrate our expertise and commitment, helping you feel confident in your decision.": "As conquistas e reconhecimentos demonstram nossa expertise e compromisso, ajudando você a se sentir confiante em sua decisão.",
  "Awards and badges": "Prêmios e distintivos",
  "Attached files": "Arquivos anexados"
}
