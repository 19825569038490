<template>
  <FileList :value="uploadModel" size="sm" @input="onInput"></FileList>
</template>

<script setup>
import { ref } from 'vue'
import FileList from '@/components/ui/FileList.vue'

// ======== Emits ======== //
const emit = defineEmits(['added'])
// ======================== //

// ======== Data ======== //
const uploadModel = ref([])
// ======================== //

// ======== Methods ======== //
const onInput = async (files) => {
  const res = await Promise.all(
    files.map(async (fileObj) => {
      const fileUrl = c.link(`file/view/${fileObj.file_id}`)
      const fetchResp = await fetch(fileUrl)
      const fetchBlob = await fetchResp.blob()
      return new File([fetchBlob], fileObj.file_name, {
        type: fetchBlob.type
      })
    })
  )

  emit('added', res)
}
// ======================== //
</script>
