<template>
  <Message
    severity="warn"
    :icon="icon"
    :closable="closable"
    :life="life"
    :pt="{
      wrapper: sizeClasses
    }"
    :ptOptions="{
      mergeProps: true
    }"
  >
    <slot></slot>
  </Message>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import Message from '@/components/ui/message/Message.vue'

const props = defineProps({
  icon: {
    type: [String, Boolean],
    default: 'exclamation-circle'
  },
  life: {
    type: Number,
    default: null
  },
  closable: {
    Type: Boolean,
    default: true
  },
  highlight: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'md',
    validator: (v) => {
      return ['sm', 'md'].includes(v)
    }
  }
})

const sizeClasses = computed(() => {
  if (props.size === 'sm') return '!py-2'
  if (props.size === 'xs') return '!py-0'
  return ''
})
</script>
