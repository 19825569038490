<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="lg"
    :isDirty="bodyIsDirty"
    :full="true"
    :clickAway="false"
    :expandable="false"
    :includeGutter="true"
    :hasSidePanel="$store.getters.inSuper"
  >
    <template #body>
      <company ref="body" :key="object.company_id" :object="object" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import Company from '../bodies/Company.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalCompany',
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  methods: {},
  components: { Company },
  props: {
    type: {
      default: 'company'
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
