// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'localization',
  
  skipAudit: true,

  fields: {
    localization_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_language_code: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_country_code: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_thousands_separator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_decimal_separator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_currency_code: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_currency_symbol: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    localization_currency_symbol_position: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
