// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


const getCountryFromCode = (code) => {
  switch (code.toLowerCase()) {
    case 'ca':
      return 1;
    case 'us':
      return 2;
    case 'uk':
      return 3;
    case 'au':
      return 4;
    case 'nz':
      return 5;
    case 'za':
      return 6;
    case 'ie':
      return 7;
  }
};

export default {
  type: 'country',

  getCountryFromCode,

  skipAudit: true,

  fields: {
    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    country_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    country_calling_code: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    country_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },


  generateVueActions() {
    return {
      getCountryFromCode(store, { code }) {
        return getCountryFromCode(code);
      },
    };
  },
};
