<template>
  <div class="rating-creator-details">
    <Sidebar v-model:visible="show" position="right">
      <template #header>
        <p class="text-xl">Reviewer's information</p>
      </template>
      <SpinContainer :loading="loading">
        <div class="rating-creator-client-info creator-details-card">
          <p class="mb-2">Profile</p>
          <div class="client mb-2">
            <PersonAvatar
              :id="creatorId"
              :name="creator.name"
              :type="type === 'ClientRating' ? 'user' : 'client'"
              size="large"
            />
          </div>
          <small class="block">Name</small>
          <small class="block font-semibold mb-2">
            {{ $f.truncate(creator.name, 32) || 'N/A' }}
          </small>
          <div class="grid grid-cols-2 mb-2">
            <div>
              <small class="block">Email</small>
              <small class="block font-semibold">
                {{ $f.truncate(creator.email, 18) || 'N/A' }}
              </small>
            </div>
            <div>
              <small class="block">Phone</small>
              <small class="block font-semibold">
                {{ $f.phone(creator.userPhone, creator.countryAbbr) || 'N/A' }}
              </small>
            </div>
          </div>
        </div>
        <div class="rating-creator-client-info creator-details-card">
          <p class="mb-2">Rating</p>
          <RatingSummary
            :creatorId="creatorId"
            :type="type === 'ClientRating' ? 'contractorId' : 'clientId'"
            :inSidebar="true"
            starSize="sm"
            :ratingData="ratingData"
          />
        </div>
      </SpinContainer>
    </Sidebar>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { onMounted, ref } from 'vue'
import Sidebar from 'primevue/sidebar'
import RatingSummary from '@/components/ratings/RatingSummary.vue'
import useRating from '@/components/composables/Rating'

const { getUser, getClient } = useRating()

const props = defineProps({
  type: {
    type: String,
    default: 'ClientRating'
  },
  creatorId: {
    type: String,
    required: true
  },
  ratingData: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()

const show = ref(false)
const loading = ref(true)
const creator = ref({})
const showRating = ref(false)

const toggleShowSidebar = () => {
  show.value = !show.value
}

const fetchDetails = async ({ type, creatorId }) => {
  loading.value = true

  try {
    let details
    if (type === 'ClientRating') {
      details = await getUser({ userId: creatorId })
    } else {
      details = await getClient({ clientId: creatorId })
    }

    const { user_fname, user_lname, user_email, user_phone, country_abbr } = details.object

    creator.value = {
      name: `${user_fname || ''} ${user_lname || ''}`,
      email: user_email,
      userPhone: user_phone,
      countryAbbr: country_abbr
    }
  } catch (err) {
    console.log(err)
    await store.dispatch('alert', {
      type: 'error',
      message: 'Failed to fetch creator details'
    })
  }

  loading.value = false
}

const handleRatingsFetched = (hasRatings) => {
  showRating.value = hasRatings
}

onMounted(() => {
  fetchDetails({ type: props.type, creatorId: props.creatorId })
})

defineExpose({
  toggleShowSidebar
})
</script>

<style lang="scss" scoped>
.creator-details-card {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: $border-radius;
  border: 2px solid $surface-200;
}
</style>
