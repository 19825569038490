import { computed } from 'vue'
import { usePreferences } from '@/stores/preferences.js'
import { storeToRefs } from 'pinia'

export default {
  useSession(args) {
    const { props, saveSession } = args

    const prefs = usePreferences()
    const { userPrefs, hasFetched, waiter } = storeToRefs(prefs)
    const { setPref } = prefs

    const sessionKey = computed(() =>
      `sheet-settings12-${props.sheets[0].title}-${props.session?.key ?? 'all'}${props.sheets[0].columns.length}`.replace(
        /\s/g,
        '_'
      )
    )

    const session = computed({
      get: () => {
        return userPrefs.value[sessionKey.value] ?? {}
      },
      set: (newSession) => {
        // Only save after intiialized and first fetch
        if (!saveSession.value || !hasFetched.value) return

        setPref(sessionKey.value, newSession)
      }
    })

    const getSessionValue = (key, sheetIndex = null) => {
      if (sheetIndex !== null) return session.value.sheets?.[sheetIndex]?.[key] ?? null
      return session.value[key] ?? null
    }

    const setSessionValue = (key, value, sheetIndex = null) => {
      let newSession = session.value

      if (sheetIndex !== null) {
        if (!newSession.sheets) newSession.sheets = []
        if (!newSession.sheets[sheetIndex]) newSession.sheets[sheetIndex] = {}

        // eslint-disable-next-line no-unused-vars
        const { [key]: previous, ...rest } = newSession.sheets[sheetIndex]

        if (value === null) {
          newSession.sheets[sheetIndex] = rest
        } else {
          newSession.sheets[sheetIndex] = {
            ...rest,
            [key]: value
          }
        }
      } else {
        let omit
          // eslint-disable-next-line no-unused-vars
        ;({ [key]: omit, ...newSession } = newSession)
        if (value !== null) newSession[key] = value
      }

      session.value = newSession
    }

    return {
      getSessionValue,
      setSessionValue,
      session,
      hasFetched,
      sessionsWaiter: waiter
    }
  }
}
