<template>
  <div class="activity-file relative">
    <div
      v-if="showRemove"
      @click="removeFile"
      class="close-icon absolute shadow -top-1.5 -right-1.5 bg-surface-0 rounded-full h-6 w-6 flex items-center justify-center cursor-pointer"
    >
      <font-awesome-icon size="sm" :icon="['fas', 'close']" />
    </div>
    <a
      v-if="!blobMimeType.startsWith('image/')"
      target="_blank"
      :href="blobUrl"
      class="download-overlay absolute w-full h-full top-0 left-0 flex items-center rounded-lg justify-center cursor-pointer opacity-0"
    >
      <font-awesome-icon class="text-surface-300" icon="arrow-up-right-from-square" />
    </a>
    <div class="w-full h-full rounded-lg overflow-hidden bg-surface-100">
      <Skeleton v-if="loading" width="w-full" height="h-full" />
      <div v-else v-tooltip="fileName" class="w-full h-full">
        <img
          v-if="blobMimeType.startsWith('image/')"
          :src="blobUrl"
          :alt="fileName"
          @click="onClickImage"
          class="object-cover w-full h-full cursor-pointer"
        />
        <div
          v-else-if="blobMimeType.includes('pdf')"
          class="flex justify-center items-center w-full h-full"
        >
          <font-awesome-icon size="xl" :icon="['fas', 'file-pdf']" class="text-red-600" />
        </div>
        <div v-else class="flex justify-center items-center w-full h-full">
          <font-awesome-icon size="xl" :icon="['fas', 'file']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useFile } from '@/components/composables/File'
import Skeleton from 'primevue/skeleton'

// ======== Props ======== //
const props = defineProps({
  fileUrl: {
    type: String,
    required: true
  },
  fileName: {
    type: String,
    required: false,
    default: 'Message file'
  },
  fileType: {
    type: String,
    required: false,
    default: null
  },
  showRemove: {
    type: Boolean,
    required: false,
    default: false
  }
})
// ====================== //

// ==== composables ==== //
const { fetchFile, revokeFile } = useFile()
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['removeFile', 'imageClick'])
// ====================== //

// ======== Data ======== //
const blobUrl = ref(null)
const blobMimeType = ref('')
const loading = ref(false)
// ====================== //

// ======== Methods ======== //
const onClickImage = () => {
  emit('imageClick')
}
const removeFile = () => {
  emit('removeFile')
}
const getFile = async () => {
  loading.value = true

  const { blobUrl: url, blobMimeType: type } = await fetchFile(props.fileUrl, props.fileType)

  blobMimeType.value = type
  blobUrl.value = url
  loading.value = false
}
// ====================== //

// ======== Lifecycle ======== //
onMounted(() => {
  getFile()
})
onBeforeUnmount(() => {
  revokeFile(blobUrl.value)
})
// ====================== //
</script>

<style lang="scss" scoped>
.activity-file {
  .download-overlay {
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .download-overlay {
      opacity: 1;
      background-color: rgba($surface-400, 0.5);
    }
  }
}
</style>
