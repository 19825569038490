<template>
  <Container class="!pt-6">
    <div v-if="tab === 'settings-presentation'">
      <div class="mb-7">
        <span class="flex flex-col">
          <p class="font-semibold" :class="darkTitleClasses">Cover letter</p>
          <small class="mb-3" :class="darkInfoClasses">
            This is a letter that, if set, will pop up at the moment the user opens the quote and it
            is designed to introduce your company to the client.
          </small>
        </span>

        <ClassicEditor
          placeholder="Write a short paragraph to introduce your company.  "
          size="md"
          dark
          :removeToolbarItems="[
            'specialCharacters',
            'insertTable',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
            'insertImage'
          ]"
          v-model="coverLetter"
          class="w-full"
        />
      </div>

      <card-list>
        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Presentation-specific terms & conditions</span>
            <small :class="darkInfoClasses">
              Set terms and conditions here that apply ONLY to proposals that use this presentation.
              Your client will need to accept these terms in order to accept your
              quote/estimate/proposal.
              <br /><br />
              <to-route class="text-info" to="estimating_defaults"
                >You can also add terms and conditions that apply to all proposals in your
                "Estimating & presentation settings" page.</to-route
              >
              <br /><br />
              <span class="text-info"
                >Separate each term with a new line. Add sections/headings with square
                brackets:</span
              >

              <br /><br />
              <pre class="code">
                      [My heading]
                      My term one
                      My term two

                      [My other heading]
                      Other terms.
                    </pre
              >
            </small>
          </span>
          <Field
            :class="darkFieldClasses"
            v-model="presentationSettings.termsAndConditions"
            type="textarea"
          />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Files to always attach</span>
            <small :class="darkInfoClasses">
              These files will always be included in the presentation view to your client with any
              project that uses this presentation. For example you could include a standard contract
              you use, or generic information that applies to every job, a catalog, brochure or
              referral form etc.
            </small>
          </span>
          <FileList
            :class="darkFieldClasses"
            v-model="presentationSettings.alwaysIncludedFileIds"
            :id-list="true"
          />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Default send message</span>
            <small :class="darkInfoClasses">
              This creates a default for the personalized message you can write when you send a
              proposal or change-order.
            </small>
            <small :class="darkInfoClasses" style="white-space: pre-line">
              Use the following variables: • {quote_type} (proposal or change order) • {client_name}
              (full name) • {quote_name} • {quote_address} (street address)
            </small>
          </span>
          <Field
            :class="darkFieldClasses"
            v-model="presentationSettings.defaultChangeOrderMessage"
            type="textarea"
          />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses"
              >Credentials / attributes / awards / accomplishments</span
            >
            <small :class="darkInfoClasses">
              List your credientials (tax number, licence numbers, insurance number, etc)
            </small>
          </span>

          <div>
            <MetaList
              :fieldClasses="darkMetaFieldClasses"
              classes="!my-2 !bg-transparent"
              firstPlaceholder="Credential name"
              v-model="presentationSettings.props"
            />
          </div>
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Badges</span>
            <small :class="darkInfoClasses">
              List your badges or certifications (in picture form)
            </small>
          </span>

          <FileList v-model="presentationSettings.badges" :id-list="true" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show heading</span>
            <small :class="darkInfoClasses">
              Hide or display the estimate presentation header.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.screens[0].showHeading" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span :class="darkTitleClasses">Show presenter/sales manager</span>
          <Toggle severity="info" v-model="presentationSettings.screens[0].showPresenter" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show contact buttons</span>
            <small :class="darkInfoClasses">
              Hide or display contact buttons in the estimate presentation header.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.screens[0].showContact" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show logo</span>
            <small :class="darkInfoClasses">
              Hide or display your company logo in the estimate presentation header.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.screens[0].showLogo" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show project details</span>
            <small :class="darkInfoClasses">
              Hide or display project details in estimate presentation header.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.screens[0].showProjectDetails" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show item specific taxes</span>
            <small :class="darkInfoClasses">
              Display the taxes breakdown or bundle into a general tax rate.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.showItemSpecificTax" />
        </card-list-field>
      </card-list>
    </div>

    <div v-if="tab === 'settings-estimation'">
      <card-list>
        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Override assembly-level presentation settings</span>
            <small :class="darkInfoClasses">
              When enabled, the settings in this presentation will override all localized itemized
              pricing settings that are set on an assembly-by-assembly basis.
            </small>

            <small :class="darkInfoClasses">
              When this is disabled, assembly-level itemized pricing
              <strong>and</strong> presentation-level itemized pricing must <strong>both</strong> be
              enabled in order for it to show.
            </small>

            <small :class="darkInfoClasses">
              This is useful for example, if you are creating a master document that you can
              download for your internal use where you want to show the quantities, costs and prices
              even in assemblies where itemized pricing is turned off for the client's view.
            </small>
          </span>

          <Toggle severity="info" v-model="presentationSettings.forceTemplateSettings" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span :class="darkTitleClasses">
            <span
              >Show quantities <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
            /></span>
          </span>
          <Toggle
            severity="info"
            :pt="togglePtOptions"
            v-model="presentationSettings.showQuantities"
          />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span :class="darkTitleClasses">
            <span>
              Show itemized pricing
              <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
            /></span>
            <small :class="darkInfoClasses">
              If disabled, then only the total price of the proposal will be shown. Can be
              overridden on an assembly-by-assembly basis.
            </small>
          </span>

          <Toggle severity="info" v-model="presentationSettings.showItemizedPrices" />
        </card-list-field>

        <template v-if="presentationSettings.showItemizedPrices">
          <card-list-field :class="darkListFieldClasses">
            <span :class="darkTitleClasses">
              <span class="flex"
                >Show item prices
                <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
              /></span>
            </span>

            <Toggle severity="info" v-model="presentationSettings.showCostItemPrices" />
          </card-list-field>

          <card-list-field :class="darkListFieldClasses">
            <span :class="darkTitleClasses">
              <span class="flex"
                >Show assembly prices
                <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
              /></span>
            </span>

            <Toggle severity="info" v-model="presentationSettings.showAssemblyPrices" />
          </card-list-field>
        </template>
      </card-list>
    </div>

    <div v-if="tab === 'settings-print'">
      <CardList>
        <card-list-field :class="darkListFieldClasses">
          <span>
            <span class="flex" :class="darkTitleClasses"
              >Show production notes
              <Tag class="ml-2" severity="info" fill="solid" value="Not recommended "
            /></span>
            <small class="text-danger"
              >If enabled, this will reveal your production notes to your client with this
              presentation!</small
            >
          </span>
          <Toggle severity="info" v-model="presentationSettings.showProductionNotes" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show terms and conditions</span>
            <small
              >Show your terms and conditions in the print version of the proposal document.</small
            >
          </span>
          <Toggle severity="info" v-model="presentationSettings.showTermsAndConditions" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show approvals summary</span>
            <small>
              If enabled, this will show a summary of change-orders/versions in the printable
              version of this presentation.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.showApprovals" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span>
            <span :class="darkTitleClasses">Show project totals</span>
            <small>
              If enabled, this will show the project price, tax and discount totals at the bottom of
              the printable version of this presentation.
            </small>
          </span>
          <Toggle severity="info" v-model="presentationSettings.showProjectTotals" />
        </card-list-field>

        <card-list-field :class="darkListFieldClasses">
          <span :class="darkTitleClasses">Show descriptions in print view </span>
          <Toggle severity="info" v-model="presentationSettings.showDocumentDescriptions" />
        </card-list-field>
      </CardList>
    </div>

    <div
      class="fixed flex gap-3 justify-between bottom-0 left-0 right-0 w-full p-6 bg-white"
      v-if="templateFull"
    >
      <choose
        :key="`settings${presentationTemplateId}`"
        data-label="choose_presentation"
        :return-array="false"
        schema="quote:presentation_template_id"
        :filters="{ template_type_id: 101 }"
        :allowCreate="false"
        v-model="presentationTemplateId"
        :multiple="false"
        :allowDeselect="false"
      >
        <template #default="{ text }">
          <Btn
            size="lg"
            severity="tertiary"
            :stop="false"
            :prevent="false"
            :loading="loading || bodyLoading"
          >
            <template #icon>
              <font-awesome-icon icon="keynote" fixed-width />
            </template>
            {{ text || 'Default' }}
            <span class="caret-down"></span>
          </Btn>
        </template>
      </choose>
    </div>
  </Container>
</template>

<script setup>
import { ref, defineProps, computed, watch, defineEmits } from 'vue'
import MetaList from '../../ui/MetaList.vue'
import Tag from 'primevue/tag'
import ClassicEditor from '@/components/ui/DocumentEditor/ClassicEditor.vue'

const props = defineProps({
  dark: {
    type: Boolean,
    default: false
  },
  tab: {
    type: String,
    default: 'settings-presentation'
  },
  presentationSettings: {
    type: Object,
    default: () => ({})
  },
  refId: {
    required: true
  }
})

const emit = defineEmits('input')

const presentationSettings = ref(props.presentationSettings)

const darkInfoClasses = computed(() => (props.dark ? '!text-cool-gray-300' : 'text-muted'))
const darkTitleClasses = computed(() => (props.dark ? '!text-flame-white' : ''))
const darkFieldClasses = computed(() =>
  props.dark ? '!text-flame-white focus:!bg-transparent focus:!border-flame-white' : ''
)
const darkListFieldClasses = computed(() =>
  props.dark ? '!bg-transparent !border-cool-gray-900' : ''
)

const darkMetaFieldClasses = computed(() =>
  props.dark
    ? '!text-flame-white focus:!border-flame-white !bg-pitch-black !px-3 !mr-1 !rounded-md'
    : ''
)

const cleanAndEscape = (html) => {
  const clean = c.sanitize(html, ['figure', 'oembed'], ['url'])
  return c.escapeHtml(clean)
}

const coverLetter = computed({
  get() {
    return presentationSettings.value.coverLetter
  },
  set(html) {
    presentationSettings.value.coverLetter = cleanAndEscape(html)
    return html
  }
})

watch(
  presentationSettings,
  (v) => {
    emit('input', v)
  },
  { deep: true }
)
</script>

<style lang="scss" rel="stylesheet/scss"></style>
