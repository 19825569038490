export default {
  root: ({ props }) => ({
    class: [
      'relative',

      // Size
      {
        'h-1.5 w-32 md:w-72': props.orientation == 'horizontal',
        'w-1.5 h-56': props.orientation == 'vertical'
      },

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'bg-surface-400/30 dark:bg-surface-700',

      // States
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
    ]
  }),
  range: ({ props }) => ({
    class: [
      // Position
      'block absolute',

      // Shape
      'rounded-full',
      {
        'top-0 left-0': props.orientation == 'horizontal',
        'bottom-0 left-0': props.orientation == 'vertical'
      },

      //Size
      {
        'h-full': props.orientation == 'horizontal',
        'w-full': props.orientation == 'vertical'
      },

      // Colors
      'bg-surface-400/60 dark:bg-primary-400'
    ]
  }),
  handle: ({ props }) => ({
    class: [
      'block',

      // Size
      'h-[1.5rem]',
      'w-[1rem]',
      {
        'top-[50%] mt-[-0.75rem] ml-[-0.75rem]': props.orientation == 'horizontal',
        'left-[50%] mb-[-0.75rem] ml-[-0.75rem]': props.orientation == 'vertical'
      },

      // Shape
      'rounded-sm',

      // Colors
      'bg-surface-900 dark:bg-surface-600',

      // States
      'hover:bg-pitch-black',
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring',
      'ring-primary-400/10 dark:ring-primary-300/50',

      // Transitions
      'transition duration-200',

      // Misc
      'cursor-grab',
      'touch-action-none'
    ]
  }),
  starthandler: ({ props }) => ({
    class: [
      'block',

      // Size
      'h-[1.143rem]',
      'w-[1.143rem]',
      {
        'top-[50%] mt-[-0.5715rem] ml-[-0.5715rem]': props.orientation == 'horizontal',
        'left-[50%] mb-[-0.5715rem] ml-[-0.4715rem]': props.orientation == 'vertical'
      },

      // Shape
      'rounded-full',
      'border-2',

      // Colors
      'bg-surface-0 dark:bg-surface-600',
      'border-primary-500 dark:border-primary-400',

      // States
      'hover:bg-primary-500 hover:border-primary-500',
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring',
      'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',

      // Transitions
      'transition duration-200',

      // Misc
      'cursor-grab',
      'touch-action-none'
    ]
  }),
  endhandler: ({ props }) => ({
    class: [
      'block',

      // Size
      'h-[1.143rem]',
      'w-[1.143rem]',
      {
        'top-[50%] mt-[-0.5715rem] ml-[-0.5715rem]': props.orientation == 'horizontal',
        'left-[50%] mb-[-0.5715rem] ml-[-0.4715rem]': props.orientation == 'vertical'
      },

      // Shape
      'rounded-full',
      'border-2',

      // Colors
      'bg-surface-0 dark:bg-surface-600',
      'border-primary-500 dark:border-primary-400',

      // States
      'hover:bg-primary-500 hover:border-primary-500',
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring',
      'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',

      // Transitions
      'transition duration-200',

      // Misc
      'cursor-grab',
      'touch-action-none'
    ]
  })
}
