// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers';
import CostItem from './CostItem';


export default {
  type: 'tax',

  skipAudit: true,

  possibleStatuses: ['a', 'h'],

  fields: {
    tax_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    province_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'province',
    },
    tax_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    oTaxSettings: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: CostItem.taxSettingsDefaulter,
    },
    tax_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
    tax_percentage: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
    },
    tax_is_default: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
    },
    tax_status: {
      type: 'string',
      filter: false,
      format: 'status',
      mapTo: false,
    },
  },
};
