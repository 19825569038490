import { useStore } from 'vuex'
import { toRefs, ref, reactive, computed } from 'vue'
import AdyenCheckout from '@adyen/adyen-web'
import usePaymentMethod from './PaymentMethod'
import usePayment from './Payment'
import useTranslation from './Translation'
import useInvoice from './Invoice'

export const initialState = {
  clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
  ...(import.meta.env.VITE_ADYEN_ENV === 'test' ? { environment: 'test' } : {})
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const { l } = useTranslation()

  const {
    fetchPaymentMethods,
    getFeeBasedOnCardBrand,
    removeStoredPaymentMethod,
    locale,
    verification3D,
    validationErrorMessage,
    paymentMethods,
    bankAccounts,
    creditCards,
    paymentMethod,
    brand,
    specialtyCards,
    paymentMethodVariants
  } = usePaymentMethod()

  const { fees, isValid, fetchPaymentDetails, makeItemizedPayment, makeItemizedInvoice, checkout } =
    usePayment()

  const { invoiceGross } = useInvoice()

  const store = useStore()

  const firstSix = ref(null)

  const counterpartyCountry = computed(() =>
    store ? store.state.session.company.country_abbr : 'us'
  )

  /**
   * Get all the payment methods from adyen and sort them
   */
  const getPaymentMethods = async () => {
    const { paymentMethods: methods, storedPaymentMethods: savedMethods } =
      await fetchPaymentMethods(null, invoiceGross.value)
    paymentMethods.value = methods
    if (!savedMethods) return
    creditCards.value = savedMethods.filter(({ type }) => type === 'scheme')
    bankAccounts.value = savedMethods.filter(({ type }) => type === 'ach')
  }

  /**
   * Handle the change of credit card component
   */
  const handleOnChange = (state) => {
    const { paymentMethod: method, browserInfo: browserData } = state.data
    fees.value.card = getFeeBasedOnCardBrand(method.brand)
    if (!state.isValid) {
      isValid.value = false
      return
    }
    // is a stored credit card
    checkout.value.browserInfo = browserData
    const savedMethod = creditCards.value.find((card) => card.id === method.storedPaymentMethodId)
    if (savedMethod) paymentMethodVariants.value = savedMethod.subVariants || null
    adjustFeeBasedOnVariant()
    // add additional details to method
    paymentMethod.value = {
      ...method,
      ...(savedMethod ? { ...savedMethod } : {}),
      firstSix: firstSix.value
    }
    checkout.value.paymentMethod = { ...method }
    // can make payment
    checkout.value.isValid = true
    // can confirm payment
    isValid.value = true
  }

  /**
   * If we need additional adyen component details
   */
  const handleOnAdditionalDetails = () => {}

  /**
   * Handle disabling stored payment method by removing it
   */
  const handleOnDisableStoredPaymentMethod = async (storedPaymentMethodId, resolve, reject) => {
    if (storedPaymentMethodId) {
      const res = await removeStoredPaymentMethod(storedPaymentMethodId)
      if (res && !res.error) return resolve()
    }
    return reject()
  }

  /**
   * Handle component error, we do not currently use ach component
   */
  const handleOnError = () => {}

  /**
   * Handle if the card needs 3ds auth
   */
  const handle3DSecure = async (state) => {
    const { payload } = await store.dispatch('ajax', {
      path: '/payfac/submitDetailsForPayment',
      data: {
        details: state.data.details
      }
    })
    const { resultCode, refusalReason } = payload
    if (resultCode === 'Authorised') {
      verification3D.value = true
    } else if (resultCode === 'Cancelled') {
      validationErrorMessage.value = l('The transaction authorization has been cancelled')
    } else if (resultCode === 'Error' || resultCode === 'Refused') {
      validationErrorMessage.value = refusalReason
    }
  }

  /**
   * Use the bin value lookup so we can show numbers representing the card
   * @param {*} data
   */
  const handleOnBinValue = (data) => {
    const { binValue } = data
    firstSix.value = binValue
  }

  const onBrand = () => {
    if (specialtyCards.value.includes(brand.value)) {
      fees.value.card = fees.value.specialtyCards
    } else {
      fees.value.card = fees.value.standardCards
    }
  }

  /**
   * Use adyen's bin lookup to adjust the fees
   */
  const handleOnBinLookup = (data) => {
    const { paymentMethodVariants: variants, detectedBrands } = data
    brand.value = detectedBrands[0] || null
    paymentMethodVariants.value = null
    // only adjust in the US
    if (counterpartyCountry.value !== 'us') {
      onBrand()
      return
    }
    paymentMethodVariants.value = variants
    if (!fees.value.variantFees || fees.value.variantFees.length === 0) return
    adjustFeeBasedOnVariant()
  }

  /**
   * Based on the set card variants adjust the credit card fee
   */
  const adjustFeeBasedOnVariant = () => {
    // set back to standard
    if (!paymentMethodVariants.value) {
      fees.value.card = fees.value.standardCards
      return
    }
    // find based on variant and set new fee
    const variant = paymentMethodVariants.value.find((v) => fees.value.variantFees[v])
    if (variant) {
      const newFee = fees.value.variantFees[variant]
      fees.value.card = newFee
    } else {
      onBrand()
    }
  }

  /**
   * Handle configuration of the adyen credit card component
   */
  const configureAdyenCreditCardComponent = async () => {
    const cards = paymentMethods.value.filter(({ type }) => type === 'scheme')
    const configure = {
      paymentMethodsResponse: {
        paymentMethods: cards,
        storedPaymentMethods: creditCards.value
      },
      clientKey: context.clientKey,
      locale: locale.value,
      environment: context.environment,
      onChange: handleOnChange,
      onAdditionalDetails: handleOnAdditionalDetails,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: false,
          name: l('Add a new credit card'),
          onBinValue: handleOnBinValue,
          onBinLookup: handleOnBinLookup
        }
      }
    }
    const adyenCheckout = await AdyenCheckout(configure)

    const options = {
      openFirstPaymentMethod: true,
      openFirstStorePaymentMethod: false,
      showRemovePaymentMethodButton: true,
      showPayButton: false,
      onDisableStoredPaymentMethod: handleOnDisableStoredPaymentMethod
    }
    adyenCheckout.create('dropin', options).mount('#adyen-credit-card-payment-element')
  }

  /**
   * Handle configuring stored card component
   */
  const configureAdyenStoredCardsComponent = async () => {
    const selected = creditCards.value.filter((c) => checkout.value.paymentMethod.id === c.id)
    const adyenCheckout = await AdyenCheckout({
      paymentMethodsResponse: {
        storedPaymentMethods: selected
      },
      clientKey: context.clientKey,
      locale: locale.value,
      environment: context.environment,
      onChange: handleOnChange,
      onAdditionalDetails: handleOnAdditionalDetails,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: false,
          name: l('Add a new credit card'),
          onBinValue: handleOnBinValue
        }
      }
    })
    const options = {
      openFirstPaymentMethod: false,
      openFirstStorePaymentMethod: true,
      showRemovePaymentMethodButton: false,
      showPayButton: false,
      showPaymentMethods: false,
      onDisableStoredPaymentMethod: handleOnDisableStoredPaymentMethod
    }
    adyenCheckout.create('dropin', options).mount('#stored-card')
  }

  /**
   * Handle configuration of the ACH component, we do not currently use this and use plaid
   */
  const configureAdyenACHComponent = async () => {
    const accounts = paymentMethods.value.filter(({ type }) => type === 'ach')
    const configure = {
      paymentMethodsResponse: {
        paymentMethods: accounts,
        storedPaymentMethods: bankAccounts.value
      },
      clientKey: context.clientKey,
      locale: locale.value,
      environment: context.environment,
      onChange: handleOnChange,
      onError: handleOnError,
      onAdditionalDetails: handleOnAdditionalDetails
    }

    const adyenCheckout = await AdyenCheckout(configure)
    const options = {
      openFirstStorePaymentMethod: false,
      showRemovePaymentMethodButton: true,
      showPaymentMethods: false,
      showPayButton: false,
      onDisableStoredPaymentMethod: handleOnDisableStoredPaymentMethod
    }
    adyenCheckout.create('dropin', options).mount('#adyen-ach-payment-element')
  }

  /**
   * Kickoff the adyen initialization
   */
  const adyenInitialize = async () => {
    fetchPaymentDetails(invoiceGross.value)
    getPaymentMethods()
  }

  /**
   * After payment is created a custom callback function mostly to handle 3ds
   */
  const makePaymentCallback = async (payload) => {
    if (!payload.error && !payload.details) return

    const adyenCheckout = await AdyenCheckout({
      clientKey: context.clientKey,
      locale: locale.value,
      environment: context.environment,
      onAdditionalDetails: handle3DSecure
    })
    const newCheckoutComponent = document.getElementById('3d-verification-newTab')
    adyenCheckout.createFromAction(payload.details).mount(newCheckoutComponent)
  }

  const adyenMakeItemizedPayment = () => makeItemizedPayment(makePaymentCallback)

  const adyenMakeItemizedInvoice = () => makeItemizedInvoice()

  const adyenInitializePaymentMethods = () => {
    configureAdyenCreditCardComponent()
  }

  return {
    ...toRefs(context),
    getPaymentMethods,
    configureAdyenCreditCardComponent,
    adyenInitializePaymentMethods,
    adyenMakeItemizedPayment,
    adyenMakeItemizedInvoice,
    configureAdyenACHComponent,
    adyenInitialize,
    handle3DSecure,
    makePaymentCallback,
    configureAdyenStoredCardsComponent
  }
}
