<template>
  <span class="dimensions-badge--container">
    <template v-if="hasParent">
      <font-awesome-icon icon="link" class="text-muted" v-if="!fullyLinked" />
      <font-awesome-icon icon="drafting-compass" class="text-danger" v-else />
    </template>
    <span
      @click="(e) => $emit('click', e)"
      @tap="(e) => $emit('click', e)"
      v-tooltip="'Edit dimensions..'"
      class="more ml-1"
      :class="{
        danger: fullyLinked || !hasParent,
        info: !fullyLinked && partiallyLinked
      }"
      v-if="activeDimensions > 0"
      style="position: relative"
    >
      <span style="position: absolute; top: 0.4em; left: 42.5%; font-size: 0.7em">{{
        activeDimensions
      }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'DimensionsBadge',
  emits: ['click'],
  computed: {},
  props: {
    hasParent: {
      default: true
    },
    activeDimensions: {
      default: 0
    },
    partiallyLinked: {
      type: Boolean,
      default: false
    },
    fullyLinked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.dimensions-badge--container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .fa {
    top: 0;
    font-size: 0.7em;
  }
}
</style>
