
export default {
  type: 'unit_of_measure_conversion',
  
  skipAudit: true,

  fields: {
    unit_of_measure_conversion_id: {
      filter: true,
      type: 'int',
      format: false,
      mapTo: false
    },
    unit_of_measure_conversion_from: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false
    },
    unit_of_measure_conversion_to: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false
    },
    unit_of_measure_conversion_formula: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false
    }
  },
};
