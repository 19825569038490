<template>
  <component :is="asLink ? 'a' : 'btn'" @click="handleVerify" id="verify-button">
    <span v-if="!$slots.default">Verify document</span>
    <slot></slot>
  </component>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'DocumentVerification',
  emits: ['verified'],
  data() {
    return {
      stripe: null,
      verificationSession: null,
      clientSecret: null
    }
  },
  components: {},
  methods: {
    /**
     * Load stripe js
     */
    loadStripeClient() {
      if (this.stripe) return this.stripe
      return loadStripe(import.meta.env.VITE_STRIPE_KEY)
    },
    /**
     * In order to verify the document we must first create a session
     */
    async createSession() {
      return new Promise(async (resolve, reject) => {
        try {
          // we need to first create a verification session which returns client secret
          const { payload } = await this.$store.dispatch('ajax', {
            path: '/payfac/createVerificationSession',
            data: {
              owner: this.owner
            }
          })

          if (payload.error) throw payload.error

          this.verificationSession = payload
          resolve(this.verificationSession.client_secret)
        } catch (e) {
          this.$store.dispatch('alert', {
            message: e.message || 'Could not create verification session.',
            error: true
          })
          reject(e)
        }
      })
    },
    /**
     * Handle the verification
     */
    async handleVerify() {
      try {
        this.stripe = await this.loadStripeClient()
        this.clientSecret = await this.createSession()

        // use stripe js to verify the session
        const result = await this.stripe.verifyIdentity(this.clientSecret)
        // when user closes modal it sends error with session cancelled code
        if (result.error && result.error.code === 'session_cancelled') return

        // if there is an error alert otherwise emit verified event,
        // webhook will update verification status on our end
        if (result.error) {
          this.$store.dispatch('alert', {
            message: result.error.message,
            error: true
          })
          return
        }

        this.$store.dispatch('alert', {
          message: 'Document is being verified, this can take a few minutes'
        })
        // emit the verification is done
        this.$emit('verified')
      } catch (e) {
        this.$store.dispatch('alert', {
          message: 'Could not verify document',
          error: true
        })
      }
    }
  },
  props: {
    asLink: {
      type: Boolean,
      default: false
    },
    owner: {
      type: String
    }
  }
}
</script>
