<template>
  <div class="flex flex-row justify-between items-center min-h-20 px-4 py-2">
    <div class="max-w-[75%]">
      <strong>{{ permission.permission_name }}</strong>
      <small class="text-muted block" v-if="$store.state.session.deviceWidth > 500">{{
        permission.permission_desc
      }}</small>
    </div>

    <div class="flex justify-start *:mr-4 *:w-16">
      <div>
        <btn
          @click="toggleSelf"
          :severity="localPermission.role_permission_self ? 'success' : 'secondary'"
          rounded
        >
          <icon :icon="localPermission.role_permission_self ? 'circle-check' : 'ban'" />
        </btn>
      </div>
      <div>
        <btn
          @click="toggleOthers"
          :severity="localPermission.role_permission_others ? 'success' : 'secondary'"
          rounded
        >
          <icon :icon="localPermission.role_permission_others ? 'circle-check' : 'ban'" />
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  emits: ['changed'],
  data() {
    return {
      localPermission: null
    }
  },
  watch: {},
  methods: {
    toggleSelf() {
      this.localPermission.role_permission_self =
        String(this.localPermission.role_permission_self) === '1' ? 0 : 1
      const rolePermission = {
        ...this.localPermission,
        permission_id: this.localPermission.permission_id,
        role_permission_self: this.localPermission.role_permission_self
      }
      this.save(rolePermission)
    },
    toggleOthers() {
      this.localPermission.role_permission_others =
        String(this.localPermission.role_permission_others) === '1' ? 0 : 1
      const rolePermission = {
        ...this.localPermission,
        permission_id: this.localPermission.permission_id,
        role_permission_others: this.localPermission.role_permission_others
      }
      this.save(rolePermission)
    },
    save(rolePermission) {
      this.$emit('changed', rolePermission)
    }
  },
  components: {},
  props: {
    permission: {
      required: true
    },
    index: {
      required: true
    }
  },
  created() {
    this.localPermission = { ...this.permission }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
