const separator = '|'
const undoRedoItems = ['undo', 'redo']
const textStyleItems = ['bold', 'italic', 'underline', 'strikethrough']
const basicListItems = ['bulletedList', 'numberedList']
const indentItems = ['indent', 'outdent']
export const basicToolbarItems = [
  ...undoRedoItems,
  separator,
  ...textStyleItems,
  separator,
  ...basicListItems,
  ...indentItems,
  'alignment',
  separator,
  'fontSize'
]
export const fullToolbarItems = [
  ...undoRedoItems,
  separator,
  'heading',
  separator,
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  separator,
  ...textStyleItems,
  separator,
  'alignment',
  separator,
  'specialCharacters',
  'link',
  'insertImage',
  'mediaEmbed',
  'insertTable',
  'blockQuote',
  separator,
  ...basicListItems,
  'todoList',
  ...indentItems
]
