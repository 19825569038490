import ComputedStyleToInlineStyle from 'computed-style-to-inline-style'

export default {
  methods: {
    exportToMicrosoftWord(element, fileName = 'mydoc') {
      const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
      const postHtml = '</body></html>'

      const clone = element.cloneNode(true)
      ComputedStyleToInlineStyle(clone, {
        recursive: true
      })

      console.log('inner', clone.innerHTML)

      const html = `${preHtml}${clone.innerHTML}${postHtml}`.replace(/<(\\?)div([^a-z])/, '<$1p$2')

      const blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      })

      // Specify link url
      const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`

      // Specify file name
      const name = `${fileName || 'mydoc'}.doc`

      // Create download link element
      const downloadLink = document.createElement('a')

      document.body.appendChild(downloadLink)

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, name)
      } else {
        // Create a link to the file
        downloadLink.href = url

        // Setting the file name
        downloadLink.download = name

        // triggering the function
        downloadLink.click()
      }

      document.body.removeChild(downloadLink)
    }
  }
}
