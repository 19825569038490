export default {
  name: 'ItemizedListMixin',
  props: {
    item: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    normalized: {
      type: Object
    },
    selected: {
      type: Array,
      default: () => []
    },
    invoiced: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    /**
     * Check to see if checkbox is selected
     * @returns {Boolean}
     */
    isSelected() {
      return [...this.invoiced, ...this.selected].includes(this.item.item_id)
    },
    /**
     * Check to see if the item is already invoiced
     * @returns {Boolean}
     */
    isInvoiced() {
      return this.invoiced.includes(this.item.item_id)
    }
  },
  methods: {}
}
