import { forceBaseValues } from '@/router/guards/beforeEnter'
const AccountingCallback = () => import('@/components/pages/AccountingCallback.vue')
const AutoCost = () => import('@/components/pages/AutoCost.vue')
const Booked = () => import('@/components/pages/Booked.vue')
const CloudOfficeManager = () => import('@/components/pages/CloudOfficeManager.vue')
const Files = () => import('@/components/pages/Files.vue')
const Home = () => import('@/components/pages/Home.vue')
const NotFoundPage = () => import('@/components/pages/NotFound.vue')
const Notifications = () => import('@/components/pages/Notifications.vue')
const Profile = () => import('@/components/pages/Profile.vue')

export default [
  {
    path: '/booked',
    component: Booked,
    meta: {
      confirmLeave: false,
      scopesAllowed: ['user', 'company', 'quote']
    }
  },
  {
    path: '/feature/autocost',
    name: 'AutoCost Feature',
    component: AutoCost,
    beforeEnter: [forceBaseValues],
    meta: {
      public: true
    }
  },
  {
    path: '/feature/com',
    name: 'Cloud Office Managers Feature',
    component: CloudOfficeManager,
    beforeEnter: [forceBaseValues],
    meta: {
      public: true
    }
  },
  {
    path: '/user/:id?',
    name: 'Profile',
    alias: ['/:scopeRoute/user/:id?', '/profile/:id?'],
    component: Profile,
    meta: {
      confirmLeave: true,
      scopesAllowed: ['user', 'company'],
      menuContext: 'user'
    }
  },
  {
    path: '/',
    name: 'Home',
    alias: ['/:scopeRoute/home', '/home'],
    component: Home,
    meta: {
      scopesAllowed: ['user']
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/accounting/callback',
    name: 'Accounting Export Callback',
    component: AccountingCallback,
    meta: {
      scopesAllowed: ['user', 'company']
    }
  },
  {
    path: '/files',
    name: 'User Files',
    component: Files,
    meta: {
      scopesAllowed: ['user', 'company'],
      fixedLayout: true
    }
  },
  {
    path: '/:notFound(.*)*',
    name: 'NotFound',
    component: NotFoundPage
  }
]
