<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #primary>
      {{ object.lead_rotation_name }}
    </template>
    <template #secondary>
      <strong class="text-muted">Leads get sent to: </strong>
      <strong class="text-info">&nbsp; {{ (object.aoUserNames || []).join(', ') }}</strong>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewLeadRotation',
  emits: ['click'],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    Preview
  },
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>
