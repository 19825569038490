import * as types from '../../mutation-types'

const state = {
  sources: {},
  companySettings: {},
  userSettings: {},
  virtualAccounts: {}
}

const getters = {
  directPayEnabled() {
    return false
  }
}

const actions = {
  async saveCompanyDirectPaySettings() {},
  async connectCreditCard({ dispatch }, payload) {
    const {
      number,
      expMonth,
      expYear,
      cid,
      name,
      postal = null,
      country = rootState.session.user.country_id,
      to = 'user'
    } = payload
  },
  async connectBankAccount({ dispatch, rootState }, payload) {
    const {
      number,
      transit,
      institution,
      institutionName = null,
      accountName = null,
      country = rootState.session.user.country_id,
      to = 'user'
    } = payload
  },
  async fundClientVirtualAccount({ dispatch }, payload) {},
  async chargeClient({ dispatch }, payload) {},
  async refundTransaction({ dispatch }) {},
  async mapItemsToSplits({ dispatch, rootState }, payload) {
    const {
      items,
      createProfitSplit = true,
      recordOnly = true,
      /**
       * 'weighted' or 'ordered'
       *  - ordereed alots funds from first item until total amount runs out
       *  - weighted alots funds to each item based on overall % of that item
       *    in the set of all items
       */
      payItems = 'weighted' // weighted or ordered
    } = payload

    let vendorItems = []
    let inhouseItems = []
    const inhouseAccount = `company-${rootState.session.company.company_id}`
    items.forEach((item) => {
      if (item.type === 'cost_item' && item.vendor_id) {
        vendorItems.push(item)
      } else {
        inhouseItems.push(item)
      }
    })
    const vendorAccountIds = await dispatch('Vendor/getAccounts', {
      vendorIds: _.cleanArray(vendorItems.map((i) => i.vendor_id))
    })

    let vendorSplits = []
    let profitSplits = []
    vendorItems.forEach((i) => {
      vendorSplits.push(
        c.buildDefaultObject('split', {
          item_id: i.vendor_item_id,
          split_amount: _.n(i.cost_item_cost_tax) + _.n(i.cost_item_total_cost_net),
          split_tax: _.n(i.cost_item_cost_tax),
          destination_account_id: vendorAccountIds[String(i.vendor_id)]
        })
      )
      if (createProfitSplit) {
        profitSplits.push(
          c.buildDefaultObject('split', {
            item_id: i.item_id,
            split_amount: _.n(i.cost_item_profit_net) + _.n(i.cost_item_profit_tax),
            split_tax: _.n(i.cost_item_profit_tax),
            destination_account_id: inhouseAccount
          })
        )
      }
    })

    let inhouseSplits = []
    inhouseItems.forEach((i) => {
      inhouseSplits.push(
        c.buildDefaultObject('split', {
          source_item_id: i.item_id,
          item_id: i.item_id,
          split_amount: _.n(i.cost_item_gross),
          split_tax: _.n(i.cost_item_tax),
          destination_account_id: inhouseAccount
        })
      )
    })

    inhouseItems
    const vendorCostSplits = vendorItems.map((i) =>
      c.buildDefaultObject('split', {
        item_id: i.vendor_item_id,
        split_amount: _.n(i.cost_item_cost_tax) + _.n(i.cost_item_total_cost_net),
        split_tax: _.n(i.cost_item_cost_tax),
        destination_account_id: vendorAccountIds[String(i.vendor_id)]
      })
    )
    const vendorCostSplitTotalAmount = vendorCostSplits.reduce(
      (acc, vc) => vc.split_amount + acc,
      0
    )
    const totalItemAmount = items.reduce((acc, i) => acc + _.n(i.cost_item_gross))
  }
}

const mutations = {
  setCompanySettings(state, { settings }) {
    state.companySettings = _.imm(settings)
  },
  setUserSettings(state, { settings }) {
    state.companySettings = _.imm(settings)
  },
  setAccountBalance(state, { accountId, balance }) {
    state.accounts = {
      ...state.accounts,
      [accountId]: {
        ...state.accounts[accountId],
        account_balance: balance
      }
    }
  },
  setAccounts(state, { accounts }) {
    state.accounts = _.imm(accounts)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
