export default {
  type: 'custom_field_value',

  skipAudit: true,

  fields: {
    custom_field_value_id: {
      type: 'int'
    },
    custom_field_value_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    custom_field_value_time_created: {
      type: 'int'
    },
    custom_field_value_time_last_modified: {
      type: 'int'
    },
    custom_field_value_status: {
      type: 'int'
    },
    custom_field_value_input: {
      type: 'string'
    },
    custom_field_id: {
      type: 'int'
    },
    user_id: {
      type: 'int'
    },
    client_id: {
      type: 'int'
    },
    form_id: {
      type: 'int'
    }
  }
}
