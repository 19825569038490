<template>
  <div
    :style="
      !editable && !filteredList.length
        ? {
            padding: 0,
            margin: 0,
            height: 0,
            width: 0
          }
        : {}
    "
    :class="editable ? 'addon_list-editing' : ''"
  >
    <fade>
      <div v-if="(scrolledIn && filteredList.length) || editable">
        <div class="addon_list-title" v-if="title">
          <font-awesome-icon
            icon="chevron-double-up"
            class="text-info"
            style="font-size: 2em"
            fixed-width
          />
          <SuggestionButton v-if="title" class="info">
            <template v-if="totalChose > 0"
              >{{ totalChose }} {{ l('other customers recently upgraded their') }}
              {{ $f.truncate(title, 60) }}</template
            >
            <template v-else>{{ l('Other options for') }} {{ $f.truncate(title, 60) }}</template>
          </SuggestionButton>
        </div>
        <!--    Selected addon    -->
        <div class="flex items-center">
          <div class="md:pr-8 md:mr-8 md:border-r border-surface-100 py-4">
            <div
              v-if="selectedAddon !== null && !isAddonGroup"
              class="hidden md:flex flex-col items-center justify-center z-8 w-52 lg:w-64 h-80 lg:h-[341px] cursor-pointer !border-2 rounded-lg bg-flame-white border-blue-print-400 overflow-hidden"
              @click="selectedAddon.viewDetails"
            >
              <div
                class="relative h-3/5 w-full bg-cover bg-no-repeat"
                v-if="selectedAddon.addonImage"
                :style="{
                  backgroundImage: `url(${selectedAddon.addonImage})`
                }"
              >
                <div
                  class="absolute top-2 left-2 bg-blue-print-400 text-flame-white text-xs px-1 py-0.5 rounded"
                >
                  Selected
                </div>
              </div>
              <div
                class="relative flex items-center justify-center h-3/5 w-full bg-surface-100 rounded-t-lg"
                v-else
              >
                <font-awesome-icon :icon="['fas', 'cube']" class="text-7xl text-surface-200" />
                <div
                  class="absolute top-2 left-2 bg-blue-print-400 text-flame-white text-xs px-1 py-0.5 rounded"
                >
                  Selected
                </div>
              </div>
              <div
                class="h-2/5 flex flex-col gap-1 items-center justify-center w-full p-4 text-center"
              >
                <span
                  class="text-left text-base min-h-12 h-12 max-h-12 w-full overflow-hidden mb-3"
                  >{{ selectedAddon.addon.name }}</span
                >
                <Btn
                  severity="tertiary"
                  class="w-full"
                  size="item"
                  @click="selectedAddon.viewDetails"
                >
                  <span>View details</span>
                </Btn>
              </div>
            </div>
            <div
              v-else
              class="hidden md:flex items-center justify-center p-6 z-8 min-w-64 max-w-64 min-h-[341px] cursor-pointer !border-2 border-transparent rounded-lg bg-surface-100"
            >
              <span class="text-center text-surface-400">Make a selection from the right</span>
            </div>
          </div>
          <!--    Upgrade options container      -->
          <div class="relative flex-col overflow-auto">
            <span
              class="sticky left-0 mb-2"
              :class="{ 'hidden md:flex': isAddonGroup, flex: !isAddonGroup }"
            >
              {{ optionsNumber }} more {{ optionsNumber === 1 ? 'option' : 'options' }}
            </span>
            <span
              class="sticky left-0"
              :class="{ 'flex md:hidden mb-3': isAddonGroup, hidden: !isAddonGroup }"
            >
              Make a selection below
            </span>
            <div
              class="max-w-80 md:max-w-full flex items-center justify-start gap-4 md:gap-6 pb-4"
              ref="scrollContainer"
            >
              <template v-for="(addon, index) in filteredList" :key="index">
                <quote-presentation-addon-item
                  :targetRefId="reference"
                  :currentPrice="currentPrice"
                  :editable="editable"
                  :addon="addon"
                  :dimensions="dimensions"
                  @number-chose="(chval) => countHandler(index, chval)"
                  @disabled="() => disableAddon(addon)"
                  @addon="(modified) => (index > 0 ? modifyAddon(addon, modified) : null)"
                  @get="(object) => get(addon)"
                  @selected-addon="setSelectedAddon"
                />

                <div v-if="editable" class="addon_list-item-options">
                  <btn rounded class="lg more px-0 p-2 danger" @click="() => removeAddon(addon)">
                    &times;
                  </btn>
                </div>
              </template>

              <div class="addon_list-options" v-if="editable">
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="upgrades && target.type !== 'quote'"
                  ref="upgradeTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addUpgradeItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add upgrade item
                    </btn>
                  </template>
                </traverse>
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="optionals && (target.type === 'assembly' || target.type === 'quote')"
                  ref="optionalTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addOptionalItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add optional item
                    </btn>
                  </template>
                </traverse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fade>
  </div>
</template>

<script>
import QuotePresentationAddonItem from './QuotePresentationAddonItem.vue'
import BtnMixin from '../../mixins/Button'
import AddonListMixin from '../addons/AddonListMixin'
import SuggestionButton from '../../ui/SuggestionButton.vue'
import TranslationMixin from './languages/TranslationMixin'

/**
 * Emits:
 * -countItems number of items in filtered list
 * -PresentationMoreEmphasis (refId)
 */
export default {
  name: 'QuotePresentationAddonList',
  mixins: [BtnMixin, AddonListMixin, TranslationMixin],
  components: {
    SuggestionButton,
    QuotePresentationAddonItem
    /* ChangeAudit, */
  },
  emits: [''],
  data() {
    return {
      numberChose: [],
      selectedAddon: null
    }
  },
  computed: {
    totalChose() {
      return Object.values(this.numberChose).reduce((acc, num) => acc + c.toNum(num || 0), 0)
    },
    isAddonGroup() {
      return (
        this.selectedAddon?.addon.isgrp ||
        (!this.selectedAddon?.addon.id && !this.selectedAddon?.addon.livePriceRef)
      )
    },
    optionsNumber() {
      return this.filteredList.filter((addon) => {
        const id = addon.id ?? addon.livePriceRef
        return id !== this.selectedAddon?.addon.id && id !== this.selectedAddon?.addon.livePriceRef
      }).length
    }
  },
  methods: {
    countHandler(index, count = 0) {
      const ch = _.immutable(this.numberChose)
      ch[index] = count
      this.numberChose = ch
    },
    setSelectedAddon(item) {
      const object = this.filteredList.find((addon) =>
        item.addon.livePriceRef
          ? item.addon.livePriceRef === addon.livePriceRef
          : addon.id === item.addon.id
      )
      if (object) {
        this.selectedAddon = { ...item, addon: { ...object } }
        if (object.image_external)
          this.selectedAddon.addonImage = `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${object.image_external}`
      }
    }
  },
  props: {
    showCurrent: {
      default: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
