<template>
  <div v-if="templateFull" class="grid grid-cols-1 md:gap-4 md:grid-cols-4 w-full h-full">
    <div class="col-span-1 flex items-center md:border-r border-cool-gray-900 h-full">
      <span class="text-flame-white hidden md:block text-base font-medium w-full"
        >Presentation editor</span
      >
    </div>
    <div class="flex justify-between items-center col-span-3 px-4">
      <span class="gap-2 hidden md:flex">
        <choose
          :key="`settings${presentationTemplateId}`"
          data-label="choose_presentation"
          :return-array="false"
          schema="quote:presentation_template_id"
          :filters="{ template_type_id: 101 }"
          :allowCreate="false"
          v-model="presentationTemplateId"
          :multiple="false"
          :allowDeselect="false"
        >
          <template #default="{ text }">
            <Btn
              :pt="{ root: '!justify-start' }"
              size="lg"
              severity="dark"
              :stop="false"
              :prevent="false"
              :loading="loading || bodyLoading"
            >
              <template #icon>
                <font-awesome-icon icon="keynote" fixed-width />
              </template>
              {{ text || 'Default' }}
              <span class="caret-down"></span>
            </Btn>
          </template>
        </choose>
        <Btn size="lg" severity="primary-black" :action="newPresentationTemplate">
          Create template
        </Btn>
      </span>
      <span class="gap-2 flex w-full justify-end">
        <Btn
          severity="primary-black"
          size="lg"
          :disabled="!unsavedChanges"
          :action="() => (templateFull.company_id ? saveTemplate() : savePresentationTemplateNew())"
        >
          <template #icon>
            <font-awesome-icon icon="floppy-disk" />
          </template>
          Save {{ templateFull.company_id ? 'changes' : 'as new' }}
        </Btn>
        <Btn severity="secondary" class="ml-2" size="lg" :action="cancel">
          <template #icon>
            <font-awesome-icon icon="times" />
          </template>
          <spam class="hidden md:block">Close</spam>
        </Btn>
      </span>
    </div>
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
    <div class="col-span-1">
      <Skeleton height="2.5em" width="100%" class="!bg-pitch-black" />
    </div>
    <div class="col-span-3">
      <Skeleton height="2.5em" width="100%" class="!bg-pitch-black" />
    </div>
  </div>
</template>

<script>
import PresentationMixin from './PresentationMixin'
import ObjectDistinct from '../../mixins/ObjectDistinct'
import Skeleton from 'primevue/skeleton'

export default {
  name: 'QuotePresentationSettingsHeader',
  mixins: [ObjectDistinct('quote'), PresentationMixin],
  emits: ['cancel'],
  components: {
    Skeleton
  },
  methods: {
    async cancel() {
      await this.loadTemplate(this.presentationTemplateId)
      this.$emit('cancel')
    }
  },
  mounted() {
    this.loadTemplate(this.presentation_template_id)
  },
  watch: {
    oPresentationSettings(a) {
      if (!this.templateFull || !this.templateFull.oMeta) return

      const original = {
        ...this.templateFull
      }
      const current = {
        ...this.templateFull,
        oMeta: a
      }

      const eq = c.jsonEquals(original.oMeta, current.oMeta, true)
      if (!eq) {
        this.unsavedChanges = 1
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
