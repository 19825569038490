// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate


export default {
  type: 'company_type',
  
  skipAudit: true,

  fields: {
    company_type_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
    },
    company_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
    },
  },
};
