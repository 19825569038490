const state = {}

const getters = {}

const actions = {
  /**
   * Payload must include:
   * -type entity type ie: file
   * -id entity id ie: 123
   * and any other props you want
   * passed to the bodies/Sign.vue component
   * @param dispatch
   * @param payload
   * @returns {Promise<any>}
   */
  toFile({ dispatch }, payload) {
    return new Promise((resolve) => {
      let closeModal
      dispatch(
        'modal/open',
        {
          modal: {
            ...payload,
            name: 'sign',
            saved(file) {
              dispatch('modal/close', { modal: closeModal }, { root: true }).then(() =>
                resolve({ ...payload, file, object: file })
              )
            }
          }
        },
        { root: true }
      ).then(({ modal }) => {
        closeModal = modal
      })
    })
  },
  /**
   * dispatch('sign/termsAndConditions', { additionalTerms: 'Must be a little weird' })
   *  .then(({ terms }) => terms);
   * @param dispatch
   * @param payload
   * @returns {Promise<any>}
   */
  termsAndConditions({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let closeModal
      let saved = 0
      dispatch(
        'modal/open',
        {
          modal: {
            ...payload,
            name: 'TermsAndConditions',
            go: false,
            saved(terms, signature) {
              saved = 1
              dispatch('modal/close', { modal: closeModal }, { root: true }).then(() =>
                resolve({ termsAccepted: terms, terms, signature })
              )
            },
            closed() {
              if (!saved) {
                dispatch('modal/close', { modal: closeModal }, { root: true }).then(() =>
                  reject('Declined')
                )
              }
            }
          }
        },
        { root: true }
      ).then(({ modal }) => {
        closeModal = modal
      })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
