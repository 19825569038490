export default {
  icon: 'sparkles',
  title: 'Getting started with Bolster estimating',
  desc: 'Learn how to add an item, create an assembly and drag and drop.',
  time: '2 min',
  id: 'Basics',
  setupSheet: async (sheet) => {
    sheet.addRow()
    sheet.resetColWidths()
    ;[0, 1, 2, 3, 4, 5, 6, 7].map((i) => sheet.uncollapseSuperHeader(0, i))
    ;[1, 3, 6].map((i) => sheet.collapseSuperHeader(0, i))
    sheet.triggerRedraw()
  },
  steps: [
    {
      icon: 'pencil',
      tooltip:
        'Enter a name for your first item in this estimate, then press the Tab button on your keyboard, or click next below to continue.',
      cell: ['name', 0],
      position: 'right'
    },
    {
      icon: 'ruler-triangle',
      tooltip:
        'Now, choose a unit of measure for this item, or press Tab on your keyboard or click next below to continue.',
      cell: ['units', 0],
      focus: false
    },
    {
      icon: 'money-bill-1-wave',
      tooltip: 'Now, add a unit cost for this item. This is what this item costs you, per 1 unit.',
      cell: ['ucost', 0],
      focus: false
    },
    {
      icon: 'sack-dollar',
      tooltip:
        'With your default profit, this is what the price comes out to per unit. Change the profit or price directly if you want.',
      cell: ['price', 0],
      focus: false
    },
    {
      icon: 'hashtag',
      tooltip: 'Set your quantity for how many items you would like to include in this estimate.',
      cell: ['qty', 0],
      focus: false,
      position: 'left'
    },
    {
      icon: 'sack-dollar',
      tooltip:
        "With the quantity you've set, this is what the price comes out to. You can also change this directly if you like, and it will adjust the markup accordingly.",
      cell: ['price', 0],
      focus: false,
      position: 'left'
    },
    {
      icon: 'plus',
      tooltip:
        'Hover your mouse around here to reveal the row options. Once it appears, click the [fa-solid fa-plus] button to add another item.',
      cell: ['gutter', 0],
      offset: [-50],
      focus: false,
      triggerNext: 'addedRows'
    },
    {
      icon: 'pencil',
      tooltip: 'Now name this item as well, or click next to continue.',
      cell: ['name', 1],
      focus: true
    },
    {
      icon: 'fa-cubes',
      tooltip:
        'Now, hover over here again, then click the [fa-solid fa-grip-dots-vertical] button for options, and choose "Turn into assembly".',
      cell: ['gutter', 1],
      offset: [-25],
      focus: false,
      triggerNext: 'turnItemIntoAssembly',
      position: 'right'
    },
    {
      icon: 'cubes',
      tooltip:
        'This becomes a group of items, that you can re-use to save time called an "Assembly". This adopts the name of your item by default. Change it if you\'d like, or click next to continue.',
      cell: ['name', 1],
      focus: true,
      position: 'right'
    },
    {
      icon: 'fa-solid fa-grip-dots-vertical',
      tooltip:
        'Now hover your mouse here again, but this time press your mouse down and hold onto the [fa-solid fa-grip-dots-vertical] button, then drag [hand-back-fist] it below into your new assembly.',
      cell: ['gutter', 0],
      offset: [-25],
      focus: false,
      triggerNext: 'movedRows',
      position: 'right'
    }
  ]
}
