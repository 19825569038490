<template>
  <div class="quote-pres--container absolute inset-0" v-if="!bodyLoading">
    <!-- Welcome message -->
    <mini-modal
      ref="messageWelcome"
      :showCloseButton="false"
      :closeable="true"
      closeClass="success"
      :scrollable="true"
      :pt="{
        header: 'border-b',
        footer: '!border-t'
      }"
    >
      <template #header>
        <div class="flex w-full justify-between pb-4">
          <img v-if="currentScreen.logoFileId" :src="logo" class="max-w-24 max-h-24 rounded-md" />
          <img v-else-if="companyLogoId" :src="companyLogo" class="max-w-24 max-h-24 rounded-md" />
          <h2 v-else>{{ companyName }}</h2>
          <div class="flex flex-col">
            <span class="text-lg font-normal">
              {{ $f.capitalize(quote_name) }}
            </span>
            <span class="text-muted text-base font-normal">
              {{ change_order_name ? `(${change_order_name})` : '' }} •
              {{ $f.date(change_order_time_sent) }}
            </span>
            <div class="mt-3" v-if="currentScreen.showPresenter">
              <div class="flex items-center">
                <div
                  class="h-10 w-10 rounded-full border-cool-gray-200 border"
                  :style="`background-image: url(${userPic});`"
                ></div>
                <div class="flex flex-col ml-3">
                  <span class="text-sm text-cool-gray-500 font-normal block">Presented by</span>
                  <div class="-mt-2">
                    <span class="text-dark text-base">{{ owner.user_fname }}</span>
                    <span class="text-dark ml-2 text-base">{{ owner.user_lname }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <Container>
        <CardSection class="coverLetterChangeOrder py-4">
          <div>
            <div
              class="mb-2"
              v-if="processedCoverLetter && processedCoverLetter.length > 0"
              v-html="processedCoverLetter"
            />
            <div
              :class="`mt-4 pt-4 ${processedCoverLetter && processedCoverLetter.length > 0 ? 'border-t border-dashed' : ''}`"
              v-if="processedMessage && processedMessage.length > 0"
              v-html="processedMessage"
            ></div>
          </div>
        </CardSection>
      </Container>
      <template #footer>
        <Btn size="lg" severity="dark" @click="() => $refs.messageWelcome.close()">
          {{ l('View') }} {{ l(documentName) }}
        </Btn>
      </template>
    </mini-modal>

    <div>
      <!-- Contents -->
      <PresentationContents
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :isInQuoteEditor="isInQuoteEditor"
        :editing="editingLocal"
        :type="type"
        @toggle-edit-toolbar="isEditing = !editingLocal"
        v-model="subComponentInterface"
      />
    </div>

    <!-- Editing -->
    <Sidebar
      v-model:visible="editingLocal"
      position="bottom"
      :modal="false"
      :dismissable="false"
      :showCloseIcon="false"
      :blockScroll="true"
      :pt="{
        root: 'max-h-screen quote-presentation-settings',
        content: '!pb-0 !overflow-y-visible',
        header: 'quote-presentation-settings--header !px-5 !py-0 h-14',
        mask: '!bottom-0 !top-auto quote-presentation-mask'
      }"
      :ptOptions="{ mergeProps: true }"
      style="height: auto"
    >
      <template #header>
        <QuotePresentationSettingsHeader
          v-if="$store.getters.isCompanyUser && editingLocal"
          v-bind="$props"
          :reference="refId"
          :store="storeName"
          :editing="editingLocal"
          @cancel="editingLocal = 0"
          :type="type"
          v-model="subComponentInterface"
        />
      </template>

      <QuotePresentationSettings
        v-if="$store.getters.isCompanyUser && editingLocal"
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :editing="editingLocal"
        @unsaved-changes="unsavedChangesHandler"
        @editing="(b) => (editingLocal = b)"
        :type="type"
        v-model="subComponentInterface"
      />
    </Sidebar>
  </div>
  <div
    v-else
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 100%;
    "
  >
    <LoadingIndicator />
  </div>
</template>

<script>
import Sidebar from 'primevue/sidebar'
import PresentationMixin from './PresentationMixin'
import LoadingIndicator from '../../ui/LoadingIndicator.vue'
import PresentationContents from './PresentationContents.vue'
import ObjectManipulator from '../../mixins/ObjectManipulator'
import QuotePresentationSettings from './QuotePresentationSettings.vue'
import QuotePresentationSettingsHeader from './QuotePresentationSettingsHeader.vue'
import TranslationMixin from './languages/TranslationMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'QuotePresentation',

  mixins: [ObjectManipulator('quote', false), PresentationMixin, TranslationMixin],

  components: {
    QuotePresentationSettings,
    PresentationContents,
    LoadingIndicator,
    Sidebar,
    QuotePresentationSettingsHeader
  },
  computed: {
    processedCoverLetter() {
      return this.coverLetter ? c.processOembedTags(this.parseMessage(this.coverLetter)) : ''
    },
    processedMessage() {
      return this.change_order_message ? this.parseMessage(this.change_order_message) : ''
    }
  },
  methods: {
    async unsavedChangesHandler(template = this.getTemplate()) {
      if (
        await this.$store.dispatch('modal/asyncConfirm', {
          message: 'Do you want to save changes to your presentation template?'
        })
      ) {
        console.log('chose yes')
        await this.saveTemplate(template)
      } else {
        await this.loadTemplate(this.presentation_template_id)
      }
    },
    parseMessage(msg) {
      return msg
        .replace('{quote_type}', this.quote_is_change_order ? 'change order' : 'proposal')
        .replace('{client_name}', this.oClient.client_name)
        .replace('{quote_name}', this.quote_name)
        .replace('{quote_address}', this.quote_address)
    }
  },
  props: {
    isInQuoteEditor: {
      default: false
    }
  },
  mounted() {
    const sel = async () => {
      this.addLoading()
      if (
        this.$store.getters.isGuestUser &&
        String(this.$store.state.session.user.user_id) === String(this.client.client_user_id) &&
        (this.coverLetter || this.change_order_message)
      ) {
        c.throttle(() => {
          this.$refs.messageWelcome.open()
        })

        this.$store.dispatch('ChangeOrder/markMultiple', {
          markAs: 'seen',
          id: this.change_order_id,
          go: false,
          alert: false
        })
      }

      this.originalSettings = this.presentationSettings
    }

    if (!this.selected) {
      eventBus.$once(`${this.uid}-selected`, sel)
    } else {
      sel()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/quote.scss';
@import '@/theme/presentations/structure/quote.scss';

$headingHeight: 50vh;

.element-replacer {
  height: 5em;
  width: 100%;
  background: transparent;
}
.quote-pres--container {
  min-height: 0;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  background-color: $flame-white;

  .quote-pres--fixed-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background: rgba($pitch-black, 0.8);
    z-index: $z-layout + 100;
    padding: 1em;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .quote-pres--fixed-nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 5em;
      height: 100%;

      &.quote-pres--fixed-btn-nav-prev {
        color: $cool-gray-300 !important;
        > a {
          background: $cool-gray-300 !important;
          color: $cool-gray-700 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $pitch-black !important;
            color: $flame-white !important;
          }
        }
      }

      &.quote-pres--fixed-btn-nav-next {
        color: $deep-red-800 !important;
        > a {
          background: $deep-red-400 !important;
          color: $deep-red-800 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $deep-red-800 !important;
            color: $flame-white !important;
          }
        }
      }
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
