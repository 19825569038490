export const statuses = {
  Active: 'a',
  a: 'Active',
  Lead: 'l',
  l: 'Lead',
  Inactive: 'i',
  i: 'Inactive',
  Completed: 'c',
  c: 'Completed',
  Incomplete: 'u',
  u: 'Incomplete',
  Blocked: 'b',
  b: 'Blocked',
  Pending: 'p',
  p: 'Pending',
  Processing: 'pr',
  pr: 'Processing',
  Queued: 'q',
  Declined: 'd',
  d: 'Declined',
  Booked: 'k',
  k: 'Booked',
  Deleted: 'h',
  h: 'Deleted',
  Cancelled: 'x',
  x: 'Cancelled',
  Approved: 'r',
  r: 'Approved',
  Archived: 'v',
  v: 'Archived',
  Outstanding: 'o',
  o: 'Outstanding',
  Paid: 'e',
  e: 'Paid',
  Restricted: 's',
  s: 'Restricted',
  InProgress: 'f',
  'In Progress': 'f',
  f: 'In Progress',
  Closed: 'g',
  g: 'Closed',
  Open: 'j',
  j: 'Open',
  Partial: 't',
  t: 'Partial',
  'Added to Payroll': 'm',
  m: 'Added to Payroll',
  n: 'Not Seen',
  q: 'Seen',
  y: 'Draft',
  Draft: 'y'
}

export const statusColors = {
  a: 'blue',
  l: 'green',
  i: 'cool-gray',
  c: 'cool-gray',
  u: 'yellow',
  b: 'cool-gray',
  p: 'yellow',
  pr: 'yellow',
  d: 'cool-gray',
  k: 'green',
  h: 'cool-gray',
  x: 'cool-gray',
  r: 'green',
  v: 'cool-gray',
  o: 'yellow',
  e: 'matcha',
  s: 'yellow',
  f: 'purple',
  g: 'cool-gray',
  j: 'sucess',
  t: 'yellow',
  m: 'green',
  n: 'green',
  q: 'cool-gray',
  y: 'draft'
}

export default {
  statuses,
  statusColors
}
