/**
 * Service to handle an approval and all aspects
 */

import _ from 'lodash'
import { toRefs, reactive, computed, onMounted, onBeforeUnmount } from 'vue'
import eventBus from '@/eventBus'
import usePayFac from '@/components/composables/PayFac'
import Approval from '@/../imports/api/Approval'
import {
  processApprovalAction,
  processMultipleApprovalActions
} from '@/apollo-client/requests/approval'
import UserError from '../../../imports/api/UserError'

export const initialState = {
  session: {
    id: _.uniqueId(),
    role: '',
    userId: null
  },
  approvedIds: [],
  approvedItems: []
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const { isApproved, isAwaitingApproval, statuses, approvalStatuses } = Approval

  const state = reactive({
    approval: {}
  })

  const { isPayfacEnabled } = usePayFac()

  const isApprovalEnabled = computed(() => isPayfacEnabled.value)

  /**
   * Mostly wrapping approval service and passing the current approver user id from session
   */

  const canApprove = (approvalStep) => Approval.canApprove(approvalStep, context.session.userId)

  const showCheckBox = (approvalStep) => canApprove(approvalStep)

  const isApprover = (approvalStep) => Approval.isApprover(approvalStep, context.session.userId)

  const getApproverFromStep = (approvalStep) =>
    Approval.getApproverFromStep(approvalStep, context.session.userId)

  const isApprovedByApprover = (approval) =>
    Approval.isApprovedByApprover(approval, context.session.userId)

  const isFirstStepOfApproval = (approval) =>
    Approval.isFirstStepOfApproval(approval, context.session.userId)

  const getStepsToApprove = (approval) =>
    Approval.getStepsToApprove(approval, context.session.userId)

  /**
   * Get the steps needing approval by approver
   * @param {Object} approval
   * @param {String} approverUserId
   * @returns
   */
  const getStepsNeedingApprovalByApprover = (approval, approverUserId) => {
    const steps = approval.approvalSteps
    const stepsApproverNeedsToApprove = steps.reduce((acc, step) => {
      const isApprover = step.approvers.find(
        (approver) => approver.userId.toString() === approverUserId.toString()
      )
      if (isApprover && isAwaitingApproval(step.status)) acc = [...acc, step]
      return acc
    }, [])
    return stepsApproverNeedsToApprove
  }

  /**
   * Get the steps approved by approver
   * @param {Object} approval
   * @param {String} approverUserId
   * @returns
   */
  const getStepsApprovedByApprover = (approval, approverUserId) => {
    const steps = approval.approvalSteps
    const stepsApproved = steps.reduce((acc, step) => {
      const isApprover = step.approvers.find(
        (approver) => approver.userId.toString() === approverUserId.toString()
      )
      if (isApprover && isApproved(step.status)) acc = [...acc, step]
      return acc
    }, [])
    return stepsApproved
  }

  /**
   * Handle clicking approve all
   * @param {*} approvalChange
   * @returns
   */
  const forceApprovalChange = (approvalChange, items = [], approval = null) => {
    // find the step to approve and make sure it is for the current user session
    let toApprove = approval || state.approval
    if (!toApprove || Object.keys(toApprove).length === 0) return
    const found = items.find((i) => i.item_id === toApprove.itemId)
    if (!found) return
    toApprove.approvalSteps.forEach((step) => {
      const canBeApproved = canApprove(step)
      if (canBeApproved) addOrRemoveFromApprovedItems(step, approvalChange)
    })
  }

  /**
   * Handle an approval action by checking if they can and format data
   * @param {Object} approval
   * @param {Object} step
   * @param {String} status
   * @returns
   */
  const handleApprovalAction = (approval, step, status) => {
    const isAbleToApprove = canApprove(step)
    if (!isAbleToApprove && status === Approval.statuses.APPROVED) return
    const approver = getApproverFromStep(step)
    if (!approver) return
    return {
      approvalId: approval.id,
      approvalStepId: step.id,
      approverId: approver.id,
      status
    }
  }

  /**
   * Approve all selected items
   */
  const approvalAllSelected = async () => {
    const input = context.approvedItems.reduce((acc, item) => {
      const action = handleApprovalAction(item.approval, item.step, Approval.statuses.APPROVED)
      if (action) acc.push(action)
      return acc
    }, [])
    try {
      const response = await processMultipleApprovalActions({ input })
      // clear after successful approval
      context.approvedItems = []
      context.approvedIds = []
      eventBus.$emit('approval-action-processed', response)
    } catch (e) {
      console.log(e, 'cannot approve')
    }
  }

  /**
   * Process an approval action
   * @param {Object} approval
   * @param {String} status
   * @returns
   */
  const processAction = async (approval, status) => {
    if (!status) throw new UserError('You must pass a status.')
    if (!approval) throw new UserError('You must pass an approval.')
    const steps = Approval.getStepsToApprove(approval, context.session.userId)
    if (!steps || steps.length === 0) return
    const step = steps[0]
    const action = handleApprovalAction(approval, step, status)
    try {
      const response = await processApprovalAction(action)
      eventBus.$emit('approval-action-processed', response)
      return response
    } catch (e) {
      console.log(e, 'ERROR')
    }
  }

  /**
   * Handle toggling items from approved list
   * @param {Object} step
   * @param {Int} value
   * @returns
   */
  const addOrRemoveFromApprovedItems = (step, value) => {
    if (value) {
      context.approvedIds.push(step.id)
      context.approvedItems.push({
        approval: state.approval,
        step
      })
      return
    }
    const index = context.approvedIds.indexOf(step.id)
    context.approvedIds.splice(index, 1)
    context.approvedItems = context.approvedItems.filter((item) => item.step.id !== step.id)
  }

  const clearApproval = () => {
    context.approvedIds = []
    context.approvedItems = []
  }

  onMounted(() => {
    // on approve all select checkboxes
    eventBus.$on(`approve-all-${context.session.id}`, ({ selected, selectedItems }) => {
      forceApprovalChange(selected, selectedItems)
    })
  })

  onBeforeUnmount(() => {
    eventBus.$off(`approve-all-${context.session.id}`)
  })

  return {
    ...toRefs(context),
    ...toRefs(state),
    forceApprovalChange,
    isApprovalEnabled,
    statuses,
    approvalStatuses,
    canApprove,
    getApproverFromStep,
    handleApprovalAction,
    getStepsNeedingApprovalByApprover,
    getStepsApprovedByApprover,
    addOrRemoveFromApprovedItems,
    isApproved,
    showCheckBox,
    isApprover,
    isApprovedByApprover,
    processAction,
    approvalAllSelected,
    isFirstStepOfApproval,
    getStepsToApprove,
    clearApproval
  }
}
