<template>
  <btn
    v-bind="$props"
    class="card-list--button list-group-item"
    :class="{ touch: deviceStore.isTouch }"
    @click="(e) => $emit('click', e)"
  >
    <template #icon>
      <slot name="icon"></slot>
    </template>
    <slot></slot>
  </btn>
</template>

<script>
import Btn from '../Btn.vue'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'CardListButton',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  props: Btn.props,
  emits: ['click']
}
</script>

<style lang="scss" rel="stylesheet/scss">
%radius {
  &:not(:first-child) {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  &:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.button--container.card-list--button {
  @extend %radius;

  &:not(.touch) {
    &:hover,
    &:active,
    .active {
      transform: translate3d(0, 0, 0) !important;
      @extend %radius;
    }
  }
}
</style>
