<template>
  <div
    class="max-w-full w-full md:w-[30em] p-[2em] md:p-0"
    :class="{
      full: seeFull
    }"
  >
    <div class="dimension-suggestor--quick-container" v-if="!seeFull">
      <div class="mt-1 mb-4" style="line-height: 1.1; text-align: center; font-size: 1.2em">
        <strong>
          <Help>
            <template #title> Linking dimensions </template>
            <template #before> Link the quantity to the most appropriate dimension </template>

            <p>
              It is always best to avoid lump sums if possible, and instead determine costs based on
              some sort of measurable dimension, (for example floor area, wall perimeter or
              foundation volume), so that the item's costs can adapt to different projects
              automatically, saving you oodles of time in the future.
            </p>

            <p>If you haven't priced this item this way before, give it a try.</p>

            <p>Choose the most appropriate dimension to accurately calculate costs.</p>
          </Help>
        </strong>
      </div>

      <Spinner :loading="loading" v-if="loading" size="2em" />

      <Btn
        class="block"
        :key="dim.abbr"
        @click="chooseDimension(dim.abbr)"
        v-for="dim in suggestedDimensions"
      >
        <template #icon>
          <font-awesome-icon :icon="dim.dimension_icon" />
        </template>
        <small class="text-left">
          <strong> {{ dim.dimension_name }} • {{ getSuggestedUnitOfMeasure(dim) }} </strong>
        </small>
        <small class="ml-3" style="margin-left: auto !important">
          <DimensionBadge :dim="dim" size="sm" />
        </small>
      </Btn>

      <btn-group class="block eq mt-4">
        <Btn @click="seeFull = true">See all..</Btn>

        <Btn class="info" :action="createDimension"> Create new dimension.. </Btn>
      </btn-group>
    </div>
    <DimensionSelector v-else v-bind="$props" @input="(abbr) => chooseDimension(abbr)" />
  </div>
</template>

<script>
import DimensionBadge from '../../ui/DimensionBadge.vue'
import DimensionSelector from './DimensionSelector.vue'
import DimensionSelectorMixin from './DimensionSelectorMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'DimensionSuggestor',
  components: { DimensionSelector, DimensionBadge },
  mixins: [DimensionSelectorMixin],
  methods: {
    onCreatedDimension(dim) {
      this.chooseDimension(dim.abbr)
    }
  },
  mounted() {
    eventBus.$on('createdDimension', this.onCreatedDimension)
  },
  unmounted() {
    eventBus.$off('createdDimension', this.onCreatedDimension)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.modal {
  .dimension-suggestor--container {
    max-width: 100%;
    width: 100%;
  }
}
</style>
