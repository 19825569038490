<template>
  <div class="info-tooltip">
    <slot name="button">
      <span
        class="cursor-pointer text-cool-gray-400 hover:text-cool-gray-800 transition-all"
        @click="toggle"
      >
        <font-awesome-icon size="lg" :icon="['fas', 'circle-info']" />
      </span>
    </slot>
    <OverlayPanel ref="op">
      <slot name="title"></slot>
      <slot></slot>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import OverlayPanel from 'primevue/overlaypanel'
OverlayPanel.compatConfig = { MODE: 3 }

const op = ref()
const toggle = (event) => op.value.toggle(event)
</script>
