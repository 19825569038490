import { truncate as lodashTruncate } from 'lodash'
import pluralize from 'pluralize'
import $h from '../Helpers'
import numeral from 'numeral'

const truncate = (str, length) => lodashTruncate(str, { length, omission: '...' })

const $ = (val) => {
  return c.format(val, 'currency')
}

const capitalize = (str) => $h.capitalize(str)

const number = (value, format) => numeral(value).format(format)

export const buildFilters = () => {
  const allFilters = [
    'currency',
    'number',
    'postal',
    'postal_ca',
    'postal_us',
    'date',
    'datetime',
    'time',
    'timerelative',
    'timelength',
    'type',
    'daterelative',
    'calendar',
    'status',
    'percent',
    'text',
    '%',
    'percentage',
    'array',
    'hours',
    'raw',
    'ft',
    'feet',
    'foot',
    'imperial',
    'cron',
    'cron_next',
    'zeropadded',
    'uppercase',
    'phone'
  ].reduce(
    (acc, formatType) => ({
      ...acc,
      [formatType]: (val, ...attr) => c.format(val, formatType, ...attr)
    }),
    {}
  )

  return {
    ...allFilters,
    phone: (val, country = null) => {
      const trimmed = String(val || '').trim()
      let code = country
      if (/^\+/.test(trimmed) && code) {
        // let it determine on its own if country code present
        code = null
      }
      return c.format(trimmed, 'phone', code)
    },
    format: (val, type, ...options) => c.format(val, type, ...options),
    empty: (val) => c.isempty(val),
    notEmpty: (val) => !c.isempty(val),
    field: (val, type) => c.getFieldTitle(val, type),
    pretty: (val) => JSON.stringify(typeof val === 'string' ? JSON.parse(val) : val, null, 2),
    pluralize: (value, number) => pluralize(value, number),
    truncate,
    number,
    capitalize,
    $
  }
}

export default buildFilters()
