export default {
  root: ({ props }) => ({
    class: [
      // Position
      'absolute left-0 top-0',
      'z-40 transform origin-center',
      'overflow-visible',

      // Color
      {
        'bg-surface-900 dark:bg-surface-0': !props.unstyled,
        'text-surface-100 dark:text-surface-0/80': !props.unstyled,
        'dark:border-surface-700': !props.unstyled,
        'border-0 dark:border': !props.unstyled,
        'rounded-sm ring  ring-surface-900/30 ring-6': !props.unstyled
      },
      // Color

      // Before: Triangle
      'before:absolute before:-top-[9px] before:-ml-[9px] before:left-[calc(var(--overlayArrowLeft,0)+1.25rem)] z-0',
      'before:w-0 before:h-0',
      'before:border-transparent before:border-solid',
      'before:border-x-[8px] before:border-[8px]',
      'before:border-t-0 before:border-b-surface-900/30 dark:before:border-b-surface-700',
      'before:hidden',

      'after:absolute after:-top-[11px] after:-ml-[8px] after:left-[calc(var(--overlayArrowLeft,0)+1.25rem)]',
      'after:w-0 after:h-0',
      'after:border-transparent after:border-solid',
      'after:border-x-[0.5rem] after:border-[0.5rem]',
      'after:border-t-0 after:border-b-surface-900/30 dark:after:border-b-surface-900/30'
    ]
  }),
  content: {
    class: ['p-2', 'overflow-auto']
  },
  transition: {
    enterFromClass: 'opacity-0',
    enterActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
