<template>
  <Modal :width="500" :showCloseButton="true" size="sm" ref="activityChannelCreateRef">
    <template #header>
      <h2>{{ editChannel ? 'Chat settings' : 'Chat create' }}</h2>
    </template>
    <template #body>
      <div class="activity-channel-create-body">
        <card-section>
          <template #title>
            <p>Channel details</p>
          </template>
          <card-list>
            <card-list-field>
              <span>
                Channel name
                <small class="text-info"> Name of the channel. </small>
              </span>
              <field :validate="{ required: true }" v-model="channelName" />
            </card-list-field>
            <card-list-field :mobileStack="true">
              <span>
                Company members
                <small class="text-info"> Company users included in this channel. </small>
              </span>
              <choose
                class="ml-5 sm"
                schema="activity:user_id"
                v-model="userMembers"
                :filters="{
                  company_ids: `INSET${$store.state.session.company.company_id}`
                }"
                :autoSelect="false"
                :multiple="true"
                :return-array="true"
              />
            </card-list-field>
            <card-list-field :mobileStack="true">
              <span>
                Client members
                <small class="text-info"> Company clients included in this channel. </small>
              </span>
              <choose
                class="ml-5 sm"
                schema="activity:client_id"
                v-model="clientMembers"
                :autoSelect="false"
                :multiple="true"
                :return-array="true"
              />
            </card-list-field>
          </card-list>
        </card-section>
        <card-section>
          <template #title>
            <p>Members</p>
          </template>
          <div class="channel-members flex py-2">
            <PersonAvatar
              :id="
                $store.state.session.authorizedUser?.user_id || $store.state.session.user.user_id
              "
              v-if="!editChannel"
              :name="`${$store.state.session.user.user_fname} ${$store.state.session.user.user_lname}`"
              size="xlarge"
              class="mr-2"
            />
            <PersonAvatar
              v-for="userId in userMembers"
              v-show="!!localUserCache[userId]"
              :id="userId"
              :name="`${localUserCache[userId]?.user_fname} ${localUserCache[userId]?.user_lname}`"
              size="xlarge"
              :key="userId"
              class="mr-2"
            />
            <PersonAvatar
              v-for="clientId in clientMembers"
              v-show="!!localClientCache[clientId]"
              :id="clientId"
              type="client"
              :name="`${localClientCache[clientId]?.client_fname} ${localClientCache[clientId]?.client_lname}`"
              size="xlarge"
              :key="clientId"
              class="mr-2"
            />
          </div>
        </card-section>
      </div>
    </template>
    <template #footer>
      <div class="footer w-full flex justify-between">
        <Btn class="w-32 h-12" severity="secondary" @click="cancel">Cancel</Btn>
        <Btn :loading="loading" class="w-32" type="primary" @click="save">
          {{ editChannel ? 'Update' : 'Save' }}
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { v4 } from 'uuid'
import { useStore } from 'vuex'
import { onMounted, ref, watch, computed } from 'vue'
import CardList from '@/components/ui/Cards/CardList.vue'
import { useRouter } from 'vue-router'
import useActivityChannel from '@/components/composables/ActivityChannel'
import PersonAvatar from '@/components/ui/PersonAvatar.vue'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: false,
    default: null
  },
  channelTypeId: {
    type: String,
    required: false,
    default: null
  }
})
// ====================== //

// ======== Composables ======== //
const store = useStore()
const router = useRouter()
const {
  channelId,
  channelName: propsChannelName,
  channelMembers: propsChannelMembers
} = useActivityChannel(props)
// ====================== //

// ======== Ref ======== //
const activityChannelCreateRef = ref(null)
// ====================== //

// ======== Data ======== //
const loading = ref(false)
const channelName = ref('')
const userMembers = ref([])
const clientMembers = ref([])
const localUserCache = ref({})
const localClientCache = ref({})
// ====================== //

// ======== Computed ======== //
const editChannel = computed(() => props.channelType && props.channelTypeId)
// ====================== //

// ======== Methods ======== //
const open = () => {
  activityChannelCreateRef.value.open()
}
const cancel = () => {
  activityChannelCreateRef.value.close()

  if (!editChannel.value) {
    channelName.value = ''
    userMembers.value = []
    clientMembers.value = []
  }
}
const saveChannel = async (members) => {
  try {
    if (editChannel.value) {
      return await store.dispatch('activityChat/updateChannel', {
        channelName: channelName.value,
        channelId: channelId.value
      })
    } else {
      const companyId = store.state.session.company.company_id
      const userMembers = members.map((member) => member.user_id).sort()
      return await store.dispatch('activityChat/postChannel', {
        companyId,
        channelName: channelName.value,
        channelType: props.channelType || 'CHAT',
        channelTypeId:
          props.channelTypeId || (members.length > 2 ? v4() : `${userMembers[0]}-${userMembers[1]}`)
      })
    }
  } catch (err) {
    console.error(err)

    await store.dispatch('alert', {
      message: 'Failed to save channel, please try again.',
      error: true
    })
  }
}
const saveChannelMembers = async (channelId, members) => {
  const currentMemberIds = (propsChannelMembers.value || []).map((member) =>
    member.user_id.toString()
  )
  const membersToSave = members.filter(
    (member) => !currentMemberIds.includes(member.user_id.toString())
  )
  const membersToRemove = (propsChannelMembers.value || []).filter(
    (propsMember) =>
      !members.find((member) => member.user_id.toString() === propsMember.user_id.toString())
  )

  await store.dispatch('activityChat/removeChannelMembers', {
    members: membersToRemove.map((member) => ({
      channel_id: channelId,
      user_id: member.user_id,
      channel_type: member.channelType || 'CHAT',
      user_fname: member.user_fname,
      user_lname: member.user_lname
    }))
  })

  return store.dispatch('activityChat/postChannelMembers', {
    members: membersToSave.map((member) => ({
      channel_id: channelId,
      user_id: member.user_id,
      user_is_admin: member.user_is_admin || false,
      channel_type: member.channelType || 'CHAT',
      user_fname: member.user_fname,
      user_lname: member.user_lname
    }))
  })
}
const save = async () => {
  const allMembers = [
    ...userMembers.value.reduce((acc, userId) => {
      if (localUserCache.value[userId]) {
        const user = localUserCache.value[userId]
        acc.push({
          user_id: user.user_id,
          user_fname: user.user_fname || '',
          user_lname: user.user_lname || '',
          channel_id: channelId.value,
          channel_type: 'CHAT'
        })
      }

      return acc
    }, []),
    ...clientMembers.value.reduce((acc, clientId) => {
      if (localClientCache.value[clientId]) {
        const client = localClientCache.value[clientId]

        acc.push({
          user_id: client.client_user_id,
          user_fname: client.user_fname || '',
          user_lname: client.user_lname || '',
          channel_id: channelId.value,
          channel_type: 'CHAT'
        })
      }

      return acc
    }, []),
    {
      user_id: store.state.session.authorizedUser?.user_id || store.state.session.user.user_id,
      user_fname: store.state.session.user.user_fname || '',
      user_lname: store.state.session.user.user_lname || '',
      channel_type: 'CHAT',
      user_is_admin: true,
      channel_id: channelId.value
    }
  ]

  const channel = await saveChannel(allMembers)
  const members = await saveChannelMembers(channel.channel_id, allMembers)

  if (!editChannel.value && members) {
    const scopeRoute = router.currentRoute.value.params.scopeRoute
    await router.push(`/${scopeRoute}/messages/${channel.channel_type}/${channel.channel_type_id}`)
    cancel()
  }
}
const fetchUsers = async () => {
  loading.value = true
  const toFetch = _.imm(userMembers.value).filter((userId) => !localUserCache.value[userId])
  const toFetchClient = _.imm(clientMembers.value).filter(
    (userId) => !localClientCache.value[userId]
  )

  const { set } = toFetch.length
    ? await store.dispatch('User/filter', {
        filters: {
          user_id: toFetch.join('||')
        }
      })
    : { set: [] }

  const clientUserIds = [
    ...toFetchClient,
    ...toFetch.filter((user_id) => !set.find((user) => user.user_id === user_id))
  ]
  const { set: clientSet } = clientUserIds.length
    ? await store.dispatch('Client/filter', {
        filters: {
          user_id: clientUserIds.join('||')
        }
      })
    : { set: [] }

  set.forEach((user) => {
    if (
      user.user_id !==
      (store.state.session.authorizedUser?.user_id || store.state.session.user.user_id)
    ) {
      localUserCache.value[user.user_id] = user
    }
  })

  clientSet.forEach((client) => {
    if (
      client.user_id !==
      (store.state.session.authorizedUser?.user_id || store.state.session.user.user_id)
    ) {
      localClientCache.value[client.client_id] = client

      userMembers.value = userMembers.value.filter(
        (userId) => userId !== client.client_user_id.toString()
      )
      if (!clientMembers.value.includes(client.client_id)) {
        clientMembers.value.push(client.client_id)
      }
    }
  })

  if (toFetchClient.length > 0) {
    const { set: set2 } = await store.dispatch('Client/filter', {
      filters: {
        user_id: toFetchClient.join('||')
      }
    })

    set2.forEach((client) => {
      if (
        client.user_id !==
        (store.state.session.authorizedUser?.user_id || store.state.session.user.user_id)
      ) {
        localClientCache.value[client.client_id] = client

        if (!clientMembers.value.includes(client.client_id)) {
          clientMembers.value.push(client.client_id)
        }
      }
    })
  }

  loading.value = false
}
const fetchClients = async () => {
  loading.value = true
  const toFetch = _.imm(clientMembers.value).filter((clientId) => !localClientCache.value[clientId])

  if (toFetch.length > 0) {
    const { set } = await store.dispatch('Client/filter', {
      filters: {
        client_id: toFetch.join('||')
      }
    })

    set.forEach((client) => {
      if (
        client.user_id !==
        (store.state.session.authorizedUser?.user_id || store.state.session.user.user_id)
      ) {
        localClientCache.value[client.client_id] = client
      }
    })
  }
  loading.value = false
}
const populateChannel = async () => {
  channelName.value = ''
  userMembers.value = []
  clientMembers.value = []

  if (!editChannel.value) return
  loading.value = true

  channelName.value = propsChannelName.value
  userMembers.value = propsChannelMembers.value?.map((member) => member.user_id) || []
  loading.value = false
}
// ====================== //

// ======== Watchers ======== //
watch(userMembers, fetchUsers)
watch(clientMembers, fetchClients)
watch(propsChannelName, populateChannel)
// ====================== //

// ======== Lifecycle ======== //
onMounted(populateChannel)
// ====================== //

// ======== Expose ======== //
defineExpose({
  open
})
// ====================== //
</script>

<style lang="scss" scoped></style>
