<template>
  <div class="section--container container-element">
    <div class="card-label" v-if="$slots.label || $slots.title">
      <slot name="title"></slot>
      <span class="card--title-buttons">
        <slot name="titleButtons"></slot>
      </span>
      <slot name="label"></slot>
      <div class="card-subtitle" v-if="$slots.subtitle || $slots.subLabel">
        <slot name="subtitle"></slot>
        <slot name="subLabel"></slot>
      </div>
    </div>
    <div
      class="section--inner container-element"
      :style="{
        maxWidth: `min(${width}, 100%)`,
        width
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerSection',
  props: {
    width: {
      default: '50em'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.section--container {
  &.green,
  &.yellow,
  &.blue,
  &.pink,
  &.purple,
  &.grey,
  &.dark,
  &.black {
    color: $flame-white !important;
    .card-label,
    p,
    div,
    span,
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      color: $flame-white;
    }
  }
  &.green {
    background: rgba($matcha-500, 0.7);
  }

  &.yellow {
    background: rgba($level-yellow, 0.7);
  }

  &.blue {
    background: rgba($blue-print-700, 0.7);
  }

  &.purple {
    background: rgba($purple-500, 0.7);
  }

  &.pink {
    background: darken(rgb(255, 187, 166), 12%);
  }

  &.black,
  &.dark {
    background: $pitch-black;
  }

  &.grey {
    background: $cool-gray-500;
  }

  .card-label {
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 1em 0 0em 0;
    max-width: 100%;
    width: 36em;
    text-align: left;
    .card--title-buttons {
      font-size: 1rem;
    }
    > * {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      > * {
        margin-right: 0.25em;
      }
    }
    .card-subtitle {
      margin-top: 0.25em;
      clear: both;
      display: block;
      font-size: 0.6em;
      font-weight: bold;
      color: $cool-gray-600;
    }
  }

  .section--inner {
    height: 100%;
    width: auto;
    margin: 3em 2em;
    overflow: visible;
    margin: 0 auto;
  }

  &.scrollx {
    // @extend %xscroll;
  }

  &.card-button-list {
    // @extend %xscroll;
    justify-content: center !important;
    .section--inner {
      margin: 2em;
      overflow: visible;
      max-width: 100% !important;
    }
    .card {
      $width: 15em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
      height: auto;
      display: inline;
      position: relative;
    }

    &.xs .card {
      $width: 10em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
    }

    &.sm .card {
      $width: 15em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
    }

    &.md .card {
      $width: 20em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
    }

    &.lg .card {
      $width: 30em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
    }

    &.xl .card {
      $width: 60em;
      width: $width;
      max-width: $width;
      min-width: $width;
      flex: 1 0 $width;
    }
  }

  &.center {
    > .section--inner {
      justify-content: center;
      align-items: stretch;
    }
  }
}

#app.small-format {
  .section--container.responsive {
    > .section--inner {
      flex-direction: column;
    }

    &.card-button-list {
      align-items: center;
      justify-content: flex-start;

      > .section--inner {
        background: red;
        // @extend %scrollbarDarkSm;
        > .card {
          $width: 100%;
          width: $width;
          max-width: $width;
          min-width: $width;
          flex: 1 0 $width;
          height: auto;
          display: inline;
          position: relative;
          margin: 0;
          height: auto;

          & + .card {
            margin-top: 1em;
          }
        }
      }
    }
  }
}
</style>
