import Normalize from '../Normalize'
import _ from '../Helpers'
import Utilities from './Utilities'
import Objects from '../Objects.js'
import NormalizeUtilities from '../NormalizeUtilities.js'

const { getNormalizedRootRefId } = Normalize

const { diffNormalized, diffAddedRemoved, denormalizeDiffSet, getParents, getReferenceName } =
  Utilities

const { getConstructor, shouldTrackChanges } = Objects

const Changes = class {
  // /**
  //  * Normalized original set
  //  * @type {object}
  //  */
  // originalNormalized = {};
  //
  // /**
  //  * Normalized current set
  //  * @type {object}
  //  */
  // currentNormalized = {};
  //
  // /**
  //  * Normalized set of field: value changes
  //  * @type {{}}
  //  */
  // explicitFieldChanges = {};
  //
  // /**
  //  * Root reference id
  //  * @type {string}
  //  */
  // rootRefId = '';
  //
  // /**
  //  * List of refIds we know that changes have been
  //  * made to, so we are tracking them
  //  * @type {Array}
  //  */
  // trackingRefIds = [];

  /**
   * @param object orig         original normalized set (inside of any namespacing like [rootRefId])
   * @param object curr         current normalized set (inside of any namespacing like [rootRefId])
   * @param string|null rref    rootRefId
   */
  constructor(orig, curr, rref = null) {
    /**
     * Normalized original set
     * @type {object}
     */
    this.originalNormalized = {}

    /**
     * Normalized current set
     * @type {object}
     */
    this.currentNormalized = {}

    /**
     * Normalized set of field: value changes
     * @type {{}}
     */
    this.explicitFieldChanges = {}

    /**
     * Root reference id
     * @type {string}
     */
    this.rootRefId = ''

    /**
     * List of refIds we know that changes have been
     * made to, so we are tracking them
     * @type {Array}
     */
    this.trackingRefIds = []

    /**
     * List of functions called when changes made
     * @type {Array}
     */
    this.watchers = []

    /**
     * List of functions called when reset is called
     * @type {*[]}
     */
    this.resetWatchers = []

    /**
     * List of functions called when object is added
     * @type {Array}
     */
    this.addWatchers = []

    /**
     * List of functions called when object is removed
     * @type {Array}
     */
    this.removeWatchers = []

    /**
     * Object filled with refId: [watchers]
     * for watchers that report only when
     * a change in that specific object is reported
     * @type {{}}
     */
    this.restrictedWatchers = {}

    this.explicitChangedFields = {}
    this.allChangedFields = {}
    this.explicitChangedRaw = {}
    this.allChangedRaw = {}
    this.added = []
    this.removed = []

    this.setOriginal(orig)
    this.setCurrent(curr, false)
    this.setRootRefId(rref || getNormalizedRootRefId(this.currentNormalized))
  }

  /**
   * Backup of exact state
   * @returns {Backup Object}
   */
  getState() {
    return {
      changeState: 1,
      state: {
        originalNormalized: this.originalNormalized,
        currentNormalized: this.currentNormalized,
        explicitFieldChanges: this.explicitFieldChanges,
        rootRefId: this.rootRefId,
        trackingRefIds: this.trackingRefIds,
        watchers: this.watchers,
        addWatchers: this.addWatchers,
        removeWatchers: this.removeWatchers,
        restrictedWatchers: this.restrictedWatchers
      }
    }
  }

  /**
   * Restore backup
   * @param backup
   * @returns {Changes}
   */
  setState(backup) {
    if (!backup.changeState || !backup.state) {
      throw new Error('Invalid change backup object.')
    }
    this.originalNormalized = backup.state.originalNormalized
    this.currentNormalized = backup.state.currentNormalized
    this.explicitFieldChanges = backup.state.explicitFieldChanges
    this.rootRefId = backup.state.rootRefId
    this.trackingRefIds = backup.state.trackingRefIds
    this.watchers = backup.state.watchers
    this.restrictedWatchers = backup.state.restrictedWatchers
    this.addWatchers = backup.state.addWatchers
    this.removeWatchers = backup.state.removeWatchers

    this.triggerWatchers(Object.keys(this.restrictedWatchers))

    return this
  }

  /**
   *
   * @param original
   * @returns {Changes}
   */
  setOriginal(original) {
    this.originalNormalized = _.imm(original)
    this.triggerWatchers(Object.keys(this.originalNormalized))

    return this
  }

  /**
   *
   * @param current
   * @returns {Changes}
   */
  setCurrent(current, trigger = true) {
    this.currentNormalized = _.imm(current)

    if (trigger) {
      this.triggerWatchers(Object.keys(this.currentNormalized))
    }

    // const curKeys = Object.keys(this.currentNormalized)
    // const origKeys = Object.keys(this.originalNormalized)
    // console.log('  setCurrent -> origKeys', Date.now() - i)
    // i = Date.now()
    //
    // this.added = _.difference(curKeys, origKeys)
    // this.removed = _.difference(origKeys, curKeys)
    // console.log('  setCurrent -> removed', Date.now() - i)
    // i = Date.now()

    return this
  }

  /**
   *
   * @param changes
   * @returns {Changes}
   */
  setExplicitFieldChanges(changes) {
    this.explicitFieldChanges = { ...changes }
    return this
  }

  /**
   *
   * @param changes
   * @returns {Changes}
   */
  setExplicitFieldChangesRaw(changes) {
    this.explicitChangedRaw = { ...changes }
    return this
  }

  /**
   *
   * @param rootRefId
   * @returns {Changes}
   */
  setRootRefId(rootRefId) {
    this.rootRefId = rootRefId
    return this
  }

  /**
   * @returns {object}
   */
  getExplicitFieldChanges() {
    return { ...this.explicitFieldChanges }
  }

  /**
   * @returns {string}
   */
  getRootRefId() {
    return this.rootRefId
  }

  /**
   * @returns object
   */
  getOriginal() {
    return this.originalNormalized
  }

  /**
   * @returns object
   */
  getCurrent() {
    return this.currentNormalized
  }

  /**
   * Set current to the original (deleting all changes)
   * @returns {Changes}
   */
  reset() {
    this.setCurrent(this.getOriginal())
    this.setExplicitFieldChanges({})
    this.setExplicitFieldChangesRaw({})
    this.resetChangedFields()
    this.triggerResetWatchers()

    return this
  }

  getHashes() {
    // const t = Date.now()
    // console.log('Generating hashes...')
    //
    // for (let i = 0; i < refIds.length; i++) {
    //   const refId = refIds[i]
    //   const current = this.getCurrent()[refId]
    //
    //   const coerceType = current.type === 'cost_item' ? 'cost_type' : current.type
    //
    //   current.type = coerceType
    // }
    //
    // console.log('Generating hashes took', Date.now() - t, 'ms')
  }

  getChangeLogs() {
    const mergedForCompleteness = {
      ...this.getOriginal(),
      ...this.getCurrent()
    }
    const reduceToObjects = (fieldChanges) =>
      Object.keys(fieldChanges).reduce(
        (acc, refId) => ({
          ...acc,
          // If it doesn't exist, it means it was added then removed before it was saved
          ...(mergedForCompleteness[refId]
            ? {
                [refId]: {
                  refId,
                  parentRefId: mergedForCompleteness[refId].parentRefId || null,
                  added: this.added.includes(refId),
                  removed: this.removed.includes(refId),
                  changed:
                    (this.added.includes(refId) && 'added') ||
                    (this.removed.includes(refId) && 'removed') ||
                    null,
                  fieldChanges: fieldChanges[refId],
                  referenceName: getReferenceName(mergedForCompleteness, refId),
                  objectType: mergedForCompleteness[refId].type
                }
              }
            : {})
        }),
        {}
      )
    return {
      explicit: reduceToObjects(this.explicitChangedFields),
      all: reduceToObjects(this.allChangedFields),
      allRaw: this.allChangedRaw,
      explicitRaw: this.explicitChangedRaw
    }
  }

  isDirty() {
    const changes = this.getChangeLogs()
    return !!Object.keys(changes.explicitRaw).some(
      (ref) => Object.keys(changes.explicitRaw[ref]).length
    )
  }

  resetChangedFields() {
    this.explicitChangedFields = {}
    this.allChangedFields = {}
  }

  addToChangedFields(rawChanges, explicit = true) {
    const original = this.getOriginal()
    const current = this.getCurrent()
    const refIds = Object.keys(rawChanges)

    let newChangedFields = { ...(explicit ? this.explicitChangedFields : this.allChangedFields) }
    let newChangedRaw = { ...(explicit ? this.explicitChangedRaw : this.allChangedRaw) }

    const constructors = {}

    const toMerge = []
    for (let i = 0; i < refIds.length; i++) {
      const ref = refIds[i]
      let newChangedObj = { ...(newChangedFields[ref] || {}) }
      const explicitChangedFields = Object.keys(rawChanges[ref] || {})

      /* if it's not in the original, that means it was added.
      If it's added we only should track changes after something changes */
      if (!(ref in original)) continue

      const constr =
        constructors[current[ref].type] ??
        (constructors[current[ref].type] = getConstructor(current[ref].type))

      for (let j = 0; j < explicitChangedFields.length; j++) {
        const field = explicitChangedFields[j]
        if (!shouldTrackChanges(field, constr, constr.fields)) continue // not tracking

        // Compare change value to current, if effectively the same
        // remove from explicitChangedFields
        const originalValue = original?.[ref]?.[field] ?? null
        const newValue = rawChanges[ref][field]
        const isSame =
          originalValue === newValue ||
          Utilities.fieldComparison(originalValue, newValue, field, constr.fields, false, false)

        if (!isSame) {
          const cf = {
            from: originalValue,
            to: newValue,
            desc: 'Changed'
          }
          newChangedObj[field] = cf
          newChangedRaw[ref] = { ...(newChangedRaw[ref] || {}), [field]: newValue }
          toMerge.push({ [ref]: newChangedObj })
        } else if (newChangedObj?.[field]) {
          // eslint-disable-next-line no-unused-vars
          let { [field]: omit, ...rest } = newChangedObj
          newChangedObj = rest
          toMerge.push({ [ref]: newChangedObj })
          if (newChangedRaw[ref]?.[field]) {
            // eslint-disable-next-line no-unused-vars
            ;({ [field]: omit, ...newChangedRaw[ref] } = newChangedRaw[ref])
          }
        }
      }
    }

    // Merge for all objects
    newChangedFields = NormalizeUtilities.mergeChanges(newChangedFields, ...toMerge)

    // remove empty changes
    newChangedFields = Object.keys(newChangedFields).reduce(
      (acc, ref) => ({
        ...acc,
        ...(!Object.keys(newChangedFields[ref]).length ? {} : { [ref]: newChangedFields[ref] })
      }),
      {}
    )

    if (explicit) {
      this.explicitChangedFields = newChangedFields
      this.allChangedFields = NormalizeUtilities.mergeChanges(
        { ...this.allChangedFields },
        { ...newChangedFields }
      )
      this.explicitChangedRaw = newChangedRaw
      this.allChangedRaw = NormalizeUtilities.mergeChanges(this.allChangedRaw, newChangedRaw) // explicit changes both
    } else {
      this.allChangedFields = newChangedFields
      this.allChangedRaw = newChangedRaw // non explicit only changes non-explicit set
    }
  }

  /**
   *
   * @param object rawChanges      starting with refIds ie: {[refIds]: {[field]: value}}
   * @returns {Changes}
   */
  addChanges(rawChanges, trigger = false, currentFull = null) {
    const refIds = Object.keys(rawChanges)

    this.trackingRefIds = [...this.trackingRefIds, ...refIds]

    const current = currentFull || NormalizeUtilities.mergeChanges(this.getCurrent(), rawChanges)

    this.getHashes(refIds)

    this.setCurrent(current, false)

    this.addToChangedFields(rawChanges, false)

    if (trigger) setTimeout(() => this.triggerWatchers(refIds, rawChanges))

    return this
  }

  /**
   *
   * @param object rawChanges     starting with refIds ie: {[refIds]: {[field]: value}}
   * @param string changeType     added|removed|default
   * @returns {Changes}
   */
  addExplicitChanges(rawChanges, trigger = true) {
    const changes = this.getExplicitFieldChanges()
    const refIds = Object.keys(rawChanges)

    this.trackingRefIds = [...this.trackingRefIds, ...refIds]

    const changeSet = NormalizeUtilities.mergeChanges(changes, rawChanges)

    this.setExplicitFieldChanges(changeSet)

    this.addToChangedFields(rawChanges, true)

    if (trigger) this.triggerWatchers(refIds, rawChanges)

    return this
  }

  /**
   * Get a diff schedule between original and current sets
   * @returns {Object}
   */
  getChanges(strict = false, normalized = true, full = false) {
    if (full) {
      return this.getAuditedChanges(strict, normalized)
    }

    const trackedChanges = this.getSpecificChanges(
      this.getOriginal(),
      this.getCurrent(),
      this.rootRefId,
      strict,
      this.trackingRefIds,
      normalized
    )

    return trackedChanges
  }

  /**
   * Get list of refIds that have been removed
   */
  getRemoved() {
    return _.difference(Object.keys(this.originalNormalized), Object.keys(this.currentNormalized))
  }

  /**
   * Audits all differences between original and current,
   * regardless of what changes have been added later.
   * @returns {Object}
   */
  getAuditedChanges(strict = false, normalized = true) {
    // Will include a full list of added/remove cahnges
    // because we are providing full original and current
    // with no limiting trackingRefIds
    return this.getSpecificChanges(
      this.getOriginal(),
      this.getCurrent(),
      this.rootRefId,
      strict,
      null,
      normalized
    )
  }

  /**
   * Get changeset for explicit
   *    -all items added are explicit
   *    -all items removed are explicit
   *    -otherwise, only changes added as explicit
   *        are deemed explicit
   * @param strict
   * @param normalized
   * @returns {*}
   */
  getExplicitChanges(strict = false, normalized = true) {
    const exp = this.getExplicitFieldChanges()
    const originalNormalized = this.getOriginal()
    const currentNormalized = this.getCurrent()

    // Add exp to original
    // Exaclty the same?
    if (_.isEqual(originalNormalized, currentNormalized)) {
      return {}
    }

    const original = originalNormalized
    const actualCurrent = currentNormalized
    const current = _.imm(originalNormalized)

    // Add only explicit changes onto the original item
    // so we careate a new 'current' set that has only
    // explicit changes instead of ALL changes.
    Object.keys(actualCurrent).forEach((refId) => {
      if (!(refId in original) || refId in exp) {
        current[refId] = {
          ...(!(refId in original) ? actualCurrent[refId] : current[refId]),
          ...(exp[refId] || {})
        }
      }
    })

    const trackedChanges = this.getSpecificChanges(
      original,
      current,
      this.rootRefId,
      strict,
      Object.keys(exp),
      normalized
    )

    return trackedChanges
  }

  /**
   *
   * @param from
   * @param to
   * @param rootRefId
   * @param strict
   * @param trackingRefIds
   * @param normalized
   * @returns {Object}
   */
  getSpecificChanges(
    from,
    to,
    rootRefId,
    strict = false,
    trackingRefIds = null,
    normalized = true
  ) {
    let diff = this.getAddedRemoved(false)

    // Exactly the same?
    if (!_.isEqual(from, to)) {
      diff = {
        ...diffNormalized(
          from,
          to,
          rootRefId,
          strict,
          trackingRefIds ? _.uniq(trackingRefIds) : null
        ),
        ...diff
      }
    }

    diff = getParents({ ...this.originalNormalized, ...this.currentNormalized }, diff)

    if (!normalized) {
      return denormalizeDiffSet(diff, this.rootRefId)
    }

    return diff
  }

  getAddedRemoved(addParents = false) {
    return diffAddedRemoved(this.getOriginal(), this.getCurrent(), this.rootRefId, addParents)
  }

  /**
   * Trigger adding or removing, so that that can be watched as well
   * @param addedOrRemoved
   * @param refIds
   */
  async trigger(addedOrRemoved = 'added', refIds = []) {
    let promises = []
    if (addedOrRemoved === 'added') {
      promises = this.addWatchers.map((watcher) => watcher(this, refIds))
    }

    if (addedOrRemoved === 'removed') {
      promises = [...promises, ...this.removeWatchers.map((watcher) => watcher(this, refIds))]
    }

    await Promise.all(promises)

    return this
  }

  convertToRaw(normalizedChangeLogs) {
    const rawChanges = {}
    const refIds = Object.keys(normalizedChangeLogs)

    for (let i = 0; i < refIds.length; i++) {
      const refId = refIds[i]
      const objLog = normalizedChangeLogs[refId]

      const fields = Object.keys(objLog)
      rawChanges[refId] = {}

      for (let j = 0; j < fields.length; j++) {
        const changeLog = objLog[fields[j]]

        rawChanges[refId][fields[j]] = changeLog.to
      }
    }

    return rawChanges
  }

  /**
   * Triggers change watcher methods on a throttle
   * @param refIds    changed refIds
   * @returns {Changes}
   */
  triggerWatchers(refIds = Object.keys(this.allChangedFields), changes = {}) {
    // First, trigger all general watchers
    setTimeout(() => {
      this.watchers.forEach((watcher) =>
        watcher(this, refIds, changes, this.isDirty(), this.added, this.removed)
      )
    })
    // Then trigger all restricted watchers
    setTimeout(() => {
      Object.keys(this.restrictedWatchers).forEach(
        (refId) =>
          refIds.includes(refId) &&
          Object.values(this.restrictedWatchers[refId]).forEach((watcher) =>
            watcher(
              this,
              [refId],
              changes,
              !!Object.keys(changes?.[refId] ?? {}).length,
              this.added,
              this.removed
            )
          )
      )
    })

    return this
  }
  /**
   * Triggers change watcher methods on a throttle
   * @param refIds    changed refIds
   * @returns {Changes}
   */
  triggerResetWatchers() {
    // First, trigger all general watchers
    this.resetWatchers.forEach((watcher) =>
      watcher(this, Object.keys(this.getCurrent()), {}, false)
    )

    return this
  }

  watchReset(watcher) {
    this.resetWatchers.push(watcher)

    return this
  }

  unwatchReset(watcher) {
    const index = this.resetWatchers.indexOf(watcher)
    if (index === -1) return
    this.resetWatchers.splice(index, 1)
  }

  /**
   * Add a watcher that is called when item is added
   * @param callback
   */
  watchAdded(callback) {
    this.addWatchers.push(callback)

    return this
  }

  /**
   * Add a watcher that is called when item is added
   * @param callback
   */
  watchRemoved(callback) {
    this.removeWatchers.push(callback)

    return this
  }

  /**
   * Watch for changes on this change manager by
   * adding a watcher callback just like an event listener.
   * @param callback
   * @param refId|null      restrict change checking to only when the refId
   *                        provided is changed
   * @returns {function}    returns unwatch function to be called
   *                        when you want to stop watching
   */
  watch(callback, refId = null) {
    if (typeof callback !== 'function') {
      return () => {}
    }

    if (
      refId &&
      (!this.restrictedWatchers[refId] || !this.restrictedWatchers[refId].includes(callback))
    ) {
      // Watching specifically for changes in normalized object
      // given by refId only
      this.restrictedWatchers = {
        ...this.restrictedWatchers,
        [refId]: [...(this.restrictedWatchers[refId] || []), callback]
      }
    } else if (!this.watchers.includes(callback)) {
      // General watchers for any change in the normalized set
      this.watchers.push(callback)
    }

    return () => {
      this.unwatch(callback)
    }
  }

  /**
   * Remove a listener by calling unwatch and providing
   * the exact instance of the method provided in watch.
   * or by calling the method returned in watch();
   * @param callback
   * @returns {Changes}
   */
  unwatch(callback) {
    const unwatchFromArray = (set = []) => {
      const index = set.indexOf(callback)

      if (index > -1) {
        set.splice(index, 1)
      }
    }

    // Unwatch from general watchers
    unwatchFromArray(this.watchers)

    // Unwatch from restricted watchers
    Object.values(this.restrictedWatchers).forEach((set) => unwatchFromArray(set))

    return this
  }
}

export default Changes
