<template>
  <modal
    v-bind="$props"
    ref="modal"
    size="md"
    :isDirty="bodyIsDirty"
    :full="deviceStore.isMobileBrowser"
    :scrollable="true"
    :pt="{
      header: '!bg-white',
      content: '!bg-white',
      footer: '!bg-white'
    }"
    :closeable="true"
    :showCloseButton="false"
    :clickAway="true"
  >
    <template #header> New vendor or subcontractor </template>
    <template #body>
      <vendor-body ref="body" :object="object" />
    </template>
  </modal>
</template>

<script>
import VendorBody from '../bodies/VendorNew.vue'
import ObjectModalMixin from './ObjectModalMixin'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'ModalVendorNew',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectModalMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  components: { VendorBody },
  methods: {},
  props: {
    type: {
      default: 'vendor'
    },
    go: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
