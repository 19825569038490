<template>
  <modal
    v-bind="$props"
    :classes="`modal-mini ${classes}`"
    :size="size"
    ref="modal"
    :width="width"
    :maxWidth="maxWidth"
    :showTopClose="showCloseButton ?? closeable"
    :show-header="true"
    :zIndex="zIndexComputed"
    :hide-only="hideOnly"
    :hasHeaderBorder="hasHeaderBorder"
    :pt="pt"
    :ptOptions="ptOptions"
    @opened="() => $emit('opened')"
    @open="() => $emit('open')"
    @close="() => $emit('close')"
    @closed="() => $emit('closed')"
    @scroll.capture.stop="() => {}"
    @doubletap="close"
    @doubleclick="close"
  >
    <template #closeicon>
      <slot name="closeicon"></slot>
    </template>
    <template #header v-if="$slots.header || title">
      <slot name="header">{{ title }}</slot>
    </template>
    <template #body>
      <slot name="body"></slot>
      <slot></slot>
    </template>
    <template #footer v-if="$slots.footer">
      <slot name="footer">
        <btn
          class="btn lg info mini-close-button block round"
          :class="closeClass"
          style="width: 100%"
          v-if="showCloseButton && (closeable || /xs|sm/.test($store.state.session.deviceSize))"
          @click="() => $refs.modal.close()"
        >
          {{ closeMessage }}
        </btn>
      </slot>
    </template>
  </modal>
</template>

<script>
import ModalMixin from './ModalMixin'

/**
 * Provide a Modal object with an actions object,
 * that has an action function that returns a promise.
 */
export default {
  name: 'MiniModal',
  emits: ['opened', 'open', 'close', 'closed'],

  data() {
    return {
      done: false
    }
  },
  mixins: [ModalMixin],
  computed: {
    zIndexComputed() {
      return this.$store ? this.$store.state.modal.topZIndex : 2
    }
  },
  props: {
    ...ModalMixin.props,
    scrollable: {
      type: Boolean,
      default: false
    },
    hideOnly: {
      default: false
    },
    size: {
      default: 'sm'
    },
    clickAway: {
      default: true
    },
    startOpen: {
      default: false
    },
    showCloseButton: {
      default: null
    },
    closeable: {
      default: true
    },
    expandable: {
      default: false
    },
    mask: {
      default: true
    },
    // NUMBER ONLY
    width: {
      default: null
    },
    // string or number
    maxWidth: {
      default: null
    },
    closeMessage: {
      default: 'Close'
    },
    closeClass: {
      default: ''
    },
    includeGutter: {
      required: false,
      default: false
    },
    hasHeaderBorder: {
      default: false
    },
    classes: {
      default: ''
    },
    pt: {
      type: Object,
      default: () => ({})
    },
    ptOptions: {
      type: Object,
      default: () => ({
        mergeProps: true
      })
    }
  }
}
</script>

<style lang="scss" rel="text/scss"></style>
