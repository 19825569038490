// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'
import Company from './Company'
import User from './User'

export default {
  type: 'vendor',

  possibleStatuses: ['a', 'i'],

  listPresets: [
    {
      title: 'Active vendors',
      description: 'Vendors that you currently/regularly use.',
      filters: {
        vendor_status: 'a'
      },
      filterText: {
        vendor_status: 'Active'
      }
    },
    {
      title: 'Inactive vendors',
      description: 'Vendors that you previously used.',
      filters: {
        vendor_status: 'i'
      },
      filterText: {
        vendor_status: 'Inactive'
      }
    }
  ],

  skipAudit: true,

  fields: {
    ...Company.fields,
    ...User.fields,
    vendor_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      visible: true,
      component: 'PreviewVendor'
    },
    company_id: {
      type: 'string',
      mapTo: 'company'
    },
    bid_group_ids: {
      type: 'array',
      save: true,
      trackChanges: true
    },
    vendor_name: {
      type: 'string',
      filter: true,
      reload: true,
      save: false,
      visible: true,
      style: 'font-size: 1.1em; font-weight: bold; text-align: left; justify-content: flex-start;'
    },
    trade_type_ids: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'trade_type',
      validate: {
        required: false
      },
      normalize: false
    },
    vendor_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    vendor_time_active: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    vendor_time_inactive: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    vendor_has_materials: {
      type: 'int',
      filter: true,
      mapTo: false,
      default: 1
    },
    vendor_has_labor: {
      type: 'int',
      filter: true,
      mapTo: false,
      default: 1
    },
    vendor_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    vendor_user_id: {
      type: 'string',
      mapTo: 'user'
    },
    vendor_company_id: {
      type: 'string',
      mapTo: 'company'
    },
    vendor_crew_size: {
      type: 'int',
      filter: true,
      save: true,
      visible: true,
      mapTo: false,
      default: 1
    }
  },

  actions: {},

  generateVueActions() {
    return {
      async createFromEmail({ dispatch }, payload) {
        const { email, object = {} } = payload

        const { object: vendor } = await dispatch('ajax', {
          path: `/vendor/addVendorToCompanyFromEmail`,
          data: {
            object: {
              ...object,
              company_email: email
            }
          }
        })

        await dispatch('clearCache')

        return {
          vendor_id: vendor.vendor_id,
          id: vendor.vendor_id,
          vendor_company_id: vendor.vendor_company_id,
          vendor,
          object: vendor
        }
      },

      /**
       * Get user for specific client
       * @param dispatch
       * @param payload
       */
      async getCompany({ dispatch }, payload) {
        const { object: vendor } = await dispatch('resolveObject', { ...payload, forceFull: true })

        const { object } = await dispatch('ajax', {
          path: `vendor/getCompany/${vendor.vendor_id}`
        })

        return object
      },
      /**
       * Get user for specific client
       * @param dispatch a sdfasdf asd
       * @param payload
       */
      async getUser({ dispatch }, payload) {
        const { object: vendor } = await dispatch('resolveObject', { ...payload, forceFull: true })

        const { object } = await dispatch('ajax', {
          path: `vendor/getUser/${vendor.vendor_id}`
        })

        return object
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async call({ dispatch }, payload) {
        let { tags = {} } = payload

        const user = await dispatch('getUser', payload)
        const { id } = await dispatch('resolveObject', { ...payload, forceFull: true })

        tags = {
          ...tags,
          vendor_id: id
        }

        return dispatch('User/call', { object: user, tags }, { root: true })
      },

      /**
       * Call a client
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async message({ dispatch }, payload) {
        const user = await dispatch('getUser', payload)

        return dispatch('User/message', { object: user }, { root: true })
      },

      /**
       * Open map to clients address
       * @param payload
       *    @see resolveObject to know what payload parameters can be included
       * @returns <Promise{object}>
       */
      async map({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', { ...payload, forceFull: true })

        return dispatch(
          'openMap',
          {
            city: object.company_city,
            prov: object.company_prov,
            address: object.compay_address
          },
          {
            root: true
          }
        )
      },

      async addTradeType({ dispatch }, payload) {
        const { tradeType: id, confirm = true } = payload

        const { object } = await dispatch('resolveObject', payload)
        const { object: tradeType } = await dispatch(
          'TradeType/resolveObject',
          { id },
          { root: true }
        )

        const r = new RegExp(`(^|,)${tradeType.trade_type_id}(,|$)`)
        if (tradeType.trade_type_id && !r.test(object.trade_type_ids)) {
          if (
            object.vendor_id &&
            (!confirm ||
              (await dispatch(
                'modal/asyncConfirm',
                {
                  message: `Would you like to add <span class='text-info'>${tradeType.trade_type_name}</span> to \
              the list of things <span class='text-info'>${object.vendor_name}</span> can do?`
                },
                { root: true }
              )))
          ) {
            await dispatch('partialUpdate', {
              selected: [
                {
                  type: 'vendor',
                  vendor_id: object.vendor_id,
                  trade_type_ids: [
                    ...(Array.isArray(object.trade_type_ids)
                      ? object.trade_type_ids
                      : object.trade_type_ids.split(',')),
                    id
                  ]
                }
              ]
            })
          }
        }
        return payload
      },
      email({ dispatch, state }, payload) {
        const { selected = [], refId = false, id = [], button = null, grid = null } = payload

        return new Promise((resolve) => {
          const fnOpen = (objects) => {
            const selectedMessages = objects.map((q) => ({
              message_to: q.user_email,
              message_cc: q.user_email_alt || null,
              message_subject: '',
              tags: {
                client_id: q.client_id
              },
              aoFiles: [],
              title: `${q.client_name} • ${q.user_email}
                ${q.user_email_alt ? `, ${q.user_email_alt}` : ''}`
            }))
            dispatch(
              'message/composeMultiple',
              {
                templateFilters: {
                  template_type_id: 13
                },
                selectTemplateByDefault: false,
                selected: selectedMessages
              },
              { root: true }
            ).then(() => {
              if (grid) grid.reload(true)
              resolve(payload)
            })
          }

          if (selected.length) {
            fnOpen(selected)
          } else if (refId) {
            fnOpen(_.makeArray(refId).map((r) => _.denormalize(state.normalized, r)))
          } else if (id.length) {
            const ids = _.makeArray(id).join('||')
            dispatch('ajax', {
              path: '/client/search',
              data: {
                filters: {
                  client_id: ids
                }
              },
              button
            }).then(({ set }) => {
              fnOpen(set)
            })
          }
        })
      }
    }
  }
}
