<script setup>
import { h, onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const props = defineProps({
  selector: {
    type: String,
    default: ''
  },
  display: {
    type: String,
    validator(value) {
      return ['left', 'right', 'popup', 'in-app'].includes(value)
    },
    default: 'popup'
  },
  display_position: {
    type: String,
    validator(value) {
      return [
        'top',
        'top-left',
        'top-right',
        'right',
        'right-bottom',
        'right-top',
        'bottom',
        'bottom-left',
        'bottom-right',
        'left',
        'left-bottom',
        'left-top'
      ].includes(value)
    },
    default(props) {
      return props.display === 'in-app' ? 'bottom' : undefined
    }
  },
  embed: {
    type: Boolean,
    default: false
  },
  filter: {
    type: String,
    default: 'admin'
  },
  alert: {
    type: Boolean,
    default: true
  }
})

function callback(unread) {
  if (unread > 0 && route.name !== 'Announcements') {
    window.Beamer.show()
  } else {
    window.Beamer.destroy()
  }
}

window.beamer_config = {
  product_id: import.meta.env.VITE_BEAMER_PRODUCT_ID,
  lazy: true,
  button: false,
  multi_user: true,
  mobile: true,
  ...props,
  user_id: store.state.session.authorizedUser?.user_id,
  user_firstname: store.state.session.authorizedUser?.user_fname,
  user_lastname: store.state.session.authorizedUser?.user_lname,
  user_email: store.state.session.authorizedUser?.user_email,
  callback: callback
}

onBeforeMount(() => {
  c.externalScript('https://app.getbeamer.com/js/beamer-embed.js').then(() => {
    window.Beamer.init()
  })
})
onBeforeUnmount(() => {
  window.Beamer.hide()
})

h('span')
</script>
