export default {
  directory: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/65556/CostCertified+Knowledgebase'
  },
  lead_rotation: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1126694956/Build+a+lead+form+for+your+website'
  },
  lead_sources: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1129185296/Lead+sources'
  },
  import_items: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/977633291/Item+importing+guide'
  },
  import_clients: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/977633291/Item+importing+guide'
  },
  tax: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/977698852/How+to+create+a+sales+tax+with+complex+options'
  },
  custom_dimensions: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1091698692/Custom+dimensions'
  },
  quote_pdf_line_items: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/975241220/Changing+proposal+PDF+template+line+item+options'
  },
  company_wide_margin: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1140228097/Changing+company-wide+markup+or+margin'
  },
  groups: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1116831779/Creating+user+groups'
  },
  user: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/985497636/Creating+a+new+user+for+your+company'
  },
  labor_type: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1236697105/Labor+types'
  },
  create_quote: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1170112523/Quick-start+guide'
  },
  fee_items: {
    url: 'https://costcertified.atlassian.net/wiki/spaces/CS/pages/1126694913/Adding+a+fee+based+on+cost+price+profit+weight+or+hours'
  },
  webhooks: {
    url: 'https://costcertified.atlassian.net/l/c/GsKQnMSZ'
  },
  payments: {
    url: 'https://calendly.com/patrick-postrehovsky/get-faster-payments-with-bolster-sign-up-today?month=2024-02'
  }
}
