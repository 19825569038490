<template>
  <component
    :is="startFull ? 'span' : 'SuggestionButton'"
    :style="showingFull ? 'text-align: left !important; font-weight: normal !important;' : ''"
    @click="showFull = !showFull"
    class="item-location"
    @mouseover="hovering = true"
    @mouseout="hovering = false"
  >
    <div class="item-location--col" v-if="!showingFull">
      <Tag v-if="locationsFull.length < 2" :value="closest" />
      <strong v-else>
        {{ closest }}
      </strong>
    </div>

    <template v-else>
      <div class="item-location--col" :key="index" v-for="(location, index) in locationsFull">
        <template v-if="index > 0">
          <font-awesome-icon icon="arrow-right" size="xs" class="mx-1" />
        </template>
        <Tag v-if="index === locationsFull.length - 1" :value="$f.truncate(location.name, 35)" />
        <span v-else>
          {{ $f.truncate(location.name, 35) }}
        </span>
      </div>
    </template>
  </component>
</template>

<script>
import Tag from 'primevue/tag'
import SuggestionButton from '../ui/SuggestionButton.vue'

export default {
  name: 'ItemLocation',
  components: { SuggestionButton, Tag },
  data() {
    return {
      showFull: false,
      hovering: false
    }
  },
  computed: {
    showingFull() {
      return (this.startFull || this.showFull) && this.locations.length >= 1
    },
    locations() {
      return this.roomsOnly
        ? c.makeArray(this.object.aoLocation)
        : [...c.makeArray(this.object.asAssemblyPath)].slice(0, -1).map((name) => ({
            name
          }))
    },
    locationsFull() {
      return [
        ...this.locations,
        {
          name: this.object.cost_type_name || this.object.assembly_name
        }
      ]
    },
    closest() {
      return (
        (this.locations.length && this.locations[this.locations.length - 1].name) ||
        (this.roomsOnly ? 'Uses root dimensions' : this.rootName)
      )
    }
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    roomsOnly: {
      default: true
    },
    startFull: {
      default: false
    },
    rootName: {
      default: 'Project Root'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.item-location {
  white-space: nowrap;
  line-height: 1.2 !important;
  font-weight: 500;
  color: $blue-print-600;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  .item-location--arrow {
    padding: 0 0.3em;
  }

  .item-location--spacer {
    display: inline-block;
    width: 0;
    margin: 0 !important;
  }
  span {
    font-weight: 500;
    font-size: 0.75em;
  }

  .item-location--col {
    height: 20px;
    display: flex;
    align-items: center;
  }

  &.title {
    color: $cool-gray-600;

    .item-location--arrow.item-location--highlighted,
    .item-location--spacer.item-location--highlighted {
      display: none;
    }
  }
}
</style>
