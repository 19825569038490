<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    ref="modal"
    :scrollable="true"
    :isDirty="bodyIsDirty"
    :full="false"
    :clickAway="true"
    :expandable="true"
  >
    <template #header>
      <font-awesome-icon icon="user" />
      New Role
    </template>
    <template #body>
      <role-body ref="body" :id="id" />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndClose"
        @cancel="cancelClick"
      />
    </template>
  </modal>
</template>

<script>
import RoleBody from '../bodies/Role.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'

export default {
  name: 'ModalRoleNew',
  mixins: [ObjectModalMixin],
  components: { RoleBody },
  props: {
    type: {
      default: 'role'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
