<template>
  <company-edit :key="refId" :reference="refId" :newCompany="true" />
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import CompanyEdit from './CompanyEdit.vue'

export default {
  name: 'CompanyNew',
  mixins: [
    ObjectManipulator(
      'company' /* IMPORTANT: object type: labor_type, cost_type, quote etc */,
      true /* track changes? */
    ),
    BodyMixin /* AutoSave */
  ],
  components: {
    CompanyEdit
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
