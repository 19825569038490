<script>
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SuperIcon',
  components: { FontAwesomeLayers },
  props: {
    size: {
      default: null
    }
  },
  emits: ['click']
}
</script>

<template>
  <font-awesome-layers @click.native="() => $emit('click')" fixed-width>
    <font-awesome-icon icon="shield" :size="size" fixed-width />
    <font-awesome-icon
      :icon="['fas', 'star']"
      transform="shrink-10 up-1"
      :size="size"
      fixed-width
    />
  </font-awesome-layers>
</template>

<style scoped lang="scss"></style>
