<template>
  <div
    v-observe-visibility="{
      callback: visibilityHandler,
      once: true,
    }"
    :id="objectLocal.refId"
    @click="isOpen = !isOpen"
    class="presentation-assembly-container"
    v-if="!showCollapsed">

    <div class="assembly-heading"
         @click="forceExpand = 0; forceShrink = 1;"
         :id="`${uid}-heading`"
        :style="headingStyle">

      <btn rounded class="more px-0 p-2 default xl" @click="forceExpand = 0; forceShrink = 1;">
        <font-awesome-icon icon="chevron-down" fixed-width />
      </btn>

      <span class="assembly-title">
        {{objectLocal.assembly_name || 'Grouped items'}}
      </span>

      <span class="badge badge-dark mx-2" v-if="objectLocal.quote_qty_net_base > 1">
        &times; {{objectLocal.quote_qty_net_base}}
      </span>

      <SuggestionButton class="danger"
        v-if="!qty">
        {{l('not included')}}
      </SuggestionButton>

      <SuggestionButton
        v-if="!presentationSettings.showConfigurator && objectLocal.assembly_is_optional && objectLocal.assembly_is_included"
        class="danger"
        @click="removeSelf()">
        <font-awesome-icon icon="remove" />
        {{l('Remove')}}
      </SuggestionButton>

      <SuggestionButton
        v-if="!presentationSettings.showConfigurator && objectLocal.assembly_is_optional && !objectLocal.assembly_is_included"
        class="info"
        @click="addSelf()">
        {{l('Include')}}?
      </SuggestionButton>

      <btn rounded class="more px-0 p-2 info lg"
           :id="`${uid}-edit-toggle`"
           ref="editorBtn"
           v-if="editable"
           @click="openEditor">
        <font-awesome-icon icon="pencil" />
      </btn>
    </div>

    <div class="assembly-properties"
      v-if="showProperties">

      <ImageGallery
        v-if="itemPictures.length && isVisible"
        :thumbs="thumbUrls"
        :urls="fileUrls" />

      <div class="assembly-desc" v-if="desc"><Linkify :content="desc || ''" /></div>

      <PresentationProperties
        class="assembly-attributes"
        :object="objectLocal" />

      <QuotePresentationDimensions
        v-if="showDimensions"
        v-bind="$props" />

    </div>

    <div class="element-replacer" v-else-if="!isVisible && itemPictures.length"></div>

    <addons-list
      ref="addons"
      class="assembly-upgrades"
      v-if="!presentationSettings.showConfigurator && addons.length && isVisible"
      :title="`Upgrade or add to ${objectLocal.assembly_name}:`"
      :reference="objectLocal.refId"
      :dimensions="possDimensions"
      :optionals="true"
      :upgrades="true"
      v-model="addons" />
    <div class="element-replacer" v-else-if="!isVisible && addons.length"></div>

    <quote-presentation-items
      ref="items"
      class="assembly-items"
      :items="objectLocal.aoChildren"
      :store="store"
      :showGeneralPrice="objectLocal.quote_show_itemized_prices"
      :editable="editable"
      :presentationSettings="presentationSettings"
      :parentRefId="parentRefId"
      :depth="depth"
      :artificialMutliplier="multiplier"
      :reference="refId" :key="refId" />

    <div class="assembly-subtotal"
      :style="footingStyle" v-if="(showQty && (qty > 1.00 || qty == 0)) || showCost || showPrice">
      <strong>
        {{objectLocal.assembly_name}} {{l('subtotal')}}
      </strong>

      <span v-if="showCost" style="margin-left: auto">(Cost: {{$f.currency(cost)}})</span>
      <div class="assembly-totals"
           v-if="(showQty && (qty > 1 || qty < 1)) || showPrice || !qty"
           :class="!qty ? 'excluded' : ''">
        <template v-if="!qty">
          {{l('not included')}}
        </template>
        <template v-else>
          <template v-if="showQty && qty > 1">
            {{$f.number(qty)}} {{l('each')}}
            <span v-if="showPrice" class="mx-2">•</span>
          </template>


          <template v-if="showPrice">
            {{$f.currency(price)}}
          </template>
        </template>
      </div>
    </div>

    <drop ref="editor" :fixTo="`#${uid}-heading`" offset="5px 5px" v-if="editable && editorRequested" targetAttachment="bottom left">
      <card-section class="sm">
        <card-list>
          <card-list-field>
            <span>
              Set emphasis
            </span>

            <strong class="text-muted" style="
                  font-size: 0.7em;
                  line-height: 1.1;
                   white-space: normal;
                  word-wrap: break-word;
                  max-width: 25%;">
              <div class="text-info mr-1">{{objectLocal.assembly_emphasis > 0 ? '+' : ''}}{{objectLocal.assembly_emphasis}}</div>

              <template v-if="objectLocal.assembly_emphasis <= -3">
                Show in a bundle with other de-emphasized items
              </template>
              <template v-else-if="objectLocal.assembly_emphasis === -2">
                Show as a regular item (cannot see items)
              </template>
              <template v-else-if="objectLocal.assembly_emphasis === -1">
                Show as an emphasized item (cannot see items)
              </template>
              <template v-else-if="objectLocal.assembly_emphasis === 0">
                Show as a regular expanded assembly
              </template>
              <template v-else-if="objectLocal.assembly_emphasis === 1">
                Show as an emphasized expanded assembly
              </template>
            </strong>

            <div>
              <btn rounded class="more px-0 p-2 lg mr-1"
                   :class="objectLocal.assembly_emphasis <= -3 ? 'muted' : 'info'"
                   :disabled="objectLocal.assembly_emphasis <= -3"
                   @click="lessEmphasis">
                <font-awesome-icon icon="minus" />
              </btn>
              <btn rounded class="more px-0 p-2 lg"
                   :class="objectLocal.assembly_emphasis >= 1 ? 'muted' : 'info'"
                   :disabled="objectLocal.assembly_emphasis >= 1"
                   @click="moreEmphasis">
                <font-awesome-icon icon="plus" />
              </btn>
            </div>
          </card-list-field>


          <card-list-field>
            <span>
              Show itemized pricing?
              <br>
              <small class="text-muted">
                If you turn this off, the prices of items inside this assembly will not be shown.
              </small>
            </span>

            <toggle
              @input="v => setShowItemizedPrices(v)"
              :value="objectLocal.assembly_show_itemized_prices" />
          </card-list-field>

          <card-list-field>
            <span>
              Optional item?
            </span>

            <toggle
              v-model="optional" />
          </card-list-field>
        </card-list>
      </card-section>

      <card-section class="sm">
        <template #label>Pictures/documents</template>
        <file-list
          class="left"
          :highlightFirst="true"
          :idList="true"
          v-model="fileIds" />
      </card-section>

      <card-section class="sm">
        <template #label>Optional/suggested upgrades</template>
      </card-section>
    </drop>

  </div>
  <quote-presentation-cost-item
    v-else
    v-bind="$props"
    :depth="depth"
    :expandable="!showAsItem"
    @expand="forceExpand = 1; forceShrink = 0">

    <div
      class="assembly-expand-container"
      v-if="!forceExpand && !showAsItem && collapseTeaser.length"
      @click="forceExpand = 1; forceShrink = 0;">


      <ul class="assembly-teaser-list">
        <template v-for="(teaser, index) in collapseTeaser" :key="index">
          <li>
            {{$f.truncate(teaser[0] || 'Misc', 50)}}
          </li>
          <template v-if="index === 3">
            <li>
              {{l('More')}}...
            </li>
          </template>
        </template>
      </ul>
    </div>

  </quote-presentation-cost-item>
</template>

<script>
  import QuotePresentationCostItem from './QuotePresentationCostItem.vue';
  import AddonsList from './QuotePresentationAddonList-classic.vue';
  import ImageGallery from '../../items/ImageGallery.vue';
  import SuggestionButton from '../../ui/SuggestionButton.vue';
  import PresentationAssemblyMixin from './PresentationAssemblyMixin';
  import TranslationMixin from './languages/TranslationMixin';
  import QuotePresentationDimensions from './QuotePresentationDimensions.vue';
  import Linkify from '../../ui/Linkify.vue';
  import PresentationItemMixin from './PresentationItemMixin';
  import PresentationProperties from './PresentationProperties.vue';

  export default {
    name: 'QuotePresentationItemAssembly',
    mixins: [
      PresentationAssemblyMixin,
      TranslationMixin,
      PresentationItemMixin,
    ],
    data() {
      return {
        isOpen: true,
        forceExpand: 0,
        forceShrink: 0,
        uid: c.uniqueId(),
        objectLocal: this.object,
        editorRequested: 0,
        isVisible: 0,
      };
    },
    asyncComputed: {
      possDimensions: {
        async get() {
          return this.$store.dispatch('Dimension/getPossibleDimensions');
        },
        default: () => ({}),
      },
    },
    watch: {
      editable(b) {
        if (!b) this.editorRequested = 0;
        // destroy drop each tiem
      },
      object(object) {
        this.objectLocal = object;
      },
    },
    methods: {
      visibilityHandler(isVisible) {
        this.isVisible = isVisible;
      },
      goToFirstUpgrade() {
        this.forceExpand = 1;
      },
      openEditor() {
        this.editorRequested = 1;
        this.$nextTick(() => this.$refs.editor.open());
      },
      saveEmphasis() {
        const itemized = this.$store.state[this.store]
          .normalized[this.objectLocal.refId].assembly_show_itemized_prices;

        const emphasis = this.$store.state[this.store]
          .normalized[this.objectLocal.refId].assembly_emphasis;

        const id = this.objectLocal.assembly_id;

        return this.objectLocal.assembly_id
          ? this.$store.dispatch('Assembly/partialUpdate', {
            go: false,
            alert: false,
            selected: [
              {
                type: 'assembly',
                assembly_id: id,
                assembly_emphasis: emphasis,
                quote_show_itemized_prices: itemized,
                assembly_show_itemized_prices: itemized,
              },
            ],
          })
          : Promise.resolve();
      },
      moreEmphasis() {
        return this.$store.dispatch(`${this.store}/addEmphasis`, {
          refId: this.object.refId,
        }).then(() => this.saveEmphasis());
      },
      lessEmphasis() {
        return this.$store.dispatch(`${this.store}/removeEmphasis`, {
          refId: this.object.refId,
        }).then(() => this.saveEmphasis());
      },

      async removeSelf() {
        this.$store.dispatch('addLoading');

        await this.$store.dispatch('Quote/toggleIncluded', {
          included: 0,
          store: 'Quote',
          explicit: true,
          refId: this.object.refId,
        });

        this.$store.dispatch('removeLoading');
      },
      async addSelf() {
        this.$store.dispatch('addLoading');

        await this.$store.dispatch('Quote/toggleIncluded', {
          included: 1,
          store: 'Quote',
          explicit: true,
          refId: this.object.refId,
        });

        this.$store.dispatch('removeLoading');
      },
    },
    components: {
      PresentationProperties,
      QuotePresentationDimensions,
      SuggestionButton,
      ImageGallery,
      QuotePresentationCostItem,
      AddonsList,
      Linkify,
    },
    props: {
      object: {
        type: Object,
        required: true,
      },
      deselectOnDestroy: {
        default: false,
      },
      store: {
        type: String,
      },
      parentRefId: {
        type: String,
      },
      refId: {
        type: String,
      },
      editable: {
        type: [Number, Boolean],
      },
      presentationSettings: {
        type: Object,
      },
      /**
       * If this cost item is in an assembly
       * marked to be shown as 'exploded', which means
       * that all the contents of the assembly are taken
       * out of the assembly container
       * and appear as though they are no longer in the
       * assembly, the item prices, costs and quantities need
       * to be multiplied by the quantity of of the container assembly
       *
       * for example, say you have a bathrom assembly with quantity: 2,
       * with the container there we can see that we have 2x everything
       * that is inside the containre.  Maybe there are 4 potlights in
       * the bathroom assembly, since we have 2 bathrooms, we know we have a total
       * of 2x4 potlights or 8 potlights.
       *
       * If we take the container away, the item would normally still show
       * 4x potlights.  We would never know that the actaul count and price is 2x that
       * So, to get around that, we need to pass an artificialMultiplier that multiplies
       * all the figures by the quantity of the (now removed) assembly container.
       *
       * This is all just for presentation purposes, the actual quote still has the container,
       * it just appears to be gone here.
       *
       * We also must pass this down indefinitely to all subsequent child items and assemblies.
       */
      artificialMultiplier: {
        type: Number,
        default: 1,
      },
    },
    mounted() {
    },
    beforeUnmount() {
    },
  };
</script>
