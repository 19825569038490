<template>
  <div
    class="p-4 rounded border border-surface-200 shadow shadow-surface-200/30"
    :class="{ open: viewing }"
  >
    <div
      class="see-more--buttons flex justify-center items-center"
      @click.capture.stop.prevent="toggle"
    >
      <slot name="button">
        <btn :class="buttonClass" severity="tertiary" size="lg" @click="toggle" v-show="!viewing">
          <template #icon>
            <font-awesome-icon v-if="icon" :icon="icon" />
          </template>
          {{ title }}
          <span class="badge badge-danger" v-if="badge > 0">{{ badge }}</span>
        </btn>
      </slot>
    </div>
    <template v-if="visibilityType === 'if'">
      <fade>
        <div v-if="viewing">
          <div class="see-more-content">
            <slot></slot>
          </div>
          <btn class="btn round secondary see-more-minimize" @click="toggle" v-if="showCollapse">
            <template #icon>
              <font-awesome-icon icon="caret-up" />
            </template>
          </btn>
        </div>
      </fade>
    </template>
    <template v-else>
      <fade>
        <div v-show="viewing">
          <div class="see-more-content">
            <slot></slot>
          </div>
          <btn class="btn round secondary see-more-minimize" @click="toggle" v-if="showCollapse">
            <template #icon>
              <font-awesome-icon icon="caret-up" />
            </template>
          </btn>
        </div>
      </fade>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SeeMore',
  emits: ['input'],
  data() {
    return {
      viewing: this.value
    }
  },
  methods: {
    toggle() {
      this.viewing = !this.viewing
      this.$emit('input', this.viewing)
    }
  },
  components: {},
  props: {
    badge: {
      default: 0
    },
    title: {
      type: String
    },
    icon: {
      type: [Boolean, String],
      default: 'plus'
    },
    value: {
      required: false,
      default: false
    },
    visibilityType: {
      default: 'if'
    },
    buttonClass: {
      default: 'round muted mx-2 my-1'
    },
    startOpen: {
      default: false
    },
    showCollapse: {
      default: false
    }
  },
  mounted() {
    if (this.startOpen) this.toggle()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.see-more-container {
  display: inline;
  text-align: left;

  .see-more-minimize {
    .caret-up {
      &:after {
        margin-left: 0;
      }
    }
  }

  .see-more-content {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.open {
    display: block;
    width: 100%;
    flex: 0 0 100%;
    background: $blue-print-300;
    padding: 0.25em 2em;
    margin: 2em -2em;
    border-radius: 1em;
    border-bottom: 2px solid $blue-print-300;
    box-shadow: $shadow;
    width: calc(100% + 4em);
  }
}

@media (max-width: 576px) {
  .see-more--buttons {
    padding: 0 1em;
  }
}
</style>
