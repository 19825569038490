<template>
  <div
    class="swatch--container"
    :class="color.contrastColor"
    @click.stop.prevent="() => $emit('select')"
  >
    <div class="swatch--bg" :style="style"></div>
    <div class="flex flex-col bg-surface-300 w-full rounded-b-lg p-2">
      <div class="text-xs font-medium text-surface-500">
        {{ showVendor ? vendorName : '' }}
      </div>
      <div class="leading-none">
        {{ $f.capitalize(color.name) }}
      </div>
      <div v-if="$slots.default">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Swatch',
  props: {
    showVendor: {
      default: false
    },
    color: {
      required: true
    },
    vendorName: {
      default: 'Benjamin Moore'
    }
  },
  emits: ['select'],
  computed: {
    style() {
      return {
        backgroundColor: `#${this.color.hex}`,
        color: `${this.color.contrastColor === 'dark' ? 'black' : 'white'}`
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$borderRadius: 0em;
.swatch--container {
  height: 15em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 10em;
  min-width: 8em;

  &.muted {
    opacity: 0.3;
  }

  .swatch--bg {
    border-radius: $borderRadius;
    height: 10em;
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .swatch--name {
    height: 5em;
    width: 100%;
    padding: 0.5em;
    background: $cool-gray-300;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      color: $cool-gray-700;
      font-size: 0.9em;
      font-weight: 500;
      text-align: center;
    }
  }

  transition: all 0.1s ease;
  &:hover:not(.single),
  &.selected:not(.single) {
    cursor: pointer;

    .swatch--bg {
      height: 10.5em;
      margin-top: -0.5em;
      border-top-right-radius: 0.5em !important;
      border-top-left-radius: 0.5em !important;
    }

    .swatch--name {
      height: 5.5em;
      background: $cool-gray-400;
      margin-bottom: -0.5em;
      border-bottom-right-radius: 0.5em !important;
      border-bottom-left-radius: 0.5em !important;
    }
  }

  &.selected {
    .swatch--name {
      background: $cool-gray-700 !important;
      .text {
        color: $cool-gray-200 !important;
      }
    }
  }

  &.single {
    cursor: pointer;
    .swatch--bg {
      border-top-right-radius: 0.5em !important;
      border-top-left-radius: 0.5em !important;
    }

    .swatch--name {
      border-bottom-right-radius: 0.5em !important;
      border-bottom-left-radius: 0.5em !important;
    }

    &:hover,
    .selected {
      cursor: pointer;

      .swatch--name {
        background: $cool-gray-400;
      }
    }
  }
}

.compact,
.horizontal {
  .swatch--container {
    flex-direction: row;
    height: 4em !important;
    width: 100%;
    max-width: 100% !important;
    overflow: hidden;

    position: relative;
    top: 0;
    right: 0;
    left: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .swatch--bg {
      max-height: 4em !important;
      border-bottom-left-radius: 0.5em !important;
      border-bottom-right-radius: 0.5em !important;
      border-top-right-radius: 0.5em !important;
      border-top-left-radius: 0.5em !important;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .swatch--name {
      height: 4em !important;

      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0.5em !important;

      background: transparent !important;

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      mix-blend-mode: multiply;
    }

    &.dark {
      .swatch--name {
        color: $cool-gray-700;
        mix-blend-mode: multiply;
        .text {
          color: $cool-gray-700;
        }
      }
    }

    &.light {
      .swatch--name {
        color: $flame-white;
        mix-blend-mode: lighten;
        .text {
          color: $flame-white;
        }
      }
    }
  }
}
</style>
