// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'lead_source',

  skipAudit: true,

  tableColumns: [
    {
      column: 'lead_source_id',
      name: 'Lead source id',
      visible: false,
      class: 'text-left justify-start'
    },
    {
      column: 'lead_source_name',
      name: 'Lead source name',
      visible: true,
      class: 'text-left justify-start'
    },
    {
      name: 'Number of leads/clients',
      column: 'lead_source_count_clients',
      visible: true,
      class: 'number justify-end'
    },
    {
      name: 'Sum of quotes',
      column: 'lead_source_sum_quotes',
      visible: true,
      class: 'number justify-end'
    },
    {
      name: 'Sum of booked projects',
      column: 'lead_source_sum_quotes_booked',
      visible: true,
      class: 'number justify-end'
    }
  ],

  fields: {
    lead_source_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      required: true
    },
    lead_source_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      required: false,
      default: 0
    },
    lead_source_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_time_created: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 'a'
    },
    lead_source_count_clients: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_count_quotes_declined: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_count_quotes_booked: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_count_quotes_pending: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_count_quotes: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_sum_quotes: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_sum_quotes_declined: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_sum_quotes_pending: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_sum_quotes_booked: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    lead_source_sum_profit: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    }
  }
}
