import Vue from 'vue'
import {
  SET_CHATS,
  SET_CHAT_LOADING,
  ADD_NEW_MESSAGE_TO_CHAT,
  LOAD_MORE_MESSAGES,
  SET_LAST_TIME_ACTIVE,
  ADD_NEW_CHAT,
  ADD_CHAT_USER,
  SET_SUBSCRIPTION_MESSAGE_OBSERVER,
  SET_SUBSCRIPTION_CHAT_OBSERVER,
  CLOSE_SUBSCRIPTIONS
} from '../../mutation-types'
import {
  addUserToChatMutation,
  chatSummaryQuery,
  createChatMutation,
  createMessagesMutation,
  getChatQuery,
  onCreateChatSubscription,
  onCreateMessagesSubscription,
  quoteMessagesQuery,
  updateLastTimeActiveMutation
} from '@/apollo-client/api-calls/quoteChat'
import { client } from '../../../apollo-client/apollo-config'

// initial state
const state = {
  chatSummary: null,
  chatsLoading: false
}

// getters
const getters = {
  unreadChatCount: (state, getters, rootState) => {
    let count = 0
    const userId = rootState.session.user.user_id
    if (!state.chatSummary) return count
    Object.values(state.chatSummary).forEach((chat) => {
      // if data is missing return
      if (
        !chat.messages ||
        !chat.messages.messageList ||
        !chat.messages.messageList.length ||
        !chat.chatUsers ||
        !chat.chatUsers.length
      )
        return

      const messageList = chat.messages.messageList
      const lastMessage = messageList[messageList.length - 1]

      // if current user sent that last message return
      if (lastMessage.userId === userId) return

      const currentChatUser = chat.chatUsers.filter((chatUser) => chatUser.userId === userId)[0]

      if (
        !currentChatUser ||
        !currentChatUser.lastTimeActive ||
        parseInt(lastMessage.createdAt, 10) > parseInt(currentChatUser.lastTimeActive, 10)
      ) {
        count += 1
      }
    })

    return count
  }
}

// actions
const actions = {
  /**
   * Set loading state of chats
   * @param state
   * @param commit
   * @param message object
   * @return message object
   */
  async setChatLoading({ state, commit }, payload) {
    const { chatLoading = false } = payload

    commit({
      type: SET_CHAT_LOADING,
      chatLoading
    })

    return chatLoading
  },

  /**
   * Initialize chats of a contractor
   * @param state
   * @param commit
   * @param chats array
   * @return chats array
   */
  async contractorInitializeChats({ state, commit, dispatch, rootState }) {
    dispatch('setChatLoading', { chatLoading: true })

    // Get chats in the last two weeks
    const { data } = await client().query({
      query: chatSummaryQuery,
      variables: {
        userId: String(rootState.session.user.user_id),
        companyId: String(rootState.session.company.company_id)
      }
    })

    const chats = data.getChatSummary

    dispatch('setChatLoading', { chatLoading: false })

    const summary = {}

    if (chats.length) {
      chats.forEach((chat) => {
        summary[chat.refId] = chat
      })
    }

    commit({
      type: SET_CHATS,
      summary
    })

    return summary
  },

  /**
   * initialize chat of client
   * @param state
   * @param commit
   * @param message object
   * @return message object
   */
  async clientInitializeChat({ state, commit }, payload) {
    const { refId = null } = payload

    if (!refId) return

    const { data } = await client().query({
      query: getChatQuery,
      variables: {
        refId: String(refId)
      }
    })

    const summary = {}

    if (data && data.getChat && data.getChat.refId) {
      summary[data.getChat.refId] = data.getChat
    }

    commit({
      type: SET_CHATS,
      summary
    })

    return data.getChat
  },

  /**
   * update the last time active of a ChatUser
   * @param state
   * @param commit
   * @param chats array
   * @return chats array
   */
  async updateLastTimeActive({ state, commit }, payload) {
    const { refId = null, userId = null, userName = null } = payload

    if (!refId || !userId || !userName) return null

    const currentChatUser = state.chatSummary[refId].chatUsers.filter(
      (chatUser) => chatUser.userId === userId
    )

    if (!currentChatUser || !currentChatUser.length) {
      await client().mutate({
        mutation: addUserToChatMutation,
        variables: { refId, userId, userName }
      })
      commit({
        type: ADD_CHAT_USER,
        refId,
        userId,
        userName
      })
    } else {
      await client().mutate({
        mutation: updateLastTimeActiveMutation,
        variables: { refId, userId }
      })
      commit({
        type: SET_LAST_TIME_ACTIVE,
        refId,
        userId
      })
    }
  },

  /**
   * Add a message to a chat
   * @param state
   * @param commit
   * @param message object
   * @return message object
   */
  async addMessage({ state, commit }, payload) {
    const { message = null } = payload

    if (!message) return null

    commit({
      type: ADD_NEW_MESSAGE_TO_CHAT,
      message
    })

    return message
  },

  /**
   * Load more messages
   * @param state
   * @param commit
   * @param message object
   * @return message object
   */
  async loadMoreMessages({ state, commit }, payload) {
    const { refId = null } = payload

    const lastKey = state.chatSummary[refId].messages.lastEvaluatedKey

    const { data } = await client().query({
      query: quoteMessagesQuery,
      variables: {
        refId: String(refId),
        lastEvaluatedKey: {
          createdAt: lastKey.createdAt,
          messageId: lastKey.messageId,
          refId: String(lastKey.refId)
        }
      }
    })

    if (!data || !data.getMessages) return null

    commit({
      type: LOAD_MORE_MESSAGES,
      messages: data.getMessages,
      refId
    })

    return data.getMessages
  },

  /**
   * Send new message
   * @param state
   * @param commit
   * @param message object
   * @return message object
   */
  async sendChatMessage({ state, commit }, payload) {
    const { refId = null, userId = null, body = null } = payload

    if (!refId || !userId || !body) return null

    const { data } = await client().mutate({
      mutation: createMessagesMutation,
      variables: {
        refId: String(refId),
        userId: String(userId),
        body: String(body)
      }
    })

    commit({
      type: ADD_NEW_MESSAGE_TO_CHAT,
      message: data.createMessage
    })
  },

  /**
   * create a new chat
   * @param state
   * @param commit
   * @param chat object
   * @return chat object
   */
  async createNewChat({ state, commit, dispatch }, payload) {
    const {
      refId = null,
      chatName = null,
      contractorId = null,
      companyId = null,
      userId = null,
      userName = null
    } = payload

    if (!refId || !chatName || !userId || !contractorId || !companyId || !userName) return null

    const { data } = await client().mutate({
      mutation: createChatMutation,
      variables: {
        refId: String(refId),
        chatName: String(chatName),
        userId: String(userId),
        contractorId: String(contractorId),
        companyId: String(companyId),
        userName: String(userName)
      }
    })

    dispatch('addNewChat', { chat: data.createChat })
  },

  /**
   * add chat to chat summary
   * @param state
   * @param commit
   * @param chat object
   * @return chat object
   */
  async addNewChat({ state, commit }, payload) {
    const { chat = null } = payload

    if (!chat) return null

    commit({
      type: ADD_NEW_CHAT,
      chat
    })
  }
}

// mutations
const mutations = {
  [SET_CHATS](state, { summary }) {
    state.chatSummary = summary
  },
  [ADD_NEW_CHAT](state, { chat }) {
    Vue.set(state.chatSummary, chat.refId, chat)
  },
  [ADD_NEW_MESSAGE_TO_CHAT](state, { message }) {
    if (!state.chatSummary[message.refId]) return
    state.chatSummary[message.refId].messages.messageList.push(message)
  },
  [SET_CHAT_LOADING](state, { chatLoading }) {
    state.chatsLoading = chatLoading
  },
  [LOAD_MORE_MESSAGES](state, { messages, refId }) {
    const stateMessages = state.chatSummary[refId].messages
    stateMessages.lastEvaluatedKey = messages.lastEvaluatedKey
    stateMessages.messageList = messages.messageList.concat(stateMessages.messageList)
  },
  [SET_LAST_TIME_ACTIVE](state, { refId, userId }) {
    const unix = Math.floor(Date.now() / 1000).toString(10)
    state.chatSummary[refId].chatUsers.forEach((chatUser) => {
      if (chatUser.userId === userId) {
        chatUser.lastTimeActive = unix
      }
    })
  },
  [ADD_CHAT_USER](state, { refId, userId, userName }) {
    const unix = Math.floor(Date.now() / 1000).toString(10)
    state.chatSummary[refId].chatUsers.push({
      refId,
      userId,
      lastTimeActive: unix,
      userName
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
