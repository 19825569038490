<template>
  <div v-if="checkoutStatus === 'not-started' || checkoutStatus === 'error'">
    <BlockUI class="p-4 relative" :blocked="loading ? true : false">
      <ProgressPaymentReview :hideBreakdown="hideBreakdown" :hideTotal="true" />
      <div v-show="!checkout.isValid" class="mt-4">
        <div id="stored-card"></div>
        <Warning class="mt-2 ml-1">
          Please enter your credit card security code to use this payment method.
        </Warning>
      </div>
      <div class="mt-6" v-if="checkout.isValid">
        <h3 class="mb-3">Payment Method</h3>
        <Card
          :pt="{
            body: '!p-4',
            root: '!rounded-md !border !border-pitch-black'
          }"
        >
          <ProgressPaymentMethod />
        </Card>
      </div>
      <Warning v-if="error" class="mt-4">
        {{ error }}
      </Warning>
      <div class="mt-8 flex justify-end">
        <Btn @click="pay" size="xl" :disabled="!checkout.isValid" severity="primary-black"
          >Pay ({{ $f.currency(invoiceTotal) }})</Btn
        >
      </div>
    </BlockUI>
    <div
      v-if="loading"
      class="w-full h-full top-0 left-0 right-0 bottom-0 absolute flex flex-col items-center justify-center z-[1102] px-10"
    >
      <h2 class="mb-4 text-pitch-black">Processing Payment</h2>
      <ProgressBar
        :pt="{
          root: '!h-3 max-w-[80%]'
        }"
        :ptOptions="{ mergeProps: true }"
        mode="indeterminate"
      ></ProgressBar>
    </div>
  </div>
  <div
    v-if="checkoutStatus === 'success'"
    class="flex text-center w-100 justify-center flex-col py-4"
  >
    <h1>Thank You</h1>
    <h4 class="mt-2">Your payment is currently being processed.</h4>
    <Checkmark class="success my-2 mx-auto" :size="100" />
    <p>
      You will receive a confirmation email when your <br />
      payment has been completed.
    </p>
    <p class="mt-6 text-sm text-cool-gray-600 px-8">
      If payment is not processed successfully, an email will be sent to you with the corresponding
      reason.
    </p>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
import ProgressPaymentReview from './ProgressPaymentReview.vue'
import ProgressPaymentMethod from './ProgressPaymentMethod.vue'
import useInvoice from '../composables/Invoice'
import usePayment from '../composables/Payment'
import usePaymentMethod from '../composables/PaymentMethod'
import useItemizedPayments from '../composables/ItemizedPayments'
import usePayFac from '../composables/PayFac'
import ProgressBar from 'primevue/progressbar'
import BlockUI from 'primevue/blockui'
import eventBus from '@/eventBus'

defineProps({
  hideBreakdown: {
    type: Boolean,
    default: false
  }
})

const checkoutStatus = ref('not-started')
const error = ref(null)

const { invoiceTotal, invoice } = useInvoice()

const { checkout, loading, paymentResponse } = usePayment()

const { paymentMethodHash, paymentMethodVariants } = usePaymentMethod()

const { items } = useItemizedPayments()

const { makeItemizedPayment } = usePayFac()

watch(
  () => paymentResponse.value,
  (response) => {
    const { error = null } = response
    if (error) {
      checkoutStatus.value = 'error'
      error.value = error.message || 'Your payment could not be processed, please try again.'
      return
    }
    checkoutStatus.value = 'success'
  }
)

const pay = async () => {
  const paymentMethodId = paymentMethodHash[checkout.value.paymentMethod.type]
  checkout.value.invoice = {
    ...invoice.value,
    aoItems: items.value,
    payment_method_id: paymentMethodId
  }
  checkout.value.paymentMethodVariations = paymentMethodVariants.value
  try {
    await makeItemizedPayment()
    eventBus.$emit('checkout-complete', items.value)
    items.value = []
  } catch (e) {
    console.log(e, 'could not make payment')
  }
}
</script>
