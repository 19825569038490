import Modal from '../modals/Modal.vue'
import SaveOrCancel from '../ui/SaveOrCancel.vue'
import ModalMixin from './ModalMixin'
import eventBus from '../../eventBus'

/**
 * When added to a modal that relates to an object,
 * the body can emit events to manipulate its container modal,
 * if it is in a modal.
 *
 * Body components can emit
 * -'closeModal' and the modal will close.
 * -'isDirty' to make the modal aware of the dirty status of the object
 * -'loading' to let the modal know whether the body is loading
 * -'selected' to let th modal know the object has been fully imported
 */
export default {
  /* render(h) {
    return (
      <modal
        ref="modal"
        size="lg"
        full="{true}"
        clickAway="{false}"
        expandable="{true}">
          <div slot="header">
            <span class="file"></span>
            Quote
          </div>
          <body-component
            ref="body"
            slot="body"
            id="{id}" />
        <template slot="footer">
          <save-or-cancel
            isDirty="{isDirty}"
            loading="{loading}"
            @save="saveClick"
            @cancel="cancelClick" />
        </template>
      </modal>
    );
  }, */
  mixins: [ModalMixin],
  data() {
    return {
      bodyIsDirty: false,
      loading: 0,
      selected: false,
      original: null,
      foundBody: 0,
      store: null,
      refId: null
    }
  },
  computed: {
    focused() {
      return this.zIndex === this.$store.state.modal.topZIndex
    },
    objects() {
      return this.modal.objects.length ? this.modal.objects : []
    },
    object() {
      return this.modal.objects.length ? this.modal.objects[0] : {}
    },
    filters() {
      return this.modal.filters ? this.modal.filters : []
    },
    id() {
      return this.modal &&
        this.modal.objects &&
        this.modal.objects.length &&
        this.modal.objects[0][`${this.type}_id`]
        ? this.modal.objects[0][`${this.type}_id`]
        : null
    }
  },
  methods: {
    selectedHandler({ store, refId }) {
      this.store = store
      this.refId = refId
    },
    /**
     * A save and close alternative for vue3 using the EntitySelect component + body with EntityComputedFields composable
     * @returns {Promise<void>}
     */
    async saveAndClose3({ refId, store }) {
      const savePayload = await this.$store.dispatch(`${store}/save`, {
        refId,
        go: false,
        alert: true,
        changes: true
      })
      await this.modal.saved.call(this.$refs.body, savePayload, this)
      this.close()
    },
    async saveAndClose() {
      if (!this.foundBody) this.watchBody()
      const saveAndCloseData = await this.$refs.body.saveAndClose()
      if (!this.foundBody) this.watchBody()
      if (this.$refs.body.isVariation) {
        eventBus.$emit('savedVariation')
      }
      return saveAndCloseData
      // this.modal.save.call(this.$refs.body, this);
      // return this.$refs.body.save(...args)
      //   .then((payload) => {
      //     if (this.modal.go !== false
      //       && this.modal.go !== 0
      //       && this.go !== false
      //       && this.go !== 0) {
      //       this.$store.dispatch('go', {
      //         object: { ...payload.object },
      //         closeModals: true,
      //       }, { root: true });
      //     }
      //     this.modal.saved.call(this.$refs.body, payload, this);
      //     this.close();
      //   });
    },
    saveAndCloseAndStay(...args) {
      // this.modal.save.call(this.$refs.body, this);
      return this.$refs.body.save(...args).then(() => {
        // this.modal.saved.call(this.$refs.body, payload, this);
        this.close()
      })
    },
    saveClick(...args) {
      // this.modal.save.call(this.$refs.body, this);
      return this.$refs.body.save(...args).then((payload) => {
        if (this.modal.go && this.go !== false) {
          this.$store.dispatch(
            'go',
            {
              object: { ...payload.object },
              closeModals: false
            },
            { root: true }
          )
        }
        // this.modal.saved.call(this.$refs.body, payload, this);
      })
    },
    cancelClick(...args) {
      return this.$refs.modal.close(...args)
    },
    devCheckRequirements() {
      c.assert(
        this.type,
        `The modal component, which uses ObjectModal mixin,
        requires a prop, or data property called "type" representing the object
        type. ie: 'quote' or 'cost_item'.`
      )
      c.assert(
        'modal' in this.$refs,
        `The modal, which uses ObjectModal 
        mixin, requires a Modal component that is also referenced
        as "modal" $refs.`
      )
      c.assert(
        'body' in this.$refs,
        `The modal, which uses ObjectModal mixin, 
        requires a "body" component in $refs.`
      )
      c.assert(
        this.$refs.body && this.$refs.body.dataManipulator,
        `The modal, which uses ObjectModal mixin, 
        requires a "body" in $refs to have 
        the ObjectManipulator mixin added to it.`
      )
    },
    watchBody() {
      const body = this.$refs.body
      if (body) {
        this.foundBody = 1
        // const addLoading = body.addBodyLoading
        //   ? body.addBodyLoading
        //   : (body.addLoading || (() => {}));
        const removeLoading = body.removeBodyLoading
          ? body.removeBodyLoading
          : body.removeLoading || (() => {})
        // const endLoading = body.endBodyLoading
        //   ? body.endBodyLoading
        //   : (body.endLoading || (() => {}));

        body.$on('isDirty', (b) => {
          this.bodyIsDirty = !!b
        })
        body.$on('loading', (i) => {
          this.loading = i
        })
        body.$on('selected', (object) => {
          this.selected = true
          this.original = object
          this.modal.select()
        })
        this.bodyIsDirty = body.isDirty || false
        this.loading = body.loading || 0
        this.selected = body.selected || false
        this.original = body.original || {}
        if (this.selected) {
          this.modal.select()
        }

        body.$on('closeModal', () => {
          this.close()
        })

        if (this.modal.save) {
          body.$on('save', () => {
            this.modal.save.call(this.$refs.body, this)
          })
        }

        if (this.modal.saved) {
          body.$on('saved', (payload) => {
            this.modal.saved.call(this.$refs.body, payload, this)

            if (
              this.modal.go !== false &&
              this.modal.go !== 0 &&
              this.go !== false &&
              this.go !== 0
            ) {
              this.$store.dispatch(
                'go',
                {
                  object: { ...payload.object }
                },
                { root: true }
              )
            }
          })
        }

        const fnEmbue = () => {
          // These must be set at setTimeout not $nextTIck
          // or setImmediate, those are too fast;
          setTimeout(() => {
            const embueKeys = Object.keys(this.modal.embue)
            if (embueKeys.length) {
              const watchers = []
              const key = _.uniqueId()

              embueKeys.forEach((k) => {
                // Make sure the embue value doesn't have filter
                //  key characters in it
                const isFilter = /(&&)|(\|\|)|(\w,\w)|(^LIKE)/.test(this.modal.embue[k])
                if (isFilter) return

                watchers.push(
                  body.$watch(k, () =>
                    c.throttle(
                      () => {
                        this.$nextTick(() => body.$emit('embued', this.modal.embue))
                        watchers.forEach((w) => w()) // Call only once
                      },
                      { delay: 300, key }
                    )
                  )
                )
                // check for a camel version of the same if is id
                // set camelId insteald of camel_id
                const camelField = c.camelCase(k)
                if (/_id$/.test(k) && camelField in body) {
                  body[camelField] = this.modal.embue[k]
                } else {
                  body[k] = this.modal.embue[k]
                }
              })

              if (body.commit) {
                c.throttle(
                  () => {
                    const embue = _.imm(this.modal.embue)
                    body.commit(embue, embue)
                  },
                  { delay: 0 }
                )
              }
            }
            this.modal.selected.call(this)
          }, 0)
        }

        if (body && body.cast) {
          setTimeout(() => {
            fnEmbue()
            removeLoading()
            this.original = body.cast()
            this.selected = true
          }, 0)
          body.$on('selected', () =>
            setTimeout(() => {
              fnEmbue()
              removeLoading()
              this.original = body.cast()
              this.selected = true
            }, 0)
          )
        }
      }
    }
  },
  created() {
    setTimeout(this.devCheckRequirements, 1000)
  },
  mounted() {
    setTimeout(this.watchBody, 0)
  },
  beforeCreated() {},
  props: {
    type: {
      required: false
    },
    go: {
      default: true
    }
  },
  emits: ['embued'],
  components: {
    Modal,
    SaveOrCancel
  }
}
