<template>
  <container v-if="!bodyLoading">
    <!-- email field -->
    <card-section v-if="!chosen">
      <card-list key="email">
        <card-list-field>
          <span> User email </span>

          <field
            schema="user:user_email"
            @valid="emailValidHandler"
            @invalid="emailInvalidHandler"
            :validate="{
              type: 'email',
              required: 1
            }"
            v-model="email"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section class="text-center" v-if="searchingUser">
      <spinner :loading="1" size="3em" class="info"></spinner>
    </card-section>

    <!--  users found -->
    <card-section v-if="usersFound">
      <template #title>
        <span v-if="!chosen" class="text-center" style="margin: 0 auto">
          User(s) found, choose an option:
        </span>
      </template>

      <div class="flex justify-around align-items-stretch flex-wrap">
        <card
          class="button"
          v-for="user in users"
          :key="user.user_id"
          style="width: 20em; margin-right: 0.5em"
          :class="{
            selected: String(user_id) === String(user.user_id)
          }"
          @click="chooseUser(user)"
        >
          <div class="flex justify-start items-center" style="height: 100%">
            <font-awesome-icon icon="user" class="text-muted" style="font-size: 3em" />
            <div class="flex flex-col ml-3">
              <h4 class="mb-0">
                {{ displayUserName(user) }}
              </h4>
              <small class="text-muted">User</small>
            </div>
          </div>
        </card>
      </div>
    </card-section>

    <user-edit v-if="noUsersFound" :key="refId" :reference="refId" />

    <card-section v-else-if="selectedInCompany">
      <card>
        <template #title>
          <h4>Add user to your company?</h4>
        </template>

        <p class="mt-3">
          Adding a user to your company will generally allow them to see your projects, invoices,
          settings and other information.
        </p>
        <p>
          Make sure the email is correct, and make sure the person with this email actually works
          for your company or you could potentially be revealing sensitive company information.
        </p>

        <div class="flex justify-center items-center mt-3">
          <btn class="lg round info" :action="addUserToCompany">
            <template #icon>
              <font-awesome-icon icon="plus" />
            </template>
            Add this user to my company..
          </btn>
        </div>
      </card>
    </card-section>

    <card-section v-else-if="selectedNotInCompany && $store.state.session.user.user_is_super_user">
      <card>
        <template #title>
          <h4>User already exists. Add them to a company from their user page</h4>
        </template>
        <div class="flex justify-center items-center mt-3">
          <btn
            class="lg round info"
            @click="() => $store.dispatch('edit', { type: 'user', id: chosenUser.user_id })"
          >
            <template #icon>
              <font-awesome-icon icon="user" />
            </template>
            See user..
          </btn>
        </div>
      </card>
    </card-section>
  </container>
</template>

<script>
import eventBus from '@/eventBus'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import Field from '@/components/ui/Field.vue'
import UserEdit from '@/components/bodies/UserEdit.vue'

export default {
  name: 'UserNew',
  mixins: [ObjectManipulator('user', true), BodyMixin],
  emits: ['users-found'],
  data() {
    return {
      email: this.user_email,
      users: [],
      searchLoading: 0,
      emailValid: false,
      chosen: false,
      chosenUser: null,
      chosenCompany: null
    }
  },
  watch: {
    async email(newEmail) {
      // Only set user_email and search if email is valid to avoid the save button activating
      if (this.emailValid) {
        this.searchLoading += 1
        this.user_email = newEmail
        this.users = []

        if (newEmail) {
          this.users = await this.find('user_email', newEmail, 'user', 'users')
        }

        this.searchLoading -= 1
      }
    },
    usersFound(val) {
      this.$emit('users-found', val)
    }
  },
  computed: {
    inCompany() {
      return !!this.$store.state.session.scope.company
    },
    searchingUser() {
      return this.searchLoading && this.emailValid
    },
    notSearchingUser() {
      return !this.searchLoading && this.emailValid
    },
    usersFound() {
      return this.notSearchingUser && (this.users.length || this.companies.length)
    },
    noUsersFound() {
      return this.notSearchingUser && !this.users.length
    },
    selectedInCompany() {
      return this.notSearchingUser && this.chosen && this.inCompany
    },
    selectedNotInCompany() {
      return this.notSearchingUser && this.chosen && !this.inCompany
    }
  },
  methods: {
    async emailValidHandler() {
      await c.throttle(() => {}, { delay: 750 })
      this.emailValid = true
      this.chosen = false
    },

    async emailInvalidHandler() {
      await c.throttle(() => {}, { delay: 750 })
      await this.$nextTick()
      this.emailValid = false
      this.chosen = false
    },

    async addUserToCompany() {
      return this.$store.dispatch('User/addToCompany', {
        object: this.chosenUser,
        company: this.$store.state.session.scope.company
      })
    },
    async chooseUser(user = null) {
      this.user_id = user.user_id
      this.chosenUser = user
      this.chosen = true
    },
    async find(field, value, entityType = 'user', list = 'found') {
      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          [field]: value
        }
      })

      const idField = `${entityType}_id`
      const dedup = Object.values(
        set.reduce(
          (acc, obj) => ({
            ...acc,
            [obj[idField]]: obj
          }),
          {}
        )
      )

      this[list] = dedup

      return dedup
    },
    displayUserName(user) {
      if (!user.user_fname && !user.user_lname) {
        return 'Unknown name'
      }
      const wrangledLastName = String(user.user_lname || '').substring(0, 1)
      return `${user.user_fname} ${wrangledLastName}`
    }
  },
  components: {
    UserEdit,
    Field
  },
  props: {},
  mounted() {
    eventBus.$once(`${this.uid}-selected`, () => {
      window.changeManager = () => this.$store.Client.changeManagers[this.refId]
    })
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
