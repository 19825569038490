<template>
  <div class="w-full md:mx-8">
    <div class="flex justify-center items-center mb-8">
      <img :src="EmptyBill" alt="" width="140" height="140" />
    </div>
    <div class="my-2">Select your preferred method to receive the confirmation code</div>
    <div class="min-w-full grid grid-cols-1 md:grid-cols-2 mb-4 gap-4 md:gap-2">
      <div
        class="col-span-1 min-w-full py-2 px-3 border border-surface-300 flex items-center cursor-pointer hover:border-pitch-black"
        :class="{ 'border-2 !border-pitch-black': chosenMethod === 'email' }"
        @click="setMethod('email')"
      >
        <font-awesome-icon icon="envelope" class="mr-4" />
        <div class="flex flex-col">
          <span class="">Send to email</span>
          <span class="font-thin text-sm">{{ userEmail }}</span>
        </div>
      </div>
      <div
        v-if="isClaimedAccount || userPhone"
        class="col-span-1 min-w-full py-2 px-3 border border-surface-300 flex items-center cursor-pointer hover:border-pitch-black"
        :class="{ 'border-2 !border-pitch-black': chosenMethod === 'sms' }"
        @click="setMethod('sms')"
      >
        <font-awesome-icon icon="message-sms" class="mr-4" />
        <div class="flex flex-col">
          <span class="">Send to msg text to </span>
          <span
            class="font-thin text-sm"
            :class="{ 'underline text-blue-print': !isClaimedAccount && !userPhone }"
          >
            {{ userPhone || isClaimedAccount ? userPhone : 'Add phone number' }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="chosenMethod" class="w-full">
      <div>
        This is a Two-Step verification progress for security reason. It helps to prevent fraudulent
        activities.
      </div>
      <div class="flex mt-4 mb-2">
        <span class="mr-1">1.</span>
        <div>The system will send a code to the client's {{ chosenMethod }}.</div>
      </div>
      <div class="flex">
        <span class="mr-1">2.</span>
        <div>
          Just hand your device to the client, and they can use the code to approve, decline, or
          request changes to the proposal.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import EmptyBill from '@/assets/Bill_empty.png'

export default {
  props: {
    clientEmail: {
      type: String,
      default: ''
    },
    clientPhone: {
      type: String,
      default: ''
    },
    isClaimedAccount: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const chosenMethod = ref('')
    const userPhone = ref(props.clientPhone)
    const userEmail = ref(props.clientEmail)

    const setMethod = (method) => {
      chosenMethod.value = method
      emit('selectedMethod', method)
    }

    watch(
      () => props.clientPhone,
      (newPhone) => {
        userPhone.value = newPhone
      }
    )

    watch(
      () => props.clientEmail,
      (newEmail) => {
        userEmail.value = newEmail
      }
    )

    return {
      EmptyBill,
      chosenMethod,
      setMethod,
      userPhone,
      userEmail
    }
  }
}
</script>
