import en from './en_US';
import fr from './fr_CA';
import pt from './pt_PT';

export default {
  en,
  fr,
  pt,
};

