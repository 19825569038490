import Objects from '../Objects'
import Normalize from '../Normalize'
import _ from '../Helpers'

const { getConstructor } = Objects
const { getNormalizedRootRefId } = Normalize

const isEqual = (a, b, type) => {
  switch (type) {
    case 'string':
    case 'bool':
    case 'int':
      return a === b
    case 'float':
    case 'number':
      return typeof a === 'number' && typeof b === 'number' && _.eq(a, b, 5)
    case 'array':
    case 'object':
      return _.isEqual(a, b)
    default:
      if (typeof a === 'number') {
        return typeof a === 'number' && typeof b === 'number' && _.eq(a, b, 5)
      } else if (typeof a === 'string' && typeof b === 'string') {
        return a === b
      }
      return _.isEqual(a, b)
  }
}

/**
 * Returns a list starting from deepest, to shallowest refId in the hierarchy given in set
 * @param set
 * @param refId
 * @param changeSet
 * @param bottomAll
 * @returns {string[]}
 */
const getParentUpdateOrder = (set, refId = false, changeSet = {}, bottomAll = false) => {
  const rootRefIds = Object.keys(changeSet).length
    ? Object.keys(changeSet)
    : [getNormalizedRootRefId(set, refId || undefined)]

  let parentUpdateOrder = {}

  const setUpdateOrder = (orderedRefId, steps = 0) => {
    const parent = set[orderedRefId]

    if (!parentUpdateOrder[orderedRefId] || parentUpdateOrder[orderedRefId] < steps) {
      // Remove parent first, because it will automatically cascade up there
      if (parent && parent.parentRefId && parent.parentRefId in parentUpdateOrder && bottomAll) {
        // eslint-disable-next-line no-unused-vars
        const { [parent.parentRefId]: omit, ...rest } = parentUpdateOrder
        parentUpdateOrder = rest
      }

      // Then add this child
      parentUpdateOrder[orderedRefId] = steps
    }

    if (parent && parent.aoChildren && parent.aoChildren.length) {
      parent.aoChildren
        .filter((cr) => !(cr in parentUpdateOrder))
        .forEach((cr) => setUpdateOrder(cr, steps + 1))
    }
  }

  rootRefIds.forEach((rootRefId) => setUpdateOrder(rootRefId))

  return Object.keys(parentUpdateOrder).sort((a, b) => {
    // If on different steps, sort by step
    const stepB = parentUpdateOrder[b]
    const stepA = parentUpdateOrder[a]
    if (stepB !== stepA) return stepB - stepA

    // Put assemblies after item
    // const typeA = set[a].type;
    // const typeB = set[b].type;
    // if (typeA !== typeB && typeA === 'assembly') return 1;
    // if (typeA !== typeB && typeB === 'assembly') return -1;

    // If on same step, check if it is a fee item and put it last
    const itemA = set[a]
    const itemB = set[b]
    const feeA = itemA && itemA.type === 'cost_item' && +itemA.cost_type_is_fee > 0
    const feeB = itemB && itemB.type === 'cost_item' && +itemB.cost_type_is_fee > 0
    return feeA - feeB
  })
}

const getComputedDependants = (typeOrConstructor) => {
  const constructor =
    typeof typeOrConstructor === 'string' ? getConstructor(typeOrConstructor) : typeOrConstructor
  if (constructor && typeof constructor.getComputedDependants === 'function') {
    return constructor.getComputedDependants(this)
  }
  return {}
}

const shouldCascadeCache = {}
const shouldCascadeField = (type, field = []) => {
  const fields = _.makeArray(field)
  const cacheKey = `${type}:${fields.join('+')}`
  if (cacheKey in shouldCascadeCache) {
    return shouldCascadeCache[cacheKey]
  }
  const constructor = getConstructor(type)
  let b = false
  if (constructor && field) {
    b = [
      ...Object.keys(
        constructor.getChildDependencies ? constructor.getChildDependencies(this) : {}
      ),
      ...Object.keys(
        constructor.getParentDependencies ? constructor.getParentDependencies(this) : {}
      ),
      ...Object.keys(constructor.getFieldDependencies ? constructor.getFieldDependencies(this) : {})
    ].some((k) => fields.includes(k))
  }
  shouldCascadeCache[cacheKey] = b
  return b
}

const cascadeDependencies = (set, refId, changes = {}, possibleDimensions) => {
  // console.log('    ', refId, set[refId].cost_type_name
  //    || set[refId].assembly_name || set[refId].quote_name);
  const latestSet = set
  const changeSet = changes
  // Because ordered array represents all objects, in order of deepness,
  //  we can also use that array to run through all dependencies for that
  //  object
  if (!latestSet[refId]) return [latestSet, changeSet]

  const constr = getConstructor(latestSet[refId].type)
  const fields = Object.keys(constr.fields)
  const depMatrix = getComputedDependants(constr)

  const children = (latestSet[refId].aoChildren ?? []).map((cr) => latestSet[cr] || {})

  const parent = latestSet[latestSet[refId].parentRefId] ?? {}
  const fieldSet = new Set(fields)
  for (const field in depMatrix) {
    if (!fieldSet.has(field)) continue

    const val = depMatrix[field](
      latestSet[refId],
      parent,
      children,
      possibleDimensions,
      latestSet,
      changeSet
    )

    const currentVal = latestSet[refId]?.[field]
    const fieldType = constr.fields[field].type
    const isSame = isEqual(val, currentVal, fieldType)

    if (!isSame) {
      latestSet[refId][field] = val
      ;(changeSet[refId] ??= {})[field] = val
    }

    // delete old equation
    if (
      latestSet[refId]?.oEquations?.[field] &&
      (!isSame ||
        // delete equation if it doesn't match the value any more
        !_.eq(val, _.toNum(latestSet[refId].oEquations[field], 5, true, possibleDimensions), 5))
    ) {
      delete latestSet[refId].oEquations?.[field] // delete from main set
      delete changeSet?.[refId]?.oEquations?.[field] // delete from main set
    }
  }

  return [latestSet, changeSet]
}

const getTypeOrConstructor = (typeOrConstructor) => {
  let constructor = false
  let type = false
  if (typeof typeOrConstructor === 'object' && typeof typeOrConstructor.type === 'string') {
    constructor = typeOrConstructor
    type = constructor.type
  } else if (typeof typeOrConstructor === 'string') {
    constructor = getConstructor(typeOrConstructor)
    if (constructor) {
      type = typeOrConstructor
    } else return { type: false, constructor: false }
  }

  return { type, constructor }
}

const shouldAuditLocal = (typeOrConstructor, changes) => {
  const { type, constructor } = getTypeOrConstructor(typeOrConstructor)

  if (!constructor || !type) return false

  const deps =
    constructor && constructor.getFieldDependencies
      ? Object.keys(constructor.getFieldDependencies())
      : false

  if (!deps || !deps.length) return false

  return Object.keys(changes).some((changeField) => deps.indexOf(changeField) > -1)
}

const childDepsByParentType = {}
const shouldAuditDown = (typeOrConstructor, changes) => {
  if (!('aoChildren' in changes)) return false
  const { type, constructor } = getTypeOrConstructor(typeOrConstructor)
  if (!constructor || !type) return false

  let childDeps = []
  if (type in childDepsByParentType) childDeps = childDepsByParentType
  else {
    childDeps = (constructor.fields.aoChildren.possibleChildTypes || [type]).reduce(
      (acc, childType) => {
        const { constructor: childController } = typeOrConstructor(typeOrConstructor)

        if (!childType || !childController) return acc
        if (!childController.getParentDependencies) return acc

        return [...acc, Object.keys(childController.getParentDependencies())]
      },
      []
    )
    childDepsByParentType[type] = childDeps
  }

  return Object.keys(changes).some((changedField) => childDeps.indexOf(changedField) > -1)
}

const shouldAuditUp = (parentType, changes) => {
  const { type, constructor } = getTypeOrConstructor(parentType)

  if (!constructor || !type) return false
  if (!constructor.getChildDependencies) return false

  const depKeys = Object.keys(constructor.getChildDependencies())
  return Object.keys(changes).some((changeField) => depKeys.indexOf(changeField) > -1)
}

const shouldAudit = (typeOrConstructor, changes) =>
  shouldAuditLocal(typeOrConstructor, changes) ||
  shouldAuditLocal(typeOrConstructor, changes) ||
  shouldAuditUp(typeOrConstructor, changes)

/**
 * Audit upt he chain only
 * @param normalized
 * @param refId
 */
const auditUp = (normalized, refId, possibleDimensions) => {
  let latestSet = normalized
  let changeObject = {}
  let parentRefId = refId

  while (parentRefId) {
    if (!latestSet[parentRefId] || !latestSet[parentRefId].type) {
      parentRefId = null
      break
    }

    // Because ordered array represents all objects, in order of deepness,
    //  we can also use that array to run through all dependencies for that
    //  object
    const updated = cascadeDependencies(latestSet, parentRefId, changeObject, possibleDimensions)
    latestSet = updated[0]
    changeObject = updated[1]

    parentRefId = latestSet[parentRefId].parentRefId
  }

  return [latestSet, changeObject]
}

/**
 * Audits the dependencies and should make up to 4
 *   passes on the set to make sure parent and children
 *   are accurately expressed.
 * @param normalized an object deconstructed using c.normalize();
 * @param refId reference ID of the root object, set in c.normalize();
 * @param changes scoped changes from the perspective of the object ie:
 * {
 *  'my_field_name': 1234,
 * }
 * NOT:
 * {
 *  'ref-id:quote-1234': {
 *      'my_field_name': 1234,
 *    },
 * }
 * @returns [newSet (object), changeSet (object)] (array),
 */
const auditDependencies = (normalized, refId = null, possibleDimensions, changes = {}) => {
  if (!Object.keys(normalized).length) return [{}, {}]

  let latestSet = normalized
  let changeObject = changes

  // first do local
  const [localSet, localChanges] = cascadeDependencies(
    latestSet,
    refId,
    changeObject,
    possibleDimensions
  )

  latestSet = localSet
  changeObject ??= {}

  const hasChangeRef = refId in changeObject
  const hasLocalChangeRef = localChanges && refId in localChanges

  if (hasChangeRef || hasLocalChangeRef) {
    const changeRef = (changeObject[refId] ??= {})

    if (hasLocalChangeRef) {
      Object.assign(changeRef, localChanges[refId])
    }
  }

  // Then rank all objects by deepness, then calculate all
  //  child sums in the exact necessary order, do each
  //  sum calculation only once
  const ordered = getParentUpdateOrder(latestSet, refId)

  // Go down
  const parentDepsByType = {
    cost_item: Object.assign.apply(
      this,
      Object.values(getConstructor('cost_item').getParentDependencies())
    ),
    quote: Object.assign.apply(
      this,
      Object.values(getConstructor('quote').getParentDependencies())
    ),
    assembly: Object.assign.apply(
      this,
      Object.values(getConstructor('assembly').getParentDependencies())
    )
  }

  for (let i = ordered.length - 1; i >= 0; i--) {
    const r = ordered[i]
    if (!r || !latestSet || !(r in latestSet)) {
      continue
    }

    const latestR = latestSet[r]
    const type = latestR.type
    if (!type || !latestR.parentRefId || !(type in parentDepsByType)) {
      continue
    }

    const constr = getConstructor(type)
    const parentDeps = parentDepsByType[type]
    const parentRefId = latestR.parentRefId
    const parent = latestSet[parentRefId] || {}

    const aoChildren = latestR.aoChildren || []
    const children = []
    for (let j = 0; j < aoChildren.length; j++) {
      const cr = aoChildren[j]
      const child = latestSet[cr]
      if (child) {
        children.push(child)
      }
    }

    for (const field in parentDeps) {
      const depFunction = parentDeps[field]
      if (typeof depFunction !== 'function') {
        throw new Error(`parentDeps[${field}] is not a function on ${type}`)
      }

      const val = depFunction(
        latestR,
        parent,
        children,
        possibleDimensions,
        latestSet,
        changeObject
      )

      const fieldType = constr.fields[field].type
      const currentVal = latestR[field]
      const isSame = isEqual(val, currentVal, fieldType)

      if (!isSame) {
        latestR[field] = val
        ;(changeObject[r] ??= {})[field] = val
      }

      // delete old equation
      if (
        latestR?.oEquations?.[field] &&
        (!isSame ||
          // delete equation if it doesn't match the value any more
          !_.eq(val, _.toNum(latestR.oEquations[field], 5, true, possibleDimensions), 5))
      ) {
        delete latestR.oEquations?.[field] // delete from main set
        delete changeObject?.[r]?.oEquations?.[field] // delete from main set
      }
    }
  }

  // Come back up
  let lastUpdated = false
  // console.log('cascade dependencies');
  for (let i = 0; i < ordered.length; i++) {
    const parentRefId = ordered[i]

    if (lastUpdated === parentRefId) {
      continue
    }

    if (!latestSet || !(parentRefId in latestSet)) {
      continue
    }

    const latestParent = latestSet[parentRefId]

    if (!latestParent || !latestParent.type) {
      continue
    }

    // Because ordered array represents all objects, in order of depth,
    // we can also use that array to run through all dependencies for that object
    cascadeDependencies(latestSet, parentRefId, changeObject, possibleDimensions)

    // If we are iterating sibling groups,
    // skip doing the same one over and over
    lastUpdated = parentRefId
  }

  // racalc fee items //
  const feeItems = Object.keys(latestSet).filter((ref) => latestSet[ref].cost_type_is_fee)

  if (feeItems.length) {
    const feeItemUpdateRefs = Normalize.getAncestors(latestSet, feeItems, true)
    const feeItemUpdateSet = {}

    for (let i = 0; i < feeItemUpdateRefs.length; i++) {
      const ref = feeItemUpdateRefs[i]
      feeItemUpdateSet[ref] = latestSet[ref]
    }

    const feeUpdateOrder = getParentUpdateOrder(feeItemUpdateSet)

    let lastUpdated = null
    for (let i = 0; i < feeUpdateOrder.length; i++) {
      const itemRef = feeUpdateOrder[i]

      if (
        lastUpdated === itemRef ||
        !(itemRef in feeItemUpdateSet) ||
        !latestSet[itemRef] ||
        !latestSet[itemRef].type
      ) {
        continue
      }

      cascadeDependencies(latestSet, itemRef, changeObject, possibleDimensions)
      // Assuming cascadeDependencies updates latestSet and changeObject in place

      lastUpdated = itemRef
    }
  }

  // _.log('benchmark audit dependencies', new Date().valueOf() - bench, 'ms');
  return [latestSet, changeObject]
}

export default {
  getNormalizedDepthOrder: getParentUpdateOrder,
  shouldCascadeField,
  auditDependencies,
  cascadeDependencies,
  auditUp,

  shouldAuditDown,
  shouldAuditUp,
  shouldAuditLocal,
  shouldAudit
}
