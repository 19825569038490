import { useMediaQuery as vuseMediaQuery } from '@vueuse/core'
import { computed } from 'vue'

export function useMediaQuery() {
  const sm = vuseMediaQuery('(min-width: 640px)')
  const md = vuseMediaQuery('(min-width: 768px)')
  const lg = vuseMediaQuery('(min-width: 1024px)')
  const xl = vuseMediaQuery('(min-width: 1280px)')
  const xxl = vuseMediaQuery('(min-width: 1536px)')

  /** Very naive check to emulate the former behaviour in Vuex (which was slow) */
  const smallFormat = computed(() => !md.value)

  return { sm, md, lg, xl, xxl, smallFormat }
}
