<template>
  <div
    class="flex align-center justify-center font-mono border border-cool-gray-600 bg-flame-white rounded-md text-cool-gray-800 whitespace-nowrap h-fit max-h-fit py-1 px-2 text-xs border-b-2"
  >
    <IconText :text="formattedHotkey" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, computed } from 'vue'
const platform = window.navigator.platform.toLowerCase()
const mac = platform.includes('mac')

const props = defineProps({
  hotkey: {
    type: String,
    default: 'Enter' // Examples: 'Ctrl-S', 'Cmd-Enter', etc.
  }
})

const emit = defineEmits(['hotkeyPressed'])

// Unicode representations for modifier keys
const iconMap = {
  enter: 'arrow-turn-down-left',
  shift: 'arrow-up',
  alt: 'alt',
  cmd: mac ? 'command' : null
}

// Format the hotkey using unicode characters for visual representation
const formattedHotkey = computed(() => {
  return props.hotkey
    .split('-')
    .map((key) => {
      key = key.toLowerCase()
      if (!iconMap[key]) return key.toUpperCase()
      return `[${iconMap[key]}]`
    })
    .join(' ')
})

// Handle keypress events to detect when the specified hotkey is pressed
const handleKeypress = (event) => {
  const keys = props.hotkey.toLowerCase().split('-')
  const keyMap = {
    ctrl: event.ctrlKey,
    cmd: mac ? event.metaKey : event.ctrlKey,
    shift: event.shiftKey,
    alt: event.altKey
  }

  const trueKeys = Object.keys(keyMap).filter((key) => !!keyMap[key])

  // Make sure all required meta keys are pressed - if false there
  // is some extra pressed keyed that should trigger something else probably
  if (!trueKeys.every((metaKey) => keys.includes(metaKey))) return

  // If a metaKey make sure its true, or If not a metaKey, make sure it is pressed
  if (
    !keys.every(
      (key) => trueKeys.includes(key) || (!(key in keyMap) && event.key.toLowerCase() === key)
    )
  )
    return

  emit('hotkeyPressed', event)
  return event.preventDefault()
}

onMounted(() => {
  window.addEventListener('keydown', handleKeypress)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeypress)
})
</script>
