import { computed, ref } from 'vue'

export function usePasswords() {
  const password = ref(null)
  const confirmPassword = ref(null)
  const passwordStrength = ref({
    score: null,
    warning: null,
    suggestions: null,
    valid: false,
    weak: false
  })

  const passwordsMatch = computed(() => {
    return password.value === confirmPassword.value
  })
  const showPasswordMatch = computed(() => {
    if (confirmPassword.value === null) {
      return false
    }
    return !passwordsMatch.value
  })

  function updatePasswordStrength(result) {
    passwordStrength.value.score = result.score
    if (result.score <= 2) {
      passwordStrength.value.warning = result.feedback.warning
      passwordStrength.value.suggestions = result.feedback.suggestions
      passwordStrength.value.weak = true
    } else {
      passwordStrength.value.warning = null
      passwordStrength.value.suggestions = null
      passwordStrength.value.weak = false
    }
  }
  function resetPasswordStrength() {
    passwordStrength.value = {
      score: null,
      warning: null,
      suggestions: null,
      valid: false,
      weak: false
    }
  }
  function setPasswordValid(valid) {
    passwordStrength.value.valid = valid
  }

  return {
    password,
    confirmPassword,
    passwordsMatch,
    showPasswordMatch,
    passwordStrength,
    updatePasswordStrength,
    resetPasswordStrength,
    setPasswordValid
  }
}
